import React, { useRef } from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import MapSvg from "../../assets/images/map.svg";

const MapContainer = styled.div`
    display: flex;
    background: #D6EBF4;
	overflow: hidden;

	svg {
		height: 80vh;
		width: auto;
		margin: 0 auto;
		transform:scale(1);
		transition: all 1s ease;
		transform-origin: 50%;
		
	}

	.st0{fill:#D6EBF4;}
	.st1{fill:#93C2C7;}
	.st2{fill:#31671D;}
	.st3{fill:none;stroke:#F8E1CF;stroke-width:3;stroke-miterlimit:10;}
	.st4{
        fill:#568D57;
        transition: all .5s ease;
        cursor: pointer;
        
        &:hover {
            fill: #72a973;
        }
    }
	.st5{fill:#F8E1CF;}
	.st6{fill:#4897C9;}
	.st7{fill:#1A1A1A;}
	.st8{fill:#4D4D4D;}
	.st9{fill:#DBDFE4;}
	.st10{fill:#FFFFFF;}
	.st11{fill:#C7C0C8;}
	.st12{fill:#A9A9C4;}
	.st13{opacity:0.6;}
	.st14{fill:#DCF1FB;}
	.st15{fill:#9ACEEA;}
	.st16{fill:#61A2C5;}
	.st17{fill:#E0F2FC;}
	.st18{fill:#D2EDFA;}
	.st19{fill:#D6EFFC;}
	.st20{opacity:0.6;fill:#68A6C8;}
	.st21{fill:none;}
	.st22{fill:#417E22;}
	.st23{fill:#333333;}
	.st24{fill:#314412;}
	.st25{fill:#CAA486;}
	.st26{fill:#666666;}
	.st27{fill:#2D261D;}
	.st28{fill:#D4C7AB;}
	.st29{fill:#5B5445;}
	.st30{fill:#7EB141;}
	.st31{fill:#2C2E19;}
	.st32{fill:#5A5515;}
	.st33{fill:#A89578;}
	.st34{fill:#D2C5A9;}
	.st35{fill:#606060;}
	.st36{fill:#635D4D;}
	.st37{fill:#C8A285;}
	.st38{fill:#D15A2C;}
	.st39{fill:#0A5382;}
	.st40{fill:#BA3830;}
	.st41{fill:#B16F5C;}
	.st42{fill:#424242;}
	.st43{fill:#CEA980;}
	.st44{fill:#4BB6F4;}
	.st45{fill:#744818;}
	.st46{fill:#8F8F8F;}
	.st47{fill:#C6D3D9;}
	.st48{fill:#F5D8AE;}
	.st49{fill:#AB1F23;}
	.st50{fill:#D3B297;}
	.st51{fill:#797979;}
	.st52{fill:#DBD0B8;}
	.st53{fill:#3E7826;}
	.st54{fill:#3F5416;}
	.st55{fill:#8CBA52;}
	.st56{fill:#3B3227;}
	.st59{fill:#9A6251;}
	.st62{fill:#9D9EA2;}
	.st63{fill:#978984;}
	.st64{fill:#B29076;}
	.st65{fill:#808080;}
	.st66{fill:#C1B2A4;}
	.st67{fill:#222406;}
	.st68{fill:#604012;}
	.st69{fill:#D7D7D7;}
	.st70{fill:#025D87;}
	.st71{fill:#988777;}
	.st72{fill:#9FAA9D;}
	.st73{fill:#45505C;}
	.st74{fill:#6C6A60;}
	.st75{fill:#ABABAB;}
	.st76{fill:#EEECF2;}
	.st77{fill:#858E8E;}
	.st78{fill:#636F70;}
	.st79{fill:#636F70;stroke:#4D4D4D;stroke-width:3;stroke-miterlimit:10;}
	.st80{fill:#856251;}
	.st81{fill:#C28772;}
	.st82{fill:#4E4146;}
	.st83{fill:#3E323E;}
	.st84{fill:#A8A8A0;}
	.st85{fill:#795F40;}
	.st86{fill:#526851;}
	.st87{fill:#937B6F;}
	.st88{fill:#6B5149;}
	.st89{fill:#E0AF72;}
	.st90{fill:#B38F60;}
	.st91{fill:#593C2C;}
	.st92{fill:#405397;}
	.st93{fill:#987551;}
	.st94{fill:#79573B;}
	.st95{fill:#405397;stroke:#333333;stroke-width:2;stroke-miterlimit:10;}
	.st96{fill:#676164;}
	.st97{fill:#558F1E;}
	.st98{fill:#7BAF21;}
	.st99{fill:#8C6E52;}
	.st100{fill:#593C2C;stroke:#333333;stroke-width:2;stroke-miterlimit:10;}
	.st101{fill:#B7AFAA;}
	.st102{fill:#CCC5B7;}
	.st103{fill:#A29480;}
	.st104{fill:#8F8176;}
	.st105{fill:#5D4E40;}
	.st106{fill:#026B6A;}
	.st107{fill:#6B6E79;}
	.st108{fill:#515646;}
	.st109{fill:#3E4B3B;}
	.st110{fill:#7E8042;}
	.st111{fill:#8A9155;}
	.st112{fill:#61773F;}
	.st113{fill:#D7D2C3;}
	.st114{fill:#0A6041;}
	.st115{fill:#E5F3FB;}
	.st116{fill:#6D7951;}
	.st117{fill:#A8D6ED;}
	.st118{fill:#5B8077;}
	.st119{fill:#A8B166;}
	.st120{fill:#72B0CF;}
	.st121{fill:#3E6B5D;}
	.st122{fill:#B5A46E;}
	.st123{fill:#3D4C32;}
	.st124{fill:#164912;}
`;


const Map = ({setRegion, handleLoad}) => {
	const inputEl = useRef(null); 
	
	const handleClick = (region) => {

		let transformOrigin = "50%";
		if (region.includes('south east')){
			transformOrigin = "70% 100%"
		} else if (region.includes('north east england')){
			transformOrigin = "50% 40%"
		} else if (region.includes('east england')){
			transformOrigin = "100% 100%"
		} else if (region.includes('east midlands')){
			transformOrigin = "80% 80%"
		} else if (region.includes('mid wales')){
			transformOrigin = "20% 100%"
		} else if (region.includes('north wales')){
			transformOrigin = "20% 80%"
		} else if (region.includes('north west england')){
			transformOrigin = "40% 50%"
		} else if (region.includes('scotland')){
			transformOrigin = "10% 10%"
		} else if (region.includes('south wales')){
			transformOrigin = "20% 120%"
		} else if (region.includes('south west')){
			transformOrigin = "20% 130%"
		} else if (region.includes('west midlands')){
			transformOrigin = "50% 100%"
		} else if (region.includes('yorkshire')){
			transformOrigin = "50%"
		}

		if (inputEl) {
			inputEl.current.style.transformOrigin = transformOrigin;
			setTimeout(() => {
				inputEl.current.style.transform = "scale(2)";
			}, 200);
		}

		setTimeout(() => {
			handleLoad();
			setRegion(region);
		}, 700);
	}

    return (
        <MapContainer>

<svg ref={inputEl} version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 1800 2880" style={{enableBackground: "new 0 0 1800 2880"}} xmlSpace="preserve" >
<switch>

	<g extraneous="self">
		<g id="background">
			<rect className="st0" width="1800" height="2880"/>
		</g>
		<g id="drop_shadow">
			<g>
				<g>
					<path className="st1" d="M469.94,385.54c5.33-53.3,10.28-106.65,16.01-159.9c-0.71,5.32-1.43,10.63-2.14,15.95
						c0.56-4.1,1.36-7.85,2.86-11.71c-2.02,4.78-4.03,9.56-6.05,14.33c-2.64,4.15-5.99,7.57-10.03,10.26
						c-4.81,3.29-5.29,3.9-1.44,1.83c-4.78,2.02-9.56,4.03-14.33,6.05c3.61-1.29,7.17-2.06,10.96-2.64
						c-5.32,0.71-10.63,1.43-15.95,2.14c48.38-6.18,96.85-11.72,145.27-17.57c24.07-2.91,48.14-5.82,72.21-8.72
						c11.71-1.41,23.42-2.83,35.13-4.24c11.05-1.33,22.46-3.72,33.61-2.83c-5.32-0.71-10.63-1.43-15.95-2.14
						c1.81,0.21,3.58,0.62,5.3,1.24c-4.78-2.02-9.56-4.03-14.33-6.05c5.44,2.69,3.58,0.99-5.58-5.12
						c-6.36-9.47-8.29-11.57-5.79-6.31c-2.02-4.78-4.03-9.56-6.05-14.33c0.51,1.46,0.85,2.95,1.02,4.49
						c-0.71-5.32-1.43-10.63-2.14-15.95c0.16,2.53,0.08,4.79-0.18,7.31c0.71-5.32,1.43-10.63,2.14-15.95
						c-0.78,4.41-2.03,8.52-3.67,12.68c2.02-4.78,4.03-9.56,6.05-14.33c-11.65,26.73-29.07,51.91-43.89,77
						c-15.83,26.79-31.91,53.43-48.23,79.93c-32.64,52.99-66.26,105.37-100.8,157.15c-16.6,24.89-6.87,60.16,15.45,77.4
						c23.39,18.07,57.48,16.61,78.78-4.69c5.98-5.98,12.27-11.49,18.73-16.94c9.02-7.6-11.91,8.34-2.13,1.75
						c3.48-2.35,6.87-4.82,10.4-7.1c13.29-8.58,27.37-15.77,41.91-21.98c-4.78,2.02-9.56,4.03-14.33,6.05
						c28.54-11.83,58.45-19.37,89.02-23.57c-5.32,0.71-10.63,1.43-15.95,2.14c67.99-8.79,138.23-3.07,206.02,5.29
						c-5.32-0.71-10.63-1.43-15.95-2.14c6.62,0.96,12.96,2.3,19.26,4.59c-4.78-2.02-9.56-4.03-14.33-6.05
						c0.32,0.14,8.96,4.27,8.68,4.57c-1,1.04-12.31-11.73-5.28-3.48c3.57,4.19-8.05-13.71-4.06-5.13c1.06,2.29,2.39,4.44,3.41,6.77
						c-2.02-4.78-4.03-9.56-6.05-14.33c2.24,5.8,3.6,11.6,4.44,17.76c-0.71-5.32-1.43-10.63-2.14-15.95
						c1.06,10.15,0.65,20.19-0.58,30.3c0.71-5.32,1.43-10.63,2.14-15.95c-5.36,38.6-17.68,76.72-32.72,112.55
						c2.02-4.78,4.03-9.56,6.05-14.33c-9.57,22.43-20.58,44.21-33.12,65.13c-6.27,10.45-12.91,20.69-19.89,30.68
						c-3.49,5-7.1,9.9-10.73,14.81c-6.96,9.4,9.24-11.62,1.95-2.48c-1.9,2.38-3.8,4.77-5.72,7.13
						c-15.42,18.87-32.17,36.67-50.09,53.18c-8.59,7.91-17.45,15.52-26.57,22.82c-10.03,8.03,7.55-5.64,2.54-1.88
						c-2.44,1.83-4.87,3.67-7.32,5.47c-5.33,3.9-10.74,7.68-16.21,11.38c-20.31,13.75-32,34.78-28.65,59.78
						c2.94,21.87,19.86,45.5,42.98,49.88c9.22,1.75,18.09,4.38,26.78,7.91c-4.78-2.02-9.56-4.03-14.33-6.05
						c7.63,3.31,14.95,7.15,21.86,11.79c1.72,1.16,3.41,2.35,5.08,3.59c-7.98-6.29-9.6-7.47-4.88-3.54c3.01,2.87,6.08,5.61,8.98,8.6
						c14.14-34.14,28.28-68.28,42.43-102.43c-72.98,2.53-141.65,30.92-195.25,80.47c-27.05,25.01-21.84,77.34,12.14,94.23
						c35.24,17.52,72.82,22.69,111.83,20.61c30.6-1.63,61.22-6.05,91.83-2.21c-5.32-0.71-10.63-1.43-15.95-2.14
						c11.22,1.64,21.96,4.41,32.44,8.76c-4.78-2.02-9.56-4.03-14.33-6.05c4.97,2.24,9.8,4.69,14.46,7.52
						c2.8,1.7,5.46,3.56,8.19,5.37c6.81,4.52-2.83-2.85-4.28-3.49c4.83,2.12,9.85,8.29,13.67,11.87c4.01,3.75,7.9,7.62,11.72,11.56
						c7.49,7.71,14.73,15.65,21.74,23.81c3.85,4.48,7.62,9.02,11.32,13.62c1.34,1.67,2.64,3.39,4,5.05
						c-11.73-14.22-3.91-5.07-1.56-1.91c26.36,35.31,48.23,73.78,65.58,114.26c-2.02-4.78-4.03-9.56-6.05-14.33
						c19.23,45.59,32.45,93.39,39.09,142.43c-0.71-5.32-1.43-10.63-2.14-15.95c2.35,18.19,3.16,36.58,6.21,54.68
						c3.54,21.02,14.49,40.09,29.23,55.3c12.07,12.46,27.09,22.16,40.83,32.66c3.24,2.48,6.49,4.96,9.73,7.44
						c1.66,1.27,11.92,9.17,3.64,2.78c-8.58-6.63,3.48,2.73,4.98,3.92c2.75,2.16,5.49,4.34,8.22,6.53
						c22.66,18.17,44.65,37.34,64.12,58.95c2.33,2.59,4.64,5.22,6.89,7.88c1.32,1.55,10.37,12.97,3.59,4.17
						c-6.79-8.81,2.02,2.89,3.18,4.55c1.91,2.71,3.77,5.45,5.58,8.22c7.83,12.01,14.61,24.61,20.36,37.74
						c-2.02-4.78-4.03-9.56-6.05-14.33c6.79,16.13,11.73,32.74,14.36,50.05c-0.71-5.32-1.43-10.63-2.14-15.95
						c1.44,10.82,1.94,21.6,1.44,32.51c25.32-19.29,50.63-38.57,75.95-57.86c-34.54-5.15-68.14-14.44-100.43-27.75
						c4.78,2.02,9.56,4.03,14.33,6.05c-1.11-0.47-2.21-0.93-3.32-1.4c-14.84-6.27-29.91-11.33-46.23-6.05
						c-12.65,4.09-23,10.94-31.17,21.5c-17.43,22.56-17.55,58.59,4.69,78.78c25.36,23.02,50.71,46.04,76.07,69.06
						c11.72,10.64,23.85,20.98,35.05,32.17c2.61,2.61,5.16,5.27,7.61,8.03c0.7,0.8,1.4,1.6,2.09,2.4c3.94,4.77,2.79,3.2-3.43-4.72
						c3.45,6.29,7.88,11.72,10.94,18.34c-2.02-4.78-4.03-9.56-6.05-14.33c1.84,4.46,3.22,8.87,4.12,13.6
						c-0.71-5.32-1.43-10.63-2.14-15.95c0.48,3.7,0.6,7.22,0.33,10.94c0.71-5.32,1.43-10.63,2.14-15.95
						c-0.55,3.8-1.47,7.24-2.85,10.81c2.02-4.78,4.03-9.56,6.05-14.33c-1.6,3.37-3.51,6.34-5.52,9.47
						c-5.39,8.41,10.18-12.97,0.84-1.1c-10.48,13.32-18.27,23.51-24.01,39.65c-10,28.08-1.43,61.25,20.56,81.26
						c23.89,21.74,53.87,24.01,84.1,18.93c17.4-2.92,34.21-8.32,51.73-10.9c-5.32,0.71-10.63,1.43-15.95,2.14
						c20.45-2.73,40.92-3.26,61.4-0.59c-5.32-0.71-10.63-1.43-15.95-2.14c13.89,2.09,27.3,5.55,40.3,10.88
						c-4.78-2.02-9.56-4.03-14.33-6.05c7.08,3.06,13.96,6.51,20.52,10.58c3.25,2.01,6.26,4.52,9.54,6.46
						c-15.72-9.25-5.38-4.23-1.07-0.28c2.81,2.57,5.5,5.27,8.06,8.08c10.2,11.2-5.96-9.42-0.45-1.1
						c4.22,6.38,8.06,12.74,11.22,19.73c-2.02-4.78-4.03-9.56-6.05-14.33c4.13,9.83,6.84,19.93,8.31,30.5
						c-0.71-5.32-1.43-10.63-2.14-15.95c1.18,10.63,1.04,21.13-0.21,31.75c0.71-5.32,1.43-10.63,2.14-15.95
						c-2.3,16.39-7.04,32.01-13.29,47.3c2.02-4.78,4.03-9.56,6.05-14.33c-12.99,30.58-31.15,58.44-50.25,85.5
						c-8.53,12.08-9.83,32.49-6.05,46.23c3.83,13.92,14.59,29,27.57,35.86c30.82,16.28,62.71,5.92,82.09-21.53
						c45.29-64.15,84.53-137.74,68.52-218.69c-7.16-36.2-29.59-71.46-58.32-94.42c-14.42-11.52-28.72-21.64-45.63-29.03
						c-23.45-10.25-45.16-15.83-70.58-19.07c-20.54-2.62-40.9-2.1-61.4,0.59c-13.79,1.8-27.48,3.7-41.03,6.8
						c-8.9,2.04-17.62,4.77-26.64,6.24c5.32-0.71,10.63-1.43,15.95-2.14c-5.11,0.67-10.06,0.91-15.2,0.36
						c5.32,0.71,10.63,1.43,15.95,2.14c-2.36-0.42-4.43-0.95-6.69-1.76c4.78,2.02,9.56,4.03,14.33,6.05c-5.21-2.68-3-0.71,6.62,5.9
						c6.55,9.64,8.36,11.84,5.45,6.6c2.02,4.78,4.03,9.56,6.05,14.33c-0.68-1.49-1.14-3.05-1.39-4.68
						c0.71,5.32,1.43,10.63,2.14,15.95c-0.22-1.52-0.24-3.05-0.06-4.57c-0.71,5.32-1.43,10.63-2.14,15.95
						c0.43-2.42,1.03-4.43,1.94-6.72c-2.02,4.78-4.03,9.56-6.05,14.33c1.13-2.31,2.33-4.46,3.75-6.61c1.38-2.1,6.7-9,0.17-0.5
						c-6.59,8.59-1.05,1.45,0.5-0.52c4.12-5.25,8.75-10.97,12.71-16.59c10.73-15.22,16.37-34.73,16.76-53.29
						c0.73-35.08-17.92-65.71-40.15-91.2c-16.92-19.4-37.6-35.85-56.62-53.12c-21.05-19.11-42.1-38.22-63.15-57.34
						c-24.24,31.41-48.47,62.82-72.71,94.23c38.39,16.22,76.74,29.04,118.08,35.2c19.76,2.95,36.01,1.85,52.31-10.74
						c15.31-11.83,22.76-27.81,23.65-47.12c3.44-75.08-33.34-145.25-82.36-199.68c-21.81-24.22-46.77-45.36-72.34-65.48
						c-15.94-12.54-32.09-24.83-48.19-37.15c-3.51-2.69-12.15-10.7,1.46,1.17c-2.64-2.3-5.5-4.35-8.12-6.69
						c-2.51-2.24-4.69-5.43-7.34-7.41c6.14,8.41,7.57,10.05,4.29,4.91c-1.03-1.81-1.91-3.69-2.64-5.64
						c2.02,4.78,4.03,9.56,6.05,14.33c-1.41-4.18-2.36-8.31-3.06-12.67c0.71,5.32,1.43,10.63,2.14,15.95
						c-5.98-47.31-10.72-93.7-24.5-139.59c-13.17-43.87-31.4-86.82-53.85-126.75c-22.94-40.82-51.21-79.18-82.87-113.66
						c-33.68-36.67-74.18-69.29-124.79-76.96c-27.37-4.15-53.62-3.91-81.12-1.81c-23.65,1.81-47.37,3.82-71.01,0.8
						c5.32,0.71,10.63,1.43,15.95,2.14c-9.95-1.48-19.53-3.86-28.91-7.53c4.78,2.02,9.56,4.03,14.33,6.05
						c-2.82-1.2-5.55-2.46-8.3-3.82c4.05,31.41,8.1,62.82,12.14,94.23c4.67-4.32,9.9-8.11,14.48-12.47
						c-12.49,11.89-5.84,4.52-1.93,1.76c3.04-2.14,6.13-4.2,9.27-6.19c11.23-7.13,23.04-13.19,35.27-18.42
						c-4.78,2.02-9.56,4.03-14.33,6.05c18.74-7.71,38.15-13.09,58.2-16.06c-5.32,0.71-10.63,1.43-15.95,2.14
						c8.44-1.13,16.88-1.84,25.39-2.14c24.2-0.84,45.34-14.4,54.83-36.88c9.4-22.27,4.54-48.03-12.41-65.54
						c-25.58-26.44-60.16-46.29-96.44-53.17c4.78,36.55,9.56,73.11,14.33,109.67c109.8-74.32,192.08-184.03,231.56-310.78
						c9.73-31.25,18.39-65.1,18.83-97.95c0.58-42.73-18.01-88.97-59.1-107.49c-24.09-10.86-48.86-12.88-74.89-15.39
						c-18.65-1.8-37.31-3.51-56-4.77c-37.89-2.55-76.04-3.29-113.85,0.78c-82.55,8.89-163.44,36.21-223.32,96.1
						c31.41,24.24,62.82,48.47,94.23,72.71c35.59-53.36,70.21-107.36,103.79-162.01c16.95-27.6,33.65-55.35,50.07-83.26
						c8.05-13.68,16.04-27.4,23.96-41.16c8.92-15.48,18.32-30.99,23.37-48.3c6.61-22.63,4.74-48.04-7.55-68.41
						c-14.85-24.61-43.82-38.43-72.21-37.19c-15.99,0.7-32.15,3.67-48.04,5.59c-16.26,1.96-32.53,3.93-48.79,5.89
						c-33.18,4.01-66.35,8.02-99.53,12.02c-16.26,1.96-32.53,3.93-48.79,5.89c-18.6,2.25-38.93,2.72-56.95,7.62
						c-30.1,8.19-54.14,33.16-58.81,64.35c-3.22,21.5-4.9,43.26-7.06,64.89c-3.7,36.99-7.4,73.98-11.09,110.97
						c-1.44,14.4,7.73,32.58,17.57,42.43c10.41,10.41,27.48,18.24,42.43,17.57c15.52-0.7,31.61-5.79,42.43-17.57
						C463.73,415.6,468.28,402.22,469.94,385.54L469.94,385.54z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st1" d="M351.02,348.02c0.16,0.88,0.32,1.76,0.48,2.65c-0.71-5.32-1.43-10.63-2.14-15.95
						c0.39,3.5,0.44,6.83,0.14,10.34c0.71-5.32,1.43-10.63,2.14-15.95c-0.64,4.29-1.74,8.25-3.34,12.28
						c2.02-4.78,4.03-9.56,6.05-14.33c-2.23,4.9-4.94,9.33-8.14,13.65c3.13-4.05,6.25-8.1,9.38-12.14
						c-5.89,7.4-12.68,13.75-20.1,19.59c4.05-3.13,8.1-6.25,12.14-9.38c-31.01,23.44-68.32,39.83-87.38,76.03
						c-13.74,26.09-17.08,54.75-9.83,83.42c5.59,22.11,18.28,41.81,27.17,62.63c-2.02-4.78-4.03-9.56-6.05-14.33
						c2.22,5.64,3.67,11.37,4.52,17.37c-0.71-5.32-1.43-10.63-2.14-15.95c0.58,5.32,0.65,10.5,0.11,15.83
						c0.71-5.32,1.43-10.63,2.14-15.95c-0.6,4.3-1.58,8.37-3.14,12.43c2.02-4.78,4.03-9.56,6.05-14.33
						c-1.11,2.5-2.37,4.78-3.91,7.04c3.13-4.05,6.25-8.1,9.38-12.14c-1.65,2.11-3.39,3.9-5.47,5.6c4.05-3.13,8.1-6.25,12.14-9.38
						c-1.7,1.19-3.31,2.13-5.17,3.03c4.78-2.02,9.56-4.03,14.33-6.05c-2.11,0.83-4.07,1.4-6.29,1.88
						c5.32-0.71,10.63-1.43,15.95-2.14c-3.04,0.4-5.82,0.42-8.86,0.03c5.32,0.71,10.63,1.43,15.95,2.14
						c-2.19-0.47-4.13-1.05-6.21-1.85c4.78,2.02,9.56,4.03,14.33,6.05c-2.74-1.29-5.19-2.77-7.64-4.56
						c4.05,3.13,8.1,6.25,12.14,9.38c-3.11-2.55-5.81-5.31-8.39-8.38c3.13,4.05,6.25,8.1,9.38,12.14
						c-4.68-6.08-8.33-12.64-11.32-19.69c2.02,4.78,4.03,9.56,6.05,14.33c-4.85-12.39-6.85-25.65-11.04-38.26
						c-6.74-20.32-16.04-39.91-31.72-54.95c-39.61-38.02-103.97-33.51-139.22,8.19c-17.43,20.61-28.7,45.73-28.1,73.21
						c0.51,23.53,7.85,47.56,20.69,67.29c22.78,35.02,56.4,56,88.97,80.74c-4.05-3.13-8.1-6.25-12.14-9.38
						c9.71,7.53,19.02,15.52,26.77,25.1c-3.13-4.05-6.25-8.1-9.38-12.14c2.42,3.27,4.49,6.56,6.29,10.21
						c-2.02-4.78-4.03-9.56-6.05-14.33c1.16,2.87,1.98,5.49,2.63,8.51c-0.71-5.32-1.43-10.63-2.14-15.95
						c0.37,3.2,0.44,6.04,0.24,9.25c0.71-5.32,1.43-10.63,2.14-15.95c-0.88,6.34-2.75,12.15-5.16,18.07
						c2.02-4.78,4.03-9.56,6.05-14.33c-13.27,30.35-28.36,60.01-42.58,89.93c-8.49,17.86-18.26,35.78-24.4,54.6
						c-7.5,22.98-7.02,51.9,6.19,72.81c16.2,25.63,44.74,40.87,75.28,38.72c17.78-1.25,35.52-5.27,52.14-11.66
						c34.6-13.31,63.47-31.63,92.29-54.74c24.73-19.83,46.84-42.67,68.66-65.6c-31.41-24.24-62.82-48.47-94.23-72.71
						c-48.17,74.67-66.82,162.85-80.49,249.32c-7.27,46.01-14.36,92.06-21.32,138.12c-4.31,28.49-6.25,57.73,10.35,83.24
						c13.56,20.82,36.21,35.08,61.47,35.47c23.35,0.36,42.97-8.97,60.29-24.12c36.42-31.85,50.85-81.32,68.84-124.35
						c-2.02,4.78-4.03,9.56-6.05,14.33c6.71-15.81,14.21-31.2,24.49-45.03c-3.13,4.05-6.25,8.1-9.38,12.14
						c5.16-6.65,10.8-12.97,17.41-18.22c-4.05,3.13-8.1,6.25-12.14,9.38c1.3-0.95,2.68-1.77,4.13-2.46
						c-4.78,2.02-9.56,4.03-14.33,6.05c1.4-0.68,2.86-1.16,4.39-1.44c-5.32,0.71-10.63,1.43-15.95,2.14
						c0.71-0.06,1.43-0.12,2.14-0.17c-5.32-0.71-10.63-1.43-15.95-2.14c0.68,0.09,1.35,0.19,2.03,0.28
						c-4.78-2.02-9.56-4.03-14.33-6.05c0.62,0.27,1.24,0.53,1.86,0.8c-4.05-3.13-8.1-6.25-12.14-9.38c0.54,0.46,1.08,0.92,1.63,1.39
						c-3.13-4.05-6.25-8.1-9.38-12.14c0.41,0.64,0.81,1.28,1.22,1.91c-2.02-4.78-4.03-9.56-6.05-14.33c0.6,1.44,0.96,2.94,1.07,4.49
						c-0.71-5.32-1.43-10.63-2.14-15.95c4.07,56.79,6.02,114.78-1.4,171.36c0.71-5.32,1.43-10.63,2.14-15.95
						c-5.12,36.83-16.8,71.82-25.03,107.96c-5.52,24.23-11.18,50.35-4.34,74.91c8.48,30.41,33.55,52.03,63.47,60.17
						c19.09,5.19,39.96,6.57,59.7,7.7c15.76,0.9,31.59,0.75,47.34-0.4c36.25-2.64,72.63-8.86,107.16-20.37
						c38.66-12.89,76.11-30.61,109.53-54.01c-30.09-17.27-60.19-34.54-90.28-51.81c0.26,6.18,0.01,12.23-0.7,18.38
						c0.71-5.32,1.43-10.63,2.14-15.95c-2.35,16.44-7.87,31.91-14.28,47.16c2.02-4.78,4.03-9.56,6.05-14.33
						c-17.86,41.71-39.24,82.41-36.05,129.33c1.61,23.65,6.78,48.08,20.32,68.03c9.63,14.19,19.79,24.5,34.07,34.09
						c11.69,7.85,25.58,11.88,38.5,17.28c-4.78-2.02-9.56-4.03-14.33-6.05c3.65,1.67,7.01,3.5,10.3,5.8
						c-4.05-3.13-8.1-6.25-12.14-9.38c0.7,0.53,1.33,1.13,1.89,1.81c-3.13-4.05-6.25-8.1-9.38-12.14c0.86,1.13,1.49,2.19,2.12,3.46
						c-2.02-4.78-4.03-9.56-6.05-14.33c0.76,1.92,1.26,3.81,1.64,5.84c-0.71-5.32-1.43-10.63-2.14-15.95
						c0.85,6.37,0.36,12.71-0.45,19.05c0.71-5.32,1.43-10.63,2.14-15.95c-4.97,34.62-18.94,68.67-7.08,103.66
						c6.4,18.86,15.69,32.83,28.7,47.82c5.94,6.85,12.69,12.96,18.3,20.09c-3.13-4.05-6.25-8.1-9.38-12.14
						c2.14,2.83,4.01,5.76,5.46,9c-2.02-4.78-4.03-9.56-6.05-14.33c0.86,2.14,1.48,4.27,1.87,6.54c-0.71-5.32-1.43-10.63-2.14-15.95
						c0.3,2.43,0.3,4.77,0.03,7.2c0.71-5.32,1.43-10.63,2.14-15.95c-0.52,3.32-1.48,6.39-2.77,9.48c2.02-4.78,4.03-9.56,6.05-14.33
						c-2.38,5.17-5.47,9.86-8.91,14.38c3.13-4.05,6.25-8.1,9.38-12.14c-6.25,7.88-13.3,15.03-20.71,21.81
						c-10.31,9.43-20.19,19.44-23.44,33.71c-3.69,16.19-2.06,32.99,6.46,47.52c8.63,14.73,22.35,20.66,32.85,33.04
						c-3.13-4.05-6.25-8.1-9.38-12.14c0.34,0.41,0.62,0.85,0.83,1.33c-2.02-4.78-4.03-9.56-6.05-14.33
						c0.05,0.18,0.09,0.36,0.14,0.54c0-10.63,0-21.27,0-31.9c-0.02,0.15-0.05,0.29-0.07,0.44c2.02-4.78,4.03-9.56,6.05-14.33
						c-0.37,0.67-0.74,1.35-1.12,2.02c3.13-4.05,6.25-8.1,9.38-12.14c-0.83,1.24-1.83,2.34-2.99,3.28c4.05-3.13,8.1-6.25,12.14-9.38
						c-1.96,1.4-3.72,2.36-5.94,3.3c4.78-2.02,9.56-4.03,14.33-6.05c-3.14,1.02-6.22,1.66-9.48,2.1c5.32-0.71,10.63-1.43,15.95-2.14
						c-23.03,2.17-46.64,1.46-69.77,2.05c-24.5,0.62-48.99,1.24-73.49,1.86c-26.7,0.68-56.3-1.92-81.28,8.62
						c-24.18,10.2-40.33,31.1-43.83,57.12c-2.93,21.78,4.66,37.88,13.11,57.27c-2.02-4.78-4.03-9.56-6.05-14.33
						c0.97,2.49,1.72,4.8,2.29,7.41c-0.71-5.32-1.43-10.63-2.14-15.95c0.27,2.54,0.27,4.63-0.04,7.16
						c0.71-5.32,1.43-10.63,2.14-15.95c-0.28,1.53-0.7,3.02-1.28,4.46c2.02-4.78,4.03-9.56,6.05-14.33c-1.34,3-2.95,5.62-4.91,8.26
						c3.13-4.05,6.25-8.1,9.38-12.14c-4.51,5.42-9.76,9.96-15.23,14.38c-9.09,7.34-14.97,13.95-22.19,23.76
						c-14.56,19.78-21.58,47.5-15.09,71.57c8.82,32.69,38.08,56.2,72.13,56.84c17.91,0.34,32.13-7.1,49.09-9.57
						c-5.32,0.71-10.63,1.43-15.95,2.14c2.27-0.17,4.27-0.16,6.54,0.07c-5.32-0.71-10.63-1.43-15.95-2.14
						c1.45,0.22,2.85,0.61,4.21,1.16c-4.78-2.02-9.56-4.03-14.33-6.05c1.39,0.57,2.69,1.29,3.89,2.18
						c-4.05-3.13-8.1-6.25-12.14-9.38c1.16,0.87,2.21,1.85,3.15,2.96c-3.13-4.05-6.25-8.1-9.38-12.14c1.4,1.83,2.46,3.55,3.51,5.6
						c-2.02-4.78-4.03-9.56-6.05-14.33c1.87,4.45,2.91,8.94,3.6,13.72c-0.71-5.32-1.43-10.63-2.14-15.95
						c3.15,28.02,2.12,58.03-1.41,85.95c0.71-5.32,1.43-10.63,2.14-15.95c-2.26,16.41-6.01,32.43-12.43,47.74
						c2.02-4.78,4.03-9.56,6.05-14.33c-4.02,9.09-8.86,17.58-14.8,25.57c3.13-4.05,6.25-8.1,9.38-12.14
						c-6.45,8.25-13.78,15.38-22.02,21.82c4.05-3.13,8.1-6.25,12.14-9.38c-18.21,13.73-38.84,23.49-58.17,35.44
						c-25.85,15.98-49.93,36.12-64.63,63.22c-14.96,27.58-7.74,66.63,21.53,82.09c28,14.79,66.11,7.94,82.09-21.52
						c2.34-4.3,4.95-8.34,7.91-12.24c-3.13,4.05-6.25,8.1-9.38,12.14c5.57-6.92,11.93-12.89,18.92-18.36
						c-4.05,3.13-8.1,6.25-12.14,9.38c19.43-14.68,41.74-24.73,62.13-37.91c27.89-18.04,52.98-41.23,67.85-71.43
						c16.02-32.53,22.78-66.38,25.23-102.42c1.97-29.01,3.47-62.44-3.85-90.73c-8.3-32.09-33.82-57.02-66.92-61.47
						c-12.96-1.74-25.61-0.83-38.31,2.67c-6.37,1.75-12.46,4.24-18.97,5.47c5.32-0.71,10.63-1.43,15.95-2.14
						c-2.17,0.27-4.13,0.38-6.32,0.29c5.32,0.71,10.63,1.43,15.95,2.14c-2.2-0.33-3.89-0.79-5.94-1.65
						c4.78,2.02,9.56,4.03,14.33,6.05c-0.63-0.36-1.25-0.73-1.88-1.09c4.05,3.13,8.1,6.25,12.14,9.38
						c-1.21-0.81-2.28-1.76-3.22-2.86c3.13,4.05,6.25,8.1,9.38,12.14c-0.87-1.03-1.57-2.16-2.12-3.39
						c2.02,4.78,4.03,9.56,6.05,14.33c-0.53-1.22-0.91-2.49-1.12-3.8c0.71,5.32,1.43,10.63,2.14,15.95c-0.23-2.18-0.21-4.05,0-6.24
						c-0.71,5.32-1.43,10.63-2.14,15.95c0.56-3.06,1.44-5.86,2.62-8.74c-2.02,4.78-4.03,9.56-6.05,14.33
						c1.8-3.73,3.9-7.08,6.42-10.36c-3.13,4.05-6.25,8.1-9.38,12.14c4.67-5.48,10.4-9.56,15.9-14.14c9.23-7.67,17.18-16.87,23.66-27
						c8.33-13.03,12.59-29.35,12.54-44.71c-0.06-17.37-7.08-31.99-14-47.43c2.02,4.78,4.03,9.56,6.05,14.33
						c-1.3-3.13-2.29-6.07-2.77-9.43c0.71,5.32,1.43,10.63,2.14,15.95c0.01-0.76,0.02-1.53,0.03-2.29
						c-0.71,5.32-1.43,10.63-2.14,15.95c0.16-1.59,0.61-3.09,1.37-4.5c-2.02,4.78-4.03,9.56-6.05,14.33
						c0.42-0.63,0.84-1.26,1.27-1.89c-3.13,4.05-6.25,8.1-9.38,12.14c0.52-0.52,1.04-1.05,1.56-1.57c-4.05,3.13-8.1,6.25-12.14,9.38
						c1.16-0.95,2.43-1.72,3.82-2.3c-4.78,2.02-9.56,4.03-14.33,6.05c2.32-0.84,4.43-1.37,6.86-1.8
						c-5.32,0.71-10.63,1.43-15.95,2.14c13.87-1.6,28.17-1.07,42.12-1.42c15.31-0.39,30.62-0.77,45.93-1.16
						c30.01-0.76,60.01-1.52,90.02-2.28c20.04-0.51,40.51-1.73,58.39-11.9c27.27-15.51,42.83-45.09,35.41-76.42
						c-3.42-14.43-13.52-26.38-24.72-35.53c-4.97-4.07-10.79-7.81-14.82-12.87c3.13,4.05,6.25,8.1,9.38,12.14
						c-0.13-0.21-0.26-0.42-0.39-0.62c2.02,4.78,4.03,9.56,6.05,14.33c-0.07-0.18-0.14-0.36-0.21-0.53
						c0.71,5.32,1.43,10.63,2.14,15.95c0-0.15,0-0.29-0.01-0.44c-2.73,10.09-5.46,20.19-8.19,30.28c0.07-0.11,0.14-0.22,0.21-0.33
						c-3.13,4.05-6.25,8.1-9.38,12.14c34.62-30.03,66.88-70.5,55.39-119.28c-6.6-28-28.19-44.48-44.9-65.89
						c3.13,4.05,6.25,8.1,9.38,12.14c-3.22-4.27-5.98-8.74-8.15-13.64c2.02,4.78,4.03,9.56,6.05,14.33
						c-1.79-4.4-2.98-8.87-3.63-13.57c0.71,5.32,1.43,10.63,2.14,15.95c-0.45-4.3-0.37-8.42,0.15-12.71
						c-0.71,5.32-1.43,10.63-2.14,15.95c3.51-24.66,11.44-48.3,12.46-73.42c0.69-16.9-3.08-34.43-11.97-48.92
						c-14.51-23.64-38.23-31.26-62.32-41.28c4.78,2.02,9.56,4.03,14.33,6.05c-4.68-2.13-8.95-4.56-13.03-7.7
						c4.05,3.13,8.1,6.25,12.14,9.38c-2.87-2.42-5.43-5-7.83-7.87c3.13,4.05,6.25,8.1,9.38,12.14c-3.2-4.17-5.75-8.52-7.92-13.31
						c2.02,4.78,4.03,9.56,6.05,14.33c-2.66-6.46-4.38-13.07-5.45-19.97c0.71,5.32,1.43,10.63,2.14,15.95
						c-1.36-10.54-1.13-21.01,0.23-31.54c-0.71,5.32-1.43,10.63-2.14,15.95c2.23-14.99,7.05-28.91,12.91-42.83
						c-2.02,4.78-4.03,9.56-6.05,14.33c17.64-41.11,39.05-81.47,37.14-127.62c-0.89-21.39-10.89-40.79-29.72-51.81
						c-17.36-10.16-43.39-12.02-60.57,0c-21.76,15.23-44.98,27.99-69.44,38.31c4.78-2.02,9.56-4.03,14.33-6.05
						c-30.23,12.47-61.77,21-94.17,25.51c5.32-0.71,10.63-1.43,15.95-2.14c-32.42,4.24-65.09,4.37-97.52,0.15
						c5.32,0.71,10.63,1.43,15.95,2.14c-8.14-1.13-16.14-2.38-23.77-5.54c4.78,2.02,9.56,4.03,14.33,6.05
						c-2.35-1.16-4.36-2.36-6.49-3.9c4.05,3.13,8.1,6.25,12.14,9.38c-1.31-1.04-2.49-2.21-3.55-3.51c3.13,4.05,6.25,8.1,9.38,12.14
						c-1.11-1.37-2.04-2.86-2.78-4.46c2.02,4.78,4.03,9.56,6.05,14.33c-1.05-2.65-1.68-4.97-2.16-7.77
						c0.71,5.32,1.43,10.63,2.14,15.95c-0.42-4.69-0.18-9.19,0.38-13.86c-0.71,5.32-1.43,10.63-2.14,15.95
						c5.16-35.25,17.47-68.88,24.76-103.68c8.18-39.05,11.67-79.23,11.92-119.1c0.11-18.26-0.39-36.52-1.16-54.76
						c-0.85-20.31,0.44-43.89-5.39-63.36c-13.29-44.34-65.06-60.04-103.54-37.48c-18.11,10.62-32.11,28.29-43.92,45.16
						c-19.86,28.36-30.06,62.64-43.4,94.28c2.02-4.78,4.03-9.56,6.05-14.33c-6.81,15.9-14.5,31.34-24.98,45.17
						c3.13-4.05,6.25-8.1,9.38-12.14c-4.47,5.66-9.18,11.01-14.87,15.48c4.05-3.13,8.1-6.25,12.14-9.38
						c-1.54,1.14-3.16,2.12-4.88,2.96c4.78-2.02,9.56-4.03,14.33-6.05c-1.66,0.81-3.41,1.39-5.22,1.73
						c5.32-0.71,10.63-1.43,15.95-2.14c-1.82,0.34-3.65,0.36-5.48,0.06c5.32,0.71,10.63,1.43,15.95,2.14
						c-1.33-0.22-2.61-0.6-3.84-1.14c4.78,2.02,9.56,4.03,14.33,6.05c-1.15-0.51-2.22-1.17-3.21-1.95c4.05,3.13,8.1,6.25,12.14,9.38
						c-0.97-0.79-1.84-1.67-2.61-2.66c3.13,4.05,6.25,8.1,9.38,12.14c-1.17-1.66-2.05-3.22-2.9-5.07c2.02,4.78,4.03,9.56,6.05,14.33
						c-1.01-2.7-1.7-5.36-2.21-8.19c0.71,5.32,1.43,10.63,2.14,15.95c-0.8-6.02-0.71-11.95-0.04-17.98
						c-0.71,5.32-1.43,10.63-2.14,15.95c8.02-57.4,17.01-114.86,26.78-171.99c8.84-51.7,20.19-103.24,40.64-151.72
						c-2.02,4.78-4.03,9.56-6.05,14.33c8.26-19.16,17.88-37.61,29.19-55.15c16.23-25.17,7.14-59.94-15.45-77.4
						c-22.96-17.74-58.05-17.1-78.78,4.69c-23.19,24.37-46.73,48.49-73.29,69.25c4.05-3.13,8.1-6.25,12.14-9.38
						c-16.94,13.01-34.92,24.39-54.52,32.96c4.78-2.02,9.56-4.03,14.33-6.05c-13.29,5.57-27.14,10.05-41.44,12.09
						c5.32-0.71,10.63-1.43,15.95-2.14c-3.81,0.36-7.39,0.4-11.2-0.05c5.32,0.71,10.63,1.43,15.95,2.14
						c-1.79-0.29-3.52-0.78-5.19-1.48c4.78,2.02,9.56,4.03,14.33,6.05c-1.68-0.7-3.22-1.64-4.62-2.8c4.05,3.13,8.1,6.25,12.14,9.38
						c-1.25-1.04-2.34-2.22-3.28-3.55c3.13,4.05,6.25,8.1,9.38,12.14c-0.89-1.28-1.6-2.64-2.13-4.1c2.02,4.78,4.03,9.56,6.05,14.33
						c-0.54-1.47-0.91-2.98-1.12-4.54c0.71,5.32,1.43,10.63,2.14,15.95c-0.32-3.39-0.19-6.55,0.25-9.92
						c-0.71,5.32-1.43,10.63-2.14,15.95c1-5.29,2.59-10.28,4.59-15.28c-2.02,4.78-4.03,9.56-6.05,14.33
						c10.19-23.57,22-46.56,33.02-69.75c5.41-11.38,10.82-22.75,16.23-34.13c6.91-14.54,14.93-29.25,19.09-44.83
						c10.67-39.97-4.4-77.36-33.45-105.16c-19.32-18.49-42.34-32.66-63.5-48.87c4.05,3.13,8.1,6.25,12.14,9.38
						c-10.13-7.93-19.45-16.49-27.49-26.57c3.13,4.05,6.25,8.1,9.38,12.14c-4.65-6.16-8.63-12.56-11.86-19.58
						c2.02,4.78,4.03,9.56,6.05,14.33c-2.56-6.16-4.32-12.26-5.27-18.86c0.71,5.32,1.43,10.63,2.14,15.95
						c-0.44-4.55-0.51-8.95,0.02-13.49c-0.71,5.32-1.43,10.63-2.14,15.95c0.75-4.22,1.91-8.13,3.55-12.08
						c-2.02,4.78-4.03,9.56-6.05,14.33c1.85-3.88,3.98-7.41,6.54-10.86c-3.13,4.05-6.25,8.1-9.38,12.14
						c2.7-3.28,5.58-6.16,8.87-8.84c-4.05,3.13-8.1,6.25-12.14,9.38c3.34-2.44,6.73-4.41,10.52-6.04
						c-4.78,2.02-9.56,4.03-14.33,6.05c3.03-1.08,5.97-1.83,9.15-2.28c-5.32,0.71-10.63,1.43-15.95,2.14
						c2.43-0.17,4.64-0.15,7.07,0.04c-5.32-0.71-10.63-1.43-15.95-2.14c3.27,0.53,6.22,1.38,9.28,2.65
						c-4.78-2.02-9.56-4.03-14.33-6.05c2.79,1.38,5.29,2.93,7.77,4.81c-4.05-3.13-8.1-6.25-12.14-9.38c2.31,2,4.35,4.1,6.31,6.45
						c-3.13-4.05-6.25-8.1-9.38-12.14c3.35,4.46,5.94,9.16,8.21,14.24c-2.02-4.78-4.03-9.56-6.05-14.33
						c10.07,24.26,12.52,50.19,26.21,73.29c22.18,37.42,63.85,62.13,107.86,49.77c41.6-11.68,63.93-51.52,65.09-92.62
						c0.97-34.33-15.97-61.64-29.29-91.83c2.02,4.78,4.03,9.56,6.05,14.33c-3.14-7.53-5.58-15.06-6.68-23.16
						c0.71,5.32,1.43,10.63,2.14,15.95c-0.33-4.07-0.3-7.95,0.11-12.01c-0.71,5.32-1.43,10.63-2.14,15.95
						c0.67-4.16,1.72-8.04,3.23-11.97c-2.02,4.78-4.03,9.56-6.05,14.33c2.01-4.54,4.44-8.65,7.37-12.66
						c-3.13,4.05-6.25,8.1-9.38,12.14c5.33-6.75,11.53-12.46,18.27-17.76c-4.05,3.13-8.1,6.25-12.14,9.38
						c21.46-16.27,45.66-28.21,65.22-47.14c10.69-10.35,20.47-22.63,26.83-36.12c11.12-23.61,13.41-45.77,8.77-71.5
						c-2.53-14.03-15.7-28.91-27.57-35.86c-12.86-7.53-31.82-10.71-46.23-6.05c-14.52,4.7-28.53,13.7-35.86,27.57
						C349.15,316.77,348.01,331.34,351.02,348.02L351.02,348.02z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st1" d="M290.41,931.33c-2.75,2.45-5.59,4.79-8.5,7.05c4.05-3.13,8.1-6.25,12.14-9.38
						c-12.87,9.94-26.92,18.23-40.72,26.78c-14.81,9.17-29.62,18.33-44.43,27.5c-6.66,4.12-13.53,8.02-19.92,12.54
						c-0.66,0.47-1.31,0.94-1.96,1.43c-4.61,3.5-9.26,6.99-13.75,10.65c-1.24,1-2.43,2.06-3.56,3.19c-0.55,0.55-1.09,1.12-1.6,1.71
						c-3.78,4.33-7.16,9.07-10.62,13.65c-0.91,1.2-1.73,2.45-2.41,3.79c-0.15,0.3-0.3,0.61-0.44,0.92
						c-2.15,4.86-4.48,10.15-6.38,15.12c-0.26,0.69-0.5,1.39-0.68,2.1c-0.19,0.72-0.31,1.45-0.42,2.19
						c-0.78,5.44-1.76,11.16-2.22,16.64c-0.03,0.41-0.05,0.82-0.06,1.24c-0.01,0.73,0.05,1.45,0.12,2.17
						c0.4,3.77,1.57,12.4,2.17,16.11c0.03,0.16,0.05,0.32,0.08,0.49c0.06,0.37,0.14,0.75,0.24,1.12c0.17,0.68,0.39,1.35,0.64,2
						c0.95,2.49,2.49,5.92,3.15,7.47c0.72,1.71,2.04,5.04,3.15,7.46c0.16,0.34,0.32,0.68,0.49,1.02c0.33,0.62,0.69,1.23,1.09,1.81
						c0.18,0.27,0.38,0.53,0.57,0.79c3.16,4.29,6.49,8.69,9.89,12.78c0.96,1.15,2.04,2.18,3.22,3.12c4.06,3.24,8.48,6.73,12.68,9.79
						c0.27,0.2,0.55,0.39,0.83,0.58c0.66,0.44,1.33,0.85,2.03,1.23c0.6,0.32,1.21,0.61,1.84,0.89c4.83,2.13,10.05,4.38,14.96,6.31
						c0.42,0.17,0.85,0.33,1.28,0.47c1.4,0.47,2.84,0.79,4.3,1.01c5.47,0.81,11.12,1.65,16.62,2.22c0.34,0.03,0.67,0.06,1.01,0.08
						c0.79,0.04,1.58,0.05,2.36,0c0.37-0.02,0.74-0.05,1.11-0.09c5.55-0.58,11.2-1.43,16.72-2.23c0.89-0.13,1.77-0.28,2.64-0.48
						c1.61-0.38,3.18-0.91,4.71-1.54c5.37-2.2,10.86-4.28,16.07-6.84c0.8-0.4,1.59-0.83,2.36-1.28c0.67-0.39,1.32-0.81,1.96-1.24
						c4.87-3.31,9.4-7.14,14.03-10.75c2.69-2.1,5.14-4.45,7.35-7.05c0.51-0.6,1-1.21,1.48-1.82c3.63-4.63,7.34-9.24,10.78-14.02
						c4.38-6.89,6.76-14.32,7.12-22.31c1.79-7.98,1.43-15.95-1.07-23.93c-1.86-7.81-5.44-14.61-10.74-20.4
						c-4.23-6.53-9.84-11.68-16.83-15.45c-4.78-2.02-9.56-4.03-14.33-6.05c-10.63-2.86-21.27-2.86-31.9,0
						c-4.78,2.02-9.56,4.03-14.33,6.05c-9.02,5.33-16.19,12.51-21.53,21.53c-0.38,0.53-0.77,1.05-1.16,1.56
						c3.13-4.05,6.25-8.1,9.38-12.14c-2.64,3.42-5.65,6.53-9.07,9.18c4.05-3.13,8.1-6.25,12.14-9.38c-2.46,1.87-5.11,3.47-7.95,4.69
						c4.78-2.02,9.56-4.03,14.33-6.05c-2.36,0.98-4.81,1.67-7.35,2.03c5.32-0.71,10.63-1.43,15.95-2.14
						c-1.98,0.25-3.94,0.27-5.92,0.02c5.32,0.71,10.63,1.43,15.95,2.14c-2.03-0.29-4-0.83-5.9-1.61c4.78,2.02,9.56,4.03,14.33,6.05
						c-1.88-0.8-3.64-1.81-5.28-3.03c4.05,3.13,8.1,6.25,12.14,9.38c-1.49-1.16-2.83-2.47-3.99-3.96c3.13,4.05,6.25,8.1,9.38,12.14
						c-0.91-1.22-1.68-2.52-2.28-3.92c2.02,4.78,4.03,9.56,6.05,14.33c-0.53-1.31-0.9-2.66-1.1-4.07
						c0.71,5.32,1.43,10.63,2.14,15.95c-0.16-1.37-0.15-2.73,0.01-4.1c-0.71,5.32-1.43,10.63-2.14,15.95
						c0.21-1.59,0.63-3.12,1.23-4.61c-2.02,4.78-4.03,9.56-6.05,14.33c0.81-1.92,1.89-3.68,3.15-5.32
						c-3.13,4.05-6.25,8.1-9.38,12.14c2.11-2.68,4.62-4.98,7.3-7.07c-4.05,3.13-8.1,6.25-12.14,9.38
						c6.64-5.12,14.08-9.23,21.2-13.64c8.08-5,16.16-10,24.23-15c15.81-9.79,31.84-19.3,47.36-29.54c4.1-2.71,8.15-5.5,12.09-8.44
						c7.95-5.93,15.97-11.92,23.39-18.52c5.85-5.43,9.99-11.87,12.41-19.31c3.76-7.15,5.48-14.86,5.17-23.12
						c0.31-8.26-1.41-15.97-5.17-23.12c-2.42-7.44-6.56-13.88-12.41-19.31c-4.05-3.13-8.1-6.25-12.14-9.38
						c-9.36-5.43-19.45-8.16-30.28-8.19c-5.32,0.71-10.63,1.43-15.95,2.14C306.73,918.75,297.9,923.89,290.41,931.33L290.41,931.33z
						"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st1" d="M505.02,1457.18c-8.14,7.22-16.53,14.17-25.13,20.84c4.05-3.13,8.1-6.25,12.14-9.38
						c-10.58,8.18-21.5,15.91-32.72,23.19c-6.99,3.77-12.6,8.92-16.83,15.45c-5.3,5.79-8.88,12.6-10.74,20.4
						c-2.5,7.98-2.86,15.95-1.07,23.93c0.36,7.98,2.74,15.42,7.12,22.31c3.13,4.05,6.25,8.1,9.38,12.14
						c7.49,7.44,16.31,12.58,26.48,15.43c5.32,0.71,10.63,1.43,15.95,2.14c10.83-0.03,20.92-2.76,30.28-8.19
						c7.96-5.16,15.77-10.55,23.41-16.17c11.63-8.55,23.08-17.39,34.2-26.61c4.19-3.47,8.32-7.02,12.39-10.63
						c5.85-5.43,9.99-11.87,12.41-19.31c3.76-7.15,5.48-14.86,5.17-23.12c0.31-8.26-1.41-15.97-5.17-23.12
						c-2.42-7.44-6.56-13.88-12.41-19.31c-4.05-3.13-8.1-6.25-12.14-9.38c-9.36-5.43-19.45-8.16-30.28-8.19
						c-5.32,0.71-10.63,1.43-15.95,2.14C521.33,1444.6,512.51,1449.74,505.02,1457.18L505.02,1457.18z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st1" d="M426.41,2113.59c-32.2,10.32-54.05,36.28-58.54,69.68c-3.86,28.74,4.54,56.86,24.66,78.11
						c20.16,21.29,49.76,30.08,78.2,31.42c22.84,1.07,45.46-5.31,67.92-8.52c-5.32,0.71-10.63,1.43-15.95,2.14
						c8.64-1.12,17.19-1.6,25.87-0.64c-5.32-0.71-10.63-1.43-15.95-2.14c7.24,1.04,14.08,3.08,20.86,5.81
						c-4.78-2.02-9.56-4.03-14.33-6.05c11.27,4.76,21.81,10.97,32.79,16.34c13.44,6.58,27.41,12.46,41.61,17.22
						c24.53,8.24,50.66,12.28,76.4,14.03c25.69,1.75,51.37-0.95,76.68-5.32c25.38-4.39,49.01-13.18,72.53-23.52
						c26.26-11.54,49.77-28.06,71.42-46.77c-34.14-14.14-68.28-28.28-102.43-42.43c0.66,8.34,0.45,16.61-0.59,24.91
						c0.71-5.32,1.43-10.63,2.14-15.95c-1.62,11.43-4.75,22.44-9.2,33.09c2.02-4.78,4.03-9.56,6.05-14.33
						c-5.17,11.93-11.81,23.01-19.7,33.33c3.13-4.05,6.25-8.1,9.38-12.14c-8.89,11.35-19.07,21.46-30.43,30.32
						c4.05-3.13,8.1-6.25,12.14-9.38c-13.53,10.3-28.4,18.55-44.01,25.24c4.78-2.02,9.56-4.03,14.33-6.05
						c-25.7,10.74-52.86,16.98-80.4,20.7c5.32-0.71,10.63-1.43,15.95-2.14c-22.79,2.96-45.69,3.66-68.57,5.51
						c-16.32,1.32-32.73,3.22-48.77,6.46c-14.89,3.01-28.38,8.37-42.33,14.42c-25.57,11.09-46.32,29.87-62.6,52.18
						c-8.06,11.04-13.94,24.15-17.67,37.23c-4.48,15.71-4.64,32.41-6.64,48.55c0.71-5.32,1.43-10.63,2.14-15.95
						c-0.63,4.4-1.46,8.5-3.07,12.64c2.02-4.78,4.03-9.56,6.05-14.33c-1.77,4.06-4,7.73-6.68,11.26c3.13-4.05,6.25-8.1,9.38-12.14
						c-4.67,5.81-10.15,10.74-16,15.32c4.05-3.13,8.1-6.25,12.14-9.38c-18.89,14.44-38.73,27.15-57.01,42.53
						c-17.81,14.99-34.6,31.17-53,45.45c4.05-3.13,8.1-6.25,12.14-9.38c-19.12,14.61-41.91,22.73-62.71,34.57
						c-17.24,9.82-32.53,21.91-43.77,38.39c-7.41,10.85-11.4,25.07-11.46,38.16c-0.08,18.67,7.68,36.09,20.33,49.57
						c15.82,16.86,39.28,25.54,61.16,31.09c26.55,6.73,54.36,10.48,81.74,11.24c27.54,0.77,55.55-2.27,82.45-8.02
						c14.88-3.18,28.61-7.85,42.55-13.98c11.3-4.97,20.76-12.78,30.36-20.45c19.38-15.48,34.45-36.05,49.2-55.72
						c11.51-15.35,22.07-31.39,33.79-46.58c-3.13,4.05-6.25,8.1-9.38,12.14c0.92-1.17,1.78-2.16,2.91-3.13
						c-4.05,3.13-8.1,6.25-12.14,9.38c0.67-0.5,1.41-0.89,2.2-1.16c-4.78,2.02-9.56,4.03-14.33,6.05c0.38-0.1,0.77-0.19,1.15-0.29
						c-5.32,0.71-10.63,1.43-15.95,2.14c0.79-0.13,1.59-0.15,2.39-0.06c-5.32-0.71-10.63-1.43-15.95-2.14
						c1.81,0.28,3.43,0.8,5.13,1.49c-4.78-2.02-9.56-4.03-14.33-6.05c4.92,2.25,9.53,5.11,14.48,7.31c6.66,2.95,13.4,6.46,20.62,7.8
						c3.1,0.57,6.18,0.99,9.25,1.77c4.85,1.22,9.5,2.98,14.22,4.61c8.43,2.91,17.79,4.42,26.61,4.74
						c19.96,0.74,40.33-6.92,56.23-18.67c9.01-6.66,17.44-13.09,24.72-21.52c7.41-8.58,14.07-17.79,20.98-26.77
						c-3.13,4.05-6.25,8.1-9.38,12.14c6.01-7.76,12.19-15.26,19.89-21.42c-4.05,3.13-8.1,6.25-12.14,9.38
						c11.77-8.84,24.73-15.91,38.21-21.76c-4.78,2.02-9.56,4.03-14.33,6.05c22.57-9.52,46.31-15.19,70.53-18.49
						c-5.32,0.71-10.63,1.43-15.95,2.14c46.72-6.05,94.03-3.89,141.01-4.51c47.91-0.63,95.82-2.01,143.73-3.11
						c48.46-1.12,96.93-2.23,145.39-3.35c47.13-1.09,94.1-2.41,140.61-11.01c27.28-5.04,53.28-14.1,78.3-26.11
						c13.79-6.62,26.48-14.76,40.6-20.75c-4.78,2.02-9.56,4.03-14.33,6.05c27.02-11.36,54.64-21.3,81.13-33.9
						c26.08-12.4,53.48-29.66,67.91-55.59c14.89-26.77,16.01-61.88,0.39-88.69c-10.92-18.74-29.27-30.27-49.92-35.61
						c-24.91-6.43-52.07-7.47-77.74-8.05c-11.96-0.27-23.93-0.27-35.89,0c-9.16,0.21-18.72,1.54-27.82,0.33
						c5.32,0.71,10.63,1.43,15.95,2.14c-4.1-0.7-7.96-1.8-11.83-3.33c4.78,2.02,9.56,4.03,14.33,6.05
						c-4.37-1.9-8.39-4.24-12.23-7.06c4.05,3.13,8.1,6.25,12.14,9.38c-3.76-2.95-7.09-6.2-10.12-9.9c3.13,4.05,6.25,8.1,9.38,12.14
						c-2.9-3.77-5.29-7.73-7.24-12.07c2.02,4.78,4.03,9.56,6.05,14.33c-1.57-3.86-2.68-7.71-3.37-11.82
						c0.71,5.32,1.43,10.63,2.14,15.95c-0.38-2.98-0.54-5.82-0.2-8.8c-0.71,5.32-1.43,10.63-2.14,15.95
						c0.54-3.93,1.72-7.67,3.25-11.32c-2.02,4.78-4.03,9.56-6.05,14.33c3.61-8.31,8.78-15.83,14.27-22.99
						c-3.13,4.05-6.25,8.1-9.38,12.14c24.25-31.32,54.38-57.87,81.19-86.93c34.1-36.96,66.55-77.59,81.93-126.22
						c9.14-28.91,10.03-61.57,1.37-90.7c-4.35-14.66-14.29-28.08-27.57-35.86c-12.86-7.53-31.82-10.71-46.23-6.05
						c-14.52,4.7-28.53,13.7-35.86,27.57c-7.46,14.13-10.71,30.54-6.05,46.23c1.44,4.85,2.5,9.76,3.18,14.78
						c-0.71-5.32-1.43-10.63-2.14-15.95c0.99,8.18,0.9,16.37-0.14,24.54c0.71-5.32,1.43-10.63,2.14-15.95
						c-1.52,10.9-4.66,21.41-8.87,31.56c2.02-4.78,4.03-9.56,6.05-14.33c-7.93,18.71-19.13,35.76-31.47,51.83
						c3.13-4.05,6.25-8.1,9.38-12.14c-37.1,47.95-84.41,86.66-121.16,134.91c-20.81,27.32-31.37,60.38-22.29,94.53
						c7.88,29.63,30.54,58.35,58.64,71.17c17.06,7.78,34.38,13.36,53.49,12.26c3.45-0.2,6.89-0.4,10.34-0.52
						c29.71-1.04,59.99-0.96,89.51,2.92c-5.32-0.71-10.63-1.43-15.95-2.14c7.52,1.03,15.25,2.22,22.3,5.15
						c-4.78-2.02-9.56-4.03-14.33-6.05c1.39,0.62,2.7,1.3,3.94,2.19c-4.05-3.13-8.1-6.25-12.14-9.38c0.67,0.53,1.26,1.09,1.79,1.76
						c-3.13-4.05-6.25-8.1-9.38-12.14c0.79,1.1,1.46,2.28,2,3.53c-2.02-4.78-4.03-9.56-6.05-14.33c0.75,1.97,1.25,3.79,1.66,5.86
						c-0.71-5.32-1.43-10.63-2.14-15.95c0.47,3.74,0.54,7.45,0.17,11.21c0.71-5.32,1.43-10.63,2.14-15.95
						c-0.32,2.36-0.96,4.57-1.88,6.77c2.02-4.78,4.03-9.56,6.05-14.33c-1.07,2.32-2.37,4.44-3.88,6.5c3.13-4.05,6.25-8.1,9.38-12.14
						c-3.39,4.39-7.46,8.09-11.83,11.48c4.05-3.13,8.1-6.25,12.14-9.38c-15.47,11.71-33.59,19.7-51.37,27.22
						c4.78-2.02,9.56-4.03,14.33-6.05c-20.68,8.69-41.64,16.68-62.27,25.49c-16.8,7.17-31.98,17.22-48.74,24.44
						c4.78-2.02,9.56-4.03,14.33-6.05c-23.8,9.97-48.93,15.69-74.44,19.14c5.32-0.71,10.63-1.43,15.95-2.14
						c-43.29,5.63-87.17,5.41-130.74,6.41c-48.42,1.12-96.84,2.23-145.26,3.35c-47.93,1.1-95.88,2.58-143.83,2.75
						c-51.43,0.19-102.6-0.15-153.3,10.01c-27.82,5.58-55.32,15.63-80.23,29.17c-13.72,7.45-27.22,16.69-38.55,27.38
						c-11.03,10.41-19.7,23.44-28.91,35.36c3.13-4.05,6.25-8.1,9.38-12.14c-5.62,7.21-11.46,14.14-18.59,19.92
						c4.05-3.13,8.1-6.25,12.14-9.38c-4.71,3.58-9.62,6.82-15.08,9.12c4.78-2.02,9.56-4.03,14.33-6.05
						c-2.23,0.83-4.42,1.45-6.76,1.82c5.32-0.71,10.63-1.43,15.95-2.14c-2.05,0.21-4.01,0.21-6.06,0.01
						c5.32,0.71,10.63,1.43,15.95,2.14c-5.82-0.85-11.21-3.2-16.75-5.04c-7.36-2.44-15.28-2.86-22.48-5.57
						c4.78,2.02,9.56,4.03,14.33,6.05c-16.85-7.23-31.28-16.78-50.5-17.01c-17.47-0.21-35.72,7.13-48.09,19.56
						c-6.1,6.12-10.98,13.64-16.15,20.61c-11.46,15.47-22.18,31.47-33.93,46.74c3.13-4.05,6.25-8.1,9.38-12.14
						c-10.88,14.01-22.51,27.71-36.58,38.63c4.05-3.13,8.1-6.25,12.14-9.38c-5.29,3.98-10.84,7.46-16.93,10.07
						c4.78-2.02,9.56-4.03,14.33-6.05c-15.34,6.13-32.3,9.21-48.6,11.45c5.32-0.71,10.63-1.43,15.95-2.14
						c-26.04,3.36-52.3,3.15-78.32-0.26c5.32,0.71,10.63,1.43,15.95,2.14c-18.19-2.52-36.83-5.99-53.9-12.96
						c4.78,2.02,9.56,4.03,14.33,6.05c-3.98-1.71-7.83-3.62-11.3-6.25c4.05,3.13,8.1,6.25,12.14,9.38
						c-1.31-1.06-2.49-2.19-3.54-3.51c3.13,4.05,6.25,8.1,9.38,12.14c-0.55-0.76-1.02-1.53-1.43-2.37
						c2.02,4.78,4.03,9.56,6.05,14.33c-0.33-0.79-0.57-1.55-0.74-2.39c0.71,5.32,1.43,10.63,2.14,15.95
						c-0.14-1.02-0.14-1.98-0.01-3.01c-0.71,5.32-1.43,10.63-2.14,15.95c0.16-0.84,0.38-1.62,0.67-2.42
						c-2.02,4.78-4.03,9.56-6.05,14.33c0.65-1.54,1.49-2.91,2.48-4.25c-3.13,4.05-6.25,8.1-9.38,12.14c2.18-2.77,4.78-5.1,7.53-7.29
						c-4.05,3.13-8.1,6.25-12.14,9.38c17.64-13.59,39.07-21.55,58.62-31.88c16.52-8.73,30.97-21.16,45.16-33.19
						c16.89-14.31,32.9-29.62,50.34-43.28c-4.05,3.13-8.1,6.25-12.14,9.38c18.42-14.22,39.34-25.63,55.87-42.2
						c13.07-13.1,22.34-28.09,28.29-45.54c5.25-15.38,4.84-32.44,6.87-48.49c-0.71,5.32-1.43,10.63-2.14,15.95
						c0.92-6.27,2.35-12.29,4.69-18.19c-2.02,4.78-4.03,9.56-6.05,14.33c2.44-5.69,5.5-10.92,9.19-15.9
						c-3.13,4.05-6.25,8.1-9.38,12.14c4.62-5.93,9.88-11.14,15.74-15.84c-4.05,3.13-8.1,6.25-12.14,9.38
						c8.07-6.22,16.83-11.21,26.21-15.18c-4.78,2.02-9.56,4.03-14.33,6.05c13.18-5.34,27.04-8.36,41.09-10.34
						c-5.32,0.71-10.63,1.43-15.95,2.14c26.84-3.53,53.99-3.52,80.87-6.68c30.05-3.53,60.05-8.1,88.7-18.17
						c57.17-20.1,109.7-56.06,138.8-110.66c16.07-30.15,26.12-65.18,23.4-99.59c-0.83-10.49-2.8-21.07-8.19-30.28
						c-6.6-11.28-16.79-19.53-28.69-24.55c-10.21-4.31-20.12-5.57-31.09-4.1c-13.72,1.84-23.88,7.36-34.45,16.5
						c-3.37,2.91-6.8,5.72-10.31,8.46c4.05-3.13,8.1-6.25,12.14-9.38c-16.15,12.35-33.76,22.48-52.46,30.44
						c4.78-2.02,9.56-4.03,14.33-6.05c-20.56,8.56-42.07,14.35-64.13,17.42c5.32-0.71,10.63-1.43,15.95-2.14
						c-22.71,2.98-45.63,3.02-68.34-0.02c5.32,0.71,10.63,1.43,15.95,2.14c-20.76-2.94-40.98-8.41-60.3-16.57
						c4.78,2.02,9.56,4.03,14.33,6.05c-23.62-10.2-45.46-25.41-70.75-31.53c-15.85-3.84-32.13-5.04-48.27-3.07
						c-16.67,2.04-33.03,5.41-49.64,7.71c5.32-0.71,10.63-1.43,15.95-2.14c-9.06,1.13-18.09,1.43-27.16,0.24
						c5.32,0.71,10.63,1.43,15.95,2.14c-5.03-0.8-9.83-2.06-14.55-3.98c4.78,2.02,9.56,4.03,14.33,6.05
						c-3.44-1.56-6.6-3.4-9.64-5.66c4.05,3.13,8.1,6.25,12.14,9.38c-2.11-1.72-4-3.51-5.72-5.61c3.13,4.05,6.25,8.1,9.38,12.14
						c-1.7-2.33-3.05-4.74-4.18-7.39c2.02,4.78,4.03,9.56,6.05,14.33c-1.02-2.71-1.72-5.43-2.17-8.29
						c0.71,5.32,1.43,10.63,2.14,15.95c-0.39-3.33-0.39-6.55,0.03-9.88c-0.71,5.32-1.43,10.63-2.14,15.95
						c0.46-2.73,1.17-5.29,2.24-7.84c-2.02,4.78-4.03,9.56-6.05,14.33c0.77-1.6,1.62-3.08,2.64-4.54c-3.13,4.05-6.25,8.1-9.38,12.14
						c1.09-1.38,2.24-2.59,3.57-3.74c-4.05,3.13-8.1,6.25-12.14,9.38c1.43-1.09,2.88-1.97,4.5-2.74c-4.78,2.02-9.56,4.03-14.33,6.05
						c0.85-0.37,1.71-0.68,2.6-0.93c14.76-4.73,27.92-14,35.86-27.57c7.53-12.86,10.71-31.81,6.05-46.23
						c-4.7-14.52-13.7-28.53-27.57-35.86C458.7,2112.27,441.88,2108.63,426.41,2113.59L426.41,2113.59z"/>
				</g>
			</g>
			<g id="drop_shadow_00000001632907059364786660000004660940840804402088_">
				<path className="st2" d="M413.62,2687.56c-2.86-0.1-5.52-1.47-7.26-3.74c-2.47-3.02-4.76-6.17-6.87-9.44
					c-7.69-11.37-17.29-25.52-31.3-25.52c-3.88,0.07-7.68,1.09-11.07,2.98c-3.11,1.91-5.97,4.2-8.52,6.83
					c-4.04,3.78-8.65,8.06-12.38,8.06c-1.41-0.06-2.76-0.6-3.83-1.53c-1.83-1.32-2.78-3.55-2.46-5.79c0.65-7,8.65-15.69,13.77-18.37
					c6.65-3.23,13.63-5.74,20.81-7.5c9.62-2.72,19.45-5.55,28.66-11.84c17.29-11.95,29.7-24.12,36.89-36.18
					c2.88-5.11,5.32-10.45,7.31-15.97c3.22-8.19,6.27-15.93,12.3-21.94c5.49-5.1,11.48-9.64,17.87-13.55
					c3.89-2.57,7.93-5.25,11.74-8.17c7.59-5.84,12.82-13.38,17.87-20.68c3.11-4.74,6.55-9.25,10.31-13.49
					c8.8-9.49,17.87-19.3,17.59-37.2c0-2.31-0.26-4.95-0.48-7.72c-0.71-9.29-1.62-20.83,3.35-25.94c2.02-1.96,4.79-2.95,7.59-2.7
					c2.78,0.09,5.54,0.39,8.28,0.89c3.3,0.59,6.64,0.93,9.99,1.02c5.39,0.23,10.66-1.65,14.7-5.23c7.8-7.09,8.8-18.35,9.6-27.38
					c0.11-1.12,0.19-2.16,0.3-3.26c1.36-13.23,13.18-20.55,37.2-23.08l9.86-1.06c12.97-1.45,26.39-2.94,39.55-3.11h0.78
					c11.07,0,21.61,2.96,32.74,6.12c5.4,1.53,10.96,3.09,16.56,4.32c3.96,0.81,8,1.21,12.04,1.19c6.39-0.15,12.76-0.75,19.06-1.82
					c5.96-1.02,11.98-1.61,18.02-1.75h2.01h2.79c14.93,0,17.74-6.27,21.83-18.26c3.78-11.17,8.37-22.06,13.72-32.57
					c5.99-11.8,14.29-18.89,23.88-27.08c2.16-1.77,4.19-3.59,6.38-5.51c10.81-9.49,19.3-20.94,27.6-32.05l2.16-3
					c1.06-1.4,2.16-2.79,3.5-4.32c2.85-3.07,5.29-6.49,7.26-10.18c1.25-2.59,2.68-6.38,0.71-9.51c-1.22-1.86-3.33-2.95-5.55-2.85
					c-4.5,0-10.44,3.65-12.97,6.27c-3.09,3.16-6.12,6.48-9.03,9.79c-8.17,9.06-16.6,18.44-27.12,24.16
					c-5.29,2.96-11.23,4.53-17.29,4.58c-1.45,0-2.89-0.1-4.32-0.3l-0.99-0.13c-2.56-0.41-5.14-0.65-7.74-0.71
					c-4.51-0.08-8.93,1.33-12.56,4.02c-5.93,4.53-11.34,9.69-16.14,15.39c-2.35,2.86-4.28,6.04-5.75,9.44
					c-1.2,2.86-2.82,5.52-4.82,7.89c-5.12,5.4-12.73,7.91-23.93,7.91c-2.31,0-4.82,0-7.67-0.32l-3.18-0.24
					c-8.43-0.58-17.18-1.21-24.25-5.04c-12.25-6.64-20.19-19.15-27.88-31.23l-1.45-2.29c-5.25-8.23-12.45-19.45-24.18-21.89
					c-1.34-0.28-2.7-0.42-4.06-0.41c-7.82,0-14.63,4.32-20.64,8.19c-1.51,0.97-2.98,1.92-4.32,2.74
					c-7.93,4.48-16.83,6.98-25.94,7.28c-3.05,0.09-6.08-0.46-8.9-1.62c-1.76-0.82-3.11-2.3-3.76-4.13c-0.91-2.5-0.82-5.26,0.26-7.69
					c0.95-2.16,4.75-2.74,8.13-3.35c1.19-0.19,2.31-0.41,3.33-0.65c0.11,0,10.81-2.79,12.28-9.73c0.59-3.18-0.86-6.39-3.63-8.06
					c-1.6-1.13-3.53-1.69-5.49-1.6c-1.45,0.03-2.9,0.17-4.32,0.43l-1.49,0.22c-2.16,0.19-4.73,0.41-7.24,0.41
					c-4.09,0.3-8.17-0.72-11.63-2.92c-4.32-3.13-5.19-6.64-3.61-12.97l0.22-0.8c0.93-3.67,3.46-13.42-5.42-15.78
					c-0.85-0.22-1.73-0.34-2.62-0.35c-6.16,0-10.63,5.3-12.28,7.56c-1.35,1.99-2.19,4.28-2.44,6.68c-0.35,2.16-0.52,2.59-1.32,3.22
					c-2.37,1.31-5.08,1.89-7.78,1.69h-0.28c-3.23-0.1-6.44-0.45-9.62-1.06c-3.62-0.67-7.28-1.06-10.96-1.15h-0.35
					c-5.07-0.25-9.61,3.15-10.81,8.08c-0.48,2.13-0.64,4.31-0.45,6.48c0.11,1.14,0.11,2.29,0,3.44c-0.5,2.98-1.34,3.22-3.78,3.22
					c-1.21,0-2.62-0.15-4.08-0.3c-1.7-0.21-3.41-0.33-5.12-0.37c-1.16-0.01-2.32,0.1-3.46,0.3c-5.75,1.15-7.82,5.77-9.34,9.16
					c-0.48,1.24-1.11,2.42-1.88,3.5c-2.29,2.61-5.66,4.02-9.12,3.8c-3.53-0.08-7-0.9-10.2-2.4c-4.44-1.93-7.99-5.47-9.94-9.9
					c-0.67-1.47-1.19-2.94-1.71-4.32c-1.49-4.32-3.18-9.08-9.03-12.56c-1.49-0.89-5.45-3.22-5.68-4.52c0-0.43,0.28-1.56,1.58-3.26
					l0.5-0.65c5.64-7.37,11.5-15.89,9.7-23.64c-0.9-3.65-3.44-6.67-6.87-8.19c-4.26-1.87-8.9-2.72-13.55-2.49h-5.12
					c-5.97,0-7.61-0.8-8.02-1.1c0.2-1.18,0.65-2.3,1.34-3.29c2.49-4.13,7.28-6.27,12.36-8.52c1.77-0.78,3.63-1.6,5.38-2.53
					c4.22-1.92,7.87-4.9,10.59-8.65c1.33-2.22,2.31-4.63,2.9-7.15c0.4-1.83,1.13-3.58,2.16-5.14c0.85-1.15,2.25-1.77,3.67-1.64
					c2.53,0.18,5.03,0.68,7.43,1.47c3.22,1.05,6.56,1.66,9.94,1.82c1.26,0,2.51-0.14,3.74-0.43c6.87-1.71,16.94-4.75,25.03-11.43
					c2.86-2.54,5.38-5.45,7.48-8.65c2.55-3.61,4.73-6.72,8.21-8.65c4.91-2.22,10.15-3.61,15.52-4.11c4.14-0.52,8.23-1.37,12.23-2.53
					c8.95-2.92,17.01-8.08,23.41-14.98c3.26-3.39,7.8-5.21,12.6-7.13c4.44-1.55,8.65-3.69,12.51-6.35
					c15.13-11.28,27.28-29.85,33.41-50.92c4.86-16.81,3.89-31.79-2.94-44.54c-6.48-12.02-7.89-19.6,1.99-27.77
					c2.8-2.02,4.52-5.2,4.69-8.65c0-5.58-4.58-9.7-8.65-13.33c-1.6-1.27-3.05-2.72-4.32-4.32c-2.68-4.13-2.16-9.29-1.32-15.26
					c1.02-5.22,0.95-10.6-0.22-15.8c-2.55-8.97-10.53-14.13-21.85-14.13c-1.65,0.01-3.31,0.11-4.95,0.3
					c-12.62,1.56-42.21,5.17-40.68,29.74c0,1.17,0.3,2.31,0.5,3.44c0,0.19,0,0.41,0.13,0.67c-1.84-0.77-3.59-1.71-5.25-2.81
					c-3.19-2.2-6.77-3.77-10.55-4.63c-5.08-0.89-10.3-0.39-15.13,1.43l-2.01,0.84c-1.01,0.46-2.05,0.84-3.11,1.15
					c-0.39,0.09-0.79,0.14-1.19,0.15h-0.26c0.8-2.01,4.32-7.91,20.77-21.61l0.93-0.78c3.93-3.37,8.17-6.48,12.25-9.7
					c10.5-8.02,21.33-16.27,29.07-28.1c4.58-7.56,8.21-15.67,10.81-24.12c0.59-1.71,1.2-3.42,1.82-5.12c1.12-2.45,1-5.28-0.3-7.63
					c-1.27-1.73-3.32-2.71-5.47-2.59c-2.84,0.23-5.62,0.89-8.26,1.97c-1.5,0.58-3.06,1.02-4.65,1.32
					c-11.02-0.84-19.75-10.35-23.77-18.22c-2.53-4.86-1.86-10.16-1.08-16.3c0.78-6.14,1.6-12.41-0.84-18.87
					c-0.41-1.08-0.89-2.16-1.36-3.13c-1.47-3.11-1.77-4.04-1.04-5.49c1.71-3.44,5.73-4.91,13.42-4.91c2.36,0,4.82,0.13,7.2,0.26
					c2.38,0.13,4.63,0.24,6.76,0.24c1.76,0.02,3.51-0.09,5.25-0.35c0.85-0.15,1.71-0.23,2.57-0.24c5.66,0,9.55,3.54,13.4,12.19
					c0.37,0.8,0.73,1.66,1.1,2.55c2.16,5.34,5.01,12,10.98,15.28c1.45,0.77,3.07,1.15,4.71,1.12c3.07-0.2,6.09-0.84,8.97-1.9
					c2.09-0.78,4.27-1.3,6.48-1.53c0.19-0.02,0.38-0.02,0.56,0c0,0,0,0.28,0.17,0.71c0,1.15-3.63,4.06-5.36,5.47
					c-2.6,1.84-4.81,4.18-6.48,6.89c-1.42,2.41-1.42,5.41,0,7.82c1.67,2.42,4.5,3.78,7.43,3.59c1.47-0.01,2.93-0.27,4.32-0.76
					c3-1.19,5.79-2.88,8.23-4.99c1.74-1.55,3.73-2.8,5.88-3.7c2.11-0.61,4.29-0.99,6.48-1.12c3.79-0.16,7.51-1.03,10.98-2.55
					c5.14-2.53,6.48-7.93,7.48-12.28c0.31-1.87,0.93-3.67,1.82-5.34c0.37-0.62,1.06-0.97,1.77-0.91c2.64,0,6.48,2.38,8.9,3.83
					c8.24,4.72,17.61,7.11,27.1,6.89h1.66c8.65-0.22,16.47-3.41,24.01-6.48c5.21-2.38,10.66-4.19,16.25-5.4
					c1.59-0.28,3.2-0.42,4.82-0.43c3.82-0.14,7.56,1.06,10.59,3.39c2.28,2.32,4.02,5.11,5.12,8.17c1.57,3.58,3.76,6.84,6.48,9.64
					c2.62,2.44,6.08,3.79,9.66,3.76c6.09,0.05,11.6-3.6,13.92-9.23c3.85-9.86-3.41-17.03-8.65-22.26c-1.27-1.2-2.47-2.46-3.61-3.78
					c-2.79-3.29-3.63-5.34-3.07-7.28c0.97-3.37,2.16-5.1,3.76-5.1c1.12,0,5.47,0.76,14.18,10.37c2.92,3.22,5.73,6.7,8.45,10.05
					c1.86,2.29,3.72,4.58,5.62,6.83c1.28,1.51,7.98,9.01,14.18,9.01c3.86,0.03,7.08-2.97,7.31-6.83c0.3-5.27-4.69-10.81-12.25-17.57
					l-1.06-0.97c-4.29-4.48-7.8-9.65-10.4-15.28c-1.77-3.31-3.59-6.72-5.79-10.03l-2.51-3.59c-4.17-5.97-8.13-11.58-8.39-18.11
					c-0.52-12.69,7.35-24.64,12.08-30.63c3-3.77,6.58-7.04,10.59-9.7c3.67-2.49,7.8-5.94,7.2-10.44s-5.58-6.48-10.37-8.19
					c-1.92-0.56-3.8-1.28-5.6-2.16c-5.88-3.33-7.13-10.44-4.02-23.04l0.5-2.03c1.77-7.37,3.76-15.71,9.49-19.65
					c1.44-0.94,2.95-1.79,4.5-2.55c4.58-2.36,10.29-5.3,12.54-11.3c1.4-4.57,1.65-9.42,0.73-14.11c-0.57-3.06-0.57-6.19,0-9.25
					c0.91-2.68,2.22-5.21,3.89-7.5c1.83-2.61,3.34-5.44,4.5-8.41c2.74-7.72,0.99-18.76-2.29-24.98c-2.77-5.21-6.48-6.31-8.95-6.31
					c-3.47,0.36-6.76,1.74-9.44,3.98c-1.16,0.81-2.38,1.53-3.65,2.16c-0.55,0.24-1.15,0.35-1.75,0.35c-1.97-0.2-3.88-0.77-5.64-1.66
					c-2.51-1.26-5.26-1.99-8.06-2.16c-3.25,0.02-6.32,1.47-8.41,3.96c-3.85,4.72-6.93,10.03-9.12,15.71
					c-1.86,5.03-4.58,9.69-8.04,13.79c-0.26,0.26-0.73,0.71-1.12,0.71c-2.66,0-7.87-7.82-8.99-12.62c-0.5-2.16,0-2.81,2.59-5.55
					c0.78-0.8,1.56-1.62,2.31-2.55c3.84-4.51,6.73-9.75,8.49-15.41c1.15-3.14,0.75-6.63-1.08-9.42c-1.39-1.79-3.53-2.81-5.79-2.77
					c-3.66,0.28-7.07,1.99-9.49,4.75c-1.36,1.73-3.36,2.85-5.55,3.09c-3.89,0-8.82-5.36-12.86-13.98
					c-2.15-4.96-3.87-10.09-5.17-15.34c-2.68-9.66-5.45-19.62-12.97-27.4l-1.95-2.01c-6.12-6.35-12.45-12.97-15.65-20.75
					c-3.2-7.78-1.1-12.51,2.81-20.62l0.67-1.43c2.14-5.13,3.21-10.63,3.16-16.19c0.11-2.01,0.22-3.91,0.43-5.77
					c0.65-4.85,2.78-9.37,6.09-12.97c1.45-1.82,3.03-3.54,4.67-5.38c3.53-3.63,6.64-7.64,9.27-11.97
					c2.94-5.19,2.16-10.27,1.32-14.33c-0.62-2.36-0.72-4.82-0.28-7.22c1.32-4.54,4.71-8.95,8.02-13.23
					c1.47-1.92,3.03-3.91,4.32-5.94l0.78-1.17c2.46-3.83,5.13-7.52,8.02-11.04c0.69-0.68,1.41-1.33,2.16-1.95
					c4.5-3.83,8.65-7.43,7.61-11.82c-1.04-4.39-5.66-6.48-13.57-6.48c-4.32,0-10.01,0.71-12.66,2.31c-1.95,1.2-3.73,2.66-5.3,4.32
					c-1.39,1.64-3.2,2.89-5.23,3.61c-3.21,0.81-6.5,1.25-9.81,1.32c-1.23,0-2.49,0.15-3.7,0.26c-5.21,0.43-11.93,3.24-13.79,9.73
					c-0.55,2.57-0.44,5.24,0.32,7.76c0.13,0.63,0.32,1.45,0.41,2.16H682h-1.08c-1.53-0.3-2.99-0.87-4.32-1.69
					c-2.2-1.3-4.63-2.17-7.15-2.55h-1.32c-2.62,0.12-5.19,0.78-7.54,1.95c-0.73,0.3-1.49,0.63-2.16,0.89l-2.79,0.91
					c-7.09,2.29-15.13,4.88-17.1,15.45c-1.08,5.81-9.55,10.57-16.79,11.65c-0.71,0.12-1.44,0.18-2.16,0.17
					c-2.62-0.18-5.18-0.92-7.5-2.16c-1.43-0.61-2.96-0.92-4.52-0.93c-2.44,0.1-4.81,0.84-6.87,2.16c-1.04,0.71-2.25,1.15-3.5,1.28
					c-4.69-0.13-5.88-2.57-8.08-9.51l-0.71-2.16c-1.06-3.35-2.68-8.39-6.66-10.81c-2.5-1.4-5.34-2.09-8.21-1.99
					c-1.51,0-2.98,0.11-4.32,0.24s-2.51,0.22-3.61,0.22c-0.72,0.01-1.45-0.05-2.16-0.17c-3.01-0.71-5.91-1.8-8.65-3.24l-1.49-0.76
					c-2.86-1.8-6.09-2.92-9.44-3.29c-2.06-0.05-4.04,0.84-5.36,2.42c-2.16,2.7-1.75,6.48-1.23,8.93c1.25,6.03,6.09,8.65,10.01,10.81
					c1.39,0.7,2.72,1.53,3.96,2.49c1.77,1.51,1.34,3.85-0.76,9.64c-0.83,2.11-1.49,4.27-1.97,6.48c-0.33,2.88-0.28,5.78,0.17,8.65
					c0.24,2.27,0.78,7.61-0.11,8.65l0,0c-0.3,0.11-0.61,0.15-0.93,0.13c-6.59-0.13-13.29-6.48-18.69-11.69
					c-0.99-0.97-1.97-1.88-2.9-2.74c-3-2.77-6.03-5.81-8.97-8.65c-8.93-8.97-18.15-18.24-30.26-23.77
					c-3.86-1.83-8.05-2.82-12.32-2.92c-4.77-0.09-9.4,1.62-12.97,4.78c-11.84,10.4-6.48,24.36-2.03,35.57
					c2.33,5.97,4.52,11.58,4.32,16.14c-0.14,1.78-0.9,3.46-2.16,4.73l0,0c-3.57-2.85-4.6-7.05-5.84-11.89
					c-0.66-2.96-1.57-5.85-2.74-8.65c-6.06-13.09-13.13-25.68-21.16-37.67c-0.76-1.19-1.58-2.36-2.38-3.54
					c-2.76-3.64-4.98-7.65-6.61-11.91c-2.83-9.23-4.32-21.14-1.56-25.18c0.47-0.74,1.28-1.19,2.16-1.19h0.41
					c1.08,0,3.07,0,4.19,7.26c0.96,7.07,3.12,13.92,6.38,20.27c2.01,3.61,4.43,5.45,7.22,5.45c1.92,0,5.4-0.95,7.05-7.2
					c1.87-6.68,2.12-13.71,0.71-20.51c-0.57-2.09-1.25-4.14-2.03-6.16c-1.09-2.6-1.82-5.35-2.16-8.15
					c-0.13-1.19-0.17-2.38-0.19-3.54c-0.15-15.63,9.14-28.98,18.13-41.86l1.21-1.77c5.3-7.15,9.8-14.85,13.42-22.97
					c1.75-4.46,3.25-9.01,4.5-13.64c1.21-4.57,2.71-9.06,4.47-13.44c1.45-3.37,3.16-6.79,4.8-10.09c2.52-4.79,4.77-9.72,6.72-14.76
					c2.83-7.83,5.21-15.81,7.13-23.9c3.76-15.56-6.2-22.97-14.98-29.5c-2.38-1.77-4.84-3.61-7-5.58
					c-9.38-8.54-11.82-19.84-12.97-29.76c-1.14-10.29-1.65-20.64-1.53-30.99c0-3.48,0.24-6.92,0.48-10.57
					c0.47-5.48,0.57-10.98,0.32-16.47c-0.21-1.06-0.05-2.16,0.45-3.11c0.15-0.17,0.86-0.71,3.61-0.71c1.3,0,2.74,0.11,4.52,0.28
					c3.44,0.32,6.94,0.8,10.33,1.25c6.06,0.93,12.16,1.51,18.28,1.75h1.08c0.95,0,2.16,0,3.37,0c3.57,0,7.5-0.35,9.62-2.9
					c1.31-1.48,1.74-3.55,1.1-5.42c-1.25-3.65-5.71-5.32-13.51-7.07l-1.21-0.5c-2.87-0.86-5.71-1.9-8.65-2.98
					c-6.19-2.52-12.65-4.3-19.26-5.3c-1.15-0.13-2.3-0.19-3.46-0.19c-5.46,0.1-10.84,1.35-15.78,3.67c-0.93,0.41-2.16,0.8-3.29,1.25
					c-5.51,1.99-12.97,4.73-15.13,11.02c-1.79,5.49-0.43,10.81,0.76,15.5c0.71,2.41,1.15,4.89,1.32,7.39
					c-0.25,6.13-1.43,12.19-3.5,17.96c-1.45,4.45-2.58,8.99-3.39,13.59c-0.19,1.28-0.26,2.53-0.35,3.74
					c-0.05,1.11-0.16,2.21-0.32,3.31c-0.64-0.14-1.27-0.33-1.88-0.58c-0.56-0.24-0.8-0.67-0.89-4.15c-0.09-3.48-0.17-7.72-3.8-10.81
					c-2.96-2.04-6.36-3.38-9.92-3.91c-1.19-0.28-2.31-0.54-3.13-0.8l-0.35-0.11c-3.2-0.69-6.17-2.18-8.65-4.32
					c-0.82-0.89-1.64-1.92-2.51-3.03c-2.42-3.63-5.71-6.6-9.57-8.65c-1.92-0.77-3.98-1.19-6.05-1.21c-3.01-0.34-5.86,1.46-6.85,4.32
					c-1.49,4.54,2.92,7.87,5.55,9.86c0.33,0.23,0.64,0.48,0.93,0.76c2.63,2.48,4.62,5.56,5.77,8.99c1.4,4.6,0,6.14-9.23,9.68
					c-4.27,1.31-8.2,3.52-11.54,6.48c-3.33,3.94-5.9,8.47-7.56,13.36c-0.35,0.84-0.69,1.71-1.06,2.55l-1.21,2.85
					c-1.95,4.47-3.93,9.1-5.53,13.94c-7.09,21.61-13.94,47.66-10.81,77.16c0.56,5.53-0.35,7.67-4.84,11.41l-0.78,0.63
					c-6.03,5.01-12.28,10.2-19.15,10.2c-1.3-0.01-2.59-0.19-3.85-0.54c-1.01-0.18-1.89-0.8-2.4-1.69
					c-1.62-3.07,0.63-9.79,1.36-11.99c0.93-2.83,2.01-5.71,3.03-8.49c3.39-9.16,6.92-18.65,7.24-29.09v-2.7
					c0.09-9.79,0.75-19.56,1.99-29.26c1.8-9.56,4.53-18.93,8.15-27.97l0.56-1.53c0.5-1.43,0.97-2.85,1.45-4.32
					c1.95-5.88,3.76-11.43,8.04-15.13c0.39-0.32,1.45-1.02,2.4-1.62c4.32-2.83,11.78-7.59,9.23-14.16c-1.51-3.91-5.27-5.9-11.2-5.9
					c-1.62,0-3.22,0.13-4.78,0.24c-1.56,0.11-2.46,0.19-3.54,0.19c-0.46,0.06-0.92,0.06-1.38,0c-1.08-0.3-1.82-0.73-1.99-1.15
					c-0.76-1.64,1.45-6.48,2.77-9.34c1.56-2.84,2.59-5.93,3.03-9.14c0.19-5.21-3.8-7.63-5.97-8.93c-0.66-0.36-1.27-0.78-1.84-1.28
					c-4.08-4.65-1.06-11.97,2.81-19.19c4.93-8.67,9.15-17.71,12.62-27.06c1.73-5.76,2.67-11.73,2.79-17.74
					c0.11-1.64,0.22-3.31,0.37-4.95c0.34-2.65,0.93-5.25,1.77-7.78c1.73-4.66,2.41-9.65,1.97-14.61c-0.55-3.41-1.32-6.78-2.31-10.09
					c-1.69-5.99-3-10.81-0.93-17.29c3.07-9.62,8.28-18.2,13.79-27.25c2.31-3.8,4.69-7.72,6.87-11.69
					c3.44-6.25,3.72-12.97,3.98-19.45c0-2.16,0.15-4.02,0.32-5.92c0.89-9.45,3.84-18.59,8.65-26.78c2.94-4.09,6.47-7.73,10.46-10.81
					c8.32-7.24,17.74-15.45,16.25-28.29c-0.5-4.32-4.02-7.07-8.95-7.07c-2.46,0.01-4.89,0.62-7.07,1.77
					c-4.01,2.62-7.39,6.11-9.88,10.2c-0.91,1.28-1.75,2.49-2.62,3.54l-2.16,2.64c-8.65,10.81-17.77,21.96-27.4,32.18
					c-2.16,2.16-4.43,4.32-6.96,6.48c-6.12,5.27-12.97,11.28-15.78,20.08c-1.71,5.49-2.98,11.13-4.19,16.58
					c-1.58,8.15-3.94,16.14-7.05,23.84c-2.33,5.91-6.14,11.12-11.07,15.13c-1.44,1.22-3.19,2.01-5.06,2.29c-0.32,0-0.45,0-0.5-0.11
					c0.02-0.49,0.07-0.98,0.15-1.47c1.31-4.59-1.35-9.38-5.94-10.69c-0.94-0.27-1.92-0.38-2.9-0.32c-5.79,0-12.15,4.32-18.13,8.65
					c-0.82,0.61-1.53,1.12-2.16,1.47c-8.02,5.36-18.48,5.92-28.53,6.05c-2.16,0-4.32,0.13-6.48,0.24s-4.75,0.26-7.07,0.26
					c-3.88,0.14-7.76-0.46-11.41-1.77c-3.93-1.58-8.06-5.4-8.32-7.76c0-0.26-0.11-0.97,1.49-2.16c2.27-1.51,4.95-2.26,7.67-2.16
					c3.49,0.15,6.96,0.65,10.35,1.49c1.79,0.39,3.57,0.76,5.3,1.04h0.52c2.04,0.4,4.12,0.63,6.2,0.67c4.15,0,6.92-1.36,8.23-4.02
					c2.64-5.34-2.16-9.32-5.17-11.71c-1.08-0.8-2.06-1.71-2.94-2.72c-0.35-3.54,2.38-6.66,6.48-10.96c1.23-1.28,2.53-2.62,3.63-3.96
					c1.18-1.41,1.45-3.36,0.71-5.04c-1.56-3.48-5.81-3.96-17.55-4.32c-1.34,0-3-0.13-3.41-0.19c-1.15-0.09-2.26-0.44-3.24-1.04
					c0.59-0.76,1.22-1.48,1.9-2.16c2.03-2.03,4.58-4.58,4.5-8.17c-0.08-2.2-1.07-4.26-2.74-5.68c-2.83-2.22-6.18-3.71-9.73-4.32
					c-2.01-0.36-3.93-1.09-5.66-2.16l0,0c0.05-0.88,0.32-1.73,0.78-2.49c2.49-5.14,7.61-9.32,12.56-13.34
					c1.64-1.34,3.26-2.66,4.78-4l0.97-0.84c7.85-6.66,14.98-13.7,14.05-20.19c-0.44-2.88-2.38-5.3-5.1-6.35
					c-0.5-0.24-1.04-0.45-1.56-0.67c-1.84-0.76-2.96-1.25-3.22-1.99c-0.44-1-0.4-2.15,0.11-3.11c0.73-1.38,3.72-4.99,15.5-8.1
					c4.17-1.24,8.49-1.92,12.84-2.03h0.5c1.17,0,2.36,0,3.57,0.15c1.6,0,3.2,0.17,4.8,0.17c5.13,0.33,10.19-1.36,14.09-4.71
					c2.81-2.69,5.42-5.57,7.8-8.65c2.21-2.89,4.65-5.6,7.28-8.1l1.1-0.95c4.32-3.65,11.45-9.79,9.36-16.25
					c-1.64-5.01-7.26-5.53-10.29-5.81c-0.61,0-1.15-0.11-1.6-0.19c-2.39-0.26-4.57-1.47-6.05-3.35c-1.24-2.3-1.52-4.99-0.78-7.5
					l0.19-0.95c0.93-4.65,2.51-12.41,5.92-14.96c0.71-0.52,1.58-0.79,2.46-0.76c3.33,0.49,6.52,1.65,9.38,3.41
					c0.95,0.45,1.84,0.89,2.68,1.25c3.62,1.59,7.49,2.53,11.43,2.77c2.86,0.26,5.64-1.01,7.31-3.35c1.55-2.77,1.55-6.14,0-8.9
					c-2.29-5.27-7-7.54-10.81-9.36c-2.1-0.83-4.03-2.04-5.71-3.57c-2.16-2.31-3.07-8.65-1.19-13.31c1.4-3.5,3.46-3.63,4.13-3.67
					h0.41c2.72,0,5.81,1.9,8.82,3.74l2.83,1.73c2.59,1.85,5.53,3.16,8.65,3.85c0.46,0.07,0.93,0.11,1.4,0.11
					c5.11-0.31,9.72-3.17,12.28-7.61c1.39-2.79,0.98-6.14-1.04-8.52c-4.11-5.01-10.2-6.2-15.6-7.26c-2.21-0.34-4.38-0.87-6.48-1.6
					c-6.16-3.11-5.66-5.64-2.9-14.61l0.67-2.16c0.31-0.91,0.31-1.9,0-2.81c0.46-0.24,0.87-0.55,1.21-0.93
					c1.11-1.32,2.07-2.77,2.83-4.32c1.08-2.01,1.88-3.35,2.77-3.52c2.01,0.48,3.97,1.14,5.86,1.97c3.72,1.71,7.7,2.76,11.78,3.11
					c3.84,0.16,7.3-2.32,8.39-6.01c1.88-5.84-3.98-11.28-8.39-13.51c-5.36-2.58-11.23-3.91-17.18-3.89h-1.02
					c-1.02,0-2.16,0.13-3.37,0.24c-1.21,0.11-2.9,0.26-4.32,0.26c-3.54,0-4.08-0.95-4.32-1.25c-0.45-0.8-0.32-1.1-0.24-1.3
					c0.67-1.71,4.56-4.32,11.67-6.48l0.48-0.15c2.37-0.58,4.63-1.55,6.7-2.85c3.15-2.02,4.99-5.57,4.82-9.32
					c-0.22-2.57-1.79-8.65-11.56-8.65c-5.93,0.43-11.76,1.66-17.35,3.67l-1.38,0.43c-1.51,0.46-3.09,0.71-4.67,0.73
					c-3.4,0-6.6-1.6-8.65-4.32c-7.26-8.49-8.8-27.51-7.11-37.95c0.15-0.91,0.26-1.97,0.41-3.11c0.35-3.05,0.99-8.65,2.74-10.01
					c0.18-0.17,0.42-0.28,0.67-0.28c1.43,0,4.04,2.16,5.79,3.59c0.95,0.8,1.86,1.56,2.77,2.16c5.45,3.76,11.91,7.63,18.54,7.74h0.24
					c5.9,0,10.01-2.83,10.42-7.2c0.5-5.17-4.19-7.76-6.48-9.01l-1.04-0.61c-9.16-5.9-17.74-11.87-21.94-20.16
					c-1.96-3.55-2.36-7.75-1.12-11.61c0.43-1.23,1.34-1.62,4.43-2.46c3.56-0.55,6.78-2.43,9.01-5.25
					c5.64-8.13-4.15-15.13-10.63-19.75c-2.68-1.92-7.18-5.12-7.65-6.48c-1.69-6.22-1.25-16.38,2.36-21.94
					c1.9-2.92,3.41-3.96,4.32-3.96c3.22,0,8.65,9.68,11.41,14.89c4.32,7.82,7.41,13.49,12.47,13.49c1.11,0,2.2-0.31,3.16-0.89
					c12.97-7.72,4.99-22.28,0.71-30.11c-0.41-0.78-0.82-1.49-1.19-2.16s-0.65-1.19-1.02-1.86c-1.17-2.16-3.37-5.99-2.96-7.67
					c0.06-0.56,0.29-1.08,0.65-1.51l0,0c0.11-0.02,0.22-0.02,0.32,0c2.16,0,6.03,2.36,8.34,3.76l0.28,0.17
					c0.58,0.37,1.23,0.82,1.9,1.3c2.5,2.07,5.47,3.5,8.65,4.17c0.53,0.07,1.06,0.1,1.6,0.11c2.18-0.08,4.34-0.51,6.38-1.3
					c1.15-0.46,2.37-0.74,3.61-0.84c0.37-0.01,0.75,0.05,1.1,0.17c2.88,1.08,5.59,2.58,8.04,4.45c3.96,3.16,8.62,5.32,13.59,6.31
					c0.72,0.11,1.44,0.16,2.16,0.17c1.69,0.04,3.35-0.4,4.8-1.28c2.45-1.19,3.92-3.77,3.7-6.48c-0.54-4.6-5.84-6.76-10.81-8.41
					l-0.32-0.11l-2.16-0.71c-4.15-1.32-10.44-3.31-11.24-6.48c-0.01-0.12-0.01-0.25,0-0.37c4.32,0,12.67,3.85,16.73,5.66
					c1.3,0.65,2.64,1.2,4.02,1.66c1.37,0.37,2.78,0.55,4.19,0.54c6.92,0,14.42-3.59,15.41-10.46c1.32-9.23-9.01-13.31-14.59-15.5
					c-1.08-0.43-2.16-0.8-2.83-1.17c-5.11-2.37-10.06-5.07-14.83-8.08c-10.18-6.76-15.13-15-14.7-24.49
					c0.03-1.06,0.12-2.12,0.26-3.18c0.45,0.61,0.95,1.38,1.38,2.05c1.35,2.52,3.38,4.61,5.86,6.03c1.6,0.76,3.35,1.15,5.12,1.15
					c5.5-0.19,10.47-3.35,12.97-8.26c2.92-5.9,1.21-12.3-0.17-17.44c-0.24-0.91-0.48-1.79-0.67-2.64
					c-1.77-6.9-4.85-13.39-9.08-19.13c-0.5-0.73-1.17-1.56-1.9-2.46c-1.1-1.38-4.43-5.55-3.72-6.48c1.21-1.69,4.47-2.03,7-2.03
					c3.13,0.08,6.25,0.4,9.34,0.95l2.38,0.35c0.71,0,1.6,0.24,2.64,0.41c4.7,0.87,9.46,1.36,14.24,1.49
					c12.36,0,15.99-5.4,16.86-9.94c1.19-6.18-2.68-11.07-6.09-15.37c-2-2.13-3.54-4.65-4.52-7.41c-1.32-8.07,0.01-16.35,3.8-23.6
					c4.15-8.19,3.67-16.43,3.24-24.38c-0.41-7.37-0.78-14.33,2.57-20.73c5.77-11,18.44-14.93,28.38-14.93
					c4.35-0.01,8.67,0.72,12.77,2.16c7.01,2.22,12.71,7.37,15.63,14.11c0.27,0.93,0.46,1.87,0.58,2.83c0.65,3.8,1.6,9.57,6.64,10.81
					c0.77,0.21,1.56,0.31,2.36,0.3c6.59,0,10.37-6.74,13.4-12.17c0.93-1.91,2.09-3.69,3.46-5.32c2.28-1.63,5.02-2.48,7.82-2.44
					c1.69,0,5.81,0.35,7.5,3.52c0.56,1.77,0.79,3.63,0.67,5.49c0,3.85,0.13,8.65,3.54,12.1c1.63,1.93,3.97,3.11,6.48,3.29
					c4.65,0,6.68-4.32,8.02-7.24c0.41-0.99,0.93-1.94,1.53-2.83c0.93-0.8,2.15-1.18,3.37-1.08c1.2,0.04,2.4,0.15,3.59,0.32
					c1.35,0.2,2.72,0.33,4.08,0.39h1.43c4.41,0.07,8.77-0.95,12.69-2.98c3.95-2.1,7.65-4.63,11.04-7.54
					c3.2-2.84,6.79-5.22,10.66-7.07c2.55-1,5.28-1.49,8.02-1.45c5.26,0.25,10.48,1.11,15.54,2.57c5.99,1.69,12.15,2.66,18.37,2.9
					c3.91,0.04,7.79-0.61,11.48-1.92c3.75-1.5,7.37-3.32,10.81-5.45c4.69-2.72,9.1-5.3,14.11-5.97c1.99-0.25,3.99-0.37,5.99-0.35
					c1.73,0,3.48,0,5.21,0c1.73,0,3.7,0.13,5.51,0.13c3.69,0.07,7.38-0.32,10.98-1.17c4.94-1.47,9.62-3.69,13.88-6.59
					c2.5-1.62,5.12-3.07,7.82-4.32c3.56-1.49,7.38-2.23,11.24-2.16c5.94,0.22,11.83,1.13,17.55,2.72
					c7.07,1.73,11.91,4.32,13.64,7.39c0.95,1.79,1.05,3.92,0.28,5.79c-1.11,2.86-2.66,5.54-4.58,7.93l-0.82,1.15
					c-4.32,6.05-9.66,13.55-5.77,22.89c0.94,2.11,2.03,4.15,3.24,6.12c1.87,2.63,3.15,5.64,3.74,8.82
					c0.35,4.54-2.55,9.38-5.36,14.07c-0.95,1.58-1.92,3.22-2.77,4.84l-1.08,2.16c-3.74,7.22-7.61,14.7-13.7,19.32
					c-2.6,1.79-5.38,3.31-8.3,4.52c-1.88,0.84-3.8,1.73-5.71,2.77c-13.36,7.35-23.77,18.33-33.15,28.96
					c-10.5,11.82-20.6,22.39-30.86,32.42c-1.88,1.82-3.74,3.72-5.6,5.62c-4.58,5.01-9.65,9.56-15.13,13.57
					c-2.63,1.58-5.41,2.9-8.3,3.91c-4.97,1.95-10.11,3.96-14.11,8.28c-6.25,6.81-9.14,17.03-9.77,24.34c-0.1,2.5,0.06,5.01,0.5,7.48
					c0.86,6.48,0.58,8-1.58,8.99c-1.43,0.51-2.93,0.83-4.45,0.93c-3.33,0.14-6.57,1.19-9.34,3.05c-2.92,1.84-4.5,5.22-4.04,8.65
					c0.78,4.78,5.6,8.04,9.83,9.51c4.15,1.36,8.49,2.02,12.86,1.95c3.18,0,6.33-0.26,9.36-0.52c2.05-0.17,4.08-0.35,6.16-0.43h0.5
					c0.84,0,1.88,0,2.96,0c4.71,0,5.6,0.97,5.62,0.97c0.39,2.59-4.56,7-7.22,9.36c-0.89,0.78-1.71,1.51-2.4,2.16
					c-5.48,5.58-10.34,11.74-14.5,18.37c-2.87,4.32-5.64,8.9-8.32,13.31c-7.78,12.79-15.84,25.94-27.12,35.73
					c-6.79,6.17-15.25,10.2-24.31,11.61c-1.49,0.17-3.03,0.24-4.67,0.32c-2.9,0.05-5.8,0.36-8.65,0.93
					c-0.89,0.19-8.82,2.16-9.66,7.91c-0.36,3.05,1.31,5.98,4.13,7.22c3.74,2.16,9.08,2.42,13.31,2.42c1.77,0,3.57,0,5.27-0.11
					c1.71-0.11,2.83,0,4.06,0c0.65,0,1.23,0,1.77,0l2.55,0.15c2.16,0.13,4.08,0.26,6.05,0.26c5.81,0.21,11.5-1.68,16.04-5.32
					c6.09-4.56,11.13-10.38,14.78-17.05c0.35-0.65,0.67-1.32,1.02-2.01c1.75-3.5,3.41-6.81,6.16-8.45c3.03-1.29,6.33-1.84,9.62-1.62
					l3.11-0.11c8.86-0.5,18.85-1.08,27.19-6.59c3.36-2.4,6.32-5.31,8.75-8.65c2.06-2.94,4.65-5.47,7.63-7.46
					c2.86-1.4,6.05-2.04,9.23-1.84c1.43,0,2.87,0,4.32,0.11c1.45,0.11,3.13,0.13,4.69,0.13c3.39,0.1,6.77-0.36,10.01-1.36
					c3.89-1.38,7.66-3.09,11.26-5.1c3.15-1.76,6.44-3.27,9.83-4.52c3.67-1.18,7.51-1.75,11.37-1.71c7.3,0.3,14.55,1.39,21.61,3.24
					c3.16,0.69,6.48,1.43,9.68,2.01c3.69,0.64,7.43,0.96,11.17,0.93c5.91-0.11,11.82-0.56,17.68-1.34
					c3.46-0.41,7.02-0.82,10.46-1.06c1.77-0.13,3.61-0.19,5.47-0.19c10.47,0.35,20.89,1.61,31.14,3.76
					c9.7,1.96,19.53,3.24,29.41,3.83c2.64,0.09,5.27,0.13,7.91,0.13c14.75-0.04,29.47-1.34,44-3.87c3.09-0.54,6.22-1.28,9.27-1.99
					c5.54-1.53,11.24-2.44,16.99-2.7c2.18-0.02,4.36,0.22,6.48,0.71c8.32,1.99,14.05,9.88,19.58,17.48l1.19,1.64
					c11.3,15.43,17.01,29.83,16.97,42.77c0.06,7.54-2.2,14.92-6.48,21.14c-2.96,3.88-6.36,7.41-10.14,10.5
					c-4.11,3.45-7.84,7.32-11.13,11.56c-4.82,7.28-8.74,15.12-11.67,23.34l-1.62,4.04c-1.27,2.96-2.71,5.85-4.32,8.65
					c-2.91,4.71-5.15,9.8-6.64,15.13c-1.53,6.48,1.02,11.82,3.29,16.53c1.66,2.83,2.66,6,2.94,9.27
					c-0.11,6.48-4.32,12.21-8.84,18.31c-1.75,2.4-3.57,4.86-5.1,7.37c-7.69,12.64-11.99,23.23-13.53,33.28
					c-0.17,1.1-0.32,2.16-0.5,3.31c-1.28,9.01-2.51,17.53-8.84,23.43c-1.99,1.86-4.08,3.59-6.33,5.42
					c-5.23,3.95-9.9,8.61-13.85,13.83c-5.21,7.5-7.2,16.23-9.1,24.68c-0.61,2.72-1.19,5.32-1.88,7.85
					c-2.49,9.12-5.4,18.67-11.43,26.26c-9.1,11.45-18.82,19.78-28.94,24.7c-6.94,3.41-14.68,3.7-22.89,3.98
					c-3.91,0.15-7.95,0.28-11.95,0.8c-10.73,1.07-21.11,4.39-30.45,9.77c-3.85,2.14-7.34,4.87-10.35,8.08
					c-2.66,2.67-3.56,6.62-2.31,10.18c1.1,2.34,3.49,3.8,6.07,3.72c1.55-0.04,3.08-0.39,4.5-1.02c3.91-1.64,7.72-3.54,11.39-5.38
					c5.41-2.89,11.05-5.33,16.86-7.28l1.66-0.52c3.99-1.44,8.15-2.32,12.38-2.62c1.09-0.01,2.18,0.12,3.24,0.39
					c3.07,0.84,3.87,4.06,4.6,11.43c0.18,2.49,0.56,4.96,1.12,7.39c1.21,5.6,4.15,10.68,8.41,14.52c2.95,2.4,6.69,3.6,10.48,3.37
					h1.79c1.28-0.06,2.56,0.09,3.78,0.45c1.08,0.35,2.16,1.88,3.09,3.33c1.73,2.68,3.29,5.46,4.69,8.32
					c1.99,4.15-1.06,8.32-9.08,12.45c-7.63,4.07-16.12,6.3-24.77,6.48c-1.44,0.01-2.89-0.07-4.32-0.24l-3.63-0.43
					c-3.77-0.54-7.58-0.85-11.39-0.93c-6.2-0.3-12.31,1.56-17.29,5.25c-8.04,6.46-15.29,13.85-21.61,22
					c-2.21,3.02-4.07,6.28-5.53,9.73c-1.24,2.98-2.84,5.8-4.78,8.39c-4.95,5.92-14.8,8.65-22.37,9.23h-1.51
					c-5.94,0-11.63-2.66-17.68-5.45c-4.61-2.35-9.47-4.19-14.48-5.49c-4.69-1.14-9.48-1.77-14.31-1.9c-10.5,0-14.35,4.58-15.71,8.43
					c-1.21,2.97-0.89,6.35,0.86,9.03c4.04,6.18,14.63,7.39,25.11,8.1c1.49,0.11,2.74,0.17,3.59,0.3l6.61,0.89
					c8.88,1.21,18.05,2.44,27.12,3.5c6.48,0.73,12.28,1.32,18.02,1.79l2.9,0.15c2.92,0.11,5.97,0.24,7.61,1.47
					c0.69,0.59,1.32,1.26,1.86,1.99c0.41,0.5,0.82,0.99,1.25,1.47c3.96,4.32,9.99,6.61,17.92,6.61c4.64-0.05,9.25-0.69,13.72-1.92
					c13.51-3.67,18.78-12.15,24.88-21.96l1.47-2.33c2.29-2.68,5.23-4.74,8.54-5.97c1.12-0.42,2.31-0.62,3.5-0.58
					c1.17,0,2.4,0.13,3.7,0.24c1.5,0.17,3.01,0.27,4.52,0.28h0.02c1.32,0,2.55-0.13,3.72-0.26c1.17-0.13,2.16-0.19,3.11-0.19
					c0.67-0.01,1.33,0.06,1.99,0.19c7.93,1.66,14.52,7.05,21.5,12.73c2.96,2.4,6.03,4.91,9.21,7.07l5.25,3.72
					c11.97,8.54,26.89,19.17,41.58,19.17c1.38,0.01,2.76-0.09,4.13-0.28c0.89-0.13,1.8-0.2,2.7-0.19c6.64,0,14.44,3.18,23.77,9.75
					c10.4,7.24,11.8,20.36,13.16,33.07v0.22c1.02,9.53,3,20.49,10.03,29.37c5.21,6.66,12.23,10.59,19.02,14.39
					c7.8,4.32,15.13,8.54,18.8,16.43c3.9,8.37,6.34,17.35,7.22,26.54c0.27,5.24,0.27,10.49,0,15.73
					c-0.35,6.48-0.23,12.98,0.35,19.45c1.07,6.7,3,13.24,5.75,19.45c2.03,4.71,3.63,9.59,4.8,14.59c0.82,4.06,1.23,8.15,1.69,12.47
					c0.45,5.43,1.27,10.83,2.46,16.14c2.41,8.5,5.56,16.78,9.4,24.75c1.36,3.11,2.7,6.2,3.98,9.34
					c4.93,12.29,8.41,25.11,10.37,38.21c1.77,11.61,4.54,24.94,11.69,35.77c2.31,3.52,4.88,6.83,7.39,10.01s5.01,6.48,7.18,9.75
					c4.47,6.85,5.62,13.44,7.09,21.83v0.37c1.58,8.99,8.39,9.86,13.34,10.46l1.66,0.24c12.76,1.64,25.2,5.18,36.91,10.48
					c2.16,1.02,4.58,2.01,6.94,3.03c11.74,5.04,23.77,10.27,32.98,18c4.91,4.49,9.34,9.48,13.21,14.89
					c4.39,6.12,9.47,11.71,15.13,16.68c2.29,1.88,4.63,3.72,6.96,5.55c8.9,7,17.29,13.59,23.21,22.82
					c9.64,15.13,14.7,33.5,15.91,57.88c0.26,5.58,0.93,11.71,1.56,17.64c0.54,4.99,1.1,10.16,1.38,14.78
					c1.15,17.92,7.26,26.99,17.18,39.79c7.28,9.42,13.18,17.66,19.8,27.1c1.58,2.16,3.98,5.19,6.48,8.3
					c2.51,3.11,5.04,6.22,6.48,8.34c0.82,1.11,1.72,2.16,2.7,3.13c2.36,2.51,5.94,6.31,5.81,9.38c-0.12,1.46-0.86,2.8-2.03,3.67
					c-0.61,0.16-1.25,0.22-1.88,0.19c-6.79,0-19.82-4.56-22.71-6.01c-6.56-3.46-12.76-7.56-18.52-12.23
					c-5.66-4.51-11.66-8.56-17.96-12.12c-5.89-3.54-12.58-5.52-19.45-5.75c-1.89-0.01-3.77,0.18-5.62,0.56
					c-0.13,0-12.73,2.79-15.13,9.68c-0.79,2.16-0.5,4.57,0.78,6.48c2.9,4.19,9.66,4.45,15.63,4.69c1.31,0.01,2.63,0.1,3.93,0.26
					c12.97,3.65,22.91,13.64,32.53,23.28l3.29,3.29c5.75,6.09,12.2,11.48,19.21,16.06c3.36,1.97,6.88,3.65,10.53,5.04
					c5.92,2.42,10.61,4.32,14.52,9.79c4.47,6.69,8.3,13.78,11.45,21.18c0.97,2.16,1.97,4.21,2.96,6.33c2.01,4.13,4.32,8,6.48,11.74
					c4.01,6.15,7.18,12.81,9.42,19.8c2.55,9.34,7.37,32.74-3.2,48.76c-3.78,5.75-9.44,9.88-15.41,14.22
					c-5.57,3.73-10.65,8.14-15.13,13.12c-4.17,4.61-6.22,10.76-5.64,16.94c0.86,7.76,6.16,15.45,15.73,22.87
					c4.17,3.22,8.65,6.07,12.86,8.84c2.01,1.3,4.02,2.62,6.01,3.96c1.39,0.98,2.73,2.04,4,3.18c3.44,2.94,6.98,5.99,11.67,5.99
					c3.94-0.2,7.58-2.14,9.94-5.3c5.53-6.25,7.15-13.79,8.65-21.07c1.36-6.31,2.64-12.25,6.48-16.64
					c7.02-8.13,21.09-9.92,31.08-10.57c5.58-0.35,11.26-0.54,16.92-0.54c49.06,0,91.36,13.12,116.06,36.01
					c19.1,17.81,32.31,41.04,37.84,66.57c4.21,18.87,4.71,37.37,1.38,52.11c-2.78,10.96-6.33,21.71-10.61,32.18
					c-2.79,7.24-5.66,14.74-7.95,22.17c-0.69,2.16-1.34,4.32-1.99,6.48c-4.56,15.32-8.15,27.45-21.2,37.26
					c-16.04,12.06-26.61,23.45-33.28,35.88c-2.44,4.54-4.32,8.95-6.2,13.23c-6.33,14.65-10.5,24.34-28.23,24.34h-1.88h-1.49
					c-10.31,0-18.98,4.02-27.36,7.91l-1.82,0.82l-4.15,1.71c-21.12,8.52-21.18,8.8-21.74,11.54c-0.28,1.44,0.16,2.93,1.19,3.98
					c2.24,2.38,5.37,3.73,8.65,3.72c4.75,0,9.12-2.72,13.36-5.38c3.29-2.05,6.68-4.17,9.14-4.17c0.28-0.03,0.56-0.03,0.84,0
					c2.21,0.3,4.12,1.72,5.04,3.76c2.42,5.62-1.6,17.05-6.64,25.94c-3.54,6.22-7.87,12.97-12.97,16.51
					c-4.28,2.76-9.33,4.09-14.42,3.83c-3.41,0-6.89-0.37-10.59-0.74c-3.82-0.46-7.67-0.72-11.52-0.78
					c-2.28-0.02-4.55,0.14-6.81,0.48c-6.18,0.97-16.99,6.01-16.51,12.6c0.32,4.32,4.32,6.61,12.06,6.68l0,0
					c1.81-0.03,3.62-0.15,5.42-0.37c2.16-0.19,4.19-0.39,6.09-0.39c1.16-0.06,2.32,0.07,3.44,0.39c-0.09,0.75-0.3,1.48-0.63,2.16
					c-0.5,1.21-4.32,3.61-6.48,5.06c-5.1,3.24-10.35,6.61-11.13,11.84c-0.34,2.74,0.56,5.49,2.46,7.5
					c2.53,3.27,6.39,5.23,10.53,5.32c6.76,0,12.58-5.29,17.7-9.94c1.69-1.64,3.49-3.14,5.4-4.52c2.46-1.62,5.35-2.47,8.3-2.44
					c5.02,0.25,9.87,1.91,13.98,4.8c0.65,0.43,1.17,0.78,1.58,1.1h-0.22c-2.53,1.53-6.33,3.85-6.98,8.39
					c-0.3,1.94,0.27,3.91,1.56,5.38c2.31,2.66,6.74,3.85,14.37,3.85c12.56,0,30.84-3.24,36.74-4.32l1.51-0.26
					c4.61-0.9,9.14-2.19,13.53-3.87c5.35-2.24,11.03-3.59,16.81-3.98c0.71,0,1.43,0,2.16,0c3.83,0.39,4.58,1.21,4.58,1.23
					c0.24,0.93-1.45,4.45-2.44,6.48c-1.84,3.87-3.91,8.23-3.76,12.67c0.24,4.58,0.76,9.14,1.56,13.66
					c1.51,9.68,2.94,18.8-1.34,27.02c-5.17,9.9-17.59,13.27-29.61,16.53c-4.47,1.21-8.65,2.36-12.56,3.83
					c-8.08,3.05-16.97,7.33-21.76,16.04c-1.93,4.06-2.98,8.48-3.09,12.97c-0.73,8.26-1.38,9.88-6.96,9.94h-0.73
					c-1.99,0-3.87,0-5.71-0.22s-3.57-0.19-5.3-0.19c-4.84-0.09-9.62,0.97-13.96,3.11c-3.59,1.77-6.94,3.76-10.18,5.71
					c-5.32,3.46-11.04,6.27-17.03,8.36c-2.92,0.91-5.97,1.66-8.9,2.42c-8.65,2.16-17.42,4.32-25.18,9.96l-4.06,2.96
					c-6.79,4.95-13.18,9.62-20.79,12.3c-2.39,0.8-4.91,1.19-7.43,1.15c-8.15,0-17.29-3.22-26.13-6.33c-4.32-1.49-8.3-2.92-12.1-3.93
					c-14.27-3.73-28.97-5.59-43.72-5.53c-11.26,0.03-22.5,0.97-33.61,2.79c-7.76,1.23-15.5,3.29-23,5.25
					c-11.74,3.09-23.77,6.27-35.77,6.27c-0.86,0-1.75,0-2.62,0c-16.88-0.67-41.06-4.91-57.17-12.97c-2.61-1.69-4.98-3.73-7.05-6.05
					c-4.84-4.84-10.31-10.33-17.03-10.33c-0.37-0.02-0.75-0.02-1.12,0c-8.54,0.8-11.22,9.42-13.38,16.36
					c-1.36,4.45-2.81,9.06-5.14,10.81c-11.11,7.74-24.42,8.77-35.96,8.77c-3.96,0-8.02-0.13-11.93-0.26
					c-3.39,0-6.74-0.22-10.05-0.26h-3.05c-1.75,0-3.65,0-5.62,0c-8.3,0-18.65,0.61-25.24,5.4c-9.21,6.7-3.63,15.63-0.3,20.96
					c3.85,6.16,4.02,7.56,2.57,8.9c-0.71,0.67-3.09,2.16-9.99,2.16c-7.53-0.23-15.01-1.3-22.3-3.18c-2.7-0.65-5.49-1.43-8.17-2.16
					c-7.08-2.36-14.46-3.73-21.92-4.06c-1.44,0-2.89,0.08-4.32,0.24c-3.48,0.37-7.02,1.1-10.44,1.82
					c-4.63,1.12-9.35,1.79-14.11,1.99c-1.73,0.01-3.45-0.16-5.14-0.5c-9.44-2.01-17.29-7.52-25.59-13.36
					c-4.54-3.31-9.27-6.36-14.16-9.14c-6.12-3.37-13-5.12-19.99-5.08c-6.56,0.22-13.09,1.15-19.45,2.77
					c-3.7,0.8-7.52,1.62-11.43,2.16c-12.46,1.78-24.53,5.65-35.7,11.45c-3.05,1.6-6.35,3-9.83,4.47c-5.4,2.11-10.6,4.7-15.54,7.74
					c-11.13,7.33-18.54,20.01-22.63,38.9c-0.9,5.06-1.17,10.2-0.8,15.32c0.26,8.13,0.48,15.13-4.32,20.62
					c-3.24,4.01-6.04,8.36-8.34,12.97c-4,7.35-8.15,14.98-14.22,17.29c-1.03,0.39-2.12,0.59-3.22,0.58c-6.72,0-15-6.48-21.61-11.78
					c-2.79-2.16-5.4-4.32-7.82-5.81c-19.45-12.43-42.38-18.74-68.36-18.74h-1.38c-6.09,0.16-12.16,0.66-18.2,1.49
					c-5.42,0.63-10.53,1.23-15.71,1.38h-0.69c-2.89-0.08-5.78-0.32-8.65-0.73c-3.58-0.5-7.19-0.79-10.81-0.86
					c-4.43-0.17-8.83,0.8-12.77,2.81c-7.02,3.85-10.68,10.05-13.92,15.5c-3.24,5.45-6.29,10.66-12.45,13.29
					c-2.42,1.02-4.97,1.99-7.59,3c-11.33,4.32-24.16,9.34-31.71,19.45c-5.66,7.67-3.13,14.8-1.12,20.53
					c1.43,4.04,2.68,7.52,1.45,11.09C426.63,2681.32,419.6,2687.56,413.62,2687.56z M284.65,665.85c-1.95,0.13-3.86-0.67-5.12-2.16
					c-2.66-3.93,1.28-10.81,3.85-14.37c5.27-7.54,10.99-14.75,17.12-21.61c1.59-1.78,1.43-4.52-0.35-6.1
					c-0.78-0.69-1.78-1.08-2.82-1.09l-21.61-0.13h-4.58c-3.96,0-8.65,0-13.36,0.43c-2.36,0.19-4.69,0.45-7.05,0.71
					c-4.08,0.53-8.18,0.84-12.3,0.93c-3.07,0.05-6.14-0.32-9.12-1.08c-8.65-2.27-16.4-9.25-20.96-18.65
					c-3.89-8.17-5.62-17.83-5.62-29.98v-0.99c0.28-3.74-0.11-7.51-1.17-11.11c-1.58-5.25-6.21-8.99-11.67-9.44h-0.13
					c-4.47,0-7.85,2.64-10.55,4.75c-0.61,0.48-1.21,0.95-1.84,1.38c-3.17,2.21-6.94,3.39-10.81,3.37c-2.67,0.01-5.31-0.56-7.74-1.66
					c-6.02-2.89-10.11-8.69-10.81-15.32c-0.5-5.86,3.13-12.84,8.65-13.44h0.99c1.14,0.02,2.27,0.12,3.39,0.28
					c1.43,0.2,2.88,0.31,4.32,0.35l0,0c5.6,0,10.03-2.53,11.71-6.61c1.84-4.45,0.15-9.57-4.6-14.07l-1.17-1.06
					c-1.71-1.23-3.05-2.9-3.87-4.84c-0.1-0.79,0.17-1.59,0.73-2.16c1.13-1.33,2.77-2.11,4.52-2.16h0.56c3.33,0.43,6.64,3,9.86,7.65
					c0.89,1.3,1.71,2.62,2.55,3.96c2.09,3.67,4.66,7.05,7.63,10.05c3.57,3.44,8.29,5.43,13.25,5.6c2.39,0.02,4.74-0.57,6.83-1.73
					c5.4-3.35,8.38-9.51,7.65-15.82c-0.64-5.17-2.46-10.12-5.34-14.46l-0.67-1.12c-4.13-6.96-8.39-14.13-6.03-20.14
					c2.11-4.2,6.51-6.74,11.2-6.48c0.98,0,1.96,0.1,2.92,0.32c7.43,1.69,12.45,8.21,14.78,11.95c7.61,12.19,8.9,27.77,9.16,41.41
					c0,2.55,0,5.08,0,7.63c0,12.71,0.22,25.94,3.89,38.47c4.11,14.11,14.8,30.63,32.42,34.58c3.27,0.72,6.61,1.06,9.96,1.02
					c3.2,0,6.48-0.24,9.47-0.48s5.99-0.43,8.9-0.43h1.25c7.55-0.25,14.72,3.32,19.06,9.51c-13.25,1.81-24.6,10.42-29.91,22.69
					c-1.49,3.84-2.62,7.82-3.39,11.87c-0.48,2.16-0.91,4.04-1.45,5.97c-1.69,6.07-4.32,10.24-7.54,12.43
					C288.2,665.38,286.44,665.87,284.65,665.85z"/>
				<path className="st2" d="M741.05,160.57c5.59,0.23,11.13,1.1,16.51,2.59c8.65,2.16,10.61,4.8,10.81,5.32c0.19,0.52,0.43,0.78,0,2.38
					c-0.99,2.42-2.32,4.68-3.96,6.72l-0.45,0.63l-0.37,0.52c-4.32,6.18-11.09,15.52-6.25,27.06c1.01,2.3,2.18,4.52,3.5,6.66
					c1.48,2.09,2.55,4.44,3.13,6.94c0.24,3.18-2.29,7.41-4.73,11.52c-0.99,1.64-2.01,3.33-2.9,5.06l-1.08,2.16
					c-3.52,6.81-7.15,13.83-12.49,17.85c-2.35,1.6-4.85,2.96-7.48,4.04c-1.95,0.89-3.96,1.79-5.99,2.92
					c-13.96,7.67-24.62,18.95-34.32,29.87c-10.42,11.74-20.45,22.24-30.63,32.09c-1.95,1.88-3.85,3.83-5.68,5.73
					c-4.38,4.78-9.21,9.13-14.44,12.97c-2.36,1.4-4.86,2.56-7.46,3.46c-5.19,2.03-11.04,4.32-15.71,9.4
					c-6.37,7.59-10.15,17-10.81,26.89c-0.15,2.82,0.02,5.65,0.5,8.43c0.25,1.53,0.4,3.08,0.45,4.63c-0.89,0.18-1.79,0.32-2.7,0.41
					c-4.1,0.22-8.05,1.57-11.43,3.89c-4.18,2.85-6.39,7.82-5.71,12.84c1.08,6.68,7.28,11.04,12.73,12.97
					c4.59,1.52,9.41,2.25,14.24,2.16c3.35,0,6.59-0.28,9.73-0.54c2.16-0.17,4.02-0.32,5.99-0.41h0.5h1.28
					c-1.1,1.08-2.27,2.16-2.98,2.74c-0.71,0.58-1.75,1.56-2.53,2.31c-5.71,5.81-10.79,12.21-15.13,19.11
					c-2.92,4.32-5.71,9.01-8.41,13.44c-7.95,12.97-15.47,25.44-26.24,34.58c-6.15,5.6-13.81,9.28-22.02,10.59
					c-1.32,0.17-2.79,0.24-4.32,0.3c-3.14,0.06-6.28,0.41-9.36,1.04c-5.64,1.3-12.02,4.99-12.97,11.5
					c-0.62,4.81,1.9,9.47,6.27,11.58c4.58,2.64,10.37,3,15.45,3c1.86,0,3.65,0,5.4-0.11c1.4,0,2.74,0,3.93,0h1.53l2.49,0.15
					c2.16,0.15,4.32,0.28,6.35,0.28c6.77,0.21,13.4-2,18.69-6.22c6.52-4.99,11.89-11.32,15.76-18.57c0.37-0.67,0.71-1.36,1.06-2.16
					c1.47-2.94,2.85-5.68,4.5-6.68c2.41-0.83,4.96-1.17,7.5-1.02c1.12,0,2.16,0,3.31-0.13c9.36-0.52,19.99-1.12,29.29-7.28
					c3.74-2.68,7.03-5.93,9.75-9.64c1.71-2.53,3.87-4.73,6.38-6.48c2.22-1.03,4.67-1.48,7.11-1.3c1.32,0,2.7,0,4.15,0.11
					c1.45,0.11,3.2,0.13,4.86,0.13c3.84,0.08,7.67-0.45,11.35-1.58c4.12-1.46,8.11-3.26,11.93-5.38c2.94-1.68,6.02-3.12,9.19-4.32
					c3.23-1.03,6.6-1.53,9.98-1.49c6.97,0.3,13.89,1.35,20.64,3.13c3.22,0.71,6.48,1.45,9.86,2.16c3.94,0.69,7.93,1.02,11.93,0.99
					c6.09-0.1,12.16-0.56,18.2-1.38c3.57-0.39,6.92-0.78,10.29-1.02c1.64-0.13,3.37-0.19,5.14-0.19c10.22,0.34,20.39,1.57,30.39,3.7
					c9.9,2,19.93,3.3,30.02,3.89c2.66,0,5.36,0.13,8.06,0.13c15-0.04,29.96-1.36,44.74-3.93c3.22-0.56,6.48-1.32,9.53-2.03
					c5.22-1.46,10.58-2.33,15.99-2.59c1.82-0.01,3.63,0.18,5.4,0.58c6.76,1.64,12.02,8.86,17.1,15.84l1.21,1.64
					c10.81,14.67,16.17,28.2,16.12,40.22c0.03,6.66-1.99,13.17-5.77,18.65c-2.78,3.6-5.95,6.88-9.47,9.77
					c-4.36,3.64-8.3,7.75-11.76,12.25c-5.05,7.55-9.13,15.71-12.17,24.27l-1.62,4c-1.22,2.79-2.6,5.51-4.15,8.13
					c-3.1,5.08-5.47,10.56-7.07,16.3c-1.88,7.95,1.3,14.57,3.59,19.45c1.32,2.25,2.19,4.74,2.55,7.33c0,5.08-3.93,10.31-8.02,15.84
					c-1.79,2.46-3.67,5.01-5.3,7.67c-8.02,13.16-12.51,24.23-14.13,34.88c-0.17,1.12-0.32,2.16-0.5,3.35
					c-1.23,8.65-2.29,16.02-7.5,20.86c-1.9,1.77-3.96,3.48-6.14,5.27c-5.54,4.2-10.47,9.14-14.65,14.7
					c-5.71,8.19-7.87,17.74-9.77,26.19c-0.61,2.68-1.17,5.23-1.84,7.65c-2.36,8.65-5.12,17.79-10.63,24.7
					c-8.65,10.96-17.94,18.87-27.47,23.54c-6.12,2.98-13.42,3.26-21.14,3.52c-4,0.15-8.15,0.3-12.34,0.82
					c-11.33,1.15-22.28,4.68-32.14,10.37c-4.24,2.36-8.09,5.38-11.39,8.95c-3.78,3.94-4.95,9.71-2.98,14.8
					c1.8,3.88,5.73,6.33,10.01,6.25c2.12-0.04,4.2-0.5,6.14-1.36c4.06-1.69,7.93-3.61,11.69-5.49c5.18-2.78,10.59-5.13,16.17-7
					l1.69-0.52c3.58-1.31,7.31-2.14,11.11-2.44c0.73-0.01,1.46,0.08,2.16,0.26c0.84,2.48,1.32,5.06,1.43,7.67
					c0.19,2.72,0.61,5.41,1.25,8.06c1.43,6.48,4.87,12.34,9.83,16.75c3.73,3.02,8.44,4.56,13.23,4.32h1.79
					c0.69-0.02,1.37,0.02,2.05,0.13c0.44,0.52,0.84,1.08,1.19,1.66c1.63,2.53,3.11,5.16,4.43,7.87c0.3,0.67,0.39,0.82-0.39,1.82
					c-1.89,2.09-4.19,3.76-6.76,4.91c-7.02,3.79-14.83,5.87-22.8,6.07c-1.3,0-2.6-0.06-3.89-0.19l-3.57-0.43
					c-3.95-0.57-7.94-0.9-11.93-0.97c-7.16-0.31-14.2,1.88-19.93,6.18c-8.35,6.66-15.88,14.29-22.43,22.71
					c-2.42,3.29-4.45,6.84-6.05,10.59c-1.1,2.64-2.51,5.13-4.19,7.43c-3.35,4.02-10.96,7.05-19.45,7.69h-1.17
					c-4.97,0-10.27-2.46-15.86-5.06c-4.94-2.48-10.13-4.4-15.5-5.73c-4.96-1.19-10.03-1.87-15.13-2.01
					c-13.72,0-18.28,7.09-19.78,11.3c-1.65,4.25-1.16,9.03,1.32,12.86c5.55,8.47,19.32,9.42,28.42,10.05c1.38,0,2.68,0.17,3.33,0.26
					l6.66,0.91c8.88,1.19,18.05,2.44,27.15,3.48c6.48,0.76,12.38,1.34,18.15,1.82c0.99,0,2.03,0.13,3.09,0.17
					c1.75-0.04,3.49,0.16,5.19,0.61c0.39,0.4,0.76,0.82,1.1,1.25c0.45,0.56,0.91,1.1,1.38,1.64c4.75,5.27,12.06,8.04,21.14,8.04
					c5.02-0.08,10.01-0.8,14.85-2.16c15.13-4.11,21.12-13.7,27.43-23.77l1.45-2.31c1.82-1.9,4.03-3.38,6.48-4.32
					c0.61-0.21,1.26-0.3,1.9-0.26c0.93,0,2.16,0.11,3.26,0.22c1.64,0.19,3.28,0.29,4.93,0.3h0.32c1.49,0,2.87-0.15,4.08-0.28
					c1.21-0.13,1.92-0.17,2.68-0.17c0.37-0.04,0.74-0.04,1.1,0c6.89,1.47,13.1,6.48,19.67,11.87c3.03,2.46,6.16,5.01,9.49,7.28
					c1.62,1.12,3.35,2.36,5.19,3.67c11.82,8.43,28.1,19.97,44.09,19.97c1.59,0.01,3.18-0.11,4.75-0.35
					c0.72-0.09,1.44-0.13,2.16-0.13c5.64,0,12.84,3,21.4,8.97c8.56,5.96,10.03,17.7,11.35,29.98v0.24
					c1.08,10.14,3.24,21.81,10.91,31.58c5.77,7.33,13.49,11.65,20.32,15.47c7.5,4.21,13.96,7.85,17.01,14.46
					c3.67,7.91,5.98,16.39,6.81,25.07c0.27,5.09,0.27,10.19,0,15.28c-0.35,6.69-0.23,13.39,0.37,20.06c1.1,7.1,3.13,14.03,6.03,20.6
					c1.92,4.48,3.45,9.11,4.58,13.85c0.78,3.87,1.19,7.87,1.64,12.08c0.48,5.67,1.36,11.31,2.62,16.86
					c2.4,8.77,5.54,17.31,9.4,25.55c1.38,3.16,2.68,6.16,3.91,9.21c4.82,11.97,8.23,24.46,10.16,37.22
					c1.84,12.06,4.73,25.94,12.36,37.52c2.4,3.65,5.04,7.02,7.59,10.29s4.91,6.27,6.98,9.44c3.98,6.09,5.01,11.99,6.48,20.19v0.37
					c2.16,12.15,12.21,13.42,17.07,14.03l1.58,0.22c12.36,1.59,24.4,5.01,35.75,10.16c2.29,1.02,4.63,2.01,7,3.03
					c11.43,4.93,23.28,10.03,31.9,17.29c4.67,4.29,8.89,9.06,12.58,14.22c4.56,6.35,9.84,12.15,15.73,17.29
					c2.33,1.92,4.73,3.8,7.05,5.6c8.65,6.76,16.73,13.16,22.22,21.74c9.23,14.46,14.07,32.18,15.13,55.78
					c0.28,5.71,0.95,11.89,1.58,17.87c0.56,5.19,1.08,10.07,1.38,14.59c1.21,19.17,7.63,28.72,18.05,42.19
					c7.22,9.32,13.1,17.51,19.69,26.91c1.66,2.38,4.11,5.38,6.68,8.65s4.95,6.09,6.31,8.06c0.94,1.3,1.98,2.52,3.11,3.65
					c1.82,1.74,3.37,3.75,4.58,5.97c-5.92-0.22-17.59-4.32-20.29-5.55c-6.32-3.35-12.3-7.3-17.85-11.8
					c-5.82-4.65-12.02-8.82-18.52-12.47c-6.54-3.92-13.99-6.09-21.61-6.29c-2.18,0-4.35,0.21-6.48,0.65
					c-0.63,0.13-15.13,3.22-18.26,12.54c-1.27,3.46-0.78,7.32,1.32,10.35c4.13,5.97,12.38,6.29,19.02,6.48c1.08,0,2.53,0,3.13,0.17
					c11.76,3.39,20.81,12.47,30.41,22.11l3.31,3.29c5.99,6.33,12.71,11.93,20.01,16.68c3.56,2.08,7.28,3.87,11.13,5.34
					c5.49,2.16,9.47,3.87,12.64,8.32c4.31,6.48,8.01,13.33,11.07,20.49c0.99,2.16,1.97,4.32,2.98,6.35
					c2.16,4.32,4.32,8.26,6.66,12.08c3.82,5.81,6.85,12.12,8.99,18.74c2.4,8.65,6.96,30.71-2.64,45.24
					c-3.35,5.1-8.65,8.99-14.35,13.12c-5.87,3.92-11.22,8.58-15.91,13.85c-4.89,5.52-7.28,12.82-6.61,20.16
					c0.99,8.97,6.85,17.66,17.29,25.81c4.32,3.33,8.8,6.25,13.12,9.03c2.01,1.3,4,2.59,5.97,3.93c1.26,0.89,2.46,1.85,3.61,2.87
					c3.65,3.13,8.19,7.02,14.48,7.02c5.17-0.2,10-2.66,13.18-6.74c6.29-7.09,8.1-15.56,9.7-23.04c1.28-6.01,2.4-11.2,5.45-14.72
					c5.94-6.89,18.87-8.49,28.1-9.08c5.47-0.37,11.07-0.54,16.64-0.54c47.98,0,89.22,12.71,113.12,34.86
					c18.46,17.22,31.22,39.67,36.57,64.34c4.08,18.24,4.56,36.07,1.38,50.21c-2.61,10.83-6,21.47-10.14,31.81
					c-2.81,7.31-5.71,14.87-8.04,22.46c-0.71,2.16-1.36,4.32-1.99,6.48c-4.52,15.13-7.76,26.09-19.67,35.03
					c-16.56,12.45-27.51,24.29-34.58,37.3c-2.53,4.69-4.47,9.19-6.35,13.55c-6.68,15.45-9.96,21.72-24.27,21.72h-1.73h-1.64
					c-11.26,0-20.38,4.32-29.2,8.3l-1.75,0.82l-4,1.62c-21.07,8.49-23.28,9.38-24.36,14.7c-0.58,2.87,0.32,5.83,2.38,7.91
					c3.04,3.16,7.22,4.95,11.61,4.97c6.01,0,11.13-3.2,15.65-6.03c2.08-1.5,4.36-2.69,6.79-3.52c1.62,0.43,1.86,0.95,1.97,1.25
					c1.3,2.98-0.52,11.71-6.48,22.02c-2.16,3.8-6.68,11.69-11.63,15.13c-3.57,2.27-7.76,3.35-11.97,3.09
					c-3.2,0-6.48-0.35-10.16-0.71c-3.97-0.47-7.96-0.73-11.95-0.8c-2.5-0.02-5,0.16-7.48,0.54c-6.64,1.02-20.9,6.79-20.14,17.18
					c0.28,3.93,2.79,10.55,16.34,10.68h0.22c1.21,0,2.49,0,3.8-0.22c-5.21,3.39-10.61,7.37-11.58,13.92
					c-0.54,3.99,0.74,8.01,3.48,10.96c3.34,4.23,8.4,6.73,13.79,6.81c8.43,0,15.13-6.14,20.62-11.07c1.52-1.46,3.13-2.83,4.82-4.08
					c1.77-1.16,3.85-1.77,5.97-1.75c2.72,0.08,5.39,0.75,7.82,1.97c-2.02,2.03-3.34,4.66-3.74,7.5c-0.5,3.18,0.45,6.41,2.57,8.82
					c3.24,3.76,8.52,5.34,17.64,5.34c12.97,0,31.51-3.31,37.63-4.32l1.45-0.26c4.85-0.94,9.62-2.29,14.24-4.04
					c4.9-2.06,10.09-3.32,15.39-3.74h1.15l-0.8,1.69c-1.92,4.08-4.32,9.16-4.17,14.65c0.26,4.75,0.8,9.49,1.62,14.18
					c1.45,9.34,2.7,17.29-0.93,24.36c-4.32,8.21-15.78,11.35-26.91,14.35c-4.32,1.19-8.86,2.42-12.97,3.95
					c-8.8,3.33-18.54,8.06-24.01,17.98c-2.15,4.56-3.31,9.53-3.41,14.57c-0.09,1.96-0.39,3.91-0.89,5.81
					c-0.6,0.15-1.22,0.22-1.84,0.19h-0.67c-1.86,0-3.7,0-5.47-0.19c-1.77-0.19-3.65-0.19-5.53-0.19c-5.5-0.12-10.95,1.1-15.89,3.54
					c-3.72,1.86-7.13,3.89-10.46,5.86c-5.03,3.28-10.44,5.95-16.1,7.95c-2.81,0.86-5.68,1.6-8.65,2.36
					c-8.95,2.29-18.2,4.65-26.61,10.63l-4.11,2.98c-6.48,4.75-12.67,9.25-19.67,11.71c-1.94,0.64-3.97,0.94-6.01,0.91
					c-7.39,0-16.19-3.09-24.68-6.07c-4.32-1.53-8.47-2.98-12.45-4.04c-14.63-3.83-29.7-5.75-44.82-5.68
					c-11.49,0.04-22.96,0.98-34.3,2.83c-7.95,1.27-15.8,3.35-23.41,5.34c-12,3.16-23.32,6.14-34.58,6.14c-0.82,0-1.62,0-2.44,0
					c-16.43-0.65-39.92-4.73-55.41-12.41c-2.16-1.52-4.15-3.27-5.92-5.23c-5.14-5.19-11.56-11.61-20.08-11.61c-0.52,0-1.02,0-1.53,0
					c-11.39,1.06-14.83,12.08-17.1,19.45c-0.8,2.64-2.33,7.54-3.5,8.43c-10.09,7.02-22.07,8-33.48,8c-3.89,0-7.91-0.13-11.8-0.26
					c-3.33,0-6.76-0.22-10.14-0.26h-3c-1.79,0-3.72,0-5.71,0c-8.95,0-20.19,0.71-27.79,6.22c-5.25,3.44-7.83,9.77-6.48,15.91
					c1.02,3.88,2.72,7.54,5.04,10.81c0.67,1.08,1.66,2.66,2.27,3.85c-1.96,0.5-3.99,0.71-6.01,0.65
					c-7.19-0.23-14.34-1.26-21.31-3.07c-2.62-0.63-5.23-1.36-7.98-2.16c-7.46-2.52-15.24-3.97-23.1-4.32c-1.6,0-3.19,0.09-4.78,0.26
					c-3.67,0.41-7.33,1.17-10.81,1.88c-4.34,1.06-8.78,1.7-13.25,1.9c-1.45,0.02-2.9-0.12-4.32-0.41
					c-8.65-1.84-16.08-7.09-24.01-12.64c-4.67-3.42-9.53-6.57-14.57-9.42c-6.76-3.72-14.35-5.64-22.07-5.6
					c-6.88,0.22-13.71,1.18-20.38,2.85c-3.63,0.8-7.39,1.6-11.13,2.16c-12.95,1.85-25.48,5.88-37.09,11.91
					c-2.92,1.51-6.14,2.87-9.53,4.32c-5.64,2.21-11.08,4.92-16.23,8.1c-12.12,7.97-20.14,21.61-24.49,41.47
					c-0.98,5.41-1.28,10.92-0.89,16.4c0.26,8.3,0.24,13.59-3.31,17.59c-3.46,4.27-6.44,8.91-8.88,13.83
					c-3.65,6.68-7.41,13.57-11.97,15.3c-0.54,0.2-1.11,0.31-1.69,0.3c-5.23,0-13.16-6.27-18.98-10.81
					c-2.87-2.16-5.58-4.32-8.17-6.05c-20.1-12.97-43.87-19.45-70.7-19.45h-1.43c-6.25,0.17-12.48,0.67-18.67,1.51
					c-5.32,0.63-10.33,1.23-15.3,1.36h-0.58c-2.75-0.08-5.49-0.32-8.21-0.71c-3.78-0.52-7.59-0.81-11.41-0.89
					c-5.15-0.17-10.26,0.99-14.85,3.35c-8.06,4.32-12.04,11.15-15.56,17.1c-3.52,5.94-5.81,9.53-10.44,11.48
					c-2.31,0.99-4.8,1.95-7.43,2.98c-11.89,4.58-25.35,9.77-33.63,21.01c-7.02,9.53-3.83,18.57-1.71,24.53
					c1.38,3.91,2.16,6.22,1.43,8.26c-2.16,6.48-7.93,11.15-11.61,11.15c-1.62-0.09-3.1-0.93-4.02-2.27
					c-2.32-2.89-4.48-5.9-6.48-9.01c-7.85-11.58-18.57-27.41-34.88-27.41c-4.59,0.06-9.09,1.25-13.1,3.48
					c-3.46,2.07-6.64,4.58-9.44,7.48c-2.74,2.57-7.35,6.89-9.42,6.89c-0.43-0.11-0.83-0.32-1.17-0.61c-0.67-0.42-1-1.22-0.82-1.99
					c0.43-4.82,6.85-12.51,11.48-14.96c6.4-3.08,13.1-5.49,19.99-7.18c9.94-2.81,20.25-5.73,29.93-12.45
					c17.79-12.3,30.63-24.92,38.15-37.52c2.99-5.31,5.54-10.85,7.63-16.58c3.05-7.78,5.92-15.13,11.3-20.49
					c5.32-4.88,11.11-9.22,17.29-12.97c3.96-2.61,8.06-5.34,11.97-8.34c8.13-6.27,13.55-14.09,18.8-21.61
					c3.01-4.55,6.33-8.88,9.94-12.97c8.93-9.64,19.04-20.58,18.74-40.2c0-2.46-0.26-5.14-0.48-8c-0.61-7.52-1.49-18.89,2.16-22.61
					c0.65-0.67,1.6-1.38,4.5-1.38c2.55,0.1,5.1,0.38,7.61,0.84c3.52,0.62,7.08,0.98,10.66,1.06c6.47,0.24,12.79-2.03,17.61-6.35
					c9.03-8.23,10.16-20.92,11-30.26c0-1.1,0.17-2.16,0.28-3.22c0.71-6.81,4.91-16.23,33.35-19.21l9.88-1.08
					c12.97-1.43,26.17-2.9,39.12-3.07h0.73c10.46,0,20.7,2.9,31.58,5.97c5.45,1.53,11.09,3.11,16.81,4.32
					c4.26,0.89,8.61,1.33,12.97,1.3c6.59-0.14,13.17-0.77,19.67-1.86c5.71-0.99,11.49-1.56,17.29-1.71h1.88h2.92
					c17.46,0,21.44-8.02,25.94-21.2c3.74-10.97,8.25-21.67,13.49-32.01c5.6-11.02,13.21-17.51,22.82-25.74
					c2.16-1.77,4.32-3.61,6.48-5.55c11.07-9.77,19.78-21.42,28.2-32.7l2.16-3c0.97-1.3,2.16-2.62,3.31-4
					c3.03-3.37,5.61-7.12,7.67-11.15c0.97-2.03,3.96-8.15,0.5-13.68c-2.01-3.13-5.51-4.98-9.23-4.88c-6.01,0-12.97,4.32-16.1,7.56
					s-6.2,6.64-9.16,9.92c-8.28,9.21-16.1,17.9-25.94,23.26c-4.62,2.6-9.82,4-15.13,4.06c-1.24,0-2.47-0.09-3.7-0.26l-1.02-0.13
					c-2.75-0.44-5.52-0.69-8.3-0.76c-5.44-0.1-10.76,1.6-15.13,4.84c-6.19,4.71-11.84,10.09-16.86,16.04
					c-2.65,3.15-4.83,6.65-6.48,10.42c-1.02,2.44-2.38,4.73-4.04,6.79c-4.32,4.47-10.81,6.48-20.81,6.48c-2.16,0-4.6,0-7.35-0.32
					l-3.18-0.22c-8.36-0.61-16.25-1.15-22.5-4.54c-11.28-6.09-18.91-18.13-26.3-29.74l-1.45-2.29
					c-5.36-8.41-13.44-21.07-26.97-23.77c-1.62-0.33-3.27-0.5-4.93-0.5c-9.08,0-16.77,4.93-22.97,8.88
					c-1.51,0.95-2.94,1.86-4.32,2.64c-7.29,4.07-15.43,6.37-23.77,6.7c-2.44,0.07-4.88-0.35-7.15-1.23
					c-0.71-0.35-1.24-0.97-1.47-1.73c-0.47-1.29-0.47-2.71,0-4c1.64-0.59,3.35-1.02,5.08-1.25c1.17-0.19,2.38-0.41,3.59-0.69
					c0.54-0.15,13.53-3.35,15.5-13.08c0.92-4.83-1.17-9.73-5.3-12.41c-2.35-1.67-5.18-2.53-8.06-2.44c-1.65,0.02-3.3,0.19-4.93,0.5
					c-0.45,0-0.84,0.15-1.19,0.17c-2.16,0.19-4.54,0.39-6.85,0.39c-3.17,0.25-6.34-0.51-9.06-2.16c-2.16-1.6-3.35-2.96-1.99-8.43
					l0.19-0.78c0.87-2.86,1.29-5.83,1.23-8.82c-0.08-5.81-4.08-10.83-9.73-12.21c-1.21-0.33-2.46-0.49-3.72-0.5
					c-8.15,0-13.77,6.48-15.8,9.36c-1.73,2.53-2.82,5.45-3.18,8.49c0,0.22,0,0.5-0.13,0.73c-1.54,0.45-3.15,0.64-4.75,0.56h-0.24
					c-3-0.1-5.98-0.43-8.93-0.99c-3.84-0.72-7.74-1.13-11.65-1.21h-0.76c-7.02-0.23-13.24,4.48-14.91,11.3
					c-0.62,2.53-0.81,5.14-0.58,7.74c0,0.82,0,1.6,0,2.16c-0.91,0-2.01-0.15-3.16-0.28c-1.86-0.24-3.73-0.37-5.6-0.39
					c-1.45-0.01-2.9,0.12-4.32,0.39c-7.95,1.58-10.81,8.13-12.45,11.63c-0.33,0.88-0.75,1.72-1.25,2.51
					c-1.47,1.62-3.63,2.42-5.79,2.16c-2.92-0.08-5.79-0.77-8.43-2.01c-3.48-1.51-6.26-4.28-7.78-7.76c-0.61-1.3-1.06-2.62-1.56-4.02
					c-1.64-4.71-3.7-10.57-10.81-14.83c-1.12-0.67-2.03-1.23-2.72-1.71l0.32-0.41c6.25-8.15,12.71-17.66,10.48-27.23
					c-1.18-4.91-4.56-9-9.16-11.09c-4.86-2.17-10.16-3.18-15.47-2.94h-6.2c2.62-1.91,5.48-3.47,8.52-4.63
					c1.84-0.82,3.76-1.66,5.64-2.64c4.88-2.26,9.08-5.75,12.19-10.14c1.6-2.58,2.76-5.41,3.46-8.36c0.27-1.26,0.71-2.47,1.3-3.61
					h0.3c2.14,0.21,4.25,0.65,6.29,1.32c3.59,1.16,7.32,1.82,11.09,1.97c1.61,0,3.21-0.18,4.78-0.56
					c7.28-1.82,17.98-5.06,26.73-12.3c3.18-2.8,5.95-6.02,8.26-9.57c1.65-2.89,3.94-5.36,6.7-7.22c4.49-1.98,9.27-3.21,14.16-3.65
					c4.38-0.56,8.72-1.46,12.97-2.68c9.65-3.12,18.33-8.67,25.22-16.1c2.62-2.72,6.48-4.32,11.09-6.12
					c4.78-1.7,9.32-4.02,13.49-6.92c15.86-11.84,28.59-31.21,34.99-53.19c5.19-17.92,4.08-34-3.29-47.76
					c-6.81-12.73-5.68-16.94,0.93-22.41c3.78-2.78,6.08-7.13,6.27-11.82c0.11-7.56-5.84-12.82-10.18-16.64
					c-1.27-1.05-2.45-2.19-3.54-3.41c-1.69-2.62-1.36-6.48-0.63-12.36c1.08-5.81,0.96-11.77-0.35-17.53
					c-3.16-10.98-12.62-17.29-25.94-17.29c-1.83,0-3.65,0.11-5.47,0.35c-9.23,1.12-18.91,2.72-27.21,6.72
					c-12.97,6.29-16.79,15.78-17.29,23.77c-3.09-1.95-6.51-3.31-10.09-4.02c-1.88-0.33-3.78-0.49-5.68-0.5
					c-1.61,0.01-3.21,0.12-4.8,0.35c2.79-2.72,6.48-6.14,11.56-10.35l0.95-0.8c3.85-3.29,7.85-6.33,12.08-9.55
					c10.81-8.21,21.89-16.73,30.04-29.05c4.79-7.85,8.58-16.28,11.28-25.07c0.61-1.69,1.19-3.39,1.82-5.08
					c1.71-3.75,1.39-8.12-0.84-11.58c-2.08-2.86-5.46-4.48-8.99-4.32c-3.31,0.21-6.56,0.94-9.64,2.16
					c-1.06,0.37-2.59,0.89-3.39,1.08c-9.08-0.99-16.38-9.1-19.91-15.86c-1.84-3.5-1.43-7.5-0.63-13.77s1.73-13.46-1.1-20.94
					c-0.5-1.3-1.02-2.42-1.49-3.44c-0.32-0.65-0.71-1.51-0.91-2.03c0.52-0.8,2.16-2.16,9.38-2.16c2.16,0,4.65,0.13,6.98,0.24
					s4.71,0.26,6.98,0.26c1.99,0.02,3.98-0.11,5.94-0.41c0.62-0.11,1.25-0.16,1.88-0.17c2.51,0,5.51,0.76,9.47,9.62
					c0.35,0.78,0.69,1.62,1.04,2.46c2.38,5.64,5.6,13.38,12.97,17.29c2.09,1.13,4.43,1.7,6.81,1.66c0.98,0,1.97-0.08,2.94-0.24
					c-1.01,1.12-1.88,2.36-2.57,3.7c-2.08,3.75-2.04,8.32,0.13,12.02c2.45,3.7,6.66,5.84,11.09,5.64c1.99-0.01,3.97-0.34,5.86-0.99
					c3.45-1.34,6.65-3.26,9.47-5.66c1.38-1.22,2.93-2.23,4.6-3c1.83-0.52,3.7-0.83,5.6-0.93c4.29-0.12,8.52-1.04,12.47-2.7
					c6.98-3.44,8.65-10.46,9.79-15.13c0.17-0.78,0.43-1.82,0.67-2.62c1.87,0.83,3.67,1.79,5.4,2.87c8.9,5.14,19.03,7.74,29.31,7.52
					h1.77c9.44-0.24,17.64-3.59,25.55-6.83c4.93-2.25,10.08-3.97,15.37-5.14c1.34-0.23,2.7-0.36,4.06-0.37
					c2.84-0.12,5.64,0.75,7.91,2.46c1.81,1.87,3.25,4.07,4.21,6.48c1.78,4.02,4.28,7.68,7.37,10.81c3.42,3.19,7.94,4.96,12.62,4.93
					c7.87,0.05,14.97-4.69,17.94-11.97c4.88-12.49-4.32-21.61-9.7-26.91c-1.28-1.28-2.46-2.46-3.33-3.48
					c-0.93-0.97-1.66-2.1-2.16-3.35c0.15-0.54,0.34-1.06,0.56-1.58c1.51,0.71,4.75,2.77,10.03,8.65c2.83,3.13,5.49,6.48,8.3,9.88
					c1.82,2.16,3.72,4.6,5.66,6.89c1.95,2.29,9.57,10.55,17.48,10.55c6.11,0.02,11.2-4.71,11.63-10.81
					c0.43-7.76-6.48-14.65-13.7-21.05l-0.97-0.89c-3.93-4.17-7.15-8.95-9.55-14.16c-1.82-3.57-3.82-7.04-6.01-10.4
					c-0.82-1.23-1.69-2.44-2.55-3.67c-3.8-5.42-7.39-10.55-7.61-15.8c-0.45-11.3,6.79-22.24,11.15-27.77
					c2.72-3.43,5.97-6.41,9.64-8.8c2.74-1.88,10.07-6.87,9.06-14.59c-0.97-7.39-8.06-9.88-13.25-11.69
					c-1.67-0.53-3.31-1.19-4.88-1.97c-2.64-1.49-5.25-4.91-1.97-18.24c0.17-0.67,0.32-1.36,0.5-2.16c1.6-6.59,3.39-14.09,7.74-17.07
					c1.3-0.8,2.65-1.52,4.04-2.16c4.95-2.55,11.76-6.05,14.59-13.64c1.66-5.21,2-10.75,0.99-16.12c-0.49-2.51-0.54-5.08-0.13-7.61
					c0.81-2.25,1.94-4.38,3.35-6.31c2.02-2.89,3.68-6.01,4.95-9.29c3.26-9.19,1.12-21.61-2.55-28.46c-3.98-7.48-9.7-8.65-12.77-8.65
					c-4.34,0.32-8.48,1.97-11.87,4.71c-0.94,0.68-1.94,1.29-2.98,1.82c-1.38-0.26-2.71-0.71-3.98-1.32
					c-3.03-1.5-6.35-2.35-9.73-2.51c-4.47,0.02-8.71,1.98-11.61,5.38c-4.17,5.06-7.5,10.75-9.88,16.86
					c-1.48,3.8-3.37,7.42-5.64,10.81c-1.92-2.37-3.39-5.07-4.32-7.98c0.37-0.45,1.02-1.12,1.51-1.66c0.5-0.54,1.62-1.69,2.51-2.77
					c4.26-5,7.44-10.82,9.36-17.1c1.73-6.64,0-10.81-1.86-13.16c-2.21-2.83-5.62-4.47-9.21-4.43c-4.84,0.25-9.38,2.44-12.58,6.07
					c-1.43,1.47-2.31,1.77-2.46,1.77c-0.93,0-4.88-2.83-8.95-11.48c-2.05-4.75-3.69-9.67-4.93-14.7
					c-2.68-9.68-5.73-20.66-13.92-29.22l-1.95-2.03c-6.09-6.33-11.87-12.32-14.74-19.45c-2.44-5.99-1.38-8.65,2.68-17.1l0.69-1.45
					c2.39-5.64,3.6-11.71,3.57-17.83c0.11-1.92,0.19-3.76,0.41-5.49c0.54-4.06,2.35-7.84,5.17-10.81c1.38-1.71,2.9-3.39,4.5-5.19
					c3.76-3.86,7.06-8.14,9.83-12.75c3.7-6.48,2.62-12.69,1.82-17.29c-0.45-1.72-0.58-3.51-0.39-5.27c1.1-3.74,4.32-7.82,7.28-11.78
					c1.53-1.99,3.11-4.04,4.54-6.16l0.8-1.19c2.35-3.65,4.89-7.17,7.61-10.55l1.79-1.56c4.82-4.08,10.81-9.16,9.01-16.17
					c-1.15-4.45-5.14-9.75-17.77-9.75c-1.66,0-10.24,0.13-14.89,2.92c-2.23,1.4-4.29,3.07-6.12,4.97c-1.64,1.64-2.29,2.16-3.44,2.57
					c-2.89,0.7-5.85,1.07-8.82,1.12c-1.28,0-2.55,0.15-3.8,0.26c-8.65,0.73-15.54,5.77-17.57,12.84c-0.42,1.48-0.6,3.02-0.54,4.56
					c-2.12-1.11-4.42-1.84-6.79-2.16c-0.59-0.03-1.18-0.03-1.77,0c-3.2,0.11-6.34,0.89-9.23,2.29c-0.71,0.3-1.38,0.58-1.97,0.78
					l-2.61,0.76c-7.41,2.38-17.55,5.66-20.01,18.76c-0.63,3.29-7.02,7.26-13.18,8.19c-0.49,0.03-0.98,0.03-1.47,0
					c-2.04-0.19-4.03-0.78-5.84-1.75c-1.95-0.84-4.05-1.28-6.18-1.3c-3.16,0.1-6.24,1.02-8.93,2.68c-0.5,0.27-1.01,0.52-1.53,0.73
					c-1.56,0-1.88-0.15-3.89-6.48l-0.48-1.51l-0.22-0.67c-1.19-3.72-3.2-9.94-8.65-13.14c-3.17-1.82-6.78-2.73-10.44-2.62
					c-1.71,0-3.33,0.13-4.78,0.26c-1.45,0.13-2.29,0.19-3.22,0.19c-0.41,0.04-0.82,0.04-1.23,0c-2.67-0.65-5.24-1.62-7.67-2.9
					l-1.36-0.71c-3.47-2.14-7.39-3.43-11.45-3.76c-3.34-0.05-6.52,1.42-8.65,4c-3.74,4.6-2.51,10.59-2.16,12.54
					c1.66,8,8,11.45,12.17,13.72c1.04,0.56,2.16,1.12,2.79,1.62c-0.38,1.79-0.92,3.54-1.6,5.23c-0.91,2.32-1.63,4.7-2.16,7.13
					c-0.42,3.3-0.38,6.64,0.13,9.92c0,0.91,0.22,2.16,0.3,3.24c-4.19-1.64-8.84-6.12-12.75-9.83c-1.02-0.97-1.99-1.92-2.94-2.79
					c-2.96-2.72-5.81-5.58-8.84-8.65c-9.21-9.25-18.74-18.82-31.53-24.55c-4.41-2.08-9.21-3.21-14.09-3.31
					c-5.8-0.06-11.42,2.02-15.78,5.86c-8.32,7.28-9.55,15.91-8.21,24.16c-4.08-7.67-8.95-15.8-15-25.11
					c-0.78-1.21-1.6-2.42-2.42-3.61c-2.51-3.31-4.56-6.95-6.09-10.81l0,0c-1.46-4.76-2.38-9.67-2.74-14.63
					c1.14,6.73,3.33,13.23,6.48,19.28c3.54,6.33,7.93,7.67,11,7.67c2.55,0,8.77-1.02,11.22-10.42c2.03-7.4,2.28-15.18,0.73-22.69
					c-0.6-2.23-1.32-4.44-2.16-6.59c-0.96-2.25-1.63-4.62-1.99-7.05c0-1.06-0.15-2.16-0.17-3.18c-0.13-14.24,8.36-26.45,17.29-39.36
					l1.21-1.75c5.48-7.39,10.13-15.36,13.88-23.77c1.81-4.61,3.37-9.32,4.65-14.11c1.18-4.41,2.62-8.74,4.32-12.97
					c1.4-3.26,3-6.48,4.69-9.86c2.58-4.91,4.88-9.96,6.89-15.13c2.88-7.99,5.31-16.14,7.26-24.4c4.32-18.33-7.74-27.38-16.6-34
					c-2.31-1.65-4.54-3.42-6.68-5.3c-6.72-6.12-10.07-13.96-11.61-27.06c-1.1-10.13-1.6-20.31-1.49-30.5
					c0-3.33,0.22-6.72,0.45-10.29c0.46-5.4,0.58-10.83,0.37-16.25c1.08,0,2.31,0.13,3.85,0.26c3.33,0.32,6.64,0.78,10.16,1.23
					c6.21,0.96,12.48,1.56,18.76,1.79h0.99c0.93,0,2.16,0,3.54,0c4.56,0,9.68-0.52,12.97-4.45c2.25-2.65,2.96-6.28,1.86-9.57
					c-2.16-6.16-8.77-8.13-16.64-9.92l-0.97-0.22c-2.72-0.82-5.47-1.82-8.39-2.87c-6.5-2.65-13.29-4.51-20.23-5.53
					c-1.31-0.15-2.63-0.22-3.96-0.22c-6.06,0.1-12.03,1.48-17.53,4.04c-0.73,0.32-1.84,0.71-3.03,1.15
					c-5.97,2.16-15,5.45-17.7,13.75c-2.16,6.68-0.61,12.97,0.67,17.9c0.63,2.11,1.03,4.29,1.19,6.48c-0.28,5.69-1.4,11.3-3.33,16.66
					c-0.52,1.75-1.06,3.52-1.56,5.32c-0.64-0.8-1.36-1.52-2.16-2.16c-3.47-2.51-7.48-4.16-11.71-4.82c-1.08-0.26-2.16-0.5-2.77-0.71
					l-0.35-0.11c-2.5-0.53-4.84-1.63-6.85-3.2c-0.71-0.78-1.49-1.75-2.29-2.79c-2.86-4.17-6.72-7.56-11.22-9.86
					c-2.48-1.01-5.13-1.55-7.8-1.58c-4.86-0.34-9.35,2.62-10.96,7.22c-2.46,7.48,4.19,12.51,7.05,14.65l0.67,0.5
					c2.1,1.96,3.71,4.39,4.69,7.09c0.11,0.37,0.19,0.74,0.24,1.12c-2.15,1.38-4.47,2.49-6.89,3.29c-4.45,1.73-9.49,3.67-13.12,7.52
					c-3.71,4.31-6.58,9.29-8.43,14.68c-0.35,0.84-0.67,1.66-1.04,2.49c-0.37,0.82-0.8,1.9-1.21,2.83c-1.97,4.58-4,9.29-5.66,14.33
					c-6.81,20.73-14.24,48.46-11.09,78.95c0.41,4.08,0,4.8-3.31,7.63l-0.78,0.63c-5.45,4.54-11.07,9.21-16.38,9.21
					c-0.84,0-1.67-0.11-2.49-0.32c0.04-2.82,0.62-5.61,1.71-8.21c0.91-2.77,1.92-5.47,2.98-8.36c3.5-9.49,7.13-19.3,7.5-30.47v-2.72
					c0.08-9.6,0.73-19.18,1.95-28.7c1.73-9.27,4.36-18.36,7.85-27.12l0.56-1.56c0.52-1.45,0.99-2.9,1.49-4.32
					c1.82-5.51,3.39-10.29,6.72-13.23c0.3-0.24,1.25-0.84,1.95-1.3c4.78-3.09,14.74-9.49,10.94-19.45
					c-1.53-3.96-5.4-8.65-15.24-8.65c-1.77,0-3.54,0.15-5.1,0.26l-1.79,0.13c0.56-1.49,1.36-3.26,1.9-4.32
					c1.83-3.35,2.99-7.02,3.41-10.81c0.04-5.47-3.1-10.47-8.06-12.79l-0.95-0.61c-2.16-2.79,0.76-8.99,3.5-14.11
					c5.06-8.87,9.4-18.14,12.97-27.71c1.85-6.13,2.87-12.47,3.03-18.87c0-1.62,0.19-3.24,0.35-4.84c0.32-2.36,0.86-4.68,1.62-6.94
					c1.95-5.26,2.69-10.89,2.16-16.47c-0.57-3.6-1.37-7.15-2.42-10.63c-1.62-5.71-2.68-9.49-0.97-14.78
					c2.92-9.14,8-17.48,13.36-26.35c2.33-3.83,4.73-7.78,6.96-11.82c3.93-7.13,4.32-14.67,4.5-21.31c0-2.01,0.17-3.89,0.32-5.68
					c0.85-8.8,3.58-17.32,8.02-24.96c2.72-3.72,5.95-7.04,9.6-9.86c8.65-7.56,19.45-16.97,17.7-32.05
					c-0.78-6.48-6.09-10.81-13.25-10.81c-3.21,0-6.36,0.8-9.19,2.31c-4.58,2.92-8.44,6.84-11.28,11.48
					c-0.86,1.23-1.69,2.38-2.44,3.33l-2.16,2.66c-8.65,10.81-17.66,21.83-27.17,31.9c-1.92,2.03-4.21,4.04-6.66,6.16
					c-6.48,5.66-13.98,12.08-17.07,22.07c-1.77,5.66-3.05,11.37-4.32,16.9c-1.49,7.94-3.75,15.73-6.74,23.23
					c-1.86,4.6-4.66,8.76-8.23,12.21c-0.38-1.66-1.12-3.22-2.16-4.56c-2.6-3.47-6.71-5.47-11.04-5.38c-7.09,0-14.09,4.71-20.66,9.51
					c-0.78,0.56-1.43,1.06-1.92,1.38c-7.02,4.71-16.79,5.21-26.17,5.32c-2.16,0-4.45,0.13-6.48,0.26c-2.03,0.13-4.63,0.24-6.83,0.24
					c-3.33,0.14-6.67-0.36-9.81-1.47c-1.68-0.73-3.2-1.76-4.5-3.05c1.2-0.42,2.47-0.62,3.74-0.58c3.19,0.15,6.35,0.62,9.44,1.4
					c1.79,0.37,3.63,0.76,5.51,1.08h0.48c2.29,0.45,4.61,0.69,6.94,0.73c7.78,0,10.94-4.02,12.1-6.48
					c4.17-8.43-3.44-14.63-6.29-16.99c-0.35-0.28-0.78-0.61-1.15-0.95c1.36-2.38,3.08-4.53,5.1-6.38c1.3-1.34,2.64-2.74,3.85-4.32
					c2.24-2.66,2.75-6.36,1.32-9.53c-2.66-5.94-9.12-6.48-19.71-6.89c1.48-2.12,2.26-4.64,2.23-7.22c-0.08-3.37-1.57-6.56-4.11-8.77
					c-3.36-2.72-7.35-4.54-11.61-5.3l-1.06-0.28c2.96-3.85,6.46-7.25,10.4-10.09c1.62-1.32,3.29-2.7,4.91-4.11l0.91-0.78
					c8.17-6.96,16.79-15.13,15.52-24.1c-0.67-4.34-3.55-8.02-7.61-9.7l-1.69-0.71l0,0c1.36-1.12,4.6-3.11,11.89-5.04
					c3.81-1.13,7.74-1.77,11.71-1.88h0.43c1.06,0,2.16,0,3.37,0.15c1.62,0,3.31,0.17,5.06,0.17c6.21,0.32,12.3-1.77,17.01-5.84
					c2.98-2.85,5.73-5.92,8.23-9.19c2.08-2.7,4.35-5.25,6.81-7.61l1.04-0.89c4.78-4.08,13.66-11.67,10.66-20.88
					c-2.53-7.72-10.57-8.47-14.01-8.8l-1.15-0.11c-1.24-0.11-2.41-0.64-3.31-1.51c-0.55-1.38-0.6-2.91-0.13-4.32l0.19-0.97
					c0.58-2.87,2.16-10.44,4.15-12.23l0,0c2.52,0.48,4.95,1.38,7.18,2.66l2.83,1.3c4.16,1.84,8.62,2.91,13.16,3.13
					c4.28,0.23,8.37-1.77,10.81-5.3c1.64-2.51,3-6.79,0.3-12.97c-2.94-6.74-8.65-9.51-12.97-11.52c-1.56-0.63-3.02-1.5-4.32-2.57
					c-0.96-1.85-1.32-3.95-1.02-6.01c0.04-1.34,0.44-2.64,1.15-3.78c2.34,0.61,4.54,1.67,6.48,3.11l1.53,0.93l1.28,0.78
					c3.06,2.1,6.51,3.56,10.14,4.32c0.71,0.11,1.44,0.17,2.16,0.17c6.78-0.31,12.9-4.13,16.17-10.07c2.12-4.3,1.5-9.45-1.58-13.12
					c-5.08-6.2-12.32-7.63-18.11-8.75c-1.84-0.31-3.66-0.75-5.45-1.32c-0.87-0.35-1.65-0.91-2.27-1.62l0,0
					c-0.35-1.04,0.95-5.25,1.73-7.76c0.24-0.71,0.45-1.45,0.67-2.16c0.24-0.75,0.36-1.53,0.37-2.31c1.25-1.51,2.33-3.16,3.22-4.91
					c0-0.22,0.26-0.45,0.39-0.69c0.95,0.32,2.03,0.76,2.9,1.08c4.21,1.93,8.72,3.08,13.34,3.41c5.76,0.26,10.96-3.41,12.64-8.93
					c2.55-7.89-3.67-15.13-10.53-18.67c-5.97-2.88-12.52-4.35-19.15-4.32h-0.54l0.32-0.11l0.48-0.13c2.8-0.71,5.46-1.87,7.89-3.44
					c4.45-2.9,7-7.97,6.68-13.27c-0.52-6.03-4.97-12.49-15.86-12.49c-6.38,0.35-12.68,1.6-18.72,3.7l-1.32,0.41
					c-1.12,0.35-2.28,0.54-3.46,0.56c-2.14,0.04-4.16-0.98-5.4-2.72c-6.07-7.15-7.67-24.9-6.14-34.58c0.17-1.06,0.3-2.16,0.43-3.31
					c0.13-1.15,0.39-3.33,0.73-5.04l1.43,1.17c0.99,0.82,2.01,1.66,3.09,2.42c5.97,4.13,13.12,8.36,20.9,8.49h0.32
					c8.17,0,14.09-4.47,14.72-11.11c0.76-7.98-6.07-11.78-8.65-13.21l-0.8-0.45c-8.65-5.58-16.71-11.17-20.4-18.48
					c-1.27-2.26-1.69-4.91-1.17-7.46l1.73-0.48c4.55-0.81,8.64-3.3,11.43-6.98c1.4-2.01,5.42-9.25-1.84-17.55
					c-2.96-3.09-6.26-5.84-9.83-8.19c-2.17-1.45-4.23-3.07-6.16-4.82c-1.38-5.94-0.52-13.94,1.99-17.79
					c0.28-0.45,0.56-0.82,0.78-1.12c2.82,3.7,5.31,7.64,7.43,11.78c4.65,8.45,8.65,15.73,16.25,15.73c1.89,0,3.74-0.52,5.36-1.49
					c7.12-4.14,10.65-12.5,8.65-20.49c-1.36-5.44-3.55-10.63-6.48-15.41l-1.17-2.16l-0.95-1.73l0.19,0.13l0.28,0.17
					c0.43,0.26,1.04,0.69,1.69,1.15c3.07,2.48,6.7,4.17,10.57,4.93c0.72,0.09,1.44,0.13,2.16,0.13c2.64-0.08,5.26-0.59,7.74-1.51
					c0.7-0.26,1.43-0.47,2.16-0.63c2.43,0.98,4.71,2.28,6.79,3.87c4.55,3.55,9.89,5.95,15.56,7c0.92,0.13,1.84,0.2,2.77,0.22
					c2.49,0.04,4.94-0.62,7.07-1.92c3.95-2.08,6.22-6.37,5.71-10.81c-0.14-1.27-0.51-2.5-1.1-3.63c7.89-0.93,15.67-5.68,16.86-13.98
					c1.82-12.62-11.56-17.9-17.29-20.14c-0.99-0.39-2.01-0.78-2.55-1.04c-4.94-2.28-9.72-4.89-14.31-7.8
					c-4.73-2.85-8.56-6.97-11.07-11.89c2.07,0.93,4.32,1.4,6.59,1.38c7.17-0.15,13.67-4.25,16.88-10.66
					c3.63-7.35,1.62-14.93,0.15-20.49c-0.24-0.84-0.45-1.69-0.65-2.51c-1.9-7.42-5.2-14.41-9.73-20.6c-0.65-0.91-1.34-1.77-2.16-2.7
					l-1.19-1.53h1.1c2.9,0.1,5.79,0.39,8.65,0.89l2.44,0.37l2.53,0.39c4.93,0.92,9.92,1.44,14.93,1.56
					c16.12,0,20.14-8.43,21.12-13.44c1.56-8.13-3.37-14.33-6.96-18.87c-1.49-1.64-2.72-3.5-3.65-5.51
					c-0.96-7.12,0.39-14.36,3.87-20.64c4.65-9.21,4.15-18.44,3.7-26.58c-0.39-7.02-0.73-13.12,2.16-18.46
					c4.86-9.32,15.89-12.62,24.55-12.62c3.85-0.02,7.67,0.62,11.3,1.9c5.79,1.92,10.5,6.2,12.97,11.78c0.11,0.39,0.28,1.4,0.39,2.16
					c0.71,4.32,2.16,12.34,9.81,14.35c1.12,0.29,2.28,0.44,3.44,0.43c9.14,0,13.98-8.65,17.29-14.37c0.75-1.44,1.59-2.83,2.51-4.17
					c1.49-0.94,3.23-1.42,4.99-1.38c1.31-0.14,2.63,0.27,3.63,1.12c0.16,1.2,0.24,2.42,0.22,3.63c0,4.13,0.15,10.37,4.78,15.13
					c2.45,2.79,5.93,4.44,9.64,4.58c7.39,0,10.46-6.48,11.93-9.75c0.19-0.41,0.45-0.97,0.67-1.38h0.32c1.02,0.04,2.04,0.14,3.05,0.3
					c1.49,0.22,2.99,0.35,4.5,0.41h1.56c5.07,0.06,10.07-1.12,14.59-3.41c4.24-2.24,8.21-4.94,11.84-8.06
					c2.88-2.6,6.11-4.78,9.6-6.48c2.03-0.79,4.2-1.17,6.38-1.12c4.92,0.27,9.79,1.09,14.52,2.44c6.34,1.79,12.87,2.8,19.45,3.03
					c4.42,0.05,8.81-0.68,12.97-2.16c4.02-1.58,7.9-3.51,11.58-5.77c3.78-2.6,8.03-4.44,12.51-5.42c1.79-0.23,3.6-0.33,5.4-0.3
					c1.64,0,3.31,0,5.08,0c1.77,0,3.72,0.13,5.64,0.13c4.04,0.06,8.08-0.37,12.02-1.3c5.38-1.57,10.48-3.96,15.13-7.09
					c2.34-1.51,4.77-2.86,7.28-4.04c3.02-1.25,6.26-1.86,9.53-1.79 M522.46,189.09L522.46,189.09 M558.53,200.53L558.53,200.53
					 M379.38,335.56L379.38,335.56 M319.43,415.7L319.43,415.7 M220.14,471.77c0.66,0,1.32,0.08,1.97,0.22
					c5.9,1.34,10.09,6.85,12.06,10.03c7.07,11.3,8.26,26.15,8.52,39.21c0,2.53,0,5.06,0,7.56c0,12.97,0.22,26.45,4.06,39.64
					c4.45,15.32,16.17,33.24,35.73,37.65c3.55,0.78,7.17,1.16,10.81,1.12c3.35,0,6.61-0.24,9.79-0.48c3.18-0.24,5.81-0.43,8.65-0.43
					h0.93c3.71-0.09,7.37,0.88,10.55,2.79c-6.98,2.43-13.23,6.61-18.15,12.12c-1.39-3.08-4.44-5.08-7.82-5.1H286.4H275.6h-4.52
					c-4.02,0-8.77,0-13.66,0.45c-2.44,0.19-4.84,0.45-7.18,0.71c-3.92,0.52-7.87,0.83-11.82,0.91c-2.69,0.05-5.38-0.27-8-0.93
					c-7.37-1.97-14.18-8.08-18.2-16.38c-3.59-7.41-5.27-16.56-5.1-27.9v-1.04c0.29-4.24-0.19-8.5-1.4-12.58
					c-2.15-6.97-8.39-11.89-15.67-12.36h-0.3c-4.92,0.23-9.6,2.17-13.23,5.49c-0.56,0.43-1.12,0.89-1.69,1.28
					c-2.46,1.7-5.38,2.6-8.36,2.57c-2.04,0.01-4.06-0.41-5.92-1.25c-4.59-2.23-7.7-6.68-8.21-11.76c-0.35-4,2.16-8.52,4.84-8.8h0.54
					c0.96,0.04,1.92,0.12,2.87,0.26c1.4,0.15,3,0.35,4.73,0.37h0.17c7.24,0,13.21-3.57,15.58-9.29c2.51-6.09,0.41-13.14-5.64-18.85
					l-1.21-1.12l-1.66-1.56h0.13c1.97,0.26,4.47,2.4,6.85,5.84c0.82,1.17,1.62,2.44,2.46,3.78c2.29,3.95,5.08,7.59,8.32,10.81
					c4.36,4.21,10.15,6.62,16.21,6.76c3.12,0.02,6.2-0.77,8.93-2.29c6.88-4.16,10.73-11.93,9.88-19.93
					c-0.71-5.85-2.76-11.45-5.99-16.38l-0.67-1.12c-3.41-5.77-7.31-12.32-5.73-16.36c1.49-2.51,4.26-3.98,7.18-3.78 M320.47,472.87
					L320.47,472.87 M311.56,509.8L311.56,509.8 M347.33,608.66L347.33,608.66 M295.46,640.04c-0.41,1.64-0.78,3.29-1.15,4.91
					c-0.43,2.03-0.86,3.93-1.38,5.75c-1.4,5.04-3.33,8.41-5.75,9.99c-0.78,0.47-1.68,0.72-2.59,0.71c-0.53,0.04-1.07-0.08-1.53-0.35
					c-0.37-0.54-0.43-3.44,3.8-9.49c2.72-3.93,5.62-7.78,8.65-11.52 M329.8,653.25L329.8,653.25 M312.51,688.35L312.51,688.35
					 M474.02,1007.98L474.02,1007.98 M424.99,1290.99L424.99,1290.99 M739.04,1313.93L739.04,1313.93 M764.97,1751.69
					L764.97,1751.69 M648.07,1776L648.07,1776 M436.94,2144.78L436.94,2144.78 M575.93,2253.23L575.93,2253.23 M741.05,151.92
					c-4.45-0.07-8.86,0.79-12.97,2.51c-7.13,3.09-13.4,8.77-21.03,10.68c-3.25,0.75-6.58,1.1-9.92,1.04
					c-3.57,0-7.15-0.24-10.81-0.24c-2.17-0.02-4.33,0.11-6.48,0.39c-9.77,1.32-16.92,8.36-25.94,11.63
					c-3.2,1.14-6.58,1.71-9.99,1.66c-11.45,0-23-5.47-33.91-5.47c-3.3-0.05-6.57,0.54-9.64,1.75c-8.08,3.29-14.09,10.81-21.98,14.76
					c-3.33,1.73-7.05,2.6-10.81,2.53h-1.28c-2.62,0-5.27-0.73-7.82-0.73c-2.27-0.09-4.48,0.68-6.2,2.16
					c-2.59,2.16-3.52,9.01-6.72,9.01c-1.38-0.19-2.63-0.91-3.48-2.01c-3.93-3.98-0.99-11.91-3.48-16.58
					c-2.38-3.9-6.74-6.14-11.3-5.81c-3.78-0.04-7.47,1.14-10.53,3.37c-4.06,3.22-7.67,16.56-14.16,16.56
					c-0.42,0-0.84-0.05-1.25-0.15c-3.37-0.86-3.13-8.1-4.32-11.09c-3.31-8.02-9.97-14.2-18.22-16.9c-4.57-1.62-9.39-2.44-14.24-2.42
					c-12.97,0-26.11,5.62-32.2,17.29c-7.72,14.74,1.47,30.69-5.84,45.17c-4.23,8.09-5.71,17.34-4.19,26.35
					c1.36,7.46,12.06,13.62,10.61,21.18c-0.97,5.04-6.48,6.48-12.62,6.48c-5.48-0.23-10.93-0.85-16.32-1.86
					c-4.06-0.74-8.16-1.18-12.28-1.34c-4.32,0-8.39,0.84-10.53,3.83c-3.24,4.54,3.18,10.53,5.6,14.01c3.9,5.3,6.75,11.29,8.41,17.66
					c1.28,5.34,3.8,11.84,1.17,17.29c-1.78,3.44-5.25,5.66-9.12,5.84c-1.12,0.01-2.23-0.23-3.24-0.71c-3.93-1.9-4.91-8.65-9.42-9.29
					c-0.19-0.02-0.39-0.02-0.58,0c-3.39,0-3.59,5.53-3.7,8.15c-0.56,12.38,6.74,21.61,16.62,28.27c4.92,3.12,10.04,5.91,15.32,8.36
					c4.43,2.16,16.08,4.97,15.13,12.19c-0.65,4.56-6.48,6.74-11.13,6.74c-1.03,0.01-2.05-0.11-3.05-0.37
					c-2.05-0.58-15.13-7.5-21.98-7.5c-3.2,0-5.1,1.47-4.02,5.75c1.6,6.38,11.02,8.54,16.21,10.31c2.46,0.84,12.04,3.83,6.48,7.26
					c-0.76,0.45-1.63,0.66-2.51,0.63c-0.52-0.01-1.04-0.05-1.56-0.13c-8.45-1.21-13.29-8.15-21.09-10.59
					c-0.77-0.24-1.57-0.37-2.38-0.37c-3.48,0-6.48,2.16-9.99,2.16h-1.06c-3.03-0.39-6.29-3.33-8.82-4.86
					c-2.96-1.82-7.35-4.58-10.81-4.58c-2.36,0-4.32,1.25-5.19,4.86c-0.95,4,2.64,9.27,4.32,12.56c3.96,7.5,12.43,20.4,2.16,26.56
					c-0.28,0.18-0.61,0.28-0.95,0.28c-5.79,0-13.75-28.38-23.77-28.38c-2.49,0-5.08,1.71-7.82,5.92
					c-4.32,6.64-4.95,17.92-2.92,25.42c1.86,6.94,24.34,14.89,18.91,22.74c-3.37,4.84-11.69,2.16-13.98,8.65
					c-1.66,4.98-1.17,10.42,1.36,15.02c4.84,9.55,14.7,16.19,23.45,21.83c1.95,1.25,5.77,2.68,5.51,5.58
					c-0.26,2.9-3.93,3.29-6.12,3.29h-0.17c-5.71,0-11.63-3.83-16.14-6.96c-2.96-2.16-7-6.48-11.02-6.48c-1.19,0-2.35,0.4-3.29,1.15
					c-3.98,3-4.08,11.43-4.8,15.89c-2.29,14.22,0.65,47.22,20.06,47.22c1.99-0.03,3.97-0.33,5.88-0.91
					c2.92-0.86,11.37-3.76,17.61-3.76c3.91,0,6.96,1.12,7.26,4.6c0.01,2.2-1.12,4.24-2.98,5.4c-1.84,1.1-3.85,1.91-5.94,2.4
					c-5.3,1.6-18.35,6.38-13.96,14.11c1.58,2.79,4.73,3.46,8.02,3.46c2.74,0,5.6-0.48,7.74-0.54h0.91
					c5.24-0.01,10.41,1.17,15.13,3.44c2.42,1.21,7.28,4.99,6.22,8.3c-0.57,1.92-2.44,3.16-4.43,2.94c-5.38,0-13.88-5.06-17.79-5.06
					c-0.22-0.02-0.43-0.02-0.65,0c-4.47,0.84-5.45,6.33-8,9.14c-5.02-8.01-13.89-12.77-23.34-12.54h-1.1
					c-6.12,0-12.3,0.91-18.37,0.91c-3.03,0.03-6.05-0.27-9.01-0.91c-14.78-3.67-25.16-17.42-29.37-31.97
					c-4.21-14.55-3.5-29.87-3.8-44.98c-0.3-15.11-1.84-30.8-9.81-43.61c-4.04-6.48-10.05-12.19-17.51-13.88
					c-1.27-0.29-2.57-0.43-3.87-0.43c-6.48,0-12.97,3.35-15.13,9.27c-3.29,8.39,2.42,17.29,7,25.05c2.54,3.79,4.18,8.12,4.8,12.64
					c0.56,4.62-1.55,9.16-5.45,11.71c-1.45,0.79-3.08,1.2-4.73,1.19c-3.89-0.14-7.6-1.72-10.4-4.43c-4-3.74-6.48-8.8-9.6-13.31
					c-3.11-4.52-7.39-8.75-12.84-9.47c-0.37-0.03-0.75-0.03-1.12,0c-5.12,0-10.42,4.32-9.49,9.38c0.63,3.46,3.74,5.81,6.29,8.26
					c2.55,2.44,4.93,6.01,3.59,9.25c-1.15,2.81-4.54,3.96-7.59,3.96l0,0c-2.57,0-5.14-0.63-7.69-0.63c-0.48,0-0.97,0-1.45,0
					c-8.1,0.86-13.25,10.01-12.56,18.11c0.78,8.18,5.79,15.35,13.21,18.89c3,1.37,6.26,2.07,9.55,2.05
					c4.78,0.03,9.46-1.43,13.38-4.17c3.11-2.16,6.07-5.34,9.88-5.34h0.15c3.63,0.43,6.65,2.98,7.67,6.48
					c0.92,3.47,1.23,7.06,0.93,10.63c-0.5,10.92,1.54,21.81,5.97,31.81c4.75,9.79,13.23,18.15,23.77,20.96
					c3.34,0.84,6.78,1.25,10.22,1.21c6.48,0,13.14-1.15,19.67-1.66c4.32-0.32,8.65-0.41,12.97-0.41h4.5l21.72,0.13
					c-6.27,6.96-12.1,14.3-17.46,21.98c-4,5.73-7.78,13.46-3.89,19.28c2,2.75,5.25,4.31,8.65,4.17c2.61,0,5.16-0.75,7.35-2.16
					c4.99-3.29,7.72-9.1,9.34-14.87c1.62-5.77,2.36-11.8,4.67-17.29c4.95-11.44,15.8-19.22,28.23-20.23
					c-2.9,9.6-6.07,16.62,4.6,21.96c6.48,2.59,15.67,1.84,20.4,7.65c3.41,4.15-4,9.06-7.89,9.06h-0.67
					c-3.04-0.85-5.9-2.24-8.45-4.11c-3.74-2.16-8-5.27-12.51-5.27H329c-10.29,0.73-11.37,18.09-5.86,24.21
					c4.32,4.91,12.97,5.19,15.73,11.76c2.16,4.75,0,6.2-3.29,6.2c-3.35-0.22-6.64-1.03-9.7-2.4c-3.78-1.66-8.84-4.69-13.46-4.69
					c-1.81-0.04-3.59,0.53-5.04,1.62c-5.01,3.74-6.61,12.77-7.78,18.5c-1.71,8.36,1.56,14.26,10.24,15.95
					c2.44,0.5,7.61,0.11,8.65,3.11c1.38,4.21-6.48,10.29-9.21,12.64c-5.68,5.08-9.55,11.74-15.13,16.86
					c-3.11,2.6-7.11,3.88-11.15,3.57c-2.77,0-5.58-0.28-8.28-0.32h-0.69c-4.72,0.11-9.41,0.84-13.94,2.16
					c-7,1.86-22.2,6.76-18.57,16.88c1.12,3.11,4.32,3.89,7.05,5.12c9.12,4.11-7.93,17.51-10.98,20.21
					c-6.35,5.62-14.59,10.81-18.37,18.69c-5.66,11.69,10.31,8.97,15.56,13.9c4.82,4.5-4.54,8.08-5.01,12.17
					c-0.48,4.08,3.67,5.4,6.87,5.92c2.16,0.37,19.45,0.24,17.57,2.62c-4.32,5.27-12.06,10.66-11.07,18.41
					c0.56,4.19,10.5,7.69,8.49,11.78c-0.61,1.23-2.4,1.6-4.32,1.6c-2.03-0.07-4.04-0.3-6.03-0.69c-5.23-0.86-10.81-2.59-16.34-2.59
					c-3.6-0.1-7.15,0.93-10.14,2.94c-8.65,5.92,1.25,14.87,7.67,17.42c4.15,1.53,8.55,2.27,12.97,2.16c4.54,0,9.12-0.45,13.51-0.5
					c10.57-0.13,21.83-0.71,30.88-6.76c3.26-2.16,11.89-9.44,17.79-9.44c1.62-0.07,3.17,0.65,4.15,1.95
					c2.01,2.66-1.71,6.81,1.88,9.6c0.91,0.7,2.03,1.06,3.18,1.04c2.83-0.26,5.51-1.37,7.69-3.18c5.48-4.48,9.74-10.27,12.38-16.84
					c5.77-13.1,7.15-27.49,11.39-41.06c3.37-10.81,14.35-17.01,21.76-24.85c10.53-11.15,20.1-23.15,29.74-35.06
					c3.44-4.32,6.35-9.96,11.24-12.71c1.54-0.79,3.24-1.2,4.97-1.21c2.16,0,4.32,0.82,4.65,3.24c1.92,16.53-19.04,24.55-26.13,36.48
					c-5.1,8.74-8.24,18.49-9.19,28.57c-0.73,7.91,0.17,16.45-3.78,23.64c-7.2,12.97-16.4,25.31-20.99,39.7
					c-3.65,11.41,1.47,18.26,3.07,29.31c1.08,7.39-3.13,14.13-3.78,21.4c-0.65,7.26-0.54,14.67-2.92,21.61
					c-3.41,9.12-7.54,17.96-12.34,26.43c-3.8,7.07-8.49,16.94-2.16,24.08c2.16,2.46,6.87,3.11,6.72,7.18
					c-0.24,6.38-12.75,20.42-0.69,23.95c0.85,0.22,1.72,0.31,2.59,0.28c2.44,0,5.51-0.43,8.32-0.43c3.29,0,6.18,0.58,7.18,3.13
					c1.69,4.32-7.93,8.75-10.46,10.98c-6.29,5.53-7.98,13.62-10.81,21.22c-3.52,9.86-7.28,19.95-8.82,30.26
					c-1.36,10.75-2.08,21.58-2.16,32.42c-0.41,12.82-6.05,24.36-10.05,36.35c-2.16,6.48-4.86,16.71,3.98,19.19
					c1.63,0.47,3.32,0.71,5.01,0.71c8.77,0,16.14-6.48,22.67-11.82c5.42-4.5,7.13-7.91,6.48-15.13c-2.7-26.22,2.53-50.49,10.7-75.36
					c1.86-5.66,4.32-11,6.61-16.47c2.16-5.01,3.96-10.57,7.78-14.61c6.74-7.05,26.19-6.18,21.87-20.45
					c-1.41-4.17-3.85-7.93-7.09-10.91c-1.21-1.15-6.05-4.02-5.36-6.09c0.3-0.95,1.45-1.28,2.74-1.28c1.48,0.03,2.94,0.32,4.32,0.84
					c4.58,2.03,7.35,7.05,10.63,10.59c2.92,3.16,6.89,4.32,10.81,5.68c3.33,1.08,8.9,1.64,11.63,3.93
					c4.63,3.87-0.5,12.97,5.86,15.58c1.27,0.57,2.63,0.9,4.02,0.97c4.71,0,3.7-6.07,4.32-10.81c1.62-10.81,7.11-21.22,6.94-32.27
					c-0.11-7.09-4.58-14.42-2.29-21.48c1.79-5.47,11.33-7.61,15.99-9.66c4.4-2.07,9.19-3.2,14.05-3.31c0.99,0,1.98,0.05,2.96,0.15
					c9.47,1.08,18.18,5.42,27.21,8.13c1.73,0.52,14.2,2.66,11.52,5.88c-0.93,1.1-3.72,1.34-6.31,1.34c-1.77,0-3.44-0.11-4.32-0.13
					c-9.49-0.19-18.85-2.16-28.29-2.98c-1.71-0.15-3.39-0.3-4.93-0.3c-5.1,0-8.86,1.49-8.39,8.45c0.61,8.88-0.78,17.83-0.8,26.71
					c-0.11,10.53,0.41,21.05,1.56,31.51c1.45,12.56,4.71,23.77,14.37,32.42c10.16,9.25,24.66,14.33,20.68,30.86
					c-1.89,7.94-4.22,15.76-7,23.43c-3.11,8.47-7.87,16.34-11.43,24.64c-3.76,8.77-5.34,18.33-8.99,27.15
					c-3.5,7.83-7.85,15.26-12.97,22.15c-9.57,14.03-20.38,28.44-20.12,46.23c0,1.3,0.06,2.59,0.19,3.89c0.52,5.47,3.09,9.81,4.32,15
					c1.22,6.09,0.98,12.38-0.71,18.35c-0.67,2.59-1.69,3.96-2.85,3.96s-2.16-1.06-3.46-3.24c-3-5.89-4.98-12.25-5.86-18.8
					c-0.8-5.12-2.53-10.94-8.47-10.94h-0.71c-12.15,0.97-6.61,24.68-4.43,31.88v0.15c2.01,6.12,6.05,11.15,9.51,16.47
					c7.89,11.82,14.87,24.22,20.86,37.11c3.39,7.52,2.72,16.49,9.83,22.17c0.76,0.67,1.73,1.05,2.74,1.08
					c3.54,0,6.35-5.25,6.48-8.95c0.67-15.13-18.78-35.47-3.76-48.67c2.78-2.45,6.37-3.76,10.07-3.7c3.66,0.1,7.25,0.97,10.55,2.55
					c15.26,6.83,25.94,20.58,38.06,31.64c6.48,5.99,14.83,15.41,24.36,15.56h0.17c9.64,0,3.91-14.5,5.1-20.9
					c1.19-6.4,7.31-15.02,1.28-20.16c-4.08-3.52-11.28-4.82-12.54-10.81c-0.97-4.67,0.28-6.14,2.36-6.14c2.68,0,6.74,2.49,9.06,3.61
					c3.08,1.61,6.36,2.81,9.75,3.59c0.96,0.19,1.94,0.27,2.92,0.26c2.59,0,5.36-0.45,8-0.45c2.09-0.09,4.17,0.39,6.01,1.38
					c2.79,1.66,4.04,6.22,4.97,9.08c2.16,6.87,3.78,13.7,12.45,13.94h0.24c4.32,0,6.61-3.44,10.37-3.44
					c0.98,0.02,1.95,0.23,2.85,0.63c2.85,1.44,5.97,2.27,9.16,2.42c0.92,0,1.84-0.07,2.74-0.22c7.8-1.17,18.78-6.48,20.4-15.13
					c1.71-9.12,8.75-10.24,17.01-12.97c2.85-0.99,5.47-2.62,8.41-2.62c0.28-0.02,0.56-0.02,0.84,0c4.08,0.43,7.33,3.87,11.5,4.32
					h1.43c8.65,0,2.27-8.32,3.63-12.97c1.21-4.32,5.99-6.27,10.01-6.61c4.84-0.08,9.67-0.66,14.39-1.73
					c5.47-1.66,6.96-5.66,11.5-8.41c3.33-1.25,6.88-1.82,10.44-1.69c4.47,0,8.77,0.82,9.38,3.24c0.61,2.42-7.31,7.98-8.9,9.88
					c-3.27,4.03-6.31,8.25-9.1,12.62c-4.32,6.48-10.81,12.82-12.97,20.36c-2.01,6.96,3.24,13.72-0.65,20.64
					c-3.57,6.31-9.06,11.15-13.55,16.77c-3.82,4.21-6.26,9.49-7,15.13c-0.84,6.89,0,14.16-3.2,20.62
					c-4.43,9.25-7.8,15.13-3.59,25.52c3.87,9.47,11.52,16.86,18.46,24.12c10.61,11.11,10.81,27.95,17.29,41.6
					c3.44,7.37,9.47,16.47,16.77,16.47c3.35-0.19,6.48-1.76,8.65-4.32c1.6-1.93,3.89-3.16,6.38-3.46c2.44,0,3.91,2.16,2.7,6.76
					c-1.58,5.06-4.19,9.75-7.65,13.77c-3.26,3.96-7.13,6.07-5.79,11.84c1.15,4.84,7.11,15.95,13.21,15.95
					c1.66-0.02,3.24-0.75,4.32-2.01c7.95-8.17,9.57-21.07,17.29-29.63c1.26-1.58,3.17-2.5,5.19-2.53c4.32,0,8.8,3.83,13.7,3.83
					c1.2,0.01,2.38-0.23,3.48-0.71c3.76-1.64,7.76-5.81,11.37-5.81c1.84,0,3.57,1.06,5.12,4.02c2.98,5.6,4.32,15.43,2.16,21.61
					c-2.16,6.18-6.85,10.2-8.49,16.19c-2.16,7.52,2.16,15.54-0.61,23c-2.36,6.29-10.42,8.34-15.45,11.8
					c-7.67,5.27-9.66,15.82-11.71,24.23c-2.36,9.53-4.02,22.11,6.09,27.82c7.22,4.08,21.61,4.56,8.45,13.57
					c-4.37,2.9-8.26,6.47-11.52,10.59c-7.33,9.25-13.51,21.4-12.97,33.5c0.39,9.27,6.7,16.6,11.63,23.93
					c5.86,8.65,8.95,18.61,16.79,25.94c2.49,2.33,16.23,13.51,10.68,17.42c-0.5,0.34-1.09,0.51-1.69,0.5c-3.7,0-9.16-5.47-10.81-7.5
					c-4.78-5.62-9.21-11.52-14.16-16.99c-4.5-4.95-11.58-11.78-17.29-11.78c-3.31,0-6.18,2.16-7.93,8.23
					c-1.23,4.32,1.19,8.02,3.93,11.26c5.06,6.03,15.13,12.45,11.61,21.61c-1.67,3.97-5.59,6.54-9.9,6.48
					c-2.48,0.03-4.88-0.89-6.7-2.57c-5.32-5.01-6.29-13.12-12.15-17.74c-3.79-2.93-8.48-4.46-13.27-4.32
					c-1.97-0.07-3.95,0.04-5.9,0.32c-13.55,2.38-25.72,11.5-39.64,11.84h-1.56c-8.69,0.19-17.27-1.97-24.83-6.27
					c-2.92-1.77-7.33-4.45-11.17-4.45c-2.1-0.06-4.1,0.96-5.27,2.7c-3.24,4.5-2.16,13.53-7.69,16.25c-5.53,2.72-11.5,1.6-17.03,3.48
					c-5.53,1.88-8.84,6.83-14.16,8.65c-0.96,0.34-1.98,0.52-3,0.52c-2.87,0-5.3-1.51-3.48-5.17c2.27-4.52,13.34-9.36,12.28-15.13
					c-0.13-2.53-2.29-4.47-4.82-4.33c-0.05,0-0.1,0.01-0.15,0.01c-4.5,0-10.96,3.44-15.41,3.44c-0.91,0.03-1.82-0.17-2.64-0.58
					c-5.49-3.05-7.8-10.44-10.2-15.82c-3.63-8.15-8.49-14.74-17.29-14.74c-1.1,0-2.2,0.1-3.29,0.28c-1.5,0.23-3.02,0.33-4.54,0.3
					c-4.19,0-9.16-0.5-13.96-0.5c-7.33,0-14.24,1.19-17.29,7.28c-2.27,4.52,0.63,7.85,2.16,12.1c4.32,11.58-3.85,24.16,2.16,35.64
					c5.36,10.27,15.45,19.6,27.45,20.53h0.26c3,0,9.47-3.29,12.97-3.29c1.95,0,2.92,1.08,1.71,4.32
					c-3.57,9.81-6.48,19.58-12.19,28.36c-10.29,15.6-26.56,24.85-40.52,36.74c-3.16,2.68-29.26,23.77-22.24,28.94
					c1.05,0.74,2.32,1.12,3.61,1.08c0.73-0.01,1.45-0.1,2.16-0.28c1.93-0.59,3.83-1.31,5.66-2.16c2.76-1.05,5.69-1.59,8.65-1.6
					c1.41,0,2.81,0.13,4.19,0.37c6.35,1.1,10.94,6.48,16.75,7.74c0.59,0.14,1.19,0.22,1.79,0.24c4.32,0,1.9-5.51,1.69-9.16
					c-1.23-19.58,21.94-23.36,36.87-25.18c1.43-0.18,2.88-0.27,4.32-0.28c7.72,0,15.32,2.68,17.7,11
					c2.96,10.33-4.15,22.63,2.16,32.22c3.8,5.88,18.72,12.62,9.19,20.51c-12.3,10.16-9.86,20.4-3.05,33.13
					c7.02,13.1,6.66,27.32,2.59,41.3c-5.32,18.35-16.36,37.11-31.84,48.67c-7.91,5.9-18.65,6.7-25.63,13.94
					c-5.9,6.39-13.34,11.16-21.61,13.85c-9.29,2.92-19.73,2.31-28.49,6.94c-7.63,4.04-10.05,12.54-16.43,17.79
					c-6.88,5.21-14.84,8.82-23.3,10.57c-0.88,0.21-1.79,0.31-2.7,0.3c-5.53,0-11.97-3.29-17.29-3.29c-2.76-0.13-5.41,1.11-7.09,3.31
					c-2.9,3.74-2.64,8.65-5.19,12.6c-2.32,3.13-5.41,5.61-8.97,7.2c-6.79,3.54-15.26,5.64-19.45,12.64
					c-6.05,10.09,3.22,10.94,10.37,10.94H412c3.99-0.19,7.97,0.55,11.63,2.16c11.28,5.6-0.32,20.03-4.84,25.94
					c-5.38,7.09-1.28,10.2,5.34,14.13c6.18,3.67,6.2,8.9,9.03,15.13c2.39,5.36,6.69,9.65,12.06,12.04c3.76,1.74,7.85,2.68,12,2.77
					c4.76,0.22,9.35-1.76,12.45-5.38c3.07-3.67,3.26-10.09,8.65-11.17c0.86-0.15,1.74-0.22,2.62-0.22c3,0,6.29,0.67,9.21,0.67
					c3.89,0,7.11-1.21,8.04-6.85c0.52-3.18-0.41-6.35,0.41-9.49c0.78-3.04,3.62-5.09,6.74-4.86h0.58c6.89,0,13.66,2.16,20.58,2.16
					h0.43c3.63,0.24,7.25-0.66,10.35-2.57c3.96-3.03,2.03-7.18,4.65-10.81c1.92-2.66,5.23-5.77,8.77-5.77
					c0.51,0,1.02,0.07,1.51,0.19c5.1,1.36,3.03,7.72,2.16,11.37c-1.73,6.94-1.1,12.77,5.23,17.46c4.2,2.76,9.18,4.08,14.2,3.78
					c2.59,0,5.19-0.19,7.63-0.43c1.43-0.13,3.5-0.63,5.34-0.63c1.03-0.07,2.05,0.19,2.92,0.73c6.22,4.63-3.5,9.27-7.07,10.14
					c-4.73,1.12-12.04,1.23-14.39,6.48c-2.83,6.25-0.91,14.63,5.66,17.57c3.38,1.4,7.02,2.08,10.68,1.99
					c9.86-0.31,19.51-3.01,28.1-7.87c6.89-4,14.52-10.35,22.91-10.35c1.07,0,2.15,0.11,3.2,0.32c9.9,1.99,16.38,12.1,21.42,19.99
					c8.32,12.97,16.84,27.38,30.91,34.99c8.84,4.78,19.45,5.04,29.16,5.77c2.59,0.19,5.3,0.35,8,0.35c9.88,0,20.01-1.84,27.06-9.25
					c4.82-5.06,6.09-12.28,10.68-17.44c4.59-5.47,9.76-10.42,15.43-14.76c2.89-2.16,6.42-3.28,10.03-3.2
					c2.73,0.09,5.45,0.36,8.15,0.82c1.61,0.21,3.24,0.32,4.86,0.32c6.75-0.07,13.37-1.83,19.26-5.12
					c15.13-8.17,25.44-22.69,37.2-34.71c2.71-2.65,6.18-4.38,9.92-4.97c2.16,0,3.16,1.58,0.95,6.14
					c-2.51,5.21-6.92,9.16-10.33,13.72c-9.03,12.04-17.92,24.4-29.24,34.41c-12.58,11.11-23.56,18.7-31.27,33.87
					c-5.43,10.71-10.09,21.79-13.96,33.15c-4.02,11.89-5.88,15.32-17.72,15.32h-2.64c-0.71,0-1.45,0-2.16,0
					c-12.6,0-24.62,3.57-37.07,3.57c-3.75,0.03-7.48-0.34-11.15-1.1c-16.71-3.57-32.89-10.5-50.21-10.5h-0.84
					c-16.66,0.22-33.24,2.46-49.71,4.19c-16.47,1.73-38.9,6.72-41.06,26.95c-0.93,9.21-1.1,21.14-8.49,27.88
					c-3.25,2.85-7.48,4.32-11.8,4.11c-6.05,0-12.49-1.9-18.26-1.9c-3.98-0.25-7.87,1.22-10.7,4.02c-8.04,8.26-4.32,26.5-4.08,36.74
					c0.24,15.47-6.74,23.77-16.45,34.19c-9.7,10.42-15.84,24.6-27.64,33.69c-9.81,7.52-21.2,13.29-30.02,22.07
					c-10.81,10.81-12.77,26.19-20.27,38.75c-8.26,13.85-22.54,25.78-35.62,34.84c-15.13,10.53-33.41,10.81-49.04,19.08
					c-8.82,4.67-23.26,22.5-11.97,31.38c1.81,1.55,4.1,2.42,6.48,2.46c8.65,0,16.4-11.91,22.93-15.41c2.76-1.55,5.87-2.39,9.03-2.44
					c15.86,0,26.22,23.54,34.9,33.48c2.56,3.22,6.42,5.12,10.53,5.21c8.36,0,16.71-8.08,19.78-16.99
					c3.8-11.09-8.65-19.86-0.93-30.45c8.45-11.48,25.01-15.86,37.5-21.16c14.16-6.03,14.11-22.02,26.76-28.98
					c3.33-1.61,7.01-2.35,10.7-2.16c6.61,0,13.75,1.6,19.62,1.6h0.82c11.26-0.3,22.43-2.77,33.8-2.87h1.36
					c23.36,0,46.19,5.34,66.03,18.07c8.3,5.32,20.55,18.26,31.81,18.26c1.62,0,3.24-0.29,4.75-0.86
					c11.84-4.5,16.36-22.54,24.27-31.47c9.42-10.66,3.39-25.2,6.16-37.91c2.98-13.64,8.65-28.1,20.79-36.09
					c7.43-4.91,16.99-7.8,25.01-11.97c10.73-5.59,22.34-9.32,34.32-11.02c10.81-1.56,20.42-4.91,30.26-4.91
					c6.27-0.05,12.45,1.52,17.94,4.56c13.75,7.48,25.11,19.45,40.91,22.91c1.98,0.42,4,0.62,6.03,0.61c8.32,0,16.92-2.94,25.01-3.83
					c1.28-0.15,2.56-0.22,3.85-0.22c9.86,0,19.45,3.83,29.09,6.14c7.63,1.93,15.44,3.04,23.3,3.31c5.4,0,10.24-0.86,12.97-3.35
					c9.27-8.65-14.22-21.16-2.68-29.54c5.45-3.98,14.65-4.58,22.69-4.58c3.16,0,6.14,0,8.65,0c7.22,0,14.65,0.52,22.02,0.52
					c13.51,0,26.86-1.49,38.43-9.55c8.04-5.6,6.29-25.35,16.45-26.3h0.73c8.13,0,15.45,12.62,22.15,15.95
					c16.25,8.04,40.85,12.58,58.92,13.29c0.93,0,1.86,0,2.79,0c20.19,0,39.72-8.43,59.43-11.58c10.89-1.78,21.9-2.69,32.94-2.72
					c14.37-0.06,28.69,1.75,42.6,5.38c11.5,3.07,26.35,10.42,39.36,10.42c3.01,0.04,6.01-0.43,8.86-1.4
					c9.81-3.44,17.59-9.86,25.94-15.8c9.66-6.89,21.61-8.3,32.81-11.78c10.29-3.13,18.33-9.57,27.88-14.33
					c3.74-1.85,7.87-2.75,12.04-2.64c3.44,0,6.98,0.39,11,0.39h0.78c15.34-0.19,8.49-15.58,13.79-25.18
					c4.08-7.41,11.89-11.2,19.45-14.07c14.59-5.49,36.4-6.89,44.48-22.39c7.09-13.62,0.76-28.53,0.28-42.81
					c-0.32-9.92,15.47-22.84-2.16-24.62c-0.85-0.09-1.7-0.13-2.55-0.13c-10.57,0-20.83,6.22-31.04,7.91
					c-2.9,0.48-24.01,4.52-37.67,4.52c-7.05,0-12.12-1.08-11.65-4.32c0.93-6.48,15.8-6.03,3.67-13.85
					c-4.81-3.34-10.47-5.24-16.32-5.49c-3.79-0.02-7.49,1.08-10.66,3.16c-5.71,3.67-13.29,13.75-20.75,13.75
					c-2.87-0.1-5.53-1.5-7.24-3.8c-7.13-8.26,13.16-13.44,15.91-19.93c2.96-7.02-1.34-8.49-6.81-8.49c-3.93,0-8.45,0.76-11.35,0.76
					h-0.15c-16.1-0.15-3.65-9.31,5.08-10.68c2.03-0.31,4.08-0.45,6.14-0.43c7.31,0,14.83,1.51,22.11,1.51
					c5.96,0.26,11.85-1.34,16.86-4.58c6.03-4.13,10.81-11.71,14.26-17.92c5.23-9.19,15.41-31.9-1.12-35.99
					c-0.61-0.15-1.25-0.23-1.88-0.24c-7.35,0-15.39,9.55-22.5,9.55c-2.09-0.01-4.08-0.9-5.49-2.44c-0.73-0.71,21.61-9.4,23.45-10.24
					c8.97-4.13,17.29-8.34,27.36-8.34h1.32h2.16c26.35,0,27.56-19.97,38.23-39.83c7.48-13.92,19.6-25.09,32.07-34.58
					c16.1-12.1,18.98-27.4,24.72-45.97c5.62-18.15,14.5-36.18,18.65-54.68c3.87-17.29,2.42-36.96-1.36-54.03
					c-5.78-26.37-19.43-50.37-39.14-68.81c-29.85-27.64-77.57-37.15-119-37.15c-5.88,0-11.65,0.19-17.29,0.56
					c-11.41,0.73-26.09,2.81-34.06,12.06c-9.1,10.53-5.62,26.99-15.13,37.65c-1.56,2.21-4.01,3.61-6.7,3.85
					c-4.56,0-8.45-5.19-13.25-8.43c-6.22-4.19-12.69-8.04-18.63-12.62c-11.13-8.65-20.08-20.73-9.4-33.63
					c8.86-10.81,23.04-15.95,30.8-27.71c9.79-14.85,8.23-35.96,3.76-52.3c-3.31-12.02-10.81-21.22-16.19-32.27
					c-4.63-9.6-8.65-19.45-14.8-28.1c-7.07-9.9-16.6-10.09-26.32-16.01c-6.72-4.41-12.9-9.59-18.41-15.43
					c-11.11-11-22.11-23.26-37.69-27.64c-3.65-1.04-19.67,0.82-17.4-5.94c1.12-3.37,8.65-6.16,11.82-6.81
					c1.56-0.32,3.14-0.48,4.73-0.48c6.15,0.28,12.14,2.07,17.42,5.23c14.22,7.74,22.11,17.14,36.61,24.4
					c3.29,1.66,16.88,6.48,24.64,6.48c1.64,0.16,3.28-0.26,4.65-1.19c9.77-8.95-3.13-18.24-5.71-21.96
					c-3-4.32-9.94-12.3-12.97-16.68c-6.81-9.73-12.67-17.87-19.93-27.25c-9.92-12.82-15.13-20.9-16.27-37.43
					c-0.65-10.16-2.44-22.17-2.96-32.42c-1.04-21.07-5.06-41.95-16.58-60c-7.89-12.36-19.93-20.23-31.06-29.37
					c-11.13-9.14-17.29-22.33-28.2-31.53c-11.52-9.77-27.32-15.63-41.06-21.61c-12.08-5.46-24.91-9.1-38.06-10.81
					c-5.53-0.78-10.18-0.61-11.35-7.15c-1.56-8.93-2.77-16.12-7.78-23.77c-4.47-6.87-10.07-12.97-14.59-19.78
					c-6.61-10.01-9.25-22.37-11.02-34.04c-2.03-13.43-5.62-26.57-10.7-39.16c-4.47-11.09-10.07-21.89-12.97-33.56
					c-2.29-9.34-2.16-19.04-4.11-28.44c-2.27-11.41-9.21-22.33-10.48-33.74c-1.28-11.41,0.45-23.36-0.43-35.01
					c-0.91-9.73-3.47-19.23-7.59-28.1c-7.52-16.38-27.71-18.13-38.36-31.66c-6.12-7.78-8.1-17.57-9.12-27.17
					c-1.47-13.49-2.96-27.97-15.13-36.37c-7.37-5.14-16.99-10.53-26.32-10.53c-1.11-0.01-2.23,0.07-3.33,0.24
					c-1.16,0.17-2.33,0.25-3.5,0.24c-15.54,0-32.79-14.16-44.39-22.11c-10.31-7.09-19.6-17.81-32.25-20.49
					c-0.95-0.2-1.92-0.29-2.9-0.28c-2.29,0-4.54,0.43-6.89,0.45h-0.28c-2.74,0-5.49-0.52-8.19-0.52c-1.75-0.04-3.49,0.26-5.12,0.89
					c-3.52,1.43-8.52,4.32-10.61,7.67c-6.74,10.81-10.98,18.95-23.77,22.43c-4.11,1.12-8.34,1.71-12.6,1.77
					c-5.62,0-11.2-1.3-14.72-5.19c-1.11-1.46-2.35-2.82-3.72-4.04c-3.48-2.57-8.65-2.16-12.75-2.46
					c-5.97-0.48-11.93-1.08-17.87-1.77c-11.24-1.3-22.43-2.87-33.65-4.32c-5.92-0.78-30.11-0.3-26.06-11.71
					c1.51-4.32,6.48-5.53,11.63-5.53c4.51,0.13,9,0.74,13.38,1.82c11.28,2.49,21.33,11.02,33.09,11.02c0.61,0.03,1.23,0.03,1.84,0
					c8.65-0.65,19.56-3.83,25.35-10.81c4.52-5.4,6.05-12.66,10.4-18.2c6.13-7.89,13.18-15.03,20.99-21.27
					c4.22-3.08,9.37-4.61,14.59-4.32c4.87,0.19,9.72,0.64,14.55,1.34c1.61,0.17,3.22,0.26,4.84,0.26c9.34-0.2,18.5-2.61,26.73-7.02
					c6.89-3.54,15.13-9.73,11-18.18c-1.46-3.02-3.11-5.95-4.93-8.77c-1.16-2.26-3.06-4.06-5.38-5.1c-1.67-0.5-3.4-0.72-5.14-0.67
					h-1.79c-2.79,0.23-5.56-0.62-7.74-2.38c-3.51-3.31-5.94-7.61-6.98-12.32c-1.92-7.18,0-19.45-8.75-21.87
					c-1.41-0.36-2.86-0.54-4.32-0.54c-5.23,0.38-10.39,1.5-15.3,3.33c-10.05,3.05-19,8.77-28.66,12.79
					c-0.89,0.41-1.85,0.65-2.83,0.69c-3.26,0-2.94-3.91-0.5-6.76c2.73-2.85,5.88-5.27,9.34-7.18c8.83-5.05,18.62-8.19,28.74-9.21
					c12.12-1.51,24.88,0.39,36.2-5.17c11.97-5.86,22.22-15.56,30.43-25.94c6.48-8.08,9.53-17.96,12.21-27.82
					c2.83-10.42,4.04-22.11,10.35-31.17c5.19-7.46,12.97-12.47,19.58-18.59c8.32-7.74,9.06-18.63,10.66-29.24
					c1.73-11.24,7.11-22.07,12.97-31.66c5.27-8.65,14.39-17.14,14.57-27.88c0.17-8.86-8.49-15.8-6.35-24.88
					c1.99-8.36,7.59-15.26,10.81-23.13c3.7-9.06,7.02-18.5,12.79-26.5c6.05-8.36,15.37-13.64,21.31-22.11
					c4.79-6.94,7.34-15.17,7.33-23.6c0-16.53-8.26-32.42-17.79-45.39c-6.03-8.21-12.71-18.24-23.26-20.77
					c-2.44-0.58-4.93-0.86-7.43-0.84c-9.06,0-18.22,3.24-26.97,4.75c-14.28,2.48-28.74,3.75-43.23,3.8c-2.59,0-5.19-0.04-7.78-0.13
					c-20.21-0.69-40.52-7.59-60.69-7.59c-1.92,0-3.85,0-5.77,0.22c-9.27,0.65-18.57,2.38-27.84,2.38
					c-3.49,0.02-6.98-0.27-10.42-0.86c-10.46-1.88-21.29-5.32-32.03-5.32c-4.31-0.05-8.61,0.61-12.71,1.95
					c-7.35,2.42-13.7,7.18-21.07,9.6c-2.8,0.83-5.72,1.22-8.65,1.15c-3,0-6.03-0.24-9.01-0.24c-4.04-0.23-8.07,0.62-11.67,2.47
					c-6.98,3.89-10.09,12.02-16.68,16.4c-7.35,4.86-16.49,5.38-25.05,5.86c-4.78,0.28-10.42-0.22-14.67,2.33
					c-4.26,2.55-6.48,7.76-8.8,12.17c-3.31,6.14-7.88,11.5-13.42,15.73c-3.79,3.02-8.54,4.56-13.38,4.32c-2.57,0-5.3-0.24-8.36-0.41
					c-0.61,0-1.28,0-2.01,0c-2.7,0-6.05,0.19-9.34,0.19c-3.81,0.26-7.63-0.37-11.15-1.84c-5.08-2.92,0.43-6.29,4.32-7.18
					c4.24-0.63,8.51-1.02,12.79-1.17c9.93-1.49,19.19-5.88,26.63-12.62c15.78-13.57,25.01-32.87,36.24-49.95
					c3.98-6.38,8.65-12.3,13.92-17.66c3.65-3.57,11.78-9.34,10.81-15.3c-0.63-3.98-5.71-4.63-9.86-4.63c-1.38,0-2.68,0-3.65,0.11
					c-5.19,0.24-10.27,0.95-15.32,0.95c-3.89,0.07-7.76-0.52-11.45-1.73c-4.75-1.62-10.33-6.27-4.56-10.66
					c3.59-2.74,8.95-1.64,12.97-3.48c7.91-3.65,3.03-13.34,3.61-20.03c0.63-7.43,3.54-16.23,8.65-21.76
					c5.6-6.12,14.93-7.05,21.61-11.54c7.98-5.38,14.44-12.97,21.31-19.67c10.81-10.44,21.12-21.35,31.08-32.57
					c9.47-10.63,19.45-21.12,32.03-28.1c4.78-2.62,10.14-4.32,14.52-7.63c7.5-5.68,11.74-14.68,15.99-22.8
					c3.37-6.48,9.19-13.53,8.65-21.25c-0.45-5.99-5.06-10.94-7.28-16.27c-3.07-7.33,1.58-13.49,5.66-19.21
					c2.39-2.95,4.29-6.26,5.64-9.81c3.59-11.48-7.91-16.43-17.03-18.65c-6.05-1.69-12.28-2.65-18.57-2.85L741.05,151.92z"/>
				<path className="st2" d="M229.39,1107.46c-0.32,0.03-0.65-0.05-0.93-0.22c0,0-0.93-1.06-0.86-4.75c0.12-1.46,0.37-2.91,0.76-4.32
					c0.89-3.85,1.99-8.65-0.5-12.56c-1.56-2.36-4.04-3.98-6.83-4.45c-1.42-0.3-2.87-0.44-4.32-0.43c-1.88,0.03-3.75,0.16-5.62,0.39
					c-1.51,0.18-3.02,0.29-4.54,0.32h-0.54c-2.31,0-9.36-0.35-11.91-3.26c-1.13-1.43-1.55-3.3-1.15-5.08
					c0.78-5.8,2.11-11.51,3.98-17.05c0.52-1.73,1.04-3.44,1.51-5.14l0.3-1.08c1.82-6.59,6.14-21.87,13.34-21.87
					c0.4-0.03,0.81-0.03,1.21,0c2.06,0.47,4.08,1.12,6.03,1.92c3.09,1.38,6.41,2.19,9.79,2.4c4.96-0.13,9.51-2.79,12.06-7.05
					c0.65-0.95,1.38-2.16,2.16-3.46c2.03-3.37,5.4-8.99,8.13-8.99c0.2-0.03,0.4-0.03,0.61,0c1.1,0.28,2.03,1.32,3.46,3
					c2.16,2.49,4.95,5.88,10.16,5.88c1.47-0.03,2.93-0.29,4.32-0.78c6.48-2.16,9.38-11.95,12.43-22.33c1-4.07,2.45-8.02,4.32-11.78
					c4.7-6.35,9.97-12.26,15.73-17.66c10.44-10.11,17.29-13.75,20.21-13.75c0.41,0,1.25,0,1.64,3.16
					c0.84,6.83-6.16,15.8-11.78,23.02c-1.79,2.31-3.5,4.5-4.86,6.48c-7.61,11.17-15.47,22.74-19.97,36.05
					c-0.77,2.39-1.4,4.82-1.9,7.28c-0.99,4.5-1.95,8.65-4.52,11.89c-1.66,1.63-3.65,2.9-5.84,3.72c-3.94,1.44-7.36,4.03-9.81,7.43
					c-3.31,5.14-3.22,11.09-3.16,16.34c0.24,3.23-0.01,6.48-0.76,9.64c-2.14,7-6.75,12.98-12.97,16.84
					c-0.86,0.54-1.84,1.19-2.92,1.9c-4.63,3.07-12.38,8.19-16.51,8.19L229.39,1107.46z"/>
				<path className="st2" d="M320.03,960.95c-0.76,5.19-6.74,12.86-10.81,18.15c-1.84,2.36-3.57,4.6-5.01,6.72
					c-7.78,11.41-15.8,23.23-20.51,37.09c-0.82,2.54-1.49,5.12-2.01,7.74c-0.93,4.15-1.73,7.72-3.65,10.05
					c-1.28,1.1-2.74,1.98-4.32,2.57c-4.68,1.79-8.73,4.93-11.63,9.01c-4,6.25-3.89,13.18-3.83,18.74c0.19,2.78,0,5.57-0.56,8.3
					c-1.85,6-5.8,11.13-11.13,14.46l-2.98,1.95c-3.68,2.56-7.55,4.83-11.58,6.79c0.14-1.09,0.35-2.18,0.63-3.24
					c0.99-4.32,2.38-10.42-1.04-15.84c-2.18-3.34-5.63-5.63-9.55-6.35c-1.73-0.38-3.5-0.56-5.27-0.54
					c-2.03,0.03-4.06,0.17-6.07,0.41c-1.51,0.15-2.94,0.3-4.08,0.3h-0.43c-7.61-0.28-8.82-1.77-8.84-1.77
					c-0.14-0.6-0.14-1.23,0-1.84c0.76-5.54,2.04-11,3.83-16.3c0.52-1.75,1.04-3.5,1.53-5.23l0.3-1.1
					c4.84-17.29,8.26-18.74,9.16-18.74h0.39c1.82,0.43,3.61,1,5.34,1.71c3.55,1.55,7.35,2.43,11.22,2.62c6.22,0,11.48-3,15.6-8.9
					c0.78-1.1,1.53-2.36,2.33-3.7c1.24-2.23,2.69-4.35,4.32-6.31l0.84,0.97c3.08,4.44,8.06,7.18,13.46,7.41
					c1.96-0.03,3.91-0.36,5.77-0.99c8.65-2.87,11.84-13.72,15.13-25.22c0.92-3.59,2.13-7.1,3.63-10.48
					c4.52-6.12,9.59-11.83,15.13-17.05c4.29-4.46,9.17-8.32,14.5-11.48 M252.06,1017.03L252.06,1017.03 M322.52,951.22
					L322.52,951.22c-7.48,0-19.62,11.5-23.19,14.98c-5.97,5.58-11.41,11.69-16.27,18.26c-4.47,6.33-7.37,30.13-14.5,32.42
					c-0.98,0.35-2.01,0.54-3.05,0.56c-5.92,0-7.05-7.31-12.54-8.65c-0.55-0.14-1.12-0.22-1.69-0.22c-6.48,0-10.81,9.99-13.83,14.29
					c-2.74,3.93-5.58,5.21-8.52,5.21c-4.65,0-9.62-3.2-15.02-4.32c-0.66-0.13-1.34-0.19-2.01-0.19c-10.96,0-15.54,18.13-17.79,26.15
					c-2.16,7.54-4.88,15.13-5.64,23.04c-1.06,10.94,8.47,12.75,17.29,12.97h0.69c3.2,0,6.83-0.71,10.16-0.71
					c1.14-0.02,2.28,0.09,3.39,0.32c8.65,1.97,3.31,11.2,3.18,16.97c-0.13,6.96,2.42,9.38,6.12,9.38c6.48,0,16.47-7.43,21.74-10.81
					c7.08-4.41,12.32-11.24,14.76-19.21c2.49-8.08-1.15-17.83,3.41-24.92c3.63-5.66,11.2-5.71,15.34-10.81
					c4.8-5.84,4.86-13.7,7.18-20.53c4.32-12.71,11.97-23.99,19.45-34.99c5.73-8.45,18.72-21.42,17.29-32.42
					c-0.63-5.06-2.9-6.94-5.92-6.94L322.52,951.22z"/>
				<path className="st2" d="M412.95,1153.6c-4.39-0.14-8.55-2.01-11.56-5.21l-0.45-0.45c-9.6-9.94-14.67-28.29-16.23-42.14
					c-0.71-6.48-1.1-14.35,2.38-18.93c3.81-4.3,9.23-6.84,14.98-7c2.14-0.11,4.24,0.54,5.94,1.84c7.54,6.48,9.27,17.29,11.11,28.66
					c0.78,6.18,2.14,12.26,4.08,18.18c0.5,1.31,1,2.56,1.49,3.76c2.46,5.99,3.44,8.82,0.76,13.4
					C423.04,1150.42,418.25,1153.46,412.95,1153.6L412.95,1153.6z"/>
				<path className="st2" d="M402.05,1084.14c1.11-0.06,2.2,0.22,3.13,0.82c6.33,5.36,7.93,15.41,9.64,26.04
					c0.83,6.46,2.28,12.82,4.32,19c0.52,1.4,1.04,2.68,1.53,3.89c2.36,5.73,2.62,6.85,1.02,9.57c-1.63,3.34-4.93,5.55-8.65,5.77
					c-3.29-0.12-6.4-1.54-8.65-3.96l-0.37-0.37c-8.84-9.21-13.64-27.1-15.13-39.64c-0.37-3.31-1.36-12.06,1.51-15.82
					c2.99-3.23,7.14-5.14,11.54-5.32 M401.97,1075.49c-7.09,0.14-13.78,3.29-18.41,8.65c-4.54,5.97-4.02,15.13-3.24,22.04
					c1.62,14.42,7.02,33.87,17.42,44.65l0.52,0.54c3.82,4,9.08,6.33,14.61,6.48c6.83-0.14,13.05-3.99,16.21-10.05
					c4.67-7.95,0.93-13.21-1.9-20.83c-5.6-15.13-3.11-37.33-16.47-48.63c-2.45-1.95-5.51-2.96-8.65-2.87L401.97,1075.49z"/>
				<path className="st2" d="M1080.35,2526.7h-1.69c-6.01-0.45-9.94-4.5-14.5-9.19c-4.13-4.32-8.39-8.65-14.59-10.81
					c-1.93-0.55-3.9-0.91-5.9-1.08c-2.16-0.3-7.43-0.97-8.65-2.16c-0.05-0.22,0.01-0.45,0.15-0.63c2.16-3.57,14.48-6.03,19.08-6.96
					c1.79-0.37,3.2-0.65,4.17-0.93c3.5-1.07,6.8-2.7,9.79-4.82c2.81-2.18,6.26-3.35,9.81-3.33h0.93c6.98,0.32,16.86,2.16,24.18,8.43
					c0.94,0.69,1.64,1.67,1.99,2.79c0.41,2.16-3.78,6.16-6.85,9.06c-3.04,2.51-5.61,5.53-7.61,8.93c-0.5,1.02-0.95,2.01-1.4,2.96
					c-2.79,6.12-3.72,7.72-8.84,7.72L1080.35,2526.7z"/>
				<path className="st2" d="M1078.03,2491.12h0.71c8.93,0.41,16.58,3.03,21.61,7.35l0.13,0.13c-1.56,1.91-3.28,3.7-5.12,5.34
					c-3.42,2.85-6.3,6.29-8.52,10.16c-0.52,1.06-0.99,2.16-1.43,3.07c-2.38,5.19-2.38,5.19-4.93,5.19h-1.36
					c-4.17-0.3-7.02-3.05-11.74-7.87c-4.32-4.32-9.1-9.34-16.3-11.78c-1-0.33-2.03-0.58-3.07-0.76c2.16-0.54,4.52-1.08,7.28-1.64
					c1.97-0.41,3.37-0.69,4.52-1.02c3.91-1.19,7.61-2.99,10.96-5.34c2.96-1.92,4.32-2.79,7.31-2.79 M1078.1,2482.52
					c-9.53,0-11.82,5.92-20.64,8.47c-4.82,1.38-28.92,4.17-26.37,13.92c1.36,5.12,13.23,4.62,17.29,5.99
					c11.84,4.02,16.49,19.08,30.15,20.08c0.71,0,1.38,0,2.01,0c9.34,0,10.46-5.6,14.13-13.12c3.11-6.48,16.19-13.29,14.83-20.66
					c-0.52-2.08-1.72-3.94-3.41-5.25c-7.31-6.48-17.29-9.06-26.82-9.49L1078.1,2482.52z"/>
				<path className="st2" d="M490.67,1556.2c-0.43,0.03-0.87,0.03-1.3,0c0.14-2.63,0.8-5.21,1.95-7.59c0.35-0.69,0.73-1.36,1.12-2.03
					c1.87-2.85,3.14-6.05,3.74-9.4c0.26-2.4,0.36-4.81,0.28-7.22c0-1.32,0-2.64,0-3.93c0.37-8.17,2.29-12.84,6.2-15.13
					c4.96-2.66,9.46-6.09,13.33-10.18c2.75-3.48,4.94-7.36,6.48-11.52c0.73-1.88,1.16-3.86,1.25-5.88c0.05-1.01,0.21-2.01,0.48-2.98
					c1.04-2.92,6.94-5.51,11.26-7.39c2.01-0.86,3.89-1.69,5.49-2.57l1.47-0.91c1.61-1.03,3.3-1.95,5.04-2.74c0,1.51,0,2.98,0,4.54
					c0,0.91,0,1.82,0,2.74c0,0.93,0,2.01-0.17,2.96c-0.32,3.83-0.71,8.65,3.93,12.97c1.43,1.25,2.98,2.36,4.63,3.31
					c1.34,0.72,2.57,1.62,3.65,2.68c1.59,2.17,2.39,4.81,2.29,7.5c0.53,8.06-1.36,16.1-5.42,23.08c-2,2.62-4.29,5.01-6.83,7.11
					l-1.25,1.15c-5.82,5.29-12.43,9.63-19.6,12.86c-6.3,2.52-12.88,4.27-19.6,5.21c-2.62,0.48-5.25,0.97-7.85,1.53l-1.6,0.39
					c-2.96,0.84-6,1.36-9.08,1.53L490.67,1556.2z"/>
				<path className="st2" d="M549.67,1470.57L549.67,1470.57 M543.18,1474.1c0,0.76,0,1.6-0.15,2.49c-0.32,4-0.89,10.66,5.3,16.43
					c1.65,1.5,3.45,2.81,5.38,3.93c0.92,0.51,1.79,1.11,2.59,1.79c0.98,1.42,1.44,3.13,1.3,4.84c0.5,7.15-1.14,14.29-4.71,20.51
					c-1.82,2.32-3.87,4.44-6.14,6.33l-1.28,1.15c-5.48,4.99-11.72,9.09-18.48,12.12c-5.98,2.36-12.22,4-18.59,4.88
					c-2.62,0.5-5.3,0.97-7.98,1.56l-1.75,0.43c-0.99,0.24-2.44,0.61-3.93,0.91c0.11-0.28,0.23-0.55,0.37-0.82
					c0.35-0.65,0.71-1.28,1.08-1.9c2.17-3.33,3.64-7.06,4.32-10.98c0.29-2.6,0.4-5.23,0.32-7.85c0-1.3,0-2.55,0-3.67
					c0.41-8.49,2.51-10.66,4.04-11.54c5.39-2.87,10.27-6.6,14.44-11.07c3.1-3.8,5.56-8.08,7.31-12.67c0.91-2.29,1.45-4.71,1.58-7.18
					c0.04-0.62,0.12-1.24,0.24-1.86c1.06-1.51,6.35-3.83,8.93-4.95c2.16-0.93,4.08-1.79,5.86-2.79l0,0 M528.42,1481.81
					L528.42,1481.81 M549.06,1462.04c-3.2,0-8.28,3.57-10.09,4.58c-5.4,3.03-16.47,5.97-18.69,12.32c-0.99,2.79-0.45,5.81-1.64,8.65
					c-1.4,3.73-3.37,7.22-5.84,10.35c-3.55,3.74-7.68,6.88-12.23,9.29c-6.64,3.78-8.06,11.63-8.39,18.63
					c-0.17,3.57,0.17,7.26-0.3,10.81c-0.48,3.54-2.66,6.7-4.32,9.96c-1.73,3.43-2.53,7.25-2.31,11.09c0.39,2.16,2.68,2.94,5.49,2.94
					c3.93-0.26,7.82-0.94,11.61-2.03c9.47-2.16,19.45-3,28.29-7.02c7.6-3.4,14.6-8,20.75-13.62c3.26-2.67,6.2-5.71,8.75-9.06
					c4.82-7.2,6.48-17.12,6.16-25.63c0.08-3.65-1.1-7.22-3.33-10.11c-2.29-2.68-6.01-3.93-8.65-6.35c-4.08-3.8-2.4-7.76-2.4-12.71
					c0.13-3.08,0.09-6.17-0.11-9.25c0-0.79-0.29-1.56-0.8-2.16c-0.52-0.49-1.21-0.74-1.92-0.69L549.06,1462.04z"/>
			</g>
		</g>
		<g id="Reigon_sections">
			<path className="st3" d="M1322.54,1907.89c-0.61,6.25-2.08,14.61-7.12,18.88c-6.27,5.31-24.09,2.91-26.57,11.74
				c-0.91,3.24,0.83,6.95-0.69,9.95c-1.12,2.21-3.7,3.29-6.15,3.69c-8.5,1.39-17.11,2.04-25.73,1.77
				c-10.05-0.32-20.12-0.04-30.14,0.89c-4.57,0.42-29.26-0.43-28.9,7.27c0.39,8.21,1.82,13.61,8.31,18.96
				c7.21,5.94,18.55,13.6,18.55,24.11c0,7.04-4.88,13.44-9.22,18.55c-3.02,3.56-5.79,7.15-9.29,10.23c-2.48,2.18-4.21,4.9-4.51,8.23
				c-0.19,2.09,2.07,5.42,0.7,7.31c-1.5,2.07-5.16,0.88-7.14,1.71c-2.63,1.11-4.33,3.43-5.37,6.09c-1.33,3.41-1.15,9.28-3.7,12.05
				c-3.93,4.28-7.04-5.07-10.71-4.89c-5.26,0.27-0.62,12.08,0.09,14.62c2.27,8.05,2.87,16.24,2.15,24.56
				c-1.06,12.17-3.61,24.13-5.71,36.14c-0.95,5.44-1.13,10.99-2.2,16.41c-0.75,3.79-4.51,7.18-4.5,11.1
				c0.03,5.89,6.24,5.79,10.67,7.3c4.04,1.37,7.58,4.17,9.84,7.79c5.09,8.14-0.51,13.53-9.3,11.64c-6.79-1.46-12.36-5.79-18.9-7.6
				c-3.54-0.98-4.03-1.33-5.21,2.74c-1.6,5.52,1.51,10.83,5.36,14.51c7.51,7.19,17.39,10.51,24.53,18.12
				c4.44,4.74,8.65,10.21,11.06,16.28c1.25,3.15,2.2,6.65,1.66,10.07c-0.47,3.01-3.27,6.25,0.68,8.24c1.33,0.67,2.87,0.75,4.36,0.78
				c11.87,0.2,19.07-4.16,27.89-11.2c8.64-6.89,19.87-9.84,30.63-11.25c10.49-1.37,16.77,2.82,22.34,11.34
				c2.68,4.1,7.18,8.77,12.55,6.3c5.73-2.63,7.49-6.38,14.54-5.93c8.97,0.57,21.03,8.22,16.82,18.75c-2.32,5.8-14,11.64-11.66,18.93
				c2.31,7.2,13.55,11.86,20.31,12.29c10.85,0.69,20.46-5.34,30.25-9.01c7.69-2.88,15.87-4.66,23.83-6.61"/>
			<path className="st3" d="M1272.06,1705.12c2.12,6.14-4.91,12.69-9.79,15.07c-9.9,4.82-14.51-7.31-18.04-14.21
				c-2.87-5.61-5.12-13.18-10.2-17.27c-4.26-3.42-10.14-0.71-14.53,0.94c-4.65,1.75-11.04,2.57-14.52,6.35
				c-6.41,6.97,6.79,20.94-4.93,26.24c-3.06,1.38-6.7,0.92-9.77-0.46c-2.71-1.22-5.77-2.96-8.07-5.1c-2.77-2.58-2.71-6.67-5.21-9.31
				c-3.11-3.28-8.76-3.5-12.63-1.57c-8.07,4.03-8.05,22.46-20.23,17.97c-5.31-1.95-6.1-7.31-8.18-11.85
				c-1.15-2.52-2.23-6.34-5.34-6.02c-5.79,0.61-8.45,8.28-8.77,13.2c-0.25,3.82,2.78,7.76-1.16,10.4
				c-4.14,2.77-13.58,1.47-18.59,3.15c-1.24,0.41-2.56,0.97-3.17,2.12c-3.08,5.81-0.41,12.7-2.83,18.64
				c-2.48,6.08-11.14,11.76-17.79,9.31c-8.51-3.13-17.95-7.3-27.14-7.15c-8.04,0.13-14.33,7.12-22.17,8.12
				c-12.77,1.63-19.21-15.01-24.23-23.86c-4.2-7.4-8.23-15.21-13.61-21.84c-3.43-4.23-7.38-7.97-11.13-11.9
				c-2.64-2.77-4.77-7.64-8.56-9.04c-4.73-1.75-10.25,1.98-13.2,5.38c-7.76,8.92-11.4,22.93-12.17,34.49
				c-0.93,14.05-1.43,37.27,9.01,48.42c6.7,7.15,16.01,9.16,21.85,17.76c6.73,9.89,8.08,22.38,14.98,32.17
				c3.01,4.27,7.17,7.21,2.7,12.25c-2.89,3.26-7.98,3.76-11.4,6.31c-10.13,7.56-9.75,27.25,0.26,34.41
				c4.87,3.48,10.87,4.95,16.78,5.92s11.93,1.56,17.57,3.57c2,0.71,4.01,1.66,5.32,3.32c5.97,7.52-8.62,13.43-13.25,15.52
				c-5.23,2.36-15.67,8.4-7.9,13.94c5.29,3.77,13.16,1.73,18.25,6.02c4.47,3.77,6,9.49,4.67,15.09c-0.89,3.76-4.89,6.65-5.54,10.25
				c-0.73,4.08,2.58,4.53,6.17,6.71c8.07,4.91,16.14,9.87,23.7,15.56c10.77,8.1,23.49,16.47,29.93,28.71
				c7.14,13.58,9.1,34.46,0.78,47.81c-2.58,4.13-5.55,8.13-6.86,12.82c-2.45,8.74-19.35,14.03-5.65,23.07
				c2.94,1.94,12.04,7.49,6.32,11.53c-8.37,5.91-6.52,15.1-3.25,24c2.32,6.31,7.11,17.98,15.74,15.86
				c5.14-1.26,8.48-7.78,11.42-11.77c3.78-5.13,7.52-10.4,12.72-14.23c2.24-1.65,4.75-3.01,7.5-3.48c5.47-0.92,8.44,3.04,12.46,5.81
				c5.52,3.79,10.28-5.02,12.11-9.1c1.87-4.16,3.17-8.61,5.61-12.47c6.37-10.11,18.49-18.22,27.06-26.58"/>
			<path className="st3" d="M597.8,1989.11c10.63,2.92,19.95-2.85,27.81-9.41c3.46-2.89,7.88-6.55,10.05-10.58
				c3.12-5.8,2.38-13.37,3.93-19.64c0.2-0.79,0.42-1.6,0.93-2.24c4.27-5.33,17.72-0.04,23.58-1.48c7.59-1.87,15.63-7.67,14.79-16.31
				c-0.49-5.04-5.22-9.57-2.87-14.82c2.32-5.18,8.52-9.01,13.03-12.09c11.07-7.57,26.95-6.27,39.66-7.91
				c7.12-0.91,9.92-6,15.96-8.01c4.45-1.48,11.04,0.7,10.36,6.29c-0.58,4.77-4.42,8.1-3.41,13.18c2.83,14.3,18.88,17.21,28.9,24.95
				c2.97,2.3-7.6,9.94-8.48,13.6c-1.56,6.48-3.31,12.68-6.2,18.73c-1.66,3.48-15.51,20.21-5.37,22.64c1.51,0.36,6.72-4.12,8.09-4.99
				c9.48-6.03,8.27,4.78,7.99,11.15c-0.21,4.75-0.9,4.41-5.24,5.44c-5.28,1.24-10.74,2.14-15.94,3.72
				c-5.96,1.82-17.87,5.8-15.37,14.02c1.82,5.99,9.93,9.06,14.58,12.37c4.77,3.41,9.97,6.78,15.39,9.06
				c3.18,1.34,11.17,2.22,12.12,6.25c0.88,3.73-4.59,4.39-5.22,7.61c-1.04,5.32,5.98,7.88,2.14,12.98
				c-2.51,3.33-8.14,3.63-11.36,6.11c-5.69,4.38-6.58,14.19-8.06,20.61c-3.13,13.57,0.95,28,2.41,41.53
				c0.95,8.83,2.58,17.76,5.87,26.05c3.12,7.87,3.85,15.67-3.17,21.46c-5.75,4.74-14.53,9.22-21.93,10.43
				c-4.66,0.76-10.6-1.77-14.32,2.03c-2.84,2.9-5.7,4.35-9.82,3.54c-3.19-0.62-11.18-3.42-13.17-6.27
				c-0.72-1.02-0.65-2.41-1.24-3.52c-2.15-4.1-11.45,5.32-13.26,7.08c-3.21,3.11-5.18,7.48-8.61,10.26
				c-1.88,1.52-4.44,1.99-6.84,1.77c-4.98-0.46-8.79-3.17-13.03-5.49c-1.81-0.99-3.76-2.01-5.81-1.8c-5.86,0.62-1.99,9.11-9.95,8.47
				c-4-0.32-11.07-3.63-12.47-7.77c-2-5.95,4.02-10.76,8.03-14.07c7.99-6.59,11.41-13.42,13.02-23.48
				c1.38-8.64,2.91-16.41,6.3-24.53c0.75-1.78,1.5-3.6,1.72-5.52c0.95-8.09-7-15.97-11.63-21.8c-4.59-5.77-8.93-6.94-16.11-6.64
				c-9.42,0.39-20.42-2.36-29.31,0.79c-8.78,3.11-15.95,8.89-23.24,14.51c-7.63,5.88-21.93,17.77-32.47,14.37
				c-3.9-1.26-5.2-5.74-9.61-6.13c-8.04-0.72-15.95,2.38-24.02,0.92c-4.94-0.89-9.35-3.39-14.2-4.57
				c-3.41-0.84-10.02,0.36-11.98-3.23c-1.69-3.1-0.19-8.42,0.18-11.7"/>
			<path className="st3" d="M763.56,2144.23c0,0,5.79,9.62,15.99,14.57c10.2,4.96,7.93,6.79,13.06,2.88c5.12-3.91,5.72-12.06,13.07-5.32
				c7.35,6.73,11.6,21.57,14.85,26.64s11.66-4.15,13.72,4.93c2.06,9.08,1.51,38.37,1.42,41.29"/>
			<path className="st3" d="M758.92,1784.88c13.07,2.9,25.11,10.39,32.31,21.84c3.86,6.14-7.25,5.71-8.8,9.85
				c-0.64,1.72,0.21,3.7,1.57,4.93c2.52,2.27,6.46,2.16,9.22,4.08c4.36,3.04,2.26,6.1,3.06,10.67c0.94,5.36,4.49,10.39,7.55,14.73
				c5.85,8.33,15.33,10.01,23.53,15.09c0.52,0.32,1.05,0.67,1.34,1.2c0.37,0.67,0.31,1.48,0.19,2.24
				c-0.91,5.75-4.64,10.65-9.08,14.24c-1.57,1.27-5.75,4.67-8.01,4.08c-4.83-1.25-4.17-8.87-8.46-11.46
				c-3.03-1.83-6.7-1.27-10.04-1.86c-3.54-0.63-6.83-2.24-10.39-2.74c-6.69-0.94-10.31,4.46-15.91,6.91
				c-5.71,2.49-10.7,6.73-12.16,12.78"/>
			<path className="st3" d="M948.71,1044.39c-5.76,2.71-13.09,3.68-17.8,8.28c-4.44,4.33-3.85,11.37-7.04,16.44
				c-3.85,6.11-11.35,8.32-15.77,13.89c-4.07,5.13-8,11.33-13.38,15.55c-4.91,3.85-13.8,4.09-10.65,12.06
				c2.91,7.36,8.22,13.18,12.54,19.68c3.85,5.79,11.53,11.95,11.28,19.54c-0.07,2.05-0.81,4.02-1.82,5.8
				c-4.64,8.12-12.72,8.4-20.22,12.28c-6.12,3.17-6.07,9.21-8.59,14.7c-1.85,4.04-3.67,2.5-7.09,2.03
				c-6.51-0.9-12.01,1.07-17.75,3.9c-15.06,7.44-19.21,21.14-28.08,33.94c-3.22,4.65-4.94,7.89-2.32,13.26
				c3.67,7.52,8.97,15.67,15.4,21.15c3.15,2.68,6.91,2.17,10.35,3.86c3.15,1.55,6.95,5.53,8.24,8.84c0.64,1.65,0.59,3.7-0.6,5.02
				c-1.64,1.82-4.48,1.53-6.91,1.8c-4.74,0.53-8.97,3.85-10.94,8.2c-1.51,3.33-1.93,7.55-0.91,11.09c1.11,3.84,4.04,6.68,2.99,10.98
				c-1.97,8.03-6.62,22.47-1.9,30.56c2.41,4.12,7.27,5.74,11.84,5.23c3.16-0.35,6.58-2.13,8.46-4.7c1.09-1.49,1.96-3.16,3.35-4.38
				c4.41-3.89,9.94,1.91,13.77,3.99c3.44,1.86,8.85,4.99,7.82,9.72c-1.43,6.6-6.99,12.34-6.87,17.67
				c0.12,5.33-9.04,11.82-2.69,14.79c2.57,1.2,8.56,1.09,10,4.1c2.15,4.5-4.5,8.81-1.12,14.46c2.69,4.51,6.55,8.34,10.22,12.05
				c3.71,3.75,7.66,7.58,10.17,12.28c2.17,4.07,0.9,10.4,2.31,14.86c3.35,10.55,13.73-2.92,21.2-1.22c2.74,0.62,5.29,1.85,7.96,2.72
				c4.66,1.52,10.37,2.16,15.3,0.66c2.63-0.8,5.03-2.13,6.95-4.13c1.38-1.43,1.98-5.11,3.95-5.86c5.18-1.96,6.04,9.23,11.58,2.4
				c2.79-3.44,2.28-10.41,6.67-12.56c4.27-2.09,10.05-0.47,14.49,0.06c6.9,0.83,8.78,6.91,13.15,11.3
				c11.07,11.13,25.64,7.09,39.67,5.27c3.49-0.45,7.08-0.94,10.11-2.73c4.02-2.37,6.51-5.55,11.27-6.39
				c5.37-0.95,10.97,0.4,16.36,0.39c12.43-0.03,24.81,1.38,37.24,1.26c11.54-0.11,13.2-5.33,16.22-15.58"/>
			<path className="st3" d="M906.78,1439.96c-4.9-1.13-12.15-0.34-16.56,2.84c-5.2,3.75-2.68,9.75-2.41,14.98
				c0.43,8.5-6.31,7.95-8.45,14.58c-1.23,3.82,1.91,8.62,2.09,12.54c0.25,5.25-0.8,11.34-3.9,15.67
				c-4.63,6.47-12.98,7.33-19.71,10.44c-7.01,3.24-10.44,9.96-12.72,16.97c-2.85,8.75,0.51,15.77,5.93,22.82
				c2.23,2.9,5.3,5.89,9.31,4.9c2.13-0.52,3.93-2.07,6.1-2.45c2.76-0.49,5.53,1.06,7.47,3.09c4.06,4.25,6.15,8.83,11.27,12.13
				c11.34,7.3,23.6,15,31.53,26.18c10.78,15.2,2.53,29.36-6.46,43.27c-1.62,2.5-3.26,5.1-3.75,8.03
				c-2.25,13.31,16.29,10.82,22.9,18.28c5.3,5.99,7.62,13.91,13.61,19.35c6.87,6.23,14.89,9.24,22.97,13.61"/>
			<path className="st3" d="M828.94,1866.79c14.25,4.19,29.03,6.1,38.64-7.07c3.91-5.36,4.36-12.16,8.14-17.43
				c6.16-8.59,14.46-15.84,24.14-19.99c9.71-4.16,19.1-9.23,26.74-16.65c8.86-8.61,15.38-17.1,26.09-23.65"/>
			<path className="st3" d="M820.52,2183c14.33,0.85,32.82-9.12,39.33-22.14c2.56-5.12-0.04-11.36-1.18-16.49
				c-0.7-3.14-0.93-6.63,0.72-9.4c2.28-3.83,9.04-7.12,13.4-7.62c9.59-1.11,22.46,18.11,31.28,8.25c1.75-1.95,2.94-4.39,4.9-6.13
				c5.99-5.29,21.08-6.7,28.4-4.64c7.41,2.08,15.57,12.05,23.87,8.96c4.65-1.73,3.15-9.12,2.7-12.75c-0.36-2.89-0.66-5.87,0.1-8.69
				c1.9-7.02,16.68-11.46,22.22-6.52c7.32,6.53,12.81,20.69,14.39,30.09c2.16,12.78-2.38,23.29-5.67,35.24
				c-1.56,5.65-3.19,11.43-4.13,17.21c-0.92,5.65,0.27,10.87,0.95,16.55c1.04,8.68,2.06,17.37,2.72,26.09
				c0.2,2.59,0.36,5.23-0.27,7.75c-2.25,9.06-4.08,14.78,1.02,23.49c4.46,7.62,11.26,13.54,16.37,20.68
				c11.58,16.18,13.23,42.26,0.67,58.36c-4.11,5.27-9.86,7.72-15.56,10.85c-5.61,3.07-7.86,7.26-6.2,13.35
				c3.09,11.32,5.4,24.01,11.64,34.14c4.02,6.53,10.3,16.33,4.55,23.63c-2.49,3.17-6.39,4.99-10.34,5.73
				c-10.07,1.89-21-1.01-29.27-6.93c-4.29-3.07-17.13-12.51-16.5-0.95c0.34,6.19,4.81,12.83,7.91,18.01
				c7.14,11.92,9.45,25.63,14,38.57"/>
			<path className="st3" d="M731.89,1295.34c4.93,1.7,9.74-0.78,14.36-2.27c3.01-0.97,10.13-0.45,11.54-3.58
				c2.84-6.3-0.03-12.74,1.68-18.68c1.46-5.06,7.16-4.13,11.24-5.61c10.53-3.83,17.73-9.63,25.85-17.16
				c7.38-6.85,15.13-10.91,24.51-14.41"/>
			<path className="st3" d="M1000.83,2146.02c5.38-2.41,18.38-10.57,22.86-20.36c4.49-9.79,0.36-12.51,6.41-18.64
				c6.06-6.12,4.14-12.43,17.93-15.76s13.79-3.33,13.79-3.33"/>
			<path className="st3" d="M1193.33,2251.54c-1.47,19.33-2.96,42.76,12.72,56.91c10.85,9.8,23.86,16.55,37.19,22.32
				c13.84,5.99,26.29,11.47,40.95,4.07c3.32-1.67,6.33-4.19,7.92-7.55c2.52-5.33,0.69-12.05,2.2-17.8c1.76-6.7,5.4-12.72,8.8-18.68
				c3.05-5.35,5.71-3.34,10.71-4.66"/>
			<g name="regions">
                <g className="st4" id="IsleOfMan">
                    <title>Isle of Man</title>
                    <path d="M481.33,1546.85c1.74-0.26,3.47-0.63,5.16-1.11l1.71-0.41c2.12-0.46,4.26-0.87,6.34-1.26l1.62-0.31
                        c6.68-0.94,13.16-2.65,19.33-5.13c6.95-3.16,13.4-7.43,19.09-12.65l1.34-1.22c2.42-2,4.6-4.27,6.51-6.76
                        c3.51-5.31,5.42-13.51,5.1-21.96l0-0.07l0-0.07c0.07-2.19-0.58-4.37-1.84-6.16c-0.93-0.88-1.97-1.64-3.1-2.24
                        c-1.82-1.03-3.5-2.23-5.03-3.59c-5.39-5.01-4.95-10.57-4.63-14.62l0.03-0.26c0.14-0.78,0.14-1.76,0.14-2.62v-4.05
                        c-0.67,0.38-1.33,0.78-1.97,1.19l-1.53,0.92c-1.72,0.96-3.62,1.79-5.76,2.71l-0.22,0.1c-3.21,1.42-9.16,4.05-9.98,6.11
                        c-0.22,0.81-0.35,1.65-0.39,2.49c-0.11,2.25-0.58,4.44-1.4,6.53c-1.62,4.34-3.91,8.42-6.81,12.09l-0.11,0.13
                        c-4.04,4.28-8.72,7.86-13.92,10.62c-3.19,1.76-4.84,6.06-5.17,13.53l0,3.84c0.08,2.45-0.02,4.99-0.29,7.47l-0.02,0.13
                        c-0.65,3.64-2,7.05-4.02,10.15c-0.39,0.67-0.74,1.27-1.04,1.89c-0.7,1.47-1.2,3.04-1.49,4.65l2.42-0.18L481.33,1546.85z"/>
                </g>
                <g className="st4" id="IsleOfWight">
                    <title>Isle of Wight</title>
                    <path d="M1092.17,2494.08l-0.11-0.09c-7.44-6.37-17.67-7.74-23.1-7.99l-0.84,0c-3.04,0.05-6.06,1.13-8.46,3.06
                        l-0.09,0.07c-3.18,2.25-6.68,3.98-10.41,5.12c-1.02,0.29-2.33,0.56-3.99,0.9l-0.49,0.1c-9.89,2-14.36,3.68-16.35,4.86
                        c1.1,0.26,2.77,0.57,5.3,0.92l0.33,0.05c2.12,0.18,4.22,0.57,6.26,1.15l0.11,0.03c6.89,2.4,11.63,7.37,15.45,11.37
                        c4.07,4.18,7.36,7.57,11.92,8.44h2.84c3.8,0,4.26-0.43,7.06-6.58l0.52-1.11c0.29-0.63,0.6-1.27,0.91-1.92l0.07-0.14
                        c2.12-3.6,4.83-6.78,8.04-9.45c1.56-1.47,6.01-5.69,6.23-7.21C1093.13,2495.03,1092.72,2494.48,1092.17,2494.08z"/>
                </g>
                <g className="st4" id="NorthWest" onClick={() => handleClick("north west england")}>
                    <title>North West</title>
                    <path d="M792.7,1805.83c0.97,1.55,1.27,2.99,0.87,4.27c-0.69,2.21-3.11,3.25-5.46,4.26
                        c-1.72,0.74-3.68,1.58-4.07,2.64c-0.41,1.1,0.28,2.49,1.17,3.29c1.11,1,2.68,1.47,4.35,1.97c1.6,0.48,3.26,0.97,4.73,1.99
                        c3.66,2.55,3.59,5.34,3.52,8.05c-0.03,1.13-0.06,2.31,0.16,3.59c0.88,4.99,4.26,9.8,7.24,14.05l0.06,0.08
                        c3.72,5.3,9.07,7.77,14.73,10.38c2.77,1.28,5.63,2.6,8.36,4.29c0.38,0.23,0.78,0.51,1.14,0.85l0.08-0.29
                        c13.79,4.05,27.82,6.07,37-6.51c1.85-2.54,2.88-5.43,3.96-8.49c1.07-3.03,2.18-6.16,4.17-8.93
                        c6.68-9.32,15.25-16.41,24.77-20.49c11.71-5.02,19.82-10.06,26.28-16.35c2.36-2.29,4.58-4.61,6.73-6.84
                        c5.63-5.86,10.99-11.41,18.26-16.12c-7.07-12.09-6.84-30.95-5.92-44.81c0.94-14.21,5.62-27.44,12.53-35.38
                        c1.46-1.69,3.3-3.24,5.3-4.39c-1.13-0.58-2.26-1.15-3.37-1.7c-6.07-3.02-11.81-5.88-17.05-10.64c-3.5-3.17-5.79-7.12-8.01-10.95
                        c-1.78-3.07-3.46-5.96-5.72-8.52c-2.24-2.53-6.31-3.88-10.25-5.18c-6.85-2.26-14.62-4.83-13-14.35c0.56-3.33,2.45-6.25,3.97-8.6
                        c9.73-15.04,16.38-27.65,6.5-41.59c-7.51-10.6-18.97-17.97-30.05-25.1l-1.06-0.68c-3.51-2.26-5.71-5.12-7.83-7.88
                        c-1.14-1.48-2.32-3.02-3.71-4.48c-1.91-1.99-4.19-2.99-6.13-2.64c-0.93,0.16-1.85,0.63-2.82,1.12c-0.99,0.5-2.01,1.02-3.18,1.3
                        c-5.6,1.37-9.56-3.76-10.85-5.45c-4.88-6.35-9.43-14.19-6.17-24.2c2.07-6.37,5.48-14.15,13.52-17.86
                        c2.02-0.93,4.18-1.67,6.27-2.38c5.06-1.73,9.85-3.36,12.86-7.57c2.54-3.54,3.89-9.05,3.62-14.73c-0.06-1.36-0.56-2.93-1.09-4.59
                        c-0.89-2.81-1.82-5.73-0.93-8.48c1.03-3.19,2.99-4.92,4.72-6.44c2.16-1.91,3.87-3.41,3.66-7.6c-0.06-1.11-0.22-2.26-0.39-3.47
                        c-0.63-4.39-1.34-9.36,3.43-12.8c3.93-2.83,9.59-3.84,14.3-3.56c-0.03-0.09-0.06-0.17-0.09-0.25c-0.68-2.15-0.79-4.61-0.9-7
                        c-0.12-2.77-0.25-5.63-1.31-7.61c-2.23-4.18-5.73-7.71-9.12-11.13l-1.2-1.22c-3.61-3.65-7.34-7.43-10.03-11.92
                        c-2.23-3.73-0.85-7,0.26-9.63c0.92-2.17,1.45-3.58,0.79-4.95c-0.64-1.34-3.36-1.9-5.55-2.35c-1.4-0.29-2.72-0.56-3.73-1.03
                        c-1.55-0.73-2.54-1.74-2.93-3.01c-0.72-2.37,0.77-4.82,2.34-7.41c1.2-1.99,2.45-4.04,2.41-5.69c-0.07-3.29,1.63-6.49,3.44-9.87
                        c1.4-2.63,2.85-5.35,3.46-8.15c0.73-3.34-2.97-5.86-7.07-8.08c-0.92-0.5-1.89-1.17-2.92-1.88c-3.86-2.67-6.78-4.39-9.14-2.31
                        c-0.83,0.74-1.49,1.72-2.18,2.76c-0.31,0.47-0.62,0.93-0.95,1.38c-2.05,2.82-5.78,4.9-9.51,5.31c-5.7,0.63-10.79-1.66-13.3-5.97
                        c-4.49-7.69-1.21-20.32,0.97-28.67c0.28-1.07,0.54-2.08,0.77-3.01c0.58-2.36-0.34-4.24-1.4-6.42c-0.58-1.18-1.17-2.4-1.57-3.79
                        c-1.1-3.81-0.74-8.34,0.98-12.13c2.24-4.94,7-8.5,12.14-9.07c0.54-0.06,1.09-0.09,1.65-0.13c1.76-0.11,3.43-0.21,4.31-1.19
                        c0.8-0.89,0.74-2.39,0.32-3.47c-1.11-2.86-4.62-6.62-7.5-8.03c-1.24-0.61-2.56-0.9-3.96-1.21c-2.24-0.5-4.55-1.02-6.71-2.85
                        c-7.77-6.62-13.22-16.39-15.77-21.63c-0.14-0.29-0.26-0.57-0.38-0.85c-9.62,3.71-16.47,7.82-22.7,13.6
                        c-8.85,8.21-15.99,13.7-26.36,17.47c-1.29,0.47-2.68,0.71-4.02,0.95c-3.42,0.6-5.55,1.09-6.29,3.67
                        c-0.69,2.4-0.56,4.96-0.42,7.67c0.19,3.63,0.39,7.39-1.34,11.22c-1.32,2.93-5.4,3.4-9,3.82c-1.36,0.16-2.65,0.31-3.46,0.57
                        c-0.81,0.26-1.63,0.56-2.46,0.85c-2.74,0.98-5.75,2.06-8.87,2.06c-0.73,0-1.47-0.07-2.21-0.2c-1.66,2.58-4.35,4.87-6.85,7
                        c-0.71,0.58-1.36,1.16-1.97,1.77c-2.8,3.42-5.43,7.05-7.82,10.78l-0.8,1.2c-1.18,1.85-2.57,3.66-3.91,5.41l-0.49,0.64
                        c-3.16,4.09-6.42,8.3-7.66,12.47c-0.36,2.06-0.27,4.22,0.27,6.25l0.02,0.1c0.69,3.33,1.98,9.52-1.54,15.72
                        c-2.73,4.5-5.95,8.65-9.58,12.38l-0.6,0.68c-1.4,1.56-2.72,3.03-3.93,4.56l-0.09,0.11c-3.05,3.31-4.98,7.42-5.58,11.88
                        c-0.2,1.7-0.3,3.45-0.41,5.55c0.05,5.84-1.06,11.53-3.31,16.92l-0.71,1.51c-3.97,8.22-5.6,12.13-2.77,19.01
                        c3.08,7.49,9.26,13.91,15.24,20.12l1.94,2.01c7.88,8.15,10.72,18.37,13.46,28.26c1.27,5.18,2.98,10.24,5.07,15.09
                        c4.05,8.64,8.48,12.67,10.92,12.77c1.6-0.21,3.08-1.05,4.08-2.33l0.07-0.08c2.78-3.18,6.63-5.11,10.84-5.43l0.12-0.01
                        c2.83-0.07,5.65,1.27,7.41,3.54l0.09,0.13c2.17,3.31,2.65,7.5,1.29,11.21c-1.82,5.86-4.81,11.28-8.85,16.02
                        c-0.73,0.9-1.49,1.72-2.4,2.65c-2.36,2.5-2.35,2.54-2.08,3.71c0.97,4.15,4.94,9.84,6.76,10.92c3.26-3.89,5.8-8.28,7.57-13.04
                        c2.28-5.92,5.46-11.39,9.45-16.28c2.49-2.96,6.1-4.66,9.94-4.69l0.07,0l0.07,0c3.09,0.19,6.07,0.98,8.84,2.37
                        c1.53,0.78,3.16,1.27,4.85,1.45c0.28-0.01,0.56-0.06,0.82-0.17c1.13-0.57,2.23-1.22,3.27-1.94c2.99-2.46,6.6-3.97,10.45-4.37
                        l0.21-0.01c2.54,0,7.31,0.96,10.71,7.37c3.44,6.52,5.45,18.05,2.41,26.59c-1.24,3.17-2.82,6.15-4.74,8.88
                        c-1.55,2.12-2.75,4.42-3.59,6.86c-0.5,2.78-0.5,5.6,0.03,8.38c0.98,4.98,0.71,10.2-0.79,15.07l-0.04,0.11
                        c-2.52,6.74-8.86,10-13.49,12.38c-1.51,0.74-2.95,1.55-4.32,2.45c-5.04,3.46-6.94,11.38-8.62,18.36l-0.52,2.12
                        c-3.65,14.77-0.32,18.88,3.01,20.79c1.68,0.81,3.43,1.49,5.22,2.01l0.11,0.04c4.33,1.54,10.89,3.87,11.69,9.81
                        c0.85,6.33-5.62,10.71-8.06,12.36c-3.86,2.56-7.27,5.69-10.15,9.29c-4.56,5.76-12.14,17.25-11.65,29.3
                        c0.24,5.9,3.98,11.26,7.95,16.94l2.58,3.7c2.21,3.32,4.01,6.67,5.75,9.91l0.22,0.43c2.5,5.42,5.86,10.37,9.98,14.69l1.01,0.93
                        c8.21,7.34,13.25,13.12,12.9,19.16c-0.29,4.9-4.34,8.71-9.24,8.71c-0.02,0-0.05,0-0.07,0c-7.13,0-14.32-8.1-15.69-9.72
                        c-1.94-2.29-3.82-4.61-5.64-6.86l-0.44-0.54c-2.56-3.16-5.21-6.42-7.94-9.42c-8.7-9.61-12.54-9.72-12.7-9.72
                        c-0.01,0-0.85,0.21-1.84,3.65c-0.24,0.84-0.12,2.15,2.67,5.43c1.08,1.25,2.24,2.47,3.46,3.62l0.14,0.14
                        c5.29,5.29,13.29,13.29,9,24.3c-0.54,1.3-1.22,2.5-2.03,3.6C775.24,1787.63,786.17,1795.44,792.7,1805.83z"/>
                </g>
				<g className="st4" id="scotland" onClick={() => handleClick("scotland")}>
                    <title>Scotland</title>
                    <path d="M190.78,553.4c1.12,3.84,1.54,7.79,1.25,11.76v0.92c0,12.09,1.72,21.35,5.42,29.12
                        c4.32,8.9,11.67,15.48,19.67,17.58c2.79,0.72,5.67,1.06,8.58,1.01c4.01-0.08,8.08-0.39,12.07-0.91l0.29-0.03
                        c2.24-0.25,4.55-0.5,6.85-0.69c4.79-0.44,9.63-0.44,13.52-0.44h4.58l21.62,0.13c1.54,0.02,3,0.59,4.14,1.6
                        c1.26,1.12,2.01,2.67,2.11,4.36s-0.47,3.31-1.59,4.57c-6.06,6.78-11.77,13.98-16.97,21.43c-2.84,3.93-5.52,9.39-3.89,12.02
                        c0.85,0.95,2.1,1.46,3.38,1.37l0.08-0.01l0.08,0c1.39,0.01,2.78-0.37,3.97-1.12c2.84-1.93,5.17-5.84,6.68-11.27
                        c0.51-1.8,0.92-3.58,1.42-5.86c0.78-4.1,1.96-8.21,3.48-12.16c5.12-11.83,15.67-20.5,28.07-23.33
                        c-3.97-4.09-9.53-6.36-15.29-6.15l-1.32,0c-3,0-6.1,0.22-8.75,0.43c-2.92,0.23-6.32,0.48-9.63,0.48
                        c-3.49,0.05-6.98-0.31-10.39-1.06c-18.5-4.15-29.64-21.31-33.91-35.98c-3.67-12.51-3.97-25.6-3.97-39.03v-7.63
                        c-0.25-13.32-1.5-28.56-8.86-40.35c-2.17-3.48-6.82-9.54-13.53-11.06c-0.81-0.19-1.69-0.27-2.47-0.27l-0.12,0
                        c-3.87-0.22-7.49,1.86-9.25,5.3c-1.92,5.09,2.06,11.8,5.92,18.3l0.67,1.13c2.98,4.5,4.93,9.79,5.61,15.24
                        c0.81,7.06-2.56,14.03-8.58,17.76l-0.09,0.05c-2.35,1.3-5,1.98-7.68,1.98c-0.04,0-0.09,0-0.14,0
                        c-5.51-0.19-10.68-2.37-14.62-6.16c-3.13-3.16-5.8-6.68-7.98-10.5l-0.18-0.29c-0.77-1.23-1.5-2.38-2.28-3.53
                        c-2.83-4.09-5.64-6.37-8.34-6.78h-0.42c-1.12,0.03-2.23,0.57-3,1.46l-0.1,0.11c-0.09,0.09-0.14,0.19-0.17,0.31
                        c0.69,1.46,1.73,2.72,3.04,3.66l0.18,0.14l1.17,1.06c5.42,5.13,7.28,11.06,5.11,16.31c-2,4.84-7.2,7.85-13.56,7.85
                        c-1.56-0.03-3.09-0.16-4.6-0.36c-1.05-0.15-2.11-0.24-3.16-0.26l-0.83,0c-4.31,0.56-7.18,6.48-6.77,11.27
                        c0.63,5.95,4.25,11.08,9.68,13.69c2.12,0.96,4.54,1.49,6.87,1.47c0.02,0,0.04,0,0.06,0c3.46,0,6.78-1.04,9.61-3.01
                        c0.58-0.4,1.14-0.84,1.73-1.3c2.8-2.2,6.62-5.19,11.8-5.19l0.29,0.01C183.68,543.06,188.95,547.33,190.78,553.4z"/>
					<path d="M308.67,947.36c-2.48,0.48-8.48,3.64-18.33,13.18c-5.66,5.32-10.87,11.15-15.49,17.36
						c-1.77,3.59-3.16,7.37-4.11,11.26c-3.26,11.03-6.32,21.35-13.82,23.86c-1.57,0.55-3.24,0.85-4.94,0.88
						c-6.03,0-9.36-3.84-11.57-6.38l-0.19-0.22c-0.79-0.94-1.74-2.06-2.32-2.34l-0.25,0.01c-0.59,0.01-2.19,1.04-6.35,7.89
						l-0.11,0.18c-0.73,1.21-1.5,2.48-2.2,3.52c-2.93,4.87-8.07,7.86-13.75,8l-0.08,0l-0.08,0c-3.65-0.21-7.2-1.08-10.54-2.58
						c-1.79-0.75-3.72-1.35-5.67-1.78c-0.25-0.05-0.51-0.08-0.78-0.08c-5.66,0-9.99,15.29-11.41,20.32l-0.37,1.33
						c-0.48,1.73-1.01,3.48-1.53,5.21c-1.85,5.52-3.17,11.19-3.93,16.9l-0.03,0.18c-0.27,1.19,0,2.42,0.74,3.38
						c1.59,1.76,6.51,2.56,10.43,2.56h0.56c1.4-0.03,2.86-0.14,4.3-0.31c1.94-0.24,3.91-0.37,5.86-0.41
						c1.61-0.01,3.22,0.15,4.79,0.48c3.22,0.53,6.22,2.42,8.12,5.19c2.97,4.71,1.7,10.19,0.78,14.2c-0.37,1.35-0.6,2.67-0.71,3.99
						c-0.02,1.51,0.1,2.39,0.22,2.87c3.77-0.39,11.34-5.41,15.05-7.87l0.5-0.33c0.9-0.6,1.73-1.14,2.48-1.61
						c5.82-3.62,10.14-9.2,12.16-15.75c0.69-2.96,0.92-5.99,0.7-9.02l-0.01-0.15l0-0.66c-0.01-5.36-0.01-11.44,3.49-16.85
						c2.76-3.84,6.5-6.67,10.85-8.26c1.87-0.7,3.62-1.83,5.05-3.25c2.29-2.95,3.17-6.88,4.09-11.05c0.52-2.5,1.18-5.03,1.96-7.49
						c4.59-13.6,12.53-25.28,20.2-36.57l0.12-0.17c1.43-2.07,3.23-4.38,4.96-6.62c5.14-6.59,12.18-15.6,11.43-21.66
						C308.84,948.07,308.75,947.64,308.67,947.36z"/>
					<path d="M389.72,1137.79c2.72,2.85,6.33,4.48,10.21,4.63c4.57-0.15,8.69-2.76,10.76-6.83l0.05-0.1
						c2.2-3.79,1.54-5.83-0.88-11.72c-0.5-1.17-1.02-2.48-1.53-3.85c-2.01-6.12-3.42-12.4-4.21-18.71
						c-1.79-11.05-3.47-21.46-10.44-27.49c-1.33-0.99-2.97-1.49-4.61-1.41c-5.23,0.15-10.17,2.45-13.6,6.33
						c-3,4-2.62,11.46-1.95,17.52c1.53,13.59,6.47,31.55,15.76,41.2L389.72,1137.79z"/>
					<path d="M734.69,1294.28c2.85,0.27,5.73-0.76,8.75-1.84c0.86-0.31,1.71-0.61,2.55-0.88
						c1.08-0.35,2.51-0.52,4.03-0.69c2.53-0.29,5.99-0.7,6.61-2.08c1.43-3.17,1.26-6.4,1.08-9.83c-0.15-2.84-0.3-5.77,0.53-8.65
						c1.3-4.51,5.38-5.22,8.66-5.79c1.27-0.22,2.47-0.43,3.51-0.81c9.89-3.6,16.78-8.9,25.34-16.85
						c6.57-6.09,13.76-10.4,23.86-14.27c-0.73-4.13,1.11-7.31,3.7-11.04c2.31-3.34,4.32-6.78,6.27-10.12
						c5.6-9.59,10.9-18.65,22.38-24.32c5.44-2.68,11.45-5.03,18.62-4.04c0.75,0.1,1.42,0.25,2.01,0.38c2.2,0.49,2.54,0.56,3.51-1.55
						c0.67-1.45,1.16-2.99,1.63-4.48c1.31-4.1,2.67-8.35,7.63-10.92c2.3-1.19,4.62-2.04,6.87-2.87c5.23-1.92,9.74-3.58,12.74-8.82
						c1.03-1.8,1.57-3.52,1.63-5.1c0.16-4.9-3.52-9.32-7.08-13.58c-1.46-1.75-2.83-3.4-3.95-5.08c-1.26-1.9-2.64-3.78-3.97-5.6
						c-3.24-4.43-6.6-9.01-8.71-14.36c-2.75-6.97,2.39-9.34,6.51-11.25c1.63-0.75,3.31-1.53,4.6-2.54
						c3.92-3.07,7.08-7.29,10.13-11.38c0.98-1.31,1.99-2.66,3-3.93c2.18-2.74,5.01-4.69,7.74-6.58c3.1-2.13,6.03-4.15,7.94-7.18
						c1.24-1.97,1.87-4.32,2.53-6.81c0.93-3.51,1.9-7.14,4.73-9.9c3.38-3.3,7.88-4.82,12.23-6.29c1.97-0.67,3.83-1.3,5.56-2.08
						c-1.45-8.37-4.14-16.02-10.91-20.74c-8.98-6.32-16.38-9.39-22.63-9.39c-0.01,0-0.03,0-0.04,0c-0.79,0-1.59,0.06-2.37,0.17
						c-1.47,0.21-2.98,0.3-4.43,0.3c-15.33,0-30.52-10.83-42.74-19.54l-5.25-3.71c-3.08-2.09-6.04-4.5-8.92-6.83l-0.39-0.32
						c-6.83-5.56-13.22-10.77-20.66-12.33c-0.51-0.11-1.06-0.16-1.56-0.15c-0.87,0-1.79,0.06-2.91,0.18
						c-1.04,0.11-2.45,0.27-3.94,0.27c-1.62-0.01-3.21-0.11-4.77-0.29l-0.64-0.05c-1.01-0.09-1.96-0.17-2.83-0.17
						c-1-0.03-1.92,0.13-2.8,0.46c-2.94,1.09-5.57,2.92-7.62,5.28l-1.39,2.21c-6.05,9.73-11.77,18.94-26.05,22.82
						c-4.63,1.27-9.42,1.94-14.23,1.99c-8.48,0-15-2.44-19.41-7.26c-0.45-0.49-0.87-1-1.29-1.51L740,994.4
						c-0.44-0.59-0.94-1.13-1.5-1.61c-1.2-0.85-4.22-0.96-6.44-1.04l-2.93-0.15c-5.63-0.46-11.38-1.04-18.15-1.8
						c-9.17-1.07-18.45-2.32-27.17-3.51l-6.61-0.89c-0.61-0.09-1.49-0.15-2.5-0.22l-0.97-0.07c-10.99-0.75-22.1-2.06-26.64-9.01
						c-2.11-3.24-2.5-7.3-1.04-10.88c1.54-4.37,5.88-9.68,17.56-9.68c5.01,0.13,9.96,0.79,14.78,1.96
						c5.17,1.34,10.18,3.24,14.92,5.65l0.18,0.08c5.74,2.65,11.17,5.15,16.59,5.15h1.43c7.14-0.56,16.34-3.08,20.88-8.47
						c1.82-2.44,3.33-5.1,4.5-7.91c1.53-3.6,3.47-7,5.77-10.14c6.44-8.31,13.82-15.83,21.97-22.38c5.4-4.01,12.01-6.01,18.64-5.69
						c3.83,0.07,7.74,0.39,11.58,0.95l3.58,0.43c1.34,0.15,2.71,0.24,4.08,0.22c8.26-0.18,16.51-2.34,23.83-6.25
						c6.89-3.55,9.64-6.84,8.21-9.82c-1.35-2.77-2.89-5.5-4.57-8.1c-1.26-1.97-1.82-2.42-2.03-2.51c-0.96-0.28-2-0.41-3.06-0.36
						l-1.83,0c-4.28,0.25-8.48-1.11-11.81-3.82l-0.08-0.07c-4.59-4.15-7.71-9.54-9.02-15.58c-0.58-2.5-0.98-5.09-1.16-7.67
						c-0.7-7-1.4-9.17-3.14-9.65c-0.84-0.21-1.77-0.33-2.63-0.32c-4.03,0.29-7.99,1.13-11.78,2.5l-1.75,0.55
						c-5.65,1.9-11.22,4.31-16.51,7.14c-3.75,1.88-7.58,3.79-11.56,5.46c-1.63,0.72-3.39,1.12-5.21,1.17
						c-3.36,0.09-6.5-1.8-7.94-4.86l-0.08-0.19c-1.49-4.25-0.39-9.06,2.78-12.25c3.12-3.32,6.76-6.17,10.8-8.42
						c9.63-5.54,20.14-8.91,31.23-10.01c3.77-0.49,7.61-0.64,11.32-0.78l0.75-0.03c7.97-0.27,15.49-0.53,22.08-3.77
						c9.82-4.78,19.32-12.91,28.26-24.15c5.77-7.26,8.63-16.6,11.07-25.54c0.67-2.44,1.24-4.96,1.86-7.75
						c1.94-8.62,3.94-17.52,9.41-25.39c4.12-5.44,8.91-10.23,14.29-14.29c2.21-1.81,4.25-3.49,6.17-5.29
						c5.85-5.45,7-13.61,8.22-22.25l0.18-1.24c0.1-0.7,0.21-1.39,0.32-2.1c1.58-10.33,5.96-21.14,13.8-34.01
						c1.5-2.44,3.23-4.81,4.9-7.11l0.29-0.4c4.28-5.77,8.31-11.22,8.45-17.06c-0.26-2.92-1.18-5.8-2.66-8.33l-0.08-0.14
						c-2.29-4.75-5.13-10.66-3.43-17.86c1.56-5.59,3.87-10.85,6.88-15.72c1.53-2.66,2.95-5.5,4.19-8.38l1.6-4
						c2.95-8.3,6.95-16.3,11.86-23.7l0.09-0.12c3.37-4.34,7.21-8.34,11.42-11.87c3.67-3.01,6.97-6.43,9.83-10.18
						c4.01-5.83,6.13-12.74,6.07-19.91c0.04-12.54-5.54-26.53-16.58-41.61l-1.2-1.65c-5.34-7.34-10.83-14.9-18.43-16.71
						c-1.96-0.45-4-0.69-6-0.66c-5.51,0.26-11.08,1.14-16.48,2.63l-0.16,0.04c-3.04,0.71-6.18,1.45-9.3,1.99
						c-14.6,2.54-29.52,3.86-44.34,3.9c-2.65,0-5.33-0.04-7.98-0.13c-9.99-0.59-19.98-1.89-29.75-3.86
						c-10.13-2.13-20.49-3.38-30.81-3.72c-1.75,0-3.54,0.06-5.26,0.19c-3.37,0.23-6.83,0.63-10.37,1.05
						c-5.89,0.78-11.91,1.24-17.88,1.35c-3.91,0.02-7.76-0.3-11.56-0.96c-3.1-0.57-6.27-1.26-9.34-1.93l-0.43-0.09
						c-6.99-1.83-14.12-2.9-21.27-3.2c-3.6-0.04-7.2,0.5-10.68,1.61c-3.2,1.18-6.42,2.65-9.47,4.36c-3.69,2.06-7.58,3.83-11.57,5.24
						c-3.53,1.09-7.1,1.59-10.73,1.48c-1.36,0-3.17-0.01-4.78-0.13c-1.37-0.1-2.8-0.1-4.17-0.1l-0.12,0
						c-2.79-0.17-5.59,0.37-8.11,1.58c-2.74,1.85-5.09,4.16-6.99,6.87c-2.59,3.54-5.69,6.6-9.23,9.13
						c-8.89,5.88-19.17,6.45-28.24,6.96l-3.26,0.11l-0.1-0.01c-2.92-0.2-5.87,0.29-8.57,1.41c-2.2,1.38-3.7,4.39-5.29,7.56
						l-0.25,0.51c-0.26,0.53-0.52,1.04-0.79,1.54c-3.83,6.99-8.99,12.95-15.35,17.71c-4.86,3.89-11.03,5.94-17.31,5.71
						c-0.01,0-0.02,0-0.03,0c-1.75,0-3.54-0.11-5.42-0.22l-3.14-0.19h-1.71c-0.42,0-0.82-0.01-1.24-0.02
						c-0.8-0.02-1.63-0.05-2.7,0.02c-1.77,0.11-3.62,0.11-5.4,0.11c-4.46,0-10.1-0.3-14.22-2.63c-3.58-1.62-5.67-5.32-5.21-9.24
						c1.06-7.2,10.18-9.4,11.22-9.63c2.99-0.6,6.02-0.92,9.04-0.98l0.55-0.03c1.38-0.07,2.68-0.14,3.92-0.28
						c8.63-1.34,16.68-5.18,23.2-11.1c11.23-9.75,19.48-23.31,26.76-35.28l0.57-0.94c2.51-4.13,5.1-8.39,7.79-12.44
						c4.2-6.69,9.17-12.99,14.74-18.66c0.77-0.72,1.6-1.47,2.51-2.26c4.01-3.55,5.63-5.51,6.24-6.59c-0.68-0.12-1.73-0.24-3.32-0.24
						h-3.46c-1.91,0.08-3.84,0.24-5.88,0.42l-0.12,0.01c-3.35,0.29-6.38,0.53-9.53,0.53c-4.57,0.08-9.11-0.61-13.48-2.04
						c-4.82-1.67-10.27-5.46-11.18-11.09c-0.57-4.24,1.38-8.4,4.95-10.66c3.04-2.03,6.62-3.2,10.32-3.36
						c1.25-0.09,2.54-0.35,3.78-0.78c0.72-0.34,1.17-0.77,0.35-6.87c-0.45-2.52-0.62-5.18-0.51-7.82
						c0.66-7.72,3.71-18.43,10.29-25.61c4.31-4.66,9.67-6.76,14.84-8.78c2.81-0.99,5.45-2.23,7.93-3.72
						c5.33-3.91,10.3-8.37,14.76-13.25l0.1-0.1c1.82-1.86,3.7-3.79,5.59-5.61c10.44-10.2,20.51-20.77,30.76-32.31
						c9-10.2,19.77-21.73,33.69-29.38c2.05-1.12,4.09-2.05,5.85-2.84c2.83-1.18,5.5-2.63,7.98-4.34
						c5.65-4.29,9.41-11.55,13.04-18.56l1.08-2.17c0.92-1.76,1.95-3.49,2.84-4.98c2.62-4.37,5.33-8.89,5.09-12.78
						c-0.54-2.83-1.71-5.57-3.38-7.92l-0.07-0.11c-1.26-2.03-2.39-4.17-3.37-6.35c-4.35-10.43,1.62-18.77,5.97-24.87l0.89-1.24
						c1.8-2.24,3.24-4.73,4.28-7.4c0.56-1.37,0.49-2.86-0.19-4.14c-1.39-2.46-5.9-4.8-12.34-6.38c-5.63-1.56-11.38-2.45-17.15-2.67
						c-3.56-0.05-7.07,0.61-10.39,2.01c-2.55,1.18-5.1,2.59-7.51,4.16c-4.4,2.99-9.25,5.3-14.39,6.83l-0.11,0.03
						c-3.76,0.88-7.61,1.3-11.48,1.22c0,0-0.01,0-0.01,0c-0.9,0-1.88-0.03-2.84-0.07c-0.9-0.03-1.79-0.06-2.62-0.06h-5.21
						c-1.93-0.02-3.85,0.09-5.74,0.33c-4.58,0.61-8.81,3.07-13.28,5.67c-3.55,2.2-7.29,4.08-11.15,5.62
						c-3.99,1.42-8.1,2.1-12.24,2.07c-6.42-0.24-12.76-1.24-18.89-2.97c-4.92-1.42-9.99-2.26-15.09-2.5
						c-2.44-0.04-4.83,0.39-7.13,1.29c-3.67,1.76-7.08,4.02-10.12,6.73c-3.52,3.03-7.36,5.65-11.43,7.81
						c-4.22,2.18-8.9,3.29-13.66,3.22l-1.39,0c-1.52-0.07-2.96-0.2-4.37-0.41c-1.11-0.16-2.25-0.27-3.37-0.3
						c-0.7-0.05-1.32,0.11-1.82,0.46c-0.46,0.7-0.85,1.45-1.17,2.22c-1.38,3.01-3.89,8.47-9.87,8.47l-0.14,0
						c-3.01-0.21-5.86-1.64-7.83-3.93c-4.06-4.17-4.06-9.98-4.06-13.45l0-0.13c0.1-1.55-0.07-3.08-0.52-4.56
						c-1.28-2.15-4.64-2.32-5.65-2.32c-2.37-0.05-4.56,0.63-6.46,1.93c-1.19,1.44-2.21,3.03-3.03,4.71l-0.05,0.1
						c-3.11,5.57-7.36,13.19-15.15,13.19c-1,0-1.93-0.12-2.88-0.37c-6.23-1.52-7.39-8.29-8.08-12.33l-0.03-0.17
						c-0.1-0.81-0.26-1.61-0.49-2.4c-2.7-6.16-7.92-10.85-14.34-12.88c-3.93-1.38-7.96-2.07-12.06-2.07c-0.04,0-0.07,0-0.1,0
						c-9.35,0-21.25,3.64-26.61,13.86c-3.11,5.94-2.74,12.62-2.35,19.69l0.01,0.14c0.44,8.18,0.9,16.63-3.46,25.25
						c-3.55,6.79-4.84,14.66-3.64,22.2c0.89,2.44,2.24,4.65,4.03,6.54l0.11,0.13c3.5,4.41,7.86,9.9,6.49,16.99
						c-0.82,4.32-4.36,11.56-18.82,11.56c-4.92-0.13-9.82-0.64-14.6-1.52c-0.31-0.05-0.65-0.12-0.96-0.18
						c-0.52-0.1-1.02-0.2-1.31-0.2l-0.29-0.02l-2.38-0.35c-3.04-0.54-6.08-0.85-9.1-0.93c-2.51,0-4.06,0.38-4.83,0.8
						c0.34,0.66,1.16,1.96,3.16,4.46l0.11,0.14c0.71,0.87,1.37,1.7,1.89,2.46c4.35,5.9,7.52,12.57,9.36,19.75
						c0.17,0.74,0.36,1.46,0.56,2.2l0.11,0.42c1.39,5.16,3.29,12.24,0.03,18.83c-2.84,5.57-8.46,9.16-14.69,9.37
						c-0.01,0-0.02,0-0.03,0c-2.11,0-4.17-0.46-6.01-1.34l-0.14-0.07c-1.69-0.97-3.19-2.21-4.45-3.66
						c0.65,7.55,5.27,14.21,13.75,19.84c4.63,2.93,9.55,5.6,14.56,7.94l0.12,0.06c0.36,0.19,0.98,0.44,1.65,0.69
						c0.31,0.12,0.64,0.24,0.96,0.37c5.63,2.2,17.37,6.8,15.83,17.64c-1.16,8-9.62,12.18-17.39,12.18
						c-0.68,0.01-1.43-0.03-2.14-0.12c1.92,1.49,3.41,3.46,3.71,6.1c0.29,3.6-1.56,6.89-4.73,8.48c-1.76,1.04-3.74,1.56-5.8,1.52
						c-0.79-0.01-1.61-0.08-2.41-0.19l-0.1-0.02c-5.26-1.05-10.26-3.37-14.45-6.71c-2.26-1.73-4.79-3.13-7.5-4.14
						c-0.07-0.02-0.19-0.04-0.32-0.05c-1.01,0.09-2.01,0.33-2.96,0.7c-2.27,0.87-4.63,1.35-7.04,1.44c-0.7,0-1.31-0.05-1.92-0.12
						l-0.16-0.03c-3.45-0.73-6.72-2.3-9.45-4.54c-0.62-0.44-1.21-0.85-1.75-1.19l-0.26-0.16c-1.43-0.87-4.44-2.69-6.32-3.28
						c0.12,1.18,1.8,4.27,2.46,5.48l1.7,3.1c0.27,0.48,0.55,0.98,0.83,1.51c4.27,7.8,13.16,24.06-1.46,32.76
						c-1.25,0.76-2.7,1.16-4.17,1.17c-6.2,0-9.7-6.33-14.13-14.34l-0.1-0.18c-6.44-12.13-9.07-13.65-9.61-13.84
						c-0.36,0.18-1.34,0.95-2.69,3.03c-3.18,4.89-3.67,14.31-2.15,20.15c0.73,1.11,5.07,4.22,6.72,5.41l0.21,0.15
						c6.25,4.46,17.89,12.76,11.11,22.52l-0.07,0.1c-2.53,3.2-6.13,5.31-10.16,5.97c-2.39,0.65-2.88,0.91-2.97,1.17
						c-1.05,3.27-0.7,6.93,0.99,9.98c3.98,7.88,12.33,13.7,21.27,19.45l0.96,0.56c2.39,1.31,8.09,4.43,7.47,10.93
						c-0.51,5.38-5.49,9.01-12.41,9.01h-0.24c-7.19-0.12-13.98-4.16-19.68-8.09c-0.94-0.63-1.84-1.37-2.92-2.28l-0.1-0.08
						c-0.86-0.7-2.97-2.42-4.06-2.91c-0.49,0.76-1.14,2.89-1.77,8.39c-0.06,0.43-0.1,0.81-0.15,1.18c-0.09,0.73-0.17,1.42-0.28,2.04
						c-1.58,9.8-0.12,28.39,6.66,36.32l0.08,0.1c1.65,2.21,4.29,3.52,7.04,3.52c1.36-0.02,2.75-0.24,4.09-0.65l1.37-0.43
						c5.68-2.04,11.7-3.32,17.81-3.76l0.14-0.01c11.76,0,13.35,8.02,13.56,10.48c0.21,4.52-1.99,8.76-5.73,11.17
						c-2.24,1.42-4.67,2.46-7.24,3.1l-0.41,0.13c-6.43,1.96-9.2,3.95-10.08,4.88c0.35,0.11,1.05,0.25,2.37,0.25
						c1.33,0,2.95-0.15,4.13-0.25l0.69-0.06c1-0.09,1.95-0.18,2.87-0.18h1.02c0.04,0,0.07,0,0.11,0c6.17,0,12.38,1.41,17.94,4.09
						c5.05,2.55,11.69,8.88,9.42,15.92c-1.33,4.53-5.61,7.57-10.37,7.39c-4.41-0.38-8.59-1.48-12.53-3.29
						c-1.49-0.65-3.05-1.2-4.65-1.63c-0.45,0.59-1.1,1.8-1.38,2.31c-0.81,1.63-1.85,3.22-3.06,4.67c-0.17,0.19-0.35,0.36-0.53,0.52
						c0.07,0.87-0.04,1.75-0.32,2.57l-0.65,2.1c-2.79,9.04-2.69,9.91,1.76,12.17c1.95,0.67,3.97,1.16,6.01,1.47l0.1,0.02
						c5.43,1.06,12.19,2.39,16.75,7.95c2.52,2.96,3.03,7.16,1.28,10.67l-0.06,0.1c-2.9,5.03-8.09,8.25-13.89,8.61l-0.13,0
						c-0.57,0-1.14-0.05-1.7-0.13l-0.13-0.02c-3.37-0.75-6.5-2.14-9.32-4.13l-2.77-1.69c-2.8-1.71-5.63-3.45-7.78-3.45h-0.34
						c-0.47,0.03-1.45,0.19-2.34,2.42c-1.67,4.15-0.72,9.49,0.75,11.15c1.48,1.34,3.17,2.39,5.03,3.12l0.12,0.05
						c3.85,1.84,9.13,4.36,11.74,10.28c1.82,3.33,1.81,7.45-0.05,10.77l-0.12,0.18c-2.07,2.91-5.58,4.5-9.12,4.18
						c-4.13-0.25-8.21-1.23-12.06-2.93c-0.7-0.3-1.44-0.66-2.21-1.03l-0.71-0.35c-2.61-1.61-5.47-2.66-8.5-3.12
						c-0.4,0-0.79,0.13-1.11,0.37c-2.82,2.11-4.39,9.96-5.15,13.74l-0.24,1.13c-0.58,1.96-0.39,4.02,0.54,5.83
						c1.14,1.38,2.8,2.27,4.59,2.46l0.16,0.02c0.43,0.08,0.83,0.16,1.22,0.16l0.19,0.01c3.24,0.3,9.95,0.93,12,7.18
						c2.54,7.83-5.6,14.71-9.97,18.4l-1.09,0.94c-2.48,2.36-4.86,5.01-7,7.81c-2.44,3.14-5.13,6.13-8.01,8.87l-0.08,0.07
						c-4.3,3.7-9.78,5.55-15.46,5.19c-0.83,0-1.65-0.05-2.45-0.09c-0.76-0.04-1.53-0.08-2.29-0.08l-0.25-0.02
						c-1.09-0.14-2.17-0.14-3.32-0.14h-0.5c-4.12,0.11-8.27,0.76-12.27,1.95c-11.65,3.08-13.95,6.47-14.3,7.12
						c-0.21,0.4-0.24,0.87-0.08,1.29c0.38,0.27,1.54,0.75,2.18,1.02l0.29,0.12c0.44,0.18,0.88,0.36,1.29,0.56
						c3.34,1.32,5.74,4.34,6.29,7.89c1.07,7.46-6.45,14.98-14.73,22.01l-0.96,0.83c-1.47,1.31-3.09,2.62-4.65,3.9l-0.17,0.14
						c-4.78,3.88-9.72,7.9-12.02,12.66l-0.09,0.17c-0.08,0.13-0.15,0.27-0.21,0.41c1.18,0.58,2.44,0.99,3.74,1.22
						c3.85,0.67,7.52,2.3,10.61,4.72c2.15,1.84,3.41,4.44,3.51,7.19c0.1,4.34-2.72,7.29-4.88,9.46l0.04,0.01
						c0.26,0.04,1.82,0.17,3.1,0.17c11.6,0.36,17.25,0.75,19.38,5.51c1.06,2.4,0.67,5.13-1,7.14c-1.12,1.37-2.42,2.71-3.67,4
						c-3.52,3.69-5.88,6.34-6,8.83c0.66,0.7,1.38,1.34,2.15,1.91c2.78,2.22,9.17,7.32,5.77,14.21c-1.16,2.34-3.84,5.13-10.03,5.13
						c-2.17-0.04-4.31-0.27-6.4-0.67l-0.65-0.03c-1.83-0.3-3.67-0.68-5.4-1.06c-3.31-0.82-6.66-1.3-10.02-1.45
						c-2.28-0.09-4.51,0.54-6.43,1.8c-0.33,0.25-0.51,0.43-0.6,0.54c0.45,1.32,3.45,4.46,7.02,5.89c3.36,1.21,6.95,1.76,10.59,1.63
						c2.02,0,4.21-0.11,6.14-0.21l1.8-0.09c1.85-0.1,3.77-0.19,5.68-0.19c9.72-0.13,19.87-0.67,27.42-5.71l0.15-0.09
						c0.51-0.28,1.15-0.75,1.94-1.33c6.58-4.75,12.99-9.01,19.26-9.04c1.18-0.07,2.36,0.07,3.51,0.39
						c5.11,1.46,8.28,6.43,7.64,11.55c0.67-0.31,1.29-0.7,1.86-1.18c4.67-3.81,8.29-8.76,10.5-14.34
						c3.06-7.58,5.39-15.47,6.94-23.48c1.34-6.03,2.57-11.41,4.25-16.79c3.03-9.49,10.43-15.87,16.38-21
						c2.2-1.88,4.38-3.91,6.85-6.38c9.53-10.11,18.73-21.36,27.26-32.02l2.18-2.66c0.71-0.88,1.44-1.89,2.2-2.97l0.29-0.4
						c2.63-4.3,6.24-8.02,10.46-10.78l0.16-0.09c2.45-1.3,5.22-1.99,7.99-2c5.94,0,10.34,3.55,10.94,8.84
						c1.62,13.89-8.67,22.84-16.93,30.03l-0.09,0.08c-3.82,2.94-7.18,6.41-10,10.31c-4.6,7.86-7.48,16.81-8.33,25.88
						c-0.17,1.83-0.32,3.64-0.32,5.73c-0.26,6.48-0.55,13.73-4.23,20.42c-2.21,4.03-4.6,7.97-6.92,11.77
						c-5.44,8.94-10.58,17.39-13.59,26.82c-1.91,5.98-0.65,10.46,0.95,16.14c1.01,3.35,1.8,6.83,2.36,10.32l0.02,0.14
						c0.46,5.22-0.26,10.57-2.08,15.48c-0.78,2.34-1.35,4.83-1.66,7.34c-0.14,1.54-0.25,3.17-0.35,4.78
						c-0.12,6.15-1.09,12.3-2.87,18.24l-0.04,0.12c-3.5,9.42-7.79,18.62-12.76,27.35c-3.48,6.49-6.27,13.04-3.13,16.79
						c0.42,0.36,0.88,0.67,1.37,0.93c2.23,1.33,7.25,4.35,7.01,10.76l-0.02,0.2c-0.47,3.44-1.55,6.73-3.22,9.77
						c-2.13,4.61-2.69,6.59-2.72,7.41c0.13,0.06,0.31,0.13,0.55,0.2c0.24,0.02,0.49,0.02,0.73-0.02l0.26-0.02
						c0.79,0,1.47-0.05,2.51-0.13l1.46-0.1c1.4-0.1,2.86-0.2,4.35-0.2c8.5,0,11.79,3.9,13.06,7.18c3.1,7.98-5.29,13.47-9.8,16.42
						l-0.2,0.13c-1.7,1.08-2.13,1.41-2.22,1.48c-3.8,3.29-5.54,8.54-7.38,14.1l-0.04,0.12c-0.48,1.47-0.96,2.91-1.46,4.35
						l-0.57,1.56c-3.58,8.93-6.28,18.2-8.05,27.59c-1.21,9.58-1.88,19.32-1.96,28.97v2.68c-0.33,10.79-3.89,20.41-7.33,29.7
						l-0.64,1.75c-0.82,2.22-1.66,4.52-2.39,6.76c-2.36,7.08-1.95,9.58-1.49,10.44c0.18,0.32,0.55,0.57,0.98,0.65l0.19,0.04
						c1.08,0.3,2.2,0.46,3.32,0.47c6.14,0,12.1-4.95,17.85-9.74l0.8-0.65c3.99-3.31,4.6-4.76,4.11-9.65
						c-3.17-29.8,3.73-56.14,10.9-77.98c1.57-4.76,3.47-9.21,5.6-14.11l1.2-2.84c0.29-0.67,0.56-1.33,0.83-1.98l0.2-0.49
						c1.76-5.12,4.42-9.81,7.91-13.95l0.2-0.2c3.56-3.16,7.67-5.47,12.21-6.88c9.1-3.5,8.84-4.36,7.97-7.21
						c-1.03-3.05-2.84-5.87-5.23-8.12c-0.22-0.21-0.46-0.4-0.71-0.58c-2.92-2.2-8.24-6.21-6.3-12.12c1.31-3.77,4.95-6.09,8.87-5.7
						c2.3,0.04,4.54,0.49,6.68,1.35l0.19,0.09c4.12,2.18,7.66,5.37,10.26,9.24c0.82,1.04,1.56,1.98,2.28,2.76
						c2.2,1.88,4.85,3.2,7.67,3.81l0.52,0.15c0.69,0.22,1.66,0.45,2.69,0.69l0.22,0.05c3.81,0.58,7.5,2.04,10.68,4.23l0.16,0.12
						c2.84,2.42,3.86,5.41,4.24,8.21c0.81-4.21,1.88-8.37,3.19-12.4c2.01-5.61,3.15-11.42,3.4-17.32c-0.16-2.32-0.58-4.62-1.23-6.85
						l-0.04-0.14c-1.25-4.94-2.67-10.53-0.7-16.55c2.5-7.29,10.84-10.29,16.35-12.28c0.45-0.18,0.99-0.38,1.51-0.57
						c0.59-0.21,1.16-0.42,1.64-0.63c5.19-2.44,10.77-3.74,16.55-3.84c0.01,0,0.02,0,0.03,0c1.25,0,2.48,0.07,3.69,0.21
						c6.83,1.03,13.46,2.85,19.79,5.43c2.72,1,5.59,2.06,8.47,2.92l0.18,0.07l1.05,0.43c8.39,1.89,13.24,3.8,14.8,8.33
						c0.87,2.56,0.29,5.39-1.5,7.4c-2.6,3.14-7.08,3.57-11.11,3.57h-4.45c-6.25-0.24-12.48-0.84-18.59-1.77l-0.42-0.06
						c-3.22-0.43-6.56-0.88-9.8-1.18c-1.96-0.19-3.25-0.27-4.33-0.27c-1.08,0-1.71,0.09-2.05,0.17c-0.12,0.41-0.13,0.84-0.05,1.26
						l0.04,0.3c0.25,5.56,0.14,11.19-0.33,16.73l-0.04,0.6c-0.22,3.39-0.43,6.59-0.43,9.8c-0.11,10.25,0.4,20.6,1.52,30.77
						c1.1,9.54,3.43,20.4,12.32,28.5c2.09,1.9,4.51,3.71,6.85,5.45c8.82,6.56,19.8,14.73,15.73,31.57
						c-1.93,8.13-4.35,16.25-7.2,24.11c-1.99,5.14-4.29,10.18-6.83,15.01l-0.21,0.43c-1.55,3.13-3.16,6.36-4.51,9.52
						c-1.71,4.25-3.19,8.69-4.38,13.17c-1.26,4.68-2.79,9.34-4.57,13.85c-3.71,8.33-8.3,16.18-13.68,23.44l-1.17,1.71
						c-8.82,12.64-17.93,25.69-17.78,40.72c0.02,0.99,0.05,2.17,0.18,3.35c0.32,2.59,1,5.16,2.02,7.59c0.82,2.11,1.53,4.25,2.12,6.4
						l0.03,0.12c1.46,7.08,1.21,14.5-0.75,21.46c-2.05,7.81-6.96,8.66-8.97,8.66c-3.56,0-6.57-2.18-8.97-6.47
						c-3.4-6.63-5.62-13.67-6.61-20.98c-0.25-1.64-0.99-5.53-2.21-5.53h-0.41c0,0,0,0,0,0c-0.19,0-0.37,0.09-0.46,0.25
						c-2.09,3.06-1.28,13.54,1.75,23.47c1.56,4.06,3.69,7.88,6.32,11.35l0.07,0.09l0.75,1.1c0.57,0.83,1.13,1.65,1.66,2.49
						c8.03,11.99,15.2,24.76,21.29,37.9c1.24,2.97,2.2,5.99,2.88,9.05l0.02,0.09c0.95,3.72,1.74,6.82,3.58,9.13
						c0.25-0.56,0.4-1.17,0.45-1.79c0.17-4.11-2.04-9.78-4.19-15.26c-4.41-11.12-10.46-26.36,2.58-37.81
						c3.95-3.5,9.04-5.39,14.32-5.27c4.58,0.1,9,1.15,13.14,3.11c12.41,5.67,21.77,15.08,30.82,24.17c0.96,0.92,1.96,1.9,2.96,2.88
						c1.95,1.91,3.97,3.88,5.94,5.7c0.95,0.88,1.93,1.8,2.94,2.79c4.64,4.47,10.94,10.55,16.69,11.09c0.07-0.94,0.04-2.8-0.34-6.48
						c-0.47-3.02-0.53-6.09-0.17-9.13l0.03-0.2c0.5-2.3,1.19-4.59,2.06-6.79c1.95-5.39,1.99-6.78,1.35-7.36
						c-1.12-0.86-2.33-1.61-3.58-2.24l-0.2-0.11c-3.99-2.2-9.44-5.22-10.87-12.08c-0.95-4.48-0.4-8.04,1.63-10.58
						c1.75-2.1,4.28-3.22,6.97-3.17l0.16,0.01c3.61,0.39,7.14,1.61,10.22,3.53l1.41,0.71c2.59,1.36,5.33,2.39,8.15,3.07
						c0.57,0.1,1.15,0.14,1.72,0.13c0.93,0,1.95-0.06,3.45-0.21c1.1-0.11,2.76-0.25,4.52-0.25c3.15-0.12,6.37,0.66,9.19,2.24
						c4.72,2.87,6.51,8.55,7.59,11.95l0.71,2.14c2.3,7.23,3.18,8.03,6.1,8.13c0.88-0.11,1.73-0.43,2.46-0.93
						c2.4-1.54,5.12-2.39,7.91-2.5c1.92,0.01,3.7,0.37,5.38,1.09l0.16,0.08c2.07,1.1,4.3,1.75,6.63,1.93c0.59,0,1.17-0.05,1.75-0.15
						c6.63-0.99,14.27-5.26,15.16-10.04c2.18-11.73,11.2-14.65,18.44-16.99l2.74-0.89c0.44-0.17,0.91-0.37,1.38-0.56l0.62-0.26
						c2.6-1.27,5.38-1.99,8.28-2.12l1.56,0l0.15,0.02c2.79,0.42,5.44,1.37,7.87,2.81c0.65,0.4,1.33,0.73,2.02,0.98
						c-0.64-2.59-0.69-5.3-0.13-7.91l0.03-0.14c2.14-7.47,9.71-10.68,15.54-11.17l0.74-0.07c1.01-0.1,2.05-0.2,3.12-0.2
						c3.08-0.06,6.2-0.48,9.23-1.23c1.66-0.6,3.14-1.64,4.29-2.99l0.07-0.08c1.69-1.79,3.61-3.36,5.7-4.65
						c3.21-1.93,9.39-2.61,13.72-2.61c9.04,0,14.26,2.7,15.52,8.02C735.11,1291.71,735.04,1293.03,734.69,1294.28z"/>
				</g>
				<g className="st4" id="midWales" onClick={() => handleClick("mid wales")}>
                    <title>Mid Wales</title>
					<path d="M499.22,2119.98c1.49,0.07,2.89,0.14,4.07,0.43c2.26,0.56,4.43,1.38,6.52,2.18c2.57,0.98,5,1.91,7.58,2.37
					c4.16,0.75,8.27,0.23,12.62-0.33c3.66-0.47,7.44-0.95,11.26-0.61c2.98,0.27,4.77,2.03,6.35,3.59c1.12,1.11,2.18,2.16,3.59,2.61
					c9.13,2.94,21.94-7.01,29.59-12.96l2.83-2.19c6.81-5.26,13.85-10.71,22.32-13.71c6.12-2.17,13.05-1.69,19.75-1.22
					c3.51,0.24,6.84,0.48,10,0.34c7.12-0.3,12.25,0.79,17.35,7.21c0.69,0.87,1.45,1.78,2.25,2.73c4.71,5.64,10.58,12.66,9.7,20.17
					c-0.25,2.14-1.05,4.06-1.83,5.92c-3.17,7.58-4.72,14.87-6.21,24.19c-1.72,10.82-5.65,17.89-13.54,24.4l-0.23,0.19
					c-3.78,3.12-8.96,7.39-7.33,12.25c1.15,3.43,7.57,6.46,11.16,6.75c3.2,0.26,3.64-1.02,4.44-3.35c0.69-2,1.63-4.74,5.24-5.12
					c2.59-0.27,4.96,1.02,6.69,1.97c0.76,0.42,1.51,0.85,2.25,1.27c3.3,1.9,6.42,3.69,10.19,4.04c1.33,0.12,3.86,0.1,5.76-1.44
					c1.73-1.4,3.07-3.27,4.5-5.25c1.21-1.68,2.47-3.43,4.01-4.93l0.32-0.31c6.21-6.08,10.18-8.6,12.88-8.17
					c1.07,0.17,1.92,0.78,2.44,1.78c0.35,0.67,0.51,1.36,0.66,1.96c0.13,0.57,0.25,1.07,0.48,1.4c1.56,2.22,8.87,5,12.23,5.66
					c3.26,0.63,5.64-0.23,8.46-3.12c2.94-3,6.92-2.71,10.42-2.46c1.71,0.12,3.32,0.24,4.73,0.01c6.68-1.09,15.2-5.15,21.22-10.1
					c5.5-4.53,6.35-10.62,2.73-19.75c-1.56-3.95-2.83-8.21-3.84-12.94c-0.19-0.3-0.31-0.49-0.34-0.54l0.2-0.12
					c-0.82-3.92-1.48-8.15-1.98-12.85c-0.38-3.48-0.94-7.11-1.49-10.63c-1.61-10.36-3.27-21.07-0.89-31.4
					c0.26-1.12,0.5-2.34,0.75-3.62c1.21-6.19,2.72-13.9,7.86-17.85c1.54-1.19,3.48-1.9,5.35-2.58c2.33-0.86,4.54-1.66,5.72-3.23
					c1.47-1.96,0.87-3.14-0.53-5.43c-1.09-1.79-2.45-4.03-1.89-6.94c0.39-1.98,1.92-3.14,3.27-4.16c1.73-1.31,2.2-1.83,1.96-2.82
					c-0.5-2.12-4.74-3.29-7.83-4.14c-1.29-0.35-2.5-0.69-3.42-1.07c-5.95-2.5-11.51-6.24-15.68-9.22c-1.14-0.81-2.52-1.62-3.98-2.48
					c-4.52-2.65-9.63-5.66-11.16-10.68c-3.03-9.97,11.07-14.27,16.37-15.9c3.49-1.06,7.11-1.83,10.62-2.56
					c1.81-0.38,3.63-0.76,5.42-1.18c0.41-0.1,0.8-0.18,1.15-0.26c2.8-0.63,2.8-0.63,2.94-3.78l0.06-1.33
					c0.17-3.31,0.46-8.86-1.25-9.86c-0.78-0.46-2.42,0.04-4.5,1.37c-0.29,0.19-0.9,0.65-1.54,1.14c-4.06,3.1-6.13,4.41-7.7,4.04
					c-2.78-0.67-3.93-2.26-4.41-3.47c-1.8-4.56,2.41-11.51,6.86-18.23c0.88-1.33,1.65-2.48,1.92-3.05
					c2.95-6.17,4.67-12.51,6.1-18.43c0.45-1.88,2.44-4.05,4.74-6.55c1.46-1.59,4.16-4.52,4.22-5.56c-2.93-2.25-6.36-4.06-9.98-5.98
					c-8.51-4.5-17.3-9.16-19.42-19.82c-0.69-3.47,0.54-6.15,1.72-8.74c0.75-1.64,1.46-3.19,1.67-4.92c0.07-0.58,0.04-1.11-0.09-1.61
					l-0.08-0.02c0.01-0.04,0.03-0.09,0.04-0.13c-0.22-0.71-0.64-1.34-1.28-1.86c-1.71-1.4-4.65-1.85-6.98-1.07
					c-2.36,0.79-4.19,2.1-6.14,3.48c-2.77,1.98-5.64,4.02-10.1,4.6c-3.28,0.42-6.69,0.65-10.29,0.88
					c-10.23,0.67-20.8,1.36-28.72,6.78l-1.27,0.86c-4.14,2.8-9.3,6.29-11.24,10.61c-1.2,2.67-0.12,5.17,1.13,8.07
					c0.82,1.9,1.66,3.86,1.87,5.99c0.91,9.39-7.62,15.86-15.93,17.91c-2.49,0.61-5.93,0.18-9.57-0.29
					c-5.19-0.66-11.07-1.4-13.2,1.25c-0.31,0.38-0.47,0.98-0.64,1.66c-0.56,2.25-0.81,4.67-1.07,7.24c-0.45,4.36-0.91,8.87-3,12.75
					c-2.28,4.25-6.72,7.95-10.28,10.92l-0.13,0.11c-6.9,5.76-16.05,11.95-26.66,10.25c1.89,9.63,1.21,20.17-2.06,31.5
					c-6.27,21.52-18.71,40.46-34.14,51.97c-4.07,2.81-8.44,5.03-13.05,6.64c-4.53,1.82-8.86,3.55-11.82,6.63
					c-6.59,7.11-14.98,12.47-24.23,15.49c-4.16,1.21-8.38,2.08-12.6,2.61c-3.32,0.31-6.56,0.96-9.69,1.94
					c-0.08,0.56-0.17,1.15-0.28,1.78c-0.47,2.84-1.06,6.38,0.01,8.35C493.22,2119.68,496.41,2119.84,499.22,2119.98z"/>
				</g>
				<g className="st4" id="northWales" onClick={() => handleClick("north wales")}>
                    <title>North Wales</title>
					<path d="M624.86,1978.46l0.13-0.11c3.36-2.81,7.55-6.3,9.56-10.04c1.81-3.36,2.24-7.57,2.66-11.64
						c0.26-2.56,0.53-5.2,1.14-7.65c0.24-0.95,0.52-1.96,1.21-2.81c3.18-3.97,9.95-3.11,15.92-2.35c3.35,0.43,6.52,0.83,8.47,0.35
						c7.1-1.75,14.4-7.09,13.66-14.71c-0.16-1.67-0.88-3.33-1.64-5.09c-1.33-3.1-2.84-6.61-1.11-10.48
						c2.29-5.1,7.84-8.85,12.29-11.86l1.26-0.86c8.59-5.88,19.58-6.6,30.21-7.29c3.55-0.23,6.91-0.45,10.1-0.86
						c3.72-0.48,6.16-2.22,8.75-4.06c2.04-1.45,4.15-2.96,6.93-3.88c3.29-1.1,7.33-0.44,9.83,1.59c0.26,0.21,0.49,0.43,0.71,0.66
						c2.16-4.23,6.18-7.76,11.65-10.16c1.75-0.77,3.37-1.9,4.94-2.99c3.33-2.33,6.78-4.74,11.78-4.03c2.01,0.28,3.91,0.89,5.75,1.48
						c1.61,0.51,3.12,1,4.69,1.28c1,0.18,2.06,0.24,3.17,0.31c2.47,0.15,5.03,0.31,7.39,1.74c2.42,1.46,3.49,4.11,4.43,6.44
						c1.1,2.72,1.9,4.41,3.63,4.86c0.46,0.12,2.02-0.01,6.66-3.77c4.79-3.88,7.83-8.6,8.58-13.33c0.04-0.26,0.15-0.96-0.02-1.27
						c-0.13-0.23-0.56-0.5-0.83-0.66c-2.58-1.6-5.36-2.88-8.04-4.12c-5.77-2.67-11.73-5.42-15.92-11.38l-0.06-0.08
						c-3.16-4.5-6.75-9.6-7.74-15.25c-0.28-1.57-0.24-2.96-0.21-4.18c0.06-2.5,0.1-3.88-2.23-5.51c-1.07-0.75-2.43-1.15-3.87-1.58
						c-1.91-0.57-3.88-1.16-5.5-2.62c-1.65-1.48-2.89-4.09-1.97-6.57c0.84-2.26,3.31-3.32,5.7-4.34c1.64-0.71,3.5-1.5,3.78-2.4
						c0.16-0.51-0.21-1.25-0.54-1.78c-6.38-10.14-17.26-17.67-30-20.84c-3.05,2.78-7.06,4.38-11.37,4.38c-0.09,0-0.18,0-0.27,0
						c-4.03,0-7.91-1.52-10.89-4.3c-2.97-3.05-5.28-6.49-6.95-10.3l-0.05-0.13c-0.98-2.72-2.56-5.26-4.57-7.34
						c-2.63-1.99-5.88-3.02-9.19-2.9c-1.56,0.01-3.07,0.15-4.55,0.4c-5.36,1.17-10.7,2.94-15.76,5.25l-0.18,0.08
						c-7.69,3.13-15.64,6.36-24.61,6.59l-1.71,0c-9.81,0.22-19.54-2.25-28.1-7.16l-0.35-0.21c-2.09-1.25-5.58-3.35-7.55-3.35h-0.09
						c-0.73,1.39-1.25,2.95-1.53,4.58l-0.02,0.11c-0.97,4.23-2.43,10.62-8.55,13.62c-3.79,1.67-7.72,2.58-11.77,2.75
						c-1.97,0.12-3.97,0.46-5.9,1.01c-1.91,0.81-3.67,1.92-5.22,3.3c-2.64,2.28-5.6,4.08-8.82,5.35c-1.66,0.58-3.31,0.88-4.99,0.9
						c-3.59,0.22-7.1-1.48-9.15-4.45l-0.08-0.12c-1.79-3.04-1.79-6.81,0-9.85c1.83-2.98,4.18-5.48,7-7.48l0.08-0.07
						c0.84-0.68,1.57-1.29,2.18-1.82c-0.75,0.2-1.49,0.44-2.21,0.71c-3.08,1.13-6.28,1.81-9.54,2.03c-2.06,0.05-4.03-0.43-5.78-1.35
						c-6.82-3.76-9.88-11.3-11.9-16.3c-0.35-0.85-0.71-1.69-1.07-2.47c-4.07-9.15-7.58-11.02-11.58-11.02
						c-0.73,0.01-1.49,0.08-2.22,0.21c-1.9,0.28-3.77,0.4-5.62,0.38c-2.24,0-4.61-0.12-6.85-0.24c-2.57-0.14-4.87-0.26-7.09-0.26
						c-8.59,0-10.7,1.92-11.63,3.8c-0.28,0.55-0.37,0.73,1.05,3.75c0.52,1.05,1.01,2.2,1.42,3.28c2.6,6.88,1.77,13.39,0.98,19.68
						l-0.02,0.15c-0.77,6.04-1.37,10.81,0.87,15.12c4.06,7.94,12.38,16.26,21.89,17.13c1.37-0.27,2.73-0.67,4.03-1.17
						c2.79-1.14,5.77-1.85,8.82-2.09c2.88-0.16,5.57,1.12,7.24,3.41l0.13,0.21c1.6,2.87,1.75,6.36,0.41,9.36
						c-0.61,1.67-1.2,3.34-1.78,5.02c-2.62,8.54-6.32,16.8-10.99,24.5c-7.95,12.15-18.92,20.52-29.52,28.62
						c-1.31,1.03-2.61,2.03-3.91,3.04c-2.77,2.14-5.64,4.35-8.3,6.63l-0.95,0.79c-9.63,8.03-14.71,13.29-17.38,16.56l1.12-0.47
						c5.23-1.97,10.82-2.5,16.25-1.55c4.14,0.93,7.93,2.59,11.34,4.95c0.49,0.33,0.99,0.64,1.51,0.93c-0.01-0.17-0.02-0.34-0.02-0.52
						c-1.6-26.36,29.21-30.16,42.38-31.79c1.77-0.21,3.51-0.32,5.24-0.32c12.3,0,20.97,5.68,23.78,15.59l0.03,0.11
						c1.23,5.48,1.31,11.06,0.24,16.57c-0.8,5.67-1.23,10.29,0.97,13.76c1.16,1.45,2.49,2.77,3.94,3.92l0.09,0.08
						c4.15,3.71,9.31,8.32,9.31,14.83c-0.2,4.15-2.19,7.84-5.46,10.23c-8.91,7.39-7.64,13.77-1.45,25.24
						c1.93,3.61,3.4,7.39,4.43,11.33C609.33,1990,618.2,1984.01,624.86,1978.46z"/>
				</g>
				<g className="st4" id="westMidlands" onClick={() => handleClick("west midlands")}>
                    <title>West Midlands</title>
					<path d="M973.54,2103.1c4.43-1.32,10.41-1.61,13.93,1.53c7.38,6.58,13.17,20.86,14.87,30.96
						c0.45,2.66,0.62,5.22,0.58,7.71c6.08-3.31,15.96-10.37,19.62-18.36c1.89-4.14,2.16-6.9,2.4-9.33c0.31-3.2,0.58-5.96,4.31-9.74
						c1.78-1.8,2.84-3.67,3.87-5.47c2.32-4.07,4.72-8.27,14.77-10.7l13.78-3.33c1.03-2.14,2.94-4.11,4.83-6.03
						c2.3-2.35,4.68-4.78,5.42-7.41c1.13-4.04,3.4-7.55,5.59-10.94c0.49-0.75,0.97-1.51,1.45-2.26c8.29-13.29,5.76-33.78-0.83-46.32
						c-5.5-10.45-15.66-17.97-25.5-25.23c-1.35-1-2.7-1.99-4.01-2.98c-7.3-5.49-15-10.26-23.58-15.47c-0.74-0.45-1.47-0.82-2.18-1.18
						c-2.56-1.31-5.46-2.79-4.7-7.08c0.39-2.17,1.72-4,3-5.77c1.12-1.55,2.18-3.01,2.55-4.57c1.25-5.27-0.32-10.35-4.18-13.6
						c-2.33-1.96-5.43-2.43-8.72-2.93c-3.25-0.49-6.61-1-9.44-3.02c-2.46-1.76-3.6-3.78-3.38-6.02c0.48-4.84,7.43-8.67,11.54-10.51
						l0.82-0.36c3.54-1.57,11.84-5.26,12.86-9.4c0.28-1.13-0.05-2.26-0.99-3.45c-1.16-1.46-3.1-2.28-4.65-2.84
						c-4.27-1.53-8.92-2.22-13.41-2.9c-1.3-0.2-2.6-0.39-3.9-0.61c-5.64-0.93-12.12-2.4-17.41-6.18c-5.12-3.67-8.23-10.51-8.31-18.31
						c-0.08-7.79,2.92-14.71,8.02-18.52c1.62-1.21,3.51-1.97,5.33-2.71c2.27-0.92,4.42-1.79,5.84-3.39c2.73-3.08,1.63-4.73-1.1-8.17
						c-0.56-0.71-1.14-1.44-1.7-2.22c-3.62-5.13-5.76-10.99-7.83-16.66c-1.94-5.32-3.95-10.82-7.16-15.53
						c-3.12-4.58-7.27-7.18-11.66-9.93c-3.44-2.15-7-4.38-10.05-7.64c-0.66-0.7-1.27-1.44-1.85-2.22
						c-6.99,4.53-12.23,9.96-17.74,15.69c-2.16,2.25-4.4,4.59-6.8,6.92c-6.74,6.55-15.12,11.78-27.19,16.95
						c-9.02,3.87-17.15,10.6-23.51,19.48c-1.74,2.43-2.73,5.23-3.78,8.19c-1.11,3.13-2.25,6.36-4.37,9.25
						c-5.94,8.15-13.73,10.81-21.97,10.81c-5.39,0-10.97-1.14-16.35-2.64c-0.02,0.33-0.05,0.65-0.1,0.95
						c-1.11,7-5.84,12.12-9.62,15.17c-3.16,2.56-6.71,5.05-9.33,4.37c-3.32-0.86-4.56-3.93-5.66-6.64c-0.84-2.08-1.64-4.05-3.2-5
						c-1.73-1.04-3.81-1.17-6.02-1.31c-1.15-0.07-2.33-0.15-3.51-0.35c-1.77-0.31-3.45-0.85-5.08-1.37c-1.8-0.57-3.49-1.12-5.25-1.36
						c-3.85-0.54-6.53,1.34-9.65,3.52c-1.68,1.18-3.42,2.39-5.45,3.28c-3.41,1.49-9.24,4.87-11.09,11.04c0.09,0.64,0.11,1.31,0.03,2
						c-0.27,2.19-1.11,4.02-1.92,5.8c-1.08,2.35-2.01,4.38-1.51,6.91c1.83,9.26,9.62,13.38,17.88,17.75c3.6,1.91,7.32,3.88,10.47,6.3
						c0.65,0.5,1.03,1.19,1.1,2c0.19,2.27-2.21,4.89-5,7.92c-1.68,1.83-3.76,4.1-4.04,5.23c-1.46,6.09-3.24,12.6-6.31,19.02
						c-0.36,0.76-1.1,1.87-2.12,3.41c-2.58,3.89-7.94,11.98-6.57,15.47c0.2,0.51,0.65,1.22,2.2,1.62c1.01-0.21,4.11-2.57,5.3-3.48
						c0.76-0.58,1.35-1.03,1.75-1.29c3.24-2.06,5.73-2.53,7.62-1.43c3.29,1.92,3,7.6,2.74,12.6l-0.06,1.3
						c-0.22,4.91-1.26,5.68-5.28,6.58c-0.34,0.08-0.71,0.16-1.11,0.25c-1.82,0.43-3.68,0.82-5.49,1.2c-3.44,0.72-7,1.47-10.36,2.5
						c-7.1,2.17-16.31,5.79-14.38,12.15c1.18,3.89,5.76,6.59,9.81,8.96c1.52,0.89,2.96,1.74,4.21,2.63c4.05,2.89,9.42,6.51,15.1,8.89
						c0.73,0.31,1.86,0.62,3.05,0.95c4.03,1.11,9.05,2.49,9.96,6.34c0.71,3.03-1.48,4.69-3.08,5.9c-1.01,0.76-1.96,1.48-2.13,2.34
						c-0.35,1.77,0.51,3.18,1.5,4.8c1.39,2.28,3.12,5.12,0.36,8.79c-1.71,2.28-4.44,3.28-7.08,4.25c-1.74,0.64-3.38,1.24-4.56,2.15
						c-4.24,3.26-5.63,10.35-6.75,16.05c-0.26,1.31-0.5,2.57-0.77,3.71c-2.25,9.76-0.63,20.19,0.93,30.26
						c0.55,3.55,1.12,7.22,1.5,10.77c0.5,4.62,1.15,8.78,1.95,12.63c1.67,2.43,6.88,9.28,14.77,13.12c3.27,1.59,5.32,2.89,6.67,3.74
						c0.97,0.61,1.74,1.1,2.06,1.1c0.01,0,0.02,0,0.03,0c0.43-0.05,1.5-0.86,2.73-1.8c1.37-1.05,2.41-2.48,3.41-3.87
						c1.51-2.09,2.95-4.07,5.34-4.32c1.85-0.19,3.84,0.75,6.24,2.95c5.18,4.74,8.75,13.01,11.62,19.65c1.16,2.67,2.18,5.03,3.08,6.62
						c13.96,0.41,31.14-9.37,37.12-21.33c1.81-3.62,0.63-8.05-0.51-12.34c-0.29-1.09-0.57-2.13-0.79-3.15
						c-0.98-4.41-0.68-7.84,0.89-10.49c2.51-4.22,9.67-7.78,14.52-8.35c4.44-0.51,9.24,2.63,13.86,5.67
						c7.53,4.95,12.4,7.62,16.47,3.08c0.67-0.75,1.25-1.58,1.87-2.46c0.92-1.31,1.87-2.66,3.15-3.79c6.64-5.87,22.41-7.04,29.8-4.96
						c2.88,0.81,5.7,2.62,8.68,4.52c4.75,3.04,9.66,6.19,14.26,4.48c3.11-1.16,2.34-6.75,1.88-10.09c-0.05-0.38-0.1-0.74-0.14-1.06
						c-0.39-3.15-0.68-6.22,0.14-9.26C964.02,2107.41,969.14,2104.41,973.54,2103.1z"/>
				</g>
				<g className="st4" id="yorkshire" onClick={() => handleClick("yorkshire and the humber")}>
                    <title>Yorkshire</title>
					<path d="M1121.11,1429.9c-6.45,0.06-12.97-0.29-19.28-0.63c-5.89-0.32-12.01-0.63-17.97-0.63
						c-2.08,0-4.17-0.18-6.2-0.37c-3.46-0.31-6.72-0.61-9.9-0.05c-2.86,0.5-4.81,1.96-7.07,3.65c-1.14,0.86-2.33,1.74-3.71,2.55
						c-3.34,1.97-7.24,2.48-10.68,2.92c-1.78,0.23-3.56,0.5-5.35,0.76c-12.48,1.86-25.38,3.79-35.58-6.47
						c-1.37-1.37-2.48-2.87-3.55-4.32c-2.41-3.24-4.5-6.05-8.71-6.55c-0.91-0.11-1.88-0.26-2.87-0.42c-3.66-0.58-7.8-1.24-10.78,0.22
						c-2.01,0.98-2.77,3.54-3.57,6.25c-0.62,2.1-1.27,4.28-2.59,5.91c-1.52,1.88-2.92,2.76-4.39,2.79c-0.02,0-0.04,0-0.07,0
						c-1.92,0-3.21-1.48-4.36-2.79c-1.5-1.72-2.23-2.38-3.4-1.94c-0.61,0.23-1.22,1.56-1.71,2.62c-0.48,1.04-0.97,2.11-1.69,2.87
						c-1.99,2.07-4.54,3.6-7.6,4.53c-4.64,1.41-10.54,1.17-16.2-0.67c-1.03-0.34-2.05-0.72-3.04-1.1c-1.64-0.62-3.19-1.22-4.8-1.58
						c-2.46-0.56-5.66,1.06-8.74,2.62c-2.73,1.39-5.35,2.72-7.75,2.72c-0.74,0-1.45-0.13-2.15-0.42c-0.67-0.28-1.26-0.7-1.78-1.25
						c-4.32-0.7-10.46-0.01-14.34,2.79c-3.29,2.37-2.79,5.88-2.21,9.94c0.18,1.23,0.36,2.5,0.42,3.74c0.29,5.64-2.35,7.96-4.67,10.01
						c-1.58,1.39-3.07,2.71-3.85,5.11c-0.59,1.84,0.18,4.29,0.93,6.65c0.56,1.77,1.14,3.6,1.23,5.36c0.31,6.43-1.22,12.48-4.18,16.62
						c-3.57,4.98-9.04,6.85-14.33,8.66c-2.11,0.72-4.1,1.4-5.98,2.27c-6.98,3.23-10.04,10.28-11.92,16.07
						c-2.82,8.67,1.07,15.43,5.69,21.45c2.8,3.64,5.19,4.99,7.76,4.36c0.83-0.2,1.66-0.62,2.53-1.07c1.13-0.57,2.29-1.16,3.66-1.4
						c2.92-0.51,6.22,0.8,8.82,3.53c1.5,1.57,2.73,3.17,3.92,4.72c2.06,2.68,4,5.2,7.08,7.19l1.06,0.68
						c11.32,7.29,23.03,14.82,30.88,25.89c11.84,16.71,1.71,32.37-6.42,44.96c-1.44,2.23-3.07,4.75-3.53,7.47
						c-1.12,6.64,3.44,8.51,10.99,11c4.32,1.43,8.78,2.9,11.56,6.04c2.46,2.78,4.29,5.94,6.07,9c2.2,3.79,4.28,7.37,7.43,10.23
						c4.94,4.48,10.49,7.24,16.37,10.17c1.83,0.91,3.71,1.86,5.59,2.86c2.01-0.56,4.07-0.6,5.99,0.11c2.98,1.1,4.99,3.9,6.76,6.38
						c0.81,1.14,1.58,2.21,2.37,3.04c1.04,1.09,2.1,2.16,3.15,3.24c2.73,2.78,5.54,5.65,8.06,8.76c4.58,5.65,8.2,12.13,11.69,18.39
						c0.68,1.23,1.37,2.45,2.05,3.66c0.73,1.29,1.49,2.73,2.28,4.25c4.7,8.96,10.55,20.11,20.45,18.86c3.38-0.43,6.49-2.09,9.78-3.84
						c3.89-2.07,7.9-4.21,12.55-4.29c8.22-0.14,16.61,3.07,24.05,5.89c1.23,0.46,2.44,0.92,3.63,1.36c5.73,2.1,13.65-2.98,15.89-8.47
						c1.03-2.52,1.04-5.29,1.05-8.23c0.02-3.51,0.03-7.14,1.84-10.55c0.85-1.6,2.51-2.34,4.02-2.84c2.31-0.77,5.28-0.95,8.42-1.14
						c3.82-0.23,7.77-0.46,9.81-1.83c1.73-1.16,1.54-2.47,0.97-5.1c-0.27-1.26-0.57-2.69-0.48-4.15c0.36-5.47,3.34-13.88,10.11-14.59
						c3.78-0.39,5.34,3.32,6.38,5.77c0.16,0.39,0.32,0.76,0.48,1.11c0.47,1.03,0.88,2.09,1.27,3.11c1.37,3.58,2.55,6.66,6.07,7.96
						c5.84,2.15,8.13-1.68,11.38-8.1c1.99-3.94,4.05-8.01,7.66-9.81c4.15-2.07,10.6-2.12,14.39,1.88c1.4,1.48,2.07,3.24,2.73,4.95
						c0.63,1.64,1.22,3.19,2.41,4.3c1.86,1.73,4.58,3.44,7.66,4.83c3.04,1.37,6.15,1.53,8.53,0.46c5.19-2.35,4.61-6.33,3.22-12.62
						c-1.04-4.73-2.12-9.62,1.23-13.26c2.81-3.05,7.21-4.33,11.09-5.46c1.43-0.42,2.79-0.81,4.01-1.27l1.08-0.41
						c4.36-1.68,10.33-3.98,14.92-0.29c4.08,3.28,6.44,8.58,8.52,13.27c0.71,1.59,1.38,3.1,2.09,4.49c0.35,0.68,0.71,1.42,1.08,2.18
						c4.18,8.53,7.94,14.79,14.96,11.36c3.77-1.84,9.92-7.08,9.28-12.1c-4.88-3.68-9.44-7.74-13.61-12.16l-3.24-3.24
						c-9.44-9.46-19.14-19.18-31.51-22.72c-1.18-0.14-2.37-0.21-3.56-0.23l-0.1,0c-6.42-0.26-13.69-0.55-17.15-5.55
						c-1.64-2.46-2.02-5.56-1.01-8.31c2.6-7.47,14.3-10.42,16.61-10.92l-0.01-0.04c1.98-0.41,4.03-0.61,6.03-0.6
						c7.25,0.24,14.31,2.33,20.47,6.04c6.32,3.58,12.45,7.72,18.18,12.27c5.66,4.59,11.78,8.64,18.21,12.03
						c2.94,1.47,15.57,5.78,21.78,5.78c0.36,0.02,0.68,0,0.98-0.05c0.54-0.52,0.88-1.22,0.94-1.98c0.09-2.14-3.38-5.83-5.24-7.82
						c-1.04-1.03-2.01-2.16-2.89-3.36c-1.34-1.95-3.54-4.69-5.88-7.59l-0.55-0.68c-3.11-3.85-5.18-6.52-6.54-8.37
						c-6.66-9.51-12.55-17.73-19.76-27.06c-10.16-13.1-16.41-22.39-17.6-40.88c-0.27-4.51-0.82-9.56-1.35-14.44l-0.13-1.21
						c-0.6-5.63-1.21-11.46-1.46-16.79c-1.19-23.99-6.15-42.07-15.6-56.9c-5.73-8.93-14-15.43-22.75-22.31c-2.32-1.82-4.7-3.7-7-5.59
						c-5.81-5.11-11.01-10.83-15.48-17.06c-3.78-5.3-8.13-10.2-12.93-14.58c-8.63-7.26-20.28-12.42-30.82-16.95
						C1135.48,1424.12,1132.96,1429.79,1121.11,1429.9z"/>
				</g>
				<g className="st4" id="northEastEngland" onClick={() => handleClick("north east england")}>
                    <title>North East England</title>
					<path d="M932.16,1053.65c-2.24,2.18-3.06,5.26-3.92,8.52c-0.69,2.6-1.4,5.28-2.89,7.64
						c-2.24,3.55-5.56,5.84-8.77,8.06c-2.67,1.84-5.19,3.58-7.09,5.97c-0.98,1.23-1.93,2.51-2.95,3.86
						c-3.17,4.24-6.45,8.62-10.68,11.94c-1.57,1.23-3.41,2.08-5.2,2.9c-4.52,2.09-6.62,3.27-4.98,7.42c1.97,5,5.07,9.22,8.34,13.69
						c1.35,1.85,2.75,3.76,4.05,5.71c1.03,1.55,2.35,3.13,3.76,4.82c3.74,4.48,7.97,9.56,7.77,15.6c-0.07,2.08-0.75,4.27-2.02,6.49
						c-3.54,6.2-9.01,8.21-14.31,10.15c-2.16,0.79-4.39,1.61-6.52,2.72c-3.86,2-4.92,5.32-6.16,9.17c-0.5,1.58-1.02,3.21-1.77,4.82
						c-1.99,4.32-4.59,3.74-6.89,3.23c-0.56-0.12-1.14-0.25-1.77-0.34c-6.01-0.83-11.09,0.9-16.88,3.76
						c-10.67,5.27-15.75,13.95-21.11,23.14c-1.98,3.38-4.02,6.88-6.4,10.31c-3.24,4.68-4.43,7.2-2.21,11.75
						c4.24,8.68,9.57,16.02,15.02,20.67c1.59,1.35,3.36,1.75,5.42,2.21c1.51,0.34,3.07,0.68,4.63,1.45c3.56,1.75,7.59,6.08,8.97,9.64
						c0.92,2.37,0.58,4.95-0.88,6.57c-1.7,1.89-4.17,2.04-6.36,2.17c-0.51,0.03-1.01,0.06-1.5,0.12c-4.11,0.46-7.93,3.33-9.74,7.32
						c-1.41,3.11-1.73,6.96-0.83,10.05c0.33,1.14,0.84,2.19,1.39,3.31c1.15,2.36,2.45,5.04,1.61,8.45c-0.23,0.94-0.5,1.96-0.78,3.05
						c-2.05,7.88-5.15,19.78-1.28,26.4c2.28,3.91,6.86,4.88,10.38,4.5c2.88-0.32,5.86-1.97,7.41-4.1c0.3-0.41,0.59-0.84,0.87-1.27
						c0.77-1.16,1.56-2.35,2.69-3.35c4.31-3.8,9.24-0.4,12.83,2.09c0.96,0.66,1.86,1.28,2.64,1.71c3.21,1.74,9.88,5.35,8.57,11.36
						c-0.7,3.21-2.25,6.12-3.75,8.93c-1.62,3.03-3.14,5.89-3.09,8.39c0.05,2.52-1.42,4.95-2.85,7.31c-1.11,1.83-2.36,3.9-2.03,4.98
						c0.13,0.42,0.57,0.82,1.32,1.17c0.69,0.32,1.85,0.56,3.07,0.81c2.99,0.62,6.37,1.32,7.65,4c1.27,2.66,0.21,5.18-0.73,7.41
						c-1.02,2.42-1.9,4.5-0.45,6.93c2.5,4.19,6.1,7.83,9.58,11.35l1.2,1.21c3.54,3.57,7.2,7.27,9.64,11.83
						c1.37,2.58,1.52,5.79,1.66,8.89c0.1,2.29,0.2,4.45,0.76,6.22c0.49,1.55,1.16,2.51,1.97,2.85c1.66,0.7,4.56-0.78,7.38-2.21
						c3.56-1.81,7.23-3.68,10.76-2.88c1.82,0.41,3.54,1.07,5.2,1.7c0.95,0.36,1.93,0.74,2.89,1.05c5.09,1.65,10.33,1.89,14.4,0.65
						c2.56-0.78,4.68-2.04,6.31-3.73c0.37-0.39,0.76-1.23,1.13-2.04c0.75-1.65,1.61-3.51,3.37-4.18c3.24-1.23,5.33,1.18,6.72,2.77
						c0.72,0.83,1.54,1.77,2.1,1.77c0,0,0.01,0,0.01,0c0.23,0,0.9-0.18,2.12-1.68c0.95-1.17,1.48-2.97,2.04-4.87
						c0.91-3.06,1.93-6.53,5.13-8.09c3.84-1.88,8.48-1.14,12.57-0.49c0.96,0.15,1.88,0.3,2.76,0.41c5.5,0.66,8.18,4.26,10.76,7.74
						c1.01,1.36,2.06,2.77,3.27,3.99c9.12,9.18,20.72,7.45,33.01,5.61c1.81-0.27,3.61-0.54,5.41-0.77c3.31-0.43,6.73-0.87,9.54-2.53
						c1.24-0.73,2.35-1.56,3.43-2.37c2.35-1.76,4.78-3.57,8.34-4.2c3.57-0.63,7.19-0.3,10.69,0.01c2.03,0.18,3.95,0.36,5.86,0.36
						c0.13,0,0.26,0,0.4,0c5.96,0,11.98,0.32,17.8,0.64c6.26,0.34,12.72,0.68,19.09,0.63c9.9-0.09,11.68-4.07,14.27-12.74l-0.74-0.32
						c-1.71-0.73-3.47-1.48-5.08-2.23c-11.52-5.22-23.75-8.69-36.32-10.31l-1.69-0.24c-4.91-0.6-13.18-1.61-15.02-12.09l-0.03-0.54
						c-1.42-8.1-2.56-14.47-6.76-20.91c-2.37-3.57-5.17-7.19-7.07-9.6c-2.5-3.17-5.14-6.56-7.49-10.15
						c-7.35-11.13-10.19-24.73-12-36.56c-1.94-12.93-5.38-25.64-10.25-37.77c-1.32-3.24-2.73-6.49-3.95-9.28
						c-3.85-7.98-7.05-16.41-9.49-25l-0.03-0.11c-1.21-5.39-2.05-10.91-2.51-16.42l-0.05-0.47c-0.43-4.09-0.83-7.96-1.6-11.77
						c-1.12-4.81-2.7-9.6-4.67-14.19c-2.8-6.34-4.79-13.05-5.89-19.93l-0.02-0.14c-0.58-6.54-0.7-13.19-0.35-19.74
						c0.27-5.15,0.27-10.37,0-15.52c-0.85-8.9-3.22-17.61-7.04-25.8c-3.5-7.51-10.85-11.58-17.96-15.52
						c-6.98-3.91-14.17-7.94-19.63-14.91c-7.33-9.28-9.4-20.57-10.44-30.39l-0.01-0.32c-0.28-2.58-0.56-5.17-0.92-7.73
						c-1.65,0.69-3.38,1.29-5.07,1.86C939.23,1049.41,935.06,1050.83,932.16,1053.65z"/>
				</g> 
				<g className="st4" id="eastMidlands" onClick={() => handleClick("east midlands")}>
                    <title>East Midlands</title>
					<path d="M1263.14,1721.45c-10.27,5.01-15.51-5.67-18.97-12.74c-0.37-0.75-0.72-1.47-1.06-2.13
						c-0.75-1.46-1.43-3-2.16-4.64c-2.04-4.58-4.14-9.32-7.65-12.14c-3.24-2.6-7.88-0.82-11.97,0.75l-1.1,0.42
						c-1.33,0.5-2.74,0.91-4.23,1.34c-3.67,1.07-7.47,2.18-9.72,4.62c-2.29,2.49-1.42,6.42-0.51,10.59c1.23,5.58,2.76,12.53-4.91,16
						c-3.17,1.43-7.17,1.27-11-0.45c-2.39-1.07-5.79-2.88-8.47-5.37c-1.7-1.58-2.48-3.62-3.17-5.42c-0.57-1.5-1.11-2.91-2.1-3.96
						c-2.69-2.84-7.64-2.87-10.87-1.26c-2.72,1.36-4.55,4.98-6.32,8.48c-2.87,5.68-6.45,12.75-15.09,9.56
						c-4.8-1.77-6.41-5.98-7.84-9.7c-0.37-0.98-0.76-1.99-1.19-2.93c-0.17-0.38-0.34-0.78-0.52-1.19c-1.05-2.49-1.82-4.1-3.3-3.96
						c-4.63,0.49-7.13,7.19-7.43,11.8c-0.07,1.04,0.17,2.15,0.42,3.32c0.56,2.62,1.26,5.88-2.24,8.22c-2.72,1.82-7.08,2.08-11.3,2.33
						c-2.81,0.17-5.71,0.34-7.65,0.99c-0.95,0.32-1.97,0.73-2.32,1.4c-1.46,2.75-1.47,5.86-1.49,9.16c-0.01,3.09-0.03,6.29-1.27,9.35
						c-3.41,8.38-13.57,12.4-19.7,10.15c-1.2-0.44-2.42-0.9-3.66-1.37c-7.54-2.86-15.35-5.81-22.94-5.69
						c-3.93,0.06-7.45,1.94-11.19,3.94c-3.38,1.8-6.88,3.67-10.81,4.17c-0.66,0.08-1.31,0.13-1.95,0.13
						c-10.76,0-16.95-11.81-21.54-20.57c-0.79-1.5-1.53-2.92-2.24-4.16c-0.69-1.22-1.38-2.45-2.06-3.68
						c-3.44-6.15-6.99-12.51-11.41-17.96c-2.43-3-5.2-5.82-7.87-8.54c-1.07-1.08-2.13-2.17-3.18-3.27c-0.94-0.98-1.8-2.19-2.64-3.36
						c-1.59-2.22-3.24-4.53-5.36-5.31c-3.76-1.39-8.62,1.59-11.54,4.95c-6.49,7.46-10.9,20.03-11.8,33.61
						c-0.58,8.8-2.35,35.59,8.61,47.29c2.79,2.98,6.03,5,9.46,7.15c4.46,2.79,9.07,5.68,12.54,10.78c3.42,5.02,5.49,10.7,7.5,16.19
						c2.09,5.73,4.07,11.15,7.46,15.96c0.51,0.72,1.06,1.41,1.59,2.08c2.59,3.25,5.52,6.93,1,12.03c-1.89,2.13-4.46,3.17-6.96,4.18
						c-1.73,0.7-3.36,1.36-4.67,2.33c-4.34,3.24-6.89,9.25-6.82,16.09c0.07,6.74,2.77,12.83,7.06,15.9
						c4.79,3.43,10.85,4.79,16.15,5.66c1.28,0.21,2.57,0.4,3.85,0.6c4.64,0.7,9.43,1.42,13.98,3.04c1.93,0.69,4.36,1.75,5.99,3.8
						c1.54,1.94,2.06,3.97,1.55,6.03c-1.37,5.58-9.95,9.38-14.56,11.43l-0.8,0.36c-5.71,2.57-9.55,5.74-9.78,8.08
						c-0.04,0.4-0.16,1.64,2.14,3.28c2.24,1.6,5.11,2.03,8.15,2.49c3.55,0.54,7.23,1.09,10.2,3.6c4.75,4,6.68,10.2,5.17,16.59
						c-0.51,2.14-1.8,3.92-3.04,5.64c-1.14,1.56-2.21,3.04-2.48,4.53c-0.36,2,0.4,2.49,3.11,3.88c0.72,0.37,1.54,0.79,2.37,1.29
						c8.65,5.26,16.41,10.07,23.82,15.64c1.31,0.98,2.64,1.97,3.99,2.96c9.63,7.12,20.55,15.19,26.37,26.25
						c3.75,7.14,6.01,15.89,6.36,24.63c0.38,9.61-1.57,18.14-5.64,24.67c-0.48,0.77-0.97,1.54-1.47,2.3
						c-2.17,3.36-4.21,6.53-5.22,10.13c-0.94,3.36-3.59,6.07-6.16,8.69c-2.55,2.61-4.96,5.08-4.72,7.38
						c0.18,1.64,1.73,3.44,4.61,5.34l0.46,0.3c2.9,1.9,8.31,5.43,8.39,9.42c0.02,1.14-0.39,2.8-2.48,4.28
						c-7.59,5.37-5.79,13.87-2.71,22.26c2.65,7.23,6.99,16.64,13.98,14.92c3.57-0.88,6.32-4.97,8.75-8.57
						c0.63-0.94,1.23-1.82,1.82-2.63l0.61-0.83c3.6-4.9,7.33-9.96,12.42-13.71c2.81-2.07,5.47-3.29,8.13-3.75
						c4.89-0.82,8.01,1.68,11.02,4.11c0.82,0.66,1.67,1.34,2.54,1.94c0.92,0.63,1.77,0.81,2.68,0.55c2.26-0.63,4.95-4.01,7.21-9.03
						c0.61-1.35,1.17-2.77,1.71-4.15c1.13-2.86,2.3-5.81,4-8.51c4.45-7.05,11.49-13.06,18.3-18.86c2.63-2.24,5.13-4.39,7.45-6.54
						c-1.43-4.61-2.52-9.39-0.78-11.93c0.68-0.99,1.72-1.55,3-1.61c2.23-0.08,3.89,1.71,5.51,3.48c1.04,1.13,2.32,2.53,3.14,2.53
						c0.29,0,0.63-0.22,1.02-0.64c1.3-1.41,1.78-4.12,2.24-6.74c0.31-1.76,0.61-3.43,1.16-4.84c1.34-3.43,3.42-5.76,6.18-6.92
						c1.02-0.43,2.21-0.46,3.36-0.48c1.61-0.04,2.7-0.1,3.15-0.73c0.39-0.54-0.04-1.97-0.38-3.11c-0.35-1.15-0.7-2.34-0.6-3.45
						c0.31-3.47,2-6.58,5.01-9.22c2.74-2.4,5-5.12,7.39-7.99c0.58-0.69,1.16-1.39,1.75-2.08c4.6-5.42,8.86-11.31,8.86-17.58
						c0-8.56-8.49-15.34-15.32-20.78c-0.94-0.75-1.84-1.47-2.69-2.17c-6.92-5.7-8.45-11.44-8.86-20.05
						c-0.06-1.33,0.42-2.59,1.38-3.63c3.85-4.14,15.07-4.78,25.29-5.06c1.61-0.04,2.89-0.08,3.59-0.14c9.9-0.91,20.1-1.21,30.32-0.89
						c8.18,0.25,16.74-0.33,25.44-1.75c1.46-0.24,4.06-0.93,5.05-2.88c0.65-1.27,0.51-2.89,0.37-4.61c-0.13-1.65-0.27-3.36,0.21-5.07
						c1.71-6.09,8.96-7.49,15.98-8.85c4.32-0.83,8.78-1.7,11.06-3.63c3.37-2.85,5.48-8.23,6.44-16.42l-1.08-0.71
						c-3.76-2.46-7.66-5.01-11.35-7.86c-10.02-7.76-15.57-15.91-16.5-24.23c-0.63-6.76,1.61-13.5,6.15-18.51
						c4.58-5.1,9.8-9.62,15.5-13.44c5.76-4.2,11.26-8.21,14.85-13.66c10.1-15.3,5.42-38.06,2.94-47.13
						c-2.17-6.73-5.26-13.23-9.17-19.23l-0.06-0.09l-0.93-1.61c-1.9-3.27-3.86-6.65-5.62-10.25l-0.89-1.88
						c-0.71-1.49-1.41-2.97-2.1-4.5c-3.11-7.3-6.91-14.32-11.29-20.89c-3.52-4.92-7.73-6.64-13.55-9.02
						c-3.76-1.43-7.4-3.17-10.85-5.19c-1.05-0.69-2.09-1.39-3.11-2.11C1273.21,1714.22,1267.77,1719.19,1263.14,1721.45z"/>
				</g>
				<g className="st4" id="southWales" onClick={() => handleClick("south wales")}>
                    <title>South Wales</title>
				<path d="M827.3,2185.55c-2.47,0.53-5.86,1.26-7.84-1.83c-1.13-1.76-2.32-4.52-3.7-7.71
					c-2.76-6.38-6.19-14.32-10.9-18.63c-1.65-1.51-3.01-2.27-3.91-2.17c-1.06,0.11-2.06,1.49-3.22,3.09
					c-1.08,1.49-2.3,3.18-4.02,4.49c-3.86,2.94-4.57,3.18-8.24,0.85c-1.38-0.87-3.26-2.06-6.38-3.58c-5-2.43-8.94-5.89-11.74-8.9
					c0.63,2.13,1.32,4.17,2.09,6.12c4.06,10.25,2.88,17.83-3.61,23.17c-6.39,5.26-15.48,9.58-22.64,10.75
					c-1.76,0.29-3.63,0.15-5.43,0.03c-3.14-0.22-6.09-0.44-8.06,1.57c-2.35,2.4-5.75,5.03-11.18,3.96
					c-3.17-0.62-11.73-3.48-14.11-6.88c-0.56-0.8-0.76-1.67-0.94-2.43c-0.11-0.48-0.22-0.93-0.39-1.26c-0.1-0.19-0.16-0.2-0.25-0.21
					c-0.74-0.11-3.18,0.37-10.32,7.35l-0.33,0.32c-1.36,1.31-2.48,2.87-3.67,4.52c-1.48,2.05-3,4.17-5.04,5.83
					c-2.02,1.64-4.83,2.39-7.92,2.1c-4.42-0.41-7.97-2.45-11.41-4.42c-0.73-0.42-1.46-0.84-2.2-1.24c-1.79-0.98-3.4-1.79-4.93-1.62
					c-1.63,0.17-2.04,1.13-2.72,3.11c-0.75,2.17-1.86,5.4-6.59,5.4c-0.29,0-0.6-0.01-0.92-0.04c-4.53-0.36-12.15-3.97-13.77-8.78
					c-2.29-6.82,4.06-12.05,8.27-15.52l0.23-0.19c7.37-6.08,10.87-12.4,12.49-22.56c1.52-9.53,3.12-17.02,6.4-24.87
					c0.7-1.67,1.42-3.39,1.62-5.11c0.73-6.23-4.68-12.7-9.02-17.9c-0.81-0.97-1.59-1.9-2.29-2.79c-4.35-5.48-8.41-6.35-14.87-6.07
					c-3.34,0.14-6.9-0.11-10.34-0.35c-6.4-0.44-13.01-0.91-18.54,1.06c-8.02,2.84-14.56,7.9-21.49,13.26l-2.83,2.19
					c-8.12,6.31-21.71,16.88-32.35,13.45c-2.09-0.67-3.52-2.08-4.78-3.33c-1.4-1.38-2.6-2.57-4.51-2.74
					c-3.5-0.31-6.96,0.13-10.62,0.6c-4.38,0.56-8.92,1.14-13.54,0.31c-2.86-0.52-5.53-1.54-8.12-2.52c-2.1-0.8-4.09-1.56-6.17-2.07
					c-0.9-0.22-2.16-0.28-3.5-0.35c-3.42-0.17-7.68-0.38-9.44-3.62c-1.57-2.88-0.89-6.98-0.34-10.27c0.01-0.05,0.02-0.1,0.03-0.16
					c-0.66,0.26-1.32,0.54-1.97,0.83c-3.01,1.69-4.96,4.45-7.42,7.93c-2.21,3.37-4.85,6.41-7.84,9.07
					c-8.46,6.99-18.81,10.12-25.87,11.88c-1.38,0.33-2.77,0.49-4.17,0.49c-0.02,0-0.04,0-0.05,0c-3.65-0.16-7.17-0.81-10.56-1.92
					c-2.25-0.74-4.59-1.2-6.96-1.38c-0.76-0.06-1.46,0.24-1.9,0.8c-0.88,1.35-1.5,2.83-1.84,4.41c-0.64,2.76-1.69,5.36-3.14,7.76
					l-0.1,0.15c-2.91,4-6.82,7.2-11.32,9.26c-1.85,0.98-3.85,1.86-5.46,2.57c-4.77,2.11-9.28,4.11-11.45,7.72l-0.07,0.12
					c-0.19,0.28-0.36,0.57-0.51,0.88c1.01,0.18,2.72,0.37,5.55,0.37h5.08c4.92-0.25,9.9,0.67,14.4,2.66
					c3.98,1.76,6.97,5.33,8.01,9.54c2,8.65-4.14,17.61-10.06,25.34l-0.5,0.65c-0.6,0.79-0.89,1.36-1.04,1.71
					c0.45,0.46,1.6,1.37,4.52,3.1c6.6,3.92,8.46,9.32,9.96,13.66l0.26,0.69c0.42,1.13,0.86,2.31,1.38,3.45
					c1.76,4.01,4.93,7.16,8.92,8.89c3.02,1.42,6.18,2.16,9.45,2.23c2.86,0.16,5.63-0.96,7.5-3.04c0.64-0.93,1.18-1.93,1.59-2.99
					c1.63-3.66,4.03-9.05,10.81-10.4c1.29-0.24,2.59-0.36,3.86-0.34c1.81,0.04,3.6,0.16,5.36,0.38l0.17,0.02
					c1.35,0.14,2.62,0.27,3.67,0.27c0.8,0,1.29-0.04,1.45-0.11c-0.02,0,0.16-0.28,0.35-1.37c0.09-0.99,0.09-2-0.01-2.99
					c-0.2-2.4-0.04-4.79,0.49-7.12c1.43-5.89,6.86-9.91,12.85-9.64l0.25,0c3.82,0.09,7.62,0.49,11.32,1.18
					c3.07,0.59,6.2,0.93,9.31,1.03l0.3,0l0.08,0.01c2.26,0.17,4.51-0.3,6.51-1.36c0.06-0.05,0.07-0.07,0.07-0.07
					c0.13-0.2,0.31-1.31,0.38-1.75c0.29-2.69,1.25-5.3,2.77-7.54c1.86-2.57,6.87-8.44,13.93-8.44c1.06,0.01,2.11,0.15,3.12,0.41
					c10.74,2.85,7.83,14.35,6.88,18.13l-0.24,0.9c-1.39,5.58-0.71,8.22,2.81,10.79c3.06,1.93,6.74,2.84,10.35,2.58l0.15-0.01
					c2.37,0,4.86-0.2,7.01-0.4l1.44-0.21c1.46-0.27,3.02-0.42,4.57-0.45c2.3-0.1,4.68,0.58,6.62,1.92c3.43,2.1,5.24,6.15,4.5,10.1
					c-1.59,7.49-11.45,10.77-13.79,11.29l0,0.02c-1.02,0.24-2.18,0.47-3.46,0.67l-0.43,0.08c-2.13,0.38-5.71,1.02-6.18,2.1
					c-0.87,1.97-0.95,4.18-0.21,6.2c0.47,1.32,1.46,2.41,2.72,3c2.5,1.03,5.22,1.53,8,1.44c8.74-0.3,17.39-2.72,25.01-7.03
					c1.09-0.67,2.33-1.47,3.62-2.29l0.61-0.39c5.89-3.79,13.21-8.51,21.72-8.51c1.48,0,3,0.14,4.47,0.45
					c12.61,2.62,20.04,14.27,25.46,22.78l1.55,2.45c7.52,11.81,15.3,24.03,27.04,30.39c6.73,3.64,15.22,4.23,23.44,4.8l3.19,0.24
					c2.82,0.32,5.21,0.32,7.52,0.32c10.59,0,17.72-2.3,22.44-7.25c1.85-2.21,3.35-4.67,4.46-7.33c1.55-3.59,3.58-6.93,6.05-9.94
					c4.91-5.82,10.45-11.1,16.48-15.71c3.98-2.95,8.88-4.5,13.81-4.43c2.68,0.06,5.38,0.31,8.02,0.74l0.93,0.12
					c1.35,0.19,2.71,0.28,4.07,0.29c5.69-0.05,11.33-1.54,16.31-4.33c10.18-5.54,18.46-14.72,26.46-23.59l0.15-0.17
					c0.04-0.05,0.08-0.09,0.12-0.13c0.16-7.42,0.35-31.61-1.42-39.4C832.19,2184.65,830.76,2184.81,827.3,2185.55z"/>
				</g>
				<g className="st4" id="eastEngland" onClick={() => handleClick("east england")}>
                    <title>East England</title>
				<path d="M1569.45,1969.81c-5.48-25.26-18.36-47.92-37.26-65.54c-24.33-22.54-66.14-35.47-114.7-35.47
					c-5.41,0-11.06,0.18-16.8,0.54c-9.62,0.62-23.16,2.33-29.69,9.88c-3.5,4-4.73,9.69-6.03,15.71c-1.46,7.14-3.12,15.21-9.06,21.96
					c-2.73,3.6-6.88,5.79-11.39,6.03l-0.1,0c-5.41,0-9.4-3.42-12.93-6.43l-0.08-0.07c-1.2-1.08-2.49-2.1-3.81-3.03
					c-1.17-0.78-2.4-1.6-3.71-2.46c-0.8,5.87-2.56,12.88-7.3,16.89c-2.88,2.44-7.52,3.34-12.43,4.29
					c-6.12,1.19-12.46,2.41-13.67,6.72c-0.33,1.19-0.22,2.56-0.11,4.01c0.16,1.96,0.34,4.18-0.69,6.21
					c-1.18,2.33-3.75,3.92-7.25,4.49c-8.89,1.45-17.65,2.05-26.02,1.79c-10.1-0.32-20.18-0.02-29.95,0.88
					c-0.8,0.07-2.12,0.11-3.78,0.16c-5.94,0.16-19.86,0.54-23.17,4.1c-0.42,0.46-0.61,0.92-0.58,1.45
					c0.37,7.92,1.68,12.86,7.77,17.87c0.84,0.69,1.73,1.4,2.65,2.14c7.33,5.85,16.45,13.13,16.44,23.13c0,7.24-4.6,13.66-9.57,19.52
					c-0.58,0.69-1.16,1.38-1.73,2.06c-2.36,2.84-4.8,5.77-7.71,8.33c-2.42,2.12-3.76,4.56-4,7.24c-0.05,0.54,0.22,1.44,0.49,2.32
					c0.53,1.78,1.2,3.99-0.06,5.73c-1.36,1.88-3.67,1.93-5.52,1.97c-0.86,0.02-1.76,0.04-2.25,0.25c-1.99,0.84-3.52,2.61-4.55,5.25
					c-0.44,1.14-0.71,2.66-1,4.27c-0.54,3.04-1.1,6.18-2.99,8.24c-0.98,1.07-2.07,1.61-3.23,1.61c-2.14,0-3.77-1.78-5.35-3.5
					c-1.05-1.14-2.33-2.59-3.15-2.51c-0.45,0.02-0.59,0.17-0.68,0.32c-1.21,1.75,0.71,7.93,1.55,10.52l0.39,0.4
					c-0.06,0.06-0.13,0.12-0.2,0.19c0.17,0.54,0.32,0.98,0.41,1.29c2.22,7.89,2.96,16.33,2.2,25.1c-0.82,9.4-2.54,18.8-4.21,27.89
					c-0.51,2.79-1.02,5.58-1.51,8.38c-0.43,2.47-0.71,5.04-0.98,7.52c-0.32,2.93-0.64,5.95-1.23,8.92
					c-0.38,1.92-1.38,3.66-2.35,5.34c-1.09,1.9-2.13,3.69-2.12,5.47c0.01,3.3,2.36,4.02,6.52,5.03c1.04,0.25,2.12,0.51,3.13,0.86
					c4.39,1.49,8.17,4.48,10.63,8.42c2.53,4.04,2.85,7.97,0.88,10.77c-2.16,3.08-6.56,4.25-11.77,3.13
					c-3.83-0.82-7.26-2.5-10.57-4.12c-2.84-1.39-5.52-2.7-8.41-3.5l-0.47-0.13c-0.84-0.24-1.81-0.5-2.06-0.45
					c-0.07,0.09-0.34,0.59-0.83,2.29c-1.69,5.84,2.91,11.04,4.96,13.01c3.43,3.28,7.49,5.81,11.42,8.25
					c4.55,2.83,9.26,5.76,13.17,9.93c5.49,5.86,9.21,11.34,11.36,16.75c1.69,4.25,2.25,7.7,1.75,10.86
					c-0.14,0.93-0.46,1.81-0.75,2.66c-0.17,0.48-0.29,0.87-0.4,1.23c-0.04,0.57-0.09,1.15-0.13,1.73c0.16,0.42,0.52,0.73,1.16,1.05
					c1.06,0.53,2.42,0.6,3.72,0.62c10.98,0.18,17.71-3.51,26.93-10.87c7.52-6,17.78-9.78,31.38-11.56
					c10.29-1.35,17.41,2.24,23.8,12.01c2.44,3.74,6.27,7.78,10.67,5.76c1.77-0.81,3.14-1.74,4.47-2.64
					c2.85-1.93,5.55-3.75,10.79-3.42c5.61,0.35,13.36,3.44,17.11,9.38c2.23,3.53,2.58,7.48,1.01,11.42
					c-0.99,2.47-3.26,4.74-5.67,7.14c-3.5,3.49-7.13,7.1-5.95,10.78c2.04,6.35,12.43,10.83,18.98,11.25
					c7.75,0.49,15.08-2.71,22.17-5.81c2.43-1.07,4.95-2.17,7.45-3.1c2.8-1.05,5.67-1.95,8.57-2.77c-7.53-1.1-9.54-4.93-9.79-8.21
					c-0.6-8.29,11.56-13.68,18.2-14.73c2.37-0.35,4.74-0.53,7.13-0.5c3.92,0.06,7.87,0.33,11.74,0.79l0.58,0.06
					c3.42,0.34,6.65,0.66,9.77,0.66l0.11,0c4.71,0.25,9.27-0.97,13.23-3.51c4.84-3.37,9.13-10.22,12.31-15.82
					c5.67-10,8.36-19.93,6.54-24.16c-0.63-1.39-1.93-2.36-3.47-2.57c-0.09-0.01-0.21-0.01-0.33,0l-0.24,0.01
					c-1.9,0-5.18,2.05-8.08,3.87c-4.26,2.67-9.12,5.67-14.47,5.68c-3.79,0-7.45-1.58-10.04-4.35c-1.46-1.49-2.1-3.65-1.69-5.73
					c0.81-3.94,1.66-4.43,22.96-13.01l4.14-1.7l1.75-0.79c8.56-3.97,17.43-8.09,28.19-8.09h3.37c16.39,0,20.15-8.69,26.38-23.1
					l0.6-1.36c1.7-3.89,3.46-7.91,5.69-12.05c6.82-12.68,17.57-24.29,33.84-36.53c12.5-9.4,16.01-21.2,20.46-36.14
					c0.68-2.27,1.33-4.44,2.03-6.61c2.36-7.65,5.35-15.42,7.99-22.28c4.25-10.38,7.79-21.12,10.54-31.96
					C1574.1,2006.6,1573.61,1988.4,1569.45,1969.81z"/>
				</g>
				<g className="st4" id="southWest" onClick={() => handleClick("south west")}>
                    <title>South West</title>
					<path d="M957.46,2439.81c-0.34-0.57-0.7-1.15-1.06-1.75c-3-4.93-6.74-11.06-7.06-16.95
						c-0.18-3.34,0.68-5.56,2.55-6.59c3.79-2.08,9.96,1.68,16.05,6.06l0.27,0.19c8.28,5.93,18.79,8.43,28.13,6.67
						c4.06-0.76,7.41-2.6,9.44-5.18c4.97-6.32-0.43-15.09-4.38-21.49l-0.27-0.43c-5.12-8.31-7.62-18.16-10.03-27.69
						c-0.58-2.31-1.17-4.6-1.78-6.84c-1.8-6.59,0.46-11.52,6.93-15.06c0.91-0.5,1.83-0.98,2.73-1.46c4.68-2.47,9.1-4.81,12.37-8.99
						c11.44-14.66,11.12-40.03-0.71-56.56c-2.1-2.93-4.55-5.73-6.92-8.43c-3.37-3.84-6.86-7.81-9.52-12.37
						c-5.15-8.79-3.63-14.84-1.53-23.22l0.35-1.39c0.55-2.22,0.43-4.61,0.23-7.27c-0.68-8.92-1.76-18.01-2.71-26.03
						c-0.14-1.18-0.3-2.34-0.46-3.49c-0.63-4.52-1.23-8.79-0.47-13.48c0.9-5.56,2.43-11.1,3.91-16.46l0.25-0.91
						c0.61-2.22,1.27-4.42,1.91-6.53c2.79-9.25,5.42-17.99,3.72-28.06c-1.62-9.6-7.04-23.1-13.91-29.22
						c-2.14-1.91-6.49-2.26-11.08-0.9c-4.55,1.35-7.96,3.98-8.69,6.69c-0.68,2.52-0.42,5.27-0.06,8.11c0.04,0.31,0.09,0.66,0.14,1.02
						c0.54,3.96,1.56,11.32-3.8,13.32c-6.01,2.24-11.81-1.49-16.93-4.77c-2.79-1.79-5.42-3.47-7.87-4.16
						c-6.63-1.87-21.36-0.67-27,4.32c-1.02,0.9-1.83,2.05-2.69,3.27c-0.64,0.91-1.31,1.86-2.09,2.73c-6.09,6.8-13.67,1.83-20.35-2.57
						c-4.18-2.75-8.5-5.58-11.87-5.19c-4.06,0.47-10.31,3.59-12.29,6.9c-1.17,1.96-1.34,4.68-0.54,8.31
						c0.22,0.97,0.49,1.99,0.76,3.03c1.22,4.6,2.61,9.82,0.29,14.45c-4.93,9.86-16.38,18.17-28.23,21.48
						c1.19,0.75,2.19,2.14,2.75,4.59c1.68,7.39,1.65,27.3,1.54,36.66c2.13-2.35,4.07-4.42,5.93-6.31c2.67-2.76,9.11-6.87,14.4-6.87
						c2.86-0.13,5.63,1.32,7.23,3.76c2.51,4.01,0.87,8.47-0.59,11.47c-2.09,3.92-4.63,7.48-7.6,10.67c-1.14,1.31-2.23,2.65-3.37,4.17
						l-2.58,3.57c-8.2,10.98-16.68,22.32-27.46,31.79l-0.9,0.79c-1.87,1.65-3.64,3.21-5.53,4.76c-9.41,8.03-17.56,14.99-23.37,26.44
						c-5.29,10.39-9.87,21.26-13.61,32.31c-4.14,12.17-7.51,19.62-23.72,19.62h-4.8c-5.87,0.14-11.84,0.72-17.69,1.72
						c-6.38,1.08-12.89,1.7-19.36,1.84c-4.16,0.05-8.4-0.39-12.49-1.23c-5.55-1.22-11.06-2.76-16.7-4.36
						c-10.96-3.11-21.31-6.04-32.2-6.04h-0.78c-13.11,0.17-26.44,1.66-39.33,3.1l-9.86,1.06c-30.11,3.17-34.63,13.67-35.42,21.3
						c-0.05,0.55-0.1,1.1-0.15,1.65l-0.15,1.6c-0.83,9.37-1.86,21.06-10.24,28.67c-4.46,3.96-10.2,6-16.12,5.75
						c-3.41-0.09-6.87-0.44-10.25-1.05c-2.64-0.48-5.33-0.77-7.99-0.86l-0.11-0.01c-2.22-0.19-4.42,0.59-6.02,2.14
						c-4.28,4.39-3.44,15.39-2.76,24.23l0.13,1.63c0.18,2.26,0.36,4.39,0.36,6.36c0.29,18.69-9.07,28.79-18.13,38.55
						c-3.65,4.12-7.06,8.58-10.1,13.22c-5.16,7.46-10.47,15.13-18.33,21.17c-3.94,3.03-8.15,5.8-11.85,8.25
						c-6.33,3.87-12.24,8.35-17.62,13.35c-5.69,5.67-8.66,13.22-11.8,21.2c-1.99,5.55-4.5,11.03-7.42,16.22
						c-7.37,12.35-19.98,24.73-37.5,36.84c-9.6,6.56-20.04,9.51-29.25,12.12c-7.1,1.74-13.97,4.22-20.48,7.38
						c-4.66,2.44-12.08,10.58-12.65,16.75c-0.22,1.6,0.41,3.09,1.64,3.99l0.15,0.12c0.72,0.63,1.64,1,2.59,1.04
						c2.9,0,7.54-4.35,10.94-7.52c2.58-2.67,5.58-5.08,8.83-7.08c3.73-2.09,7.88-3.21,12.08-3.28c15.12,0,25.02,14.64,32.99,26.4
						c2.08,3.24,4.35,6.35,6.76,9.3c1.41,1.84,3.49,2.92,5.75,3c5.17-0.03,11.43-5.87,13.97-13.06c0.99-2.86-0.07-5.85-1.4-9.63
						l-0.04-0.13c-2.07-5.89-4.87-13.88,1.4-22.38c8.02-10.75,21.65-15.95,32.6-20.13l1.24-0.48c2.2-0.85,4.28-1.66,6.29-2.5
						c5.48-2.34,8.22-6.95,11.39-12.28l0.19-0.33c3.35-5.63,7.15-12.01,14.59-16.09c4.3-2.19,9.05-3.22,13.81-3.06
						c3.64,0.07,7.36,0.37,11.01,0.88c2.78,0.4,5.61,0.64,8.42,0.72l0.64,0c4.86-0.14,9.74-0.71,14.91-1.3l0.57-0.07
						c6.04-0.83,12.24-1.34,18.38-1.5l1.44,0c26.29,0,49.65,6.41,69.44,19.05c1.99,1.22,4.06,2.86,6.26,4.58
						c0.56,0.44,1.13,0.89,1.71,1.33c5.66,4.54,14.19,11.36,20.39,11.36c0.85-0.02,1.71-0.15,2.51-0.45
						c5.38-2.05,9.34-9.33,13.17-16.36c2.34-4.69,5.22-9.17,8.55-13.28c4.34-4.96,4.13-11.29,3.88-19.3
						c-0.38-5.2-0.1-10.52,0.83-15.74c4.2-19.45,11.89-32.58,23.5-40.22c5.08-3.13,10.41-5.79,15.91-7.93
						c3.21-1.36,6.58-2.78,9.63-4.38c11.41-5.92,23.64-9.85,36.35-11.66c3.93-0.54,7.84-1.39,11.29-2.14
						c6.42-1.63,13.11-2.58,19.81-2.81c0.09,0,0.18,0,0.26,0c7.28,0,14.43,1.84,20.76,5.33c4.96,2.82,9.79,5.94,14.37,9.28
						c8.1,5.69,15.77,11.09,24.83,13.02c1.54,0.31,3.13,0.48,4.72,0.45c4.54-0.19,9.16-0.84,13.65-1.93c3.34-0.7,7.06-1.48,10.7-1.86
						c1.49-0.16,3.02-0.25,4.53-0.25c7.72,0.34,15.26,1.74,22.5,4.15l0.89,0.25c2.36,0.65,4.8,1.32,7.16,1.88
						c7.17,1.85,14.53,2.89,21.9,3.12c6.64,0,8.38-1.45,8.55-1.62c0.18-0.16,0.21-0.23,0.21-0.23c0.07-0.4-0.4-1.82-3.11-6.16
						c-3.21-5.15-9.88-15.85,0.82-23.64c6.44-4.69,16-5.64,24.17-5.77c-1.1-3.34-2.1-6.73-3.06-10.03
						C964.89,2456.85,962.25,2447.79,957.46,2439.81z"/>
				</g>
				<path className="st5" d="M177.11,546.53c4.64,0.42,8.5,3.56,9.84,8.01c0.99,3.38,1.36,6.88,1.09,10.4l-0.01,1.14
					c0,12.71,1.85,22.51,5.82,30.85c4.83,9.95,13.15,17.32,22.27,19.72c3.15,0.81,6.39,1.2,9.66,1.14c4.17-0.09,8.38-0.4,12.47-0.94
					l0.25-0.03c2.31-0.25,4.5-0.49,6.76-0.68c4.62-0.42,9.17-0.42,13.17-0.42l26.17,0.13c0.56,0.01,1.1,0.22,1.52,0.59
					c0.46,0.41,0.74,0.98,0.77,1.6s-0.17,1.22-0.58,1.68c-6.16,6.89-11.97,14.23-17.25,21.78c-2.35,3.26-7.43,11.41-3.88,16.66
					l0.13,0.17c1.55,1.83,3.85,2.88,6.23,2.88c0.16,0,0.31,0,0.47-0.01c0.02,0,0.04,0,0.06,0c2.13,0,4.21-0.6,6.06-1.77
					c3.7-2.51,6.58-7.19,8.34-13.54c0.53-1.89,0.96-3.73,1.49-6.13c0.75-3.92,1.86-7.8,3.26-11.45c5-11.56,15.87-19.8,28.35-21.51
					l3.25-0.44l-1.88-2.69c-4.75-6.76-12.47-10.63-20.7-10.36H299c-3.14,0-6.34,0.22-9.06,0.44c-2.85,0.23-6.15,0.47-9.33,0.47
					c-3.21,0.04-6.39-0.29-9.5-0.97c-16.79-3.77-26.99-19.62-30.94-33.19c-3.52-12-3.81-24.78-3.81-37.91v-7.67
					c-0.26-13.9-1.6-29.82-9.47-42.43c-2.5-4.01-7.91-11-16.03-12.84c-1.09-0.25-2.2-0.37-3.32-0.37
					c-5.44-0.28-10.57,2.69-13.03,7.59l-0.08,0.17c-2.71,6.92,1.8,14.53,6.17,21.9l0.72,1.2c2.69,4.06,4.43,8.76,5.02,13.58
					c0.63,5.5-1.99,10.94-6.68,13.87c-1.77,0.97-3.79,1.46-5.75,1.46c-4.47-0.15-8.71-1.94-11.9-5.01
					c-2.85-2.88-5.31-6.12-7.36-9.71l-0.23-0.36c-0.76-1.2-1.54-2.45-2.37-3.67c-3.55-5.13-7.34-7.99-11.24-8.49l-0.13-0.02l-0.75,0
					c-2.28,0.07-4.44,1.09-5.94,2.81c-0.96,1.01-1.42,2.37-1.24,3.76l0.03,0.28l0.11,0.26c0.95,2.24,2.49,4.18,4.45,5.62l1.06,0.96
					c4.12,3.9,5.63,8.22,4.13,11.85c-1.37,3.32-5.15,5.38-9.82,5.38c-1.37-0.03-2.74-0.14-4.08-0.33c-1.2-0.17-2.42-0.27-3.68-0.3
					h-0.99l-0.22,0.01c-6.86,0.75-11,8.83-10.42,15.64c0.78,7.38,5.24,13.71,11.97,16.93c2.65,1.21,5.59,1.84,8.5,1.84
					c0.04,0,0.09,0,0.14,0c4.27,0,8.38-1.29,11.87-3.73c0.67-0.46,1.31-0.96,1.93-1.46c2.59-2.03,5.52-4.33,9.31-4.33H177.11z
					 M166.61,549.29c-0.6,0.48-1.21,0.95-1.84,1.38c-3.17,2.21-6.94,3.39-10.81,3.37c-2.67,0.01-5.31-0.56-7.74-1.66
					c-6.02-2.89-10.11-8.69-10.81-15.32c-0.5-5.86,3.13-12.84,8.65-13.44h0.99c1.14,0.02,2.27,0.12,3.39,0.28
					c1.43,0.2,2.88,0.31,4.32,0.35c5.6,0,10.03-2.53,11.71-6.61c1.84-4.45,0.15-9.57-4.6-14.07l-1.17-1.06
					c-1.71-1.23-3.05-2.9-3.87-4.84c-0.1-0.79,0.17-1.59,0.73-2.16c1.13-1.33,2.77-2.11,4.52-2.16h0.56c3.33,0.43,6.64,3,9.86,7.65
					c0.89,1.3,1.71,2.62,2.55,3.96c2.09,3.67,4.66,7.05,7.63,10.05c3.57,3.44,8.29,5.43,13.25,5.6c2.39,0.02,4.74-0.57,6.83-1.73
					c5.4-3.35,8.38-9.51,7.65-15.82c-0.64-5.17-2.46-10.12-5.34-14.46l-0.67-1.12c-4.13-6.96-8.39-14.13-6.03-20.14
					c2.11-4.2,6.51-6.74,11.2-6.48c0.98,0,1.96,0.1,2.92,0.32c7.43,1.69,12.45,8.21,14.78,11.95c7.61,12.19,8.9,27.77,9.16,41.41
					c0,2.55,0,5.08,0,7.63c0,12.71,0.22,25.94,3.89,38.47c4.11,14.11,14.8,30.62,32.42,34.58c3.27,0.72,6.61,1.06,9.96,1.02
					c3.2,0,6.48-0.24,9.47-0.48c2.98-0.24,5.99-0.43,8.9-0.43h1.25c7.55-0.25,14.72,3.32,19.06,9.51
					c-13.25,1.81-24.6,10.42-29.91,22.69c-1.49,3.85-2.62,7.82-3.39,11.87c-0.48,2.16-0.91,4.04-1.45,5.97
					c-1.69,6.07-4.32,10.24-7.54,12.43c-1.51,0.95-3.27,1.45-5.06,1.43c-1.95,0.14-3.86-0.67-5.12-2.16
					c-2.66-3.93,1.27-10.81,3.85-14.37c5.27-7.54,10.99-14.75,17.12-21.61c1.59-1.78,1.43-4.52-0.35-6.1
					c-0.78-0.69-1.78-1.08-2.82-1.09l-21.61-0.13h-4.58c-3.96,0-8.65,0-13.36,0.43c-2.36,0.19-4.69,0.45-7.05,0.71
					c-4.08,0.53-8.18,0.84-12.3,0.93c-3.07,0.05-6.14-0.32-9.12-1.08c-8.65-2.27-16.4-9.25-20.96-18.65
					c-3.89-8.17-5.62-17.83-5.62-29.98v-0.99c0.28-3.74-0.11-7.51-1.17-11.11c-1.58-5.25-6.21-8.99-11.67-9.44h-0.13
					C172.69,544.53,169.32,547.17,166.61,549.29z"/>
				<path className="st5" d="M177.29,544.53c5.46,0.45,10.09,4.2,11.67,9.44c1.05,3.6,1.45,7.37,1.17,11.11v0.99
					c0,12.15,1.73,21.81,5.62,29.98c4.56,9.4,12.32,16.38,20.96,18.65c2.98,0.76,6.05,1.13,9.12,1.08c4.11-0.08,8.22-0.4,12.3-0.93
					c2.36-0.26,4.69-0.52,7.05-0.71c4.71-0.43,9.4-0.43,13.36-0.43h4.58l21.61,0.13c1.04,0.01,2.05,0.4,2.82,1.09
					c1.78,1.59,1.94,4.32,0.35,6.1c-6.13,6.86-11.85,14.07-17.12,21.61c-2.57,3.57-6.51,10.44-3.85,14.37
					c1.27,1.49,3.17,2.3,5.12,2.16c1.79,0.02,3.54-0.47,5.06-1.43c3.22-2.18,5.86-6.35,7.54-12.43c0.54-1.92,0.97-3.8,1.45-5.97
					c0.77-4.05,1.91-8.02,3.39-11.87c5.31-12.28,16.66-20.88,29.91-22.69c-4.34-6.19-11.51-9.76-19.06-9.51h-1.25
					c-2.92,0-5.92,0.19-8.9,0.43c-2.98,0.24-6.27,0.48-9.47,0.48c-3.35,0.04-6.69-0.3-9.96-1.02
					c-17.61-3.96-28.31-20.47-32.42-34.58c-3.67-12.54-3.89-25.76-3.89-38.47c0-2.55,0-5.08,0-7.63
					c-0.26-13.64-1.56-29.22-9.16-41.41c-2.33-3.74-7.35-10.27-14.78-11.95c-0.96-0.22-1.94-0.33-2.92-0.32
					c-4.69-0.26-9.09,2.29-11.2,6.48c-2.36,6.01,1.9,13.18,6.03,20.14l0.67,1.12c2.88,4.34,4.7,9.29,5.34,14.46
					c0.73,6.31-2.25,12.47-7.65,15.82c-2.09,1.15-4.44,1.75-6.83,1.73c-4.96-0.17-9.67-2.16-13.25-5.6c-2.97-3-5.54-6.38-7.63-10.05
					c-0.84-1.34-1.66-2.66-2.55-3.96c-3.22-4.65-6.53-7.22-9.86-7.65h-0.56c-1.74,0.05-3.38,0.84-4.52,2.16
					c-0.56,0.57-0.83,1.37-0.73,2.16c0.82,1.94,2.16,3.61,3.87,4.84l1.17,1.06c4.75,4.5,6.44,9.62,4.6,14.07
					c-1.69,4.08-6.12,6.61-11.71,6.61c-1.45-0.03-2.89-0.15-4.32-0.35c-1.12-0.16-2.26-0.26-3.39-0.28h-0.99
					c-5.51,0.61-9.14,7.59-8.65,13.44c0.7,6.64,4.79,12.44,10.81,15.32c2.43,1.1,5.07,1.67,7.74,1.66
					c3.86,0.01,7.64-1.16,10.81-3.37c0.63-0.43,1.23-0.91,1.84-1.38c2.7-2.12,6.07-4.75,10.55-4.75H177.29z M165.27,547.73
					c-0.59,0.47-1.15,0.9-1.73,1.3c-2.83,1.97-6.15,3.01-9.61,3.01c-0.02,0-0.04,0-0.06,0c-2.32,0.02-4.75-0.51-6.87-1.47
					c-5.43-2.61-9.05-7.74-9.68-13.69c-0.41-4.79,2.46-10.72,6.77-11.27l0.83,0c1.05,0.02,2.11,0.11,3.16,0.26
					c1.5,0.21,3.03,0.33,4.6,0.36c6.37,0,11.56-3.01,13.56-7.85c2.17-5.25,0.32-11.19-5.11-16.31l-1.17-1.06l-0.18-0.14
					c-1.31-0.94-2.36-2.2-3.04-3.66c0.02-0.12,0.08-0.22,0.17-0.31l0.1-0.11c0.76-0.89,1.88-1.43,3-1.46h0.42
					c2.7,0.41,5.51,2.69,8.34,6.78c0.78,1.15,1.51,2.31,2.28,3.53l0.18,0.29c2.18,3.82,4.85,7.34,7.98,10.5
					c3.94,3.78,9.11,5.97,14.62,6.16c0.05,0,0.09,0,0.14,0c2.68,0,5.33-0.68,7.68-1.98l0.09-0.05c6.03-3.74,9.4-10.7,8.58-17.76
					c-0.67-5.45-2.63-10.74-5.61-15.24l-0.67-1.13c-3.85-6.5-7.84-13.22-5.92-18.3c1.77-3.44,5.39-5.52,9.25-5.3l0.12,0
					c0.78,0,1.66,0.09,2.47,0.27c6.71,1.52,11.36,7.58,13.53,11.06c7.36,11.79,8.61,27.03,8.86,40.35v7.63
					c0,13.43,0.3,26.52,3.97,39.03c4.27,14.67,15.42,31.82,33.91,35.98c3.42,0.75,6.9,1.11,10.39,1.06c3.3,0,6.7-0.25,9.63-0.48
					c2.64-0.21,5.75-0.43,8.75-0.43l1.32,0c5.76-0.2,11.32,2.06,15.29,6.15c-12.4,2.82-22.95,11.5-28.07,23.33
					c-1.52,3.95-2.7,8.05-3.48,12.16c-0.5,2.28-0.92,4.05-1.42,5.86c-1.51,5.43-3.84,9.34-6.68,11.27
					c-1.19,0.75-2.58,1.13-3.97,1.12l-0.08,0l-0.08,0.01c-1.29,0.09-2.53-0.42-3.38-1.37c-1.63-2.63,1.05-8.08,3.89-12.02
					c5.21-7.44,10.92-14.65,16.97-21.43c1.12-1.26,1.69-2.88,1.59-4.57s-0.85-3.23-2.11-4.36c-1.14-1.01-2.61-1.58-4.14-1.6
					l-21.62-0.13h-4.58c-3.89,0-8.74,0-13.52,0.44c-2.3,0.19-4.61,0.44-6.85,0.69l-0.29,0.03c-3.99,0.52-8.05,0.83-12.07,0.91
					c-2.91,0.04-5.79-0.3-8.58-1.01c-7.99-2.1-15.35-8.67-19.67-17.58c-3.7-7.77-5.42-17.02-5.42-29.12v-0.92
					c0.29-3.97-0.13-7.92-1.25-11.76c-1.83-6.08-7.1-10.34-13.42-10.86l-0.29-0.01C171.89,542.53,168.07,545.53,165.27,547.73z"/>
				<path className="st5" d="M1573.36,1968.95c-5.65-26.05-18.94-49.43-38.44-67.61c-25.06-23.22-67.86-36.54-117.42-36.54
					c-5.49,0-11.23,0.18-17.05,0.54c-10.34,0.67-24.95,2.57-32.45,11.25c-4.18,4.77-5.51,10.95-6.94,17.56
					c-1.36,6.65-2.91,14.19-8.18,20.15l-0.1,0.13c-2,2.67-5.06,4.31-8.39,4.49c-3.91-0.02-7-2.67-10.28-5.48
					c-1.32-1.18-2.73-2.3-4.21-3.35c-1.86-1.25-3.84-2.55-6.03-3.97l-1.64-1.07c-3.7-2.42-7.53-4.92-11.1-7.68
					c-9.13-7.07-14.17-14.31-14.97-21.47c-0.52-5.62,1.35-11.23,5.14-15.42c4.36-4.86,9.33-9.16,14.82-12.84
					c6.13-4.46,11.92-8.68,15.9-14.74c10.99-16.66,6.08-40.79,3.43-50.47c-2.3-7.14-5.53-13.94-9.62-20.23l-0.91-1.57
					c-1.86-3.21-3.79-6.52-5.47-9.97l-0.88-1.87c-0.69-1.46-1.38-2.92-2.05-4.4c-3.19-7.49-7.11-14.73-11.67-21.56
					c-4.23-5.92-9.37-8.02-15.33-10.45l-0.11-0.05c-3.53-1.34-6.97-2.99-10.14-4.84c-6.88-4.48-13.22-9.78-18.89-15.8l-3.34-3.34
					c-9.78-9.8-19.9-19.94-33.34-23.73l-0.3-0.06c-1.37-0.17-2.77-0.26-4.14-0.27c-5.48-0.22-11.69-0.47-14-3.8
					c-0.92-1.38-1.13-3.13-0.55-4.72c1.72-4.95,10.92-7.81,13.43-8.34l0.21-0.04c1.68-0.35,3.4-0.52,5.07-0.52c0.03,0,0.05,0,0.08,0
					c6.51,0.22,12.9,2.11,18.53,5.49c6.18,3.5,12.14,7.52,17.68,11.94c5.85,4.75,12.19,8.93,18.89,12.47
					c3.1,1.55,16.4,6.22,23.52,6.22c0.82,0.04,1.65-0.05,2.46-0.25l0.38-0.1l0.32-0.24c1.64-1.22,2.67-3.09,2.84-5.19
					c0.17-3.89-3.58-7.88-6.32-10.8l-0.08-0.09c-0.9-0.89-1.74-1.87-2.46-2.84c-1.39-2.03-3.64-4.83-6.03-7.79l-0.55-0.68
					c-1.93-2.39-4.69-5.85-6.4-8.19c-6.67-9.52-12.6-17.79-19.86-27.18c-9.69-12.5-15.66-21.34-16.77-38.68
					c-0.28-4.59-0.83-9.69-1.36-14.63l-0.13-1.21c-0.59-5.58-1.2-11.34-1.45-16.56c-1.23-24.71-6.38-43.42-16.22-58.86
					c-6.1-9.51-14.62-16.21-23.66-23.31c-2.29-1.8-4.65-3.66-6.88-5.49c-5.54-4.86-10.52-10.36-14.82-16.35
					c-3.94-5.52-8.48-10.63-13.55-15.26c-9.59-8.06-22.28-13.51-33.48-18.31l-1.93-0.83c-1.75-0.75-3.41-1.45-4.97-2.19
					c-11.91-5.39-24.52-8.97-37.46-10.64l-1.7-0.24c-4.78-0.58-10.2-1.25-11.58-8.65l-0.04-0.61c-1.5-8.56-2.69-15.33-7.38-22.51
					c-2.46-3.71-5.33-7.42-7.27-9.88c-2.44-3.1-5.02-6.41-7.29-9.87c-6.93-10.49-9.64-23.56-11.38-34.96
					c-1.98-13.24-5.51-26.24-10.5-38.67c-1.34-3.3-2.77-6.57-4.03-9.45c-3.77-7.82-6.89-16.02-9.26-24.37
					c-1.16-5.19-1.97-10.52-2.42-15.87l-0.05-0.51c-0.44-4.19-0.85-8.15-1.67-12.2c-1.19-5.1-2.84-10.12-4.92-14.94
					c-2.66-6.03-4.54-12.38-5.59-18.89c-0.56-6.33-0.68-12.76-0.33-19.1c0.27-5.29,0.27-10.65-0.01-16.03
					c-0.9-9.44-3.39-18.59-7.4-27.19c-4.05-8.69-12.33-13.28-19.65-17.33c-6.61-3.71-13.45-7.54-18.42-13.88
					c-6.68-8.45-8.61-19.01-9.6-28.24l-0.01-0.32c-1.41-13.14-2.86-26.73-14-34.49c-9.67-6.8-17.82-10.11-24.92-10.11
					c-0.99,0.01-2.01,0.07-2.98,0.21c-1.27,0.18-2.53,0.27-3.85,0.26c-14.06,0-28.67-10.42-40.43-18.8l-5.28-3.74
					c-2.97-2.02-5.87-4.37-8.68-6.65l-0.45-0.36c-7.15-5.83-13.91-11.33-22.3-13.09c-0.76-0.16-1.53-0.24-2.29-0.24
					c-1.04,0-2.13,0.06-3.44,0.21c-1.22,0.14-2.33,0.25-3.5,0.25c-1.43-0.01-2.88-0.1-4.37-0.27l-0.69-0.06
					c-1.09-0.1-2.12-0.19-3.11-0.19c0,0-0.01,0-0.01,0c-1.46-0.04-2.89,0.2-4.25,0.71c-3.62,1.34-6.85,3.61-9.36,6.54l-1.65,2.58
					c-6.11,9.84-10.94,17.61-23.71,21.09c-4.3,1.18-8.75,1.81-13.19,1.85c-7.29,0-12.82-2.01-16.44-5.96
					c-0.39-0.43-0.76-0.88-1.16-1.36c-0.62-0.83-1.34-1.6-2.13-2.27l-0.1-0.08c-2.17-1.63-5.66-1.75-8.7-1.87l-2.84-0.15
					c-5.53-0.46-11.24-1.03-17.96-1.79c-9.13-1.07-18.39-2.31-27.09-3.5l-6.57-0.88c-0.74-0.11-1.69-0.18-2.8-0.25l-0.96-0.07
					c-9.99-0.68-20.04-1.8-23.57-7.2c-1.39-2.14-1.65-4.82-0.65-7.27c1.64-4.64,6.42-7.1,13.77-7.1c4.68,0.12,9.35,0.75,13.86,1.84
					c4.86,1.26,9.6,3.06,14.15,5.37l0.25,0.12c6.15,2.83,11.96,5.51,18.26,5.51h1.51l0.15-0.01c7.98-0.62,18.41-3.55,23.75-9.94
					l0.07-0.08c2.04-2.72,3.72-5.68,5.02-8.8c1.4-3.3,3.19-6.44,5.27-9.28c6.21-8.02,13.38-15.31,21.22-21.62
					c4.6-3.41,10.29-5.14,16.06-4.86c3.72,0.07,7.47,0.38,11.19,0.92l3.64,0.43c1.51,0.17,3.05,0.27,4.6,0.25
					c8.92-0.19,17.79-2.52,25.64-6.71c9.15-4.71,12.51-9.79,9.96-15.1c-1.42-2.92-3.04-5.79-4.8-8.52c-1.1-1.72-2.42-3.6-4.21-4.17
					c-1.45-0.43-2.94-0.61-4.35-0.54h-1.79l-0.12,0c-3.29,0.2-6.5-0.83-9.06-2.89c-3.95-3.58-6.63-8.22-7.76-13.46
					c-0.54-2.32-0.9-4.71-1.08-7.14c-0.71-7.14-1.52-11.91-6.1-13.17c-1.23-0.31-2.49-0.46-3.76-0.45l-0.11,0
					c-4.42,0.31-8.76,1.23-12.84,2.7l-1.71,0.53c-5.9,1.99-11.67,4.48-17.12,7.39c-3.65,1.82-7.42,3.71-11.3,5.34
					c-1.19,0.52-2.44,0.81-3.75,0.85c-1.75,0.07-3.38-0.91-4.16-2.48c-0.93-2.79-0.2-5.92,1.92-8.05c2.87-3.06,6.19-5.66,9.89-7.72
					c9.16-5.27,19.14-8.47,29.71-9.52c3.65-0.47,7.4-0.62,11.02-0.76l0.74-0.03c8.4-0.29,16.34-0.56,23.7-4.18
					c10.35-5.04,20.32-13.54,29.63-25.26c6.24-7.85,9.25-17.63,11.8-26.98c0.69-2.53,1.27-5.09,1.9-7.93
					c1.85-8.24,3.77-16.75,8.74-23.91c3.85-5.08,8.38-9.6,13.52-13.49c2.33-1.9,4.42-3.63,6.43-5.51
					c6.88-6.41,8.19-15.66,9.45-24.59l0.18-1.23c0.1-0.69,0.2-1.37,0.31-2.07c1.5-9.8,5.71-20.14,13.26-32.54
					c1.41-2.3,3.09-4.6,4.72-6.83l0.28-0.39c4.48-6.04,9.11-12.3,9.23-19.46l0-0.1l-0.01-0.1c-0.3-3.52-1.39-6.99-3.17-10.04
					c-2.21-4.59-4.48-9.34-3.13-15.06c1.44-5.15,3.6-10.07,6.44-14.67c1.64-2.85,3.13-5.83,4.45-8.9l1.65-4.12
					c2.86-8.04,6.7-15.73,11.41-22.84c3.19-4.1,6.82-7.87,10.77-11.18c3.89-3.19,7.41-6.84,10.52-10.92
					c4.54-6.58,6.9-14.29,6.84-22.27c0.05-13.39-5.79-28.18-17.35-43.95l-1.21-1.68c-5.74-7.9-11.68-16.07-20.72-18.23
					c-2.28-0.53-4.62-0.78-7.03-0.76c-5.88,0.27-11.75,1.21-17.43,2.77c-2.99,0.7-6.07,1.42-9.08,1.95
					c-14.38,2.51-29.07,3.8-43.66,3.84c-2.6,0-5.24-0.04-7.79-0.13c-9.75-0.58-19.56-1.85-29.12-3.79
					c-10.34-2.17-20.93-3.45-31.56-3.8c-1.91,0-3.8,0.07-5.61,0.2c-3.46,0.24-6.97,0.64-10.58,1.07
					c-5.77,0.76-11.64,1.21-17.43,1.32c-3.58,0.02-7.25-0.28-10.8-0.9c-3.02-0.55-6.15-1.24-9.19-1.9l-0.35-0.07
					c-7.19-1.89-14.61-3-22.1-3.31c-4.07-0.06-8.12,0.56-12.08,1.83c-3.48,1.28-6.88,2.84-10.12,4.65
					c-3.5,1.95-7.18,3.62-10.87,4.94c-3.04,0.94-6.2,1.36-9.42,1.27c-1.34,0-3.06-0.01-4.54-0.12c-1.5-0.11-2.98-0.11-4.41-0.11
					c-3.5-0.21-7.02,0.5-10.17,2.04l-0.23,0.13c-3.2,2.14-5.95,4.82-8.13,7.94c-2.32,3.16-5.11,5.92-8.24,8.16
					c-7.93,5.24-17.63,5.78-26.16,6.26l-3.01,0.1c-3.56-0.22-7.16,0.39-10.43,1.78l-0.25,0.12c-3.26,1.95-5.12,5.67-6.92,9.27
					l-0.26,0.52c-0.24,0.49-0.48,0.97-0.72,1.42c-3.54,6.47-8.33,11.99-14.28,16.45c-4.16,3.33-9.38,5.06-14.79,4.88
					c-1.71,0-3.37-0.1-5.28-0.22l-3.2-0.19l-1.89,0c-0.38,0-0.75-0.01-1.12-0.02c-0.85-0.03-1.82-0.05-3.06,0.03
					c-1.64,0.1-3.42,0.1-5.15,0.1c-4,0-9.01-0.24-12.31-2.15l-0.2-0.1c-2.02-0.89-3.21-2.96-2.95-5.1c0.67-4.56,8.04-6.23,8.07-6.24
					c2.72-0.54,5.51-0.84,8.36-0.89l0.61-0.03c1.45-0.07,2.81-0.14,4.25-0.31c9.48-1.47,18.25-5.65,25.32-12.07
					c11.67-10.13,20.09-23.98,27.52-36.2l0.57-0.94c2.49-4.09,5.06-8.32,7.73-12.35c4.08-6.49,8.86-12.56,14.18-17.97
					c0.68-0.64,1.48-1.35,2.36-2.12c4.26-3.78,8.38-7.75,7.87-11.15l-0.26-1.7h-1.16c-1.28-0.65-3.35-0.97-6.18-0.97l-3.54,0
					c-2.08,0.09-4.14,0.26-6.13,0.43l-0.12,0.01c-3.26,0.28-6.2,0.51-9.22,0.51c-4.15,0.07-8.26-0.55-12.17-1.83
					c-3.53-1.23-7.88-4.04-8.51-7.88c-0.35-2.64,0.87-5.27,3.17-6.72c2.47-1.65,5.34-2.59,8.36-2.71c1.7-0.12,3.38-0.47,4.99-1.04
					l0.16-0.07c3.77-1.74,3.51-5.16,2.71-11.15c-0.41-2.32-0.57-4.69-0.47-6.96c0.6-6.97,3.34-16.72,9.24-23.15
					c3.68-3.98,8.6-5.91,13.31-7.75c3.01-1.06,5.93-2.43,8.67-4.09l0.15-0.1c5.58-4.08,10.77-8.74,15.43-13.84
					c1.8-1.84,3.66-3.75,5.51-5.53c10.52-10.28,20.65-20.91,30.97-32.53c8.78-9.95,19.26-21.18,32.61-28.53
					c1.92-1.05,3.88-1.94,5.51-2.67c3.04-1.26,5.96-2.85,8.74-4.77c6.45-4.89,10.41-12.55,14.28-20.02l1.07-2.13
					c0.85-1.63,1.84-3.3,2.71-4.74c2.97-4.95,6.03-10.07,5.64-15.25l-0.03-0.21c-0.64-3.42-2.03-6.72-4.04-9.55
					c-1.15-1.87-2.19-3.83-3.06-5.78c-3.47-8.33,1.32-15.04,5.55-20.95l0.79-1.1c2.04-2.55,3.67-5.38,4.84-8.39
					c0.99-2.43,0.86-5.16-0.39-7.53c-2.54-4.51-9.51-7.03-14.84-8.34c-5.87-1.63-11.93-2.57-18.05-2.8
					c-4.18-0.08-8.21,0.71-12.12,2.35c-2.79,1.29-5.5,2.79-8.11,4.48c-4.08,2.77-8.54,4.9-13.26,6.31c-3.41,0.8-6.93,1.17-10.46,1.1
					c-0.9,0-1.83-0.03-2.75-0.06c-0.95-0.03-1.88-0.06-2.76-0.06h-5.19c-2.07-0.02-4.19,0.1-6.27,0.36
					c-5.38,0.72-9.94,3.37-14.78,6.17l-0.13,0.07c-3.33,2.06-6.86,3.84-10.42,5.26c-3.47,1.24-7.09,1.83-10.73,1.81
					c-6.05-0.23-12.08-1.18-17.89-2.82c-5.2-1.5-10.59-2.39-16.06-2.65c-3-0.06-5.97,0.48-8.78,1.59l-0.13,0.06
					c-4.04,1.92-7.78,4.41-11.1,7.36c-3.27,2.81-6.87,5.27-10.66,7.28c-3.61,1.87-7.66,2.82-11.77,2.76l-1.34,0
					c-1.29-0.06-2.6-0.18-3.88-0.37c-1.26-0.18-2.54-0.3-3.71-0.34c-1.75-0.15-3.51,0.42-4.84,1.55l-0.2,0.17l-0.15,0.22
					c-0.68,1-1.27,2.07-1.7,3.13c-1.46,3.18-3.03,6.03-6.13,6.08c-1.94-0.15-3.77-1.09-5.03-2.58l-0.11-0.11
					c-2.72-2.76-2.96-6.6-2.97-10.63c0.13-2.09-0.13-4.16-0.76-6.16l-0.14-0.33c-2.2-4.14-7.21-4.58-9.24-4.58
					c-3.29-0.03-6.37,0.93-9.02,2.81l-0.21,0.15l-0.16,0.19c-1.46,1.73-2.7,3.64-3.7,5.68c-2.77,4.96-6.21,11.1-11.64,11.1
					c-0.62,0-1.23-0.07-1.87-0.25c-3.44-0.84-4.39-4.8-5.13-9.11c-0.13-1.06-0.35-2.12-0.65-3.15l-0.08-0.23
					c-3.15-7.28-9.29-12.83-16.8-15.21c-4.33-1.52-8.87-2.28-13.44-2.27c-10.52,0-23.96,4.2-30.15,16.01
					c-3.62,6.92-3.2,14.47-2.8,21.76l0.01,0.14c0.42,7.65,0.85,15.56-3.02,23.2c-3.97,7.58-5.39,16.41-4.01,24.85l0.09,0.34
					c1.06,3.01,2.71,5.72,4.89,8.06c3.29,4.14,6.66,8.42,5.65,13.68c-1.05,5.52-6.06,8.32-14.84,8.32
					c-4.66-0.12-9.35-0.62-13.97-1.46c-0.31-0.05-0.6-0.11-0.88-0.16c-0.66-0.13-1.29-0.25-1.92-0.27l-2.19-0.32
					c-3.17-0.56-6.41-0.9-9.69-0.98c-4.42,0-7.24,0.94-8.59,2.81c-1.41,1.84,0.08,4.35,3.75,8.96l0.11,0.14
					c0.65,0.8,1.26,1.56,1.74,2.26c4.09,5.55,7.04,11.75,8.74,18.39c0.17,0.73,0.37,1.49,0.57,2.27l0.11,0.43
					c1.29,4.81,2.9,10.79,0.32,16.01c-2.17,4.26-6.48,7-11.19,7.16c-0.01,0-0.01,0-0.02,0c-1.43,0-2.87-0.32-4.17-0.92
					c-2.12-1.24-3.86-3.03-5.02-5.2l-0.18-0.28c-0.42-0.66-0.91-1.4-1.37-2.02l-2.93-3.91l-0.66,4.84
					c-0.15,1.12-0.24,2.26-0.28,3.35c-0.47,10.23,4.78,19.06,15.63,26.27c4.8,3.03,9.85,5.78,15,8.18c0.58,0.31,1.31,0.59,2.08,0.88
					c0.3,0.11,0.6,0.23,0.92,0.36c6.99,2.73,14.35,6.25,13.34,13.35c-0.83,5.74-7.38,8.75-13.44,8.75
					c-1.23,0.03-2.43-0.15-3.61-0.46c-1.26-0.42-2.51-0.94-3.78-1.57l-0.67-0.3c-5.97-2.68-12.7-5.53-16.87-5.53h-1.88l-0.12,1.87
					c-0.01,0.21-0.01,0.42,0,0.62l0.06,0.36c1.07,4.25,7.69,6.35,12.55,7.89l2.49,0.82c5.41,1.79,9.08,3.62,9.44,6.67
					c0.16,1.91-0.86,3.68-2.58,4.52l-0.16,0.08c-1.12,0.68-2.44,1.01-3.74,0.99c-0.62-0.01-1.24-0.06-1.85-0.15
					c-4.62-0.93-9-2.97-12.72-5.93c-2.61-1.99-5.48-3.59-8.61-4.76c-0.58-0.2-1.18-0.29-1.78-0.28l-0.13,0.01
					c-1.43,0.12-2.84,0.45-4.16,0.97c-1.83,0.7-3.76,1.1-5.64,1.16c-0.43,0-0.86-0.03-1.28-0.08c-2.82-0.61-5.48-1.9-7.7-3.74
					l-0.12-0.09c-0.77-0.55-1.4-0.98-2.01-1.37l-0.32-0.2c-3.42-2.08-6.7-3.95-9.19-4.03c-0.29-0.03-0.59-0.02-0.88,0.04l-0.69,0.14
					l-0.45,0.54c-0.59,0.7-0.96,1.55-1.09,2.46c-0.43,2.11,0.98,4.94,2.87,8.4l1.72,3.13c0.25,0.45,0.52,0.93,0.8,1.46
					c5.48,10.01,10.44,21.21,0,27.43c-0.64,0.39-1.38,0.59-2.12,0.6c-3.84,0-6.68-5.15-10.62-12.27l-0.08-0.15
					C311.26,404.9,307.88,403,305.55,403c-1.88,0-3.9,1.64-6,4.87c-3.97,6.11-4.45,16.77-2.61,23.55l0.04,0.14
					c0.57,1.64,3.09,3.66,8.16,7.3l0.22,0.15c7.71,5.5,14.29,10.94,10.19,16.94c-1.93,2.41-4.66,3.99-7.71,4.47l-0.22,0.05
					c-2.88,0.79-4.96,1.35-5.81,3.78c-1.4,4.36-0.94,9.17,1.24,13.12c4.4,8.72,13.22,14.88,22.71,20.99l1.08,0.63
					c3.03,1.66,5.78,3.66,5.45,7.07c-0.31,3.27-3.62,5.38-8.43,5.38l-0.2,0c-6.09-0.1-12.23-3.79-17.47-7.4
					c-0.79-0.53-1.61-1.2-2.61-2.05l-0.13-0.1c-2.73-2.23-4.98-3.93-6.98-3.93c-0.72,0.02-1.4,0.29-1.93,0.76
					c-1.97,1.61-2.77,5.53-3.42,11.26c-0.05,0.41-0.1,0.8-0.15,1.18c-0.08,0.67-0.16,1.3-0.25,1.87
					c-1.68,10.4-0.45,30.14,7.52,39.52c2.41,3.18,6.22,5.07,10.24,5.07c1.77-0.03,3.52-0.31,5.23-0.83l1.46-0.46
					c5.4-1.94,11.03-3.14,16.75-3.56c7.76,0.03,9.28,4.28,9.49,6.74c0.14,3.02-1.36,5.91-3.89,7.53c-1.89,1.19-3.94,2.07-6.11,2.6
					l-0.58,0.18c-7.17,2.18-11.9,4.97-12.92,7.59c-0.43,0.96-0.32,1.95,0.33,3.09l0.18,0.26c0.7,0.93,2.01,2,5.88,2
					c1.51,0,3.23-0.15,4.5-0.27l0.7-0.06c0.91-0.08,1.77-0.17,2.49-0.17h1.02c0.03,0,0.06,0,0.1,0c5.58,0,11.19,1.28,16.18,3.68
					c3.65,1.84,8.87,6.48,7.37,11.16c-0.83,2.81-3.46,4.7-6.29,4.58c-3.86-0.33-7.6-1.32-11.15-2.95c-1.99-0.87-4.08-1.57-6.19-2.08
					l-0.42-0.1l-0.43,0.08c-1.9,0.37-2.99,2.4-4.18,4.6c-0.69,1.41-1.56,2.72-2.52,3.86c-0.19,0.2-0.4,0.37-0.65,0.5l-1.53,0.79
					l0.56,1.63c0.17,0.49,0.17,1.02-0.02,1.57l-0.67,2.16c-2.85,9.24-3.7,13.14,3.9,16.98l0.25,0.11c2.22,0.77,4.52,1.33,6.77,1.68
					c5.3,1.04,10.78,2.11,14.45,6.59c1.49,1.75,1.8,4.21,0.8,6.27c-2.22,3.82-6.16,6.26-10.57,6.55c-0.33,0-0.66-0.03-0.98-0.08
					c-2.84-0.64-5.48-1.82-7.85-3.51l-3.01-1.84c-3.21-1.96-6.53-4-9.8-4l-0.54,0c-2.64,0.17-4.61,1.83-5.85,4.92
					c-2.06,5.11-1.32,12.32,1.58,15.43l0.11,0.11c1.84,1.68,3.94,3,6.25,3.92c3.66,1.74,7.78,3.73,9.77,8.32l0.09,0.18
					c1.18,2.11,1.2,4.73,0.05,6.85c-1.25,1.7-3.33,2.62-5.49,2.43c-3.72-0.22-7.34-1.1-10.75-2.6c-0.67-0.29-1.36-0.62-2.09-0.97
					l-0.43-0.21c-3.09-1.89-6.47-3.11-10.05-3.63l-0.21-0.02c-1.32-0.05-2.66,0.35-3.74,1.15c-3.74,2.8-5.41,9.81-6.68,16.16
					l-0.18,0.87c-0.86,3-0.52,6.17,0.96,8.93l0.19,0.29c1.79,2.27,4.45,3.76,7.32,4.09c0.46,0.09,1.08,0.2,1.8,0.21
					c3.53,0.33,7.36,0.99,8.48,4.43c1.71,5.27-5.09,11.02-8.76,14.12l-1.17,1.02c-2.7,2.57-5.22,5.37-7.48,8.33
					c-2.3,2.97-4.85,5.79-7.57,8.39c-3.52,3.01-8,4.5-12.62,4.2l-0.13,0c-0.76,0-1.53-0.04-2.29-0.08
					c-0.77-0.04-1.57-0.08-2.38-0.09c-1.29-0.15-2.51-0.15-3.69-0.15l-0.55,0c-4.52,0.12-9.02,0.83-13.3,2.1
					c-12.06,3.19-15.67,7.07-16.75,9.1c-0.79,1.5-0.86,3.24-0.19,4.8c0.6,1.56,2.24,2.23,4.3,3.08l0.3,0.12
					c0.39,0.16,0.79,0.33,1.17,0.51l0.14,0.06c2.04,0.79,3.52,2.63,3.84,4.77c0.79,5.53-6.31,12.39-13.38,18.39l-0.99,0.86
					c-1.43,1.27-2.94,2.5-4.54,3.81l-0.17,0.14c-5.09,4.14-10.36,8.41-13.06,13.93c-0.59,1.01-0.94,2.15-1.01,3.32l-0.07,1.2
					l1.02,0.63c1.96,1.2,4.09,2.02,6.37,2.43c3.21,0.56,6.27,1.91,8.77,3.87c1.24,1.06,1.99,2.6,2.04,4.21
					c0.07,2.73-2.05,4.84-3.91,6.71c-0.73,0.73-1.43,1.53-2.07,2.35l-1.37,1.77l1.92,1.16c1.23,0.74,2.63,1.2,4.06,1.32
					c0.64,0.09,2.29,0.2,3.57,0.2c11.12,0.35,14.75,0.83,15.78,3.13c0.44,0.99,0.28,2.12-0.43,2.96c-1.03,1.26-2.27,2.54-3.53,3.84
					c-4.52,4.74-7.45,8.17-7.03,12.53l0.06,0.64l0.42,0.48c0.97,1.12,2.07,2.13,3.2,2.97c4.07,3.25,6.27,5.94,4.62,9.26
					c-0.97,1.96-3.08,2.91-6.4,2.91c-1.96-0.04-3.93-0.25-5.86-0.63l-0.74-0.04c-1.69-0.28-3.41-0.64-4.98-0.98
					c-3.51-0.87-7.13-1.39-10.76-1.55c-3.17-0.12-6.22,0.74-8.86,2.5l-0.08,0.06c-1.6,1.19-2.33,2.38-2.3,3.77l0.01,0.22
					c0.38,3.43,5.34,7.7,9.63,9.42c3.91,1.41,8.02,2.05,12.09,1.89c2.05,0,4.3-0.12,6.28-0.22l1.8-0.09c1.8-0.09,3.67-0.19,5.5-0.19
					c10.32-0.13,21.11-0.73,29.55-6.35c0.7-0.39,1.42-0.92,2.29-1.55c6.03-4.36,11.87-8.27,16.96-8.27l0.12,0
					c0.75-0.04,1.5,0.04,2.23,0.24c3.52,1.01,5.57,4.69,4.57,8.21l-0.05,0.24c-0.09,0.56-0.15,1.13-0.17,1.69l-0.02,0.43l0.16,0.4
					c0.54,1.37,1.91,1.37,2.36,1.37l0.3-0.02c2.23-0.34,4.33-1.28,6.02-2.72c5.18-4.22,9.21-9.73,11.66-15.93
					c3.15-7.8,5.56-15.95,7.14-24.15c1.31-5.88,2.52-11.18,4.15-16.4c2.68-8.4,9.32-14.12,15.17-19.17
					c2.28-1.95,4.52-4.04,7.11-6.63c9.67-10.25,18.94-21.58,27.5-32.29l2.16-2.64c0.8-0.98,1.56-2.05,2.37-3.19l0.41-0.58
					c2.3-3.79,5.48-7.08,9.18-9.51c1.87-0.97,3.96-1.49,6.05-1.5c3.92,0,6.58,2.03,6.96,5.3c1.37,11.78-7.21,19.27-15.53,26.51
					c-4.13,3.19-7.77,6.95-10.82,11.19l-0.1,0.16c-4.92,8.38-8,17.93-8.91,27.61c-0.17,1.93-0.33,3.84-0.33,6.02
					c-0.25,6.25-0.51,12.71-3.73,18.57c-2.17,3.96-4.54,7.85-6.83,11.62c-5.57,9.15-10.83,17.79-13.98,27.68
					c-2.28,7.12-0.8,12.37,0.92,18.47c0.96,3.19,1.71,6.47,2.24,9.76c0.4,4.61-0.24,9.33-1.88,13.73c-0.88,2.65-1.51,5.39-1.87,8.23
					c-0.15,1.64-0.26,3.31-0.37,4.96l-0.01,0.14c-0.12,5.81-1.02,11.58-2.69,17.15c-3.42,9.19-7.62,18.18-12.49,26.75
					c-4.21,7.86-7.44,15.9-2.55,21.46l0.18,0.18c0.67,0.59,1.42,1.1,2.12,1.49c2.58,1.55,5.09,3.37,5,7.04
					c-0.41,2.94-1.35,5.75-2.78,8.35l-0.06,0.13c-2.55,5.51-3.74,8.9-2.79,10.95c0.44,1.03,1.54,1.8,3.29,2.3l0.28,0.06
					c0.58,0.08,1.18,0.08,1.77,0.02c0.88,0,1.64-0.06,2.68-0.14l1.45-0.1c1.33-0.09,2.71-0.19,4.07-0.19c5.08,0,8.13,1.51,9.33,4.62
					c1.78,4.58-3.11,8.26-8.26,11.63l-0.18,0.12c-1.82,1.15-2.36,1.56-2.63,1.79c-4.67,4.03-6.57,9.79-8.59,15.89l-0.04,0.14
					c-0.47,1.46-0.94,2.87-1.42,4.25l-0.54,1.48c-3.66,9.14-6.44,18.67-8.26,28.34l-0.02,0.12c-1.24,9.75-1.91,19.67-2,29.52l0,2.64
					c-0.31,10.08-3.76,19.38-7.08,28.37l-0.64,1.76c-0.83,2.26-1.68,4.59-2.44,6.9c-1.51,4.53-3.01,10.16-1.19,13.6
					c0.79,1.38,2.13,2.34,3.68,2.64c1.4,0.38,2.84,0.58,4.3,0.59c7.6,0,14.12-5.42,20.4-10.64l0.8-0.65
					c4.99-4.15,6.18-6.97,5.55-13.16c-3.09-29.05,3.69-54.89,10.72-76.32c1.52-4.6,3.38-8.96,5.47-13.79l1.2-2.83
					c0.29-0.68,0.58-1.37,0.86-2.05l0.26-0.66c1.58-4.62,3.97-8.86,7.11-12.6c3.12-2.75,6.72-4.75,10.69-5.97l0.13-0.04
					c8.79-3.38,12.43-5.54,10.41-12.19c-1.25-3.73-3.43-7.12-6.3-9.81c-0.36-0.34-0.74-0.65-1.09-0.9c-2.99-2.25-5.82-4.7-4.87-7.6
					c0.69-2,2.64-3.23,4.74-2.99l0.2,0.01c1.8,0.02,3.56,0.37,5.24,1.03c3.52,1.88,6.54,4.62,8.75,7.94l0.09,0.12
					c0.92,1.17,1.75,2.22,2.61,3.15l0.16,0.15c2.7,2.34,5.96,3.98,9.44,4.75l0.25,0.08c0.82,0.26,1.87,0.51,2.97,0.77l0.47,0.1
					c3.21,0.48,6.31,1.69,8.99,3.52c2.86,2.49,2.94,5.91,3.03,9.27c0.08,3.07,0.13,5.1,2.12,5.95c0.72,0.29,1.46,0.53,2.22,0.69
					l2.08,0.45l0.32-2.1c0.17-1.16,0.29-2.34,0.34-3.46l0.04-0.61c0.07-0.98,0.14-1.99,0.28-2.92c0.79-4.5,1.91-8.98,3.31-13.27
					c2.14-5.97,3.36-12.21,3.62-18.55l0-0.11l-0.01-0.11c-0.17-2.65-0.64-5.28-1.39-7.81c-1.17-4.62-2.38-9.41-0.79-14.29
					c1.87-5.44,8.83-7.96,13.99-9.82c0.47-0.19,0.97-0.37,1.45-0.55c0.67-0.25,1.31-0.48,1.94-0.75c4.72-2.22,9.75-3.39,14.93-3.48
					c1.08,0,2.16,0.06,3.16,0.17c6.44,0.97,12.77,2.71,18.86,5.19c2.81,1.03,5.71,2.1,8.67,2.99l1.12,0.46l0.32,0.1
					c8.06,1.81,11.21,3.32,12.05,5.76c0.4,1.19,0.13,2.52-0.75,3.51c-1.59,1.92-4.98,2.17-8.08,2.17l-4.37,0
					c-6.02-0.23-12.1-0.82-18.1-1.73l-0.45-0.06c-3.26-0.44-6.63-0.89-9.95-1.2c-2.11-0.21-3.48-0.29-4.71-0.29
					c-2.66,0-4.28,0.44-5.11,1.4l-0.15,0.18l-0.11,0.21c-0.69,1.31-0.93,2.82-0.68,4.27c0.24,5.34,0.13,10.75-0.33,16.11l-0.04,0.64
					c-0.23,3.45-0.44,6.72-0.44,10.03c-0.11,10.39,0.41,20.9,1.55,31.24c1.19,10.29,3.73,22.02,13.61,31.01
					c2.24,2.04,4.74,3.9,7.16,5.7c8.69,6.46,17.68,13.15,14.22,27.43c-1.9,8-4.27,15.96-7.05,23.64c-1.92,4.96-4.15,9.85-6.65,14.59
					l-0.24,0.48c-1.58,3.17-3.21,6.46-4.63,9.76c-1.79,4.44-3.32,9.04-4.55,13.67c-1.22,4.54-2.71,9.05-4.39,13.34
					c-3.55,7.98-8,15.58-13.25,22.66l-1.2,1.76c-9.17,13.14-18.65,26.72-18.49,43.04c0.02,1.1,0.06,2.39,0.21,3.75
					c0.37,2.98,1.14,5.9,2.28,8.63c0.75,1.92,1.4,3.9,1.95,5.9c1.32,6.44,1.08,13.18-0.7,19.54c-0.98,3.73-2.75,5.71-5.11,5.71
					c-2,0-3.84-1.49-5.44-4.36c-3.16-6.16-5.24-12.77-6.18-19.67c-0.97-6.28-2.82-8.96-6.17-8.96h-0.4c-0.01,0-0.02,0-0.03,0
					c-1.55,0-3.02,0.81-3.79,2.06c-3.8,5.55-0.82,20.02,1.29,26.9l0.04,0.13c1.69,4.42,4,8.58,6.85,12.36l0.73,1.07
					c0.54,0.79,1.08,1.58,1.61,2.42c7.94,11.86,15.01,24.44,20.98,37.33c1.12,2.68,2.01,5.47,2.65,8.37l0.04,0.15
					c1.25,4.92,2.43,9.56,6.49,12.8l1.4,1.12l1.27-1.28c1.59-1.61,2.56-3.73,2.74-6.05c0.21-5.02-2.06-10.82-4.46-16.97
					c-4.2-10.58-9.43-23.75,1.5-33.34c3.2-2.84,7.32-4.36,11.6-4.27c4.01,0.09,7.88,1.01,11.53,2.74
					c11.78,5.38,20.87,14.52,29.7,23.4c0.98,0.95,1.97,1.92,2.97,2.89c1.97,1.93,4.01,3.93,6.02,5.78c0.92,0.85,1.88,1.75,2.87,2.72
					c5.3,5.11,12.56,12.1,19.95,12.25c0.59,0.04,1.17-0.04,1.73-0.24l0.5-0.18l0.35-0.41c0.93-1.08,1.32-3.09,0.58-10.15l-0.01-0.1
					c-0.42-2.65-0.47-5.34-0.17-8.01c0.46-2.06,1.08-4.11,1.87-6.13c2.06-5.7,3.1-9.34,0.17-11.84l-0.08-0.06
					c-1.34-1.03-2.77-1.93-4.21-2.65l-0.13-0.07c-3.67-2.03-7.83-4.33-8.89-9.4c-0.69-3.26-0.41-5.7,0.81-7.23
					c0.92-1.1,2.22-1.71,3.7-1.71c3.01,0.34,5.95,1.37,8.5,2.98l1.63,0.83c2.88,1.52,5.95,2.67,9.11,3.42l0.11,0.02
					c0.77,0.14,1.55,0.21,2.31,0.21c1.38-0.01,2.79-0.11,4-0.23c1.01-0.1,2.54-0.23,4.2-0.23c2.5-0.08,4.98,0.51,7.1,1.7
					c3.37,2.05,4.83,6.65,5.8,9.72l0.71,2.14c2.18,6.85,3.71,10.73,9.93,10.9l0.13,0l0.13-0.01c1.58-0.16,3.1-0.72,4.38-1.58
					c1.76-1.13,3.79-1.76,5.78-1.85c1.27,0.01,2.5,0.25,3.67,0.74c2.56,1.35,5.32,2.14,8.22,2.35l0.12,0
					c0.83,0.01,1.69-0.06,2.48-0.19c8.09-1.21,17.21-6.5,18.46-13.26c1.75-9.39,8.86-11.69,15.75-13.92l2.79-0.91l0.1-0.04
					c0.48-0.19,1-0.4,1.53-0.62l0.8-0.34c2.12-1.05,4.39-1.63,6.66-1.74h1.17c2.23,0.36,4.34,1.12,6.26,2.25
					c1.54,0.95,3.22,1.6,4.99,1.95l3.92,0.04l-0.27-2.24c-0.08-0.65-0.22-1.33-0.35-1.92l-0.13-0.58c-0.65-2.17-0.76-4.48-0.3-6.7
					c1.61-5.46,7.47-7.84,12.02-8.22l0.78-0.07c0.92-0.09,1.86-0.18,2.78-0.18c3.46-0.07,6.91-0.53,10.26-1.38l0.18-0.05
					c2.34-0.83,4.43-2.27,6.05-4.15c1.44-1.53,3.08-2.86,4.84-3.95c1.95-1.17,6.84-2.03,11.63-2.03c6.94,0,10.85,1.66,11.63,4.94
					c0.72,3.04-2.29,5.87-6.92,9.81c-0.8,0.65-1.58,1.35-2.31,2.08l-0.13,0.15c-2.92,3.56-5.66,7.34-8.14,11.2l-0.8,1.2
					c-1.11,1.74-2.44,3.48-3.74,5.16l-0.48,0.63c-3.4,4.41-6.92,8.96-8.36,13.89l-0.05,0.2c-0.48,2.65-0.38,5.43,0.3,8.04
					c0.84,4.06,1.43,8.39-1.08,12.83c-2.55,4.19-5.57,8.1-9.05,11.68l-0.66,0.74c-1.42,1.58-2.76,3.08-4.04,4.68
					c-3.59,3.91-5.85,8.75-6.56,14.04c-0.22,1.87-0.33,3.7-0.44,5.89l0,0.06l0,0.06c0.06,5.31-0.95,10.49-2.97,15.32l-0.66,1.41
					c-3.76,7.8-6.48,13.43-2.86,22.25c3.37,8.19,10.11,15.2,16.06,21.38l1.95,2.01c7.17,7.42,9.87,17.14,12.46,26.49
					c1.31,5.34,3.09,10.61,5.3,15.72c4.51,9.62,9.86,15.13,14.67,15.13l0.22-0.01c2.68-0.3,5.18-1.68,6.87-3.79
					c2.07-2.35,4.93-3.78,8.05-4.04c0.01,0,0.03,0,0.05,0c1.56,0,3.05,0.72,4.02,1.93c1.44,2.24,1.75,5.07,0.8,7.67
					c-1.69,5.43-4.42,10.38-8.14,14.74c-0.67,0.82-1.36,1.56-2.21,2.44c-2.56,2.71-3.85,4.07-3.09,7.38
					c1.11,4.72,6.61,14.17,10.94,14.17c1.1,0,1.93-0.69,2.54-1.3l0.11-0.12c3.62-4.28,6.44-9.12,8.38-14.36
					c2.12-5.5,5.08-10.6,8.79-15.14c1.7-2.02,4.18-3.2,6.82-3.24c2.53,0.16,4.95,0.82,7.21,1.94c1.99,1.01,4.12,1.64,6.34,1.87
					l0.18,0.01c0.86,0,1.75-0.16,2.56-0.51l0.11-0.05c1.35-0.67,2.67-1.45,3.9-2.31l0.14-0.1c2.36-1.96,5.22-3.18,8.27-3.51
					c2,0.03,4.81,0.98,7.08,5.24c3.01,5.71,4.7,16.27,2.2,23.33c-1.1,2.82-2.53,5.51-4.25,7.95c-1.8,2.46-3.2,5.16-4.17,8.04
					l-0.07,0.27c-0.62,3.31-0.62,6.67,0,10c0.85,4.34,0.62,8.86-0.67,13.09c-2,5.25-7.07,7.86-11.52,10.15
					c-1.62,0.79-3.21,1.69-4.75,2.7c-6.34,4.35-8.43,13.06-10.3,20.82l-0.5,2.03c-2.26,9.17-3.75,20.32,4.98,25.26l0.11,0.06
					c1.88,0.92,3.85,1.68,5.86,2.27c4.5,1.6,8.57,3.32,9.01,6.56c0.36,2.68-1.59,5.31-6.32,8.51c-4.19,2.78-7.91,6.19-11.05,10.13
					c-4.91,6.21-13.06,18.61-12.51,31.95c0.28,7.07,4.36,12.9,8.67,19.07l2.56,3.66c2.08,3.12,3.83,6.39,5.52,9.54l0.14,0.27
					c2.69,5.83,6.3,11.13,10.74,15.77l1.17,1.08c7.14,6.39,11.84,11.54,11.59,15.96c-0.17,2.78-2.47,4.95-5.27,4.95
					c-0.01,0-0.03,0-0.04,0c-4.46,0-10.05-5.22-12.65-8.3c-1.91-2.26-3.78-4.57-5.59-6.8l-0.44-0.54c-2.6-3.2-5.28-6.5-8.08-9.59
					c-8.69-9.6-13.54-11.03-15.66-11.03c-3.79,0-5.17,4.76-5.68,6.54c-0.96,3.31,1.1,6.35,3.47,9.14c1.17,1.36,2.43,2.68,3.71,3.89
					l0.1,0.1c5.1,5.1,11.45,11.45,8.11,19.99c-2.02,4.9-6.74,8.06-12.08,7.99c-3.08,0-6.02-1.12-8.21-3.15
					c-2.55-2.62-4.59-5.64-6.06-8.99c-1.19-3.28-3.11-6.33-5.55-8.83l-0.21-0.18c-3.39-2.61-7.61-3.96-11.83-3.81
					c-1.72,0.01-3.45,0.17-5.23,0.48c-5.72,1.24-11.33,3.11-16.58,5.51l-0.1,0.04c-7.36,2.99-14.96,6.09-23.15,6.29l-1.71,0
					c-9.14,0.21-18.14-2.09-26.03-6.61l-0.32-0.19c-2.73-1.64-6.47-3.88-9.53-3.92c-1.46-0.1-2.84,0.64-3.62,1.97
					c-0.98,1.85-1.66,3.83-2.01,5.89c-1.09,4.77-2.34,8.88-6.32,10.84c-3.26,1.43-6.71,2.23-10.3,2.39
					c-2.34,0.14-4.67,0.55-6.92,1.2l-0.22,0.08c-2.36,0.99-4.52,2.35-6.41,4.03c-2.28,1.97-4.86,3.53-7.59,4.62
					c-1.18,0.42-2.42,0.63-3.68,0.64l-0.11,0c-2.2,0.14-4.34-0.88-5.62-2.67c-1.01-1.78-1-3.97,0.02-5.7
					c1.54-2.5,3.54-4.62,5.93-6.31l0.24-0.19c4.63-3.76,5.96-5.31,5.96-6.91v-0.39l-0.14-0.36c-0.01-0.02-0.01-0.03-0.02-0.05
					l0.29-1.64l-2.04-0.27c-0.36-0.05-0.72-0.05-1.03-0.01c-2.38,0.25-4.73,0.81-6.96,1.65c-2.7,1-5.53,1.6-8.31,1.78
					c-1.31,0.03-2.59-0.29-3.71-0.88c-5.55-3.05-8.28-9.81-10.1-14.29c-0.38-0.91-0.75-1.8-1.12-2.6
					c-4.17-9.38-8.73-13.38-15.25-13.38c-0.97,0.01-1.95,0.1-2.84,0.26c-1.63,0.24-3.3,0.35-4.96,0.33c-2.16,0-4.47-0.12-6.66-0.23
					c-2.63-0.14-4.99-0.26-7.31-0.26c-8.57,0-13.12,1.8-15.21,6c-1.19,2.35-0.47,4.1,1.02,7.27c0.47,0.97,0.92,2,1.29,2.96
					c2.25,5.94,1.52,11.68,0.75,17.76l-0.02,0.15c-0.79,6.2-1.53,12.05,1.28,17.46c4.26,8.33,13.47,18.39,25.4,19.3l0.26,0.02
					l0.26-0.05c1.7-0.32,3.38-0.79,5.04-1.43c2.45-1,5.03-1.61,7.61-1.82c1.44-0.07,2.8,0.55,3.67,1.68c0.94,1.77,1,3.89,0.17,5.72
					l-0.06,0.15c-0.62,1.71-1.23,3.43-1.85,5.22c-2.54,8.29-6.11,16.25-10.57,23.61c-7.54,11.52-18.23,19.68-28.63,27.62
					c-1.28,1.01-2.57,2-3.86,3c-2.81,2.17-5.72,4.41-8.44,6.74l-0.92,0.77c-15.37,12.81-20.09,19.26-21.35,22.41l-1.09,2.74l3.25,0
					c0.54-0.01,1.07-0.08,1.69-0.23c1.16-0.33,2.3-0.75,3.34-1.22l1.94-0.82c4.49-1.69,9.36-2.15,13.98-1.35
					c3.54,0.8,6.86,2.25,9.88,4.34c1.76,1.17,3.64,2.17,5.59,2.99l4.95,2.07l-2.3-4.64l-0.03-0.28c-0.05-0.3-0.11-0.6-0.17-0.9
					c-0.15-0.78-0.3-1.52-0.3-2.19l0-0.12c-1.25-19.97,18.95-25.17,38.92-27.63c1.56-0.19,3.14-0.28,4.71-0.29
					c10.35,0,17.6,4.6,19.91,12.63c1.1,4.94,1.16,9.96,0.19,14.92l-0.02,0.1c-0.93,6.58-1.4,11.97,1.62,16.63l0.11,0.15
					c1.35,1.71,2.9,3.26,4.6,4.61c3.73,3.33,7.94,7.11,7.94,11.71c-0.14,2.85-1.54,5.45-3.86,7.12l-0.1,0.08
					c-10.87,8.99-9.14,17.91-2.48,30.26c6.56,12.25,7.5,26.73,2.78,43.04c-6.03,20.7-17.94,38.88-32.63,49.83
					c-3.72,2.57-7.77,4.62-12.04,6.11l-0.12,0.04c-4.99,2-9.71,3.89-13.29,7.62c-6.15,6.64-13.96,11.63-22.5,14.42
					c-3.89,1.13-7.91,1.96-11.86,2.46c-5.6,0.52-11.03,1.96-16.16,4.27l-0.14,0.07c-3.86,2.13-6.27,5.55-8.82,9.16l-0.09,0.14
					c-2,3.05-4.4,5.82-7.08,8.2c-7.78,6.42-17.56,9.37-24.22,11.03c-1.06,0.25-2.16,0.38-3.17,0.38c-0.01,0-0.01,0-0.02,0
					c-3.2-0.14-6.37-0.72-9.41-1.72c-2.56-0.84-5.22-1.37-7.88-1.56c-2.12-0.2-4.2,0.74-5.46,2.44l-0.06,0.09
					c-1.17,1.78-2,3.74-2.44,5.79c-0.53,2.29-1.41,4.48-2.62,6.5c-2.51,3.43-5.88,6.17-9.75,7.93l-0.11,0.05
					c-1.74,0.92-3.68,1.78-5.25,2.47c-5.11,2.26-10.4,4.6-13.23,9.25c-0.83,1.2-1.39,2.6-1.63,4.05l-0.2,1.21l0.99,0.73
					c1.36,1,4.37,1.49,9.2,1.49h5.12l0.1,0c4.32-0.22,8.69,0.58,12.64,2.32c2.85,1.26,4.99,3.82,5.73,6.81
					c1.6,6.93-3.98,14.98-9.34,21.98l-0.5,0.66c-1.73,2.27-1.98,3.78-1.98,4.47l0.03,0.36c0.29,1.61,2.01,3.13,6.63,5.88
					c5.28,3.14,6.75,7.38,8.18,11.54l0.27,0.73c0.45,1.2,0.91,2.44,1.48,3.7c2.16,4.91,6.06,8.79,10.92,10.9
					c3.47,1.63,7.17,2.5,10.93,2.58c4.06,0.26,8.07-1.43,10.75-4.48l0.13-0.17c0.86-1.22,1.57-2.54,2.07-3.84
					c1.4-3.15,3.15-7.07,7.87-8.01c1.02-0.19,2.05-0.31,3.06-0.27c1.64,0.03,3.29,0.15,4.96,0.36l0.21,0.02
					c1.45,0.15,2.82,0.29,4.08,0.29c3.2,0,5.07-0.78,5.75-4.89l0.02-0.14c0.12-1.27,0.12-2.55,0-3.79c-0.17-1.97-0.03-3.94,0.4-5.84
					c0.97-4,4.67-6.74,8.86-6.55l0.3,0c3.56,0.09,7.14,0.46,10.63,1.11c3.27,0.62,6.61,0.99,9.99,1.09h0.21
					c3.07,0.22,6.12-0.45,8.82-1.93l0.27-0.18c1.47-1.15,1.72-2.33,2.06-4.48l0.01-0.11c0.21-2.06,0.94-4.05,2.07-5.71
					c1.47-2.03,5.44-6.74,10.64-6.74c0.72,0,1.43,0.1,2.12,0.28c3.42,0.91,6.57,3.19,4.01,13.32l-0.23,0.84
					c-1.51,6.05-1.34,10.93,4.38,15.07l0.1,0.07c3.78,2.4,8.3,3.54,12.77,3.23c2.53,0,5.12-0.22,7.35-0.42l1.67-0.24
					c1.32-0.24,2.66-0.38,4.06-0.4c1.51-0.07,3.01,0.37,4.25,1.24l0.12,0.08c2.05,1.23,3.13,3.64,2.71,5.93
					c-1.03,4.84-8.42,7.6-10.61,8.15l-0.17,0.04c-0.93,0.22-2,0.43-3.22,0.63l-0.46,0.08c-3.66,0.65-7.81,1.39-9.14,4.43
					c-1.29,2.92-1.4,6.18-0.31,9.17c0.82,2.32,2.57,4.24,4.88,5.31c3.1,1.28,6.39,1.87,9.73,1.77c9.39-0.32,18.67-2.92,26.91-7.58
					c1.17-0.72,2.44-1.53,3.75-2.38l0.61-0.39c5.73-3.69,12.23-7.87,19.57-7.87c1.21-0.02,2.45,0.12,3.65,0.37
					c10.96,2.28,17.86,13.11,22.9,21l1.55,2.46c7.8,12.24,15.86,24.9,28.51,31.76c7.5,4.06,16.83,4.7,25.05,5.27l3.1,0.23
					c2.96,0.34,5.59,0.34,7.9,0.34c11.79,0,19.86-2.71,25.38-8.53l0.08-0.09c2.13-2.53,3.86-5.36,5.13-8.39
					c1.39-3.23,3.23-6.25,5.44-8.95c4.7-5.58,10.03-10.66,15.81-15.07c3.25-2.41,7.27-3.72,11.32-3.63c2.49,0.06,5,0.29,7.53,0.7
					l0.98,0.13c1.51,0.21,3.06,0.32,4.62,0.32c6.38-0.05,12.69-1.72,18.23-4.83c10.76-5.86,19.27-15.29,27.5-24.41l0.17-0.19
					c3.43-3.88,6.28-6.97,8.97-9.72c2.28-2.36,7.78-5.66,11.62-5.66c1.54-0.08,2.97,0.68,3.77,1.92c1.44,2.29,0.22,5.42-0.78,7.5
					c-1.89,3.54-4.23,6.82-7,9.81c-1.25,1.43-2.39,2.84-3.62,4.47l-2.14,2.97l-0.42,0.57c-8.08,10.81-16.43,21.99-26.89,31.18
					l-0.9,0.8c-1.84,1.63-3.59,3.16-5.45,4.69c-9.76,8.34-18.2,15.53-24.37,27.69c-5.38,10.56-10.04,21.61-13.83,32.83
					c-3.99,11.72-6.33,16.91-19.94,16.91l-4.84,0c-6.11,0.14-12.28,0.74-18.31,1.78c-6.19,1.04-12.5,1.64-18.74,1.79
					c-3.89,0.05-7.81-0.36-11.6-1.14c-5.41-1.19-10.86-2.71-16.44-4.29c-10.74-3.04-21.84-6.19-33.29-6.19h-0.8
					c-13.33,0.17-26.76,1.67-39.74,3.12l-9.85,1.06c-25.12,2.64-37.5,10.55-38.98,24.88c-0.06,0.58-0.11,1.13-0.16,1.69l-0.15,1.59
					c-0.77,8.69-1.73,19.51-8.93,26.06c-3.67,3.26-8.4,4.92-13.32,4.73c-3.24-0.08-6.5-0.41-9.68-0.99
					c-2.81-0.51-5.68-0.82-8.52-0.92c-3.37-0.28-6.67,0.91-9.13,3.3c-5.58,5.73-4.66,17.73-3.92,27.37l0.13,1.65
					c0.18,2.18,0.35,4.23,0.35,6.07c0.27,17.11-8.54,26.62-17.09,35.84c-3.81,4.29-7.33,8.91-10.46,13.67
					c-4.96,7.17-10.09,14.58-17.45,20.23c-3.83,2.94-7.97,5.67-11.56,8.05c-6.5,3.97-12.62,8.61-18.24,13.84
					c-6.33,6.31-9.45,14.23-12.77,22.68c-1.94,5.39-4.35,10.67-7.14,15.62c-7.05,11.81-19.27,23.78-36.3,35.55
					c-9.05,6.18-18.72,8.92-28.01,11.55c-7.31,1.79-14.44,4.36-21.26,7.67c-5.56,2.91-14.11,12.13-14.82,19.86
					c-0.42,2.95,0.81,5.86,3.19,7.63c1.39,1.2,3.16,1.9,5.08,1.97c4.56,0,9.44-4.57,13.82-8.67c2.42-2.5,5.15-4.69,8.05-6.47
					c3.09-1.72,6.59-2.67,10.09-2.73c12.96,0,22.21,13.67,29.61,24.61c2.14,3.33,4.5,6.56,6.97,9.58c2.11,2.75,5.31,4.4,8.78,4.52
					l0.07,0c6.97,0,14.61-6.76,17.78-15.74c1.45-4.19,0.06-8.12-1.41-12.28l-0.06-0.17c-1.95-5.55-4.15-11.83,0.86-18.62
					c7.32-9.81,20.35-14.78,30.83-18.78l1.25-0.48c2.23-0.86,4.33-1.68,6.4-2.55c6.69-2.86,10.03-8.48,13.27-13.92l0.19-0.33
					c3.09-5.19,6.59-11.08,13.03-14.6c3.63-1.85,7.69-2.77,11.83-2.59c3.52,0.07,7.08,0.36,10.56,0.84
					c2.93,0.42,5.92,0.67,8.93,0.75l0.75,0c5.09-0.15,10.05-0.72,15.31-1.33l0.62-0.07c5.94-0.82,11.99-1.31,17.93-1.47h1.38
					c25.51,0,48.15,6.2,67.31,18.44c1.82,1.12,3.81,2.69,5.92,4.34c0.57,0.45,1.14,0.9,1.7,1.33c8.04,6.44,15.84,12.22,22.85,12.22
					c1.3,0.01,2.68-0.23,3.95-0.71c6.76-2.57,11.08-10.51,15.3-18.27c2.23-4.47,4.96-8.71,8.06-12.54
					c5.32-6.09,5.08-13.82,4.81-22.08c-0.36-4.95-0.1-9.94,0.76-14.76c3.96-18.3,11.08-30.62,21.72-37.62
					c4.82-2.97,9.94-5.52,15.27-7.6c3.34-1.41,6.79-2.87,9.98-4.54c11-5.71,22.8-9.5,35.06-11.25c4.07-0.56,8.06-1.43,11.65-2.2
					c6.22-1.58,12.62-2.49,18.97-2.71c0.08,0,0.16,0,0.23,0c6.55,0,13.04,1.67,18.76,4.82c4.81,2.73,9.51,5.77,14,9.04
					c8.47,5.96,16.47,11.58,26.35,13.68c1.82,0.36,3.68,0.57,5.62,0.53c4.88-0.2,9.76-0.89,14.44-2.03
					c3.19-0.67,6.8-1.42,10.25-1.79c1.35-0.15,2.74-0.22,4.02-0.23c7.28,0.33,14.47,1.66,21.37,3.96l1.05,0.29
					c2.39,0.66,4.87,1.34,7.25,1.91c7.43,1.91,15.08,3,22.8,3.24c7.01,0,10.04-1.47,11.34-2.69c2.96-2.74,1.08-6.13-2.24-11.44
					c-4.17-6.68-7.32-13.11-0.21-18.28c6.11-4.45,16.07-5.02,24.07-5.02h8.64c1.44,0.02,2.89,0.07,4.35,0.12
					c1.86,0.07,3.82,0.1,5.66,0.13c3.5,0.12,7.86,0.26,12,0.26c11.86,0,25.55-1.08,37.16-9.17c2.91-2.18,4.48-7.3,5.86-11.83
					c2.08-6.69,4.45-14.27,11.58-14.94c0.3-0.02,0.59-0.02,0.9,0l0.11,0c5.85,0,10.99,5.12,15.58,9.71
					c2.18,2.44,4.67,4.58,7.41,6.36l0.19,0.11c16.3,8.16,40.87,12.5,58.06,13.18h2.62c12.23,0,24.42-3.21,36.28-6.33l0.65-0.17
					c7.23-1.9,14.71-3.86,22.16-5.04c10.97-1.8,22.17-2.73,33.29-2.76c0.22,0,0.44,0,0.67,0c14.35,0,28.66,1.84,42.53,5.47
					c3.22,0.86,6.63,2.04,10.24,3.29l1.73,0.6c8.99,3.17,18.29,6.44,26.74,6.44c2.75,0.06,5.49-0.37,8.14-1.26
					c7.88-2.78,14.4-7.53,21.3-12.57l4.06-2.96c7.38-5.36,15.96-7.51,24.27-9.58l1.41-0.36c2.58-0.66,5.24-1.34,7.89-2.17
					c6.13-2.15,12.01-5.03,17.4-8.54c3.33-2,6.6-3.93,10.04-5.63c4.03-1.99,8.56-3.01,13.08-2.91h0.43c1.58-0.01,3.06,0,4.63,0.18
					c1.95,0.23,3.89,0.23,5.94,0.23h0.76c7.55-0.09,8.23-3.88,8.93-11.76l0.01-0.13c0.1-4.22,1.07-8.29,2.87-12.1
					c4.49-8.11,12.98-12.17,20.69-15.08c3.97-1.49,8.44-2.7,12.4-3.77c12.42-3.37,25.27-6.86,30.84-17.53
					c4.58-8.79,3.11-18.24,1.54-28.29c-0.78-4.42-1.29-8.93-1.53-13.38c-0.13-3.94,1.83-8.08,3.56-11.72
					c2.34-4.79,2.8-6.46,2.64-7.57c-0.05-0.78-0.59-2.42-6.38-3.01l-2.36-0.01l-0.13,0c-6.02,0.41-11.89,1.79-17.39,4.1
					c-4.27,1.63-8.71,2.9-13.16,3.76l-1.53,0.26c-15.11,2.77-28.03,4.29-36.38,4.29c-6.9,0-10.99-1-12.87-3.16
					c-0.9-1.03-1.3-2.4-1.08-3.78c0.48-3.37,3.29-5.28,5.56-6.67h5.42l-4.53-3.57c-0.47-0.37-1.01-0.73-1.67-1.17
					c-4.43-3.11-9.63-4.89-15.12-5.16c-3.33,0.02-6.61,0.93-9.48,2.82c-1.98,1.42-3.87,3.01-5.63,4.7
					c-4.85,4.4-10.34,9.38-16.27,9.38c-3.54-0.08-6.82-1.73-8.99-4.54l-0.13-0.15c-1.5-1.58-2.2-3.72-1.94-5.83
					c0.65-4.36,5.52-7.46,10.27-10.48l0.9-0.6c3.85-2.55,5.7-3.93,6.29-5.3c0.41-0.87,0.68-1.79,0.79-2.75l0.2-1.69l-1.64-0.47
					c-1.31-0.38-2.68-0.53-4.04-0.47c-1.86,0-3.79,0.18-5.83,0.36l-0.44,0.04c-1.72,0.21-3.48,0.32-5.17,0.35
					c-6.45-0.05-9.85-1.68-10.08-4.82c-0.35-4.84,8.69-9.52,14.81-10.48c2.15-0.32,4.35-0.48,6.48-0.45
					c3.77,0.06,7.57,0.32,11.35,0.77l0.62,0.06c3.5,0.35,6.81,0.68,10.11,0.68c5.52,0.29,10.9-1.15,15.61-4.18
					c5.56-3.87,10.17-11.2,13.57-17.17c4.89-8.63,9.59-21.07,6.72-27.74c-1.21-2.68-3.67-4.52-6.62-4.92
					c-0.4-0.05-0.8-0.05-1.21-0.01c-3.02,0.06-6.61,2.3-10.09,4.48c-3.97,2.5-8.09,5.08-12.3,5.08c-0.01,0-0.03,0-0.04,0
					c-2.69,0-5.29-1.12-7.16-3.11c-0.57-0.58-0.81-1.4-0.66-2.18c0.33-1.63,0.4-1.97,20.54-10.09l4.21-1.73l1.83-0.83
					c8.18-3.8,16.65-7.72,26.52-7.72h3.37c19.02,0,23.65-10.7,30.06-25.53l0.58-1.32c1.67-3.81,3.4-7.76,5.55-11.76
					c6.53-12.15,16.93-23.35,32.72-35.23c13.56-10.19,17.23-22.55,21.92-38.28c0.64-2.15,1.29-4.3,1.98-6.47
					c2.32-7.54,5.29-15.24,7.89-22.01c4.3-10.52,7.9-21.44,10.71-32.5C1578.13,2006.91,1577.64,1988.1,1573.36,1968.95z
					 M1572.88,2021.48c-2.78,10.96-6.33,21.71-10.61,32.18c-2.79,7.24-5.66,14.74-7.95,22.17c-0.69,2.16-1.34,4.32-1.99,6.48
					c-4.56,15.32-8.15,27.45-21.2,37.26c-16.04,12.06-26.61,23.45-33.28,35.88c-2.44,4.54-4.32,8.95-6.2,13.23
					c-6.33,14.65-10.5,24.34-28.23,24.34h-1.88h-1.49c-10.31,0-18.98,4.02-27.36,7.91l-1.82,0.82l-4.15,1.71
					c-21.12,8.52-21.18,8.8-21.74,11.54c-0.28,1.44,0.16,2.93,1.19,3.98c2.24,2.38,5.37,3.73,8.65,3.72c4.75,0,9.12-2.72,13.36-5.38
					c3.29-2.05,6.68-4.17,9.14-4.17c0.28-0.03,0.56-0.03,0.84,0c2.21,0.3,4.12,1.72,5.04,3.76c2.42,5.62-1.6,17.05-6.63,25.94
					c-3.54,6.22-7.87,12.97-12.97,16.51c-4.28,2.76-9.33,4.09-14.42,3.83c-3.41,0-6.89-0.37-10.59-0.73
					c-3.82-0.46-7.67-0.72-11.52-0.78c-2.28-0.02-4.55,0.14-6.81,0.48c-6.18,0.97-16.99,6.01-16.51,12.6
					c0.32,4.32,4.32,6.61,12.06,6.68c1.81-0.03,3.62-0.15,5.42-0.37c2.12-0.19,4.11-0.38,5.97-0.39c0.04,0,0.08,0,0.12,0
					c1.16-0.06,2.32,0.07,3.44,0.39c-0.09,0.75-0.3,1.48-0.63,2.16c-0.5,1.21-4.32,3.61-6.48,5.06c-5.1,3.24-10.35,6.61-11.13,11.84
					c-0.34,2.74,0.56,5.49,2.46,7.5c2.53,3.27,6.39,5.23,10.53,5.32c6.77,0,12.58-5.3,17.7-9.94c1.69-1.63,3.5-3.14,5.4-4.52
					c2.46-1.62,5.35-2.47,8.3-2.44c5.02,0.25,9.87,1.91,13.98,4.8c0.65,0.43,1.17,0.78,1.58,1.1h-0.22
					c-2.53,1.53-6.33,3.85-6.98,8.39c-0.3,1.94,0.27,3.91,1.56,5.38c2.31,2.66,6.74,3.85,14.37,3.85c12.56,0,30.84-3.24,36.74-4.32
					l1.51-0.26c4.61-0.9,9.14-2.19,13.53-3.87c5.35-2.24,11.03-3.59,16.81-3.98c0.71,0,1.43,0,2.16,0c3.83,0.39,4.58,1.21,4.58,1.23
					c0.24,0.93-1.45,4.45-2.44,6.48c-1.84,3.87-3.91,8.23-3.76,12.67c0.24,4.58,0.76,9.14,1.56,13.66
					c1.51,9.68,2.94,18.8-1.34,27.02c-5.17,9.9-17.59,13.27-29.61,16.53c-4.47,1.21-8.65,2.36-12.56,3.83
					c-8.08,3.05-16.97,7.33-21.76,16.04c-1.93,4.06-2.98,8.48-3.09,12.97c-0.73,8.26-1.38,9.88-6.96,9.94h-0.73
					c-1.99,0-3.87,0-5.71-0.22c-1.84-0.22-3.57-0.19-5.29-0.19c-4.84-0.09-9.62,0.97-13.96,3.11c-3.59,1.77-6.94,3.76-10.18,5.71
					c-5.32,3.46-11.04,6.27-17.03,8.36c-2.92,0.91-5.96,1.66-8.9,2.42c-8.64,2.16-17.42,4.32-25.18,9.96l-4.06,2.96
					c-6.79,4.95-13.18,9.62-20.79,12.3c-2.39,0.8-4.91,1.19-7.43,1.15c-8.15,0-17.29-3.22-26.13-6.33c-4.32-1.49-8.3-2.92-12.1-3.93
					c-14.27-3.73-28.97-5.59-43.72-5.53c-11.26,0.03-22.5,0.97-33.61,2.79c-7.76,1.23-15.5,3.29-23,5.25
					c-11.74,3.09-23.77,6.27-35.77,6.27c-0.86,0-1.75,0-2.62,0c-16.88-0.67-41.06-4.91-57.17-12.97c-2.61-1.69-4.98-3.73-7.05-6.05
					c-4.84-4.84-10.31-10.33-17.03-10.33c-0.37-0.02-0.75-0.02-1.12,0c-8.54,0.8-11.22,9.42-13.38,16.36
					c-1.36,4.45-2.81,9.06-5.14,10.81c-11.11,7.74-24.42,8.78-35.96,8.78c-3.96,0-8.02-0.13-11.93-0.26
					c-3.39,0-6.74-0.22-10.05-0.26h-3.05c-1.75,0-3.65,0-5.62,0c-8.3,0-18.65,0.6-25.24,5.4c-9.21,6.7-3.63,15.63-0.3,20.96
					c3.85,6.16,4.02,7.56,2.57,8.9c-0.71,0.67-3.09,2.16-9.99,2.16c-7.53-0.23-15.01-1.3-22.3-3.18c-2.7-0.65-5.49-1.43-8.17-2.16
					c-7.08-2.36-14.46-3.73-21.92-4.06c-1.44,0-2.89,0.08-4.32,0.24c-3.48,0.37-7.02,1.1-10.44,1.82
					c-4.63,1.12-9.35,1.79-14.11,1.99c-1.73,0.01-3.45-0.16-5.14-0.5c-9.44-2.01-17.29-7.52-25.59-13.36
					c-4.54-3.31-9.27-6.36-14.16-9.14c-6.12-3.37-13-5.12-19.99-5.08c-6.56,0.22-13.09,1.15-19.45,2.77
					c-3.7,0.8-7.52,1.62-11.43,2.16c-12.46,1.78-24.53,5.65-35.7,11.46c-3.05,1.6-6.35,3-9.83,4.47c-5.4,2.11-10.6,4.7-15.54,7.74
					c-11.13,7.33-18.54,20.01-22.63,38.9c-0.9,5.06-1.17,10.2-0.8,15.32c0.26,8.13,0.48,15.13-4.32,20.62
					c-3.24,4.01-6.04,8.36-8.34,12.97c-4,7.35-8.15,14.98-14.22,17.29c-1.03,0.39-2.12,0.59-3.22,0.58c-6.72,0-15-6.48-21.61-11.78
					c-2.79-2.16-5.4-4.32-7.82-5.81c-19.45-12.43-42.38-18.74-68.36-18.74h-1.38c-6.09,0.16-12.16,0.66-18.2,1.49
					c-5.42,0.63-10.53,1.23-15.71,1.38h-0.69c-2.89-0.08-5.78-0.32-8.64-0.74c-3.58-0.5-7.19-0.79-10.81-0.86
					c-4.43-0.17-8.83,0.8-12.77,2.81c-7.02,3.85-10.68,10.05-13.92,15.5c-3.24,5.45-6.29,10.66-12.45,13.29
					c-2.42,1.02-4.97,1.99-7.59,3c-11.33,4.32-24.16,9.34-31.71,19.45c-5.66,7.67-3.13,14.8-1.12,20.53
					c1.43,4.04,2.68,7.52,1.45,11.09c-2.87,8.15-9.9,14.39-15.89,14.39c-2.86-0.1-5.52-1.47-7.26-3.74
					c-2.47-3.02-4.76-6.17-6.87-9.44c-7.69-11.37-17.29-25.52-31.29-25.52c-3.88,0.07-7.68,1.09-11.07,2.98
					c-3.11,1.91-5.97,4.21-8.52,6.83c-4.04,3.78-8.65,8.06-12.38,8.06c-1.41-0.06-2.76-0.6-3.83-1.53
					c-1.83-1.32-2.78-3.55-2.46-5.79c0.65-7,8.65-15.69,13.77-18.37c6.65-3.23,13.63-5.74,20.81-7.5
					c9.62-2.72,19.45-5.55,28.66-11.84c17.29-11.95,29.7-24.12,36.89-36.18c2.88-5.11,5.32-10.45,7.31-15.97
					c3.22-8.19,6.27-15.93,12.3-21.94c5.49-5.1,11.48-9.64,17.87-13.55c3.89-2.57,7.93-5.25,11.74-8.17
					c7.59-5.83,12.82-13.38,17.87-20.68c3.11-4.74,6.55-9.25,10.31-13.49c8.8-9.49,17.87-19.3,17.59-37.2
					c0-2.31-0.26-4.95-0.48-7.72c-0.71-9.29-1.62-20.83,3.35-25.94c2.02-1.96,4.79-2.94,7.59-2.7c2.78,0.09,5.54,0.39,8.28,0.89
					c3.3,0.59,6.64,0.93,9.98,1.02c5.39,0.23,10.66-1.65,14.7-5.23c7.8-7.09,8.8-18.35,9.6-27.38c0.11-1.12,0.19-2.16,0.3-3.26
					c1.36-13.23,13.18-20.55,37.2-23.08l9.86-1.06c12.97-1.45,26.39-2.94,39.55-3.11h0.78c11.07,0,21.61,2.96,32.74,6.12
					c5.4,1.53,10.96,3.09,16.56,4.32c3.96,0.81,8,1.21,12.04,1.19c6.39-0.15,12.76-0.75,19.06-1.82c5.96-1.02,11.98-1.61,18.02-1.75
					h2.01h2.79c14.93,0,17.74-6.27,21.83-18.26c3.78-11.17,8.37-22.06,13.72-32.57c5.99-11.8,14.29-18.89,23.88-27.08
					c2.16-1.77,4.19-3.59,6.38-5.51c10.81-9.49,19.3-20.94,27.6-32.05l2.16-3c1.06-1.4,2.16-2.79,3.5-4.32
					c2.85-3.07,5.29-6.49,7.26-10.18c1.25-2.59,2.68-6.38,0.71-9.51c-1.22-1.86-3.33-2.95-5.55-2.85c-4.5,0-10.44,3.65-12.97,6.27
					c-3.09,3.16-6.12,6.48-9.03,9.79c-0.01,0.01-0.01,0.01-0.02,0.02c-8.16,9.05-16.59,18.42-27.11,24.15
					c-5.29,2.96-11.23,4.53-17.29,4.58c-1.45,0-2.89-0.1-4.32-0.3l-0.99-0.13c-2.56-0.41-5.15-0.65-7.74-0.71
					c-4.51-0.08-8.93,1.33-12.56,4.02c-5.93,4.53-11.34,9.69-16.14,15.39c-2.35,2.86-4.28,6.04-5.75,9.45
					c-1.2,2.86-2.82,5.52-4.82,7.89c-5.12,5.4-12.73,7.91-23.92,7.91c-2.31,0-4.82,0-7.67-0.32l-3.18-0.24
					c-8.43-0.58-17.18-1.21-24.25-5.04c-12.25-6.64-20.19-19.15-27.88-31.23l-1.45-2.29c-5.25-8.23-12.45-19.45-24.18-21.89
					c-1.34-0.28-2.7-0.42-4.06-0.41c-7.82,0-14.63,4.32-20.64,8.19c-1.51,0.97-2.98,1.92-4.32,2.75
					c-7.93,4.48-16.83,6.98-25.94,7.28c-3.05,0.09-6.08-0.46-8.9-1.62c-1.76-0.82-3.11-2.3-3.76-4.13c-0.91-2.5-0.82-5.26,0.26-7.69
					c0.95-2.16,4.75-2.74,8.13-3.35c1.19-0.19,2.31-0.41,3.33-0.65c0.11,0,10.81-2.79,12.28-9.73c0.59-3.18-0.86-6.39-3.63-8.06
					c-1.6-1.13-3.53-1.69-5.49-1.6c-1.45,0.03-2.9,0.17-4.32,0.43l-1.49,0.22c-2.16,0.19-4.73,0.41-7.24,0.41
					c-4.09,0.3-8.17-0.72-11.63-2.92c-4.32-3.13-5.19-6.63-3.61-12.97l0.22-0.8c0.93-3.67,3.46-13.42-5.42-15.78
					c-0.85-0.22-1.73-0.34-2.62-0.35c-6.16,0-10.63,5.3-12.28,7.56c-1.35,1.99-2.19,4.28-2.44,6.68c-0.35,2.16-0.52,2.59-1.32,3.22
					c-2.37,1.31-5.08,1.89-7.78,1.69h-0.28c-3.23-0.1-6.44-0.45-9.62-1.06c-3.62-0.67-7.28-1.06-10.96-1.15h-0.35
					c-5.07-0.25-9.61,3.15-10.81,8.08c-0.48,2.12-0.64,4.31-0.45,6.48c0.11,1.14,0.11,2.29,0,3.44c-0.5,2.98-1.34,3.22-3.78,3.22
					c-1.21,0-2.62-0.15-4.08-0.3c-1.7-0.21-3.41-0.33-5.12-0.37c-1.16-0.01-2.32,0.1-3.46,0.3c-5.75,1.15-7.82,5.77-9.34,9.16
					c-0.48,1.24-1.11,2.42-1.88,3.5c-2.29,2.61-5.66,4.02-9.12,3.8c-3.53-0.08-7-0.9-10.2-2.4c-4.44-1.93-7.99-5.46-9.94-9.9
					c-0.67-1.47-1.19-2.94-1.71-4.32c-1.49-4.32-3.18-9.08-9.03-12.56c-1.49-0.89-5.45-3.22-5.68-4.52c0-0.43,0.28-1.56,1.58-3.26
					l0.5-0.65c5.64-7.37,11.5-15.89,9.7-23.64c-0.9-3.65-3.44-6.67-6.87-8.19c-4.26-1.87-8.9-2.72-13.55-2.49h-5.12
					c-5.97,0-7.61-0.8-8.02-1.1c0.2-1.18,0.65-2.3,1.34-3.29c2.49-4.13,7.28-6.27,12.36-8.52c1.77-0.78,3.63-1.6,5.38-2.53
					c4.22-1.92,7.87-4.9,10.59-8.64c1.33-2.22,2.31-4.63,2.9-7.15c0.4-1.83,1.13-3.58,2.16-5.14c0.85-1.15,2.25-1.77,3.67-1.64
					c2.53,0.18,5.03,0.68,7.43,1.47c3.22,1.05,6.56,1.66,9.94,1.82c1.26,0,2.51-0.14,3.74-0.43c6.87-1.71,16.94-4.75,25.03-11.43
					c2.86-2.54,5.38-5.45,7.48-8.65c2.55-3.61,4.73-6.72,8.21-8.64c4.91-2.22,10.15-3.61,15.52-4.11c4.14-0.52,8.23-1.37,12.23-2.53
					c8.95-2.92,17.01-8.08,23.41-14.98c3.26-3.39,7.8-5.21,12.6-7.13c4.44-1.55,8.65-3.69,12.51-6.35
					c15.13-11.28,27.27-29.85,33.41-50.92c4.86-16.81,3.89-31.79-2.94-44.54c-6.48-12.02-7.89-19.6,1.99-27.77
					c2.8-2.02,4.52-5.2,4.69-8.65c0-5.58-4.58-9.7-8.65-13.33c-1.6-1.27-3.05-2.72-4.32-4.32c-2.68-4.13-2.16-9.29-1.32-15.26
					c1.02-5.22,0.95-10.6-0.22-15.8c-2.55-8.97-10.53-14.13-21.85-14.13c-1.65,0.01-3.31,0.11-4.95,0.3
					c-12.62,1.56-42.21,5.17-40.67,29.74c0,1.17,0.3,2.31,0.5,3.44c0,0.19,0,0.41,0.13,0.67c-1.84-0.77-3.59-1.71-5.25-2.81
					c-3.19-2.2-6.77-3.77-10.55-4.62c-5.08-0.89-10.3-0.39-15.13,1.43l-2.01,0.84c-1.01,0.46-2.05,0.84-3.11,1.15
					c-0.39,0.09-0.79,0.14-1.19,0.15h-0.26c0.8-2.01,4.32-7.91,20.77-21.61l0.93-0.78c3.93-3.37,8.17-6.48,12.25-9.7
					c10.5-8.02,21.33-16.27,29.07-28.1c4.58-7.56,8.21-15.67,10.81-24.12c0.59-1.71,1.2-3.42,1.82-5.12c1.12-2.45,1-5.28-0.3-7.63
					c-1.27-1.73-3.32-2.71-5.47-2.59c-2.84,0.23-5.62,0.89-8.26,1.97c-1.5,0.58-3.06,1.02-4.65,1.32
					c-11.02-0.84-19.75-10.35-23.77-18.22c-2.53-4.86-1.86-10.16-1.08-16.3c0.78-6.14,1.6-12.41-0.84-18.87
					c-0.41-1.08-0.89-2.16-1.36-3.13c-1.47-3.11-1.77-4.04-1.04-5.49c1.71-3.44,5.73-4.91,13.42-4.91c2.36,0,4.82,0.13,7.2,0.26
					c2.38,0.13,4.63,0.24,6.77,0.24c1.76,0.02,3.51-0.09,5.25-0.35c0.85-0.15,1.71-0.23,2.57-0.24c5.66,0,9.55,3.54,13.4,12.19
					c0.37,0.8,0.73,1.66,1.1,2.55c2.16,5.34,5.01,12,10.98,15.28c1.45,0.77,3.07,1.15,4.71,1.12c3.07-0.2,6.09-0.84,8.97-1.9
					c2.09-0.78,4.27-1.3,6.48-1.53c0.19-0.02,0.38-0.02,0.56,0c0,0,0,0.28,0.17,0.71c0,1.15-3.63,4.06-5.36,5.47
					c-2.6,1.84-4.81,4.18-6.48,6.89c-1.42,2.42-1.42,5.41,0,7.82c1.67,2.42,4.5,3.78,7.43,3.59c1.47-0.01,2.93-0.27,4.32-0.76
					c3-1.19,5.79-2.88,8.23-4.99c1.74-1.55,3.73-2.8,5.88-3.7c2.11-0.61,4.29-0.99,6.48-1.12c3.79-0.16,7.51-1.03,10.98-2.55
					c5.14-2.53,6.48-7.93,7.48-12.28c0.31-1.87,0.93-3.67,1.82-5.34c0.37-0.62,1.06-0.97,1.77-0.91c2.64,0,6.48,2.38,8.9,3.83
					c8.24,4.73,17.61,7.11,27.1,6.89h1.66c8.65-0.22,16.47-3.41,24.01-6.48c5.21-2.38,10.66-4.19,16.25-5.4
					c1.59-0.28,3.2-0.42,4.82-0.43c3.82-0.14,7.56,1.06,10.59,3.39c2.28,2.32,4.02,5.11,5.12,8.17c1.57,3.58,3.76,6.84,6.48,9.64
					c2.62,2.44,6.08,3.79,9.66,3.76c3.97,0.03,7.7-1.52,10.45-4.16c1.47-1.41,2.66-3.11,3.47-5.07c3.85-9.86-3.42-17.03-8.65-22.26
					c-1.27-1.2-2.47-2.46-3.61-3.78c-2.79-3.29-3.63-5.34-3.07-7.28c0.97-3.37,2.16-5.1,3.76-5.1c1.12,0,5.47,0.76,14.18,10.37
					c2.92,3.22,5.73,6.7,8.45,10.05c1.86,2.29,3.72,4.58,5.62,6.83c1.27,1.51,7.97,9.01,14.18,9.01c3.86,0.03,7.08-2.97,7.31-6.83
					c0.3-5.27-4.69-10.81-12.25-17.57l-1.06-0.97c-4.29-4.48-7.8-9.65-10.4-15.28c-1.77-3.31-3.59-6.72-5.79-10.03l-2.51-3.59
					c-4.17-5.96-8.13-11.58-8.39-18.11c-0.52-12.69,7.35-24.64,12.08-30.62c3-3.76,6.58-7.04,10.59-9.7
					c3.67-2.49,7.8-5.94,7.2-10.44c-0.61-4.5-5.58-6.48-10.37-8.19c-1.92-0.56-3.8-1.28-5.6-2.16c-5.88-3.33-7.13-10.44-4.02-23.04
					l0.5-2.03c1.77-7.37,3.76-15.71,9.49-19.65c1.44-0.94,2.95-1.79,4.5-2.55c4.58-2.36,10.29-5.29,12.54-11.3
					c1.4-4.57,1.65-9.42,0.73-14.11c-0.57-3.06-0.57-6.19,0-9.25c0.91-2.68,2.22-5.21,3.89-7.5c1.83-2.61,3.34-5.44,4.5-8.41
					c2.74-7.72,0.99-18.76-2.29-24.98c-2.77-5.21-6.48-6.31-8.95-6.31c-3.47,0.36-6.76,1.74-9.44,3.98
					c-1.16,0.81-2.38,1.53-3.65,2.16c-0.55,0.24-1.15,0.35-1.75,0.35c-1.97-0.2-3.88-0.77-5.64-1.66c-2.51-1.26-5.26-1.99-8.06-2.16
					c-3.25,0.02-6.32,1.47-8.41,3.96c-3.85,4.72-6.93,10.03-9.12,15.71c-1.86,5.03-4.58,9.69-8.04,13.79
					c-0.26,0.26-0.73,0.71-1.12,0.71c-2.66,0-7.87-7.82-8.99-12.62c-0.5-2.16,0-2.81,2.59-5.55c0.78-0.8,1.56-1.62,2.31-2.55
					c3.84-4.51,6.73-9.75,8.49-15.41c1.15-3.14,0.75-6.63-1.08-9.42c-1.39-1.79-3.53-2.81-5.79-2.77c-3.66,0.28-7.07,1.99-9.49,4.75
					c-1.36,1.73-3.36,2.84-5.55,3.09c-3.89,0-8.82-5.36-12.86-13.98c-2.15-4.96-3.87-10.09-5.17-15.35
					c-2.68-9.66-5.45-19.62-12.97-27.4l-1.95-2.01c-6.12-6.35-12.45-12.97-15.65-20.75s-1.1-12.51,2.81-20.62l0.67-1.43
					c2.14-5.13,3.21-10.63,3.16-16.19c0.11-2.01,0.22-3.91,0.43-5.77c0.65-4.85,2.78-9.37,6.09-12.97c1.45-1.82,3.03-3.54,4.67-5.38
					c3.53-3.63,6.64-7.64,9.27-11.97c2.94-5.19,2.16-10.27,1.32-14.33c-0.62-2.36-0.72-4.82-0.28-7.22
					c1.32-4.54,4.71-8.95,8.02-13.23c1.47-1.92,3.03-3.91,4.32-5.94l0.78-1.17c2.46-3.83,5.13-7.52,8.02-11.04
					c0.69-0.68,1.41-1.33,2.16-1.95c2.86-2.44,5.58-4.79,6.91-7.31c0.76-1.43,1.07-2.92,0.69-4.52c-1.04-4.39-5.66-6.48-13.57-6.48
					c-4.32,0-10.01,0.71-12.67,2.31c-1.95,1.2-3.73,2.66-5.3,4.32c-1.39,1.64-3.2,2.89-5.23,3.61c-3.21,0.81-6.5,1.25-9.81,1.32
					c-1.23,0-2.49,0.15-3.7,0.26c-5.21,0.43-11.93,3.24-13.79,9.73c-0.55,2.57-0.44,5.24,0.32,7.76c0.13,0.63,0.32,1.45,0.41,2.16
					h-0.19h-1.08c-1.53-0.3-2.99-0.87-4.32-1.69c-2.2-1.3-4.63-2.17-7.15-2.55h-1.32c-2.62,0.12-5.19,0.78-7.54,1.95
					c-0.73,0.3-1.49,0.63-2.16,0.89l-2.79,0.91c-7.09,2.29-15.13,4.88-17.1,15.45c-1.08,5.81-9.55,10.57-16.79,11.65
					c-0.71,0.12-1.44,0.18-2.16,0.17c-2.62-0.18-5.18-0.92-7.5-2.16c-1.43-0.61-2.96-0.92-4.52-0.93c-2.44,0.1-4.81,0.84-6.87,2.16
					c-1.04,0.71-2.25,1.15-3.5,1.27c-4.69-0.13-5.88-2.57-8.08-9.51l-0.71-2.16c-1.06-3.35-2.68-8.39-6.66-10.81
					c-2.5-1.4-5.35-2.09-8.21-1.99c-1.51,0-2.98,0.11-4.32,0.24s-2.51,0.22-3.61,0.22c-0.72,0.01-1.45-0.05-2.16-0.17
					c-3.01-0.71-5.91-1.8-8.65-3.24l-1.49-0.76c-2.86-1.8-6.09-2.92-9.44-3.29c-2.06-0.05-4.04,0.84-5.36,2.42
					c-2.16,2.7-1.75,6.48-1.23,8.93c1.25,6.03,6.09,8.65,10.01,10.81c1.39,0.7,2.72,1.53,3.96,2.49c1.77,1.51,1.34,3.85-0.76,9.64
					c-0.83,2.1-1.49,4.27-1.97,6.48c-0.33,2.88-0.28,5.78,0.17,8.65c0.24,2.27,0.78,7.61-0.11,8.65c-0.3,0.11-0.61,0.15-0.93,0.13
					c-6.59-0.13-13.29-6.48-18.69-11.69c-0.99-0.97-1.97-1.88-2.9-2.75c-3-2.77-6.03-5.81-8.97-8.65
					c-8.93-8.97-18.15-18.24-30.26-23.77c-3.86-1.83-8.05-2.82-12.32-2.92c-4.77-0.09-9.4,1.62-12.97,4.78
					c-11.84,10.4-6.48,24.36-2.03,35.57c2.33,5.97,4.52,11.58,4.32,16.14c-0.14,1.78-0.9,3.46-2.16,4.73
					c-3.57-2.85-4.6-7.05-5.84-11.89c-0.66-2.96-1.58-5.85-2.75-8.65c-6.06-13.09-13.13-25.68-21.16-37.67
					c-0.76-1.19-1.58-2.36-2.38-3.54c-2.76-3.64-4.98-7.65-6.61-11.91c-2.83-9.23-4.32-21.14-1.56-25.18
					c0.47-0.74,1.28-1.19,2.16-1.19h0.41c1.08,0,3.07,0,4.19,7.26c0.96,7.07,3.12,13.92,6.38,20.27c2.01,3.61,4.43,5.45,7.22,5.45
					c1.92,0,5.4-0.95,7.05-7.2c1.88-6.68,2.12-13.71,0.71-20.51c-0.57-2.09-1.25-4.14-2.03-6.16c-1.09-2.6-1.82-5.35-2.16-8.15
					c-0.13-1.19-0.17-2.38-0.19-3.54c-0.15-15.63,9.14-28.98,18.13-41.86l1.21-1.77c5.3-7.15,9.8-14.85,13.42-22.97
					c1.75-4.46,3.25-9.01,4.5-13.64c1.21-4.57,2.71-9.06,4.47-13.44c1.45-3.37,3.16-6.79,4.8-10.09c2.52-4.79,4.77-9.72,6.72-14.76
					c2.83-7.83,5.21-15.81,7.13-23.9c3.76-15.56-6.2-22.97-14.98-29.5c-2.38-1.77-4.84-3.61-7-5.58
					c-9.38-8.54-11.82-19.84-12.97-29.76c-1.14-10.29-1.65-20.64-1.53-30.99c0-3.48,0.24-6.92,0.48-10.57
					c0.47-5.48,0.57-10.98,0.32-16.47c-0.21-1.06-0.05-2.16,0.45-3.11c0.15-0.17,0.86-0.71,3.61-0.71c1.3,0,2.75,0.11,4.52,0.28
					c3.44,0.32,6.94,0.8,10.33,1.25c6.06,0.93,12.16,1.51,18.28,1.75h1.08c0.95,0,2.16,0,3.37,0c3.57,0,7.5-0.35,9.62-2.9
					c1.31-1.48,1.74-3.55,1.1-5.42c-1.25-3.65-5.71-5.32-13.51-7.07l-1.21-0.5c-2.87-0.86-5.71-1.9-8.65-2.98
					c-6.19-2.52-12.65-4.3-19.26-5.3c-1.15-0.13-2.3-0.19-3.46-0.19c-5.46,0.1-10.84,1.35-15.78,3.67c-0.93,0.41-2.16,0.8-3.29,1.25
					c-5.51,1.99-12.97,4.73-15.13,11.02c-1.79,5.49-0.43,10.81,0.76,15.5c0.71,2.41,1.15,4.89,1.32,7.39
					c-0.25,6.13-1.43,12.19-3.5,17.96c-1.45,4.45-2.58,8.99-3.39,13.59c-0.19,1.28-0.26,2.53-0.35,3.74
					c-0.05,1.11-0.16,2.21-0.32,3.31c-0.64-0.14-1.27-0.33-1.88-0.58c-0.56-0.24-0.8-0.67-0.89-4.15c-0.09-3.48-0.17-7.72-3.8-10.81
					c-2.96-2.04-6.36-3.38-9.92-3.91c-1.19-0.28-2.31-0.54-3.13-0.8l-0.35-0.11c-3.2-0.69-6.17-2.18-8.65-4.32
					c-0.82-0.89-1.64-1.92-2.51-3.03c-2.42-3.63-5.71-6.61-9.57-8.65c-1.92-0.77-3.98-1.19-6.05-1.21
					c-3.01-0.34-5.86,1.46-6.85,4.32c-1.49,4.54,2.92,7.87,5.55,9.86c0.33,0.23,0.64,0.48,0.93,0.76c2.63,2.48,4.62,5.56,5.77,8.99
					c1.4,4.6,0,6.14-9.23,9.68c-4.27,1.31-8.2,3.52-11.54,6.48c-3.33,3.94-5.9,8.47-7.56,13.36c-0.35,0.84-0.69,1.71-1.06,2.55
					l-1.21,2.85c-1.94,4.47-3.93,9.1-5.53,13.94c-7.09,21.61-13.94,47.66-10.81,77.16c0.56,5.53-0.35,7.67-4.84,11.41l-0.78,0.63
					c-6.03,5.01-12.28,10.2-19.15,10.2c-1.3-0.01-2.59-0.19-3.85-0.54c-1.01-0.18-1.89-0.8-2.4-1.69
					c-1.62-3.07,0.63-9.79,1.36-11.99c0.93-2.83,2.01-5.71,3.03-8.49c3.39-9.16,6.92-18.65,7.24-29.09v-2.7
					c0.09-9.79,0.75-19.56,1.99-29.26c1.8-9.56,4.53-18.93,8.15-27.97l0.56-1.53c0.5-1.43,0.97-2.85,1.45-4.32
					c1.95-5.88,3.76-11.43,8.04-15.13c0.39-0.32,1.45-1.02,2.4-1.62c4.32-2.83,11.78-7.59,9.23-14.16c-1.51-3.91-5.27-5.9-11.2-5.9
					c-1.62,0-3.22,0.13-4.78,0.24s-2.46,0.19-3.54,0.19c-0.46,0.06-0.92,0.06-1.38,0c-1.08-0.3-1.82-0.73-1.99-1.15
					c-0.76-1.64,1.45-6.48,2.77-9.34c1.56-2.84,2.59-5.94,3.03-9.14c0.19-5.21-3.8-7.63-5.96-8.93c-0.66-0.36-1.27-0.78-1.84-1.27
					c-4.08-4.65-1.06-11.97,2.81-19.19c4.93-8.67,9.15-17.71,12.62-27.06c1.73-5.76,2.67-11.73,2.79-17.74
					c0.11-1.64,0.22-3.31,0.37-4.95c0.34-2.65,0.93-5.25,1.77-7.78c1.73-4.66,2.41-9.65,1.97-14.61c-0.55-3.41-1.32-6.78-2.31-10.09
					c-1.69-5.99-3-10.81-0.93-17.29c3.07-9.62,8.28-18.2,13.79-27.25c2.31-3.8,4.69-7.72,6.87-11.69
					c3.44-6.25,3.72-12.97,3.98-19.45c0-2.16,0.15-4.02,0.32-5.92c0.89-9.45,3.84-18.59,8.65-26.78c2.94-4.09,6.47-7.73,10.46-10.81
					c8.32-7.24,17.74-15.45,16.25-28.29c-0.5-4.32-4.02-7.07-8.95-7.07c-2.46,0.01-4.89,0.62-7.07,1.77
					c-4.01,2.62-7.39,6.11-9.88,10.2c-0.91,1.28-1.75,2.49-2.62,3.54l-2.16,2.64c-8.65,10.81-17.77,21.96-27.4,32.18
					c-2.16,2.16-4.43,4.32-6.96,6.48c-6.12,5.27-12.97,11.28-15.78,20.08c-1.71,5.49-2.98,11.13-4.19,16.58
					c-1.58,8.15-3.94,16.14-7.05,23.84c-2.33,5.91-6.14,11.12-11.07,15.13c-1.44,1.22-3.19,2.01-5.06,2.29c-0.32,0-0.45,0-0.5-0.11
					c0.02-0.49,0.07-0.98,0.15-1.47c1.31-4.59-1.35-9.38-5.94-10.69c-0.94-0.27-1.92-0.38-2.9-0.32c-5.79,0-12.15,4.32-18.13,8.65
					c-0.82,0.61-1.53,1.12-2.16,1.47c-8.02,5.36-18.48,5.92-28.53,6.05c-2.16,0-4.32,0.13-6.48,0.24c-2.16,0.11-4.75,0.26-7.07,0.26
					c-3.88,0.14-7.76-0.46-11.41-1.77c-3.93-1.58-8.06-5.4-8.32-7.76c0-0.26-0.11-0.97,1.49-2.16c2.27-1.51,4.95-2.26,7.67-2.16
					c3.49,0.15,6.96,0.65,10.35,1.49c1.79,0.39,3.57,0.76,5.29,1.04h0.52c2.04,0.4,4.12,0.63,6.2,0.67c4.15,0,6.92-1.36,8.23-4.02
					c2.64-5.34-2.16-9.31-5.17-11.71c-1.08-0.8-2.06-1.71-2.94-2.72c-0.35-3.54,2.38-6.66,6.48-10.96c1.23-1.27,2.53-2.61,3.63-3.96
					c1.18-1.41,1.45-3.36,0.71-5.04c-1.56-3.48-5.81-3.96-17.55-4.32c-1.34,0-3-0.13-3.42-0.19c-1.15-0.09-2.26-0.44-3.24-1.04
					c0.59-0.76,1.22-1.48,1.9-2.16c2.03-2.03,4.58-4.58,4.5-8.17c-0.08-2.2-1.07-4.26-2.75-5.68c-2.83-2.22-6.18-3.71-9.73-4.32
					c-2.01-0.36-3.93-1.09-5.66-2.16c0.05-0.88,0.32-1.73,0.78-2.49c2.49-5.14,7.61-9.31,12.56-13.33c1.64-1.34,3.26-2.66,4.78-4
					l0.97-0.84c7.85-6.66,14.98-13.7,14.05-20.19c-0.44-2.88-2.38-5.3-5.1-6.35c-0.5-0.24-1.04-0.45-1.56-0.67
					c-1.84-0.76-2.96-1.25-3.22-1.99c-0.44-1-0.4-2.15,0.11-3.11c0.73-1.38,3.72-4.99,15.5-8.1c4.17-1.24,8.49-1.92,12.84-2.03h0.5
					c1.17,0,2.36,0,3.57,0.15c1.6,0,3.2,0.17,4.8,0.17c5.13,0.33,10.19-1.36,14.09-4.71c2.81-2.69,5.42-5.57,7.8-8.65
					c2.21-2.89,4.65-5.6,7.28-8.1l1.1-0.95c4.32-3.65,11.45-9.79,9.36-16.25c-1.64-5.01-7.26-5.53-10.29-5.81
					c-0.6,0-1.15-0.11-1.6-0.19c-2.39-0.26-4.57-1.47-6.05-3.35c-1.24-2.3-1.52-5-0.78-7.5l0.19-0.95
					c0.93-4.65,2.51-12.41,5.92-14.96c0.71-0.52,1.58-0.79,2.46-0.76c3.33,0.49,6.52,1.65,9.38,3.42c0.95,0.45,1.84,0.89,2.68,1.25
					c3.62,1.59,7.49,2.53,11.43,2.77c2.86,0.26,5.64-1.01,7.31-3.35c1.55-2.77,1.55-6.14,0-8.9c-2.29-5.27-7-7.54-10.81-9.36
					c-2.1-0.83-4.03-2.04-5.71-3.57c-2.16-2.31-3.07-8.65-1.19-13.31c1.41-3.5,3.46-3.63,4.13-3.67h0.41c2.72,0,5.81,1.9,8.82,3.74
					l2.83,1.73c2.59,1.85,5.53,3.16,8.65,3.85c0.47,0.07,0.93,0.11,1.4,0.11c5.11-0.31,9.72-3.17,12.28-7.61
					c1.39-2.79,0.98-6.14-1.04-8.52c-4.11-5.01-10.2-6.2-15.6-7.26c-2.21-0.34-4.38-0.87-6.48-1.6c-6.16-3.11-5.66-5.64-2.9-14.61
					l0.67-2.16c0.31-0.91,0.31-1.9,0-2.81c0.46-0.24,0.87-0.55,1.21-0.93c1.11-1.32,2.07-2.77,2.83-4.32
					c1.08-2.01,1.88-3.35,2.77-3.52c2.01,0.48,3.97,1.14,5.86,1.97c3.72,1.71,7.7,2.76,11.78,3.11c3.84,0.16,7.3-2.32,8.39-6.01
					c1.88-5.84-3.98-11.28-8.39-13.51c-5.36-2.58-11.23-3.91-17.18-3.89h-1.02c-1.02,0-2.16,0.13-3.37,0.24
					c-1.21,0.11-2.9,0.26-4.32,0.26c-3.54,0-4.08-0.95-4.32-1.25c-0.45-0.8-0.32-1.1-0.24-1.3c0.67-1.71,4.56-4.32,11.67-6.48
					l0.48-0.15c2.37-0.58,4.63-1.55,6.7-2.85c3.15-2.02,4.99-5.57,4.82-9.31c-0.22-2.57-1.79-8.65-11.56-8.65
					c-5.93,0.43-11.76,1.66-17.35,3.67l-1.38,0.43c-1.51,0.46-3.09,0.71-4.67,0.73c-3.4,0-6.6-1.6-8.65-4.32
					c-7.26-8.49-8.8-27.51-7.11-37.95c0.15-0.91,0.26-1.97,0.41-3.11c0.35-3.05,0.99-8.65,2.75-10.01c0.18-0.17,0.42-0.28,0.67-0.28
					c1.43,0,4.04,2.16,5.79,3.59c0.95,0.8,1.86,1.56,2.77,2.16c5.45,3.76,11.91,7.63,18.54,7.74h0.24c5.9,0,10.01-2.83,10.42-7.2
					c0.5-5.17-4.19-7.76-6.48-9.01l-1.04-0.6c-9.16-5.9-17.74-11.87-21.94-20.16c-1.96-3.55-2.36-7.75-1.12-11.61
					c0.43-1.23,1.34-1.62,4.43-2.46c3.56-0.55,6.78-2.43,9.01-5.25c5.64-8.13-4.15-15.13-10.63-19.75
					c-2.68-1.92-7.18-5.12-7.65-6.48c-1.69-6.22-1.25-16.38,2.36-21.94c1.9-2.92,3.41-3.96,4.32-3.96c3.22,0,8.65,9.68,11.41,14.89
					c4.32,7.82,7.41,13.49,12.47,13.49c1.11,0,2.2-0.31,3.16-0.89c12.97-7.72,4.99-22.28,0.71-30.11c-0.41-0.78-0.82-1.49-1.19-2.16
					c-0.37-0.67-0.65-1.19-1.02-1.86c-1.17-2.16-3.37-5.99-2.96-7.67c0.06-0.56,0.29-1.08,0.65-1.51c0.11-0.02,0.22-0.02,0.32,0
					c2.16,0,6.03,2.36,8.34,3.76l0.28,0.17c0.58,0.37,1.23,0.82,1.9,1.3c2.5,2.07,5.47,3.5,8.65,4.17c0.53,0.07,1.06,0.1,1.6,0.11
					c2.18-0.08,4.34-0.51,6.38-1.3c1.15-0.46,2.37-0.74,3.61-0.84c0.37-0.01,0.75,0.05,1.1,0.17c2.88,1.08,5.59,2.58,8.04,4.45
					c3.96,3.16,8.62,5.32,13.59,6.31c0.72,0.11,1.44,0.16,2.16,0.17c1.69,0.04,3.35-0.41,4.8-1.28c2.45-1.19,3.92-3.77,3.7-6.48
					c-0.54-4.6-5.84-6.76-10.81-8.41l-0.32-0.11l-2.16-0.71c-4.15-1.32-10.44-3.31-11.24-6.48c-0.01-0.12-0.01-0.25,0-0.37
					c4.32,0,12.67,3.85,16.73,5.66c1.3,0.65,2.64,1.2,4.02,1.66c1.37,0.37,2.78,0.55,4.19,0.54c6.92,0,14.42-3.59,15.41-10.46
					c1.32-9.23-9.01-13.31-14.59-15.5c-1.08-0.43-2.16-0.8-2.83-1.17c-5.11-2.37-10.06-5.07-14.83-8.08
					c-10.18-6.76-15.13-15-14.7-24.49c0.03-1.06,0.12-2.12,0.26-3.18c0.45,0.6,0.95,1.38,1.38,2.05c1.35,2.52,3.38,4.61,5.86,6.03
					c1.6,0.76,3.35,1.15,5.12,1.15c5.5-0.19,10.47-3.35,12.97-8.26c2.92-5.9,1.21-12.3-0.17-17.44c-0.24-0.91-0.48-1.79-0.67-2.64
					c-1.77-6.9-4.85-13.39-9.08-19.13c-0.5-0.73-1.17-1.56-1.9-2.46c-1.1-1.38-4.43-5.55-3.72-6.48c1.21-1.69,4.47-2.03,7-2.03
					c3.13,0.08,6.25,0.4,9.34,0.95l2.38,0.35c0.71,0,1.6,0.24,2.64,0.41c4.7,0.87,9.46,1.36,14.24,1.49
					c12.36,0,15.99-5.4,16.86-9.94c1.19-6.18-2.68-11.07-6.09-15.37c-2-2.13-3.54-4.65-4.52-7.41c-1.32-8.07,0.01-16.35,3.8-23.6
					c4.15-8.19,3.67-16.43,3.24-24.38c-0.41-7.37-0.78-14.33,2.57-20.73c5.77-11,18.44-14.93,28.38-14.93
					c4.35-0.01,8.67,0.72,12.77,2.16c7.01,2.22,12.71,7.37,15.63,14.11c0.27,0.93,0.46,1.87,0.58,2.83c0.65,3.8,1.6,9.57,6.63,10.81
					c0.77,0.21,1.56,0.31,2.36,0.3c6.59,0,10.37-6.74,13.4-12.17c0.93-1.91,2.09-3.69,3.46-5.32c2.28-1.63,5.02-2.48,7.82-2.44
					c1.69,0,5.81,0.35,7.5,3.52c0.56,1.77,0.79,3.63,0.67,5.49c0,3.85,0.13,8.65,3.54,12.1c1.63,1.93,3.97,3.11,6.48,3.29
					c4.65,0,6.68-4.32,8.02-7.24c0.41-0.99,0.93-1.94,1.53-2.83c0.94-0.8,2.15-1.18,3.37-1.08c1.2,0.04,2.4,0.15,3.59,0.32
					c1.35,0.2,2.72,0.33,4.08,0.39h1.43c4.41,0.07,8.77-0.95,12.69-2.98c3.95-2.1,7.65-4.63,11.04-7.54
					c3.2-2.84,6.79-5.22,10.66-7.07c2.55-1,5.28-1.49,8.02-1.45c5.26,0.25,10.48,1.11,15.54,2.57c5.99,1.69,12.15,2.66,18.37,2.9
					c3.91,0.04,7.79-0.61,11.48-1.92c3.75-1.5,7.37-3.32,10.81-5.45c4.69-2.72,9.1-5.3,14.11-5.97c1.99-0.25,3.99-0.37,5.99-0.35
					c1.73,0,3.48,0,5.21,0s3.7,0.13,5.51,0.13c3.69,0.07,7.38-0.32,10.98-1.17c4.94-1.47,9.62-3.69,13.88-6.59
					c2.5-1.62,5.12-3.07,7.82-4.32c3.56-1.49,7.38-2.23,11.24-2.16c5.94,0.22,11.83,1.13,17.55,2.72
					c7.07,1.73,11.91,4.32,13.64,7.39c0.95,1.79,1.05,3.92,0.28,5.79c-1.11,2.86-2.66,5.54-4.58,7.93l-0.82,1.15
					c-4.32,6.05-9.66,13.55-5.77,22.89c0.94,2.11,2.02,4.15,3.24,6.12c1.87,2.63,3.15,5.64,3.74,8.82
					c0.35,4.54-2.55,9.38-5.36,14.07c-0.95,1.58-1.92,3.22-2.77,4.84l-1.08,2.16c-3.74,7.22-7.61,14.7-13.7,19.32
					c-2.6,1.79-5.38,3.31-8.3,4.52c-1.88,0.84-3.8,1.73-5.71,2.77c-13.36,7.35-23.77,18.33-33.15,28.96
					c-10.5,11.82-20.6,22.39-30.86,32.42c-1.88,1.82-3.74,3.72-5.6,5.62c-4.58,5.01-9.65,9.56-15.13,13.57
					c-2.63,1.58-5.41,2.9-8.3,3.91c-4.97,1.95-10.11,3.96-14.11,8.28c-6.25,6.81-9.14,17.03-9.77,24.34c-0.1,2.5,0.06,5.01,0.5,7.48
					c0.86,6.48,0.58,8-1.58,8.99c-1.43,0.51-2.93,0.83-4.45,0.93c-3.33,0.14-6.56,1.19-9.34,3.05c-2.92,1.84-4.5,5.22-4.04,8.65
					c0.78,4.78,5.6,8.04,9.83,9.51c4.15,1.36,8.49,2.02,12.86,1.95c3.18,0,6.33-0.26,9.36-0.52c2.05-0.17,4.08-0.35,6.16-0.43h0.5
					c0.84,0,1.88,0,2.96,0c4.71,0,5.6,0.97,5.62,0.97c0.39,2.59-4.56,7-7.22,9.36c-0.89,0.78-1.71,1.51-2.4,2.16
					c-5.48,5.58-10.34,11.74-14.5,18.37c-2.87,4.32-5.64,8.9-8.32,13.31c-7.78,12.79-15.84,25.94-27.12,35.73
					c-6.79,6.17-15.25,10.2-24.31,11.61c-1.49,0.17-3.03,0.24-4.67,0.32c-2.9,0.05-5.8,0.36-8.65,0.93
					c-0.89,0.19-8.82,2.16-9.66,7.91c-0.36,3.05,1.31,5.98,4.13,7.22c3.74,2.16,9.08,2.42,13.31,2.42c1.77,0,3.57,0,5.27-0.11
					c1.71-0.11,2.83,0,4.06,0c0.65,0,1.23,0,1.77,0l2.55,0.15c2.16,0.13,4.08,0.26,6.05,0.26c5.81,0.21,11.5-1.68,16.04-5.32
					c6.09-4.56,11.13-10.38,14.78-17.05c0.35-0.65,0.67-1.32,1.02-2.01c1.75-3.5,3.42-6.81,6.16-8.45c3.03-1.29,6.33-1.84,9.62-1.62
					l3.11-0.11c8.86-0.5,18.85-1.08,27.19-6.59c3.36-2.4,6.32-5.31,8.75-8.65c2.06-2.94,4.65-5.47,7.63-7.46
					c2.86-1.4,6.05-2.04,9.23-1.84c1.43,0,2.87,0,4.32,0.11c1.45,0.11,3.13,0.13,4.69,0.13c3.39,0.1,6.77-0.36,10.01-1.36
					c3.89-1.38,7.66-3.09,11.26-5.1c3.15-1.76,6.44-3.27,9.83-4.52c3.67-1.18,7.51-1.75,11.37-1.71c7.3,0.3,14.55,1.39,21.61,3.24
					c3.16,0.69,6.48,1.43,9.68,2.01c3.69,0.64,7.43,0.96,11.17,0.93c5.91-0.11,11.82-0.56,17.68-1.34
					c3.46-0.41,7.02-0.82,10.46-1.06c1.77-0.13,3.61-0.19,5.47-0.19c10.47,0.35,20.89,1.61,31.14,3.76
					c9.7,1.96,19.53,3.24,29.42,3.83c2.64,0.09,5.27,0.13,7.91,0.13c14.75-0.04,29.47-1.34,44-3.87c3.09-0.54,6.22-1.28,9.27-1.99
					c5.54-1.53,11.24-2.44,16.99-2.7c2.18-0.02,4.36,0.22,6.48,0.71c8.32,1.99,14.05,9.88,19.58,17.48l1.19,1.64
					c11.3,15.43,17.01,29.83,16.97,42.77c0.06,7.54-2.2,14.92-6.48,21.14c-2.96,3.88-6.36,7.41-10.14,10.5
					c-4.11,3.45-7.84,7.32-11.13,11.56c-4.82,7.28-8.74,15.12-11.67,23.34l-1.62,4.04c-1.27,2.96-2.71,5.85-4.32,8.65
					c-2.91,4.71-5.15,9.8-6.63,15.13c-1.53,6.48,1.02,11.82,3.28,16.53c1.66,2.83,2.66,6,2.94,9.27
					c-0.11,6.48-4.32,12.21-8.84,18.31c-1.75,2.4-3.57,4.86-5.1,7.37c-7.69,12.64-12,23.23-13.53,33.28
					c-0.17,1.1-0.32,2.16-0.5,3.31c-1.27,9.01-2.51,17.53-8.84,23.43c-1.99,1.86-4.08,3.59-6.33,5.42
					c-5.23,3.95-9.9,8.61-13.85,13.83c-5.21,7.5-7.2,16.23-9.1,24.68c-0.61,2.72-1.19,5.32-1.88,7.85
					c-2.49,9.12-5.4,18.67-11.43,26.26c-9.1,11.45-18.82,19.78-28.94,24.7c-6.94,3.41-14.68,3.7-22.89,3.98
					c-3.91,0.15-7.95,0.28-11.95,0.8c-10.73,1.06-21.11,4.39-30.45,9.77c-3.85,2.14-7.34,4.87-10.35,8.08
					c-2.66,2.67-3.55,6.62-2.31,10.18c1.1,2.34,3.49,3.8,6.07,3.72c1.55-0.04,3.08-0.39,4.5-1.02c3.91-1.64,7.72-3.54,11.39-5.38
					c5.41-2.89,11.05-5.33,16.86-7.28l1.66-0.52c3.99-1.44,8.15-2.32,12.38-2.62c1.09-0.01,2.18,0.12,3.24,0.39
					c3.07,0.84,3.87,4.06,4.6,11.43c0.18,2.49,0.56,4.96,1.12,7.39c1.21,5.6,4.15,10.68,8.41,14.52c2.95,2.4,6.69,3.6,10.48,3.37
					h1.79c1.28-0.06,2.56,0.09,3.78,0.45c1.08,0.35,2.16,1.88,3.09,3.33c1.73,2.68,3.29,5.46,4.69,8.32
					c1.99,4.15-1.06,8.32-9.08,12.45c-7.63,4.07-16.12,6.3-24.77,6.48c-1.44,0.01-2.89-0.07-4.32-0.24l-3.63-0.43
					c-3.77-0.54-7.58-0.85-11.39-0.93c-6.2-0.3-12.31,1.56-17.29,5.25c-8.04,6.46-15.29,13.85-21.61,22
					c-2.21,3.02-4.07,6.28-5.53,9.73c-1.24,2.98-2.84,5.8-4.78,8.39c-4.95,5.92-14.8,8.65-22.37,9.23h-1.51
					c-5.94,0-11.63-2.66-17.68-5.45c-4.61-2.35-9.47-4.19-14.48-5.49c-4.69-1.14-9.48-1.77-14.31-1.9c-10.5,0-14.35,4.58-15.71,8.43
					c-1.21,2.97-0.89,6.35,0.86,9.03c4.04,6.18,14.63,7.39,25.11,8.1c1.49,0.11,2.75,0.17,3.59,0.3l6.61,0.89
					c8.88,1.21,18.05,2.44,27.12,3.5c6.48,0.73,12.28,1.32,18.02,1.79l2.9,0.15c2.92,0.11,5.97,0.24,7.61,1.47
					c0.69,0.59,1.32,1.26,1.86,1.99c0.41,0.5,0.82,0.99,1.25,1.47c3.96,4.32,9.99,6.61,17.92,6.61c4.64-0.05,9.25-0.69,13.72-1.92
					c13.51-3.67,18.78-12.15,24.88-21.96l1.47-2.33c2.29-2.68,5.23-4.74,8.54-5.97c1.12-0.42,2.31-0.62,3.5-0.58
					c1.17,0,2.4,0.13,3.7,0.24c1.5,0.17,3.01,0.27,4.52,0.28h0.02c1.32,0,2.55-0.13,3.72-0.26c1.17-0.13,2.16-0.19,3.11-0.19
					c0.67-0.01,1.33,0.06,1.99,0.19c7.93,1.66,14.52,7.05,21.5,12.73c2.96,2.4,6.03,4.91,9.21,7.07l5.25,3.72
					c11.97,8.54,26.89,19.17,41.58,19.17c1.38,0.01,2.76-0.09,4.13-0.28c0.89-0.13,1.8-0.2,2.7-0.19c6.63,0,14.44,3.18,23.77,9.75
					c10.4,7.24,11.8,20.36,13.16,33.07v0.22c1.02,9.53,3,20.49,10.03,29.37c5.21,6.66,12.23,10.59,19.02,14.39
					c7.8,4.32,15.13,8.54,18.8,16.43c3.9,8.37,6.34,17.35,7.22,26.54c0.27,5.24,0.27,10.49,0,15.73
					c-0.35,6.48-0.23,12.98,0.35,19.45c1.07,6.7,3,13.24,5.75,19.45c2.03,4.71,3.63,9.59,4.8,14.59c0.82,4.06,1.23,8.15,1.69,12.47
					c0.45,5.43,1.27,10.83,2.46,16.14c2.41,8.5,5.56,16.78,9.4,24.75c1.36,3.11,2.7,6.2,3.98,9.34
					c4.93,12.29,8.41,25.11,10.37,38.21c1.77,11.61,4.54,24.94,11.69,35.77c2.31,3.52,4.88,6.83,7.39,10.01
					c2.51,3.18,5.01,6.48,7.18,9.75c4.47,6.85,5.62,13.44,7.09,21.83v0.37c1.58,8.99,8.39,9.86,13.34,10.46l1.66,0.24
					c12.76,1.64,25.2,5.18,36.91,10.48c2.16,1.02,4.58,2.01,6.94,3.03c11.74,5.04,23.77,10.27,32.98,18
					c4.91,4.49,9.34,9.48,13.21,14.89c4.39,6.12,9.47,11.71,15.13,16.68c2.29,1.88,4.62,3.72,6.96,5.55
					c8.9,7,17.29,13.59,23.21,22.82c9.64,15.13,14.7,33.5,15.91,57.88c0.26,5.58,0.93,11.71,1.56,17.64
					c0.54,4.99,1.1,10.16,1.38,14.78c1.15,17.92,7.26,26.99,17.18,39.79c7.28,9.42,13.18,17.66,19.8,27.1
					c1.58,2.16,3.98,5.19,6.48,8.3c2.51,3.11,5.04,6.22,6.48,8.34c0.82,1.11,1.72,2.16,2.7,3.13c2.36,2.51,5.94,6.31,5.81,9.38
					c-0.12,1.46-0.86,2.8-2.03,3.67c-0.61,0.16-1.25,0.22-1.88,0.19c-6.79,0-19.82-4.56-22.71-6.01
					c-6.56-3.46-12.76-7.56-18.52-12.23c-5.66-4.51-11.66-8.56-17.96-12.12c-5.89-3.54-12.58-5.52-19.45-5.75
					c-1.89-0.01-3.77,0.18-5.62,0.56c-0.13,0-12.73,2.79-15.13,9.68c-0.79,2.16-0.5,4.57,0.78,6.48c2.9,4.19,9.66,4.45,15.63,4.69
					c1.31,0.01,2.63,0.1,3.93,0.26c12.97,3.65,22.91,13.64,32.53,23.28l3.29,3.29c5.75,6.09,12.2,11.48,19.21,16.06
					c3.36,1.97,6.88,3.65,10.53,5.04c5.92,2.42,10.61,4.32,14.52,9.79c4.47,6.69,8.3,13.78,11.45,21.18
					c0.97,2.16,1.97,4.21,2.96,6.33c2.01,4.13,4.32,8,6.48,11.74c4.01,6.15,7.18,12.81,9.42,19.8c2.55,9.34,7.37,32.74-3.2,48.76
					c-3.78,5.75-9.44,9.88-15.41,14.22c-5.57,3.73-10.65,8.14-15.13,13.12c-4.17,4.61-6.22,10.76-5.64,16.94
					c0.86,7.76,6.16,15.45,15.73,22.87c4.17,3.22,8.65,6.07,12.86,8.84c2.01,1.3,4.02,2.62,6.01,3.96c1.39,0.98,2.73,2.04,4,3.18
					c3.44,2.94,6.98,5.99,11.67,5.99c3.94-0.2,7.58-2.14,9.94-5.3c5.53-6.25,7.15-13.79,8.64-21.07c1.36-6.31,2.64-12.25,6.48-16.64
					c7.02-8.13,21.09-9.92,31.08-10.57c5.58-0.35,11.26-0.54,16.92-0.54c49.06,0,91.36,13.12,116.06,36.01
					c19.1,17.81,32.31,41.04,37.84,66.57C1575.72,1988.24,1576.21,2006.74,1572.88,2021.48z"/>
				<path className="st5" d="M1571.5,1969.37c-5.54-25.53-18.74-48.75-37.84-66.57c-24.7-22.89-67-36.01-116.06-36.01
					c-5.66,0-11.35,0.19-16.92,0.54c-9.98,0.65-24.05,2.44-31.08,10.57c-3.85,4.39-5.12,10.33-6.48,16.64
					c-1.49,7.28-3.11,14.83-8.64,21.07c-2.36,3.15-6.01,5.09-9.94,5.3c-4.69,0-8.23-3.05-11.67-5.99c-1.27-1.14-2.6-2.2-4-3.18
					c-1.99-1.34-4-2.66-6.01-3.96c-4.21-2.77-8.69-5.62-12.86-8.84c-9.57-7.41-14.87-15.11-15.73-22.87
					c-0.58-6.19,1.47-12.34,5.64-16.94c4.48-4.98,9.56-9.39,15.13-13.12c5.97-4.34,11.63-8.47,15.41-14.22
					c10.57-16.02,5.75-39.42,3.2-48.76c-2.25-6.99-5.42-13.65-9.42-19.8c-2.16-3.74-4.47-7.61-6.48-11.74
					c-0.99-2.12-1.99-4.17-2.96-6.33c-3.15-7.4-6.99-14.49-11.45-21.18c-3.91-5.47-8.6-7.37-14.52-9.79
					c-3.64-1.38-7.16-3.07-10.53-5.04c-7.02-4.57-13.47-9.96-19.21-16.06l-3.29-3.29c-9.62-9.64-19.56-19.62-32.53-23.28
					c-1.31-0.16-2.62-0.25-3.93-0.26c-5.97-0.24-12.73-0.5-15.63-4.69c-1.28-1.91-1.57-4.32-0.78-6.48c2.4-6.89,15-9.68,15.13-9.68
					c1.85-0.38,3.73-0.57,5.62-0.56c6.87,0.23,13.56,2.21,19.45,5.75c6.3,3.56,12.3,7.62,17.96,12.12
					c5.76,4.67,11.96,8.77,18.52,12.23c2.9,1.45,15.93,6.01,22.71,6.01c0.63,0.03,1.27-0.04,1.88-0.19
					c1.18-0.87,1.92-2.21,2.03-3.67c0.13-3.07-3.46-6.87-5.81-9.38c-0.98-0.97-1.89-2.02-2.7-3.13c-1.45-2.12-3.98-5.23-6.48-8.34
					c-2.51-3.11-4.91-6.14-6.48-8.3c-6.61-9.44-12.51-17.68-19.8-27.1c-9.92-12.79-16.04-21.87-17.18-39.79
					c-0.28-4.62-0.84-9.79-1.38-14.78c-0.63-5.92-1.3-12.06-1.56-17.64c-1.21-24.38-6.27-42.75-15.91-57.88
					c-5.92-9.23-14.31-15.82-23.21-22.82c-2.33-1.84-4.67-3.67-6.96-5.55c-5.66-4.97-10.73-10.57-15.13-16.68
					c-3.87-5.41-8.29-10.41-13.21-14.89c-9.21-7.74-21.25-12.97-32.98-18c-2.36-1.02-4.78-2.01-6.94-3.03
					c-11.72-5.31-24.16-8.84-36.91-10.48l-1.66-0.24c-4.95-0.6-11.76-1.47-13.34-10.46v-0.37c-1.47-8.39-2.61-14.98-7.09-21.83
					c-2.16-3.26-4.67-6.57-7.18-9.75c-2.51-3.18-5.08-6.48-7.39-10.01c-7.15-10.83-9.92-24.16-11.69-35.77
					c-1.96-13.1-5.44-25.92-10.37-38.21c-1.28-3.13-2.62-6.22-3.98-9.34c-3.84-7.96-6.99-16.24-9.4-24.75
					c-1.19-5.32-2.02-10.71-2.46-16.14c-0.45-4.32-0.86-8.41-1.69-12.47c-1.16-5-2.77-9.88-4.8-14.59
					c-2.74-6.21-4.68-12.75-5.75-19.45c-0.58-6.47-0.69-12.97-0.35-19.45c0.27-5.24,0.27-10.49,0-15.73
					c-0.88-9.19-3.32-18.17-7.22-26.54c-3.67-7.89-11-12.1-18.8-16.43c-6.79-3.8-13.81-7.74-19.02-14.39
					c-7.02-8.88-9.01-19.84-10.03-29.37v-0.22c-1.36-12.71-2.77-25.83-13.16-33.07c-9.34-6.57-17.14-9.75-23.77-9.75
					c-0.9,0-1.81,0.06-2.7,0.19c-1.37,0.19-2.75,0.29-4.13,0.28c-14.7,0-29.61-10.63-41.58-19.17l-5.25-3.72
					c-3.18-2.16-6.25-4.67-9.21-7.07c-6.98-5.68-13.57-11.07-21.5-12.73c-0.65-0.14-1.32-0.2-1.99-0.19c-0.95,0-1.95,0.06-3.11,0.19
					c-1.17,0.13-2.4,0.26-3.72,0.26h-0.02c-1.51-0.01-3.02-0.11-4.52-0.28c-1.3-0.11-2.53-0.24-3.7-0.24
					c-1.19-0.03-2.38,0.17-3.5,0.58c-3.31,1.23-6.25,3.28-8.54,5.97l-1.47,2.33c-6.09,9.81-11.37,18.28-24.88,21.96
					c-4.47,1.23-9.09,1.88-13.72,1.92c-7.93,0-13.96-2.29-17.92-6.61c-0.43-0.48-0.84-0.97-1.25-1.47c-0.54-0.73-1.17-1.4-1.86-1.99
					c-1.64-1.23-4.69-1.36-7.61-1.47l-2.9-0.15c-5.75-0.48-11.54-1.06-18.02-1.79c-9.08-1.06-18.24-2.29-27.12-3.5l-6.61-0.89
					c-0.84-0.13-2.1-0.19-3.59-0.3c-10.48-0.71-21.07-1.92-25.11-8.1c-1.75-2.69-2.07-6.06-0.86-9.03
					c1.36-3.85,5.21-8.43,15.71-8.43c4.82,0.13,9.62,0.77,14.31,1.9c5.01,1.3,9.87,3.14,14.48,5.49c6.05,2.79,11.74,5.45,17.68,5.45
					h1.51c7.56-0.58,17.42-3.31,22.37-9.23c1.94-2.58,3.54-5.4,4.78-8.39c1.47-3.44,3.32-6.71,5.53-9.73
					c6.32-8.15,13.57-15.54,21.61-22c4.98-3.69,11.09-5.55,17.29-5.25c3.81,0.08,7.62,0.39,11.39,0.93l3.63,0.43
					c1.43,0.17,2.88,0.24,4.32,0.24c8.65-0.19,17.14-2.41,24.77-6.48c8.02-4.13,11.07-8.3,9.08-12.45c-1.4-2.86-2.96-5.64-4.69-8.32
					c-0.93-1.45-2.01-2.98-3.09-3.33c-1.23-0.36-2.5-0.52-3.78-0.45h-1.79c-3.79,0.23-7.54-0.97-10.48-3.37
					c-4.25-3.84-7.19-8.92-8.41-14.52c-0.57-2.43-0.94-4.9-1.12-7.39c-0.73-7.37-1.53-10.59-4.6-11.43
					c-1.06-0.27-2.15-0.4-3.24-0.39c-4.23,0.29-8.4,1.17-12.38,2.62l-1.66,0.52c-5.81,1.96-11.45,4.39-16.86,7.28
					c-3.67,1.84-7.48,3.74-11.39,5.38c-1.42,0.63-2.94,0.97-4.5,1.02c-2.58,0.08-4.97-1.38-6.07-3.72
					c-1.24-3.56-0.34-7.51,2.31-10.18c3.01-3.21,6.5-5.94,10.35-8.08c9.35-5.37,19.72-8.7,30.45-9.77c4-0.52,8.04-0.65,11.95-0.8
					c8.21-0.28,15.95-0.56,22.89-3.98c10.11-4.93,19.84-13.25,28.94-24.7c6.03-7.59,8.95-17.14,11.43-26.26
					c0.69-2.53,1.27-5.12,1.88-7.85c1.9-8.45,3.89-17.18,9.1-24.68c3.96-5.23,8.62-9.88,13.85-13.83c2.25-1.84,4.34-3.57,6.33-5.42
					c6.33-5.9,7.56-14.42,8.84-23.43c0.17-1.15,0.32-2.2,0.5-3.31c1.53-10.05,5.84-20.64,13.53-33.28c1.53-2.51,3.35-4.97,5.1-7.37
					c4.52-6.09,8.73-11.82,8.84-18.31c-0.28-3.27-1.28-6.44-2.94-9.27c-2.27-4.71-4.82-10.05-3.28-16.53
					c1.49-5.33,3.72-10.42,6.63-15.13c1.61-2.79,3.05-5.68,4.32-8.65l1.62-4.04c2.93-8.23,6.85-16.06,11.67-23.34
					c3.29-4.24,7.02-8.12,11.13-11.56c3.78-3.09,7.18-6.62,10.14-10.5c4.28-6.21,6.54-13.59,6.48-21.14
					c0.04-12.95-5.66-27.34-16.97-42.77l-1.19-1.64c-5.53-7.61-11.26-15.5-19.58-17.48c-2.13-0.49-4.3-0.73-6.48-0.71
					c-5.75,0.27-11.44,1.17-16.99,2.7c-3.05,0.71-6.18,1.45-9.27,1.99c-14.53,2.53-29.25,3.83-44,3.87c-2.64,0-5.27-0.04-7.91-0.13
					c-9.88-0.58-19.71-1.86-29.42-3.83c-10.25-2.15-20.67-3.41-31.14-3.76c-1.86,0-3.7,0.06-5.47,0.19c-3.44,0.24-7,0.65-10.46,1.06
					c-5.86,0.78-11.77,1.23-17.68,1.34c-3.74,0.03-7.48-0.29-11.17-0.93c-3.2-0.58-6.53-1.32-9.68-2.01
					c-7.07-1.85-14.31-2.94-21.61-3.24c-3.86-0.05-7.7,0.53-11.37,1.71c-3.39,1.24-6.68,2.75-9.83,4.52
					c-3.6,2.01-7.37,3.72-11.26,5.1c-3.24,1-6.62,1.46-10.01,1.36c-1.56,0-3.24-0.02-4.69-0.13c-1.45-0.11-2.9-0.11-4.32-0.11
					c-3.18-0.2-6.36,0.43-9.23,1.84c-2.98,1.99-5.57,4.52-7.63,7.46c-2.44,3.33-5.39,6.25-8.75,8.65
					c-8.34,5.51-18.33,6.09-27.19,6.59l-3.11,0.11c-3.29-0.22-6.58,0.33-9.62,1.62c-2.74,1.64-4.41,4.95-6.16,8.45
					c-0.35,0.69-0.67,1.36-1.02,2.01c-3.65,6.67-8.69,12.49-14.78,17.05c-4.54,3.64-10.23,5.52-16.04,5.32
					c-1.97,0-3.89-0.13-6.05-0.26l-2.55-0.15c-0.54,0-1.12,0-1.77,0c-1.23,0-2.36-0.11-4.06,0c-1.71,0.11-3.5,0.11-5.27,0.11
					c-4.24,0-9.57-0.26-13.31-2.42c-2.82-1.23-4.49-4.17-4.13-7.22c0.84-5.75,8.77-7.72,9.66-7.91c2.85-0.57,5.74-0.88,8.65-0.93
					c1.64-0.09,3.18-0.15,4.67-0.32c9.07-1.4,17.52-5.44,24.31-11.61c11.28-9.79,19.34-22.93,27.12-35.73
					c2.68-4.41,5.45-8.99,8.32-13.31c4.16-6.63,9.02-12.79,14.5-18.37c0.69-0.65,1.51-1.38,2.4-2.16c2.66-2.36,7.61-6.76,7.22-9.36
					c-0.02,0-0.91-0.97-5.62-0.97c-1.08,0-2.12,0-2.96,0h-0.5c-2.07,0.09-4.11,0.26-6.16,0.43c-3.03,0.26-6.18,0.52-9.36,0.52
					c-4.36,0.07-8.71-0.59-12.86-1.95c-4.24-1.47-9.06-4.73-9.83-9.51c-0.46-3.42,1.12-6.8,4.04-8.65c2.77-1.86,6-2.91,9.34-3.05
					c1.52-0.1,3.02-0.42,4.45-0.93c2.16-0.99,2.44-2.51,1.58-8.99c-0.43-2.47-0.6-4.97-0.5-7.48c0.63-7.31,3.52-17.53,9.77-24.34
					c4-4.32,9.14-6.33,14.11-8.28c2.89-1.02,5.67-2.33,8.3-3.91c5.48-4.01,10.55-8.56,15.13-13.57c1.86-1.9,3.72-3.8,5.6-5.62
					c10.27-10.03,20.36-20.6,30.86-32.42c9.38-10.63,19.8-21.61,33.15-28.96c1.9-1.04,3.83-1.92,5.71-2.77
					c2.92-1.21,5.7-2.73,8.3-4.52c6.09-4.63,9.96-12.1,13.7-19.32l1.08-2.16c0.84-1.62,1.82-3.26,2.77-4.84
					c2.81-4.69,5.71-9.53,5.36-14.07c-0.59-3.18-1.87-6.18-3.74-8.82c-1.22-1.96-2.3-4.01-3.24-6.12
					c-3.89-9.34,1.45-16.84,5.77-22.89l0.82-1.15c1.93-2.39,3.47-5.07,4.58-7.93c0.77-1.88,0.67-4-0.28-5.79
					c-1.73-3.07-6.57-5.66-13.64-7.39c-5.72-1.59-11.61-2.5-17.55-2.72c-3.86-0.07-7.68,0.67-11.24,2.16
					c-2.71,1.25-5.32,2.7-7.82,4.32c-4.26,2.9-8.94,5.12-13.88,6.59c-3.6,0.85-7.29,1.24-10.98,1.17c-1.82,0-3.78-0.13-5.51-0.13
					s-3.48,0-5.21,0c-2-0.02-4,0.09-5.99,0.35c-5.01,0.67-9.42,3.24-14.11,5.97c-3.44,2.13-7.05,3.95-10.81,5.45
					c-3.68,1.31-7.57,1.96-11.48,1.92c-6.22-0.24-12.38-1.21-18.37-2.9c-5.06-1.46-10.28-2.32-15.54-2.57
					c-2.74-0.04-5.47,0.45-8.02,1.45c-3.87,1.84-7.45,4.22-10.66,7.07c-3.39,2.92-7.09,5.45-11.04,7.54
					c-3.92,2.03-8.28,3.05-12.69,2.98h-1.43c-1.37-0.06-2.73-0.19-4.08-0.39c-1.19-0.17-2.39-0.28-3.59-0.32
					c-1.22-0.1-2.44,0.29-3.37,1.08c-0.61,0.89-1.12,1.84-1.53,2.83c-1.34,2.92-3.37,7.24-8.02,7.24c-2.52-0.17-4.86-1.36-6.48-3.29
					c-3.41-3.46-3.54-8.26-3.54-12.1c0.12-1.86-0.11-3.72-0.67-5.49c-1.69-3.18-5.81-3.52-7.5-3.52c-2.8-0.04-5.54,0.81-7.82,2.44
					c-1.37,1.62-2.53,3.41-3.46,5.32c-3.03,5.42-6.81,12.17-13.4,12.17c-0.8,0.01-1.59-0.1-2.36-0.3c-5.04-1.23-5.99-7-6.63-10.81
					c-0.12-0.96-0.31-1.9-0.58-2.83c-2.92-6.75-8.62-11.9-15.63-14.11c-4.1-1.44-8.42-2.17-12.77-2.16
					c-9.94,0-22.61,3.93-28.38,14.93c-3.35,6.4-2.98,13.36-2.57,20.73c0.43,7.95,0.91,16.19-3.24,24.38
					c-3.79,7.25-5.13,15.53-3.8,23.6c0.97,2.76,2.51,5.28,4.52,7.41c3.42,4.3,7.28,9.19,6.09,15.37c-0.86,4.54-4.5,9.94-16.86,9.94
					c-4.78-0.13-9.54-0.63-14.24-1.49c-1.04-0.17-1.92-0.41-2.64-0.41l-2.38-0.35c-3.08-0.55-6.21-0.87-9.34-0.95
					c-2.53,0-5.79,0.35-7,2.03c-0.71,0.93,2.61,5.1,3.72,6.48c0.73,0.91,1.4,1.73,1.9,2.46c4.23,5.73,7.31,12.23,9.08,19.13
					c0.19,0.84,0.43,1.73,0.67,2.64c1.38,5.14,3.09,11.54,0.17,17.44c-2.5,4.91-7.46,8.07-12.97,8.26c-1.77,0-3.52-0.39-5.12-1.15
					c-2.48-1.42-4.51-3.51-5.86-6.03c-0.43-0.67-0.93-1.45-1.38-2.05c-0.14,1.05-0.23,2.11-0.26,3.18
					c-0.43,9.49,4.52,17.72,14.7,24.49c4.76,3.01,9.72,5.71,14.83,8.08c0.67,0.37,1.75,0.73,2.83,1.17
					c5.58,2.18,15.91,6.27,14.59,15.5c-0.99,6.87-8.49,10.46-15.41,10.46c-1.42,0.01-2.83-0.17-4.19-0.54
					c-1.38-0.46-2.72-1.02-4.02-1.66c-4.06-1.82-12.41-5.66-16.73-5.66c-0.01,0.12-0.01,0.25,0,0.37c0.8,3.18,7.09,5.17,11.24,6.48
					l2.16,0.71l0.32,0.11c4.97,1.64,10.27,3.8,10.81,8.41c0.22,2.72-1.25,5.29-3.7,6.48c-1.45,0.87-3.11,1.31-4.8,1.28
					c-0.72-0.01-1.45-0.07-2.16-0.17c-4.97-0.99-9.63-3.15-13.59-6.31c-2.45-1.87-5.16-3.37-8.04-4.45
					c-0.35-0.12-0.73-0.18-1.1-0.17c-1.24,0.1-2.45,0.39-3.61,0.84c-2.04,0.78-4.19,1.22-6.38,1.3c-0.53,0-1.07-0.04-1.6-0.11
					c-3.18-0.67-6.14-2.1-8.65-4.17c-0.67-0.48-1.32-0.93-1.9-1.3l-0.28-0.17c-2.31-1.4-6.18-3.76-8.34-3.76
					c-0.11-0.02-0.22-0.02-0.32,0c-0.36,0.43-0.59,0.96-0.65,1.51c-0.41,1.69,1.79,5.51,2.96,7.67c0.37,0.67,0.65,1.19,1.02,1.86
					c0.37,0.67,0.78,1.38,1.19,2.16c4.28,7.82,12.25,22.39-0.71,30.11c-0.95,0.58-2.04,0.88-3.16,0.89
					c-5.06,0-8.15-5.66-12.47-13.49c-2.77-5.21-8.19-14.89-11.41-14.89c-0.91,0-2.42,1.04-4.32,3.96
					c-3.61,5.55-4.04,15.71-2.36,21.94c0.48,1.36,4.97,4.56,7.65,6.48c6.48,4.62,16.27,11.63,10.63,19.75
					c-2.24,2.82-5.45,4.7-9.01,5.25c-3.09,0.84-4,1.23-4.43,2.46c-1.24,3.86-0.83,8.06,1.12,11.61c4.19,8.3,12.77,14.26,21.94,20.16
					l1.04,0.6c2.29,1.25,6.98,3.85,6.48,9.01c-0.41,4.37-4.52,7.2-10.42,7.2h-0.24c-6.63-0.11-13.1-3.98-18.54-7.74
					c-0.91-0.6-1.82-1.36-2.77-2.16c-1.75-1.43-4.37-3.59-5.79-3.59c-0.25,0.01-0.49,0.11-0.67,0.28c-1.75,1.36-2.4,6.96-2.75,10.01
					c-0.15,1.15-0.26,2.2-0.41,3.11c-1.69,10.44-0.15,29.46,7.11,37.95c2.04,2.72,5.24,4.32,8.65,4.32
					c1.58-0.03,3.15-0.27,4.67-0.73l1.38-0.43c5.59-2.01,11.43-3.25,17.35-3.67c9.77,0,11.35,6.07,11.56,8.65
					c0.17,3.74-1.67,7.29-4.82,9.31c-2.06,1.31-4.33,2.27-6.7,2.85l-0.48,0.15c-7.11,2.16-11,4.78-11.67,6.48
					c-0.09,0.19-0.22,0.5,0.24,1.3c0.24,0.3,0.78,1.25,4.32,1.25c1.43,0,3.11-0.15,4.32-0.26c1.21-0.11,2.36-0.24,3.37-0.24h1.02
					c5.95-0.02,11.82,1.31,17.18,3.89c4.41,2.23,10.27,7.67,8.39,13.51c-1.09,3.69-4.54,6.17-8.39,6.01
					c-4.08-0.35-8.06-1.4-11.78-3.11c-1.89-0.83-3.85-1.48-5.86-1.97c-0.89,0.17-1.69,1.51-2.77,3.52c-0.77,1.55-1.72,3-2.83,4.32
					c-0.35,0.38-0.75,0.69-1.21,0.93c0.31,0.91,0.31,1.9,0,2.81l-0.67,2.16c-2.77,8.97-3.26,11.5,2.9,14.61
					c2.11,0.73,4.28,1.26,6.48,1.6c5.4,1.06,11.5,2.25,15.6,7.26c2.02,2.37,2.43,5.73,1.04,8.52c-2.55,4.44-7.17,7.3-12.28,7.61
					c-0.47,0-0.94-0.04-1.4-0.11c-3.11-0.69-6.05-2-8.65-3.85l-2.83-1.73c-3-1.84-6.09-3.74-8.82-3.74h-0.41
					c-0.67,0.04-2.72,0.17-4.13,3.67c-1.88,4.67-0.97,11,1.19,13.31c1.67,1.52,3.6,2.73,5.71,3.57c3.8,1.82,8.52,4.08,10.81,9.36
					c1.55,2.77,1.55,6.14,0,8.9c-1.67,2.34-4.45,3.61-7.31,3.35c-3.95-0.23-7.82-1.17-11.43-2.77c-0.84-0.37-1.73-0.8-2.68-1.25
					c-2.86-1.76-6.05-2.93-9.38-3.42c-0.88-0.04-1.75,0.23-2.46,0.76c-3.41,2.55-4.99,10.31-5.92,14.96l-0.19,0.95
					c-0.74,2.5-0.46,5.2,0.78,7.5c1.49,1.88,3.67,3.09,6.05,3.35c0.45,0.09,0.99,0.19,1.6,0.19c3.03,0.28,8.65,0.8,10.29,5.81
					c2.1,6.46-5.04,12.6-9.36,16.25l-1.1,0.95c-2.64,2.51-5.07,5.22-7.28,8.1c-2.38,3.07-4.99,5.96-7.8,8.65
					c-3.9,3.35-8.96,5.04-14.09,4.71c-1.6,0-3.2-0.17-4.8-0.17c-1.21-0.15-2.4-0.15-3.57-0.15h-0.5c-4.35,0.11-8.67,0.79-12.84,2.03
					c-11.78,3.11-14.76,6.72-15.5,8.1c-0.51,0.97-0.55,2.11-0.11,3.11c0.26,0.73,1.38,1.23,3.22,1.99c0.52,0.22,1.06,0.43,1.56,0.67
					c2.72,1.05,4.66,3.48,5.1,6.35c0.93,6.48-6.2,13.53-14.05,20.19l-0.97,0.84c-1.51,1.34-3.13,2.66-4.78,4
					c-4.95,4.02-10.07,8.19-12.56,13.33c-0.46,0.75-0.73,1.61-0.78,2.49c1.74,1.07,3.66,1.8,5.66,2.16c3.55,0.61,6.89,2.1,9.73,4.32
					c1.67,1.43,2.67,3.49,2.75,5.68c0.09,3.59-2.46,6.14-4.5,8.17c-0.68,0.68-1.31,1.4-1.9,2.16c0.98,0.6,2.09,0.95,3.24,1.04
					c0.41,0.06,2.08,0.19,3.42,0.19c11.74,0.37,15.99,0.84,17.55,4.32c0.74,1.68,0.46,3.63-0.71,5.04c-1.1,1.34-2.4,2.68-3.63,3.96
					c-4.11,4.3-6.83,7.41-6.48,10.96c0.88,1.01,1.86,1.92,2.94,2.72c3,2.4,7.8,6.38,5.17,11.71c-1.32,2.66-4.08,4.02-8.23,4.02
					c-2.08-0.04-4.16-0.27-6.2-0.67h-0.52c-1.73-0.28-3.5-0.65-5.29-1.04c-3.39-0.84-6.86-1.34-10.35-1.49
					c-2.72-0.1-5.41,0.65-7.67,2.16c-1.6,1.19-1.49,1.9-1.49,2.16c0.26,2.36,4.39,6.18,8.32,7.76c3.66,1.31,7.53,1.91,11.41,1.77
					c2.31,0,4.91-0.15,7.07-0.26c2.16-0.11,4.32-0.24,6.48-0.24c10.05-0.13,20.51-0.69,28.53-6.05c0.63-0.35,1.34-0.86,2.16-1.47
					c5.99-4.32,12.34-8.65,18.13-8.65c0.98-0.06,1.96,0.05,2.9,0.32c4.59,1.31,7.25,6.09,5.94,10.69c-0.08,0.49-0.13,0.98-0.15,1.47
					c0.04,0.11,0.17,0.11,0.5,0.11c1.86-0.28,3.62-1.07,5.06-2.29c4.92-4.01,8.73-9.22,11.07-15.13c3.11-7.7,5.47-15.68,7.05-23.84
					c1.21-5.45,2.49-11.09,4.19-16.58c2.81-8.8,9.66-14.8,15.78-20.08c2.53-2.16,4.8-4.32,6.96-6.48
					c9.64-10.22,18.76-21.38,27.4-32.18l2.16-2.64c0.86-1.06,1.71-2.27,2.62-3.54c2.49-4.09,5.87-7.58,9.88-10.2
					c2.18-1.15,4.6-1.76,7.07-1.77c4.93,0,8.45,2.74,8.95,7.07c1.49,12.84-7.93,21.05-16.25,28.29c-3.99,3.07-7.52,6.71-10.46,10.81
					c-4.8,8.19-7.75,17.33-8.65,26.78c-0.17,1.9-0.32,3.76-0.32,5.92c-0.26,6.48-0.54,13.21-3.98,19.45
					c-2.18,3.98-4.56,7.89-6.87,11.69c-5.51,9.06-10.72,17.64-13.79,27.25c-2.07,6.48-0.76,11.3,0.93,17.29
					c0.99,3.31,1.77,6.68,2.31,10.09c0.44,4.96-0.23,9.95-1.97,14.61c-0.84,2.53-1.44,5.14-1.77,7.78
					c-0.15,1.64-0.26,3.31-0.37,4.95c-0.12,6.01-1.06,11.98-2.79,17.74c-3.47,9.34-7.69,18.39-12.62,27.06
					c-3.87,7.22-6.89,14.55-2.81,19.19c0.56,0.49,1.18,0.92,1.84,1.27c2.16,1.3,6.16,3.72,5.96,8.93c-0.44,3.21-1.46,6.31-3.03,9.14
					c-1.32,2.85-3.52,7.69-2.77,9.34c0.17,0.41,0.91,0.84,1.99,1.15c0.46,0.06,0.92,0.06,1.38,0c1.08,0,1.99-0.09,3.54-0.19
					s3.16-0.24,4.78-0.24c5.92,0,9.68,1.99,11.2,5.9c2.55,6.57-4.91,11.32-9.23,14.16c-0.95,0.6-2.01,1.3-2.4,1.62
					c-4.28,3.7-6.09,9.25-8.04,15.13c-0.48,1.47-0.95,2.9-1.45,4.32l-0.56,1.53c-3.62,9.04-6.35,18.4-8.15,27.97
					c-1.23,9.71-1.9,19.48-1.99,29.26v2.7c-0.32,10.44-3.85,19.93-7.24,29.09c-1.02,2.79-2.1,5.66-3.03,8.49
					c-0.73,2.2-2.98,8.93-1.36,11.99c0.51,0.89,1.39,1.51,2.4,1.69c1.25,0.35,2.55,0.53,3.85,0.54c6.87,0,13.12-5.19,19.15-10.2
					l0.78-0.63c4.5-3.74,5.4-5.88,4.84-11.41c-3.13-29.5,3.72-55.54,10.81-77.16c1.6-4.84,3.59-9.47,5.53-13.94l1.21-2.85
					c0.37-0.84,0.71-1.71,1.06-2.55c1.67-4.88,4.23-9.41,7.56-13.36c3.34-2.96,7.27-5.17,11.54-6.48c9.23-3.54,10.63-5.08,9.23-9.68
					c-1.15-3.43-3.14-6.51-5.77-8.99c-0.29-0.27-0.6-0.53-0.93-0.76c-2.64-1.99-7.05-5.32-5.55-9.86c0.99-2.87,3.84-4.66,6.85-4.32
					c2.07,0.03,4.13,0.44,6.05,1.21c3.86,2.04,7.15,5.01,9.57,8.65c0.86,1.1,1.69,2.14,2.51,3.03c2.47,2.15,5.45,3.63,8.65,4.32
					l0.35,0.11c0.82,0.26,1.94,0.52,3.13,0.8c3.56,0.53,6.96,1.87,9.92,3.91c3.63,3.09,3.72,7.33,3.8,10.81
					c0.09,3.48,0.32,3.91,0.89,4.15c0.61,0.25,1.24,0.45,1.88,0.58c0.16-1.1,0.27-2.2,0.32-3.31c0.09-1.21,0.15-2.46,0.35-3.74
					c0.81-4.61,1.95-9.15,3.39-13.59c2.07-5.77,3.25-11.83,3.5-17.96c-0.17-2.5-0.61-4.98-1.32-7.39
					c-1.19-4.69-2.55-10.01-0.76-15.5c2.16-6.29,9.62-9.03,15.13-11.02c1.12-0.45,2.36-0.84,3.29-1.25
					c4.94-2.32,10.32-3.58,15.78-3.67c1.16,0,2.31,0.06,3.46,0.19c6.61,0.99,13.07,2.77,19.26,5.3c2.94,1.08,5.77,2.12,8.65,2.98
					l1.21,0.5c7.8,1.75,12.25,3.41,13.51,7.07c0.63,1.88,0.21,3.95-1.1,5.42c-2.12,2.55-6.05,2.9-9.62,2.9c-1.21,0-2.42,0-3.37,0
					h-1.08c-6.12-0.24-12.23-0.82-18.28-1.75c-3.39-0.45-6.89-0.93-10.33-1.25c-1.77-0.17-3.22-0.28-4.52-0.28
					c-2.74,0-3.46,0.54-3.61,0.71c-0.5,0.95-0.67,2.05-0.45,3.11c0.25,5.49,0.14,10.99-0.32,16.47c-0.24,3.65-0.48,7.09-0.48,10.57
					c-0.11,10.35,0.4,20.7,1.53,30.99c1.15,9.92,3.59,21.22,12.97,29.76c2.16,1.97,4.63,3.8,7,5.58
					c8.77,6.53,18.74,13.94,14.98,29.5c-1.92,8.1-4.3,16.08-7.13,23.9c-1.96,5.05-4.2,9.97-6.72,14.76
					c-1.64,3.31-3.35,6.72-4.8,10.09c-1.77,4.39-3.26,8.88-4.47,13.44c-1.24,4.63-2.74,9.18-4.5,13.64
					c-3.62,8.12-8.12,15.83-13.42,22.97l-1.21,1.77c-8.99,12.88-18.28,26.24-18.13,41.86c0.02,1.17,0.06,2.36,0.19,3.54
					c0.34,2.8,1.07,5.54,2.16,8.15c0.78,2.02,1.46,4.07,2.03,6.16c1.41,6.8,1.16,13.83-0.71,20.51c-1.64,6.25-5.12,7.2-7.05,7.2
					c-2.79,0-5.21-1.84-7.22-5.45c-3.26-6.35-5.41-13.2-6.38-20.27c-1.12-7.26-3.11-7.26-4.19-7.26h-0.41
					c-0.88-0.01-1.7,0.44-2.16,1.19c-2.77,4.04-1.28,15.95,1.56,25.18c1.63,4.26,3.86,8.27,6.61,11.91c0.8,1.19,1.62,2.36,2.38,3.54
					c8.02,11.99,15.1,24.58,21.16,37.67c1.17,2.79,2.09,5.69,2.75,8.65c1.23,4.84,2.27,9.03,5.84,11.89
					c1.26-1.27,2.02-2.95,2.16-4.73c0.19-4.56-1.99-10.18-4.32-16.14c-4.45-11.22-9.81-25.18,2.03-35.57
					c3.57-3.16,8.2-4.86,12.97-4.78c4.27,0.1,8.46,1.09,12.32,2.92c12.1,5.53,21.33,14.8,30.26,23.77c2.94,2.83,5.97,5.88,8.97,8.65
					c0.93,0.86,1.9,1.77,2.9,2.75c5.4,5.21,12.1,11.56,18.69,11.69c0.32,0.02,0.63-0.02,0.93-0.13c0.89-1.04,0.35-6.38,0.11-8.65
					c-0.45-2.86-0.51-5.77-0.17-8.65c0.48-2.21,1.14-4.38,1.97-6.48c2.1-5.79,2.53-8.13,0.76-9.64c-1.24-0.95-2.56-1.78-3.96-2.49
					c-3.91-2.16-8.75-4.78-10.01-10.81c-0.52-2.44-0.93-6.22,1.23-8.93c1.32-1.58,3.3-2.47,5.36-2.42c3.36,0.36,6.59,1.49,9.44,3.29
					l1.49,0.76c2.73,1.44,5.64,2.53,8.65,3.24c0.71,0.13,1.44,0.19,2.16,0.17c1.1,0,2.27-0.09,3.61-0.22s2.81-0.24,4.32-0.24
					c2.87-0.1,5.71,0.59,8.21,1.99c3.98,2.42,5.6,7.46,6.66,10.81l0.71,2.16c2.2,6.94,3.39,9.38,8.08,9.51
					c1.25-0.13,2.46-0.57,3.5-1.27c2.06-1.32,4.43-2.06,6.87-2.16c1.55,0.01,3.09,0.32,4.52,0.93c2.32,1.24,4.88,1.98,7.5,2.16
					c0.72,0.01,1.45-0.05,2.16-0.17c7.24-1.08,15.71-5.84,16.79-11.65c1.97-10.57,10.01-13.16,17.1-15.45l2.79-0.91
					c0.67-0.26,1.43-0.58,2.16-0.89c2.35-1.16,4.92-1.82,7.54-1.95h1.32c2.53,0.38,4.96,1.25,7.15,2.55
					c1.33,0.82,2.79,1.39,4.32,1.69h1.08h0.19c-0.09-0.71-0.28-1.53-0.41-2.16c-0.76-2.52-0.87-5.19-0.32-7.76
					c1.86-6.48,8.58-9.29,13.79-9.73c1.21-0.11,2.46-0.26,3.7-0.26c3.31-0.06,6.6-0.51,9.81-1.32c2.03-0.72,3.84-1.96,5.23-3.61
					c1.57-1.67,3.35-3.12,5.3-4.32c2.66-1.6,8.34-2.31,12.67-2.31c7.91,0,12.54,2.1,13.57,6.48c0.38,1.59,0.06,3.08-0.69,4.52
					c-1.33,2.52-4.05,4.87-6.91,7.31c-0.75,0.61-1.47,1.26-2.16,1.95c-2.88,3.52-5.56,7.21-8.02,11.04l-0.78,1.17
					c-1.3,2.03-2.85,4.02-4.32,5.94c-3.31,4.28-6.7,8.69-8.02,13.23c-0.44,2.4-0.34,4.86,0.28,7.22c0.84,4.06,1.62,9.14-1.32,14.33
					c-2.63,4.33-5.74,8.35-9.27,11.97c-1.64,1.84-3.22,3.57-4.67,5.38c-3.32,3.59-5.45,8.12-6.09,12.97
					c-0.22,1.86-0.32,3.76-0.43,5.77c0.06,5.55-1.01,11.06-3.16,16.19l-0.67,1.43c-3.91,8.1-6.01,12.84-2.81,20.62
					s9.53,14.39,15.65,20.75l1.95,2.01c7.52,7.78,10.29,17.74,12.97,27.4c1.29,5.25,3.02,10.38,5.17,15.35
					c4.04,8.62,8.97,13.98,12.86,13.98c2.19-0.25,4.19-1.36,5.55-3.09c2.42-2.76,5.83-4.47,9.49-4.75c2.26-0.04,4.41,0.98,5.79,2.77
					c1.83,2.79,2.23,6.29,1.08,9.42c-1.76,5.66-4.65,10.9-8.49,15.41c-0.76,0.93-1.53,1.75-2.31,2.55
					c-2.59,2.75-3.09,3.39-2.59,5.55c1.12,4.8,6.33,12.62,8.99,12.62c0.39,0,0.86-0.45,1.12-0.71c3.46-4.09,6.18-8.76,8.04-13.79
					c2.19-5.69,5.27-10.99,9.12-15.71c2.09-2.49,5.16-3.93,8.41-3.96c2.81,0.17,5.55,0.9,8.06,2.16c1.76,0.9,3.67,1.46,5.64,1.66
					c0.6,0.01,1.2-0.11,1.75-0.35c1.27-0.63,2.49-1.35,3.65-2.16c2.68-2.23,5.97-3.62,9.44-3.98c2.46,0,6.18,1.1,8.95,6.31
					c3.29,6.22,5.04,17.27,2.29,24.98c-1.16,2.97-2.67,5.79-4.5,8.41c-1.67,2.29-2.98,4.82-3.89,7.5c-0.57,3.06-0.57,6.19,0,9.25
					c0.92,4.69,0.67,9.54-0.73,14.11c-2.25,6.01-7.95,8.95-12.54,11.3c-1.55,0.76-3.05,1.61-4.5,2.55
					c-5.73,3.93-7.72,12.28-9.49,19.65l-0.5,2.03c-3.11,12.6-1.86,19.71,4.02,23.04c1.8,0.88,3.67,1.6,5.6,2.16
					c4.8,1.71,9.77,3.7,10.37,8.19c0.6,4.5-3.52,7.95-7.2,10.44c-4.01,2.67-7.59,5.94-10.59,9.7c-4.73,5.99-12.6,17.94-12.08,30.62
					c0.26,6.53,4.21,12.15,8.39,18.11l2.51,3.59c2.2,3.31,4.02,6.72,5.79,10.03c2.6,5.63,6.11,10.8,10.4,15.28l1.06,0.97
					c7.56,6.76,12.56,12.3,12.25,17.57c-0.23,3.86-3.44,6.86-7.31,6.83c-6.2,0-12.9-7.5-14.18-9.01c-1.9-2.25-3.76-4.54-5.62-6.83
					c-2.72-3.35-5.53-6.83-8.45-10.05c-8.71-9.62-13.05-10.37-14.18-10.37c-1.6,0-2.79,1.73-3.76,5.1c-0.56,1.94,0.28,4,3.07,7.28
					c1.14,1.32,2.34,2.58,3.61,3.78c5.23,5.23,12.49,12.41,8.65,22.26c-0.81,1.95-2,3.66-3.47,5.07c-2.75,2.64-6.48,4.19-10.45,4.16
					c-3.58,0.03-7.04-1.32-9.66-3.76c-2.72-2.8-4.92-6.06-6.48-9.64c-1.1-3.06-2.85-5.85-5.12-8.17c-3.03-2.33-6.77-3.53-10.59-3.39
					c-1.62,0.01-3.23,0.16-4.82,0.43c-5.6,1.21-11.04,3.03-16.25,5.4c-7.54,3.07-15.37,6.27-24.01,6.48h-1.66
					c-9.49,0.21-18.87-2.17-27.1-6.89c-2.42-1.45-6.27-3.83-8.9-3.83c-0.72-0.07-1.41,0.29-1.77,0.91c-0.89,1.67-1.5,3.47-1.82,5.34
					c-0.99,4.34-2.33,9.75-7.48,12.28c-3.47,1.52-7.19,2.39-10.98,2.55c-2.2,0.14-4.37,0.51-6.48,1.12c-2.15,0.9-4.14,2.15-5.88,3.7
					c-2.45,2.11-5.23,3.8-8.23,4.99c-1.39,0.49-2.85,0.74-4.32,0.76c-2.94,0.2-5.76-1.17-7.43-3.59c-1.42-2.42-1.42-5.41,0-7.82
					c1.67-2.71,3.88-5.06,6.48-6.89c1.73-1.4,5.36-4.32,5.36-5.47c-0.17-0.43-0.17-0.71-0.17-0.71c-0.19-0.02-0.38-0.02-0.56,0
					c-2.22,0.23-4.4,0.75-6.48,1.53c-2.88,1.06-5.9,1.7-8.97,1.9c-1.64,0.03-3.26-0.36-4.71-1.12c-5.96-3.29-8.82-9.94-10.98-15.28
					c-0.37-0.89-0.73-1.75-1.1-2.55c-3.85-8.64-7.74-12.19-13.4-12.19c-0.86,0.01-1.72,0.09-2.57,0.24
					c-1.74,0.25-3.5,0.37-5.25,0.35c-2.14,0-4.39-0.11-6.77-0.24c-2.38-0.13-4.84-0.26-7.2-0.26c-7.69,0-11.71,1.47-13.42,4.91
					c-0.73,1.45-0.43,2.38,1.04,5.49c0.48,0.97,0.95,2.05,1.36,3.13c2.44,6.46,1.62,12.73,0.84,18.87
					c-0.78,6.14-1.45,11.43,1.08,16.3c4.02,7.87,12.75,17.38,23.77,18.22c1.59-0.29,3.14-0.74,4.65-1.32
					c2.64-1.07,5.42-1.74,8.26-1.97c2.14-0.11,4.2,0.86,5.47,2.59c1.31,2.35,1.42,5.18,0.3,7.63c-0.62,1.7-1.22,3.41-1.82,5.12
					c-2.59,8.45-6.22,16.56-10.81,24.12c-7.74,11.82-18.57,20.08-29.07,28.1c-4.08,3.22-8.32,6.33-12.25,9.7l-0.93,0.78
					c-16.45,13.7-19.97,19.6-20.77,21.61h0.26c0.4-0.01,0.8-0.06,1.19-0.15c1.06-0.3,2.1-0.69,3.11-1.15l2.01-0.84
					c4.82-1.82,10.05-2.31,15.13-1.43c3.78,0.85,7.36,2.42,10.55,4.62c1.66,1.1,3.42,2.04,5.25,2.81c-0.13-0.26-0.13-0.48-0.13-0.67
					c-0.19-1.12-0.5-2.27-0.5-3.44c-1.53-24.57,28.05-28.18,40.67-29.74c1.64-0.2,3.3-0.3,4.95-0.3c11.32,0,19.3,5.17,21.85,14.13
					c1.16,5.19,1.24,10.57,0.22,15.8c-0.84,5.96-1.36,11.13,1.32,15.26c1.27,1.6,2.72,3.05,4.32,4.32
					c4.06,3.63,8.65,7.76,8.65,13.33c-0.17,3.45-1.89,6.63-4.69,8.65c-9.88,8.17-8.47,15.76-1.99,27.77
					c6.83,12.75,7.8,27.73,2.94,44.54c-6.14,21.07-18.28,39.64-33.41,50.92c-3.87,2.67-8.08,4.81-12.51,6.35
					c-4.8,1.92-9.34,3.74-12.6,7.13c-6.4,6.9-14.46,12.06-23.41,14.98c-4.01,1.16-8.1,2.01-12.23,2.53
					c-5.36,0.5-10.61,1.89-15.52,4.11c-3.48,1.92-5.66,5.04-8.21,8.64c-2.1,3.2-4.61,6.11-7.48,8.65
					c-8.08,6.68-18.15,9.73-25.03,11.43c-1.23,0.29-2.48,0.44-3.74,0.43c-3.38-0.15-6.73-0.76-9.94-1.82
					c-2.41-0.79-4.91-1.29-7.43-1.47c-1.43-0.13-2.82,0.49-3.67,1.64c-1.03,1.57-1.77,3.31-2.16,5.14c-0.59,2.52-1.56,4.94-2.9,7.15
					c-2.73,3.75-6.37,6.73-10.59,8.64c-1.75,0.93-3.61,1.75-5.38,2.53c-5.08,2.25-9.88,4.39-12.36,8.52
					c-0.69,0.98-1.14,2.1-1.34,3.29c0.41,0.3,2.05,1.1,8.02,1.1h5.12c4.65-0.24,9.29,0.61,13.55,2.49c3.43,1.52,5.97,4.55,6.87,8.19
					c1.79,7.76-4.06,16.27-9.7,23.64l-0.5,0.65c-1.3,1.71-1.58,2.83-1.58,3.26c0.24,1.3,4.19,3.63,5.68,4.52
					c5.86,3.48,7.54,8.23,9.03,12.56c0.52,1.38,1.04,2.85,1.71,4.32c1.95,4.43,5.5,7.97,9.94,9.9c3.2,1.5,6.67,2.32,10.2,2.4
					c3.47,0.21,6.83-1.19,9.12-3.8c0.77-1.09,1.4-2.26,1.88-3.5c1.51-3.39,3.59-8.02,9.34-9.16c1.14-0.21,2.3-0.31,3.46-0.3
					c1.71,0.03,3.42,0.16,5.12,0.37c1.47,0.15,2.87,0.3,4.08,0.3c2.44,0,3.29-0.24,3.78-3.22c0.11-1.14,0.11-2.29,0-3.44
					c-0.18-2.17-0.03-4.36,0.45-6.48c1.2-4.94,5.73-8.33,10.81-8.08h0.35c3.68,0.09,7.34,0.47,10.96,1.15
					c3.17,0.61,6.39,0.96,9.62,1.06h0.28c2.7,0.21,5.41-0.38,7.78-1.69c0.8-0.63,0.97-1.06,1.32-3.22c0.25-2.39,1.09-4.69,2.44-6.68
					c1.64-2.27,6.12-7.56,12.28-7.56c0.88,0.01,1.76,0.12,2.62,0.35c8.88,2.36,6.35,12.1,5.42,15.78l-0.22,0.8
					c-1.58,6.33-0.71,9.83,3.61,12.97c3.46,2.2,7.54,3.22,11.63,2.92c2.51,0,5.08-0.22,7.24-0.41l1.49-0.22
					c1.43-0.26,2.87-0.4,4.32-0.43c1.96-0.09,3.89,0.47,5.49,1.6c2.77,1.67,4.22,4.88,3.63,8.06c-1.47,6.94-12.17,9.73-12.28,9.73
					c-1.02,0.24-2.14,0.45-3.33,0.65c-3.37,0.61-7.18,1.19-8.13,3.35c-1.08,2.44-1.17,5.19-0.26,7.69c0.65,1.83,2,3.31,3.76,4.13
					c2.82,1.16,5.85,1.72,8.9,1.62c9.1-0.31,18-2.81,25.94-7.28c1.34-0.82,2.81-1.77,4.32-2.75c6.01-3.87,12.82-8.19,20.64-8.19
					c1.36-0.01,2.73,0.13,4.06,0.41c11.74,2.44,18.93,13.66,24.18,21.89l1.45,2.29c7.69,12.08,15.63,24.59,27.88,31.23
					c7.07,3.83,15.82,4.45,24.25,5.04l3.18,0.24c2.85,0.32,5.36,0.32,7.67,0.32c11.2,0,18.8-2.51,23.92-7.91
					c2-2.37,3.62-5.03,4.82-7.89c1.46-3.4,3.4-6.58,5.75-9.45c4.81-5.7,10.22-10.86,16.14-15.39c3.63-2.69,8.04-4.1,12.56-4.02
					c2.59,0.06,5.18,0.3,7.74,0.71l0.99,0.13c1.43,0.2,2.88,0.3,4.32,0.3c6.06-0.05,12-1.62,17.29-4.58
					c10.52-5.72,18.94-15.09,27.11-24.15c0.01-0.01,0.01-0.01,0.02-0.02c2.92-3.31,5.94-6.64,9.03-9.79
					c2.53-2.62,8.47-6.27,12.97-6.27c2.23-0.1,4.34,0.99,5.55,2.85c1.97,3.13,0.54,6.92-0.71,9.51c-1.97,3.69-4.41,7.11-7.26,10.18
					c-1.34,1.53-2.44,2.92-3.5,4.32l-2.16,3c-8.3,11.11-16.79,22.56-27.6,32.05c-2.18,1.92-4.21,3.74-6.38,5.51
					c-9.6,8.19-17.9,15.28-23.88,27.08c-5.35,10.51-9.94,21.4-13.72,32.57c-4.08,11.99-6.89,18.26-21.83,18.26h-2.79h-2.01
					c-6.04,0.14-12.07,0.73-18.02,1.75c-6.3,1.06-12.67,1.67-19.06,1.82c-4.04,0.02-8.08-0.38-12.04-1.19
					c-5.6-1.23-11.15-2.79-16.56-4.32c-11.13-3.16-21.68-6.12-32.74-6.12h-0.78c-13.16,0.17-26.58,1.66-39.55,3.11l-9.86,1.06
					c-24.01,2.53-35.83,9.86-37.2,23.08c-0.11,1.1-0.19,2.14-0.3,3.26c-0.8,9.03-1.79,20.29-9.6,27.38
					c-4.04,3.58-9.31,5.46-14.7,5.23c-3.35-0.08-6.69-0.42-9.98-1.02c-2.73-0.5-5.5-0.79-8.28-0.89c-2.8-0.24-5.57,0.74-7.59,2.7
					c-4.97,5.1-4.06,16.64-3.35,25.94c0.22,2.77,0.48,5.4,0.48,7.72c0.28,17.89-8.8,27.71-17.59,37.2
					c-3.76,4.24-7.2,8.75-10.31,13.49c-5.06,7.3-10.29,14.85-17.87,20.68c-3.8,2.92-7.85,5.6-11.74,8.17
					c-6.4,3.91-12.39,8.45-17.87,13.55c-6.03,6.01-9.08,13.75-12.3,21.94c-1.98,5.52-4.43,10.86-7.31,15.97
					c-7.2,12.06-19.6,24.23-36.89,36.18c-9.21,6.29-19.04,9.12-28.66,11.84c-7.18,1.76-14.16,4.27-20.81,7.5
					c-5.12,2.68-13.12,11.37-13.77,18.37c-0.32,2.24,0.63,4.47,2.46,5.79c1.06,0.93,2.41,1.48,3.83,1.53
					c3.74,0,8.34-4.28,12.38-8.06c2.54-2.62,5.4-4.92,8.52-6.83c3.39-1.89,7.19-2.92,11.07-2.98c14,0,23.6,14.16,31.29,25.52
					c2.11,3.28,4.4,6.43,6.87,9.44c1.74,2.27,4.4,3.64,7.26,3.74c5.99,0,13.01-6.25,15.89-14.39c1.23-3.57-0.02-7.05-1.45-11.09
					c-2.01-5.73-4.54-12.86,1.12-20.53c7.54-10.12,20.38-15.13,31.71-19.45c2.62-1.02,5.17-1.99,7.59-3
					c6.16-2.64,9.21-7.85,12.45-13.29c3.24-5.45,6.89-11.65,13.92-15.5c3.95-2.01,8.35-2.98,12.77-2.81
					c3.62,0.07,7.22,0.36,10.81,0.86c2.87,0.41,5.75,0.66,8.64,0.74h0.69c5.19-0.15,10.29-0.76,15.71-1.38
					c6.03-0.83,12.11-1.33,18.2-1.49h1.38c25.98,0,48.91,6.31,68.36,18.74c2.42,1.49,5.04,3.65,7.82,5.81
					c6.61,5.29,14.89,11.78,21.61,11.78c1.1,0.01,2.19-0.19,3.22-0.58c6.07-2.31,10.22-9.94,14.22-17.29
					c2.3-4.61,5.1-8.96,8.34-12.97c4.8-5.49,4.58-12.49,4.32-20.62c-0.37-5.12-0.1-10.27,0.8-15.32
					c4.08-18.89,11.5-31.58,22.63-38.9c4.94-3.04,10.14-5.63,15.54-7.74c3.48-1.47,6.79-2.88,9.83-4.47
					c11.17-5.8,23.24-9.67,35.7-11.46c3.91-0.54,7.74-1.36,11.43-2.16c6.37-1.62,12.89-2.54,19.45-2.77
					c6.99-0.04,13.87,1.71,19.99,5.08c4.89,2.78,9.61,5.83,14.16,9.14c8.3,5.84,16.14,11.35,25.59,13.36
					c1.69,0.34,3.42,0.5,5.14,0.5c4.76-0.2,9.49-0.86,14.11-1.99c3.41-0.71,6.96-1.45,10.44-1.82c1.44-0.16,2.88-0.24,4.32-0.24
					c7.46,0.33,14.83,1.7,21.92,4.06c2.68,0.73,5.47,1.51,8.17,2.16c7.29,1.88,14.78,2.94,22.3,3.18c6.89,0,9.27-1.49,9.99-2.16
					c1.45-1.34,1.27-2.75-2.57-8.9c-3.33-5.34-8.9-14.26,0.3-20.96c6.59-4.8,16.94-5.4,25.24-5.4c1.97,0,3.87,0,5.62,0h3.05
					c3.31,0.04,6.66,0.26,10.05,0.26c3.91,0.13,7.97,0.26,11.93,0.26c11.54,0,24.85-1.04,35.96-8.78c2.33-1.75,3.78-6.35,5.14-10.81
					c2.16-6.94,4.84-15.56,13.38-16.36c0.37-0.02,0.75-0.02,1.12,0c6.72,0,12.19,5.49,17.03,10.33c2.07,2.33,4.44,4.36,7.05,6.05
					c16.1,8.06,40.29,12.3,57.17,12.97c0.86,0,1.75,0,2.62,0c12,0,24.03-3.18,35.77-6.27c7.5-1.97,15.24-4.02,23-5.25
					c11.11-1.82,22.35-2.75,33.61-2.79c14.75-0.06,29.45,1.8,43.72,5.53c3.8,1.02,7.78,2.44,12.1,3.93
					c8.84,3.11,17.98,6.33,26.13,6.33c2.53,0.05,5.04-0.34,7.43-1.15c7.61-2.68,14-7.35,20.79-12.3l4.06-2.96
					c7.76-5.64,16.53-7.8,25.18-9.96c2.94-0.76,5.99-1.51,8.9-2.42c5.99-2.1,11.71-4.9,17.03-8.36c3.24-1.95,6.59-3.93,10.18-5.71
					c4.34-2.14,9.13-3.21,13.96-3.11c1.73,0,3.46-0.02,5.29,0.19c1.84,0.22,3.72,0.22,5.71,0.22h0.73c5.58-0.06,6.22-1.69,6.96-9.94
					c0.11-4.49,1.16-8.91,3.09-12.97c4.8-8.71,13.68-12.99,21.76-16.04c3.91-1.47,8.08-2.62,12.56-3.83
					c12.02-3.26,24.44-6.64,29.61-16.53c4.28-8.21,2.85-17.33,1.34-27.02c-0.79-4.52-1.31-9.08-1.56-13.66
					c-0.15-4.43,1.92-8.8,3.76-12.67c0.99-2.03,2.68-5.55,2.44-6.48c0-0.02-0.76-0.84-4.58-1.23c-0.73,0-1.45,0-2.16,0
					c-5.79,0.39-11.47,1.74-16.81,3.98c-4.39,1.68-8.92,2.97-13.53,3.87l-1.51,0.26c-5.9,1.08-24.18,4.32-36.74,4.32
					c-7.63,0-12.06-1.19-14.37-3.85c-1.29-1.48-1.86-3.45-1.56-5.38c0.65-4.54,4.45-6.85,6.98-8.39h0.22
					c-0.41-0.32-0.93-0.67-1.58-1.1c-4.12-2.88-8.96-4.55-13.98-4.8c-2.95-0.02-5.83,0.83-8.3,2.44c-1.91,1.37-3.71,2.88-5.4,4.52
					c-5.12,4.65-10.94,9.94-17.7,9.94c-4.13-0.09-8-2.05-10.53-5.32c-1.9-2.01-2.8-4.76-2.46-7.5c0.78-5.23,6.03-8.6,11.13-11.84
					c2.16-1.45,5.99-3.85,6.48-5.06c0.33-0.68,0.54-1.41,0.63-2.16c-1.12-0.32-2.28-0.45-3.44-0.39c-0.04,0-0.08,0-0.12,0
					c-1.87,0.01-3.86,0.2-5.97,0.39c-1.8,0.21-3.61,0.34-5.42,0.37c-7.74-0.06-11.74-2.36-12.06-6.68
					c-0.48-6.59,10.33-11.63,16.51-12.6c2.25-0.33,4.53-0.49,6.81-0.48c3.85,0.06,7.7,0.32,11.52,0.78
					c3.7,0.37,7.18,0.73,10.59,0.73c5.09,0.27,10.13-1.07,14.42-3.83c5.1-3.54,9.42-10.29,12.97-16.51
					c5.04-8.88,9.06-20.32,6.63-25.94c-0.92-2.04-2.82-3.46-5.04-3.76c-0.28-0.03-0.56-0.03-0.84,0c-2.46,0-5.86,2.12-9.14,4.17
					c-4.24,2.66-8.6,5.38-13.36,5.38c-3.27,0.01-6.4-1.33-8.65-3.72c-1.02-1.05-1.47-2.54-1.19-3.98
					c0.56-2.74,0.63-3.03,21.74-11.54l4.15-1.71l1.82-0.82c8.39-3.89,17.05-7.91,27.36-7.91h1.49h1.88
					c17.72,0,21.89-9.68,28.23-24.34c1.88-4.28,3.76-8.69,6.2-13.23c6.68-12.43,17.25-23.82,33.28-35.88
					c13.05-9.81,16.64-21.94,21.2-37.26c0.65-2.16,1.3-4.32,1.99-6.48c2.29-7.43,5.17-14.93,7.95-22.17
					c4.28-10.47,7.83-21.22,10.61-32.18C1576.21,2006.74,1575.72,1988.24,1571.5,1969.37z M1277.15,1710.9
					c3.45,2.02,7.09,3.76,10.85,5.19c5.82,2.38,10.03,4.1,13.55,9.02c4.39,6.57,8.18,13.59,11.29,20.89c0.69,1.54,1.39,3.02,2.1,4.5
					l0.89,1.88c1.75,3.6,3.72,6.98,5.62,10.25l0.93,1.61l0.06,0.09c3.91,6,7,12.5,9.17,19.23c2.48,9.07,7.16,31.83-2.94,47.13
					c-3.59,5.45-9.09,9.46-14.85,13.66c-5.7,3.82-10.92,8.34-15.5,13.44c-4.54,5.01-6.78,11.74-6.15,18.51
					c0.93,8.32,6.48,16.47,16.5,24.23c3.7,2.86,7.59,5.4,11.35,7.86l1.08,0.71c-0.96,8.19-3.07,13.57-6.44,16.42
					c-2.28,1.93-6.74,2.8-11.06,3.63c-7.02,1.36-14.28,2.76-15.98,8.85c-0.48,1.71-0.34,3.42-0.21,5.07
					c0.14,1.72,0.27,3.34-0.37,4.61c-0.99,1.96-3.59,2.65-5.05,2.88c-8.7,1.42-17.26,2.01-25.44,1.75
					c-10.22-0.32-20.42-0.02-30.32,0.89c-0.7,0.06-1.98,0.1-3.59,0.14c-10.22,0.28-21.44,0.92-25.29,5.06
					c-0.97,1.04-1.45,2.3-1.38,3.63c0.41,8.61,1.94,14.35,8.86,20.05c0.85,0.7,1.75,1.42,2.69,2.17
					c6.83,5.45,15.32,12.22,15.32,20.78c0,6.28-4.26,12.16-8.86,17.58c-0.59,0.7-1.17,1.39-1.75,2.08
					c-2.39,2.87-4.65,5.59-7.39,7.99c-3.01,2.65-4.7,5.75-5.01,9.22c-0.1,1.11,0.26,2.3,0.6,3.45c0.34,1.14,0.77,2.57,0.38,3.11
					c-0.45,0.62-1.54,0.69-3.15,0.73c-1.15,0.02-2.33,0.05-3.36,0.48c-2.76,1.17-4.84,3.5-6.18,6.92c-0.55,1.41-0.85,3.08-1.16,4.84
					c-0.46,2.62-0.95,5.32-2.24,6.74c-0.39,0.42-0.73,0.64-1.02,0.64c-0.82,0-2.11-1.4-3.14-2.53c-1.62-1.77-3.28-3.57-5.51-3.48
					c-1.29,0.07-2.32,0.62-3,1.61c-1.74,2.54-0.66,7.31,0.78,11.93c-2.32,2.16-4.82,4.3-7.45,6.54c-6.81,5.8-13.85,11.81-18.3,18.86
					c-1.7,2.7-2.87,5.65-4,8.51c-0.54,1.37-1.1,2.79-1.71,4.15c-2.25,5.02-4.95,8.4-7.21,9.03c-0.91,0.26-1.76,0.08-2.68-0.55
					c-0.87-0.6-1.72-1.28-2.54-1.94c-3.02-2.43-6.13-4.93-11.02-4.11c-2.66,0.46-5.33,1.68-8.13,3.75
					c-5.1,3.75-8.82,8.81-12.42,13.71l-0.61,0.83c-0.59,0.81-1.19,1.69-1.82,2.63c-2.43,3.61-5.17,7.7-8.75,8.57
					c-6.99,1.72-11.32-7.69-13.98-14.92c-3.08-8.39-4.89-16.9,2.71-22.26c2.09-1.48,2.5-3.14,2.48-4.28c-0.07-4-5.48-7.53-8.39-9.42
					l-0.46-0.3c-2.88-1.9-4.43-3.7-4.61-5.34c-0.25-2.3,2.17-4.77,4.72-7.38c2.56-2.62,5.22-5.33,6.16-8.69
					c1.01-3.6,3.05-6.77,5.22-10.13c0.49-0.77,0.99-1.53,1.47-2.3c4.07-6.53,6.02-15.06,5.64-24.67
					c-0.35-8.75-2.61-17.49-6.36-24.63c-5.82-11.06-16.74-19.13-26.37-26.25c-1.35-1-2.68-1.98-3.99-2.96
					c-7.41-5.57-15.17-10.38-23.82-15.64c-0.83-0.51-1.65-0.92-2.37-1.29c-2.7-1.38-3.47-1.87-3.11-3.88
					c0.27-1.49,1.34-2.97,2.48-4.53c1.25-1.72,2.54-3.5,3.04-5.64c1.51-6.39-0.42-12.59-5.17-16.59c-2.97-2.5-6.65-3.06-10.2-3.6
					c-3.04-0.46-5.9-0.89-8.15-2.49c-2.3-1.64-2.18-2.88-2.14-3.28c0.23-2.33,4.07-5.5,9.78-8.08l0.8-0.36
					c4.61-2.05,13.19-5.85,14.56-11.43c0.51-2.06-0.02-4.09-1.55-6.03c-1.63-2.05-4.06-3.11-5.99-3.8
					c-4.54-1.62-9.34-2.34-13.98-3.04c-1.29-0.19-2.57-0.39-3.85-0.6c-5.3-0.88-11.36-2.24-16.15-5.66
					c-4.28-3.07-6.99-9.16-7.06-15.9c-0.07-6.83,2.48-12.85,6.82-16.09c1.31-0.97,2.94-1.63,4.67-2.33
					c2.49-1.01,5.07-2.05,6.96-4.18c4.52-5.1,1.58-8.78-1-12.03c-0.54-0.67-1.09-1.37-1.59-2.08c-3.39-4.81-5.37-10.22-7.46-15.96
					c-2.01-5.49-4.08-11.17-7.5-16.19c-3.47-5.1-8.08-7.99-12.54-10.78c-3.43-2.15-6.67-4.18-9.46-7.15
					c-10.96-11.7-9.19-38.49-8.61-47.29c0.9-13.58,5.31-26.14,11.8-33.61c2.92-3.36,7.79-6.34,11.54-4.95
					c2.12,0.78,3.76,3.08,5.36,5.31c0.84,1.17,1.7,2.38,2.64,3.36c1.05,1.1,2.12,2.19,3.18,3.27c2.68,2.73,5.45,5.55,7.87,8.54
					c4.42,5.45,7.97,11.81,11.41,17.96c0.69,1.23,1.37,2.46,2.06,3.68c0.71,1.25,1.45,2.66,2.24,4.16
					c4.59,8.76,10.78,20.57,21.54,20.57c0.63,0,1.28-0.04,1.95-0.13c3.93-0.5,7.43-2.37,10.81-4.17c3.74-1.99,7.26-3.87,11.19-3.94
					c7.59-0.12,15.4,2.83,22.94,5.69c1.23,0.47,2.46,0.93,3.66,1.37c6.13,2.25,16.29-1.77,19.7-10.15c1.25-3.06,1.26-6.25,1.27-9.35
					c0.01-3.29,0.03-6.4,1.49-9.16c0.36-0.67,1.37-1.08,2.32-1.4c1.94-0.65,4.84-0.82,7.65-0.99c4.22-0.25,8.58-0.51,11.3-2.33
					c3.5-2.34,2.8-5.6,2.24-8.22c-0.25-1.17-0.49-2.28-0.42-3.32c0.3-4.61,2.8-11.31,7.43-11.8c1.48-0.14,2.25,1.47,3.3,3.96
					c0.17,0.41,0.35,0.82,0.52,1.19c0.43,0.94,0.82,1.95,1.19,2.93c1.42,3.72,3.03,7.94,7.84,9.7c8.65,3.19,12.22-3.88,15.09-9.56
					c1.77-3.5,3.6-7.12,6.32-8.48c3.23-1.61,8.18-1.58,10.87,1.26c0.99,1.04,1.53,2.46,2.1,3.96c0.69,1.8,1.47,3.84,3.17,5.42
					c2.68,2.49,6.09,4.29,8.47,5.37c3.83,1.72,7.83,1.89,11,0.45c7.67-3.47,6.14-10.42,4.91-16c-0.92-4.16-1.79-8.1,0.51-10.59
					c2.24-2.44,6.04-3.54,9.72-4.62c1.49-0.43,2.9-0.84,4.23-1.34l1.1-0.42c4.09-1.57,8.73-3.36,11.97-0.75
					c3.51,2.82,5.62,7.56,7.65,12.14c0.72,1.63,1.41,3.18,2.16,4.64c0.34,0.67,0.69,1.39,1.06,2.13c3.46,7.07,8.7,17.75,18.97,12.74
					c4.63-2.26,10.07-7.23,10.9-12.66C1275.06,1709.51,1276.1,1710.21,1277.15,1710.9z M962.66,2121.15
					c0.04,0.33,0.09,0.68,0.14,1.06c0.46,3.34,1.23,8.94-1.88,10.09c-4.61,1.71-9.51-1.44-14.26-4.48
					c-2.98-1.91-5.79-3.71-8.68-4.52c-7.39-2.08-23.16-0.91-29.8,4.96c-1.28,1.13-2.23,2.49-3.15,3.79
					c-0.62,0.88-1.21,1.71-1.87,2.46c-4.07,4.55-8.94,1.87-16.47-3.08c-4.63-3.04-9.42-6.17-13.86-5.67
					c-4.85,0.56-12.01,4.13-14.52,8.35c-1.58,2.65-1.87,6.08-0.89,10.49c0.23,1.03,0.5,2.06,0.79,3.15
					c1.14,4.29,2.32,8.72,0.51,12.34c-5.98,11.97-23.16,21.75-37.12,21.33c-0.9-1.59-1.92-3.95-3.08-6.62
					c-2.87-6.64-6.45-14.91-11.62-19.65c-2.4-2.2-4.39-3.14-6.24-2.95c-2.4,0.24-3.83,2.22-5.34,4.32c-1,1.39-2.04,2.82-3.41,3.87
					c-1.23,0.94-2.3,1.75-2.73,1.8c-0.01,0-0.02,0-0.03,0c-0.32,0-1.09-0.49-2.06-1.1c-1.35-0.86-3.4-2.15-6.67-3.74
					c-7.89-3.84-13.1-10.69-14.77-13.12c-0.8-3.84-1.45-8-1.95-12.63c-0.38-3.55-0.95-7.22-1.5-10.77
					c-1.57-10.08-3.19-20.5-0.93-30.26c0.26-1.15,0.51-2.4,0.77-3.71c1.12-5.7,2.51-12.79,6.75-16.05c1.18-0.91,2.82-1.51,4.56-2.15
					c2.64-0.97,5.37-1.97,7.08-4.25c2.76-3.67,1.03-6.51-0.36-8.79c-0.99-1.63-1.85-3.03-1.5-4.8c0.17-0.86,1.12-1.58,2.13-2.34
					c1.6-1.21,3.79-2.86,3.08-5.9c-0.91-3.85-5.92-5.23-9.96-6.34c-1.19-0.33-2.32-0.64-3.05-0.95c-5.68-2.38-11.05-6.01-15.1-8.89
					c-1.25-0.89-2.68-1.73-4.21-2.63c-4.04-2.38-8.62-5.07-9.81-8.96c-1.93-6.37,7.28-9.99,14.38-12.15
					c3.36-1.03,6.92-1.77,10.36-2.5c1.8-0.38,3.67-0.77,5.49-1.2c0.4-0.09,0.77-0.18,1.11-0.25c4.02-0.9,5.06-1.66,5.28-6.58
					l0.06-1.3c0.26-5.01,0.55-10.68-2.74-12.6c-1.89-1.1-4.38-0.63-7.62,1.43c-0.4,0.25-1,0.71-1.75,1.29
					c-1.19,0.9-4.29,3.27-5.3,3.48c-1.55-0.4-2-1.12-2.2-1.62c-1.37-3.49,3.99-11.58,6.57-15.47c1.02-1.54,1.76-2.66,2.12-3.41
					c3.07-6.42,4.84-12.94,6.31-19.02c0.27-1.13,2.36-3.4,4.04-5.23c2.79-3.04,5.19-5.66,5-7.92c-0.07-0.8-0.45-1.49-1.1-2
					c-3.14-2.43-6.87-4.4-10.47-6.3c-8.25-4.37-16.04-8.49-17.88-17.75c-0.5-2.52,0.43-4.56,1.51-6.91c0.81-1.78,1.65-3.62,1.92-5.8
					c0.08-0.69,0.07-1.36-0.03-2c1.85-6.17,7.69-9.56,11.09-11.04c2.03-0.89,3.77-2.1,5.45-3.28c3.12-2.18,5.8-4.06,9.65-3.52
					c1.76,0.25,3.46,0.79,5.25,1.36c1.63,0.52,3.32,1.06,5.08,1.37c1.17,0.21,2.36,0.28,3.51,0.35c2.21,0.14,4.29,0.27,6.02,1.31
					c1.56,0.94,2.36,2.91,3.2,5c1.1,2.71,2.34,5.77,5.66,6.64c2.62,0.68,6.17-1.81,9.33-4.37c3.78-3.05,8.51-8.17,9.62-15.17
					c0.05-0.3,0.09-0.63,0.1-0.95c5.39,1.49,10.96,2.64,16.35,2.64c8.24,0,16.03-2.67,21.97-10.81c2.11-2.89,3.26-6.13,4.37-9.25
					c1.05-2.96,2.04-5.76,3.78-8.19c6.37-8.88,14.5-15.62,23.51-19.48c12.07-5.17,20.45-10.4,27.19-16.95
					c2.4-2.33,4.64-4.66,6.8-6.92c5.51-5.74,10.75-11.16,17.74-15.69c0.58,0.78,1.19,1.52,1.85,2.22c3.06,3.26,6.61,5.49,10.05,7.64
					c4.39,2.75,8.54,5.35,11.66,9.93c3.21,4.71,5.22,10.21,7.16,15.53c2.07,5.67,4.21,11.52,7.83,16.66
					c0.56,0.79,1.14,1.52,1.7,2.22c2.74,3.44,3.83,5.09,1.1,8.17c-1.42,1.6-3.56,2.47-5.84,3.39c-1.83,0.74-3.72,1.5-5.33,2.71
					c-5.1,3.81-8.1,10.73-8.02,18.52c0.08,7.8,3.19,14.64,8.31,18.31c5.29,3.79,11.77,5.25,17.41,6.18c1.29,0.21,2.59,0.41,3.9,0.61
					c4.5,0.67,9.14,1.37,13.41,2.9c1.56,0.56,3.5,1.38,4.65,2.84c0.94,1.19,1.27,2.32,0.99,3.45c-1.02,4.15-9.32,7.83-12.86,9.4
					l-0.82,0.36c-4.1,1.85-11.06,5.67-11.54,10.51c-0.22,2.23,0.92,4.26,3.38,6.02c2.83,2.02,6.19,2.53,9.44,3.02
					c3.29,0.5,6.39,0.97,8.72,2.93c3.86,3.25,5.42,8.34,4.18,13.6c-0.37,1.56-1.43,3.02-2.55,4.57c-1.28,1.77-2.61,3.6-3,5.77
					c-0.77,4.28,2.13,5.77,4.7,7.08c0.71,0.36,1.44,0.74,2.18,1.18c8.58,5.22,16.28,9.98,23.58,15.47c1.31,0.99,2.65,1.98,4.01,2.98
					c9.83,7.27,20,14.78,25.5,25.23c6.59,12.54,9.12,33.03,0.83,46.32c-0.47,0.76-0.96,1.51-1.45,2.26
					c-2.19,3.4-4.46,6.91-5.59,10.94c-0.74,2.62-3.11,5.05-5.42,7.41c-1.88,1.92-3.8,3.89-4.83,6.03l-13.78,3.33
					c-10.05,2.42-12.45,6.63-14.77,10.7c-1.03,1.8-2.09,3.67-3.87,5.47c-3.73,3.77-4,6.54-4.31,9.74c-0.24,2.43-0.5,5.19-2.4,9.33
					c-3.66,8-13.54,15.05-19.62,18.36c0.04-2.49-0.13-5.05-0.58-7.71c-1.7-10.1-7.5-24.38-14.87-30.96
					c-3.52-3.14-9.51-2.85-13.93-1.53c-4.39,1.31-9.52,4.3-10.73,8.78C961.98,2114.92,962.27,2118,962.66,2121.15z M1138.13,1415.35
					c10.54,4.53,22.18,9.7,30.82,16.95c4.8,4.38,9.15,9.29,12.93,14.58c4.48,6.23,9.67,11.96,15.48,17.06
					c2.31,1.89,4.69,3.77,7,5.59c8.75,6.88,17.01,13.38,22.75,22.31c9.45,14.83,14.4,32.91,15.6,56.9
					c0.25,5.33,0.87,11.16,1.46,16.79l0.13,1.21c0.53,4.89,1.07,9.94,1.35,14.44c1.18,18.49,7.44,27.78,17.6,40.88
					c7.21,9.33,13.11,17.55,19.76,27.06c1.36,1.86,3.44,4.52,6.54,8.37l0.55,0.68c2.34,2.9,4.54,5.64,5.88,7.59
					c0.88,1.2,1.86,2.33,2.89,3.36c1.86,1.98,5.33,5.68,5.24,7.82c-0.06,0.76-0.4,1.46-0.94,1.98c-0.3,0.05-0.62,0.07-0.98,0.05
					c-6.21,0-18.84-4.31-21.78-5.78c-6.43-3.39-12.55-7.44-18.21-12.03c-5.73-4.56-11.86-8.7-18.18-12.27
					c-6.17-3.71-13.22-5.79-20.47-6.04c-2-0.01-4.05,0.19-6.03,0.6l0.01,0.04c-2.31,0.5-14.01,3.45-16.61,10.92
					c-1,2.75-0.63,5.85,1.01,8.31c3.46,5,10.73,5.29,17.15,5.55l0.1,0c1.19,0.01,2.38,0.09,3.56,0.23
					c12.37,3.54,22.06,13.26,31.51,22.72l3.24,3.24c4.17,4.42,8.72,8.48,13.61,12.16c0.64,5.02-5.5,10.26-9.28,12.1
					c-7.02,3.42-10.78-2.83-14.96-11.36c-0.38-0.76-0.73-1.5-1.08-2.18c-0.71-1.39-1.38-2.89-2.09-4.49
					c-2.08-4.68-4.44-9.99-8.52-13.27c-4.59-3.68-10.56-1.39-14.92,0.29l-1.08,0.41c-1.22,0.46-2.58,0.85-4.01,1.27
					c-3.88,1.13-8.28,2.41-11.09,5.46c-3.35,3.64-2.27,8.53-1.23,13.26c1.39,6.29,1.98,10.27-3.22,12.62
					c-2.38,1.08-5.49,0.91-8.53-0.46c-3.08-1.39-5.8-3.1-7.66-4.83c-1.19-1.11-1.79-2.66-2.41-4.3c-0.65-1.71-1.33-3.47-2.73-4.95
					c-3.79-4-10.24-3.95-14.39-1.88c-3.61,1.81-5.67,5.88-7.66,9.81c-3.25,6.42-5.54,10.25-11.38,8.1c-3.52-1.3-4.7-4.38-6.07-7.96
					c-0.39-1.02-0.8-2.08-1.27-3.11c-0.16-0.35-0.32-0.72-0.48-1.11c-1.04-2.46-2.6-6.17-6.38-5.77
					c-6.77,0.71-9.76,9.12-10.11,14.59c-0.09,1.46,0.21,2.88,0.48,4.15c0.56,2.63,0.75,3.94-0.97,5.1c-2.04,1.37-5.99,1.6-9.81,1.83
					c-3.14,0.19-6.1,0.36-8.42,1.14c-1.51,0.5-3.17,1.24-4.02,2.84c-1.8,3.41-1.82,7.04-1.84,10.55c-0.01,2.94-0.03,5.71-1.05,8.23
					c-2.23,5.49-10.16,10.57-15.89,8.47c-1.19-0.44-2.4-0.9-3.63-1.36c-7.44-2.82-15.83-6.02-24.05-5.89
					c-4.65,0.07-8.67,2.22-12.55,4.29c-3.29,1.75-6.39,3.41-9.78,3.84c-9.9,1.25-15.76-9.89-20.45-18.86
					c-0.8-1.52-1.55-2.96-2.28-4.25c-0.69-1.21-1.37-2.43-2.05-3.66c-3.5-6.26-7.12-12.74-11.69-18.39
					c-2.52-3.11-5.34-5.98-8.06-8.76c-1.06-1.08-2.11-2.15-3.15-3.24c-0.79-0.83-1.56-1.9-2.37-3.04c-1.77-2.47-3.77-5.28-6.76-6.38
					c-1.93-0.71-3.98-0.67-5.99-0.11c-1.88-1-3.76-1.95-5.59-2.86c-5.88-2.93-11.44-5.7-16.37-10.17
					c-3.15-2.86-5.23-6.44-7.43-10.23c-1.77-3.06-3.61-6.22-6.07-9c-2.78-3.14-7.24-4.61-11.56-6.04c-7.54-2.49-12.11-4.36-10.99-11
					c0.46-2.72,2.09-5.24,3.53-7.47c8.14-12.59,18.27-28.25,6.42-44.96c-7.85-11.07-19.56-18.61-30.88-25.89l-1.06-0.68
					c-3.08-1.98-5.02-4.51-7.08-7.19c-1.19-1.55-2.42-3.15-3.92-4.72c-2.6-2.72-5.9-4.03-8.82-3.53c-1.37,0.24-2.53,0.83-3.66,1.4
					c-0.87,0.44-1.7,0.86-2.53,1.07c-2.57,0.63-4.96-0.72-7.76-4.36c-4.62-6.02-8.51-12.78-5.69-21.45
					c1.88-5.79,4.94-12.84,11.92-16.07c1.88-0.87,3.87-1.55,5.98-2.27c5.29-1.81,10.75-3.67,14.33-8.66
					c2.96-4.13,4.48-10.19,4.18-16.62c-0.08-1.75-0.66-3.59-1.23-5.36c-0.75-2.37-1.53-4.81-0.93-6.65c0.77-2.4,2.27-3.72,3.85-5.11
					c2.32-2.05,4.96-4.37,4.67-10.01c-0.06-1.24-0.25-2.51-0.42-3.74c-0.58-4.06-1.08-7.57,2.21-9.94
					c3.88-2.8,10.03-3.49,14.34-2.79c0.52,0.55,1.11,0.97,1.78,1.25c0.69,0.29,1.41,0.42,2.15,0.42c2.39,0,5.01-1.33,7.75-2.72
					c3.08-1.57,6.28-3.19,8.74-2.62c1.61,0.37,3.16,0.96,4.8,1.58c0.98,0.38,2,0.76,3.04,1.1c5.66,1.84,11.56,2.08,16.2,0.67
					c3.05-0.93,5.61-2.45,7.6-4.53c0.72-0.75,1.22-1.83,1.69-2.87c0.49-1.07,1.1-2.39,1.71-2.62c1.16-0.44,1.89,0.22,3.4,1.94
					c1.14,1.31,2.44,2.79,4.36,2.79c0.02,0,0.04,0,0.07,0c1.47-0.03,2.87-0.91,4.39-2.79c1.32-1.63,1.97-3.81,2.59-5.91
					c0.8-2.71,1.56-5.26,3.57-6.25c2.98-1.46,7.13-0.8,10.78-0.22c1,0.16,1.96,0.31,2.87,0.42c4.22,0.51,6.3,3.31,8.71,6.55
					c1.08,1.45,2.19,2.94,3.55,4.32c10.19,10.25,23.1,8.33,35.58,6.47c1.79-0.27,3.57-0.53,5.35-0.76
					c3.44-0.45,7.34-0.95,10.68-2.92c1.38-0.81,2.56-1.7,3.71-2.55c2.26-1.69,4.21-3.14,7.07-3.65c3.18-0.56,6.44-0.27,9.9,0.05
					c2.03,0.18,4.12,0.37,6.2,0.37c5.96,0,12.08,0.31,17.97,0.63c6.31,0.34,12.84,0.69,19.28,0.63
					C1132.96,1429.79,1135.48,1424.12,1138.13,1415.35z M949.25,1053.92l0.01,0.32c1.05,9.82,3.11,21.12,10.44,30.39
					c5.45,6.97,12.65,11,19.63,14.91c7.11,3.94,14.46,8.01,17.96,15.52c3.81,8.19,6.18,16.9,7.04,25.8c0.27,5.15,0.27,10.38,0,15.52
					c-0.35,6.55-0.23,13.19,0.35,19.74l0.02,0.14c1.1,6.87,3.08,13.58,5.89,19.93c1.98,4.59,3.55,9.38,4.67,14.19
					c0.77,3.81,1.17,7.68,1.6,11.77l0.05,0.47c0.45,5.5,1.3,11.03,2.51,16.42l0.03,0.11c2.44,8.59,5.64,17.03,9.49,25
					c1.22,2.79,2.63,6.04,3.95,9.28c4.87,12.13,8.32,24.83,10.25,37.77c1.81,11.84,4.65,25.44,12,36.56
					c2.36,3.59,4.99,6.98,7.49,10.15c1.9,2.41,4.7,6.03,7.07,9.6c4.21,6.44,5.34,12.8,6.76,20.91l0.03,0.54
					c1.84,10.48,10.12,11.49,15.02,12.09l1.69,0.24c12.57,1.62,24.8,5.09,36.32,10.31c1.61,0.76,3.38,1.51,5.08,2.23l0.74,0.32
					c-2.59,8.67-4.37,12.65-14.27,12.74c-6.37,0.06-12.83-0.29-19.09-0.63c-5.83-0.31-11.85-0.64-17.8-0.64c-0.13,0-0.27,0-0.4,0
					c-1.91,0-3.83-0.17-5.86-0.36c-3.5-0.32-7.12-0.64-10.69-0.01c-3.56,0.63-5.99,2.44-8.34,4.2c-1.08,0.81-2.2,1.64-3.43,2.37
					c-2.81,1.66-6.23,2.1-9.54,2.53c-1.79,0.23-3.6,0.5-5.41,0.77c-12.28,1.83-23.88,3.56-33.01-5.61
					c-1.21-1.22-2.26-2.63-3.27-3.99c-2.59-3.48-5.26-7.08-10.76-7.74c-0.88-0.1-1.8-0.25-2.76-0.41
					c-4.09-0.65-8.74-1.39-12.57,0.49c-3.2,1.56-4.22,5.03-5.13,8.09c-0.56,1.91-1.1,3.71-2.04,4.87c-1.22,1.5-1.89,1.68-2.12,1.68
					c0,0-0.01,0-0.01,0c-0.56,0-1.38-0.94-2.1-1.77c-1.39-1.59-3.48-4-6.72-2.77c-1.76,0.67-2.62,2.53-3.37,4.18
					c-0.37,0.81-0.76,1.65-1.13,2.04c-1.63,1.7-3.75,2.95-6.31,3.73c-4.06,1.24-9.31,1-14.4-0.65c-0.96-0.31-1.94-0.69-2.89-1.05
					c-1.66-0.63-3.38-1.29-5.2-1.7c-3.53-0.8-7.2,1.07-10.76,2.88c-2.81,1.43-5.72,2.91-7.38,2.21c-0.81-0.34-1.48-1.3-1.97-2.85
					c-0.56-1.77-0.66-3.93-0.76-6.22c-0.14-3.1-0.28-6.31-1.66-8.89c-2.43-4.56-6.1-8.26-9.64-11.83l-1.2-1.21
					c-3.48-3.52-7.08-7.17-9.58-11.35c-1.45-2.43-0.57-4.51,0.45-6.93c0.94-2.23,2-4.75,0.73-7.41c-1.28-2.68-4.66-3.38-7.65-4
					c-1.22-0.25-2.37-0.49-3.07-0.81c-0.75-0.35-1.2-0.74-1.32-1.17c-0.33-1.09,0.93-3.16,2.03-4.98c1.43-2.36,2.9-4.79,2.85-7.31
					c-0.05-2.51,1.47-5.36,3.09-8.39c1.5-2.81,3.05-5.71,3.75-8.93c1.31-6.01-5.37-9.62-8.57-11.36c-0.78-0.42-1.69-1.05-2.64-1.71
					c-3.6-2.49-8.53-5.89-12.83-2.09c-1.13,1-1.92,2.19-2.69,3.35c-0.29,0.43-0.57,0.86-0.87,1.27c-1.55,2.13-4.53,3.78-7.41,4.1
					c-3.52,0.39-8.1-0.59-10.38-4.5c-3.87-6.62-0.77-18.52,1.28-26.4c0.28-1.09,0.55-2.11,0.78-3.05c0.84-3.41-0.46-6.09-1.61-8.45
					c-0.54-1.12-1.06-2.17-1.39-3.31c-0.9-3.09-0.58-6.94,0.83-10.05c1.81-3.99,5.63-6.87,9.74-7.32c0.49-0.05,0.99-0.08,1.5-0.12
					c2.18-0.13,4.65-0.28,6.36-2.17c1.46-1.62,1.81-4.2,0.88-6.57c-1.39-3.56-5.41-7.89-8.97-9.64c-1.56-0.77-3.12-1.11-4.63-1.45
					c-2.05-0.46-3.83-0.85-5.42-2.21c-5.45-4.64-10.79-11.98-15.02-20.67c-2.22-4.55-1.03-7.07,2.21-11.75
					c2.38-3.43,4.42-6.93,6.4-10.31c5.37-9.19,10.44-17.87,21.11-23.14c5.79-2.86,10.87-4.59,16.88-3.76
					c0.63,0.09,1.21,0.22,1.77,0.34c2.29,0.51,4.9,1.09,6.89-3.23c0.74-1.61,1.26-3.24,1.77-4.82c1.23-3.85,2.29-7.17,6.16-9.17
					c2.13-1.1,4.36-1.92,6.52-2.72c5.29-1.95,10.77-3.96,14.31-10.15c1.27-2.23,1.95-4.41,2.02-6.49c0.2-6.04-4.04-11.12-7.77-15.6
					c-1.4-1.68-2.73-3.27-3.76-4.82c-1.3-1.95-2.7-3.86-4.05-5.71c-3.28-4.47-6.37-8.69-8.34-13.69c-1.64-4.15,0.46-5.34,4.98-7.42
					c1.78-0.82,3.63-1.68,5.2-2.9c4.23-3.32,7.51-7.7,10.68-11.94c1.01-1.35,1.97-2.63,2.95-3.86c1.9-2.39,4.42-4.13,7.09-5.97
					c3.21-2.21,6.53-4.5,8.77-8.06c1.49-2.36,2.2-5.04,2.89-7.64c0.87-3.26,1.68-6.34,3.92-8.52c2.9-2.82,7.07-4.24,11.1-5.6
					c1.69-0.57,3.42-1.17,5.07-1.86C948.69,1048.74,948.97,1051.34,949.25,1053.92z M719.26,1282.29c-4.33,0-10.51,0.68-13.72,2.61
					c-2.09,1.29-4.01,2.86-5.7,4.65l-0.07,0.08c-1.15,1.35-2.63,2.38-4.29,2.99c-3.03,0.76-6.15,1.17-9.23,1.23
					c-1.07,0-2.11,0.1-3.12,0.2l-0.74,0.07c-5.83,0.48-13.4,3.7-15.54,11.17l-0.03,0.14c-0.55,2.61-0.51,5.33,0.13,7.91
					c-0.7-0.25-1.37-0.58-2.02-0.98c-2.43-1.44-5.08-2.38-7.87-2.81l-0.15-0.02l-1.56,0c-2.89,0.13-5.68,0.85-8.28,2.12l-0.62,0.26
					c-0.48,0.2-0.95,0.4-1.38,0.56l-2.74,0.89c-7.24,2.34-16.25,5.25-18.44,16.99c-0.89,4.79-8.54,9.05-15.16,10.04
					c-0.58,0.1-1.16,0.14-1.75,0.15c-2.34-0.17-4.57-0.82-6.63-1.93l-0.16-0.08c-1.68-0.71-3.46-1.08-5.38-1.09
					c-2.79,0.11-5.52,0.97-7.91,2.5c-0.74,0.5-1.58,0.82-2.46,0.93c-2.92-0.1-3.8-0.9-6.1-8.13l-0.71-2.14
					c-1.07-3.4-2.87-9.08-7.59-11.95c-2.82-1.58-6.04-2.36-9.19-2.24c-1.76,0-3.42,0.14-4.52,0.25c-1.5,0.15-2.52,0.21-3.45,0.21
					c-0.58,0.01-1.16-0.04-1.72-0.13c-2.82-0.67-5.55-1.7-8.15-3.07l-1.41-0.71c-3.08-1.92-6.61-3.14-10.22-3.53l-0.16-0.01
					c-2.69-0.05-5.22,1.08-6.97,3.17c-2.03,2.54-2.58,6.1-1.63,10.58c1.43,6.86,6.88,9.88,10.87,12.08l0.2,0.11
					c1.26,0.63,2.46,1.39,3.58,2.24c0.64,0.59,0.6,1.98-1.35,7.36c-0.87,2.2-1.56,4.48-2.06,6.79l-0.03,0.2
					c-0.35,3.04-0.29,6.11,0.17,9.13c0.38,3.68,0.41,5.54,0.34,6.48c-5.75-0.54-12.05-6.61-16.69-11.09
					c-1.01-0.98-1.99-1.9-2.94-2.79c-1.97-1.82-3.99-3.79-5.94-5.7c-1-0.98-2-1.96-2.96-2.88c-9.05-9.09-18.41-18.5-30.82-24.17
					c-4.13-1.96-8.55-3-13.14-3.11c-5.28-0.11-10.37,1.78-14.32,5.27c-13.04,11.44-6.99,26.69-2.58,37.81
					c2.15,5.48,4.37,11.15,4.19,15.26c-0.05,0.62-0.2,1.23-0.45,1.79c-1.85-2.32-2.63-5.41-3.58-9.13l-0.02-0.09
					c-0.68-3.06-1.64-6.09-2.88-9.05c-6.08-13.14-13.25-25.91-21.29-37.9c-0.53-0.83-1.09-1.66-1.66-2.49l-0.75-1.1l-0.07-0.09
					c-2.63-3.47-4.75-7.29-6.32-11.35c-3.03-9.93-3.84-20.41-1.75-23.47c0.1-0.15,0.27-0.25,0.46-0.25c0,0,0,0,0,0h0.41
					c1.22,0,1.96,3.89,2.21,5.53c0.99,7.31,3.21,14.35,6.61,20.98c2.39,4.3,5.41,6.47,8.97,6.47c2.01,0,6.92-0.85,8.97-8.66
					c1.95-6.96,2.21-14.38,0.75-21.46l-0.03-0.12c-0.59-2.15-1.29-4.29-2.12-6.4c-1.02-2.44-1.7-5-2.02-7.59
					c-0.13-1.17-0.16-2.36-0.18-3.35c-0.15-15.03,8.96-28.08,17.78-40.72l1.17-1.71c5.38-7.25,9.97-15.11,13.68-23.44
					c1.77-4.51,3.31-9.17,4.57-13.85c1.19-4.47,2.67-8.92,4.38-13.17c1.36-3.16,2.96-6.39,4.51-9.52l0.21-0.43
					c2.55-4.83,4.84-9.87,6.83-15.01c2.84-7.86,5.27-15.98,7.2-24.11c4.07-16.85-6.91-25.01-15.73-31.57
					c-2.34-1.74-4.76-3.55-6.85-5.45c-8.9-8.1-11.22-18.96-12.32-28.5c-1.12-10.17-1.64-20.52-1.52-30.77c0-3.21,0.21-6.41,0.43-9.8
					l0.04-0.6c0.47-5.54,0.58-11.17,0.33-16.73l-0.04-0.3c-0.08-0.42-0.06-0.86,0.05-1.26c0.34-0.08,0.98-0.17,2.05-0.17
					c1.08,0,2.37,0.08,4.33,0.27c3.25,0.31,6.58,0.75,9.8,1.18l0.42,0.06c6.11,0.94,12.33,1.53,18.59,1.77h4.45
					c4.03,0,8.51-0.43,11.11-3.57c1.79-2.01,2.36-4.84,1.5-7.4c-1.56-4.54-6.4-6.44-14.8-8.33l-1.05-0.43l-0.18-0.07
					c-2.87-0.86-5.75-1.92-8.47-2.92c-6.33-2.58-12.96-4.4-19.79-5.43c-1.21-0.14-2.44-0.21-3.69-0.21c-0.01,0-0.02,0-0.03,0
					c-5.78,0.1-11.36,1.4-16.55,3.84c-0.48,0.21-1.05,0.42-1.64,0.63c-0.53,0.19-1.07,0.39-1.51,0.57
					c-5.51,1.99-13.85,5-16.35,12.28c-1.97,6.02-0.55,11.61,0.7,16.55l0.04,0.14c0.66,2.23,1.07,4.53,1.23,6.85
					c-0.25,5.9-1.39,11.7-3.4,17.32c-1.31,4.04-2.38,8.19-3.19,12.4c-0.39-2.79-1.4-5.79-4.24-8.21l-0.16-0.12
					c-3.17-2.19-6.86-3.65-10.68-4.23l-0.22-0.05c-1.03-0.24-2-0.47-2.69-0.69l-0.52-0.15c-2.83-0.61-5.48-1.93-7.67-3.81
					c-0.72-0.79-1.46-1.72-2.28-2.76c-2.59-3.87-6.14-7.06-10.26-9.24l-0.19-0.09c-2.13-0.86-4.38-1.31-6.68-1.35
					c-3.92-0.4-7.56,1.92-8.87,5.7c-1.94,5.9,3.39,9.92,6.3,12.12c0.25,0.18,0.49,0.37,0.71,0.58c2.39,2.24,4.2,5.07,5.23,8.12
					c0.87,2.85,1.13,3.71-7.97,7.21c-4.55,1.4-8.66,3.72-12.21,6.88l-0.2,0.2c-3.49,4.14-6.15,8.83-7.91,13.95l-0.2,0.49
					c-0.27,0.65-0.54,1.31-0.83,1.98l-1.2,2.84c-2.13,4.9-4.03,9.35-5.6,14.11c-7.16,21.84-14.06,48.19-10.9,77.98
					c0.5,4.89-0.12,6.34-4.11,9.65l-0.8,0.65c-5.76,4.79-11.71,9.74-17.85,9.74c-1.12-0.01-2.24-0.17-3.32-0.47l-0.19-0.04
					c-0.43-0.08-0.8-0.33-0.98-0.65c-0.45-0.86-0.87-3.36,1.49-10.44c0.73-2.24,1.58-4.54,2.39-6.76l0.64-1.75
					c3.44-9.29,7-18.91,7.33-29.7v-2.68c0.09-9.65,0.75-19.4,1.96-28.97c1.77-9.39,4.47-18.65,8.05-27.59l0.57-1.56
					c0.5-1.44,0.98-2.88,1.46-4.35l0.04-0.12c1.84-5.56,3.58-10.81,7.38-14.1c0.09-0.07,0.51-0.4,2.22-1.48l0.2-0.13
					c4.51-2.95,12.9-8.44,9.8-16.42c-1.27-3.27-4.56-7.18-13.06-7.18c-1.5,0-2.95,0.1-4.35,0.2l-1.46,0.1
					c-1.04,0.08-1.73,0.13-2.51,0.13l-0.26,0.02c-0.24,0.03-0.49,0.04-0.73,0.02c-0.24-0.07-0.42-0.14-0.55-0.2
					c0.03-0.82,0.59-2.8,2.72-7.41c1.67-3.04,2.75-6.33,3.22-9.77l0.02-0.2c0.24-6.41-4.79-9.43-7.01-10.76
					c-0.49-0.26-0.95-0.58-1.37-0.93c-3.14-3.74-0.35-10.3,3.13-16.79c4.96-8.73,9.26-17.93,12.76-27.35l0.04-0.12
					c1.78-5.93,2.75-12.08,2.87-18.24c0.11-1.61,0.21-3.24,0.35-4.78c0.32-2.51,0.88-5,1.66-7.34c1.83-4.91,2.55-10.26,2.08-15.48
					l-0.02-0.14c-0.56-3.49-1.36-6.97-2.36-10.32c-1.6-5.68-2.86-10.16-0.95-16.14c3.01-9.44,8.15-17.88,13.59-26.82
					c2.31-3.8,4.7-7.73,6.92-11.77c3.68-6.68,3.97-13.94,4.23-20.42c0-2.09,0.15-3.91,0.32-5.73c0.86-9.07,3.74-18.01,8.33-25.88
					c2.82-3.9,6.19-7.37,10-10.31l0.09-0.08c8.26-7.19,18.54-16.13,16.93-30.03c-0.61-5.29-5-8.84-10.94-8.84
					c-2.78,0.01-5.54,0.71-7.99,2l-0.16,0.09c-4.21,2.75-7.83,6.48-10.46,10.78l-0.29,0.4c-0.77,1.08-1.49,2.1-2.2,2.97l-2.18,2.66
					c-8.52,10.65-17.73,21.91-27.26,32.02c-2.47,2.48-4.65,4.5-6.85,6.38c-5.95,5.13-13.35,11.51-16.38,21
					c-1.67,5.38-2.91,10.76-4.25,16.79c-1.55,8.01-3.88,15.91-6.94,23.48c-2.2,5.58-5.82,10.53-10.5,14.34
					c-0.57,0.48-1.19,0.88-1.86,1.18c0.64-5.12-2.53-10.09-7.64-11.55c-1.14-0.33-2.32-0.46-3.51-0.39
					c-6.27,0.03-12.68,4.28-19.26,9.04c-0.79,0.58-1.43,1.05-1.94,1.33l-0.15,0.09c-7.54,5.04-17.69,5.59-27.42,5.71
					c-1.91,0-3.83,0.1-5.68,0.19l-1.8,0.09c-1.93,0.1-4.12,0.21-6.14,0.21c-3.64,0.13-7.23-0.42-10.59-1.63
					c-3.58-1.43-6.57-4.57-7.02-5.89c0.1-0.11,0.28-0.29,0.6-0.54c1.92-1.26,4.15-1.88,6.43-1.8c3.35,0.15,6.7,0.63,10.02,1.45
					c1.72,0.37,3.57,0.76,5.4,1.06l0.65,0.03c2.09,0.4,4.23,0.63,6.4,0.67c6.18,0,8.87-2.79,10.03-5.13
					c3.4-6.89-2.98-11.99-5.77-14.21c-0.77-0.57-1.49-1.21-2.15-1.91c0.13-2.49,2.48-5.14,6-8.83c1.25-1.29,2.54-2.63,3.67-4
					c1.68-2,2.06-4.73,1-7.14c-2.13-4.76-7.77-5.14-19.38-5.51c-1.28,0-2.84-0.13-3.1-0.17l-0.04-0.01
					c2.17-2.17,4.99-5.12,4.88-9.46c-0.1-2.75-1.36-5.35-3.51-7.19c-3.08-2.42-6.76-4.05-10.61-4.72c-1.31-0.23-2.56-0.65-3.74-1.22
					c0.06-0.14,0.13-0.28,0.21-0.41l0.09-0.17c2.3-4.76,7.24-8.77,12.02-12.66l0.17-0.14c1.56-1.27,3.18-2.59,4.65-3.9l0.96-0.83
					c8.28-7.03,15.8-14.56,14.73-22.01c-0.54-3.55-2.95-6.57-6.29-7.89c-0.42-0.2-0.86-0.38-1.29-0.56l-0.29-0.12
					c-0.65-0.27-1.81-0.74-2.18-1.02c-0.16-0.42-0.14-0.89,0.08-1.29c0.34-0.65,2.65-4.05,14.3-7.12c4-1.19,8.15-1.84,12.27-1.95
					h0.5c1.15,0,2.23,0,3.32,0.14l0.25,0.02c0.76,0,1.53,0.04,2.29,0.08c0.79,0.04,1.61,0.09,2.45,0.09
					c5.67,0.35,11.15-1.49,15.46-5.19l0.08-0.07c2.87-2.75,5.57-5.73,8.01-8.87c2.14-2.8,4.52-5.45,7-7.81l1.09-0.94
					c4.37-3.69,12.51-10.57,9.97-18.4c-2.05-6.26-8.77-6.88-12-7.18l-0.19-0.01c-0.39,0-0.79-0.08-1.22-0.16l-0.16-0.02
					c-1.78-0.19-3.45-1.09-4.59-2.46c-0.93-1.81-1.12-3.87-0.54-5.83l0.24-1.13c0.76-3.78,2.33-11.63,5.15-13.74
					c0.32-0.24,0.71-0.37,1.11-0.37c3.03,0.46,5.89,1.51,8.5,3.12l0.71,0.35c0.78,0.37,1.52,0.73,2.21,1.03
					c3.84,1.7,7.92,2.68,12.06,2.93c3.54,0.32,7.05-1.27,9.12-4.18l0.12-0.18c1.86-3.32,1.87-7.44,0.05-10.77
					c-2.61-5.92-7.88-8.44-11.74-10.28l-0.12-0.05c-1.86-0.74-3.55-1.79-5.03-3.12c-1.47-1.66-2.42-7-0.75-11.15
					c0.89-2.23,1.87-2.38,2.34-2.42h0.34c2.14,0,4.97,1.73,7.78,3.45l2.77,1.69c2.81,1.99,5.95,3.38,9.32,4.13l0.13,0.02
					c0.56,0.09,1.13,0.13,1.7,0.13l0.13,0c5.8-0.35,10.99-3.57,13.89-8.61l0.06-0.1c1.75-3.51,1.24-7.71-1.28-10.67
					c-4.56-5.56-11.32-6.89-16.75-7.95l-0.1-0.02c-2.03-0.31-4.05-0.8-6.01-1.47c-4.46-2.27-4.55-3.13-1.76-12.17l0.65-2.1
					c0.28-0.83,0.39-1.71,0.32-2.57c0.18-0.16,0.36-0.34,0.53-0.52c1.22-1.44,2.26-3.03,3.06-4.67c0.28-0.51,0.93-1.72,1.38-2.31
					c1.6,0.43,3.17,0.98,4.65,1.63c3.94,1.81,8.12,2.92,12.53,3.29c4.76,0.18,9.04-2.87,10.37-7.39c2.27-7.05-4.37-13.37-9.42-15.92
					c-5.57-2.68-11.77-4.09-17.94-4.09c-0.04,0-0.07,0-0.11,0h-1.02c-0.91,0-1.86,0.09-2.87,0.18l-0.69,0.06
					c-1.18,0.11-2.8,0.25-4.13,0.25c-1.31,0-2.02-0.15-2.37-0.25c0.89-0.93,3.65-2.92,10.08-4.88l0.41-0.13
					c2.56-0.64,4.99-1.68,7.24-3.1c3.74-2.4,5.94-6.65,5.73-11.17c-0.21-2.46-1.79-10.48-13.56-10.48l-0.14,0.01
					c-6.11,0.44-12.12,1.72-17.81,3.76l-1.37,0.43c-1.34,0.41-2.72,0.62-4.09,0.65c-2.76,0-5.39-1.32-7.04-3.52l-0.08-0.1
					c-6.78-7.93-8.24-26.52-6.66-36.32c0.1-0.62,0.19-1.3,0.28-2.04c0.04-0.37,0.09-0.75,0.15-1.18c0.62-5.5,1.27-7.63,1.77-8.39
					c1.09,0.49,3.2,2.21,4.06,2.91l0.1,0.08c1.08,0.91,1.98,1.65,2.92,2.28c5.7,3.94,12.49,7.97,19.68,8.09h0.24
					c6.92,0,11.9-3.62,12.41-9.01c0.63-6.5-5.08-9.63-7.47-10.93l-0.96-0.56c-8.94-5.75-17.28-11.56-21.27-19.45
					c-1.68-3.05-2.04-6.71-0.99-9.98c0.09-0.26,0.58-0.51,2.97-1.17c4.02-0.66,7.63-2.77,10.16-5.97l0.07-0.1
					c6.78-9.76-4.86-18.06-11.11-22.52l-0.21-0.15c-1.65-1.19-5.99-4.3-6.72-5.41c-1.52-5.84-1.03-15.25,2.15-20.15
					c1.35-2.08,2.33-2.85,2.69-3.03c0.54,0.19,3.17,1.7,9.61,13.84l0.1,0.18c4.43,8.01,7.92,14.34,14.13,14.34
					c1.48,0,2.92-0.41,4.17-1.17c14.62-8.7,5.73-24.96,1.46-32.76c-0.28-0.53-0.56-1.03-0.83-1.51l-1.7-3.1
					c-0.66-1.21-2.34-4.3-2.46-5.48c1.88,0.58,4.89,2.41,6.32,3.28l0.26,0.16c0.54,0.34,1.13,0.76,1.75,1.19
					c2.73,2.24,6,3.81,9.45,4.54l0.16,0.03c0.61,0.08,1.22,0.12,1.92,0.12c2.41-0.08,4.77-0.56,7.04-1.44
					c0.95-0.38,1.94-0.61,2.96-0.7c0.13,0,0.25,0.02,0.32,0.05c2.7,1.01,5.23,2.41,7.5,4.14c4.19,3.34,9.19,5.66,14.45,6.71
					l0.1,0.02c0.8,0.12,1.62,0.18,2.41,0.19c2.06,0.04,4.04-0.48,5.8-1.52c3.17-1.58,5.02-4.87,4.73-8.48
					c-0.31-2.64-1.8-4.61-3.71-6.1c0.71,0.08,1.46,0.13,2.14,0.12c7.77,0,16.23-4.18,17.39-12.18c1.55-10.83-10.2-15.43-15.83-17.64
					c-0.32-0.13-0.65-0.25-0.96-0.37c-0.67-0.25-1.29-0.5-1.65-0.69l-0.12-0.06c-5.01-2.33-9.93-5.01-14.56-7.94
					c-8.48-5.64-13.09-12.29-13.75-19.84c1.26,1.45,2.76,2.69,4.45,3.66l0.14,0.07c1.85,0.87,3.9,1.34,6.01,1.34
					c0.01,0,0.02,0,0.03,0c6.23-0.21,11.85-3.79,14.69-9.37c3.26-6.6,1.36-13.68-0.03-18.83l-0.11-0.42
					c-0.19-0.74-0.38-1.46-0.56-2.2c-1.84-7.18-5-13.85-9.36-19.75c-0.52-0.77-1.19-1.59-1.89-2.46l-0.11-0.14
					c-1.99-2.5-2.82-3.8-3.16-4.46c0.77-0.42,2.32-0.8,4.83-0.8c3.02,0.08,6.06,0.39,9.1,0.93l2.38,0.35l0.29,0.02
					c0.29,0,0.79,0.1,1.31,0.2c0.31,0.06,0.65,0.13,0.96,0.18c4.79,0.88,9.68,1.39,14.6,1.52c14.46,0,18-7.25,18.82-11.56
					c1.36-7.09-2.99-12.58-6.49-16.99l-0.11-0.13c-1.79-1.9-3.14-4.1-4.03-6.54c-1.2-7.54,0.09-15.41,3.64-22.2
					c4.37-8.62,3.91-17.07,3.46-25.25l-0.01-0.14c-0.39-7.07-0.77-13.75,2.35-19.69c5.36-10.22,17.26-13.86,26.61-13.86
					c0.03,0,0.07,0,0.1,0c4.1,0,8.14,0.69,12.06,2.07c6.42,2.03,11.64,6.72,14.34,12.88c0.22,0.79,0.38,1.59,0.49,2.4l0.03,0.17
					c0.69,4.04,1.84,10.8,8.08,12.33c0.94,0.25,1.88,0.37,2.88,0.37c7.79,0,12.04-7.63,15.15-13.19l0.05-0.1
					c0.82-1.68,1.84-3.26,3.03-4.71c1.9-1.3,4.08-1.98,6.46-1.93c1.02,0,4.37,0.17,5.65,2.32c0.45,1.48,0.62,3.02,0.52,4.56l0,0.13
					c0,3.48,0,9.28,4.06,13.45c1.96,2.29,4.81,3.73,7.83,3.93l0.14,0c5.97,0,8.49-5.47,9.87-8.47c0.32-0.77,0.71-1.52,1.17-2.22
					c0.5-0.35,1.12-0.51,1.82-0.46c1.12,0.04,2.25,0.14,3.37,0.3c1.41,0.21,2.86,0.34,4.37,0.41l1.39,0
					c4.75,0.08,9.44-1.03,13.66-3.22c4.07-2.16,7.91-4.79,11.43-7.81c3.05-2.71,6.45-4.97,10.12-6.73c2.3-0.89,4.68-1.32,7.13-1.29
					c5.1,0.24,10.18,1.08,15.09,2.5c6.13,1.73,12.47,2.73,18.89,2.97c4.13,0.04,8.25-0.64,12.24-2.07c3.85-1.54,7.6-3.42,11.15-5.62
					c4.48-2.6,8.7-5.05,13.28-5.67c1.89-0.24,3.8-0.35,5.74-0.33h5.21c0.83,0,1.72,0.03,2.62,0.06c0.96,0.03,1.94,0.07,2.84,0.07
					c0,0,0.01,0,0.01,0c3.87,0.08,7.72-0.34,11.48-1.22l0.11-0.03c5.14-1.53,10-3.84,14.39-6.83c2.41-1.57,4.96-2.97,7.51-4.16
					c3.32-1.4,6.83-2.06,10.39-2.01c5.77,0.21,11.52,1.11,17.15,2.67c6.45,1.58,10.96,3.92,12.34,6.38
					c0.67,1.27,0.74,2.77,0.19,4.14c-1.04,2.67-2.48,5.16-4.28,7.4l-0.89,1.24c-4.35,6.09-10.31,14.44-5.97,24.87
					c0.97,2.18,2.11,4.32,3.37,6.35l0.07,0.11c1.67,2.35,2.84,5.08,3.38,7.92c0.24,3.89-2.47,8.41-5.09,12.78
					c-0.9,1.49-1.93,3.22-2.84,4.98l-1.08,2.17c-3.63,7.02-7.39,14.27-13.04,18.56c-2.48,1.71-5.15,3.16-7.98,4.34
					c-1.76,0.79-3.8,1.72-5.85,2.84c-13.92,7.66-24.69,19.19-33.69,29.38c-10.25,11.54-20.31,22.11-30.76,32.31
					c-1.88,1.82-3.77,3.75-5.59,5.61l-0.1,0.1c-4.46,4.88-9.43,9.34-14.76,13.25c-2.48,1.49-5.12,2.73-7.93,3.72
					c-5.18,2.03-10.53,4.12-14.84,8.78c-6.59,7.18-9.63,17.89-10.29,25.61c-0.11,2.65,0.07,5.31,0.51,7.82
					c0.81,6.1,0.37,6.53-0.35,6.87c-1.24,0.43-2.52,0.7-3.78,0.78c-3.7,0.15-7.29,1.32-10.32,3.36c-3.57,2.25-5.51,6.42-4.95,10.66
					c0.92,5.63,6.36,9.42,11.18,11.09c4.37,1.43,8.92,2.12,13.48,2.04c3.14,0,6.17-0.24,9.53-0.53l0.12-0.01
					c2.04-0.17,3.97-0.33,5.88-0.42h3.46c1.58,0,2.64,0.12,3.32,0.24c-0.6,1.07-2.22,3.03-6.24,6.59c-0.9,0.79-1.74,1.54-2.51,2.26
					c-5.57,5.68-10.54,11.97-14.74,18.66c-2.69,4.05-5.29,8.32-7.79,12.44l-0.57,0.94c-7.28,11.97-15.53,25.54-26.76,35.28
					c-6.52,5.92-14.57,9.76-23.2,11.1c-1.24,0.14-2.54,0.21-3.92,0.28l-0.55,0.03c-3.02,0.05-6.04,0.38-9.04,0.98
					c-1.04,0.23-10.16,2.43-11.22,9.63c-0.47,3.92,1.62,7.62,5.21,9.24c4.12,2.33,9.76,2.63,14.22,2.63c1.78,0,3.63,0,5.4-0.11
					c1.07-0.07,1.89-0.04,2.7-0.02c0.42,0.01,0.82,0.02,1.24,0.02h1.71l3.14,0.19c1.89,0.11,3.68,0.22,5.42,0.22
					c0.01,0,0.02,0,0.03,0c6.28,0.23,12.45-1.82,17.31-5.71c6.36-4.77,11.52-10.72,15.35-17.71c0.27-0.5,0.52-1.02,0.79-1.54
					l0.25-0.51c1.59-3.17,3.09-6.18,5.29-7.56c2.7-1.12,5.65-1.61,8.57-1.41l0.1,0.01l3.26-0.11c9.07-0.51,19.35-1.08,28.24-6.96
					c3.54-2.52,6.64-5.58,9.23-9.13c1.9-2.71,4.25-5.02,6.99-6.87c2.52-1.21,5.32-1.75,8.11-1.58l0.12,0c1.38,0,2.8,0,4.17,0.1
					c1.61,0.12,3.42,0.13,4.78,0.13c3.63,0.11,7.2-0.38,10.73-1.48c3.98-1.41,7.88-3.18,11.57-5.24c3.05-1.71,6.27-3.18,9.47-4.36
					c3.47-1.11,7.08-1.65,10.68-1.61c7.14,0.3,14.27,1.36,21.27,3.2l0.43,0.09c3.07,0.67,6.24,1.37,9.34,1.93
					c3.8,0.66,7.65,0.98,11.56,0.96c5.97-0.12,11.99-0.57,17.88-1.35c3.54-0.42,7-0.82,10.37-1.05c1.72-0.13,3.51-0.19,5.26-0.19
					c10.33,0.34,20.69,1.6,30.81,3.72c9.76,1.97,19.75,3.27,29.75,3.86c2.65,0.09,5.33,0.13,7.98,0.13
					c14.82-0.04,29.74-1.35,44.34-3.9c3.12-0.55,6.26-1.28,9.3-1.99l0.16-0.04c5.39-1.49,10.96-2.37,16.48-2.63
					c2-0.03,4.04,0.2,6,0.66c7.59,1.81,13.09,9.37,18.43,16.71l1.2,1.65c11.04,15.08,16.62,29.07,16.58,41.61
					c0.06,7.17-2.06,14.08-6.07,19.91c-2.86,3.75-6.16,7.18-9.83,10.18c-4.21,3.53-8.05,7.52-11.42,11.87l-0.09,0.12
					c-4.91,7.41-8.91,15.41-11.86,23.7l-1.6,4c-1.24,2.88-2.65,5.72-4.19,8.38c-3.01,4.87-5.32,10.13-6.88,15.72
					c-1.7,7.2,1.14,13.11,3.43,17.86l0.08,0.14c1.48,2.53,2.4,5.41,2.66,8.33c-0.14,5.85-4.17,11.29-8.45,17.06l-0.29,0.4
					c-1.67,2.29-3.41,4.67-4.9,7.11c-7.83,12.88-12.22,23.69-13.8,34.01c-0.11,0.71-0.21,1.39-0.32,2.1l-0.18,1.24
					c-1.22,8.64-2.38,16.8-8.22,22.25c-1.92,1.8-3.96,3.48-6.17,5.29c-5.38,4.06-10.17,8.85-14.29,14.29
					c-5.47,7.87-7.47,16.77-9.41,25.39c-0.62,2.8-1.19,5.31-1.86,7.75c-2.44,8.94-5.3,18.28-11.07,25.54
					c-8.93,11.24-18.44,19.37-28.26,24.15c-6.59,3.24-14.11,3.5-22.08,3.77l-0.75,0.03c-3.71,0.14-7.55,0.29-11.32,0.78
					c-11.08,1.1-21.6,4.47-31.23,10.01c-4.04,2.25-7.68,5.09-10.8,8.42c-3.18,3.19-4.27,8-2.78,12.25l0.08,0.19
					c1.44,3.06,4.58,4.95,7.94,4.86c1.82-0.05,3.59-0.45,5.21-1.17c3.97-1.67,7.8-3.58,11.56-5.46c5.29-2.83,10.86-5.24,16.51-7.14
					l1.75-0.55c3.79-1.37,7.76-2.21,11.78-2.5c0.86-0.01,1.79,0.1,2.63,0.32c1.73,0.48,2.44,2.65,3.14,9.65
					c0.19,2.58,0.58,5.17,1.16,7.67c1.31,6.05,4.43,11.44,9.02,15.58l0.08,0.07c3.33,2.71,7.53,4.07,11.81,3.82l1.83,0
					c1.06-0.05,2.1,0.07,3.06,0.36c0.22,0.09,0.77,0.54,2.03,2.51c1.68,2.6,3.22,5.33,4.57,8.1c1.43,2.98-1.33,6.27-8.21,9.82
					c-7.32,3.91-15.57,6.07-23.83,6.25c-1.37,0.01-2.74-0.07-4.08-0.22l-3.58-0.43c-3.84-0.55-7.75-0.87-11.58-0.95
					c-6.63-0.32-13.23,1.69-18.64,5.69c-8.15,6.55-15.54,14.07-21.97,22.38c-2.3,3.14-4.24,6.54-5.77,10.14
					c-1.16,2.81-2.68,5.47-4.5,7.91c-4.54,5.39-13.74,7.91-20.88,8.47h-1.43c-5.42,0-10.85-2.5-16.59-5.15l-0.18-0.08
					c-4.73-2.41-9.74-4.31-14.92-5.65c-4.81-1.17-9.77-1.82-14.78-1.96c-11.69,0-16.02,5.31-17.56,9.68
					c-1.46,3.58-1.07,7.64,1.04,10.88c4.54,6.95,15.66,8.26,26.64,9.01l0.97,0.07c1.01,0.07,1.89,0.13,2.5,0.22l6.61,0.89
					c8.72,1.19,18,2.44,27.17,3.51c6.77,0.77,12.52,1.34,18.15,1.8l2.93,0.15c2.22,0.08,5.24,0.19,6.44,1.04
					c0.56,0.48,1.06,1.02,1.5,1.61l0.09,0.12c0.42,0.51,0.84,1.02,1.29,1.51c4.41,4.82,10.93,7.26,19.41,7.26
					c4.81-0.05,9.6-0.72,14.23-1.99c14.28-3.88,20-13.09,26.05-22.82l1.39-2.21c2.05-2.37,4.68-4.19,7.62-5.28
					c0.88-0.33,1.81-0.48,2.8-0.46c0.87,0,1.82,0.08,2.83,0.17l0.64,0.05c1.56,0.18,3.15,0.28,4.77,0.29c1.49,0,2.9-0.16,3.94-0.27
					c1.12-0.12,2.04-0.18,2.91-0.18c0.5-0.01,1.05,0.05,1.56,0.15c7.43,1.56,13.83,6.77,20.66,12.33l0.39,0.32
					c2.87,2.33,5.84,4.73,8.92,6.83l5.25,3.71c12.21,8.71,27.41,19.54,42.74,19.54c1.45-0.01,2.96-0.1,4.43-0.3
					c0.78-0.11,1.58-0.17,2.37-0.17c0.01,0,0.02,0,0.04,0c6.25,0,13.65,3.07,22.63,9.39c6.77,4.71,9.45,12.36,10.91,20.74
					c-1.73,0.78-3.59,1.42-5.56,2.08c-4.35,1.47-8.84,3-12.23,6.29c-2.83,2.76-3.79,6.39-4.73,9.9c-0.66,2.49-1.29,4.84-2.53,6.81
					c-1.91,3.03-4.84,5.05-7.94,7.18c-2.73,1.88-5.56,3.83-7.74,6.58c-1,1.27-2.02,2.62-3,3.93c-3.06,4.08-6.22,8.31-10.13,11.38
					c-1.29,1.01-2.97,1.79-4.6,2.54c-4.13,1.91-9.27,4.28-6.51,11.25c2.12,5.36,5.47,9.94,8.71,14.36c1.33,1.82,2.71,3.7,3.97,5.6
					c1.12,1.68,2.49,3.33,3.95,5.08c3.56,4.27,7.24,8.68,7.08,13.58c-0.05,1.59-0.6,3.31-1.63,5.1c-3,5.25-7.51,6.9-12.74,8.82
					c-2.25,0.83-4.57,1.68-6.87,2.87c-4.96,2.57-6.32,6.82-7.63,10.92c-0.48,1.49-0.97,3.03-1.63,4.48
					c-0.97,2.12-1.31,2.04-3.51,1.55c-0.59-0.13-1.26-0.28-2.01-0.38c-7.17-1-13.18,1.35-18.62,4.04
					c-11.48,5.67-16.77,14.73-22.38,24.32c-1.95,3.33-3.96,6.78-6.27,10.12c-2.59,3.74-4.43,6.92-3.7,11.04
					c-10.1,3.88-17.29,8.18-23.86,14.27c-8.56,7.95-15.46,13.25-25.34,16.85c-1.04,0.38-2.24,0.59-3.51,0.81
					c-3.28,0.57-7.36,1.29-8.66,5.79c-0.83,2.88-0.68,5.82-0.53,8.65c0.18,3.42,0.35,6.66-1.08,9.83c-0.62,1.38-4.08,1.78-6.61,2.08
					c-1.51,0.18-2.94,0.34-4.03,0.69c-0.84,0.27-1.69,0.58-2.55,0.88c-3.02,1.08-5.9,2.11-8.75,1.84c0.35-1.25,0.42-2.57,0.09-3.96
					C733.52,1284.99,728.3,1282.29,719.26,1282.29z M764.43,1780.47c4.3-11.01-3.7-19.01-9-24.3l-0.14-0.14
					c-1.22-1.15-2.38-2.38-3.46-3.62c-2.79-3.29-2.92-4.59-2.67-5.43c0.99-3.44,1.83-3.65,1.84-3.65c0.16,0,4,0.11,12.7,9.72
					c2.72,3.01,5.38,6.27,7.94,9.42l0.44,0.54c1.82,2.25,3.71,4.57,5.64,6.86c1.37,1.62,8.56,9.72,15.69,9.72c0.02,0,0.05,0,0.07,0
					c4.9,0,8.95-3.81,9.24-8.71c0.35-6.04-4.69-11.81-12.9-19.16l-1.01-0.93c-4.12-4.32-7.48-9.26-9.98-14.69l-0.22-0.43
					c-1.74-3.24-3.53-6.59-5.75-9.91l-2.58-3.7c-3.97-5.67-7.72-11.04-7.95-16.94c-0.49-12.05,7.09-23.53,11.65-29.3
					c2.88-3.61,6.29-6.73,10.15-9.29c2.44-1.65,8.91-6.03,8.06-12.36c-0.8-5.93-7.35-8.27-11.69-9.81l-0.11-0.04
					c-1.79-0.52-3.55-1.2-5.22-2.01c-3.33-1.91-6.66-6.03-3.01-20.79l0.52-2.12c1.68-6.98,3.58-14.9,8.62-18.36
					c1.37-0.89,2.81-1.71,4.32-2.45c4.63-2.38,10.97-5.64,13.49-12.38l0.04-0.11c1.49-4.87,1.76-10.09,0.79-15.07
					c-0.52-2.78-0.53-5.6-0.03-8.38c0.84-2.44,2.04-4.74,3.59-6.86c1.92-2.74,3.51-5.71,4.74-8.88c3.04-8.55,1.03-20.08-2.41-26.59
					c-3.41-6.41-8.17-7.37-10.71-7.37l-0.21,0.01c-3.85,0.4-7.46,1.91-10.45,4.37c-1.04,0.72-2.14,1.37-3.27,1.94
					c-0.26,0.1-0.54,0.16-0.82,0.17c-1.69-0.19-3.32-0.67-4.85-1.45c-2.77-1.39-5.74-2.18-8.84-2.37l-0.07,0l-0.07,0
					c-3.84,0.03-7.46,1.73-9.94,4.69c-3.99,4.89-7.17,10.36-9.45,16.28c-1.76,4.76-4.31,9.15-7.57,13.04
					c-1.83-1.07-5.79-6.77-6.76-10.92c-0.27-1.17-0.28-1.22,2.08-3.71c0.91-0.93,1.67-1.75,2.4-2.65
					c4.04-4.74,7.03-10.16,8.85-16.02c1.36-3.71,0.88-7.9-1.29-11.21l-0.09-0.13c-1.76-2.27-4.58-3.61-7.41-3.54l-0.12,0.01
					c-4.21,0.33-8.06,2.25-10.84,5.43l-0.07,0.08c-1,1.27-2.48,2.11-4.08,2.33c-2.45-0.11-6.87-4.13-10.92-12.77
					c-2.1-4.85-3.8-9.91-5.07-15.09c-2.74-9.89-5.58-20.11-13.46-28.26l-1.94-2.01c-5.98-6.21-12.16-12.63-15.24-20.12
					c-2.83-6.88-1.2-10.79,2.77-19.01l0.71-1.51c2.25-5.39,3.36-11.08,3.31-16.92c0.11-2.1,0.22-3.85,0.41-5.55
					c0.6-4.46,2.53-8.57,5.58-11.88l0.09-0.11c1.22-1.53,2.54-3,3.93-4.56l0.6-0.68c3.63-3.73,6.85-7.88,9.58-12.38
					c3.51-6.2,2.23-12.39,1.54-15.72l-0.02-0.1c-0.54-2.02-0.63-4.18-0.27-6.25c1.24-4.17,4.5-8.39,7.66-12.47l0.49-0.64
					c1.34-1.75,2.73-3.57,3.91-5.41l0.8-1.2c2.39-3.73,5.02-7.35,7.82-10.78c0.61-0.61,1.27-1.19,1.97-1.77
					c2.5-2.13,5.19-4.42,6.85-7c0.74,0.13,1.48,0.2,2.21,0.2c3.12,0,6.12-1.07,8.87-2.06c0.83-0.3,1.65-0.59,2.46-0.85
					c0.8-0.26,2.09-0.41,3.46-0.57c3.6-0.42,7.68-0.9,9-3.82c1.73-3.83,1.53-7.59,1.34-11.22c-0.14-2.71-0.27-5.27,0.42-7.67
					c0.74-2.58,2.88-3.07,6.29-3.67c1.34-0.23,2.73-0.48,4.02-0.95c10.36-3.77,17.51-9.26,26.36-17.47
					c6.22-5.77,13.07-9.88,22.7-13.6c0.12,0.28,0.24,0.56,0.38,0.85c2.56,5.24,8,15.02,15.77,21.63c2.15,1.83,4.47,2.35,6.71,2.85
					c1.4,0.31,2.72,0.61,3.96,1.21c2.88,1.42,6.39,5.17,7.5,8.03c0.42,1.09,0.49,2.58-0.32,3.47c-0.88,0.98-2.55,1.08-4.31,1.19
					c-0.56,0.03-1.12,0.07-1.65,0.13c-5.14,0.57-9.9,4.13-12.14,9.07c-1.72,3.79-2.08,8.32-0.98,12.13c0.4,1.38,1,2.6,1.57,3.79
					c1.06,2.18,1.98,4.06,1.4,6.42c-0.23,0.92-0.49,1.93-0.77,3.01c-2.17,8.35-5.46,20.98-0.97,28.67c2.52,4.31,7.6,6.6,13.3,5.97
					c3.72-0.41,7.46-2.5,9.51-5.31c0.33-0.45,0.64-0.92,0.95-1.38c0.69-1.04,1.35-2.03,2.18-2.76c2.36-2.09,5.28-0.36,9.14,2.31
					c1.03,0.71,1.99,1.38,2.92,1.88c4.1,2.22,7.8,4.74,7.07,8.08c-0.61,2.81-2.06,5.53-3.46,8.15c-1.81,3.38-3.51,6.58-3.44,9.87
					c0.04,1.65-1.21,3.7-2.41,5.69c-1.57,2.59-3.06,5.04-2.34,7.41c0.39,1.27,1.37,2.29,2.93,3.01c1.01,0.47,2.33,0.75,3.73,1.03
					c2.19,0.45,4.91,1.02,5.55,2.35c0.66,1.38,0.13,2.78-0.79,4.95c-1.11,2.63-2.49,5.9-0.26,9.63c2.69,4.5,6.42,8.27,10.03,11.92
					l1.2,1.22c3.39,3.42,6.89,6.96,9.12,11.13c1.06,1.98,1.18,4.84,1.31,7.61c0.11,2.38,0.22,4.85,0.9,7
					c0.03,0.09,0.06,0.17,0.09,0.25c-4.71-0.28-10.37,0.73-14.3,3.56c-4.77,3.44-4.05,8.41-3.43,12.8c0.17,1.22,0.34,2.36,0.39,3.47
					c0.21,4.19-1.49,5.69-3.66,7.6c-1.73,1.52-3.69,3.25-4.72,6.44c-0.89,2.76,0.04,5.67,0.93,8.48c0.53,1.66,1.03,3.23,1.09,4.59
					c0.27,5.68-1.08,11.19-3.62,14.73c-3.01,4.2-7.79,5.84-12.86,7.57c-2.09,0.71-4.25,1.45-6.27,2.38
					c-8.04,3.71-11.45,11.5-13.52,17.86c-3.26,10.01,1.29,17.85,6.17,24.2c1.3,1.69,5.25,6.82,10.85,5.45
					c1.17-0.29,2.19-0.8,3.18-1.3c0.97-0.49,1.89-0.96,2.82-1.12c1.94-0.34,4.22,0.65,6.13,2.64c1.39,1.46,2.57,2.99,3.71,4.48
					c2.12,2.76,4.32,5.62,7.83,7.88l1.06,0.68c11.08,7.13,22.54,14.5,30.05,25.1c9.88,13.94,3.23,26.55-6.5,41.59
					c-1.52,2.35-3.41,5.27-3.97,8.6c-1.61,9.52,6.15,12.09,13,14.35c3.94,1.3,8.01,2.65,10.25,5.18c2.26,2.55,3.94,5.45,5.72,8.52
					c2.22,3.82,4.51,7.78,8.01,10.95c5.24,4.75,10.98,7.61,17.05,10.64c1.11,0.55,2.24,1.12,3.37,1.7c-2,1.15-3.83,2.7-5.3,4.39
					c-6.91,7.94-11.59,21.17-12.53,35.38c-0.92,13.86-1.15,32.72,5.92,44.81c-7.27,4.71-12.63,10.26-18.26,16.12
					c-2.15,2.24-4.36,4.55-6.73,6.84c-6.46,6.28-14.57,11.32-26.28,16.35c-9.52,4.08-18.08,11.17-24.77,20.49
					c-1.99,2.77-3.1,5.91-4.17,8.93c-1.08,3.06-2.11,5.95-3.96,8.49c-9.18,12.58-23.21,10.57-37,6.51l-0.08,0.29
					c-0.37-0.34-0.76-0.62-1.14-0.85c-2.73-1.69-5.59-3.02-8.36-4.29c-5.66-2.61-11-5.08-14.73-10.38l-0.06-0.08
					c-2.98-4.24-6.36-9.05-7.24-14.05c-0.22-1.28-0.19-2.45-0.16-3.59c0.07-2.71,0.14-5.5-3.52-8.05c-1.47-1.02-3.12-1.52-4.73-1.99
					c-1.66-0.5-3.23-0.97-4.35-1.97c-0.89-0.8-1.58-2.19-1.17-3.29c0.4-1.06,2.35-1.9,4.07-2.64c2.34-1.01,4.77-2.05,5.46-4.26
					c0.4-1.29,0.11-2.72-0.87-4.27c-6.53-10.39-17.46-18.2-30.29-21.76C763.21,1782.97,763.9,1781.77,764.43,1780.47z
					 M594.95,1976.46c-6.19-11.47-7.45-17.85,1.45-25.24c3.28-2.39,5.27-6.08,5.46-10.23c0-6.5-5.16-11.12-9.31-14.83l-0.09-0.08
					c-1.45-1.15-2.78-2.47-3.94-3.92c-2.2-3.47-1.77-8.09-0.97-13.76c1.07-5.51,0.99-11.09-0.24-16.57l-0.03-0.11
					c-2.82-9.91-11.48-15.59-23.78-15.59c-1.73,0.01-3.47,0.11-5.24,0.32c-13.16,1.62-43.98,5.42-42.38,31.79
					c0,0.17,0.01,0.35,0.02,0.52c-0.51-0.3-1.02-0.61-1.51-0.93c-3.42-2.36-7.2-4.02-11.34-4.95c-5.43-0.95-11.02-0.42-16.25,1.55
					l-1.12,0.47c2.67-3.27,7.74-8.54,17.38-16.56l0.95-0.79c2.66-2.28,5.53-4.49,8.3-6.63c1.3-1,2.61-2.01,3.91-3.04
					c10.61-8.09,21.57-16.47,29.52-28.62c4.66-7.7,8.37-15.97,10.99-24.5c0.58-1.68,1.17-3.35,1.78-5.02c1.34-3,1.19-6.49-0.41-9.36
					l-0.13-0.21c-1.67-2.29-4.36-3.56-7.24-3.41c-3.05,0.25-6.02,0.96-8.82,2.09c-1.3,0.5-2.66,0.9-4.03,1.17
					c-9.52-0.87-17.83-9.19-21.89-17.13c-2.24-4.31-1.64-9.08-0.87-15.12l0.02-0.15c0.8-6.29,1.62-12.8-0.98-19.68
					c-0.41-1.08-0.91-2.22-1.42-3.28c-1.42-3.02-1.33-3.2-1.05-3.75c0.93-1.88,3.04-3.8,11.63-3.8c2.22,0,4.52,0.12,7.09,0.26
					c2.24,0.12,4.61,0.24,6.85,0.24c1.86,0.02,3.73-0.1,5.62-0.38c0.74-0.13,1.49-0.2,2.22-0.21c4.01,0,7.51,1.87,11.58,11.02
					c0.36,0.78,0.71,1.62,1.07,2.47c2.02,4.99,5.08,12.54,11.9,16.3c1.75,0.92,3.72,1.4,5.78,1.35c3.26-0.21,6.47-0.89,9.54-2.03
					c0.72-0.27,1.46-0.51,2.21-0.71c-0.61,0.53-1.34,1.14-2.18,1.82l-0.08,0.07c-2.82,2-5.16,4.5-7,7.48
					c-1.79,3.04-1.79,6.81,0,9.85l0.08,0.12c2.05,2.97,5.56,4.67,9.15,4.45c1.68-0.02,3.33-0.31,4.99-0.9
					c3.22-1.28,6.18-3.08,8.82-5.35c1.55-1.38,3.31-2.49,5.22-3.3c1.93-0.55,3.93-0.89,5.9-1.01c4.05-0.17,7.99-1.09,11.77-2.75
					c6.12-3.01,7.58-9.4,8.55-13.62l0.02-0.11c0.27-1.63,0.8-3.19,1.53-4.58h0.09c1.97,0,5.47,2.1,7.55,3.35l0.35,0.21
					c8.55,4.91,18.28,7.38,28.1,7.16l1.71,0c8.97-0.22,16.92-3.46,24.61-6.59l0.18-0.08c5.07-2.31,10.4-4.09,15.76-5.25
					c1.48-0.26,2.99-0.39,4.55-0.4c3.31-0.13,6.56,0.91,9.19,2.9c2.01,2.08,3.59,4.62,4.57,7.34l0.05,0.13
					c1.67,3.81,3.99,7.25,6.95,10.3c2.98,2.77,6.85,4.3,10.89,4.3c0.09,0,0.18,0,0.27,0c4.31,0,8.32-1.61,11.37-4.38
					c12.74,3.16,23.63,10.7,30,20.84c0.33,0.53,0.7,1.27,0.54,1.78c-0.28,0.89-2.14,1.69-3.78,2.4c-2.39,1.03-4.85,2.08-5.7,4.34
					c-0.92,2.48,0.32,5.08,1.97,6.57c1.61,1.46,3.59,2.04,5.5,2.62c1.44,0.43,2.8,0.84,3.87,1.58c2.33,1.62,2.3,3,2.23,5.51
					c-0.03,1.22-0.07,2.61,0.21,4.18c0.99,5.65,4.58,10.75,7.74,15.25l0.06,0.08c4.19,5.96,10.15,8.71,15.92,11.38
					c2.69,1.24,5.46,2.52,8.04,4.12c0.26,0.16,0.7,0.43,0.83,0.66c0.17,0.3,0.06,1,0.02,1.27c-0.75,4.73-3.78,9.46-8.58,13.33
					c-4.64,3.76-6.19,3.89-6.66,3.77c-1.73-0.45-2.53-2.14-3.63-4.86c-0.95-2.33-2.02-4.98-4.43-6.44
					c-2.36-1.42-4.92-1.58-7.39-1.74c-1.11-0.07-2.16-0.13-3.17-0.31c-1.57-0.28-3.09-0.76-4.69-1.28
					c-1.84-0.59-3.74-1.19-5.75-1.48c-5.01-0.71-8.45,1.7-11.78,4.03c-1.56,1.09-3.18,2.22-4.94,2.99
					c-5.48,2.39-9.49,5.93-11.65,10.16c-0.22-0.23-0.45-0.46-0.71-0.66c-2.49-2.04-6.53-2.69-9.83-1.59
					c-2.78,0.93-4.89,2.43-6.93,3.88c-2.59,1.84-5.03,3.59-8.75,4.06c-3.19,0.41-6.55,0.63-10.1,0.86
					c-10.63,0.7-21.62,1.42-30.21,7.29l-1.26,0.86c-4.46,3.01-10.01,6.76-12.29,11.86c-1.73,3.87-0.22,7.38,1.11,10.48
					c0.76,1.76,1.47,3.43,1.64,5.09c0.74,7.61-6.56,12.96-13.66,14.71c-1.95,0.48-5.12,0.08-8.47-0.35
					c-5.98-0.76-12.74-1.62-15.92,2.35c-0.69,0.86-0.97,1.86-1.21,2.81c-0.61,2.46-0.88,5.1-1.14,7.65
					c-0.42,4.07-0.85,8.28-2.66,11.64c-2.01,3.73-6.19,7.23-9.56,10.04l-0.13,0.11c-6.66,5.56-15.53,11.54-25.48,9.34
					C598.35,1983.85,596.88,1980.07,594.95,1976.46z M492.26,2109.58c0.1-0.63,0.2-1.22,0.28-1.78c3.13-0.98,6.36-1.63,9.69-1.94
					c4.23-0.53,8.44-1.41,12.6-2.61c9.25-3.02,17.64-8.38,24.23-15.49c2.96-3.08,7.29-4.81,11.82-6.63
					c4.61-1.61,8.98-3.83,13.05-6.64c15.43-11.5,27.87-30.45,34.14-51.97c3.28-11.33,3.95-21.87,2.06-31.5
					c10.62,1.7,19.76-4.49,26.66-10.25l0.13-0.11c3.56-2.97,8-6.67,10.28-10.92c2.09-3.88,2.55-8.39,3-12.75
					c0.26-2.57,0.51-4.99,1.07-7.24c0.17-0.68,0.33-1.27,0.64-1.66c2.13-2.65,8.01-1.91,13.2-1.25c3.64,0.46,7.08,0.9,9.57,0.29
					c8.31-2.05,16.84-8.52,15.93-17.91c-0.21-2.13-1.05-4.09-1.87-5.99c-1.25-2.9-2.32-5.4-1.13-8.07
					c1.94-4.32,7.09-7.81,11.24-10.61l1.27-0.86c7.92-5.41,18.49-6.11,28.72-6.78c3.6-0.24,7-0.46,10.29-0.88
					c4.46-0.57,7.33-2.62,10.1-4.6c1.94-1.39,3.78-2.7,6.14-3.48c2.33-0.78,5.27-0.33,6.98,1.07c0.64,0.52,1.06,1.15,1.28,1.86
					c-0.01,0.04-0.03,0.09-0.04,0.13l0.08,0.02c0.13,0.49,0.16,1.03,0.09,1.61c-0.21,1.72-0.92,3.27-1.67,4.92
					c-1.18,2.59-2.41,5.27-1.72,8.74c2.11,10.66,10.91,15.32,19.42,19.82c3.62,1.92,7.05,3.73,9.98,5.98
					c-0.07,1.03-2.76,3.96-4.22,5.56c-2.3,2.51-4.29,4.67-4.74,6.55c-1.42,5.93-3.15,12.26-6.1,18.43
					c-0.27,0.57-1.03,1.72-1.92,3.05c-4.46,6.72-8.66,13.66-6.86,18.23c0.48,1.22,1.63,2.81,4.41,3.47c1.57,0.37,3.64-0.94,7.7-4.04
					c0.64-0.49,1.25-0.96,1.54-1.14c2.08-1.32,3.72-1.82,4.5-1.37c1.71,1,1.42,6.54,1.25,9.86l-0.06,1.33
					c-0.14,3.15-0.14,3.15-2.94,3.78c-0.35,0.08-0.73,0.16-1.15,0.26c-1.79,0.42-3.6,0.8-5.42,1.18c-3.5,0.74-7.13,1.5-10.62,2.56
					c-5.3,1.62-19.4,5.93-16.37,15.9c1.52,5.02,6.64,8.02,11.16,10.68c1.46,0.86,2.84,1.67,3.98,2.48
					c4.18,2.98,9.73,6.72,15.68,9.22c0.91,0.38,2.13,0.72,3.42,1.07c3.09,0.85,7.33,2.02,7.83,4.14c0.23,0.98-0.24,1.51-1.96,2.82
					c-1.35,1.02-2.88,2.18-3.27,4.16c-0.57,2.91,0.79,5.14,1.89,6.94c1.39,2.29,2,3.47,0.53,5.43c-1.18,1.57-3.39,2.38-5.72,3.23
					c-1.87,0.69-3.81,1.4-5.35,2.58c-5.14,3.95-6.65,11.66-7.86,17.85c-0.25,1.28-0.49,2.5-0.75,3.62
					c-2.38,10.33-0.72,21.04,0.89,31.4c0.55,3.51,1.11,7.15,1.49,10.63c0.5,4.69,1.16,8.93,1.98,12.85l-0.2,0.12
					c0.03,0.05,0.15,0.24,0.34,0.54c1.02,4.73,2.28,8.99,3.84,12.94c3.62,9.12,2.77,15.22-2.73,19.75
					c-6.01,4.95-14.54,9.01-21.22,10.1c-1.42,0.23-3.03,0.12-4.73-0.01c-3.5-0.25-7.48-0.54-10.42,2.46
					c-2.82,2.88-5.2,3.75-8.46,3.12c-3.37-0.66-10.67-3.44-12.23-5.66c-0.23-0.33-0.35-0.82-0.48-1.4c-0.14-0.61-0.3-1.3-0.66-1.96
					c-0.52-1-1.37-1.61-2.44-1.78c-2.7-0.43-6.67,2.09-12.88,8.17l-0.32,0.31c-1.55,1.5-2.8,3.24-4.01,4.93
					c-1.42,1.98-2.77,3.85-4.5,5.25c-1.9,1.54-4.42,1.56-5.76,1.44c-3.77-0.35-6.89-2.14-10.19-4.04c-0.74-0.43-1.49-0.86-2.25-1.27
					c-1.73-0.95-4.09-2.24-6.69-1.97c-3.61,0.38-4.55,3.12-5.24,5.12c-0.8,2.32-1.24,3.6-4.44,3.35c-3.6-0.29-10.01-3.32-11.16-6.75
					c-1.64-4.86,3.55-9.13,7.33-12.25l0.23-0.19c7.9-6.51,11.82-13.58,13.54-24.4c1.48-9.31,3.04-16.61,6.21-24.19
					c0.78-1.86,1.58-3.78,1.83-5.92c0.88-7.51-4.99-14.53-9.7-20.17c-0.8-0.95-1.56-1.86-2.25-2.73c-5.1-6.42-10.22-7.51-17.35-7.21
					c-3.17,0.14-6.49-0.1-10-0.34c-6.7-0.47-13.63-0.95-19.75,1.22c-8.47,3.01-15.51,8.45-22.32,13.71l-2.83,2.19
					c-7.65,5.95-20.46,15.91-29.59,12.96c-1.41-0.45-2.47-1.5-3.59-2.61c-1.58-1.56-3.37-3.33-6.35-3.59
					c-3.83-0.34-7.61,0.14-11.26,0.61c-4.35,0.56-8.46,1.08-12.62,0.33c-2.58-0.47-5.01-1.39-7.58-2.37
					c-2.1-0.8-4.26-1.63-6.52-2.18c-1.18-0.29-2.58-0.36-4.07-0.43c-2.81-0.14-5.99-0.3-6.95-2.05
					C491.2,2115.95,491.78,2112.42,492.26,2109.58z M834.41,2227.57c-0.04,0.04-0.08,0.09-0.12,0.13l-0.15,0.17
					c-8,8.87-16.28,18.05-26.46,23.59c-4.98,2.79-10.62,4.28-16.31,4.33c-1.35,0-2.72-0.1-4.07-0.29l-0.93-0.12
					c-2.64-0.43-5.34-0.67-8.02-0.74c-4.93-0.07-9.83,1.48-13.81,4.43c-6.03,4.61-11.57,9.88-16.48,15.71
					c-2.47,3.01-4.5,6.35-6.05,9.94c-1.11,2.65-2.61,5.12-4.46,7.33c-4.71,4.94-11.84,7.25-22.44,7.25c-2.31,0-4.71,0-7.52-0.32
					l-3.19-0.24c-8.21-0.57-16.7-1.16-23.44-4.8c-11.74-6.36-19.52-18.57-27.04-30.39l-1.55-2.45c-5.42-8.5-12.85-20.15-25.46-22.78
					c-1.47-0.31-3-0.45-4.47-0.45c-8.51,0-15.83,4.72-21.72,8.51l-0.61,0.39c-1.28,0.83-2.53,1.63-3.62,2.29
					c-7.62,4.3-16.27,6.73-25.01,7.03c-2.78,0.09-5.5-0.41-8-1.44c-1.26-0.59-2.25-1.67-2.72-3c-0.74-2.03-0.66-4.23,0.21-6.2
					c0.48-1.09,4.05-1.72,6.18-2.1l0.43-0.08c1.28-0.21,2.45-0.44,3.46-0.67l0-0.02c2.34-0.52,12.2-3.8,13.79-11.29
					c0.73-3.96-1.08-8-4.5-10.1c-1.94-1.35-4.32-2.03-6.62-1.92c-1.55,0.03-3.11,0.19-4.57,0.45l-1.44,0.21
					c-2.15,0.19-4.64,0.4-7.01,0.4l-0.15,0.01c-3.62,0.26-7.29-0.65-10.35-2.58c-3.52-2.57-4.2-5.21-2.81-10.79l0.24-0.9
					c0.96-3.78,3.87-15.28-6.88-18.13c-1.01-0.27-2.06-0.4-3.12-0.41c-7.06,0-12.07,5.87-13.93,8.44c-1.52,2.24-2.48,4.85-2.77,7.54
					c-0.07,0.44-0.25,1.54-0.38,1.75c0,0-0.02,0.02-0.07,0.07c-2,1.06-4.25,1.54-6.51,1.36l-0.08-0.01l-0.3,0
					c-3.11-0.09-6.24-0.44-9.31-1.03c-3.71-0.69-7.5-1.09-11.32-1.18l-0.25,0c-5.99-0.27-11.43,3.75-12.85,9.64
					c-0.53,2.33-0.7,4.72-0.49,7.12c0.09,0.99,0.1,2,0.01,2.99c-0.19,1.09-0.37,1.37-0.35,1.37c-0.16,0.07-0.64,0.11-1.45,0.11
					c-1.05,0-2.32-0.13-3.67-0.27l-0.17-0.02c-1.76-0.22-3.55-0.35-5.36-0.38c-1.27-0.02-2.56,0.11-3.86,0.34
					c-6.78,1.35-9.18,6.74-10.81,10.4c-0.41,1.06-0.95,2.06-1.59,2.99c-1.87,2.08-4.64,3.2-7.5,3.04c-3.27-0.08-6.43-0.82-9.45-2.23
					c-3.99-1.73-7.16-4.88-8.92-8.89c-0.52-1.15-0.96-2.32-1.38-3.45l-0.26-0.69c-1.5-4.34-3.36-9.74-9.96-13.66
					c-2.92-1.73-4.07-2.65-4.52-3.1c0.14-0.35,0.44-0.92,1.04-1.71l0.5-0.65c5.92-7.73,12.06-16.69,10.06-25.34
					c-1.04-4.21-4.03-7.78-8.01-9.54c-4.51-1.98-9.48-2.9-14.4-2.66h-5.08c-2.82,0-4.54-0.18-5.55-0.37
					c0.14-0.31,0.31-0.6,0.51-0.88l0.07-0.12c2.17-3.61,6.68-5.6,11.45-7.72c1.61-0.71,3.61-1.58,5.46-2.57
					c4.5-2.06,8.41-5.26,11.32-9.26l0.1-0.15c1.44-2.4,2.5-5,3.14-7.76c0.34-1.58,0.96-3.06,1.84-4.41c0.44-0.56,1.13-0.87,1.9-0.8
					c2.37,0.17,4.71,0.64,6.96,1.38c3.39,1.11,6.91,1.75,10.56,1.92c0.02,0,0.04,0,0.05,0c1.4,0,2.8-0.16,4.17-0.49
					c7.06-1.75,17.42-4.89,25.87-11.88c3-2.66,5.63-5.7,7.84-9.07c2.46-3.48,4.41-6.24,7.42-7.93c0.65-0.29,1.31-0.57,1.97-0.83
					c-0.01,0.05-0.02,0.1-0.03,0.16c-0.55,3.29-1.23,7.39,0.34,10.27c1.76,3.24,6.02,3.45,9.44,3.62c1.34,0.07,2.6,0.13,3.5,0.35
					c2.08,0.51,4.07,1.27,6.17,2.07c2.58,0.99,5.26,2.01,8.12,2.52c4.62,0.83,9.15,0.25,13.54-0.31c3.66-0.47,7.12-0.91,10.62-0.6
					c1.9,0.17,3.11,1.36,4.51,2.74c1.26,1.25,2.69,2.66,4.78,3.33c10.64,3.43,24.23-7.14,32.35-13.45l2.83-2.19
					c6.93-5.35,13.47-10.41,21.49-13.26c5.53-1.96,12.14-1.5,18.54-1.06c3.44,0.24,7,0.48,10.34,0.35
					c6.46-0.28,10.52,0.6,14.87,6.07c0.7,0.89,1.48,1.82,2.29,2.79c4.35,5.2,9.75,11.67,9.02,17.9c-0.2,1.72-0.92,3.45-1.62,5.11
					c-3.28,7.85-4.88,15.34-6.4,24.87c-1.62,10.16-5.12,16.48-12.49,22.56l-0.23,0.19c-4.21,3.47-10.56,8.7-8.27,15.52
					c1.62,4.82,9.24,8.42,13.77,8.78c0.32,0.03,0.63,0.04,0.92,0.04c4.73,0,5.84-3.22,6.59-5.4c0.68-1.98,1.08-2.94,2.72-3.11
					c1.53-0.17,3.14,0.64,4.93,1.62c0.74,0.41,1.47,0.83,2.2,1.24c3.44,1.97,6.99,4.01,11.41,4.42c3.09,0.29,5.91-0.46,7.92-2.1
					c2.04-1.65,3.56-3.77,5.04-5.83c1.19-1.65,2.31-3.21,3.67-4.52l0.33-0.32c7.13-6.98,9.58-7.47,10.32-7.35
					c0.09,0.01,0.15,0.02,0.25,0.21c0.17,0.33,0.28,0.78,0.39,1.26c0.18,0.76,0.38,1.63,0.94,2.43c2.38,3.4,10.95,6.26,14.11,6.88
					c5.43,1.07,8.83-1.56,11.18-3.96c1.97-2.01,4.92-1.8,8.06-1.57c1.8,0.13,3.67,0.26,5.43-0.03c7.15-1.17,16.25-5.49,22.64-10.75
					c6.49-5.35,7.67-12.92,3.61-23.17c-0.77-1.95-1.46-3.99-2.09-6.12c2.8,3.01,6.74,6.47,11.74,8.9c3.12,1.52,5.01,2.71,6.38,3.58
					c3.67,2.33,4.38,2.09,8.24-0.85c1.72-1.32,2.95-3,4.02-4.49c1.16-1.6,2.16-2.98,3.22-3.09c0.89-0.09,2.25,0.66,3.91,2.17
					c4.71,4.31,8.14,12.25,10.9,18.63c1.38,3.19,2.57,5.95,3.7,7.71c1.98,3.09,5.36,2.36,7.84,1.83c3.45-0.74,4.88-0.9,5.68,2.62
					C834.76,2195.96,834.56,2220.15,834.41,2227.57z M946.33,2481.4c-10.71,7.79-4.04,18.49-0.82,23.64
					c2.71,4.34,3.18,5.75,3.11,6.16c0,0-0.04,0.07-0.21,0.23c-0.18,0.17-1.92,1.62-8.55,1.62c-7.37-0.23-14.73-1.28-21.9-3.12
					c-2.35-0.56-4.79-1.24-7.16-1.88l-0.89-0.25c-7.24-2.41-14.78-3.81-22.5-4.15c-1.51,0-3.04,0.09-4.53,0.25
					c-3.64,0.38-7.36,1.16-10.7,1.86c-4.49,1.09-9.11,1.74-13.65,1.93c-1.59,0.03-3.18-0.15-4.72-0.45
					c-9.06-1.93-16.73-7.32-24.83-13.02c-4.58-3.34-9.41-6.46-14.37-9.28c-6.33-3.49-13.48-5.33-20.76-5.33c-0.09,0-0.18,0-0.26,0
					c-6.7,0.23-13.38,1.18-19.81,2.81c-3.45,0.75-7.37,1.59-11.29,2.14c-12.71,1.82-24.94,5.74-36.35,11.66
					c-3.05,1.6-6.42,3.03-9.63,4.38c-5.5,2.14-10.84,4.8-15.91,7.93c-11.61,7.64-19.29,20.78-23.5,40.22
					c-0.93,5.21-1.21,10.54-0.83,15.74c0.25,8.01,0.46,14.33-3.88,19.3c-3.33,4.11-6.21,8.6-8.55,13.28
					c-3.83,7.04-7.79,14.31-13.17,16.36c-0.8,0.31-1.65,0.43-2.51,0.45c-6.2,0-14.72-6.82-20.39-11.36
					c-0.58-0.45-1.15-0.89-1.71-1.33c-2.2-1.73-4.28-3.36-6.26-4.58c-19.79-12.64-43.15-19.05-69.44-19.05l-1.44,0
					c-6.14,0.16-12.33,0.67-18.38,1.5l-0.57,0.07c-5.17,0.6-10.05,1.16-14.91,1.3l-0.64,0c-2.81-0.08-5.64-0.32-8.42-0.72
					c-3.65-0.51-7.36-0.81-11.01-0.88c-4.76-0.17-9.52,0.87-13.81,3.06c-7.44,4.08-11.24,10.46-14.59,16.09l-0.19,0.33
					c-3.17,5.33-5.92,9.94-11.39,12.28c-2.01,0.84-4.09,1.65-6.29,2.5l-1.24,0.48c-10.95,4.18-24.59,9.38-32.6,20.13
					c-6.27,8.5-3.47,16.5-1.4,22.38l0.04,0.13c1.34,3.78,2.39,6.77,1.4,9.63c-2.54,7.19-8.79,13.03-13.97,13.06
					c-2.25-0.09-4.33-1.17-5.75-3c-2.41-2.95-4.68-6.06-6.76-9.3c-7.96-11.76-17.87-26.4-32.99-26.4
					c-4.19,0.07-8.35,1.19-12.08,3.28c-3.25,2-6.25,4.4-8.83,7.08c-3.39,3.17-8.04,7.52-10.94,7.52c-0.95-0.04-1.87-0.41-2.59-1.04
					l-0.15-0.12c-1.23-0.89-1.87-2.38-1.64-3.99c0.57-6.18,7.99-14.31,12.65-16.75c6.51-3.16,13.38-5.63,20.48-7.38
					c9.21-2.61,19.65-5.56,29.25-12.12c17.52-12.11,30.13-24.49,37.5-36.84c2.93-5.2,5.43-10.67,7.42-16.22
					c3.14-7.99,6.11-15.53,11.8-21.2c5.38-5,11.28-9.48,17.62-13.35c3.71-2.45,7.91-5.23,11.85-8.25
					c7.85-6.04,13.16-13.71,18.33-21.17c3.04-4.65,6.46-9.11,10.1-13.22c9.06-9.77,18.42-19.87,18.13-38.55
					c0-1.97-0.17-4.1-0.36-6.36l-0.13-1.63c-0.68-8.84-1.52-19.84,2.76-24.23c1.6-1.55,3.8-2.33,6.02-2.14l0.11,0.01
					c2.66,0.09,5.35,0.38,7.99,0.86c3.39,0.61,6.85,0.96,10.25,1.05c5.92,0.25,11.66-1.79,16.12-5.75
					c8.38-7.61,9.41-19.3,10.24-28.67l0.15-1.6c0.05-0.55,0.1-1.1,0.15-1.65c0.79-7.62,5.31-18.13,35.42-21.3l9.86-1.06
					c12.89-1.44,26.22-2.93,39.33-3.1h0.78c10.89,0,21.24,2.93,32.2,6.04c5.64,1.6,11.15,3.14,16.7,4.36
					c4.09,0.84,8.32,1.28,12.49,1.23c6.46-0.15,12.97-0.77,19.36-1.84c5.85-1,11.81-1.58,17.69-1.72h4.8
					c16.22,0,19.58-7.46,23.72-19.62c3.74-11.04,8.32-21.91,13.61-32.31c5.81-11.45,13.96-18.41,23.37-26.44
					c1.89-1.55,3.66-3.11,5.53-4.76l0.9-0.79c10.78-9.46,19.26-20.81,27.46-31.79l2.58-3.57c1.14-1.52,2.22-2.85,3.37-4.17
					c2.96-3.19,5.51-6.75,7.6-10.67c1.46-3.01,3.1-7.47,0.59-11.47c-1.6-2.44-4.36-3.89-7.23-3.76c-5.29,0-11.72,4.1-14.4,6.87
					c-1.86,1.9-3.8,3.96-5.93,6.31c0.12-9.37,0.14-29.27-1.54-36.66c-0.56-2.45-1.55-3.84-2.75-4.59
					c11.86-3.31,23.3-11.62,28.23-21.48c2.32-4.63,0.93-9.85-0.29-14.45c-0.28-1.04-0.55-2.06-0.76-3.03
					c-0.8-3.63-0.63-6.35,0.54-8.31c1.97-3.31,8.23-6.43,12.29-6.9c3.37-0.39,7.69,2.45,11.87,5.19c6.69,4.39,14.26,9.37,20.35,2.57
					c0.78-0.88,1.45-1.82,2.09-2.73c0.86-1.22,1.67-2.37,2.69-3.27c5.65-4.99,20.38-6.19,27-4.32c2.45,0.69,5.08,2.38,7.87,4.16
					c5.12,3.28,10.91,7,16.93,4.77c5.36-2,4.35-9.36,3.8-13.32c-0.05-0.37-0.1-0.71-0.14-1.02c-0.35-2.84-0.62-5.59,0.06-8.11
					c0.73-2.71,4.14-5.33,8.69-6.69c4.59-1.37,8.94-1.01,11.08,0.9c6.87,6.12,12.29,19.62,13.91,29.22
					c1.7,10.07-0.93,18.81-3.72,28.06c-0.64,2.12-1.3,4.31-1.91,6.53l-0.25,0.91c-1.48,5.36-3.01,10.9-3.91,16.46
					c-0.76,4.69-0.16,8.96,0.47,13.48c0.16,1.15,0.32,2.31,0.46,3.49c0.96,8.02,2.04,17.11,2.71,26.03c0.2,2.66,0.32,5.05-0.23,7.27
					l-0.35,1.39c-2.1,8.38-3.62,14.43,1.53,23.22c2.67,4.55,6.15,8.52,9.52,12.37c2.37,2.71,4.83,5.5,6.92,8.43
					c11.83,16.53,12.15,41.9,0.71,56.56c-3.26,4.18-7.69,6.52-12.37,8.99c-0.91,0.48-1.82,0.96-2.73,1.46
					c-6.46,3.54-8.73,8.46-6.93,15.06c0.61,2.24,1.19,4.53,1.78,6.84c2.41,9.52,4.91,19.37,10.03,27.69l0.27,0.43
					c3.95,6.4,9.36,15.17,4.38,21.49c-2.03,2.58-5.39,4.42-9.44,5.18c-9.33,1.75-19.84-0.75-28.13-6.67l-0.27-0.19
					c-6.1-4.37-12.26-8.13-16.05-6.06c-1.88,1.03-2.74,3.25-2.55,6.59c0.32,5.88,4.06,12.02,7.06,16.95
					c0.37,0.6,0.72,1.19,1.06,1.75c4.78,7.98,7.42,17.04,9.98,25.79c0.96,3.3,1.96,6.69,3.06,10.03
					C962.34,2475.76,952.78,2476.71,946.33,2481.4z M1383.72,2283.05c0.43-0.1,0.86-0.21,1.29-0.31c0.76-0.05,1.5-0.08,2.21-0.08
					l0.1,0c0.21-0.01,0.43-0.01,0.65-0.01c-0.91,0.89-3.45,2.58-4.62,3.35l-0.88,0.58c-5.21,3.31-11.12,7.07-12.04,13.29
					c-0.41,3.31,0.66,6.6,2.93,9.05c2.91,3.72,7.28,5.91,12.05,6.02c7.52,0,13.62-5.54,19-10.42l0.1-0.09
					c1.61-1.56,3.36-3.02,5.11-4.28c2.11-1.39,4.57-2.12,7.01-2.12c0.03,0,0.06,0,0.09,0c4.24,0.21,8.33,1.51,11.95,3.78
					c-2.36,1.7-4.89,4.22-5.46,8.2c-0.39,2.53,0.35,5.08,2.02,7c2.73,3.14,7.63,4.53,15.88,4.53c8.58,0,21.76-1.55,37.08-4.35
					l1.56-0.27c4.71-0.92,9.38-2.25,13.92-3.99c5.13-2.15,10.55-3.44,16.11-3.82h1.99c1.11,0.12,1.9,0.27,2.42,0.41
					c-0.29,0.79-0.86,2.15-2,4.46c-1.93,4.06-4.12,8.67-3.95,13.62c0.25,4.64,0.78,9.32,1.58,13.87c1.46,9.33,2.84,18.15-1.14,25.78
					c-4.76,9.12-16.75,12.38-28.36,15.53c-4.03,1.09-8.59,2.33-12.74,3.88c-8.41,3.17-17.7,7.67-22.81,16.94l-0.05,0.11
					c-2.05,4.32-3.15,8.93-3.28,13.71c-0.71,8.01-1.13,8.01-4.96,8.06h-0.74c-1.93,0-3.75,0-5.47-0.2c-1.8-0.21-3.47-0.22-5.1-0.21
					h-0.39c-5.13-0.11-10.28,1.05-14.89,3.32c-3.57,1.76-6.92,3.74-10.38,5.82c-5.18,3.37-10.77,6.11-16.54,8.13
					c-2.48,0.77-5.1,1.44-7.63,2.09l-1.39,0.36c-8.67,2.17-17.64,4.41-25.65,10.23l-4.07,2.97c-6.66,4.85-12.94,9.44-20.24,12.01
					c-2.18,0.73-4.45,1.08-6.8,1.04c-7.8,0-16.77-3.16-25.48-6.22l-1.72-0.6c-3.68-1.28-7.15-2.48-10.53-3.38
					c-14.44-3.77-29.31-5.67-44.23-5.6c-11.33,0.03-22.74,0.98-33.92,2.81c-7.63,1.21-15.21,3.2-22.54,5.12l-0.72,0.19
					c-11.54,3.04-23.48,6.18-35.19,6.18l-2.54,0c-16.65-0.66-40.48-4.85-56.25-12.71c-2.42-1.58-4.62-3.48-6.55-5.64l-0.13-0.14
					c-5.08-5.08-10.84-10.84-18.33-10.87c-0.43-0.02-0.87-0.02-1.37,0.01c-9.86,0.92-12.88,10.64-15.1,17.77
					c-1.08,3.54-2.57,8.39-4.37,9.75c-10.65,7.42-23.58,8.42-34.82,8.42c-4.07,0-8.39-0.14-11.93-0.26c-1.86,0-3.75-0.07-5.58-0.13
					c-1.49-0.05-2.97-0.11-4.47-0.13h-7.76c-1.21-3.59-2.3-7.27-3.34-10.85c-2.61-8.94-5.3-18.18-10.28-26.49
					c-0.34-0.58-0.71-1.17-1.08-1.77c-2.83-4.64-6.35-10.42-6.63-15.55c-0.06-1.18-0.02-3.24,1-3.8c1.76-0.97,6.09,1.01,12.86,5.86
					l0.27,0.19c8.93,6.4,20.3,9.08,30.42,7.18c4.77-0.9,8.77-3.12,11.25-6.28c6.27-7.97-0.02-18.17-4.19-24.92l-0.26-0.43
					c-4.88-7.92-7.32-17.55-9.68-26.85c-0.59-2.33-1.17-4.64-1.79-6.89c-1.42-5.2,0.27-8.79,5.47-11.64c0.9-0.49,1.8-0.97,2.69-1.44
					c4.76-2.51,9.67-5.11,13.33-9.8c12.41-15.9,12.13-42.32-0.63-60.15c-2.19-3.05-4.69-5.91-7.11-8.67
					c-3.28-3.74-6.67-7.6-9.19-11.9c-4.53-7.73-3.28-12.72-1.21-20.98l0.35-1.39c0.66-2.66,0.53-5.3,0.31-8.22
					c-0.68-8.98-1.77-18.11-2.73-26.16c-0.14-1.2-0.31-2.38-0.47-3.55c-0.6-4.29-1.17-8.34-0.48-12.59
					c0.87-5.4,2.38-10.86,3.84-16.14l0.25-0.91c0.6-2.19,1.26-4.36,1.89-6.46c1.84-6.1,3.61-11.99,4.19-18.23
					c5.38-2.68,17.93-10.53,22.54-20.59c2.11-4.59,2.41-7.75,2.66-10.29c0.29-2.95,0.48-4.9,3.46-7.92
					c2.06-2.08,3.27-4.21,4.35-6.09c2.28-3.99,4.08-7.15,12.87-9.27l12.32-2.97c0,0.16,0.01,0.33,0.03,0.5
					c0.28,2.61,2.22,5.07,5.94,7.52l0.47,0.31c1.91,1.25,6.98,4.56,7.03,6.96c0,0.22,0.02,0.91-1.21,1.78
					c-9.27,6.55-7.12,16.69-3.79,25.75c1.78,4.86,6.28,17.11,15.05,17.11c0.78,0,1.6-0.1,2.46-0.31c4.7-1.15,7.93-5.96,10.52-9.81
					c0.61-0.91,1.19-1.77,1.75-2.53l0.61-0.83c3.47-4.72,7.05-9.59,11.78-13.07c2.42-1.78,4.67-2.83,6.86-3.21
					c3.55-0.6,5.91,1.29,8.64,3.49c0.86,0.69,1.75,1.41,2.72,2.08c1.64,1.12,3.43,1.46,5.19,0.96c4.1-1.15,7.23-6.45,9.13-10.69
					c0.63-1.41,1.21-2.87,1.76-4.27c1.13-2.85,2.19-5.55,3.74-8.01c4.2-6.67,11.07-12.52,17.71-18.18c2.27-1.93,4.44-3.79,6.5-5.66
					c2.08,7.49,2.78,15.51,2.05,23.85c-0.8,9.26-2.52,18.59-4.17,27.61c-0.51,2.8-1.03,5.6-1.52,8.41c-0.45,2.57-0.73,5.19-1,7.72
					c-0.31,2.86-0.63,5.82-1.19,8.67c-0.28,1.43-1.12,2.88-2,4.42c-1.24,2.16-2.53,4.39-2.52,6.98c0.03,5.81,4.9,6.98,8.81,7.93
					c1.02,0.25,1.99,0.48,2.87,0.78c3.69,1.25,6.99,3.87,9.05,7.17c1.88,3.01,2.23,5.66,0.97,7.46c-1.42,2.03-4.75,2.76-8.68,1.92
					c-3.47-0.75-6.59-2.27-9.89-3.88c-2.84-1.39-5.77-2.82-8.93-3.7l-0.46-0.13c-3.97-1.12-5.24-0.76-6.59,3.89
					c-1.51,5.22,0.59,11.06,5.77,16.01c3.65,3.5,7.85,6.11,11.91,8.63c4.59,2.85,8.93,5.55,12.56,9.43
					c5.23,5.58,8.75,10.75,10.76,15.81c1.48,3.73,1.98,6.68,1.57,9.28c-0.1,0.65-0.35,1.37-0.62,2.12
					c-0.15,0.43-0.32,0.91-0.46,1.42l-0.05,0c-0.01,0.09-0.01,0.19-0.02,0.29c-0.21,0.82-0.33,1.69-0.19,2.55
					c-1.32,17.93-2.24,41.17,13.42,55.3c11.89,10.73,26.34,17.71,37.6,22.58l0.27,0.12c8.98,3.89,17.64,7.64,26.79,7.64
					c4.87,0,9.88-1.06,15.16-3.72c2.82-1.42,6.6-4.01,8.6-8.24c1.56-3.31,1.6-7,1.64-10.58c0.03-2.65,0.05-5.16,0.66-7.48
					c1.56-5.93,4.72-11.45,7.78-16.79l0.88-1.54c1.74-3.05,3.02-3.14,5.57-3.33c0.89-0.06,1.86-0.14,2.96-0.34
					c4.27,5.9,13.74,9.58,19.89,9.98c0.61,0.04,1.22,0.06,1.82,0.06c7.76,0,14.86-3.11,21.75-6.12c2.4-1.05,4.88-2.13,7.3-3.04
					C1370.28,2286.29,1377.11,2284.65,1383.72,2283.05z M1311.4,2284.45c0.06,0.19,0.15,0.36,0.22,0.55
					c-0.57,0.07-1.11,0.11-1.64,0.15c-2.79,0.2-5.43,0.4-7.96,4.83l-0.88,1.53c-3.15,5.5-6.41,11.2-8.08,17.51
					c-0.7,2.68-0.73,5.49-0.76,8.21c-0.03,3.4-0.07,6.61-1.35,9.32c-1.64,3.47-4.84,5.64-7.24,6.85
					c-13.94,7.04-25.74,1.93-39.41-3.99l-0.27-0.12c-11.05-4.78-25.22-11.61-36.78-22.05c-13.72-12.38-13.76-32.89-12.66-49.68
					c0.02,0.01,0.04,0.03,0.07,0.04c1.62,0.82,3.44,0.91,5.01,0.94c11.86,0.21,19.29-3.89,28.85-11.52
					c7.08-5.65,16.86-9.23,29.89-10.93c9.09-1.19,15.16,1.9,20.89,10.67c4.37,6.69,9.5,9.12,14.43,6.84
					c1.99-0.92,3.54-1.96,4.9-2.88c2.72-1.85,4.69-3.17,8.92-2.91c5.03,0.32,11.72,3.16,14.77,7.99c1.72,2.72,1.98,5.65,0.76,8.71
					c-0.76,1.89-2.82,3.95-5.01,6.13C1314.14,2274.57,1309.66,2279.03,1311.4,2284.45z M1570.85,2020.99
					c-2.75,10.83-6.29,21.57-10.54,31.96c-2.64,6.86-5.64,14.64-7.99,22.28c-0.69,2.17-1.35,4.34-2.03,6.61
					c-4.45,14.94-7.96,26.75-20.46,36.14c-16.27,12.24-27.03,23.85-33.84,36.53c-2.23,4.14-3.99,8.16-5.69,12.05l-0.6,1.36
					c-6.22,14.4-9.98,23.1-26.38,23.1h-3.37c-10.75,0-19.62,4.12-28.19,8.09l-1.75,0.79l-4.14,1.7
					c-21.29,8.59-22.15,9.07-22.96,13.01c-0.41,2.08,0.24,4.24,1.69,5.73c2.6,2.76,6.26,4.35,10.04,4.35
					c5.35-0.02,10.21-3.01,14.47-5.68c2.9-1.81,6.19-3.87,8.08-3.87l0.24-0.01c0.12-0.02,0.24-0.01,0.33,0
					c1.54,0.21,2.84,1.18,3.47,2.57c1.82,4.22-0.87,14.16-6.54,24.16c-3.18,5.59-7.47,12.45-12.31,15.82
					c-3.96,2.54-8.52,3.76-13.23,3.51l-0.11,0c-3.12,0-6.35-0.32-9.77-0.66l-0.58-0.06c-3.88-0.46-7.82-0.73-11.74-0.79
					c-2.39-0.03-4.76,0.15-7.13,0.5c-6.64,1.04-18.79,6.44-18.2,14.73c0.25,3.28,2.25,7.11,9.79,8.21
					c-2.89,0.82-5.77,1.72-8.57,2.77c-2.5,0.94-5.02,2.04-7.45,3.1c-7.09,3.1-14.42,6.3-22.17,5.81
					c-6.55-0.42-16.94-4.9-18.98-11.25c-1.18-3.67,2.45-7.28,5.95-10.78c2.41-2.4,4.69-4.67,5.67-7.14
					c1.58-3.94,1.23-7.89-1.01-11.42c-3.76-5.94-11.5-9.03-17.11-9.38c-5.24-0.32-7.94,1.49-10.79,3.42
					c-1.33,0.9-2.7,1.83-4.47,2.64c-4.39,2.01-8.22-2.02-10.67-5.76c-6.38-9.76-13.5-13.35-23.8-12.01
					c-13.59,1.78-23.86,5.56-31.38,11.56c-9.22,7.36-15.95,11.05-26.93,10.87c-1.3-0.02-2.66-0.09-3.72-0.62
					c-0.64-0.32-1-0.63-1.16-1.05c0.04-0.58,0.09-1.16,0.13-1.73c0.1-0.36,0.23-0.75,0.4-1.23c0.3-0.85,0.61-1.73,0.75-2.66
					c0.5-3.16-0.06-6.61-1.75-10.86c-2.15-5.41-5.87-10.89-11.36-16.75c-3.91-4.17-8.62-7.1-13.17-9.93
					c-3.93-2.44-7.99-4.97-11.42-8.25c-2.05-1.97-6.65-7.18-4.96-13.01c0.49-1.7,0.76-2.2,0.83-2.29c0.25-0.05,1.22,0.21,2.06,0.45
					l0.47,0.13c2.89,0.8,5.57,2.12,8.41,3.5c3.32,1.62,6.75,3.3,10.57,4.12c5.21,1.12,9.61-0.05,11.77-3.13
					c1.97-2.81,1.65-6.73-0.88-10.77c-2.46-3.94-6.24-6.92-10.63-8.42c-1.01-0.34-2.09-0.6-3.13-0.86c-4.15-1-6.5-1.73-6.52-5.03
					c-0.01-1.78,1.02-3.57,2.12-5.47c0.97-1.68,1.97-3.42,2.35-5.34c0.58-2.97,0.91-6,1.23-8.92c0.27-2.48,0.54-5.05,0.98-7.52
					c0.49-2.79,1-5.59,1.51-8.38c1.67-9.09,3.39-18.49,4.21-27.89c0.76-8.77,0.02-17.21-2.2-25.1c-0.09-0.31-0.23-0.75-0.41-1.29
					c0.07-0.06,0.13-0.12,0.2-0.19l-0.39-0.4c-0.84-2.6-2.75-8.77-1.55-10.52c0.1-0.14,0.24-0.29,0.68-0.32
					c0.82-0.07,2.11,1.38,3.15,2.51c1.58,1.72,3.22,3.5,5.35,3.5c1.16,0,2.25-0.54,3.23-1.61c1.89-2.06,2.45-5.2,2.99-8.24
					c0.29-1.61,0.56-3.14,1-4.27c1.03-2.64,2.57-4.41,4.55-5.25c0.49-0.21,1.39-0.23,2.25-0.25c1.85-0.04,4.15-0.09,5.52-1.97
					c1.26-1.75,0.6-3.96,0.06-5.73c-0.26-0.88-0.54-1.78-0.49-2.32c0.24-2.68,1.59-5.12,4-7.24c2.91-2.56,5.35-5.49,7.71-8.33
					c0.57-0.69,1.15-1.38,1.73-2.06c4.97-5.86,9.57-12.28,9.57-19.52c0-10-9.12-17.28-16.44-23.13c-0.92-0.74-1.82-1.45-2.65-2.14
					c-6.09-5.02-7.4-9.95-7.77-17.87c-0.02-0.53,0.16-0.99,0.58-1.45c3.31-3.56,17.23-3.94,23.17-4.1c1.67-0.04,2.98-0.08,3.78-0.16
					c9.77-0.9,19.85-1.2,29.95-0.88c8.37,0.26,17.12-0.34,26.02-1.79c3.49-0.57,6.07-2.16,7.25-4.49c1.03-2.03,0.85-4.25,0.69-6.21
					c-0.12-1.45-0.23-2.82,0.11-4.01c1.21-4.31,7.54-5.53,13.67-6.72c4.91-0.95,9.55-1.85,12.43-4.29c4.74-4.01,6.5-11.03,7.3-16.89
					c1.31,0.86,2.55,1.68,3.71,2.46c1.33,0.93,2.61,1.95,3.81,3.03l0.08,0.07c3.52,3.01,7.52,6.43,12.93,6.43l0.1,0
					c4.51-0.23,8.66-2.42,11.39-6.03c5.94-6.75,7.6-14.82,9.06-21.96c1.3-6.02,2.53-11.71,6.03-15.71
					c6.53-7.55,20.07-9.26,29.69-9.88c5.74-0.36,11.39-0.54,16.8-0.54c48.56,0,90.37,12.93,114.7,35.47
					c18.9,17.62,31.78,40.28,37.26,65.54C1573.61,1988.4,1574.1,2006.6,1570.85,2020.99z"/>
				<path className="st5" d="M478.03,1551.2c3.23-0.19,6.44-0.73,9.47-1.59l1.59-0.38c2.02-0.44,4.14-0.84,6.18-1.22l1.53-0.29
					c6.91-0.96,13.69-2.77,20.25-5.39c7.42-3.37,14.22-7.87,20.22-13.38l1.22-1.12c2.65-2.2,5.06-4.7,7.14-7.44l0.07-0.1
					c3.97-5.96,6.14-15.05,5.81-24.31c0.08-3.11-0.88-6.2-2.71-8.71l-0.22-0.25c-1.22-1.2-2.61-2.21-4.08-3
					c-1.54-0.87-2.99-1.91-4.28-3.05c-3.9-3.63-3.63-7.5-3.33-11.31c0.19-1.1,0.19-2.28,0.19-3.23v-10.44l-2.83,1.3
					c-1.84,0.84-3.62,1.82-5.26,2.88l-1.42,0.85c-1.5,0.83-3.3,1.62-5.35,2.5l-0.24,0.1c-6.14,2.72-10.94,5.06-12.15,8.48
					l-0.04,0.13c-0.31,1.13-0.5,2.29-0.55,3.46c-0.09,1.81-0.47,3.57-1.14,5.29c-1.47,3.96-3.54,7.65-6.16,10.97
					c-3.72,3.94-8.04,7.23-12.85,9.79c-4.55,2.52-6.86,7.87-7.27,16.95l0,3.99c0.07,2.3-0.02,4.63-0.26,6.91
					c-0.56,3.1-1.72,6-3.45,8.64l-0.08,0.13c-0.39,0.68-0.8,1.38-1.19,2.15c-1.25,2.64-1.98,5.45-2.14,8.37l-0.11,1.96l0.24,0.02
					l-0.27,0.55L478.03,1551.2z M479.78,1541.36c0.35-0.69,0.74-1.37,1.13-2.04c1.88-2.86,3.16-6.08,3.76-9.45
					c0.26-2.41,0.36-4.83,0.28-7.25c0-1.32,0-2.63,0-3.93c0.37-8.23,2.3-13.03,6.23-15.2c4.99-2.66,9.52-6.11,13.4-10.23
					c2.76-3.5,4.96-7.4,6.52-11.58c0.74-1.88,1.16-3.87,1.26-5.89c0.04-1.02,0.21-2.03,0.48-3.02c1.04-2.93,6.99-5.52,11.32-7.43
					c2.02-0.87,3.91-1.69,5.52-2.58l1.48-0.89c1.62-1.05,3.31-1.98,5.06-2.78c0,1.52,0,3,0,4.56c0,0.91,0,1.82,0,2.76
					s0,2.02-0.17,2.98c-0.3,3.84-0.72,8.69,3.95,13.03c1.43,1.27,2.99,2.38,4.65,3.32c1.34,0.72,2.58,1.63,3.67,2.69
					c1.59,2.18,2.41,4.84,2.32,7.54c0.33,8.86-1.72,17.55-5.47,23.2c-2.01,2.64-4.31,5.04-6.86,7.15l-1.26,1.15
					c-5.84,5.35-12.49,9.75-19.7,13.03c-6.33,2.53-12.95,4.29-19.7,5.23c-2.63,0.5-5.28,0.98-7.86,1.54l-1.63,0.39
					c-2.97,0.85-6.03,1.36-9.12,1.54l0.11-0.22c-0.43,0.03-0.87,0.03-1.3,0C477.98,1546.34,478.64,1543.76,479.78,1541.36z"/>
				<path className="st5" d="M479.02,1549.21c3.09-0.18,6.15-0.7,9.12-1.54l1.63-0.39c2.58-0.56,5.23-1.04,7.86-1.54
					c6.75-0.94,13.37-2.7,19.7-5.23c7.21-3.28,13.86-7.68,19.7-13.03l1.26-1.15c2.55-2.11,4.86-4.51,6.86-7.15
					c3.76-5.65,5.8-14.33,5.47-23.2c0.09-2.7-0.73-5.35-2.32-7.54c-1.09-1.07-2.33-1.97-3.67-2.69c-1.66-0.94-3.22-2.06-4.65-3.32
					c-4.67-4.34-4.26-9.19-3.95-13.03c0.17-0.96,0.17-2.04,0.17-2.98s0-1.85,0-2.76c0-1.56,0-3.04,0-4.56
					c-1.75,0.8-3.44,1.73-5.06,2.78l-1.48,0.89c-1.61,0.89-3.5,1.72-5.52,2.58c-4.32,1.91-10.27,4.5-11.32,7.43
					c-0.27,0.98-0.43,2-0.48,3.02c-0.1,2.02-0.52,4-1.26,5.89c-1.55,4.18-3.75,8.08-6.52,11.58c-3.88,4.12-8.41,7.57-13.4,10.23
					c-3.93,2.17-5.86,6.97-6.23,15.2c0,1.3,0,2.61,0,3.93c0.08,2.42-0.02,4.85-0.28,7.25c-0.6,3.37-1.88,6.59-3.76,9.45
					c-0.39,0.67-0.78,1.35-1.13,2.04c-1.14,2.39-1.8,4.98-1.96,7.62c0.43,0.03,0.87,0.03,1.3,0L479.02,1549.21z M478.98,1546.91
					c0.29-1.61,0.79-3.18,1.49-4.65c0.31-0.61,0.66-1.22,1.04-1.89c2.02-3.09,3.38-6.51,4.02-10.15l0.02-0.13
					c0.27-2.49,0.37-5.02,0.29-7.47l0-3.84c0.33-7.46,1.99-11.77,5.17-13.53c5.2-2.76,9.88-6.34,13.92-10.62l0.11-0.13
					c2.9-3.67,5.19-7.74,6.81-12.09c0.82-2.08,1.29-4.28,1.4-6.53c0.04-0.84,0.17-1.68,0.39-2.49c0.82-2.06,6.77-4.69,9.98-6.11
					l0.22-0.1c2.13-0.92,4.04-1.75,5.76-2.71l1.53-0.92c0.64-0.42,1.3-0.81,1.97-1.19v4.05c0,0.86,0,1.84-0.14,2.62l-0.03,0.26
					c-0.32,4.06-0.76,9.61,4.63,14.62c1.53,1.35,3.21,2.55,5.03,3.59c1.13,0.61,2.17,1.36,3.1,2.24c1.26,1.79,1.91,3.97,1.84,6.16
					l0,0.07l0,0.07c0.31,8.45-1.6,16.65-5.1,21.96c-1.9,2.49-4.09,4.77-6.51,6.76l-1.34,1.22c-5.69,5.22-12.14,9.49-19.09,12.65
					c-6.17,2.47-12.64,4.19-19.33,5.13l-1.62,0.31c-2.07,0.39-4.22,0.79-6.34,1.26l-1.71,0.41c-1.69,0.48-3.42,0.85-5.16,1.11
					l0.06-0.12L478.98,1546.91z"/>
				<path className="st5" d="M312.89,948.22c-0.17-1.35-0.61-4.93-3.64-4.93c-4.36,0-12.27,5.24-21.68,14.35
					c-5.86,5.5-11.25,11.56-16.05,18.01l-0.18,0.3c-1.94,3.9-3.46,8.02-4.47,12.16c-2.93,9.91-5.7,19.26-11.25,21.11
					c-1.2,0.42-2.45,0.65-3.68,0.67c-4.17,0-6.47-2.66-8.51-5l-0.17-0.2c-1.46-1.72-2.71-3.21-4.51-3.66l-0.21-0.04
					c-0.34-0.05-0.69-0.05-1.03-0.02c-3.46,0.12-6.41,4.52-9.62,9.81l-0.12,0.19c-0.68,1.14-1.46,2.43-2.11,3.38l-0.06,0.1
					c-2.21,3.69-6.08,5.97-10.37,6.1c-3.14-0.19-6.18-0.94-9.1-2.26c-2.06-0.86-4.22-1.54-6.41-2.02c-0.52-0.11-1.06-0.17-1.61-0.17
					c-8.7,0-13.14,15.69-15.28,23.23l-0.38,1.34c-0.47,1.7-0.99,3.42-1.49,5.07c-1.91,5.68-3.28,11.57-4.07,17.51
					c-0.5,2.36,0.06,4.79,1.55,6.69l0.07,0.08c3.21,3.68,11.12,3.96,13.47,3.96l0.61,0c1.58-0.03,3.17-0.15,4.74-0.34
					c1.8-0.22,3.62-0.35,5.42-0.38c1.32-0.01,2.63,0.12,4,0.41c2.24,0.37,4.27,1.65,5.52,3.46c2.03,3.23,1.07,7.41,0.24,11.03
					c-0.42,1.54-0.69,3.12-0.82,4.7l-0.01,0.13c-0.07,3.9,0.71,5.32,1.31,6.06c0.76,0.96,1.91,0.97,2.47,0.97h0.02
					c4.42-0.01,11.16-4.24,17.67-8.56l0.5-0.33c0.87-0.58,1.67-1.1,2.39-1.55c6.66-4.13,11.59-10.54,13.89-18.03l0.04-0.13
					c0.79-3.35,1.06-6.79,0.81-10.22l0-0.59c-0.01-5.04-0.01-10.25,2.79-14.59c2.24-3.11,5.32-5.45,8.94-6.77
					c2.46-0.92,4.74-2.4,6.59-4.27l0.14-0.16c2.92-3.68,3.94-8.29,4.93-12.78c0.49-2.4,1.12-4.79,1.85-7.08
					c4.41-13.05,12.19-24.5,19.71-35.57l0.11-0.16c1.36-1.97,3.12-4.23,4.82-6.42C306.52,965.3,313.84,955.93,312.89,948.22z
					 M299.16,971.6c-1.8,2.32-3.52,4.54-4.89,6.52c-7.65,11.25-15.55,22.85-20.07,36.23c-0.76,2.4-1.4,4.85-1.91,7.32
					c-1,4.52-1.95,8.69-4.54,11.94c-1.66,1.68-3.66,2.97-5.86,3.8c-3.96,1.44-7.4,4.05-9.86,7.47c-3.32,5.13-3.17,11.16-3.17,16.42
					c0.24,3.25-0.02,6.52-0.76,9.69c-2.16,7.03-6.78,13.04-13.03,16.92c-0.87,0.54-1.85,1.19-2.93,1.91
					c-4.65,3.08-12.42,8.23-16.59,8.23c-0.32,0-0.75,0-0.92-0.22c-0.17-0.22-0.93-1.06-0.87-4.78c0.12-1.47,0.38-2.92,0.76-4.34
					c0.89-3.87,2-8.69-0.48-12.62c-1.61-2.33-4.09-3.91-6.88-4.37c-1.43-0.3-2.88-0.45-4.34-0.43c-1.89,0.03-3.77,0.16-5.65,0.39
					c-1.51,0.18-3.02,0.29-4.54,0.33h-0.56c-2.32,0-9.4-0.35-11.97-3.28c-1.13-1.44-1.56-3.32-1.15-5.1
					c0.78-5.86,2.12-11.62,4-17.22c0.52-1.72,1.04-3.45,1.52-5.17l0.3-1.09c1.85-6.52,6.15-22.02,13.42-22.02
					c0.4,0,0.8,0.05,1.19,0.13c2.07,0.46,4.1,1.1,6.06,1.91c3.11,1.4,6.44,2.21,9.84,2.41c4.99-0.13,9.56-2.8,12.12-7.08
					c0.65-0.96,1.39-2.17,2.17-3.48c2.04-3.37,5.43-9.04,8.17-9.04c0.2-0.03,0.41-0.03,0.61,0c1.11,0.28,2.04,1.32,3.48,3.02
					c2.17,2.5,4.97,5.91,10.21,5.91c1.48-0.03,2.95-0.29,4.34-0.78c6.52-2.17,9.43-12.01,12.51-22.44
					c1.01-4.09,2.46-8.06,4.34-11.84c4.73-6.37,10.02-12.31,15.81-17.74c10.49-10.16,17.37-13.81,20.31-13.81
					c0.41,0,1.26,0,1.65,3.17C311.85,955.33,304.81,964.37,299.16,971.6z"/>
				<path className="st5" d="M309.35,945.3c-2.93,0-9.82,3.65-20.31,13.81c-5.79,5.43-11.08,11.37-15.81,17.74
					c-1.88,3.77-3.34,7.74-4.34,11.84c-3.08,10.42-5.99,20.26-12.51,22.44c-1.4,0.49-2.86,0.75-4.34,0.78
					c-5.23,0-8.04-3.41-10.21-5.91c-1.43-1.69-2.37-2.74-3.48-3.02c-0.2-0.03-0.41-0.03-0.61,0c-2.74,0-6.12,5.67-8.17,9.04
					c-0.78,1.3-1.52,2.52-2.17,3.48c-2.56,4.28-7.13,6.95-12.12,7.08c-3.4-0.2-6.73-1.02-9.84-2.41c-1.96-0.81-3.99-1.45-6.06-1.91
					c-0.39-0.08-0.79-0.13-1.19-0.13c-7.28,0-11.58,15.51-13.42,22.02l-0.3,1.09c-0.48,1.72-1,3.45-1.52,5.17
					c-1.88,5.6-3.22,11.37-4,17.22c-0.41,1.79,0.02,3.66,1.15,5.1c2.56,2.93,9.64,3.28,11.97,3.28h0.56
					c1.52-0.03,3.03-0.14,4.54-0.33c1.87-0.23,3.76-0.36,5.65-0.39c1.46-0.01,2.92,0.13,4.34,0.43c2.79,0.46,5.28,2.03,6.88,4.37
					c2.48,3.93,1.37,8.75,0.48,12.62c-0.39,1.42-0.64,2.88-0.76,4.34c-0.07,3.71,0.69,4.56,0.87,4.78c0.17,0.21,0.59,0.22,0.92,0.22
					c4.17,0,11.94-5.15,16.59-8.23c1.09-0.72,2.06-1.37,2.93-1.91c6.25-3.88,10.88-9.89,13.03-16.92c0.75-3.17,1-6.44,0.76-9.69
					c0-5.26-0.15-11.29,3.17-16.42c2.46-3.42,5.9-6.03,9.86-7.47c2.21-0.83,4.21-2.12,5.86-3.8c2.58-3.26,3.54-7.43,4.54-11.94
					c0.51-2.47,1.15-4.91,1.91-7.32c4.52-13.38,12.42-24.98,20.07-36.23c1.37-1.98,3.08-4.19,4.89-6.52
					c5.65-7.23,12.68-16.27,11.84-23.13C310.61,945.3,309.76,945.3,309.35,945.3z M297.49,970.37c-1.73,2.24-3.53,4.55-4.96,6.62
					l-0.12,0.17c-7.67,11.29-15.61,22.97-20.2,36.57c-0.78,2.46-1.44,4.99-1.96,7.49c-0.92,4.17-1.79,8.1-4.09,11.05
					c-1.43,1.42-3.18,2.55-5.05,3.25c-4.36,1.59-8.09,4.42-10.85,8.26c-3.51,5.41-3.5,11.49-3.49,16.85l0,0.66l0.01,0.15
					c0.22,3.03-0.01,6.06-0.7,9.02c-2.02,6.54-6.34,12.13-12.16,15.75c-0.75,0.47-1.57,1.01-2.48,1.61l-0.5,0.33
					c-3.71,2.46-11.28,7.49-15.05,7.87c-0.12-0.48-0.24-1.37-0.22-2.87c0.11-1.32,0.34-2.64,0.71-3.99
					c0.92-4.01,2.19-9.49-0.78-14.2c-1.9-2.76-4.9-4.66-8.12-5.19c-1.57-0.33-3.18-0.49-4.79-0.48c-1.95,0.03-3.92,0.17-5.86,0.41
					c-1.43,0.18-2.89,0.28-4.3,0.31h-0.56c-3.92,0-8.83-0.8-10.43-2.56c-0.74-0.96-1.01-2.19-0.74-3.38l0.03-0.18
					c0.76-5.72,2.08-11.39,3.93-16.9c0.53-1.73,1.05-3.48,1.53-5.21l0.37-1.33c1.42-5.03,5.75-20.32,11.41-20.32
					c0.27,0,0.53,0.03,0.78,0.08c1.95,0.43,3.88,1.04,5.67,1.78c3.34,1.5,6.89,2.37,10.54,2.58l0.08,0l0.08,0
					c5.68-0.15,10.82-3.14,13.75-8c0.7-1.03,1.47-2.31,2.2-3.52l0.11-0.18c4.15-6.85,5.76-7.88,6.35-7.89l0.25-0.01
					c0.58,0.28,1.53,1.4,2.32,2.34l0.19,0.22c2.2,2.54,5.53,6.38,11.57,6.38c1.7-0.03,3.37-0.33,4.94-0.88
					c7.5-2.5,10.56-12.83,13.82-23.86c0.96-3.89,2.34-7.68,4.11-11.26c4.62-6.21,9.83-12.04,15.49-17.36
					c9.85-9.55,15.86-12.7,18.33-13.18c0.08,0.28,0.17,0.71,0.25,1.35C309.67,954.77,302.63,963.79,297.49,970.37z"/>
				<path className="st5" d="M386.86,1140.58c3.42,3.59,8.04,5.66,13,5.83l0.06,0l0.06,0c6.04-0.16,11.5-3.6,14.26-8.97
					c3.12-5.39,1.92-8.93-0.68-15.23c-0.49-1.14-0.98-2.38-1.44-3.62c-1.91-5.81-3.26-11.83-4.02-17.89l-0.02-0.16
					c-1.91-11.79-3.71-22.93-11.82-29.91l-0.09-0.08c-2.08-1.58-4.68-2.38-7.24-2.25c-6.31,0.18-12.33,3-16.5,7.74l-0.09,0.11
					c-3.88,5.08-3.59,13.18-2.79,20.44c1.6,14.25,6.87,33.17,16.88,43.56L386.86,1140.58z M374.03,1077.37
					c3.82-4.34,9.27-6.9,15.05-7.06c2.15-0.11,4.26,0.54,5.97,1.85c7.58,6.52,9.32,17.38,11.16,28.8c0.78,6.21,2.16,12.32,4.1,18.27
					c0.49,1.33,0.99,2.59,1.5,3.78c2.48,6.02,3.45,8.86,0.76,13.49c-2.42,4.74-7.23,7.78-12.55,7.93
					c-4.41-0.15-8.58-2.02-11.62-5.21l-0.46-0.46c-9.64-10.01-14.75-28.45-16.31-42.37
					C370.92,1089.86,370.53,1081.95,374.03,1077.37z"/>
				<path className="st5" d="M1097.26,2494.67c-0.47-1.51-1.39-2.82-2.66-3.77c-8.35-7.11-19.53-8.63-25.55-8.91h-0.96
					c-3.94,0.06-7.8,1.44-10.88,3.9c-2.82,1.99-5.92,3.52-9.19,4.52c-0.83,0.24-2.07,0.49-3.65,0.82l-0.48,0.1
					c-8.73,1.76-17.96,4.07-20.35,7.84c-0.42,0.59-0.58,1.34-0.46,2.06l0.11,0.64l0.46,0.45c1.36,1.33,4.48,1.99,9.43,2.67
					l0.49,0.07c1.86,0.16,3.7,0.49,5.49,1c5.95,2.09,10.13,6.47,13.83,10.35c4.66,4.79,9.06,9.32,15.86,9.83l4.73,0.01l-0.15-0.39
					c4.09-0.74,5.48-3.41,7.88-8.67l0.52-1.1c0.27-0.58,0.55-1.18,0.85-1.79c1.89-3.19,4.3-6.01,7.16-8.38l0.1-0.08
					c4.59-4.33,8.05-7.89,7.48-10.93L1097.26,2494.67z M1089.57,2504.37c-3.05,2.53-5.63,5.56-7.64,8.97
					c-0.5,1.02-0.96,2.02-1.41,2.98c-2.8,6.15-3.74,7.75-8.88,7.75l0.07,0.17H1070c-6.04-0.46-9.99-4.52-14.57-9.23
					c-4.15-4.34-8.43-8.69-14.66-10.86c-1.94-0.55-3.92-0.92-5.93-1.09c-2.17-0.3-7.47-0.98-8.69-2.17
					c-0.04-0.21,0.02-0.44,0.15-0.61c2.17-3.61,14.55-6.08,19.18-7.02c1.8-0.37,3.21-0.65,4.19-0.93c3.52-1.08,6.84-2.72,9.84-4.84
					c2.76-2.21,6.17-3.44,9.71-3.5h0.93c7.02,0.33,16.94,2.17,24.3,8.47c0.95,0.7,1.65,1.68,2,2.8
					C1096.87,2497.44,1092.65,2501.46,1089.57,2504.37z"/>
				<g className="st4" id="southEast" onClick={() => handleClick("south east")}>
                    <title>South East</title>
					<path className="st4" d="M1356.61,2291.72c-6.88,3.01-13.98,6.12-21.75,6.12c-0.6,0-1.21-0.02-1.82-0.06
						c-6.15-0.39-15.62-4.07-19.89-9.98c-1.1,0.2-2.07,0.28-2.96,0.34c-2.55,0.19-3.83,0.28-5.57,3.33l-0.88,1.54
						c-3.06,5.33-6.22,10.85-7.78,16.79c-0.61,2.32-0.63,4.83-0.66,7.48c-0.04,3.57-0.07,7.26-1.64,10.58
						c-2,4.24-5.78,6.82-8.6,8.24c-5.28,2.66-10.29,3.72-15.16,3.72c-9.16,0-17.81-3.75-26.79-7.64l-0.27-0.12
						c-11.26-4.88-25.71-11.85-37.6-22.58c-15.66-14.13-14.74-37.37-13.42-55.3c-0.14-0.86-0.02-1.73,0.19-2.55
						c0.01-0.09,0.01-0.19,0.02-0.29l0.05,0c0.14-0.5,0.31-0.98,0.46-1.42c0.27-0.76,0.52-1.47,0.62-2.12
						c0.41-2.61-0.09-5.55-1.57-9.28c-2.01-5.05-5.53-10.23-10.76-15.81c-3.64-3.88-7.97-6.58-12.56-9.43
						c-4.06-2.52-8.25-5.13-11.91-8.63c-5.18-4.96-7.28-10.79-5.77-16.01c1.35-4.65,2.62-5.01,6.59-3.89l0.46,0.13
						c3.16,0.88,6.09,2.31,8.93,3.7c3.3,1.61,6.42,3.14,9.89,3.88c3.93,0.85,7.26,0.11,8.68-1.92c1.26-1.8,0.92-4.45-0.97-7.46
						c-2.07-3.3-5.37-5.92-9.05-7.17c-0.88-0.3-1.85-0.53-2.87-0.78c-3.92-0.95-8.79-2.12-8.81-7.93c-0.01-2.59,1.28-4.82,2.52-6.98
						c0.89-1.54,1.72-2.99,2-4.42c0.56-2.84,0.88-5.8,1.19-8.67c0.27-2.53,0.56-5.14,1-7.72c0.49-2.8,1-5.6,1.52-8.41
						c1.66-9.02,3.37-18.35,4.17-27.61c0.72-8.34,0.03-16.36-2.05-23.85c-2.06,1.87-4.23,3.73-6.5,5.66
						c-6.64,5.66-13.5,11.51-17.71,18.18c-1.55,2.46-2.62,5.16-3.74,8.01c-0.56,1.41-1.13,2.86-1.76,4.27
						c-1.91,4.24-5.04,9.54-9.13,10.69c-1.76,0.5-3.56,0.16-5.19-0.96c-0.96-0.66-1.86-1.38-2.72-2.08
						c-2.73-2.19-5.09-4.09-8.64-3.49c-2.19,0.38-4.44,1.43-6.86,3.21c-4.73,3.48-8.32,8.36-11.78,13.07l-0.61,0.83
						c-0.56,0.76-1.14,1.62-1.75,2.53c-2.59,3.86-5.82,8.66-10.52,9.81c-0.85,0.21-1.67,0.31-2.46,0.31
						c-8.77,0-13.27-12.25-15.05-17.11c-3.33-9.06-5.47-19.2,3.79-25.75c1.23-0.87,1.22-1.55,1.21-1.78
						c-0.04-2.41-5.12-5.72-7.03-6.96l-0.47-0.31c-3.72-2.45-5.66-4.91-5.94-7.52c-0.02-0.17-0.02-0.33-0.03-0.5l-12.32,2.97
						c-8.79,2.12-10.59,5.28-12.87,9.27c-1.07,1.88-2.29,4.01-4.35,6.09c-2.98,3.01-3.17,4.96-3.46,7.92
						c-0.25,2.54-0.55,5.69-2.66,10.29c-4.61,10.06-17.16,17.91-22.54,20.59c-0.58,6.24-2.35,12.13-4.19,18.23
						c-0.63,2.1-1.29,4.27-1.89,6.46l-0.25,0.91c-1.46,5.28-2.97,10.75-3.84,16.14c-0.69,4.25-0.12,8.3,0.48,12.59
						c0.16,1.17,0.33,2.35,0.47,3.55c0.96,8.05,2.05,17.17,2.73,26.16c0.22,2.93,0.35,5.56-0.31,8.22l-0.35,1.39
						c-2.07,8.26-3.32,13.25,1.21,20.98c2.52,4.3,5.91,8.17,9.19,11.9c2.42,2.76,4.92,5.61,7.11,8.67
						c12.76,17.83,13.04,44.25,0.63,60.15c-3.66,4.69-8.58,7.29-13.33,9.8c-0.89,0.47-1.79,0.95-2.69,1.44
						c-5.21,2.85-6.89,6.44-5.47,11.64c0.62,2.25,1.2,4.56,1.79,6.89c2.36,9.31,4.79,18.93,9.68,26.85l0.26,0.43
						c4.17,6.75,10.46,16.95,4.19,24.92c-2.48,3.15-6.48,5.38-11.25,6.28c-10.12,1.89-21.49-0.79-30.42-7.18l-0.27-0.19
						c-6.77-4.86-11.11-6.83-12.86-5.86c-1.02,0.56-1.07,2.62-1,3.8c0.28,5.13,3.8,10.91,6.63,15.55c0.37,0.61,0.73,1.2,1.08,1.77
						c4.98,8.31,7.68,17.55,10.28,26.49c1.04,3.58,2.13,7.26,3.34,10.85h7.76c1.5,0.02,2.98,0.07,4.47,0.13
						c1.83,0.06,3.72,0.13,5.58,0.13c3.54,0.12,7.86,0.26,11.93,0.26c11.24,0,24.17-1,34.82-8.42c1.81-1.36,3.29-6.21,4.37-9.75
						c2.22-7.12,5.25-16.84,15.1-17.77c0.5-0.03,0.94-0.03,1.37-0.01c7.5,0.03,13.25,5.79,18.33,10.87l0.13,0.14
						c1.92,2.16,4.12,4.06,6.55,5.64c15.77,7.86,39.6,12.05,56.25,12.71l2.54,0c11.71,0,23.65-3.14,35.19-6.18l0.72-0.19
						c7.33-1.92,14.91-3.91,22.54-5.12c11.17-1.83,22.59-2.78,33.92-2.81c14.92-0.07,29.8,1.82,44.23,5.6
						c3.37,0.9,6.85,2.1,10.53,3.38l1.72,0.6c8.71,3.06,17.68,6.22,25.48,6.22c2.35,0.04,4.61-0.31,6.8-1.04
						c7.3-2.57,13.58-7.16,20.24-12.01l4.07-2.97c8.01-5.82,16.98-8.06,25.65-10.23l1.39-0.36c2.53-0.65,5.15-1.32,7.63-2.09
						c5.77-2.02,11.35-4.76,16.54-8.13c3.46-2.08,6.81-4.06,10.38-5.82c4.61-2.27,9.76-3.43,14.89-3.32h0.39
						c1.63-0.01,3.3,0,5.1,0.21c1.72,0.2,3.54,0.2,5.47,0.2h0.74c3.83-0.04,4.25-0.05,4.96-8.06c0.13-4.78,1.23-9.39,3.28-13.71
						l0.05-0.11c5.11-9.28,14.4-13.77,22.81-16.94c4.15-1.56,8.71-2.79,12.74-3.88c11.61-3.15,23.6-6.41,28.36-15.53
						c3.97-7.63,2.6-16.44,1.14-25.78c-0.8-4.54-1.33-9.22-1.58-13.87c-0.17-4.95,2.02-9.55,3.95-13.62c1.13-2.32,1.71-3.67,2-4.46
						c-0.53-0.14-1.31-0.29-2.42-0.41h-1.99c-5.55,0.38-10.97,1.67-16.11,3.82c-4.55,1.74-9.21,3.07-13.92,3.99l-1.56,0.27
						c-15.32,2.8-28.5,4.35-37.08,4.35c-8.26,0-13.15-1.4-15.88-4.53c-1.68-1.92-2.42-4.48-2.02-7c0.57-3.98,3.1-6.5,5.46-8.2
						c-3.62-2.28-7.71-3.57-11.95-3.78c-0.03,0-0.06,0-0.09,0c-2.45,0-4.9,0.73-7.01,2.12c-1.75,1.26-3.49,2.72-5.11,4.28l-0.1,0.09
						c-5.38,4.88-11.48,10.42-19,10.42c-4.77-0.11-9.14-2.3-12.05-6.02c-2.27-2.44-3.34-5.73-2.93-9.05
						c0.92-6.22,6.83-9.97,12.04-13.29l0.88-0.58c1.16-0.77,3.71-2.46,4.62-3.35c-0.21,0-0.43,0-0.65,0.01l-0.1,0
						c-0.71,0-1.45,0.03-2.21,0.08c-0.43,0.1-0.86,0.21-1.29,0.31c-6.61,1.59-13.44,3.24-19.8,5.62
						C1361.49,2289.59,1359.01,2290.67,1356.61,2291.72z"/>
					<path className="st5" d="M1094.46,2492.47c-7.36-6.3-17.29-8.14-24.3-8.47h-0.93c-3.53,0.06-6.95,1.29-9.71,3.5
						c-3,2.13-6.32,3.76-9.84,4.84c-0.98,0.28-2.39,0.56-4.19,0.93c-4.63,0.93-17.01,3.41-19.18,7.02
						c-0.13,0.17-0.19,0.39-0.15,0.61c1.22,1.19,6.52,1.87,8.69,2.17c2.01,0.17,3.99,0.53,5.93,1.09
						c6.23,2.17,10.51,6.52,14.66,10.86c4.58,4.71,8.54,8.77,14.57,9.23h1.69l-0.07-0.17c5.15,0,6.08-1.61,8.88-7.75
						c0.46-0.96,0.91-1.95,1.41-2.98c2.01-3.41,4.6-6.44,7.64-8.97c3.08-2.91,7.3-6.93,6.89-9.1
						C1096.1,2494.15,1095.4,2493.17,1094.46,2492.47z M1087.15,2502.87c-3.21,2.67-5.92,5.85-8.04,9.45l-0.07,0.14
						c-0.32,0.65-0.62,1.29-0.91,1.92l-0.52,1.11c-2.8,6.15-3.27,6.58-7.06,6.58h-2.84c-4.56-0.87-7.85-4.26-11.92-8.44
						c-3.81-4-8.56-8.97-15.45-11.37l-0.11-0.03c-2.04-0.58-4.14-0.97-6.26-1.15l-0.33-0.05c-2.53-0.35-4.2-0.66-5.3-0.92
						c2-1.19,6.47-2.87,16.35-4.86l0.49-0.1c1.66-0.34,2.97-0.61,3.99-0.9c3.73-1.15,7.23-2.87,10.41-5.12l0.09-0.07
						c2.4-1.92,5.42-3.01,8.46-3.06l0.84,0c5.43,0.25,15.65,1.62,23.1,7.99l0.11,0.09c0.55,0.41,0.96,0.95,1.21,1.58
						C1093.16,2497.19,1088.7,2501.4,1087.15,2502.87z"/>
					<g className="st4" >
                    	<title>London</title>
						<path d="M1318.09,2270.63c2.19-2.18,4.25-4.23,5.01-6.13c1.22-3.05,0.96-5.98-0.76-8.71
							c-3.05-4.83-9.74-7.67-14.77-7.99c-4.23-0.26-6.2,1.07-8.92,2.91c-1.36,0.92-2.91,1.97-4.9,2.88
							c-4.94,2.27-10.06-0.16-14.43-6.84c-5.74-8.77-11.8-11.87-20.89-10.67c-13.04,1.71-22.82,5.29-29.89,10.93
							c-9.57,7.63-16.99,11.74-28.85,11.52c-1.57-0.03-3.39-0.12-5.01-0.94c-0.02-0.01-0.04-0.03-0.07-0.04
							c-1.1,16.79-1.06,37.3,12.66,49.68c11.57,10.44,25.73,17.27,36.78,22.05l0.27,0.12c13.66,5.92,25.46,11.03,39.41,3.99
							c2.4-1.21,5.6-3.38,7.24-6.85c1.28-2.72,1.32-5.93,1.35-9.32c0.03-2.72,0.06-5.53,0.76-8.21c1.66-6.32,4.92-12.01,8.08-17.51
							l0.88-1.53c2.53-4.44,5.17-4.63,7.96-4.83c0.53-0.04,1.08-0.08,1.64-0.15c-0.07-0.18-0.16-0.36-0.22-0.55
							C1309.66,2279.03,1314.14,2274.57,1318.09,2270.63z"/>
					</g>
				</g>
			</g>
		</g>
		<g id="Landmarks">
			<g>
				<g>
					<path className="st2" d="M653.68,2205.94c1.91-1.73,1.78-1.26,0.86-0.8c-0.45,0.23-0.92,0.44-1.39,0.65
						c-1.45,0.65-2.92,1.27-4.39,1.9c0.6-0.25,1.19-0.5,1.79-0.76c-8.01,3.38-16.11,6.55-24.04,10.09
						c-1.42,0.63-2.57,1.21-3.75,2.28c-2.42,2.19-2.82,6.35-1.17,9.09c2.84,4.74,7.87,6.67,13.09,7.42c5.31,0.76,10.63,1.23,16,1.3
						c9.66,0.12,19.3-0.78,28.91-1.64c9.64-0.86,19.3-1.68,28.99-1.4c9.65,0.28,19.23,1.64,28.84,2.53
						c11.3,1.05,22.65,1.26,33.81-1.18c4.09-0.89,6.09-5.45,5.24-9.23c-2-8.89-10.69-15.49-19.73-15.39
						c-3.92,0.04-7.68,3.42-7.5,7.5c0.18,4.03,3.3,7.55,7.5,7.5c0.59-0.01,1.17,0.02,1.75,0.09c-0.66-0.09-1.33-0.18-1.99-0.27
						c1.05,0.15,2.05,0.42,3.04,0.81c-0.6-0.25-1.19-0.5-1.79-0.76c0.76,0.32,1.49,0.69,2.18,1.14c0.63,0.41,0.98,1.05-0.38-0.34
						c0.28,0.29,0.63,0.53,0.92,0.82c0.29,0.29,0.56,0.6,0.84,0.9c0.85,0.92-1.01-1.54-0.32-0.4c0.43,0.71,0.83,1.39,1.16,2.16
						c-0.25-0.6-0.5-1.19-0.76-1.79c0.26,0.66,0.47,1.32,0.62,2.01c1.75-3.08,3.49-6.15,5.24-9.23c-1.85,0.4-3.72,0.73-5.59,0.99
						c0.66-0.09,1.33-0.18,1.99-0.27c-16.39,2.15-32.77-1.2-49.1-2.31c-16.51-1.13-32.86,1.2-49.3,2.29
						c-9.28,0.62-18.61,0.76-27.84-0.47c0.66,0.09,1.33,0.18,1.99,0.27c-2.03-0.28-4.07-0.57-5.98-1.33c0.6,0.25,1.19,0.5,1.79,0.76
						c-0.32-0.14-0.63-0.28-0.93-0.45c-0.29-0.16-1.15-0.8-0.09,0.03c1.09,0.85,0.21,0.11,0.01-0.07c-0.9-0.8,1.04,1.55,0.37,0.43
						c-0.39,3.03-0.78,6.06-1.17,9.09c-1.91,1.73-1.78,1.26-0.86,0.8c0.45-0.23,0.92-0.44,1.39-0.65c1.45-0.65,2.92-1.27,4.39-1.9
						c-0.6,0.25-1.19,0.5-1.79,0.76c8.01-3.38,16.11-6.55,24.04-10.09c1.42-0.63,2.57-1.21,3.75-2.28c2.92-2.64,2.88-7.96,0-10.61
						C661.17,2203.08,656.79,2203.13,653.68,2205.94L653.68,2205.94z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M581.45,1858.11c-4.27,3.52-3.93,9.43-1.69,13.91c1.57,3.15,4.26,5.88,6.8,8.29
						c0.97,0.92,1.95,1.83,2.97,2.69c1.66,1.42,3.08,2.15,5.14,2.91c2.79,1.03,5.89,1.32,8.81,1.65c5.51,0.62,11.07,0.64,16.6,0.87
						c2.83,0.12,5.61,0.5,8.42,0.84c4.59,0.55,9.19,1.06,13.79,1.51c16.13,1.6,32.33,2.5,48.54,2.32
						c16.33-0.19,32.63-1.46,48.86-3.29c4.54-0.51,9.08-1.07,13.61-1.65c4.62-0.59,9.38-0.85,13.93-1.8
						c5.1-1.06,9.86-3.91,12.61-8.42c2.43-3.99,3.19-9.39,1.74-13.87c-0.82-2.53-2.13-4.8-4.02-6.68c-2.79-2.77-7.86-2.99-10.61,0
						c-2.74,2.99-2.97,7.65,0,10.61c0.15,0.15,0.3,0.32,0.45,0.47c0.81,0.8-0.86-1.36-0.49-0.62c0.21,0.43,0.48,0.82,0.68,1.26
						c-0.25-0.6-0.5-1.19-0.76-1.79c0.32,0.8,0.54,1.61,0.66,2.46c-0.09-0.66-0.18-1.33-0.27-1.99c0.09,0.86,0.08,1.71-0.02,2.58
						c0.09-0.66,0.18-1.33,0.27-1.99c-0.13,0.86-0.35,1.69-0.68,2.5c0.25-0.6,0.5-1.19,0.76-1.79c-0.22,0.5-0.45,0.98-0.74,1.44
						c-0.17,0.27-0.81,1.12,0.02,0.03c0.84-1.1,0.15-0.23-0.06-0.03c-0.14,0.14-0.36,0.28-0.48,0.44c0.79-1.09,1.11-0.8,0.4-0.34
						c-0.6,0.39-1.23,0.72-1.88,1.01c0.6-0.25,1.19-0.5,1.79-0.76c-1.84,0.76-3.76,1.14-5.72,1.41c0.66-0.09,1.33-0.18,1.99-0.27
						c-22.66,2.88-45.39,5.54-68.25,6.02c-22.6,0.48-45.15-1.22-67.57-3.94c-8.29-1.01-16.68-0.6-24.96-1.71
						c0.66,0.09,1.33,0.18,1.99,0.27c-1.98-0.28-4.01-0.61-5.87-1.39c0.6,0.25,1.19,0.5,1.79,0.76c-0.36-0.16-0.68-0.38-1.04-0.54
						c-0.7-0.32,1.58,1.33,1.06,0.82c-0.18-0.18-0.39-0.34-0.58-0.51c-1.01-0.9-2.01-1.81-2.98-2.76c-1.01-0.98-1.95-2.02-2.88-3.08
						c-1.02-1.17,1.03,1.45,0.32,0.42c-0.17-0.24-0.34-0.48-0.5-0.73c-0.35-0.53-0.65-1.08-0.91-1.66c0.25,0.6,0.5,1.19,0.76,1.79
						c-0.21-0.51-0.37-1.02-0.44-1.57c0.09,0.66,0.18,1.33,0.27,1.99c-0.02-0.28-0.02-0.55,0-0.83c-0.09,0.66-0.18,1.33-0.27,1.99
						c0.05-0.27,0.11-0.52,0.22-0.77c-0.25,0.6-0.5,1.19-0.76,1.79c0.43-0.86,0.3,0.18-0.72,0.81c0.18-0.11,0.34-0.34,0.51-0.48
						c1.4-1.15,2.2-3.57,2.2-5.3c0-1.84-0.82-4.04-2.2-5.3c-1.43-1.31-3.31-2.29-5.3-2.2C584.66,1856,583.06,1856.78,581.45,1858.11
						L581.45,1858.11z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M1245.47,2294.01c-1.31,0.16-2.58,0.22-3.8,0.7c-1.88,0.74-3.19,1.48-4.75,2.8
						c-1.81,1.53-3.2,3.95-3.77,6.23c-1.41,5.62,1.09,11.65,6.09,14.58c3.08,1.81,6.82,2.3,10.33,1.97
						c2.98-0.28,5.93-0.64,8.93-0.28c-0.8-0.11-1.6-0.21-2.39-0.32c4.47,0.64,8.7,2.05,13.27,2.19c4.09,0.12,8.15-0.01,12.24,0.32
						c8.15,0.64,16.23,2.16,24.4,2.55c2.68,0.13,5.37-0.24,7.84-1.28c1.69-0.71,3.12-1.54,4.45-2.88c1.08-1.09,1.88-2.44,2.79-3.67
						c-0.47,0.61-0.94,1.21-1.41,1.82c0.23-0.28,0.45-0.5,0.73-0.73c-0.61,0.47-1.21,0.94-1.82,1.41c0.1-0.06,0.2-0.12,0.3-0.18
						c-0.72,0.3-1.43,0.6-2.15,0.91c0.22-0.11,0.45-0.19,0.69-0.23c-0.8,0.11-1.6,0.21-2.39,0.32c0.38-0.04,0.7-0.04,1.08-0.01
						c-0.8-0.11-1.59-0.21-2.39-0.32c3.03,0.46,5.76,1.22,8.86,0.39c4.07-1.09,6.88-4.39,8.26-8.21c0.96-2.66,0.92-5.89,0.01-8.56
						c-1.06-3.1-2.76-5.3-4.78-7.83c-1.34-1.68-4.31-2.64-6.36-2.64c-2.21,0-4.84,0.98-6.36,2.64c-1.57,1.72-2.74,3.97-2.64,6.36
						c0.11,0.8,0.21,1.59,0.32,2.39c0.43,1.52,1.2,2.85,2.31,3.97c0.18,0.22,0.35,0.44,0.52,0.66c-0.47-0.61-0.94-1.21-1.41-1.82
						c0.69,0.89,1.31,1.8,1.79,2.82c-0.3-0.72-0.6-1.43-0.91-2.15c0.21,0.52,0.37,1.03,0.48,1.58c-0.11-0.8-0.21-1.59-0.32-2.39
						c0.06,0.45,0.06,0.86,0.02,1.31c0.11-0.8,0.21-1.6,0.32-2.39c-0.07,0.44-0.18,0.85-0.35,1.27c0.3-0.72,0.6-1.43,0.91-2.15
						c-0.14,0.3-0.29,0.56-0.48,0.83c0.47-0.61,0.94-1.21,1.41-1.82c-0.21,0.25-0.41,0.46-0.66,0.66c0.61-0.47,1.21-0.94,1.82-1.41
						c-0.17,0.12-0.34,0.23-0.53,0.32c0.72-0.3,1.43-0.6,2.15-0.91c-0.34,0.13-0.61,0.2-0.97,0.24c0.8-0.11,1.59-0.21,2.39-0.32
						c-0.25,0.02-0.5,0.01-0.75-0.02c0.8,0.11,1.6,0.21,2.39,0.32c-2.99-0.41-5.82-1.37-8.86-0.41c-1.95,0.62-3.66,1.66-5.12,3.1
						c-1.04,1.03-1.78,2.37-2.66,3.54c0.47-0.61,0.94-1.21,1.41-1.82c-0.23,0.27-0.46,0.5-0.73,0.74c0.61-0.47,1.21-0.94,1.82-1.41
						c-0.37,0.27-0.75,0.48-1.17,0.67c0.72-0.3,1.43-0.6,2.15-0.91c-0.55,0.2-1.1,0.34-1.68,0.43c0.8-0.11,1.6-0.21,2.39-0.32
						c-3.14,0.38-6.45-0.18-9.57-0.52c-3.57-0.39-7.14-0.86-10.71-1.27c-3.56-0.41-7.14-0.77-10.72-0.92
						c-3.59-0.15-7.21,0.15-10.78-0.25c0.8,0.11,1.6,0.21,2.39,0.32c-4.35-0.59-8.47-2.04-12.9-2.17c-3.41-0.1-6.83,0.83-10.23,0.46
						c0.8,0.11,1.59,0.21,2.39,0.32c-0.68-0.1-1.32-0.26-1.96-0.53c0.72,0.3,1.43,0.6,2.15,0.91c-0.3-0.15-0.57-0.31-0.85-0.5
						c0.61,0.47,1.21,0.94,1.82,1.41c-0.27-0.22-0.5-0.44-0.73-0.7c0.47,0.61,0.94,1.21,1.41,1.82c-0.23-0.31-0.4-0.59-0.57-0.94
						c0.3,0.72,0.6,1.43,0.91,2.15c-0.13-0.36-0.22-0.68-0.3-1.06c0.11,0.8,0.21,1.59,0.32,2.39c-0.04-0.38-0.04-0.72-0.01-1.1
						c-0.11,0.8-0.21,1.59-0.32,2.39c0.06-0.38,0.15-0.7,0.28-1.06c-0.3,0.72-0.6,1.43-0.91,2.15c0.17-0.34,0.34-0.63,0.57-0.93
						c-0.47,0.61-0.94,1.21-1.41,1.82c0.26-0.28,0.5-0.51,0.8-0.75c-0.61,0.47-1.21,0.94-1.82,1.41c0.2-0.14,0.41-0.27,0.63-0.37
						c-0.72,0.3-1.43,0.6-2.15,0.91c0.35-0.15,0.67-0.25,1.04-0.33c-0.8,0.11-1.6,0.21-2.39,0.32c0.12-0.01,0.24-0.03,0.36-0.04
						c2.47-0.29,4.54-0.81,6.36-2.64c1.56-1.56,2.74-4.12,2.64-6.36c-0.1-2.33-0.87-4.74-2.64-6.36
						C1250.2,2295.14,1247.78,2293.73,1245.47,2294.01L1245.47,2294.01z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M440.18,528.14c-0.26,0.15-0.54,0.27-0.81,0.39c0.84-0.35,1.67-0.71,2.51-1.06
						c-4,1.64-8.31,2.56-12.42,3.86c-2.93,0.92-5.79,1.99-8.59,3.25c-2.89,1.3-5.14,3.29-6.94,5.91c0.55-0.71,1.09-1.42,1.64-2.13
						c-0.07,0.09-0.15,0.16-0.23,0.23c0.71-0.55,1.42-1.09,2.13-1.64c-0.32,0.24-0.69,0.42-1.06,0.58c0.84-0.35,1.67-0.71,2.51-1.06
						c-4.82,2.02-10.19,2.48-15.15,4.06c-1.56,0.5-3.17,1.06-4.56,1.93c-4.88,3.05-7.31,9.76-4.01,14.92
						c3.89,6.09,11.53,7.87,18.2,8.82c12.23,1.74,24.68,1.94,37.01,2.12c11.66,0.17,23.31,0.02,34.96-0.45
						c11.73-0.47,23.45-1.27,35.13-2.4c11.59-1.12,23.14-2.73,34.77-3.39c5.83-0.33,11.65-0.37,17.47-0.06
						c7.11,0.38,14.93-0.75,19.94-6.37c3.09-3.47,5.11-8.01,4.64-12.75c-0.2-2.01-0.49-3.87-1.24-5.73c-0.9-2.25-1.81-4.66-3.61-6.4
						c-2.41-2.32-5.12-3.45-8.23-4.6c-10.51-3.88-21.79-5.29-32.48-8.57c-0.76-0.23-1.52-0.48-2.25-0.78
						c0.84,0.35,1.67,0.71,2.51,1.06c-0.33-0.14-0.66-0.29-0.95-0.5c-2.06-1.52-5.74-1.71-8.09-1.06c-2.44,0.67-5.08,2.55-6.28,4.83
						c-1.29,2.44-1.93,5.39-1.06,8.09c0.35,0.84,0.71,1.67,1.06,2.51c0.93,1.58,2.19,2.83,3.77,3.77c1.12,0.82,2.61,1.25,3.87,1.73
						c0.81,0.31,1.64,0.56,2.48,0.81c2.42,0.71,4.88,1.31,7.33,1.91c6.01,1.47,12.05,2.82,18.02,4.46c2.6,0.71,5.21,1.44,7.7,2.48
						c-0.84-0.35-1.67-0.71-2.51-1.06c0.7,0.3,1.4,0.62,2.01,1.07c-0.71-0.55-1.42-1.09-2.13-1.64c0.14,0.11,0.27,0.23,0.39,0.37
						c-0.55-0.71-1.09-1.42-1.64-2.13c0.38,0.51,0.63,1.16,0.88,1.74c-0.35-0.84-0.71-1.67-1.06-2.51c0.4,0.96,0.7,1.91,0.86,2.94
						c-0.13-0.93-0.25-1.86-0.38-2.79c0.08,0.73,0.08,1.42-0.02,2.14c0.12-0.93,0.25-1.86,0.38-2.79c-0.1,0.5-0.23,0.95-0.42,1.42
						c0.35-0.84,0.71-1.67,1.06-2.51c-0.25,0.53-0.54,1.02-0.9,1.49c0.55-0.71,1.09-1.42,1.64-2.13c-0.46,0.55-0.96,1.03-1.52,1.47
						c0.71-0.55,1.42-1.09,2.13-1.64c-0.69,0.5-1.41,0.9-2.19,1.24c0.84-0.35,1.67-0.71,2.51-1.06c-1.03,0.41-2.09,0.65-3.19,0.81
						c0.93-0.13,1.86-0.25,2.79-0.38c-2.5,0.29-5,0.08-7.51-0.04c-2.63-0.12-5.27-0.16-7.91-0.13c-5.28,0.06-10.54,0.4-15.8,0.87
						c-10.62,0.94-21.19,2.32-31.81,3.26c-21.08,1.86-42.26,2.67-63.42,2.42c-5.95-0.07-11.9-0.23-17.85-0.47
						c-5.88-0.24-11.79-0.47-17.63-1.24c0.93,0.12,1.86,0.25,2.79,0.38c-3.14-0.43-6.4-0.89-9.36-2.1c0.84,0.35,1.67,0.71,2.51,1.06
						c-0.84-0.37-1.61-0.8-2.36-1.35c0.71,0.55,1.42,1.09,2.13,1.64c-0.22-0.17-0.45-0.35-0.64-0.55c0.55,0.71,1.09,1.42,1.64,2.13
						c-0.02-0.03-0.05-0.06-0.06-0.09c0.35,0.84,0.71,1.67,1.06,2.51c-0.01-0.03-0.02-0.06-0.03-0.09c0.13,0.93,0.25,1.86,0.38,2.79
						c0-0.02,0-0.03,0-0.05c-0.13,0.93-0.25,1.86-0.38,2.79c0-0.03,0.01-0.06,0.02-0.1c-0.35,0.84-0.71,1.67-1.06,2.51
						c0.02-0.03,0.03-0.07,0.06-0.1c-0.55,0.71-1.09,1.42-1.64,2.13c0.06-0.07,0.13-0.14,0.21-0.2c-0.71,0.55-1.42,1.09-2.13,1.64
						c0.23-0.17,0.49-0.29,0.75-0.4c-0.84,0.35-1.67,0.71-2.51,1.06c3.3-1.39,7.03-1.93,10.49-2.78c1.75-0.43,3.52-0.85,5.22-1.46
						c1.35-0.49,2.72-0.99,3.97-1.7c2.12-1.2,3.1-2.59,4.51-4.46c-0.55,0.71-1.09,1.42-1.64,2.13c0.17-0.2,0.36-0.38,0.56-0.54
						c-0.71,0.55-1.42,1.09-2.13,1.64c0.87-0.66,1.87-1.12,2.86-1.55c-0.84,0.35-1.67,0.71-2.51,1.06c3.5-1.47,7.19-2.49,10.83-3.56
						c3.53-1.03,7.35-1.95,10.58-3.76c2.28-1.27,4.14-3.78,4.83-6.28c0.69-2.51,0.38-5.86-1.06-8.09
						C451.41,527.05,445.31,525.27,440.18,528.14L440.18,528.14z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M643.84,1066.04c-2.69-1.76-6.15-1.12-8.99-0.15c-2.98,1.01-5.87,2.29-8.74,3.56
						c-5.97,2.64-11.84,5.53-17.55,8.68c-2.1,1.16-4.21,2.34-6.17,3.74c-1.03,0.74-2.09,1.47-2.97,2.39
						c-2.36,2.45-2.94,5.72-1.95,8.96c2.13,6.99,9.71,10.58,15.98,13.06c5.67,2.24,11.79,3.19,17.84,3.73
						c5.59,0.5,11.22,0.47,16.83,0.41c6.64-0.07,13.28-0.14,19.92-0.21c13.28-0.14,26.56-0.28,39.84-0.42
						c26.65-0.28,53.3-0.56,79.95-0.84c26.65-0.28,53.3-0.56,79.95-0.84c3.27-0.03,6.55-0.07,9.82-0.1c1.85-0.02,4.02-0.8,5.3-2.2
						c1.42-1.55,1.99-3.22,2.2-5.3c0.07-0.66-0.5,2.43-0.2,1.5c0.04-0.11,0.06-0.24,0.08-0.35c0.02-0.11,0.08-0.21,0.1-0.31
						c0.14-0.68-0.91,1.82-0.6,1.41c0.09-0.12,0.18-0.3,0.25-0.43c0.51-1-1.53,1.86-0.84,1.08c1.64-1.84,2.86-3.76,2.88-6.34
						c0.04-5.46-5.23-8.6-9.06-11.44c-3.94-2.91-7.93-5.74-11.9-8.6c-1.49-1.07-4.08-1.22-5.78-0.76c-1.74,0.48-3.62,1.82-4.48,3.45
						c-2.05,3.88-0.72,7.81,2.69,10.26c3.51,2.53,7.03,5.04,10.53,7.59c1.06,0.78,2.11,1.56,3.17,2.35c1.02,0.76-1.52-1.2-0.82-0.63
						c0.18,0.15,0.36,0.29,0.55,0.43c0.3,0.24,0.6,0.49,0.89,0.74c0.43,0.38,1.63,1.8,0.5,0.32c-1.01-1.31-0.52-0.72-0.34-0.37
						c0.14,0.29-0.88-2.3-0.69-1.6c0.16,0.59-0.27-2.45-0.25-1.79c-0.05-1.24,0.38-2.47,0.93-3.46c-0.47,0.85,1.27-1.53,0.97-1.22
						c-0.18,0.18-0.34,0.4-0.51,0.6c-1.92,2.3-2.77,4.58-3.07,7.56c2.5-2.5,5-5,7.5-7.5c-25.65,0.27-51.3,0.54-76.95,0.81
						c-25.65,0.27-51.3,0.54-76.95,0.81c-25.59,0.27-51.18,0.57-76.77,0.8c-5.37,0.05-10.75,0.04-16.1-0.47
						c-0.61-0.06-1.22-0.12-1.82-0.19c-0.21-0.02-1.74-0.22-0.36-0.04c1.22,0.16,0.17,0.02-0.13-0.03c-1.42-0.22-2.83-0.48-4.23-0.8
						c-1.17-0.27-2.33-0.57-3.47-0.92c-1.24-0.38-2.53-1.1-3.79-1.34c1.63,0.31,1.02,0.44,0.35,0.14c-0.27-0.12-0.54-0.25-0.81-0.38
						c-0.61-0.29-1.21-0.6-1.79-0.93c-0.53-0.29-1.05-0.59-1.56-0.91c-0.26-0.16-0.51-0.32-0.77-0.49
						c-0.69-0.46-0.87-1.02,0.21,0.23c-0.34-0.39-0.81-0.75-1.2-1.09c-0.96-0.85,0.56,0.54,0.42,0.6c-0.09,0.03-0.31-0.59-0.4-0.67
						c0.15,0.36,0.3,0.72,0.45,1.09c-0.15-0.57-0.16-0.49-0.04,0.24c0.11,0.97,0.11,0.29-0.06,1.54c-0.16,1.21-0.05,1.24-0.83,2.62
						c0.02-0.04,0.05-0.08,0.08-0.12c-1.12,1.89-1.41,1.65-0.64,0.96c0.11-0.1,0.23-0.18,0.33-0.28c0.32-0.31-1.99,1.46-0.91,0.71
						c0.83-0.58,1.68-1.13,2.54-1.66c5.1-3.11,10.48-5.78,15.86-8.35c1.45-0.69,2.91-1.37,4.38-2.03c0.61-0.27,1.22-0.55,1.84-0.81
						c0.33-0.14,0.66-0.29,0.99-0.43c1.12-0.48-2.12,0.88-0.52,0.22c1.84-0.76,3.73-1.57,5.67-2.02c1.61-0.38-1.91,0.12-1.12,0.12
						c0.09,0,0.17-0.01,0.26,0c-1.23-0.04-2.49-0.29-3.49-0.94c3.29,2.15,8.44,0.77,10.26-2.69
						C648.51,1072.56,647.35,1068.34,643.84,1066.04L643.84,1066.04z"/>
				</g>
			</g>
			<path d="M878.74,1096.82c0.2-0.34,0.57-1.17,0.37-2.62c-0.13-0.94-0.1-1.94-0.06-2.9c0.01-0.43,0.03-0.88,0.03-1.32v-0.21
				l-0.21-0.01c-1.96-0.09-3.7-1.16-5.16-3.18c-1.13-1.55-2.47-3.05-4.6-3.53c-1.31-0.3-2.46-0.66-3.5-1.11
				c-5.66-7.84-10.61-14.15-18.44-18.12c-0.33-0.17-0.67-0.33-1.01-0.5c-1.85-0.9-3.77-1.84-5.12-3.49l-1.81-8.72l-0.04-0.02
				l-0.04-0.2l-0.1-0.05c-1.38-0.67-2.11-1.71-2.58-2.93c-0.27-0.75-0.45-1.56-0.64-2.38l-0.12-0.55l-0.04,0l-0.05-0.22l-0.18,0.01
				c-1.94,0.06-3.88,0.12-5.84,0.18c-0.39-0.11-0.63-0.18-0.79-0.21l-0.02-0.4l-0.04,0.05l-0.7-5.09l0.04,0l-0.07-0.25l-0.02-0.18
				l-0.04,0c-0.3-0.86-0.84-1.55-1.52-1.9c-0.57-0.29-1.18-0.33-1.78-0.13c-1.87,0.65-2.65,2.36-2.09,4.49
				c-0.88,0.08-1.75,0.25-2.59,0.41c-1.48,0.3-3.02,0.6-4.64,0.42c-2.1-0.24-4.48-0.32-6.18,1.52c-1.03-0.62-2.02-1.35-2.98-2.06
				c-2.2-1.64-4.48-3.33-7.46-3.73l-4.86-3.54c-0.07-0.04-0.13-0.09-0.19-0.15l-1.05-1.44c0.51-4.94-0.99-9.53-2.45-13.97
				c-0.5-1.51-1.01-3.08-1.44-4.63c-0.07-0.27-0.21-0.43-0.4-0.48c-0.35-0.08-0.69,0.29-0.96,0.59c-1.71,0.77-3.57,1.16-5.37,1.54
				c-0.89,0.19-1.81,0.38-2.71,0.61l-1.02-0.08l-1.11-0.36c-0.02-0.06-0.04-0.12-0.06-0.19c-0.2-0.66-0.42-1.4-0.93-1.59
				c-0.23-0.08-0.48-0.04-0.74,0.13c-1.65,1.06-2.53,0.3-3.55-0.59c-0.4-0.35-0.82-0.7-1.29-0.93c0.02-0.4,0.03-0.8,0.05-1.2
				c0.04-0.98,0.08-1.98,0.14-2.98l0.02-0.34c0.16-2.76,0.31-5.61-2.84-7.23c-0.08-0.18-0.17-0.35-0.27-0.52
				c-0.22-0.4-0.43-0.77-0.42-1.12c0.04-1.35-0.22-2.19-0.79-2.54c-0.66-0.41-1.6-0.12-2.78,0.47l0,0l-0.26,0.14l-0.01,0l-0.03,0.02
				c-0.12-0.01-0.17-0.09-0.25-0.37l-0.02-0.05c-0.28-0.74-0.59-1.48-0.88-2.2c-1.01-2.44-2.05-4.96-2.06-7.73
				c0-1.24-0.77-2.02-2.22-2.24c-0.65-0.33-1.14-0.4-1.48-0.18c-0.37,0.23-0.51,0.74-0.47,1.6c0.04,0.73,0.06,1.47,0.08,2.18
				c0.01,0.28,0.02,0.56,0.02,0.83l-19.32,6.4c0-0.74-0.03-1.49-0.52-1.95c-0.31-0.29-0.76-0.41-1.38-0.37
				c-1.49,0.11-1.45,1.2-1.42,2c0.01,0.23,0.02,0.46,0,0.66c-0.02,0.21-0.02,0.41-0.02,0.61c0,0.04,0,0.08,0,0.12
				c-1.35,0.4-2.69,0.8-4.04,1.21c-0.67-1.44-2-2.03-3.29-2.59l-0.28-0.12c-0.75-0.33-1.35-0.22-1.79,0.34
				c-0.64,0.83-0.13,1.43,0.31,1.88c-0.31,1.29-0.78,2.07-1.41,2.3c-0.62,0.22-1.44-0.05-2.45-0.82c-0.19-0.14-0.42-0.18-0.64-0.21
				c-0.06-0.01-0.12-0.02-0.18-0.03l-0.47-0.68l-0.03,0.02c-0.22-0.19-0.45-0.37-0.67-0.56l-0.06-0.05l-0.04-0.01
				c-0.1-0.04-0.12-0.09-0.13-0.15l-0.01-0.06l-0.04-0.04c-0.78-0.93-1.56-1.87-2.43-2.92l-0.25-0.3l-0.12,0.38
				c-0.41,1.32-1.15,2.53-1.87,3.69c-0.95,1.54-1.91,3.13-2.17,5.02c-0.63-0.97-1.94-1.22-2.81-1.35l-0.01-0.07l-0.19,0.04
				l-0.25-0.04l0.02,0.11c-1.34,0.42-1.33,1.49-1.3,2.36c0.02,0.6,0.03,1.17-0.31,1.55l-0.06,0.07l-0.08,0.08
				c-0.15,0.08-0.23,0.13-0.25,0.14l-0.03,0.02l-0.02,0.03c-0.28,0.33-0.47,0.84-0.68,1.37c-0.34,0.88-0.73,1.86-1.52,2.21
				l-0.03-0.16l-0.27,0.27l-0.12,0.03l0.01,0.06c-0.1,0.03-0.2,0.07-0.3,0.09c-0.16,0.05-0.33,0.09-0.5,0.16l-0.08,0.03l-0.04,0.07
				c-0.07,0.14-0.15,0.28-0.23,0.41c-0.36,0.63-0.74,1.28-0.49,2.15c0.48,1.67,0.45,3.42,0.43,5.11c-0.01,0.81-0.02,1.64,0.02,2.47
				c-0.43,0.22-0.87,0.38-1.35,0.34c-0.04-0.09-0.11-0.21-0.23-0.27c-0.04-0.07-0.11-0.15-0.19-0.14c-2.56,0.07-5.12,0.15-7.68,0.23
				l-0.76,0.02c-1.09-0.94-2.2-0.52-3.18-0.14c-0.25,0.1-0.52,0.2-0.77,0.28l-0.1,0.03l-0.04,0.1c-0.2,0.51-0.16,1.1-0.13,1.66
				c0.01,0.1,0.01,0.2,0.02,0.3c0.04,0.92-0.03,1.71-1.26,2.12l-4.19,0.64l-0.05,0.1c-0.04,0.08-0.08,0.16-0.13,0.23
				c-0.02,0.03-0.04,0.06-0.06,0.09l-0.16-0.11c-0.09,0.12-0.18,0.24-0.27,0.34l0.15,0.14c-0.02,0.02-0.04,0.05-0.06,0.08
				c-0.04,0.04-0.08,0.08-0.12,0.11c-0.58,0.52-1.34,0.65-2.31,0.37c-0.99-0.76-2.11-1.11-3.32-1.03c-0.08,0.01-0.15,0.01-0.23,0.03
				c-0.52,0.1-0.86,0.41-0.99,0.91c-0.16,0.07-0.31,0.11-0.47,0.14c-0.09,0.01-0.17,0.02-0.26,0.02c-0.6,0.01-1.15-0.28-1.74-0.58
				c-0.49-0.26-1-0.53-1.56-0.61c-0.24-0.77-0.48-1.54-0.71-2.31l-0.12-0.38l-0.25,0.25c-0.07,0.07-0.17,0.15-0.27,0.23
				c-0.26,0.2-0.53,0.41-0.54,0.69c-0.04,0.84-0.23,1.37-0.58,1.64c-0.5,0.39-1.39,0.25-2.41,0.05c-0.26-0.05-0.54-0.17-0.85-0.3
				c-0.95-0.39-2.25-0.93-3.14,0.72c-0.33,0.35-0.68,0.7-1.01,1.04c-0.79,0.8-1.6,1.62-2.29,2.52c-0.83,1.09-1.6,0.97-2.58,0.4
				l-0.53-1.26c0.12-0.64,0.03-1.13-0.28-1.47c-0.33-0.36-0.9-0.51-1.63-0.41c-0.47,0.06-0.88,0.29-1.15,0.65
				c-0.26,0.35-0.36,0.8-0.28,1.26c0.1,0.61,0.05,1.22,0,1.86c-0.08,0.9-0.16,1.83,0.23,2.76c0.45,1.08,0.23,2.6-0.42,2.94
				c-1.25,0.66-2.07,1.82-2.87,2.95c-1.15,1.63-2.23,3.16-4.53,3.27c-0.63,0.03-0.67,0.65-0.7,1.1c-0.01,0.19-0.03,0.39-0.07,0.53
				l-0.01,0.04c-0.31,2.23-1.55,3.36-3.78,3.43l-0.06,0.01c-0.59,0.2-1.19,0.4-1.77,0.59c-1.36,0.45-2.76,0.91-4.1,1.48
				c-0.72,0.31-1.15-0.02-1.65-0.4c-0.39-0.29-0.78-0.59-1.32-0.59l-0.03,0c-0.38,0.06-0.74,0.21-1.08,0.35
				c-0.52,0.22-1.01,0.42-1.57,0.31l-0.09-0.02l-0.08,0.05c-0.31,0.22-0.55,0.51-0.79,0.8c-0.38,0.47-0.74,0.92-1.4,0.95l-0.2,0.01
				l-0.04,0.15c-0.01,0.02-0.01,0.04-0.02,0.06c-0.16,0.21-0.31,0.43-0.47,0.65c-0.76,1.04-1.52,2.11-2.26,3.18
				c-1.93,1.6-2.96,3.61-3.05,5.98c-0.02,0.66,0.34,1.27,0.65,1.81c0.21,0.35,0.4,0.68,0.46,0.96c0,0.38-0.01,0.77-0.04,1.18
				c-0.21,0.13-0.42,0.42-0.63,0.87c-0.45,0.97-0.66,2.03-0.87,3.05c-0.09,0.45-0.18,0.91-0.3,1.35c-0.21,0.85-0.41,1.71-0.6,2.55
				c-0.26,1.15-0.54,2.34-0.85,3.51c-1.34,4.95-2.58,9.4-4.09,13.81c-0.4,1.16-0.94,2.67-1.58,4.15l0.1,0.04l0,0h0l0.03,0.01
				l-1.48,2.79c66.18,0,129.19,0,193.61-0.01c25.64,0.13,50.96,0.22,74.82,0.22l0.56,0L878.74,1096.82z"/>
			<path d="M580.98,487.61c0.01-0.57-0.17-1.02-0.53-1.33c-1.06-0.92-3.48-0.53-6.04-0.11c-0.51,0.08-1.02,0.16-1.52,0.24
				c-1.84,0.26-1.84,0.26-2.05,0.33c-0.19,0.06-0.58,0.18-3.17,0.69c-0.03,0.01-3.12,0.53-6.17-0.48c-1.61-0.54-1.61-0.54-1.7-0.36
				c-0.08,0.16,0.07,0.26,0.15,0.32c0.01,0.01,0.03,0.02,0.06,0.04c-0.04,0-0.09,0-0.15,0.01c-0.88,0.01-2.3,0.59-4.09,1.34
				c-1.48,0.61-3.16,1.31-4.84,1.79c-1.93,0.55-1.93,0.55-2.04,0.88c-0.06,0.19-0.21,0.63-2.3,2.03l-4.54,2.82l-2.38-0.14
				c-2.36-0.14-14.78,1.26-15.63,2.86c-2.77,5.27-6.9,5.84-10.91,6.39c-1.96,0.27-3.8,0.52-5.42,1.31c-0.8,0.39-1.82,0.36-2.87,0.2
				c-0.27-0.28-0.52-0.6-0.72-1.02c-0.41-0.83-1.23-0.67-1.89-0.09l-0.01-0.16l-0.28,0.17c-0.23,0.14-0.41,0.32-0.57,0.51
				c-1.09-0.09-2.09,0.02-2.85,0.71l-2.98,2.68l0.01-1.7c-0.05-1.98-0.08-4-0.11-5.95c-0.05-3.95-0.11-8.04-0.39-12.06l-0.01-0.08
				l-0.03,0c0.42-0.62,1.13-1.01,1.4-1.74c0.02,0,0.05,0,0.07,0l0.09-0.01l0-0.09c-0.01-0.48,0.04-0.98,0.08-1.46
				c0.14-1.54,0.28-3.14-1.23-4.45c-1.7-1.47-3.19-2.09-4.55-1.9c-1.46,0.2-2.8,1.3-4.22,3.45l-0.02,0.02v0.03c0,0.04,0,0.07,0,0.11
				c-0.98,1.15-2.44,1.9-2.98,3.42c-0.31,0.88-0.91,0.81-1.52,0.44c-0.81-0.48-1.56-1.07-2.35-1.58c-0.38-0.25-0.61-0.63-0.35-0.96
				c1.04-1.32-0.02-1.52-0.87-1.85c-0.16,0.07-0.32,0.13-0.5,0.18c-0.32,0.3-0.68,0.43-1.12,0.23c-0.08-0.02-0.15-0.03-0.23-0.04
				c-0.15,0.02-0.31,0.02-0.47,0.02c-0.18,0-0.35-0.01-0.52-0.03c-0.21-0.01-0.42-0.04-0.62-0.12c-0.17-0.05-0.33-0.1-0.48-0.17
				c-0.14-0.03-0.29-0.07-0.43-0.1l-0.19,0.03c0,0-0.19-0.03-0.19-0.03l-1.15,0.11l-0.24,0.18l-0.36-2.96
				c-0.06-0.23-0.11-0.47-0.13-0.71l-0.01-0.11l-0.1-0.05c-0.71-0.32-1.26-0.87-1.61-1.6l-0.05-0.1l-0.11-0.01
				c-0.08-0.01-0.15-0.01-0.23-0.01c-0.49-0.02-0.7-0.06-0.73-0.26c-0.22-1.57-1.31-2.17-2.37-2.75c-0.3-0.16-0.6-0.33-0.88-0.52
				c0.04-0.12,0.08-0.24,0.12-0.36l0.03-0.09l-0.13-0.05l-0.07-0.02c-0.27-0.01-0.52-0.03-0.74-0.08l-0.05-0.01l-0.04,0.04
				c-0.25,0.25-0.29,0.59-0.33,0.93c-0.05,0.42-0.1,0.79-0.52,0.97l-0.06-0.09l-0.07,0.17c-0.24,0.58-0.73,0.89-1.24,1.23
				c-0.43,0.28-0.88,0.57-1.18,1.04c-0.03,0.06-0.03,0.13-0.04,0.2l-0.05,0l-0.31,0.49c-0.32,0.33-0.39,0.74-0.44,1.11
				c-0.03,0.19-0.05,0.36-0.11,0.53l-0.01,0.03l0.01,0.39l-0.63,0.12l0.06,0.12c0.03,0.06,0.04,0.11,0.02,0.17l-0.01,0.02l0,0.02
				c0.01,0.08,0.02,0.16,0.04,0.24c0.06,0.29,0.1,0.47-0.29,0.56c-0.89,0.21-1.66-0.08-2.47-0.38c-0.36-0.14-0.74-0.27-1.12-0.36
				c-0.13-0.03-0.26-0.07-0.39-0.11c-0.44-0.13-0.9-0.27-1.27-0.06c-0.21,0.12-0.37,0.33-0.49,0.64l-0.03-0.01l-0.09,0.11
				c-0.84,0.99-0.66,2.05-0.49,3.08c0.1,0.6,0.19,1.17,0.09,1.72l-0.01,0.04l0.17,0.7c-0.05,0.01-0.1,0.03-0.16,0.04l-0.05,0.01
				l-0.03,0.04c-0.09,0.14-0.22,0.28-0.34,0.41c-0.36,0.41-0.77,0.87-0.44,1.59c-0.04,0.1-0.08,0.21-0.11,0.31l-0.02,0.05l0,0.02
				c0.46,5.82,0.42,11.76,0.38,17.49c-0.02,2.44-0.03,4.96-0.01,7.45c-0.49-0.64-1.08-1.04-1.76-1.16
				c-1.88-0.32-4.03,1.42-5.76,2.82c-0.93,0.75-1.99,1.6-2.3,1.43c-0.09-0.05-0.27-0.31-0.05-1.62c0.43-2.5,0.13-4-0.9-4.47
				c-2.58-1.17-8.77,4.93-9.91,6.32c-0.38,0.46-0.36,1.76-0.3,3.53c0.02,0.57,0.04,1.11,0.02,1.5c-1.25-1.38-2.34-2.96-3.39-4.49
				c-1.39-2.02-2.82-4.1-4.67-5.82c-1.52-1.41-2.81-1.94-3.93-1.61c-2.24,0.66-3.26,4.66-4.24,8.52c-0.78,3.06-1.51,5.94-2.75,6.88
				c-0.16,0.12-0.32,0.23-0.49,0.35c-0.49,0.34-1,0.68-1.33,1.21c-0.31,0.5-0.42,1.09-0.5,1.6c-0.63,4.32,0.76,11.06,4.08,14.42
				c1.46,1.48,3.17,2.22,5.09,2.22c0.35,0,0.7-0.02,1.06-0.07l2.09-0.32c-1.22,0.38-2.93,0.87-5.3,1.51l-0.22,0.06l0,0.01
				c-1.38,0.43-3.37,1.83-5,2.98c-0.74,0.52-1.44,1.02-1.96,1.33l-0.15,0.09l0.08,0.16c0.05,0.09,0.08,0.18,0.11,0.28l0.04,0.15
				l3.2-0.16c2.19-0.24,4.43-0.19,6.59-0.15c0.81,0.02,1.62,0.03,2.43,0.03h0c2.07,0,4.15-0.02,6.22-0.04
				c4.7-0.04,9.56-0.09,14.34,0.06c2.28,0.07,4.6,0.31,6.85,0.54c2.72,0.28,5.51,0.57,8.29,0.57c0.74,0,1.47-0.02,2.21-0.07
				c3.8-0.24,7.66-0.23,11.4-0.22c3.25,0,6.62,0.01,9.92-0.14c8.13-0.38,16.39-0.2,24.38-0.02c4.51,0.1,9.17,0.2,13.75,0.2
				c2.61,0,5.27,0.06,7.84,0.11c3.07,0.07,6.25,0.13,9.36,0.11c3.91-0.03,7.89-0.15,11.74-0.26c5.89-0.17,11.98-0.35,17.97-0.24
				c2.09,0.04,4.2-0.05,6.23-0.14c2.43-0.11,4.94-0.22,7.42-0.1l0.21,0.01v-5.13v-3.15v-42v-0.06v-0.24l0,0
				C580.97,500.11,580.94,490.01,580.98,487.61z M479.08,502.77c-0.01-0.01-0.02-0.03-0.03-0.04c0.04-0.02,0.1-0.03,0.15-0.04
				C479.16,502.72,479.12,502.74,479.08,502.77z M456.14,528.29c-0.03,0.03-0.06,0.05-0.08,0.08c-0.11-0.14-0.17-0.33-0.19-0.57
				C455.93,527.99,456.02,528.15,456.14,528.29z M462.54,546.5c-0.01,0.02-0.01,0.03-0.02,0.05c-0.03-0.03-0.08-0.05-0.1-0.08
				c-0.02-0.04-0.03-0.09-0.04-0.14C462.44,546.38,462.49,546.43,462.54,546.5z M487.09,545.72c0.08,0.04,0.15,0.06,0.23,0.1
				c0.08,0.04,0.15,0.06,0.23,0.09c-0.39,0.2-0.81,0.26-1.25,0.22C486.56,546,486.83,545.87,487.09,545.72z M542.78,545.05
				c-0.21,0.06-0.41,0.11-0.62,0.15C542.37,545.13,542.58,545.08,542.78,545.05z M418.27,548.37c-0.04,0.02-0.08,0.04-0.12,0.07
				c0-0.07-0.01-0.13-0.01-0.19C418.17,548.29,418.22,548.33,418.27,548.37z"/>
			<g>
				<path className="st2" d="M432.06,720.72c-8.84,3.84-23.15,9.05-60.32,20.47c-22.71,6.98,32.05,27.11,38.6,28.94
					c48.23,13.5,101.49,7.34,150.06-2.22c25.58-5.04,78.79,6.75,91.61-22.2c1.34-3.01-3.42-4.75-5.65-7.09
					c-1.51-1.59-5.11-2.67-6.96-3.52c-5.68-2.59-22.75-4.86-28.46-7.38c0.89,0.39-4.29,1.02-3.4,1.41c-0.88-0.39-1.91-1.5-2.73-2.02
					c-2.47-1.56-3.18,1.21-5.94,2.02c-2.59,0.75-34.31-2.99-35.58-0.44c-2.85,5.68,43.25,10.93,48.6,14.31
					c2.77,1.75,5.94,2.83,8.95,4.05c3.26,1.31,6.52,2.6,9.74,4.01c-0.89-0.39-1.78-0.79-2.67-1.18c1.19,0.53,2.52,0.99,3.62,1.72
					c-0.75-0.61-1.51-1.22-2.26-1.84c0.08,0.06,0.15,0.12,0.21,0.19c-0.58-0.79-1.16-1.59-1.74-2.38c0.05,0.06,0.09,0.13,0.12,0.21
					c-0.37-0.94-0.75-1.87-1.12-2.81c0.01,0.04,0.02,0.07,0.03,0.11c-0.13-1.04-0.27-2.08-0.4-3.12c0.01,0.08,0.01,0.15,0,0.23
					c0.13-1.04,0.27-2.08,0.4-3.12c-0.01,0.08-0.04,0.16-0.07,0.24c0.37-0.94,0.75-1.87,1.12-2.81c-0.04,0.09-0.09,0.17-0.14,0.25
					c0.58-0.79,1.16-1.59,1.74-2.38c-0.23,0.3-0.49,0.54-0.78,0.78c0.75-0.61,1.51-1.22,2.26-1.84c-1.92,1.5-4.21,2.46-6.35,3.54
					c-3.29,1.65-6.51,3.44-9.87,4.94c0.89-0.39,1.78-0.79,2.67-1.18c-4.23,1.86-8.59,3.35-13.14,4.01c0.99-0.14,1.98-0.28,2.97-0.42
					c-2.18,0.29-4.38,0.39-6.57,0.23c-1.91-0.14-3.82-0.45-5.74-0.32c-3.51,0.23-7.14,1.01-10.05,3.22
					c-1.5,1.14-2.72,2.18-3.89,3.61c-0.68,0.84-1.36,1.63-2.18,2.33c0.75-0.61,1.51-1.22,2.26-1.84c-0.92,0.72-1.91,1.29-2.97,1.77
					c0.89-0.39,1.78-0.79,2.67-1.18c-3.54,1.49-7.44,2.06-11.15,2.87c-4.31,0.94-8.61,1.89-12.92,2.83
					c-8.36,1.83-16.72-1.39-25.19-0.18c0.99-0.14,1.98,1.77,2.97,1.63c-8.27,1.16-16.63,4.69-24.93,3.54
					c0.99,0.14,1.98,0.28,2.97,0.42c-4.15-0.6-8.18-1.68-12.18-2.98c-3.85-1.26-7.68-2.59-11.66-3.32
					c-4.82-0.88-9.67-1.29-14.55-1.5c-4.2-0.18-8.39-0.31-12.56-0.88c0.99,0.14,1.98,0.28,2.97,0.42
					c-4.58-0.66-9.08-1.77-13.59-2.83c-4.69-1.1-9.38-2.19-14.07-3.29c-9.42-2.2-18.82-4.64-28.29-6.58
					c-3.31-0.68-6.8-1.31-10.18-0.93c-2.06,0.23-4.05,0.54-6.04,1.16c-1.18,0.37-2.36,0.76-3.59,0.94c0.99-0.14,1.98-0.28,2.97-0.42
					c-1.21,0.16-2.42,0.13-3.62-0.03c0.99,0.14,1.98,0.28,2.97,0.42c-1.12-0.17-2.22-0.45-3.27-0.92c0.89,0.39,1.78,0.79,2.67,1.18
					c-0.57-0.26-1.1-0.57-1.59-0.97c0.75,0.61,1.51,1.22,2.26,1.84c-0.29-0.25-0.56-0.52-0.79-0.83c0.58,0.79,1.16,1.59,1.74,2.38
					c-0.14-0.2-0.25-0.4-0.35-0.63c0.37,0.94,0.75,1.87,1.12,2.81c-0.09-0.22-0.15-0.45-0.19-0.68c0.13,1.04,0.27,2.08,0.4,3.12
					c-0.03-0.25-0.03-0.49-0.01-0.74c-0.13,1.04-0.27,2.08-0.4,3.12c0.04-0.32,0.12-0.62,0.24-0.93c-0.37,0.94-0.75,1.87-1.12,2.81
					c0.17-0.41,0.39-0.78,0.64-1.14c-0.58,0.79-1.16,1.59-1.74,2.38c0.57-0.77,1.25-1.42,1.97-2.03c-0.75,0.61-1.51,1.22-2.26,1.84
					c2.44-1.97,5.27-3.38,8.08-4.64c-0.89,0.39-1.78,0.79-2.67,1.18c0.29-0.13,0.59-0.26,0.88-0.39c1.45-0.36,2.72-1.06,3.79-2.1
					c1.21-0.83,2.17-1.93,2.87-3.3c1.4-2.52,1.99-6.23,1.12-9.05c-0.87-2.84,46.46-11.35,43.87-12.79
					C445.81,723.43,434.77,719.55,432.06,720.72z"/>
			</g>
			<g>
				<g>
					<path className="st6" d="M794.47,2492.11c0,0.05,0,0.1-0.01,0.16c0.18-1.33,0.36-2.66,0.54-3.99c-0.02,0.14-0.05,0.26-0.1,0.39
						c0.5-1.19,1.01-2.39,1.51-3.58c-0.08,0.17-0.17,0.33-0.29,0.48c0.78-1.01,1.56-2.02,2.35-3.04c-0.25,0.31-0.53,0.56-0.84,0.81
						c1.01-0.78,2.02-1.56,3.04-2.35c-1.58,1.2-3.47,2-5.29,2.77c1.19-0.5,2.39-1.01,3.58-1.51c-7.1,2.99-14.67,5.13-21.43,8.86
						c-5.06,2.79-9.04,6.9-10.76,12.52c-1.65,5.4,0.41,10.61,2.6,15.45c1.54,3.41,3.89,6.33,6.22,9.23
						c1.76,2.18,3.77,4.16,5.92,5.95c2.81,2.33,5.75,4.63,8.91,6.49c3.14,1.85,6.5,3.25,9.86,4.63c2.19,0.9,4.43,1.68,6.74,2.22
						c10.86,2.58,22.93,1.73,32.44-4.58c5.69-3.77,10.21-8.54,13.85-14.31c3.36-5.32,5.71-11.44,7.33-17.5
						c1.01-3.78,0.47-8.17-1.51-11.56c-1.82-3.11-5.39-6.08-8.96-6.89c-3.84-0.87-8.15-0.69-11.56,1.51
						c-3.27,2.11-5.87,5.12-6.89,8.96c-0.7,2.62-1.55,5.19-2.59,7.69c0.5-1.19,1.01-2.39,1.51-3.58c-1.35,3.16-3,6.18-5.09,8.91
						c0.78-1.01,1.56-2.02,2.35-3.04c-1.49,1.91-3.17,3.65-5.08,5.15c1.01-0.78,2.02-1.56,3.04-2.35c-1.69,1.3-3.51,2.38-5.47,3.21
						c1.19-0.5,2.39-1.01,3.58-1.51c-1.98,0.82-4.04,1.36-6.17,1.65c1.33-0.18,2.66-0.36,3.99-0.54c-2.47,0.31-4.95,0.26-7.42-0.07
						c1.33,0.18,2.66,0.36,3.99,0.54c-3.48-0.49-6.85-1.51-10.09-2.85c1.19,0.5,2.39,1.01,3.58,1.51c-3.9-1.65-7.56-3.7-10.92-6.28
						c1.01,0.78,2.02,1.56,3.04,2.35c-2.83-2.22-5.43-4.72-7.64-7.56c0.78,1.01,1.56,2.02,2.35,3.04c-1.4-1.84-2.61-3.79-3.53-5.91
						c0.5,1.19,1.01,2.39,1.51,3.58c-0.39-0.93-0.8-1.93-0.97-2.93c0.18,1.33,0.36,2.66,0.54,3.99c-0.02-0.16-0.02-0.32,0-0.48
						c-0.18,1.33-0.36,2.66-0.54,3.99c0.03-0.17,0.07-0.32,0.13-0.48c-0.5,1.19-1.01,2.39-1.51,3.58c0.1-0.24,0.23-0.46,0.39-0.67
						c-0.78,1.01-1.56,2.02-2.35,3.04c0.34-0.43,0.74-0.8,1.17-1.14c-1.01,0.78-2.02,1.56-3.04,2.35c1.95-1.51,4.29-2.51,6.54-3.47
						c-1.19,0.5-2.39,1.01-3.58,1.51c5.66-2.38,11.45-4.39,17.1-6.81c1.7-0.73,3.42-1.48,4.95-2.53c2.71-1.86,4.85-3.58,6.56-6.43
						c1.59-2.65,2.45-5.48,2.44-8.59c-0.01-3.85-1.67-7.88-4.39-10.61c-2.6-2.6-6.87-4.56-10.61-4.39
						c-3.88,0.17-7.9,1.45-10.61,4.39C796.18,2484.44,794.47,2488.05,794.47,2492.11L794.47,2492.11z"/>
				</g>
			</g>
			<g>
				<path className="st2" d="M894.79,2059.63c-6.79,2.79,6.6,0.86-14.63,15.22c-14.82,10.02,21.61,13.42,30.37,14.77
					c18.44,2.86,36.16,5,55.26,6.4c19.44,6.73,38.97,6.3,58.85,2.6c8.36-1.56,14.83-6.26,22.75-9.25c4.71-1.77,9.73-1.43,14.63-2.08
					c5.04-0.67,9.97-2.26,14.55-4.44c0.96-0.46,1.92-0.95,2.97-1.1c0.82-0.12,1.66-0.04,2.49-0.01c5.89,0.21,19.65-4.55,16.07-12.81
					c-0.96-2.21-6.2-4.8-7.91-6.5c-1.16-1.15-2.31-1.67-3.74-2.29c-4.36-1.88-8.85-3.43-13.23-5.27c0.68,0.29,1.36,0.57,2.05,0.86
					c-0.68-0.29-1.35-0.58-1.99-0.96c-1.89-1.13-4.48-1.44-6.6-0.86c-1.99,0.55-4.14,2.08-5.12,3.92
					c-2.19,4.13-1.03,9.22,3.07,11.68c2.12,1.27,4.56,2.06,6.87,2.94c2.5,0.95,5.01,1.89,7.48,2.91c-0.68-0.29-1.36-0.57-2.05-0.86
					c0.91,0.38,1.94,0.72,2.78,1.25c-0.58-0.44-1.16-0.89-1.73-1.33c0.06,0.04,0.11,0.09,0.16,0.14c-0.45-0.58-0.89-1.15-1.34-1.73
					c0.04,0.04,0.07,0.1,0.09,0.15c-0.29-0.68-0.58-1.36-0.86-2.04c0.01,0.03,0.01,0.05,0.02,0.08c-0.1-0.76-0.2-1.51-0.31-2.27
					c0.01,0.05,0.01,0.11,0,0.16c0.1-0.76,0.2-1.51,0.31-2.27c-0.01,0.06-0.03,0.12-0.06,0.17c0.29-0.68,0.58-1.36,0.86-2.04
					c-0.03,0.06-0.07,0.12-0.11,0.18c0.45-0.58,0.89-1.15,1.34-1.73c-0.17,0.22-0.38,0.4-0.6,0.57c0.58-0.44,1.16-0.89,1.73-1.33
					c-1.48,1.09-3.23,1.79-4.88,2.57c-2.53,1.2-5,2.5-7.58,3.59c0.68-0.29,1.36-0.57,2.05-0.86c-3.25,1.35-6.59,2.43-10.09,2.91
					c0.76-0.1,1.52-0.2,2.28-0.3c-1.68,0.21-3.36,0.28-5.05,0.17c-1.47-0.1-2.93-0.32-4.41-0.23c-2.7,0.17-5.48,0.74-7.72,2.34
					c-1.15,0.83-2.09,1.58-2.99,2.62c-0.53,0.61-1.04,1.19-1.67,1.69c0.58-0.44,1.16-0.89,1.73-1.33c-0.71,0.53-1.47,0.94-2.28,1.28
					c0.68-0.29,1.36-0.57,2.05-0.86c-2.72,1.08-5.71,1.49-8.56,2.08c-3.31,0.68-6.61,1.37-9.92,2.05
					c-6.42,1.33-12.84-1.01-19.34-0.13c0.76-0.1,1.52,1.29,2.28,1.19c-6.35,0.84-12.77,3.4-19.14,2.57c0.76,0.1,1.52,0.2,2.28,0.3
					c-3.19-0.44-6.28-1.22-9.35-2.17c-2.95-0.91-5.9-1.88-8.96-2.41c-3.7-0.64-7.42-0.94-11.17-1.09c-3.22-0.13-6.44-0.23-9.65-0.64
					c0.76,0.1,1.52,0.2,2.28,0.3c-3.52-0.48-6.97-1.29-10.44-2.05c-3.6-0.8-7.2-1.59-10.8-2.39c-7.23-1.6-14.45-3.37-21.72-4.78
					c-2.54-0.49-5.22-0.95-7.81-0.67c-1.58,0.17-3.11,0.4-4.63,0.84c-0.91,0.27-1.81,0.55-2.76,0.68c0.76-0.1,1.52-0.2,2.28-0.3
					c-0.93,0.11-1.86,0.1-2.78-0.02c0.76,0.1,1.52,0.2,2.28,0.3c-0.86-0.12-1.71-0.33-2.51-0.67c0.68,0.29,1.36,0.57,2.05,0.86
					c-0.43-0.19-0.85-0.41-1.22-0.7c0.58,0.44,1.16,0.89,1.73,1.33c-0.23-0.18-0.43-0.38-0.61-0.61c0.45,0.58,0.89,1.15,1.34,1.73
					c-0.11-0.14-0.19-0.29-0.27-0.46c0.29,0.68,0.58,1.36,0.86,2.04c-0.07-0.16-0.12-0.32-0.15-0.5c0.1,0.76,0.2,1.51,0.31,2.27
					c-0.02-0.18-0.03-0.35-0.01-0.54c-0.1,0.76-0.2,1.51-0.31,2.27c0.03-0.23,0.1-0.45,0.18-0.67c-0.29,0.68-0.58,1.36-0.86,2.04
					c0.13-0.3,0.3-0.57,0.49-0.83c-0.45,0.58-0.89,1.15-1.34,1.73c0.44-0.56,0.96-1.03,1.52-1.47c-0.58,0.44-1.16,0.89-1.73,1.33
					c1.88-1.43,4.04-2.45,6.21-3.37c-0.68,0.29-1.36,0.57-2.05,0.86c0.23-0.09,0.45-0.19,0.68-0.28c1.11-0.26,2.09-0.77,2.91-1.53
					c0.93-0.6,1.67-1.4,2.21-2.39c1.07-1.83,1.53-4.53,0.86-6.58c-0.67-2.07-1.96-4.06-3.94-5.1
					C899.5,2059.49,896.87,2058.77,894.79,2059.63z"/>
			</g>
			<g>
				<g>
					<g>
						<path className="st2" d="M1034.4,2384.84c-4.11,1.31-7.68,3.91-9.77,7.75c-2.05,3.75-2.86,8.56-1.43,12.67
							c0.88,2.53,2.16,4.58,4.3,6.23c2.13,1.64,4.53,2.52,7.27,2.58c16.89,0.37,33.78,0.74,50.67,1.11
							c16.97,0.37,33.95,0.74,50.92,1.12c16.89,0.37,33.78,0.74,50.67,1.11s33.78,0.74,50.67,1.11c3.59,0.08,7.25,0.36,10.83-0.04
							c2.46-0.27,4.98-0.52,7.3-1.43c3.1-1.22,5.89-2.59,8.23-5.07c2.05-2.18,3.88-4.94,4.45-7.93c0.59-3.09,0.7-6.3-0.35-9.32
							c-2.16-6.19-6.86-11.26-13.59-12.17c-2.04-0.27-3.69-0.34-5.79,0.23c-1.4,0.38-2.9,0.95-4.1,1.78
							c-2.61,1.81-4.65,4.03-5.51,7.17c-0.79,2.87-0.43,6.7,1.21,9.25c1.65,2.56,4.11,4.82,7.17,5.51c3.01,0.69,6.61,0.62,9.25-1.21
							c0.22-0.15,0.43-0.28,0.67-0.39c-0.96,0.4-1.91,0.81-2.87,1.21c0.23-0.09,0.44-0.15,0.68-0.2c-1.06,0.14-2.13,0.29-3.19,0.43
							c0.31-0.04,0.59-0.04,0.89,0c-1.06-0.14-2.13-0.29-3.19-0.43c0.23,0.05,0.43,0.1,0.65,0.18c-0.96-0.4-1.91-0.81-2.87-1.21
							c0.37,0.16,0.7,0.36,1.02,0.6c-0.81-0.63-1.62-1.25-2.43-1.88c0.33,0.28,0.62,0.57,0.89,0.9c-0.63-0.81-1.25-1.62-1.88-2.43
							c0.39,0.51,0.69,1.04,0.95,1.63c-0.4-0.96-0.81-1.91-1.21-2.87c0.2,0.52,0.35,1.03,0.44,1.58c-0.14-1.06-0.29-2.13-0.43-3.19
							c0.07,0.54,0.08,1.07,0.01,1.61c0.14-1.06,0.29-2.13,0.43-3.19c-0.06,0.35-0.15,0.69-0.27,1.03c0.4-0.96,0.81-1.91,1.21-2.87
							c-0.15,0.34-0.32,0.64-0.53,0.94c0.63-0.81,1.25-1.62,1.88-2.43c-0.43,0.55-0.9,1.03-1.44,1.47c0.81-0.63,1.62-1.25,2.43-1.88
							c-0.64,0.48-1.32,0.86-2.05,1.18c0.96-0.4,1.91-0.81,2.87-1.21c-1.21,0.49-2.46,0.78-3.75,0.96c1.06-0.14,2.13-0.29,3.19-0.43
							c-2.88,0.36-5.8,0.2-8.69,0.14c-3.49-0.08-6.99-0.15-10.48-0.23c-7.07-0.16-14.14-0.31-21.22-0.47
							c-13.98-0.31-27.96-0.61-41.93-0.92c-28.12-0.62-56.25-1.23-84.37-1.85c-15.81-0.35-31.62-0.69-47.43-1.04
							c3.86,2.94,7.71,5.87,11.57,8.81c-0.11-0.31-0.18-0.6-0.24-0.93c0.14,1.06,0.29,2.13,0.43,3.19c-0.03-0.33-0.03-0.63,0-0.96
							c-0.14,1.06-0.29,2.13-0.43,3.19c0.07-0.43,0.17-0.83,0.32-1.24c-0.4,0.96-0.81,1.91-1.21,2.87c0.18-0.4,0.39-0.76,0.65-1.11
							c-0.63,0.81-1.25,1.62-1.88,2.43c0.21-0.25,0.43-0.47,0.67-0.68c-0.81,0.63-1.62,1.25-2.43,1.88c0.26-0.2,0.52-0.36,0.81-0.51
							c-0.96,0.4-1.91,0.81-2.87,1.21c0.19-0.09,0.4-0.17,0.6-0.22c2.95-0.94,5.58-2.8,7.17-5.51c1.51-2.57,2.14-6.36,1.21-9.25
							c-0.94-2.9-2.74-5.71-5.51-7.17C1040.85,2384.57,1037.49,2383.85,1034.4,2384.84L1034.4,2384.84z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st2" d="M1022.19,2392.26c-8.96,5.26-9.74,17.67-2.96,24.98c5.03,5.43,12.28,6,19.16,6.71
							c10.17,1.04,20.4,1.3,30.62,1.02c21.52-0.58,42.89-3.5,64.18-6.43c11.69-1.61,23.38-3.23,35.07-4.85
							c2.76-0.38,5.84-3.24,7.17-5.51c1.51-2.57,2.14-6.36,1.21-9.25c-0.94-2.9-2.74-5.71-5.51-7.17c-0.96-0.4-1.91-0.81-2.87-1.21
							c-2.13-0.57-4.25-0.57-6.38,0c-14.29,1.98-28.58,3.99-42.88,5.92c1.06-0.14,2.13-0.29,3.19-0.43
							c-22.73,3.05-45.65,5.65-68.63,4.92c-6.46-0.21-12.93-0.64-19.34-1.47c1.06,0.14,2.13,0.29,3.19,0.43
							c-1.39-0.19-2.74-0.46-4.05-0.99c0.96,0.4,1.91,0.81,2.87,1.21c-0.57-0.26-1.09-0.55-1.6-0.92c0.81,0.63,1.62,1.25,2.43,1.88
							c-0.35-0.28-0.66-0.58-0.95-0.92c0.63,0.81,1.25,1.62,1.88,2.43c-0.23-0.32-0.42-0.65-0.59-1.01c0.4,0.96,0.81,1.91,1.21,2.87
							c-0.18-0.47-0.31-0.93-0.38-1.42c0.14,1.06,0.29,2.13,0.43,3.19c-0.03-0.39-0.03-0.76,0.01-1.15
							c-0.14,1.06-0.29,2.13-0.43,3.19c0.06-0.37,0.16-0.71,0.3-1.06c-0.4,0.96-0.81,1.91-1.21,2.87c0.13-0.25,0.26-0.46,0.43-0.68
							c-0.63,0.81-1.25,1.62-1.88,2.43c0.12-0.14,0.25-0.27,0.39-0.38c-0.81,0.63-1.62,1.25-2.43,1.88c0.15-0.12,0.3-0.22,0.47-0.31
							c2.61-1.53,4.71-4.26,5.51-7.17c0.79-2.87,0.43-6.7-1.21-9.25c-1.65-2.56-4.11-4.82-7.17-5.51
							C1028.29,2390.33,1025.03,2390.59,1022.19,2392.26L1022.19,2392.26z"/>
					</g>
				</g>
				<g>
					<path className="st7" d="M1026.65,2404.49c0.83-1.79,2.31-4.75,2.89-6.45c0.97-2.81,1.9-5.66,2.56-8.55
						c0.71-3.16,0.94-6.43,1.65-9.59c0.66-2.94,1.69-5.8,2.56-8.69c0.06,0.02,0.13,0.04,0.19,0.06c-0.03-0.04-0.05-0.11-0.09-0.12
						c-1.55-0.52-1.78-0.87-1.57-2.49c0.27-2.02,1.24-3.52,3.1-4.48c0.94-0.49,1.82-1.17,2.58-1.92c1.3-1.28,2.74-2.12,4.59-2.2
						c0.87-0.04,1.74-0.15,2.61-0.2c1.74-0.1,3.14,0.65,4.28,1.88c0.71,0.77,0.72,1.81,0.56,2.79c-0.16,0.97-0.04,1.8,0.55,2.62
						c0.63,0.87,0.55,1.91,0.41,2.91c-0.4,2.82-0.82,5.63-1.23,8.45c0.09,0.02,0.17,0.04,0.26,0.06c0.23-0.96,0.53-1.91,0.69-2.88
						c0.36-2.19,0.65-4.41,1.88-6.32c1.38-2.15,2.86-4.25,4.32-6.35c0.72-1.04,1.76-1.65,2.93-2.08c0.29-0.11,0.59-0.21,0.99-0.35
						c-0.21-0.31-0.37-0.56-0.54-0.81c-1.55-2.25-0.78-4.77,1.84-5.68c1.56-0.55,3.18-1,4.81-1.27c3.15-0.52,6.31-1.01,9.49-1.26
						c2.49-0.2,5.01-0.06,7.52-0.02c0.42,0.01,0.89,0.18,1.26,0.41c1.28,0.81,2.22,1.95,2.77,3.37c0.45,1.16,0.05,2.17-0.67,2.92
						c1.08,0.42,2.28,0.73,3.3,1.33c1.15,0.66,1.23,2.01,1.47,3.23c1.32-1.16,2.61-2.24,3.84-3.39c0.73-0.68,1.53-1.19,2.51-1.22
						c1.58-0.05,3.16,0.01,4.74,0.05c0.69,0.02,1.37,0.13,2.05,0.12c0.26,0,0.6-0.16,0.77-0.36c0.64-0.74,1.41-1.24,2.39-1.23
						c2,0.02,4,0.11,6.01,0.19c1.74,0.07,3.5,0.02,5.21,0.31c1.18,0.2,2.32,0.81,3.41,1.38c1.12,0.59,1.7,1.61,2.07,2.86
						c1.13,3.85,1.03,7.71,0.47,11.62c-0.48,3.33-0.23,6.67,0.1,10.02c0.15-1.29,0.26-2.59,0.45-3.87c0.17-1.11,0.37-2.23,0.7-3.3
						c0.33-1.08,1.14-1.58,2.28-1.59c1.45-0.01,2.91-0.02,4.36,0c1.43,0.02,2.43,0.82,2.64,2.23c0.14,0.96,0.11,1.95,0.16,2.92
						c0.08,1.64,0.2,3.27,0.24,4.91c0.03,1.4-0.13,2.8-0.06,4.19c0.11,2.37,0.34,4.73,0.53,7.1c0.02,0.28,0.09,0.56,0.24,0.84
						c0.06-0.38,0.09-0.77,0.17-1.15c0.07-0.3,0.1-0.7,0.3-0.87c0.54-0.44,0.5-0.98,0.49-1.57c-0.11-5.74-0.27-11.47-0.3-17.21
						c-0.02-3.11,0.09-6.24,0.3-9.34c0.06-0.91,0.64-1.8,1.02-2.68c0.42-0.99,0.98-1.92,1.32-2.94c0.82-2.47,2.38-3.74,4.98-3.9
						c0.55-0.03,1.1-0.09,1.64-0.13c-0.14-1.98,0.38-3.76,1.17-5.46c0.77-1.66,2.21-2.35,3.99-1.96c1.59,0.36,3.12,0.26,4.69-0.14
						c2.28-0.57,4.59-1.07,6.97-0.86c1.44,0.13,2.86,0.49,4.3,0.63c3.84,0.35,7.68,0.62,11.52,0.95c1.42,0.12,2.92,0.09,4.21,0.59
						c1.23,0.47,2.22,0.56,3.5,0.24c2.92-0.74,5.89,0.03,8.84,0.03c4.06,0.01,8.04,0.7,12.04,1.26c1.93,0.27,3.89,0.38,5.8,0.76
						c0.92,0.18,1.74,0.8,2.61,1.22c0.16,0.08,0.34,0.2,0.5,0.19c2.42-0.24,4.55,0.77,6.73,1.54c2.68,0.95,5.36,1.91,8.03,2.89
						c0.59,0.22,1.15,0.52,1.7,0.84c1.21,0.7,1.89,1.73,1.9,3.17c0.01,1.24,0.05,2.48-0.69,3.58c1.3,0.84,1.92,2.17,2.56,3.47
						c0.22,0.45,0.43,0.66,0.95,0.54c1.46-0.34,2.85-0.06,4.27,0.37c1.41,0.43,2.88,0.68,4.33,0.94c3.29,0.59,4.31,2.63,4.3,6.18
						c0,0.94-0.38,1.91-0.73,2.81c-0.5,1.27-1.16,2.49-1.71,3.74c-0.21,0.48-0.44,1-0.43,1.51c0.05,3.91,0.1,7.79,2.22,11.33
						c0.82,1.36,1.31,2.93,1.85,4.44c0.2,0.57,0.67,1.67,0.67,1.67l0,0l0,0l0.22,1.63l0,0l0,0c-2.57,0.28-7.41,0.09-9.89-0.44
						c-1.28-0.27-2.61-0.31-3.92-0.42c-2.63-0.22-5.26-0.38-7.88-0.64c-1.68-0.17-3.36-0.14-4.94,0.38
						c-2.04,0.67-4.16,0.68-6.25,0.94c-2.63,0.32-5.2,0.11-7.73-0.77c-1.49-0.52-3.03-0.26-4.54,0.06c-1.39,0.3-2.79,0.58-4.19,0.83
						c-0.45,0.08-1.03,0.32-1.24-0.34c-0.79,0-1.54,0-2.3,0c0.01,0.07,0.02,0.13,0.03,0.2c-0.32,0.07-0.64,0.18-0.96,0.19
						c-0.52,0.01-1.05-0.09-1.58-0.06c-3.3,0.19-6.59,0.46-9.89,0.6c-1.26,0.05-2.53-0.23-3.79-0.2c-2.08,0.04-4.16,0.22-6.25,0.31
						c-0.87,0.04-1.75,0.07-2.61-0.02c-0.69-0.07-1.35-0.36-2.04-0.51c-0.53-0.11-1.07-0.24-1.6-0.22
						c-1.36,0.05-2.72,0.18-4.09,0.28c-1.65,0.12-3.31,0.23-4.96,0.36c-2.49,0.2-4.91,0-7.13-1.27c-0.64-0.37-1.21-0.27-1.81,0
						c-1.48,0.66-2.98,1.22-4.64,1.2c-1.79-0.02-3.58,0.04-5.37-0.02c-0.61-0.02-1.24-0.21-1.82-0.42c-0.54-0.2-1.04-0.51-1.55-0.79
						c-0.71-0.4-1.37-0.82-2.26-0.38c-0.37,0.18-0.87,0.08-1.32,0.11c-0.59,1.43-0.88,1.65-2.49,1.6c-1.56-0.05-3.13-0.09-4.66-0.32
						c-0.83-0.13-1.59-0.64-2.38-1.01c-0.58-0.27-1.01-0.47-1.69,0.04c-0.54,0.41-1.44,0.45-2.18,0.46
						c-2.5,0.06-5.01-0.01-7.51,0.05c-1.47,0.03-2.9,0-4.27-0.63c-0.23-0.11-0.64-0.16-0.81-0.03c-1.53,1.15-3.26,0.95-4.96,0.78
						c-1.67-0.17-3.33-0.54-5.01-0.69c-0.78-0.07-1.58,0.23-2.38,0.3c-1.31,0.11-2.63,0.18-3.95,0.26c-0.08,0-0.17,0.02-0.24-0.01
						c-1.37-0.72-2.87-0.48-4.31-0.53c-2.61-0.1-5.22-0.08-7.83,0.06c-2.95,0.16-5.89,0.55-8.86,0.22c-1.34-0.15-2.7-0.32-4.04-0.32
						c-0.73,0-1.45,0.42-2.19,0.52c-3.32,0.47-6.63,0.98-10.01,0.54c-1.24-0.16-2.53,0.05-3.79,0.04c-1.35-0.01-2.7-0.02-4.03-0.17
						c-0.97-0.11-1.94-0.41-2.24-1.59c-1.12,0.29-2.21,0.58-3.3,0.86c-0.1,0.03-0.21,0.07-0.31,0.07
						c-3.74-0.24-7.45,0.76-11.22,0.19c-1.68-0.25-3.46,0.14-5.19,0.22c-0.6,0.03-1.24,0.12-1.8-0.03 M1167.67,2384.54
						c0.09-0.01,0.18-0.01,0.28-0.02c0-0.61-0.01-1.21,0-1.82c0.04-2.17-0.58-4.22-1.09-6.3c-0.96-3.89-1.35-7.9-2.68-11.71
						c-0.58-1.66-1.34-3.15-2.49-4.45c-0.92-1.04-2.06-1.79-3.46-1.91c-3.13-0.26-6.26-0.5-9.4-0.62c-1.94-0.07-3.32,1.6-3.1,3.56
						c0.12,1.04,0.07,2.03-0.24,3.04c-0.17,0.58-0.16,1.27-0.04,1.87c0.23,1.1,0.14,2.13-0.16,3.2c-0.14,0.51-0.02,1.12,0.1,1.67
						c0.08,0.4,0.33,0.77,0.51,1.15c0.52,1.12,0.44,2.16-0.22,3.23c-0.48,0.78-1.02,1.62-1.15,2.49c-0.27,1.82-0.49,3.68-0.39,5.51
						c0.29,5.4,0.74,10.8,1.14,16.2c0.1,1.29,0.2,2.58,0.44,3.85c0.17,0.92,0.58,1.06,1.48,0.97c0.78-0.09,1.58-0.1,2.37-0.09
						c1,0.01,2.02,0.22,2.99,0.06c1.18-0.2,2.33-0.64,3.45-1.06c0.93-0.35,1.8-0.4,2.66,0.09c2.03,1.17,4.24,1.37,6.5,1.1
						c1.18-0.14,2.33-0.44,3.51-0.05c0.54,0.18,0.63-0.24,0.61-0.64c-0.11-2.19-0.16-4.39-0.36-6.57
						C1168.57,2393.04,1168.11,2388.79,1167.67,2384.54z M1126.4,2392.17c0.81-0.91,1.19-1.71,1.14-2.65
						c-0.13-2.88-0.37-5.75-0.42-8.63c-0.05-2.74-0.13-5.52,0.19-8.23c0.45-3.72,0.52-7.35-0.55-10.98
						c-0.28-0.95-0.68-1.77-1.53-2.21c-1.08-0.57-2.22-1.21-3.4-1.38c-1.95-0.29-3.95-0.25-5.92-0.34c-1.48-0.07-2.95-0.14-4.43-0.2
						c-0.73-0.03-1.22,0.37-1.44,1.01c-0.18,0.51-0.28,1.07-0.32,1.61c-0.07,1.05,0.1,2.15-0.15,3.16c-0.5,2.03-0.41,3.86,1.09,5.45
						c0.02,0.02,0.02,0.05,0.04,0.07c0.63,1.2,0.47,2.05-0.68,2.78c-0.81,0.52-1.33,1.17-1.52,2.11c-0.07,0.33-0.2,0.66-0.36,0.96
						c-1.73,3.27-1.96,6.8-1.83,10.4c0.07,2.03,0.16,4.07,0.22,6.1c0.11,3.7,0.18,7.39,0.34,11.09c0.05,1.22,0.4,1.73,1.59,1.68
						c0.39-0.02,0.79,0.06,1.19,0.08c1.03,0.05,2.07,0.12,2.97-0.56c0.69-0.52,1.39-0.45,2.17-0.18c0.74,0.26,1.52,0.53,2.29,0.54
						c3.11,0.04,6.23-0.02,9.34-0.06c1.53-0.02,2.1-0.66,1.98-2.18c-0.14-1.84-0.29-3.69-0.44-5.53c-0.09-1.06-0.39-2.1-1.23-2.75
						c-1.49-1.16-2.32-2.68-2.9-4.42c-0.39-1.15-1.12-2.04-2.23-2.67c-0.68-0.38-1.33-0.95-1.79-1.59c-0.61-0.85-1.05-1.83-1.5-2.78
						c-0.11-0.24,0.03-0.59,0.05-0.89c0.23,0.17,0.53,0.29,0.66,0.51c0.54,0.93,0.98,1.94,1.56,2.84c0.34,0.52,0.9,0.89,1.55,1.51
						c0.55-1.16,0.97-2.08,1.43-2.99c0.07-0.13,0.4-0.27,0.49-0.22c0.16,0.09,0.36,0.39,0.31,0.51c-0.26,0.67-0.48,1.42-0.94,1.95
						c-0.43,0.49-0.43,0.82-0.16,1.31c0.59,1.08,1.15,2.18,1.73,3.26C1125.43,2390.48,1125.89,2391.26,1126.4,2392.17z
						 M1198.18,2375c0-0.57,0.07-1.02-0.01-1.43c-0.61-2.87-1.2-5.75-1.16-8.69c0.04-3.09-3.44-5.83-5.61-5.84
						c-3.09-0.02-6.18,0.05-9.26,0.05c-1.81,0-2.79,1.06-3.41,2.58c-0.91,2.22-0.85,4.52-0.57,6.85c0.21,1.77,0.4,3.58,0.3,5.35
						c-0.16,2.79,0.11,5.47,1.17,8.06c1.73,4.21,1.86,8.62,1.34,13.02c-0.34,2.89-0.02,5.68,0.34,8.5c0.12,0.99,0.64,1.22,1.61,1.16
						c1.63-0.1,3.26-0.12,4.9-0.12c1.11,0,2.22,0.22,3.32,0.16c3.11-0.16,6.22-0.4,9.32-0.65c0.28-0.02,0.66-0.31,0.79-0.58
						c0.57-1.18,0.66-2.46,0.43-3.72c-0.32-1.76-0.74-3.5-1.17-5.24c-1.24-5.04-1.53-10.17-1.61-15.32
						c-0.01-0.76-0.15-1.52-0.3-2.26c-0.13-0.65-0.42-1.19-1.21-1.26c-0.22-0.02-0.46-0.17-0.61-0.34
						c-0.12-0.12-0.11-0.35-0.17-0.53c0.18-0.05,0.38-0.17,0.55-0.14C1197.44,2374.68,1197.72,2374.83,1198.18,2375z
						 M1093.93,2367.1c0.1-0.01,0.2-0.02,0.3-0.03c-0.23-1.75-0.42-3.5-0.69-5.24c-0.18-1.15-0.92-1.9-2.03-2.19
						c-0.66-0.17-1.36-0.34-2.02-0.28c-2,0.2-3.95,0.02-5.86-0.59c-0.36-0.12-0.9-0.12-1.2,0.07c-1.14,0.7-1.87,1.76-1.86,3.12
						c0.02,3.25,0.09,6.49,0.23,9.73c0.07,1.68-0.13,3.24-1.34,4.49c-1.16,1.2-1.7,2.64-1.91,4.25c-0.36,2.77-1.01,5.42-2.61,7.8
						c-0.93,1.38-1.14,3.04-1.07,4.72c0.03,0.76,0.09,1.55-0.04,2.29c-0.41,2.34-0.01,4.63,0.23,6.94c0.09,0.83,0.5,1.12,1.29,1.26
						c2.96,0.53,5.9,0.12,8.85-0.08c3.11-0.21,6.21-0.6,9.32,0.05c0.68,0.14,1.19-0.12,1.31-0.8c0.17-0.95,0.37-1.93,0.33-2.88
						c-0.09-2.27-0.4-4.53-0.44-6.79c-0.02-1.63,0.41-3.26,0.37-4.89c-0.09-4.14-0.31-8.28-0.54-12.42
						C1094.4,2372.78,1094.14,2369.95,1093.93,2367.1z M1070.91,2376.96c-0.12-1.43-0.16-2.65-0.33-3.85
						c-0.32-2.3-0.73-4.58-1.08-6.88c-0.12-0.78-0.1-1.59-0.26-2.36c-0.4-1.89-1.71-3-3.62-2.79c-1.08,0.12-2.13,0.67-3.17,1.09
						c-1.48,0.61-2.21,1.77-2.42,3.35c-0.17,1.31-0.73,2.45-1.69,3.41c-0.43,0.43-0.8,1.02-0.97,1.6c-0.75,2.58-1.49,5.16-2.08,7.78
						c-0.42,1.86-0.79,3.72-1.72,5.4c-0.85,1.54-1.01,3.16-0.98,4.89c0.04,2.29-0.03,4.59-0.25,6.87c-0.22,2.23-0.66,4.43-0.38,6.69
						c0.18,1.48,0.82,2.2,2.27,2.21c2.43,0.01,4.86,0,7.28,0.01c0.92,0.01,1.85,0.16,2.77,0.08c1.78-0.17,3.56-0.45,5.34-0.7
						c1.31-0.18,1.37-0.2,1.38-1.52c0.01-2.48,0.05-4.97-0.1-7.45c-0.08-1.26-0.6-2.49-0.84-3.76c-0.16-0.84-0.19-1.71-0.24-2.56
						c-0.02-0.31,0.07-0.63,0.1-0.94c0.17-2.08,0.31-4.16,0.51-6.23C1070.56,2379.79,1070.76,2378.28,1070.91,2376.96z
						 M1231.5,2393.74c-0.15-4.32-1.08-8.58-1.97-12.83c-0.9-4.28-2.2-8.47-3.28-12.7c-0.5-1.95-0.95-3.91-2.33-5.48
						c-0.26-0.3-0.63-0.6-1.01-0.69c-1-0.24-2.01-0.43-3.03-0.53c-1.3-0.12-2.62-0.2-3.92-0.16c-0.99,0.03-1.6,0.75-1.66,1.76
						c-0.02,0.36,0.02,0.74,0.08,1.1c0.41,2.52,0.86,5.04,1.24,7.57c0.21,1.43,0.2,2.9,0.5,4.3c0.28,1.29,0.39,2.53-0.21,3.71
						c-1.02,2.02-1.06,4.08-0.59,6.24c0.32,1.47,0.14,2.92-0.93,4.09c-0.84,0.93-1.03,2.01-0.85,3.17c0.14,0.88,0.44,1.74,0.57,2.62
						c0.17,1.08,0.26,2.18,0.34,3.27c0.08,1.1,0.07,2.21,0.16,3.31c0.02,0.2,0.23,0.5,0.42,0.56c0.82,0.27,1.67,0.48,2.51,0.69
						c1.8,0.46,3.62,0.38,5.43,0.15c1.91-0.24,3.81-0.52,5.71-0.81c0.86-0.13,1.7-0.39,2.55-0.45c1.71-0.12,1.97-0.45,1.43-2.06
						c-0.05-0.15-0.1-0.3-0.16-0.45C1231.73,2398.1,1231.13,2396.03,1231.5,2393.74z M1027.83,2403.74c0.91,0.27,1.8,0.12,2.48-0.43
						c1.56-1.26,2.96-2.65,3.6-4.69c0.57-1.81,0.72-3.79,1.94-5.37c0.05-0.06,0.09-0.15,0.08-0.22c-0.14-1.38,1.05-2.27,1.31-3.51
						c0.03-0.12,0.28-0.27,0.4-0.26c0.13,0.02,0.35,0.23,0.34,0.34c-0.02,0.3-0.09,0.62-0.22,0.9c-0.27,0.59-0.63,1.15-0.88,1.75
						c-0.28,0.67-0.5,1.37-0.73,2.06c-0.19,0.59-0.4,1.17-0.53,1.77c-0.09,0.43-0.05,0.9-0.1,1.34c-0.19,1.86-0.41,3.72-0.57,5.59
						c-0.02,0.22,0.23,0.66,0.37,0.67c1.52,0.09,3.05,0.16,4.58,0.13c1.13-0.02,2.27-0.36,3.38-0.28c1.97,0.14,3.84-0.15,5.65-0.9
						c0.21-0.09,0.43-0.42,0.44-0.65c0.06-1.19-0.03-2.38,0.07-3.56c0.2-2.18,0.39-4.36,0.79-6.51c0.25-1.37,0.44-2.7,0.2-4.07
						c-0.26-1.5-0.24-2.99,0.25-4.46c0.24-0.72,0.55-1.45,0.64-2.19c0.5-3.87,0.94-7.75,1.4-11.63c0.11-0.88,0.06-1.83-0.63-2.35
						c-0.75-0.57-1.73-0.84-2.61-1.21c-0.11-0.04-0.29,0.09-0.44,0.13c-0.69,0.21-1.37,0.55-2.07,0.61
						c-1.98,0.15-3.54,0.9-4.58,2.65c-0.42,0.72-1.11,1.34-1.34,2.11c-0.42,1.35-0.56,2.78-0.86,4.16c-0.21,0.98-0.48,1.95-0.78,2.9
						c-0.06,0.19-0.4,0.29-0.61,0.43c-0.06-0.23-0.14-0.46-0.17-0.7c-0.01-0.12,0.09-0.25,0.12-0.37c0.32-1.68,0.67-3.36,0.95-5.04
						c0.12-0.7-0.25-1.17-0.96-1.32c-1.22-0.27-1.45-0.24-1.8,0.66c-0.57,1.47-1,2.99-1.54,4.47c-1.16,3.21-1.48,6.59-1.97,9.92
						c-0.63,4.18-1.75,8.19-3.26,12.11C1029.55,2400.4,1028.66,2402,1027.83,2403.74z M1244.73,2403.21
						c-0.03-0.44-0.04-0.83-0.07-1.22c-0.12-1.6-0.33-3.2-0.34-4.81c-0.03-2.63-0.26-5.23-1.01-7.75
						c-1.15-3.84-1.84-7.72-1.94-11.75c-0.09-3.3-1.36-6.32-2.91-9.2c-0.71-1.31-1.82-1.86-3.28-1.72
						c-0.89,0.08-1.79,0.15-2.67,0.27c-1.13,0.16-2.34,1.85-1.95,2.8c0.43,1.05,0.42,2.1,0.36,3.19c-0.04,0.63-0.03,1.27,0.06,1.89
						c0.26,1.77,0.55,3.54,0.87,5.3c0.38,2.11,0.63,4.19,0.08,6.34c-0.23,0.9-0.07,1.92-0.02,2.88c0.06,1.26,0.3,2.53,0.26,3.78
						c-0.06,2.24,0.16,4.41,0.99,6.52c0.33,0.83,0.44,1.76,0.68,2.75C1237.28,2403.3,1240.94,2403.31,1244.73,2403.21z
						 M1240.6,2371.16c0.06,0.21,0.1,0.36,0.16,0.51c1.04,2.55,1.45,5.21,1.49,7.96c0.04,2.51,0.53,4.97,1.17,7.41
						c0.63,2.39,1.19,4.81,1.49,7.26c0.35,2.82,0.4,5.68,0.56,8.53c0.03,0.49,0.15,0.76,0.69,0.84c0.86,0.13,1.7,0.33,2.55,0.53
						c2.07,0.48,4.12,0.12,6.25-0.01c-0.59-2.29-1.21-4.45-2.28-6.48c-1.32-2.5-2.62-5.02-2.27-8.01c0.04-0.31-0.06-0.63-0.07-0.95
						c-0.02-1.21-0.27-2.47,0.02-3.6c0.4-1.54,1.12-3.01,1.86-4.43c0.9-1.72,1.04-3.46,0.61-5.3c-0.36-1.55-1.26-2.57-2.87-2.91
						c-1.83-0.39-3.68-0.72-5.47-1.26C1243.2,2370.84,1241.97,2370.86,1240.6,2371.16z M1187.95,2354.81c0.02,0,0.03,0,0.05,0
						c0-0.19,0-0.37,0-0.55c0.04-2.2-0.52-2.93-2.72-3.16c-2.26-0.24-4.53-0.31-6.79-0.5c-2.63-0.22-5.26-0.45-7.88-0.74
						c-1.57-0.17-3.13-0.68-4.69-0.64c-2.01,0.05-4.04,0.37-6.02,0.8c-1.79,0.39-3.51,0.6-5.31,0.19c-1.37-0.31-2.52,0.2-3.02,1.44
						c-0.52,1.29-0.81,2.66-1.15,4.02c-0.11,0.43,0.07,0.75,0.64,0.71c1.21-0.09,2.43-0.17,3.64-0.18c1.68-0.01,3.37,0.01,5.05,0.1
						c2.31,0.12,4.62,0.45,6.93,0.46c2.24,0.01,4.48-0.27,6.71-0.44c2.59-0.2,5.1,0.39,7.63,0.72c2.02,0.27,4.03,0.56,6.07,0.25
						c0.57-0.09,0.88-0.28,0.85-0.89C1187.93,2355.86,1187.95,2355.33,1187.95,2354.81z M1106.7,2366.84c0-0.37,0.05-0.75-0.02-1.11
						c-0.04-0.2-0.26-0.5-0.42-0.52c-2.61-0.26-4.97,0.36-7.01,2.06c-0.17,0.14-0.33,0.37-0.38,0.59c-0.32,1.38-0.75,2.76-0.88,4.17
						c-0.19,2.07-0.08,4.17-0.25,6.24c-0.26,3.2,0.1,6.33,0.95,9.4c0.55,1.97,0.93,3.97,0.34,5.93c-0.47,1.57-1.28,3.06-2.09,4.5
						c-0.53,0.93-1.04,1.78-0.95,2.91c0.05,0.53-0.12,1.11-0.31,1.62c-0.21,0.58-0.01,0.92,0.52,0.99c0.7,0.09,1.41,0.07,2.12,0.07
						c0.39,0,0.79-0.03,1.18-0.08c1.22-0.17,2.44-0.34,3.66-0.55c0.17-0.03,0.44-0.23,0.44-0.36c0.08-1.42,0.2-2.84,0.16-4.26
						c-0.15-5.33-0.37-10.67-0.52-16c-0.08-2.8,0.29-5.57,1.69-8.05C1106.29,2372.03,1106.49,2369.44,1106.7,2366.84z
						 M1192.99,2351.15c-1.13,0.17-2.43,0.34-3.72,0.6c-0.22,0.04-0.52,0.43-0.53,0.66c-0.03,1.18,0.05,2.37,0.05,3.55
						c0,0.5,0.16,0.82,0.64,0.95c0.33,0.09,0.67,0.15,1.01,0.18c2.39,0.25,4.8,0.35,7.16,0.76c4.65,0.79,9.27,1.77,13.91,2.62
						c0.92,0.17,1.88,0.16,2.82,0.13c1.21-0.04,2.42-0.34,3.61-0.26c2.59,0.17,2.59,0.24,2.37-2.36c-0.18-2.2-1.4-3.74-3.46-4.33
						c-0.55-0.16-1.13-0.3-1.7-0.36c-2.25-0.26-4.55-0.29-6.76-0.76C1203.36,2351.45,1198.23,2351.85,1192.99,2351.15z
						 M1130.92,2399.9c0.8,0.11,1.69,0.24,2.57,0.36c0.34,0.05,0.7,0.17,1.01,0.09c1.3-0.29,2.6-0.63,3.88-1
						c0.17-0.05,0.4-0.35,0.39-0.53c-0.06-1.26-0.2-2.52-0.26-3.79c-0.12-2.58-0.19-5.16-0.29-7.74c-0.14-3.51-0.24-7.01-0.44-10.51
						c-0.06-1.06-0.76-1.55-1.83-1.57c-1.27-0.03-2.54-0.05-3.8-0.06c-1.25-0.01-1.59,0.37-1.47,1.63c0.02,0.21,0.07,0.42,0.09,0.63
						c0.11,2.08,0.5,4.19,0.25,6.23c-0.35,2.93-0.87,5.84-0.8,8.81c0.03,1.41-0.11,2.84,0.01,4.24
						C1130.31,2397.76,1130.67,2398.8,1130.92,2399.9z M1080.57,2352.34c0,0,0,0.01,0,0.01c-0.5,0-1.01-0.05-1.5,0.01
						c-2.85,0.35-5.72,0.65-8.55,1.13c-1.86,0.31-3.72,0.79-5.51,1.39c-2.22,0.74-2.77,2.64-1.44,4.53c0.05,0.06,0.1,0.12,0.14,0.19
						c0.18,0.39,0.46,0.43,0.84,0.28c0.54-0.21,1.09-0.46,1.65-0.53c2.57-0.32,5.14-0.62,7.71-0.85c2.23-0.2,4.46-0.15,6.65-0.85
						c0.88-0.28,2.01-0.13,2.94,0.14c1.62,0.47,3.23,0.51,4.86,0.28c0.87-0.12,1.54-1,1.48-1.87c-0.12-1.79-2.43-3.89-4.2-3.86
						C1083.95,2352.36,1082.26,2352.34,1080.57,2352.34z M1206.23,2389.16c0.26-1.99,0.4-3.97,0.8-5.89
						c0.52-2.51,0.49-4.98-0.08-7.46c-0.9-3.9-0.81-7.85-0.71-11.85c-2.77-0.15-5.65-0.3-8.55-0.45c0.04,0.54,0.05,1.02,0.11,1.48
						c0.31,2.41,0.63,4.81,0.96,7.22c0.07,0.52,0.28,0.83,0.92,0.78c0.79-0.06,1.36,0.38,1.63,1.13c0.18,0.5,0.3,1.01,0.47,1.51
						c0.4,1.17,0.82,2.34,1.23,3.51c0.65,1.86,0.83,3.74,0.45,5.69c-0.14,0.73-0.23,1.57,0.6,1.96
						C1205.11,2387.29,1205.69,2388.17,1206.23,2389.16z M1175.61,2403.88c-0.06-0.67-0.15-1.34-0.18-2.02
						c-0.13-2.61-0.2-5.21-0.75-7.78c-0.41-1.93-0.64-3.94-0.19-5.9c0.66-2.85,0.47-5.66,0.01-8.49c-0.14-0.89-1.35-1.42-2.07-0.81
						c-1.02,0.87-1.79,1.89-1.93,3.33c-0.25,2.47-0.59,4.92-0.92,7.38c-0.26,1.94-0.41,3.87-0.04,5.83c0.2,1.08,0.28,2.2,0.35,3.3
						c0.09,1.48,0.09,2.96,0.19,4.43c0.08,1.21,0.13,1.27,1.32,1.2C1172.78,2404.26,1174.15,2404.05,1175.61,2403.88z
						 M1069.81,2362.82c0.07,0.28,0.21,0.68,0.27,1.09c0.2,1.49,0.36,2.98,0.55,4.47c0.34,2.72,0.88,5.43,0.96,8.16
						c0.07,2.33-0.43,4.67-0.63,7c-0.14,1.58-0.12,3.17-0.32,4.74c-0.14,1.07,0.01,2.03,0.36,3.02c0.27,0.76,0.6,1.56,0.63,2.35
						c0.12,2.93,0.16,5.86,0.16,8.79c0,0.75,0.26,0.95,0.92,0.87c0.22-0.03,0.44-0.08,0.59-0.11c-0.33-2.87-0.67-5.67-0.96-8.47
						c-0.17-1.64-0.68-3.24-0.3-4.94c0.72-3.19,1.29-6.41,2.01-9.6c0.42-1.87,0.78-3.78,2.05-5.38c-0.74-1.04-0.94-2.21-0.8-3.46
						c0.16-1.46,0.29-2.93,0.42-4.39c0.08-0.92,0.11-1.85,0.18-2.77c0.06-0.75,0.14-1.5,0.21-2.35
						C1073.95,2362.18,1071.89,2362.5,1069.81,2362.82z M1163.7,2361.34c1.79,3.62,2.6,7.64,3.3,11.59c0.88-0.1,1.66-0.29,2.44-0.26
						c1.6,0.06,3.19,0.23,4.87,0.37c-0.08-4.13,0.07-8.11,1.15-12.2C1171.48,2361.01,1167.58,2361.18,1163.7,2361.34z
						 M1221.1,2360.89c0.22,0.03,0.49,0.04,0.73,0.11c2.56,0.73,5.13,1.42,7.66,2.22c1.48,0.47,2.91,1.1,4.36,1.66
						c0.77,0.3,1.53,0.64,2.33,0.86c0.24,0.07,0.71-0.14,0.85-0.36c1.02-1.63,0.32-4.32-1.34-5.27c-0.64-0.36-1.32-0.67-2.01-0.92
						c-2.82-1.04-5.64-2.07-8.48-3.05c-1.61-0.56-3.19-1.27-4.91-1.09c0.27,0.94,0.65,1.85,0.78,2.79
						C1221.21,2358.8,1221.1,2359.81,1221.1,2360.89z M1051.62,2365.95c0.03-0.49,0.04-0.88,0.08-1.27
						c0.22-2.05-0.14-2.77-2.02-3.61c-0.56-0.25-1.22-0.33-1.84-0.34c-0.89-0.02-1.79,0.11-2.69,0.16c-1.49,0.08-2.7,0.67-3.79,1.73
						c-0.9,0.87-1.9,1.73-3,2.28c-1.66,0.82-2.38,2.17-2.72,3.83c-0.07,0.36,0.06,0.77,0.1,1.15c0.39-0.09,0.79-0.16,1.17-0.29
						c0.24-0.08,0.47-0.25,0.67-0.41c1.6-1.23,3.29-2.3,5.32-2.67c0.15-0.03,0.29-0.14,0.44-0.18c0.86-0.18,1.71-0.41,2.58-0.51
						c0.99-0.12,2.03,0.21,2.96-0.52c0.27-0.21,0.86-0.12,1.26-0.01C1050.64,2365.41,1051.09,2365.7,1051.62,2365.95z
						 M1107.69,2359.26c-2.25-0.11-4.58-0.64-6.91-0.17c-0.67,0.14-1.28,0.67-1.86,1.09c-0.67,0.49-1.26,1.1-1.92,1.59
						c-1.02,0.76-1.93,1.63-2.13,2.92c-0.14,0.87-0.03,1.77-0.03,2.86c0.92-0.63,1.69-1.12,2.42-1.65c1.8-1.3,3.78-1.93,6.02-1.7
						c0.97,0.1,1.95,0.12,2.92,0.2c0.42,0.03,0.61-0.15,0.68-0.57C1107.12,2362.33,1107.4,2360.84,1107.69,2359.26z M1168.87,2378.7
						c1.85-0.31,3.59-0.6,5.44-0.91c0.04-1,0.12-2.23,0.13-3.47c0-0.19-0.28-0.55-0.44-0.55c-0.94-0.03-1.91-0.07-2.83,0.08
						c-0.45,0.07-0.94,0.48-1.23,0.87C1169.1,2375.86,1169.03,2377.24,1168.87,2378.7z M1135.98,2404.6c0-0.01,0-0.02,0-0.03
						c0.58,0,1.16,0.01,1.74,0c0.56-0.02,0.77-0.35,0.74-0.89c-0.05-0.94-0.61-1.55-1.42-1.8c-1.1-0.34-2.25-0.5-3.37-0.75
						c-0.65-0.15-1.19,0.06-1.51,0.59c-0.46,0.78-0.42,2.07,0.02,2.46c0.14,0.12,0.37,0.19,0.57,0.21
						C1133.82,2404.46,1134.9,2404.52,1135.98,2404.6z M1136.62,2400.58c0.7,0.53,1.55,1.04,2.23,1.72
						c0.58,0.58,1.36,0.91,2.11,0.53c0.14-0.07,0.33-0.24,0.34-0.37c0.03-0.99,0.02-1.98,0.02-2.9
						C1139.56,2399.94,1137.99,2400.28,1136.62,2400.58z M1140.21,2398.77c0.06,0.04,0.12,0.08,0.18,0.13
						c0.26-0.2,0.75-0.41,0.75-0.61c0.01-0.99-0.11-1.99-0.19-2.99c-0.07,0-0.15,0-0.22,0
						C1140.56,2396.45,1140.39,2397.61,1140.21,2398.77z"/>
					<path className="st7" d="M1167.95,2384.52c0.43,4.25,0.62,8.52,1,12.77c0.2,2.18,0.25,4.38,0.36,6.57c0.02,0.4-0.07,0.81-0.61,0.64
						c-1.19-0.39-2.33-0.09-3.51,0.05c-2.26,0.27-4.46,0.07-6.5-1.1c-0.86-0.5-1.74-0.44-2.66-0.09c-1.13,0.42-2.28,0.87-3.45,1.06
						c-0.97,0.16-1.99-0.05-2.99-0.06c-0.79-0.01-1.59,0.01-2.37,0.09c-0.9,0.1-1.31-0.04-1.48-0.97c-0.23-1.27-0.34-2.56-0.44-3.85
						c-0.41-5.4-0.85-10.79-1.14-16.2c-0.1-1.83,0.11-3.69,0.39-5.51c0.13-0.87,0.67-1.71,1.15-2.49c0.66-1.07,0.73-2.11,0.22-3.23
						c-0.18-0.38-0.43-0.75-0.51-1.15c-0.11-0.54-0.24-1.15-0.1-1.67c0.3-1.07,0.39-2.1,0.16-3.2c-0.13-0.6-0.14-1.29,0.04-1.87
						c0.31-1.01,0.36-2,0.24-3.04c-0.23-1.96,1.16-3.63,3.1-3.56c3.14,0.11,6.27,0.36,9.4,0.62c1.4,0.12,2.54,0.87,3.46,1.91
						c1.15,1.3,1.92,2.79,2.49,4.45c1.33,3.81,1.72,7.82,2.68,11.71c0.51,2.08,1.13,4.13,1.09,6.3
						C1167.94,2383.31,1167.95,2383.91,1167.95,2384.52C1167.86,2384.53,1168.04,2384.51,1167.95,2384.52z"/>
					<path className="st7" d="M1122.13,2385.85"/>
					<path className="st7" d="M1127.62,2394.51c0,0,0.24,0.52,0.33,1.58c0.15,1.84,0.29,3.69,0.44,5.53c0.12,1.52-0.45,2.16-1.98,2.18
						c-3.11,0.04-6.23,0.1-9.34,0.06c-0.77-0.01-1.55-0.28-2.29-0.54c-0.78-0.27-1.48-0.34-2.17,0.18
						c-0.89,0.68-1.94,0.61-2.97,0.56c-0.4-0.02-0.79-0.1-1.19-0.08c-1.2,0.05-1.54-0.46-1.59-1.68c-0.16-3.69-0.23-7.39-0.34-11.09
						c-0.06-2.03-0.15-4.07-0.22-6.1c-0.13-3.6,0.1-7.13,1.83-10.4c0.16-0.3,0.3-0.63,0.36-0.96c0.19-0.94,0.7-1.59,1.52-2.11
						c1.15-0.73,1.31-1.58,0.68-2.78c-0.01-0.02-0.02-0.05-0.04-0.07c-1.5-1.59-1.58-3.42-1.09-5.45c0.25-1,0.08-2.1,0.15-3.16
						c0.04-0.54,0.14-1.1,0.32-1.61c0.22-0.65,0.71-1.04,1.44-1.01c1.48,0.06,2.95,0.13,4.43,0.2c1.98,0.09,3.98,0.04,5.92,0.34
						c1.18,0.18,2.31,0.82,3.4,1.38c0.84,0.44,1.25,1.26,1.53,2.21c1.07,3.63,1,7.26,0.55,10.98c-0.33,2.71-0.24,5.49-0.19,8.23
						c0.05,2.88,0.29,5.75,0.42,8.63c0.04,0.94,0.38,2.79,0.06,3.45"/>
					<path className="st7" d="M1198.59,2376.89c0.15,0.75,0.29,1.51,0.3,2.26c0.08,5.16,0.38,10.29,1.61,15.32
						c0.43,1.74,0.85,3.48,1.17,5.24c0.23,1.26,0.14,2.54-0.43,3.72c-0.13,0.27-0.5,0.56-0.79,0.58c-3.11,0.25-6.21,0.49-9.32,0.65
						c-1.1,0.06-2.21-0.15-3.32-0.16c-1.63-0.01-3.27,0.02-4.9,0.12c-0.97,0.06-1.49-0.17-1.61-1.16c-0.35-2.82-0.68-5.61-0.34-8.5
						c0.52-4.4,0.39-8.81-1.34-13.02c-1.06-2.59-1.33-5.27-1.17-8.06c0.1-1.77-0.09-3.58-0.3-5.35c-0.28-2.34-0.34-4.63,0.57-6.85
						c0.62-1.52,1.6-2.58,3.41-2.58c3.09,0,6.18-0.07,9.26-0.05c2.16,0.02,5.65,2.75,5.61,5.84c-0.04,2.94,0.56,5.82,1.16,8.69
						c0.09,0.41,0.01,0.86,0.01,1.43"/>
					<path className="st7" d="M1093.93,2367.1c0.21,2.84,0.47,5.68,0.63,8.53c0.23,4.14,0.44,8.28,0.54,12.42
						c0.04,1.63-0.4,3.26-0.37,4.89c0.03,2.27,0.35,4.53,0.44,6.79c0.04,0.96-0.16,1.93-0.33,2.88c-0.12,0.68-0.64,0.94-1.31,0.8
						c-3.1-0.65-6.21-0.25-9.32-0.05c-2.95,0.2-5.89,0.61-8.85,0.08c-0.79-0.14-1.2-0.43-1.29-1.26c-0.25-2.31-0.64-4.6-0.23-6.94
						c0.13-0.74,0.07-1.53,0.04-2.29c-0.08-1.68,0.14-3.33,1.07-4.72c1.6-2.38,2.24-5.03,2.61-7.8c0.21-1.61,0.75-3.05,1.91-4.25
						c1.2-1.25,1.41-2.8,1.34-4.49c-0.14-3.24-0.21-6.49-0.23-9.73c-0.01-1.36,0.72-2.42,1.86-3.12c0.31-0.19,0.84-0.19,1.2-0.07
						c1.92,0.62,3.86,0.79,5.86,0.59c0.66-0.06,1.37,0.1,2.02,0.28c1.11,0.29,1.85,1.04,2.03,2.19c0.27,1.74,0.47,3.49,0.69,5.24
						C1094.13,2367.08,1094.03,2367.09,1093.93,2367.1z"/>
					<path className="st7" d="M1070.91,2376.96c-0.15,1.31-0.36,2.83-0.5,4.35c-0.19,2.07-0.34,4.15-0.51,6.23
						c-0.03,0.31-0.12,0.63-0.1,0.94c0.05,0.86,0.09,1.72,0.24,2.56c0.24,1.26,0.76,2.49,0.84,3.76c0.15,2.47,0.12,4.96,0.1,7.45
						c-0.01,1.31-0.07,1.33-1.38,1.52c-1.78,0.25-3.55,0.53-5.34,0.7c-0.91,0.09-1.84-0.07-2.77-0.08c-2.43-0.02-4.86,0-7.28-0.01
						c-1.45-0.01-2.09-0.73-2.27-2.21c-0.28-2.27,0.16-4.46,0.38-6.69c0.22-2.28,0.29-4.58,0.25-6.87
						c-0.03-1.73,0.13-3.35,0.98-4.89c0.93-1.68,1.3-3.54,1.72-5.4c0.59-2.62,1.34-5.2,2.08-7.78c0.17-0.58,0.54-1.17,0.97-1.6
						c0.97-0.96,1.53-2.09,1.69-3.41c0.2-1.58,0.94-2.74,2.42-3.35c1.04-0.43,2.08-0.98,3.17-1.09c1.9-0.21,3.21,0.9,3.62,2.79
						c0.16,0.77,0.15,1.58,0.26,2.36c0.35,2.29,0.75,4.58,1.08,6.88C1070.75,2374.32,1070.8,2375.53,1070.91,2376.96z"/>
					<path className="st7" d="M1231.5,2393.74c-0.37,2.29,0.23,4.36,1.01,6.4c0.06,0.15,0.11,0.3,0.16,0.45
						c0.53,1.61,0.27,1.93-1.43,2.06c-0.86,0.06-1.7,0.32-2.55,0.45c-1.9,0.28-3.8,0.57-5.71,0.81c-1.81,0.23-3.64,0.31-5.43-0.15
						c-0.84-0.21-1.68-0.42-2.51-0.69c-0.19-0.06-0.41-0.36-0.42-0.56c-0.08-1.1-0.08-2.21-0.16-3.31
						c-0.08-1.09-0.17-2.19-0.34-3.27c-0.14-0.88-0.43-1.74-0.57-2.62c-0.18-1.16,0-2.24,0.85-3.17c1.07-1.17,1.26-2.62,0.93-4.09
						c-0.47-2.16-0.43-4.22,0.59-6.24c0.59-1.18,0.49-2.41,0.21-3.71c-0.31-1.4-0.29-2.87-0.5-4.3c-0.38-2.53-0.83-5.04-1.24-7.57
						c-0.06-0.36-0.1-0.74-0.08-1.1c0.06-1.01,0.67-1.73,1.66-1.76c1.31-0.03,2.62,0.04,3.92,0.16c1.02,0.09,2.04,0.29,3.03,0.53
						c0.37,0.09,0.74,0.39,1.01,0.69c1.37,1.56,1.83,3.52,2.33,5.48c1.08,4.24,2.38,8.43,3.28,12.7
						C1230.42,2385.16,1231.35,2389.43,1231.5,2393.74z"/>
					<path className="st7" d="M1027.83,2403.74c0.83-1.74,1.71-3.34,2.36-5.03c1.51-3.92,2.63-7.93,3.26-12.11
						c0.5-3.32,0.81-6.71,1.97-9.92c0.54-1.48,0.97-3,1.54-4.47c0.35-0.9,0.58-0.93,1.8-0.66c0.71,0.16,1.08,0.63,0.96,1.32
						c-0.28,1.69-0.63,3.36-0.95,5.04c-0.02,0.13-0.13,0.26-0.12,0.37c0.03,0.24,0.11,0.47,0.17,0.7c0.21-0.14,0.55-0.24,0.61-0.43
						c0.3-0.95,0.57-1.92,0.78-2.9c0.3-1.39,0.44-2.81,0.86-4.16c0.24-0.77,0.92-1.39,1.34-2.11c1.03-1.75,2.6-2.5,4.58-2.65
						c0.7-0.05,1.38-0.4,2.07-0.61c0.15-0.05,0.33-0.18,0.44-0.13c0.89,0.37,1.87,0.64,2.61,1.21c0.69,0.52,0.74,1.47,0.63,2.35
						c-0.47,3.88-0.91,7.76-1.4,11.63c-0.1,0.75-0.4,1.47-0.64,2.19c-0.49,1.47-0.51,2.95-0.25,4.46c0.24,1.38,0.05,2.7-0.2,4.07
						c-0.4,2.14-0.59,4.33-0.79,6.51c-0.11,1.18-0.02,2.38-0.07,3.56c-0.01,0.23-0.23,0.57-0.44,0.65c-1.81,0.75-3.67,1.04-5.65,0.9
						c-1.11-0.08-2.25,0.25-3.38,0.28c-1.53,0.03-3.06-0.04-4.58-0.13c-0.14-0.01-0.39-0.45-0.37-0.67
						c0.16-1.86,0.38-3.73,0.57-5.59c0.05-0.45,0.01-0.91,0.1-1.34c0.13-0.6,0.33-1.19,0.53-1.77c0.23-0.69,0.45-1.39,0.73-2.06
						c0.25-0.6,0.61-1.16,0.88-1.75c0.13-0.28,0.2-0.59,0.22-0.9c0.01-0.12-0.21-0.32-0.34-0.34c-0.13-0.02-0.38,0.14-0.4,0.26
						c-0.27,1.23-1.45,2.12-1.31,3.51c0.01,0.07-0.03,0.16-0.08,0.22c-1.23,1.58-1.38,3.56-1.94,5.37c-0.64,2.05-2.04,3.43-3.6,4.69
						C1029.63,2403.85,1028.74,2404.01,1027.83,2403.74z"/>
					<path className="st7" d="M1244.73,2403.21c-3.79,0.09-7.45,0.08-10.89-0.72c-0.24-0.99-0.35-1.91-0.68-2.75
						c-0.83-2.11-1.05-4.27-0.99-6.52c0.03-1.26-0.2-2.52-0.26-3.78c-0.05-0.96-0.21-1.98,0.02-2.88c0.55-2.15,0.3-4.23-0.08-6.34
						c-0.32-1.76-0.61-3.53-0.87-5.3c-0.09-0.62-0.1-1.27-0.06-1.89c0.06-1.09,0.07-2.14-0.36-3.19c-0.39-0.95,0.82-2.64,1.95-2.8
						c0.89-0.13,1.78-0.19,2.67-0.27c1.46-0.13,2.58,0.41,3.28,1.72c1.55,2.88,2.82,5.9,2.91,9.2c0.11,4.02,0.8,7.91,1.94,11.75
						c0.75,2.51,0.98,5.12,1.01,7.75c0.02,1.6,0.23,3.21,0.34,4.81C1244.69,2402.39,1244.71,2402.77,1244.73,2403.21z"/>
					<path className="st7" d="M1240.6,2371.16c1.36-0.3,2.59-0.32,3.89,0.08c1.78,0.55,3.64,0.87,5.47,1.26
						c1.61,0.34,2.51,1.36,2.87,2.91c0.43,1.84,0.28,3.58-0.61,5.3c-0.74,1.42-1.47,2.89-1.86,4.43c-0.29,1.13-0.04,2.4-0.02,3.6
						c0.01,0.32,0.11,0.64,0.07,0.95c-0.35,2.99,0.95,5.51,2.27,8.01c1.07,2.03,1.69,4.19,2.28,6.48c-2.13,0.13-4.18,0.49-6.25,0.01
						c-0.85-0.2-1.69-0.4-2.55-0.53c-0.55-0.08-0.66-0.35-0.69-0.84c-0.16-2.84-0.21-5.7-0.56-8.53c-0.31-2.45-0.86-4.87-1.49-7.26
						c-0.64-2.44-1.13-4.9-1.17-7.41c-0.04-2.76-0.46-5.41-1.49-7.96C1240.7,2371.52,1240.67,2371.37,1240.6,2371.16z"/>
					<path className="st7" d="M1187.95,2354.81c0,0.53-0.02,1.06,0.01,1.58c0.03,0.61-0.28,0.8-0.85,0.89
						c-2.04,0.31-4.05,0.02-6.07-0.25c-2.53-0.34-5.04-0.93-7.63-0.72c-2.24,0.18-4.47,0.45-6.71,0.44
						c-2.31-0.01-4.62-0.33-6.93-0.46c-1.68-0.09-3.37-0.11-5.05-0.1c-1.21,0.01-2.43,0.09-3.64,0.18
						c-0.57,0.04-0.75-0.28-0.64-0.71c0.34-1.35,0.63-2.73,1.15-4.02c0.5-1.24,1.64-1.75,3.02-1.44c1.81,0.41,3.53,0.2,5.31-0.19
						c1.97-0.43,4.01-0.75,6.02-0.8c1.56-0.04,3.12,0.47,4.69,0.64c2.62,0.29,5.25,0.52,7.88,0.74c2.26,0.19,4.54,0.26,6.79,0.5
						c2.2,0.23,2.76,0.96,2.72,3.16c0,0.18,0,0.37,0,0.55C1187.99,2354.81,1187.97,2354.81,1187.95,2354.81z"/>
					<path className="st7" d="M1106.7,2366.84c-0.21,2.6-0.41,5.19-1.75,7.56c-1.4,2.48-1.77,5.24-1.69,8.05
						c0.14,5.33,0.37,10.67,0.52,16c0.04,1.42-0.08,2.84-0.16,4.26c-0.01,0.13-0.27,0.33-0.44,0.36c-1.22,0.2-2.44,0.38-3.66,0.55
						c-0.39,0.05-0.79,0.08-1.18,0.08c-0.71,0-1.42,0.02-2.12-0.07c-0.52-0.07-0.73-0.4-0.52-0.99c0.19-0.51,0.35-1.09,0.31-1.62
						c-0.1-1.14,0.42-1.98,0.95-2.91c0.81-1.44,1.62-2.93,2.09-4.5c0.59-1.96,0.21-3.96-0.34-5.93c-0.85-3.07-1.21-6.2-0.95-9.4
						c0.17-2.07,0.06-4.17,0.25-6.24c0.13-1.4,0.56-2.78,0.88-4.17c0.05-0.22,0.2-0.45,0.38-0.59c2.03-1.7,4.4-2.32,7.01-2.06
						c0.16,0.02,0.38,0.32,0.42,0.52C1106.75,2366.09,1106.7,2366.47,1106.7,2366.84z"/>
					<path className="st7" d="M1192.99,2351.15c5.24,0.7,10.38,0.3,15.41,1.37c2.21,0.47,4.51,0.5,6.76,0.76
						c0.57,0.07,1.14,0.2,1.7,0.36c2.06,0.6,3.28,2.14,3.46,4.33c0.22,2.59,0.22,2.52-2.37,2.36c-1.19-0.08-2.41,0.22-3.61,0.26
						c-0.94,0.03-1.9,0.04-2.82-0.13c-4.64-0.85-9.26-1.83-13.91-2.62c-2.36-0.4-4.78-0.51-7.16-0.76c-0.34-0.04-0.68-0.1-1.01-0.18
						c-0.48-0.12-0.64-0.45-0.64-0.95c0.01-1.18-0.08-2.37-0.05-3.55c0.01-0.23,0.31-0.62,0.53-0.66
						C1190.55,2351.49,1191.86,2351.32,1192.99,2351.15z"/>
					<path className="st7" d="M1130.92,2399.9c-0.25-1.11-0.6-2.15-0.69-3.21c-0.11-1.41,0.03-2.83-0.01-4.24
						c-0.07-2.97,0.45-5.87,0.8-8.81c0.24-2.04-0.14-4.15-0.25-6.23c-0.01-0.21-0.07-0.42-0.09-0.63c-0.12-1.26,0.22-1.64,1.47-1.63
						c1.27,0.01,2.54,0.03,3.8,0.06c1.07,0.02,1.77,0.51,1.83,1.57c0.2,3.5,0.3,7.01,0.44,10.51c0.1,2.58,0.18,5.16,0.29,7.74
						c0.06,1.26,0.2,2.52,0.26,3.79c0.01,0.18-0.22,0.48-0.39,0.53c-1.29,0.37-2.58,0.7-3.88,1c-0.32,0.07-0.68-0.05-1.01-0.09
						C1132.6,2400.14,1131.72,2400.02,1130.92,2399.9z"/>
					<path className="st7" d="M1080.57,2352.34c1.69,0,3.38,0.02,5.06-0.01c1.77-0.02,4.08,2.08,4.2,3.86c0.06,0.87-0.61,1.75-1.48,1.87
						c-1.63,0.23-3.24,0.19-4.86-0.28c-0.93-0.27-2.06-0.42-2.94-0.14c-2.19,0.7-4.42,0.65-6.65,0.85
						c-2.57,0.23-5.14,0.54-7.71,0.85c-0.56,0.07-1.11,0.32-1.65,0.53c-0.39,0.15-0.66,0.11-0.84-0.28
						c-0.03-0.07-0.09-0.13-0.14-0.19c-1.33-1.89-0.78-3.8,1.44-4.53c1.8-0.59,3.65-1.07,5.51-1.39c2.83-0.48,5.7-0.78,8.55-1.13
						c0.49-0.06,1-0.01,1.5-0.01C1080.57,2352.35,1080.57,2352.34,1080.57,2352.34z"/>
					<path className="st7" d="M1206.23,2389.16c-0.54-0.99-1.12-1.87-2.17-2.37c-0.83-0.39-0.74-1.24-0.6-1.96
						c0.38-1.95,0.2-3.83-0.45-5.69c-0.41-1.17-0.83-2.34-1.23-3.51c-0.17-0.5-0.29-1.02-0.47-1.51c-0.27-0.75-0.84-1.19-1.63-1.13
						c-0.64,0.05-0.85-0.26-0.92-0.78c-0.33-2.4-0.65-4.81-0.96-7.22c-0.06-0.47-0.07-0.94-0.11-1.48c2.9,0.15,5.78,0.31,8.55,0.45
						c-0.1,4.01-0.18,7.96,0.71,11.85c0.57,2.48,0.6,4.95,0.08,7.46C1206.63,2385.19,1206.49,2387.16,1206.23,2389.16z"/>
					<path className="st7" d="M1175.61,2403.88c-1.45,0.17-2.83,0.38-4.21,0.47c-1.19,0.08-1.23,0.01-1.32-1.2
						c-0.1-1.47-0.1-2.96-0.19-4.43c-0.07-1.1-0.15-2.22-0.35-3.3c-0.36-1.95-0.22-3.88,0.04-5.83c0.33-2.46,0.67-4.92,0.92-7.38
						c0.15-1.43,0.91-2.46,1.93-3.33c0.72-0.61,1.93-0.08,2.07,0.81c0.46,2.83,0.65,5.64-0.01,8.49c-0.45,1.95-0.22,3.96,0.19,5.9
						c0.55,2.58,0.62,5.18,0.75,7.78C1175.46,2402.53,1175.55,2403.21,1175.61,2403.88z"/>
					<path className="st7" d="M1069.81,2362.82c2.08-0.33,4.14-0.65,6.31-0.99c-0.08,0.84-0.16,1.6-0.21,2.35
						c-0.07,0.92-0.1,1.85-0.18,2.77c-0.12,1.47-0.26,2.93-0.42,4.39c-0.14,1.25,0.06,2.42,0.8,3.46c-1.27,1.6-1.63,3.51-2.05,5.38
						c-0.72,3.19-1.3,6.41-2.01,9.6c-0.38,1.7,0.13,3.3,0.3,4.94c0.29,2.8,0.63,5.6,0.96,8.47c-0.15,0.03-0.37,0.08-0.59,0.11
						c-0.65,0.08-0.92-0.12-0.92-0.87c0-2.93-0.04-5.86-0.16-8.79c-0.03-0.79-0.36-1.59-0.63-2.35c-0.36-0.99-0.5-1.95-0.36-3.02
						c0.2-1.57,0.18-3.16,0.32-4.74c0.2-2.34,0.7-4.68,0.63-7c-0.08-2.73-0.62-5.44-0.96-8.16c-0.19-1.49-0.35-2.99-0.55-4.47
						C1070.03,2363.5,1069.89,2363.1,1069.81,2362.82z"/>
					<path className="st7" d="M1163.7,2361.34c3.88-0.17,7.78-0.33,11.76-0.5c-1.08,4.09-1.23,8.08-1.15,12.2
						c-1.68-0.13-3.27-0.31-4.87-0.37c-0.77-0.03-1.56,0.16-2.44,0.26C1166.3,2368.99,1165.49,2364.97,1163.7,2361.34z"/>
					<path className="st7" d="M1221.1,2360.89c0-1.07,0.11-2.08-0.03-3.06c-0.13-0.95-0.51-1.86-0.78-2.79
						c1.72-0.18,3.31,0.53,4.91,1.09c2.84,0.99,5.66,2.02,8.48,3.05c0.69,0.25,1.37,0.56,2.01,0.92c1.67,0.95,2.36,3.64,1.34,5.27
						c-0.14,0.22-0.61,0.43-0.85,0.36c-0.79-0.22-1.55-0.56-2.33-0.86c-1.45-0.56-2.88-1.2-4.36-1.66c-2.54-0.8-5.11-1.49-7.66-2.22
						C1221.59,2360.92,1221.32,2360.92,1221.1,2360.89z"/>
					<path className="st7" d="M1051.62,2365.95c-0.53-0.25-0.98-0.54-1.47-0.67c-0.4-0.11-0.99-0.19-1.26,0.01
						c-0.93,0.73-1.96,0.41-2.96,0.52c-0.87,0.1-1.72,0.33-2.58,0.51c-0.15,0.03-0.29,0.15-0.44,0.18
						c-2.04,0.37-3.72,1.44-5.32,2.67c-0.21,0.16-0.43,0.33-0.67,0.41c-0.38,0.13-0.78,0.19-1.17,0.29
						c-0.04-0.39-0.17-0.79-0.1-1.15c0.34-1.67,1.05-3.01,2.72-3.83c1.11-0.55,2.11-1.4,3-2.28c1.08-1.05,2.3-1.64,3.79-1.73
						c0.9-0.05,1.79-0.18,2.69-0.16c0.62,0.01,1.28,0.09,1.84,0.34c1.88,0.83,2.25,1.56,2.02,3.61
						C1051.66,2365.07,1051.65,2365.46,1051.62,2365.95z"/>
					<path className="st7" d="M1107.69,2359.26c-0.28,1.58-0.57,3.07-0.82,4.57c-0.07,0.42-0.25,0.6-0.68,0.57
						c-0.97-0.07-1.95-0.09-2.92-0.2c-2.24-0.23-4.22,0.4-6.02,1.7c-0.74,0.53-1.5,1.02-2.42,1.65c0-1.09-0.11-2,0.03-2.86
						c0.21-1.29,1.11-2.16,2.13-2.92c0.67-0.5,1.25-1.1,1.92-1.59c0.58-0.43,1.19-0.96,1.86-1.09
						C1103.11,2358.62,1105.44,2359.15,1107.69,2359.26z"/>
					<path className="st7" d="M1168.87,2378.7c0.16-1.45,0.23-2.84,1.08-3.99c0.29-0.39,0.78-0.79,1.23-0.87
						c0.93-0.15,1.89-0.11,2.83-0.08c0.16,0.01,0.44,0.36,0.44,0.55c-0.01,1.23-0.09,2.47-0.13,3.47
						C1172.46,2378.09,1170.72,2378.39,1168.87,2378.7z"/>
					<path className="st7" d="M1135.98,2404.6c-1.08-0.07-2.15-0.14-3.23-0.23c-0.2-0.02-0.43-0.08-0.57-0.21
						c-0.44-0.38-0.48-1.67-0.02-2.46c0.32-0.53,0.85-0.74,1.51-0.59c1.12,0.26,2.27,0.42,3.37,0.75c0.82,0.25,1.37,0.86,1.42,1.8
						c0.03,0.54-0.18,0.87-0.74,0.89c-0.58,0.02-1.16,0-1.74,0C1135.98,2404.57,1135.98,2404.59,1135.98,2404.6z"/>
					<path className="st7" d="M1136.62,2400.58c1.37-0.3,2.94-0.63,4.69-1.01c0,0.92,0.01,1.91-0.02,2.9c0,0.13-0.19,0.3-0.34,0.37
						c-0.75,0.38-1.53,0.05-2.11-0.53C1138.17,2401.62,1137.31,2401.1,1136.62,2400.58z"/>
					<path className="st7" d="M1140.21,2398.77c0.17-1.16,0.35-2.31,0.52-3.47c0.07,0,0.15,0,0.22,0c0.08,1,0.2,1.99,0.19,2.99
						c0,0.21-0.49,0.41-0.75,0.61C1140.33,2398.85,1140.27,2398.81,1140.21,2398.77z"/>
				</g>
				<g>
					<path className="st8" d="M1022.2,2399.64c0.83-1.79,2.31-4.75,2.89-6.45c0.97-2.81,1.9-5.66,2.56-8.55
						c0.71-3.16,0.94-6.43,1.65-9.59c0.66-2.94,1.69-5.8,2.56-8.69c0.06,0.02,0.13,0.04,0.19,0.06c-0.03-0.04-0.05-0.11-0.09-0.12
						c-1.55-0.52-1.78-0.87-1.57-2.49c0.27-2.02,1.24-3.52,3.1-4.48c0.94-0.49,1.82-1.17,2.58-1.92c1.3-1.28,2.74-2.12,4.59-2.2
						c0.87-0.04,1.74-0.15,2.61-0.2c1.74-0.1,3.14,0.65,4.28,1.88c0.71,0.77,0.72,1.81,0.56,2.79c-0.16,0.97-0.04,1.8,0.55,2.62
						c0.63,0.87,0.55,1.91,0.41,2.91c-0.4,2.82-0.82,5.63-1.23,8.45c0.09,0.02,0.17,0.04,0.26,0.06c0.23-0.96,0.53-1.91,0.69-2.88
						c0.36-2.19,0.65-4.41,1.88-6.32c1.38-2.15,2.86-4.25,4.32-6.35c0.72-1.04,1.76-1.65,2.93-2.08c0.29-0.11,0.59-0.21,0.99-0.35
						c-0.21-0.31-0.37-0.56-0.54-0.81c-1.55-2.25-0.78-4.77,1.84-5.68c1.56-0.55,3.18-1,4.81-1.27c3.15-0.52,6.31-1.01,9.49-1.26
						c2.49-0.2,5.01-0.06,7.52-0.02c0.42,0.01,0.89,0.18,1.26,0.41c1.28,0.81,2.22,1.95,2.77,3.37c0.45,1.16,0.05,2.17-0.67,2.92
						c1.08,0.42,2.28,0.73,3.3,1.33c1.15,0.66,1.23,2.01,1.47,3.23c1.32-1.16,2.61-2.24,3.84-3.39c0.73-0.68,1.53-1.19,2.51-1.22
						c1.58-0.05,3.16,0.01,4.74,0.05c0.69,0.02,1.37,0.13,2.05,0.12c0.26,0,0.6-0.16,0.77-0.36c0.64-0.74,1.41-1.24,2.39-1.23
						c2,0.02,4,0.11,6.01,0.19c1.74,0.07,3.5,0.02,5.21,0.31c1.18,0.2,2.32,0.81,3.41,1.38c1.12,0.59,1.7,1.61,2.07,2.86
						c1.13,3.85,1.03,7.71,0.47,11.62c-0.48,3.33-0.23,6.67,0.1,10.02c0.15-1.29,0.26-2.59,0.45-3.87c0.17-1.11,0.37-2.23,0.7-3.3
						c0.33-1.08,1.14-1.58,2.28-1.59c1.45-0.01,2.91-0.02,4.36,0c1.43,0.02,2.43,0.82,2.64,2.23c0.14,0.96,0.11,1.95,0.16,2.92
						c0.08,1.64,0.2,3.27,0.24,4.91c0.03,1.4-0.13,2.8-0.06,4.19c0.11,2.37,0.34,4.73,0.53,7.1c0.02,0.28,0.09,0.56,0.24,0.84
						c0.06-0.38,0.09-0.77,0.17-1.15c0.07-0.3,0.1-0.7,0.3-0.87c0.54-0.44,0.5-0.98,0.49-1.57c-0.11-5.74-0.27-11.47-0.3-17.21
						c-0.02-3.11,0.09-6.24,0.3-9.34c0.06-0.91,0.64-1.8,1.02-2.68c0.42-0.99,0.98-1.92,1.32-2.94c0.82-2.46,2.38-3.74,4.98-3.9
						c0.55-0.03,1.1-0.09,1.64-0.13c-0.14-1.98,0.38-3.76,1.17-5.46c0.77-1.66,2.21-2.35,3.99-1.96c1.59,0.36,3.12,0.26,4.69-0.14
						c2.28-0.57,4.59-1.07,6.97-0.86c1.44,0.13,2.86,0.49,4.3,0.63c3.84,0.35,7.68,0.62,11.52,0.95c1.42,0.12,2.92,0.09,4.21,0.59
						c1.23,0.47,2.22,0.56,3.5,0.24c2.92-0.74,5.89,0.03,8.84,0.03c4.06,0.01,8.04,0.7,12.04,1.26c1.93,0.27,3.89,0.38,5.8,0.76
						c0.92,0.18,1.74,0.8,2.61,1.22c0.16,0.08,0.34,0.2,0.5,0.19c2.42-0.24,4.55,0.77,6.73,1.54c2.68,0.95,5.36,1.91,8.03,2.89
						c0.59,0.22,1.15,0.52,1.7,0.84c1.21,0.7,1.89,1.73,1.9,3.17c0.01,1.24,0.05,2.48-0.69,3.58c1.3,0.84,1.92,2.17,2.56,3.47
						c0.22,0.45,0.43,0.66,0.95,0.54c1.46-0.34,2.85-0.06,4.27,0.37c1.41,0.43,2.88,0.68,4.33,0.94c3.29,0.59,4.31,2.63,4.3,6.18
						c0,0.94-0.38,1.91-0.73,2.81c-0.5,1.27-1.16,2.49-1.71,3.74c-0.21,0.48-0.44,1.01-0.43,1.51c0.05,3.91,0.1,7.79,2.22,11.33
						c0.82,1.36,1.31,2.93,1.85,4.44c0.2,0.57,0.67,1.67,0.67,1.67l0,0l0,0l0.22,1.63l0,0l0,0c-2.57,0.28-7.41,0.09-9.89-0.44
						c-1.28-0.27-2.61-0.31-3.92-0.42c-2.63-0.22-5.26-0.38-7.88-0.64c-1.68-0.17-3.36-0.14-4.94,0.38
						c-2.04,0.67-4.16,0.68-6.25,0.94c-2.63,0.32-5.2,0.11-7.73-0.77c-1.49-0.52-3.03-0.26-4.54,0.06c-1.39,0.3-2.79,0.58-4.19,0.83
						c-0.45,0.08-1.03,0.32-1.24-0.34c-0.79,0-1.54,0-2.3,0c0.01,0.07,0.02,0.13,0.03,0.2c-0.32,0.07-0.64,0.18-0.96,0.19
						c-0.52,0.01-1.05-0.09-1.58-0.06c-3.3,0.19-6.59,0.46-9.89,0.6c-1.26,0.05-2.53-0.23-3.79-0.2c-2.08,0.04-4.16,0.22-6.25,0.31
						c-0.87,0.04-1.75,0.07-2.61-0.02c-0.69-0.07-1.35-0.36-2.04-0.51c-0.53-0.11-1.07-0.24-1.6-0.22
						c-1.36,0.05-2.72,0.18-4.09,0.28c-1.65,0.12-3.31,0.23-4.96,0.36c-2.49,0.2-4.91,0-7.13-1.27c-0.64-0.37-1.21-0.27-1.81,0
						c-1.48,0.66-2.98,1.22-4.64,1.2c-1.79-0.02-3.58,0.04-5.37-0.02c-0.61-0.02-1.24-0.21-1.82-0.42c-0.54-0.2-1.04-0.51-1.55-0.79
						c-0.71-0.4-1.37-0.82-2.26-0.38c-0.37,0.18-0.87,0.08-1.32,0.11c-0.59,1.43-0.88,1.65-2.49,1.6c-1.56-0.05-3.13-0.09-4.66-0.32
						c-0.83-0.13-1.59-0.64-2.38-1.01c-0.58-0.27-1.01-0.47-1.69,0.04c-0.54,0.41-1.44,0.45-2.18,0.46
						c-2.5,0.06-5.01-0.01-7.51,0.05c-1.47,0.03-2.9,0-4.27-0.63c-0.23-0.11-0.64-0.16-0.81-0.03c-1.53,1.15-3.26,0.95-4.96,0.78
						c-1.67-0.17-3.33-0.54-5.01-0.69c-0.78-0.07-1.58,0.23-2.38,0.3c-1.31,0.11-2.63,0.18-3.95,0.26c-0.08,0-0.17,0.02-0.24-0.01
						c-1.37-0.72-2.87-0.48-4.31-0.53c-2.61-0.1-5.22-0.08-7.83,0.06c-2.95,0.16-5.89,0.55-8.86,0.22c-1.34-0.15-2.7-0.32-4.04-0.32
						c-0.73,0-1.45,0.42-2.19,0.52c-3.32,0.47-6.63,0.98-10.01,0.54c-1.24-0.16-2.53,0.05-3.79,0.04c-1.35-0.01-2.7-0.02-4.03-0.17
						c-0.97-0.11-1.94-0.41-2.24-1.59c-1.12,0.29-2.21,0.58-3.3,0.86c-0.1,0.03-0.21,0.07-0.31,0.07
						c-3.74-0.24-7.45,0.76-11.22,0.19c-1.68-0.25-3.46,0.14-5.19,0.22c-0.6,0.03-1.24,0.12-1.8-0.03 M1163.22,2379.69
						c0.09-0.01,0.18-0.01,0.28-0.02c0-0.61-0.01-1.21,0-1.82c0.04-2.17-0.58-4.22-1.09-6.3c-0.96-3.89-1.35-7.9-2.68-11.71
						c-0.58-1.66-1.34-3.15-2.49-4.45c-0.92-1.04-2.06-1.79-3.46-1.91c-3.13-0.26-6.26-0.5-9.4-0.62c-1.94-0.07-3.32,1.6-3.1,3.56
						c0.12,1.04,0.07,2.03-0.24,3.04c-0.17,0.58-0.16,1.27-0.04,1.87c0.23,1.1,0.14,2.13-0.16,3.2c-0.14,0.51-0.02,1.12,0.1,1.67
						c0.08,0.4,0.33,0.77,0.51,1.15c0.52,1.12,0.44,2.16-0.22,3.23c-0.48,0.78-1.02,1.62-1.15,2.49c-0.27,1.82-0.49,3.68-0.39,5.51
						c0.29,5.4,0.74,10.8,1.14,16.2c0.1,1.29,0.2,2.58,0.44,3.85c0.17,0.92,0.58,1.06,1.48,0.97c0.78-0.09,1.58-0.1,2.37-0.09
						c1,0.01,2.02,0.22,2.99,0.06c1.18-0.2,2.33-0.64,3.45-1.06c0.93-0.35,1.8-0.4,2.66,0.09c2.03,1.17,4.24,1.37,6.5,1.1
						c1.18-0.14,2.33-0.44,3.51-0.05c0.54,0.18,0.63-0.24,0.61-0.64c-0.11-2.19-0.16-4.38-0.36-6.57
						C1164.12,2388.19,1163.66,2383.94,1163.22,2379.69z M1121.95,2387.32c0.81-0.91,1.19-1.71,1.14-2.65
						c-0.13-2.88-0.37-5.75-0.42-8.63c-0.05-2.74-0.13-5.52,0.19-8.23c0.45-3.72,0.52-7.35-0.55-10.98
						c-0.28-0.95-0.68-1.77-1.53-2.21c-1.08-0.57-2.22-1.21-3.4-1.39c-1.95-0.29-3.95-0.25-5.92-0.34c-1.48-0.07-2.95-0.14-4.43-0.2
						c-0.73-0.03-1.22,0.37-1.44,1.01c-0.18,0.51-0.28,1.07-0.32,1.61c-0.07,1.05,0.1,2.15-0.15,3.16c-0.5,2.03-0.41,3.86,1.09,5.45
						c0.02,0.02,0.02,0.05,0.04,0.07c0.63,1.2,0.47,2.05-0.68,2.78c-0.81,0.52-1.33,1.17-1.52,2.11c-0.07,0.33-0.2,0.66-0.36,0.96
						c-1.73,3.27-1.96,6.8-1.83,10.4c0.07,2.03,0.16,4.07,0.22,6.1c0.11,3.7,0.18,7.39,0.34,11.09c0.05,1.22,0.4,1.73,1.59,1.68
						c0.39-0.02,0.79,0.06,1.19,0.08c1.03,0.05,2.07,0.12,2.97-0.56c0.69-0.52,1.39-0.45,2.17-0.18c0.74,0.26,1.52,0.53,2.29,0.54
						c3.11,0.04,6.23-0.02,9.34-0.06c1.53-0.02,2.1-0.66,1.98-2.18c-0.14-1.84-0.29-3.69-0.44-5.53c-0.09-1.06-0.39-2.1-1.23-2.75
						c-1.49-1.16-2.32-2.68-2.9-4.42c-0.39-1.15-1.12-2.04-2.23-2.67c-0.68-0.38-1.33-0.95-1.79-1.59c-0.61-0.85-1.05-1.83-1.5-2.78
						c-0.11-0.24,0.03-0.59,0.05-0.89c0.23,0.17,0.53,0.29,0.66,0.51c0.54,0.93,0.98,1.94,1.56,2.84c0.34,0.52,0.9,0.89,1.55,1.52
						c0.55-1.16,0.97-2.08,1.43-2.99c0.07-0.13,0.4-0.27,0.49-0.22c0.16,0.09,0.36,0.39,0.31,0.51c-0.26,0.67-0.48,1.42-0.94,1.95
						c-0.43,0.49-0.43,0.82-0.16,1.31c0.59,1.08,1.15,2.18,1.73,3.26C1120.98,2385.63,1121.43,2386.41,1121.95,2387.32z
						 M1193.73,2370.15c0-0.57,0.07-1.02-0.01-1.43c-0.61-2.87-1.2-5.75-1.16-8.69c0.04-3.09-3.44-5.83-5.61-5.84
						c-3.09-0.02-6.18,0.05-9.26,0.05c-1.81,0-2.79,1.06-3.41,2.58c-0.91,2.22-0.85,4.52-0.57,6.85c0.21,1.77,0.4,3.58,0.3,5.35
						c-0.16,2.79,0.11,5.47,1.17,8.06c1.73,4.21,1.86,8.62,1.34,13.02c-0.34,2.89-0.02,5.68,0.34,8.5c0.12,0.99,0.64,1.22,1.61,1.16
						c1.63-0.1,3.26-0.12,4.9-0.12c1.11,0,2.22,0.22,3.32,0.16c3.11-0.16,6.22-0.4,9.32-0.65c0.28-0.02,0.66-0.31,0.79-0.58
						c0.57-1.18,0.66-2.46,0.43-3.72c-0.32-1.76-0.74-3.5-1.17-5.24c-1.24-5.04-1.53-10.17-1.61-15.32
						c-0.01-0.76-0.15-1.52-0.3-2.26c-0.13-0.65-0.42-1.19-1.21-1.26c-0.22-0.02-0.46-0.17-0.61-0.34
						c-0.12-0.12-0.11-0.35-0.17-0.53c0.18-0.05,0.38-0.17,0.55-0.14C1192.99,2369.83,1193.27,2369.98,1193.73,2370.15z
						 M1089.48,2362.25c0.1-0.01,0.2-0.02,0.3-0.03c-0.23-1.75-0.42-3.5-0.69-5.24c-0.18-1.15-0.92-1.9-2.03-2.19
						c-0.66-0.17-1.36-0.34-2.02-0.28c-2,0.2-3.95,0.02-5.86-0.59c-0.36-0.12-0.9-0.12-1.2,0.07c-1.14,0.7-1.87,1.76-1.86,3.12
						c0.02,3.25,0.09,6.49,0.23,9.73c0.07,1.68-0.13,3.24-1.34,4.49c-1.16,1.2-1.7,2.64-1.91,4.25c-0.36,2.77-1.01,5.42-2.61,7.8
						c-0.93,1.38-1.14,3.04-1.07,4.72c0.03,0.76,0.09,1.55-0.04,2.29c-0.41,2.34-0.01,4.63,0.23,6.94c0.09,0.83,0.5,1.12,1.29,1.26
						c2.96,0.53,5.9,0.12,8.85-0.08c3.11-0.21,6.21-0.6,9.32,0.05c0.68,0.14,1.19-0.12,1.31-0.8c0.17-0.95,0.37-1.93,0.33-2.88
						c-0.09-2.27-0.4-4.53-0.44-6.79c-0.02-1.63,0.41-3.26,0.37-4.89c-0.09-4.14-0.31-8.28-0.54-12.42
						C1089.95,2367.94,1089.69,2365.1,1089.48,2362.25z M1066.46,2372.11c-0.12-1.43-0.16-2.65-0.33-3.85
						c-0.32-2.3-0.73-4.58-1.08-6.88c-0.12-0.78-0.1-1.59-0.26-2.36c-0.4-1.89-1.71-3-3.62-2.8c-1.08,0.12-2.13,0.67-3.17,1.09
						c-1.48,0.61-2.21,1.77-2.42,3.35c-0.17,1.31-0.73,2.45-1.69,3.41c-0.43,0.43-0.8,1.02-0.97,1.6c-0.75,2.58-1.49,5.16-2.08,7.78
						c-0.42,1.86-0.79,3.72-1.72,5.4c-0.85,1.54-1.01,3.16-0.98,4.89c0.04,2.29-0.03,4.59-0.25,6.87c-0.22,2.23-0.66,4.43-0.38,6.69
						c0.18,1.48,0.82,2.2,2.27,2.21c2.43,0.01,4.86,0,7.28,0.01c0.92,0.01,1.85,0.16,2.77,0.08c1.78-0.17,3.56-0.45,5.34-0.7
						c1.31-0.18,1.37-0.2,1.38-1.52c0.01-2.48,0.05-4.97-0.1-7.45c-0.08-1.26-0.6-2.49-0.84-3.76c-0.16-0.84-0.19-1.71-0.24-2.56
						c-0.02-0.31,0.07-0.63,0.1-0.94c0.17-2.08,0.31-4.16,0.51-6.23C1066.1,2374.95,1066.31,2373.43,1066.46,2372.11z
						 M1227.05,2388.89c-0.15-4.32-1.08-8.58-1.97-12.83c-0.9-4.28-2.2-8.47-3.28-12.7c-0.5-1.95-0.95-3.91-2.33-5.48
						c-0.26-0.3-0.63-0.6-1.01-0.69c-1-0.24-2.01-0.43-3.03-0.53c-1.3-0.12-2.62-0.2-3.92-0.16c-0.99,0.03-1.6,0.75-1.66,1.76
						c-0.02,0.36,0.02,0.74,0.08,1.1c0.41,2.52,0.86,5.04,1.24,7.57c0.21,1.43,0.2,2.9,0.5,4.3c0.28,1.29,0.39,2.53-0.21,3.71
						c-1.02,2.02-1.06,4.08-0.59,6.24c0.32,1.47,0.14,2.92-0.93,4.09c-0.84,0.93-1.03,2.01-0.85,3.17c0.14,0.88,0.44,1.74,0.57,2.62
						c0.17,1.08,0.26,2.18,0.34,3.27c0.08,1.1,0.07,2.21,0.16,3.31c0.02,0.2,0.23,0.5,0.42,0.56c0.82,0.27,1.67,0.48,2.51,0.69
						c1.8,0.46,3.62,0.38,5.43,0.15c1.91-0.24,3.81-0.52,5.71-0.81c0.86-0.13,1.7-0.39,2.55-0.45c1.71-0.12,1.97-0.45,1.43-2.06
						c-0.05-0.15-0.1-0.3-0.16-0.45C1227.28,2393.25,1226.68,2391.18,1227.05,2388.89z M1023.38,2398.89
						c0.91,0.27,1.8,0.12,2.48-0.43c1.56-1.26,2.96-2.65,3.6-4.69c0.57-1.81,0.72-3.79,1.94-5.37c0.05-0.06,0.09-0.15,0.08-0.22
						c-0.14-1.38,1.05-2.27,1.31-3.51c0.03-0.12,0.28-0.27,0.4-0.26c0.13,0.02,0.35,0.23,0.34,0.34c-0.02,0.3-0.09,0.62-0.22,0.9
						c-0.27,0.59-0.63,1.15-0.88,1.75c-0.28,0.67-0.5,1.37-0.73,2.06c-0.19,0.59-0.4,1.17-0.53,1.77c-0.09,0.43-0.05,0.9-0.1,1.34
						c-0.19,1.86-0.41,3.72-0.57,5.59c-0.02,0.22,0.23,0.66,0.37,0.67c1.52,0.09,3.05,0.16,4.58,0.13c1.13-0.02,2.27-0.36,3.38-0.28
						c1.97,0.14,3.84-0.15,5.65-0.9c0.21-0.09,0.43-0.42,0.44-0.65c0.06-1.19-0.03-2.38,0.07-3.56c0.2-2.18,0.39-4.36,0.79-6.51
						c0.25-1.37,0.44-2.7,0.2-4.07c-0.26-1.5-0.24-2.99,0.25-4.46c0.24-0.72,0.55-1.45,0.64-2.2c0.5-3.87,0.94-7.75,1.4-11.63
						c0.11-0.88,0.06-1.83-0.63-2.35c-0.75-0.57-1.73-0.84-2.61-1.21c-0.11-0.04-0.29,0.09-0.44,0.13
						c-0.69,0.21-1.37,0.55-2.07,0.61c-1.98,0.15-3.54,0.9-4.58,2.65c-0.42,0.72-1.11,1.34-1.34,2.11
						c-0.42,1.35-0.56,2.78-0.86,4.16c-0.21,0.98-0.48,1.95-0.78,2.9c-0.06,0.19-0.4,0.29-0.61,0.43c-0.06-0.23-0.14-0.46-0.17-0.7
						c-0.01-0.12,0.09-0.25,0.12-0.37c0.32-1.68,0.67-3.36,0.95-5.04c0.12-0.7-0.25-1.17-0.96-1.32c-1.22-0.27-1.45-0.24-1.8,0.66
						c-0.57,1.47-1,2.99-1.54,4.47c-1.16,3.21-1.48,6.59-1.97,9.92c-0.63,4.18-1.75,8.19-3.26,12.11
						C1025.09,2395.55,1024.21,2397.15,1023.38,2398.89z M1240.28,2398.37c-0.03-0.44-0.04-0.83-0.07-1.22
						c-0.12-1.6-0.33-3.2-0.34-4.81c-0.03-2.63-0.26-5.23-1.01-7.75c-1.15-3.84-1.84-7.72-1.94-11.75c-0.09-3.3-1.36-6.32-2.91-9.2
						c-0.71-1.31-1.82-1.86-3.28-1.72c-0.89,0.08-1.79,0.15-2.67,0.27c-1.13,0.16-2.34,1.85-1.95,2.8c0.43,1.05,0.42,2.1,0.36,3.19
						c-0.04,0.63-0.03,1.27,0.06,1.89c0.26,1.77,0.55,3.54,0.87,5.3c0.38,2.11,0.63,4.19,0.08,6.34c-0.23,0.9-0.07,1.92-0.02,2.88
						c0.06,1.26,0.3,2.53,0.26,3.78c-0.06,2.24,0.16,4.41,0.99,6.52c0.33,0.83,0.44,1.76,0.68,2.75
						C1232.83,2398.45,1236.49,2398.46,1240.28,2398.37z M1236.15,2366.31c0.06,0.21,0.1,0.37,0.16,0.51
						c1.04,2.55,1.45,5.21,1.49,7.96c0.04,2.51,0.53,4.97,1.17,7.41c0.63,2.39,1.19,4.81,1.49,7.26c0.35,2.82,0.4,5.68,0.56,8.53
						c0.03,0.49,0.15,0.76,0.69,0.84c0.86,0.13,1.7,0.33,2.55,0.53c2.07,0.48,4.12,0.12,6.25-0.01c-0.59-2.29-1.21-4.45-2.28-6.48
						c-1.32-2.5-2.62-5.02-2.27-8.01c0.04-0.31-0.06-0.63-0.07-0.95c-0.02-1.21-0.27-2.47,0.02-3.6c0.4-1.54,1.12-3.01,1.86-4.43
						c0.9-1.72,1.04-3.46,0.61-5.3c-0.36-1.55-1.26-2.57-2.87-2.91c-1.83-0.39-3.68-0.72-5.47-1.26
						C1238.75,2365.99,1237.52,2366.01,1236.15,2366.31z M1183.5,2349.96c0.02,0,0.03,0,0.05,0c0-0.19,0-0.37,0-0.55
						c0.04-2.2-0.52-2.93-2.72-3.16c-2.26-0.24-4.53-0.31-6.79-0.5c-2.63-0.22-5.26-0.45-7.88-0.74c-1.57-0.17-3.13-0.68-4.69-0.64
						c-2.01,0.05-4.04,0.37-6.02,0.8c-1.79,0.39-3.51,0.6-5.31,0.19c-1.37-0.31-2.52,0.2-3.02,1.44c-0.52,1.29-0.81,2.66-1.15,4.02
						c-0.11,0.43,0.07,0.75,0.64,0.71c1.21-0.09,2.43-0.17,3.64-0.18c1.68-0.01,3.37,0.01,5.05,0.1c2.31,0.12,4.62,0.45,6.93,0.46
						c2.24,0.01,4.48-0.27,6.71-0.44c2.59-0.2,5.1,0.39,7.63,0.72c2.02,0.27,4.03,0.56,6.07,0.25c0.57-0.09,0.88-0.28,0.85-0.89
						C1183.48,2351.01,1183.5,2350.48,1183.5,2349.96z M1102.25,2361.99c0-0.37,0.05-0.75-0.02-1.11c-0.04-0.2-0.26-0.5-0.42-0.52
						c-2.61-0.26-4.97,0.36-7.01,2.06c-0.17,0.14-0.33,0.37-0.38,0.59c-0.32,1.38-0.75,2.76-0.88,4.17
						c-0.19,2.07-0.08,4.17-0.25,6.24c-0.26,3.2,0.1,6.33,0.95,9.4c0.55,1.97,0.93,3.97,0.34,5.93c-0.47,1.57-1.28,3.06-2.09,4.5
						c-0.53,0.93-1.04,1.78-0.95,2.91c0.05,0.53-0.12,1.11-0.31,1.62c-0.21,0.58-0.01,0.92,0.52,0.99c0.7,0.09,1.41,0.07,2.12,0.07
						c0.39,0,0.79-0.03,1.18-0.08c1.22-0.17,2.44-0.34,3.66-0.55c0.17-0.03,0.44-0.23,0.44-0.36c0.08-1.42,0.2-2.84,0.16-4.26
						c-0.15-5.33-0.37-10.67-0.52-16c-0.08-2.8,0.29-5.57,1.69-8.05C1101.84,2367.18,1102.04,2364.59,1102.25,2361.99z
						 M1188.54,2346.3c-1.13,0.17-2.43,0.34-3.72,0.6c-0.22,0.04-0.52,0.43-0.53,0.66c-0.03,1.18,0.05,2.37,0.05,3.55
						c0,0.5,0.16,0.82,0.64,0.95c0.33,0.09,0.67,0.15,1.01,0.18c2.39,0.25,4.8,0.35,7.16,0.76c4.65,0.79,9.27,1.77,13.91,2.62
						c0.92,0.17,1.88,0.16,2.82,0.13c1.21-0.04,2.42-0.34,3.61-0.26c2.59,0.17,2.59,0.24,2.37-2.36c-0.18-2.2-1.4-3.74-3.46-4.33
						c-0.55-0.16-1.13-0.3-1.7-0.36c-2.25-0.26-4.55-0.29-6.76-0.76C1198.91,2346.6,1193.78,2347,1188.54,2346.3z M1126.46,2395.05
						c0.8,0.11,1.69,0.24,2.57,0.36c0.34,0.05,0.7,0.17,1.01,0.09c1.3-0.29,2.6-0.63,3.88-1c0.17-0.05,0.4-0.35,0.39-0.53
						c-0.06-1.26-0.2-2.52-0.26-3.79c-0.12-2.58-0.19-5.16-0.29-7.74c-0.14-3.51-0.24-7.01-0.44-10.51
						c-0.06-1.06-0.76-1.55-1.83-1.57c-1.27-0.03-2.54-0.05-3.8-0.06c-1.25-0.01-1.59,0.37-1.47,1.63c0.02,0.21,0.07,0.42,0.09,0.63
						c0.11,2.08,0.5,4.19,0.25,6.23c-0.35,2.93-0.87,5.84-0.8,8.81c0.03,1.41-0.11,2.84,0.01,4.24
						C1125.86,2392.91,1126.22,2393.95,1126.46,2395.05z M1076.12,2347.49c0,0,0,0.01,0,0.01c-0.5,0-1.01-0.05-1.5,0.01
						c-2.85,0.35-5.72,0.65-8.55,1.13c-1.86,0.31-3.72,0.79-5.51,1.39c-2.22,0.73-2.77,2.64-1.44,4.53c0.05,0.06,0.1,0.12,0.14,0.19
						c0.18,0.39,0.46,0.43,0.84,0.28c0.54-0.21,1.09-0.46,1.65-0.53c2.57-0.32,5.14-0.62,7.71-0.85c2.23-0.2,4.46-0.15,6.65-0.85
						c0.88-0.28,2.01-0.13,2.94,0.14c1.62,0.47,3.23,0.51,4.86,0.28c0.87-0.12,1.54-1,1.48-1.87c-0.12-1.79-2.43-3.89-4.2-3.86
						C1079.5,2347.51,1077.81,2347.49,1076.12,2347.49z M1201.77,2384.31c0.26-1.99,0.4-3.97,0.8-5.89
						c0.52-2.51,0.49-4.98-0.08-7.46c-0.9-3.9-0.81-7.85-0.71-11.85c-2.77-0.15-5.65-0.3-8.55-0.45c0.04,0.54,0.05,1.02,0.11,1.48
						c0.31,2.41,0.63,4.81,0.96,7.22c0.07,0.52,0.28,0.83,0.92,0.78c0.79-0.06,1.36,0.38,1.63,1.13c0.18,0.5,0.3,1.01,0.47,1.51
						c0.4,1.17,0.82,2.34,1.23,3.51c0.65,1.86,0.83,3.74,0.45,5.69c-0.14,0.72-0.23,1.57,0.6,1.96
						C1200.66,2382.44,1201.24,2383.32,1201.77,2384.31z M1171.16,2399.03c-0.06-0.67-0.15-1.34-0.18-2.02
						c-0.13-2.61-0.2-5.21-0.75-7.78c-0.41-1.93-0.64-3.94-0.19-5.9c0.66-2.85,0.47-5.66,0.01-8.49c-0.14-0.89-1.35-1.42-2.07-0.81
						c-1.02,0.87-1.79,1.89-1.93,3.33c-0.25,2.47-0.59,4.92-0.92,7.38c-0.26,1.94-0.41,3.87-0.04,5.83c0.2,1.08,0.28,2.2,0.35,3.3
						c0.09,1.48,0.09,2.96,0.19,4.43c0.08,1.21,0.13,1.27,1.32,1.2C1168.33,2399.41,1169.7,2399.2,1171.16,2399.03z
						 M1065.36,2357.98c0.07,0.28,0.21,0.68,0.27,1.09c0.2,1.49,0.36,2.98,0.55,4.48c0.34,2.72,0.88,5.43,0.96,8.16
						c0.07,2.33-0.43,4.67-0.63,7c-0.14,1.58-0.12,3.17-0.32,4.74c-0.14,1.07,0.01,2.03,0.36,3.02c0.27,0.76,0.6,1.56,0.63,2.35
						c0.12,2.93,0.16,5.86,0.16,8.79c0,0.75,0.26,0.95,0.92,0.87c0.22-0.03,0.44-0.08,0.59-0.11c-0.33-2.87-0.67-5.67-0.96-8.47
						c-0.17-1.64-0.68-3.24-0.3-4.94c0.72-3.19,1.29-6.41,2.01-9.6c0.42-1.87,0.78-3.78,2.05-5.38c-0.74-1.04-0.94-2.21-0.8-3.46
						c0.16-1.46,0.29-2.93,0.42-4.39c0.08-0.92,0.11-1.85,0.18-2.77c0.06-0.75,0.14-1.5,0.21-2.35
						C1069.5,2357.33,1067.44,2357.65,1065.36,2357.98z M1159.25,2356.49c1.79,3.62,2.6,7.64,3.3,11.59
						c0.88-0.1,1.66-0.29,2.44-0.26c1.6,0.06,3.19,0.23,4.87,0.37c-0.08-4.13,0.07-8.11,1.15-12.2
						C1167.03,2356.16,1163.13,2356.33,1159.25,2356.49z M1216.65,2356.04c0.22,0.03,0.49,0.04,0.73,0.11
						c2.56,0.73,5.13,1.42,7.66,2.22c1.48,0.47,2.91,1.1,4.36,1.66c0.77,0.3,1.53,0.64,2.33,0.86c0.24,0.07,0.71-0.14,0.85-0.36
						c1.02-1.63,0.32-4.32-1.34-5.27c-0.64-0.36-1.32-0.67-2.01-0.92c-2.82-1.04-5.64-2.07-8.48-3.05
						c-1.61-0.56-3.19-1.27-4.91-1.09c0.27,0.94,0.65,1.85,0.78,2.79C1216.76,2353.95,1216.65,2354.96,1216.65,2356.04z
						 M1047.17,2361.1c0.03-0.49,0.04-0.88,0.08-1.27c0.22-2.05-0.14-2.77-2.02-3.61c-0.56-0.25-1.22-0.33-1.84-0.34
						c-0.89-0.02-1.79,0.11-2.69,0.16c-1.49,0.08-2.7,0.67-3.79,1.73c-0.9,0.87-1.9,1.73-3,2.28c-1.66,0.82-2.38,2.17-2.72,3.83
						c-0.07,0.36,0.06,0.77,0.1,1.15c0.39-0.09,0.79-0.16,1.17-0.29c0.24-0.08,0.47-0.25,0.67-0.41c1.6-1.23,3.29-2.3,5.32-2.67
						c0.15-0.03,0.29-0.14,0.44-0.18c0.86-0.18,1.71-0.41,2.58-0.51c0.99-0.12,2.03,0.21,2.96-0.52c0.27-0.21,0.86-0.12,1.26-0.01
						C1046.19,2360.56,1046.64,2360.86,1047.17,2361.1z M1103.24,2354.41c-2.25-0.11-4.58-0.64-6.91-0.17
						c-0.67,0.14-1.28,0.67-1.86,1.09c-0.67,0.49-1.26,1.1-1.92,1.59c-1.02,0.76-1.93,1.63-2.13,2.92
						c-0.14,0.87-0.03,1.77-0.03,2.86c0.92-0.63,1.69-1.12,2.42-1.65c1.8-1.31,3.78-1.93,6.02-1.7c0.97,0.1,1.95,0.12,2.92,0.19
						c0.42,0.03,0.61-0.15,0.68-0.57C1102.67,2357.48,1102.95,2355.99,1103.24,2354.41z M1164.42,2373.85
						c1.85-0.31,3.59-0.6,5.44-0.91c0.04-1,0.12-2.23,0.13-3.47c0-0.19-0.28-0.55-0.44-0.55c-0.94-0.03-1.91-0.07-2.83,0.08
						c-0.45,0.07-0.94,0.48-1.23,0.87C1164.65,2371.01,1164.58,2372.4,1164.42,2373.85z M1131.52,2399.75c0-0.01,0-0.02,0-0.03
						c0.58,0,1.16,0.01,1.74,0c0.56-0.02,0.77-0.35,0.74-0.89c-0.05-0.94-0.61-1.55-1.42-1.8c-1.1-0.34-2.25-0.5-3.37-0.75
						c-0.65-0.15-1.19,0.06-1.51,0.59c-0.46,0.78-0.42,2.07,0.02,2.45c0.14,0.12,0.37,0.19,0.57,0.21
						C1129.37,2399.61,1130.45,2399.67,1131.52,2399.75z M1132.17,2395.73c0.7,0.53,1.55,1.04,2.23,1.72
						c0.58,0.58,1.36,0.91,2.11,0.53c0.14-0.07,0.33-0.24,0.34-0.37c0.03-0.99,0.02-1.98,0.02-2.9
						C1135.11,2395.09,1133.54,2395.43,1132.17,2395.73z M1135.76,2393.92c0.06,0.04,0.12,0.08,0.18,0.13
						c0.26-0.2,0.75-0.41,0.75-0.61c0.01-0.99-0.11-1.99-0.19-2.99c-0.07,0-0.15,0-0.22,0
						C1136.11,2391.6,1135.94,2392.76,1135.76,2393.92z"/>
					<path className="st9" d="M1163.5,2379.67c0.43,4.25,0.62,8.52,1,12.77c0.2,2.18,0.25,4.38,0.36,6.57c0.02,0.4-0.07,0.81-0.61,0.64
						c-1.19-0.39-2.33-0.09-3.51,0.05c-2.26,0.27-4.46,0.07-6.5-1.1c-0.86-0.5-1.74-0.44-2.66-0.09c-1.13,0.42-2.28,0.87-3.45,1.06
						c-0.97,0.16-1.99-0.05-2.99-0.06c-0.79-0.01-1.59,0.01-2.37,0.09c-0.9,0.1-1.31-0.04-1.48-0.97c-0.23-1.27-0.34-2.56-0.44-3.85
						c-0.41-5.4-0.85-10.79-1.14-16.2c-0.1-1.83,0.11-3.69,0.39-5.51c0.13-0.87,0.67-1.71,1.15-2.49c0.66-1.07,0.73-2.11,0.22-3.23
						c-0.18-0.38-0.43-0.75-0.51-1.15c-0.11-0.54-0.24-1.15-0.1-1.67c0.3-1.07,0.39-2.1,0.16-3.2c-0.13-0.6-0.14-1.29,0.04-1.87
						c0.31-1.02,0.36-2,0.24-3.04c-0.23-1.96,1.16-3.63,3.1-3.56c3.14,0.11,6.27,0.36,9.4,0.62c1.4,0.12,2.54,0.87,3.46,1.91
						c1.15,1.3,1.92,2.79,2.49,4.45c1.33,3.81,1.72,7.82,2.68,11.71c0.51,2.08,1.13,4.13,1.09,6.3
						C1163.49,2378.46,1163.5,2379.06,1163.5,2379.67C1163.41,2379.68,1163.59,2379.66,1163.5,2379.67z"/>
					<path className="st10" d="M1117.67,2381"/>
					<path className="st9" d="M1123.17,2389.66c0,0,0.24,0.52,0.33,1.58c0.15,1.84,0.29,3.69,0.44,5.53c0.12,1.52-0.45,2.16-1.98,2.18
						c-3.11,0.04-6.23,0.1-9.34,0.06c-0.77-0.01-1.55-0.28-2.29-0.54c-0.78-0.27-1.48-0.34-2.17,0.18
						c-0.89,0.68-1.94,0.61-2.97,0.56c-0.4-0.02-0.79-0.1-1.19-0.08c-1.2,0.05-1.54-0.46-1.59-1.68c-0.16-3.69-0.23-7.39-0.34-11.09
						c-0.06-2.03-0.15-4.07-0.22-6.1c-0.13-3.6,0.1-7.13,1.83-10.4c0.16-0.3,0.3-0.63,0.36-0.96c0.19-0.94,0.7-1.59,1.52-2.11
						c1.15-0.73,1.31-1.58,0.68-2.78c-0.01-0.02-0.02-0.05-0.04-0.07c-1.5-1.59-1.58-3.42-1.09-5.45c0.25-1,0.08-2.1,0.15-3.16
						c0.04-0.54,0.14-1.1,0.32-1.61c0.22-0.65,0.71-1.04,1.44-1.01c1.48,0.06,2.95,0.13,4.43,0.2c1.98,0.09,3.98,0.04,5.92,0.34
						c1.18,0.18,2.31,0.82,3.4,1.39c0.84,0.44,1.25,1.26,1.53,2.21c1.07,3.63,1,7.26,0.55,10.98c-0.33,2.71-0.24,5.49-0.19,8.23
						c0.05,2.88,0.29,5.75,0.42,8.63c0.04,0.94,0.38,2.79,0.06,3.45"/>
					<path className="st9" d="M1194.13,2372.04c0.15,0.75,0.29,1.51,0.3,2.26c0.08,5.16,0.38,10.29,1.61,15.32
						c0.43,1.74,0.85,3.48,1.17,5.24c0.23,1.26,0.14,2.54-0.43,3.72c-0.13,0.26-0.5,0.55-0.79,0.58c-3.11,0.25-6.21,0.49-9.32,0.65
						c-1.1,0.06-2.21-0.15-3.32-0.16c-1.63-0.01-3.27,0.02-4.9,0.12c-0.97,0.06-1.49-0.17-1.61-1.16c-0.35-2.82-0.68-5.61-0.34-8.5
						c0.52-4.4,0.39-8.81-1.34-13.02c-1.06-2.59-1.33-5.27-1.17-8.06c0.1-1.77-0.09-3.58-0.3-5.35c-0.28-2.34-0.34-4.63,0.57-6.85
						c0.62-1.52,1.6-2.58,3.41-2.58c3.09,0,6.18-0.07,9.26-0.05c2.16,0.02,5.65,2.75,5.61,5.84c-0.04,2.94,0.56,5.82,1.16,8.69
						c0.09,0.41,0.01,0.86,0.01,1.43"/>
					<path className="st9" d="M1089.48,2362.25c0.21,2.84,0.47,5.68,0.63,8.53c0.23,4.14,0.44,8.28,0.54,12.42
						c0.04,1.63-0.4,3.26-0.37,4.89c0.03,2.27,0.35,4.53,0.44,6.79c0.04,0.96-0.16,1.93-0.33,2.88c-0.12,0.68-0.64,0.94-1.31,0.8
						c-3.1-0.65-6.21-0.25-9.32-0.05c-2.95,0.2-5.89,0.61-8.85,0.08c-0.79-0.14-1.2-0.43-1.29-1.26c-0.25-2.31-0.64-4.6-0.23-6.94
						c0.13-0.74,0.07-1.53,0.04-2.29c-0.08-1.68,0.14-3.33,1.07-4.72c1.6-2.38,2.24-5.03,2.61-7.8c0.21-1.61,0.75-3.05,1.91-4.25
						c1.2-1.25,1.41-2.81,1.34-4.49c-0.14-3.24-0.21-6.49-0.23-9.73c-0.01-1.36,0.72-2.42,1.86-3.12c0.31-0.19,0.84-0.19,1.2-0.07
						c1.92,0.62,3.86,0.79,5.86,0.59c0.66-0.06,1.37,0.1,2.02,0.28c1.11,0.29,1.85,1.04,2.03,2.19c0.27,1.74,0.47,3.49,0.69,5.24
						C1089.68,2362.23,1089.58,2362.24,1089.48,2362.25z"/>
					<path className="st9" d="M1066.46,2372.11c-0.15,1.31-0.36,2.83-0.5,4.35c-0.19,2.07-0.34,4.15-0.51,6.23
						c-0.03,0.31-0.12,0.63-0.1,0.94c0.05,0.86,0.09,1.72,0.24,2.56c0.24,1.26,0.76,2.49,0.84,3.76c0.15,2.47,0.12,4.96,0.1,7.45
						c-0.01,1.31-0.07,1.33-1.38,1.52c-1.78,0.25-3.55,0.53-5.34,0.7c-0.91,0.09-1.84-0.07-2.77-0.08c-2.43-0.02-4.86,0-7.28-0.01
						c-1.45-0.01-2.09-0.73-2.27-2.21c-0.28-2.27,0.16-4.46,0.38-6.69c0.22-2.28,0.29-4.58,0.25-6.87
						c-0.03-1.73,0.13-3.35,0.98-4.89c0.93-1.68,1.3-3.54,1.72-5.4c0.59-2.62,1.34-5.2,2.08-7.78c0.17-0.58,0.54-1.17,0.97-1.6
						c0.97-0.96,1.53-2.09,1.69-3.41c0.2-1.58,0.94-2.74,2.42-3.35c1.04-0.43,2.08-0.98,3.17-1.09c1.9-0.21,3.21,0.9,3.62,2.8
						c0.16,0.77,0.15,1.58,0.26,2.36c0.35,2.29,0.75,4.58,1.08,6.88C1066.3,2369.47,1066.34,2370.68,1066.46,2372.11z"/>
					<path className="st9" d="M1227.05,2388.89c-0.37,2.29,0.23,4.36,1.01,6.4c0.06,0.15,0.11,0.3,0.16,0.45
						c0.53,1.61,0.27,1.93-1.43,2.06c-0.86,0.06-1.7,0.32-2.55,0.45c-1.9,0.28-3.8,0.57-5.71,0.81c-1.81,0.23-3.64,0.31-5.43-0.15
						c-0.84-0.21-1.68-0.42-2.51-0.69c-0.19-0.06-0.41-0.36-0.42-0.56c-0.08-1.1-0.08-2.21-0.16-3.31
						c-0.08-1.09-0.17-2.19-0.34-3.27c-0.14-0.88-0.43-1.74-0.57-2.62c-0.18-1.16,0-2.24,0.85-3.17c1.07-1.17,1.26-2.62,0.93-4.09
						c-0.47-2.16-0.43-4.22,0.59-6.24c0.59-1.18,0.49-2.41,0.21-3.71c-0.31-1.4-0.29-2.87-0.5-4.3c-0.38-2.53-0.83-5.04-1.24-7.57
						c-0.06-0.36-0.1-0.74-0.08-1.1c0.06-1.01,0.67-1.73,1.66-1.76c1.31-0.03,2.62,0.04,3.92,0.16c1.02,0.09,2.04,0.29,3.03,0.53
						c0.37,0.09,0.74,0.39,1.01,0.69c1.37,1.56,1.83,3.52,2.33,5.48c1.08,4.24,2.38,8.43,3.28,12.7
						C1225.97,2380.31,1226.9,2384.58,1227.05,2388.89z"/>
					<path className="st11" d="M1023.38,2398.89c0.83-1.74,1.71-3.34,2.36-5.03c1.51-3.92,2.63-7.93,3.26-12.11
						c0.5-3.32,0.81-6.71,1.97-9.92c0.54-1.48,0.97-3,1.54-4.47c0.35-0.9,0.58-0.93,1.8-0.66c0.71,0.16,1.08,0.63,0.96,1.32
						c-0.28,1.69-0.63,3.36-0.95,5.04c-0.02,0.13-0.13,0.26-0.12,0.37c0.03,0.24,0.11,0.47,0.17,0.7c0.21-0.14,0.55-0.24,0.61-0.43
						c0.3-0.95,0.57-1.92,0.78-2.9c0.3-1.39,0.44-2.81,0.86-4.16c0.24-0.77,0.92-1.39,1.34-2.11c1.03-1.75,2.6-2.5,4.58-2.65
						c0.7-0.05,1.38-0.39,2.07-0.61c0.15-0.05,0.33-0.18,0.44-0.13c0.89,0.37,1.87,0.64,2.61,1.21c0.69,0.52,0.74,1.47,0.63,2.35
						c-0.47,3.88-0.91,7.76-1.4,11.63c-0.1,0.75-0.4,1.47-0.64,2.2c-0.49,1.47-0.51,2.95-0.25,4.46c0.24,1.38,0.05,2.7-0.2,4.07
						c-0.4,2.14-0.59,4.33-0.79,6.51c-0.11,1.18-0.02,2.38-0.07,3.56c-0.01,0.23-0.23,0.56-0.44,0.65c-1.81,0.75-3.67,1.04-5.65,0.9
						c-1.11-0.08-2.25,0.25-3.38,0.28c-1.53,0.03-3.06-0.04-4.58-0.13c-0.14-0.01-0.39-0.45-0.37-0.67
						c0.16-1.86,0.38-3.73,0.57-5.59c0.05-0.45,0.01-0.91,0.1-1.34c0.13-0.6,0.33-1.19,0.53-1.77c0.23-0.69,0.45-1.39,0.73-2.06
						c0.25-0.6,0.61-1.16,0.88-1.75c0.13-0.28,0.2-0.59,0.22-0.9c0.01-0.12-0.21-0.32-0.34-0.34c-0.13-0.02-0.38,0.14-0.4,0.26
						c-0.27,1.23-1.45,2.12-1.31,3.51c0.01,0.07-0.03,0.16-0.08,0.22c-1.23,1.58-1.38,3.56-1.94,5.37c-0.64,2.05-2.04,3.43-3.6,4.69
						C1025.18,2399,1024.29,2399.16,1023.38,2398.89z"/>
					<path className="st9" d="M1240.28,2398.37c-3.79,0.09-7.45,0.08-10.89-0.72c-0.24-0.99-0.35-1.91-0.68-2.75
						c-0.83-2.11-1.05-4.27-0.99-6.52c0.03-1.26-0.2-2.52-0.26-3.78c-0.05-0.96-0.21-1.98,0.02-2.88c0.55-2.15,0.3-4.23-0.08-6.34
						c-0.32-1.76-0.61-3.53-0.87-5.3c-0.09-0.62-0.1-1.27-0.06-1.89c0.06-1.09,0.07-2.14-0.36-3.19c-0.39-0.95,0.82-2.64,1.95-2.8
						c0.89-0.13,1.78-0.19,2.67-0.27c1.46-0.13,2.58,0.41,3.28,1.72c1.55,2.88,2.82,5.9,2.91,9.2c0.11,4.02,0.8,7.91,1.94,11.75
						c0.75,2.51,0.98,5.12,1.01,7.75c0.02,1.6,0.23,3.21,0.34,4.81C1240.24,2397.54,1240.26,2397.93,1240.28,2398.37z"/>
					<path className="st11" d="M1236.15,2366.31c1.36-0.3,2.59-0.32,3.89,0.08c1.78,0.55,3.64,0.87,5.47,1.26
						c1.61,0.34,2.51,1.36,2.87,2.91c0.43,1.84,0.28,3.58-0.61,5.3c-0.74,1.42-1.47,2.89-1.86,4.43c-0.29,1.13-0.04,2.4-0.02,3.6
						c0.01,0.32,0.11,0.64,0.07,0.95c-0.35,2.99,0.95,5.51,2.27,8.01c1.07,2.03,1.69,4.19,2.28,6.48c-2.13,0.13-4.18,0.49-6.25,0.01
						c-0.85-0.2-1.69-0.4-2.55-0.53c-0.55-0.08-0.66-0.35-0.69-0.84c-0.16-2.84-0.21-5.7-0.56-8.53c-0.31-2.45-0.86-4.87-1.49-7.26
						c-0.64-2.44-1.13-4.9-1.17-7.41c-0.04-2.76-0.46-5.41-1.49-7.96C1236.25,2366.67,1236.22,2366.52,1236.15,2366.31z"/>
					<path className="st11" d="M1183.5,2349.96c0,0.53-0.02,1.06,0.01,1.58c0.03,0.61-0.28,0.8-0.85,0.89
						c-2.04,0.31-4.05,0.02-6.07-0.25c-2.53-0.34-5.04-0.93-7.63-0.72c-2.24,0.18-4.47,0.45-6.71,0.44
						c-2.31-0.01-4.62-0.33-6.93-0.46c-1.68-0.09-3.37-0.11-5.05-0.1c-1.21,0.01-2.43,0.09-3.64,0.18
						c-0.57,0.04-0.75-0.28-0.64-0.71c0.34-1.35,0.63-2.73,1.15-4.02c0.5-1.24,1.64-1.75,3.02-1.44c1.81,0.41,3.53,0.2,5.31-0.19
						c1.97-0.43,4.01-0.75,6.02-0.8c1.56-0.04,3.12,0.47,4.69,0.64c2.62,0.29,5.25,0.52,7.88,0.74c2.26,0.19,4.54,0.26,6.79,0.5
						c2.2,0.23,2.76,0.96,2.72,3.16c0,0.18,0,0.37,0,0.55C1183.54,2349.96,1183.52,2349.96,1183.5,2349.96z"/>
					<path className="st11" d="M1102.25,2361.99c-0.21,2.6-0.41,5.19-1.75,7.56c-1.4,2.48-1.77,5.24-1.69,8.05
						c0.14,5.33,0.37,10.67,0.52,16c0.04,1.42-0.08,2.84-0.16,4.26c-0.01,0.13-0.27,0.33-0.44,0.36c-1.22,0.2-2.44,0.38-3.66,0.55
						c-0.39,0.05-0.79,0.08-1.18,0.08c-0.71,0-1.42,0.02-2.12-0.07c-0.52-0.07-0.73-0.4-0.52-0.99c0.19-0.51,0.35-1.09,0.31-1.62
						c-0.1-1.14,0.42-1.98,0.95-2.91c0.81-1.44,1.62-2.93,2.09-4.5c0.59-1.96,0.21-3.96-0.34-5.93c-0.85-3.07-1.21-6.2-0.95-9.4
						c0.17-2.07,0.06-4.17,0.25-6.24c0.13-1.4,0.56-2.78,0.88-4.17c0.05-0.22,0.2-0.45,0.38-0.59c2.03-1.7,4.4-2.32,7.01-2.06
						c0.16,0.02,0.38,0.32,0.42,0.52C1102.3,2361.24,1102.25,2361.62,1102.25,2361.99z"/>
					<path className="st9" d="M1188.54,2346.3c5.24,0.7,10.38,0.3,15.41,1.37c2.21,0.47,4.51,0.5,6.76,0.76c0.57,0.07,1.14,0.2,1.7,0.36
						c2.06,0.6,3.28,2.14,3.46,4.33c0.22,2.59,0.22,2.52-2.37,2.36c-1.19-0.08-2.41,0.22-3.61,0.26c-0.94,0.03-1.9,0.04-2.82-0.13
						c-4.64-0.85-9.26-1.83-13.91-2.62c-2.36-0.4-4.78-0.51-7.16-0.76c-0.34-0.04-0.68-0.1-1.01-0.18
						c-0.48-0.12-0.64-0.45-0.64-0.95c0.01-1.18-0.08-2.37-0.05-3.55c0.01-0.23,0.31-0.62,0.53-0.66
						C1186.1,2346.64,1187.41,2346.47,1188.54,2346.3z"/>
					<path className="st11" d="M1126.46,2395.05c-0.25-1.11-0.6-2.15-0.69-3.21c-0.11-1.41,0.03-2.83-0.01-4.24
						c-0.07-2.97,0.45-5.87,0.8-8.81c0.24-2.04-0.14-4.15-0.25-6.23c-0.01-0.21-0.07-0.42-0.09-0.63c-0.12-1.26,0.22-1.64,1.47-1.63
						c1.27,0.01,2.54,0.03,3.8,0.06c1.07,0.02,1.77,0.51,1.83,1.57c0.2,3.5,0.3,7.01,0.44,10.51c0.1,2.58,0.18,5.16,0.29,7.74
						c0.06,1.26,0.2,2.52,0.26,3.79c0.01,0.18-0.22,0.48-0.39,0.53c-1.29,0.37-2.58,0.7-3.88,1c-0.32,0.07-0.68-0.05-1.01-0.09
						C1128.15,2395.29,1127.27,2395.17,1126.46,2395.05z"/>
					<path className="st9" d="M1076.12,2347.49c1.69,0,3.38,0.02,5.06,0c1.77-0.02,4.08,2.08,4.2,3.86c0.06,0.87-0.61,1.75-1.48,1.87
						c-1.63,0.23-3.24,0.19-4.86-0.28c-0.93-0.27-2.06-0.42-2.94-0.14c-2.19,0.7-4.42,0.65-6.65,0.85
						c-2.57,0.23-5.14,0.54-7.71,0.85c-0.56,0.07-1.11,0.32-1.65,0.53c-0.39,0.15-0.66,0.11-0.84-0.28
						c-0.03-0.07-0.09-0.13-0.14-0.19c-1.33-1.89-0.78-3.8,1.44-4.53c1.8-0.59,3.65-1.07,5.51-1.39c2.83-0.48,5.7-0.78,8.55-1.13
						c0.49-0.06,1-0.01,1.5-0.01C1076.12,2347.5,1076.12,2347.49,1076.12,2347.49z"/>
					<path className="st11" d="M1201.77,2384.31c-0.54-0.99-1.12-1.87-2.17-2.37c-0.83-0.39-0.74-1.24-0.6-1.96
						c0.38-1.95,0.2-3.83-0.45-5.69c-0.41-1.17-0.83-2.34-1.23-3.51c-0.17-0.5-0.29-1.02-0.47-1.51c-0.27-0.75-0.84-1.19-1.63-1.13
						c-0.64,0.05-0.85-0.26-0.92-0.78c-0.33-2.4-0.65-4.81-0.96-7.22c-0.06-0.47-0.07-0.94-0.11-1.48c2.9,0.15,5.78,0.31,8.55,0.45
						c-0.1,4.01-0.18,7.96,0.71,11.85c0.57,2.48,0.6,4.95,0.08,7.46C1202.18,2380.34,1202.04,2382.31,1201.77,2384.31z"/>
					<path className="st11" d="M1171.16,2399.03c-1.45,0.17-2.83,0.38-4.21,0.47c-1.19,0.08-1.23,0.01-1.32-1.2
						c-0.1-1.47-0.1-2.96-0.19-4.43c-0.07-1.1-0.15-2.22-0.35-3.3c-0.36-1.95-0.22-3.88,0.04-5.83c0.33-2.46,0.67-4.92,0.92-7.38
						c0.15-1.43,0.91-2.46,1.93-3.33c0.72-0.61,1.93-0.08,2.07,0.81c0.46,2.83,0.65,5.64-0.01,8.49c-0.45,1.95-0.22,3.96,0.19,5.9
						c0.55,2.58,0.62,5.18,0.75,7.78C1171.01,2397.68,1171.09,2398.36,1171.16,2399.03z"/>
					<path className="st12" d="M1065.36,2357.98c2.08-0.32,4.14-0.65,6.31-0.99c-0.08,0.84-0.16,1.6-0.21,2.35
						c-0.07,0.92-0.1,1.85-0.18,2.77c-0.12,1.47-0.26,2.93-0.42,4.39c-0.14,1.25,0.06,2.42,0.8,3.46c-1.27,1.6-1.63,3.51-2.05,5.38
						c-0.72,3.19-1.3,6.41-2.01,9.6c-0.38,1.7,0.13,3.3,0.3,4.94c0.29,2.8,0.63,5.6,0.96,8.47c-0.15,0.03-0.37,0.08-0.59,0.11
						c-0.65,0.08-0.92-0.12-0.92-0.87c0-2.93-0.04-5.86-0.16-8.79c-0.03-0.79-0.36-1.59-0.63-2.35c-0.36-0.99-0.5-1.95-0.36-3.02
						c0.2-1.57,0.18-3.16,0.32-4.74c0.2-2.34,0.7-4.68,0.63-7c-0.08-2.73-0.62-5.44-0.96-8.16c-0.19-1.49-0.35-2.99-0.55-4.48
						C1065.58,2358.65,1065.43,2358.25,1065.36,2357.98z"/>
					<path className="st12" d="M1159.25,2356.49c3.88-0.17,7.78-0.33,11.76-0.5c-1.08,4.09-1.23,8.08-1.15,12.2
						c-1.68-0.13-3.27-0.31-4.87-0.37c-0.77-0.03-1.56,0.16-2.44,0.26C1161.85,2364.14,1161.04,2360.12,1159.25,2356.49z"/>
					<path className="st11" d="M1216.65,2356.04c0-1.07,0.11-2.08-0.03-3.06c-0.13-0.95-0.51-1.86-0.78-2.79
						c1.72-0.18,3.31,0.53,4.91,1.09c2.84,0.99,5.66,2.02,8.48,3.05c0.69,0.25,1.37,0.56,2.01,0.92c1.67,0.95,2.36,3.64,1.34,5.27
						c-0.14,0.22-0.61,0.43-0.85,0.36c-0.79-0.22-1.55-0.56-2.33-0.86c-1.45-0.56-2.88-1.2-4.36-1.66c-2.54-0.8-5.11-1.49-7.66-2.22
						C1217.14,2356.07,1216.87,2356.07,1216.65,2356.04z"/>
					<path className="st9" d="M1047.17,2361.1c-0.53-0.25-0.98-0.54-1.47-0.67c-0.4-0.11-0.99-0.19-1.26,0.01
						c-0.93,0.73-1.96,0.41-2.96,0.52c-0.87,0.1-1.72,0.33-2.58,0.51c-0.15,0.03-0.29,0.15-0.44,0.18
						c-2.04,0.37-3.72,1.44-5.32,2.67c-0.21,0.16-0.43,0.33-0.67,0.41c-0.38,0.13-0.78,0.19-1.17,0.29
						c-0.04-0.39-0.17-0.79-0.1-1.15c0.34-1.67,1.05-3.01,2.72-3.83c1.11-0.55,2.11-1.4,3-2.28c1.08-1.05,2.3-1.64,3.79-1.73
						c0.9-0.05,1.79-0.18,2.69-0.16c0.62,0.01,1.28,0.1,1.84,0.34c1.88,0.83,2.25,1.56,2.02,3.61
						C1047.21,2360.22,1047.2,2360.61,1047.17,2361.1z"/>
					<path className="st12" d="M1103.24,2354.41c-0.28,1.58-0.57,3.07-0.82,4.57c-0.07,0.42-0.25,0.6-0.68,0.57
						c-0.97-0.07-1.95-0.09-2.92-0.19c-2.24-0.23-4.22,0.4-6.02,1.7c-0.74,0.53-1.5,1.02-2.42,1.65c0-1.09-0.11-2,0.03-2.86
						c0.21-1.29,1.11-2.16,2.13-2.92c0.67-0.5,1.25-1.1,1.92-1.59c0.58-0.43,1.19-0.96,1.86-1.09
						C1098.66,2353.77,1100.98,2354.3,1103.24,2354.41z"/>
					<path className="st9" d="M1164.42,2373.85c0.16-1.45,0.23-2.84,1.08-3.99c0.29-0.39,0.78-0.79,1.23-0.87
						c0.93-0.15,1.89-0.11,2.83-0.08c0.16,0.01,0.44,0.36,0.44,0.55c-0.01,1.23-0.09,2.47-0.13,3.47
						C1168.01,2373.24,1166.27,2373.54,1164.42,2373.85z"/>
					<path className="st12" d="M1131.52,2399.75c-1.08-0.07-2.15-0.14-3.23-0.23c-0.2-0.02-0.43-0.08-0.57-0.21
						c-0.44-0.38-0.48-1.67-0.02-2.45c0.32-0.53,0.85-0.74,1.51-0.59c1.12,0.26,2.27,0.42,3.37,0.75c0.82,0.25,1.37,0.86,1.42,1.8
						c0.03,0.54-0.18,0.87-0.74,0.89c-0.58,0.02-1.16,0-1.74,0C1131.53,2399.72,1131.53,2399.74,1131.52,2399.75z"/>
					<path className="st9" d="M1132.17,2395.73c1.37-0.3,2.94-0.63,4.69-1.01c0,0.92,0.01,1.91-0.02,2.9c0,0.13-0.19,0.3-0.34,0.37
						c-0.75,0.38-1.53,0.05-2.11-0.53C1133.71,2396.77,1132.86,2396.25,1132.17,2395.73z"/>
					<path className="st11" d="M1135.76,2393.92c0.17-1.16,0.35-2.31,0.52-3.47c0.07,0,0.15,0,0.22,0c0.08,1,0.2,1.99,0.19,2.99
						c0,0.21-0.49,0.41-0.75,0.61C1135.88,2394,1135.82,2393.96,1135.76,2393.92z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st6" d="M1575.4,2360.66c-2.9,0.15-5.8,0.3-8.7,0.45c-3.48,0.18-6.88,0.8-10.3,1.42
						c-2.44,0.44-4.76,0.84-7.11,1.61c-5.33,1.75-10.71,3.23-16.21,4.35c-7.94,1.62-15.93,3.12-23.97,4.21
						c1.33-0.18,2.66-0.36,3.99-0.54c-9.34,1.22-18.99,1.27-28.36,0.34c-2.07-0.08-3.99,0.35-5.78,1.29
						c-1.86,0.6-3.47,1.64-4.83,3.1c-2.6,2.6-4.56,6.87-4.39,10.61c0.17,3.88,1.45,7.9,4.39,10.61c1.01,0.78,2.02,1.56,3.04,2.35
						c2.34,1.36,4.86,2.04,7.57,2.05c6.33,0.63,12.73,0.71,19.08,0.43c7.88-0.34,15.67-1.59,23.43-2.93c7-1.2,14.06-2.39,20.93-4.22
						c2.72-0.72,5.42-1.54,8.1-2.4c1.03-0.33,2.07-0.76,3.12-1.03c3.03-0.75,6.2-1.12,9.27-1.68c-1.33,0.18-2.66,0.36-3.99,0.54
						c3.57-0.19,7.14-0.37,10.71-0.56c3.97-0.21,7.75-1.53,10.61-4.39c2.6-2.6,4.56-6.87,4.39-10.61c-0.17-3.88-1.45-7.9-4.39-10.61
						C1583.16,2362.44,1579.38,2360.45,1575.4,2360.66L1575.4,2360.66z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M1490.74,2368.1c-1.9,1.34-4.17,2.2-6.25,3.2c-2.67,1.28-5.37,2.67-7.54,4.72
						c-3.05,2.88-4.36,8.27-1.04,11.5c1.34,1.3,3.05,2.05,4.85,2.46c3.97,0.91,8.16,1.12,12.22,1.36c4.14,0.25,8.28,0.39,12.42,0.6
						c1.54,0.08,3.06,0.23,4.6,0.33c0.3,0.02-1.98-0.36-1.16-0.15c0.09,0.02,0.19,0.02,0.28,0.05c0.93,0.37-1.02-0.47-1.03-0.48
						c0.09,0.05-1.2-0.93-1.16-0.9c-4.16-3.68-1.4-7.61,1.15-9.27c-0.52,0.34,1.87-0.64,1.23-0.51c-0.21,0.04-0.43,0.12-0.64,0.17
						c-3.07,0.67-5.15,4.41-4.19,7.38c1.05,3.23,4.09,4.91,7.38,4.19c4.06-0.89,6.81-5.31,4.51-9.21c-1.51-2.57-4.01-3.01-6.66-3.3
						c-3.38-0.36-6.8-0.41-10.19-0.56c-4.24-0.19-8.5-0.37-12.72-0.82c-0.44-0.05-0.87-0.1-1.31-0.15
						c-1.01-0.12,1.65,0.24,0.81,0.11c-0.22-0.03-0.44-0.06-0.66-0.1c-0.68-0.11-1.37-0.23-2.04-0.4c-0.24-0.06-0.47-0.14-0.71-0.21
						c-1.01-0.27,1.65,0.84,0.7,0.29c-0.06-0.03-0.12-0.09-0.18-0.11c0.83,0.3,1.07,0.82,1.47,1.35c0.64,0.83,0.28,0.23,0.69,1.2
						c0.49,1.18,0.32,0.8,0.23,0.52c-0.15-0.48,0.17-1.01,0.15,1.14c0.01-0.7-0.19,1.29-0.16,1.14c0.15-0.73-0.86,1.6-0.28,0.68
						c0.41-0.65-1.19,1.28-0.36,0.47c0.2-0.2,0.42-0.39,0.62-0.58c0.61-0.59-0.72,0.57-0.67,0.51c0.09-0.11,0.28-0.21,0.4-0.29
						c1.27-0.85,2.62-1.58,3.99-2.25c2.42-1.2,5.1-2.15,7.31-3.71c2.56-1.8,4.01-5.33,2.15-8.21
						C1497.31,2367.71,1493.48,2366.16,1490.74,2368.1L1490.74,2368.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M1498.65,2391.19c8.98,4.84,19.04,3.34,28.28,0.1c3.83-1.35,7.58-2.84,11.49-3.94
						c4.06-1.14,8.17-2.08,12.23-3.21c4.78-1.33,9.53-2.96,13.9-5.33c2.76-1.5,3.87-5.54,2.15-8.21c-1.81-2.8-5.26-3.76-8.21-2.15
						c-0.79,0.43-1.59,0.83-2.4,1.21c-0.25,0.12-1.55,0.69-0.72,0.32c0.79-0.34,0.15-0.06-0.01,0c-0.31,0.12-0.62,0.24-0.93,0.36
						c-1.83,0.69-3.7,1.29-5.58,1.84c-3.79,1.1-7.63,2.01-11.44,3.02c-3.78,1-7.45,2.23-11.11,3.6c-3.76,1.41-7.6,2.72-11.57,3.39
						c-0.16,0.03-0.64,0.16-0.83,0.13c0.29,0.05,1.19-0.13,0.35-0.06c-0.62,0.05-1.24,0.11-1.87,0.13c-1.11,0.04-2.2-0.05-3.3-0.1
						c-0.71-0.03,0.61,0.06,0.57,0.09c-0.14,0.1-0.86-0.15-1.02-0.18c-0.54-0.11-1.08-0.24-1.61-0.4c-0.2-0.06-0.4-0.12-0.6-0.19
						c-0.09-0.03-1.31-0.54-0.47-0.14c0.74,0.35,0.07,0.03-0.07-0.04c-0.39-0.19-0.78-0.39-1.17-0.59
						c-2.76-1.49-6.66-0.78-8.21,2.15C1495.02,2385.77,1495.7,2389.6,1498.65,2391.19L1498.65,2391.19z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M1509.34,2370.38c-3.36-0.56-6.84-0.03-10.2,0.32c-5.03,0.53-10.07,1.17-15.04,2.08
						c-4.1,0.75-9.73,1.65-11.06,6.34c-1.14,3.98,1.94,7.61,5.74,8.27c2.46,0.43,4.9,0.39,7.38,0.25c2.73-0.16,5.45-0.43,8.18-0.66
						c1.14-0.09,2.29-0.19,3.44-0.22c0.39-0.01,0.79-0.02,1.18-0.01c0.15,0.01,0.33,0.05,0.48,0.03c0.22-0.02-1.94-0.36-1.12-0.13
						c3.02,0.86,6.67-1.05,7.38-4.19c0.73-3.2-0.96-6.46-4.19-7.38c-1.66-0.48-3.51-0.36-5.22-0.25c-2.18,0.14-4.35,0.36-6.53,0.54
						c-2.17,0.18-4.35,0.35-6.53,0.38c-0.83,0.01-1.64-0.04-2.46-0.09c1.06,0.06,1.56,0.17,0.52,0.02
						c-0.77-0.11,0.98,0.43,1.02,0.45c-0.69-0.37,1.07,0.9,0.89,0.72c1.86,1.86,1.97,5.36,0.41,7.38c-0.74,0.96-0.58,0.71-0.4,0.53
						c0.17-0.17-1.08,0.82-1.06,0.82c0.05,0,0.19-0.12,0.23-0.14c0.91-0.57-1.4,0.5-0.79,0.32c0.27-0.08,0.53-0.19,0.79-0.27
						c1.53-0.47,3.12-0.79,4.69-1.08c2.02-0.38,4.04-0.69,6.08-0.98c0.25-0.04,0.51-0.07,0.76-0.11c1.09-0.15-0.98,0.13-0.58,0.08
						c0.59-0.07,1.18-0.15,1.77-0.23c1.03-0.13,2.06-0.25,3.09-0.36c2.05-0.22,4.09-0.43,6.15-0.57c0.69-0.05,1.38-0.09,2.07-0.11
						c0.26-0.01,0.52,0,0.78-0.01c1.11-0.02-1-0.17-1.04-0.17c1.64,0.27,3.14,0.26,4.62-0.6c1.24-0.73,2.43-2.16,2.76-3.59
						C1514.19,2374.86,1512.65,2370.93,1509.34,2370.38L1509.34,2370.38z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M1564.34,2379.46c3.16-0.32,6.3-0.74,9.44-1.22c1.16-0.18,2.33-0.33,3.49-0.52
						c-0.53,0.09-1.27,0.17-0.3,0.04c0.33-0.04,0.66-0.08,0.99-0.12c0.9-0.11,1.81-0.2,2.71-0.29c2.17-0.22,4.03-1.01,5.18-2.97
						c1.15-1.96,0.96-4.09,0-6.06c-1.24-2.54-2.53-5.03-5.19-6.36c-3.02-1.51-6.58-1.48-9.82-2.28c-1.66-0.41-3.31-0.9-4.92-1.47
						c-0.47-0.17-0.94-0.34-1.41-0.52c-0.17-0.07-1.55-0.6-0.67-0.26c0.87,0.34-0.49-0.22-0.66-0.3c-0.46-0.21-0.91-0.42-1.36-0.64
						c-3.21-1.57-6.25-3.47-9.07-5.66c-1.14-0.88-3.31-0.97-4.62-0.6c-1.39,0.38-2.9,1.46-3.59,2.76c-0.74,1.39-1.1,3.08-0.6,4.62
						c0.51,1.59,1.45,2.57,2.76,3.59c5.71,4.41,12.36,7.66,19.31,9.61c1.65,0.46,3.32,0.85,5.01,1.14c1.39,0.24,2.83,0.34,4.2,0.69
						c0.09,0.02,0.63,0.21,0.65,0.2c-0.05,0.07-1.67-0.81-0.74-0.27c0.69,0.4-1.19-1-0.73-0.56c0.72,0.69-0.78-1.2-0.46-0.65
						c0.57,0.98,1.07,1.97,1.57,3c1.73-3.01,3.45-6.02,5.18-9.03c-3.16,0.32-6.3,0.74-9.44,1.22c-1.16,0.18-2.33,0.33-3.49,0.52
						c0.53-0.09,1.27-0.17,0.3-0.04c-0.33,0.04-0.66,0.08-0.99,0.12c-0.9,0.11-1.81,0.2-2.71,0.29c-1.63,0.16-3.04,0.56-4.24,1.76
						c-1.04,1.04-1.82,2.75-1.76,4.24C1558.48,2376.44,1561,2379.79,1564.34,2379.46L1564.34,2379.46z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M458.86,2531.6c-6.04,2.49-13.55,5.98-13.68,13.6c-0.06,3.53,2.21,6.66,5.13,8.42
						c2.17,1.31,4.5,1.74,6.98,2c0.68,0.07,1.35,0.08,2.03,0.02c1.34-0.12,2.67-0.31,3.97-0.69c0.94-0.27,1.86-0.56,2.82-0.7
						c-0.68,0.09-1.35,0.18-2.03,0.27c1.17-0.13,2.33-0.07,3.5,0.08c-0.68-0.09-1.35-0.18-2.03-0.27c3.64,0.54,7.24,1.52,10.82,2.31
						c3.64,0.81,7.28,1.61,10.92,2.42c7.63,1.69,15.3,3.79,23.12,4.34c4.03,0.28,8.07,0.23,12.08,0.77
						c-0.68-0.09-1.35-0.18-2.03-0.27c5.11,0.72,9.86,2.85,14.9,3.85c5.59,1.1,11.28,1.38,16.95,0.93
						c10.86-0.87,21.54-3.49,32.18-5.7c2.58-0.54,5.29-0.9,7.77-1.81c2.3-0.84,4.01-1.75,5.89-3.3c0.97-0.8,1.66-1.85,2.6-2.66
						c-0.51,0.4-1.03,0.79-1.54,1.19c0.35-0.27,0.72-0.48,1.12-0.66c-0.61,0.26-1.21,0.51-1.82,0.77c0.41-0.15,0.81-0.25,1.24-0.32
						c-0.68,0.09-1.35,0.18-2.03,0.27c1.73-0.22,3.45,0.1,5.18,0.18c2.47,0.1,4.97-0.23,7.39-0.69c4.32-0.82,8.45-2.63,12.39-4.52
						c1.39-0.66,2.75-1.39,4.14-2.04c1.09-0.51,2.19-1,3.22-1.63c2.83-1.73,5.08-4.34,5.11-7.85c0.02-2.15-0.87-4.29-2.4-5.81
						c-1.03-1.03-2.06-1.49-3.32-2.04c-3.88-1.68-7.88-3.07-11.78-4.7c0.61,0.26,1.21,0.51,1.82,0.77c-0.6-0.26-1.21-0.52-1.77-0.85
						c-1.69-1.01-3.99-1.29-5.88-0.77c-1.77,0.49-3.69,1.85-4.56,3.5c-1.95,3.69-0.92,8.24,2.74,10.43
						c1.89,1.13,4.06,1.84,6.12,2.63c2.23,0.85,4.46,1.68,6.66,2.6c-0.61-0.26-1.21-0.51-1.82-0.77c0.81,0.34,1.72,0.64,2.47,1.12
						c-0.51-0.4-1.03-0.79-1.54-1.19c0.05,0.04,0.1,0.08,0.14,0.13c-0.4-0.51-0.79-1.03-1.19-1.54c0.03,0.04,0.06,0.08,0.08,0.13
						c-0.26-0.61-0.51-1.21-0.77-1.82c0.01,0.02,0.01,0.05,0.02,0.07c-0.09-0.68-0.18-1.35-0.27-2.03c0.01,0.05,0.01,0.1,0,0.15
						c0.09-0.68,0.18-1.35,0.27-2.03c-0.01,0.05-0.03,0.1-0.05,0.15c0.26-0.61,0.51-1.21,0.77-1.82c-0.03,0.06-0.06,0.11-0.1,0.16
						c0.4-0.51,0.79-1.03,1.19-1.54c-0.15,0.19-0.34,0.35-0.53,0.51c0.51-0.4,1.03-0.79,1.54-1.19c-1.31,0.97-2.87,1.6-4.34,2.29
						c-2.25,1.07-4.45,2.23-6.74,3.21c0.61-0.26,1.21-0.51,1.82-0.77c-2.89,1.21-5.87,2.17-8.98,2.6c0.68-0.09,1.35-0.18,2.03-0.27
						c-1.49,0.19-2.99,0.25-4.49,0.15c-1.31-0.09-2.61-0.29-3.92-0.21c-2.4,0.15-4.88,0.66-6.87,2.09
						c-1.03,0.74-1.86,1.41-2.66,2.34c-0.47,0.54-0.93,1.06-1.49,1.51c0.51-0.4,1.03-0.79,1.54-1.19c-0.63,0.47-1.31,0.84-2.03,1.15
						c0.61-0.26,1.21-0.51,1.82-0.77c-2.42,0.97-5.08,1.33-7.62,1.86c-2.94,0.61-5.89,1.22-8.83,1.84
						c-5.71,1.19-11.43,2.43-17.21,3.21c0.68-0.09,1.35-0.18,2.03-0.27c-5.65,0.75-11.37,1.04-17.03,0.3
						c0.68,0.09,1.35,0.18,2.03,0.27c-2.84-0.39-5.59-1.09-8.32-1.94c-2.63-0.82-5.25-1.68-7.97-2.15
						c-3.29-0.57-6.61-0.84-9.94-0.98c-2.87-0.12-5.74-0.2-8.59-0.57c0.68,0.09,1.35,0.18,2.03,0.27c-3.13-0.43-6.21-1.15-9.29-1.83
						c-3.21-0.71-6.41-1.42-9.62-2.13c-6.44-1.43-12.86-3.01-19.33-4.27c-2.26-0.44-4.64-0.85-6.95-0.6
						c-1.41,0.15-2.77,0.35-4.12,0.75c-0.81,0.24-1.61,0.49-2.45,0.61c0.68-0.09,1.35-0.18,2.03-0.27c-0.82,0.1-1.65,0.09-2.48-0.02
						c0.68,0.09,1.35,0.18,2.03,0.27c-0.77-0.11-1.52-0.29-2.24-0.59c0.61,0.26,1.21,0.51,1.82,0.77c-0.39-0.17-0.75-0.37-1.09-0.63
						c0.51,0.4,1.03,0.79,1.54,1.19c-0.2-0.16-0.38-0.34-0.54-0.54c0.4,0.51,0.79,1.03,1.19,1.54c-0.09-0.13-0.17-0.26-0.24-0.41
						c0.26,0.61,0.51,1.21,0.77,1.82c-0.06-0.14-0.1-0.29-0.13-0.44c0.09,0.68,0.18,1.35,0.27,2.03c-0.02-0.16-0.02-0.32-0.01-0.48
						c-0.09,0.68-0.18,1.35-0.27,2.03c0.03-0.21,0.08-0.4,0.16-0.6c-0.26,0.61-0.51,1.21-0.77,1.82c0.12-0.27,0.27-0.51,0.44-0.74
						c-0.4,0.51-0.79,1.03-1.19,1.54c0.39-0.5,0.85-0.92,1.35-1.32c-0.51,0.4-1.03,0.79-1.54,1.19c1.67-1.28,3.6-2.19,5.52-3.01
						c-0.61,0.26-1.21,0.51-1.82,0.77c0.2-0.08,0.4-0.17,0.6-0.25c0.99-0.24,1.86-0.69,2.59-1.36c0.83-0.54,1.48-1.25,1.96-2.14
						c0.96-1.63,1.36-4.04,0.77-5.88c-0.6-1.85-1.74-3.63-3.5-4.56C463.05,2531.48,460.71,2530.84,458.86,2531.6L458.86,2531.6z"/>
				</g>
				<path d="M491.24,2550.88l15.21,3.66l9.73,1.74l13.07,1.93h11.77h8.81l17.64-2.4l15.37-4.08l5.76-2.25l1.19-2.16l-1.2-6.54
					l-4.28-12.05c0,0-4.62-9-4.62-9c-0.7-1.36-1.25-3.64-2.36-4.68c-2.4-2.26-4.19-3.78-6.79-5.04c-5.16-2.51-10.3-4.92-15.57-7.17
					c-1.58-0.67-3.74-0.85-5.43-1.14c-2.35-0.4-4.73-0.65-7.12-0.75c-10.77-0.42-22.01,2.08-31.33,7.56
					c-2.32,1.36-8.15,6.12-9.65,8.42c-1.76,2.7-0.09,2.74-1.65,5.57c-0.19,0.35-0.48,0.73-0.61,1.11l-5.37,15.11L491.24,2550.88z"/>
				<polygon points="452.59,2543.34 466.07,2548.08 485.05,2551.93 489.93,2550.88 493.75,2535.27 498.76,2523.63 503.29,2518.34 
					498.94,2516.53 486.41,2515.53 476.39,2519.69 467.06,2521.82 460.26,2529.73 455.06,2536.06 				"/>
				<path d="M522.65,2554.7c-0.86-2.42-2.08-4.68-3.12-7.01c-0.28-0.63-0.28-1.08,0.09-1.68c1.22-1.97,2.37-3.98,3.54-5.98
					c0.26-0.44,0.51-0.75,1.1-0.78c2.38-0.12,4.75-0.28,7.13-0.48c0.66-0.05,1.03,0.16,1.35,0.73c1.21,2.11,2.45,4.22,3.73,6.29
					c0.41,0.67,0.27,1.18-0.08,1.8c-1.67,3.02-3.49,5.95-5,9.06c-1.06,2.18-4.42,0.78-6.23,0.76c-0.99-0.01-1.32-0.41-1.84-1.32
					C523.34,2556.11,522.89,2555.17,522.65,2554.7z"/>
				<path d="M554.62,2556.14c-1.81-2.99-3.59-5.99-5.43-8.96c-0.41-0.67-0.5-1.16-0.08-1.88c1.18-2.02,2.28-4.1,3.36-6.18
					c0.31-0.59,0.66-0.84,1.34-0.79c2.23,0.16,4.46,0.28,6.69,0.36c0.67,0.02,1.05,0.31,1.37,0.85c1.21,2.01,2.43,4.01,3.69,5.99
					c0.35,0.55,0.36,1.01,0.11,1.6c-1.04,2.5-2.04,5.02-3.05,7.53c-0.05,0.25-0.77,1.55-0.77,1.55l0,0
					c-0.73,0.78-2.75,0.72-4.84,0.95C555.87,2557.29,555.08,2557.25,554.62,2556.14z"/>
				<path d="M532.36,2556.75c1.69-2.89,3.4-5.76,5.06-8.67c0.42-0.74,0.89-1.09,1.81-1.06c2.5,0.07,5,0.05,7.5,0.01
					c0.7-0.01,1.1,0.2,1.47,0.8c1.8,2.91,3.66,5.79,5.5,8.69c0.71,0.94,0.32,1.21-0.72,1.29c-6.66,0.48-13.33,0.51-20,0.19
					C531.75,2557.94,531.82,2557.52,532.36,2556.75z"/>
				<path d="M499.88,2548.49c-0.94-4.37,0.25-4.74,1.7-6.93c1.42-2.15,2.32-2.55,4.6-2.28c2.05,0.24,2.25,0.79,2.53,1.99
					c0.33,1.43,0.73,2.85,1.18,4.24c0.26,0.8,0.17,1.39-0.32,2.08c-0.64,0.89-1.25,1.81-1.85,2.74c-0.3,0.46-0.6,0.93-0.9,1.39
					c-0.45,0.69-0.95,1.36-1.28,2.13l0,0c-0.08,0.18-0.25,0.28-0.43,0.27c-1.43-0.15-2.64-0.72-4.11-0.99
					c-0.4-0.07-0.38-0.46-0.43-0.79C500.34,2551.05,500.11,2549.77,499.88,2548.49z"/>
				<path d="M507.74,2552.62c0.41-0.59,0.86-1.15,1.25-1.75c0.6-0.91,1.17-1.85,1.78-2.75c0.38-0.57,0.91-0.87,1.55-0.91
					c1.51-0.1,3.01-0.03,4.52-0.16c0.95-0.09,1.7,0.12,2.11,1.13c0.91,2.24,2.94,7.07,3.03,7.28c0,0.01,0,0.01,0.01,0.02
					c0.02,0.07,0.19,0.53,0.44,1.23c0.08,0.22-0.09,0.44-0.3,0.42c-1.94-0.26-3.86-0.49-5.76-0.82c-2.93-0.51-5.86-1.1-8.78-1.69
					c-0.18-0.04-0.39-0.11-0.56-0.19c-0.17-0.07-0.24-0.29-0.14-0.46l0.28-0.46C507.34,2553.2,507.54,2552.9,507.74,2552.62z"/>
				<path d="M577.79,2551.36c0,0-1.22-2.34-1.95-3.41c-0.75-1.09-1.05-2.11-0.64-3.41c0.4-1.28,0.64-2.61,0.93-3.92
					c0.08-0.38,0.19-0.66,0.63-0.72c0.96-0.13,1.92-0.29,2.88-0.45c0.47-0.08,0.71,0.23,0.94,0.55c1.01,1.36,2,2.73,3.03,4.07
					c0.55,0.72,0.3,1.05,0.29,2.3c-0.09,1.3-0.23,2.6-0.25,3.91c-0.01,0.72-0.29,1.07-0.94,1.27c-1,0.3-2.01,0.63-2.98,1.02
					c-0.51,0.2-0.87,0.06-1.24-0.25c0,0,0.01,0.01,0.01,0.01S577.79,2551.36,577.79,2551.36L577.79,2551.36z"/>
				<path d="M563.44,2554.84c0.98-2.42,1.96-4.83,2.95-7.25c0.13-0.31,0.26-0.64,0.53-0.85c0.33-0.26,0.79-0.27,1.21-0.27
					c1.2,0,2.39,0.01,3.59,0.01c0.29,0,0.58,0,0.87,0c0.26,0,0.57-0.05,0.82,0.03c0.44,0.15,0.74,0.62,0.97,1
					c0.71,1.13,1.41,2.27,2.12,3.4c0.19,0.31,0.65,0.88,0.65,0.88l0.53,0.82c0,0,0.13,0.5-0.11,0.67c-0.24,0.17-0.53,0.25-0.81,0.32
					c-4.47,1.22-9,2.21-13.57,2.99c-0.09,0.02-0.2,0.03-0.28-0.04c-0.1-0.09-0.06-0.26-0.01-0.39
					C563.08,2555.73,563.26,2555.29,563.44,2554.84z"/>
				<path d="M496.25,2534.87c1.08-1.23,2.2-2.44,3.23-3.72c0.48-0.6,0.84-0.55,1.48-0.26c1.71,0.77,2.15,1.87,2,3.87
					c-0.1,1.35-0.31,2.71-0.82,3.97c-0.24,0.61-0.55,1.19-0.92,1.72c-0.49,0.71-1.1,1.34-1.62,2.03c-0.31,0.42-0.65,0.92-1.11,1.18
					c-0.91,0.53-2.14-0.46-2.9-0.89c-0.49-0.28-0.67-0.57-0.6-1.15c0.27-2.01,0.49-4.03,0.73-6.05
					C495.9,2535.35,496.08,2535.11,496.25,2534.87z"/>
				<path d="M493.2,2544.5c0.31-0.45,0.42-1.02,1.07-1.11c0.44-0.06,0.79,0.19,1.16,0.39c0.83,0.46,1.96,0.74,2.43,1.64
					c0.29,0.56,0.3,1.2,0.39,1.81c0.13,0.87,0.34,1.72,0.53,2.58c0.16,0.71,0.22,1.44,0.37,2.15c0.14,0.67,0.05,0.97-0.73,0.75
					c-1.94-0.56-3.88-1.1-5.83-1.63c-0.48-0.13-0.76-0.34-0.66-0.87C492.25,2548.29,492.01,2546.24,493.2,2544.5z"/>
				<path d="M504.91,2516.12c1.41-0.93,2.88-1.77,4.2-2.8c1.08-0.84,1.89-0.19,2.78,0.19c0.45,0.2,0.11,0.46-0.02,0.7
					c-2.02,3.79-5.27,6.27-8.88,8.37c-0.85,0.5-1.46-0.22-2.03-0.58c-0.53-0.33,0.68-0.94,0.9-1.24c0.86-1.18,1.09-2.46,1.93-3.65
					C504.09,2516.68,504.48,2516.43,504.91,2516.12z"/>
				<path d="M516,2508.08c0.01,0,0.02-0.01,0.03-0.01c1.34-0.5,2.73-0.89,4-1.53c1.15-0.58,2.17-0.47,3.32-0.03
					c0.28,0.11,0.35,0.48,0.12,0.68c-0.54,0.48-1.27,1.27-1.41,1.4c-0.66,0.62-1.33,1.24-2.1,1.72c-1.45,0.9-3.17,1.14-4.7,1.87
					c-1.44,0.68-2.74,1.23-4.11,0.37c-0.23-0.14-0.27-0.46-0.07-0.64c0.89-0.83,1.79-1.68,2.7-2.52
					C514.41,2508.76,515.22,2508.45,516,2508.08z"/>
				<path d="M500.04,2522.63c0.77-0.55,0.15-0.69,1.36,0.23c0.57,0.43,0.75,0.64,0.5,1.37c-1.23,3.55-2.97,6.76-5.72,9.37
					c-0.27,0.26-0.47,0.59-0.7,0.89c-1.02,0.45-0.58-0.28-0.44-0.78c0.7-2.6,2.06-4.42,3.08-6.91
					C498.5,2525.86,499.19,2523.27,500.04,2522.63z"/>
				<path d="M567.54,2510.15c0.01,0,0.01,0.01,0.01,0.01c0.58,0.55,1.18,1.08,1.72,1.67c0.28,0.3,0.91,0.58,0.48,1.1
					c-0.34,0.41-0.85,0.72-1.47,0.43c-3.18-1.44-6.62-2.39-9.17-4.99c-0.47-0.48-1.14-0.76-1.51-1.32
					c-0.03-0.05-0.02-0.11,0.02-0.15c1.03-0.82,2.06-0.57,3.13-0.09c0.65,0.29,1.34,0.49,2.01,0.73c0,0,0.01,0,0.01,0
					C564.44,2508.29,566.04,2509.13,567.54,2510.15z"/>
				<path d="M584.58,2546.23c-0.05-1.43,0.67-2.28,1.93-2.91c0.81-0.41,1.17-0.33,1.54,0.44c0.18,0.37,0.48,0.69,0.73,1.03
					c0.45,0.81,0.85,1.59,1.03,2.54c0.22,1.17-0.02,1.8-1.19,2.16c-1.08,0.33-2.12,0.82-3.16,1.28c-0.82,0.36-1.22,0.34-1.09-0.75
					C584.51,2548.76,584.51,2547.49,584.58,2546.23z"/>
				<path d="M578.04,2519.61c0.32,0.73,0.53,1.53,0.53,2.3c0,0.37-0.43,0.58-0.73,0.36c-1.7-1.22-3.51-2.17-4.91-3.68
					c-1.08-1.17-2.02-2.48-2.98-3.76c-0.21-0.28-0.81-0.56-0.33-1.03c0.42-0.39,0.87-0.75,1.56-0.32c0.88,0.56,1.85,0.99,2.78,1.47
					c0,0,0.64,0.5,0.64,0.5c0.74,0.58,1.42,1.15,2.02,1.89c0.29,0.36,0.55,0.73,0.8,1.12
					C577.64,2518.81,577.86,2519.2,578.04,2519.61z"/>
				<path d="M586.63,2534.71c-0.16-0.04-0.39-0.02-0.46-0.12c-1.72-2.27-3.94-4.17-5.01-6.92c-0.43-1.1-0.93-2.17-1.47-3.21
					c-0.37-0.71-0.44-1.42-0.44-2.19c0-0.98-0.32-1.9-0.76-2.78c-0.06-0.22-0.32-0.81-0.09-0.64c0.71,0.51,1.18,1.17,1.42,1.63
					c0.22,0.42,0.46,0.83,0.71,1.23c0.51,0.82,1.06,1.63,1.51,2.48c1.63,3.06,3.1,6.19,4.33,9.43
					C586.5,2533.96,586.54,2534.34,586.63,2534.71z"/>
				<path d="M535.74,2503c0.03,0.01,0.04,0.05,0.02,0.07c-1.09,1.07-2.49,1.57-3.91,1.9c-1.88,0.44-3.82,0.65-5.7,1.1
					c-1.07,0.26-2.44,0.18-3.41-0.39c0.06-0.3,0.43-0.42,0.67-0.54c0.68-0.33,1.35-0.69,2.05-0.97c0.73-0.29,1.47-0.54,2.22-0.74
					c1.49-0.4,3.03-0.62,4.58-0.65c0.6-0.01,1.19,0,1.79,0.05c0.3,0.02,0.6,0.05,0.9,0.08
					C535.05,2502.91,535.53,2502.96,535.74,2503z"/>
				<path d="M535.15,2502.33c3.93-0.61,7.85-0.88,11.77,0.02c0.05,0.01,0.04,0.08,0,0.09c-3.93,0.57-7.85,0.77-11.77-0.02
					C535.1,2502.41,535.1,2502.34,535.15,2502.33z"/>
				<path d="M512.97,2509.22c-0.56,1.03-1.57,1.62-2.42,2.33c-1.9,1.58-3.94,2.99-6.11,4.17c1.04-2.78,3.33-4.26,5.81-5.5
					C511.11,2509.79,512.06,2509.55,512.97,2509.22z"/>
				<path d="M573.84,2514.54c-0.08-0.05-0.17-0.1-0.26-0.15c-0.23-0.13-0.45-0.26-0.67-0.4c-0.43-0.27-0.86-0.56-1.27-0.86
					c-0.41-0.3-0.81-0.62-1.2-0.95c-0.39-0.33-0.77-0.67-1.15-1.01c-0.19-0.17-0.38-0.35-0.57-0.53c-0.09-0.09-0.19-0.18-0.28-0.27
					c-0.05-0.04-0.09-0.09-0.14-0.13c-0.01-0.01-0.14-0.12-0.14-0.13c0.07-0.21,0.23-0.22,0.39-0.16c2.44,0.82,4.51,2.18,5.99,4.33
					c0.14,0.2,0.6,0.54-0.09,0.66c-0.07-0.04-0.15-0.07-0.21-0.12c-0.12-0.07-0.22-0.15-0.33-0.23
					C573.89,2514.57,573.86,2514.56,573.84,2514.54z"/>
				<path d="M589.08,2544.49c-1.01-1.17-1.4-2.81-1.69-4.29c-0.3-1.55-0.43-3.12-0.68-4.68c-0.02-0.09,0.1-0.15,0.16-0.09
					c0.6,0.62,0.61,1.5,0.87,2.27c0.35,1.01,0.63,2.04,0.87,3.08c0.13,0.56,0.18,1.15,0.28,1.72c0.1,0.54,0.52,1.03,0.49,1.6
					C589.37,2544.28,589.27,2544.47,589.08,2544.49C589.08,2544.49,589.08,2544.49,589.08,2544.49z"/>
				<path d="M523.42,2504.64c0.02,0.06-4.06,1.66-4.44,1.81c-0.78,0.32-1.55,0.64-2.33,0.94c-0.4,0.16-0.8,0.31-1.2,0.45
					c-0.37,0.13-0.78,0.36-1.17,0.28c2.72-2.15,5.71-3.4,9.07-3.7C523.37,2504.42,523.4,2504.56,523.42,2504.64z"/>
				<path d="M494.68,2535.27c-0.29,2.28-0.55,4.56-0.87,6.83c-0.1,0.76-0.56,1.39-0.98,2.01c-0.05-0.2-0.18-0.42-0.14-0.59
					c0.62-2.7,0.36-2.12,1.15-4.79C493.87,2538.6,494.53,2535.17,494.68,2535.27z"/>
				<path d="M542.64,2545.92c-0.51,0.02-1.01,0.04-1.52,0.08c-1.21,0.09-2.34,0.18-3.25-0.64c-0.6-0.54-2.82-4.61-2.82-4.61l0,0
					c-0.52-0.85-1.18-1.81-1.11-2.81c0.07-0.98,0.6-1.85,1.12-2.69c0.65-1.04,1.29-2.08,1.94-3.12c0.28-0.44,0.57-0.91,1.04-1.14
					c0.36-0.18,0.77-0.19,1.17-0.19c1.61-0.03,3.22-0.06,4.83-0.09c0.56-0.01,1.86-0.03,2.27-0.03c0.1,0,0.2,0.02,0.28,0.07
					c0.46,0.27,0.82,0.96,1.07,1.37c1.14,1.81,2.29,3.62,3.43,5.44c0.13,0.21,0.27,0.43,0.27,0.68c0,0.23-0.11,0.45-0.21,0.65
					c-1.03,1.95-2.06,3.9-3.09,5.85c-0.21,0.4-0.44,0.82-0.83,1.05c-0.41,0.24-0.92,0.21-1.39,0.18
					C544.78,2545.9,543.71,2545.89,542.64,2545.92z"/>
				<path d="M524.74,2538.01c-0.66,0.18-1-0.09-1.26-0.65c-0.79-1.74-1.6-3.47-2.45-5.18c-0.27-0.54-0.24-0.96,0.05-1.48
					c0.96-1.74,1.9-3.49,2.8-5.26c0.34-0.66,0.8-0.91,1.48-0.98c1.94-0.2,3.87-0.44,5.8-0.7c0.72-0.1,1.22,0.01,1.66,0.73
					c0.99,1.62,2.09,3.17,3.17,4.73c0.37,0.53,0.4,0.96,0.06,1.55c-1.17,1.99-2.3,4-3.42,6.02c-0.25,0.46-0.52,0.75-1.07,0.78
					C529.25,2537.7,526.96,2537.87,524.74,2538.01z"/>
				<path d="M542.16,2529.6c-0.74,0-1.48-0.01-2.21,0c-1.53,0.01-2.7-1.05-3.62-2.25c-0.47-0.61-0.88-1.26-1.32-1.89
					c-0.47-0.67-1.17-1.28-1.32-2.14c-0.17-0.93,0.59-1.75,1.06-2.43c0.83-1.22,1.75-3.05,3.03-3.79c0.8-0.46,1.73-0.33,2.6-0.34
					c1.52-0.02,3.16-0.29,4.64,0.16c1.03,0.31,1.84,1.06,2.5,1.93c0.54,0.71,1.07,1.42,1.61,2.13c0.43,0.57,1.14,1.23,1.2,2.01
					c0.04,0.58-0.29,1.06-0.6,1.5c-0.81,1.17-1.25,2.65-2.21,3.69c-0.75,0.82-1.8,1.32-2.87,1.37
					C543.83,2529.6,542.99,2529.6,542.16,2529.6z"/>
				<path d="M558.15,2537.67l-4.76-0.4c-0.52,0.01-0.87-0.14-1.17-0.63c-1.3-2.17-2.64-4.33-3.99-6.47
					c-0.27-0.43-0.33-0.78-0.05-1.24c1.02-1.67,2.03-3.36,3.01-5.06c0.3-0.53,0.69-0.59,1.2-0.52c1.99,0.28,3.99,0.55,5.99,0.8
					c0.64,0.08,1.04,0.38,1.37,0.98c0.97,1.75,1.99,3.48,3.05,5.18c0.38,0.61,0.36,1.1,0.09,1.71c-0.56,1.28-1.14,2.55-1.58,3.88
					C560.89,2537.22,560.65,2537.95,558.15,2537.67z"/>
				<path d="M514.62,2546.26c-0.89,0.03-1.79,0.05-2.68,0.08c-0.22,0.01-0.45,0.01-0.64-0.08c-0.4-0.18-0.54-0.64-0.67-1.05
					c-0.47-1.48-1.08-2.93-1.35-4.43c-0.34-1.86,1.05-3.41,2.05-5.17c0.6-1.05,1.53-1.96,2.66-2.43c0.99-0.41,2.07-0.57,3.12-0.72
					c0.87-0.12,2.05-0.56,2.79,0.1c0.63,0.56,1.45,2.19,1.45,2.19s1.19,2.14,1.32,3.38c0.08,0.8-0.18,1.61-0.57,2.32
					c-0.39,0.71-0.9,1.35-1.37,2.02c-0.54,0.76-1.02,1.57-1.44,2.4c-0.19,0.38-0.39,0.79-0.75,1.02c-0.36,0.24-0.83,0.26-1.27,0.28
					C516.39,2546.2,515.5,2546.23,514.62,2546.26z"/>
				<path d="M571.08,2545.23c-4.54,0.47-5.05-0.48-6.12-2.61c-0.68-1.36-1.68-2.59-2.57-3.86c-0.3-0.43-0.36-0.8-0.12-1.28
					c0.75-1.51,1.48-3.04,2.16-4.58c0.28-0.63,0.67-0.81,1.36-0.66c0.74,0.16,1.46,0.29,2.24,0.38c4.2,0.51,3.52,0.47,5.22,3.28
					c2.06,3.41,2.17,3.36,1.1,7.07C573.69,2545.23,573.69,2545.23,571.08,2545.23L571.08,2545.23z"/>
				<path d="M524.85,2523.38c-0.2,0.03-0.56-0.06-0.89-0.47c-0.79-1.02-1.6-2.02-2.46-2.97c-0.55-0.6-0.5-1.07-0.04-1.68
					c0,0,1.6-2.41,1.6-2.41c0.77-1.15,1.43-1.98,2.85-2.31c1.61-0.37,3.25-0.54,4.88-0.78c1.23-0.18,2.2,0.34,3.09,1.14
					c0.75,0.69,2.33,1.57,2.47,2.66c0.09,0.71-0.39,1.25-0.82,1.73c-1.28,1.44-1.92,3.4-3.81,4.18
					C529.56,2523.36,527.1,2523,524.85,2523.38z"/>
				<path d="M558.6,2522.71c-2.29-0.28-4.39-0.54-6.5-0.8c-0.41-0.05-0.71-0.24-0.97-0.57c-1.16-1.46-2.34-2.91-3.55-4.33
					c-0.34-0.4-0.47-0.86-0.15-1.3c0.29-0.4,0.62-0.77,0.9-1.18c0.38-0.53,0.78-1.07,1.35-1.42c0.88-0.55,1.95-0.58,2.95-0.43
					c0.34,0.05,0.67,0.12,1.01,0.2c4.25,1.05,4.29,0.9,7.01,4.29c1.33,1.66,1.33,1.66,0.09,3.43c-0.36,0.51-0.75,1-1.07,1.52
					C559.36,2522.61,559.01,2522.88,558.6,2522.71z"/>
				<path d="M513.63,2531.96c-0.58,0.12-1.12-0.78-1.47-1.18c-0.5-0.57-1.08-1.65-1.63-2.43c-0.34-0.48,0.98-3.15,1.74-4.46
					c0.95-1.63,0.31-1.82,3.38-2.82c1.24-0.4,2.3-0.72,3.43-1.15c0.6-0.23,1.02-0.13,1.41,0.4c0.77,1.03,1.56,2.03,2.38,3.01
					c0.37,0.44,0.38,0.81,0.1,1.31c-1.01,1.84-1.99,3.7-2.98,5.55c-0.16,0.3-0.34,0.54-0.68,0.61
					C517.43,2531.19,515.53,2531.58,513.63,2531.96z"/>
				<path d="M541,2515.36c-2.97,0.23-4.49-0.59-6.3-2.78c-0.95-1.15-0.93-0.97-0.04-2.16c1.57-2.11,3.53-2.64,6.09-2.66
					c2.75-0.02,4.98,0.5,6.72,2.7c0.21,0.26,0.5,0.46,0.76,0.67c0.32,0.26,0.4,0.53,0.1,0.86c-0.08,0.08-0.14,0.17-0.22,0.26
					c-0.89,1.02-1.49,2.53-2.68,3C544.29,2515.72,542.84,2515.36,541,2515.36z"/>
				<path d="M569.87,2531.85c-1.71-0.35-3.43-0.7-5.14-1.04c-0.4-0.08-0.52-0.44-0.7-0.74c-1-1.72-1.98-3.46-3-5.17
					c-0.36-0.61-0.33-1.07,0.08-1.63c0.61-0.83,1.18-1.7,1.68-2.6c0.35-0.62,0.71-0.69,1.31-0.43c0.98,0.43,1.97,0.91,3,1.12
					c1.76,0.35,2.79,1.46,3.41,3.15c0.1,0.26,0.25,0.5,0.37,0.75c1.56,3.11,1.56,3.1-0.23,6.04
					C570.47,2531.6,570.31,2531.92,569.87,2531.85z"/>
				<path d="M522.74,2514.07C522.74,2514.07,522.74,2514.07,522.74,2514.07c-0.04,0.07-0.08,0.13-0.12,0.19
					c-1.03,1.51-1.75,3.52-3.14,4.45c-1.79,1.2-4.11,1.55-6.19,2.29c-0.56,0.2-0.87-0.2-1.24-0.46c-2.28-1.63-2.16-1.56-0.71-3.93
					c1.22-2.01,1.71-2.97,3.1-3.38l4.28-1.68c1.6-0.56,2.37,0.25,3.86,1.66c0.02,0.02,0.04,0.04,0.06,0.06
					C522.84,2513.48,522.88,2513.81,522.74,2514.07z"/>
				<path d="M511.67,2522.41c-0.07,0.16-0.15,0.34-0.24,0.52c-0.75,1.49-1.51,2.97-2.25,4.47c-0.22,0.45-0.54,0.76-0.97,1.02
					c-1.27,0.77-2.55,1.54-3.77,2.38c-0.66,0.46-1.17,0.44-1.85,0.03c-2.11-1.28-1.99-1.2-1.24-3.45c0.41-1.23,1.01-2.39,1.81-3.41
					c1.18-1.49,2.9-2.25,4.37-3.39c0.34-0.26,0.73-0.58,1.19-0.45c0.99,0.28,1.8,1.15,2.69,1.77
					C511.6,2522.01,511.73,2522.16,511.67,2522.41z"/>
				<path d="M525.07,2512.68c-0.71,0.04-3.43-1.65-3.63-2.09c-0.14-0.31,0.12-0.42,0.27-0.56c1.01-0.93,2-1.88,3.06-2.76
					c0.47-0.39,1.09-0.45,1.67-0.52c0.69-0.09,1.38-0.2,2.07-0.35c0.74-0.16,1.48-0.46,2.23-0.54c0.65-0.07,1.32,0,1.95,0.18
					c0.91,0.27,1.75,0.76,2.5,1.33c0.45,0.34,0.59,0.49,0.14,0.94c-0.93,0.9-1.81,1.85-2.71,2.79c-0.25,0.26-0.56,0.43-0.92,0.49
					C529.43,2511.96,527.15,2512.34,525.07,2512.68z"/>
				<path d="M571.94,2518.9c0.13,0.16,0.14,0.39,0.02,0.56c-0.19,0.28-0.5,0.45-0.7,0.71c-0.78,1-1.62,1.02-2.85,0.71
					c-3.66-0.9-6.35-2.97-8.43-6.18c-0.62-0.97-0.46-1.27,0.18-2.05c0.4-0.48,0.89-0.96,1.49-1.15c0.57-0.18,1.16-0.04,1.67,0.25
					c0.34,0.19,0.69,0.35,1.04,0.5c0.27,0.12,0.53,0.24,0.79,0.38c0.59,0.3,1.17,0.63,1.73,1c1.07,0.71,2.06,1.55,2.94,2.49
					c0.71,0.77,1.35,1.61,1.92,2.5C571.8,2518.72,571.87,2518.81,571.94,2518.9z"/>
				<path d="M549.48,2505.71c2.38,0.47,4.74,0.69,6.91,1.45c1.81,0.63,3.09,2.31,4.63,3.49c0.38,0.3,0,0.51-0.18,0.71
					c-0.66,0.74-1.45,1.48-2.44,1.73c-1.01,0.25-2.08-0.09-3.06-0.32c-1.06-0.25-2.13-0.4-3.18-0.73c-1.05-0.33-2.02-0.86-2.91-1.48
					c-0.97-0.68-1.84-1.48-2.64-2.35c-0.44-0.48-0.48-0.72,0.08-1.03C547.64,2506.69,548.37,2505.74,549.48,2505.71z"/>
				<path d="M507.79,2538.29c-1.27-0.15-2.51-0.3-3.76-0.42c-0.49-0.05-0.58-0.26-0.53-0.7c0.16-1.52,0.31-3.04,0.41-4.56
					c0.03-0.54,0.26-0.85,0.71-1.12c1.34-0.81,2.67-1.63,3.97-2.49c0.47-0.31,0.78-0.32,1.05,0.19c0.04,0.07,0.09,0.13,0.14,0.19
					c0.72,0.99,1.88,1.93,2.02,2.99c0.15,1.09-1.12,2.07-1.73,3.13c-0.45,0.78-0.97,1.53-1.46,2.3
					C508.42,2538.11,508.24,2538.44,507.79,2538.29z"/>
				<path d="M587.4,2541.84c0.03,0.14,0.01,0.29-0.06,0.42c-0.37,0.69-1.35,0.82-2.01,1.29c-0.62,0.45-0.86-0.22-1.15-0.57
					c-1.07-1.29-2.32-2.55-3.03-4.07c-0.7-1.51-0.95-3.19-1.06-4.83c-0.01-0.13-0.03-0.25-0.05-0.38c-0.18-1.21,0.64-2.46,1.79-2.77
					c0.38-0.1,0.55,0.11,0.73,0.33c1.15,1.34,2.29,2.69,3.43,4.04c0.18,0.21,0.29,0.46,0.34,0.72
					C586.68,2537.9,587.02,2539.79,587.4,2541.84z"/>
				<path d="M580.85,2529.09c-0.03,0.79-2.03,1.75-2.74,1.37c-2.92-1.58-5.02-3.86-6.34-6.95c-0.76-1.77-0.64-2.52,1.02-3.53
					c0.4-0.24,0.59-0.03,0.84,0.15c1.41,0.99,2.82,2,4.24,2.98c0.36,0.25,0.54,0.58,0.71,0.95c0.65,1.43,1.31,2.85,1.97,4.28
					C580.66,2528.58,580.76,2528.85,580.85,2529.09z"/>
				<path d="M576.31,2538.72c-0.53,0.18-0.72-0.18-0.92-0.51c-1.06-1.72-2.12-3.45-3.19-5.17c-0.22-0.36-0.23-0.64-0.06-1.04
					c1.58-3.6,1.59-3.54,4.84-1.46c1.54,0.98,2.3,2.15,2.34,3.94c0.02,0.96,0.27,1.92,0.44,2.87c0.11,0.61-0.05,0.93-0.75,0.98
					C578.1,2538.41,577.18,2538.6,576.31,2538.72z"/>
				<g>
					<path d="M545.64,2503.91C545.64,2503.91,545.64,2503.91,545.64,2503.91c-1.31-0.62-2.81-0.65-4.25-0.66
						c-1.22-0.01-2.46-0.02-3.66,0.2c-1.19,0.22-2.36,0.68-3.24,1.5c-0.02,0.02-0.02,0.05-0.01,0.08c0.82,1.11,2.21,1.68,3.58,1.89
						c1.38,0.2,2.79,0.09,4.19,0.12c1.15,0.03,2.33,0.16,3.41-0.23c0.86-0.31,1.58-0.93,2.26-1.55c0.03-0.03,0.02-0.07-0.01-0.09
						c-0.1-0.05-0.27-0.16-0.3-0.17c-0.13-0.07-0.27-0.14-0.4-0.21c-0.16-0.08-0.32-0.16-0.47-0.25c-0.16-0.09-0.3-0.2-0.46-0.28
						c-0.05-0.03-0.1-0.05-0.15-0.08c-0.06-0.03-0.13-0.06-0.19-0.1L545.64,2503.91z"/>
					<path d="M558.11,2505.82c-0.04-0.16-0.6-0.42-0.75-0.51c-0.52-0.32-1.25-0.74-1.82-1.02c-2.73-1.38-5.93-1.79-8.92-1.13
						c-0.15,0.03-0.33,0.1-0.34,0.25c-0.01,0.1,0.07,0.29,0.15,0.35c0.06,0.04,0.12,0.06,0.19,0.08c0.27,0.08,0.54,0.17,0.8,0.26
						l0.27,0.1c2.9,1.05,5.93,1.73,9,2.03c0.21,0.02,0.54,0.1,0.74,0.02C557.72,2506.1,558.17,2506.08,558.11,2505.82z"/>
				</g>
				<path className="st13" d="M469.75,2547.98l17.12,3.85l4.41-1.06l3.44-15.6l4.53-11.65l3.2-4.07c1.12-1.45-0.7-1.95-2.36-2.72
					l-16.04,9.31"/>
				<g>
					<path d="M482.9,2526.84c-1.85-0.63-3.68-1.36-5.4,0.33c-0.35,0.35-0.86,0.53-1.29,0.79c-1.15,0.69-2.15,3.42-1.72,4.65
						c0.09,0.26,0.26,0.44,0.52,0.51c1.74,0.49,3.5,1.51,5.18-0.17c0.17-0.17,0.42-0.29,0.65-0.4c1.94-0.85,2.75-2.37,2.63-4.37
						C483.64,2527.55,483.62,2527.08,482.9,2526.84z"/>
					<path d="M466.03,2531.64c0.56,0.07,0.98-0.17,1.37-0.53c0.68-0.63,1.34-1.28,2.07-1.85c1.04-0.82,1.59-1.9,1.98-3.14
						c0.29-0.92,0.01-0.99-0.8-0.83c-1.71,0.34-5.32,4.02-5.5,5.67C465.05,2531.72,465.59,2531.6,466.03,2531.64z"/>
					<path d="M479.48,2521.72c0.08,0.02,0.17,0,0.25,0c2.09-0.02,3.89-1,5.74-1.8c0.26-0.11,0.42-0.51,0.59-0.79
						c0.34-0.57,0.28-0.84-0.5-0.97c-2.41-0.41-4.45,0.73-6.55,1.53c-0.6,0.23-1.2,0.93-1.08,1.58
						C478.08,2522,478.94,2521.6,479.48,2521.72z"/>
					<path d="M471,2524.67c2.38-0.61,4.31-2.03,6.15-3.57c0.24-0.2,0.5-0.46,0.46-1.07c-2.87,0.48-5.19,1.81-7.16,3.8
						c-0.2,0.2-0.48,0.48-0.28,0.77C470.36,2524.87,470.72,2524.74,471,2524.67z"/>
					<path d="M476.68,2522.3c-1.29,0.79-2.62,1.54-3.86,2.41c-0.9,0.62-0.91,1.76-1.23,2.7c-0.07,0.22,0.06,0.47,0.27,0.5
						c0.99,0.14,2.02,0.68,2.96,0.21c1.46-0.74,2.9-1.58,4.22-2.54c0.94-0.68,1.18-1.94,1.05-2.97c-0.08-0.63-1.18-0.21-1.81-0.38
						c-0.2-0.05-0.42-0.06-0.62-0.09C477.31,2522.08,476.98,2522.12,476.68,2522.3z"/>
					<path d="M465.01,2532.31c-0.44,0.11-0.74,0.37-1.02,0.7c-1.48,1.75-2.74,3.56-3.1,5.95c-0.19,1.24,0.3,1.39,1.12,1.4
						c0.48,0.1,0.73-0.08,0.96-0.37c1.56-1.94,3.21-3.8,3.34-6.5C466.37,2532.32,466.15,2532.03,465.01,2532.31z"/>
					<path d="M493.39,2517.13c1.91,0.65,3.9,0.6,5.88,0.55c0.36-0.01,0.84,0.03,0.88-0.49c0.04-0.5-0.44-0.55-0.79-0.66
						c-1.18-0.39-2.4-0.38-3.63-0.31c-0.76,0-1.52-0.01-2.27,0.01c-0.3,0.01-0.64,0.08-0.69,0.43
						C492.72,2517.06,493.13,2517.04,493.39,2517.13z"/>
					<path d="M463.43,2528.93c0.39,0.16,0.76,0.35,1.12,0.24l0.6-0.92C464.79,2528.76,464.26,2529.08,463.43,2528.93z"/>
					<path d="M489.51,2521.45c-1.41-0.37-2.76-1.21-4.28-0.75c-1.13,0.34-2.22,0.82-3.32,1.26c-0.8,0.32-1.88,2.31-1.75,3.14
						c0.04,0.24,0.17,0.39,0.38,0.45c1.09,0.34,2.18,0.67,3.21,0.99c1.42-0.28,2.64-0.9,3.91-1.35c1.4-0.5,2.19-1.77,2.1-3.2
						C489.75,2521.77,489.71,2521.5,489.51,2521.45z"/>
					<path d="M486.54,2519.54c-0.1,0.43,0.33,0.5,0.63,0.65c2.59,1.2,5.11,0.46,7.64-0.25c0.6-0.17,0.65-0.72,0.73-1.22
						c0.06-0.37-0.21-0.54-0.51-0.61c-1.1-0.28-2.21-0.54-3.19-0.78c-1.47,0.25-2.81,0.45-4.13,0.71
						C486.93,2518.19,486.7,2518.89,486.54,2519.54z"/>
					<path d="M483.74,2530.4c-0.01,0.25-0.11,0.56,0.13,0.71c1.33,0.82,2.73,1.49,4.19,2.07c1.61-0.54,3.17-1.05,4.73-1.59
						c0.31-0.11,0.42-0.42,0.48-0.71c0.19-0.95,0.37-1.91,0.54-2.86c0.07-0.37-0.03-0.69-0.37-0.87c-2.09-1.08-3.92-2.38-6.67-1.17
						C484.53,2526.95,483.8,2528.27,483.74,2530.4z"/>
					<path d="M491.8,2516.48c-2.49-0.36-4.94-0.5-7.19,0.86C487.11,2517.92,489.45,2517.08,491.8,2516.48z"/>
					<path d="M498.8,2522.08c-0.89-0.38-1.76-0.83-2.63-1.26c-0.47-0.23-0.94-0.37-1.47-0.25c-1.16,0.25-2.32,0.47-3.46,0.76
						c-0.89,0.23-1.88,2.96-1.2,3.4c1.4,0.92,2.93,1.65,4.35,2.08c1.96-0.22,3.75-0.31,4.37-2.43c0.08-0.28,0.26-0.53,0.44-0.76
						C499.75,2522.92,499.65,2522.44,498.8,2522.08z"/>
					<path d="M501.94,2518.9c-1.06-0.48-2.14-0.8-3.15-0.65c-0.59,0-1.02-0.01-1.44,0c-0.21,0-0.43,0-0.63,0.06
						c-0.59,0.18-0.66,0.72-0.8,1.2c-0.14,0.49,0.27,0.65,0.59,0.84c3.32,1.99,3.31,1.98,5.63-0.4
						C502.71,2519.39,502.56,2519.17,501.94,2518.9z"/>
					<path d="M474.05,2530.38c0.53-1.35-0.2-1.6-1.38-1.78c-1.28-0.2-2.18,0.18-3.01,1.08c-0.63,0.68-1.39,1.24-2.01,1.93
						c-1.1,1.22-0.92,2.81-1.14,4.29c-0.01,0.09,0.11,0.26,0.2,0.29c1,0.31,2.2,0.97,3.03,0.32
						C471.74,2534.92,474.51,2533.76,474.05,2530.38z"/>
					<path d="M494.42,2532.69c-1.18-1.08-2.25-0.26-3.31,0.07c-3.14,0.98-3.14,1-3.23,4.34c0.02,0.49-0.17,1.12,0.39,1.57
						c0.97,0.76,1.91,1.55,2.92,2.24c0.86,0.59,1.76,0.2,2.03-0.79c0.55-2,1.06-4.01,1.63-6
						C495.02,2533.51,494.92,2533.15,494.42,2532.69z"/>
					<path d="M496.75,2526.97c-1.72,0.05-2.28,0.49-2.61,2.23c-0.11,0.58-0.2,1.17-0.3,1.75c-0.15,0.84,0.55,1.05,1.05,1.4
						c0.29,0.2,0.46,0.01,0.57-0.26c0.57-1.5,1.15-3,1.72-4.51C497.31,2527.21,497.32,2526.96,496.75,2526.97z"/>
					<path d="M486.61,2538.61c0.66-0.48,1.26-4.35,0.76-4.99c-0.12-0.16-0.33-0.26-0.51-0.37c-2.07-1.21-4-1.99-6.27-0.04
						c-1.26,1.09-2.29,1.92-1.87,3.39c0,0.6,0.04,0.9-0.01,1.19c-0.2,1.28,0.47,1.75,1.58,2.28c2.14,1.02,3.85,0.63,5.48-0.88
						C486.02,2538.95,486.33,2538.81,486.61,2538.61z"/>
					<path d="M478.15,2535.43c0-0.4-0.1-0.71-0.5-0.94c-2.11-1.17-3.5-0.82-5.45,0.67c-1.79,1.38-2.34,2.96-2.16,5.12
						c0.17,2.07,0.03,2.1,2.05,2.59c0.8,0.19,1.8,0.88,2.42,0.25c1.64-1.67,4.19-2.87,3.65-5.79
						C478.15,2536.61,478.16,2536.02,478.15,2535.43z"/>
					<path d="M490.33,2543.41c0.82-1.84,0.84-1.86-0.79-3.07c-2.61-1.95-1.59-1.83-4.36-0.16c-1.33,0.8-1.75,1.65-1.35,3.19
						c0.75,2.91,0.31,2.42,3.4,2.52c1.54,0.59,2.45-0.34,2.89-2.02C490.16,2543.71,490.26,2543.56,490.33,2543.41z"/>
					<path d="M492.42,2541.69c-0.42-0.07-0.75,0.12-0.94,0.5c-0.38,0.8-0.64,1.67-1.13,2.4c-0.89,1.33-0.36,2.22,0.66,3.12
						c0.49,0.43,0.69,0.41,0.8-0.22c0.3-1.7,0.6-3.4,0.94-5.36C492.76,2542.09,492.84,2541.75,492.42,2541.69z"/>
					<path d="M502.25,2517.29c-1.52-0.61-2.13-1.24-2.69-1.39c-2.69-0.73-5.04-0.98-7.83-0.78c-2.9,0.21-5.79,0.69-8.6,1.44
						c-2.86,0.76-5.86,1.53-8.59,2.82c-1.4,0.66-2.74,1.46-3.93,2.45c-0.95,0.78-17.73,21.84-8.68,13.45
						c0.74-0.38,7.44-10.39,7.69-10.87c-1.54,1.83-3.01,3.43-4.84,4.68c-0.08,0.05,0.44,0.61,0.37,0.63l-0.67,0.03
						c0.14-0.01,0,0,0.13,0.06c0.47,0.22,0.13,0.59,0.04,0.87c-0.66,1.92-2.14,3.29-3.27,4.9c-0.49,0.69-1.04-0.59-1.59,0.51
						c-0.59,1.19-1.19,2.38-1.59,3.65c-0.37,1.2-0.32,2.43-0.64,3.63c0.64-2.41,1.08-4.87,3.16-6.85c0.03,2.9-1.38,5.06-2.47,7.34
						c0.89-0.55,1.22-1.47,1.67-2.28c0.43-0.76,1.03-0.78,1.73-0.66c0.74,0.12,0.75,0.7,0.76,1.26c0.01,0.76-0.02,1.53,0.02,2.29
						c0.01,0.32-0.16,0.77,0.56,0.95c0-1.05,0.04-2.01-0.01-2.97c-0.06-1.07,0.19-1.98,0.95-2.78c0.52-0.55,0.91-1.22,1.37-1.83
						c0.68-0.9,3.13-1.12,3.95-0.35c0.11,0.11,0.22,0.29,0.22,0.44c-0.06,2.18,0.66,4.49-1.34,6.31c-0.64,0.59-1.41,1.25-1.65,2.37
						c0.26-0.13,0.4-0.15,0.47-0.24c0.34-0.38,0.67-0.76,0.99-1.16c1.94-2.41,1.93-2.38,4.91-1.56c0.95,0.26,1.37,0.74,1.56,1.71
						c0.15,0.79-0.02,1.75,0.85,2.52c0.02-0.34,0.08-0.52,0.02-0.65c-1.23-2.83,0.2-4.7,2.28-6.34c0.07-0.05,0.13-0.11,0.18-0.18
						c0.47-0.55,1-0.64,1.7-0.43c3.5,1.03,3.48,1.02,4.21,4.57c0.18,0.87,0.01,1.54-0.53,2.23c-0.4,0.52-1.07,0.92-1.17,1.85
						c1.42-0.7,1.66-2.81,3.57-2.48c1.38,0.24,2.92-0.49,4.13,0.64c1.06,0.99,2.27,1.94,1.6,3.81c0.66-0.25,0.64-0.57,0.69-0.85
						c0.27-1.5,0.5-3,0.78-4.5c1.19-6.42,2.64-12.78,5.41-18.74c1.48-3.2,3.36-4.52,5.17-8.46c0.19-0.42,0.12-0.63,0.12-0.63
						S502.83,2517.52,502.25,2517.29z M493.46,2516.22c0.76-0.02,1.51-0.01,2.27-0.01c1.22-0.07,2.45-0.08,3.63,0.31
						c0.34,0.11,0.83,0.16,0.79,0.66c-0.04,0.52-0.52,0.48-0.88,0.49c-1.98,0.05-3.96,0.1-5.88-0.55c-0.26-0.09-0.67-0.06-0.61-0.48
						C492.82,2516.3,493.17,2516.22,493.46,2516.22z M495.03,2518.1c0.3,0.08,0.57,0.24,0.51,0.61c-0.08,0.5-0.14,1.05-0.73,1.22
						c-2.52,0.71-5.05,1.45-7.64,0.25c-0.31-0.14-0.73-0.22-0.63-0.65c0.16-0.66,0.39-1.35,1.17-1.51c1.32-0.26,2.66-0.46,4.13-0.71
						C492.82,2517.56,493.93,2517.82,495.03,2518.1z M487.66,2525.19c-1.27,0.45-2.49,1.07-3.91,1.35
						c-1.03-0.32-2.12-0.65-3.21-0.99c-0.21-0.07-0.34-0.21-0.38-0.45c-0.14-0.83,0.94-2.82,1.75-3.14c1.1-0.44,2.19-0.92,3.32-1.26
						c1.52-0.46,2.88,0.37,4.28,0.75c0.2,0.05,0.24,0.32,0.25,0.54C489.86,2523.42,489.06,2524.7,487.66,2525.19z M493.45,2527.14
						c0.34,0.18,0.44,0.5,0.37,0.87c-0.17,0.96-0.35,1.91-0.54,2.86c-0.06,0.29-0.17,0.6-0.48,0.71c-1.56,0.54-3.12,1.05-4.73,1.59
						c-1.46-0.58-2.87-1.24-4.19-2.07c-0.24-0.15-0.14-0.45-0.13-0.71c0.06-2.13,0.79-3.45,3.03-4.43
						C489.53,2524.76,491.36,2526.06,493.45,2527.14z M480.84,2532.59c-0.49,0.32-0.93,0.7-1.44,0.97c-1.43,0.78-2.98-0.03-4.4-0.43
						c-0.25-0.07-0.43-0.25-0.52-0.51c-0.34-0.97,0.08-2.21,0.5-3.09c0.22-0.46,0.5-0.98,0.89-1.32c0.55-0.47,1.2-0.79,1.79-1.21
						c0.55-0.39,1.06-0.74,1.75-0.84c1.19-0.18,2.38,0.3,3.48,0.68c0.56,0.19,0.66,0.67,0.62,1.2c-0.04,0.58-0.05,1.2-0.2,1.77
						c-0.13,0.51-0.36,0.99-0.68,1.41c-0.41,0.53-0.99,0.88-1.56,1.21C481,2532.48,480.92,2532.53,480.84,2532.59z M491.8,2516.48
						c-2.35,0.6-4.69,1.44-7.19,0.86C486.86,2515.99,489.31,2516.13,491.8,2516.48z M479.02,2519.69c2.1-0.8,4.14-1.94,6.55-1.53
						c0.78,0.13,0.84,0.4,0.5,0.97c-0.17,0.29-0.33,0.68-0.59,0.79c-1.33,0.58-2.65,1.24-4.07,1.57c-0.74,0.18-1.45,0.21-2.2,0.2
						c-0.44,0-1.15,0.17-1.26-0.43C477.82,2520.62,478.42,2519.92,479.02,2519.69z M478.29,2522.17c0.03,0,0.06,0.01,0.1,0.01
						c0.41,0.03,1.25-0.16,1.57,0.18c0.35,0.37,0.22,1.33,0.08,1.76c-0.19,0.59-0.59,1.09-1.05,1.5c-0.47,0.41-1,0.72-1.54,1.04
						c-0.55,0.32-1.1,0.65-1.65,0.97c-0.51,0.3-1.03,0.6-1.62,0.67c-0.47,0.05-0.94-0.06-1.4-0.17c-0.28-0.07-0.56-0.13-0.84-0.2
						c-0.08-0.02-0.16-0.04-0.23-0.09c-0.14-0.12-0.11-0.33-0.08-0.51c0.16-0.81,0.33-1.66,0.87-2.29c0.33-0.38,0.76-0.65,1.19-0.91
						c0.87-0.53,1.73-1.06,2.6-1.59c0.3-0.18,0.61-0.37,0.96-0.42C477.61,2522.05,477.95,2522.14,478.29,2522.17z M470.45,2523.83
						c1.97-1.99,4.29-3.32,7.16-3.8c0.04,0.61-0.23,0.87-0.46,1.07c-1.84,1.54-3.77,2.96-6.15,3.57c-0.28,0.07-0.64,0.2-0.83-0.07
						C469.97,2524.31,470.25,2524.03,470.45,2523.83z M465.14,2530.95c0.19-1.65,3.79-5.33,5.5-5.67c0.81-0.16,1.09-0.09,0.8,0.83
						c-0.39,1.24-0.93,2.32-1.98,3.14c-0.73,0.57-1.39,1.22-2.07,1.85c-0.39,0.36-0.81,0.6-1.37,0.53
						C465.59,2531.6,465.05,2531.72,465.14,2530.95z M466.31,2533.48c-0.13,2.7-1.78,4.57-3.34,6.5c-0.23,0.28-0.48,0.47-0.96,0.37
						c-0.82-0.01-1.31-0.16-1.12-1.4c0.36-2.39,1.62-4.2,3.1-5.95c0.28-0.33,0.59-0.59,1.02-0.7
						C466.15,2532.03,466.37,2532.32,466.31,2533.48z M466.71,2536.19c-0.09-0.03-0.21-0.2-0.2-0.29c0.21-1.47,0.03-3.07,1.14-4.29
						c0.62-0.69,1.38-1.25,2.01-1.93c0.83-0.9,1.73-1.28,3.01-1.08c0.44,0.07,1.05,0.13,1.37,0.49c0.47,0.54,0.1,1.71,0.05,2.36
						c-0.06,0.73-0.31,1.42-0.73,2.02c-0.83,1.17-2.12,1.91-3.25,2.75c-0.13,0.09-0.25,0.19-0.37,0.28
						C468.91,2537.16,467.71,2536.5,466.71,2536.19z M474.5,2543.11c-0.62,0.63-1.62-0.06-2.42-0.25c-2.01-0.48-1.88-0.51-2.05-2.59
						c-0.17-2.15,0.37-3.74,2.16-5.12c1.94-1.49,3.34-1.84,5.45-0.67c0.41,0.22,0.5,0.54,0.5,0.94c0.01,1.63,0.37,3.36-0.63,4.78
						C476.71,2541.36,475.48,2542.12,474.5,2543.11z M480.29,2540.06c-0.5-0.24-1.08-0.51-1.39-1c-0.26-0.41-0.21-0.89-0.21-1.35
						c-0.01-0.73-0.18-1.55,0-2.26c0.12-0.48,0.46-0.89,0.8-1.24c0.9-0.93,2.06-2.08,3.82-2.18c0.24-0.01,1.46,0.46,2.23,0.76
						c0.63,0.25,1.17,0.45,1.61,1.01c0.21,0.27,0.41,0.58,0.41,0.91c0,0.59-0.07,1.17-0.18,1.75c-0.09,0.54-0.21,1.08-0.42,1.59
						c-0.24,0.58-0.79,0.91-1.27,1.27c-0.62,0.47-1.26,0.93-2.01,1.15C482.53,2540.81,481.35,2540.57,480.29,2540.06z
						 M487.23,2545.94c-0.58-0.04-1.16,0.04-1.73,0c-0.32-0.02-0.67-0.08-0.91-0.3c-0.22-0.2-0.32-0.49-0.4-0.76
						c-0.16-0.54-0.3-1.09-0.4-1.64c-0.1-0.53-0.16-1.09,0.02-1.6c0.19-0.54,0.62-0.95,1.08-1.27c0.47-0.32,0.98-0.57,1.44-0.9
						c0.23-0.17,0.45-0.35,0.71-0.46c0.6-0.25,0.99,0.17,1.43,0.51c0.56,0.43,1.12,0.85,1.67,1.28c0.33,0.26,0.69,0.56,0.74,0.97
						c0.03,0.25-0.07,0.49-0.16,0.72c-0.24,0.6-0.48,1.2-0.71,1.8c-0.26,0.67-0.6,1.41-1.28,1.65c-0.47,0.17-0.99,0.05-1.49,0.01
						C487.24,2545.94,487.23,2545.94,487.23,2545.94z M492.75,2542.13c-0.34,1.96-0.64,3.66-0.94,5.36
						c-0.11,0.62-0.31,0.65-0.8,0.22c-1.02-0.9-1.55-1.79-0.66-3.12c0.49-0.73,0.75-1.6,1.13-2.4c0.18-0.38,0.52-0.56,0.94-0.5
						C492.84,2541.75,492.76,2542.09,492.75,2542.13z M494.85,2534.11c-0.56,2-1.08,4-1.63,6c-0.27,0.99-1.17,1.38-2.03,0.79
						c-1.01-0.69-1.96-1.48-2.92-2.24c-0.56-0.44-0.37-1.08-0.39-1.57c0.09-3.34,0.08-3.36,3.23-4.34c1.07-0.33,2.14-1.15,3.31-0.07
						C494.92,2533.15,495.02,2533.51,494.85,2534.11z M497.17,2527.58c-0.57,1.5-1.15,3-1.72,4.51c-0.11,0.28-0.28,0.47-0.57,0.26
						c-0.5-0.35-1.2-0.56-1.05-1.4c0.1-0.58,0.19-1.17,0.3-1.75c0.34-1.74,0.89-2.18,2.61-2.23
						C497.32,2526.96,497.31,2527.21,497.17,2527.58z M499.21,2523.63c-0.18,0.23-0.9,1.33-1.02,1.58
						c-0.93,1.89-1.83,1.39-3.79,1.61c-1.42-0.43-2.94-1.16-4.35-2.08c-0.68-0.44,0.31-3.18,1.2-3.4c1.14-0.29,2.31-0.51,3.46-0.76
						c0.52-0.11,1,0.02,1.47,0.25c0.87,0.43,1.73,0.88,2.63,1.26C499.65,2522.44,499.75,2522.92,499.21,2523.63z M502.15,2519.95
						c-2.33,2.38-2.31,2.39-5.63,0.4c-0.32-0.19-0.73-0.36-0.59-0.84c0.13-0.48,0.21-1.03,0.8-1.2c0.2-0.06,0.42-0.06,0.63-0.06
						c0.42-0.01,0.85,0,1.44,0c1.01-0.15,2.09,0.17,3.15,0.65C502.56,2519.17,502.71,2519.39,502.15,2519.95z"/>
				</g>
				<polygon points="626.03,2541.3 613.03,2546.04 594.71,2549.89 590,2548.83 586.32,2533.23 581.47,2521.58 577.11,2516.29 
					581.31,2514.48 593.4,2513.48 603.07,2517.65 612.07,2519.77 618.63,2527.69 623.65,2534.02 				"/>
				<path className="st13" d="M610.39,2546.14l-17.12,3.85l-4.41-1.06l-3.44-15.6l-4.53-11.65l-3.2-4.07c-1.12-1.45,0.7-1.95,2.36-2.72
					l16.04,9.31"/>
				<g>
					<path d="M597.5,2524.79c1.85-0.63,3.68-1.36,5.4,0.33c0.35,0.35,0.86,0.53,1.29,0.79c1.15,0.69,2.15,3.42,1.72,4.65
						c-0.09,0.26-0.26,0.44-0.52,0.51c-1.74,0.49-3.5,1.51-5.18-0.17c-0.17-0.17-0.42-0.29-0.65-0.4c-1.94-0.85-2.75-2.37-2.63-4.37
						C596.76,2525.51,596.78,2525.04,597.5,2524.79z"/>
					<path d="M614.37,2529.59c-0.56,0.07-0.98-0.17-1.37-0.53c-0.68-0.63-1.34-1.28-2.07-1.85c-1.04-0.82-1.59-1.9-1.98-3.14
						c-0.29-0.92-0.01-0.99,0.8-0.83c1.71,0.34,5.32,4.02,5.5,5.67C615.35,2529.67,614.8,2529.55,614.37,2529.59z"/>
					<path d="M600.91,2519.67c-0.08,0.02-0.17,0-0.25,0c-2.09-0.02-3.89-1-5.74-1.8c-0.26-0.11-0.42-0.51-0.59-0.79
						c-0.34-0.57-0.28-0.84,0.5-0.97c2.41-0.41,4.45,0.73,6.55,1.53c0.6,0.23,1.2,0.93,1.08,1.58
						C602.31,2519.95,601.45,2519.55,600.91,2519.67z"/>
					<path d="M609.4,2522.62c-2.38-0.61-4.31-2.03-6.15-3.57c-0.24-0.2-0.5-0.46-0.46-1.07c2.87,0.48,5.19,1.81,7.16,3.8
						c0.2,0.2,0.48,0.48,0.28,0.77C610.04,2522.83,609.68,2522.69,609.4,2522.62z"/>
					<path d="M603.72,2520.26c1.29,0.79,2.62,1.54,3.86,2.4c0.9,0.62,0.91,1.76,1.23,2.7c0.07,0.22-0.06,0.47-0.27,0.5
						c-0.99,0.14-2.02,0.68-2.96,0.21c-1.46-0.74-2.9-1.58-4.22-2.54c-0.94-0.68-1.18-1.94-1.05-2.97c0.08-0.63,1.18-0.21,1.81-0.38
						c0.2-0.05,0.42-0.06,0.62-0.09C603.09,2520.03,603.42,2520.07,603.72,2520.26z"/>
					<path d="M615.39,2530.26c0.44,0.11,0.74,0.37,1.02,0.7c1.48,1.75,2.74,3.56,3.1,5.95c0.19,1.24-0.3,1.39-1.12,1.4
						c-0.48,0.1-0.73-0.08-0.96-0.37c-1.56-1.94-3.21-3.8-3.34-6.5C614.03,2530.28,614.25,2529.98,615.39,2530.26z"/>
					<path d="M587.01,2515.08c-1.91,0.65-3.9,0.6-5.88,0.55c-0.36-0.01-0.84,0.03-0.88-0.49c-0.04-0.5,0.44-0.55,0.79-0.66
						c1.18-0.39,2.4-0.38,3.63-0.31c0.76,0,1.52-0.01,2.27,0.01c0.3,0.01,0.64,0.08,0.69,0.43
						C587.68,2515.02,587.27,2514.99,587.01,2515.08z"/>
					<path d="M616.97,2526.89c-0.39,0.16-0.76,0.35-1.12,0.24l-0.6-0.92C615.61,2526.71,616.14,2527.03,616.97,2526.89z"/>
					<path d="M590.89,2519.41c1.41-0.37,2.76-1.21,4.28-0.75c1.13,0.34,2.22,0.82,3.32,1.26c0.8,0.32,1.88,2.31,1.75,3.14
						c-0.04,0.24-0.17,0.39-0.38,0.45c-1.09,0.34-2.18,0.67-3.21,0.99c-1.42-0.28-2.64-0.9-3.91-1.35c-1.4-0.5-2.19-1.77-2.1-3.2
						C590.65,2519.72,590.69,2519.46,590.89,2519.41z"/>
					<path d="M593.86,2517.5c0.1,0.43-0.33,0.5-0.63,0.64c-2.59,1.2-5.11,0.46-7.64-0.25c-0.6-0.17-0.65-0.72-0.73-1.22
						c-0.06-0.37,0.21-0.54,0.51-0.61c1.1-0.28,2.21-0.54,3.19-0.78c1.47,0.25,2.81,0.45,4.13,0.71
						C593.47,2516.15,593.7,2516.84,593.86,2517.5z"/>
					<path d="M596.66,2528.35c0.01,0.25,0.11,0.56-0.13,0.71c-1.33,0.82-2.73,1.49-4.19,2.07c-1.61-0.54-3.17-1.05-4.73-1.59
						c-0.31-0.11-0.42-0.42-0.48-0.71c-0.19-0.95-0.37-1.91-0.54-2.87c-0.07-0.37,0.03-0.69,0.37-0.87
						c2.09-1.08,3.92-2.38,6.67-1.17C595.86,2524.9,596.59,2526.22,596.66,2528.35z"/>
					<path d="M588.6,2514.44c2.49-0.36,4.94-0.5,7.19,0.86C593.28,2515.88,590.95,2515.04,588.6,2514.44z"/>
					<path d="M581.6,2520.04c0.89-0.38,1.76-0.83,2.63-1.26c0.47-0.23,0.94-0.37,1.47-0.25c1.16,0.25,2.32,0.47,3.46,0.76
						c0.89,0.23,1.88,2.96,1.2,3.4c-1.4,0.92-2.93,1.65-4.35,2.08c-1.96-0.22-3.75-0.31-4.37-2.43c-0.08-0.28-0.26-0.53-0.44-0.76
						C580.65,2520.88,580.74,2520.4,581.6,2520.04z"/>
					<path d="M578.46,2516.85c1.06-0.48,2.14-0.8,3.15-0.65c0.59,0,1.02-0.01,1.44,0c0.21,0,0.43,0,0.63,0.06
						c0.59,0.18,0.66,0.72,0.8,1.2c0.14,0.49-0.27,0.65-0.59,0.84c-3.32,1.99-3.31,1.98-5.63-0.4
						C577.69,2517.34,577.84,2517.13,578.46,2516.85z"/>
					<path d="M606.35,2528.34c-0.53-1.35,0.2-1.6,1.38-1.78c1.28-0.2,2.18,0.18,3.01,1.08c0.63,0.68,1.39,1.24,2.01,1.93
						c1.1,1.22,0.92,2.81,1.14,4.29c0.01,0.09-0.11,0.26-0.2,0.29c-1,0.31-2.2,0.97-3.03,0.32
						C608.65,2532.88,605.88,2531.71,606.35,2528.34z"/>
					<path d="M585.98,2530.65c1.18-1.08,2.25-0.26,3.31,0.07c3.14,0.98,3.14,1,3.23,4.34c-0.02,0.49,0.17,1.12-0.39,1.57
						c-0.97,0.76-1.91,1.55-2.92,2.24c-0.86,0.59-1.76,0.2-2.03-0.8c-0.55-2-1.06-4.01-1.63-6
						C585.37,2531.46,585.48,2531.1,585.98,2530.65z"/>
					<path d="M583.65,2524.93c1.72,0.05,2.28,0.49,2.61,2.23c0.11,0.58,0.2,1.17,0.3,1.75c0.15,0.84-0.55,1.05-1.05,1.4
						c-0.29,0.2-0.46,0.01-0.57-0.26c-0.57-1.5-1.15-3-1.72-4.51C583.09,2525.16,583.08,2524.91,583.65,2524.93z"/>
					<path d="M593.79,2536.56c-0.66-0.48-1.26-4.35-0.76-4.99c0.12-0.16,0.33-0.26,0.51-0.37c2.07-1.21,4-1.99,6.27-0.04
						c1.26,1.09,2.29,1.92,1.87,3.39c0,0.6-0.04,0.9,0.01,1.19c0.2,1.28-0.47,1.75-1.58,2.28c-2.14,1.02-3.85,0.63-5.48-0.88
						C594.38,2536.91,594.06,2536.76,593.79,2536.56z"/>
					<path d="M602.25,2533.38c0-0.4,0.1-0.71,0.5-0.94c2.11-1.17,3.5-0.82,5.45,0.67c1.79,1.38,2.34,2.96,2.16,5.12
						c-0.17,2.07-0.03,2.1-2.05,2.59c-0.8,0.19-1.8,0.88-2.42,0.25c-1.64-1.67-4.19-2.87-3.65-5.79
						C602.25,2534.57,602.24,2533.98,602.25,2533.38z"/>
					<path d="M590.07,2541.36c-0.82-1.84-0.84-1.86,0.79-3.07c2.61-1.95,1.59-1.83,4.36-0.16c1.33,0.8,1.75,1.65,1.35,3.19
						c-0.75,2.91-0.31,2.42-3.4,2.52c-1.54,0.59-2.45-0.34-2.89-2.02C590.24,2541.66,590.14,2541.51,590.07,2541.36z"/>
					<path d="M587.98,2539.64c0.42-0.07,0.75,0.12,0.94,0.5c0.38,0.8,0.64,1.67,1.13,2.4c0.89,1.33,0.36,2.22-0.66,3.12
						c-0.49,0.43-0.69,0.41-0.8-0.22c-0.3-1.7-0.6-3.4-0.94-5.36C587.64,2540.04,587.56,2539.71,587.98,2539.64z"/>
					<path d="M576.35,2516.03c0,0,1.85,2.39,2.05,2.81c1.81,3.93,2.4,2.71,3.88,5.9c2.77,5.96,4.21,12.32,5.41,18.74
						c0.28,1.5,0.52,3,0.78,4.5c0.05,0.27,0.03,0.6,0.69,0.85c-0.67-1.87,0.54-2.82,1.6-3.81c1.21-1.13,2.76-0.4,4.13-0.64
						c1.91-0.33,2.15,1.78,3.57,2.48c-0.1-0.93-0.76-1.33-1.17-1.85c-0.54-0.69-0.71-1.36-0.53-2.23c0.72-3.55,0.71-3.54,4.21-4.57
						c0.7-0.21,1.24-0.12,1.7,0.43c0.05,0.06,0.11,0.13,0.18,0.18c2.08,1.64,3.51,3.51,2.28,6.34c-0.06,0.13,0.01,0.31,0.02,0.65
						c0.86-0.77,0.7-1.73,0.85-2.52c0.18-0.97,0.6-1.45,1.56-1.71c2.98-0.82,2.97-0.86,4.91,1.56c0.32,0.4,0.65,0.78,0.99,1.16
						c0.08,0.08,0.21,0.11,0.47,0.24c-0.24-1.12-1.01-1.79-1.65-2.37c-2-1.82-1.28-4.13-1.34-6.31c0-0.15,0.1-0.33,0.22-0.44
						c0.82-0.77,3.27-0.55,3.95,0.35c0.46,0.61,0.85,1.28,1.37,1.83c0.76,0.8,1.01,1.71,0.95,2.78c-0.06,0.96-0.01,1.92-0.01,2.97
						c0.72-0.18,0.54-0.64,0.56-0.95c0.03-0.76,0.01-1.52,0.02-2.29c0.01-0.56,0.01-1.14,0.76-1.26c0.7-0.11,1.31-0.09,1.73,0.66
						c0.46,0.81,0.78,1.73,1.67,2.28c-1.09-2.28-2.49-4.44-2.47-7.34c2.09,1.98,2.53,4.44,3.16,6.85c-0.32-1.2-0.26-2.43-0.64-3.63
						c-0.4-1.27-1-2.47-1.59-3.65c-0.55-1.1-1.1,0.19-1.59-0.51c-1.13-1.6-2.61-2.98-3.27-4.9c-0.09-0.28-0.43-0.65,0.04-0.87
						c0.13-0.06-0.01-0.07,0.13-0.06l-0.67-0.03c-0.08-0.02,0.44-0.58,0.37-0.63c-1.83-1.24-3.3-2.84-4.84-4.68
						c0.25,0.49,6.95,10.49,7.69,10.87c9.04,8.39-7.73-12.67-8.68-13.45c-1.2-0.99-2.53-1.79-3.93-2.45
						c-2.73-1.29-5.73-2.05-8.59-2.82c-2.81-0.75-5.7-1.23-8.6-1.44c-2.79-0.2-5.14,0.05-7.83,0.78c-0.56,0.15-1.17,0.78-2.69,1.39
						C577.57,2515.47,576.35,2516.03,576.35,2516.03z M587.62,2514.6c0.05,0.41-0.35,0.39-0.61,0.48c-1.91,0.65-3.9,0.6-5.88,0.55
						c-0.36-0.01-0.84,0.03-0.88-0.49c-0.04-0.5,0.44-0.55,0.79-0.66c1.18-0.39,2.4-0.38,3.63-0.31c0.76,0,1.52-0.01,2.27,0.01
						C587.23,2514.18,587.58,2514.26,587.62,2514.6z M588.56,2515.28c1.47,0.25,2.81,0.45,4.13,0.71c0.78,0.16,1.02,0.85,1.17,1.51
						c0.1,0.43-0.33,0.5-0.63,0.64c-2.59,1.2-5.11,0.46-7.64-0.25c-0.6-0.17-0.65-0.72-0.73-1.22c-0.06-0.37,0.21-0.54,0.51-0.61
						C586.47,2515.78,587.57,2515.52,588.56,2515.28z M590.64,2519.95c0.02-0.23,0.05-0.49,0.25-0.54c1.41-0.37,2.76-1.21,4.28-0.75
						c1.13,0.34,2.22,0.82,3.32,1.26c0.8,0.32,1.88,2.31,1.75,3.14c-0.04,0.24-0.17,0.39-0.38,0.45c-1.09,0.34-2.18,0.67-3.21,0.99
						c-1.42-0.28-2.64-0.9-3.91-1.35C591.34,2522.65,590.54,2521.38,590.64,2519.95z M593.62,2523.92c2.24,0.98,2.97,2.3,3.03,4.43
						c0.01,0.25,0.11,0.56-0.13,0.71c-1.33,0.82-2.73,1.49-4.19,2.07c-1.61-0.54-3.17-1.05-4.73-1.59
						c-0.31-0.11-0.42-0.42-0.48-0.71c-0.19-0.95-0.37-1.91-0.54-2.87c-0.07-0.37,0.03-0.69,0.37-0.87
						C589.04,2524.01,590.87,2522.72,593.62,2523.92z M599.31,2530.39c-0.57-0.34-1.14-0.68-1.56-1.21
						c-0.32-0.42-0.55-0.9-0.68-1.41c-0.14-0.57-0.15-1.18-0.2-1.77c-0.04-0.54,0.06-1.01,0.62-1.2c1.11-0.38,2.29-0.85,3.48-0.68
						c0.68,0.1,1.2,0.45,1.75,0.84c0.59,0.42,1.24,0.74,1.79,1.21c0.39,0.34,0.67,0.85,0.89,1.32c0.41,0.88,0.84,2.12,0.5,3.09
						c-0.09,0.26-0.26,0.44-0.52,0.51c-1.41,0.4-2.96,1.21-4.4,0.43c-0.5-0.27-0.95-0.65-1.44-0.97
						C599.47,2530.49,599.39,2530.44,599.31,2530.39z M595.79,2515.3c-2.5,0.58-4.84-0.26-7.19-0.86
						C591.09,2514.08,593.54,2513.94,595.79,2515.3z M602.45,2519.22c-0.11,0.6-0.83,0.43-1.26,0.43c-0.75,0-1.46-0.03-2.2-0.2
						c-1.42-0.34-2.74-1-4.07-1.58c-0.26-0.11-0.42-0.51-0.59-0.79c-0.34-0.57-0.28-0.84,0.5-0.97c2.41-0.41,4.45,0.73,6.55,1.53
						C601.98,2517.88,602.58,2518.58,602.45,2519.22z M603.13,2520.06c0.35,0.05,0.66,0.24,0.96,0.42c0.87,0.53,1.73,1.06,2.6,1.59
						c0.43,0.26,0.87,0.53,1.19,0.91c0.54,0.63,0.71,1.48,0.87,2.29c0.03,0.18,0.06,0.39-0.08,0.51c-0.06,0.05-0.15,0.07-0.23,0.09
						c-0.28,0.07-0.56,0.13-0.84,0.2c-0.46,0.11-0.93,0.22-1.4,0.17c-0.58-0.06-1.11-0.37-1.62-0.67c-0.55-0.32-1.1-0.65-1.65-0.97
						c-0.53-0.31-1.07-0.63-1.54-1.04s-0.87-0.91-1.05-1.5c-0.14-0.43-0.26-1.39,0.08-1.76c0.32-0.34,1.15-0.15,1.57-0.18
						c0.03,0,0.06,0,0.1-0.01C602.45,2520.09,602.79,2520.01,603.13,2520.06z M610.23,2522.55c-0.19,0.27-0.55,0.14-0.83,0.07
						c-2.38-0.61-4.31-2.03-6.15-3.57c-0.24-0.2-0.5-0.46-0.46-1.07c2.87,0.48,5.19,1.81,7.16,3.8
						C610.15,2521.99,610.43,2522.26,610.23,2522.55z M614.37,2529.59c-0.56,0.07-0.98-0.17-1.37-0.53
						c-0.68-0.63-1.34-1.28-2.07-1.85c-1.04-0.82-1.59-1.9-1.98-3.14c-0.29-0.92-0.01-0.99,0.8-0.83c1.71,0.34,5.32,4.02,5.5,5.67
						C615.35,2529.67,614.8,2529.55,614.37,2529.59z M615.39,2530.26c0.44,0.11,0.74,0.37,1.02,0.7c1.48,1.75,2.74,3.56,3.1,5.95
						c0.19,1.24-0.3,1.39-1.12,1.4c-0.48,0.1-0.73-0.08-0.96-0.37c-1.56-1.94-3.21-3.8-3.34-6.5
						C614.03,2530.28,614.25,2529.98,615.39,2530.26z M610.66,2534.46c-0.12-0.1-0.25-0.19-0.37-0.28
						c-1.13-0.84-2.42-1.57-3.25-2.75c-0.42-0.6-0.67-1.29-0.73-2.02c-0.05-0.64-0.42-1.82,0.05-2.36c0.32-0.36,0.93-0.42,1.37-0.49
						c1.28-0.2,2.18,0.18,3.01,1.08c0.63,0.68,1.39,1.24,2.01,1.93c1.1,1.22,0.92,2.81,1.14,4.29c0.01,0.09-0.11,0.26-0.2,0.29
						C612.69,2534.46,611.49,2535.11,610.66,2534.46z M602.88,2538.16c-1-1.42-0.64-3.15-0.63-4.78c0-0.4,0.1-0.71,0.5-0.94
						c2.11-1.17,3.5-0.82,5.45,0.67c1.79,1.38,2.34,2.96,2.16,5.12c-0.17,2.07-0.03,2.1-2.05,2.59c-0.8,0.19-1.8,0.88-2.42,0.25
						C604.92,2540.07,603.69,2539.31,602.88,2538.16z M596.73,2538.42c-0.75-0.23-1.4-0.68-2.01-1.15
						c-0.48-0.36-1.03-0.69-1.27-1.27c-0.21-0.51-0.32-1.05-0.42-1.59c-0.1-0.58-0.17-1.16-0.18-1.75c0-0.32,0.2-0.63,0.41-0.91
						c0.43-0.55,0.97-0.75,1.61-1c0.77-0.31,1.99-0.78,2.23-0.76c1.76,0.1,2.92,1.25,3.82,2.18c0.34,0.35,0.67,0.76,0.8,1.24
						c0.18,0.71,0.01,1.53,0,2.26c-0.01,0.46,0.04,0.94-0.21,1.35c-0.31,0.49-0.88,0.76-1.39,1
						C599.05,2538.52,597.87,2538.76,596.73,2538.42z M593.15,2543.9c-0.5,0.04-1.02,0.16-1.49-0.01c-0.67-0.24-1.01-0.98-1.28-1.65
						c-0.24-0.6-0.48-1.2-0.71-1.8c-0.09-0.23-0.18-0.47-0.16-0.72c0.04-0.42,0.4-0.72,0.74-0.97c0.56-0.43,1.12-0.85,1.67-1.28
						c0.44-0.34,0.83-0.76,1.43-0.51c0.26,0.11,0.48,0.3,0.71,0.46c0.46,0.33,0.98,0.58,1.44,0.9c0.47,0.32,0.9,0.74,1.08,1.27
						c0.18,0.51,0.11,1.07,0.02,1.6c-0.1,0.55-0.23,1.1-0.4,1.64c-0.08,0.28-0.19,0.57-0.4,0.76c-0.24,0.22-0.58,0.28-0.91,0.3
						c-0.58,0.04-1.16-0.04-1.73,0C593.17,2543.9,593.16,2543.9,593.15,2543.9z M587.98,2539.64c0.42-0.07,0.75,0.12,0.94,0.5
						c0.38,0.8,0.64,1.67,1.13,2.4c0.89,1.33,0.36,2.22-0.66,3.12c-0.49,0.43-0.69,0.41-0.8-0.22c-0.3-1.7-0.6-3.4-0.94-5.36
						C587.64,2540.04,587.56,2539.71,587.98,2539.64z M585.98,2530.65c1.18-1.08,2.25-0.26,3.31,0.07c3.14,0.98,3.14,1,3.23,4.34
						c-0.02,0.49,0.17,1.12-0.39,1.57c-0.97,0.76-1.91,1.55-2.92,2.24c-0.86,0.59-1.76,0.2-2.03-0.8c-0.55-2-1.06-4.01-1.63-6
						C585.37,2531.46,585.48,2531.1,585.98,2530.65z M583.65,2524.93c1.72,0.05,2.28,0.49,2.61,2.23c0.11,0.58,0.2,1.17,0.3,1.75
						c0.15,0.84-0.55,1.05-1.05,1.4c-0.29,0.2-0.46,0.01-0.57-0.26c-0.57-1.5-1.15-3-1.72-4.51
						C583.09,2525.16,583.08,2524.91,583.65,2524.93z M581.6,2520.04c0.89-0.38,1.76-0.83,2.63-1.26c0.47-0.23,0.94-0.37,1.47-0.25
						c1.16,0.25,2.32,0.47,3.46,0.76c0.89,0.23,1.88,2.96,1.2,3.4c-1.4,0.92-2.93,1.65-4.35,2.08c-1.96-0.22-2.86,0.28-3.79-1.61
						c-0.13-0.26-0.85-1.35-1.02-1.58C580.65,2520.88,580.74,2520.4,581.6,2520.04z M578.46,2516.85c1.06-0.48,2.14-0.8,3.15-0.65
						c0.59,0,1.02-0.01,1.44,0c0.21,0,0.43,0,0.63,0.06c0.59,0.18,0.66,0.72,0.8,1.2c0.14,0.49-0.27,0.65-0.59,0.84
						c-3.32,1.99-3.31,1.98-5.63-0.4C577.69,2517.34,577.84,2517.13,578.46,2516.85z"/>
				</g>
				<path className="st8" d="M491.24,2546.09l15.21,3.66l9.73,1.74l13.07,1.93h11.77h8.81l17.64-2.4l15.37-4.08l5.76-2.25l1.19-2.16
					l-1.2-6.54l-4.28-12.05c0,0-4.62-9-4.62-9c-0.7-1.36-1.25-3.64-2.36-4.68c-2.4-2.26-4.19-3.78-6.79-5.04
					c-5.16-2.51-10.3-4.92-15.57-7.17c-1.58-0.67-3.74-0.85-5.43-1.14c-2.35-0.4-4.73-0.65-7.12-0.75
					c-10.77-0.42-22.01,2.08-31.33,7.56c-2.32,1.36-8.15,6.12-9.65,8.42c-1.76,2.7-0.09,2.74-1.65,5.57
					c-0.19,0.35-0.48,0.73-0.61,1.11l-5.37,15.11L491.24,2546.09z"/>
				<polygon className="st14" points="457.55,2538.56 469.71,2543.3 486.83,2547.15 491.24,2546.09 494.68,2530.49 499.21,2518.84 
					503.29,2513.55 499.36,2511.74 488.06,2510.75 479.02,2514.91 470.61,2517.04 464.47,2524.95 459.78,2531.28 				"/>
				<path className="st15" d="M522.65,2549.92c-0.86-2.42-2.08-4.68-3.12-7.01c-0.28-0.63-0.28-1.08,0.09-1.68
					c1.22-1.97,2.37-3.98,3.54-5.98c0.26-0.44,0.51-0.75,1.1-0.78c2.38-0.12,4.75-0.28,7.13-0.48c0.66-0.05,1.03,0.16,1.35,0.73
					c1.21,2.11,2.45,4.22,3.73,6.29c0.41,0.67,0.27,1.18-0.08,1.8c-1.67,3.02-3.49,5.95-5,9.06c-1.06,2.18-4.42,0.78-6.23,0.76
					c-0.99-0.01-1.32-0.41-1.84-1.32C523.34,2551.33,522.89,2550.39,522.65,2549.92z"/>
				<path className="st16" d="M554.62,2551.36c-1.81-2.99-3.59-5.99-5.43-8.96c-0.41-0.67-0.5-1.16-0.08-1.88
					c1.18-2.02,2.28-4.1,3.36-6.18c0.31-0.59,0.66-0.84,1.34-0.79c2.23,0.16,4.46,0.28,6.69,0.36c0.67,0.02,1.05,0.31,1.37,0.85
					c1.21,2.01,2.43,4.01,3.69,5.99c0.35,0.55,0.36,1.01,0.11,1.6c-1.04,2.5-2.04,5.02-3.05,7.53c-0.05,0.25-0.77,1.55-0.77,1.55
					l0,0c-0.73,0.78-2.75,0.72-4.84,0.95C555.87,2552.51,555.08,2552.47,554.62,2551.36z"/>
				<path className="st15" d="M532.36,2551.97c1.69-2.89,3.4-5.76,5.06-8.67c0.42-0.74,0.89-1.09,1.81-1.06c2.5,0.07,5,0.05,7.5,0.01
					c0.7-0.01,1.1,0.2,1.47,0.8c1.8,2.91,3.66,5.79,5.5,8.69c0.71,0.94,0.32,1.21-0.72,1.29c-6.66,0.48-13.33,0.51-20,0.19
					C531.75,2553.15,531.82,2552.74,532.36,2551.97z"/>
				<path className="st15" d="M499.88,2543.71c-0.94-4.37,0.25-4.74,1.7-6.93c1.42-2.15,2.32-2.55,4.6-2.28
					c2.05,0.24,2.25,0.79,2.53,1.99c0.33,1.43,0.73,2.85,1.18,4.24c0.26,0.8,0.17,1.39-0.32,2.08c-0.64,0.89-1.25,1.81-1.85,2.74
					c-0.3,0.46-0.6,0.93-0.9,1.39c-0.45,0.69-0.95,1.36-1.28,2.13l0,0c-0.08,0.18-0.25,0.28-0.43,0.27
					c-1.43-0.15-2.64-0.72-4.11-0.99c-0.4-0.07-0.38-0.46-0.43-0.79C500.34,2546.27,500.11,2544.99,499.88,2543.71z"/>
				<path className="st15" d="M507.74,2547.83c0.41-0.59,0.86-1.15,1.25-1.75c0.6-0.91,1.17-1.85,1.78-2.75
					c0.38-0.57,0.91-0.87,1.55-0.91c1.51-0.1,3.01-0.03,4.52-0.16c0.95-0.09,1.7,0.12,2.11,1.13c0.91,2.24,2.94,7.07,3.03,7.28
					c0,0.01,0,0.01,0.01,0.02c0.02,0.07,0.19,0.53,0.44,1.23c0.08,0.22-0.09,0.44-0.3,0.42c-1.94-0.26-3.86-0.49-5.76-0.82
					c-2.93-0.51-5.86-1.1-8.78-1.69c-0.18-0.04-0.39-0.11-0.56-0.19c-0.17-0.07-0.24-0.29-0.14-0.46l0.28-0.46
					C507.34,2548.41,507.54,2548.12,507.74,2547.83z"/>
				<path className="st15" d="M577.79,2546.58c0,0-1.22-2.34-1.95-3.41c-0.75-1.09-1.05-2.11-0.64-3.41c0.4-1.28,0.64-2.61,0.93-3.92
					c0.08-0.38,0.19-0.66,0.63-0.72c0.96-0.13,1.92-0.29,2.88-0.45c0.47-0.08,0.71,0.23,0.94,0.55c1.01,1.36,2,2.73,3.03,4.07
					c0.55,0.72,0.3,1.05,0.29,2.3c-0.09,1.3-0.23,2.6-0.25,3.91c-0.01,0.72-0.29,1.07-0.94,1.27c-1,0.3-2.01,0.63-2.98,1.02
					c-0.51,0.2-0.87,0.06-1.24-0.25c0,0,0.01,0.01,0.01,0.01S577.79,2546.58,577.79,2546.58L577.79,2546.58z"/>
				<path className="st15" d="M563.44,2550.06c0.98-2.42,1.96-4.83,2.95-7.25c0.13-0.31,0.26-0.64,0.53-0.85
					c0.33-0.26,0.79-0.27,1.21-0.27c1.2,0,2.39,0.01,3.59,0.01c0.29,0,0.58,0,0.87,0c0.26,0,0.57-0.05,0.82,0.03
					c0.44,0.15,0.74,0.62,0.97,1c0.71,1.13,1.41,2.27,2.12,3.4c0.19,0.31,0.65,0.88,0.65,0.88l0.53,0.82c0,0,0.13,0.5-0.11,0.67
					c-0.24,0.17-0.53,0.25-0.81,0.32c-4.47,1.22-9,2.21-13.57,2.99c-0.09,0.02-0.2,0.03-0.28-0.04c-0.1-0.09-0.06-0.26-0.01-0.39
					C563.08,2550.95,563.26,2550.5,563.44,2550.06z"/>
				<path className="st15" d="M496.25,2530.09c1.08-1.23,2.2-2.44,3.23-3.72c0.48-0.6,0.84-0.55,1.48-0.26c1.71,0.77,2.15,1.87,2,3.87
					c-0.1,1.35-0.31,2.71-0.82,3.97c-0.24,0.61-0.55,1.19-0.92,1.72c-0.49,0.71-1.1,1.34-1.62,2.03c-0.31,0.42-0.65,0.92-1.11,1.18
					c-0.91,0.53-2.14-0.46-2.9-0.89c-0.49-0.28-0.67-0.57-0.6-1.15c0.27-2.01,0.49-4.03,0.73-6.05
					C495.9,2530.56,496.08,2530.33,496.25,2530.09z"/>
				<path className="st15" d="M493.2,2539.72c0.31-0.45,0.42-1.02,1.07-1.11c0.44-0.06,0.79,0.19,1.16,0.39
					c0.83,0.46,1.96,0.74,2.43,1.64c0.29,0.56,0.3,1.2,0.39,1.81c0.13,0.87,0.34,1.72,0.53,2.58c0.16,0.71,0.22,1.44,0.37,2.15
					c0.14,0.67,0.05,0.97-0.73,0.75c-1.94-0.56-3.88-1.1-5.83-1.63c-0.48-0.13-0.76-0.34-0.66-0.87
					C492.25,2543.51,492.01,2541.45,493.2,2539.72z"/>
				<path className="st15" d="M504.91,2511.34c1.41-0.93,2.88-1.77,4.2-2.8c1.08-0.84,1.89-0.19,2.78,0.19c0.45,0.2,0.11,0.46-0.02,0.7
					c-2.02,3.79-5.27,6.27-8.88,8.37c-0.85,0.5-1.46-0.22-2.03-0.58c-0.53-0.33,0.68-0.94,0.9-1.24c0.86-1.18,1.09-2.46,1.93-3.65
					C504.09,2511.9,504.48,2511.65,504.91,2511.34z"/>
				<path className="st15" d="M516,2503.3c0.01,0,0.02-0.01,0.03-0.01c1.34-0.5,2.73-0.89,4-1.53c1.15-0.58,2.17-0.47,3.32-0.03
					c0.28,0.11,0.35,0.48,0.12,0.68c-0.54,0.48-1.27,1.27-1.41,1.4c-0.66,0.62-1.33,1.24-2.1,1.72c-1.45,0.9-3.17,1.14-4.7,1.87
					c-1.44,0.68-2.74,1.23-4.11,0.37c-0.23-0.14-0.27-0.46-0.07-0.64c0.89-0.83,1.79-1.68,2.7-2.52
					C514.41,2503.98,515.22,2503.66,516,2503.3z"/>
				<path className="st14" d="M500.04,2517.84c0.77-0.55,0.15-0.69,1.36,0.23c0.57,0.43,0.75,0.64,0.5,1.37
					c-1.23,3.55-2.97,6.76-5.72,9.37c-0.27,0.26-0.47,0.59-0.7,0.89c-1.02,0.45-0.58-0.28-0.44-0.78c0.7-2.6,2.06-4.42,3.08-6.91
					C498.5,2521.07,499.19,2518.48,500.04,2517.84z"/>
				<path className="st15" d="M567.54,2505.36c0.01,0,0.01,0.01,0.01,0.01c0.58,0.55,1.18,1.08,1.72,1.67c0.28,0.3,0.91,0.58,0.48,1.1
					c-0.34,0.41-0.85,0.72-1.47,0.43c-3.18-1.44-6.62-2.39-9.17-4.99c-0.47-0.48-1.14-0.76-1.51-1.32
					c-0.03-0.05-0.02-0.11,0.02-0.15c1.03-0.82,2.06-0.57,3.13-0.09c0.65,0.29,1.34,0.49,2.01,0.73c0,0,0.01,0,0.01,0
					C564.44,2503.5,566.04,2504.35,567.54,2505.36z"/>
				<path className="st15" d="M584.58,2541.44c-0.05-1.43,0.67-2.28,1.93-2.91c0.81-0.41,1.17-0.33,1.54,0.44
					c0.18,0.37,0.48,0.69,0.73,1.03c0.45,0.81,0.85,1.59,1.03,2.54c0.22,1.17-0.02,1.8-1.19,2.16c-1.08,0.33-2.12,0.82-3.16,1.28
					c-0.82,0.36-1.22,0.34-1.09-0.75C584.51,2543.97,584.51,2542.7,584.58,2541.44z"/>
				<path className="st15" d="M578.04,2514.82c0.32,0.73,0.53,1.53,0.53,2.3c0,0.37-0.43,0.58-0.73,0.36c-1.7-1.22-3.51-2.17-4.91-3.68
					c-1.08-1.17-2.02-2.48-2.98-3.76c-0.21-0.28-0.81-0.56-0.33-1.03c0.42-0.39,0.87-0.75,1.56-0.32c0.88,0.56,1.85,0.99,2.78,1.47
					c0,0,0.64,0.5,0.64,0.5c0.74,0.58,1.42,1.15,2.02,1.89c0.29,0.36,0.55,0.73,0.8,1.12
					C577.64,2514.02,577.86,2514.41,578.04,2514.82z"/>
				<path className="st15" d="M586.63,2529.92c-0.16-0.04-0.39-0.02-0.46-0.12c-1.72-2.27-3.94-4.17-5.01-6.92
					c-0.43-1.1-0.93-2.17-1.47-3.21c-0.37-0.71-0.44-1.42-0.44-2.19c0-0.98-0.32-1.9-0.76-2.78c-0.06-0.22-0.32-0.81-0.09-0.64
					c0.71,0.51,1.18,1.17,1.42,1.63c0.22,0.42,0.46,0.83,0.71,1.23c0.51,0.82,1.06,1.63,1.51,2.48c1.63,3.06,3.1,6.19,4.33,9.43
					C586.5,2529.17,586.54,2529.56,586.63,2529.92z"/>
				<path className="st17" d="M535.74,2498.22c0.03,0.01,0.04,0.05,0.02,0.07c-1.09,1.07-2.49,1.57-3.91,1.9
					c-1.88,0.44-3.82,0.65-5.7,1.1c-1.07,0.26-2.44,0.18-3.41-0.39c0.06-0.3,0.43-0.42,0.67-0.54c0.68-0.33,1.35-0.69,2.05-0.97
					c0.73-0.29,1.47-0.54,2.22-0.74c1.49-0.4,3.03-0.62,4.58-0.65c0.6-0.01,1.19,0,1.79,0.05c0.3,0.02,0.6,0.05,0.9,0.08
					C535.05,2498.13,535.53,2498.18,535.74,2498.22z"/>
				<path className="st15" d="M535.15,2497.55c3.93-0.61,7.85-0.88,11.77,0.02c0.05,0.01,0.04,0.08,0,0.09
					c-3.93,0.57-7.85,0.77-11.77-0.02C535.1,2497.63,535.1,2497.56,535.15,2497.55z"/>
				<path className="st15" d="M512.97,2504.44c-0.56,1.03-1.57,1.62-2.42,2.33c-1.9,1.58-3.94,2.99-6.11,4.17
					c1.04-2.78,3.33-4.26,5.81-5.5C511.11,2505.01,512.06,2504.76,512.97,2504.44z"/>
				<path className="st15" d="M573.84,2509.76c-0.08-0.05-0.17-0.1-0.26-0.15c-0.23-0.13-0.45-0.26-0.67-0.4
					c-0.43-0.27-0.86-0.56-1.27-0.86c-0.41-0.3-0.81-0.62-1.2-0.95c-0.39-0.33-0.77-0.67-1.15-1.01c-0.19-0.17-0.38-0.35-0.57-0.53
					c-0.09-0.09-0.19-0.18-0.28-0.27c-0.05-0.04-0.09-0.09-0.14-0.13c-0.01-0.01-0.14-0.12-0.14-0.13c0.07-0.21,0.23-0.22,0.39-0.16
					c2.44,0.82,4.51,2.18,5.99,4.33c0.14,0.2,0.6,0.54-0.09,0.66c-0.07-0.04-0.15-0.07-0.21-0.12c-0.12-0.07-0.22-0.15-0.33-0.23
					C573.89,2509.79,573.86,2509.77,573.84,2509.76z"/>
				<path className="st15" d="M589.08,2539.71c-1.01-1.17-1.4-2.81-1.69-4.29c-0.3-1.55-0.43-3.12-0.68-4.68
					c-0.02-0.09,0.1-0.15,0.16-0.09c0.6,0.62,0.61,1.5,0.87,2.27c0.35,1.01,0.63,2.04,0.87,3.08c0.13,0.56,0.18,1.15,0.28,1.72
					c0.1,0.54,0.52,1.03,0.49,1.6C589.37,2539.5,589.27,2539.68,589.08,2539.71C589.08,2539.71,589.08,2539.71,589.08,2539.71z"/>
				<path className="st15" d="M523.42,2499.86c0.02,0.06-4.06,1.66-4.44,1.81c-0.78,0.32-1.55,0.64-2.33,0.94
					c-0.4,0.16-0.8,0.31-1.2,0.45c-0.37,0.13-0.78,0.36-1.17,0.28c2.72-2.15,5.71-3.4,9.07-3.7
					C523.37,2499.64,523.4,2499.78,523.42,2499.86z"/>
				<path className="st14" d="M494.68,2530.49c-0.29,2.28-0.55,4.56-0.87,6.83c-0.1,0.76-0.56,1.39-0.98,2.01
					c-0.05-0.2-0.18-0.42-0.14-0.59c0.62-2.7,0.36-2.12,1.15-4.79C493.87,2533.82,494.53,2530.38,494.68,2530.49z"/>
				<path className="st15" d="M542.64,2541.14c-0.51,0.02-1.01,0.04-1.52,0.08c-1.21,0.09-2.34,0.18-3.25-0.64
					c-0.6-0.54-2.82-4.61-2.82-4.61l0,0c-0.52-0.85-1.18-1.81-1.11-2.81c0.07-0.98,0.6-1.85,1.12-2.69
					c0.65-1.04,1.29-2.08,1.94-3.12c0.28-0.44,0.57-0.91,1.04-1.14c0.36-0.18,0.77-0.19,1.17-0.19c1.61-0.03,3.22-0.06,4.83-0.09
					c0.56-0.01,1.86-0.03,2.27-0.03c0.1,0,0.2,0.02,0.28,0.07c0.46,0.27,0.82,0.96,1.07,1.37c1.14,1.81,2.29,3.62,3.43,5.44
					c0.13,0.21,0.27,0.43,0.27,0.68c0,0.23-0.11,0.45-0.21,0.65c-1.03,1.95-2.06,3.9-3.09,5.85c-0.21,0.4-0.44,0.82-0.83,1.05
					c-0.41,0.24-0.92,0.21-1.39,0.18C544.78,2541.12,543.71,2541.1,542.64,2541.14z"/>
				<path className="st18" d="M524.74,2533.23c-0.66,0.18-1-0.09-1.26-0.65c-0.79-1.74-1.6-3.47-2.45-5.18
					c-0.27-0.54-0.24-0.96,0.05-1.48c0.96-1.74,1.9-3.49,2.8-5.26c0.34-0.66,0.8-0.91,1.48-0.98c1.94-0.2,3.87-0.44,5.8-0.7
					c0.72-0.1,1.22,0.01,1.66,0.73c0.99,1.62,2.09,3.17,3.17,4.73c0.37,0.53,0.4,0.96,0.06,1.55c-1.17,1.99-2.3,4-3.42,6.02
					c-0.25,0.46-0.52,0.75-1.07,0.78C529.25,2532.92,526.96,2533.08,524.74,2533.23z"/>
				<path className="st17" d="M542.16,2524.82c-0.74,0-1.48-0.01-2.21,0c-1.53,0.01-2.7-1.05-3.62-2.25c-0.47-0.61-0.88-1.26-1.32-1.89
					c-0.47-0.67-1.17-1.28-1.32-2.14c-0.17-0.93,0.59-1.75,1.06-2.43c0.83-1.22,1.75-3.05,3.03-3.79c0.8-0.46,1.73-0.33,2.6-0.34
					c1.52-0.02,3.16-0.29,4.64,0.16c1.03,0.31,1.84,1.06,2.5,1.93c0.54,0.71,1.07,1.42,1.61,2.13c0.43,0.57,1.14,1.23,1.2,2.01
					c0.04,0.58-0.29,1.06-0.6,1.5c-0.81,1.17-1.25,2.65-2.21,3.69c-0.75,0.82-1.8,1.32-2.87,1.37
					C543.83,2524.81,542.99,2524.82,542.16,2524.82z"/>
				<path className="st16" d="M558.15,2532.89l-4.76-0.4c-0.52,0.01-0.87-0.14-1.17-0.63c-1.3-2.17-2.64-4.33-3.99-6.47
					c-0.27-0.43-0.33-0.78-0.05-1.24c1.02-1.67,2.03-3.36,3.01-5.06c0.3-0.53,0.69-0.59,1.2-0.52c1.99,0.28,3.99,0.55,5.99,0.8
					c0.64,0.08,1.04,0.38,1.37,0.98c0.97,1.75,1.99,3.48,3.05,5.18c0.38,0.61,0.36,1.1,0.09,1.71c-0.56,1.28-1.14,2.55-1.58,3.88
					C560.89,2532.44,560.65,2533.17,558.15,2532.89z"/>
				<path className="st18" d="M514.62,2541.47c-0.89,0.03-1.79,0.05-2.68,0.08c-0.22,0.01-0.45,0.01-0.64-0.08
					c-0.4-0.18-0.54-0.64-0.67-1.05c-0.47-1.48-1.08-2.93-1.35-4.43c-0.34-1.86,1.05-3.41,2.05-5.17c0.6-1.05,1.53-1.96,2.66-2.43
					c0.99-0.41,2.07-0.57,3.12-0.72c0.87-0.12,2.05-0.56,2.79,0.1c0.63,0.56,1.45,2.19,1.45,2.19s1.19,2.14,1.32,3.38
					c0.08,0.8-0.18,1.61-0.57,2.32c-0.39,0.71-0.9,1.35-1.37,2.02c-0.54,0.76-1.02,1.57-1.44,2.4c-0.19,0.38-0.39,0.79-0.75,1.02
					c-0.36,0.24-0.83,0.26-1.27,0.28C516.39,2541.42,515.5,2541.45,514.62,2541.47z"/>
				<path className="st16" d="M571.08,2540.44c-4.54,0.47-5.05-0.48-6.12-2.61c-0.68-1.36-1.68-2.59-2.57-3.86
					c-0.3-0.43-0.36-0.8-0.12-1.28c0.75-1.51,1.48-3.04,2.16-4.58c0.28-0.63,0.67-0.81,1.36-0.66c0.74,0.16,1.46,0.29,2.24,0.38
					c4.2,0.51,3.52,0.47,5.22,3.28c2.06,3.41,2.17,3.36,1.1,7.07C573.69,2540.44,573.69,2540.44,571.08,2540.44L571.08,2540.44z"/>
				<path className="st17" d="M524.85,2518.6c-0.2,0.03-0.56-0.06-0.89-0.47c-0.79-1.02-1.6-2.02-2.46-2.97
					c-0.55-0.6-0.5-1.07-0.04-1.68c0,0,1.6-2.41,1.6-2.41c0.77-1.15,1.43-1.98,2.85-2.31c1.61-0.37,3.25-0.54,4.88-0.78
					c1.23-0.18,2.2,0.34,3.09,1.14c0.75,0.69,2.33,1.57,2.47,2.66c0.09,0.71-0.39,1.25-0.82,1.73c-1.28,1.44-1.92,3.4-3.81,4.18
					C529.56,2518.57,527.1,2518.21,524.85,2518.6z"/>
				<path className="st15" d="M558.6,2517.92c-2.29-0.28-4.39-0.54-6.5-0.8c-0.41-0.05-0.71-0.24-0.97-0.57
					c-1.16-1.46-2.34-2.91-3.55-4.33c-0.34-0.4-0.47-0.86-0.15-1.3c0.29-0.4,0.62-0.77,0.9-1.18c0.38-0.53,0.78-1.07,1.35-1.42
					c0.88-0.55,1.95-0.58,2.95-0.43c0.34,0.05,0.67,0.12,1.01,0.2c4.25,1.05,4.29,0.9,7.01,4.29c1.33,1.66,1.33,1.66,0.09,3.43
					c-0.36,0.51-0.75,1-1.07,1.52C559.36,2517.82,559.01,2518.1,558.6,2517.92z"/>
				<path className="st15" d="M513.63,2527.18c-0.58,0.12-1.12-0.78-1.47-1.18c-0.5-0.57-1.08-1.65-1.63-2.43
					c-0.34-0.48,0.98-3.15,1.74-4.46c0.95-1.63,0.31-1.82,3.38-2.82c1.24-0.4,2.3-0.72,3.43-1.15c0.6-0.23,1.02-0.13,1.41,0.4
					c0.77,1.03,1.56,2.03,2.38,3.01c0.37,0.44,0.38,0.81,0.1,1.31c-1.01,1.84-1.99,3.7-2.98,5.55c-0.16,0.3-0.34,0.54-0.68,0.61
					C517.43,2526.41,515.53,2526.79,513.63,2527.18z"/>
				<path className="st17" d="M541,2510.58c-2.97,0.23-4.49-0.59-6.3-2.78c-0.95-1.15-0.93-0.97-0.04-2.16
					c1.57-2.11,3.53-2.64,6.09-2.66c2.75-0.02,4.98,0.5,6.72,2.7c0.21,0.26,0.5,0.46,0.76,0.67c0.32,0.26,0.4,0.53,0.1,0.86
					c-0.08,0.08-0.14,0.17-0.22,0.26c-0.89,1.02-1.49,2.53-2.68,3C544.29,2510.93,542.84,2510.58,541,2510.58z"/>
				<path className="st15" d="M569.87,2527.07c-1.71-0.35-3.43-0.7-5.14-1.04c-0.4-0.08-0.52-0.44-0.7-0.74c-1-1.72-1.98-3.46-3-5.17
					c-0.36-0.61-0.33-1.07,0.08-1.63c0.61-0.83,1.18-1.7,1.68-2.6c0.35-0.62,0.71-0.69,1.31-0.43c0.98,0.43,1.97,0.91,3,1.12
					c1.76,0.35,2.79,1.46,3.41,3.15c0.1,0.26,0.25,0.5,0.37,0.75c1.56,3.11,1.56,3.1-0.23,6.04
					C570.47,2526.82,570.31,2527.14,569.87,2527.07z"/>
				<path className="st17" d="M522.74,2509.28C522.74,2509.28,522.74,2509.28,522.74,2509.28c-0.04,0.07-0.08,0.13-0.12,0.19
					c-1.03,1.51-1.75,3.52-3.14,4.45c-1.79,1.2-4.11,1.55-6.19,2.29c-0.56,0.2-0.87-0.2-1.24-0.46c-2.28-1.63-2.16-1.56-0.71-3.93
					c1.22-2.01,1.71-2.97,3.1-3.38l4.28-1.68c1.6-0.56,2.37,0.25,3.86,1.66c0.02,0.02,0.04,0.04,0.06,0.06
					C522.84,2508.7,522.88,2509.02,522.74,2509.28z"/>
				<path className="st19" d="M511.67,2517.63c-0.07,0.16-0.15,0.34-0.24,0.52c-0.75,1.49-1.51,2.97-2.25,4.47
					c-0.22,0.45-0.54,0.76-0.97,1.02c-1.27,0.77-2.55,1.54-3.77,2.38c-0.66,0.46-1.17,0.44-1.85,0.03c-2.11-1.28-1.99-1.2-1.24-3.45
					c0.41-1.23,1.01-2.39,1.81-3.41c1.18-1.49,2.9-2.25,4.37-3.39c0.34-0.26,0.73-0.58,1.19-0.45c0.99,0.28,1.8,1.15,2.69,1.77
					C511.6,2517.23,511.73,2517.37,511.67,2517.63z"/>
				<path className="st15" d="M525.07,2507.9c-0.71,0.04-3.43-1.65-3.63-2.09c-0.14-0.31,0.12-0.42,0.27-0.56
					c1.01-0.93,2-1.88,3.06-2.76c0.47-0.39,1.09-0.45,1.67-0.52c0.69-0.09,1.38-0.2,2.07-0.35c0.74-0.16,1.48-0.46,2.23-0.54
					c0.65-0.07,1.32,0,1.95,0.18c0.91,0.27,1.75,0.76,2.5,1.33c0.45,0.34,0.59,0.49,0.14,0.94c-0.93,0.9-1.81,1.85-2.71,2.79
					c-0.25,0.26-0.56,0.43-0.92,0.49C529.43,2507.18,527.15,2507.56,525.07,2507.9z"/>
				<path className="st18" d="M571.94,2514.11c0.13,0.16,0.14,0.39,0.02,0.56c-0.19,0.28-0.5,0.45-0.7,0.71c-0.78,1-1.62,1.02-2.85,0.71
					c-3.66-0.9-6.35-2.97-8.43-6.18c-0.62-0.97-0.46-1.27,0.18-2.05c0.4-0.48,0.89-0.96,1.49-1.15c0.57-0.18,1.16-0.04,1.67,0.25
					c0.34,0.19,0.69,0.35,1.04,0.5c0.27,0.12,0.53,0.24,0.79,0.38c0.59,0.3,1.17,0.63,1.73,1c1.07,0.71,2.06,1.55,2.94,2.49
					c0.71,0.77,1.35,1.61,1.92,2.5C571.8,2513.94,571.87,2514.03,571.94,2514.11z"/>
				<path className="st18" d="M549.48,2500.93c2.38,0.47,4.74,0.69,6.91,1.45c1.81,0.63,3.09,2.31,4.63,3.49c0.38,0.3,0,0.51-0.18,0.71
					c-0.66,0.74-1.45,1.48-2.44,1.73c-1.01,0.25-2.08-0.09-3.06-0.32c-1.06-0.25-2.13-0.4-3.18-0.73c-1.05-0.33-2.02-0.86-2.91-1.48
					c-0.97-0.68-1.84-1.48-2.64-2.35c-0.44-0.48-0.48-0.72,0.08-1.03C547.64,2501.9,548.37,2500.96,549.48,2500.93z"/>
				<path className="st15" d="M507.79,2533.51c-1.27-0.15-2.51-0.3-3.76-0.42c-0.49-0.05-0.58-0.26-0.53-0.7
					c0.16-1.52,0.31-3.04,0.41-4.56c0.03-0.54,0.26-0.85,0.71-1.12c1.34-0.81,2.67-1.63,3.97-2.49c0.47-0.31,0.78-0.32,1.05,0.19
					c0.04,0.07,0.09,0.13,0.14,0.19c0.72,0.99,1.88,1.93,2.02,2.99c0.15,1.09-1.12,2.07-1.73,3.13c-0.45,0.78-0.97,1.53-1.46,2.3
					C508.42,2533.32,508.24,2533.65,507.79,2533.51z"/>
				<path className="st15" d="M587.4,2537.06c0.03,0.14,0.01,0.29-0.06,0.42c-0.37,0.69-1.35,0.82-2.01,1.29
					c-0.62,0.45-0.86-0.22-1.15-0.57c-1.07-1.29-2.32-2.55-3.03-4.07c-0.7-1.51-0.95-3.19-1.06-4.83c-0.01-0.13-0.03-0.25-0.05-0.38
					c-0.18-1.21,0.64-2.46,1.79-2.77c0.38-0.1,0.55,0.11,0.73,0.33c1.15,1.34,2.29,2.69,3.43,4.04c0.18,0.21,0.29,0.46,0.34,0.72
					C586.68,2533.11,587.02,2535,587.4,2537.06z"/>
				<path className="st15" d="M580.85,2524.31c-0.03,0.79-2.03,1.75-2.74,1.37c-2.92-1.58-5.02-3.86-6.34-6.95
					c-0.76-1.77-0.64-2.52,1.02-3.53c0.4-0.24,0.59-0.03,0.84,0.15c1.41,0.99,2.82,2,4.24,2.98c0.36,0.25,0.54,0.58,0.71,0.95
					c0.65,1.43,1.31,2.85,1.97,4.28C580.66,2523.8,580.76,2524.07,580.85,2524.31z"/>
				<path className="st15" d="M576.31,2533.94c-0.53,0.18-0.72-0.18-0.92-0.51c-1.06-1.72-2.12-3.45-3.19-5.17
					c-0.22-0.36-0.23-0.64-0.06-1.04c1.58-3.6,1.59-3.54,4.84-1.46c1.54,0.98,2.3,2.15,2.34,3.94c0.02,0.96,0.27,1.92,0.44,2.87
					c0.11,0.61-0.05,0.93-0.75,0.98C578.1,2533.63,577.18,2533.81,576.31,2533.94z"/>
				<g>
					<path className="st17" d="M545.64,2499.12C545.64,2499.12,545.64,2499.12,545.64,2499.12c-1.31-0.62-2.81-0.65-4.25-0.66
						c-1.22-0.01-2.46-0.02-3.66,0.2c-1.19,0.22-2.36,0.68-3.24,1.5c-0.02,0.02-0.02,0.05-0.01,0.08c0.82,1.11,2.21,1.68,3.58,1.89
						c1.38,0.2,2.79,0.09,4.19,0.12c1.15,0.03,2.33,0.16,3.41-0.23c0.86-0.31,1.58-0.93,2.26-1.55c0.03-0.03,0.02-0.07-0.01-0.09
						c-0.1-0.05-0.27-0.16-0.3-0.17c-0.13-0.07-0.27-0.14-0.4-0.21c-0.16-0.08-0.32-0.16-0.47-0.25c-0.16-0.09-0.3-0.2-0.46-0.28
						c-0.05-0.03-0.1-0.05-0.15-0.08c-0.06-0.03-0.13-0.06-0.19-0.1L545.64,2499.12z"/>
					<path className="st17" d="M558.11,2501.03c-0.04-0.16-0.6-0.42-0.75-0.51c-0.52-0.32-1.25-0.74-1.82-1.02
						c-2.73-1.38-5.93-1.79-8.92-1.13c-0.15,0.03-0.33,0.1-0.34,0.25c-0.01,0.1,0.07,0.29,0.15,0.35c0.06,0.04,0.12,0.06,0.19,0.08
						c0.27,0.08,0.54,0.17,0.8,0.26l0.27,0.1c2.9,1.05,5.93,1.73,9,2.03c0.21,0.02,0.54,0.1,0.74,0.02
						C557.72,2501.32,558.17,2501.3,558.11,2501.03z"/>
				</g>
				<path className="st20" d="M469.75,2543.2l17.12,3.85l4.41-1.06l3.44-15.6l4.53-11.65l3.2-4.07c1.12-1.45-0.7-1.95-2.36-2.72
					l-16.04,9.31"/>
				<g>
					<path className="st21" d="M482.9,2522.06c-1.85-0.63-3.68-1.36-5.4,0.33c-0.35,0.35-0.86,0.53-1.29,0.79
						c-1.15,0.69-2.15,3.42-1.72,4.65c0.09,0.26,0.26,0.44,0.52,0.51c1.74,0.49,3.5,1.51,5.18-0.17c0.17-0.17,0.42-0.29,0.65-0.4
						c1.94-0.85,2.75-2.37,2.63-4.37C483.64,2522.77,483.62,2522.3,482.9,2522.06z"/>
					<path className="st21" d="M466.03,2526.85c0.56,0.07,0.98-0.17,1.37-0.53c0.68-0.63,1.34-1.28,2.07-1.85
						c1.04-0.82,1.59-1.9,1.98-3.14c0.29-0.92,0.01-0.99-0.8-0.83c-1.71,0.34-5.32,4.02-5.5,5.67
						C465.05,2526.93,465.59,2526.81,466.03,2526.85z"/>
					<path className="st21" d="M479.48,2516.94c0.08,0.02,0.17,0,0.25,0c2.09-0.02,3.89-1,5.74-1.8c0.26-0.11,0.42-0.51,0.59-0.79
						c0.34-0.57,0.28-0.84-0.5-0.97c-2.41-0.41-4.45,0.73-6.55,1.53c-0.6,0.23-1.2,0.93-1.08,1.58
						C478.08,2517.21,478.94,2516.81,479.48,2516.94z"/>
					<path className="st21" d="M471,2519.88c2.38-0.61,4.31-2.03,6.15-3.57c0.24-0.2,0.5-0.46,0.46-1.07c-2.87,0.48-5.19,1.81-7.16,3.8
						c-0.2,0.2-0.48,0.48-0.28,0.77C470.36,2520.09,470.72,2519.96,471,2519.88z"/>
					<path className="st21" d="M476.68,2517.52c-1.29,0.79-2.62,1.54-3.86,2.41c-0.9,0.62-0.91,1.76-1.23,2.7
						c-0.07,0.22,0.06,0.47,0.27,0.5c0.99,0.14,2.02,0.68,2.96,0.21c1.46-0.74,2.9-1.58,4.22-2.54c0.94-0.68,1.18-1.94,1.05-2.97
						c-0.08-0.63-1.18-0.21-1.81-0.38c-0.2-0.05-0.42-0.06-0.62-0.09C477.31,2517.29,476.98,2517.34,476.68,2517.52z"/>
					<path className="st21" d="M465.01,2527.52c-0.44,0.11-0.74,0.37-1.02,0.7c-1.48,1.75-2.74,3.56-3.1,5.95
						c-0.19,1.24,0.3,1.39,1.12,1.4c0.48,0.1,0.73-0.08,0.96-0.37c1.56-1.94,3.21-3.8,3.34-6.5
						C466.37,2527.54,466.15,2527.25,465.01,2527.52z"/>
					<path className="st21" d="M493.39,2512.34c1.91,0.65,3.9,0.6,5.88,0.55c0.36-0.01,0.84,0.03,0.88-0.49
						c0.04-0.5-0.44-0.55-0.79-0.66c-1.18-0.39-2.4-0.38-3.63-0.31c-0.76,0-1.52-0.01-2.27,0.01c-0.3,0.01-0.64,0.08-0.69,0.43
						C492.72,2512.28,493.13,2512.25,493.39,2512.34z"/>
					<path className="st21" d="M463.43,2524.15c0.39,0.16,0.76,0.35,1.12,0.24l0.6-0.92C464.79,2523.98,464.26,2524.29,463.43,2524.15z"
						/>
					<path className="st21" d="M489.51,2516.67c-1.41-0.37-2.76-1.21-4.28-0.75c-1.13,0.34-2.22,0.82-3.32,1.26
						c-0.8,0.32-1.88,2.31-1.75,3.14c0.04,0.24,0.17,0.39,0.38,0.45c1.09,0.34,2.18,0.67,3.21,0.99c1.42-0.28,2.64-0.9,3.91-1.35
						c1.4-0.5,2.19-1.77,2.1-3.2C489.75,2516.98,489.71,2516.72,489.51,2516.67z"/>
					<path className="st21" d="M486.54,2514.76c-0.1,0.43,0.33,0.5,0.63,0.65c2.59,1.2,5.11,0.46,7.64-0.25
						c0.6-0.17,0.65-0.72,0.73-1.22c0.06-0.37-0.21-0.54-0.51-0.61c-1.1-0.28-2.21-0.54-3.19-0.78c-1.47,0.25-2.81,0.45-4.13,0.71
						C486.93,2513.41,486.7,2514.1,486.54,2514.76z"/>
					<path className="st21" d="M483.74,2525.61c-0.01,0.25-0.11,0.56,0.13,0.71c1.33,0.82,2.73,1.49,4.19,2.07
						c1.61-0.54,3.17-1.05,4.73-1.59c0.31-0.11,0.42-0.42,0.48-0.71c0.19-0.95,0.37-1.91,0.54-2.86c0.07-0.37-0.03-0.69-0.37-0.87
						c-2.09-1.08-3.92-2.38-6.67-1.17C484.53,2522.16,483.8,2523.48,483.74,2525.61z"/>
					<path className="st21" d="M491.8,2511.7c-2.49-0.36-4.94-0.5-7.19,0.86C487.11,2513.14,489.45,2512.3,491.8,2511.7z"/>
					<path className="st21" d="M498.8,2517.3c-0.89-0.38-1.76-0.83-2.63-1.26c-0.47-0.23-0.94-0.37-1.47-0.25
						c-1.16,0.25-2.32,0.47-3.46,0.76c-0.89,0.23-1.88,2.96-1.2,3.4c1.4,0.92,2.93,1.65,4.35,2.08c1.96-0.22,3.75-0.31,4.37-2.43
						c0.08-0.28,0.26-0.53,0.44-0.76C499.75,2518.14,499.65,2517.66,498.8,2517.3z"/>
					<path className="st21" d="M501.94,2514.11c-1.06-0.48-2.14-0.8-3.15-0.65c-0.59,0-1.02-0.01-1.44,0c-0.21,0-0.43,0-0.63,0.06
						c-0.59,0.18-0.66,0.72-0.8,1.2c-0.14,0.49,0.27,0.65,0.59,0.84c3.32,1.99,3.31,1.98,5.63-0.4
						C502.71,2514.6,502.56,2514.39,501.94,2514.11z"/>
					<path className="st21" d="M474.05,2525.6c0.53-1.35-0.2-1.6-1.38-1.78c-1.28-0.2-2.18,0.18-3.01,1.08
						c-0.63,0.68-1.39,1.24-2.01,1.93c-1.1,1.22-0.92,2.81-1.14,4.29c-0.01,0.09,0.11,0.26,0.2,0.29c1,0.31,2.2,0.97,3.03,0.32
						C471.74,2530.14,474.51,2528.97,474.05,2525.6z"/>
					<path className="st21" d="M494.42,2527.91c-1.18-1.08-2.25-0.26-3.31,0.07c-3.14,0.98-3.14,1-3.23,4.34
						c0.02,0.49-0.17,1.12,0.39,1.57c0.97,0.76,1.91,1.55,2.92,2.24c0.86,0.59,1.76,0.2,2.03-0.79c0.55-2,1.06-4.01,1.63-6
						C495.02,2528.72,494.92,2528.36,494.42,2527.91z"/>
					<path className="st21" d="M496.75,2522.19c-1.72,0.05-2.28,0.49-2.61,2.23c-0.11,0.58-0.2,1.17-0.3,1.75
						c-0.15,0.84,0.55,1.05,1.05,1.4c0.29,0.2,0.46,0.01,0.57-0.26c0.57-1.5,1.15-3,1.72-4.51
						C497.31,2522.43,497.32,2522.17,496.75,2522.19z"/>
					<path className="st21" d="M486.61,2533.82c0.66-0.48,1.26-4.35,0.76-4.99c-0.12-0.16-0.33-0.26-0.51-0.37
						c-2.07-1.21-4-1.99-6.27-0.04c-1.26,1.09-2.29,1.92-1.87,3.39c0,0.6,0.04,0.9-0.01,1.19c-0.2,1.28,0.47,1.75,1.58,2.28
						c2.14,1.02,3.85,0.63,5.48-0.88C486.02,2534.17,486.33,2534.02,486.61,2533.82z"/>
					<path className="st21" d="M478.15,2530.65c0-0.4-0.1-0.71-0.5-0.94c-2.11-1.17-3.5-0.82-5.45,0.67c-1.79,1.38-2.34,2.96-2.16,5.12
						c0.17,2.07,0.03,2.1,2.05,2.59c0.8,0.19,1.8,0.88,2.42,0.25c1.64-1.67,4.19-2.87,3.65-5.79
						C478.15,2531.83,478.16,2531.24,478.15,2530.65z"/>
					<path className="st21" d="M490.33,2538.62c0.82-1.84,0.84-1.86-0.79-3.07c-2.61-1.95-1.59-1.83-4.36-0.16
						c-1.33,0.8-1.75,1.65-1.35,3.19c0.75,2.91,0.31,2.42,3.4,2.52c1.54,0.59,2.45-0.34,2.89-2.02
						C490.16,2538.92,490.26,2538.78,490.33,2538.62z"/>
					<path className="st21" d="M492.42,2536.9c-0.42-0.07-0.75,0.12-0.94,0.5c-0.38,0.8-0.64,1.67-1.13,2.4
						c-0.89,1.33-0.36,2.22,0.66,3.12c0.49,0.43,0.69,0.41,0.8-0.22c0.3-1.7,0.6-3.4,0.94-5.36
						C492.76,2537.31,492.84,2536.97,492.42,2536.9z"/>
					<path className="st8" d="M502.25,2512.51c-1.52-0.61-2.13-1.24-2.69-1.39c-2.69-0.73-5.04-0.98-7.83-0.78
						c-2.9,0.21-5.79,0.69-8.6,1.44c-2.86,0.76-5.86,1.53-8.59,2.82c-1.4,0.66-2.74,1.46-3.93,2.45
						c-0.95,0.78-17.73,21.84-8.68,13.45c0.74-0.38,7.44-10.39,7.69-10.87c-1.54,1.83-3.01,3.43-4.84,4.68
						c-0.08,0.05,0.44,0.61,0.37,0.63l-0.67,0.03c0.14-0.01,0,0,0.13,0.06c0.47,0.22,0.13,0.59,0.04,0.87
						c-0.66,1.92-2.14,3.29-3.27,4.9c-0.49,0.69-1.04-0.59-1.59,0.51c-0.59,1.19-1.19,2.38-1.59,3.65c-0.37,1.2-0.32,2.43-0.64,3.63
						c0.64-2.41,1.08-4.87,3.16-6.85c0.03,2.9-1.38,5.06-2.47,7.34c0.89-0.55,1.22-1.47,1.67-2.28c0.43-0.76,1.03-0.78,1.73-0.66
						c0.74,0.12,0.75,0.7,0.76,1.26c0.01,0.76-0.02,1.53,0.02,2.29c0.01,0.32-0.16,0.77,0.56,0.95c0-1.05,0.04-2.01-0.01-2.97
						c-0.06-1.07,0.19-1.98,0.95-2.78c0.52-0.55,0.91-1.22,1.37-1.83c0.68-0.9,3.13-1.12,3.95-0.35c0.11,0.11,0.22,0.29,0.22,0.44
						c-0.06,2.18,0.66,4.49-1.34,6.31c-0.64,0.59-1.41,1.25-1.65,2.37c0.26-0.13,0.4-0.15,0.47-0.24c0.34-0.38,0.67-0.76,0.99-1.16
						c1.94-2.41,1.93-2.38,4.91-1.56c0.95,0.26,1.37,0.74,1.56,1.71c0.15,0.79-0.02,1.75,0.85,2.52c0.02-0.34,0.08-0.52,0.02-0.65
						c-1.23-2.83,0.2-4.7,2.28-6.34c0.07-0.05,0.13-0.11,0.18-0.18c0.47-0.55,1-0.64,1.7-0.43c3.5,1.03,3.48,1.02,4.21,4.57
						c0.18,0.87,0.01,1.54-0.53,2.23c-0.4,0.52-1.07,0.92-1.17,1.85c1.42-0.7,1.66-2.81,3.57-2.48c1.38,0.24,2.92-0.49,4.13,0.64
						c1.06,0.99,2.27,1.94,1.6,3.81c0.66-0.25,0.64-0.57,0.69-0.85c0.27-1.5,0.5-3,0.78-4.5c1.19-6.42,2.64-12.78,5.41-18.74
						c1.48-3.2,3.36-4.52,5.17-8.46c0.19-0.42,0.12-0.63,0.12-0.63S502.83,2512.74,502.25,2512.51z M493.46,2511.43
						c0.76-0.02,1.51-0.01,2.27-0.01c1.22-0.07,2.45-0.08,3.63,0.31c0.34,0.11,0.83,0.16,0.79,0.66c-0.04,0.52-0.52,0.48-0.88,0.49
						c-1.98,0.05-3.96,0.1-5.88-0.55c-0.26-0.09-0.67-0.06-0.61-0.48C492.82,2511.52,493.17,2511.44,493.46,2511.43z
						 M495.03,2513.32c0.3,0.08,0.57,0.24,0.51,0.61c-0.08,0.5-0.14,1.05-0.73,1.22c-2.52,0.71-5.05,1.45-7.64,0.25
						c-0.31-0.14-0.73-0.22-0.63-0.65c0.16-0.66,0.39-1.35,1.17-1.51c1.32-0.26,2.66-0.46,4.13-0.71
						C492.82,2512.78,493.93,2513.04,495.03,2513.32z M487.66,2520.41c-1.27,0.45-2.49,1.07-3.91,1.35
						c-1.03-0.32-2.12-0.65-3.21-0.99c-0.21-0.07-0.34-0.21-0.38-0.45c-0.14-0.83,0.94-2.82,1.75-3.14c1.1-0.44,2.19-0.92,3.32-1.26
						c1.52-0.46,2.88,0.37,4.28,0.75c0.2,0.05,0.24,0.32,0.25,0.54C489.86,2518.64,489.06,2519.91,487.66,2520.41z M493.45,2522.35
						c0.34,0.18,0.44,0.5,0.37,0.87c-0.17,0.96-0.35,1.91-0.54,2.86c-0.06,0.29-0.17,0.6-0.48,0.71c-1.56,0.54-3.12,1.05-4.73,1.59
						c-1.46-0.58-2.87-1.24-4.19-2.07c-0.24-0.15-0.14-0.45-0.13-0.71c0.06-2.13,0.79-3.45,3.03-4.43
						C489.53,2519.98,491.36,2521.28,493.45,2522.35z M480.84,2527.8c-0.49,0.32-0.93,0.7-1.44,0.97c-1.43,0.78-2.98-0.03-4.4-0.43
						c-0.25-0.07-0.43-0.25-0.52-0.51c-0.34-0.97,0.08-2.21,0.5-3.09c0.22-0.46,0.5-0.98,0.89-1.32c0.55-0.47,1.2-0.79,1.79-1.21
						c0.55-0.39,1.06-0.74,1.75-0.84c1.19-0.18,2.38,0.3,3.48,0.68c0.56,0.19,0.66,0.67,0.62,1.2c-0.04,0.58-0.05,1.2-0.2,1.77
						c-0.13,0.51-0.36,0.99-0.68,1.41c-0.41,0.53-0.99,0.88-1.56,1.21C481,2527.7,480.92,2527.75,480.84,2527.8z M491.8,2511.7
						c-2.35,0.6-4.69,1.44-7.19,0.86C486.86,2511.21,489.31,2511.34,491.8,2511.7z M479.02,2514.91c2.1-0.8,4.14-1.94,6.55-1.53
						c0.78,0.13,0.84,0.4,0.5,0.97c-0.17,0.29-0.33,0.68-0.59,0.79c-1.33,0.58-2.65,1.24-4.07,1.57c-0.74,0.18-1.45,0.21-2.2,0.2
						c-0.44,0-1.15,0.17-1.26-0.43C477.82,2515.84,478.42,2515.14,479.02,2514.91z M478.29,2517.39c0.03,0,0.06,0.01,0.1,0.01
						c0.41,0.03,1.25-0.16,1.57,0.18c0.35,0.37,0.22,1.33,0.08,1.76c-0.19,0.59-0.59,1.09-1.05,1.5c-0.47,0.41-1,0.72-1.54,1.04
						c-0.55,0.32-1.1,0.65-1.65,0.97c-0.51,0.3-1.03,0.6-1.62,0.67c-0.47,0.05-0.94-0.06-1.4-0.17c-0.28-0.07-0.56-0.13-0.84-0.2
						c-0.08-0.02-0.16-0.04-0.23-0.09c-0.14-0.12-0.11-0.33-0.08-0.51c0.16-0.81,0.33-1.66,0.87-2.29c0.33-0.38,0.76-0.65,1.19-0.91
						c0.87-0.53,1.73-1.06,2.6-1.59c0.3-0.18,0.61-0.37,0.96-0.42C477.61,2517.27,477.95,2517.36,478.29,2517.39z M470.45,2519.05
						c1.97-1.99,4.29-3.32,7.16-3.8c0.04,0.61-0.23,0.87-0.46,1.07c-1.84,1.54-3.77,2.96-6.15,3.57c-0.28,0.07-0.64,0.2-0.83-0.07
						C469.97,2519.52,470.25,2519.25,470.45,2519.05z M465.14,2526.17c0.19-1.65,3.79-5.33,5.5-5.67c0.81-0.16,1.09-0.09,0.8,0.83
						c-0.39,1.24-0.93,2.32-1.98,3.14c-0.73,0.57-1.39,1.22-2.07,1.85c-0.39,0.36-0.81,0.6-1.37,0.53
						C465.59,2526.81,465.05,2526.93,465.14,2526.17z M466.31,2528.7c-0.13,2.7-1.78,4.57-3.34,6.5c-0.23,0.28-0.48,0.47-0.96,0.37
						c-0.82-0.01-1.31-0.16-1.12-1.4c0.36-2.39,1.62-4.2,3.1-5.95c0.28-0.33,0.59-0.59,1.02-0.7
						C466.15,2527.25,466.37,2527.54,466.31,2528.7z M466.71,2531.4c-0.09-0.03-0.21-0.2-0.2-0.29c0.21-1.47,0.03-3.07,1.14-4.29
						c0.62-0.69,1.38-1.25,2.01-1.93c0.83-0.9,1.73-1.28,3.01-1.08c0.44,0.07,1.05,0.13,1.37,0.49c0.47,0.54,0.1,1.71,0.05,2.36
						c-0.06,0.73-0.31,1.42-0.73,2.02c-0.83,1.17-2.12,1.91-3.25,2.75c-0.13,0.09-0.25,0.19-0.37,0.28
						C468.91,2532.37,467.71,2531.72,466.71,2531.4z M474.5,2538.33c-0.62,0.63-1.62-0.06-2.42-0.25c-2.01-0.48-1.88-0.51-2.05-2.59
						c-0.17-2.15,0.37-3.74,2.16-5.12c1.94-1.49,3.34-1.84,5.45-0.67c0.41,0.22,0.5,0.54,0.5,0.94c0.01,1.63,0.37,3.36-0.63,4.78
						C476.71,2536.58,475.48,2537.33,474.5,2538.33z M480.29,2535.28c-0.5-0.24-1.08-0.51-1.39-1c-0.26-0.41-0.21-0.89-0.21-1.35
						c-0.01-0.73-0.18-1.55,0-2.26c0.12-0.48,0.46-0.89,0.8-1.24c0.9-0.93,2.06-2.08,3.82-2.18c0.24-0.01,1.46,0.46,2.23,0.76
						c0.63,0.25,1.17,0.45,1.61,1.01c0.21,0.27,0.41,0.58,0.41,0.91c0,0.59-0.07,1.17-0.18,1.75c-0.09,0.54-0.21,1.08-0.42,1.59
						c-0.24,0.58-0.79,0.91-1.27,1.27c-0.62,0.47-1.26,0.93-2.01,1.15C482.53,2536.03,481.35,2535.79,480.29,2535.28z
						 M487.23,2541.16c-0.58-0.04-1.16,0.04-1.73,0c-0.32-0.02-0.67-0.08-0.91-0.3c-0.22-0.2-0.32-0.49-0.4-0.76
						c-0.16-0.54-0.3-1.09-0.4-1.64c-0.1-0.53-0.16-1.09,0.02-1.6c0.19-0.54,0.62-0.95,1.08-1.27c0.47-0.32,0.98-0.57,1.44-0.9
						c0.23-0.17,0.45-0.35,0.71-0.46c0.6-0.25,0.99,0.17,1.43,0.51c0.56,0.43,1.12,0.85,1.67,1.28c0.33,0.26,0.69,0.56,0.74,0.97
						c0.03,0.25-0.07,0.49-0.16,0.72c-0.24,0.6-0.48,1.2-0.71,1.8c-0.26,0.67-0.6,1.41-1.28,1.65c-0.47,0.17-0.99,0.05-1.49,0.01
						C487.24,2541.16,487.23,2541.16,487.23,2541.16z M492.75,2537.34c-0.34,1.96-0.64,3.66-0.94,5.36
						c-0.11,0.62-0.31,0.65-0.8,0.22c-1.02-0.9-1.55-1.79-0.66-3.12c0.49-0.73,0.75-1.6,1.13-2.4c0.18-0.38,0.52-0.56,0.94-0.5
						C492.84,2536.97,492.76,2537.31,492.75,2537.34z M494.85,2529.33c-0.56,2-1.08,4-1.63,6c-0.27,0.99-1.17,1.38-2.03,0.79
						c-1.01-0.69-1.96-1.48-2.92-2.24c-0.56-0.44-0.37-1.08-0.39-1.57c0.09-3.34,0.08-3.36,3.23-4.34c1.07-0.33,2.14-1.15,3.31-0.07
						C494.92,2528.36,495.02,2528.72,494.85,2529.33z M497.17,2522.8c-0.57,1.5-1.15,3-1.72,4.51c-0.11,0.28-0.28,0.47-0.57,0.26
						c-0.5-0.35-1.2-0.56-1.05-1.4c0.1-0.58,0.19-1.17,0.3-1.75c0.34-1.74,0.89-2.18,2.61-2.23
						C497.32,2522.17,497.31,2522.43,497.17,2522.8z M499.21,2518.84c-0.18,0.23-0.9,1.33-1.02,1.58c-0.93,1.89-1.83,1.39-3.79,1.61
						c-1.42-0.43-2.94-1.16-4.35-2.08c-0.68-0.44,0.31-3.18,1.2-3.4c1.14-0.29,2.31-0.51,3.46-0.76c0.52-0.11,1,0.02,1.47,0.25
						c0.87,0.43,1.73,0.88,2.63,1.26C499.65,2517.66,499.75,2518.14,499.21,2518.84z M502.15,2515.17c-2.33,2.38-2.31,2.39-5.63,0.4
						c-0.32-0.19-0.73-0.36-0.59-0.84c0.13-0.48,0.21-1.03,0.8-1.2c0.2-0.06,0.42-0.06,0.63-0.06c0.42-0.01,0.85,0,1.44,0
						c1.01-0.15,2.09,0.17,3.15,0.65C502.56,2514.39,502.71,2514.6,502.15,2515.17z"/>
				</g>
				<polygon className="st14" points="622.85,2536.51 610.69,2541.26 593.57,2545.1 589.16,2544.05 585.72,2528.44 581.19,2516.8 
					577.11,2511.51 581.03,2509.7 592.34,2508.7 601.38,2512.86 609.79,2514.99 615.93,2522.9 620.62,2529.23 				"/>
				<path className="st20" d="M610.39,2541.35l-17.12,3.85l-4.41-1.06l-3.44-15.6l-4.53-11.65l-3.2-4.07c-1.12-1.45,0.7-1.95,2.36-2.72
					l16.04,9.31"/>
				<g>
					<path className="st21" d="M597.5,2520.01c1.85-0.63,3.68-1.36,5.4,0.33c0.35,0.35,0.86,0.53,1.29,0.79
						c1.15,0.69,2.15,3.42,1.72,4.65c-0.09,0.26-0.26,0.44-0.52,0.51c-1.74,0.49-3.5,1.51-5.18-0.17c-0.17-0.17-0.42-0.29-0.65-0.4
						c-1.94-0.85-2.75-2.37-2.63-4.37C596.76,2520.72,596.78,2520.26,597.5,2520.01z"/>
					<path className="st21" d="M614.37,2524.81c-0.56,0.07-0.98-0.17-1.37-0.53c-0.68-0.63-1.34-1.28-2.07-1.85
						c-1.04-0.82-1.59-1.9-1.98-3.14c-0.29-0.92-0.01-0.99,0.8-0.83c1.71,0.34,5.32,4.02,5.5,5.67
						C615.35,2524.89,614.8,2524.77,614.37,2524.81z"/>
					<path className="st21" d="M600.91,2514.89c-0.08,0.02-0.17,0-0.25,0c-2.09-0.02-3.89-1-5.74-1.8c-0.26-0.11-0.42-0.51-0.59-0.79
						c-0.34-0.57-0.28-0.84,0.5-0.97c2.41-0.41,4.45,0.73,6.55,1.53c0.6,0.23,1.2,0.93,1.08,1.58
						C602.31,2515.17,601.45,2514.77,600.91,2514.89z"/>
					<path className="st21" d="M609.4,2517.84c-2.38-0.61-4.31-2.03-6.15-3.57c-0.24-0.2-0.5-0.46-0.46-1.07
						c2.87,0.48,5.19,1.81,7.16,3.8c0.2,0.2,0.48,0.48,0.28,0.77C610.04,2518.04,609.68,2517.91,609.4,2517.84z"/>
					<path className="st21" d="M603.72,2515.47c1.29,0.79,2.62,1.54,3.86,2.4c0.9,0.62,0.91,1.76,1.23,2.7c0.07,0.22-0.06,0.47-0.27,0.5
						c-0.99,0.14-2.02,0.68-2.96,0.21c-1.46-0.74-2.9-1.58-4.22-2.54c-0.94-0.68-1.18-1.94-1.05-2.97c0.08-0.63,1.18-0.21,1.81-0.38
						c0.2-0.05,0.42-0.06,0.62-0.09C603.09,2515.25,603.42,2515.29,603.72,2515.47z"/>
					<path className="st21" d="M615.39,2525.48c0.44,0.11,0.74,0.37,1.02,0.7c1.48,1.75,2.74,3.56,3.1,5.95c0.19,1.24-0.3,1.39-1.12,1.4
						c-0.48,0.1-0.73-0.08-0.96-0.37c-1.56-1.94-3.21-3.8-3.34-6.5C614.03,2525.49,614.25,2525.2,615.39,2525.48z"/>
					<path className="st21" d="M587.01,2510.3c-1.91,0.65-3.9,0.6-5.88,0.55c-0.36-0.01-0.84,0.03-0.88-0.49
						c-0.04-0.5,0.44-0.55,0.79-0.66c1.18-0.39,2.4-0.38,3.63-0.31c0.76,0,1.52-0.01,2.27,0.01c0.3,0.01,0.64,0.08,0.69,0.43
						C587.68,2510.23,587.27,2510.21,587.01,2510.3z"/>
					<path className="st21" d="M616.97,2522.1c-0.39,0.16-0.76,0.35-1.12,0.24l-0.6-0.92C615.61,2521.93,616.14,2522.25,616.97,2522.1z"
						/>
					<path className="st21" d="M590.89,2514.62c1.41-0.37,2.76-1.21,4.28-0.75c1.13,0.34,2.22,0.82,3.32,1.26
						c0.8,0.32,1.88,2.31,1.75,3.14c-0.04,0.24-0.17,0.39-0.38,0.45c-1.09,0.34-2.18,0.67-3.21,0.99c-1.42-0.28-2.64-0.9-3.91-1.35
						c-1.4-0.5-2.19-1.77-2.1-3.2C590.65,2514.94,590.69,2514.68,590.89,2514.62z"/>
					<path className="st21" d="M593.86,2512.71c0.1,0.43-0.33,0.5-0.63,0.64c-2.59,1.2-5.11,0.46-7.64-0.25
						c-0.6-0.17-0.65-0.72-0.73-1.22c-0.06-0.37,0.21-0.54,0.51-0.61c1.1-0.28,2.21-0.54,3.19-0.78c1.47,0.25,2.81,0.45,4.13,0.71
						C593.47,2511.36,593.7,2512.06,593.86,2512.71z"/>
					<path className="st21" d="M596.66,2523.57c0.01,0.25,0.11,0.56-0.13,0.71c-1.33,0.82-2.73,1.49-4.19,2.07
						c-1.61-0.54-3.17-1.05-4.73-1.59c-0.31-0.11-0.42-0.42-0.48-0.71c-0.19-0.95-0.37-1.91-0.54-2.87
						c-0.07-0.37,0.03-0.69,0.37-0.87c2.09-1.08,3.92-2.38,6.67-1.17C595.86,2520.12,596.59,2521.44,596.66,2523.57z"/>
					<path className="st21" d="M588.6,2509.66c2.49-0.36,4.94-0.5,7.19,0.86C593.28,2511.09,590.95,2510.25,588.6,2509.66z"/>
					<path className="st21" d="M581.6,2515.25c0.89-0.38,1.76-0.83,2.63-1.26c0.47-0.23,0.94-0.37,1.47-0.25
						c1.16,0.25,2.32,0.47,3.46,0.76c0.89,0.23,1.88,2.96,1.2,3.4c-1.4,0.92-2.93,1.65-4.35,2.08c-1.96-0.22-3.75-0.31-4.37-2.43
						c-0.08-0.28-0.26-0.53-0.44-0.76C580.65,2516.09,580.74,2515.61,581.6,2515.25z"/>
					<path className="st21" d="M578.46,2512.07c1.06-0.48,2.14-0.8,3.15-0.65c0.59,0,1.02-0.01,1.44,0c0.21,0,0.43,0,0.63,0.06
						c0.59,0.18,0.66,0.72,0.8,1.2c0.14,0.49-0.27,0.65-0.59,0.84c-3.32,1.99-3.31,1.98-5.63-0.4
						C577.69,2512.56,577.84,2512.35,578.46,2512.07z"/>
					<path className="st21" d="M606.35,2523.55c-0.53-1.35,0.2-1.6,1.38-1.78c1.28-0.2,2.18,0.18,3.01,1.08
						c0.63,0.68,1.39,1.24,2.01,1.93c1.1,1.22,0.92,2.81,1.14,4.29c0.01,0.09-0.11,0.26-0.2,0.29c-1,0.31-2.2,0.97-3.03,0.32
						C608.65,2528.09,605.88,2526.93,606.35,2523.55z"/>
					<path className="st21" d="M585.98,2525.86c1.18-1.08,2.25-0.26,3.31,0.07c3.14,0.98,3.14,1,3.23,4.34
						c-0.02,0.49,0.17,1.12-0.39,1.57c-0.97,0.76-1.91,1.55-2.92,2.24c-0.86,0.59-1.76,0.2-2.03-0.8c-0.55-2-1.06-4.01-1.63-6
						C585.37,2526.68,585.48,2526.32,585.98,2525.86z"/>
					<path className="st21" d="M583.65,2520.14c1.72,0.05,2.28,0.49,2.61,2.23c0.11,0.58,0.2,1.17,0.3,1.75
						c0.15,0.84-0.55,1.05-1.05,1.4c-0.29,0.2-0.46,0.01-0.57-0.26c-0.57-1.5-1.15-3-1.72-4.51
						C583.09,2520.38,583.08,2520.13,583.65,2520.14z"/>
					<path className="st21" d="M593.79,2531.78c-0.66-0.48-1.26-4.35-0.76-4.99c0.12-0.16,0.33-0.26,0.51-0.37
						c2.07-1.21,4-1.99,6.27-0.04c1.26,1.09,2.29,1.92,1.87,3.39c0,0.6-0.04,0.9,0.01,1.19c0.2,1.28-0.47,1.75-1.58,2.28
						c-2.14,1.02-3.85,0.63-5.48-0.88C594.38,2532.12,594.06,2531.98,593.79,2531.78z"/>
					<path className="st21" d="M602.25,2528.6c0-0.4,0.1-0.71,0.5-0.94c2.11-1.17,3.5-0.82,5.45,0.67c1.79,1.38,2.34,2.96,2.16,5.12
						c-0.17,2.07-0.03,2.1-2.05,2.59c-0.8,0.19-1.8,0.88-2.42,0.25c-1.64-1.67-4.19-2.87-3.65-5.79
						C602.25,2529.78,602.24,2529.19,602.25,2528.6z"/>
					<path className="st21" d="M590.07,2536.58c-0.82-1.84-0.84-1.86,0.79-3.07c2.61-1.95,1.59-1.83,4.36-0.16
						c1.33,0.8,1.75,1.65,1.35,3.19c-0.75,2.91-0.31,2.42-3.4,2.52c-1.54,0.59-2.45-0.34-2.89-2.02
						C590.24,2536.88,590.14,2536.73,590.07,2536.58z"/>
					<path className="st21" d="M587.98,2534.86c0.42-0.07,0.75,0.12,0.94,0.5c0.38,0.8,0.64,1.67,1.13,2.4
						c0.89,1.33,0.36,2.22-0.66,3.12c-0.49,0.43-0.69,0.41-0.8-0.22c-0.3-1.7-0.6-3.4-0.94-5.36
						C587.64,2535.26,587.56,2534.92,587.98,2534.86z"/>
					<path className="st8" d="M576.35,2511.25c0,0,1.85,2.39,2.05,2.81c1.81,3.93,2.4,2.71,3.88,5.9c2.77,5.96,4.21,12.32,5.41,18.74
						c0.28,1.5,0.52,3,0.78,4.5c0.05,0.27,0.03,0.6,0.69,0.85c-0.67-1.87,0.54-2.82,1.6-3.81c1.21-1.13,2.76-0.4,4.13-0.64
						c1.91-0.33,2.15,1.78,3.57,2.48c-0.1-0.93-0.76-1.33-1.17-1.85c-0.54-0.69-0.71-1.36-0.53-2.23c0.72-3.55,0.71-3.54,4.21-4.57
						c0.7-0.21,1.24-0.12,1.7,0.43c0.05,0.06,0.11,0.13,0.18,0.18c2.08,1.64,3.51,3.51,2.28,6.34c-0.06,0.13,0.01,0.31,0.02,0.65
						c0.86-0.77,0.7-1.73,0.85-2.52c0.18-0.97,0.6-1.45,1.56-1.71c2.98-0.82,2.97-0.86,4.91,1.56c0.32,0.4,0.65,0.78,0.99,1.16
						c0.08,0.08,0.21,0.11,0.47,0.24c-0.24-1.12-1.01-1.79-1.65-2.37c-2-1.82-1.28-4.13-1.34-6.31c0-0.15,0.1-0.33,0.22-0.44
						c0.82-0.77,3.27-0.55,3.95,0.35c0.46,0.61,0.85,1.28,1.37,1.83c0.76,0.8,1.01,1.71,0.95,2.78c-0.06,0.96-0.01,1.92-0.01,2.97
						c0.72-0.18,0.54-0.64,0.56-0.95c0.03-0.76,0.01-1.52,0.02-2.29c0.01-0.56,0.01-1.14,0.76-1.26c0.7-0.11,1.31-0.09,1.73,0.66
						c0.46,0.81,0.78,1.73,1.67,2.28c-1.09-2.28-2.49-4.44-2.47-7.34c2.09,1.98,2.53,4.44,3.16,6.85c-0.32-1.2-0.26-2.43-0.64-3.63
						c-0.4-1.27-1-2.47-1.59-3.65c-0.55-1.1-1.1,0.19-1.59-0.51c-1.13-1.6-2.61-2.98-3.27-4.9c-0.09-0.28-0.43-0.65,0.04-0.87
						c0.13-0.06-0.01-0.07,0.13-0.06l-0.67-0.03c-0.08-0.02,0.44-0.58,0.37-0.63c-1.83-1.24-3.3-2.84-4.84-4.68
						c0.25,0.49,6.95,10.49,7.69,10.87c9.04,8.39-7.73-12.67-8.68-13.45c-1.2-0.99-2.53-1.79-3.93-2.45
						c-2.73-1.29-5.73-2.05-8.59-2.82c-2.81-0.75-5.7-1.23-8.6-1.44c-2.79-0.2-5.14,0.05-7.83,0.78c-0.56,0.15-1.17,0.78-2.69,1.39
						C577.57,2510.69,576.35,2511.25,576.35,2511.25z M587.62,2509.82c0.05,0.41-0.35,0.39-0.61,0.48c-1.91,0.65-3.9,0.6-5.88,0.55
						c-0.36-0.01-0.84,0.03-0.88-0.49c-0.04-0.5,0.44-0.55,0.79-0.66c1.18-0.39,2.4-0.38,3.63-0.31c0.76,0,1.52-0.01,2.27,0.01
						C587.23,2509.4,587.58,2509.47,587.62,2509.82z M588.56,2510.5c1.47,0.25,2.81,0.45,4.13,0.71c0.78,0.16,1.02,0.85,1.17,1.51
						c0.1,0.43-0.33,0.5-0.63,0.64c-2.59,1.2-5.11,0.46-7.64-0.25c-0.6-0.17-0.65-0.72-0.73-1.22c-0.06-0.37,0.21-0.54,0.51-0.61
						C586.47,2510.99,587.57,2510.73,588.56,2510.5z M590.64,2515.16c0.02-0.23,0.05-0.49,0.25-0.54c1.41-0.37,2.76-1.21,4.28-0.75
						c1.13,0.34,2.22,0.82,3.32,1.26c0.8,0.32,1.88,2.31,1.75,3.14c-0.04,0.24-0.17,0.39-0.38,0.45c-1.09,0.34-2.18,0.67-3.21,0.99
						c-1.42-0.28-2.64-0.9-3.91-1.35C591.34,2517.87,590.54,2516.59,590.64,2515.16z M593.62,2519.14c2.24,0.98,2.97,2.3,3.03,4.43
						c0.01,0.25,0.11,0.56-0.13,0.71c-1.33,0.82-2.73,1.49-4.19,2.07c-1.61-0.54-3.17-1.05-4.73-1.59
						c-0.31-0.11-0.42-0.42-0.48-0.71c-0.19-0.95-0.37-1.91-0.54-2.87c-0.07-0.37,0.03-0.69,0.37-0.87
						C589.04,2519.23,590.87,2517.93,593.62,2519.14z M599.31,2525.6c-0.57-0.34-1.14-0.68-1.56-1.21c-0.32-0.42-0.55-0.9-0.68-1.41
						c-0.14-0.57-0.15-1.18-0.2-1.77c-0.04-0.54,0.06-1.01,0.62-1.2c1.11-0.38,2.29-0.85,3.48-0.68c0.68,0.1,1.2,0.45,1.75,0.84
						c0.59,0.42,1.24,0.74,1.79,1.21c0.39,0.34,0.67,0.85,0.89,1.32c0.41,0.88,0.84,2.12,0.5,3.09c-0.09,0.26-0.26,0.44-0.52,0.51
						c-1.41,0.4-2.96,1.21-4.4,0.43c-0.5-0.27-0.95-0.65-1.44-0.97C599.47,2525.7,599.39,2525.65,599.31,2525.6z M595.79,2510.51
						c-2.5,0.58-4.84-0.26-7.19-0.86C591.09,2509.3,593.54,2509.16,595.79,2510.51z M602.45,2514.44c-0.11,0.6-0.83,0.43-1.26,0.43
						c-0.75,0-1.46-0.03-2.2-0.2c-1.42-0.34-2.74-1-4.07-1.58c-0.26-0.11-0.42-0.51-0.59-0.79c-0.34-0.57-0.28-0.84,0.5-0.97
						c2.41-0.41,4.45,0.73,6.55,1.53C601.98,2513.09,602.58,2513.8,602.45,2514.44z M603.13,2515.27c0.35,0.05,0.66,0.24,0.96,0.42
						c0.87,0.53,1.73,1.06,2.6,1.59c0.43,0.26,0.87,0.53,1.19,0.91c0.54,0.63,0.71,1.48,0.87,2.29c0.03,0.18,0.06,0.39-0.08,0.51
						c-0.06,0.05-0.15,0.07-0.23,0.09c-0.28,0.07-0.56,0.13-0.84,0.2c-0.46,0.11-0.93,0.22-1.4,0.17c-0.58-0.06-1.11-0.37-1.62-0.67
						c-0.55-0.32-1.1-0.65-1.65-0.97c-0.53-0.31-1.07-0.63-1.54-1.04s-0.87-0.91-1.05-1.5c-0.14-0.43-0.26-1.39,0.08-1.76
						c0.32-0.34,1.15-0.15,1.57-0.18c0.03,0,0.06,0,0.1-0.01C602.45,2515.31,602.79,2515.23,603.13,2515.27z M610.23,2517.77
						c-0.19,0.27-0.55,0.14-0.83,0.07c-2.38-0.61-4.31-2.03-6.15-3.57c-0.24-0.2-0.5-0.46-0.46-1.07c2.87,0.48,5.19,1.81,7.16,3.8
						C610.15,2517.21,610.43,2517.48,610.23,2517.77z M614.37,2524.81c-0.56,0.07-0.98-0.17-1.37-0.53
						c-0.68-0.63-1.34-1.28-2.07-1.85c-1.04-0.82-1.59-1.9-1.98-3.14c-0.29-0.92-0.01-0.99,0.8-0.83c1.71,0.34,5.32,4.02,5.5,5.67
						C615.35,2524.89,614.8,2524.77,614.37,2524.81z M615.39,2525.48c0.44,0.11,0.74,0.37,1.02,0.7c1.48,1.75,2.74,3.56,3.1,5.95
						c0.19,1.24-0.3,1.39-1.12,1.4c-0.48,0.1-0.73-0.08-0.96-0.37c-1.56-1.94-3.21-3.8-3.34-6.5
						C614.03,2525.49,614.25,2525.2,615.39,2525.48z M610.66,2529.67c-0.12-0.1-0.25-0.19-0.37-0.28c-1.13-0.84-2.42-1.57-3.25-2.75
						c-0.42-0.6-0.67-1.29-0.73-2.02c-0.05-0.64-0.42-1.82,0.05-2.36c0.32-0.36,0.93-0.42,1.37-0.49c1.28-0.2,2.18,0.18,3.01,1.08
						c0.63,0.68,1.39,1.24,2.01,1.93c1.1,1.22,0.92,2.81,1.14,4.29c0.01,0.09-0.11,0.26-0.2,0.29
						C612.69,2529.67,611.49,2530.33,610.66,2529.67z M602.88,2533.38c-1-1.42-0.64-3.15-0.63-4.78c0-0.4,0.1-0.71,0.5-0.94
						c2.11-1.17,3.5-0.82,5.45,0.67c1.79,1.38,2.34,2.96,2.16,5.12c-0.17,2.07-0.03,2.1-2.05,2.59c-0.8,0.19-1.8,0.88-2.42,0.25
						C604.92,2535.29,603.69,2534.53,602.88,2533.38z M596.73,2533.63c-0.75-0.23-1.4-0.68-2.01-1.15
						c-0.48-0.36-1.03-0.69-1.27-1.27c-0.21-0.51-0.32-1.05-0.42-1.59c-0.1-0.58-0.17-1.16-0.18-1.75c0-0.32,0.2-0.63,0.41-0.91
						c0.43-0.55,0.97-0.75,1.61-1c0.77-0.31,1.99-0.78,2.23-0.76c1.76,0.1,2.92,1.25,3.82,2.18c0.34,0.35,0.67,0.76,0.8,1.24
						c0.18,0.71,0.01,1.53,0,2.26c-0.01,0.46,0.04,0.94-0.21,1.35c-0.31,0.49-0.88,0.76-1.39,1
						C599.05,2533.74,597.87,2533.98,596.73,2533.63z M593.15,2539.11c-0.5,0.04-1.02,0.16-1.49-0.01
						c-0.67-0.24-1.01-0.98-1.28-1.65c-0.24-0.6-0.48-1.2-0.71-1.8c-0.09-0.23-0.18-0.47-0.16-0.72c0.04-0.42,0.4-0.72,0.74-0.97
						c0.56-0.43,1.12-0.85,1.67-1.28c0.44-0.34,0.83-0.76,1.43-0.51c0.26,0.11,0.48,0.3,0.71,0.46c0.46,0.33,0.98,0.58,1.44,0.9
						c0.47,0.32,0.9,0.74,1.08,1.27c0.18,0.51,0.11,1.07,0.02,1.6c-0.1,0.55-0.23,1.1-0.4,1.64c-0.08,0.28-0.19,0.57-0.4,0.76
						c-0.24,0.22-0.58,0.28-0.91,0.3c-0.58,0.04-1.16-0.04-1.73,0C593.17,2539.11,593.16,2539.11,593.15,2539.11z M587.98,2534.86
						c0.42-0.07,0.75,0.12,0.94,0.5c0.38,0.8,0.64,1.67,1.13,2.4c0.89,1.33,0.36,2.22-0.66,3.12c-0.49,0.43-0.69,0.41-0.8-0.22
						c-0.3-1.7-0.6-3.4-0.94-5.36C587.64,2535.26,587.56,2534.92,587.98,2534.86z M585.98,2525.86c1.18-1.08,2.25-0.26,3.31,0.07
						c3.14,0.98,3.14,1,3.23,4.34c-0.02,0.49,0.17,1.12-0.39,1.57c-0.97,0.76-1.91,1.55-2.92,2.24c-0.86,0.59-1.76,0.2-2.03-0.8
						c-0.55-2-1.06-4.01-1.63-6C585.37,2526.68,585.48,2526.32,585.98,2525.86z M583.65,2520.14c1.72,0.05,2.28,0.49,2.61,2.23
						c0.11,0.58,0.2,1.17,0.3,1.75c0.15,0.84-0.55,1.05-1.05,1.4c-0.29,0.2-0.46,0.01-0.57-0.26c-0.57-1.5-1.15-3-1.72-4.51
						C583.09,2520.38,583.08,2520.13,583.65,2520.14z M581.6,2515.25c0.89-0.38,1.76-0.83,2.63-1.26c0.47-0.23,0.94-0.37,1.47-0.25
						c1.16,0.25,2.32,0.47,3.46,0.76c0.89,0.23,1.88,2.96,1.2,3.4c-1.4,0.92-2.93,1.65-4.35,2.08c-1.96-0.22-2.86,0.28-3.79-1.61
						c-0.13-0.26-0.85-1.35-1.02-1.58C580.65,2516.09,580.74,2515.61,581.6,2515.25z M578.46,2512.07c1.06-0.48,2.14-0.8,3.15-0.65
						c0.59,0,1.02-0.01,1.44,0c0.21,0,0.43,0,0.63,0.06c0.59,0.18,0.66,0.72,0.8,1.2c0.14,0.49-0.27,0.65-0.59,0.84
						c-3.32,1.99-3.31,1.98-5.63-0.4C577.69,2512.56,577.84,2512.35,578.46,2512.07z"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st6" d="M447.78,2672.8c0.86,0.34,1.73,0.69,2.58,1.04c-1.19-0.5-2.39-1.01-3.58-1.51c2.34,0.99,4.78,2,6.82,3.53
						c-1.01-0.78-2.02-1.56-3.04-2.35c0.35,0.27,0.67,0.56,0.95,0.9c-0.78-1.01-1.56-2.02-2.35-3.04c0.1,0.14,0.19,0.28,0.27,0.44
						c-0.5-1.19-1.01-2.39-1.51-3.58c0.04,0.11,0.07,0.21,0.09,0.32c-0.18-1.33-0.36-2.66-0.54-3.99c0.01,0.08,0.01,0.15,0,0.23
						c0.18-1.33,0.36-2.66,0.54-3.99c-0.02,0.1-0.04,0.19-0.08,0.28c0.5-1.19,1.01-2.39,1.51-3.58c-0.04,0.07-0.07,0.13-0.12,0.2
						c0.78-1.01,1.56-2.02,2.35-3.04c-0.11,0.14-0.23,0.26-0.37,0.37c1.01-0.78,2.02-1.56,3.04-2.35c-0.3,0.22-0.62,0.38-0.96,0.53
						c1.19-0.5,2.39-1.01,3.58-1.51c-2.24,0.91-4.71,1.17-7.05,1.72c-2.25,0.53-4.57,1.34-6.5,2.59c-3.43,2.23-5.4,4.51-7.8,7.75
						c-0.88,1.19-1.66,2.44-2.55,3.62c0.78-1.01,1.56-2.02,2.35-3.04c-0.68,0.86-1.42,1.62-2.28,2.3c1.01-0.78,2.02-1.56,3.04-2.35
						c-0.85,0.62-1.75,1.13-2.71,1.56c1.19-0.5,2.39-1.01,3.58-1.51c-2.12,0.88-4.37,1.32-6.63,1.62c1.33-0.18,2.66-0.36,3.99-0.54
						c-4.55,0.59-9.11,1.18-13.65,1.82c1.33-0.18,2.66-0.36,3.99-0.54c-2.03,0.26-4.15,0.07-6.18-0.08
						c-3.37-0.25-6.73-0.63-10.08-1.04c-2.34-0.29-4.69-0.59-7.03-0.91c1.33,0.18,2.66,0.36,3.99,0.54
						c-9.54-1.28-19.07-2.72-28.6-4.09c-9.27-1.33-18.57-2.76-27.9-3.61c-1.41-0.13-2.82-0.24-4.23-0.28
						c-2.25-0.07-4.34,0.3-6.6,0.62c1.33-0.18,2.66-0.36,3.99-0.54c-0.95,0.12-1.94,0-2.88-0.13c1.33,0.18,2.66,0.36,3.99,0.54
						c-3.82-0.52-7.57-1.54-11.27-2.58c-4.84-1.37-9.63-2.94-14.36-4.66c-2.62-0.95-5.21-1.96-7.78-3.04
						c1.19,0.5,2.39,1.01,3.58,1.51c-3.77-1.59-7.76-3.25-11.02-5.76c1.01,0.78,2.02,1.56,3.04,2.35c-0.49-0.39-0.97-0.8-1.35-1.29
						c0.78,1.01,1.56,2.02,2.35,3.04c-0.13-0.18-0.25-0.36-0.34-0.56c0.5,1.19,1.01,2.39,1.51,3.58c-0.06-0.14-0.1-0.27-0.12-0.42
						c0.18,1.33,0.36,2.66,0.54,3.99c-0.01-0.1-0.01-0.2,0-0.3c-0.18,1.33-0.36,2.66-0.54,3.99c0.01-0.08,0.04-0.16,0.07-0.23
						c-0.5,1.19-1.01,2.39-1.51,3.58c0.04-0.09,0.09-0.18,0.16-0.26c-0.78,1.01-1.56,2.02-2.35,3.04c0.09-0.11,0.19-0.2,0.29-0.28
						c-1.01,0.78-2.02,1.56-3.04,2.35c0.22-0.16,0.46-0.29,0.71-0.39c-1.19,0.5-2.39,1.01-3.58,1.51c0.77-0.31,1.59-0.46,2.41-0.57
						c-1.33,0.18-2.66,0.36-3.99,0.54c2.86-0.38,5.78-0.3,8.66-0.18c3.74,0.16,8-1.78,10.61-4.39c2.6-2.6,4.56-6.87,4.39-10.61
						c-0.17-3.88-1.45-7.9-4.39-10.61c-3-2.76-6.49-4.22-10.61-4.39c-8.81-0.37-20.22,0.04-25.06,8.9
						c-3.79,6.93-2.4,14.56,2.86,20.2c1.92,2.06,4.42,3.72,6.87,5.13c5.74,3.3,12.06,5.7,18.25,8.01
						c5.73,2.14,11.56,4.05,17.47,5.66c2.62,0.71,5.27,1.38,7.95,1.86c2.22,0.39,4.46,0.73,6.7,0.91c2.01,0.17,3.86-0.25,5.91-0.55
						c-1.33,0.18-2.66,0.36-3.99,0.54c2.36-0.31,4.88,0.04,7.23,0.26c4.17,0.4,8.33,0.94,12.49,1.5c-1.33-0.18-2.66-0.36-3.99-0.54
						c10.24,1.38,20.45,2.93,30.68,4.39c9.99,1.42,20.02,3,30.09,3.72c1.46,0.11,2.93,0.19,4.4,0.17c3.01-0.05,5.95-0.79,8.92-1.19
						c-1.33,0.18-2.66,0.36-3.99,0.54c2.78-0.36,5.56-0.62,8.34-0.99c2.69-0.36,5.42-0.66,7.98-1.63c4.25-1.61,7.75-3.38,11.05-6.61
						c2.41-2.35,4.09-5.34,6.09-8.03c-0.78,1.01-1.56,2.02-2.35,3.04c0.59-0.76,1.22-1.46,1.98-2.06c-1.01,0.78-2.02,1.56-3.04,2.35
						c0.4-0.29,0.81-0.56,1.27-0.76c-1.19,0.5-2.39,1.01-3.58,1.51c2.7-1.12,5.75-1.17,8.51-2.12c1.73-0.59,3.48-1.23,5.04-2.18
						c3.61-2.21,6.36-5.58,7.53-9.68c0.36-1.28,0.6-3.1,0.58-4.43c-0.04-2.69-0.77-5.72-2.2-8.02c-1.89-3.04-3.7-4.52-6.53-6.59
						c-1.07-0.78-2.22-1.41-3.4-1.99c-3.11-1.53-6.35-2.83-9.58-4.09c-1.99-0.62-3.99-0.71-5.98-0.27c-2,0.09-3.86,0.68-5.58,1.78
						c-3.11,1.82-6.08,5.39-6.89,8.96c-0.87,3.84-0.69,8.15,1.51,11.56c0.78,1.01,1.56,2.02,2.35,3.04
						C443.04,2670.8,445.24,2672.09,447.78,2672.8L447.78,2672.8z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path d="M373.48,2678.88c-0.23-0.56-0.57-1.08-0.76-1.65c-0.27-0.79-0.83-1.73,0.75-1.84c0.41-0.03,0.64-0.5,0.87-0.85
							c1.52-2.34,3.02-4.7,5.24-6.46c1.78-0.04,3.07-1.25,4.6-1.89c1.13-0.48,1.17-1.11,0.36-1.83c-1.36-1.22-1.63-2.7-1.27-4.39
							c0.34-1.59,1.22-2.9,2.67-3.53c1.87-0.82,3.9-0.83,5.91-0.42c3.1,0.63,5.35,2.77,7.89,4.4c0.63,0.41,1.22,0.87,1.98,0.98
							c0.25,0.04,0.6,0.11,0.73-0.22c0.1-0.25-0.11-0.44-0.28-0.59c-0.95-0.82-1.9-1.65-2.87-2.45c-0.93-0.77-1.89-1.5-2.84-2.25
							c0.08-0.18,0.07-0.15,0.15-0.33c2.74,0.6,5.29,1.73,7.77,3.03c1.57,0.82,3.1,1.74,4.88,2c0.57,0.08,1.62,0.06,1.39-0.66
							c-0.5-1.53-0.59-3.24-1.97-4.48c-0.97-0.88-1.88-0.48-2.86-0.45c-0.42,0.01-0.94,0.06-1.12-0.39c-0.2-0.5,0.31-0.7,0.63-0.96
							c0.62-0.49,1.26-0.97,1.87-1.46c0.55-0.45,0.52-1.07,0.44-1.68c-0.09-0.67-0.64-0.58-1.09-0.57
							c-0.83,0.01-0.89-0.51-0.82-1.13c0.12-1.12-0.16-2.03-1.25-2.56c-0.57-0.28-0.72-0.79-0.74-1.4
							c-0.02-0.72,0.15-1.39,0.36-2.06c0.08-0.26,0.25-0.54,0-0.78c-0.25-0.24-0.53-0.06-0.78,0.04c-1.17,0.47-2.33,0.97-3.52,1.39
							c-0.67,0.24-0.99,0.71-1.07,1.34c-0.2,1.48-1.09,2.67-1.7,3.97c-0.33,0.7-0.68,0.93-1.25,0.05c-1.71-2.67-3.51-5.28-5.29-7.9
							c-0.17-0.25-0.41-0.95-0.73-0.42c-0.96,1.6-1.96,0.39-2.91,0.15c-0.76-0.19-1.35-0.49-0.28-1.3c0.88-0.67,0.85-2.52,0.14-3.02
							c-1.2-0.85-4.22-0.61-5.4,0.4c-0.75,0.64-1.48,1.09-2.05-0.24c-0.17-0.41-0.57-0.73-0.9-1.06c-0.21-0.21-0.41-0.61-0.79-0.4
							c-0.34,0.19-0.21,0.59-0.19,0.9c0.04,0.56,0.18,1.1,0.22,1.66c0.02,0.34,0.22,0.81-0.24,0.99c-0.36,0.15-0.57-0.25-0.78-0.48
							c-0.47-0.52-0.87-1.1-1.35-1.6c-0.52-0.55-0.79-1.78-1.63-1.47c-0.84,0.31-0.79,1.52-0.74,2.37c0.09,1.57-0.53,2.77-1.57,3.88
							c-1.7,1.83-1.69,1.87-2.47-0.47c-0.62-1.84-1.36-3.54-3.5-4.16c-0.82-0.24-1.62-0.53-2.39-0.91
							c-0.75-0.38-1.43-0.58-1.77,0.24c-0.37,0.9-0.92,0.74-1.55,0.31c-1.46-1-3-1.9-4.38-3c-1.12-0.89,0.56,3.06-0.21,4.12
							c-0.27-0.05-3.51-0.67-3.64-0.83c-0.93-1.12-1.46-1.52-2.71-2.26c-0.14-0.08-2.32-1.79-2.32-1.95
							c-0.01-1.79-0.11-3.4-1.29-3.85c-0.47-0.39-0.54-1.57-1.06-1.26c-0.5,0.3-0.07,1.03-0.06,1.57c0.01,0.92,0.49,1.69,0.97,2.44
							c0.31,0.48,0.61,0.99,0.59,1.55c-0.05,1.42,0.56,2.62,1.13,3.83c0.52,1.11,2.13,1.91,2.34,3.1c0.06,0.12,0.67,0.26,0.74,0.38
							c-0.07,0.19,1.4,4.8,1.33,5c-0.95,0.4-2.37,3.29-3.32,3.31c-2.71-0.51-3.3-6.47-4.33-7.05c-0.78-0.43-1.04,0.58,0,0
							c0.31-0.17,0.1,0.34,0,0c-0.62-2.13,0.96,1.99,0,0c-0.02-0.04,3.67,11.82-0.44-0.47c-0.08-0.25,0.44,0.27,0.44,0.47
							c-0.02,1.62,0.89-1.51,0,0c-0.38,0.65-1.46-0.77-1.06-0.19c0.42,0.61,0.53,0.22,1.01,0.79c1.55,1.84,0.82,2.44,0.88,4.58
							c0.04,1.31-1.4,1.52-2.21,2.24c-0.85,0.16-3.26,0.66-3.98,1.08c-2.55-0.05-5.38-1.03-7.68-1.96
							c-0.41-0.16-0.92-0.72-1.11-1.11c-0.57-1.19-1.21-0.91-2.35-0.83c-1.02,0.07-2.31-0.69-3.09-1.52
							c-1.1-1.16-1.16-2.22-1.96-3.47c-0.18-0.29-0.12-0.91-0.07-1.23c0.08-0.53,0.47-0.75,0.99-0.61c0.81,0.22,1.61,0.52,2.43,0.65
							c1.35,0.21,1.59-0.6,2.4-2.18c0.37-0.73,1.81,0.88,2.02,1.19c0.91,1.35,2.23,1.27,3.11,2.64c0.29,0.45,1.08,0.83,1.66,0.74
							c1.06-0.17,1.32,0.65,2.02,1.33c0.5,0.47,0.65,2.05,1.25,2.4c0.31,0.18,0.68,0.45,1.03,0.15c0.29-0.26,0.06-0.61,0.02-0.92
							c-0.24-2.05-1.3-3.79-2.27-5.54c-0.45-0.81-0.73-1.59-0.62-2.51c0.15-1.19-0.43-1.95-1.48-2.42
							c-0.86-0.39-1.85-0.84-2.19-1.67c-0.6-1.47-1.82-1.87-3.09-2.32c-0.28-0.1-0.68-0.09-0.68-0.58
							c3.85-2.68,8.02-4.45,12.79-4.66c0.92-0.04,1.91,0.23,2.77-0.36c0.1-0.04,0.2-0.09,0.3-0.13c0,0,0.07,0.03,0.07,0.03
							c0.22,0.33,0.6,0.41,0.92,0.58c1.78,0.92,3.77,0.36,5.6,0.84c0.55,0.03,1.13-0.03,1.66,0.11c1.57,0.42,3.13,0.92,4.69,1.38
							c1.5,0.08,2.99,0.01,4.45-0.37c0.26-0.03,0.53-0.07,0.79-0.1c0.09-0.03,0.24-0.08,0.33-0.11l0.11-0.06
							c1.02,0.05,2.13,0.11,3.15,0.16c0.63,0.42,1.31,0.71,2.09,0.79c0.69,0.47,1.47,0.71,2.25,0.96c0.97,0.58,1.99,1.03,3.09,1.29
							c1.04,0.63,2.12,1.69,2.82-0.4c0.12-0.36,0.67-0.57,1.1-0.44c0.57,0.17,1.15,0.41,1.66,0.73c0.9,0.58,1.65,0.56,2.54-0.09
							c1.54-1.13,3.21-2.07,5.08-2.55c1.28-0.33,2.4-0.37,3.21,1.1c0.98,1.78,3.95,2.21,5.76,1.01c1.42-0.75,2.47-2.13,4.14-2.45
							c2.18,1.09,3.94,2.77,5.95,4.1c-0.68,1.59-0.63,2.98-1.94,2.13c-0.77-0.5-1.55-1.44-2.22-1.21c-0.05,0.03-0.09,0.06-0.13,0.08
							l-0.18,0.16c-0.1,0.08-0.19,0.16-0.29,0.25c-0.09,0.08-0.18,0.16-0.27,0.24c-0.32,0.01-0.63,0.04-0.82,0.34l-0.01,0.01
							c-0.46,0.08-0.98,0.14-1.05,0.72c-0.05,0.46,0.37,0.58,0.73,0.7c0.77,0.41,1.52,0.86,2.32,1.23c1.01,0.46,1.9,1.21,3.07,1.27
							c2.34,0.11,4.98,2.71,5.05,5.03c0.02,0.55,0.17,0.83,0.61,1.23c2,1.8,4.11,3.51,5.52,5.87c0.32,0.53,3.03,1.84,2.56,2.45
							c-0.53,0.68-0.46,1.66-1.05,1.47c-0.22-0.07,0.2-0.21,0,0c-0.02,0.82-0.74,0.79-0.53,1.52c-0.51,0.36-0.27,0.8-0.66,0.76
							c-0.61-0.31,0.62,0.29,0,0c-0.33-0.15,0.4,0.44,0.55,0.89c0.25,0.72-1.02-0.37,0,0l-0.55-0.89c0.15,0.74,6.2-2.55,6.71-2.19
							c2.43,1.74-4.72,4.63-1.81,3.52c0.15-0.03,0.3-0.06,0.45-0.09c0.46-0.03,0.92-0.26,1.36,0.06c0.46,0.75,0.73,1.81,1.4,2.2
							c1.76,1.03,3.37,2.3,5.26,3.14c2.03,0.9,4.13,0.01,6.17,0.5c0.74,0.18,0.54-0.39,0.22-0.76c-0.89-1.02-1.82-2-2.68-3.05
							c-0.3-0.37-0.88-0.82-0.54-1.31c0.3-0.45,0.98-0.23,1.45-0.13c1.25,0.28,2.48,0.65,4.06,1.07c-1.09-1.84-5.31-1.1-7.35-1.42
							c-2.99-0.47-1.85,0.18-4.86-0.2c-0.57-0.07-1.71-3.7-1.68-4.38c0.07-1.37-0.89-2.08-1.77-2.85c-0.36-0.31-0.88-0.48-0.91-1.11
							c0.71-0.13,1.1,0.34,1.57,0.6c1.57,0.85,3.17,1.66,5.02,1.45c0.73-0.08,1.44-0.27,0.6-1.29c-0.89-1.08-0.69-1.56,0.61-2.02
							c0.5-0.18,1.01-0.39,1.27-0.82c0.68-1.13,1.7-1.88,2.58-1.25c0.38,0.27,0.85,0.41,1.27,0.63c0.48,0.87,1.97-0.54,2.69,0.03
							c0.24,0.19,0.37,0.86,0.47,0.56c0.26-0.82,0.49,0.31-0.47-0.56c-0.11-0.77,0.37-1.32,0.7-1.93c0.15-0.26,0.52-0.65,0.01-0.78
							c-1.57-0.38-2.21,1.6-3.56,0.88c-0.87-0.46-1-0.68-1.45,0.42c-0.56,1.39-4.83,1.79-6.3,1.36c-1.94-0.56-1.82-3.44-3.31-4.72
							c-1.68-1.45-0.56-3.61,1.5-4.51c0.68-0.3,1.39-0.51,2.15-0.57c0.62-0.04-0.73-0.68-0.12-0.78c-0.09-0.45,1.61,0.03,1.42-0.09
							c-1.87-1.1-3.96-1.87-5.49-3.49c1.18-0.25,2.23,0.43,3.39,0.49c0.92,0.05,1.9,0.35,2.6,1.11c0.08,0.09,0.16,0.18,0.24,0.27
							l0.59,0.56c0.16,1.6,2.51,1.27,4.05,1.45c2.56,0.29,3.55,0.22,6.09,0.47c0,0,0,0.01,0,0.01c-0.01,0.79,0.08,1.6-0.04,2.37
							c-0.16,1.11,0.43,1.8,1.31,1.92c1.29,0.17,2.4,0.81,3.74,0.8c-0.02,0,0.72,0.66,0.87,0.72c0.58,0.25,1.22,0.42,1.81,0.63
							c1.25,0.44,2.5,0.87,3.75,1.3c2.47,0.85,3.13,1.53,4.31,2.04l1.66,1.69l0,0c0,0,0.34,0.09,0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
							l0,0l0,0l0,0l0,0c0,0,4.6,2.38,3.43,3.99c-0.25,0.34-0.5,0.7-0.61,1.11c-0.11,0.41-0.05,0.89,0.26,1.18
							c0.28,0.26,0.73,0.34,0.94,0.65c0.19,0.27,0.13,0.68-0.12,0.89l0,0l-0.1-0.01l0,0l0,0l0,0l0,0c0,0,1.42,1.79,1.58,2.27
							c0.25,0.76-0.19,1.57-0.61,2.25c0.42,0.21,0.85,0.43,1.27,0.64c0.18,0.09,0.38,0.22,0.38,0.41c0,0.13-0.09,0.25-0.18,0.35
							l4.4,2.02l2.64,2.2l0,0c0,0,0.89,0.13,1.28,0.29c0.42,0.17,0.28,0.25,0.54,0.5c0.38,0.37,0.91,0.48,1.44,0.61
							C475.34,2667.34,428.79,2691.01,373.48,2678.88"/>
						<path d="M405.8,2682.41c-10.08,0-21-0.95-32.34-3.44l-0.04-0.01l-0.02-0.04c-0.1-0.24-0.22-0.48-0.34-0.71
							c-0.15-0.3-0.31-0.61-0.42-0.94c-0.03-0.09-0.06-0.18-0.1-0.27c-0.17-0.45-0.34-0.91-0.12-1.25c0.16-0.25,0.5-0.38,1.04-0.42
							c0.31-0.02,0.52-0.35,0.72-0.67c0.03-0.05,0.06-0.1,0.09-0.15l0.1-0.16c1.46-2.25,2.97-4.59,5.15-6.32l0.02-0.02l0.03,0
							c1.18-0.03,2.16-0.59,3.11-1.13c0.47-0.27,0.95-0.54,1.46-0.76c0.52-0.22,0.81-0.47,0.86-0.75c0.05-0.27-0.13-0.59-0.52-0.94
							c-1.27-1.14-1.69-2.6-1.29-4.47c0.37-1.71,1.33-2.99,2.72-3.59c1.71-0.75,3.66-0.89,5.96-0.42c2.33,0.47,4.21,1.81,6.03,3.11
							c0.61,0.44,1.25,0.89,1.88,1.3c0.1,0.06,0.19,0.13,0.29,0.19c0.53,0.35,1.04,0.68,1.66,0.78l0.04,0.01
							c0.29,0.05,0.5,0.06,0.6-0.18c0.08-0.19-0.07-0.34-0.25-0.5l-0.09-0.07c-0.91-0.79-1.84-1.6-2.78-2.37
							c-0.65-0.53-1.32-1.06-1.97-1.57c-0.29-0.23-0.58-0.45-0.87-0.68l-0.05-0.04l0.21-0.45l0.06,0.01
							c3.02,0.66,5.75,1.97,7.79,3.03c0.22,0.11,0.43,0.23,0.65,0.34c1.32,0.7,2.68,1.43,4.21,1.65c0.47,0.07,1.06,0.04,1.26-0.19
							c0.08-0.09,0.09-0.21,0.04-0.36c-0.12-0.38-0.22-0.77-0.32-1.15c-0.3-1.17-0.6-2.37-1.62-3.29c-0.72-0.66-1.41-0.57-2.14-0.49
							c-0.21,0.03-0.44,0.05-0.66,0.06l-0.07,0c-0.42,0.02-0.94,0.03-1.13-0.44c-0.19-0.47,0.18-0.72,0.47-0.92
							c0.07-0.04,0.13-0.09,0.19-0.13c0.2-0.16,0.4-0.31,0.6-0.47c0.42-0.32,0.85-0.66,1.27-0.99c0.52-0.42,0.49-1.02,0.41-1.6
							c-0.07-0.53-0.46-0.52-0.88-0.51c-0.04,0-0.09,0-0.13,0c-0.34,0-0.58-0.08-0.73-0.24c-0.22-0.24-0.21-0.63-0.17-0.97
							c0.14-1.22-0.25-2.01-1.21-2.48c-0.67-0.33-0.77-0.96-0.78-1.47c-0.02-0.78,0.17-1.49,0.36-2.09
							c0.01-0.04,0.03-0.09,0.05-0.13c0.09-0.25,0.14-0.41-0.02-0.56c-0.16-0.15-0.33-0.1-0.58,0.01c-0.04,0.02-0.08,0.03-0.11,0.05
							c-0.37,0.15-0.74,0.3-1.12,0.45c-0.78,0.32-1.59,0.65-2.4,0.94c-0.6,0.21-0.93,0.63-1.02,1.27c-0.15,1.07-0.65,2-1.13,2.9
							c-0.19,0.36-0.39,0.73-0.57,1.1c-0.21,0.45-0.41,0.66-0.64,0.67c-0.23,0.02-0.48-0.18-0.76-0.61
							c-1.36-2.11-2.79-4.23-4.18-6.27c-0.37-0.54-0.74-1.09-1.11-1.63c-0.03-0.05-0.07-0.11-0.11-0.18
							c-0.09-0.16-0.22-0.39-0.32-0.39c0,0,0,0,0,0c-0.05,0-0.1,0.05-0.16,0.15c-0.75,1.26-1.57,0.85-2.29,0.49
							c-0.25-0.12-0.48-0.24-0.71-0.3c-0.42-0.11-0.85-0.26-0.92-0.57c-0.05-0.25,0.15-0.53,0.6-0.88c0.48-0.37,0.64-1.08,0.63-1.62
							c-0.01-0.58-0.2-1.06-0.49-1.27c-1.16-0.82-4.13-0.6-5.3,0.4c-0.52,0.44-0.95,0.72-1.37,0.62c-0.32-0.08-0.59-0.37-0.82-0.9
							c-0.13-0.31-0.41-0.58-0.68-0.84c-0.07-0.06-0.13-0.13-0.2-0.19c-0.04-0.04-0.08-0.09-0.13-0.14
							c-0.2-0.24-0.34-0.37-0.57-0.24c-0.22,0.12-0.2,0.34-0.16,0.65c0.01,0.06,0.01,0.11,0.02,0.17c0.02,0.27,0.07,0.54,0.11,0.8
							c0.05,0.28,0.09,0.57,0.11,0.86c0,0.07,0.02,0.14,0.03,0.21c0.05,0.31,0.11,0.69-0.31,0.86c-0.35,0.14-0.58-0.14-0.76-0.36
							c-0.04-0.05-0.07-0.09-0.11-0.13c-0.21-0.24-0.42-0.49-0.61-0.73c-0.23-0.29-0.48-0.59-0.74-0.87
							c-0.17-0.18-0.31-0.42-0.45-0.65c-0.3-0.51-0.58-0.99-1.09-0.8c-0.8,0.29-0.73,1.49-0.69,2.29c0.08,1.46-0.41,2.67-1.59,3.95
							l-0.08,0.08c-0.85,0.92-1.24,1.34-1.59,1.27c-0.35-0.07-0.54-0.63-0.94-1.85c-0.61-1.81-1.33-3.49-3.45-4.11
							c-0.92-0.27-1.71-0.57-2.4-0.91c-0.44-0.22-0.89-0.41-1.23-0.28c-0.18,0.07-0.32,0.23-0.43,0.48
							c-0.16,0.38-0.36,0.6-0.61,0.68c-0.36,0.11-0.76-0.12-1.07-0.33c-0.47-0.32-0.97-0.64-1.44-0.95
							c-0.99-0.64-2.01-1.31-2.94-2.05c-0.14-0.11-0.2-0.12-0.22-0.11c-0.12,0.07,0.02,0.81,0.11,1.35c0.19,1.03,0.42,2.3,0.01,2.86
							l-0.03,0.04l-0.05-0.01c-0.04-0.01-3.52-0.65-3.69-0.86c-0.91-1.1-1.44-1.51-2.68-2.25c-0.02-0.01-2.36-1.78-2.36-2.02
							c-0.01-1.74-0.11-3.34-1.24-3.77l-0.02-0.01c-0.21-0.17-0.34-0.48-0.46-0.74c-0.11-0.25-0.22-0.5-0.35-0.54
							c-0.04-0.01-0.1,0-0.16,0.03c-0.29,0.17-0.21,0.54-0.11,0.93c0.04,0.19,0.09,0.39,0.09,0.56c0.01,0.92,0.51,1.7,0.95,2.39
							c0.33,0.51,0.62,1.02,0.6,1.59c-0.04,1.32,0.48,2.43,1.03,3.6l0.09,0.2c0.22,0.47,0.66,0.9,1.08,1.31
							c0.56,0.54,1.13,1.1,1.26,1.79c0.04,0.04,0.21,0.1,0.32,0.15c0.23,0.09,0.37,0.15,0.41,0.23l0.02,0.03l-0.01,0.04
							c-0.01,0.13,0.36,1.41,0.66,2.45c0.55,1.88,0.7,2.44,0.66,2.55l-0.01,0.03l-0.03,0.01c-0.41,0.17-0.95,0.88-1.47,1.56
							c-0.68,0.9-1.33,1.75-1.89,1.76l-0.01,0l-0.01,0c-1.77-0.33-2.63-2.84-3.32-4.85c-0.22-0.65-0.43-1.25-0.63-1.66
							c-0.01,0-0.02,0.01-0.02,0.01c-0.02,0.01-0.04,0.02-0.06,0.01c0.05,0.16,0.09,0.3,0.14,0.45c0.53,0.77,0.62,1.34,0.6,1.97
							c0.63,2.09,0.59,2.1,0.51,2.13c-0.07,0.02-0.11,0.03-0.57-1.28c-0.03,0.37-0.06,0.78-0.04,1.28c0.03,0.96-0.69,1.36-1.39,1.75
							c-0.3,0.17-0.6,0.34-0.85,0.55l-0.02,0.01l-0.02,0c-1.16,0.22-3.3,0.69-3.95,1.07l-0.02,0.01l-0.02,0
							c-2.67-0.05-5.68-1.15-7.71-1.97c-0.42-0.17-0.96-0.75-1.15-1.15c-0.47-0.96-0.94-0.91-1.8-0.82
							c-0.15,0.01-0.3,0.03-0.47,0.04c-1.1,0.08-2.4-0.75-3.16-1.54c-0.67-0.71-0.96-1.37-1.26-2.07c-0.19-0.45-0.4-0.92-0.71-1.41
							c-0.14-0.23-0.17-0.65-0.08-1.29c0.04-0.27,0.16-0.48,0.34-0.6c0.2-0.13,0.46-0.16,0.75-0.08c0.26,0.07,0.52,0.15,0.78,0.23
							c0.54,0.16,1.09,0.33,1.64,0.42c1.15,0.18,1.45-0.41,2.09-1.7c0.07-0.14,0.14-0.29,0.22-0.44c0.07-0.14,0.19-0.22,0.34-0.23
							c0.01,0,0.02,0,0.03,0c0.65,0,1.69,1.27,1.79,1.41c0.44,0.65,0.99,0.97,1.52,1.27c0.56,0.32,1.14,0.66,1.59,1.37
							c0.28,0.45,1.05,0.79,1.57,0.7c0.83-0.14,1.2,0.31,1.62,0.82c0.14,0.18,0.29,0.36,0.47,0.53c0.24,0.23,0.39,0.67,0.56,1.14
							c0.18,0.53,0.38,1.07,0.68,1.25l0.09,0.05c0.28,0.17,0.57,0.34,0.85,0.1c0.17-0.15,0.12-0.35,0.06-0.58
							c-0.02-0.09-0.05-0.18-0.06-0.27c-0.22-1.84-1.11-3.45-1.98-5.01c-0.09-0.17-0.19-0.33-0.28-0.5
							c-0.38-0.69-0.75-1.54-0.63-2.56c0.13-1.08-0.34-1.84-1.43-2.33c-0.83-0.37-1.87-0.83-2.23-1.72
							c-0.58-1.41-1.73-1.82-2.96-2.25l-0.08-0.03c-0.05-0.02-0.1-0.03-0.16-0.05c-0.25-0.07-0.57-0.15-0.58-0.61l0-0.04l0.04-0.03
							c4.22-2.95,8.42-4.48,12.83-4.67c0.25-0.01,0.5,0,0.75,0.01c0.67,0.03,1.37,0.06,1.98-0.36l0.34-0.15l0.03,0.01
							c0.06,0.02,0.1,0.04,0.11,0.07c0.15,0.21,0.38,0.31,0.62,0.42c0.09,0.04,0.18,0.08,0.26,0.12c1.03,0.53,2.15,0.56,3.24,0.59
							c0.78,0.02,1.58,0.04,2.34,0.24c0.16,0.01,0.33,0.01,0.51,0.01c0.38,0,0.78,0,1.15,0.1c1.13,0.3,2.27,0.65,3.38,0.99
							c0.43,0.13,0.87,0.27,1.3,0.4c1.71,0.09,3.11-0.03,4.42-0.37l0.8-0.1l0.32-0.1l0.11-0.07l3.2,0.16l0.02,0.01
							c0.67,0.45,1.34,0.7,2.05,0.77l0.02,0l0.02,0.01c0.64,0.44,1.4,0.68,2.12,0.91l0.13,0.04c1.05,0.63,2.06,1.05,3.07,1.28
							l0.02,0.01c0.13,0.08,0.26,0.16,0.39,0.25c0.55,0.35,1.06,0.69,1.5,0.54c0.32-0.11,0.59-0.49,0.81-1.14
							c0.13-0.39,0.73-0.64,1.2-0.49c0.67,0.2,1.22,0.44,1.68,0.74c0.88,0.57,1.59,0.54,2.45-0.09c1.77-1.3,3.44-2.14,5.11-2.56
							c1.14-0.29,2.42-0.46,3.31,1.14c0.4,0.73,1.17,1.27,2.17,1.51c1.21,0.3,2.54,0.09,3.48-0.53c0.53-0.28,1.01-0.65,1.47-1.01
							c0.8-0.61,1.63-1.25,2.7-1.46l0.03-0.01l0.03,0.01c1.37,0.68,2.6,1.62,3.78,2.52c0.7,0.54,1.43,1.09,2.18,1.58l0.06,0.04
							l-0.03,0.06c-0.16,0.38-0.28,0.75-0.39,1.07c-0.22,0.67-0.39,1.2-0.77,1.33c-0.24,0.08-0.53,0.01-0.91-0.24
							c-0.22-0.14-0.44-0.32-0.66-0.5c-0.54-0.43-1.05-0.85-1.48-0.71l-0.13,0.08l-0.16,0.15l-0.58,0.5l-0.03,0
							c-0.3,0.01-0.58,0.03-0.75,0.31l-0.03,0.04l-0.09,0.02c-0.44,0.08-0.86,0.15-0.91,0.64c-0.04,0.35,0.23,0.47,0.64,0.6
							l0.04,0.01c0.25,0.13,0.49,0.26,0.72,0.39c0.52,0.29,1.06,0.59,1.6,0.83c0.3,0.14,0.6,0.3,0.89,0.47
							c0.66,0.37,1.35,0.76,2.15,0.8c2.42,0.12,5.06,2.74,5.13,5.11c0.02,0.51,0.15,0.78,0.58,1.17c0.25,0.23,0.51,0.45,0.76,0.68
							c1.74,1.54,3.54,3.14,4.77,5.21c0.11,0.18,0.55,0.48,1.02,0.8c0.75,0.52,1.54,1.05,1.6,1.48c0.02,0.1-0.01,0.19-0.07,0.27
							c-0.2,0.26-0.31,0.56-0.41,0.83c-0.14,0.38-0.27,0.73-0.63,0.69c-0.03,0.35-0.19,0.54-0.34,0.71
							c-0.17,0.2-0.29,0.34-0.19,0.68l0.02,0.06l-0.05,0.03c-0.23,0.16-0.29,0.34-0.34,0.48c-0.03,0.09-0.06,0.18-0.13,0.23
							c0,0,0.01,0,0.01,0.01c0.53-0.02,2.06-0.68,3.32-1.22c1.81-0.78,2.93-1.25,3.19-1.06c0.38,0.27,0.56,0.59,0.55,0.94
							c-0.04,1-1.62,2.05-2.51,2.6c0.03-0.01,0.05-0.02,0.08-0.03l0.46-0.09c0.13-0.01,0.25-0.03,0.36-0.05
							c0.35-0.06,0.71-0.13,1.06,0.13l0.02,0.02c0.15,0.24,0.27,0.51,0.4,0.78c0.27,0.56,0.54,1.14,0.98,1.4
							c0.63,0.37,1.25,0.78,1.86,1.17c1.07,0.7,2.19,1.43,3.39,1.96c1.13,0.5,2.31,0.44,3.45,0.37c0.89-0.05,1.81-0.1,2.71,0.12
							c0.21,0.05,0.35,0.04,0.39-0.03c0.06-0.1-0.05-0.37-0.25-0.6c-0.3-0.34-0.6-0.68-0.91-1.01c-0.59-0.66-1.21-1.34-1.78-2.03
							c-0.05-0.06-0.11-0.13-0.17-0.19c-0.31-0.34-0.69-0.76-0.38-1.22c0.08-0.12,0.19-0.2,0.31-0.25c-0.78,0-1.52-0.01-2.09-0.1
							c-1.53-0.24-1.97-0.19-2.44-0.13c-0.46,0.06-0.95,0.11-2.42-0.07c-0.68-0.09-1.78-3.9-1.75-4.46
							c0.06-1.23-0.73-1.91-1.56-2.63l-0.18-0.16c-0.08-0.07-0.17-0.13-0.27-0.2c-0.3-0.21-0.64-0.45-0.67-0.96l0-0.07l0.07-0.01
							c0.57-0.1,0.93,0.15,1.29,0.39c0.11,0.08,0.22,0.15,0.34,0.22c1.67,0.91,3.19,1.64,4.97,1.44c0.39-0.04,0.79-0.12,0.88-0.35
							c0.07-0.18-0.04-0.45-0.34-0.81c-0.46-0.57-0.63-0.96-0.54-1.3c0.09-0.34,0.45-0.59,1.18-0.85c0.46-0.16,0.97-0.37,1.22-0.79
							c0.16-0.27,0.34-0.51,0.52-0.71c-1.56,0.43-3.62,0.47-4.51,0.21c-1.15-0.33-1.61-1.46-2.05-2.55
							c-0.33-0.81-0.67-1.65-1.3-2.19c-0.65-0.56-0.94-1.26-0.82-2.02c0.16-1.04,1.08-2.07,2.34-2.62c0.77-0.34,1.49-0.53,2.18-0.58
							c0.05,0,0.07-0.01,0.08-0.01c-0.02-0.05-0.12-0.14-0.18-0.2c-0.15-0.15-0.3-0.28-0.25-0.42c0.02-0.08,0.1-0.11,0.16-0.13
							c0.01-0.05,0.03-0.08,0.06-0.11c0.15-0.16,0.59-0.11,1.06-0.04c-0.41-0.23-0.84-0.45-1.25-0.67c-1.38-0.72-2.8-1.46-3.92-2.64
							l-0.1-0.11l0.14-0.03c0.73-0.15,1.41,0.04,2.08,0.22c0.43,0.12,0.88,0.24,1.34,0.27c0.77,0.04,1.86,0.27,2.66,1.13l0.24,0.27
							l0.61,0.57l0,0.03c0.12,1.19,1.54,1.25,2.91,1.31c0.38,0.02,0.73,0.03,1.06,0.07c1.38,0.16,2.29,0.21,3.25,0.26
							c0.82,0.05,1.66,0.09,2.84,0.21l0.05,0l0.03,0.08c0,0.24,0,0.49,0.01,0.74c0.01,0.54,0.03,1.11-0.05,1.65
							c-0.08,0.51,0.01,0.96,0.26,1.28c0.22,0.3,0.56,0.48,0.98,0.54c0.55,0.07,1.08,0.23,1.58,0.39c0.68,0.2,1.37,0.42,2.12,0.42
							c0.01,0,0.01,0,0.02,0h0c0,0,0,0,0,0l0,0.08l0.07-0.05c0.05,0.07,0.71,0.64,0.84,0.7c0.38,0.17,0.8,0.3,1.2,0.43
							c0.2,0.07,0.41,0.13,0.61,0.2c1.25,0.44,2.5,0.87,3.75,1.3c1.61,0.56,2.44,1.03,3.17,1.45c0.39,0.22,0.73,0.42,1.14,0.6
							l0.03,0.02l1.64,1.68l0.13,0.03l-0.01,0.03c0.61,0.33,3.18,1.79,3.51,3.15c0.08,0.33,0.02,0.63-0.17,0.89
							c-0.23,0.31-0.49,0.67-0.59,1.08c-0.11,0.43-0.02,0.86,0.24,1.1c0.11,0.11,0.26,0.18,0.41,0.26c0.2,0.1,0.41,0.21,0.55,0.41
							c0.21,0.3,0.15,0.76-0.13,1c0.32,0.41,1.36,1.75,1.49,2.18c0.25,0.77-0.15,1.55-0.57,2.24l1.19,0.6
							c0.2,0.1,0.42,0.24,0.43,0.49c0,0.12-0.06,0.23-0.13,0.32l4.32,1.98l2.62,2.19c0.15,0.02,0.91,0.14,1.28,0.29
							c0.28,0.11,0.34,0.19,0.41,0.31c0.03,0.06,0.07,0.12,0.15,0.2c0.34,0.33,0.81,0.45,1.31,0.57l0.09,0.02
							c0.07,0.02,0.1,0.07,0.09,0.14c-0.14,0.61-11.79,6.57-30.03,10.87C434.11,2680.26,420.9,2682.41,405.8,2682.41z
							 M373.54,2678.81c28.21,6.18,53.8,2.86,70.29-1.01c18.34-4.3,29.3-10.02,30.04-10.75l-0.02-0.01
							c-0.5-0.12-1.01-0.25-1.38-0.61c-0.1-0.1-0.15-0.17-0.18-0.23c-0.05-0.09-0.09-0.15-0.33-0.25c-0.37-0.15-1.25-0.28-1.26-0.29
							l-0.02,0l-2.65-2.21l-4.49-2.06l0.08-0.08c0.09-0.1,0.16-0.19,0.16-0.29c0-0.15-0.18-0.26-0.34-0.34l-1.35-0.68l0.05-0.08
							c0.41-0.68,0.84-1.46,0.6-2.18c-0.15-0.47-1.55-2.23-1.56-2.25l-0.12-0.15l0.26,0.02c0.2-0.19,0.23-0.53,0.08-0.76
							c-0.11-0.16-0.3-0.26-0.49-0.36c-0.15-0.08-0.31-0.16-0.44-0.28c-0.3-0.28-0.42-0.78-0.29-1.26c0.12-0.44,0.38-0.81,0.62-1.13
							c0.16-0.23,0.21-0.47,0.14-0.76c-0.33-1.4-3.36-3.01-3.54-3.1l-0.02-0.01l-0.67-0.34l0.45,0.12l-1.43-1.47
							c-0.41-0.18-0.77-0.38-1.14-0.6c-0.73-0.41-1.55-0.89-3.15-1.44c-1.25-0.43-2.5-0.87-3.75-1.3c-0.2-0.07-0.4-0.13-0.6-0.2
							c-0.41-0.13-0.83-0.26-1.22-0.44c-0.01,0-0.67-0.49-0.87-0.72c-0.77,0-1.48-0.22-2.17-0.42c-0.5-0.15-1.02-0.31-1.56-0.38
							c-0.46-0.06-0.84-0.27-1.09-0.6c-0.27-0.36-0.37-0.85-0.29-1.41c0.08-0.53,0.06-1.08,0.05-1.62
							c-0.01-0.22-0.01-0.45-0.01-0.67c-1.13-0.11-1.96-0.16-2.76-0.2c-0.97-0.05-1.88-0.11-3.26-0.26
							c-0.32-0.04-0.67-0.05-1.05-0.07c-1.37-0.05-2.91-0.12-3.07-1.42l-0.57-0.54l-0.25-0.28c-0.76-0.82-1.8-1.04-2.54-1.08
							c-0.47-0.03-0.93-0.15-1.37-0.27c-0.62-0.17-1.21-0.34-1.85-0.24c1.08,1.1,2.45,1.81,3.77,2.5c0.53,0.28,1.08,0.56,1.6,0.87
							c0.07,0.04,0.06,0.1,0.05,0.11c-0.03,0.08-0.07,0.07-0.34,0.02c-0.26-0.04-0.95-0.16-1.07-0.04
							c-0.01,0.01-0.01,0.01-0.01,0.04l0.02,0.08l-0.08,0.01c-0.08,0.01-0.09,0.03-0.09,0.03c0,0.04,0.14,0.18,0.21,0.25
							c0.15,0.14,0.27,0.26,0.23,0.38c-0.03,0.09-0.15,0.11-0.24,0.12c-0.67,0.05-1.37,0.23-2.12,0.56c-1.22,0.53-2.1,1.51-2.25,2.5
							c-0.11,0.7,0.16,1.35,0.77,1.87c0.65,0.56,1,1.42,1.34,2.26c0.45,1.1,0.87,2.14,1.94,2.45c0.99,0.29,3.23,0.18,4.74-0.32
							c0.3-0.27,0.61-0.45,0.92-0.51c0.05-0.01,0.11-0.02,0.16-0.02c0.18-0.14,0.31-0.29,0.37-0.46c0.22-0.54,0.36-0.76,0.55-0.83
							c0.21-0.07,0.42,0.04,0.78,0.24c0.07,0.04,0.15,0.08,0.23,0.12c0.65,0.34,1.12,0.02,1.65-0.34c0.51-0.35,1.08-0.74,1.88-0.55
							c0.16,0.04,0.26,0.11,0.3,0.21c0.07,0.17-0.06,0.37-0.17,0.54c-0.03,0.05-0.06,0.1-0.09,0.14c-0.07,0.12-0.14,0.24-0.21,0.36
							c-0.28,0.47-0.55,0.92-0.48,1.49c0,0,0.01,0.01,0.01,0.01c0.2,0.18,0.34,0.26,0.43,0.29c0.05-0.06,0.1-0.1,0.15-0.1
							c0.05,0,0.09,0.04,0.11,0.09c0.02,0.06-0.01,0.12-0.07,0.16c-0.03,0.02-0.06,0.03-0.1,0.03c-0.02,0.04-0.04,0.08-0.06,0.15
							c-0.01,0.03-0.04,0.13-0.13,0.13c-0.1,0.01-0.15-0.1-0.23-0.27c-0.06-0.12-0.13-0.27-0.21-0.35
							c-0.01-0.01-0.02-0.02-0.03-0.03c-0.3-0.24-0.79-0.08-1.26,0.08c-0.56,0.19-1.14,0.38-1.44-0.11
							c-0.14-0.07-0.29-0.13-0.43-0.2c-0.28-0.12-0.57-0.25-0.82-0.42c-0.21-0.15-0.43-0.22-0.66-0.21c-0.28,0.22-0.66,0.4-1.1,0.54
							c-0.25,0.23-0.49,0.54-0.7,0.89c-0.28,0.46-0.82,0.68-1.31,0.86c-0.67,0.24-1,0.46-1.08,0.74c-0.08,0.27,0.09,0.63,0.51,1.15
							c0.34,0.42,0.46,0.74,0.37,0.98c-0.12,0.31-0.56,0.4-1.02,0.45c-1.83,0.2-3.38-0.54-5.07-1.46c-0.12-0.07-0.24-0.15-0.35-0.22
							c-0.32-0.22-0.63-0.44-1.09-0.38c0.05,0.38,0.31,0.56,0.59,0.76c0.1,0.07,0.19,0.14,0.28,0.21l0.18,0.15
							c0.83,0.71,1.69,1.45,1.62,2.76c-0.03,0.68,1.11,4.23,1.61,4.29c1.45,0.18,1.92,0.13,2.38,0.07c0.48-0.06,0.94-0.11,2.49,0.13
							c0.59,0.09,1.37,0.1,2.2,0.1c2.07,0.01,4.41,0.02,5.21,1.36l0.1,0.18l-0.2-0.05c-0.47-0.13-0.91-0.25-1.33-0.36
							c-0.99-0.27-1.85-0.51-2.72-0.71c-0.05-0.01-0.1-0.02-0.15-0.03c-0.44-0.1-0.98-0.23-1.22,0.12
							c-0.22,0.33,0.01,0.63,0.36,1.02c0.06,0.07,0.12,0.13,0.18,0.2c0.57,0.69,1.18,1.37,1.77,2.03c0.3,0.34,0.61,0.68,0.91,1.02
							c0.22,0.25,0.39,0.59,0.27,0.79c-0.08,0.14-0.27,0.17-0.57,0.1c-0.87-0.21-1.78-0.16-2.66-0.11
							c-1.16,0.06-2.36,0.13-3.52-0.39c-1.22-0.54-2.34-1.27-3.42-1.98c-0.6-0.39-1.22-0.8-1.85-1.16
							c-0.48-0.28-0.77-0.89-1.04-1.47c-0.12-0.25-0.24-0.52-0.38-0.75c-0.29-0.2-0.6-0.15-0.92-0.09
							c-0.12,0.02-0.25,0.04-0.37,0.05l-0.43,0.09c-0.76,0.29-0.76,0.29-0.8,0.2c-0.04-0.08-0.01-0.1,0.36-0.33
							c0.72-0.44,2.62-1.6,2.66-2.6c0.01-0.3-0.15-0.57-0.48-0.8c-0.2-0.14-1.83,0.56-3.03,1.08c-1.69,0.73-2.9,1.23-3.42,1.23
							l0.11,0.17c0.12,0.16,0.25,0.33,0.31,0.49l0.12,0.19l-0.08-0.03c0.01,0.07-0.01,0.14-0.07,0.18
							c-0.12,0.08-0.32-0.05-0.43-0.16c-0.12-0.12-0.11-0.21-0.08-0.25c0.04-0.07,0.13-0.08,0.28-0.05l-0.18-0.29
							c-0.06-0.07-0.12-0.15-0.17-0.21c-0.11-0.12-0.17-0.19-0.18-0.25c-0.1-0.06-0.11-0.08-0.08-0.14
							c0.03-0.05,0.05-0.06,0.13-0.03l-0.06-0.28l0.22,0.35c0,0,0,0,0.01,0c0.09,0,0.11-0.05,0.16-0.19
							c0.05-0.15,0.12-0.35,0.36-0.53c-0.09-0.39,0.07-0.59,0.23-0.78c0.14-0.16,0.28-0.33,0.3-0.66c-0.06-0.03-0.07-0.08-0.07-0.11
							c0-0.07,0.07-0.12,0.13-0.13c0.08-0.02,0.12,0.01,0.15,0.05c0.02,0.03,0.02,0.05,0.01,0.08c0.18,0,0.26-0.19,0.41-0.58
							c0.1-0.28,0.22-0.6,0.44-0.88c0.03-0.04,0.04-0.09,0.04-0.14c-0.06-0.36-0.87-0.92-1.53-1.36c-0.5-0.34-0.94-0.64-1.07-0.85
							c-1.22-2.04-3.01-3.63-4.74-5.17c-0.25-0.23-0.51-0.45-0.76-0.68c-0.47-0.42-0.62-0.73-0.64-1.29
							c-0.07-2.25-2.67-4.84-4.98-4.95c-0.84-0.04-1.54-0.44-2.23-0.82c-0.28-0.16-0.58-0.32-0.87-0.46
							c-0.55-0.25-1.09-0.55-1.62-0.84c-0.24-0.13-0.47-0.26-0.71-0.39l-0.03-0.01c-0.36-0.12-0.81-0.27-0.75-0.78
							c0.07-0.61,0.61-0.71,1.05-0.78l0.03-0.01c0.21-0.31,0.53-0.35,0.85-0.36l0.24-0.22l0.47-0.4l0.16-0.1
							c0.52-0.18,1.1,0.28,1.65,0.73c0.21,0.17,0.43,0.35,0.65,0.49c0.33,0.22,0.58,0.29,0.76,0.22c0.3-0.11,0.46-0.6,0.67-1.23
							c0.1-0.31,0.22-0.66,0.37-1.02c-0.73-0.49-1.44-1.03-2.13-1.55c-1.17-0.89-2.38-1.82-3.73-2.49c-1.02,0.21-1.82,0.82-2.6,1.42
							c-0.47,0.36-0.96,0.73-1.49,1.02c-0.97,0.64-2.35,0.85-3.6,0.55c-1.04-0.25-1.84-0.82-2.27-1.59
							c-0.75-1.35-1.75-1.41-3.12-1.06c-1.65,0.42-3.3,1.25-5.06,2.53c-0.91,0.67-1.7,0.7-2.63,0.1c-0.44-0.28-0.99-0.53-1.63-0.72
							c-0.39-0.12-0.89,0.08-1,0.39c-0.24,0.71-0.53,1.11-0.91,1.24c-0.52,0.18-1.09-0.19-1.65-0.55c-0.12-0.08-0.25-0.16-0.37-0.24
							c-1.02-0.24-2.04-0.66-3.1-1.29l-0.1-0.03c-0.73-0.23-1.49-0.47-2.15-0.92c-0.72-0.08-1.41-0.34-2.09-0.79l-3.1-0.15
							l-0.09,0.05l-0.35,0.11l-0.81,0.1c-1.31,0.34-2.74,0.46-4.47,0.37l-0.02,0c-0.44-0.13-0.88-0.26-1.31-0.4
							c-1.1-0.34-2.24-0.68-3.37-0.99c-0.35-0.09-0.74-0.09-1.11-0.1c-0.17,0-0.35,0-0.53-0.01c-0.76-0.2-1.56-0.22-2.32-0.24
							c-1.11-0.03-2.25-0.06-3.31-0.61c-0.08-0.04-0.17-0.08-0.25-0.12c-0.25-0.11-0.51-0.22-0.69-0.47l-0.01,0l-0.26,0.11
							c-0.64,0.44-1.37,0.41-2.07,0.38c-0.24-0.01-0.5-0.02-0.74-0.01c-4.36,0.2-8.52,1.71-12.71,4.62c0.02,0.3,0.21,0.35,0.45,0.41
							c0.06,0.02,0.12,0.03,0.17,0.05l0.08,0.03c1.26,0.44,2.45,0.86,3.06,2.34c0.34,0.82,1.34,1.27,2.14,1.63
							c1.17,0.52,1.67,1.34,1.53,2.5c-0.12,0.97,0.22,1.76,0.61,2.46c0.09,0.17,0.18,0.33,0.28,0.5c0.87,1.57,1.78,3.19,2,5.07
							c0.01,0.08,0.03,0.16,0.06,0.25c0.07,0.25,0.15,0.53-0.11,0.75c-0.36,0.32-0.75,0.08-1.04-0.09l-0.08-0.05
							c-0.36-0.21-0.56-0.78-0.75-1.34c-0.15-0.43-0.31-0.87-0.52-1.07c-0.19-0.18-0.34-0.36-0.49-0.54
							c-0.41-0.49-0.73-0.88-1.47-0.76c-0.59,0.1-1.42-0.27-1.74-0.77c-0.43-0.68-0.97-0.99-1.53-1.32
							c-0.55-0.32-1.11-0.64-1.57-1.32c-0.19-0.27-1.18-1.37-1.67-1.34c-0.09,0.01-0.16,0.05-0.2,0.14
							c-0.08,0.15-0.15,0.3-0.22,0.44c-0.64,1.28-0.99,1.98-2.27,1.79c-0.57-0.09-1.13-0.26-1.67-0.42
							c-0.25-0.08-0.52-0.16-0.77-0.23c-0.24-0.07-0.46-0.05-0.61,0.06c-0.15,0.1-0.24,0.27-0.27,0.49
							c-0.08,0.59-0.06,0.99,0.06,1.18c0.32,0.5,0.53,0.97,0.72,1.43c0.3,0.69,0.58,1.34,1.23,2.03c0.75,0.79,2.03,1.56,3.03,1.49
							c0.16-0.01,0.32-0.03,0.46-0.04c0.87-0.09,1.45-0.15,1.97,0.92c0.18,0.37,0.68,0.92,1.07,1.07c2.01,0.81,4.98,1.9,7.62,1.96
							c0.84-0.47,3.66-1.01,3.96-1.07c0.25-0.22,0.56-0.39,0.86-0.55c0.69-0.38,1.33-0.74,1.31-1.6c-0.02-0.61,0.03-1.1,0.06-1.52
							c0-0.05,0.01-0.1,0.01-0.14c-0.23-0.67-0.55-1.61-0.99-2.93c-0.19-0.21-0.31-0.25-0.42-0.29c-0.15-0.05-0.3-0.11-0.54-0.45
							c-0.1-0.15-0.14-0.2-0.07-0.25c0.06-0.05,0.11-0.01,0.2,0.07c0.07,0.06,0.18,0.16,0.3,0.24c0.06-0.06,0.15-0.1,0.26-0.11
							c-0.03-0.08-0.06-0.17-0.08-0.25c-0.01-0.02-0.03-0.1,0.02-0.15c0.02-0.02,0.06-0.04,0.13-0.01c0.04,0.02,0.1,0.06,0.16,0.12
							c-0.04-0.18-0.01-0.2,0.05-0.22c0.06-0.02,0.11-0.04,0.28,0.38c0.13-0.18,0.21-0.21,0.28-0.18c0.04,0.02,0.16,0.07,0.02,0.54
							c-0.02,0.07-0.04,0.14-0.07,0.2c0.27,0.41,0.52,1.13,0.81,1.99c0.67,1.97,1.51,4.42,3.18,4.74c0.48-0.02,1.12-0.87,1.75-1.69
							c0.54-0.71,1.05-1.38,1.49-1.59c-0.02-0.22-0.38-1.44-0.66-2.42c-0.48-1.66-0.66-2.29-0.67-2.49
							c-0.05-0.04-0.21-0.1-0.31-0.14c-0.23-0.09-0.37-0.15-0.41-0.23l-0.01-0.02c-0.11-0.65-0.67-1.19-1.22-1.72
							c-0.43-0.42-0.88-0.86-1.12-1.36l-0.09-0.2c-0.54-1.14-1.09-2.32-1.05-3.68c0.02-0.53-0.26-1.01-0.57-1.5
							c-0.45-0.71-0.97-1.52-0.98-2.48c0-0.16-0.05-0.35-0.09-0.53c-0.1-0.41-0.21-0.88,0.19-1.11c0.1-0.06,0.2-0.08,0.29-0.05
							c0.2,0.07,0.32,0.33,0.45,0.63c0.11,0.25,0.23,0.53,0.4,0.67c1.21,0.47,1.32,2.11,1.33,3.91c0.13,0.22,2.09,1.78,2.28,1.89
							c1.26,0.75,1.8,1.16,2.73,2.28c0.17,0.13,2.82,0.66,3.54,0.79c0.33-0.53,0.11-1.73-0.07-2.69c-0.17-0.91-0.24-1.4-0.04-1.52
							c0.13-0.08,0.29,0.03,0.41,0.12c0.93,0.74,1.95,1.4,2.93,2.04c0.48,0.31,0.97,0.63,1.44,0.96c0.41,0.28,0.7,0.38,0.92,0.31
							c0.2-0.06,0.36-0.25,0.5-0.58c0.13-0.3,0.3-0.49,0.53-0.57c0.4-0.15,0.89,0.05,1.36,0.29c0.68,0.34,1.46,0.64,2.37,0.9
							c2.19,0.64,2.93,2.36,3.56,4.21c0.36,1.08,0.57,1.68,0.82,1.74c0.25,0.05,0.67-0.4,1.43-1.22l0.08-0.08
							c1.15-1.24,1.63-2.42,1.55-3.82c-0.04-0.79-0.12-2.12,0.8-2.45c0.64-0.24,0.99,0.35,1.29,0.87c0.13,0.23,0.27,0.46,0.42,0.62
							c0.27,0.28,0.51,0.58,0.75,0.88c0.19,0.24,0.4,0.49,0.61,0.73c0.04,0.04,0.08,0.09,0.12,0.14c0.2,0.25,0.34,0.4,0.57,0.31
							c0.28-0.11,0.27-0.33,0.21-0.68c-0.01-0.08-0.02-0.16-0.03-0.23c-0.02-0.28-0.07-0.57-0.11-0.85
							c-0.04-0.26-0.09-0.54-0.11-0.81c0-0.05-0.01-0.1-0.02-0.16c-0.04-0.28-0.08-0.63,0.25-0.82c0.37-0.21,0.6,0.07,0.77,0.28
							c0.04,0.05,0.08,0.09,0.12,0.13c0.06,0.06,0.13,0.13,0.2,0.19c0.28,0.27,0.57,0.55,0.72,0.89c0.2,0.48,0.43,0.74,0.7,0.8
							c0.37,0.09,0.81-0.23,1.22-0.59c1.21-1.04,4.29-1.27,5.5-0.41c0.33,0.24,0.55,0.77,0.56,1.4c0.01,0.58-0.17,1.35-0.7,1.75
							c-0.39,0.3-0.58,0.54-0.54,0.72c0.05,0.23,0.48,0.36,0.8,0.44c0.25,0.06,0.5,0.19,0.75,0.31c0.72,0.36,1.4,0.7,2.07-0.43
							c0.09-0.16,0.19-0.23,0.3-0.23c0,0,0,0,0,0c0.2,0,0.34,0.26,0.46,0.48c0.04,0.06,0.07,0.12,0.1,0.17
							c0.37,0.54,0.74,1.09,1.11,1.63c1.39,2.04,2.82,4.16,4.18,6.27c0.24,0.37,0.44,0.55,0.6,0.54c0.16-0.01,0.32-0.2,0.5-0.58
							c0.18-0.38,0.38-0.75,0.57-1.11c0.48-0.88,0.97-1.8,1.12-2.84c0.1-0.71,0.47-1.17,1.13-1.4c0.81-0.29,1.61-0.62,2.4-0.94
							c0.37-0.15,0.74-0.3,1.12-0.45c0.04-0.01,0.07-0.03,0.11-0.05c0.23-0.1,0.51-0.22,0.76,0.02c0.25,0.24,0.14,0.52,0.06,0.74
							c-0.02,0.04-0.03,0.09-0.04,0.13c-0.18,0.58-0.37,1.28-0.36,2.03c0.01,0.7,0.22,1.1,0.69,1.33c1.03,0.5,1.44,1.34,1.3,2.65
							c-0.03,0.31-0.05,0.65,0.13,0.84c0.12,0.13,0.32,0.19,0.61,0.19c0.04,0,0.09,0,0.13,0c0.43-0.01,0.96-0.03,1.05,0.65
							c0.08,0.63,0.11,1.28-0.47,1.76c-0.42,0.34-0.85,0.67-1.27,1c-0.2,0.16-0.4,0.31-0.6,0.47c-0.06,0.05-0.13,0.09-0.2,0.14
							c-0.34,0.23-0.54,0.4-0.41,0.72c0.15,0.37,0.57,0.35,0.97,0.34l0.07,0c0.22-0.01,0.43-0.03,0.65-0.06
							c0.73-0.09,1.49-0.18,2.27,0.53c1.05,0.96,1.37,2.19,1.67,3.37c0.1,0.38,0.2,0.77,0.32,1.14c0.07,0.21,0.05,0.38-0.07,0.52
							c-0.25,0.3-0.92,0.32-1.41,0.25c-1.55-0.22-2.93-0.96-4.26-1.67c-0.22-0.11-0.43-0.23-0.65-0.34
							c-2.02-1.05-4.71-2.34-7.69-3.01l-0.1,0.21c0.27,0.21,0.54,0.43,0.82,0.64c0.65,0.51,1.33,1.04,1.98,1.57
							c0.94,0.78,1.88,1.59,2.79,2.38l0.09,0.07c0.16,0.14,0.43,0.37,0.3,0.69c-0.15,0.38-0.53,0.32-0.78,0.28l-0.04-0.01
							c-0.66-0.1-1.2-0.46-1.73-0.8c-0.09-0.06-0.19-0.13-0.29-0.19c-0.64-0.41-1.28-0.87-1.89-1.3c-1.8-1.29-3.67-2.62-5.97-3.08
							c-2.27-0.46-4.18-0.32-5.86,0.41c-1.34,0.59-2.27,1.82-2.62,3.48c-0.39,1.84,0,3.21,1.24,4.31c0.44,0.39,0.63,0.76,0.58,1.09
							c-0.06,0.34-0.38,0.64-0.96,0.88c-0.5,0.21-0.98,0.48-1.44,0.75c-0.96,0.55-1.96,1.12-3.16,1.15
							c-2.15,1.72-3.65,4.03-5.1,6.26l-0.1,0.16c-0.03,0.05-0.06,0.09-0.09,0.14c-0.21,0.34-0.45,0.72-0.85,0.75
							c-0.49,0.03-0.79,0.15-0.92,0.34c-0.17,0.27-0.02,0.7,0.14,1.11c0.04,0.09,0.07,0.19,0.1,0.28c0.11,0.31,0.26,0.62,0.42,0.91
							C373.33,2678.35,373.44,2678.58,373.54,2678.81z M434.07,2657.39c0.07,0.02,0.14,0.03,0.21,0.05c0.05,0.01,0.1,0.02,0.14,0.03
							c0.88,0.2,1.74,0.43,2.73,0.71c0.35,0.1,0.72,0.2,1.11,0.3C437.52,2657.54,435.78,2657.41,434.07,2657.39z M416.67,2656.56
							c0.03,0.03,0.07,0.08,0.14,0.12c0.06,0.04,0.11,0.05,0.12,0.05c0,0,0-0.03-0.02-0.11
							C416.78,2656.57,416.71,2656.56,416.67,2656.56z M437.83,2646.35c0,0.01,0.01,0.02,0.01,0.02c0.02,0.04,0.04,0.09,0.06,0.13
							c0.01-0.04,0.02-0.07,0.04-0.1C437.9,2646.39,437.87,2646.37,437.83,2646.35z M433.01,2645.04c0.2,0.03,0.4,0.1,0.58,0.24
							c0.23,0.17,0.52,0.29,0.79,0.41c0.16,0.07,0.32,0.14,0.47,0.21l0.02,0.01l0.01,0.02c0.21,0.37,0.61,0.27,1.25,0.06
							c0.45-0.15,0.91-0.3,1.27-0.14c-0.02-0.57,0.25-1.02,0.52-1.46c0.07-0.12,0.14-0.23,0.21-0.35c0.03-0.05,0.06-0.1,0.1-0.15
							c0.09-0.13,0.19-0.3,0.16-0.39c-0.02-0.06-0.12-0.09-0.19-0.11c-0.73-0.18-1.25,0.18-1.75,0.52
							c-0.53,0.37-1.09,0.74-1.83,0.35c-0.08-0.04-0.16-0.09-0.23-0.13c-0.32-0.17-0.51-0.28-0.64-0.23
							c-0.13,0.05-0.26,0.25-0.45,0.73C433.23,2644.78,433.13,2644.91,433.01,2645.04z M343.54,2642.34
							c0.3,0.88,0.53,1.58,0.72,2.13c0,0,0-0.01,0-0.01c-0.16-0.54-0.36-1.18-0.58-1.93
							C343.64,2642.47,343.59,2642.4,343.54,2642.34z M344.06,2643.18c0.06,0.21,0.12,0.4,0.18,0.58
							C344.2,2643.57,344.15,2643.38,344.06,2643.18z M343.39,2641.88c0.01,0.01,0.02,0.02,0.03,0.04c0.04,0.05,0.08,0.1,0.12,0.14
							c-0.01-0.04-0.03-0.09-0.04-0.14c0,0-0.01,0-0.01,0c-0.09-0.01-0.14-0.12-0.15-0.36c-0.01,0.01-0.03,0.02-0.04,0.02
							C343.33,2641.69,343.36,2641.79,343.39,2641.88z M343.1,2641.62c0.01,0.01,0.02,0.01,0.03,0.02c0-0.01-0.01-0.02-0.01-0.02
							C343.12,2641.62,343.11,2641.62,343.1,2641.62z M342.87,2641.38c0.05,0.03,0.11,0.05,0.16,0.06c0.01,0,0.03-0.01,0.04-0.01
							c-0.01-0.03-0.02-0.06-0.03-0.1C342.96,2641.33,342.91,2641.35,342.87,2641.38z M343.62,2641.28c0.03,0.03,0.04,0.06,0.04,0.1
							c0.04-0.12,0.06-0.25,0.06-0.33c-0.03,0.03-0.07,0.08-0.13,0.17C343.6,2641.24,343.61,2641.26,343.62,2641.28z
							 M343.22,2641.37C343.22,2641.37,343.22,2641.37,343.22,2641.37C343.22,2641.37,343.22,2641.37,343.22,2641.37
							C343.22,2641.37,343.22,2641.37,343.22,2641.37z M343.16,2641.18c0.03,0.01,0.07,0.01,0.1,0.03c-0.04-0.06-0.1-0.14-0.16-0.19
							C343.12,2641.07,343.14,2641.12,343.16,2641.18z"/>
					</g>
					<g>
						<path d="M373.48,2678.88c-66.41-2.45-92.86-22.5-90.95-22.48c1.71,0.02,3.34,0.26,4.97,0.66c1.18,0.29,2.27,0.1,3.41-0.31
							c3.09-1.08,4.89-3.77,7.41-5.6c-0.53-0.53-1.13-0.3-1.66-0.35c-0.85-0.08-0.66-0.75-0.77-1.27c0.53-1.53,2.24-1.46,3.21-2.39
							c0.23-0.02,0.49,0.02,0.69-0.06c1.43-0.56,2.85-1.14,4.26-1.72c0.31-0.13,0.62-0.32,0.92-0.09c0.35,0.27,0.07,0.62-0.03,0.9
							c-0.16,0.48-0.41,0.94-0.61,1.41c-0.11,0.25-0.3,0.56,0,0.75c0.14,0.09,0.43-0.05,0.64-0.11c1.34-0.35,2.37-1.29,3.59-1.86
							c1.65-0.77,1.63-0.82,1.75,1.01c0.02,0.38,0.03,0.76,0.33,1.04c0.37,0.33,1.06,0.6,1.15,0.09c0.19-1.18,1.14-1.18,1.87-1.54
							c1.89-0.91,3.79-1.79,5.68-2.71c0.32-0.16,0.7-0.4,0.61-0.85c-0.1-0.46-0.53-0.48-0.9-0.48c-0.84,0.01-1.68-0.12-2.51,0.11
							c-0.35,0.1-0.77,0.34-1.01-0.08c-0.21-0.37,0.13-0.7,0.36-0.99c0.39-0.47,0.78-0.93,0.87-1.57c1.29-1.63,2.51-3.31,3.8-4.94
							c0.75-0.95,1.51-1.87,1.81-3.1c0.19-0.78,0.78-1.39,1.39-1.89c1.41-1.16,2.61-2.51,3.89-3.8c2.32-0.44,4.51-1.35,6.78-1.97
							c0.11,0.14,0.24,0.42,0.32,0.41c1.6-0.36,3.45,0.36,4.78-1.11c0.18,0.02,0.35,0.05,0.53,0.07l0.27,0.04
							c0.19,0.03,0.37,0.06,0.56,0.08c0,0,0.28,0.07,0.33,0.07c0.22,0.01,0,0,0,0c0.75,0.04,1.49,0.16,2.17,0.48
							c0.08,0.3,0.64,0.12,0.86,0.16c-0.94,0.81-2.15,0.45-3.07,0.49c-2.61,0.12-5.17,0.73-7.58,1.74
							c-1.22,0.51-2.39,1.13-3.52,1.81c-0.3,0.18-1.58,0.76-1.67,1.1c-0.14,0.53,0.89,0.67,1.23,0.8c0.74,0.27,1.36,0.53,1.89,1.13
							c0.53,0.6,0.87,1.36,1.5,1.89c0.73,0.61,1.77,0.82,2.33,1.58c0.65,0.88,0.56,1.99,0.76,3.01c0.23,1.16,0.91,2.12,1.44,3.15
							c0.64,1.22,1.06,2.55,1.24,3.92c0.01,0.11,0.03,0.22-0.01,0.32c-0.11,0.34-0.59,0.36-0.9,0.19c-0.97-0.52-1.78-1.32-2.77-1.78
							c-0.72-0.34-1.57-0.46-2.24-0.91c-0.71-0.49-1.11-1.28-1.57-1.99c-0.55-0.85-0.91-1.98-1.69-2.65
							c-0.21-0.18-0.51-0.29-0.77-0.2c-0.33,0.11-0.51,0.46-0.73,0.73c-0.77,0.95-2.04,0.83-3.11,0.58
							c-0.7-0.17-1.33-0.56-2.04-0.71c-1.53-0.31-0.49,1.93-0.2,2.52c0.46,0.94,0.96,1.96,1.81,2.61c1.09,0.83,2.24,0.43,3.46,0.78
							c0.62,0.18,0.89,0.64,1.29,1.09c0.54,0.61,1.14,1.01,1.9,1.31c2.06,0.82,4.4,1.87,6.66,1.85c0.82-0.01,1.73-0.45,1.9-1.25
							c1.1-0.13,3.72-0.86,3.71-2.31c-0.01-1.47-0.91-2.89-1.21-4.31c-0.08-0.37-0.11-0.81,0.17-1.06c0.31-0.27,0.79-0.12,1.14,0.09
							c0.35,0.22,0.67,0.5,0.93,0.83c0.09,0.11,0.17,0.23,0.18,0.37c0.02,0.17-0.07,0.32-0.12,0.48c-0.34,0.9,0.15,1.92,0.85,2.6
							c0.35,0.34,1.56,1.57,2.06,1.55c0.68-0.03,0.69-1.58,0.69-2.16c0-1.88-0.29-3.76-0.82-5.55c-0.42-1.41-1.28-2.68-1.64-4.11
							c-0.23-0.93-0.17-1.94-0.57-2.82c-0.31-0.66-2.2-3.72-1.12-4.36c0.8-0.47,1.05,0.17,1.4,0.76c0.34,0.57,1.03,0.72,1.47,1.17
							c1.31,1.35,1.9,3.65,3.7,4.46c0.55,0.25,1.13,0.38,1.7,0.56c0.79,0.25,1.32,0.93,2.07,1.28c1.54,0.7,3.37,0.79,4.98,1.41
							c2.15,0.84,3.99,2.85,5.61,4.43c0.29,0.28,0.58,0.57,0.73,0.95c0.46,1.17-0.4,2.61-0.84,3.67c-0.55,1.34-1.18,2.64-1.81,3.94
							c-0.41,0.86-0.95,3.16-2.01,3.38c-0.86,0.18-2.24-0.83-3.03-1.14c-3.44-1.32-6.6,1.4-10.19,1.26
							c-1.26-0.05-0.53,1.03-1.78,1.3c-0.78,0.17-2.14-0.6-2.72-0.07c-0.4,0.36-0.52-0.55,0,0c0.24,0.25,0.02,0.54,0.37,0.59
							c1.27,0.18,2.63,0.2,3.09,1.47c0.17,0.47-1.02,1.15-0.59,1.4c0.42,0.24,0.99,0.09,1.45-0.07c0.46-0.16,0.65-0.82,1.13-0.78
							c0.61,0.05,0.93,0.28,1.53,0.42c2.09,0.48,2.75-0.85,4.63-1.16c1.49-0.25,2.49,0.93,2.95,2.21c0.29,0.79,0.88,1.25,1.74,1.07
							c2.42-0.51,4.65-1.33,5.67-3.88c1.03-0.01,2.06-0.05,3.08-0.02c2.12,0.05,3.03,0.99,3.08,3.13c0.05,2.06,0.05,2.06,2.01,1.7
							c0.5-0.09,1.01-0.18,1.52-0.23c2.88-0.31,5.11,1.62,5.19,4.37c0.03,1.16-0.39,2.19-0.62,3.28c-2.22,1.77-3.72,4.12-5.24,6.46
							c-0.23,0.36-0.46,0.83-0.87,0.85c-1.58,0.11-1.02,1.05-0.75,1.84C372.91,2677.81,373.26,2678.32,373.48,2678.88"/>
						<path d="M373.61,2678.97l-0.13,0c-33.59-1.24-56.41-7.02-69.64-11.65c-14.88-5.2-21.5-10.17-21.48-10.85
							c0-0.07,0.06-0.14,0.18-0.14c1.62,0.02,3.25,0.24,4.99,0.66c1.03,0.25,2.04,0.16,3.37-0.3c1.98-0.69,3.46-2.09,4.89-3.43
							c0.76-0.72,1.55-1.46,2.41-2.1c-0.34-0.29-0.7-0.28-1.08-0.26c-0.15,0.01-0.31,0.01-0.46,0c-0.73-0.07-0.77-0.56-0.8-0.99
							c-0.01-0.12-0.02-0.24-0.04-0.34l0-0.02l0.01-0.02c0.33-0.97,1.13-1.32,1.91-1.65c0.47-0.21,0.96-0.42,1.32-0.77l0.02-0.02
							l0.03,0c0.08-0.01,0.15,0,0.23-0.01c0.16,0,0.31,0,0.44-0.05c1.36-0.53,2.76-1.1,4.26-1.72c0.04-0.02,0.08-0.03,0.12-0.05
							c0.27-0.12,0.58-0.26,0.88-0.03c0.33,0.25,0.18,0.58,0.08,0.81c-0.03,0.06-0.05,0.12-0.07,0.18c-0.1,0.32-0.25,0.62-0.39,0.92
							c-0.08,0.17-0.16,0.33-0.23,0.5c-0.01,0.03-0.03,0.06-0.04,0.09c-0.12,0.27-0.18,0.44,0.01,0.56c0.07,0.04,0.25-0.02,0.4-0.06
							c0.06-0.02,0.12-0.04,0.17-0.05c0.81-0.21,1.52-0.65,2.21-1.08c0.44-0.27,0.89-0.55,1.37-0.78l0.14-0.07
							c0.79-0.37,1.15-0.54,1.39-0.4c0.25,0.15,0.27,0.57,0.33,1.5l0.01,0.12c0.02,0.34,0.04,0.67,0.3,0.9
							c0.24,0.22,0.64,0.41,0.86,0.34c0.08-0.03,0.13-0.09,0.15-0.2c0.16-0.96,0.79-1.18,1.4-1.4c0.17-0.06,0.35-0.12,0.51-0.2
							c0.82-0.39,1.64-0.78,2.46-1.17c1.05-0.5,2.14-1.01,3.21-1.53c0.32-0.16,0.64-0.37,0.56-0.75c-0.08-0.39-0.43-0.42-0.82-0.41
							c-0.23,0-0.45-0.01-0.68-0.01c-0.6-0.02-1.22-0.04-1.81,0.12c-0.06,0.02-0.12,0.04-0.18,0.06c-0.31,0.1-0.69,0.23-0.92-0.17
							c-0.22-0.38,0.07-0.72,0.3-0.99c0.03-0.03,0.05-0.06,0.08-0.09l0.04-0.05c0.37-0.45,0.73-0.88,0.81-1.47l0-0.02l0.01-0.02
							c0.62-0.79,1.24-1.6,1.84-2.39c0.64-0.84,1.3-1.71,1.96-2.55l0.1-0.13c0.72-0.91,1.41-1.78,1.69-2.94
							c0.21-0.85,0.87-1.48,1.42-1.94c1.03-0.84,1.96-1.81,2.87-2.75c0.33-0.34,0.67-0.7,1.02-1.04l0.02-0.02l0.03,0
							c1.44-0.27,2.85-0.73,4.22-1.18c0.83-0.27,1.69-0.55,2.55-0.79l0.05-0.01l0.03,0.04c0.04,0.05,0.07,0.11,0.11,0.17
							c0.04,0.06,0.11,0.17,0.14,0.2c0.53-0.11,1.09-0.12,1.63-0.12c1.14,0,2.22,0,3.09-0.97l0.03-0.03l1.41,0.19
							c0.14,0.03,0.3,0.07,0.33,0.07l0.1,0.01c0.86,0.05,1.52,0.21,2.11,0.49l0.03,0.02l0.01,0.04c0.04,0.13,0.24,0.13,0.5,0.11
							c0.12-0.01,0.21-0.02,0.29,0l0.17,0.03l-0.13,0.11c-0.73,0.63-1.61,0.57-2.39,0.53c-0.26-0.02-0.5-0.03-0.73-0.02
							c-2.56,0.12-5.1,0.7-7.55,1.74c-1.16,0.49-2.34,1.1-3.51,1.81c-0.07,0.04-0.19,0.11-0.35,0.19c-0.4,0.21-1.23,0.64-1.28,0.86
							c-0.08,0.32,0.4,0.48,0.91,0.62c0.11,0.03,0.2,0.05,0.27,0.08c0.76,0.28,1.38,0.54,1.92,1.15c0.22,0.25,0.4,0.52,0.58,0.78
							c0.26,0.39,0.54,0.79,0.9,1.09c0.29,0.24,0.65,0.43,0.99,0.6c0.5,0.26,1.02,0.53,1.36,0.99c0.48,0.65,0.57,1.42,0.65,2.16
							c0.03,0.29,0.07,0.59,0.13,0.88c0.17,0.86,0.61,1.64,1.03,2.38c0.14,0.24,0.28,0.49,0.41,0.75c0.64,1.24,1.06,2.57,1.24,3.95
							c0.02,0.12,0.03,0.24-0.01,0.35c-0.04,0.14-0.14,0.24-0.29,0.3c-0.21,0.09-0.51,0.06-0.74-0.06
							c-0.45-0.24-0.86-0.54-1.27-0.83c-0.47-0.34-0.97-0.7-1.5-0.94c-0.28-0.13-0.59-0.23-0.89-0.33
							c-0.47-0.15-0.95-0.31-1.36-0.59c-0.59-0.4-0.96-1.01-1.33-1.59c-0.09-0.14-0.17-0.28-0.26-0.42
							c-0.19-0.29-0.36-0.62-0.52-0.94c-0.32-0.62-0.65-1.26-1.15-1.69c-0.17-0.14-0.44-0.27-0.69-0.19
							c-0.23,0.07-0.38,0.28-0.53,0.5c-0.05,0.07-0.1,0.14-0.15,0.2c-0.83,1.03-2.22,0.84-3.19,0.61c-0.34-0.08-0.67-0.22-0.98-0.35
							c-0.34-0.14-0.69-0.29-1.05-0.36c-0.3-0.06-0.5-0.02-0.61,0.12c-0.24,0.31-0.06,1.14,0.5,2.28c0.42,0.86,0.94,1.93,1.79,2.58
							c0.66,0.5,1.34,0.54,2.06,0.58c0.44,0.02,0.9,0.05,1.37,0.19c0.53,0.15,0.81,0.49,1.11,0.86c0.07,0.08,0.14,0.17,0.22,0.26
							c0.51,0.58,1.09,0.98,1.87,1.29c0.2,0.08,0.41,0.17,0.62,0.25c1.87,0.76,3.98,1.61,6.01,1.6c0.78-0.01,1.66-0.42,1.82-1.18
							l0.01-0.06l0.06-0.01c1.14-0.14,3.65-0.88,3.64-2.23c-0.01-0.87-0.33-1.74-0.65-2.58c-0.21-0.56-0.43-1.14-0.55-1.72
							c-0.12-0.55-0.05-0.92,0.2-1.14c0.34-0.29,0.86-0.15,1.24,0.08c0.36,0.23,0.68,0.51,0.95,0.84c0.09,0.12,0.18,0.25,0.2,0.41
							c0.02,0.16-0.04,0.3-0.09,0.43c-0.01,0.03-0.02,0.06-0.03,0.09c-0.34,0.92,0.23,1.93,0.83,2.51l0.1,0.1
							c0.4,0.39,1.45,1.43,1.89,1.43c0,0,0.01,0,0.01,0c0.28-0.01,0.62-0.38,0.61-2.07c-0.01-1.86-0.28-3.72-0.82-5.53
							c-0.19-0.65-0.49-1.29-0.78-1.9c-0.33-0.71-0.67-1.44-0.86-2.21c-0.09-0.36-0.14-0.74-0.18-1.1c-0.07-0.57-0.14-1.17-0.39-1.7
							c-0.05-0.1-0.13-0.26-0.23-0.45c-0.59-1.11-1.83-3.43-0.85-4.01c0.27-0.16,0.49-0.21,0.7-0.16c0.33,0.09,0.52,0.42,0.72,0.77
							c0.03,0.06,0.06,0.11,0.1,0.17c0.19,0.32,0.49,0.5,0.8,0.69c0.23,0.14,0.46,0.28,0.65,0.47c0.51,0.52,0.91,1.19,1.3,1.83
							c0.63,1.04,1.28,2.12,2.37,2.61c0.38,0.17,0.78,0.29,1.17,0.4c0.17,0.05,0.35,0.1,0.52,0.16c0.45,0.15,0.82,0.42,1.18,0.7
							c0.28,0.21,0.57,0.44,0.9,0.59c0.82,0.37,1.74,0.57,2.63,0.77c0.78,0.17,1.59,0.35,2.34,0.64c1.88,0.73,3.52,2.35,4.97,3.78
							c0.23,0.23,0.45,0.45,0.67,0.66c0.31,0.3,0.6,0.6,0.75,0.98c0.39,0.99-0.15,2.19-0.58,3.14c-0.09,0.21-0.18,0.4-0.26,0.59
							c-0.49,1.2-1.06,2.39-1.61,3.54l-0.2,0.41c-0.1,0.2-0.2,0.48-0.32,0.81c-0.38,1.03-0.89,2.44-1.74,2.62
							c-0.63,0.13-1.48-0.32-2.23-0.73c-0.31-0.17-0.61-0.32-0.84-0.41c-1.97-0.75-3.82-0.17-5.78,0.44
							c-1.4,0.44-2.85,0.89-4.38,0.83c-0.59-0.02-0.7,0.21-0.83,0.51c-0.13,0.29-0.29,0.65-0.93,0.79
							c-0.35,0.08-0.79-0.02-1.22-0.12c-0.53-0.12-1.08-0.24-1.4-0.02c0.07,0.11,0.09,0.21,0.1,0.3c0.02,0.13,0.03,0.17,0.16,0.19
							c0.14,0.02,0.27,0.04,0.41,0.05c1.14,0.14,2.32,0.29,2.75,1.47c0.1,0.27-0.16,0.57-0.39,0.84c-0.14,0.17-0.31,0.36-0.29,0.45
							c0,0.01,0.01,0.03,0.05,0.06c0.39,0.23,0.94,0.08,1.38-0.08c0.18-0.06,0.32-0.22,0.46-0.37c0.19-0.22,0.39-0.44,0.71-0.41
							c0.38,0.03,0.65,0.13,0.93,0.23c0.19,0.07,0.38,0.14,0.61,0.19c1.24,0.28,1.94-0.07,2.76-0.49c0.53-0.27,1.09-0.55,1.84-0.68
							c0.49-0.08,2.17-0.16,3.05,2.27c0.29,0.82,0.88,1.18,1.64,1.02c3.91-0.82,5.01-2.33,5.61-3.83l0.02-0.05l0.06,0
							c0.32,0,0.64-0.01,0.96-0.02c0.7-0.01,1.42-0.03,2.12-0.01c2.16,0.05,3.11,1.01,3.16,3.21c0.03,1.01,0.04,1.52,0.27,1.7
							c0.22,0.18,0.73,0.09,1.64-0.08c0.46-0.08,0.99-0.18,1.53-0.23c1.44-0.15,2.77,0.24,3.75,1.1c0.95,0.83,1.49,2.02,1.53,3.35
							c0.02,0.83-0.18,1.6-0.38,2.34c-0.08,0.31-0.17,0.63-0.24,0.96l-0.01,0.03l-0.02,0.02c-2.16,1.72-3.66,4.04-5.12,6.28
							l-0.1,0.16c-0.03,0.05-0.06,0.09-0.09,0.14c-0.21,0.34-0.45,0.72-0.85,0.75c-0.49,0.03-0.79,0.15-0.92,0.34
							c-0.17,0.27-0.02,0.7,0.14,1.11c0.04,0.09,0.07,0.19,0.1,0.28c0.11,0.31,0.26,0.62,0.42,0.91c0.12,0.24,0.25,0.48,0.35,0.73
							L373.61,2678.97z M282.52,2656.49c0.15,0.56,6.33,5.41,21.38,10.68c13.2,4.62,35.96,10.38,69.45,11.63
							c-0.09-0.2-0.19-0.4-0.29-0.6c-0.15-0.3-0.31-0.61-0.43-0.94c-0.03-0.09-0.06-0.18-0.1-0.27c-0.17-0.45-0.34-0.91-0.12-1.25
							c0.16-0.25,0.5-0.38,1.04-0.42c0.31-0.02,0.52-0.35,0.72-0.67c0.03-0.05,0.06-0.1,0.09-0.15l0.1-0.16
							c1.46-2.25,2.96-4.57,5.13-6.3c0.07-0.32,0.15-0.63,0.24-0.93c0.2-0.73,0.4-1.49,0.38-2.3c-0.04-1.29-0.56-2.44-1.47-3.23
							c-0.95-0.83-2.24-1.21-3.63-1.06c-0.54,0.06-1.06,0.15-1.51,0.23c-1,0.18-1.5,0.27-1.78,0.05c-0.29-0.23-0.3-0.77-0.33-1.83
							c-0.05-2.11-0.92-3-3-3.05c-0.7-0.02-1.42,0-2.12,0.01c-0.3,0.01-0.61,0.01-0.91,0.01c-1.06,2.58-3.37,3.39-5.71,3.88
							c-0.84,0.18-1.51-0.23-1.83-1.12c-0.21-0.58-1.04-2.46-2.86-2.16c-0.73,0.12-1.27,0.39-1.79,0.66
							c-0.81,0.41-1.58,0.8-2.87,0.5c-0.25-0.06-0.44-0.13-0.63-0.2c-0.27-0.1-0.53-0.19-0.89-0.22c-0.23-0.02-0.39,0.16-0.57,0.36
							c-0.15,0.17-0.31,0.34-0.53,0.42c-0.47,0.17-1.06,0.32-1.52,0.07c-0.08-0.04-0.12-0.1-0.13-0.17
							c-0.03-0.17,0.14-0.37,0.33-0.59c0.2-0.23,0.42-0.49,0.36-0.68c-0.39-1.09-1.47-1.22-2.61-1.37
							c-0.14-0.02-0.28-0.03-0.41-0.05c-0.26-0.04-0.28-0.2-0.3-0.33c-0.01-0.07-0.02-0.15-0.07-0.22c-0.15,0.1-0.27,0.05-0.34-0.02
							c-0.09-0.1-0.1-0.24-0.02-0.32c0.06-0.06,0.18-0.08,0.39,0.11c0.38-0.27,0.97-0.14,1.55-0.01c0.41,0.09,0.84,0.18,1.15,0.12
							c0.56-0.12,0.69-0.41,0.81-0.69c0.14-0.31,0.29-0.64,0.98-0.61c1.49,0.06,2.93-0.39,4.32-0.83c1.99-0.62,3.87-1.21,5.89-0.43
							c0.25,0.09,0.54,0.25,0.86,0.42c0.73,0.39,1.55,0.83,2.12,0.71c0.76-0.16,1.26-1.52,1.62-2.51c0.12-0.33,0.23-0.61,0.33-0.83
							l0.2-0.41c0.55-1.15,1.12-2.33,1.61-3.53c0.08-0.19,0.17-0.38,0.26-0.59c0.44-0.98,0.95-2.08,0.58-3.01
							c-0.14-0.35-0.4-0.62-0.71-0.92c-0.22-0.21-0.45-0.44-0.67-0.66c-1.44-1.42-3.07-3.03-4.91-3.75
							c-0.74-0.29-1.54-0.46-2.32-0.63c-0.9-0.2-1.83-0.4-2.67-0.78c-0.35-0.16-0.65-0.38-0.94-0.6c-0.36-0.27-0.7-0.53-1.13-0.67
							c-0.17-0.05-0.34-0.11-0.52-0.16c-0.4-0.12-0.81-0.24-1.19-0.41c-1.14-0.51-1.8-1.61-2.45-2.67c-0.39-0.64-0.79-1.3-1.28-1.8
							c-0.17-0.18-0.39-0.31-0.62-0.44c-0.32-0.19-0.65-0.39-0.86-0.74c-0.03-0.06-0.07-0.11-0.1-0.17
							c-0.18-0.32-0.36-0.63-0.62-0.7c-0.16-0.04-0.34,0-0.57,0.14c-0.84,0.5,0.39,2.81,0.92,3.79c0.11,0.2,0.19,0.36,0.24,0.46
							c0.26,0.55,0.33,1.16,0.4,1.75c0.04,0.36,0.09,0.73,0.18,1.08c0.19,0.76,0.53,1.48,0.85,2.19c0.29,0.62,0.59,1.26,0.78,1.93
							c0.54,1.82,0.82,3.7,0.83,5.58c0,0.55,0.01,2.21-0.77,2.24c-0.44,0.02-1.27-0.74-2.02-1.48l-0.1-0.1
							c-0.63-0.61-1.24-1.69-0.87-2.68c0.01-0.03,0.02-0.06,0.04-0.09c0.05-0.12,0.1-0.24,0.08-0.35c-0.01-0.12-0.09-0.22-0.17-0.32
							c-0.25-0.32-0.56-0.59-0.91-0.81c-0.33-0.2-0.77-0.33-1.04-0.1c-0.2,0.17-0.25,0.49-0.15,0.98c0.12,0.56,0.33,1.14,0.54,1.69
							c0.32,0.85,0.66,1.74,0.66,2.64c0.01,1.45-2.47,2.22-3.73,2.39c-0.2,0.82-1.13,1.25-1.96,1.26c-2.05,0.01-4.19-0.85-6.07-1.61
							c-0.21-0.08-0.42-0.17-0.62-0.25c-0.79-0.32-1.41-0.74-1.93-1.34c-0.08-0.09-0.15-0.17-0.22-0.26
							c-0.29-0.36-0.55-0.66-1.03-0.8c-0.45-0.13-0.9-0.16-1.33-0.18c-0.75-0.04-1.46-0.08-2.15-0.61
							c-0.88-0.67-1.41-1.76-1.84-2.64c-0.42-0.86-0.84-1.99-0.48-2.46c0.15-0.19,0.41-0.25,0.78-0.18
							c0.38,0.08,0.74,0.23,1.08,0.37c0.31,0.13,0.63,0.26,0.96,0.34c0.93,0.22,2.25,0.4,3.02-0.55c0.05-0.06,0.1-0.13,0.15-0.2
							c0.17-0.23,0.34-0.47,0.62-0.56c0.27-0.08,0.59,0,0.85,0.22c0.53,0.45,0.87,1.11,1.19,1.74c0.17,0.33,0.33,0.64,0.52,0.93
							c0.09,0.14,0.18,0.28,0.27,0.42c0.35,0.57,0.72,1.16,1.28,1.54c0.39,0.27,0.86,0.42,1.32,0.57c0.3,0.1,0.62,0.2,0.91,0.34
							c0.55,0.25,1.04,0.61,1.52,0.96c0.4,0.29,0.81,0.59,1.25,0.82c0.18,0.1,0.43,0.12,0.6,0.05c0.1-0.04,0.16-0.11,0.19-0.2
							c0.03-0.08,0.02-0.18,0-0.28c-0.18-1.36-0.59-2.67-1.23-3.89c-0.13-0.25-0.27-0.5-0.41-0.74c-0.43-0.76-0.87-1.54-1.05-2.43
							c-0.06-0.29-0.09-0.6-0.13-0.89c-0.08-0.72-0.17-1.47-0.62-2.08c-0.32-0.43-0.8-0.68-1.3-0.94c-0.35-0.18-0.71-0.37-1.02-0.62
							c-0.39-0.32-0.67-0.73-0.94-1.13c-0.18-0.26-0.36-0.53-0.57-0.77c-0.51-0.58-1.12-0.84-1.86-1.11
							c-0.06-0.02-0.15-0.05-0.26-0.08c-0.46-0.12-1.16-0.31-1.02-0.82c0.07-0.27,0.69-0.61,1.37-0.96
							c0.14-0.08,0.27-0.14,0.34-0.18c1.17-0.71,2.36-1.33,3.53-1.82c2.47-1.04,5.03-1.63,7.61-1.75c0.24-0.01,0.48,0,0.75,0.02
							c0.68,0.04,1.44,0.09,2.08-0.34c-0.01,0-0.01,0-0.02,0c-0.24,0.02-0.56,0.04-0.66-0.19c-0.58-0.27-1.24-0.41-2.11-0.46
							c-0.06,0-0.28-0.06-0.35-0.07l-1.31-0.18c-0.92,0.99-2.07,0.99-3.19,0.99c-0.54,0-1.1,0-1.61,0.12
							c-0.11,0.02-0.19-0.1-0.3-0.28c-0.02-0.04-0.05-0.08-0.07-0.11c-0.84,0.23-1.68,0.51-2.49,0.77
							c-1.36,0.45-2.77,0.91-4.21,1.18c-0.34,0.34-0.67,0.69-1,1.02c-0.91,0.94-1.85,1.91-2.88,2.76c-0.53,0.44-1.17,1.04-1.36,1.85
							c-0.29,1.19-1.02,2.11-1.72,3l-0.1,0.13c-0.66,0.84-1.32,1.71-1.96,2.55c-0.59,0.78-1.21,1.59-1.83,2.38
							c-0.09,0.62-0.47,1.08-0.84,1.53l-0.04,0.05c-0.03,0.03-0.05,0.06-0.08,0.09c-0.21,0.25-0.44,0.52-0.28,0.8
							c0.15,0.27,0.37,0.21,0.72,0.1c0.06-0.02,0.13-0.04,0.19-0.06c0.62-0.17,1.25-0.15,1.86-0.13c0.22,0.01,0.45,0.01,0.67,0.01
							c0.32,0,0.87-0.01,0.98,0.55c0.12,0.56-0.44,0.83-0.65,0.94c-1.07,0.52-2.16,1.04-3.21,1.54c-0.82,0.39-1.64,0.78-2.46,1.17
							c-0.17,0.08-0.36,0.15-0.53,0.21c-0.59,0.21-1.15,0.4-1.29,1.27c-0.03,0.21-0.16,0.3-0.26,0.33c-0.29,0.1-0.75-0.11-1.03-0.37
							c-0.31-0.28-0.33-0.65-0.35-1.02l-0.01-0.12c-0.05-0.82-0.08-1.27-0.25-1.37c-0.17-0.1-0.57,0.09-1.24,0.4l-0.14,0.07
							c-0.47,0.22-0.92,0.5-1.35,0.77c-0.7,0.44-1.42,0.89-2.26,1.1c-0.05,0.01-0.11,0.03-0.16,0.05c-0.2,0.06-0.41,0.13-0.54,0.05
							c-0.33-0.21-0.18-0.53-0.07-0.77c0.01-0.03,0.03-0.06,0.04-0.09c0.07-0.17,0.15-0.34,0.23-0.5c0.14-0.29,0.28-0.59,0.38-0.9
							c0.02-0.06,0.05-0.13,0.08-0.19c0.12-0.27,0.18-0.45-0.03-0.61c-0.22-0.17-0.45-0.07-0.71,0.05
							c-0.04,0.02-0.08,0.03-0.12,0.05c-1.51,0.63-2.9,1.19-4.27,1.72c-0.16,0.06-0.33,0.06-0.5,0.06c-0.06,0-0.13,0-0.19,0
							c-0.38,0.36-0.87,0.57-1.35,0.78c-0.77,0.33-1.49,0.65-1.81,1.54c0.02,0.11,0.03,0.23,0.04,0.34
							c0.03,0.43,0.06,0.78,0.65,0.83c0.14,0.01,0.28,0.01,0.43,0c0.41-0.02,0.87-0.04,1.28,0.38l0.07,0.07l-0.08,0.06
							c-0.88,0.64-1.69,1.41-2.48,2.14c-1.44,1.36-2.94,2.77-4.95,3.47c-1.34,0.47-2.41,0.56-3.46,0.31
							C285.76,2656.73,284.14,2656.51,282.52,2656.49C282.53,2656.49,282.52,2656.49,282.52,2656.49z M343.07,2654.5
							C343.07,2654.5,343.07,2654.5,343.07,2654.5c0,0.01,0.01,0.06,0.05,0.09c0.03,0.02,0.06,0.02,0.1,0
							C343.12,2654.5,343.07,2654.5,343.07,2654.5z"/>
					</g>
					<g>
						<path d="M405.72,2631.41c-2.01-1.33-3.78-3.02-5.95-4.1c0.39-0.78,0.82-1.64-0.38-2.04c-1.18-0.39-2.42-0.61-3.65-0.86
							c-0.74-0.15-1.01,0.27-0.96,0.97c0.09,1.19,0.18,2.38,0.25,3.57c0.02,0.41,0.12,0.73,0.59,0.8c-1.81,1.2-4.78,0.77-5.76-1.01
							c-0.81-1.47-1.93-1.43-3.21-1.1c-1.87,0.48-3.54,1.42-5.08,2.55c-0.89,0.65-1.64,0.67-2.54,0.09
							c-0.5-0.32-1.08-0.56-1.66-0.73c-0.42-0.13-0.98,0.08-1.1,0.44c-0.7,2.09-1.77,1.03-2.82,0.4c-0.77-1.72-0.25-3.25-0.71-4.83
							c-0.13-0.45-0.39-0.87-0.96-0.88c-0.57-0.01-0.8,0.4-1.01,0.83c-0.55,1.15-0.42,2.37-0.42,3.58
							c-0.78-0.25-1.56-0.49-2.25-0.96c-0.27-0.88-0.04-1.44-0.07-2.14c-0.03-0.73-0.47-1.7-1.33-1.72
							c-0.92-0.02-0.89,1.03-1.11,1.7c-0.1,0.3-0.1,1.24-0.15,1.56c-0.86,0.09-1.72-0.42-2.59-0.33c-0.12-0.77,0.05-1.59-0.39-2.31
							c-0.28-0.44-1.32-0.86-1.26-0.21c0.07,0.68-0.6,0.82-0.58,1.8c0.02,1.01,0.49,0.85,1,0.99c-1.46,0.38-2,0.75-3.5,0.68
							c-1.14-1.08-0.11-0.85-0.11-1.48c0-1.91,0.05-3.83,0.08-5.74c0.32-0.08,0.63-0.16,0.95-0.24l3.17,0.33l2.26,0.29
							c0,0,2.23,0.19,3.35,0.27c0.63,0.08,1.25,0.16,1.88,0.24l3.48,0.32c0.51-0.02,1.03-0.04,1.54-0.05c0,0,2.25,0.22,3.37,0.33
							c0,0,2.12,0.15,3.17,0.28c1.01,0.13,1.15-0.38,1.09-1.23c-0.22-2.78-0.05-5.66-2.2-7.91c0.14-0.71-0.1-1.38-0.32-2.02
							c-1.07-3.2-1.09-3.19-4.11-3.13c-0.01-2.88,0.03-5.76-0.05-8.63c-0.03-0.97,0.24-1.59,1.19-1.84
							c0.84-0.21,1.67-0.43,2.51-0.64c0.39-0.1,0.75-0.02,1-0.42c0.15-0.24,0.12-2.18,0.37-2.22c0.48-0.12,0.96-0.22,1.46-0.27
							c0.28-0.03,1.06-0.16,1.32,0.01l0.01,0c-0.06,5.47-0.09,10.93-0.2,16.4c-0.02,1.07,0.3,1.52,1.37,1.61
							c1.58,0.14,3.14,0.44,4.72,0.61c3.48,0.38,6.91,1.07,10.42,1.23c0.49,0.33,1.06,0.32,1.6,0.27c1.31-0.13,2.09,0.43,2.4,1.69
							c-0.68,0.16-0.63,0.7-0.63,1.22c0,1.39-0.01,2.78-0.01,4.17c0,1.7,0.28,1.97,1.97,1.6c1.46-0.31,2.91-0.55,4.4-0.52
							c0.21,3.27,0.42,6.54,0.64,9.81c-0.97,0.28-1.87-0.26-2.82-0.23C407.11,2631.44,406.48,2631.3,405.72,2631.41z
							 M395.63,2620.11c0.15,0.59-0.4,1.55,0.51,1.9c0.8,0.3,1.8,0.4,2.57,0c0.68-0.35-0.01-1.09,0.04-1.66
							c0.03-0.37-0.01-0.74,0-1.11l-0.92-0.35C396.79,2618.6,395.63,2617.83,395.63,2620.11z M404.87,2627.37
							C404.87,2627.37,404.87,2627.37,404.87,2627.37c-0.01,0.42,0.01,0.84-0.01,1.26c-0.02,0.62,0.08,1.1,0.88,1.11
							c0.75,0,1.14-0.3,1.14-1.07c0-0.84-0.01-1.68,0.01-2.52c0.02-0.76-0.42-0.93-1.07-0.94c-0.63-0.01-0.97,0.24-0.95,0.9
							C404.88,2626.53,404.87,2626.95,404.87,2627.37z"/>
						<path d="M409.63,2632.73c-0.38,0-0.75-0.08-1.11-0.16c-0.38-0.08-0.74-0.16-1.11-0.15l-0.12,0l-0.04-0.12
							c-0.22-0.64-0.67-0.86-1.5-0.74l-0.06,0.01l-0.05-0.03c-0.75-0.5-1.48-1.06-2.19-1.59c-1.18-0.9-2.39-1.82-3.75-2.5
							l-0.15-0.07l0.08-0.15c0.23-0.46,0.5-0.98,0.35-1.34c-0.08-0.2-0.29-0.35-0.63-0.46c-1-0.33-2.07-0.54-3.1-0.75
							c-0.18-0.04-0.35-0.07-0.53-0.11c-0.27-0.05-0.47-0.03-0.59,0.08c-0.17,0.15-0.19,0.47-0.17,0.71l0.02,0.32
							c0.08,1.07,0.16,2.17,0.23,3.26c0.03,0.44,0.13,0.6,0.45,0.65l0.42,0.07l-0.36,0.24c-0.99,0.66-2.4,0.87-3.67,0.56
							c-1.06-0.26-1.89-0.84-2.32-1.63c-0.72-1.31-1.69-1.36-3.03-1.02c-1.64,0.42-3.28,1.24-5.03,2.52
							c-0.93,0.68-1.77,0.71-2.73,0.1c-0.43-0.28-0.98-0.52-1.61-0.71c-0.35-0.1-0.81,0.07-0.89,0.33c-0.24,0.73-0.56,1.16-0.96,1.3
							c-0.56,0.19-1.15-0.19-1.72-0.56c-0.13-0.08-0.25-0.16-0.38-0.24l-0.04-0.03l-0.02-0.05c-0.45-1.01-0.47-1.95-0.5-2.86
							c-0.02-0.68-0.03-1.33-0.22-1.99c-0.15-0.51-0.4-0.75-0.81-0.76c-0.01,0-0.02,0-0.02,0c-0.44,0-0.62,0.31-0.83,0.74
							c-0.45,0.93-0.43,1.9-0.41,2.93c0,0.19,0.01,0.39,0.01,0.58l0,0.23l-0.33-0.1c-0.74-0.24-1.51-0.48-2.19-0.94l-0.05-0.03
							l-0.02-0.06c-0.19-0.61-0.15-1.07-0.11-1.52c0.02-0.22,0.04-0.44,0.03-0.67c-0.03-0.64-0.41-1.55-1.16-1.57
							c-0.54-0.01-0.67,0.4-0.82,1.08c-0.04,0.17-0.07,0.34-0.13,0.5c-0.06,0.17-0.08,0.61-0.1,0.96c-0.01,0.24-0.03,0.44-0.04,0.57
							l-0.02,0.13l-0.13,0.01c-0.46,0.05-0.91-0.06-1.35-0.17c-0.43-0.11-0.83-0.2-1.24-0.16l-0.16,0.02l-0.02-0.16
							c-0.04-0.25-0.05-0.51-0.06-0.77c-0.02-0.53-0.03-1.03-0.32-1.48c-0.18-0.29-0.71-0.53-0.9-0.46
							c-0.02,0.01-0.06,0.02-0.05,0.14c0.04,0.37-0.12,0.61-0.27,0.85c-0.16,0.24-0.32,0.49-0.31,0.97c0.02,0.68,0.2,0.71,0.58,0.77
							c0.09,0.01,0.19,0.03,0.3,0.06l0.61,0.16l-0.61,0.16c-0.39,0.1-0.73,0.21-1.02,0.3c-0.82,0.26-1.41,0.44-2.53,0.38l-0.06,0
							l-0.04-0.04c-0.42-0.4-0.59-0.66-0.59-0.88c0-0.19,0.13-0.31,0.23-0.4c0.12-0.1,0.19-0.18,0.19-0.32
							c0-1.38,0.03-2.78,0.05-4.13c0.01-0.54,0.02-1.07,0.03-1.61l0-0.13l1.1-0.27l3.2,0.33l2.27,0.29c0.02,0,2.24,0.19,3.34,0.27
							l1.89,0.24l3.46,0.32l1.54-0.05l0.01,0c0,0,2.25,0.22,3.37,0.33c0.02,0,2.13,0.15,3.18,0.29c0.36,0.05,0.61,0.01,0.74-0.12
							c0.15-0.14,0.2-0.43,0.16-0.93c-0.03-0.4-0.06-0.8-0.08-1.2c-0.14-2.43-0.28-4.73-2.08-6.61l-0.06-0.06l0.02-0.08
							c0.13-0.63-0.08-1.24-0.28-1.83l-0.03-0.1c-0.51-1.51-0.78-2.34-1.26-2.71c-0.47-0.36-1.2-0.34-2.67-0.31l-0.17,0l-0.02-0.17
							c0-0.81,0-1.62,0-2.43c0-2.03,0.01-4.14-0.05-6.2c-0.02-0.81,0.12-1.69,1.31-2l2.51-0.64c0.11-0.03,0.22-0.04,0.32-0.06
							c0.26-0.04,0.44-0.06,0.58-0.29c0.04-0.08,0.08-0.53,0.1-0.85c0.08-1.04,0.13-1.4,0.38-1.44c0.55-0.14,1.02-0.23,1.46-0.27
							c0.04,0,0.08-0.01,0.13-0.02c0.52-0.06,1.04-0.11,1.29,0.05l0.08,0.05l0,0.09c-0.02,1.42-0.03,2.83-0.04,4.25
							c-0.04,3.98-0.08,8.1-0.16,12.15c-0.02,1.01,0.28,1.36,1.22,1.45c0.87,0.08,1.75,0.21,2.6,0.33c0.69,0.1,1.41,0.21,2.12,0.28
							c1.28,0.14,2.58,0.33,3.84,0.51c2.14,0.31,4.36,0.62,6.57,0.72l0.05,0l0.04,0.03c0.46,0.31,1.01,0.29,1.49,0.24
							c1.38-0.14,2.25,0.47,2.58,1.81l0.04,0.16l-0.16,0.04c-0.48,0.11-0.5,0.42-0.5,0.94l0,0.11c0,0.59,0,1.19,0,1.78
							c0,0.8,0,1.59,0,2.39c0,0.75,0.05,1.25,0.3,1.45c0.22,0.17,0.63,0.17,1.47-0.01c1.29-0.28,2.82-0.55,4.44-0.52l0.15,0
							l0.66,10.09l-0.13,0.04C410.04,2632.71,409.83,2632.73,409.63,2632.73z M407.51,2632.09c0.37,0,0.73,0.08,1.08,0.16
							c0.5,0.11,0.97,0.21,1.45,0.11l-0.62-9.52c-1.53-0.01-2.98,0.25-4.21,0.52c-0.82,0.18-1.4,0.23-1.75-0.06
							c-0.35-0.28-0.42-0.84-0.42-1.71c0-0.8,0-1.6,0-2.39c0-0.59,0-1.19,0-1.78l0-0.11c0-0.45-0.01-1,0.59-1.22
							c-0.32-1.08-1.02-1.52-2.19-1.41c-0.56,0.06-1.15,0.06-1.67-0.27c-2.2-0.1-4.41-0.42-6.55-0.72c-1.25-0.18-2.55-0.36-3.83-0.5
							c-0.71-0.08-1.43-0.18-2.13-0.28c-0.85-0.12-1.72-0.25-2.59-0.33c-1.12-0.1-1.54-0.6-1.52-1.78
							c0.08-4.05,0.12-8.17,0.16-12.15c0.01-1.38,0.03-2.76,0.04-4.14c-0.24-0.07-0.78,0-1,0.03c-0.05,0.01-0.1,0.01-0.14,0.02
							c-0.42,0.05-0.87,0.13-1.4,0.26c-0.07,0.15-0.12,0.77-0.15,1.15c-0.05,0.62-0.07,0.88-0.15,1c-0.22,0.36-0.54,0.41-0.82,0.45
							c-0.09,0.01-0.19,0.03-0.28,0.05l-2.51,0.64c-0.8,0.21-1.09,0.67-1.07,1.67c0.05,2.07,0.05,4.17,0.05,6.21
							c0,0.75,0,1.51,0,2.26c2.94-0.06,3.04,0.06,4.1,3.25l0.03,0.1c0.2,0.61,0.42,1.24,0.3,1.92c1.83,1.96,1.97,4.4,2.11,6.76
							c0.02,0.39,0.05,0.8,0.08,1.19c0.03,0.43,0.03,0.92-0.26,1.2c-0.21,0.2-0.54,0.27-1.01,0.21c-1.04-0.13-3.14-0.28-3.16-0.28
							c-1.08-0.11-3.19-0.31-3.36-0.33l-1.54,0.05l-3.49-0.32l-1.89-0.24c-1.1-0.08-3.32-0.27-3.34-0.27l-2.27-0.29l-3.14-0.33
							l-0.8,0.2c-0.01,0.49-0.02,0.99-0.03,1.48c-0.03,1.35-0.05,2.75-0.05,4.13c0,0.3-0.17,0.45-0.3,0.57
							c-0.09,0.08-0.12,0.11-0.12,0.16c0,0.06,0.06,0.22,0.44,0.59c1.02,0.04,1.58-0.13,2.36-0.37c0.12-0.04,0.25-0.08,0.39-0.12
							c-0.26-0.11-0.47-0.35-0.48-1c-0.01-0.57,0.19-0.9,0.36-1.15c0.14-0.22,0.24-0.37,0.22-0.63c-0.03-0.35,0.18-0.46,0.27-0.49
							c0.38-0.13,1.06,0.23,1.29,0.6c0.33,0.52,0.35,1.09,0.37,1.64c0.01,0.2,0.01,0.39,0.03,0.58c0.41-0.01,0.8,0.09,1.19,0.18
							c0.38,0.09,0.74,0.19,1.1,0.17c0.01-0.11,0.02-0.26,0.03-0.41c0.02-0.41,0.05-0.84,0.12-1.05c0.05-0.14,0.08-0.3,0.12-0.47
							c0.13-0.61,0.3-1.37,1.15-1.35c0.97,0.03,1.45,1.12,1.49,1.88c0.01,0.25-0.01,0.48-0.03,0.71c-0.04,0.42-0.07,0.81,0.08,1.34
							c0.59,0.39,1.27,0.62,1.94,0.83c0-0.12,0-0.23-0.01-0.35c-0.02-1.02-0.04-2.08,0.44-3.08c0.19-0.4,0.45-0.94,1.16-0.93
							c0.55,0.01,0.93,0.35,1.12,1c0.2,0.7,0.22,1.4,0.23,2.07c0.02,0.86,0.04,1.76,0.45,2.69c0.11,0.07,0.23,0.14,0.34,0.22
							c0.53,0.34,1.03,0.67,1.43,0.53c0.29-0.1,0.55-0.47,0.75-1.09c0.15-0.44,0.78-0.7,1.31-0.55c0.68,0.21,1.23,0.45,1.7,0.75
							c0.84,0.54,1.52,0.52,2.35-0.09c1.78-1.3,3.46-2.15,5.14-2.58c1.17-0.3,2.49-0.48,3.4,1.18c0.39,0.71,1.14,1.23,2.11,1.47
							c1.06,0.26,2.23,0.13,3.12-0.34c-0.28-0.18-0.35-0.51-0.37-0.83c-0.06-1.08-0.15-2.19-0.23-3.25l-0.02-0.32
							c-0.03-0.46,0.06-0.79,0.28-0.98c0.2-0.18,0.5-0.23,0.87-0.16c0.18,0.04,0.35,0.07,0.53,0.11c1.04,0.21,2.12,0.42,3.14,0.76
							c0.44,0.14,0.71,0.36,0.83,0.65c0.19,0.46-0.04,0.99-0.28,1.47c1.32,0.68,2.5,1.58,3.65,2.46c0.69,0.52,1.39,1.06,2.12,1.55
							C406.65,2631.12,407.23,2631.4,407.51,2632.09z M380.8,2594.82C380.8,2594.82,380.8,2594.82,380.8,2594.82
							C380.8,2594.82,380.8,2594.82,380.8,2594.82z M405.75,2629.9C405.75,2629.9,405.75,2629.9,405.75,2629.9
							c-0.36,0-0.62-0.09-0.8-0.27c-0.25-0.26-0.27-0.64-0.26-1.01c0.01-0.29,0.01-0.58,0-0.86c0-0.13,0-0.26,0-0.39l0.01-0.17
							c0-0.08,0-0.15,0-0.23c0-0.28,0-0.58,0-0.86c-0.01-0.35,0.07-0.61,0.25-0.79c0.19-0.19,0.47-0.28,0.87-0.28
							c0.32,0,0.73,0.04,0.98,0.3c0.18,0.18,0.26,0.45,0.25,0.8c-0.02,0.65-0.01,1.31-0.01,1.94l0,0.57c0,0.41-0.1,0.71-0.31,0.91
							C406.52,2629.79,406.2,2629.9,405.75,2629.9z M405.03,2627.53c0,0.07,0,0.15,0,0.22c0,0.29,0,0.58,0,0.88
							c-0.01,0.29,0,0.6,0.16,0.77c0.11,0.11,0.29,0.17,0.55,0.17c0.36,0.01,0.61-0.07,0.76-0.22c0.14-0.14,0.21-0.36,0.21-0.68
							l0-0.57c0-0.64-0.01-1.3,0.01-1.95c0.01-0.26-0.04-0.45-0.16-0.56c-0.13-0.14-0.36-0.2-0.75-0.2c-0.3,0-0.51,0.06-0.63,0.18
							c-0.11,0.11-0.16,0.29-0.15,0.55c0.01,0.29,0.01,0.59,0,0.87c0,0.13,0,0.26,0,0.39L405.03,2627.53z M397.51,2622.44
							c-0.46,0-0.95-0.09-1.43-0.27c-0.74-0.28-0.67-0.91-0.62-1.42c0.02-0.22,0.05-0.43,0-0.59l-0.01-0.04
							c0-0.85,0.16-1.36,0.49-1.6c0.4-0.29,0.95-0.1,1.48,0.07c0.14,0.05,0.29,0.1,0.44,0.14l1.04,0.39l0,0.12
							c0,0.15,0,0.31,0.01,0.46c0.01,0.22,0.01,0.44,0,0.66c-0.01,0.17,0.06,0.38,0.12,0.58c0.13,0.39,0.31,0.92-0.25,1.22
							C398.42,2622.34,397.99,2622.44,397.51,2622.44z M395.8,2620.09c0.05,0.21,0.02,0.46,0,0.69c-0.06,0.55-0.07,0.89,0.4,1.07
							c0.89,0.33,1.8,0.33,2.44,0c0.28-0.15,0.25-0.35,0.09-0.82c-0.08-0.23-0.16-0.47-0.14-0.71c0.02-0.2,0.01-0.41,0-0.62
							c0-0.12-0.01-0.24-0.01-0.36l-0.81-0.31c-0.14-0.04-0.29-0.09-0.44-0.14c-0.47-0.16-0.92-0.31-1.18-0.12
							C395.92,2618.95,395.8,2619.39,395.8,2620.09z"/>
					</g>
					<g>
						<path d="M358.11,2620.93c0.09,0.84-0.03,1.75-0.04,2.59c-0.01,1.02-0.02,2.04-0.04,3.06l0.11,1.57
							c-1.56-0.47-4.07-1.27-5.64-1.69c-0.53-0.14-1.1-0.08-1.66-0.11c-0.06-0.28-0.12-0.56-0.18-0.84
							c-0.16-0.75-0.05-1.52-0.05-2.27c0-0.89-0.02-1.77-0.03-2.66c-0.05-2.58-0.22-5.15-0.22-7.73c0.16-1.55,0.91-2.95,1.9-4.13
							c1.21-1.45,1.08-3.28,1.59-4.99c0.77,1.78,0.73,3.98,2.75,5.03c0.14,1.18,1.53,1.75,1.46,3.03
							C358.06,2614.84,357.8,2617.89,358.11,2620.93z"/>
					</g>
					<g>
						<path d="M399.84,2614.25c-3.5-0.16-6.94-0.85-10.42-1.23c-1.58-0.17-3.14-0.47-4.72-0.61c-1.07-0.1-1.39-0.55-1.37-1.61
							c0.11-5.46,0.14-10.93,0.2-16.4c0-0.43,1.68,0.59,1.85,0.76c0.34,0.33,0.41,0.89,0.55,1.31c0.28,0.9,0.74,1,1.57,0.96
							c0.46-0.02,1.43-0.12,1.42,0.57c-0.02,3.81-0.12,7.62,0.23,11.43c0.06,0.71,0.26,1.1,1.01,1.24c2.5,0.47,4.94,1.22,7.49,1.37
							C399.08,2612.12,399.69,2612.94,399.84,2614.25z"/>
						<path d="M400.02,2614.42l-0.19-0.01c-2.22-0.1-4.44-0.42-6.6-0.73c-1.25-0.18-2.55-0.36-3.83-0.5
							c-0.71-0.08-1.43-0.18-2.13-0.28c-0.85-0.12-1.72-0.25-2.59-0.33c-1.12-0.1-1.54-0.6-1.52-1.78
							c0.08-4.05,0.12-8.17,0.16-12.15c0.01-1.42,0.03-2.83,0.04-4.25c0-0.11,0.06-0.2,0.16-0.24c0.43-0.18,1.88,0.79,1.97,0.88
							c0.29,0.29,0.4,0.71,0.5,1.08c0.03,0.1,0.05,0.21,0.08,0.3c0.25,0.81,0.62,0.88,1.41,0.85l0.1,0
							c0.39-0.02,0.98-0.05,1.29,0.25c0.13,0.13,0.2,0.29,0.2,0.49c0,0.6-0.01,1.21-0.01,1.81c-0.03,3.15-0.05,6.41,0.24,9.6
							c0.07,0.73,0.27,0.98,0.87,1.09c0.81,0.15,1.62,0.33,2.4,0.51c1.65,0.37,3.35,0.76,5.07,0.85c1.4,0.08,2.17,0.85,2.34,2.35
							L400.02,2614.42z M383.7,2594.46c-0.02,1.4-0.03,2.79-0.04,4.19c-0.04,3.98-0.08,8.1-0.16,12.15
							c-0.02,1.01,0.28,1.36,1.22,1.45c0.87,0.08,1.75,0.21,2.6,0.33c0.69,0.1,1.41,0.21,2.12,0.28c1.28,0.14,2.58,0.33,3.84,0.51
							c2.08,0.3,4.23,0.61,6.37,0.71c-0.19-1.22-0.81-1.8-2-1.87c-1.75-0.1-3.46-0.49-5.12-0.86c-0.78-0.18-1.59-0.36-2.39-0.51
							c-0.86-0.16-1.08-0.65-1.14-1.39c-0.29-3.21-0.27-6.48-0.24-9.64c0-0.6,0.01-1.21,0.01-1.81c0-0.11-0.03-0.19-0.09-0.25
							c-0.21-0.2-0.73-0.17-1.05-0.16l-0.11,0c-0.81,0.03-1.41-0.03-1.74-1.08c-0.03-0.1-0.06-0.21-0.09-0.31
							c-0.09-0.35-0.19-0.71-0.41-0.93C385.09,2595.1,384.01,2594.49,383.7,2594.46z"/>
					</g>
					<g>
						<path d="M339.52,2624.01c-1.33,1.47-3.19,0.76-4.78,1.11c-0.07,0.02-0.21-0.26-0.32-0.41c0.14-0.23,0.16-0.49-0.16-0.5
							c-1.26-0.01-2.56-0.52-3.76,0.36c-0.34,0.25-0.78,0.59-1.23,0.1c0.03-0.53,0.05-1.07,0.08-1.6c0-0.15,0-0.3,0-0.45
							c0.88-3.39,0.66-6.85,0.63-10.3c-0.01-0.94,0.68-0.66,1.19-0.71c0.71-0.08,1.18,0.07,1.17,0.94c-0.02,2.15,0.09,4.3-0.03,6.44
							c-0.07,1.39,0.4,1.87,1.73,1.65c0.36-0.06,0.75-0.05,1.12-0.03c0.85,0.05,1.14-0.35,1.13-1.18c-0.04-2.33-0.01-4.67-0.04-7.01
							c-0.01-0.8,0.06-1.3,1.11-1.28c1.01,0.02,1.28,0.38,1.25,1.33c-0.06,1.59,0.05,3.18-0.01,4.76
							C338.5,2619.56,339.11,2621.77,339.52,2624.01z"/>
						<path d="M334.72,2625.29c-0.14,0-0.23-0.14-0.34-0.32c-0.03-0.05-0.07-0.11-0.1-0.15l-0.07-0.09l0.06-0.1
							c0.08-0.13,0.08-0.21,0.07-0.22c0,0-0.02-0.02-0.1-0.02c-0.34,0-0.69-0.04-1.02-0.08c-0.93-0.1-1.81-0.2-2.64,0.41l-0.02,0.02
							c-0.33,0.24-0.89,0.65-1.43,0.06l-0.05-0.05l0.08-1.67l0.01-0.48c0.74-2.87,0.7-5.85,0.65-8.73
							c-0.01-0.51-0.02-1.01-0.02-1.52c0-0.32,0.07-0.55,0.22-0.69c0.21-0.2,0.52-0.19,0.79-0.18c0.12,0,0.23,0.01,0.33,0
							c0.34-0.04,0.77-0.04,1.06,0.22c0.21,0.19,0.3,0.48,0.3,0.89c-0.01,0.78,0,1.57,0.01,2.33c0.02,1.35,0.03,2.75-0.04,4.12
							c-0.04,0.68,0.06,1.1,0.31,1.32c0.23,0.21,0.63,0.26,1.23,0.16c0.39-0.07,0.8-0.05,1.16-0.03c0.35,0.02,0.58-0.04,0.73-0.18
							c0.16-0.15,0.23-0.42,0.22-0.83c-0.02-1.22-0.02-2.46-0.02-3.66c0-1.1,0-2.23-0.02-3.34c-0.01-0.46,0.01-0.89,0.28-1.17
							c0.2-0.2,0.52-0.29,0.99-0.29c0.55,0.01,0.9,0.12,1.12,0.35c0.22,0.23,0.31,0.58,0.29,1.14c-0.03,0.78-0.02,1.57-0.01,2.33
							c0.01,0.8,0.02,1.62-0.01,2.43c-0.07,1.77,0.29,3.52,0.63,5.21c0.1,0.5,0.2,1.01,0.3,1.51l0.01,0.08l-0.05,0.06
							c-0.94,1.04-2.13,1.05-3.28,1.05c-0.53,0-1.09,0-1.59,0.11C334.75,2625.29,334.74,2625.29,334.72,2625.29z M334.61,2624.7
							c0.02,0.03,0.04,0.06,0.05,0.08c0.03,0.05,0.07,0.11,0.1,0.15c0.52-0.11,1.07-0.11,1.6-0.11c1.1,0,2.14,0,2.98-0.88
							c-0.09-0.48-0.19-0.96-0.28-1.43c-0.35-1.71-0.71-3.48-0.64-5.29c0.03-0.8,0.02-1.62,0.01-2.41
							c-0.01-0.77-0.02-1.57,0.01-2.35c0.02-0.46-0.04-0.74-0.2-0.9c-0.15-0.16-0.44-0.24-0.89-0.25c-0.37-0.01-0.62,0.05-0.75,0.19
							c-0.17,0.16-0.19,0.45-0.19,0.93c0.02,1.12,0.02,2.25,0.02,3.35c0,1.2,0,2.44,0.02,3.66c0.01,0.51-0.09,0.85-0.32,1.08
							c-0.22,0.21-0.53,0.3-0.98,0.27c-0.34-0.02-0.73-0.03-1.08,0.03c-0.71,0.12-1.19,0.04-1.51-0.24
							c-0.33-0.29-0.46-0.79-0.42-1.58c0.07-1.36,0.06-2.75,0.04-4.09c-0.01-0.77-0.02-1.56-0.01-2.34c0-0.31-0.06-0.52-0.19-0.64
							c-0.15-0.13-0.4-0.17-0.8-0.13c-0.12,0.01-0.25,0.01-0.37,0c-0.23-0.01-0.44-0.02-0.55,0.09c-0.08,0.08-0.12,0.23-0.12,0.45
							c0,0.51,0.01,1.01,0.02,1.52c0.05,2.9,0.1,5.89-0.65,8.8l0,0.43l-0.08,1.53c0.3,0.28,0.56,0.13,0.94-0.15l0.02-0.02
							c0.93-0.69,1.92-0.57,2.87-0.47c0.33,0.04,0.66,0.07,0.99,0.08c0.23,0,0.34,0.1,0.38,0.19
							C334.71,2624.37,334.7,2624.53,334.61,2624.7z"/>
					</g>
					<g>
						<path d="M375.74,2608.42c3.02-0.06,3.04-0.07,4.11,3.13c0.21,0.64,0.46,1.31,0.32,2.02c-0.47-0.37-1.03-0.4-1.59-0.45
							c-3.85-0.38-7.71-0.78-11.56-1.17c-0.67-1.13-1.04-2.59-2.81-2.53c-0.16,0-0.33-0.28-0.5-0.43c-0.01-1.77,0.02-3.55-0.05-5.32
							c-0.03-0.92,0.18-1.37,1.22-1.33c0.91,0.03,1.34,0.25,1.3,1.27c-0.08,1.71-0.02,3.42,0.28,5.25c0.5-0.69,0.98-1.39,1.5-2.06
							c0.32-0.42,0.52-1.05,1.26-0.9C370.96,2607.83,372.91,2609.26,375.74,2608.42z"/>
					</g>
					<g>
						<path d="M316.76,2640.41c-0.09,0.63-0.48,1.09-0.87,1.57c-0.23,0.28-0.57,0.61-0.36,0.99c0.23,0.42,0.66,0.17,1.01,0.08
							c0.83-0.23,1.67-0.11,2.51-0.11c0.37,0,0.81,0.02,0.9,0.48c0.09,0.45-0.29,0.69-0.61,0.85c-1.88,0.92-3.79,1.8-5.68,2.71
							c-0.73,0.35-1.68,0.35-1.87,1.54c-0.08,0.51-0.78,0.25-1.15-0.09c-0.3-0.27-0.31-0.66-0.33-1.04
							c-0.12-1.84-0.1-1.79-1.75-1.01c-1.23,0.57-2.25,1.52-3.59,1.86c-0.21,0.06-0.5,0.19-0.64,0.11c-0.3-0.19-0.11-0.5,0-0.75
							c0.2-0.47,0.45-0.93,0.61-1.41c0.09-0.28,0.38-0.63,0.03-0.9c-0.3-0.23-0.61-0.04-0.92,0.09c-1.42,0.59-2.84,1.17-4.26,1.72
							c-0.21,0.08-0.46,0.04-0.69,0.06c0.22-0.56,0.75-0.8,1.21-1.11c1.32-0.87,2.69-1.66,3.71-2.92c0.44-0.55,1.08-1.03,1.81-0.91
							c2.42,0.4,4.59-0.49,6.8-1.18C313.97,2640.6,315.34,2640.38,316.76,2640.41z"/>
						<path d="M311.45,2648.96c-0.33,0-0.71-0.23-0.92-0.42c-0.33-0.3-0.36-0.71-0.38-1.07l-0.01-0.12
							c-0.05-0.71-0.08-1.22-0.21-1.3c-0.13-0.08-0.56,0.13-1.16,0.41l-0.14,0.07c-0.46,0.22-0.91,0.49-1.34,0.76
							c-0.7,0.44-1.43,0.89-2.28,1.11c-0.05,0.01-0.1,0.03-0.16,0.05c-0.22,0.07-0.44,0.14-0.61,0.04c-0.39-0.25-0.21-0.64-0.1-0.87
							c0.01-0.03,0.03-0.06,0.04-0.08c0.07-0.17,0.15-0.34,0.23-0.51c0.14-0.29,0.28-0.59,0.38-0.89c0.02-0.07,0.05-0.14,0.08-0.2
							c0.12-0.27,0.16-0.39,0-0.51c-0.17-0.13-0.35-0.06-0.63,0.06c-0.04,0.02-0.08,0.04-0.12,0.05c-1.51,0.63-2.9,1.19-4.27,1.73
							c-0.17,0.07-0.35,0.07-0.53,0.07c-0.07,0-0.14,0-0.21,0l-0.26,0.02l0.1-0.25c0.21-0.52,0.63-0.78,1.04-1.04
							c0.08-0.05,0.15-0.1,0.23-0.15c0.18-0.12,0.36-0.23,0.53-0.35c1.12-0.72,2.27-1.47,3.14-2.54c0.59-0.74,1.29-1.08,1.97-0.97
							c2.07,0.34,3.91-0.26,5.86-0.9c0.29-0.09,0.57-0.19,0.86-0.28c1.42-0.45,2.83-0.65,4.18-0.62l0.19,0l-0.03,0.18
							c-0.09,0.66-0.48,1.13-0.86,1.59l-0.04,0.05c-0.03,0.03-0.05,0.06-0.08,0.09c-0.24,0.28-0.39,0.49-0.27,0.7
							c0.12,0.2,0.27,0.18,0.62,0.06c0.07-0.02,0.13-0.04,0.19-0.06c0.63-0.17,1.27-0.15,1.89-0.13c0.22,0.01,0.45,0.01,0.67,0.01
							c0.33,0,0.94-0.01,1.07,0.61c0.13,0.63-0.47,0.92-0.7,1.03c-1.07,0.52-2.16,1.04-3.21,1.54c-0.82,0.39-1.64,0.78-2.46,1.17
							c-0.18,0.08-0.36,0.15-0.54,0.21c-0.59,0.21-1.1,0.38-1.24,1.2c-0.03,0.2-0.14,0.34-0.31,0.4
							C311.59,2648.95,311.52,2648.96,311.45,2648.96z M309.87,2645.69c0.09,0,0.17,0.02,0.24,0.06c0.28,0.17,0.31,0.61,0.37,1.57
							l0.01,0.13c0.02,0.34,0.04,0.64,0.27,0.85c0.25,0.23,0.62,0.38,0.78,0.32c0.04-0.01,0.08-0.04,0.09-0.14
							c0.16-1.01,0.85-1.25,1.45-1.46c0.18-0.06,0.35-0.12,0.51-0.2c0.82-0.39,1.64-0.78,2.46-1.17c1.05-0.5,2.14-1.01,3.21-1.53
							c0.42-0.2,0.57-0.4,0.52-0.66c-0.06-0.3-0.3-0.35-0.74-0.35c-0.23,0-0.46-0.01-0.68-0.01c-0.62-0.02-1.21-0.04-1.79,0.12
							c-0.06,0.02-0.12,0.04-0.18,0.06c-0.3,0.1-0.76,0.25-1.02-0.21c-0.24-0.43,0.07-0.81,0.3-1.08c0.03-0.03,0.05-0.06,0.08-0.09
							l0.04-0.05c0.32-0.4,0.63-0.77,0.75-1.24c-1.26-0.01-2.56,0.19-3.88,0.61c-0.29,0.09-0.57,0.18-0.86,0.28
							c-1.9,0.62-3.87,1.27-6.02,0.91c-0.74-0.12-1.35,0.48-1.65,0.85c-0.9,1.11-2.08,1.88-3.22,2.61
							c-0.18,0.11-0.36,0.23-0.53,0.35c-0.08,0.05-0.16,0.1-0.24,0.15c-0.3,0.19-0.6,0.37-0.79,0.65c0.13,0,0.26,0,0.35-0.04
							c1.36-0.53,2.76-1.1,4.26-1.72c0.04-0.02,0.08-0.03,0.11-0.05c0.28-0.12,0.63-0.28,0.97-0.02c0.38,0.29,0.21,0.68,0.1,0.91
							c-0.03,0.06-0.05,0.11-0.07,0.17c-0.11,0.32-0.25,0.63-0.39,0.93c-0.08,0.16-0.15,0.33-0.23,0.49
							c-0.01,0.03-0.03,0.06-0.04,0.09c-0.13,0.29-0.14,0.38-0.02,0.45c0.05,0.02,0.23-0.04,0.33-0.07
							c0.06-0.02,0.12-0.04,0.18-0.05c0.8-0.21,1.47-0.63,2.19-1.07c0.44-0.27,0.89-0.56,1.37-0.78l0.14-0.07
							C309.25,2645.86,309.61,2645.69,309.87,2645.69z"/>
					</g>
					<g>
						<path d="M295.89,2649.54c0.11,0.51-0.09,1.18,0.77,1.27c0.53,0.05,1.13-0.18,1.66,0.35c-2.52,1.83-4.32,4.52-7.41,5.6
							c-1.15,0.4-2.23,0.59-3.41,0.31c-1.63-0.4-4.47-0.66-4.47-0.66c0.16-0.2-0.66,0.2-0.5,0c0.3-0.2,1.09-1,1.39-1.2
							c1.12-0.13,2.26,0.12,3.37-0.17c0.97-0.03,1.95-0.1,2.92-0.06c0.63,0.02,0.84-0.32,0.91-0.82
							C291.5,2651.35,293.13,2649.87,295.89,2649.54z"/>
						<path d="M288.64,2657.37c-0.4,0-0.79-0.05-1.18-0.14c-1.6-0.39-4.42-0.65-4.45-0.65l-0.16-0.01c-0.01,0-0.02,0.01-0.03,0.01
							c-0.22,0.07-0.37,0.11-0.45-0.02c-0.02-0.04-0.07-0.13,0.03-0.25l0.03-0.03c0.14-0.1,0.41-0.35,0.68-0.59
							c0.29-0.26,0.55-0.51,0.71-0.62l0.03-0.02l0.04,0c0.47-0.05,0.96-0.04,1.42-0.03c0.67,0.02,1.3,0.03,1.93-0.13l0.04-0.01
							c0.29-0.01,0.58-0.02,0.87-0.03c0.68-0.03,1.37-0.06,2.06-0.03c0.46,0.01,0.67-0.17,0.74-0.67c0.38-2.81,2.03-4.41,4.91-4.76
							l0.15-0.02l0.03,0.15c0.03,0.12,0.04,0.25,0.04,0.38c0.03,0.46,0.08,0.71,0.58,0.76c0.13,0.01,0.27,0.01,0.42,0
							c0.42-0.02,0.9-0.04,1.34,0.4l0.14,0.14l-0.16,0.11c-0.88,0.64-1.69,1.4-2.47,2.14c-1.45,1.37-2.95,2.78-4.98,3.49
							C290.1,2657.22,289.35,2657.37,288.64,2657.37z M283.18,2656.26c0.58,0.06,2.94,0.3,4.36,0.65c1.02,0.25,2.01,0.16,3.32-0.3
							c1.96-0.69,3.44-2.07,4.86-3.42c0.74-0.7,1.5-1.41,2.33-2.04c-0.29-0.21-0.61-0.19-0.94-0.18c-0.15,0.01-0.31,0.01-0.47,0
							c-0.8-0.08-0.85-0.65-0.88-1.06c0-0.06-0.01-0.12-0.02-0.18c-2.65,0.37-4.11,1.82-4.46,4.45c-0.09,0.67-0.44,0.98-1.08,0.96
							c-0.68-0.02-1.37,0.01-2.04,0.03c-0.28,0.01-0.57,0.02-0.85,0.03c-0.67,0.17-1.35,0.15-2,0.14c-0.44-0.01-0.89-0.02-1.33,0.03
							c-0.15,0.11-0.4,0.34-0.64,0.56c-0.1,0.09-0.19,0.18-0.29,0.26C283.1,2656.19,283.15,2656.21,283.18,2656.26z"/>
					</g>
					<g>
						<path d="M363.71,2608.98c0.17,0.15,0.34,0.44,0.5,0.43c1.76-0.05,2.13,1.4,2.81,2.53c-0.55,0.02-1.1,0.07-1.65,0.04
							c-2.44-0.13-4.87-0.36-7.32-0.19c0.07-1.28-1.32-1.85-1.46-3.03c1.88,0.1,3.76,0.2,5.64,0.29
							C362.72,2609.09,363.23,2609.24,363.71,2608.98z"/>
					</g>
					<g>
						<path d="M329.27,2624.68c0.45,0.49,0.89,0.15,1.23-0.1c1.19-0.88,7.76-0.58,9.02-0.57c0.33,0-0.38,0.67-0.52,0.91
							c-2.27,0.62-9.03,1.33-11.35,1.77c-0.13-0.48,0.2-0.7,0.55-0.77C328.9,2625.76,329.11,2625.26,329.27,2624.68z"/>
					</g>
					<g>
						<path d="M410.21,2632.49c-0.21-3.27-0.42-6.54-0.64-9.81c0,0,0.77-0.17,1.18-0.11c3.1,0.49,3.09,0.48,3.16-2.74
							c0.01-0.63-0.17-1.31,0.27-1.89c1.64-2.1,2.49-4.61,3.58-6.99c1.43,0.38,2.45,1.46,3.65,2.21c0.97,0.61,1.86,1,2.95,0.3
							c0.85-0.55,0.97,0.34,1.1,0.75c0.46,1.4,0.7,2.85,0.86,4.31c0.09,0.86,0.09,1.78,0.27,2.64c0.12,0.6,0.31,1.27,0.75,1.72
							c0.57,0.58,1.46,0.73,1.87,1.48c0.71,1.31,0.49,2.77,0.6,4.15c0.15,1.89,0.05,3.8,0.05,5.7c-0.2-0.18-0.39-0.36-0.59-0.54
							c0,0,0-0.01,0-0.01c-0.08-0.09-0.16-0.18-0.24-0.27c-0.7-0.75-1.68-1.05-2.6-1.11c-1.16-0.06-2.21-0.74-3.39-0.49
							c-0.24-0.05-0.49-0.1-0.73-0.15c-0.1-0.09-0.2-0.17-0.29-0.26c-0.86-0.53-1.47,0.19-2.17,0.43c-1.13,0.15-2.26,0.32-3.39,0.45
							c-0.47,0.06-0.62,0.33-0.65,0.74c-1.03-0.06-2.02,0.05-2.97,0.49C411.92,2633.31,410.87,2633.41,410.21,2632.49z
							 M414.93,2626.41c0.14-0.74-0.09-1.16-1.01-1.14c-0.83,0.02-1.18,0.33-1.15,1.15c0.03,0.68-0.03,1.3,0.97,1.28
							C414.63,2627.69,415.05,2627.37,414.93,2626.41z M417.66,2616.68c-0.15,0.68-0.01,1.21,0.93,1.18c0.69-0.02,1.18-0.15,1.12-1
							c-0.05-0.64,0.1-1.32-0.92-1.32C418.01,2615.53,417.51,2615.69,417.66,2616.68z"/>
						<path d="M430.05,2634.61l-0.9-0.83l-0.01-0.02l-0.22-0.24c-0.74-0.8-1.76-1.01-2.49-1.05c-0.48-0.03-0.94-0.15-1.39-0.28
							c-0.66-0.18-1.29-0.36-1.96-0.22l-0.03,0.01l-0.03-0.01c-0.24-0.05-0.49-0.1-0.73-0.14l-0.04-0.01l-0.32-0.28
							c-0.53-0.32-0.94-0.09-1.42,0.17c-0.19,0.11-0.39,0.21-0.6,0.29l-0.03,0.01c-0.32,0.04-0.65,0.09-0.97,0.13
							c-0.79,0.11-1.61,0.22-2.42,0.32c-0.33,0.04-0.47,0.2-0.51,0.59l-0.01,0.16l-0.16-0.01c-1.11-0.07-2.05,0.09-2.89,0.48
							l-0.05,0.02l-0.06-0.01c-0.21-0.05-0.43-0.08-0.65-0.11c-0.76-0.11-1.55-0.22-2.09-0.99l-0.03-0.04l-0.65-9.99l0.14-0.03
							c0.03-0.01,0.81-0.18,1.24-0.11c1.44,0.23,2.24,0.35,2.58,0.07c0.34-0.29,0.36-1.11,0.39-2.62l0-0.03
							c0-0.14-0.01-0.29-0.01-0.45c-0.03-0.52-0.06-1.05,0.32-1.54c1.24-1.59,2.04-3.45,2.81-5.26c0.24-0.56,0.49-1.14,0.75-1.7
							l0.06-0.13l0.14,0.04c1.01,0.27,1.83,0.88,2.61,1.46c0.35,0.26,0.71,0.53,1.09,0.77c1.04,0.65,1.83,0.91,2.78,0.3
							c0.3-0.19,0.55-0.24,0.76-0.16c0.34,0.13,0.45,0.54,0.54,0.84c0.02,0.06,0.03,0.11,0.05,0.16c0.41,1.23,0.68,2.61,0.87,4.34
							c0.03,0.3,0.05,0.61,0.07,0.91c0.04,0.57,0.08,1.16,0.2,1.72c0.11,0.54,0.29,1.21,0.71,1.64c0.22,0.22,0.49,0.38,0.78,0.54
							c0.42,0.24,0.86,0.49,1.12,0.97c0.54,1,0.56,2.07,0.58,3.11c0.01,0.37,0.01,0.74,0.04,1.11c0.11,1.33,0.09,2.69,0.07,4
							c-0.01,0.56-0.02,1.14-0.01,1.71L430.05,2634.61z M429.45,2633.61l0.26,0.24c0-0.45,0.01-0.9,0.01-1.33
							c0.02-1.31,0.04-2.66-0.07-3.97c-0.03-0.37-0.04-0.76-0.04-1.13c-0.02-1-0.03-2.04-0.54-2.96c-0.22-0.4-0.6-0.62-1-0.84
							c-0.29-0.17-0.6-0.34-0.85-0.6c-0.48-0.49-0.67-1.22-0.79-1.8c-0.12-0.58-0.16-1.18-0.2-1.77c-0.02-0.3-0.04-0.6-0.07-0.89
							c-0.18-1.71-0.45-3.07-0.85-4.28c-0.02-0.05-0.03-0.11-0.05-0.17c-0.07-0.23-0.16-0.56-0.34-0.62
							c-0.13-0.05-0.33,0.04-0.47,0.13c-1.21,0.77-2.21,0.28-3.13-0.3c-0.38-0.24-0.75-0.52-1.11-0.78
							c-0.76-0.57-1.48-1.1-2.36-1.37c-0.24,0.52-0.46,1.05-0.68,1.56c-0.78,1.82-1.59,3.71-2.86,5.33
							c-0.3,0.39-0.28,0.84-0.25,1.32c0.01,0.15,0.02,0.31,0.01,0.47l0,0.03c-0.03,1.65-0.05,2.48-0.51,2.86
							c-0.45,0.38-1.25,0.26-2.84,0.01c-0.25-0.04-0.71,0.03-0.98,0.08l0.62,9.61c0.46,0.62,1.13,0.71,1.84,0.82
							c0.2,0.03,0.41,0.06,0.62,0.1c0.84-0.38,1.77-0.54,2.84-0.49c0.06-0.31,0.22-0.66,0.78-0.73c0.8-0.1,1.62-0.21,2.41-0.32
							c0.32-0.04,0.64-0.09,0.96-0.13c0.18-0.06,0.35-0.16,0.53-0.26c0.5-0.27,1.06-0.59,1.77-0.16l0.02,0.02l0.26,0.23
							c0.22,0.04,0.44,0.09,0.66,0.13c0.73-0.15,1.42,0.04,2.08,0.23c0.43,0.12,0.87,0.24,1.32,0.27c0.78,0.04,1.89,0.28,2.71,1.16
							L429.45,2633.61z M413.71,2627.87c-1.06,0-1.08-0.69-1.1-1.3l0-0.14c-0.02-0.45,0.07-0.76,0.28-0.98
							c0.21-0.22,0.54-0.33,1.03-0.34c0.49-0.01,0.82,0.1,1.02,0.33c0.19,0.22,0.24,0.54,0.16,0.98c0.06,0.48-0.01,0.83-0.22,1.07
							c-0.22,0.25-0.58,0.38-1.13,0.38C413.73,2627.87,413.72,2627.87,413.71,2627.87z M413.98,2625.44c-0.02,0-0.04,0-0.06,0
							c-0.4,0.01-0.65,0.08-0.8,0.24c-0.14,0.15-0.2,0.39-0.19,0.74l0,0.14c0.02,0.63,0.03,0.98,0.76,0.98c0.01,0,0.02,0,0.04,0
							c0.45-0.01,0.73-0.09,0.88-0.27c0.15-0.17,0.19-0.44,0.14-0.84l0-0.03l0.01-0.03c0.07-0.35,0.04-0.59-0.08-0.73
							C414.56,2625.51,414.32,2625.44,413.98,2625.44z M418.54,2618.02c-0.42,0-0.72-0.11-0.9-0.33c-0.19-0.23-0.24-0.57-0.14-1.03
							c-0.06-0.44-0.01-0.76,0.18-0.97c0.25-0.29,0.69-0.33,1.12-0.33c1.04,0,1.06,0.68,1.07,1.22c0,0.09,0,0.18,0.01,0.26
							c0.03,0.38-0.05,0.66-0.22,0.85c-0.25,0.27-0.65,0.32-1.06,0.33C418.58,2618.02,418.56,2618.02,418.54,2618.02z
							 M418.77,2615.7c-0.46,0-0.71,0.06-0.84,0.22c-0.12,0.14-0.15,0.38-0.1,0.73l0,0.03l-0.01,0.03
							c-0.08,0.36-0.06,0.62,0.06,0.77c0.12,0.15,0.35,0.21,0.7,0.21c0.45-0.01,0.69-0.08,0.82-0.22c0.11-0.12,0.16-0.32,0.14-0.6
							c-0.01-0.09-0.01-0.19-0.01-0.28c-0.02-0.55-0.03-0.89-0.74-0.89C418.78,2615.7,418.77,2615.7,418.77,2615.7z"/>
					</g>
					<g>
						<path d="M417.77,2610.96c-1.09,2.38-1.94,4.89-3.58,6.99c-0.45,0.58-0.26,1.25-0.27,1.89c-0.06,3.22-0.06,3.22-3.16,2.74
							c-0.41-0.06-0.54,0.01-1.18,0.11c-1.49-0.03-2.94,0.2-4.4,0.52c-1.69,0.36-1.97,0.09-1.97-1.6c0-1.39,0.01-2.78,0.01-4.17
							c0-0.51-0.05-1.05,0.63-1.22c0.23,0.03,0.49,0.01,0.6-0.23c0.06-0.13,0.07-0.29,0.07-0.43c0.03-0.96,0.06-1.91,0.09-2.87
							c0.02-0.49-0.12-1.27,0.55-1.29c0.7-0.02,0.57,0.82,0.56,1.28c-0.03,0.88-0.03,1.76,0,2.64c0.02,0.61,0.35,0.9,0.96,0.88
							c0.87-0.03,1.74-0.01,2.61-0.03c0.34-0.01,0.8,0.01,1.03-0.29c0.24-0.32,0.16-0.92,0.15-1.28c-0.02-1.2,0-2.39,0-3.59
							c0-0.88-0.33-2.38,0.99-2.3c0.71,0.04,1.5-0.09,1.75,0.76c0.17,0.6,0.27,1.21,0.36,1.83c0.06,0.43-0.01,1.03,0.22,1.41
							c0,0,0.61-0.08,0.6-0.45c-0.02-0.99-0.06-1.99-0.08-2.98c-0.01-0.43,0.24-0.94,0.64-0.74c0.31,0.15,0.65,3.09,0.65,3.09
							C416.04,2611.88,417.03,2611.21,417.77,2610.96z"/>
					</g>
					<g>
						<path d="M379.58,2668.08c0.23-1.09,0.66-2.12,0.62-3.28c-0.08-2.75-2.31-4.68-5.19-4.37c-0.51,0.05-1.01,0.14-1.52,0.23
							c-1.96,0.36-1.96,0.36-2.01-1.7c-0.05-2.03-2-3.92-4.04-2.92c-1.95,0.96-2.22,3.63-4.01,4.76c-1.15,0.73-2.06-0.16-3.24-0.16
							c-3.95,0.02-8.57,1.17-12.21-0.89c-2.06-1.17-3.86-2.81-5.76-4.23c-0.1-0.07-0.2-0.16-0.23-0.28
							c-0.03-0.15,0.08-0.29,0.18-0.39c1.54-1.6,4.58-2.29,6.62-2.98c2.65-0.9,5.35-1.66,8.02-2.53c1.88-0.61,3.77-1.22,5.58-2.01
							c0.75-0.33,2.24-0.81,2.57-1.68c1.21-3.17,1.38-3.23-1.2-5.45c-1.74-1.49-3.32-3.31-5.73-3.85c-1.13-0.25-2.27-0.48-3.41-0.72
							c0.15-1.52-0.84-1.88-2.04-2.03c0.78-1.06,1.56-1.22,2.68-0.33c1.38,1.1,2.92,2,4.38,3c0.63,0.43,1.18,0.59,1.55-0.31
							c0.29-0.68,0.8-0.66,1.39-0.42c0.99,0.41,1.99,0.81,3.01,1.16c1.96,0.68,2.66,2.32,3.26,4.08c0.78,2.33,0.78,2.29,2.47,0.47
							c1.04-1.12,1.66-2.31,1.57-3.88c-0.05-0.86-0.1-2.06,0.74-2.37c0.83-0.31,1.1,0.93,1.63,1.47c0.48,0.5,0.88,1.08,1.35,1.6
							c0.21,0.23,0.41,0.62,0.78,0.48c0.46-0.18,0.26-0.65,0.24-0.99c-0.04-0.56-0.18-1.1-0.22-1.66c-0.02-0.31-0.15-0.71,0.19-0.9
							c0.38-0.22,0.58,0.18,0.79,0.4c0.33,0.33,0.72,0.65,0.9,1.06c0.57,1.34,1.31,0.88,2.05,0.24c1.17-1.01,4.2-1.25,5.4-0.4
							c0.71,0.5,0.74,2.36-0.14,3.02c-1.07,0.81-0.47,1.11,0.28,1.3c0.95,0.24,1.95,1.45,2.91-0.15c0.32-0.53,0.56,0.17,0.73,0.42
							c1.78,2.62,3.58,5.23,5.29,7.9c0.57,0.88,0.92,0.65,1.25-0.05c0.61-1.3,1.49-2.49,1.7-3.97c0.09-0.63,0.4-1.1,1.07-1.34
							c1.19-0.42,2.34-0.93,3.52-1.39c0.25-0.1,0.53-0.28,0.78-0.04c0.25,0.24,0.08,0.52,0,0.78c-0.21,0.67-0.37,1.34-0.36,2.06
							c0.01,0.61,0.17,1.12,0.74,1.4c1.1,0.53,1.38,1.44,1.25,2.56c-0.07,0.61-0.01,1.14,0.82,1.13c0.45-0.01,1.01-0.09,1.09,0.57
							c0.08,0.61,0.11,1.23-0.44,1.68c-0.62,0.5-1.25,0.98-1.87,1.46c-0.32,0.25-0.83,0.45-0.63,0.96c0.18,0.45,0.7,0.4,1.12,0.39
							c0.98-0.03,1.89-0.43,2.86,0.45c1.37,1.24,1.47,2.95,1.97,4.48c0.24,0.72-0.82,0.74-1.39,0.66c-1.79-0.26-3.31-1.18-4.88-2
							c-2.47-1.29-5-2.41-7.72-3.02c-0.07,0.11-0.14,0.21-0.21,0.32c0.95,0.75,1.91,1.49,2.84,2.25c0.97,0.8,1.92,1.62,2.87,2.45
							c0.17,0.15,0.38,0.34,0.28,0.59c-0.14,0.34-0.48,0.26-0.73,0.22c-0.76-0.11-1.35-0.57-1.98-0.98
							c-2.54-1.63-4.79-3.77-7.89-4.4c-2.02-0.41-4.05-0.4-5.91,0.42c-1.45,0.63-2.33,1.94-2.67,3.53c-0.36,1.69-0.1,3.17,1.27,4.39
							c0.81,0.72,0.78,1.36-0.36,1.83C382.65,2666.83,381.36,2668.04,379.58,2668.08z"/>
						<path d="M379.38,2668.25l0.04-0.21c0.07-0.33,0.16-0.65,0.24-0.97c0.21-0.76,0.4-1.48,0.38-2.27
							c-0.04-1.27-0.55-2.39-1.44-3.17c-0.93-0.82-2.2-1.18-3.56-1.04c-0.53,0.06-1.06,0.15-1.51,0.23
							c-1.03,0.19-1.54,0.28-1.85,0.03c-0.32-0.26-0.33-0.77-0.36-1.89c-0.03-1.09-0.62-2.14-1.52-2.69
							c-0.72-0.43-1.53-0.47-2.29-0.09c-0.98,0.48-1.51,1.41-2.07,2.39c-0.51,0.89-1.03,1.8-1.92,2.36
							c-0.79,0.5-1.49,0.29-2.17,0.09c-0.37-0.11-0.75-0.22-1.16-0.22c0,0-0.01,0-0.01,0c-1.01,0-2.04,0.08-3.14,0.16
							c-3.15,0.24-6.41,0.48-9.15-1.08c-1.44-0.82-2.77-1.88-4.06-2.9c-0.56-0.45-1.14-0.91-1.72-1.34
							c-0.1-0.07-0.25-0.19-0.29-0.38c-0.04-0.23,0.11-0.42,0.22-0.54c1.29-1.34,3.51-2.03,5.46-2.63c0.44-0.14,0.85-0.26,1.23-0.39
							c1.49-0.51,3.02-0.97,4.5-1.43c1.15-0.35,2.35-0.72,3.51-1.1l0.2-0.06c1.78-0.58,3.63-1.18,5.37-1.94
							c0.11-0.05,0.24-0.1,0.37-0.15c0.74-0.3,1.86-0.76,2.11-1.43l0.15-0.38c1.09-2.83,1.04-2.87-1.3-4.88
							c-0.33-0.28-0.65-0.58-0.97-0.87c-1.36-1.24-2.77-2.52-4.69-2.95c-0.78-0.17-1.57-0.34-2.34-0.5
							c-0.36-0.07-0.71-0.15-1.07-0.22l-0.15-0.03l0.01-0.15c0.13-1.32-0.63-1.68-1.9-1.85l-0.28-0.04l0.17-0.23
							c0.43-0.59,0.87-0.9,1.33-0.96c0.48-0.06,1,0.13,1.58,0.6c0.93,0.74,1.94,1.4,2.92,2.04c0.48,0.31,0.97,0.63,1.44,0.96
							c0.39,0.27,0.65,0.36,0.85,0.3c0.17-0.05,0.32-0.23,0.45-0.53c0.37-0.89,1.1-0.72,1.61-0.51c1.01,0.41,2,0.81,3,1.16
							c2.04,0.71,2.76,2.38,3.36,4.19c0.32,0.95,0.55,1.64,0.76,1.68c0.2,0.04,0.69-0.47,1.36-1.19l0.08-0.08
							c1.13-1.22,1.6-2.38,1.53-3.76c-0.04-0.82-0.12-2.18,0.85-2.54c0.7-0.26,1.08,0.39,1.39,0.91c0.13,0.22,0.26,0.45,0.41,0.61
							c0.27,0.28,0.51,0.59,0.75,0.88c0.19,0.24,0.39,0.49,0.6,0.72c0.04,0.04,0.08,0.09,0.12,0.15c0.2,0.25,0.31,0.35,0.47,0.29
							c0.22-0.09,0.22-0.25,0.16-0.59c-0.01-0.08-0.03-0.16-0.03-0.24c-0.02-0.28-0.07-0.56-0.11-0.84
							c-0.04-0.27-0.09-0.54-0.11-0.82c0-0.05-0.01-0.1-0.02-0.16c-0.04-0.29-0.09-0.69,0.29-0.9c0.43-0.24,0.71,0.09,0.88,0.3
							c0.04,0.05,0.08,0.09,0.11,0.13c0.06,0.06,0.13,0.13,0.19,0.19c0.29,0.28,0.58,0.56,0.74,0.92c0.19,0.44,0.41,0.7,0.64,0.75
							c0.34,0.08,0.75-0.23,1.15-0.57c1.25-1.08,4.34-1.31,5.6-0.41c0.36,0.26,0.58,0.81,0.59,1.47c0.01,0.6-0.18,1.4-0.73,1.82
							c-0.5,0.38-0.52,0.57-0.51,0.63c0.03,0.13,0.27,0.26,0.73,0.38c0.26,0.06,0.51,0.19,0.76,0.32c0.74,0.37,1.33,0.67,1.96-0.39
							c0.11-0.18,0.24-0.28,0.38-0.27c0.25,0,0.4,0.28,0.53,0.52c0.03,0.06,0.07,0.12,0.1,0.16c0.37,0.54,0.74,1.09,1.11,1.63
							c1.39,2.04,2.83,4.16,4.18,6.27c0.29,0.45,0.46,0.5,0.53,0.5c0.08-0.01,0.23-0.1,0.43-0.53c0.18-0.38,0.38-0.75,0.57-1.11
							c0.48-0.88,0.97-1.78,1.11-2.81c0.1-0.73,0.5-1.23,1.18-1.47c0.81-0.29,1.61-0.62,2.39-0.94c0.37-0.15,0.74-0.3,1.12-0.45
							c0.03-0.01,0.07-0.03,0.1-0.04c0.24-0.1,0.56-0.24,0.85,0.04c0.29,0.27,0.17,0.59,0.08,0.83c-0.02,0.04-0.03,0.08-0.04,0.12
							c-0.18,0.57-0.37,1.26-0.35,2.01c0.01,0.67,0.21,1.04,0.64,1.26c1.05,0.51,1.49,1.41,1.35,2.73
							c-0.03,0.29-0.04,0.61,0.11,0.78c0.1,0.11,0.27,0.16,0.54,0.16c0.04,0,0.08,0,0.13,0c0.41-0.01,1.03-0.03,1.13,0.72
							c0.08,0.62,0.12,1.33-0.5,1.83c-0.42,0.34-0.85,0.67-1.27,1c-0.2,0.16-0.4,0.31-0.6,0.47c-0.06,0.05-0.13,0.1-0.2,0.14
							c-0.34,0.23-0.48,0.36-0.38,0.62c0.12,0.29,0.43,0.3,0.89,0.29l0.07,0c0.21-0.01,0.43-0.03,0.64-0.06
							c0.75-0.09,1.53-0.18,2.34,0.55c1.07,0.97,1.39,2.21,1.7,3.42c0.1,0.38,0.2,0.77,0.32,1.14c0.1,0.3,0.01,0.49-0.08,0.6
							c-0.35,0.42-1.3,0.31-1.48,0.28c-1.57-0.23-2.95-0.96-4.29-1.68c-0.22-0.11-0.43-0.23-0.65-0.34
							c-1.99-1.04-4.64-2.31-7.57-2.98l-0.06,0.09c0.25,0.2,0.5,0.39,0.75,0.59c0.65,0.51,1.33,1.04,1.98,1.57
							c0.94,0.78,1.88,1.59,2.79,2.38l0.09,0.07c0.16,0.14,0.47,0.41,0.32,0.78c-0.18,0.44-0.61,0.37-0.87,0.33l-0.04-0.01
							c-0.68-0.1-1.23-0.47-1.76-0.82c-0.09-0.06-0.19-0.13-0.29-0.19c-0.64-0.41-1.28-0.87-1.89-1.31
							c-1.8-1.28-3.66-2.61-5.94-3.07c-2.25-0.46-4.15-0.32-5.82,0.41c-1.31,0.57-2.23,1.79-2.57,3.42c-0.39,1.8,0,3.15,1.21,4.24
							c0.46,0.41,0.66,0.8,0.6,1.17c-0.06,0.38-0.39,0.69-1.01,0.94c-0.49,0.21-0.97,0.48-1.43,0.74c-0.98,0.56-1.99,1.13-3.23,1.16
							L379.38,2668.25z M375.61,2660.23c1.22,0,2.34,0.4,3.21,1.15c0.96,0.84,1.52,2.06,1.56,3.41c0.02,0.84-0.19,1.62-0.39,2.37
							c-0.07,0.24-0.13,0.49-0.19,0.74c1.06-0.08,1.94-0.58,2.86-1.11c0.47-0.27,0.96-0.55,1.47-0.76c0.49-0.21,0.76-0.44,0.81-0.69
							c0.04-0.24-0.13-0.53-0.5-0.86c-1.29-1.16-1.73-2.65-1.32-4.55c0.37-1.74,1.35-3.03,2.76-3.65c1.73-0.76,3.7-0.9,6.01-0.43
							c2.35,0.48,4.24,1.82,6.06,3.13c0.61,0.44,1.24,0.89,1.88,1.3c0.1,0.06,0.19,0.13,0.29,0.19c0.52,0.35,1.02,0.67,1.63,0.77
							l0.04,0.01c0.29,0.05,0.44,0.05,0.51-0.13c0.05-0.13-0.04-0.24-0.23-0.4l-0.09-0.07c-0.91-0.79-1.84-1.6-2.78-2.37
							c-0.65-0.53-1.32-1.06-1.97-1.57c-0.29-0.23-0.58-0.45-0.87-0.68l-0.12-0.09l0.35-0.54l0.11,0.02
							c3.01,0.67,5.73,1.97,7.76,3.03c0.22,0.11,0.43,0.23,0.65,0.34c1.31,0.7,2.67,1.42,4.18,1.64c0.46,0.07,1.02,0.03,1.18-0.16
							c0.04-0.04,0.08-0.12,0.02-0.28c-0.12-0.38-0.23-0.78-0.32-1.16c-0.29-1.15-0.6-2.35-1.6-3.25c-0.69-0.63-1.33-0.55-2.07-0.46
							c-0.22,0.03-0.44,0.05-0.67,0.06l-0.07,0c-0.42,0.02-0.99,0.04-1.21-0.5c-0.21-0.53,0.2-0.81,0.5-1.02
							c0.06-0.04,0.13-0.09,0.18-0.13c0.2-0.16,0.4-0.31,0.61-0.47c0.42-0.32,0.85-0.66,1.26-0.99c0.49-0.39,0.46-0.95,0.38-1.53
							c-0.06-0.43-0.33-0.45-0.79-0.43c-0.05,0-0.09,0-0.13,0c-0.37,0.01-0.62-0.08-0.79-0.27c-0.24-0.27-0.23-0.67-0.19-1.04
							c0.13-1.18-0.24-1.94-1.16-2.39c-0.72-0.35-0.82-1.05-0.83-1.55c-0.02-0.79,0.18-1.51,0.37-2.11
							c0.01-0.05,0.03-0.09,0.05-0.14c0.09-0.25,0.12-0.36,0-0.47c-0.12-0.12-0.24-0.08-0.49,0.03c-0.04,0.02-0.08,0.03-0.11,0.05
							c-0.37,0.15-0.74,0.3-1.11,0.45c-0.78,0.32-1.59,0.65-2.41,0.94c-0.58,0.2-0.88,0.59-0.97,1.2c-0.15,1.09-0.65,2.02-1.14,2.92
							c-0.19,0.36-0.39,0.73-0.57,1.1c-0.15,0.32-0.37,0.7-0.71,0.72c-0.27,0.02-0.54-0.19-0.83-0.65
							c-1.35-2.11-2.79-4.22-4.18-6.27c-0.37-0.54-0.74-1.09-1.11-1.63c-0.03-0.05-0.07-0.12-0.11-0.19
							c-0.05-0.1-0.18-0.33-0.25-0.35c0,0-0.03,0.02-0.09,0.11c-0.79,1.33-1.68,0.88-2.4,0.52c-0.24-0.12-0.47-0.24-0.69-0.29
							c-0.44-0.11-0.91-0.27-0.98-0.63c-0.06-0.28,0.14-0.59,0.63-0.96c0.38-0.29,0.61-0.89,0.6-1.55c-0.01-0.54-0.19-1.01-0.45-1.2
							c-1.12-0.79-4.08-0.57-5.19,0.39c-0.43,0.37-0.94,0.76-1.44,0.64c-0.35-0.08-0.64-0.39-0.87-0.95
							c-0.13-0.3-0.4-0.56-0.66-0.81c-0.07-0.07-0.14-0.13-0.2-0.2c-0.04-0.04-0.09-0.1-0.13-0.15c-0.21-0.25-0.3-0.31-0.46-0.22
							c-0.17,0.1-0.16,0.28-0.12,0.57c0.01,0.06,0.01,0.12,0.02,0.17c0.02,0.26,0.06,0.53,0.11,0.79c0.05,0.28,0.09,0.58,0.11,0.87
							c0,0.06,0.02,0.13,0.03,0.21c0.05,0.3,0.12,0.76-0.37,0.95c-0.41,0.16-0.68-0.17-0.85-0.39c-0.04-0.05-0.07-0.09-0.11-0.13
							c-0.22-0.24-0.42-0.49-0.62-0.74c-0.23-0.29-0.47-0.59-0.73-0.86c-0.17-0.18-0.32-0.43-0.46-0.67
							c-0.34-0.58-0.56-0.92-0.99-0.76c-0.68,0.25-0.69,1.23-0.63,2.21c0.08,1.48-0.42,2.72-1.61,4.01l-0.08,0.08
							c-0.88,0.95-1.28,1.38-1.67,1.29c-0.4-0.08-0.59-0.65-1.01-1.9c-0.58-1.72-1.26-3.32-3.16-3.98c-1.01-0.35-2-0.75-3.01-1.16
							c-0.64-0.26-0.97-0.17-1.18,0.33c-0.17,0.41-0.38,0.64-0.66,0.72c-0.39,0.12-0.81-0.12-1.14-0.34
							c-0.47-0.32-0.96-0.64-1.44-0.95c-0.99-0.64-2.01-1.31-2.95-2.06c-0.51-0.41-0.95-0.58-1.33-0.53
							c-0.32,0.04-0.62,0.24-0.94,0.61c1.04,0.17,1.99,0.6,1.91,2.02c0.31,0.06,0.62,0.13,0.92,0.19c0.77,0.16,1.56,0.32,2.34,0.5
							c2,0.44,3.44,1.76,4.84,3.02c0.31,0.29,0.64,0.58,0.96,0.86c2.56,2.2,2.47,2.43,1.39,5.25l-0.15,0.38
							c-0.31,0.81-1.46,1.28-2.3,1.62c-0.13,0.05-0.26,0.1-0.36,0.15c-1.76,0.77-3.61,1.37-5.4,1.95l-0.2,0.06
							c-1.17,0.38-2.36,0.75-3.52,1.1c-1.48,0.45-3.01,0.92-4.49,1.42c-0.38,0.13-0.8,0.26-1.23,0.39c-1.92,0.6-4.1,1.27-5.32,2.55
							c-0.1,0.11-0.15,0.19-0.14,0.25c0.01,0.06,0.09,0.12,0.16,0.18c0.58,0.44,1.16,0.9,1.72,1.35c1.27,1.02,2.59,2.07,4.01,2.87
							c2.66,1.51,5.86,1.27,8.96,1.03c1.1-0.08,2.14-0.16,3.16-0.17c0.45,0,0.87,0.12,1.26,0.24c0.66,0.2,1.23,0.37,1.89-0.05
							c0.81-0.51,1.3-1.35,1.81-2.24c0.56-0.98,1.15-2,2.21-2.52c0.86-0.43,1.79-0.39,2.6,0.11c0.99,0.6,1.65,1.76,1.68,2.96
							c0.02,0.95,0.04,1.48,0.23,1.64c0.19,0.16,0.71,0.06,1.57-0.1c0.46-0.08,0.99-0.18,1.54-0.23
							C375.2,2660.24,375.41,2660.23,375.61,2660.23z"/>
					</g>
					<g>
						<path d="M412.66,2633.26c0.52-0.44,1.11-0.75,1.91-0.65c1.12,0.13,2.8,1.59,3.92,1.11c1.13-0.48,1.43-2.2,2.74-2.5
							c1.74-0.39,3.22,1.17,4.53,2.07c1.11,0.76,3.09,0.99,3.69,2.33c-0.11-0.19-2.35,0.57-2.57,0.64c-1.05,0.3-2.39,0.41-2.71,1.64
							c-0.32,1.24,1.41,2.75,2.39,3.24c1.86,1.27,4.66,2.92,6.45,0.64c0.63-0.8,1.1-1.03,2.16-0.78c1.45,0.33,4.21,2.19,3.79,3.98
							c-0.16,0.67-0.81,2.86-1.75,2.84c-0.25-0.01-0.49-0.1-0.72-0.2c-0.8-0.34-1.59-0.67-2.39-1.01c-1.94-0.82-1.78,0.05-3.27,1
							c-0.65,0.41-1.8,0.34-1.68,1.44c0.09,0.87,1.67,2.14-0.1,2.25c-1.14,0.07-2.25-0.26-3.3-0.67c-0.46-0.18-2.38-0.62-2.66-0.91
							c0.93,0.95,1.82,2.04,1.87,3.44c0.01,0.2,0,0.41,0.08,0.59c0.64,1.42,6.97,0.76,8.39,0.99c1.83,0.29,5.85,1.36,5.32,3.99
							c-1.39-0.25-2.66-0.24-4.07-0.45c-2.08-0.3,3.66,2.04,4.24,1.61c3.47-2.61-1.89,3.99-2.19,3.97c-2.25-0.13-4.88,0.1-6.98-0.71
							c-1.01-0.39-1.97-0.87-2.86-1.48c-0.75-0.51-1.47-1.58-2.23-1.97c-0.54-0.62-1.12-2.43-2.02-2.55
							c-0.89-0.11-0.87,0.89-1.65,1.16c-1.05,0.35-2.54-0.3-3.59-0.48c-0.64-0.11-5.39-2.6-5.45-2.37c0.21-0.9,1.16-1.7,2.11-1.41
							c1.5,0.45-0.92-2.24,0.31-2.4c0.73-0.09,1.5,1.1,2.13,0.16c0.31-0.47-0.15-1.46-0.39-1.87c-1.73-2.9-5.89-4.66-6.5-8.15
							c-0.23-1.33-1.16-2.47-2.27-3.24c-1.31-0.91-8.88-2.54-8.53-4.6c0.18-1.05,1.24-1.75,2.2-1.98c1.15-0.28,1.86,0.51,2.89,0.43
							c0.74-0.05,0.85-0.63,1.3-1.19c0.33-0.41,1.07-0.12,1.4,0.15c1.21,0.96,2.4,2.55,4.1,3.27
							C412.23,2635.27,411.62,2634.14,412.66,2633.26z"/>
						<path d="M436.72,2663.93C436.72,2663.93,436.72,2663.93,436.72,2663.93c-0.58-0.03-1.18-0.04-1.81-0.05
							c-1.78-0.03-3.63-0.06-5.2-0.66c-1.08-0.42-2.02-0.91-2.88-1.49c-0.34-0.23-0.68-0.58-1-0.92c-0.4-0.41-0.81-0.84-1.22-1.04
							l-0.03-0.02c-0.18-0.21-0.37-0.55-0.56-0.91c-0.39-0.72-0.84-1.54-1.41-1.61c-0.45-0.06-0.64,0.19-0.87,0.51
							c-0.18,0.24-0.38,0.52-0.75,0.65c-0.77,0.26-1.77-0.01-2.65-0.24c-0.35-0.09-0.68-0.18-0.98-0.23
							c-0.31-0.05-1.46-0.6-2.69-1.19c-1.13-0.54-2.53-1.21-2.71-1.19c0.01,0,0.04-0.02,0.05-0.05c0,0,0,0,0,0l-0.16-0.04l0.01-0.04
							c0.13-0.51,0.49-0.99,0.96-1.26c0.4-0.23,0.84-0.29,1.25-0.17c0.26,0.08,0.35,0.04,0.37,0.01c0.1-0.11-0.08-0.53-0.22-0.88
							c-0.21-0.5-0.42-1.02-0.25-1.32c0.07-0.12,0.2-0.19,0.37-0.22c0.3-0.04,0.6,0.12,0.89,0.27c0.52,0.28,0.85,0.42,1.18-0.08
							c0.28-0.42-0.14-1.35-0.4-1.78c-0.69-1.16-1.8-2.15-2.87-3.12c-1.61-1.45-3.27-2.95-3.64-5.06c-0.21-1.2-1-2.33-2.24-3.18
							c-0.4-0.28-1.43-0.64-2.62-1.05c-2.76-0.96-6.19-2.15-5.94-3.63c0.18-1.08,1.27-1.8,2.27-2.05c0.66-0.16,1.18,0.02,1.69,0.19
							c0.4,0.14,0.78,0.27,1.21,0.24c0.5-0.04,0.69-0.33,0.93-0.71c0.09-0.15,0.19-0.3,0.32-0.45c0.37-0.46,1.17-0.14,1.51,0.13
							c0.39,0.31,0.78,0.69,1.19,1.08c0.86,0.83,1.75,1.69,2.89,2.17c0.88,0.37,0.97,0.15,1.18-0.33c0.13-0.3,0.29-0.68,0.7-1.03
							c0.44-0.37,1.06-0.78,1.97-0.67c0.45,0.05,0.97,0.3,1.52,0.57c0.84,0.41,1.71,0.82,2.36,0.55c0.5-0.21,0.83-0.67,1.17-1.16
							c0.41-0.58,0.84-1.18,1.58-1.34c1.43-0.32,2.69,0.64,3.8,1.49c0.28,0.21,0.54,0.41,0.79,0.59c0.38,0.26,0.85,0.45,1.36,0.66
							c0.96,0.39,1.96,0.8,2.36,1.71l-0.15,0.08c0.01,0.01,0.01,0.01,0.02,0.02c-0.09-0.04-0.55,0.03-2.25,0.58
							c-0.12,0.04-0.21,0.07-0.24,0.08c-0.17,0.05-0.35,0.09-0.53,0.14c-0.92,0.23-1.87,0.46-2.12,1.45
							c-0.3,1.16,1.34,2.64,2.34,3.14c2.22,1.51,4.7,2.76,6.35,0.66c0.68-0.86,1.2-1.05,2.24-0.81c1.1,0.25,2.78,1.3,3.5,2.47
							c0.35,0.56,0.47,1.11,0.35,1.62c-0.03,0.12-0.69,2.9-1.81,2.9c-0.01,0-0.01,0-0.02,0c-0.27-0.01-0.52-0.11-0.75-0.21
							l-2.39-1.01c-1.34-0.57-1.62-0.32-2.23,0.23c-0.24,0.22-0.54,0.49-0.96,0.76c-0.19,0.12-0.43,0.21-0.66,0.28
							c-0.57,0.2-1.06,0.36-0.98,1.08c0.03,0.26,0.2,0.57,0.37,0.87c0.24,0.43,0.47,0.83,0.31,1.12c-0.11,0.2-0.39,0.32-0.86,0.35
							c-1.22,0.08-2.37-0.3-3.33-0.68c-0.15-0.06-0.45-0.14-0.8-0.24c-0.58-0.16-1.08-0.31-1.43-0.44c0.81,0.88,1.5,1.88,1.55,3.13
							c0,0.03,0,0.07,0,0.1c0,0.16,0.01,0.32,0.07,0.46c0.43,0.95,3.83,0.92,6.08,0.89c0.98-0.01,1.83-0.02,2.25,0.05
							c0.9,0.14,3.92,0.73,5.03,2.33c0.37,0.53,0.49,1.12,0.36,1.76l-0.02,0.08l-0.08-0.01c-0.77-0.14-1.48-0.2-2.24-0.26
							c-0.59-0.05-1.2-0.1-1.83-0.19c-0.18-0.03-0.27-0.03-0.32-0.03c0.3,0.26,2.18,1.06,3.53,1.4c0.78,0.19,0.95,0.12,0.99,0.09
							c0.89-0.67,1.12-0.67,1.23-0.59c0.13,0.09,0.05,0.3,0.03,0.37C439.8,2660.45,437.08,2663.93,436.72,2663.93z M436.71,2663.77
							L436.71,2663.77L436.71,2663.77z M424.71,2659.62c0.43,0.22,0.85,0.65,1.25,1.07c0.32,0.33,0.65,0.67,0.97,0.9
							c0.84,0.58,1.78,1.06,2.84,1.47c1.54,0.6,3.37,0.62,5.14,0.65c0.63,0.01,1.22,0.02,1.8,0.05c0.28-0.14,2.96-3.31,3.26-4.27
							c0.04-0.11,0.02-0.14,0.02-0.14c-0.01-0.01-0.16-0.06-1.03,0.59c-0.3,0.22-1.58-0.17-2.32-0.42
							c-1.06-0.36-2.08-0.82-2.39-1.05c-0.04-0.03-0.15-0.12-0.11-0.22c0.04-0.1,0.19-0.11,0.54-0.06c0.63,0.09,1.23,0.14,1.82,0.19
							c0.7,0.06,1.42,0.12,2.17,0.25c0.09-0.56-0.02-1.08-0.35-1.55c-1.07-1.55-4.03-2.13-4.92-2.27c-0.41-0.06-1.25-0.06-2.22-0.04
							c-2.43,0.03-5.76,0.06-6.23-0.99c-0.07-0.17-0.08-0.35-0.08-0.52c0-0.03,0-0.07,0-0.1c-0.05-1.37-0.92-2.44-1.84-3.38
							c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0.12-0.12c0,0,0,0,0,0c0.18,0.18,1.17,0.46,1.82,0.65c0.36,0.1,0.66,0.19,0.82,0.25
							c0.95,0.37,2.08,0.74,3.26,0.66c0.4-0.03,0.64-0.11,0.72-0.26c0.12-0.22-0.1-0.6-0.31-0.96c-0.18-0.31-0.36-0.64-0.39-0.93
							c-0.09-0.85,0.54-1.06,1.09-1.26c0.22-0.08,0.45-0.15,0.63-0.27c0.42-0.26,0.69-0.52,0.94-0.74c0.62-0.56,0.96-0.87,2.4-0.26
							l2.39,1.01c0.22,0.09,0.45,0.19,0.69,0.2c0,0,0.01,0,0.01,0c0.83,0,1.47-2,1.65-2.77c0.11-0.47,0-0.97-0.33-1.49
							c-0.7-1.13-2.33-2.15-3.4-2.39c-0.98-0.23-1.43-0.06-2.07,0.75c-1.97,2.5-5.19,0.31-6.56-0.62c-0.97-0.49-2.76-2.02-2.42-3.33
							c0.28-1.08,1.32-1.34,2.24-1.57c0.18-0.04,0.35-0.09,0.52-0.13c0.03-0.01,0.12-0.04,0.23-0.07c1.36-0.44,1.95-0.58,2.21-0.59
							c-0.42-0.75-1.32-1.12-2.19-1.47c-0.51-0.21-1-0.41-1.39-0.68c-0.26-0.18-0.52-0.38-0.8-0.59c-1.08-0.83-2.31-1.77-3.66-1.46
							c-0.68,0.15-1.07,0.7-1.48,1.28c-0.35,0.48-0.7,0.98-1.24,1.21c-0.72,0.31-1.63-0.13-2.5-0.55c-0.54-0.26-1.04-0.5-1.46-0.55
							c-0.66-0.08-1.23,0.12-1.84,0.63l0,0c-0.38,0.32-0.53,0.68-0.65,0.97c-0.23,0.54-0.41,0.83-1.39,0.42
							c-1.16-0.49-2.1-1.4-2.94-2.21c-0.41-0.39-0.79-0.77-1.18-1.07c-0.29-0.23-0.99-0.52-1.28-0.16
							c-0.12,0.15-0.22,0.29-0.31,0.44c-0.25,0.4-0.47,0.74-1.05,0.78c-0.47,0.03-0.88-0.11-1.28-0.24
							c-0.5-0.17-0.98-0.34-1.59-0.19c-0.94,0.23-1.97,0.91-2.14,1.92c-0.23,1.34,3.27,2.56,5.83,3.45c1.2,0.42,2.24,0.78,2.66,1.07
							c1.27,0.88,2.09,2.05,2.31,3.29c0.36,2.06,2,3.54,3.59,4.97c1.08,0.97,2.19,1.98,2.9,3.16c0.03,0.05,0.8,1.35,0.39,1.96
							c-0.43,0.65-0.95,0.37-1.4,0.13c-0.26-0.14-0.54-0.29-0.79-0.26c-0.13,0.02-0.21,0.06-0.25,0.13
							c-0.13,0.23,0.07,0.73,0.26,1.17c0.19,0.46,0.36,0.87,0.19,1.05c-0.1,0.11-0.27,0.12-0.54,0.03
							c-0.46-0.14-0.86,0.01-1.11,0.15c-0.41,0.24-0.73,0.65-0.86,1.09c0.29,0.06,1.02,0.39,2.71,1.2c1.16,0.55,2.36,1.12,2.64,1.17
							c0.3,0.05,0.64,0.14,0.99,0.24c0.86,0.23,1.83,0.49,2.56,0.24c0.32-0.11,0.5-0.35,0.67-0.59c0.23-0.32,0.47-0.64,1.02-0.57
							c0.65,0.08,1.12,0.94,1.53,1.7C424.36,2659.1,424.54,2659.42,424.71,2659.62z"/>
					</g>
					<g>
						<path d="M449.93,2665.3c1.59,1.29,3.06,2.72,4.35,4.32c0.27,0.34,0.55,0.78,0.36,1.17c-0.16,0.32-0.56,0.42-0.91,0.46
							c-1.59,0.16-3.23-0.39-4.41-1.47c-1.86-1.7-2.77-4.73-5.24-5.21c-1.38-0.27-3.18,0.25-3.93-0.94c-0.68-1.1,0.33-2.43,0.56-3.7
							c0.24-1.32-7-14.25,1.14-10.79c0.37,0.16,0.73,0.39,1.13,0.37c0.54-0.02,0.95-0.49,1.37-0.83c1.01-0.82,2.5-1,3.69-0.46
							c1.18,0.55,2.01,1.8,2.05,3.1c0.01,0.51-0.08,1.02-0.07,1.54c0.04,1.57,1.23,3.04,2.76,3.4c-1.46,0.51-2.92,1.03-4.38,1.54
							c2.16,2.18,5.61,3.22,6.77,6.07c0.12,0.29,0.2,0.65,0,0.89c-0.17,0.2-0.46,0.23-0.73,0.24
							C452.94,2665.08,451.35,2665.12,449.93,2665.3z"/>
						<path d="M453.19,2671.52c-1.48,0-2.95-0.56-4.04-1.56c-0.69-0.63-1.24-1.43-1.78-2.2c-0.95-1.36-1.85-2.65-3.34-2.94
							c-0.41-0.08-0.88-0.09-1.34-0.09c-1.07-0.01-2.17-0.03-2.76-0.96c-0.53-0.85-0.18-1.79,0.16-2.69c0.15-0.4,0.3-0.8,0.37-1.19
							c0.05-0.26-0.41-1.44-0.85-2.59c-1.16-3.02-2.76-7.16-1.32-8.54c0.68-0.66,1.87-0.61,3.65,0.15c0.09,0.04,0.17,0.08,0.26,0.12
							c0.27,0.13,0.51,0.24,0.76,0.23c0.35-0.02,0.64-0.28,0.96-0.55c0.09-0.08,0.18-0.15,0.26-0.23c1.09-0.88,2.68-1.08,3.95-0.49
							c1.27,0.59,2.15,1.92,2.19,3.32c0.01,0.27-0.01,0.54-0.04,0.8c-0.02,0.25-0.04,0.49-0.03,0.73c0.03,1.47,1.14,2.83,2.57,3.17
							l0.82,0.2l-4.8,1.69c0.74,0.68,1.61,1.26,2.46,1.82c1.63,1.08,3.33,2.2,4.08,4.05c0.25,0.61,0.11,0.96-0.04,1.15
							c-0.23,0.28-0.59,0.32-0.9,0.33c-0.3,0.02-0.6,0.03-0.9,0.04c-1,0.05-2.03,0.09-3,0.19c1.52,1.27,2.81,2.58,3.93,3.98
							c0.17,0.21,0.68,0.84,0.38,1.44c-0.17,0.34-0.54,0.54-1.11,0.59C453.56,2671.52,453.37,2671.52,453.19,2671.52z
							 M439.58,2648.8c-0.41,0-0.73,0.11-0.95,0.32c-1.2,1.16,0.45,5.44,1.44,8.01c0.62,1.61,0.94,2.47,0.87,2.85
							c-0.08,0.43-0.24,0.86-0.39,1.27c-0.31,0.83-0.6,1.61-0.2,2.25c0.44,0.7,1.36,0.71,2.34,0.73c0.48,0.01,0.97,0.01,1.42,0.1
							c1.69,0.33,2.69,1.76,3.65,3.15c0.52,0.75,1.07,1.53,1.71,2.12c1.12,1.03,2.7,1.56,4.22,1.41c0.39-0.04,0.63-0.15,0.71-0.32
							c0.1-0.21,0-0.5-0.33-0.9c-1.22-1.51-2.63-2.91-4.31-4.28l-0.45-0.37l0.58-0.07c1.15-0.14,2.4-0.2,3.62-0.26
							c0.3-0.01,0.61-0.03,0.9-0.04c0.21-0.01,0.45-0.03,0.55-0.16c0.13-0.16,0.03-0.47-0.04-0.64c-0.69-1.71-2.25-2.74-3.89-3.82
							c-0.98-0.65-2-1.32-2.83-2.16l-0.28-0.28l4.08-1.44c-1.29-0.61-2.22-1.96-2.26-3.4c-0.01-0.27,0.02-0.53,0.04-0.79
							c0.02-0.26,0.04-0.5,0.03-0.75c-0.03-1.21-0.8-2.37-1.9-2.88c-1.1-0.51-2.48-0.34-3.42,0.42c-0.08,0.07-0.17,0.14-0.25,0.21
							c-0.35,0.31-0.74,0.65-1.27,0.68c-0.37,0.01-0.69-0.13-1-0.28c-0.08-0.04-0.16-0.08-0.24-0.11
							C440.86,2648.99,440.14,2648.8,439.58,2648.8z"/>
					</g>
					<g>
						<path d="M395.62,2629.76c-0.46-0.07-0.56-0.39-0.59-0.8c-0.07-1.19-0.16-2.38-0.25-3.57c-0.05-0.7,0.21-1.12,0.96-0.97
							c1.22,0.25,2.46,0.47,3.65,0.86c1.2,0.39,0.77,1.26,0.38,2.04C398.09,2627.63,397.04,2629,395.62,2629.76z"/>
					</g>
					<g>
						<path d="M370.36,2629.11c-0.1-0.06-0.01-1.15-0.01-1.3c0.01-0.45,0.04-0.91,0.13-1.36c0.14-0.66,0.45-1.76,1.29-1.76
							c1.16,0,1.22,1.78,1.37,2.59c0.05,0.27,0.29,3.12,0.3,3.12C372.35,2630.14,371.33,2629.69,370.36,2629.11z"/>
					</g>
					<g>
						<path d="M365.49,2627.53c0-0.01-0.01-0.02-0.01-0.03c-0.03-0.1-0.02-0.2-0.01-0.3c0.08-0.78,0.01-2.96,1.24-2.92
							c1.68,0.05,1.37,2.77,1.4,3.87C368.11,2628.1,365.73,2628.12,365.49,2627.53z"/>
					</g>
					<g>
						<path d="M361.66,2627.57c-0.27,0.05-0.53,0.08-0.75,0.08l-0.5,0.19c-0.02-0.87-0.12-2.78,0.58-3.41
							c0.21-0.19,0.5-0.34,0.78-0.26c0.15,0.04,0.27,0.15,0.37,0.26c0.77,0.82,0.59,1.77,0.74,2.79c0,0-0.11,0.06-0.11,0.06
							C362.45,2627.38,362.05,2627.5,361.66,2627.57z"/>
					</g>
					<g>
						<path d="M347.65,2640.1c0.06,0.12,0.13,0.23,0.19,0.35C347.78,2640.34,347.72,2640.22,347.65,2640.1z"/>
					</g>
					<g>
						<path d="M429.05,2633.4c0.08,0.09,0.16,0.18,0.24,0.27C429.21,2633.58,429.13,2633.49,429.05,2633.4z"/>
					</g>
					<g>
						<path d="M401.24,2632.57c-0.1,0.08-0.19,0.16-0.29,0.25C401.05,2632.73,401.14,2632.65,401.24,2632.57z"/>
					</g>
					<g>
						<path d="M401.51,2632.34c-0.08,0.06-0.15,0.13-0.23,0.19C401.35,2632.47,401.43,2632.41,401.51,2632.34z"/>
					</g>
					<g>
						<path d="M461.86,2663.39c-0.09,0.1-0.18,0.19-0.27,0.29C461.67,2663.58,461.76,2663.48,461.86,2663.39z"/>
					</g>
					<g>
						<path d="M362.49,2627.37c0.09-0.03,0.18-0.07,0.27-0.1C362.67,2627.3,362.58,2627.34,362.49,2627.37z"/>
					</g>
					<g>
						<path d="M358.05,2611.8c2.45-0.17,4.88,0.06,7.32,0.19c0.55,0.03,1.1-0.03,1.65-0.04c3.85,0.39,7.71,0.79,11.56,1.17
							c0.56,0.06,1.12,0.08,1.59,0.45c2.15,2.24,1.98,5.12,2.2,7.91c0.07,0.85-0.08,1.36-1.09,1.23c-1.05-0.13-3.17-0.28-3.17-0.28
							c-0.56-2.14-0.53-4.07-1.19-6.14c-0.15-0.28-0.21-0.67-0.59-0.71c-0.41-0.04-0.49,0.38-0.65,0.64
							c-0.41,0.69-0.34,1.64-0.74,2.24c-0.06,1.21,0.23,2.6,0.16,3.81c0,0-1.39-0.14-1.9-0.13c-0.36-1.87-0.15-3.84-0.97-5.62
							c-0.22-0.49-0.28-1.27-0.89-1.31c-0.66-0.04-0.74,0.74-0.95,1.23c-0.73,1.73-0.38,3.59-0.67,5.38
							c-0.63-0.08-1.25-0.16-1.88-0.24c-0.16-1.89-0.19-3.8-1.04-5.57c-0.18-0.37-0.26-0.89-0.76-0.9
							c-0.49-0.01-0.58,0.51-0.78,0.87c-0.91,1.68-0.39,3.56-0.77,5.32l-2.26-0.29c-0.27-2.28-0.4-3.21-1.59-5.49
							c-1.3,2.17-1.31,3.03-1.59,5.16c-0.32,0.08-0.63,0.16-0.95,0.24C357.8,2617.89,358.06,2614.84,358.05,2611.8z"/>
						<path d="M381.56,2622.89c-0.09,0-0.19-0.01-0.3-0.02c-1.04-0.13-3.14-0.28-3.16-0.28l-0.12-0.01l-0.03-0.11
							c-0.26-0.98-0.39-1.93-0.52-2.85c-0.16-1.11-0.31-2.16-0.66-3.26c-0.04-0.07-0.07-0.14-0.09-0.2
							c-0.11-0.25-0.17-0.39-0.36-0.4c-0.19-0.02-0.26,0.09-0.38,0.36c-0.03,0.07-0.06,0.14-0.1,0.2c-0.19,0.32-0.27,0.72-0.35,1.1
							c-0.08,0.39-0.16,0.78-0.36,1.1c-0.03,0.58,0.03,1.22,0.08,1.83c0.06,0.65,0.11,1.32,0.08,1.94l-0.01,0.17l-0.17-0.02
							c-0.01,0-1.39-0.14-1.88-0.13l-0.14,0.01l-0.03-0.14c-0.13-0.67-0.19-1.36-0.24-2.03c-0.1-1.19-0.2-2.43-0.71-3.55
							c-0.06-0.13-0.11-0.28-0.15-0.42c-0.16-0.48-0.27-0.77-0.6-0.8c-0.37-0.02-0.49,0.28-0.66,0.78
							c-0.04,0.12-0.08,0.24-0.12,0.35c-0.45,1.05-0.48,2.2-0.51,3.3c-0.02,0.67-0.04,1.37-0.15,2.04l-0.03,0.16l-2.17-0.28
							l-0.01-0.13c-0.02-0.27-0.04-0.54-0.06-0.8c-0.12-1.58-0.25-3.22-0.96-4.7c-0.04-0.08-0.07-0.16-0.1-0.24
							c-0.14-0.34-0.23-0.56-0.51-0.56c0,0,0,0-0.01,0c-0.26,0-0.36,0.2-0.5,0.52c-0.04,0.09-0.08,0.18-0.12,0.26
							c-0.54,1-0.56,2.05-0.58,3.17c-0.01,0.69-0.03,1.41-0.18,2.1l-0.03,0.15l-2.54-0.32l-0.02-0.13
							c-0.25-2.12-0.36-3.05-1.43-5.17c-1.03,1.79-1.14,2.67-1.35,4.35c-0.02,0.16-0.04,0.32-0.06,0.49l-0.01,0.11l-1.24,0.31
							l-0.02-0.19c-0.21-2.08-0.16-4.19-0.11-6.23c0.02-0.96,0.05-1.95,0.05-2.92l0-0.16l0.15-0.01c1.94-0.14,3.91-0.02,5.8,0.1
							c0.51,0.03,1.02,0.06,1.53,0.09c0.37,0.02,0.75,0,1.11-0.02c0.17-0.01,0.35-0.02,0.52-0.02l0.01,0l2.93,0.3
							c2.88,0.29,5.76,0.59,8.64,0.87l0.13,0.01c0.53,0.05,1.07,0.1,1.54,0.48l0.02,0.01c1.88,1.97,2.03,4.43,2.17,6.82
							c0.02,0.39,0.05,0.8,0.08,1.19c0.03,0.43,0.03,0.92-0.26,1.2C382.11,2622.81,381.87,2622.89,381.56,2622.89z M378.24,2622.26
							c0.46,0.03,2.16,0.16,3.06,0.28c0.36,0.05,0.61,0.01,0.74-0.12c0.15-0.14,0.2-0.43,0.16-0.93c-0.03-0.4-0.06-0.8-0.08-1.2
							c-0.14-2.43-0.28-4.72-2.07-6.6c-0.39-0.31-0.86-0.35-1.36-0.4l-0.13-0.01c-2.88-0.29-5.76-0.58-8.64-0.87l-2.91-0.3
							c-0.17,0.01-0.34,0.01-0.51,0.02c-0.38,0.02-0.76,0.04-1.15,0.02c-0.51-0.03-1.03-0.06-1.54-0.09
							c-1.84-0.11-3.73-0.23-5.61-0.11c0,0.93-0.02,1.87-0.05,2.78c-0.05,1.97-0.1,4,0.09,5.99l0.65-0.16
							c0.02-0.13,0.03-0.26,0.05-0.38c0.22-1.8,0.34-2.71,1.55-4.73l0.15-0.26l0.14,0.26c1.15,2.21,1.32,3.15,1.59,5.42l1.98,0.25
							c0.12-0.62,0.13-1.26,0.14-1.89c0.02-1.11,0.04-2.26,0.62-3.33c0.04-0.07,0.07-0.15,0.11-0.24c0.14-0.32,0.31-0.73,0.82-0.72
							c0.51,0.01,0.68,0.43,0.81,0.77c0.03,0.08,0.06,0.15,0.09,0.22c0.74,1.54,0.87,3.21,0.99,4.82c0.02,0.22,0.03,0.45,0.05,0.67
							l1.59,0.2c0.09-0.61,0.1-1.23,0.12-1.84c0.03-1.14,0.07-2.31,0.54-3.42c0.04-0.1,0.08-0.21,0.11-0.32
							c0.14-0.44,0.34-1.05,1-1.01c0.57,0.04,0.75,0.59,0.89,1.02c0.05,0.14,0.09,0.27,0.14,0.38c0.54,1.18,0.64,2.44,0.74,3.66
							c0.05,0.61,0.1,1.25,0.21,1.86c0.46,0,1.25,0.08,1.61,0.11c0.02-0.55-0.04-1.14-0.09-1.72c-0.06-0.64-0.11-1.31-0.08-1.92
							l0-0.04l0.02-0.04c0.18-0.27,0.26-0.64,0.34-1.03c0.09-0.41,0.17-0.83,0.39-1.2c0.03-0.05,0.06-0.11,0.09-0.17
							c0.11-0.24,0.27-0.59,0.72-0.55c0.39,0.04,0.53,0.35,0.64,0.6c0.03,0.07,0.06,0.13,0.09,0.19l0.01,0.03
							c0.36,1.14,0.52,2.25,0.68,3.33C377.87,2620.44,378,2621.34,378.24,2622.26z"/>
					</g>
					<g>
						<path d="M369.72,2621.82c0.17-1.04,0.12-2.1,0.2-3.15c0.07-1,0.33-1.89,0.7-2.82c0.1-0.26,0.23-0.54,0.49-0.63
							c0.33-0.11,0.67,0.16,0.83,0.47c1.02,2.04,0.85,4.28,1.27,6.46L369.72,2621.82z"/>
					</g>
					<g>
						<path d="M374.74,2622.26c0.07-1.21,0.14-2.59,0.2-3.8c0.05-0.89,0.45-1.81,0.89-2.57c0.1-0.17,0.28-0.33,0.48-0.32
							c0.21,0.02,0.36,0.21,0.46,0.39c0.51,0.92,0.77,2.03,0.95,3.06c0.05,0.29,0.36,3.39,0.39,3.4
							C378.11,2622.42,374.74,2622.26,374.74,2622.26z"/>
					</g>
					<g>
						<path d="M364.49,2621.31c0.15,0.01,0.19-2.73,0.2-2.98c0.06-1.01,0.3-1.92,0.8-2.8c0.07-0.13,0.15-0.26,0.27-0.34
							c0.17-0.12,0.42-0.11,0.58,0.02c0.11,0.09,0.18,0.22,0.25,0.35c0.45,0.9,0.75,1.84,0.92,2.83c0.05,0.27,0.33,3.19,0.32,3.19
							C367.84,2621.59,364.49,2621.31,364.49,2621.31z"/>
					</g>
					<g>
						<path d="M359.05,2620.7c0.23-1.82,0.19-3.69,0.98-5.56c0.18-0.41,0.76-0.41,0.93,0c0.82,1.97,1.03,3.93,1.26,5.89
							L359.05,2620.7z"/>
					</g>
					<g>
						<path d="M395.52,2620.11c0.04-0.47,0.12-0.92,0.16-1.21c0.18-1.21,1.41-0.92,2.25-0.71c0.72,0.17,0.92,1.04,1,1.67
							c0.06,0.45,0.29,1.89-0.22,2.15c-0.8,0.41-1.76,0.31-2.57,0C395.53,2621.78,395.46,2620.91,395.52,2620.11z"/>
					</g>
					<g>
						<path d="M413.32,2632.81c-0.41,0.78-1.12,2.3-1.79,2.75c-0.62,0.42-1.83-0.15-2.43-0.68c-0.81-0.73-1.45-1.55-1.7-2.63
							c0.95-0.03,1.85,0.5,2.82,0.23C410.87,2633.41,412.39,2632.6,413.32,2632.81z"/>
					</g>
					<g>
						<path d="M404.87,2627.37c0-0.42,0.01-0.84,0-1.26c-0.02-0.66,0.31-0.9,0.95-0.9c0.65,0.01,1.09,0.17,1.07,0.94
							c-0.02,0.84-0.01,1.68-0.01,2.52c0,0.76-0.38,1.07-1.14,1.07c-0.8,0-0.9-0.48-0.88-1.11
							C404.88,2628.21,404.87,2627.79,404.87,2627.37C404.87,2627.37,404.87,2627.37,404.87,2627.37z"/>
					</g>
					<g>
						<path d="M421.23,2631.22c-0.21,0.35-1.36,1.43-1.49,1.81c-0.48,1.41-1.57,1.55-2.84,0.83c-0.39-0.22-0.72-0.55-1.08-0.83
							c0.04-0.41,0.19-0.68,0.65-0.74C417.6,2632.16,420.1,2631.37,421.23,2631.22z"/>
					</g>
					<g>
						<path d="M414.97,2626.41c0,0,0,0.01,0,0.01c-0.01,0.85-0.34,1.27-1.22,1.28c-1,0.02-0.95-0.6-0.97-1.28
							c-0.03-0.83,0.31-1.14,1.15-1.15C414.65,2625.26,414.97,2625.71,414.97,2626.41z"/>
					</g>
					<g>
						<path d="M417.66,2616.68c-0.16-0.99,0.34-1.15,1.13-1.14c1.02,0,0.87,0.68,0.92,1.32c0.06,0.85-0.43,0.98-1.12,1
							C417.65,2617.88,417.51,2617.36,417.66,2616.68z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st22" d="M369.9,2672.3c-0.23-0.56-0.57-1.08-0.76-1.65c-0.27-0.79-0.83-1.73,0.75-1.84
							c0.41-0.03,0.64-0.5,0.87-0.85c1.52-2.34,3.02-4.7,5.24-6.46c1.78-0.04,3.07-1.25,4.6-1.89c1.13-0.48,1.17-1.11,0.36-1.83
							c-1.36-1.22-1.63-2.7-1.27-4.39c0.34-1.59,1.22-2.9,2.67-3.53c1.87-0.82,3.9-0.83,5.91-0.42c3.1,0.63,5.35,2.77,7.89,4.4
							c0.63,0.41,1.22,0.87,1.98,0.98c0.25,0.04,0.6,0.11,0.73-0.22c0.1-0.25-0.11-0.44-0.28-0.59c-0.95-0.82-1.9-1.65-2.87-2.45
							c-0.93-0.77-1.89-1.5-2.84-2.25c0.08-0.18,0.07-0.15,0.15-0.33c2.74,0.6,5.29,1.73,7.77,3.03c1.57,0.82,3.1,1.74,4.88,2
							c0.57,0.08,1.62,0.06,1.39-0.66c-0.5-1.53-0.59-3.24-1.97-4.48c-0.97-0.88-1.88-0.48-2.86-0.45
							c-0.42,0.01-0.94,0.06-1.12-0.39c-0.2-0.5,0.31-0.7,0.63-0.96c0.62-0.49,1.26-0.97,1.87-1.46c0.55-0.45,0.52-1.07,0.44-1.68
							c-0.09-0.67-0.64-0.58-1.09-0.57c-0.83,0.01-0.89-0.51-0.82-1.13c0.12-1.12-0.16-2.03-1.25-2.56
							c-0.57-0.28-0.72-0.79-0.74-1.4c-0.02-0.72,0.15-1.39,0.36-2.06c0.08-0.26,0.25-0.54,0-0.78c-0.25-0.24-0.53-0.06-0.78,0.04
							c-1.17,0.47-2.33,0.97-3.52,1.39c-0.67,0.24-0.99,0.71-1.07,1.34c-0.2,1.48-1.09,2.67-1.7,3.97c-0.33,0.7-0.68,0.93-1.25,0.05
							c-1.71-2.67-3.51-5.28-5.29-7.9c-0.17-0.25-0.41-0.95-0.73-0.42c-0.96,1.6-1.96,0.39-2.91,0.15c-0.76-0.19-1.35-0.49-0.28-1.3
							c0.88-0.67,0.85-2.52,0.14-3.02c-1.2-0.85-4.22-0.61-5.4,0.4c-0.75,0.64-1.48,1.09-2.05-0.24c-0.17-0.41-0.57-0.73-0.9-1.06
							c-0.21-0.21-0.41-0.61-0.79-0.4c-0.34,0.19-0.21,0.59-0.19,0.9c0.04,0.56,0.18,1.1,0.22,1.66c0.02,0.34,0.22,0.81-0.24,1
							c-0.36,0.15-0.57-0.25-0.78-0.48c-0.47-0.52-0.87-1.1-1.35-1.6c-0.52-0.55-0.79-1.78-1.63-1.47
							c-0.84,0.31-0.79,1.52-0.74,2.37c0.09,1.57-0.53,2.77-1.57,3.88c-1.7,1.83-1.69,1.87-2.47-0.47c-0.62-1.84-1.36-3.54-3.5-4.16
							c-0.82-0.24-1.62-0.53-2.39-0.91c-0.75-0.38-1.43-0.58-1.77,0.24c-0.37,0.9-0.92,0.74-1.55,0.31c-1.46-1-3-1.9-4.38-3
							c-1.12-0.89,0.56,3.06-0.21,4.12c-0.27-0.05-3.51-0.67-3.64-0.83c-0.93-1.12-1.46-1.52-2.71-2.26
							c-0.14-0.08-2.32-1.79-2.32-1.95c-0.01-1.79-0.11-3.4-1.29-3.85c-0.47-0.39-0.54-1.57-1.06-1.26c-0.5,0.3-0.07,1.03-0.06,1.57
							c0.01,0.92,0.49,1.69,0.97,2.44c0.31,0.48,0.61,0.99,0.59,1.55c-0.05,1.42,0.56,2.62,1.13,3.83c0.52,1.11,2.13,1.91,2.34,3.1
							c0.06,0.12,0.67,0.26,0.74,0.38c-0.07,0.19,1.4,4.8,1.33,5c-0.95,0.4-2.37,3.29-3.32,3.31c-2.71-0.51-3.3-6.47-4.33-7.05
							c-0.78-0.43-1.04,0.58,0,0c0.31-0.17,0.1,0.34,0,0c-0.62-2.13,0.96,1.99,0,0c-0.02-0.04,3.67,11.82-0.44-0.47
							c-0.08-0.25,0.44,0.27,0.44,0.47c-0.02,1.62,0.89-1.51,0,0c-0.38,0.65-1.46-0.77-1.06-0.19c0.42,0.61,0.53,0.22,1.01,0.79
							c1.55,1.84,0.82,2.44,0.88,4.58c0.04,1.31-1.4,1.52-2.21,2.24c-0.85,0.16-3.26,0.66-3.98,1.08c-2.55-0.05-5.38-1.03-7.68-1.96
							c-0.41-0.16-0.92-0.72-1.11-1.11c-0.57-1.19-1.21-0.91-2.35-0.83c-1.02,0.07-2.31-0.69-3.09-1.52
							c-1.1-1.16-1.16-2.22-1.96-3.47c-0.18-0.29-0.12-0.91-0.07-1.23c0.08-0.53,0.47-0.75,0.99-0.62c0.81,0.22,1.61,0.52,2.43,0.65
							c1.35,0.21,1.59-0.6,2.4-2.18c0.37-0.74,1.81,0.88,2.02,1.19c0.91,1.35,2.23,1.27,3.11,2.64c0.29,0.45,1.08,0.83,1.66,0.74
							c1.06-0.17,1.32,0.65,2.02,1.33c0.5,0.47,0.65,2.05,1.25,2.4c0.31,0.18,0.68,0.45,1.03,0.15c0.29-0.26,0.06-0.61,0.02-0.92
							c-0.24-2.05-1.3-3.79-2.27-5.54c-0.45-0.81-0.73-1.59-0.62-2.51c0.15-1.19-0.43-1.95-1.48-2.42
							c-0.86-0.39-1.85-0.84-2.19-1.67c-0.6-1.47-1.82-1.87-3.09-2.32c-0.28-0.1-0.68-0.09-0.68-0.58
							c3.85-2.68,8.02-4.45,12.79-4.66c0.92-0.04,1.91,0.23,2.77-0.36c0.1-0.04,0.2-0.09,0.3-0.13c0,0,0.07,0.03,0.07,0.03
							c0.22,0.33,0.6,0.41,0.92,0.58c1.78,0.92,3.77,0.36,5.6,0.84c0.55,0.03,1.13-0.03,1.66,0.11c1.57,0.42,3.13,0.92,4.69,1.38
							c1.5,0.08,2.99,0.01,4.45-0.37c0.26-0.03,0.53-0.07,0.79-0.1c0.09-0.03,0.24-0.08,0.33-0.11l0.11-0.06
							c1.02,0.05,2.13,0.11,3.15,0.16c0.63,0.42,1.31,0.71,2.09,0.79c0.69,0.47,1.47,0.71,2.25,0.96c0.97,0.58,1.99,1.03,3.09,1.29
							c1.04,0.63,2.12,1.69,2.82-0.4c0.12-0.36,0.67-0.57,1.1-0.44c0.57,0.17,1.15,0.41,1.66,0.73c0.9,0.58,1.65,0.56,2.54-0.09
							c1.54-1.13,3.21-2.07,5.08-2.55c1.28-0.33,2.4-0.37,3.21,1.1c0.98,1.78,3.95,2.21,5.76,1.01c1.42-0.75,2.47-2.13,4.14-2.45
							c2.18,1.09,3.94,2.77,5.95,4.1c-0.68,1.59-0.63,2.98-1.94,2.13c-0.77-0.5-1.55-1.44-2.22-1.21c-0.05,0.03-0.09,0.06-0.13,0.08
							l-0.18,0.16c-0.1,0.08-0.19,0.16-0.29,0.25c-0.09,0.08-0.18,0.16-0.27,0.24c-0.32,0.01-0.63,0.04-0.82,0.34l-0.01,0.01
							c-0.46,0.08-0.98,0.14-1.05,0.72c-0.05,0.46,0.37,0.58,0.73,0.7c0.77,0.41,1.52,0.86,2.32,1.23c1.01,0.46,1.9,1.21,3.07,1.27
							c2.34,0.11,4.98,2.71,5.05,5.03c0.02,0.55,0.17,0.83,0.61,1.23c2,1.8,4.11,3.51,5.52,5.87c0.32,0.53,3.03,1.84,2.56,2.45
							c-0.53,0.68-0.46,1.66-1.05,1.47c-0.22-0.07,0.2-0.21,0,0c-0.02,0.82-0.74,0.79-0.53,1.52c-0.51,0.36-0.27,0.8-0.66,0.76
							c-0.61-0.31,0.62,0.29,0,0c-0.33-0.15,0.4,0.44,0.55,0.89c0.25,0.72-1.02-0.37,0,0l-0.55-0.89c0.15,0.74,6.2-2.55,6.71-2.19
							c2.43,1.74-4.72,4.63-1.81,3.52c0.15-0.03,0.3-0.06,0.45-0.09c0.46-0.03,0.92-0.26,1.36,0.06c0.46,0.75,0.73,1.81,1.4,2.2
							c1.76,1.03,3.37,2.3,5.26,3.14c2.03,0.9,4.13,0.01,6.17,0.5c0.74,0.18,0.54-0.39,0.22-0.76c-0.89-1.02-1.82-2-2.68-3.05
							c-0.3-0.37-0.88-0.82-0.54-1.31c0.3-0.45,0.98-0.23,1.45-0.13c1.25,0.28,2.48,0.65,4.06,1.07c-1.09-1.84-5.31-1.1-7.35-1.42
							c-2.99-0.47-1.85,0.18-4.86-0.2c-0.57-0.07-1.71-3.7-1.68-4.38c0.07-1.37-0.89-2.08-1.77-2.85c-0.36-0.31-0.88-0.48-0.91-1.11
							c0.71-0.13,1.1,0.34,1.57,0.6c1.57,0.85,3.17,1.66,5.02,1.45c0.73-0.08,1.44-0.27,0.6-1.29c-0.89-1.08-0.69-1.56,0.61-2.02
							c0.5-0.18,1.01-0.39,1.27-0.82c0.68-1.13,1.7-1.88,2.58-1.25c0.38,0.27,0.85,0.41,1.27,0.63c0.48,0.87,1.97-0.54,2.69,0.03
							c0.24,0.19,0.37,0.86,0.47,0.56c0.26-0.82,0.49,0.31-0.47-0.56c-0.11-0.77,0.37-1.32,0.7-1.93c0.14-0.26,0.52-0.65,0.01-0.78
							c-1.57-0.38-2.21,1.6-3.56,0.88c-0.87-0.46-1-0.68-1.45,0.42c-0.56,1.39-4.83,1.79-6.3,1.36c-1.94-0.56-1.82-3.44-3.31-4.72
							c-1.68-1.45-0.56-3.61,1.5-4.51c0.68-0.3,1.39-0.51,2.15-0.57c0.62-0.04-0.73-0.68-0.12-0.78c-0.09-0.45,1.61,0.03,1.42-0.09
							c-1.87-1.1-3.96-1.87-5.49-3.49c1.18-0.25,2.23,0.43,3.39,0.49c0.92,0.05,1.9,0.35,2.6,1.11c0.08,0.09,0.16,0.18,0.24,0.27
							l0.59,0.56c0.16,1.6,2.51,1.27,4.05,1.45c2.56,0.29,3.55,0.22,6.09,0.47c0,0,0,0.01,0,0.01c-0.01,0.79,0.08,1.6-0.04,2.37
							c-0.16,1.11,0.43,1.8,1.31,1.92c1.29,0.17,2.4,0.81,3.74,0.8c-0.02,0,0.72,0.66,0.87,0.72c0.58,0.25,1.22,0.42,1.81,0.63
							c1.25,0.44,2.5,0.87,3.75,1.3c2.47,0.85,3.13,1.53,4.31,2.04l1.66,1.69l0,0c0,0,0.34,0.09,0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
							l0,0l0,0l0,0l0,0c0,0,4.6,2.38,3.43,3.99c-0.25,0.34-0.5,0.7-0.61,1.11c-0.11,0.41-0.05,0.89,0.26,1.18
							c0.28,0.26,0.73,0.34,0.94,0.65c0.19,0.27,0.13,0.68-0.12,0.89l0,0l-0.1-0.01l0,0l0,0l0,0l0,0c0,0,1.42,1.79,1.58,2.27
							c0.25,0.76-0.19,1.57-0.61,2.25c0.42,0.21,0.85,0.43,1.27,0.64c0.18,0.09,0.38,0.22,0.38,0.41c0,0.13-0.09,0.25-0.18,0.35
							l4.4,2.02l2.64,2.2l0,0c0,0,0.89,0.13,1.28,0.29c0.42,0.17,0.28,0.25,0.54,0.5c0.38,0.37,0.91,0.48,1.44,0.61
							C471.76,2660.76,425.21,2684.43,369.9,2672.3"/>
						<path className="st23" d="M402.22,2675.83c-10.08,0-21-0.95-32.34-3.44l-0.04-0.01l-0.02-0.04c-0.1-0.24-0.22-0.48-0.34-0.71
							c-0.15-0.3-0.31-0.61-0.42-0.94c-0.03-0.09-0.06-0.18-0.1-0.27c-0.17-0.45-0.34-0.91-0.12-1.25c0.16-0.25,0.5-0.38,1.04-0.42
							c0.31-0.02,0.52-0.35,0.72-0.67c0.03-0.05,0.06-0.1,0.09-0.15l0.1-0.16c1.46-2.25,2.97-4.59,5.15-6.32l0.02-0.02l0.03,0
							c1.18-0.03,2.16-0.59,3.11-1.13c0.47-0.27,0.95-0.54,1.46-0.76c0.52-0.22,0.81-0.47,0.86-0.75c0.05-0.27-0.13-0.59-0.52-0.94
							c-1.27-1.14-1.69-2.6-1.29-4.47c0.37-1.71,1.33-2.99,2.72-3.59c1.71-0.75,3.66-0.89,5.96-0.42c2.33,0.47,4.21,1.81,6.03,3.11
							c0.61,0.44,1.25,0.89,1.88,1.3c0.1,0.06,0.19,0.13,0.29,0.19c0.53,0.35,1.04,0.68,1.66,0.78l0.04,0.01
							c0.29,0.05,0.5,0.06,0.6-0.18c0.08-0.19-0.07-0.34-0.25-0.5l-0.09-0.07c-0.91-0.79-1.84-1.6-2.78-2.37
							c-0.65-0.53-1.32-1.06-1.97-1.57c-0.29-0.23-0.58-0.45-0.87-0.68l-0.05-0.04l0.21-0.45l0.06,0.01
							c3.02,0.66,5.75,1.97,7.79,3.03c0.22,0.11,0.43,0.23,0.65,0.34c1.32,0.7,2.68,1.43,4.21,1.65c0.47,0.07,1.06,0.04,1.26-0.19
							c0.08-0.09,0.09-0.21,0.04-0.36c-0.12-0.38-0.22-0.77-0.32-1.15c-0.3-1.17-0.6-2.37-1.62-3.29c-0.72-0.66-1.41-0.57-2.14-0.49
							c-0.21,0.03-0.44,0.05-0.66,0.06l-0.07,0c-0.42,0.02-0.94,0.03-1.13-0.44c-0.19-0.47,0.18-0.72,0.47-0.92
							c0.07-0.04,0.13-0.09,0.19-0.13c0.2-0.16,0.4-0.31,0.6-0.47c0.42-0.32,0.85-0.66,1.27-0.99c0.52-0.42,0.49-1.02,0.41-1.6
							c-0.07-0.53-0.46-0.52-0.88-0.51c-0.04,0-0.09,0-0.13,0c-0.34,0-0.58-0.08-0.73-0.24c-0.22-0.24-0.21-0.63-0.17-0.97
							c0.14-1.22-0.25-2.01-1.21-2.48c-0.67-0.33-0.77-0.96-0.78-1.47c-0.02-0.78,0.17-1.49,0.36-2.09
							c0.01-0.04,0.03-0.09,0.05-0.13c0.09-0.25,0.14-0.41-0.02-0.56c-0.16-0.15-0.33-0.1-0.58,0.01c-0.04,0.02-0.08,0.03-0.11,0.05
							c-0.37,0.15-0.74,0.3-1.12,0.45c-0.78,0.32-1.59,0.65-2.4,0.94c-0.6,0.21-0.93,0.63-1.02,1.27c-0.15,1.07-0.65,2-1.13,2.9
							c-0.19,0.36-0.39,0.73-0.57,1.1c-0.21,0.45-0.41,0.66-0.64,0.67c-0.23,0.02-0.48-0.18-0.76-0.61
							c-1.36-2.11-2.79-4.23-4.18-6.27c-0.37-0.54-0.74-1.09-1.11-1.63c-0.03-0.05-0.07-0.11-0.11-0.18
							c-0.09-0.16-0.22-0.39-0.32-0.39c0,0,0,0,0,0c-0.05,0-0.1,0.05-0.16,0.15c-0.75,1.26-1.57,0.85-2.29,0.49
							c-0.25-0.12-0.48-0.24-0.71-0.3c-0.42-0.11-0.85-0.26-0.92-0.57c-0.05-0.25,0.15-0.53,0.6-0.88c0.48-0.37,0.64-1.08,0.63-1.62
							c-0.01-0.58-0.2-1.06-0.49-1.27c-1.16-0.82-4.13-0.6-5.3,0.4c-0.52,0.44-0.95,0.72-1.37,0.62c-0.32-0.08-0.59-0.37-0.82-0.9
							c-0.13-0.31-0.41-0.58-0.68-0.84c-0.07-0.06-0.13-0.13-0.2-0.19c-0.04-0.04-0.08-0.09-0.13-0.14
							c-0.2-0.24-0.34-0.37-0.57-0.24c-0.22,0.12-0.2,0.34-0.16,0.65c0.01,0.06,0.01,0.11,0.02,0.17c0.02,0.27,0.07,0.54,0.11,0.8
							c0.05,0.28,0.09,0.57,0.11,0.87c0,0.07,0.02,0.14,0.03,0.21c0.05,0.31,0.11,0.69-0.31,0.86c-0.35,0.14-0.58-0.14-0.76-0.36
							c-0.04-0.05-0.07-0.09-0.11-0.13c-0.21-0.24-0.42-0.49-0.61-0.73c-0.23-0.29-0.48-0.59-0.74-0.87
							c-0.17-0.18-0.31-0.42-0.45-0.65c-0.3-0.51-0.58-0.99-1.09-0.8c-0.8,0.29-0.73,1.49-0.69,2.29c0.08,1.46-0.41,2.67-1.59,3.95
							l-0.08,0.08c-0.85,0.92-1.24,1.34-1.59,1.27c-0.35-0.07-0.54-0.63-0.94-1.85c-0.61-1.81-1.33-3.49-3.45-4.11
							c-0.92-0.27-1.71-0.57-2.4-0.91c-0.44-0.22-0.89-0.41-1.23-0.28c-0.18,0.07-0.32,0.23-0.43,0.48
							c-0.16,0.38-0.36,0.6-0.61,0.68c-0.36,0.11-0.76-0.12-1.07-0.33c-0.47-0.32-0.97-0.64-1.44-0.95
							c-0.99-0.64-2.01-1.31-2.94-2.05c-0.14-0.11-0.2-0.12-0.22-0.11c-0.12,0.07,0.02,0.81,0.11,1.35
							c0.19,1.03,0.42,2.31,0.01,2.86l-0.03,0.04l-0.05-0.01c-0.04-0.01-3.52-0.65-3.69-0.86c-0.91-1.1-1.44-1.51-2.68-2.25
							c-0.02-0.01-2.36-1.78-2.36-2.02c-0.01-1.74-0.11-3.34-1.24-3.77l-0.02-0.01c-0.21-0.17-0.34-0.48-0.46-0.74
							c-0.11-0.25-0.22-0.5-0.35-0.54c-0.04-0.01-0.1,0-0.16,0.03c-0.29,0.17-0.21,0.54-0.11,0.93c0.04,0.19,0.09,0.39,0.09,0.56
							c0.01,0.92,0.51,1.7,0.95,2.39c0.33,0.51,0.62,1.02,0.6,1.59c-0.04,1.32,0.48,2.43,1.03,3.6l0.09,0.2
							c0.22,0.47,0.66,0.9,1.08,1.31c0.56,0.54,1.13,1.1,1.26,1.79c0.04,0.04,0.21,0.1,0.32,0.15c0.23,0.09,0.37,0.15,0.41,0.23
							l0.02,0.03l-0.01,0.04c-0.01,0.13,0.36,1.41,0.66,2.45c0.55,1.88,0.7,2.44,0.66,2.55l-0.01,0.03l-0.03,0.01
							c-0.41,0.17-0.95,0.88-1.47,1.56c-0.68,0.9-1.33,1.75-1.89,1.76h-0.01l-0.01,0c-1.77-0.33-2.63-2.84-3.32-4.85
							c-0.22-0.65-0.43-1.25-0.63-1.66c-0.01,0-0.02,0.01-0.02,0.01c-0.02,0.01-0.04,0.02-0.06,0.01c0.05,0.16,0.09,0.3,0.14,0.45
							c0.53,0.77,0.62,1.34,0.6,1.97c0.63,2.09,0.59,2.1,0.51,2.13c-0.07,0.02-0.11,0.03-0.57-1.28c-0.03,0.37-0.06,0.78-0.04,1.28
							c0.03,0.96-0.69,1.36-1.39,1.75c-0.3,0.17-0.6,0.34-0.85,0.55l-0.02,0.01l-0.02,0c-1.16,0.22-3.3,0.69-3.95,1.07l-0.02,0.01
							l-0.02,0c-2.67-0.05-5.68-1.15-7.71-1.97c-0.42-0.17-0.96-0.75-1.15-1.15c-0.47-0.96-0.94-0.91-1.8-0.82
							c-0.15,0.01-0.3,0.03-0.47,0.04c-1.1,0.08-2.4-0.75-3.16-1.54c-0.67-0.71-0.96-1.37-1.26-2.07c-0.19-0.45-0.4-0.92-0.71-1.41
							c-0.14-0.23-0.17-0.65-0.08-1.29c0.04-0.27,0.16-0.48,0.34-0.6c0.2-0.13,0.46-0.16,0.75-0.08c0.26,0.07,0.52,0.15,0.78,0.23
							c0.54,0.16,1.09,0.33,1.64,0.42c1.15,0.18,1.45-0.41,2.09-1.7c0.07-0.14,0.14-0.29,0.22-0.44c0.07-0.14,0.19-0.22,0.34-0.23
							c0.01,0,0.02,0,0.03,0c0.65,0,1.69,1.27,1.79,1.41c0.44,0.65,0.99,0.97,1.52,1.27c0.56,0.32,1.14,0.66,1.59,1.37
							c0.28,0.45,1.05,0.79,1.57,0.7c0.83-0.14,1.2,0.31,1.62,0.82c0.14,0.18,0.29,0.36,0.47,0.53c0.24,0.23,0.39,0.67,0.56,1.14
							c0.18,0.53,0.38,1.07,0.68,1.25l0.09,0.05c0.28,0.17,0.57,0.34,0.85,0.1c0.17-0.15,0.12-0.35,0.06-0.58
							c-0.02-0.09-0.05-0.18-0.06-0.27c-0.22-1.84-1.11-3.45-1.98-5.01c-0.09-0.17-0.19-0.33-0.28-0.5
							c-0.38-0.69-0.75-1.54-0.63-2.56c0.13-1.08-0.34-1.84-1.43-2.33c-0.83-0.37-1.87-0.83-2.23-1.72
							c-0.58-1.41-1.73-1.82-2.96-2.25l-0.08-0.03c-0.05-0.02-0.1-0.03-0.16-0.05c-0.25-0.07-0.57-0.15-0.58-0.61l0-0.04l0.04-0.03
							c4.22-2.95,8.42-4.48,12.83-4.67c0.25-0.01,0.5,0,0.75,0.01c0.67,0.03,1.37,0.06,1.98-0.36l0.34-0.15l0.03,0.01
							c0.06,0.02,0.1,0.04,0.11,0.07c0.15,0.21,0.38,0.31,0.62,0.42c0.09,0.04,0.18,0.08,0.26,0.12c1.03,0.53,2.15,0.56,3.24,0.59
							c0.78,0.02,1.58,0.04,2.34,0.24c0.16,0.01,0.33,0.01,0.51,0.01c0.38,0,0.78,0,1.15,0.1c1.13,0.3,2.27,0.65,3.38,0.99
							c0.43,0.13,0.87,0.27,1.3,0.4c1.71,0.09,3.11-0.03,4.42-0.37l0.8-0.1l0.32-0.1l0.11-0.07l3.2,0.16l0.02,0.01
							c0.67,0.45,1.34,0.7,2.05,0.77l0.02,0l0.02,0.01c0.64,0.44,1.4,0.68,2.12,0.91l0.13,0.04c1.05,0.63,2.06,1.05,3.07,1.28
							l0.02,0.01c0.13,0.08,0.26,0.16,0.39,0.25c0.55,0.36,1.06,0.69,1.5,0.54c0.32-0.11,0.59-0.49,0.81-1.14
							c0.13-0.39,0.73-0.64,1.2-0.49c0.67,0.2,1.22,0.44,1.68,0.74c0.88,0.57,1.59,0.54,2.45-0.09c1.77-1.3,3.44-2.14,5.11-2.56
							c1.14-0.29,2.42-0.46,3.31,1.14c0.4,0.73,1.17,1.27,2.17,1.51c1.21,0.3,2.54,0.09,3.48-0.53c0.53-0.28,1.01-0.65,1.47-1.01
							c0.8-0.61,1.63-1.25,2.7-1.46l0.03-0.01l0.03,0.01c1.37,0.68,2.6,1.62,3.78,2.52c0.7,0.54,1.43,1.09,2.18,1.58l0.06,0.04
							l-0.03,0.06c-0.16,0.38-0.28,0.75-0.39,1.07c-0.22,0.67-0.39,1.2-0.77,1.33c-0.24,0.08-0.53,0.01-0.91-0.24
							c-0.22-0.14-0.44-0.32-0.66-0.5c-0.54-0.43-1.05-0.85-1.48-0.71l-0.13,0.08l-0.16,0.15l-0.58,0.5l-0.03,0
							c-0.3,0.01-0.58,0.03-0.75,0.31l-0.03,0.04l-0.09,0.02c-0.44,0.08-0.86,0.15-0.91,0.64c-0.04,0.35,0.23,0.47,0.64,0.6
							l0.04,0.01c0.25,0.13,0.49,0.26,0.72,0.39c0.52,0.29,1.06,0.59,1.6,0.83c0.3,0.14,0.6,0.3,0.89,0.47
							c0.66,0.37,1.35,0.76,2.15,0.8c2.42,0.12,5.06,2.74,5.13,5.11c0.02,0.51,0.15,0.78,0.58,1.17c0.25,0.23,0.51,0.45,0.76,0.68
							c1.74,1.54,3.54,3.14,4.77,5.21c0.11,0.18,0.55,0.48,1.02,0.8c0.75,0.52,1.54,1.05,1.6,1.48c0.02,0.1-0.01,0.19-0.07,0.27
							c-0.2,0.26-0.31,0.56-0.41,0.83c-0.14,0.38-0.27,0.73-0.63,0.69c-0.03,0.35-0.19,0.54-0.34,0.71
							c-0.17,0.2-0.29,0.34-0.19,0.68l0.02,0.06l-0.05,0.03c-0.23,0.16-0.29,0.34-0.34,0.48c-0.03,0.09-0.06,0.18-0.13,0.23
							c0,0,0.01,0,0.01,0.01c0.53-0.02,2.06-0.68,3.32-1.22c1.81-0.78,2.93-1.25,3.19-1.06c0.38,0.27,0.56,0.59,0.55,0.94
							c-0.04,1-1.62,2.05-2.51,2.6c0.03-0.01,0.05-0.02,0.08-0.03l0.46-0.09c0.13-0.01,0.25-0.03,0.36-0.05
							c0.35-0.06,0.71-0.13,1.06,0.13l0.02,0.02c0.15,0.24,0.27,0.51,0.4,0.78c0.27,0.56,0.54,1.14,0.98,1.4
							c0.63,0.37,1.25,0.78,1.86,1.17c1.07,0.7,2.19,1.43,3.39,1.96c1.13,0.5,2.31,0.44,3.45,0.37c0.89-0.05,1.81-0.1,2.71,0.12
							c0.21,0.05,0.35,0.04,0.39-0.03c0.06-0.1-0.05-0.37-0.25-0.6c-0.3-0.34-0.6-0.68-0.91-1.01c-0.59-0.66-1.21-1.34-1.78-2.03
							c-0.05-0.06-0.11-0.13-0.17-0.19c-0.31-0.34-0.69-0.76-0.38-1.22c0.08-0.12,0.19-0.2,0.31-0.25c-0.78,0-1.52-0.01-2.09-0.1
							c-1.53-0.24-1.97-0.19-2.44-0.13c-0.46,0.06-0.95,0.11-2.42-0.07c-0.68-0.09-1.78-3.9-1.75-4.46
							c0.06-1.23-0.73-1.91-1.56-2.63l-0.18-0.16c-0.08-0.07-0.17-0.13-0.27-0.2c-0.3-0.21-0.64-0.45-0.67-0.96l0-0.07l0.07-0.01
							c0.57-0.1,0.93,0.15,1.29,0.39c0.11,0.08,0.22,0.15,0.34,0.22c1.67,0.91,3.19,1.64,4.97,1.44c0.39-0.04,0.79-0.12,0.88-0.35
							c0.07-0.18-0.04-0.45-0.34-0.81c-0.46-0.57-0.63-0.97-0.54-1.3c0.09-0.34,0.45-0.59,1.18-0.85c0.46-0.16,0.97-0.37,1.22-0.79
							c0.16-0.27,0.34-0.51,0.52-0.71c-1.56,0.43-3.62,0.47-4.51,0.21c-1.15-0.33-1.61-1.46-2.05-2.55
							c-0.33-0.81-0.67-1.65-1.3-2.19c-0.65-0.56-0.94-1.26-0.82-2.02c0.16-1.04,1.08-2.07,2.34-2.62c0.77-0.34,1.49-0.53,2.18-0.58
							c0.05,0,0.07-0.01,0.08-0.01c-0.02-0.05-0.12-0.14-0.18-0.2c-0.15-0.15-0.3-0.28-0.25-0.42c0.02-0.08,0.1-0.11,0.16-0.13
							c0.01-0.05,0.03-0.08,0.06-0.11c0.15-0.16,0.59-0.11,1.06-0.04c-0.41-0.23-0.84-0.45-1.25-0.67c-1.38-0.72-2.8-1.46-3.92-2.64
							l-0.1-0.11l0.14-0.03c0.73-0.15,1.41,0.04,2.08,0.22c0.43,0.12,0.88,0.24,1.34,0.27c0.77,0.04,1.86,0.27,2.66,1.13l0.24,0.27
							l0.61,0.57l0,0.03c0.12,1.19,1.54,1.25,2.91,1.31c0.38,0.02,0.73,0.03,1.06,0.07c1.38,0.16,2.29,0.21,3.25,0.26
							c0.82,0.05,1.66,0.09,2.84,0.21l0.05,0l0.03,0.08c0,0.24,0,0.49,0.01,0.74c0.01,0.54,0.03,1.11-0.05,1.65
							c-0.08,0.51,0.01,0.96,0.26,1.28c0.22,0.3,0.56,0.48,0.98,0.54c0.55,0.07,1.08,0.23,1.58,0.39c0.68,0.2,1.37,0.42,2.12,0.42
							c0.01,0,0.01,0,0.02,0h0c0,0,0,0,0,0l0,0.08l0.07-0.05c0.05,0.07,0.71,0.64,0.84,0.7c0.38,0.17,0.8,0.3,1.2,0.43
							c0.2,0.07,0.41,0.13,0.61,0.2c1.25,0.44,2.5,0.87,3.75,1.3c1.61,0.56,2.44,1.03,3.17,1.45c0.39,0.22,0.73,0.42,1.14,0.6
							l0.03,0.02l1.64,1.68l0.13,0.03l-0.01,0.03c0.61,0.33,3.18,1.79,3.51,3.15c0.08,0.33,0.02,0.63-0.17,0.89
							c-0.23,0.31-0.49,0.67-0.59,1.08c-0.11,0.43-0.02,0.86,0.24,1.1c0.11,0.11,0.26,0.18,0.41,0.26c0.2,0.1,0.41,0.21,0.55,0.41
							c0.21,0.3,0.15,0.76-0.13,1c0.32,0.41,1.36,1.75,1.49,2.18c0.25,0.77-0.15,1.55-0.57,2.24l1.19,0.6
							c0.2,0.1,0.42,0.24,0.43,0.49c0,0.12-0.06,0.23-0.13,0.32l4.32,1.98l2.62,2.19c0.15,0.02,0.91,0.14,1.28,0.29
							c0.28,0.11,0.34,0.19,0.41,0.31c0.03,0.06,0.07,0.12,0.15,0.2c0.34,0.33,0.81,0.45,1.31,0.57l0.09,0.02
							c0.07,0.02,0.1,0.07,0.09,0.14c-0.14,0.61-11.79,6.57-30.03,10.87C430.53,2673.68,417.32,2675.83,402.22,2675.83z
							 M369.96,2672.23c28.21,6.18,53.8,2.86,70.29-1.01c18.34-4.3,29.3-10.02,30.04-10.75l-0.02-0.01
							c-0.5-0.12-1.01-0.25-1.38-0.61c-0.1-0.1-0.15-0.17-0.18-0.23c-0.05-0.09-0.09-0.15-0.33-0.25c-0.37-0.15-1.25-0.28-1.26-0.28
							l-0.02,0l-2.65-2.21l-4.49-2.06l0.08-0.08c0.09-0.1,0.16-0.19,0.16-0.29c0-0.15-0.18-0.26-0.34-0.34l-1.35-0.68l0.05-0.08
							c0.41-0.68,0.84-1.46,0.6-2.18c-0.15-0.47-1.55-2.23-1.56-2.25l-0.12-0.15l0.26,0.02c0.2-0.19,0.23-0.53,0.08-0.76
							c-0.11-0.16-0.3-0.26-0.49-0.36c-0.15-0.08-0.31-0.16-0.44-0.28c-0.3-0.28-0.42-0.78-0.29-1.26c0.12-0.44,0.38-0.81,0.62-1.13
							c0.16-0.23,0.21-0.47,0.14-0.76c-0.33-1.4-3.36-3.01-3.54-3.1l-0.02-0.01l-0.67-0.34l0.45,0.12l-1.43-1.47
							c-0.41-0.18-0.77-0.38-1.14-0.6c-0.73-0.41-1.55-0.89-3.15-1.44c-1.25-0.43-2.5-0.87-3.75-1.3c-0.2-0.07-0.4-0.13-0.6-0.2
							c-0.41-0.13-0.83-0.26-1.22-0.44c-0.01,0-0.67-0.49-0.87-0.72c-0.77,0-1.48-0.22-2.17-0.42c-0.5-0.15-1.02-0.31-1.56-0.38
							c-0.46-0.06-0.84-0.27-1.09-0.6c-0.27-0.36-0.37-0.85-0.29-1.41c0.08-0.53,0.06-1.08,0.05-1.62
							c-0.01-0.22-0.01-0.45-0.01-0.67c-1.13-0.11-1.96-0.16-2.76-0.2c-0.97-0.05-1.88-0.11-3.26-0.26
							c-0.32-0.04-0.67-0.05-1.05-0.07c-1.37-0.05-2.91-0.12-3.07-1.42l-0.57-0.54l-0.25-0.28c-0.76-0.82-1.8-1.04-2.54-1.08
							c-0.47-0.03-0.93-0.15-1.37-0.27c-0.62-0.17-1.21-0.34-1.85-0.24c1.08,1.1,2.45,1.81,3.77,2.5c0.53,0.28,1.08,0.56,1.6,0.87
							c0.07,0.04,0.06,0.1,0.05,0.11c-0.03,0.08-0.07,0.07-0.34,0.02c-0.26-0.04-0.95-0.16-1.07-0.04
							c-0.01,0.01-0.01,0.01-0.01,0.04l0.02,0.08l-0.08,0.01c-0.08,0.01-0.09,0.03-0.09,0.03c0,0.04,0.14,0.18,0.21,0.25
							c0.15,0.14,0.27,0.26,0.23,0.38c-0.03,0.09-0.15,0.11-0.24,0.12c-0.67,0.05-1.37,0.23-2.12,0.56c-1.22,0.53-2.1,1.51-2.25,2.5
							c-0.11,0.7,0.16,1.35,0.77,1.87c0.65,0.56,1,1.42,1.34,2.26c0.45,1.1,0.87,2.14,1.94,2.45c0.99,0.29,3.23,0.18,4.74-0.32
							c0.3-0.27,0.61-0.45,0.92-0.51c0.05-0.01,0.11-0.02,0.16-0.02c0.18-0.14,0.31-0.29,0.37-0.46c0.22-0.54,0.36-0.76,0.55-0.83
							c0.21-0.07,0.42,0.04,0.78,0.24c0.07,0.04,0.15,0.08,0.23,0.12c0.65,0.34,1.12,0.02,1.65-0.34c0.51-0.35,1.08-0.74,1.88-0.55
							c0.16,0.04,0.26,0.11,0.3,0.21c0.07,0.17-0.06,0.37-0.17,0.54c-0.03,0.05-0.06,0.1-0.09,0.14c-0.07,0.12-0.14,0.24-0.21,0.36
							c-0.28,0.47-0.55,0.92-0.48,1.49c0,0,0.01,0.01,0.01,0.01c0.2,0.18,0.34,0.26,0.43,0.29c0.05-0.06,0.1-0.1,0.15-0.1
							c0.05,0,0.09,0.04,0.11,0.09c0.02,0.06-0.01,0.12-0.07,0.16c-0.03,0.02-0.06,0.03-0.1,0.03c-0.02,0.04-0.04,0.08-0.06,0.15
							c-0.01,0.03-0.04,0.13-0.13,0.13c-0.1,0.01-0.15-0.1-0.23-0.27c-0.06-0.12-0.13-0.27-0.21-0.35
							c-0.01-0.01-0.02-0.02-0.03-0.03c-0.3-0.24-0.79-0.08-1.26,0.08c-0.56,0.19-1.14,0.38-1.44-0.11
							c-0.14-0.07-0.29-0.13-0.43-0.2c-0.28-0.12-0.57-0.25-0.82-0.42c-0.21-0.15-0.43-0.22-0.66-0.21c-0.28,0.22-0.66,0.4-1.1,0.54
							c-0.25,0.23-0.49,0.54-0.7,0.89c-0.28,0.46-0.82,0.68-1.31,0.86c-0.67,0.24-1,0.46-1.08,0.74c-0.08,0.27,0.09,0.63,0.51,1.15
							c0.34,0.42,0.46,0.74,0.37,0.98c-0.12,0.31-0.56,0.4-1.02,0.45c-1.83,0.2-3.38-0.54-5.07-1.46c-0.12-0.07-0.24-0.15-0.35-0.22
							c-0.32-0.22-0.63-0.44-1.09-0.38c0.05,0.38,0.31,0.56,0.59,0.76c0.1,0.07,0.19,0.14,0.28,0.21l0.18,0.15
							c0.83,0.71,1.69,1.45,1.62,2.76c-0.03,0.68,1.11,4.23,1.61,4.29c1.45,0.18,1.92,0.13,2.38,0.07c0.48-0.06,0.94-0.11,2.49,0.13
							c0.59,0.09,1.37,0.1,2.2,0.1c2.07,0.01,4.41,0.02,5.21,1.36l0.1,0.18l-0.2-0.05c-0.47-0.13-0.91-0.25-1.33-0.36
							c-0.99-0.27-1.85-0.51-2.72-0.71c-0.05-0.01-0.1-0.02-0.15-0.03c-0.44-0.1-0.98-0.23-1.22,0.12
							c-0.22,0.33,0.01,0.63,0.36,1.02c0.06,0.07,0.12,0.13,0.18,0.2c0.57,0.69,1.18,1.37,1.77,2.03c0.3,0.34,0.61,0.68,0.91,1.02
							c0.22,0.25,0.39,0.59,0.27,0.79c-0.08,0.14-0.27,0.17-0.57,0.1c-0.87-0.21-1.78-0.16-2.66-0.11
							c-1.16,0.06-2.36,0.13-3.52-0.39c-1.22-0.54-2.34-1.27-3.42-1.98c-0.6-0.39-1.22-0.8-1.85-1.16
							c-0.48-0.28-0.77-0.89-1.04-1.47c-0.12-0.25-0.24-0.52-0.38-0.75c-0.29-0.2-0.6-0.15-0.92-0.09
							c-0.12,0.02-0.25,0.04-0.37,0.05l-0.43,0.09c-0.76,0.29-0.76,0.29-0.8,0.2c-0.04-0.08-0.01-0.1,0.36-0.33
							c0.72-0.44,2.62-1.6,2.66-2.6c0.01-0.3-0.15-0.57-0.48-0.8c-0.2-0.14-1.83,0.56-3.03,1.08c-1.69,0.73-2.9,1.23-3.42,1.23
							l0.11,0.17c0.12,0.16,0.25,0.33,0.31,0.49l0.12,0.19l-0.08-0.03c0.01,0.07-0.01,0.14-0.07,0.18
							c-0.12,0.08-0.32-0.05-0.43-0.16c-0.12-0.12-0.11-0.21-0.08-0.25c0.04-0.07,0.13-0.08,0.28-0.05l-0.18-0.29
							c-0.06-0.07-0.12-0.15-0.17-0.21c-0.11-0.12-0.17-0.19-0.18-0.25c-0.1-0.06-0.11-0.08-0.08-0.14
							c0.03-0.05,0.05-0.06,0.13-0.03l-0.06-0.28l0.22,0.35c0,0,0,0,0.01,0c0.09,0,0.11-0.05,0.16-0.19
							c0.05-0.15,0.12-0.35,0.36-0.53c-0.09-0.39,0.07-0.59,0.23-0.78c0.14-0.16,0.28-0.33,0.3-0.66c-0.06-0.03-0.07-0.08-0.07-0.11
							c0-0.07,0.07-0.12,0.13-0.13c0.08-0.02,0.12,0.01,0.15,0.05c0.02,0.03,0.02,0.05,0.01,0.08c0.18,0,0.26-0.19,0.41-0.58
							c0.1-0.28,0.22-0.6,0.44-0.88c0.03-0.04,0.04-0.09,0.04-0.14c-0.06-0.36-0.87-0.92-1.53-1.36c-0.5-0.34-0.94-0.64-1.07-0.85
							c-1.22-2.04-3.01-3.63-4.74-5.17c-0.25-0.23-0.51-0.45-0.76-0.68c-0.47-0.42-0.62-0.73-0.64-1.29
							c-0.07-2.25-2.67-4.84-4.98-4.95c-0.84-0.04-1.54-0.44-2.23-0.82c-0.28-0.16-0.58-0.32-0.87-0.46
							c-0.55-0.25-1.09-0.55-1.62-0.84c-0.24-0.13-0.47-0.26-0.71-0.39l-0.03-0.01c-0.36-0.12-0.81-0.27-0.75-0.78
							c0.07-0.61,0.61-0.71,1.05-0.78l0.03-0.01c0.21-0.31,0.53-0.35,0.85-0.36l0.24-0.22l0.47-0.4l0.16-0.1
							c0.52-0.18,1.1,0.28,1.65,0.73c0.21,0.17,0.43,0.35,0.65,0.49c0.33,0.22,0.58,0.29,0.76,0.22c0.3-0.11,0.46-0.6,0.67-1.23
							c0.1-0.31,0.22-0.66,0.37-1.02c-0.73-0.49-1.44-1.03-2.13-1.55c-1.17-0.89-2.38-1.82-3.73-2.49c-1.02,0.21-1.82,0.82-2.6,1.42
							c-0.47,0.36-0.96,0.73-1.49,1.02c-0.97,0.64-2.35,0.85-3.6,0.55c-1.04-0.25-1.84-0.82-2.27-1.59
							c-0.75-1.35-1.75-1.41-3.12-1.06c-1.65,0.42-3.3,1.25-5.06,2.53c-0.91,0.67-1.7,0.7-2.63,0.1c-0.44-0.28-0.99-0.53-1.63-0.72
							c-0.39-0.12-0.89,0.08-1,0.39c-0.24,0.71-0.53,1.11-0.91,1.24c-0.52,0.18-1.09-0.19-1.65-0.55c-0.12-0.08-0.25-0.16-0.37-0.24
							c-1.02-0.24-2.04-0.66-3.1-1.29l-0.1-0.03c-0.73-0.23-1.49-0.47-2.15-0.92c-0.72-0.08-1.41-0.34-2.09-0.79l-3.1-0.15
							l-0.09,0.05l-0.35,0.11l-0.81,0.1c-1.31,0.34-2.74,0.46-4.47,0.37l-0.02,0c-0.44-0.13-0.88-0.26-1.31-0.4
							c-1.1-0.34-2.24-0.68-3.37-0.99c-0.35-0.09-0.74-0.09-1.11-0.1c-0.17,0-0.35,0-0.53-0.01c-0.76-0.2-1.56-0.22-2.32-0.24
							c-1.11-0.03-2.25-0.06-3.31-0.61c-0.08-0.04-0.17-0.08-0.25-0.12c-0.25-0.11-0.51-0.22-0.69-0.47l-0.01,0l-0.26,0.11
							c-0.64,0.44-1.37,0.41-2.07,0.38c-0.24-0.01-0.5-0.02-0.73-0.01c-4.36,0.2-8.52,1.71-12.71,4.62c0.02,0.3,0.21,0.35,0.45,0.41
							c0.06,0.02,0.12,0.03,0.17,0.05l0.08,0.03c1.26,0.44,2.45,0.86,3.06,2.34c0.34,0.82,1.34,1.27,2.14,1.63
							c1.17,0.52,1.67,1.34,1.53,2.5c-0.12,0.97,0.22,1.76,0.61,2.46c0.09,0.17,0.18,0.33,0.28,0.5c0.87,1.57,1.78,3.19,2,5.07
							c0.01,0.08,0.03,0.16,0.06,0.25c0.07,0.25,0.15,0.53-0.11,0.75c-0.36,0.32-0.75,0.08-1.04-0.09l-0.08-0.05
							c-0.36-0.21-0.56-0.78-0.75-1.34c-0.15-0.43-0.31-0.87-0.52-1.07c-0.19-0.18-0.34-0.36-0.49-0.54
							c-0.41-0.49-0.73-0.88-1.47-0.76c-0.59,0.1-1.42-0.27-1.74-0.77c-0.43-0.68-0.97-0.99-1.53-1.32
							c-0.55-0.32-1.11-0.64-1.57-1.32c-0.19-0.27-1.18-1.37-1.67-1.34c-0.09,0.01-0.16,0.05-0.2,0.14
							c-0.08,0.15-0.15,0.3-0.22,0.44c-0.64,1.28-0.99,1.98-2.27,1.79c-0.57-0.09-1.13-0.26-1.67-0.42
							c-0.25-0.08-0.52-0.16-0.77-0.23c-0.24-0.07-0.46-0.05-0.61,0.06c-0.15,0.1-0.24,0.27-0.27,0.49
							c-0.08,0.59-0.06,0.99,0.06,1.18c0.32,0.5,0.53,0.97,0.72,1.43c0.3,0.69,0.58,1.34,1.23,2.03c0.75,0.79,2.03,1.56,3.03,1.49
							c0.16-0.01,0.32-0.03,0.46-0.04c0.87-0.09,1.45-0.15,1.97,0.92c0.18,0.37,0.68,0.92,1.07,1.07c2.01,0.81,4.98,1.9,7.62,1.96
							c0.84-0.47,3.66-1.01,3.96-1.07c0.25-0.22,0.56-0.39,0.86-0.55c0.69-0.38,1.33-0.74,1.31-1.6c-0.02-0.61,0.03-1.1,0.06-1.52
							c0-0.05,0.01-0.1,0.01-0.14c-0.23-0.67-0.55-1.61-0.99-2.93c-0.19-0.21-0.31-0.25-0.42-0.29c-0.15-0.05-0.3-0.11-0.54-0.45
							c-0.1-0.15-0.14-0.2-0.07-0.25c0.06-0.05,0.11-0.01,0.2,0.07c0.07,0.06,0.18,0.16,0.3,0.24c0.06-0.06,0.15-0.1,0.26-0.11
							c-0.03-0.08-0.06-0.17-0.08-0.25c-0.01-0.02-0.03-0.1,0.02-0.15c0.02-0.02,0.06-0.04,0.13-0.01c0.04,0.02,0.1,0.06,0.16,0.12
							c-0.04-0.18-0.01-0.2,0.05-0.22c0.06-0.02,0.11-0.04,0.28,0.38c0.13-0.18,0.21-0.21,0.28-0.18c0.04,0.02,0.16,0.07,0.02,0.54
							c-0.02,0.07-0.04,0.14-0.07,0.2c0.27,0.41,0.52,1.13,0.81,1.99c0.67,1.97,1.51,4.42,3.18,4.74c0.48-0.02,1.12-0.87,1.75-1.69
							c0.54-0.71,1.05-1.38,1.49-1.59c-0.02-0.22-0.38-1.44-0.66-2.42c-0.48-1.66-0.66-2.29-0.67-2.49
							c-0.05-0.04-0.21-0.1-0.31-0.14c-0.23-0.09-0.37-0.15-0.41-0.23l-0.01-0.03c-0.11-0.65-0.67-1.19-1.22-1.72
							c-0.43-0.42-0.88-0.86-1.12-1.36l-0.09-0.2c-0.54-1.14-1.09-2.32-1.05-3.68c0.02-0.53-0.26-1.01-0.57-1.5
							c-0.45-0.71-0.97-1.52-0.98-2.48c0-0.16-0.05-0.35-0.09-0.53c-0.1-0.41-0.21-0.88,0.19-1.11c0.1-0.06,0.2-0.08,0.29-0.05
							c0.2,0.07,0.32,0.33,0.45,0.63c0.11,0.25,0.23,0.53,0.4,0.67c1.21,0.47,1.32,2.11,1.33,3.91c0.13,0.22,2.09,1.78,2.28,1.89
							c1.26,0.75,1.8,1.16,2.73,2.28c0.17,0.13,2.82,0.66,3.54,0.79c0.33-0.53,0.11-1.73-0.07-2.69c-0.17-0.91-0.24-1.4-0.04-1.52
							c0.13-0.08,0.29,0.03,0.41,0.12c0.93,0.74,1.95,1.4,2.93,2.04c0.48,0.31,0.97,0.63,1.44,0.96c0.41,0.28,0.7,0.38,0.92,0.31
							c0.2-0.06,0.36-0.25,0.5-0.58c0.13-0.3,0.3-0.49,0.53-0.57c0.4-0.15,0.89,0.05,1.36,0.29c0.68,0.34,1.46,0.64,2.37,0.9
							c2.19,0.64,2.93,2.36,3.56,4.21c0.36,1.08,0.57,1.68,0.82,1.74c0.25,0.05,0.67-0.4,1.43-1.22l0.08-0.08
							c1.15-1.24,1.63-2.42,1.55-3.82c-0.04-0.79-0.12-2.12,0.8-2.45c0.64-0.24,0.99,0.35,1.29,0.87c0.13,0.23,0.27,0.46,0.42,0.62
							c0.27,0.28,0.51,0.58,0.75,0.88c0.19,0.24,0.4,0.49,0.61,0.73c0.04,0.04,0.08,0.09,0.12,0.14c0.2,0.25,0.34,0.4,0.57,0.31
							c0.28-0.11,0.27-0.33,0.21-0.68c-0.01-0.08-0.02-0.16-0.03-0.23c-0.02-0.28-0.07-0.57-0.11-0.85
							c-0.04-0.26-0.09-0.54-0.11-0.81c0-0.05-0.01-0.1-0.02-0.16c-0.04-0.28-0.08-0.63,0.25-0.82c0.37-0.21,0.6,0.07,0.77,0.28
							c0.04,0.05,0.08,0.09,0.12,0.13c0.06,0.06,0.13,0.13,0.2,0.19c0.28,0.27,0.57,0.55,0.72,0.89c0.2,0.48,0.43,0.74,0.7,0.8
							c0.37,0.09,0.81-0.23,1.22-0.59c1.21-1.04,4.29-1.27,5.5-0.41c0.33,0.24,0.55,0.77,0.56,1.4c0.01,0.58-0.17,1.35-0.7,1.75
							c-0.39,0.3-0.58,0.54-0.54,0.72c0.05,0.23,0.48,0.36,0.8,0.44c0.25,0.06,0.5,0.19,0.75,0.31c0.72,0.36,1.4,0.7,2.07-0.43
							c0.09-0.16,0.19-0.23,0.3-0.23c0,0,0,0,0,0c0.2,0,0.34,0.26,0.46,0.48c0.04,0.06,0.07,0.12,0.1,0.17
							c0.37,0.54,0.74,1.09,1.11,1.63c1.39,2.04,2.82,4.16,4.18,6.27c0.24,0.37,0.44,0.55,0.6,0.54c0.16-0.01,0.32-0.2,0.5-0.58
							c0.18-0.38,0.38-0.75,0.57-1.11c0.48-0.88,0.97-1.8,1.12-2.84c0.1-0.71,0.47-1.17,1.13-1.4c0.81-0.29,1.61-0.62,2.4-0.94
							c0.37-0.15,0.74-0.3,1.12-0.45c0.04-0.01,0.07-0.03,0.11-0.05c0.23-0.1,0.51-0.22,0.76,0.02c0.25,0.24,0.14,0.52,0.06,0.74
							c-0.02,0.04-0.03,0.09-0.04,0.13c-0.18,0.58-0.37,1.28-0.36,2.03c0.01,0.7,0.22,1.1,0.69,1.33c1.03,0.5,1.44,1.34,1.3,2.65
							c-0.03,0.31-0.05,0.65,0.13,0.84c0.12,0.13,0.32,0.19,0.61,0.19c0.04,0,0.09,0,0.13,0c0.43-0.01,0.96-0.03,1.05,0.65
							c0.08,0.63,0.11,1.28-0.47,1.75c-0.42,0.34-0.85,0.67-1.27,1c-0.2,0.16-0.4,0.31-0.6,0.47c-0.06,0.05-0.13,0.09-0.2,0.14
							c-0.34,0.23-0.54,0.4-0.41,0.72c0.15,0.37,0.57,0.35,0.97,0.34l0.07,0c0.22-0.01,0.43-0.03,0.65-0.06
							c0.73-0.09,1.49-0.18,2.27,0.53c1.05,0.96,1.37,2.19,1.67,3.37c0.1,0.38,0.2,0.77,0.32,1.14c0.07,0.21,0.05,0.38-0.07,0.52
							c-0.25,0.3-0.92,0.32-1.41,0.25c-1.55-0.22-2.93-0.96-4.26-1.67c-0.22-0.11-0.43-0.23-0.65-0.34
							c-2.02-1.05-4.71-2.34-7.69-3.01l-0.1,0.21c0.27,0.21,0.54,0.43,0.82,0.64c0.65,0.51,1.33,1.04,1.98,1.57
							c0.94,0.78,1.88,1.59,2.79,2.38l0.09,0.07c0.16,0.14,0.43,0.37,0.3,0.69c-0.15,0.38-0.53,0.32-0.78,0.28l-0.04-0.01
							c-0.66-0.1-1.2-0.46-1.73-0.8c-0.09-0.06-0.19-0.13-0.29-0.19c-0.64-0.41-1.28-0.87-1.89-1.3c-1.8-1.29-3.67-2.62-5.97-3.08
							c-2.27-0.46-4.18-0.32-5.86,0.41c-1.34,0.59-2.27,1.82-2.62,3.48c-0.39,1.84,0,3.21,1.24,4.31c0.44,0.39,0.63,0.76,0.58,1.09
							c-0.06,0.34-0.38,0.64-0.96,0.88c-0.5,0.21-0.98,0.48-1.44,0.75c-0.96,0.55-1.96,1.12-3.16,1.15
							c-2.15,1.72-3.65,4.03-5.1,6.26l-0.1,0.16c-0.03,0.05-0.06,0.09-0.09,0.14c-0.21,0.34-0.45,0.72-0.85,0.75
							c-0.49,0.03-0.79,0.15-0.92,0.34c-0.17,0.27-0.02,0.7,0.14,1.11c0.04,0.09,0.07,0.19,0.1,0.28c0.11,0.31,0.26,0.62,0.42,0.91
							C369.75,2671.77,369.86,2672,369.96,2672.23z M430.49,2650.81c0.07,0.02,0.14,0.03,0.21,0.05c0.05,0.01,0.1,0.02,0.14,0.03
							c0.88,0.2,1.74,0.43,2.73,0.71c0.35,0.1,0.72,0.2,1.11,0.3C433.94,2650.96,432.2,2650.83,430.49,2650.81z M413.09,2649.98
							c0.03,0.03,0.07,0.08,0.14,0.12c0.06,0.04,0.11,0.05,0.12,0.05c0,0,0-0.03-0.02-0.11
							C413.2,2649.99,413.13,2649.98,413.09,2649.98z M434.25,2639.77c0,0.01,0.01,0.02,0.01,0.02c0.02,0.04,0.04,0.09,0.06,0.13
							c0.01-0.03,0.02-0.07,0.04-0.1C434.32,2639.81,434.29,2639.79,434.25,2639.77z M429.43,2638.46c0.2,0.03,0.4,0.1,0.58,0.24
							c0.23,0.17,0.52,0.29,0.79,0.41c0.16,0.07,0.32,0.14,0.47,0.21l0.02,0.01l0.01,0.02c0.21,0.37,0.61,0.27,1.25,0.06
							c0.45-0.15,0.91-0.3,1.27-0.14c-0.02-0.57,0.25-1.02,0.52-1.46c0.07-0.12,0.14-0.23,0.21-0.35c0.03-0.05,0.06-0.1,0.1-0.15
							c0.09-0.13,0.19-0.3,0.16-0.39c-0.02-0.06-0.12-0.09-0.19-0.11c-0.73-0.18-1.25,0.18-1.75,0.52
							c-0.53,0.37-1.09,0.74-1.83,0.35c-0.08-0.04-0.16-0.09-0.23-0.13c-0.32-0.17-0.51-0.28-0.64-0.23
							c-0.13,0.05-0.26,0.25-0.45,0.73C429.65,2638.2,429.55,2638.33,429.43,2638.46z M339.96,2635.76c0.3,0.88,0.53,1.58,0.72,2.13
							c0,0,0-0.01,0-0.01c-0.16-0.54-0.36-1.18-0.58-1.93C340.06,2635.88,340.01,2635.82,339.96,2635.76z M340.48,2636.6
							c0.06,0.21,0.12,0.4,0.18,0.58C340.62,2636.99,340.57,2636.8,340.48,2636.6z M339.81,2635.3c0.01,0.01,0.02,0.02,0.03,0.04
							c0.04,0.05,0.08,0.1,0.12,0.14c-0.01-0.04-0.03-0.09-0.04-0.14c0,0-0.01,0-0.01,0c-0.09-0.01-0.14-0.12-0.15-0.36
							c-0.01,0.01-0.03,0.02-0.04,0.02C339.75,2635.11,339.78,2635.2,339.81,2635.3z M339.52,2635.04c0.01,0.01,0.02,0.01,0.03,0.02
							c0-0.01-0.01-0.02-0.01-0.02C339.54,2635.04,339.53,2635.04,339.52,2635.04z M339.29,2634.8c0.05,0.03,0.11,0.05,0.16,0.06
							c0.01,0,0.03-0.01,0.04-0.01c-0.01-0.03-0.02-0.06-0.03-0.1C339.38,2634.75,339.33,2634.77,339.29,2634.8z M340.04,2634.7
							c0.03,0.03,0.04,0.06,0.04,0.1c0.04-0.12,0.06-0.25,0.06-0.33c-0.03,0.03-0.07,0.08-0.13,0.17
							C340.02,2634.66,340.03,2634.68,340.04,2634.7z M339.64,2634.79C339.64,2634.79,339.64,2634.79,339.64,2634.79
							C339.64,2634.79,339.64,2634.79,339.64,2634.79C339.64,2634.79,339.64,2634.79,339.64,2634.79z M339.58,2634.6
							c0.03,0.01,0.07,0.01,0.1,0.03c-0.04-0.06-0.1-0.14-0.16-0.19C339.54,2634.49,339.56,2634.54,339.58,2634.6z"/>
					</g>
					<g>
						<path className="st24" d="M369.9,2672.3c-66.41-2.45-92.86-22.5-90.95-22.48c1.71,0.02,3.34,0.26,4.97,0.66
							c1.18,0.29,2.27,0.1,3.41-0.31c3.09-1.08,4.89-3.77,7.41-5.6c-0.53-0.53-1.13-0.3-1.66-0.35c-0.85-0.08-0.66-0.75-0.77-1.27
							c0.53-1.53,2.24-1.46,3.21-2.39c0.23-0.02,0.49,0.02,0.69-0.06c1.43-0.56,2.85-1.14,4.26-1.72c0.31-0.13,0.62-0.32,0.92-0.09
							c0.35,0.27,0.07,0.62-0.03,0.9c-0.16,0.48-0.41,0.94-0.61,1.41c-0.11,0.25-0.3,0.56,0,0.75c0.14,0.09,0.43-0.05,0.64-0.11
							c1.34-0.35,2.37-1.29,3.59-1.86c1.65-0.77,1.63-0.82,1.75,1.01c0.02,0.38,0.03,0.76,0.33,1.04c0.37,0.33,1.06,0.6,1.15,0.09
							c0.19-1.18,1.14-1.18,1.87-1.54c1.89-0.91,3.79-1.79,5.68-2.71c0.32-0.16,0.7-0.4,0.61-0.85c-0.1-0.46-0.53-0.48-0.9-0.48
							c-0.84,0.01-1.68-0.12-2.51,0.11c-0.35,0.1-0.77,0.34-1.01-0.08c-0.21-0.37,0.13-0.7,0.36-0.99c0.39-0.47,0.78-0.93,0.87-1.57
							c1.29-1.63,2.51-3.31,3.8-4.94c0.75-0.95,1.51-1.87,1.81-3.1c0.19-0.78,0.78-1.39,1.39-1.89c1.41-1.16,2.61-2.51,3.89-3.8
							c2.32-0.44,4.51-1.35,6.78-1.97c0.11,0.14,0.24,0.42,0.32,0.41c1.6-0.36,3.45,0.36,4.78-1.11c0.18,0.02,0.35,0.05,0.53,0.07
							l0.27,0.04c0.19,0.03,0.37,0.06,0.56,0.08c0,0,0.28,0.07,0.33,0.07c0.22,0.01,0,0,0,0c0.75,0.04,1.49,0.16,2.17,0.48
							c0.08,0.3,0.64,0.12,0.86,0.16c-0.94,0.81-2.15,0.45-3.07,0.49c-2.61,0.12-5.17,0.73-7.58,1.74
							c-1.22,0.51-2.39,1.13-3.52,1.81c-0.3,0.18-1.58,0.76-1.67,1.1c-0.14,0.53,0.89,0.67,1.23,0.8c0.74,0.27,1.36,0.53,1.89,1.13
							c0.53,0.6,0.87,1.36,1.5,1.89c0.73,0.61,1.77,0.82,2.33,1.58c0.65,0.88,0.56,1.99,0.76,3.01c0.23,1.16,0.91,2.12,1.44,3.15
							c0.64,1.22,1.06,2.55,1.24,3.92c0.01,0.11,0.03,0.22-0.01,0.32c-0.11,0.34-0.59,0.36-0.9,0.19c-0.97-0.52-1.78-1.32-2.77-1.78
							c-0.72-0.34-1.57-0.46-2.24-0.91c-0.71-0.49-1.11-1.28-1.57-1.99c-0.55-0.85-0.91-1.98-1.69-2.65
							c-0.21-0.18-0.51-0.29-0.77-0.2c-0.33,0.11-0.51,0.46-0.73,0.73c-0.77,0.95-2.04,0.83-3.11,0.58
							c-0.7-0.17-1.33-0.56-2.04-0.71c-1.53-0.31-0.49,1.93-0.2,2.52c0.46,0.94,0.96,1.96,1.81,2.61c1.09,0.83,2.24,0.43,3.46,0.78
							c0.62,0.18,0.89,0.64,1.29,1.09c0.54,0.61,1.14,1.01,1.9,1.31c2.06,0.82,4.4,1.87,6.66,1.85c0.82-0.01,1.73-0.45,1.9-1.25
							c1.1-0.13,3.72-0.86,3.71-2.31c-0.01-1.47-0.91-2.89-1.21-4.31c-0.08-0.37-0.11-0.81,0.17-1.06c0.31-0.27,0.79-0.12,1.14,0.09
							c0.35,0.22,0.67,0.5,0.93,0.83c0.09,0.11,0.17,0.23,0.18,0.36c0.02,0.17-0.07,0.32-0.12,0.48c-0.34,0.9,0.15,1.92,0.85,2.6
							c0.35,0.34,1.56,1.57,2.06,1.55c0.68-0.03,0.69-1.58,0.69-2.16c0-1.88-0.29-3.76-0.82-5.55c-0.42-1.41-1.28-2.68-1.64-4.11
							c-0.23-0.93-0.17-1.94-0.57-2.82c-0.31-0.66-2.2-3.72-1.12-4.36c0.8-0.47,1.05,0.17,1.4,0.76c0.34,0.57,1.03,0.72,1.47,1.17
							c1.31,1.35,1.9,3.65,3.7,4.46c0.55,0.25,1.13,0.38,1.7,0.56c0.79,0.25,1.32,0.93,2.07,1.28c1.54,0.7,3.37,0.79,4.98,1.41
							c2.15,0.84,3.99,2.85,5.61,4.43c0.29,0.28,0.58,0.57,0.73,0.95c0.46,1.17-0.4,2.61-0.84,3.67c-0.55,1.34-1.18,2.64-1.81,3.94
							c-0.41,0.86-0.95,3.16-2.01,3.38c-0.86,0.18-2.24-0.83-3.03-1.14c-3.44-1.32-6.6,1.4-10.19,1.26
							c-1.26-0.05-0.53,1.03-1.78,1.3c-0.78,0.17-2.14-0.6-2.72-0.07c-0.4,0.36-0.52-0.55,0,0c0.24,0.25,0.02,0.54,0.37,0.59
							c1.27,0.18,2.63,0.2,3.09,1.47c0.17,0.47-1.02,1.15-0.59,1.4c0.42,0.24,0.99,0.09,1.45-0.07s0.65-0.82,1.13-0.78
							c0.61,0.05,0.93,0.28,1.53,0.42c2.09,0.48,2.75-0.85,4.63-1.16c1.49-0.25,2.49,0.93,2.95,2.21c0.29,0.79,0.88,1.25,1.74,1.07
							c2.42-0.51,4.65-1.33,5.67-3.88c1.03-0.01,2.06-0.05,3.08-0.02c2.12,0.05,3.03,0.99,3.08,3.13c0.05,2.06,0.05,2.06,2.01,1.7
							c0.5-0.09,1.01-0.18,1.52-0.23c2.88-0.31,5.11,1.62,5.19,4.37c0.03,1.16-0.39,2.19-0.62,3.28c-2.22,1.77-3.72,4.12-5.24,6.46
							c-0.23,0.36-0.46,0.83-0.87,0.85c-1.58,0.11-1.02,1.05-0.75,1.84C369.33,2671.23,369.68,2671.74,369.9,2672.3"/>
						<path className="st23" d="M370.03,2672.39l-0.13,0c-33.59-1.24-56.41-7.02-69.64-11.65c-14.88-5.2-21.5-10.17-21.48-10.85
							c0-0.07,0.06-0.14,0.18-0.14c1.62,0.02,3.25,0.24,4.99,0.66c1.03,0.25,2.04,0.16,3.37-0.3c1.98-0.69,3.46-2.09,4.89-3.43
							c0.76-0.72,1.55-1.46,2.41-2.1c-0.34-0.29-0.7-0.28-1.08-0.26c-0.15,0.01-0.31,0.01-0.46,0c-0.73-0.07-0.77-0.56-0.8-0.99
							c-0.01-0.12-0.02-0.24-0.04-0.34l0-0.02l0.01-0.02c0.33-0.97,1.13-1.32,1.91-1.65c0.47-0.21,0.96-0.42,1.32-0.77l0.02-0.02
							l0.03,0c0.08-0.01,0.15,0,0.23-0.01c0.16,0,0.31,0,0.44-0.05c1.36-0.53,2.76-1.1,4.26-1.72c0.04-0.02,0.08-0.03,0.12-0.05
							c0.27-0.12,0.58-0.26,0.88-0.03c0.33,0.25,0.18,0.58,0.08,0.81c-0.03,0.06-0.05,0.12-0.07,0.18c-0.1,0.32-0.25,0.62-0.39,0.92
							c-0.08,0.17-0.16,0.33-0.23,0.5c-0.01,0.03-0.03,0.06-0.04,0.09c-0.12,0.27-0.18,0.44,0.01,0.56c0.07,0.04,0.25-0.02,0.4-0.06
							c0.06-0.02,0.12-0.04,0.17-0.05c0.81-0.21,1.52-0.65,2.21-1.08c0.44-0.27,0.89-0.55,1.37-0.78l0.14-0.07
							c0.79-0.37,1.15-0.54,1.39-0.4c0.25,0.15,0.27,0.57,0.33,1.5l0.01,0.12c0.02,0.34,0.04,0.67,0.3,0.9
							c0.24,0.22,0.64,0.41,0.86,0.34c0.08-0.03,0.13-0.09,0.15-0.2c0.16-0.96,0.79-1.18,1.4-1.4c0.17-0.06,0.35-0.12,0.51-0.2
							c0.82-0.39,1.64-0.78,2.46-1.17c1.05-0.5,2.14-1.01,3.21-1.53c0.32-0.16,0.64-0.37,0.56-0.75c-0.08-0.39-0.43-0.42-0.82-0.41
							c-0.23,0-0.45-0.01-0.68-0.01c-0.6-0.02-1.22-0.04-1.81,0.12c-0.06,0.02-0.12,0.04-0.18,0.06c-0.31,0.1-0.69,0.23-0.92-0.17
							c-0.22-0.38,0.07-0.72,0.3-0.99c0.03-0.03,0.05-0.06,0.08-0.09l0.04-0.05c0.37-0.45,0.73-0.88,0.81-1.47l0-0.02l0.01-0.02
							c0.62-0.79,1.24-1.6,1.84-2.39c0.64-0.84,1.3-1.71,1.96-2.55l0.1-0.13c0.72-0.91,1.41-1.78,1.69-2.94
							c0.21-0.85,0.87-1.48,1.42-1.94c1.03-0.84,1.96-1.81,2.87-2.75c0.33-0.34,0.67-0.7,1.02-1.04l0.02-0.02l0.03,0
							c1.44-0.27,2.85-0.73,4.22-1.18c0.83-0.27,1.69-0.55,2.55-0.79l0.05-0.01l0.03,0.04c0.04,0.05,0.07,0.11,0.11,0.17
							c0.04,0.06,0.11,0.17,0.14,0.2c0.53-0.11,1.09-0.12,1.63-0.12c1.14,0,2.22,0,3.09-0.97l0.03-0.03l1.41,0.19
							c0.14,0.03,0.3,0.07,0.33,0.07l0.1,0.01c0.86,0.05,1.52,0.21,2.11,0.49l0.03,0.02l0.01,0.04c0.04,0.13,0.24,0.13,0.5,0.11
							c0.12-0.01,0.21-0.02,0.29,0l0.17,0.03l-0.13,0.11c-0.73,0.63-1.61,0.57-2.39,0.53c-0.26-0.02-0.5-0.03-0.73-0.02
							c-2.56,0.12-5.1,0.7-7.55,1.74c-1.16,0.49-2.34,1.1-3.51,1.81c-0.07,0.04-0.19,0.11-0.35,0.19c-0.4,0.21-1.23,0.64-1.28,0.86
							c-0.08,0.32,0.4,0.48,0.91,0.62c0.11,0.03,0.2,0.05,0.27,0.08c0.76,0.28,1.38,0.54,1.92,1.15c0.22,0.25,0.4,0.52,0.58,0.78
							c0.26,0.39,0.54,0.79,0.9,1.09c0.29,0.24,0.65,0.43,0.99,0.6c0.5,0.26,1.02,0.53,1.36,0.99c0.48,0.65,0.57,1.42,0.65,2.16
							c0.03,0.29,0.07,0.59,0.13,0.88c0.17,0.86,0.61,1.64,1.03,2.38c0.14,0.24,0.28,0.49,0.41,0.75c0.64,1.24,1.06,2.57,1.24,3.95
							c0.02,0.12,0.03,0.24-0.01,0.35c-0.04,0.14-0.14,0.24-0.29,0.3c-0.21,0.09-0.51,0.06-0.74-0.06
							c-0.45-0.24-0.86-0.54-1.27-0.83c-0.47-0.34-0.97-0.7-1.5-0.94c-0.28-0.13-0.59-0.23-0.89-0.33
							c-0.47-0.15-0.95-0.31-1.36-0.59c-0.59-0.4-0.96-1.01-1.33-1.59c-0.09-0.14-0.17-0.28-0.26-0.42
							c-0.19-0.29-0.36-0.62-0.52-0.94c-0.32-0.62-0.65-1.26-1.15-1.69c-0.17-0.14-0.44-0.27-0.69-0.19
							c-0.23,0.07-0.38,0.28-0.53,0.5c-0.05,0.07-0.1,0.14-0.15,0.2c-0.83,1.03-2.22,0.84-3.19,0.61c-0.34-0.08-0.67-0.22-0.98-0.35
							c-0.34-0.14-0.69-0.29-1.05-0.36c-0.3-0.06-0.5-0.02-0.61,0.12c-0.24,0.31-0.06,1.14,0.5,2.28c0.42,0.86,0.94,1.93,1.79,2.58
							c0.66,0.5,1.34,0.54,2.06,0.58c0.44,0.02,0.9,0.05,1.37,0.19c0.53,0.15,0.81,0.49,1.11,0.86c0.07,0.08,0.14,0.17,0.22,0.26
							c0.51,0.58,1.09,0.98,1.87,1.29c0.2,0.08,0.41,0.17,0.62,0.25c1.87,0.76,3.98,1.61,6.01,1.6c0.78-0.01,1.66-0.42,1.82-1.18
							l0.01-0.06l0.06-0.01c1.14-0.14,3.65-0.88,3.64-2.23c-0.01-0.87-0.33-1.74-0.65-2.58c-0.21-0.56-0.43-1.14-0.55-1.72
							c-0.12-0.55-0.05-0.92,0.2-1.14c0.34-0.29,0.86-0.15,1.24,0.08c0.36,0.23,0.68,0.51,0.95,0.84c0.09,0.12,0.18,0.25,0.2,0.41
							c0.02,0.16-0.04,0.3-0.09,0.43c-0.01,0.03-0.02,0.06-0.03,0.09c-0.34,0.92,0.23,1.93,0.83,2.51l0.1,0.1
							c0.4,0.39,1.45,1.43,1.89,1.43c0,0,0.01,0,0.01,0c0.28-0.01,0.62-0.38,0.61-2.07c-0.01-1.86-0.28-3.72-0.82-5.53
							c-0.19-0.65-0.49-1.29-0.78-1.9c-0.33-0.71-0.67-1.44-0.86-2.21c-0.09-0.36-0.14-0.74-0.18-1.1c-0.07-0.57-0.14-1.17-0.39-1.7
							c-0.05-0.1-0.13-0.26-0.23-0.45c-0.59-1.11-1.83-3.43-0.85-4.01c0.27-0.16,0.49-0.21,0.7-0.16c0.33,0.09,0.52,0.42,0.72,0.77
							c0.03,0.06,0.06,0.11,0.1,0.17c0.19,0.32,0.49,0.5,0.8,0.69c0.23,0.14,0.46,0.28,0.65,0.47c0.51,0.52,0.91,1.19,1.3,1.83
							c0.63,1.04,1.28,2.12,2.37,2.61c0.38,0.17,0.78,0.29,1.17,0.4c0.17,0.05,0.35,0.1,0.52,0.16c0.45,0.14,0.82,0.42,1.18,0.7
							c0.28,0.21,0.57,0.44,0.9,0.59c0.82,0.37,1.74,0.57,2.63,0.77c0.78,0.17,1.59,0.35,2.34,0.64c1.88,0.73,3.52,2.35,4.97,3.78
							c0.23,0.23,0.45,0.45,0.67,0.66c0.31,0.3,0.6,0.6,0.75,0.98c0.39,0.99-0.15,2.19-0.58,3.14c-0.09,0.21-0.18,0.4-0.26,0.59
							c-0.49,1.2-1.06,2.39-1.61,3.54l-0.2,0.41c-0.1,0.2-0.2,0.48-0.32,0.81c-0.38,1.03-0.89,2.44-1.74,2.62
							c-0.63,0.13-1.48-0.32-2.23-0.73c-0.31-0.17-0.61-0.32-0.84-0.41c-1.97-0.75-3.82-0.17-5.78,0.44
							c-1.4,0.44-2.85,0.89-4.38,0.83c-0.59-0.02-0.7,0.21-0.83,0.51c-0.13,0.29-0.29,0.65-0.93,0.79
							c-0.35,0.08-0.79-0.02-1.22-0.12c-0.53-0.12-1.08-0.24-1.4-0.02c0.07,0.11,0.09,0.21,0.1,0.3c0.02,0.13,0.03,0.17,0.16,0.19
							c0.14,0.02,0.27,0.04,0.41,0.05c1.14,0.14,2.32,0.29,2.75,1.47c0.1,0.27-0.16,0.57-0.39,0.84c-0.14,0.17-0.31,0.36-0.29,0.45
							c0,0.01,0.01,0.03,0.05,0.06c0.39,0.23,0.94,0.08,1.38-0.08c0.18-0.06,0.32-0.22,0.46-0.37c0.19-0.22,0.39-0.44,0.71-0.41
							c0.38,0.03,0.65,0.13,0.93,0.23c0.19,0.07,0.38,0.14,0.61,0.19c1.24,0.28,1.94-0.07,2.76-0.49c0.53-0.27,1.09-0.55,1.84-0.68
							c0.49-0.08,2.17-0.16,3.05,2.27c0.29,0.82,0.88,1.18,1.64,1.02c3.91-0.82,5.01-2.33,5.61-3.83l0.02-0.05l0.06,0
							c0.32,0,0.64-0.01,0.96-0.02c0.7-0.01,1.42-0.03,2.12-0.01c2.16,0.05,3.11,1.01,3.16,3.21c0.03,1.01,0.04,1.52,0.27,1.7
							c0.22,0.18,0.73,0.09,1.64-0.08c0.46-0.08,0.99-0.18,1.53-0.23c1.44-0.15,2.77,0.24,3.75,1.1c0.95,0.83,1.49,2.02,1.53,3.35
							c0.02,0.83-0.18,1.6-0.38,2.34c-0.08,0.31-0.17,0.63-0.24,0.96l-0.01,0.03l-0.02,0.02c-2.16,1.72-3.66,4.04-5.12,6.28
							l-0.1,0.16c-0.03,0.05-0.06,0.09-0.09,0.14c-0.21,0.34-0.45,0.72-0.85,0.75c-0.49,0.03-0.79,0.15-0.92,0.34
							c-0.17,0.27-0.02,0.7,0.14,1.11c0.04,0.09,0.07,0.19,0.1,0.28c0.11,0.31,0.26,0.62,0.42,0.91c0.12,0.24,0.25,0.48,0.35,0.73
							L370.03,2672.39z M278.94,2649.91c0.15,0.56,6.33,5.41,21.38,10.68c13.2,4.62,35.96,10.38,69.45,11.63
							c-0.09-0.2-0.19-0.4-0.29-0.6c-0.15-0.3-0.31-0.61-0.43-0.94c-0.03-0.09-0.06-0.18-0.1-0.27c-0.17-0.45-0.34-0.91-0.12-1.25
							c0.16-0.25,0.5-0.38,1.04-0.42c0.31-0.02,0.52-0.35,0.72-0.67c0.03-0.05,0.06-0.1,0.09-0.15l0.1-0.16
							c1.46-2.25,2.96-4.57,5.13-6.3c0.07-0.32,0.15-0.63,0.24-0.93c0.2-0.73,0.4-1.49,0.38-2.3c-0.04-1.29-0.56-2.44-1.47-3.23
							c-0.95-0.83-2.24-1.21-3.63-1.06c-0.54,0.06-1.06,0.15-1.51,0.23c-1,0.18-1.5,0.27-1.78,0.05c-0.29-0.23-0.3-0.77-0.33-1.83
							c-0.05-2.11-0.92-3-3-3.05c-0.7-0.02-1.42,0-2.12,0.01c-0.3,0.01-0.61,0.01-0.91,0.01c-1.06,2.58-3.37,3.39-5.71,3.88
							c-0.84,0.18-1.51-0.23-1.83-1.12c-0.21-0.58-1.04-2.46-2.86-2.16c-0.73,0.12-1.27,0.39-1.79,0.66
							c-0.81,0.41-1.58,0.8-2.87,0.5c-0.25-0.06-0.44-0.13-0.63-0.2c-0.27-0.1-0.53-0.19-0.89-0.22c-0.23-0.02-0.39,0.16-0.57,0.36
							c-0.15,0.17-0.31,0.34-0.53,0.42c-0.47,0.17-1.06,0.32-1.52,0.07c-0.08-0.04-0.12-0.1-0.13-0.17
							c-0.03-0.17,0.14-0.37,0.33-0.59c0.2-0.23,0.42-0.49,0.36-0.68c-0.39-1.09-1.47-1.22-2.61-1.37
							c-0.14-0.02-0.28-0.03-0.41-0.05c-0.26-0.04-0.28-0.2-0.3-0.33c-0.01-0.07-0.02-0.15-0.07-0.22c-0.15,0.1-0.27,0.05-0.34-0.02
							c-0.09-0.1-0.1-0.24-0.02-0.32c0.06-0.06,0.18-0.08,0.39,0.11c0.38-0.27,0.97-0.14,1.55-0.01c0.41,0.09,0.84,0.18,1.15,0.12
							c0.56-0.12,0.69-0.41,0.81-0.69c0.14-0.31,0.29-0.64,0.98-0.61c1.49,0.06,2.93-0.39,4.32-0.83c1.99-0.62,3.87-1.21,5.89-0.43
							c0.25,0.09,0.54,0.25,0.86,0.42c0.73,0.39,1.55,0.83,2.12,0.71c0.76-0.16,1.26-1.52,1.62-2.51c0.12-0.33,0.23-0.61,0.33-0.83
							l0.2-0.41c0.55-1.15,1.12-2.33,1.61-3.53c0.08-0.19,0.17-0.38,0.26-0.59c0.44-0.98,0.95-2.08,0.58-3.01
							c-0.14-0.35-0.4-0.62-0.71-0.92c-0.22-0.21-0.45-0.44-0.67-0.66c-1.44-1.42-3.07-3.03-4.91-3.75
							c-0.74-0.29-1.54-0.46-2.32-0.63c-0.9-0.2-1.83-0.4-2.67-0.78c-0.35-0.16-0.65-0.38-0.94-0.6c-0.36-0.27-0.7-0.53-1.13-0.67
							c-0.17-0.05-0.34-0.11-0.52-0.16c-0.4-0.12-0.81-0.24-1.19-0.41c-1.14-0.51-1.8-1.61-2.45-2.67c-0.39-0.64-0.79-1.3-1.28-1.8
							c-0.17-0.18-0.39-0.31-0.62-0.44c-0.32-0.19-0.65-0.39-0.86-0.74c-0.03-0.06-0.07-0.11-0.1-0.17
							c-0.18-0.32-0.36-0.63-0.62-0.7c-0.16-0.04-0.34,0-0.57,0.14c-0.84,0.5,0.39,2.81,0.92,3.79c0.11,0.2,0.19,0.36,0.24,0.46
							c0.26,0.55,0.33,1.16,0.4,1.75c0.04,0.36,0.09,0.73,0.18,1.08c0.19,0.76,0.53,1.48,0.85,2.19c0.29,0.62,0.59,1.26,0.78,1.93
							c0.54,1.82,0.82,3.7,0.83,5.58c0,0.55,0.01,2.21-0.77,2.24c-0.44,0.02-1.27-0.74-2.02-1.48l-0.1-0.1
							c-0.63-0.61-1.24-1.69-0.87-2.68c0.01-0.03,0.02-0.06,0.04-0.09c0.05-0.12,0.1-0.24,0.08-0.35c-0.01-0.12-0.09-0.22-0.17-0.32
							c-0.25-0.32-0.56-0.59-0.91-0.81c-0.33-0.2-0.77-0.33-1.04-0.1c-0.2,0.17-0.25,0.49-0.15,0.98c0.12,0.56,0.33,1.14,0.54,1.69
							c0.32,0.85,0.66,1.74,0.66,2.64c0.01,1.45-2.47,2.22-3.73,2.39c-0.2,0.82-1.13,1.25-1.96,1.26c-2.05,0.01-4.19-0.85-6.07-1.61
							c-0.21-0.08-0.42-0.17-0.62-0.25c-0.79-0.32-1.41-0.74-1.93-1.34c-0.08-0.09-0.15-0.17-0.22-0.26
							c-0.29-0.36-0.55-0.66-1.03-0.8c-0.45-0.13-0.9-0.16-1.33-0.18c-0.75-0.04-1.46-0.08-2.15-0.61
							c-0.88-0.67-1.41-1.76-1.84-2.64c-0.42-0.86-0.84-1.99-0.48-2.46c0.15-0.19,0.41-0.25,0.78-0.18
							c0.38,0.08,0.74,0.23,1.08,0.37c0.31,0.13,0.63,0.26,0.96,0.34c0.93,0.22,2.25,0.4,3.02-0.55c0.05-0.06,0.1-0.13,0.15-0.2
							c0.17-0.23,0.34-0.47,0.62-0.56c0.27-0.08,0.59,0,0.85,0.22c0.53,0.45,0.87,1.11,1.19,1.74c0.17,0.33,0.33,0.64,0.52,0.93
							c0.09,0.14,0.18,0.28,0.27,0.42c0.35,0.57,0.72,1.16,1.28,1.54c0.39,0.27,0.86,0.42,1.32,0.57c0.3,0.1,0.62,0.2,0.91,0.34
							c0.55,0.25,1.04,0.61,1.52,0.96c0.4,0.29,0.81,0.59,1.25,0.82c0.18,0.1,0.43,0.12,0.6,0.05c0.1-0.04,0.16-0.11,0.19-0.2
							c0.03-0.08,0.02-0.18,0-0.28c-0.18-1.36-0.59-2.67-1.23-3.89c-0.13-0.25-0.27-0.5-0.41-0.74c-0.43-0.76-0.87-1.54-1.05-2.43
							c-0.06-0.29-0.09-0.6-0.13-0.89c-0.08-0.72-0.17-1.47-0.62-2.08c-0.32-0.43-0.8-0.68-1.3-0.94c-0.35-0.18-0.71-0.37-1.02-0.62
							c-0.39-0.32-0.67-0.73-0.94-1.13c-0.18-0.26-0.36-0.53-0.57-0.77c-0.51-0.58-1.12-0.84-1.86-1.11
							c-0.06-0.02-0.15-0.05-0.26-0.08c-0.46-0.12-1.16-0.31-1.02-0.82c0.07-0.27,0.69-0.61,1.37-0.96
							c0.14-0.08,0.27-0.14,0.34-0.18c1.17-0.71,2.36-1.33,3.53-1.82c2.47-1.04,5.03-1.63,7.61-1.75c0.24-0.01,0.48,0,0.75,0.02
							c0.68,0.04,1.44,0.09,2.08-0.34c-0.01,0-0.01,0-0.02,0c-0.24,0.02-0.56,0.04-0.66-0.19c-0.58-0.27-1.24-0.41-2.11-0.46
							c-0.06,0-0.28-0.06-0.35-0.07l-1.31-0.18c-0.92,0.99-2.07,0.99-3.19,0.99c-0.54,0-1.1,0-1.61,0.12
							c-0.11,0.02-0.19-0.1-0.3-0.28c-0.02-0.04-0.05-0.08-0.07-0.11c-0.84,0.23-1.68,0.51-2.49,0.77
							c-1.36,0.45-2.77,0.91-4.21,1.18c-0.34,0.34-0.67,0.69-1,1.02c-0.91,0.94-1.85,1.91-2.88,2.76c-0.53,0.44-1.17,1.04-1.36,1.85
							c-0.29,1.19-1.02,2.11-1.72,3l-0.1,0.13c-0.66,0.84-1.32,1.71-1.96,2.55c-0.59,0.78-1.21,1.59-1.83,2.38
							c-0.09,0.62-0.47,1.08-0.84,1.53l-0.04,0.05c-0.03,0.03-0.05,0.06-0.08,0.09c-0.21,0.25-0.44,0.52-0.28,0.8
							c0.15,0.27,0.37,0.21,0.72,0.1c0.06-0.02,0.13-0.04,0.19-0.06c0.62-0.17,1.25-0.15,1.86-0.13c0.22,0.01,0.45,0.01,0.67,0.01
							c0.32,0,0.87-0.01,0.98,0.55c0.12,0.56-0.44,0.83-0.65,0.94c-1.07,0.52-2.16,1.04-3.21,1.54c-0.82,0.39-1.64,0.78-2.46,1.17
							c-0.17,0.08-0.36,0.15-0.53,0.21c-0.59,0.21-1.15,0.4-1.29,1.27c-0.03,0.21-0.16,0.3-0.26,0.33c-0.29,0.1-0.75-0.11-1.03-0.37
							c-0.31-0.28-0.33-0.65-0.35-1.02l-0.01-0.12c-0.05-0.82-0.08-1.27-0.25-1.37c-0.17-0.1-0.57,0.09-1.24,0.4l-0.14,0.07
							c-0.47,0.22-0.92,0.5-1.35,0.77c-0.7,0.44-1.42,0.89-2.26,1.1c-0.05,0.01-0.11,0.03-0.16,0.05c-0.2,0.06-0.41,0.13-0.54,0.05
							c-0.33-0.21-0.18-0.53-0.07-0.77c0.01-0.03,0.03-0.06,0.04-0.09c0.07-0.17,0.15-0.34,0.23-0.5c0.14-0.29,0.28-0.59,0.38-0.9
							c0.02-0.06,0.05-0.13,0.08-0.19c0.12-0.27,0.18-0.45-0.03-0.61c-0.22-0.17-0.45-0.07-0.71,0.05
							c-0.04,0.02-0.08,0.03-0.12,0.05c-1.51,0.63-2.9,1.19-4.27,1.72c-0.16,0.06-0.33,0.06-0.5,0.06c-0.06,0-0.13,0-0.19,0
							c-0.38,0.36-0.87,0.57-1.35,0.78c-0.77,0.33-1.49,0.65-1.81,1.54c0.02,0.11,0.03,0.23,0.04,0.34
							c0.03,0.43,0.06,0.78,0.65,0.83c0.14,0.01,0.28,0.01,0.43,0c0.41-0.02,0.87-0.04,1.28,0.38l0.07,0.07l-0.08,0.06
							c-0.88,0.64-1.69,1.41-2.48,2.14c-1.44,1.36-2.94,2.77-4.95,3.47c-1.34,0.47-2.41,0.56-3.46,0.31
							C282.18,2650.15,280.56,2649.93,278.94,2649.91C278.95,2649.91,278.94,2649.91,278.94,2649.91z M339.49,2647.92
							C339.49,2647.92,339.49,2647.92,339.49,2647.92c0,0.01,0.01,0.06,0.05,0.09c0.03,0.02,0.06,0.02,0.1,0
							C339.54,2647.92,339.49,2647.92,339.49,2647.92z"/>
					</g>
					<g>
						<path className="st25" d="M402.14,2624.83c-2.01-1.33-3.78-3.02-5.95-4.1c0.39-0.78,0.82-1.64-0.38-2.04
							c-1.18-0.39-2.42-0.61-3.65-0.86c-0.74-0.15-1.01,0.27-0.96,0.97c0.09,1.19,0.18,2.38,0.25,3.57c0.02,0.41,0.12,0.73,0.59,0.8
							c-1.81,1.2-4.77,0.77-5.76-1.01c-0.81-1.47-1.93-1.43-3.21-1.1c-1.87,0.48-3.54,1.42-5.08,2.55
							c-0.89,0.65-1.64,0.67-2.54,0.09c-0.5-0.32-1.08-0.56-1.66-0.73c-0.42-0.13-0.98,0.08-1.1,0.44c-0.7,2.09-1.77,1.03-2.82,0.4
							c-0.77-1.72-0.25-3.25-0.71-4.83c-0.13-0.45-0.39-0.87-0.96-0.88c-0.57-0.01-0.8,0.4-1.01,0.83
							c-0.55,1.15-0.42,2.37-0.42,3.58c-0.78-0.25-1.56-0.49-2.25-0.96c-0.27-0.88-0.04-1.44-0.07-2.14
							c-0.03-0.73-0.47-1.7-1.33-1.72c-0.92-0.02-0.89,1.03-1.11,1.7c-0.1,0.3-0.1,1.24-0.15,1.56c-0.86,0.09-1.72-0.42-2.59-0.33
							c-0.12-0.77,0.05-1.59-0.39-2.31c-0.28-0.44-1.32-0.86-1.26-0.21c0.07,0.68-0.6,0.82-0.58,1.8c0.02,1.01,0.49,0.85,1,0.99
							c-1.46,0.38-2,0.75-3.5,0.68c-1.14-1.08-0.11-0.85-0.11-1.48c0-1.91,0.05-3.83,0.08-5.74c0.32-0.08,0.63-0.16,0.95-0.24
							l3.17,0.33l2.26,0.29c0,0,2.23,0.19,3.35,0.27c0.63,0.08,1.25,0.16,1.88,0.24l3.48,0.32c0.51-0.02,1.03-0.04,1.54-0.05
							c0,0,2.25,0.22,3.37,0.33c0,0,2.12,0.15,3.17,0.28c1.01,0.13,1.15-0.38,1.09-1.23c-0.22-2.78-0.05-5.66-2.2-7.91
							c0.14-0.71-0.1-1.38-0.32-2.02c-1.07-3.2-1.09-3.19-4.11-3.13c-0.01-2.88,0.03-5.76-0.05-8.63c-0.03-0.97,0.24-1.59,1.19-1.84
							c0.84-0.21,1.67-0.43,2.51-0.64c0.39-0.1,0.75-0.02,1-0.42c0.15-0.24,0.12-2.18,0.37-2.22c0.48-0.12,0.96-0.22,1.46-0.27
							c0.28-0.03,1.06-0.16,1.32,0.01l0.01,0c-0.06,5.47-0.09,10.93-0.2,16.4c-0.02,1.07,0.3,1.52,1.37,1.61
							c1.58,0.14,3.14,0.44,4.72,0.61c3.48,0.38,6.91,1.07,10.42,1.23c0.49,0.33,1.06,0.32,1.6,0.27c1.31-0.13,2.09,0.43,2.4,1.69
							c-0.68,0.16-0.63,0.7-0.63,1.21c0,1.39-0.01,2.78-0.01,4.18c0,1.7,0.28,1.97,1.97,1.6c1.46-0.31,2.91-0.55,4.4-0.52
							c0.21,3.27,0.42,6.54,0.64,9.81c-0.97,0.28-1.87-0.26-2.82-0.23C403.53,2624.86,402.9,2624.72,402.14,2624.83z
							 M392.05,2613.53c0.15,0.59-0.4,1.55,0.51,1.9c0.8,0.3,1.8,0.4,2.57,0c0.68-0.35-0.01-1.09,0.04-1.66
							c0.03-0.37-0.01-0.74,0-1.11l-0.92-0.35C393.21,2612.02,392.05,2611.25,392.05,2613.53z M401.29,2620.79
							C401.29,2620.79,401.29,2620.79,401.29,2620.79c-0.01,0.42,0.01,0.84-0.01,1.26c-0.02,0.62,0.08,1.1,0.88,1.11
							c0.75,0,1.14-0.3,1.14-1.07c0-0.84-0.01-1.68,0.01-2.52c0.02-0.76-0.42-0.93-1.07-0.94c-0.63-0.01-0.97,0.24-0.95,0.9
							C401.3,2619.95,401.29,2620.37,401.29,2620.79z"/>
						<path className="st26" d="M406.05,2626.15c-0.38,0-0.75-0.08-1.11-0.16c-0.38-0.08-0.74-0.16-1.11-0.15l-0.12,0l-0.04-0.12
							c-0.22-0.64-0.67-0.86-1.5-0.74l-0.06,0.01l-0.05-0.03c-0.75-0.5-1.48-1.06-2.19-1.59c-1.18-0.9-2.39-1.82-3.75-2.5
							l-0.15-0.07l0.08-0.15c0.23-0.46,0.5-0.98,0.35-1.34c-0.08-0.2-0.29-0.35-0.63-0.46c-1-0.33-2.07-0.54-3.1-0.75
							c-0.18-0.03-0.35-0.07-0.53-0.11c-0.27-0.05-0.47-0.03-0.59,0.08c-0.17,0.15-0.19,0.47-0.17,0.71l0.02,0.32
							c0.08,1.07,0.16,2.17,0.23,3.26c0.03,0.44,0.13,0.6,0.45,0.65l0.42,0.07l-0.36,0.24c-0.99,0.66-2.4,0.87-3.67,0.56
							c-1.06-0.26-1.89-0.84-2.32-1.63c-0.72-1.31-1.69-1.36-3.03-1.02c-1.64,0.42-3.28,1.24-5.03,2.52
							c-0.93,0.68-1.77,0.71-2.73,0.1c-0.43-0.28-0.98-0.52-1.61-0.71c-0.35-0.1-0.81,0.07-0.89,0.33c-0.24,0.73-0.56,1.16-0.96,1.3
							c-0.56,0.19-1.15-0.19-1.72-0.56c-0.13-0.08-0.25-0.16-0.38-0.24l-0.04-0.03l-0.02-0.05c-0.45-1.01-0.47-1.95-0.5-2.86
							c-0.02-0.68-0.03-1.33-0.22-1.99c-0.15-0.51-0.4-0.75-0.81-0.76c-0.01,0-0.02,0-0.02,0c-0.44,0-0.62,0.31-0.83,0.74
							c-0.45,0.93-0.43,1.9-0.41,2.93c0,0.19,0.01,0.39,0.01,0.58l0,0.23l-0.33-0.1c-0.74-0.24-1.51-0.48-2.19-0.94l-0.05-0.03
							l-0.02-0.06c-0.19-0.61-0.15-1.07-0.11-1.52c0.02-0.22,0.04-0.44,0.03-0.67c-0.03-0.64-0.41-1.55-1.16-1.57
							c-0.54-0.01-0.67,0.4-0.82,1.08c-0.04,0.17-0.07,0.34-0.13,0.5c-0.06,0.17-0.08,0.61-0.1,0.96c-0.01,0.24-0.03,0.44-0.04,0.57
							l-0.02,0.13l-0.13,0.01c-0.46,0.05-0.91-0.06-1.35-0.17c-0.43-0.11-0.83-0.2-1.24-0.16l-0.16,0.02l-0.02-0.16
							c-0.04-0.25-0.05-0.51-0.06-0.77c-0.02-0.53-0.03-1.03-0.32-1.48c-0.18-0.29-0.71-0.53-0.9-0.46
							c-0.02,0.01-0.06,0.02-0.05,0.14c0.04,0.37-0.12,0.61-0.27,0.85c-0.16,0.24-0.32,0.49-0.31,0.97c0.02,0.68,0.2,0.71,0.58,0.77
							c0.09,0.01,0.19,0.03,0.3,0.06l0.61,0.16l-0.61,0.16c-0.39,0.1-0.73,0.21-1.02,0.3c-0.82,0.26-1.41,0.44-2.53,0.38l-0.06,0
							l-0.04-0.04c-0.42-0.4-0.59-0.66-0.59-0.88c0-0.19,0.13-0.31,0.24-0.4c0.12-0.1,0.19-0.18,0.19-0.32
							c0-1.38,0.03-2.78,0.05-4.13c0.01-0.54,0.02-1.07,0.03-1.61l0-0.13l1.1-0.27l3.2,0.33l2.27,0.29c0.02,0,2.24,0.19,3.34,0.27
							l1.89,0.24l3.46,0.32l1.54-0.05l0.01,0c0,0,2.25,0.22,3.37,0.33c0.02,0,2.13,0.15,3.18,0.29c0.36,0.05,0.61,0.01,0.74-0.12
							c0.15-0.14,0.2-0.43,0.16-0.93c-0.03-0.4-0.06-0.8-0.08-1.2c-0.14-2.43-0.28-4.73-2.08-6.61l-0.06-0.06l0.02-0.08
							c0.13-0.63-0.08-1.24-0.28-1.83l-0.03-0.1c-0.51-1.51-0.78-2.34-1.26-2.71c-0.47-0.36-1.2-0.34-2.67-0.31l-0.17,0l-0.02-0.17
							c0-0.81,0-1.62,0-2.43c0-2.03,0.01-4.14-0.05-6.2c-0.02-0.81,0.12-1.69,1.31-2l2.51-0.64c0.11-0.03,0.22-0.04,0.32-0.06
							c0.27-0.04,0.44-0.06,0.58-0.29c0.04-0.08,0.08-0.53,0.1-0.86c0.08-1.04,0.13-1.4,0.38-1.44c0.55-0.14,1.02-0.23,1.46-0.27
							c0.04,0,0.08-0.01,0.13-0.02c0.52-0.06,1.04-0.11,1.29,0.05l0.08,0.05l0,0.09c-0.02,1.42-0.03,2.83-0.04,4.25
							c-0.04,3.98-0.08,8.1-0.16,12.15c-0.02,1.01,0.28,1.36,1.22,1.45c0.87,0.08,1.75,0.21,2.6,0.33c0.69,0.1,1.41,0.21,2.12,0.28
							c1.28,0.14,2.58,0.33,3.84,0.51c2.14,0.31,4.36,0.62,6.57,0.72l0.05,0l0.04,0.03c0.46,0.31,1.01,0.29,1.49,0.24
							c1.38-0.14,2.25,0.47,2.58,1.81l0.04,0.16l-0.16,0.04c-0.48,0.11-0.5,0.42-0.5,0.94l0,0.11c0,0.59,0,1.19,0,1.78
							c0,0.8,0,1.59,0,2.39c0,0.75,0.05,1.25,0.3,1.45c0.22,0.17,0.63,0.17,1.47-0.01c1.29-0.28,2.82-0.55,4.44-0.52l0.15,0
							l0.66,10.09l-0.13,0.04C406.47,2626.13,406.25,2626.15,406.05,2626.15z M403.93,2625.51c0.37,0,0.73,0.08,1.08,0.16
							c0.5,0.11,0.97,0.21,1.45,0.11l-0.62-9.52c-1.53-0.01-2.98,0.25-4.21,0.52c-0.82,0.18-1.4,0.23-1.75-0.06
							c-0.35-0.28-0.42-0.84-0.42-1.71c0-0.8,0-1.59,0-2.39c0-0.59,0-1.19,0-1.78l0-0.11c0-0.45-0.01-1,0.59-1.22
							c-0.32-1.08-1.02-1.52-2.19-1.41c-0.56,0.06-1.15,0.06-1.67-0.27c-2.2-0.1-4.41-0.42-6.55-0.72c-1.25-0.18-2.55-0.36-3.83-0.5
							c-0.71-0.08-1.43-0.18-2.13-0.28c-0.85-0.12-1.72-0.25-2.59-0.33c-1.12-0.1-1.54-0.6-1.52-1.78
							c0.08-4.05,0.12-8.17,0.16-12.15c0.01-1.38,0.03-2.76,0.04-4.14c-0.24-0.07-0.78,0-1,0.03c-0.05,0.01-0.1,0.01-0.14,0.02
							c-0.42,0.05-0.87,0.13-1.4,0.26c-0.07,0.15-0.12,0.77-0.15,1.15c-0.05,0.62-0.07,0.88-0.15,1c-0.22,0.36-0.54,0.41-0.82,0.45
							c-0.09,0.01-0.19,0.03-0.28,0.05l-2.51,0.64c-0.8,0.21-1.09,0.67-1.07,1.67c0.05,2.07,0.05,4.17,0.05,6.21
							c0,0.75,0,1.51,0,2.26c2.94-0.06,3.04,0.06,4.1,3.25l0.03,0.1c0.2,0.61,0.42,1.24,0.3,1.92c1.83,1.96,1.97,4.4,2.11,6.76
							c0.02,0.39,0.05,0.8,0.08,1.19c0.03,0.43,0.03,0.92-0.26,1.2c-0.21,0.2-0.54,0.27-1.01,0.21c-1.04-0.13-3.14-0.28-3.16-0.28
							c-1.08-0.11-3.19-0.31-3.36-0.33l-1.54,0.05l-3.49-0.32l-1.89-0.24c-1.1-0.08-3.32-0.27-3.34-0.27l-2.27-0.29l-3.14-0.33
							l-0.8,0.2c-0.01,0.49-0.02,0.99-0.03,1.48c-0.03,1.35-0.05,2.75-0.05,4.13c0,0.3-0.17,0.45-0.3,0.57
							c-0.09,0.08-0.12,0.11-0.12,0.16c0,0.06,0.06,0.22,0.44,0.59c1.02,0.04,1.58-0.13,2.35-0.37c0.12-0.04,0.25-0.08,0.39-0.12
							c-0.26-0.11-0.47-0.35-0.48-1c-0.01-0.57,0.19-0.9,0.36-1.15c0.14-0.22,0.24-0.37,0.22-0.63c-0.03-0.35,0.18-0.46,0.27-0.49
							c0.38-0.13,1.06,0.23,1.29,0.6c0.33,0.52,0.35,1.09,0.37,1.64c0.01,0.2,0.01,0.39,0.03,0.58c0.41-0.01,0.8,0.09,1.19,0.18
							c0.38,0.09,0.74,0.19,1.1,0.17c0.01-0.11,0.02-0.26,0.03-0.41c0.02-0.41,0.05-0.84,0.12-1.05c0.05-0.14,0.08-0.3,0.12-0.47
							c0.13-0.61,0.3-1.37,1.15-1.35c0.97,0.03,1.45,1.12,1.49,1.88c0.01,0.25-0.01,0.48-0.03,0.71c-0.04,0.42-0.07,0.81,0.08,1.34
							c0.59,0.39,1.27,0.62,1.94,0.83c0-0.12,0-0.23-0.01-0.35c-0.02-1.02-0.04-2.08,0.44-3.08c0.19-0.4,0.45-0.94,1.16-0.93
							c0.55,0.01,0.93,0.35,1.12,1c0.2,0.7,0.22,1.4,0.23,2.07c0.02,0.86,0.04,1.76,0.45,2.69c0.11,0.07,0.23,0.14,0.34,0.22
							c0.53,0.34,1.03,0.67,1.43,0.53c0.29-0.1,0.55-0.47,0.75-1.09c0.15-0.44,0.78-0.7,1.31-0.55c0.68,0.21,1.23,0.45,1.7,0.75
							c0.84,0.54,1.52,0.52,2.35-0.09c1.78-1.3,3.46-2.15,5.14-2.58c1.17-0.3,2.49-0.48,3.4,1.18c0.39,0.71,1.14,1.23,2.11,1.47
							c1.06,0.26,2.23,0.13,3.12-0.34c-0.28-0.18-0.35-0.51-0.37-0.83c-0.06-1.08-0.15-2.19-0.23-3.25l-0.02-0.32
							c-0.03-0.46,0.06-0.79,0.28-0.99c0.2-0.18,0.5-0.23,0.87-0.16c0.18,0.04,0.35,0.07,0.53,0.11c1.04,0.21,2.12,0.42,3.14,0.76
							c0.44,0.14,0.71,0.36,0.83,0.65c0.19,0.46-0.04,0.99-0.28,1.47c1.32,0.68,2.5,1.58,3.65,2.46c0.69,0.52,1.39,1.06,2.12,1.55
							C403.07,2624.54,403.65,2624.82,403.93,2625.51z M377.22,2588.24C377.22,2588.24,377.22,2588.24,377.22,2588.24
							C377.22,2588.24,377.22,2588.24,377.22,2588.24z M402.17,2623.32C402.17,2623.32,402.17,2623.32,402.17,2623.32
							c-0.36,0-0.62-0.09-0.8-0.27c-0.25-0.26-0.27-0.64-0.26-1.01c0.01-0.29,0.01-0.58,0-0.86c0-0.13,0-0.26,0-0.39l0.01-0.17
							c0-0.07,0-0.15,0-0.23c0-0.28,0-0.58,0-0.86c-0.01-0.35,0.07-0.61,0.25-0.79c0.19-0.19,0.47-0.28,0.87-0.28
							c0.32,0,0.73,0.04,0.98,0.3c0.18,0.18,0.26,0.45,0.25,0.8c-0.02,0.65-0.01,1.31-0.01,1.94l0,0.57c0,0.41-0.1,0.71-0.31,0.91
							C402.94,2623.21,402.62,2623.32,402.17,2623.32z M401.45,2620.95c0,0.07,0,0.15,0,0.22c0,0.29,0,0.58,0,0.88
							c-0.01,0.29,0,0.6,0.16,0.77c0.11,0.11,0.29,0.17,0.55,0.17c0.36,0.01,0.61-0.07,0.76-0.22c0.14-0.14,0.21-0.36,0.21-0.68
							l0-0.57c0-0.64-0.01-1.3,0.01-1.95c0.01-0.26-0.04-0.45-0.16-0.56c-0.13-0.14-0.36-0.2-0.75-0.2c-0.3,0-0.51,0.06-0.63,0.18
							c-0.11,0.11-0.16,0.29-0.15,0.55c0.01,0.29,0.01,0.59,0,0.87c0,0.13,0,0.26,0,0.39L401.45,2620.95z M393.94,2615.86
							c-0.46,0-0.95-0.09-1.43-0.27c-0.74-0.28-0.67-0.91-0.62-1.42c0.02-0.22,0.05-0.43,0-0.59l-0.01-0.04
							c0-0.85,0.16-1.36,0.49-1.6c0.4-0.29,0.95-0.1,1.48,0.07c0.14,0.05,0.29,0.1,0.44,0.14l1.04,0.39l0,0.12
							c0,0.15,0,0.31,0.01,0.46c0.01,0.22,0.01,0.44,0,0.66c-0.01,0.17,0.06,0.38,0.12,0.58c0.13,0.39,0.31,0.92-0.25,1.22
							C394.84,2615.76,394.41,2615.86,393.94,2615.86z M392.22,2613.51c0.05,0.21,0.02,0.46,0,0.69c-0.06,0.55-0.07,0.89,0.4,1.07
							c0.89,0.33,1.8,0.33,2.44,0c0.28-0.15,0.25-0.35,0.09-0.82c-0.08-0.23-0.16-0.47-0.14-0.71c0.02-0.2,0.01-0.41,0-0.62
							c0-0.12-0.01-0.24-0.01-0.36l-0.81-0.31c-0.14-0.04-0.29-0.09-0.44-0.14c-0.47-0.16-0.92-0.31-1.18-0.12
							C392.34,2612.37,392.22,2612.81,392.22,2613.51z"/>
					</g>
					<g>
						<path className="st27" d="M354.53,2614.35c0.09,0.84-0.03,1.75-0.04,2.59c-0.01,1.02-0.02,2.04-0.04,3.06l0.11,1.57
							c-1.56-0.47-4.07-1.27-5.64-1.69c-0.53-0.14-1.1-0.08-1.66-0.11c-0.06-0.28-0.12-0.56-0.18-0.84
							c-0.16-0.75-0.05-1.52-0.05-2.27c0-0.89-0.02-1.77-0.03-2.66c-0.05-2.58-0.22-5.15-0.22-7.73c0.16-1.55,0.91-2.95,1.9-4.13
							c1.21-1.45,1.08-3.28,1.59-4.99c0.77,1.78,0.73,3.98,2.75,5.03c0.14,1.18,1.53,1.75,1.46,3.03
							C354.48,2608.26,354.22,2611.31,354.53,2614.35z"/>
					</g>
					<g>
						<path className="st28" d="M396.26,2607.67c-3.5-0.16-6.94-0.85-10.42-1.23c-1.58-0.17-3.14-0.47-4.72-0.61
							c-1.07-0.1-1.39-0.55-1.37-1.61c0.11-5.46,0.14-10.93,0.2-16.4c0-0.43,1.68,0.59,1.85,0.76c0.34,0.33,0.41,0.89,0.55,1.31
							c0.28,0.9,0.74,0.99,1.57,0.96c0.46-0.02,1.43-0.12,1.42,0.57c-0.02,3.81-0.12,7.62,0.23,11.43c0.06,0.71,0.26,1.1,1.01,1.24
							c2.5,0.47,4.94,1.22,7.49,1.37C395.5,2605.54,396.11,2606.36,396.26,2607.67z"/>
						<path className="st26" d="M396.45,2607.84l-0.19-0.01c-2.22-0.1-4.44-0.42-6.6-0.73c-1.25-0.18-2.55-0.36-3.83-0.5
							c-0.71-0.08-1.43-0.18-2.13-0.28c-0.85-0.12-1.72-0.25-2.59-0.33c-1.12-0.1-1.54-0.6-1.52-1.78
							c0.08-4.05,0.12-8.17,0.16-12.15c0.01-1.42,0.03-2.83,0.04-4.25c0-0.11,0.06-0.2,0.16-0.24c0.43-0.18,1.88,0.79,1.97,0.88
							c0.29,0.29,0.4,0.71,0.5,1.08c0.03,0.1,0.05,0.21,0.08,0.3c0.25,0.81,0.62,0.88,1.41,0.85l0.1,0
							c0.39-0.02,0.98-0.05,1.29,0.25c0.13,0.13,0.2,0.29,0.2,0.49c0,0.6-0.01,1.21-0.01,1.81c-0.03,3.15-0.05,6.41,0.24,9.6
							c0.07,0.73,0.27,0.98,0.87,1.09c0.81,0.15,1.62,0.33,2.4,0.51c1.65,0.37,3.35,0.76,5.07,0.85c1.4,0.08,2.17,0.85,2.34,2.35
							L396.45,2607.84z M380.12,2587.88c-0.02,1.4-0.03,2.79-0.04,4.19c-0.04,3.98-0.08,8.1-0.16,12.15
							c-0.02,1.01,0.28,1.36,1.22,1.45c0.87,0.08,1.75,0.21,2.6,0.33c0.69,0.1,1.41,0.21,2.12,0.28c1.28,0.14,2.58,0.33,3.84,0.51
							c2.08,0.3,4.23,0.61,6.37,0.71c-0.19-1.22-0.81-1.8-2-1.87c-1.75-0.1-3.46-0.49-5.12-0.86c-0.78-0.18-1.59-0.36-2.39-0.51
							c-0.86-0.16-1.08-0.65-1.14-1.39c-0.29-3.21-0.27-6.48-0.24-9.64c0-0.6,0.01-1.21,0.01-1.81c0-0.11-0.03-0.19-0.09-0.25
							c-0.21-0.2-0.73-0.17-1.05-0.16l-0.11,0c-0.81,0.03-1.41-0.03-1.74-1.08c-0.03-0.1-0.06-0.21-0.09-0.31
							c-0.09-0.35-0.19-0.71-0.41-0.93C381.51,2588.52,380.43,2587.91,380.12,2587.88z"/>
					</g>
					<g>
						<path className="st8" d="M335.94,2617.43c-1.33,1.47-3.19,0.76-4.78,1.11c-0.07,0.02-0.21-0.26-0.32-0.41
							c0.14-0.23,0.16-0.49-0.16-0.5c-1.26-0.01-2.56-0.52-3.76,0.36c-0.34,0.25-0.78,0.59-1.23,0.1c0.03-0.53,0.05-1.07,0.08-1.6
							c0-0.15,0-0.3,0-0.45c0.88-3.39,0.66-6.85,0.63-10.3c-0.01-0.94,0.68-0.66,1.19-0.71c0.71-0.08,1.18,0.07,1.17,0.94
							c-0.02,2.15,0.09,4.3-0.03,6.44c-0.07,1.39,0.4,1.87,1.73,1.65c0.36-0.06,0.75-0.05,1.12-0.03c0.85,0.05,1.14-0.35,1.13-1.18
							c-0.04-2.33-0.01-4.67-0.04-7.01c-0.01-0.8,0.06-1.3,1.11-1.28c1.01,0.02,1.28,0.38,1.25,1.33c-0.06,1.59,0.05,3.18-0.01,4.76
							C334.92,2612.98,335.53,2615.19,335.94,2617.43z"/>
						<path className="st7" d="M331.14,2618.71c-0.14,0-0.23-0.14-0.34-0.32c-0.03-0.05-0.07-0.11-0.1-0.15l-0.07-0.09l0.06-0.1
							c0.08-0.13,0.08-0.21,0.07-0.22c0,0-0.02-0.02-0.1-0.02c-0.34,0-0.69-0.04-1.02-0.08c-0.93-0.1-1.81-0.2-2.64,0.41l-0.02,0.02
							c-0.33,0.24-0.89,0.65-1.43,0.06l-0.05-0.05l0.08-1.67l0.01-0.48c0.74-2.87,0.7-5.85,0.65-8.73
							c-0.01-0.51-0.02-1.01-0.02-1.52c0-0.32,0.07-0.55,0.22-0.69c0.21-0.2,0.52-0.19,0.79-0.18c0.12,0,0.23,0.01,0.33,0
							c0.34-0.04,0.77-0.04,1.06,0.22c0.21,0.19,0.3,0.48,0.3,0.89c-0.01,0.78,0,1.57,0.01,2.33c0.02,1.35,0.03,2.75-0.04,4.12
							c-0.04,0.68,0.06,1.1,0.31,1.32c0.23,0.21,0.63,0.26,1.23,0.16c0.39-0.07,0.8-0.05,1.16-0.03c0.35,0.02,0.58-0.04,0.73-0.18
							c0.16-0.15,0.23-0.42,0.22-0.83c-0.02-1.22-0.02-2.46-0.02-3.66c0-1.1,0-2.23-0.02-3.34c-0.01-0.46,0.01-0.89,0.28-1.17
							c0.2-0.2,0.52-0.29,0.99-0.29c0.55,0.01,0.9,0.12,1.12,0.35c0.22,0.23,0.31,0.58,0.29,1.14c-0.03,0.78-0.02,1.57-0.01,2.33
							c0.01,0.8,0.02,1.62-0.01,2.43c-0.07,1.77,0.29,3.52,0.63,5.21c0.1,0.5,0.2,1.01,0.3,1.51l0.01,0.08l-0.05,0.06
							c-0.94,1.04-2.13,1.05-3.28,1.05c-0.53,0-1.09,0-1.59,0.11C331.17,2618.7,331.16,2618.71,331.14,2618.71z M331.03,2618.12
							c0.02,0.03,0.04,0.06,0.05,0.08c0.03,0.05,0.07,0.11,0.1,0.15c0.52-0.11,1.07-0.11,1.6-0.11c1.1,0,2.14,0,2.98-0.88
							c-0.09-0.48-0.19-0.96-0.28-1.43c-0.35-1.71-0.71-3.48-0.64-5.29c0.03-0.8,0.02-1.62,0.01-2.41
							c-0.01-0.77-0.02-1.57,0.01-2.35c0.02-0.46-0.04-0.74-0.2-0.9c-0.15-0.16-0.44-0.24-0.89-0.25c-0.37-0.01-0.62,0.05-0.75,0.19
							c-0.17,0.16-0.19,0.45-0.19,0.93c0.02,1.12,0.02,2.25,0.02,3.35c0,1.2,0,2.44,0.02,3.66c0.01,0.51-0.09,0.85-0.32,1.08
							c-0.22,0.21-0.53,0.3-0.98,0.27c-0.34-0.02-0.73-0.03-1.08,0.03c-0.71,0.12-1.19,0.04-1.51-0.24
							c-0.33-0.29-0.46-0.79-0.42-1.58c0.07-1.36,0.06-2.75,0.04-4.09c-0.01-0.77-0.02-1.56-0.01-2.34c0-0.31-0.06-0.52-0.19-0.64
							c-0.15-0.13-0.4-0.17-0.8-0.13c-0.12,0.01-0.25,0.01-0.37,0c-0.23-0.01-0.44-0.02-0.55,0.09c-0.08,0.08-0.12,0.23-0.12,0.45
							c0,0.51,0.01,1.01,0.02,1.52c0.05,2.9,0.1,5.89-0.65,8.8l0,0.43l-0.08,1.53c0.3,0.28,0.56,0.13,0.94-0.15l0.02-0.02
							c0.93-0.69,1.92-0.57,2.87-0.47c0.33,0.04,0.66,0.07,0.99,0.08c0.23,0,0.34,0.1,0.38,0.19
							C331.13,2617.79,331.12,2617.95,331.03,2618.12z"/>
					</g>
					<g>
						<path className="st27" d="M372.16,2601.83c3.02-0.06,3.04-0.07,4.11,3.13c0.21,0.64,0.46,1.31,0.32,2.02
							c-0.47-0.37-1.03-0.4-1.59-0.45c-3.85-0.38-7.71-0.78-11.56-1.17c-0.67-1.13-1.04-2.59-2.81-2.53c-0.16,0-0.33-0.28-0.5-0.43
							c-0.01-1.77,0.02-3.55-0.05-5.32c-0.03-0.92,0.18-1.37,1.22-1.33c0.91,0.03,1.34,0.25,1.3,1.27
							c-0.08,1.71-0.02,3.42,0.28,5.25c0.5-0.69,0.98-1.39,1.5-2.06c0.32-0.42,0.52-1.05,1.26-0.9
							C367.38,2601.25,369.33,2602.68,372.16,2601.83z"/>
					</g>
					<g>
						<path className="st29" d="M313.18,2633.83c-0.09,0.63-0.48,1.09-0.87,1.57c-0.23,0.28-0.57,0.61-0.36,0.99
							c0.23,0.42,0.66,0.17,1.01,0.08c0.83-0.23,1.67-0.11,2.51-0.11c0.37,0,0.81,0.02,0.9,0.48c0.09,0.45-0.29,0.69-0.61,0.85
							c-1.88,0.92-3.79,1.8-5.68,2.71c-0.73,0.35-1.68,0.35-1.87,1.54c-0.08,0.51-0.78,0.25-1.15-0.09
							c-0.3-0.27-0.31-0.66-0.33-1.04c-0.12-1.84-0.1-1.79-1.75-1.01c-1.23,0.57-2.25,1.52-3.59,1.86c-0.21,0.06-0.5,0.19-0.64,0.11
							c-0.3-0.19-0.11-0.5,0-0.75c0.2-0.47,0.45-0.93,0.61-1.41c0.09-0.28,0.38-0.63,0.03-0.9c-0.3-0.23-0.61-0.04-0.92,0.09
							c-1.42,0.59-2.84,1.17-4.26,1.72c-0.21,0.08-0.46,0.04-0.69,0.06c0.22-0.56,0.75-0.8,1.21-1.11c1.32-0.87,2.69-1.66,3.71-2.92
							c0.44-0.55,1.08-1.03,1.81-0.91c2.42,0.4,4.59-0.49,6.8-1.18C310.39,2634.02,311.76,2633.8,313.18,2633.83z"/>
						<path className="st8" d="M307.87,2642.38c-0.33,0-0.71-0.23-0.92-0.42c-0.33-0.3-0.36-0.71-0.38-1.07l-0.01-0.12
							c-0.05-0.71-0.08-1.22-0.21-1.3c-0.13-0.08-0.56,0.13-1.16,0.41l-0.14,0.07c-0.46,0.22-0.91,0.49-1.34,0.76
							c-0.7,0.44-1.43,0.89-2.28,1.11c-0.05,0.01-0.1,0.03-0.16,0.05c-0.22,0.07-0.44,0.14-0.61,0.04c-0.39-0.25-0.21-0.64-0.1-0.87
							c0.01-0.03,0.03-0.06,0.04-0.08c0.07-0.17,0.15-0.34,0.23-0.51c0.14-0.29,0.28-0.59,0.38-0.89c0.02-0.07,0.05-0.14,0.08-0.2
							c0.12-0.27,0.16-0.39,0-0.51c-0.17-0.13-0.35-0.06-0.63,0.06c-0.04,0.02-0.08,0.04-0.12,0.05c-1.51,0.63-2.9,1.19-4.27,1.73
							c-0.17,0.07-0.35,0.07-0.53,0.07c-0.07,0-0.14,0-0.21,0l-0.26,0.02l0.1-0.25c0.21-0.52,0.63-0.78,1.04-1.04
							c0.08-0.05,0.15-0.1,0.23-0.15c0.18-0.12,0.36-0.23,0.53-0.35c1.12-0.72,2.27-1.47,3.14-2.54c0.59-0.74,1.29-1.08,1.97-0.97
							c2.07,0.34,3.91-0.26,5.86-0.9c0.29-0.09,0.57-0.19,0.86-0.28c1.42-0.45,2.83-0.65,4.18-0.62l0.19,0l-0.03,0.18
							c-0.09,0.66-0.48,1.13-0.86,1.59l-0.04,0.05c-0.03,0.03-0.05,0.06-0.08,0.09c-0.24,0.28-0.39,0.49-0.27,0.7
							c0.12,0.2,0.27,0.18,0.62,0.06c0.07-0.02,0.13-0.04,0.19-0.06c0.63-0.17,1.27-0.15,1.89-0.13c0.22,0.01,0.45,0.01,0.67,0.01
							c0.33,0,0.94-0.01,1.07,0.61c0.13,0.63-0.47,0.92-0.7,1.03c-1.07,0.52-2.16,1.04-3.21,1.54c-0.82,0.39-1.64,0.78-2.46,1.17
							c-0.18,0.08-0.36,0.15-0.54,0.21c-0.59,0.21-1.1,0.38-1.24,1.2c-0.03,0.2-0.14,0.34-0.31,0.4
							C308.01,2642.37,307.94,2642.38,307.87,2642.38z M306.29,2639.11c0.09,0,0.17,0.02,0.24,0.06c0.28,0.17,0.31,0.61,0.37,1.57
							l0.01,0.13c0.02,0.34,0.04,0.64,0.27,0.85c0.25,0.23,0.62,0.38,0.78,0.32c0.04-0.01,0.08-0.04,0.09-0.14
							c0.16-1.01,0.85-1.25,1.45-1.46c0.18-0.06,0.35-0.12,0.51-0.2c0.82-0.39,1.64-0.78,2.46-1.17c1.05-0.5,2.14-1.01,3.21-1.53
							c0.42-0.2,0.57-0.4,0.52-0.66c-0.06-0.3-0.3-0.35-0.74-0.35c-0.23,0-0.46-0.01-0.68-0.01c-0.62-0.02-1.21-0.04-1.79,0.12
							c-0.06,0.02-0.12,0.04-0.18,0.06c-0.3,0.1-0.76,0.25-1.02-0.21c-0.24-0.43,0.07-0.81,0.3-1.08c0.03-0.03,0.05-0.06,0.08-0.09
							l0.04-0.05c0.32-0.4,0.63-0.77,0.75-1.24c-1.26-0.01-2.56,0.19-3.88,0.61c-0.29,0.09-0.57,0.18-0.86,0.28
							c-1.9,0.62-3.87,1.27-6.02,0.91c-0.74-0.12-1.35,0.48-1.65,0.85c-0.9,1.11-2.08,1.88-3.22,2.61
							c-0.18,0.11-0.36,0.23-0.53,0.35c-0.08,0.05-0.16,0.1-0.24,0.15c-0.3,0.19-0.6,0.37-0.79,0.65c0.13,0,0.26,0,0.35-0.04
							c1.36-0.53,2.76-1.1,4.26-1.72c0.04-0.02,0.08-0.03,0.11-0.05c0.28-0.12,0.63-0.28,0.97-0.02c0.38,0.29,0.21,0.68,0.1,0.91
							c-0.03,0.06-0.05,0.11-0.07,0.17c-0.11,0.32-0.25,0.63-0.39,0.93c-0.08,0.16-0.15,0.33-0.23,0.49
							c-0.01,0.03-0.03,0.06-0.04,0.09c-0.13,0.29-0.14,0.38-0.02,0.45c0.05,0.02,0.23-0.04,0.33-0.07
							c0.06-0.02,0.12-0.04,0.18-0.05c0.8-0.21,1.47-0.63,2.19-1.07c0.44-0.27,0.89-0.56,1.37-0.78l0.14-0.07
							C305.67,2639.28,306.03,2639.11,306.29,2639.11z"/>
					</g>
					<g>
						<path className="st29" d="M292.31,2642.96c0.11,0.51-0.09,1.18,0.77,1.27c0.53,0.05,1.13-0.18,1.66,0.35
							c-2.52,1.83-4.32,4.52-7.41,5.6c-1.15,0.4-2.23,0.59-3.41,0.31c-1.63-0.4-4.47-0.66-4.47-0.66c0.16-0.2-0.66,0.2-0.5,0
							c0.3-0.2,1.09-1,1.39-1.2c1.12-0.13,2.26,0.12,3.37-0.17c0.97-0.03,1.95-0.1,2.92-0.06c0.63,0.02,0.84-0.32,0.91-0.82
							C287.92,2644.77,289.55,2643.29,292.31,2642.96z"/>
						<path className="st8" d="M285.06,2650.79c-0.4,0-0.79-0.05-1.18-0.14c-1.6-0.39-4.42-0.65-4.45-0.65l-0.16-0.01
							c-0.01,0-0.02,0.01-0.03,0.01c-0.22,0.07-0.37,0.11-0.45-0.02c-0.02-0.03-0.07-0.13,0.03-0.25l0.03-0.03
							c0.14-0.1,0.41-0.35,0.68-0.59c0.29-0.26,0.55-0.51,0.71-0.62l0.03-0.02l0.04,0c0.47-0.05,0.96-0.04,1.42-0.03
							c0.67,0.02,1.3,0.03,1.93-0.13l0.04-0.01c0.29-0.01,0.58-0.02,0.87-0.03c0.68-0.03,1.37-0.06,2.06-0.03
							c0.46,0.01,0.67-0.17,0.74-0.67c0.38-2.81,2.03-4.41,4.91-4.76l0.15-0.02l0.03,0.15c0.03,0.12,0.04,0.25,0.04,0.38
							c0.03,0.46,0.08,0.71,0.58,0.76c0.13,0.01,0.27,0.01,0.42,0c0.42-0.02,0.9-0.04,1.34,0.4l0.14,0.14l-0.16,0.11
							c-0.88,0.64-1.69,1.4-2.47,2.14c-1.45,1.37-2.95,2.78-4.98,3.49C286.52,2650.64,285.77,2650.79,285.06,2650.79z
							 M279.6,2649.68c0.58,0.06,2.94,0.3,4.36,0.65c1.02,0.25,2.01,0.16,3.32-0.3c1.96-0.69,3.44-2.07,4.86-3.42
							c0.74-0.7,1.5-1.41,2.33-2.04c-0.29-0.21-0.61-0.19-0.94-0.18c-0.15,0.01-0.31,0.01-0.47,0c-0.8-0.08-0.85-0.65-0.88-1.06
							c0-0.06-0.01-0.12-0.02-0.18c-2.65,0.37-4.11,1.82-4.46,4.45c-0.09,0.67-0.44,0.98-1.08,0.96c-0.68-0.02-1.37,0.01-2.04,0.03
							c-0.28,0.01-0.57,0.02-0.85,0.03c-0.67,0.17-1.35,0.15-2,0.14c-0.44-0.01-0.89-0.02-1.33,0.03c-0.15,0.11-0.4,0.34-0.64,0.56
							c-0.1,0.09-0.19,0.18-0.29,0.26C279.53,2649.61,279.57,2649.63,279.6,2649.68z"/>
					</g>
					<g>
						<path className="st29" d="M360.13,2602.4c0.17,0.15,0.34,0.44,0.5,0.43c1.76-0.05,2.13,1.4,2.81,2.53
							c-0.55,0.02-1.1,0.07-1.65,0.04c-2.44-0.13-4.87-0.36-7.32-0.19c0.07-1.28-1.32-1.85-1.46-3.03c1.88,0.1,3.76,0.2,5.64,0.29
							C359.14,2602.51,359.65,2602.66,360.13,2602.4z"/>
					</g>
					<g>
						<path className="st2" d="M325.69,2618.1c0.45,0.49,0.89,0.15,1.23-0.1c1.19-0.88,7.76-0.58,9.02-0.57c0.33,0-0.38,0.67-0.52,0.91
							c-2.27,0.62-9.03,1.33-11.35,1.77c-0.13-0.48,0.2-0.7,0.55-0.77C325.32,2619.18,325.53,2618.68,325.69,2618.1z"/>
					</g>
					<g>
						<path className="st28" d="M406.63,2625.91c-0.21-3.27-0.42-6.54-0.64-9.81c0,0,0.77-0.17,1.18-0.11c3.1,0.49,3.09,0.48,3.16-2.74
							c0.01-0.63-0.17-1.31,0.27-1.89c1.64-2.1,2.49-4.61,3.58-6.99c1.43,0.38,2.45,1.46,3.65,2.21c0.97,0.61,1.86,1,2.95,0.3
							c0.85-0.55,0.97,0.34,1.1,0.75c0.46,1.4,0.7,2.85,0.86,4.31c0.09,0.86,0.09,1.78,0.27,2.64c0.12,0.6,0.31,1.27,0.75,1.72
							c0.57,0.58,1.46,0.73,1.87,1.48c0.71,1.31,0.49,2.77,0.6,4.15c0.15,1.89,0.05,3.8,0.05,5.7c-0.2-0.18-0.39-0.36-0.59-0.54
							c0,0,0-0.01,0-0.01c-0.08-0.09-0.16-0.18-0.24-0.27c-0.7-0.75-1.68-1.05-2.6-1.11c-1.16-0.06-2.21-0.74-3.39-0.49
							c-0.24-0.05-0.49-0.1-0.73-0.15c-0.1-0.09-0.2-0.17-0.29-0.26c-0.86-0.53-1.47,0.19-2.17,0.43c-1.13,0.15-2.26,0.32-3.39,0.45
							c-0.47,0.06-0.62,0.33-0.65,0.74c-1.03-0.06-2.02,0.05-2.97,0.49C408.34,2626.73,407.29,2626.83,406.63,2625.91z
							 M411.35,2619.83c0.14-0.74-0.09-1.16-1.01-1.14c-0.83,0.02-1.18,0.33-1.15,1.15c0.03,0.68-0.03,1.3,0.97,1.28
							C411.05,2621.11,411.47,2620.79,411.35,2619.83z M414.08,2610.09c-0.15,0.68-0.01,1.21,0.93,1.18c0.69-0.02,1.18-0.15,1.12-1
							c-0.05-0.64,0.1-1.32-0.92-1.32C414.43,2608.95,413.93,2609.11,414.08,2610.09z"/>
						<path className="st26" d="M426.47,2628.03l-0.9-0.83l-0.01-0.02l-0.22-0.24c-0.74-0.8-1.76-1.01-2.49-1.05
							c-0.48-0.03-0.94-0.15-1.39-0.28c-0.66-0.18-1.29-0.36-1.96-0.22l-0.03,0.01l-0.03-0.01c-0.24-0.05-0.49-0.1-0.73-0.15
							l-0.04-0.01l-0.32-0.28c-0.53-0.32-0.94-0.09-1.42,0.17c-0.19,0.11-0.39,0.21-0.6,0.29l-0.03,0.01
							c-0.32,0.04-0.65,0.09-0.97,0.13c-0.79,0.11-1.61,0.22-2.42,0.32c-0.33,0.04-0.47,0.2-0.51,0.59l-0.01,0.16l-0.16-0.01
							c-1.11-0.07-2.05,0.09-2.89,0.48l-0.05,0.02l-0.06-0.01c-0.21-0.05-0.43-0.08-0.65-0.11c-0.76-0.11-1.55-0.22-2.09-0.99
							l-0.03-0.04l-0.65-9.99l0.14-0.03c0.03-0.01,0.81-0.18,1.24-0.11c1.44,0.23,2.24,0.35,2.58,0.07
							c0.34-0.29,0.36-1.11,0.39-2.62l0-0.03c0-0.15-0.01-0.29-0.01-0.45c-0.03-0.52-0.06-1.05,0.32-1.54
							c1.24-1.59,2.04-3.45,2.81-5.26c0.24-0.56,0.49-1.14,0.75-1.7l0.06-0.13l0.14,0.04c1.01,0.27,1.83,0.88,2.61,1.46
							c0.35,0.26,0.71,0.53,1.09,0.77c1.04,0.65,1.83,0.91,2.78,0.3c0.3-0.19,0.55-0.24,0.76-0.16c0.34,0.13,0.45,0.54,0.54,0.84
							c0.02,0.06,0.03,0.11,0.05,0.16c0.41,1.23,0.68,2.61,0.87,4.34c0.03,0.3,0.05,0.61,0.07,0.91c0.04,0.57,0.08,1.16,0.2,1.72
							c0.11,0.54,0.29,1.21,0.71,1.64c0.22,0.22,0.49,0.38,0.78,0.54c0.42,0.24,0.86,0.49,1.12,0.97c0.54,1,0.56,2.07,0.58,3.11
							c0.01,0.37,0.01,0.74,0.04,1.11c0.11,1.33,0.09,2.69,0.07,4c-0.01,0.56-0.02,1.14-0.01,1.71L426.47,2628.03z M425.87,2627.02
							l0.26,0.24c0-0.45,0.01-0.9,0.01-1.33c0.02-1.31,0.04-2.66-0.07-3.97c-0.03-0.37-0.04-0.76-0.04-1.13
							c-0.02-1-0.03-2.04-0.54-2.96c-0.22-0.4-0.6-0.62-1-0.84c-0.29-0.17-0.6-0.34-0.85-0.6c-0.48-0.49-0.67-1.22-0.79-1.8
							c-0.12-0.58-0.16-1.18-0.2-1.76c-0.02-0.3-0.04-0.6-0.07-0.89c-0.18-1.71-0.45-3.07-0.85-4.28c-0.02-0.05-0.03-0.11-0.05-0.17
							c-0.07-0.23-0.16-0.56-0.34-0.62c-0.13-0.05-0.33,0.04-0.47,0.13c-1.21,0.77-2.21,0.28-3.13-0.3
							c-0.38-0.24-0.75-0.52-1.11-0.78c-0.76-0.57-1.48-1.1-2.36-1.37c-0.24,0.52-0.46,1.05-0.68,1.56
							c-0.78,1.82-1.59,3.71-2.86,5.33c-0.3,0.39-0.28,0.84-0.25,1.32c0.01,0.15,0.02,0.31,0.01,0.47l0,0.03
							c-0.03,1.65-0.05,2.48-0.51,2.86c-0.45,0.38-1.25,0.26-2.84,0.01c-0.25-0.04-0.71,0.03-0.98,0.08l0.62,9.61
							c0.46,0.62,1.13,0.71,1.84,0.82c0.2,0.03,0.41,0.06,0.62,0.1c0.84-0.38,1.77-0.54,2.84-0.49c0.06-0.31,0.22-0.66,0.78-0.73
							c0.8-0.1,1.62-0.21,2.41-0.32c0.32-0.04,0.64-0.09,0.96-0.13c0.18-0.06,0.35-0.16,0.53-0.26c0.5-0.27,1.06-0.59,1.77-0.16
							l0.02,0.02l0.26,0.23c0.22,0.04,0.44,0.09,0.66,0.13c0.73-0.15,1.42,0.04,2.08,0.23c0.43,0.12,0.87,0.24,1.32,0.27
							c0.78,0.04,1.89,0.28,2.71,1.16L425.87,2627.02z M410.13,2621.29c-1.06,0-1.08-0.69-1.1-1.3l0-0.14
							c-0.02-0.45,0.07-0.76,0.28-0.98c0.21-0.22,0.54-0.33,1.03-0.34c0.49-0.01,0.82,0.1,1.02,0.33c0.19,0.22,0.24,0.54,0.16,0.98
							c0.06,0.48-0.01,0.83-0.22,1.07c-0.22,0.25-0.58,0.38-1.13,0.38C410.15,2621.29,410.14,2621.29,410.13,2621.29z
							 M410.4,2618.86c-0.02,0-0.04,0-0.06,0c-0.4,0.01-0.65,0.08-0.8,0.24c-0.14,0.15-0.2,0.39-0.19,0.74l0,0.14
							c0.02,0.63,0.03,0.98,0.76,0.98c0.01,0,0.02,0,0.04,0c0.45-0.01,0.73-0.09,0.88-0.27c0.15-0.17,0.19-0.44,0.14-0.84l0-0.03
							l0.01-0.03c0.07-0.35,0.04-0.59-0.08-0.73C410.98,2618.93,410.75,2618.86,410.4,2618.86z M414.96,2611.44
							c-0.42,0-0.72-0.11-0.9-0.33c-0.19-0.23-0.24-0.57-0.14-1.03c-0.06-0.44-0.01-0.76,0.18-0.97c0.25-0.29,0.69-0.33,1.12-0.33
							c1.04,0,1.06,0.68,1.07,1.22c0,0.09,0,0.18,0.01,0.26c0.03,0.38-0.05,0.66-0.22,0.85c-0.25,0.27-0.65,0.32-1.06,0.33
							C415,2611.44,414.98,2611.44,414.96,2611.44z M415.19,2609.12c-0.46,0-0.71,0.06-0.84,0.22c-0.12,0.14-0.15,0.38-0.1,0.73
							l0,0.03l-0.01,0.03c-0.08,0.36-0.06,0.62,0.06,0.77c0.12,0.15,0.35,0.21,0.7,0.21c0.45-0.01,0.69-0.08,0.82-0.22
							c0.11-0.12,0.16-0.32,0.14-0.6c-0.01-0.09-0.01-0.19-0.01-0.28c-0.02-0.55-0.03-0.89-0.74-0.89
							C415.2,2609.12,415.2,2609.12,415.19,2609.12z"/>
					</g>
					<g>
						<path className="st27" d="M414.19,2604.38c-1.09,2.38-1.94,4.89-3.58,6.99c-0.45,0.58-0.26,1.25-0.27,1.89
							c-0.06,3.22-0.06,3.22-3.16,2.74c-0.41-0.06-0.54,0.01-1.18,0.11c-1.49-0.03-2.94,0.2-4.4,0.52c-1.69,0.36-1.97,0.09-1.97-1.6
							c0-1.39,0.01-2.78,0.01-4.18c0-0.51-0.05-1.05,0.63-1.21c0.23,0.03,0.49,0.01,0.6-0.23c0.06-0.13,0.07-0.29,0.07-0.43
							c0.03-0.96,0.06-1.91,0.09-2.87c0.02-0.49-0.12-1.27,0.55-1.29c0.7-0.02,0.57,0.82,0.56,1.28c-0.03,0.88-0.03,1.76,0,2.64
							c0.02,0.61,0.35,0.91,0.96,0.88c0.87-0.03,1.74-0.01,2.61-0.03c0.34-0.01,0.8,0.01,1.03-0.29c0.24-0.32,0.16-0.92,0.15-1.28
							c-0.02-1.2,0-2.39,0-3.59c0-0.89-0.33-2.38,0.99-2.3c0.71,0.04,1.5-0.09,1.75,0.76c0.17,0.6,0.27,1.21,0.36,1.83
							c0.06,0.43-0.01,1.03,0.22,1.41c0,0,0.61-0.08,0.6-0.45c-0.02-0.99-0.06-1.99-0.08-2.98c-0.01-0.43,0.24-0.94,0.64-0.74
							c0.31,0.15,0.65,3.09,0.65,3.09C412.46,2605.3,413.45,2604.63,414.19,2604.38z"/>
					</g>
					<g>
						<path className="st30" d="M376,2661.5c0.23-1.09,0.66-2.12,0.62-3.28c-0.08-2.75-2.31-4.68-5.19-4.37
							c-0.51,0.05-1.01,0.14-1.52,0.23c-1.96,0.36-1.96,0.36-2.01-1.7c-0.05-2.03-2-3.92-4.04-2.92c-1.95,0.96-2.22,3.63-4.01,4.76
							c-1.15,0.73-2.06-0.16-3.24-0.16c-3.95,0.02-8.57,1.17-12.21-0.89c-2.06-1.17-3.86-2.81-5.76-4.23
							c-0.1-0.07-0.2-0.16-0.23-0.28c-0.03-0.15,0.08-0.29,0.18-0.39c1.54-1.6,4.58-2.29,6.62-2.98c2.65-0.9,5.35-1.66,8.02-2.53
							c1.88-0.61,3.77-1.22,5.58-2.01c0.75-0.33,2.24-0.81,2.57-1.68c1.21-3.17,1.38-3.23-1.2-5.45c-1.74-1.49-3.32-3.31-5.73-3.85
							c-1.13-0.25-2.27-0.48-3.41-0.72c0.15-1.52-0.84-1.88-2.04-2.03c0.78-1.06,1.56-1.22,2.68-0.33c1.38,1.1,2.92,2,4.38,3
							c0.63,0.43,1.18,0.59,1.55-0.31c0.29-0.68,0.8-0.66,1.39-0.42c0.99,0.41,1.99,0.81,3.01,1.16c1.96,0.68,2.66,2.32,3.26,4.08
							c0.78,2.33,0.78,2.29,2.47,0.47c1.04-1.12,1.66-2.31,1.57-3.88c-0.05-0.86-0.1-2.06,0.74-2.37c0.83-0.31,1.1,0.93,1.63,1.47
							c0.48,0.5,0.88,1.08,1.35,1.6c0.21,0.23,0.41,0.62,0.78,0.48c0.46-0.18,0.26-0.65,0.24-1c-0.04-0.56-0.18-1.1-0.22-1.66
							c-0.02-0.31-0.15-0.71,0.19-0.9c0.38-0.22,0.58,0.18,0.79,0.4c0.33,0.33,0.72,0.65,0.9,1.06c0.57,1.34,1.31,0.88,2.05,0.24
							c1.17-1.01,4.2-1.25,5.4-0.4c0.71,0.5,0.74,2.36-0.14,3.02c-1.07,0.81-0.47,1.11,0.28,1.3c0.95,0.24,1.95,1.45,2.91-0.15
							c0.32-0.53,0.56,0.17,0.73,0.42c1.78,2.62,3.58,5.23,5.29,7.9c0.57,0.88,0.92,0.65,1.25-0.05c0.61-1.3,1.49-2.49,1.7-3.97
							c0.09-0.63,0.4-1.1,1.07-1.34c1.19-0.42,2.34-0.93,3.52-1.39c0.25-0.1,0.53-0.28,0.78-0.04c0.25,0.24,0.08,0.52,0,0.78
							c-0.21,0.67-0.37,1.34-0.36,2.06c0.01,0.61,0.17,1.12,0.74,1.4c1.1,0.53,1.38,1.44,1.25,2.56c-0.07,0.61-0.01,1.14,0.82,1.13
							c0.45-0.01,1.01-0.09,1.09,0.57c0.08,0.61,0.11,1.23-0.44,1.68c-0.62,0.5-1.25,0.98-1.87,1.46c-0.32,0.25-0.83,0.45-0.63,0.96
							c0.18,0.45,0.7,0.4,1.12,0.39c0.98-0.03,1.89-0.43,2.86,0.45c1.37,1.24,1.47,2.95,1.97,4.48c0.24,0.72-0.82,0.74-1.39,0.66
							c-1.79-0.26-3.31-1.18-4.88-2c-2.47-1.29-5-2.41-7.72-3.02c-0.07,0.11-0.14,0.21-0.21,0.32c0.95,0.75,1.91,1.49,2.84,2.25
							c0.97,0.8,1.92,1.62,2.87,2.45c0.17,0.15,0.38,0.34,0.28,0.59c-0.14,0.34-0.48,0.26-0.73,0.22c-0.76-0.11-1.35-0.57-1.98-0.98
							c-2.54-1.63-4.79-3.77-7.89-4.4c-2.02-0.41-4.05-0.4-5.91,0.42c-1.45,0.63-2.33,1.94-2.67,3.53c-0.36,1.69-0.1,3.17,1.27,4.39
							c0.81,0.72,0.78,1.36-0.36,1.83C379.07,2660.25,377.78,2661.46,376,2661.5z"/>
						<path className="st8" d="M375.8,2661.67l0.04-0.21c0.07-0.33,0.16-0.65,0.24-0.97c0.21-0.76,0.4-1.48,0.38-2.27
							c-0.04-1.27-0.55-2.39-1.44-3.17c-0.93-0.82-2.2-1.18-3.56-1.04c-0.53,0.06-1.06,0.15-1.51,0.23
							c-1.03,0.19-1.54,0.28-1.85,0.03c-0.32-0.26-0.33-0.77-0.36-1.89c-0.03-1.09-0.62-2.14-1.52-2.69
							c-0.72-0.43-1.53-0.47-2.29-0.09c-0.98,0.48-1.51,1.41-2.07,2.39c-0.51,0.89-1.03,1.8-1.92,2.36
							c-0.79,0.5-1.49,0.29-2.17,0.09c-0.37-0.11-0.75-0.22-1.16-0.22c0,0-0.01,0-0.01,0c-1.01,0-2.04,0.08-3.14,0.16
							c-3.15,0.24-6.41,0.48-9.15-1.08c-1.44-0.82-2.77-1.88-4.06-2.9c-0.56-0.45-1.14-0.91-1.72-1.34
							c-0.1-0.07-0.25-0.19-0.29-0.38c-0.04-0.23,0.11-0.42,0.22-0.54c1.29-1.34,3.51-2.03,5.46-2.63c0.44-0.14,0.85-0.26,1.23-0.39
							c1.49-0.51,3.02-0.97,4.5-1.43c1.15-0.35,2.35-0.72,3.51-1.1l0.2-0.06c1.78-0.58,3.63-1.18,5.37-1.94
							c0.11-0.05,0.24-0.1,0.37-0.15c0.74-0.3,1.86-0.76,2.11-1.43l0.15-0.38c1.09-2.83,1.04-2.87-1.3-4.88
							c-0.33-0.28-0.65-0.58-0.97-0.87c-1.36-1.24-2.77-2.52-4.69-2.95c-0.78-0.17-1.57-0.34-2.34-0.5
							c-0.36-0.07-0.71-0.15-1.07-0.22l-0.15-0.03l0.01-0.15c0.13-1.32-0.63-1.68-1.9-1.85l-0.28-0.04l0.17-0.23
							c0.43-0.59,0.87-0.9,1.33-0.96c0.48-0.06,1,0.13,1.58,0.6c0.93,0.74,1.94,1.4,2.92,2.04c0.48,0.31,0.97,0.63,1.44,0.96
							c0.39,0.27,0.65,0.36,0.85,0.3c0.17-0.05,0.32-0.23,0.45-0.53c0.37-0.89,1.1-0.72,1.61-0.51c1.01,0.41,2,0.81,3,1.16
							c2.04,0.71,2.76,2.38,3.36,4.19c0.32,0.95,0.55,1.64,0.76,1.68c0.2,0.04,0.69-0.47,1.36-1.19l0.08-0.08
							c1.13-1.22,1.6-2.38,1.53-3.76c-0.04-0.82-0.12-2.18,0.85-2.54c0.7-0.26,1.08,0.39,1.39,0.91c0.13,0.22,0.26,0.45,0.41,0.61
							c0.27,0.28,0.51,0.59,0.75,0.88c0.19,0.24,0.39,0.49,0.6,0.72c0.04,0.04,0.08,0.09,0.12,0.15c0.2,0.25,0.31,0.35,0.47,0.29
							c0.22-0.09,0.22-0.25,0.16-0.59c-0.01-0.08-0.03-0.16-0.03-0.24c-0.02-0.28-0.07-0.56-0.11-0.84
							c-0.04-0.27-0.09-0.54-0.11-0.82c0-0.05-0.01-0.1-0.02-0.16c-0.04-0.29-0.09-0.69,0.29-0.9c0.43-0.24,0.71,0.09,0.88,0.3
							c0.04,0.05,0.08,0.09,0.11,0.13c0.06,0.06,0.13,0.13,0.19,0.19c0.29,0.28,0.58,0.56,0.74,0.92c0.19,0.44,0.41,0.7,0.64,0.75
							c0.34,0.08,0.75-0.23,1.15-0.57c1.25-1.08,4.34-1.31,5.6-0.41c0.36,0.26,0.58,0.81,0.59,1.47c0.01,0.6-0.18,1.4-0.73,1.82
							c-0.5,0.38-0.52,0.57-0.51,0.63c0.03,0.13,0.27,0.26,0.73,0.38c0.26,0.06,0.51,0.19,0.76,0.32c0.74,0.37,1.33,0.67,1.96-0.39
							c0.11-0.18,0.24-0.28,0.38-0.27c0.25,0,0.4,0.28,0.53,0.52c0.03,0.06,0.07,0.12,0.1,0.16c0.37,0.54,0.74,1.09,1.11,1.63
							c1.39,2.04,2.83,4.16,4.18,6.27c0.29,0.45,0.46,0.5,0.53,0.5c0.08-0.01,0.23-0.1,0.43-0.53c0.18-0.38,0.38-0.75,0.57-1.11
							c0.48-0.88,0.97-1.78,1.11-2.81c0.1-0.73,0.5-1.23,1.18-1.47c0.81-0.29,1.61-0.62,2.39-0.94c0.37-0.15,0.74-0.3,1.12-0.45
							c0.03-0.01,0.07-0.03,0.1-0.04c0.24-0.1,0.56-0.24,0.85,0.04c0.29,0.27,0.17,0.6,0.08,0.83c-0.02,0.04-0.03,0.08-0.04,0.12
							c-0.18,0.57-0.37,1.26-0.35,2.01c0.01,0.67,0.21,1.04,0.64,1.26c1.05,0.51,1.49,1.41,1.35,2.73
							c-0.03,0.29-0.04,0.61,0.11,0.78c0.1,0.11,0.27,0.16,0.54,0.16c0.04,0,0.08,0,0.13,0c0.41-0.01,1.03-0.03,1.13,0.72
							c0.08,0.62,0.12,1.33-0.5,1.83c-0.42,0.34-0.85,0.67-1.27,1c-0.2,0.16-0.4,0.31-0.6,0.47c-0.06,0.05-0.13,0.1-0.2,0.14
							c-0.34,0.23-0.48,0.36-0.38,0.62c0.12,0.29,0.43,0.3,0.89,0.29l0.07,0c0.21-0.01,0.43-0.03,0.64-0.06
							c0.75-0.09,1.53-0.18,2.34,0.55c1.07,0.97,1.39,2.21,1.7,3.42c0.1,0.38,0.2,0.77,0.32,1.14c0.1,0.3,0.01,0.49-0.08,0.6
							c-0.35,0.42-1.3,0.31-1.48,0.28c-1.57-0.23-2.95-0.96-4.29-1.68c-0.22-0.11-0.43-0.23-0.65-0.34
							c-1.99-1.04-4.64-2.31-7.57-2.98l-0.06,0.09c0.25,0.2,0.5,0.39,0.75,0.59c0.65,0.51,1.33,1.04,1.98,1.57
							c0.94,0.78,1.88,1.59,2.79,2.38l0.09,0.07c0.16,0.14,0.47,0.41,0.32,0.78c-0.18,0.44-0.61,0.37-0.87,0.33l-0.04-0.01
							c-0.68-0.1-1.23-0.47-1.76-0.82c-0.09-0.06-0.19-0.13-0.29-0.19c-0.64-0.41-1.28-0.87-1.89-1.31
							c-1.8-1.28-3.66-2.61-5.94-3.07c-2.25-0.46-4.15-0.32-5.82,0.41c-1.31,0.57-2.23,1.79-2.57,3.42c-0.39,1.8,0,3.15,1.21,4.24
							c0.46,0.41,0.66,0.8,0.6,1.17c-0.06,0.38-0.39,0.69-1.01,0.94c-0.49,0.21-0.97,0.48-1.43,0.74c-0.98,0.56-1.99,1.13-3.23,1.16
							L375.8,2661.67z M372.03,2653.65c1.22,0,2.34,0.4,3.21,1.15c0.96,0.84,1.52,2.06,1.56,3.41c0.02,0.84-0.19,1.62-0.39,2.37
							c-0.07,0.24-0.13,0.49-0.19,0.74c1.06-0.08,1.94-0.58,2.86-1.11c0.47-0.27,0.96-0.55,1.47-0.76c0.49-0.21,0.76-0.44,0.81-0.69
							c0.04-0.24-0.13-0.53-0.5-0.86c-1.29-1.16-1.73-2.65-1.32-4.55c0.37-1.74,1.35-3.03,2.76-3.65c1.73-0.76,3.7-0.9,6.01-0.43
							c2.35,0.48,4.24,1.82,6.06,3.13c0.61,0.44,1.24,0.89,1.88,1.3c0.1,0.06,0.19,0.13,0.29,0.19c0.52,0.35,1.02,0.67,1.63,0.77
							l0.04,0.01c0.29,0.05,0.44,0.05,0.51-0.13c0.05-0.13-0.04-0.24-0.23-0.4l-0.09-0.07c-0.91-0.79-1.84-1.6-2.78-2.37
							c-0.65-0.53-1.32-1.06-1.97-1.57c-0.29-0.23-0.58-0.45-0.87-0.68l-0.12-0.09l0.35-0.54l0.11,0.02
							c3.01,0.67,5.73,1.97,7.76,3.03c0.22,0.11,0.43,0.23,0.65,0.34c1.31,0.7,2.67,1.42,4.18,1.64c0.46,0.07,1.02,0.03,1.18-0.16
							c0.04-0.04,0.08-0.12,0.02-0.28c-0.12-0.38-0.23-0.78-0.32-1.16c-0.29-1.15-0.6-2.35-1.6-3.25c-0.69-0.63-1.33-0.55-2.07-0.46
							c-0.22,0.03-0.44,0.05-0.67,0.06l-0.07,0c-0.42,0.02-0.99,0.04-1.21-0.5c-0.21-0.53,0.2-0.81,0.5-1.02
							c0.06-0.04,0.13-0.09,0.18-0.13c0.2-0.16,0.4-0.31,0.61-0.47c0.42-0.32,0.85-0.66,1.26-0.99c0.49-0.39,0.46-0.95,0.38-1.53
							c-0.06-0.43-0.33-0.45-0.79-0.43c-0.05,0-0.09,0-0.13,0c-0.37,0.01-0.62-0.08-0.79-0.27c-0.24-0.27-0.23-0.67-0.19-1.04
							c0.13-1.18-0.24-1.94-1.16-2.39c-0.72-0.35-0.82-1.05-0.83-1.55c-0.02-0.79,0.18-1.51,0.37-2.11
							c0.01-0.05,0.03-0.09,0.05-0.14c0.09-0.25,0.12-0.36,0-0.47c-0.12-0.12-0.24-0.08-0.49,0.03c-0.04,0.02-0.08,0.03-0.11,0.05
							c-0.37,0.15-0.74,0.3-1.11,0.45c-0.78,0.32-1.59,0.65-2.41,0.94c-0.58,0.2-0.88,0.59-0.97,1.2c-0.15,1.09-0.65,2.02-1.14,2.92
							c-0.19,0.36-0.39,0.73-0.57,1.1c-0.15,0.32-0.37,0.7-0.71,0.72c-0.27,0.02-0.54-0.19-0.83-0.65
							c-1.35-2.11-2.79-4.22-4.18-6.27c-0.37-0.54-0.74-1.09-1.11-1.63c-0.03-0.05-0.07-0.12-0.11-0.19
							c-0.05-0.1-0.18-0.33-0.25-0.35c0,0-0.03,0.02-0.09,0.11c-0.79,1.33-1.68,0.88-2.4,0.52c-0.24-0.12-0.47-0.24-0.69-0.29
							c-0.44-0.11-0.91-0.27-0.98-0.63c-0.06-0.28,0.14-0.59,0.63-0.96c0.38-0.29,0.61-0.89,0.6-1.55c-0.01-0.54-0.19-1.01-0.45-1.2
							c-1.12-0.79-4.08-0.57-5.19,0.39c-0.43,0.37-0.94,0.76-1.44,0.64c-0.35-0.08-0.64-0.39-0.87-0.95
							c-0.13-0.3-0.4-0.56-0.66-0.81c-0.07-0.07-0.14-0.13-0.2-0.2c-0.04-0.04-0.09-0.1-0.13-0.15c-0.21-0.25-0.3-0.31-0.46-0.22
							c-0.17,0.1-0.16,0.28-0.12,0.57c0.01,0.06,0.01,0.12,0.02,0.17c0.02,0.26,0.06,0.53,0.11,0.79c0.05,0.28,0.09,0.58,0.11,0.87
							c0,0.06,0.02,0.13,0.03,0.21c0.05,0.3,0.12,0.76-0.37,0.95c-0.41,0.16-0.68-0.17-0.85-0.39c-0.04-0.05-0.07-0.09-0.11-0.13
							c-0.22-0.24-0.42-0.49-0.62-0.74c-0.23-0.29-0.47-0.59-0.73-0.86c-0.17-0.18-0.32-0.43-0.46-0.67
							c-0.34-0.58-0.56-0.92-0.99-0.76c-0.68,0.25-0.69,1.23-0.63,2.21c0.08,1.48-0.42,2.72-1.61,4.01l-0.08,0.08
							c-0.88,0.95-1.28,1.38-1.67,1.29c-0.4-0.08-0.59-0.65-1.01-1.9c-0.58-1.72-1.26-3.32-3.16-3.98c-1.01-0.35-2-0.75-3.01-1.16
							c-0.64-0.26-0.97-0.17-1.18,0.33c-0.17,0.41-0.38,0.64-0.66,0.72c-0.39,0.12-0.81-0.12-1.14-0.34
							c-0.47-0.32-0.96-0.64-1.44-0.95c-0.99-0.64-2.01-1.31-2.95-2.06c-0.51-0.41-0.95-0.58-1.33-0.53
							c-0.32,0.04-0.62,0.24-0.94,0.61c1.04,0.17,1.99,0.6,1.91,2.02c0.31,0.06,0.62,0.13,0.92,0.19c0.77,0.16,1.56,0.32,2.34,0.5
							c2,0.44,3.44,1.76,4.84,3.02c0.31,0.29,0.64,0.58,0.96,0.86c2.56,2.2,2.47,2.43,1.39,5.25l-0.15,0.38
							c-0.31,0.81-1.46,1.28-2.3,1.62c-0.13,0.05-0.26,0.1-0.36,0.15c-1.76,0.77-3.61,1.37-5.4,1.95l-0.2,0.06
							c-1.17,0.38-2.36,0.75-3.52,1.1c-1.48,0.45-3.01,0.92-4.49,1.42c-0.38,0.13-0.8,0.26-1.23,0.39c-1.92,0.6-4.1,1.27-5.32,2.55
							c-0.1,0.11-0.15,0.19-0.14,0.25c0.01,0.06,0.09,0.12,0.16,0.18c0.58,0.43,1.16,0.9,1.72,1.35c1.27,1.02,2.59,2.07,4.01,2.87
							c2.66,1.51,5.86,1.27,8.96,1.03c1.1-0.08,2.14-0.16,3.16-0.17c0.45,0,0.87,0.12,1.26,0.24c0.66,0.2,1.23,0.37,1.89-0.05
							c0.81-0.51,1.3-1.35,1.81-2.24c0.56-0.98,1.15-2,2.21-2.52c0.86-0.43,1.79-0.39,2.6,0.11c0.99,0.6,1.65,1.76,1.68,2.96
							c0.02,0.95,0.04,1.48,0.23,1.64c0.19,0.16,0.71,0.06,1.57-0.1c0.46-0.08,0.99-0.18,1.54-0.23
							C371.62,2653.66,371.83,2653.65,372.03,2653.65z"/>
					</g>
					<g>
						<path className="st24" d="M409.08,2626.67c0.52-0.44,1.11-0.75,1.91-0.65c1.12,0.13,2.8,1.59,3.92,1.11
							c1.13-0.48,1.43-2.2,2.74-2.5c1.74-0.39,3.22,1.17,4.53,2.07c1.11,0.76,3.09,0.99,3.69,2.33c-0.11-0.19-2.35,0.57-2.57,0.64
							c-1.05,0.3-2.39,0.41-2.71,1.64c-0.32,1.24,1.41,2.75,2.39,3.24c1.86,1.27,4.66,2.92,6.45,0.64c0.63-0.8,1.1-1.03,2.16-0.78
							c1.45,0.33,4.21,2.19,3.79,3.98c-0.16,0.67-0.81,2.86-1.75,2.84c-0.25-0.01-0.49-0.1-0.72-0.2c-0.8-0.34-1.59-0.67-2.39-1.01
							c-1.94-0.82-1.78,0.05-3.27,1c-0.65,0.41-1.8,0.34-1.68,1.44c0.09,0.87,1.67,2.14-0.1,2.25c-1.14,0.07-2.25-0.26-3.3-0.67
							c-0.46-0.18-2.38-0.62-2.66-0.91c0.93,0.95,1.82,2.04,1.87,3.44c0.01,0.2,0,0.41,0.08,0.59c0.64,1.42,6.97,0.76,8.39,0.99
							c1.83,0.29,5.85,1.36,5.32,3.99c-1.39-0.25-2.66-0.24-4.07-0.45c-2.08-0.3,3.66,2.04,4.24,1.61c3.47-2.61-1.89,3.99-2.19,3.97
							c-2.25-0.13-4.88,0.1-6.98-0.71c-1.01-0.39-1.97-0.87-2.86-1.48c-0.75-0.51-1.47-1.58-2.23-1.97
							c-0.54-0.62-1.12-2.43-2.02-2.55c-0.89-0.11-0.87,0.89-1.65,1.16c-1.05,0.35-2.54-0.3-3.59-0.48
							c-0.64-0.11-5.39-2.6-5.45-2.37c0.21-0.9,1.16-1.7,2.11-1.41c1.5,0.45-0.92-2.24,0.31-2.4c0.73-0.09,1.5,1.1,2.13,0.16
							c0.31-0.47-0.15-1.46-0.39-1.87c-1.73-2.9-5.89-4.66-6.5-8.15c-0.23-1.33-1.16-2.47-2.27-3.24c-1.31-0.91-8.88-2.54-8.53-4.6
							c0.18-1.05,1.24-1.75,2.2-1.98c1.15-0.28,1.86,0.51,2.89,0.43c0.74-0.05,0.85-0.63,1.3-1.19c0.33-0.4,1.07-0.12,1.4,0.15
							c1.21,0.96,2.4,2.55,4.1,3.27C408.65,2628.69,408.04,2627.55,409.08,2626.67z"/>
						<path className="st23" d="M433.14,2657.35C433.14,2657.35,433.14,2657.35,433.14,2657.35c-0.58-0.03-1.18-0.04-1.81-0.05
							c-1.78-0.03-3.63-0.06-5.2-0.66c-1.08-0.42-2.02-0.91-2.88-1.49c-0.34-0.23-0.68-0.58-1-0.92c-0.4-0.41-0.81-0.84-1.22-1.04
							l-0.03-0.02c-0.18-0.21-0.37-0.55-0.56-0.91c-0.39-0.72-0.84-1.54-1.41-1.61c-0.45-0.06-0.64,0.19-0.87,0.51
							c-0.18,0.24-0.38,0.52-0.75,0.65c-0.77,0.26-1.77-0.01-2.65-0.24c-0.35-0.09-0.68-0.18-0.98-0.23
							c-0.31-0.05-1.46-0.6-2.69-1.19c-1.13-0.54-2.53-1.21-2.71-1.19c0.01,0,0.04-0.02,0.05-0.05c0,0,0,0,0,0l-0.16-0.04l0.01-0.04
							c0.13-0.51,0.49-0.99,0.96-1.26c0.4-0.23,0.84-0.29,1.25-0.17c0.26,0.08,0.35,0.04,0.37,0.01c0.1-0.11-0.08-0.53-0.22-0.88
							c-0.21-0.5-0.42-1.02-0.25-1.32c0.07-0.12,0.2-0.19,0.37-0.22c0.3-0.04,0.6,0.12,0.89,0.27c0.52,0.28,0.85,0.42,1.18-0.08
							c0.28-0.42-0.14-1.35-0.4-1.78c-0.69-1.16-1.8-2.15-2.87-3.12c-1.61-1.45-3.27-2.95-3.64-5.06c-0.21-1.2-1-2.33-2.24-3.18
							c-0.4-0.28-1.43-0.64-2.62-1.05c-2.76-0.96-6.19-2.15-5.94-3.63c0.18-1.08,1.27-1.8,2.27-2.05c0.66-0.16,1.18,0.02,1.69,0.19
							c0.4,0.14,0.78,0.27,1.21,0.24c0.5-0.04,0.69-0.33,0.93-0.71c0.09-0.15,0.19-0.3,0.32-0.45c0.37-0.46,1.17-0.14,1.51,0.13
							c0.39,0.31,0.78,0.69,1.19,1.08c0.86,0.83,1.75,1.69,2.88,2.17c0.88,0.37,0.97,0.15,1.18-0.33c0.13-0.3,0.29-0.68,0.7-1.03
							c0.44-0.37,1.06-0.78,1.97-0.67c0.45,0.05,0.97,0.3,1.52,0.57c0.84,0.41,1.71,0.82,2.36,0.55c0.5-0.21,0.83-0.67,1.17-1.16
							c0.41-0.58,0.84-1.18,1.58-1.34c1.43-0.32,2.69,0.64,3.8,1.49c0.28,0.21,0.54,0.41,0.79,0.59c0.38,0.26,0.85,0.45,1.36,0.66
							c0.96,0.39,1.96,0.8,2.36,1.71l-0.15,0.08c0.01,0.01,0.01,0.01,0.02,0.02c-0.09-0.04-0.55,0.03-2.25,0.58
							c-0.12,0.04-0.21,0.07-0.24,0.08c-0.17,0.05-0.35,0.09-0.53,0.14c-0.92,0.23-1.87,0.46-2.12,1.45
							c-0.3,1.16,1.34,2.64,2.34,3.14c2.22,1.51,4.7,2.76,6.35,0.66c0.68-0.86,1.2-1.05,2.24-0.81c1.1,0.25,2.78,1.3,3.5,2.47
							c0.35,0.56,0.47,1.11,0.35,1.62c-0.03,0.12-0.69,2.9-1.81,2.9c-0.01,0-0.01,0-0.02,0c-0.27-0.01-0.52-0.11-0.75-0.21
							l-2.39-1.01c-1.34-0.57-1.62-0.32-2.23,0.23c-0.24,0.22-0.54,0.49-0.96,0.76c-0.19,0.12-0.43,0.21-0.66,0.28
							c-0.57,0.2-1.06,0.36-0.98,1.08c0.03,0.26,0.2,0.57,0.37,0.87c0.24,0.43,0.47,0.83,0.31,1.12c-0.11,0.2-0.39,0.32-0.86,0.35
							c-1.22,0.08-2.37-0.3-3.33-0.68c-0.15-0.06-0.45-0.14-0.8-0.24c-0.58-0.16-1.08-0.31-1.43-0.44c0.81,0.88,1.5,1.88,1.55,3.13
							c0,0.03,0,0.07,0,0.1c0,0.16,0.01,0.32,0.07,0.46c0.43,0.95,3.83,0.92,6.08,0.89c0.98-0.01,1.83-0.02,2.25,0.05
							c0.9,0.14,3.92,0.73,5.03,2.33c0.37,0.53,0.49,1.12,0.36,1.76l-0.02,0.08l-0.08-0.01c-0.77-0.14-1.48-0.2-2.24-0.26
							c-0.59-0.05-1.2-0.1-1.83-0.19c-0.18-0.03-0.27-0.03-0.32-0.03c0.3,0.26,2.18,1.06,3.53,1.4c0.78,0.19,0.95,0.12,0.99,0.09
							c0.89-0.67,1.12-0.67,1.23-0.59c0.13,0.09,0.05,0.3,0.03,0.37C436.22,2653.87,433.5,2657.35,433.14,2657.35z M433.13,2657.18
							L433.13,2657.18L433.13,2657.18z M421.13,2653.04c0.43,0.22,0.85,0.65,1.25,1.07c0.32,0.33,0.65,0.67,0.97,0.9
							c0.84,0.58,1.78,1.06,2.84,1.47c1.54,0.6,3.37,0.62,5.14,0.65c0.63,0.01,1.22,0.02,1.8,0.05c0.28-0.14,2.96-3.31,3.26-4.27
							c0.04-0.11,0.02-0.14,0.02-0.14c-0.01-0.01-0.16-0.06-1.03,0.59c-0.3,0.22-1.58-0.17-2.32-0.42
							c-1.06-0.36-2.08-0.82-2.39-1.05c-0.04-0.03-0.15-0.12-0.11-0.22c0.04-0.1,0.19-0.11,0.54-0.06c0.63,0.09,1.23,0.14,1.82,0.19
							c0.7,0.06,1.42,0.12,2.17,0.25c0.09-0.56-0.02-1.08-0.35-1.55c-1.07-1.55-4.03-2.13-4.92-2.27c-0.41-0.06-1.25-0.06-2.22-0.04
							c-2.43,0.03-5.76,0.06-6.23-0.99c-0.07-0.17-0.08-0.35-0.08-0.52c0-0.03,0-0.07,0-0.1c-0.05-1.37-0.92-2.44-1.84-3.38
							c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0.12-0.12c0,0,0,0,0,0c0.18,0.18,1.17,0.46,1.82,0.65c0.36,0.1,0.66,0.19,0.82,0.25
							c0.95,0.37,2.08,0.74,3.26,0.66c0.4-0.03,0.64-0.11,0.72-0.26c0.12-0.22-0.1-0.6-0.31-0.96c-0.18-0.31-0.36-0.64-0.39-0.93
							c-0.09-0.85,0.54-1.06,1.09-1.26c0.22-0.08,0.45-0.15,0.63-0.27c0.42-0.26,0.69-0.52,0.94-0.74c0.62-0.56,0.96-0.87,2.4-0.26
							l2.39,1.01c0.22,0.09,0.45,0.19,0.69,0.2c0,0,0.01,0,0.01,0c0.83,0,1.47-2,1.65-2.77c0.11-0.47,0-0.97-0.33-1.49
							c-0.7-1.13-2.33-2.15-3.4-2.39c-0.98-0.23-1.43-0.06-2.07,0.75c-1.97,2.5-5.19,0.31-6.56-0.62c-0.97-0.49-2.76-2.02-2.42-3.33
							c0.28-1.08,1.32-1.34,2.24-1.57c0.18-0.04,0.35-0.09,0.52-0.13c0.03-0.01,0.12-0.04,0.23-0.07c1.36-0.44,1.95-0.58,2.21-0.59
							c-0.42-0.75-1.32-1.12-2.19-1.47c-0.51-0.21-1-0.41-1.39-0.68c-0.26-0.18-0.52-0.38-0.8-0.59c-1.08-0.83-2.31-1.77-3.66-1.46
							c-0.68,0.15-1.07,0.7-1.48,1.28c-0.35,0.48-0.7,0.98-1.24,1.21c-0.72,0.31-1.63-0.13-2.5-0.55c-0.54-0.26-1.04-0.5-1.46-0.55
							c-0.66-0.08-1.23,0.11-1.84,0.63l0,0c-0.38,0.32-0.53,0.68-0.65,0.97c-0.23,0.54-0.41,0.83-1.39,0.42
							c-1.16-0.49-2.1-1.4-2.94-2.21c-0.41-0.39-0.79-0.77-1.18-1.07c-0.29-0.23-0.99-0.52-1.28-0.16
							c-0.12,0.15-0.22,0.29-0.31,0.44c-0.25,0.4-0.47,0.74-1.05,0.78c-0.47,0.03-0.88-0.11-1.28-0.24
							c-0.5-0.17-0.98-0.34-1.59-0.19c-0.94,0.23-1.97,0.91-2.14,1.92c-0.23,1.34,3.27,2.56,5.83,3.45c1.2,0.42,2.24,0.78,2.66,1.07
							c1.27,0.88,2.09,2.05,2.31,3.29c0.36,2.06,2,3.54,3.59,4.97c1.08,0.97,2.19,1.98,2.9,3.16c0.03,0.05,0.8,1.35,0.39,1.96
							c-0.43,0.65-0.95,0.37-1.4,0.13c-0.26-0.14-0.54-0.29-0.79-0.26c-0.13,0.02-0.21,0.06-0.25,0.13
							c-0.13,0.23,0.07,0.73,0.26,1.17c0.19,0.46,0.36,0.87,0.19,1.05c-0.1,0.11-0.27,0.12-0.54,0.03
							c-0.46-0.14-0.86,0.01-1.11,0.15c-0.41,0.24-0.73,0.65-0.86,1.09c0.29,0.06,1.02,0.39,2.71,1.2c1.16,0.55,2.36,1.12,2.64,1.17
							c0.3,0.05,0.64,0.14,0.99,0.24c0.86,0.23,1.83,0.49,2.56,0.24c0.32-0.11,0.5-0.35,0.67-0.59c0.23-0.32,0.47-0.64,1.02-0.57
							c0.65,0.08,1.12,0.94,1.53,1.7C420.78,2652.52,420.96,2652.84,421.13,2653.04z"/>
					</g>
					<g>
						<path className="st30" d="M446.35,2658.72c1.59,1.29,3.06,2.72,4.35,4.32c0.27,0.34,0.55,0.78,0.36,1.17
							c-0.16,0.32-0.56,0.42-0.91,0.46c-1.59,0.16-3.23-0.39-4.41-1.47c-1.86-1.7-2.77-4.73-5.24-5.21
							c-1.38-0.27-3.18,0.25-3.93-0.94c-0.68-1.1,0.33-2.43,0.56-3.7c0.24-1.32-7-14.25,1.14-10.79c0.37,0.16,0.73,0.39,1.13,0.37
							c0.54-0.02,0.95-0.49,1.37-0.83c1.01-0.82,2.5-1,3.69-0.46c1.18,0.55,2.01,1.8,2.05,3.1c0.01,0.51-0.08,1.02-0.07,1.54
							c0.04,1.57,1.23,3.04,2.76,3.4c-1.46,0.51-2.92,1.03-4.38,1.54c2.16,2.18,5.61,3.22,6.77,6.07c0.12,0.29,0.2,0.65,0,0.89
							c-0.17,0.2-0.46,0.23-0.73,0.24C449.36,2658.5,447.77,2658.54,446.35,2658.72z"/>
						<path className="st8" d="M449.61,2664.94c-1.48,0-2.95-0.56-4.04-1.56c-0.69-0.63-1.24-1.43-1.78-2.2
							c-0.95-1.36-1.85-2.65-3.34-2.94c-0.41-0.08-0.88-0.09-1.34-0.09c-1.07-0.01-2.17-0.03-2.76-0.96
							c-0.53-0.85-0.18-1.79,0.16-2.69c0.15-0.4,0.3-0.8,0.37-1.19c0.05-0.26-0.41-1.44-0.85-2.59c-1.16-3.02-2.76-7.16-1.32-8.54
							c0.68-0.66,1.87-0.61,3.65,0.15c0.09,0.04,0.17,0.08,0.26,0.12c0.27,0.13,0.51,0.24,0.76,0.23c0.35-0.02,0.64-0.28,0.96-0.55
							c0.09-0.08,0.18-0.15,0.26-0.23c1.09-0.88,2.68-1.08,3.95-0.49c1.27,0.59,2.15,1.92,2.19,3.32c0.01,0.27-0.01,0.54-0.04,0.8
							c-0.02,0.25-0.04,0.49-0.03,0.73c0.03,1.47,1.14,2.83,2.57,3.17l0.83,0.2l-4.8,1.69c0.74,0.68,1.61,1.26,2.46,1.82
							c1.63,1.08,3.33,2.2,4.08,4.05c0.25,0.61,0.11,0.96-0.04,1.15c-0.23,0.28-0.59,0.32-0.9,0.33c-0.3,0.02-0.6,0.03-0.9,0.04
							c-1,0.05-2.03,0.09-3,0.19c1.52,1.28,2.81,2.58,3.93,3.98c0.17,0.21,0.68,0.84,0.38,1.44c-0.17,0.34-0.54,0.54-1.11,0.59
							C449.98,2664.93,449.79,2664.94,449.61,2664.94z M436,2642.22c-0.41,0-0.73,0.11-0.95,0.32c-1.2,1.16,0.45,5.44,1.44,8.01
							c0.62,1.61,0.94,2.47,0.87,2.85c-0.08,0.43-0.24,0.86-0.39,1.27c-0.31,0.83-0.6,1.61-0.2,2.25c0.44,0.7,1.36,0.71,2.34,0.73
							c0.48,0.01,0.97,0.01,1.42,0.1c1.69,0.33,2.69,1.76,3.65,3.15c0.52,0.75,1.07,1.53,1.71,2.12c1.12,1.03,2.7,1.56,4.22,1.41
							c0.39-0.04,0.63-0.15,0.71-0.32c0.1-0.21,0-0.5-0.33-0.9c-1.22-1.51-2.63-2.91-4.31-4.28l-0.45-0.37l0.58-0.07
							c1.15-0.14,2.4-0.2,3.62-0.26c0.3-0.01,0.61-0.03,0.9-0.04c0.21-0.01,0.45-0.03,0.55-0.16c0.13-0.16,0.03-0.47-0.04-0.64
							c-0.69-1.71-2.25-2.74-3.89-3.83c-0.98-0.65-2-1.32-2.83-2.16l-0.28-0.28l4.08-1.44c-1.29-0.61-2.22-1.96-2.26-3.4
							c-0.01-0.27,0.02-0.53,0.04-0.79c0.02-0.26,0.04-0.5,0.03-0.75c-0.03-1.21-0.8-2.37-1.9-2.88c-1.1-0.51-2.48-0.34-3.42,0.42
							c-0.08,0.07-0.17,0.14-0.25,0.21c-0.35,0.31-0.74,0.65-1.27,0.68c-0.37,0.01-0.69-0.13-1-0.28c-0.08-0.04-0.16-0.08-0.24-0.11
							C437.28,2642.41,436.56,2642.22,436,2642.22z"/>
					</g>
					<g>
						<path className="st27" d="M392.04,2623.18c-0.46-0.07-0.56-0.39-0.59-0.8c-0.07-1.19-0.16-2.38-0.25-3.57
							c-0.05-0.7,0.21-1.12,0.96-0.97c1.22,0.25,2.46,0.47,3.65,0.86c1.2,0.39,0.77,1.26,0.38,2.04
							C394.51,2621.05,393.46,2622.42,392.04,2623.18z"/>
					</g>
					<g>
						<path className="st27" d="M366.78,2622.53c-0.1-0.06-0.01-1.15-0.01-1.3c0.01-0.45,0.04-0.91,0.13-1.36
							c0.14-0.66,0.45-1.76,1.29-1.76c1.16,0,1.22,1.78,1.37,2.59c0.05,0.27,0.29,3.12,0.3,3.12
							C368.77,2623.56,367.75,2623.11,366.78,2622.53z"/>
					</g>
					<g>
						<path className="st31" d="M361.91,2620.95c0-0.01-0.01-0.02-0.01-0.03c-0.03-0.1-0.02-0.2-0.01-0.3c0.08-0.77,0.01-2.96,1.24-2.92
							c1.68,0.05,1.37,2.77,1.4,3.87C364.53,2621.52,362.15,2621.54,361.91,2620.95z"/>
					</g>
					<g>
						<path className="st27" d="M358.08,2620.99c-0.27,0.05-0.53,0.08-0.75,0.08l-0.5,0.19c-0.02-0.87-0.12-2.78,0.58-3.41
							c0.21-0.19,0.5-0.34,0.78-0.26c0.15,0.04,0.27,0.15,0.37,0.26c0.77,0.82,0.59,1.77,0.74,2.79c0,0-0.11,0.06-0.11,0.06
							C358.87,2620.8,358.47,2620.92,358.08,2620.99z"/>
					</g>
					<g>
						<path className="st32" d="M344.07,2633.52c0.06,0.12,0.13,0.23,0.19,0.35C344.2,2633.76,344.14,2633.64,344.07,2633.52z"/>
					</g>
					<g>
						<path className="st33" d="M425.47,2626.82c0.08,0.09,0.16,0.18,0.24,0.27C425.63,2627,425.55,2626.91,425.47,2626.82z"/>
					</g>
					<g>
						<path className="st32" d="M397.66,2625.99c-0.1,0.08-0.19,0.16-0.29,0.25C397.47,2626.15,397.57,2626.07,397.66,2625.99z"/>
					</g>
					<g>
						<path className="st32" d="M397.93,2625.76c-0.08,0.06-0.15,0.13-0.23,0.19C397.77,2625.89,397.85,2625.83,397.93,2625.76z"/>
					</g>
					<g>
						<path className="st32" d="M458.28,2656.81c-0.09,0.1-0.18,0.19-0.27,0.29C458.09,2657,458.18,2656.9,458.28,2656.81z"/>
					</g>
					<g>
						<path className="st29" d="M358.91,2620.79c0.09-0.03,0.18-0.07,0.27-0.1C359.09,2620.72,359,2620.76,358.91,2620.79z"/>
					</g>
					<g>
						<path className="st28" d="M354.47,2605.22c2.45-0.17,4.88,0.06,7.32,0.19c0.55,0.03,1.1-0.03,1.65-0.04
							c3.85,0.39,7.71,0.79,11.56,1.17c0.56,0.06,1.12,0.08,1.59,0.45c2.15,2.24,1.98,5.12,2.2,7.91c0.07,0.85-0.08,1.36-1.09,1.23
							c-1.05-0.13-3.17-0.29-3.17-0.29c-0.56-2.14-0.53-4.07-1.19-6.14c-0.15-0.28-0.21-0.67-0.59-0.71
							c-0.41-0.04-0.49,0.38-0.65,0.64c-0.41,0.69-0.34,1.64-0.74,2.24c-0.06,1.21,0.23,2.6,0.16,3.81c0,0-1.39-0.14-1.9-0.13
							c-0.36-1.87-0.15-3.84-0.97-5.62c-0.22-0.49-0.28-1.27-0.89-1.31c-0.66-0.04-0.74,0.74-0.95,1.23
							c-0.73,1.73-0.38,3.59-0.67,5.38c-0.63-0.08-1.25-0.16-1.88-0.24c-0.16-1.89-0.19-3.8-1.04-5.57
							c-0.18-0.37-0.26-0.89-0.76-0.9c-0.49-0.01-0.58,0.51-0.78,0.87c-0.91,1.68-0.39,3.56-0.77,5.32l-2.26-0.29
							c-0.27-2.28-0.4-3.21-1.59-5.49c-1.3,2.17-1.31,3.03-1.59,5.16c-0.32,0.08-0.63,0.16-0.95,0.24
							C354.22,2611.31,354.48,2608.26,354.47,2605.22z"/>
						<path className="st26" d="M377.98,2616.31c-0.09,0-0.19-0.01-0.3-0.02c-1.04-0.13-3.14-0.28-3.16-0.28l-0.12-0.01l-0.03-0.11
							c-0.26-0.98-0.39-1.93-0.52-2.85c-0.16-1.11-0.31-2.16-0.66-3.26c-0.04-0.07-0.07-0.14-0.09-0.2
							c-0.11-0.25-0.17-0.39-0.36-0.4c-0.19-0.02-0.26,0.09-0.38,0.36c-0.03,0.07-0.06,0.14-0.1,0.2c-0.19,0.32-0.27,0.72-0.35,1.1
							c-0.08,0.39-0.16,0.78-0.36,1.1c-0.03,0.58,0.03,1.22,0.08,1.83c0.06,0.65,0.11,1.32,0.08,1.94l-0.01,0.17l-0.17-0.02
							c-0.01,0-1.39-0.14-1.88-0.13l-0.14,0l-0.03-0.14c-0.13-0.67-0.19-1.36-0.24-2.03c-0.1-1.19-0.2-2.43-0.71-3.55
							c-0.06-0.13-0.11-0.28-0.15-0.42c-0.16-0.48-0.27-0.77-0.6-0.8c-0.37-0.02-0.49,0.28-0.66,0.78
							c-0.04,0.12-0.08,0.24-0.12,0.35c-0.45,1.05-0.48,2.2-0.51,3.3c-0.02,0.67-0.04,1.37-0.15,2.04l-0.03,0.16l-2.17-0.28
							l-0.01-0.13c-0.02-0.27-0.04-0.54-0.06-0.8c-0.12-1.58-0.25-3.22-0.96-4.7c-0.04-0.08-0.07-0.16-0.1-0.24
							c-0.14-0.34-0.23-0.56-0.51-0.56c0,0,0,0-0.01,0c-0.26,0-0.36,0.2-0.5,0.52c-0.04,0.09-0.08,0.18-0.12,0.26
							c-0.54,1-0.56,2.05-0.58,3.17c-0.01,0.69-0.03,1.41-0.18,2.1l-0.03,0.15l-2.54-0.32l-0.02-0.13
							c-0.25-2.12-0.36-3.05-1.43-5.17c-1.03,1.79-1.14,2.67-1.35,4.35c-0.02,0.16-0.04,0.32-0.06,0.49l-0.01,0.11l-1.24,0.31
							l-0.02-0.19c-0.21-2.08-0.16-4.19-0.11-6.23c0.02-0.96,0.05-1.95,0.05-2.92l0-0.16l0.15-0.01c1.94-0.14,3.91-0.02,5.8,0.1
							c0.51,0.03,1.02,0.06,1.53,0.09c0.37,0.02,0.75,0,1.11-0.02c0.17-0.01,0.35-0.02,0.52-0.02l0.01,0l2.93,0.3
							c2.88,0.29,5.76,0.59,8.64,0.87l0.13,0.01c0.53,0.05,1.07,0.1,1.54,0.48l0.02,0.01c1.88,1.97,2.03,4.43,2.17,6.82
							c0.02,0.39,0.05,0.8,0.08,1.19c0.03,0.43,0.03,0.92-0.26,1.2C378.53,2616.23,378.29,2616.31,377.98,2616.31z M374.66,2615.68
							c0.46,0.03,2.16,0.16,3.06,0.28c0.36,0.05,0.61,0.01,0.74-0.12c0.15-0.14,0.2-0.43,0.16-0.93c-0.03-0.4-0.06-0.8-0.08-1.2
							c-0.14-2.43-0.28-4.72-2.07-6.6c-0.39-0.31-0.86-0.35-1.36-0.4l-0.13-0.01c-2.88-0.29-5.76-0.58-8.64-0.87l-2.91-0.3
							c-0.17,0.01-0.34,0.01-0.51,0.02c-0.38,0.02-0.76,0.04-1.15,0.02c-0.51-0.03-1.03-0.06-1.54-0.09
							c-1.84-0.11-3.73-0.23-5.61-0.11c0,0.93-0.02,1.87-0.05,2.78c-0.05,1.97-0.1,4,0.09,5.99l0.65-0.16
							c0.02-0.13,0.03-0.26,0.05-0.38c0.22-1.8,0.34-2.71,1.55-4.73l0.15-0.26l0.14,0.26c1.15,2.21,1.32,3.15,1.59,5.42l1.98,0.25
							c0.12-0.62,0.13-1.26,0.14-1.89c0.02-1.11,0.04-2.26,0.62-3.33c0.04-0.07,0.07-0.15,0.11-0.24c0.14-0.32,0.31-0.73,0.82-0.72
							c0.51,0.01,0.68,0.43,0.81,0.77c0.03,0.08,0.06,0.15,0.09,0.22c0.74,1.54,0.87,3.21,0.99,4.82c0.02,0.22,0.03,0.45,0.05,0.67
							l1.59,0.2c0.09-0.61,0.1-1.23,0.12-1.84c0.03-1.14,0.07-2.31,0.54-3.42c0.04-0.1,0.08-0.21,0.11-0.32
							c0.14-0.44,0.34-1.05,1-1.01c0.57,0.04,0.75,0.58,0.89,1.02c0.05,0.14,0.09,0.27,0.14,0.38c0.54,1.18,0.64,2.44,0.74,3.66
							c0.05,0.61,0.1,1.25,0.21,1.86c0.46,0,1.25,0.08,1.61,0.11c0.02-0.55-0.04-1.14-0.09-1.72c-0.06-0.64-0.11-1.31-0.08-1.92
							l0-0.04l0.02-0.04c0.18-0.27,0.26-0.64,0.34-1.03c0.09-0.41,0.17-0.83,0.39-1.2c0.03-0.05,0.06-0.11,0.09-0.17
							c0.11-0.24,0.27-0.59,0.72-0.55c0.39,0.04,0.53,0.35,0.64,0.6c0.03,0.07,0.06,0.13,0.09,0.19l0.01,0.03
							c0.36,1.14,0.52,2.25,0.68,3.33C374.29,2613.86,374.42,2614.76,374.66,2615.68z"/>
					</g>
					<g>
						<path className="st27" d="M366.14,2615.24c0.17-1.04,0.12-2.1,0.2-3.15c0.07-1,0.33-1.89,0.7-2.82c0.1-0.26,0.23-0.54,0.49-0.63
							c0.33-0.11,0.67,0.16,0.83,0.47c1.02,2.04,0.85,4.28,1.27,6.46L366.14,2615.24z"/>
					</g>
					<g>
						<path className="st27" d="M371.16,2615.68c0.07-1.21,0.14-2.59,0.2-3.8c0.05-0.89,0.45-1.81,0.89-2.57
							c0.1-0.17,0.28-0.33,0.48-0.32c0.21,0.02,0.35,0.21,0.46,0.39c0.51,0.92,0.77,2.03,0.95,3.06c0.05,0.29,0.36,3.39,0.39,3.4
							C374.53,2615.84,371.16,2615.68,371.16,2615.68z"/>
					</g>
					<g>
						<path className="st27" d="M360.91,2614.73c0.15,0.01,0.19-2.74,0.2-2.98c0.06-1.01,0.3-1.92,0.8-2.8
							c0.07-0.13,0.15-0.26,0.27-0.34c0.17-0.12,0.42-0.11,0.58,0.02c0.11,0.09,0.18,0.22,0.25,0.35c0.45,0.9,0.75,1.84,0.92,2.83
							c0.05,0.27,0.33,3.19,0.32,3.19C364.26,2615,360.91,2614.73,360.91,2614.73z"/>
					</g>
					<g>
						<path className="st27" d="M355.47,2614.11c0.23-1.82,0.19-3.69,0.98-5.56c0.18-0.41,0.76-0.41,0.93,0
							c0.82,1.97,1.03,3.93,1.26,5.89L355.47,2614.11z"/>
					</g>
					<g>
						<path className="st27" d="M391.94,2613.53c0.04-0.47,0.12-0.92,0.16-1.21c0.18-1.21,1.41-0.92,2.25-0.71
							c0.72,0.17,0.92,1.04,1,1.67c0.06,0.45,0.29,1.89-0.22,2.15c-0.8,0.41-1.76,0.31-2.57,0
							C391.95,2615.2,391.88,2614.33,391.94,2613.53z"/>
					</g>
					<g>
						<path className="st30" d="M409.74,2626.23c-0.41,0.78-1.12,2.3-1.79,2.75c-0.62,0.42-1.83-0.15-2.43-0.68
							c-0.81-0.73-1.45-1.55-1.7-2.63c0.95-0.03,1.85,0.5,2.82,0.23C407.29,2626.83,408.81,2626.02,409.74,2626.23z"/>
					</g>
					<g>
						<path className="st27" d="M401.29,2620.79c0-0.42,0.01-0.84,0-1.26c-0.02-0.66,0.31-0.9,0.95-0.9c0.65,0.01,1.09,0.17,1.07,0.94
							c-0.02,0.84-0.01,1.68-0.01,2.52c0,0.76-0.38,1.07-1.14,1.07c-0.8,0-0.9-0.48-0.88-1.11
							C401.3,2621.62,401.29,2621.21,401.29,2620.79C401.29,2620.79,401.29,2620.79,401.29,2620.79z"/>
					</g>
					<g>
						<path className="st30" d="M417.65,2624.64c-0.21,0.35-1.36,1.43-1.49,1.81c-0.48,1.41-1.57,1.55-2.84,0.83
							c-0.39-0.22-0.72-0.55-1.08-0.83c0.04-0.41,0.19-0.68,0.65-0.74C414.02,2625.58,416.52,2624.79,417.65,2624.64z"/>
					</g>
					<g>
						<path className="st29" d="M411.39,2619.83c0,0,0,0.01,0,0.01c-0.01,0.85-0.34,1.27-1.22,1.28c-1,0.02-0.95-0.6-0.97-1.28
							c-0.03-0.83,0.31-1.14,1.15-1.15C411.07,2618.68,411.39,2619.13,411.39,2619.83z"/>
					</g>
					<g>
						<path className="st32" d="M414.08,2610.09c-0.16-0.99,0.34-1.15,1.13-1.14c1.02,0,0.87,0.68,0.92,1.32c0.06,0.85-0.43,0.98-1.12,1
							C414.07,2611.3,413.93,2610.78,414.08,2610.09z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path d="M804.88,2452.34c-0.1,0.24-0.15,0.47-0.29,0.62c-0.51,0.57-1.22,0.75-1.93,0.9c-1.73,0.35-3.42,0.81-4.77,2.04
						c-0.19,0.18-0.37,0.43-0.44,0.68c-0.25,0.89-0.07,1.74,0.37,2.54c0.16,0.3,0.17,0.62-0.04,0.93c-0.27,0.41-0.52,0.83-0.78,1.25
						c-0.18,0.29-0.16,0.57,0.01,0.85c0.41,0.68,1.13,0.9,1.79,1.22c0.19,0.09,0.46,0.25,0.49,0.41c0.05,0.26-0.01,0.58-0.12,0.83
						c-0.52,1.15-1.55,1.74-2.59,2.33c-1.11,0.63-1.3,1.19-0.71,2.28c0.51,0.94,1.03,1.87,1.56,2.8c0.8,1.42,0.94,2.91,0.52,4.48
						c-0.22,0.84-0.32,1.71-0.15,2.59c0.09,0.45,0.04,0.84-0.19,1.28c-0.43,0.83-1.23,1.03-1.95,1.38
						c-0.93,0.45-1.87,0.87-2.77,1.36c-0.6,0.33-1.14,0.76-1.68,1.18c-0.82,0.62-1.65,1.24-2.43,1.91c-0.67,0.58-1.3,1.21-1.88,1.89
						c-0.76,0.9-1.52,1.83-2.16,2.82c-0.91,1.42-1.9,2.81-2.51,4.41c-0.36,0.93-0.85,1.81-1.2,2.74c-0.39,1.01-0.7,2.04-1.02,3.07
						c-0.13,0.42-0.24,0.85-0.29,1.28c-0.08,0.82-0.13,1.64-0.16,2.47c-0.04,0.93-0.09,1.86-0.04,2.78
						c0.05,0.92,0.21,1.83,0.35,2.74c0.16,1.1,0.29,2.22,0.54,3.31c0.25,1.1,0.61,2.18,0.97,3.25c0.3,0.89,0.64,1.77,1.01,2.63
						c0.35,0.82,0.73,1.64,1.15,2.43c0.55,1.02,1.14,2.03,1.77,3.01c0.47,0.73,0.98,1.45,1.54,2.1c0.94,1.09,1.87,2.22,2.95,3.18
						c1.16,1.04,2.43,1.99,3.75,2.82c1.43,0.9,2.95,1.65,4.46,2.4c0.91,0.45,1.86,0.82,2.82,1.16c0.97,0.34,1.97,0.58,2.97,0.86
						c0.53,0.15,1.07,0.29,1.62,0.39c0.96,0.18,1.92,0.32,2.88,0.47c0.68,0.11,1.36,0.25,2.04,0.29c1.47,0.08,2.94,0.13,4.4,0.15
						c1.03,0.01,2.06-0.01,3.09-0.08c1.16-0.09,2.31-0.27,3.46-0.39c1.92-0.2,3.75-0.77,5.55-1.41c0.67-0.24,1.33-0.51,1.97-0.81
						c0.8-0.38,1.58-0.8,2.35-1.23c0.46-0.26,0.9-0.56,1.35-0.85c0.73-0.47,1.47-0.92,2.16-1.44c0.68-0.51,1.3-1.09,1.94-1.65
						c0.93-0.82,1.89-1.62,2.78-2.49c0.8-0.79,1.55-1.64,2.27-2.51c0.76-0.93,1.48-1.89,2.18-2.87c0.46-0.63,0.91-1.28,1.29-1.96
						c0.89-1.57,1.73-3.16,2.59-4.73c0.73-1.33,1.32-2.72,1.77-4.17c0.38-1.21,0.87-2.38,1.22-3.59c0.32-1.12,0.54-2.28,0.78-3.43
						c0.2-0.96,0.43-1.92,0.55-2.89c0.19-1.49,0.32-2.99,0.43-4.49c0.07-0.91,0.1-1.83,0.08-2.74c-0.03-1-0.11-1.99-0.23-2.98
						c-0.18-1.48-0.39-2.95-0.64-4.42c-0.14-0.85-0.36-1.68-0.59-2.51c-0.2-0.75-0.41-1.5-0.69-2.22c-0.43-1.12-0.91-2.23-1.4-3.33
						c-0.46-1.01-0.91-2.04-1.46-3c-0.7-1.22-1.45-2.41-2.25-3.56c-0.67-0.97-1.39-1.9-2.15-2.8c-0.83-0.98-1.72-1.91-2.61-2.84
						c-0.81-0.86-1.64-1.7-2.5-2.51c-0.66-0.62-1.38-1.18-2.07-1.77c-0.83-0.7-1.62-1.45-2.49-2.09c-1.11-0.82-2.26-1.58-3.43-2.31
						c-2.48-1.53-4.97-3.04-7.75-3.99c-0.65-0.22-1.28-0.51-1.94-0.74c-1.01-0.34-2.01-0.69-3.04-0.97
						c-1.06-0.29-2.14-0.52-3.21-0.75c-0.56-0.12-1.12-0.2-1.69-0.25c-0.86-0.09-1.72-0.15-2.58-0.2c-0.56-0.04-1.11-0.07-1.67-0.06
						c-0.7,0.01-1.41,0.04-2.1,0.11c-0.33,0.03-0.82-0.13-0.87,0.48C805.06,2452.37,804.95,2452.35,804.88,2452.34z"/>
					<path d="M804.88,2452.34c0.06,0.01,0.18,0.03,0.3,0.06c0.05-0.61,0.54-0.44,0.87-0.48c0.7-0.07,1.4-0.1,2.1-0.11
						c0.56-0.01,1.12,0.03,1.67,0.06c0.86,0.06,1.72,0.12,2.58,0.2c0.57,0.06,1.13,0.14,1.69,0.25c1.07,0.23,2.15,0.46,3.21,0.75
						c1.03,0.28,2.03,0.63,3.04,0.97c0.65,0.22,1.28,0.51,1.94,0.74c2.78,0.94,5.28,2.45,7.75,3.99c1.17,0.73,2.32,1.49,3.43,2.31
						c0.87,0.64,1.66,1.39,2.49,2.09c0.69,0.59,1.41,1.15,2.07,1.77c0.86,0.81,1.68,1.66,2.5,2.51c0.89,0.93,1.78,1.86,2.61,2.84
						c0.76,0.9,1.48,1.83,2.15,2.8c0.8,1.16,1.55,2.34,2.25,3.56c0.55,0.96,1,1.99,1.46,3c0.49,1.1,0.97,2.21,1.4,3.33
						c0.28,0.72,0.49,1.47,0.69,2.22c0.22,0.83,0.44,1.67,0.59,2.51c0.25,1.47,0.46,2.94,0.64,4.42c0.12,0.99,0.2,1.99,0.23,2.98
						c0.02,0.91-0.01,1.83-0.08,2.74c-0.11,1.5-0.24,3-0.43,4.49c-0.12,0.97-0.34,1.93-0.55,2.89c-0.24,1.15-0.46,2.3-0.78,3.43
						c-0.35,1.21-0.84,2.38-1.22,3.59c-0.45,1.45-1.05,2.84-1.77,4.17c-0.86,1.58-1.71,3.17-2.59,4.73
						c-0.38,0.68-0.83,1.33-1.29,1.96c-0.71,0.97-1.42,1.94-2.18,2.87c-0.72,0.87-1.47,1.72-2.27,2.51
						c-0.89,0.87-1.84,1.67-2.78,2.49c-0.64,0.56-1.26,1.14-1.94,1.65c-0.69,0.52-1.44,0.97-2.16,1.44
						c-0.44,0.29-0.88,0.59-1.35,0.85c-0.77,0.43-1.56,0.85-2.35,1.23c-0.64,0.3-1.3,0.58-1.97,0.81c-1.81,0.65-3.63,1.21-5.55,1.41
						c-1.16,0.12-2.31,0.3-3.46,0.39c-1.03,0.08-2.06,0.1-3.09,0.08c-1.47-0.02-2.94-0.07-4.4-0.15c-0.68-0.04-1.36-0.18-2.04-0.29
						c-0.96-0.15-1.92-0.29-2.88-0.47c-0.54-0.1-1.08-0.25-1.62-0.39c-0.99-0.27-1.99-0.52-2.97-0.86c-0.96-0.33-1.91-0.7-2.82-1.16
						c-1.51-0.75-3.04-1.5-4.46-2.4c-1.32-0.83-2.59-1.78-3.75-2.82c-1.07-0.96-2-2.08-2.95-3.18c-0.57-0.66-1.08-1.37-1.54-2.1
						c-0.63-0.98-1.21-1.99-1.77-3.01c-0.43-0.79-0.8-1.61-1.15-2.43c-0.37-0.86-0.71-1.74-1.01-2.63
						c-0.36-1.07-0.72-2.15-0.97-3.25c-0.25-1.09-0.37-2.2-0.54-3.31c-0.13-0.91-0.3-1.82-0.35-2.74c-0.05-0.92,0-1.85,0.04-2.78
						c0.03-0.82,0.08-1.65,0.16-2.47c0.04-0.43,0.16-0.86,0.29-1.28c0.32-1.03,0.64-2.06,1.02-3.07c0.36-0.93,0.85-1.81,1.2-2.74
						c0.61-1.6,1.6-2.98,2.51-4.41c0.64-0.99,1.39-1.92,2.16-2.82c0.57-0.68,1.21-1.31,1.88-1.89c0.78-0.68,1.61-1.29,2.43-1.91
						c0.55-0.41,1.09-0.85,1.68-1.18c0.9-0.5,1.84-0.91,2.77-1.36c0.73-0.35,1.53-0.55,1.95-1.38c0.23-0.44,0.27-0.83,0.19-1.28
						c-0.17-0.87-0.07-1.75,0.15-2.59c0.42-1.58,0.28-3.06-0.52-4.48c-0.52-0.93-1.05-1.86-1.56-2.8c-0.59-1.09-0.4-1.66,0.71-2.28
						c1.04-0.58,2.07-1.18,2.59-2.33c0.11-0.25,0.18-0.57,0.12-0.83c-0.03-0.16-0.3-0.32-0.49-0.41c-0.66-0.32-1.38-0.54-1.79-1.22
						c-0.17-0.28-0.19-0.57-0.01-0.85c0.26-0.42,0.5-0.84,0.78-1.25c0.21-0.31,0.2-0.63,0.04-0.93c-0.45-0.81-0.62-1.65-0.37-2.54
						c0.07-0.25,0.24-0.51,0.44-0.68c1.35-1.23,3.04-1.69,4.77-2.04c0.72-0.15,1.42-0.33,1.93-0.9
						C804.73,2452.81,804.78,2452.57,804.88,2452.34z M804.42,2483.47c0.09,0,0.18,0.02,0.26,0.01c0.77-0.07,1.55-0.19,2.32-0.21
						c0.33-0.01,0.5-0.12,0.66-0.36c0.77-1.15,1.03-2.48,1.29-3.79c0.18-0.91,0.23-1.84,0.31-2.77c0.05-0.56,0.05-1.12,0.07-1.67
						c0.03-0.75,0.06-1.51,0.07-2.26c0.02-0.93,0.03-1.86,0.03-2.79c0-0.91-0.05-1.82-0.05-2.74c0-0.91,0.02-1.83,0.03-2.74
						c0.01-0.94,0.02-1.88,0.01-2.81c-0.01-0.67-0.03-1.35-0.13-2.02c-0.15-1-0.41-1.97-0.87-2.9c-0.46-0.93-0.97-1.79-1.74-2.48
						c-0.36-0.32-0.73-0.64-1.05-0.92c-0.38,0.39-0.66,0.72-0.99,1c-0.79,0.67-1.83,0.59-2.75,0.9c-0.71,0.24-1.44,0.46-2.15,0.69
						c0.39,0.7,0.78,1.3,1.08,1.94c0.38,0.81,0.72,1.64,1,2.49c0.24,0.72,0.41,1.48,0.55,2.23c0.14,0.8,0.16,1.61,0.29,2.41
						c0.19,1.19,0.45,2.37,0.63,3.56c0.28,1.84,0.52,3.68,0.78,5.52c0.09,0.67,0.21,1.33,0.27,2c0.09,0.87,0.15,1.74,0.19,2.61
						c0.04,0.84,0.09,1.68,0.07,2.51C804.58,2481.72,804.49,2482.57,804.42,2483.47z M821.88,2455.43c-0.01,0.11-0.06,0.2-0.03,0.25
						c0.5,0.71,1.03,1.4,1.5,2.13c0.29,0.45,0.57,0.92,0.72,1.43c0.22,0.73,0.32,1.5,0.48,2.25c0.21,0.99,0,1.96-0.26,2.88
						c-0.3,1.09-0.76,2.13-1.16,3.19c-0.51,1.36-1.04,2.72-1.55,4.08c-0.31,0.84-0.6,1.7-0.91,2.55c-0.14,0.39-0.35,0.75-0.5,1.13
						c-0.26,0.68-0.47,1.39-0.74,2.06c-0.24,0.59-0.55,1.16-0.81,1.75c-0.46,1.02-0.84,2.08-1.38,3.06
						c-0.53,0.96-1.22,1.83-1.85,2.75c0.45,0.24,0.95,0.53,1.46,0.79c0.07,0.04,0.2-0.01,0.29-0.04c1.17-0.4,2.11-1.19,2.96-2.03
						c0.95-0.94,1.78-2.01,2.63-3.06c0.43-0.53,0.8-1.12,1.15-1.72c1.43-2.49,2.85-4.99,4.27-7.49c0.61-1.07,1.2-2.14,1.82-3.19
						c0.52-0.88,0.89-1.8,0.99-2.83c0.1-1.03-0.04-2.02-0.32-3c-0.19-0.68-0.47-1.34-0.74-1.99c-0.26-0.64-0.58-1.21-1.28-1.54
						c-1.08-0.51-2.11-1.14-3.18-1.69C824.28,2456.55,823.09,2456.01,821.88,2455.43z M808.55,2483.27c0.65,0.03,1.27,0.08,1.9,0.08
						c0.89,0,1.76-0.19,2.24-1.01c0.4-0.68,0.73-1.44,0.92-2.2c0.3-1.14,0.45-2.31,0.67-3.47c0.12-0.64,0.26-1.28,0.36-1.92
						c0.17-1.03,0.32-2.06,0.49-3.09c0.11-0.67,0.25-1.34,0.34-2.02c0.13-0.9,0.21-1.81,0.34-2.72c0.1-0.71,0.26-1.41,0.36-2.11
						c0.17-1.24,0.32-2.49,0.45-3.73c0.06-0.53,0.11-1.08,0.05-1.61c-0.11-1.06-0.57-1.97-1.21-2.85c-0.6-0.82-1.32-1.49-2.06-2.15
						c-0.41-0.36-0.94-0.59-1.32-0.98c-0.4-0.4-0.81-0.66-1.37-0.7c-0.41-0.03-0.81-0.05-1.22-0.06c-0.84-0.01-1.68-0.02-2.51-0.02
						c-0.09,0-0.17,0.04-0.23,0.06c0.33,0.36,0.63,0.69,0.94,1.01c0.91,0.91,1.51,2.01,1.9,3.21c0.26,0.78,0.49,1.58,0.58,2.38
						c0.1,0.9,0.02,1.83,0.02,2.74c0.01,1.11,0.02,2.21,0.03,3.32c0.01,0.78,0.01,1.57,0.01,2.35c0.01,1.08,0.02,2.16,0.02,3.24
						c0,0.97,0.01,1.95-0.02,2.92c-0.02,0.78-0.09,1.56-0.15,2.34c-0.05,0.6-0.1,1.21-0.19,1.81c-0.14,0.93-0.24,1.88-0.47,2.78
						C809.22,2481.7,808.85,2482.46,808.55,2483.27z M812.4,2483.82c0.43,0.12,0.78,0.17,1.08,0.31c0.86,0.38,1.27,0.26,1.84-0.51
						c0.98-1.32,1.66-2.8,2.34-4.29c0.13-0.29,0.29-0.57,0.41-0.87c0.33-0.84,0.63-1.68,0.96-2.52c0.26-0.68,0.54-1.35,0.8-2.02
						c0.4-1.07,0.79-2.14,1.19-3.2c0.28-0.73,0.58-1.46,0.84-2.19c0.26-0.71,0.44-1.46,0.73-2.16c0.52-1.23,1.07-2.45,1.15-3.81
						c0.05-0.83-0.01-1.66-0.3-2.43c-0.3-0.79-0.63-1.59-1.07-2.31c-0.49-0.8-1.08-1.55-1.7-2.27c-0.65-0.75-1.52-1.15-2.51-1.34
						c-1.22-0.24-2.43-0.61-3.64-0.9c-0.43-0.1-0.87-0.13-1.3-0.19c0.24,0.27,0.54,0.42,0.76,0.64c0.72,0.73,1.47,1.45,2.1,2.26
						c0.49,0.62,0.91,1.33,1.2,2.06c0.47,1.2,0.34,2.46,0.14,3.71c-0.11,0.66-0.19,1.32-0.28,1.98c-0.12,0.91-0.22,1.81-0.35,2.72
						c-0.1,0.71-0.25,1.4-0.36,2.11c-0.16,1.02-0.3,2.04-0.47,3.05c-0.11,0.67-0.24,1.34-0.35,2.01c-0.17,1.03-0.33,2.06-0.5,3.09
						c-0.11,0.67-0.19,1.35-0.32,2.02c-0.12,0.67-0.18,1.38-0.45,2C813.85,2481.85,813.48,2483.01,812.4,2483.82z M821.07,2488.54
						c0.44,0.4,0.91,0.78,1.3,1.22c0.33,0.38,0.7,0.33,1.04,0.16c0.79-0.39,1.58-0.79,2.32-1.25c1.08-0.68,2.14-1.39,3.17-2.13
						c1.17-0.84,2.3-1.73,3.43-2.61c0.77-0.6,1.53-1.21,2.29-1.84c1.11-0.91,2.2-1.83,3.3-2.74c0.3-0.25,0.6-0.49,0.88-0.75
						c0.45-0.41,0.91-0.8,1.31-1.24c0.65-0.72,0.91-1.64,1.16-2.54c0.19-0.69,0.25-1.43,0.31-2.15c0.06-0.72,0.06-1.44,0.05-2.17
						c0-0.28-0.03-0.6-0.18-0.83c-0.21-0.34-0.52-0.61-0.79-0.9c-0.57-0.6-1.12-1.21-1.71-1.78c-0.9-0.86-1.83-1.7-2.8-2.6
						c0.14,0.59,0.31,1.09,0.38,1.6c0.12,0.84,0.23,1.7,0.24,2.55c0.02,1.08-0.04,2.18-0.64,3.12c-0.62,0.97-1.32,1.89-2.01,2.82
						c-0.46,0.62-0.95,1.22-1.43,1.82c-1.01,1.26-2.02,2.53-3.05,3.78c-0.95,1.15-1.92,2.29-2.91,3.41
						c-0.65,0.73-1.34,1.42-2.02,2.12c-0.41,0.42-0.81,0.84-1.25,1.23c-0.35,0.31-0.75,0.58-1.13,0.85
						C821.91,2487.98,821.48,2488.26,821.07,2488.54z M842.56,2470.93c-0.06,0.91-0.1,1.7-0.16,2.5c-0.09,1.26-0.49,2.44-1,3.57
						c-0.39,0.86-1.04,1.53-1.8,2.11c-0.63,0.48-1.21,1.01-1.82,1.52c-0.76,0.62-1.52,1.24-2.28,1.86
						c-0.52,0.42-1.02,0.85-1.55,1.26c-0.81,0.62-1.63,1.22-2.44,1.85c-0.61,0.47-1.19,0.99-1.81,1.44
						c-0.95,0.68-1.93,1.32-2.9,1.97c-1.05,0.71-2.11,1.39-3.32,1.77c0.54,0.74,1.05,1.47,1.61,2.18c0.12,0.15,0.38,0.28,0.58,0.27
						c0.32-0.01,0.64-0.1,0.94-0.22c0.88-0.37,1.76-0.75,2.62-1.16c0.89-0.43,1.77-0.89,2.65-1.36c0.81-0.43,1.6-0.9,2.4-1.35
						c0.74-0.42,1.49-0.84,2.22-1.28c0.46-0.27,0.9-0.58,1.36-0.85c1.11-0.65,2.22-1.29,3.33-1.94c0.47-0.27,0.92-0.6,1.41-0.83
						c1.04-0.49,1.93-1.14,2.5-2.15c0.23-0.4,0.41-0.85,0.51-1.3c0.23-0.99,0.44-1.98,0.57-2.99c0.07-0.58,0.12-1.24-0.08-1.75
						c-0.31-0.78-0.84-1.48-1.31-2.2c-0.41-0.63-0.87-1.23-1.32-1.83C843.19,2471.66,842.89,2471.34,842.56,2470.93z
						 M808.74,2517.24c-0.14-0.07-0.19-0.1-0.24-0.12c-0.81-0.28-1.63-0.51-2.42-0.84c-0.53-0.22-0.95-0.15-1.36,0.2
						c-0.45,0.39-0.94,0.74-1.33,1.18c-0.85,0.96-1.69,1.94-2.45,2.97c-1.06,1.42-2.05,2.89-3.06,4.34
						c-0.98,1.42-1.96,2.84-2.93,4.26c-0.5,0.74-0.98,1.48-1.46,2.23c-0.09,0.14-0.17,0.28-0.25,0.42c0.06,0.06,0.09,0.1,0.13,0.12
						c2.3,1.42,4.57,2.91,7.22,3.66c0.77,0.22,1.51,0.53,2.25,0.81c0.26,0.1,0.39,0.03,0.42-0.22c0.04-0.26,0.04-0.53,0.05-0.8
						c0.08-1.37,0.16-2.74,0.25-4.11c0.04-0.55,0.11-1.09,0.19-1.63c0.13-0.92,0.21-1.86,0.44-2.75c0.27-1.01,0.66-2,1.05-2.98
						c0.37-0.93,0.79-1.84,1.24-2.74c0.44-0.89,0.95-1.75,1.43-2.62C808.16,2518.15,808.44,2517.72,808.74,2517.24z M848.71,2512.07
						c-0.1,0-0.18-0.03-0.25-0.01c-1.52,0.51-3.07,0.37-4.6,0.18c-1.2-0.15-2.39-0.45-3.57-0.75c-1.37-0.35-2.73-0.78-4.09-1.17
						c-0.47-0.14-0.94-0.29-1.42-0.41c-1.25-0.31-2.51-0.58-3.76-0.93c-0.94-0.26-1.86-0.59-2.76-0.97c-0.58-0.25-1.1-0.65-1.68-1
						c-0.16,0.48-0.27,0.89-0.45,1.27c-0.26,0.57-0.56,1.12-0.87,1.67c-0.12,0.21-0.11,0.36,0.08,0.51c0.48,0.37,0.93,0.79,1.44,1.1
						c0.94,0.56,1.91,1.07,2.88,1.59c1.62,0.88,3.24,1.76,4.88,2.61c1.16,0.6,2.35,1.16,3.52,1.73c0.83,0.4,1.68,0.77,2.5,1.19
						c1,0.51,2.04,0.79,3.15,0.61c1-0.16,1.9-0.55,2.38-1.52c0.53-1.07,1.05-2.16,1.55-3.25
						C848.04,2513.72,848.36,2512.9,848.71,2512.07z M825.84,2494.11c0.23,0.56,0.47,1.08,0.64,1.63c0.14,0.45,0.38,0.76,0.86,0.77
						c0.41,0.01,0.83-0.02,1.22-0.11c0.87-0.19,1.75-0.39,2.61-0.65c0.8-0.24,1.59-0.52,2.37-0.82c0.77-0.3,1.53-0.65,2.29-0.95
						c0.87-0.35,1.76-0.66,2.63-1.02c0.82-0.34,1.6-0.74,2.43-1.07c1.49-0.59,3.02-1.07,4.46-1.78c0.86-0.42,1.62-0.98,2.2-1.69
						c0.73-0.89,1.31-1.91,1.88-2.92c0.24-0.42,0.42-0.94,0.4-1.41c-0.02-0.51-0.29-1.01-0.49-1.5c-0.39-0.97-0.78-1.94-1.2-2.89
						c-0.31-0.7-0.69-1.37-1.04-2.05c-0.13,0.26-0.16,0.48-0.2,0.7c-0.18,0.96-0.27,1.94-0.57,2.86c-0.24,0.74-0.69,1.44-1.15,2.08
						c-0.31,0.43-0.78,0.76-1.22,1.08c-0.48,0.35-1,0.64-1.51,0.94c-0.49,0.29-0.99,0.57-1.48,0.87c-1.07,0.64-2.13,1.28-3.2,1.92
						c-0.48,0.29-0.95,0.6-1.44,0.88c-0.66,0.38-1.34,0.73-2,1.11c-0.85,0.48-1.68,1-2.54,1.46c-0.79,0.42-1.59,0.81-2.41,1.18
						c-0.81,0.37-1.63,0.71-2.46,1.04C826.58,2493.91,826.2,2494,825.84,2494.11z M830.86,2460.25c-0.02,0.02-0.04,0.04-0.07,0.07
						c0.07,0.15,0.17,0.28,0.21,0.44c0.26,1,0.56,2.01,0.76,3.02c0.21,1.06,0.11,2.14-0.28,3.14c-0.35,0.9-0.81,1.77-1.28,2.62
						c-1.14,2.05-2.27,4.11-3.48,6.11c-1.3,2.15-2.66,4.26-4.07,6.34c-0.52,0.78-1.2,1.47-1.88,2.11c-0.71,0.68-1.51,1.26-2.28,1.87
						c-0.17,0.14-0.38,0.23-0.67,0.4c0.79,0.48,1.48,0.91,2.18,1.32c0.13,0.07,0.32,0.09,0.47,0.05c0.86-0.25,1.54-0.78,2.2-1.36
						c0.39-0.34,0.76-0.72,1.13-1.09c0.81-0.8,1.62-1.59,2.4-2.41c0.36-0.37,0.65-0.81,0.99-1.2c0.47-0.54,0.99-1.04,1.45-1.58
						c0.43-0.51,0.8-1.08,1.22-1.6c0.59-0.72,1.22-1.4,1.8-2.13c0.5-0.62,0.92-1.3,1.41-1.92c0.61-0.78,1.29-1.51,1.89-2.3
						c0.53-0.7,0.7-1.54,0.87-2.39c0.19-0.94,0.1-1.86-0.01-2.78c-0.1-0.84-0.37-1.66-0.6-2.47c-0.12-0.44-0.25-0.91-0.52-1.25
						c-0.35-0.44-0.85-0.77-1.3-1.13c-0.71-0.55-1.43-1.08-2.15-1.61C831.15,2460.4,831,2460.33,830.86,2460.25z M827.41,2500.2
						c0.01,0.56,0.08,1.14,0.01,1.69c-0.08,0.6,0.37,0.69,0.69,0.77c1.13,0.26,2.29,0.46,3.44,0.66c0.88,0.15,1.76,0.28,2.64,0.37
						c1.28,0.13,2.57,0.2,3.86,0.31c1.12,0.09,2.24,0.22,3.35,0.3c1.59,0.13,3.18,0.22,4.77,0.35c1.09,0.09,2.13-0.01,3.04-0.69
						c0.27-0.21,0.53-0.44,0.79-0.66c0.72-0.62,1.27-1.35,1.38-2.34c0.03-0.28,0.08-0.56,0.1-0.84c0.06-0.82,0.13-1.65,0.17-2.48
						c0.03-0.72,0.04-1.44,0.04-2.16c0-0.55-0.04-1.1-0.06-1.6c-0.62,0.83-1.24,1.68-1.88,2.51c-0.47,0.61-1.11,0.98-1.86,1.16
						c-0.83,0.2-1.66,0.38-2.49,0.56c-0.34,0.07-0.69,0.12-1.04,0.19c-0.72,0.14-1.45,0.27-2.17,0.41c-1.02,0.2-2.05,0.42-3.07,0.61
						c-0.75,0.14-1.5,0.25-2.25,0.37c-0.86,0.13-1.71,0.28-2.57,0.39c-0.8,0.1-1.61,0.17-2.42,0.22c-0.89,0.05-1.78,0.09-2.67,0.08
						C828.61,2500.38,828.03,2500.27,827.41,2500.2z M826.99,2497.32c0.08,0.48,0.14,0.93,0.23,1.37c0.04,0.21,0.1,0.51,0.24,0.57
						c0.35,0.15,0.75,0.24,1.14,0.26c0.76,0.04,1.52,0.05,2.28,0.01c1.02-0.06,2.05-0.16,3.07-0.27c0.67-0.07,1.33-0.19,2-0.3
						c0.99-0.15,1.98-0.3,2.97-0.46c0.84-0.14,1.67-0.31,2.51-0.47c0.69-0.13,1.39-0.26,2.08-0.4c1.12-0.23,2.24-0.48,3.37-0.68
						c0.93-0.17,1.79-0.47,2.39-1.22c0.47-0.58,0.89-1.21,1.25-1.87c0.33-0.61,0.6-1.26,0.82-1.92c0.26-0.74-0.01-1.49-0.13-2.23
						c-0.14-0.9-0.31-1.8-0.5-2.69c-0.09-0.45-0.25-0.89-0.37-1.34c-0.04,0-0.08,0-0.12,0c-0.07,0.23-0.09,0.5-0.22,0.7
						c-0.49,0.74-0.85,1.55-1.5,2.22c-0.58,0.6-1.15,1.19-1.83,1.7c-0.83,0.62-1.76,0.99-2.71,1.35c-0.97,0.38-1.95,0.74-2.93,1.13
						c-0.81,0.32-1.6,0.67-2.4,1c-0.92,0.38-1.85,0.76-2.78,1.14c-0.74,0.3-1.46,0.62-2.21,0.88c-0.8,0.29-1.62,0.52-2.44,0.79
						C829.85,2497.05,828.48,2497.45,826.99,2497.32z M826.9,2506.15c0.35,0.28,0.68,0.63,1.09,0.83c0.69,0.33,1.42,0.56,2.14,0.82
						c0.64,0.22,1.28,0.42,1.93,0.62c0.43,0.13,0.86,0.26,1.29,0.36c1.12,0.27,2.25,0.5,3.37,0.79c1.22,0.31,2.42,0.7,3.64,1.01
						c1.16,0.29,2.34,0.5,3.52,0.76c1.17,0.26,2.35,0.3,3.52,0.11c0.91-0.15,1.82-0.29,2.03-1.53c0.21-1.23,0.66-2.41,0.99-3.62
						c0.2-0.76,0.37-1.54,0.53-2.31c0.02-0.1-0.02-0.22-0.03-0.3c-0.35,0.29-0.66,0.57-0.99,0.83c-0.86,0.68-1.85,1.04-2.93,1.02
						c-1.23-0.03-2.46-0.17-3.69-0.27c-0.77-0.06-1.54-0.14-2.31-0.2c-1.39-0.11-2.79-0.21-4.18-0.32
						c-0.53-0.04-1.06-0.11-1.59-0.16c-0.85-0.08-1.71-0.14-2.55-0.25c-0.73-0.09-1.46-0.23-2.19-0.36
						c-0.72-0.12-1.45-0.24-2.17-0.39c-0.32-0.06-0.62-0.18-0.94-0.28C827.21,2504.29,827.05,2505.21,826.9,2506.15z
						 M821.32,2514.87c-0.67,0.44-1.34,0.88-2,1.35c-0.08,0.06-0.13,0.24-0.11,0.34c0.19,0.96,0.34,1.93,0.6,2.86
						c0.55,1.99,1.15,3.97,1.75,5.94c0.42,1.38,0.85,2.75,1.29,4.11c0.3,0.93,0.6,1.85,0.94,2.76c0.32,0.87,0.64,1.75,1.23,2.5
						c0.26,0.32,0.46,0.69,0.75,0.98c0.21,0.21,0.58,0.52,0.76,0.45c0.94-0.34,1.84-0.78,2.74-1.21c0.63-0.3,1.24-0.64,1.83-1
						c0.92-0.55,1.82-1.13,2.8-1.75c-0.19-0.1-0.28-0.14-0.37-0.19c-0.74-0.41-1.57-0.72-2.21-1.25c-0.65-0.54-1.14-1.29-1.64-1.98
						c-0.62-0.85-1.2-1.73-1.77-2.62c-0.69-1.09-1.35-2.2-2.03-3.29c-0.64-1.03-1.28-2.05-1.92-3.07
						C822.98,2518.21,822.04,2516.58,821.32,2514.87z M809.16,2537.68c0.14,0.01,0.23,0.01,0.31,0.02c0.7,0.09,1.39,0.22,2.09,0.26
						c1.01,0.06,2.03,0.06,3.05,0.06c0.81,0,1.63-0.02,2.44-0.04c0.29-0.01,0.38-0.17,0.31-0.45c-0.07-0.26-0.12-0.51-0.18-0.77
						c-0.27-1.29-0.54-2.59-0.79-3.89c-0.26-1.36-0.54-2.72-0.74-4.08c-0.14-0.93-0.16-1.88-0.25-2.81
						c-0.11-1.17-0.32-2.34-0.36-3.51c-0.04-1.54,0.04-3.08,0.07-4.61c-0.54,0.04-1.18,0.1-1.83,0.12
						c-0.28,0.01-0.41,0.14-0.56,0.36c-0.96,1.37-1.67,2.85-2.04,4.48c-0.21,0.92-0.33,1.87-0.44,2.81
						c-0.1,0.81-0.12,1.63-0.19,2.45c-0.09,0.89-0.22,1.77-0.3,2.66c-0.1,1.08-0.15,2.17-0.24,3.26
						C809.41,2535.21,809.28,2536.44,809.16,2537.68z M797.77,2481.64c1.22,0.74,2.37,1.41,3.49,2.14c0.36,0.24,0.69,0.13,1.04,0.11
						c0.22-0.01,0.43-0.16,0.65-0.15c0.53,0.01,0.63-0.36,0.67-0.74c0.06-0.56,0.08-1.14,0.09-1.71c0.01-1.13,0.06-2.27-0.03-3.4
						c-0.13-1.64-0.36-3.26-0.57-4.89c-0.2-1.53-0.43-3.06-0.66-4.59c-0.27-1.77-0.56-3.54-0.84-5.3c-0.14-0.87-0.23-1.76-0.47-2.61
						c-0.4-1.42-1.09-2.73-1.83-4c-0.23-0.4-0.46-0.4-0.75-0.04c-0.54,0.67-0.41,1.34-0.09,2.04c0.55,1.17,0.44,1.76-0.47,2.66
						c-0.36,0.35-0.32,0.65,0.13,0.91c0.42,0.24,0.85,0.5,1.3,0.68c0.96,0.39,0.99,1.51,0.56,2.17c-0.33,0.51-0.67,1.04-1.12,1.45
						c-0.5,0.46-1.1,0.83-1.71,1.16c-0.62,0.33-0.84,0.8-0.5,1.41c0.45,0.81,0.98,1.58,1.39,2.41c0.35,0.72,0.63,1.49,0.86,2.26
						c0.36,1.18-0.04,2.34-0.2,3.51c-0.08,0.59-0.09,1.2-0.07,1.8c0.02,0.61,0.18,1.22-0.2,1.77
						C798.25,2480.97,798.05,2481.25,797.77,2481.64z M822.92,2513.34c-0.91,0.62-1.01,0.74-0.67,1.54
						c0.41,0.96,0.92,1.88,1.43,2.79c0.38,0.69,0.81,1.35,1.22,2.01c0.59,0.96,1.18,1.91,1.78,2.86c0.31,0.49,0.63,0.97,0.94,1.46
						c0.46,0.72,0.91,1.44,1.37,2.15c0.46,0.72,0.92,1.45,1.41,2.15c0.41,0.58,0.8,1.21,1.34,1.65c0.68,0.56,1.48,0.96,2.26,1.38
						c0.22,0.12,0.57,0.16,0.79,0.07c0.35-0.15,0.68-0.42,0.96-0.68c1-0.93,1.98-1.86,2.96-2.81c0.47-0.46,0.89-0.95,1.41-1.5
						c-0.42-0.09-0.73-0.11-0.99-0.22c-0.58-0.25-1.17-0.48-1.68-0.83c-0.92-0.62-1.78-1.33-2.66-1.99
						c-0.84-0.63-1.67-1.26-2.52-1.87c-0.56-0.4-1.14-0.77-1.71-1.16c-0.39-0.27-0.78-0.53-1.15-0.83
						c-0.76-0.61-1.51-1.25-2.25-1.88c-0.76-0.64-1.57-1.25-2.26-1.95C824.18,2514.96,823.58,2514.14,822.92,2513.34z
						 M782.1,2497.34c0.21,0.07,0.29,0.12,0.38,0.13c0.6,0.05,1.21,0.05,1.8,0.16c1.29,0.24,2.57,0.51,3.84,0.83
						c0.9,0.22,1.77,0.54,2.66,0.79c1.21,0.35,2.43,0.67,3.64,1.02c0.65,0.19,1.29,0.43,1.93,0.66c0.88,0.32,1.75,0.64,2.62,0.97
						c0.21,0.08,0.41,0.17,0.58,0.24c0.55-0.91,1.07-1.77,1.58-2.61c-0.57-0.48-1.08-0.95-1.63-1.37c-0.82-0.63-1.64-1.27-2.5-1.83
						c-2.44-1.6-4.84-3.26-7.54-4.42c-1-0.43-1.97-0.95-2.94-1.45c-0.25-0.13-0.38-0.09-0.52,0.13c-0.7,1.1-1.44,2.17-2.1,3.3
						C783.26,2495.01,782.71,2496.17,782.1,2497.34z M804.51,2515.46c-0.55-0.39-1.12-0.81-1.71-1.2c-0.16-0.11-0.41-0.2-0.59-0.15
						c-0.5,0.12-1.03,0.23-1.45,0.5c-0.99,0.65-1.92,1.39-2.86,2.11c-0.6,0.46-1.17,0.96-1.76,1.43c-1.17,0.95-2.35,1.88-3.51,2.83
						c-0.39,0.32-0.71,0.73-1.12,1.04c-0.83,0.62-1.49,1.38-1.92,2.3c-0.42,0.9-0.72,1.85-1.09,2.77c-0.16,0.39-0.12,0.65,0.23,0.93
						c0.41,0.32,0.77,0.71,1.12,1.1c0.71,0.79,1.49,1.48,2.37,2.07c0.25,0.17,0.4,0.18,0.58-0.11c0.59-0.95,1.2-1.89,1.83-2.81
						c0.72-1.06,1.46-2.1,2.2-3.15c0.93-1.33,1.83-2.66,2.78-3.98c0.83-1.15,1.69-2.26,2.54-3.39c0.12-0.16,0.22-0.34,0.36-0.47
						C803.17,2516.69,803.83,2516.08,804.51,2515.46z M818.28,2516.84c-0.58,0.22-1.2,0.45-1.81,0.7c-0.1,0.04-0.24,0.12-0.27,0.22
						c-0.37,1.16-0.4,2.36-0.38,3.55c0.02,1.31,0.15,2.63,0.28,3.93c0.12,1.24,0.3,2.48,0.47,3.71c0.16,1.14,0.31,2.28,0.53,3.41
						c0.34,1.71,0.75,3.41,1.11,5.12c0.07,0.32,0.26,0.39,0.51,0.38c0.4-0.01,0.8-0.03,1.18-0.1c1.23-0.21,2.47-0.42,3.69-0.68
						c0.6-0.12,1.19-0.34,1.83-0.52c-0.34-0.39-0.66-0.69-0.89-1.05c-0.43-0.67-0.91-1.34-1.19-2.07c-0.62-1.65-1.13-3.35-1.69-5.02
						c-0.27-0.82-0.57-1.64-0.82-2.47c-0.45-1.46-0.88-2.93-1.29-4.41C819.1,2519.97,818.7,2518.4,818.28,2516.84z M824.65,2511.05
						c-0.34,0.47-0.65,0.86-0.91,1.29c-0.08,0.12-0.09,0.38-0.01,0.49c0.54,0.72,1.07,1.45,1.67,2.11c0.58,0.64,1.23,1.22,1.89,1.79
						c0.67,0.59,1.38,1.13,2.09,1.68c0.82,0.63,1.65,1.24,2.48,1.86c1.03,0.76,2.05,1.53,3.09,2.28c1.18,0.85,2.34,1.75,3.59,2.5
						c0.56,0.34,1.3,0.38,1.97,0.52c0.15,0.03,0.38-0.1,0.5-0.23c0.41-0.46,0.81-0.94,1.18-1.43c0.4-0.52,0.79-1.06,1.14-1.61
						c0.49-0.76,0.94-1.54,1.44-2.36c-0.94,0.3-1.86,0.4-2.75,0.15c-1.11-0.32-2.19-0.76-3.25-1.23c-1.21-0.53-2.39-1.15-3.57-1.74
						c-1.13-0.57-2.28-1.11-3.38-1.73c-2.04-1.16-4.05-2.37-6.06-3.56C825.38,2511.6,825.03,2511.32,824.65,2511.05z
						 M801.81,2513.22c-0.3-0.45-0.62-0.94-0.97-1.41c-0.06-0.08-0.24-0.15-0.33-0.12c-0.88,0.24-1.76,0.47-2.62,0.76
						c-0.95,0.33-1.87,0.71-2.8,1.08c-1.05,0.42-2.1,0.84-3.16,1.25c-0.9,0.35-1.81,0.69-2.71,1.05c-0.59,0.24-1.24,0.4-1.71,0.79
						c-0.73,0.59-1.34,1.32-1.97,2.02c-0.44,0.49-0.85,1.03-1.24,1.56c-0.19,0.26-0.52,0.48-0.28,0.9c0.5,0.87,0.96,1.76,1.47,2.62
						c0.35,0.6,0.75,1.17,1.15,1.74c0.31,0.44,0.67,0.84,1.08,1.35c0.11-0.45,0.13-0.78,0.27-1.05c0.47-0.94,0.95-1.88,1.49-2.79
						c0.28-0.47,0.62-0.92,1.02-1.29c0.92-0.84,1.89-1.63,2.85-2.44c0.59-0.5,1.18-1.02,1.79-1.5c1.12-0.88,2.26-1.73,3.38-2.61
						c0.34-0.27,0.63-0.61,1.01-0.82C800.28,2513.92,801.07,2513.58,801.81,2513.22z M781.76,2498.26
						c-0.05,0.23-0.06,0.42-0.13,0.58c-0.36,0.78-0.56,1.59-0.76,2.42c-0.27,1.12-0.41,2.24-0.46,3.39c-0.04,0.8-0.06,0.8,0.65,0.6
						c0.79-0.23,1.57-0.49,2.37-0.66c0.73-0.15,1.48-0.23,2.23-0.28c0.7-0.04,1.41,0.03,2.12,0.03c0.86,0,1.72-0.03,2.58-0.02
						c1.14,0.02,2.27,0.05,3.41,0.11c1.62,0.08,3.24,0.19,4.86,0.26c0.11,0.01,0.29-0.14,0.33-0.26c0.12-0.37,0.21-0.75,0.27-1.13
						c0.03-0.21,0.02-0.44-0.27-0.54c-0.75-0.26-1.49-0.57-2.24-0.82c-1.31-0.42-2.62-0.83-3.94-1.21
						c-1.07-0.31-2.15-0.58-3.22-0.89c-0.89-0.25-1.77-0.57-2.67-0.79c-1.03-0.25-2.07-0.46-3.11-0.63
						C783.11,2498.31,782.43,2498.31,781.76,2498.26z M783.46,2519.92c0.59-0.73,1.13-1.42,1.7-2.09c0.53-0.62,1.09-1.21,1.63-1.81
						c0.04-0.04,0.09-0.07,0.14-0.1c0.65-0.3,1.28-0.62,1.95-0.89c1.08-0.43,2.17-0.82,3.25-1.24c1.51-0.58,3-1.2,4.52-1.77
						c1.05-0.39,2.12-0.71,3.17-1.07c0.26-0.09,0.4-0.21,0.22-0.54c-0.22-0.41-0.36-0.86-0.55-1.29c-0.11-0.26-0.23-0.49-0.59-0.46
						c-0.47,0.04-0.94,0.01-1.41,0.07c-1.03,0.14-2.06,0.33-3.08,0.5c-1.09,0.18-2.18,0.33-3.27,0.54
						c-1.48,0.29-2.95,0.62-4.42,0.94c-1.1,0.24-2.09,0.73-2.97,1.42c-0.56,0.44-1.1,0.9-1.68,1.32c-0.75,0.55-0.78,0.64-0.49,1.44
						c0.45,1.25,0.86,2.51,1.31,3.76C783.05,2519.1,783.28,2519.51,783.46,2519.92z M801.65,2498.73c0.32-0.31,0.63-0.6,0.93-0.91
						c0.27-0.28,0.6-0.55,0.27-1.02c-0.75-1.06-1.45-2.15-2.23-3.18c-0.63-0.83-1.32-1.61-2.02-2.37c-0.86-0.93-1.76-1.83-2.66-2.73
						c-0.72-0.72-1.47-1.42-2.21-2.12c-0.38-0.36-0.76-0.73-1.16-1.07c-0.22-0.2-0.47-0.36-0.69-0.52
						c-1.94,1.36-3.59,2.94-5.06,4.77c0.06,0.05,0.13,0.14,0.22,0.18c0.48,0.22,0.98,0.4,1.45,0.63c1.16,0.57,2.34,1.11,3.45,1.77
						c1.76,1.06,3.48,2.2,5.2,3.34c0.93,0.62,1.82,1.32,2.73,1.97C800.45,2497.9,801.05,2498.32,801.65,2498.73z M799.05,2507.75
						c-0.06-0.57-0.2-1.2-0.17-1.82c0.02-0.4-0.18-0.42-0.41-0.43c-1.06-0.06-2.11-0.1-3.17-0.14c-1.47-0.05-2.94-0.08-4.41-0.12
						c-1.55-0.04-3.1-0.08-4.64-0.11c-0.47-0.01-0.95-0.04-1.41,0.02c-0.72,0.11-1.45,0.25-2.15,0.45
						c-0.69,0.19-1.37,0.45-2.03,0.73c-0.15,0.06-0.26,0.35-0.28,0.54c-0.04,0.3,0,0.62,0.03,0.92c0.1,1,0.19,1.99,0.32,2.99
						c0.07,0.55,0.19,1.1,0.29,1.65c0.04,0.2,0.11,0.4,0.16,0.59c0.82-0.61,1.56-1.17,2.31-1.71c0.73-0.53,1.51-1,2.39-1.23
						c1.28-0.34,2.57-0.63,3.87-0.91c1.06-0.23,2.13-0.45,3.2-0.64c0.88-0.15,1.77-0.26,2.66-0.37
						C796.74,2508.02,797.9,2507.89,799.05,2507.75z M811.93,2517.9c-0.58-0.14-1.11-0.28-1.66-0.38c-0.73-0.13-0.76-0.13-1.13,0.55
						c-0.4,0.72-0.74,1.47-1.14,2.18c-0.9,1.61-1.7,3.25-2.27,5.01c-0.23,0.7-0.58,1.37-0.74,2.09c-0.26,1.17-0.42,2.37-0.59,3.56
						c-0.11,0.76-0.16,1.54-0.2,2.31c-0.06,1.01-0.07,2.03-0.13,3.05c-0.02,0.3,0.02,0.49,0.35,0.56c0.83,0.18,1.65,0.41,2.48,0.58
						c0.48,0.1,0.97,0.11,1.43,0.16c0.05-0.44,0.09-0.85,0.14-1.26c0.06-0.59,0.12-1.18,0.18-1.77c0.07-0.85,0.14-1.7,0.21-2.55
						c0.05-0.62,0.1-1.25,0.15-1.87c0.08-1,0.15-2,0.25-2.99c0.06-0.62,0.22-1.23,0.23-1.85c0.03-1.4,0.32-2.75,0.79-4.04
						c0.39-1.06,0.99-2.05,1.5-3.07C811.81,2518.07,811.87,2518,811.93,2517.9z M803.79,2496.67c0.48-0.29,0.91-0.53,1.32-0.8
						c0.07-0.05,0.12-0.19,0.12-0.28c0.01-0.94-0.24-1.83-0.49-2.73c-0.36-1.33-0.83-2.6-1.42-3.84c-0.6-1.26-1.14-2.55-1.72-3.82
						c-0.09-0.19-0.2-0.42-0.37-0.53c-1.25-0.82-2.53-1.61-3.79-2.43c-0.25-0.16-0.46-0.14-0.72-0.02c-0.6,0.28-1.22,0.49-1.81,0.79
						c-0.75,0.38-1.47,0.81-2.22,1.22c0.17,0.24,0.26,0.41,0.39,0.52c0.6,0.54,1.24,1.05,1.83,1.61c0.87,0.84,1.72,1.7,2.56,2.56
						c0.58,0.59,1.16,1.19,1.72,1.81c0.82,0.91,1.63,1.82,2.41,2.77c0.43,0.52,0.78,1.1,1.16,1.65
						C803.12,2495.66,803.46,2496.18,803.79,2496.67z M812.6,2484.72c-0.26-0.06-0.53-0.13-0.8-0.19c-0.44-0.1-0.87-0.23-1.31-0.27
						c-0.87-0.08-1.74-0.08-2.61-0.18c-0.42-0.04-0.4,0.17-0.36,0.42c0.07,0.41,0.2,0.8,0.27,1.21c0.14,0.75,0.27,1.5,0.38,2.26
						c0.13,0.89,0.27,1.78,0.35,2.67c0.11,1.36,0.18,2.73,0.26,4.13c0.49,0.03,1,0.07,1.51,0.08c0.06,0,0.16-0.1,0.19-0.17
						c0.33-0.75,0.71-1.48,0.94-2.25c0.28-0.9,0.44-1.84,0.62-2.77c0.13-0.65,0.25-1.31,0.32-1.97
						C812.47,2486.73,812.52,2485.77,812.6,2484.72z M802.31,2484.67c1.62,3.51,3.37,6.87,3.82,10.67c0.53-0.14,1.07-0.28,1.59-0.45
						c0.08-0.02,0.17-0.18,0.17-0.28c0-0.62,0.01-1.24-0.04-1.85c-0.1-1.21-0.19-2.43-0.37-3.63c-0.24-1.67-0.56-3.32-0.86-5.05
						C805.18,2484.28,803.78,2484.47,802.31,2484.67z M813.21,2495.78c0.11-0.16,0.24-0.3,0.32-0.46c0.44-0.85,0.87-1.7,1.29-2.56
						c0.48-1,0.94-2,1.4-3.01c0.32-0.72,0.62-1.45,0.92-2.17c0.09-0.21,0.18-0.41,0.3-0.69c-1.34-0.64-2.65-1.26-3.99-1.89
						c-0.04,0.17-0.06,0.23-0.07,0.29c-0.09,0.92-0.15,1.83-0.27,2.75c-0.08,0.64-0.26,1.28-0.32,1.92c-0.1,1.18-0.47,2.29-0.82,3.4
						c-0.18,0.57-0.49,1.1-0.7,1.57C811.92,2495.22,812.54,2495.49,813.21,2495.78z M818.13,2487.34c-0.27,0.76-0.48,1.42-0.74,2.05
						c-0.39,0.94-0.8,1.87-1.25,2.78c-0.68,1.36-1.4,2.7-2.11,4.05c-0.01,0.01-0.01,0.03-0.02,0.1c0.29,0.21,0.59,0.44,0.91,0.65
						c0.06,0.04,0.19,0.02,0.24-0.02c0.63-0.49,1.32-0.91,1.86-1.49c0.97-1.05,1.87-2.18,2.76-3.3c0.46-0.59,0.84-1.24,1.26-1.86
						c0.09-0.13,0.17-0.27,0.27-0.45C820.23,2489,819.2,2488.19,818.13,2487.34z M807.65,2508.1c0.23-0.97,0.44-1.9,0.66-2.85
						c0.39,0.25,0.42-0.14,0.58-0.28c0.54,0.05,1.07,0.1,1.59,0.02c0.39-0.06,0.84-0.2,1.13-0.45c0.63-0.54,0.67-2.04,0.15-2.7
						c-0.41-0.52-0.86-0.94-1.57-0.98c-0.81-0.05-1.57,0.09-2.2,0.58c-0.8,0.62-1.18,1.54-1.45,2.49c-0.02,0.06-0.03,0.12-0.02,0.17
						c0.05,0.64,0.05,1.29,0.15,1.92C806.78,2506.79,806.97,2507.54,807.65,2508.1z M815.66,2497.69c0.26,0.33,0.48,0.61,0.71,0.88
						c0.19,0.23,0.38,0.28,0.69,0.14c1.18-0.51,2.14-1.32,3.09-2.15c0.7-0.61,1.34-1.28,1.98-1.95c0.51-0.53,0.98-1.1,1.47-1.66
						c0.14-0.16,0.28-0.29,0.05-0.54c-0.44-0.46-0.82-0.97-1.23-1.46c-0.16-0.19-0.33-0.36-0.54-0.57
						C820.16,2493.16,818.32,2495.74,815.66,2497.69z M817.37,2500.18c0.19,0.02,0.39,0.07,0.58,0.04c1.62-0.24,3.12-0.85,4.62-1.47
						c1.03-0.43,2.01-0.99,3.02-1.48c0.51-0.25,0.53-0.29,0.27-0.78c-0.04-0.07-0.09-0.13-0.1-0.2c-0.21-1.07-0.85-1.93-1.35-2.87
						c-0.56,0.57-1.12,1.06-1.58,1.63c-0.87,1.06-1.89,1.96-2.93,2.84c-0.75,0.63-1.52,1.2-2.44,1.56
						c-0.23,0.09-0.47,0.24-0.15,0.53C817.35,2500.02,817.35,2500.11,817.37,2500.18z M826.28,2497.98
						c-0.22,0.07-0.37,0.09-0.51,0.15c-1.24,0.56-2.47,1.14-3.71,1.7c-1.31,0.59-2.65,1.04-4.08,1.22
						c-0.07,0.01-0.19,0.16-0.19,0.23c0.03,0.5,0.42,0.83,0.94,0.85c1.27,0.06,2.54,0.02,3.78-0.25c1.29-0.28,2.56-0.62,3.84-0.94
						c0.09-0.02,0.23-0.13,0.23-0.18C826.5,2499.84,826.39,2498.93,826.28,2497.98z M826.62,2501.84c-2.86,0.68-5.61,1.5-8.53,1.01
						c0,0.37-0.01,0.76-0.01,1.17c0.06,0,0.13-0.01,0.18,0c1.37,0.38,2.78,0.57,4.2,0.64c1.07,0.05,2.15,0.16,3.22,0.23
						c0.4,0.03,0.66-0.11,0.69-0.58C826.43,2503.5,826.53,2502.71,826.62,2501.84z M813.05,2510.44c0.2,0.77,0.4,1.54,0.61,2.3
						c0.35,1.28,0.75,2.54,1.06,3.83c0.11,0.45,0.31,0.44,0.63,0.37c1-0.24,1.99-0.53,2.86-1.1c-0.07-0.14-0.13-0.3-0.22-0.42
						c-0.8-1.02-1.62-2.04-2.42-3.06c-0.37-0.48-0.74-0.96-1.06-1.48c-0.54-0.88-0.58-0.95-1.34-0.48
						C813.15,2510.4,813.14,2510.41,813.05,2510.44z M826.12,2505.74c-2.83-0.14-5.59-0.22-8.29-0.92
						c-0.08,0.21-0.15,0.41-0.23,0.64c0.14,0.09,0.27,0.17,0.4,0.24c1.21,0.66,2.46,1.21,3.79,1.6c0.82,0.24,1.61,0.59,2.41,0.9
						c0.87,0.33,0.9,0.34,1.24-0.51C825.69,2507.09,825.87,2506.47,826.12,2505.74z M804.11,2514.11c0.56-0.93,1.1-1.78,1.61-2.65
						c0.44-0.75,0.85-1.53,1.28-2.28c0.16-0.28,0.04-0.47-0.18-0.6c-0.13-0.08-0.35-0.13-0.46-0.07c-0.61,0.36-1.2,0.77-1.8,1.14
						c-0.97,0.59-1.95,1.15-2.97,1.76C802.19,2512.45,803.03,2513.35,804.11,2514.11z M809.47,2495.61
						c-0.58,0.05-1.08,0.08-1.58,0.14c-0.84,0.11-1.62,0.42-2.35,0.85c-0.1,0.06-0.18,0.38-0.11,0.48c0.4,0.57,0.84,1.11,1.28,1.64
						c0.37,0.45,0.74,0.89,1.13,1.32c0.11,0.12,0.3,0.27,0.42,0.24c0.44-0.08,0.87-0.22,1.3-0.34
						C809.54,2498.53,809.5,2497.11,809.47,2495.61z M816.33,2507.99c-0.17,0.21-0.34,0.43-0.6,0.75c1.84,1.72,3.65,3.41,5.47,5.11
						c0.62-0.67,1.17-1.27,1.71-1.86C820.69,2510.64,818.53,2509.32,816.33,2507.99z M824.8,2509.24c-0.41-0.11-0.77-0.18-1.11-0.3
						c-0.99-0.34-2-0.65-2.97-1.05c-1.06-0.44-2.09-0.96-3.13-1.45c-0.09-0.04-0.19-0.07-0.3-0.12c-0.15,0.29-0.28,0.56-0.48,0.94
						c0.84,0.51,1.65,1.01,2.48,1.5c1.27,0.76,2.55,1.52,3.83,2.28c0.19,0.11,0.41,0.28,0.59-0.01
						C824.06,2510.47,824.41,2509.9,824.8,2509.24z M805.69,2503.84c0.08-0.2,0.13-0.29,0.15-0.4c0.09-0.41,0.23-0.84-0.25-1.13
						c-1.23-0.74-2.45-1.49-3.76-2.28c-0.17,0.21-0.44,0.49-0.63,0.82c-0.28,0.5-0.46,1.05-0.75,1.54c-0.24,0.4-0.07,0.58,0.27,0.66
						c0.57,0.14,1.15,0.24,1.73,0.33C803.53,2503.54,804.61,2503.68,805.69,2503.84z M805.68,2505.66c-0.01-0.2-0.02-0.32-0.02-0.43
						c-0.01-0.5-0.17-0.62-0.63-0.67c-1.3-0.16-2.6-0.34-3.89-0.53c-0.39-0.06-0.77-0.16-1.27-0.27c-0.05,0.94-0.13,1.82-0.15,2.71
						c-0.01,0.64,0.11,0.71,0.73,0.55C802.2,2506.56,803.94,2506.11,805.68,2505.66z M801.06,2510.68c0.98-0.54,1.92-1.04,2.84-1.58
						c0.66-0.39,1.3-0.82,1.94-1.25c0.14-0.1,0.31-0.31,0.29-0.45c-0.04-0.31-0.18-0.61-0.29-0.93c-1.95,0.52-3.88,1.04-5.89,1.58
						C800.33,2508.94,800.68,2509.77,801.06,2510.68z M812.78,2504.7c-0.52,0.38-0.96,0.7-1.43,1.04c0.72,1.17,1.45,2.36,2.2,3.57
						c0.87-0.51,1.58-1.09,2.07-1.91c0.14-0.24,0.32-0.45,0.48-0.68c-0.25-0.15-0.5-0.31-0.75-0.46
						C814.48,2505.73,813.6,2505.2,812.78,2504.7z M814.24,2504.72c-0.01,0.02-0.02,0.05-0.03,0.07c0.69,0.31,1.38,0.62,2.06,0.94
						c0.21,0.1,0.34,0.08,0.4-0.18c0.08-0.3,0.15-0.61,0.27-0.88c0.36-0.78,0.31-1.59,0.24-2.41c-0.03-0.34-0.22-0.46-0.56-0.36
						c-1.13,0.32-2.27,0.61-3.4,0.94c-0.12,0.04-0.22,0.23-0.28,0.38c-0.04,0.1,0.03,0.24,0,0.34c-0.07,0.25,0,0.39,0.23,0.52
						C813.55,2504.28,813.89,2504.51,814.24,2504.72z M810.34,2495.76c0.01,1.19,0,2.4,0.06,3.61c0.01,0.21-0.17,0.59,0.27,0.71
						c0.34,0.09,0.66,0.31,0.98,0c0.78-0.75,1.56-1.51,2.34-2.27c0.21-0.21,0.24-0.39-0.05-0.59
						C812.84,2496.49,811.71,2495.84,810.34,2495.76z M812.7,2509.58c-0.25-0.42-0.45-0.78-0.67-1.14
						c-0.47-0.78-0.93-1.56-1.42-2.33c-0.08-0.12-0.29-0.23-0.44-0.24c-0.37-0.02-0.75,0.01-1.16,0.02
						c-0.21,0.93-0.43,1.91-0.66,2.93C809.76,2509.43,811.14,2509.89,812.7,2509.58z M814.9,2498.12c-0.91,0.85-1.82,1.7-2.72,2.55
						c-0.05,0.05-0.07,0.21-0.04,0.28c0.18,0.33,0.37,0.66,0.58,0.98c0.05,0.08,0.21,0.17,0.29,0.15c1.19-0.33,2.38-0.67,3.56-1.03
						c0.07-0.02,0.18-0.22,0.15-0.27c-0.25-0.46-0.52-0.93-0.82-1.36C815.61,2498.98,815.27,2498.59,814.9,2498.12z M802.39,2499.15
						c1.42,1,2.71,1.9,3.97,2.78c0.38-0.43,0.69-0.78,1.01-1.13c-0.93-1.13-1.9-2.32-2.91-3.55
						C803.78,2497.87,803.13,2498.47,802.39,2499.15z M807.24,2515.85c0.16-0.88,0.31-1.7,0.45-2.53c0.17-1.06,0.29-2.13,0.51-3.18
						c0.08-0.4-0.05-0.51-0.28-0.6c-1.06,1.74-2.09,3.44-3.17,5.21C805.63,2515.14,806.4,2515.48,807.24,2515.85z M815.12,2509.32
						c-0.49,0.28-0.49,0.31-0.05,0.87c0.08,0.11,0.19,0.2,0.28,0.31c0.78,1.07,1.56,2.14,2.34,3.21c0.29,0.4,0.56,0.81,0.89,1.17
						c0.17,0.18,0.3,0.6,0.63,0.41c0.47-0.26,0.88-0.62,1.28-0.92C818.66,2512.65,816.9,2510.99,815.12,2509.32z M813.95,2517.13
						c-0.05-0.23-0.06-0.36-0.1-0.48c-0.2-0.73-0.42-1.45-0.61-2.17c-0.32-1.22-0.61-2.45-0.93-3.67c-0.03-0.12-0.22-0.2-0.4-0.35
						c-0.05,0.95-0.13,1.77-0.12,2.58c0.02,1.22,0.1,2.43,0.17,3.65c0.01,0.12,0.13,0.33,0.22,0.34
						C812.74,2517.08,813.31,2517.1,813.95,2517.13z M809.02,2509.98c-0.32,2.07-0.62,4.07-0.94,6.15c0.42,0.12,0.83,0.24,1.27,0.36
						c0.04-2.13,0.08-4.2,0.12-6.29C809.36,2510.15,809.24,2510.09,809.02,2509.98z M811.09,2516.8c-0.03-0.94-0.09-1.84-0.1-2.74
						c-0.01-0.94,0-1.89,0.02-2.83c0.02-0.63,0.18-0.72-0.68-0.83c-0.02,0.14-0.07,0.29-0.07,0.44c-0.02,1.63-0.02,3.26-0.06,4.89
						C810.2,2516.58,810.19,2516.61,811.09,2516.8z"/>
					<path d="M821.88,2455.43c1.21,0.58,2.39,1.12,3.56,1.71c1.07,0.54,2.1,1.17,3.18,1.69c0.69,0.33,1.02,0.9,1.28,1.54
						c0.27,0.66,0.55,1.31,0.74,1.99c0.28,0.97,0.42,1.97,0.32,3c-0.1,1.03-0.47,1.95-0.99,2.83c-0.62,1.05-1.22,2.13-1.82,3.19
						c-1.42,2.5-2.83,5-4.27,7.49c-0.34,0.6-0.72,1.19-1.15,1.72c-0.84,1.05-1.67,2.12-2.63,3.06c-0.85,0.84-1.79,1.62-2.96,2.03
						c-0.09,0.03-0.22,0.07-0.29,0.04c-0.51-0.26-1.02-0.55-1.46-0.79c0.63-0.92,1.32-1.79,1.85-2.75c0.54-0.97,0.92-2.04,1.38-3.06
						c0.26-0.59,0.57-1.15,0.81-1.75c0.27-0.68,0.48-1.38,0.74-2.06c0.15-0.38,0.36-0.74,0.5-1.13c0.32-0.84,0.6-1.7,0.91-2.55
						c0.51-1.36,1.04-2.72,1.55-4.08c0.4-1.06,0.86-2.1,1.16-3.19c0.26-0.93,0.47-1.9,0.26-2.88c-0.16-0.75-0.26-1.52-0.48-2.25
						c-0.15-0.5-0.43-0.98-0.72-1.43c-0.47-0.73-1-1.42-1.5-2.13C821.82,2455.64,821.87,2455.54,821.88,2455.43z"/>
					<path d="M808.55,2483.27c0.31-0.81,0.67-1.58,0.88-2.38c0.23-0.91,0.34-1.85,0.47-2.78c0.09-0.6,0.14-1.2,0.19-1.81
						c0.06-0.78,0.13-1.56,0.15-2.34c0.03-0.97,0.02-1.95,0.02-2.92c0-1.08-0.01-2.16-0.02-3.24c0-0.78-0.01-1.57-0.01-2.35
						c-0.01-1.11-0.02-2.21-0.03-3.32c0-0.92,0.08-1.84-0.02-2.74c-0.09-0.81-0.33-1.61-0.58-2.38c-0.4-1.2-0.99-2.3-1.9-3.21
						c-0.32-0.31-0.61-0.65-0.94-1.01c0.06-0.02,0.15-0.06,0.23-0.06c0.84,0,1.68,0,2.51,0.02c0.41,0.01,0.82,0.03,1.22,0.06
						c0.55,0.04,0.97,0.29,1.37,0.7c0.38,0.39,0.91,0.62,1.32,0.98c0.74,0.66,1.46,1.32,2.06,2.15c0.64,0.88,1.1,1.78,1.21,2.85
						c0.05,0.53,0.01,1.08-0.05,1.61c-0.13,1.25-0.28,2.49-0.45,3.73c-0.1,0.71-0.26,1.41-0.36,2.11c-0.13,0.9-0.22,1.81-0.34,2.72
						c-0.1,0.68-0.23,1.34-0.34,2.02c-0.17,1.03-0.32,2.06-0.49,3.09c-0.11,0.64-0.24,1.28-0.36,1.92
						c-0.22,1.16-0.37,2.33-0.67,3.47c-0.2,0.76-0.53,1.52-0.92,2.2c-0.48,0.82-1.34,1.02-2.24,1.01
						C809.82,2483.35,809.19,2483.3,808.55,2483.27z"/>
					<path d="M812.4,2483.82c1.09-0.81,1.46-1.98,1.93-3.06c0.27-0.62,0.33-1.33,0.45-2c0.12-0.67,0.21-1.35,0.32-2.02
						c0.16-1.03,0.33-2.06,0.5-3.09c0.11-0.67,0.25-1.34,0.35-2.01c0.16-1.02,0.3-2.04,0.47-3.05c0.11-0.7,0.26-1.4,0.36-2.11
						c0.13-0.9,0.23-1.81,0.35-2.72c0.09-0.66,0.17-1.32,0.28-1.98c0.2-1.25,0.33-2.51-0.14-3.71c-0.29-0.73-0.71-1.44-1.2-2.06
						c-0.63-0.81-1.38-1.53-2.1-2.26c-0.22-0.22-0.52-0.36-0.76-0.64c0.43,0.06,0.88,0.09,1.3,0.19c1.22,0.29,2.42,0.66,3.64,0.9
						c0.99,0.2,1.86,0.59,2.51,1.34c0.62,0.71,1.21,1.46,1.7,2.27c0.44,0.72,0.77,1.52,1.07,2.31c0.29,0.77,0.35,1.6,0.3,2.43
						c-0.08,1.36-0.63,2.58-1.15,3.81c-0.29,0.7-0.47,1.44-0.73,2.16c-0.27,0.74-0.57,1.46-0.84,2.19c-0.4,1.07-0.79,2.14-1.19,3.2
						c-0.26,0.68-0.54,1.35-0.8,2.02c-0.32,0.84-0.63,1.69-0.96,2.52c-0.12,0.3-0.28,0.58-0.41,0.87c-0.68,1.48-1.35,2.96-2.34,4.29
						c-0.57,0.77-0.98,0.89-1.84,0.51C813.17,2484,812.83,2483.94,812.4,2483.82z"/>
					<path d="M821.07,2488.54c0.41-0.28,0.84-0.55,1.26-0.85c0.39-0.27,0.78-0.54,1.13-0.85c0.44-0.39,0.84-0.81,1.25-1.23
						c0.68-0.7,1.37-1.39,2.02-2.12c0.99-1.12,1.96-2.26,2.91-3.41c1.03-1.25,2.04-2.52,3.05-3.78c0.48-0.6,0.97-1.2,1.43-1.82
						c0.69-0.93,1.39-1.85,2.01-2.82c0.6-0.94,0.66-2.04,0.64-3.12c-0.01-0.85-0.12-1.7-0.24-2.55c-0.07-0.52-0.24-1.02-0.38-1.6
						c0.97,0.9,1.9,1.73,2.8,2.6c0.59,0.57,1.15,1.18,1.71,1.78c0.28,0.29,0.59,0.57,0.79,0.9c0.14,0.23,0.17,0.55,0.18,0.83
						c0.01,0.72,0.01,1.45-0.05,2.17c-0.06,0.72-0.12,1.46-0.31,2.15c-0.25,0.9-0.51,1.82-1.16,2.54c-0.4,0.45-0.87,0.84-1.31,1.24
						c-0.29,0.26-0.59,0.5-0.88,0.75c-1.1,0.92-2.2,1.83-3.3,2.74c-0.76,0.62-1.52,1.24-2.29,1.84c-1.14,0.88-2.27,1.77-3.43,2.61
						c-1.03,0.75-2.09,1.46-3.17,2.13c-0.74,0.47-1.53,0.87-2.32,1.25c-0.35,0.17-0.71,0.22-1.04-0.16
						C821.98,2489.31,821.5,2488.94,821.07,2488.54z"/>
					<path d="M842.56,2470.93c0.34,0.41,0.63,0.73,0.9,1.08c0.45,0.6,0.9,1.21,1.32,1.83c0.47,0.71,1,1.42,1.31,2.2
						c0.21,0.52,0.16,1.18,0.08,1.75c-0.13,1-0.34,2-0.57,2.99c-0.1,0.45-0.29,0.9-0.51,1.3c-0.57,1.01-1.46,1.67-2.5,2.15
						c-0.49,0.23-0.94,0.55-1.41,0.83c-1.11,0.65-2.22,1.29-3.33,1.94c-0.46,0.27-0.9,0.58-1.36,0.85
						c-0.73,0.43-1.48,0.85-2.22,1.28c-0.8,0.45-1.59,0.92-2.4,1.35c-0.87,0.47-1.75,0.93-2.65,1.36c-0.86,0.41-1.74,0.79-2.62,1.16
						c-0.3,0.12-0.62,0.21-0.94,0.22c-0.19,0.01-0.46-0.12-0.58-0.27c-0.55-0.7-1.07-1.44-1.61-2.18c1.21-0.38,2.27-1.07,3.32-1.77
						c0.97-0.65,1.95-1.29,2.9-1.97c0.63-0.45,1.2-0.97,1.81-1.44c0.81-0.62,1.63-1.22,2.44-1.85c0.53-0.41,1.03-0.84,1.55-1.26
						c0.76-0.62,1.52-1.23,2.28-1.86c0.61-0.5,1.19-1.04,1.82-1.52c0.76-0.58,1.4-1.25,1.8-2.11c0.52-1.13,0.91-2.31,1-3.57
						C842.46,2472.64,842.5,2471.84,842.56,2470.93z"/>
					<path d="M808.74,2517.24c-0.29,0.48-0.57,0.92-0.83,1.37c-0.49,0.87-0.99,1.73-1.43,2.62c-0.45,0.9-0.86,1.81-1.24,2.74
						c-0.39,0.98-0.78,1.96-1.05,2.98c-0.24,0.89-0.31,1.83-0.44,2.75c-0.08,0.54-0.15,1.09-0.19,1.63
						c-0.1,1.37-0.17,2.74-0.25,4.11c-0.02,0.27-0.02,0.53-0.05,0.8c-0.03,0.25-0.16,0.32-0.42,0.22c-0.75-0.29-1.49-0.59-2.25-0.81
						c-2.65-0.75-4.91-2.24-7.22-3.66c-0.04-0.02-0.06-0.06-0.13-0.12c0.08-0.14,0.16-0.28,0.25-0.42c0.49-0.74,0.96-1.49,1.46-2.23
						c0.97-1.43,1.95-2.85,2.93-4.26c1.01-1.45,2-2.92,3.06-4.34c0.77-1.03,1.6-2.01,2.45-2.97c0.39-0.44,0.88-0.79,1.33-1.18
						c0.41-0.35,0.83-0.42,1.36-0.2c0.79,0.33,1.61,0.57,2.42,0.84C808.55,2517.13,808.6,2517.17,808.74,2517.24z"/>
					<path d="M848.71,2512.07c-0.35,0.83-0.67,1.65-1.04,2.46c-0.5,1.09-1.02,2.17-1.55,3.25c-0.48,0.98-1.38,1.36-2.38,1.52
						c-1.12,0.17-2.16-0.1-3.15-0.61c-0.82-0.42-1.67-0.79-2.5-1.19c-1.18-0.57-2.36-1.13-3.52-1.73c-1.64-0.85-3.25-1.74-4.88-2.61
						c-0.96-0.52-1.94-1.02-2.88-1.59c-0.51-0.31-0.96-0.73-1.44-1.1c-0.19-0.15-0.2-0.29-0.08-0.51c0.31-0.55,0.61-1.1,0.87-1.67
						c0.18-0.38,0.28-0.79,0.45-1.27c0.58,0.35,1.1,0.75,1.68,1c0.89,0.38,1.82,0.7,2.76,0.97c1.24,0.35,2.5,0.62,3.76,0.93
						c0.48,0.12,0.95,0.27,1.42,0.41c1.36,0.39,2.72,0.82,4.09,1.17c1.18,0.3,2.36,0.6,3.57,0.75c1.53,0.19,3.09,0.33,4.6-0.18
						C848.53,2512.04,848.61,2512.07,848.71,2512.07z"/>
					<path d="M825.84,2494.11c0.36-0.11,0.74-0.21,1.1-0.35c0.83-0.33,1.65-0.67,2.46-1.04c0.81-0.37,1.62-0.76,2.41-1.18
						c0.86-0.46,1.69-0.98,2.54-1.46c0.66-0.38,1.34-0.73,2-1.11c0.49-0.28,0.96-0.59,1.44-0.88c1.06-0.64,2.13-1.28,3.2-1.92
						c0.49-0.29,0.99-0.57,1.48-0.87c0.51-0.31,1.03-0.59,1.51-0.94c0.44-0.32,0.91-0.65,1.22-1.08c0.46-0.64,0.91-1.34,1.15-2.08
						c0.3-0.92,0.39-1.9,0.57-2.86c0.04-0.22,0.07-0.44,0.2-0.7c0.35,0.68,0.73,1.35,1.04,2.05c0.43,0.95,0.82,1.92,1.2,2.89
						c0.2,0.49,0.46,0.99,0.49,1.5c0.02,0.46-0.16,0.99-0.4,1.41c-0.57,1.01-1.15,2.03-1.88,2.92c-0.58,0.71-1.34,1.27-2.2,1.69
						c-1.44,0.71-2.97,1.19-4.46,1.78c-0.82,0.32-1.61,0.73-2.43,1.07c-0.87,0.36-1.76,0.67-2.63,1.02
						c-0.77,0.31-1.52,0.66-2.29,0.95c-0.78,0.3-1.57,0.58-2.37,0.82c-0.86,0.25-1.73,0.46-2.61,0.65c-0.4,0.09-0.82,0.11-1.22,0.11
						c-0.48-0.01-0.72-0.32-0.86-0.77C826.31,2495.2,826.07,2494.68,825.84,2494.11z"/>
					<path d="M830.86,2460.25c0.14,0.08,0.28,0.16,0.41,0.25c0.72,0.53,1.44,1.06,2.15,1.61c0.45,0.35,0.94,0.69,1.3,1.13
						c0.27,0.34,0.4,0.82,0.52,1.25c0.23,0.82,0.49,1.64,0.6,2.47c0.11,0.91,0.2,1.84,0.01,2.78c-0.17,0.85-0.34,1.69-0.87,2.39
						c-0.6,0.79-1.28,1.52-1.89,2.3c-0.49,0.62-0.92,1.3-1.41,1.92c-0.58,0.73-1.21,1.41-1.8,2.13c-0.42,0.52-0.79,1.09-1.22,1.6
						c-0.46,0.55-0.98,1.05-1.45,1.58c-0.34,0.39-0.63,0.83-0.99,1.2c-0.79,0.82-1.6,1.61-2.4,2.41c-0.37,0.37-0.74,0.75-1.13,1.09
						c-0.66,0.57-1.34,1.11-2.2,1.36c-0.14,0.04-0.34,0.02-0.47-0.05c-0.7-0.4-1.39-0.83-2.18-1.32c0.3-0.18,0.5-0.27,0.67-0.4
						c0.77-0.61,1.57-1.19,2.28-1.87c0.68-0.65,1.36-1.34,1.88-2.11c1.4-2.08,2.77-4.19,4.07-6.34c1.21-2,2.34-4.06,3.48-6.11
						c0.47-0.85,0.93-1.72,1.28-2.62c0.39-1,0.49-2.08,0.28-3.14c-0.2-1.02-0.49-2.02-0.76-3.02c-0.04-0.15-0.14-0.29-0.21-0.44
						C830.82,2460.29,830.84,2460.27,830.86,2460.25z"/>
					<path d="M827.41,2500.2c0.61,0.07,1.2,0.18,1.78,0.19c0.89,0.01,1.78-0.03,2.67-0.08c0.81-0.05,1.62-0.12,2.42-0.22
						c0.86-0.11,1.71-0.25,2.57-0.39c0.75-0.12,1.5-0.23,2.25-0.37c1.03-0.19,2.05-0.41,3.07-0.61c0.72-0.14,1.45-0.28,2.17-0.41
						c0.35-0.06,0.7-0.11,1.04-0.19c0.83-0.18,1.66-0.36,2.49-0.56c0.74-0.18,1.39-0.55,1.86-1.16c0.64-0.83,1.26-1.68,1.88-2.51
						c0.02,0.49,0.06,1.05,0.06,1.6c0,0.72,0,1.44-0.04,2.16c-0.04,0.83-0.11,1.65-0.17,2.48c-0.02,0.28-0.07,0.56-0.1,0.84
						c-0.11,0.98-0.66,1.71-1.38,2.34c-0.26,0.22-0.51,0.45-0.79,0.66c-0.91,0.68-1.95,0.78-3.04,0.69
						c-1.59-0.13-3.18-0.22-4.77-0.35c-1.12-0.09-2.24-0.21-3.35-0.3c-1.28-0.11-2.57-0.19-3.86-0.31
						c-0.88-0.09-1.77-0.22-2.64-0.37c-1.15-0.19-2.3-0.39-3.44-0.66c-0.32-0.08-0.77-0.17-0.69-0.77
						C827.49,2501.33,827.42,2500.76,827.41,2500.2z"/>
					<path d="M826.99,2497.32c1.49,0.12,2.86-0.28,4.22-0.72c0.81-0.26,1.63-0.5,2.44-0.79c0.75-0.27,1.48-0.58,2.21-0.88
						c0.93-0.38,1.85-0.76,2.78-1.14c0.8-0.33,1.6-0.68,2.4-1c0.97-0.39,1.95-0.75,2.93-1.13c0.94-0.37,1.88-0.73,2.71-1.35
						c0.67-0.51,1.25-1.09,1.83-1.7c0.64-0.67,1.01-1.48,1.5-2.22c0.13-0.2,0.15-0.46,0.22-0.7c0.04,0,0.08,0,0.12,0
						c0.13,0.45,0.28,0.89,0.37,1.34c0.19,0.89,0.36,1.79,0.5,2.69c0.11,0.74,0.38,1.48,0.13,2.23c-0.23,0.66-0.49,1.31-0.82,1.92
						c-0.36,0.65-0.78,1.29-1.25,1.87c-0.6,0.75-1.47,1.05-2.39,1.22c-1.13,0.2-2.24,0.46-3.37,0.68c-0.69,0.14-1.38,0.27-2.08,0.4
						c-0.84,0.16-1.67,0.32-2.51,0.47c-0.99,0.17-1.98,0.31-2.97,0.46c-0.67,0.1-1.33,0.22-2,0.3c-1.02,0.11-2.04,0.22-3.07,0.27
						c-0.76,0.04-1.52,0.03-2.28-0.01c-0.39-0.02-0.79-0.11-1.14-0.26c-0.14-0.06-0.2-0.37-0.24-0.57
						C827.13,2498.25,827.07,2497.81,826.99,2497.32z"/>
					<path d="M826.9,2506.15c0.16-0.94,0.31-1.86,0.47-2.84c0.32,0.09,0.62,0.21,0.94,0.28c0.72,0.14,1.44,0.26,2.17,0.39
						c0.73,0.12,1.46,0.26,2.19,0.36c0.85,0.11,1.7,0.17,2.55,0.25c0.53,0.05,1.06,0.12,1.59,0.16c1.39,0.11,2.79,0.21,4.18,0.32
						c0.77,0.06,1.54,0.14,2.31,0.2c1.23,0.1,2.46,0.25,3.69,0.27c1.08,0.02,2.07-0.34,2.93-1.02c0.33-0.26,0.65-0.54,0.99-0.83
						c0.01,0.08,0.06,0.2,0.03,0.3c-0.17,0.77-0.33,1.54-0.53,2.31c-0.32,1.21-0.77,2.39-0.99,3.62c-0.21,1.24-1.12,1.38-2.03,1.53
						c-1.17,0.19-2.35,0.15-3.52-0.11c-1.17-0.26-2.35-0.47-3.52-0.76c-1.22-0.3-2.42-0.69-3.64-1.01
						c-1.12-0.29-2.25-0.52-3.37-0.79c-0.44-0.1-0.87-0.23-1.29-0.36c-0.65-0.2-1.29-0.4-1.93-0.62c-0.72-0.25-1.45-0.49-2.14-0.82
						C827.58,2506.78,827.25,2506.43,826.9,2506.15z"/>
					<path d="M821.32,2514.87c0.72,1.71,1.66,3.34,2.66,4.93c0.64,1.02,1.28,2.04,1.92,3.07c0.68,1.1,1.34,2.21,2.03,3.29
						c0.57,0.89,1.15,1.77,1.77,2.62c0.5,0.69,0.99,1.44,1.64,1.98c0.64,0.53,1.46,0.84,2.21,1.25c0.09,0.05,0.18,0.09,0.37,0.19
						c-0.99,0.62-1.89,1.2-2.8,1.75c-0.6,0.36-1.21,0.69-1.83,1c-0.9,0.44-1.81,0.87-2.74,1.21c-0.18,0.07-0.55-0.24-0.76-0.45
						c-0.29-0.29-0.5-0.65-0.75-0.98c-0.59-0.74-0.91-1.62-1.23-2.5c-0.33-0.91-0.64-1.84-0.94-2.76c-0.44-1.37-0.88-2.74-1.29-4.11
						c-0.6-1.98-1.2-3.95-1.75-5.94c-0.26-0.94-0.42-1.91-0.6-2.86c-0.02-0.11,0.03-0.29,0.11-0.34
						C819.98,2515.76,820.65,2515.32,821.32,2514.87z"/>
					<path d="M809.16,2537.68c0.12-1.24,0.25-2.46,0.36-3.69c0.09-1.08,0.14-2.17,0.24-3.26c0.08-0.89,0.22-1.77,0.3-2.66
						c0.08-0.82,0.1-1.64,0.19-2.45c0.11-0.94,0.23-1.89,0.44-2.81c0.37-1.63,1.08-3.11,2.04-4.48c0.15-0.21,0.27-0.35,0.56-0.36
						c0.64-0.02,1.28-0.08,1.83-0.12c-0.03,1.53-0.12,3.07-0.07,4.61c0.03,1.17,0.24,2.34,0.36,3.51c0.09,0.94,0.11,1.88,0.25,2.81
						c0.21,1.37,0.48,2.73,0.74,4.08c0.25,1.3,0.53,2.59,0.79,3.89c0.05,0.26,0.11,0.52,0.18,0.77c0.07,0.27-0.02,0.44-0.31,0.45
						c-0.81,0.02-1.63,0.05-2.44,0.04c-1.02,0-2.03-0.01-3.05-0.06c-0.7-0.04-1.4-0.17-2.09-0.26
						C809.39,2537.69,809.3,2537.69,809.16,2537.68z"/>
					<path d="M797.77,2481.64c0.28-0.39,0.48-0.67,0.68-0.96c0.37-0.56,0.22-1.17,0.2-1.77c-0.02-0.6-0.01-1.21,0.07-1.8
						c0.16-1.17,0.55-2.33,0.2-3.51c-0.23-0.77-0.51-1.54-0.86-2.26c-0.41-0.83-0.94-1.6-1.39-2.41c-0.34-0.61-0.11-1.07,0.5-1.41
						c0.6-0.33,1.2-0.7,1.71-1.16c0.45-0.41,0.79-0.94,1.12-1.45c0.42-0.66,0.4-1.78-0.56-2.17c-0.45-0.18-0.88-0.43-1.3-0.68
						c-0.45-0.26-0.48-0.56-0.13-0.91c0.91-0.9,1.02-1.5,0.47-2.66c-0.33-0.7-0.45-1.38,0.09-2.04c0.29-0.36,0.52-0.36,0.75,0.04
						c0.74,1.27,1.42,2.58,1.83,4c0.24,0.85,0.33,1.74,0.47,2.61c0.29,1.77,0.57,3.53,0.84,5.3c0.23,1.53,0.46,3.06,0.66,4.59
						c0.21,1.63,0.44,3.26,0.57,4.89c0.09,1.13,0.04,2.26,0.03,3.4c0,0.57-0.02,1.14-0.09,1.71c-0.04,0.37-0.15,0.75-0.67,0.74
						c-0.21,0-0.43,0.14-0.65,0.15c-0.34,0.02-0.67,0.13-1.04-0.11C800.15,2483.05,798.99,2482.39,797.77,2481.64z"/>
					<path d="M822.92,2513.34c0.66,0.8,1.26,1.62,1.96,2.33c0.7,0.71,1.5,1.31,2.26,1.95c0.75,0.63,1.49,1.26,2.25,1.88
						c0.37,0.3,0.76,0.56,1.15,0.83c0.57,0.39,1.15,0.76,1.71,1.16c0.85,0.61,1.68,1.25,2.52,1.87c0.89,0.67,1.75,1.37,2.66,1.99
						c0.51,0.35,1.11,0.58,1.68,0.83c0.26,0.11,0.57,0.13,0.99,0.22c-0.51,0.55-0.94,1.05-1.41,1.5c-0.97,0.95-1.96,1.89-2.96,2.81
						c-0.29,0.27-0.61,0.53-0.96,0.68c-0.22,0.09-0.57,0.05-0.79-0.07c-0.78-0.42-1.58-0.83-2.26-1.38
						c-0.54-0.44-0.93-1.07-1.34-1.65c-0.5-0.7-0.95-1.43-1.41-2.15c-0.46-0.71-0.91-1.44-1.37-2.15c-0.31-0.49-0.63-0.97-0.94-1.46
						c-0.6-0.95-1.19-1.91-1.78-2.86c-0.41-0.67-0.84-1.33-1.22-2.01c-0.5-0.92-1.01-1.83-1.43-2.79
						C821.91,2514.09,822.01,2513.97,822.92,2513.34z"/>
					<path d="M782.1,2497.34c0.6-1.17,1.16-2.33,1.8-3.45c0.65-1.13,1.4-2.2,2.1-3.3c0.14-0.22,0.27-0.26,0.52-0.13
						c0.97,0.5,1.94,1.02,2.94,1.45c2.7,1.16,5.1,2.82,7.54,4.42c0.86,0.57,1.68,1.2,2.5,1.83c0.55,0.42,1.06,0.89,1.63,1.37
						c-0.51,0.84-1.03,1.7-1.58,2.61c-0.16-0.07-0.37-0.16-0.58-0.24c-0.87-0.33-1.74-0.65-2.62-0.97
						c-0.64-0.23-1.28-0.47-1.93-0.66c-1.21-0.36-2.43-0.67-3.64-1.02c-0.89-0.26-1.76-0.57-2.66-0.79
						c-1.27-0.31-2.55-0.58-3.84-0.83c-0.59-0.11-1.2-0.11-1.8-0.16C782.4,2497.46,782.31,2497.41,782.1,2497.34z"/>
					<path d="M804.51,2515.46c-0.68,0.63-1.34,1.23-1.99,1.84c-0.14,0.13-0.24,0.31-0.36,0.47c-0.85,1.13-1.72,2.25-2.54,3.39
						c-0.94,1.31-1.85,2.65-2.78,3.98c-0.73,1.05-1.48,2.09-2.2,3.15c-0.63,0.93-1.24,1.87-1.83,2.81
						c-0.18,0.29-0.33,0.28-0.58,0.11c-0.88-0.59-1.67-1.28-2.37-2.07c-0.35-0.39-0.71-0.78-1.12-1.1
						c-0.35-0.28-0.39-0.54-0.23-0.93c0.38-0.92,0.68-1.87,1.09-2.77c0.43-0.93,1.09-1.68,1.92-2.3c0.4-0.3,0.72-0.71,1.12-1.04
						c1.16-0.96,2.34-1.89,3.51-2.83c0.59-0.48,1.16-0.97,1.76-1.43c0.94-0.72,1.87-1.46,2.86-2.11c0.41-0.27,0.95-0.38,1.45-0.5
						c0.18-0.04,0.43,0.04,0.59,0.15C803.39,2514.65,803.95,2515.07,804.51,2515.46z"/>
					<path d="M818.28,2516.84c0.42,1.56,0.82,3.13,1.26,4.7c0.41,1.47,0.84,2.94,1.29,4.41c0.25,0.83,0.55,1.64,0.82,2.47
						c0.56,1.68,1.07,3.37,1.69,5.02c0.28,0.74,0.76,1.4,1.19,2.07c0.23,0.36,0.56,0.66,0.89,1.05c-0.64,0.19-1.23,0.4-1.83,0.52
						c-1.22,0.25-2.46,0.47-3.69,0.68c-0.39,0.07-0.79,0.09-1.18,0.1c-0.25,0.01-0.45-0.06-0.51-0.38
						c-0.36-1.71-0.77-3.41-1.11-5.12c-0.22-1.13-0.37-2.27-0.53-3.41c-0.17-1.24-0.35-2.47-0.47-3.71
						c-0.13-1.31-0.26-2.62-0.28-3.93c-0.02-1.19,0.01-2.4,0.38-3.55c0.03-0.09,0.16-0.18,0.27-0.22
						C817.08,2517.29,817.7,2517.06,818.28,2516.84z"/>
					<path d="M824.65,2511.05c0.38,0.27,0.72,0.55,1.1,0.78c2.02,1.2,4.03,2.41,6.06,3.56c1.1,0.62,2.24,1.17,3.38,1.73
						c1.18,0.59,2.36,1.2,3.57,1.74c1.06,0.47,2.14,0.9,3.25,1.23c0.88,0.26,1.81,0.15,2.75-0.15c-0.5,0.82-0.95,1.6-1.44,2.36
						c-0.36,0.55-0.75,1.08-1.14,1.61c-0.38,0.49-0.77,0.97-1.18,1.43c-0.11,0.13-0.35,0.25-0.5,0.23
						c-0.67-0.13-1.41-0.18-1.97-0.52c-1.25-0.75-2.41-1.65-3.59-2.5c-1.04-0.75-2.06-1.51-3.09-2.28
						c-0.83-0.62-1.66-1.23-2.48-1.86c-0.71-0.55-1.42-1.09-2.09-1.68c-0.65-0.57-1.3-1.15-1.89-1.79c-0.6-0.66-1.14-1.39-1.67-2.11
						c-0.08-0.11-0.07-0.37,0.01-0.49C824,2511.91,824.32,2511.52,824.65,2511.05z"/>
					<path d="M801.81,2513.22c-0.74,0.36-1.53,0.7-2.29,1.11c-0.37,0.21-0.66,0.55-1.01,0.82c-1.13,0.87-2.27,1.73-3.38,2.61
						c-0.61,0.48-1.19,1-1.79,1.5c-0.95,0.81-1.93,1.6-2.85,2.44c-0.4,0.37-0.75,0.82-1.02,1.29c-0.54,0.91-1.02,1.85-1.49,2.79
						c-0.13,0.27-0.16,0.6-0.27,1.05c-0.41-0.51-0.77-0.92-1.08-1.35c-0.4-0.56-0.8-1.14-1.15-1.74c-0.51-0.86-0.97-1.76-1.47-2.62
						c-0.24-0.42,0.09-0.65,0.28-0.9c0.39-0.54,0.8-1.07,1.24-1.56c0.63-0.7,1.24-1.43,1.97-2.02c0.47-0.38,1.13-0.55,1.71-0.79
						c0.9-0.36,1.81-0.69,2.71-1.05c1.05-0.41,2.1-0.84,3.16-1.25c0.93-0.37,1.86-0.76,2.8-1.08c0.86-0.3,1.74-0.52,2.62-0.76
						c0.1-0.03,0.27,0.04,0.33,0.12C801.18,2512.28,801.51,2512.77,801.81,2513.22z"/>
					<path d="M781.76,2498.26c0.67,0.05,1.35,0.06,2.01,0.16c1.04,0.17,2.09,0.38,3.11,0.63c0.9,0.22,1.78,0.54,2.67,0.79
						c1.07,0.3,2.15,0.57,3.22,0.89c1.32,0.39,2.63,0.79,3.94,1.21c0.76,0.25,1.49,0.55,2.24,0.82c0.29,0.1,0.3,0.33,0.27,0.54
						c-0.06,0.38-0.15,0.77-0.27,1.13c-0.04,0.11-0.22,0.26-0.33,0.26c-1.62-0.08-3.24-0.18-4.86-0.26
						c-1.14-0.06-2.27-0.09-3.41-0.11c-0.86-0.01-1.72,0.02-2.58,0.02c-0.71,0-1.42-0.07-2.12-0.03c-0.75,0.04-1.5,0.12-2.23,0.28
						c-0.8,0.17-1.58,0.44-2.37,0.66c-0.71,0.2-0.69,0.2-0.65-0.6c0.05-1.14,0.19-2.27,0.46-3.39c0.2-0.83,0.4-1.65,0.76-2.42
						C781.7,2498.68,781.71,2498.49,781.76,2498.26z"/>
					<path d="M783.46,2519.92c-0.19-0.41-0.41-0.82-0.57-1.26c-0.45-1.25-0.86-2.51-1.31-3.76c-0.29-0.8-0.25-0.89,0.49-1.44
						c0.57-0.42,1.11-0.89,1.68-1.32c0.89-0.69,1.88-1.17,2.97-1.42c1.47-0.33,2.94-0.65,4.42-0.94c1.08-0.21,2.18-0.37,3.27-0.54
						c1.03-0.17,2.05-0.35,3.08-0.5c0.46-0.06,0.94-0.03,1.41-0.07c0.36-0.03,0.48,0.21,0.59,0.46c0.18,0.43,0.32,0.88,0.55,1.29
						c0.18,0.33,0.04,0.45-0.22,0.54c-1.06,0.36-2.13,0.68-3.17,1.07c-1.51,0.56-3.01,1.18-4.52,1.77
						c-1.08,0.42-2.18,0.81-3.25,1.24c-0.66,0.27-1.3,0.59-1.95,0.89c-0.05,0.02-0.11,0.05-0.14,0.1c-0.55,0.6-1.11,1.19-1.63,1.81
						C784.59,2518.5,784.06,2519.19,783.46,2519.92z"/>
					<path d="M801.65,2498.73c-0.6-0.42-1.2-0.83-1.79-1.26c-0.91-0.66-1.79-1.35-2.73-1.97c-1.71-1.14-3.43-2.28-5.2-3.34
						c-1.1-0.67-2.29-1.2-3.45-1.77c-0.47-0.23-0.97-0.42-1.45-0.63c-0.09-0.04-0.16-0.13-0.22-0.18c1.47-1.83,3.12-3.41,5.06-4.77
						c0.21,0.16,0.46,0.33,0.69,0.52c0.4,0.35,0.78,0.71,1.16,1.07c0.74,0.7,1.48,1.4,2.21,2.12c0.9,0.9,1.8,1.8,2.66,2.73
						c0.71,0.76,1.4,1.54,2.02,2.37c0.78,1.03,1.49,2.12,2.23,3.18c0.33,0.46,0,0.73-0.27,1.02
						C802.28,2498.13,801.96,2498.43,801.65,2498.73z"/>
					<path d="M799.05,2507.75c-1.15,0.14-2.3,0.27-3.45,0.41c-0.89,0.11-1.78,0.22-2.66,0.37c-1.07,0.19-2.14,0.4-3.2,0.64
						c-1.29,0.28-2.59,0.57-3.87,0.91c-0.88,0.23-1.65,0.7-2.39,1.23c-0.75,0.54-1.49,1.11-2.31,1.71c-0.05-0.2-0.12-0.39-0.16-0.59
						c-0.11-0.55-0.22-1.1-0.29-1.65c-0.12-0.99-0.22-1.99-0.32-2.99c-0.03-0.31-0.07-0.62-0.03-0.92c0.02-0.19,0.13-0.48,0.28-0.54
						c0.66-0.28,1.34-0.54,2.03-0.73c0.7-0.2,1.43-0.34,2.15-0.45c0.46-0.07,0.94-0.03,1.41-0.02c1.55,0.03,3.1,0.07,4.64,0.11
						c1.47,0.04,2.94,0.07,4.41,0.12c1.06,0.03,2.11,0.08,3.17,0.14c0.23,0.01,0.43,0.03,0.41,0.43
						C798.85,2506.55,798.99,2507.17,799.05,2507.75z"/>
					<path d="M811.93,2517.9c-0.06,0.1-0.11,0.17-0.15,0.26c-0.51,1.02-1.12,2-1.5,3.07c-0.47,1.29-0.76,2.64-0.79,4.04
						c-0.01,0.62-0.17,1.23-0.23,1.85c-0.1,1-0.17,2-0.25,2.99c-0.05,0.62-0.1,1.25-0.15,1.87c-0.07,0.85-0.13,1.7-0.21,2.55
						c-0.05,0.59-0.11,1.18-0.18,1.77c-0.04,0.41-0.09,0.82-0.14,1.26c-0.46-0.05-0.95-0.06-1.43-0.16
						c-0.83-0.17-1.65-0.4-2.48-0.58c-0.33-0.07-0.37-0.26-0.35-0.56c0.06-1.01,0.07-2.03,0.13-3.05c0.04-0.77,0.1-1.54,0.2-2.31
						c0.17-1.19,0.33-2.39,0.59-3.56c0.16-0.71,0.51-1.38,0.74-2.09c0.57-1.75,1.38-3.4,2.27-5.01c0.4-0.72,0.74-1.47,1.14-2.18
						c0.38-0.68,0.4-0.68,1.13-0.55C810.81,2517.62,811.35,2517.76,811.93,2517.9z"/>
					<path d="M803.79,2496.67c-0.33-0.5-0.67-1.01-1.02-1.52c-0.38-0.56-0.73-1.13-1.16-1.65c-0.78-0.94-1.59-1.86-2.41-2.77
						c-0.56-0.62-1.14-1.22-1.72-1.81c-0.85-0.86-1.69-1.73-2.56-2.56c-0.59-0.56-1.22-1.07-1.83-1.61
						c-0.13-0.12-0.22-0.29-0.39-0.52c0.75-0.41,1.47-0.84,2.22-1.22c0.59-0.29,1.21-0.51,1.81-0.79c0.26-0.12,0.47-0.14,0.72,0.02
						c1.26,0.82,2.53,1.61,3.79,2.43c0.17,0.11,0.28,0.34,0.37,0.53c0.58,1.27,1.13,2.56,1.72,3.82c0.59,1.24,1.05,2.51,1.42,3.84
						c0.25,0.9,0.5,1.79,0.49,2.73c0,0.1-0.05,0.24-0.12,0.28C804.7,2496.14,804.27,2496.38,803.79,2496.67z"/>
					<path d="M812.6,2484.72c-0.08,1.05-0.13,2.01-0.23,2.96c-0.07,0.66-0.19,1.32-0.32,1.97c-0.19,0.93-0.35,1.87-0.62,2.77
						c-0.24,0.78-0.62,1.51-0.94,2.25c-0.03,0.07-0.13,0.17-0.19,0.17c-0.51-0.02-1.02-0.05-1.51-0.08
						c-0.09-1.4-0.15-2.77-0.26-4.13c-0.07-0.89-0.22-1.78-0.35-2.67c-0.11-0.76-0.24-1.51-0.38-2.26c-0.07-0.41-0.2-0.8-0.27-1.21
						c-0.05-0.25-0.06-0.47,0.36-0.42c0.87,0.09,1.74,0.09,2.61,0.18c0.44,0.04,0.88,0.17,1.31,0.27
						C812.07,2484.59,812.34,2484.65,812.6,2484.72z"/>
					<path d="M802.31,2484.67c1.47-0.2,2.87-0.39,4.31-0.58c0.3,1.72,0.62,3.38,0.86,5.05c0.18,1.2,0.27,2.42,0.37,3.63
						c0.05,0.61,0.04,1.23,0.04,1.85c0,0.1-0.09,0.26-0.17,0.28c-0.53,0.17-1.06,0.3-1.59,0.45
						C805.68,2491.54,803.94,2488.18,802.31,2484.67z"/>
					<path d="M813.21,2495.78c-0.68-0.3-1.29-0.57-1.93-0.85c0.21-0.47,0.52-1,0.7-1.57c0.34-1.11,0.72-2.22,0.82-3.4
						c0.05-0.65,0.24-1.28,0.32-1.92c0.11-0.91,0.18-1.83,0.27-2.75c0.01-0.06,0.03-0.12,0.07-0.29c1.34,0.63,2.64,1.25,3.99,1.89
						c-0.12,0.28-0.21,0.48-0.3,0.69c-0.31,0.72-0.6,1.45-0.92,2.17c-0.45,1.01-0.92,2.02-1.4,3.01c-0.41,0.86-0.85,1.71-1.29,2.56
						C813.45,2495.49,813.33,2495.62,813.21,2495.78z"/>
					<path d="M818.13,2487.34c1.08,0.85,2.11,1.65,3.19,2.51c-0.1,0.17-0.18,0.31-0.27,0.45c-0.42,0.62-0.8,1.28-1.26,1.86
						c-0.89,1.13-1.79,2.25-2.76,3.3c-0.53,0.58-1.23,1-1.86,1.49c-0.06,0.04-0.19,0.05-0.24,0.02c-0.32-0.21-0.62-0.44-0.91-0.65
						c0.01-0.07,0.01-0.08,0.02-0.1c0.7-1.35,1.43-2.69,2.11-4.05c0.45-0.91,0.86-1.84,1.25-2.78
						C817.65,2488.76,817.85,2488.11,818.13,2487.34z"/>
					<path d="M807.65,2508.1c-0.69-0.56-0.87-1.31-0.99-2.07c-0.1-0.63-0.11-1.28-0.15-1.92c0-0.06,0.01-0.12,0.02-0.17
						c0.27-0.96,0.65-1.87,1.45-2.49c0.63-0.49,1.4-0.63,2.2-0.58c0.71,0.04,1.17,0.46,1.57,0.98c0.51,0.66,0.48,2.16-0.15,2.7
						c-0.29,0.25-0.74,0.39-1.13,0.45c-0.52,0.08-1.05,0.03-1.59-0.02c-0.16,0.13-0.19,0.53-0.58,0.28
						C808.09,2506.2,807.88,2507.13,807.65,2508.1z"/>
					<path d="M815.66,2497.69c2.66-1.95,4.51-4.53,6.23-7.3c0.21,0.22,0.38,0.39,0.54,0.57c0.41,0.49,0.79,1,1.23,1.46
						c0.23,0.25,0.09,0.38-0.05,0.54c-0.48,0.56-0.96,1.13-1.47,1.66c-0.64,0.67-1.29,1.34-1.98,1.95
						c-0.95,0.83-1.91,1.64-3.09,2.15c-0.31,0.14-0.5,0.08-0.69-0.14C816.14,2498.3,815.92,2498.02,815.66,2497.69z"/>
					<path d="M817.37,2500.18c-0.02-0.07-0.02-0.16-0.07-0.2c-0.32-0.29-0.07-0.44,0.15-0.53c0.91-0.37,1.69-0.94,2.44-1.56
						c1.04-0.88,2.06-1.77,2.93-2.84c0.47-0.57,1.03-1.07,1.58-1.63c0.5,0.94,1.14,1.8,1.35,2.87c0.01,0.07,0.07,0.13,0.1,0.2
						c0.26,0.49,0.24,0.53-0.27,0.78c-1.01,0.49-1.99,1.05-3.02,1.48c-1.49,0.62-3,1.23-4.62,1.47
						C817.76,2500.25,817.56,2500.19,817.37,2500.18z"/>
					<path d="M826.28,2497.98c0.11,0.95,0.22,1.86,0.31,2.78c0.01,0.06-0.14,0.16-0.23,0.18c-1.28,0.32-2.55,0.66-3.84,0.94
						c-1.24,0.27-2.51,0.31-3.78,0.25c-0.52-0.03-0.91-0.35-0.94-0.85c0-0.08,0.11-0.22,0.19-0.23c1.43-0.18,2.78-0.63,4.08-1.22
						c1.24-0.56,2.47-1.13,3.71-1.7C825.91,2498.08,826.06,2498.05,826.28,2497.98z"/>
					<path d="M826.62,2501.84c-0.09,0.87-0.18,1.66-0.24,2.47c-0.03,0.46-0.3,0.6-0.69,0.58c-1.07-0.07-2.15-0.17-3.22-0.23
						c-1.42-0.07-2.83-0.25-4.2-0.64c-0.06-0.02-0.12,0-0.18,0c0-0.4,0.01-0.8,0.01-1.17C821,2503.34,823.76,2502.52,826.62,2501.84
						z"/>
					<path d="M813.05,2510.44c0.09-0.04,0.1-0.04,0.12-0.05c0.77-0.46,0.81-0.39,1.34,0.48c0.32,0.52,0.69,1,1.06,1.48
						c0.8,1.03,1.62,2.04,2.42,3.06c0.1,0.12,0.15,0.28,0.22,0.42c-0.87,0.57-1.85,0.86-2.86,1.1c-0.32,0.07-0.52,0.08-0.63-0.37
						c-0.31-1.29-0.71-2.55-1.06-3.83C813.44,2511.98,813.25,2511.21,813.05,2510.44z"/>
					<path d="M826.12,2505.74c-0.25,0.73-0.43,1.35-0.67,1.94c-0.34,0.85-0.37,0.84-1.24,0.51c-0.8-0.31-1.59-0.66-2.41-0.9
						c-1.33-0.39-2.58-0.94-3.79-1.6c-0.13-0.07-0.26-0.16-0.4-0.24c0.08-0.23,0.15-0.42,0.23-0.64
						C820.53,2505.53,823.29,2505.6,826.12,2505.74z"/>
					<path d="M804.11,2514.11c-1.08-0.76-1.91-1.66-2.52-2.71c1.02-0.6,2-1.17,2.97-1.76c0.61-0.37,1.19-0.78,1.8-1.14
						c0.11-0.06,0.33-0.01,0.46,0.07c0.22,0.13,0.34,0.32,0.18,0.6c-0.44,0.76-0.84,1.53-1.28,2.28
						C805.21,2512.33,804.67,2513.18,804.11,2514.11z"/>
					<path d="M809.47,2495.61c0.04,1.5,0.07,2.92,0.11,4.34c-0.43,0.12-0.86,0.27-1.3,0.34c-0.13,0.02-0.32-0.12-0.42-0.24
						c-0.39-0.43-0.77-0.87-1.13-1.32c-0.44-0.54-0.88-1.08-1.28-1.64c-0.07-0.09,0.01-0.41,0.11-0.48
						c0.72-0.43,1.51-0.74,2.35-0.85C808.39,2495.69,808.89,2495.66,809.47,2495.61z"/>
					<path d="M816.33,2507.99c2.19,1.33,4.36,2.65,6.59,4c-0.55,0.59-1.09,1.18-1.71,1.86c-1.82-1.7-3.63-3.39-5.47-5.11
						C815.99,2508.42,816.17,2508.2,816.33,2507.99z"/>
					<path d="M824.8,2509.24c-0.4,0.65-0.74,1.23-1.09,1.8c-0.18,0.29-0.4,0.12-0.59,0.01c-1.28-0.76-2.55-1.51-3.83-2.28
						c-0.82-0.49-1.64-1-2.48-1.5c0.19-0.38,0.33-0.65,0.48-0.94c0.11,0.04,0.21,0.07,0.3,0.12c1.04,0.48,2.07,1.01,3.13,1.45
						c0.97,0.4,1.97,0.71,2.97,1.05C824.03,2509.06,824.4,2509.14,824.8,2509.24z"/>
					<path d="M805.69,2503.84c-1.08-0.15-2.16-0.29-3.23-0.46c-0.58-0.09-1.16-0.19-1.73-0.33c-0.34-0.08-0.51-0.26-0.27-0.66
						c0.29-0.49,0.47-1.04,0.75-1.54c0.19-0.33,0.46-0.61,0.63-0.82c1.31,0.8,2.53,1.54,3.76,2.28c0.48,0.29,0.35,0.71,0.25,1.13
						C805.82,2503.54,805.77,2503.64,805.69,2503.84z"/>
					<path d="M805.68,2505.66c-1.74,0.45-3.48,0.9-5.23,1.35c-0.62,0.16-0.74,0.09-0.73-0.55c0.01-0.89,0.09-1.77,0.15-2.71
						c0.49,0.11,0.88,0.22,1.27,0.27c1.3,0.19,2.59,0.38,3.89,0.53c0.46,0.06,0.62,0.18,0.63,0.67
						C805.67,2505.34,805.67,2505.46,805.68,2505.66z"/>
					<path d="M801.06,2510.68c-0.38-0.91-0.74-1.75-1.1-2.62c2.01-0.54,3.94-1.06,5.89-1.58c0.11,0.32,0.25,0.62,0.29,0.93
						c0.02,0.14-0.15,0.35-0.29,0.45c-0.63,0.44-1.27,0.86-1.94,1.25C802.99,2509.64,802.05,2510.14,801.06,2510.68z"/>
					<path d="M812.78,2504.7c0.82,0.5,1.7,1.03,2.57,1.56c0.25,0.15,0.5,0.31,0.75,0.46c-0.16,0.23-0.34,0.44-0.48,0.68
						c-0.49,0.82-1.2,1.39-2.07,1.91c-0.75-1.21-1.48-2.4-2.2-3.57C811.82,2505.4,812.26,2505.08,812.78,2504.7z"/>
					<path d="M814.24,2504.72c-0.35-0.21-0.7-0.44-1.06-0.64c-0.23-0.12-0.29-0.27-0.23-0.52c0.03-0.11-0.04-0.25,0-0.34
						c0.06-0.14,0.16-0.34,0.28-0.38c1.13-0.33,2.27-0.63,3.4-0.94c0.34-0.1,0.53,0.02,0.56,0.36c0.07,0.82,0.12,1.63-0.24,2.41
						c-0.13,0.28-0.2,0.58-0.27,0.88c-0.06,0.25-0.19,0.27-0.4,0.18c-0.68-0.32-1.37-0.63-2.06-0.94
						C814.22,2504.77,814.23,2504.75,814.24,2504.72z"/>
					<path d="M810.34,2495.76c1.37,0.08,2.5,0.72,3.59,1.45c0.29,0.2,0.27,0.38,0.05,0.59c-0.78,0.76-1.56,1.52-2.34,2.27
						c-0.32,0.31-0.65,0.09-0.98,0c-0.44-0.12-0.26-0.5-0.27-0.71C810.34,2498.16,810.35,2496.95,810.34,2495.76z"/>
					<path d="M812.7,2509.58c-1.57,0.3-2.94-0.15-4.34-0.75c0.23-1.02,0.45-2,0.66-2.93c0.41-0.01,0.79-0.05,1.16-0.02
						c0.15,0.01,0.36,0.11,0.44,0.24c0.49,0.76,0.95,1.55,1.42,2.33C812.25,2508.8,812.45,2509.16,812.7,2509.58z"/>
					<path d="M814.9,2498.12c0.37,0.47,0.71,0.86,1,1.29c0.3,0.44,0.56,0.9,0.82,1.36c0.03,0.05-0.07,0.25-0.15,0.27
						c-1.18,0.36-2.37,0.7-3.56,1.03c-0.08,0.02-0.24-0.06-0.29-0.15c-0.21-0.31-0.4-0.64-0.58-0.98c-0.04-0.07-0.02-0.22,0.04-0.28
						C813.08,2499.82,813.99,2498.97,814.9,2498.12z"/>
					<path d="M802.39,2499.15c0.74-0.68,1.39-1.28,2.06-1.9c1.01,1.23,1.98,2.41,2.91,3.55c-0.32,0.35-0.63,0.71-1.01,1.13
						C805.1,2501.05,803.82,2500.14,802.39,2499.15z"/>
					<path d="M807.24,2515.85c-0.84-0.37-1.61-0.71-2.49-1.1c1.08-1.77,2.11-3.48,3.17-5.21c0.22,0.09,0.36,0.2,0.28,0.6
						c-0.21,1.05-0.33,2.12-0.51,3.18C807.55,2514.15,807.39,2514.97,807.24,2515.85z"/>
					<path d="M815.12,2509.32c1.78,1.68,3.54,3.33,5.37,5.06c-0.41,0.3-0.82,0.66-1.28,0.92c-0.33,0.18-0.47-0.24-0.63-0.41
						c-0.33-0.36-0.6-0.77-0.89-1.17c-0.78-1.07-1.56-2.14-2.34-3.21c-0.08-0.11-0.19-0.2-0.28-0.31
						C814.63,2509.62,814.63,2509.59,815.12,2509.32z"/>
					<path d="M813.95,2517.13c-0.64-0.03-1.2-0.05-1.77-0.11c-0.09-0.01-0.21-0.22-0.22-0.34c-0.07-1.21-0.15-2.43-0.17-3.65
						c-0.01-0.82,0.07-1.64,0.12-2.58c0.18,0.15,0.37,0.23,0.4,0.35c0.32,1.22,0.61,2.45,0.93,3.67c0.19,0.73,0.4,1.45,0.61,2.17
						C813.88,2516.77,813.9,2516.9,813.95,2517.13z"/>
					<path d="M809.02,2509.98c0.23,0.11,0.35,0.17,0.45,0.22c-0.04,2.09-0.08,4.16-0.12,6.29c-0.44-0.13-0.85-0.24-1.27-0.36
						C808.4,2514.05,808.7,2512.05,809.02,2509.98z"/>
					<path d="M811.09,2516.8c-0.9-0.19-0.89-0.21-0.87-1.07c0.03-1.63,0.04-3.26,0.06-4.89c0-0.15,0.04-0.29,0.07-0.44
						c0.86,0.11,0.69,0.2,0.68,0.83c-0.02,0.94-0.04,1.89-0.02,2.83C811,2514.96,811.05,2515.86,811.09,2516.8z"/>
				</g>
				<g>
					<path className="st12" d="M809.97,2448.67c-0.1,0.24-0.15,0.47-0.29,0.62c-0.51,0.57-1.22,0.75-1.93,0.9
						c-1.73,0.35-3.42,0.81-4.77,2.04c-0.19,0.18-0.37,0.43-0.44,0.68c-0.25,0.89-0.07,1.74,0.37,2.54c0.16,0.3,0.17,0.62-0.04,0.93
						c-0.27,0.41-0.52,0.83-0.78,1.25c-0.18,0.29-0.16,0.57,0.01,0.85c0.41,0.68,1.13,0.9,1.79,1.22c0.19,0.09,0.46,0.25,0.49,0.41
						c0.05,0.26-0.01,0.58-0.12,0.83c-0.52,1.15-1.55,1.74-2.59,2.33c-1.11,0.63-1.3,1.19-0.71,2.28c0.51,0.94,1.03,1.87,1.56,2.8
						c0.8,1.42,0.94,2.91,0.52,4.48c-0.22,0.84-0.32,1.71-0.15,2.59c0.09,0.45,0.04,0.84-0.19,1.28c-0.43,0.83-1.23,1.03-1.95,1.38
						c-0.93,0.45-1.87,0.87-2.77,1.36c-0.6,0.33-1.14,0.76-1.68,1.18c-0.82,0.62-1.65,1.24-2.43,1.91c-0.67,0.58-1.3,1.21-1.88,1.89
						c-0.76,0.9-1.52,1.83-2.16,2.82c-0.91,1.42-1.9,2.81-2.51,4.41c-0.36,0.93-0.85,1.81-1.2,2.74c-0.39,1.01-0.7,2.04-1.02,3.07
						c-0.13,0.42-0.24,0.85-0.29,1.28c-0.08,0.82-0.13,1.64-0.16,2.47c-0.04,0.93-0.09,1.86-0.04,2.78
						c0.05,0.92,0.21,1.83,0.35,2.74c0.16,1.1,0.29,2.22,0.54,3.31c0.25,1.1,0.61,2.18,0.97,3.25c0.3,0.89,0.64,1.77,1.01,2.63
						c0.35,0.82,0.73,1.64,1.15,2.43c0.55,1.02,1.14,2.03,1.77,3.01c0.47,0.73,0.98,1.45,1.54,2.1c0.94,1.09,1.87,2.22,2.95,3.18
						c1.16,1.04,2.43,1.99,3.75,2.82c1.43,0.9,2.95,1.65,4.46,2.4c0.91,0.45,1.86,0.82,2.82,1.16c0.97,0.34,1.97,0.58,2.97,0.86
						c0.53,0.15,1.07,0.29,1.62,0.39c0.96,0.18,1.92,0.32,2.88,0.47c0.68,0.11,1.36,0.25,2.04,0.29c1.47,0.08,2.94,0.13,4.4,0.15
						c1.03,0.01,2.06-0.01,3.09-0.08c1.16-0.09,2.31-0.27,3.46-0.39c1.92-0.2,3.75-0.77,5.55-1.41c0.67-0.24,1.33-0.51,1.97-0.81
						c0.8-0.38,1.58-0.8,2.35-1.23c0.46-0.26,0.9-0.56,1.35-0.85c0.73-0.47,1.47-0.92,2.16-1.44c0.68-0.51,1.3-1.09,1.94-1.65
						c0.93-0.82,1.89-1.62,2.78-2.49c0.8-0.79,1.55-1.64,2.27-2.51c0.76-0.93,1.48-1.89,2.18-2.87c0.46-0.63,0.91-1.28,1.29-1.96
						c0.89-1.57,1.73-3.16,2.59-4.73c0.73-1.33,1.32-2.72,1.77-4.17c0.38-1.21,0.87-2.38,1.22-3.59c0.32-1.12,0.54-2.28,0.78-3.43
						c0.2-0.96,0.43-1.92,0.55-2.89c0.19-1.49,0.32-2.99,0.43-4.49c0.07-0.91,0.1-1.83,0.08-2.74c-0.03-1-0.11-1.99-0.23-2.98
						c-0.18-1.48-0.39-2.95-0.64-4.42c-0.14-0.85-0.36-1.68-0.59-2.51c-0.2-0.75-0.41-1.5-0.69-2.22c-0.43-1.12-0.91-2.23-1.4-3.33
						c-0.46-1.01-0.91-2.04-1.46-3c-0.7-1.22-1.45-2.41-2.25-3.56c-0.67-0.97-1.39-1.9-2.15-2.8c-0.83-0.98-1.72-1.91-2.61-2.84
						c-0.81-0.86-1.64-1.7-2.5-2.51c-0.66-0.62-1.38-1.18-2.07-1.77c-0.83-0.7-1.62-1.45-2.49-2.09c-1.11-0.82-2.26-1.58-3.43-2.31
						c-2.48-1.53-4.97-3.04-7.75-3.99c-0.65-0.22-1.28-0.51-1.94-0.74c-1.01-0.34-2.01-0.69-3.04-0.97
						c-1.06-0.29-2.14-0.52-3.21-0.75c-0.56-0.12-1.12-0.2-1.69-0.25c-0.86-0.09-1.72-0.15-2.58-0.2c-0.56-0.04-1.11-0.07-1.67-0.06
						c-0.7,0.01-1.41,0.04-2.1,0.11c-0.33,0.03-0.82-0.13-0.87,0.48C810.15,2448.7,810.03,2448.68,809.97,2448.67z"/>
					<path className="st23" d="M809.97,2448.67c0.06,0.01,0.18,0.03,0.3,0.06c0.05-0.61,0.54-0.44,0.87-0.48c0.7-0.07,1.4-0.1,2.1-0.11
						c0.56-0.01,1.12,0.03,1.67,0.06c0.86,0.06,1.72,0.12,2.58,0.2c0.57,0.06,1.13,0.14,1.69,0.25c1.07,0.23,2.15,0.46,3.21,0.75
						c1.03,0.28,2.03,0.63,3.04,0.97c0.65,0.22,1.28,0.51,1.94,0.74c2.78,0.94,5.28,2.45,7.75,3.99c1.17,0.73,2.32,1.49,3.43,2.31
						c0.87,0.64,1.66,1.39,2.49,2.09c0.69,0.59,1.41,1.15,2.07,1.77c0.86,0.81,1.68,1.66,2.5,2.51c0.89,0.93,1.78,1.86,2.61,2.84
						c0.76,0.9,1.48,1.83,2.15,2.8c0.79,1.16,1.55,2.34,2.25,3.56c0.55,0.96,1,1.99,1.46,3c0.49,1.1,0.97,2.21,1.4,3.33
						c0.28,0.72,0.49,1.47,0.69,2.22c0.22,0.83,0.44,1.67,0.59,2.51c0.25,1.47,0.46,2.94,0.64,4.42c0.12,0.99,0.2,1.99,0.23,2.98
						c0.02,0.91-0.01,1.83-0.08,2.74c-0.11,1.5-0.24,3-0.43,4.49c-0.12,0.97-0.34,1.93-0.55,2.89c-0.24,1.15-0.46,2.3-0.78,3.43
						c-0.35,1.21-0.84,2.38-1.22,3.59c-0.45,1.45-1.05,2.84-1.77,4.17c-0.86,1.58-1.71,3.17-2.59,4.73
						c-0.38,0.68-0.83,1.33-1.29,1.96c-0.71,0.97-1.42,1.94-2.18,2.87c-0.72,0.87-1.47,1.72-2.27,2.51
						c-0.89,0.87-1.84,1.67-2.78,2.49c-0.64,0.56-1.26,1.14-1.94,1.65c-0.69,0.52-1.44,0.97-2.16,1.44
						c-0.44,0.29-0.88,0.59-1.35,0.85c-0.77,0.43-1.56,0.85-2.35,1.23c-0.64,0.3-1.3,0.58-1.97,0.81c-1.81,0.65-3.63,1.21-5.55,1.41
						c-1.16,0.12-2.31,0.3-3.46,0.39c-1.03,0.08-2.06,0.1-3.09,0.08c-1.47-0.02-2.94-0.07-4.4-0.15c-0.68-0.04-1.36-0.18-2.04-0.29
						c-0.96-0.15-1.92-0.29-2.88-0.47c-0.54-0.1-1.08-0.25-1.62-0.39c-0.99-0.27-1.99-0.52-2.97-0.86c-0.96-0.33-1.91-0.7-2.82-1.16
						c-1.51-0.75-3.04-1.5-4.46-2.4c-1.32-0.83-2.59-1.78-3.75-2.82c-1.07-0.96-2-2.08-2.95-3.18c-0.57-0.66-1.08-1.37-1.54-2.1
						c-0.63-0.98-1.21-1.99-1.77-3.01c-0.43-0.79-0.8-1.61-1.15-2.43c-0.37-0.86-0.71-1.74-1.01-2.63
						c-0.36-1.07-0.72-2.15-0.97-3.25c-0.25-1.09-0.37-2.2-0.54-3.31c-0.13-0.91-0.3-1.82-0.35-2.74c-0.05-0.92,0-1.85,0.04-2.78
						c0.03-0.82,0.08-1.65,0.16-2.47c0.04-0.43,0.16-0.86,0.29-1.28c0.32-1.03,0.64-2.06,1.02-3.07c0.36-0.93,0.85-1.81,1.2-2.74
						c0.61-1.6,1.6-2.98,2.51-4.41c0.64-0.99,1.39-1.92,2.16-2.82c0.57-0.68,1.21-1.31,1.88-1.89c0.78-0.68,1.61-1.29,2.43-1.91
						c0.55-0.41,1.09-0.85,1.68-1.18c0.9-0.5,1.84-0.91,2.77-1.36c0.73-0.35,1.53-0.55,1.95-1.38c0.23-0.44,0.27-0.83,0.19-1.28
						c-0.17-0.87-0.07-1.75,0.15-2.59c0.42-1.58,0.28-3.06-0.52-4.48c-0.52-0.93-1.05-1.86-1.56-2.8c-0.59-1.09-0.4-1.66,0.71-2.28
						c1.04-0.58,2.07-1.18,2.59-2.33c0.11-0.25,0.18-0.57,0.12-0.83c-0.03-0.16-0.3-0.32-0.49-0.41c-0.66-0.32-1.38-0.54-1.79-1.22
						c-0.17-0.28-0.19-0.57-0.01-0.85c0.26-0.42,0.5-0.84,0.78-1.25c0.21-0.31,0.2-0.63,0.04-0.93c-0.45-0.81-0.62-1.65-0.37-2.54
						c0.07-0.25,0.24-0.51,0.44-0.68c1.35-1.23,3.04-1.69,4.77-2.04c0.72-0.15,1.42-0.33,1.93-0.9
						C809.82,2449.14,809.87,2448.9,809.97,2448.67z M809.51,2479.8c0.09,0,0.18,0.02,0.26,0.01c0.77-0.07,1.55-0.19,2.32-0.21
						c0.33-0.01,0.5-0.12,0.66-0.36c0.77-1.15,1.03-2.48,1.29-3.79c0.18-0.91,0.23-1.84,0.31-2.77c0.05-0.56,0.05-1.12,0.07-1.67
						c0.03-0.75,0.06-1.51,0.07-2.26c0.02-0.93,0.03-1.86,0.03-2.79c0-0.91-0.05-1.82-0.05-2.74c0-0.91,0.02-1.83,0.03-2.74
						c0.01-0.94,0.02-1.88,0.01-2.81c-0.01-0.67-0.03-1.35-0.13-2.02c-0.15-1-0.41-1.97-0.87-2.9c-0.46-0.93-0.97-1.79-1.74-2.48
						c-0.36-0.32-0.73-0.64-1.05-0.92c-0.38,0.39-0.66,0.72-0.99,1c-0.79,0.67-1.83,0.59-2.75,0.9c-0.71,0.24-1.44,0.46-2.15,0.69
						c0.39,0.7,0.78,1.3,1.08,1.94c0.38,0.81,0.72,1.64,1,2.49c0.24,0.72,0.41,1.48,0.55,2.23c0.14,0.8,0.16,1.61,0.29,2.41
						c0.19,1.19,0.45,2.37,0.63,3.56c0.28,1.84,0.52,3.68,0.78,5.52c0.09,0.67,0.21,1.33,0.27,2c0.09,0.87,0.15,1.74,0.19,2.61
						c0.04,0.84,0.09,1.68,0.07,2.51C809.67,2478.05,809.57,2478.9,809.51,2479.8z M826.97,2451.76c-0.01,0.11-0.06,0.2-0.03,0.25
						c0.5,0.71,1.03,1.4,1.5,2.13c0.29,0.45,0.57,0.92,0.72,1.43c0.22,0.73,0.32,1.5,0.48,2.25c0.21,0.99,0,1.96-0.26,2.88
						c-0.3,1.09-0.76,2.13-1.16,3.19c-0.51,1.36-1.04,2.72-1.55,4.08c-0.31,0.84-0.6,1.7-0.91,2.55c-0.14,0.39-0.35,0.75-0.5,1.13
						c-0.26,0.68-0.47,1.39-0.74,2.06c-0.24,0.59-0.55,1.16-0.81,1.75c-0.46,1.02-0.84,2.08-1.38,3.06
						c-0.53,0.96-1.22,1.83-1.85,2.75c0.45,0.24,0.95,0.53,1.46,0.79c0.07,0.04,0.2-0.01,0.29-0.04c1.17-0.4,2.11-1.19,2.96-2.03
						c0.95-0.94,1.78-2.01,2.63-3.06c0.43-0.53,0.8-1.12,1.15-1.72c1.43-2.49,2.85-4.99,4.27-7.49c0.61-1.07,1.2-2.14,1.82-3.19
						c0.52-0.88,0.89-1.8,0.99-2.83c0.1-1.03-0.04-2.02-0.32-3c-0.19-0.68-0.47-1.34-0.74-1.99c-0.26-0.64-0.58-1.21-1.28-1.54
						c-1.08-0.51-2.11-1.14-3.18-1.69C829.36,2452.88,828.18,2452.34,826.97,2451.76z M813.64,2479.6c0.65,0.03,1.27,0.08,1.9,0.08
						c0.89,0,1.76-0.19,2.24-1.01c0.4-0.68,0.73-1.44,0.92-2.2c0.3-1.14,0.45-2.31,0.67-3.47c0.12-0.64,0.26-1.28,0.36-1.92
						c0.17-1.03,0.32-2.06,0.49-3.09c0.11-0.67,0.25-1.34,0.34-2.02c0.13-0.9,0.21-1.81,0.34-2.72c0.1-0.71,0.26-1.41,0.36-2.11
						c0.17-1.24,0.32-2.49,0.45-3.73c0.06-0.53,0.11-1.08,0.05-1.61c-0.11-1.06-0.57-1.97-1.21-2.85c-0.6-0.82-1.32-1.49-2.06-2.15
						c-0.41-0.36-0.94-0.59-1.32-0.98c-0.4-0.4-0.81-0.66-1.37-0.7c-0.41-0.03-0.81-0.05-1.22-0.06c-0.84-0.01-1.68-0.02-2.51-0.02
						c-0.09,0-0.17,0.04-0.23,0.06c0.33,0.36,0.63,0.69,0.94,1.01c0.91,0.91,1.51,2.01,1.9,3.21c0.26,0.78,0.49,1.58,0.58,2.38
						c0.1,0.9,0.02,1.83,0.02,2.74c0.01,1.11,0.02,2.21,0.03,3.32c0.01,0.78,0.01,1.57,0.01,2.35c0.01,1.08,0.02,2.16,0.02,3.24
						c0,0.97,0.01,1.95-0.02,2.92c-0.02,0.78-0.09,1.56-0.15,2.34c-0.05,0.6-0.1,1.21-0.19,1.81c-0.14,0.93-0.24,1.88-0.47,2.78
						C814.31,2478.02,813.94,2478.79,813.64,2479.6z M817.49,2480.15c0.43,0.12,0.78,0.17,1.08,0.31c0.86,0.38,1.27,0.26,1.84-0.51
						c0.98-1.32,1.66-2.8,2.34-4.29c0.13-0.29,0.29-0.57,0.41-0.87c0.33-0.84,0.63-1.68,0.96-2.52c0.26-0.68,0.54-1.35,0.8-2.02
						c0.4-1.07,0.79-2.14,1.19-3.2c0.28-0.73,0.58-1.46,0.84-2.19c0.26-0.71,0.44-1.46,0.73-2.16c0.52-1.23,1.07-2.45,1.15-3.81
						c0.05-0.83-0.01-1.66-0.3-2.43c-0.3-0.79-0.63-1.59-1.07-2.31c-0.49-0.8-1.08-1.55-1.7-2.27c-0.65-0.75-1.52-1.15-2.51-1.34
						c-1.22-0.24-2.43-0.61-3.64-0.9c-0.43-0.1-0.87-0.13-1.3-0.19c0.24,0.27,0.54,0.42,0.76,0.64c0.72,0.73,1.47,1.45,2.1,2.26
						c0.49,0.62,0.91,1.33,1.2,2.06c0.47,1.2,0.34,2.46,0.13,3.71c-0.11,0.66-0.19,1.32-0.28,1.98c-0.12,0.91-0.22,1.81-0.35,2.72
						c-0.1,0.71-0.25,1.4-0.36,2.11c-0.16,1.02-0.3,2.04-0.47,3.05c-0.11,0.67-0.24,1.34-0.35,2.01c-0.17,1.03-0.33,2.06-0.5,3.09
						c-0.11,0.67-0.19,1.35-0.32,2.02c-0.12,0.67-0.18,1.38-0.45,2C818.94,2478.18,818.57,2479.34,817.49,2480.15z M826.15,2484.87
						c0.44,0.4,0.91,0.78,1.3,1.22c0.33,0.38,0.7,0.33,1.04,0.16c0.79-0.39,1.58-0.79,2.32-1.25c1.08-0.68,2.14-1.39,3.17-2.13
						c1.17-0.84,2.3-1.73,3.43-2.61c0.77-0.6,1.53-1.21,2.29-1.84c1.11-0.91,2.2-1.83,3.3-2.74c0.3-0.25,0.6-0.49,0.88-0.75
						c0.45-0.41,0.91-0.8,1.31-1.24c0.65-0.72,0.91-1.64,1.16-2.54c0.19-0.69,0.25-1.43,0.31-2.15c0.06-0.72,0.06-1.44,0.05-2.17
						c0-0.28-0.03-0.6-0.18-0.83c-0.21-0.34-0.52-0.61-0.79-0.9c-0.57-0.6-1.12-1.21-1.71-1.78c-0.9-0.86-1.83-1.7-2.8-2.6
						c0.14,0.59,0.31,1.09,0.38,1.6c0.12,0.84,0.23,1.7,0.24,2.55c0.02,1.08-0.04,2.18-0.64,3.12c-0.62,0.97-1.32,1.89-2.01,2.82
						c-0.46,0.62-0.95,1.22-1.43,1.82c-1.01,1.26-2.02,2.53-3.05,3.78c-0.95,1.15-1.92,2.29-2.91,3.41
						c-0.65,0.73-1.34,1.42-2.02,2.12c-0.41,0.42-0.81,0.84-1.25,1.23c-0.35,0.31-0.75,0.58-1.13,0.85
						C827,2484.31,826.57,2484.59,826.15,2484.87z M847.65,2467.26c-0.06,0.91-0.1,1.7-0.16,2.5c-0.09,1.26-0.49,2.44-1,3.57
						c-0.39,0.86-1.04,1.53-1.8,2.11c-0.63,0.48-1.21,1.01-1.82,1.52c-0.76,0.62-1.52,1.24-2.28,1.86
						c-0.52,0.42-1.02,0.85-1.55,1.26c-0.81,0.62-1.63,1.22-2.44,1.85c-0.61,0.47-1.19,0.99-1.81,1.44
						c-0.95,0.68-1.93,1.32-2.9,1.97c-1.05,0.71-2.11,1.39-3.32,1.77c0.54,0.74,1.05,1.47,1.61,2.18c0.12,0.15,0.38,0.28,0.58,0.27
						c0.32-0.01,0.64-0.1,0.94-0.22c0.88-0.37,1.76-0.75,2.62-1.16c0.89-0.43,1.77-0.89,2.65-1.36c0.81-0.43,1.6-0.9,2.4-1.35
						c0.74-0.42,1.49-0.84,2.22-1.28c0.46-0.27,0.9-0.58,1.36-0.85c1.11-0.65,2.22-1.29,3.33-1.94c0.47-0.27,0.92-0.6,1.41-0.83
						c1.04-0.49,1.93-1.14,2.5-2.15c0.23-0.4,0.41-0.85,0.51-1.3c0.23-0.99,0.44-1.98,0.57-2.99c0.07-0.58,0.12-1.24-0.08-1.75
						c-0.31-0.78-0.84-1.48-1.31-2.2c-0.41-0.63-0.87-1.23-1.32-1.83C848.28,2467.99,847.98,2467.66,847.65,2467.26z
						 M813.83,2513.56c-0.14-0.07-0.19-0.1-0.24-0.12c-0.81-0.28-1.63-0.51-2.42-0.84c-0.53-0.22-0.95-0.15-1.36,0.2
						c-0.45,0.39-0.94,0.74-1.33,1.18c-0.85,0.96-1.69,1.94-2.45,2.97c-1.06,1.42-2.05,2.89-3.06,4.34
						c-0.98,1.42-1.96,2.84-2.93,4.26c-0.5,0.74-0.98,1.48-1.46,2.23c-0.09,0.14-0.17,0.28-0.25,0.42c0.06,0.06,0.09,0.1,0.13,0.12
						c2.3,1.42,4.57,2.91,7.22,3.66c0.77,0.22,1.51,0.53,2.25,0.81c0.25,0.1,0.39,0.03,0.42-0.22c0.04-0.26,0.04-0.53,0.05-0.8
						c0.08-1.37,0.16-2.74,0.25-4.11c0.04-0.55,0.11-1.09,0.19-1.63c0.13-0.92,0.21-1.86,0.44-2.75c0.27-1.01,0.66-2,1.05-2.98
						c0.37-0.93,0.79-1.84,1.24-2.74c0.44-0.89,0.95-1.75,1.43-2.62C813.25,2514.48,813.53,2514.05,813.83,2513.56z M853.8,2508.4
						c-0.1,0-0.18-0.03-0.25-0.01c-1.52,0.51-3.07,0.37-4.6,0.18c-1.2-0.15-2.39-0.45-3.57-0.75c-1.37-0.35-2.73-0.78-4.09-1.17
						c-0.47-0.14-0.94-0.29-1.42-0.41c-1.25-0.31-2.51-0.58-3.76-0.93c-0.94-0.26-1.86-0.59-2.76-0.97c-0.58-0.25-1.1-0.65-1.68-1
						c-0.16,0.48-0.27,0.89-0.45,1.27c-0.26,0.57-0.56,1.12-0.87,1.67c-0.12,0.21-0.11,0.36,0.08,0.51c0.48,0.37,0.93,0.79,1.44,1.1
						c0.94,0.56,1.91,1.07,2.88,1.59c1.62,0.88,3.24,1.76,4.88,2.61c1.16,0.6,2.35,1.16,3.52,1.73c0.83,0.4,1.68,0.77,2.5,1.19
						c1,0.51,2.04,0.79,3.15,0.61c1-0.16,1.9-0.55,2.38-1.52c0.53-1.07,1.05-2.16,1.55-3.25
						C853.13,2510.05,853.45,2509.23,853.8,2508.4z M830.93,2490.44c0.23,0.56,0.47,1.08,0.64,1.63c0.14,0.45,0.38,0.76,0.86,0.77
						c0.41,0.01,0.83-0.02,1.22-0.11c0.87-0.19,1.75-0.39,2.61-0.65c0.8-0.24,1.59-0.52,2.37-0.82c0.77-0.3,1.53-0.65,2.29-0.95
						c0.87-0.35,1.76-0.66,2.63-1.02c0.82-0.34,1.6-0.74,2.43-1.07c1.49-0.59,3.02-1.07,4.46-1.78c0.86-0.42,1.62-0.98,2.2-1.69
						c0.73-0.89,1.31-1.91,1.88-2.92c0.24-0.42,0.42-0.94,0.4-1.41c-0.02-0.51-0.29-1.01-0.49-1.5c-0.39-0.97-0.78-1.94-1.2-2.89
						c-0.31-0.7-0.69-1.37-1.04-2.05c-0.13,0.26-0.16,0.48-0.2,0.7c-0.18,0.96-0.27,1.94-0.57,2.86c-0.24,0.74-0.69,1.44-1.15,2.08
						c-0.31,0.43-0.78,0.76-1.22,1.08c-0.48,0.35-1,0.64-1.51,0.94c-0.49,0.29-0.99,0.57-1.48,0.87c-1.07,0.64-2.13,1.28-3.2,1.92
						c-0.48,0.29-0.95,0.6-1.44,0.88c-0.66,0.38-1.34,0.73-2,1.11c-0.85,0.48-1.68,1-2.54,1.46c-0.79,0.42-1.59,0.81-2.41,1.18
						c-0.81,0.37-1.63,0.71-2.46,1.04C831.67,2490.23,831.29,2490.33,830.93,2490.44z M835.95,2456.58
						c-0.02,0.02-0.04,0.04-0.07,0.07c0.07,0.15,0.17,0.28,0.21,0.44c0.26,1,0.56,2.01,0.76,3.02c0.21,1.06,0.11,2.14-0.28,3.14
						c-0.35,0.9-0.81,1.77-1.28,2.62c-1.14,2.05-2.27,4.11-3.48,6.11c-1.3,2.15-2.66,4.26-4.07,6.34c-0.52,0.78-1.2,1.47-1.88,2.11
						c-0.71,0.68-1.51,1.26-2.28,1.87c-0.17,0.14-0.38,0.23-0.67,0.4c0.79,0.48,1.48,0.91,2.18,1.32c0.13,0.07,0.32,0.09,0.47,0.05
						c0.86-0.25,1.54-0.78,2.2-1.36c0.39-0.34,0.76-0.72,1.13-1.09c0.81-0.8,1.62-1.59,2.4-2.41c0.36-0.37,0.65-0.81,0.99-1.2
						c0.47-0.54,0.99-1.04,1.45-1.58c0.43-0.51,0.8-1.08,1.22-1.6c0.59-0.72,1.22-1.4,1.8-2.13c0.5-0.62,0.92-1.3,1.41-1.92
						c0.61-0.78,1.29-1.51,1.89-2.3c0.53-0.7,0.7-1.54,0.87-2.39c0.19-0.94,0.1-1.86-0.01-2.78c-0.1-0.84-0.37-1.66-0.6-2.47
						c-0.12-0.44-0.25-0.91-0.52-1.25c-0.35-0.44-0.85-0.77-1.3-1.13c-0.71-0.55-1.43-1.08-2.15-1.61
						C836.23,2456.73,836.09,2456.66,835.95,2456.58z M832.5,2496.52c0.01,0.56,0.08,1.14,0.01,1.69c-0.08,0.6,0.37,0.69,0.69,0.77
						c1.13,0.26,2.29,0.46,3.44,0.66c0.88,0.15,1.76,0.28,2.64,0.37c1.28,0.13,2.57,0.2,3.86,0.31c1.12,0.09,2.24,0.22,3.35,0.3
						c1.59,0.13,3.18,0.22,4.77,0.35c1.09,0.09,2.13-0.01,3.04-0.69c0.27-0.21,0.53-0.44,0.79-0.66c0.72-0.62,1.27-1.35,1.38-2.34
						c0.03-0.28,0.08-0.56,0.1-0.84c0.06-0.82,0.13-1.65,0.17-2.48c0.03-0.72,0.04-1.44,0.04-2.16c0-0.55-0.04-1.1-0.06-1.6
						c-0.62,0.83-1.24,1.68-1.88,2.51c-0.47,0.61-1.11,0.98-1.86,1.16c-0.83,0.2-1.66,0.38-2.49,0.56
						c-0.34,0.07-0.69,0.12-1.04,0.19c-0.72,0.14-1.45,0.27-2.17,0.41c-1.02,0.2-2.05,0.42-3.07,0.61c-0.75,0.14-1.5,0.25-2.25,0.37
						c-0.86,0.13-1.71,0.28-2.57,0.39c-0.8,0.1-1.61,0.17-2.42,0.22c-0.89,0.05-1.78,0.09-2.67,0.08
						C833.7,2496.71,833.11,2496.59,832.5,2496.52z M832.08,2493.65c0.08,0.48,0.14,0.93,0.23,1.37c0.04,0.21,0.1,0.51,0.24,0.57
						c0.35,0.15,0.75,0.24,1.14,0.26c0.76,0.04,1.52,0.05,2.28,0.01c1.02-0.06,2.05-0.16,3.07-0.27c0.67-0.07,1.33-0.19,2-0.3
						c0.99-0.15,1.98-0.3,2.97-0.46c0.84-0.14,1.67-0.31,2.51-0.47c0.69-0.13,1.39-0.26,2.08-0.4c1.12-0.23,2.24-0.48,3.37-0.68
						c0.93-0.17,1.79-0.47,2.39-1.22c0.47-0.58,0.89-1.21,1.25-1.87c0.33-0.61,0.6-1.26,0.82-1.92c0.26-0.74-0.01-1.49-0.13-2.23
						c-0.14-0.9-0.31-1.8-0.5-2.69c-0.09-0.45-0.25-0.89-0.37-1.34c-0.04,0-0.08,0-0.12,0c-0.07,0.23-0.09,0.5-0.22,0.7
						c-0.49,0.74-0.85,1.55-1.5,2.22c-0.58,0.6-1.15,1.19-1.83,1.7c-0.83,0.62-1.76,0.99-2.71,1.35c-0.97,0.38-1.95,0.74-2.93,1.13
						c-0.81,0.32-1.6,0.67-2.4,1c-0.92,0.38-1.85,0.76-2.78,1.14c-0.74,0.3-1.46,0.62-2.21,0.88c-0.8,0.29-1.62,0.52-2.44,0.79
						C834.94,2493.38,833.57,2493.77,832.08,2493.65z M831.99,2502.48c0.35,0.28,0.68,0.63,1.09,0.83c0.69,0.33,1.42,0.56,2.14,0.82
						c0.64,0.22,1.28,0.42,1.93,0.62c0.43,0.13,0.86,0.26,1.29,0.36c1.12,0.27,2.25,0.5,3.37,0.79c1.22,0.31,2.42,0.7,3.64,1.01
						c1.16,0.29,2.34,0.5,3.52,0.76c1.17,0.26,2.35,0.3,3.52,0.11c0.91-0.15,1.82-0.29,2.03-1.53c0.21-1.23,0.66-2.41,0.99-3.62
						c0.2-0.76,0.37-1.54,0.53-2.31c0.02-0.1-0.02-0.22-0.03-0.3c-0.35,0.29-0.66,0.57-0.99,0.83c-0.86,0.68-1.85,1.04-2.93,1.02
						c-1.23-0.03-2.46-0.17-3.69-0.27c-0.77-0.06-1.54-0.14-2.31-0.2c-1.39-0.11-2.79-0.21-4.18-0.32
						c-0.53-0.04-1.06-0.11-1.59-0.16c-0.85-0.08-1.71-0.14-2.55-0.25c-0.73-0.09-1.46-0.23-2.19-0.36
						c-0.72-0.12-1.45-0.24-2.17-0.39c-0.32-0.06-0.62-0.18-0.94-0.28C832.3,2500.62,832.14,2501.53,831.99,2502.48z M826.41,2511.2
						c-0.67,0.44-1.34,0.88-2,1.35c-0.08,0.06-0.13,0.24-0.11,0.34c0.19,0.96,0.34,1.93,0.6,2.86c0.55,1.99,1.15,3.97,1.75,5.94
						c0.42,1.38,0.85,2.75,1.29,4.11c0.3,0.93,0.6,1.85,0.94,2.76c0.32,0.87,0.64,1.75,1.23,2.5c0.26,0.32,0.46,0.69,0.75,0.98
						c0.21,0.21,0.58,0.52,0.76,0.45c0.94-0.34,1.84-0.78,2.74-1.21c0.63-0.3,1.24-0.64,1.83-1c0.92-0.55,1.82-1.13,2.8-1.75
						c-0.19-0.1-0.28-0.14-0.37-0.19c-0.74-0.41-1.57-0.72-2.21-1.25c-0.65-0.54-1.14-1.29-1.64-1.98c-0.62-0.85-1.2-1.73-1.77-2.62
						c-0.69-1.09-1.35-2.2-2.03-3.29c-0.64-1.03-1.28-2.05-1.92-3.07C828.07,2514.54,827.12,2512.91,826.41,2511.2z M814.25,2534
						c0.14,0.01,0.23,0.01,0.31,0.02c0.7,0.09,1.39,0.22,2.09,0.26c1.01,0.06,2.03,0.06,3.05,0.06c0.81,0,1.63-0.02,2.44-0.04
						c0.29-0.01,0.38-0.17,0.31-0.45c-0.07-0.26-0.12-0.51-0.18-0.77c-0.27-1.29-0.54-2.59-0.79-3.89
						c-0.26-1.36-0.54-2.72-0.74-4.08c-0.14-0.93-0.16-1.88-0.25-2.81c-0.11-1.17-0.32-2.34-0.36-3.51
						c-0.04-1.54,0.04-3.08,0.07-4.61c-0.54,0.04-1.18,0.1-1.83,0.12c-0.28,0.01-0.41,0.14-0.56,0.36
						c-0.96,1.37-1.67,2.85-2.04,4.48c-0.21,0.92-0.33,1.87-0.44,2.81c-0.1,0.81-0.12,1.63-0.19,2.45c-0.09,0.89-0.22,1.77-0.3,2.66
						c-0.1,1.08-0.15,2.17-0.24,3.26C814.5,2531.54,814.37,2532.76,814.25,2534z M802.86,2477.97c1.22,0.74,2.37,1.41,3.49,2.14
						c0.36,0.24,0.69,0.13,1.04,0.11c0.22-0.01,0.43-0.16,0.65-0.15c0.53,0.01,0.63-0.36,0.67-0.74c0.06-0.56,0.08-1.14,0.09-1.71
						c0.01-1.13,0.06-2.27-0.03-3.4c-0.13-1.64-0.36-3.26-0.57-4.89c-0.2-1.53-0.43-3.06-0.66-4.59c-0.27-1.77-0.56-3.54-0.84-5.3
						c-0.14-0.87-0.23-1.76-0.47-2.61c-0.4-1.42-1.09-2.73-1.83-4c-0.23-0.4-0.46-0.4-0.75-0.04c-0.54,0.67-0.41,1.34-0.09,2.04
						c0.55,1.17,0.44,1.76-0.47,2.66c-0.36,0.35-0.32,0.65,0.13,0.91c0.42,0.24,0.85,0.5,1.3,0.68c0.96,0.39,0.99,1.51,0.56,2.17
						c-0.33,0.51-0.67,1.04-1.12,1.45c-0.5,0.46-1.1,0.83-1.71,1.16c-0.62,0.33-0.84,0.8-0.5,1.41c0.45,0.81,0.98,1.58,1.39,2.41
						c0.35,0.72,0.63,1.49,0.86,2.26c0.36,1.18-0.04,2.34-0.2,3.51c-0.08,0.59-0.09,1.2-0.07,1.8c0.02,0.61,0.18,1.22-0.2,1.77
						C803.34,2477.3,803.14,2477.58,802.86,2477.97z M828.01,2509.67c-0.91,0.62-1.01,0.74-0.67,1.54c0.41,0.96,0.92,1.88,1.43,2.79
						c0.38,0.69,0.81,1.35,1.22,2.01c0.59,0.96,1.18,1.91,1.78,2.86c0.31,0.49,0.63,0.97,0.94,1.46c0.46,0.72,0.91,1.44,1.37,2.15
						c0.46,0.72,0.92,1.45,1.41,2.15c0.41,0.58,0.8,1.21,1.34,1.65c0.68,0.56,1.48,0.96,2.26,1.38c0.22,0.12,0.57,0.16,0.79,0.07
						c0.35-0.15,0.68-0.42,0.96-0.68c1-0.93,1.98-1.86,2.96-2.81c0.47-0.46,0.89-0.95,1.41-1.5c-0.42-0.09-0.73-0.11-0.99-0.22
						c-0.58-0.25-1.17-0.48-1.68-0.83c-0.92-0.62-1.78-1.33-2.66-1.99c-0.84-0.63-1.67-1.26-2.52-1.87
						c-0.56-0.4-1.14-0.77-1.71-1.16c-0.39-0.27-0.78-0.53-1.15-0.83c-0.76-0.61-1.51-1.25-2.25-1.88
						c-0.76-0.64-1.57-1.25-2.26-1.95C829.27,2511.29,828.67,2510.47,828.01,2509.67z M787.19,2493.67
						c0.21,0.07,0.29,0.12,0.38,0.13c0.6,0.05,1.21,0.05,1.8,0.16c1.29,0.24,2.57,0.51,3.84,0.83c0.9,0.22,1.77,0.54,2.66,0.79
						c1.21,0.35,2.43,0.67,3.64,1.02c0.65,0.19,1.29,0.43,1.93,0.66c0.88,0.32,1.75,0.64,2.62,0.97c0.21,0.08,0.41,0.17,0.58,0.24
						c0.55-0.91,1.07-1.77,1.58-2.61c-0.57-0.48-1.08-0.95-1.63-1.37c-0.82-0.63-1.64-1.27-2.5-1.83c-2.44-1.6-4.84-3.26-7.54-4.42
						c-1-0.43-1.97-0.95-2.94-1.45c-0.25-0.13-0.38-0.09-0.52,0.13c-0.7,1.1-1.44,2.17-2.1,3.3
						C788.35,2491.34,787.8,2492.5,787.19,2493.67z M809.6,2511.79c-0.55-0.39-1.12-0.81-1.71-1.2c-0.16-0.11-0.41-0.2-0.59-0.15
						c-0.5,0.12-1.03,0.23-1.45,0.5c-0.99,0.65-1.92,1.39-2.86,2.11c-0.6,0.46-1.17,0.96-1.76,1.43c-1.17,0.95-2.35,1.88-3.51,2.83
						c-0.39,0.32-0.71,0.73-1.12,1.04c-0.83,0.62-1.49,1.38-1.92,2.3c-0.42,0.9-0.72,1.85-1.09,2.77c-0.16,0.39-0.12,0.65,0.23,0.93
						c0.41,0.32,0.77,0.71,1.12,1.1c0.71,0.79,1.49,1.48,2.37,2.07c0.25,0.17,0.4,0.18,0.58-0.11c0.59-0.95,1.2-1.89,1.83-2.81
						c0.72-1.06,1.46-2.1,2.2-3.15c0.93-1.33,1.83-2.66,2.78-3.98c0.83-1.15,1.69-2.26,2.54-3.39c0.12-0.16,0.22-0.34,0.36-0.47
						C808.26,2513.01,808.92,2512.41,809.6,2511.79z M823.37,2513.17c-0.58,0.22-1.2,0.45-1.81,0.7c-0.1,0.04-0.24,0.12-0.27,0.22
						c-0.37,1.16-0.4,2.36-0.38,3.55c0.02,1.31,0.15,2.63,0.28,3.93c0.12,1.24,0.3,2.48,0.47,3.71c0.16,1.14,0.31,2.28,0.53,3.41
						c0.34,1.71,0.75,3.41,1.11,5.12c0.07,0.32,0.26,0.39,0.51,0.38c0.4-0.01,0.8-0.03,1.18-0.1c1.23-0.21,2.47-0.42,3.69-0.68
						c0.6-0.12,1.19-0.34,1.83-0.52c-0.34-0.39-0.66-0.69-0.89-1.05c-0.43-0.67-0.91-1.34-1.19-2.07c-0.62-1.65-1.13-3.35-1.69-5.02
						c-0.27-0.82-0.57-1.64-0.82-2.47c-0.45-1.46-0.88-2.93-1.29-4.41C824.19,2516.3,823.79,2514.73,823.37,2513.17z
						 M829.74,2507.38c-0.34,0.47-0.65,0.86-0.91,1.29c-0.08,0.12-0.09,0.38-0.01,0.49c0.54,0.72,1.07,1.45,1.67,2.11
						c0.58,0.64,1.23,1.22,1.89,1.79c0.67,0.59,1.38,1.13,2.09,1.68c0.82,0.63,1.65,1.24,2.48,1.86c1.03,0.76,2.05,1.53,3.09,2.28
						c1.18,0.85,2.34,1.75,3.59,2.5c0.56,0.34,1.3,0.38,1.97,0.52c0.15,0.03,0.38-0.1,0.5-0.23c0.41-0.46,0.81-0.94,1.18-1.43
						c0.4-0.52,0.79-1.06,1.15-1.61c0.49-0.76,0.94-1.54,1.44-2.36c-0.94,0.3-1.86,0.4-2.75,0.15c-1.11-0.32-2.19-0.76-3.25-1.23
						c-1.21-0.53-2.39-1.15-3.57-1.74c-1.13-0.57-2.28-1.11-3.38-1.73c-2.04-1.16-4.05-2.37-6.06-3.56
						C830.47,2507.93,830.12,2507.65,829.74,2507.38z M806.9,2509.55c-0.3-0.45-0.62-0.94-0.97-1.41c-0.06-0.08-0.24-0.15-0.33-0.12
						c-0.88,0.24-1.76,0.47-2.62,0.76c-0.95,0.33-1.87,0.71-2.8,1.08c-1.05,0.42-2.1,0.84-3.16,1.25c-0.9,0.35-1.81,0.69-2.71,1.05
						c-0.59,0.24-1.24,0.4-1.71,0.79c-0.73,0.59-1.34,1.32-1.97,2.02c-0.44,0.49-0.85,1.03-1.24,1.56c-0.19,0.26-0.52,0.48-0.28,0.9
						c0.5,0.87,0.96,1.76,1.47,2.62c0.35,0.6,0.75,1.17,1.15,1.74c0.31,0.44,0.67,0.84,1.08,1.35c0.11-0.45,0.13-0.78,0.27-1.05
						c0.47-0.94,0.95-1.88,1.49-2.79c0.28-0.47,0.62-0.92,1.02-1.29c0.92-0.84,1.89-1.63,2.85-2.44c0.59-0.5,1.18-1.02,1.79-1.5
						c1.12-0.88,2.26-1.73,3.38-2.61c0.34-0.27,0.63-0.61,1.01-0.82C805.36,2510.25,806.16,2509.91,806.9,2509.55z M786.85,2494.58
						c-0.05,0.23-0.06,0.42-0.13,0.58c-0.36,0.78-0.56,1.59-0.76,2.42c-0.27,1.12-0.41,2.24-0.46,3.39c-0.04,0.8-0.06,0.8,0.65,0.6
						c0.79-0.23,1.57-0.49,2.37-0.66c0.73-0.15,1.48-0.23,2.23-0.28c0.7-0.04,1.41,0.03,2.12,0.03c0.86,0,1.72-0.03,2.58-0.02
						c1.14,0.02,2.27,0.05,3.41,0.11c1.62,0.08,3.24,0.19,4.86,0.26c0.11,0.01,0.29-0.14,0.33-0.26c0.12-0.37,0.21-0.75,0.27-1.13
						c0.03-0.21,0.02-0.44-0.27-0.54c-0.75-0.26-1.49-0.57-2.24-0.82c-1.31-0.42-2.62-0.83-3.94-1.21
						c-1.07-0.31-2.15-0.58-3.22-0.89c-0.89-0.25-1.77-0.57-2.67-0.79c-1.03-0.25-2.07-0.46-3.11-0.63
						C788.2,2494.64,787.52,2494.64,786.85,2494.58z M788.55,2516.25c0.59-0.73,1.13-1.42,1.7-2.09c0.53-0.62,1.09-1.21,1.63-1.81
						c0.04-0.04,0.09-0.07,0.14-0.1c0.65-0.3,1.28-0.62,1.95-0.89c1.08-0.43,2.17-0.82,3.25-1.24c1.51-0.58,3-1.2,4.52-1.77
						c1.05-0.39,2.12-0.71,3.17-1.07c0.26-0.09,0.4-0.21,0.22-0.54c-0.22-0.41-0.36-0.86-0.55-1.29c-0.11-0.26-0.23-0.49-0.59-0.46
						c-0.47,0.04-0.94,0.01-1.41,0.07c-1.03,0.14-2.06,0.33-3.08,0.5c-1.09,0.18-2.18,0.33-3.27,0.54
						c-1.48,0.29-2.95,0.62-4.42,0.94c-1.1,0.24-2.09,0.73-2.97,1.42c-0.56,0.44-1.1,0.9-1.68,1.32c-0.75,0.55-0.78,0.64-0.49,1.44
						c0.45,1.25,0.86,2.51,1.31,3.76C788.14,2515.43,788.36,2515.84,788.55,2516.25z M806.74,2495.06c0.32-0.31,0.63-0.6,0.93-0.91
						c0.27-0.28,0.6-0.55,0.27-1.02c-0.75-1.06-1.45-2.15-2.23-3.18c-0.63-0.83-1.32-1.61-2.02-2.37c-0.86-0.93-1.76-1.83-2.66-2.73
						c-0.72-0.72-1.47-1.42-2.21-2.12c-0.38-0.36-0.76-0.73-1.16-1.07c-0.22-0.2-0.47-0.36-0.69-0.52
						c-1.94,1.36-3.59,2.94-5.06,4.77c0.06,0.05,0.13,0.14,0.22,0.18c0.48,0.22,0.98,0.4,1.45,0.63c1.16,0.57,2.34,1.11,3.45,1.77
						c1.76,1.06,3.48,2.2,5.2,3.34c0.93,0.62,1.82,1.32,2.73,1.97C805.54,2494.23,806.14,2494.64,806.74,2495.06z M804.13,2504.08
						c-0.06-0.57-0.2-1.2-0.17-1.82c0.02-0.4-0.18-0.42-0.41-0.43c-1.06-0.06-2.11-0.1-3.17-0.14c-1.47-0.05-2.94-0.08-4.41-0.12
						c-1.55-0.04-3.1-0.08-4.64-0.11c-0.47-0.01-0.95-0.04-1.41,0.02c-0.72,0.11-1.45,0.25-2.15,0.45
						c-0.69,0.19-1.37,0.45-2.03,0.73c-0.15,0.06-0.26,0.35-0.28,0.54c-0.04,0.3,0,0.62,0.03,0.92c0.1,1,0.19,1.99,0.32,2.99
						c0.07,0.55,0.19,1.1,0.29,1.65c0.04,0.2,0.11,0.4,0.16,0.59c0.82-0.61,1.56-1.17,2.31-1.71c0.73-0.53,1.51-1,2.39-1.23
						c1.28-0.34,2.57-0.63,3.87-0.91c1.06-0.23,2.13-0.45,3.2-0.64c0.88-0.15,1.77-0.26,2.66-0.37
						C801.83,2504.35,802.99,2504.21,804.13,2504.08z M817.02,2514.23c-0.58-0.14-1.11-0.28-1.66-0.38
						c-0.73-0.13-0.76-0.13-1.13,0.55c-0.4,0.72-0.74,1.47-1.14,2.18c-0.9,1.61-1.7,3.25-2.27,5.01c-0.23,0.7-0.58,1.37-0.74,2.09
						c-0.26,1.17-0.42,2.37-0.59,3.56c-0.11,0.76-0.16,1.54-0.2,2.31c-0.06,1.01-0.07,2.03-0.13,3.05c-0.02,0.3,0.02,0.49,0.35,0.56
						c0.83,0.18,1.65,0.41,2.48,0.58c0.48,0.1,0.97,0.11,1.43,0.16c0.05-0.44,0.09-0.85,0.14-1.26c0.06-0.59,0.12-1.18,0.18-1.77
						c0.07-0.85,0.14-1.7,0.21-2.55c0.05-0.62,0.1-1.25,0.15-1.87c0.08-1,0.15-2,0.25-2.99c0.06-0.62,0.22-1.23,0.23-1.85
						c0.03-1.4,0.32-2.75,0.79-4.04c0.39-1.06,0.99-2.05,1.5-3.07C816.9,2514.4,816.96,2514.33,817.02,2514.23z M808.88,2493
						c0.48-0.29,0.91-0.53,1.32-0.8c0.07-0.05,0.12-0.19,0.12-0.28c0.01-0.94-0.24-1.83-0.49-2.73c-0.36-1.33-0.83-2.6-1.42-3.84
						c-0.6-1.26-1.14-2.55-1.72-3.82c-0.09-0.19-0.2-0.42-0.37-0.53c-1.25-0.82-2.53-1.61-3.79-2.43c-0.25-0.16-0.46-0.14-0.72-0.02
						c-0.6,0.28-1.22,0.49-1.81,0.79c-0.75,0.38-1.47,0.81-2.22,1.22c0.17,0.24,0.26,0.41,0.39,0.52c0.6,0.54,1.24,1.05,1.83,1.61
						c0.87,0.84,1.72,1.7,2.56,2.56c0.58,0.59,1.16,1.19,1.72,1.81c0.82,0.91,1.63,1.82,2.41,2.77c0.43,0.52,0.78,1.1,1.16,1.65
						C808.21,2491.99,808.55,2492.51,808.88,2493z M817.69,2481.04c-0.26-0.06-0.53-0.13-0.8-0.19c-0.44-0.1-0.87-0.23-1.31-0.27
						c-0.87-0.08-1.74-0.08-2.61-0.18c-0.42-0.04-0.4,0.17-0.36,0.42c0.07,0.41,0.2,0.8,0.27,1.21c0.14,0.75,0.27,1.5,0.38,2.26
						c0.13,0.89,0.27,1.78,0.35,2.67c0.11,1.36,0.18,2.73,0.26,4.13c0.49,0.03,1,0.07,1.51,0.08c0.06,0,0.16-0.1,0.19-0.17
						c0.33-0.75,0.71-1.48,0.94-2.25c0.28-0.9,0.44-1.84,0.62-2.77c0.13-0.65,0.25-1.31,0.32-1.97
						C817.56,2483.05,817.61,2482.1,817.69,2481.04z M807.4,2480.99c1.62,3.51,3.37,6.87,3.82,10.67c0.53-0.14,1.07-0.28,1.59-0.45
						c0.08-0.02,0.17-0.18,0.17-0.28c0-0.62,0.01-1.24-0.04-1.85c-0.1-1.21-0.19-2.43-0.37-3.63c-0.24-1.67-0.56-3.32-0.86-5.05
						C810.27,2480.6,808.87,2480.79,807.4,2480.99z M818.3,2492.11c0.11-0.16,0.24-0.3,0.32-0.46c0.44-0.85,0.87-1.7,1.29-2.56
						c0.48-1,0.94-2,1.4-3.01c0.32-0.72,0.62-1.45,0.92-2.17c0.09-0.21,0.18-0.41,0.3-0.69c-1.34-0.64-2.65-1.26-3.99-1.89
						c-0.04,0.17-0.06,0.23-0.07,0.29c-0.09,0.92-0.15,1.83-0.27,2.75c-0.08,0.64-0.26,1.28-0.32,1.92c-0.1,1.18-0.47,2.29-0.82,3.4
						c-0.18,0.57-0.49,1.1-0.7,1.57C817.01,2491.55,817.63,2491.82,818.3,2492.11z M823.21,2483.67c-0.27,0.76-0.48,1.42-0.74,2.05
						c-0.39,0.94-0.8,1.87-1.25,2.78c-0.68,1.36-1.4,2.7-2.11,4.05c-0.01,0.01-0.01,0.03-0.02,0.1c0.29,0.21,0.59,0.44,0.91,0.65
						c0.06,0.04,0.19,0.02,0.24-0.02c0.63-0.49,1.32-0.91,1.86-1.49c0.97-1.05,1.87-2.18,2.76-3.3c0.46-0.59,0.84-1.24,1.26-1.86
						c0.09-0.13,0.17-0.27,0.27-0.45C825.32,2485.33,824.29,2484.52,823.21,2483.67z M812.74,2504.43c0.23-0.97,0.44-1.9,0.66-2.85
						c0.39,0.25,0.42-0.14,0.58-0.28c0.54,0.05,1.07,0.1,1.59,0.02c0.39-0.06,0.84-0.2,1.13-0.45c0.63-0.54,0.67-2.04,0.15-2.7
						c-0.41-0.52-0.86-0.94-1.57-0.98c-0.81-0.05-1.57,0.09-2.2,0.58c-0.8,0.62-1.18,1.54-1.45,2.49c-0.02,0.06-0.03,0.12-0.02,0.17
						c0.05,0.64,0.05,1.29,0.15,1.92C811.87,2503.11,812.05,2503.87,812.74,2504.43z M820.74,2494.02c0.26,0.33,0.48,0.61,0.71,0.88
						c0.19,0.23,0.38,0.28,0.69,0.14c1.18-0.51,2.14-1.32,3.09-2.15c0.7-0.61,1.34-1.28,1.98-1.95c0.51-0.53,0.98-1.1,1.47-1.66
						c0.14-0.16,0.28-0.29,0.05-0.54c-0.44-0.46-0.82-0.97-1.23-1.46c-0.16-0.19-0.33-0.36-0.54-0.57
						C825.25,2489.49,823.41,2492.07,820.74,2494.02z M822.46,2496.5c0.19,0.02,0.39,0.07,0.58,0.04c1.62-0.24,3.12-0.85,4.62-1.47
						c1.03-0.43,2.01-0.99,3.02-1.48c0.51-0.25,0.53-0.29,0.27-0.78c-0.04-0.07-0.09-0.13-0.1-0.2c-0.21-1.07-0.85-1.93-1.35-2.87
						c-0.56,0.57-1.12,1.06-1.58,1.63c-0.87,1.06-1.89,1.96-2.93,2.84c-0.75,0.63-1.52,1.2-2.44,1.56
						c-0.23,0.09-0.47,0.24-0.15,0.53C822.44,2496.34,822.44,2496.44,822.46,2496.5z M831.37,2494.31
						c-0.22,0.07-0.37,0.09-0.51,0.15c-1.24,0.56-2.47,1.14-3.71,1.7c-1.31,0.59-2.65,1.04-4.08,1.22
						c-0.07,0.01-0.19,0.16-0.19,0.23c0.03,0.5,0.42,0.83,0.94,0.85c1.27,0.06,2.54,0.02,3.78-0.25c1.29-0.28,2.56-0.62,3.84-0.94
						c0.09-0.02,0.23-0.13,0.23-0.18C831.59,2496.17,831.48,2495.26,831.37,2494.31z M831.71,2498.17c-2.86,0.68-5.61,1.5-8.53,1.01
						c0,0.37-0.01,0.76-0.01,1.17c0.06,0,0.13-0.01,0.18,0c1.37,0.38,2.78,0.57,4.2,0.64c1.07,0.05,2.15,0.16,3.22,0.23
						c0.4,0.03,0.66-0.11,0.69-0.58C831.52,2499.83,831.62,2499.03,831.71,2498.17z M818.14,2506.77c0.2,0.77,0.4,1.54,0.61,2.3
						c0.35,1.28,0.75,2.54,1.06,3.83c0.11,0.45,0.31,0.44,0.63,0.37c1-0.24,1.99-0.53,2.86-1.1c-0.07-0.14-0.13-0.3-0.22-0.42
						c-0.8-1.02-1.62-2.04-2.42-3.06c-0.37-0.48-0.74-0.96-1.06-1.48c-0.54-0.88-0.58-0.95-1.34-0.48
						C818.24,2506.73,818.22,2506.73,818.14,2506.77z M831.21,2502.07c-2.83-0.14-5.59-0.22-8.29-0.92
						c-0.08,0.21-0.15,0.41-0.23,0.64c0.14,0.09,0.27,0.17,0.4,0.24c1.21,0.66,2.46,1.21,3.79,1.6c0.82,0.24,1.61,0.59,2.41,0.9
						c0.87,0.33,0.9,0.34,1.24-0.51C830.78,2503.42,830.96,2502.8,831.21,2502.07z M809.2,2510.44c0.56-0.93,1.1-1.78,1.61-2.65
						c0.44-0.75,0.85-1.53,1.28-2.28c0.16-0.28,0.04-0.47-0.18-0.6c-0.13-0.08-0.35-0.13-0.46-0.07c-0.61,0.36-1.2,0.77-1.8,1.14
						c-0.97,0.59-1.95,1.15-2.97,1.76C807.28,2508.78,808.12,2509.68,809.2,2510.44z M814.56,2491.94
						c-0.58,0.05-1.08,0.08-1.58,0.14c-0.84,0.11-1.62,0.42-2.35,0.85c-0.1,0.06-0.18,0.38-0.11,0.48c0.4,0.57,0.84,1.11,1.28,1.64
						c0.37,0.45,0.74,0.89,1.13,1.32c0.11,0.12,0.3,0.27,0.42,0.24c0.44-0.08,0.87-0.22,1.3-0.34
						C814.63,2494.86,814.59,2493.44,814.56,2491.94z M821.42,2504.32c-0.17,0.21-0.34,0.43-0.6,0.75c1.84,1.72,3.65,3.41,5.47,5.11
						c0.62-0.67,1.17-1.27,1.71-1.86C825.78,2506.97,823.61,2505.65,821.42,2504.32z M829.89,2505.57c-0.41-0.11-0.77-0.18-1.11-0.3
						c-0.99-0.34-2-0.65-2.97-1.05c-1.06-0.44-2.09-0.96-3.13-1.45c-0.09-0.04-0.19-0.07-0.3-0.12c-0.15,0.29-0.28,0.56-0.48,0.94
						c0.84,0.51,1.65,1.01,2.48,1.5c1.27,0.76,2.55,1.52,3.83,2.28c0.19,0.11,0.41,0.28,0.59-0.01
						C829.15,2506.8,829.49,2506.22,829.89,2505.57z M810.78,2500.16c0.08-0.2,0.13-0.29,0.15-0.4c0.09-0.41,0.23-0.84-0.25-1.13
						c-1.23-0.74-2.45-1.49-3.76-2.28c-0.17,0.21-0.44,0.49-0.63,0.82c-0.28,0.5-0.46,1.05-0.75,1.54c-0.24,0.4-0.07,0.58,0.27,0.66
						c0.57,0.14,1.15,0.24,1.73,0.33C808.62,2499.87,809.7,2500.01,810.78,2500.16z M810.77,2501.99c-0.01-0.2-0.02-0.32-0.02-0.43
						c-0.01-0.5-0.17-0.62-0.63-0.67c-1.3-0.16-2.6-0.34-3.89-0.53c-0.39-0.06-0.77-0.16-1.27-0.27c-0.05,0.94-0.13,1.82-0.15,2.71
						c-0.01,0.64,0.11,0.71,0.73,0.55C807.29,2502.89,809.03,2502.43,810.77,2501.99z M806.15,2507.01
						c0.98-0.54,1.92-1.04,2.84-1.58c0.66-0.39,1.3-0.82,1.94-1.25c0.14-0.1,0.31-0.31,0.29-0.45c-0.04-0.31-0.18-0.61-0.29-0.93
						c-1.95,0.52-3.88,1.04-5.89,1.58C805.42,2505.26,805.77,2506.1,806.15,2507.01z M817.87,2501.03c-0.52,0.38-0.96,0.7-1.43,1.04
						c0.72,1.17,1.45,2.36,2.2,3.57c0.87-0.51,1.58-1.09,2.07-1.91c0.14-0.24,0.32-0.45,0.48-0.68c-0.25-0.15-0.5-0.31-0.75-0.46
						C819.57,2502.06,818.69,2501.53,817.87,2501.03z M819.33,2501.05c-0.01,0.02-0.02,0.05-0.03,0.07
						c0.69,0.31,1.38,0.62,2.06,0.94c0.21,0.1,0.34,0.08,0.4-0.18c0.08-0.3,0.15-0.61,0.27-0.88c0.36-0.78,0.31-1.59,0.24-2.41
						c-0.03-0.34-0.22-0.46-0.56-0.36c-1.13,0.32-2.27,0.61-3.4,0.94c-0.12,0.04-0.22,0.23-0.28,0.38c-0.04,0.1,0.03,0.24,0,0.34
						c-0.07,0.25,0,0.39,0.23,0.52C818.63,2500.61,818.98,2500.84,819.33,2501.05z M815.43,2492.09c0.01,1.19,0,2.4,0.06,3.61
						c0.01,0.21-0.17,0.59,0.27,0.71c0.34,0.09,0.66,0.31,0.98,0c0.78-0.75,1.56-1.51,2.34-2.27c0.21-0.21,0.24-0.39-0.05-0.59
						C817.92,2492.81,816.8,2492.17,815.43,2492.09z M817.79,2505.91c-0.25-0.42-0.45-0.78-0.67-1.14
						c-0.47-0.78-0.93-1.56-1.42-2.33c-0.08-0.12-0.29-0.23-0.44-0.24c-0.37-0.02-0.75,0.01-1.16,0.02
						c-0.21,0.93-0.43,1.91-0.66,2.93C814.84,2505.76,816.22,2506.22,817.79,2505.91z M819.99,2494.45
						c-0.91,0.85-1.82,1.7-2.72,2.55c-0.05,0.05-0.07,0.21-0.04,0.28c0.18,0.33,0.37,0.66,0.58,0.98c0.05,0.08,0.21,0.17,0.29,0.15
						c1.19-0.33,2.38-0.67,3.56-1.03c0.07-0.02,0.18-0.22,0.15-0.27c-0.25-0.46-0.52-0.93-0.82-1.36
						C820.7,2495.31,820.36,2494.92,819.99,2494.45z M807.48,2495.48c1.42,1,2.71,1.9,3.97,2.78c0.38-0.43,0.69-0.78,1.01-1.13
						c-0.93-1.13-1.9-2.32-2.91-3.55C808.87,2494.2,808.22,2494.8,807.48,2495.48z M812.33,2512.18c0.16-0.88,0.31-1.7,0.45-2.53
						c0.17-1.06,0.29-2.13,0.51-3.18c0.08-0.4-0.05-0.51-0.28-0.6c-1.06,1.74-2.09,3.44-3.17,5.21
						C810.72,2511.47,811.49,2511.81,812.33,2512.18z M820.21,2505.64c-0.49,0.28-0.49,0.31-0.05,0.87c0.08,0.11,0.19,0.2,0.28,0.31
						c0.78,1.07,1.56,2.14,2.34,3.21c0.29,0.4,0.56,0.81,0.89,1.17c0.17,0.18,0.3,0.6,0.63,0.41c0.47-0.26,0.88-0.62,1.28-0.92
						C823.75,2508.97,821.99,2507.32,820.21,2505.64z M819.03,2513.46c-0.05-0.23-0.06-0.36-0.1-0.48c-0.2-0.73-0.42-1.45-0.61-2.17
						c-0.32-1.22-0.61-2.45-0.93-3.67c-0.03-0.12-0.22-0.2-0.4-0.35c-0.05,0.95-0.13,1.77-0.12,2.58c0.02,1.22,0.1,2.43,0.17,3.65
						c0.01,0.12,0.13,0.33,0.22,0.34C817.83,2513.41,818.4,2513.42,819.03,2513.46z M814.1,2506.31c-0.32,2.07-0.62,4.07-0.94,6.15
						c0.42,0.12,0.83,0.24,1.27,0.36c0.04-2.13,0.08-4.2,0.12-6.29C814.45,2506.48,814.33,2506.42,814.1,2506.31z M816.18,2513.13
						c-0.03-0.94-0.09-1.84-0.1-2.74c-0.01-0.94,0-1.89,0.02-2.83c0.01-0.63,0.18-0.72-0.68-0.83c-0.02,0.14-0.07,0.29-0.07,0.44
						c-0.02,1.63-0.02,3.26-0.06,4.89C815.29,2512.91,815.28,2512.94,816.18,2513.13z"/>
					<path className="st12" d="M826.97,2451.76c1.21,0.58,2.39,1.12,3.56,1.71c1.07,0.54,2.1,1.17,3.18,1.69
						c0.69,0.33,1.02,0.9,1.28,1.54c0.27,0.66,0.55,1.31,0.74,1.99c0.28,0.97,0.42,1.97,0.32,3c-0.1,1.03-0.47,1.95-0.99,2.83
						c-0.62,1.05-1.22,2.13-1.82,3.19c-1.42,2.5-2.83,5-4.27,7.49c-0.34,0.6-0.72,1.19-1.15,1.72c-0.84,1.05-1.67,2.12-2.63,3.06
						c-0.85,0.84-1.79,1.62-2.96,2.03c-0.09,0.03-0.22,0.07-0.29,0.04c-0.51-0.26-1.02-0.55-1.46-0.79
						c0.63-0.92,1.32-1.79,1.85-2.75c0.54-0.97,0.92-2.04,1.38-3.06c0.26-0.59,0.57-1.15,0.81-1.75c0.27-0.68,0.48-1.38,0.74-2.06
						c0.15-0.38,0.36-0.74,0.5-1.13c0.32-0.84,0.6-1.7,0.91-2.55c0.51-1.36,1.04-2.72,1.55-4.08c0.4-1.06,0.86-2.1,1.16-3.19
						c0.26-0.93,0.47-1.9,0.26-2.88c-0.16-0.75-0.26-1.52-0.48-2.25c-0.15-0.5-0.43-0.98-0.72-1.43c-0.47-0.73-1-1.42-1.5-2.13
						C826.91,2451.97,826.96,2451.87,826.97,2451.76z"/>
					<path className="st9" d="M813.64,2479.6c0.31-0.81,0.67-1.58,0.88-2.38c0.23-0.91,0.34-1.85,0.47-2.78c0.09-0.6,0.14-1.2,0.19-1.81
						c0.06-0.78,0.13-1.56,0.15-2.34c0.03-0.97,0.02-1.95,0.02-2.92c0-1.08-0.01-2.16-0.02-3.24c0-0.78-0.01-1.57-0.01-2.35
						c-0.01-1.11-0.02-2.21-0.03-3.32c0-0.92,0.08-1.84-0.02-2.74c-0.09-0.81-0.33-1.61-0.58-2.38c-0.4-1.2-0.99-2.3-1.9-3.21
						c-0.32-0.31-0.61-0.65-0.94-1.01c0.06-0.02,0.15-0.06,0.23-0.06c0.84,0,1.68,0,2.51,0.02c0.41,0.01,0.82,0.03,1.22,0.06
						c0.55,0.04,0.97,0.29,1.37,0.7c0.38,0.39,0.91,0.62,1.32,0.98c0.74,0.66,1.46,1.32,2.06,2.15c0.64,0.88,1.1,1.78,1.21,2.85
						c0.05,0.53,0.01,1.08-0.05,1.61c-0.13,1.25-0.28,2.49-0.45,3.73c-0.1,0.71-0.26,1.41-0.36,2.11c-0.13,0.9-0.22,1.81-0.34,2.72
						c-0.1,0.68-0.23,1.34-0.34,2.02c-0.17,1.03-0.32,2.06-0.49,3.09c-0.11,0.64-0.24,1.28-0.36,1.92
						c-0.22,1.16-0.37,2.33-0.67,3.47c-0.2,0.76-0.53,1.52-0.92,2.2c-0.48,0.82-1.34,1.02-2.24,1.01
						C814.91,2479.68,814.28,2479.63,813.64,2479.6z"/>
					<path className="st12" d="M817.49,2480.15c1.09-0.81,1.46-1.98,1.93-3.06c0.27-0.62,0.33-1.33,0.45-2
						c0.12-0.67,0.21-1.35,0.32-2.02c0.16-1.03,0.33-2.06,0.5-3.09c0.11-0.67,0.25-1.34,0.35-2.01c0.16-1.02,0.3-2.04,0.47-3.05
						c0.11-0.7,0.26-1.4,0.36-2.11c0.13-0.9,0.23-1.81,0.35-2.72c0.09-0.66,0.17-1.32,0.28-1.98c0.2-1.25,0.33-2.51-0.13-3.71
						c-0.29-0.73-0.71-1.44-1.2-2.06c-0.63-0.81-1.38-1.53-2.1-2.26c-0.22-0.22-0.52-0.36-0.76-0.64c0.43,0.06,0.88,0.09,1.3,0.19
						c1.22,0.29,2.42,0.66,3.64,0.9c0.99,0.2,1.86,0.59,2.51,1.34c0.62,0.71,1.21,1.46,1.7,2.27c0.44,0.72,0.77,1.52,1.07,2.31
						c0.29,0.77,0.35,1.6,0.3,2.43c-0.08,1.36-0.63,2.58-1.15,3.81c-0.29,0.7-0.47,1.44-0.73,2.16c-0.27,0.74-0.57,1.46-0.84,2.19
						c-0.4,1.07-0.79,2.14-1.19,3.2c-0.26,0.68-0.54,1.35-0.8,2.02c-0.32,0.84-0.63,1.69-0.96,2.52c-0.12,0.3-0.28,0.58-0.41,0.87
						c-0.68,1.48-1.35,2.96-2.34,4.29c-0.57,0.77-0.98,0.89-1.84,0.51C818.26,2480.32,817.92,2480.27,817.49,2480.15z"/>
					<path className="st11" d="M826.15,2484.87c0.41-0.28,0.84-0.55,1.26-0.85c0.39-0.27,0.78-0.54,1.13-0.85
						c0.44-0.39,0.84-0.81,1.25-1.23c0.68-0.7,1.37-1.39,2.02-2.12c0.99-1.12,1.96-2.26,2.91-3.41c1.03-1.25,2.04-2.52,3.05-3.78
						c0.48-0.6,0.97-1.2,1.43-1.82c0.69-0.93,1.39-1.85,2.01-2.82c0.6-0.94,0.66-2.04,0.64-3.12c-0.01-0.85-0.12-1.7-0.24-2.55
						c-0.07-0.52-0.24-1.02-0.38-1.6c0.97,0.9,1.9,1.73,2.8,2.6c0.59,0.57,1.15,1.18,1.71,1.78c0.28,0.29,0.59,0.57,0.79,0.9
						c0.14,0.23,0.17,0.55,0.18,0.83c0.01,0.72,0.01,1.45-0.05,2.17c-0.06,0.72-0.12,1.46-0.31,2.15c-0.25,0.9-0.51,1.82-1.16,2.54
						c-0.4,0.45-0.87,0.84-1.31,1.24c-0.29,0.26-0.59,0.5-0.88,0.75c-1.1,0.92-2.2,1.83-3.3,2.74c-0.76,0.62-1.52,1.24-2.29,1.84
						c-1.14,0.88-2.27,1.77-3.43,2.61c-1.03,0.75-2.09,1.46-3.17,2.13c-0.74,0.47-1.53,0.87-2.32,1.25
						c-0.35,0.17-0.71,0.22-1.04-0.16C827.07,2485.64,826.59,2485.27,826.15,2484.87z"/>
					<path className="st11" d="M847.65,2467.26c0.34,0.41,0.63,0.73,0.9,1.08c0.45,0.6,0.9,1.21,1.32,1.83c0.47,0.71,1,1.42,1.31,2.2
						c0.21,0.52,0.16,1.18,0.08,1.75c-0.13,1-0.34,2-0.57,2.99c-0.1,0.45-0.29,0.9-0.51,1.3c-0.57,1.01-1.46,1.67-2.5,2.15
						c-0.49,0.23-0.94,0.55-1.41,0.83c-1.11,0.65-2.22,1.29-3.33,1.94c-0.46,0.27-0.9,0.58-1.36,0.85
						c-0.73,0.43-1.48,0.85-2.22,1.28c-0.8,0.45-1.59,0.92-2.4,1.35c-0.87,0.47-1.75,0.93-2.65,1.36c-0.86,0.41-1.74,0.79-2.62,1.16
						c-0.3,0.12-0.62,0.21-0.94,0.22c-0.19,0.01-0.46-0.12-0.58-0.27c-0.55-0.7-1.07-1.44-1.61-2.18c1.21-0.38,2.27-1.07,3.32-1.77
						c0.97-0.65,1.95-1.29,2.9-1.97c0.63-0.45,1.2-0.97,1.81-1.44c0.81-0.62,1.63-1.22,2.44-1.85c0.53-0.41,1.03-0.84,1.55-1.26
						c0.76-0.62,1.52-1.23,2.28-1.86c0.61-0.5,1.19-1.04,1.82-1.52c0.76-0.58,1.4-1.25,1.8-2.11c0.52-1.13,0.91-2.31,1-3.57
						C847.54,2468.96,847.59,2468.17,847.65,2467.26z"/>
					<path className="st12" d="M813.83,2513.56c-0.29,0.48-0.57,0.92-0.83,1.37c-0.49,0.87-0.99,1.73-1.43,2.62
						c-0.45,0.9-0.86,1.81-1.24,2.74c-0.39,0.98-0.78,1.96-1.05,2.98c-0.24,0.89-0.31,1.83-0.44,2.75
						c-0.08,0.54-0.15,1.09-0.19,1.63c-0.1,1.37-0.17,2.74-0.25,4.11c-0.02,0.27-0.02,0.53-0.05,0.8c-0.03,0.25-0.16,0.32-0.42,0.22
						c-0.75-0.29-1.49-0.59-2.25-0.81c-2.65-0.75-4.91-2.24-7.22-3.66c-0.04-0.02-0.06-0.06-0.13-0.12
						c0.08-0.14,0.16-0.28,0.25-0.42c0.49-0.74,0.96-1.49,1.46-2.23c0.97-1.43,1.95-2.85,2.93-4.26c1.01-1.45,2-2.92,3.06-4.34
						c0.77-1.03,1.6-2.01,2.45-2.97c0.39-0.44,0.88-0.79,1.33-1.18c0.41-0.35,0.83-0.42,1.36-0.2c0.79,0.33,1.61,0.57,2.42,0.84
						C813.64,2513.46,813.69,2513.49,813.83,2513.56z"/>
					<path className="st12" d="M853.8,2508.4c-0.35,0.83-0.67,1.65-1.04,2.46c-0.5,1.09-1.02,2.17-1.55,3.25
						c-0.48,0.98-1.38,1.36-2.38,1.52c-1.12,0.17-2.16-0.1-3.15-0.61c-0.82-0.42-1.67-0.79-2.5-1.19c-1.18-0.57-2.36-1.13-3.52-1.73
						c-1.64-0.85-3.25-1.74-4.88-2.61c-0.96-0.52-1.94-1.02-2.88-1.59c-0.51-0.31-0.96-0.73-1.44-1.1c-0.19-0.15-0.2-0.29-0.08-0.51
						c0.31-0.55,0.61-1.1,0.87-1.67c0.18-0.38,0.28-0.79,0.45-1.27c0.58,0.35,1.1,0.75,1.68,1c0.89,0.38,1.82,0.7,2.76,0.97
						c1.24,0.35,2.5,0.62,3.76,0.93c0.48,0.12,0.95,0.27,1.42,0.41c1.36,0.39,2.72,0.82,4.09,1.17c1.18,0.3,2.36,0.6,3.57,0.75
						c1.53,0.19,3.09,0.33,4.6-0.18C853.62,2508.37,853.7,2508.4,853.8,2508.4z"/>
					<path className="st9" d="M830.93,2490.44c0.36-0.11,0.74-0.21,1.1-0.35c0.83-0.33,1.65-0.67,2.46-1.04
						c0.81-0.37,1.62-0.76,2.41-1.18c0.86-0.46,1.69-0.98,2.54-1.46c0.66-0.38,1.34-0.73,2-1.11c0.49-0.28,0.96-0.59,1.44-0.88
						c1.06-0.64,2.13-1.28,3.2-1.92c0.49-0.29,0.99-0.57,1.48-0.87c0.51-0.31,1.03-0.59,1.51-0.94c0.44-0.32,0.91-0.65,1.22-1.08
						c0.46-0.64,0.91-1.34,1.15-2.08c0.3-0.92,0.39-1.9,0.57-2.86c0.04-0.22,0.07-0.44,0.2-0.7c0.35,0.68,0.73,1.35,1.04,2.05
						c0.43,0.95,0.82,1.92,1.2,2.89c0.2,0.49,0.46,0.99,0.49,1.5c0.02,0.46-0.16,0.99-0.4,1.41c-0.57,1.01-1.15,2.03-1.88,2.92
						c-0.58,0.71-1.34,1.27-2.2,1.69c-1.44,0.71-2.97,1.19-4.46,1.78c-0.82,0.32-1.61,0.73-2.43,1.07
						c-0.87,0.36-1.76,0.67-2.63,1.02c-0.77,0.31-1.52,0.66-2.29,0.95c-0.78,0.3-1.57,0.58-2.37,0.82
						c-0.86,0.25-1.73,0.46-2.61,0.65c-0.4,0.09-0.82,0.11-1.22,0.11c-0.48-0.01-0.72-0.32-0.86-0.77
						C831.4,2491.53,831.16,2491,830.93,2490.44z"/>
					<path className="st11" d="M835.95,2456.58c0.14,0.08,0.28,0.16,0.41,0.25c0.72,0.53,1.44,1.06,2.15,1.61
						c0.45,0.35,0.94,0.69,1.3,1.13c0.27,0.34,0.4,0.82,0.52,1.25c0.23,0.82,0.49,1.64,0.6,2.47c0.11,0.91,0.2,1.84,0.01,2.78
						c-0.17,0.85-0.34,1.69-0.87,2.39c-0.6,0.79-1.28,1.52-1.89,2.3c-0.49,0.62-0.92,1.3-1.41,1.92c-0.58,0.73-1.21,1.41-1.8,2.13
						c-0.42,0.52-0.79,1.09-1.22,1.6c-0.46,0.55-0.98,1.05-1.45,1.58c-0.34,0.39-0.63,0.83-0.99,1.2c-0.79,0.82-1.6,1.61-2.4,2.41
						c-0.37,0.37-0.74,0.75-1.13,1.09c-0.66,0.57-1.34,1.11-2.2,1.36c-0.14,0.04-0.34,0.02-0.47-0.05c-0.7-0.4-1.39-0.83-2.18-1.32
						c0.3-0.18,0.5-0.27,0.67-0.4c0.77-0.61,1.57-1.19,2.28-1.87c0.68-0.65,1.36-1.34,1.88-2.11c1.4-2.08,2.77-4.19,4.07-6.34
						c1.21-2,2.34-4.06,3.48-6.11c0.47-0.85,0.93-1.72,1.28-2.62c0.39-1,0.49-2.08,0.28-3.14c-0.2-1.02-0.49-2.02-0.76-3.02
						c-0.04-0.15-0.14-0.29-0.21-0.44C835.91,2456.62,835.93,2456.6,835.95,2456.58z"/>
					<path className="st9" d="M832.5,2496.52c0.61,0.07,1.2,0.18,1.78,0.19c0.89,0.01,1.78-0.03,2.67-0.08
						c0.81-0.05,1.62-0.12,2.42-0.22c0.86-0.11,1.71-0.25,2.57-0.39c0.75-0.12,1.5-0.23,2.25-0.37c1.03-0.19,2.05-0.41,3.07-0.61
						c0.72-0.14,1.45-0.28,2.17-0.41c0.35-0.06,0.7-0.11,1.04-0.19c0.83-0.18,1.66-0.36,2.49-0.56c0.74-0.18,1.39-0.55,1.86-1.16
						c0.64-0.83,1.26-1.68,1.88-2.51c0.02,0.49,0.06,1.05,0.06,1.6c0,0.72,0,1.44-0.04,2.16c-0.04,0.83-0.11,1.65-0.17,2.48
						c-0.02,0.28-0.07,0.56-0.1,0.84c-0.11,0.98-0.66,1.71-1.38,2.34c-0.26,0.22-0.51,0.45-0.79,0.66
						c-0.91,0.68-1.95,0.78-3.04,0.69c-1.59-0.13-3.18-0.22-4.77-0.35c-1.12-0.09-2.24-0.21-3.35-0.3
						c-1.28-0.11-2.57-0.19-3.86-0.31c-0.88-0.09-1.77-0.22-2.64-0.37c-1.15-0.19-2.3-0.39-3.44-0.66
						c-0.32-0.08-0.77-0.17-0.69-0.77C832.58,2497.66,832.51,2497.09,832.5,2496.52z"/>
					<path className="st9" d="M832.08,2493.65c1.49,0.12,2.86-0.28,4.22-0.72c0.81-0.26,1.63-0.5,2.44-0.79
						c0.75-0.27,1.48-0.58,2.21-0.88c0.93-0.38,1.85-0.76,2.78-1.14c0.8-0.33,1.6-0.68,2.4-1c0.97-0.39,1.95-0.75,2.93-1.13
						c0.94-0.37,1.88-0.73,2.71-1.35c0.67-0.51,1.25-1.09,1.83-1.7c0.64-0.67,1.01-1.48,1.5-2.22c0.13-0.2,0.15-0.46,0.22-0.7
						c0.04,0,0.08,0,0.12,0c0.13,0.45,0.28,0.89,0.37,1.34c0.19,0.89,0.36,1.79,0.5,2.69c0.11,0.74,0.38,1.48,0.13,2.23
						c-0.23,0.66-0.49,1.31-0.82,1.92c-0.36,0.65-0.78,1.29-1.25,1.87c-0.6,0.75-1.47,1.05-2.39,1.22c-1.13,0.2-2.24,0.46-3.37,0.68
						c-0.69,0.14-1.38,0.27-2.08,0.4c-0.84,0.16-1.67,0.32-2.51,0.47c-0.99,0.17-1.98,0.31-2.97,0.46c-0.67,0.1-1.33,0.22-2,0.3
						c-1.02,0.11-2.04,0.22-3.07,0.27c-0.76,0.04-1.52,0.03-2.28-0.01c-0.39-0.02-0.79-0.11-1.14-0.26
						c-0.14-0.06-0.2-0.37-0.24-0.57C832.22,2494.58,832.16,2494.13,832.08,2493.65z"/>
					<path className="st12" d="M831.99,2502.48c0.16-0.94,0.31-1.86,0.47-2.84c0.32,0.09,0.62,0.21,0.94,0.28
						c0.72,0.14,1.44,0.26,2.17,0.39c0.73,0.12,1.46,0.26,2.19,0.36c0.85,0.11,1.7,0.17,2.55,0.25c0.53,0.05,1.06,0.12,1.59,0.16
						c1.39,0.11,2.79,0.21,4.18,0.32c0.77,0.06,1.54,0.14,2.31,0.2c1.23,0.1,2.46,0.25,3.69,0.27c1.08,0.02,2.07-0.34,2.93-1.02
						c0.33-0.26,0.65-0.54,0.99-0.83c0.01,0.08,0.06,0.2,0.03,0.3c-0.17,0.77-0.33,1.54-0.53,2.31c-0.32,1.21-0.77,2.39-0.99,3.62
						c-0.21,1.24-1.12,1.38-2.03,1.53c-1.17,0.19-2.35,0.15-3.52-0.11c-1.17-0.26-2.35-0.47-3.52-0.76
						c-1.22-0.3-2.42-0.69-3.64-1.01c-1.12-0.29-2.25-0.52-3.37-0.79c-0.44-0.1-0.87-0.23-1.29-0.36c-0.65-0.2-1.29-0.4-1.93-0.62
						c-0.72-0.25-1.45-0.49-2.14-0.82C832.67,2503.11,832.34,2502.75,831.99,2502.48z"/>
					<path className="st11" d="M826.41,2511.2c0.72,1.71,1.66,3.34,2.66,4.93c0.64,1.02,1.28,2.04,1.92,3.07
						c0.68,1.1,1.34,2.21,2.03,3.29c0.57,0.89,1.15,1.77,1.77,2.62c0.5,0.69,0.99,1.44,1.64,1.98c0.64,0.53,1.46,0.84,2.21,1.25
						c0.09,0.05,0.18,0.09,0.37,0.19c-0.99,0.62-1.89,1.2-2.8,1.75c-0.6,0.36-1.21,0.69-1.83,1c-0.9,0.44-1.81,0.87-2.74,1.21
						c-0.18,0.07-0.55-0.24-0.76-0.45c-0.29-0.29-0.5-0.65-0.75-0.98c-0.59-0.74-0.91-1.62-1.23-2.5c-0.33-0.91-0.64-1.84-0.94-2.76
						c-0.44-1.37-0.88-2.74-1.29-4.11c-0.6-1.98-1.2-3.95-1.75-5.94c-0.26-0.94-0.42-1.91-0.6-2.86c-0.02-0.11,0.03-0.29,0.11-0.34
						C825.06,2512.08,825.74,2511.64,826.41,2511.2z"/>
					<path className="st11" d="M814.25,2534c0.12-1.24,0.25-2.46,0.36-3.69c0.09-1.08,0.14-2.17,0.24-3.26c0.08-0.89,0.22-1.77,0.3-2.66
						c0.08-0.82,0.1-1.64,0.19-2.45c0.11-0.94,0.23-1.89,0.44-2.81c0.37-1.63,1.08-3.11,2.04-4.48c0.15-0.21,0.27-0.35,0.56-0.36
						c0.64-0.02,1.28-0.08,1.83-0.12c-0.03,1.53-0.12,3.07-0.07,4.61c0.03,1.17,0.24,2.34,0.36,3.51c0.09,0.94,0.11,1.88,0.25,2.81
						c0.21,1.37,0.48,2.73,0.74,4.08c0.25,1.3,0.53,2.59,0.79,3.89c0.05,0.26,0.11,0.52,0.18,0.77c0.07,0.27-0.02,0.44-0.31,0.45
						c-0.81,0.02-1.63,0.05-2.44,0.04c-1.02,0-2.03-0.01-3.05-0.06c-0.7-0.04-1.4-0.17-2.09-0.26
						C814.48,2534.01,814.39,2534.01,814.25,2534z"/>
					<path className="st9" d="M802.86,2477.97c0.28-0.39,0.48-0.67,0.68-0.96c0.37-0.56,0.22-1.17,0.2-1.77
						c-0.02-0.6-0.01-1.21,0.07-1.8c0.16-1.17,0.55-2.33,0.2-3.51c-0.23-0.77-0.51-1.54-0.86-2.26c-0.41-0.83-0.94-1.6-1.39-2.41
						c-0.34-0.61-0.11-1.07,0.5-1.41c0.6-0.33,1.2-0.7,1.71-1.16c0.45-0.41,0.79-0.94,1.12-1.45c0.42-0.66,0.4-1.78-0.56-2.17
						c-0.45-0.18-0.88-0.43-1.3-0.68c-0.45-0.26-0.48-0.56-0.13-0.91c0.91-0.9,1.02-1.5,0.47-2.66c-0.33-0.7-0.45-1.38,0.09-2.04
						c0.29-0.36,0.52-0.36,0.75,0.04c0.74,1.27,1.42,2.58,1.83,4c0.24,0.85,0.33,1.74,0.47,2.61c0.29,1.77,0.57,3.53,0.84,5.3
						c0.23,1.53,0.46,3.06,0.66,4.59c0.21,1.63,0.44,3.26,0.57,4.89c0.09,1.13,0.04,2.26,0.03,3.4c0,0.57-0.02,1.14-0.09,1.71
						c-0.04,0.37-0.15,0.75-0.67,0.74c-0.21,0-0.43,0.14-0.65,0.15c-0.34,0.02-0.67,0.13-1.04-0.11
						C805.23,2479.38,804.08,2478.71,802.86,2477.97z"/>
					<path className="st9" d="M828.01,2509.67c0.66,0.8,1.26,1.62,1.96,2.33c0.7,0.71,1.5,1.31,2.26,1.95c0.75,0.63,1.49,1.26,2.25,1.88
						c0.37,0.3,0.76,0.56,1.15,0.83c0.57,0.39,1.15,0.76,1.71,1.16c0.85,0.61,1.68,1.25,2.52,1.87c0.89,0.67,1.75,1.37,2.66,1.99
						c0.51,0.35,1.11,0.58,1.68,0.83c0.26,0.11,0.57,0.13,0.99,0.22c-0.51,0.55-0.94,1.05-1.41,1.5c-0.97,0.95-1.96,1.89-2.96,2.81
						c-0.29,0.27-0.61,0.53-0.96,0.68c-0.22,0.09-0.57,0.05-0.79-0.07c-0.78-0.42-1.58-0.83-2.26-1.38
						c-0.54-0.44-0.93-1.07-1.34-1.65c-0.5-0.7-0.95-1.43-1.41-2.15c-0.46-0.71-0.91-1.44-1.37-2.15c-0.31-0.49-0.63-0.97-0.94-1.46
						c-0.6-0.95-1.19-1.91-1.78-2.86c-0.41-0.67-0.84-1.33-1.22-2.01c-0.5-0.92-1.01-1.83-1.43-2.79
						C827,2510.41,827.1,2510.29,828.01,2509.67z"/>
					<path className="st9" d="M787.19,2493.67c0.6-1.17,1.16-2.33,1.8-3.45c0.65-1.13,1.4-2.2,2.1-3.3c0.14-0.22,0.27-0.26,0.52-0.13
						c0.97,0.5,1.94,1.02,2.94,1.45c2.7,1.16,5.1,2.82,7.54,4.42c0.86,0.57,1.68,1.2,2.5,1.83c0.55,0.42,1.06,0.89,1.63,1.37
						c-0.51,0.84-1.03,1.7-1.58,2.61c-0.16-0.07-0.37-0.16-0.58-0.24c-0.87-0.33-1.74-0.65-2.62-0.97
						c-0.64-0.23-1.28-0.47-1.93-0.66c-1.21-0.36-2.43-0.67-3.64-1.02c-0.89-0.26-1.76-0.57-2.66-0.79
						c-1.27-0.31-2.55-0.58-3.84-0.83c-0.59-0.11-1.2-0.11-1.8-0.16C787.49,2493.79,787.4,2493.74,787.19,2493.67z"/>
					<path className="st12" d="M809.6,2511.79c-0.68,0.63-1.34,1.23-1.99,1.84c-0.14,0.13-0.24,0.31-0.36,0.47
						c-0.85,1.13-1.72,2.25-2.54,3.39c-0.94,1.31-1.85,2.65-2.78,3.98c-0.73,1.05-1.48,2.09-2.2,3.15
						c-0.63,0.93-1.24,1.87-1.83,2.81c-0.18,0.29-0.33,0.28-0.58,0.11c-0.88-0.59-1.67-1.28-2.37-2.07
						c-0.35-0.39-0.71-0.78-1.12-1.1c-0.35-0.28-0.39-0.54-0.23-0.93c0.38-0.92,0.68-1.87,1.09-2.77c0.43-0.93,1.09-1.68,1.92-2.3
						c0.4-0.3,0.72-0.71,1.12-1.04c1.16-0.96,2.34-1.89,3.51-2.83c0.59-0.48,1.16-0.97,1.76-1.43c0.94-0.72,1.87-1.46,2.86-2.11
						c0.41-0.27,0.95-0.38,1.45-0.5c0.18-0.04,0.43,0.04,0.59,0.15C808.48,2510.98,809.04,2511.39,809.6,2511.79z"/>
					<path className="st12" d="M823.37,2513.17c0.42,1.56,0.82,3.13,1.26,4.7c0.41,1.47,0.84,2.94,1.29,4.41
						c0.25,0.83,0.55,1.64,0.82,2.47c0.56,1.68,1.07,3.37,1.69,5.02c0.28,0.74,0.76,1.4,1.19,2.07c0.23,0.36,0.56,0.66,0.89,1.05
						c-0.64,0.19-1.23,0.4-1.83,0.52c-1.22,0.25-2.46,0.47-3.69,0.68c-0.39,0.07-0.79,0.09-1.18,0.1c-0.25,0.01-0.45-0.06-0.51-0.38
						c-0.36-1.71-0.77-3.41-1.11-5.12c-0.22-1.13-0.37-2.27-0.53-3.41c-0.17-1.24-0.35-2.47-0.47-3.71
						c-0.13-1.31-0.26-2.62-0.28-3.93c-0.02-1.19,0.01-2.4,0.38-3.55c0.03-0.09,0.16-0.18,0.27-0.22
						C822.17,2513.62,822.79,2513.39,823.37,2513.17z"/>
					<path className="st12" d="M829.74,2507.38c0.38,0.27,0.72,0.55,1.1,0.78c2.02,1.2,4.03,2.41,6.06,3.56
						c1.1,0.62,2.24,1.17,3.38,1.73c1.18,0.59,2.36,1.2,3.57,1.74c1.06,0.47,2.14,0.9,3.25,1.23c0.88,0.26,1.81,0.15,2.75-0.15
						c-0.5,0.82-0.95,1.6-1.44,2.36c-0.36,0.55-0.75,1.08-1.15,1.61c-0.38,0.49-0.77,0.97-1.18,1.43c-0.11,0.13-0.35,0.25-0.5,0.23
						c-0.67-0.13-1.41-0.18-1.97-0.52c-1.25-0.75-2.41-1.65-3.59-2.5c-1.04-0.75-2.06-1.51-3.09-2.28
						c-0.83-0.62-1.66-1.23-2.48-1.86c-0.71-0.55-1.42-1.09-2.09-1.68c-0.65-0.57-1.3-1.15-1.89-1.79c-0.6-0.66-1.14-1.39-1.67-2.11
						c-0.08-0.11-0.07-0.37,0.01-0.49C829.09,2508.24,829.41,2507.85,829.74,2507.38z"/>
					<path className="st11" d="M806.9,2509.55c-0.74,0.36-1.53,0.7-2.29,1.11c-0.37,0.21-0.66,0.55-1.01,0.82
						c-1.13,0.87-2.27,1.73-3.38,2.61c-0.61,0.48-1.19,1-1.79,1.5c-0.95,0.81-1.93,1.6-2.85,2.44c-0.4,0.37-0.75,0.82-1.02,1.29
						c-0.54,0.91-1.02,1.85-1.49,2.79c-0.13,0.27-0.16,0.6-0.27,1.05c-0.41-0.51-0.77-0.92-1.08-1.35c-0.4-0.56-0.8-1.14-1.15-1.74
						c-0.51-0.86-0.97-1.76-1.47-2.62c-0.24-0.42,0.09-0.65,0.28-0.9c0.39-0.54,0.8-1.07,1.24-1.56c0.63-0.7,1.24-1.43,1.97-2.02
						c0.47-0.38,1.13-0.55,1.71-0.79c0.9-0.36,1.81-0.69,2.71-1.05c1.05-0.41,2.1-0.84,3.16-1.25c0.93-0.37,1.86-0.76,2.8-1.08
						c0.86-0.3,1.74-0.52,2.62-0.76c0.1-0.03,0.27,0.04,0.33,0.12C806.27,2508.61,806.59,2509.1,806.9,2509.55z"/>
					<path className="st9" d="M786.85,2494.58c0.67,0.05,1.35,0.06,2.01,0.16c1.04,0.17,2.09,0.38,3.11,0.63
						c0.9,0.22,1.78,0.54,2.67,0.79c1.07,0.3,2.15,0.57,3.22,0.89c1.32,0.39,2.63,0.79,3.94,1.21c0.76,0.25,1.49,0.55,2.24,0.82
						c0.29,0.1,0.3,0.33,0.27,0.54c-0.06,0.38-0.15,0.77-0.27,1.13c-0.04,0.11-0.22,0.26-0.33,0.26c-1.62-0.08-3.24-0.18-4.86-0.26
						c-1.14-0.06-2.27-0.09-3.41-0.11c-0.86-0.01-1.72,0.02-2.58,0.02c-0.71,0-1.42-0.07-2.12-0.03c-0.75,0.04-1.5,0.12-2.23,0.28
						c-0.8,0.17-1.58,0.44-2.37,0.66c-0.71,0.2-0.69,0.2-0.65-0.6c0.05-1.14,0.19-2.27,0.46-3.39c0.2-0.83,0.4-1.65,0.76-2.42
						C786.79,2495,786.8,2494.82,786.85,2494.58z"/>
					<path className="st11" d="M788.55,2516.25c-0.19-0.41-0.41-0.82-0.57-1.26c-0.45-1.25-0.86-2.51-1.31-3.76
						c-0.29-0.8-0.25-0.89,0.49-1.44c0.57-0.42,1.11-0.89,1.68-1.32c0.89-0.69,1.88-1.17,2.97-1.42c1.47-0.33,2.94-0.65,4.42-0.94
						c1.08-0.21,2.18-0.37,3.27-0.54c1.03-0.17,2.05-0.35,3.08-0.5c0.46-0.06,0.94-0.03,1.41-0.07c0.36-0.03,0.48,0.21,0.59,0.46
						c0.18,0.43,0.32,0.88,0.55,1.29c0.18,0.33,0.04,0.45-0.22,0.54c-1.06,0.36-2.13,0.68-3.17,1.07c-1.51,0.56-3.01,1.18-4.52,1.77
						c-1.08,0.42-2.18,0.81-3.25,1.24c-0.66,0.27-1.3,0.59-1.95,0.89c-0.05,0.02-0.11,0.05-0.14,0.1c-0.55,0.6-1.11,1.19-1.63,1.81
						C789.68,2514.83,789.15,2515.52,788.55,2516.25z"/>
					<path className="st9" d="M806.74,2495.06c-0.6-0.42-1.2-0.83-1.79-1.26c-0.91-0.66-1.79-1.35-2.73-1.97
						c-1.71-1.14-3.43-2.28-5.2-3.34c-1.1-0.67-2.29-1.2-3.45-1.77c-0.47-0.23-0.97-0.42-1.45-0.63c-0.09-0.04-0.16-0.13-0.22-0.18
						c1.47-1.83,3.12-3.41,5.06-4.77c0.21,0.16,0.46,0.33,0.69,0.52c0.39,0.35,0.78,0.71,1.16,1.07c0.74,0.7,1.48,1.4,2.21,2.12
						c0.9,0.9,1.8,1.8,2.66,2.73c0.71,0.76,1.4,1.54,2.02,2.37c0.78,1.03,1.49,2.12,2.23,3.18c0.33,0.46,0,0.73-0.27,1.02
						C807.37,2494.46,807.05,2494.76,806.74,2495.06z"/>
					<path className="st9" d="M804.13,2504.08c-1.15,0.14-2.3,0.27-3.45,0.41c-0.89,0.11-1.78,0.22-2.66,0.37
						c-1.07,0.19-2.14,0.4-3.2,0.64c-1.29,0.28-2.59,0.57-3.87,0.91c-0.88,0.23-1.65,0.7-2.39,1.23c-0.75,0.54-1.49,1.11-2.31,1.71
						c-0.05-0.2-0.12-0.39-0.16-0.59c-0.11-0.55-0.22-1.1-0.29-1.65c-0.12-0.99-0.22-1.99-0.32-2.99c-0.03-0.31-0.07-0.62-0.03-0.92
						c0.02-0.19,0.13-0.48,0.28-0.54c0.66-0.28,1.34-0.54,2.03-0.73c0.7-0.2,1.43-0.34,2.15-0.45c0.46-0.07,0.94-0.03,1.41-0.02
						c1.55,0.03,3.1,0.07,4.64,0.11c1.47,0.04,2.94,0.07,4.41,0.12c1.06,0.03,2.11,0.08,3.17,0.14c0.23,0.01,0.43,0.03,0.41,0.43
						C803.94,2502.88,804.07,2503.5,804.13,2504.08z"/>
					<path className="st11" d="M817.02,2514.23c-0.06,0.1-0.11,0.17-0.15,0.26c-0.51,1.02-1.12,2-1.5,3.07
						c-0.47,1.29-0.76,2.64-0.79,4.04c-0.01,0.62-0.17,1.23-0.23,1.85c-0.1,1-0.17,2-0.25,2.99c-0.05,0.62-0.1,1.25-0.15,1.87
						c-0.07,0.85-0.13,1.7-0.21,2.55c-0.05,0.59-0.11,1.18-0.18,1.77c-0.04,0.41-0.09,0.82-0.14,1.26
						c-0.46-0.05-0.95-0.06-1.43-0.16c-0.83-0.17-1.65-0.4-2.48-0.58c-0.33-0.07-0.37-0.26-0.35-0.56c0.06-1.01,0.07-2.03,0.13-3.05
						c0.04-0.77,0.1-1.54,0.2-2.31c0.17-1.19,0.33-2.39,0.59-3.56c0.16-0.71,0.51-1.38,0.74-2.09c0.57-1.75,1.38-3.4,2.27-5.01
						c0.4-0.72,0.74-1.47,1.14-2.18c0.38-0.68,0.4-0.68,1.13-0.55C815.9,2513.94,816.43,2514.09,817.02,2514.23z"/>
					<path className="st12" d="M808.88,2493c-0.33-0.5-0.67-1.01-1.02-1.52c-0.38-0.56-0.73-1.13-1.16-1.65
						c-0.78-0.94-1.59-1.86-2.41-2.77c-0.56-0.62-1.14-1.22-1.72-1.81c-0.85-0.86-1.69-1.73-2.56-2.56
						c-0.59-0.56-1.22-1.07-1.83-1.61c-0.13-0.12-0.22-0.29-0.39-0.52c0.75-0.41,1.47-0.84,2.22-1.22c0.59-0.29,1.21-0.51,1.81-0.79
						c0.26-0.12,0.47-0.14,0.72,0.02c1.26,0.82,2.53,1.61,3.79,2.43c0.17,0.11,0.28,0.34,0.37,0.53c0.58,1.27,1.13,2.56,1.72,3.82
						c0.59,1.24,1.05,2.51,1.42,3.84c0.25,0.9,0.5,1.79,0.49,2.73c0,0.1-0.05,0.24-0.12,0.28
						C809.79,2492.47,809.36,2492.71,808.88,2493z"/>
					<path className="st12" d="M817.69,2481.04c-0.08,1.05-0.13,2.01-0.23,2.96c-0.07,0.66-0.19,1.32-0.32,1.97
						c-0.19,0.93-0.35,1.87-0.62,2.77c-0.24,0.78-0.62,1.51-0.94,2.25c-0.03,0.07-0.13,0.17-0.19,0.17
						c-0.51-0.02-1.02-0.05-1.51-0.08c-0.09-1.4-0.15-2.77-0.26-4.13c-0.07-0.89-0.22-1.78-0.35-2.67
						c-0.11-0.76-0.24-1.51-0.38-2.26c-0.07-0.41-0.2-0.8-0.27-1.21c-0.05-0.25-0.06-0.47,0.36-0.42c0.87,0.09,1.74,0.09,2.61,0.18
						c0.44,0.04,0.88,0.17,1.31,0.27C817.16,2480.92,817.42,2480.98,817.69,2481.04z"/>
					<path className="st12" d="M807.4,2480.99c1.47-0.2,2.87-0.39,4.31-0.58c0.3,1.72,0.62,3.38,0.86,5.05c0.18,1.2,0.27,2.42,0.37,3.63
						c0.05,0.61,0.04,1.23,0.04,1.85c0,0.1-0.09,0.26-0.17,0.28c-0.53,0.17-1.06,0.3-1.59,0.45
						C810.77,2487.87,809.02,2484.51,807.4,2480.99z"/>
					<path className="st12" d="M818.3,2492.11c-0.68-0.3-1.29-0.57-1.93-0.85c0.21-0.47,0.52-1,0.7-1.57c0.34-1.11,0.72-2.22,0.82-3.4
						c0.05-0.65,0.24-1.28,0.32-1.92c0.11-0.91,0.18-1.83,0.27-2.75c0.01-0.06,0.03-0.12,0.07-0.29c1.34,0.63,2.64,1.25,3.99,1.89
						c-0.12,0.28-0.21,0.48-0.3,0.69c-0.31,0.72-0.6,1.45-0.92,2.17c-0.45,1.01-0.92,2.02-1.4,3.01c-0.41,0.86-0.85,1.71-1.29,2.56
						C818.54,2491.82,818.42,2491.95,818.3,2492.11z"/>
					<path className="st12" d="M823.21,2483.67c1.08,0.85,2.11,1.65,3.19,2.51c-0.1,0.17-0.18,0.31-0.27,0.45
						c-0.42,0.62-0.8,1.28-1.26,1.86c-0.89,1.13-1.79,2.25-2.76,3.3c-0.53,0.58-1.23,1-1.86,1.49c-0.06,0.04-0.19,0.05-0.24,0.02
						c-0.32-0.21-0.62-0.44-0.91-0.65c0.01-0.07,0.01-0.08,0.02-0.1c0.7-1.35,1.43-2.69,2.11-4.05c0.45-0.91,0.86-1.84,1.25-2.78
						C822.74,2485.09,822.94,2484.43,823.21,2483.67z"/>
					<path className="st8" d="M812.74,2504.43c-0.69-0.56-0.87-1.31-0.99-2.07c-0.1-0.63-0.11-1.28-0.15-1.92
						c0-0.06,0.01-0.12,0.02-0.17c0.27-0.96,0.65-1.87,1.45-2.49c0.63-0.49,1.4-0.63,2.2-0.58c0.71,0.04,1.17,0.46,1.57,0.98
						c0.51,0.66,0.48,2.16-0.15,2.7c-0.29,0.25-0.74,0.39-1.13,0.45c-0.52,0.08-1.05,0.03-1.59-0.02c-0.16,0.13-0.19,0.53-0.58,0.28
						C813.18,2502.53,812.97,2503.46,812.74,2504.43z"/>
					<path className="st12" d="M820.74,2494.02c2.66-1.95,4.51-4.53,6.23-7.3c0.21,0.22,0.38,0.39,0.54,0.57c0.41,0.49,0.79,1,1.23,1.46
						c0.23,0.25,0.09,0.38-0.05,0.54c-0.48,0.56-0.96,1.13-1.47,1.66c-0.64,0.67-1.29,1.34-1.98,1.95
						c-0.95,0.83-1.91,1.64-3.09,2.15c-0.31,0.14-0.5,0.08-0.69-0.14C821.23,2494.63,821.01,2494.35,820.74,2494.02z"/>
					<path className="st11" d="M822.46,2496.5c-0.02-0.07-0.02-0.16-0.07-0.2c-0.32-0.29-0.07-0.44,0.15-0.53
						c0.91-0.37,1.69-0.94,2.44-1.56c1.04-0.88,2.06-1.77,2.93-2.84c0.47-0.57,1.03-1.07,1.58-1.63c0.5,0.94,1.14,1.8,1.35,2.87
						c0.01,0.07,0.07,0.13,0.1,0.2c0.26,0.49,0.24,0.53-0.27,0.78c-1.01,0.49-1.99,1.05-3.02,1.48c-1.49,0.62-3,1.23-4.62,1.47
						C822.85,2496.57,822.65,2496.52,822.46,2496.5z"/>
					<path className="st11" d="M831.37,2494.31c0.11,0.95,0.22,1.86,0.31,2.78c0.01,0.06-0.14,0.16-0.23,0.18
						c-1.28,0.32-2.55,0.66-3.84,0.94c-1.24,0.27-2.51,0.31-3.78,0.25c-0.52-0.03-0.91-0.35-0.94-0.85c0-0.08,0.11-0.22,0.19-0.23
						c1.43-0.18,2.78-0.63,4.08-1.22c1.24-0.56,2.47-1.13,3.71-1.7C831,2494.4,831.14,2494.38,831.37,2494.31z"/>
					<path className="st11" d="M831.71,2498.17c-0.09,0.87-0.18,1.66-0.24,2.47c-0.03,0.46-0.3,0.6-0.69,0.58
						c-1.07-0.07-2.15-0.17-3.22-0.23c-1.42-0.07-2.83-0.25-4.2-0.64c-0.06-0.02-0.12,0-0.18,0c0-0.4,0.01-0.8,0.01-1.17
						C826.09,2499.67,828.85,2498.85,831.71,2498.17z"/>
					<path className="st9" d="M818.14,2506.77c0.09-0.04,0.1-0.04,0.12-0.05c0.77-0.46,0.81-0.39,1.34,0.48c0.32,0.52,0.69,1,1.06,1.48
						c0.8,1.03,1.62,2.04,2.42,3.06c0.1,0.12,0.15,0.28,0.22,0.42c-0.87,0.57-1.85,0.86-2.86,1.1c-0.32,0.07-0.52,0.08-0.63-0.37
						c-0.31-1.29-0.71-2.55-1.06-3.83C818.53,2508.31,818.34,2507.54,818.14,2506.77z"/>
					<path className="st12" d="M831.21,2502.07c-0.25,0.73-0.43,1.35-0.67,1.94c-0.34,0.85-0.37,0.84-1.24,0.51
						c-0.8-0.31-1.59-0.66-2.41-0.9c-1.33-0.39-2.58-0.94-3.79-1.6c-0.13-0.07-0.26-0.16-0.4-0.24c0.08-0.23,0.15-0.42,0.23-0.64
						C825.62,2501.85,828.38,2501.93,831.21,2502.07z"/>
					<path className="st12" d="M809.2,2510.44c-1.08-0.76-1.91-1.66-2.52-2.71c1.02-0.6,2-1.17,2.97-1.76c0.61-0.37,1.19-0.78,1.8-1.14
						c0.11-0.06,0.33-0.01,0.46,0.07c0.22,0.13,0.34,0.32,0.18,0.6c-0.44,0.76-0.84,1.53-1.28,2.28
						C810.3,2508.66,809.76,2509.51,809.2,2510.44z"/>
					<path className="st9" d="M814.56,2491.94c0.04,1.5,0.07,2.92,0.11,4.34c-0.43,0.12-0.86,0.27-1.3,0.34
						c-0.13,0.02-0.32-0.12-0.42-0.24c-0.39-0.43-0.77-0.87-1.13-1.32c-0.44-0.54-0.88-1.08-1.28-1.64
						c-0.07-0.09,0.01-0.41,0.11-0.48c0.72-0.43,1.51-0.74,2.35-0.85C813.48,2492.02,813.98,2491.99,814.56,2491.94z"/>
					<path className="st12" d="M821.42,2504.32c2.19,1.33,4.36,2.65,6.59,4c-0.55,0.59-1.09,1.18-1.71,1.86
						c-1.82-1.7-3.63-3.39-5.47-5.11C821.08,2504.75,821.26,2504.53,821.42,2504.32z"/>
					<path className="st12" d="M829.89,2505.57c-0.4,0.65-0.74,1.23-1.09,1.8c-0.18,0.29-0.4,0.12-0.59,0.01
						c-1.28-0.76-2.55-1.51-3.83-2.28c-0.82-0.49-1.64-1-2.48-1.5c0.19-0.38,0.33-0.65,0.48-0.94c0.11,0.04,0.21,0.07,0.3,0.12
						c1.04,0.48,2.07,1.01,3.13,1.45c0.97,0.4,1.97,0.71,2.97,1.05C829.12,2505.39,829.48,2505.46,829.89,2505.57z"/>
					<path className="st9" d="M810.78,2500.16c-1.08-0.15-2.16-0.29-3.23-0.46c-0.58-0.09-1.16-0.19-1.73-0.33
						c-0.34-0.08-0.51-0.26-0.27-0.66c0.29-0.49,0.47-1.04,0.75-1.54c0.19-0.33,0.46-0.61,0.63-0.82c1.31,0.8,2.53,1.54,3.76,2.28
						c0.48,0.29,0.35,0.71,0.25,1.13C810.91,2499.87,810.86,2499.96,810.78,2500.16z"/>
					<path className="st11" d="M810.77,2501.99c-1.74,0.45-3.48,0.9-5.23,1.35c-0.62,0.16-0.74,0.09-0.73-0.55
						c0.01-0.89,0.09-1.77,0.15-2.71c0.49,0.11,0.88,0.22,1.27,0.27c1.3,0.19,2.59,0.38,3.89,0.53c0.46,0.06,0.62,0.18,0.63,0.67
						C810.76,2501.67,810.76,2501.79,810.77,2501.99z"/>
					<path className="st11" d="M806.15,2507.01c-0.38-0.91-0.74-1.75-1.1-2.62c2.01-0.54,3.94-1.06,5.89-1.58
						c0.11,0.32,0.25,0.62,0.29,0.93c0.02,0.14-0.15,0.35-0.29,0.45c-0.63,0.44-1.27,0.86-1.94,1.25
						C808.08,2505.97,807.13,2506.47,806.15,2507.01z"/>
					<path className="st12" d="M817.87,2501.03c0.82,0.5,1.7,1.03,2.57,1.56c0.25,0.15,0.5,0.31,0.75,0.46
						c-0.16,0.23-0.34,0.44-0.48,0.68c-0.49,0.82-1.2,1.39-2.07,1.91c-0.75-1.21-1.48-2.4-2.2-3.57
						C816.91,2501.73,817.35,2501.41,817.87,2501.03z"/>
					<path className="st12" d="M819.33,2501.05c-0.35-0.21-0.7-0.44-1.06-0.64c-0.23-0.12-0.29-0.27-0.23-0.52
						c0.03-0.11-0.04-0.25,0-0.34c0.06-0.14,0.16-0.34,0.28-0.38c1.13-0.33,2.27-0.63,3.4-0.94c0.34-0.1,0.53,0.02,0.56,0.36
						c0.07,0.82,0.12,1.63-0.24,2.41c-0.13,0.28-0.2,0.58-0.27,0.88c-0.06,0.25-0.19,0.27-0.4,0.18c-0.68-0.32-1.37-0.63-2.06-0.94
						C819.31,2501.1,819.32,2501.07,819.33,2501.05z"/>
					<path className="st11" d="M815.43,2492.09c1.37,0.08,2.5,0.72,3.59,1.45c0.29,0.2,0.27,0.38,0.05,0.59
						c-0.78,0.76-1.56,1.52-2.34,2.27c-0.32,0.31-0.65,0.09-0.98,0c-0.44-0.12-0.26-0.5-0.27-0.71
						C815.43,2494.49,815.44,2493.28,815.43,2492.09z"/>
					<path className="st12" d="M817.79,2505.91c-1.57,0.3-2.94-0.15-4.34-0.75c0.23-1.02,0.45-2,0.66-2.93
						c0.41-0.01,0.79-0.05,1.16-0.02c0.15,0.01,0.36,0.11,0.44,0.24c0.49,0.76,0.95,1.55,1.42,2.33
						C817.34,2505.13,817.54,2505.49,817.79,2505.91z"/>
					<path className="st12" d="M819.99,2494.45c0.37,0.47,0.71,0.86,1,1.29c0.3,0.44,0.56,0.9,0.82,1.36c0.03,0.05-0.07,0.25-0.15,0.27
						c-1.18,0.36-2.37,0.7-3.56,1.03c-0.08,0.02-0.24-0.06-0.29-0.15c-0.21-0.31-0.4-0.64-0.58-0.98c-0.04-0.07-0.02-0.22,0.04-0.28
						C818.17,2496.14,819.08,2495.3,819.99,2494.45z"/>
					<path className="st11" d="M807.48,2495.48c0.74-0.68,1.39-1.28,2.06-1.9c1.01,1.23,1.98,2.41,2.91,3.55
						c-0.32,0.35-0.63,0.71-1.01,1.13C810.19,2497.37,808.9,2496.47,807.48,2495.48z"/>
					<path className="st12" d="M812.33,2512.18c-0.84-0.37-1.61-0.71-2.49-1.1c1.08-1.77,2.11-3.48,3.17-5.21
						c0.22,0.09,0.36,0.2,0.28,0.6c-0.21,1.05-0.33,2.12-0.51,3.18C812.64,2510.47,812.48,2511.29,812.33,2512.18z"/>
					<path className="st9" d="M820.21,2505.64c1.78,1.68,3.54,3.33,5.37,5.06c-0.41,0.3-0.82,0.66-1.28,0.92
						c-0.33,0.18-0.47-0.24-0.63-0.41c-0.33-0.36-0.6-0.77-0.89-1.17c-0.78-1.07-1.56-2.14-2.34-3.21c-0.08-0.11-0.19-0.2-0.28-0.31
						C819.72,2505.95,819.72,2505.92,820.21,2505.64z"/>
					<path className="st9" d="M819.03,2513.46c-0.64-0.03-1.2-0.05-1.77-0.11c-0.09-0.01-0.21-0.22-0.22-0.34
						c-0.07-1.21-0.15-2.43-0.17-3.65c-0.01-0.82,0.07-1.64,0.12-2.58c0.18,0.15,0.37,0.23,0.4,0.35c0.32,1.22,0.61,2.45,0.93,3.67
						c0.19,0.73,0.4,1.45,0.61,2.17C818.97,2513.1,818.99,2513.23,819.03,2513.46z"/>
					<path className="st9" d="M814.1,2506.31c0.23,0.11,0.35,0.17,0.45,0.22c-0.04,2.09-0.08,4.16-0.12,6.29
						c-0.44-0.13-0.85-0.24-1.27-0.36C813.48,2510.38,813.79,2508.38,814.1,2506.31z"/>
					<path className="st9" d="M816.18,2513.13c-0.9-0.19-0.89-0.21-0.87-1.07c0.03-1.63,0.04-3.26,0.06-4.89c0-0.15,0.04-0.29,0.07-0.44
						c0.86,0.11,0.69,0.2,0.68,0.83c-0.02,0.94-0.04,1.89-0.02,2.83C816.09,2511.29,816.14,2512.19,816.18,2513.13z"/>
				</g>
				<g>
					<path className="st23" d="M808.48,2480.8c-0.08,0.07,0.03-0.02,0.06-0.04c0.05-0.03,0.1-0.06,0.15-0.08c0.02-0.01,0.1-0.04,0,0
						c0.02-0.01,0.05-0.02,0.07-0.03c0.06-0.02,0.13-0.04,0.19-0.06c0.24-0.07,0.5-0.1,0.75-0.14c0.52-0.07,0.99-0.18,1.47-0.4
						c0.91-0.4,1.7-1.15,2.15-2.04c0.54-1.07,0.63-2.29,0.8-3.46c0.18-1.24,0.35-2.48,0.53-3.72c0.2-1.4,0.4-2.8,0.6-4.2
						c0.04-0.3-0.07-0.64-0.41-0.72c-0.27-0.06-0.67,0.08-0.71,0.41c-0.35,2.45-0.7,4.9-1.04,7.34c-0.09,0.61-0.17,1.21-0.26,1.82
						c-0.08,0.55-0.17,1.1-0.36,1.62c-0.01,0.04-0.03,0.07-0.04,0.11c0,0-0.04,0.09-0.02,0.04c0.02-0.05-0.02,0.04-0.03,0.06
						c-0.01,0.02-0.02,0.04-0.03,0.07c-0.05,0.11-0.11,0.21-0.17,0.32c-0.06,0.1-0.12,0.19-0.19,0.28
						c-0.04,0.05,0.01-0.02,0.02-0.02c-0.02,0.02-0.04,0.05-0.06,0.07c-0.04,0.05-0.08,0.1-0.13,0.15
						c-0.08,0.08-0.16,0.16-0.24,0.24c-0.04,0.04-0.08,0.08-0.13,0.11c-0.02,0.02-0.04,0.03-0.06,0.05
						c-0.12,0.1,0.06-0.04-0.02,0.01c-0.19,0.14-0.38,0.26-0.59,0.37c-0.06,0.03-0.12,0.06-0.17,0.08c-0.01,0-0.08,0.03-0.02,0.01
						c0.07-0.03-0.05,0.02-0.06,0.02c-0.12,0.05-0.24,0.08-0.37,0.12c-0.26,0.07-0.53,0.1-0.79,0.14c-0.63,0.09-1.2,0.23-1.7,0.64
						c-0.24,0.19-0.22,0.62,0,0.82C807.91,2481.03,808.23,2481.01,808.48,2480.8L808.48,2480.8z"/>
				</g>
				<g>
					<path className="st23" d="M813.07,2479.65c1.29,0.18,2.58-0.03,3.73-0.66c1.08-0.59,1.97-1.56,2.46-2.69
						c0.29-0.67,0.47-1.36,0.51-2.09c0.02-0.3-0.28-0.59-0.58-0.58c-0.33,0.01-0.56,0.26-0.58,0.58c0,0.07-0.01,0.14-0.02,0.2
						c0,0.03-0.01,0.07-0.01,0.1c0,0.01-0.01,0.09,0,0.02c0.01-0.08-0.01,0.03-0.01,0.05c-0.01,0.03-0.01,0.07-0.02,0.1
						c-0.01,0.08-0.03,0.15-0.05,0.22c-0.06,0.26-0.14,0.51-0.23,0.75c-0.01,0.02-0.05,0.11-0.02,0.05
						c0.03-0.06-0.01,0.02-0.01,0.02c-0.03,0.06-0.06,0.12-0.09,0.18c-0.06,0.12-0.12,0.24-0.19,0.36
						c-0.06,0.11-0.13,0.22-0.21,0.32c-0.04,0.06-0.08,0.11-0.12,0.17c-0.04,0.05,0.01-0.01,0.01-0.02
						c-0.03,0.04-0.06,0.08-0.1,0.12c-0.17,0.2-0.35,0.39-0.55,0.57c-0.04,0.04-0.09,0.08-0.14,0.12c-0.03,0.02-0.05,0.04-0.08,0.07
						c0.08-0.06,0.01-0.01-0.01,0c-0.11,0.08-0.23,0.16-0.35,0.23c-0.11,0.07-0.22,0.13-0.34,0.19c-0.06,0.03-0.12,0.06-0.19,0.09
						c-0.02,0.01-0.05,0.02-0.07,0.03c-0.16,0.07,0.11-0.04-0.05,0.02c-0.25,0.1-0.51,0.18-0.77,0.24
						c-0.14,0.03-0.29,0.06-0.44,0.08c0.11-0.02,0.02,0,0,0c-0.04,0-0.09,0.01-0.13,0.01c-0.08,0.01-0.16,0.01-0.23,0.02
						c-0.16,0.01-0.31,0.01-0.47,0c-0.07,0-0.14-0.01-0.21-0.01c-0.03,0-0.07-0.01-0.1-0.01c-0.03,0-0.18-0.02-0.05-0.01
						c-0.3-0.04-0.64,0.07-0.71,0.41C812.6,2479.21,812.75,2479.61,813.07,2479.65L813.07,2479.65z"/>
				</g>
				<g>
					<path className="st23" d="M816.33,2480.23c1.38-0.67,2.31-1.96,2.86-3.35c0.64-1.61,0.72-3.39,0.82-5.11
						c0.05-0.89,0.12-1.77,0.21-2.65c0.04-0.43,0.09-0.86,0.14-1.3c0.02-0.22,0.05-0.43,0.08-0.65c0.01-0.11,0.03-0.23,0.04-0.34
						c0.01-0.04,0.01-0.08,0.02-0.12s0.01-0.08,0.02-0.12c0.01-0.07,0.01-0.08,0-0.03c0.24-1.75,0.54-3.49,0.9-5.22
						c0.41-1.95,0.91-3.89,1.48-5.8c0.09-0.29-0.1-0.65-0.41-0.72c-0.31-0.07-0.62,0.09-0.71,0.41c-1.01,3.36-1.77,6.79-2.28,10.25
						c-0.26,1.76-0.46,3.53-0.59,5.3c-0.06,0.87-0.1,1.74-0.18,2.61c-0.07,0.84-0.17,1.59-0.42,2.46c-0.06,0.2-0.12,0.39-0.19,0.58
						c-0.01,0.03-0.02,0.06-0.04,0.09c-0.01,0.02-0.07,0.18-0.05,0.11c0.03-0.08-0.03,0.07-0.03,0.07
						c-0.02,0.05-0.05,0.11-0.07,0.16c-0.17,0.35-0.36,0.69-0.59,1c-0.03,0.04-0.06,0.08-0.09,0.12c0.06-0.08,0,0-0.03,0.04
						c-0.06,0.08-0.12,0.15-0.19,0.22c-0.13,0.14-0.27,0.28-0.41,0.41c-0.02,0.02-0.05,0.04-0.07,0.06
						c-0.02,0.02-0.18,0.14-0.07,0.06c-0.08,0.06-0.16,0.11-0.24,0.17c-0.16,0.11-0.33,0.2-0.51,0.29c-0.27,0.13-0.37,0.55-0.21,0.8
						C815.71,2480.3,816.03,2480.37,816.33,2480.23L816.33,2480.23z"/>
				</g>
				<g>
					<path className="st23" d="M812.21,2479.88c1.25,0.01,2.51,0.06,3.76,0.21c0.08,0.01,0.16,0.02,0.24,0.03
						c0.04,0,0.07,0.01,0.11,0.01c0.09,0.01-0.09-0.01,0.01,0c0.17,0.02,0.34,0.05,0.5,0.08c0.3,0.05,0.6,0.1,0.89,0.17
						c0.6,0.13,1.2,0.29,1.78,0.49c0.14,0.05,0.28,0.1,0.42,0.15c0.07,0.03,0.15,0.06,0.22,0.09c0.03,0.01,0.06,0.03,0.1,0.04
						c-0.09-0.04,0.02,0.01,0.05,0.02c0.27,0.12,0.54,0.25,0.8,0.39c0.54,0.29,1.05,0.63,1.53,1c0.47,0.36,0.95,0.74,1.49,1
						c0.62,0.29,1.28,0.42,1.96,0.28c1.31-0.27,2.18-1.43,3.18-2.22c0.24-0.19,0.21-0.63,0-0.82c-0.25-0.23-0.57-0.2-0.82,0
						c-0.44,0.34-0.83,0.74-1.25,1.1c-0.1,0.09-0.21,0.18-0.31,0.26c-0.02,0.02-0.09,0.07,0.01,0c-0.03,0.02-0.05,0.04-0.08,0.06
						c-0.06,0.05-0.13,0.09-0.19,0.13c-0.1,0.07-0.21,0.13-0.31,0.19c-0.05,0.03-0.11,0.05-0.16,0.08c0.13-0.06-0.07,0.02-0.11,0.04
						c-0.12,0.04-0.23,0.06-0.35,0.09c0.15-0.03-0.05,0-0.1,0c-0.07,0-0.15,0-0.22,0c-0.03,0-0.07-0.01-0.1-0.01
						c0.12,0.01,0.04,0.01,0.01,0c-0.07-0.02-0.14-0.03-0.21-0.05c-0.06-0.02-0.13-0.04-0.19-0.06c-0.03-0.01-0.06-0.02-0.09-0.03
						c-0.04-0.02-0.03-0.01,0.02,0.01c-0.02-0.01-0.05-0.02-0.07-0.03c-0.54-0.25-1.01-0.62-1.47-0.98
						c-0.99-0.75-2.05-1.34-3.22-1.77c-1.18-0.43-2.42-0.68-3.66-0.85c-1.38-0.18-2.77-0.24-4.16-0.26c-0.3,0-0.6,0.27-0.58,0.58
						C811.65,2479.62,811.89,2479.88,812.21,2479.88L812.21,2479.88z"/>
				</g>
				<g>
					<path className="st23" d="M818.72,2480.8c1.76-0.4,3.32-1.5,4.35-2.98c0.3-0.43,0.53-0.89,0.73-1.38c0.12-0.28-0.12-0.65-0.41-0.71
						c-0.34-0.08-0.59,0.1-0.71,0.41c-0.04,0.1,0.03-0.07-0.02,0.04c-0.02,0.04-0.04,0.09-0.06,0.13c-0.04,0.09-0.09,0.19-0.14,0.28
						c-0.09,0.18-0.19,0.35-0.3,0.51c-0.05,0.08-0.11,0.16-0.16,0.24c-0.03,0.04-0.06,0.08-0.09,0.12
						c-0.01,0.01-0.06,0.07-0.01,0.02c0.04-0.05-0.02,0.03-0.03,0.03c-0.12,0.16-0.26,0.3-0.4,0.45c-0.14,0.14-0.28,0.28-0.43,0.41
						c-0.08,0.07-0.16,0.13-0.24,0.2c-0.09,0.07,0.06-0.04-0.03,0.03c-0.04,0.03-0.09,0.07-0.14,0.1c-0.32,0.23-0.67,0.43-1.03,0.6
						c-0.05,0.02-0.1,0.05-0.15,0.07c-0.01,0-0.08,0.04-0.02,0.01c0.07-0.03-0.03,0.01-0.04,0.02c-0.1,0.04-0.21,0.08-0.31,0.12
						c-0.22,0.07-0.44,0.14-0.67,0.19c-0.3,0.07-0.5,0.43-0.41,0.72C818.11,2480.71,818.4,2480.87,818.72,2480.8L818.72,2480.8z"/>
				</g>
				<g>
					<path className="st23" d="M821.63,2482.31c3.26-1.14,5.45-3.97,7.26-6.76c1.96-3.02,4.07-5.96,5.71-9.17
						c0.93-1.83,1.7-3.75,2.17-5.75c0.07-0.3-0.09-0.64-0.41-0.72c-0.3-0.07-0.64,0.09-0.71,0.41c-0.2,0.87-0.46,1.72-0.76,2.55
						c-0.08,0.21-0.15,0.41-0.23,0.62c-0.04,0.1-0.08,0.2-0.12,0.31c-0.02,0.05-0.04,0.1-0.06,0.15c0.04-0.09-0.02,0.04-0.03,0.06
						c-0.17,0.4-0.36,0.8-0.55,1.2c-0.77,1.59-1.67,3.11-2.62,4.59c-0.96,1.51-1.97,2.99-2.94,4.49c-0.45,0.7-0.9,1.4-1.38,2.08
						c-0.12,0.17-0.24,0.33-0.36,0.5c-0.05,0.07-0.1,0.14-0.15,0.2c-0.03,0.04-0.06,0.08-0.09,0.12c0.08-0.1-0.05,0.07-0.07,0.08
						c-0.25,0.32-0.51,0.63-0.78,0.94c-0.3,0.34-0.62,0.66-0.95,0.97c-0.16,0.15-0.32,0.29-0.49,0.43
						c-0.09,0.07-0.18,0.15-0.27,0.22c-0.13,0.1,0.09-0.07-0.04,0.03c-0.05,0.04-0.11,0.08-0.16,0.12c-0.36,0.26-0.73,0.5-1.13,0.71
						c-0.19,0.1-0.38,0.2-0.58,0.29c-0.05,0.02-0.09,0.04-0.14,0.06c0.13-0.05-0.07,0.03-0.09,0.04c-0.11,0.04-0.22,0.08-0.33,0.12
						c-0.29,0.1-0.51,0.4-0.41,0.72C821.01,2482.18,821.33,2482.41,821.63,2482.31L821.63,2482.31z"/>
				</g>
				<g>
					<path className="st23" d="M825.99,2484.8c0.94,1.4,2.77,2.1,4.4,1.61c0.29-0.09,0.5-0.41,0.41-0.71c-0.1-0.29-0.4-0.5-0.71-0.41
						c-0.08,0.02-0.16,0.05-0.24,0.06c-0.04,0.01-0.09,0.02-0.13,0.03c-0.1,0.02-0.03,0.03,0,0c-0.03,0.03-0.2,0.02-0.25,0.02
						c-0.08,0-0.17,0-0.25,0c-0.08,0-0.17-0.01-0.25-0.02c-0.07-0.01,0.11,0.02,0,0c-0.05-0.01-0.09-0.02-0.14-0.02
						c-0.16-0.03-0.33-0.08-0.49-0.13c-0.04-0.01-0.07-0.03-0.11-0.04c-0.06-0.02-0.08-0.04-0.01,0c-0.08-0.04-0.17-0.08-0.25-0.12
						c-0.15-0.08-0.29-0.17-0.42-0.27c0.06,0.05,0.04,0.03-0.01-0.01c-0.03-0.02-0.06-0.05-0.09-0.07
						c-0.06-0.06-0.12-0.11-0.18-0.17c-0.06-0.06-0.11-0.12-0.17-0.19c-0.1-0.11,0.07,0.1-0.02-0.03c-0.03-0.04-0.05-0.07-0.08-0.11
						c-0.17-0.25-0.52-0.39-0.8-0.21C825.94,2484.17,825.81,2484.53,825.99,2484.8L825.99,2484.8z"/>
				</g>
				<g>
					<path className="st23" d="M827.88,2486.23c0.37,1.76,2.09,2.8,3.77,3.02c2.01,0.26,3.94-0.76,5.59-1.77
						c1.93-1.19,3.85-2.41,5.77-3.62c0.9-0.57,1.8-1.16,2.72-1.69c0.8-0.46,1.63-0.9,2.39-1.42c0.39-0.27,0.77-0.56,1.11-0.89
						c0.35-0.35,0.67-0.73,0.96-1.13c0.59-0.79,1.07-1.71,1.23-2.68c0.05-0.27,0.07-0.54,0.08-0.81c0-0.3-0.27-0.6-0.58-0.58
						c-0.32,0.01-0.58,0.26-0.58,0.58c0,0.11-0.01,0.21-0.01,0.31c0,0.03-0.01,0.07-0.01,0.1c0,0.01-0.01,0.09,0,0.03s0,0.02,0,0.03
						c-0.01,0.03-0.01,0.07-0.02,0.1c-0.04,0.22-0.1,0.44-0.18,0.66c-0.03,0.1-0.07,0.2-0.11,0.3c0.04-0.09-0.01,0.03-0.03,0.06
						c-0.03,0.06-0.06,0.13-0.09,0.19c-0.1,0.2-0.21,0.4-0.33,0.59c-0.12,0.2-0.26,0.39-0.4,0.58c-0.04,0.05,0,0,0.01-0.01
						c-0.02,0.03-0.04,0.06-0.07,0.08c-0.03,0.04-0.07,0.08-0.1,0.13c-0.07,0.09-0.15,0.18-0.23,0.26
						c-0.14,0.16-0.29,0.32-0.45,0.47c-0.07,0.06-0.14,0.13-0.21,0.19c-0.04,0.04-0.09,0.07-0.13,0.11
						c-0.02,0.02-0.04,0.03-0.06,0.05c-0.07,0.06,0.07-0.05,0,0c-0.71,0.53-1.48,0.97-2.25,1.4c-0.85,0.48-1.67,0.99-2.49,1.51
						c-1.88,1.18-3.75,2.35-5.63,3.53c-0.84,0.53-1.68,1.07-2.58,1.5c-0.1,0.05-0.2,0.1-0.31,0.14c-0.03,0.01-0.05,0.02-0.08,0.03
						c-0.01,0.01-0.12,0.05-0.06,0.03c0.05-0.02-0.03,0.01-0.03,0.01c-0.03,0.01-0.07,0.03-0.1,0.04c-0.23,0.09-0.46,0.17-0.69,0.24
						c-0.23,0.07-0.47,0.12-0.71,0.17c-0.06,0.01-0.11,0.02-0.17,0.03c0.1-0.02-0.04,0-0.07,0.01c-0.11,0.01-0.21,0.02-0.32,0.02
						c-0.19,0.01-0.38,0-0.56-0.01c-0.04,0-0.09-0.01-0.13-0.01c-0.14-0.02,0.07,0.01-0.04-0.01c-0.09-0.02-0.19-0.03-0.28-0.05
						c-0.19-0.04-0.37-0.09-0.55-0.16c-0.04-0.01-0.08-0.03-0.12-0.05c-0.02-0.01-0.14-0.06-0.07-0.03
						c0.07,0.03-0.07-0.03-0.09-0.04c-0.05-0.02-0.09-0.04-0.14-0.07c-0.16-0.09-0.32-0.18-0.48-0.29
						c-0.02-0.01-0.12-0.09-0.06-0.04c0.06,0.05-0.04-0.03-0.06-0.05c-0.07-0.06-0.14-0.12-0.2-0.18c-0.06-0.06-0.13-0.13-0.19-0.2
						c-0.01-0.02-0.1-0.11-0.05-0.05c0.05,0.06-0.04-0.05-0.05-0.07c-0.05-0.07-0.1-0.15-0.14-0.23c-0.04-0.06-0.12-0.17-0.12-0.24
						c0,0,0.04,0.11,0.02,0.04c-0.01-0.02-0.02-0.04-0.02-0.06c-0.02-0.05-0.03-0.09-0.05-0.14c-0.03-0.09-0.05-0.18-0.07-0.27
						c-0.06-0.3-0.43-0.5-0.71-0.41C827.97,2485.61,827.81,2485.91,827.88,2486.23L827.88,2486.23z"/>
				</g>
				<g>
					<path className="st23" d="M830.18,2489.23c0.21,0.21,0.46,0.35,0.73,0.44c0.14,0.04,0.28,0.06,0.42,0.08
						c0.15,0.01,0.3-0.01,0.44-0.03c0.13-0.02,0.28-0.16,0.35-0.27c0.07-0.12,0.1-0.31,0.06-0.45c-0.05-0.14-0.13-0.28-0.27-0.35
						c-0.05-0.02-0.09-0.04-0.14-0.06c-0.1-0.03-0.21-0.03-0.31,0c-0.01,0-0.02,0-0.03,0c0.05-0.01,0.1-0.01,0.15-0.02
						c-0.1,0.01-0.2,0.01-0.31,0c0.05,0.01,0.1,0.01,0.15,0.02c-0.11-0.02-0.21-0.04-0.31-0.08c0.05,0.02,0.09,0.04,0.14,0.06
						c-0.09-0.04-0.18-0.09-0.26-0.15c0.04,0.03,0.08,0.06,0.12,0.09c-0.04-0.03-0.08-0.07-0.12-0.11
						c-0.11-0.11-0.26-0.17-0.41-0.17c-0.14,0-0.31,0.06-0.41,0.17c-0.1,0.11-0.18,0.26-0.17,0.41
						C830.01,2488.98,830.07,2489.12,830.18,2489.23L830.18,2489.23z"/>
				</g>
				<g>
					<path className="st23" d="M813.86,2503.42c-0.01-0.01-0.04-0.08-0.01-0.02c0.03,0.07-0.01-0.02-0.01-0.04
						c-0.01-0.02-0.04-0.16-0.02-0.08c0.02,0.07,0,0.01,0-0.01c0-0.03,0-0.06,0-0.09c0-0.02,0-0.05,0-0.07c0-0.08-0.02,0.1,0,0.02
						c0.02-0.1,0.05-0.19,0.08-0.29c-0.03,0.1,0.01-0.01,0.03-0.06c0.03-0.05,0.05-0.1,0.08-0.15c0.01-0.02,0.1-0.15,0.07-0.1
						c-0.04,0.05,0.05-0.06,0.06-0.07c0.08-0.09,0.16-0.16,0.25-0.23c0.05-0.04,0,0-0.01,0.01c0.03-0.02,0.06-0.04,0.09-0.06
						c0.04-0.03,0.09-0.06,0.14-0.09c0.11-0.07,0.22-0.13,0.33-0.2c0.45-0.26,0.91-0.5,1.32-0.83c0.38-0.3,0.7-0.69,0.94-1.1
						c0.3-0.52,0.47-1.08,0.54-1.67c0.04-0.3-0.29-0.59-0.58-0.58c-0.35,0.02-0.54,0.26-0.58,0.58c0,0.03-0.02,0.11,0-0.01
						c0,0.02-0.01,0.05-0.01,0.07c-0.01,0.06-0.02,0.12-0.04,0.17c-0.03,0.11-0.06,0.23-0.1,0.34c-0.02,0.06-0.04,0.11-0.06,0.16
						c-0.02,0.06,0.01-0.01,0.01-0.02c-0.01,0.02-0.02,0.05-0.03,0.07c-0.05,0.11-0.11,0.23-0.18,0.33
						c-0.06,0.09-0.12,0.18-0.18,0.27c-0.04,0.05,0.01-0.01,0.02-0.02c-0.02,0.03-0.04,0.05-0.06,0.08
						c-0.04,0.05-0.09,0.1-0.13,0.15c-0.07,0.08-0.15,0.16-0.23,0.23c-0.04,0.04-0.09,0.08-0.13,0.11c0.08-0.07-0.04,0.03-0.07,0.05
						c-0.86,0.6-1.92,0.93-2.43,1.91c-0.3,0.58-0.36,1.22-0.06,1.81c0.14,0.27,0.54,0.37,0.8,0.21
						C813.93,2504.04,814,2503.71,813.86,2503.42L813.86,2503.42z"/>
				</g>
				<g>
					<path className="st23" d="M815.04,2502.47c0.3,0.14,0.59,0.31,0.86,0.5c0.04,0.03,0.07,0.05,0.11,0.08
						c-0.11-0.09,0.01,0.01,0.02,0.02c0.06,0.05,0.12,0.1,0.17,0.15c0.13,0.12,0.25,0.24,0.37,0.37c0.06,0.06,0.11,0.12,0.16,0.19
						c0.02,0.03,0.05,0.06,0.07,0.09c0.03,0.03,0.02,0.02-0.02-0.03c0.01,0.02,0.03,0.04,0.04,0.05c0.1,0.13,0.19,0.27,0.28,0.41
						c0.09,0.14,0.17,0.29,0.24,0.44c0.02,0.03,0.03,0.07,0.05,0.1c0.01,0.03,0.08,0.14,0.01,0.03c0.05,0.07,0.07,0.17,0.1,0.25
						c0.1,0.29,0.4,0.51,0.71,0.41c0.28-0.09,0.51-0.41,0.41-0.71c-0.51-1.45-1.59-2.69-2.99-3.34c-0.28-0.13-0.64-0.09-0.8,0.21
						C814.7,2501.93,814.75,2502.34,815.04,2502.47L815.04,2502.47z"/>
				</g>
				<g>
					<path className="st23" d="M817.36,2501.22c0.16,0,0.32,0,0.48,0.02c0.16,0.01-0.12-0.02,0.04,0c0.03,0.01,0.06,0.01,0.1,0.02
						c0.08,0.01,0.16,0.03,0.24,0.05c0.16,0.04,0.31,0.09,0.46,0.15c0.15,0.06-0.11-0.05,0.04,0.02c0.03,0.01,0.06,0.03,0.09,0.04
						c0.08,0.04,0.16,0.08,0.24,0.12c0.14,0.08,0.27,0.17,0.41,0.26c0.07,0.05-0.1-0.08,0.02,0.01c0.03,0.03,0.06,0.05,0.09,0.08
						c0.06,0.05,0.12,0.11,0.18,0.16c0.06,0.06,0.11,0.11,0.17,0.17c0.03,0.03,0.05,0.06,0.08,0.09c0.01,0.01,0.09,0.11,0.05,0.05
						c-0.05-0.06,0.03,0.05,0.04,0.06c0.02,0.03,0.05,0.07,0.07,0.1c0.05,0.07,0.1,0.15,0.14,0.23c0.15,0.26,0.53,0.38,0.8,0.21
						c0.27-0.17,0.37-0.51,0.21-0.8c-0.79-1.38-2.34-2.26-3.93-2.22c-0.3,0.01-0.6,0.26-0.58,0.58
						C816.79,2500.95,817.03,2501.23,817.36,2501.22L817.36,2501.22z"/>
				</g>
				<g>
					<path className="st23" d="M818.11,2499.37c0.07-0.09,0.15-0.18,0.24-0.26c0.04-0.04,0.08-0.07,0.12-0.1
						c0.01-0.01,0.11-0.08,0.03-0.03c-0.07,0.05,0.01,0,0.02-0.01c0.1-0.07,0.19-0.13,0.3-0.19c0.05-0.03,0.1-0.06,0.16-0.08
						c0.02-0.01,0.05-0.02,0.07-0.03c-0.04,0.03-0.1,0.04-0.01,0.01c0.1-0.04,0.21-0.07,0.32-0.1c0.06-0.01,0.11-0.03,0.17-0.04
						c0.03-0.01,0.05-0.01,0.08-0.01c0.09-0.02,0.04,0-0.02,0c0.11,0,0.22-0.02,0.33-0.02c0.12,0,0.25,0.01,0.37,0.02
						c0.14,0.02,0.32-0.08,0.41-0.17c0.1-0.1,0.18-0.27,0.17-0.41c-0.01-0.15-0.06-0.31-0.17-0.41c-0.12-0.11-0.25-0.15-0.41-0.17
						c-1.1-0.14-2.29,0.34-2.99,1.19c-0.19,0.23-0.25,0.6,0,0.82C817.5,2499.56,817.91,2499.62,818.11,2499.37L818.11,2499.37z"/>
				</g>
				<g>
					<path className="st23" d="M818.51,2497.8c-0.05-0.04-0.1-0.09-0.14-0.14c-0.05-0.05,0.09,0.13,0.04,0.05
						c-0.01-0.01-0.02-0.03-0.03-0.04c-0.02-0.03-0.04-0.06-0.05-0.09c-0.01-0.01-0.01-0.03-0.02-0.04
						c-0.03-0.05,0.05,0.13,0.03,0.07c-0.01-0.03-0.02-0.07-0.03-0.1c-0.02-0.07-0.03-0.14-0.04-0.21c0.01,0.05,0.01,0.1,0.02,0.15
						c-0.01-0.12-0.01-0.24,0-0.36c-0.01,0.05-0.01,0.1-0.02,0.15c0.01-0.09,0.03-0.19,0.06-0.28c0.01-0.02,0.02-0.04,0.02-0.07
						c-0.06,0.18-0.04,0.1-0.02,0.05c0.02-0.05,0.05-0.09,0.07-0.13c0.01-0.02,0.03-0.04,0.04-0.06c0.03-0.05-0.11,0.13-0.03,0.04
						c0.03-0.03,0.06-0.07,0.1-0.1c0.02-0.02,0.09-0.08,0-0.01c-0.09,0.07-0.01,0.01,0.01,0c0.04-0.03,0.09-0.05,0.14-0.07
						c0.12-0.06,0.23-0.22,0.27-0.35c0.04-0.14,0.02-0.32-0.06-0.45c-0.18-0.28-0.5-0.35-0.8-0.21c-1.08,0.5-1.3,2.23-0.38,2.99
						c0.12,0.1,0.25,0.17,0.41,0.17c0.14,0,0.31-0.06,0.41-0.17C818.7,2498.41,818.76,2498.01,818.51,2497.8L818.51,2497.8z"/>
				</g>
				<g>
					<path className="st23" d="M818.71,2497.26c-0.44-0.63-1.05-1.07-1.57-1.62c-0.06-0.06-0.12-0.13-0.17-0.19
						c-0.07-0.08,0.02,0.03-0.02-0.02c-0.02-0.04-0.05-0.07-0.07-0.11c-0.05-0.07-0.09-0.15-0.13-0.22
						c-0.02-0.04-0.04-0.08-0.06-0.12c-0.03-0.06,0.02,0.08-0.01-0.03c-0.03-0.08-0.06-0.16-0.07-0.24
						c-0.01-0.05-0.02-0.1-0.03-0.15c0.01,0.08,0.01,0.04,0-0.02c0-0.04,0-0.08,0-0.12c0-0.11-0.02,0.05,0-0.01
						c0.01-0.04,0.02-0.08,0.03-0.12c0.01-0.03,0.06-0.16,0.01-0.05c0.12-0.28,0.09-0.64-0.21-0.8c-0.25-0.13-0.67-0.09-0.8,0.21
						c-0.2,0.45-0.24,0.9-0.12,1.38c0.11,0.44,0.35,0.83,0.63,1.18c0.24,0.3,0.54,0.55,0.82,0.81c0.14,0.13,0.29,0.26,0.42,0.4
						c0.07,0.07,0.15,0.15,0.21,0.23c0.02,0.02,0.11,0.14,0.04,0.05c0.03,0.04,0.06,0.09,0.09,0.13c0.17,0.25,0.52,0.39,0.8,0.21
						C818.75,2497.9,818.9,2497.53,818.71,2497.26L818.71,2497.26z"/>
				</g>
				<g>
					<path className="st23" d="M816.67,2495.89c-0.21,0.01-0.41,0.01-0.62-0.01c-0.1-0.01-0.2-0.01-0.29-0.02
						c-0.03,0-0.2-0.03-0.07-0.01c-0.06-0.01-0.11-0.02-0.17-0.03c-0.4-0.07-0.8-0.18-1.19-0.32c-0.05-0.02-0.09-0.03-0.14-0.05
						c-0.02-0.01-0.05-0.02-0.07-0.03c-0.01,0-0.08-0.04-0.02-0.01c0.06,0.03-0.01-0.01-0.02-0.01c-0.02-0.01-0.04-0.02-0.07-0.03
						c-0.05-0.02-0.1-0.05-0.16-0.07c-0.19-0.09-0.38-0.2-0.56-0.31c-0.18-0.11-0.35-0.22-0.51-0.34c-0.02-0.01-0.04-0.03-0.06-0.04
						c0.01,0,0.07,0.06,0.02,0.02c-0.04-0.03-0.08-0.06-0.11-0.09c-0.08-0.07-0.16-0.14-0.24-0.21c-0.18-0.16-0.34-0.33-0.5-0.51
						c-0.04-0.04-0.08-0.09-0.11-0.13c-0.02-0.02-0.03-0.04-0.05-0.06c-0.01-0.01-0.07-0.09-0.03-0.04
						c0.04,0.05-0.01-0.01-0.01-0.02c-0.02-0.03-0.04-0.05-0.06-0.08c-0.04-0.05-0.08-0.11-0.11-0.16c-0.17-0.25-0.52-0.39-0.8-0.21
						c-0.25,0.16-0.39,0.53-0.21,0.8c1.05,1.54,2.71,2.66,4.55,3.01c0.53,0.1,1.06,0.15,1.6,0.13c0.3-0.01,0.6-0.26,0.58-0.58
						C817.24,2496.17,817,2495.88,816.67,2495.89L816.67,2495.89z"/>
				</g>
				<g>
					<path className="st23" d="M813.62,2495.52c-0.05,0.02-0.1,0.05-0.15,0.07c-0.17,0.07,0.1-0.04-0.07,0.03
						c-0.11,0.04-0.21,0.08-0.32,0.12c-0.2,0.07-0.41,0.14-0.62,0.19c-0.21,0.05-0.42,0.1-0.63,0.14c-0.06,0.01-0.11,0.02-0.17,0.03
						c-0.01,0-0.19,0.02-0.07,0.01c-0.11,0.01-0.22,0.03-0.34,0.04c-0.43,0.04-0.87,0.04-1.3,0c-0.11-0.01-0.22-0.02-0.34-0.03
						c-0.18-0.02,0.11,0.02-0.07-0.01c-0.06-0.01-0.11-0.02-0.17-0.03c-0.22-0.04-0.44-0.09-0.66-0.15
						c-0.29-0.08-0.64,0.1-0.71,0.41c-0.07,0.3,0.09,0.63,0.41,0.72c1.93,0.51,3.98,0.31,5.79-0.52c0.28-0.13,0.37-0.55,0.21-0.8
						C814.23,2495.44,813.92,2495.38,813.62,2495.52L813.62,2495.52z"/>
				</g>
				<g>
					<path className="st23" d="M812.46,2496.83c-0.51-0.28-1.11-0.2-1.58,0.13c-0.43,0.31-0.7,0.71-0.96,1.16
						c-0.1,0.18-0.21,0.35-0.32,0.53c-0.05,0.07,0.06-0.07,0.01-0.01c-0.01,0.02-0.03,0.03-0.04,0.05c-0.03,0.03-0.06,0.07-0.09,0.1
						c-0.01,0.01-0.13,0.11-0.07,0.07c0.06-0.04,0,0-0.01,0.01c-0.03,0.02-0.06,0.04-0.09,0.06c-0.02,0.01-0.04,0.02-0.06,0.03
						c0.01,0,0.1-0.03,0.03-0.02c-0.05,0.01-0.1,0.03-0.15,0.04c-0.11,0.03,0.09,0-0.02,0c-0.06,0-0.12,0-0.18,0
						c-0.02,0-0.05,0-0.07-0.01c-0.09,0,0.1,0.02,0.02,0c-0.05-0.01-0.09-0.02-0.14-0.02c-0.12-0.02-0.23-0.05-0.35-0.08
						c-0.53-0.13-1.09-0.27-1.63-0.08c-0.29,0.1-0.51,0.4-0.41,0.71c0.09,0.28,0.41,0.51,0.71,0.41c0.04-0.02,0.09-0.03,0.13-0.04
						c0.01,0,0.09-0.02,0.09-0.02c0,0.01-0.12,0.01-0.04,0.01c0.1,0,0.2,0,0.29,0.01c-0.12,0,0,0,0.05,0.01
						c0.05,0.01,0.09,0.02,0.14,0.03c0.11,0.02,0.22,0.05,0.33,0.08c0.46,0.12,0.93,0.21,1.41,0.11c0.5-0.11,0.88-0.46,1.16-0.87
						c0.23-0.33,0.39-0.7,0.62-1.03c-0.01,0.01-0.06,0.07-0.01,0.02c0.03-0.03,0.05-0.06,0.08-0.09c0.04-0.04,0.08-0.08,0.13-0.12
						c0.02-0.02,0.04-0.03,0.06-0.05c-0.04,0.04-0.05,0.03,0,0c0.05-0.03,0.11-0.07,0.16-0.1c0.01-0.01,0.08-0.04,0.01-0.01
						c-0.07,0.03,0.02-0.01,0.04-0.01c0.02-0.01,0.04-0.01,0.06-0.02c0.1-0.03-0.02,0.01-0.02,0c0,0,0.06,0,0.06,0c0.13,0,0,0,0,0
						c0,0,0.06,0.02,0.06,0.02c0.07,0.01-0.11-0.06,0.01,0.01c0.27,0.15,0.65,0.07,0.8-0.21
						C812.81,2497.35,812.75,2496.99,812.46,2496.83L812.46,2496.83z"/>
				</g>
				<g>
					<path className="st23" d="M810.71,2499.18c-0.94,0.28-1.51,1.07-2.03,1.84c-0.06,0.09-0.12,0.17-0.17,0.26
						c-0.03,0.05-0.07,0.09-0.1,0.14c-0.01,0.01-0.11,0.14-0.03,0.04c-0.14,0.17-0.28,0.33-0.43,0.47
						c-0.04,0.04-0.09,0.07-0.13,0.11c-0.03,0.03-0.02,0.02,0.02-0.02c-0.03,0.02-0.05,0.04-0.08,0.06
						c-0.08,0.05-0.16,0.1-0.24,0.14c-0.03,0.01-0.06,0.03-0.08,0.04c0.07-0.03,0.07-0.03,0.01-0.01c-0.04,0.01-0.09,0.03-0.13,0.04
						c-0.05,0.01-0.09,0.02-0.14,0.03c0.15-0.02-0.05,0-0.08,0c0.05,0.38,0.1,0.76,0.15,1.14c1-0.3,1.9-0.81,2.69-1.48
						c-0.3-0.04-0.61-0.08-0.91-0.12c0.05,0.06,0.09,0.13,0.13,0.2c0.02,0.03,0.04,0.07,0.05,0.1c0.01,0.02,0.02,0.05,0.03,0.07
						c-0.03-0.07-0.04-0.09-0.02-0.05c0.02,0.07,0.05,0.15,0.07,0.22c0.01,0.04,0.02,0.08,0.03,0.11c0,0.02,0.01,0.04,0.01,0.06
						c0.01,0.05,0.01,0.05,0,0c-0.01-0.05-0.01-0.05,0,0c0,0.02,0,0.04,0.01,0.06c0,0.05,0.01,0.09,0.01,0.14
						c0,0.08,0,0.16-0.01,0.24c0,0.02,0,0.11-0.01,0.12c0.02-0.01,0.02-0.14,0,0c-0.01,0.08-0.03,0.15-0.05,0.23
						c-0.02,0.08-0.05,0.15-0.07,0.23c-0.04,0.13,0.06-0.12,0,0.01c-0.02,0.04-0.03,0.07-0.05,0.11c-0.04,0.07-0.08,0.14-0.12,0.2
						c-0.02,0.03-0.04,0.06-0.07,0.1c-0.07,0.1,0.07-0.08,0,0.01c-0.09,0.1-0.21,0.28-0.33,0.33c0.07-0.05,0.07-0.06,0.02-0.02
						c-0.02,0.01-0.03,0.02-0.05,0.03c-0.03,0.02-0.07,0.04-0.1,0.06c-0.07,0.04-0.15,0.08-0.23,0.11c-0.28,0.12-0.36,0.55-0.21,0.8
						c0.19,0.29,0.49,0.34,0.8,0.21c1.55-0.66,2.16-2.89,1.18-4.25c-0.23-0.32-0.61-0.38-0.91-0.12c-0.08,0.07-0.17,0.14-0.25,0.21
						c0.09-0.07-0.04,0.03-0.06,0.04c-0.04,0.03-0.09,0.06-0.13,0.1c-0.19,0.13-0.38,0.25-0.58,0.36c-0.43,0.24-0.67,0.33-1.15,0.48
						c-0.28,0.08-0.46,0.35-0.42,0.64c0.04,0.28,0.28,0.5,0.57,0.5c1.06,0,1.83-0.79,2.4-1.6c0.12-0.17,0.23-0.35,0.35-0.52
						c0.06-0.09,0.13-0.18,0.2-0.28c0.08-0.11-0.05,0.06,0.03-0.04c0.04-0.05,0.08-0.1,0.12-0.15c0.06-0.07,0.13-0.14,0.19-0.21
						c0.04-0.04,0.09-0.09,0.14-0.13c0.02-0.01,0.14-0.1,0.03-0.03c0.09-0.06,0.18-0.12,0.27-0.17c0.02-0.01,0.16-0.07,0.03-0.02
						c0.06-0.02,0.12-0.04,0.18-0.06c0.29-0.09,0.5-0.41,0.41-0.72C811.32,2499.29,811.02,2499.09,810.71,2499.18L810.71,2499.18z"
						/>
				</g>
				<g>
					<path className="st23" d="M810.34,2500.85c0,0.56-0.01,1.12-0.01,1.68c0,0.56-0.04,1.13,0.06,1.68c0.05,0.27,0.17,0.53,0.28,0.78
						c0.05,0.11,0.11,0.22,0.16,0.33c0.01,0.03,0.07,0.15,0.03,0.06c0.03,0.06,0.05,0.13,0.07,0.2c0.03,0.1,0.06,0.2,0.08,0.3
						c0.01,0.06,0.03,0.12,0.04,0.18c0,0.03,0.01,0.05,0.01,0.08c-0.01-0.06-0.01-0.06,0,0c0.02,0.22,0.03,0.45,0.01,0.68
						c0,0.04-0.03,0.22-0.01,0.1c-0.01,0.06-0.02,0.12-0.03,0.18c-0.03,0.13-0.06,0.25-0.1,0.38c-0.02,0.06-0.04,0.11-0.06,0.17
						c-0.02,0.06,0.01-0.01,0.01-0.02c-0.01,0.03-0.03,0.06-0.04,0.09c-0.06,0.13-0.13,0.25-0.2,0.36c-0.16,0.26-0.06,0.65,0.21,0.8
						c0.29,0.15,0.62,0.07,0.8-0.21c0.56-0.89,0.73-1.97,0.52-3c-0.05-0.25-0.13-0.48-0.23-0.71c-0.05-0.13-0.11-0.25-0.17-0.38
						c-0.03-0.06-0.06-0.12-0.09-0.18c-0.01-0.03-0.08-0.18-0.04-0.09c-0.05-0.12-0.08-0.23-0.11-0.35c0-0.01-0.03-0.13-0.01-0.07
						c0.01,0.06,0-0.04,0-0.04c-0.01-0.07-0.01-0.14-0.01-0.21c-0.01-0.28,0-0.55,0-0.83c0-0.65,0.01-1.31,0.01-1.96
						c0-0.3-0.27-0.6-0.58-0.58C810.61,2500.29,810.34,2500.53,810.34,2500.85L810.34,2500.85z"/>
				</g>
				<g>
					<path className="st23" d="M810.32,2505.04c0.3,0,0.6-0.27,0.58-0.58c-0.01-0.31-0.26-0.58-0.58-0.58c-0.3,0-0.6,0.27-0.58,0.58
						C809.75,2504.77,809.99,2505.04,810.32,2505.04L810.32,2505.04z"/>
				</g>
				<g>
					<path className="st23" d="M812.54,2505.48c-0.08-0.1,0.02,0.03,0.03,0.04c0.02,0.03,0.04,0.07,0.07,0.1
						c0.04,0.07,0.08,0.14,0.12,0.21c0.02,0.04,0.03,0.07,0.05,0.11c-0.06-0.12,0.01,0.02,0.01,0.04c0.03,0.07,0.05,0.15,0.07,0.23
						c0.02,0.08,0.04,0.15,0.06,0.23c0.01,0.05,0.02,0.09,0.03,0.14c0.02,0.11,0-0.06,0,0.01c0.01,0.17,0.03,0.34,0.03,0.52
						c0,0.17-0.01,0.34-0.02,0.5c-0.01,0.09-0.02,0.19-0.03,0.28c0,0.04-0.01,0.08-0.02,0.13c0.01-0.12,0,0,0,0.02
						c-0.05,0.36-0.12,0.72-0.2,1.07c-0.06,0.3,0.09,0.64,0.41,0.72c0.29,0.07,0.65-0.09,0.71-0.41c0.17-0.79,0.33-1.6,0.31-2.41
						c-0.02-0.87-0.26-1.67-0.8-2.35c-0.19-0.24-0.63-0.21-0.82,0C812.31,2504.91,812.34,2505.22,812.54,2505.48L812.54,2505.48z"/>
				</g>
				<g>
					<path className="st23" d="M813.25,2506.56c0.07,0.04,0.14,0.08,0.2,0.13c0.03,0.02,0.05,0.04,0.08,0.06
						c0.13,0.09-0.05-0.05,0.02,0.01c0.12,0.1,0.23,0.2,0.34,0.3c0.11,0.11,0.22,0.24,0.32,0.36c-0.03-0.04-0.04-0.05,0,0
						c0.02,0.03,0.05,0.07,0.07,0.1c0.05,0.07,0.09,0.13,0.13,0.2c0.08,0.13,0.15,0.26,0.22,0.4c0.01,0.03,0.03,0.06,0.04,0.09
						c0.06,0.14-0.02-0.07,0.01,0.02c0.03,0.08,0.06,0.15,0.08,0.23c0.05,0.14,0.09,0.29,0.12,0.44c0.02,0.08,0.03,0.16,0.04,0.24
						c-0.01-0.05-0.01-0.08,0-0.01c0,0.05,0.01,0.1,0.01,0.14c0.02,0.3,0.25,0.6,0.58,0.58c0.29-0.01,0.61-0.26,0.58-0.58
						c-0.12-1.52-0.95-2.91-2.26-3.71c-0.26-0.16-0.65-0.07-0.8,0.21C812.9,2506.05,812.98,2506.39,813.25,2506.56L813.25,2506.56z"
						/>
				</g>
				<g>
					<path className="st23" d="M815.32,2507.41c0.07,0.02,0.14,0.04,0.22,0.07c0.04,0.01,0.07,0.03,0.11,0.04
						c0.01,0,0.02,0.01,0.04,0.01c0.06,0.02,0.05,0.02-0.03-0.01c0-0.01,0.34,0.17,0.37,0.19c0.13,0.08,0.26,0.16,0.38,0.25
						c0.09,0.07-0.09-0.08,0,0c0.02,0.02,0.05,0.04,0.07,0.06c0.06,0.05,0.11,0.1,0.17,0.15c0.11,0.1,0.2,0.2,0.3,0.31
						c0.02,0.03,0.05,0.06,0.07,0.09c0.07,0.09-0.07-0.1,0,0c0.04,0.06,0.08,0.11,0.12,0.17c0.08,0.12,0.15,0.25,0.22,0.37
						c0.01,0.02,0.1,0.2,0.1,0.2c0,0-0.05-0.14-0.01-0.02c0.02,0.04,0.03,0.08,0.05,0.12c0.1,0.29,0.4,0.51,0.71,0.41
						c0.28-0.09,0.52-0.41,0.41-0.71c-0.48-1.35-1.59-2.42-2.97-2.82c-0.29-0.09-0.65,0.1-0.71,0.41
						C814.84,2507.01,815.01,2507.32,815.32,2507.41L815.32,2507.41z"/>
				</g>
				<g>
					<path className="st23" d="M816.98,2507.17c1.78-0.38,3.69-0.89,5.02-2.22c0.73-0.73,1.2-1.68,1.44-2.69
						c0.24-1.04,0.24-2.09,0.28-3.14c0.01-0.15,0.02-0.29,0.03-0.44c0-0.04,0.01-0.08,0.01-0.12c-0.01,0.06,0,0.02,0-0.02
						c0.01-0.08,0.03-0.15,0.05-0.23c0.03-0.14,0.08-0.28,0.13-0.41c0.03-0.08-0.04,0.08,0,0.01c0.02-0.03,0.03-0.06,0.04-0.09
						c0.03-0.06,0.06-0.11,0.1-0.17c0.01-0.02,0.03-0.04,0.04-0.06c0.01-0.01,0.09-0.12,0.04-0.06c-0.04,0.05,0.01-0.01,0.01-0.02
						c0.02-0.02,0.04-0.05,0.06-0.07c0.04-0.05,0.09-0.09,0.14-0.13c0.02-0.02,0.05-0.04,0.07-0.06c-0.04,0.03-0.06,0.04,0,0.01
						c0.05-0.03,0.11-0.06,0.16-0.09c0.01-0.01,0.08-0.04,0.01-0.01c-0.07,0.04,0.05-0.02,0.06-0.02c0.05-0.02,0.1-0.02,0.15-0.04
						c0.08-0.03-0.1,0-0.02,0c0.04,0,0.08,0,0.12-0.01c0.3-0.01,0.6-0.26,0.58-0.58c-0.01-0.31-0.26-0.59-0.58-0.58
						c-1.13,0.02-1.96,0.98-2.22,2.01c-0.14,0.57-0.15,1.16-0.16,1.74c-0.01,0.51-0.03,1.02-0.09,1.52c0,0.01-0.01,0.1,0,0.03
						c0.01-0.07-0.01,0.04-0.01,0.06c-0.01,0.05-0.02,0.11-0.03,0.16c-0.02,0.13-0.05,0.26-0.08,0.4c-0.05,0.24-0.12,0.48-0.2,0.72
						c-0.02,0.06-0.04,0.12-0.06,0.17c-0.01,0.02-0.06,0.15-0.04,0.1c0.03-0.06-0.02,0.04-0.02,0.05c-0.01,0.02-0.02,0.05-0.03,0.07
						c-0.03,0.06-0.06,0.13-0.09,0.19c-0.11,0.22-0.25,0.42-0.39,0.62c0.06-0.08,0,0-0.03,0.03c-0.04,0.04-0.07,0.08-0.11,0.13
						c-0.08,0.09-0.16,0.17-0.25,0.25c-0.07,0.07-0.15,0.14-0.23,0.2c-0.04,0.04-0.09,0.07-0.13,0.1c0.09-0.07-0.04,0.03-0.07,0.05
						c-0.36,0.26-0.75,0.48-1.16,0.66c-0.03,0.01-0.2,0.08-0.08,0.04c-0.05,0.02-0.11,0.04-0.16,0.06
						c-0.12,0.05-0.24,0.09-0.37,0.14c-0.24,0.08-0.48,0.16-0.73,0.23c-0.5,0.14-1.01,0.26-1.52,0.37c-0.3,0.06-0.5,0.43-0.41,0.71
						C816.37,2507.08,816.66,2507.24,816.98,2507.17L816.98,2507.17z"/>
				</g>
				<g>
					<path className="st23" d="M824.34,2500.42c0.01-0.02,0.08-0.11,0.02-0.03c-0.05,0.07,0,0,0.02-0.02c0.04-0.04,0.08-0.09,0.12-0.13
						c0.08-0.08,0.19-0.15,0.26-0.24c-0.08,0.09-0.05,0.04-0.01,0.01c0.02-0.02,0.04-0.03,0.06-0.04c0.05-0.03,0.1-0.06,0.15-0.09
						c0.06-0.03,0.11-0.06,0.17-0.09c0.02-0.01,0.05-0.02,0.07-0.03c0.02-0.01,0.12-0.05,0.04-0.02c-0.08,0.03,0,0,0.02-0.01
						c0.03-0.01,0.06-0.02,0.09-0.03c0.06-0.02,0.11-0.04,0.17-0.05c0.06-0.02,0.11-0.03,0.17-0.04c0.03-0.01,0.05-0.01,0.08-0.02
						c0.02,0,0.14-0.02,0.04-0.01c-0.09,0.01,0,0,0.02,0c0.03,0,0.06-0.01,0.1-0.01c0.06,0,0.12-0.01,0.17-0.01
						c0.12,0,0.25,0.01,0.37,0.03c0.14,0.02,0.32-0.08,0.41-0.17c0.1-0.1,0.18-0.27,0.17-0.41c-0.01-0.15-0.06-0.31-0.17-0.41
						c-0.12-0.11-0.25-0.15-0.41-0.17c-1.21-0.16-2.42,0.44-3.12,1.42c-0.18,0.25-0.05,0.66,0.21,0.8
						C823.85,2500.79,824.15,2500.68,824.34,2500.42L824.34,2500.42z"/>
				</g>
				<g>
					<path className="st23" d="M823.52,2502.23c0.45-0.16,0.9-0.3,1.37-0.41c0.23-0.05,0.46-0.1,0.7-0.14c0.12-0.02,0.23-0.04,0.35-0.06
						c0.19-0.03-0.11,0.01,0.08-0.01c0.06-0.01,0.12-0.01,0.18-0.02c0.95-0.1,1.9-0.09,2.85,0.03c0.3,0.04,0.59-0.3,0.58-0.58
						c-0.02-0.35-0.26-0.54-0.58-0.58c-1.96-0.25-3.98-0.03-5.83,0.65c-0.29,0.1-0.51,0.4-0.41,0.72
						C822.89,2502.11,823.21,2502.34,823.52,2502.23L823.52,2502.23z"/>
				</g>
				<g>
					<path className="st23" d="M823.55,2503.73c-0.09,0.12,0.02-0.02,0.04-0.04c0.02-0.02,0.04-0.04,0.06-0.05
						c-0.06,0.04-0.07,0.06-0.04,0.03c0.04-0.02,0.08-0.05,0.12-0.07c0.02-0.01,0.05-0.02,0.07-0.04c-0.08,0.03-0.09,0.04-0.03,0.01
						c0.06-0.02,0.22-0.09,0.27-0.07c-0.09,0.01-0.1,0.01-0.03,0c0.01,0,0.03,0,0.04,0c0.06,0,0.11-0.01,0.17-0.01
						c0.1,0,0.2,0.01,0.31,0.02c0.07,0.01,0.06,0.01-0.02,0c0.03,0,0.06,0.01,0.09,0.01c0.04,0.01,0.09,0.02,0.13,0.03
						c0.21,0.05,0.42,0.11,0.62,0.18c0.13,0.05,0.26,0.1,0.39,0.15c0.05,0.02,0.11,0.04,0.16,0.07c0.06,0.03,0.07,0.03,0.03,0.01
						c0.04,0.02,0.07,0.03,0.11,0.05c0.25,0.11,0.51,0.23,0.75,0.35c0.51,0.25,1.02,0.53,1.51,0.83c0.26,0.16,0.65,0.07,0.8-0.21
						c0.15-0.28,0.07-0.63-0.21-0.8c-0.54-0.33-1.09-0.63-1.67-0.9c-0.56-0.27-1.14-0.53-1.73-0.72c-0.47-0.15-0.99-0.26-1.48-0.21
						c-0.61,0.06-1.1,0.3-1.47,0.8c-0.18,0.25-0.05,0.66,0.21,0.8C823.06,2504.1,823.36,2503.99,823.55,2503.73L823.55,2503.73z"/>
				</g>
				<g>
					<path className="st23" d="M822.52,2505.35c-0.18-0.02,0.06,0.01,0.1,0.02c0.07,0.01,0.14,0.03,0.21,0.05
						c0.06,0.02,0.13,0.04,0.19,0.06c0.04,0.01,0.07,0.03,0.11,0.04c0.02,0.01,0.12,0.06,0.04,0.02c-0.08-0.04,0.02,0.01,0.04,0.02
						c0.04,0.02,0.07,0.03,0.11,0.05c0.06,0.03,0.12,0.06,0.17,0.09c0.06,0.04,0.12,0.08,0.18,0.12c0.03,0.02,0.05,0.04,0.08,0.06
						c0.03,0.02,0.14,0.08,0.01,0c0.11,0.07,0.22,0.18,0.31,0.27c0.09,0.08,0.23,0.2,0.28,0.31c-0.05-0.11-0.03-0.03,0,0.01
						c0.02,0.03,0.04,0.05,0.06,0.08c0.04,0.06,0.08,0.12,0.12,0.18c0.03,0.06,0.06,0.11,0.09,0.17c0.02,0.04,0.03,0.07,0.05,0.11
						c0.05,0.11-0.04-0.11,0,0.01c0.06,0.15,0.13,0.26,0.27,0.35c0.12,0.07,0.31,0.1,0.45,0.06c0.27-0.09,0.53-0.41,0.41-0.72
						c-0.27-0.66-0.68-1.24-1.24-1.68c-0.6-0.47-1.28-0.75-2.04-0.85c-0.3-0.04-0.59,0.3-0.58,0.58
						C821.95,2505.12,822.19,2505.31,822.52,2505.35L822.52,2505.35z"/>
				</g>
				<g>
					<path className="st23" d="M817.85,2508.24C817.94,2508.17,817.87,2508.22,817.85,2508.24c0.02-0.02,0.05-0.04,0.08-0.06
						c0.05-0.04,0.11-0.07,0.16-0.11c0.11-0.07,0.22-0.13,0.34-0.19c0.06-0.03,0.12-0.06,0.18-0.08c0.02-0.01,0.1-0.04,0,0
						c0.03-0.01,0.06-0.02,0.09-0.04c0.12-0.05,0.25-0.09,0.37-0.12c0.13-0.03,0.25-0.06,0.38-0.08c0.02,0,0.05-0.01,0.07-0.01
						c0.09-0.02-0.1,0.01-0.01,0c0.07-0.01,0.15-0.02,0.22-0.02c0.26-0.02,0.52-0.01,0.78,0.02c-0.13-0.01,0.05,0.01,0.09,0.02
						c0.06,0.01,0.11,0.02,0.17,0.03c0.13,0.03,0.27,0.07,0.4,0.11c0.06,0.02,0.12,0.05,0.18,0.07c-0.11-0.04,0.03,0.01,0.06,0.03
						c0.13,0.06,0.26,0.13,0.38,0.21c0.11,0.07,0.22,0.14,0.32,0.21c0.03,0.02,0.05,0.04,0.08,0.06c0.07,0.05-0.06-0.05,0,0
						c0.06,0.05,0.12,0.1,0.18,0.15c0.21,0.18,0.4,0.37,0.59,0.58c0.11,0.12,0.21,0.23,0.31,0.35c0.04,0.05,0.09,0.11,0.13,0.16
						c0.02,0.03,0.04,0.05,0.07,0.08c0,0,0.09,0.12,0.05,0.06c-0.04-0.05,0.03,0.03,0.03,0.04c0.03,0.03,0.05,0.07,0.08,0.1
						c0.05,0.06,0.09,0.13,0.14,0.19c0.1,0.13,0.19,0.27,0.29,0.4c0.18,0.25,0.51,0.39,0.8,0.21c0.24-0.16,0.4-0.53,0.21-0.8
						c-0.74-1.02-1.5-2.05-2.58-2.75c-1.06-0.68-2.37-0.88-3.59-0.6c-0.69,0.16-1.32,0.49-1.87,0.93c-0.24,0.19-0.21,0.62,0,0.82
						C817.28,2508.47,817.6,2508.44,817.85,2508.24L817.85,2508.24z"/>
				</g>
				<g>
					<path className="st23" d="M817.67,2508.61c0.19-0.02,0.38-0.02,0.57,0c0.02,0,0.18,0.03,0.05,0c0.05,0.01,0.1,0.02,0.15,0.03
						c0.08,0.02,0.17,0.04,0.25,0.06c0.1,0.03,0.2,0.06,0.29,0.09c0.04,0.01,0.08,0.03,0.12,0.05c0.04,0.02,0.1,0.04,0.03,0.01
						c0.18,0.08,0.36,0.17,0.54,0.27c0.17,0.1,0.34,0.2,0.5,0.31c0.08,0.06,0.16,0.11,0.24,0.17c0.04,0.03,0.09,0.06,0.13,0.1
						c0.08,0.06-0.02-0.01,0.03,0.03c0.31,0.25,0.62,0.51,0.92,0.78c0.23,0.2,0.6,0.24,0.82,0c0.2-0.22,0.24-0.61,0-0.82
						c-1.28-1.14-2.82-2.4-4.65-2.24c-0.3,0.03-0.6,0.25-0.58,0.58C817.11,2508.32,817.35,2508.64,817.67,2508.61L817.67,2508.61z"
						/>
				</g>
				<g>
					<path className="st23" d="M820.62,2506.75c0.3,0,0.6-0.27,0.58-0.58c-0.01-0.31-0.26-0.58-0.58-0.58c-0.3,0-0.6,0.27-0.58,0.58
						C820.06,2506.49,820.3,2506.75,820.62,2506.75L820.62,2506.75z"/>
				</g>
				<g>
					<path className="st23" d="M815.99,2508.47c0.3,0,0.6-0.27,0.58-0.58c-0.01-0.31-0.26-0.58-0.58-0.58c-0.3,0-0.6,0.27-0.58,0.58
						C815.42,2508.21,815.66,2508.47,815.99,2508.47L815.99,2508.47z"/>
				</g>
				<g>
					<path className="st23" d="M816.71,2491.74c0.56,0.22,1.19,0.11,1.72-0.14c0.54-0.25,0.96-0.7,1.27-1.19c0.31-0.47,0.52-1,0.74-1.51
						c0.26-0.6,0.53-1.2,0.79-1.8c0.12-0.28,0.09-0.64-0.21-0.8c-0.25-0.13-0.66-0.09-0.8,0.21c-0.24,0.55-0.48,1.1-0.72,1.65
						c-0.21,0.48-0.41,0.97-0.67,1.43c-0.06,0.1-0.12,0.2-0.18,0.3c-0.01,0.02-0.14,0.19-0.07,0.1c-0.03,0.04-0.07,0.08-0.1,0.12
						c-0.08,0.09-0.17,0.17-0.26,0.26c-0.05,0.04-0.05,0.04,0,0c-0.02,0.01-0.04,0.03-0.06,0.04c-0.05,0.03-0.1,0.06-0.15,0.09
						c-0.04,0.03-0.09,0.05-0.13,0.07c-0.06,0.03-0.06,0.03,0,0c-0.03,0.01-0.06,0.02-0.09,0.03c-0.11,0.04-0.22,0.07-0.33,0.08
						c0.14-0.02-0.02,0-0.04,0c-0.06,0-0.13,0-0.19-0.01c0.1,0.01,0.02,0-0.03-0.01c-0.06-0.02-0.12-0.04-0.18-0.06
						c-0.28-0.11-0.65,0.12-0.71,0.41C816.22,2491.36,816.4,2491.63,816.71,2491.74L816.71,2491.74z"/>
				</g>
				<g>
					<path className="st23" d="M817.84,2492.09c0.86,0.66,2.03,0.74,3.05,0.49c1.08-0.27,2-0.96,2.76-1.75c0.43-0.45,0.82-0.93,1.2-1.42
						c0.19-0.24,0.25-0.59,0-0.82c-0.21-0.19-0.62-0.25-0.82,0c-0.32,0.4-0.64,0.8-0.98,1.18c-0.17,0.19-0.35,0.37-0.53,0.55
						c-0.09,0.08-0.17,0.16-0.26,0.24c-0.05,0.04-0.1,0.08-0.14,0.12c-0.02,0.02-0.04,0.03-0.06,0.05
						c-0.07,0.06,0.06-0.05-0.01,0.01c-0.2,0.15-0.41,0.29-0.63,0.41c-0.1,0.06-0.2,0.1-0.31,0.15c-0.06,0.03,0.02-0.01,0.03-0.01
						c-0.02,0.01-0.05,0.02-0.07,0.03c-0.06,0.02-0.12,0.05-0.19,0.07c-0.1,0.03-0.21,0.06-0.31,0.09
						c-0.06,0.01-0.11,0.03-0.17,0.04c-0.02,0-0.05,0.01-0.07,0.01c0,0-0.15,0.03-0.07,0.01c0.07-0.01-0.01,0-0.02,0
						c-0.03,0-0.07,0.01-0.1,0.01c-0.05,0-0.1,0.01-0.15,0.01c-0.12,0.01-0.24,0-0.36,0c-0.06,0-0.11-0.01-0.17-0.01
						c0.14,0.01-0.05-0.01-0.08-0.02c-0.12-0.02-0.25-0.06-0.37-0.1c-0.04-0.01-0.2-0.09-0.08-0.03c-0.05-0.03-0.1-0.05-0.15-0.07
						c-0.12-0.06-0.23-0.14-0.34-0.22c-0.24-0.19-0.66-0.05-0.8,0.21C817.47,2491.6,817.58,2491.89,817.84,2492.09L817.84,2492.09z"
						/>
				</g>
				<g>
					<path className="st23" d="M819.46,2493.29c0.05,0.03,0.09,0.06,0.14,0.1c0.08,0.06,0,0-0.01-0.01c0.02,0.02,0.05,0.04,0.07,0.06
						c0.1,0.08,0.19,0.17,0.28,0.25c0.18,0.17,0.35,0.35,0.52,0.52c0.38,0.37,0.78,0.72,1.28,0.92c0.59,0.23,1.21,0.13,1.78-0.1
						c0.64-0.27,1.26-0.57,1.88-0.89c0.66-0.35,1.3-0.72,1.93-1.12c0.26-0.16,0.38-0.52,0.21-0.8c-0.17-0.26-0.52-0.38-0.8-0.21
						c-1.06,0.68-2.17,1.28-3.32,1.8c-0.06,0.03-0.13,0.06-0.19,0.09c-0.04,0.02-0.07,0.03-0.11,0.05c0.02-0.01,0.09-0.03-0.02,0.01
						c-0.15,0.06-0.29,0.1-0.44,0.14c-0.02,0-0.11,0.02-0.02,0.01c-0.03,0-0.06,0.01-0.09,0.01c-0.06,0-0.13,0-0.19,0
						c-0.14-0.01,0.07,0.02-0.03,0c-0.06-0.02-0.12-0.03-0.18-0.05c-0.03-0.01-0.05-0.02-0.08-0.03c-0.06-0.02,0.05,0.02,0.05,0.02
						c-0.03,0.01-0.12-0.06-0.14-0.07c-0.09-0.05-0.21-0.11-0.27-0.18c0.1,0.11-0.02-0.02-0.05-0.04c-0.04-0.03-0.08-0.07-0.11-0.1
						c-0.09-0.08-0.17-0.16-0.25-0.24c-0.4-0.39-0.78-0.8-1.25-1.11c-0.25-0.17-0.65-0.06-0.8,0.21
						C819.1,2492.79,819.19,2493.11,819.46,2493.29L819.46,2493.29z"/>
				</g>
				<g>
					<path className="st23" d="M809.57,2492.75c0.69-0.84,1.02-1.87,1.07-2.94c0.05-1.09-0.22-2.18-0.61-3.19
						c-0.24-0.63-0.52-1.25-0.8-1.86c-0.13-0.28-0.55-0.37-0.8-0.21c-0.29,0.18-0.34,0.5-0.21,0.8c0.11,0.23,0.21,0.47,0.32,0.71
						c0.05,0.12,0.1,0.24,0.15,0.36c0.03,0.06,0,0.01,0,0c0.02,0.04,0.03,0.07,0.05,0.11c0.03,0.06,0.05,0.13,0.08,0.19
						c0.19,0.49,0.36,0.98,0.49,1.49c0.03,0.12,0.06,0.25,0.08,0.38c0.01,0.06,0.02,0.12,0.03,0.18c0.01,0.04,0.03,0.2,0.01,0.08
						c0.03,0.23,0.04,0.46,0.05,0.69c0,0.22-0.02,0.44-0.04,0.67c-0.01,0.07,0-0.02,0-0.03c0,0.03-0.01,0.07-0.02,0.1
						c-0.01,0.05-0.02,0.1-0.03,0.15c-0.02,0.12-0.05,0.23-0.08,0.35c-0.03,0.12-0.07,0.24-0.12,0.36
						c-0.01,0.03-0.07,0.17-0.03,0.07c-0.02,0.05-0.05,0.11-0.07,0.16c-0.06,0.11-0.12,0.22-0.18,0.33
						c-0.04,0.06-0.07,0.11-0.11,0.17c-0.01,0.02-0.03,0.04-0.04,0.06c-0.05,0.08,0.08-0.1-0.02,0.02c-0.19,0.23-0.25,0.59,0,0.82
						C808.96,2492.95,809.36,2493,809.57,2492.75L809.57,2492.75z"/>
				</g>
				<g>
					<path className="st23" d="M810.1,2492.38c1.67-0.61,2.97-2.06,3.4-3.79c0.12-0.48,0.18-0.99,0.15-1.49
						c-0.02-0.3-0.26-0.6-0.58-0.58c-0.3,0.01-0.6,0.26-0.58,0.58c0.01,0.25,0,0.49-0.02,0.74c-0.01,0.07-0.01,0.06,0-0.02
						c0,0.03-0.01,0.06-0.01,0.09c-0.01,0.06-0.02,0.12-0.03,0.18c-0.02,0.11-0.05,0.21-0.08,0.32c-0.03,0.12-0.07,0.24-0.11,0.35
						c-0.02,0.06-0.04,0.12-0.07,0.17c0.06-0.14-0.02,0.05-0.03,0.07c-0.1,0.22-0.22,0.43-0.35,0.63c-0.06,0.1-0.13,0.19-0.2,0.29
						c0.09-0.11-0.04,0.04-0.05,0.06c-0.04,0.05-0.08,0.09-0.12,0.14c-0.16,0.18-0.33,0.35-0.52,0.5c-0.02,0.02-0.16,0.12-0.05,0.04
						c-0.05,0.04-0.1,0.07-0.15,0.11c-0.1,0.07-0.21,0.14-0.31,0.2c-0.11,0.06-0.22,0.12-0.33,0.17c-0.02,0.01-0.21,0.09-0.07,0.03
						c-0.06,0.02-0.12,0.05-0.17,0.07c-0.29,0.1-0.51,0.4-0.41,0.72C809.47,2492.26,809.79,2492.49,810.1,2492.38L810.1,2492.38z"/>
				</g>
				<g>
					<path className="st23" d="M811.66,2491.83c0.72-0.07,1.47-0.11,2.15-0.37c0.6-0.22,1.13-0.56,1.59-1c0.95-0.89,1.53-2.1,1.89-3.34
						c0.22-0.74,0.36-1.5,0.5-2.26c0.06-0.3-0.08-0.64-0.41-0.72c-0.29-0.07-0.66,0.09-0.71,0.41c-0.12,0.64-0.24,1.29-0.41,1.92
						c-0.08,0.31-0.17,0.61-0.28,0.91c-0.05,0.15-0.11,0.3-0.17,0.44c0.03-0.08-0.01,0.01-0.01,0.02c-0.02,0.04-0.03,0.07-0.05,0.11
						c-0.03,0.07-0.07,0.15-0.11,0.22c-0.12,0.24-0.26,0.48-0.42,0.71c-0.04,0.05-0.08,0.11-0.12,0.16
						c-0.02,0.03-0.04,0.05-0.06,0.08c0.01-0.02,0.07-0.09,0,0c-0.08,0.1-0.17,0.2-0.26,0.3c-0.09,0.1-0.18,0.19-0.28,0.28
						c-0.05,0.04-0.1,0.09-0.15,0.13c-0.03,0.02-0.05,0.04-0.08,0.06c0.06-0.05,0.02-0.02-0.02,0.01c-0.22,0.16-0.45,0.29-0.69,0.41
						c-0.02,0.01-0.1,0.04,0,0c-0.04,0.02-0.08,0.03-0.11,0.04c-0.08,0.03-0.16,0.06-0.24,0.08c-0.17,0.05-0.35,0.09-0.53,0.12
						c-0.04,0.01-0.08,0.01-0.12,0.02c0,0-0.19,0.02-0.09,0.01c-0.09,0.01-0.18,0.02-0.27,0.03c-0.19,0.02-0.38,0.04-0.57,0.05
						c-0.3,0.03-0.6,0.25-0.58,0.58C811.09,2491.54,811.34,2491.86,811.66,2491.83L811.66,2491.83z"/>
				</g>
				<g>
					<path className="st23" d="M814.98,2491.25c0.63,0.17,1.26,0.34,1.89,0.52c0.29,0.08,0.65-0.1,0.71-0.41
						c0.07-0.31-0.09-0.63-0.41-0.71c-0.63-0.17-1.26-0.34-1.89-0.52c-0.29-0.08-0.65,0.1-0.71,0.41
						C814.5,2490.84,814.66,2491.16,814.98,2491.25L814.98,2491.25z"/>
				</g>
				<g>
					<path className="st23" d="M804.8,2500.73c-1.14-1.56-2.7-2.74-4.52-3.41c-0.49-0.18-0.99-0.32-1.51-0.41
						c-0.3-0.05-0.64,0.08-0.71,0.41c-0.06,0.28,0.09,0.66,0.41,0.71c0.43,0.08,0.85,0.18,1.27,0.32c0.1,0.03,0.19,0.07,0.29,0.1
						c0.05,0.02,0.09,0.04,0.14,0.05c0.03,0.01,0.06,0.03,0.09,0.04c0.05,0.02,0.05,0.02,0,0c0.2,0.09,0.4,0.18,0.59,0.28
						c0.38,0.2,0.75,0.42,1.1,0.67c0.09,0.06,0.18,0.13,0.26,0.19c-0.08-0.06,0.01,0.01,0.04,0.03c0.05,0.04,0.09,0.07,0.14,0.11
						c0.17,0.14,0.33,0.29,0.49,0.44c0.18,0.17,0.34,0.35,0.5,0.53c0.08,0.09,0.15,0.18,0.23,0.27c0.02,0.02,0.13,0.17,0.06,0.08
						c0.04,0.05,0.08,0.11,0.12,0.16c0.18,0.25,0.51,0.39,0.8,0.21C804.84,2501.37,804.99,2500.99,804.8,2500.73L804.8,2500.73z"/>
				</g>
				<g>
					<path className="st23" d="M804.76,2499.82c0.1-0.69,0.31-1.36,0.43-2.05c0.12-0.69,0.14-1.39-0.05-2.07
						c-0.32-1.14-1.12-2.08-2-2.83c-0.52-0.45-1.08-0.85-1.64-1.24c-0.25-0.17-0.66-0.05-0.8,0.21c-0.16,0.3-0.06,0.61,0.21,0.8
						c0.23,0.16,0.45,0.32,0.67,0.48c0.05,0.03,0.09,0.07,0.14,0.1c0.03,0.02,0.05,0.04,0.08,0.06c0.01,0.01,0.1,0.07,0.04,0.03
						s0.03,0.02,0.04,0.03c0.03,0.02,0.05,0.04,0.08,0.06c0.06,0.05,0.12,0.09,0.17,0.14c0.41,0.34,0.81,0.71,1.15,1.12
						c0.02,0.03,0.13,0.17,0.06,0.07c0.04,0.05,0.08,0.1,0.11,0.15c0.08,0.11,0.16,0.23,0.23,0.35c0.06,0.11,0.12,0.21,0.17,0.33
						c0.02,0.03,0.08,0.2,0.03,0.07c0.02,0.06,0.05,0.13,0.07,0.19c0.04,0.11,0.07,0.22,0.1,0.33c0.01,0.06,0.03,0.12,0.04,0.18
						c0.01,0.03,0.01,0.06,0.02,0.09c-0.03-0.14,0,0.01,0,0.03c0.01,0.15,0.02,0.31,0.01,0.46c0,0.08-0.01,0.16-0.01,0.24
						c0,0.04-0.01,0.08-0.01,0.12c0,0.02,0,0.04-0.01,0.06c0.01-0.05,0.01-0.05,0-0.01c-0.12,0.74-0.35,1.46-0.45,2.2
						c-0.04,0.3,0.07,0.64,0.41,0.71C804.32,2500.29,804.71,2500.14,804.76,2499.82L804.76,2499.82z"/>
				</g>
				<g>
					<path className="st23" d="M804.3,2499.06c0.88-1.2,1.72-2.43,2.28-3.82c0.56-1.41,0.68-2.94,0.2-4.39
						c-0.27-0.82-0.68-1.58-1.16-2.29c-0.17-0.25-0.52-0.39-0.8-0.21c-0.25,0.16-0.39,0.53-0.21,0.8c0.2,0.3,0.39,0.6,0.56,0.92
						c0.08,0.15,0.16,0.3,0.23,0.45c0.02,0.03,0.03,0.07,0.05,0.1c-0.01-0.01,0.07,0.16,0.04,0.09s0.04,0.1,0.03,0.09
						c0.02,0.05,0.04,0.1,0.06,0.16c0.12,0.32,0.21,0.66,0.28,1c0.01,0.05,0.02,0.09,0.02,0.14c0,0.02,0.01,0.11,0,0.01
						c0.01,0.08,0.02,0.17,0.02,0.25c0.01,0.17,0.02,0.34,0.01,0.52c0,0.16-0.02,0.32-0.03,0.48c-0.02,0.17,0.01-0.07,0,0.03
						c-0.01,0.04-0.01,0.09-0.02,0.13c-0.01,0.09-0.03,0.18-0.05,0.26c-0.07,0.33-0.17,0.66-0.29,0.98
						c-0.03,0.08-0.06,0.15-0.09,0.23c-0.01,0.02-0.06,0.13-0.02,0.04c-0.01,0.03-0.03,0.07-0.04,0.1c-0.07,0.17-0.15,0.33-0.24,0.5
						c-0.16,0.31-0.33,0.61-0.5,0.9c-0.41,0.68-0.87,1.32-1.34,1.96c-0.18,0.25-0.05,0.66,0.21,0.8
						C803.81,2499.43,804.11,2499.32,804.3,2499.06L804.3,2499.06z"/>
				</g>
				<g>
					<path className="st23" d="M805.16,2495.75c0.92-0.06,1.92-0.21,2.63-0.84c0.36-0.32,0.57-0.76,0.63-1.22
						c0.02-0.15,0.04-0.3,0.05-0.46c0-0.04,0.01-0.09,0.01-0.13c0.01-0.07,0.01-0.06-0.01,0.03c0-0.03,0.01-0.05,0.01-0.08
						c0.01-0.05,0.02-0.11,0.04-0.16c0.01-0.03,0.01-0.05,0.02-0.08c0.02-0.06,0.02-0.05-0.01,0.03c0.01-0.02,0.02-0.05,0.04-0.07
						c0.03-0.05,0.03-0.04-0.02,0.02c0-0.03,0.1-0.09,0.11-0.11c0.24-0.19,0.22-0.62,0-0.82c-0.25-0.23-0.57-0.21-0.82,0
						c-0.41,0.33-0.49,0.87-0.53,1.36c-0.01,0.06-0.01,0.12-0.02,0.17c0,0.03-0.01,0.06-0.01,0.09c0.01-0.08,0.01-0.09,0-0.03
						c-0.02,0.1-0.04,0.19-0.07,0.28c-0.01,0.02-0.02,0.05-0.03,0.07c0.03-0.08,0.04-0.09,0.01-0.03c-0.02,0.03-0.04,0.07-0.05,0.1
						c-0.01,0.02-0.03,0.04-0.04,0.06c-0.04,0.05-0.03,0.04,0.02-0.03c0,0-0.08,0.09-0.09,0.1c-0.03,0.03-0.06,0.06-0.09,0.09
						c-0.05,0.04-0.04,0.03,0.03-0.02c-0.02,0.02-0.04,0.03-0.06,0.04c-0.07,0.05-0.15,0.1-0.23,0.14c-0.04,0.02-0.1,0.07-0.14,0.07
						c0.02-0.01,0.04-0.02,0.07-0.03c-0.02,0.01-0.05,0.02-0.07,0.03c-0.1,0.04-0.19,0.07-0.29,0.09c-0.2,0.05-0.39,0.09-0.59,0.12
						c-0.07,0.01-0.06,0.01,0.03,0c-0.03,0-0.06,0.01-0.08,0.01c-0.04,0-0.08,0.01-0.12,0.01c-0.11,0.01-0.22,0.02-0.32,0.03
						c-0.3,0.02-0.6,0.25-0.58,0.58C804.6,2495.47,804.84,2495.77,805.16,2495.75L805.16,2495.75z"/>
				</g>
				<g>
					<path className="st23" d="M807.74,2492.84c0.3,0,0.6-0.27,0.58-0.58c-0.01-0.31-0.26-0.58-0.58-0.58c-0.3,0-0.6,0.27-0.58,0.58
						C807.17,2492.57,807.41,2492.84,807.74,2492.84L807.74,2492.84z"/>
				</g>
				<g>
					<path className="st23" d="M798.98,2502.58c1.19,0.1,2.38,0.2,3.56,0.29c0.07,0.01,0.13,0.01,0.2,0.02c0.03,0,0.2,0.03,0.09,0.01
						c0.05,0.01,0.11,0.02,0.16,0.03c0.03,0.01,0.21,0.07,0.11,0.03c0.05,0.03,0.1,0.06,0.15,0.09c0.06,0.03-0.01-0.01-0.02-0.02
						c0.02,0.02,0.05,0.04,0.07,0.07c0.04,0.03,0.07,0.07,0.1,0.11c0.02,0.03,0.04,0.05,0.06,0.08c-0.03-0.04-0.03-0.04,0,0
						c0.31,0.45,0.59,0.93,0.86,1.41c0.32,0.57,0.62,1.15,0.9,1.74c0.13,0.28,0.55,0.37,0.8,0.21c0.28-0.18,0.35-0.5,0.21-0.8
						c-0.5-1.06-1.06-2.14-1.73-3.1c-0.18-0.26-0.38-0.5-0.64-0.68c-0.28-0.19-0.6-0.27-0.93-0.31c-0.6-0.08-1.21-0.11-1.82-0.16
						c-0.71-0.06-1.42-0.12-2.13-0.18c-0.3-0.03-0.59,0.29-0.58,0.58C798.42,2502.34,798.66,2502.56,798.98,2502.58L798.98,2502.58z
						"/>
				</g>
				<g>
					<path className="st23" d="M830.91,2490.07c-0.21,0.78,0.09,1.58,0.65,2.14c0.59,0.59,1.39,0.85,2.22,0.86
						c0.89,0.01,1.77-0.23,2.63-0.43c0.85-0.2,1.71-0.39,2.56-0.59c0.98-0.23,1.96-0.45,2.94-0.68c0.3-0.07,0.5-0.42,0.41-0.71
						c-0.1-0.31-0.4-0.48-0.71-0.41c-1.6,0.37-3.21,0.74-4.81,1.11c-0.77,0.18-1.54,0.38-2.32,0.51c-0.19,0.03,0.06,0-0.06,0.01
						c-0.05,0-0.1,0.01-0.15,0.02c-0.09,0.01-0.18,0.01-0.27,0.02c-0.09,0-0.18,0-0.27,0c-0.04,0-0.08,0-0.12-0.01
						c-0.04,0-0.08-0.01-0.12-0.01c-0.07-0.01-0.08-0.01-0.04,0c0.08,0.01-0.11-0.02-0.1-0.02c-0.04-0.01-0.08-0.02-0.12-0.02
						c-0.08-0.02-0.16-0.04-0.24-0.07c-0.04-0.01-0.07-0.03-0.11-0.04c0.11,0.04,0.03,0.01,0.01,0c-0.08-0.04-0.15-0.08-0.23-0.13
						c-0.03-0.02-0.07-0.04-0.1-0.07c-0.13-0.09,0.08,0.08-0.03-0.03c-0.06-0.05-0.12-0.1-0.17-0.16c-0.03-0.03-0.05-0.06-0.08-0.09
						c-0.1-0.11,0.02,0.05-0.04-0.05c-0.04-0.07-0.08-0.13-0.12-0.2c-0.01-0.03-0.03-0.05-0.04-0.08c0.07,0.13-0.01-0.02-0.02-0.05
						c-0.02-0.07-0.06-0.16-0.06-0.24c0,0.02,0.01,0.13,0.01,0.02c0-0.04,0-0.09,0-0.13c0-0.01,0.02-0.15,0-0.06
						c-0.02,0.09,0.02-0.09,0.03-0.1c0.08-0.29-0.1-0.65-0.41-0.72C831.32,2489.59,831,2489.75,830.91,2490.07L830.91,2490.07z"/>
				</g>
				<g>
					<path className="st23" d="M832.21,2493.34c-0.19,0.68,0,1.41,0.42,1.97c0.46,0.6,1.1,0.97,1.83,1.16c0.72,0.19,1.48,0.22,2.22,0.25
						c0.78,0.03,1.56,0.02,2.35-0.01c3.13-0.15,6.22-0.77,9.24-1.57c1.71-0.45,3.4-0.95,5.09-1.45c0.29-0.09,0.5-0.41,0.41-0.71
						c-0.1-0.29-0.4-0.5-0.71-0.41c-2.88,0.86-5.76,1.71-8.7,2.29c-0.73,0.15-1.46,0.27-2.2,0.38c-0.09,0.01-0.18,0.03-0.28,0.04
						c-0.04,0.01-0.08,0.01-0.12,0.02c0.08-0.01-0.02,0-0.03,0c-0.19,0.02-0.39,0.05-0.58,0.07c-0.37,0.04-0.74,0.08-1.11,0.11
						c-1.47,0.12-2.96,0.15-4.42,0.01c-0.09-0.01-0.18-0.02-0.27-0.03c0.02,0,0.11,0.02-0.01,0c-0.05-0.01-0.11-0.02-0.16-0.03
						c-0.18-0.03-0.36-0.07-0.53-0.12c-0.09-0.03-0.17-0.06-0.26-0.09c-0.11-0.04-0.03-0.01-0.01,0c-0.05-0.02-0.1-0.04-0.15-0.07
						c-0.08-0.04-0.16-0.08-0.24-0.13c-0.03-0.02-0.05-0.04-0.08-0.05c-0.11-0.07,0.06,0.05-0.03-0.02
						c-0.06-0.05-0.12-0.1-0.18-0.16c-0.02-0.02-0.04-0.05-0.07-0.07c-0.03-0.03-0.09-0.11-0.03-0.03c-0.1-0.13-0.18-0.26-0.24-0.41
						c0.05,0.11,0.01,0.02,0-0.01c-0.01-0.04-0.02-0.07-0.03-0.11s-0.02-0.07-0.03-0.11c0-0.02-0.01-0.04-0.01-0.07
						c0.01,0.07,0.01,0.09,0.01,0.04c0-0.08,0-0.15,0-0.23c0-0.08-0.02,0.1,0,0.02c0.01-0.05,0.02-0.11,0.04-0.16
						c0.08-0.29-0.1-0.65-0.41-0.72C832.61,2492.87,832.29,2493.03,832.21,2493.34L832.21,2493.34z"/>
				</g>
				<g>
					<path className="st23" d="M832.48,2495.39c-0.48,1.3,0.16,2.81,1.19,3.66c1.16,0.96,2.65,1.3,4.1,1.59
						c3.16,0.64,6.39,0.97,9.61,0.99c1.8,0.01,3.6-0.08,5.39-0.27c0.3-0.03,0.6-0.24,0.58-0.58c-0.01-0.29-0.26-0.62-0.58-0.58
						c-3.07,0.32-6.18,0.36-9.26,0.1c-0.77-0.06-1.53-0.15-2.3-0.25c-0.02,0-0.15-0.02-0.06-0.01c-0.04-0.01-0.08-0.01-0.12-0.02
						c-0.08-0.01-0.16-0.02-0.25-0.03c-0.21-0.03-0.41-0.06-0.62-0.09c-0.38-0.06-0.76-0.12-1.14-0.19
						c-0.73-0.13-1.45-0.27-2.17-0.45c-0.33-0.08-0.65-0.17-0.97-0.28c-0.08-0.03-0.15-0.06-0.23-0.08
						c-0.04-0.02-0.08-0.03-0.13-0.05c0.02,0.01,0.09,0.04-0.01,0c-0.16-0.07-0.31-0.14-0.46-0.23c-0.17-0.09-0.32-0.19-0.48-0.3
						c-0.09-0.06-0.03-0.02-0.01-0.01c-0.04-0.03-0.08-0.06-0.12-0.09c-0.06-0.05-0.12-0.11-0.18-0.16
						c-0.06-0.06-0.12-0.13-0.18-0.19c-0.03-0.03-0.05-0.06-0.08-0.09c-0.08-0.09,0.05,0.07-0.03-0.04
						c-0.09-0.13-0.18-0.27-0.25-0.41c-0.01-0.03-0.03-0.06-0.04-0.09c-0.02-0.04-0.06-0.13-0.02-0.04
						c-0.03-0.08-0.06-0.16-0.09-0.25c-0.02-0.08-0.04-0.15-0.06-0.23c-0.01-0.04-0.01-0.08-0.02-0.12c-0.03-0.12,0,0.11,0-0.03
						c-0.01-0.16-0.01-0.31,0-0.46c0.01-0.11,0,0,0,0.02c0.01-0.05,0.02-0.09,0.03-0.14c0.02-0.09,0.05-0.18,0.08-0.27
						c0.11-0.29-0.12-0.65-0.41-0.71C832.87,2494.9,832.6,2495.08,832.48,2495.39L832.48,2495.39z"/>
				</g>
				<g>
					<path className="st23" d="M832.64,2497.98c-0.52,1.34-1.02,2.79-0.6,4.23c0.21,0.71,0.62,1.29,1.21,1.74
						c0.54,0.41,1.19,0.66,1.81,0.93c2.76,1.19,5.6,2.2,8.5,3c1.62,0.45,3.26,0.83,4.91,1.15c0.3,0.06,0.64-0.08,0.71-0.41
						c0.07-0.29-0.09-0.65-0.41-0.72c-2.83-0.55-5.63-1.28-8.37-2.21c-1.37-0.46-2.73-0.97-4.07-1.53c-0.54-0.22-1.2-0.49-1.8-0.79
						c-0.14-0.07-0.29-0.15-0.42-0.23c-0.06-0.04-0.12-0.08-0.18-0.12c-0.03-0.02-0.06-0.04-0.09-0.06
						c0.09,0.07-0.04-0.04-0.05-0.04c-0.11-0.1-0.21-0.21-0.31-0.32c-0.06-0.07,0.07,0.11-0.01-0.02c-0.02-0.03-0.04-0.06-0.06-0.08
						c-0.04-0.07-0.09-0.14-0.12-0.22c-0.02-0.03-0.03-0.06-0.04-0.09c-0.07-0.15,0.04,0.11-0.02-0.05
						c-0.03-0.08-0.06-0.16-0.08-0.24c-0.02-0.08-0.04-0.16-0.06-0.25c-0.01-0.03-0.01-0.07-0.02-0.1
						c-0.01-0.03-0.02-0.18-0.01-0.05c-0.02-0.17-0.03-0.34-0.03-0.51c0-0.08,0-0.17,0.01-0.25c0-0.03,0.01-0.07,0.01-0.1
						c0-0.02,0.02-0.19,0.01-0.08c0.11-0.79,0.41-1.54,0.69-2.28c0.11-0.28-0.12-0.65-0.41-0.72
						C833.02,2497.5,832.76,2497.67,832.64,2497.98L832.64,2497.98z"/>
				</g>
				<g>
					<path className="st23" d="M831.68,2503.52c-0.58,1-1.18,2.1-1.17,3.29c0.02,1.15,0.66,2.11,1.49,2.84
						c1.66,1.47,3.72,2.46,5.77,3.26c1.14,0.45,2.31,0.85,3.48,1.24c0.29,0.1,0.65-0.11,0.71-0.41c0.07-0.32-0.1-0.61-0.41-0.71
						c-0.99-0.33-1.98-0.67-2.95-1.04c-0.24-0.09-0.47-0.18-0.71-0.28c-0.12-0.05-0.24-0.1-0.36-0.15
						c-0.02-0.01-0.15-0.06-0.07-0.03c-0.07-0.03-0.14-0.06-0.21-0.09c-0.48-0.21-0.95-0.43-1.42-0.67c-0.91-0.47-1.79-1-2.6-1.62
						c0.07,0.06-0.05-0.04-0.07-0.06c-0.05-0.04-0.09-0.07-0.14-0.11c-0.09-0.08-0.18-0.16-0.27-0.24c-0.18-0.16-0.35-0.33-0.5-0.52
						c-0.02-0.03-0.1-0.12-0.04-0.04c-0.04-0.05-0.07-0.09-0.1-0.14c-0.07-0.1-0.13-0.2-0.18-0.3c-0.03-0.05-0.05-0.1-0.08-0.16
						c0-0.01-0.04-0.08-0.01-0.02c0.03,0.07-0.01-0.03-0.02-0.05c-0.04-0.11-0.08-0.23-0.1-0.35c-0.01-0.03-0.01-0.06-0.02-0.08
						c-0.02-0.11,0,0.02-0.01-0.04c0-0.07-0.01-0.13-0.01-0.2c0-0.15,0-0.3,0.02-0.45c0,0.01-0.01,0.08,0,0.02
						c0.01-0.05,0.02-0.09,0.02-0.14c0.01-0.07,0.03-0.15,0.05-0.22c0.04-0.14,0.08-0.28,0.13-0.41c0.03-0.07,0.05-0.14,0.08-0.22
						c0.02-0.06,0-0.01,0,0c0.02-0.04,0.03-0.07,0.05-0.11c0.06-0.14,0.13-0.28,0.2-0.42c0.14-0.28,0.3-0.55,0.45-0.82
						c0.15-0.26,0.07-0.65-0.21-0.8C832.2,2503.17,831.85,2503.24,831.68,2503.52L831.68,2503.52z"/>
				</g>
				<g>
					<path className="st23" d="M830.67,2506.77c-0.92,0.47-1.94,1.04-2.41,2.01c-0.61,1.25-0.16,2.6,0.65,3.63
						c0.36,0.46,0.8,0.86,1.24,1.24c0.47,0.42,0.95,0.83,1.44,1.23c1,0.83,2.03,1.63,3.07,2.41c1.15,0.85,2.33,1.66,3.54,2.44
						c0.26,0.17,0.65,0.06,0.8-0.21c0.15-0.29,0.06-0.62-0.21-0.8c-1.02-0.66-2.02-1.34-3-2.05c-0.49-0.35-0.97-0.71-1.45-1.08
						c-0.06-0.04-0.11-0.09-0.17-0.13c0.06,0.05-0.08-0.06-0.1-0.07c-0.13-0.1-0.25-0.2-0.38-0.3c-0.24-0.19-0.47-0.37-0.7-0.56
						c-0.92-0.75-1.86-1.5-2.7-2.33c-0.2-0.19-0.39-0.4-0.56-0.62c0.06,0.07-0.04-0.06-0.06-0.08c-0.04-0.05-0.07-0.11-0.11-0.16
						c-0.08-0.12-0.15-0.25-0.21-0.38c-0.02-0.03-0.03-0.07-0.05-0.1c0,0.01,0.03,0.07,0.01,0.01c-0.02-0.06-0.04-0.12-0.06-0.18
						c-0.04-0.14-0.08-0.28-0.1-0.42c0.02,0.11-0.01-0.05-0.01-0.09c0-0.06-0.01-0.13-0.01-0.19c0-0.07,0.01-0.14,0.01-0.21
						c0,0.01-0.02,0.09,0,0.02c0.01-0.04,0.02-0.09,0.03-0.13c0.02-0.07,0.04-0.14,0.06-0.2c0.01-0.03,0.02-0.06,0.03-0.09
						c-0.03,0.07-0.02,0.05,0.01-0.01c0.06-0.12,0.13-0.22,0.2-0.33c0.01-0.02,0.07-0.08-0.01,0.01c0.02-0.02,0.04-0.05,0.06-0.07
						c0.04-0.05,0.09-0.1,0.14-0.15c0.09-0.09,0.19-0.18,0.29-0.27c0.01-0.01,0.07-0.06,0.02-0.01c-0.06,0.05,0.04-0.03,0.05-0.04
						c0.05-0.04,0.1-0.07,0.16-0.11c0.11-0.08,0.23-0.15,0.35-0.22c0.24-0.14,0.49-0.27,0.74-0.4c0.27-0.14,0.37-0.54,0.21-0.8
						C831.28,2506.7,830.96,2506.62,830.67,2506.77L830.67,2506.77z"/>
				</g>
				<g>
					<path className="st23" d="M830.26,2506.57c-0.2,0.28-0.39,0.57-0.6,0.85c-0.05,0.07-0.1,0.14-0.15,0.21
						c-0.01,0.01-0.08,0.1-0.04,0.06c0.05-0.06-0.07,0.09-0.06,0.08c-0.11,0.13-0.22,0.27-0.33,0.4c-0.22,0.25-0.45,0.48-0.69,0.69
						c-0.09,0.08-0.19,0.16-0.29,0.24c-0.02,0.02-0.05,0.04-0.07,0.06c-0.07,0.06,0.11-0.08-0.01,0c-0.06,0.04-0.12,0.09-0.18,0.13
						c-0.89,0.65-1.85,1.19-2.68,1.91c-0.41,0.36-0.78,0.77-1.07,1.23c-0.35,0.58-0.54,1.22-0.59,1.89
						c-0.06,0.74,0.05,1.47,0.16,2.2c0.11,0.75,0.24,1.49,0.37,2.24c0.31,1.66,0.68,3.31,1.11,4.94c0.08,0.29,0.42,0.5,0.71,0.41
						c0.3-0.1,0.49-0.4,0.41-0.71c-0.38-1.43-0.71-2.87-0.99-4.33c-0.14-0.73-0.27-1.47-0.39-2.2c-0.03-0.17-0.05-0.33-0.08-0.5
						c-0.01-0.09-0.03-0.19-0.04-0.28c-0.01-0.04-0.01-0.08-0.02-0.12c0-0.02-0.02-0.17-0.02-0.13c0.01,0.06-0.01-0.06-0.01-0.07
						c0-0.04-0.01-0.08-0.01-0.12c-0.01-0.09-0.02-0.18-0.03-0.27c-0.02-0.18-0.03-0.36-0.03-0.53c0-0.17,0-0.33,0.01-0.5
						c0-0.04,0.01-0.08,0.01-0.12c0-0.01,0.02-0.13,0.01-0.07c-0.01,0.08,0.02-0.11,0.02-0.1c0.01-0.05,0.02-0.1,0.03-0.14
						c0.03-0.15,0.08-0.29,0.13-0.44c0.01-0.03,0.02-0.05,0.03-0.08c-0.04,0.12-0.02,0.05-0.01,0.02c0.03-0.06,0.06-0.13,0.09-0.19
						c0.06-0.11,0.12-0.21,0.18-0.31c0.03-0.05,0.07-0.1,0.11-0.16c0.02-0.02,0.03-0.04,0.05-0.07c-0.06,0.08-0.03,0.04,0.01-0.01
						c0.15-0.19,0.32-0.36,0.5-0.52c0.1-0.09,0.19-0.17,0.29-0.26c0.04-0.04,0.09-0.07,0.14-0.11c0.02-0.02,0.05-0.04,0.07-0.05
						c-0.11,0.09,0.01,0,0.02-0.02c0.21-0.16,0.42-0.31,0.64-0.45c0.44-0.3,0.9-0.59,1.33-0.9c0.54-0.39,1.06-0.81,1.51-1.3
						c0.53-0.59,0.99-1.25,1.44-1.9c0.17-0.25,0.05-0.66-0.21-0.8C830.76,2506.21,830.45,2506.3,830.26,2506.57L830.26,2506.57z"/>
				</g>
				<g>
					<path className="st23" d="M824.11,2512.49c-1.96,0.36-3.91,0.83-5.7,1.72c-1.62,0.81-3.06,2.01-4,3.57
						c-0.53,0.88-0.86,1.84-0.99,2.85c-0.04,0.3,0.29,0.59,0.58,0.58c0.35-0.02,0.54-0.26,0.58-0.58c0.02-0.16-0.02,0.12,0.01-0.04
						c0.01-0.05,0.02-0.1,0.02-0.15c0.02-0.1,0.04-0.19,0.06-0.29c0.05-0.21,0.11-0.41,0.17-0.61c0.03-0.09,0.07-0.18,0.1-0.27
						c0.01-0.02,0.07-0.17,0.02-0.04c0.02-0.04,0.04-0.09,0.06-0.13c0.09-0.19,0.18-0.38,0.29-0.57c0.1-0.17,0.2-0.34,0.31-0.5
						c0.06-0.08,0.11-0.16,0.17-0.24c0.03-0.04,0.06-0.08,0.09-0.12c-0.08,0.11,0.05-0.06,0.06-0.08c0.25-0.31,0.52-0.6,0.82-0.87
						c0.15-0.14,0.3-0.27,0.45-0.39c0.04-0.03,0.08-0.06,0.12-0.09c0.13-0.1-0.09,0.07,0.04-0.03c0.09-0.07,0.19-0.14,0.28-0.2
						c0.37-0.26,0.75-0.49,1.15-0.69c0.2-0.11,0.41-0.21,0.61-0.3c0.1-0.05,0.21-0.09,0.31-0.14c-0.08,0.04,0,0,0.02-0.01
						c0.07-0.03,0.14-0.06,0.21-0.08c0.44-0.17,0.89-0.33,1.35-0.46c1.03-0.31,2.07-0.53,3.13-0.72c0.3-0.05,0.5-0.43,0.41-0.72
						C824.72,2512.58,824.43,2512.43,824.11,2512.49L824.11,2512.49z"/>
				</g>
				<g>
					<path className="st23" d="M799.54,2516c1.39-0.91,2.77-1.82,4.16-2.72c0.63-0.41,1.27-0.81,1.96-1.12
						c0.11-0.05,0.04-0.02,0.02-0.01c0.05-0.02,0.11-0.04,0.16-0.06c0.07-0.03,0.14-0.05,0.22-0.08c0.18-0.06,0.37-0.12,0.56-0.16
						c0.07-0.02,0.15-0.03,0.22-0.05c0.04-0.01,0.08-0.02,0.13-0.02c0.03-0.01,0.15-0.02,0.02,0c0.17-0.02,0.34-0.03,0.51-0.04
						c0.19,0,0.37,0.01,0.56,0.03c-0.02,0-0.12-0.02,0,0c0.05,0.01,0.1,0.02,0.15,0.03c0.1,0.02,0.2,0.04,0.29,0.07
						c0.08,0.02,0.16,0.05,0.24,0.08c0,0,0.15,0.06,0.07,0.03c-0.07-0.03,0.07,0.04,0.09,0.04c0.17,0.09,0.33,0.19,0.49,0.3
						c0.24,0.18,0.66,0.05,0.8-0.21c0.16-0.31,0.05-0.6-0.21-0.8c-1.2-0.91-2.89-0.89-4.26-0.4c-0.78,0.28-1.5,0.68-2.19,1.12
						c-0.7,0.44-1.39,0.9-2.08,1.36c-0.82,0.54-1.65,1.08-2.47,1.61c-0.25,0.17-0.38,0.52-0.21,0.8
						C798.91,2516.05,799.27,2516.18,799.54,2516L799.54,2516z"/>
				</g>
				<g>
					<path className="st23" d="M806.83,2517.62c0.29-0.65,0.58-1.31,0.99-1.89c0.02-0.03,0.05-0.07,0.07-0.1
						c0.01-0.02,0.11-0.14,0.03-0.04c0.05-0.06,0.1-0.12,0.15-0.18c0.12-0.13,0.24-0.25,0.37-0.37c0.04-0.03,0.07-0.06,0.11-0.09
						c-0.09,0.07-0.02,0.02,0,0c0.07-0.05,0.14-0.1,0.22-0.15c0.08-0.05,0.16-0.09,0.25-0.13c0.03-0.02,0.07-0.03,0.1-0.05
						c-0.1,0.04,0.1-0.04,0.12-0.04c0.17-0.06,0.35-0.11,0.53-0.14c0.05-0.01,0.11-0.02,0.16-0.03c0.02,0,0.11-0.01,0.01,0
						c0.11-0.01,0.22-0.02,0.33-0.03c0.41-0.03,0.83-0.02,1.24,0c0.91,0.04,1.81,0.08,2.72,0.11c1.04,0.04,2.09,0.09,3.13,0.13
						c0.3,0.01,0.59-0.28,0.58-0.58c-0.01-0.33-0.26-0.57-0.58-0.58c-1.91-0.08-3.83-0.16-5.74-0.24c-0.86-0.04-1.76-0.05-2.59,0.21
						c-0.77,0.24-1.43,0.71-1.93,1.34c-0.54,0.69-0.92,1.47-1.27,2.26c-0.12,0.28-0.09,0.64,0.21,0.8
						C806.29,2517.96,806.7,2517.91,806.83,2517.62L806.83,2517.62z"/>
				</g>
				<g>
					<path className="st23" d="M809.77,2513.57c0.02,0,0.17-0.03,0.09-0.02c0.06-0.01,0.12-0.01,0.18-0.02
						c0.14-0.01,0.29-0.01,0.43-0.01c0.26,0.01,0.52,0.03,0.77,0.06c0.06,0.01,0.12,0.02,0.18,0.02c0.07,0.01,0.01,0,0,0
						c0.03,0,0.06,0.01,0.09,0.01c0.14,0.02,0.28,0.04,0.41,0.06c0.28,0.04,0.55,0.08,0.83,0.11c1.09,0.15,2.17,0.3,3.26,0.45
						c0.62,0.09,1.24,0.17,1.85,0.26c0.3,0.04,0.64-0.07,0.71-0.41c0.06-0.27-0.08-0.67-0.41-0.71c-2.26-0.31-4.53-0.64-6.79-0.93
						c-0.65-0.08-1.29-0.11-1.94,0.01c-0.3,0.06-0.5,0.43-0.41,0.71C809.16,2513.49,809.45,2513.63,809.77,2513.57L809.77,2513.57z"
						/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M767.55,1649.73c20.78,1.97,41.77,2.23,62.61,1.05c5.69-0.32,11.38-0.77,17.05-1.36
						c2.07,0.08,3.99-0.35,5.78-1.29c1.86-0.6,3.47-1.64,4.83-3.1c2.6-2.6,4.56-6.87,4.39-10.61c-0.17-3.88-1.45-7.9-4.39-10.61
						c-2.76-2.53-6.72-4.8-10.61-4.39c-20.7,2.15-41.63,2.54-62.42,1.53c-5.75-0.28-11.5-0.68-17.24-1.23
						c-3.61-0.34-8.13,1.92-10.61,4.39c-2.6,2.6-4.56,6.87-4.39,10.61c0.17,3.88,1.45,7.9,4.39,10.61c1.01,0.78,2.02,1.56,3.04,2.35
						C762.32,1649.04,764.84,1649.72,767.55,1649.73L767.55,1649.73z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M1279.32,2311.61c0.72,0,1.43,0.01,2.14,0.02c0.95,0.01,1.9,0.02,2.84,0.02c2.72,0.01,5.28,0,5.28,0s0,0,0.9,0
						c11.98-0.02,23.97,0,35.95,0c0.14,0,0.27,0,0.42,0c0.3,0,0.55-0.25,0.55-0.55c0-6.32,0-65.19,0-70.24c0-0.2,0.1-0.38,0.28-0.48
						c0.26-0.14,0.47-0.32,0.46-0.49c-0.04-0.66,0.1-1.1,0.67-1.2c0.27-0.04,0.47-0.28,0.46-0.55c0-0.19,0-0.36,0.03-0.53
						c0.04-0.2,0.24-0.51,0.38-0.52c0.66-0.05,0.67-0.48,0.67-0.96c0.01-1.49,0.01-2.97,0.02-4.46c0-1.06-0.03-2.9-0.04-3.84
						c-0.01-0.31-0.26-0.54-0.57-0.54c-0.3,0.01-0.62-0.02-0.84-0.16c-0.36-0.23-0.4-0.74-0.4-1.12c-0.01-0.7,0.01-1.4,0.01-2.09
						c0-0.18,0.07-2.89,0.07-2.89c0.01-0.15-0.11-0.49-0.83-0.49h-4.4c-0.3,0-0.55,0.25-0.55,0.55c0,2.35-0.02,4.66,0.01,6.96
						c0.02,2.27,0.04,2.25-2.24,2.11c-0.58-0.03-0.72-0.26-0.7-0.78c0.02-0.51,0.02-1.03,0.01-1.56c0-0.3-0.25-0.54-0.55-0.54h-3.29
						c-0.3,0-0.55,0.25-0.55,0.55v1.77c0,0.31-0.25,0.56-0.56,0.55c-0.49-0.01-0.94-0.03-1.38,0.02c-0.86,0.09-1-0.34-0.94-1.06
						c0.04-0.42,0.03-0.85,0.02-1.28c-0.01-0.3-0.25-0.54-0.55-0.54h-3.32c-0.3,0-0.55,0.25-0.55,0.55c0,0.1,0,0.19,0,0.28
						c0,2.09,0,2.06-2.1,2.04c-0.67-0.01-0.81-0.29-0.8-0.87c0.02-2.09,0-4.18,0-6.27c0-0.65,0-1.29,0-1.99
						c0-0.3-0.25-0.55-0.55-0.55c-1.46,0-2.91,0.02-4.36-0.02c-0.68-0.02-0.88,0.2-0.85,0.87c0.06,1.64,0,3.28,0.07,4.92
						c0.03,0.65-0.54,0.89-1.31,0.95c-0.29,0.02-0.51,0.27-0.5,0.55l0,0.29c0.04,2.32,0.05,4.63,0.06,6.95c0,0.52,0,1.04,0,1.56
						c0,0.25,0.17,0.48,0.42,0.53l0.15,0.04c0.24,0.06,0.41,0.26,0.42,0.51l0.03,0.61c0.01,0.28,0.23,0.5,0.5,0.52
						c0.34,0.03,0.64,0.08,0.62,0.68c-0.01,0.41-0.3,1.06,0.66,1.17c0.06-0.02-0.06,0.02,0,0v4.41c0,0.3-0.25,0.55-0.55,0.55
						c-2.1,0-4.15,0.01-6.2-0.01c-0.58-0.01-0.82,0.15-0.78,0.76c0.06,1.07,0.06,2.15,0.09,3.22c0.02,0.49-0.18,0.73-0.69,0.71
						c-0.55-0.02-1.11-0.01-1.69-0.01c-0.3,0-0.55-0.24-0.55-0.55v-3.52c0-0.3-0.25-0.55-0.55-0.55h-3.35
						c-0.3,0-0.55,0.25-0.55,0.55c0,0.15,0,0.28,0,0.42c0.03,0.99,0.09,1.98,0.07,2.96c0,0.22-0.27,0.62-0.44,0.63
						c-0.63,0.05-1.27,0.04-1.95,0.03c-0.3,0-0.54-0.25-0.54-0.55v-3.49c0-0.3-0.25-0.55-0.55-0.55h-3.28
						c-0.3,0-0.55,0.25-0.55,0.55v3.43c0,0.3-0.25,0.55-0.55,0.55h-1.8c-0.3,0-0.55-0.25-0.55-0.55v-3.52c0-0.3-0.25-0.55-0.55-0.55
						h-6.45c-0.3,0-0.55-0.25-0.55-0.55c0-1.23-0.01-2.4,0.01-3.58c0.01-0.43-0.22-1.01,0.56-1.08c0.08-0.01,0.22-0.33,0.19-0.49
						c-0.09-0.66,0.09-1.06,0.62-1.14c0.27-0.04,0.48-0.27,0.48-0.54c0-0.52-0.08-1.06,0.55-1.14c0.26-0.03,0.47-0.29,0.47-0.55
						v-8.71c0-0.3-0.25-0.55-0.55-0.55c-0.11,0-0.22,0-0.33,0.02c-0.66,0.08-0.91-0.18-0.88-0.85c0.05-0.96,0.01-1.92,0.01-2.88
						c0-0.79,0-1.59,0-2.38c0-0.3-0.25-0.55-0.55-0.55h-4.66c-0.3,0-0.55,0.25-0.55,0.55c0,2.37,0,4.68,0,6.98
						c0,2.16,0,2.14-2.17,2.07c-0.55-0.02-0.76-0.2-0.73-0.75c0.03-0.52,0.02-1.04,0.01-1.57c0-0.3-0.25-0.54-0.55-0.54h-3.31
						c-0.3,0-0.55,0.25-0.55,0.55c0,0.1,0,0.19,0,0.28c-0.01,2.12,0,2.1-2.18,2.03c-0.57-0.02-0.74-0.22-0.72-0.76
						c0.02-0.52,0.02-1.03,0.01-1.55c0-0.3-0.25-0.54-0.55-0.54h-3.29c-0.3,0-0.55,0.25-0.55,0.55c0,0.16,0,0.31,0,0.46
						c0.01,1.86,0.01,1.82-1.91,1.86c-0.85,0.02-1.02-0.28-1-1.08c0.07-2.65,0.06-5.31,0.04-7.96c0-0.21-0.28-0.6-0.44-0.61
						c-1.58-0.04-3.16-0.03-4.79-0.03c-0.29,0-0.53,0.22-0.55,0.51c-0.01,0.11-0.01,0.21-0.01,0.31c0.01,1.68,0.01,3.37,0.06,5.05
						c0.02,0.66-0.17,0.98-0.86,0.86c-0.83-0.15-0.99,0.27-0.97,1c0.06,2.37,0.06,4.75,0.08,7.12c0,0.39,0,0.77,0,1.18
						c0,0.28,0.21,0.5,0.48,0.55c0.65,0.1,0.51,0.69,0.52,1.17c0.01,0.25,0.19,0.47,0.44,0.52c0.5,0.1,0.56,0.25,0.68,1.2
						c0.03,0.22,0.19,0.41,0.4,0.46c0.24,0.06,0.41,0.28,0.41,0.53v70.13c0,0.3,0.25,0.55,0.55,0.55c0.05,0,0.1,0,0.15,0
						c0.14,0,0.28,0,0.42,0c0.23,0,0.45,0,0.68,0c0.3,0,0.61,0,0.91,0c0.37,0,0.75,0,1.12,0c0.44,0,0.87,0,1.31,0
						c0.49,0,0.98,0,1.48,0c0.54,0,1.08,0,1.62,0c0.58,0,1.16,0,1.75,0c0.62,0,1.23,0,1.85,0c0.64,0,1.28,0,1.93,0
						c0.66,0,1.32,0,1.99,0c0.67,0,1.35,0,2.02,0c0.68,0,1.36,0,2.04,0c0.68,0,1.35,0,2.03,0c0.67,0,1.33,0,2,0c0.65,0,1.3,0,1.95,0
						c0.63,0,1.25,0,1.88,0c0.59,0,1.19,0,1.78,0c0.56,0,1.11,0,1.67,0c0.51,0,1.02,0,1.53,0c0.46,0,0.91,0,1.37,0
						c0.4,0,0.79,0,1.19,0c0.33,0,0.66,0,0.98,0c0.25,0,0.51,0,0.76,0c0.17,0,0.34,0,0.51,0
						C1279.15,2311.61,1279.24,2311.61,1279.32,2311.61z"/>
				</g>
				<g>
					<path className="st34" d="M1275.41,2307.28c0.72,0,1.43,0.01,2.14,0.02c0.95,0.01,1.9,0.02,2.84,0.02c2.72,0.01,5.28,0,5.28,0
						s0,0,0.9,0c11.98-0.03,23.97,0,35.95,0c0.14,0,0.27,0,0.42,0c0.3,0,0.55-0.25,0.55-0.55c0-6.32,0-65.19,0-70.24
						c0-0.2,0.1-0.38,0.28-0.48c0.26-0.14,0.47-0.32,0.46-0.49c-0.04-0.66,0.1-1.1,0.67-1.2c0.27-0.04,0.47-0.28,0.46-0.55
						c0-0.19,0-0.36,0.03-0.53c0.04-0.2,0.24-0.51,0.38-0.52c0.66-0.05,0.67-0.48,0.67-0.96c0.01-1.49,0.01-2.97,0.02-4.46
						c0-1.06-0.03-2.9-0.04-3.84c-0.01-0.31-0.26-0.54-0.57-0.54c-0.3,0.01-0.62-0.02-0.84-0.16c-0.36-0.23-0.4-0.74-0.4-1.12
						c-0.01-0.7,0.01-1.4,0.01-2.09c0-0.18,0.07-2.89,0.07-2.89c0.01-0.15-0.11-0.49-0.83-0.49h-4.4c-0.3,0-0.55,0.25-0.55,0.55
						c0,2.35-0.02,4.66,0.01,6.96c0.02,2.27,0.04,2.25-2.24,2.11c-0.58-0.03-0.72-0.26-0.7-0.78c0.02-0.51,0.02-1.03,0.01-1.56
						c0-0.3-0.25-0.54-0.55-0.54h-3.29c-0.3,0-0.55,0.25-0.55,0.55v1.77c0,0.31-0.25,0.56-0.56,0.55c-0.49-0.01-0.94-0.03-1.38,0.02
						c-0.86,0.09-1-0.34-0.94-1.06c0.04-0.42,0.03-0.85,0.02-1.28c-0.01-0.3-0.25-0.54-0.55-0.54h-3.32c-0.3,0-0.55,0.25-0.55,0.55
						c0,0.09,0,0.19,0,0.28c0,2.09,0,2.06-2.1,2.04c-0.67-0.01-0.81-0.29-0.8-0.87c0.02-2.09,0-4.18,0-6.27c0-0.65,0-1.29,0-1.99
						c0-0.3-0.25-0.55-0.55-0.55c-1.46,0-2.91,0.02-4.36-0.02c-0.68-0.02-0.88,0.2-0.85,0.87c0.06,1.64,0,3.28,0.07,4.92
						c0.03,0.65-0.54,0.89-1.31,0.95c-0.29,0.02-0.51,0.27-0.51,0.55l0,0.29c0.04,2.32,0.05,4.63,0.06,6.95c0,0.52,0,1.04,0,1.56
						c0,0.25,0.17,0.48,0.42,0.53l0.15,0.04c0.24,0.06,0.41,0.26,0.42,0.51l0.03,0.61c0.01,0.28,0.23,0.5,0.5,0.52
						c0.34,0.03,0.64,0.08,0.62,0.68c-0.01,0.41-0.3,1.06,0.66,1.17c0.06-0.02-0.06,0.02,0,0v4.41c0,0.3-0.25,0.55-0.55,0.55
						c-2.1,0-4.15,0.01-6.2-0.01c-0.58-0.01-0.82,0.15-0.78,0.76c0.06,1.07,0.06,2.14,0.09,3.22c0.02,0.49-0.18,0.73-0.69,0.71
						c-0.55-0.02-1.11-0.01-1.69-0.01c-0.3,0-0.55-0.24-0.55-0.55v-3.52c0-0.3-0.25-0.55-0.55-0.55h-3.35
						c-0.3,0-0.55,0.25-0.55,0.55c0,0.15,0,0.28,0,0.42c0.03,0.99,0.09,1.98,0.07,2.96c0,0.22-0.27,0.62-0.44,0.63
						c-0.63,0.05-1.27,0.04-1.95,0.03c-0.3,0-0.54-0.25-0.54-0.55v-3.49c0-0.3-0.25-0.55-0.55-0.55h-3.28
						c-0.3,0-0.55,0.25-0.55,0.55v3.43c0,0.3-0.25,0.55-0.55,0.55h-1.8c-0.3,0-0.55-0.25-0.55-0.55v-3.52c0-0.3-0.25-0.55-0.55-0.55
						h-6.45c-0.3,0-0.55-0.25-0.55-0.55c0-1.23-0.01-2.4,0.01-3.58c0.01-0.43-0.22-1.01,0.56-1.08c0.08-0.01,0.22-0.33,0.19-0.49
						c-0.09-0.66,0.09-1.06,0.62-1.14c0.27-0.04,0.48-0.27,0.48-0.54c0-0.52-0.08-1.06,0.55-1.14c0.26-0.03,0.47-0.29,0.47-0.55
						v-8.71c0-0.3-0.25-0.55-0.55-0.55c-0.11,0-0.22,0-0.33,0.02c-0.66,0.08-0.91-0.18-0.88-0.85c0.05-0.96,0.01-1.92,0.01-2.88
						c0-0.79,0-1.59,0-2.38c0-0.3-0.25-0.55-0.55-0.55h-4.66c-0.3,0-0.55,0.25-0.55,0.55c0,2.37,0,4.68,0,6.98
						c0,2.16,0,2.14-2.17,2.07c-0.55-0.02-0.76-0.2-0.73-0.75c0.03-0.52,0.02-1.04,0.01-1.57c0-0.3-0.25-0.54-0.55-0.54h-3.31
						c-0.3,0-0.55,0.25-0.55,0.55c0,0.1,0,0.19,0,0.28c-0.01,2.12,0,2.1-2.18,2.03c-0.57-0.02-0.74-0.22-0.72-0.76
						c0.02-0.52,0.02-1.03,0.01-1.55c0-0.3-0.25-0.54-0.55-0.54h-3.29c-0.3,0-0.55,0.25-0.55,0.55c0,0.16,0,0.31,0,0.46
						c0.01,1.86,0.01,1.82-1.91,1.86c-0.85,0.02-1.02-0.28-1-1.08c0.07-2.65,0.06-5.31,0.04-7.96c0-0.21-0.28-0.6-0.44-0.61
						c-1.58-0.04-3.16-0.03-4.79-0.03c-0.29,0-0.53,0.22-0.55,0.51c-0.01,0.11-0.01,0.21-0.01,0.31c0.01,1.68,0.01,3.37,0.06,5.05
						c0.02,0.66-0.17,0.98-0.86,0.86c-0.83-0.15-0.99,0.27-0.97,1c0.06,2.37,0.06,4.75,0.08,7.12c0,0.39,0,0.77,0,1.18
						c0,0.28,0.21,0.5,0.48,0.55c0.65,0.1,0.51,0.69,0.52,1.17c0.01,0.25,0.19,0.47,0.44,0.52c0.5,0.1,0.56,0.25,0.68,1.2
						c0.03,0.22,0.19,0.41,0.4,0.46c0.24,0.06,0.41,0.28,0.41,0.53v70.13c0,0.3,0.25,0.55,0.55,0.55c0.05,0,0.1,0,0.15,0
						c0.14,0,0.28,0,0.42,0c0.23,0,0.45,0,0.68,0c0.3,0,0.61,0,0.91,0c0.37,0,0.75,0,1.12,0c0.44,0,0.87,0,1.31,0
						c0.49,0,0.98,0,1.48,0c0.54,0,1.08,0,1.62,0c0.58,0,1.16,0,1.75,0c0.62,0,1.23,0,1.85,0c0.64,0,1.28,0,1.93,0
						c0.66,0,1.32,0,1.99,0c0.67,0,1.35,0,2.02,0c0.68,0,1.36,0,2.04,0c0.68,0,1.35,0,2.03,0c0.67,0,1.33,0,2,0c0.65,0,1.3,0,1.95,0
						c0.63,0,1.25,0,1.88,0c0.59,0,1.19,0,1.78,0c0.56,0,1.11,0,1.67,0c0.51,0,1.02,0,1.53,0c0.46,0,0.91,0,1.37,0
						c0.4,0,0.79,0,1.19,0c0.33,0,0.66,0,0.98,0c0.25,0,0.51,0,0.76,0c0.17,0,0.34,0,0.51,0
						C1275.24,2307.28,1275.33,2307.28,1275.41,2307.28z"/>
					<path className="st35" d="M1282.47,2307.6c-0.65,0-1.35,0-2.07,0c-0.97,0-1.93-0.01-2.85-0.02c-0.7-0.01-1.42-0.02-2.14-0.02
						l-37.15,0c-0.46,0-0.83-0.37-0.83-0.83v-70.13c0-0.12-0.08-0.22-0.2-0.26c-0.33-0.09-0.57-0.36-0.61-0.7
						c-0.11-0.89-0.14-0.9-0.46-0.96c-0.38-0.08-0.66-0.4-0.67-0.79c0-0.08,0-0.17,0-0.26c0.01-0.51-0.03-0.6-0.28-0.64
						c-0.42-0.07-0.72-0.41-0.72-0.83v-0.03c0-0.39,0-0.77,0-1.14c-0.01-0.61-0.01-1.21-0.01-1.82c-0.01-1.74-0.02-3.53-0.07-5.3
						c-0.01-0.4,0.02-0.85,0.33-1.12c0.22-0.19,0.54-0.25,0.98-0.17c0.2,0.04,0.35,0.02,0.42-0.03c0.05-0.05,0.12-0.18,0.11-0.54
						c-0.04-1.31-0.05-2.64-0.05-3.93c0-0.37,0-0.75-0.01-1.12c0-0.1,0-0.2,0.01-0.33c0.03-0.43,0.4-0.77,0.83-0.77l0.64,0
						c1.43,0,2.78-0.01,4.15,0.03c0.36,0.01,0.72,0.57,0.72,0.89c0.02,2.7,0.03,5.35-0.04,7.97c-0.01,0.37,0.03,0.58,0.12,0.67
						c0.09,0.09,0.28,0.13,0.59,0.12l0.12,0c0.74-0.01,1.23-0.02,1.37-0.17c0.14-0.15,0.14-0.65,0.14-1.4c0-0.15,0-0.3,0-0.46
						c0-0.46,0.37-0.83,0.83-0.83h3.29c0.46,0,0.83,0.37,0.83,0.83l0,0.09c0,0.48,0.01,0.99-0.01,1.48
						c-0.01,0.19,0.01,0.31,0.06,0.36c0.05,0.06,0.18,0.09,0.38,0.09l0.06,0c0.91,0.03,1.47,0.04,1.64-0.13
						c0.18-0.17,0.18-0.72,0.18-1.62l0-0.28c0-0.46,0.37-0.83,0.83-0.83h3.31c0.45,0,0.83,0.37,0.83,0.82l0,0.11
						c0.01,0.51,0.01,0.99-0.01,1.48c-0.01,0.18,0.01,0.3,0.06,0.35c0.05,0.06,0.18,0.09,0.39,0.1l0.05,0
						c0.91,0.03,1.46,0.05,1.64-0.12c0.18-0.17,0.18-0.73,0.18-1.66c0-1.76,0-3.51,0-5.3v-1.68c0-0.46,0.37-0.83,0.83-0.83h4.66
						c0.46,0,0.83,0.37,0.83,0.83l0,2.38c0,0.29,0,0.58,0.01,0.87c0.01,0.66,0.02,1.35-0.02,2.03c-0.01,0.26,0.02,0.43,0.1,0.5
						c0.09,0.08,0.3,0.08,0.46,0.06c0.11-0.01,0.22-0.02,0.36-0.02c0.46,0,0.83,0.38,0.83,0.83v8.71c0,0.41-0.32,0.78-0.72,0.83
						c-0.27,0.04-0.32,0.13-0.31,0.69c0,0.05,0,0.11,0,0.16c0,0.41-0.31,0.76-0.72,0.82c-0.17,0.02-0.49,0.07-0.38,0.82
						c0.03,0.21-0.08,0.52-0.2,0.67c-0.09,0.11-0.19,0.14-0.25,0.15c-0.34,0.03-0.35,0.11-0.31,0.52c0.01,0.09,0.01,0.18,0.01,0.28
						c-0.01,0.97-0.01,1.92-0.01,2.93l0,0.64c0,0.15,0.12,0.26,0.26,0.26h6.45c0.46,0,0.83,0.37,0.83,0.83v3.52
						c0,0.15,0.12,0.26,0.26,0.26h1.8c0.15,0,0.26-0.12,0.26-0.26v-3.43c0-0.46,0.37-0.83,0.83-0.83h3.28
						c0.46,0,0.83,0.37,0.83,0.83v3.49c0,0.14,0.12,0.26,0.26,0.26l0.15,0c0.6,0.01,1.18,0.01,1.74-0.03
						c0.08-0.06,0.21-0.27,0.21-0.36c0.01-0.69-0.01-1.39-0.04-2.07c-0.01-0.29-0.02-0.59-0.03-0.88c0-0.14,0-0.28,0-0.43
						c0-0.46,0.38-0.83,0.83-0.83h3.35c0.46,0,0.83,0.37,0.83,0.83v3.52c0,0.07,0.03,0.14,0.08,0.19c0.05,0.05,0.12,0.08,0.19,0.08
						c0,0,0,0,0,0l0.15,0c0.53,0,1.04-0.01,1.55,0.01c0.11,0,0.26-0.01,0.33-0.07c0.07-0.07,0.08-0.23,0.07-0.35
						c-0.01-0.4-0.02-0.81-0.03-1.21c-0.01-0.66-0.03-1.33-0.06-2c-0.01-0.23-0.01-0.57,0.22-0.81c0.23-0.24,0.57-0.26,0.85-0.26
						c1.62,0.02,3.2,0.02,4.88,0.01l1.32,0c0.15,0,0.26-0.12,0.26-0.26v-4.17c-0.24-0.06-0.42-0.17-0.54-0.32
						c-0.22-0.29-0.17-0.64-0.14-0.9c0.01-0.07,0.02-0.14,0.02-0.2c0.01-0.36-0.07-0.36-0.36-0.39c-0.42-0.03-0.74-0.37-0.77-0.79
						l-0.03-0.61c-0.01-0.12-0.09-0.22-0.2-0.24l-0.15-0.04c-0.38-0.09-0.64-0.42-0.64-0.81l0-0.19c0-0.45,0-0.91,0-1.37
						c0-0.53-0.01-1.07-0.01-1.6c-0.01-1.75-0.02-3.56-0.05-5.34l0-0.29c-0.01-0.44,0.33-0.81,0.77-0.84
						c1.07-0.09,1.05-0.49,1.05-0.66c-0.03-0.86-0.04-1.74-0.04-2.58c0-0.77,0-1.56-0.03-2.33c-0.01-0.29,0.01-0.66,0.25-0.91
						c0.24-0.24,0.61-0.26,0.9-0.26c1.16,0.03,2.34,0.03,3.48,0.02c0.29,0,0.58,0,0.87,0c0,0,0,0,0,0c0.22,0,0.43,0.09,0.59,0.24
						c0.16,0.16,0.25,0.37,0.25,0.59l0,1.99c0,0.58,0,1.16,0,1.75c0,1.48,0.01,3.02-0.01,4.53c0,0.49,0.07,0.57,0.52,0.58l0.08,0
						c0.87,0.01,1.39,0.01,1.56-0.15c0.17-0.17,0.17-0.71,0.17-1.6l0-0.28c0-0.46,0.37-0.83,0.83-0.83h3.32
						c0.45,0,0.82,0.37,0.83,0.82l0,0.02c0.01,0.42,0.02,0.86-0.02,1.29c-0.03,0.36,0,0.57,0.1,0.67c0.11,0.1,0.35,0.1,0.53,0.08
						c0.43-0.04,0.87-0.03,1.33-0.02l0.09,0c0.07,0,0.14-0.03,0.19-0.08c0.05-0.05,0.08-0.12,0.08-0.19v-1.77
						c0-0.46,0.37-0.83,0.83-0.83h3.29c0.46,0,0.83,0.37,0.83,0.83l0,0.1c0,0.48,0.01,0.98-0.01,1.47
						c-0.02,0.39,0.04,0.46,0.43,0.49l0.06,0c0.95,0.06,1.52,0.09,1.7-0.08c0.18-0.17,0.17-0.74,0.17-1.68l0-0.06
						c-0.02-1.73-0.01-3.42-0.01-5.2c0-0.58,0-1.17,0-1.76c0-0.46,0.37-0.83,0.83-0.83h4.4c0.43,0,0.74,0.11,0.94,0.32
						c0.13,0.14,0.19,0.31,0.18,0.48c-0.02,0.92-0.07,2.73-0.07,2.87c0,0.3,0,0.6-0.01,0.91c0,0.39-0.01,0.79-0.01,1.18
						c0,0.33,0.04,0.74,0.27,0.88c0.14,0.09,0.37,0.12,0.68,0.12c0.23-0.01,0.44,0.08,0.6,0.23c0.16,0.15,0.25,0.36,0.26,0.58
						c0.02,0.98,0.05,2.79,0.04,3.85l0,0.68c-0.01,1.26-0.01,2.52-0.02,3.79c0,0.46,0,1.15-0.89,1.24c-0.05,0.05-0.14,0.2-0.16,0.3
						c-0.03,0.15-0.03,0.31-0.03,0.46c0.01,0.41-0.29,0.77-0.7,0.84c-0.26,0.04-0.48,0.14-0.44,0.9c0.02,0.27-0.19,0.53-0.61,0.76
						c-0.08,0.04-0.13,0.13-0.13,0.23v70.24c0,0.46-0.37,0.83-0.83,0.83l-7.64-0.01c-9.41-0.01-19.15-0.02-28.72,0l-0.9,0
						C1285.67,2307.6,1284.27,2307.6,1282.47,2307.6z M1275.41,2306.99c0.73,0,1.45,0.01,2.15,0.02c0.92,0.01,1.87,0.02,2.84,0.02
						c2.69,0.01,5.26,0,5.28,0l0.9,0c9.58-0.02,19.31-0.01,28.73,0l7.64,0.01c0.15,0,0.26-0.12,0.26-0.26v-70.24
						c0-0.3,0.16-0.58,0.42-0.73c0.21-0.12,0.29-0.21,0.31-0.24c-0.02-0.35-0.06-1.32,0.91-1.48c0.13-0.02,0.23-0.14,0.22-0.27
						c0-0.19,0-0.38,0.04-0.59c0.05-0.24,0.29-0.73,0.64-0.75c0.37-0.03,0.4-0.14,0.4-0.67c0-1.26,0.01-2.52,0.02-3.79l0-0.68
						c0-1.05-0.02-2.85-0.04-3.84c0-0.07-0.03-0.13-0.08-0.18c-0.05-0.05-0.12-0.07-0.19-0.07c-0.43,0.01-0.76-0.05-1-0.2
						c-0.53-0.33-0.54-1.05-0.54-1.36c0-0.4,0-0.8,0.01-1.19c0-0.3,0.01-0.6,0.01-0.9c0-0.18,0.06-2.79,0.07-2.9
						c0-0.07-0.14-0.2-0.55-0.2h-4.4c-0.15,0-0.26,0.12-0.26,0.26c0,0.59,0,1.18,0,1.76c0,1.79-0.01,3.47,0.01,5.2l0,0.06
						c0.01,1.17,0.02,1.76-0.34,2.1c-0.36,0.34-0.95,0.31-2.13,0.24l-0.06,0c-0.7-0.04-1-0.37-0.97-1.08
						c0.02-0.47,0.02-0.94,0.01-1.44l0-0.1c0-0.14-0.12-0.26-0.26-0.26h-3.29c-0.15,0-0.26,0.12-0.26,0.26v1.77
						c0,0.23-0.09,0.44-0.25,0.6c-0.16,0.16-0.38,0.25-0.6,0.24l-0.09,0c-0.44-0.01-0.86-0.02-1.25,0.02
						c-0.45,0.05-0.78-0.03-0.99-0.24c-0.29-0.29-0.3-0.73-0.26-1.12c0.03-0.4,0.03-0.82,0.02-1.23l0-0.02
						c0-0.14-0.12-0.26-0.26-0.26h-3.32c-0.15,0-0.26,0.12-0.26,0.26l0,0.28c0,1.11,0,1.67-0.34,2.01
						c-0.34,0.33-0.88,0.33-1.96,0.32l-0.08,0c-0.96-0.01-1.09-0.59-1.08-1.15c0.01-1.51,0.01-3.04,0.01-4.52c0-0.58,0-1.16,0-1.75
						l0-1.99c0-0.07-0.03-0.14-0.08-0.19c-0.05-0.05-0.12-0.08-0.19-0.08c-0.29,0-0.58,0-0.86,0c-1.15,0.01-2.34,0.01-3.5-0.02
						c-0.25-0.01-0.41,0.02-0.48,0.09s-0.09,0.23-0.09,0.49c0.03,0.79,0.03,1.58,0.03,2.35c0,0.84,0.01,1.71,0.04,2.56
						c0.03,0.74-0.5,1.16-1.57,1.25c-0.14,0.01-0.25,0.13-0.24,0.27l0,0.29c0.03,1.78,0.04,3.6,0.05,5.35c0,0.53,0.01,1.07,0.01,1.6
						c0,0.46,0,0.92,0,1.37l0,0.18c0,0.12,0.08,0.23,0.2,0.26l0.15,0.04c0.36,0.08,0.62,0.4,0.64,0.77l0.03,0.61
						c0.01,0.13,0.11,0.24,0.24,0.25c0.3,0.02,0.91,0.07,0.88,0.97c0,0.08-0.01,0.17-0.03,0.26c-0.03,0.18-0.05,0.38,0.02,0.47
						c0.05,0.07,0.17,0.12,0.34,0.14l0.32-0.12v0.31c0.02,0.05,0.03,0.13,0,0.2v4.31c0,0.46-0.37,0.83-0.83,0.83l-1.32,0
						c-1.68,0-3.26,0.01-4.89-0.01c-0.23,0-0.38,0.03-0.43,0.08c-0.04,0.04-0.08,0.13-0.06,0.38c0.04,0.67,0.05,1.36,0.07,2.02
						c0.01,0.4,0.02,0.8,0.03,1.21c0.01,0.34-0.07,0.59-0.24,0.77c-0.17,0.17-0.41,0.25-0.75,0.24c-0.5-0.02-1-0.01-1.53-0.01
						l-0.15,0c-0.23,0-0.43-0.08-0.59-0.24c-0.16-0.16-0.25-0.37-0.25-0.59v-3.52c0-0.15-0.12-0.26-0.26-0.26h-3.35
						c-0.15,0-0.26,0.12-0.26,0.26c0,0.14,0,0.28,0,0.41c0.01,0.29,0.02,0.58,0.03,0.88c0.03,0.69,0.05,1.4,0.04,2.1
						c-0.01,0.32-0.34,0.88-0.7,0.91c-0.6,0.05-1.19,0.04-1.82,0.04l-0.15,0c-0.45-0.01-0.83-0.38-0.83-0.83v-3.49
						c0-0.15-0.12-0.26-0.26-0.26h-3.28c-0.15,0-0.26,0.12-0.26,0.26v3.43c0,0.46-0.37,0.83-0.83,0.83h-1.8
						c-0.46,0-0.83-0.37-0.83-0.83v-3.52c0-0.15-0.12-0.26-0.26-0.26h-6.45c-0.46,0-0.83-0.37-0.83-0.83l0-0.64
						c0-1.01,0-1.97,0.01-2.94c0-0.07-0.01-0.14-0.01-0.22c-0.03-0.35-0.08-0.99,0.72-1.12c0.03-0.06,0.05-0.15,0.05-0.19
						c-0.16-1.14,0.47-1.4,0.87-1.46c0.13-0.02,0.23-0.13,0.23-0.26c0-0.05,0-0.1,0-0.16c-0.01-0.46-0.02-1.16,0.8-1.27
						c0.11-0.02,0.22-0.15,0.22-0.27v-8.71c0-0.15-0.12-0.26-0.27-0.26c-0.12,0-0.21,0-0.29,0.01c-0.41,0.05-0.71-0.02-0.92-0.21
						c-0.28-0.26-0.29-0.66-0.28-0.94c0.03-0.66,0.02-1.34,0.02-1.99c0-0.29-0.01-0.58-0.01-0.87v-2.38c0-0.15-0.12-0.26-0.26-0.26
						h-4.66c-0.15,0-0.26,0.12-0.26,0.27v1.68c0,1.79,0,3.54,0,5.3c0,1.15,0,1.73-0.35,2.07c-0.35,0.34-0.92,0.32-2.06,0.28l-0.05,0
						c-0.26-0.01-0.58-0.05-0.79-0.28c-0.21-0.23-0.22-0.55-0.21-0.77c0.02-0.47,0.02-0.95,0.01-1.44l0-0.11
						c0-0.14-0.12-0.26-0.26-0.26h-3.31c-0.15,0-0.26,0.12-0.26,0.26l0,0.28c0,1.13,0,1.69-0.35,2.03
						c-0.35,0.34-0.92,0.32-2.06,0.29l-0.06,0c-0.23-0.01-0.57-0.04-0.78-0.28c-0.21-0.23-0.22-0.55-0.21-0.78
						c0.02-0.48,0.02-0.95,0.01-1.45l0-0.09c0-0.14-0.12-0.26-0.26-0.26h-3.29c-0.15,0-0.26,0.12-0.26,0.26c0,0.16,0,0.31,0,0.46
						c0.01,1.99-0.11,2.11-2.07,2.14l-0.12,0c-0.49,0.01-0.8-0.08-1.01-0.29c-0.27-0.27-0.29-0.68-0.28-1.09
						c0.07-2.62,0.06-5.26,0.04-7.96c-0.01-0.07-0.13-0.27-0.21-0.33c-1.35-0.03-2.69-0.03-4.1-0.03l-0.64,0
						c-0.14,0-0.25,0.11-0.26,0.25c-0.01,0.12-0.01,0.21-0.01,0.29c0,0.38,0,0.75,0.01,1.13c0.01,1.29,0.01,2.61,0.05,3.92
						c0.01,0.3-0.01,0.73-0.3,0.99c-0.21,0.18-0.5,0.23-0.89,0.16c-0.17-0.03-0.4-0.05-0.51,0.04c-0.1,0.08-0.14,0.31-0.13,0.67
						c0.04,1.77,0.05,3.57,0.07,5.31c0,0.61,0.01,1.21,0.01,1.82c0,0.38,0,0.76,0,1.15v0.03c0,0.13,0.1,0.24,0.24,0.26
						c0.79,0.13,0.77,0.81,0.76,1.21c0,0.08,0,0.15,0,0.23c0,0.12,0.09,0.22,0.21,0.25c0.72,0.14,0.79,0.52,0.91,1.45
						c0.01,0.11,0.09,0.2,0.19,0.22c0.36,0.1,0.62,0.43,0.62,0.81v70.13c0,0.15,0.12,0.26,0.26,0.26L1275.41,2306.99z"/>
				</g>
				<g>
					<rect x="1263.95" y="2242.25" className="st29" width="0.71" height="65.07"/>
				</g>
				<g>
					<rect x="1296.62" y="2242.25" className="st29" width="0.71" height="65.07"/>
				</g>
				<g>
					<rect x="1239.74" y="2252.02" className="st36" width="21.7" height="0.28"/>
				</g>
				<g>
					<rect x="1299.67" y="2252.02" className="st36" width="21.7" height="0.28"/>
				</g>
				<g>
					<rect x="1239.74" y="2263.94" className="st35" width="21.7" height="0.57"/>
				</g>
				<g>
					<rect x="1265.97" y="2265.93" className="st36" width="28.79" height="0.28"/>
				</g>
				<g>
					<rect x="1299.67" y="2263.94" className="st35" width="21.7" height="0.57"/>
				</g>
				<g>
					<rect x="1239.74" y="2282.16" className="st35" width="21.7" height="0.57"/>
				</g>
				<g>
					<rect x="1265.97" y="2283.58" className="st35" width="28.79" height="0.57"/>
				</g>
				<g>
					<rect x="1299.67" y="2282.16" className="st35" width="21.7" height="0.57"/>
				</g>
				<g>
					<path className="st37" d="M1288.97,2307.14c-0.47,0-0.87,0-1.35,0c0-2.2,0,2.28,0,0.01c-4.75,0-9.41,0-14.16,0
						c0,2.22,0-2.26,0-0.01c-0.5,0-0.92,0-1.4,0c0-0.32,0-0.59,0-0.87c0-2.54,0.09-5.08-0.03-7.61c-0.15-3.11,1.26-5.35,3.7-7
						c1.2-0.81,2.58-1.38,3.94-1.93c0.49-0.2,1.18-0.17,1.71-0.01c2.31,0.69,4.36,1.85,5.93,3.73c1.1,1.32,1.71,2.8,1.68,4.56
						C1288.94,2301.02,1288.97,2304.04,1288.97,2307.14z"/>
				</g>
				<g>
					<path className="st27" d="M1287.73,2300.02c-4.75,0-9.38,0-14.18,0c0,2.62,0,5.2,0,7.79c4.81,0,9.45,0,14.18,0
						C1287.73,2305.19,1287.73,2302.64,1287.73,2300.02z"/>
				</g>
				<g>
					<g>
						<path className="st27" d="M1242.66,2236.23c-0.75,0-1.42,0-2.18,0c0-1.48-0.07-2.94,0.05-4.39c0.03-0.33,0.69-0.89,1.02-0.86
							c0.39,0.03,1.03,0.55,1.06,0.91C1242.73,2233.31,1242.66,2234.75,1242.66,2236.23z"/>
					</g>
					<g>
						<path className="st27" d="M1260.72,2236.25c-0.77,0-1.43,0-2.18,0c0-1.5-0.06-2.99,0.05-4.46c0.02-0.32,0.73-0.85,1.06-0.81
							c0.39,0.05,0.99,0.59,1.02,0.96C1260.79,2233.34,1260.72,2234.76,1260.72,2236.25z"/>
					</g>
					<g>
						<path className="st27" d="M1254.72,2236.25c-0.78,0-1.44,0-2.22,0c0-0.62,0-1.23,0-1.83c0-0.78-0.01-1.57,0-2.35
							c0.01-0.53,0.58-1.13,1.06-1.16c0.42-0.02,1.13,0.55,1.14,1.02C1254.74,2233.35,1254.72,2234.77,1254.72,2236.25z"/>
					</g>
					<g>
						<path className="st27" d="M1248.69,2236.24c-0.78,0-1.46,0-2.21,0c0-1.47-0.07-2.92,0.05-4.35c0.03-0.36,0.64-0.77,1.08-0.95
							c0.36-0.15,1.07,0.57,1.07,1.04C1248.7,2233.37,1248.69,2234.77,1248.69,2236.24z"/>
					</g>
					<g>
						<path className="st27" d="M1293.94,2254.36c0-0.86,0.01-1.68,0-2.51c-0.01-0.7-0.86-1.49-1.57-1.48c-0.72,0.01-1.55,0.8-1.55,1.5
							c-0.01,0.83,0,1.65,0,2.5c-0.56,0-1.03,0-1.58,0c0-0.85,0.01-1.65,0-2.45c-0.01-0.75-0.84-1.56-1.57-1.55
							c-0.76,0.02-1.54,0.81-1.55,1.58c-0.01,0.8,0,1.6,0,2.44c-0.54,0-0.99,0-1.57,0c0-0.81,0.01-1.6,0-2.4
							c-0.01-0.81-0.84-1.63-1.6-1.61c-0.77,0.02-1.51,0.8-1.51,1.59c0,0.78,0,1.56,0,2.43c-0.55,0-1.01,0-1.59,0
							c0-0.82,0.01-1.62,0-2.42c-0.01-0.8-0.86-1.63-1.62-1.6c-0.76,0.03-1.54,0.82-1.55,1.59c-0.01,0.76-0.01,1.52-0.02,2.28
							c0,0.02-0.01,0.04-0.04,0.12c-0.45,0-0.92,0-1.48,0c0-0.79,0-1.59,0-2.39c0-0.79-0.85-1.63-1.61-1.6
							c-0.77,0.03-1.51,0.81-1.52,1.59c-0.01,0.78,0,1.56,0,2.4c-0.52,0-0.99,0-1.58,0c0-0.8,0-1.6,0-2.39
							c-0.01-0.8-0.84-1.63-1.6-1.6c-0.77,0.03-1.51,0.8-1.51,1.59c0,0.78,0,1.56,0,2.4"/>
					</g>
					<g>
						<path className="st27" d="M1283.19,2283.69c0.05-0.1,0.1-0.15,0.1-0.2c0-4.05,0.02-8.11-0.02-12.16c-0.01-1.09-1.86-2.5-2.84-2.31
							c-1.41,0.27-2.47,1.56-2.48,3c0,3.57,0,7.15,0,10.72c0,0.32,0.03,0.63,0.05,0.94
							C1279.81,2283.69,1281.51,2283.69,1283.19,2283.69z"/>
					</g>
					<g>
						<path className="st27" d="M1248.5,2278.89c1.45,0,2.79,0,4.11,0c0.04-0.09,0.07-0.13,0.07-0.18c0-3.15,0.02-6.3,0-9.45
							c-0.01-0.85-1.37-2.06-2.16-1.94c-0.73,0.11-2.08,1.01-2.04,2.07c0.07,1.73,0.02,3.46,0.02,5.19
							C1248.5,2276,1248.5,2277.41,1248.5,2278.89z"/>
					</g>
					<g>
						<path className="st27" d="M1271.22,2283.69c0-3.51,0.01-6.88,0-10.25c0-1.11-1.11-2.31-2.06-2.3c-0.98,0.01-1.99,1.18-1.99,2.33
							c-0.01,3.07,0,6.14,0,9.21c0,0.33,0.04,0.66,0.07,1.01C1268.57,2283.69,1269.82,2283.69,1271.22,2283.69z"/>
					</g>
					<g>
						<path className="st27" d="M1293.43,2283.69c0-3.51,0.01-6.88,0-10.25c0-1.11-1.11-2.31-2.06-2.3c-0.98,0.01-1.99,1.18-1.99,2.33
							c-0.01,3.07,0,6.14,0,9.21c0,0.33,0.04,0.66,0.07,1.01C1290.78,2283.69,1292.04,2283.69,1293.43,2283.69z"/>
					</g>
					<g>
						<path className="st35" d="M1254.59,2286.03c-2.67,0-5.26,0-7.95,0c0,2.62,0,5.2,0,7.79c2.7,0,5.3,0,7.95,0
							C1254.59,2291.2,1254.59,2288.65,1254.59,2286.03z"/>
					</g>
					<g>
						<path className="st35" d="M1248.65,2242.12c1.33,0,2.62,0,3.96,0c0,2.39,0,4.78,0,7.21c-1.29,0-2.6,0-3.96,0
							C1248.65,2246.96,1248.65,2244.57,1248.65,2242.12z"/>
					</g>
					<g>
						<path className="st35" d="M1248.38,2261.54c-1.35,0-2.64,0-3.99,0c0-2.41,0-4.8,0-7.21c1.31,0,2.63,0,3.99,0
							C1248.38,2256.74,1248.38,2259.11,1248.38,2261.54z"/>
					</g>
					<g>
						<path className="st35" d="M1256.86,2261.54c-1.34,0-2.65,0-4,0c0-2.42,0-4.8,0-7.2c1.34,0,2.65,0,4,0
							C1256.86,2256.74,1256.86,2259.11,1256.86,2261.54z"/>
					</g>
					<g>
						<path className="st27" d="M1301.73,2236.23c-0.75,0-1.42,0-2.18,0c0-1.48-0.07-2.94,0.05-4.39c0.03-0.33,0.69-0.89,1.02-0.86
							c0.39,0.03,1.03,0.55,1.06,0.91C1301.81,2233.31,1301.73,2234.75,1301.73,2236.23z"/>
					</g>
					<g>
						<path className="st27" d="M1319.79,2236.25c-0.77,0-1.43,0-2.18,0c0-1.5-0.06-2.99,0.05-4.46c0.02-0.32,0.73-0.85,1.06-0.81
							c0.39,0.05,0.99,0.59,1.02,0.96C1319.87,2233.34,1319.79,2234.76,1319.79,2236.25z"/>
					</g>
					<g>
						<path className="st27" d="M1313.79,2236.25c-0.78,0-1.44,0-2.22,0c0-0.62,0-1.23,0-1.83c0-0.78-0.01-1.57,0-2.35
							c0.01-0.53,0.58-1.13,1.06-1.16c0.42-0.02,1.13,0.55,1.14,1.02C1313.81,2233.35,1313.79,2234.77,1313.79,2236.25z"/>
					</g>
					<g>
						<path className="st27" d="M1307.76,2236.24c-0.78,0-1.46,0-2.21,0c0-1.47-0.07-2.92,0.05-4.35c0.03-0.36,0.64-0.77,1.08-0.95
							c0.36-0.15,1.07,0.57,1.07,1.04C1307.77,2233.37,1307.76,2234.77,1307.76,2236.24z"/>
					</g>
					<g>
						<path className="st27" d="M1307.57,2278.89c1.45,0,2.79,0,4.11,0c0.04-0.09,0.07-0.13,0.07-0.18c0-3.15,0.02-6.3,0-9.45
							c-0.01-0.85-1.37-2.06-2.16-1.94c-0.73,0.11-2.08,1.01-2.04,2.07c0.07,1.73,0.02,3.46,0.02,5.19
							C1307.57,2276,1307.57,2277.41,1307.57,2278.89z"/>
					</g>
					<g>
						<path className="st35" d="M1313.67,2286.03c-2.67,0-5.26,0-7.95,0c0,2.62,0,5.2,0,7.79c2.7,0,5.3,0,7.95,0
							C1313.67,2291.2,1313.67,2288.65,1313.67,2286.03z"/>
					</g>
					<g>
						<path className="st35" d="M1307.72,2242.12c1.33,0,2.62,0,3.96,0c0,2.39,0,4.78,0,7.21c-1.29,0-2.6,0-3.96,0
							C1307.72,2246.96,1307.72,2244.57,1307.72,2242.12z"/>
					</g>
					<g>
						<path className="st35" d="M1307.46,2261.54c-1.35,0-2.64,0-3.99,0c0-2.41,0-4.8,0-7.21c1.31,0,2.63,0,3.99,0
							C1307.46,2256.74,1307.46,2259.11,1307.46,2261.54z"/>
					</g>
					<g>
						<path className="st35" d="M1315.94,2261.54c-1.34,0-2.65,0-4,0c0-2.42,0-4.8,0-7.2c1.34,0,2.65,0,4,0
							C1315.94,2256.74,1315.94,2259.11,1315.94,2261.54z"/>
					</g>
					<g>
						<path className="st27" d="M1281.81,2263.25c-1.03,0-2.01,0-3.01,0c0-1.48-0.03-2.94,0.02-4.39c0.02-0.63,0.87-1.3,1.48-1.3
							c0.62,0,1.48,0.69,1.5,1.35C1281.85,2260.34,1281.81,2261.78,1281.81,2263.25z"/>
					</g>
					<g>
						<path className="st27" d="M1270.3,2263.25c-1.03,0-2.01,0-3.01,0c0-1.48-0.03-2.94,0.02-4.39c0.02-0.63,0.87-1.3,1.48-1.3
							c0.62,0,1.48,0.69,1.5,1.35C1270.34,2260.34,1270.3,2261.78,1270.3,2263.25z"/>
					</g>
					<g>
						<path className="st27" d="M1293.86,2263.25c-1.03,0-2.01,0-3.01,0c0-1.48-0.03-2.94,0.02-4.39c0.02-0.63,0.87-1.3,1.48-1.3
							c0.62,0,1.48,0.69,1.5,1.35C1293.89,2260.34,1293.86,2261.78,1293.86,2263.25z"/>
					</g>
					<g>
						<path className="st35" d="M1289.09,2307.11c-0.47,0-0.87,0-1.35,0c0-2.2,0-4.42,0-6.68c-4.75,0-9.41,0-14.16,0
							c0,2.22,0,4.44,0,6.68c-0.5,0-0.92,0-1.4,0c0-0.32,0-0.59,0-0.87c0-2.54,0.09-5.08-0.03-7.61c-0.15-3.11,1.26-5.35,3.7-7
							c1.2-0.81,2.58-1.38,3.94-1.93c0.49-0.2,1.18-0.17,1.71-0.01c2.31,0.69,4.36,1.85,5.93,3.73c1.1,1.32,1.71,2.8,1.68,4.56
							C1289.06,2301,1289.09,2304.01,1289.09,2307.11z M1287.64,2298.99c0.28-1.8-0.12-3.36-1.31-4.71
							c-1.45-1.66-3.3-2.66-5.37-3.31c-0.19-0.06-0.43-0.07-0.62-0.01c-2.26,0.71-4.26,1.81-5.74,3.73c-0.98,1.29-1.2,2.72-0.98,4.3
							C1278.32,2298.99,1282.97,2298.99,1287.64,2298.99z"/>
					</g>
				</g>
				<g>
					<rect x="1264.62" y="2242.25" className="st37" width="1.34" height="64.87"/>
				</g>
				<g>
					<rect x="1295.34" y="2242.25" className="st37" width="1.34" height="64.87"/>
				</g>
				<g>
					
						<rect x="1250.22" y="2253.97" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3515.4106 1014.2901)" className="st37" width="0.67" height="21.76"/>
				</g>
				<g>
					
						<rect x="1310.19" y="2253.97" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3575.3745 954.3264)" className="st37" width="0.67" height="21.76"/>
				</g>
				<g>
					
						<rect x="1250.32" y="2241.61" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3503.0017 1001.97)" className="st37" width="0.38" height="21.76"/>
				</g>
				<g>
					
						<rect x="1310.29" y="2241.61" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3562.9653 942.0063)" className="st37" width="0.38" height="21.76"/>
				</g>
				<g>
					
						<rect x="1310.19" y="2272.19" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3593.5898 972.5418)" className="st37" width="0.67" height="21.76"/>
				</g>
				<g>
					
						<rect x="1250.29" y="2272.19" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3533.6912 1032.4404)" className="st37" width="0.67" height="21.76"/>
				</g>
				<g>
					
						<rect x="1280.25" y="2269.06" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3565.0771 1003.9066)" className="st37" width="0.67" height="30.86"/>
				</g>
				<g>
					
						<rect x="1280.42" y="2250.91" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3546.9275 985.757)" className="st37" width="0.34" height="30.86"/>
				</g>
				<g>
					
						<rect x="1281.09" y="2239.01" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 3535.7024 973.1869)" className="st37" width="0.34" height="30.86"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M948.85,1397.18c1.87,0.76,3.8,1.33,5.79,1.67c3.09,0.53,6.28,1.01,9.44,0.94
						c3.15-0.07,6.34-0.51,9.41-1.19c3.12-0.69,6.04-2,8.94-3.3c1.75-0.94,3.15-2.23,4.21-3.86c1.33-1.45,2.22-3.15,2.68-5.1
						c0.63-1.99,0.71-3.99,0.27-5.98c-0.09-2-0.68-3.86-1.78-5.58c-2.06-3.2-5.14-6.02-8.96-6.89c-1.33-0.18-2.66-0.36-3.99-0.54
						c-2.71,0.01-5.23,0.69-7.57,2.05c-0.23,0.1-0.46,0.21-0.69,0.3c1.19-0.5,2.39-1.01,3.58-1.51c-2.22,0.92-4.52,1.54-6.9,1.88
						c1.33-0.18,2.66-0.36,3.99-0.54c-2.51,0.33-5.02,0.34-7.53,0.02c1.33,0.18,2.66,0.36,3.99,0.54c-2.38-0.33-4.68-0.94-6.91-1.84
						c-1.99-0.63-3.99-0.71-5.98-0.27c-2,0.09-3.86,0.68-5.58,1.78c-3.11,1.82-6.08,5.39-6.89,8.96c-0.87,3.84-0.69,8.15,1.51,11.56
						c0.78,1.01,1.56,2.02,2.35,3.04C944.1,1395.18,946.31,1396.47,948.85,1397.18L948.85,1397.18z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path d="M970.7,1384.81c-0.39-0.92-0.55-1.85-1.08-2.45c-1.54-1.73-1.59-3.7-1.11-5.72c0.55-2.33,1.28-4.61,1.95-6.91
							c0.82-2.81,1.17-5.67-0.06-8.4c-1.25-2.76-1.04-5.37-0.17-8.17c1.76-5.7,2.97-11.51,2.48-17.55
							c-0.01-0.11,0.11-0.24,0.18-0.39c0.46-0.03,0.92-0.07,1.38-0.09c6.13-0.29,12.26-0.54,18.39-0.86
							c16.91-0.87,33.82-1.73,50.73-2.65c8.94-0.49,17.87-1.08,26.8-1.69c1.03-0.07,2.09-0.41,3.03-0.84
							c1.27-0.59,1.91-1.69,1.83-3.14c-0.35-6.03-0.72-12.05-1.05-18.08c-0.05-0.97-0.53-1.46-1.43-1.52
							c-0.92-0.06-1.84-0.03-2.76-0.02c-31.7,0.45-63.4,0.91-95.1,1.36c-0.36,0.01-0.75,0.01-1.09-0.1
							c-1.75-0.53-3.51-1.05-5.22-1.7c-0.49-0.19-1.02-0.72-1.2-1.22c-0.52-1.44-0.07-2.8,0.69-4.08c1.23-2.08,1.69-4.27,0.79-6.61
							c-0.77-2.02-2.13-3-4.17-3c-2.04,0.01-3.42,1.01-4.18,3.01c-0.89,2.35-0.33,4.46,0.73,6.64c0.55,1.14,0.68,2.52,0.8,3.8
							c0.09,0.94-0.54,1.42-1.52,1.6c-1.4,0.26-2.76,0.7-4.14,1.06c-0.74,0.19-1.48,0.54-2.21,0.53
							c-32.44-0.38-64.88-0.8-97.31-1.22c-1.08-0.01-1.62,0.31-1.68,1.46c-0.32,5.98-0.67,11.96-1.02,17.94
							c-0.1,1.63,0.64,2.79,2.08,3.4c0.92,0.39,1.93,0.67,2.92,0.73c11.74,0.75,23.48,1.53,35.23,2.15
							c19.54,1.03,39.08,1.95,58.62,2.92c1.14,0.06,2.28,0.13,3.49,0.2c0,1.89-0.07,3.63,0.01,5.36c0.25,4.96,1.62,9.71,2.97,14.45
							c0.41,1.45,0.69,2.91,0.01,4.32c-2.02,4.21-1.57,8.38-0.08,12.62c0.67,1.9,1.11,3.89,1.51,5.87c0.3,1.5-0.05,2.88-1.06,4.14
							c-0.6,0.75-0.89,1.73-1.42,2.83C962.55,1384.81,966.48,1384.81,970.7,1384.81z"/>
					</g>
					<g>
						<path d="M970.7,1384.81c-4.21,0-8.15,0-12.44,0c0.54-1.09,0.83-2.08,1.42-2.83c1.01-1.26,1.36-2.64,1.06-4.14
							c-0.4-1.98-0.84-3.97-1.51-5.87c-1.49-4.24-1.95-8.41,0.08-12.62c0.68-1.41,0.4-2.87-0.01-4.32
							c-1.35-4.74-2.71-9.49-2.97-14.45c-0.09-1.73-0.01-3.47-0.01-5.36c-1.21-0.07-2.35-0.15-3.49-0.2
							c-19.54-0.97-39.08-1.89-58.62-2.92c-11.75-0.62-23.49-1.4-35.23-2.15c-0.99-0.06-2.01-0.34-2.92-0.73
							c-1.45-0.61-2.18-1.77-2.08-3.4c0.35-5.98,0.7-11.96,1.02-17.94c0.06-1.15,0.6-1.47,1.68-1.46
							c32.44,0.43,64.88,0.84,97.31,1.22c0.74,0.01,1.48-0.34,2.21-0.53c1.38-0.36,2.74-0.81,4.14-1.06
							c0.99-0.18,1.61-0.66,1.52-1.6c-0.12-1.29-0.25-2.67-0.8-3.8c-1.06-2.18-1.62-4.29-0.73-6.64c0.76-2,2.13-3,4.18-3.01
							c2.05-0.01,3.4,0.98,4.17,3c0.9,2.35,0.44,4.53-0.79,6.61c-0.76,1.28-1.21,2.64-0.69,4.08c0.18,0.5,0.7,1.03,1.2,1.22
							c1.71,0.65,3.47,1.16,5.22,1.7c0.34,0.1,0.73,0.1,1.09,0.1c31.7-0.45,63.4-0.9,95.1-1.36c0.92-0.01,1.84-0.05,2.76,0.02
							c0.9,0.06,1.38,0.55,1.43,1.52c0.33,6.03,0.69,12.05,1.05,18.08c0.09,1.45-0.56,2.55-1.83,3.14c-0.94,0.44-2,0.77-3.03,0.84
							c-8.93,0.61-17.86,1.2-26.8,1.69c-16.91,0.92-33.82,1.79-50.73,2.65c-6.13,0.31-12.26,0.57-18.39,0.86
							c-0.46,0.02-0.92,0.06-1.38,0.09c-0.08,0.15-0.19,0.28-0.18,0.39c0.49,6.04-0.72,11.85-2.48,17.55
							c-0.87,2.8-1.08,5.41,0.17,8.17c1.23,2.73,0.88,5.59,0.06,8.4c-0.67,2.3-1.4,4.58-1.95,6.91c-0.48,2.02-0.43,3.99,1.11,5.72
							C970.15,1382.96,970.31,1383.89,970.7,1384.81z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st38" d="M967.9,1380.27c-0.39-0.92-0.55-1.85-1.08-2.45c-1.54-1.73-1.59-3.7-1.11-5.72
							c0.55-2.33,1.28-4.61,1.95-6.91c0.82-2.81,1.17-5.67-0.06-8.4c-1.25-2.76-1.04-5.37-0.17-8.17c1.76-5.7,2.97-11.51,2.48-17.55
							c-0.01-0.11,0.11-0.24,0.18-0.39c0.46-0.03,0.92-0.07,1.38-0.09c6.13-0.29,12.26-0.54,18.39-0.86
							c16.91-0.87,33.82-1.73,50.73-2.65c8.94-0.49,17.87-1.08,26.8-1.69c1.03-0.07,2.09-0.41,3.03-0.84
							c1.27-0.59,1.91-1.69,1.83-3.14c-0.35-6.03-0.72-12.05-1.05-18.08c-0.05-0.97-0.53-1.46-1.43-1.52
							c-0.92-0.06-1.84-0.03-2.76-0.02c-31.7,0.45-63.4,0.91-95.1,1.36c-0.36,0.01-0.75,0.01-1.09-0.1
							c-1.75-0.53-3.51-1.05-5.22-1.7c-0.49-0.19-1.02-0.72-1.2-1.22c-0.52-1.44-0.07-2.8,0.69-4.08c1.23-2.08,1.69-4.27,0.79-6.61
							c-0.77-2.02-2.13-3-4.17-3c-2.04,0.01-3.42,1.01-4.18,3.01c-0.89,2.35-0.33,4.46,0.73,6.64c0.55,1.14,0.68,2.52,0.8,3.8
							c0.09,0.94-0.54,1.42-1.52,1.6c-1.4,0.26-2.76,0.7-4.14,1.06c-0.74,0.19-1.48,0.54-2.21,0.53
							c-32.44-0.38-64.88-0.8-97.31-1.22c-1.08-0.01-1.62,0.31-1.68,1.46c-0.32,5.98-0.67,11.96-1.02,17.94
							c-0.1,1.63,0.64,2.79,2.08,3.4c0.92,0.39,1.93,0.67,2.92,0.73c11.74,0.75,23.48,1.53,35.23,2.15
							c19.54,1.03,39.08,1.95,58.62,2.92c1.14,0.06,2.28,0.13,3.49,0.2c0,1.89-0.07,3.63,0.01,5.36c0.25,4.96,1.62,9.71,2.97,14.45
							c0.41,1.45,0.69,2.91,0.01,4.32c-2.02,4.21-1.57,8.38-0.08,12.62c0.67,1.9,1.11,3.89,1.51,5.87c0.3,1.5-0.05,2.88-1.06,4.14
							c-0.6,0.75-0.89,1.73-1.42,2.83C959.75,1380.27,963.68,1380.27,967.9,1380.27z"/>
					</g>
					<g>
						<path className="st38" d="M967.9,1380.27c-4.21,0-8.15,0-12.44,0c0.54-1.09,0.83-2.08,1.42-2.83c1.01-1.26,1.36-2.64,1.06-4.14
							c-0.4-1.98-0.84-3.97-1.51-5.87c-1.49-4.24-1.95-8.41,0.08-12.62c0.68-1.41,0.4-2.87-0.01-4.32
							c-1.35-4.74-2.71-9.49-2.97-14.45c-0.09-1.73-0.01-3.47-0.01-5.36c-1.21-0.07-2.35-0.15-3.49-0.2
							c-19.54-0.97-39.08-1.89-58.62-2.92c-11.75-0.62-23.49-1.4-35.23-2.15c-0.99-0.06-2.01-0.34-2.92-0.73
							c-1.45-0.61-2.18-1.77-2.08-3.4c0.35-5.98,0.7-11.96,1.02-17.94c0.06-1.15,0.6-1.47,1.68-1.46
							c32.44,0.43,64.88,0.84,97.31,1.22c0.74,0.01,1.48-0.34,2.21-0.53c1.38-0.36,2.74-0.81,4.14-1.06
							c0.99-0.18,1.61-0.66,1.52-1.6c-0.12-1.29-0.25-2.67-0.8-3.8c-1.06-2.18-1.62-4.29-0.73-6.64c0.76-2,2.13-3,4.18-3.01
							c2.05-0.01,3.4,0.98,4.17,3c0.9,2.35,0.44,4.53-0.79,6.61c-0.76,1.28-1.21,2.64-0.69,4.08c0.18,0.5,0.7,1.03,1.2,1.22
							c1.71,0.65,3.47,1.16,5.22,1.7c0.34,0.1,0.73,0.1,1.09,0.1c31.7-0.45,63.4-0.9,95.1-1.36c0.92-0.01,1.84-0.05,2.76,0.02
							c0.9,0.06,1.38,0.55,1.43,1.52c0.33,6.03,0.69,12.05,1.05,18.08c0.09,1.45-0.56,2.55-1.83,3.14c-0.94,0.44-2,0.77-3.03,0.84
							c-8.93,0.61-17.86,1.2-26.8,1.69c-16.91,0.92-33.82,1.79-50.73,2.65c-6.13,0.31-12.26,0.57-18.39,0.86
							c-0.46,0.02-0.92,0.06-1.38,0.09c-0.08,0.15-0.19,0.28-0.18,0.39c0.49,6.04-0.72,11.85-2.48,17.55
							c-0.87,2.8-1.08,5.41,0.17,8.17c1.23,2.73,0.88,5.59,0.06,8.4c-0.67,2.3-1.4,4.58-1.95,6.91c-0.48,2.02-0.43,3.99,1.11,5.72
							C967.35,1378.42,967.51,1379.35,967.9,1380.27z M1069.6,1305.59c0.1-1.44-0.44-2.04-2.14-1.99c-1.61,0.04-3.23,0.1-4.84,0.12
							c-29.57,0.4-59.14,0.81-88.71,1.19c-1.11,0.01-1.95,0.13-1.73,1.67c0.49,0,0.99,0.01,1.49,0c29.71-0.47,59.41-0.94,89.12-1.38
							C1065.06,1305.17,1067.32,1305.45,1069.6,1305.59z M854.9,1305.71c1.77-0.19,3.49-0.57,5.21-0.54
							c30.22,0.45,60.45,0.94,90.67,1.43c0.54,0.01,1.08,0,1.61,0c0.15-1.42-0.53-1.65-1.64-1.67c-17.35-0.22-34.7-0.48-52.05-0.73
							c-13.93-0.2-27.87-0.4-41.8-0.59C855.42,1303.59,854.95,1304.13,854.9,1305.71z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M968,1380.34h-12.66l0.05-0.1c0.17-0.35,0.32-0.69,0.46-1.02c0.29-0.67,0.56-1.31,0.97-1.82
							c0.99-1.24,1.34-2.61,1.04-4.08c-0.37-1.81-0.82-3.9-1.51-5.86c-1.74-4.92-1.71-8.95,0.08-12.67c0.7-1.45,0.36-2.96-0.01-4.27
							l-0.03-0.12c-1.32-4.63-2.68-9.42-2.93-14.35c-0.06-1.18-0.05-2.36-0.03-3.6c0.01-0.55,0.01-1.12,0.02-1.7
							c-0.37-0.02-0.73-0.04-1.09-0.06c-0.8-0.05-1.56-0.09-2.33-0.13c-5.96-0.3-11.93-0.59-17.89-0.88
							c-13.35-0.65-27.15-1.33-40.73-2.04c-10.03-0.53-20.22-1.19-30.07-1.82c-1.72-0.11-3.44-0.22-5.16-0.33
							c-0.93-0.06-1.95-0.31-2.95-0.74c-1.49-0.63-2.22-1.83-2.13-3.47l0.01-0.12c0.34-5.84,0.7-11.88,1.01-17.83
							c0.07-1.28,0.75-1.54,1.75-1.53c36.38,0.48,68.21,0.88,97.31,1.22c0.01,0,0.02,0,0.03,0c0.49,0,1-0.16,1.49-0.32
							c0.22-0.07,0.45-0.15,0.67-0.2c0.43-0.11,0.87-0.24,1.3-0.36c0.93-0.26,1.88-0.53,2.84-0.71c1.05-0.19,1.54-0.71,1.47-1.52
							l0-0.04c-0.12-1.29-0.25-2.63-0.79-3.74c-0.91-1.87-1.71-4.13-0.73-6.7c0.77-2.02,2.19-3.04,4.24-3.05c0.01,0,0.01,0,0.02,0
							c2.06,0,3.44,1,4.22,3.04c0.83,2.18,0.57,4.36-0.8,6.67c-0.9,1.53-1.12,2.8-0.68,4.02c0.17,0.48,0.68,1,1.16,1.18
							c1.48,0.56,3.02,1.03,4.51,1.48l0.71,0.22c0.34,0.1,0.75,0.1,1.07,0.09c20.95-0.3,41.91-0.6,62.86-0.9l32.24-0.46
							c0.16,0,0.31-0.01,0.47-0.01c0.75-0.01,1.53-0.03,2.29,0.02c0.94,0.06,1.44,0.6,1.5,1.59c0.26,4.76,0.55,9.6,0.83,14.28
							l0.22,3.8c0.09,1.47-0.58,2.61-1.87,3.21c-1.06,0.49-2.12,0.78-3.05,0.85c-8.13,0.55-17.58,1.18-26.8,1.69
							c-15.3,0.84-30.87,1.63-45.92,2.41l-4.82,0.25c-4.1,0.21-8.26,0.4-12.29,0.58c-2.03,0.09-4.07,0.18-6.1,0.28
							c-0.31,0.01-0.63,0.04-0.94,0.06c-0.13,0.01-0.27,0.02-0.4,0.03c-0.02,0.04-0.05,0.08-0.07,0.12
							c-0.05,0.08-0.09,0.15-0.09,0.2c0.42,5.22-0.34,10.65-2.48,17.58c-0.98,3.19-0.93,5.69,0.17,8.12
							c1.36,3.01,0.75,6.09,0.07,8.44c-0.17,0.59-0.35,1.19-0.53,1.78c-0.5,1.67-1.02,3.4-1.42,5.13c-0.57,2.39-0.22,4.18,1.09,5.66
							c0.37,0.42,0.56,0.99,0.77,1.59c0.1,0.29,0.2,0.59,0.32,0.89L968,1380.34z M955.57,1380.2h12.23c-0.11-0.27-0.2-0.54-0.29-0.8
							c-0.2-0.59-0.39-1.15-0.74-1.54c-1.34-1.51-1.7-3.35-1.12-5.78c0.41-1.73,0.93-3.46,1.43-5.14c0.18-0.59,0.35-1.18,0.53-1.78
							c0.68-2.33,1.28-5.38-0.06-8.35c-1.11-2.46-1.17-5-0.17-8.22c2.13-6.91,2.9-12.32,2.48-17.52c-0.01-0.1,0.05-0.19,0.11-0.29
							c0.03-0.04,0.06-0.09,0.08-0.14l0.02-0.04l0.04,0c0.15-0.01,0.29-0.02,0.44-0.03c0.31-0.02,0.63-0.05,0.94-0.06
							c2.03-0.09,4.07-0.19,6.1-0.28c4.03-0.18,8.19-0.37,12.29-0.58l4.82-0.25c15.05-0.77,30.61-1.57,45.91-2.41
							c9.22-0.5,18.67-1.14,26.8-1.69c0.92-0.06,1.96-0.35,3-0.84c1.25-0.58,1.87-1.64,1.79-3.07l-0.22-3.8
							c-0.28-4.68-0.56-9.52-0.83-14.28c-0.05-0.92-0.5-1.4-1.37-1.45c-0.76-0.05-1.53-0.04-2.28-0.02c-0.16,0-0.32,0.01-0.47,0.01
							l-32.24,0.46c-20.95,0.3-41.91,0.6-62.86,0.9c-0.33,0-0.75,0.01-1.11-0.1l-0.71-0.22c-1.49-0.45-3.03-0.92-4.51-1.48
							c-0.51-0.19-1.06-0.75-1.24-1.26c-0.45-1.26-0.23-2.57,0.69-4.14c1.34-2.28,1.6-4.42,0.79-6.55c-0.76-1.99-2.1-2.95-4.09-2.95
							c-0.01,0-0.01,0-0.02,0c-2.01,0.01-3.36,0.97-4.11,2.96c-0.95,2.52-0.17,4.74,0.73,6.58c0.55,1.14,0.68,2.49,0.81,3.79l0,0.04
							c0.09,0.89-0.46,1.47-1.58,1.68c-0.95,0.17-1.91,0.44-2.83,0.7c-0.43,0.12-0.87,0.24-1.3,0.36c-0.22,0.06-0.45,0.13-0.67,0.2
							c-0.51,0.17-1.04,0.34-1.56,0.33c-29.1-0.34-60.94-0.74-97.31-1.22c-1.12-0.02-1.56,0.36-1.61,1.39
							c-0.31,5.94-0.67,11.98-1.01,17.83l-0.01,0.12c-0.09,1.58,0.61,2.73,2.04,3.34c0.98,0.42,1.98,0.67,2.9,0.73
							c1.72,0.11,3.44,0.22,5.16,0.33c9.85,0.63,20.04,1.29,30.07,1.82c13.57,0.72,27.38,1.39,40.73,2.04
							c5.96,0.29,11.93,0.58,17.89,0.88c0.77,0.04,1.53,0.08,2.34,0.13c0.38,0.02,0.76,0.05,1.15,0.07l0.07,0v0.07
							c0,0.6-0.01,1.19-0.02,1.77c-0.02,1.24-0.03,2.41,0.03,3.59c0.25,4.91,1.61,9.69,2.93,14.32l0.03,0.12
							c0.38,1.33,0.72,2.88,0.01,4.37c-1.77,3.69-1.8,7.68-0.08,12.57c0.7,1.98,1.15,4.07,1.51,5.88c0.31,1.51-0.05,2.92-1.07,4.2
							c-0.4,0.5-0.65,1.09-0.95,1.79C955.85,1379.57,955.72,1379.88,955.57,1380.2z"/>
					</g>
					<g>
						<path className="st8" d="M968.22,1380.48h-13.1l0.15-0.3c0.17-0.35,0.32-0.69,0.46-1.02c0.29-0.68,0.57-1.32,0.99-1.85
							c0.96-1.21,1.31-2.54,1.02-3.97c-0.37-1.8-0.81-3.89-1.5-5.85c-1.75-4.96-1.72-9.02,0.09-12.78c0.68-1.4,0.34-2.89-0.02-4.17
							l-0.03-0.12c-1.32-4.64-2.69-9.43-2.94-14.38c-0.06-1.19-0.05-2.36-0.03-3.61c0.01-0.51,0.01-1.03,0.01-1.56
							c-0.32-0.02-0.64-0.04-0.95-0.06c-0.8-0.05-1.56-0.09-2.33-0.13c-5.96-0.3-11.93-0.59-17.89-0.88
							c-13.35-0.65-27.15-1.33-40.73-2.04c-10.03-0.53-20.22-1.19-30.07-1.82c-1.72-0.11-3.44-0.22-5.16-0.33
							c-0.95-0.06-1.98-0.32-2.99-0.75c-1.55-0.66-2.31-1.9-2.21-3.61l0.01-0.12c0.34-5.84,0.7-11.88,1.01-17.83
							c0.06-1.19,0.61-1.68,1.89-1.66c36.38,0.48,68.21,0.88,97.31,1.22c0.48,0,0.96-0.15,1.47-0.32c0.22-0.07,0.45-0.15,0.68-0.21
							c0.43-0.11,0.87-0.24,1.3-0.36c0.93-0.26,1.89-0.53,2.86-0.71c0.98-0.18,1.42-0.63,1.35-1.37l0-0.04
							c-0.12-1.28-0.25-2.61-0.78-3.7c-0.92-1.9-1.73-4.19-0.74-6.81c0.79-2.08,2.26-3.13,4.37-3.14c0.01,0,0.01,0,0.02,0
							c2.12,0,3.54,1.03,4.35,3.13c0.85,2.22,0.59,4.44-0.81,6.8c-0.89,1.51-1.1,2.71-0.67,3.9c0.16,0.44,0.64,0.93,1.07,1.09
							c1.47,0.56,3.01,1.02,4.5,1.48l0.71,0.22c0.32,0.1,0.71,0.09,1.03,0.09c20.95-0.3,41.91-0.6,62.86-0.9l32.24-0.46
							c0.16,0,0.31-0.01,0.47-0.01c0.76-0.01,1.54-0.03,2.31,0.02c1.02,0.07,1.57,0.65,1.63,1.72c0.26,4.76,0.55,9.6,0.83,14.28
							l0.22,3.8c0.09,1.53-0.6,2.72-1.95,3.34c-1.08,0.5-2.15,0.8-3.1,0.86c-8.13,0.55-17.58,1.18-26.8,1.69
							c-15.3,0.84-30.87,1.63-45.92,2.41l-4.82,0.25c-4.1,0.21-8.26,0.4-12.29,0.58c-2.03,0.09-4.07,0.18-6.1,0.28
							c-0.31,0.01-0.63,0.04-0.93,0.06c-0.11,0.01-0.22,0.02-0.32,0.02c-0.01,0.02-0.02,0.04-0.03,0.06
							c-0.03,0.04-0.07,0.1-0.07,0.13c0.42,5.23-0.34,10.67-2.49,17.62c-0.97,3.15-0.92,5.63,0.16,8.02
							c1.38,3.05,0.76,6.17,0.07,8.54c-0.17,0.59-0.35,1.19-0.53,1.78c-0.5,1.67-1.01,3.4-1.42,5.12c-0.56,2.37-0.23,4.08,1.06,5.53
							c0.39,0.44,0.59,1.02,0.8,1.64c0.1,0.29,0.2,0.58,0.32,0.88L968.22,1380.48z M955.79,1380.06h11.8
							c-0.08-0.21-0.15-0.41-0.22-0.61c-0.2-0.58-0.38-1.12-0.71-1.49c-1.38-1.55-1.74-3.43-1.16-5.91
							c0.41-1.73,0.93-3.47,1.43-5.14c0.18-0.59,0.35-1.18,0.53-1.78c1-3.42,0.98-5.97-0.05-8.25c-1.13-2.5-1.19-5.06-0.18-8.32
							c2.13-6.89,2.89-12.28,2.47-17.47c-0.01-0.14,0.06-0.27,0.13-0.37c0.03-0.04,0.05-0.08,0.07-0.13l0.05-0.11l0.12-0.01
							c0.15-0.01,0.29-0.02,0.44-0.03c0.31-0.02,0.63-0.05,0.94-0.06c2.03-0.09,4.07-0.19,6.1-0.28c4.03-0.18,8.19-0.37,12.29-0.58
							l4.82-0.25c15.05-0.77,30.61-1.57,45.91-2.41c9.22-0.5,18.66-1.14,26.8-1.69c0.9-0.06,1.92-0.35,2.96-0.82
							c1.2-0.55,1.79-1.57,1.71-2.94l-0.22-3.8c-0.28-4.68-0.56-9.52-0.83-14.28c-0.05-0.85-0.44-1.27-1.24-1.32
							c-0.75-0.05-1.52-0.04-2.27-0.02c-0.16,0-0.32,0.01-0.47,0.01l-32.24,0.46c-20.95,0.3-41.91,0.6-62.86,0.9
							c-0.34,0-0.77,0.01-1.16-0.11l-0.71-0.22c-1.49-0.45-3.04-0.92-4.52-1.48c-0.55-0.21-1.12-0.79-1.32-1.35
							c-0.61-1.71,0.11-3.25,0.7-4.26c1.32-2.24,1.58-4.34,0.78-6.43c-0.75-1.95-2-2.86-3.96-2.86c-0.01,0-0.01,0-0.02,0
							c-1.95,0.01-3.25,0.94-3.98,2.87c-0.94,2.47-0.16,4.66,0.72,6.47c0.56,1.16,0.69,2.52,0.82,3.84l0,0.04
							c0.07,0.68-0.18,1.55-1.69,1.83c-0.95,0.17-1.9,0.44-2.82,0.7c-0.43,0.12-0.87,0.24-1.3,0.36c-0.22,0.06-0.44,0.13-0.66,0.2
							c-0.52,0.17-1.06,0.34-1.61,0.34c-29.1-0.34-60.94-0.74-97.31-1.22c-1.05-0.02-1.42,0.3-1.47,1.26
							c-0.31,5.94-0.67,11.99-1.01,17.83l-0.01,0.12c-0.09,1.53,0.57,2.61,1.96,3.2c0.97,0.41,1.95,0.66,2.85,0.71
							c1.72,0.11,3.44,0.22,5.16,0.33c9.85,0.63,20.04,1.29,30.07,1.82c13.57,0.72,27.38,1.39,40.73,2.04
							c5.96,0.29,11.93,0.58,17.89,0.88c0.77,0.04,1.53,0.08,2.34,0.13c0.38,0.02,0.76,0.05,1.15,0.07l0.2,0.01v0.2
							c0,0.6-0.01,1.2-0.02,1.77c-0.02,1.24-0.03,2.41,0.03,3.58c0.25,4.9,1.61,9.67,2.92,14.28l0.03,0.12
							c0.39,1.36,0.74,2.93,0,4.47c-1.76,3.65-1.78,7.61-0.07,12.46c0.7,1.99,1.15,4.09,1.52,5.9c0.32,1.55-0.05,3-1.1,4.31
							c-0.39,0.48-0.64,1.07-0.93,1.75C956.01,1379.57,955.9,1379.81,955.79,1380.06z"/>
					</g>
				</g>
				<g>
					<path className="st8" d="M1069.6,1305.61c0.1-1.44-0.44-2.04-2.14-1.99c-1.61,0.04-3.23,0.1-4.84,0.12
						c-29.57,0.4-59.14,0.81-88.71,1.19c-1.11,0.01-1.95,0.13-1.73,1.67c0.49,0,0.99,0.01,1.49,0c29.71-0.47,59.41-0.94,89.12-1.38
						C1065.06,1305.18,1067.32,1305.47,1069.6,1305.61z"/>
				</g>
				<g>
					<path className="st8" d="M1069.6,1317.42c0.1-1.44-0.44-2.04-2.14-1.99c-1.61,0.04-3.23,0.1-4.84,0.12
						c-29.57,0.4-59.14,0.81-88.71,1.19c-1.11,0.01-1.95,0.13-1.73,1.67c0.49,0,0.99,0.01,1.49,0c29.71-0.47,59.41-0.94,89.12-1.38
						C1065.06,1317,1067.32,1317.28,1069.6,1317.42z"/>
				</g>
				<g>
					<path className="st8" d="M854.9,1305.98c1.77-0.19,3.49-0.57,5.21-0.54c30.22,0.45,60.45,0.94,90.67,1.43c0.54,0.01,1.08,0,1.61,0
						c0.15-1.42-0.53-1.65-1.64-1.67c-17.35-0.22-34.7-0.48-52.05-0.73c-13.93-0.2-27.87-0.4-41.8-0.59
						C855.42,1303.87,854.95,1304.41,854.9,1305.98z"/>
				</g>
				<g>
					<path className="st8" d="M854.9,1317.81c1.77-0.19,3.49-0.57,5.21-0.54c30.22,0.45,60.45,0.94,90.67,1.43c0.54,0.01,1.08,0,1.61,0
						c0.15-1.42-0.53-1.65-1.64-1.67c-17.35-0.22-34.7-0.48-52.05-0.73c-13.93-0.2-27.87-0.4-41.8-0.59
						C855.42,1315.7,854.95,1316.23,854.9,1317.81z"/>
				</g>
				<g>
					<g>
						<path className="st8" d="M951.66,1318.45c1.37-0.44,2.77-0.8,4.18-1.07c0.35-0.07,0.69-0.13,1.04-0.18
							c0.17-0.03,0.34-0.05,0.51-0.08c0.09-0.01,0.19-0.03,0.29-0.04c0.12-0.02-0.16,0.02-0.03,0c0.05-0.01,0.11-0.01,0.16-0.02
							c0.72-0.09,1.44-0.16,2.17-0.21c1.43-0.09,2.86-0.1,4.29-0.03c0.71,0.04,1.43,0.1,2.14,0.18c0.17,0.02,0.34,0.04,0.51,0.06
							c0.04,0.01,0.08,0.01,0.13,0.02c0.12,0.02-0.16-0.02-0.03,0c0.11,0.01,0.21,0.03,0.32,0.04c0.35,0.05,0.7,0.11,1.04,0.17
							c1.6,0.28,3.17,0.67,4.72,1.17c0.42,0.14,0.94-0.16,1.03-0.59c0.1-0.46-0.14-0.89-0.59-1.03c-2.81-0.91-5.74-1.46-8.69-1.66
							c-2.95-0.2-5.92-0.03-8.83,0.47c-1.62,0.28-3.22,0.68-4.78,1.18c-0.42,0.14-0.73,0.59-0.59,1.03
							C950.76,1318.28,951.21,1318.6,951.66,1318.45L951.66,1318.45z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M952.52,1307.1c1.23-0.48,2.48-0.86,3.77-1.16c0.64-0.15,1.28-0.27,1.92-0.37
							c0.16-0.03,0.33-0.05,0.49-0.07c0.02,0,0.13-0.02,0.01,0c-0.12,0.02,0.01,0,0.04-0.01c0.09-0.01,0.18-0.02,0.28-0.03
							c0.33-0.04,0.66-0.07,0.99-0.1c1.3-0.11,2.61-0.13,3.91-0.06c0.66,0.04,1.32,0.09,1.98,0.18c0.07,0.01,0.14,0.02,0.22,0.03
							c0.12,0.02-0.16-0.02-0.04-0.01c0.04,0.01,0.08,0.01,0.12,0.02c0.17,0.02,0.35,0.05,0.52,0.08c0.32,0.05,0.63,0.11,0.95,0.17
							c1.45,0.29,2.88,0.69,4.27,1.21c0.41,0.15,0.94-0.17,1.03-0.59c0.11-0.48-0.15-0.87-0.59-1.03c-2.55-0.95-5.23-1.51-7.94-1.7
							c-2.7-0.19-5.42,0-8.07,0.56c-1.47,0.31-2.91,0.74-4.31,1.28c-0.21,0.08-0.38,0.19-0.5,0.39c-0.11,0.18-0.15,0.45-0.08,0.65
							C951.61,1306.91,952.08,1307.27,952.52,1307.1L952.52,1307.1z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M957.45,1355.62c2.24,1.13,4.82,1.44,7.27,0.9c0.7-0.16,1.38-0.4,2.04-0.69c0.4-0.18,0.53-0.8,0.3-1.15
							c-0.27-0.42-0.72-0.49-1.15-0.3c-0.04,0.02-0.07,0.03-0.11,0.05c-0.02,0.01-0.11,0.05-0.01,0c0.11-0.05,0.01-0.01-0.01,0
							c-0.04,0.01-0.07,0.03-0.11,0.04c-0.13,0.05-0.26,0.1-0.39,0.14c-0.26,0.09-0.52,0.17-0.79,0.23
							c-0.28,0.07-0.55,0.12-0.83,0.17c-0.06,0.01-0.27,0.03-0.04,0.01c-0.06,0-0.12,0.01-0.17,0.02c-0.15,0.02-0.29,0.03-0.44,0.04
							c-0.55,0.04-1.1,0.03-1.64-0.02c-0.07-0.01-0.14-0.01-0.2-0.02c-0.04,0-0.08-0.01-0.12-0.01c0,0,0.17,0.03,0.08,0.01
							c-0.15-0.02-0.29-0.04-0.43-0.07c-0.27-0.05-0.54-0.11-0.8-0.18c-0.29-0.08-0.58-0.17-0.86-0.28
							c-0.07-0.03-0.15-0.06-0.22-0.09c-0.03-0.01-0.15-0.06-0.03-0.01c0.11,0.04-0.01-0.01-0.03-0.02
							c-0.15-0.07-0.3-0.14-0.45-0.21c-0.39-0.2-0.93-0.12-1.15,0.3C956.95,1354.86,957.04,1355.41,957.45,1355.62L957.45,1355.62z"
							/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M953.94,1339.58c3.88,1.21,8.04,1.44,12.04,0.74c1.09-0.19,2.17-0.46,3.23-0.8
							c0.42-0.13,0.73-0.59,0.59-1.03c-0.14-0.42-0.59-0.73-1.03-0.59c-0.94,0.3-1.89,0.55-2.85,0.73
							c-0.23,0.04-0.46,0.09-0.69,0.12c-0.11,0.02-0.21,0.03-0.32,0.05c-0.06,0.01-0.13,0.02-0.19,0.03
							c-0.04,0.01-0.08,0.01-0.13,0.02c0.03,0,0.06-0.01,0.1-0.01c-0.48,0.08-0.98,0.11-1.47,0.15c-0.96,0.07-1.92,0.08-2.88,0.04
							c-0.49-0.02-0.98-0.06-1.47-0.11c-0.12-0.01-0.23-0.03-0.35-0.04c-0.05-0.01-0.11-0.01-0.16-0.02c-0.04-0.01-0.26-0.03-0.03,0
							c-0.25-0.03-0.51-0.07-0.76-0.12c-1.07-0.18-2.14-0.44-3.18-0.76c-0.42-0.13-0.94,0.15-1.03,0.59
							C953.25,1339.01,953.49,1339.44,953.94,1339.58L953.94,1339.58z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M864.63,1304.88c-1.83,2.67-3.67,5.35-5.5,8.02c-0.52,0.76-1.04,1.51-1.56,2.27
							c-0.13,0.19-0.04,0.47,0.15,0.58c0.21,0.12,0.45,0.04,0.58-0.15c1.83-2.67,3.67-5.35,5.5-8.02c0.52-0.76,1.04-1.51,1.56-2.27
							c0.13-0.19,0.04-0.47-0.15-0.58C865,1304.6,864.76,1304.69,864.63,1304.88L864.63,1304.88z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M857.28,1315.67c1.46,1.79,2.81,3.67,4.04,5.62c0.35,0.56,0.69,1.12,1.02,1.7c0.27,0.47,1,0.05,0.73-0.42
							c-1.18-2.04-2.48-4.01-3.91-5.88c-0.42-0.54-0.84-1.08-1.27-1.61c-0.14-0.18-0.45-0.15-0.59,0
							C857.11,1315.25,857.13,1315.49,857.28,1315.67L857.28,1315.67z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M875.31,1305.6c-1.89,2.7-3.79,5.41-5.68,8.11c-0.53,0.76-1.07,1.52-1.6,2.28
							c-0.13,0.19-0.03,0.47,0.15,0.58c0.21,0.12,0.44,0.04,0.58-0.15c1.89-2.7,3.79-5.41,5.68-8.11c0.53-0.76,1.07-1.52,1.6-2.28
							c0.13-0.19,0.03-0.47-0.15-0.58C875.67,1305.32,875.44,1305.41,875.31,1305.6L875.31,1305.6z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M867.81,1316.76c1.36,1.93,2.63,3.92,3.81,5.96c0.34,0.58,0.67,1.17,0.99,1.77
							c0.26,0.48,0.99,0.05,0.73-0.42c-1.13-2.07-2.35-4.09-3.66-6.06c-0.37-0.56-0.76-1.12-1.15-1.67
							c-0.13-0.19-0.37-0.27-0.58-0.15C867.78,1316.29,867.68,1316.57,867.81,1316.76L867.81,1316.76z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M886.34,1305.21c-1.63,2.74-3.33,5.44-5.11,8.09c-0.5,0.75-1.01,1.5-1.53,2.24
							c-0.13,0.19-0.03,0.47,0.15,0.58c0.21,0.12,0.45,0.04,0.58-0.15c1.81-2.62,3.56-5.29,5.23-8.01c0.47-0.77,0.94-1.55,1.4-2.32
							C887.34,1305.17,886.62,1304.74,886.34,1305.21L886.34,1305.21z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M879.66,1316.51c1.56,1.98,3.12,3.96,4.68,5.95c0.45,0.57,0.9,1.14,1.34,1.71c0.14,0.18,0.45,0.15,0.59,0
							c0.18-0.18,0.14-0.42,0-0.59c-1.56-1.98-3.12-3.96-4.68-5.95c-0.45-0.57-0.9-1.14-1.34-1.71c-0.14-0.18-0.45-0.15-0.59,0
							C879.48,1316.09,879.51,1316.33,879.66,1316.51L879.66,1316.51z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M897.28,1305.5c-1.57,2.63-3.13,5.25-4.7,7.88c-0.44,0.74-0.89,1.48-1.33,2.23
							c-0.28,0.47,0.45,0.89,0.73,0.42c1.57-2.63,3.13-5.25,4.7-7.88c0.44-0.74,0.89-1.48,1.33-2.23
							C898.28,1305.46,897.56,1305.04,897.28,1305.5L897.28,1305.5z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M891.06,1316.38c1.25,2.2,2.49,4.4,3.74,6.6c0.35,0.63,0.71,1.25,1.06,1.88
							c0.27,0.47,0.99,0.05,0.73-0.42c-1.25-2.2-2.49-4.4-3.74-6.6c-0.35-0.63-0.71-1.25-1.06-1.88
							C891.52,1315.48,890.79,1315.9,891.06,1316.38L891.06,1316.38z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M907.36,1305.2c-1.15,2.59-2.3,5.17-3.44,7.76c-0.33,0.73-0.65,1.47-0.98,2.2
							c-0.09,0.21-0.06,0.45,0.15,0.58c0.18,0.1,0.48,0.06,0.58-0.15c1.15-2.59,2.3-5.17,3.44-7.76c0.33-0.73,0.65-1.47,0.98-2.2
							c0.09-0.21,0.06-0.45-0.15-0.58C907.75,1304.95,907.45,1305,907.36,1305.2L907.36,1305.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M902.61,1316.07c2.08,2.59,4.09,5.23,6.03,7.93c0.55,0.77,1.1,1.54,1.64,2.32
							c0.13,0.19,0.37,0.27,0.58,0.15c0.18-0.11,0.28-0.39,0.15-0.58c-1.94-2.78-3.96-5.52-6.04-8.19
							c-0.58-0.75-1.17-1.49-1.76-2.23c-0.14-0.18-0.45-0.15-0.59,0C902.44,1315.65,902.47,1315.89,902.61,1316.07L902.61,1316.07z"
							/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M917.61,1305.89c-1.86,2.53-3.31,5.37-4.29,8.35c-0.27,0.84-0.51,1.68-0.71,2.54
							c-0.05,0.22,0.07,0.46,0.29,0.52c0.21,0.06,0.47-0.07,0.52-0.29c0.69-2.99,1.85-5.87,3.44-8.5c0.45-0.75,0.94-1.48,1.46-2.19
							c0.13-0.18,0.03-0.47-0.15-0.58C917.98,1305.61,917.75,1305.7,917.61,1305.89L917.61,1305.89z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M912.98,1317.75c1.98,2.61,3.95,5.22,5.93,7.84c0.56,0.74,1.12,1.48,1.68,2.22
							c0.14,0.18,0.36,0.28,0.58,0.15c0.18-0.1,0.29-0.39,0.15-0.58c-1.98-2.61-3.95-5.22-5.93-7.84c-0.56-0.74-1.12-1.48-1.68-2.22
							c-0.14-0.18-0.36-0.28-0.58-0.15C912.96,1317.28,912.85,1317.56,912.98,1317.75L912.98,1317.75z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M928.07,1306.71c-1.25,2.63-2.73,5.15-4.43,7.52c-0.47,0.66-0.97,1.31-1.47,1.95
							c-0.14,0.18-0.17,0.42,0,0.59c0.15,0.15,0.45,0.18,0.59,0c1.85-2.32,3.5-4.8,4.91-7.42c0.39-0.73,0.77-1.47,1.12-2.22
							c0.1-0.2,0.06-0.45-0.15-0.58C928.46,1306.45,928.17,1306.5,928.07,1306.71L928.07,1306.71z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M921.85,1317c1.87,2.53,3.74,5.06,5.61,7.59c0.53,0.71,1.05,1.43,1.58,2.14
							c0.13,0.18,0.36,0.27,0.58,0.15c0.18-0.11,0.29-0.39,0.15-0.58c-1.87-2.53-3.74-5.06-5.61-7.59
							c-0.53-0.71-1.05-1.43-1.58-2.14c-0.13-0.18-0.36-0.27-0.58-0.15C921.82,1316.53,921.71,1316.82,921.85,1317L921.85,1317z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M940.59,1305.83c-2.09,2.49-4.03,5.11-5.8,7.85c-0.5,0.77-0.98,1.54-1.44,2.33
							c-0.28,0.47,0.45,0.89,0.73,0.42c1.63-2.73,3.43-5.37,5.39-7.88c0.56-0.72,1.14-1.43,1.73-2.13c0.15-0.17,0.17-0.42,0-0.59
							C941.04,1305.68,940.74,1305.65,940.59,1305.83L940.59,1305.83z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M933.12,1316.74c1.37,2.66,2.75,5.33,4.12,7.99c0.39,0.76,0.78,1.51,1.17,2.27
							c0.1,0.2,0.39,0.26,0.58,0.15c0.2-0.12,0.26-0.37,0.15-0.58c-1.37-2.66-2.75-5.33-4.12-7.99c-0.39-0.76-0.78-1.51-1.17-2.27
							c-0.1-0.2-0.39-0.26-0.58-0.15C933.06,1316.28,933.01,1316.54,933.12,1316.74L933.12,1316.74z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M948.72,1306.71c-1.36,2.38-2.73,4.76-4.09,7.13c-0.39,0.68-0.78,1.36-1.17,2.03
							c-0.27,0.47,0.46,0.89,0.73,0.42c1.36-2.38,2.73-4.76,4.09-7.14c0.39-0.68,0.78-1.36,1.17-2.03
							C949.72,1306.66,948.99,1306.24,948.72,1306.71L948.72,1306.71z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M943.84,1317.47c1.42,2.38,2.56,4.92,3.4,7.56c0.24,0.75,0.45,1.51,0.64,2.27
							c0.13,0.53,0.94,0.3,0.81-0.22c-0.68-2.75-1.68-5.42-2.97-7.94c-0.36-0.71-0.75-1.41-1.16-2.09
							C944.29,1316.58,943.56,1317,943.84,1317.47L943.84,1317.47z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M952.22,1306.75c-0.89,2.52-1.28,5.23-1.15,7.9c0.04,0.77,0.12,1.54,0.24,2.31
							c0.04,0.22,0.32,0.35,0.52,0.29c0.24-0.07,0.33-0.29,0.29-0.52c-0.4-2.53-0.3-5.15,0.29-7.64c0.17-0.72,0.37-1.42,0.62-2.12
							c0.08-0.21-0.09-0.46-0.29-0.52C952.51,1306.39,952.3,1306.53,952.22,1306.75L952.22,1306.75z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M951.91,1318.82c0.49,2.87,0.99,5.75,1.48,8.62c0.14,0.81,0.28,1.63,0.42,2.44
							c0.04,0.22,0.31,0.35,0.52,0.29c0.24-0.07,0.33-0.29,0.29-0.52c-0.49-2.87-0.99-5.75-1.48-8.62
							c-0.14-0.81-0.28-1.63-0.42-2.44c-0.04-0.22-0.31-0.35-0.52-0.29C951.97,1318.37,951.87,1318.6,951.91,1318.82L951.91,1318.82
							z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M969.61,1329.2c1.21-2.56,2.14-5.24,2.75-8.01c0.17-0.78,0.32-1.57,0.44-2.36
							c0.03-0.22-0.06-0.45-0.29-0.52c-0.2-0.05-0.48,0.07-0.52,0.29c-0.42,2.75-1.15,5.45-2.17,8.04c-0.29,0.72-0.6,1.43-0.93,2.13
							c-0.1,0.21-0.06,0.45,0.15,0.58C969.21,1329.46,969.51,1329.4,969.61,1329.2L969.61,1329.2z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M973.11,1317.96c-0.12-3.07-0.23-6.15-0.35-9.22c-0.03-0.86-0.06-1.72-0.1-2.58
							c-0.01-0.23-0.19-0.42-0.42-0.42c-0.22,0-0.43,0.19-0.42,0.42c0.12,3.07,0.23,6.15,0.35,9.22c0.03,0.86,0.06,1.72,0.1,2.58
							c0.01,0.23,0.19,0.42,0.42,0.42C972.91,1318.38,973.12,1318.19,973.11,1317.96L973.11,1317.96z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M958.63,1355.93c-1.53,1.75-2.25,4.04-2.33,6.33c-0.1,2.52,0.6,4.99,1.57,7.29
							c0.58,1.36,1.26,2.67,1.96,3.96c0.26,0.48,0.98,0.05,0.73-0.42c-1.19-2.2-2.34-4.46-2.97-6.9c-0.55-2.14-0.66-4.42-0.03-6.56
							c0.34-1.13,0.88-2.21,1.66-3.1c0.15-0.17,0.17-0.43,0-0.59C959.08,1355.78,958.78,1355.76,958.63,1355.93L958.63,1355.93z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M959.97,1373.91c-0.25,1.86-1.1,3.6-2.43,4.92c-0.38,0.38,0.21,0.98,0.59,0.59
							c1.42-1.42,2.38-3.3,2.65-5.3c0.03-0.22-0.05-0.45-0.29-0.52C960.29,1373.56,960,1373.68,959.97,1373.91L959.97,1373.91z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M965.89,1378.78c-1.53-0.9-2.48-2.62-2.44-4.38c0.01-0.54-0.83-0.54-0.84,0
							c-0.04,2.06,1.09,4.07,2.86,5.11C965.94,1379.78,966.36,1379.06,965.89,1378.78L965.89,1378.78z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M962.89,1373.02c1.25-1.74,2.16-3.72,2.71-5.8c0.54-2.06,0.73-4.23,0.52-6.36
							c-0.12-1.21-0.35-2.4-0.72-3.56c-0.16-0.51-0.97-0.29-0.81,0.22c0.62,1.97,0.86,4.04,0.74,6.1c-0.12,2.03-0.6,4.05-1.42,5.91
							c-0.47,1.08-1.06,2.1-1.75,3.06c-0.13,0.18-0.03,0.47,0.15,0.58C962.52,1373.29,962.75,1373.2,962.89,1373.02L962.89,1373.02z
							"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M959.06,1297.63c1.31,1.02,3.12,1.21,4.61,0.45c0.2-0.1,0.26-0.39,0.15-0.58
							c-0.12-0.21-0.37-0.25-0.58-0.15c-1.11,0.57-2.59,0.46-3.59-0.32c-0.18-0.14-0.42-0.17-0.59,0
							C958.91,1297.18,958.88,1297.49,959.06,1297.63L959.06,1297.63z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M960.27,1299.15c0,0.74,0,1.48,0,2.21c0,0.27,0.42,0.27,0.42,0c0-0.74,0-1.48,0-2.21
							C960.69,1298.88,960.27,1298.88,960.27,1299.15L960.27,1299.15z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M962.12,1298.78c0,1.11,0,2.21,0,3.32c0,0.27,0.42,0.27,0.42,0c0-1.11,0-2.21,0-3.32
							C962.54,1298.51,962.12,1298.51,962.12,1298.78L962.12,1298.78z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M960.66,1286.79c-1.66,1.88-2.45,4.47-2.17,6.95c0.08,0.71,0.24,1.41,0.49,2.08
							c0.09,0.25,0.5,0.14,0.41-0.11c-0.84-2.24-0.66-4.82,0.43-6.95c0.31-0.6,0.69-1.17,1.14-1.68
							C961.13,1286.88,960.84,1286.58,960.66,1286.79L960.66,1286.79z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M962.22,1287.77c1.08,1.65,1.61,3.61,1.53,5.57c-0.02,0.56-0.1,1.12-0.23,1.67
							c-0.06,0.26,0.34,0.38,0.41,0.11c0.46-2.01,0.27-4.14-0.53-6.03c-0.23-0.54-0.5-1.05-0.81-1.53
							C962.43,1287.34,962.07,1287.55,962.22,1287.77L962.22,1287.77z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M959.84,1356.99c-0.99,3.34-1.06,6.94-0.23,10.32c0.23,0.95,0.54,1.87,0.91,2.78
							c0.1,0.25,0.51,0.14,0.41-0.11c-1.31-3.16-1.76-6.66-1.29-10.05c0.13-0.96,0.34-1.9,0.61-2.82
							C960.33,1356.84,959.92,1356.73,959.84,1356.99L959.84,1356.99z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M963.19,1357.94c1.51,3.03,1.5,6.75,0.1,9.82c-0.4,0.87-0.9,1.68-1.51,2.41c-0.17,0.21,0.12,0.51,0.3,0.3
							c2.27-2.73,3.13-6.49,2.43-9.95c-0.2-0.97-0.52-1.91-0.96-2.79C963.44,1357.49,963.07,1357.7,963.19,1357.94L963.19,1357.94z"
							/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M958.58,1307.25c0.19,1.85,0.39,3.7,0.58,5.55c0.03,0.27,0.45,0.27,0.42,0c-0.19-1.85-0.39-3.7-0.58-5.55
							C958.98,1306.98,958.56,1306.98,958.58,1307.25L958.58,1307.25z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M959.29,1318.7c0.05,2.35,0.09,4.69,0.14,7.04c0.05,2.35,0.09,4.69,0.14,7.04
							c0.03,1.33,0.05,2.66,0.08,3.99c0.01,0.27,0.43,0.27,0.42,0c-0.05-2.35-0.09-4.69-0.14-7.04c-0.05-2.35-0.09-4.69-0.14-7.04
							c-0.03-1.33-0.05-2.66-0.08-3.99C959.71,1318.43,959.29,1318.43,959.29,1318.7L959.29,1318.7z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M959.7,1341.97c0.41,2.66,0.69,5.34,0.82,8.03c0.04,0.76,0.07,1.51,0.08,2.27c0.01,0.27,0.43,0.27,0.42,0
							c-0.06-2.72-0.26-5.44-0.6-8.14c-0.1-0.76-0.2-1.52-0.32-2.27C960.07,1341.6,959.66,1341.71,959.7,1341.97L959.7,1341.97z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M964.39,1306.17c-0.21,2.22-0.43,4.43-0.64,6.65c-0.03,0.27,0.39,0.27,0.42,0
							c0.21-2.22,0.43-4.43,0.64-6.65C964.84,1305.9,964.42,1305.9,964.39,1306.17L964.39,1306.17z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M964.16,1335.68c0.17-3.84,0.33-7.67,0.5-11.51c0.05-1.08,0.09-2.17,0.14-3.25
							c0.01-0.27-0.41-0.27-0.42,0c-0.17,3.84-0.33,7.67-0.5,11.51c-0.05,1.08-0.09,2.17-0.14,3.25
							C963.72,1335.95,964.14,1335.95,964.16,1335.68L964.16,1335.68z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M963.24,1353.8c0.35-2.49,0.71-4.98,1.06-7.46c0.1-0.71,0.2-1.43,0.3-2.14c0.02-0.11-0.03-0.22-0.15-0.26
							c-0.1-0.03-0.24,0.03-0.26,0.15c-0.35,2.49-0.71,4.98-1.06,7.46c-0.1,0.71-0.2,1.43-0.3,2.14c-0.02,0.11,0.03,0.22,0.15,0.26
							C963.08,1353.97,963.22,1353.91,963.24,1353.8L963.24,1353.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M954.85,1318.97c-0.69,2.22-0.56,4.55-0.14,6.81c0.43,2.3,1.14,4.53,1.64,6.81
							c0.28,1.27,0.49,2.55,0.53,3.85c0.02,0.54,0.86,0.54,0.84,0c-0.08-2.34-0.66-4.63-1.25-6.89c-0.57-2.19-1.18-4.41-1.27-6.68
							c-0.04-1.25,0.09-2.49,0.45-3.68C955.82,1318.68,955.01,1318.46,954.85,1318.97L954.85,1318.97z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M968.52,1319.07c-0.21,2.25-0.77,4.47-1.63,6.56c-0.25,0.59-0.52,1.17-0.82,1.73
							c-0.11,0.2-0.05,0.46,0.15,0.58c0.19,0.11,0.47,0.05,0.58-0.15c1.1-2.1,1.88-4.36,2.3-6.7c0.12-0.67,0.21-1.35,0.27-2.03
							c0.02-0.23-0.21-0.42-0.42-0.42C968.7,1318.65,968.54,1318.84,968.52,1319.07L968.52,1319.07z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M969,1313.17c0.2-2.75-0.08-5.54-0.81-8.2c-0.21-0.75-0.45-1.49-0.73-2.22
							c-0.08-0.21-0.28-0.36-0.52-0.29c-0.2,0.06-0.38,0.3-0.29,0.52c0.97,2.52,1.5,5.19,1.56,7.89c0.02,0.77,0,1.53-0.06,2.3
							c-0.02,0.23,0.21,0.42,0.42,0.42C968.83,1313.59,968.99,1313.4,969,1313.17L969,1313.17z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M955.13,1313.16c-0.12-2.85,0.32-5.69,1.28-8.37c0.27-0.75,0.58-1.48,0.93-2.2
							c0.1-0.2,0.06-0.45-0.15-0.58c-0.19-0.11-0.47-0.05-0.58,0.15c-1.31,2.67-2.09,5.57-2.29,8.53c-0.06,0.82-0.07,1.64-0.03,2.47
							c0.01,0.23,0.19,0.42,0.42,0.42C954.93,1313.58,955.14,1313.39,955.13,1313.16L955.13,1313.16z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1061.8,1306.49c1.83,2.67,3.67,5.35,5.5,8.02c0.52,0.76,1.04,1.51,1.56,2.27
							c0.13,0.19,0.37,0.27,0.58,0.15c0.19-0.11,0.28-0.39,0.15-0.58c-1.83-2.67-3.67-5.35-5.5-8.02c-0.52-0.76-1.04-1.51-1.56-2.27
							c-0.13-0.19-0.37-0.27-0.58-0.15C1061.77,1306.03,1061.67,1306.31,1061.8,1306.49L1061.8,1306.49z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1069.29,1316.27c-1.5,1.83-2.89,3.76-4.15,5.77c-0.36,0.57-0.7,1.14-1.03,1.72
							c-0.27,0.47,0.46,0.89,0.73,0.42c1.16-2.01,2.45-3.95,3.85-5.8c0.39-0.51,0.79-1.02,1.2-1.52c0.14-0.18,0.17-0.42,0-0.59
							C1069.73,1316.12,1069.43,1316.09,1069.29,1316.27L1069.29,1316.27z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1051.13,1307.22c1.89,2.7,3.79,5.41,5.68,8.11c0.53,0.76,1.07,1.52,1.6,2.28
							c0.13,0.19,0.37,0.27,0.58,0.15c0.18-0.11,0.28-0.39,0.15-0.58c-1.89-2.7-3.79-5.41-5.68-8.11c-0.53-0.76-1.07-1.52-1.6-2.28
							c-0.13-0.19-0.37-0.27-0.58-0.15C1051.1,1306.75,1051,1307.03,1051.13,1307.22L1051.13,1307.22z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1058.62,1317.53c-1.36,1.93-2.63,3.92-3.81,5.96c-0.34,0.58-0.67,1.17-0.99,1.77
							c-0.26,0.48,0.47,0.9,0.73,0.42c1.13-2.07,2.35-4.09,3.66-6.06c0.37-0.56,0.76-1.12,1.15-1.67c0.13-0.19,0.03-0.47-0.15-0.58
							C1058.98,1317.26,1058.75,1317.34,1058.62,1317.53L1058.62,1317.53z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1040.09,1306.82c1.63,2.74,3.33,5.44,5.11,8.09c0.5,0.75,1.01,1.5,1.53,2.24
							c0.13,0.19,0.37,0.27,0.58,0.15c0.18-0.11,0.28-0.39,0.15-0.58c-1.81-2.62-3.56-5.29-5.23-8.01c-0.47-0.77-0.94-1.55-1.4-2.32
							C1040.54,1305.94,1039.82,1306.36,1040.09,1306.82L1040.09,1306.82z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1046.91,1317.11c-1.56,1.98-3.12,3.96-4.68,5.95c-0.45,0.57-0.9,1.14-1.34,1.71
							c-0.14,0.18-0.17,0.42,0,0.59c0.15,0.15,0.45,0.18,0.59,0c1.56-1.98,3.12-3.96,4.68-5.95c0.45-0.57,0.9-1.14,1.34-1.71
							c0.14-0.18,0.17-0.42,0-0.59C1047.36,1316.96,1047.05,1316.93,1046.91,1317.11L1046.91,1317.11z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1029.15,1307.12c1.57,2.63,3.13,5.25,4.7,7.88c0.44,0.74,0.89,1.48,1.33,2.23
							c0.28,0.46,1,0.04,0.73-0.42c-1.57-2.63-3.13-5.25-4.7-7.88c-0.44-0.74-0.89-1.48-1.33-2.23
							C1029.6,1306.23,1028.88,1306.65,1029.15,1307.12L1029.15,1307.12z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1035.37,1317.14c-1.25,2.2-2.49,4.4-3.74,6.6c-0.35,0.63-0.71,1.25-1.06,1.88
							c-0.27,0.47,0.46,0.9,0.73,0.42c1.25-2.2,2.49-4.4,3.74-6.6c0.35-0.63,0.71-1.25,1.06-1.88
							C1036.37,1317.1,1035.64,1316.67,1035.37,1317.14L1035.37,1317.14z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1019.08,1306.82c1.15,2.59,2.3,5.17,3.44,7.76c0.33,0.73,0.65,1.47,0.98,2.2
							c0.09,0.21,0.39,0.26,0.58,0.15c0.21-0.13,0.24-0.37,0.15-0.58c-1.15-2.59-2.3-5.17-3.44-7.76c-0.33-0.73-0.65-1.47-0.98-2.2
							c-0.09-0.21-0.39-0.26-0.58-0.15C1019.01,1306.37,1018.98,1306.61,1019.08,1306.82L1019.08,1306.82z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1023.95,1316.67c-2.12,2.65-4.18,5.35-6.16,8.1c-0.55,0.77-1.1,1.54-1.64,2.32
							c-0.13,0.19-0.03,0.47,0.15,0.58c0.21,0.12,0.45,0.04,0.58-0.15c1.9-2.72,3.87-5.4,5.91-8.02c0.58-0.75,1.17-1.49,1.76-2.23
							c0.14-0.18,0.17-0.42,0-0.59C1024.4,1316.52,1024.09,1316.49,1023.95,1316.67L1023.95,1316.67z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1008.82,1307.5c1.82,2.48,3.24,5.23,4.2,8.15c0.27,0.84,0.51,1.68,0.71,2.54
							c0.05,0.22,0.3,0.35,0.52,0.29c0.23-0.06,0.34-0.3,0.29-0.52c-0.71-3.06-1.9-6.01-3.53-8.7c-0.45-0.75-0.94-1.48-1.46-2.19
							c-0.13-0.18-0.36-0.27-0.58-0.15C1008.79,1307.03,1008.68,1307.32,1008.82,1307.5L1008.82,1307.5z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1013.45,1318.51c-1.98,2.61-3.95,5.22-5.93,7.84c-0.56,0.74-1.12,1.48-1.68,2.22
							c-0.14,0.18-0.03,0.47,0.15,0.58c0.22,0.13,0.44,0.03,0.58-0.15c1.98-2.61,3.95-5.22,5.93-7.84c0.56-0.74,1.12-1.48,1.68-2.22
							c0.14-0.18,0.03-0.47-0.15-0.58C1013.81,1318.24,1013.59,1318.33,1013.45,1318.51L1013.45,1318.51z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M998.36,1308.32c1.27,2.67,2.78,5.22,4.51,7.62c0.49,0.68,1.01,1.36,1.53,2.01
							c0.14,0.18,0.45,0.15,0.59,0c0.18-0.18,0.14-0.42,0-0.59c-1.81-2.26-3.41-4.7-4.78-7.25c-0.39-0.73-0.77-1.47-1.12-2.22
							c-0.1-0.21-0.39-0.26-0.58-0.15C998.3,1307.87,998.26,1308.12,998.36,1308.32L998.36,1308.32z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1004.58,1317.77c-1.87,2.53-3.74,5.06-5.61,7.59c-0.53,0.71-1.05,1.43-1.58,2.14
							c-0.14,0.18-0.03,0.47,0.15,0.58c0.21,0.13,0.44,0.03,0.58-0.15c1.87-2.53,3.74-5.06,5.61-7.59c0.53-0.71,1.05-1.43,1.58-2.14
							c0.13-0.18,0.03-0.47-0.15-0.58C1004.95,1317.49,1004.72,1317.59,1004.58,1317.77L1004.58,1317.77z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M985.97,1307.61c2.05,2.44,3.94,5,5.67,7.68c0.5,0.77,0.98,1.54,1.44,2.33c0.28,0.46,1,0.04,0.73-0.42
							c-1.67-2.8-3.51-5.48-5.52-8.05c-0.56-0.72-1.14-1.43-1.73-2.13c-0.15-0.17-0.44-0.15-0.59,0
							C985.8,1307.19,985.82,1307.44,985.97,1307.61L985.97,1307.61z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M993.31,1317.51c-1.37,2.66-2.75,5.33-4.12,7.99c-0.39,0.76-0.78,1.51-1.17,2.27
							c-0.1,0.2-0.05,0.46,0.15,0.58c0.19,0.11,0.47,0.05,0.58-0.15c1.37-2.66,2.75-5.33,4.12-7.99c0.39-0.76,0.78-1.51,1.17-2.27
							c0.1-0.2,0.05-0.46-0.15-0.58C993.7,1317.25,993.42,1317.3,993.31,1317.51L993.31,1317.51z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M977.71,1308.32c1.36,2.38,2.73,4.76,4.09,7.14c0.39,0.68,0.78,1.36,1.17,2.03
							c0.27,0.47,1,0.05,0.73-0.42c-1.36-2.38-2.73-4.76-4.09-7.14c-0.39-0.68-0.78-1.36-1.17-2.03
							C978.17,1307.43,977.44,1307.85,977.71,1308.32L977.71,1308.32z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M982.59,1318.24c-1.45,2.43-2.62,5.04-3.48,7.74c-0.24,0.76-0.46,1.53-0.65,2.3
							c-0.13,0.53,0.68,0.75,0.81,0.22c0.66-2.69,1.63-5.3,2.9-7.77c0.36-0.7,0.74-1.39,1.14-2.06
							C983.59,1318.19,982.87,1317.77,982.59,1318.24L982.59,1318.24z"/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path d="M848.31,1620.71c0-0.42-0.08-1.18-0.22-1.2c-1.48-0.23-0.99-1.35-1.01-2.17c-0.05-1.8-0.18-3.62,0.03-5.4
						c0.25-2.13-1.16-3.17-2.26-4.55c-1.46,1.97-1.43,1.98-2.07,4.57c-0.08,0.33-0.63,0.77-0.96,0.77
						c-2.89,0.06-5.79,0.07-8.68-0.01c-0.36-0.01-1.03-0.62-0.99-0.88c0.28-1.98-1.12-3.03-2.18-4.43
						c-0.85,1.01-1.61,1.92-2.66,3.17c0-2.24,0-4.02,0-5.86c-2.18,0-4.21,0-6.25,0c-0.08,0.87-0.14,1.61-0.21,2.43
						c-1.73,0-3.35,0-5.15,0c-0.04-0.46-0.08-0.78-0.09-1.1c-0.31-9.43-0.64-18.87-0.93-28.3c-0.38-12.33-0.76-24.66-1.05-37
						c-0.04-1.81,0.42-3.63,0.68-5.44c0.07-0.51,0.23-1.02,0.35-1.52c-0.13-0.03-0.27-0.06-0.4-0.09c-0.31,0.82-0.63,1.63-0.94,2.45
						c-0.09-0.02-0.17-0.05-0.26-0.07c0-0.83,0-1.66,0-2.8c-1.01,1.17-1.95,0.39-2.9,0.37c-0.78-0.02-1.56,0.36-2.37,0.57
						c-0.37-0.16-1.11-0.74-1.43-0.56c-1.24,0.69-2.41,0.23-3.66-0.08c0.17,1,0.32,1.83,0.46,2.67c-0.11,0.02-0.23,0.05-0.34,0.07
						c-0.34-0.91-0.67-1.82-1.01-2.73c-0.31,0.48-0.31,0.86-0.17,1.18c1,2.32,1.09,4.76,0.9,7.23c-0.09,1.14-0.18,2.28-0.23,3.43
						c-0.7,17.62-1.39,35.23-2.1,52.85c-0.12,2.93-0.28,5.86-0.42,8.82c-1.8,0-3.45,0-5.11,0c-0.07-0.89-0.13-1.63-0.19-2.36
						c-1.94,0-3.76,0-5.8,0c0,2,0,3.84,0,5.68c-0.58-0.55-0.76-1.15-0.97-1.73c-1.85-5.15-7.01-7.81-11.86-6.35
						c-3.16,0.95-7.19,5.68-7.05,9.77c0.07,1.96,0.03,3.93,0,5.9c-0.01,0.41-0.11,0.84-0.25,1.23c-0.19,0.54-0.65,1.05-0.66,1.58
						c-0.06,3.06-0.02,6.11-0.02,9.17c0,2.19,0,4.39,0,6.63h29.64h50.78C848.25,1631.32,848.32,1626.01,848.31,1620.71z"/>
					<path d="M802.33,1529.06c0.53-0.15,1.01-0.61,1.53-0.63c2.01-0.08,4.03-0.07,6.05,0c0.51,0.02,0.99,0.49,1.52,0.62
						c0.66,0.16,1.36,0.19,2.04,0.28c-0.11-0.58-0.15-1.19-0.34-1.74c-0.23-0.66-0.74-1.25-0.87-1.92
						c-0.38-2.05-0.63-4.13-0.93-6.19c-0.07,0.01-0.15,0.02-0.22,0.04c-0.11,0.57-0.21,1.13-0.32,1.7c-0.2-0.01-0.4-0.02-0.59-0.03
						c-0.11-1.53-0.55-3.14-0.22-4.57c0.32-1.4-0.2-2.43-0.51-3.61c-0.03-0.04-0.06-0.08-0.08-0.13c-0.02-0.04-0.05-0.09-0.07-0.13
						c-0.65-1.07-1.31-2.15-2.03-3.34c-0.17,0-0.59,0-0.66,0c-0.43,0.76-0.8,1.3-1.05,1.88c-0.6,1.37-1.82,2.9-1.58,4.12
						c0.43,2.16,0.4,4.02-0.67,6.22c-0.25-0.68-0.41-1.1-0.56-1.51c-0.59,1.6-0.91,3.16-1.06,4.74c-0.1,1.01,0.07,1.97-1.01,2.68
						c-0.34,0.22-0.17,1.21-0.24,1.85C801.08,1529.28,801.72,1529.23,802.33,1529.06z"/>
				</g>
				<g>
					<g>
						<path className="st39" d="M810.13,1502.52l-2.49-0.03c-1.53,0.17-2.83,1.11-2.83,0.5l-0.16-2.61c0-0.61,1.37-1.46,1.99-1.46h1.65
							c0.61,0,2.02,0.62,2.02,1.24l0.04,0.9C810.35,1501.66,810.74,1502.52,810.13,1502.52z"/>
					</g>
					<g>
						<g>
							<path className="st40" d="M812.42,1602.64c-0.31-9.43-0.64-18.87-0.93-28.3c-0.38-12.33-0.76-24.66-1.05-37
								c-0.04-1.81,0.42-3.63,0.68-5.44c0.07-0.51,0.23-1.02,0.35-1.52c-0.13-0.03-0.27-0.06-0.4-0.09
								c-0.32,0.82-0.63,1.63-0.94,2.45c-0.09-0.02-0.17-0.05-0.26-0.07c0-0.83,0-1.66,0-2.8c-1.01,1.17-1.95,0.39-2.9,0.37
								c-0.78-0.02-1.56,0.36-2.37,0.57c-0.37-0.16-1.11-0.74-1.43-0.56c-1.24,0.69-2.41,0.23-3.66-0.08c0.17,1,0.32,1.83,0.46,2.67
								c-0.11,0.02-0.23,0.05-0.34,0.07c-0.34-0.91-0.67-1.82-1.01-2.73c-0.31,0.48-0.31,0.86-0.17,1.18c1,2.32,1.09,4.76,0.9,7.23
								c-0.09,1.14-0.18,2.28-0.23,3.43c-0.7,17.62-1.39,35.23-2.1,52.85c-0.12,2.91-0.27,6.69-0.41,9.63h15.88
								C812.46,1604.09,812.43,1602.93,812.42,1602.64z"/>
						</g>
						<g>
							<path className="st40" d="M800.29,1526.77c0.53-0.15,1.01-0.61,1.53-0.63c2.01-0.08,4.03-0.07,6.05,0
								c0.51,0.02,0.99,0.49,1.52,0.62c0.66,0.16,1.36,0.19,2.04,0.28c-0.11-0.58-0.15-1.19-0.34-1.74
								c-0.23-0.66-0.74-1.25-0.87-1.92c-0.38-2.05-0.63-4.13-0.93-6.19c-0.07,0.01-0.15,0.02-0.22,0.04
								c-0.11,0.57-0.21,1.13-0.32,1.7c-0.2-0.01-0.4-0.02-0.59-0.03c-0.11-1.53-0.55-3.14-0.22-4.57c0.32-1.4-0.2-2.43-0.51-3.61
								c-0.03-0.04-0.06-0.08-0.08-0.13c-0.02-0.04-0.05-0.09-0.07-0.13c-0.65-1.07-1.31-2.15-2.03-3.34c-0.17,0-0.59,0-0.66,0
								c-0.43,0.76-0.8,1.3-1.05,1.88c-0.6,1.37-1.82,2.9-1.58,4.12c0.43,2.16,0.4,4.02-0.67,6.22c-0.25-0.68-0.41-1.1-0.56-1.51
								c-0.59,1.6-0.91,3.16-1.06,4.74c-0.1,1.01,0.07,1.97-1.01,2.68c-0.34,0.22-0.17,1.21-0.24,1.85
								C799.03,1526.99,799.68,1526.94,800.29,1526.77z"/>
						</g>
					</g>
					<g>
						<path className="st41" d="M845.13,1617.28c0-0.42-0.08-1.18-0.22-1.2c-1.48-0.23-0.99-1.35-1.01-2.17
							c-0.05-1.8-0.18-3.62,0.03-5.4c0.25-2.13-1.16-3.17-2.26-4.55c-1.46,1.97-1.43,1.98-2.07,4.57c-0.08,0.33-0.63,0.77-0.96,0.77
							c-2.89,0.06-5.79,0.07-8.68-0.01c-0.36-0.01-1.03-0.62-0.99-0.88c0.28-1.98-1.12-3.03-2.18-4.43
							c-0.85,1.01-1.61,1.92-2.66,3.17c0-2.24,0-4.02,0-5.86c-2.18,0-4.21,0-6.25,0c-0.08,0.87-0.14,1.61-0.21,2.43
							c-1.73,0-3.35,0-5.15,0c0-0.04-0.01,0.67-0.01,0.63l-15.88,0.06c0,0.02,0-0.76,0-0.75c-1.8,0-3.45,0-5.11,0
							c-0.07-0.89-0.13-1.63-0.19-2.36c-1.94,0-3.76,0-5.8,0c0,2,0,3.84,0,5.68c-0.58-0.55-0.76-1.15-0.97-1.73
							c-1.85-5.15-7.01-7.81-11.86-6.35c-3.16,0.95-7.19,5.68-7.05,9.77c0.07,1.96,0.03,3.93,0,5.9c-0.01,0.41-0.11,0.84-0.25,1.23
							c-0.19,0.54-0.65,1.05-0.66,1.58c-0.06,3.06-0.02,6.11-0.02,9.17c0,2.19,0,4.39,0,6.63h29.64h50.78
							C845.07,1627.89,845.14,1622.59,845.13,1617.28z"/>
					</g>
					<g>
						<g>
							<g>
								<line className="st21" x1="800.6" y1="1633.33" x2="800.6" y2="1633.33"/>
							</g>
							<g>
								<path className="st42" d="M845.41,1633.64h-80.93l0-0.93c0-2,0-3.97,0-5.94c0-0.83,0-1.66-0.01-2.49
									c-0.01-2.19-0.02-4.45,0.03-6.68c0.01-0.39,0.2-0.75,0.39-1.09c0.11-0.2,0.21-0.38,0.28-0.57c0.16-0.44,0.23-0.82,0.24-1.15
									l0.01-0.74c0.02-1.69,0.05-3.43-0.01-5.14c-0.07-1.92,0.79-4.19,2.34-6.22c1.44-1.89,3.27-3.31,4.89-3.79
									c5.05-1.52,10.28,1.27,12.16,6.51l0.05,0.14c0.12,0.34,0.24,0.67,0.44,0.99v-5.24h6.27l0.19,2.36h4.64
									c0.04-0.86,0.08-1.71,0.13-2.56c0.1-1.97,0.2-4.01,0.28-6.02c0.36-9.04,0.72-18.07,1.08-27.11
									c0.34-8.58,0.68-17.16,1.02-25.75c0.04-0.96,0.11-1.94,0.19-2.88l0.04-0.56c0.15-2,0.19-4.62-0.88-7.11
									c-0.19-0.45-0.13-0.91,0.19-1.41l0.27-0.42l0.9,2.44l-0.38-2.22l0.37,0.09c0.89,0.22,8.93,0.83,10,0
									c0.04-0.03,0.05-0.05,0.05-0.06l0.5,0v2.21l0.8-2.08l0.86,0.18l-0.06,0.25c-0.04,0.16-0.08,0.32-0.12,0.48
									c-0.09,0.35-0.18,0.68-0.22,1.02c-0.08,0.53-0.17,1.08-0.26,1.6c-0.22,1.25-0.44,2.55-0.42,3.8
									c0.27,11.56,0.63,23.31,0.98,34.68l0.07,2.31c0.21,6.91,0.45,13.93,0.67,20.72c0.08,2.53,0.17,5.05,0.25,7.58
									c0.01,0.2,0.03,0.41,0.05,0.65c0.01,0.06,0.01,0.13,0.02,0.2h4.69l0.21-2.43h6.73v5.42l2.42-2.89l0.19,0.25
									c0.21,0.28,0.44,0.55,0.67,0.82c0.88,1.04,1.79,2.11,1.56,3.78c0.05,0.18,0.54,0.61,0.75,0.61
									c2.75,0.07,5.59,0.07,8.67,0.01c0.22,0,0.67-0.37,0.73-0.59l0.05-0.22c0.59-2.39,0.61-2.48,2.06-4.44l0.19-0.26l0.2,0.25
									c0.2,0.25,0.42,0.5,0.63,0.73c0.94,1.06,1.91,2.16,1.69,4c-0.17,1.43-0.11,2.91-0.06,4.35c0.01,0.34,0.03,0.68,0.03,1.01
									c0.01,0.18-0.01,0.38-0.03,0.56c-0.07,0.78-0.07,1.23,0.83,1.37c0.35,0.06,0.43,0.82,0.43,1.45c0,1.77,0,3.57-0.01,5.31
									c-0.01,3.48-0.02,7.07,0.03,10.6L845.41,1633.64z M764.98,1633.15h79.92c-0.05-3.46-0.04-6.96-0.03-10.36
									c0.01-1.74,0.01-3.54,0.01-5.31c0-0.36-0.06-0.81-0.11-0.98c-1.3-0.25-1.21-1.2-1.15-1.89c0.02-0.18,0.03-0.35,0.03-0.5
									c-0.01-0.34-0.02-0.67-0.03-1.01c-0.05-1.46-0.11-2.96,0.06-4.43c0.19-1.62-0.62-2.55-1.57-3.61
									c-0.14-0.16-0.29-0.33-0.44-0.5c-1.2,1.63-1.23,1.78-1.79,4.01l-0.05,0.22c-0.11,0.44-0.75,0.95-1.2,0.96
									c-3.09,0.07-5.93,0.06-8.69-0.01c-0.36-0.01-0.8-0.37-1.01-0.62c-0.18-0.21-0.25-0.39-0.23-0.55
									c0.21-1.46-0.56-2.37-1.44-3.41c-0.16-0.19-0.33-0.39-0.49-0.59l-2.9,3.46v-6.29h-5.78l-0.21,2.43h-5.6l-0.02-0.23
									c-0.01-0.16-0.03-0.3-0.04-0.43c-0.02-0.25-0.04-0.47-0.05-0.68c-0.08-2.53-0.17-5.05-0.25-7.58
									c-0.23-6.79-0.46-13.82-0.67-20.73l-0.07-2.31c-0.35-11.37-0.71-23.12-0.98-34.68c-0.03-1.3,0.2-2.62,0.42-3.9
									c0.09-0.52,0.18-1.06,0.26-1.59c0.04-0.28,0.1-0.56,0.17-0.83l-0.77,2.01l-0.66-0.18v-2.39c-0.76,0.33-2.48,0.45-5.14,0.36
									c-1.89-0.06-3.82-0.23-4.66-0.35l0.44,2.56l-0.79,0.16l-0.85-2.32c-0.01,0.16,0.03,0.28,0.07,0.38
									c1.11,2.59,1.08,5.28,0.92,7.34l-0.04,0.56c-0.07,0.94-0.15,1.91-0.18,2.86c-0.34,8.58-0.68,17.16-1.02,25.75
									c-0.36,9.04-0.72,18.07-1.08,27.11c-0.08,2-0.18,4.05-0.28,6.02c-0.05,0.93-0.09,1.86-0.14,2.8l-0.01,0.24h-5.57l-0.19-2.36
									h-5.32v6.01l-0.42-0.4c-0.58-0.55-0.78-1.13-0.98-1.69l-0.05-0.14c-1.79-4.98-6.75-7.64-11.55-6.2
									c-3.14,0.95-7.01,5.62-6.87,9.53c0.06,1.72,0.04,3.47,0.01,5.17l-0.01,0.74c0,0.39-0.09,0.81-0.27,1.31
									c-0.08,0.23-0.2,0.44-0.31,0.65c-0.17,0.3-0.33,0.59-0.33,0.86c-0.04,2.22-0.03,4.48-0.03,6.67c0,0.83,0.01,1.66,0.01,2.5
									c0,1.97,0,3.94,0,5.94L764.98,1633.15z M786.54,1616.37c-0.74,0-1.2-0.14-1.5-0.45c-0.29-0.31-0.4-0.77-0.35-1.48
									c0.05-0.68,0.07-1.24,0.08-1.69c0.08-2.15,0.18-2.25,2.16-2.19c0.41,0.01,0.92,0.03,1.53,0.03
									c5.23-0.02,10.55-0.02,15.69-0.02c6.01,0,12.22,0,18.33-0.03c0.83-0.01,1.47,0.08,1.87,0.49c0.34,0.34,0.49,0.86,0.48,1.7
									l0,0.29c-0.02,3.21-0.12,3.32-3.49,3.32c-0.02,0-0.05,0-0.07,0c-4.45,0-8.9,0-13.35,0l-3.34,0c-1.61,0-3.21,0-4.82,0
									c-3.58-0.01-7.28-0.01-10.92,0.01c-0.75,0.01-1.5,0.01-2.25,0.02l0,0C786.58,1616.37,786.56,1616.37,786.54,1616.37z
									 M786.22,1611.04c-0.38,0-0.63,0.03-0.74,0.14c-0.17,0.17-0.19,0.71-0.22,1.59c-0.02,0.45-0.04,1.02-0.08,1.71
									c-0.04,0.56,0.03,0.91,0.22,1.11c0.2,0.21,0.56,0.3,1.19,0.29l0,0c0.75-0.01,1.5-0.01,2.25-0.02
									c3.64-0.02,7.34-0.02,10.92-0.01c1.61,0,3.21,0,4.82,0l3.34,0c4.45,0,8.9,0,13.34,0c1.53,0,2.38,0,2.72-0.32
									c0.34-0.31,0.34-1.09,0.35-2.51l0-0.29c0.01-0.68-0.1-1.11-0.33-1.34c-0.25-0.25-0.67-0.34-1.52-0.34
									c-6.11,0.03-12.32,0.03-18.33,0.03c-5.14,0-10.46,0-15.69,0.02c-0.63,0-1.14-0.02-1.55-0.03
									C786.65,1611.04,786.42,1611.04,786.22,1611.04z"/>
							</g>
							<g>
								<path className="st42" d="M798.12,1527.59l0.03-0.32c0.02-0.19,0.02-0.42,0.01-0.64c-0.01-0.6-0.01-1.16,0.33-1.39
									c0.79-0.52,0.82-1.16,0.86-1.97c0.01-0.17,0.02-0.34,0.03-0.52c0.16-1.7,0.51-3.27,1.07-4.8l0.23-0.63l0.58,1.58
									c0.72-1.71,0.83-3.36,0.41-5.52c-0.19-0.96,0.4-2.03,0.97-3.07c0.22-0.4,0.45-0.81,0.62-1.2c0.18-0.4,0.4-0.78,0.66-1.21
									c0.13-0.22,0.26-0.44,0.41-0.69l0.07-0.13h0.94l2.24,3.68l-0.03,0.02c0,0,0.01,0.01,0.01,0.01
									c0.02,0.03,0.03,0.05,0.05,0.08l0.02-0.01c0.01,0.03,0.02,0.06,0.03,0.09c0.03,0.07,0.05,0.12,0.05,0.18
									c0.04,0.15,0.09,0.29,0.13,0.43c0.28,0.91,0.57,1.85,0.31,3.02c-0.21,0.92-0.03,2.15,0.15,3.34
									c0.04,0.29,0.09,0.57,0.12,0.85l0.84-1.67l0.11,0.79c0.19,1.3,0.41,2.4,0.65,3.55c0.14,0.67,0.28,1.36,0.41,2.11
									c0.06,0.34,0.25,0.67,0.44,1.02c0.15,0.27,0.31,0.55,0.41,0.86c0.14,0.41,0.21,0.83,0.27,1.25
									c0.03,0.18,0.05,0.36,0.09,0.53l0.06,0.34l-0.34-0.04c-0.2-0.03-0.41-0.05-0.61-0.07c-0.48-0.05-0.98-0.1-1.46-0.22
									c-0.26-0.07-0.5-0.2-0.73-0.33c-0.26-0.14-0.51-0.28-0.73-0.29c-2.01-0.08-4.04-0.08-6.03,0c-0.24,0.01-0.49,0.15-0.76,0.3
									c-0.22,0.12-0.46,0.25-0.71,0.32c-0.44,0.12-0.9,0.19-1.34,0.25c-0.19,0.03-0.38,0.05-0.57,0.08L798.12,1527.59z
									 M800.72,1518.76c-0.42,1.28-0.69,2.61-0.82,4.03c-0.02,0.17-0.02,0.34-0.03,0.5c-0.04,0.84-0.09,1.7-1.09,2.36
									c-0.12,0.08-0.11,0.66-0.11,0.97c0,0.13,0,0.26,0,0.38c0.09-0.01,0.18-0.03,0.28-0.04c0.45-0.06,0.87-0.12,1.28-0.23
									c0.2-0.06,0.39-0.16,0.6-0.28c0.31-0.17,0.63-0.35,0.98-0.36c2-0.08,4.04-0.07,6.07,0c0.35,0.01,0.66,0.19,0.96,0.35
									c0.21,0.12,0.41,0.23,0.61,0.28c0.45,0.11,0.9,0.16,1.39,0.21c0.1,0.01,0.2,0.02,0.29,0.03c-0.01-0.07-0.02-0.15-0.03-0.22
									c-0.06-0.41-0.12-0.8-0.24-1.16c-0.09-0.26-0.23-0.51-0.38-0.78c-0.21-0.37-0.42-0.75-0.5-1.18
									c-0.14-0.75-0.28-1.44-0.41-2.1c-0.19-0.93-0.37-1.82-0.53-2.81l-0.18,0.36l-0.75,1.35l-0.06-0.83
									c-0.03-0.49-0.11-1.03-0.2-1.6c-0.18-1.23-0.38-2.51-0.15-3.52c0.24-1.04-0.02-1.88-0.3-2.76
									c-0.06-0.19-0.12-0.38-0.17-0.58c-0.02-0.03-0.04-0.07-0.06-0.1c-0.07-0.13-0.13-0.22-0.14-0.3l-1.92-3.16h-0.38
									c-0.12,0.21-0.23,0.39-0.34,0.58c-0.25,0.42-0.47,0.78-0.63,1.15c-0.18,0.41-0.41,0.83-0.64,1.24
									c-0.53,0.96-1.07,1.95-0.92,2.73c0.49,2.51,0.29,4.35-0.69,6.38l-0.25,0.52L800.72,1518.76z M807.35,1510.92
									c-0.01,0-0.02,0.01-0.02,0.02L807.35,1510.92L807.35,1510.92z"/>
							</g>
							<g>
								<path className="st42" d="M807.12,1510.91c-0.03-0.05-0.06-0.1-0.08-0.16l0.45-0.2c0.02,0.04,0.04,0.08,0.06,0.11
									L807.12,1510.91z"/>
							</g>
							<g>
								<path className="st43" d="M804.58,1616.09c5.56,0,11.12,0,16.68,0c3.4,0,3.29,0,3.32-3.37c0.01-1.67-0.62-1.94-2.1-1.93
									c-11.34,0.05-22.68,0-34.01,0.04c-4.01,0.01-3.24-0.77-3.53,3.62c-0.09,1.29,0.37,1.68,1.66,1.66
									c0.75-0.01,1.5-0.01,2.25-0.02C794.09,1616.06,799.34,1616.09,804.58,1616.09z"/>
								<path className="st42" d="M786.54,1616.37c-0.74,0-1.2-0.14-1.5-0.45c-0.29-0.31-0.4-0.77-0.35-1.48
									c0.05-0.68,0.07-1.24,0.08-1.69c0.08-2.15,0.18-2.25,2.16-2.19c0.41,0.01,0.92,0.03,1.53,0.03
									c5.23-0.02,10.55-0.02,15.69-0.02c6.01,0,12.22,0,18.33-0.03c0.83,0,1.47,0.08,1.87,0.49c0.34,0.34,0.49,0.86,0.48,1.7
									l0,0.29c-0.02,3.21-0.12,3.32-3.49,3.32c-0.02,0-0.05,0-0.07,0c-3.93,0-7.86,0-11.78,0l-4.91,0c-1.63,0-3.26,0-4.89,0
									c-3.56-0.01-7.24-0.01-10.85,0.01c-0.75,0.01-1.5,0.01-2.25,0.02C786.58,1616.37,786.56,1616.37,786.54,1616.37z
									 M786.22,1611.04c-0.38,0-0.63,0.03-0.74,0.14c-0.17,0.17-0.19,0.71-0.22,1.59c-0.02,0.45-0.04,1.02-0.08,1.71
									c-0.04,0.56,0.03,0.91,0.22,1.11c0.2,0.21,0.56,0.3,1.19,0.29c0.75-0.01,1.5-0.01,2.25-0.02c3.62-0.02,7.3-0.02,10.86-0.01
									c1.63,0,3.26,0,4.88,0l4.91,0c3.92,0,7.85,0,11.77,0c1.53,0,2.38,0,2.72-0.32c0.34-0.31,0.34-1.09,0.35-2.51l0-0.29
									c0.01-0.68-0.1-1.11-0.33-1.34c-0.25-0.25-0.67-0.34-1.52-0.34c-6.11,0.03-12.32,0.03-18.33,0.03
									c-5.14,0-10.46,0-15.69,0.02c-0.63,0-1.14-0.02-1.55-0.03C786.65,1611.04,786.42,1611.04,786.22,1611.04z"/>
							</g>
							<g>
								<path className="st42" d="M788.85,1616.35l0-0.5c0.02,0,0.03,0,0.05,0c0.02,0,0.03,0,0.05,0c0.01,0,0.02,0,0.02,0
									c0.01,0,0.02,0,0.02,0c0.01,0,0.02,0,0.02,0c0.01,0,0.02,0,0.02,0c3.56-0.02,7.17-0.02,10.66-0.01c1.63,0,3.26,0,4.88,0
									l4.91,0c3.18,0,6.37,0,9.55,0c0.05,0,0.1,0,0.14,0c0.06,0,0.11,0,0.17,0c0.64,0,1.27,0,1.91,0l0,0.5c-0.64,0-1.27,0-1.91,0
									c-0.06,0-0.11,0-0.17,0c-0.05,0-0.09,0-0.14,0c-3.71,0-7.42,0-11.13,0l-3.34,0c-1.61,0-3.21,0-4.82,0
									c-3.48-0.01-7.08-0.01-10.63,0.01c-0.01,0-0.02,0-0.02,0c-0.01,0-0.02,0-0.02,0c-0.01,0-0.02,0-0.02,0
									c-0.01,0-0.02,0-0.02,0c-0.01,0-0.02,0-0.02,0C788.96,1616.35,788.9,1616.35,788.85,1616.35z"/>
							</g>
							<g>
								<path className="st44" d="M801.56,1557.68c0,1.88,0,4.16,0,5.97C804.39,1559.98,804.08,1560.47,801.56,1557.68z"/>
								<path className="st42" d="M801.31,1564.37v-7.34l0.43,0.48c0.27,0.3,0.52,0.57,0.74,0.8c0.92,0.98,1.42,1.51,1.33,2.26
									c-0.09,0.69-0.71,1.49-1.94,3.08L801.31,1564.37z M801.81,1558.31v4.6c0.93-1.21,1.45-1.91,1.51-2.41
									c0.06-0.49-0.3-0.9-1.2-1.85C802.02,1558.54,801.92,1558.43,801.81,1558.31z"/>
							</g>
							<g>
								<path className="st44" d="M807.89,1557.64c-2.75,2.71-2.51,3.12,0,5.85C807.89,1561.83,807.89,1559.23,807.89,1557.64z"/>
								<path className="st42" d="M808.14,1564.13l-0.43-0.47c-1.28-1.39-2.01-2.22-2.04-3.1c-0.03-0.89,0.63-1.71,2.05-3.1l0.42-0.42
									V1564.13z M807.64,1558.24c-2.05,2.08-1.88,2.55,0,4.62V1558.24z"/>
							</g>
							<g>
								<path className="st44" d="M802.71,1557.06c0.7,0.95,1.44,1.63,2.03,2.43c0.61-0.83,1.37-1.53,2.03-2.43
									C805.48,1557.06,804.02,1557.06,802.71,1557.06z"/>
								<path className="st42" d="M804.74,1559.9l-0.2-0.27c-0.27-0.37-0.58-0.72-0.91-1.09c-0.37-0.41-0.74-0.83-1.11-1.34l-0.29-0.4
									h5.04l-0.29,0.4c-0.34,0.47-0.71,0.88-1.07,1.28c-0.34,0.39-0.67,0.75-0.96,1.15L804.74,1559.9z M803.22,1557.3
									c0.26,0.32,0.52,0.62,0.78,0.91c0.26,0.29,0.51,0.57,0.74,0.86c0.25-0.32,0.53-0.63,0.79-0.92
									c0.24-0.27,0.49-0.55,0.73-0.85H803.22z"/>
							</g>
							<g>
								<path className="st42" d="M800.6,1633.33"/>
							</g>
						</g>
						<g>
							<path className="st45" d="M770.24,1633.36L770.24,1633.36c-0.86,0-1.57-0.01-1.57-0.01v-11.9c0-1.22,0.7-2.21,1.57-2.21l0,0
								c0.86,0,1.57,0.99,1.57,2.21v11.9C771.81,1633.35,771.11,1633.36,770.24,1633.36z"/>
							<path className="st42" d="M770.24,1633.61c-0.87,0-1.57-0.01-1.57-0.01l-0.25,0v-12.15c0-1.36,0.81-2.46,1.81-2.46
								s1.81,1.1,1.81,2.46v12.15l-0.25,0C771.81,1633.6,771.11,1633.61,770.24,1633.61z M768.93,1633.11c0.28,0,0.76,0,1.32,0
								s1.04,0,1.32,0v-11.65c0-1.08-0.59-1.97-1.32-1.97s-1.32,0.88-1.32,1.97V1633.11z"/>
						</g>
						<g>
							<path className="st45" d="M775.02,1633.36L775.02,1633.36c-0.86,0-1.57-0.01-1.57-0.01v-11.9c0-1.22,0.7-2.21,1.57-2.21l0,0
								c0.86,0,1.57,0.99,1.57,2.21v11.9C776.59,1633.35,775.89,1633.36,775.02,1633.36z"/>
							<path className="st42" d="M775.02,1633.61c-0.87,0-1.57-0.01-1.57-0.01l-0.25,0v-12.15c0-1.36,0.81-2.46,1.81-2.46
								s1.81,1.1,1.81,2.46v12.15l-0.25,0C776.59,1633.6,775.89,1633.61,775.02,1633.61z M773.7,1633.11c0.28,0,0.76,0,1.32,0
								s1.04,0,1.32,0v-11.65c0-1.08-0.59-1.97-1.32-1.97s-1.32,0.88-1.32,1.97V1633.11z"/>
						</g>
						<g>
							<path className="st45" d="M779.55,1633.36L779.55,1633.36c-0.86,0-1.57-0.01-1.57-0.01v-11.9c0-1.22,0.7-2.21,1.57-2.21l0,0
								c0.86,0,1.57,0.99,1.57,2.21v11.9C781.12,1633.35,780.41,1633.36,779.55,1633.36z"/>
							<path className="st42" d="M779.55,1633.61c-0.87,0-1.57-0.01-1.57-0.01l-0.25,0v-12.15c0-1.36,0.81-2.46,1.81-2.46
								s1.81,1.1,1.81,2.46v12.15l-0.25,0C781.12,1633.6,780.42,1633.61,779.55,1633.61z M778.23,1633.11c0.28,0,0.76,0,1.32,0
								s1.04,0,1.32,0v-11.65c0-1.08-0.59-1.97-1.32-1.97c-0.73,0-1.32,0.88-1.32,1.97V1633.11z"/>
						</g>
						<g>
							<path className="st45" d="M830.22,1633.36L830.22,1633.36c-0.86,0-1.57-0.01-1.57-0.01v-11.9c0-1.22,0.7-2.21,1.57-2.21l0,0
								c0.86,0,1.57,0.99,1.57,2.21v11.9C831.78,1633.35,831.08,1633.36,830.22,1633.36z"/>
							<path className="st42" d="M830.22,1633.61c-0.87,0-1.57-0.01-1.57-0.01l-0.25,0v-12.15c0-1.36,0.81-2.46,1.81-2.46
								s1.81,1.1,1.81,2.46v12.15l-0.25,0C831.79,1633.6,831.08,1633.61,830.22,1633.61z M828.9,1633.11c0.28,0,0.76,0,1.32,0
								s1.04,0,1.32,0v-11.65c0-1.08-0.59-1.97-1.32-1.97c-0.73,0-1.32,0.88-1.32,1.97V1633.11z"/>
						</g>
						<g>
							<path className="st45" d="M834.75,1633.36L834.75,1633.36c-0.86,0-1.57-0.01-1.57-0.01v-11.9c0-1.22,0.7-2.21,1.57-2.21h0
								c0.86,0,1.57,0.99,1.57,2.21v11.9C836.31,1633.35,835.61,1633.36,834.75,1633.36z"/>
							<path className="st42" d="M834.75,1633.61c-0.87,0-1.57-0.01-1.57-0.01l-0.25,0v-12.15c0-1.36,0.81-2.46,1.81-2.46
								c1,0,1.81,1.1,1.81,2.46v12.15l-0.25,0C836.31,1633.6,835.61,1633.61,834.75,1633.61z M833.43,1633.11c0.28,0,0.76,0,1.32,0
								c0.55,0,1.04,0,1.32,0v-11.65c0-1.08-0.59-1.97-1.32-1.97c-0.73,0-1.32,0.88-1.32,1.97V1633.11z"/>
						</g>
						<g>
							<path className="st45" d="M839.4,1633.36L839.4,1633.36c-0.86,0-1.57-0.01-1.57-0.01v-11.9c0-1.22,0.7-2.21,1.57-2.21l0,0
								c0.86,0,1.57,0.99,1.57,2.21v11.9C840.96,1633.35,840.26,1633.36,839.4,1633.36z"/>
							<path className="st42" d="M839.4,1633.61c-0.87,0-1.57-0.01-1.57-0.01l-0.25,0v-12.15c0-1.36,0.81-2.46,1.81-2.46
								s1.81,1.1,1.81,2.46v12.15l-0.25,0C840.97,1633.6,840.26,1633.61,839.4,1633.61z M838.08,1633.11c0.28,0,0.76,0,1.32,0
								s1.04,0,1.32,0v-11.65c0-1.08-0.59-1.97-1.32-1.97s-1.32,0.88-1.32,1.97V1633.11z"/>
						</g>
						<g>
							<path className="st46" d="M788.33,1633.3L788.33,1633.3c-1.15,0-2.09,0-2.09,0v-5.9c0-0.61,0.93-1.1,2.09-1.1l0,0
								c1.15,0,2.09,0.49,2.09,1.1v5.9C790.41,1633.3,789.48,1633.3,788.33,1633.3z"/>
							<path className="st42" d="M788.33,1633.55c-1.15,0-2.09,0-2.09,0l-0.25,0v-6.15c0-0.76,1.03-1.35,2.34-1.35
								c1.31,0,2.34,0.59,2.34,1.35v6.15l-0.25,0C790.41,1633.55,789.48,1633.55,788.33,1633.55z M786.49,1633.05
								c0.35,0,1.04,0,1.84,0c0.8,0,1.49,0,1.84,0v-5.66c0-0.4-0.75-0.85-1.84-0.85c-1.08,0-1.84,0.45-1.84,0.85V1633.05z"/>
						</g>
						<g>
							<path className="st46" d="M794.94,1633.3L794.94,1633.3c-1.15,0-2.09,0-2.09,0v-5.9c0-0.61,0.93-1.1,2.09-1.1l0,0
								c1.15,0,2.09,0.49,2.09,1.1v5.9C797.03,1633.3,796.09,1633.3,794.94,1633.3z"/>
							<path className="st42" d="M794.94,1633.55c-1.15,0-2.09,0-2.09,0l-0.25,0v-6.15c0-0.76,1.03-1.35,2.34-1.35
								c1.31,0,2.34,0.59,2.34,1.35v6.15l-0.25,0C797.03,1633.55,796.09,1633.55,794.94,1633.55z M793.1,1633.05
								c0.35,0,1.04,0,1.84,0s1.49,0,1.84,0v-5.66c0-0.4-0.75-0.85-1.84-0.85c-1.08,0-1.84,0.45-1.84,0.85V1633.05z"/>
						</g>
						<g>
							<path className="st46" d="M801.39,1633.3L801.39,1633.3c-1.15,0-2.09,0-2.09,0v-5.9c0-0.61,0.93-1.1,2.09-1.1l0,0
								c1.15,0,2.09,0.49,2.09,1.1v5.9C803.48,1633.3,802.55,1633.3,801.39,1633.3z"/>
							<path className="st42" d="M801.39,1633.55c-1.15,0-2.09,0-2.09,0l-0.25,0v-6.15c0-0.76,1.03-1.35,2.34-1.35s2.34,0.59,2.34,1.35
								v6.15l-0.25,0C803.48,1633.55,802.55,1633.55,801.39,1633.55z M799.56,1633.05c0.35,0,1.04,0,1.84,0s1.49,0,1.84,0v-5.66
								c0-0.4-0.76-0.85-1.84-0.85s-1.84,0.45-1.84,0.85V1633.05z"/>
						</g>
						<g>
							<path className="st46" d="M807.76,1633.3L807.76,1633.3c-1.15,0-2.09,0-2.09,0v-5.9c0-0.61,0.93-1.1,2.09-1.1l0,0
								c1.15,0,2.09,0.49,2.09,1.1v5.9C809.85,1633.3,808.91,1633.3,807.76,1633.3z"/>
							<path className="st42" d="M807.76,1633.55c-1.15,0-2.09,0-2.09,0l-0.25,0v-6.15c0-0.76,1.03-1.35,2.34-1.35
								c1.31,0,2.34,0.59,2.34,1.35v6.15l-0.25,0C809.85,1633.55,808.91,1633.55,807.76,1633.55z M805.92,1633.05
								c0.35,0,1.04,0,1.84,0s1.49,0,1.84,0v-5.66c0-0.4-0.76-0.85-1.84-0.85s-1.84,0.45-1.84,0.85V1633.05z"/>
						</g>
						<g>
							<path className="st46" d="M814.38,1633.3L814.38,1633.3c-1.15,0-2.09,0-2.09,0v-5.9c0-0.61,0.93-1.1,2.09-1.1h0
								c1.15,0,2.09,0.49,2.09,1.1v5.9C816.46,1633.3,815.53,1633.3,814.38,1633.3z"/>
							<path className="st42" d="M814.38,1633.55c-1.15,0-2.09,0-2.09,0l-0.25,0v-6.15c0-0.76,1.03-1.35,2.34-1.35
								c1.31,0,2.34,0.59,2.34,1.35v6.15l-0.25,0C816.47,1633.55,815.53,1633.55,814.38,1633.55z M812.54,1633.05
								c0.35,0,1.04,0,1.84,0c0.8,0,1.49,0,1.84,0v-5.66c0-0.4-0.75-0.85-1.84-0.85c-1.08,0-1.84,0.45-1.84,0.85V1633.05z"/>
						</g>
						<g>
							<path className="st46" d="M820.83,1633.3L820.83,1633.3c-1.15,0-2.09,0-2.09,0v-5.9c0-0.61,0.93-1.1,2.09-1.1h0
								c1.15,0,2.09,0.49,2.09,1.1v5.9C822.92,1633.3,821.98,1633.3,820.83,1633.3z"/>
							<path className="st42" d="M820.83,1633.55c-1.15,0-2.09,0-2.09,0l-0.25,0v-6.15c0-0.76,1.03-1.35,2.34-1.35
								c1.31,0,2.34,0.59,2.34,1.35v6.15l-0.25,0C822.92,1633.55,821.98,1633.55,820.83,1633.55z M818.99,1633.05
								c0.35,0,1.04,0,1.84,0c0.8,0,1.49,0,1.84,0v-5.66c0-0.4-0.75-0.85-1.84-0.85c-1.08,0-1.84,0.45-1.84,0.85V1633.05z"/>
						</g>
						<g>
							<path className="st44" d="M804.88,1542.8c0.83,0.9,1.59,1.74,2.58,2.82c-1.82,0-3.31,0-5.17,0
								C803.27,1544.55,804,1543.76,804.88,1542.8z"/>
							<path className="st42" d="M808.02,1545.87h-6.3l3.16-3.44L808.02,1545.87z M802.85,1545.38h4.04l-2.01-2.2L802.85,1545.38z"/>
						</g>
						<g>
							<path className="st44" d="M801.6,1538.68c0,1.88,0,4.16,0,5.97C804.44,1540.99,804.13,1541.47,801.6,1538.68z"/>
							<path className="st42" d="M801.35,1545.37v-7.34l0.43,0.48c0.27,0.3,0.52,0.57,0.74,0.8c0.91,0.98,1.42,1.51,1.33,2.26
								c-0.09,0.69-0.71,1.49-1.94,3.08L801.35,1545.37z M801.85,1539.32v4.6c0.93-1.21,1.45-1.91,1.51-2.41
								c0.06-0.49-0.3-0.9-1.2-1.85C802.07,1539.55,801.96,1539.43,801.85,1539.32z"/>
						</g>
						<g>
							<path className="st44" d="M807.93,1538.64c-2.75,2.71-2.51,3.12,0,5.85C807.93,1542.83,807.93,1540.23,807.93,1538.64z"/>
							<path className="st42" d="M808.18,1545.14l-0.43-0.47c-1.28-1.39-2.01-2.22-2.04-3.1c-0.03-0.89,0.63-1.71,2.05-3.1l0.42-0.42
								V1545.14z M807.68,1539.24c-2.05,2.08-1.88,2.55,0,4.62V1539.24z"/>
						</g>
						<g>
							<path className="st44" d="M802.76,1538.06c0.7,0.95,1.44,1.63,2.03,2.43c0.61-0.83,1.37-1.53,2.03-2.43
								C805.53,1538.06,804.07,1538.06,802.76,1538.06z"/>
							<path className="st42" d="M804.78,1540.91l-0.2-0.27c-0.27-0.37-0.58-0.72-0.91-1.09c-0.37-0.41-0.74-0.83-1.11-1.34l-0.29-0.4
								h5.04l-0.29,0.4c-0.34,0.47-0.71,0.88-1.07,1.28c-0.34,0.39-0.67,0.75-0.96,1.15L804.78,1540.91z M803.26,1538.31
								c0.26,0.32,0.52,0.62,0.78,0.91c0.26,0.29,0.51,0.57,0.74,0.86c0.25-0.32,0.53-0.63,0.79-0.92c0.24-0.27,0.49-0.55,0.73-0.85
								H803.26z"/>
						</g>
						<g>
							<path className="st44" d="M804.75,1552.47c0.83,0.9,1.59,1.74,2.58,2.82c-1.82,0-3.31,0-5.17,0
								C803.14,1554.22,803.87,1553.43,804.75,1552.47z"/>
							<path className="st42" d="M807.89,1555.54h-6.3l3.16-3.44L807.89,1555.54z M802.72,1555.05h4.04l-2.01-2.2L802.72,1555.05z"/>
						</g>
						<g>
							<path className="st44" d="M801.47,1548.35c0,1.88,0,4.16,0,5.97C804.31,1550.66,804,1551.14,801.47,1548.35z"/>
							<path className="st42" d="M801.22,1555.04v-7.34l0.43,0.48c0.27,0.3,0.52,0.57,0.74,0.8c0.91,0.98,1.42,1.51,1.33,2.26
								c-0.09,0.69-0.71,1.49-1.94,3.08L801.22,1555.04z M801.72,1548.99v4.6c0.93-1.21,1.45-1.91,1.51-2.41
								c0.06-0.49-0.3-0.9-1.2-1.85C801.94,1549.22,801.83,1549.1,801.72,1548.99z"/>
						</g>
						<g>
							<path className="st44" d="M807.8,1548.31c-2.75,2.71-2.51,3.12,0,5.85C807.8,1552.5,807.8,1549.9,807.8,1548.31z"/>
							<path className="st42" d="M808.05,1554.81l-0.43-0.47c-1.28-1.39-2.01-2.22-2.04-3.1c-0.03-0.89,0.63-1.71,2.05-3.1l0.42-0.42
								V1554.81z M807.55,1548.91c-2.05,2.08-1.88,2.55,0,4.62V1548.91z"/>
						</g>
						<g>
							<path className="st44" d="M802.63,1547.73c0.7,0.95,1.44,1.63,2.03,2.43c0.61-0.83,1.37-1.53,2.03-2.43
								C805.4,1547.73,803.94,1547.73,802.63,1547.73z"/>
							<path className="st42" d="M804.65,1550.58l-0.2-0.27c-0.27-0.37-0.58-0.72-0.91-1.09c-0.37-0.41-0.74-0.83-1.11-1.34l-0.29-0.4
								h5.04l-0.29,0.4c-0.34,0.47-0.71,0.88-1.07,1.28c-0.34,0.39-0.67,0.75-0.96,1.15L804.65,1550.58z M803.13,1547.98
								c0.26,0.32,0.52,0.62,0.78,0.91c0.26,0.29,0.51,0.57,0.74,0.86c0.25-0.32,0.53-0.63,0.79-0.92c0.24-0.27,0.49-0.55,0.73-0.85
								H803.13z"/>
						</g>
						<g>
							<path className="st44" d="M804.85,1571.5c0.83,0.9,1.59,1.74,2.58,2.82c-1.82,0-3.31,0-5.17,0
								C803.25,1573.24,803.97,1572.45,804.85,1571.5z"/>
							<path className="st42" d="M807.99,1574.57h-6.3l3.16-3.44L807.99,1574.57z M802.83,1574.07h4.04l-2.01-2.2L802.83,1574.07z"/>
						</g>
						<g>
							<path className="st44" d="M801.58,1567.37c0,1.88,0,4.16,0,5.97C804.42,1569.68,804.11,1570.17,801.58,1567.37z"/>
							<path className="st42" d="M801.33,1574.07v-7.34l0.43,0.48c0.27,0.3,0.52,0.57,0.74,0.8c0.91,0.98,1.42,1.51,1.33,2.26
								c-0.09,0.69-0.71,1.49-1.94,3.08L801.33,1574.07z M801.83,1568.01v4.6c0.93-1.21,1.45-1.91,1.51-2.41
								c0.06-0.49-0.3-0.9-1.2-1.85C802.04,1568.24,801.94,1568.13,801.83,1568.01z"/>
						</g>
						<g>
							<path className="st44" d="M807.91,1567.34c-2.75,2.71-2.51,3.12,0,5.85C807.91,1571.53,807.91,1568.93,807.91,1567.34z"/>
							<path className="st42" d="M808.16,1573.83l-0.43-0.47c-1.28-1.39-2.01-2.22-2.04-3.1c-0.03-0.89,0.63-1.71,2.05-3.1l0.42-0.42
								V1573.83z M807.66,1567.94c-2.05,2.08-1.88,2.55,0,4.62V1567.94z"/>
						</g>
						<g>
							<path className="st44" d="M802.73,1566.75c0.7,0.95,1.44,1.63,2.03,2.43c0.61-0.83,1.37-1.53,2.03-2.43
								C805.51,1566.75,804.04,1566.75,802.73,1566.75z"/>
							<path className="st42" d="M804.76,1569.6l-0.2-0.27c-0.27-0.37-0.58-0.72-0.91-1.09c-0.37-0.41-0.74-0.83-1.11-1.34l-0.29-0.4
								h5.04l-0.29,0.4c-0.34,0.47-0.71,0.88-1.07,1.28c-0.34,0.39-0.67,0.75-0.96,1.15L804.76,1569.6z M803.24,1567
								c0.26,0.32,0.52,0.62,0.78,0.91c0.26,0.29,0.51,0.57,0.74,0.86c0.25-0.32,0.53-0.63,0.79-0.92c0.24-0.27,0.49-0.55,0.73-0.85
								H803.24z"/>
						</g>
						<g>
							<path className="st44" d="M804.77,1581.09c0.9,0.91,1.74,1.75,2.82,2.83c-1.99,0-3.63,0-5.66,0
								C803.01,1582.84,803.8,1582.05,804.77,1581.09z"/>
							<path className="st42" d="M808.19,1584.17h-6.86l3.44-3.43L808.19,1584.17z M802.53,1583.67h4.46l-2.22-2.23L802.53,1583.67z"/>
						</g>
						<g>
							<path className="st44" d="M801.21,1576.95c0,1.88,0,4.17,0,5.99C804.32,1579.27,803.98,1579.75,801.21,1576.95z"/>
							<path className="st42" d="M800.97,1583.62v-7.27l0.43,0.43c0.3,0.3,0.57,0.57,0.81,0.8c1,0.98,1.56,1.52,1.45,2.27
								c-0.1,0.7-0.77,1.5-2.12,3.09L800.97,1583.62z M801.46,1577.55v4.71c1.05-1.24,1.63-1.97,1.7-2.47
								c0.07-0.48-0.33-0.89-1.31-1.85C801.73,1577.82,801.6,1577.69,801.46,1577.55z"/>
						</g>
						<g>
							<path className="st44" d="M808.08,1576.92c-3.01,2.72-2.75,3.13,0,5.87C808.08,1581.12,808.08,1578.51,808.08,1576.92z"/>
							<path className="st42" d="M808.33,1583.39l-0.42-0.42c-1.48-1.48-2.26-2.29-2.24-3.21c0.02-0.86,0.77-1.69,2.24-3.02l0.42-0.37
								V1583.39z M807.83,1577.48c-1.07,0.99-1.65,1.68-1.67,2.28c-0.02,0.64,0.58,1.33,1.67,2.42V1577.48z"/>
						</g>
						<g>
							<path className="st44" d="M802.44,1576.33c0.76,0.96,1.58,1.63,2.22,2.44c0.67-0.83,1.5-1.54,2.22-2.44
								C805.48,1576.33,803.88,1576.33,802.44,1576.33z"/>
							<path className="st42" d="M804.66,1579.17l-0.19-0.24c-0.3-0.38-0.64-0.72-1-1.09c-0.4-0.41-0.81-0.83-1.22-1.34l-0.32-0.4h5.48
								l-0.32,0.4c-0.37,0.47-0.78,0.88-1.17,1.28c-0.38,0.39-0.74,0.75-1.05,1.15L804.66,1579.17z M802.98,1576.58
								c0.28,0.32,0.57,0.62,0.85,0.9c0.29,0.3,0.58,0.59,0.84,0.9c0.28-0.33,0.59-0.65,0.89-0.96c0.26-0.27,0.54-0.55,0.8-0.84
								H802.98z"/>
						</g>
						<g>
							<path className="st44" d="M804.82,1590.76c1,1,1.92,1.92,3.11,3.12c-2.2,0-4,0-6.24,0
								C802.89,1592.69,803.76,1591.82,804.82,1590.76z"/>
							<path className="st42" d="M808.53,1594.13h-7.44l3.73-3.72L808.53,1594.13z M802.3,1593.63h5.04l-2.51-2.52L802.3,1593.63z"/>
						</g>
						<g>
							<path className="st44" d="M800.95,1586.24c0,2.07,0,4.59,0,6.6C804.37,1588.79,804,1589.33,800.95,1586.24z"/>
							<path className="st42" d="M800.7,1593.51v-7.88l0.43,0.43c0.33,0.33,0.63,0.63,0.9,0.89c1.1,1.07,1.71,1.67,1.6,2.48
								c-0.1,0.76-0.85,1.64-2.34,3.4L800.7,1593.51z M801.2,1586.84v5.32c1.19-1.41,1.85-2.22,1.93-2.79
								c0.07-0.55-0.36-1-1.45-2.06C801.52,1587.16,801.37,1587.01,801.2,1586.84z"/>
						</g>
						<g>
							<path className="st44" d="M808.44,1586.2c-3.32,2.99-3.04,3.45,0,6.47C808.44,1590.83,808.44,1587.96,808.44,1586.2z"/>
							<path className="st42" d="M808.69,1593.27l-0.42-0.42c-1.63-1.62-2.48-2.52-2.46-3.52c0.02-0.94,0.85-1.85,2.47-3.31l0.42-0.37
								V1593.27z M808.19,1586.76c-1.21,1.12-1.87,1.89-1.89,2.58c-0.02,0.72,0.66,1.5,1.89,2.73V1586.76z"/>
						</g>
						<g>
							<path className="st44" d="M802.26,1585.59c0.84,1.05,1.74,1.8,2.45,2.68c0.73-0.92,1.66-1.7,2.45-2.68
								C805.61,1585.59,803.85,1585.59,802.26,1585.59z"/>
							<path className="st42" d="M804.71,1588.67l-0.19-0.24c-0.33-0.42-0.71-0.8-1.1-1.21c-0.44-0.45-0.9-0.92-1.34-1.48l-0.32-0.4
								h5.93l-0.32,0.4c-0.41,0.51-0.86,0.97-1.29,1.41c-0.42,0.43-0.81,0.83-1.16,1.27L804.71,1588.67z M802.8,1585.84
								c0.32,0.37,0.65,0.71,0.97,1.04c0.33,0.34,0.65,0.66,0.94,1.01c0.31-0.37,0.65-0.71,1-1.07c0.3-0.31,0.62-0.63,0.91-0.97
								H802.8z"/>
						</g>
						<g>
							<path className="st42" d="M808.87,1604.19h-7.95l3.99-3.97L808.87,1604.19z M802.12,1603.69h5.55l-2.77-2.77L802.12,1603.69z"/>
						</g>
						<g>
							<path className="st44" d="M801.11,1596.08c0,2.24,0,4.97,0,7.14C804.82,1598.84,804.41,1599.42,801.11,1596.08z"/>
							<path className="st42" d="M800.86,1603.89v-8.42l0.43,0.43c0.36,0.36,0.68,0.68,0.97,0.96c2.5,2.44,2.37,2.58-0.8,6.33
								L800.86,1603.89z M801.36,1596.68v5.86c1.31-1.55,2.04-2.44,2.12-3.08c0.08-0.6-0.39-1.09-1.57-2.24
								C801.74,1597.05,801.56,1596.87,801.36,1596.68z"/>
						</g>
						<g>
							<path className="st44" d="M808.27,1603.94c-1.29-1.29-2.29-2.29-3.36-3.37c-1.15,1.15-2.1,2.09-3.38,3.37
								C803.94,1603.94,805.89,1603.94,808.27,1603.94z"/>
							<path className="st42" d="M808.87,1604.19h-7.95l3.99-3.97L808.87,1604.19z M802.12,1603.69h5.55l-2.77-2.77L802.12,1603.69z"/>
						</g>
						<g>
							<path className="st44" d="M808.41,1596.03c-3.59,3.24-3.28,3.73,0,7C808.41,1601.05,808.41,1597.93,808.41,1596.03z"/>
							<path className="st42" d="M808.66,1603.63l-0.42-0.42c-1.76-1.75-2.68-2.72-2.65-3.8c0.02-1.01,0.92-1.99,2.66-3.57l0.42-0.37
								V1603.63z M808.16,1596.6c-1.34,1.24-2.06,2.08-2.08,2.83c-0.02,0.79,0.73,1.65,2.08,3.01V1596.6z"/>
						</g>
						<g>
							<path className="st44" d="M802.13,1595.78c0.91,1.14,1.88,1.94,2.65,2.9c0.79-0.99,1.79-1.83,2.65-2.9
								C805.75,1595.78,803.85,1595.78,802.13,1595.78z"/>
							<path className="st42" d="M804.78,1599.08l-0.19-0.24c-0.36-0.45-0.77-0.87-1.2-1.31c-0.48-0.49-0.97-0.99-1.45-1.6l-0.32-0.4
								h6.34l-0.32,0.4c-0.44,0.55-0.93,1.05-1.39,1.53c-0.45,0.46-0.88,0.9-1.26,1.38L804.78,1599.08z M802.66,1596.03
								c0.36,0.42,0.73,0.79,1.08,1.16c0.36,0.37,0.71,0.73,1.04,1.11c0.34-0.4,0.71-0.78,1.1-1.18c0.34-0.35,0.69-0.71,1.02-1.09
								H802.66z"/>
						</g>
						<g>
							<polygon className="st47" points="812.05,1529.7 797.91,1529.7 797.04,1527.83 812.92,1527.83 							"/>
							<path className="st42" d="M812.21,1529.94h-14.45l-1.1-2.36h16.66L812.21,1529.94z M798.07,1529.45h13.82l0.64-1.37h-15.1
								L798.07,1529.45z"/>
						</g>
						<g>
							<path className="st44" d="M804.83,1561.92c0.83,0.9,1.59,1.74,2.58,2.82c-1.82,0-3.31,0-5.17,0
								C803.23,1563.67,803.95,1562.88,804.83,1561.92z"/>
							<path className="st42" d="M807.97,1565h-6.3l3.16-3.44L807.97,1565z M802.81,1564.5h4.04l-2.01-2.2L802.81,1564.5z"/>
						</g>
						<g>
							<g>
								<g>
									<path className="st42" d="M805.17,1507.28c0-1.32,0-2.65,0-3.97c0-0.24-0.37-0.24-0.37,0c0,1.32,0,2.65,0,3.97
										C804.8,1507.52,805.17,1507.52,805.17,1507.28L805.17,1507.28z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="st42" d="M805.47,1503.32c0.49-0.57,1.28-0.68,1.99-0.65c0.91,0.03,1.81,0.26,2.72,0.04
										c0.23-0.06,0.13-0.42-0.1-0.36c-0.93,0.23-1.87-0.05-2.81-0.05c-0.75,0-1.56,0.18-2.07,0.77
										C805.05,1503.23,805.31,1503.5,805.47,1503.32L805.47,1503.32z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="st42" d="M810.46,1502.49c0-0.99,0-1.98,0-2.98c0-0.24-0.37-0.24-0.37,0c0,0.99,0,1.98,0,2.98
										C810.09,1502.73,810.46,1502.73,810.46,1502.49L810.46,1502.49z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="st42" d="M805,1502.98c0-1.16,0-2.32,0-3.47c0-0.24-0.37-0.24-0.37,0c0,1.16,0,2.32,0,3.47
										C804.63,1503.22,805,1503.22,805,1502.98L805,1502.98z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="st42" d="M809.89,1499.32c-1.56-0.96-3.68-0.89-5.16,0.2c-0.19,0.14-0.01,0.46,0.19,0.32
										c1.38-1.01,3.33-1.1,4.78-0.2C809.91,1499.77,810.09,1499.45,809.89,1499.32L809.89,1499.32z"/>
								</g>
							</g>
						</g>
					</g>
					<g>
						<path className="st48" d="M779.8,1615.31c0,0-2.34,0-5.22,0c-2.89,0-5.22,0-5.22,0c0-2.59,2.34-4.69,5.22-4.69
							C777.46,1610.62,779.8,1612.72,779.8,1615.31z"/>
						<path className="st42" d="M780.05,1615.56H769.1v-0.25c0-2.72,2.46-4.94,5.47-4.94c3.02,0,5.47,2.22,5.47,4.94V1615.56z
							 M769.61,1615.06h9.94c-0.14-2.34-2.32-4.2-4.97-4.2C771.92,1610.87,769.75,1612.73,769.61,1615.06z"/>
					</g>
					<g>
						<path className="st48" d="M840.13,1616.17c0,0-2.34,0-5.22,0c-2.89,0-5.22,0-5.22,0c0-2.59,2.34-4.69,5.22-4.69
							C837.79,1611.48,840.13,1613.58,840.13,1616.17z"/>
						<path className="st42" d="M840.38,1616.42h-10.95v-0.25c0-2.72,2.46-4.94,5.47-4.94s5.47,2.22,5.47,4.94V1616.42z M829.94,1615.92
							h9.94c-0.14-2.34-2.32-4.2-4.97-4.2C832.26,1611.73,830.08,1613.59,829.94,1615.92z"/>
					</g>
					<g>
						<g>
							<path className="st10" d="M805.55,1501.37c0.61-0.43,1.34-0.43,2.07-0.35c0.79,0.08,1.57,0.25,2.37,0.24
								c0.48-0.01,0.48-0.75,0-0.75c-1.57,0.02-3.39-0.77-4.82,0.22c-0.17,0.11-0.24,0.33-0.13,0.51
								C805.14,1501.4,805.39,1501.49,805.55,1501.37L805.55,1501.37z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st10" d="M807.12,1499.16c-0.01,0.97-0.02,1.95-0.04,2.92c-0.01,0.48,0.74,0.48,0.75,0
								c0.01-0.97,0.02-1.95,0.04-2.92C807.87,1498.68,807.13,1498.68,807.12,1499.16L807.12,1499.16z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st10" d="M807.16,1499.86c-0.47-0.15-0.93-0.32-1.39-0.52c-0.14-0.06-0.31,0.06-0.34,0.2
								c-0.04,0.16,0.05,0.28,0.2,0.34c0.46,0.19,0.92,0.36,1.39,0.52c0.14,0.05,0.31-0.05,0.34-0.2
								C807.39,1500.05,807.31,1499.91,807.16,1499.86L807.16,1499.86z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st10" d="M808.15,1500.44c0.11-0.06,0.23-0.12,0.35-0.18c0.02-0.01,0.03-0.01,0.05-0.02
								c-0.02,0.01-0.02,0.01,0.01,0c0.03-0.01,0.05-0.02,0.08-0.03c0.06-0.02,0.13-0.05,0.2-0.07c0.12-0.04,0.25-0.07,0.38-0.1
								c0.06-0.01,0.13-0.02,0.19-0.03c0.01,0,0.1-0.01,0.05-0.01c0.04,0,0.07-0.01,0.11-0.01c0.15-0.01,0.29-0.12,0.28-0.28
								c-0.01-0.14-0.12-0.29-0.28-0.28c-0.59,0.06-1.17,0.23-1.69,0.53c-0.13,0.07-0.18,0.26-0.1,0.38
								C807.85,1500.47,808.02,1500.52,808.15,1500.44L808.15,1500.44z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st10" d="M806.89,1500.8c-0.68,0.48-1.3,1.04-1.83,1.69c-0.09,0.11-0.12,0.29,0,0.4c0.1,0.09,0.3,0.12,0.4,0
								c0.25-0.31,0.53-0.6,0.82-0.87c0.29-0.27,0.58-0.51,0.9-0.73c0.12-0.08,0.19-0.25,0.1-0.38
								C807.2,1500.79,807.02,1500.71,806.89,1500.8L806.89,1500.8z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st10" d="M807.58,1501.14c0.64,0.51,1.33,0.95,2.08,1.31c0.14,0.07,0.3,0.04,0.38-0.1
								c0.07-0.12,0.04-0.32-0.1-0.38c-0.7-0.33-1.36-0.74-1.96-1.22c-0.12-0.09-0.28-0.12-0.4,0
								C807.48,1500.84,807.46,1501.05,807.58,1501.14L807.58,1501.14z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st49" d="M805.3,1501.18c1.48-0.4,3.03-0.47,4.54-0.2c0.23,0.04,0.34-0.32,0.1-0.36
								c-1.57-0.29-3.19-0.22-4.74,0.2C804.97,1500.89,805.07,1501.25,805.3,1501.18L805.3,1501.18z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st49" d="M807.29,1499.1c0.16,0.49,0.14,0.99,0.1,1.5c-0.02,0.25-0.06,0.5-0.09,0.75
								c-0.02,0.13-0.03,0.26-0.05,0.39c-0.01,0.06-0.01,0.12-0.02,0.18c0,0.04-0.01,0.08-0.01,0.12c-0.02,0.06-0.02,0.08,0.01,0.04
								c0.08-0.01,0.15-0.02,0.23-0.03c-0.01,0-0.01-0.01-0.02-0.01c0.03,0.05,0.06,0.11,0.09,0.16c0-0.01,0-0.01,0-0.02
								c-0.05,0.06-0.09,0.12-0.14,0.18c0,0,0.01,0,0.01,0c0.23-0.07,0.13-0.43-0.1-0.36c0,0-0.01,0-0.01,0
								c-0.08,0.02-0.14,0.1-0.14,0.18c0,0.09,0.04,0.14,0.11,0.19c0.07,0.05,0.16,0.02,0.23-0.03c0.11-0.09,0.1-0.24,0.11-0.37
								c0.02-0.14,0.03-0.28,0.05-0.41c0.03-0.29,0.07-0.58,0.1-0.87c0.06-0.56,0.07-1.14-0.1-1.68
								C807.58,1498.77,807.22,1498.87,807.29,1499.1L807.29,1499.1z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st49" d="M808.09,1500.22c0.48-0.15,0.96-0.29,1.43-0.44c0.11-0.04,0.07-0.22-0.05-0.18
								c-0.48,0.15-0.96,0.29-1.43,0.44C807.92,1500.08,807.97,1500.26,808.09,1500.22L808.09,1500.22z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st49" d="M806.96,1500.02c-0.39-0.19-0.77-0.37-1.16-0.56c-0.11-0.05-0.2,0.11-0.09,0.16
								c0.39,0.19,0.77,0.37,1.16,0.56C806.97,1500.24,807.06,1500.07,806.96,1500.02L806.96,1500.02z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st49" d="M806.4,1501.34c-0.44,0.34-0.85,0.74-1.2,1.17c-0.08,0.09,0.06,0.22,0.13,0.13
								c0.36-0.43,0.76-0.82,1.2-1.17c0.04-0.03,0.03-0.1,0-0.13C806.49,1501.3,806.44,1501.31,806.4,1501.34L806.4,1501.34z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st49" d="M808.24,1501.5c0.51,0.31,0.97,0.69,1.36,1.14c0.08,0.09,0.21-0.04,0.13-0.13
								c-0.4-0.46-0.87-0.86-1.4-1.17C808.23,1501.28,808.14,1501.44,808.24,1501.5L808.24,1501.5z"/>
						</g>
					</g>
					<g>
						<g>
							<g>
								<path className="st42" d="M805.17,1507.28c0-1.32,0-2.65,0-3.97c0-0.24-0.37-0.24-0.37,0c0,1.32,0,2.65,0,3.97
									C804.8,1507.52,805.17,1507.52,805.17,1507.28L805.17,1507.28z"/>
							</g>
						</g>
						<g>
							<g>
								<path className="st42" d="M805.47,1503.32c0.49-0.57,1.28-0.68,1.99-0.65c0.91,0.03,1.81,0.26,2.72,0.04
									c0.23-0.06,0.13-0.42-0.1-0.36c-0.93,0.23-1.87-0.05-2.81-0.05c-0.75,0-1.56,0.18-2.07,0.77
									C805.05,1503.23,805.31,1503.5,805.47,1503.32L805.47,1503.32z"/>
							</g>
						</g>
						<g>
							<g>
								<path className="st42" d="M810.46,1502.49c0-0.99,0-1.98,0-2.98c0-0.24-0.37-0.24-0.37,0c0,0.99,0,1.98,0,2.98
									C810.09,1502.73,810.46,1502.73,810.46,1502.49L810.46,1502.49z"/>
							</g>
						</g>
						<g>
							<g>
								<path className="st42" d="M805,1502.98c0-1.16,0-2.32,0-3.47c0-0.24-0.37-0.24-0.37,0c0,1.16,0,2.32,0,3.47
									C804.63,1503.22,805,1503.22,805,1502.98L805,1502.98z"/>
							</g>
						</g>
						<g>
							<g>
								<path className="st42" d="M809.89,1499.32c-1.56-0.96-3.68-0.89-5.16,0.2c-0.19,0.14-0.01,0.46,0.19,0.32
									c1.38-1.01,3.33-1.1,4.78-0.2C809.91,1499.77,810.09,1499.45,809.89,1499.32L809.89,1499.32z"/>
							</g>
						</g>
					</g>
				</g>
			</g>
			<path d="M1095.1,2074.49c-0.16-0.54-0.61-0.93-1.06-1.27c-2.83-2.1-6.18-2.74-9.71-2.92l0,0l-0.08,0
				c-0.43-0.02-0.85-0.03-1.29-0.04l-0.81-0.03l-0.87-21.38h-12.61l-0.59,2.49l-0.9-0.38v-1.89l-2.34,0.16l-1.1,0.78l0.36,4.96
				l-0.79-0.3v-1.88h-4.03v2.23h-0.87v-1.93h-2.37v-0.82h-1.19v-0.96h-0.82v-0.89h-1.63v-1.26h-1.11v-0.82h-1.34v-1.11h-1.34v-1.04
				h-0.96v-1.11h-1.41v-0.67h-1.19v-1.04h-1.11v-1.04h-1.04v-1.11h-1.34v-0.96h-1.26v-0.96h-1.11v-5.49l-5.9-0.45l-0.8,0.56v2.33
				h-0.51l-0.3-1.99l-1.86-0.08v3.53l-1.03-0.39l-0.37-2.02l-7.66-0.23l0.37,2.23h-1.6v-2.52l-7.66-0.15v2.01h-1.61v-2.07
				l-7.89-0.34v2.42h-1.93l-0.25-0.74l0.86-0.14v-1.72h-8.56l0.22,2.22h-1.46v-2.66h-3.19v-1.4l-5.66-0.37v-28.84l1.3-1.3v-1.42
				l0.22-4.49l-1.23-1.36v1.34l-0.3-0.32v-1.78l-0.96-1.04l-2.29-0.64l-0.28,1.94l-0.79-0.28v-2.11c0-0.88-2.95-1.24-4.7-1.37
				c-0.96-0.07-0.96-0.07-0.97,0.02c0,0.01-0.21,1.37-0.27,1.71l-1.79-0.05v-1.72l-2.13-0.19c0,0,0-0.01,0-0.01
				c0-1.37,0-2.74,0-4.11s0-2.74,0-4.11c0-0.77,0-1.54,0-2.31c0-0.01-0.01-0.03-0.01-0.04c0.03,0.03,0.07,0.03,0.11,0
				c0.06-0.07,0.14-0.13,0.21-0.19c0.31-0.11,0.7-0.26,1.13-0.35c0.51-0.02,1.01,0.07,1.52,0.1c0.33,0.02,0.67,0.01,1-0.07
				c0.03-0.01,0.04-0.02,0.05-0.04c0.05,0.03,0.13,0.01,0.13-0.07c0-0.02,0-0.04,0-0.05c0.14-0.15,0.05-0.51,0-0.85
				c0-0.5,0-1.01,0-1.51c0-0.11-0.17-0.11-0.17,0c0,0.08,0,0.17,0,0.25c-0.06-0.08-0.14-0.16-0.24-0.24
				c0.02-0.04,0.02-0.08-0.03-0.11c-0.97-0.6-2.21-0.7-3.27-0.3c-0.26,0.1-0.5,0.23-0.73,0.38c-0.01-0.1-0.16-0.1-0.16,0.01
				c0,0.17,0,0.34,0,0.51c-0.03,0.06-0.05,0.13-0.05,0.19l0.05,0.77c0,0.45,0,0.9,0,1.35c0,0.05,0.03,0.07,0.07,0.08
				c-0.06,0.05-0.1,0.11-0.1,0.19c0,1.37,0,2.74,0,4.11c0,1.37,0,2.74,0,4.11c0,0.76,0,1.52,0,2.28l-0.56-0.05l-3.32,0v2.61
				l-2.06-0.05v-2.58l-3.05,0.52l-1.96,0.81v2.45l-0.89,0.33v-1.94l-2.6,0.77l-0.63,1.76l-0.67,1.46v5.56l1.5,1.56l-0.44,37.06
				h-0.83l-4.54,0.98v1.77l-0.57,0.06v-1.87l-4.3,0.91l-0.15-10.08l-0.17,0v1.47h-0.57v-1.87l-1.95,0.32v0.87l-0.8-0.06v-1.41
				l-1.95-0.16v2.06l-0.55-0.17l0.38-1.14h-2.13v0.74h-0.87v-1.12l-1.8,0.08v1.19l-1.17,0.06v-4.66c0-0.25-0.78-0.48-1.25-0.59
				l-0.1-0.03v1.34l-0.65-0.06v-1.26h-1.8v2.77l-0.82,0.44v1.09h-0.88c-0.05-0.25-0.23-1.04-0.73-1.11
				c-0.53-0.08-1.83,0.28-1.89,0.3l-0.06,0.02v1.69h-1.34v0.89h-0.87v-1.1l-2.99-0.39v1.58l-0.72,0.06v-1.25h-0.8l-0.53,1.12v-0.81
				l-1.06,0.62v7.4l-2.52,0.67l0,0.06c-0.03,0.57-0.19,3.41,0.06,3.68c0.03,0.04,0.07,0.04,0.09,0.04c0.12,0,0.36,0.62,0.51,1.33
				l-0.36,4.4h-3.2v0.72l-1.09,0.19v-0.9l-5.36-0.65v2.53h-0.65v-2.52h-2.02v1.34l-1.54,0.13v-1.48h-1.47l-0.76,1.27v-1.27h-1.09
				l-0.93,1.32l-0.98,25.96c-0.86-0.33-1.71-0.69-2.61-0.88c-1.92-0.41-4.47,0.51-4.5,2.47c-0.02,0.88,0.5,1.68,1.09,2.33
				c0.28,0.31,0.58,0.61,0.89,0.88c3.55,3.09,8.89,3.44,13.71,3.52c63.31,1.09,126.55-3.81,189.67-8.71
				C1093.25,2077.09,1095.59,2076.15,1095.1,2074.49z M968.2,1979.21c0,0-0.01-0.01-0.01-0.01c0,0,0.01,0,0.01,0
				C968.21,1979.2,968.2,1979.21,968.2,1979.21z M971.23,1978.67l0.04,0C971.26,1978.67,971.24,1978.68,971.23,1978.67z
				 M967.94,1976.43c0.1-0.07,0.21-0.14,0.32-0.2c-0.12,0.09-0.23,0.19-0.32,0.28C967.94,1976.49,967.94,1976.46,967.94,1976.43z"/>
			<g>
				<g>
					<polygon className="st50" points="979.58,2025.52 985.24,2025.89 985.24,2027.3 988.44,2027.3 988.44,2029.97 990.07,2029.97 
						989.85,2027.74 998.23,2027.74 998.23,2029.31 997.34,2029.45 997.64,2030.35 999.72,2030.35 999.72,2027.93 1007.43,2028.27 
						1007.43,2029.68 1007.43,2030.35 1009.21,2030.35 1009.21,2028.34 1016.71,2028.49 1016.71,2031.01 1018.49,2031.01 
						1018.12,2028.79 1025.61,2029.01 1025.99,2031.01 1027.17,2031.46 1027.17,2027.9 1028.88,2027.97 1029.18,2029.97 
						1029.84,2029.97 1029.84,2027.6 1030.59,2027.08 1036.38,2027.53 1036.38,2033.02 1037.49,2033.02 1037.49,2033.98 
						1038.75,2033.98 1038.75,2034.95 1040.09,2034.95 1040.09,2036.06 1041.12,2036.06 1041.12,2037.1 1042.24,2037.1 
						1042.24,2038.14 1043.43,2038.14 1043.43,2038.81 1044.09,2038.81 1044.84,2038.81 1044.84,2039.92 1045.8,2039.92 
						1045.8,2040.96 1047.14,2040.96 1047.14,2042.07 1048.47,2042.07 1048.47,2042.89 1049.58,2042.89 1049.58,2044.15 
						1051.22,2044.15 1051.22,2045.04 1052.03,2045.04 1052.03,2046 1053.22,2046 1053.22,2046.82 1055.6,2046.82 1055.6,2048.75 
						1056.63,2048.75 1056.63,2046.52 1060.49,2046.52 1060.49,2048.38 1061.46,2048.75 1061.09,2043.7 1062.13,2042.96 
						1064.35,2042.81 1064.35,2044.67 1065.39,2045.11 1065.98,2042.59 1078.45,2042.59 1079.32,2063.89 1062.72,2063.89 
						1042.61,2056.02 979.45,2055.21 					"/>
					<path className="st51" d="M1079.41,2063.97h-16.7l-0.01-0.01l-20.1-7.86l-63.22-0.82l0.13-29.86l5.83,0.38v1.4h3.19v2.66h1.46
						l-0.22-2.22h8.56v1.72l-0.86,0.14l0.25,0.74h1.93v-2.42l7.89,0.34v2.07h1.61v-2.01l7.66,0.15v2.52h1.6l-0.37-2.23l7.66,0.23
						l0.37,2.02l1.03,0.39v-3.53l1.86,0.08l0.3,1.99h0.51v-2.33l0.8-0.56l5.9,0.45v5.49h1.11v0.96h1.26v0.96h1.34v1.11h1.04v1.04
						h1.11v1.04h1.19v0.67h1.41v1.11h0.96v1.04h1.34v1.11h1.34v0.82h1.11v1.26h1.63v0.89h0.82v0.96h1.19v0.82h2.37v1.93h0.87v-2.23
						h4.03v1.88l0.79,0.3l-0.36-4.96l1.1-0.78l2.34-0.16v1.89l0.9,0.38l0.59-2.49h12.61L1079.41,2063.97z M1062.74,2063.8h16.5
						l-0.86-21.13h-12.32l-0.6,2.56l-1.18-0.51v-1.82l-2.11,0.14l-0.98,0.7l0.38,5.13l-1.14-0.44v-1.83h-3.69v2.23h-1.21v-1.93
						h-2.37v-0.82h-1.19v-0.96h-0.82v-0.89h-1.63v-1.26h-1.11v-0.82h-1.34v-1.11h-1.34V2040h-0.96v-1.11h-1.41v-0.67h-1.19v-1.04
						h-1.11v-1.04H1040v-1.11h-1.34v-0.96h-1.26v-0.96h-1.11v-5.5l-5.68-0.44l-0.68,0.48v2.41h-0.82l-0.3-2l-1.55-0.07v3.6
						l-1.34-0.5l-0.37-1.98l-7.33-0.22l0.37,2.22h-1.96v-2.52l-7.33-0.15v2h-1.95v-2.08l-7.55-0.33v2.41h-2.22l-0.35-1.04l0.92-0.15
						v-1.41h-8.21l0.22,2.22h-1.81v-2.66h-3.19v-1.42l-5.5-0.36l-0.12,29.51l63.09,0.82l0.01,0.01L1062.74,2063.8z"/>
				</g>
				<g>
					<path className="st52" d="M890.55,2072.72l1.04-27.38l0.89-1.26h0.96v1.48l0.89-1.48h1.34v1.48l1.71-0.15v-1.34h1.86v2.52h0.82
						v-2.52l5.19,0.63v0.93l1.26-0.22v-0.7h3.19l0.37-4.49c0,0-0.3-1.41-0.59-1.41c-0.3,0-0.07-3.64-0.07-3.64l2.52-0.67v-7.42
						l0.89-0.52v1.04l0.67-1.41h0.67v1.26l0.89-0.07v-1.56l2.82,0.37v1.11h1.04v-0.89h1.34v-1.71c0,0,1.34-0.37,1.86-0.3
						s0.67,1.11,0.67,1.11h1.04v-1.12l0.82-0.44v-2.74h1.63v1.25l0.82,0.07v-1.33c0,0,1.19,0.29,1.19,0.51s0,3.34,0,3.34v1.41
						l1.34-0.07v-1.19l1.63-0.07v1.11h1.04v-0.74h1.93l-0.37,1.11l0.74,0.22v-2.08l1.78,0.15v1.41l0.96,0.07v-0.89l1.78-0.3v0.96
						v0.89h0.74v-1.56l0.22,14.63l-7.2,18.78l-35.77,16.53L890.55,2072.72z"/>
					<path className="st51" d="M897.08,2075.56l-6.62-2.78l0-0.06l1.04-27.41l0.93-1.32h1.09v1.27l0.76-1.27h1.47v1.48l1.54-0.13v-1.34
						h2.02v2.52h0.65v-2.53l5.36,0.65v0.9l1.09-0.19v-0.72h3.2l0.36-4.4c-0.15-0.71-0.39-1.33-0.51-1.33
						c-0.02,0-0.06-0.01-0.09-0.04c-0.26-0.28-0.1-3.12-0.06-3.68l0-0.06l2.52-0.67v-7.4l1.06-0.62v0.81l0.53-1.12h0.8v1.25
						l0.72-0.06v-1.58l2.99,0.39v1.1h0.87v-0.89h1.34v-1.69l0.06-0.02c0.06-0.02,1.36-0.37,1.89-0.3c0.5,0.07,0.68,0.86,0.73,1.11
						h0.88v-1.09l0.82-0.44v-2.77h1.8v1.26l0.65,0.06v-1.34l0.1,0.03c0.47,0.11,1.25,0.34,1.25,0.59v4.66l1.17-0.06v-1.19l1.8-0.08
						v1.12h0.87v-0.74h2.13l-0.38,1.14l0.55,0.17v-2.06l1.95,0.16v1.41l0.8,0.06v-0.87l1.95-0.32v1.87h0.57v-1.47l0.17,0l0.22,14.65
						l-7.22,18.83l-0.03,0.01L897.08,2075.56z M890.64,2072.66l6.44,2.71l35.7-16.5l7.18-18.74l-0.2-12.97H939v-1.84l-1.61,0.27
						v0.91l-1.13-0.09v-1.41l-1.61-0.13v2.1l-0.93-0.28l0.36-1.08h-1.73v0.74h-1.21v-1.11l-1.47,0.07v1.19l-1.5,0.08v-4.84
						c-0.03-0.08-0.49-0.27-1.02-0.4v1.31l-0.98-0.09v-1.24h-1.47v2.7l-0.82,0.44v1.16h-1.2l-0.01-0.07
						c-0.04-0.27-0.22-0.99-0.6-1.04c-0.43-0.06-1.49,0.21-1.76,0.28v1.73h-1.34v0.89h-1.21v-1.12l-2.65-0.35v1.54l-1.06,0.09v-1.27
						h-0.53l-0.8,1.7v-1.27l-0.72,0.42v7.44l-2.53,0.67c-0.09,1.59-0.11,3.29,0.02,3.49c0.32,0.04,0.56,1.04,0.65,1.47l0,0.01
						l0,0.01l-0.38,4.57h-3.18v0.69l-1.43,0.25v-0.95l-5.03-0.61v2.51h-0.98v-2.52h-1.69v1.33l-1.87,0.16v-1.49h-1.2l-1.02,1.7v-1.7
						h-0.84l-0.85,1.21L890.64,2072.66z"/>
				</g>
				<g>
					<path className="st50" d="M925.21,2055.95v-6.23l1.11-0.59v-11.43h0.74v1.93l0.67,0.07v-1.56l1.11-0.45v2.67v-3.71
						c0,0,1.26-0.37,1.19,0c-0.07,0.37,0,2.82,0,2.82l0.89-0.37v-1.63l3.93-0.59v1.71h0.67v-2.09l3.86-0.8v1.78l0.67-0.07v-1.71
						l4.23-0.89v1.86l0.74-0.07v-1.78l4.45-0.96h0.91v20.11L925.21,2055.95z"/>
					<path className="st51" d="M925.12,2056.04v-6.37l1.11-0.59v-11.46h0.91v1.94l0.5,0.06v-1.52l1.11-0.45v-1.04l0.06-0.02
						c0.24-0.07,1.03-0.28,1.24-0.09c0.05,0.05,0.07,0.11,0.06,0.19c-0.05,0.27-0.03,1.81,0,2.68l0.72-0.3v-1.65l4.1-0.62v1.72h0.5
						v-2.08l4.03-0.83v1.79l0.5-0.06v-1.7l4.4-0.93v1.87l0.57-0.06v-1.77l4.54-0.98h0.99v20.27L925.12,2056.04z M925.29,2049.76
						v6.09l25.01-1.99v-19.95h-0.82l-4.38,0.95v1.79l-0.91,0.09v-1.84l-4.06,0.86v1.71l-0.84,0.09v-1.77l-3.69,0.77v2.11h-0.84
						v-1.69l-3.77,0.57v1.62l-1.05,0.44l0-0.12c0-0.1-0.08-2.46,0-2.84c0-0.02,0-0.03,0-0.03c-0.09-0.09-0.59-0.01-1.02,0.11v3.65
						h-0.17v-2.55l-0.95,0.38v1.6l-0.84-0.09v-1.92h-0.57v11.39L925.29,2049.76z"/>
				</g>
				<g>
					<path className="st52" d="M979.58,2050.89v-54.32l1.3-1.3v-1.39l0.22-4.45l-1.06-1.17v1.34l-0.47-0.5v-1.78l-0.92-1l-2.17-0.61
						l-0.28,1.95l-0.95-0.33c0,0,0-2.16,0-2.17c0-1.09-5.51-1.36-5.51-1.34c0,0-0.28,1.78-0.28,1.78l-1.95-0.06v-1.73l-3.12-0.28
						h-3.23v2.62l-2.23-0.06v-2.56l-2.95,0.5l-1.89,0.78v2.45l-1.06,0.39v-1.95l-2.45,0.72l-0.61,1.73l-0.67,1.45v5.51l1.5,1.56
						l-0.67,55.77l-1.95,1.84v0.89l0.67,3.01l3.6,0.78l11.26-2.06l11.86-2.11l5.01-0.72L979.58,2050.89z"/>
					<path className="st51" d="M952.48,2059.02l-3.67-0.79l-0.68-3.08v-0.93l1.95-1.84l0.67-55.7l-1.5-1.56v-5.56l0.67-1.46l0.63-1.76
						l2.6-0.77v1.94l0.89-0.33v-2.45l1.96-0.81l3.05-0.52v2.58l2.06,0.05v-2.61l3.32,0l3.19,0.29v1.72l1.79,0.05
						c0.05-0.33,0.27-1.7,0.27-1.71c0.01-0.09,0.02-0.1,0.97-0.02c1.76,0.13,4.7,0.49,4.7,1.37v2.11l0.79,0.28l0.28-1.94l2.29,0.64
						l0.96,1.04v1.78l0.3,0.32v-1.34l1.23,1.36l-0.22,4.49l0,1.42l-1.3,1.3v54.27l1.05,3.23l-5.11,0.74l-11.85,2.11L952.48,2059.02z
						 M948.95,2058.08l3.53,0.76l11.25-2.06l11.86-2.11l4.91-0.71l-1-3.07v-54.37l1.3-1.3v-1.36l0.22-4.42l-0.89-0.98v1.33
						l-0.64-0.68v-1.78l-0.88-0.96l-2.06-0.58l-0.28,1.95l-1.1-0.39v-2.23c0-0.08-0.04-0.16-0.13-0.24c-0.8-0.75-4.67-1-5.23-1.01
						c-0.02,0.15-0.09,0.55-0.27,1.71l-0.01,0.07l-2.1-0.06v-1.73l-3.04-0.27h-3.14v2.62l-2.39-0.06v-2.54l-2.84,0.48l-1.83,0.75
						v2.45l-1.22,0.45v-1.96l-2.3,0.68l-0.6,1.69l-0.66,1.43v5.46l1.5,1.56l0,0.03l-0.67,55.8l-1.95,1.84v0.85L948.95,2058.08z"/>
				</g>
				<g>
					<path className="st10" d="M969.41,1972.34l-2.02-0.03c-1.24,0.14-2.29,0.9-2.29,0.4l-0.13-2.12c0-0.5,1.12-1.18,1.61-1.18h1.34
						c0.5,0,1.64,0.51,1.64,1l0.04,0.73C969.59,1971.64,969.91,1972.34,969.41,1972.34z"/>
				</g>
				<g>
					<g>
						<path className="st42" d="M965.48,1983.52c0-1.37,0-2.74,0-4.11s0-2.74,0-4.11c0-0.77,0-1.54,0-2.31c0-0.13-0.12-0.26-0.25-0.25
							c-0.14,0.01-0.25,0.11-0.25,0.25c0,1.37,0,2.74,0,4.11s0,2.74,0,4.11c0,0.77,0,1.54,0,2.31c0,0.13,0.12,0.26,0.25,0.25
							C965.37,1983.76,965.48,1983.66,965.48,1983.52L965.48,1983.52z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M965.58,1972.94c0.31-0.36,0.77-0.5,1.23-0.54c0.55-0.04,1.09,0.06,1.63,0.09
							c0.33,0.02,0.67,0.01,1-0.07c0.1-0.03,0.06-0.19-0.04-0.16c-0.6,0.15-1.22,0.04-1.83-0.02c-0.49-0.04-1-0.04-1.46,0.14
							c-0.25,0.1-0.47,0.24-0.64,0.44C965.39,1972.9,965.51,1973.02,965.58,1972.94L965.58,1972.94z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M969.61,1972.31c0-0.81,0-1.61,0-2.42c0-0.11-0.17-0.11-0.17,0c0,0.81,0,1.61,0,2.42
							C969.44,1972.42,969.61,1972.42,969.61,1972.31L969.61,1972.31z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M965.18,1972.72c0-0.94,0-1.88,0-2.82c0-0.11-0.17-0.11-0.17,0c0,0.94,0,1.88,0,2.82
							C965.01,1972.83,965.18,1972.83,965.18,1972.72L965.18,1972.72z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M969.18,1969.8c-0.97-0.6-2.21-0.7-3.27-0.3c-0.3,0.11-0.59,0.27-0.85,0.46c-0.09,0.06,0,0.21,0.08,0.14
							c0.88-0.65,2.06-0.84,3.11-0.54c0.3,0.09,0.58,0.21,0.84,0.37C969.19,1970,969.27,1969.86,969.18,1969.8L969.18,1969.8z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st49" d="M965.63,1971.29c0.39-0.27,0.85-0.34,1.32-0.33c0.51,0.01,1.01,0.1,1.51,0.17
							c0.28,0.03,0.57,0.06,0.85,0.06c0.22,0,0.22-0.34,0-0.33c-1.02,0.01-2.05-0.37-3.07-0.17c-0.28,0.05-0.54,0.16-0.77,0.32
							c-0.07,0.05-0.11,0.15-0.06,0.23C965.44,1971.3,965.55,1971.34,965.63,1971.29L965.63,1971.29z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st49" d="M967.1,1969.61c-0.01,0.48,0,0.96-0.01,1.45c-0.01,0.31-0.01,0.62-0.02,0.92
							c-0.01,0.22,0.33,0.22,0.33,0c0.02-0.65,0.03-1.31,0.03-1.96c0-0.14,0-0.27,0-0.41C967.44,1969.4,967.11,1969.4,967.1,1969.61
							L967.1,1969.61z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path className="st42" d="M965.58,1972.94c0.31-0.36,0.77-0.5,1.23-0.54c0.55-0.04,1.09,0.06,1.63,0.09
								c0.33,0.02,0.67,0.01,1-0.07c0.1-0.03,0.06-0.19-0.04-0.16c-0.6,0.15-1.22,0.04-1.83-0.02c-0.49-0.04-1-0.04-1.46,0.14
								c-0.25,0.1-0.47,0.24-0.64,0.44C965.39,1972.9,965.51,1973.02,965.58,1972.94L965.58,1972.94z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st42" d="M969.61,1972.31c0-0.81,0-1.61,0-2.42c0-0.11-0.17-0.11-0.17,0c0,0.81,0,1.61,0,2.42
								C969.44,1972.42,969.61,1972.42,969.61,1972.31L969.61,1972.31z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st42" d="M965.18,1972.72c0-0.94,0-1.88,0-2.82c0-0.11-0.17-0.11-0.17,0c0,0.94,0,1.88,0,2.82
								C965.01,1972.83,965.18,1972.83,965.18,1972.72L965.18,1972.72z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st42" d="M969.18,1969.8c-0.97-0.6-2.21-0.7-3.27-0.3c-0.3,0.11-0.59,0.27-0.85,0.46
								c-0.09,0.06,0,0.21,0.08,0.14c0.88-0.65,2.06-0.84,3.11-0.54c0.3,0.09,0.58,0.21,0.84,0.37
								C969.19,1970,969.27,1969.86,969.18,1969.8L969.18,1969.8z"/>
						</g>
					</g>
				</g>
				<g>
					<path className="st53" d="M885.39,2076.07c3.55,3.09,8.89,3.44,13.71,3.52c63.31,1.09,126.55-3.81,189.67-8.71
						c1.73-0.13,4.06-1.08,3.57-2.74c-0.16-0.54-0.61-0.93-1.06-1.27c-6.36-4.72-15.31-2.1-23.14-3.28
						c-6.79-1.02-12.76-4.97-19.34-6.96c-6.81-2.06-14.07-1.97-21.18-1.85c-10.78,0.17-21.57,0.35-32.35,0.52
						c-3.4,0.05-7,0.06-9.92-1.67c-2.25-1.33-4.24-3.7-6.84-3.45c-2.11,0.2-3.61,2.08-5.44,3.14c-4.83,2.79-10.74-0.41-16.26-1.17
						c-6.29-0.86-12.49,1.59-18.74,2.66c-3.67,0.63-7.42,0.78-11.13,0.45c-2.92-0.26-5.89-1.43-8.65-0.1
						c-6.45,3.11-12.65,9.68-18.39,14.03c-1.89,1.43-3.99,2.94-6.36,2.85c-1.96-0.08-3.7-1.24-5.62-1.65
						c-1.92-0.41-4.47,0.51-4.5,2.47c-0.02,0.88,0.5,1.68,1.09,2.33C884.78,2075.51,885.08,2075.8,885.39,2076.07z"/>
				</g>
				<g>
					<path className="st54" d="M1027.62,2054.78c0,0,1.83,2.3,4.2,2.6s2.08,1.19,5.64,1.19c3.56,0,7.12,0,7.12,0s1.8,0.43,3.81,0.87
						c2,0.45,3.56,1.45,6.01,2.56c2.45,1.11,4.23,1.78,5.34,2.11s3.9,0.68,5.57,0.84c1.67,0.16,3.23-0.16,5.34,0
						c2.11,0.16,4.01,0.05,4.79,0.61c0.78,0.56,1,1.22,2.67,2.45c1.67,1.22,1.11,1.45,2.45,1.89c1.34,0.45,5.01,0,5.01,0
						s0.45-2-0.89-3.12c-1.34-1.11-3.11-2.83-3.11-2.83l-11.91-0.4c0,0-7.57-0.12-8.35-1.12c-0.78-1-10.19-5.03-10.19-5.03
						l-11.06-2.39L1027.62,2054.78z"/>
				</g>
				<g>
					<path className="st54" d="M969.25,2056.43c-1.89-0.11-9.13,0.89-11.91,1.67c-2.78,0.78-3.56,1.56-1.22,1.89
						c2.34,0.33,5.68,0.67,7.24,1.22c1.56,0.56,6.9,0,3.34,2s-4.5,2.34-4.7,2.89s4.81,1.78,4.59,3.01
						c-0.22,1.22-1.11,2.67-3.56,3.01s-7.35,1.89-8.79,2.34s-4.56,2.89-5.12,3.01c-0.56,0.11-24.27-1.22-25.94-1
						s-9.68,0.11-9.68,0.11s0.56,0.11,6.68-3.01c6.12-3.12,19.59-6.79,20.59-10.46c1-3.67,1.89-3.56,6.01-5.01s8.79-2.23,8.79-2.23
						L969.25,2056.43z"/>
				</g>
				<g>
					<path className="st54" d="M928.84,2057.65c0,0-1.45-1.89-4.68,1.11c-3.23,3.01-4.23,2.89-5.68,4.23c-1.45,1.34-1.89,2.11-4.56,2.67
						c-2.67,0.56-5.01,0.9-5.34,1.01c-0.33,0.11-2.78,0.66-4.79,1.67c-2,1-4.79,3.56-5.57,4.23c-0.78,0.67-3.12,0.89-0.78,0.67
						c2.34-0.22,4.9-0.56,5.9-1s1.89-2,1-0.45c-0.89,1.56-1.95,1.78-2.09,3.01s1.64,0.45,1.64,0.45s3.9-1.89,4.79-3.01
						c0.89-1.11-0.01-1.16,3.95-2.14c3.96-0.98,0.39,0.36,4.85-1.2c4.45-1.56,1.78,0.79,6.12-2.22c4.34-3.01,3.78-2.56,5.23-3.57
						c1.45-1,1.78-0.89,3.9-2.23c2.11-1.34,5.64-2.04,4.56-2.34C934.52,2057.77,928.84,2057.65,928.84,2057.65z"/>
				</g>
				<g>
					<path className="st55" d="M980.83,2054.78c-1.78,0.76-3.34,0.47-4.01,2.29c-0.67,1.81-1.56,2.59-1.78,3.48
						c-0.22,0.89-1,2-0.78,3.01c0.22,1-1.05,5.23-1.42,5.79c-0.36,0.56-0.7,1.56-0.81,2.11c-0.11,0.56-2.16,2.67,0.81,0.89
						s1.19-1,2.97-1.78c1.78-0.78,1.34-2,3.01-1.67c1.67,0.33,1.78-0.27,2,1.2c0.22,1.47-0.89,1.25,0.22,1.47
						c1.11,0.22-0.45,2.11,1.34-0.78c1.78-2.89-0.78-0.67,2.34-3.45c3.12-2.78,3.23-2.56,4.79-4.12c1.56-1.56-3.34-1.56,3.34-2.11
						c6.68-0.56,5.34-0.67,7.35-0.45s3.12,0.11,3.78,1.22c0.67,1.11-0.78,4.34,0.67,1.11c1.45-3.23-3.01-2.23,1.78-3.9
						c4.79-1.67,4.23-2.07,6.68-2.04c2.45,0.03,2.78-1.37,5.57,0c2.78,1.37,3.23,1.81,4.12,2.48c0.89,0.67,0.86,0.67,4.83,1.78
						c3.97,1.11,4.86,2.11,5.63,2.11s3.78,1.78,2.89,0c-0.89-1.78-0.13-0.83-2.23-3.45c-2.1-2.62-3.08-2.85-3.08-2.85
						s-2.26-1.27-4.15-1.27c-1.89,0-22.15-1.45-24.16-0.45c-2,1-8.35,2.61-10.24,1.64S980.83,2054.78,980.83,2054.78z"/>
				</g>
				<g>
					<rect x="967.25" y="1995.65" className="st56" width="2.83" height="4.34"/>
				</g>
				<g>
					<polygon className="st56" points="953.45,2001.44 955.34,2000.77 955.34,1996.54 953.45,1997.1 					"/>
				</g>
				<g>
					<rect x="959.9" y="2007.23" className="st56" width="1.45" height="7.35"/>
				</g>
				<g>
					<rect x="959.9" y="2021.37" className="st56" width="1.45" height="7.68"/>
				</g>
				<g>
					<rect x="959.9" y="2035.06" className="st56" width="1.45" height="8.35"/>
				</g>
				<g>
					<polygon className="st56" points="978.05,2002.33 978.9,2002.85 978.9,1999.14 978.31,1999.36 					"/>
				</g>
				<g>
					<path className="st56" d="M967.25,2010.35c0.22,0.07,0.74,0,0.74,0v3.49h-0.74V2010.35z"/>
				</g>
				<g>
					<polygon className="st56" points="975.86,2012.09 976.45,2012.28 976.45,2014.95 975.86,2014.72 					"/>
				</g>
				<g>
					<path className="st56" d="M965.32,2028.04h0.82v-2.84c0,0,0.15-0.39-0.22-0.46c-0.37-0.07-0.41,0.19-0.41,0.46
						C965.5,2025.48,965.32,2028.04,965.32,2028.04z"/>
				</g>
				<g>
					<path className="st56" d="M976.15,2028.86l0.63,0.19v-2.66c0,0-0.07-0.68-0.33-0.53C976.19,2026,976.15,2028.86,976.15,2028.86z"/>
				</g>
				<g>
					<path className="st56" d="M954.39,2014.58h0.5v-3.01c0,0-0.06-0.56-0.25-0.33S954.39,2014.58,954.39,2014.58z"/>
				</g>
				<g>
					<path className="st56" d="M955.34,2028.64h0.45v-3.23c0,0-0.33-0.33-0.45-0.2C955.23,2025.34,955.34,2028.64,955.34,2028.64z"/>
				</g>
				<g>
					<path className="st56" d="M955.29,2042.55h0.49v-3.32c0,0-0.01-0.69-0.25-0.54c-0.23,0.15-0.25,1.56-0.25,1.56V2042.55z"/>
				</g>
				<g>
					<path className="st56" d="M976.15,2043.41h0.32v-2.79c0,0,0.04-0.84-0.16-0.66C976.12,2040.14,976.15,2043.41,976.15,2043.41z"/>
				</g>
				<g>
					<path className="st56" d="M965.32,2042.14h0.67v-2.91c0,0,0.07-0.47-0.25-0.56c-0.31-0.09-0.42,0.56-0.42,0.56V2042.14z"/>
				</g>
				<g>
					<path className="st56" d="M928.21,2030.9h1.48h2.93c0,0,0.19-0.46-0.41-0.94s-1.09-0.62-1.79-0.66c-0.7-0.04-1.03,0-1.55,0.37
						C928.36,2030.05,928.21,2030.9,928.21,2030.9z"/>
				</g>
				<g>
					<polygon className="st56" points="929.62,2032.59 929.96,2032.59 929.96,2033.13 930.85,2033.13 930.85,2031.16 929.62,2031.31 
											"/>
				</g>
				<g>
					<path className="st56" d="M980.71,1994.13l-2.3-2.43l-5.59-2.43l-8.8-0.59l-8.46,1.04l-4.82,2.2c-0.13,0.34-0.74,1.99-0.89,2.21
						l-0.19-0.12c0.1-0.16,0.58-1.4,0.88-2.2l0.02-0.04l4.93-2.25l8.51-1.04l8.85,0.6l5.67,2.47l2.33,2.47L980.71,1994.13z"/>
				</g>
				<g>
					<rect x="964.21" y="1993.69" className="st56" width="0.22" height="57.29"/>
				</g>
				<g>
					<rect x="971.85" y="1994.06" className="st56" width="0.22" height="56.92"/>
				</g>
				<g>
					
						<rect x="977.71" y="1996.54" transform="matrix(1 -8.281766e-03 8.281766e-03 1 -16.724 8.1675)" className="st56" width="0.22" height="53.77"/>
				</g>
				<g>
					
						<rect x="928.84" y="2023" transform="matrix(0.012 -0.9999 0.9999 0.012 -1077.6986 2955.5515)" className="st56" width="55.74" height="0.22"/>
				</g>
				<g>
					<rect x="964.21" y="1984.41" className="st56" width="0.22" height="3.27"/>
				</g>
				<g>
					<rect x="972.73" y="1984.85" className="st56" width="0.22" height="3.27"/>
				</g>
				<g>
					<rect x="978.79" y="1987.38" className="st56" width="0.22" height="3.49"/>
				</g>
				<g>
					<rect x="955.67" y="1984.85" className="st56" width="0.22" height="3.71"/>
				</g>
				<g>
					<rect x="950.4" y="1988.12" className="st56" width="0.22" height="2.75"/>
				</g>
				<g>
					<rect x="934.3" y="2027.75" className="st56" width="0.22" height="8.09"/>
				</g>
				<g>
					
						<rect x="922.89" y="2031.67" transform="matrix(0.0381 -0.9993 0.9993 0.0381 -1137.8726 2881.4929)" className="st56" width="9.75" height="0.22"/>
				</g>
				<g>
					<polygon className="st56" points="1035.08,2037.32 1034.85,2037.32 1034.85,2035.8 1035.99,2034.46 1030.4,2034.46 1030.4,2028.64 
						1030.62,2028.64 1030.62,2034.24 1036.47,2034.24 1035.08,2035.88 					"/>
				</g>
				<g>
					<polygon className="st56" points="1030.1,2043.71 1029.99,2043.7 980.79,2042.66 980.79,2042.44 1029.88,2043.48 1029.88,2036.65 
						1030.1,2036.65 					"/>
				</g>
				<g>
					
						<rect x="1005.32" y="2018.03" transform="matrix(0.0276 -0.9996 0.9996 0.0276 -1060.7847 2988.0593)" className="st56" width="0.22" height="42.46"/>
				</g>
				<g>
					
						<rect x="1006.65" y="2029.08" transform="matrix(0.0101 -0.9999 0.9999 0.0101 -1054.4637 3037.2642)" className="st56" width="0.22" height="44.23"/>
				</g>
				<g>
					
						<rect x="926.71" y="2047.93" transform="matrix(0.9953 -0.0964 0.0964 0.9953 -193.129 99.9862)" className="st56" width="22.29" height="0.22"/>
				</g>
				<g>
					
						<rect x="930.81" y="2050.98" transform="matrix(0.9963 -0.0857 0.0857 0.9963 -172.3044 88.0866)" className="st56" width="18.17" height="0.22"/>
				</g>
				<g>
					
						<rect x="908.38" y="2040.68" transform="matrix(0.0222 -0.9998 0.9998 0.0222 -1140.6038 2915.3486)" className="st56" width="23.49" height="0.22"/>
				</g>
				<g>
					<rect x="938.6" y="2026.91" className="st56" width="0.22" height="7.89"/>
				</g>
				<g>
					
						<rect x="1061.51" y="2050.24" transform="matrix(0.9998 -0.0191 0.0191 0.9998 -39.0976 20.6735)" className="st56" width="0.22" height="9.61"/>
				</g>
				<g>
					<rect x="1053.78" y="2050.31" className="st56" width="0.22" height="7.05"/>
				</g>
				<g>
					<rect x="1072.57" y="2049.68" className="st56" width="1.45" height="7.68"/>
				</g>
				<g>
					<rect x="892.61" y="2049.77" className="st56" width="1.45" height="2.76"/>
				</g>
				<g>
					<rect x="892.61" y="2054.49" className="st56" width="1.45" height="2.76"/>
				</g>
				<g>
					<rect x="892.61" y="2061.84" className="st56" width="1.45" height="2.76"/>
				</g>
				<g>
					<rect x="895.36" y="2061.84" className="st56" width="1.45" height="2.76"/>
				</g>
				<g>
					<path className="st56" d="M910.03,2038.17l-0.08-0.21c0.1-0.04,2.48-0.9,3.15-0.9c0.21,0,0.47-0.01,0.75-0.01
						c0.63-0.02,1.34-0.04,1.71,0.02c0.52,0.07,2.97,0.43,2.97,0.43l-0.03,0.22c0,0-2.45-0.35-2.97-0.43
						c-0.35-0.05-1.05-0.03-1.67-0.01c-0.28,0.01-0.55,0.01-0.76,0.01C912.47,2037.28,910.06,2038.16,910.03,2038.17z"/>
				</g>
				<g>
					<rect x="912.63" y="2029.97" className="st56" width="0.22" height="5.87"/>
				</g>
				<g>
					<polygon className="st51" points="914.37,2047.08 912.63,2047.08 912.63,2044.85 910,2044.85 910,2044.63 912.85,2044.63 
						912.85,2046.86 914.15,2046.86 914.15,2044.63 915.97,2044.63 915.97,2043.29 918.87,2043.29 919.38,2027.74 919.61,2027.75 
						919.08,2043.52 916.19,2043.52 916.19,2044.85 914.37,2044.85 					"/>
				</g>
				<g>
					<path className="st51" d="M925.25,2023.53l-0.08-0.21c0.04-0.01,0.92-0.36,1.61-0.32c0.7,0.04,1.05,0.33,1.07,0.34l-0.14,0.17
						c0,0-0.32-0.26-0.94-0.29C926.13,2023.19,925.26,2023.53,925.25,2023.53z"/>
				</g>
				<g>
					<path className="st51" d="M911.8,2040.66l-0.2-0.09l0.1,0.04l-0.1-0.04c0,0,0.2-0.49-0.01-1.31c-0.21-0.83-0.49-1.11-0.49-1.12
						l0.16-0.16c0.01,0.01,0.32,0.33,0.55,1.22C912.04,2040.1,911.81,2040.64,911.8,2040.66z"/>
				</g>
				<g>
					<path className="st51" d="M913.15,2040.35l-0.2-0.11c0,0,0.25-0.47,0.12-1.3c-0.13-0.84-0.38-1.16-0.38-1.16l0.17-0.14
						c0.01,0.01,0.29,0.36,0.43,1.27C913.44,2039.82,913.16,2040.33,913.15,2040.35z"/>
				</g>
				<g>
					<path className="st51" d="M917.69,2040.73c-0.01-0.02-0.24-0.55-0.01-1.45c0.23-0.89,0.54-1.21,0.55-1.22l0.16,0.16
						c0,0-0.28,0.29-0.49,1.12c-0.21,0.82-0.01,1.3-0.01,1.31L917.69,2040.73z"/>
				</g>
				<g>
					<path className="st51" d="M916.34,2040.42c-0.01-0.02-0.29-0.53-0.14-1.45c0.14-0.91,0.42-1.25,0.43-1.27l0.17,0.14
						c0,0-0.25,0.32-0.38,1.16c-0.13,0.83,0.11,1.3,0.12,1.3L916.34,2040.42z"/>
				</g>
				<g>
					<polygon className="st51" points="899.27,2070.28 899.05,2070.27 899.5,2056.9 901.95,2056.9 901.57,2067.76 901.35,2067.75 
						901.72,2057.12 899.71,2057.12 					"/>
				</g>
			</g>
			<g>
				<path className="st2" d="M818.82,2295.94c-6.71,2.4-15.04,5.76-15.2,13.09c-0.31,15.39,22.1,11.54,30.76,12.71
					c18.23,2.46,35.75,4.3,54.63,5.51c19.22,5.79,38.52,5.42,58.18,2.23c8.26-1.34,14.66-5.39,22.49-7.96
					c5.25-1.72,10.54-1.2,15.93-1.97c5.23-0.75,10.33-2.51,15.19-4.53c1.54-0.64,3.05-1.34,4.6-1.97c1.21-0.49,2.43-0.97,3.57-1.57
					c3.14-1.67,5.64-4.18,5.68-7.56c0.02-2.07-0.97-4.13-2.66-5.59c-1.15-0.99-2.29-1.44-3.69-1.97c-4.31-1.62-8.75-2.95-13.08-4.53
					c0.67,0.25,1.35,0.49,2.02,0.74c-0.67-0.25-1.34-0.5-1.96-0.82c-1.87-0.97-4.43-1.24-6.53-0.74c-1.96,0.47-4.09,1.79-5.06,3.37
					c-2.16,3.55-1.02,7.94,3.04,10.05c2.1,1.09,4.51,1.77,6.79,2.53c2.47,0.82,4.95,1.62,7.39,2.51c-0.67-0.25-1.35-0.49-2.02-0.74
					c0.9,0.33,1.91,0.62,2.75,1.07c-0.57-0.38-1.14-0.77-1.71-1.15c0.06,0.03,0.11,0.07,0.16,0.12c-0.44-0.5-0.88-0.99-1.32-1.49
					c0.04,0.04,0.07,0.08,0.09,0.13c-0.28-0.58-0.57-1.17-0.85-1.75c0.01,0.02,0.01,0.05,0.02,0.07c-0.1-0.65-0.2-1.3-0.3-1.95
					c0.01,0.05,0.01,0.09,0,0.14c0.1-0.65,0.2-1.3,0.3-1.95c-0.01,0.05-0.03,0.1-0.06,0.15c0.28-0.58,0.57-1.17,0.85-1.75
					c-0.03,0.05-0.07,0.11-0.11,0.15c0.44-0.5,0.88-0.99,1.32-1.49c-0.17,0.19-0.37,0.34-0.59,0.49c0.57-0.38,1.14-0.77,1.71-1.15
					c-1.46,0.94-3.19,1.54-4.82,2.21c-2.5,1.03-4.94,2.15-7.49,3.09c0.67-0.25,1.35-0.49,2.02-0.74c-3.21,1.16-6.52,2.09-9.97,2.51
					c0.75-0.09,1.5-0.17,2.25-0.26c-1.66,0.18-3.32,0.24-4.99,0.14c-1.45-0.09-2.9-0.28-4.36-0.2c-2.67,0.14-5.42,0.63-7.63,2.01
					c-1.14,0.71-2.06,1.36-2.95,2.25c-0.52,0.52-1.03,1.02-1.65,1.45c0.57-0.38,1.14-0.77,1.71-1.15c-0.7,0.45-1.45,0.81-2.26,1.1
					c0.67-0.25,1.35-0.49,2.02-0.74c-2.69,0.93-5.64,1.28-8.47,1.79c-3.27,0.59-6.54,1.18-9.81,1.77
					c-6.35,1.14-12.69,2.34-19.12,3.09c0.75-0.09,1.5-0.17,2.25-0.26c-6.27,0.72-12.63,1-18.92,0.29c0.75,0.09,1.5,0.18,2.25,0.26
					c-3.15-0.38-6.21-1.05-9.24-1.86c-2.92-0.79-5.83-1.62-8.85-2.07c-3.66-0.55-7.34-0.81-11.04-0.94
					c-3.18-0.11-6.37-0.19-9.54-0.55c0.75,0.09,1.5,0.17,2.25,0.26c-3.48-0.41-6.9-1.11-10.32-1.77c-3.56-0.68-7.12-1.37-10.68-2.05
					c-7.15-1.38-14.29-2.9-21.47-4.12c-2.51-0.43-5.16-0.82-7.72-0.58c-1.57,0.15-3.08,0.34-4.58,0.72
					c-0.9,0.23-1.79,0.47-2.72,0.59c0.75-0.09,1.5-0.17,2.25-0.26c-0.92,0.1-1.84,0.08-2.75-0.02c0.75,0.09,1.5,0.18,2.25,0.26
					c-0.85-0.1-1.69-0.28-2.48-0.57c0.67,0.25,1.35,0.49,2.02,0.74c-0.43-0.16-0.84-0.36-1.21-0.6c0.57,0.38,1.14,0.77,1.71,1.15
					c-0.22-0.16-0.43-0.32-0.6-0.52c0.44,0.5,0.88,0.99,1.32,1.49c-0.1-0.12-0.19-0.25-0.27-0.39c0.28,0.58,0.57,1.17,0.85,1.75
					c-0.07-0.14-0.11-0.28-0.14-0.43c0.1,0.65,0.2,1.3,0.3,1.95c-0.02-0.15-0.03-0.3-0.01-0.46c-0.1,0.65-0.2,1.3-0.3,1.95
					c0.03-0.2,0.09-0.39,0.18-0.58c-0.28,0.58-0.57,1.17-0.85,1.75c0.13-0.26,0.29-0.49,0.48-0.71c-0.44,0.5-0.88,0.99-1.32,1.49
					c0.43-0.48,0.95-0.89,1.5-1.27c-0.57,0.38-1.14,0.77-1.71,1.15c1.85-1.23,4-2.11,6.14-2.9c-0.67,0.25-1.35,0.49-2.02,0.74
					c0.22-0.08,0.45-0.16,0.67-0.24c1.1-0.23,2.06-0.67,2.88-1.31c0.92-0.52,1.65-1.2,2.18-2.06c1.06-1.57,1.51-3.89,0.85-5.66
					c-0.66-1.78-1.93-3.49-3.89-4.39C823.47,2295.82,820.87,2295.2,818.82,2295.94z"/>
			</g>
			<g>
				<g>
					<polygon points="937.36,2304.88 988.27,2305.9 984.55,2246.43 937.36,2281.1 					"/>
				</g>
				<g>
					<polygon points="878.23,2304.88 817.11,2306.36 820.45,2245.21 878.23,2281.1 					"/>
				</g>
				<g>
					<rect x="875.69" y="2281.1" width="61.67" height="23.78"/>
				</g>
				<g>
					<g>
						<defs>
							<path id="SVGID_1_" d="M819.99,2307.85l58.79-1.47c0.17-0.01,0.35-0.02,0.53-0.02h56.4c0.18,0,0.36,0.01,0.55,0.02l64.7,4.94
								c7.27,1.58,2.25,2.39,0,4.18c-1.85,1.47-3.31,3.21-5.67,3.02l-5.42-1.55c-5.82-0.48-11.68-0.15-17.39,1.06
								c-13.05,2.77-27.6,1.31-40.84,1.49c-11.86,0.16-23.73,0.19-35.59,0.1c-5.77-0.05-11.85,0.39-17.59-0.26
								c-5.2-0.59-10.31-2.2-15.58-2.48c-8.51-0.44-16.96,1.44-25.46,0.95c-5.17-0.3-10.35-0.98-15.51-1.52
								C815.79,2314.38,808.46,2309.3,819.99,2307.85z"/>
						</defs>
					</g>
				</g>
				<g>
					<polygon points="828.94,2245.56 877.43,2281.1 936.21,2281.1 981.89,2245.6 976.54,2245.56 937.13,2278.27 878.58,2278.27 
						837.11,2245.48 					"/>
					<path d="M936.24,2281.19H877.4l-0.02-0.02l-48.7-35.7l8.46-0.08l0.02,0.02l41.45,32.77h58.49l39.41-32.71l5.63,0.05
						L936.24,2281.19z M877.46,2281.02h58.72l45.45-35.33l-5.07-0.04l-39.41,32.71h-58.61l-0.02-0.02l-41.45-32.77l-7.88,0.07
						L877.46,2281.02z"/>
				</g>
				<g>
					<polygon points="830.62,2309.92 829.68,2303.6 813.18,2304.52 813.18,2312.1 					"/>
				</g>
				<g>
					<polygon points="1000.96,2311.32 982.11,2309.88 982.11,2304.52 995.22,2304.52 1000.96,2304.52 					"/>
				</g>
				<g>
					<polygon points="953.96,2306.63 935.11,2305.19 935.11,2301.75 948.22,2299.83 953.96,2299.83 					"/>
				</g>
				<g>
					<polygon points="877.07,2306.63 858.22,2305.19 858.22,2297.81 871.33,2300.79 877.07,2299.83 					"/>
				</g>
				<g>
					<polygon points="848.27,2308.62 848.27,2303.6 845.75,2302.23 845.75,2299.24 845.75,2291.21 841.39,2289.83 831.51,2289.83 
						817.74,2289.83 817.74,2293.04 814.29,2292.81 814.29,2300.75 813.15,2303.6 813.15,2306.36 830.09,2306.36 830.62,2309.92 
						839.55,2309.11 					"/>
					<path d="M830.51,2310.06l-0.53-3.57h-16.96v-2.91l1.15-2.85v-8.06l3.44,0.23v-3.21h23.8l4.47,1.41v11.04l2.52,1.38v5.22
						l-8.85,0.5L830.51,2310.06z M813.28,2306.23h16.92l0.53,3.55l8.81-0.79l8.6-0.49v-4.82l-2.52-1.38v-11l-4.25-1.34h-23.5v3.22
						l-3.44-0.23v7.83l-1.15,2.85V2306.23z"/>
				</g>
				<g>
					<polygon points="972.54,2245.56 938.05,2274.9 935.06,2277.54 879,2276.68 843.81,2245.64 848.66,2245.56 878.83,2272.67 
						880.57,2273.54 932.79,2273.72 935.58,2273.37 967.9,2245.56 					"/>
					<path d="M935.11,2277.68l-0.05,0l-56.11-0.87l-35.48-31.29l5.24-0.09l0.04,0.03l30.15,27.1l1.7,0.85l52.19,0.17l2.74-0.34
						l32.33-27.82h5.04L938.13,2275L935.11,2277.68z M879.05,2276.55l55.96,0.86l2.95-2.61l34.22-29.11h-4.23l-32.31,27.8
						l-2.84,0.35l-52.26-0.17l-1.78-0.89l-0.01-0.01l-30.13-27.08l-4.46,0.07L879.05,2276.55z"/>
				</g>
				<g>
					<path d="M900.15,2304.48c0,0,12.1,0,12.1,0c0.18,0,0.13-7.81,0.02-8.43c-0.31-1.75-1.27-3.47-2.86-4.26
						c-0.82-0.4-1.74-0.54-2.65-0.6c-0.86-0.06-1.75-0.06-2.58,0.18c-2.17,0.63-3.56,2.82-4.01,5.04
						C900.03,2297.09,900.15,2304.48,900.15,2304.48z"/>
				</g>
				<g>
					<path d="M882.61,2304.48c0,0,10.76,0,10.76,0c0.16,0,0.11-7.51,0.02-8.11c-0.28-1.68-1.13-3.34-2.54-4.1
						c-0.73-0.39-1.55-0.52-2.35-0.58c-0.77-0.06-1.55-0.06-2.3,0.17c-1.93,0.61-3.17,2.72-3.57,4.85
						C882.51,2297.37,882.61,2304.48,882.61,2304.48z"/>
				</g>
				<g>
					<path d="M918.82,2304.48c0,0,10.76,0,10.76,0c0.16,0,0.11-7.51,0.02-8.11c-0.28-1.68-1.13-3.34-2.54-4.1
						c-0.73-0.39-1.55-0.52-2.35-0.58c-0.77-0.06-1.55-0.06-2.3,0.17c-1.93,0.61-3.17,2.72-3.57,4.85
						C918.72,2297.37,918.82,2304.48,918.82,2304.48z"/>
				</g>
				<g>
					<polygon points="815.79,2245.28 815.79,2245.28 829.03,2245.56 877.43,2281.1 873.49,2281.1 					"/>
				</g>
				<g>
					<path d="M817.74,2289.83h5.05h8.72v-39.26l1.73-2.17c0,0,2.35-0.3-1.27-1.96c-3.63-1.67-16.18-1.15-16.18-1.15l1.94,2.76
						l0.46,36.28L817.74,2289.83z"/>
					<path d="M831.64,2289.96h-14.05l0.47-5.65l-0.46-36.23l-2.06-2.92l0.24-0.01c0.51-0.02,12.62-0.5,16.24,1.16
						c1.5,0.69,2.17,1.22,2.1,1.67c-0.06,0.38-0.62,0.51-0.81,0.54l-1.67,2.09V2289.96z M817.88,2289.7h13.5v-39.18l1.8-2.25
						l0.05-0.01c0.19-0.02,0.61-0.14,0.63-0.32c0.02-0.11-0.07-0.53-1.95-1.39c-1.47-0.67-4.72-1.08-9.4-1.19
						c-2.98-0.07-5.68,0.01-6.48,0.04l1.83,2.59l0,0.04l0.46,36.28l0,0.01L817.88,2289.7z"/>
				</g>
				<g>
					<path d="M848.27,2265.36c0,0,0.15,0.58,0.5,1.28c0.35,0.7,0.58,3.02,0.58,3.02v14.04v9.29c0,0-0.53,0.92-1.08,1.15
						c-0.55,0.23-1.01,0-1.01,0v8.91l1.01,0.55v5.02l18.97-1.39v-3.62c0,0-1.41-0.17-1.41-0.52s0-3.71,0-3.71v-6.38h-2.79h-5.8
						v-23.45v-2.02l1.27-0.42l-2.35-1.74H848.27z"/>
					<path d="M848.14,2308.76v-5.08l-1.01-0.55v-9.2l0.19,0.09c0.02,0.01,0.42,0.2,0.9,0c0.45-0.19,0.91-0.91,1-1.07v-23.29
						c-0.01-0.13-0.24-2.32-0.57-2.97c-0.35-0.7-0.5-1.28-0.51-1.3l-0.04-0.16h8.11l2.59,1.92l-1.42,0.46v25.25h8.58v10.22
						c0.02,0.11,0.58,0.3,1.29,0.39l0.11,0.01v3.86l-0.12,0.01L848.14,2308.76z M847.39,2302.97l1.01,0.55v4.95l18.7-1.38v-3.39
						c-0.5-0.07-1.41-0.25-1.41-0.64v-9.96h-8.58v-25.7l1.12-0.37l-2.11-1.56h-7.68c0.07,0.22,0.21,0.63,0.44,1.09
						c0.35,0.71,0.58,2.97,0.59,3.06l0,0.01v23.37l-0.02,0.03c-0.02,0.04-0.56,0.96-1.14,1.21c-0.38,0.16-0.73,0.12-0.93,0.06
						V2302.97z"/>
				</g>
				<g>
					<polygon points="991.09,2245.21 982.11,2245.21 936.21,2281.1 939.18,2281.1 					"/>
				</g>
				<g>
					<path d="M956.65,2307.85v-3.33h9.18l1.38-3.77v-5.88h4.13c0,0,5.85,0,5.97,0c-0.05,0,2.61-5.07,2.77-5.51
						c0.5-1.36,0.45-2.54,0.45-4.02c0-4.39,0-8.78,0-13.17c0-1.71,1.12-20.97,0-21.37c0,0-3.61-1.29-3.61-1.29l5.19-4.3h8.98
						l-3.21,3.29c0,0-0.92,1.61-0.69,2.98c0.23,1.38,0.69,36.28,0.69,36.28l2.07,2.07l1.15,3.21h3.44h2.12v6.83l4.31,0.88v3.77
						h-18.85v5.36L956.65,2307.85z"/>
					<path d="M982.24,2310.02l-25.72-2.05v-3.58h9.22l1.34-3.66v-5.98h4.26c0,0,5.15,0,5.88,0c0.1-0.24,0.4-0.82,1.2-2.38
						c0.68-1.32,1.45-2.83,1.53-3.05c0.45-1.2,0.44-2.29,0.44-3.56c0-0.14,0-0.28,0-0.42v-13.17c0-0.35,0.05-1.43,0.11-2.92
						c0.13-2.95,0.32-7.41,0.37-11.23c0.03-2.11,0.01-3.77-0.05-4.95c-0.1-1.93-0.31-2.13-0.35-2.15c-0.01,0-3.6-1.29-3.6-1.29
						l-0.22-0.08l5.4-4.47h9.34l-3.42,3.5c-0.09,0.16-0.87,1.64-0.67,2.88c0.23,1.36,0.66,34.2,0.69,36.24l2.05,2.05l1.13,3.16h5.61
						v6.86l4.31,0.88v4.01h-18.85V2310.02z M956.78,2307.73l25.2,2.01v-5.35h18.85v-3.53l-4.31-0.88v-6.81h-5.53l-1.17-3.27
						l-2.08-2.08l0-0.05c0-0.35-0.46-34.91-0.69-36.26c-0.23-1.41,0.67-3,0.7-3.07l0.02-0.03l3-3.07h-8.62l-4.98,4.12
						c0.78,0.28,3.39,1.21,3.39,1.21c0.22,0.08,0.67,0.24,0.57,7.28c-0.05,3.85-0.25,8.34-0.37,11.31c-0.06,1.49-0.11,2.56-0.11,2.9
						v13.17c0,0.14,0,0.28,0,0.42c0.01,1.29,0.01,2.4-0.45,3.65c-0.09,0.24-0.83,1.68-1.55,3.08c-0.56,1.08-1.19,2.31-1.22,2.42
						c0.01-0.02,0.01-0.06-0.02-0.1l-0.1,0.07l0,0.13c-0.12,0-5.97,0-5.97,0h-4v5.77l-1.42,3.88h-9.14V2307.73z"/>
				</g>
				<g>
					<polygon points="837.11,2245.48 844.06,2245.65 879,2276.68 935.06,2277.54 972.54,2245.56 976.54,2245.56 937.13,2278.27 
						878.58,2278.27 					"/>
				</g>
				<g>
					<path d="M966.78,2301.92l-1.26-1.17v-5.68c0,0-3.37,0.58-3.71,0c-0.35-0.58-0.93-1.84-1.16-2.08s-0.81-24.26-0.81-24.26
						s1.56-1.74,1.74-2.79s0.18-1.04,0.18-1.04h-4.68l-3.37,2.7l2.18,0.66l0.23,24.73l-0.7,1.5l-1.28,0.7v3.6l-6.5,0.23v2.21
						l-1.28,0.7v1.68h-2.79v3.34l13.06,0.91v-3.33h9.18L966.78,2301.92z"/>
					<path d="M956.78,2307.99l-13.32-0.93v-3.59h2.79v-1.63l1.28-0.7v-2.25l6.5-0.23v-3.55l1.31-0.71l0.67-1.44l-0.23-24.6
						l-2.32-0.7l3.6-2.89h4.88l-0.21,1.19c-0.18,0.99-1.5,2.54-1.74,2.81c0.16,6.53,0.6,23.34,0.79,24.14
						c0.15,0.18,0.41,0.66,0.67,1.18c0.17,0.33,0.35,0.68,0.49,0.91c0.19,0.31,1.97,0.22,3.58-0.06l0.15-0.03v5.78l1.28,1.19
						l-1.01,2.76h-9.14V2307.99z M943.72,2306.82l12.8,0.89v-3.32h9.22l0.89-2.43l-1.24-1.15v-5.58c-0.82,0.13-3.35,0.49-3.7-0.09
						c-0.14-0.24-0.32-0.58-0.5-0.92c-0.24-0.47-0.52-1.01-0.65-1.14c-0.07-0.07-0.18-0.19-0.53-12.31
						c-0.17-5.97-0.32-11.98-0.32-12.04l0-0.05l0.03-0.04c0.02-0.02,1.53-1.73,1.71-2.72l0.16-0.89h-4.48l-3.14,2.52l2.03,0.62
						l0.23,24.85l-0.73,1.57l-1.25,0.68v3.65l-6.5,0.23v2.16l-1.28,0.7v1.73h-2.79V2306.82z"/>
				</g>
				<g>
					<path d="M894.06,2304.88h4.18v-2.35l-0.7-0.87c0,0,0-18.63,0-18.89c0-0.26,0.35-1.66,0.35-1.66l-3.83-0.01l0.52,0.89
						c0,0,0.44,0.35,0.44,0.96c0,0.61,0.26,18.71,0.26,18.71l-0.87,0.52l-0.35,0.78V2304.88z"/>
					<path d="M898.37,2305.01h-4.44v-2.07l0.38-0.85l0.84-0.5c-0.02-1.28-0.26-18.06-0.26-18.64c0-0.54-0.38-0.85-0.39-0.86
						l-0.02-0.01l-0.01-0.02l-0.64-1.08l4.23,0.01l-0.04,0.16c-0.14,0.55-0.34,1.44-0.34,1.62v18.84l0.7,0.87V2305.01z
						 M894.19,2304.75h3.92v-2.17l-0.7-0.87v-18.93c0-0.22,0.22-1.14,0.31-1.53l-3.43-0.01l0.39,0.67c0.09,0.08,0.46,0.45,0.46,1.04
						c0,0.6,0.26,18.53,0.26,18.71l0,0.08l-0.9,0.54l-0.32,0.72V2304.75z"/>
				</g>
				<g>
					<path d="M915.56,2304.88h4.18v-2.35l-0.7-0.87c0,0,0-18.63,0-18.89c0-0.26,0.96-1.66,0.96-1.66l-4.44-0.01l0.52,0.89
						c0,0,0.44,0.35,0.44,0.96c0,0.61,0.26,18.71,0.26,18.71l-0.87,0.52l-0.35,0.78V2304.88z"/>
					<path d="M919.87,2305.01h-4.44v-2.07l0.38-0.85l0.84-0.5c-0.02-1.28-0.26-18.06-0.26-18.64c0-0.54-0.38-0.85-0.39-0.86
						l-0.02-0.01l-0.01-0.02l-0.64-1.08l4.92,0.01l-0.14,0.2c-0.44,0.65-0.93,1.43-0.94,1.58v18.84l0.7,0.87V2305.01z
						 M915.69,2304.75h3.92v-2.17l-0.7-0.87v-18.93c0-0.24,0.54-1.07,0.84-1.53l-3.96-0.01l0.39,0.67c0.09,0.08,0.46,0.45,0.46,1.04
						c0,0.6,0.26,18.53,0.26,18.71l0,0.08l-0.9,0.54l-0.32,0.72V2304.75z"/>
				</g>
				<g>
					<polygon points="934.54,2281.1 936.21,2282.66 936.21,2294.96 936.21,2296.47 930.82,2296.47 930.82,2302.38 930.82,2304.88 
						935.46,2304.88 936.74,2303.6 936.74,2302.27 941.27,2301.8 941.27,2298.9 939.53,2298.44 938.83,2283 939.73,2280.72 					"/>
					<path d="M935.52,2305.01h-4.83v-8.68h5.39v-13.62l-1.86-1.72l5.7-0.42l-0.96,2.45l0.69,15.31l1.75,0.47v3.12l-4.53,0.46v1.27
						L935.52,2305.01z M930.95,2304.75h4.46l1.2-1.2v-1.4l4.53-0.46V2299l-1.74-0.46l-0.7-15.56l0.83-2.11l-4.68,0.34l1.5,1.39v14
						h-5.39V2304.75z"/>
				</g>
				<g>
					<polygon points="877.55,2281.1 875.88,2282.66 875.88,2294.96 875.88,2296.47 881.27,2296.47 881.27,2302.38 881.27,2304.88 
						876.62,2304.88 875.35,2303.6 875.35,2302.27 870.82,2301.8 870.82,2298.9 872.56,2298.44 873.26,2283 872.36,2280.72 					"/>
					<path d="M881.4,2305.01h-4.83l-1.35-1.35v-1.27l-4.53-0.46v-3.12l1.75-0.47l0.69-15.31l-0.96-2.45l5.7,0.42l-1.86,1.72v13.62
						h5.39V2305.01z M876.68,2304.75h4.46v-8.15h-5.39v-14l1.5-1.39l-4.68-0.34l0.83,2.11l-0.7,15.56l-1.74,0.46v2.68l4.53,0.46v1.4
						L876.68,2304.75z"/>
				</g>
				<g>
					<rect x="877.7" y="2303.87" width="59.04" height="1.28"/>
				</g>
				<g>
					<polygon points="987.87,2287.76 980.39,2288.21 977.31,2294.88 967.21,2294.88 967.21,2300.75 965.83,2304.52 956.65,2304.52 
						958.09,2306.63 982.11,2307.38 982.11,2304.52 1000.96,2304.52 1000.96,2300.75 996.65,2299.87 996.65,2293.04 991.09,2293.04 
						989.94,2289.83 					"/>
					<path d="M982.24,2307.52l-24.22-0.76l-1.63-2.36h9.34l1.34-3.66v-5.98h10.15l3.08-6.67l7.62-0.46l2.13,2.13l1.13,3.16h5.61
						v6.86l4.31,0.88v4.01h-18.85V2307.52z M958.16,2306.5l23.81,0.75v-2.85h18.85v-3.53l-4.31-0.88v-6.81h-5.53l-1.17-3.27l-2-2
						l-7.34,0.44l-3.09,6.68h-10.05v5.77l-1.42,3.88h-9.03L958.16,2306.5z"/>
				</g>
				<g>
					<polygon points="1001.01,2307.48 935.86,2303.93 935.12,2305 1000.96,2308.63 					"/>
				</g>
				<g>
					<polygon points="879.04,2306.36 934.88,2306.36 1000.96,2311.32 1000.96,2308.63 943.59,2305.29 935.46,2304.88 
						879.04,2304.88 813.27,2305.89 813.18,2312.1 					"/>
				</g>
				<g>
					<polygon points="812.48,2306.23 878.32,2303.82 878.37,2305.23 812.53,2307.38 					"/>
				</g>
				<g>
					<path d="M894.88,2273.41l0.4-0.77v-1.86c0,0-0.07-1.97,0-2.09s0-1.24,0-1.24l-0.4-0.58c0,0,0.12-0.62,0-0.81
						c-0.12-0.19-0.27-0.35,0-0.39c0.27-0.04,0.43,0,0.43,0l0.39-0.31v-0.89c0,0,0.02-0.46,0.45-0.35c0.44,0.12,0.71,0.15,0.67,0.62
						c-0.04,0.46,0,0.93,0,0.93l0.65,0.19c0,0,0.31,0.77,0.31,1.16c0,0.39,0.39,1.01,0.27,1.16c-0.12,0.15-0.23,1.43-0.27,1.82
						c-0.04,0.39-0.27,0.85-0.39,1.12c-0.12,0.27-0.12,1.59-0.12,1.59l0.35,0.7H894.88z"/>
					<path d="M897.84,2273.54h-3.17l0.48-0.94v-1.83c-0.06-1.89-0.01-2.1,0.01-2.15c0.03-0.1,0.02-0.66-0.01-1.14l-0.41-0.6
						l0.01-0.05c0.05-0.27,0.07-0.63,0.02-0.72c-0.02-0.03-0.04-0.06-0.05-0.08c-0.08-0.12-0.15-0.24-0.1-0.36
						c0.05-0.12,0.2-0.14,0.24-0.15c0.19-0.03,0.33-0.02,0.41-0.01l0.29-0.23v-0.83c0-0.03,0.02-0.28,0.2-0.42
						c0.08-0.06,0.21-0.11,0.42-0.06l0.08,0.02c0.39,0.1,0.73,0.19,0.68,0.74c-0.03,0.33-0.01,0.66,0,0.82l0.63,0.19l0.02,0.06
						c0.01,0.03,0.32,0.8,0.32,1.21c0,0.16,0.08,0.39,0.16,0.59c0.11,0.3,0.19,0.51,0.09,0.65c-0.06,0.09-0.14,0.65-0.23,1.64
						l-0.01,0.11c-0.03,0.35-0.21,0.74-0.34,1.02c-0.02,0.05-0.04,0.1-0.06,0.14c-0.08,0.18-0.1,1-0.11,1.5L897.84,2273.54z
						 M895.1,2273.28h2.32l-0.27-0.53v-0.03c0-0.14,0-1.35,0.13-1.64c0.02-0.04,0.04-0.09,0.06-0.14c0.12-0.27,0.28-0.64,0.31-0.94
						l0.01-0.11c0.11-1.15,0.19-1.63,0.28-1.77c0.01-0.06-0.07-0.27-0.12-0.41c-0.08-0.22-0.18-0.47-0.18-0.68
						c0-0.3-0.21-0.87-0.28-1.05l-0.68-0.2l-0.01-0.09c0-0.02-0.04-0.48,0-0.95c0.03-0.31-0.08-0.35-0.49-0.46l-0.08-0.02
						c-0.09-0.02-0.15-0.02-0.2,0.01c-0.07,0.05-0.09,0.18-0.09,0.21l0,0.95l-0.49,0.39l-0.06-0.02c0,0-0.14-0.03-0.38,0
						c-0.01,0-0.02,0-0.02,0c0.02,0.03,0.04,0.07,0.06,0.1c0.02,0.03,0.04,0.06,0.06,0.09c0.12,0.2,0.06,0.67,0.03,0.85l0.39,0.57
						l0,0.04c0.04,0.74,0.04,1.17-0.01,1.3c-0.03,0.13-0.02,1.13,0.01,2.03l0,1.89L895.1,2273.28z M895.39,2268.76
						C895.39,2268.76,895.39,2268.76,895.39,2268.76C895.39,2268.76,895.39,2268.76,895.39,2268.76z M895.4,2268.75
						C895.4,2268.75,895.39,2268.75,895.4,2268.75C895.39,2268.75,895.4,2268.75,895.4,2268.75z M895.4,2268.74
						C895.4,2268.74,895.4,2268.74,895.4,2268.74S895.4,2268.74,895.4,2268.74z M897.95,2268.12L897.95,2268.12L897.95,2268.12z"/>
				</g>
				<g>
					<path d="M916.24,2273.41c0,0-0.15-0.66,0-0.73s0.39-0.08,0.46-0.27c0.08-0.19,0.25-3.44,0.25-3.44v-1.35c0,0-0.34,0-0.39-0.35
						s0-1.32,0-1.32s0.45-0.04,0.6-0.23c0.15-0.19,0.19-0.58,0.19-0.58s0-0.04,0-0.46s0.15-0.73,0.42-0.56s0.47,0.37,0.47,0.37
						s0.08,0.77,0,0.89c-0.08,0.12,0.36,0.5,0.36,0.5s0.49,0.27,0.49,0.54c0,0.27,0.46,1.16,0.19,1.32
						c-0.27,0.15-0.46,1.01-0.46,1.01l0.35,2.43c0,0-0.41,0.39-0.57,0.7c-0.16,0.31-0.16,0.77-0.16,0.77l0.46,0.27v0.5H916.24z"/>
					<path d="M919.04,2273.54h-2.9l-0.02-0.1c-0.12-0.5-0.1-0.8,0.07-0.88c0.06-0.03,0.11-0.05,0.17-0.06
						c0.12-0.04,0.2-0.06,0.23-0.14c0.05-0.17,0.18-2.18,0.24-3.4l0-1.23c-0.16-0.04-0.35-0.16-0.39-0.44c-0.05-0.36,0-1.3,0-1.34
						l0.01-0.11l0.11-0.01c0.15-0.01,0.43-0.08,0.51-0.18c0.1-0.13,0.15-0.4,0.16-0.51v-0.46c0-0.21,0.03-0.58,0.26-0.7
						c0.06-0.03,0.19-0.08,0.36,0.03c0.28,0.18,0.48,0.38,0.49,0.39l0.03,0.03l0,0.05c0.03,0.35,0.06,0.78-0.01,0.94
						c0.03,0.07,0.16,0.22,0.31,0.35c0.12,0.07,0.55,0.33,0.55,0.65c0,0.07,0.06,0.25,0.11,0.41c0.14,0.43,0.29,0.87,0.02,1.02
						c-0.15,0.08-0.32,0.57-0.4,0.9l0.35,2.48l-0.05,0.05c0,0-0.39,0.38-0.55,0.66c-0.11,0.21-0.14,0.5-0.15,0.64l0.46,0.27V2273.54
						z M916.34,2273.28h2.43v-0.3l-0.46-0.27v-0.08c0-0.02,0-0.5,0.18-0.83c0.14-0.26,0.43-0.57,0.55-0.68l-0.34-2.39l0.01-0.02
						c0.03-0.15,0.22-0.92,0.53-1.1c0.06-0.03,0.01-0.27-0.14-0.71c-0.07-0.2-0.12-0.37-0.12-0.49c0-0.13-0.25-0.33-0.43-0.43
						l-0.02-0.02c-0.5-0.45-0.42-0.61-0.38-0.67c0.03-0.08,0.02-0.44-0.01-0.76c-0.06-0.06-0.22-0.19-0.41-0.32
						c-0.03-0.02-0.07-0.04-0.1-0.02c-0.05,0.03-0.12,0.18-0.12,0.47l0,0.48c0,0.04-0.05,0.43-0.22,0.65
						c-0.14,0.17-0.42,0.24-0.58,0.27c-0.01,0.26-0.04,0.92,0,1.18c0.03,0.22,0.23,0.24,0.26,0.24l0.13,0l0,0.13v1.35
						c-0.04,0.77-0.18,3.29-0.26,3.49c-0.08,0.19-0.26,0.25-0.4,0.29c-0.04,0.01-0.08,0.03-0.12,0.04
						C916.29,2272.85,916.3,2273.06,916.34,2273.28z"/>
				</g>
				<g>
					<path d="M945.33,2255.58c0.12-0.19,0.35-0.43,0.23-0.7c-0.12-0.27,0.04-0.35-0.23-0.46c-0.27-0.12-0.35,0-0.66,0
						c-0.31,0-0.66,0.18-0.66,0.55s-0.12,0.57,0,0.76c0.12,0.19,0.19,0.39,0.27,0.58c0.08,0.19,0.12,0.08,0.08,0.39
						c-0.04,0.31-0.06,0.39-0.4,0.66s-0.26,0-0.34,0.27c-0.08,0.27,0,1.2,0,1.2s-0.26,1.7-0.03,2.13c0.23,0.43,0.34,0.92,0.34,0.92
						s0.45,0.51,0.61,0.7c0.16,0.19,0.16,0.5,0.16,0.72c0,0.21-0.08,0.56,0,0.81c0.08,0.25,0.14,0.65-0.16,0.8
						c-0.3,0.15-0.42,0.19-0.49,0.42c-0.08,0.23-0.4-0.08-0.08,0.23c0.33,0.31,1.06-0.32,1.06-0.32l0.81-0.7c0,0,0.13-1.15,0-2
						s-0.21-3.87-0.21-3.87s-0.05,0.19,0.27-0.39c0.31-0.58,0.39,0,0.31-0.58c-0.08-0.58,0-0.81-0.15-1.2
						C945.91,2256.12,945.33,2255.58,945.33,2255.58z"/>
					<path d="M944.2,2265.77c-0.12,0-0.23-0.03-0.32-0.12c-0.17-0.16-0.22-0.21-0.17-0.3c0.02-0.04,0.07-0.09,0.17-0.07
						c0.01,0,0.03,0,0.04,0.01c0,0,0,0,0-0.01c0.09-0.26,0.23-0.33,0.48-0.46l0.08-0.04c0.22-0.11,0.16-0.45,0.1-0.64
						c-0.06-0.2-0.04-0.43-0.02-0.64c0.01-0.08,0.01-0.16,0.01-0.22c0-0.2,0-0.47-0.13-0.63c-0.16-0.19-0.6-0.7-0.61-0.7l-0.02-0.02
						l-0.01-0.03c0,0-0.12-0.49-0.33-0.89c-0.24-0.44-0.02-1.96,0.02-2.19c-0.01-0.15-0.07-0.96,0.01-1.23
						c0.04-0.14,0.07-0.23,0.2-0.23c0.04,0,0.13-0.07,0.18-0.11c0.31-0.25,0.32-0.3,0.35-0.57c0.01-0.1,0.02-0.16,0.01-0.17
						c0,0,0,0-0.01-0.01c-0.02-0.03-0.04-0.06-0.07-0.14c-0.08-0.19-0.15-0.38-0.26-0.56c-0.1-0.16-0.07-0.31-0.05-0.49
						c0.01-0.1,0.03-0.21,0.03-0.34c0-0.45,0.41-0.69,0.79-0.69c0.1,0,0.18-0.01,0.25-0.03c0.13-0.03,0.26-0.05,0.46,0.04
						c0.22,0.1,0.25,0.21,0.26,0.34c0,0.06,0.01,0.11,0.04,0.19c0.12,0.28-0.05,0.53-0.17,0.71c-0.01,0.01-0.01,0.02-0.02,0.02
						c0.17,0.17,0.56,0.57,0.69,0.9c0.1,0.26,0.11,0.45,0.12,0.7c0.01,0.15,0.01,0.31,0.04,0.53c0.04,0.28,0.04,0.32-0.01,0.39
						c-0.05,0.05-0.11,0.05-0.13,0.05c-0.06,0-0.15,0.15-0.19,0.22c-0.11,0.21-0.19,0.34-0.25,0.42c0.01,0.52,0.09,3.01,0.2,3.76
						c0.13,0.86,0.01,1.98,0,2.03l-0.01,0.05l-0.85,0.73C945.06,2265.39,944.6,2265.77,944.2,2265.77z M944.1,2265.49
						c0.2,0.11,0.65-0.18,0.85-0.35l0.77-0.66c0.02-0.23,0.11-1.19,0-1.91c-0.12-0.8-0.2-3.43-0.21-3.84
						c-0.01-0.02-0.01-0.05,0-0.08l0.23-0.98l0.02,0.62c0.01-0.02,0.02-0.04,0.03-0.06c0.12-0.23,0.23-0.31,0.32-0.34
						c0-0.04-0.01-0.1-0.02-0.16c-0.03-0.23-0.04-0.4-0.04-0.55c-0.01-0.23-0.02-0.4-0.1-0.61c-0.12-0.29-0.55-0.74-0.7-0.88
						l-0.08-0.07l0.05-0.09c0.02-0.04,0.05-0.08,0.08-0.12c0.13-0.18,0.2-0.31,0.14-0.45c-0.05-0.12-0.06-0.21-0.06-0.28
						c-0.01-0.08-0.01-0.08-0.1-0.12c-0.13-0.06-0.2-0.04-0.31-0.02c-0.08,0.01-0.17,0.03-0.3,0.03c-0.21,0-0.53,0.11-0.53,0.42
						c0,0.15-0.02,0.28-0.03,0.38c-0.02,0.16-0.03,0.24,0.01,0.32c0.12,0.2,0.2,0.4,0.28,0.6c0.02,0.05,0.03,0.07,0.05,0.09
						c0.06,0.09,0.06,0.15,0.04,0.36c-0.04,0.34-0.09,0.45-0.45,0.74c-0.13,0.1-0.21,0.14-0.28,0.16c0,0.01-0.01,0.03-0.01,0.05
						c-0.06,0.2-0.02,0.9,0,1.15l0,0.02l0,0.02c-0.1,0.64-0.2,1.76-0.05,2.05c0.2,0.38,0.32,0.8,0.35,0.92
						c0.09,0.1,0.45,0.51,0.59,0.68c0.2,0.23,0.2,0.57,0.2,0.8c0,0.07-0.01,0.15-0.02,0.24c-0.02,0.18-0.04,0.39,0.01,0.53
						c0.14,0.46,0.06,0.81-0.23,0.95l-0.08,0.04c-0.25,0.12-0.3,0.15-0.35,0.31C944.16,2265.4,944.14,2265.45,944.1,2265.49z"/>
				</g>
				<g>
					<rect x="841.1" y="2290.78" width="0.26" height="9.05"/>
				</g>
				<g>
					<polygon points="828.48,2299.13 828.21,2299.13 828.21,2291.95 821.21,2291.95 821.21,2291.69 828.48,2291.69 					"/>
				</g>
				<g>
					<rect x="830.09" y="2302.14" width="11.14" height="0.26"/>
				</g>
				<g>
					<rect x="830.09" y="2304.23" width="11.14" height="0.26"/>
				</g>
				<g>
					
						<rect x="843.27" y="2291.71" transform="matrix(0.158 -0.9874 0.9874 0.158 -1554.9486 2764.3074)" width="0.26" height="4.41"/>
				</g>
				<g>
					<rect x="830.65" y="2290.78" width="0.26" height="8.36"/>
				</g>
				<g>
					<path d="M816.99,2302.55c-0.82,0-1.56-0.04-1.92-0.16l0.08-0.25c1.01,0.34,5.3,0,5.34-0.01l0.02,0.26
						C820.4,2302.41,818.55,2302.55,816.99,2302.55z"/>
				</g>
				<g>
					<path d="M819.32,2304.49c-0.03,0-0.06-0.01-0.08-0.02l0.12-0.23c-0.03-0.01-0.06-0.01-0.06,0c0.17-0.11,0.62-1.45,1.09-3.5
						c0.06-2.6,0.12-6.81,0.02-7.11c0.01,0.02,0.04,0.07,0.11,0.07v-0.26l0.1,0.04c0.06,0.06,0.14,0.14,0.1,3.75
						c-0.02,1.76-0.06,3.51-0.06,3.53l0,0.03c-0.24,1.05-0.85,3.51-1.24,3.69C819.37,2304.49,819.35,2304.49,819.32,2304.49z"/>
				</g>
				<g>
					<line x1="824.78" y1="2250.39" x2="824.78" y2="2286.95"/>
					<rect x="824.65" y="2250.39" width="0.26" height="36.56"/>
				</g>
				<g>
					<line x1="819.94" y1="2248.82" x2="823.47" y2="2248.82"/>
					<rect x="819.94" y="2248.69" width="3.53" height="0.26"/>
				</g>
				<g>
					<path d="M825.82,2249.17c0,0,1.04-0.17,1.91,0s1.31,0,2.35,0.52s1.22,1.13,1.22,1.13"/>
					<path d="M831.18,2250.86c0,0-0.18-0.57-1.15-1.05c-0.68-0.34-1.09-0.37-1.55-0.41c-0.23-0.02-0.47-0.04-0.76-0.1
						c-0.84-0.17-1.86,0-1.87,0l-0.04-0.26c0.04-0.01,1.08-0.18,1.96,0c0.28,0.06,0.51,0.07,0.73,0.09
						c0.47,0.04,0.92,0.08,1.65,0.44c1.08,0.54,1.28,1.19,1.29,1.21L831.18,2250.86z"/>
				</g>
				<g>
					<line x1="819.29" y1="2283.03" x2="823.47" y2="2283.29"/>
					
						<rect x="821.25" y="2281.07" transform="matrix(0.0624 -0.9981 0.9981 0.0624 -1508.5752 2960.511)" width="0.26" height="4.19"/>
				</g>
				<g>
					<line x1="819.29" y1="2285.64" x2="822.86" y2="2285.64"/>
					<rect x="819.29" y="2285.51" width="3.57" height="0.26"/>
				</g>
				<g>
					<rect x="821.71" y="2300.14" width="5.94" height="0.26"/>
				</g>
				<g>
					
						<rect x="835.7" y="2292.17" transform="matrix(0.0384 -0.9993 0.9993 0.0384 -1491.292 3043.647)" width="0.26" height="9.06"/>
				</g>
				<g>
					<rect x="852.76" y="2268.06" width="0.26" height="24.2"/>
				</g>
				<g>
					<rect x="856.94" y="2294.96" width="0.26" height="5.8"/>
				</g>
				<g>
					<path d="M865.06,2302.66c-0.61,0-1.34-0.08-1.56-0.11h-6.78v-0.26l6.8,0c0.81,0.1,2.05,0.17,2.26,0.02l0.15,0.22
						C865.79,2302.63,865.45,2302.66,865.06,2302.66z"/>
				</g>
				<g>
					<path d="M863.34,2304.18c-0.75,0-1.61-0.04-1.66-0.04h-4.96v-0.26h4.96c0.03,0,2,0.09,2.59,0c0.62-0.09,1.56,0,1.6,0
						l-0.02,0.26c-0.01,0-0.95-0.08-1.54,0C864.1,2304.17,863.73,2304.18,863.34,2304.18z"/>
				</g>
				<g>
					
						<rect x="869.22" y="2288.68" transform="matrix(0.042 -0.9991 0.9991 0.042 -1449.1116 3066.3201)" width="10.34" height="0.26"/>
				</g>
				<g>
					
						<rect x="864.42" y="2294.52" transform="matrix(0.0995 -0.995 0.995 0.0995 -1505.4689 2927.2698)" width="0.26" height="1.75"/>
				</g>
				<g>
					<rect x="863.55" y="2293.56" width="0.26" height="7.19"/>
				</g>
				<g>
					<rect x="850.5" y="2294.26" width="0.26" height="4.7"/>
				</g>
				<g>
					<rect x="849.76" y="2293.43" width="6.96" height="0.26"/>
				</g>
				<g>
					<path d="M857.02,2268.79c-0.01,0-0.88-0.35-1.65-0.61c-0.73-0.24-1.29-0.01-1.3,0l-0.1-0.24c0.03-0.01,0.66-0.28,1.49,0
						c0.78,0.26,1.65,0.61,1.66,0.61L857.02,2268.79z"/>
				</g>
				<g>
					<rect x="849.15" y="2267.81" width="2.79" height="0.26"/>
				</g>
				<g>
					<path d="M981.25,2250.17c0,0,0.88-0.58,1.75-0.78c0.87-0.19,1.19-0.53,2.36-0.48c1.17,0.05,1.57,0.54,1.57,0.54"/>
					<path d="M981.32,2250.27l-0.14-0.22c0.04-0.02,0.91-0.6,1.79-0.79c0.27-0.06,0.5-0.14,0.71-0.21c0.45-0.16,0.87-0.3,1.69-0.27
						c1.21,0.06,1.65,0.57,1.67,0.59l-0.2,0.17c0,0-0.39-0.44-1.48-0.49c-0.76-0.04-1.14,0.1-1.59,0.25
						c-0.22,0.08-0.45,0.16-0.74,0.22C982.2,2249.7,981.33,2250.27,981.32,2250.27z"/>
				</g>
				<g>
					<polygon points="971.57,2299.57 971.31,2299.57 971.31,2295.78 982,2295.78 982,2296.05 971.57,2296.05 					"/>
				</g>
				<g>
					<rect x="972.72" y="2300.62" width="9.28" height="0.26"/>
				</g>
				<g>
					<rect x="971.44" y="2302.14" width="11.49" height="0.26"/>
				</g>
				<g>
					<rect x="967.38" y="2300.14" width="3.02" height="0.26"/>
				</g>
				<g>
					<rect x="984.09" y="2291.11" width="5.8" height="0.26"/>
				</g>
				<g>
					<rect x="983.96" y="2293.56" width="0.26" height="5.4"/>
				</g>
				<g>
					<polygon points="991.3,2302.53 991.04,2302.53 991.04,2300.77 989.76,2293.59 990.02,2293.54 991.3,2300.74 					"/>
				</g>
				<g>
					
						<rect x="994.52" y="2300.07" transform="matrix(0.0564 -0.9984 0.9984 0.0564 -1360.177 3165.624)" width="0.26" height="4.65"/>
				</g>
				<g>
					<rect x="992.33" y="2300.14" width="4.64" height="0.26"/>
				</g>
				<g>
					<path d="M980.75,2289.48l-0.05-0.26c0.07-0.01,1.7-0.32,2.23-0.32h5.16v0.26h-5.16
						C982.43,2289.17,980.77,2289.48,980.75,2289.48z"/>
				</g>
				<g>
					<rect x="956.45" y="2295.39" width="0.26" height="6.18"/>
				</g>
				<g>
					<polygon points="962.52,2303.16 962.17,2300.76 962.17,2300.75 962.34,2295.3 962.61,2295.31 962.43,2300.75 962.78,2303.12 
											"/>
				</g>
				<g>
					<rect x="963.35" y="2300.62" width="1.83" height="0.26"/>
				</g>
				<g>
					<rect x="963.78" y="2302.4" width="2.18" height="0.26"/>
				</g>
				<g>
					<rect x="957.69" y="2299.7" width="4" height="0.26"/>
				</g>
				<g>
					<rect x="949.59" y="2301.44" width="6.18" height="0.26"/>
				</g>
				<g>
					<rect x="949.59" y="2302.75" width="6.99" height="0.26"/>
				</g>
				<g>
					<path d="M956.66,2268.42l-0.15-0.22c0.03-0.02,0.62-0.42,1.18-0.38c0.52,0.03,1.65,0.12,1.66,0.12l-0.02,0.26
						c-0.01,0-1.14-0.09-1.65-0.12C957.21,2268.05,956.66,2268.41,956.66,2268.42z"/>
				</g>
				<g>
					<polygon points="955.88,2267.44 955.67,2267.29 956.52,2266.1 960.73,2266.1 960.73,2266.36 956.65,2266.36 					"/>
				</g>
				<g>
					<rect x="936.53" y="2282.55" width="1.74" height="0.26"/>
				</g>
				<g>
					<path d="M936.34,2282.27l-0.14-0.22c0.02-0.01,0.45-0.27,0.94-0.45c0.5-0.18,1.73,0.13,1.86,0.17l-0.07,0.25
						c-0.35-0.09-1.35-0.31-1.71-0.18C936.77,2282.02,936.35,2282.27,936.34,2282.27z"/>
				</g>
				<g>
					<rect x="936.97" y="2294.83" width="1.74" height="0.26"/>
				</g>
				<g>
					<rect x="936.14" y="2297.57" width="0.26" height="4"/>
				</g>
				<g>
					<rect x="931.4" y="2302.4" width="3.74" height="0.26"/>
				</g>
				<g>
					<rect x="931.84" y="2303.09" width="4.44" height="0.26"/>
				</g>
				<g>
					<rect x="916.87" y="2302.14" width="2.18" height="0.26"/>
				</g>
				<g>
					<path d="M897.44,2302.4c-0.48-0.05-1.8-0.14-2.21-0.01l-0.08-0.25c0.54-0.18,2.25-0.01,2.32-0.01L897.44,2302.4z"/>
				</g>
				<g>
					<rect x="895.28" y="2282.55" width="1.74" height="0.26"/>
				</g>
				<g>
					<rect x="916.95" y="2282.55" width="1.65" height="0.26"/>
				</g>
				<g>
					<rect x="873.98" y="2282.56" width="1.74" height="0.26"/>
				</g>
				<g>
					<path d="M875.92,2282.28c0,0-0.43-0.26-0.89-0.43c-0.36-0.13-1.35,0.08-1.71,0.18l-0.07-0.25c0.14-0.04,1.36-0.35,1.86-0.17
						c0.49,0.18,0.92,0.44,0.94,0.45L875.92,2282.28z"/>
				</g>
				<g>
					<rect x="873.54" y="2294.83" width="1.74" height="0.26"/>
				</g>
				<g>
					<rect x="875.85" y="2297.58" width="0.26" height="4"/>
				</g>
				<g>
					<rect x="877.11" y="2302.41" width="3.74" height="0.26"/>
				</g>
				<g>
					<rect x="875.98" y="2303.1" width="4.44" height="0.26"/>
				</g>
			</g>
			<g>
				<g>
					<polygon className="st58" points="933.29,2299.69 984.2,2300.71 980.48,2241.24 933.29,2275.91 					"/>
				</g>
				<g>
					<polygon className="st58" points="874.16,2299.69 813.04,2301.16 816.38,2240.01 874.16,2275.91 					"/>
				</g>
				<g>
					<rect x="871.62" y="2275.91" className="st59" width="61.67" height="23.78"/>
				</g>
				<g>
					<g>
						<defs>
							<path id="SVGID_00000019672906097042008050000011124006536032555662_" d="M815.92,2302.66l58.79-1.47
								c0.17-0.01,0.35-0.02,0.53-0.02h56.4c0.18,0,0.36,0.01,0.55,0.02l64.7,4.94c7.27,1.58,2.25,2.39,0,4.18
								c-1.85,1.47-3.31,3.21-5.67,3.02l-5.42-1.55c-5.82-0.48-11.68-0.15-17.39,1.06c-13.05,2.77-27.6,1.31-40.84,1.49
								c-11.86,0.16-23.73,0.19-35.59,0.1c-5.77-0.05-11.85,0.39-17.59-0.26c-5.2-0.59-10.31-2.2-15.58-2.48
								c-8.51-0.44-16.96,1.44-25.46,0.95c-5.17-0.3-10.35-0.98-15.51-1.52C811.72,2309.18,804.39,2304.11,815.92,2302.66z"/>
						</defs>
					</g>
				</g>
				<g>
					<polygon className="st61" points="824.87,2240.36 873.36,2275.91 932.14,2275.91 977.81,2240.41 972.47,2240.37 933.06,2273.08 
						874.51,2273.08 833.04,2240.29 					"/>
					<path className="st26" d="M932.17,2276h-58.84l-0.02-0.02l-48.7-35.7l8.46-0.08l0.02,0.02l41.45,32.77h58.49l39.41-32.71l5.63,0.05
						L932.17,2276z M873.39,2275.82h58.72l45.45-35.33l-5.07-0.04l-39.41,32.71h-58.61l-0.02-0.02l-41.45-32.77l-7.88,0.07
						L873.39,2275.82z"/>
				</g>
				<g>
					<polygon className="st62" points="826.55,2304.72 825.61,2298.41 809.11,2299.33 809.11,2306.9 					"/>
				</g>
				<g>
					<polygon className="st26" points="996.89,2306.12 978.04,2304.68 978.04,2299.33 991.15,2299.33 996.89,2299.33 					"/>
				</g>
				<g>
					<polygon className="st26" points="949.89,2301.43 931.04,2299.99 931.04,2296.55 944.15,2294.64 949.89,2294.64 					"/>
				</g>
				<g>
					<polygon className="st26" points="873,2301.43 854.15,2299.99 854.15,2292.62 867.26,2295.6 873,2294.64 					"/>
				</g>
				<g>
					<polygon className="st63" points="844.2,2303.43 844.2,2298.41 841.68,2297.03 841.68,2294.05 841.68,2286.01 837.32,2284.63 
						827.44,2284.63 813.67,2284.63 813.67,2287.85 810.22,2287.62 810.22,2295.56 809.07,2298.41 809.07,2301.16 826.02,2301.16 
						826.55,2304.72 835.48,2303.92 					"/>
					<path className="st26" d="M826.44,2304.87l-0.53-3.57h-16.96v-2.91l1.15-2.85v-8.06l3.44,0.23v-3.21h23.8l4.47,1.41v11.04
						l2.52,1.38v5.22l-8.85,0.5L826.44,2304.87z M809.2,2301.03h16.92l0.53,3.55l8.81-0.79l8.6-0.49v-4.82l-2.52-1.38v-11
						l-4.25-1.34h-23.5v3.22l-3.44-0.23v7.83l-1.15,2.85V2301.03z"/>
				</g>
				<g>
					<polygon className="st64" points="968.46,2240.37 933.98,2269.71 930.99,2272.35 874.93,2271.48 839.74,2240.45 844.59,2240.37 
						874.76,2267.48 876.5,2268.35 928.72,2268.52 931.51,2268.18 963.83,2240.37 					"/>
					<path className="st65" d="M931.04,2272.48l-0.05,0l-56.11-0.87l-35.48-31.29l5.24-0.09l0.04,0.03l30.15,27.1l1.7,0.85l52.19,0.17
						l2.74-0.34l32.33-27.82h5.04l-34.76,29.57L931.04,2272.48z M874.98,2271.35l55.96,0.86l2.95-2.61l34.22-29.11h-4.23
						l-32.31,27.8l-2.84,0.35l-52.26-0.17l-1.78-0.89l-0.01-0.01l-30.13-27.08l-4.46,0.07L874.98,2271.35z"/>
				</g>
				<g>
					<path className="st42" d="M896.08,2299.28c0,0,12.1,0,12.1,0c0.18,0,0.13-7.81,0.02-8.43c-0.31-1.75-1.27-3.47-2.86-4.26
						c-0.82-0.4-1.74-0.54-2.65-0.6c-0.86-0.06-1.75-0.07-2.58,0.18c-2.17,0.63-3.56,2.82-4.01,5.04
						C895.96,2291.9,896.08,2299.28,896.08,2299.28z"/>
				</g>
				<g>
					<path className="st27" d="M878.54,2299.28c0,0,10.76,0,10.76,0c0.16,0,0.11-7.51,0.02-8.11c-0.28-1.68-1.13-3.34-2.54-4.1
						c-0.73-0.39-1.55-0.52-2.35-0.58c-0.77-0.06-1.55-0.06-2.3,0.17c-1.93,0.61-3.17,2.72-3.57,4.85
						C878.44,2292.18,878.54,2299.28,878.54,2299.28z"/>
				</g>
				<g>
					<path className="st27" d="M914.75,2299.28c0,0,10.76,0,10.76,0c0.16,0,0.11-7.51,0.02-8.11c-0.28-1.68-1.13-3.34-2.54-4.1
						c-0.73-0.39-1.55-0.52-2.35-0.58c-0.77-0.06-1.55-0.06-2.3,0.17c-1.93,0.61-3.17,2.72-3.57,4.85
						C914.64,2292.18,914.75,2299.28,914.75,2299.28z"/>
				</g>
				<g>
					<polygon className="st42" points="811.72,2240.09 811.72,2240.09 824.96,2240.36 873.36,2275.91 869.42,2275.91 					"/>
				</g>
				<g>
					<path className="st50" d="M813.67,2284.63h5.05h8.72v-39.26l1.73-2.17c0,0,2.35-0.3-1.27-1.96s-16.18-1.15-16.18-1.15l1.94,2.76
						l0.46,36.28L813.67,2284.63z"/>
					<path className="st26" d="M827.57,2284.76h-14.05l0.47-5.65l-0.46-36.23l-2.06-2.92l0.24-0.01c0.51-0.02,12.62-0.5,16.24,1.16
						c1.5,0.69,2.17,1.22,2.1,1.67c-0.06,0.38-0.62,0.51-0.81,0.54l-1.67,2.09V2284.76z M813.81,2284.5h13.5v-39.18l1.8-2.25
						l0.05-0.01c0.19-0.02,0.61-0.14,0.63-0.32c0.02-0.11-0.07-0.53-1.95-1.39c-1.47-0.67-4.72-1.08-9.4-1.19
						c-2.98-0.07-5.68,0.01-6.48,0.04l1.83,2.59l0,0.04l0.46,36.28l0,0.01L813.81,2284.5z"/>
				</g>
				<g>
					<path className="st66" d="M844.2,2260.17c0,0,0.15,0.58,0.5,1.28c0.35,0.7,0.58,3.02,0.58,3.02v14.04v9.29c0,0-0.53,0.92-1.08,1.15
						c-0.55,0.23-1.01,0-1.01,0v8.91l1.01,0.55v5.02l18.97-1.39v-3.62c0,0-1.41-0.17-1.41-0.52s0-3.71,0-3.71v-6.38h-2.79h-5.8
						v-23.45v-2.02l1.27-0.42l-2.35-1.74H844.2z"/>
					<path className="st26" d="M844.07,2303.57v-5.08l-1.01-0.55v-9.2l0.19,0.09c0.02,0.01,0.42,0.2,0.9,0c0.45-0.19,0.91-0.91,1-1.07
						v-23.29c-0.01-0.13-0.24-2.32-0.57-2.97c-0.35-0.7-0.5-1.28-0.51-1.3l-0.04-0.16h8.11l2.59,1.92l-1.42,0.46v25.25h8.58v10.22
						c0.02,0.11,0.58,0.3,1.29,0.39l0.11,0.01v3.86l-0.12,0.01L844.07,2303.57z M843.32,2297.78l1.01,0.55v4.95l18.7-1.38v-3.39
						c-0.5-0.07-1.41-0.25-1.41-0.64v-9.96h-8.58v-25.7l1.12-0.37l-2.11-1.56h-7.68c0.07,0.22,0.21,0.63,0.44,1.09
						c0.35,0.71,0.58,2.97,0.59,3.06l0,0.01v23.37l-0.02,0.03c-0.02,0.04-0.56,0.96-1.14,1.21c-0.38,0.16-0.73,0.12-0.93,0.06
						V2297.78z"/>
				</g>
				<g>
					<polygon className="st42" points="987.02,2240.01 978.04,2240.01 932.14,2275.91 935.11,2275.91 					"/>
				</g>
				<g>
					<path className="st50" d="M952.57,2302.66v-3.33h9.18l1.38-3.77v-5.88h4.13c0,0,5.85,0,5.97,0c-0.05,0,2.61-5.07,2.77-5.51
						c0.5-1.36,0.45-2.54,0.45-4.02c0-4.39,0-8.78,0-13.17c0-1.71,1.12-20.97,0-21.37c0,0-3.61-1.29-3.61-1.29l5.19-4.3h8.98
						l-3.21,3.29c0,0-0.92,1.61-0.69,2.98c0.23,1.38,0.69,36.28,0.69,36.28l2.07,2.07l1.15,3.21h3.44h2.12v6.83l4.31,0.88v3.77
						h-18.85v5.36L952.57,2302.66z"/>
					<path className="st26" d="M978.17,2304.83l-25.72-2.05v-3.58h9.22l1.34-3.66v-5.98h4.26c0,0,5.15,0,5.88,0
						c0.1-0.24,0.4-0.82,1.2-2.38c0.68-1.32,1.45-2.83,1.53-3.05c0.45-1.2,0.44-2.29,0.44-3.56c0-0.14,0-0.28,0-0.42v-13.17
						c0-0.35,0.05-1.43,0.11-2.92c0.13-2.95,0.32-7.41,0.37-11.23c0.03-2.11,0.01-3.77-0.05-4.95c-0.1-1.93-0.31-2.13-0.35-2.15
						c-0.01,0-3.6-1.29-3.6-1.29l-0.22-0.08l5.4-4.47h9.34l-3.42,3.5c-0.09,0.16-0.87,1.64-0.67,2.88
						c0.23,1.36,0.66,34.2,0.69,36.24l2.05,2.05l1.13,3.16h5.61v6.86l4.31,0.88v4.01h-18.85V2304.83z M952.71,2302.54l25.2,2.01
						v-5.35h18.85v-3.53l-4.31-0.88v-6.81h-5.53l-1.17-3.27l-2.08-2.08l0-0.05c0-0.35-0.46-34.91-0.69-36.26
						c-0.23-1.41,0.67-3,0.7-3.07l0.02-0.03l3-3.07h-8.62l-4.98,4.12c0.78,0.28,3.39,1.21,3.39,1.21c0.22,0.08,0.67,0.24,0.57,7.28
						c-0.05,3.85-0.25,8.34-0.37,11.31c-0.06,1.49-0.11,2.56-0.11,2.9v13.17c0,0.14,0,0.28,0,0.42c0.01,1.29,0.01,2.4-0.45,3.65
						c-0.09,0.24-0.83,1.68-1.55,3.08c-0.56,1.08-1.19,2.31-1.22,2.42c0.01-0.02,0.01-0.06-0.02-0.1l-0.1,0.07l0,0.13
						c-0.12,0-5.97,0-5.97,0h-4v5.77l-1.42,3.88h-9.14V2302.54z"/>
				</g>
				<g>
					<polygon className="st67" points="833.04,2240.29 839.99,2240.45 874.93,2271.48 930.99,2272.35 968.46,2240.37 972.47,2240.37 
						933.06,2273.08 874.51,2273.08 					"/>
				</g>
				<g>
					<path className="st66" d="M962.71,2296.73l-1.26-1.17v-5.68c0,0-3.37,0.58-3.71,0c-0.35-0.58-0.93-1.84-1.16-2.08
						s-0.81-24.26-0.81-24.26s1.56-1.74,1.74-2.79c0.18-1.04,0.18-1.04,0.18-1.04h-4.68l-3.37,2.7l2.18,0.66l0.23,24.73l-0.7,1.5
						l-1.28,0.7v3.6l-6.5,0.23v2.21l-1.28,0.7v1.68h-2.79v3.34l13.06,0.91v-3.33h9.18L962.71,2296.73z"/>
					<path className="st26" d="M952.71,2302.8l-13.32-0.93v-3.59h2.79v-1.63l1.28-0.7v-2.25l6.5-0.23v-3.55l1.31-0.71l0.67-1.44
						l-0.23-24.6l-2.32-0.7l3.6-2.89h4.88l-0.21,1.19c-0.18,0.99-1.5,2.54-1.74,2.81c0.16,6.53,0.6,23.34,0.79,24.14
						c0.15,0.18,0.41,0.66,0.67,1.18c0.17,0.33,0.35,0.68,0.49,0.91c0.19,0.31,1.97,0.22,3.58-0.06l0.15-0.03v5.78l1.28,1.19
						l-1.01,2.76h-9.14V2302.8z M939.65,2301.62l12.8,0.89v-3.32h9.22l0.89-2.43l-1.24-1.15v-5.58c-0.82,0.13-3.35,0.49-3.7-0.09
						c-0.14-0.24-0.32-0.58-0.5-0.92c-0.24-0.47-0.52-1.01-0.65-1.14c-0.07-0.07-0.18-0.19-0.53-12.31
						c-0.17-5.97-0.32-11.98-0.32-12.04l0-0.05l0.03-0.04c0.02-0.02,1.53-1.73,1.71-2.72l0.16-0.89h-4.48l-3.14,2.52l2.03,0.62
						l0.23,24.85l-0.73,1.57l-1.25,0.68v3.65l-6.5,0.23v2.16l-1.28,0.7v1.73h-2.79V2301.62z"/>
				</g>
				<g>
					<path className="st61" d="M889.99,2299.69h4.18v-2.35l-0.7-0.87c0,0,0-18.63,0-18.89s0.35-1.66,0.35-1.66l-3.83-0.01l0.52,0.89
						c0,0,0.44,0.35,0.44,0.96s0.26,18.71,0.26,18.71l-0.87,0.52l-0.35,0.78V2299.69z"/>
					<path className="st26" d="M894.3,2299.82h-4.44v-2.07l0.38-0.85l0.84-0.5c-0.02-1.28-0.26-18.06-0.26-18.64
						c0-0.54-0.38-0.85-0.39-0.86l-0.02-0.01l-0.01-0.02l-0.64-1.08l4.23,0.01l-0.04,0.16c-0.14,0.55-0.34,1.44-0.34,1.62v18.84
						l0.7,0.87V2299.82z M890.12,2299.55h3.92v-2.17l-0.7-0.87v-18.93c0-0.22,0.22-1.14,0.31-1.53l-3.43-0.01l0.39,0.67
						c0.09,0.08,0.46,0.45,0.46,1.04c0,0.6,0.26,18.53,0.26,18.71l0,0.08l-0.9,0.54l-0.32,0.72V2299.55z"/>
				</g>
				<g>
					<path className="st61" d="M911.49,2299.69h4.18v-2.35l-0.7-0.87c0,0,0-18.63,0-18.89s0.96-1.66,0.96-1.66l-4.44-0.01l0.52,0.89
						c0,0,0.44,0.35,0.44,0.96s0.26,18.71,0.26,18.71l-0.87,0.52l-0.35,0.78V2299.69z"/>
					<path className="st26" d="M915.8,2299.82h-4.44v-2.07l0.38-0.85l0.84-0.5c-0.02-1.28-0.26-18.06-0.26-18.64
						c0-0.54-0.38-0.85-0.39-0.86l-0.02-0.01l-0.01-0.02l-0.64-1.08l4.92,0.01l-0.14,0.2c-0.44,0.65-0.93,1.43-0.94,1.58v18.84
						l0.7,0.87V2299.82z M911.62,2299.55h3.92v-2.17l-0.7-0.87v-18.93c0-0.24,0.54-1.07,0.84-1.53l-3.96-0.01l0.39,0.67
						c0.09,0.08,0.46,0.45,0.46,1.04c0,0.6,0.26,18.53,0.26,18.71l0,0.08l-0.9,0.54l-0.32,0.72V2299.55z"/>
				</g>
				<g>
					<polygon className="st63" points="930.46,2275.91 932.14,2277.46 932.14,2289.76 932.14,2291.27 926.75,2291.27 926.75,2297.19 
						926.75,2299.69 931.39,2299.69 932.67,2298.41 932.67,2297.07 937.2,2296.61 937.2,2293.71 935.45,2293.24 934.76,2277.81 
						935.65,2275.53 					"/>
					<path className="st26" d="M931.45,2299.82h-4.83v-8.68h5.39v-13.62l-1.86-1.72l5.7-0.42l-0.96,2.45l0.69,15.31l1.75,0.47v3.12
						l-4.53,0.46v1.27L931.45,2299.82z M926.88,2299.55h4.46l1.2-1.2v-1.4l4.53-0.46v-2.68l-1.74-0.46l-0.7-15.56l0.83-2.11
						l-4.68,0.34l1.5,1.39v14h-5.39V2299.55z"/>
				</g>
				<g>
					<polygon className="st63" points="873.48,2275.91 871.8,2277.46 871.8,2289.76 871.8,2291.27 877.19,2291.27 877.19,2297.19 
						877.19,2299.69 872.55,2299.69 871.28,2298.41 871.28,2297.07 866.75,2296.61 866.75,2293.71 868.49,2293.24 869.19,2277.81 
						868.29,2275.53 					"/>
					<path className="st26" d="M877.33,2299.82h-4.83l-1.35-1.35v-1.27l-4.53-0.46v-3.12l1.75-0.47l0.69-15.31l-0.96-2.45l5.7,0.42
						l-1.86,1.72v13.62h5.39V2299.82z M872.61,2299.55h4.46v-8.15h-5.39v-14l1.5-1.39l-4.68-0.34l0.83,2.11l-0.7,15.56l-1.74,0.46
						v2.68l4.53,0.46v1.4L872.61,2299.55z"/>
				</g>
				<g>
					<rect x="873.63" y="2298.67" className="st27" width="59.04" height="1.28"/>
				</g>
				<g>
					<polygon className="st63" points="983.8,2282.57 976.32,2283.01 973.24,2289.68 963.14,2289.68 963.14,2295.56 961.76,2299.33 
						952.57,2299.33 954.02,2301.43 978.04,2302.19 978.04,2299.33 996.89,2299.33 996.89,2295.56 992.58,2294.68 992.58,2287.85 
						987.02,2287.85 985.87,2284.63 					"/>
					<path className="st26" d="M978.17,2302.32l-24.22-0.76l-1.63-2.36h9.34l1.34-3.66v-5.98l10.15,0l3.08-6.67l7.62-0.46l2.13,2.13
						l1.13,3.16h5.61v6.86l4.31,0.88v4.01h-18.85V2302.32z M954.09,2301.3l23.81,0.75v-2.85h18.85v-3.53l-4.31-0.88v-6.81h-5.53
						l-1.17-3.27l-2-2l-7.34,0.44l-3.09,6.68l-10.05,0v5.77l-1.42,3.88h-9.03L954.09,2301.3z"/>
				</g>
				<g>
					<polygon className="st27" points="996.93,2302.29 931.79,2298.74 931.05,2299.8 996.89,2303.44 					"/>
				</g>
				<g>
					<polygon className="st68" points="874.97,2301.16 930.81,2301.16 996.89,2306.12 996.89,2303.44 939.52,2300.09 931.39,2299.69 
						874.97,2299.69 809.2,2300.7 809.11,2306.9 					"/>
				</g>
				<g>
					<polygon className="st27" points="808.41,2301.04 874.25,2298.62 874.3,2300.03 808.46,2302.19 					"/>
				</g>
				<g>
					<path className="st50" d="M890.81,2268.22l0.4-0.77v-1.86c0,0-0.07-1.97,0-2.09c0.07-0.12,0-1.24,0-1.24l-0.4-0.58
						c0,0,0.12-0.62,0-0.81c-0.12-0.19-0.27-0.35,0-0.39c0.27-0.04,0.43,0,0.43,0l0.39-0.31v-0.89c0,0,0.02-0.46,0.45-0.35
						c0.44,0.12,0.71,0.15,0.67,0.62c-0.04,0.46,0,0.93,0,0.93l0.65,0.19c0,0,0.31,0.77,0.31,1.16c0,0.39,0.39,1.01,0.27,1.16
						c-0.12,0.15-0.23,1.43-0.27,1.82c-0.04,0.39-0.27,0.85-0.39,1.12c-0.12,0.27-0.12,1.59-0.12,1.59l0.35,0.7H890.81z"/>
					<path className="st26" d="M893.77,2268.35h-3.17l0.48-0.94v-1.83c-0.06-1.89-0.01-2.1,0.01-2.15c0.03-0.1,0.02-0.66-0.01-1.14
						l-0.41-0.59l0.01-0.05c0.05-0.27,0.07-0.63,0.02-0.72c-0.02-0.03-0.04-0.06-0.05-0.08c-0.08-0.12-0.15-0.24-0.1-0.36
						c0.05-0.12,0.2-0.14,0.24-0.14c0.19-0.03,0.33-0.02,0.41-0.01l0.29-0.23v-0.83c0-0.03,0.02-0.28,0.2-0.42
						c0.08-0.06,0.21-0.11,0.42-0.06l0.08,0.02c0.39,0.1,0.73,0.19,0.68,0.74c-0.03,0.33-0.01,0.66,0,0.82l0.63,0.19l0.02,0.06
						c0.01,0.03,0.32,0.8,0.32,1.21c0,0.16,0.08,0.39,0.16,0.59c0.11,0.3,0.19,0.51,0.09,0.65c-0.06,0.09-0.14,0.65-0.23,1.64
						l-0.01,0.11c-0.03,0.35-0.21,0.74-0.34,1.02c-0.02,0.05-0.04,0.1-0.06,0.14c-0.08,0.18-0.1,1-0.11,1.5L893.77,2268.35z
						 M891.03,2268.08h2.32l-0.27-0.53v-0.03c0-0.14,0-1.35,0.13-1.64c0.02-0.04,0.04-0.09,0.06-0.14c0.12-0.27,0.28-0.64,0.31-0.94
						l0.01-0.11c0.11-1.15,0.19-1.63,0.28-1.77c0.01-0.06-0.07-0.27-0.12-0.41c-0.08-0.22-0.18-0.47-0.18-0.68
						c0-0.3-0.21-0.87-0.28-1.05l-0.68-0.2l-0.01-0.09c0-0.02-0.04-0.48,0-0.95c0.03-0.31-0.08-0.35-0.49-0.46l-0.08-0.02
						c-0.09-0.02-0.15-0.02-0.2,0.01c-0.07,0.05-0.09,0.18-0.09,0.21l0,0.95l-0.49,0.39l-0.06-0.02c0,0-0.14-0.03-0.38,0
						c-0.01,0-0.02,0-0.02,0c0.02,0.03,0.04,0.07,0.06,0.1c0.02,0.03,0.04,0.06,0.06,0.09c0.12,0.2,0.06,0.67,0.03,0.85l0.39,0.57
						l0,0.04c0.04,0.74,0.04,1.17-0.01,1.3c-0.03,0.13-0.02,1.13,0.01,2.03l0,1.89L891.03,2268.08z M891.32,2263.56
						C891.32,2263.56,891.32,2263.56,891.32,2263.56C891.32,2263.56,891.32,2263.56,891.32,2263.56z M891.33,2263.55
						C891.32,2263.56,891.32,2263.56,891.33,2263.55C891.32,2263.56,891.32,2263.56,891.33,2263.55z M891.33,2263.54
						C891.33,2263.54,891.33,2263.54,891.33,2263.54S891.33,2263.54,891.33,2263.54z M893.87,2262.92L893.87,2262.92L893.87,2262.92
						z"/>
				</g>
				<g>
					<path className="st50" d="M912.17,2268.22c0,0-0.15-0.66,0-0.73c0.15-0.08,0.39-0.08,0.46-0.27c0.08-0.19,0.25-3.44,0.25-3.44
						v-1.35c0,0-0.34,0-0.39-0.35s0-1.32,0-1.32s0.45-0.04,0.6-0.23s0.19-0.58,0.19-0.58s0-0.04,0-0.46s0.15-0.73,0.42-0.56
						c0.27,0.17,0.47,0.37,0.47,0.37s0.08,0.77,0,0.89c-0.08,0.12,0.36,0.5,0.36,0.5s0.49,0.27,0.49,0.54s0.46,1.16,0.19,1.32
						c-0.27,0.15-0.46,1.01-0.46,1.01l0.35,2.43c0,0-0.41,0.39-0.57,0.7c-0.16,0.31-0.16,0.77-0.16,0.77l0.46,0.27v0.5H912.17z"/>
					<path className="st26" d="M914.97,2268.35h-2.9l-0.02-0.1c-0.12-0.5-0.1-0.8,0.07-0.88c0.06-0.03,0.11-0.05,0.17-0.06
						c0.12-0.04,0.2-0.06,0.23-0.14c0.05-0.17,0.18-2.18,0.24-3.4l0-1.23c-0.16-0.04-0.35-0.16-0.39-0.44c-0.05-0.36,0-1.3,0-1.34
						l0.01-0.11l0.11-0.01c0.15-0.01,0.43-0.08,0.51-0.18c0.1-0.13,0.15-0.4,0.16-0.51v-0.46c0-0.21,0.03-0.58,0.26-0.7
						c0.06-0.03,0.19-0.08,0.36,0.03c0.28,0.18,0.48,0.38,0.49,0.38l0.03,0.03l0,0.05c0.03,0.35,0.06,0.78-0.01,0.94
						c0.03,0.07,0.16,0.22,0.31,0.35c0.12,0.07,0.55,0.33,0.55,0.65c0,0.07,0.06,0.25,0.11,0.41c0.14,0.43,0.29,0.87,0.02,1.02
						c-0.15,0.08-0.32,0.57-0.4,0.9l0.35,2.47l-0.05,0.05c0,0-0.39,0.38-0.55,0.66c-0.11,0.21-0.14,0.5-0.15,0.64l0.46,0.27V2268.35
						z M912.27,2268.08h2.43v-0.3l-0.46-0.27v-0.08c0-0.02,0-0.5,0.18-0.83c0.14-0.26,0.43-0.57,0.55-0.68l-0.34-2.39l0.01-0.02
						c0.03-0.15,0.22-0.92,0.53-1.1c0.06-0.03,0.01-0.27-0.14-0.71c-0.07-0.2-0.12-0.37-0.12-0.49c0-0.13-0.25-0.33-0.43-0.43
						l-0.02-0.02c-0.5-0.45-0.42-0.61-0.38-0.67c0.03-0.08,0.02-0.44-0.01-0.76c-0.06-0.06-0.22-0.19-0.41-0.32
						c-0.03-0.02-0.07-0.04-0.1-0.02c-0.05,0.03-0.12,0.18-0.12,0.47l0,0.48c0,0.04-0.05,0.43-0.22,0.65
						c-0.14,0.17-0.42,0.24-0.58,0.27c-0.01,0.26-0.04,0.92,0,1.18c0.03,0.22,0.23,0.24,0.26,0.24l0.13,0l0,0.13v1.35
						c-0.04,0.77-0.18,3.29-0.26,3.49c-0.08,0.19-0.26,0.25-0.4,0.29c-0.04,0.01-0.08,0.03-0.12,0.04
						C912.22,2267.65,912.23,2267.87,912.27,2268.08z"/>
				</g>
				<g>
					<path className="st50" d="M941.26,2250.38c0.12-0.19,0.35-0.43,0.23-0.7c-0.12-0.27,0.04-0.35-0.23-0.46c-0.27-0.12-0.35,0-0.66,0
						c-0.31,0-0.66,0.18-0.66,0.55c0,0.37-0.12,0.57,0,0.76c0.12,0.19,0.19,0.39,0.27,0.58c0.08,0.19,0.12,0.08,0.08,0.39
						c-0.04,0.31-0.06,0.39-0.4,0.66s-0.26,0-0.34,0.27s0,1.2,0,1.2s-0.26,1.7-0.03,2.13c0.23,0.43,0.34,0.92,0.34,0.92
						s0.45,0.51,0.61,0.7c0.16,0.19,0.16,0.5,0.16,0.71c0,0.21-0.08,0.56,0,0.81c0.08,0.25,0.14,0.64-0.16,0.8
						c-0.3,0.15-0.42,0.19-0.49,0.42c-0.08,0.23-0.4-0.08-0.08,0.23c0.33,0.31,1.06-0.32,1.06-0.32l0.81-0.7c0,0,0.13-1.15,0-2
						s-0.21-3.87-0.21-3.87s-0.05,0.19,0.27-0.39c0.31-0.58,0.39,0,0.31-0.58c-0.08-0.58,0-0.81-0.15-1.2
						C941.84,2250.92,941.26,2250.38,941.26,2250.38z"/>
					<path className="st26" d="M940.13,2260.58c-0.12,0-0.23-0.03-0.32-0.12c-0.17-0.16-0.22-0.21-0.17-0.3
						c0.02-0.04,0.07-0.09,0.17-0.07c0.01,0,0.03,0,0.04,0.01c0,0,0,0,0-0.01c0.09-0.26,0.23-0.33,0.48-0.46l0.08-0.04
						c0.22-0.11,0.16-0.45,0.1-0.64c-0.06-0.2-0.04-0.43-0.02-0.64c0.01-0.08,0.01-0.16,0.01-0.22c0-0.2,0-0.47-0.13-0.63
						c-0.16-0.19-0.6-0.7-0.61-0.7l-0.02-0.02l-0.01-0.03c0,0-0.12-0.49-0.33-0.89c-0.24-0.44-0.02-1.96,0.02-2.19
						c-0.01-0.15-0.07-0.96,0.01-1.23c0.04-0.14,0.07-0.23,0.2-0.23c0.04,0,0.13-0.07,0.18-0.11c0.31-0.25,0.32-0.3,0.35-0.57
						c0.01-0.1,0.02-0.16,0.01-0.17c0,0,0,0-0.01-0.01c-0.02-0.03-0.04-0.06-0.07-0.14c-0.08-0.19-0.15-0.38-0.26-0.56
						c-0.1-0.16-0.07-0.31-0.05-0.49c0.01-0.1,0.03-0.21,0.03-0.34c0-0.45,0.41-0.69,0.79-0.69c0.1,0,0.18-0.01,0.25-0.03
						c0.13-0.03,0.26-0.05,0.46,0.04c0.22,0.1,0.25,0.21,0.26,0.34c0,0.06,0.01,0.11,0.04,0.19c0.12,0.28-0.05,0.53-0.17,0.71
						c-0.01,0.01-0.01,0.02-0.02,0.02c0.17,0.17,0.56,0.57,0.69,0.9c0.1,0.26,0.11,0.45,0.12,0.7c0.01,0.15,0.01,0.31,0.04,0.53
						c0.04,0.28,0.04,0.32-0.01,0.39c-0.05,0.05-0.11,0.05-0.13,0.05c-0.06,0-0.15,0.15-0.19,0.22c-0.11,0.21-0.19,0.34-0.25,0.42
						c0.01,0.52,0.09,3.01,0.2,3.76c0.13,0.86,0.01,1.98,0,2.03l-0.01,0.05l-0.85,0.73
						C940.98,2260.19,940.52,2260.58,940.13,2260.58z M940.03,2260.29c0.2,0.11,0.65-0.18,0.85-0.35l0.77-0.66
						c0.02-0.23,0.11-1.19,0-1.91c-0.12-0.8-0.2-3.43-0.21-3.84c-0.01-0.02-0.01-0.05,0-0.08l0.23-0.98l0.02,0.62
						c0.01-0.02,0.02-0.04,0.03-0.06c0.12-0.23,0.23-0.31,0.32-0.34c0-0.04-0.01-0.1-0.02-0.16c-0.03-0.23-0.04-0.4-0.04-0.55
						c-0.01-0.23-0.02-0.4-0.1-0.61c-0.12-0.3-0.55-0.74-0.7-0.88l-0.08-0.07l0.05-0.09c0.02-0.04,0.05-0.08,0.08-0.12
						c0.13-0.18,0.2-0.31,0.14-0.45c-0.05-0.12-0.06-0.21-0.06-0.28c-0.01-0.08-0.01-0.08-0.1-0.12c-0.13-0.06-0.2-0.04-0.31-0.02
						c-0.08,0.01-0.17,0.03-0.3,0.03c-0.21,0-0.53,0.11-0.53,0.42c0,0.15-0.02,0.28-0.03,0.38c-0.02,0.16-0.03,0.24,0.01,0.32
						c0.12,0.2,0.2,0.4,0.28,0.6c0.02,0.05,0.03,0.07,0.05,0.09c0.06,0.09,0.06,0.15,0.04,0.36c-0.04,0.34-0.09,0.45-0.45,0.74
						c-0.13,0.1-0.21,0.14-0.28,0.16c0,0.01-0.01,0.03-0.01,0.05c-0.06,0.2-0.02,0.9,0,1.15l0,0.02l0,0.02
						c-0.1,0.64-0.2,1.76-0.05,2.05c0.2,0.38,0.32,0.8,0.35,0.92c0.09,0.1,0.45,0.51,0.59,0.68c0.2,0.23,0.2,0.57,0.2,0.8
						c0,0.07-0.01,0.15-0.02,0.24c-0.02,0.18-0.04,0.39,0.01,0.53c0.14,0.46,0.06,0.81-0.23,0.95l-0.08,0.04
						c-0.25,0.12-0.3,0.15-0.35,0.31C940.09,2260.21,940.07,2260.26,940.03,2260.29z"/>
				</g>
				<g>
					<rect x="837.03" y="2285.58" className="st8" width="0.26" height="9.05"/>
				</g>
				<g>
					<polygon className="st8" points="824.4,2293.94 824.14,2293.94 824.14,2286.76 817.14,2286.76 817.14,2286.5 824.4,2286.5 					"/>
				</g>
				<g>
					<rect x="826.01" y="2296.94" className="st8" width="11.14" height="0.26"/>
				</g>
				<g>
					<rect x="826.01" y="2299.03" className="st8" width="11.14" height="0.26"/>
				</g>
				<g>
					
						<rect x="839.2" y="2286.51" transform="matrix(0.158 -0.9874 0.9874 0.158 -1553.2476 2755.9138)" className="st8" width="0.26" height="4.41"/>
				</g>
				<g>
					<rect x="826.58" y="2285.58" className="st8" width="0.26" height="8.36"/>
				</g>
				<g>
					<path className="st8" d="M812.92,2297.36c-0.82,0-1.56-0.04-1.92-0.16l0.08-0.25c1.01,0.34,5.3,0,5.34-0.01l0.02,0.26
						C816.33,2297.21,814.48,2297.36,812.92,2297.36z"/>
				</g>
				<g>
					<path className="st8" d="M815.25,2299.3c-0.03,0-0.06-0.01-0.08-0.02l0.12-0.23c-0.03-0.01-0.06-0.01-0.06,0
						c0.17-0.11,0.62-1.45,1.09-3.5c0.06-2.6,0.12-6.81,0.02-7.11c0.01,0.02,0.04,0.07,0.11,0.07v-0.26l0.1,0.04
						c0.06,0.06,0.14,0.14,0.1,3.75c-0.02,1.76-0.06,3.51-0.06,3.53l0,0.03c-0.24,1.05-0.85,3.51-1.24,3.69
						C815.3,2299.29,815.27,2299.3,815.25,2299.3z"/>
				</g>
				<g>
					<line className="st64" x1="820.71" y1="2245.2" x2="820.71" y2="2281.75"/>
					<rect x="820.57" y="2245.2" className="st65" width="0.26" height="36.56"/>
				</g>
				<g>
					<line className="st64" x1="815.87" y1="2243.63" x2="819.4" y2="2243.63"/>
					<rect x="815.87" y="2243.5" className="st65" width="3.53" height="0.26"/>
				</g>
				<g>
					<path className="st64" d="M821.75,2243.98c0,0,1.04-0.17,1.91,0s1.31,0,2.35,0.52s1.22,1.13,1.22,1.13"/>
					<path className="st65" d="M827.11,2245.67c0,0-0.18-0.57-1.15-1.05c-0.68-0.34-1.09-0.37-1.55-0.41c-0.23-0.02-0.47-0.04-0.76-0.1
						c-0.84-0.17-1.86,0-1.87,0l-0.04-0.26c0.04-0.01,1.08-0.18,1.96,0c0.28,0.06,0.51,0.07,0.73,0.09
						c0.47,0.04,0.92,0.08,1.65,0.44c1.08,0.54,1.28,1.19,1.29,1.21L827.11,2245.67z"/>
				</g>
				<g>
					<line className="st64" x1="815.22" y1="2277.84" x2="819.4" y2="2278.1"/>
					
						<rect x="817.18" y="2275.88" transform="matrix(0.0624 -0.9981 0.9981 0.0624 -1507.2083 2951.5776)" className="st65" width="0.26" height="4.19"/>
				</g>
				<g>
					<line className="st64" x1="815.22" y1="2280.45" x2="818.79" y2="2280.45"/>
					<rect x="815.22" y="2280.32" className="st65" width="3.57" height="0.26"/>
				</g>
				<g>
					<rect x="817.63" y="2294.94" className="st8" width="5.94" height="0.26"/>
				</g>
				<g>
					
						<rect x="831.63" y="2286.97" transform="matrix(0.0384 -0.9993 0.9993 0.0384 -1490.0164 3034.5845)" className="st8" width="0.26" height="9.06"/>
				</g>
				<g>
					<rect x="848.69" y="2262.87" className="st8" width="0.26" height="24.2"/>
				</g>
				<g>
					<rect x="852.87" y="2289.76" className="st8" width="0.26" height="5.8"/>
				</g>
				<g>
					<path className="st8" d="M860.99,2297.47c-0.61,0-1.34-0.08-1.56-0.11h-6.78v-0.26l6.8,0c0.81,0.1,2.05,0.17,2.26,0.02l0.15,0.22
						C861.72,2297.44,861.38,2297.47,860.99,2297.47z"/>
				</g>
				<g>
					<path className="st8" d="M859.27,2298.98c-0.75,0-1.61-0.04-1.66-0.04h-4.96v-0.26h4.96c0.03,0,2,0.09,2.59,0
						c0.62-0.09,1.56,0,1.6,0l-0.02,0.26c-0.01,0-0.95-0.08-1.54,0C860.03,2298.97,859.66,2298.98,859.27,2298.98z"/>
				</g>
				<g>
					
						<rect x="865.15" y="2283.48" transform="matrix(0.042 -0.9991 0.9991 0.042 -1447.8221 3057.2766)" className="st8" width="10.34" height="0.26"/>
				</g>
				<g>
					
						<rect x="860.35" y="2289.32" transform="matrix(0.0995 -0.995 0.995 0.0995 -1503.9664 2918.5415)" className="st8" width="0.26" height="1.75"/>
				</g>
				<g>
					<rect x="859.48" y="2288.37" className="st8" width="0.26" height="7.19"/>
				</g>
				<g>
					<rect x="846.43" y="2289.07" className="st8" width="0.26" height="4.7"/>
				</g>
				<g>
					<rect x="845.69" y="2288.24" className="st8" width="6.96" height="0.26"/>
				</g>
				<g>
					<path className="st8" d="M852.95,2263.6c-0.01,0-0.88-0.35-1.65-0.61c-0.73-0.24-1.29-0.01-1.3,0l-0.1-0.24
						c0.03-0.01,0.66-0.28,1.49,0c0.78,0.26,1.65,0.61,1.66,0.61L852.95,2263.6z"/>
				</g>
				<g>
					<rect x="845.08" y="2262.62" className="st8" width="2.79" height="0.26"/>
				</g>
				<g>
					<path className="st64" d="M977.18,2244.97c0,0,0.88-0.58,1.75-0.78c0.87-0.19,1.19-0.53,2.36-0.48c1.17,0.05,1.57,0.54,1.57,0.54"
						/>
					<path className="st65" d="M977.25,2245.08l-0.14-0.22c0.04-0.02,0.91-0.6,1.79-0.79c0.27-0.06,0.5-0.14,0.71-0.21
						c0.45-0.16,0.87-0.3,1.69-0.27c1.21,0.06,1.65,0.57,1.67,0.59l-0.2,0.17c0,0-0.39-0.44-1.48-0.49
						c-0.76-0.04-1.14,0.1-1.59,0.25c-0.22,0.08-0.45,0.16-0.74,0.22C978.13,2244.51,977.26,2245.07,977.25,2245.08z"/>
				</g>
				<g>
					<polygon className="st8" points="967.5,2294.38 967.24,2294.38 967.24,2290.59 977.93,2290.59 977.93,2290.85 967.5,2290.85 					
						"/>
				</g>
				<g>
					<rect x="968.65" y="2295.43" className="st8" width="9.28" height="0.26"/>
				</g>
				<g>
					<rect x="967.37" y="2296.94" className="st8" width="11.49" height="0.26"/>
				</g>
				<g>
					<rect x="963.31" y="2294.94" className="st8" width="3.02" height="0.26"/>
				</g>
				<g>
					<rect x="980.02" y="2285.92" className="st8" width="5.8" height="0.26"/>
				</g>
				<g>
					<rect x="979.89" y="2288.37" className="st8" width="0.26" height="5.4"/>
				</g>
				<g>
					<polygon className="st8" points="987.23,2297.34 986.97,2297.34 986.97,2295.57 985.69,2288.39 985.95,2288.35 987.23,2295.55 
											"/>
				</g>
				<g>
					
						<rect x="990.45" y="2294.88" transform="matrix(0.0564 -0.9984 0.9984 0.0564 -1358.8325 3156.6582)" className="st8" width="0.26" height="4.65"/>
				</g>
				<g>
					<rect x="988.26" y="2294.94" className="st8" width="4.64" height="0.26"/>
				</g>
				<g>
					<path className="st8" d="M976.68,2284.29l-0.05-0.26c0.07-0.01,1.7-0.32,2.23-0.32h5.16v0.26h-5.16
						C978.35,2283.97,976.69,2284.28,976.68,2284.29z"/>
				</g>
				<g>
					<rect x="952.38" y="2290.2" className="st8" width="0.26" height="6.18"/>
				</g>
				<g>
					<polygon className="st8" points="958.45,2297.96 958.1,2295.57 958.1,2295.56 958.27,2290.11 958.54,2290.11 958.36,2295.55 
						958.71,2297.93 					"/>
				</g>
				<g>
					<rect x="959.27" y="2295.43" className="st8" width="1.83" height="0.26"/>
				</g>
				<g>
					<rect x="959.71" y="2297.21" className="st8" width="2.18" height="0.26"/>
				</g>
				<g>
					<rect x="953.62" y="2294.51" className="st8" width="4" height="0.26"/>
				</g>
				<g>
					<rect x="945.52" y="2296.25" className="st8" width="6.18" height="0.26"/>
				</g>
				<g>
					<rect x="945.52" y="2297.55" className="st8" width="6.99" height="0.26"/>
				</g>
				<g>
					<path className="st8" d="M952.59,2263.22l-0.15-0.22c0.03-0.02,0.62-0.42,1.18-0.39c0.52,0.03,1.65,0.12,1.66,0.12l-0.02,0.26
						c-0.01,0-1.14-0.09-1.65-0.12C953.14,2262.86,952.59,2263.22,952.59,2263.22z"/>
				</g>
				<g>
					<polygon className="st8" points="951.81,2262.25 951.6,2262.09 952.45,2260.91 956.66,2260.91 956.66,2261.17 952.58,2261.17 					
						"/>
				</g>
				<g>
					<rect x="932.46" y="2277.36" className="st8" width="1.74" height="0.26"/>
				</g>
				<g>
					<path className="st8" d="M932.27,2277.08l-0.14-0.22c0.02-0.01,0.45-0.27,0.94-0.45c0.5-0.18,1.73,0.13,1.86,0.17l-0.07,0.25
						c-0.35-0.09-1.35-0.31-1.71-0.18C932.7,2276.82,932.27,2277.08,932.27,2277.08z"/>
				</g>
				<g>
					<rect x="932.9" y="2289.63" className="st8" width="1.74" height="0.26"/>
				</g>
				<g>
					<rect x="932.07" y="2292.38" className="st8" width="0.26" height="4"/>
				</g>
				<g>
					<rect x="927.33" y="2297.21" className="st8" width="3.74" height="0.26"/>
				</g>
				<g>
					<rect x="927.77" y="2297.9" className="st8" width="4.44" height="0.26"/>
				</g>
				<g>
					<rect x="912.8" y="2296.94" className="st8" width="2.18" height="0.26"/>
				</g>
				<g>
					<path className="st8" d="M893.37,2297.2c-0.48-0.05-1.8-0.14-2.21-0.01l-0.08-0.25c0.54-0.18,2.25-0.01,2.32-0.01L893.37,2297.2z"
						/>
				</g>
				<g>
					<rect x="891.21" y="2277.36" className="st8" width="1.74" height="0.26"/>
				</g>
				<g>
					<rect x="912.88" y="2277.36" className="st8" width="1.65" height="0.26"/>
				</g>
				<g>
					<rect x="869.91" y="2277.37" className="st8" width="1.74" height="0.26"/>
				</g>
				<g>
					<path className="st8" d="M871.84,2277.09c0,0-0.43-0.26-0.89-0.43c-0.36-0.13-1.35,0.08-1.71,0.18l-0.07-0.25
						c0.14-0.04,1.36-0.35,1.86-0.17c0.49,0.18,0.92,0.44,0.94,0.45L871.84,2277.09z"/>
				</g>
				<g>
					<rect x="869.47" y="2289.64" className="st8" width="1.74" height="0.26"/>
				</g>
				<g>
					<rect x="871.78" y="2292.39" className="st8" width="0.26" height="4"/>
				</g>
				<g>
					<rect x="873.04" y="2297.21" className="st8" width="3.74" height="0.26"/>
				</g>
				<g>
					<rect x="871.91" y="2297.91" className="st8" width="4.44" height="0.26"/>
				</g>
			</g>
			<g>
				<g>
					<path className="st2" d="M1582.1,2366.99c-0.63-0.43-1.31-0.77-2.06-0.97c-1.59-0.42-3.2-0.43-4.8-0.15
						c-1.58,0.27-2.92-0.01-4.05-1.22c-0.85-0.91-1.96-1.26-3.22-1.24c-3.1,0.03-6.19,0.01-9.29,0.01c0.85,0.92,1.63,1.88,1.9,3.22
						c-1.7,0.15-3.37,0.17-5,0.51c-1.73,0.98-3.49,1.9-5.35,2.64c-0.37,0.18-0.75,0.34-1.13,0.5c-1.28,0.53-2.58,0.94-3.92,1.21
						c-0.07,0.01-0.14,0.03-0.21,0.04c-0.37,0.07-0.73,0.13-1.1,0.17c-1,0.12-2.02,0.17-3.06,0.12c-1.51,0.46-3.1,0.24-4.64,0.51
						c-2.01,0.35-4.05,0.4-6.09,0.5c0.06,0.19,0.11,0.38,0.16,0.57c0.16,0.57,0.32,1.15,0.51,1.71c0.21,0.63,0.19,1-0.61,1.12
						c-1.73,0.27-3.36,1.03-5.13,1.18c-2.86,0.22-5.72,0.44-8.58,0.66c0,0,0,0,0,0c0.1,0.59,0.19,1.19,0.29,1.78
						c0.03,0.2,0.07,0.39,0.11,0.59c0.05,0.27-25.78-4.5-34.9-2.37c-1.58,0.37,1.65,10.16,3.21,10.54
						c0.16,0.04,24.94-1.72,25.09-1.66l9.48,2.65c0.3-0.38,13.83-4.82,14.29-5.17c1.24-0.94,1.44-1.37,2.9-1.8
						c8.83-2.61,18.57-5.69,27.76-6.41c1.2-0.09,2.41-0.31,3.53-0.83c0.37-0.17,0.7-0.36,0.72-0.8c0.02-0.48-4.13-1.27-4.52-1.45
						c-0.13-0.06-0.27-0.11-0.41-0.16c-1.51-0.5-0.43-0.62-1.94-1.15c-0.42-0.15-1.05-0.34-1.04-0.81c0-0.33,0.25-0.47,0.54-0.56
						c0.77-0.23,2.05-0.45,2.85-0.52c6.62-0.62,10.65-1.1,17.28-1.41c0.47-0.02,1.21,0.3,1.33-0.28
						C1583.11,2367.83,1582.56,2367.3,1582.1,2366.99z"/>
				</g>
				<g>
					<g>
						<path className="st7" d="M1558.29,2366.37c0.27,0.07,0.53,0.14,0.8,0.23c0.11,0.04,0.23,0.07,0.34,0.11
							c0.06,0.02,0.12,0.05,0.18,0.07c0.23,0.08-0.36-0.17-0.14-0.06c0.15,0.07,0.31,0.14,0.45,0.23c0.18,0.11,0.16,0.24-0.1-0.09
							c0.04,0.04,0.1,0.09,0.14,0.13c0.17,0.14-0.25-0.38-0.13-0.17c0.14,0.24-0.13-0.37-0.09-0.22c0.06,0.23-0.03-0.49-0.03-0.26
							c0,0.23,0.11-0.5,0.03-0.23c-0.05,0.15,0.23-0.46,0.09-0.22c-0.14,0.24,0.25-0.27,0.13-0.16c-0.08,0.07-0.4,0.35-0.13,0.15
							c0.28-0.21-0.13,0.08-0.22,0.13c-0.15,0.09-0.31,0.17-0.46,0.25c-0.17,0.08-0.34,0.15-0.51,0.24
							c0.51-0.24,0.14-0.06,0.02-0.01c-0.12,0.05-0.24,0.09-0.36,0.14c-0.94,0.35-1.9,0.64-2.87,0.92
							c-1.97,0.56-3.97,1.02-5.96,1.54c-1.38,0.36-2.74,0.74-4.15,0.99c-0.48,0.08-0.96,0.16-1.44,0.23c0.02,0-0.45,0.06-0.23,0.03
							c0.22-0.03-0.26,0.03-0.23,0.03c-0.29,0.04-0.58,0.07-0.87,0.1c-0.97,0.11-1.93,0.2-2.9,0.29c-1.88,0.17-3.82,0.22-5.66,0.64
							c-0.61,0.14-1.22,0.29-1.84,0.4c-0.14,0.03-0.29,0.05-0.43,0.08c-0.14,0.02-0.36,0,0.11-0.01c-0.06,0-0.13,0.02-0.2,0.02
							c-0.11,0.01-0.22,0.01-0.33,0.01c-0.03,0-0.06-0.01-0.09-0.01c0.18-0.03,0.49,0.1,0.58,0.15c-0.64-0.4-1.5-0.18-1.94,0.39
							c-0.42,0.54-0.47,1.51,0.12,1.98c0.05,0.04,0.1,0.08,0.14,0.12c-0.13-0.19-0.16-0.22-0.07-0.09c0.07,0.14,0.06,0.1-0.04-0.11
							c0.02,0.06,0.04,0.12,0.05,0.18c-0.03-0.25-0.03-0.29-0.02-0.12c0,0.03,0,0.06,0,0.09c-0.01,0.17,0,0.13,0.03-0.11
							c-0.01,0.06-0.03,0.13-0.04,0.19c-0.02,0.06-0.04,0.12-0.06,0.19c0.09-0.21,0.1-0.24,0.03-0.09
							c-0.02,0.04-0.05,0.08-0.08,0.12c-0.06,0.09-0.03,0.06,0.09-0.11c0-0.01-0.41,0.48-0.11,0.13c-0.09,0.1-0.27,0.18-0.39,0.24
							c-0.1,0.05-0.31,0.23-0.06,0.03c-0.1,0.08-0.32,0.11-0.44,0.15c-0.26,0.08-0.53,0.14-0.8,0.19c-0.71,0.15-1.43,0.27-2.15,0.39
							c-0.34,0.05-0.68,0.1-1.02,0.15c0.3-0.04-0.28,0.03-0.33,0.04c-0.2,0.02-0.39,0.05-0.59,0.07c-0.68,0.07-1.37,0.14-2.06,0.18
							c-1.47,0.1-2.95,0.13-4.42,0.1c-1.12-0.02-1.91,1.3-1.3,2.27c0.01,0.01,0.08,0.12,0.08,0.13c0.01,0.01,0.02,0.03,0.02,0.04
							c-0.03-0.06-0.05-0.12-0.08-0.18c0,0,0.04,0.13,0.04,0.14c-0.06,0.17,0.04-0.2-0.02-0.16c0,0-0.01,0.29,0,0.29
							c-0.03-0.02,0.08-0.3,0.01-0.16c-0.02,0.05-0.05,0.14-0.05,0.19c-0.01,0.14,0.04-0.1,0.06-0.12c-0.03,0.03-0.05,0.1-0.07,0.14
							c-0.05,0.08-0.17,0.22-0.19,0.3c0-0.02,0.19-0.21,0.07-0.1c-0.04,0.03-0.07,0.08-0.11,0.11c-0.04,0.04-0.08,0.07-0.11,0.1
							c-0.13,0.13,0.22-0.13,0.06-0.05c-0.1,0.05-0.2,0.11-0.3,0.17c-0.15,0.08,0.04-0.02,0.07-0.03c-0.06,0.02-0.12,0.04-0.18,0.06
							c-0.12,0.04-0.24,0.07-0.37,0.1c-0.06,0.01-0.13,0.02-0.19,0.04c0.13-0.03,0.19-0.02,0.02,0c-0.54,0.05-1.08,0.04-1.62,0.02
							c-2.49-0.1-4.98-0.21-7.47-0.31c-4.77-0.21-9.54-0.42-14.31-0.62c-2.8-0.12-5.6-0.24-8.41-0.35c-0.79-0.03-1.95,1.5-1.39,2.06
							c1.42,1.41,2.04,5,2.81,5.14c16.36,2.96,20.35,1.23,25.51,1.45l6.2-0.84c0,0,2.23-0.35,4.81-0.33
							c1.47,0.01,5.85-1.36,6.89-2.5c1.01-1.12-3.25-2.44-3.7-2.91l-2.21-0.51c2.7,0.05,7.36,0.94,10.03,0.58
							c1.24-0.17,4.24-1.18,5.47-1.47c1.22-0.29-1.5-1.27-0.82-2.37c0.74-1.19-3.66-3.4-4.82-4.33c-0.61,0.79-1.22,1.58-1.83,2.37
							c0.62,0.39,1.41,0.26,2.09,0.16c0.6-0.09,1.19-0.22,1.78-0.36c0.26-0.06,0.6-0.38,0.77-0.18c2.01,2.34,1.95,2.25,2.01,2.34
							l0,0c0.47,0.01,2.89-1.04,3.37-1.08c0.58-0.06,3.68,0.8,4.27,0.75c2.17-0.19,0.19-2.08,2.35-2.39
							c1.73-0.25,4.38-0.59,6.07-1.04c2.07-0.55,4.45-0.72,6.51-1.3c1.03-0.29,0.83-1.36,1.84-1.75c0.93-0.35,6.52-0.84,7.29-1.48
							c0.84-0.7,1.6-1.86,0.95-2.82c-0.73-1.06-9.28-1.72-10.46-2.02c-0.76-0.2-1.67,0.25-1.85,1.05
							C1557.06,2365.3,1557.47,2366.16,1558.29,2366.37L1558.29,2366.37z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st21" d="M1508.58,2304.35c0.47,1.65,0.94,3.29,1.4,4.94c0.39,1.35,0.81,2.7,1.2,4.06
							c-0.39-1.36-0.8-2.7-1.2-4.06C1509.52,2307.64,1509.05,2306,1508.58,2304.35z"/>
					</g>
					<g>
						<path className="st21" d="M1530.1,2372.83c0.06,0.19,0.11,0.38,0.16,0.57C1530.21,2373.21,1530.15,2373.02,1530.1,2372.83
							c-0.29-1.07-0.58-2.15-0.85-3.22C1529.52,2370.69,1529.81,2371.76,1530.1,2372.83z"/>
					</g>
					<g>
						<path className="st21" d="M1544.99,2371.53c-0.37,0.07-0.73,0.13-1.1,0.17C1544.25,2371.66,1544.62,2371.6,1544.99,2371.53z"/>
					</g>
					<g>
						<path className="st21" d="M1514.09,2367.55c-0.31-1.38-0.61-2.75-0.91-4.13C1513.47,2364.8,1513.78,2366.18,1514.09,2367.55z"/>
					</g>
					<g>
						<path className="st21" d="M1549.12,2370.29c-1.28,0.53-2.58,0.94-3.92,1.21C1546.53,2371.23,1547.84,2370.82,1549.12,2370.29z"/>
					</g>
					<g>
						<path className="st9" d="M1482.71,2284.44l-0.78,95.92l35.13,1.12c-0.07-0.34-0.14-0.69-0.21-1.03c-0.04-0.2-0.08-0.39-0.11-0.59
							c-0.1-0.59-0.19-1.19-0.29-1.78l0,0c-0.79-3.51-1.58-7.02-2.36-10.53c-0.31-1.38-0.61-2.75-0.91-4.13
							c-0.14-0.65-0.14-1.35-0.14-2.02c-0.01-9.69-0.02-19.37,0-29.06c0.01-2.76-0.14-5.51-0.46-8.25c-0.05-0.4-0.01-0.82,0.19-1.37
							c0.46,1.56,1.99,1.9,2.85,2.89c0.78,0.91,0.98,2.24,0.53,3.5c-0.34,0.94-0.43,1.75,0.89,1.95c0.45,0.07,0.67,0.48,0.89,0.85
							c1.08,1.87,2.15,3.75,3.27,5.6c0.37,0.61,0.54,1.22,0.53,1.92c-0.02,2.34,0.1,4.68-0.03,7.01c-0.17,3.19,0.38,6.25,1.38,9.24
							c0.24,0.73,0.11,1.31-0.63,1.54c-0.71,0.22-0.81,0.71-0.71,1.28c0.22,1.29-0.49,2.06-1.41,2.77c-2.59,2-2.67,2.24,0.3,3.3
							c2.35,0.84,2.94,2.35,3.16,4.53c0.28,2.78,0.82,5.53,1.25,8.3c1.77-0.15,3.39-0.91,5.13-1.18c0.81-0.12,0.83-0.49,0.61-1.12
							c-0.19-0.56-0.35-1.14-0.51-1.71c-0.05-0.19-0.11-0.38-0.16-0.57c-0.29-1.07-0.58-2.15-0.85-3.22
							c-0.59-2.37-1.08-4.76-1.17-7.22c-0.17-4.62-0.76-9.19-1.88-13.68c-0.44-1.75-0.78-3.53-1.18-5.38
							c2.6,2.21,2.94,5.33,3.78,8.21c0.24,0.83,0.54,1.61,1.19,2.2c3.34,3.05,5.47,6.97,7.76,10.77c1.05,1.75,1.9,3.58,2.64,5.48
							c0.23,0.59,0.51,1.17,0.44,1.84c1.04,0.05,2.06,0.01,3.06-0.12c0.37-0.05,0.74-0.1,1.1-0.17c0.07-0.01,0.14-0.03,0.21-0.04
							c1.34-0.27,2.64-0.67,3.92-1.21c0.38-0.16,0.75-0.32,1.13-0.5c-2.2-2.59-1.71-6.62-3.31-10.2c-0.3-0.67-1.53-1.17-1.73-1.89
							c-0.4-1.43-0.73-2.95-0.65-4.61c1.02,1.69,1.55,3.34,2.4,4.81c0.39,0.68,0.93,1.18,1.49,1.71c2.15,2.03,4.64,3.7,6.48,6.06
							c0.35,0.44,0.67,0.87,0.66,1.47c1.63-0.34,3.3-0.36,5-0.51c-0.28-1.34-1.06-2.3-1.9-3.22c-0.92-0.69-1.41-1.6-1.65-2.74
							c-0.32-1.48-0.95-2.88-1.17-4.39c-0.39-2.01-1.73-3.29-3.34-4.35c-0.54-0.35-0.73-0.84-0.78-1.49
							c-0.15-1.81-0.38-3.61-0.59-5.41c-0.56-5.04-1.13-10.08-1.69-15.13c-0.2-1.85,0.02-3.86-0.64-5.51
							c-1.46-3.65-2.43-7.44-3.8-11.11c-0.56-1.49-0.79-3.11-1.62-4.5c-4,0.9-7.87,2.14-11.44,4.21c-0.92,0.53-1.81,1.11-2.43,2
							c-0.78,1.12-0.63,1.9,0.49,2.68c1.41,0.98,3.03,1.49,4.6,2.13c0.94,0.38,1.89,0.76,2.79,1.21c0.48,0.24,1.19,0.51,0.02,1
							c-0.53,0.22-1,0.36-1.53,0.36c-5.31,0.04-10.42-1.08-15.46-2.63c-2.45-0.76-4.81-1.76-7.18-2.72
							c-0.85-0.34-1.33-0.84-1.56-1.77c-0.06-0.25-0.13-0.5-0.19-0.75c-0.1-0.39-0.21-0.78-0.32-1.17c-0.39-1.36-0.8-2.7-1.2-4.06
							c-0.47-1.65-0.94-3.29-1.4-4.94c-0.21-0.77-0.43-1.54-0.64-2.31c-0.14-1.48-0.28-2.96-0.42-4.44
							c-0.19-1.59-0.38-3.18-0.56-4.77c-5.74-1.75-11.48-3.5-17.22-5.25c-2.02-0.61-4.02-1.26-6.05-1.84
							C1482.98,2285.55,1482.62,2285.26,1482.71,2284.44c0.1-0.88,0.06-1.78,0.07-2.67"/>
						<path className="st8" d="M1517.47,2381.83l-35.88-1.15l0-0.33l0.78-95.5c-0.01-0.13-0.01-0.28,0.01-0.45
							c0.07-0.59,0.07-1.22,0.07-1.82c0-0.27,0-0.55,0.01-0.82l0.67,0.01c-0.01,0.27-0.01,0.54-0.01,0.81c0,0.61,0,1.25-0.07,1.87
							l0,0.39c0.05,0.3,0.26,0.45,0.75,0.59c1.38,0.39,2.77,0.83,4.12,1.24c0.64,0.2,1.29,0.4,1.93,0.6
							c4.07,1.24,8.14,2.48,12.21,3.72l5.22,1.59l0.59,4.98l0.41,4.42l0.63,2.28l1.4,4.94c0.17,0.57,0.34,1.14,0.51,1.72
							c0.23,0.77,0.47,1.56,0.69,2.34c0.11,0.39,0.22,0.78,0.32,1.17c0.07,0.25,0.13,0.5,0.2,0.76c0.2,0.79,0.58,1.22,1.36,1.54
							c0.41,0.17,0.82,0.33,1.23,0.5c1.92,0.78,3.91,1.59,5.92,2.21c5.9,1.82,10.78,2.65,15.36,2.61c0.43,0,0.85-0.1,1.41-0.34
							c0.19-0.08,0.3-0.14,0.36-0.19c-0.09-0.06-0.22-0.12-0.31-0.16l-0.1-0.05c-0.8-0.4-1.65-0.74-2.47-1.08l-0.3-0.12
							c-0.34-0.14-0.67-0.27-1.01-0.4c-1.24-0.48-2.52-0.97-3.66-1.77c-1.28-0.9-1.46-1.87-0.57-3.14c0.67-0.97,1.66-1.59,2.54-2.1
							c3.16-1.84,6.83-3.19,11.53-4.25l0.24-0.05l0.12,0.21c0.57,0.94,0.86,1.98,1.15,2.98c0.15,0.52,0.31,1.06,0.5,1.58
							c0.62,1.66,1.16,3.36,1.69,5.01c0.64,2.01,1.31,4.09,2.1,6.09c0.47,1.17,0.51,2.5,0.56,3.79c0.02,0.6,0.04,1.22,0.11,1.81
							c0.4,3.61,0.8,7.23,1.21,10.84l0.48,4.29c0.06,0.51,0.12,1.02,0.18,1.53c0.15,1.27,0.3,2.59,0.41,3.89
							c0.05,0.63,0.23,0.97,0.63,1.24c1.39,0.92,3.04,2.26,3.49,4.57c0.12,0.85,0.38,1.67,0.63,2.47c0.19,0.62,0.4,1.26,0.54,1.91
							c0.25,1.14,0.73,1.95,1.53,2.54l0.05,0.04c0.84,0.91,1.68,1.92,1.99,3.38l0.08,0.37l-0.38,0.03c-0.48,0.04-0.95,0.07-1.42,0.1
							c-1.23,0.08-2.39,0.16-3.55,0.4l-0.41,0.09l0.01-0.42c0.01-0.48-0.27-0.84-0.59-1.25c-1.18-1.52-2.67-2.77-4.12-3.98
							c-0.78-0.65-1.58-1.33-2.33-2.04l-0.05-0.05c-0.54-0.51-1.09-1.03-1.5-1.73c-0.4-0.69-0.72-1.4-1.06-2.15
							c-0.22-0.49-0.44-0.98-0.7-1.48c0.09,1.16,0.35,2.23,0.62,3.17c0.08,0.28,0.44,0.57,0.78,0.84c0.37,0.3,0.76,0.61,0.93,0.99
							c0.69,1.55,0.99,3.15,1.29,4.71c0.39,2.05,0.76,3.98,1.97,5.41l0.28,0.33l-0.39,0.19c-0.38,0.18-0.76,0.35-1.14,0.51
							c-1.32,0.55-2.67,0.96-3.98,1.22c-0.07,0.01-0.14,0.03-0.21,0.04c-0.39,0.07-0.76,0.13-1.12,0.18
							c-1.03,0.13-2.08,0.17-3.11,0.12l-0.35-0.02l0.04-0.35c0.05-0.5-0.14-0.97-0.33-1.47c-0.03-0.07-0.06-0.14-0.08-0.22
							c-0.8-2.05-1.65-3.83-2.61-5.42c-0.27-0.44-0.53-0.88-0.79-1.33c-1.96-3.3-3.99-6.71-6.91-9.37
							c-0.72-0.66-1.05-1.52-1.29-2.35c-0.16-0.54-0.3-1.09-0.44-1.63c-0.53-2.07-1.03-4.05-2.46-5.66
							c0.05,0.22,0.09,0.44,0.14,0.66c0.26,1.26,0.51,2.44,0.81,3.63c1.08,4.33,1.72,8.95,1.89,13.75c0.09,2.59,0.64,5.07,1.16,7.15
							c0.21,0.84,0.44,1.69,0.66,2.52l0.19,0.7c0.05,0.18,0.11,0.38,0.16,0.57l0.03,0.12c0.15,0.52,0.3,1.06,0.47,1.57
							c0.13,0.37,0.22,0.76,0.02,1.09c-0.2,0.33-0.61,0.43-0.9,0.48c-0.75,0.12-1.51,0.34-2.24,0.55c-0.93,0.27-1.9,0.55-2.9,0.64
							l-0.31,0.03l-0.05-0.31c-0.13-0.83-0.27-1.68-0.41-2.49c-0.32-1.9-0.65-3.87-0.84-5.82c-0.21-2.1-0.76-3.47-2.94-4.25
							c-1.59-0.57-2.35-0.9-2.45-1.52c-0.1-0.66,0.63-1.26,2.06-2.36c0.93-0.72,1.46-1.37,1.28-2.45c-0.15-0.86,0.17-1.42,0.94-1.66
							c0.23-0.07,0.37-0.17,0.44-0.32c0.12-0.23,0.05-0.56-0.03-0.8c-1.1-3.32-1.56-6.39-1.4-9.37c0.08-1.48,0.06-3,0.04-4.47
							c-0.01-0.83-0.02-1.68-0.01-2.52c0.01-0.64-0.15-1.19-0.48-1.75c-0.91-1.5-1.8-3.05-2.66-4.54l-0.64-1.1
							c-0.22-0.38-0.37-0.61-0.63-0.65c-0.61-0.09-1.01-0.32-1.23-0.68c-0.3-0.51-0.13-1.15,0.07-1.72
							c0.41-1.14,0.24-2.36-0.46-3.17c-0.28-0.33-0.66-0.58-1.05-0.85c-0.49-0.33-1.02-0.7-1.42-1.25c0,0.11,0,0.22,0.01,0.33
							c0.31,2.66,0.47,5.45,0.46,8.29c-0.02,9.29-0.01,18.73,0,27.87l0,1.19c0,0.66,0,1.34,0.14,1.95c0.3,1.38,0.61,2.75,0.91,4.12
							c0.53,2.37,1.06,4.73,1.59,7.1c0.26,1.14,0.51,2.29,0.77,3.43l0,0.02c0.04,0.25,0.08,0.5,0.12,0.75
							c0.06,0.34,0.11,0.68,0.17,1.02c0.03,0.2,0.07,0.39,0.11,0.58L1517.47,2381.83z M1482.27,2380.03l34.38,1.1l-0.12-0.62
							c-0.04-0.2-0.08-0.4-0.11-0.6c-0.06-0.34-0.12-0.69-0.17-1.03c-0.04-0.25-0.08-0.49-0.12-0.74c-0.26-1.14-0.51-2.28-0.77-3.42
							c-0.53-2.37-1.07-4.73-1.59-7.1c-0.31-1.38-0.61-2.75-0.91-4.13c-0.15-0.69-0.15-1.4-0.15-2.09l0-1.2
							c-0.01-9.13-0.02-18.58,0-27.87c0.01-2.81-0.15-5.57-0.45-8.21c-0.06-0.49,0.01-0.96,0.21-1.53l0.35-0.96l0.29,0.98
							c0.26,0.88,0.91,1.32,1.6,1.8c0.41,0.28,0.84,0.58,1.18,0.97c0.86,0.99,1.08,2.46,0.59,3.83c-0.14,0.39-0.29,0.88-0.13,1.15
							c0.1,0.18,0.36,0.3,0.75,0.36c0.6,0.09,0.88,0.58,1.11,0.98l0.64,1.1c0.86,1.49,1.75,3.03,2.65,4.53
							c0.4,0.66,0.59,1.33,0.58,2.1c-0.01,0.83,0,1.69,0.01,2.51c0.02,1.48,0.04,3.01-0.05,4.51c-0.16,2.89,0.29,5.88,1.36,9.12
							c0.17,0.52,0.17,0.97-0.01,1.31c-0.16,0.31-0.44,0.53-0.84,0.65c-0.44,0.14-0.57,0.38-0.48,0.91
							c0.25,1.48-0.59,2.36-1.53,3.09c-0.74,0.57-1.85,1.43-1.8,1.72c0.05,0.29,1.38,0.77,2.01,0.99c2.5,0.89,3.15,2.5,3.38,4.82
							c0.19,1.93,0.52,3.89,0.84,5.78c0.12,0.72,0.24,1.45,0.36,2.19c0.84-0.11,1.66-0.35,2.47-0.58c0.75-0.22,1.53-0.44,2.33-0.57
							c0.34-0.05,0.41-0.14,0.43-0.16c0.03-0.05,0.04-0.18-0.08-0.52c-0.18-0.53-0.34-1.08-0.48-1.6l-0.03-0.12
							c-0.05-0.19-0.11-0.38-0.16-0.57l-0.19-0.71c-0.22-0.83-0.45-1.68-0.66-2.53c-0.53-2.12-1.09-4.64-1.18-7.29
							c-0.17-4.75-0.8-9.33-1.87-13.61c-0.3-1.21-0.55-2.4-0.81-3.66c-0.12-0.57-0.24-1.15-0.37-1.73l-0.21-0.96l0.75,0.64
							c2.2,1.87,2.84,4.36,3.45,6.76c0.14,0.53,0.28,1.08,0.43,1.61c0.25,0.86,0.53,1.53,1.1,2.05c2.99,2.73,5.04,6.18,7.03,9.52
							c0.26,0.44,0.53,0.88,0.79,1.32c0.98,1.63,1.85,3.44,2.66,5.53c0.03,0.07,0.05,0.14,0.08,0.21c0.18,0.45,0.36,0.91,0.39,1.43
							c0.89,0.03,1.79-0.02,2.67-0.13c0.35-0.04,0.71-0.1,1.08-0.17c0.07-0.01,0.14-0.03,0.21-0.04c1.28-0.25,2.57-0.65,3.86-1.19
							c0.24-0.1,0.49-0.21,0.73-0.32c-1.11-1.51-1.47-3.39-1.84-5.38c-0.29-1.52-0.59-3.08-1.24-4.56c-0.11-0.24-0.43-0.5-0.74-0.74
							c-0.43-0.35-0.88-0.7-1.01-1.19c-0.38-1.34-0.75-2.95-0.67-4.71l0.05-1.1l0.57,0.94c0.57,0.93,0.98,1.85,1.38,2.73
							c0.33,0.74,0.65,1.43,1.03,2.09c0.35,0.61,0.85,1.08,1.38,1.58l0.05,0.05c0.74,0.7,1.53,1.37,2.3,2.01
							c1.47,1.23,2.99,2.51,4.21,4.08c0.28,0.36,0.59,0.76,0.69,1.28c1.09-0.19,2.2-0.27,3.27-0.34c0.33-0.02,0.67-0.05,1.01-0.07
							c-0.32-1.09-1.02-1.92-1.71-2.67c-0.92-0.7-1.48-1.62-1.75-2.92c-0.13-0.62-0.33-1.25-0.52-1.85
							c-0.26-0.82-0.53-1.67-0.65-2.56c-0.31-1.61-1.27-2.85-3.2-4.12c-0.74-0.49-0.89-1.19-0.93-1.75c-0.1-1.29-0.26-2.6-0.41-3.86
							c-0.06-0.51-0.12-1.03-0.18-1.54l-0.48-4.29c-0.41-3.61-0.81-7.23-1.21-10.84c-0.07-0.61-0.09-1.25-0.11-1.86
							c-0.04-1.29-0.08-2.5-0.51-3.56c-0.81-2.02-1.47-4.11-2.12-6.13c-0.52-1.64-1.07-3.34-1.68-4.98
							c-0.2-0.54-0.36-1.09-0.51-1.63c-0.27-0.92-0.52-1.8-0.96-2.61c-4.52,1.03-8.05,2.34-11.1,4.12
							c-0.85,0.49-1.73,1.05-2.33,1.91c-0.68,0.97-0.57,1.53,0.4,2.21c1.07,0.75,2.31,1.23,3.52,1.69c0.34,0.13,0.68,0.26,1.02,0.4
							l0.29,0.12c0.83,0.34,1.7,0.69,2.52,1.1l0.09,0.05c0.34,0.17,0.76,0.37,0.75,0.77c-0.01,0.39-0.43,0.62-0.84,0.79
							c-0.64,0.27-1.14,0.39-1.66,0.39c-4.65,0.03-9.6-0.81-15.56-2.64c-2.04-0.63-4.04-1.44-5.98-2.23
							c-0.41-0.17-0.82-0.33-1.23-0.5c-0.98-0.4-1.51-0.99-1.76-2c-0.06-0.25-0.13-0.5-0.19-0.75c-0.1-0.39-0.21-0.78-0.32-1.16
							c-0.22-0.78-0.46-1.57-0.69-2.33c-0.17-0.57-0.34-1.15-0.51-1.72l-1.4-4.94l-0.65-2.37l-0.42-4.44l-0.54-4.54l-4.81-1.46
							c-4.07-1.24-8.14-2.48-12.21-3.72c-0.65-0.2-1.29-0.4-1.93-0.6c-1.34-0.42-2.73-0.85-4.11-1.24c-0.2-0.06-0.4-0.13-0.58-0.23
							L1482.27,2380.03z"/>
					</g>
					<g>
						<path className="st8" d="M1507.55,2381.14l9.51,0.34l-4.37-1.37c0.19-0.35-0.79-0.62-0.99-0.96c-0.84-1.44-2.53-2.1-3.95-2.99
							c-1.42-0.89-2.75-2.5-2.2-4.08c0.27-0.76-0.25-1.32,0.2-1.99s1.82-3.35,1.22-3.89c-0.83-0.76-2.12,1.81-2.81,2.7
							c-0.69,0.89-3.06,2.63-3.06,2.63c-0.79-0.37,0.58-2.68,0.98-3.46c0.4-0.78-0.03-1.32,0.6-1.92
							c5.37-5.11-0.13-11.87-5.74-13.54c-0.28-0.08-0.58-0.16-0.86-0.08c-0.55,0.15-0.8,0.84-0.69,1.41
							c0.11,0.56,0.47,1.04,0.77,1.53c3.48,5.77-1.42,13.94,2.05,19.72c1.19,1.97,1.7,5.67,1.7,5.67L1507.55,2381.14z"/>
					</g>
					<g>
						<path className="st8" d="M1494.2,2376.8c-0.11-0.4,0.02-0.98,0.01-1.28c-0.04-0.76-0.22-1.51-0.44-2.24
							c-0.45-1.47-1.11-2.87-1.39-4.39c-0.59-3.09,1.93-5.71,1.24-8.83c-0.71-3.19-4.06-6.13-6.02-8.61
							c-1.66-2.1-1.98-4.95-3.49-7.15c-0.73-1.06,2.2-16.58-1.86-8.89c0,11.19-0.31,33.75-0.31,44.94c0,0,19.11,0.95,17.46,0.62
							C1499.15,2380.93,1494.26,2377,1494.2,2376.8z"/>
					</g>
					<g>
						<path className="st69" d="M1493.44,2387.39C1493.44,2387.39,1493.44,2387.39,1493.44,2387.39
							C1493.43,2387.39,1493.43,2387.39,1493.44,2387.39L1493.44,2387.39z"/>
					</g>
					<g>
						<path className="st8" d="M1487.57,2334.54c-0.8-1.31-1.74-2.66-1.06-4.37c0.06-0.16,0-0.49-0.13-0.59
							c-1.25-1.03-2.23-8.65-3.92-9.08c0,3.31,0,21.95,0,25.26c1.54-0.04,2.98-9.72,4.49-9.91
							C1487.8,2335.74,1488.14,2335.46,1487.57,2334.54z"/>
					</g>
					<g>
						<path className="st30" d="M1509.99,2309.29c0.39,1.35,0.81,2.7,1.2,4.06c0.11,0.39,0.22,0.78,0.32,1.17
							c0.07,0.25,0.13,0.5,0.19,0.75c0.23,0.92,0.71,1.42,1.56,1.77c2.38,0.96,4.73,1.96,7.18,2.72c5.04,1.55,10.15,2.67,15.46,2.63
							c0.53,0,1.01-0.14,1.53-0.36c1.17-0.49,0.46-0.76-0.02-1c-0.91-0.45-1.85-0.83-2.79-1.21c-1.57-0.64-3.19-1.15-4.6-2.13
							c-1.12-0.78-1.27-1.55-0.49-2.68c0.62-0.89,1.52-1.47,2.43-2c3.56-2.07,7.44-3.31,11.44-4.21c-0.19-0.07-0.38-0.16-0.58-0.2
							c-2.97-0.66-6.02-0.88-8.94-1.77c-1.34-0.26-2.67-0.52-4.01-0.78c-1.35-0.01-2.63-0.34-3.92-0.69
							c-5.42-1-9.79-0.76-15.22-1.76c-0.58-0.11-1.86-0.15-2.44-0.25"/>
						<path className="st8" d="M1535.63,2322.55c-4.55,0-9.41-0.84-15.24-2.64c-2.04-0.63-4.03-1.44-5.96-2.22
							c-0.41-0.17-0.82-0.33-1.23-0.5c-0.93-0.37-1.42-0.94-1.66-1.88c-0.06-0.25-0.13-0.5-0.19-0.75c-0.1-0.39-0.21-0.78-0.32-1.17
							c-0.22-0.78-0.46-1.57-0.69-2.33c-0.17-0.57-0.34-1.15-0.51-1.72l0.32-0.09c0.17,0.57,0.34,1.15,0.51,1.72
							c0.23,0.77,0.47,1.56,0.69,2.34c0.11,0.39,0.22,0.78,0.32,1.17c0.07,0.25,0.13,0.5,0.2,0.75c0.21,0.84,0.63,1.32,1.46,1.65
							c0.41,0.17,0.82,0.33,1.23,0.5c1.92,0.78,3.91,1.59,5.94,2.22c5.92,1.82,10.81,2.65,15.41,2.62c0.45,0,0.89-0.11,1.47-0.35
							c0.52-0.22,0.53-0.34,0.53-0.34c0-0.08-0.34-0.25-0.47-0.31l-0.1-0.05c-0.81-0.4-1.66-0.75-2.49-1.08l-0.3-0.12
							c-0.34-0.14-0.67-0.27-1.01-0.4c-1.23-0.47-2.5-0.97-3.62-1.75c-1.2-0.84-1.36-1.71-0.53-2.91c0.65-0.94,1.59-1.53,2.49-2.05
							c3.02-1.76,6.5-3.06,10.92-4.1c-0.01,0-0.01,0-0.02,0c-1.17-0.26-2.37-0.45-3.53-0.64c-1.79-0.29-3.64-0.59-5.41-1.13
							c-1.33-0.26-2.65-0.51-3.98-0.77c-1.39-0.01-2.75-0.37-3.95-0.69c-2.69-0.5-5.18-0.69-7.58-0.88
							c-2.41-0.19-4.9-0.38-7.62-0.88c-0.29-0.05-0.78-0.09-1.24-0.13c-0.46-0.04-0.9-0.07-1.19-0.12l0.06-0.33
							c0.27,0.05,0.72,0.08,1.16,0.12c0.48,0.04,0.97,0.08,1.28,0.13c2.7,0.5,5.18,0.69,7.58,0.88c2.41,0.19,4.91,0.38,7.63,0.88
							c1.2,0.32,2.54,0.68,3.89,0.69l0.03,0c1.34,0.26,2.67,0.52,4.01,0.78l0.02,0c1.76,0.53,3.6,0.83,5.38,1.12
							c1.17,0.19,2.38,0.39,3.55,0.65c0.15,0.03,0.29,0.09,0.42,0.14c0.06,0.02,0.12,0.05,0.18,0.07l0.55,0.19l-0.57,0.13
							c-4.65,1.04-8.27,2.38-11.39,4.19c-0.87,0.5-1.76,1.07-2.38,1.95c-0.73,1.05-0.61,1.71,0.45,2.45
							c1.09,0.76,2.34,1.24,3.55,1.71c0.34,0.13,0.68,0.26,1.02,0.4l0.3,0.12c0.83,0.34,1.69,0.69,2.51,1.09l0.09,0.05
							c0.31,0.15,0.66,0.32,0.65,0.62c-0.01,0.23-0.23,0.43-0.74,0.64c-0.62,0.26-1.1,0.37-1.6,0.38
							C1535.82,2322.55,1535.73,2322.55,1535.63,2322.55z"/>
					</g>
					<g>
						<path className="st30" d="M1507.95,2302.04c0.21,0.77,2.09,7.36,2.3,8.13C1510.04,2309.4,1508.16,2302.81,1507.95,2302.04
							C1507.95,2302.04,1507.95,2302.04,1507.95,2302.04z"/>
					</g>
					<g>
						<path className="st30" d="M1511.51,2314.52c-0.1-0.39-0.21-0.78-0.32-1.17C1511.3,2313.74,1511.4,2314.12,1511.51,2314.52z"/>
					</g>
					<g>
						<path className="st30" d="M1483.7,2285.75c2.03,0.58,4.03,1.22,6.05,1.84c5.74,1.75,11.48,3.5,17.22,5.25
							c-0.01-0.52-0.36-0.84-0.67-1.19c-1.31-1.47-3.08-2.21-4.83-2.92c-4.01-1.6-8.04-3.16-12.09-4.65
							c-2.18-0.81-4.31-1.78-6.59-2.31c-0.02,0.89,0.02,1.79-0.07,2.67C1482.62,2285.26,1482.98,2285.55,1483.7,2285.75z"/>
						<path className="st8" d="M1507.23,2293.18l-5.35-1.63c-4.07-1.24-8.14-2.48-12.21-3.72c-0.65-0.2-1.29-0.4-1.93-0.6
							c-1.34-0.42-2.73-0.85-4.11-1.24c-0.68-0.19-1.29-0.52-1.17-1.58c0.07-0.6,0.07-1.22,0.07-1.83c0-0.27,0-0.55,0.01-0.82
							l0.01-0.31l0.3,0.07c1.68,0.39,3.3,1.03,4.87,1.64c0.57,0.23,1.17,0.46,1.76,0.68c3.99,1.48,8.06,3.05,12.09,4.66
							c1.57,0.63,3.53,1.41,4.93,2.98c0.04,0.04,0.07,0.08,0.11,0.12c0.29,0.32,0.62,0.68,0.63,1.23L1507.23,2293.18z
							 M1483.03,2282.09c0,0.16,0,0.33,0,0.49c0,0.62,0,1.25-0.07,1.88c-0.07,0.61,0.11,0.85,0.81,1.05l0,0
							c1.38,0.39,2.77,0.83,4.12,1.24c0.64,0.2,1.29,0.4,1.93,0.6c4.07,1.24,8.14,2.48,12.21,3.72l4.6,1.4
							c-0.09-0.19-0.24-0.35-0.41-0.53c-0.04-0.04-0.07-0.08-0.11-0.12c-1.32-1.48-3.22-2.24-4.74-2.85
							c-4.03-1.61-8.09-3.17-12.08-4.65c-0.59-0.22-1.19-0.45-1.77-0.68C1486.07,2283.06,1484.57,2282.47,1483.03,2282.09z"/>
					</g>
					<g>
						<path className="st2" d="M1508.3,2303.59c0.58,0.08,1.01-0.07,1.58,0.06c5.46,1.17,10.75,1.02,16.2,1.85c0,0-0.61-1.15-0.66-1.37
							c-0.04-0.15-0.05-0.31-0.13-0.45c-0.23-0.4-0.73-0.12-1.08-0.13c-0.61-0.01-1.2-0.19-1.79-0.32
							c-0.86-0.19-1.76,0.05-2.58-0.15c-0.41-0.1-0.93-0.21-1.36-0.04c-0.44,0.16-0.74,0.76-1.25,0.71
							c-0.35-0.03-0.82-0.32-1.13-0.49c-0.39-0.23-0.55-0.62-0.66-1.03c-0.21-0.77-0.14-1.92-1.12-2.13
							c-0.3-0.07-0.6,0.07-0.87,0.22c-0.4,0.22-0.77,0.49-1.18,0.68c-0.97,0.44-2.92,0.18-3.33-0.99c-0.09-0.26-0.07-0.56-0.23-0.78
							c-0.16-0.23-0.7-0.3-0.97-0.31c-0.02,0.69,0.22,2.88,0.31,3.56C1508.14,2303.19,1508.3,2303.59,1508.3,2303.59z"/>
						<path className="st8" d="M1526.24,2305.6l-0.16-0.03c-2.32-0.35-4.64-0.53-6.89-0.7c-3.04-0.23-6.18-0.48-9.32-1.15
							c-0.3-0.06-0.56-0.05-0.83-0.04c-0.23,0.01-0.47,0.02-0.75-0.02l-0.05-0.01l-0.02-0.04c-0.01-0.02-0.17-0.41-0.26-1.14
							c-0.09-0.68-0.33-2.88-0.31-3.57l0-0.08l0.08,0c0.08,0,0.81,0.04,1.03,0.35c0.11,0.15,0.14,0.33,0.17,0.51
							c0.02,0.1,0.04,0.2,0.07,0.29c0.17,0.48,0.6,0.76,0.93,0.9c0.71,0.3,1.67,0.32,2.28,0.05c0.26-0.12,0.51-0.27,0.76-0.42
							c0.14-0.09,0.28-0.17,0.42-0.25c0.27-0.15,0.6-0.3,0.93-0.23c0.82,0.18,0.95,0.96,1.07,1.65c0.03,0.19,0.07,0.38,0.11,0.54
							c0.12,0.45,0.28,0.78,0.62,0.98l0.04,0.02c0.3,0.17,0.74,0.43,1.05,0.46c0.27,0.03,0.48-0.16,0.7-0.36
							c0.16-0.14,0.32-0.28,0.51-0.35c0.45-0.17,0.96-0.06,1.41,0.04c0.41,0.1,0.83,0.09,1.27,0.07c0.43-0.01,0.88-0.02,1.31,0.07
							c0.14,0.03,0.28,0.06,0.42,0.1c0.44,0.1,0.9,0.21,1.35,0.22c0.09,0,0.2-0.02,0.31-0.04c0.29-0.06,0.65-0.13,0.84,0.21
							c0.07,0.12,0.09,0.25,0.12,0.37c0.01,0.03,0.01,0.07,0.02,0.1c0.04,0.18,0.49,1.04,0.66,1.35L1526.24,2305.6z
							 M1509.36,2303.51c0.17,0,0.35,0.01,0.54,0.05c3.13,0.67,6.27,0.91,9.3,1.15c2.2,0.17,4.47,0.34,6.73,0.68
							c-0.17-0.33-0.55-1.06-0.59-1.25c-0.01-0.03-0.01-0.07-0.02-0.1c-0.02-0.12-0.05-0.23-0.1-0.32c-0.13-0.22-0.33-0.2-0.66-0.13
							c-0.12,0.02-0.24,0.05-0.35,0.04c-0.47-0.01-0.94-0.12-1.38-0.22c-0.14-0.03-0.28-0.07-0.42-0.1
							c-0.42-0.09-0.85-0.08-1.27-0.07c-0.44,0.01-0.89,0.02-1.31-0.08c-0.42-0.1-0.91-0.2-1.31-0.05
							c-0.16,0.06-0.31,0.19-0.46,0.32c-0.24,0.21-0.49,0.43-0.83,0.4c-0.35-0.03-0.81-0.3-1.12-0.48l-0.04-0.02
							c-0.42-0.24-0.59-0.67-0.7-1.08c-0.05-0.17-0.08-0.36-0.11-0.56c-0.12-0.67-0.24-1.36-0.95-1.52
							c-0.28-0.06-0.57,0.07-0.81,0.21c-0.14,0.08-0.28,0.16-0.41,0.25c-0.25,0.15-0.5,0.31-0.78,0.43
							c-0.65,0.3-1.66,0.28-2.41-0.04c-0.52-0.22-0.88-0.57-1.03-1c-0.04-0.1-0.06-0.21-0.07-0.31c-0.03-0.16-0.06-0.32-0.15-0.44
							c-0.12-0.17-0.53-0.25-0.82-0.27c0,0.76,0.23,2.81,0.31,3.46c0.07,0.57,0.19,0.93,0.23,1.05c0.24,0.03,0.46,0.02,0.67,0.01
							C1509.14,2303.51,1509.25,2303.51,1509.36,2303.51z"/>
					</g>
					<g>
						<path className="st70" d="M1507.95,2302.04C1507.95,2302.04,1507.95,2302.04,1507.95,2302.04c-0.14-1.48-0.28-2.96-0.42-4.44
							C1507.67,2299.08,1507.81,2300.56,1507.95,2302.04C1507.95,2302.04,1507.95,2302.04,1507.95,2302.04z"/>
					</g>
					<g>
						<path className="st71" d="M1516.45,2378.08L1516.45,2378.08c0.1,0.59,0.19,1.19,0.29,1.78
							C1516.64,2379.26,1516.55,2378.67,1516.45,2378.08C1516.45,2378.08,1516.45,2378.08,1516.45,2378.08
							C1516.45,2378.08,1516.45,2378.08,1516.45,2378.08L1516.45,2378.08z"/>
					</g>
					<g>
						<path className="st71" d="M1545.2,2371.49c-0.07,0.01-0.14,0.03-0.21,0.04C1545.06,2371.52,1545.13,2371.51,1545.2,2371.49z"/>
					</g>
					<g>
						<path className="st8" d="M1520.62,2364.58c-2.97-1.06-2.89-1.3-0.3-3.3c0.92-0.71,1.63-1.48,1.41-2.77c-0.1-0.57,0-1.06,0.71-1.28
							c0.74-0.23,0.87-0.81,0.63-1.54c-0.99-3-1.55-6.05-1.38-9.24c0.13-2.33,0.01-4.67,0.03-7.01c0.01-0.7-0.16-1.31-0.53-1.92
							c-1.12-1.85-2.19-3.73-3.27-5.6c-0.22-0.38-0.44-0.78-0.89-0.85c-1.32-0.2-1.23-1.01-0.89-1.95c0.46-1.26,0.26-2.59-0.53-3.5
							c-0.85-0.99-2.39-1.33-2.85-2.89c-0.2,0.55-0.24,0.97-0.19,1.37c0.32,2.74,0.46,5.49,0.46,8.25c-0.02,9.69-0.01,19.37,0,29.06
							c0,0.68,0,1.37,0.14,2.02c0.3,1.38,0.61,2.75,0.91,4.13c0.78,3.51,1.58,7.02,2.36,10.53c2.86-0.22,9.62,1.44,14.56-2.29
							c-0.43-2.77-6.94-3.88-7.22-6.66C1523.57,2366.94,1522.98,2365.42,1520.62,2364.58z"/>
					</g>
					<g>
						<polygon className="st69" points="1516.45,2378.08 1516.45,2378.08 1516.45,2378.08 1516.45,2378.08 						"/>
					</g>
					<g>
						<path className="st8" d="M1541.06,2369.15c-1.94-0.61-2.25-2.89-3.31-4.64c-2.29-3.81-4.42-7.72-7.76-10.77
							c-0.65-0.59-0.95-1.37-1.19-2.2c-0.84-2.88-1.18-6-3.78-8.21c0.4,1.85,0.74,3.63,1.18,5.38c1.12,4.49,1.71,9.05,1.88,13.68
							c0.09,2.46,0.58,4.85,1.17,7.22c0.27,1.08,0.56,2.15,0.85,3.22c2.04-0.1,4.08-0.15,6.09-0.5c1.54-0.27,7.99,0.48,12.64-1.52
							C1548.9,2370.14,1543.88,2370.04,1541.06,2369.15z"/>
					</g>
					<g>
						<path className="st8" d="M1548.45,2359.61c-0.56-0.53-1.1-1.03-1.49-1.71c-0.85-1.48-1.38-2.75-2.4-4.44
							c0.29,1.51,0.14,3.43,0.54,4.86c0.2,0.72,0.92,3.1,1.07,3.82c0.76,3.58,2.3,4.46,4.09,7.64c0,0,0,0,0,0
							c1.85-0.74,8.62-2.17,10.35-3.15c0.02-0.6-5.32-0.52-5.66-0.96C1553.09,2363.31,1550.6,2361.65,1548.45,2359.61z"/>
					</g>
					<g>
						<path className="st2" d="M1516.89,2311.91c-0.47-1.81-2.12-3.39-3.72-4.23c-1.32-0.69-4.13-1.74-4.58-3.34
							c0.47,1.65,0.94,3.29,1.4,4.94C1510.61,2311.45,1515.07,2312.3,1516.89,2311.91z"/>
						<path className="st8" d="M1515.9,2312.08c-2.09,0-5.45-0.86-5.99-2.77l-1.4-4.94c0,0,0,0,0,0l0.16-0.05
							c0.37,1.28,2.31,2.21,3.73,2.89c0.3,0.14,0.58,0.28,0.81,0.4c1.42,0.74,3.26,2.31,3.76,4.28l0.02,0.08l-0.09,0.02
							C1516.63,2312.05,1516.29,2312.08,1515.9,2312.08z M1508.89,2305.11l1.18,4.16c0.57,1.99,4.79,2.93,6.72,2.58
							c-0.52-1.87-2.29-3.37-3.66-4.09c-0.23-0.12-0.51-0.25-0.8-0.39C1511.15,2306.81,1509.63,2306.08,1508.89,2305.11z"/>
					</g>
					<g>
						<polygon className="st69" points="1493.44,2387.39 1493.43,2387.4 1493.4,2387.42 						"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1512.42,2323.63c0.03,0.32,0.53,0.32,0.5,0l0,0c-0.24-2.39-0.48-4.78-0.72-7.17"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1543.55,2350.43c0.31,1.44,0.62,2.88,0.93,4.32c0.07,0.32,0.55,0.18,0.48-0.13l0,0
							c-1.11-5.16-2.21-10.33-3.29-15.5"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1523.69,2321.14c-0.36,2.91-0.19,5.85,0.05,8.77c0.24,2.97,0.5,5.94,0.75,8.91
							c0.14,1.67,0.28,3.35,0.43,5.02c0.03,0.32,0.53,0.32,0.5,0l0,0c-0.25-2.96-0.5-5.92-0.75-8.88c-0.25-2.95-0.57-5.9-0.66-8.86"
							/>
					</g>
				</g>
				<g>
					<g>
						<path className="st8" d="M1538.87,2328.23c-0.31-2.08-0.52-4.17-0.62-6.26c-0.02-0.32-0.52-0.32-0.5,0l0,0"/>
					</g>
				</g>
			</g>
			<path d="M628.45,740.25c-0.02-0.01-0.1-0.04-0.23-0.09l0.13,0.02l-62.04-29.69l-0.25-0.08l-0.01,0.07c-0.01,0-0.02,0-0.02,0
				l-0.02,0c-0.45-0.03-0.87-0.18-1.28-0.44l-0.03-0.01c-0.24-0.08-0.49-0.16-0.73-0.24c-0.47-0.15-0.94-0.31-1.41-0.46
				c-0.09-0.03-0.18-0.06-0.27-0.08c-0.55-0.18-1.08-0.34-1.58-0.6c-0.52-0.26-1.03-0.59-1.67-1.08c-0.47-0.36-0.93-0.74-1.37-1.12
				c-0.56-0.47-1.13-0.95-1.73-1.39c-0.04-0.03-0.07-0.06-0.09-0.1l-0.02-0.04l-0.04-0.02c-0.55-0.2-1.11-0.43-1.65-0.65
				c-0.34-0.14-0.7-0.29-1.05-0.42c-1.11-0.43-2.03-0.79-3.01-1.11c-0.36-0.12-0.73-0.24-1.11-0.36c-3.19-1.03-6.48-2.1-8.74-4.55
				c-0.01-0.01-0.02-0.03-0.04-0.05l-0.01-0.02l-0.02-0.01c-1.11-0.78-2.18-1.34-3.36-1.76c-0.32-0.11-0.65-0.23-0.96-0.33
				c-0.85-0.29-1.73-0.59-2.56-0.97c-0.05-0.02-0.08-0.05-0.11-0.08l-0.03-0.03l-0.04-0.01c-0.15-0.04-0.26-0.09-0.34-0.19
				l-0.03-0.03l-0.04-0.01c-0.33-0.11-0.59-0.33-0.86-0.56c-0.09-0.08-0.18-0.16-0.28-0.23l-0.2-0.16l-0.02-0.01
				c-0.93-0.36-1.87-0.82-2.8-1.37c-1.27-0.75-2.25-1.52-2.99-2.35l-0.02-0.02l-0.03-0.01c-0.29-0.11-0.51-0.21-0.71-0.29
				l-0.81-0.23c-1.42-0.41-2.88-0.83-4.32-1.25c-0.09-0.03-0.19-0.05-0.28-0.06c-0.27-0.05-0.5-0.1-0.64-0.32l-0.01-0.02l-0.22-0.16
				l-0.03-0.01c-0.85-0.18-1.72-0.4-2.55-0.62l-0.27-0.07v0l-0.18-0.05c0,0-0.01,0-0.01,0l-0.09-0.02l0,0
				c-0.81-0.2-1.51-0.37-2.21-0.52c-3.71-0.77-7.7-1.09-12.56-0.99c-0.09,0-0.19,0-0.28,0.01V685c-0.22,0-0.75,0-1.18,0l0,0l-1.08,0
				c-1.76,0-3.59,0.01-5.37,0.16l-0.07,0.01l-0.01,0.02c-0.06,0.01-0.23,0.02-0.3,0.02c-0.05,0-0.1,0-0.12,0
				c-0.17,0.02-0.34,0.04-0.51,0.06c-0.36,0.04-0.71,0.09-1.05,0.15c-0.33,0.05-0.65,0.13-0.97,0.2c-0.33,0.07-0.68,0.15-1.02,0.2
				c-0.25,0.04-0.5,0.19-0.72,0.32c-0.06,0.04-0.12,0.07-0.18,0.11c-0.27,0.15-0.56,0.32-0.91,0.53l0,0c-0.54,0.19-1,0.51-1.45,0.82
				c-0.15,0.11-0.31,0.21-0.47,0.32c-0.07,0.05-0.15,0.09-0.23,0.14c-0.27,0.15-0.55,0.32-0.72,0.62c-0.52,0.26-0.95,0.56-1.3,0.9
				c-1.26,1.23-2.21,2.77-2.92,4.72c-0.13,0.37-0.29,0.51-0.64,0.56c-0.59,0.08-1.44,0.29-2.04,1.07c-0.24,0.32-0.54,0.67-0.97,0.8
				c-0.36,0.11-0.73,0.2-1.09,0.29c-0.04,0.01-0.08,0.02-0.12,0.03l0-0.01l-0.36,0.09c0,0,0,0-0.01,0l-0.04,0.01l0,0
				c-0.36,0.09-0.72,0.19-1.08,0.28c-0.9,0.23-1.83,0.47-2.74,0.72c-0.62,0.16-1.27,0.36-1.85,0.74c-1.45,0.97-3.02,1.99-4.79,3.13
				c-0.1,0.07-0.2,0.14-0.3,0.21c-0.28,0.2-0.54,0.39-0.87,0.43c-0.51,0.06-1,0.2-1.47,0.33l-0.05,0.01l0,0l0,0l-0.09,0.03l0,0.01
				c-0.46,0.14-0.92,0.36-1.37,0.68c-0.99,0.7-1.93,1.49-2.84,2.25c-0.29,0.25-0.59,0.49-0.88,0.73c-0.31,0.25-0.59,0.41-0.96,0.35
				l0,0c-0.14-0.07-0.28-0.08-0.4-0.08c-0.09,0-0.17,0-0.24-0.02c-1.48-0.49-2.79-0.41-4.03,0.23c-0.45,0.23-0.93,0.26-1.48,0.09
				c-0.32-0.1-0.66-0.17-0.98-0.23c-0.11-0.02-0.21-0.04-0.32-0.06c-0.39-0.08-0.74,0.01-1.04,0.26c-0.01,0-0.02,0-0.02,0
				c-0.09,0.01-0.21,0.03-0.31,0.11c-0.26,0.06-0.48,0.18-0.7,0.3c-0.1,0.05-0.2,0.11-0.3,0.16l-0.04,0.02
				c-0.21,0.1-0.4,0.19-0.62,0.16c-0.72-0.08-1.45-0.17-2.15-0.24c-0.44-0.05-0.89-0.1-1.33-0.15c-0.5-0.06-1.09-0.09-1.67,0.22
				c-0.44,0.24-0.91,0.43-1.37,0.62c-0.18,0.07-0.36,0.15-0.53,0.22c-0.81,0.35-1.78,0.67-2.79,0.37c-0.65-0.19-1.32-0.46-2.18-0.85
				c-0.49-0.22-1.06-0.43-1.67-0.02c-0.1,0.07-0.22,0.07-0.38,0.04c-0.97-0.16-1.71,0.11-2.25,0.84c-0.55,0.74-1.33,1.31-2.36,1.74
				c-0.17,0.07-0.34,0.16-0.5,0.24c-0.18,0.09-0.35,0.18-0.52,0.25c-0.49,0.19-0.8,0.54-1,1.08c-0.1,0.27-0.2,0.58-0.45,0.81
				c-0.49,0.45-0.98,0.92-1.46,1.38c-0.2,0.19-0.39,0.38-0.59,0.56l-0.04,0.04l0,0.05c-0.01,0.06-0.01,0.11-0.02,0.17
				c-0.01,0.1-0.02,0.2-0.03,0.31l-50.38,30.57l-0.38,0.1l0.38,0.15c0.05,0.03,0.51,0.28,1.41,0.56c2.78,1.1,9.49,3.68,17.88,6.47
				c18.49,6.15,30.75,8.58,37.79,9.53c10.27,1.39,29.53,1.79,43.88,1.89c2.51,0.02,5.09,0.02,7.7,0.02
				c14.06,0,29.07-0.24,39.94-0.64c8.19-0.31,16.01-1.68,23.25-4.09l26.71-2.5c0.32-0.04,32.41-4,45.35-3.72
				c0.34,0.01,0.67,0.01,1.01,0.01c12.71,0,26.56-4.92,26.7-4.97l0.27-0.1L628.45,740.25z M492.57,685.22L492.57,685.22
				L492.57,685.22L492.57,685.22z"/>
			<g>
				<g>
					<path className="st72" d="M573.25,744.66l61.1-12.17c0,0-3.11-0.58-13.45-3.1c-10.12-2.46-14.7-3.54-21.01-6.42
						c-6.31-2.89-18.93-6-18.93-6l-7.72-1.2"/>
				</g>
				<g>
					<path className="st73" d="M428.54,708.38c41.45,44.82-25.61,28.78-35.62,31.07c-10.01,2.29-14.84-0.45-14.84-0.45"/>
					<path className="st8" d="M420.73,740.56c-5.24,0-10.72-0.39-15.38-0.73c-5.53-0.4-10.3-0.74-12.4-0.26
						c-5.19,1.19-8.97,1-11.23,0.63c-2.46-0.4-3.66-1.07-3.71-1.1l0.13-0.24c0.01,0.01,1.23,0.68,3.64,1.07
						c2.23,0.36,5.97,0.54,11.1-0.63c2.14-0.49,6.7-0.16,12.48,0.25c6.52,0.47,14.64,1.05,21.46,0.51
						c7.93-0.63,12.6-2.63,14.28-6.12c1.17-2.43,0.84-5.65-0.97-9.56c-2.02-4.36-5.96-9.72-11.69-15.92l0.2-0.19
						c5.75,6.22,9.71,11.6,11.74,15.99c1.85,3.99,2.18,7.28,0.97,9.79c-1.72,3.59-6.47,5.64-14.51,6.28
						C424.89,740.5,422.83,740.56,420.73,740.56z"/>
				</g>
				<g>
					<path className="st74" d="M378.08,738.99c0,0,32.39,13.22,57.04,16.55c18.26,2.47,68.21,2.14,91.5,1.27
						c7.9-0.3,15.72-1.59,23.22-4.09l26.72-2.5c0,0,32.27-4,45.37-3.72c13.1,0.28,27.66-4.95,27.66-4.95l-14.16-8.52
						c-0.58-0.35-95.5-35.55-96.18-35.53L378.08,738.99z"/>
					<path className="st8" d="M486.68,757.59c-2.61,0-5.19-0.01-7.7-0.02c-14.35-0.1-33.62-0.5-43.88-1.89
						c-7.03-0.95-19.3-3.38-37.79-9.53c-11.05-3.68-19.2-7-19.28-7.03l-0.38-0.16l0.4-0.1l161.18-41.5l0.02,0c0,0,0,0,0,0
						c0.47,0,33.85,12.33,48.18,17.64c22.97,8.5,47.77,17.73,48.08,17.91l14.41,8.67l-0.27,0.1c-0.14,0.05-13.99,4.97-26.7,4.97
						c-0.34,0-0.67,0-1.01-0.01c-12.94-0.27-45.03,3.68-45.35,3.72l-26.71,2.5c-7.24,2.41-15.06,3.78-23.25,4.09
						C515.75,757.35,500.75,757.59,486.68,757.59z M378.52,739.02c1.74,0.7,9.19,3.64,18.88,6.87c10.19,3.39,25.01,7.8,37.73,9.52
						c10.25,1.39,29.5,1.79,43.85,1.89c16.05,0.11,34.74-0.14,47.63-0.62c8.17-0.31,15.97-1.68,23.18-4.08l0.03-0.01l26.72-2.5
						c0.32-0.04,32.42-4,45.39-3.73c11.95,0.25,25.27-4.14,27.34-4.85l-13.91-8.37c-0.42-0.22-22.82-8.56-47.93-17.85
						c-28.93-10.71-47.33-17.45-48.18-17.65L378.52,739.02z"/>
				</g>
				<g>
					<path className="st73" d="M573.28,703.31c-0.01,0-0.02-0.01-0.03-0.01l-3.08,15.34l22.7,8.12l41.4,5.73L573.28,703.31z"/>
					<path className="st8" d="M635.4,732.83l-42.6-5.9l-22.85-8.18l3.16-15.69l0.25,0.08L635.4,732.83z M592.91,726.58l40.22,5.57
						l-59.74-28.59l-3.01,14.96L592.91,726.58z"/>
				</g>
				<g>
					<g>
						<path className="st74" d="M428.55,743.8c0.07-0.01,0.15-0.02,0.22-0.04c0.91-0.33,1.8-0.72,2.74-0.97
							c1.05-0.28,2.07-0.67,3.04-1.18c0.32-0.17,0.63-0.39,0.94-0.44c-0.25,0.29-0.52,0.6-0.79,0.92c-0.06,0.07-0.15,0.13-0.07,0.23
							c0.04,0.06,0.11,0.04,0.17,0.01c0.85-0.43,1.79-0.62,2.67-0.98c0.38-0.15,0.78-0.17,1.21-0.11c0.71,0.1,1.39,0.32,2.07,0.52
							c0.48,0.14,0.95,0.23,1.46-0.01c0.81-0.38,1.69-0.55,2.5-0.92c0.09-0.04,0.21-0.1,0.28,0.02c0.05,0.1-0.01,0.18-0.1,0.22
							c-0.57,0.24-0.84,0.82-1.35,1.13c-0.12,0.07-0.29,0.18-0.26,0.45c0.16-0.11,0.3-0.19,0.43-0.28c0.53-0.34,1.06-0.7,1.61-1.01
							c1.21-0.68,2.37-1.46,3.67-1.97c0.31-0.12,0.61-0.26,0.89-0.44c1.26-0.79,2.42-1.7,3.57-2.65c0.51-0.42,1.04-0.79,1.76-0.37
							c0.27,0.16,0.61,0.12,0.91-0.08c1.31-0.89,2.62-1.79,3.85-2.79c0.12-0.1,0.23-0.23,0.44-0.16c-0.11,0.3-0.39,0.52-0.33,0.85
							c0.02,0.13-0.06,0.3,0.11,0.36c0.13,0.05,0.21-0.09,0.3-0.17c0.44-0.38,0.86-0.77,1.32-1.11c0.22-0.16,0.43-0.27,0.46-0.56
							l0.01-0.01c0.33-0.14,0.74-0.18,0.81-0.65c0.11,0.02,0.21,0.02,0.31-0.05c0.89-0.63,1.86-1.16,2.71-1.86
							c0.21-0.11,0.42-0.23,0.63-0.34c0.1-0.07,0.2-0.13,0.3-0.2c0.25,0,0.47-0.1,0.69-0.2c1.33-0.61,2.52-1.48,3.87-2.05
							c0.22-0.09,0.4-0.14,0.58,0.03c0.47,0.43,0.85,0.93,1.03,1.55c-0.96,0.1-1.93,0.2-2.89,0.31c-0.13,0.02-0.26,0.08-0.38,0.15
							c-0.69,0.4-1.4,0.78-2.07,1.22c-0.52,0.35-1.17,0.51-1.54,1.08c-0.41,0.65-0.87,1.27-1.29,1.92
							c-0.19,0.28-0.43,0.54-0.51,0.91c0.65-0.44,1.37-0.76,2.03-1.19c0.06,0.18-0.02,0.23-0.08,0.29
							c-1.03,0.94-2.05,1.88-3.08,2.83c-0.13,0.12-0.31,0.21-0.31,0.43c0,0-0.02,0.02-0.02,0.02l0.02-0.02
							c0.16-0.01,0.28-0.1,0.41-0.19c1.11-0.76,2.21-1.54,3.34-2.28c1.11-0.74,2.2-1.48,2.8-2.76c0.18-0.38,0.4-0.8,0.8-1.03
							c0.95-0.54,1.91-1.05,2.87-1.57c0.14,0.02,0.26,0.1,0.34,0.2c0.24,0.34,0.56,0.31,0.92,0.28c0.42-0.04,0.76-0.25,1.13-0.39
							c-0.19,0.59-0.6,1.08-0.88,1.78c1.16-0.66,2.34-1.05,3.04-2.13c0.13-0.2,0.29-0.38,0.55-0.17c0.46,0.37,0.89,0.22,1.32-0.06
							c0.09-0.06,0.19-0.1,0.28-0.15c0.23-0.14,0.38-0.08,0.42,0.19c0.05,0.28,0.22,0.33,0.48,0.36c0.92,0.1,1.43-0.42,1.85-1.12
							c0.07-0.12,0.09-0.34,0.28-0.3c0.15,0.03,0.11,0.23,0.16,0.35c0.07,0.17,0.03,0.46,0.32,0.41c0.92-0.18,1.88-0.23,2.73-0.65
							c1.2-0.6,2.24-1.44,3.36-2.17c0.92-0.6,1.97-0.92,3.03-0.7c1.12,0.24,2.2,0.68,3.27,1.11c0.32,0.13,0.55,0.15,0.83-0.02
							c0.09,0.12,0.21,0.21,0.35,0.18c0.6-0.12,1.25-0.08,1.81-0.39c0.75-0.41,1.57-0.71,2.16-1.38c0.05-0.05,0.12-0.09,0.2-0.11
							c0.36-0.09,0.57-0.38,0.79-0.64c0.28-0.22,0.66-0.3,0.85-0.64c0.63-0.36,1.22-0.8,1.98-0.84c0.14,0.01,0.28,0.01,0.4,0.05
							c0.55,0.15,0.97-0.05,1.32-0.47c0.38-0.47,0.86-0.85,1.19-1.37c0.41-0.63,0.99-0.83,1.7-0.78c0.44,0.03,0.88,0.01,1.31,0.03
							c0.28,0.02,0.43-0.09,0.46-0.37c0.26,0.03,0.42-0.12,0.59-0.28c0.56-0.59,1.11-1.2,1.7-1.75c0.42-0.39,0.82-0.79,1.16-1.26
							c0.63-0.1,1.25-0.21,1.88-0.28c0.51-0.06,0.98-0.2,1.41-0.46c0.51-0.3,1.01-0.61,1.47-0.99c0.72-0.6,1.54-0.84,2.48-0.85
							c1.13-0.02,2.28-0.05,3.4-0.22c1.41-0.22,2.62-1,3.89-1.59c0.91-0.42,1.33-1.15,1.66-1.99c0.36-0.92,1.29-1.19,1.91-1.93
							c0.07,0.33-0.07,0.43-0.16,0.53c-0.44,0.48-0.83,1-1.1,1.6c-0.26,0.58-0.65,1.1-0.8,1.72c0,0,0,0,0,0
							c0.38-0.18,0.77-0.36,1.15-0.53c0.24-0.11,0.44-0.26,0.58-0.49c0.58-0.96,1.13-1.94,1.62-2.94c0.17-0.34,0.38-0.6,0.68-0.83
							c1.13-0.88,2.32-1.71,3.06-2.98c0.22-0.38,0.48-0.73,0.91-0.9l0.64-0.34l0,0l0,0l0,0c0.52-0.43,0.99-0.9,1.76-0.86
							c0.78,0.04,1.57,0,2.36-0.02c0.25-0.01,0.27,0.13,0.27,0.31c0,0.42,0.02,0.84,0.01,1.27c-0.01,0.31,0.09,0.43,0.42,0.42
							c0.62-0.02,1.24,0.01,1.86,0.01 M459.42,747.21 M468.72,735.5c-0.51,0.36-1.03,0.69-1.64,0.84c-0.2,0.05-0.35,0.2-0.45,0.37
							c-0.25,0.45-0.69,0.48-1.19,0.43c1.12-1.16,2.24-2.32,3.4-3.53c-0.29-0.03-0.36,0.14-0.46,0.23c-0.9,0.86-1.79,1.72-2.7,2.58
							c-0.25,0.24-0.49,0.49-0.84,0.6c-1,0.3-2,0.6-3,0.91c-0.67,0.21-1.32,0.4-1.85,0.97c-0.99,1.06-2.08,2.03-3.11,3.06
							c-0.38,0.38-0.8,0.63-1.31,0.76c-0.59,0.15-1.17,0.32-1.76,0.47c-0.45,0.12-0.84,0.3-1.17,0.66
							c-0.55,0.61-1.14,1.18-1.71,1.76c-0.06,0.06-0.16,0.11-0.08,0.22c0.09,0.03,0.15-0.03,0.2-0.07c1.64-1.26,3.5-2.03,5.49-2.54
							c1.25-0.32,2.4-0.8,3.32-1.77c0.41-0.43,0.88-0.85,1.44-1.13c-0.21,0.54-0.75,0.82-1.06,1.38c0.39-0.08,0.59-0.32,0.84-0.46
							c1.28-0.75,2.57-1.48,3.73-2.43c1.29-1.05,2.58-2.11,3.91-3.11 M486.06,733.09c2.66-1.39,5.31-2.69,8.04-3.91
							c-0.19-0.13-0.32-0.08-0.45-0.06c-0.87,0.11-1.75,0.21-2.62,0.33c-0.46,0.06-0.93,0.07-1.36,0.31
							c-1.55,0.86-3.11,1.71-4.67,2.55c-0.68,0.36-1.36,0.73-2.12,0.9c-1.46,0.33-2.92,0.69-4.39,1.01
							c-0.32,0.07-0.55,0.22-0.75,0.47c-0.68,0.85-1.37,1.7-2.05,2.55c-0.15,0.19-0.33,0.28-0.58,0.3c-0.8,0.08-1.58,0.22-2.28,0.68
							c-0.61,0.4-1.25,0.74-1.87,1.11c-1.78,1.06-3.65,1.99-5.2,3.41c-0.36,0.33-0.74,0.63-1.11,0.94c0.77-0.2,1.49-0.48,2.2-0.77
							c1.46-0.59,2.98-1.05,4.37-1.8c2.93-1.59,5.85-3.2,8.79-4.77c0.45-0.24,0.85-0.58,1.39-0.64c1.46-0.18,2.93-0.26,4.37-0.62
							c0.39-0.1,0.78-0.22,1.21-0.16c1.06,0.16,2.14,0.27,3.2,0.42c0.46,0.06,0.87-0.01,1.25-0.28c0.41-0.29,0.83-0.56,1.24-0.84
							c1.08-0.72,2.17-1.44,3.26-2.16c0.85-0.56,1.69-1.12,2.54-1.69c0.08-0.05,0.22-0.1,0.16-0.23c-0.06-0.12-0.19-0.05-0.29-0.04
							c-1.11,0.01-2.03,0.55-2.96,1.06c-0.39,0.21-0.77,0.42-1.19,0.64c-0.03-0.2,0.07-0.23,0.14-0.28c0.34-0.25,0.68-0.5,1.03-0.76
							c0.07-0.05,0.16-0.11,0.16-0.21c0-0.07-0.06-0.11-0.12-0.1c-0.84,0.05-1.7-0.15-2.52,0.16c-2.17,0.83-4.36,1.6-6.49,2.5
							C486.31,733.16,486.22,733.2,486.06,733.09z M514.36,721.07c-0.03-0.02-0.05-0.04-0.08-0.06c-0.04,0.01-0.09,0.02-0.12,0.04
							c-1.52,1.04-2.45,2.59-3.53,4c-0.11,0.14-0.08,0.26,0.08,0.33c0.95,0.38,1.86,0.88,2.96,0.66c0.56-0.11,1.17-0.26,1.75-0.02
							c0.65,0.27,1.33,0.42,1.98,0.66c0.35,0.13,0.63,0.1,0.93-0.12c0.88-0.62,1.8-1.18,2.9-1.36c0.4-0.07,0.79-0.2,1.19-0.31
							c0.09-0.02,0.19-0.05,0.2-0.15c0.01-0.11-0.09-0.15-0.18-0.18c-0.58-0.21-1.15-0.49-1.78-0.21c-0.09,0.04-0.24,0.15-0.31-0.01
							c-0.05-0.12,0.09-0.17,0.16-0.25c1.04-1.08,2.09-2.15,3.13-3.23c0.98-1.02,1.92-2.07,3-2.98c0.24-0.21,0.54-0.38,0.76-0.71
							c-2.45,0.61-4.46,1.93-6.51,3.21c0.16-0.29,0.34-0.54,0.55-0.76c0.68-0.73,1.23-1.58,2.07-2.16c1.06-0.73,2.14-1.43,3.2-2.16
							c0.24-0.17,0.55-0.27,0.75-0.62c-0.74,0.22-1.3,0.61-1.92,0.87c-1.29,0.54-2.51,1.17-3.57,2.12
							c-0.77,0.69-1.55,1.42-2.62,1.68c-0.55,0.13-1.08,0.3-1.63,0.42c-0.85,0.2-1.64,0.53-2.34,1.06
							c-0.47,0.36-0.97,0.68-1.45,1.02c-0.02-0.02-0.05-0.05-0.07-0.07C514.02,721.54,514.19,721.3,514.36,721.07z M531.05,729.9
							c0.29,0.11,0.43,0,0.57-0.07c1.54-0.82,3.06-1.69,4.7-2.32c0.64-0.25,1.33-0.46,1.85-0.92c1.36-1.18,2.73-2.36,4.01-3.62
							c1.27-1.25,2.76-2.2,4.2-3.22c0.44-0.31,0.87-0.62,1.1-1.11c0.37-0.8,0.93-1.36,1.75-1.72c0.59-0.26,1.05-0.66,1.29-1.34
							c0.17-0.48,0.33-1.03,0.72-1.39c0.49-0.45,0.99-0.89,1.47-1.34c0.08-0.07,0.26-0.1,0.18-0.26c-0.06-0.12-0.19-0.03-0.29-0.02
							c-1.14,0.08-2.23,0.45-3.37,0.51c-1.23,0.07-1.87,1.1-2.85,1.58c-0.06,0.03-0.1,0.11-0.13,0.18c-0.27,0.51-0.5,1.04-0.8,1.53
							c-0.81,1.33-1.28,2.87-2.56,3.92c-0.62,0.51-1.18,0.93-2.01,0.75c-0.26-0.05-0.44,0.05-0.59,0.25
							c-0.38,0.49-0.75,0.99-1.15,1.46c-0.19,0.23-0.3,0.46-0.25,0.74c0.15,0.77-0.22,1.26-0.83,1.64c-0.08,0.05-0.14,0.13-0.24,0.1
							c-0.12-0.04-0.12-0.16-0.12-0.27c0-0.29,0.01-0.58,0-0.86c0-0.1,0.01-0.23-0.11-0.27c-0.09-0.03-0.17,0.04-0.24,0.1
							c-0.26,0.21-0.52,0.42-0.77,0.64c-1.39,1.23-3.01,2.18-4.19,3.66C531.97,728.77,531.62,729.39,531.05,729.9z M508.28,735.27
							c0.13-0.39,0.47-0.57,0.5-0.91c-0.16-0.07-0.22,0.04-0.31,0.09c-1.15,0.67-2.27,1.41-3.47,1.99c-1.2,0.59-1.47,1.77-2.03,2.78
							c-0.12,0.21,0.02,0.28,0.22,0.19c0.24-0.1,0.46-0.23,0.7-0.32c2.59-1.06,5.19-2.11,7.78-3.19c0.57-0.24,1.21-0.35,1.69-0.76
							c0.84-0.73,1.27-1.77,1.97-2.62c-0.51,0.22-0.84,0.65-1.24,0.99c-0.09-0.15,0.01-0.23,0.06-0.31
							c0.18-0.28,0.36-0.56,0.54-0.84c0.07-0.1,0.14-0.29-0.05-0.25c-0.48,0.11-1.02,0.1-1.38,0.49c-0.25,0.26-0.43,0.58-0.62,0.89
							c-0.42,0.7-0.91,1.2-1.82,0.89c-0.11-0.04-0.24,0-0.36-0.03c-0.33-0.07-0.59,0.05-0.88,0.22
							C509.17,734.8,508.81,735.11,508.28,735.27z M518.28,732.27c0.25,0.04,0.34-0.05,0.44-0.12c0.84-0.56,1.67-1.15,2.49-1.75
							c0.62-0.45,1.06-0.97,1.21-1.74c0.11-0.55,0.25-1.09,0.39-1.64c0.06-0.23,0.04-0.42-0.17-0.57c-0.26-0.18-0.52-0.37-0.77-0.56
							c-0.16-0.12-0.27-0.11-0.24,0.11c0.06,0.47-0.05,0.94-0.06,1.4c-0.02,1.07-0.39,1.98-1.24,2.71
							C519.61,730.77,518.99,731.52,518.28,732.27z"/>
						<path className="st8" d="M450.9,746c-0.03,0-0.06,0-0.09-0.02l-0.04-0.02l-0.03-0.04c-0.13-0.19,0.02-0.33,0.07-0.37
							c0.01-0.01,0.02-0.02,0.02-0.02c0.19-0.19,0.37-0.38,0.56-0.57c0.38-0.39,0.78-0.78,1.14-1.19c0.31-0.34,0.69-0.55,1.23-0.7
							c0.27-0.07,0.54-0.14,0.81-0.22c0.31-0.09,0.63-0.17,0.95-0.26c0.47-0.12,0.88-0.36,1.25-0.73c0.4-0.4,0.81-0.79,1.21-1.17
							c0.64-0.61,1.29-1.24,1.9-1.89c0.54-0.58,1.2-0.79,1.85-0.99l0.06-0.02c0.9-0.28,1.82-0.56,2.7-0.82l0.3-0.09
							c0.29-0.09,0.5-0.29,0.72-0.5l0.07-0.07c0.44-0.42,0.88-0.84,1.32-1.26c0.46-0.44,0.91-0.88,1.37-1.31
							c0.02-0.02,0.03-0.03,0.05-0.05c0.09-0.1,0.21-0.25,0.52-0.22l0.29,0.03l-3.39,3.52c0.36,0,0.6-0.09,0.76-0.38
							c0.13-0.23,0.32-0.38,0.53-0.44c0.61-0.15,1.13-0.49,1.6-0.82l0.16,0.22c-0.04,0.02-0.07,0.05-0.11,0.07l0.11,0.15
							c-1.21,0.91-2.41,1.89-3.57,2.83l-0.34,0.28c-1.07,0.87-2.27,1.57-3.43,2.26l-0.31,0.18c-0.07,0.04-0.13,0.09-0.21,0.14
							c-0.18,0.13-0.38,0.28-0.68,0.34l-0.29,0.06l0.14-0.26c0.15-0.28,0.35-0.48,0.55-0.68c0.07-0.07,0.13-0.14,0.19-0.2
							c-0.29,0.21-0.58,0.47-0.89,0.8c-0.82,0.86-1.86,1.42-3.38,1.81c-2.18,0.55-3.96,1.38-5.44,2.51l-0.02,0.01
							C451.07,745.94,451,746,450.9,746z M466.64,735.7c-0.29,0.28-0.58,0.55-0.87,0.83l-0.07,0.06c-0.24,0.23-0.48,0.46-0.83,0.57
							l-0.3,0.09c-0.89,0.26-1.8,0.54-2.7,0.82l-0.06,0.02c-0.64,0.2-1.24,0.39-1.73,0.92c-0.61,0.66-1.27,1.29-1.91,1.9
							c-0.4,0.38-0.81,0.77-1.2,1.17c-0.4,0.41-0.86,0.67-1.38,0.8c-0.32,0.08-0.64,0.17-0.95,0.25c-0.27,0.07-0.54,0.15-0.81,0.22
							c-0.49,0.13-0.83,0.32-1.1,0.62c-0.37,0.41-0.77,0.81-1.15,1.2c-0.07,0.07-0.15,0.15-0.22,0.22c1.43-1.01,3.11-1.75,5.14-2.27
							c1.47-0.37,2.47-0.91,3.25-1.73c0.33-0.34,0.83-0.84,1.47-1.16l0.32-0.16l-0.13,0.33c-0.12,0.32-0.35,0.55-0.57,0.78
							c-0.07,0.08-0.15,0.15-0.22,0.23c0.05-0.03,0.09-0.07,0.14-0.1c0.07-0.05,0.15-0.11,0.23-0.16l0.31-0.18
							c1.15-0.68,2.35-1.37,3.4-2.23l0.34-0.28c0.93-0.76,1.89-1.54,2.86-2.29c-0.25,0.13-0.52,0.24-0.81,0.31
							c-0.19,0.05-0.31,0.21-0.36,0.3c-0.3,0.54-0.84,0.54-1.32,0.5l-0.29-0.02L466.64,735.7z M464.1,743.98l0.47-0.39
							c0.12-0.1,0.24-0.2,0.35-0.29c0.25-0.21,0.51-0.42,0.75-0.64c1.22-1.12,2.66-1.94,4.04-2.74c0.39-0.22,0.79-0.45,1.18-0.69
							c0.22-0.13,0.44-0.26,0.66-0.38c0.4-0.23,0.82-0.47,1.21-0.73c0.73-0.48,1.56-0.62,2.35-0.7c0.22-0.02,0.36-0.1,0.48-0.25
							c0.4-0.5,0.81-1.01,1.2-1.5c0.28-0.35,0.57-0.7,0.85-1.05c0.22-0.28,0.48-0.44,0.82-0.51c0.86-0.19,1.74-0.39,2.59-0.59
							c0.59-0.14,1.2-0.28,1.8-0.42c0.74-0.17,1.42-0.53,2.08-0.89l0.01-0.01c1.6-0.86,3.17-1.72,4.67-2.55
							c0.37-0.21,0.76-0.25,1.14-0.3c0.09-0.01,0.18-0.02,0.27-0.03c0.58-0.08,1.17-0.15,1.74-0.22c0.29-0.04,0.59-0.07,0.88-0.11
							c0.02,0,0.03,0,0.05-0.01c0.12-0.02,0.29-0.05,0.49,0.09l0.2,0.14l-0.23,0.1c-2.15,0.96-4.35,2.01-6.85,3.29
							c1.17-0.47,2.35-0.91,3.5-1.34c0.67-0.25,1.35-0.5,2.03-0.76c0.58-0.22,1.17-0.2,1.75-0.18c0.27,0.01,0.55,0.02,0.82,0.01
							c0.07-0.01,0.15,0.02,0.2,0.07c0.05,0.05,0.07,0.11,0.07,0.17c0,0.16-0.12,0.25-0.2,0.3l-0.32,0.24
							c-0.07,0.05-0.14,0.1-0.21,0.16c0.13-0.07,0.26-0.14,0.39-0.21l0.13-0.07c0.88-0.49,1.8-0.99,2.89-1.01
							c0.01,0,0.04,0,0.06-0.01c0.08-0.02,0.26-0.05,0.35,0.13c0.1,0.22-0.1,0.34-0.17,0.38c-0.01,0.01-0.03,0.02-0.04,0.02
							c-0.72,0.48-1.44,0.96-2.17,1.44l-1.55,1.03c-0.69,0.46-1.38,0.92-2.08,1.38l-0.27,0.18c-0.32,0.21-0.66,0.43-0.98,0.66
							c-0.39,0.28-0.83,0.38-1.34,0.31c-0.46-0.07-0.93-0.12-1.39-0.18c-0.59-0.07-1.21-0.15-1.81-0.24
							c-0.34-0.05-0.65,0.03-0.99,0.11c-0.06,0.01-0.11,0.03-0.17,0.04c-1.06,0.26-2.15,0.38-3.21,0.49
							c-0.39,0.04-0.78,0.08-1.17,0.13c-0.37,0.05-0.67,0.23-0.99,0.42c-0.11,0.07-0.23,0.14-0.35,0.21c-1.87,1-3.77,2.03-5.6,3.03
							c-1.07,0.58-2.13,1.16-3.2,1.74c-0.98,0.53-2.04,0.92-3.05,1.3c-0.44,0.16-0.89,0.33-1.33,0.51
							c-0.73,0.29-1.45,0.57-2.22,0.78L464.1,743.98z M493.58,729.27c-0.26,0.03-0.53,0.07-0.79,0.1c-0.57,0.07-1.16,0.14-1.73,0.22
							c-0.09,0.01-0.18,0.02-0.27,0.03c-0.37,0.04-0.72,0.08-1.04,0.26c-1.5,0.83-3.07,1.69-4.67,2.55l-0.01,0.01
							c-0.67,0.36-1.37,0.73-2.15,0.91c-0.6,0.14-1.21,0.28-1.8,0.42c-0.85,0.2-1.73,0.41-2.59,0.59c-0.28,0.06-0.48,0.19-0.67,0.42
							c-0.28,0.35-0.57,0.7-0.85,1.06c-0.39,0.49-0.8,1-1.2,1.5c-0.17,0.22-0.38,0.33-0.67,0.36c-0.87,0.09-1.58,0.23-2.22,0.66
							c-0.4,0.26-0.82,0.5-1.22,0.74c-0.22,0.12-0.44,0.25-0.65,0.38c-0.39,0.23-0.79,0.47-1.18,0.69c-1.37,0.79-2.8,1.61-4,2.7
							c-0.19,0.18-0.4,0.35-0.6,0.52c0.52-0.17,1.03-0.37,1.55-0.58c0.44-0.18,0.9-0.35,1.34-0.51c1.01-0.37,2.06-0.76,3.02-1.29
							c1.07-0.58,2.13-1.16,3.2-1.74c1.83-1,3.73-2.03,5.6-3.03c0.11-0.06,0.22-0.13,0.34-0.2c0.33-0.2,0.67-0.41,1.1-0.46
							c0.39-0.05,0.79-0.09,1.18-0.13c1.05-0.11,2.13-0.23,3.17-0.48c0.05-0.01,0.11-0.03,0.16-0.04c0.34-0.09,0.7-0.18,1.1-0.12
							c0.6,0.09,1.21,0.17,1.81,0.24c0.46,0.06,0.93,0.12,1.39,0.18c0.44,0.06,0.81-0.02,1.15-0.26c0.32-0.23,0.66-0.45,0.98-0.66
							l0.27-0.18c0.69-0.46,1.38-0.92,2.08-1.38l1.55-1.03c0.72-0.48,1.45-0.96,2.17-1.44c0.02-0.01,0.04-0.02,0.05-0.03
							c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c-0.03,0.01-0.07,0.01-0.11,0.01c-1.02,0.01-1.87,0.48-2.76,0.97l-0.13,0.07
							c-0.26,0.15-0.53,0.29-0.8,0.43c-0.13,0.07-0.25,0.14-0.38,0.2l-0.17,0.09l-0.03-0.19c-0.04-0.26,0.1-0.35,0.17-0.4
							c0.01-0.01,0.02-0.01,0.02-0.02c0.24-0.18,0.48-0.35,0.72-0.53l0.32-0.24c0.03-0.02,0.05-0.04,0.06-0.05
							c-0.27,0.01-0.54,0-0.8-0.01c-0.57-0.02-1.11-0.04-1.64,0.16c-0.68,0.26-1.36,0.52-2.03,0.76c-1.47,0.55-2.99,1.11-4.46,1.74
							c-0.09,0.04-0.24,0.1-0.46-0.05l-0.19-0.13l0.21-0.11C488.81,731.5,491.23,730.32,493.58,729.27z M428.57,743.94l-0.05-0.27
							c0.02,0,0.05-0.01,0.07-0.01c0.05-0.01,0.09-0.01,0.12-0.02c0.28-0.1,0.56-0.21,0.83-0.31c0.62-0.24,1.26-0.48,1.92-0.66
							c1.07-0.29,2.09-0.68,3.02-1.17c0.08-0.04,0.16-0.09,0.24-0.14c0.24-0.14,0.49-0.28,0.74-0.32l0.37-0.06l-0.44,0.51
							c-0.19,0.22-0.39,0.45-0.58,0.68c0.49-0.24,1.01-0.41,1.52-0.57c0.36-0.11,0.72-0.23,1.07-0.37c0.37-0.15,0.78-0.19,1.28-0.12
							c0.61,0.09,1.2,0.26,1.78,0.43c0.1,0.03,0.21,0.06,0.31,0.09c0.51,0.15,0.92,0.2,1.36,0c0.41-0.19,0.85-0.33,1.27-0.47
							c0.41-0.13,0.84-0.27,1.24-0.46l0.01,0c0.09-0.04,0.32-0.15,0.45,0.09c0.04,0.07,0.05,0.15,0.02,0.22
							c-0.03,0.08-0.1,0.15-0.19,0.19c-0.29,0.12-0.49,0.34-0.71,0.58c-0.09,0.1-0.19,0.2-0.3,0.3c0.07-0.04,0.13-0.09,0.2-0.13
							c0.36-0.24,0.74-0.48,1.13-0.7c0.34-0.19,0.68-0.39,1.02-0.59c0.85-0.5,1.72-1.02,2.67-1.39c0.34-0.13,0.62-0.27,0.87-0.43
							c1.29-0.81,2.47-1.75,3.55-2.64c0.42-0.35,1.07-0.88,1.91-0.39c0.22,0.13,0.5,0.1,0.76-0.08c1.39-0.94,2.64-1.81,3.84-2.78
							c0.02-0.01,0.03-0.03,0.05-0.04c0.11-0.09,0.27-0.23,0.52-0.15l0.13,0.04l-0.05,0.13c-0.04,0.12-0.11,0.22-0.17,0.32
							c-0.1,0.16-0.18,0.29-0.15,0.46c0.01,0.05,0.01,0.1,0,0.15c0,0.03,0,0.09,0,0.1l0,0c0,0,0,0,0.01,0.01
							c0.02,0,0.06-0.05,0.09-0.07c0.02-0.03,0.05-0.05,0.07-0.07c0.11-0.09,0.21-0.19,0.32-0.28c0.32-0.29,0.66-0.58,1.01-0.83
							l0.07-0.05c0.2-0.14,0.32-0.23,0.34-0.42l0.01-0.05l0.07-0.06l0.02-0.01c0.06-0.03,0.13-0.05,0.2-0.07
							c0.3-0.1,0.49-0.18,0.53-0.47l0.02-0.14l0.14,0.02c0.1,0.02,0.16,0.01,0.2-0.02c0.34-0.24,0.69-0.47,1.04-0.69
							c0.56-0.36,1.14-0.74,1.66-1.17l0.02-0.01l0.62-0.34l0.33-0.21l0.04,0c0.21,0,0.41-0.08,0.63-0.19
							c0.61-0.28,1.19-0.62,1.76-0.95c0.67-0.39,1.37-0.79,2.11-1.1c0.22-0.09,0.48-0.17,0.73,0.06c0.57,0.53,0.91,1.04,1.07,1.61
							l0,0.01c0.17,0.03,0.31,0.12,0.41,0.26c0.18,0.26,0.42,0.26,0.79,0.22c0.28-0.03,0.53-0.14,0.79-0.25
							c0.1-0.04,0.2-0.09,0.31-0.13l0.27-0.1l-0.09,0.27c-0.11,0.32-0.27,0.62-0.44,0.92c-0.09,0.16-0.18,0.32-0.27,0.49
							c0.14-0.08,0.29-0.15,0.43-0.22c0.86-0.44,1.68-0.86,2.2-1.67c0.09-0.14,0.21-0.3,0.39-0.32c0.12-0.02,0.24,0.02,0.36,0.12
							c0.39,0.31,0.74,0.2,1.15-0.07c0.06-0.04,0.11-0.07,0.17-0.1c0.04-0.02,0.08-0.04,0.11-0.06c0.16-0.09,0.3-0.12,0.41-0.06
							c0.11,0.05,0.18,0.17,0.21,0.34c0.03,0.18,0.09,0.22,0.36,0.25c0.73,0.08,1.23-0.22,1.72-1.06c0.01-0.02,0.02-0.05,0.04-0.08
							c0.04-0.11,0.13-0.33,0.39-0.29c0.2,0.04,0.22,0.22,0.24,0.33c0.01,0.04,0.01,0.08,0.02,0.1c0.02,0.05,0.04,0.11,0.05,0.17
							c0.04,0.17,0.05,0.17,0.12,0.16c0.23-0.04,0.47-0.08,0.7-0.12c0.67-0.1,1.37-0.21,1.99-0.52c0.81-0.4,1.56-0.93,2.29-1.44
							c0.34-0.24,0.7-0.49,1.06-0.72c1.04-0.68,2.13-0.93,3.13-0.72c1.06,0.22,2.09,0.64,3.09,1.03l0.2,0.08
							c0.32,0.13,0.49,0.12,0.71-0.01l0.11-0.07l0.07,0.1c0.03,0.03,0.12,0.15,0.21,0.13c0.19-0.04,0.39-0.06,0.57-0.08
							c0.43-0.05,0.83-0.1,1.19-0.29c0.17-0.09,0.35-0.18,0.52-0.27c0.6-0.3,1.17-0.59,1.6-1.08c0.08-0.1,0.21-0.14,0.26-0.15
							c0.28-0.07,0.46-0.29,0.65-0.52l0.09-0.1c0.1-0.08,0.22-0.14,0.33-0.2c0.2-0.11,0.38-0.21,0.49-0.4l0.02-0.03l0.03-0.02
							c0.1-0.06,0.2-0.12,0.3-0.18c0.53-0.32,1.07-0.64,1.74-0.68l0.01,0l0.01,0c0.04,0,0.08,0.01,0.12,0.01
							c0.1,0.01,0.2,0.01,0.3,0.04c0.46,0.12,0.84-0.01,1.18-0.42c0.15-0.18,0.32-0.36,0.48-0.52c0.26-0.27,0.51-0.53,0.7-0.83
							c0.4-0.62,0.99-0.9,1.83-0.84c0.22,0.01,0.44,0.02,0.66,0.02c0.22,0,0.44,0,0.66,0.02c0.24,0.02,0.29-0.07,0.31-0.24
							l0.01-0.14l0.14,0.02c0.18,0.02,0.31-0.07,0.47-0.24c0.15-0.16,0.3-0.32,0.45-0.48c0.4-0.43,0.82-0.87,1.26-1.28
							c0.37-0.34,0.79-0.75,1.15-1.24l0.03-0.05l0.06-0.01c0.19-0.03,0.38-0.06,0.56-0.09c0.43-0.07,0.88-0.14,1.32-0.19
							c0.51-0.06,0.95-0.2,1.36-0.44c0.49-0.29,1-0.6,1.45-0.98c0.7-0.58,1.54-0.87,2.57-0.88c1.16-0.02,2.29-0.05,3.38-0.22
							c1.02-0.16,1.96-0.63,2.87-1.09c0.32-0.16,0.66-0.33,0.99-0.49c0.91-0.42,1.3-1.17,1.59-1.91c0.23-0.6,0.69-0.94,1.13-1.27
							c0.28-0.21,0.57-0.42,0.8-0.7l0.18-0.22l0.06,0.28c0.07,0.37-0.07,0.52-0.17,0.62c-0.01,0.01-0.02,0.02-0.03,0.03
							c-0.5,0.55-0.84,1.04-1.08,1.56c-0.09,0.21-0.2,0.41-0.31,0.6c-0.15,0.27-0.29,0.53-0.39,0.81c0.02-0.01,0.04-0.02,0.06-0.03
							c0.27-0.12,0.54-0.25,0.81-0.37c0.23-0.1,0.4-0.24,0.52-0.44c0.59-0.97,1.13-1.95,1.61-2.93c0.17-0.36,0.4-0.64,0.72-0.88
							c0.11-0.09,0.23-0.18,0.34-0.26c1-0.76,2.03-1.56,2.68-2.68c0.22-0.39,0.5-0.77,0.97-0.96l0.63-0.33
							c0.04-0.04,0.09-0.07,0.13-0.11c0.47-0.4,0.96-0.81,1.71-0.77c0.64,0.03,1.29,0.01,1.92,0c0.14,0,0.28-0.01,0.43-0.01
							c0.13,0,0.23,0.03,0.3,0.1c0.1,0.09,0.11,0.23,0.11,0.35c0,0.14,0,0.29,0.01,0.43c0.01,0.27,0.01,0.56,0,0.84
							c0,0.12,0.01,0.2,0.04,0.23c0.03,0.03,0.11,0.05,0.23,0.05c0.44-0.01,0.89,0,1.32,0c0.18,0,0.36,0.01,0.54,0.01l0,0.27
							c-0.18,0-0.36-0.01-0.55-0.01c-0.43-0.01-0.87-0.02-1.31,0c-0.13,0-0.31-0.01-0.43-0.13c-0.12-0.12-0.13-0.31-0.12-0.43
							c0.01-0.27,0-0.55,0-0.82c0-0.15-0.01-0.29-0.01-0.44c0-0.04,0-0.14-0.02-0.16c0,0-0.03-0.02-0.1-0.02
							c-0.14,0-0.28,0.01-0.42,0.01c-0.64,0.02-1.29,0.04-1.94,0c-0.64-0.03-1.07,0.33-1.52,0.71c-0.05,0.04-0.09,0.08-0.14,0.12
							l-0.02,0.02l-0.66,0.34c-0.38,0.15-0.61,0.45-0.84,0.85c-0.68,1.17-1.73,1.98-2.75,2.76c-0.11,0.09-0.23,0.18-0.34,0.26
							c-0.28,0.22-0.48,0.47-0.64,0.78c-0.48,0.99-1.03,1.98-1.62,2.95c-0.15,0.24-0.36,0.42-0.64,0.54
							c-0.27,0.12-0.54,0.25-0.81,0.37c-0.1,0.05-0.2,0.1-0.3,0.14l-0.42,0.66l0.17-0.71l-0.1-0.08l0.14-0.07
							c0.11-0.4,0.3-0.75,0.49-1.1c0.1-0.19,0.21-0.39,0.3-0.58c0.24-0.52,0.57-1.02,1.05-1.56c-0.13,0.11-0.27,0.21-0.41,0.31
							c-0.43,0.32-0.84,0.62-1.04,1.15c-0.31,0.8-0.74,1.61-1.73,2.06c-0.33,0.15-0.66,0.32-0.98,0.48
							c-0.93,0.47-1.88,0.96-2.95,1.12c-1.11,0.17-2.25,0.2-3.42,0.22c-0.98,0.02-1.74,0.28-2.4,0.82c-0.47,0.39-0.99,0.71-1.49,1
							c-0.44,0.26-0.92,0.42-1.47,0.48c-0.44,0.05-0.88,0.12-1.31,0.19c-0.17,0.03-0.34,0.06-0.51,0.08
							c-0.36,0.48-0.78,0.89-1.15,1.23c-0.43,0.4-0.84,0.84-1.24,1.26c-0.15,0.16-0.3,0.32-0.45,0.48
							c-0.15,0.15-0.32,0.31-0.57,0.33c-0.07,0.26-0.26,0.38-0.58,0.36c-0.21-0.01-0.43-0.01-0.64-0.02c-0.22,0-0.45,0-0.67-0.02
							c-0.73-0.05-1.24,0.18-1.58,0.72c-0.21,0.33-0.47,0.6-0.73,0.87c-0.16,0.16-0.32,0.33-0.46,0.51
							c-0.4,0.49-0.89,0.66-1.46,0.51c-0.08-0.02-0.16-0.03-0.25-0.03c-0.04,0-0.08-0.01-0.12-0.01c-0.6,0.04-1.09,0.33-1.6,0.64
							c-0.09,0.05-0.18,0.11-0.27,0.16c-0.15,0.24-0.36,0.35-0.58,0.47c-0.1,0.05-0.2,0.11-0.28,0.17l-0.06,0.07
							c-0.21,0.25-0.42,0.51-0.79,0.61c-0.05,0.01-0.1,0.04-0.13,0.07c-0.47,0.54-1.09,0.85-1.69,1.15
							c-0.17,0.08-0.34,0.17-0.51,0.26c-0.41,0.22-0.86,0.27-1.29,0.32c-0.18,0.02-0.37,0.04-0.55,0.08
							c-0.14,0.03-0.28-0.03-0.4-0.14c-0.29,0.14-0.55,0.09-0.85-0.03l-0.2-0.08c-0.99-0.39-2.01-0.8-3.05-1.02
							c-0.93-0.2-1.94,0.04-2.92,0.68c-0.35,0.23-0.71,0.48-1.05,0.72c-0.74,0.52-1.5,1.05-2.32,1.46
							c-0.66,0.33-1.38,0.44-2.07,0.55c-0.23,0.04-0.46,0.07-0.69,0.12c-0.35,0.07-0.41-0.23-0.44-0.37
							c-0.01-0.05-0.02-0.09-0.03-0.12c-0.02-0.06-0.03-0.11-0.04-0.17c-0.01-0.03-0.01-0.09-0.03-0.11c-0.01,0-0.01,0-0.01,0
							c-0.02,0.01-0.05,0.08-0.06,0.12c-0.02,0.04-0.03,0.08-0.06,0.12c-0.39,0.66-0.92,1.31-1.99,1.19
							c-0.27-0.03-0.53-0.09-0.6-0.48c-0.01-0.09-0.04-0.13-0.06-0.14c-0.02-0.01-0.07,0-0.16,0.05c-0.04,0.02-0.08,0.05-0.13,0.07
							c-0.05,0.03-0.1,0.05-0.15,0.08c-0.42,0.28-0.93,0.5-1.48,0.05c-0.06-0.05-0.11-0.07-0.15-0.06c-0.07,0.01-0.15,0.12-0.2,0.2
							c-0.56,0.87-1.41,1.3-2.3,1.76c-0.26,0.13-0.52,0.27-0.78,0.41l-0.34,0.19l0.15-0.36c0.14-0.33,0.3-0.62,0.45-0.89
							c0.11-0.2,0.22-0.38,0.31-0.58c-0.27,0.12-0.55,0.24-0.87,0.28c-0.36,0.03-0.76,0.07-1.04-0.34c-0.05-0.07-0.12-0.12-0.2-0.14
							c-0.26,0.14-0.52,0.28-0.79,0.43c-0.67,0.36-1.37,0.74-2.04,1.12c-0.36,0.2-0.56,0.59-0.74,0.97
							c-0.63,1.34-1.81,2.12-2.85,2.81c-0.85,0.56-1.7,1.15-2.52,1.72c-0.27,0.19-0.54,0.37-0.81,0.56l-0.01,0.01
							c-0.1,0.07-0.2,0.14-0.33,0.18l0,0.12l-0.12-0.06l-0.98,0.69l0.84-0.92c0.03-0.19,0.16-0.3,0.27-0.4
							c0.03-0.02,0.05-0.04,0.07-0.06c0.7-0.64,1.4-1.29,2.1-1.93c0.24-0.22,0.49-0.45,0.73-0.67c-0.16,0.09-0.32,0.18-0.48,0.27
							c-0.35,0.19-0.71,0.39-1.05,0.62l-0.28,0.19l0.07-0.33c0.07-0.31,0.23-0.53,0.39-0.75c0.05-0.07,0.1-0.13,0.14-0.2
							c0.19-0.29,0.39-0.58,0.59-0.87c0.23-0.34,0.48-0.7,0.7-1.05c0.27-0.43,0.68-0.64,1.08-0.84c0.18-0.09,0.34-0.18,0.5-0.28
							c0.5-0.34,1.03-0.64,1.55-0.93c0.18-0.1,0.35-0.2,0.53-0.3c0.1-0.06,0.26-0.14,0.43-0.16c0.73-0.08,1.47-0.16,2.19-0.24
							l0.53-0.06c-0.17-0.46-0.47-0.89-0.95-1.33c-0.11-0.1-0.2-0.1-0.43-0.01c-0.72,0.3-1.41,0.7-2.08,1.09
							c-0.58,0.33-1.17,0.67-1.79,0.96c-0.24,0.11-0.45,0.2-0.7,0.21l-0.28,0.18l-0.62,0.34c-0.53,0.44-1.11,0.81-1.68,1.18
							c-0.34,0.22-0.69,0.45-1.03,0.68c-0.09,0.06-0.19,0.08-0.28,0.08c-0.11,0.36-0.42,0.47-0.68,0.56
							c-0.04,0.01-0.08,0.03-0.12,0.04c-0.06,0.27-0.25,0.4-0.44,0.53l-0.07,0.05c-0.35,0.25-0.67,0.54-0.99,0.82
							c-0.11,0.09-0.21,0.19-0.32,0.28c-0.02,0.02-0.03,0.03-0.05,0.05c-0.07,0.08-0.2,0.21-0.38,0.14
							c-0.21-0.08-0.2-0.27-0.19-0.38c0-0.03,0-0.07,0-0.09c-0.05-0.27,0.08-0.47,0.19-0.65c0.01-0.01,0.02-0.03,0.02-0.04
							c-0.01,0.01-0.03,0.02-0.05,0.04c-0.02,0.02-0.04,0.03-0.05,0.05c-1.21,0.98-2.46,1.85-3.86,2.79
							c-0.35,0.24-0.74,0.27-1.05,0.09c-0.63-0.37-1.08-0.07-1.6,0.36c-1.09,0.9-2.27,1.84-3.58,2.66
							c-0.26,0.17-0.56,0.31-0.92,0.45c-0.92,0.36-1.79,0.87-2.63,1.37c-0.33,0.2-0.68,0.4-1.02,0.59
							c-0.38,0.21-0.75,0.45-1.11,0.69c-0.16,0.11-0.33,0.21-0.49,0.32c-0.09,0.06-0.18,0.11-0.28,0.18l-0.34,0.22l-0.03-0.22
							c-0.04-0.36,0.2-0.51,0.32-0.58c0.21-0.13,0.38-0.31,0.56-0.5c0.23-0.25,0.47-0.5,0.81-0.64c0.02-0.01,0.03-0.02,0.04-0.03
							c0,0,0-0.01-0.01-0.01c-0.02,0-0.07,0.02-0.09,0.04l-0.01,0c-0.41,0.19-0.85,0.33-1.27,0.47c-0.41,0.13-0.84,0.27-1.24,0.46
							c-0.57,0.27-1.1,0.15-1.55,0.02c-0.11-0.03-0.21-0.06-0.31-0.09c-0.56-0.17-1.14-0.34-1.74-0.42
							c-0.45-0.06-0.81-0.03-1.14,0.1c-0.36,0.14-0.73,0.26-1.09,0.38c-0.52,0.17-1.07,0.34-1.57,0.59c-0.2,0.1-0.3,0-0.34-0.05
							c-0.13-0.18,0.01-0.32,0.06-0.38c0.01-0.01,0.02-0.02,0.02-0.02c0.14-0.17,0.29-0.34,0.43-0.5c-0.05,0.03-0.11,0.06-0.16,0.09
							c-0.08,0.05-0.17,0.1-0.25,0.14c-0.94,0.5-1.98,0.9-3.07,1.19c-0.65,0.17-1.28,0.42-1.89,0.65c-0.27,0.1-0.55,0.21-0.83,0.31
							c-0.06,0.02-0.12,0.03-0.18,0.04C428.61,743.93,428.59,743.93,428.57,743.94z M444.84,740.9
							C444.84,740.9,444.84,740.9,444.84,740.9C444.84,740.9,444.84,740.9,444.84,740.9z M466.61,732.7l0.06,0.16
							c0.09,0.25-0.04,0.37-0.11,0.43l-0.01,0.01c-0.33,0.3-0.65,0.6-0.98,0.9c-0.65,0.6-1.31,1.2-1.96,1.8
							c0.2-0.14,0.4-0.27,0.6-0.41c0.82-0.57,1.68-1.16,2.53-1.72c1.06-0.7,2.15-1.43,2.75-2.7c0.2-0.42,0.43-0.85,0.85-1.09
							c0.68-0.38,1.37-0.76,2.05-1.12c0.16-0.09,0.33-0.18,0.49-0.27l-0.38,0.04c-0.72,0.08-1.46,0.15-2.19,0.24
							c-0.12,0.01-0.24,0.08-0.32,0.13c-0.18,0.1-0.35,0.2-0.53,0.3c-0.51,0.29-1.04,0.59-1.53,0.92c-0.17,0.11-0.35,0.21-0.53,0.3
							c-0.38,0.2-0.74,0.38-0.97,0.75c-0.23,0.36-0.47,0.72-0.71,1.06c-0.19,0.28-0.39,0.57-0.58,0.86
							c-0.05,0.07-0.1,0.14-0.15,0.21c-0.07,0.09-0.13,0.18-0.19,0.28c0.23-0.14,0.47-0.27,0.7-0.4c0.32-0.18,0.65-0.36,0.96-0.56
							L466.61,732.7z M503.05,739.58c-0.08,0-0.14-0.03-0.19-0.08c-0.05-0.05-0.1-0.16,0-0.35c0.13-0.23,0.24-0.48,0.36-0.71
							c0.38-0.81,0.77-1.65,1.74-2.12c0.82-0.4,1.62-0.89,2.4-1.36c0.35-0.21,0.7-0.43,1.06-0.63c0.01-0.01,0.03-0.02,0.04-0.03
							c0.07-0.05,0.2-0.15,0.39-0.06l0.09,0.04l-0.01,0.1c-0.02,0.22-0.15,0.38-0.26,0.54c-0.03,0.03-0.05,0.07-0.08,0.1
							c0.21-0.1,0.4-0.22,0.58-0.34c0.11-0.07,0.22-0.15,0.34-0.21c0.29-0.17,0.59-0.32,0.98-0.23c0.04,0.01,0.08,0.01,0.13,0.01
							c0.07,0,0.16,0,0.24,0.02c0.8,0.27,1.24-0.14,1.66-0.83l0.02-0.03c0.18-0.31,0.37-0.62,0.62-0.88
							c0.31-0.33,0.72-0.4,1.12-0.46c0.12-0.02,0.23-0.04,0.33-0.06c0.15-0.04,0.23,0.03,0.26,0.07c0.09,0.12,0,0.29-0.06,0.38
							c-0.07,0.1-0.13,0.21-0.2,0.31c-0.06,0.09-0.11,0.17-0.17,0.26c0.24-0.23,0.5-0.47,0.84-0.61l0.52-0.22l-0.36,0.44
							c-0.27,0.33-0.51,0.69-0.74,1.05c-0.36,0.56-0.73,1.13-1.25,1.58c-0.35,0.31-0.79,0.46-1.21,0.6
							c-0.17,0.06-0.35,0.12-0.51,0.19c-1.77,0.74-3.58,1.47-5.33,2.19c-0.82,0.33-1.63,0.67-2.45,1c-0.13,0.05-0.26,0.12-0.38,0.18
							c-0.1,0.05-0.21,0.1-0.31,0.15C503.18,739.56,503.11,739.58,503.05,739.58z M503.07,739.35L503.07,739.35
							C503.07,739.35,503.07,739.35,503.07,739.35z M508.58,734.54c-0.01,0.01-0.02,0.02-0.04,0.02c-0.35,0.21-0.71,0.42-1.06,0.63
							c-0.78,0.47-1.59,0.96-2.42,1.37c-0.88,0.43-1.23,1.19-1.61,1.99c-0.11,0.24-0.23,0.49-0.36,0.73c0,0.01-0.01,0.01-0.01,0.02
							c0.02,0,0.03-0.01,0.06-0.02c0.1-0.04,0.2-0.09,0.3-0.14c0.13-0.06,0.26-0.13,0.4-0.18c0.82-0.33,1.63-0.67,2.45-1
							c1.75-0.71,3.55-1.45,5.32-2.19c0.17-0.07,0.35-0.13,0.53-0.19c0.42-0.14,0.81-0.27,1.12-0.55c0.49-0.43,0.84-0.96,1.2-1.53
							c0.09-0.14,0.18-0.28,0.27-0.41c-0.07,0.06-0.14,0.13-0.21,0.2c-0.11,0.11-0.23,0.23-0.36,0.33l-0.12,0.1l-0.08-0.14
							c-0.12-0.2-0.02-0.35,0.04-0.42c0.01-0.01,0.02-0.02,0.02-0.03c0.11-0.18,0.23-0.36,0.34-0.54c0.07-0.1,0.13-0.2,0.2-0.31
							c0.01-0.01,0.01-0.02,0.01-0.02c-0.09,0.02-0.18,0.03-0.27,0.05c-0.37,0.06-0.72,0.12-0.96,0.38
							c-0.23,0.24-0.41,0.54-0.58,0.84l-0.02,0.03c-0.4,0.67-0.95,1.3-1.98,0.95c-0.04-0.01-0.09-0.01-0.15-0.01
							c-0.06,0-0.13,0-0.2-0.01c-0.29-0.06-0.53,0.05-0.79,0.2c-0.11,0.06-0.22,0.13-0.33,0.21c-0.29,0.19-0.59,0.38-0.98,0.5
							l-0.25,0.08l0.08-0.25c0.07-0.21,0.18-0.36,0.29-0.49C508.49,734.67,508.54,734.6,508.58,734.54z M518.39,732.42
							c-0.04,0-0.09,0-0.14-0.01l-0.25-0.04l0.18-0.19c0.22-0.23,0.44-0.47,0.64-0.69c0.47-0.52,0.92-1.01,1.42-1.45
							c0.78-0.68,1.17-1.53,1.19-2.61c0-0.18,0.02-0.35,0.04-0.53c0.03-0.3,0.06-0.58,0.02-0.86c-0.02-0.16,0.01-0.26,0.1-0.31
							c0.09-0.06,0.21-0.03,0.35,0.07c0.24,0.18,0.5,0.36,0.74,0.53l0.03,0.02c0.34,0.24,0.25,0.59,0.22,0.71
							c-0.03,0.13-0.06,0.26-0.1,0.38c-0.1,0.41-0.21,0.83-0.29,1.25c-0.14,0.72-0.54,1.3-1.27,1.82c-0.92,0.66-1.7,1.22-2.5,1.75
							c-0.01,0.01-0.02,0.02-0.04,0.02C518.68,732.35,518.58,732.42,518.39,732.42z M521.76,726C521.76,726,521.76,726,521.76,726
							c0.04,0.31,0.01,0.62-0.02,0.92c-0.02,0.17-0.03,0.34-0.04,0.5c-0.02,1.16-0.44,2.08-1.28,2.81c-0.49,0.43-0.93,0.91-1.4,1.43
							c-0.09,0.1-0.18,0.19-0.27,0.29c0.76-0.51,1.5-1.04,2.38-1.67c0.67-0.49,1.03-1,1.16-1.65c0.08-0.43,0.19-0.85,0.29-1.26
							c0.03-0.13,0.06-0.25,0.1-0.38c0.05-0.21,0.02-0.33-0.11-0.42l-0.03-0.02c-0.25-0.17-0.5-0.35-0.75-0.54
							C521.78,726.01,521.77,726.01,521.76,726z M531.27,730.08c-0.08,0-0.17-0.02-0.28-0.06l-0.21-0.08l0.17-0.15
							c0.38-0.34,0.67-0.74,0.95-1.13c0.13-0.17,0.26-0.36,0.39-0.53c0.78-0.99,1.77-1.75,2.73-2.49c0.49-0.38,1-0.77,1.47-1.19
							c0.17-0.15,0.35-0.3,0.52-0.44c0.08-0.07,0.17-0.14,0.25-0.21c0.09-0.07,0.21-0.17,0.37-0.13c0.21,0.06,0.21,0.27,0.21,0.37
							l0,0.03c0,0.17,0,0.34,0,0.51c0,0.12,0,0.24,0,0.36v0.01c0,0.11,0.01,0.13,0.03,0.13c0.01,0,0.04-0.03,0.06-0.04
							c0.02-0.02,0.04-0.03,0.07-0.05c0.66-0.41,0.89-0.87,0.77-1.5c-0.08-0.39,0.12-0.68,0.28-0.86c0.31-0.36,0.6-0.75,0.89-1.12
							c0.09-0.11,0.17-0.22,0.26-0.34c0.2-0.26,0.44-0.36,0.72-0.3c0.75,0.16,1.26-0.2,1.89-0.73c0.88-0.72,1.38-1.71,1.86-2.66
							c0.21-0.41,0.42-0.83,0.67-1.23c0.2-0.32,0.37-0.67,0.54-1.01c0.08-0.17,0.17-0.35,0.26-0.52l0.01-0.02
							c0.04-0.08,0.09-0.17,0.18-0.22c0.34-0.17,0.64-0.4,0.96-0.65c0.55-0.44,1.12-0.89,1.94-0.94c0.57-0.03,1.15-0.15,1.71-0.26
							c0.54-0.1,1.09-0.21,1.65-0.25c0.01,0,0.04-0.01,0.06-0.01c0.08-0.02,0.26-0.08,0.36,0.11c0.12,0.23-0.08,0.35-0.15,0.39
							c-0.02,0.01-0.04,0.03-0.05,0.04c-0.31,0.29-0.62,0.57-0.93,0.85c-0.18,0.17-0.37,0.33-0.55,0.5c-0.33,0.3-0.49,0.76-0.64,1.2
							l-0.04,0.13c-0.23,0.65-0.67,1.12-1.36,1.42c-0.78,0.34-1.33,0.88-1.68,1.65c-0.24,0.53-0.7,0.85-1.14,1.16l-0.01,0.01
							c-0.27,0.19-0.54,0.38-0.81,0.56c-1.15,0.8-2.34,1.63-3.37,2.64c-1.18,1.16-2.45,2.26-3.67,3.33l-0.35,0.3
							c-0.47,0.41-1.06,0.63-1.63,0.84c-0.09,0.03-0.18,0.07-0.27,0.1c-1.39,0.53-2.72,1.25-4,1.94c-0.23,0.12-0.46,0.25-0.68,0.37
							c-0.01,0.01-0.03,0.02-0.04,0.02C531.55,730.02,531.43,730.08,531.27,730.08z M537.55,723.95c-0.02,0-0.07,0.04-0.1,0.07
							c-0.09,0.08-0.17,0.14-0.26,0.21c-0.17,0.14-0.34,0.28-0.51,0.43c-0.48,0.42-0.99,0.82-1.49,1.2
							c-0.94,0.73-1.92,1.48-2.68,2.44c-0.13,0.17-0.26,0.35-0.39,0.52c-0.24,0.33-0.48,0.66-0.78,0.97
							c0.06-0.01,0.1-0.04,0.16-0.07c0.02-0.01,0.03-0.02,0.05-0.03c0.23-0.12,0.46-0.24,0.68-0.37c1.29-0.7,2.63-1.42,4.03-1.96
							c0.09-0.03,0.18-0.07,0.27-0.1c0.54-0.2,1.11-0.41,1.54-0.79l0.35-0.3c1.22-1.06,2.49-2.16,3.66-3.32
							c1.04-1.03,2.25-1.86,3.41-2.67c0.27-0.19,0.54-0.37,0.8-0.56l0.01-0.01c0.43-0.3,0.83-0.59,1.05-1.05
							c0.38-0.83,0.97-1.42,1.82-1.79c0.63-0.28,1.01-0.68,1.22-1.26l0.04-0.13c0.16-0.45,0.33-0.97,0.72-1.32
							c0.18-0.17,0.37-0.33,0.55-0.5c0.3-0.27,0.62-0.56,0.92-0.84c0.03-0.03,0.07-0.05,0.1-0.07c0,0,0,0,0,0
							c-0.03,0.01-0.07,0.02-0.11,0.02c-0.54,0.04-1.09,0.15-1.62,0.25c-0.57,0.11-1.16,0.23-1.75,0.26
							c-0.73,0.04-1.24,0.45-1.79,0.88c-0.31,0.25-0.64,0.51-1.01,0.69c-0.01,0.01-0.04,0.07-0.06,0.1l-0.01,0.02
							c-0.09,0.17-0.18,0.34-0.26,0.51c-0.17,0.34-0.34,0.69-0.55,1.03c-0.24,0.39-0.45,0.81-0.66,1.22
							c-0.5,0.97-1.01,1.98-1.93,2.74c-0.67,0.55-1.26,0.96-2.12,0.78c-0.18-0.04-0.31,0.02-0.45,0.2
							c-0.09,0.11-0.17,0.22-0.26,0.33c-0.29,0.38-0.58,0.77-0.9,1.14c-0.19,0.23-0.26,0.42-0.22,0.63
							c0.15,0.74-0.15,1.32-0.89,1.79c-0.01,0.01-0.03,0.02-0.04,0.03c-0.06,0.05-0.17,0.13-0.32,0.08
							c-0.21-0.07-0.21-0.28-0.21-0.39v-0.01c0-0.12,0-0.24,0-0.36c0-0.17,0-0.34,0-0.5l0-0.03c0-0.03,0-0.09-0.01-0.1l0,0
							C537.57,723.96,537.56,723.96,537.55,723.95C537.56,723.95,537.56,723.95,537.55,723.95z M517.79,726.89
							c-0.14,0-0.28-0.03-0.43-0.09c-0.28-0.11-0.58-0.2-0.87-0.28c-0.37-0.11-0.75-0.23-1.12-0.38c-0.5-0.21-1.04-0.1-1.56,0
							l-0.11,0.02c-0.98,0.19-1.81-0.16-2.61-0.49c-0.14-0.06-0.28-0.12-0.43-0.18c-0.15-0.06-0.21-0.15-0.22-0.22
							c-0.02-0.07-0.02-0.18,0.08-0.31c0.23-0.3,0.45-0.6,0.67-0.9c0.83-1.13,1.68-2.3,2.89-3.13c0.04-0.03,0.09-0.04,0.13-0.05
							c0.01,0,0.02-0.01,0.03-0.01l0.07-0.02l0.23,0.19l-0.29,0.4c0.01-0.01,0.03-0.02,0.04-0.03c0.33-0.23,0.67-0.46,1-0.71
							c0.68-0.51,1.46-0.87,2.39-1.08c0.29-0.07,0.59-0.15,0.87-0.22c0.25-0.07,0.5-0.14,0.76-0.2c0.99-0.24,1.75-0.92,2.48-1.57
							l0.08-0.07c1.16-1.03,2.47-1.67,3.61-2.15c0.23-0.1,0.45-0.21,0.68-0.33c0.39-0.2,0.79-0.41,1.26-0.55l0.32-0.09l-0.16,0.29
							c-0.17,0.3-0.41,0.44-0.61,0.56c-0.06,0.04-0.13,0.07-0.18,0.11c-0.52,0.36-1.06,0.72-1.58,1.07
							c-0.53,0.36-1.08,0.73-1.62,1.09c-0.56,0.39-1.01,0.92-1.43,1.43c-0.2,0.24-0.4,0.48-0.61,0.71
							c-0.07,0.07-0.13,0.15-0.19,0.22c1.85-1.15,3.76-2.33,6.02-2.89l0.34-0.09l-0.2,0.29c-0.18,0.27-0.4,0.43-0.59,0.58
							c-0.07,0.05-0.13,0.1-0.19,0.15c-0.85,0.72-1.63,1.55-2.38,2.34c-0.2,0.21-0.4,0.42-0.6,0.63c-0.64,0.66-1.28,1.32-1.92,1.98
							c-0.4,0.42-0.81,0.83-1.21,1.25c-0.03,0.03-0.05,0.05-0.08,0.07c-0.01,0.01-0.02,0.01-0.03,0.02
							c0.02-0.01,0.04-0.02,0.06-0.03c0.02-0.01,0.04-0.02,0.05-0.02c0.62-0.27,1.17-0.06,1.7,0.14c0.06,0.02,0.12,0.05,0.18,0.07
							c0.08,0.03,0.29,0.1,0.27,0.32c-0.02,0.19-0.21,0.25-0.3,0.27c-0.12,0.03-0.23,0.07-0.34,0.1c-0.28,0.08-0.57,0.17-0.86,0.22
							c-1.1,0.18-2.01,0.75-2.84,1.33C518.21,726.82,518.01,726.89,517.79,726.89z M514.73,725.75c0.25,0,0.5,0.04,0.74,0.14
							c0.36,0.15,0.73,0.26,1.1,0.37c0.29,0.09,0.59,0.18,0.88,0.29c0.31,0.12,0.54,0.09,0.81-0.1c0.85-0.6,1.8-1.19,2.95-1.38
							c0.28-0.05,0.56-0.13,0.83-0.21c0.11-0.03,0.23-0.07,0.34-0.1c0.03-0.01,0.08-0.02,0.1-0.03c-0.01-0.01-0.03-0.02-0.08-0.04
							c-0.06-0.02-0.12-0.05-0.19-0.07c-0.5-0.19-0.98-0.37-1.5-0.14c-0.01,0.01-0.02,0.01-0.04,0.02c-0.07,0.04-0.18,0.09-0.3,0.05
							c-0.05-0.02-0.11-0.06-0.16-0.15c-0.09-0.19,0.07-0.31,0.14-0.36c0.02-0.01,0.04-0.03,0.05-0.04c0.4-0.42,0.81-0.83,1.21-1.25
							c0.64-0.66,1.28-1.32,1.92-1.98c0.2-0.21,0.4-0.42,0.6-0.63c0.76-0.8,1.54-1.63,2.4-2.36c0.07-0.06,0.14-0.11,0.2-0.16
							c0.08-0.06,0.16-0.12,0.24-0.19c-2.08,0.6-3.88,1.73-5.63,2.82l-0.83,0.52l0.24-0.45c0.15-0.28,0.34-0.54,0.57-0.79
							c0.21-0.22,0.41-0.46,0.6-0.7c0.44-0.53,0.9-1.07,1.49-1.48c0.54-0.37,1.09-0.74,1.62-1.1c0.52-0.35,1.05-0.71,1.58-1.07
							c0.06-0.04,0.13-0.08,0.2-0.12c-0.18,0.09-0.36,0.18-0.54,0.27c-0.23,0.12-0.46,0.24-0.7,0.34c-1.12,0.47-2.41,1.09-3.53,2.1
							l-0.08,0.07c-0.75,0.68-1.53,1.38-2.6,1.63c-0.25,0.06-0.5,0.13-0.75,0.2c-0.29,0.08-0.58,0.16-0.88,0.23
							c-0.89,0.21-1.64,0.55-2.29,1.04c-0.33,0.25-0.67,0.49-1.01,0.71c-0.15,0.1-0.3,0.21-0.45,0.31l-0.09,0.07l-0.23-0.23
							l0.33-0.46c-1.06,0.79-1.84,1.86-2.6,2.9c-0.22,0.3-0.44,0.61-0.67,0.91c-0.03,0.04-0.04,0.07-0.03,0.08
							c0,0.01,0.02,0.02,0.06,0.04c0.14,0.06,0.29,0.12,0.43,0.18c0.8,0.33,1.56,0.65,2.45,0.48l0.11-0.02
							C514.07,725.82,514.4,725.75,514.73,725.75z"/>
					</g>
					<g>
						<path className="st73" d="M573.25,703.3c-0.01,0-0.02-0.01-0.03-0.01c-0.06-0.01-0.11-0.02-0.17-0.03
							c-0.5-0.04-0.93-0.19-1.34-0.46c-0.71-0.23-1.43-0.47-2.14-0.7c-0.63-0.2-1.27-0.39-1.87-0.69c-0.6-0.3-1.16-0.68-1.69-1.09
							c-1.06-0.81-2.02-1.72-3.1-2.5c-0.05-0.04-0.1-0.08-0.13-0.13c-0.91-0.33-1.81-0.72-2.71-1.07c-1-0.39-1.99-0.77-3-1.11
							c-3.45-1.14-7.35-2.18-9.9-4.95c-0.02-0.02-0.03-0.04-0.05-0.06c-1.05-0.74-2.12-1.31-3.33-1.74
							c-1.19-0.42-2.39-0.79-3.53-1.31c-0.07-0.03-0.12-0.07-0.16-0.11c-0.16-0.04-0.31-0.1-0.41-0.24
							c-0.48-0.15-0.81-0.52-1.19-0.81c-0.06-0.05-0.12-0.1-0.18-0.14c-0.98-0.38-1.92-0.85-2.82-1.38
							c-1.1-0.65-2.16-1.42-3.03-2.37c-0.24-0.1-0.48-0.19-0.71-0.29c-1.7-0.49-3.41-0.98-5.11-1.48c-0.35-0.1-0.77-0.08-1-0.44
							c-0.06-0.04-0.12-0.09-0.17-0.13c-1.01-0.22-2-0.48-3-0.73c-0.32,0.51-0.74,0.87-1.45,0.84c-1-0.03-2-0.02-3-0.01
							c-1,0.01-1.95,0.25-2.85,0.66c-0.08,0.04-0.2,0.06-0.19,0.16c0.01,0.14,0.15,0.13,0.25,0.15c0.99,0.23,1.97,0.49,3,0.41
							c0.37-0.03,0.69,0.09,0.99,0.29c1.44,0.97,2.73,2.12,3.91,3.38c0.08,0.09,0.21,0.16,0.15,0.3c-0.06,0.14-0.21,0.12-0.32,0.11
							c-0.95-0.06-1.91-0.03-2.86-0.16c-0.22-0.03-0.4-0.14-0.57-0.27c-1.73-1.23-3.59-2.26-5.33-3.48
							c-0.29-0.21-0.61-0.3-0.98-0.28c-0.31,0.02-0.64,0-0.91-0.2c-0.04-0.03-0.11-0.07-0.16-0.06c-1.16,0.25-2.27-0.17-3.4-0.27
							c-1.63-0.14-3.23-0.5-4.86-0.63c-0.72-0.06-1.44-0.23-2.16,0.03l-0.67,0.23c-0.73,0.29-1.46,0.58-2.19,0.88
							c-0.48,0.2-0.99,0.13-1.49,0.12c-0.28-0.01-0.17-0.18-0.05-0.29c0.44-0.41,1-0.62,1.54-0.86c0.34-0.15,0.57-0.34,0.73-0.69
							c0.67-1.49,0.68-1.45,2.29-1.53c1.16-0.06,2.33,0.07,3.47-0.26c0.09-0.03,0.19-0.04,0.27-0.08c-1.97,0-3.94-0.01-5.89,0.16
							c-0.08,0.13-0.21,0.22-0.3,0.34c-0.45,0.55-0.46,0.56-0.9,0.03c-0.24-0.29-0.37-0.07-0.48,0.09c-0.22,0.31-0.41,0.63-0.8,0.76
							c-0.09,0.03-0.19,0.11-0.25,0.04c-0.55-0.58-0.99,0.04-1.48,0.13l-0.25,0.36c-0.1,0.32-0.53,0.52-0.45,0.86
							c0.08,0.31,0.46,0.45,0.72,0.65c0.35,0.27,0.57,0.66,0.7,1.04c0.19,0.56,0.53,1.05,0.74,1.6c0.24,0.64,0.81,1.01,1.23,1.5
							c0.19,0.22,0.45,0.4,0.58,0.72c-0.73-0.12-1.35-0.23-1.75-0.96c-0.55-1.01-1.25-1.94-1.87-2.91c-0.2-0.32-0.4-0.5-0.75-0.18
							c-0.12,0.11-0.27,0.2-0.41,0.28c-0.29,0.16-0.33,0.39-0.22,0.69c0.21,0.6,0.01,1.17-0.15,1.73c-0.06,0.22-0.08,0.47-0.18,0.65
							c-0.28,0.52-0.05,0.86,0.34,1.11c0.55,0.35,0.57,0.8,0.46,1.39c-0.17,0.97-0.26,0.74,0.56,1.16c0.39,0.2,0.79,0.39,1.17,0.6
							c0.18,0.09,0.35,0.15,0.33-0.13c-0.08-1.09,0.87-1.21,1.5-1.63c0.09-0.06,0.2-0.05,0.22,0.07c0.07,0.66,0.5,1.28,0.2,1.99
							c-0.12,0.28-0.23,0.46-0.54,0.4c-0.64-0.12-1.15,0.15-1.64,0.51c-0.46,0.34-1,0.55-1.4,0.99c0.91-0.13,1.85,0.21,2.72-0.32
							c0.57-0.35,1.22-0.07,1.82-0.02c0.63,0.04,0.63,0.09,0.52,0.73c-0.13,0.77-0.26,1.55-0.3,2.36c0.22-0.34,0.48-0.67,0.66-1.03
							c0.49-1.02,1.31-1.68,2.29-2.19c0.08-0.04,0.16-0.14,0.24-0.07c0.11,0.08,0.02,0.2-0.01,0.29c-0.1,0.35-0.2,0.69-0.23,1.06
							c-0.03,0.35,0.13,0.43,0.39,0.48c0.31,0.05,0.63,0.09,0.94,0.13c0.07,0.01,0.16,0,0.2,0.08c0.05,0.11-0.04,0.17-0.11,0.22
							c-1.05,0.66-1.48,1.78-2.09,2.76c-0.18,0.28-0.36,0.56-0.53,0.85c-0.17,0.29-0.33,0.56-0.74,0.31
							c-0.18-0.11-0.3,0.06-0.43,0.16c-0.87,0.64-1.49,1.45-1.71,2.53c-0.02,0.08-0.09,0.16-0.13,0.24
							c-0.07,0.13-0.32,0.26-0.11,0.41c0.18,0.13,0.27-0.13,0.37-0.24c0.63-0.63,1.23-1.3,1.89-1.91c0.39-0.36,0.91-0.55,1.39-0.76
							c0.75-0.33,1.38-0.8,1.96-1.38c0.28-0.28,0.58-0.53,0.88-0.79c0.34-0.3,0.71-0.48,1.18-0.52c0.48-0.03,0.96-0.16,1.44-0.23
							c0.24-0.03,0.36-0.17,0.22-0.36c-0.57-0.81-0.05-1.45,0.3-2.12c0.35-0.66,0.71-1.31,1.05-1.96c0.05-0.09,0.05-0.2,0.07-0.31
							l0,0c-0.22,0.12-0.34,0.33-0.49,0.53c-0.53,0.69-1.08,1.38-1.62,2.07c-0.05,0.06-0.1,0.12-0.23,0.06
							c0.15-0.6,0.31-1.21,0.46-1.82c0.07-0.27,0.11-0.53,0.01-0.8c-0.16-0.44-0.23-0.47-0.58-0.18c-0.09,0.07-0.15,0.19-0.32,0.2
							c-0.07-0.35,0.19-0.57,0.3-0.82c0.56-1.21,1.15-2.4,1.73-3.6c0.11-0.23,0.27-0.16,0.46-0.12c0.8,0.16,1.4,0.6,1.88,1.25
							c0.19,0.25,0.43,0.47,0.65,0.7c0,0,0.31,0.18,0.47,0.26c0.36-0.02,0.72-0.03,1.08-0.05l0.29-0.03
							c0.49-0.06,0.84,0.09,1.22,0.47c1.29,1.3,2.64,2.55,4.14,3.62c0.58,0.41,1.29,0.46,1.96,0.52c2.13,0.17,3.99,1.04,5.83,2.04
							c0.29,0.16,0.38,0.27,0.05,0.53c-0.65,0.51-0.63,0.54-0.25,1.25c0.08,0.16,0.17,0.33,0.28,0.46c0.48,0.56,0.37,1.2,0.3,1.84
							c-0.19,0.02-0.2-0.12-0.22-0.21c-0.14-0.76-0.68-1.27-1.12-1.85c-0.15-0.19-0.26-0.17-0.36,0.05
							c-0.17,0.37-0.35,0.74-0.53,1.11c-0.09,0.19-0.09,0.37-0.02,0.57c0.34,0.99,0.65,1.99,1,2.97c0.09,0.25,0.13,0.49,0.12,0.75
							c-0.04,0.8-0.07,1.59-0.11,2.39c-0.04,0-0.08,0.01-0.12,0.01c-0.13-0.53-0.33-1.05-0.38-1.59c-0.11-1.03-0.86-1.63-1.44-2.35
							c-0.12-0.15-0.21-0.06-0.28,0.04c-0.25,0.35-0.49,0.69-0.72,1.05c-0.11,0.17,0.01,0.32,0.09,0.46c0.3,0.58,0.6,1.16,0.91,1.73
							c0.11,0.21,0.14,0.38-0.02,0.58c-0.57,0.69-0.52,1.44-0.19,2.21c0.09,0.21,0.12,0.44,0.05,0.67
							c-0.26,0.84-0.52,1.68-0.79,2.52c-0.03,0.09-0.03,0.22-0.21,0.26c-0.08-0.59-0.19-1.16-0.25-1.74
							c-0.05-0.47-0.22-0.86-0.55-1.21c-0.35-0.38-0.65-0.8-0.96-1.2c-0.14-0.19-0.25-0.26-0.38,0.01c-0.5,1.05-1.03,2.09-1.5,3.16
							c-0.35,0.81-0.05,1.55,0.17,2.3c0.08,0.27,0.45,0.28,0.68,0.41c0.32,0.18,0.71,0.23,1.02,0.6c-0.39-0.04-0.69,0.06-0.99,0.12
							c-0.54,0.11-0.95-0.15-1.34-0.44c-0.37-0.28-0.67-0.29-1.1-0.06c-0.86,0.47-1.46,1.09-1.74,2.05
							c-0.13,0.46-0.34,0.91-0.51,1.36c0.63-0.1,1.25-0.21,1.88-0.28c0.51-0.06,0.98-0.2,1.41-0.46c0.51-0.3,1.01-0.61,1.47-0.99
							c0.72-0.6,1.54-0.84,2.48-0.85c1.13-0.02,2.28-0.05,3.4-0.22c1.41-0.22,2.62-1,3.89-1.59c0.91-0.42,1.33-1.15,1.66-1.99
							c0.36-0.92,1.29-1.19,1.91-1.93c0.07,0.33-0.07,0.43-0.16,0.53c-0.44,0.48-0.83,1-1.1,1.6c-0.26,0.58-0.65,1.09-0.8,1.72
							c0.38-0.18,0.76-0.36,1.15-0.53c0.24-0.11,0.44-0.26,0.58-0.49c0.58-0.96,1.13-1.94,1.62-2.94c0.17-0.34,0.38-0.6,0.68-0.83
							c1.13-0.88,2.32-1.71,3.06-2.98c0.22-0.38,0.48-0.73,0.91-0.9l0.64-0.34c0.52-0.43,0.99-0.9,1.76-0.86
							c0.78,0.04,1.57,0,2.36-0.02c0.25-0.01,0.27,0.13,0.27,0.31c0,0.42,0.02,0.84,0.01,1.27c-0.01,0.31,0.09,0.43,0.42,0.42
							c0.62-0.02,1.24,0.01,1.86,0.01c0.13,0,0.25,0,0.38,0c0.91,0.07,1.63,0.6,2.43,0.96c0.03,0.01,0.06,0.08,0.05,0.11
							c-0.02,0.1-0.11,0.1-0.18,0.09c-0.35-0.01-0.69,0.09-1.03,0.16c-0.38,0.08-0.6,0.3-0.59,0.69c0.01,0.42,0.06,0.85,0.08,1.27
							c0.02,0.37,0.2,0.52,0.57,0.54c1.51,0.11,3.02,0.26,4.54,0.22c0.23-0.01,0.45,0.03,0.66,0.14c0.71,0.37,1.28,0.91,1.89,1.41
							c0.07,0.06,0.18,0.09,0.15,0.2c-0.04,0.13-0.16,0.11-0.27,0.11c-0.63,0-1.27,0.12-1.9-0.02c-0.11-0.02-0.25-0.01-0.35,0.04
							c-0.97,0.5-1.71,1.27-2.51,2.17c0.84-0.07,1.56-0.13,2.28-0.19l0.29-0.07c0.18-0.01,0.36-0.03,0.54-0.04l0.18-0.01
							c0.15-0.02,0.3-0.05,0.46-0.07l0.64-0.05c0.1,0,0.21,0,0.31-0.01l0.41-0.08c0.24-0.02,0.48-0.05,0.72-0.07
							c0.07-0.01,0.13-0.03,0.19-0.04c0.42-0.04,0.84-0.16,1.27-0.12l0.73,0c0.08,0,0.15,0,0.23,0.01c1.67,0.34,3.17,1.1,4.64,1.92
							c0.07,0.04,0.14,0.08,0.13,0.17c-0.01,0.12-0.12,0.12-0.21,0.14c-0.38,0.05-0.75,0.11-1.13,0.14c-0.81,0.07-1.52,0.3-1.9,1.1
							c-0.04,0.09-0.15,0.18-0.06,0.28c0.08,0.1,0.19,0.03,0.29,0.01c1.35-0.31,2.67-0.75,4.04-0.98c0.96-0.16,1.9-0.33,2.88-0.08
							c2,0.5,3.94,1.21,5.86,1.96 M510.25,683.49c-0.93-0.34-1.82-0.06-2.73,0.08c-0.01,0-0.03,0.02-0.04,0.02
							c-1.71,0.03-1.96,1.55-2.63,2.62c-0.45,0.73-0.85,1.49-1.27,2.24c-0.15-0.05-0.13-0.16-0.14-0.24
							c-0.03-0.89-0.09-1.78-0.05-2.67c0.02-0.43,0.36-0.79,0.61-1.15c0.65-0.92,0.66-0.92-0.26-1.47c-0.73-0.44-1.46-0.86-2.18-1.3
							c-0.24-0.15-0.5-0.12-0.75-0.12c-0.41-0.01-0.83,0.06-1.25-0.1c0.47-0.07,0.93-0.16,1.4-0.2c0.87-0.09,1.75-0.14,2.63-0.23
							c0.55-0.05,1.03,0.14,1.45,0.44c0.95,0.68,2.04,0.96,3.15,1.24c0.94,0.24,1.85,0.59,2.77,0.89c0.05,0.02,0.09,0.06,0.21,0.14
							C510.77,683.72,510.5,683.59,510.25,683.49z"/>
						<path className="st8" d="M514.44,715.57l0.08-0.22c0.05-0.14,0.11-0.28,0.17-0.43c0.12-0.3,0.25-0.61,0.34-0.93
							c0.26-0.92,0.84-1.6,1.8-2.13c0.48-0.26,0.84-0.24,1.25,0.07c0.37,0.28,0.74,0.52,1.23,0.42c0.05-0.01,0.11-0.02,0.16-0.04
							c0.16-0.04,0.32-0.07,0.5-0.09c-0.12-0.08-0.25-0.13-0.39-0.19c-0.11-0.05-0.23-0.09-0.34-0.16
							c-0.06-0.04-0.14-0.06-0.23-0.09c-0.2-0.07-0.44-0.15-0.51-0.4c-0.02-0.08-0.05-0.17-0.08-0.25
							c-0.21-0.68-0.42-1.37-0.09-2.14c0.31-0.71,0.65-1.41,0.98-2.09c0.17-0.35,0.35-0.72,0.52-1.08c0.05-0.1,0.13-0.24,0.27-0.26
							c0.15-0.02,0.25,0.11,0.34,0.22c0.07,0.09,0.13,0.17,0.2,0.26c0.24,0.32,0.49,0.64,0.75,0.93c0.35,0.37,0.53,0.78,0.58,1.29
							c0.04,0.4,0.1,0.81,0.17,1.21c0.01,0.07,0.02,0.14,0.03,0.21c0.27-0.84,0.53-1.67,0.78-2.48c0.06-0.18,0.04-0.37-0.05-0.57
							c-0.4-0.93-0.33-1.7,0.21-2.35c0.11-0.13,0.11-0.24,0.01-0.43c-0.23-0.42-0.45-0.86-0.67-1.28c-0.08-0.15-0.16-0.3-0.24-0.46
							c-0.01-0.02-0.02-0.04-0.04-0.07c-0.07-0.13-0.19-0.32-0.05-0.53c0.23-0.34,0.47-0.68,0.7-1.01l0.03-0.04
							c0.04-0.06,0.13-0.18,0.26-0.18c0.09,0,0.17,0.04,0.24,0.13c0.12,0.16,0.26,0.31,0.39,0.46c0.49,0.55,0.99,1.12,1.08,1.97
							c0.04,0.36,0.14,0.72,0.24,1.07c0,0.01,0.01,0.02,0.01,0.04l0-0.08c0.03-0.61,0.06-1.22,0.09-1.83
							c0.01-0.23-0.03-0.45-0.11-0.7c-0.22-0.61-0.42-1.24-0.62-1.85c-0.12-0.38-0.25-0.75-0.38-1.13
							c-0.08-0.25-0.08-0.46,0.03-0.68l0.03-0.07c0.17-0.34,0.34-0.69,0.5-1.03c0.03-0.06,0.11-0.24,0.28-0.26
							c0.17-0.02,0.28,0.13,0.32,0.18c0.08,0.11,0.17,0.21,0.25,0.32c0.36,0.44,0.73,0.9,0.87,1.5c0.06-0.52,0.07-1.01-0.3-1.44
							c-0.12-0.13-0.2-0.29-0.28-0.44l-0.03-0.06c-0.39-0.74-0.41-0.84,0.3-1.41c0.15-0.12,0.16-0.17,0.16-0.17
							c0-0.01-0.03-0.05-0.19-0.13c-2.01-1.1-3.77-1.86-5.77-2.03l-0.03,0c-0.66-0.05-1.4-0.11-2-0.54
							c-1.3-0.93-2.62-2.08-4.16-3.63c-0.36-0.36-0.67-0.49-1.11-0.43l-0.29,0.03l-1.12,0.05l-0.03-0.02
							c-0.16-0.08-0.46-0.26-0.48-0.26l-0.02-0.01l-0.01-0.01c-0.07-0.07-0.14-0.14-0.21-0.21c-0.16-0.16-0.32-0.32-0.45-0.5
							c-0.48-0.66-1.07-1.05-1.79-1.19c-0.02-0.01-0.05-0.01-0.07-0.02c-0.16-0.04-0.19-0.04-0.24,0.06
							c-0.18,0.37-0.36,0.73-0.54,1.1c-0.4,0.82-0.81,1.66-1.2,2.5c-0.04,0.08-0.08,0.15-0.13,0.23c-0.07,0.11-0.14,0.21-0.16,0.32
							c0.01-0.01,0.02-0.02,0.02-0.02c0.02-0.02,0.05-0.05,0.07-0.07c0.17-0.14,0.33-0.26,0.48-0.21c0.16,0.04,0.24,0.22,0.32,0.45
							c0.11,0.31,0.06,0.61-0.01,0.88l-0.39,1.53l0.1-0.13c0.47-0.6,0.96-1.22,1.43-1.83c0.03-0.04,0.06-0.08,0.09-0.12
							c0.12-0.16,0.24-0.34,0.45-0.45l0.08-0.05l0.13,0.11l-0.01,0.08c-0.01,0.03-0.01,0.06-0.01,0.09
							c-0.01,0.08-0.02,0.17-0.07,0.25c-0.17,0.33-0.35,0.66-0.52,0.98c-0.17,0.32-0.35,0.65-0.53,0.98
							c-0.05,0.09-0.1,0.18-0.15,0.27c-0.32,0.57-0.6,1.07-0.16,1.7c0.11,0.15,0.09,0.27,0.06,0.35c-0.04,0.09-0.13,0.19-0.38,0.23
							c-0.18,0.03-0.37,0.06-0.55,0.1c-0.29,0.06-0.59,0.11-0.9,0.13c-0.41,0.03-0.76,0.18-1.1,0.48c-0.07,0.06-0.14,0.12-0.21,0.19
							c-0.22,0.19-0.45,0.39-0.66,0.6c-0.64,0.64-1.29,1.1-2,1.41c-0.07,0.03-0.15,0.06-0.23,0.1c-0.4,0.17-0.82,0.34-1.13,0.64
							c-0.4,0.38-0.8,0.79-1.17,1.18c-0.23,0.24-0.47,0.49-0.71,0.73c-0.02,0.02-0.04,0.05-0.06,0.08c-0.06,0.09-0.14,0.2-0.27,0.22
							c-0.07,0.01-0.15-0.01-0.22-0.06c-0.08-0.06-0.13-0.13-0.14-0.21c-0.01-0.13,0.07-0.22,0.14-0.29
							c0.03-0.03,0.06-0.06,0.07-0.08c0.02-0.04,0.04-0.07,0.06-0.1c0.02-0.04,0.05-0.07,0.05-0.1c0.21-1.04,0.79-1.89,1.76-2.61
							c0.02-0.02,0.05-0.04,0.07-0.06c0.11-0.09,0.29-0.24,0.51-0.1c0.11,0.07,0.19,0.09,0.26,0.07c0.11-0.03,0.19-0.17,0.29-0.33
							l0.01-0.01c0.11-0.19,0.23-0.37,0.34-0.55c0.06-0.1,0.12-0.2,0.19-0.3c0.14-0.22,0.27-0.45,0.4-0.68
							c0.44-0.79,0.9-1.6,1.74-2.13c0.03-0.02,0.05-0.03,0.05-0.04c-0.01,0-0.03,0-0.04,0c-0.02,0-0.04,0-0.05,0
							c-0.08-0.01-0.17-0.02-0.25-0.03c-0.23-0.03-0.47-0.06-0.7-0.1c-0.27-0.05-0.55-0.16-0.51-0.62c0.03-0.38,0.13-0.72,0.23-1.09
							c0.01-0.03,0.02-0.05,0.03-0.08c0-0.01,0.01-0.02,0.01-0.03c0,0-0.01,0.01-0.01,0.01c-0.02,0.02-0.05,0.03-0.07,0.04
							c-1.1,0.58-1.81,1.25-2.23,2.12c-0.13,0.26-0.29,0.5-0.45,0.73c-0.07,0.1-0.15,0.21-0.21,0.31l-0.28,0.43l0.03-0.51
							c0.05-0.81,0.17-1.59,0.31-2.38c0.04-0.24,0.08-0.45,0.04-0.5c-0.04-0.05-0.28-0.07-0.43-0.08c-0.17-0.01-0.35-0.04-0.52-0.07
							c-0.44-0.08-0.85-0.15-1.22,0.08c-0.6,0.36-1.23,0.34-1.83,0.32c-0.33-0.01-0.63-0.02-0.94,0.02l-0.39,0.06l0.27-0.29
							c0.27-0.29,0.6-0.48,0.91-0.67c0.18-0.11,0.35-0.21,0.51-0.33c0.46-0.34,1.03-0.67,1.74-0.54c0.2,0.04,0.27-0.02,0.39-0.32
							c0.18-0.43,0.06-0.83-0.06-1.25c-0.06-0.21-0.12-0.42-0.15-0.64c0,0,0,0-0.01,0c-0.16,0.1-0.33,0.19-0.49,0.27
							c-0.54,0.27-1,0.5-0.95,1.24c0,0.06,0.02,0.23-0.11,0.32c-0.14,0.09-0.33-0.01-0.41-0.05c-0.23-0.12-0.47-0.24-0.7-0.36
							c-0.16-0.08-0.32-0.16-0.47-0.24c-0.12-0.06-0.23-0.11-0.31-0.15c-0.46-0.22-0.49-0.28-0.38-0.83
							c0.02-0.09,0.04-0.19,0.06-0.32c0.11-0.59,0.07-0.95-0.4-1.25c-0.54-0.34-0.67-0.78-0.39-1.29c0.05-0.1,0.08-0.23,0.11-0.37
							c0.02-0.08,0.03-0.17,0.06-0.25l0.03-0.1c0.16-0.53,0.3-1.03,0.12-1.54c-0.14-0.39-0.04-0.66,0.28-0.85
							c0.13-0.08,0.27-0.16,0.39-0.26c0.17-0.15,0.32-0.21,0.48-0.19c0.21,0.03,0.35,0.21,0.48,0.41c0.21,0.34,0.44,0.67,0.66,1
							c0.42,0.62,0.84,1.25,1.21,1.91c0.32,0.58,0.8,0.74,1.36,0.84c-0.07-0.09-0.15-0.17-0.24-0.26c-0.06-0.06-0.13-0.13-0.19-0.19
							c-0.13-0.15-0.27-0.28-0.41-0.42c-0.33-0.32-0.67-0.65-0.85-1.12c-0.09-0.25-0.22-0.49-0.35-0.74
							c-0.14-0.28-0.29-0.56-0.4-0.87c-0.15-0.43-0.37-0.76-0.66-0.98c-0.07-0.05-0.14-0.1-0.22-0.15c-0.22-0.14-0.48-0.3-0.55-0.58
							c-0.07-0.27,0.1-0.46,0.25-0.63c0.09-0.1,0.17-0.2,0.2-0.3l0.01-0.02l0.29-0.42l0.05-0.01c0.13-0.03,0.26-0.1,0.39-0.17
							c0.33-0.18,0.74-0.4,1.19,0.05c0.01-0.01,0.03-0.01,0.04-0.02c0.03-0.01,0.06-0.03,0.09-0.04c0.31-0.1,0.48-0.34,0.66-0.6
							c0.02-0.03,0.05-0.07,0.07-0.1l0.02-0.03c0.08-0.11,0.18-0.25,0.34-0.26c0.11,0,0.22,0.06,0.34,0.19
							c0.12,0.14,0.28,0.34,0.33,0.34c0,0,0,0,0,0c0.05,0,0.21-0.2,0.35-0.37c0.04-0.05,0.09-0.1,0.13-0.15
							c0.06-0.06,0.12-0.12,0.16-0.19l0.04-0.05l0.07-0.01c1.78-0.15,3.6-0.15,5.37-0.16l1.08,0l-0.47,0.26
							c-0.07,0.04-0.14,0.05-0.21,0.07c-0.03,0.01-0.06,0.01-0.09,0.02c-0.82,0.24-1.66,0.24-2.48,0.24c-0.34,0-0.69,0-1.03,0.02
							l-0.13,0.01c-1.43,0.07-1.43,0.07-2.04,1.45c-0.16,0.35-0.4,0.58-0.8,0.75c-0.07,0.03-0.14,0.06-0.21,0.09
							c-0.46,0.2-0.93,0.4-1.29,0.74c-0.02,0.02-0.04,0.04-0.05,0.06c0,0,0.01,0,0.01,0c0.09,0,0.17,0.01,0.26,0.01
							c0.41,0.02,0.8,0.04,1.17-0.12c0.51-0.21,1.03-0.42,1.53-0.62c0.22-0.09,0.44-0.18,0.66-0.26l0.67-0.23
							c0.6-0.21,1.19-0.14,1.77-0.08c0.15,0.02,0.3,0.03,0.45,0.05c0.84,0.07,1.68,0.2,2.5,0.32c0.77,0.12,1.57,0.24,2.36,0.31
							c0.38,0.03,0.75,0.1,1.12,0.17c0.77,0.14,1.49,0.27,2.24,0.11c0.08-0.02,0.17,0.01,0.27,0.09c0.24,0.18,0.54,0.19,0.82,0.17
							c0.39-0.02,0.74,0.08,1.07,0.3c0.84,0.59,1.72,1.14,2.57,1.67c0.92,0.57,1.86,1.16,2.76,1.8c0.16,0.11,0.31,0.22,0.51,0.24
							c0.59,0.08,1.19,0.1,1.78,0.12c0.35,0.01,0.71,0.02,1.07,0.04l0.02,0c0.13,0.01,0.15,0,0.16-0.03
							c0.01-0.02,0.01-0.02-0.07-0.09c-0.02-0.02-0.04-0.04-0.06-0.06c-1.28-1.36-2.55-2.46-3.89-3.36c-0.3-0.2-0.59-0.29-0.9-0.26
							c-0.96,0.07-1.89-0.15-2.79-0.36l-0.26-0.06c-0.02,0-0.03-0.01-0.05-0.01c-0.09-0.01-0.29-0.05-0.3-0.27
							c-0.01-0.19,0.15-0.25,0.23-0.28c0.01-0.01,0.03-0.01,0.04-0.02c0.95-0.44,1.93-0.66,2.91-0.67l0.06,0
							c0.97-0.01,1.97-0.02,2.96,0.01c0.58,0.02,0.98-0.21,1.33-0.78l0.05-0.08l0.54,0.14c0.83,0.21,1.7,0.43,2.55,0.62l0.03,0.01
							l0.22,0.17l0.01,0.02c0.15,0.22,0.38,0.27,0.64,0.32c0.09,0.02,0.19,0.04,0.28,0.06c1.44,0.43,2.9,0.85,4.32,1.25l0.81,0.23
							c0.2,0.09,0.42,0.18,0.71,0.29l0.03,0.01l0.02,0.02c0.75,0.83,1.73,1.59,2.99,2.35c0.93,0.55,1.87,1.01,2.8,1.37l0.02,0.01
							l0.2,0.16c0.1,0.07,0.19,0.15,0.28,0.23c0.27,0.23,0.53,0.45,0.86,0.56l0.04,0.01l0.03,0.03c0.08,0.1,0.19,0.15,0.34,0.19
							l0.04,0.01l0.03,0.03c0.03,0.03,0.06,0.06,0.11,0.08c0.83,0.38,1.71,0.68,2.56,0.97c0.31,0.11,0.64,0.22,0.96,0.33
							c1.18,0.42,2.25,0.98,3.36,1.76l0.02,0.01l0.01,0.02c0.01,0.02,0.02,0.03,0.04,0.05c2.26,2.45,5.55,3.52,8.74,4.55
							c0.37,0.12,0.74,0.24,1.11,0.36c0.98,0.32,1.9,0.68,3.01,1.11c0.35,0.14,0.71,0.28,1.05,0.42c0.54,0.22,1.1,0.45,1.65,0.65
							l0.04,0.02l0.03,0.04c0.02,0.04,0.05,0.07,0.09,0.1c0.6,0.43,1.18,0.92,1.73,1.39c0.44,0.37,0.9,0.76,1.37,1.12
							c0.64,0.49,1.15,0.82,1.67,1.08c0.51,0.25,1.03,0.42,1.58,0.6c0.09,0.03,0.18,0.06,0.27,0.08c0.47,0.15,0.94,0.31,1.41,0.46
							c0.24,0.08,0.49,0.16,0.73,0.24l0.03,0.01c0.41,0.26,0.83,0.4,1.28,0.44l0.02,0c0.05,0.01,0.11,0.02,0.16,0.03l0.05,0.02
							l-0.08,0.26l-0.02-0.01c-0.05-0.01-0.1-0.02-0.15-0.03c-0.49-0.04-0.94-0.19-1.38-0.47c-0.24-0.08-0.48-0.16-0.71-0.23
							c-0.47-0.15-0.94-0.31-1.41-0.46c-0.09-0.03-0.18-0.06-0.26-0.08c-0.54-0.17-1.1-0.35-1.62-0.61
							c-0.53-0.27-1.06-0.61-1.71-1.1c-0.47-0.36-0.93-0.75-1.38-1.13c-0.55-0.46-1.12-0.95-1.72-1.37
							c-0.05-0.04-0.1-0.08-0.13-0.13c-0.55-0.2-1.09-0.42-1.62-0.64c-0.34-0.14-0.7-0.28-1.05-0.42c-1.1-0.43-2.02-0.78-2.99-1.1
							c-0.36-0.12-0.73-0.24-1.1-0.36c-3.22-1.04-6.55-2.12-8.86-4.62c-0.02-0.02-0.03-0.04-0.05-0.06
							c-1.09-0.76-2.13-1.3-3.28-1.71c-0.32-0.11-0.64-0.22-0.96-0.33c-0.86-0.29-1.74-0.6-2.59-0.98
							c-0.07-0.03-0.13-0.07-0.17-0.12c-0.15-0.05-0.3-0.11-0.42-0.25c-0.36-0.13-0.65-0.37-0.92-0.6
							c-0.09-0.07-0.18-0.15-0.27-0.22l-0.17-0.13c-0.93-0.36-1.88-0.83-2.82-1.38c-1.28-0.76-2.27-1.54-3.04-2.38
							c-0.27-0.11-0.48-0.2-0.68-0.28l-0.78-0.22c-1.42-0.41-2.88-0.83-4.32-1.25c-0.08-0.02-0.17-0.04-0.26-0.06
							c-0.28-0.05-0.6-0.11-0.81-0.42l-0.13-0.1c-0.85-0.18-1.7-0.4-2.53-0.61l-0.35-0.09c-0.26,0.39-0.7,0.85-1.52,0.82
							c-0.98-0.03-1.98-0.02-2.94-0.01l-0.06,0c-0.94,0.01-1.88,0.23-2.79,0.65c-0.02,0.01-0.04,0.02-0.06,0.02
							c-0.01,0-0.02,0.01-0.03,0.01c0.02,0,0.03,0.01,0.05,0.01c0.02,0,0.05,0.01,0.07,0.01l0.26,0.06c0.88,0.21,1.78,0.42,2.7,0.35
							c0.37-0.03,0.72,0.07,1.07,0.31c1.36,0.91,2.64,2.02,3.93,3.4c0.01,0.01,0.03,0.03,0.05,0.04c0.08,0.07,0.22,0.2,0.13,0.4
							c-0.09,0.22-0.32,0.2-0.44,0.19l-0.02,0c-0.35-0.02-0.71-0.03-1.06-0.04c-0.59-0.02-1.21-0.03-1.81-0.12
							c-0.25-0.04-0.45-0.16-0.63-0.29c-0.89-0.63-1.83-1.22-2.74-1.79c-0.86-0.54-1.74-1.09-2.58-1.68
							c-0.27-0.19-0.57-0.28-0.9-0.26c-0.32,0.02-0.69,0.01-1-0.23c-0.03-0.02-0.05-0.03-0.06-0.03c-0.8,0.17-1.58,0.03-2.34-0.11
							c-0.36-0.07-0.73-0.13-1.09-0.16c-0.8-0.07-1.6-0.19-2.38-0.31c-0.81-0.12-1.65-0.25-2.48-0.32
							c-0.15-0.01-0.31-0.03-0.46-0.05c-0.57-0.06-1.11-0.12-1.65,0.07l-0.67,0.23c-0.21,0.09-0.43,0.17-0.65,0.26
							c-0.3,0.12-0.61,0.25-0.92,0.37l0.01,0c0.14-0.02,0.29-0.04,0.43-0.07c0.32-0.05,0.65-0.1,0.97-0.14
							c0.45-0.04,0.9-0.08,1.34-0.12c0.42-0.03,0.86-0.07,1.29-0.11c0.52-0.05,1.03,0.1,1.54,0.47c0.95,0.67,2.04,0.95,3.1,1.22
							c0.67,0.17,1.33,0.4,1.97,0.62c0.27,0.09,0.53,0.18,0.8,0.27c0.05,0.02,0.1,0.05,0.15,0.09c0.03,0.02,0.06,0.04,0.1,0.07
							l0.29,0.21l-0.36,0.04c-0.36,0.04-0.62-0.06-0.86-0.15c-0.03-0.01-0.07-0.03-0.1-0.04l0,0c-0.71-0.26-1.4-0.14-2.13,0
							c-0.17,0.03-0.34,0.06-0.51,0.09c-0.02,0.01-0.04,0.02-0.08,0.02c-1.25,0.02-1.67,0.88-2.11,1.78
							c-0.13,0.26-0.26,0.53-0.41,0.78c-0.31,0.5-0.6,1.03-0.88,1.54c-0.13,0.23-0.26,0.46-0.38,0.69l-0.06,0.1l-0.11-0.04
							c-0.22-0.08-0.23-0.26-0.23-0.34l0-0.02c-0.01-0.25-0.02-0.49-0.03-0.74c-0.03-0.64-0.05-1.3-0.03-1.94
							c0.02-0.39,0.26-0.71,0.49-1.02c0.05-0.07,0.1-0.13,0.14-0.2l0.02-0.03c0.27-0.39,0.44-0.62,0.41-0.74
							c-0.03-0.12-0.28-0.27-0.65-0.5c-0.33-0.2-0.67-0.4-1.01-0.6c-0.39-0.23-0.79-0.47-1.18-0.7c-0.16-0.1-0.35-0.1-0.54-0.1
							c-0.05,0-0.09,0-0.14,0c-0.11,0-0.21,0-0.33,0c-0.31,0.01-0.64,0.01-0.97-0.11l-0.32-0.12c-0.09,0.04-0.18,0.07-0.27,0.11
							c-0.43,0.18-0.86,0.16-1.29,0.14c-0.09,0-0.17-0.01-0.26-0.01c-0.16,0-0.26-0.06-0.3-0.16c-0.04-0.11,0.01-0.24,0.16-0.37
							c0.4-0.38,0.9-0.59,1.37-0.79c0.07-0.03,0.14-0.06,0.21-0.09c0.34-0.15,0.53-0.32,0.66-0.62c0.66-1.48,0.73-1.53,2.27-1.61
							l0.13-0.01c0.35-0.02,0.7-0.02,1.04-0.02c0.44,0,0.88,0,1.33-0.04c-1.47,0.01-2.97,0.03-4.43,0.15
							c-0.05,0.06-0.1,0.12-0.15,0.17c-0.04,0.04-0.08,0.09-0.12,0.13c-0.26,0.32-0.38,0.47-0.56,0.47c0,0,0,0,0,0
							c-0.18,0-0.32-0.17-0.54-0.44c-0.06-0.08-0.1-0.09-0.11-0.09c0,0,0,0,0,0c-0.03,0-0.1,0.1-0.13,0.14l-0.02,0.03
							c-0.02,0.03-0.05,0.07-0.07,0.1c-0.2,0.29-0.41,0.58-0.8,0.71c-0.02,0.01-0.04,0.01-0.05,0.02c-0.07,0.03-0.22,0.1-0.34-0.02
							c-0.3-0.31-0.52-0.22-0.88-0.02c-0.13,0.07-0.27,0.14-0.41,0.18l-0.21,0.3c-0.05,0.15-0.16,0.27-0.25,0.38
							c-0.14,0.16-0.22,0.26-0.19,0.39c0.04,0.17,0.23,0.29,0.43,0.41c0.08,0.05,0.17,0.11,0.25,0.17c0.33,0.26,0.59,0.63,0.75,1.11
							c0.1,0.29,0.24,0.56,0.38,0.83c0.13,0.25,0.26,0.5,0.36,0.77c0.15,0.41,0.46,0.71,0.78,1.02c0.14,0.14,0.29,0.28,0.42,0.44
							c0.05,0.06,0.11,0.12,0.17,0.18c0.15,0.15,0.33,0.32,0.43,0.58l0.1,0.23l-0.24-0.04c-0.72-0.12-1.41-0.23-1.85-1.03
							c-0.36-0.65-0.78-1.28-1.2-1.89c-0.22-0.33-0.45-0.67-0.67-1.01c-0.08-0.12-0.18-0.27-0.28-0.28
							c-0.07-0.01-0.15,0.03-0.26,0.13c-0.14,0.12-0.3,0.22-0.44,0.3c-0.21,0.12-0.25,0.26-0.16,0.52c0.21,0.6,0.04,1.17-0.12,1.71
							l-0.03,0.1c-0.02,0.07-0.04,0.15-0.05,0.23c-0.03,0.15-0.06,0.31-0.14,0.45c-0.21,0.38-0.12,0.66,0.3,0.93
							c0.66,0.42,0.62,0.99,0.52,1.53c-0.02,0.13-0.04,0.23-0.06,0.32c-0.04,0.19-0.07,0.33-0.05,0.37
							c0.02,0.04,0.12,0.09,0.27,0.16c0.09,0.04,0.19,0.09,0.32,0.16c0.16,0.08,0.31,0.16,0.47,0.24c0.23,0.12,0.47,0.24,0.71,0.36
							c0.07,0.03,0.1,0.05,0.13,0.06c0-0.01,0-0.03,0-0.06c-0.07-0.92,0.55-1.23,1.1-1.5c0.17-0.08,0.32-0.16,0.46-0.25
							c0.09-0.06,0.2-0.07,0.29-0.03c0.08,0.04,0.13,0.11,0.14,0.2c0.02,0.21,0.08,0.41,0.14,0.62c0.13,0.45,0.27,0.91,0.05,1.43
							c-0.09,0.23-0.24,0.57-0.69,0.48c-0.61-0.11-1.1,0.17-1.53,0.49c-0.17,0.13-0.35,0.24-0.53,0.34
							c-0.2,0.12-0.39,0.23-0.56,0.36c0.19,0,0.39,0,0.58,0.01c0.59,0.02,1.15,0.04,1.68-0.28c0.46-0.28,0.94-0.19,1.41-0.11
							c0.17,0.03,0.33,0.06,0.49,0.07c0.32,0.02,0.52,0.04,0.63,0.18c0.11,0.14,0.08,0.33,0.02,0.71c-0.11,0.62-0.2,1.22-0.26,1.84
							c0.14-0.2,0.26-0.39,0.36-0.59c0.45-0.93,1.19-1.64,2.35-2.25c0.01-0.01,0.03-0.02,0.04-0.03c0.07-0.05,0.2-0.14,0.35-0.03
							c0.17,0.13,0.09,0.31,0.06,0.39c-0.01,0.02-0.02,0.04-0.02,0.05c-0.1,0.36-0.19,0.68-0.22,1.03
							c-0.02,0.24,0.05,0.29,0.28,0.33c0.23,0.04,0.46,0.07,0.69,0.1c0.08,0.01,0.17,0.02,0.25,0.03c0.01,0,0.02,0,0.04,0
							c0.07,0.01,0.21,0.01,0.27,0.16c0.05,0.1,0.06,0.26-0.16,0.39c-0.78,0.49-1.2,1.24-1.64,2.03c-0.13,0.23-0.26,0.46-0.4,0.69
							c-0.06,0.1-0.12,0.2-0.19,0.3c-0.11,0.18-0.23,0.36-0.34,0.55l-0.01,0.01c-0.12,0.2-0.24,0.4-0.46,0.46
							c-0.14,0.03-0.29,0-0.47-0.11c-0.05-0.03-0.07-0.02-0.19,0.08c-0.03,0.02-0.06,0.05-0.09,0.07c-0.92,0.68-1.46,1.48-1.66,2.45
							c-0.02,0.08-0.06,0.14-0.09,0.2c-0.02,0.03-0.03,0.05-0.04,0.07c-0.03,0.06-0.07,0.1-0.11,0.15
							c-0.02,0.02-0.05,0.06-0.06,0.08c0,0,0.01,0.01,0.02,0.01c0.01,0.01,0.02,0.01,0.02,0.01c0.01-0.01,0.06-0.08,0.09-0.11
							c0.03-0.04,0.06-0.08,0.09-0.12c0.24-0.24,0.48-0.48,0.71-0.72c0.38-0.4,0.78-0.81,1.18-1.19c0.35-0.32,0.79-0.51,1.21-0.69
							c0.07-0.03,0.15-0.06,0.22-0.09c0.68-0.29,1.3-0.74,1.91-1.35c0.21-0.22,0.45-0.42,0.67-0.61c0.07-0.06,0.14-0.12,0.21-0.18
							c0.39-0.34,0.79-0.52,1.26-0.55c0.29-0.02,0.58-0.08,0.87-0.13c0.18-0.03,0.37-0.07,0.56-0.1c0.11-0.01,0.16-0.05,0.16-0.07
							c0-0.01,0-0.04-0.03-0.08c-0.55-0.77-0.18-1.42,0.14-1.99c0.05-0.09,0.1-0.18,0.15-0.27c0.17-0.33,0.35-0.66,0.53-0.99
							c0.11-0.2,0.22-0.41,0.33-0.62c0,0,0,0-0.01,0.01c-0.47,0.61-0.96,1.24-1.43,1.84l-0.19,0.24c-0.04,0.05-0.16,0.2-0.39,0.11
							l-0.11-0.04l0.49-1.94c0.07-0.27,0.1-0.5,0.02-0.72c-0.08-0.22-0.12-0.27-0.13-0.28c-0.01,0-0.07,0.02-0.23,0.16
							c-0.02,0.02-0.04,0.03-0.05,0.05c-0.07,0.07-0.17,0.17-0.35,0.18l-0.12,0.01l-0.02-0.12c-0.06-0.3,0.08-0.53,0.2-0.71
							c0.04-0.07,0.08-0.13,0.11-0.19c0.39-0.84,0.8-1.68,1.2-2.5c0.18-0.37,0.36-0.73,0.53-1.1c0.14-0.3,0.38-0.24,0.54-0.21
							c0.02,0,0.04,0.01,0.07,0.01c0.79,0.16,1.43,0.59,1.96,1.3c0.12,0.17,0.27,0.31,0.43,0.47c0.07,0.07,0.13,0.13,0.2,0.2
							c0.06,0.04,0.28,0.16,0.42,0.23l1.04-0.05l0.28-0.03c0.52-0.06,0.91,0.08,1.33,0.51c1.52,1.54,2.83,2.68,4.12,3.6
							c0.54,0.38,1.21,0.44,1.86,0.49l0.03,0c2.17,0.18,4.11,1.09,5.88,2.06c0.16,0.09,0.32,0.19,0.33,0.35
							c0.01,0.16-0.13,0.3-0.26,0.41c-0.3,0.24-0.44,0.36-0.46,0.47c-0.02,0.11,0.08,0.29,0.24,0.6l0.03,0.06
							c0.07,0.14,0.15,0.28,0.24,0.39c0.49,0.57,0.42,1.21,0.34,1.83l-0.03,0.23l-0.11,0.01c-0.3,0.02-0.35-0.22-0.36-0.3l0-0.01
							c-0.11-0.58-0.46-1.01-0.83-1.46c-0.09-0.11-0.18-0.22-0.26-0.32c-0.03-0.04-0.06-0.06-0.07-0.07
							c-0.01,0.01-0.03,0.04-0.06,0.1c-0.16,0.35-0.33,0.7-0.5,1.04l-0.03,0.07c-0.07,0.14-0.08,0.29-0.01,0.47
							c0.13,0.38,0.25,0.75,0.38,1.13c0.2,0.6,0.41,1.23,0.62,1.84c0.1,0.29,0.14,0.54,0.13,0.81c-0.03,0.61-0.06,1.22-0.09,1.83
							l-0.03,0.67l-0.36,0.04l-0.03-0.12c-0.04-0.16-0.09-0.33-0.13-0.49c-0.1-0.36-0.21-0.74-0.25-1.12
							c-0.08-0.76-0.53-1.27-1.01-1.81c-0.13-0.15-0.27-0.31-0.4-0.47c-0.01-0.01-0.01-0.02-0.02-0.02
							c-0.01,0.01-0.02,0.03-0.05,0.06l-0.03,0.04c-0.23,0.33-0.47,0.66-0.69,1c-0.04,0.06-0.02,0.11,0.05,0.25
							c0.01,0.03,0.03,0.05,0.04,0.08c0.08,0.15,0.16,0.3,0.24,0.46c0.22,0.42,0.44,0.85,0.67,1.27c0.11,0.2,0.2,0.46-0.04,0.73
							c-0.47,0.57-0.53,1.23-0.17,2.07c0.11,0.26,0.13,0.52,0.06,0.76c-0.25,0.82-0.52,1.67-0.79,2.52c0,0.01-0.01,0.02-0.01,0.04
							c-0.02,0.09-0.06,0.25-0.29,0.32l-0.15,0.04l-0.02-0.15c-0.03-0.18-0.05-0.35-0.08-0.53c-0.06-0.4-0.12-0.81-0.17-1.22
							c-0.05-0.45-0.21-0.81-0.51-1.13c-0.28-0.3-0.53-0.63-0.77-0.95c-0.07-0.09-0.13-0.17-0.2-0.26
							c-0.05-0.06-0.08-0.09-0.09-0.11c-0.01,0.01-0.03,0.04-0.06,0.1c-0.17,0.36-0.35,0.73-0.52,1.08
							c-0.33,0.68-0.67,1.38-0.97,2.08c-0.29,0.67-0.1,1.29,0.1,1.95c0.03,0.09,0.05,0.17,0.08,0.26c0.03,0.11,0.15,0.15,0.34,0.22
							c0.09,0.03,0.19,0.06,0.28,0.11c0.09,0.05,0.2,0.1,0.3,0.14c0.24,0.1,0.52,0.21,0.75,0.49l0.21,0.26l-0.33-0.03
							c-0.3-0.03-0.54,0.03-0.78,0.08c-0.06,0.01-0.11,0.03-0.17,0.04c-0.6,0.12-1.06-0.18-1.45-0.47
							c-0.33-0.25-0.57-0.26-0.95-0.05c-0.9,0.49-1.43,1.12-1.67,1.96c-0.09,0.33-0.22,0.65-0.34,0.95
							c-0.03,0.07-0.05,0.13-0.08,0.2c0.11-0.02,0.22-0.04,0.33-0.05c0.43-0.07,0.88-0.14,1.32-0.19c0.51-0.06,0.95-0.2,1.36-0.44
							c0.49-0.29,1-0.6,1.45-0.98c0.7-0.58,1.54-0.87,2.57-0.88c1.16-0.02,2.29-0.05,3.38-0.22c1.02-0.16,1.96-0.63,2.87-1.09
							c0.32-0.16,0.66-0.33,0.99-0.49c0.91-0.42,1.3-1.17,1.59-1.91c0.23-0.6,0.69-0.94,1.13-1.27c0.28-0.21,0.57-0.42,0.8-0.7
							l0.18-0.22l0.06,0.28c0.07,0.37-0.07,0.52-0.17,0.62c-0.01,0.01-0.02,0.02-0.03,0.03c-0.5,0.55-0.84,1.04-1.08,1.56
							c-0.09,0.21-0.2,0.41-0.31,0.6c-0.15,0.27-0.29,0.53-0.39,0.81c0.02-0.01,0.04-0.02,0.06-0.03c0.27-0.13,0.54-0.25,0.82-0.38
							c0.23-0.1,0.4-0.24,0.52-0.44c0.59-0.97,1.13-1.95,1.61-2.93c0.17-0.36,0.4-0.64,0.72-0.88c0.11-0.09,0.23-0.18,0.34-0.26
							c1-0.76,2.03-1.56,2.68-2.68c0.22-0.39,0.5-0.77,0.97-0.96l0.63-0.33c0.04-0.04,0.09-0.07,0.13-0.11
							c0.47-0.4,0.96-0.81,1.71-0.77c0.64,0.03,1.29,0.01,1.92,0c0.14,0,0.29-0.01,0.43-0.01c0.13,0,0.23,0.03,0.3,0.1
							c0.1,0.09,0.11,0.23,0.11,0.35c0,0.14,0,0.29,0.01,0.43c0.01,0.27,0.01,0.56,0,0.84c0,0.12,0.01,0.2,0.04,0.23
							c0.03,0.03,0.11,0.05,0.23,0.05c0.44-0.01,0.89,0,1.32,0c0.18,0,0.36,0.01,0.54,0.01l0.39,0l0.01,0
							c0.69,0.05,1.27,0.36,1.83,0.65c0.21,0.11,0.42,0.22,0.64,0.32c0.09,0.04,0.15,0.17,0.13,0.26c-0.02,0.14-0.13,0.21-0.31,0.21
							c-0.27-0.01-0.53,0.05-0.8,0.11c-0.07,0.02-0.14,0.03-0.21,0.05c-0.33,0.07-0.49,0.25-0.48,0.55
							c0.01,0.23,0.02,0.46,0.04,0.69c0.01,0.19,0.03,0.38,0.04,0.58c0.01,0.29,0.12,0.39,0.44,0.41c0.27,0.02,0.54,0.04,0.8,0.06
							c1.22,0.09,2.48,0.19,3.73,0.16c0.28-0.01,0.51,0.04,0.72,0.15c0.58,0.3,1.07,0.72,1.55,1.12c0.12,0.1,0.24,0.21,0.37,0.31
							c0.01,0.01,0.03,0.02,0.04,0.03c0.06,0.04,0.2,0.13,0.16,0.31c-0.06,0.22-0.27,0.21-0.36,0.21c-0.02,0-0.03,0-0.04,0
							c-0.2,0-0.4,0.01-0.6,0.03c-0.43,0.03-0.88,0.06-1.33-0.04c-0.08-0.02-0.19,0-0.26,0.03c-0.87,0.44-1.54,1.11-2.24,1.88
							l1.93-0.16l0.3-0.07l0.72-0.05l0.46-0.07l0.64-0.05l0.31-0.01l0.41-0.08l0.71-0.07l0.2-0.04c0.14-0.01,0.29-0.04,0.44-0.06
							c0.27-0.04,0.55-0.09,0.85-0.07l0.72,0c0.03,0,0.05,0,0.07,0c0.05,0,0.12,0,0.18,0.01c1.73,0.35,3.26,1.15,4.68,1.93
							c0.06,0.03,0.22,0.12,0.2,0.3c-0.02,0.22-0.22,0.24-0.31,0.25l-0.02,0c-0.1,0.01-0.21,0.03-0.31,0.04
							c-0.27,0.04-0.55,0.08-0.82,0.1c-0.67,0.06-1.41,0.23-1.79,1.03c-0.02,0.03-0.03,0.06-0.05,0.08
							c-0.01,0.02-0.03,0.05-0.03,0.06c0,0,0-0.01,0-0.01l-0.07,0.12l0.08-0.11c0.01,0,0.05-0.01,0.08-0.02
							c0.02-0.01,0.05-0.01,0.07-0.02c0.52-0.12,1.04-0.26,1.54-0.39c0.82-0.22,1.66-0.45,2.51-0.59l0.03-0.01
							c0.94-0.16,1.91-0.33,2.9-0.08c2.07,0.52,4.05,1.25,5.88,1.96l-0.1,0.26c-1.82-0.71-3.79-1.44-5.84-1.95
							c-0.94-0.24-1.84-0.08-2.79,0.08l-0.03,0.01c-0.84,0.14-1.68,0.37-2.49,0.59c-0.51,0.14-1.03,0.28-1.55,0.4
							c-0.02,0-0.04,0.01-0.05,0.02c-0.09,0.03-0.25,0.08-0.37-0.07c-0.13-0.16-0.03-0.31,0.01-0.38c0.01-0.02,0.02-0.03,0.03-0.05
							c0.44-0.92,1.31-1.11,2.01-1.18c0.27-0.03,0.55-0.06,0.81-0.1c0.1-0.01,0.21-0.03,0.31-0.04l0.02,0
							c0.02,0,0.05-0.01,0.07-0.01c-0.01-0.01-0.02-0.02-0.05-0.04c-1.4-0.77-2.91-1.55-4.61-1.9c-0.04-0.01-0.08-0.01-0.13-0.01
							c-0.03,0-0.05,0-0.08,0l-0.73,0l-0.01,0c-0.26-0.02-0.52,0.02-0.79,0.06c-0.15,0.02-0.3,0.05-0.45,0.06l-0.2,0.04l-0.72,0.07
							l-0.43,0.08l-0.31,0.01l-0.63,0.05l-0.46,0.07l-0.71,0.05l-0.3,0.07l-2.62,0.22l0.23-0.26c0.8-0.9,1.55-1.69,2.55-2.2
							c0.13-0.07,0.3-0.09,0.45-0.06c0.41,0.09,0.82,0.07,1.25,0.04c0.2-0.01,0.41-0.02,0.62-0.03c0.02,0,0.03,0,0.05,0
							c0.02,0,0.05,0,0.07,0c-0.01-0.01-0.02-0.01-0.03-0.02c-0.02-0.01-0.05-0.03-0.07-0.05c-0.12-0.1-0.25-0.21-0.37-0.31
							c-0.46-0.39-0.95-0.8-1.49-1.09c-0.17-0.09-0.36-0.13-0.59-0.12c-1.26,0.03-2.53-0.07-3.75-0.16
							c-0.27-0.02-0.54-0.04-0.8-0.06c-0.46-0.03-0.68-0.25-0.69-0.68c-0.01-0.19-0.02-0.38-0.04-0.57
							c-0.02-0.23-0.04-0.47-0.04-0.7c-0.01-0.44,0.24-0.73,0.7-0.83c0.07-0.01,0.14-0.03,0.2-0.04c0.26-0.06,0.53-0.12,0.81-0.12
							c-0.18-0.09-0.36-0.18-0.53-0.27c-0.56-0.29-1.09-0.57-1.72-0.62l-0.38,0c-0.18,0-0.37-0.01-0.55-0.01
							c-0.43-0.01-0.87-0.02-1.31,0c-0.13,0-0.31-0.01-0.43-0.13c-0.12-0.12-0.13-0.31-0.12-0.43c0.01-0.27,0-0.55,0-0.82
							c0-0.15-0.01-0.29-0.01-0.44c0-0.04,0-0.14-0.02-0.16c0,0-0.02-0.02-0.1-0.02c-0.14,0-0.28,0.01-0.43,0.01
							c-0.64,0.02-1.29,0.04-1.94,0c-0.64-0.03-1.07,0.33-1.52,0.71c-0.05,0.04-0.09,0.08-0.14,0.12l-0.02,0.02l-0.66,0.34
							c-0.38,0.15-0.61,0.45-0.84,0.85c-0.68,1.17-1.73,1.98-2.75,2.76c-0.11,0.09-0.23,0.17-0.34,0.26
							c-0.28,0.22-0.48,0.47-0.64,0.78c-0.48,0.99-1.03,1.98-1.62,2.95c-0.15,0.24-0.36,0.42-0.64,0.54
							c-0.27,0.12-0.54,0.25-0.81,0.37c-0.11,0.05-0.23,0.11-0.34,0.16l-0.26,0.12l0.07-0.28c0.1-0.42,0.31-0.8,0.51-1.16
							c0.1-0.19,0.21-0.39,0.3-0.58c0.24-0.52,0.57-1.02,1.05-1.56c-0.13,0.11-0.27,0.21-0.41,0.31c-0.43,0.32-0.84,0.62-1.04,1.15
							c-0.31,0.8-0.74,1.61-1.73,2.06c-0.33,0.15-0.66,0.32-0.98,0.48c-0.93,0.47-1.88,0.96-2.95,1.12
							c-1.11,0.17-2.25,0.2-3.42,0.22c-0.98,0.02-1.74,0.28-2.4,0.82c-0.47,0.39-0.99,0.71-1.49,1c-0.44,0.26-0.92,0.42-1.47,0.48
							c-0.44,0.05-0.88,0.12-1.31,0.19c-0.19,0.03-0.38,0.06-0.57,0.09L514.44,715.57z M524.93,696.6L524.93,696.6L524.93,696.6z
							 M496.19,689.62L496.19,689.62L496.19,689.62z M500.69,681.36c0.04,0,0.08,0,0.12,0c0.04,0,0.09,0,0.13,0
							c0.22,0,0.46,0,0.69,0.14c0.39,0.24,0.79,0.47,1.17,0.7c0.34,0.2,0.67,0.4,1.01,0.6c0.49,0.29,0.73,0.44,0.78,0.68
							c0.05,0.24-0.12,0.48-0.46,0.96l-0.02,0.03c-0.05,0.07-0.1,0.14-0.15,0.2c-0.21,0.28-0.42,0.56-0.43,0.87
							c-0.03,0.64,0,1.29,0.03,1.92c0.01,0.24,0.02,0.47,0.03,0.71c0.09-0.16,0.18-0.32,0.27-0.48c0.28-0.51,0.57-1.04,0.89-1.55
							c0.14-0.23,0.27-0.5,0.4-0.75c0.45-0.93,0.92-1.9,2.32-1.94c0.01-0.01,0.03-0.01,0.05-0.02c0.17-0.03,0.35-0.06,0.52-0.09
							c0.49-0.09,1-0.18,1.51-0.15c-0.46-0.16-0.93-0.3-1.4-0.42c-1.03-0.26-2.2-0.56-3.19-1.26c-0.46-0.33-0.9-0.46-1.36-0.42
							c-0.43,0.04-0.87,0.08-1.29,0.11c-0.44,0.04-0.89,0.07-1.33,0.12C500.87,681.34,500.78,681.35,500.69,681.36z"/>
					</g>
					<g>
						<path className="st72" d="M545.64,742.75c0.67-0.54,1.33-1.09,2-1.63c1.1-0.88,2.21-1.75,3.3-2.63c0.43-0.35,0.94-0.55,1.42-0.81
							c0.72-0.39,1.4-0.77,1.82-1.56c0.5-0.93,1.28-1.67,2.03-2.44c-0.53,0.1-1.05,0.22-1.58,0.29c-1.99,0.25-3.68,1.28-5.43,2.12
							c-1.58,0.76-3.13,1.59-4.75,2.26c-2.13,0.89-4.21,1.88-6.33,2.77c-1.18,0.49-2.17,1.26-3.23,1.93
							c-0.37,0.23-0.73,0.47-1.09,0.71c-0.03-0.03-0.06-0.07-0.09-0.1c0.29-0.28,0.58-0.56,0.88-0.82c0.42-0.37,0.87-0.7,1.28-1.08
							c0.66-0.6,1.46-0.93,2.24-1.33c0.92-0.46,1.86-0.87,2.76-1.35c1.16-0.63,2.4-1.09,3.58-1.67c0.99-0.49,1.99-0.94,2.92-1.56
							c1.09-0.72,2.2-1.41,3.29-2.11c0.08-0.05,0.2-0.08,0.24-0.24c-2.75,0.63-4.95,2.34-7.49,3.65c0.21-0.4,0.5-0.54,0.75-0.69
							c1.04-0.63,1.98-1.39,2.96-2.09c0.68-0.49,1.4-0.87,2.18-1.15c0.99-0.36,1.99-0.72,2.96-1.14c0.55-0.24,1.04-0.61,1.56-0.92
							c0.75-0.46,1.58-0.76,2.42-0.98c1.45-0.39,2.69-1.11,3.83-2.06c0.83-0.69,1.72-1.33,2.6-1.96c0.46-0.34,1.03-0.4,1.58-0.48
							c0.04-0.01,0.09,0.01,0.13,0.01c0.19-0.01,0.43-0.28,0.55,0.01c0.11,0.26,0.07,0.57-0.14,0.8c-0.39,0.43-0.78,0.85-1.17,1.27
							c-0.5,0.54-1.01,1.08-1.48,1.68c0.28-0.11,0.57-0.23,0.85-0.34c0.64-0.26,1.27-0.59,1.93-0.77c0.94-0.24,1.56-0.89,2.24-1.48
							c0.66-0.57,1.35-1.12,2.03-1.67c0.68-0.55,1.51-0.62,2.31-0.81c0.02,0.03,0.04,0.07,0.06,0.1c-0.88,0.81-1.77,1.63-2.65,2.44
							c0.03,0.03,0.05,0.07,0.08,0.1c0.49-0.34,0.98-0.66,1.45-1.03c0.49-0.38,1.03-0.69,1.48-1.13c0.37-0.35,6.01-1.8,6.49-1.74
							c0,0.46,7.1,3.21,7.1,3.67c-0.69,0.2-13.53-0.21-14.17,0.1c-0.25,0.12-0.54,0.22-0.73,0.46c-0.62,0.8-1.25,1.6-1.86,2.41
							c-0.22,0.29-0.48,0.49-0.81,0.64c-1.51,0.71-3.08,1.28-4.63,1.9c-0.19,0.07-0.34,0.18-0.47,0.34
							c-0.46,0.57-0.88,1.16-1.4,1.67c-0.09,0.09-0.24,0.2-0.17,0.46c1.16-1.02,2.31-1.99,4-2.04c-0.46,0.2-0.87,0.36-1.26,0.56
							c-0.35,0.18-0.59,0.56-0.57,0.87c0.02,0.25,0.41-0.01,0.61-0.06c0.72-0.16,1.48-0.24,2.08-0.72c1.69-1.34,3.36-2.69,5.06-4.01
							c0.66-0.52,1.26-1.13,2.02-1.53c0,0.49,31.47,4.95,31.47,5.44c-0.78,0.25-32.84-3.17-33.49-2.72
							c-1.42,0.98-2.82,1.99-4.2,3.03c-1.06,0.81-2.17,1.55-3.21,2.37c-0.63,0.5-1.35,0.74-2.1,0.74c-1.1,0.01-1.9,0.61-2.68,1.21
							c-1.01,0.78-2.1,1.19-3.38,1.24c-1.08,0.04-2.17,0.19-3.25,0.29c-0.85,0.08-1.5,0.62-2.22,0.99c-1.37,0.7-2.72,1.46-4.09,2.19
							C545.96,742.67,545.81,742.76,545.64,742.75C545.63,742.74,545.64,742.75,545.64,742.75z"/>
						<path className="st8" d="M533.79,743.92l-0.25-0.29l0.1-0.09c0.09-0.08,0.18-0.17,0.27-0.26c0.2-0.19,0.4-0.39,0.62-0.57
							c0.19-0.16,0.39-0.32,0.58-0.48c0.24-0.19,0.48-0.39,0.71-0.6c0.57-0.51,1.25-0.84,1.9-1.16c0.12-0.06,0.25-0.12,0.37-0.18
							c0.36-0.18,0.73-0.36,1.09-0.53c0.55-0.26,1.12-0.53,1.67-0.83c0.69-0.37,1.41-0.69,2.1-1c0.49-0.22,0.99-0.44,1.48-0.68
							c0.14-0.07,0.27-0.13,0.41-0.2c0.84-0.41,1.7-0.83,2.49-1.35c0.7-0.47,1.43-0.93,2.13-1.37c0.3-0.19,0.61-0.38,0.91-0.58
							c-1.66,0.48-3.11,1.34-4.65,2.24c-0.71,0.42-1.45,0.85-2.22,1.25l-0.39,0.2l0.2-0.38c0.21-0.39,0.48-0.55,0.72-0.7
							c0.03-0.02,0.05-0.03,0.08-0.05c0.73-0.44,1.43-0.96,2.1-1.46c0.28-0.21,0.57-0.42,0.85-0.63c0.69-0.49,1.41-0.88,2.21-1.17
							l0.4-0.15c0.84-0.31,1.72-0.63,2.55-0.99c0.39-0.17,0.76-0.41,1.12-0.65c0.14-0.09,0.28-0.18,0.42-0.27
							c0.67-0.41,1.45-0.73,2.45-1c1.32-0.35,2.55-1.02,3.78-2.03c0.78-0.65,1.62-1.26,2.43-1.84l0.17-0.13
							c0.47-0.34,1.02-0.42,1.56-0.49l0.09-0.01c0.04-0.01,0.08,0,0.11,0.01c0.01,0,0.02,0,0.03,0c0.03,0,0.09-0.03,0.14-0.05
							c0.1-0.05,0.23-0.11,0.36-0.05c0.08,0.03,0.14,0.1,0.19,0.2c0.14,0.32,0.07,0.69-0.17,0.95c-0.39,0.43-0.78,0.85-1.17,1.27
							l-0.06,0.07c-0.36,0.38-0.72,0.78-1.07,1.19c0.12-0.05,0.23-0.09,0.35-0.14c0.2-0.08,0.4-0.17,0.59-0.25
							c0.44-0.19,0.89-0.4,1.36-0.52c0.77-0.2,1.32-0.68,1.9-1.2c0.1-0.09,0.19-0.17,0.29-0.26c0.48-0.41,0.98-0.82,1.46-1.21
							c0.19-0.16,0.38-0.31,0.57-0.47c0.56-0.46,1.22-0.59,1.86-0.73c0.17-0.03,0.34-0.07,0.5-0.11l0.1-0.02l0.18,0.28l-1.96,1.8
							c0.2-0.14,0.39-0.28,0.58-0.42c0.18-0.14,0.37-0.27,0.55-0.4c0.32-0.22,0.64-0.45,0.92-0.72c0.26-0.25,2.37-0.82,3.26-1.06
							c1.09-0.29,3.02-0.76,3.33-0.72l0.12,0.02v0.1c0.18,0.25,2.08,1.1,3.47,1.73c3.02,1.37,3.63,1.7,3.63,1.96v0.1l-0.1,0.03
							c-0.31,0.09-2.51,0.07-6.07,0.03c-3.27-0.04-7.75-0.09-8.08,0.07c-0.05,0.02-0.1,0.05-0.15,0.07
							c-0.21,0.09-0.4,0.18-0.53,0.35c-0.63,0.81-1.25,1.61-1.86,2.41c-0.22,0.29-0.49,0.51-0.86,0.68
							c-1.15,0.55-2.36,1.01-3.53,1.47c-0.37,0.14-0.74,0.29-1.11,0.43c-0.18,0.07-0.31,0.16-0.42,0.3
							c-0.12,0.15-0.23,0.29-0.35,0.44c-0.25,0.32-0.51,0.65-0.79,0.96c0.93-0.77,2-1.48,3.46-1.53l0.71-0.02l-0.65,0.29
							c-0.15,0.06-0.29,0.12-0.42,0.18c-0.29,0.12-0.57,0.24-0.83,0.37c-0.28,0.14-0.5,0.46-0.49,0.71
							c0.06-0.01,0.17-0.05,0.23-0.08c0.08-0.03,0.15-0.06,0.22-0.08c0.13-0.03,0.26-0.06,0.39-0.08c0.6-0.12,1.17-0.24,1.64-0.61
							c0.61-0.48,1.21-0.96,1.81-1.44c1.06-0.85,2.16-1.72,3.25-2.57c0.2-0.15,0.39-0.32,0.58-0.48c0.45-0.38,0.91-0.78,1.46-1.06
							l0.2-0.1v0.15c0.75,0.31,8.64,1.56,15.62,2.65c3.87,0.61,7.87,1.24,10.82,1.73c5.03,0.84,5.03,0.94,5.03,1.12v0.1l-0.1,0.03
							c-0.35,0.11-5.56-0.37-14.04-1.16c-8.38-0.79-18.81-1.77-19.43-1.56c-1.51,1.04-2.88,2.03-4.18,3.02
							c-0.47,0.35-0.95,0.7-1.41,1.03c-0.6,0.43-1.21,0.88-1.8,1.34c-0.65,0.51-1.38,0.77-2.19,0.77c-1.08,0.01-1.88,0.63-2.59,1.18
							c-1.04,0.81-2.17,1.22-3.46,1.26c-0.74,0.02-1.49,0.1-2.21,0.18c-0.34,0.04-0.69,0.07-1.03,0.1
							c-0.59,0.05-1.08,0.34-1.59,0.65c-0.19,0.11-0.38,0.23-0.58,0.33c-0.96,0.49-1.92,1.01-2.85,1.52
							c-0.41,0.22-0.82,0.45-1.24,0.67c-0.02,0.01-0.04,0.02-0.06,0.03c-0.12,0.07-0.26,0.14-0.42,0.14l-0.06,0.05l-0.36-0.36
							l0.33,0.04c0.19-0.16,0.38-0.31,0.57-0.47c0.45-0.37,0.92-0.75,1.38-1.12c0.56-0.45,1.13-0.9,1.7-1.35
							c0.54-0.43,1.07-0.85,1.61-1.28c0.33-0.26,0.7-0.45,1.06-0.63c0.13-0.06,0.26-0.13,0.38-0.2c0.79-0.42,1.38-0.78,1.76-1.5
							c0.44-0.82,1.1-1.5,1.75-2.16c-0.02,0-0.04,0.01-0.06,0.01c-0.36,0.07-0.72,0.14-1.09,0.19c-1.58,0.2-3,0.91-4.37,1.6
							c-0.33,0.17-0.68,0.34-1.02,0.5c-0.51,0.24-1.03,0.5-1.53,0.75c-1.04,0.52-2.12,1.06-3.22,1.51c-1.07,0.45-2.15,0.93-3.19,1.4
							c-1.02,0.46-2.08,0.93-3.14,1.37c-0.83,0.35-1.59,0.85-2.32,1.34c-0.29,0.19-0.59,0.39-0.89,0.58
							c-0.25,0.16-0.5,0.32-0.74,0.48c-0.11,0.08-0.23,0.15-0.34,0.23L533.79,743.92z M551.1,733.3l-0.05,0.21
							c-0.04,0.19-0.17,0.26-0.25,0.3c-0.02,0.01-0.03,0.02-0.04,0.03c-0.39,0.25-0.77,0.49-1.16,0.74c-0.7,0.44-1.43,0.9-2.13,1.37
							c-0.81,0.54-1.68,0.96-2.53,1.37c-0.14,0.07-0.27,0.13-0.41,0.2c-0.49,0.24-1,0.46-1.49,0.68c-0.69,0.31-1.41,0.62-2.08,0.99
							c-0.55,0.3-1.12,0.57-1.68,0.83c-0.36,0.17-0.72,0.34-1.08,0.52c-0.12,0.06-0.25,0.12-0.37,0.18
							c-0.67,0.33-1.3,0.63-1.84,1.12c-0.23,0.21-0.48,0.41-0.72,0.6c-0.19,0.15-0.38,0.31-0.57,0.47c-0.1,0.08-0.19,0.17-0.28,0.26
							c0.14-0.09,0.28-0.18,0.41-0.27c0.3-0.19,0.59-0.38,0.88-0.57c0.74-0.49,1.51-1,2.37-1.36c1.05-0.44,2.11-0.91,3.13-1.37
							c1.04-0.47,2.12-0.95,3.2-1.4c1.09-0.45,2.17-0.99,3.21-1.51c0.5-0.25,1.02-0.51,1.53-0.75c0.34-0.16,0.68-0.33,1.01-0.5
							c1.39-0.7,2.83-1.42,4.46-1.63c0.36-0.05,0.72-0.12,1.07-0.19c0.17-0.03,0.33-0.07,0.5-0.1l0.42-0.08l-0.3,0.31
							c-0.09,0.09-0.17,0.17-0.26,0.26c-0.68,0.69-1.32,1.35-1.75,2.15c-0.46,0.86-1.21,1.26-1.88,1.62
							c-0.13,0.07-0.26,0.13-0.39,0.2c-0.36,0.18-0.7,0.35-1.01,0.6c-0.53,0.43-1.07,0.85-1.61,1.28c-0.57,0.45-1.13,0.9-1.7,1.35
							c-0.41,0.33-0.82,0.67-1.23,1c0.26-0.14,0.52-0.28,0.77-0.42c0.93-0.51,1.89-1.03,2.85-1.52c0.19-0.1,0.38-0.21,0.57-0.32
							c0.52-0.31,1.05-0.63,1.7-0.69c0.34-0.03,0.69-0.07,1.03-0.1c0.73-0.08,1.48-0.16,2.23-0.18c1.22-0.04,2.3-0.43,3.3-1.21
							c0.74-0.58,1.59-1.23,2.76-1.24c0.74,0,1.42-0.25,2.02-0.71c0.59-0.46,1.21-0.91,1.81-1.34c0.46-0.33,0.94-0.68,1.41-1.03
							c1.31-1,2.69-1.99,4.2-3.04c0.35-0.24,6.06,0.25,19.6,1.52c5.26,0.49,10.66,1,12.89,1.14c-2.43-0.5-8.87-1.51-14.68-2.42
							c-3.87-0.61-7.87-1.24-10.82-1.73c-3.55-0.59-4.59-0.82-4.9-0.96c-0.49,0.26-0.92,0.63-1.34,0.99
							c-0.19,0.16-0.39,0.33-0.59,0.49c-1.09,0.85-2.18,1.72-3.25,2.57c-0.6,0.48-1.21,0.96-1.82,1.44
							c-0.52,0.42-1.15,0.54-1.75,0.67c-0.13,0.03-0.26,0.05-0.38,0.08c-0.04,0.01-0.11,0.04-0.17,0.06
							c-0.18,0.07-0.36,0.15-0.5,0.07c-0.05-0.03-0.1-0.09-0.11-0.2c-0.03-0.37,0.25-0.8,0.64-1c0.09-0.05,0.18-0.09,0.28-0.13
							c-1.16,0.34-2.06,1.13-2.87,1.84l-0.17,0.15l-0.06-0.21c-0.08-0.32,0.08-0.47,0.18-0.57l0.02-0.02
							c0.38-0.37,0.71-0.8,1.04-1.22c0.12-0.15,0.23-0.3,0.35-0.44c0.14-0.17,0.3-0.29,0.53-0.38c0.37-0.15,0.74-0.29,1.11-0.43
							c1.16-0.45,2.36-0.92,3.51-1.46c0.32-0.15,0.57-0.34,0.76-0.6c0.61-0.81,1.23-1.6,1.86-2.42c0.17-0.22,0.42-0.33,0.63-0.43
							c0.05-0.02,0.1-0.04,0.14-0.07c0.36-0.17,3.48-0.15,8.2-0.1c2.42,0.03,4.92,0.06,5.73,0.01c-0.52-0.34-2.11-1.06-3.31-1.61
							c-2.53-1.15-3.37-1.56-3.57-1.82c-0.32,0.02-1.53,0.28-3.17,0.71c-1.91,0.5-3.03,0.88-3.14,0.99
							c-0.3,0.28-0.63,0.52-0.95,0.75c-0.18,0.13-0.36,0.25-0.54,0.39c-0.33,0.25-0.68,0.49-1.01,0.72c-0.15,0.1-0.3,0.2-0.45,0.31
							l-0.11,0.07l-0.23-0.3l2.53-2.33c-0.08,0.02-0.16,0.03-0.24,0.05c-0.63,0.13-1.23,0.25-1.74,0.67
							c-0.19,0.16-0.38,0.31-0.57,0.47c-0.48,0.39-0.98,0.79-1.46,1.2c-0.1,0.08-0.19,0.17-0.29,0.25
							c-0.58,0.51-1.18,1.04-2.01,1.26c-0.45,0.12-0.89,0.31-1.32,0.5c-0.2,0.09-0.4,0.18-0.6,0.26c-0.2,0.08-0.39,0.16-0.59,0.24
							l-0.74,0.3l0.32-0.41c0.46-0.58,0.95-1.11,1.42-1.62l0.06-0.07c0.39-0.42,0.78-0.85,1.17-1.27c0.17-0.18,0.21-0.43,0.12-0.66
							c-0.02-0.05-0.03-0.05-0.03-0.05c-0.02-0.01-0.11,0.03-0.15,0.05c-0.07,0.03-0.15,0.07-0.23,0.07c-0.04,0-0.07,0-0.1-0.01
							c-0.01,0-0.02,0-0.03,0l-0.09,0.01c-0.5,0.07-1.02,0.15-1.43,0.45l-0.17,0.13c-0.81,0.58-1.64,1.19-2.42,1.83
							c-1.25,1.04-2.52,1.72-3.88,2.09c-0.98,0.26-1.74,0.57-2.38,0.97c-0.14,0.09-0.27,0.17-0.41,0.26
							c-0.37,0.24-0.75,0.49-1.16,0.67c-0.84,0.37-1.72,0.69-2.57,1l-0.4,0.15c-0.78,0.29-1.48,0.66-2.15,1.14
							c-0.29,0.2-0.57,0.42-0.85,0.62c-0.56,0.41-1.13,0.83-1.72,1.22c0.31-0.18,0.62-0.36,0.92-0.54c1.73-1.02,3.37-1.98,5.32-2.42
							L551.1,733.3z M571.29,730.65L571.29,730.65L571.29,730.65z"/>
					</g>
					<g>
						<path className="st72" d="M552.39,743.85c0.82-0.23,1.57-0.64,2.35-0.96c1.24-0.5,2.45-1.05,3.69-1.55
							c0.88-0.35,1.83-0.41,2.75-0.58c1.09-0.2,2.18-0.38,3.27-0.58c0.48-0.09,0.74-0.51,1.08-0.8c1.12-0.93,2.19-1.91,3.32-2.83
							c0.53-0.43,1.19-0.66,1.79-0.99c1.8-0.99,3.1-3.27,5.91-2c-1.92,1.02-3.86,2.01-5.79,3.01c-0.23,0.12-0.47,0.21-0.61,0.45
							c-0.3,0.51-0.61,1.01-0.92,1.51c-0.05,0.08-0.11,0.16-0.03,0.24c0.07,0.07,0.14,0.01,0.21-0.02
							c0.34-0.17,16.41,2.13,16.76,1.98c6.01-3.69,29.21-3.69,31.12-2.43c-15.04,1.82-23.81,3.35-31.82,4.8
							c-2.24,0.4-4.54,0.3-6.73-0.31c-3.24-0.9-7.24-2.02-7.66-1.82c-2.07,0.99-4.11,2.03-6.12,3.12c-0.28,0.15-0.42,0.12-0.5-0.18
							c-0.11-0.43-0.33-0.38-0.63-0.18c-0.61,0.39-1.25,0.64-2,0.58c-0.77-0.06-1.55-0.02-2.32-0.03c-0.14,0-0.34,0.09-0.41-0.08
							c-0.08-0.16,0.11-0.26,0.19-0.38c0.3-0.42,0.62-0.83,0.93-1.24c-0.45,0.06-0.83,0.12-1.18,0.37c-0.72,0.52-1.47,1.01-2.22,1.5
							c-0.23,0.15-0.47,0.19-0.77,0.1c-0.4-0.12-0.8-0.38-1.22-0.27c-0.62,0.15-1.23,0.35-1.82,0.57c-2.47,0.92-5.03,1.34-7.64,1.54
							c-1.06,0.08-2.05,0.35-3,0.83h-1.73c0.01-0.17,0.15-0.21,0.27-0.27c1.47-0.68,2.96-1.31,4.41-2.04
							c1.41-0.71,2.95-0.83,4.45-1.14c0.93-0.19,1.87-0.35,2.81-0.52c0.12-0.02,0.23-0.02,0.4-0.04c-0.12,0.33-0.52,0.31-0.59,0.61
							c-0.08,0.01-0.18-0.01-0.21,0.1c0,0.02,0.03,0.05,0.05,0.06C552.32,744.01,552.36,743.93,552.39,743.85z"/>
						<path className="st8" d="M542.39,747.35h-1.91l0.01-0.14c0.01-0.24,0.2-0.32,0.31-0.37c0.01-0.01,0.03-0.01,0.04-0.02
							c0.48-0.22,0.96-0.44,1.43-0.65c0.98-0.44,1.99-0.9,2.97-1.39c1.09-0.54,2.26-0.75,3.4-0.95c0.36-0.06,0.73-0.13,1.09-0.2
							c0.69-0.14,1.39-0.27,2.07-0.39c0.25-0.04,0.5-0.09,0.75-0.13c0.08-0.02,0.17-0.02,0.26-0.03c0.05,0,0.1-0.01,0.15-0.01
							l0.22-0.02l-0.08,0.2c-0.05,0.13-0.13,0.21-0.22,0.28c0.35-0.13,0.69-0.29,1.03-0.44c0.26-0.12,0.52-0.24,0.79-0.35
							c0.55-0.22,1.1-0.46,1.64-0.69c0.67-0.29,1.36-0.58,2.05-0.86c0.65-0.26,1.34-0.36,2-0.46c0.26-0.04,0.52-0.08,0.78-0.13
							c0.56-0.11,1.14-0.21,1.69-0.3c0.52-0.09,1.05-0.18,1.58-0.28c0.3-0.06,0.51-0.27,0.72-0.49c0.09-0.09,0.19-0.19,0.29-0.28
							c0.54-0.44,1.07-0.91,1.59-1.36c0.56-0.49,1.14-0.99,1.73-1.47c0.37-0.3,0.8-0.51,1.21-0.7c0.2-0.09,0.4-0.19,0.6-0.3
							c0.46-0.25,0.9-0.6,1.33-0.94c1.26-1,2.56-2.03,4.71-1.06l0.25,0.11l-0.25,0.13c-1.32,0.7-2.67,1.4-3.97,2.07
							c-0.61,0.31-1.21,0.63-1.82,0.94c-0.03,0.02-0.06,0.03-0.09,0.05c-0.19,0.09-0.36,0.18-0.46,0.35c-0.2,0.33-0.4,0.66-0.6,0.98
							c-0.11,0.18-0.22,0.35-0.32,0.53l-0.02,0.02c0,0,0,0.01-0.01,0.01c0.01,0,0.01-0.01,0.02-0.01c0.18-0.09,2.21,0.16,8.64,1
							c3.63,0.47,7.74,1.01,8.13,0.98c3.7-2.25,13.21-2.9,17.14-3.07c6.76-0.3,13.09,0,14.11,0.66l0.31,0.21l-0.37,0.04
							c-14.59,1.77-23.38,3.28-31.81,4.8c-2.25,0.4-4.59,0.3-6.79-0.31l-0.37-0.1c-2.71-0.75-6.81-1.88-7.19-1.72
							c-2.03,0.97-4.08,2.02-6.12,3.12c-0.13,0.07-0.31,0.15-0.46,0.08c-0.15-0.06-0.2-0.23-0.23-0.34
							c-0.03-0.12-0.07-0.2-0.12-0.22c-0.05-0.02-0.15,0.02-0.31,0.12c-0.72,0.47-1.38,0.66-2.09,0.6c-0.5-0.04-1.02-0.03-1.51-0.03
							c-0.26,0-0.53,0.01-0.8,0c-0.03,0-0.06,0-0.1,0.01c-0.12,0.02-0.34,0.05-0.44-0.17c-0.1-0.21,0.05-0.35,0.14-0.44
							c0.03-0.03,0.05-0.05,0.07-0.07c0.22-0.31,0.45-0.61,0.67-0.91c0.02-0.03,0.04-0.05,0.06-0.08c-0.29,0.05-0.56,0.13-0.79,0.3
							c-0.61,0.44-1.25,0.87-1.87,1.28l-0.35,0.23c-0.28,0.18-0.55,0.22-0.88,0.11c-0.11-0.03-0.22-0.08-0.32-0.12
							c-0.29-0.11-0.56-0.22-0.83-0.15c-0.57,0.14-1.16,0.32-1.81,0.57c-2.21,0.83-4.65,1.32-7.68,1.55
							c-1.05,0.08-2.04,0.35-2.95,0.81L542.39,747.35z M540.94,747.07h1.38c0.93-0.47,1.95-0.74,3.02-0.83
							c3-0.23,5.42-0.71,7.6-1.53c0.66-0.25,1.26-0.44,1.84-0.58c0.36-0.09,0.68,0.04,0.99,0.16c0.1,0.04,0.2,0.08,0.3,0.11
							c0.26,0.08,0.45,0.06,0.65-0.08l0.35-0.23c0.62-0.41,1.26-0.83,1.86-1.27c0.38-0.27,0.79-0.34,1.24-0.4l0.32-0.04l-0.19,0.26
							c-0.09,0.11-0.17,0.23-0.26,0.34c-0.22,0.29-0.45,0.6-0.67,0.9c-0.03,0.04-0.06,0.07-0.1,0.11c-0.09,0.09-0.1,0.11-0.09,0.14
							c0.01,0.02,0.02,0.03,0.15,0.01c0.04-0.01,0.09-0.01,0.14-0.01c0.26,0,0.53,0,0.79,0c0.51-0.01,1.03-0.01,1.54,0.03
							c0.65,0.05,1.26-0.13,1.92-0.56c0.17-0.11,0.37-0.22,0.56-0.15c0.13,0.05,0.23,0.19,0.28,0.41c0.03,0.1,0.06,0.15,0.08,0.16
							c0.01,0.01,0.06,0.02,0.22-0.07c2.03-1.1,4.1-2.15,6.13-3.12c0.42-0.2,3.19,0.55,7.38,1.71l0.37,0.1
							c2.16,0.6,4.46,0.7,6.67,0.31c8.34-1.5,17.01-2.99,31.29-4.73c-1.72-0.46-7.01-0.72-13.46-0.43
							c-3.91,0.17-13.4,0.82-17.03,3.05l-0.02,0.01c-0.18,0.08-2.12-0.16-8.27-0.96c-3.81-0.5-8.12-1.06-8.5-1.02
							c0,0-0.01,0.01-0.01,0.01c-0.06,0.03-0.21,0.11-0.34-0.02c-0.15-0.15-0.05-0.32,0-0.39l0.01-0.02
							c0.11-0.18,0.22-0.35,0.32-0.53c0.2-0.32,0.4-0.65,0.59-0.98c0.14-0.24,0.37-0.36,0.58-0.46c0.03-0.01,0.06-0.03,0.09-0.04
							c0.6-0.31,1.21-0.63,1.82-0.94c1.22-0.63,2.48-1.28,3.72-1.94c-1.84-0.71-2.97,0.19-4.16,1.14c-0.43,0.35-0.88,0.7-1.37,0.97
							c-0.2,0.11-0.41,0.21-0.61,0.31c-0.42,0.2-0.81,0.39-1.16,0.67c-0.58,0.48-1.16,0.98-1.72,1.47c-0.52,0.45-1.06,0.92-1.6,1.37
							c-0.09,0.08-0.18,0.17-0.27,0.26c-0.24,0.24-0.49,0.5-0.87,0.57c-0.53,0.1-1.06,0.19-1.58,0.28c-0.55,0.1-1.13,0.19-1.69,0.3
							c-0.26,0.05-0.53,0.09-0.78,0.13c-0.65,0.1-1.33,0.2-1.94,0.45c-0.69,0.27-1.38,0.57-2.05,0.85
							c-0.54,0.23-1.09,0.47-1.64,0.69c-0.26,0.11-0.52,0.23-0.78,0.34c-0.49,0.22-0.99,0.45-1.53,0.61
							c-0.08,0.17-0.21,0.18-0.31,0.16c-0.07-0.01-0.11-0.07-0.12-0.09c-0.03-0.05-0.03-0.1-0.03-0.13c0.04-0.16,0.16-0.19,0.24-0.2
							c0.07-0.14,0.19-0.22,0.31-0.29c0.02-0.01,0.04-0.02,0.05-0.03c-0.01,0-0.03,0-0.04,0.01c-0.25,0.04-0.5,0.09-0.75,0.13
							c-0.68,0.12-1.38,0.24-2.06,0.38c-0.36,0.07-0.74,0.14-1.1,0.2c-1.12,0.2-2.27,0.4-3.32,0.92c-0.98,0.49-2,0.95-2.98,1.39
							c-0.47,0.21-0.95,0.43-1.43,0.65C540.95,747.07,540.94,747.07,540.94,747.07z M586.13,740.59L586.13,740.59L586.13,740.59z
							 M569.45,738.87L569.45,738.87L569.45,738.87z"/>
					</g>
					<g>
						<path className="st72" d="M542.36,747.21c0.94-0.48,1.94-0.75,3-0.83c2.61-0.2,5.17-0.62,7.64-1.54c0.6-0.22,1.21-0.42,1.82-0.57
							c0.43-0.1,0.83,0.15,1.22,0.27c0.3,0.09,0.54,0.06,0.77-0.1c0.74-0.49,1.5-0.98,2.22-1.5c0.35-0.25,0.73-0.32,1.18-0.37
							c-0.31,0.41-0.63,0.82-0.93,1.24c-0.08,0.12-0.27,0.22-0.19,0.38c0.08,0.17,0.27,0.08,0.41,0.08
							c0.77,0.01,1.55-0.03,2.32,0.03c0.76,0.06,1.4-0.19,2-0.58c0.3-0.2,0.52-0.25,0.63,0.18c0.08,0.3,0.21,0.33,0.5,0.18
							c2.02-1.09,4.06-2.13,6.12-3.12c0.68-0.33,1.41-0.54,2.18-0.59c0,1.43,0,2.85,0,4.28c-1.61,0.58-3.3,0.72-4.98,0.93
							c-1.59,0.19-3.18,0.37-4.78,0.55c-1.61,0.18-3.21,0.36-4.82,0.52c-1.43,0.14-2.85,0.32-4.28,0.38
							c-0.13,0.01-0.24,0.04-0.28,0.18C550.19,747.21,546.27,747.21,542.36,747.21z"/>
						<path className="st8" d="M554.21,747.35h-12.43l0.51-0.26c0.94-0.48,1.97-0.76,3.05-0.84c3-0.23,5.42-0.71,7.6-1.53
							c0.66-0.25,1.26-0.44,1.84-0.58c0.36-0.09,0.68,0.04,0.99,0.16c0.1,0.04,0.2,0.08,0.3,0.11c0.26,0.08,0.45,0.06,0.65-0.08
							l0.35-0.23c0.62-0.41,1.26-0.83,1.86-1.27c0.38-0.27,0.79-0.34,1.24-0.4l0.32-0.04l-0.19,0.26c-0.09,0.11-0.17,0.23-0.26,0.34
							c-0.22,0.29-0.45,0.6-0.67,0.9c-0.03,0.04-0.06,0.07-0.1,0.11c-0.09,0.09-0.1,0.11-0.09,0.14c0.01,0.02,0.02,0.03,0.15,0.01
							c0.04-0.01,0.09-0.01,0.14-0.01c0.26,0,0.53,0,0.79,0c0.51-0.01,1.03-0.01,1.54,0.03c0.65,0.05,1.26-0.13,1.92-0.56
							c0.17-0.11,0.37-0.22,0.56-0.15c0.13,0.05,0.23,0.19,0.28,0.41c0.03,0.1,0.06,0.15,0.08,0.16c0.01,0.01,0.06,0.02,0.22-0.07
							c2.03-1.1,4.1-2.15,6.13-3.12c0.74-0.35,1.49-0.56,2.23-0.6l0.15-0.01v4.52l-0.09,0.03c-1.44,0.52-2.97,0.69-4.44,0.87
							c-0.19,0.02-0.38,0.04-0.57,0.07c-1.4,0.17-2.91,0.34-4.78,0.55l-0.41,0.05c-1.44,0.16-2.94,0.33-4.41,0.47
							c-0.36,0.03-0.72,0.07-1.07,0.11c-1.05,0.11-2.14,0.22-3.21,0.27c-0.13,0.01-0.14,0.03-0.16,0.08L554.21,747.35z
							 M542.99,747.07h11.04c0.1-0.17,0.3-0.17,0.37-0.18c1.07-0.04,2.15-0.16,3.2-0.27c0.36-0.04,0.72-0.07,1.08-0.11
							c1.47-0.14,2.96-0.31,4.4-0.47l0.41-0.05c1.86-0.21,3.38-0.38,4.78-0.55c0.19-0.02,0.38-0.05,0.57-0.07
							c1.43-0.17,2.9-0.34,4.29-0.82v-4.04c-0.66,0.06-1.32,0.25-1.98,0.56c-2.03,0.97-4.09,2.02-6.12,3.12
							c-0.13,0.07-0.31,0.15-0.46,0.08c-0.15-0.06-0.2-0.23-0.23-0.34c-0.03-0.12-0.07-0.2-0.12-0.22
							c-0.05-0.02-0.15,0.02-0.31,0.12c-0.72,0.47-1.38,0.66-2.09,0.6c-0.5-0.04-1.02-0.03-1.51-0.03c-0.26,0-0.53,0.01-0.8,0
							c-0.03,0-0.06,0-0.1,0.01c-0.12,0.02-0.34,0.05-0.44-0.17c-0.1-0.21,0.05-0.35,0.14-0.44c0.03-0.03,0.05-0.05,0.07-0.07
							c0.22-0.31,0.45-0.61,0.67-0.91c0.02-0.03,0.04-0.05,0.06-0.08c-0.29,0.05-0.56,0.13-0.79,0.3c-0.61,0.44-1.25,0.87-1.87,1.28
							l-0.35,0.23c-0.28,0.18-0.55,0.22-0.88,0.11c-0.11-0.03-0.22-0.08-0.32-0.12c-0.29-0.11-0.56-0.22-0.83-0.15
							c-0.57,0.14-1.16,0.32-1.81,0.57c-2.21,0.83-4.65,1.32-7.68,1.55C544.53,746.58,543.73,746.77,542.99,747.07z"/>
					</g>
					<g>
						<path className="st72" d="M573.25,727.8c-0.76,0.39-1.35,1.01-2.02,1.53c-1.7,1.32-3.37,2.68-5.06,4.01
							c-0.61,0.48-1.37,0.56-2.08,0.72c-0.2,0.04-0.59,0.31-0.61,0.06c-0.02-0.31,0.22-0.69,0.57-0.87c0.39-0.2,0.79-0.35,1.26-0.56
							c-1.69,0.06-2.84,1.02-4,2.04c-0.07-0.26,0.07-0.36,0.17-0.46c0.52-0.51,0.94-1.11,1.4-1.67c0.13-0.16,0.28-0.26,0.47-0.34
							c1.55-0.61,3.12-1.18,4.63-1.9c0.32-0.15,0.59-0.35,0.81-0.64c0.61-0.81,1.23-1.61,1.86-2.41c0.18-0.24,0.47-0.33,0.73-0.46
							c0.64-0.31,1.2-0.77,1.89-0.97C573.25,726.53,573.25,727.16,573.25,727.8z"/>
						<path className="st8" d="M561.23,734.99l-0.06-0.21c-0.08-0.32,0.08-0.47,0.18-0.57l0.02-0.02c0.38-0.37,0.71-0.8,1.04-1.22
							c0.12-0.15,0.23-0.3,0.35-0.44c0.14-0.17,0.3-0.29,0.53-0.38c0.37-0.15,0.74-0.29,1.11-0.43c1.16-0.45,2.36-0.92,3.51-1.46
							c0.32-0.15,0.57-0.34,0.76-0.6c0.61-0.81,1.23-1.6,1.86-2.42c0.17-0.22,0.42-0.33,0.63-0.43c0.05-0.02,0.1-0.04,0.14-0.07
							c0.23-0.11,0.45-0.25,0.67-0.38c0.39-0.23,0.79-0.47,1.24-0.6l0.17-0.05v2.18l-0.07,0.04c-0.52,0.27-0.97,0.65-1.41,1.02
							c-0.19,0.16-0.39,0.33-0.59,0.49c-1.09,0.85-2.18,1.72-3.25,2.57c-0.6,0.48-1.21,0.96-1.82,1.44
							c-0.52,0.42-1.15,0.54-1.75,0.67c-0.13,0.03-0.26,0.05-0.38,0.08c-0.04,0.01-0.11,0.04-0.17,0.06
							c-0.18,0.07-0.36,0.15-0.5,0.07c-0.05-0.03-0.1-0.09-0.11-0.2c-0.03-0.37,0.25-0.8,0.64-1c0.09-0.05,0.18-0.09,0.28-0.13
							c-1.16,0.34-2.06,1.13-2.87,1.84L561.23,734.99z M573.11,726.08c-0.35,0.13-0.67,0.32-1,0.52c-0.22,0.13-0.45,0.27-0.69,0.39
							c-0.05,0.02-0.1,0.05-0.15,0.07c-0.21,0.09-0.4,0.18-0.53,0.35c-0.63,0.81-1.25,1.61-1.86,2.41
							c-0.22,0.29-0.49,0.51-0.86,0.68c-1.15,0.55-2.36,1.01-3.53,1.47c-0.37,0.14-0.74,0.29-1.11,0.43
							c-0.18,0.07-0.31,0.16-0.42,0.3c-0.12,0.15-0.23,0.29-0.35,0.44c-0.25,0.32-0.51,0.65-0.79,0.96c0.93-0.77,2-1.48,3.46-1.53
							l0.71-0.02l-0.65,0.29c-0.15,0.06-0.29,0.12-0.42,0.18c-0.29,0.12-0.57,0.24-0.83,0.37c-0.28,0.14-0.5,0.46-0.49,0.71
							c0.06-0.01,0.17-0.05,0.23-0.08c0.08-0.03,0.15-0.06,0.22-0.08c0.13-0.03,0.26-0.06,0.39-0.08c0.6-0.12,1.17-0.24,1.64-0.61
							c0.61-0.48,1.21-0.96,1.81-1.44c1.06-0.85,2.16-1.72,3.25-2.57c0.2-0.15,0.39-0.32,0.58-0.48c0.43-0.36,0.87-0.74,1.38-1.02
							V726.08z"/>
					</g>
					<g>
						<path className="st72" d="M485.58,747.21c0.01-0.15,0.13-0.2,0.23-0.25c0.92-0.49,1.82-1.02,2.77-1.46c0.85-0.4,1.8-0.5,2.72-0.7
							c0.93-0.2,1.87-0.37,2.78-0.65c0.05-0.02,0.11,0,0.31,0c-0.46,0.42-0.84,0.78-1.22,1.12c-0.67,0.59-1.35,1.17-2.02,1.76
							c-0.05,0.05-0.08,0.12-0.11,0.19H485.58z"/>
						<path className="st8" d="M491.12,747.35h-5.69l0.01-0.15c0.02-0.21,0.18-0.29,0.28-0.35l0.02-0.01c0.27-0.14,0.53-0.29,0.8-0.43
							c0.64-0.35,1.3-0.72,1.98-1.03c0.67-0.31,1.39-0.45,2.09-0.58c0.22-0.04,0.44-0.08,0.66-0.13c0.16-0.03,0.33-0.07,0.49-0.1
							c0.75-0.16,1.53-0.32,2.28-0.54c0.05-0.02,0.1-0.01,0.18-0.01c0.04,0,0.1,0,0.17,0h0.35l-0.26,0.24
							c-0.15,0.14-0.29,0.27-0.42,0.39c-0.28,0.26-0.54,0.5-0.8,0.73c-0.36,0.32-0.73,0.64-1.09,0.95c-0.31,0.27-0.62,0.53-0.92,0.8
							c-0.01,0.01-0.03,0.05-0.05,0.08c-0.01,0.02-0.02,0.05-0.04,0.07L491.12,747.35z M485.88,747.07h5.08
							c0.02-0.05,0.05-0.11,0.11-0.15c0.31-0.27,0.62-0.54,0.92-0.81c0.36-0.32,0.73-0.63,1.09-0.95c0.26-0.23,0.52-0.47,0.8-0.72
							c0.04-0.04,0.09-0.08,0.14-0.13c-0.73,0.21-1.47,0.37-2.19,0.52c-0.16,0.03-0.33,0.07-0.49,0.1
							c-0.22,0.05-0.45,0.09-0.66,0.13c-0.68,0.13-1.39,0.26-2.03,0.56c-0.67,0.31-1.33,0.67-1.96,1.02
							C486.41,746.79,486.15,746.93,485.88,747.07z"/>
					</g>
					<g>
						<path className="st72" d="M530.35,745.62c2.97-0.42,5.91-0.99,8.89-1.48c-0.03,0.2-0.17,0.21-0.29,0.25
							c-2.22,0.73-4.44,1.45-6.65,2.18c-0.52,0.17-1.12,0.19-1.53,0.64c-1.7,0-10.8,5.37-12.5,5.37c0.29-0.37,8.15-5.71,8.53-5.84
							c1.09-0.38,2.21-0.68,3.32-1.02C530.23,745.74,530.3,745.69,530.35,745.62z"/>
						<path className="st8" d="M518.28,752.72H518l0.17-0.22c0.26-0.34,8.13-5.73,8.59-5.89c0.79-0.27,1.6-0.51,2.39-0.74
							c0.31-0.09,0.62-0.18,0.93-0.28l0.03-0.01l0.03,0.01c0.02,0,0.05,0.01,0.09-0.05l0.03-0.05l0.06-0.01
							c1.94-0.27,3.9-0.62,5.81-0.95c1.01-0.18,2.05-0.36,3.09-0.53l0.18-0.03l-0.03,0.18c-0.04,0.26-0.23,0.32-0.34,0.35
							c-0.01,0-0.03,0.01-0.04,0.01c-0.64,0.21-1.28,0.42-1.92,0.63c-1.55,0.51-3.16,1.03-4.73,1.55c-0.14,0.05-0.29,0.08-0.44,0.12
							c-0.39,0.1-0.76,0.19-1.03,0.49l-0.04,0.05h-0.06c-0.82,0-3.55,1.36-6.19,2.67C521.8,751.4,519.16,752.72,518.28,752.72z
							 M530.14,745.86c-0.3,0.09-0.6,0.18-0.91,0.27c-0.78,0.23-1.6,0.47-2.38,0.74c-0.4,0.15-6.47,4.29-8.08,5.49
							c1.15-0.32,3.45-1.47,5.7-2.58c2.73-1.36,5.31-2.64,6.25-2.7c0.32-0.33,0.73-0.43,1.13-0.53c0.15-0.04,0.28-0.07,0.42-0.11
							c1.58-0.52,3.18-1.05,4.73-1.55c0.49-0.16,0.98-0.32,1.47-0.48c-0.76,0.13-1.53,0.26-2.27,0.39
							c-1.89,0.33-3.84,0.67-5.76,0.95C530.33,745.85,530.23,745.87,530.14,745.86z"/>
					</g>
					<g>
						<path className="st73" d="M573.25,716.96c-0.03-0.01-0.06-0.03-0.09-0.03c-1.83,0.2-3.55-0.42-5.3-0.77
							c-1.37-0.28-2.73-0.28-4.11,0.03c-0.8,0.18-1.59,0.45-2.41,0.52c0,0-1,0.35-1.03,0.3c1.01-0.68,3.6-1.41,4.95-1.82
							c1.13-0.34,2.24-0.31,3.37-0.08c0.83,0.17,1.66,0.37,2.48,0.59c0.71,0.19,1.43,0.27,2.15,0.07
							C573.25,716.17,573.25,716.56,573.25,716.96z"/>
						<path className="st8" d="M560.31,717.15c-0.09,0-0.1-0.03-0.13-0.08l-0.06-0.11l0.1-0.07c0.9-0.61,3.03-1.25,4.44-1.67
							c0.2-0.06,0.38-0.11,0.54-0.16c1.05-0.32,2.14-0.35,3.44-0.08c0.73,0.15,1.5,0.33,2.48,0.59c0.81,0.22,1.47,0.24,2.08,0.07
							l0.17-0.05v1.55l-0.18-0.05c-0.01,0-0.03-0.01-0.04-0.02c0,0,0,0-0.01,0c-1.41,0.15-2.76-0.18-4.07-0.49
							c-0.41-0.1-0.84-0.2-1.26-0.29c-1.38-0.28-2.71-0.27-4.06,0.03c-0.24,0.05-0.49,0.12-0.72,0.18
							c-0.55,0.14-1.11,0.29-1.69,0.34C560.71,717.08,560.44,717.15,560.31,717.15z M565.87,715.82c0.67,0,1.35,0.07,2.02,0.21
							c0.42,0.09,0.85,0.19,1.27,0.29c1.28,0.31,2.6,0.63,3.96,0.49v-0.85c-0.61,0.13-1.27,0.09-2.05-0.11
							c-0.98-0.26-1.74-0.44-2.47-0.59c-1.25-0.25-2.3-0.23-3.3,0.08c-0.16,0.05-0.35,0.1-0.54,0.16c-0.95,0.28-2.23,0.67-3.22,1.07
							c0.49-0.06,0.98-0.19,1.46-0.31c0.24-0.06,0.49-0.13,0.73-0.18C564.43,715.9,565.15,715.82,565.87,715.82z"/>
					</g>
					<g>
						<path className="st72" d="M491.79,750.39c0.03-0.16,3.89-3.38,4.01-3.43c1.51-0.64,3.06-1.19,4.51-1.93
							c1.4-0.71,2.91-0.98,4.38-1.43c0.45-0.14,0.87-0.34,1.29-0.54c0.9-0.44,1.78-0.91,2.68-1.36c0.08-0.04,0.15-0.11,0.28-0.03
							c-0.17,0.31-0.51,0.45-0.73,0.7c-1.02,1.1-2.38,1.56-3.75,2.02c-1.18,0.39-2.35,0.81-3.49,1.3c-0.78,0.33-9.25,8.67-9.78,9.35
							C490.03,755.04,492.95,750.39,491.79,750.39z"/>
						<path className="st8" d="M491.25,755.17h-0.07c-0.18,0-0.28-0.09-0.33-0.16c-0.23-0.34,0.03-1.11,0.5-2.35
							c0.3-0.78,0.7-1.85,0.53-2.09c-0.01-0.01-0.03-0.04-0.1-0.04h-0.17l0.03-0.16c0.01-0.07,0.03-0.16,2.02-1.84
							c0.02-0.02,1.94-1.64,2.08-1.7c0.45-0.19,0.91-0.37,1.35-0.55c1.05-0.42,2.13-0.86,3.15-1.38c1-0.51,2.08-0.8,3.12-1.08
							c0.42-0.11,0.86-0.23,1.28-0.36c0.45-0.14,0.87-0.34,1.27-0.54c0.6-0.29,1.21-0.61,1.8-0.91c0.29-0.15,0.58-0.3,0.87-0.45
							c0.01-0.01,0.02-0.01,0.03-0.02c0.07-0.04,0.21-0.12,0.38-0.01l0.11,0.07l-0.06,0.11c-0.12,0.22-0.3,0.36-0.48,0.49
							c-0.1,0.08-0.2,0.15-0.28,0.24c-1.06,1.14-2.52,1.63-3.81,2.06c-1.38,0.46-2.49,0.87-3.48,1.29
							c-0.31,0.13-2.21,1.86-4.94,4.51c-2.29,2.22-4.53,4.46-4.78,4.8L491.25,755.17z M492.03,750.33c0.04,0.03,0.07,0.06,0.09,0.09
							c0.23,0.34-0.03,1.11-0.5,2.35c-0.3,0.78-0.7,1.85-0.53,2.09c0.01,0.01,0.02,0.02,0.04,0.03c0.83-0.95,8.98-9,9.78-9.34
							c1-0.42,2.12-0.84,3.5-1.3c1.32-0.44,2.68-0.9,3.7-1.99c0.1-0.1,0.21-0.19,0.31-0.27c0.04-0.03,0.07-0.06,0.11-0.08
							c-0.23,0.12-0.45,0.23-0.67,0.35c-0.59,0.3-1.2,0.62-1.81,0.91c-0.41,0.2-0.84,0.41-1.31,0.55c-0.43,0.13-0.87,0.25-1.29,0.36
							c-1.03,0.28-2.09,0.56-3.06,1.06c-1.03,0.52-2.12,0.96-3.17,1.39c-0.44,0.18-0.9,0.36-1.34,0.55
							C495.63,747.24,492.68,749.7,492.03,750.33z"/>
					</g>
					<g>
						<path className="st72" d="M577.75,736.77c-0.98,0.37-6.37,1.05-7.32,1.48c-0.34,0.15-0.68,0.33-1.02,0.5
							c-0.07,0.03-0.14,0.09-0.21,0.02c-0.08-0.08-0.02-0.17,0.03-0.24c0.31-0.5,0.62-1,0.92-1.51c0.14-0.24,0.38-0.33,0.61-0.45
							c0.83-0.43,10.94-1.4,11.77-1.83C582.53,735.28,577.75,736.22,577.75,736.77z"/>
						<path className="st8" d="M569.27,738.93c-0.05,0-0.11-0.02-0.16-0.07c-0.15-0.15-0.05-0.32,0-0.39l0.01-0.02
							c0.11-0.18,0.22-0.35,0.32-0.53c0.2-0.32,0.4-0.65,0.59-0.98c0.14-0.24,0.37-0.36,0.58-0.46c0.03-0.01,0.06-0.03,0.09-0.04
							c0.44-0.23,3.11-0.57,5.94-0.93c2.55-0.32,5.44-0.69,5.83-0.9l0.2-0.1v0.22c0,0.34-0.72,0.6-2.49,1.15
							c-0.88,0.27-2.2,0.68-2.29,0.89v0.09l-0.09,0.03c-0.47,0.18-1.88,0.42-3.38,0.67c-1.61,0.27-3.44,0.58-3.93,0.8
							c-0.23,0.1-0.46,0.22-0.68,0.33c-0.11,0.06-0.22,0.11-0.33,0.16c-0.01,0-0.02,0.01-0.03,0.01
							C569.41,738.9,569.34,738.93,569.27,738.93z M581.76,735.07c-1.09,0.2-3.05,0.45-5.08,0.71c-2.56,0.33-5.45,0.7-5.85,0.9
							c-0.03,0.02-0.06,0.03-0.09,0.05c-0.19,0.09-0.36,0.18-0.46,0.35c-0.2,0.33-0.4,0.66-0.6,0.98c-0.11,0.18-0.22,0.35-0.32,0.53
							l-0.02,0.02c0,0,0,0.01-0.01,0.01c0.01,0,0.01-0.01,0.02-0.01c0.11-0.05,0.22-0.11,0.33-0.16c0.23-0.11,0.46-0.23,0.7-0.34
							c0.52-0.23,2.29-0.53,4-0.82c1.36-0.23,2.76-0.47,3.27-0.63c0.14-0.29,0.88-0.55,2.46-1.04
							C580.61,735.46,581.27,735.26,581.76,735.07z M577.88,736.78C577.88,736.78,577.88,736.78,577.88,736.78L577.88,736.78z
							 M582.39,734.73L582.39,734.73C582.39,734.73,582.39,734.73,582.39,734.73z"/>
					</g>
					<g>
						<path className="st72" d="M487.26,751.21c0.51-0.65,6.47-5.03,7.16-5.43c0.93-0.53,1.92-0.95,2.84-1.52
							c0.09-0.06,0.22-0.19,0.32-0.07c0.11,0.13-0.07,0.21-0.14,0.29c-0.73,0.83-1.46,1.66-2.2,2.48c-0.06,0.07-0.11,0.14-0.1,0.24
							C494.26,747.21,488.14,751.21,487.26,751.21z"/>
						<path className="st8" d="M487.26,751.35h-0.28l0.17-0.22c0.32-0.41,2.58-2.12,3.54-2.83c1.67-1.25,3.31-2.43,3.66-2.63
							c0.41-0.23,0.84-0.45,1.25-0.66c0.53-0.27,1.08-0.54,1.59-0.86c0.01-0.01,0.03-0.02,0.05-0.03c0.08-0.06,0.29-0.21,0.45-0.01
							c0.05,0.06,0.07,0.12,0.07,0.19c-0.01,0.11-0.1,0.18-0.16,0.23c-0.02,0.02-0.04,0.03-0.05,0.04c-0.7,0.8-1.41,1.6-2.1,2.38
							l-0.09,0.1c-0.06,0.07-0.07,0.1-0.07,0.13l0.03,0.16h-0.16c-0.4,0-2.25,1.05-3.88,1.98
							C489.07,750.59,487.7,751.35,487.26,751.35z M497.4,744.34C497.4,744.34,497.39,744.34,497.4,744.34
							c-0.03,0.02-0.05,0.03-0.07,0.05c-0.52,0.32-1.07,0.6-1.61,0.87c-0.41,0.21-0.83,0.42-1.24,0.65
							c-0.66,0.38-5.26,3.76-6.71,5.01c0.76-0.33,2.12-1.11,3.36-1.82c2.02-1.15,3.33-1.89,3.88-2c0.02-0.1,0.09-0.17,0.13-0.21
							l0.09-0.1c0.69-0.78,1.4-1.58,2.1-2.38C497.36,744.37,497.38,744.36,497.4,744.34z"/>
					</g>
					<g>
						<path className="st75" d="M491.59,683.38c-1.04,0.48-1.73,1.34-2.46,2.17c-0.02,0.02-0.06,0.05-0.05,0.07
							c0.13,0.57-0.35,0.44-0.66,0.53c0.41-0.53,0.28-1.36,1.06-1.71c0.38-0.17,0.41-0.68,0.6-1.04c0.26-0.5,0.49-1,0.9-1.4
							c0.17-0.17,0.19-0.37,0.11-0.58c-0.48,0.24-0.92,0.53-1.3,0.9c-1.34,1.32-2.25,2.92-2.89,4.67c-0.14,0.39-0.33,0.58-0.75,0.64
							c-0.76,0.11-1.45,0.36-1.95,1.02c-0.27,0.36-0.58,0.71-1.04,0.85c-0.52,0.15-1.05,0.27-1.58,0.41
							c-0.36,0.51-0.91,0.83-1.35,1.25c-0.92,0.86-1.9,1.65-2.86,2.46c-0.17,0.14-0.27,0.32-0.33,0.53c-0.2,0.73-0.42,1.46-0.6,2.19
							c-0.08,0.35-0.25,0.62-0.52,0.82c-1.21,0.91-1.82,2.15-2.08,3.6c-0.06,0.34-0.19,0.67-0.25,1.01c-0.1,0.64-0.6,0.92-1.05,1.25
							c-0.46,0.34-0.92,0.69-1.42,0.98c-0.4,0.23-0.59,0.58-0.63,0.99c-0.12,1.29-0.91,2.13-1.86,2.87
							c-0.81,0.63-1.44,1.43-2.13,2.17c-1.19,1.27-2.34,2.58-3.54,3.85c-0.25,0.26-0.64,0.33-0.98,0.47
							c-1.04,0.41-2.09,0.81-3.11,1.28c-0.79,0.37-1.35,1.02-1.99,1.57c-1.26,1.09-2.52,2.18-3.72,3.34
							c-0.8,0.78-1.5,1.67-2.23,2.51c-0.09,0.1-0.15,0.23-0.29,0.28c-0.11-0.12-0.01-0.23,0.03-0.32c0.41-0.96,0.83-1.92,1.24-2.88
							c0.2-0.47,0.66-0.66,1.02-0.95c1.05-0.84,1.85-1.9,2.69-2.93c1.15-1.42,2.46-2.47,4.03-3.44c1.17-0.72,1.92-1.72,3.11-2.42
							c0.33-0.2,0.48-0.58,0.69-0.89c0.59-0.86,1.53-1.72,2.08-2.61c-1.21,0.82-2.14,1.99-3.54,2.72c0.07-0.55,0.45-0.76,0.58-1.12
							c0.13-0.35,0.34-0.66,0.62-0.91c0.54-0.48,0.56-1.14,0.76-1.73c0.31-0.9,0.42-1.87,1.08-2.62c0.04-0.05,0.06-0.11,0.11-0.14
							c1.14-0.81,1.37-2.05,1.55-3.31c0.06-0.41,0.31-0.68,0.54-0.98c0.23-0.3,0.45-0.61,0.71-0.89c0.42-0.46,0.26-1.03,0.37-1.56
							c0.04-0.19,0.03-0.39,0.05-0.59c-0.52,0.14-0.99,0.39-1.42,0.7c-1.3,0.92-2.49,1.97-3.72,2.98c-0.32,0.27-0.64,0.45-1.07,0.38
							c-1.03,0.36-2.01,0.83-3,1.28c-0.9,0.41-1.67,0.98-2.31,1.72c-1.13,1.3-2.29,2.59-3.37,3.93c-0.38,0.47-0.5,1.03-0.64,1.59
							c-0.11,0.45-0.29,0.86-0.54,1.24c-0.52,0.8-0.92,1.67-1.53,2.44c-0.47-0.76-0.26-1.46,0.13-2.11
							c0.33-0.56,0.72-1.08,1.09-1.62c0.07-0.1,0.2-0.19,0.16-0.31c-0.05-0.16-0.2-0.07-0.32-0.06c-0.2,0.03-0.28-0.09-0.3-0.26
							c-0.14-1.16-0.38-2.32-0.37-3.47c0.01-0.93,0.47-1.75,1.42-2.15c0.35-0.15,0.68-0.34,1.02-0.51c0.24-0.12,0.36-0.28,0.26-0.58
							c-0.09-0.3-0.1-0.63-0.14-0.94c-0.12,0.01-0.25,0-0.34,0.11c-0.2,0.73-0.69,1.22-1.29,1.64c-0.31,0.22-0.59,0.48-0.91,0.68
							c-1,0.63-1.43,1.64-1.65,2.72c-0.17,0.84-0.67,1.41-1.22,1.97c-0.26,0.27-0.39,0.54-0.39,0.92c0,0.81-0.16,1.58-0.64,2.28
							c-0.39,0.56-0.37,1.27-0.55,1.91c-0.03,0.11,0.01,0.25-0.23,0.35c0.17-1.18-0.25-2.36,0.35-3.46
							c0.15-0.28,0.13-0.51-0.05-0.79c-0.58-0.88-0.85-1.82-0.52-2.89c0.23-0.75,0.19-0.76-0.46-1.19
							c-0.34-0.23-0.68-0.45-1.02-0.68c-0.18-0.12-0.29-0.11-0.37,0.11c-0.34,0.92-0.81,1.81-0.7,2.84
							c0.02,0.22-0.09,0.37-0.24,0.51c-0.47,0.45-0.93,0.91-1.41,1.35c-0.56,0.52-0.74,1.2-0.81,1.92c-0.04,0.4-0.07,0.8-0.16,1.2
							c-0.43-0.81-0.76-1.64-0.86-2.55c-0.01-0.13,0.05-0.3-0.12-0.36c-0.13-0.04-0.21,0.08-0.31,0.15
							c-0.78,0.5-1.13,1.32-1.57,2.08c-0.25,0.43-0.45,0.89-0.67,1.34c-0.05,0.1-0.07,0.27-0.22,0.26
							c-0.15-0.01-0.15-0.18-0.16-0.29c-0.13-1.06-0.92-1.65-1.61-2.33c0.17,0.88,0.79,1.64,0.65,2.61
							c-0.2-0.03-0.21-0.16-0.23-0.25c-0.19-0.72-0.66-1.21-1.29-1.57c-0.37-0.21-0.63-0.61-1.1-0.67c0.47,0.79,0.93,1.59,1.41,2.37
							c0.48,0.76,0.56,1.62,0.66,2.48c0.02,0.15-0.07,0.25-0.15,0.36c-0.23,0.3-0.44,0.62-0.66,0.92c-0.33,0.44-0.49,0.93-0.44,1.49
							c0.03,0.4-0.47,1.1-0.83,1.24c-0.17,0.07-0.21-0.04-0.25-0.17c-0.08-0.31-0.17-0.61-0.25-0.92c-0.2-0.74-0.67-1.26-1.31-1.65
							c-0.8-0.49-1.57-1.02-2.37-1.55c-0.07,0.19,0.05,0.26,0.13,0.34c0.58,0.54,1.08,1.15,1.61,1.73c0.52,0.56,0.64,1.25,0.78,1.95
							c0.05,0.24,0.04,0.49,0.11,0.72c0.3,1.03,0.11,1.99-0.29,2.96c-0.28,0.68-0.48,1.39-0.72,2.08c-0.07-0.01-0.06,0-0.12-0.01
							c-0.1-0.65-0.2-1.29-0.29-1.94c-0.06-0.42-0.12-0.85-0.14-1.27c-0.02-0.42-0.25-0.74-0.46-1.07
							c-0.48-0.73-1.79-6.23-2.39-6.89c-0.02,0.29-26.32,25.41-26.07,25.26c5.71-3.42,10.83-5.88,10.77-4.97
							c-0.07,1.16,2.28,1.78,3.06,1.69c1.84-0.21,4.2,0.29,4.26,0c0.17-0.82,8.49,0.61,9.13-0.01c0.28-0.27,1.55-4.06,1.88-4.26
							c0.31-0.19,0.33-0.37,0.21-0.67c-0.34-0.85-0.33-0.86,0.47-1.31c0.04-0.02,0.08-0.05,0.12-0.07c0.67-0.43,0.68-0.43,0.18-1.03
							c-0.1-0.13-0.17-0.25-0.12-0.41c0.35-1.05,0.74-2.05,2-2.3c0.29-0.06,0.37-0.26,0.34-0.5c-0.11-1.04,0.65-1.41,1.41-1.21
							c0.96,0.25,1.57-0.01,2.09-0.76c0.08-0.12,0.22-0.21,0.32-0.33c0.41-0.52,0.94-0.66,1.54-0.41c0.57,0.24,1.04,0.13,1.48-0.28
							c0.31-0.3,0.64-0.31,0.94,0.04c0.6,0.69,1.35,1.02,2.27,0.97c0.49-0.02,0.85,0.35,1.08,0.83c0.12,0.24,0.32,0.42,0.53,0.59
							c0.09,0.07,0.12,0.29,0.3,0.22c0.12-0.04,0.06-0.18,0.09-0.28c0.2-0.96,0.06-1.91-0.21-2.83c-0.25-0.85,0-1.52,0.61-2.09
							c0.22-0.2,0.35-0.38,0.19-0.7c-0.11-0.23-0.1-0.5-0.17-0.75c-0.15-0.55-0.04-1,0.42-1.38c0.38-0.32,0.74-0.67,0.89-1.18
							c0.08-0.27,0.27-0.48,0.53-0.59c0.65-0.26,0.8-0.76,0.69-1.39c-0.02-0.1-0.06-0.21,0.07-0.37c0.21,0.51,0.4,0.98,0.59,1.46
							c0.12,0.33,0.22,0.66,0.34,0.99c0.14,0.42,0.19,0.81-0.13,1.2c-0.19,0.23-0.3,0.53-0.48,0.77c-0.62,0.78-0.92,1.65-0.8,2.64
							c0.04,0.33-0.09,0.65-0.34,0.86c-0.29,0.24-0.54,0.53-0.82,0.79c-0.2,0.19-0.28,0.39-0.1,0.64c0.1,0.15,0.16,0.34,0.27,0.47
							c0.64,0.73,0.66,1.58,0.48,2.47c-0.06,0.29-0.1,0.59-0.12,0.89c-0.04,0.68-0.2,1.24-0.86,1.65c-1.18,0.74-2.14,1.79-3.33,2.52
							c-1.34,0.83-2.65,1.71-4.06,2.43c-0.47,0.24-0.97,0.4-1.49,0.49c-1.21,0.21-2.32,0.71-3.44,1.18c-1.49,0.62-3.1,1-4.67,1.37
							c-0.53,0.13-50.61,3.46-51.15,3.54c0.01,1.42,22.34,1.15,22.34,2.57c0.17-0.11,21.51,0.74,21.56,0.57
							c0.34-1,7.09-4.03,7.71-4.86c0.1-0.13,0.21-0.31,0.4-0.18c0.26,0.18,0.36-0.01,0.46-0.17c0.12-0.19,0.2-0.41,0.32-0.6
							c0.22-0.36,0.47-0.67,0.9-0.76c0.29-0.06,0.58-0.14,0.85-0.26c0.9-0.39,0.97-0.53,0.97,0.08c0,0.65,0.02,1.3-0.1,1.95
							c-0.02,0.1-0.1,0.22,0.01,0.29c0.14,0.09,0.21-0.07,0.3-0.14c0.54-0.4,0.97-0.9,1.11-1.56c0.19-0.9,0.81-1.34,1.57-1.68
							c0.37-0.16,0.73-0.33,1.18-0.54c-0.18,0.41-1.5,1.47-1.74,1.68c-1.02,0.9-0.73,1.1-1.26,2.34c-0.38,0.88-1.02,1.37-1.93,1.63
							c-1.65,0.48-5.83,2.03-7.29,2.95c-0.09,0.06-4.25,7.33-4.37,7.28c0,0.76,6.7-6.53,6.7-5.77c0.07-0.01,0.15-0.02,0.22-0.04
							c0.91-0.33,1.8-0.72,2.74-0.97c1.05-0.28,2.07-0.67,3.04-1.18c0.32-0.17,0.63-0.39,0.94-0.44c-0.25,0.29-0.52,0.6-0.79,0.92
							c-0.06,0.07-3.17,5-3.21,5.12c-0.73,2.42,4.6-3.03,4.65-3.06c0,0,1.4-1.59,2.28-1.94c0.38-0.15-0.18-1.03,0.24-0.97
							c0.71,0.1,1.39,0.32,2.07,0.52c0.48,0.14,0.95,0.23,1.46-0.01c0.81-0.38,1.69-0.55,2.5-0.92c0.09-0.04,0.21-0.1,0.28,0.02
							c0.05,0.1-0.01,0.18-0.1,0.22c-0.57,0.24-0.84,0.82-1.35,1.13c-0.12,0.07-0.29,0.18-0.26,0.45c0.16-0.11,0.3-0.19,0.43-0.28
							c0.53-0.34,1.06-0.7,1.61-1.01c1.21-0.68,2.37-1.46,3.67-1.97c0.32-0.12,0.63-0.27,0.91-0.45c1.25-0.79,2.41-1.7,3.54-2.64
							c0.51-0.42,1.04-0.79,1.76-0.37c0.27,0.16,0.61,0.12,0.91-0.08c1.31-0.89,2.62-1.79,3.85-2.79c0.12-0.1,0.23-0.23,0.44-0.16
							c-0.11,0.3-0.39,0.52-0.33,0.85c0.02,0.13-0.06,0.3,0.11,0.36c0.13,0.05,0.21-0.09,0.3-0.17c0.44-0.38,0.86-0.77,1.32-1.11
							c0.22-0.16,0.43-0.27,0.46-0.56c-0.11-0.18-0.08-0.38-0.08-0.58c0-0.88,0.01-1.76,0-2.64c-0.01-0.36,0.11-0.5,0.47-0.56
							c0.7-0.1,1.4-0.28,2.1-0.41c0.25-0.05,0.39-0.17,0.51-0.39c0.52-0.9,1.07-1.79,1.61-2.69c0.18-0.3,0.4-0.46,0.79-0.41
							c0.44,0.05,0.88,0.06,1.31,0.01c0.46-0.05,0.67,0.05,0.49,0.42c-0.12,0.25-0.14,0.5-0.02,0.74c0.14,0.29,0.04,0.51-0.14,0.75
							c-0.6,0.78-1.23,1.54-1.94,2.22c-0.13,0.13-0.27,0.24-0.34,0.42c0.25,0,0.47-0.1,0.69-0.2c1.33-0.61,2.52-1.48,3.87-2.05
							c0.22-0.09,0.4-0.14,0.58,0.03c0.47,0.43,0.85,0.93,1.03,1.55c0.04,0.04,0.09,0.08,0.13,0.11c0.13,0.02,0.23,0.1,0.31,0.2
							c0.24,0.34,0.56,0.31,0.92,0.28c0.42-0.04,0.76-0.25,1.13-0.39c0.23-0.17,0.32-0.44,0.44-0.68c0.16-0.33,0.4-0.47,0.75-0.49
							c0.88-0.06,1.75-0.13,2.63-0.18c0.23-0.01,0.4-0.09,0.57-0.24c0.58-0.53,1.18-1.04,1.75-1.59c0.2-0.19,0.32-0.14,0.49,0.02
							c0.3,0.27,0.61,0.52,1.01,0.85c0-0.48,0.01-0.84,0-1.2c-0.01-0.28,0.09-0.42,0.37-0.45c0.53-0.06,1.05-0.17,1.57-0.22
							c0.35-0.03,0.43-0.17,0.28-0.47c-0.3-0.61-0.6-1.22-0.9-1.84c-0.04-0.09-0.14-0.19-0.05-0.29c0.09-0.1,0.2-0.02,0.3,0
							c0.49,0.15,1,0.27,1.48,0.45c0.39,0.15,0.62,0.52,0.92,0.8c0.05,0.05,0.09,0.12,0.21,0.08c-0.03-0.35-0.3-0.58-0.49-0.82
							c-0.67-0.86-1.23-1.8-1.88-2.67c-0.11-0.15-0.12-0.21,0.07-0.29c0.31-0.14,0.57-0.13,0.84,0.08c0.33,0.27,0.68,0.51,1.01,0.76
							c0.06,0.05,0.12,0.11,0.2,0.05c0.09-0.07,0-0.18,0.01-0.26c0.02-0.35,0-0.71-0.16-1.03c-0.52-1.06,0.22-0.91,0.54-1.14
							c0.24-0.18,0.36,0.05,0.49,0.23c0.88,1.27,1.77,2.53,2.66,3.79c0.05,0.07,0.09,0.16,0.27,0.12c-0.43-1.6-0.86-3.19-1.3-4.85
							c0.36,0.18,0.55,0.46,0.75,0.7c0.71,0.84,1.4,1.71,2.11,2.56c0.47,0.56,0.66,1.26,0.91,1.92c0.12,0.31,0.27,0.56,0.57,0.73
							c0.89,0.52,1.75,1.09,2.65,1.59c0.35,0.19,0.37,0.3,0.09,0.53c-0.04,0.04-0.07,0.1-0.1,0.15c0.09,0.12,0.21,0.21,0.35,0.18
							c0.6-0.12,1.25-0.08,1.81-0.39c0.75-0.41,1.57-0.71,2.16-1.38c0.05-0.05,0.12-0.09,0.2-0.11c0.36-0.09,0.57-0.38,0.79-0.64
							c-0.62,0.32-1.29,0.25-1.96,0.26c-0.1,0-0.24,0.04-0.27-0.1c-0.01-0.06,0.07-0.16,0.13-0.22c0.35-0.34,0.7-0.68,1.05-1.01
							c0.17-0.16,0.33-0.33,0.31-0.58c-0.14-1.41,0.17-2.73,0.78-4c0.05-0.1,0.17-0.22,0.05-0.32c-0.12-0.09-0.22,0.04-0.33,0.09
							c-0.44,0.2-0.87,0.41-1.41,0.67c0.2-0.76,0.41-1.43,0.56-2.11c0.19-0.88,0.7-1.48,1.5-1.87c0.17-0.08,0.34-0.2,0.49-0.43
							c-0.57-0.07-1.1-0.11-1.59,0.17c-0.44,0.24-0.85,0.52-1.28,0.79c-0.07,0.05-0.13,0.15-0.23,0.1
							c-0.13-0.06-0.08-0.19-0.07-0.28c0.09-0.57,0.44-1.03,0.72-1.51c0.36-0.61,0.76-1.2,1.07-1.85c0.09-0.2,0.15-0.4,0.06-0.56
							c-0.59-1.08-0.25-2.17-0.15-3.28c0.09-1.01,0.76-1.53,1.43-2.08c0.34-0.28,0.69-0.55,1.01-0.84c0.23-0.2,0.36-0.17,0.55,0.07
							c0.29,0.38,0.48,0.83,0.92,1.24c-0.1-0.45-0.14-0.82-0.27-1.15c-0.28-0.76-0.12-1.45,0.27-2.14c0.3-0.54,0.56-1.09,0.78-1.67
							c0.09-0.23,0.21-0.33,0.43-0.34c0.2-0.01,0.39-0.05,0.59-0.05c0.7,0.03,1.08-0.54,1.6-0.86c0.16-0.1,0.31-0.34,0.5-0.25
							c0.24,0.1,0.11,0.38,0.12,0.58c0.04,1.09,0.07,2.18,0.1,3.27c0,0.1-0.02,0.21,0.07,0.32c0.16-0.06,0.14-0.22,0.16-0.33
							c0.21-1.09,0.41-2.18,0.61-3.26c0.09-0.5,0.47-0.84,0.69-1.27c0.13-0.25,0.31-0.48,0.49-0.76c0.18,0.38,0.12,0.75,0.08,1.09
							c-0.12,0.9,0.1,1.56,1.02,1.87c0.29,0.1,0.37,0.34,0.38,0.65c0.05,1.06,0.06,2.12,0.06,3.18c0,0.25-0.01,0.49-0.09,0.72
							c-0.36,1-0.51,2.06-0.84,3.07c-0.04,0.14-0.12,0.28,0.01,0.48c0.34-0.62,0.66-1.21,0.98-1.8c0.04-0.08,0.03-0.2,0.14-0.21
							c0.15,0,0.14,0.13,0.16,0.23c0.13,0.65,0.25,1.31,0.44,1.95c0.07,0.23-0.05,0.4-0.19,0.57c-0.65,0.78-1.35,1.52-2.08,2.23
							c-0.22,0.22-0.34,0.4-0.07,0.68c0.23,0.23,0.19,0.5,0.01,0.79c-0.63,1-1.24,2.01-1.86,3.02c-0.27,0.44-0.23,0.5,0.28,0.62
							c0.03,0.01,0.06,0.02,0.09,0.02c1.28,0.28,1.28,0.28,1.17,1.59c-0.01,0.15-0.08,0.3,0.03,0.44c0.26,0.03,0.42-0.12,0.59-0.28
							c0.56-0.59,1.11-1.2,1.7-1.75c0.42-0.39,0.82-0.79,1.16-1.26c0.17-0.45,0.37-0.9,0.51-1.36c0.27-0.95,0.88-1.58,1.74-2.05
							c0.43-0.24,0.73-0.22,1.1,0.06c0.39,0.29,0.8,0.56,1.34,0.44c0.3-0.06,0.6-0.16,0.99-0.12c-0.31-0.37-0.71-0.42-1.02-0.6
							c-0.23-0.13-0.6-0.14-0.68-0.41c-0.22-0.75-0.53-1.49-0.17-2.3c0.46-1.07,1-2.11,1.5-3.16c0.13-0.28,0.24-0.2,0.38-0.01
							c0.31,0.41,0.61,0.83,0.96,1.2c0.33,0.35,0.5,0.74,0.55,1.21c0.06,0.58,0.16,1.16,0.25,1.74c0.18-0.05,0.18-0.18,0.21-0.26
							c0.27-0.84,0.53-1.68,0.79-2.52c0.07-0.22,0.04-0.45-0.05-0.67c-0.33-0.77-0.38-1.52,0.19-2.21c0.16-0.2,0.13-0.38,0.02-0.58
							c-0.31-0.57-0.61-1.15-0.91-1.73c-0.08-0.15-0.2-0.29-0.09-0.46c0.23-0.35,0.48-0.7,0.72-1.05c0.07-0.11,0.16-0.19,0.28-0.04
							c0.58,0.72,1.34,1.32,1.44,2.35c0.05,0.54,0.25,1.06,0.38,1.59c0.07-0.01,0.05-0.01,0.12-0.01c0.04-0.8,0.08-1.59,0.11-2.39
							c0.01-0.26-0.03-0.5-0.12-0.75c-0.35-1-0.67-2.01-1.01-3.01c-0.06-0.18-0.05-0.37,0.03-0.54c0.18-0.37,0.36-0.74,0.53-1.11
							c0.1-0.22,0.21-0.24,0.36-0.05c0.44,0.57,0.97,1.09,1.12,1.85c0.02,0.09,0.03,0.22,0.22,0.21c0.08-0.64,0.19-1.28-0.3-1.84
							c-0.12-0.14-0.2-0.3-0.28-0.46c-0.37-0.71-0.39-0.73,0.25-1.25c0.33-0.26,0.24-0.37-0.05-0.53c-1.83-1-3.7-1.87-5.83-2.04
							c-0.67-0.05-1.38-0.11-1.96-0.52c-1.5-1.07-2.85-2.31-4.14-3.62c-0.38-0.38-0.73-0.53-1.22-0.47
							c-0.09,0.06-0.29,0.03-0.29,0.03c-0.36,0.02-0.72,0.03-1.08,0.05l-0.47-0.26c-0.22-0.23-0.46-0.44-0.65-0.7
							c-0.48-0.65-1.08-1.08-1.88-1.25c-0.19-0.04-0.35-0.11-0.46,0.12c-0.58,1.2-1.17,2.39-1.73,3.6c-0.12,0.25-0.38,0.47-0.3,0.82
							c0.17-0.01,0.23-0.13,0.32-0.2c0.35-0.29,0.43-0.26,0.58,0.18c0.1,0.27,0.05,0.54-0.01,0.8c-0.15,0.61-0.31,1.22-0.46,1.82
							c0.13,0.05,0.18-0.01,0.23-0.06c0.54-0.69,1.08-1.38,1.62-2.07c0.15-0.19,0.27-0.4,0.49-0.53l0.02-0.08
							c0.01,0.01,0.03,0.02,0.04,0.03c-0.02,0.02-0.04,0.03-0.06,0.05c-0.02,0.1-0.02,0.22-0.07,0.31
							c-0.35,0.66-0.71,1.31-1.05,1.96c-0.35,0.67-0.87,1.31-0.3,2.12c0.14,0.2,0.02,0.33-0.22,0.36c-0.48,0.07-0.96,0.2-1.44,0.23
							c-0.47,0.03-0.84,0.22-1.18,0.52c-0.3,0.26-0.6,0.51-0.88,0.79c-0.57,0.58-1.2,1.05-1.96,1.38c-0.48,0.21-1,0.39-1.39,0.76
							c-0.65,0.61-1.25,1.28-1.89,1.91c-0.1,0.1-0.19,0.36-0.37,0.24c-0.21-0.15,0.05-0.28,0.11-0.41c0.04-0.08,0.11-0.15,0.13-0.24
							c0.22-1.08,0.84-1.89,1.71-2.53c0.14-0.1,0.25-0.27,0.43-0.16c0.41,0.25,0.57-0.02,0.74-0.31c0.17-0.29,0.35-0.57,0.53-0.85
							c0.61-0.98,1.04-2.11,2.09-2.76c0.07-0.05,0.16-0.11,0.11-0.22c-0.04-0.08-0.12-0.07-0.2-0.08c-0.32-0.04-0.63-0.08-0.94-0.13
							c-0.26-0.04-0.43-0.13-0.39-0.48c0.03-0.37,0.13-0.71,0.23-1.06c0.03-0.1,0.12-0.21,0.01-0.29c-0.08-0.06-0.16,0.03-0.24,0.07
							c-0.97,0.51-1.8,1.16-2.29,2.19c-0.18,0.37-0.44,0.69-0.66,1.03c0.05-0.82,0.17-1.59,0.3-2.36c0.11-0.65,0.12-0.69-0.52-0.73
							c-0.61-0.04-1.25-0.32-1.82,0.02c-0.88,0.53-1.81,0.19-2.72,0.32c0.41-0.43,0.95-0.65,1.4-0.99c0.49-0.36,1-0.63,1.64-0.51
							c0.32,0.06,0.43-0.13,0.54-0.4c0.3-0.71-0.13-1.33-0.2-1.99c-0.01-0.12-0.13-0.12-0.22-0.07c-0.63,0.42-1.58,0.55-1.5,1.63
							c0.02,0.28-0.15,0.23-0.33,0.13c-0.39-0.21-0.78-0.4-1.17-0.6c-0.82-0.42-0.73-0.19-0.56-1.16c0.11-0.59,0.09-1.04-0.46-1.39
							c-0.4-0.25-0.62-0.59-0.34-1.11c0.1-0.19,0.12-0.43,0.18-0.65c0.17-0.57,0.36-1.14,0.15-1.73c-0.11-0.3-0.07-0.52,0.22-0.69
							c0.14-0.08,0.29-0.17,0.41-0.28c0.35-0.31,0.54-0.13,0.75,0.18c0.62,0.97,1.31,1.9,1.87,2.91c0.4,0.73,1.02,0.84,1.75,0.96
							c-0.14-0.33-0.39-0.5-0.58-0.72c-0.42-0.49-0.99-0.86-1.23-1.5c-0.21-0.55-0.55-1.04-0.74-1.6c-0.13-0.39-0.35-0.77-0.7-1.04
							c-0.26-0.2-0.64-0.34-0.72-0.65c-0.09-0.34,0.35-0.54,0.45-0.86l0.25-0.36c0.49-0.1,0.92-0.71,1.48-0.13
							c0.07,0.07,0.17-0.02,0.25-0.04c0.39-0.12,0.58-0.45,0.8-0.76c0.11-0.16,0.24-0.38,0.48-0.09c0.44,0.53,0.45,0.52,0.9-0.03
							c0.1-0.12,0.22-0.22,0.3-0.34c-0.03,0.05-0.42,0.04-0.49,0.04c-0.17,0.02-0.34,0.04-0.51,0.06c-0.35,0.04-0.7,0.09-1.04,0.15
							c-0.67,0.11-1.32,0.3-1.99,0.4c-0.29,0.04-0.6,0.27-0.85,0.41c-0.31,0.17-0.61,0.35-0.9,0.53c-0.1,1.6-0.71,2.91-2.18,3.71
							 M439.32,720.59c-0.02,0.29-0.14,0.4-0.43,0.39c-0.23-0.01-0.45,0.03-0.68,0.03c-0.99,0.02-1.4,0.8-1.89,1.45
							c-0.2,0.27-0.38,0.37-0.72,0.28c-0.86-0.22-1.68-0.15-2.44,0.37c-0.04,0.03-0.08,0.04-0.12,0.05
							c-0.03,0.01-0.06-0.01-0.13-0.02c0.2-1.15,0.38-2.29,0.99-3.32c0.19-0.31,0.36-0.63,0.51-0.96c0.13-0.3,0.28-0.45,0.62-0.25
							c0.24,0.14,0.42,0.11,0.37-0.24c-0.18-1.27,0.28-2.32,1.13-3.22c0.5-0.53,0.88-1.15,1.23-1.78c0.05-0.1,0.04-0.28,0.21-0.25
							c0.14,0.03,0.11,0.19,0.13,0.31c0.09,0.88,0.2,1.74,0.43,2.6c0.06,0.21,0.14,0.4,0.24,0.58
							C439.45,717.86,439.42,719.22,439.32,720.59z M443.94,714.09c-0.33,1.18-0.48,2.41-0.93,3.56c-0.03,0.07-0.06,0.14-0.09,0.2
							c-0.06,0-0.12,0-0.17-0.01c0-0.6-0.05-1.2,0.01-1.79c0.07-0.6-0.03-1.17-0.31-1.67c-0.84-1.49-0.62-3.08-0.51-4.66
							c0.03-0.47,0.43-0.79,0.74-1.12c0.22-0.25,0.48-0.46,0.73-0.69c0.07-0.06,0.12-0.16,0.22-0.13c0.13,0.04,0.11,0.17,0.1,0.27
							c-0.03,0.51,0,1.04-0.12,1.54c-0.24,1.01,0,1.96,0.28,2.9C444.05,713.04,444.1,713.53,443.94,714.09z M455.81,711.51
							c-0.12-0.54-0.01-1.01,0.45-1.38c0.49-0.39,0.94-0.81,1.41-1.21c0.17-0.14,0.29-0.31,0.28-0.54c-0.05-0.9,0.32-1.64,0.91-2.29
							c0.33-0.37,0.59-0.76,0.49-1.3c-0.08-0.42,0.27-0.66,0.49-0.93c0.74-0.9,1.51-1.78,2.27-2.66c0.08-0.09,0.15-0.24,0.28-0.2
							c0.15,0.05,0.06,0.21,0.05,0.32c0,0.06-0.01,0.12-0.01,0.18c-0.03,0.51-0.01,0.52,0.49,0.37c0.15-0.04,0.3-0.15,0.51-0.08
							c-0.55,0.54-1.41,0.67-1.79,1.36c-0.34,0.61-0.41,1.32-0.69,2.07c0.34-0.21,0.51-0.43,0.69-0.63c0.47-0.54,0.9-1.11,1.44-1.57
							c0.1-0.08,0.21-0.25,0.34-0.12c0.12,0.11-0.06,0.21-0.12,0.3c-0.74,1.04-1.47,2.08-2.22,3.11c-0.21,0.29-0.13,0.61-0.13,0.92
							c0,0.28-0.01,0.54-0.26,0.74c-0.75,0.61-0.98,1.39-0.71,2.31c0.1,0.36-0.01,0.59-0.31,0.81c-0.91,0.66-1.82,1.33-2.59,2.14
							c-1.04,1.12-1.9,2.4-2.8,3.63c-0.28,0.38-0.56,0.75-0.84,1.13c-0.03,0.04-0.1,0.09-0.14,0.09c-0.09-0.01-0.1-0.1-0.09-0.18
							c0.1-0.75,0.15-1.51,0.33-2.25c0.08-0.32,0.4-0.6,0.64-0.87c0.31-0.36,0.58-0.71,0.64-1.21c0.07-0.56,0.46-0.98,0.8-1.41
							C455.8,711.96,455.87,711.78,455.81,711.51z M450.03,722.77c0.09,0.67-0.08,1.27-0.49,1.79c-0.46,0.58-0.54,1.27-0.58,1.97
							c-0.02,0.35-0.11,0.57-0.45,0.69c-0.31,0.11-0.59,0.29-0.92,0.36c-0.08-0.17,0.03-0.24,0.12-0.31c0.49-0.4,0.65-0.94,0.6-1.54
							c-0.06-0.73,0.08-1.39,0.5-1.98c0.38-0.54,0.3-1.11,0.21-1.7c-0.07-0.46-0.2-0.92-0.09-1.4c0.12-0.57,0.43-1.06,0.69-1.58
							c0.2-0.4,0.3-0.8,0.22-1.22c-0.06-0.29,0.03-0.5,0.25-0.68c0.3-0.26,0.59-0.53,0.89-0.78c0.23-0.19,0.36-0.38,0.04-0.61
							c-0.19-0.14-0.14-0.28,0-0.45c1.1-1.35,2.46-2.42,3.68-3.64c0.07-0.07,0.15-0.15,0.3-0.12c-0.55,1.3-1.35,2.42-2.13,3.55
							c-0.25,0.36-0.33,0.74-0.36,1.15c-0.04,0.59-0.08,1.18-0.1,1.77c-0.01,0.21-0.08,0.37-0.2,0.54
							c-0.66,0.94-1.32,1.89-1.97,2.83c-0.13,0.18-0.2,0.37-0.19,0.59C450.03,722.25,449.99,722.52,450.03,722.77z M487.54,694.67
							c0.91-1.04,1.92-1.97,2.92-2.92c0.22-0.21,0.44-0.41,0.67-0.62c0.2-0.19,0.31-0.13,0.36,0.13c0.09,0.54,0.19,1.07,0.29,1.61
							c0.06,0.32-0.02,0.58-0.27,0.82c-0.46,0.42-0.88,0.88-1.33,1.31c-0.19,0.18-0.24,0.34-0.12,0.6c0.4,0.88,0.76,1.78,1.17,2.66
							c0.19,0.4,0.16,0.67-0.17,0.99c-1.05,1.07-2.06,2.18-3.09,3.27c-0.14,0.15-0.19,0.34-0.26,0.53
							c-0.19,0.56-0.39,1.12-0.57,1.68c-0.1,0.32-0.29,0.55-0.57,0.74c-0.71,0.47-1.4,0.97-2.1,1.46c-0.03-0.03-0.07-0.06-0.1-0.08
							c0.3-0.38,0.51-0.82,0.79-1.21c0.31-0.43,0.48-0.88,0.53-1.41c0.12-1.13,0.34-2.23,1.12-3.14c0.33-0.39,0.59-0.85,0.86-1.28
							c0.19-0.3,0.33-0.6,0.27-0.98c-0.19-1.15-0.35-2.3-0.55-3.45C487.34,695.08,487.37,694.87,487.54,694.67z M474.19,703.8
							c0.29-0.61,0.88-1,1.36-1.45c0.37-0.35,0.65-0.73,0.65-1.24c0.01-0.93,0.59-1.53,1.21-2.1c0.19-0.17,0.29-0.46,0.59-0.5
							c0.03,0.25,0.06,0.5,0.08,0.75c0.03,0.31-0.05,0.63,0.11,0.97c0.41-2.04,1.54-3.69,2.83-5.28c-0.14,0.68-0.28,1.36-0.13,2.15
							c0.59-1.08,0.96-2.11,1.02-3.25c0.04-0.7,0.13-1.39,0.2-2.08c0.02-0.17,0.05-0.34,0.18-0.45c0.36-0.32,0.73-0.62,1.15-0.98
							c-0.02,0.44-0.21,0.75-0.37,1.03c-0.27,0.47-0.22,0.96-0.23,1.46c0,0.1,0.02,0.18,0.12,0.23c0.2,0.09,1.03-0.34,1.07-0.57
							c0.13-0.68,0.26-1.37,0.35-2.06c0.05-0.4,0.17-0.64,0.6-0.73c0.71-0.15,1.13-0.72,1.61-1.2c0.13-0.14,0.23-0.46,0.46-0.33
							c0.16,0.09-0.06,0.32-0.09,0.49c-0.1,0.55-0.23,1.1-0.35,1.65c-0.07,0.34,0.03,0.64,0.2,0.91c0.1,0.17,0.16,0.36,0.26,0.52
							c0.34,0.57,0.22,1.04-0.16,1.54c-0.44,0.57-0.72,1.2-0.71,1.95c0,0.34-0.27,0.62-0.49,0.85c-0.68,0.72-0.66,1.49-0.29,2.33
							c0.21,0.47,0.34,0.97,0.53,1.45c0.14,0.35,0.05,0.61-0.21,0.85c-0.54,0.48-1.08,0.97-1.63,1.44
							c-0.29,0.24-0.68,0.11-0.96,0.07c-0.74-0.1-1.16,0.34-1.63,0.73c-0.29,0.24-0.57,0.49-0.85,0.74
							c-0.14,0.13-0.3,0.13-0.47,0.13c-0.58,0-1.15,0.01-1.73-0.01c-0.47-0.01-0.86,0.13-1.17,0.49c-0.08,0.09-0.19,0.16-0.25,0.26
							c-0.48,0.77-1.12,1.17-2.06,1.22c-0.54,0.03-1.02,0.44-1.51,0.72c-0.14,0.08-0.27,0.2-0.53,0.17
							C473.36,705.71,473.75,704.74,474.19,703.8z M475.92,713.62c-0.46,0.47-1.02,0.69-1.63,0.85c-0.66,0.17-1.32,0.35-1.98,0.5
							c-0.83,0.18-1.36,0.83-2.01,1.28c-0.95,0.67-1.85,1.42-2.78,2.13c-0.08,0.06-0.15,0.15-0.37,0.15
							c0.44-0.65,0.92-1.2,1.22-1.83c0.57-1.2,1.74-1.48,2.79-1.94c0.87-0.39,1.8-0.67,2.63-1.13c0.6-0.33,1.09-0.86,1.63-1.3
							c0.84-0.69,1.59-1.5,2.57-2.01c0.26-0.14,0.52-0.35,0.96-0.35c-0.21,0.2-0.37,0.34-0.5,0.49c-0.09,0.09-0.25,0.2-0.12,0.34
							c0.1,0.11,0.25-0.01,0.32-0.08c0.5-0.55,1.18-0.57,1.84-0.66c0.07-0.01,0.15-0.03,0.21,0.1c-0.51,0.3-1.02,0.62-1.55,0.88
							c-0.77,0.38-1.41,0.94-2.04,1.51C476.7,712.9,476.29,713.24,475.92,713.62z M484,719.5c-1.18,0.67-2.14,1.65-3.26,2.39
							c-0.76,0.51-1.65,0.81-2.4,1.38c0.23-0.5,0.55-0.99,0.99-1.22c0.67-0.34,1.07-0.81,1.23-1.52c0.09-0.43,0.32-0.72,0.75-0.89
							c0.74-0.28,1.19-0.91,1.68-1.49c0.08-0.1,0.14-0.28,0.3-0.22c0.13,0.04,0.09,0.21,0.13,0.32c0.11,0.4,0.04,0.9,0.65,1.01
							C484.22,719.3,484.09,719.46,484,719.5z M485,713.84c-0.03,0.15-0.12,0.27-0.22,0.39c-0.83,1.12-1.86,1.97-3.19,2.43
							c-0.4,0.14-0.76,0.36-1.21,0.48c0.32-0.39,0.64-0.79,0.96-1.18c0.59-0.73,1.29-1.32,2.11-1.77c0.43-0.23,0.5-0.65,0.64-1.04
							c0.01-0.04-0.01-0.13-0.05-0.16c-0.07-0.05-0.14,0.02-0.2,0.06c-0.91,0.62-1.84,1.23-2.64,2c-0.07,0.07-0.14,0.2-0.25,0.17
							c-0.16-0.05-0.08-0.2-0.09-0.32c-0.01-0.23,0.01-0.46-0.02-0.68c-0.14-1.01,0.33-1.78,0.93-2.51c0.38-0.46,0.75-0.94,1.2-1.51
							c-0.58,0.18-0.78,0.69-1.23,0.82l-0.03,0.03c-0.01-0.01-0.01-0.01-0.02-0.02c0.01-0.01,0.02-0.02,0.03-0.03
							c0.35-0.9,0.74-1.79,1.33-2.56c0.31-0.41,0.88-0.49,1.39-0.58c0.11-0.02,0.15,0.08,0.2,0.15c0.49,0.82,1.09,1.56,1.5,2.44
							c0.13,0.27,0.14,0.5-0.03,0.71C485.5,711.97,485.19,712.87,485,713.84z M497.43,708.23c-0.5,0.46-0.98,0.95-1.49,1.4
							c-0.56,0.5-0.93,1.11-1.18,1.81c-0.14,0.4-0.39,0.65-0.79,0.82c-0.97,0.43-1.97,0.79-2.93,1.24
							c-0.47,0.22-0.79,0.54-1.01,0.98c-0.33,0.65-0.68,1.29-1.02,1.93c-0.06,0.1-0.13,0.2-0.22,0.34c-0.17-0.31-0.14-0.61-0.2-0.89
							c-0.02-0.12,0.01-0.25-0.02-0.36c-0.25-0.85,0.13-1.46,0.71-2.01c0.46-0.44,0.83-0.97,1.22-1.47
							c0.26-0.33,0.62-0.54,0.95-0.77c0.49-0.35,0.98-0.69,1.45-1.07c-0.47,0.01-0.82,0.34-1.24,0.47
							c-0.43,0.14-0.84,0.35-1.24,0.57c-0.49,0.27-0.76,0.77-1.14,1.16c-0.53,0.54-0.99,1.15-1.49,1.72c-0.54,0.61-1.31,0.89-2,1.31
							c0.2-0.34,0.36-0.71,0.6-1.02c0.76-0.99,1.21-2.13,1.58-3.29c0.2-0.64,0.61-1.07,1.08-1.48c0.98-0.84,1.96-1.68,2.96-2.49
							c0.74-0.6,1.25-1.4,1.82-2.14c0.82-1.07,1.61-2.17,2.41-3.25c0.13-0.18,0.28-0.35,0.42-0.52c0.12,0.58,0.19,1.11-0.05,1.64
							c-0.34,0.75-0.59,1.53-0.9,2.28c-0.16,0.39-0.09,0.7,0.21,0.99c0.51,0.5,0.98,1.04,1.52,1.5
							C497.75,707.89,497.66,708.02,497.43,708.23z"/>
						<path className="st8" d="M421.88,749.77c-0.03,0-0.05,0-0.07-0.01c-0.07-0.03-0.11-0.1-0.11-0.18l0-0.22l0.1,0.05
							c0.32-0.45,1.82-3,2.83-4.73c1.44-2.46,1.45-2.47,1.5-2.5c1.44-0.92,5.61-2.47,7.32-2.97c0.92-0.27,1.5-0.76,1.84-1.55
							c0.16-0.38,0.25-0.67,0.32-0.9c0.16-0.53,0.25-0.84,0.98-1.49c0.04-0.03,0.11-0.09,0.19-0.16c0.26-0.22,0.81-0.68,1.17-1.05
							c-0.23,0.11-0.45,0.2-0.66,0.3c-0.66,0.29-1.3,0.69-1.49,1.58c-0.13,0.62-0.51,1.15-1.16,1.64c-0.01,0.01-0.03,0.03-0.05,0.05
							c-0.06,0.07-0.21,0.22-0.41,0.1c-0.18-0.11-0.11-0.3-0.09-0.38c0.01-0.02,0.01-0.04,0.02-0.06c0.11-0.55,0.1-1.13,0.1-1.68
							c0-0.08,0-0.16,0-0.24c0-0.06,0-0.22-0.02-0.28c-0.07,0.01-0.24,0.09-0.42,0.17c-0.1,0.04-0.21,0.1-0.33,0.15
							c-0.25,0.11-0.53,0.19-0.87,0.27c-0.36,0.08-0.58,0.33-0.82,0.7c-0.06,0.09-0.1,0.19-0.15,0.29c-0.05,0.1-0.1,0.21-0.16,0.31
							l-0.01,0.02c-0.07,0.11-0.15,0.25-0.31,0.28c-0.1,0.02-0.21-0.01-0.33-0.09c-0.05-0.04-0.07-0.05-0.19,0.11l-0.02,0.03
							c-0.29,0.38-1.69,1.15-3.32,2.03c-1.77,0.96-4.2,2.28-4.37,2.79c-0.04,0.12-0.04,0.13-1.25,0.11
							c-0.66-0.01-1.6-0.03-2.87-0.07c-2.35-0.07-5.4-0.17-8.35-0.26c-4.32-0.14-8.78-0.29-9.17-0.26l-0.18,0.11l0-0.24
							c-0.01-0.06-0.21-0.37-3.42-0.67c-2.09-0.19-4.9-0.34-7.62-0.48c-2.75-0.14-5.59-0.29-7.68-0.48c-3-0.28-3.61-0.58-3.62-0.94
							l0-0.12l0.12-0.02c0.23-0.03,8.59-0.6,19.17-1.32c1.5-0.1,3.04-0.21,4.59-0.31c0.32-0.31,0.71-0.68,1.15-1.11
							c1.4-1.34,3.34-3.21,5.52-5.3c7.09-6.81,17.76-17.07,18.28-17.72l0.02-0.29l0.22,0.24c0.35,0.38,0.89,2.19,1.41,3.94
							c0.39,1.3,0.79,2.65,1,2.96l0.03,0.04c0.21,0.32,0.44,0.66,0.46,1.1c0.02,0.41,0.08,0.82,0.14,1.22l0.01,0.05
							c0.06,0.44,0.13,0.88,0.19,1.31c0.02,0.11,0.03,0.21,0.05,0.32c0.04-0.12,0.08-0.23,0.11-0.35c0.15-0.48,0.31-0.97,0.51-1.45
							c0.46-1.1,0.54-1.99,0.28-2.87c-0.04-0.14-0.06-0.29-0.07-0.43c-0.01-0.1-0.02-0.2-0.04-0.3l-0.02-0.08
							c-0.14-0.67-0.26-1.3-0.73-1.81c-0.17-0.19-0.34-0.38-0.51-0.56c-0.35-0.39-0.71-0.79-1.1-1.16
							c-0.01-0.01-0.02-0.02-0.03-0.02c-0.08-0.07-0.23-0.2-0.14-0.46l0.06-0.17l0.15,0.1c0.24,0.16,0.48,0.32,0.72,0.48
							c0.56,0.38,1.09,0.73,1.64,1.07c0.74,0.45,1.19,1.02,1.38,1.74c0.05,0.19,0.1,0.37,0.15,0.56c0.03,0.12,0.07,0.24,0.1,0.36
							c0.01,0.05,0.02,0.07,0.03,0.08c0.01,0,0.02,0,0.04-0.01c0.3-0.12,0.77-0.76,0.74-1.11c-0.05-0.58,0.1-1.1,0.47-1.58
							c0.11-0.15,0.22-0.3,0.33-0.45c0.11-0.15,0.22-0.32,0.34-0.47l0.02-0.02c0.08-0.1,0.12-0.16,0.11-0.24l-0.01-0.11
							c-0.1-0.83-0.19-1.61-0.63-2.31c-0.34-0.55-0.67-1.11-0.99-1.65c-0.14-0.24-0.28-0.48-0.42-0.72l-0.14-0.24l0.28,0.03
							c0.34,0.04,0.58,0.24,0.81,0.43c0.12,0.1,0.23,0.19,0.35,0.26c0.71,0.4,1.15,0.93,1.34,1.61c-0.01-0.44-0.17-0.85-0.35-1.28
							c-0.13-0.31-0.26-0.64-0.32-0.97l-0.08-0.43l0.31,0.3c0.09,0.09,0.19,0.18,0.28,0.27c0.62,0.58,1.25,1.18,1.37,2.15l0,0.04
							c0,0.03,0.01,0.11,0.03,0.13l0.01,0c0.01-0.01,0.03-0.06,0.04-0.09c0.01-0.03,0.02-0.06,0.04-0.09
							c0.08-0.15,0.15-0.3,0.22-0.45c0.14-0.3,0.29-0.6,0.46-0.89c0.07-0.12,0.13-0.24,0.2-0.36c0.36-0.65,0.73-1.33,1.41-1.77
							c0.02-0.01,0.04-0.03,0.06-0.05c0.08-0.07,0.2-0.17,0.37-0.11c0.22,0.08,0.21,0.28,0.21,0.39c0,0.03,0,0.06,0,0.08
							c0.07,0.68,0.28,1.35,0.66,2.14c0.04-0.27,0.06-0.53,0.09-0.79c0.06-0.62,0.2-1.41,0.85-2.01c0.29-0.27,0.58-0.55,0.85-0.82
							c0.18-0.18,0.37-0.36,0.55-0.54c0.14-0.13,0.22-0.24,0.2-0.4c-0.09-0.87,0.21-1.64,0.51-2.38c0.07-0.17,0.14-0.34,0.2-0.52
							c0.05-0.15,0.13-0.23,0.23-0.27c0.13-0.04,0.26,0.03,0.35,0.09c0.24,0.16,0.48,0.32,0.71,0.47c0.1,0.07,0.21,0.14,0.31,0.2
							l0.03,0.02c0.66,0.43,0.73,0.52,0.48,1.32c-0.29,0.93-0.13,1.81,0.5,2.77c0.22,0.33,0.24,0.61,0.06,0.93
							c-0.36,0.66-0.33,1.36-0.3,2.1c0.01,0.17,0.01,0.35,0.02,0.53c0.01-0.08,0.03-0.15,0.04-0.22c0.08-0.46,0.16-0.94,0.44-1.35
							c0.42-0.6,0.62-1.3,0.62-2.2c0-0.4,0.13-0.71,0.43-1.02c0.53-0.54,1.02-1.1,1.19-1.9c0.27-1.35,0.81-2.24,1.71-2.81
							c0.17-0.11,0.33-0.24,0.5-0.37c0.13-0.1,0.26-0.21,0.4-0.31c0.69-0.48,1.07-0.96,1.23-1.56l0.01-0.03l0.02-0.02
							c0.11-0.14,0.27-0.15,0.38-0.16c0.02,0,0.04,0,0.06,0l0.13-0.01l0.02,0.13c0.01,0.1,0.02,0.2,0.03,0.3
							c0.02,0.22,0.04,0.43,0.1,0.62c0.14,0.44-0.12,0.64-0.33,0.74c-0.09,0.05-0.18,0.09-0.27,0.14c-0.24,0.13-0.49,0.26-0.75,0.37
							c-0.85,0.36-1.33,1.1-1.34,2.03c-0.01,0.8,0.11,1.62,0.23,2.41c0.05,0.34,0.1,0.7,0.14,1.04c0.01,0.07,0.02,0.11,0.05,0.13
							c0.02,0.02,0.06,0.02,0.1,0.01c0.02,0,0.04-0.01,0.06-0.01c0.09-0.02,0.32-0.08,0.4,0.17c0.05,0.16-0.06,0.28-0.13,0.36
							c-0.02,0.02-0.04,0.04-0.05,0.07c-0.1,0.15-0.21,0.3-0.31,0.45c-0.27,0.38-0.54,0.76-0.77,1.16
							c-0.41,0.71-0.48,1.26-0.23,1.79c0.32-0.43,0.59-0.9,0.85-1.35c0.17-0.3,0.35-0.61,0.54-0.91c0.24-0.37,0.41-0.76,0.52-1.2
							l0.02-0.1c0.13-0.53,0.27-1.07,0.64-1.54c0.81-1.01,1.67-1.99,2.51-2.95c0.29-0.33,0.58-0.66,0.87-0.99
							c0.65-0.75,1.43-1.33,2.36-1.75l0.37-0.17c0.86-0.39,1.74-0.8,2.65-1.12l0.03-0.01l0.03,0.01c0.37,0.06,0.65-0.1,0.96-0.35
							c0.29-0.24,0.59-0.49,0.88-0.73c0.91-0.76,1.86-1.55,2.84-2.25c0.48-0.34,0.97-0.58,1.47-0.72l0.19-0.05l-0.01,0.19
							c0,0.06-0.01,0.12-0.01,0.18c-0.01,0.14-0.01,0.28-0.04,0.42c-0.03,0.15-0.04,0.31-0.04,0.48c-0.02,0.39-0.03,0.79-0.36,1.15
							c-0.19,0.21-0.36,0.44-0.53,0.66c-0.06,0.07-0.11,0.15-0.17,0.22c-0.03,0.04-0.06,0.07-0.08,0.11
							c-0.2,0.25-0.38,0.49-0.43,0.81c-0.19,1.32-0.45,2.57-1.61,3.4c-0.01,0.01-0.02,0.03-0.03,0.05
							c-0.02,0.02-0.03,0.05-0.06,0.08c-0.47,0.53-0.65,1.17-0.83,1.84c-0.07,0.24-0.14,0.49-0.22,0.73
							c-0.05,0.15-0.09,0.31-0.13,0.47c-0.12,0.46-0.24,0.94-0.67,1.32c-0.26,0.23-0.45,0.51-0.58,0.86
							c-0.06,0.16-0.16,0.29-0.25,0.42c-0.09,0.12-0.18,0.24-0.24,0.38c0.68-0.4,1.26-0.91,1.82-1.4c0.45-0.4,0.92-0.81,1.42-1.15
							l0.53-0.36l-0.34,0.54c-0.31,0.49-0.73,0.98-1.14,1.44c-0.35,0.4-0.69,0.78-0.95,1.17c-0.05,0.08-0.1,0.16-0.16,0.25
							c-0.15,0.25-0.3,0.51-0.58,0.68c-0.6,0.35-1.09,0.79-1.57,1.22c-0.47,0.41-0.95,0.84-1.53,1.2c-1.7,1.05-2.93,2.1-3.99,3.41
							c-0.12,0.14-0.23,0.29-0.35,0.43c-0.71,0.88-1.44,1.79-2.36,2.53c-0.1,0.08-0.2,0.15-0.31,0.22
							c-0.28,0.19-0.54,0.37-0.67,0.67c-0.2,0.48-0.41,0.96-0.62,1.44c-0.19,0.43-0.38,0.87-0.56,1.3c0.21-0.24,0.41-0.48,0.61-0.72
							c0.51-0.6,1.03-1.22,1.6-1.77c1.13-1.1,2.34-2.15,3.51-3.16l0.21-0.18c0.16-0.14,0.32-0.29,0.48-0.44
							c0.46-0.43,0.93-0.88,1.54-1.16c0.89-0.41,1.82-0.78,2.72-1.13l0.39-0.15c0.09-0.03,0.18-0.06,0.27-0.09
							c0.26-0.09,0.5-0.17,0.66-0.34c0.62-0.66,1.25-1.35,1.85-2.01c0.55-0.6,1.12-1.23,1.69-1.84c0.18-0.19,0.36-0.4,0.54-0.59
							c0.49-0.55,1.01-1.12,1.61-1.6c0.86-0.68,1.68-1.48,1.8-2.78c0.05-0.47,0.29-0.85,0.7-1.09c0.41-0.24,0.8-0.53,1.18-0.81
							c0.08-0.06,0.15-0.11,0.23-0.17c0.05-0.04,0.1-0.07,0.15-0.11c0.39-0.28,0.76-0.54,0.84-1.05c0.03-0.19,0.08-0.37,0.13-0.54
							c0.05-0.16,0.09-0.32,0.12-0.48c0.28-1.62,0.98-2.83,2.13-3.69c0.24-0.18,0.39-0.42,0.47-0.75c0.11-0.44,0.24-0.89,0.36-1.32
							c0.08-0.29,0.17-0.58,0.24-0.88c0.07-0.25,0.19-0.44,0.37-0.6c0.26-0.22,0.52-0.44,0.78-0.66c0.69-0.58,1.4-1.17,2.07-1.8
							c0.16-0.15,0.33-0.28,0.49-0.42c0.32-0.25,0.62-0.49,0.84-0.81l0.03-0.04l0.05-0.01c0.16-0.04,0.32-0.08,0.48-0.12
							c0.36-0.09,0.73-0.18,1.09-0.29c0.43-0.13,0.73-0.48,0.97-0.8c0.6-0.79,1.45-0.99,2.04-1.07c0.35-0.05,0.51-0.19,0.64-0.56
							c0.71-1.95,1.66-3.49,2.92-4.72c0.36-0.35,0.8-0.66,1.34-0.92l0.14-0.07l0.05,0.14c0.13,0.36-0.01,0.6-0.14,0.73
							c-0.34,0.33-0.55,0.75-0.78,1.18c-0.03,0.06-0.06,0.12-0.09,0.18c-0.05,0.1-0.09,0.21-0.13,0.34
							c-0.1,0.29-0.21,0.62-0.53,0.76c-0.43,0.19-0.55,0.56-0.67,0.94c-0.05,0.15-0.1,0.31-0.17,0.46c0.11-0.01,0.22-0.03,0.25-0.08
							c0.03-0.04,0.03-0.12,0.01-0.24c-0.02-0.09,0.04-0.15,0.07-0.18l0.01-0.01l0.16-0.19c0.67-0.77,1.35-1.56,2.34-2.02l0.11,0.25
							c-0.93,0.43-1.6,1.2-2.25,1.95l-0.17,0.19c0,0-0.01,0.01-0.01,0.01c0.03,0.17,0.01,0.3-0.06,0.4
							c-0.11,0.16-0.31,0.18-0.49,0.2c-0.08,0.01-0.15,0.02-0.21,0.03l-0.4,0.11l0.26-0.33c0.14-0.18,0.21-0.41,0.29-0.64
							c0.13-0.41,0.28-0.86,0.82-1.11c0.21-0.1,0.3-0.34,0.38-0.6c0.04-0.13,0.09-0.26,0.15-0.37c0.03-0.06,0.06-0.12,0.09-0.18
							c0.22-0.44,0.45-0.89,0.83-1.26c0.09-0.08,0.12-0.17,0.11-0.29c-0.44,0.23-0.81,0.49-1.11,0.79c-1.23,1.2-2.16,2.71-2.85,4.62
							c-0.17,0.46-0.41,0.67-0.86,0.73c-0.87,0.12-1.45,0.42-1.86,0.97c-0.29,0.38-0.63,0.75-1.11,0.9c-0.37,0.11-0.74,0.2-1.1,0.29
							c-0.14,0.04-0.29,0.07-0.43,0.11c-0.25,0.33-0.56,0.58-0.86,0.82c-0.16,0.13-0.33,0.26-0.48,0.4
							c-0.67,0.63-1.39,1.23-2.08,1.81c-0.26,0.22-0.52,0.44-0.78,0.66c-0.14,0.12-0.23,0.27-0.28,0.46
							c-0.08,0.29-0.16,0.59-0.25,0.88c-0.12,0.43-0.25,0.87-0.36,1.31c-0.1,0.39-0.28,0.68-0.57,0.9
							c-1.09,0.82-1.75,1.97-2.03,3.52c-0.03,0.17-0.08,0.34-0.12,0.5c-0.05,0.17-0.1,0.34-0.12,0.51c-0.1,0.62-0.53,0.93-0.95,1.23
							c-0.05,0.04-0.1,0.07-0.15,0.11c-0.07,0.05-0.15,0.11-0.22,0.16c-0.39,0.28-0.78,0.58-1.2,0.83c-0.34,0.2-0.53,0.5-0.57,0.88
							c-0.13,1.4-1.04,2.28-1.91,2.97c-0.58,0.46-1.09,1.02-1.57,1.57c-0.18,0.2-0.36,0.4-0.54,0.59c-0.57,0.61-1.14,1.23-1.68,1.83
							c-0.6,0.66-1.23,1.35-1.85,2.01c-0.21,0.22-0.5,0.32-0.77,0.41c-0.09,0.03-0.17,0.06-0.25,0.09l-0.39,0.15
							c-0.9,0.35-1.82,0.71-2.71,1.12c-0.57,0.26-1.03,0.69-1.47,1.11c-0.16,0.15-0.32,0.3-0.48,0.44l-0.21,0.18
							c-1.17,1.01-2.37,2.06-3.5,3.15c-0.56,0.54-1.08,1.16-1.58,1.75c-0.21,0.25-0.43,0.51-0.64,0.75
							c-0.02,0.02-0.04,0.05-0.06,0.08c-0.06,0.09-0.14,0.19-0.29,0.25l-0.08,0.03l-0.06-0.06c-0.16-0.17-0.06-0.34-0.02-0.42
							c0.01-0.02,0.02-0.03,0.02-0.04c0.21-0.48,0.42-0.97,0.62-1.45c0.21-0.48,0.42-0.96,0.62-1.43c0.16-0.38,0.47-0.59,0.77-0.79
							c0.1-0.07,0.2-0.13,0.29-0.21c0.9-0.71,1.62-1.61,2.32-2.48c0.12-0.14,0.23-0.29,0.35-0.43c1.09-1.34,2.34-2.41,4.06-3.47
							c0.56-0.35,1.04-0.77,1.5-1.18c0.49-0.43,1-0.88,1.61-1.25c0.21-0.13,0.34-0.35,0.48-0.58c0.05-0.09,0.11-0.18,0.17-0.27
							c0.27-0.4,0.63-0.8,0.97-1.19c0.19-0.22,0.39-0.44,0.57-0.67c-0.22,0.18-0.44,0.38-0.66,0.57c-0.65,0.57-1.32,1.16-2.15,1.6
							l-0.23,0.12l0.03-0.26c0.05-0.37,0.22-0.6,0.38-0.81c0.09-0.12,0.17-0.23,0.22-0.35c0.14-0.38,0.36-0.71,0.65-0.97
							c0.37-0.32,0.47-0.74,0.59-1.18c0.04-0.16,0.08-0.33,0.14-0.49c0.08-0.23,0.15-0.48,0.22-0.72c0.19-0.68,0.39-1.38,0.89-1.95
							c0.01-0.01,0.02-0.03,0.03-0.04c0.02-0.04,0.05-0.09,0.1-0.12c1.07-0.76,1.31-1.91,1.5-3.21c0.06-0.39,0.28-0.67,0.49-0.94
							c0.03-0.03,0.05-0.07,0.08-0.1c0.06-0.07,0.11-0.14,0.17-0.22c0.17-0.23,0.35-0.46,0.55-0.68c0.26-0.28,0.27-0.61,0.29-0.97
							c0.01-0.17,0.02-0.35,0.05-0.52c0.02-0.12,0.03-0.24,0.03-0.37c-0.4,0.13-0.8,0.34-1.2,0.62c-0.98,0.7-1.92,1.48-2.83,2.24
							c-0.29,0.25-0.59,0.49-0.88,0.73c-0.31,0.25-0.65,0.48-1.14,0.42c-0.89,0.31-1.75,0.71-2.59,1.1l-0.37,0.17
							c-0.9,0.41-1.64,0.96-2.27,1.68c-0.29,0.33-0.58,0.66-0.87,0.99c-0.83,0.95-1.7,1.93-2.5,2.94c-0.34,0.42-0.46,0.91-0.59,1.44
							l-0.02,0.1c-0.12,0.47-0.3,0.89-0.55,1.28c-0.19,0.29-0.36,0.6-0.54,0.89c-0.3,0.52-0.61,1.06-1,1.55l-0.12,0.15l-0.1-0.17
							c-0.54-0.88-0.22-1.67,0.13-2.25c0.24-0.41,0.52-0.8,0.79-1.18c0.1-0.15,0.21-0.29,0.31-0.44c0.02-0.03,0.05-0.06,0.07-0.09
							c0.02-0.03,0.07-0.08,0.07-0.1c-0.02,0-0.05,0.01-0.07,0.02c-0.03,0.01-0.06,0.02-0.09,0.02c-0.12,0.02-0.22-0.01-0.3-0.07
							c-0.08-0.07-0.13-0.17-0.15-0.31c-0.04-0.34-0.09-0.7-0.14-1.04c-0.12-0.8-0.24-1.63-0.23-2.45c0.01-1.04,0.56-1.87,1.51-2.28
							c0.25-0.11,0.49-0.24,0.73-0.36c0.09-0.05,0.18-0.1,0.28-0.14c0.21-0.11,0.26-0.21,0.19-0.42c-0.07-0.22-0.09-0.45-0.11-0.68
							c-0.01-0.05-0.01-0.11-0.02-0.16c-0.05,0.01-0.08,0.01-0.1,0.03c-0.25,0.85-0.88,1.36-1.33,1.68c-0.13,0.09-0.26,0.2-0.39,0.3
							c-0.17,0.13-0.34,0.27-0.52,0.39c-0.83,0.52-1.33,1.36-1.59,2.63c-0.18,0.87-0.7,1.46-1.26,2.04
							c-0.25,0.25-0.35,0.5-0.35,0.83c0,0.96-0.22,1.7-0.67,2.36c-0.25,0.36-0.32,0.78-0.4,1.24c-0.04,0.21-0.07,0.42-0.13,0.63
							c0,0.02-0.01,0.03-0.01,0.05c-0.01,0.1-0.04,0.28-0.3,0.38l-0.22,0.09l0.03-0.24c0.06-0.42,0.04-0.85,0.02-1.27
							c-0.03-0.74-0.06-1.51,0.34-2.24c0.13-0.23,0.11-0.41-0.05-0.65c-0.68-1.04-0.85-1.99-0.53-3c0.2-0.63,0.2-0.63-0.37-1.01
							l-0.03-0.02c-0.1-0.07-0.21-0.14-0.31-0.2c-0.24-0.15-0.48-0.31-0.72-0.47c-0.08-0.06-0.11-0.05-0.11-0.05
							c0,0-0.02,0.02-0.06,0.1c-0.06,0.18-0.13,0.35-0.2,0.52c-0.3,0.74-0.57,1.45-0.49,2.25c0.03,0.28-0.13,0.48-0.28,0.63
							c-0.19,0.18-0.37,0.36-0.55,0.53c-0.28,0.27-0.57,0.55-0.86,0.82c-0.46,0.42-0.69,0.97-0.76,1.83
							c-0.04,0.4-0.07,0.81-0.16,1.22l-0.08,0.36l-0.17-0.33c-0.52-0.98-0.79-1.78-0.87-2.6c0-0.04,0-0.08,0-0.12
							c0.01-0.11,0-0.11-0.02-0.12c-0.02-0.01-0.06,0.02-0.1,0.06c-0.03,0.02-0.06,0.05-0.09,0.07c-0.63,0.4-0.96,1.02-1.32,1.67
							c-0.07,0.12-0.13,0.25-0.2,0.37c-0.16,0.28-0.31,0.58-0.45,0.88c-0.07,0.15-0.15,0.31-0.22,0.46
							c-0.01,0.02-0.02,0.04-0.03,0.06c-0.04,0.1-0.11,0.28-0.32,0.27c-0.25-0.02-0.28-0.27-0.29-0.38l0-0.03
							c-0.1-0.83-0.63-1.36-1.21-1.91c0.05,0.15,0.11,0.3,0.18,0.46c0.22,0.53,0.44,1.09,0.34,1.73l-0.02,0.14l-0.14-0.02
							c-0.27-0.04-0.32-0.23-0.34-0.32l-0.01-0.02c-0.17-0.63-0.57-1.11-1.22-1.49c-0.14-0.08-0.27-0.18-0.39-0.28
							c-0.11-0.09-0.22-0.19-0.34-0.25c0.08,0.13,0.16,0.27,0.24,0.4c0.32,0.54,0.65,1.1,0.99,1.65c0.47,0.76,0.57,1.61,0.67,2.43
							l0.01,0.11c0.02,0.2-0.08,0.33-0.16,0.44l-0.02,0.02c-0.11,0.15-0.23,0.31-0.33,0.46c-0.11,0.15-0.22,0.31-0.33,0.46
							c-0.32,0.42-0.46,0.88-0.41,1.39c0.04,0.47-0.51,1.23-0.92,1.38c-0.1,0.04-0.19,0.04-0.26,0c-0.11-0.05-0.14-0.16-0.17-0.26
							c-0.03-0.12-0.07-0.24-0.1-0.36c-0.05-0.19-0.1-0.37-0.15-0.56c-0.17-0.65-0.57-1.15-1.25-1.57
							c-0.56-0.34-1.11-0.71-1.65-1.08c-0.05-0.04-0.11-0.07-0.16-0.11c0.3,0.3,0.58,0.61,0.85,0.92c0.16,0.18,0.33,0.38,0.51,0.56
							c0.52,0.56,0.67,1.26,0.8,1.94l0.02,0.08c0.02,0.11,0.03,0.22,0.04,0.32c0.01,0.14,0.03,0.26,0.06,0.38
							c0.27,0.93,0.18,1.9-0.29,3.05c-0.19,0.46-0.35,0.95-0.5,1.43c-0.07,0.22-0.14,0.43-0.21,0.65l-0.03,0.1l-0.33-0.03
							l-0.02-0.11c-0.03-0.21-0.06-0.42-0.09-0.63c-0.06-0.43-0.13-0.87-0.19-1.31l-0.01-0.05c-0.06-0.4-0.12-0.82-0.14-1.24
							c-0.02-0.36-0.21-0.65-0.41-0.96l-0.03-0.04c-0.23-0.35-0.6-1.59-1.03-3.04c-0.42-1.42-0.94-3.15-1.26-3.7
							c-0.37,0.41-1.65,1.69-6.18,6.07c-3.62,3.5-8.29,7.98-12.04,11.59c-2.63,2.52-5.02,4.83-6.43,6.19
							c0.13-0.01,0.27-0.02,0.4-0.03c3.59-2.06,8.2-4.47,8.96-4.01c0.1,0.06,0.14,0.16,0.14,0.27c-0.02,0.24,0.09,0.47,0.32,0.69
							c0.66,0.63,2.07,0.92,2.59,0.86c0.98-0.11,2.11-0.03,2.94,0.04c0.51,0.04,1.08,0.08,1.21,0.03c0.17-0.45,1.64-0.38,4.86-0.12
							c1.7,0.14,4.04,0.33,4.3,0.08c0.11-0.11,0.48-1.06,0.78-1.83c0.61-1.58,0.93-2.33,1.12-2.45c0.24-0.15,0.25-0.26,0.15-0.5
							c-0.36-0.92-0.34-0.99,0.53-1.48c0.04-0.02,0.08-0.05,0.11-0.07c0.25-0.16,0.46-0.29,0.47-0.36c0.01-0.07-0.14-0.25-0.32-0.47
							c-0.11-0.13-0.22-0.31-0.14-0.54c0.3-0.89,0.7-2.11,2.1-2.4c0.14-0.03,0.26-0.09,0.23-0.36c-0.07-0.62,0.16-0.96,0.36-1.14
							c0.3-0.26,0.74-0.34,1.21-0.22c0.88,0.23,1.44,0.02,1.95-0.71c0.05-0.08,0.12-0.14,0.19-0.2c0.05-0.04,0.1-0.09,0.13-0.13
							c0.45-0.57,1.04-0.73,1.7-0.46c0.51,0.21,0.93,0.13,1.33-0.26c0.19-0.18,0.39-0.27,0.59-0.26c0.2,0.01,0.38,0.11,0.55,0.31
							c0.58,0.67,1.29,0.97,2.16,0.93c0.49-0.02,0.91,0.29,1.21,0.91c0.11,0.23,0.31,0.4,0.49,0.55c0.05,0.04,0.08,0.09,0.11,0.14
							c0.01,0.02,0.04,0.06,0.05,0.07c0-0.01,0-0.04,0-0.05c0-0.04,0-0.08,0.01-0.13c0.17-0.81,0.11-1.69-0.21-2.76
							c-0.25-0.85-0.04-1.58,0.65-2.23c0.21-0.19,0.28-0.31,0.16-0.55c-0.08-0.16-0.1-0.33-0.13-0.5c-0.01-0.1-0.03-0.19-0.05-0.28
							c-0.17-0.63-0.02-1.13,0.46-1.52c0.36-0.3,0.7-0.64,0.84-1.11c0.09-0.31,0.31-0.56,0.61-0.68c0.53-0.21,0.72-0.6,0.61-1.25
							l-0.01-0.03c-0.02-0.1-0.05-0.25,0.11-0.45l0.15-0.18l0.09,0.21c0.06,0.15,0.12,0.31,0.18,0.46c0.14,0.35,0.28,0.68,0.4,1.01
							c0.08,0.22,0.16,0.45,0.23,0.67c0.03,0.11,0.07,0.21,0.11,0.32c0.14,0.4,0.22,0.87-0.15,1.33c-0.09,0.11-0.16,0.23-0.23,0.37
							c-0.07,0.13-0.15,0.27-0.25,0.4c-0.63,0.79-0.88,1.62-0.77,2.54c0.04,0.37-0.1,0.74-0.38,0.98c-0.17,0.15-0.34,0.31-0.5,0.47
							c-0.1,0.1-0.21,0.21-0.32,0.31c-0.18,0.17-0.2,0.29-0.08,0.46c0.05,0.07,0.09,0.15,0.12,0.22c0.04,0.09,0.09,0.17,0.14,0.24
							c0.74,0.84,0.66,1.82,0.52,2.58c-0.06,0.33-0.1,0.61-0.12,0.87c-0.04,0.64-0.18,1.29-0.92,1.75
							c-0.58,0.36-1.11,0.81-1.63,1.24c-0.54,0.45-1.09,0.91-1.7,1.29c-0.34,0.21-0.69,0.43-1.02,0.64
							c-0.98,0.62-1.99,1.26-3.05,1.79c-0.47,0.24-0.98,0.41-1.53,0.5c-1.09,0.19-2.12,0.63-3.11,1.05l-0.3,0.13
							c-1.47,0.62-3.06,0.99-4.6,1.35l-0.09,0.02c-0.27,0.06-11.33,0.82-26.54,1.86c-0.6,0.34-1.21,0.7-1.82,1.07l-0.1,0.06
							l-0.08-0.1c-0.08-0.1-0.03-0.15,0.16-0.34c0.1-0.1,0.25-0.25,0.44-0.43c0.06-0.06,0.12-0.12,0.19-0.18
							c-1.39,0.09-2.81,0.19-4.26,0.29c-9.27,0.63-17.38,1.18-18.9,1.3c0.26,0.13,1.04,0.35,3.28,0.56c2.09,0.19,4.9,0.34,7.62,0.48
							c2.75,0.14,5.59,0.29,7.68,0.48c2.6,0.24,3.41,0.5,3.58,0.8c0.49-0.02,2.3,0.03,9.13,0.26c5.15,0.17,11.53,0.38,12.24,0.33
							c0.31-0.58,1.99-1.52,4.46-2.87c1.46-0.8,2.98-1.62,3.23-1.95l0.02-0.03c0.09-0.12,0.28-0.38,0.57-0.18
							c0.05,0.04,0.1,0.05,0.12,0.05c0.04-0.01,0.09-0.09,0.13-0.16l0.01-0.02c0.05-0.09,0.1-0.18,0.15-0.28
							c0.05-0.11,0.1-0.22,0.17-0.32c0.21-0.34,0.49-0.72,0.99-0.83c0.33-0.07,0.59-0.15,0.82-0.25c0.13-0.06,0.24-0.11,0.33-0.15
							c0.38-0.17,0.55-0.25,0.69-0.16c0.13,0.08,0.14,0.26,0.14,0.52c0,0.08,0,0.16,0,0.24c0,0.57,0.01,1.16-0.1,1.73
							c-0.01,0.03-0.02,0.06-0.03,0.09c-0.01,0.02-0.01,0.04-0.02,0.05c0.02-0.01,0.04-0.04,0.05-0.05
							c0.02-0.03,0.05-0.06,0.09-0.08c0.6-0.45,0.94-0.93,1.06-1.48c0.22-1.02,0.95-1.47,1.65-1.78c0.25-0.11,0.49-0.22,0.76-0.35
							c0.13-0.06,0.27-0.12,0.42-0.19l0.33-0.15l-0.15,0.33c-0.14,0.32-0.85,0.94-1.58,1.57c-0.08,0.07-0.15,0.13-0.19,0.16
							c-0.67,0.59-0.75,0.85-0.9,1.36c-0.07,0.24-0.16,0.53-0.33,0.93c-0.37,0.88-1.02,1.42-2.02,1.71
							c-1.69,0.49-5.79,2.02-7.23,2.92c-0.11,0.15-0.75,1.25-1.43,2.42c-0.62,1.07-1.33,2.28-1.9,3.22
							c-0.35,0.59-0.59,0.98-0.75,1.23c0.61-0.48,1.84-1.68,2.87-2.69c2.64-2.57,3.22-3.07,3.48-2.97c0.02,0.01,0.03,0.02,0.05,0.03
							c0.04-0.01,0.07-0.01,0.1-0.02c0.28-0.1,0.56-0.21,0.83-0.31c0.62-0.24,1.26-0.48,1.92-0.66c1.07-0.29,2.09-0.68,3.02-1.17
							c0.08-0.04,0.16-0.09,0.24-0.14c0.24-0.14,0.49-0.28,0.74-0.32l0.37-0.06l-0.44,0.51c-0.19,0.22-0.39,0.46-0.6,0.69
							c-0.18,0.25-3.07,4.85-3.18,5.08c-0.11,0.37-0.04,0.43-0.04,0.43c0.08,0.05,0.66,0.11,4.27-3.44c0.12-0.12,0.17-0.17,0.2-0.19
							c0.12-0.13,1.45-1.62,2.33-1.97c0.08-0.03,0.06-0.21,0.02-0.41c-0.03-0.2-0.07-0.38,0.04-0.5c0.04-0.04,0.12-0.09,0.25-0.07
							c0.61,0.09,1.2,0.26,1.78,0.43c0.1,0.03,0.21,0.06,0.31,0.09c0.51,0.15,0.92,0.2,1.36,0c0.41-0.19,0.85-0.33,1.27-0.47
							c0.41-0.13,0.84-0.27,1.24-0.46l0.01,0c0.09-0.04,0.32-0.15,0.45,0.09c0.04,0.07,0.05,0.15,0.02,0.22
							c-0.03,0.08-0.1,0.15-0.19,0.19c-0.29,0.12-0.49,0.34-0.71,0.58c-0.09,0.1-0.19,0.2-0.3,0.3c0.07-0.04,0.13-0.09,0.2-0.13
							c0.36-0.24,0.74-0.48,1.13-0.7c0.34-0.19,0.68-0.39,1.02-0.59c0.85-0.5,1.72-1.02,2.67-1.39c0.34-0.13,0.63-0.28,0.89-0.44
							c1.28-0.81,2.46-1.74,3.53-2.62c0.42-0.35,1.07-0.88,1.91-0.39c0.22,0.13,0.5,0.1,0.76-0.08c1.39-0.94,2.64-1.81,3.84-2.78
							c0.02-0.01,0.03-0.03,0.05-0.04c0.11-0.09,0.27-0.23,0.52-0.15l0.13,0.04l-0.05,0.13c-0.04,0.12-0.11,0.22-0.17,0.32
							c-0.1,0.16-0.18,0.29-0.15,0.46c0.01,0.05,0.01,0.1,0,0.15c0,0.03,0,0.09,0,0.1l0,0c0,0,0,0,0.01,0.01
							c0.02,0,0.06-0.05,0.09-0.07c0.02-0.03,0.05-0.05,0.07-0.07c0.11-0.09,0.21-0.19,0.32-0.28c0.32-0.29,0.66-0.58,1.01-0.83
							l0.07-0.05c0.19-0.13,0.3-0.21,0.33-0.38c-0.08-0.17-0.08-0.35-0.08-0.51c0-0.03,0-0.06,0-0.09c0-0.31,0-0.61,0-0.92
							c0-0.56,0.01-1.15-0.01-1.72c-0.01-0.43,0.16-0.63,0.59-0.7c0.42-0.06,0.84-0.15,1.25-0.24c0.28-0.06,0.56-0.12,0.84-0.17
							c0.19-0.03,0.3-0.12,0.42-0.32c0.33-0.57,0.68-1.14,1.01-1.7c0.2-0.33,0.4-0.66,0.6-0.99c0.22-0.38,0.51-0.52,0.92-0.48
							c0.48,0.05,0.9,0.05,1.28,0.01c0.24-0.03,0.52-0.04,0.65,0.15c0.12,0.17,0.01,0.4-0.02,0.47c-0.11,0.22-0.12,0.43-0.02,0.62
							c0.18,0.37,0.03,0.66-0.15,0.89c-0.5,0.64-1.17,1.48-1.95,2.24c-0.02,0.02-0.04,0.04-0.06,0.06
							c-0.03,0.02-0.05,0.05-0.08,0.07c0.11-0.03,0.22-0.08,0.33-0.14c0.61-0.28,1.19-0.62,1.76-0.95c0.67-0.39,1.37-0.79,2.11-1.1
							c0.22-0.09,0.48-0.17,0.73,0.06c0.55,0.51,0.89,1.02,1.06,1.58l0.07,0.06c0.14,0.04,0.27,0.12,0.35,0.25
							c0.18,0.26,0.42,0.26,0.79,0.22c0.28-0.03,0.53-0.14,0.79-0.25c0.09-0.04,0.19-0.09,0.29-0.12c0.16-0.12,0.24-0.3,0.32-0.5
							c0.02-0.04,0.04-0.08,0.05-0.12c0.17-0.36,0.44-0.54,0.86-0.57c0.34-0.02,0.69-0.05,1.03-0.07c0.52-0.04,1.06-0.08,1.6-0.11
							c0.19-0.01,0.34-0.07,0.48-0.21c0.22-0.2,0.44-0.4,0.66-0.59c0.36-0.32,0.74-0.65,1.09-0.99c0.3-0.28,0.5-0.14,0.67,0.02
							c0.2,0.18,0.41,0.36,0.65,0.56c0.04,0.04,0.09,0.07,0.13,0.11c0-0.05,0-0.09,0-0.14c0-0.28,0.01-0.52-0.01-0.76
							c-0.02-0.36,0.14-0.55,0.49-0.59c0.23-0.03,0.46-0.06,0.68-0.1c0.29-0.05,0.6-0.09,0.9-0.12c0.18-0.02,0.22-0.06,0.22-0.07
							c0-0.01,0.02-0.06-0.05-0.21l-0.27-0.54c-0.21-0.43-0.42-0.86-0.63-1.29c-0.01-0.01-0.02-0.03-0.03-0.05
							c-0.05-0.08-0.14-0.24,0-0.4c0.13-0.15,0.3-0.09,0.39-0.05c0.02,0.01,0.03,0.01,0.05,0.02c0.14,0.04,0.29,0.08,0.44,0.12
							c0.35,0.1,0.71,0.2,1.05,0.33c0.31,0.12,0.52,0.35,0.72,0.57c0.04,0.04,0.07,0.08,0.11,0.12c-0.06-0.09-0.13-0.17-0.2-0.26
							c-0.05-0.05-0.1-0.11-0.14-0.17c-0.36-0.47-0.69-0.96-1.02-1.44c-0.28-0.41-0.57-0.84-0.87-1.24
							c-0.07-0.09-0.13-0.18-0.1-0.29c0.03-0.11,0.13-0.16,0.22-0.21c0.38-0.17,0.69-0.14,0.99,0.1c0.2,0.16,0.41,0.31,0.61,0.46
							c0.13,0.1,0.27,0.2,0.4,0.3c-0.01-0.03-0.02-0.07-0.01-0.11c0.03-0.4-0.02-0.7-0.15-0.96c-0.19-0.38-0.24-0.66-0.16-0.86
							c0.09-0.22,0.3-0.29,0.49-0.35c0.1-0.03,0.19-0.06,0.25-0.11c0.1-0.07,0.2-0.1,0.3-0.09c0.17,0.03,0.27,0.18,0.36,0.31
							l0.03,0.04c0.73,1.05,1.48,2.12,2.21,3.15l0.39,0.55l-1.3-4.82l0.27,0.14c0.33,0.17,0.53,0.41,0.71,0.63
							c0.03,0.04,0.06,0.07,0.09,0.1c0.39,0.46,0.78,0.94,1.16,1.4c0.31,0.38,0.63,0.77,0.95,1.15c0.4,0.48,0.6,1.05,0.8,1.59
							c0.04,0.12,0.09,0.24,0.13,0.36c0.12,0.33,0.28,0.53,0.51,0.66c0.33,0.2,0.66,0.4,0.99,0.6c0.54,0.33,1.1,0.68,1.66,0.98
							c0.2,0.11,0.34,0.21,0.35,0.37c0.01,0.16-0.11,0.28-0.24,0.39c-0.01,0.01-0.01,0.02-0.02,0.03c0.04,0.04,0.1,0.07,0.15,0.06
							c0.19-0.04,0.39-0.06,0.57-0.08c0.43-0.05,0.83-0.1,1.19-0.29c0.17-0.09,0.35-0.18,0.52-0.27c0.6-0.3,1.17-0.59,1.6-1.08
							c0.08-0.1,0.21-0.14,0.26-0.15c0.14-0.04,0.25-0.11,0.35-0.19c-0.4,0.09-0.8,0.09-1.2,0.08c-0.1,0-0.19,0-0.29,0
							c-0.01,0-0.03,0-0.05,0c-0.09,0.01-0.31,0.02-0.35-0.21c-0.02-0.1,0.04-0.22,0.17-0.34c0.36-0.35,0.71-0.69,1.05-1.01
							c0.16-0.15,0.28-0.28,0.27-0.47c-0.13-1.35,0.13-2.68,0.79-4.07c0.01-0.02,0.03-0.05,0.04-0.08c0.01-0.02,0.04-0.06,0.04-0.08
							c0,0,0,0,0,0c-0.01,0-0.05,0.03-0.08,0.05c-0.03,0.02-0.07,0.05-0.1,0.06c-0.31,0.15-0.63,0.3-0.98,0.47l-0.7,0.33l0.08-0.29
							c0.06-0.24,0.13-0.47,0.19-0.7c0.13-0.49,0.26-0.95,0.37-1.41c0.2-0.91,0.72-1.55,1.57-1.97c0.11-0.05,0.21-0.12,0.29-0.2
							c-0.44-0.04-0.87-0.04-1.26,0.18c-0.3,0.17-0.6,0.36-0.89,0.54c-0.13,0.08-0.25,0.16-0.38,0.24
							c-0.01,0.01-0.03,0.02-0.04,0.03c-0.05,0.05-0.17,0.15-0.32,0.08c-0.21-0.1-0.17-0.3-0.15-0.39c0-0.01,0-0.03,0.01-0.04
							c0.07-0.49,0.33-0.89,0.57-1.28c0.06-0.09,0.12-0.18,0.17-0.28c0.1-0.16,0.2-0.33,0.3-0.49c0.27-0.43,0.54-0.88,0.76-1.35
							c0.1-0.21,0.12-0.34,0.07-0.44c-0.49-0.9-0.37-1.81-0.25-2.68c0.03-0.22,0.06-0.45,0.08-0.67c0.09-1.03,0.76-1.58,1.41-2.12
							l0.07-0.06c0.11-0.09,0.22-0.18,0.33-0.27c0.23-0.18,0.46-0.38,0.69-0.57c0.09-0.08,0.23-0.18,0.39-0.17
							c0.16,0.02,0.28,0.15,0.36,0.26c0.1,0.13,0.19,0.27,0.28,0.41c0.09,0.14,0.18,0.29,0.29,0.43c-0.05-0.23-0.09-0.44-0.16-0.63
							c-0.27-0.72-0.18-1.44,0.28-2.26c0.29-0.51,0.54-1.05,0.77-1.65c0.11-0.28,0.28-0.42,0.55-0.43c0.08,0,0.16-0.01,0.24-0.02
							c0.12-0.01,0.24-0.03,0.36-0.02c0.44,0.02,0.75-0.24,1.07-0.5c0.14-0.12,0.29-0.24,0.45-0.33c0.04-0.02,0.08-0.06,0.12-0.1
							c0.12-0.11,0.29-0.26,0.51-0.17c0.27,0.12,0.23,0.39,0.21,0.55c-0.01,0.06-0.01,0.11-0.01,0.15c0.03,0.75,0.05,1.5,0.07,2.24
							c0.01,0.28,0.02,0.55,0.02,0.83c0.15-0.81,0.3-1.63,0.45-2.42l0.12-0.67c0.06-0.35,0.25-0.61,0.43-0.88
							c0.1-0.15,0.2-0.28,0.27-0.43c0.09-0.18,0.21-0.35,0.33-0.52c0.06-0.08,0.11-0.16,0.17-0.25l0.13-0.21l0.1,0.22
							c0.19,0.41,0.14,0.8,0.09,1.15l0,0.01c-0.13,0.94,0.15,1.46,0.93,1.73c0.39,0.13,0.46,0.48,0.47,0.77
							c0.05,1.15,0.06,2.26,0.06,3.19c0,0.25-0.01,0.51-0.1,0.76c-0.2,0.54-0.33,1.11-0.46,1.66c-0.1,0.41-0.2,0.84-0.33,1.25
							c0.24-0.44,0.47-0.86,0.7-1.28c0-0.01,0.01-0.03,0.02-0.05c0.02-0.07,0.06-0.23,0.25-0.23c0.24-0.01,0.28,0.22,0.29,0.3
							c0,0.01,0,0.03,0.01,0.04c0.03,0.14,0.06,0.28,0.08,0.42c0.1,0.5,0.2,1.02,0.35,1.52c0.1,0.32-0.11,0.57-0.22,0.7
							c-0.65,0.77-1.35,1.53-2.08,2.24c-0.1,0.09-0.18,0.19-0.19,0.27c0,0.06,0.04,0.13,0.12,0.21c0.26,0.26,0.27,0.58,0.03,0.96
							c-0.57,0.9-1.13,1.83-1.68,2.72l-0.18,0.29c-0.13,0.21-0.15,0.28-0.14,0.3c0.02,0.03,0.13,0.07,0.34,0.12
							c0.02,0,0.03,0.01,0.05,0.01c0.01,0,0.03,0.01,0.04,0.01c1.35,0.3,1.4,0.36,1.27,1.74c0,0.04-0.01,0.08-0.02,0.11
							c-0.01,0.08-0.02,0.13-0.01,0.18c0.15-0.01,0.26-0.09,0.4-0.25c0.15-0.16,0.3-0.32,0.45-0.48c0.4-0.43,0.82-0.87,1.26-1.28
							c0.36-0.34,0.78-0.75,1.13-1.22c0.05-0.14,0.11-0.27,0.16-0.41c0.12-0.3,0.25-0.61,0.34-0.93c0.26-0.92,0.84-1.6,1.8-2.13
							c0.48-0.26,0.84-0.24,1.25,0.07c0.37,0.28,0.74,0.52,1.23,0.42c0.05-0.01,0.11-0.02,0.16-0.04c0.16-0.04,0.32-0.07,0.5-0.09
							c-0.12-0.08-0.25-0.13-0.39-0.19c-0.11-0.05-0.23-0.09-0.34-0.16c-0.06-0.04-0.14-0.06-0.23-0.09
							c-0.2-0.07-0.44-0.15-0.51-0.4c-0.02-0.08-0.05-0.17-0.08-0.25c-0.21-0.68-0.42-1.37-0.09-2.14c0.31-0.71,0.65-1.41,0.98-2.09
							c0.17-0.35,0.35-0.72,0.52-1.08c0.05-0.1,0.13-0.24,0.27-0.26c0.15-0.02,0.25,0.11,0.34,0.22c0.07,0.09,0.13,0.17,0.2,0.26
							c0.24,0.32,0.49,0.64,0.75,0.93c0.35,0.37,0.53,0.78,0.58,1.29c0.04,0.4,0.1,0.81,0.17,1.21c0.01,0.07,0.02,0.14,0.03,0.21
							c0.27-0.84,0.53-1.67,0.78-2.48c0.06-0.18,0.04-0.37-0.05-0.57c-0.4-0.93-0.33-1.7,0.21-2.35c0.11-0.13,0.11-0.24,0.01-0.43
							c-0.23-0.42-0.45-0.86-0.67-1.28c-0.08-0.15-0.16-0.3-0.24-0.46c-0.01-0.02-0.02-0.04-0.04-0.07
							c-0.07-0.13-0.19-0.32-0.05-0.53c0.23-0.34,0.47-0.68,0.7-1.01l0.03-0.04c0.04-0.06,0.13-0.18,0.26-0.18
							c0.09,0,0.17,0.04,0.24,0.13c0.12,0.16,0.26,0.31,0.39,0.46c0.49,0.55,0.99,1.12,1.08,1.97c0.04,0.36,0.14,0.72,0.24,1.07
							c0,0.01,0.01,0.02,0.01,0.04l0-0.07c0.03-0.61,0.06-1.23,0.09-1.84c0.01-0.23-0.03-0.45-0.11-0.7
							c-0.21-0.61-0.42-1.24-0.62-1.84c-0.13-0.39-0.26-0.78-0.39-1.17c-0.07-0.21-0.06-0.44,0.04-0.64l0.04-0.07
							c0.17-0.34,0.34-0.69,0.49-1.03c0.03-0.06,0.11-0.24,0.28-0.26c0.17-0.02,0.28,0.13,0.32,0.18c0.08,0.11,0.17,0.21,0.25,0.32
							c0.36,0.44,0.73,0.9,0.87,1.5c0.06-0.52,0.07-1.01-0.3-1.44c-0.12-0.13-0.2-0.29-0.28-0.44l-0.03-0.06
							c-0.39-0.74-0.41-0.84,0.3-1.41c0.15-0.12,0.16-0.17,0.16-0.17c0-0.01-0.03-0.05-0.19-0.13c-2.01-1.1-3.77-1.86-5.77-2.03
							l-0.03,0c-0.66-0.05-1.4-0.11-2-0.54c-1.3-0.93-2.62-2.08-4.16-3.63c-0.35-0.36-0.66-0.48-1.08-0.44
							c-0.12,0.06-0.28,0.04-0.34,0.04c-0.35,0.02-0.7,0.03-1.05,0.05l-0.05,0l-0.52-0.29l-0.01-0.02
							c-0.07-0.07-0.14-0.14-0.21-0.21c-0.16-0.16-0.32-0.32-0.45-0.5c-0.48-0.66-1.07-1.05-1.79-1.19
							c-0.02-0.01-0.05-0.01-0.07-0.02c-0.16-0.04-0.19-0.04-0.24,0.06c-0.18,0.37-0.36,0.73-0.54,1.1c-0.4,0.82-0.81,1.66-1.2,2.5
							c-0.04,0.08-0.08,0.15-0.13,0.23c-0.07,0.11-0.14,0.21-0.16,0.32c0.01-0.01,0.02-0.02,0.02-0.02
							c0.02-0.02,0.05-0.05,0.07-0.07c0.17-0.14,0.33-0.26,0.48-0.21c0.16,0.04,0.24,0.22,0.32,0.45c0.11,0.31,0.06,0.61-0.01,0.88
							l-0.39,1.53l0.1-0.13c0.47-0.6,0.96-1.22,1.43-1.83c0.03-0.04,0.06-0.08,0.09-0.12c0.11-0.15,0.22-0.3,0.39-0.41l0.05-0.21
							l0.36,0.24l-0.16,0.14c0,0.02,0,0.03-0.01,0.05c-0.01,0.08-0.02,0.17-0.07,0.25c-0.17,0.33-0.35,0.66-0.52,0.98
							c-0.17,0.32-0.35,0.65-0.53,0.98c-0.05,0.09-0.1,0.18-0.15,0.27c-0.32,0.57-0.6,1.07-0.16,1.7c0.11,0.15,0.09,0.27,0.06,0.35
							c-0.04,0.09-0.13,0.19-0.38,0.23c-0.18,0.03-0.37,0.06-0.55,0.1c-0.29,0.06-0.59,0.11-0.9,0.13c-0.41,0.03-0.76,0.18-1.1,0.48
							c-0.07,0.06-0.14,0.12-0.21,0.19c-0.22,0.19-0.45,0.39-0.66,0.6c-0.64,0.64-1.29,1.1-2,1.41c-0.07,0.03-0.15,0.06-0.23,0.1
							c-0.4,0.17-0.82,0.34-1.13,0.64c-0.4,0.38-0.8,0.79-1.17,1.18c-0.23,0.24-0.47,0.49-0.71,0.73c-0.02,0.02-0.04,0.05-0.06,0.08
							c-0.06,0.09-0.14,0.2-0.27,0.22c-0.07,0.01-0.15-0.01-0.22-0.06c-0.08-0.06-0.13-0.13-0.14-0.21
							c-0.01-0.13,0.07-0.22,0.14-0.29c0.03-0.03,0.06-0.06,0.07-0.08c0.02-0.04,0.04-0.07,0.06-0.1c0.02-0.04,0.05-0.07,0.05-0.1
							c0.21-1.04,0.79-1.89,1.76-2.61c0.02-0.02,0.05-0.04,0.07-0.06c0.11-0.09,0.29-0.24,0.51-0.1c0.11,0.07,0.19,0.09,0.26,0.07
							c0.11-0.03,0.19-0.17,0.29-0.33l0.01-0.01c0.11-0.19,0.23-0.37,0.34-0.55c0.06-0.1,0.12-0.2,0.19-0.3
							c0.14-0.22,0.27-0.45,0.4-0.68c0.44-0.79,0.9-1.6,1.74-2.13c0.03-0.02,0.05-0.03,0.05-0.04c-0.01,0-0.03,0-0.04,0
							c-0.02,0-0.04,0-0.05,0c-0.08-0.01-0.17-0.02-0.25-0.03c-0.23-0.03-0.47-0.06-0.7-0.1c-0.27-0.05-0.55-0.16-0.51-0.62
							c0.03-0.38,0.13-0.72,0.23-1.09c0.01-0.03,0.02-0.05,0.03-0.08c0-0.01,0.01-0.02,0.01-0.03c0,0-0.01,0.01-0.01,0.01
							c-0.02,0.02-0.05,0.03-0.07,0.04c-1.1,0.58-1.81,1.25-2.23,2.12c-0.13,0.26-0.29,0.5-0.45,0.73c-0.07,0.1-0.15,0.21-0.21,0.31
							l-0.28,0.43l0.03-0.51c0.05-0.81,0.17-1.59,0.31-2.38c0.04-0.24,0.08-0.45,0.04-0.5c-0.04-0.05-0.28-0.07-0.43-0.08
							c-0.17-0.01-0.35-0.04-0.52-0.07c-0.44-0.08-0.85-0.15-1.22,0.08c-0.6,0.36-1.23,0.34-1.83,0.32
							c-0.33-0.01-0.63-0.02-0.94,0.02l-0.39,0.06l0.27-0.29c0.27-0.29,0.6-0.48,0.91-0.67c0.18-0.11,0.35-0.21,0.51-0.33
							c0.46-0.34,1.03-0.67,1.74-0.54c0.2,0.04,0.27-0.02,0.39-0.32c0.18-0.43,0.06-0.83-0.06-1.25c-0.06-0.21-0.12-0.42-0.15-0.64
							c0,0,0,0-0.01,0c-0.16,0.1-0.33,0.19-0.49,0.27c-0.54,0.27-1,0.5-0.95,1.24c0,0.06,0.02,0.23-0.11,0.32
							c-0.14,0.09-0.33-0.01-0.41-0.05c-0.23-0.12-0.47-0.24-0.7-0.36c-0.16-0.08-0.32-0.16-0.47-0.24
							c-0.12-0.06-0.23-0.11-0.31-0.15c-0.46-0.22-0.49-0.28-0.38-0.83c0.02-0.09,0.04-0.19,0.06-0.32c0.11-0.59,0.07-0.95-0.4-1.25
							c-0.54-0.34-0.67-0.78-0.39-1.29c0.05-0.1,0.08-0.23,0.11-0.37c0.02-0.08,0.03-0.17,0.06-0.25l0.03-0.1
							c0.16-0.53,0.3-1.03,0.12-1.54c-0.14-0.39-0.04-0.66,0.28-0.85c0.13-0.08,0.27-0.16,0.39-0.26c0.17-0.15,0.33-0.21,0.48-0.19
							c0.21,0.03,0.35,0.21,0.48,0.41c0.21,0.34,0.44,0.67,0.66,1c0.42,0.62,0.84,1.25,1.21,1.91c0.32,0.58,0.8,0.74,1.36,0.84
							c-0.07-0.09-0.15-0.17-0.24-0.26c-0.06-0.06-0.13-0.13-0.19-0.19c-0.13-0.15-0.27-0.28-0.41-0.42
							c-0.33-0.32-0.67-0.65-0.85-1.12c-0.09-0.25-0.22-0.49-0.35-0.74c-0.14-0.28-0.29-0.56-0.4-0.87
							c-0.15-0.43-0.37-0.76-0.66-0.98c-0.07-0.05-0.14-0.1-0.22-0.15c-0.22-0.14-0.48-0.3-0.55-0.58c-0.07-0.27,0.1-0.46,0.25-0.63
							c0.09-0.1,0.17-0.2,0.2-0.3l0.01-0.02l0.29-0.42l0.05-0.01c0.13-0.03,0.26-0.1,0.39-0.17c0.33-0.18,0.74-0.4,1.19,0.05
							c0.01-0.01,0.03-0.01,0.04-0.02c0.03-0.01,0.06-0.03,0.09-0.04c0.31-0.1,0.48-0.34,0.66-0.6c0.02-0.03,0.05-0.07,0.07-0.1
							l0.02-0.03c0-0.01,0.01-0.01,0.01-0.02c-0.09,0.01-0.18,0.03-0.26,0.04c-0.32,0.05-0.64,0.12-0.95,0.19
							c-0.34,0.08-0.69,0.15-1.04,0.21c-0.2,0.03-0.42,0.16-0.62,0.28c-0.07,0.04-0.13,0.08-0.19,0.11
							c-0.25,0.14-0.52,0.3-0.84,0.49c-0.13,1.77-0.87,2.99-2.24,3.75l-0.13-0.24c1.31-0.72,2-1.89,2.11-3.6l0-0.07l0.06-0.04
							c0.35-0.21,0.63-0.38,0.91-0.53c0.06-0.03,0.12-0.07,0.18-0.11c0.22-0.13,0.47-0.28,0.72-0.32c0.34-0.05,0.69-0.13,1.02-0.2
							c0.32-0.07,0.64-0.14,0.97-0.2c0.34-0.05,0.69-0.1,1.05-0.15c0.17-0.02,0.34-0.04,0.51-0.06c0.02,0,0.06,0,0.12,0
							c0.07,0,0.27-0.01,0.32-0.02c0,0-0.03,0.01-0.05,0.04l0.23,0.15c-0.06,0.08-0.12,0.15-0.19,0.22
							c-0.04,0.04-0.08,0.09-0.12,0.13c-0.26,0.32-0.38,0.47-0.56,0.47c0,0,0,0,0,0c-0.18,0-0.32-0.17-0.54-0.44
							c-0.06-0.08-0.1-0.09-0.11-0.09c0,0,0,0,0,0c-0.03,0-0.1,0.1-0.13,0.14l-0.02,0.03c-0.02,0.03-0.05,0.07-0.07,0.1
							c-0.2,0.29-0.41,0.58-0.8,0.71c-0.02,0.01-0.04,0.01-0.05,0.02c-0.07,0.03-0.22,0.1-0.34-0.02c-0.3-0.31-0.52-0.22-0.88-0.02
							c-0.13,0.07-0.27,0.14-0.41,0.18l-0.21,0.3c-0.05,0.15-0.16,0.27-0.25,0.38c-0.14,0.16-0.22,0.26-0.19,0.39
							c0.04,0.17,0.23,0.29,0.43,0.41c0.08,0.05,0.17,0.11,0.25,0.17c0.33,0.26,0.59,0.63,0.75,1.11c0.1,0.29,0.24,0.56,0.38,0.83
							c0.13,0.25,0.26,0.5,0.36,0.77c0.15,0.41,0.46,0.71,0.78,1.02c0.14,0.14,0.29,0.28,0.42,0.44c0.05,0.06,0.11,0.12,0.17,0.18
							c0.15,0.15,0.33,0.32,0.43,0.58l0.1,0.23l-0.24-0.04c-0.72-0.12-1.41-0.23-1.85-1.03c-0.36-0.65-0.78-1.28-1.2-1.89
							c-0.22-0.33-0.45-0.67-0.67-1.01c-0.08-0.12-0.18-0.27-0.28-0.28c-0.07-0.01-0.15,0.03-0.26,0.13
							c-0.14,0.12-0.3,0.22-0.44,0.3c-0.21,0.12-0.25,0.26-0.16,0.52c0.21,0.6,0.04,1.17-0.12,1.71l-0.03,0.1
							c-0.02,0.07-0.04,0.15-0.05,0.23c-0.03,0.15-0.06,0.31-0.14,0.45c-0.21,0.38-0.12,0.66,0.3,0.93
							c0.66,0.42,0.62,0.99,0.52,1.53c-0.02,0.13-0.04,0.23-0.06,0.32c-0.04,0.19-0.07,0.33-0.05,0.37
							c0.02,0.04,0.12,0.09,0.27,0.16c0.09,0.04,0.19,0.09,0.32,0.16c0.16,0.08,0.31,0.16,0.47,0.24c0.23,0.12,0.47,0.24,0.71,0.36
							c0.07,0.03,0.1,0.05,0.13,0.06c0-0.01,0-0.03,0-0.06c-0.07-0.92,0.55-1.23,1.1-1.5c0.17-0.08,0.32-0.16,0.46-0.25
							c0.09-0.06,0.2-0.07,0.29-0.03c0.08,0.04,0.13,0.11,0.14,0.2c0.02,0.21,0.08,0.41,0.14,0.62c0.13,0.45,0.27,0.91,0.05,1.43
							c-0.09,0.23-0.24,0.57-0.69,0.48c-0.61-0.11-1.1,0.17-1.53,0.49c-0.17,0.13-0.35,0.24-0.53,0.34
							c-0.2,0.12-0.39,0.23-0.56,0.36c0.19,0,0.39,0,0.58,0.01c0.59,0.02,1.15,0.04,1.68-0.28c0.46-0.28,0.94-0.19,1.41-0.11
							c0.17,0.03,0.33,0.06,0.49,0.07c0.32,0.02,0.52,0.04,0.63,0.18c0.11,0.14,0.08,0.33,0.02,0.71c-0.11,0.62-0.2,1.22-0.26,1.84
							c0.14-0.2,0.26-0.39,0.36-0.59c0.45-0.93,1.19-1.64,2.35-2.25c0.01-0.01,0.03-0.02,0.04-0.03c0.07-0.05,0.2-0.14,0.35-0.03
							c0.17,0.13,0.09,0.31,0.06,0.39c-0.01,0.02-0.02,0.04-0.02,0.05c-0.1,0.36-0.19,0.68-0.22,1.03
							c-0.02,0.24,0.05,0.29,0.28,0.33c0.23,0.04,0.46,0.07,0.69,0.1c0.08,0.01,0.17,0.02,0.25,0.03c0.01,0,0.02,0,0.04,0
							c0.07,0.01,0.21,0.01,0.27,0.16c0.05,0.1,0.06,0.26-0.16,0.39c-0.78,0.49-1.2,1.24-1.64,2.03c-0.13,0.23-0.26,0.46-0.4,0.69
							c-0.06,0.1-0.12,0.2-0.19,0.3c-0.11,0.18-0.23,0.36-0.34,0.55l-0.01,0.01c-0.12,0.2-0.24,0.4-0.46,0.46
							c-0.14,0.03-0.29,0-0.47-0.11c-0.05-0.03-0.07-0.02-0.19,0.08c-0.03,0.02-0.06,0.05-0.09,0.07c-0.92,0.68-1.46,1.48-1.66,2.45
							c-0.02,0.08-0.06,0.14-0.09,0.2c-0.02,0.03-0.03,0.05-0.04,0.07c-0.03,0.06-0.07,0.1-0.11,0.15
							c-0.02,0.02-0.05,0.06-0.06,0.08c0,0,0.01,0.01,0.02,0.01c0.01,0.01,0.02,0.01,0.02,0.01c0.01-0.01,0.06-0.08,0.09-0.11
							c0.03-0.04,0.06-0.08,0.09-0.12c0.24-0.24,0.48-0.48,0.71-0.72c0.38-0.4,0.78-0.81,1.18-1.19c0.35-0.32,0.79-0.51,1.21-0.69
							c0.07-0.03,0.15-0.06,0.22-0.09c0.68-0.29,1.3-0.74,1.91-1.35c0.21-0.22,0.45-0.42,0.67-0.61c0.07-0.06,0.14-0.12,0.21-0.18
							c0.39-0.34,0.79-0.52,1.26-0.55c0.29-0.02,0.58-0.08,0.87-0.13c0.18-0.03,0.37-0.07,0.56-0.1c0.11-0.01,0.16-0.05,0.16-0.07
							c0-0.01,0-0.04-0.03-0.08c-0.55-0.77-0.18-1.42,0.14-1.99c0.05-0.09,0.1-0.18,0.15-0.27c0.17-0.33,0.35-0.66,0.53-0.99
							c0.11-0.2,0.22-0.41,0.33-0.62c0,0,0,0-0.01,0.01c-0.47,0.61-0.96,1.24-1.43,1.84l-0.19,0.24c-0.04,0.05-0.16,0.2-0.39,0.11
							l-0.11-0.04l0.49-1.94c0.07-0.27,0.1-0.5,0.02-0.72c-0.08-0.22-0.12-0.27-0.13-0.28c-0.01,0-0.07,0.02-0.23,0.16
							c-0.02,0.02-0.04,0.03-0.05,0.05c-0.07,0.07-0.17,0.17-0.35,0.18l-0.12,0.01l-0.02-0.12c-0.06-0.3,0.08-0.53,0.2-0.71
							c0.04-0.07,0.08-0.13,0.11-0.19c0.39-0.84,0.8-1.68,1.2-2.5c0.18-0.37,0.36-0.73,0.53-1.1c0.14-0.3,0.38-0.24,0.54-0.21
							c0.02,0,0.04,0.01,0.07,0.01c0.79,0.16,1.43,0.59,1.96,1.3c0.12,0.17,0.27,0.31,0.43,0.47c0.07,0.07,0.13,0.13,0.2,0.2
							l0.42,0.23c0.35-0.02,0.69-0.03,1.04-0.05l0.01,0l0.01,0c0.07,0.01,0.17,0.01,0.19-0.01l0.03-0.02l0.03-0.01
							c0.53-0.06,0.91,0.08,1.34,0.51c1.52,1.54,2.83,2.68,4.12,3.6c0.54,0.38,1.21,0.44,1.86,0.49l0.03,0
							c2.17,0.18,4.11,1.09,5.88,2.06c0.16,0.09,0.32,0.19,0.33,0.35c0.01,0.16-0.13,0.3-0.26,0.41c-0.3,0.24-0.44,0.36-0.46,0.47
							c-0.02,0.11,0.08,0.29,0.24,0.6l0.03,0.06c0.07,0.14,0.15,0.28,0.24,0.39c0.49,0.57,0.42,1.21,0.34,1.83l-0.03,0.23
							l-0.11,0.01c-0.3,0.02-0.35-0.22-0.36-0.3l0-0.01c-0.11-0.58-0.46-1.01-0.83-1.46c-0.09-0.11-0.18-0.22-0.26-0.32
							c-0.03-0.04-0.06-0.06-0.07-0.07c-0.01,0.01-0.03,0.04-0.06,0.1c-0.16,0.35-0.33,0.7-0.5,1.04l-0.04,0.07
							c-0.07,0.13-0.08,0.29-0.03,0.43c0.13,0.39,0.26,0.78,0.39,1.17c0.2,0.6,0.41,1.23,0.62,1.84c0.1,0.29,0.14,0.54,0.13,0.81
							c-0.03,0.61-0.06,1.23-0.09,1.84l-0.03,0.67l-0.36,0.04l-0.03-0.12c-0.04-0.16-0.09-0.33-0.13-0.49
							c-0.1-0.36-0.21-0.74-0.25-1.12c-0.08-0.76-0.53-1.27-1.01-1.81c-0.13-0.15-0.27-0.31-0.4-0.47
							c-0.01-0.01-0.01-0.02-0.02-0.02c-0.01,0.01-0.02,0.03-0.05,0.06l-0.03,0.04c-0.23,0.33-0.47,0.66-0.69,1
							c-0.04,0.06-0.02,0.11,0.05,0.25c0.01,0.03,0.03,0.05,0.04,0.08c0.08,0.15,0.16,0.3,0.24,0.46c0.22,0.42,0.44,0.85,0.67,1.27
							c0.11,0.2,0.2,0.46-0.04,0.73c-0.47,0.57-0.53,1.23-0.17,2.07c0.11,0.26,0.13,0.52,0.06,0.76c-0.25,0.82-0.52,1.67-0.79,2.52
							c0,0.01-0.01,0.02-0.01,0.04c-0.02,0.09-0.06,0.25-0.29,0.32l-0.15,0.04l-0.02-0.15c-0.03-0.18-0.05-0.35-0.08-0.53
							c-0.06-0.4-0.12-0.81-0.17-1.22c-0.05-0.45-0.21-0.81-0.51-1.13c-0.28-0.3-0.53-0.63-0.77-0.95c-0.07-0.09-0.13-0.17-0.2-0.26
							c-0.05-0.06-0.08-0.09-0.09-0.11c-0.01,0.01-0.03,0.04-0.06,0.1c-0.17,0.36-0.35,0.73-0.52,1.08
							c-0.33,0.68-0.67,1.38-0.97,2.08c-0.29,0.67-0.1,1.29,0.1,1.95c0.03,0.09,0.05,0.17,0.08,0.26c0.03,0.11,0.15,0.15,0.34,0.22
							c0.09,0.03,0.19,0.06,0.28,0.11c0.09,0.05,0.2,0.1,0.3,0.14c0.24,0.1,0.52,0.21,0.75,0.49l0.21,0.26l-0.33-0.03
							c-0.3-0.03-0.54,0.03-0.78,0.08c-0.06,0.01-0.11,0.03-0.17,0.04c-0.6,0.12-1.06-0.18-1.45-0.47
							c-0.33-0.25-0.57-0.26-0.95-0.05c-0.9,0.49-1.43,1.12-1.67,1.96c-0.09,0.33-0.22,0.65-0.34,0.95
							c-0.06,0.14-0.11,0.28-0.16,0.42l-0.01,0.02l-0.01,0.02c-0.37,0.5-0.8,0.93-1.18,1.28c-0.43,0.4-0.84,0.84-1.24,1.26
							c-0.15,0.16-0.3,0.32-0.45,0.48c-0.17,0.18-0.38,0.36-0.7,0.33l-0.06-0.01l-0.04-0.05c-0.12-0.15-0.09-0.31-0.07-0.44
							c0.01-0.03,0.01-0.06,0.01-0.09c0.05-0.6,0.08-0.93-0.03-1.09c-0.12-0.16-0.44-0.23-1.03-0.36c-0.02,0-0.04-0.01-0.05-0.01
							c-0.01,0-0.02-0.01-0.04-0.01c-0.27-0.06-0.46-0.12-0.53-0.28c-0.07-0.16,0.03-0.33,0.16-0.55l0.18-0.29
							c0.55-0.9,1.11-1.82,1.68-2.73c0.21-0.34,0.11-0.51,0.01-0.62c-0.14-0.14-0.2-0.27-0.2-0.4c0-0.19,0.14-0.34,0.27-0.47
							c0.73-0.71,1.42-1.45,2.07-2.22c0.14-0.17,0.21-0.3,0.17-0.45c-0.15-0.51-0.26-1.04-0.36-1.54c-0.03-0.14-0.06-0.28-0.08-0.42
							c0-0.02-0.01-0.03-0.01-0.05c0-0.01,0-0.02-0.01-0.04c-0.01,0.03-0.02,0.06-0.04,0.1c-0.25,0.46-0.5,0.92-0.76,1.39
							l-0.33,0.61l-0.13-0.19c-0.15-0.23-0.09-0.42-0.04-0.55c0.01-0.02,0.01-0.03,0.02-0.05c0.15-0.45,0.26-0.93,0.37-1.38
							c0.13-0.56,0.27-1.14,0.47-1.69c0.08-0.21,0.08-0.44,0.08-0.67c0-0.93-0.01-2.04-0.06-3.18c-0.01-0.32-0.09-0.46-0.29-0.53
							c-0.91-0.31-1.26-0.95-1.11-2.02l0-0.01c0.03-0.24,0.07-0.5,0.02-0.75c-0.01,0.01-0.01,0.02-0.02,0.03
							c-0.12,0.17-0.22,0.32-0.31,0.49c-0.08,0.17-0.19,0.32-0.29,0.47c-0.17,0.25-0.33,0.48-0.39,0.77l-0.12,0.67
							c-0.16,0.85-0.32,1.73-0.48,2.59c0,0.02-0.01,0.04-0.01,0.06c-0.01,0.11-0.03,0.3-0.24,0.37l-0.09,0.03l-0.06-0.07
							c-0.11-0.12-0.11-0.26-0.11-0.35c0-0.02,0-0.03,0-0.05c-0.01-0.35-0.02-0.69-0.03-1.04c-0.02-0.73-0.04-1.49-0.07-2.24
							c0-0.07,0.01-0.13,0.02-0.2c0.03-0.19,0.02-0.23-0.05-0.26c-0.05-0.02-0.1,0.02-0.22,0.12c-0.05,0.04-0.1,0.09-0.16,0.13
							c-0.14,0.09-0.28,0.2-0.42,0.31c-0.35,0.29-0.71,0.59-1.26,0.57c-0.1,0-0.21,0.01-0.32,0.02c-0.08,0.01-0.17,0.02-0.26,0.02
							c-0.14,0.01-0.22,0.05-0.31,0.25c-0.24,0.62-0.5,1.17-0.79,1.68c-0.42,0.75-0.5,1.38-0.26,2.03c0.09,0.23,0.13,0.47,0.19,0.75
							c0.03,0.13,0.05,0.27,0.09,0.42l0.1,0.44l-0.33-0.31c-0.3-0.28-0.49-0.57-0.67-0.86c-0.09-0.14-0.17-0.27-0.27-0.4
							c-0.09-0.12-0.14-0.15-0.17-0.15c-0.04,0-0.1,0.03-0.18,0.1c-0.23,0.2-0.46,0.39-0.69,0.58c-0.11,0.09-0.22,0.18-0.32,0.26
							l-0.08,0.06c-0.63,0.52-1.22,1.01-1.31,1.93c-0.02,0.23-0.05,0.46-0.08,0.69c-0.12,0.87-0.23,1.7,0.22,2.52
							c0.13,0.23,0.04,0.49-0.06,0.69c-0.23,0.48-0.51,0.94-0.78,1.38c-0.1,0.16-0.2,0.32-0.29,0.48c-0.06,0.1-0.12,0.19-0.17,0.28
							c-0.24,0.38-0.47,0.74-0.53,1.17c0,0.01-0.01,0.03-0.01,0.05c0,0.02-0.01,0.07-0.01,0.08c0.01-0.01,0.02-0.02,0.03-0.03
							c0.02-0.02,0.05-0.04,0.08-0.06c0.13-0.08,0.25-0.16,0.38-0.24c0.29-0.19,0.6-0.38,0.9-0.55c0.54-0.3,1.1-0.26,1.67-0.19
							l0.22,0.03l-0.12,0.18c-0.15,0.22-0.31,0.37-0.54,0.48c-0.77,0.37-1.24,0.96-1.42,1.78c-0.1,0.47-0.23,0.93-0.37,1.43
							c-0.04,0.13-0.07,0.27-0.11,0.41l0.16-0.08c0.35-0.17,0.67-0.32,0.98-0.47c0.02-0.01,0.04-0.02,0.06-0.04
							c0.08-0.05,0.24-0.17,0.41-0.03c0.19,0.16,0.07,0.35,0.02,0.44c-0.01,0.02-0.02,0.04-0.03,0.05
							c-0.64,1.34-0.89,2.62-0.77,3.93c0.03,0.33-0.2,0.55-0.35,0.69c-0.34,0.32-0.69,0.66-1.05,1.01
							c-0.04,0.04-0.06,0.07-0.08,0.09c0.02,0,0.04,0,0.05,0c0.03,0,0.05,0,0.08,0c0.09,0,0.19,0,0.28,0
							c0.57,0.01,1.1,0.01,1.6-0.24l0.61-0.31l-0.5,0.6c-0.21,0.25-0.42,0.51-0.79,0.61c-0.05,0.01-0.1,0.04-0.13,0.07
							c-0.47,0.54-1.09,0.85-1.69,1.15c-0.17,0.08-0.34,0.17-0.51,0.26c-0.41,0.22-0.86,0.27-1.29,0.32
							c-0.18,0.02-0.37,0.04-0.55,0.08c-0.17,0.03-0.35-0.05-0.48-0.24l-0.06-0.08l0.05-0.08c0.01-0.01,0.02-0.03,0.03-0.04
							c0.02-0.04,0.05-0.09,0.1-0.14c0.13-0.11,0.14-0.15,0.14-0.16c0,0-0.02-0.05-0.21-0.15c-0.57-0.31-1.13-0.66-1.67-0.99
							c-0.32-0.2-0.65-0.4-0.98-0.6c-0.37-0.22-0.53-0.54-0.63-0.8c-0.05-0.12-0.09-0.24-0.13-0.37c-0.2-0.55-0.39-1.07-0.76-1.51
							c-0.32-0.38-0.64-0.78-0.95-1.16c-0.38-0.46-0.77-0.94-1.16-1.4c-0.03-0.04-0.06-0.07-0.09-0.11c-0.1-0.12-0.2-0.25-0.33-0.36
							l1.25,4.63l-0.14,0.03c-0.25,0.06-0.35-0.09-0.4-0.16l-0.01-0.02l-0.45-0.64c-0.73-1.03-1.48-2.1-2.21-3.15l-0.03-0.04
							c-0.06-0.08-0.12-0.18-0.18-0.19c-0.03,0-0.07,0.02-0.09,0.04c-0.1,0.07-0.21,0.11-0.32,0.15c-0.18,0.06-0.29,0.1-0.32,0.19
							c-0.03,0.08-0.04,0.26,0.15,0.63c0.15,0.3,0.2,0.65,0.18,1.09c0,0.01,0.01,0.03,0.01,0.05c0.02,0.07,0.06,0.22-0.07,0.32
							c-0.15,0.11-0.29,0.01-0.34-0.04l-0.02-0.01c-0.13-0.1-0.26-0.2-0.39-0.29c-0.21-0.15-0.42-0.31-0.62-0.47
							c-0.21-0.17-0.42-0.19-0.7-0.07c-0.03,0.01-0.04,0.02-0.06,0.03c0.01,0.01,0.02,0.03,0.04,0.06c0.31,0.41,0.59,0.83,0.87,1.25
							c0.32,0.47,0.65,0.96,1.01,1.42c0.04,0.05,0.09,0.1,0.13,0.16c0.17,0.2,0.36,0.42,0.39,0.74l0.01,0.1l-0.1,0.04
							c-0.19,0.07-0.29-0.03-0.34-0.09c-0.01-0.01-0.01-0.01-0.02-0.02c-0.09-0.09-0.18-0.18-0.26-0.27
							c-0.19-0.21-0.37-0.41-0.61-0.5c-0.33-0.13-0.69-0.22-1.03-0.32c-0.15-0.04-0.29-0.08-0.44-0.12
							c-0.02-0.01-0.04-0.01-0.07-0.02c-0.03-0.01-0.07-0.03-0.09-0.02l-0.13-0.06l0.12,0.06c0,0.01,0.02,0.05,0.04,0.07
							c0.01,0.02,0.03,0.04,0.04,0.06c0.21,0.43,0.42,0.86,0.63,1.29l0.27,0.54c0.06,0.12,0.13,0.31,0.05,0.46
							c-0.09,0.16-0.29,0.2-0.43,0.21c-0.29,0.03-0.59,0.08-0.88,0.12c-0.22,0.04-0.46,0.07-0.69,0.1
							c-0.21,0.03-0.27,0.09-0.25,0.31c0.01,0.25,0.01,0.49,0.01,0.78c0,0.13,0,0.27,0,0.43v0.29l-0.22-0.19
							c-0.13-0.1-0.24-0.2-0.35-0.29c-0.25-0.2-0.46-0.38-0.67-0.57c-0.14-0.13-0.17-0.14-0.3-0.02c-0.36,0.34-0.73,0.67-1.1,1
							c-0.22,0.2-0.44,0.39-0.66,0.59c-0.19,0.18-0.39,0.26-0.65,0.28c-0.53,0.03-1.07,0.07-1.59,0.11
							c-0.34,0.03-0.69,0.05-1.03,0.07c-0.32,0.02-0.51,0.14-0.63,0.41c-0.02,0.04-0.04,0.08-0.05,0.12
							c-0.09,0.21-0.2,0.45-0.43,0.62l-0.03,0.02c-0.1,0.04-0.2,0.08-0.3,0.13c-0.27,0.12-0.55,0.24-0.87,0.27
							c-0.35,0.03-0.76,0.07-1.04-0.34c-0.04-0.05-0.11-0.12-0.22-0.14l-0.04-0.01l-0.19-0.16l-0.01-0.04c-0.15-0.53-0.47-1-1-1.49
							c-0.11-0.1-0.2-0.1-0.43-0.01c-0.72,0.3-1.41,0.7-2.08,1.09c-0.58,0.33-1.17,0.67-1.79,0.96c-0.25,0.11-0.48,0.21-0.75,0.21
							l-0.2,0l0.07-0.19c0.07-0.18,0.2-0.3,0.31-0.41c0.02-0.02,0.04-0.04,0.06-0.06c0.77-0.75,1.43-1.57,1.93-2.21
							c0.19-0.25,0.22-0.4,0.13-0.6c-0.14-0.27-0.13-0.57,0.02-0.87c0.06-0.12,0.06-0.18,0.05-0.19c0,0-0.05-0.07-0.4-0.03
							c-0.4,0.05-0.84,0.04-1.34-0.01c-0.3-0.03-0.49,0.07-0.66,0.35c-0.2,0.33-0.4,0.66-0.6,0.99c-0.33,0.55-0.68,1.13-1.01,1.7
							c-0.16,0.27-0.34,0.4-0.61,0.45c-0.28,0.05-0.56,0.11-0.84,0.17c-0.41,0.09-0.84,0.18-1.26,0.24
							c-0.29,0.04-0.36,0.13-0.36,0.42c0.01,0.58,0.01,1.16,0.01,1.73c0,0.31,0,0.61,0,0.92c0,0.03,0,0.06,0,0.1
							c0,0.15-0.01,0.29,0.07,0.41l0.02,0.04l-0.01,0.05c-0.04,0.32-0.25,0.46-0.46,0.61l-0.07,0.05c-0.35,0.25-0.67,0.54-0.99,0.82
							c-0.11,0.09-0.21,0.19-0.32,0.28c-0.02,0.02-0.03,0.03-0.05,0.05c-0.07,0.08-0.2,0.21-0.38,0.14
							c-0.21-0.08-0.2-0.27-0.19-0.38c0-0.03,0-0.07,0-0.09c-0.05-0.27,0.08-0.47,0.19-0.65c0.01-0.01,0.02-0.03,0.02-0.04
							c-0.01,0.01-0.03,0.02-0.05,0.04c-0.02,0.02-0.04,0.03-0.05,0.05c-1.21,0.98-2.46,1.85-3.86,2.79
							c-0.35,0.24-0.74,0.27-1.05,0.09c-0.63-0.37-1.08-0.07-1.6,0.36c-1.08,0.89-2.26,1.83-3.56,2.65
							c-0.28,0.17-0.58,0.33-0.94,0.46c-0.92,0.36-1.79,0.87-2.63,1.37c-0.33,0.2-0.68,0.4-1.02,0.59
							c-0.38,0.21-0.75,0.45-1.11,0.69c-0.16,0.11-0.33,0.21-0.49,0.32c-0.09,0.06-0.18,0.11-0.28,0.18l-0.34,0.22l-0.03-0.22
							c-0.04-0.36,0.2-0.51,0.32-0.58c0.21-0.13,0.38-0.31,0.56-0.5c0.23-0.25,0.47-0.5,0.81-0.64c0.02-0.01,0.03-0.02,0.04-0.03
							c0,0,0-0.01-0.01-0.01c-0.02,0-0.07,0.02-0.09,0.04l-0.01,0c-0.41,0.19-0.85,0.33-1.27,0.47c-0.41,0.13-0.84,0.27-1.24,0.46
							c-0.57,0.27-1.1,0.15-1.55,0.02c-0.11-0.03-0.21-0.06-0.31-0.09c-0.56-0.17-1.14-0.34-1.74-0.42c0,0-0.01,0-0.01,0
							c-0.01,0.05,0.02,0.17,0.03,0.25c0.04,0.25,0.11,0.6-0.19,0.72c-0.84,0.34-2.22,1.89-2.23,1.91l-0.03,0.02
							c-0.03,0.02-0.1,0.1-0.18,0.18c-3.49,3.44-4.29,3.66-4.6,3.48c-0.23-0.14-0.16-0.5-0.08-0.75c0.05-0.16,3.21-5.14,3.24-5.17
							c0.14-0.17,0.29-0.34,0.43-0.5c-0.05,0.03-0.11,0.06-0.16,0.09c-0.08,0.05-0.17,0.1-0.25,0.14c-0.94,0.5-1.98,0.9-3.07,1.19
							c-0.65,0.17-1.28,0.42-1.89,0.65c-0.27,0.1-0.55,0.21-0.83,0.31c-0.06,0.02-0.12,0.03-0.18,0.04c-0.02,0-0.04,0.01-0.06,0.01
							l-0.16,0.03l0-0.04c-0.45,0.26-1.92,1.69-3.12,2.86C422.86,749.16,422.17,749.77,421.88,749.77z M402.7,737.47
							c-0.08,0.08-0.15,0.15-0.22,0.22c0.13-0.07,0.26-0.15,0.39-0.23C402.81,737.46,402.76,737.47,402.7,737.47z M412.09,733.34
							c-0.27,0-1.04,0.15-3.19,1.18c-1.39,0.66-3.06,1.55-4.87,2.58c12.58-0.85,25.58-1.74,25.94-1.81l0.09-0.02
							c1.53-0.36,3.11-0.73,4.56-1.34l0.3-0.13c1.01-0.43,2.05-0.87,3.17-1.06c0.52-0.09,1.01-0.25,1.46-0.48
							c1.04-0.53,2.05-1.17,3.03-1.78c0.34-0.21,0.68-0.43,1.03-0.64c0.6-0.37,1.14-0.82,1.67-1.26c0.52-0.44,1.07-0.89,1.66-1.26
							c0.6-0.37,0.75-0.89,0.79-1.54c0.02-0.28,0.06-0.57,0.12-0.91c0.2-1.05,0.07-1.76-0.45-2.35c-0.08-0.09-0.13-0.2-0.18-0.3
							c-0.03-0.07-0.06-0.13-0.1-0.19c-0.19-0.28-0.15-0.56,0.12-0.82c0.11-0.1,0.21-0.21,0.31-0.31c0.16-0.16,0.33-0.33,0.51-0.49
							c0.21-0.18,0.32-0.46,0.29-0.74c-0.12-0.98,0.16-1.91,0.83-2.74c0.09-0.11,0.15-0.23,0.23-0.36c0.08-0.14,0.16-0.28,0.26-0.41
							c0.23-0.29,0.26-0.6,0.1-1.07c-0.04-0.11-0.07-0.22-0.11-0.32c-0.07-0.22-0.14-0.44-0.23-0.66c-0.12-0.31-0.24-0.62-0.37-0.94
							c0.04,0.64-0.22,1.07-0.8,1.31c-0.22,0.09-0.39,0.27-0.45,0.5c-0.16,0.55-0.54,0.92-0.93,1.25c-0.4,0.33-0.51,0.72-0.37,1.24
							c0.03,0.1,0.04,0.21,0.06,0.31c0.02,0.15,0.05,0.3,0.1,0.42c0.2,0.42-0.01,0.67-0.22,0.86c-0.62,0.58-0.8,1.2-0.58,1.95
							c0.33,1.12,0.4,2.04,0.22,2.9c0,0.01,0,0.04,0,0.06c0,0.08,0.01,0.25-0.17,0.32c-0.22,0.08-0.34-0.1-0.38-0.18
							c-0.01-0.02-0.03-0.06-0.04-0.06c-0.2-0.16-0.43-0.36-0.56-0.64c-0.17-0.36-0.48-0.78-0.95-0.75
							c-0.95,0.05-1.76-0.3-2.38-1.02c-0.12-0.14-0.24-0.21-0.36-0.22c-0.12-0.01-0.25,0.06-0.38,0.18
							c-0.49,0.46-1.02,0.56-1.63,0.31c-0.55-0.23-1.01-0.1-1.38,0.37c-0.05,0.06-0.11,0.12-0.16,0.17
							c-0.06,0.05-0.11,0.1-0.15,0.15c-0.56,0.82-1.26,1.07-2.24,0.82c-0.38-0.1-0.74-0.04-0.96,0.16c-0.22,0.19-0.32,0.5-0.27,0.9
							c0.04,0.36-0.12,0.59-0.45,0.65c-1.15,0.23-1.54,1.14-1.9,2.21c-0.03,0.09-0.01,0.17,0.09,0.28c0.27,0.33,0.41,0.49,0.38,0.68
							c-0.03,0.19-0.22,0.31-0.59,0.55c-0.04,0.03-0.08,0.05-0.12,0.07c-0.36,0.2-0.56,0.32-0.6,0.43
							c-0.04,0.11,0.03,0.31,0.19,0.71c0.11,0.28,0.16,0.58-0.27,0.84c-0.16,0.12-0.67,1.45-1.01,2.32
							c-0.48,1.24-0.71,1.79-0.84,1.93c-0.33,0.32-1.93,0.21-4.51,0c-1.1-0.09-2.24-0.18-3.11-0.2c-1.31-0.03-1.46,0.12-1.47,0.15
							c-0.05,0.25-0.47,0.22-1.49,0.14c-0.82-0.06-1.94-0.15-2.89-0.04c-0.57,0.07-2.07-0.24-2.81-0.93
							c-0.29-0.27-0.43-0.58-0.41-0.9c0-0.01,0-0.02,0-0.02C412.22,733.37,412.2,733.34,412.09,733.34z M498.12,700.69
							L498.12,700.69L498.12,700.69z M498.61,678.2c0.01,0.01,0.02,0.02,0.03,0.04c0.12,0.14,0.28,0.34,0.33,0.34c0,0,0,0,0,0
							c0.05,0,0.21-0.2,0.35-0.37c0.02-0.03,0.05-0.05,0.07-0.08c-0.01,0-0.03,0-0.04,0c-0.04,0-0.08,0-0.09,0
							c-0.17,0.02-0.34,0.04-0.51,0.06C498.7,678.19,498.66,678.2,498.61,678.2z M447.49,727.72l-0.05-0.1
							c-0.12-0.26,0.06-0.4,0.14-0.47l0.01-0.01c0.42-0.34,0.61-0.82,0.55-1.42c-0.07-0.78,0.11-1.48,0.52-2.07
							c0.35-0.5,0.27-1.04,0.19-1.6c-0.01-0.09-0.03-0.18-0.05-0.27c-0.07-0.38-0.13-0.77-0.05-1.17c0.1-0.45,0.31-0.86,0.51-1.25
							c0.06-0.12,0.12-0.24,0.18-0.36c0.21-0.44,0.28-0.79,0.2-1.14c-0.07-0.33,0.03-0.58,0.29-0.81c0.15-0.12,0.29-0.26,0.43-0.38
							c0.15-0.13,0.3-0.27,0.45-0.4c0.05-0.04,0.19-0.16,0.18-0.23c0-0.04-0.05-0.1-0.13-0.16c-0.11-0.08-0.17-0.17-0.18-0.27
							c-0.02-0.14,0.07-0.27,0.16-0.38c0.72-0.89,1.57-1.67,2.39-2.43c0.43-0.4,0.88-0.81,1.3-1.22c0.08-0.08,0.2-0.2,0.43-0.16
							l0.16,0.03l-0.07,0.15c-0.53,1.25-1.29,2.35-2.03,3.41l-0.12,0.17c-0.2,0.29-0.3,0.62-0.34,1.09
							c-0.05,0.64-0.08,1.28-0.1,1.77c-0.01,0.22-0.08,0.42-0.22,0.62c-0.4,0.56-0.79,1.13-1.18,1.69c-0.27,0.38-0.53,0.76-0.8,1.15
							c-0.12,0.17-0.17,0.33-0.17,0.51c0,0.11,0,0.22,0,0.32c-0.01,0.15-0.01,0.3,0.01,0.44l0,0c0.09,0.7-0.08,1.34-0.52,1.89
							c-0.44,0.55-0.51,1.21-0.55,1.89c-0.02,0.33-0.1,0.65-0.55,0.81c-0.11,0.04-0.22,0.09-0.34,0.14
							c-0.19,0.09-0.38,0.18-0.6,0.22L447.49,727.72z M454.7,711.84c-0.4,0.4-0.82,0.78-1.23,1.16c-0.81,0.75-1.66,1.52-2.37,2.4
							c-0.07,0.09-0.1,0.14-0.1,0.18c0,0.02,0.03,0.05,0.07,0.08c0.16,0.12,0.24,0.24,0.24,0.37c0.01,0.18-0.13,0.32-0.28,0.45
							c-0.15,0.13-0.3,0.26-0.45,0.39c-0.14,0.13-0.29,0.26-0.44,0.39c-0.19,0.16-0.25,0.32-0.2,0.55c0.08,0.41,0.01,0.82-0.23,1.31
							c-0.06,0.12-0.12,0.24-0.19,0.36c-0.2,0.38-0.4,0.76-0.49,1.18c-0.08,0.35-0.02,0.7,0.05,1.07c0.02,0.09,0.03,0.18,0.05,0.28
							c0.09,0.59,0.18,1.21-0.23,1.8c-0.38,0.55-0.54,1.16-0.47,1.89c0.06,0.7-0.16,1.26-0.65,1.66l-0.01,0.01c0,0,0,0,0,0
							c0.11-0.04,0.21-0.09,0.32-0.14c0.12-0.05,0.24-0.11,0.36-0.15c0.25-0.09,0.34-0.23,0.36-0.57c0.05-0.73,0.13-1.44,0.61-2.05
							c0.39-0.49,0.55-1.06,0.47-1.68l0,0c-0.02-0.16-0.02-0.32-0.01-0.48c0-0.11,0.01-0.2,0-0.3c-0.01-0.24,0.06-0.45,0.21-0.68
							c0.27-0.38,0.53-0.76,0.8-1.15c0.38-0.55,0.78-1.13,1.18-1.69c0.11-0.16,0.16-0.3,0.17-0.47c0.02-0.49,0.06-1.13,0.1-1.77
							c0.04-0.51,0.16-0.89,0.39-1.22l0.12-0.17C453.51,713.91,454.19,712.93,454.7,711.84z M477.99,723.72l0.23-0.5
							c0.31-0.66,0.65-1.07,1.05-1.28c0.66-0.34,1.01-0.78,1.16-1.43c0.11-0.49,0.38-0.81,0.83-0.99c0.6-0.23,1-0.71,1.42-1.21
							c0.07-0.08,0.13-0.16,0.2-0.24c0.01-0.02,0.03-0.04,0.05-0.06c0.06-0.09,0.18-0.27,0.41-0.19c0.18,0.06,0.19,0.23,0.2,0.33
							c0,0.03,0.01,0.06,0.01,0.08c0.03,0.09,0.04,0.19,0.06,0.28c0.06,0.36,0.12,0.57,0.48,0.63c0.14,0.02,0.17,0.13,0.18,0.16
							c0.03,0.15-0.14,0.28-0.2,0.32l0,0c-0.67,0.38-1.28,0.87-1.87,1.34c-0.44,0.36-0.9,0.72-1.38,1.04
							c-0.35,0.23-0.73,0.43-1.09,0.61c-0.44,0.22-0.9,0.46-1.3,0.76L477.99,723.72z M483.24,718.07
							C483.24,718.07,483.24,718.07,483.24,718.07c-0.02,0.01-0.05,0.06-0.07,0.09c-0.02,0.03-0.04,0.06-0.07,0.09
							c-0.07,0.08-0.13,0.16-0.2,0.24c-0.42,0.51-0.86,1.03-1.53,1.29c-0.37,0.14-0.57,0.38-0.66,0.79
							c-0.16,0.73-0.57,1.24-1.3,1.61c-0.24,0.12-0.46,0.35-0.67,0.67c0.29-0.17,0.58-0.33,0.87-0.47c0.36-0.18,0.73-0.37,1.06-0.6
							c0.47-0.32,0.92-0.68,1.36-1.03c0.6-0.48,1.22-0.98,1.9-1.37c-0.47-0.13-0.53-0.52-0.59-0.83c-0.02-0.09-0.03-0.17-0.05-0.25
							c-0.01-0.04-0.02-0.09-0.02-0.13c0-0.03-0.01-0.09-0.02-0.1C483.25,718.07,483.24,718.07,483.24,718.07z M433.02,723.3
							c-0.03,0-0.06-0.01-0.08-0.01c-0.02,0-0.03-0.01-0.06-0.01l-0.13-0.02l0.04-0.24c0.19-1.1,0.39-2.24,0.99-3.26
							c0.2-0.34,0.37-0.65,0.5-0.95c0.07-0.17,0.17-0.34,0.35-0.4c0.13-0.04,0.28-0.01,0.46,0.09c0.11,0.06,0.16,0.06,0.16,0.06
							c0,0,0.02-0.04,0-0.16c-0.18-1.23,0.2-2.32,1.17-3.34c0.51-0.54,0.89-1.19,1.21-1.75c0.01-0.01,0.02-0.04,0.02-0.07
							c0.02-0.08,0.09-0.3,0.33-0.25c0.22,0.05,0.23,0.26,0.23,0.37c0,0.02,0,0.04,0,0.06c0.08,0.77,0.19,1.68,0.43,2.58
							c0.05,0.18,0.12,0.35,0.23,0.55c0.72,1.32,0.68,2.73,0.57,4.07c-0.03,0.36-0.22,0.53-0.57,0.51c-0.12-0.01-0.25,0-0.38,0.01
							c-0.09,0.01-0.19,0.02-0.28,0.02c-0.78,0.01-1.17,0.55-1.58,1.11c-0.07,0.1-0.14,0.19-0.21,0.28
							c-0.21,0.28-0.44,0.44-0.86,0.33c-0.89-0.23-1.65-0.12-2.32,0.35c-0.05,0.03-0.1,0.06-0.17,0.07
							C433.05,723.3,433.04,723.3,433.02,723.3z M434.76,718.66c-0.01,0-0.03,0-0.04,0.01c-0.06,0.02-0.12,0.1-0.19,0.25
							c-0.14,0.31-0.3,0.63-0.51,0.98c-0.57,0.96-0.76,2-0.95,3.11c0.01,0,0.01-0.01,0.01-0.01c0.73-0.51,1.59-0.64,2.55-0.39
							c0.26,0.07,0.39,0.02,0.58-0.23c0.07-0.09,0.14-0.18,0.2-0.28c0.43-0.6,0.88-1.21,1.79-1.23c0.09,0,0.18-0.01,0.27-0.02
							c0.14-0.01,0.28-0.02,0.42-0.01c0.21,0.01,0.27-0.04,0.29-0.26v0c0.1-1.29,0.14-2.66-0.54-3.91c-0.12-0.22-0.2-0.41-0.26-0.61
							c-0.25-0.92-0.36-1.84-0.44-2.62c0-0.03,0-0.05,0-0.08c0-0.03,0-0.08-0.01-0.1c-0.01,0.02-0.01,0.04-0.02,0.06
							c-0.01,0.04-0.02,0.08-0.05,0.12c-0.32,0.58-0.72,1.24-1.25,1.81c-0.9,0.95-1.26,1.97-1.1,3.11c0.03,0.21-0.01,0.36-0.12,0.43
							c-0.11,0.08-0.26,0.06-0.45-0.05C434.87,718.68,434.81,718.66,434.76,718.66z M437.92,713.25 M467.17,718.67
							c-0.01,0-0.02,0-0.02,0l-0.25-0.01l0.14-0.21c0.14-0.21,0.29-0.41,0.43-0.6c0.3-0.41,0.58-0.8,0.78-1.21
							c0.5-1.04,1.43-1.42,2.32-1.78c0.18-0.07,0.36-0.15,0.54-0.23c0.33-0.15,0.67-0.28,1-0.41c0.54-0.21,1.1-0.43,1.62-0.71
							c0.4-0.22,0.77-0.55,1.12-0.86c0.16-0.14,0.32-0.29,0.49-0.42c0.24-0.19,0.47-0.4,0.7-0.61c0.58-0.52,1.18-1.05,1.9-1.42
							c0.05-0.03,0.1-0.06,0.16-0.09c0.22-0.13,0.48-0.28,0.87-0.27l0.34,0l-0.24,0.23c-0.07,0.07-0.13,0.12-0.19,0.18
							c-0.12,0.11-0.22,0.21-0.31,0.3c-0.01,0.02-0.03,0.03-0.05,0.05c-0.03,0.03-0.07,0.07-0.08,0.09c0,0,0,0.01,0.01,0.01
							c0.01,0,0.07-0.03,0.11-0.08c0.48-0.52,1.1-0.6,1.7-0.68c0.08-0.01,0.15-0.02,0.23-0.03c0.08-0.01,0.25-0.04,0.36,0.18
							l0.06,0.11l-0.11,0.06c-0.16,0.09-0.32,0.18-0.47,0.28c-0.35,0.21-0.71,0.42-1.09,0.61c-0.78,0.39-1.43,0.97-2.01,1.49
							c-0.12,0.1-0.23,0.21-0.35,0.31c-0.28,0.24-0.56,0.49-0.82,0.75c-0.51,0.52-1.14,0.74-1.69,0.89l-0.21,0.05
							c-0.58,0.15-1.18,0.31-1.78,0.44c-0.56,0.12-0.98,0.47-1.42,0.83c-0.17,0.15-0.36,0.3-0.54,0.43
							c-0.66,0.47-1.31,0.98-1.94,1.48c-0.28,0.22-0.55,0.44-0.83,0.65c-0.01,0.01-0.02,0.02-0.03,0.03
							C467.5,718.58,467.4,718.67,467.17,718.67z M478.51,710.2c-0.1,0.04-0.2,0.1-0.29,0.15c-0.06,0.03-0.11,0.06-0.17,0.09
							c-0.69,0.35-1.27,0.87-1.84,1.38c-0.23,0.2-0.47,0.41-0.71,0.61c-0.16,0.13-0.32,0.28-0.48,0.42
							c-0.36,0.33-0.74,0.66-1.17,0.9c-0.53,0.29-1.1,0.51-1.65,0.73c-0.33,0.13-0.67,0.26-0.99,0.41
							c-0.18,0.08-0.37,0.16-0.55,0.23c-0.89,0.36-1.72,0.7-2.18,1.65c-0.21,0.44-0.5,0.84-0.81,1.26
							c-0.04,0.05-0.08,0.11-0.12,0.16c0.23-0.18,0.47-0.36,0.7-0.55c0.63-0.5,1.28-1.01,1.95-1.48c0.18-0.13,0.36-0.27,0.53-0.42
							c0.44-0.37,0.91-0.75,1.53-0.89c0.59-0.13,1.19-0.29,1.77-0.44l0.21-0.05c0.52-0.14,1.11-0.34,1.57-0.81l0,0
							c0.26-0.27,0.55-0.53,0.83-0.77c0.12-0.1,0.23-0.2,0.35-0.31c0.59-0.53,1.25-1.13,2.07-1.53c0.36-0.18,0.72-0.39,1.07-0.6
							c0.06-0.03,0.11-0.07,0.17-0.1c-0.02,0-0.03,0-0.05,0.01c-0.58,0.07-1.12,0.14-1.53,0.59c-0.04,0.04-0.17,0.17-0.32,0.17
							c0,0,0,0,0,0c-0.08,0-0.14-0.03-0.2-0.09c-0.19-0.21,0.01-0.41,0.09-0.48c0.01-0.01,0.03-0.03,0.04-0.04
							C478.4,710.31,478.45,710.25,478.51,710.2z M453.33,718.22c-0.01,0-0.02,0-0.02,0c-0.07-0.01-0.12-0.04-0.16-0.09
							c-0.05-0.06-0.06-0.14-0.05-0.24c0.03-0.21,0.05-0.41,0.08-0.62c0.06-0.54,0.13-1.1,0.26-1.64c0.07-0.29,0.29-0.52,0.51-0.75
							c0.06-0.06,0.11-0.12,0.16-0.18c0.29-0.33,0.55-0.67,0.61-1.14c0.07-0.52,0.38-0.92,0.69-1.3c0.05-0.06,0.09-0.11,0.13-0.17
							c0.15-0.2,0.19-0.34,0.14-0.55l0,0c-0.14-0.63,0.03-1.14,0.5-1.52c0.32-0.26,0.64-0.54,0.95-0.81
							c0.15-0.13,0.31-0.27,0.46-0.4c0.17-0.15,0.24-0.27,0.23-0.43c-0.05-0.85,0.26-1.63,0.94-2.39c0.34-0.37,0.54-0.72,0.45-1.18
							c-0.08-0.41,0.17-0.67,0.38-0.89c0.05-0.05,0.1-0.11,0.14-0.16c0.6-0.73,1.23-1.46,1.84-2.16l0.43-0.5
							c0.01-0.02,0.03-0.03,0.04-0.05c0.07-0.09,0.19-0.25,0.39-0.18c0.23,0.08,0.18,0.3,0.16,0.39c-0.01,0.03-0.01,0.05-0.01,0.07
							c0,0.04,0,0.07-0.01,0.11c0,0.03,0,0.05-0.01,0.08c-0.01,0.1-0.01,0.25,0,0.31c0.06,0,0.21-0.05,0.32-0.08
							c0.03-0.01,0.07-0.02,0.1-0.04c0.13-0.05,0.29-0.11,0.5-0.04l0.21,0.07l-0.16,0.16c-0.24,0.23-0.53,0.39-0.81,0.55
							c-0.39,0.21-0.75,0.41-0.96,0.78c-0.2,0.35-0.3,0.75-0.41,1.17c-0.04,0.15-0.08,0.31-0.13,0.47c0.1-0.1,0.19-0.19,0.27-0.29
							l0.05-0.06c0.11-0.13,0.22-0.26,0.33-0.39c0.35-0.41,0.7-0.84,1.13-1.2c0.02-0.01,0.03-0.03,0.05-0.04
							c0.08-0.08,0.27-0.27,0.48-0.07c0.07,0.06,0.08,0.13,0.08,0.18c0,0.11-0.08,0.19-0.14,0.25c-0.02,0.02-0.04,0.04-0.05,0.05
							l-0.26,0.37c-0.64,0.9-1.3,1.83-1.95,2.74c-0.14,0.19-0.13,0.4-0.12,0.64c0,0.07,0.01,0.14,0.01,0.21c0,0.25,0,0.59-0.31,0.84
							c-0.7,0.57-0.92,1.28-0.66,2.17c0.12,0.39,0,0.7-0.36,0.96c-0.89,0.65-1.81,1.32-2.57,2.13c-0.81,0.87-1.52,1.86-2.21,2.82
							c-0.19,0.27-0.39,0.54-0.58,0.8c-0.25,0.34-0.52,0.7-0.85,1.13C453.53,718.13,453.44,718.22,453.33,718.22z M453.31,717.95
							L453.31,717.95C453.31,717.95,453.31,717.95,453.31,717.95z M462.34,701.12c-0.02,0.01-0.05,0.06-0.07,0.09
							c-0.02,0.02-0.03,0.05-0.05,0.07l-0.43,0.5c-0.61,0.7-1.24,1.43-1.84,2.16c-0.05,0.06-0.1,0.12-0.15,0.17
							c-0.19,0.2-0.36,0.38-0.31,0.65c0.12,0.61-0.18,1.04-0.52,1.42c-0.64,0.71-0.92,1.4-0.87,2.19c0.02,0.31-0.17,0.52-0.33,0.65
							c-0.15,0.13-0.31,0.27-0.46,0.4c-0.31,0.27-0.63,0.55-0.96,0.82c-0.39,0.31-0.52,0.72-0.41,1.24v0
							c0.07,0.29,0.01,0.52-0.19,0.78c-0.04,0.06-0.09,0.11-0.14,0.17c-0.3,0.37-0.58,0.72-0.63,1.17
							c-0.07,0.55-0.37,0.94-0.68,1.29c-0.05,0.06-0.11,0.12-0.17,0.19c-0.19,0.2-0.39,0.41-0.44,0.63
							c-0.13,0.52-0.19,1.07-0.25,1.6c-0.02,0.2-0.05,0.4-0.07,0.6c0.32-0.42,0.58-0.77,0.83-1.11c0.19-0.27,0.39-0.53,0.58-0.8
							c0.69-0.96,1.41-1.96,2.23-2.85c0.78-0.83,1.71-1.51,2.61-2.16c0.27-0.19,0.34-0.38,0.25-0.66c-0.29-0.99-0.04-1.82,0.75-2.46
							c0.19-0.16,0.21-0.36,0.21-0.63c0-0.06,0-0.13-0.01-0.19c-0.01-0.25-0.03-0.54,0.17-0.81c0.66-0.91,1.32-1.84,1.95-2.74
							l0.26-0.37c0.01-0.02,0.02-0.03,0.04-0.04c-0.02,0.02-0.04,0.04-0.06,0.05c-0.41,0.34-0.76,0.76-1.1,1.16
							c-0.11,0.13-0.22,0.26-0.33,0.39l-0.05,0.06c-0.16,0.19-0.34,0.4-0.67,0.6l-0.33,0.2l0.13-0.36c0.11-0.29,0.18-0.58,0.26-0.86
							c0.12-0.44,0.23-0.85,0.44-1.24c0.25-0.44,0.66-0.67,1.06-0.89c0.11-0.06,0.21-0.12,0.32-0.18c-0.25,0.07-0.4,0.11-0.51,0.02
							c-0.12-0.1-0.11-0.27-0.1-0.54c0-0.03,0-0.06,0.01-0.09c0-0.03,0.01-0.06,0.01-0.09c0-0.04,0.01-0.09,0.02-0.12
							C462.33,701.17,462.34,701.14,462.34,701.12z M443.02,718l-0.4-0.01v-0.13c0-0.18,0-0.37-0.01-0.55
							c-0.01-0.41-0.02-0.84,0.02-1.26c0.07-0.58-0.03-1.12-0.3-1.59c-0.78-1.38-0.67-2.85-0.56-4.28c0.01-0.15,0.02-0.3,0.03-0.45
							c0.03-0.44,0.34-0.75,0.61-1.03c0.06-0.06,0.11-0.12,0.17-0.18c0.15-0.17,0.32-0.32,0.49-0.47c0.08-0.07,0.16-0.15,0.24-0.22
							c0.01-0.01,0.02-0.02,0.03-0.04c0.06-0.06,0.16-0.18,0.32-0.13c0.22,0.07,0.21,0.29,0.2,0.38l0,0.03
							c-0.01,0.15-0.01,0.31-0.02,0.47c-0.01,0.36-0.02,0.74-0.1,1.09c-0.23,0.98,0,1.9,0.27,2.83c0.19,0.63,0.2,1.13,0.06,1.67
							c-0.12,0.44-0.22,0.89-0.32,1.32c-0.16,0.74-0.33,1.51-0.61,2.25c-0.02,0.05-0.04,0.1-0.06,0.15L443.02,718z M443.6,707.93
							c-0.01,0.01-0.03,0.03-0.04,0.04c-0.02,0.02-0.03,0.04-0.05,0.05c-0.08,0.08-0.16,0.15-0.25,0.23
							c-0.16,0.14-0.33,0.29-0.47,0.45c-0.06,0.06-0.11,0.12-0.17,0.18c-0.26,0.27-0.51,0.52-0.53,0.86
							c-0.01,0.15-0.02,0.3-0.03,0.46c-0.11,1.45-0.21,2.82,0.52,4.13c0.29,0.52,0.4,1.11,0.33,1.75c-0.04,0.4-0.03,0.82-0.02,1.23
							c0,0.1,0.01,0.2,0.01,0.3c0.27-0.71,0.44-1.47,0.6-2.2c0.1-0.44,0.2-0.9,0.32-1.34l0.13,0.04l-0.13-0.04
							c0.13-0.49,0.12-0.94-0.05-1.52c-0.29-0.97-0.53-1.92-0.28-2.97c0.08-0.33,0.09-0.67,0.1-1.04c0-0.16,0.01-0.32,0.02-0.48
							l0-0.04C443.6,707.99,443.61,707.94,443.6,707.93z M480.01,717.39l0.57-0.7c0.22-0.27,0.44-0.54,0.66-0.81
							c0.61-0.75,1.33-1.36,2.15-1.8c0.32-0.18,0.42-0.47,0.52-0.8c0.01-0.04,0.02-0.07,0.04-0.11c-0.01,0.01-0.01,0.01-0.02,0.02
							l-0.25,0.17c-0.81,0.55-1.65,1.13-2.37,1.82c-0.01,0.01-0.03,0.03-0.04,0.05c-0.06,0.07-0.17,0.21-0.34,0.15
							c-0.23-0.07-0.2-0.28-0.19-0.37c0-0.03,0.01-0.05,0.01-0.07c0-0.09,0-0.18,0-0.27c0-0.14,0-0.27-0.01-0.4
							c-0.16-1.1,0.37-1.91,0.96-2.62c0.22-0.26,0.44-0.54,0.66-0.82c-0.15,0.14-0.3,0.27-0.51,0.34l-0.1,0.11l-0.22-0.22l0.11-0.1
							c0.31-0.81,0.72-1.75,1.33-2.57c0.32-0.43,0.89-0.53,1.39-0.61l0.08-0.01c0.19-0.03,0.28,0.12,0.33,0.2l0.01,0.01
							c0.17,0.29,0.37,0.58,0.55,0.85c0.34,0.51,0.69,1.03,0.96,1.6c0.1,0.21,0.21,0.54-0.05,0.86c-0.54,0.67-0.88,1.5-1.09,2.59v0
							c-0.03,0.17-0.13,0.3-0.22,0.43l-0.02,0.02c-0.91,1.22-1.98,2.04-3.26,2.48c-0.16,0.06-0.31,0.12-0.47,0.2
							c-0.23,0.1-0.47,0.21-0.75,0.29L480.01,717.39z M484,712.85c0.04,0,0.08,0.01,0.12,0.04c0.1,0.06,0.13,0.22,0.1,0.31
							c-0.02,0.05-0.03,0.1-0.05,0.16c-0.11,0.36-0.23,0.73-0.65,0.96c-0.78,0.42-1.48,1.01-2.07,1.73
							c-0.22,0.27-0.44,0.54-0.66,0.8c0.09-0.04,0.17-0.07,0.26-0.11c0.16-0.07,0.33-0.15,0.5-0.21c1.23-0.43,2.25-1.21,3.13-2.39
							l0.02-0.02c0.08-0.1,0.15-0.2,0.17-0.32l0,0c0.22-1.14,0.58-2,1.15-2.71c0.13-0.16,0.13-0.32,0.02-0.57
							c-0.26-0.55-0.6-1.06-0.94-1.56c-0.19-0.28-0.38-0.57-0.56-0.86l-0.01-0.02c-0.02-0.03-0.04-0.07-0.05-0.08l-0.08,0.02
							c-0.47,0.08-0.96,0.17-1.22,0.51c-0.53,0.7-0.9,1.5-1.19,2.23c0.07-0.06,0.14-0.12,0.22-0.19c0.19-0.18,0.41-0.38,0.74-0.49
							l0.42-0.13l-0.27,0.34c-0.15,0.18-0.28,0.36-0.41,0.52c-0.27,0.35-0.53,0.67-0.79,0.99c-0.55,0.66-1.04,1.41-0.9,2.4
							c0.02,0.15,0.02,0.3,0.02,0.44c0,0.09,0,0.17,0,0.26c0,0.04,0,0.08-0.01,0.11c0,0.02-0.01,0.06-0.01,0.07
							c0.02-0.01,0.04-0.04,0.06-0.06c0.02-0.03,0.04-0.05,0.06-0.07c0.74-0.71,1.59-1.29,2.41-1.85l0.25-0.17
							c0.01-0.01,0.02-0.01,0.03-0.02C483.84,712.9,483.91,712.85,484,712.85z M488.79,717.03l-0.11-0.2
							c-0.13-0.24-0.16-0.47-0.18-0.68c-0.01-0.09-0.02-0.17-0.04-0.25c-0.01-0.07-0.01-0.14-0.01-0.2c0-0.06,0-0.11-0.01-0.15
							c-0.3-0.99,0.24-1.67,0.74-2.15c0.35-0.34,0.66-0.74,0.95-1.12c0.09-0.11,0.17-0.22,0.26-0.33c0.22-0.28,0.51-0.48,0.78-0.66
							c0.07-0.04,0.13-0.09,0.19-0.13c0.08-0.06,0.17-0.12,0.25-0.18c0.17-0.12,0.35-0.25,0.53-0.37c-0.14,0.07-0.29,0.15-0.44,0.2
							c-0.41,0.13-0.79,0.33-1.21,0.56c-0.3,0.17-0.52,0.43-0.74,0.72c-0.11,0.14-0.23,0.29-0.36,0.42
							c-0.33,0.34-0.64,0.71-0.94,1.07c-0.18,0.21-0.36,0.43-0.55,0.64c-0.4,0.45-0.92,0.73-1.42,1c-0.2,0.11-0.41,0.22-0.61,0.34
							l-0.46,0.28l0.27-0.46c0.06-0.11,0.12-0.22,0.18-0.32c0.13-0.24,0.26-0.49,0.43-0.71c0.78-1.01,1.21-2.16,1.55-3.25
							c0.22-0.7,0.69-1.17,1.12-1.54c1-0.86,1.97-1.69,2.96-2.49c0.57-0.46,1.01-1.06,1.43-1.63c0.12-0.16,0.24-0.33,0.37-0.49
							c0.54-0.71,1.08-1.44,1.6-2.15c0.27-0.37,0.54-0.73,0.81-1.1c0.09-0.13,0.2-0.25,0.29-0.36c0.04-0.05,0.09-0.11,0.13-0.16
							l0.18-0.22l0.06,0.28c0.12,0.58,0.2,1.15-0.06,1.72c-0.19,0.43-0.36,0.88-0.53,1.31c-0.12,0.32-0.24,0.65-0.38,0.97
							c-0.14,0.33-0.08,0.58,0.18,0.83c0.18,0.18,0.36,0.37,0.54,0.55c0.31,0.32,0.63,0.66,0.98,0.95c0.17,0.14,0.24,0.26,0.24,0.39
							c0,0.16-0.12,0.29-0.25,0.41l-0.09-0.1l0.09,0.1c-0.22,0.21-0.45,0.42-0.66,0.63c-0.27,0.26-0.54,0.52-0.83,0.78
							c-0.51,0.46-0.89,1.04-1.14,1.76c-0.15,0.42-0.42,0.71-0.86,0.9c-0.43,0.19-0.87,0.37-1.29,0.54c-0.54,0.22-1.1,0.45-1.64,0.7
							c-0.43,0.2-0.73,0.49-0.95,0.92c-0.29,0.56-0.59,1.12-0.88,1.66l-0.14,0.27c-0.04,0.07-0.09,0.14-0.14,0.22
							c-0.03,0.04-0.05,0.08-0.09,0.13L488.79,717.03z M493.3,710.04l-0.31,0.25c-0.39,0.32-0.81,0.61-1.21,0.9
							c-0.08,0.06-0.17,0.12-0.25,0.18c-0.07,0.05-0.13,0.09-0.2,0.14c-0.27,0.18-0.52,0.36-0.72,0.61
							c-0.09,0.11-0.17,0.22-0.26,0.33c-0.3,0.39-0.61,0.8-0.98,1.15c-0.67,0.65-0.87,1.21-0.67,1.88c0.02,0.08,0.02,0.16,0.02,0.24
							c0,0.05,0,0.1,0,0.14c0.02,0.09,0.03,0.19,0.04,0.28c0.01,0.12,0.03,0.23,0.06,0.35c0.03-0.04,0.05-0.08,0.07-0.12l0.14-0.27
							c0.29-0.54,0.59-1.1,0.88-1.66c0.25-0.48,0.59-0.82,1.08-1.04c0.54-0.25,1.11-0.48,1.65-0.7c0.42-0.17,0.86-0.35,1.28-0.54
							c0.37-0.16,0.59-0.39,0.71-0.74c0.27-0.76,0.66-1.37,1.21-1.87c0.28-0.25,0.55-0.52,0.82-0.77c0.22-0.21,0.44-0.42,0.67-0.63
							v0c0.08-0.08,0.16-0.16,0.16-0.2c0-0.02-0.02-0.08-0.15-0.19c-0.35-0.3-0.68-0.64-0.99-0.97c-0.17-0.18-0.35-0.36-0.53-0.54
							c-0.34-0.34-0.42-0.7-0.24-1.14c0.13-0.32,0.25-0.64,0.37-0.96c0.16-0.44,0.34-0.89,0.53-1.33c0.19-0.41,0.17-0.83,0.09-1.27
							c-0.08,0.09-0.16,0.19-0.23,0.29c-0.27,0.36-0.54,0.73-0.81,1.1c-0.52,0.71-1.06,1.44-1.6,2.15
							c-0.12,0.16-0.24,0.32-0.36,0.49c-0.43,0.59-0.88,1.19-1.48,1.68c-0.98,0.8-1.96,1.63-2.95,2.49
							c-0.42,0.36-0.84,0.78-1.04,1.42c-0.34,1.06-0.79,2.29-1.6,3.33c-0.14,0.18-0.25,0.38-0.36,0.58c0.07-0.04,0.14-0.07,0.2-0.11
							c0.5-0.27,0.98-0.52,1.35-0.93c0.18-0.21,0.36-0.43,0.54-0.64c0.3-0.36,0.62-0.74,0.96-1.09c0.12-0.12,0.23-0.26,0.34-0.4
							c0.23-0.29,0.47-0.59,0.83-0.78c0.42-0.23,0.83-0.44,1.26-0.58c0.15-0.05,0.29-0.12,0.43-0.2c0.25-0.13,0.52-0.27,0.84-0.28
							L493.3,710.04z M484.46,707.08l-0.29-0.23l0.08-0.11c0.17-0.21,0.31-0.45,0.45-0.68c0.1-0.17,0.21-0.36,0.33-0.53
							c0.3-0.42,0.46-0.85,0.51-1.35c0.11-1.11,0.33-2.26,1.15-3.22c0.25-0.29,0.46-0.63,0.66-0.95c0.06-0.1,0.13-0.21,0.19-0.31
							c0.19-0.29,0.3-0.56,0.25-0.88c-0.09-0.54-0.18-1.1-0.26-1.63c-0.09-0.6-0.19-1.21-0.29-1.82c-0.06-0.33,0-0.58,0.19-0.79l0,0
							c0.82-0.94,1.73-1.8,2.62-2.64l0.31-0.29c0.14-0.14,0.29-0.27,0.44-0.41c0.08-0.07,0.15-0.14,0.23-0.21
							c0.05-0.05,0.21-0.19,0.37-0.13c0.17,0.06,0.2,0.27,0.22,0.34c0.09,0.54,0.19,1.07,0.29,1.61c0.07,0.37-0.04,0.68-0.32,0.94
							c-0.26,0.24-0.51,0.49-0.76,0.74c-0.19,0.19-0.38,0.38-0.57,0.57c-0.16,0.15-0.18,0.25-0.09,0.44
							c0.18,0.4,0.36,0.82,0.53,1.22c0.2,0.47,0.41,0.97,0.64,1.44c0.22,0.46,0.16,0.79-0.19,1.15c-0.73,0.75-1.46,1.52-2.16,2.27
							c-0.31,0.33-0.62,0.67-0.93,1c-0.11,0.11-0.16,0.26-0.21,0.42l-0.03,0.1c-0.19,0.54-0.38,1.09-0.56,1.64
							c-0.11,0.34-0.31,0.6-0.62,0.81c-0.5,0.34-1.01,0.69-1.5,1.04c-0.2,0.14-0.4,0.28-0.6,0.42L484.46,707.08z M487.65,694.76
							L487.65,694.76c-0.13,0.15-0.17,0.31-0.12,0.56c0.1,0.61,0.2,1.22,0.29,1.82c0.08,0.53,0.17,1.09,0.26,1.63
							c0.07,0.44-0.09,0.77-0.29,1.08c-0.06,0.1-0.13,0.2-0.19,0.31c-0.21,0.34-0.42,0.68-0.68,0.99c-0.77,0.9-0.98,2.01-1.09,3.07
							c-0.06,0.55-0.23,1.02-0.56,1.48c-0.11,0.16-0.22,0.34-0.32,0.51c-0.07,0.12-0.15,0.24-0.23,0.37
							c0.09-0.06,0.18-0.13,0.27-0.19c0.49-0.34,1-0.7,1.5-1.04c0.26-0.17,0.42-0.38,0.51-0.67c0.18-0.55,0.37-1.1,0.56-1.64
							l0.03-0.1c0.06-0.18,0.12-0.36,0.27-0.52c0.31-0.33,0.62-0.66,0.93-1c0.7-0.75,1.43-1.53,2.16-2.28
							c0.27-0.28,0.31-0.48,0.14-0.84c-0.22-0.48-0.43-0.97-0.64-1.45c-0.17-0.4-0.35-0.81-0.53-1.21c-0.14-0.3-0.09-0.52,0.15-0.75
							c0.19-0.18,0.38-0.38,0.57-0.56c0.25-0.25,0.5-0.5,0.76-0.74c0.21-0.2,0.28-0.41,0.23-0.69c-0.1-0.54-0.19-1.07-0.29-1.61
							c-0.01-0.07-0.03-0.11-0.04-0.12c-0.02,0.01-0.05,0.02-0.1,0.07c-0.08,0.07-0.15,0.14-0.23,0.21
							c-0.15,0.13-0.29,0.27-0.44,0.41l-0.31,0.29C489.37,692.97,488.46,693.83,487.65,694.76z M473.01,706.83
							c-0.03,0-0.06,0-0.09-0.01l-0.18-0.02l0.07-0.17c0.13-0.3,0.25-0.59,0.38-0.88c0.29-0.7,0.57-1.35,0.89-2.01l0,0
							c0.23-0.48,0.63-0.83,1.02-1.16c0.13-0.11,0.26-0.22,0.38-0.33c0.42-0.4,0.6-0.74,0.61-1.14c0.01-0.96,0.6-1.59,1.25-2.19
							c0.05-0.05,0.09-0.1,0.14-0.17c0.12-0.16,0.26-0.34,0.53-0.37l0.14-0.02l0.01,0.14c0.01,0.08,0.02,0.15,0.02,0.23
							c0.02,0.17,0.04,0.35,0.05,0.52c0.01,0.09,0.01,0.19,0.01,0.28c0.53-1.83,1.6-3.32,2.69-4.66l0.35-0.43l-0.11,0.55
							c-0.11,0.56-0.22,1.09-0.18,1.68c0.5-1,0.76-1.9,0.81-2.81c0.03-0.46,0.08-0.93,0.12-1.38c0.03-0.24,0.05-0.48,0.07-0.71
							c0.02-0.19,0.06-0.4,0.23-0.54c0.25-0.22,0.51-0.45,0.79-0.68c0.12-0.1,0.24-0.2,0.36-0.31l0.24-0.21l-0.02,0.32
							c-0.03,0.47-0.22,0.8-0.38,1.08l-0.01,0.01c-0.21,0.36-0.21,0.75-0.21,1.16c0,0.08,0,0.15,0,0.23c0,0.08,0.02,0.09,0.04,0.1
							c0.15,0.04,0.84-0.32,0.88-0.47l0.05-0.27c0.11-0.58,0.22-1.19,0.29-1.78c0.04-0.37,0.16-0.73,0.7-0.85
							c0.55-0.12,0.92-0.51,1.31-0.92c0.08-0.08,0.16-0.17,0.24-0.25c0.03-0.03,0.06-0.07,0.09-0.12c0.09-0.14,0.26-0.39,0.54-0.24
							c0.22,0.12,0.1,0.36,0.04,0.49c-0.03,0.06-0.06,0.11-0.06,0.15c-0.09,0.48-0.2,0.96-0.3,1.42l-0.05,0.23
							c-0.06,0.26,0,0.52,0.18,0.81c0.06,0.1,0.1,0.2,0.15,0.29c0.04,0.08,0.07,0.16,0.11,0.23c0.34,0.57,0.29,1.09-0.17,1.69
							c-0.46,0.6-0.69,1.21-0.68,1.86c0,0.4-0.29,0.7-0.52,0.94l-0.01,0.01c-0.58,0.61-0.66,1.28-0.26,2.18
							c0.12,0.27,0.21,0.55,0.3,0.83c0.07,0.21,0.14,0.43,0.22,0.63c0.15,0.39,0.07,0.71-0.25,1c-0.18,0.16-0.36,0.32-0.54,0.48
							c-0.36,0.32-0.72,0.65-1.1,0.97c-0.29,0.24-0.65,0.18-0.92,0.13c-0.05-0.01-0.1-0.02-0.15-0.03c-0.6-0.08-0.96,0.22-1.37,0.57
							c-0.05,0.04-0.1,0.09-0.15,0.13c-0.3,0.24-0.58,0.5-0.85,0.74c-0.17,0.16-0.37,0.17-0.56,0.17c-0.2,0-0.4,0-0.6,0
							c-0.37,0-0.75,0-1.13-0.01c-0.46-0.01-0.79,0.13-1.06,0.44c-0.04,0.05-0.08,0.08-0.12,0.12c-0.05,0.04-0.09,0.09-0.12,0.13
							c-0.51,0.83-1.2,1.24-2.17,1.29c-0.37,0.02-0.74,0.25-1.1,0.48c-0.11,0.07-0.23,0.15-0.35,0.22
							c-0.02,0.01-0.04,0.03-0.07,0.04C473.35,706.75,473.22,706.83,473.01,706.83z M477.89,698.7c-0.08,0.05-0.14,0.13-0.21,0.22
							c-0.05,0.07-0.11,0.14-0.17,0.2c-0.63,0.59-1.15,1.14-1.16,2c-0.01,0.61-0.36,1.01-0.7,1.33c-0.12,0.12-0.26,0.23-0.39,0.34
							c-0.38,0.33-0.74,0.64-0.95,1.07l0,0c-0.31,0.65-0.59,1.31-0.88,2c-0.09,0.22-0.19,0.45-0.29,0.67
							c0.06-0.02,0.11-0.05,0.17-0.09c0.03-0.02,0.05-0.03,0.08-0.05c0.11-0.06,0.22-0.14,0.34-0.21c0.38-0.25,0.78-0.5,1.23-0.53
							c0.89-0.05,1.49-0.4,1.96-1.16c0.05-0.08,0.11-0.13,0.17-0.19c0.03-0.03,0.07-0.06,0.09-0.09c0.32-0.38,0.73-0.56,1.28-0.54
							c0.37,0.01,0.75,0.01,1.12,0.01c0.2,0,0.4,0,0.61,0c0.16,0,0.27-0.01,0.37-0.1c0.27-0.24,0.56-0.5,0.86-0.75
							c0.05-0.04,0.1-0.08,0.15-0.12c0.43-0.36,0.87-0.73,1.59-0.63c0.05,0.01,0.11,0.02,0.16,0.03c0.25,0.05,0.5,0.09,0.69-0.07
							c0.37-0.31,0.74-0.64,1.09-0.96c0.18-0.16,0.36-0.32,0.54-0.48c0.23-0.21,0.29-0.41,0.18-0.69c-0.08-0.21-0.16-0.43-0.23-0.65
							c-0.09-0.27-0.18-0.54-0.3-0.8c-0.44-1-0.34-1.79,0.31-2.48l0.01-0.01c0.21-0.22,0.44-0.47,0.44-0.75
							c0-0.72,0.24-1.38,0.74-2.03c0.39-0.5,0.43-0.91,0.15-1.38c-0.05-0.08-0.09-0.17-0.13-0.26c-0.04-0.09-0.08-0.18-0.13-0.26
							c-0.22-0.35-0.29-0.68-0.21-1.01l0.05-0.23c0.1-0.46,0.21-0.94,0.3-1.41c0.01-0.08,0.05-0.15,0.09-0.22
							c0.02-0.04,0.05-0.1,0.06-0.13c-0.03-0.01-0.06,0.01-0.16,0.16c-0.04,0.06-0.08,0.11-0.12,0.16
							c-0.08,0.08-0.16,0.16-0.23,0.24c-0.4,0.42-0.81,0.86-1.45,1c-0.32,0.07-0.44,0.22-0.49,0.61c-0.07,0.6-0.19,1.21-0.3,1.8
							l-0.05,0.27c-0.04,0.22-0.4,0.43-0.56,0.51c-0.19,0.1-0.52,0.24-0.7,0.16c-0.09-0.04-0.2-0.14-0.2-0.35c0-0.08,0-0.15,0-0.23
							c0-0.43,0-0.87,0.25-1.3l0.01-0.01c0.11-0.19,0.22-0.38,0.29-0.6c-0.03,0.02-0.05,0.04-0.08,0.06
							c-0.28,0.23-0.54,0.45-0.79,0.67c-0.1,0.09-0.12,0.23-0.14,0.36c-0.02,0.24-0.05,0.48-0.07,0.72
							c-0.05,0.45-0.1,0.91-0.12,1.36c-0.06,1.07-0.39,2.12-1.04,3.31l-0.18,0.33l-0.07-0.37c-0.11-0.6-0.07-1.14,0.02-1.66
							c-1.08,1.37-2.07,2.9-2.45,4.79l-0.08,0.41l-0.18-0.38c-0.12-0.27-0.12-0.52-0.12-0.74c0-0.09,0-0.18-0.01-0.27
							c-0.02-0.17-0.03-0.34-0.05-0.51C477.9,698.74,477.9,698.72,477.89,698.7z"/>
					</g>
					<g>
						<path className="st73" d="M378.08,738.99l50.46-30.61c0-0.18,0.03-0.36,0.05-0.54c0.68-0.65,1.35-1.3,2.04-1.94
							c0.26-0.24,0.37-0.55,0.48-0.86c0.17-0.47,0.44-0.81,0.92-1c0.35-0.14,0.68-0.35,1.02-0.49c0.96-0.4,1.8-0.95,2.42-1.78
							c0.55-0.73,1.26-0.93,2.12-0.79c0.17,0.03,0.33,0.04,0.48-0.06c0.52-0.35,1.01-0.21,1.54,0.03c0.71,0.33,1.45,0.63,2.2,0.86
							c1,0.3,1.95,0.02,2.88-0.37c0.64-0.28,1.3-0.52,1.91-0.85c0.52-0.28,1.04-0.27,1.59-0.21c1.16,0.13,2.32,0.26,3.48,0.39
							c0.28,0.03,0.5-0.08,0.74-0.19c0.33-0.15,0.63-0.36,0.99-0.45c-0.2,0.73-0.69,1.22-1.29,1.64c-0.31,0.22-0.59,0.48-0.91,0.68
							c-1,0.63-1.43,1.64-1.65,2.72c-0.17,0.84-0.67,1.41-1.22,1.97c-0.26,0.27-0.39,0.54-0.39,0.92c0,0.81-0.16,1.58-0.64,2.28
							c-0.39,0.56-0.37,1.27-0.55,1.91c-0.03,0.11,0.01,0.25-0.23,0.35c0.17-1.18-0.25-2.36,0.35-3.46
							c0.15-0.28,0.13-0.51-0.05-0.79c-0.58-0.88-0.85-1.82-0.52-2.89c0.23-0.75,0.19-0.76-0.46-1.19
							c-0.34-0.23-0.68-0.45-1.02-0.68c-0.18-0.12-0.29-0.11-0.37,0.11c-0.34,0.92-0.81,1.81-0.7,2.84
							c0.02,0.22-0.09,0.37-0.24,0.51c-0.47,0.45-0.93,0.91-1.41,1.35c-0.56,0.52-0.74,1.2-0.81,1.92c-0.04,0.4-0.07,0.8-0.16,1.2
							c-0.43-0.81-0.76-1.64-0.86-2.55c-0.01-0.13,0.05-0.3-0.12-0.36c-0.13-0.04-0.21,0.08-0.31,0.15
							c-0.78,0.5-1.13,1.32-1.57,2.08c-0.25,0.43-0.45,0.89-0.67,1.34c-0.05,0.1-0.07,0.27-0.22,0.26
							c-0.15-0.01-0.15-0.18-0.16-0.29c-0.13-1.06-0.92-1.65-1.61-2.33c0.17,0.88,0.79,1.64,0.65,2.61
							c-0.2-0.03-0.21-0.16-0.23-0.25c-0.19-0.72-0.66-1.21-1.29-1.57c-0.37-0.21-0.64-0.62-1.11-0.67
							c-0.01-0.01-0.01-0.01-0.02-0.02c0.01,0,0.02,0.01,0.03,0.01c0.47,0.79,0.93,1.59,1.42,2.37c0.48,0.76,0.56,1.62,0.66,2.48
							c0.02,0.15-0.07,0.25-0.15,0.36c-0.23,0.3-0.44,0.62-0.66,0.92c-0.33,0.44-0.49,0.93-0.44,1.49c0.03,0.4-0.47,1.1-0.83,1.24
							c-0.17,0.07-0.21-0.04-0.25-0.17c-0.08-0.31-0.17-0.61-0.25-0.92c-0.2-0.74-0.67-1.26-1.31-1.65
							c-0.8-0.49-1.57-1.02-2.37-1.55c-0.07,0.19,0.05,0.26,0.13,0.34c0.58,0.54,1.08,1.15,1.61,1.73c0.52,0.56,0.64,1.25,0.78,1.95
							c0.05,0.24,0.04,0.49,0.11,0.72c0.3,1.03,0.11,1.99-0.29,2.96c-0.28,0.68-0.48,1.39-0.72,2.08c-0.04,0-0.08-0.01-0.12-0.01
							c-0.1-0.71-0.21-1.42-0.31-2.13c-0.05-0.38-0.09-0.75-0.12-1.13c-0.04-0.39-0.25-0.7-0.46-1.02
							c-0.48-0.73-0.88-1.52-1.47-2.18"/>
						<path className="st8" d="M378.15,739.11l-0.14-0.23l50.4-30.58c0-0.1,0.02-0.21,0.03-0.31c0.01-0.06,0.01-0.11,0.02-0.17l0-0.05
							l0.04-0.04c0.2-0.19,0.39-0.37,0.59-0.56c0.47-0.45,0.96-0.92,1.46-1.38c0.24-0.22,0.35-0.53,0.45-0.81
							c0.19-0.54,0.51-0.89,1-1.08c0.18-0.07,0.35-0.16,0.52-0.25c0.16-0.08,0.33-0.17,0.5-0.24c1.03-0.43,1.81-1,2.36-1.74
							c0.55-0.73,1.28-1,2.25-0.84c0.16,0.03,0.28,0.03,0.38-0.04c0.61-0.41,1.18-0.21,1.67,0.02c0.86,0.39,1.53,0.66,2.18,0.85
							c1,0.3,1.97-0.02,2.79-0.37c0.18-0.08,0.35-0.15,0.53-0.22c0.46-0.19,0.93-0.38,1.37-0.62c0.58-0.31,1.16-0.28,1.67-0.22
							c0.44,0.05,0.89,0.1,1.33,0.15c0.71,0.08,1.44,0.16,2.15,0.24c0.22,0.03,0.42-0.06,0.62-0.16l0.04-0.02
							c0.1-0.05,0.2-0.1,0.3-0.16c0.22-0.12,0.45-0.24,0.71-0.3l0.23-0.06l-0.06,0.22c-0.24,0.87-0.88,1.39-1.34,1.71
							c-0.13,0.09-0.26,0.2-0.39,0.3c-0.17,0.13-0.34,0.27-0.52,0.39c-0.83,0.52-1.33,1.36-1.59,2.63c-0.18,0.87-0.7,1.46-1.26,2.04
							c-0.25,0.25-0.35,0.5-0.35,0.83c0,0.96-0.22,1.7-0.67,2.36c-0.25,0.36-0.32,0.78-0.4,1.24c-0.04,0.21-0.07,0.42-0.13,0.63
							c0,0.02-0.01,0.03-0.01,0.05c-0.01,0.1-0.04,0.28-0.3,0.38l-0.22,0.09l0.03-0.24c0.06-0.42,0.04-0.85,0.02-1.27
							c-0.03-0.74-0.06-1.51,0.34-2.24c0.13-0.23,0.11-0.41-0.05-0.65c-0.68-1.04-0.85-1.99-0.53-3c0.2-0.63,0.2-0.63-0.37-1.01
							l-0.03-0.02c-0.1-0.07-0.21-0.14-0.31-0.2c-0.24-0.15-0.48-0.31-0.72-0.47c-0.08-0.06-0.11-0.05-0.11-0.05
							c0,0-0.02,0.02-0.06,0.1c-0.06,0.18-0.13,0.35-0.2,0.52c-0.3,0.75-0.57,1.45-0.49,2.25c0.03,0.28-0.13,0.48-0.28,0.63
							c-0.19,0.18-0.37,0.36-0.55,0.53c-0.28,0.27-0.57,0.55-0.86,0.82c-0.46,0.42-0.69,0.97-0.76,1.83
							c-0.04,0.4-0.07,0.81-0.16,1.22l-0.08,0.36l-0.17-0.33c-0.52-0.98-0.79-1.78-0.87-2.6c0-0.04,0-0.08,0-0.12
							c0.01-0.11,0-0.11-0.02-0.12c-0.02-0.01-0.05,0.02-0.1,0.06c-0.03,0.02-0.06,0.05-0.09,0.07c-0.63,0.4-0.96,1.02-1.32,1.67
							c-0.07,0.12-0.13,0.25-0.2,0.37c-0.16,0.28-0.31,0.58-0.45,0.88c-0.07,0.15-0.15,0.31-0.22,0.46
							c-0.01,0.02-0.02,0.04-0.03,0.06c-0.04,0.1-0.11,0.28-0.32,0.27c-0.25-0.02-0.28-0.27-0.29-0.38l0-0.03
							c-0.1-0.83-0.63-1.36-1.21-1.91c0.05,0.15,0.11,0.3,0.18,0.46c0.22,0.53,0.44,1.09,0.34,1.73l-0.02,0.14l-0.14-0.02
							c-0.27-0.04-0.32-0.23-0.34-0.32l-0.01-0.02c-0.17-0.63-0.57-1.11-1.22-1.49c-0.14-0.08-0.27-0.18-0.39-0.28
							c-0.11-0.09-0.22-0.19-0.34-0.25c0.08,0.13,0.16,0.27,0.24,0.4c0.32,0.54,0.65,1.1,0.99,1.64c0.47,0.76,0.57,1.61,0.67,2.43
							l0.01,0.11c0.02,0.2-0.08,0.33-0.16,0.44l-0.02,0.02c-0.11,0.15-0.23,0.31-0.33,0.46c-0.11,0.15-0.22,0.31-0.33,0.46
							c-0.32,0.42-0.46,0.88-0.41,1.39c0.04,0.47-0.51,1.23-0.92,1.38c-0.1,0.04-0.19,0.04-0.26,0c-0.11-0.05-0.14-0.16-0.17-0.26
							c-0.03-0.12-0.07-0.24-0.1-0.36c-0.05-0.19-0.1-0.37-0.15-0.56c-0.17-0.65-0.57-1.15-1.25-1.57
							c-0.56-0.34-1.11-0.71-1.65-1.08c-0.05-0.04-0.11-0.07-0.16-0.11c0.3,0.3,0.58,0.61,0.85,0.92c0.16,0.18,0.33,0.38,0.51,0.56
							c0.52,0.56,0.67,1.26,0.8,1.94l0.02,0.08c0.02,0.11,0.03,0.22,0.04,0.32c0.01,0.14,0.03,0.26,0.06,0.38
							c0.27,0.93,0.18,1.9-0.29,3.05c-0.19,0.46-0.35,0.95-0.5,1.43c-0.07,0.22-0.14,0.43-0.21,0.65l-0.03,0.1l-0.33-0.03
							l-0.02-0.11c-0.03-0.21-0.06-0.42-0.09-0.63c-0.07-0.49-0.15-1-0.22-1.5c-0.05-0.36-0.08-0.73-0.12-1.08l0-0.05
							c-0.03-0.34-0.22-0.62-0.41-0.92l-0.03-0.04c-0.15-0.23-0.29-0.46-0.43-0.69c-0.31-0.5-0.62-1.03-1.03-1.47l0.2-0.18
							c0.42,0.46,0.74,1,1.06,1.51c0.14,0.23,0.28,0.46,0.43,0.68l0.03,0.04c0.21,0.31,0.42,0.63,0.46,1.04l0,0.05
							c0.03,0.35,0.07,0.72,0.11,1.07c0.07,0.5,0.14,1,0.22,1.49c0.02,0.11,0.03,0.21,0.05,0.32c0.04-0.12,0.08-0.23,0.11-0.35
							c0.15-0.48,0.31-0.97,0.51-1.45c0.46-1.1,0.54-1.99,0.28-2.87c-0.04-0.14-0.06-0.29-0.07-0.43c-0.01-0.1-0.02-0.2-0.04-0.3
							l-0.02-0.08c-0.14-0.67-0.26-1.3-0.73-1.81c-0.17-0.19-0.34-0.38-0.51-0.56c-0.35-0.39-0.71-0.79-1.1-1.16
							c-0.01-0.01-0.02-0.02-0.03-0.02c-0.08-0.07-0.23-0.2-0.14-0.46l0.06-0.17l0.15,0.1c0.24,0.16,0.48,0.32,0.72,0.48
							c0.56,0.38,1.09,0.73,1.64,1.07c0.74,0.45,1.19,1.02,1.38,1.74c0.05,0.19,0.1,0.37,0.15,0.56c0.03,0.12,0.07,0.24,0.1,0.36
							c0.01,0.05,0.02,0.07,0.03,0.08c0.01,0,0.02,0,0.04-0.01c0.3-0.12,0.77-0.76,0.74-1.11c-0.05-0.58,0.1-1.1,0.47-1.58
							c0.11-0.15,0.22-0.3,0.33-0.45c0.11-0.15,0.22-0.31,0.34-0.47l0.02-0.02c0.08-0.1,0.12-0.16,0.11-0.24l-0.01-0.11
							c-0.1-0.83-0.19-1.61-0.63-2.31c-0.34-0.54-0.67-1.11-0.99-1.65c-0.14-0.23-0.27-0.46-0.41-0.69l-0.02-0.01l0-0.01
							c0-0.01-0.01-0.01-0.01-0.02l0.03-0.02l0.09-0.14l0.01-0.05c0.01,0,0.02,0,0.02,0l0-0.01l0.02,0.01
							c0.32,0.05,0.55,0.24,0.77,0.42c0.12,0.1,0.23,0.19,0.35,0.26c0.71,0.4,1.15,0.93,1.34,1.61c-0.01-0.44-0.17-0.85-0.35-1.28
							c-0.13-0.31-0.26-0.64-0.32-0.97l-0.08-0.43l0.31,0.3c0.09,0.09,0.19,0.18,0.28,0.27c0.62,0.58,1.25,1.18,1.37,2.15l0,0.04
							c0,0.03,0.01,0.11,0.03,0.13l0.01,0c0.01-0.01,0.03-0.06,0.04-0.09c0.01-0.03,0.02-0.06,0.04-0.09
							c0.08-0.15,0.15-0.3,0.22-0.45c0.14-0.3,0.29-0.6,0.46-0.89c0.07-0.12,0.13-0.24,0.2-0.36c0.36-0.65,0.73-1.33,1.41-1.77
							c0.02-0.01,0.04-0.03,0.06-0.05c0.08-0.07,0.2-0.17,0.37-0.11c0.22,0.08,0.21,0.28,0.21,0.39c0,0.03,0,0.06,0,0.08
							c0.07,0.68,0.28,1.35,0.66,2.14c0.04-0.27,0.06-0.53,0.09-0.79c0.06-0.62,0.2-1.41,0.85-2.01c0.29-0.27,0.58-0.55,0.85-0.82
							c0.18-0.18,0.37-0.36,0.55-0.54c0.14-0.13,0.22-0.24,0.2-0.4c-0.09-0.87,0.21-1.64,0.51-2.38c0.07-0.17,0.14-0.34,0.2-0.52
							c0.05-0.15,0.13-0.23,0.23-0.27c0.13-0.04,0.26,0.03,0.35,0.09c0.24,0.16,0.48,0.32,0.71,0.47c0.1,0.07,0.21,0.14,0.31,0.2
							l0.03,0.02c0.66,0.43,0.73,0.52,0.48,1.32c-0.29,0.93-0.13,1.81,0.5,2.77c0.22,0.33,0.24,0.61,0.06,0.93
							c-0.36,0.66-0.33,1.36-0.3,2.1c0.01,0.17,0.01,0.35,0.02,0.53c0.01-0.07,0.03-0.15,0.04-0.22c0.08-0.46,0.16-0.94,0.44-1.35
							c0.42-0.6,0.62-1.3,0.62-2.2c0-0.4,0.13-0.71,0.43-1.02c0.53-0.54,1.02-1.1,1.19-1.9c0.27-1.35,0.81-2.24,1.71-2.81
							c0.17-0.11,0.33-0.24,0.5-0.37c0.13-0.1,0.26-0.21,0.4-0.31c0.59-0.41,0.95-0.82,1.15-1.31c-0.13,0.06-0.26,0.12-0.4,0.2
							c-0.1,0.05-0.21,0.11-0.32,0.16l-0.04,0.02c-0.23,0.11-0.47,0.22-0.77,0.18c-0.72-0.08-1.45-0.17-2.15-0.24
							c-0.44-0.05-0.89-0.1-1.33-0.15c-0.59-0.07-1.05-0.06-1.5,0.19c-0.45,0.24-0.93,0.44-1.39,0.63
							c-0.18,0.07-0.35,0.15-0.53,0.22c-0.86,0.37-1.88,0.7-2.97,0.38c-0.65-0.19-1.35-0.47-2.21-0.86
							c-0.54-0.25-0.96-0.34-1.41-0.04c-0.19,0.13-0.39,0.11-0.58,0.08c-0.87-0.15-1.51,0.09-1.99,0.74
							c-0.58,0.78-1.39,1.38-2.48,1.83c-0.16,0.07-0.32,0.15-0.48,0.23c-0.18,0.09-0.36,0.19-0.55,0.26
							c-0.41,0.16-0.67,0.45-0.84,0.91c-0.1,0.29-0.23,0.65-0.52,0.92c-0.49,0.45-0.98,0.92-1.45,1.37
							c-0.18,0.18-0.37,0.35-0.55,0.53c0,0.04-0.01,0.08-0.01,0.12c-0.01,0.12-0.03,0.24-0.03,0.35l0,0.08l-0.07,0.04L378.15,739.11
							z"/>
					</g>
					<g>
						<path className="st73" d="M428.55,734.95c0.36-0.29,0.26-0.72,0.34-1.09c0.17-0.82,0.27-1.66,0.9-2.28
							c0.28-0.27,0.57-0.55,0.9-0.76c0.31-0.19,0.33-0.37,0.21-0.67c-0.34-0.85-0.33-0.86,0.47-1.31c0.04-0.02,0.08-0.05,0.12-0.07
							c0.67-0.43,0.68-0.43,0.18-1.03c-0.1-0.13-0.17-0.25-0.12-0.41c0.35-1.05,0.74-2.05,2-2.3c0.29-0.06,0.37-0.26,0.34-0.5
							c-0.11-1.04,0.65-1.41,1.41-1.21c0.96,0.25,1.57-0.01,2.09-0.76c0.08-0.12,0.22-0.21,0.32-0.33c0.41-0.52,0.94-0.66,1.54-0.41
							c0.57,0.24,1.04,0.13,1.48-0.28c0.31-0.3,0.64-0.31,0.94,0.04c0.6,0.69,1.35,1.02,2.27,0.97c0.37-0.02,0.66,0.19,0.88,0.49
							c0.24,0.33,0.5,0.64,0.76,0.96c0.07,0.09,0.11,0.25,0.27,0.19c0.12-0.04,0.06-0.18,0.09-0.28c0.2-0.96,0.06-1.91-0.21-2.83
							c-0.25-0.85,0-1.52,0.61-2.09c0.22-0.2,0.35-0.38,0.19-0.7c-0.11-0.23-0.1-0.5-0.17-0.75c-0.15-0.55-0.04-1,0.42-1.38
							c0.38-0.32,0.74-0.67,0.89-1.18c0.08-0.27,0.27-0.48,0.53-0.59c0.65-0.26,0.8-0.76,0.69-1.39c-0.02-0.1-0.06-0.21,0.07-0.37
							c0.21,0.51,0.4,0.98,0.59,1.46c0.12,0.33,0.22,0.66,0.34,0.99c0.14,0.42,0.19,0.81-0.13,1.2c-0.19,0.23-0.3,0.53-0.48,0.77
							c-0.63,0.79-0.92,1.66-0.8,2.67c0.04,0.35-0.07,0.61-0.34,0.84c-0.29,0.24-0.54,0.53-0.82,0.79c-0.2,0.19-0.28,0.39-0.1,0.64
							c0.1,0.15,0.16,0.34,0.27,0.47c0.64,0.73,0.66,1.58,0.48,2.47c-0.06,0.3-0.1,0.6-0.12,0.9c-0.04,0.67-0.21,1.23-0.86,1.63
							c-1.18,0.74-2.14,1.79-3.33,2.52c-1.35,0.83-2.67,1.73-4.1,2.44c-0.58,0.29-1.23,0.41-1.87,0.56
							c-1.06,0.24-2.04,0.68-3.04,1.1c-1.49,0.62-3.01,1.13-4.58,1.5c-0.53,0.13-20.81,0.84-21.35,0.92
							C408.74,736.11,428.54,735.32,428.55,734.95z"/>
						<path className="st8" d="M408.6,736.64l0-0.16c0-0.24,0-0.31,10.03-0.9c4.37-0.26,9.32-0.55,9.8-0.72l0.02-0.02
							c0.21-0.17,0.23-0.4,0.25-0.67c0.01-0.11,0.02-0.23,0.04-0.34c0.02-0.1,0.04-0.2,0.06-0.3c0.14-0.72,0.29-1.47,0.88-2.05
							c0.31-0.3,0.6-0.57,0.93-0.77c0.24-0.15,0.25-0.26,0.15-0.5c-0.36-0.91-0.33-0.99,0.53-1.48c0.04-0.02,0.08-0.05,0.11-0.07
							c0.25-0.16,0.46-0.29,0.47-0.36c0.01-0.07-0.14-0.25-0.32-0.47c-0.11-0.13-0.22-0.31-0.14-0.54c0.3-0.89,0.7-2.11,2.1-2.4
							c0.14-0.03,0.26-0.09,0.23-0.36c-0.07-0.62,0.16-0.96,0.36-1.14c0.3-0.26,0.74-0.34,1.21-0.22c0.88,0.23,1.44,0.02,1.95-0.71
							c0.05-0.08,0.12-0.14,0.19-0.2c0.05-0.04,0.1-0.09,0.13-0.13c0.45-0.57,1.04-0.73,1.7-0.46c0.51,0.21,0.93,0.13,1.33-0.26
							c0.19-0.18,0.39-0.27,0.59-0.26c0.2,0.01,0.38,0.11,0.55,0.31c0.58,0.67,1.29,0.97,2.16,0.93c0.38-0.02,0.72,0.17,1,0.54
							c0.18,0.25,0.38,0.49,0.57,0.73c0.06,0.08,0.12,0.15,0.18,0.23c0.02,0.03,0.04,0.06,0.06,0.08c0.01,0.02,0.04,0.06,0.05,0.07
							c0-0.01,0-0.04,0-0.05c0-0.04,0-0.08,0.01-0.13c0.17-0.81,0.11-1.69-0.21-2.76c-0.25-0.85-0.04-1.58,0.65-2.23
							c0.21-0.19,0.28-0.31,0.16-0.55c-0.08-0.16-0.1-0.33-0.13-0.5c-0.01-0.1-0.03-0.19-0.05-0.28c-0.17-0.63-0.02-1.13,0.46-1.52
							c0.36-0.3,0.7-0.64,0.84-1.11c0.09-0.31,0.31-0.56,0.61-0.68c0.53-0.21,0.72-0.6,0.61-1.25l-0.01-0.03
							c-0.02-0.1-0.05-0.25,0.11-0.45l0.15-0.18l0.09,0.21c0.06,0.15,0.12,0.31,0.18,0.45c0.14,0.35,0.28,0.68,0.4,1.01
							c0.08,0.22,0.16,0.45,0.23,0.67c0.03,0.11,0.07,0.21,0.11,0.32c0.14,0.4,0.22,0.87-0.15,1.33c-0.09,0.11-0.16,0.23-0.23,0.37
							c-0.07,0.13-0.15,0.27-0.25,0.4c-0.63,0.79-0.89,1.63-0.77,2.56c0.05,0.39-0.07,0.69-0.39,0.96c-0.17,0.15-0.34,0.31-0.5,0.47
							c-0.1,0.1-0.21,0.21-0.32,0.31c-0.18,0.17-0.2,0.29-0.08,0.46c0.05,0.07,0.09,0.15,0.12,0.22c0.04,0.09,0.09,0.17,0.14,0.24
							c0.74,0.85,0.66,1.83,0.51,2.59c-0.06,0.32-0.1,0.61-0.12,0.88c-0.04,0.63-0.18,1.28-0.92,1.74
							c-0.58,0.36-1.11,0.81-1.63,1.24c-0.54,0.45-1.09,0.91-1.7,1.29c-0.34,0.21-0.69,0.43-1.02,0.64
							c-0.99,0.62-2.01,1.27-3.08,1.81c-0.51,0.26-1.08,0.38-1.62,0.51c-0.09,0.02-0.18,0.04-0.28,0.06
							c-0.94,0.22-1.84,0.6-2.71,0.96l-0.3,0.13c-1.51,0.63-3.06,1.14-4.6,1.5c-0.29,0.07-5.5,0.28-12.82,0.57
							c-4.28,0.17-8.31,0.33-8.54,0.36L408.6,736.64z M428.67,735.02c-0.11,0.19-1.1,0.3-10.02,0.83c-1.25,0.07-2.54,0.15-3.76,0.22
							c0.77-0.03,1.57-0.06,2.4-0.09c5.55-0.22,12.46-0.49,12.77-0.56c1.53-0.36,3.07-0.86,4.56-1.49l0.3-0.13
							c0.88-0.37,1.79-0.76,2.76-0.98c0.09-0.02,0.19-0.04,0.28-0.06c0.53-0.12,1.08-0.24,1.56-0.48c1.06-0.53,2.07-1.18,3.06-1.8
							c0.34-0.21,0.68-0.43,1.03-0.64c0.59-0.37,1.14-0.82,1.67-1.26c0.52-0.44,1.07-0.89,1.66-1.26c0.6-0.37,0.75-0.88,0.79-1.52
							c0.02-0.29,0.06-0.59,0.12-0.92c0.2-1.05,0.07-1.76-0.45-2.36c-0.08-0.09-0.13-0.2-0.18-0.3c-0.03-0.07-0.06-0.13-0.1-0.19
							c-0.19-0.28-0.15-0.56,0.12-0.82c0.11-0.1,0.21-0.21,0.31-0.31c0.16-0.16,0.33-0.33,0.51-0.49c0.24-0.2,0.33-0.42,0.29-0.72
							c-0.12-0.99,0.15-1.93,0.83-2.77c0.09-0.11,0.15-0.23,0.23-0.36c0.08-0.14,0.16-0.28,0.26-0.41c0.23-0.29,0.26-0.6,0.1-1.07
							c-0.04-0.11-0.07-0.22-0.11-0.32c-0.07-0.22-0.14-0.44-0.23-0.66c-0.12-0.31-0.24-0.62-0.37-0.94
							c0.04,0.64-0.22,1.07-0.8,1.31c-0.22,0.09-0.39,0.27-0.45,0.5c-0.16,0.55-0.54,0.92-0.93,1.25c-0.4,0.33-0.51,0.72-0.37,1.24
							c0.03,0.1,0.04,0.21,0.06,0.31c0.02,0.15,0.05,0.3,0.1,0.42c0.2,0.42-0.01,0.67-0.22,0.86c-0.62,0.58-0.8,1.2-0.58,1.95
							c0.33,1.12,0.4,2.04,0.22,2.9c0,0.01,0,0.04,0,0.06c0,0.08,0.01,0.25-0.17,0.32c-0.22,0.08-0.34-0.1-0.38-0.18
							c-0.01-0.02-0.02-0.04-0.04-0.05c-0.06-0.08-0.12-0.15-0.18-0.23c-0.19-0.24-0.39-0.49-0.58-0.74
							c-0.22-0.3-0.48-0.45-0.76-0.43c-0.95,0.05-1.76-0.3-2.38-1.02c-0.12-0.14-0.24-0.21-0.36-0.22
							c-0.12-0.01-0.25,0.06-0.38,0.18c-0.49,0.46-1.02,0.56-1.63,0.31c-0.55-0.23-1.01-0.1-1.38,0.37
							c-0.05,0.06-0.11,0.12-0.16,0.17c-0.06,0.05-0.11,0.1-0.15,0.15c-0.56,0.82-1.26,1.07-2.24,0.82
							c-0.38-0.1-0.74-0.04-0.96,0.16c-0.22,0.19-0.32,0.5-0.27,0.9c0.04,0.36-0.12,0.59-0.45,0.65c-1.15,0.23-1.54,1.14-1.9,2.21
							c-0.03,0.09-0.01,0.17,0.09,0.28c0.27,0.33,0.41,0.49,0.38,0.68c-0.03,0.19-0.22,0.31-0.59,0.55
							c-0.04,0.03-0.08,0.05-0.12,0.07c-0.36,0.2-0.56,0.32-0.6,0.43c-0.04,0.11,0.03,0.31,0.19,0.71c0.11,0.28,0.16,0.58-0.27,0.84
							c-0.31,0.19-0.58,0.44-0.88,0.74c-0.53,0.52-0.66,1.19-0.8,1.91c-0.02,0.1-0.04,0.21-0.06,0.31c-0.02,0.1-0.03,0.2-0.04,0.3
							C428.96,734.47,428.94,734.78,428.67,735.02z"/>
					</g>
					<g>
						<path className="st76" d="M507.35,677.79c-0.33,0-1.4,0-1.73,0c-0.08,0.04-0.18,0.05-0.27,0.08c-1.14,0.33-2.31,0.2-3.47,0.26
							c-1.61,0.09-1.62,0.05-2.29,1.53c-0.16,0.35-0.39,0.54-0.73,0.69c-0.54,0.24-1.1,0.45-1.54,0.86
							c-0.13,0.12-0.23,0.29,0.05,0.29c0.5,0.01,1,0.08,1.49-0.12c0.73-0.3,1.46-0.59,2.19-0.88l0,0l0,0l0,0l0,0
							c0.02,0.04,0.67-0.23,0.67-0.23s1.44-0.08,2.16-0.03c1.63,0.13,3.23,0.49,4.86,0.63c1.13,0.1,2.24,0.52,3.4,0.27
							c0.05-0.01,0.11,0.03,0.16,0.06c0.27,0.21,0.6,0.22,0.91,0.2c0.37-0.02,0.69,0.07,0.98,0.28c1.74,1.22,3.6,2.24,5.33,3.48
							c0.17,0.12,0.35,0.24,0.57,0.27c0.95,0.13,1.91,0.1,2.86,0.16c0.12,0.01,0.26,0.03,0.32-0.11c0.06-0.14-0.07-0.21-0.15-0.3
							c-1.18-1.26-2.47-2.41-3.91-3.38c-0.3-0.2-0.62-0.31-0.99-0.29c-1.03,0.08-2.02-0.18-3-0.41c-0.1-0.02-0.24-0.01-0.25-0.15
							c-0.01-0.11,0.12-0.12,0.19-0.16c0.9-0.41,1.85-0.65,2.85-0.66c1-0.01,2-0.02,3,0.01c0.71,0.02,1.13-0.33,1.45-0.84
							c-0.77-0.19-1.53-0.38-2.3-0.54c-4.13-0.86-8.32-1.07-12.53-0.99C507.54,677.77,507.45,677.78,507.35,677.79"/>
						<path className="st8" d="M523.05,685.73c-0.03,0-0.07,0-0.09,0l-0.02,0c-0.35-0.02-0.71-0.03-1.06-0.04
							c-0.59-0.02-1.21-0.03-1.81-0.12c-0.25-0.04-0.45-0.16-0.63-0.29c-0.89-0.63-1.83-1.22-2.74-1.79
							c-0.86-0.54-1.74-1.09-2.58-1.68c-0.27-0.19-0.57-0.28-0.9-0.26c-0.32,0.02-0.69,0.01-1-0.23c-0.03-0.02-0.05-0.03-0.06-0.03
							c-0.8,0.17-1.58,0.03-2.34-0.11c-0.36-0.07-0.73-0.13-1.09-0.16c-0.8-0.07-1.6-0.19-2.38-0.31c-0.81-0.12-1.65-0.25-2.48-0.32
							c-0.66-0.05-1.93,0.02-2.12,0.03c-0.38,0.16-0.58,0.23-0.68,0.23c-0.21,0.08-0.41,0.17-0.62,0.25
							c-0.5,0.2-1.02,0.41-1.53,0.62c-0.43,0.18-0.86,0.16-1.29,0.14c-0.09,0-0.17-0.01-0.26-0.01c-0.16,0-0.26-0.06-0.3-0.16
							c-0.04-0.11,0.01-0.24,0.16-0.37c0.4-0.38,0.9-0.59,1.37-0.79c0.07-0.03,0.14-0.06,0.21-0.09c0.34-0.15,0.53-0.32,0.66-0.62
							c0.66-1.48,0.73-1.53,2.27-1.61l0.13-0.01c0.35-0.02,0.7-0.02,1.04-0.02c0.8,0,1.62-0.01,2.4-0.23
							c0.03-0.01,0.07-0.02,0.1-0.02c0.05-0.01,0.11-0.02,0.14-0.04l0.03-0.02l0.35,0c0.45,0,1.15,0,1.41,0l0-0.01
							c0.1,0,0.19,0,0.28-0.01c4.86-0.1,8.85,0.21,12.56,0.99c0.74,0.15,1.46,0.33,2.31,0.54l0.18,0.05l-0.1,0.16
							c-0.26,0.4-0.7,0.93-1.57,0.91c-0.98-0.03-1.98-0.02-2.94-0.01l-0.06,0c-0.94,0.01-1.88,0.23-2.79,0.65
							c-0.02,0.01-0.04,0.02-0.06,0.02c-0.01,0-0.02,0.01-0.03,0.01c0.02,0,0.03,0.01,0.05,0.01c0.02,0,0.05,0.01,0.07,0.01
							l0.26,0.06c0.88,0.21,1.78,0.42,2.7,0.35c0.37-0.03,0.72,0.07,1.07,0.31c1.36,0.91,2.64,2.02,3.93,3.4
							c0.01,0.01,0.03,0.03,0.05,0.04c0.08,0.07,0.22,0.2,0.14,0.4C523.32,685.71,523.17,685.73,523.05,685.73z M513.31,681.27
							c0.35,0,0.66,0.1,0.96,0.31c0.84,0.59,1.72,1.14,2.57,1.67c0.92,0.57,1.86,1.16,2.76,1.8c0.16,0.11,0.31,0.22,0.51,0.24
							c0.59,0.08,1.19,0.1,1.78,0.12c0.35,0.01,0.71,0.02,1.07,0.04l0.02,0c0.13,0.01,0.15,0,0.16-0.03
							c0.01-0.02,0.01-0.02-0.07-0.09c-0.02-0.02-0.04-0.04-0.06-0.06c-1.28-1.36-2.55-2.46-3.89-3.36c-0.3-0.2-0.59-0.29-0.9-0.26
							c-0.96,0.07-1.89-0.15-2.79-0.36l-0.26-0.06c-0.02,0-0.03-0.01-0.05-0.01c-0.09-0.01-0.29-0.05-0.3-0.27
							c-0.01-0.19,0.15-0.25,0.23-0.28c0.01-0.01,0.03-0.01,0.04-0.02c0.95-0.44,1.93-0.66,2.91-0.67l0.06,0
							c0.97-0.01,1.97-0.02,2.96,0.01c0.52,0.02,0.89-0.17,1.23-0.62c-0.77-0.19-1.43-0.35-2.11-0.49
							c-3.69-0.77-7.66-1.08-12.5-0.98c-0.09,0-0.19,0-0.28,0.01l0,0.01c-0.26,0-0.96,0-1.42,0l-0.28,0
							c-0.06,0.03-0.12,0.04-0.18,0.05c-0.03,0.01-0.06,0.01-0.09,0.02c-0.82,0.24-1.66,0.24-2.48,0.24c-0.34,0-0.69,0-1.03,0.02
							l-0.13,0.01c-1.43,0.07-1.43,0.07-2.04,1.45c-0.16,0.35-0.4,0.58-0.8,0.75c-0.07,0.03-0.14,0.06-0.21,0.09
							c-0.46,0.2-0.93,0.4-1.29,0.74c-0.02,0.02-0.04,0.04-0.05,0.06c0,0,0.01,0,0.01,0c0.09,0,0.17,0.01,0.26,0.01
							c0.41,0.02,0.8,0.04,1.17-0.12c0.51-0.21,1.03-0.42,1.53-0.62c0.22-0.09,0.44-0.18,0.66-0.26l0.13-0.05l0.01,0.02
							c0.12-0.04,0.34-0.12,0.53-0.2l0.02-0.01l0.02,0c0.06,0,1.46-0.08,2.18-0.03c0.84,0.07,1.68,0.2,2.5,0.32
							c0.77,0.12,1.57,0.24,2.36,0.31c0.38,0.03,0.75,0.1,1.12,0.17c0.77,0.14,1.49,0.27,2.24,0.11c0.08-0.02,0.17,0.01,0.27,0.09
							c0.24,0.18,0.54,0.19,0.82,0.17C513.24,681.27,513.28,681.27,513.31,681.27z M497.31,681.35L497.31,681.35L497.31,681.35z
							 M512.15,681.28L512.15,681.28L512.15,681.28z"/>
					</g>
					<g>
						<path className="st73" d="M402.27,744.64c-2.11,0.76-3.69-4.11-10.91-4.11c0.34-1,38.49-2.45,39.1-3.28
							c0.1-0.13,0.21-0.31,0.4-0.18c0.26,0.18,0.36-0.01,0.46-0.17c0.12-0.19,0.2-0.41,0.32-0.6c0.22-0.36,0.47-0.67,0.9-0.76
							c0.29-0.06,0.58-0.14,0.85-0.26c0.9-0.39,0.97-0.53,0.97,0.08c0,0.65,0.02,1.3-0.1,1.95c-0.02,0.1-0.1,0.22,0.01,0.29
							c0.14,0.09,0.21-0.07,0.3-0.14c0.54-0.4,0.97-0.9,1.11-1.56c0.19-0.9,0.81-1.34,1.57-1.68c0.37-0.16,10.32-4.93,10.78-5.13
							c-0.18,0.41-5.94,5.14-6.18,5.35c-1.02,0.9-3.61,1.73-4.14,2.97c-0.38,0.88,0.11,1.86-0.79,2.12c-1.65,0.48-6.63,1-8.08,1.93
							c-0.09,0.06-0.18,0.12-0.29,0.07c0.01-0.39,2.61-0.27-13.95,2.17C413.44,743.87,406.16,743.24,402.27,744.64z"/>
						<path className="st8" d="M401.81,744.86c-0.71,0-1.39-0.53-2.26-1.2c-1.63-1.26-3.87-2.99-8.19-2.99h-0.19l0.06-0.18
							c0.06-0.18,0.15-0.44,6.89-0.96c3.97-0.31,9.2-0.63,14.26-0.94c8.18-0.5,17.44-1.07,17.98-1.43l0.02-0.02
							c0.09-0.12,0.28-0.38,0.57-0.18c0.05,0.04,0.1,0.05,0.12,0.05c0.04-0.01,0.09-0.09,0.13-0.16l0.01-0.02
							c0.05-0.09,0.1-0.18,0.15-0.28c0.05-0.11,0.1-0.22,0.17-0.32c0.21-0.34,0.49-0.72,0.99-0.83c0.33-0.07,0.59-0.15,0.82-0.25
							c0.13-0.06,0.24-0.11,0.33-0.15c0.38-0.17,0.55-0.25,0.69-0.16c0.13,0.08,0.14,0.26,0.14,0.52c0,0.08,0,0.16,0,0.24
							c0,0.57,0.01,1.16-0.1,1.73c-0.01,0.03-0.02,0.06-0.03,0.09c-0.01,0.02-0.01,0.04-0.02,0.06c0.02-0.01,0.04-0.04,0.05-0.05
							c0.02-0.03,0.05-0.06,0.09-0.08c0.6-0.45,0.94-0.93,1.06-1.48c0.22-1.02,0.95-1.47,1.65-1.78c0.19-0.08,3.01-1.43,5.74-2.73
							c2.48-1.18,4.82-2.3,5.03-2.4l0.33-0.15l-0.15,0.33c-0.11,0.26-1.74,1.67-5.6,4.89c-0.33,0.27-0.56,0.47-0.6,0.51
							c-0.4,0.35-1.01,0.68-1.66,1.03c-1,0.54-2.13,1.16-2.45,1.88c-0.13,0.31-0.15,0.64-0.17,0.95c-0.03,0.52-0.05,1.05-0.71,1.24
							c-0.58,0.17-1.54,0.34-2.64,0.53c-1.99,0.35-4.47,0.79-5.4,1.38l-0.01,0.01c-0.09,0.06-0.23,0.15-0.41,0.07l-0.08-0.04l0-0.05
							c-2.21,0.48-7.09,1.29-13.79,2.28c-0.31,0.05-1.03,0.04-2.03,0.02c-2.78-0.04-7.43-0.11-10.27,0.92l-0.05-0.13l0.05,0.13
							C402.14,744.84,401.97,744.86,401.81,744.86z M392.13,740.41c3.92,0.2,6.11,1.89,7.58,3.03c1.05,0.81,1.74,1.35,2.51,1.07
							c2.89-1.05,7.57-0.98,10.37-0.94c0.95,0.01,1.69,0.02,1.98-0.02c6.89-1.02,11.85-1.84,13.96-2.31
							c0.16-0.04,0.25-0.06,0.29,0.06c1.01-0.6,3.36-1.01,5.44-1.38c1.1-0.19,2.05-0.36,2.62-0.53c0.45-0.13,0.48-0.43,0.51-0.99
							c0.02-0.32,0.04-0.69,0.19-1.05c0.35-0.81,1.48-1.42,2.57-2.02c0.63-0.34,1.23-0.67,1.61-1c0.04-0.04,0.26-0.22,0.61-0.51
							c1.07-0.89,3.89-3.24,5.04-4.3c-0.91,0.43-2.6,1.24-4.36,2.08c-2.73,1.3-5.55,2.65-5.75,2.74c-0.66,0.29-1.3,0.69-1.49,1.58
							c-0.13,0.62-0.51,1.15-1.16,1.64c-0.01,0.01-0.03,0.03-0.05,0.05c-0.06,0.07-0.21,0.22-0.41,0.1
							c-0.18-0.11-0.11-0.3-0.09-0.38c0.01-0.02,0.01-0.04,0.02-0.06c0.11-0.55,0.1-1.13,0.1-1.68c0-0.08,0-0.16,0-0.24
							c0-0.06,0-0.22-0.02-0.28c-0.07,0.01-0.24,0.09-0.42,0.17c-0.1,0.04-0.21,0.1-0.33,0.15c-0.25,0.11-0.53,0.19-0.87,0.27
							c-0.36,0.08-0.58,0.33-0.82,0.7c-0.06,0.09-0.1,0.19-0.15,0.29c-0.05,0.1-0.1,0.21-0.16,0.31l-0.01,0.02
							c-0.07,0.11-0.15,0.25-0.31,0.28c-0.1,0.02-0.21-0.01-0.33-0.09c-0.05-0.04-0.07-0.05-0.19,0.11l-0.02,0.03
							c-0.12,0.16-0.66,0.34-5.83,0.73c-3.35,0.25-7.72,0.52-12.35,0.8C404.42,739.35,394.74,739.95,392.13,740.41z"/>
					</g>
					<g>
						<path className="st73" d="M501.99,723.08c-0.62,0.32-1.29,0.25-1.96,0.25c-0.1,0-0.24,0.04-0.27-0.1
							c-0.01-0.06,0.07-0.16,0.13-0.22c0.35-0.34,0.7-0.68,1.05-1.01c0.17-0.16,0.33-0.33,0.31-0.58c-0.14-1.41,0.17-2.73,0.78-4
							c0.05-0.1,0.17-0.22,0.05-0.32c-0.12-0.09-0.22,0.04-0.33,0.09c-0.44,0.2-0.87,0.41-1.41,0.67c0.2-0.76,0.41-1.43,0.56-2.11
							c0.19-0.88,0.7-1.48,1.5-1.87c0.17-0.08,0.34-0.2,0.49-0.43c-0.57-0.07-1.1-0.11-1.59,0.17c-0.44,0.24-0.85,0.52-1.28,0.79
							c-0.07,0.05-0.13,0.15-0.23,0.1c-0.13-0.06-0.08-0.19-0.07-0.28c0.09-0.57,0.44-1.03,0.72-1.51c0.36-0.61,0.76-1.2,1.07-1.85
							c0.09-0.2,0.15-0.4,0.06-0.56c-0.59-1.08-0.25-2.17-0.15-3.28c0.09-1.01,0.76-1.53,1.43-2.08c0.34-0.28,0.69-0.55,1.01-0.84
							c0.23-0.2,0.36-0.17,0.55,0.07c0.29,0.38,0.48,0.83,0.92,1.24c-0.1-0.45-0.14-0.82-0.27-1.15c-0.27-0.73-0.14-1.38,0.22-2.05
							c0.31-0.59,0.58-1.2,0.86-1.81c0.09-0.19,0.2-0.29,0.41-0.29c0.2-0.01,0.39-0.05,0.59-0.05c0.7,0.03,1.08-0.54,1.6-0.86
							c0.16-0.1,0.31-0.34,0.5-0.25c0.24,0.1,0.11,0.38,0.12,0.58c0.04,1.09,0.07,2.18,0.1,3.27c0,0.1-0.02,0.21,0.07,0.32
							c0.16-0.06,0.14-0.22,0.16-0.33c0.21-1.09,0.41-2.18,0.61-3.26c0.09-0.5,0.47-0.84,0.69-1.27c0.13-0.25,0.31-0.48,0.49-0.76
							c0.18,0.38,0.12,0.75,0.08,1.09c-0.12,0.9,0.1,1.56,1.02,1.87c0.29,0.1,0.37,0.34,0.38,0.65c0.05,1.06,0.06,2.12,0.06,3.18
							c0,0.25-0.01,0.49-0.09,0.72c-0.36,1-0.51,2.06-0.84,3.07c-0.04,0.14-0.12,0.28,0.01,0.48c0.34-0.62,0.66-1.21,0.98-1.8
							c0.04-0.08,0.03-0.2,0.14-0.21c0.15,0,0.14,0.13,0.16,0.23c0.13,0.65,0.25,1.31,0.44,1.95c0.07,0.23-0.05,0.4-0.19,0.57
							c-0.65,0.78-1.35,1.52-2.08,2.23c-0.22,0.22-0.34,0.4-0.07,0.68c0.23,0.23,0.19,0.5,0.01,0.79c-0.63,1-1.24,2.01-1.86,3.02
							c-0.27,0.44-0.23,0.5,0.28,0.62c0.03,0.01,0.06,0.02,0.09,0.02c1.28,0.28,1.28,0.28,1.17,1.59c-0.01,0.15-0.08,0.3,0.03,0.44
							c-0.03,0.28-0.17,0.38-0.46,0.37c-0.44-0.03-0.88-0.01-1.31-0.03c-0.71-0.05-1.3,0.15-1.7,0.78c-0.33,0.51-0.8,0.9-1.19,1.37
							c-0.35,0.42-0.77,0.62-1.32,0.47c-0.13-0.03-0.27-0.03-0.4-0.05c-0.05-0.83,0.2-1.62,0.29-2.43c0.11-0.94,0.04-1.97,0.93-2.65
							c0.05-0.04,0.06-0.14,0.09-0.21c0.14-0.43,0.33-0.82,0.83-0.92c0.07-0.01,0.15-0.05,0.14-0.15c-0.01-0.1-0.1-0.1-0.17-0.12
							c-0.88-0.32-1.77-0.37-2.68-0.15c-0.11,0.03-0.19,0.08-0.27,0.16c-0.92,0.88-0.82,2.06-0.94,3.18
							c0.43-0.41,0.64-0.97,1.07-1.39c0.11,0.17,0.06,0.3,0.03,0.43c-0.29,1.08-0.6,2.16-0.88,3.24c-0.16,0.61-0.37,1.21-0.43,1.85
							C502.65,722.78,502.27,722.86,501.99,723.08z"/>
						<path className="st8" d="M500.6,723.47c-0.09,0-0.19,0-0.28,0c-0.1,0-0.19,0-0.29,0c-0.01,0-0.03,0-0.05,0
							c-0.09,0.01-0.31,0.02-0.35-0.21c-0.02-0.1,0.04-0.22,0.17-0.34c0.36-0.35,0.71-0.69,1.05-1.01c0.16-0.15,0.28-0.28,0.27-0.47
							c-0.13-1.35,0.13-2.68,0.79-4.07c0.01-0.02,0.03-0.05,0.04-0.08c0.01-0.02,0.04-0.06,0.04-0.08c0,0,0,0,0,0
							c-0.01,0-0.05,0.03-0.08,0.05c-0.03,0.02-0.07,0.05-0.1,0.06c-0.32,0.15-0.63,0.3-0.99,0.47l-0.69,0.33l0.08-0.29
							c0.06-0.24,0.13-0.47,0.19-0.7c0.13-0.49,0.26-0.95,0.37-1.41c0.2-0.91,0.72-1.55,1.57-1.97c0.11-0.05,0.21-0.12,0.29-0.2
							c-0.44-0.04-0.87-0.04-1.26,0.18c-0.3,0.17-0.6,0.36-0.89,0.54c-0.13,0.08-0.25,0.16-0.38,0.24
							c-0.01,0.01-0.03,0.02-0.04,0.03c-0.06,0.05-0.17,0.15-0.32,0.08c-0.21-0.1-0.17-0.3-0.15-0.39c0-0.01,0-0.03,0.01-0.04
							c0.07-0.49,0.33-0.89,0.57-1.28c0.06-0.09,0.12-0.18,0.17-0.28c0.1-0.16,0.2-0.33,0.3-0.49c0.27-0.43,0.54-0.88,0.76-1.35
							c0.1-0.21,0.12-0.34,0.07-0.44c-0.49-0.9-0.37-1.81-0.25-2.68c0.03-0.22,0.06-0.45,0.08-0.67c0.09-1.03,0.76-1.58,1.41-2.12
							l0.07-0.06c0.11-0.09,0.22-0.18,0.33-0.27c0.23-0.18,0.46-0.38,0.69-0.57c0.09-0.08,0.23-0.18,0.39-0.17
							c0.16,0.02,0.28,0.15,0.36,0.26c0.1,0.13,0.19,0.27,0.28,0.41c0.09,0.14,0.18,0.29,0.29,0.43c-0.05-0.23-0.09-0.44-0.16-0.63
							c-0.26-0.7-0.19-1.38,0.22-2.16c0.3-0.57,0.57-1.17,0.83-1.76l0.02-0.04c0.11-0.25,0.27-0.36,0.53-0.37
							c0.08,0,0.16-0.01,0.24-0.02c0.12-0.01,0.24-0.03,0.36-0.02c0.44,0.02,0.75-0.24,1.07-0.5c0.14-0.12,0.29-0.24,0.45-0.33
							c0.04-0.02,0.08-0.06,0.12-0.1c0.12-0.11,0.29-0.26,0.51-0.17c0.27,0.12,0.23,0.39,0.21,0.55c-0.01,0.06-0.01,0.11-0.01,0.15
							c0.03,0.75,0.05,1.5,0.07,2.24c0.01,0.28,0.02,0.55,0.02,0.83c0.15-0.81,0.3-1.63,0.45-2.42l0.12-0.67
							c0.06-0.35,0.25-0.61,0.43-0.88c0.1-0.15,0.2-0.28,0.27-0.43c0.09-0.18,0.21-0.35,0.33-0.52c0.06-0.08,0.11-0.16,0.17-0.25
							l0.13-0.21l0.1,0.22c0.19,0.41,0.14,0.8,0.09,1.15l0,0.01c-0.13,0.94,0.15,1.46,0.93,1.73c0.39,0.13,0.46,0.48,0.47,0.77
							c0.05,1.15,0.06,2.26,0.06,3.19c0,0.25-0.01,0.51-0.1,0.76c-0.2,0.54-0.33,1.11-0.46,1.66c-0.1,0.41-0.2,0.84-0.33,1.25
							c0.24-0.44,0.47-0.86,0.7-1.28c0-0.01,0.01-0.03,0.02-0.05c0.02-0.07,0.06-0.23,0.25-0.23c0.24-0.01,0.28,0.22,0.29,0.3
							c0,0.01,0,0.03,0.01,0.04c0.03,0.14,0.06,0.28,0.08,0.42c0.1,0.5,0.2,1.02,0.35,1.52c0.1,0.32-0.11,0.57-0.22,0.7
							c-0.65,0.77-1.35,1.53-2.08,2.24c-0.1,0.09-0.18,0.19-0.19,0.27c0,0.06,0.04,0.13,0.12,0.21c0.26,0.26,0.27,0.58,0.03,0.96
							c-0.57,0.9-1.14,1.83-1.68,2.73l-0.18,0.29c-0.13,0.21-0.15,0.28-0.14,0.3c0.02,0.03,0.13,0.07,0.34,0.12
							c0.02,0,0.03,0.01,0.05,0.01c0.01,0,0.03,0.01,0.04,0.01c1.35,0.3,1.4,0.36,1.27,1.74c0,0.04-0.01,0.08-0.02,0.11
							c-0.02,0.11-0.03,0.17,0.02,0.23l0.03,0.04l0,0.05c-0.03,0.35-0.23,0.51-0.6,0.49c-0.21-0.01-0.43-0.01-0.64-0.02
							c-0.22,0-0.45,0-0.67-0.02c-0.73-0.05-1.24,0.18-1.58,0.72c-0.21,0.33-0.47,0.6-0.73,0.87c-0.16,0.16-0.32,0.33-0.46,0.51
							c-0.4,0.49-0.89,0.66-1.46,0.51c-0.08-0.02-0.16-0.03-0.25-0.03c-0.04,0-0.09-0.01-0.13-0.01l-0.12-0.01l-0.01-0.12
							c-0.03-0.56,0.07-1.11,0.16-1.64c0.05-0.27,0.1-0.55,0.13-0.82c0.02-0.15,0.03-0.3,0.04-0.44c0.07-0.83,0.14-1.69,0.94-2.3
							c0.01-0.01,0.02-0.05,0.02-0.08c0.01-0.02,0.01-0.05,0.02-0.07c0.14-0.43,0.34-0.89,0.93-1.01c-0.01,0-0.02-0.01-0.03-0.01
							c-0.85-0.31-1.73-0.36-2.6-0.15c-0.08,0.02-0.14,0.05-0.21,0.12c-0.72,0.69-0.78,1.56-0.85,2.49c0,0.06-0.01,0.12-0.01,0.18
							c0.08-0.11,0.16-0.23,0.24-0.35c0.16-0.25,0.33-0.5,0.55-0.72l0.12-0.11l0.09,0.14c0.14,0.21,0.09,0.39,0.06,0.52l-0.01,0.02
							c-0.11,0.41-0.22,0.81-0.33,1.21c-0.18,0.66-0.37,1.35-0.54,2.03c-0.04,0.17-0.09,0.34-0.14,0.5
							c-0.12,0.43-0.25,0.88-0.29,1.33l0,0.03l-0.01,0.03c-0.15,0.26-0.37,0.38-0.6,0.5c-0.11,0.06-0.21,0.11-0.29,0.18l-0.02,0.01
							C501.58,723.44,501.09,723.47,500.6,723.47z M500.04,723.19c0.09,0,0.19,0,0.28,0c0.56,0.01,1.09,0.01,1.59-0.24
							c0.1-0.08,0.21-0.14,0.32-0.19c0.19-0.1,0.37-0.2,0.47-0.37c0.05-0.47,0.17-0.91,0.3-1.35c0.05-0.16,0.09-0.33,0.13-0.49
							c0.17-0.68,0.36-1.37,0.55-2.03c0.11-0.4,0.22-0.81,0.33-1.21l0.01-0.03c0.01-0.03,0.02-0.06,0.02-0.09
							c-0.13,0.15-0.23,0.32-0.35,0.49c-0.16,0.24-0.33,0.5-0.55,0.71l-0.27,0.26l0.04-0.38c0.02-0.2,0.04-0.4,0.05-0.6
							c0.07-0.94,0.14-1.9,0.93-2.67c0.1-0.1,0.21-0.16,0.33-0.19c0.93-0.22,1.85-0.17,2.76,0.16c0.01,0,0.02,0.01,0.03,0.01
							c0.06,0.02,0.21,0.06,0.23,0.23c0.01,0.15-0.09,0.26-0.25,0.29c-0.41,0.08-0.58,0.37-0.73,0.83
							c-0.01,0.02-0.01,0.03-0.01,0.05c-0.02,0.07-0.04,0.16-0.12,0.22c-0.71,0.53-0.77,1.3-0.84,2.1c-0.01,0.15-0.03,0.3-0.04,0.45
							c-0.03,0.28-0.08,0.56-0.13,0.84c-0.08,0.47-0.17,0.96-0.16,1.45c0,0,0,0,0.01,0c0.1,0.01,0.2,0.01,0.3,0.04
							c0.46,0.12,0.84-0.01,1.18-0.42c0.15-0.18,0.32-0.36,0.48-0.52c0.26-0.27,0.51-0.53,0.7-0.83c0.4-0.62,0.99-0.9,1.83-0.84
							c0.22,0.01,0.44,0.02,0.66,0.02c0.22,0,0.44,0,0.66,0.02c0.22,0.01,0.28-0.05,0.31-0.2c-0.08-0.14-0.06-0.28-0.04-0.39
							c0.01-0.03,0.01-0.06,0.01-0.09c0.05-0.6,0.08-0.93-0.03-1.09c-0.12-0.16-0.44-0.23-1.03-0.36c-0.02,0-0.04-0.01-0.05-0.01
							c-0.01,0-0.02-0.01-0.04-0.01c-0.27-0.06-0.46-0.12-0.53-0.28c-0.07-0.16,0.03-0.33,0.16-0.55l0.18-0.29
							c0.55-0.9,1.11-1.83,1.68-2.73c0.21-0.34,0.12-0.51,0.01-0.62c-0.14-0.14-0.2-0.27-0.2-0.4c0-0.19,0.14-0.34,0.27-0.47
							c0.73-0.71,1.42-1.45,2.07-2.22c0.14-0.17,0.21-0.3,0.17-0.45c-0.15-0.51-0.26-1.04-0.36-1.54c-0.03-0.14-0.06-0.28-0.08-0.42
							c0-0.02-0.01-0.03-0.01-0.05c0-0.01,0-0.02-0.01-0.04c-0.01,0.03-0.02,0.06-0.04,0.1c-0.25,0.46-0.5,0.92-0.76,1.39
							l-0.33,0.61l-0.13-0.19c-0.15-0.23-0.09-0.42-0.04-0.55c0.01-0.02,0.01-0.03,0.02-0.05c0.15-0.45,0.26-0.93,0.37-1.38
							c0.13-0.56,0.27-1.14,0.47-1.69c0.08-0.21,0.08-0.44,0.08-0.67c0-0.93-0.01-2.04-0.06-3.18c-0.01-0.32-0.09-0.46-0.29-0.53
							c-0.91-0.31-1.26-0.95-1.11-2.02l0-0.01c0.03-0.24,0.07-0.5,0.02-0.75c-0.01,0.01-0.01,0.02-0.02,0.03
							c-0.12,0.17-0.22,0.32-0.31,0.49c-0.08,0.17-0.19,0.32-0.29,0.47c-0.17,0.25-0.33,0.48-0.39,0.77l-0.12,0.67
							c-0.16,0.85-0.32,1.73-0.48,2.59c0,0.02-0.01,0.04-0.01,0.06c-0.01,0.11-0.03,0.3-0.24,0.37l-0.09,0.03l-0.06-0.07
							c-0.11-0.12-0.11-0.26-0.11-0.35c0-0.02,0-0.03,0-0.05c-0.01-0.35-0.02-0.69-0.03-1.04c-0.02-0.73-0.04-1.49-0.07-2.24
							c0-0.07,0.01-0.13,0.02-0.2c0.03-0.19,0.02-0.23-0.05-0.26c-0.05-0.02-0.1,0.02-0.22,0.12c-0.05,0.04-0.1,0.09-0.16,0.13
							c-0.14,0.09-0.28,0.2-0.42,0.31c-0.35,0.29-0.71,0.58-1.26,0.57c-0.1,0-0.21,0.01-0.32,0.02c-0.08,0.01-0.17,0.02-0.26,0.02
							c-0.15,0.01-0.22,0.06-0.29,0.21l-0.02,0.04c-0.26,0.59-0.53,1.2-0.84,1.78c-0.38,0.71-0.44,1.31-0.21,1.93
							c0.09,0.23,0.13,0.47,0.19,0.75c0.03,0.13,0.05,0.27,0.09,0.42l0.1,0.44l-0.33-0.31c-0.3-0.28-0.49-0.57-0.67-0.86
							c-0.09-0.14-0.17-0.27-0.27-0.4c-0.09-0.12-0.14-0.15-0.17-0.15c-0.04,0-0.1,0.03-0.18,0.1c-0.23,0.2-0.46,0.39-0.69,0.58
							c-0.11,0.09-0.22,0.18-0.32,0.26l-0.08,0.06c-0.63,0.52-1.22,1.01-1.31,1.93c-0.02,0.23-0.05,0.46-0.08,0.69
							c-0.12,0.87-0.23,1.7,0.22,2.52c0.13,0.23,0.04,0.49-0.06,0.69c-0.23,0.48-0.51,0.94-0.78,1.38c-0.1,0.16-0.2,0.32-0.29,0.48
							c-0.06,0.1-0.12,0.19-0.17,0.28c-0.24,0.38-0.47,0.74-0.53,1.17c0,0.01-0.01,0.03-0.01,0.05c0,0.02-0.01,0.07-0.01,0.08
							c0.01-0.01,0.02-0.02,0.03-0.03c0.02-0.02,0.05-0.04,0.08-0.06c0.13-0.08,0.25-0.16,0.38-0.24c0.29-0.19,0.6-0.38,0.9-0.55
							c0.54-0.3,1.1-0.26,1.67-0.19l0.22,0.03l-0.12,0.18c-0.15,0.22-0.31,0.37-0.54,0.48c-0.77,0.37-1.24,0.96-1.42,1.78
							c-0.1,0.47-0.23,0.93-0.37,1.43c-0.04,0.13-0.07,0.27-0.11,0.41l0.15-0.07c0.36-0.17,0.67-0.32,0.99-0.47
							c0.02-0.01,0.04-0.02,0.06-0.04c0.08-0.05,0.24-0.17,0.41-0.03c0.19,0.16,0.07,0.35,0.02,0.44c-0.01,0.02-0.02,0.04-0.03,0.05
							c-0.64,1.34-0.89,2.62-0.77,3.93c0.03,0.33-0.2,0.55-0.35,0.69c-0.34,0.32-0.69,0.66-1.05,1.01
							c-0.04,0.04-0.06,0.07-0.08,0.09c0.02,0,0.04,0,0.05,0C499.99,723.2,500.02,723.19,500.04,723.19z"/>
					</g>
					<g>
						<path className="st72" d="M486.06,733.09c0.17,0.11,0.25,0.07,0.34,0.04c2.14-0.91,4.33-1.67,6.49-2.5
							c0.83-0.32,1.68-0.11,2.52-0.16c0.07,0,0.12,0.03,0.12,0.1c0,0.1-0.09,0.15-0.16,0.21c-0.34,0.25-0.69,0.5-1.03,0.76
							c-0.07,0.05-0.17,0.08-0.14,0.28c0.41-0.22,0.8-0.43,1.19-0.64c0.93-0.51,1.85-1.05,2.96-1.06c0.1,0,0.23-0.08,0.29,0.04
							c0.06,0.13-0.08,0.18-0.16,0.23c-0.84,0.56-1.69,1.13-2.54,1.69c-1.09,0.72-2.17,1.44-3.26,2.16
							c-0.42,0.28-0.84,0.55-1.24,0.84c-0.38,0.27-0.79,0.35-1.25,0.28c-1.07-0.15-2.14-0.26-3.2-0.42
							c-0.43-0.07-0.81,0.06-1.21,0.16c-1.44,0.35-2.91,0.43-4.37,0.62c-0.54,0.07-0.94,0.41-1.39,0.64
							c-2.94,1.57-5.86,3.18-8.79,4.77c-1.39,0.75-3.37,5.5-4.83,6.08c-0.71,0.29-9.66,3.8-10.43,4c0.37-0.31,9.44-8.13,9.8-8.45
							c1.55-1.42,3.42-2.34,5.2-3.41c0.62-0.37,1.27-0.71,1.87-1.11c0.7-0.46,1.48-0.6,2.28-0.68c0.25-0.02,0.42-0.11,0.58-0.3
							c0.68-0.85,1.37-1.7,2.05-2.55c0.2-0.24,0.42-0.4,0.75-0.47c1.47-0.32,2.92-0.68,4.39-1.01c0.77-0.17,1.45-0.54,2.12-0.9
							c1.56-0.84,3.12-1.69,4.67-2.55c0.43-0.24,0.9-0.25,1.36-0.31c0.87-0.12,1.74-0.22,2.62-0.33c0.13-0.02,0.27-0.06,0.45,0.06
							C491.36,730.4,488.72,731.7,486.06,733.09z"/>
						<path className="st8" d="M455.41,751.5l0.47-0.39c0.43-0.36,9.46-8.14,9.79-8.45c1.22-1.12,2.66-1.94,4.04-2.74
							c0.39-0.22,0.79-0.45,1.18-0.69c0.22-0.13,0.44-0.26,0.66-0.38c0.4-0.23,0.82-0.47,1.21-0.73c0.73-0.48,1.56-0.62,2.35-0.7
							c0.22-0.02,0.36-0.1,0.48-0.25c0.4-0.5,0.81-1.01,1.2-1.5c0.28-0.35,0.57-0.7,0.85-1.05c0.22-0.28,0.48-0.44,0.82-0.51
							c0.86-0.19,1.74-0.39,2.59-0.59c0.59-0.14,1.2-0.28,1.8-0.42c0.74-0.17,1.42-0.53,2.08-0.89l0.01-0.01
							c1.6-0.86,3.17-1.72,4.67-2.55c0.37-0.21,0.76-0.25,1.14-0.3c0.09-0.01,0.18-0.02,0.27-0.03c0.58-0.08,1.17-0.15,1.74-0.22
							c0.29-0.04,0.59-0.07,0.88-0.11c0.02,0,0.03,0,0.05-0.01c0.12-0.02,0.29-0.05,0.49,0.09l0.2,0.14l-0.23,0.1
							c-2.15,0.96-4.35,2.01-6.85,3.29c1.17-0.47,2.35-0.91,3.5-1.34c0.67-0.25,1.35-0.5,2.03-0.76c0.58-0.22,1.17-0.2,1.75-0.18
							c0.27,0.01,0.55,0.02,0.82,0.01c0.08,0,0.15,0.02,0.2,0.07c0.05,0.05,0.07,0.11,0.07,0.17c0,0.16-0.12,0.25-0.2,0.3
							l-0.32,0.24c-0.07,0.05-0.14,0.1-0.21,0.16c0.13-0.07,0.26-0.14,0.39-0.21l0.13-0.07c0.88-0.49,1.8-0.99,2.89-1.01
							c0.01,0,0.04,0,0.06-0.01c0.08-0.02,0.26-0.05,0.35,0.13c0.1,0.22-0.1,0.34-0.17,0.38c-0.01,0.01-0.03,0.02-0.04,0.02
							c-0.72,0.48-1.44,0.96-2.17,1.44l-1.55,1.03c-0.69,0.46-1.38,0.92-2.08,1.38l-0.27,0.18c-0.32,0.21-0.66,0.43-0.98,0.66
							c-0.39,0.28-0.83,0.38-1.34,0.31c-0.46-0.07-0.93-0.12-1.39-0.18c-0.59-0.07-1.21-0.15-1.81-0.24
							c-0.34-0.05-0.65,0.03-0.99,0.11c-0.06,0.01-0.11,0.03-0.17,0.04c-1.06,0.26-2.15,0.38-3.21,0.49
							c-0.39,0.04-0.78,0.08-1.17,0.13c-0.37,0.05-0.67,0.23-0.99,0.42c-0.11,0.07-0.23,0.14-0.35,0.21c-1.87,1-3.77,2.03-5.6,3.03
							c-1.07,0.58-2.13,1.16-3.2,1.74c-0.63,0.34-1.44,1.63-2.23,2.88c-0.93,1.49-1.82,2.89-2.62,3.21
							c-0.74,0.3-9.66,3.8-10.44,4.01L455.41,751.5z M493.57,729.27c-0.26,0.03-0.53,0.07-0.79,0.1c-0.57,0.07-1.16,0.14-1.73,0.22
							c-0.09,0.01-0.18,0.02-0.27,0.03c-0.37,0.04-0.72,0.08-1.04,0.26c-1.5,0.83-3.07,1.69-4.67,2.55l-0.01,0.01
							c-0.67,0.36-1.37,0.73-2.15,0.91c-0.6,0.14-1.21,0.28-1.8,0.42c-0.85,0.2-1.73,0.41-2.59,0.59c-0.28,0.06-0.48,0.19-0.67,0.42
							c-0.28,0.35-0.57,0.7-0.85,1.06c-0.39,0.49-0.8,1-1.2,1.5c-0.17,0.22-0.38,0.33-0.67,0.36c-0.87,0.09-1.58,0.23-2.22,0.66
							c-0.4,0.26-0.82,0.5-1.22,0.74c-0.22,0.12-0.44,0.25-0.65,0.38c-0.39,0.23-0.79,0.47-1.18,0.69c-1.37,0.79-2.8,1.61-4,2.7
							c-0.29,0.27-7.22,6.24-9.26,7.99c2.26-0.82,9.12-3.52,9.74-3.77c0.72-0.29,1.62-1.72,2.49-3.1c0.84-1.34,1.63-2.6,2.33-2.97
							c1.07-0.58,2.13-1.16,3.2-1.74c1.83-1,3.73-2.03,5.6-3.03c0.11-0.06,0.22-0.13,0.34-0.2c0.33-0.2,0.67-0.41,1.1-0.46
							c0.39-0.05,0.79-0.09,1.18-0.13c1.05-0.11,2.13-0.23,3.17-0.48c0.05-0.01,0.11-0.03,0.16-0.04c0.34-0.09,0.7-0.18,1.1-0.12
							c0.6,0.09,1.21,0.17,1.81,0.24c0.46,0.06,0.93,0.12,1.39,0.18c0.44,0.06,0.81-0.02,1.15-0.26c0.32-0.23,0.66-0.45,0.98-0.66
							l0.27-0.18c0.69-0.46,1.38-0.92,2.08-1.38l1.55-1.03c0.72-0.48,1.45-0.96,2.17-1.44c0.02-0.01,0.04-0.02,0.05-0.03
							c0,0,0.01-0.01,0.01-0.01c0,0,0,0,0,0c-0.03,0.01-0.07,0.01-0.11,0.01c-1.02,0.01-1.87,0.48-2.76,0.97l-0.13,0.07
							c-0.26,0.15-0.53,0.29-0.8,0.43c-0.13,0.07-0.25,0.14-0.38,0.2l-0.17,0.09l-0.03-0.19c-0.04-0.26,0.1-0.35,0.17-0.4
							c0.01-0.01,0.02-0.01,0.02-0.02c0.24-0.18,0.48-0.35,0.72-0.53l0.32-0.24c0.03-0.02,0.05-0.04,0.06-0.05
							c-0.27,0.01-0.54,0-0.8-0.01c-0.57-0.02-1.11-0.04-1.64,0.16c-0.68,0.26-1.36,0.52-2.03,0.76c-1.47,0.55-2.99,1.11-4.46,1.74
							c-0.09,0.04-0.24,0.1-0.46-0.05l-0.19-0.13l0.21-0.11C488.81,731.5,491.23,730.32,493.57,729.27z"/>
					</g>
					<g>
						<path className="st73" d="M475.66,728.71c0.23-0.17,0.32-0.44,0.44-0.68c0.16-0.33,0.4-0.47,0.75-0.49
							c0.88-0.06,1.75-0.13,2.63-0.18c0.23-0.01,0.4-0.09,0.57-0.24c0.58-0.53,1.18-1.04,1.75-1.59c0.2-0.19,0.32-0.14,0.49,0.02
							c0.3,0.27,0.61,0.52,1.01,0.85c0-0.48,0.01-0.84,0-1.2c-0.01-0.28,0.09-0.42,0.37-0.45c0.53-0.06,1.05-0.17,1.57-0.22
							c0.35-0.03,0.43-0.17,0.28-0.47c-0.3-0.61-0.6-1.22-0.9-1.84c-0.04-0.09-0.14-0.19-0.05-0.29c0.09-0.1,0.2-0.02,0.3,0
							c0.49,0.15,1,0.27,1.48,0.45c0.39,0.15,0.62,0.52,0.92,0.8c0.05,0.05,0.09,0.12,0.21,0.08c-0.03-0.35-0.3-0.58-0.49-0.82
							c-0.67-0.86-1.23-1.8-1.88-2.67c-0.11-0.15-0.12-0.21,0.07-0.29c0.31-0.14,0.57-0.13,0.84,0.08c0.33,0.27,0.68,0.51,1.01,0.76
							c0.06,0.05,0.12,0.11,0.2,0.05c0.07-0.05,0.03-0.13,0.01-0.21c-0.11-0.5-0.22-1.01-0.33-1.51c-0.13-0.59,0.43-0.52,0.7-0.71
							c0.24-0.18,0.36,0.05,0.49,0.23c0.88,1.27,1.77,2.53,2.66,3.79c0.05,0.07,0.09,0.16,0.27,0.12c-0.43-1.6-0.86-3.19-1.3-4.85
							c0.36,0.18,0.55,0.46,0.75,0.7c0.71,0.84,1.4,1.71,2.11,2.56c0.47,0.56,0.66,1.26,0.91,1.92c0.12,0.31,0.27,0.56,0.57,0.73
							c0.89,0.52,1.75,1.09,2.65,1.59c0.35,0.19,0.37,0.3,0.09,0.53c-0.04,0.04-0.07,0.1-0.1,0.15c-0.27,0.17-0.5,0.14-0.83,0.02
							c-1.07-0.43-2.15-0.87-3.27-1.11c-1.06-0.22-2.11,0.1-3.03,0.7c-1.12,0.73-2.16,1.57-3.36,2.17
							c-0.86,0.43-1.81,0.48-2.73,0.65c-0.29,0.06-0.25-0.24-0.32-0.41c-0.05-0.12-0.01-0.32-0.16-0.35
							c-0.19-0.04-0.21,0.19-0.28,0.3c-0.42,0.71-0.93,1.23-1.85,1.12c-0.26-0.03-0.43-0.08-0.48-0.36
							c-0.04-0.27-0.19-0.32-0.42-0.19c-0.09,0.05-0.19,0.1-0.28,0.15c-0.43,0.28-0.85,0.44-1.32,0.06
							c-0.26-0.21-0.42-0.03-0.55,0.17c-0.7,1.08-1.88,1.47-3.04,2.13C475.06,729.8,475.47,729.3,475.66,728.71z"/>
						<path className="st8" d="M474.51,730.8l0.15-0.36c0.14-0.33,0.3-0.62,0.45-0.89c0.17-0.3,0.32-0.57,0.42-0.87l0.01-0.04l0.04-0.03
							c0.17-0.12,0.25-0.31,0.34-0.51c0.02-0.04,0.04-0.08,0.05-0.12c0.17-0.36,0.44-0.54,0.86-0.57c0.34-0.02,0.69-0.05,1.03-0.07
							c0.52-0.04,1.06-0.08,1.6-0.11c0.19-0.01,0.34-0.07,0.48-0.21c0.22-0.2,0.44-0.4,0.66-0.59c0.36-0.32,0.74-0.65,1.09-0.99
							c0.3-0.28,0.5-0.14,0.67,0.02c0.2,0.18,0.41,0.36,0.65,0.56c0.04,0.04,0.09,0.07,0.13,0.11c0-0.05,0-0.09,0-0.14
							c0-0.28,0.01-0.52-0.01-0.76c-0.02-0.36,0.14-0.55,0.49-0.59c0.23-0.03,0.46-0.06,0.68-0.1c0.29-0.05,0.6-0.09,0.9-0.12
							c0.18-0.02,0.22-0.06,0.22-0.07c0-0.01,0.02-0.06-0.05-0.21l-0.27-0.54c-0.21-0.43-0.42-0.86-0.63-1.29
							c-0.01-0.01-0.02-0.03-0.03-0.05c-0.05-0.08-0.14-0.24,0-0.4c0.13-0.15,0.3-0.09,0.39-0.05c0.02,0.01,0.03,0.01,0.05,0.02
							c0.14,0.04,0.29,0.08,0.44,0.12c0.35,0.1,0.71,0.2,1.05,0.33c0.31,0.12,0.52,0.35,0.72,0.57c0.04,0.04,0.07,0.08,0.11,0.12
							c-0.06-0.09-0.13-0.17-0.2-0.26c-0.05-0.05-0.1-0.11-0.14-0.17c-0.36-0.47-0.69-0.96-1.02-1.44
							c-0.28-0.41-0.57-0.83-0.87-1.24c-0.07-0.09-0.13-0.18-0.1-0.29c0.03-0.11,0.13-0.16,0.22-0.21c0.38-0.17,0.69-0.14,0.99,0.1
							c0.2,0.16,0.41,0.31,0.61,0.46c0.13,0.1,0.27,0.2,0.4,0.3c0-0.01,0-0.01,0-0.02c-0.06-0.27-0.12-0.55-0.18-0.82
							c-0.05-0.23-0.1-0.46-0.15-0.68c-0.12-0.54,0.26-0.66,0.51-0.75c0.1-0.03,0.19-0.06,0.25-0.11c0.1-0.07,0.2-0.1,0.3-0.09
							c0.17,0.03,0.27,0.18,0.36,0.31l0.03,0.04c0.73,1.05,1.48,2.12,2.21,3.15l0.39,0.55l-1.3-4.82l0.27,0.14
							c0.33,0.17,0.53,0.41,0.71,0.63c0.03,0.04,0.06,0.07,0.09,0.1c0.39,0.46,0.78,0.94,1.16,1.4c0.31,0.38,0.63,0.77,0.95,1.15
							c0.4,0.48,0.6,1.05,0.8,1.59c0.04,0.12,0.09,0.24,0.13,0.36c0.12,0.33,0.28,0.53,0.51,0.66c0.33,0.2,0.66,0.4,0.99,0.6
							c0.54,0.33,1.1,0.68,1.66,0.98c0.2,0.11,0.34,0.21,0.35,0.37c0.01,0.16-0.11,0.28-0.24,0.39c-0.01,0.01-0.03,0.04-0.04,0.06
							c-0.01,0.02-0.02,0.04-0.04,0.06l-0.02,0.02l-0.03,0.02c-0.33,0.2-0.62,0.16-0.95,0.03l-0.2-0.08
							c-0.99-0.39-2.01-0.8-3.05-1.02c-0.93-0.2-1.94,0.04-2.92,0.68c-0.35,0.23-0.71,0.48-1.05,0.72c-0.74,0.52-1.5,1.05-2.32,1.46
							c-0.66,0.33-1.38,0.44-2.07,0.55c-0.23,0.04-0.46,0.07-0.69,0.12c-0.35,0.07-0.41-0.23-0.44-0.37
							c-0.01-0.05-0.02-0.09-0.03-0.12c-0.02-0.06-0.03-0.11-0.04-0.17c-0.01-0.03-0.01-0.09-0.03-0.11c-0.01,0-0.01,0-0.01,0
							c-0.02,0.01-0.05,0.08-0.06,0.12c-0.02,0.04-0.03,0.08-0.06,0.12c-0.39,0.66-0.92,1.31-1.99,1.19
							c-0.27-0.03-0.53-0.09-0.6-0.48c-0.01-0.09-0.04-0.13-0.06-0.14c-0.02-0.01-0.07,0-0.16,0.05c-0.04,0.02-0.08,0.05-0.13,0.07
							c-0.05,0.03-0.1,0.05-0.15,0.08c-0.42,0.28-0.93,0.5-1.48,0.05c-0.06-0.05-0.11-0.07-0.15-0.06c-0.07,0.01-0.15,0.12-0.2,0.2
							c-0.56,0.87-1.41,1.3-2.3,1.76c-0.26,0.13-0.52,0.27-0.78,0.41L474.51,730.8z M475.78,728.8c-0.11,0.31-0.26,0.59-0.43,0.88
							c-0.09,0.16-0.18,0.32-0.27,0.49c0.14-0.08,0.29-0.15,0.43-0.22c0.86-0.44,1.68-0.86,2.2-1.67c0.09-0.14,0.21-0.3,0.39-0.32
							c0.12-0.02,0.24,0.02,0.36,0.12c0.39,0.31,0.74,0.2,1.15-0.07c0.06-0.04,0.11-0.07,0.17-0.1c0.04-0.02,0.08-0.04,0.11-0.06
							c0.16-0.09,0.3-0.12,0.41-0.06c0.11,0.05,0.18,0.17,0.21,0.34c0.03,0.18,0.09,0.22,0.36,0.25c0.73,0.08,1.23-0.22,1.72-1.06
							c0.01-0.02,0.02-0.05,0.04-0.08c0.04-0.11,0.13-0.33,0.39-0.29c0.2,0.04,0.22,0.22,0.24,0.33c0.01,0.04,0.01,0.08,0.02,0.1
							c0.02,0.05,0.04,0.11,0.05,0.17c0.04,0.17,0.05,0.17,0.12,0.16c0.23-0.04,0.47-0.08,0.7-0.12c0.67-0.1,1.37-0.21,1.99-0.52
							c0.81-0.4,1.56-0.93,2.29-1.44c0.34-0.24,0.7-0.49,1.06-0.72c1.04-0.68,2.13-0.93,3.13-0.72c1.06,0.22,2.09,0.64,3.09,1.03
							l0.2,0.08c0.3,0.12,0.48,0.12,0.68,0.01c0-0.01,0.01-0.01,0.01-0.02c0.02-0.04,0.05-0.09,0.1-0.14
							c0.13-0.11,0.14-0.15,0.14-0.16c0,0-0.02-0.05-0.21-0.15c-0.57-0.31-1.13-0.66-1.67-0.99c-0.32-0.2-0.65-0.4-0.98-0.6
							c-0.37-0.22-0.53-0.54-0.63-0.8c-0.05-0.12-0.09-0.24-0.13-0.37c-0.2-0.55-0.39-1.07-0.76-1.51
							c-0.32-0.38-0.64-0.78-0.95-1.16c-0.38-0.46-0.77-0.94-1.16-1.4c-0.03-0.04-0.06-0.07-0.09-0.11c-0.1-0.12-0.2-0.25-0.33-0.36
							l1.25,4.63l-0.14,0.03c-0.25,0.06-0.35-0.09-0.4-0.16l-0.01-0.02l-0.45-0.64c-0.73-1.03-1.48-2.1-2.21-3.15l-0.03-0.04
							c-0.06-0.08-0.12-0.18-0.18-0.19c-0.03,0-0.07,0.02-0.09,0.04c-0.1,0.07-0.21,0.11-0.32,0.15c-0.27,0.09-0.39,0.14-0.33,0.43
							c0.05,0.23,0.1,0.46,0.15,0.68c0.06,0.27,0.12,0.55,0.18,0.82c0,0.01,0.01,0.02,0.01,0.03c0.02,0.07,0.06,0.22-0.07,0.32
							c-0.15,0.11-0.29,0.01-0.34-0.04l-0.02-0.01c-0.13-0.1-0.26-0.2-0.39-0.29c-0.21-0.15-0.42-0.31-0.62-0.47
							c-0.21-0.17-0.42-0.19-0.7-0.07c-0.03,0.01-0.04,0.02-0.06,0.03c0.01,0.01,0.02,0.03,0.04,0.06c0.31,0.41,0.59,0.83,0.87,1.25
							c0.32,0.47,0.65,0.96,1.01,1.42c0.04,0.05,0.09,0.1,0.13,0.16c0.17,0.2,0.36,0.42,0.39,0.74l0.01,0.1l-0.1,0.04
							c-0.19,0.07-0.29-0.03-0.34-0.09c-0.01-0.01-0.01-0.01-0.02-0.02c-0.09-0.09-0.18-0.18-0.26-0.27
							c-0.19-0.21-0.37-0.41-0.61-0.5c-0.33-0.13-0.69-0.22-1.03-0.32c-0.15-0.04-0.29-0.08-0.44-0.12
							c-0.02-0.01-0.04-0.01-0.07-0.02c-0.03-0.01-0.07-0.03-0.09-0.02l-0.01,0.01c0,0.01,0.02,0.05,0.04,0.07
							c0.01,0.02,0.03,0.04,0.04,0.06c0.21,0.43,0.42,0.86,0.63,1.29l0.27,0.54c0.06,0.12,0.13,0.31,0.05,0.46
							c-0.09,0.16-0.29,0.2-0.43,0.21c-0.29,0.03-0.59,0.08-0.88,0.12c-0.22,0.04-0.46,0.07-0.69,0.1
							c-0.21,0.03-0.27,0.09-0.25,0.31c0.01,0.25,0.01,0.49,0.01,0.78c0,0.13,0,0.27,0,0.43v0.29l-0.22-0.19
							c-0.13-0.1-0.24-0.2-0.35-0.29c-0.25-0.2-0.46-0.38-0.67-0.57c-0.14-0.13-0.17-0.14-0.3-0.02c-0.36,0.34-0.73,0.67-1.1,1
							c-0.22,0.2-0.44,0.39-0.66,0.59c-0.19,0.18-0.39,0.26-0.65,0.28c-0.53,0.03-1.07,0.07-1.59,0.11
							c-0.34,0.03-0.69,0.05-1.03,0.07c-0.32,0.02-0.51,0.14-0.63,0.41c-0.02,0.04-0.04,0.08-0.05,0.12
							C476.08,728.41,475.98,728.63,475.78,728.8z"/>
					</g>
					<g>
						<path className="st76" d="M514.36,721.07c-0.17,0.23-0.34,0.47-0.51,0.7c0.02,0.02,0.05,0.05,0.07,0.07
							c0.49-0.34,0.98-0.66,1.45-1.02c0.7-0.53,1.49-0.86,2.34-1.06c0.55-0.13,1.08-0.29,1.63-0.42c1.07-0.26,1.85-0.98,2.62-1.68
							c1.06-0.95,2.29-1.58,3.57-2.12c0.62-0.26,1.19-0.66,1.92-0.87c-0.2,0.36-0.51,0.46-0.75,0.62c-1.06,0.73-2.14,1.43-3.2,2.16
							c-0.84,0.57-1.39,1.43-2.07,2.16c-0.21,0.22-0.4,0.47-0.55,0.76c2.05-1.28,4.06-2.6,6.51-3.21c-0.22,0.33-0.51,0.5-0.76,0.71
							c-1.08,0.92-2.02,1.97-3,2.98c-1.04,1.08-2.09,2.15-3.13,3.23c-0.07,0.07-0.22,0.13-0.16,0.25c0.07,0.16,0.22,0.05,0.31,0.01
							c0.64-0.28,1.21,0,1.78,0.21c0.09,0.03,0.19,0.07,0.18,0.18c-0.01,0.1-0.12,0.13-0.2,0.15c-0.39,0.11-0.78,0.25-1.19,0.31
							c-1.1,0.18-2.01,0.73-2.9,1.36c-0.3,0.22-0.59,0.25-0.93,0.12c-0.65-0.24-1.34-0.39-1.98-0.66c-0.57-0.24-1.18-0.09-1.75,0.02
							c-1.11,0.22-2.01-0.28-2.96-0.66c-0.17-0.07-0.19-0.19-0.08-0.33c1.09-1.41,2.02-2.97,3.53-4c0.03-0.02,0.08-0.03,0.12-0.04
							C514.31,721.03,514.34,721.05,514.36,721.07z"/>
						<path className="st8" d="M517.79,726.89c-0.14,0-0.28-0.03-0.43-0.09c-0.28-0.11-0.58-0.2-0.87-0.28
							c-0.37-0.11-0.75-0.23-1.12-0.38c-0.5-0.21-1.04-0.1-1.56,0l-0.11,0.02c-0.98,0.19-1.81-0.16-2.61-0.49
							c-0.14-0.06-0.28-0.12-0.43-0.18c-0.15-0.06-0.21-0.15-0.22-0.22c-0.02-0.07-0.02-0.18,0.08-0.31c0.23-0.3,0.45-0.6,0.67-0.9
							c0.83-1.13,1.68-2.3,2.89-3.13c0.04-0.03,0.09-0.04,0.13-0.05c0.01,0,0.02-0.01,0.03-0.01l0.07-0.02l0.23,0.19l-0.29,0.4
							c0.01-0.01,0.03-0.02,0.04-0.03c0.33-0.23,0.67-0.46,1-0.71c0.68-0.51,1.46-0.87,2.39-1.08c0.29-0.07,0.59-0.15,0.87-0.22
							c0.25-0.07,0.5-0.14,0.76-0.2c0.99-0.24,1.75-0.92,2.48-1.57l0.08-0.07c1.16-1.03,2.47-1.66,3.61-2.15
							c0.23-0.1,0.45-0.21,0.68-0.33c0.39-0.2,0.79-0.41,1.26-0.55l0.32-0.09l-0.16,0.29c-0.17,0.3-0.41,0.44-0.61,0.56
							c-0.06,0.04-0.13,0.07-0.18,0.11c-0.52,0.36-1.06,0.72-1.58,1.07c-0.53,0.36-1.08,0.73-1.62,1.09
							c-0.56,0.39-1.01,0.92-1.43,1.43c-0.2,0.24-0.4,0.48-0.61,0.71c-0.07,0.07-0.13,0.15-0.19,0.22c1.85-1.15,3.76-2.33,6.02-2.89
							l0.34-0.09l-0.2,0.29c-0.18,0.27-0.4,0.43-0.59,0.58c-0.07,0.05-0.13,0.1-0.19,0.15c-0.85,0.72-1.63,1.55-2.38,2.34
							c-0.2,0.21-0.4,0.42-0.6,0.63c-0.64,0.66-1.28,1.32-1.92,1.98c-0.4,0.42-0.81,0.83-1.21,1.25c-0.03,0.03-0.05,0.05-0.08,0.07
							c-0.01,0.01-0.02,0.01-0.03,0.02c0.02-0.01,0.04-0.02,0.06-0.03c0.02-0.01,0.04-0.02,0.05-0.02c0.62-0.27,1.17-0.06,1.7,0.14
							c0.06,0.02,0.12,0.05,0.18,0.07c0.08,0.03,0.29,0.1,0.27,0.32c-0.02,0.19-0.21,0.25-0.3,0.27c-0.12,0.03-0.23,0.07-0.34,0.1
							c-0.28,0.08-0.57,0.17-0.86,0.22c-1.1,0.18-2.01,0.75-2.84,1.33C518.21,726.82,518.01,726.89,517.79,726.89z M514.73,725.75
							c0.25,0,0.5,0.04,0.74,0.14c0.36,0.15,0.73,0.26,1.1,0.37c0.29,0.09,0.59,0.18,0.88,0.29c0.31,0.12,0.54,0.09,0.81-0.1
							c0.85-0.6,1.8-1.19,2.95-1.38c0.28-0.05,0.56-0.13,0.83-0.21c0.11-0.03,0.23-0.07,0.34-0.1c0.03-0.01,0.08-0.02,0.1-0.03
							c-0.01-0.01-0.03-0.02-0.08-0.04c-0.06-0.02-0.12-0.05-0.19-0.07c-0.5-0.19-0.98-0.37-1.5-0.14
							c-0.01,0.01-0.02,0.01-0.04,0.02c-0.07,0.04-0.18,0.09-0.3,0.05c-0.05-0.02-0.11-0.06-0.16-0.15
							c-0.09-0.19,0.07-0.31,0.14-0.36c0.02-0.01,0.04-0.03,0.05-0.04c0.4-0.42,0.81-0.83,1.21-1.25c0.64-0.66,1.28-1.32,1.92-1.98
							c0.2-0.21,0.4-0.42,0.6-0.63c0.76-0.8,1.54-1.63,2.4-2.36c0.07-0.06,0.14-0.11,0.2-0.16c0.08-0.06,0.16-0.12,0.24-0.19
							c-2.08,0.6-3.88,1.73-5.63,2.82l-0.83,0.52l0.24-0.45c0.15-0.28,0.34-0.54,0.57-0.79c0.21-0.22,0.41-0.46,0.6-0.7
							c0.44-0.53,0.9-1.07,1.49-1.48c0.54-0.37,1.09-0.74,1.62-1.1c0.52-0.35,1.05-0.71,1.58-1.07c0.06-0.04,0.13-0.08,0.2-0.12
							c-0.18,0.09-0.36,0.18-0.54,0.27c-0.23,0.12-0.46,0.24-0.7,0.34c-1.12,0.47-2.41,1.09-3.53,2.1l-0.08,0.07
							c-0.75,0.68-1.53,1.38-2.6,1.63c-0.25,0.06-0.5,0.13-0.75,0.2c-0.29,0.08-0.58,0.16-0.88,0.23c-0.89,0.21-1.64,0.55-2.29,1.04
							c-0.33,0.25-0.67,0.49-1.01,0.71c-0.15,0.1-0.3,0.21-0.45,0.31l-0.09,0.07l-0.23-0.23l0.33-0.46c-1.06,0.79-1.84,1.86-2.6,2.9
							c-0.22,0.3-0.44,0.61-0.67,0.91c-0.03,0.04-0.04,0.07-0.03,0.08c0,0.01,0.02,0.02,0.06,0.04c0.14,0.06,0.29,0.12,0.43,0.18
							c0.8,0.33,1.56,0.65,2.45,0.48l0.11-0.02C514.07,725.82,514.4,725.75,514.73,725.75z M520.48,724.3L520.48,724.3L520.48,724.3
							z"/>
					</g>
					<g>
						<path className="st72" d="M531.05,729.9c0.57-0.51,0.92-1.12,1.36-1.67c1.17-1.48,2.8-2.43,4.19-3.66
							c0.25-0.22,0.51-0.43,0.77-0.64c0.07-0.06,0.15-0.13,0.24-0.1c0.13,0.04,0.11,0.17,0.11,0.27c0,0.29,0,0.58,0,0.86
							c0,0.11,0,0.23,0.12,0.27c0.1,0.03,0.16-0.05,0.24-0.1c0.61-0.38,0.98-0.87,0.83-1.64c-0.06-0.28,0.05-0.52,0.25-0.74
							c0.4-0.47,0.77-0.97,1.15-1.46c0.15-0.2,0.33-0.3,0.59-0.25c0.83,0.17,1.38-0.24,2.01-0.75c1.28-1.06,1.75-2.59,2.56-3.92
							c0.3-0.49,0.53-1.02,0.8-1.53c0.03-0.07,0.07-0.15,0.13-0.18c0.98-0.47,1.62-1.51,2.85-1.58c1.13-0.07,2.23-0.43,3.37-0.51
							c0.1-0.01,0.22-0.1,0.29,0.02c0.09,0.16-0.1,0.19-0.18,0.26c-0.49,0.45-0.98,0.89-1.47,1.34c-0.39,0.36-0.56,0.91-0.72,1.39
							c-0.24,0.68-0.7,1.08-1.29,1.34c-0.82,0.36-1.38,0.92-1.75,1.72c-0.23,0.5-0.66,0.8-1.1,1.11c-1.44,1.02-2.92,1.97-4.2,3.22
							c-1.28,1.26-2.65,2.44-4.01,3.62c-0.53,0.46-1.21,0.67-1.85,0.92c-1.64,0.63-3.16,1.49-4.7,2.32
							C531.47,729.9,531.33,730.01,531.05,729.9z"/>
						<path className="st8" d="M531.27,730.08c-0.08,0-0.17-0.02-0.28-0.06l-0.21-0.08l0.17-0.15c0.38-0.34,0.67-0.74,0.95-1.13
							c0.13-0.17,0.26-0.36,0.39-0.53c0.78-0.99,1.77-1.75,2.73-2.49c0.49-0.38,1-0.77,1.47-1.19c0.17-0.15,0.35-0.3,0.52-0.44
							c0.08-0.07,0.17-0.14,0.25-0.21c0.09-0.07,0.21-0.17,0.37-0.13c0.21,0.06,0.21,0.27,0.21,0.37l0,0.03c0,0.17,0,0.34,0,0.51
							c0,0.12,0,0.24,0,0.36v0.01c0,0.11,0.01,0.13,0.03,0.13c0.01,0,0.04-0.03,0.06-0.04c0.02-0.02,0.04-0.03,0.07-0.05
							c0.66-0.41,0.89-0.87,0.77-1.5c-0.08-0.39,0.12-0.68,0.28-0.86c0.31-0.36,0.6-0.75,0.89-1.12c0.09-0.11,0.17-0.22,0.26-0.34
							c0.2-0.26,0.44-0.36,0.72-0.3c0.75,0.16,1.26-0.2,1.89-0.73c0.88-0.72,1.38-1.71,1.86-2.66c0.21-0.41,0.42-0.83,0.67-1.23
							c0.2-0.32,0.37-0.67,0.54-1.01c0.08-0.17,0.17-0.35,0.26-0.52l0.01-0.02c0.04-0.08,0.09-0.17,0.18-0.22
							c0.34-0.17,0.64-0.4,0.96-0.65c0.55-0.44,1.12-0.89,1.94-0.94c0.57-0.03,1.15-0.15,1.71-0.26c0.54-0.1,1.09-0.21,1.65-0.25
							c0.01,0,0.04-0.01,0.06-0.01c0.08-0.02,0.26-0.08,0.36,0.11c0.12,0.23-0.08,0.35-0.15,0.39c-0.02,0.01-0.04,0.03-0.05,0.04
							c-0.31,0.29-0.62,0.57-0.93,0.85c-0.18,0.17-0.37,0.33-0.55,0.5c-0.33,0.3-0.49,0.76-0.64,1.2l-0.04,0.13
							c-0.23,0.65-0.67,1.12-1.36,1.42c-0.78,0.34-1.33,0.88-1.68,1.65c-0.24,0.53-0.7,0.85-1.14,1.16l-0.01,0.01
							c-0.27,0.19-0.54,0.38-0.81,0.56c-1.15,0.8-2.34,1.63-3.37,2.64c-1.18,1.16-2.45,2.26-3.67,3.33l-0.35,0.3
							c-0.47,0.41-1.06,0.63-1.63,0.84c-0.09,0.03-0.18,0.07-0.27,0.1c-1.39,0.53-2.72,1.25-4,1.94c-0.23,0.12-0.46,0.25-0.68,0.37
							c-0.01,0.01-0.03,0.02-0.04,0.02C531.55,730.02,531.43,730.08,531.27,730.08z M537.55,723.95c-0.02,0-0.07,0.04-0.1,0.07
							c-0.09,0.08-0.17,0.14-0.26,0.21c-0.17,0.14-0.34,0.28-0.51,0.43c-0.48,0.42-0.99,0.82-1.49,1.2
							c-0.94,0.73-1.92,1.48-2.68,2.44c-0.13,0.17-0.26,0.35-0.39,0.52c-0.24,0.33-0.48,0.66-0.78,0.97
							c0.06-0.01,0.1-0.04,0.16-0.07c0.02-0.01,0.03-0.02,0.05-0.03c0.23-0.12,0.46-0.24,0.68-0.37c1.29-0.7,2.63-1.42,4.03-1.96
							c0.09-0.03,0.18-0.07,0.27-0.1c0.54-0.2,1.11-0.41,1.54-0.79l0.35-0.3c1.22-1.06,2.49-2.16,3.66-3.32
							c1.04-1.03,2.25-1.86,3.41-2.67c0.27-0.19,0.54-0.37,0.8-0.56l0.01-0.01c0.43-0.3,0.83-0.59,1.05-1.05
							c0.38-0.83,0.97-1.42,1.82-1.79c0.63-0.28,1.01-0.68,1.22-1.26l0.04-0.13c0.16-0.45,0.33-0.97,0.72-1.32
							c0.18-0.17,0.37-0.33,0.55-0.5c0.3-0.27,0.62-0.56,0.92-0.84c0.03-0.03,0.07-0.05,0.1-0.07c0,0,0,0,0,0
							c-0.03,0.01-0.07,0.02-0.11,0.02c-0.54,0.04-1.09,0.15-1.62,0.25c-0.57,0.11-1.16,0.23-1.75,0.26
							c-0.73,0.04-1.24,0.45-1.79,0.88c-0.31,0.25-0.64,0.51-1.01,0.69c-0.01,0.01-0.04,0.07-0.06,0.1l-0.01,0.02
							c-0.09,0.17-0.18,0.34-0.26,0.51c-0.17,0.34-0.34,0.69-0.55,1.03c-0.24,0.39-0.45,0.81-0.66,1.22
							c-0.5,0.97-1.01,1.98-1.93,2.74c-0.67,0.55-1.26,0.96-2.12,0.78c-0.18-0.04-0.31,0.02-0.45,0.2
							c-0.09,0.11-0.17,0.22-0.26,0.33c-0.29,0.38-0.58,0.77-0.9,1.14c-0.19,0.23-0.26,0.42-0.22,0.63
							c0.15,0.74-0.15,1.32-0.89,1.79c-0.01,0.01-0.03,0.02-0.04,0.03c-0.06,0.05-0.17,0.13-0.32,0.08
							c-0.21-0.07-0.21-0.28-0.21-0.39v-0.01c0-0.12,0-0.24,0-0.36c0-0.17,0-0.33,0-0.5l0-0.03c0-0.03,0-0.09-0.01-0.1l0,0
							C537.57,723.96,537.56,723.96,537.55,723.95C537.56,723.95,537.56,723.95,537.55,723.95z"/>
					</g>
					<g>
						<path className="st72" d="M536.4,709.15c0.32,0.8,0.63,1.6,0.98,2.47c0.42-0.74,0.47-1.38,0.1-2.04c-0.23-0.41-0.08-0.57,0.34-0.6
							c0.24-0.02,0.48-0.04,0.72-0.09c0.39-0.08,0.73-0.1,0.79,0.43c0.03,0.26,0.16,0.23,0.34,0.1c0.54-0.41,0.97-0.97,1.62-1.24
							c0.46-0.19,0.92-0.35,1.37-0.56c0.32-0.15,0.61,0.12,0.84,0.38c-0.04,0.04-0.07,0.07-0.08,0.07
							c-0.46-0.22-0.68-0.01-0.89,0.41c-0.77,1.53-1.56,3.05-2.38,4.55c-0.7,1.3-1.44,2.56-2.65,3.51
							c-0.84,0.66-1.41,1.61-2.09,2.45c-0.59,0.74-1.17,1.49-1.8,2.21c0.04-0.39,0.08-0.76,0.32-1.08c1.12-1.45,2.23-2.91,3.36-4.35
							c0.2-0.26,0.24-0.56,0.32-0.84c0.27-0.88,0.75-1.6,1.57-2.17c-0.33-0.09-0.44,0.11-0.59,0.21c-0.59,0.39-1.17,0.81-1.77,1.2
							c-0.19,0.13-0.3,0.3-0.35,0.51c-0.11,0.47-0.25,0.94-0.31,1.42c-0.08,0.59-0.46,0.98-0.84,1.36c-0.48,0.49-0.9,0.99-1.15,1.66
							c-0.35,0.94-1.15,1.58-1.79,2.32c-0.21,0.25-0.68,0.32-1.04,0.4c-0.76,0.16-1.4,0.56-2.02,0.99
							c-0.09,0.06-0.19,0.09-0.28,0.14l0-0.01c0.05-0.3,0.33-0.38,0.51-0.55c0.61-0.58,1.28-1.08,2.11-1.28
							c0.66-0.16,0.88-0.7,1.16-1.18c0.31-0.54,0.55-1.11,0.89-1.64c0.19-0.3,0.27-0.62,0.2-0.99c-0.08-0.49-0.08-0.99-0.12-1.49
							c-0.02-0.35,0.23-0.57,0.46-0.75c0.45-0.36,0.61-0.8,0.43-1.31c-0.3-0.82,0.07-1.43,0.54-2.02c0.14-0.18,0.17-0.33,0.11-0.54
							c-0.1-0.4-0.15-0.8-0.25-1.2c-0.09-0.38,0.02-0.61,0.41-0.71c0.22-0.05,0.43-0.14,0.65-0.2
							C536.23,709.12,536.31,709.14,536.4,709.15z"/>
						<path className="st8" d="M529.02,723.14l-0.12-0.12l0.01-0.07c0.04-0.27,0.23-0.4,0.39-0.51c0.06-0.04,0.11-0.08,0.16-0.12
							c0.52-0.49,1.24-1.08,2.17-1.31c0.52-0.13,0.75-0.53,0.98-0.95c0.03-0.06,0.06-0.11,0.1-0.17c0.13-0.22,0.25-0.46,0.37-0.69
							c0.16-0.32,0.33-0.64,0.52-0.95c0.18-0.29,0.24-0.57,0.18-0.9c-0.06-0.36-0.08-0.72-0.1-1.08c-0.01-0.14-0.01-0.28-0.02-0.42
							c-0.03-0.44,0.29-0.69,0.51-0.86c0.41-0.33,0.54-0.72,0.39-1.16c-0.33-0.92,0.12-1.6,0.56-2.15c0.11-0.14,0.13-0.24,0.09-0.42
							c-0.05-0.21-0.09-0.42-0.13-0.62c-0.04-0.19-0.07-0.39-0.12-0.58c-0.11-0.47,0.06-0.76,0.51-0.87
							c0.15-0.04,0.29-0.08,0.44-0.13c0.07-0.02,0.14-0.05,0.2-0.07l0.03-0.01l0.35,0.06l0.9,2.27c0.27-0.6,0.26-1.12-0.03-1.64
							c-0.13-0.24-0.16-0.42-0.09-0.56c0.1-0.19,0.36-0.23,0.54-0.24c0.27-0.02,0.5-0.05,0.7-0.09c0.24-0.05,0.51-0.09,0.72,0.05
							c0.14,0.1,0.21,0.26,0.24,0.49c0,0.03,0.01,0.06,0.01,0.07c0.02-0.01,0.05-0.03,0.11-0.07c0.18-0.14,0.36-0.3,0.53-0.45
							c0.33-0.3,0.67-0.62,1.12-0.8c0.18-0.08,0.37-0.15,0.55-0.22c0.27-0.1,0.55-0.21,0.82-0.34c0.45-0.21,0.84,0.23,1,0.42
							l0.09,0.1l-0.1,0.09c-0.01,0.01-0.03,0.02-0.04,0.03c-0.03,0.03-0.1,0.1-0.2,0.06c-0.33-0.16-0.49-0.08-0.71,0.35
							c-0.77,1.52-1.57,3.05-2.38,4.55c-0.68,1.26-1.45,2.57-2.69,3.55c-0.59,0.47-1.07,1.11-1.53,1.73
							c-0.17,0.23-0.35,0.48-0.54,0.71c-0.12,0.15-0.24,0.3-0.36,0.45c-0.46,0.58-0.94,1.18-1.45,1.76l-0.28,0.32l0.04-0.42
							c0.04-0.39,0.08-0.79,0.35-1.15c0.38-0.49,0.75-0.98,1.13-1.47c0.73-0.95,1.48-1.93,2.23-2.88c0.14-0.18,0.19-0.38,0.24-0.59
							c0.02-0.07,0.04-0.14,0.06-0.2c0.19-0.63,0.49-1.16,0.9-1.62c-0.21,0.14-0.43,0.29-0.63,0.43c-0.28,0.19-0.56,0.39-0.85,0.58
							c-0.16,0.1-0.25,0.24-0.29,0.43c-0.03,0.13-0.06,0.27-0.1,0.4c-0.08,0.33-0.17,0.67-0.21,1.01c-0.09,0.63-0.49,1.04-0.87,1.43
							c-0.51,0.51-0.89,0.98-1.12,1.61c-0.27,0.72-0.79,1.27-1.3,1.81c-0.17,0.18-0.35,0.37-0.51,0.56c-0.22,0.26-0.63,0.34-1,0.42
							c-0.04,0.01-0.08,0.02-0.12,0.02c-0.75,0.16-1.39,0.56-1.97,0.97c-0.07,0.05-0.14,0.08-0.22,0.11
							c-0.03,0.01-0.06,0.03-0.09,0.04L529.02,723.14z M536.16,709.25c-0.06,0.02-0.11,0.04-0.17,0.06c-0.15,0.05-0.3,0.1-0.46,0.14
							c-0.3,0.08-0.39,0.22-0.31,0.54c0.05,0.2,0.09,0.4,0.12,0.59c0.04,0.2,0.08,0.41,0.13,0.61c0.07,0.26,0.02,0.46-0.14,0.66
							c-0.51,0.64-0.78,1.17-0.52,1.89c0.2,0.55,0.04,1.06-0.47,1.47c-0.24,0.19-0.42,0.36-0.4,0.63c0.01,0.14,0.02,0.28,0.02,0.43
							c0.02,0.35,0.03,0.7,0.09,1.04c0.07,0.39,0,0.75-0.22,1.09c-0.19,0.3-0.35,0.62-0.51,0.93c-0.12,0.23-0.24,0.47-0.37,0.7
							c-0.03,0.05-0.06,0.11-0.09,0.17c-0.25,0.46-0.52,0.93-1.16,1.08c-0.75,0.18-1.36,0.62-1.83,1.04
							c0.44-0.27,0.92-0.49,1.46-0.61c0.04-0.01,0.08-0.02,0.12-0.03c0.32-0.07,0.68-0.14,0.84-0.33c0.17-0.19,0.35-0.39,0.52-0.57
							c0.49-0.51,0.99-1.05,1.24-1.71c0.27-0.71,0.73-1.24,1.18-1.7c0.38-0.38,0.73-0.74,0.8-1.28c0.05-0.35,0.13-0.7,0.22-1.03
							c0.03-0.13,0.07-0.26,0.1-0.4c0.06-0.25,0.19-0.45,0.41-0.59c0.28-0.19,0.57-0.39,0.84-0.58c0.3-0.21,0.61-0.42,0.92-0.63
							c0.02-0.02,0.05-0.04,0.08-0.06c0.12-0.1,0.29-0.26,0.62-0.16l0.28,0.08l-0.24,0.17c-0.76,0.54-1.25,1.21-1.52,2.1
							c-0.02,0.06-0.04,0.13-0.05,0.19c-0.06,0.23-0.12,0.47-0.29,0.69c-0.75,0.95-1.5,1.93-2.22,2.88
							c-0.38,0.49-0.75,0.98-1.13,1.47c-0.13,0.17-0.2,0.36-0.24,0.56c0.39-0.47,0.77-0.94,1.14-1.41c0.12-0.15,0.24-0.3,0.36-0.45
							c0.18-0.23,0.36-0.47,0.53-0.7c0.47-0.63,0.95-1.28,1.58-1.78c1.2-0.95,1.92-2.18,2.61-3.47c0.81-1.5,1.61-3.03,2.37-4.55
							c0.24-0.48,0.5-0.6,0.74-0.57c-0.17-0.13-0.3-0.16-0.43-0.1c-0.28,0.13-0.56,0.24-0.84,0.35c-0.18,0.07-0.36,0.14-0.54,0.21
							c-0.4,0.17-0.71,0.45-1.04,0.75c-0.17,0.16-0.35,0.32-0.55,0.47c-0.11,0.08-0.25,0.17-0.38,0.11
							c-0.14-0.06-0.17-0.24-0.17-0.3c-0.02-0.15-0.06-0.26-0.13-0.3c-0.1-0.07-0.27-0.05-0.5-0.01c-0.22,0.05-0.45,0.08-0.74,0.1
							c-0.25,0.02-0.3,0.08-0.32,0.1c-0.01,0.02-0.03,0.1,0.08,0.3c0.38,0.68,0.35,1.37-0.1,2.17l-0.14,0.25l-1.06-2.67
							L536.16,709.25z M539.49,709.39L539.49,709.39L539.49,709.39z"/>
					</g>
					<g>
						<path className="st72" d="M468.72,735.73c-1.33,1-2.62,2.06-3.91,3.11c-1.15,0.94-2.45,1.67-3.73,2.43
							c-0.25,0.15-0.46,0.39-0.84,0.46c0.31-0.56,0.86-0.85,1.06-1.38c-0.56,0.28-1.02,0.7-1.44,1.13
							c-0.92,0.97-2.07,1.45-3.32,1.77c-1.99,0.5-3.85,1.28-5.49,2.54c-0.06,0.04-3.21,3.02-3.3,2.99c-0.07-0.11,1.66-3.2,1.72-3.26
							c0.57-0.59,2.62-1.05,3.17-1.65c0.32-0.36,0.72-0.54,1.17-0.66c0.59-0.15,1.17-0.32,1.76-0.47c0.51-0.13,0.94-0.39,1.31-0.76
							c1.03-1.03,2.12-2,3.11-3.06c0.64-0.69,1.46-0.82,2.27-1.11c0.84-0.29,1.7-0.53,2.55-0.76c0.36-0.1,0.61-0.36,0.87-0.6
							c0.9-0.86,1.79-1.72,2.7-2.58c0.1-0.1,0.17-0.26,0.46-0.23c-1.17,1.21-2.29,2.37-3.4,3.53c0.5,0.04,0.94,0.02,1.19-0.43
							c0.1-0.17,0.25-0.32,0.45-0.37c0.62-0.15,1.14-0.48,1.64-0.84C468.72,735.58,468.72,735.65,468.72,735.73z"/>
						<path className="st8" d="M447.75,748.92c-0.01,0-0.03,0-0.04-0.01l-0.04-0.02l-0.03-0.04c-0.06-0.09-0.09-0.14,0.8-1.79
							c0.24-0.44,0.87-1.58,0.93-1.64c0.3-0.31,0.93-0.57,1.6-0.84c0.64-0.26,1.3-0.53,1.56-0.81c0.31-0.34,0.69-0.55,1.23-0.7
							c0.27-0.07,0.54-0.14,0.81-0.22c0.31-0.09,0.63-0.17,0.95-0.26c0.47-0.12,0.88-0.36,1.25-0.73c0.4-0.4,0.81-0.79,1.21-1.17
							c0.64-0.61,1.29-1.24,1.9-1.89c0.54-0.58,1.2-0.79,1.85-0.99c0.16-0.05,0.32-0.1,0.48-0.16c0.81-0.28,1.65-0.51,2.46-0.74
							l0.11-0.03c0.3-0.08,0.51-0.29,0.74-0.51l0.07-0.07c0.44-0.42,0.88-0.84,1.32-1.26c0.46-0.44,0.91-0.88,1.37-1.31
							c0.02-0.02,0.03-0.03,0.05-0.05c0.09-0.1,0.21-0.25,0.52-0.22l0.29,0.03l-3.39,3.52c0.36,0,0.6-0.09,0.76-0.38
							c0.13-0.23,0.32-0.38,0.53-0.44c0.61-0.15,1.13-0.49,1.6-0.82l0.21-0.15l0,0.56l-0.05,0.04c-1.21,0.91-2.41,1.89-3.57,2.83
							l-0.34,0.28c-1.07,0.87-2.27,1.57-3.43,2.26l-0.31,0.18c-0.07,0.04-0.13,0.09-0.21,0.14c-0.18,0.13-0.38,0.28-0.68,0.34
							l-0.29,0.06l0.14-0.26c0.15-0.28,0.35-0.48,0.55-0.68c0.07-0.07,0.13-0.14,0.19-0.2c-0.29,0.21-0.58,0.47-0.89,0.8
							c-0.82,0.86-1.86,1.42-3.38,1.81c-2.18,0.55-3.96,1.38-5.44,2.51c-0.02,0.02-0.2,0.18-0.42,0.4
							C448.18,748.66,447.87,748.92,447.75,748.92z M449.58,745.61c-0.14,0.21-1.08,1.9-1.5,2.73c0.64-0.57,1.89-1.73,2.44-2.25
							c0.37-0.35,0.43-0.4,0.44-0.41c1.51-1.16,3.32-2,5.54-2.56c1.47-0.37,2.47-0.91,3.25-1.73c0.33-0.34,0.83-0.84,1.47-1.16
							l0.32-0.16l-0.13,0.33c-0.12,0.32-0.35,0.55-0.57,0.78c-0.07,0.08-0.15,0.15-0.22,0.23c0.05-0.03,0.09-0.07,0.14-0.1
							c0.07-0.05,0.15-0.11,0.23-0.16l0.31-0.18c1.15-0.68,2.35-1.37,3.4-2.23l0.34-0.28c0.93-0.76,1.89-1.54,2.86-2.29
							c-0.25,0.13-0.52,0.24-0.81,0.31c-0.19,0.05-0.31,0.21-0.36,0.3c-0.3,0.54-0.84,0.54-1.32,0.5l-0.29-0.02l1.51-1.56
							c-0.29,0.28-0.58,0.55-0.87,0.83l-0.07,0.06c-0.24,0.23-0.49,0.47-0.86,0.57l-0.11,0.03c-0.81,0.22-1.64,0.45-2.44,0.73
							c-0.16,0.06-0.33,0.11-0.49,0.16c-0.64,0.2-1.24,0.39-1.73,0.92c-0.61,0.66-1.27,1.29-1.91,1.9c-0.4,0.38-0.81,0.77-1.2,1.17
							c-0.4,0.41-0.86,0.67-1.38,0.8c-0.32,0.08-0.64,0.17-0.95,0.25c-0.27,0.07-0.54,0.15-0.81,0.22c-0.49,0.13-0.83,0.32-1.1,0.62
							c-0.3,0.33-0.96,0.6-1.66,0.88C450.47,745.09,449.84,745.35,449.58,745.61z"/>
					</g>
					<g>
						<path className="st72" d="M508.28,735.27c0.52-0.16,0.89-0.47,1.28-0.7c0.29-0.17,0.55-0.29,0.88-0.22
							c0.12,0.03,0.25-0.01,0.36,0.03c0.91,0.3,1.4-0.2,1.82-0.89c0.19-0.31,0.37-0.63,0.62-0.89c0.36-0.39,0.91-0.38,1.38-0.49
							c0.2-0.04,0.12,0.14,0.05,0.25c-0.18,0.28-0.37,0.56-0.54,0.84c-0.05,0.08-0.15,0.16-0.06,0.31c0.41-0.34,0.73-0.77,1.24-0.99
							c-0.7,0.84-1.13,1.89-1.97,2.62c-0.47,0.41-1.12,0.52-1.69,0.76c-2.59,1.08-5.18,2.13-7.78,3.19c-0.24,0.1-0.46,0.22-0.7,0.32
							c-0.2,0.09-0.34,0.02-0.22-0.19c0.56-1.01,0.83-2.19,2.03-2.78c1.19-0.59,2.31-1.32,3.47-1.99c0.09-0.05,0.15-0.16,0.31-0.09
							C508.75,734.7,508.41,734.88,508.28,735.27z"/>
						<path className="st8" d="M503.05,739.58c-0.08,0-0.14-0.03-0.19-0.08c-0.05-0.05-0.1-0.16,0-0.35c0.13-0.23,0.24-0.48,0.36-0.71
							c0.38-0.81,0.77-1.65,1.74-2.12c0.82-0.4,1.62-0.89,2.4-1.36c0.35-0.21,0.7-0.43,1.06-0.63c0.01-0.01,0.03-0.02,0.04-0.03
							c0.07-0.05,0.2-0.15,0.39-0.06l0.09,0.04l-0.01,0.1c-0.02,0.22-0.15,0.38-0.26,0.54c-0.03,0.03-0.05,0.07-0.08,0.1
							c0.21-0.1,0.4-0.22,0.58-0.34c0.11-0.07,0.22-0.15,0.34-0.21c0.29-0.17,0.59-0.32,0.98-0.23c0.04,0.01,0.08,0.01,0.13,0.01
							c0.07,0,0.16,0,0.24,0.02c0.8,0.27,1.24-0.14,1.66-0.83l0.02-0.03c0.18-0.31,0.37-0.62,0.62-0.88
							c0.31-0.33,0.72-0.4,1.12-0.46c0.12-0.02,0.23-0.04,0.33-0.06c0.15-0.04,0.23,0.03,0.26,0.07c0.09,0.12,0,0.29-0.06,0.38
							c-0.07,0.1-0.13,0.21-0.2,0.31c-0.06,0.09-0.11,0.17-0.17,0.26c0.24-0.23,0.5-0.47,0.84-0.61l0.52-0.22l-0.36,0.44
							c-0.27,0.33-0.51,0.69-0.74,1.05c-0.36,0.56-0.73,1.13-1.25,1.58c-0.35,0.31-0.79,0.46-1.21,0.6
							c-0.17,0.06-0.35,0.12-0.51,0.19c-1.77,0.74-3.58,1.47-5.33,2.19c-0.82,0.33-1.63,0.67-2.45,1c-0.13,0.05-0.26,0.12-0.38,0.18
							c-0.1,0.05-0.21,0.1-0.31,0.15C503.18,739.56,503.11,739.58,503.05,739.58z M508.58,734.54c-0.01,0.01-0.02,0.02-0.04,0.02
							c-0.35,0.21-0.71,0.42-1.06,0.63c-0.78,0.47-1.59,0.96-2.42,1.37c-0.88,0.43-1.23,1.19-1.61,1.99
							c-0.11,0.24-0.23,0.49-0.36,0.73c0,0.01-0.01,0.01-0.01,0.02c0.02,0,0.03-0.01,0.06-0.02c0.1-0.04,0.2-0.09,0.3-0.14
							c0.13-0.06,0.26-0.13,0.4-0.18c0.82-0.33,1.63-0.67,2.45-1c1.75-0.71,3.55-1.45,5.32-2.19c0.17-0.07,0.35-0.13,0.53-0.19
							c0.42-0.14,0.81-0.27,1.12-0.55c0.49-0.43,0.84-0.96,1.2-1.53c0.09-0.14,0.18-0.28,0.27-0.41c-0.07,0.06-0.14,0.13-0.21,0.2
							c-0.11,0.11-0.23,0.23-0.36,0.33l-0.12,0.1l-0.08-0.14c-0.12-0.2-0.02-0.35,0.04-0.42c0.01-0.01,0.02-0.02,0.02-0.03
							c0.11-0.18,0.23-0.36,0.34-0.54c0.07-0.1,0.13-0.2,0.2-0.31c0.01-0.01,0.01-0.02,0.01-0.02c-0.09,0.02-0.18,0.03-0.27,0.05
							c-0.37,0.06-0.72,0.12-0.96,0.38c-0.23,0.24-0.41,0.54-0.58,0.84l-0.02,0.03c-0.4,0.67-0.95,1.3-1.98,0.95
							c-0.04-0.01-0.09-0.01-0.15-0.01c-0.06,0-0.13,0-0.2-0.01c-0.29-0.06-0.53,0.05-0.79,0.2c-0.11,0.06-0.22,0.13-0.33,0.21
							c-0.29,0.19-0.59,0.38-0.98,0.5l-0.25,0.08l0.08-0.25c0.07-0.21,0.18-0.36,0.29-0.49
							C508.49,734.67,508.54,734.6,508.58,734.54z"/>
					</g>
					<g>
						<path className="st73" d="M462.25,732.28c-0.11-0.18-0.08-0.38-0.08-0.58c0-0.88,0.01-1.76,0-2.64c-0.01-0.36,0.11-0.5,0.47-0.56
							c0.7-0.1,1.4-0.28,2.1-0.41c0.25-0.05,0.39-0.17,0.51-0.39c0.52-0.9,1.07-1.79,1.61-2.69c0.17-0.28,0.37-0.44,0.73-0.42
							c0.51,0.03,1.03,0.01,1.55,0c0.33-0.01,0.47,0.11,0.31,0.44c-0.12,0.25-0.14,0.5-0.02,0.74c0.14,0.29,0.04,0.51-0.14,0.75
							c-0.6,0.78-1.23,1.54-1.94,2.22c-0.13,0.13-0.27,0.24-0.34,0.42c-0.1,0.07-0.2,0.13-0.3,0.2c-0.14-0.47,0.14-1-0.2-1.58
							c-0.12,0.73-0.25,1.33-0.43,1.92c-0.85,0.7-1.81,1.23-2.71,1.86c-0.09,0.06-0.2,0.06-0.31,0.05
							c-0.03-0.54-0.25-1.02-0.52-1.52c-0.1,0.72-0.19,1.45-0.29,2.17L462.25,732.28z"/>
						<path className="st8" d="M462.21,732.49l-0.08-0.14c-0.11-0.19-0.11-0.38-0.1-0.56c0-0.03,0-0.06,0-0.09c0-0.31,0-0.61,0-0.92
							c0-0.56,0.01-1.15-0.01-1.72c-0.01-0.43,0.16-0.63,0.59-0.7c0.42-0.06,0.84-0.15,1.25-0.24c0.28-0.06,0.56-0.12,0.84-0.17
							c0.19-0.03,0.3-0.12,0.42-0.32c0.33-0.57,0.68-1.14,1.01-1.7c0.2-0.33,0.4-0.66,0.6-0.99c0.21-0.36,0.48-0.51,0.86-0.49
							c0.45,0.03,0.91,0.02,1.35,0.01l0.19,0c0.24-0.01,0.39,0.05,0.47,0.17c0.07,0.12,0.06,0.27-0.03,0.46
							c-0.11,0.22-0.12,0.43-0.02,0.62c0.18,0.37,0.03,0.66-0.15,0.89c-0.5,0.64-1.17,1.48-1.95,2.24
							c-0.02,0.02-0.04,0.04-0.06,0.06c-0.1,0.1-0.2,0.19-0.25,0.31l-0.02,0.04l-0.49,0.32l-0.05-0.18
							c-0.06-0.21-0.05-0.43-0.04-0.64c0.01-0.13,0.02-0.26,0.01-0.39c-0.09,0.49-0.2,0.93-0.33,1.37l-0.01,0.04l-0.03,0.03
							c-0.53,0.44-1.12,0.82-1.69,1.19c-0.34,0.22-0.69,0.45-1.03,0.68c-0.14,0.1-0.29,0.09-0.41,0.07l-0.11-0.02l-0.01-0.11
							c-0.02-0.36-0.13-0.71-0.3-1.07l-0.24,1.79L462.21,732.49z M462.47,729.67l0.2,0.37c0.28,0.51,0.47,0.96,0.52,1.46
							c0.04,0,0.07-0.01,0.1-0.03c0.34-0.24,0.69-0.47,1.04-0.69c0.55-0.35,1.12-0.72,1.63-1.14c0.17-0.58,0.3-1.16,0.41-1.86
							l0.06-0.38l0.19,0.33c0.23,0.38,0.2,0.74,0.19,1.06c-0.01,0.12-0.01,0.24-0.01,0.36l0.09-0.06c0.07-0.16,0.19-0.27,0.3-0.37
							c0.02-0.02,0.04-0.04,0.06-0.06c0.77-0.75,1.43-1.57,1.92-2.21c0.19-0.25,0.22-0.4,0.13-0.6c-0.14-0.27-0.13-0.57,0.02-0.87
							c0.06-0.12,0.06-0.18,0.05-0.19c0,0-0.03-0.05-0.23-0.04l-0.19,0c-0.45,0.01-0.91,0.02-1.37-0.01
							c-0.28-0.02-0.45,0.08-0.61,0.35c-0.2,0.33-0.4,0.66-0.6,0.99c-0.33,0.55-0.68,1.13-1.01,1.7c-0.16,0.27-0.34,0.4-0.61,0.45
							c-0.28,0.05-0.56,0.11-0.84,0.17c-0.41,0.09-0.84,0.18-1.26,0.24c-0.29,0.04-0.36,0.13-0.36,0.42
							c0.01,0.58,0.01,1.16,0.01,1.73c0,0.04,0,0.08,0,0.12L462.47,729.67z"/>
					</g>
					<g>
						<path className="st72" d="M473.17,728.51c0.03,0.04,0.07,0.07,0.1,0.11c-0.96,0.52-1.92,1.04-2.87,1.57
							c-0.4,0.23-0.62,0.65-0.8,1.03c-0.6,1.28-1.69,2.02-2.8,2.76c-1.12,0.75-2.23,1.52-3.34,2.28c-0.13,0.09-0.25,0.17-0.41,0.19
							l0,0c0-0.21,0.18-0.31,0.31-0.43c1.02-0.94,2.05-1.88,3.08-2.83c0.06-0.06,0.15-0.11,0.08-0.29
							c-0.66,0.43-1.38,0.75-2.03,1.19c0.08-0.37,0.32-0.62,0.51-0.91c0.42-0.65,0.88-1.27,1.29-1.92c0.37-0.58,1.02-0.74,1.54-1.08
							c0.66-0.45,1.37-0.82,2.07-1.22c0.12-0.07,0.25-0.13,0.38-0.15C471.25,728.71,472.21,728.62,473.17,728.51z"/>
						<path className="st8" d="M463.2,736.79l-0.43-0.44l0.18-0.02c0.05-0.16,0.16-0.25,0.26-0.33c0.03-0.02,0.05-0.04,0.07-0.06
							c0.7-0.64,1.4-1.29,2.1-1.93c0.24-0.22,0.49-0.45,0.73-0.67c-0.16,0.09-0.32,0.18-0.48,0.27c-0.35,0.19-0.71,0.39-1.05,0.62
							l-0.28,0.19l0.07-0.33c0.07-0.31,0.23-0.53,0.39-0.75c0.05-0.07,0.1-0.13,0.14-0.2c0.19-0.29,0.39-0.58,0.59-0.87
							c0.23-0.34,0.48-0.7,0.7-1.05c0.27-0.43,0.68-0.64,1.08-0.84c0.18-0.09,0.34-0.18,0.5-0.28c0.5-0.34,1.03-0.64,1.55-0.93
							c0.18-0.1,0.35-0.2,0.53-0.3c0.1-0.06,0.26-0.14,0.43-0.16c0.73-0.08,1.47-0.16,2.19-0.24l0.77-0.08l0.26,0.29l-0.15,0.08
							c-0.28,0.15-0.55,0.3-0.83,0.45c-0.67,0.36-1.37,0.74-2.04,1.12c-0.36,0.2-0.56,0.59-0.74,0.97
							c-0.63,1.34-1.81,2.12-2.85,2.81c-0.85,0.56-1.7,1.15-2.52,1.72c-0.27,0.19-0.54,0.37-0.81,0.56l-0.01,0.01
							c-0.1,0.07-0.2,0.14-0.33,0.18L463.2,736.79z M466.61,732.7l0.06,0.16c0.09,0.25-0.04,0.37-0.11,0.43l-0.01,0.01
							c-0.33,0.3-0.65,0.6-0.98,0.9c-0.65,0.6-1.31,1.2-1.96,1.8c0.2-0.14,0.4-0.27,0.6-0.41c0.82-0.57,1.68-1.16,2.53-1.72
							c1.06-0.7,2.15-1.43,2.75-2.7c0.2-0.42,0.43-0.85,0.85-1.09c0.68-0.38,1.37-0.76,2.05-1.12c0.16-0.09,0.33-0.18,0.49-0.27
							l-0.38,0.04c-0.72,0.08-1.46,0.15-2.19,0.24c-0.12,0.01-0.24,0.08-0.32,0.13c-0.18,0.1-0.35,0.2-0.53,0.3
							c-0.51,0.29-1.04,0.59-1.53,0.92c-0.17,0.11-0.35,0.21-0.53,0.3c-0.38,0.2-0.74,0.38-0.97,0.75
							c-0.23,0.36-0.47,0.72-0.71,1.06c-0.19,0.28-0.39,0.57-0.58,0.86c-0.05,0.07-0.1,0.14-0.15,0.21
							c-0.07,0.09-0.13,0.18-0.19,0.28c0.23-0.14,0.47-0.27,0.7-0.4c0.32-0.18,0.65-0.36,0.96-0.56L466.61,732.7z"/>
					</g>
					<g>
						<path className="st77" d="M502.84,722.44c0.06-0.64,0.27-1.24,0.43-1.85c0.28-1.09,0.59-2.16,0.88-3.24
							c0.03-0.13,0.08-0.26-0.03-0.43c-0.43,0.41-0.64,0.97-1.07,1.39c0.12-1.12,0.02-2.3,0.94-3.18c0.08-0.08,0.16-0.13,0.27-0.16
							c0.91-0.22,1.8-0.16,2.68,0.15c0.07,0.02,0.16,0.03,0.17,0.12c0.01,0.09-0.07,0.13-0.14,0.15c-0.51,0.1-0.69,0.49-0.83,0.92
							c-0.02,0.07-0.03,0.17-0.09,0.21c-0.9,0.68-0.83,1.71-0.93,2.65c-0.09,0.81-0.34,1.6-0.29,2.43
							C504.06,721.64,503.46,722.08,502.84,722.44z"/>
						<path className="st8" d="M502.68,722.69l0.02-0.26c0.04-0.48,0.17-0.93,0.3-1.38c0.05-0.16,0.09-0.33,0.13-0.49
							c0.17-0.68,0.36-1.37,0.55-2.03c0.11-0.4,0.22-0.81,0.33-1.21l0.01-0.03c0.01-0.03,0.02-0.06,0.02-0.09
							c-0.13,0.15-0.23,0.32-0.35,0.49c-0.16,0.24-0.33,0.5-0.55,0.71l-0.27,0.26l0.04-0.38c0.02-0.2,0.04-0.4,0.05-0.6
							c0.07-0.94,0.14-1.9,0.93-2.67c0.1-0.1,0.21-0.16,0.33-0.19c0.93-0.22,1.85-0.17,2.76,0.16c0.01,0,0.02,0.01,0.03,0.01
							c0.06,0.02,0.21,0.06,0.23,0.23c0.01,0.15-0.09,0.26-0.25,0.29c-0.41,0.08-0.58,0.37-0.73,0.83
							c-0.01,0.02-0.01,0.03-0.01,0.05c-0.02,0.07-0.04,0.16-0.12,0.22c-0.71,0.53-0.77,1.3-0.84,2.1c-0.01,0.15-0.03,0.3-0.04,0.45
							c-0.03,0.28-0.08,0.56-0.13,0.84c-0.09,0.51-0.19,1.04-0.16,1.57l0.01,0.14l-0.14,0.01c-0.6,0.03-1.1,0.33-1.61,0.64
							c-0.1,0.06-0.2,0.12-0.3,0.18L502.68,722.69z M504.14,716.7l0.09,0.14c0.14,0.21,0.09,0.39,0.06,0.52l-0.01,0.02
							c-0.11,0.41-0.22,0.81-0.33,1.21c-0.18,0.66-0.37,1.35-0.54,2.03c-0.04,0.17-0.09,0.34-0.14,0.5c-0.1,0.34-0.2,0.7-0.26,1.06
							c0.02-0.01,0.04-0.02,0.06-0.04c0.49-0.3,1-0.6,1.61-0.67c-0.01-0.51,0.08-1.02,0.17-1.5c0.05-0.27,0.1-0.55,0.13-0.82
							c0.02-0.15,0.03-0.3,0.04-0.44c0.07-0.83,0.14-1.69,0.94-2.3c0.01-0.01,0.02-0.05,0.02-0.08c0.01-0.02,0.01-0.05,0.02-0.07
							c0.14-0.43,0.34-0.89,0.93-1.01c-0.01,0-0.02-0.01-0.03-0.01c-0.85-0.31-1.73-0.36-2.6-0.15c-0.08,0.02-0.14,0.05-0.21,0.12
							c-0.72,0.69-0.78,1.56-0.85,2.49c0,0.06-0.01,0.12-0.01,0.18c0.08-0.11,0.16-0.23,0.24-0.35c0.16-0.25,0.33-0.5,0.55-0.72
							L504.14,716.7z"/>
					</g>
					<g>
						<path className="st72" d="M518.28,732.27c0.71-0.75,1.33-1.5,2.06-2.13c0.85-0.74,1.22-1.64,1.24-2.71
							c0.01-0.47,0.12-0.93,0.06-1.4c-0.03-0.22,0.08-0.23,0.24-0.11c0.25,0.19,0.51,0.37,0.77,0.56c0.21,0.15,0.22,0.34,0.17,0.57
							c-0.13,0.54-0.28,1.09-0.39,1.64c-0.15,0.77-0.59,1.29-1.21,1.74c-0.82,0.59-1.65,1.18-2.49,1.75
							C518.62,732.22,518.53,732.31,518.28,732.27z"/>
						<path className="st8" d="M518.39,732.42c-0.04,0-0.09,0-0.14-0.01l-0.25-0.04l0.18-0.19c0.22-0.23,0.44-0.47,0.64-0.69
							c0.47-0.52,0.92-1.01,1.42-1.45c0.78-0.68,1.17-1.53,1.19-2.61c0-0.18,0.02-0.35,0.04-0.53c0.03-0.3,0.06-0.58,0.02-0.86
							c-0.02-0.16,0.01-0.26,0.1-0.31c0.09-0.06,0.21-0.03,0.35,0.07c0.24,0.18,0.5,0.36,0.74,0.53l0.03,0.02
							c0.34,0.24,0.25,0.59,0.22,0.71c-0.03,0.13-0.06,0.26-0.1,0.38c-0.1,0.41-0.21,0.83-0.29,1.25c-0.14,0.72-0.54,1.3-1.27,1.82
							c-0.92,0.66-1.7,1.22-2.5,1.75c-0.01,0.01-0.02,0.02-0.04,0.02C518.68,732.35,518.58,732.42,518.39,732.42z M521.76,726
							C521.76,726,521.76,726,521.76,726c0.04,0.31,0.01,0.62-0.02,0.92c-0.02,0.17-0.03,0.34-0.04,0.5
							c-0.02,1.16-0.44,2.08-1.28,2.81c-0.49,0.43-0.93,0.91-1.4,1.43c-0.09,0.1-0.18,0.19-0.27,0.29c0.76-0.51,1.5-1.04,2.38-1.67
							c0.67-0.49,1.03-1,1.16-1.65c0.08-0.43,0.19-0.85,0.29-1.26c0.03-0.13,0.06-0.25,0.1-0.38c0.05-0.21,0.02-0.33-0.11-0.42
							l-0.03-0.02c-0.25-0.17-0.5-0.35-0.75-0.54C521.78,726.01,521.77,726.01,521.76,726z M521.76,725.93L521.76,725.93
							L521.76,725.93z"/>
					</g>
					<g>
						<path className="st75" d="M462.26,732.27c0.1-0.72,0.19-1.45,0.29-2.17c0.27,0.5,0.49,0.98,0.52,1.52
							C463,732.09,462.59,732.14,462.26,732.27z"/>
						<path className="st8" d="M462.09,732.49l0.38-2.82l0.2,0.37c0.3,0.56,0.5,1.04,0.53,1.58l0,0.01l0,0.01
							c-0.07,0.47-0.42,0.59-0.71,0.69c-0.06,0.02-0.12,0.04-0.18,0.07L462.09,732.49z M462.63,730.56l-0.2,1.51
							c0.28-0.1,0.46-0.18,0.5-0.45C462.91,731.26,462.8,730.92,462.63,730.56z"/>
					</g>
					<g>
						<path className="st75" d="M466.08,729.72c0.18-0.59,0.31-1.19,0.43-1.92c0.34,0.58,0.06,1.1,0.2,1.58
							C466.49,729.49,466.29,729.6,466.08,729.72z"/>
						<path className="st8" d="M465.85,730l0.1-0.32c0.18-0.59,0.31-1.18,0.42-1.9l0.06-0.38l0.19,0.33c0.23,0.38,0.2,0.74,0.19,1.06
							c-0.01,0.2-0.02,0.38,0.03,0.55l0.03,0.11L465.85,730z M466.54,728.38c-0.07,0.37-0.15,0.72-0.24,1.05l0.25-0.14
							c-0.03-0.18-0.02-0.36-0.01-0.53C466.54,728.64,466.55,728.51,466.54,728.38z"/>
					</g>
					<g>
						<path className="st77" d="M530.35,745.62c-0.05,0.08-0.12,0.12-0.22,0.1C530.18,745.63,530.25,745.59,530.35,745.62z"/>
						<path className="st8" d="M530.17,745.86c-0.03,0-0.05,0-0.07-0.01l-0.19-0.04l0.1-0.17c0.12-0.21,0.29-0.19,0.38-0.17l0.18,0.05
							l-0.11,0.16C530.37,745.83,530.27,745.86,530.17,745.86z"/>
					</g>
					<g>
						<path className="st78" d="M529.05,722.97c-0.01,0.06-0.02,0.12-0.11,0.11c-0.01,0-0.03-0.02-0.03-0.03
							C528.92,722.97,528.98,722.97,529.05,722.97C529.05,722.97,529.05,722.97,529.05,722.97z"/>
						<path className="st8" d="M528.96,723.22c-0.01,0-0.02,0-0.03,0c-0.08-0.01-0.15-0.09-0.15-0.18c0.01-0.08,0.04-0.21,0.27-0.21
							l0.06,0l0.09,0.09l-0.01,0.07C529.15,723.18,529.05,723.22,528.96,723.22z M529.05,723.01l0,0.08
							c0.01-0.01,0.01-0.02,0.01-0.02C529.06,723.04,529.05,723.02,529.05,723.01z"/>
					</g>
					<g>
						<polygon className="st78" points="463.06,736.45 463.04,736.48 463.07,736.46 						"/>
						<polygon className="st8" points="462.09,737.31 463.06,736.26 463.79,736.99 463.08,736.62 						"/>
					</g>
					<g>
						<polygon className="st78" points="545.63,742.74 545.61,742.77 545.64,742.75 						"/>
						<polygon className="st8" points="545.71,742.86 545.61,742.77 545.54,742.84 545.61,742.77 545.51,742.69 545.62,742.53 
							545.83,742.74 						"/>
					</g>
					<g>
						<path className="st73" d="M530.69,710.94C530.69,710.94,530.69,710.95,530.69,710.94C530.69,710.95,530.69,710.94,530.69,710.94z"
							/>
						<path className="st8" d="M530.77,711.08h-0.13l-0.04-0.03l0.09-0.1l0,0l0,0l0,0l0-0.13v0.13l0.08-0.1l0.1,0.08L530.77,711.08z
							 M530.69,710.94l0.09-0.1L530.69,710.94z"/>
					</g>
					<g>
						<path className="st75" d="M511.14,683.68c-0.38,0.04-0.64-0.09-0.9-0.18c-0.93-0.34-1.82-0.06-2.73,0.08
							c-0.01,0-0.03,0.02-0.04,0.02c-1.71,0.03-1.96,1.55-2.63,2.62c-0.45,0.73-0.85,1.49-1.27,2.24c-0.15-0.05-0.13-0.16-0.14-0.24
							c-0.03-0.89-0.09-1.78-0.05-2.67c0.02-0.43,0.36-0.79,0.61-1.15c0.65-0.92,0.66-0.92-0.26-1.47c-0.73-0.44-1.46-0.86-2.18-1.3
							c-0.24-0.15-0.5-0.12-0.75-0.12c-0.41-0.01-0.83,0.06-1.25-0.1c0.47-0.07,0.93-0.16,1.4-0.2c0.87-0.09,1.75-0.14,2.63-0.23
							c0.55-0.05,1.03,0.14,1.45,0.44c0.95,0.68,2.04,0.96,3.15,1.24c0.94,0.24,1.85,0.59,2.77,0.89
							C510.99,683.55,511.03,683.6,511.14,683.68z"/>
						<path className="st8" d="M503.64,688.62l-0.11-0.04c-0.22-0.08-0.23-0.26-0.23-0.34l0-0.02c-0.01-0.25-0.02-0.49-0.03-0.74
							c-0.03-0.64-0.05-1.3-0.03-1.94c0.02-0.39,0.26-0.71,0.49-1.02c0.05-0.07,0.1-0.13,0.14-0.2l0.02-0.03
							c0.27-0.39,0.44-0.62,0.41-0.74c-0.03-0.12-0.28-0.27-0.65-0.5c-0.33-0.2-0.67-0.4-1.01-0.6c-0.39-0.23-0.79-0.47-1.18-0.7
							c-0.16-0.1-0.35-0.1-0.54-0.1c-0.05,0-0.09,0-0.14,0c-0.11,0-0.21,0-0.33,0c-0.31,0.01-0.64,0.01-0.97-0.11l-0.49-0.19
							l0.51-0.08c0.14-0.02,0.29-0.04,0.43-0.07c0.32-0.05,0.65-0.1,0.97-0.14c0.45-0.04,0.9-0.08,1.34-0.12
							c0.42-0.03,0.86-0.07,1.29-0.11c0.52-0.05,1.03,0.1,1.54,0.47c0.95,0.67,2.04,0.95,3.1,1.22c0.67,0.17,1.33,0.4,1.97,0.62
							c0.27,0.09,0.53,0.18,0.8,0.27c0.05,0.02,0.1,0.05,0.15,0.09c0.03,0.02,0.06,0.04,0.1,0.07l0.29,0.21l-0.36,0.04
							c-0.36,0.04-0.62-0.06-0.86-0.15c-0.03-0.01-0.07-0.03-0.1-0.04c-0.71-0.26-1.4-0.14-2.13,0c-0.17,0.03-0.34,0.06-0.51,0.09
							c-0.02,0.01-0.04,0.02-0.08,0.02c-1.25,0.02-1.67,0.88-2.11,1.78c-0.13,0.26-0.26,0.53-0.41,0.78
							c-0.31,0.5-0.6,1.03-0.88,1.54c-0.13,0.23-0.26,0.46-0.38,0.69L503.64,688.62z M500.69,681.36c0.04,0,0.08,0,0.12,0
							c0.04,0,0.09,0,0.13,0c0.22,0,0.46,0,0.69,0.14c0.39,0.24,0.79,0.47,1.17,0.7c0.34,0.2,0.67,0.4,1.01,0.6
							c0.49,0.29,0.73,0.44,0.78,0.68c0.05,0.24-0.12,0.48-0.46,0.96l-0.02,0.03c-0.05,0.07-0.1,0.14-0.15,0.2
							c-0.21,0.28-0.42,0.56-0.43,0.87c-0.03,0.64,0,1.29,0.03,1.92c0.01,0.24,0.02,0.47,0.03,0.71c0.09-0.16,0.18-0.32,0.27-0.48
							c0.28-0.51,0.57-1.04,0.89-1.55c0.14-0.23,0.27-0.5,0.4-0.75c0.45-0.93,0.92-1.9,2.32-1.94c0.01-0.01,0.03-0.01,0.05-0.02
							c0.17-0.03,0.35-0.06,0.52-0.09c0.49-0.09,1-0.18,1.51-0.15c-0.46-0.16-0.93-0.3-1.4-0.42c-1.03-0.26-2.2-0.56-3.19-1.26
							c-0.46-0.33-0.9-0.46-1.36-0.42c-0.43,0.04-0.87,0.08-1.29,0.11c-0.44,0.03-0.89,0.07-1.33,0.12
							C500.87,681.34,500.78,681.35,500.69,681.36z"/>
					</g>
					<g>
						<path className="st79" d="M511.55,687.99"/>
					</g>
					<g>
						<path className="st79" d="M511.08,687.73"/>
					</g>
					<g>
						<path className="st72" d="M552.39,743.85c-0.03,0.09-0.07,0.16-0.18,0.14c-0.02,0-0.05-0.04-0.05-0.06
							c0.03-0.11,0.13-0.09,0.21-0.1C552.37,743.83,552.39,743.85,552.39,743.85z"/>
						<path className="st8" d="M552.25,744.13c-0.02,0-0.05,0-0.07-0.01c-0.07-0.01-0.11-0.07-0.12-0.09c-0.03-0.05-0.03-0.1-0.03-0.13
							c0.04-0.19,0.21-0.2,0.28-0.2c0.01,0,0.03,0,0.04,0l0.45-0.08l-0.27,0.26l-0.01,0.03
							C552.45,744.08,552.35,744.13,552.25,744.13z"/>
					</g>
					<g>
						<path className="st73" d="M481.57,689.9c-0.36,0.51-0.91,0.83-1.35,1.25c-0.92,0.86-1.9,1.65-2.86,2.46
							c-0.17,0.14-0.27,0.32-0.33,0.53c-0.2,0.73-0.42,1.46-0.6,2.19c-0.08,0.35-0.25,0.62-0.52,0.82c-1.21,0.91-1.82,2.15-2.08,3.6
							c-0.06,0.34-0.19,0.67-0.25,1.01c-0.1,0.64-0.6,0.92-1.05,1.25c-0.46,0.34-0.92,0.69-1.42,0.98c-0.4,0.23-0.59,0.58-0.63,0.99
							c-0.12,1.29-0.91,2.13-1.86,2.87c-0.81,0.63-1.44,1.43-2.13,2.17c-1.19,1.27-2.34,2.58-3.54,3.85
							c-0.25,0.26-0.64,0.33-0.98,0.47c-1.04,0.41-2.09,0.81-3.11,1.28c-0.79,0.37-1.35,1.02-1.99,1.57
							c-1.26,1.09-2.52,2.18-3.72,3.34c-0.8,0.78-1.5,1.67-2.23,2.51c-0.09,0.1-0.15,0.23-0.29,0.28c-0.11-0.12-0.01-0.23,0.03-0.32
							c0.41-0.96,0.83-1.92,1.24-2.88c0.2-0.47,0.66-0.66,1.02-0.95c1.05-0.84,1.85-1.9,2.69-2.93c1.15-1.42,2.46-2.66,4.03-3.62
							c1.17-0.72,2.29-1.54,3.47-2.24c0.33-0.2,0.48-0.58,0.69-0.89c0.59-0.86,1.17-1.72,1.72-2.61c-1.21,0.82-2.14,1.99-3.54,2.72
							c0.06-0.45,0.32-0.67,0.49-0.94c0.29-0.46,0.61-0.9,0.93-1.34c0.33-0.44,0.37-0.99,0.54-1.48c0.31-0.9,0.42-1.87,1.08-2.62
							c0.04-0.05,0.06-0.11,0.11-0.14c1.14-0.81,1.37-2.05,1.55-3.31c0.06-0.41,0.31-0.68,0.54-0.98c0.23-0.3,0.45-0.61,0.71-0.89
							c0.42-0.46,0.26-1.03,0.37-1.56c0.04-0.19,0.03-0.39,0.05-0.59c0.5-0.14,0.99-0.28,1.51-0.34c0.5-0.05,0.84-0.41,1.23-0.66
							c1.6-1.03,3.2-2.08,4.79-3.13c0.56-0.37,1.18-0.56,1.81-0.72C478.99,690.57,480.29,690.23,481.57,689.9z"/>
						<path className="st8" d="M450.61,723.5l-0.06-0.06c-0.16-0.17-0.06-0.34-0.02-0.42c0.01-0.02,0.02-0.03,0.02-0.04
							c0.21-0.48,0.42-0.97,0.62-1.45c0.21-0.48,0.42-0.96,0.62-1.43c0.16-0.38,0.47-0.59,0.77-0.79c0.1-0.07,0.2-0.13,0.29-0.21
							c0.9-0.71,1.62-1.61,2.32-2.48c0.12-0.14,0.23-0.29,0.35-0.43c1.26-1.55,2.59-2.74,4.06-3.65c0.53-0.33,1.05-0.67,1.56-1.01
							c0.62-0.41,1.26-0.84,1.92-1.23c0.21-0.13,0.34-0.35,0.48-0.58c0.05-0.09,0.11-0.18,0.17-0.27c0.43-0.63,0.88-1.3,1.32-1.98
							c-0.27,0.22-0.54,0.45-0.81,0.7c-0.65,0.57-1.32,1.16-2.15,1.6l-0.23,0.12l0.03-0.26c0.05-0.37,0.22-0.6,0.38-0.81
							c0.05-0.06,0.09-0.13,0.13-0.19c0.3-0.48,0.64-0.95,0.94-1.35c0.21-0.28,0.29-0.61,0.38-0.96c0.04-0.16,0.08-0.33,0.14-0.49
							c0.08-0.23,0.15-0.48,0.22-0.72c0.19-0.68,0.39-1.38,0.89-1.95c0.01-0.01,0.02-0.03,0.03-0.04c0.02-0.04,0.05-0.09,0.1-0.12
							c1.07-0.76,1.31-1.91,1.5-3.21c0.06-0.39,0.28-0.67,0.49-0.94c0.03-0.03,0.05-0.07,0.08-0.1c0.06-0.07,0.11-0.14,0.17-0.22
							c0.17-0.23,0.35-0.46,0.55-0.68c0.26-0.28,0.27-0.61,0.29-0.97c0.01-0.17,0.02-0.35,0.05-0.52c0.02-0.12,0.03-0.25,0.03-0.38
							c0-0.06,0.01-0.13,0.01-0.19l0.01-0.1l0.15-0.04c0.48-0.14,0.97-0.27,1.47-0.33c0.33-0.04,0.6-0.23,0.87-0.43
							c0.1-0.07,0.19-0.14,0.3-0.21c1.77-1.14,3.34-2.16,4.79-3.13c0.58-0.39,1.23-0.58,1.85-0.74c0.91-0.24,1.84-0.48,2.74-0.72
							c0.38-0.1,0.75-0.19,1.13-0.29l0.36-0.09l-0.22,0.31c-0.25,0.35-0.58,0.61-0.89,0.87c-0.16,0.13-0.33,0.26-0.48,0.4
							c-0.67,0.63-1.39,1.23-2.08,1.81c-0.26,0.22-0.52,0.44-0.78,0.66c-0.14,0.12-0.23,0.27-0.28,0.46
							c-0.08,0.29-0.16,0.59-0.25,0.88c-0.12,0.43-0.25,0.87-0.36,1.31c-0.1,0.39-0.28,0.68-0.57,0.9
							c-1.09,0.82-1.75,1.97-2.03,3.52c-0.03,0.17-0.08,0.34-0.12,0.5c-0.05,0.17-0.1,0.34-0.12,0.51c-0.1,0.62-0.53,0.93-0.95,1.23
							c-0.05,0.04-0.1,0.07-0.15,0.11c-0.07,0.05-0.15,0.11-0.22,0.16c-0.39,0.28-0.78,0.58-1.2,0.83c-0.34,0.2-0.53,0.5-0.57,0.88
							c-0.13,1.4-1.04,2.28-1.91,2.97c-0.58,0.46-1.09,1.02-1.57,1.57c-0.18,0.2-0.36,0.4-0.54,0.59c-0.57,0.61-1.14,1.23-1.69,1.83
							c-0.6,0.66-1.23,1.35-1.85,2.01c-0.21,0.22-0.5,0.32-0.77,0.41c-0.09,0.03-0.17,0.06-0.25,0.09l-0.39,0.15
							c-0.9,0.35-1.82,0.71-2.71,1.12c-0.57,0.26-1.03,0.69-1.47,1.11c-0.16,0.15-0.32,0.3-0.48,0.44l-0.21,0.18
							c-1.17,1.01-2.37,2.06-3.5,3.16c-0.56,0.54-1.08,1.16-1.58,1.75c-0.21,0.25-0.43,0.51-0.64,0.75
							c-0.02,0.02-0.04,0.05-0.06,0.08c-0.06,0.09-0.14,0.19-0.29,0.25L450.61,723.5z M465.99,706.43l-0.34,0.54
							c-0.56,0.9-1.16,1.79-1.72,2.61c-0.05,0.08-0.1,0.16-0.16,0.25c-0.15,0.25-0.3,0.51-0.58,0.68c-0.65,0.39-1.29,0.81-1.9,1.22
							c-0.51,0.34-1.04,0.69-1.57,1.02c-1.45,0.9-2.76,2.07-3.99,3.59c-0.12,0.14-0.23,0.29-0.35,0.43
							c-0.71,0.88-1.44,1.79-2.36,2.53c-0.1,0.08-0.2,0.15-0.31,0.22c-0.28,0.19-0.54,0.37-0.67,0.67c-0.2,0.48-0.41,0.96-0.62,1.44
							c-0.19,0.43-0.38,0.87-0.56,1.3c0.21-0.24,0.41-0.48,0.61-0.72c0.51-0.6,1.03-1.22,1.6-1.77c1.13-1.1,2.34-2.15,3.51-3.17
							l0.21-0.18c0.16-0.14,0.32-0.29,0.48-0.44c0.46-0.43,0.93-0.88,1.54-1.16c0.89-0.41,1.82-0.78,2.72-1.13l0.39-0.15
							c0.09-0.03,0.18-0.06,0.27-0.09c0.26-0.09,0.5-0.17,0.66-0.34c0.62-0.66,1.25-1.35,1.85-2.01c0.55-0.6,1.12-1.23,1.69-1.84
							c0.18-0.19,0.36-0.4,0.54-0.59c0.49-0.55,1.01-1.12,1.61-1.6c0.86-0.68,1.68-1.48,1.8-2.78c0.05-0.47,0.29-0.85,0.7-1.09
							c0.41-0.24,0.8-0.53,1.18-0.81c0.08-0.06,0.15-0.11,0.23-0.17c0.05-0.04,0.1-0.07,0.15-0.11c0.39-0.28,0.76-0.54,0.84-1.05
							c0.03-0.19,0.08-0.37,0.13-0.54c0.05-0.16,0.09-0.32,0.12-0.48c0.28-1.62,0.98-2.83,2.13-3.69c0.24-0.18,0.39-0.42,0.47-0.75
							c0.11-0.44,0.24-0.89,0.36-1.32c0.08-0.29,0.17-0.58,0.24-0.88c0.07-0.25,0.19-0.44,0.37-0.6c0.26-0.22,0.52-0.44,0.78-0.66
							c0.69-0.58,1.4-1.17,2.07-1.8c0.16-0.15,0.33-0.28,0.49-0.42c0.2-0.16,0.4-0.32,0.57-0.49c-0.24,0.06-0.47,0.12-0.71,0.18
							c-0.9,0.23-1.83,0.47-2.74,0.72c-0.62,0.16-1.22,0.34-1.76,0.7c-1.46,0.97-3.02,2-4.79,3.14c-0.09,0.06-0.19,0.13-0.28,0.2
							c-0.29,0.22-0.6,0.44-1,0.48c-0.47,0.05-0.94,0.18-1.39,0.31c0,0.03,0,0.06,0,0.09c-0.01,0.14-0.01,0.28-0.04,0.42
							c-0.03,0.15-0.04,0.31-0.04,0.48c-0.02,0.39-0.04,0.79-0.36,1.15c-0.19,0.21-0.36,0.44-0.53,0.66
							c-0.06,0.07-0.11,0.15-0.17,0.22c-0.03,0.04-0.06,0.07-0.08,0.11c-0.2,0.25-0.38,0.49-0.43,0.81
							c-0.19,1.32-0.45,2.57-1.61,3.4c-0.01,0.01-0.02,0.03-0.03,0.05c-0.02,0.02-0.03,0.05-0.06,0.08
							c-0.47,0.53-0.65,1.17-0.83,1.84c-0.07,0.24-0.14,0.49-0.22,0.73c-0.05,0.15-0.09,0.31-0.13,0.47
							c-0.09,0.36-0.19,0.73-0.43,1.05c-0.29,0.4-0.63,0.86-0.93,1.33c-0.05,0.07-0.1,0.14-0.15,0.21
							c-0.09,0.12-0.18,0.24-0.24,0.38c0.68-0.4,1.26-0.91,1.82-1.4c0.45-0.4,0.92-0.81,1.42-1.15L465.99,706.43z"/>
					</g>
					<g>
						<path className="st73" d="M462.17,699.83c-1.03,0.36-2.01,0.83-3,1.28c-0.9,0.41-1.67,0.98-2.31,1.72
							c-1.13,1.3-2.29,2.59-3.37,3.93c-0.38,0.47-0.5,1.03-0.64,1.59c-0.11,0.45-0.29,0.86-0.54,1.24c-0.52,0.8-0.92,1.67-1.53,2.44
							c-0.47-0.76-0.26-1.46,0.13-2.11c0.33-0.56,0.72-1.08,1.09-1.62c0.07-0.1,0.2-0.19,0.16-0.31c-0.05-0.16-0.2-0.07-0.32-0.06
							c-0.2,0.03-0.28-0.09-0.3-0.26c-0.14-1.16-0.38-2.32-0.37-3.47c0.01-0.93,0.47-1.75,1.42-2.15c0.35-0.15,0.68-0.34,1.02-0.51
							c0.24-0.12,0.36-0.28,0.26-0.58c-0.09-0.3-0.1-0.63-0.14-0.94c0.28-0.24,0.61-0.33,0.97-0.25c0.43,0.09,0.87,0.16,1.29,0.29
							c0.55,0.17,1.08,0.17,1.59-0.1c1.27-0.67,2.58-0.67,3.92-0.23C461.72,699.79,461.96,699.7,462.17,699.83z"/>
						<path className="st8" d="M450.76,712.26l-0.1-0.17c-0.54-0.88-0.22-1.67,0.13-2.25c0.24-0.41,0.52-0.8,0.79-1.18
							c0.1-0.15,0.21-0.29,0.31-0.44c0.02-0.03,0.05-0.06,0.07-0.09c0.02-0.03,0.07-0.08,0.07-0.1c-0.02,0-0.05,0.01-0.07,0.02
							c-0.03,0.01-0.06,0.02-0.09,0.02c-0.12,0.02-0.22-0.01-0.3-0.07c-0.08-0.07-0.13-0.17-0.15-0.31
							c-0.04-0.34-0.09-0.7-0.14-1.04c-0.12-0.8-0.24-1.63-0.23-2.45c0.01-1.04,0.56-1.87,1.51-2.28c0.25-0.11,0.49-0.24,0.73-0.36
							c0.09-0.05,0.18-0.1,0.28-0.14c0.21-0.11,0.26-0.21,0.19-0.42c-0.07-0.22-0.09-0.45-0.11-0.68c-0.01-0.1-0.02-0.19-0.03-0.29
							l-0.01-0.07l0.06-0.05c0.32-0.27,0.68-0.37,1.09-0.28c0.11,0.02,0.21,0.04,0.32,0.06c0.32,0.06,0.66,0.13,0.98,0.23
							c0.55,0.17,1.04,0.15,1.48-0.09c1.23-0.64,2.55-0.72,4.03-0.23c0.07,0.02,0.15,0.02,0.24,0.02c0.14,0,0.3,0,0.46,0.1
							l0.24,0.15l-0.27,0.09c-0.9,0.31-1.77,0.72-2.62,1.11l-0.37,0.17c-0.9,0.41-1.64,0.96-2.27,1.68
							c-0.29,0.33-0.58,0.66-0.87,0.99c-0.83,0.95-1.7,1.93-2.5,2.94c-0.34,0.42-0.46,0.91-0.59,1.44l-0.02,0.1
							c-0.12,0.47-0.3,0.89-0.55,1.28c-0.19,0.29-0.36,0.6-0.54,0.89c-0.3,0.52-0.61,1.06-1,1.55L450.76,712.26z M452.04,707.75
							c0.1,0,0.2,0.04,0.25,0.19c0.05,0.16-0.06,0.28-0.13,0.36c-0.02,0.02-0.04,0.04-0.05,0.07c-0.1,0.15-0.21,0.3-0.31,0.45
							c-0.27,0.38-0.54,0.76-0.77,1.16c-0.41,0.71-0.48,1.26-0.23,1.79c0.32-0.43,0.59-0.9,0.85-1.35c0.17-0.3,0.35-0.61,0.54-0.91
							c0.24-0.37,0.41-0.76,0.52-1.2l0.02-0.1c0.13-0.53,0.27-1.07,0.64-1.54c0.81-1.01,1.67-1.99,2.51-2.95
							c0.29-0.33,0.58-0.66,0.87-0.99c0.65-0.75,1.43-1.33,2.36-1.75l0.37-0.17c0.7-0.32,1.41-0.65,2.15-0.93
							c-0.05,0-0.11-0.01-0.16-0.03c-1.4-0.46-2.65-0.39-3.81,0.22c-0.52,0.27-1.07,0.3-1.69,0.11c-0.31-0.1-0.63-0.16-0.95-0.22
							c-0.11-0.02-0.22-0.04-0.32-0.06c-0.3-0.06-0.56,0-0.8,0.18c0.01,0.07,0.02,0.15,0.02,0.22c0.02,0.22,0.04,0.43,0.1,0.62
							c0.14,0.44-0.12,0.64-0.33,0.74c-0.09,0.05-0.18,0.1-0.27,0.14c-0.24,0.13-0.49,0.26-0.75,0.37c-0.85,0.36-1.33,1.1-1.34,2.03
							c-0.01,0.8,0.11,1.62,0.23,2.41c0.05,0.34,0.1,0.7,0.14,1.04c0.01,0.07,0.02,0.11,0.05,0.13c0.02,0.02,0.06,0.02,0.1,0.01
							c0.02,0,0.04-0.01,0.06-0.01C451.92,707.77,451.98,707.75,452.04,707.75z"/>
					</g>
					<g>
						<path className="st73" d="M491.59,683.38c-1.04,0.48-1.73,1.34-2.46,2.17c-0.02,0.02-0.06,0.05-0.05,0.07
							c0.13,0.57-0.35,0.44-0.66,0.53c0.41-0.53,0.28-1.36,1.06-1.71c0.38-0.17,0.41-0.68,0.6-1.04c0.26-0.5,0.49-1,0.9-1.4
							c0.17-0.17,0.19-0.37,0.11-0.58c0.2-0.39,0.6-0.53,0.93-0.75c0.62-0.4,1.2-0.88,1.92-1.14c-0.1,1.6-0.71,2.91-2.18,3.71
							L491.59,683.38z"/>
						<path className="st8" d="M488.06,686.4l0.26-0.33c0.14-0.18,0.21-0.41,0.29-0.64c0.13-0.41,0.28-0.86,0.82-1.11
							c0.21-0.1,0.3-0.34,0.38-0.6c0.04-0.13,0.09-0.26,0.15-0.37c0.03-0.06,0.06-0.12,0.09-0.18c0.22-0.44,0.45-0.89,0.83-1.26
							c0.12-0.12,0.14-0.25,0.08-0.44l-0.02-0.06l0.03-0.05c0.17-0.33,0.46-0.5,0.75-0.66c0.08-0.05,0.16-0.09,0.23-0.14
							c0.16-0.1,0.31-0.21,0.47-0.32c0.46-0.32,0.93-0.64,1.48-0.83l0.2-0.07l-0.01,0.21c-0.12,1.8-0.85,3.05-2.24,3.82l-0.19,0.15
							c-0.93,0.43-1.6,1.2-2.25,1.95l-0.17,0.19c0,0-0.01,0.01-0.01,0.01c0.03,0.17,0.01,0.3-0.06,0.4
							c-0.11,0.16-0.31,0.18-0.49,0.2c-0.08,0.01-0.15,0.02-0.21,0.03L488.06,686.4z M491.24,681.43c0.09,0.33-0.03,0.54-0.16,0.67
							c-0.34,0.33-0.55,0.75-0.78,1.18c-0.03,0.06-0.06,0.12-0.09,0.18c-0.05,0.1-0.09,0.21-0.13,0.34
							c-0.1,0.29-0.21,0.62-0.53,0.76c-0.43,0.19-0.55,0.56-0.67,0.94c-0.05,0.15-0.1,0.31-0.17,0.46c0.11-0.01,0.22-0.03,0.25-0.08
							c0.03-0.04,0.03-0.12,0.01-0.24c-0.02-0.09,0.04-0.15,0.07-0.18l0.01-0.01l0.16-0.19c0.66-0.76,1.35-1.56,2.32-2.01l0.18-0.14
							c1.25-0.69,1.94-1.8,2.09-3.38c-0.43,0.19-0.83,0.46-1.21,0.72c-0.15,0.11-0.31,0.22-0.47,0.32c-0.08,0.05-0.17,0.1-0.25,0.15
							C491.61,681.07,491.38,681.2,491.24,681.43z M489.22,685.59C489.22,685.59,489.22,685.59,489.22,685.59
							C489.22,685.59,489.22,685.59,489.22,685.59z"/>
					</g>
					<g>
						<path className="st73" d="M472.93,706.69c0.43-0.99,0.81-1.95,1.26-2.89c0.29-0.61,0.88-1,1.36-1.45
							c0.37-0.35,0.65-0.73,0.65-1.24c0.01-0.93,0.59-1.53,1.21-2.1c0.19-0.17,0.29-0.46,0.59-0.5c0.03,0.25,0.06,0.5,0.08,0.75
							c0.03,0.31-0.05,0.63,0.11,0.97c0.41-2.04,1.54-3.69,2.83-5.28c-0.14,0.68-0.28,1.36-0.13,2.15c0.59-1.08,0.96-2.11,1.02-3.25
							c0.04-0.7,0.13-1.39,0.2-2.08c0.02-0.17,0.05-0.34,0.18-0.45c0.36-0.32,0.73-0.62,1.15-0.98c-0.02,0.44-0.21,0.75-0.37,1.03
							c-0.27,0.47-0.22,0.96-0.23,1.46c0,0.1,0.02,0.18,0.12,0.23c0.2,0.09,1.03-0.34,1.07-0.57c0.13-0.68,0.26-1.37,0.35-2.06
							c0.05-0.4,0.17-0.64,0.6-0.73c0.71-0.15,1.13-0.72,1.61-1.2c0.13-0.14,0.23-0.46,0.46-0.33c0.16,0.09-0.06,0.32-0.09,0.49
							c-0.1,0.55-0.23,1.1-0.35,1.65c-0.07,0.34,0.03,0.64,0.2,0.91c0.1,0.17,0.16,0.36,0.26,0.52c0.34,0.57,0.22,1.04-0.16,1.54
							c-0.44,0.57-0.72,1.2-0.71,1.95c0,0.34-0.27,0.62-0.49,0.85c-0.68,0.72-0.66,1.49-0.29,2.33c0.21,0.47,0.34,0.97,0.53,1.45
							c0.14,0.35,0.05,0.61-0.21,0.85c-0.54,0.48-1.08,0.97-1.63,1.44c-0.29,0.24-0.68,0.11-0.96,0.07
							c-0.74-0.1-1.16,0.34-1.63,0.73c-0.29,0.24-0.57,0.49-0.85,0.74c-0.14,0.13-0.3,0.13-0.47,0.13c-0.58,0-1.15,0.01-1.73-0.01
							c-0.47-0.01-0.86,0.13-1.17,0.49c-0.08,0.09-0.19,0.16-0.25,0.26c-0.48,0.77-1.12,1.17-2.06,1.22
							c-0.54,0.03-1.02,0.44-1.51,0.72C473.32,706.6,473.19,706.72,472.93,706.69z"/>
						<path className="st8" d="M473.01,706.83c-0.03,0-0.06,0-0.09-0.01l-0.18-0.02l0.07-0.17c0.13-0.3,0.25-0.59,0.38-0.88
							c0.29-0.7,0.57-1.35,0.89-2.01c0.23-0.48,0.63-0.83,1.02-1.16c0.13-0.11,0.26-0.22,0.38-0.33c0.42-0.4,0.6-0.74,0.61-1.14
							c0.01-0.96,0.6-1.59,1.25-2.19c0.05-0.05,0.09-0.1,0.14-0.17c0.12-0.16,0.26-0.34,0.53-0.37l0.14-0.02l0.01,0.14
							c0.01,0.08,0.02,0.15,0.02,0.23c0.02,0.17,0.04,0.35,0.05,0.52c0.01,0.09,0.01,0.19,0.01,0.28c0.53-1.83,1.6-3.32,2.69-4.66
							l0.35-0.43l-0.11,0.55c-0.11,0.56-0.22,1.09-0.18,1.68c0.5-1,0.76-1.9,0.81-2.81c0.03-0.46,0.08-0.93,0.12-1.38
							c0.03-0.24,0.05-0.48,0.07-0.71c0.02-0.19,0.06-0.4,0.23-0.54c0.25-0.22,0.51-0.45,0.79-0.68c0.12-0.1,0.24-0.2,0.36-0.31
							l0.24-0.21l-0.02,0.32c-0.03,0.47-0.22,0.8-0.38,1.08l-0.01,0.01c-0.21,0.36-0.21,0.75-0.21,1.16c0,0.08,0,0.15,0,0.23
							c0,0.08,0.02,0.09,0.04,0.1c0.15,0.04,0.84-0.32,0.88-0.47l0.05-0.27c0.11-0.58,0.22-1.19,0.29-1.78
							c0.04-0.37,0.16-0.73,0.7-0.85c0.55-0.12,0.92-0.51,1.31-0.92c0.08-0.08,0.16-0.17,0.24-0.25c0.03-0.03,0.06-0.07,0.09-0.12
							c0.09-0.14,0.26-0.39,0.54-0.24c0.22,0.12,0.1,0.36,0.04,0.49c-0.03,0.06-0.06,0.11-0.06,0.15c-0.09,0.48-0.2,0.96-0.3,1.42
							l-0.05,0.23c-0.06,0.26,0,0.52,0.18,0.81c0.06,0.1,0.1,0.2,0.15,0.29c0.04,0.08,0.07,0.16,0.11,0.23
							c0.34,0.57,0.29,1.09-0.17,1.69c-0.46,0.6-0.69,1.21-0.68,1.86c0,0.4-0.29,0.7-0.52,0.94l-0.01,0.01
							c-0.58,0.61-0.66,1.28-0.26,2.18c0.12,0.27,0.21,0.55,0.3,0.83c0.07,0.21,0.14,0.43,0.22,0.63c0.15,0.39,0.07,0.71-0.25,1
							c-0.18,0.16-0.36,0.32-0.54,0.48c-0.36,0.32-0.72,0.65-1.1,0.97c-0.29,0.24-0.65,0.18-0.92,0.13
							c-0.05-0.01-0.1-0.02-0.15-0.03c-0.6-0.08-0.96,0.22-1.37,0.57c-0.05,0.04-0.1,0.09-0.15,0.13c-0.3,0.24-0.58,0.5-0.85,0.74
							c-0.17,0.16-0.37,0.17-0.56,0.17c-0.2,0-0.4,0-0.6,0c-0.37,0-0.75,0-1.13-0.01c-0.46-0.01-0.79,0.13-1.06,0.44
							c-0.04,0.05-0.08,0.08-0.12,0.12c-0.05,0.04-0.09,0.09-0.12,0.13c-0.51,0.83-1.2,1.24-2.17,1.29
							c-0.37,0.02-0.74,0.25-1.1,0.48c-0.11,0.07-0.23,0.15-0.35,0.22c-0.02,0.01-0.04,0.03-0.07,0.04
							C473.35,706.75,473.22,706.83,473.01,706.83z M477.89,698.7c-0.08,0.05-0.14,0.13-0.21,0.22c-0.05,0.07-0.11,0.14-0.17,0.2
							c-0.63,0.59-1.15,1.14-1.16,2c-0.01,0.61-0.36,1.01-0.7,1.33c-0.12,0.12-0.26,0.23-0.39,0.34c-0.38,0.33-0.74,0.64-0.95,1.07
							c-0.31,0.65-0.59,1.31-0.88,2c-0.09,0.22-0.19,0.45-0.29,0.67c0.06-0.02,0.11-0.05,0.17-0.09c0.03-0.02,0.05-0.03,0.08-0.05
							c0.11-0.06,0.22-0.14,0.34-0.21c0.38-0.25,0.78-0.5,1.23-0.53c0.89-0.05,1.49-0.4,1.96-1.16c0.05-0.08,0.11-0.13,0.17-0.19
							c0.03-0.03,0.07-0.06,0.09-0.09c0.32-0.38,0.74-0.56,1.28-0.54c0.37,0.01,0.75,0.01,1.12,0.01c0.2,0,0.4,0,0.61,0
							c0.16,0,0.27-0.01,0.37-0.1c0.27-0.24,0.56-0.5,0.86-0.75c0.05-0.04,0.1-0.08,0.15-0.12c0.43-0.36,0.87-0.73,1.59-0.63
							c0.05,0.01,0.11,0.02,0.16,0.03c0.25,0.05,0.5,0.09,0.69-0.07c0.37-0.31,0.74-0.64,1.09-0.96c0.18-0.16,0.36-0.32,0.54-0.48
							c0.23-0.21,0.29-0.41,0.18-0.69c-0.08-0.21-0.16-0.43-0.23-0.65c-0.09-0.27-0.18-0.54-0.3-0.8c-0.44-1-0.34-1.79,0.31-2.48
							l0.01-0.01c0.21-0.22,0.44-0.47,0.44-0.75c0-0.72,0.24-1.38,0.74-2.03c0.39-0.5,0.43-0.91,0.15-1.38
							c-0.05-0.08-0.09-0.17-0.13-0.26c-0.04-0.09-0.08-0.18-0.13-0.26c-0.22-0.35-0.29-0.68-0.21-1.01l0.05-0.23
							c0.1-0.46,0.21-0.94,0.3-1.41c0.01-0.08,0.05-0.15,0.09-0.22c0.02-0.04,0.05-0.1,0.06-0.13c-0.03-0.01-0.06,0.01-0.16,0.16
							c-0.04,0.06-0.08,0.11-0.12,0.16c-0.08,0.08-0.16,0.16-0.23,0.24c-0.4,0.42-0.81,0.86-1.45,1c-0.32,0.07-0.44,0.22-0.49,0.61
							c-0.07,0.6-0.19,1.21-0.3,1.8l-0.05,0.27c-0.04,0.22-0.4,0.43-0.56,0.51c-0.19,0.1-0.52,0.24-0.7,0.16
							c-0.09-0.04-0.2-0.14-0.2-0.35c0-0.08,0-0.15,0-0.23c0-0.43,0-0.87,0.25-1.3l0.01-0.01c0.11-0.19,0.22-0.38,0.29-0.6
							c-0.03,0.02-0.05,0.04-0.08,0.06c-0.28,0.23-0.54,0.45-0.79,0.67c-0.1,0.09-0.12,0.23-0.14,0.36
							c-0.02,0.24-0.05,0.48-0.07,0.72c-0.05,0.45-0.1,0.91-0.12,1.36c-0.06,1.07-0.39,2.12-1.04,3.31l-0.18,0.33l-0.07-0.37
							c-0.11-0.6-0.07-1.14,0.02-1.66c-1.08,1.37-2.07,2.9-2.45,4.79l-0.08,0.41l-0.18-0.38c-0.12-0.27-0.12-0.52-0.12-0.74
							c0-0.09,0-0.18-0.01-0.27c-0.02-0.17-0.03-0.34-0.05-0.51C477.9,698.74,477.9,698.72,477.89,698.7z"/>
					</g>
					<g>
						<path className="st73" d="M496.67,701.22c0.12,0.58,0.19,1.11-0.05,1.64c-0.34,0.75-0.59,1.53-0.9,2.28
							c-0.16,0.39-0.09,0.7,0.21,0.99c0.51,0.5,0.98,1.04,1.52,1.5c0.3,0.26,0.21,0.39-0.01,0.6c-0.5,0.46-0.98,0.95-1.49,1.4
							c-0.56,0.5-0.93,1.11-1.18,1.81c-0.14,0.4-0.39,0.65-0.79,0.82c-0.97,0.43-1.97,0.79-2.93,1.24
							c-0.47,0.22-0.79,0.54-1.01,0.98c-0.33,0.65-0.68,1.29-1.02,1.93c-0.06,0.1-0.13,0.2-0.22,0.34c-0.17-0.31-0.14-0.61-0.2-0.89
							c-0.02-0.12,0.01-0.25-0.02-0.36c-0.25-0.85,0.13-1.46,0.71-2.01c0.46-0.44,0.83-0.97,1.22-1.47
							c0.26-0.33,0.62-0.54,0.95-0.77c0.49-0.35,0.98-0.69,1.45-1.07c-0.47,0.01-0.82,0.34-1.24,0.47
							c-0.43,0.14-0.84,0.35-1.24,0.57c-0.49,0.27-0.76,0.77-1.14,1.16c-0.53,0.54-0.99,1.15-1.49,1.72c-0.54,0.61-1.31,0.89-2,1.31
							c0.2-0.34,0.36-0.71,0.6-1.02c0.76-0.99,1.21-2.13,1.58-3.29c0.2-0.64,0.61-1.07,1.08-1.48c0.98-0.84,1.96-1.68,2.96-2.49
							c0.74-0.6,1.25-1.4,1.82-2.14c0.82-1.07,1.61-2.17,2.41-3.25C496.38,701.56,496.52,701.39,496.67,701.22z"/>
						<path className="st8" d="M488.79,717.03l-0.11-0.2c-0.13-0.24-0.16-0.47-0.18-0.68c-0.01-0.09-0.02-0.17-0.04-0.25
							c-0.01-0.07-0.01-0.14-0.01-0.2c0-0.06,0-0.11-0.01-0.15c-0.3-0.99,0.24-1.67,0.74-2.15c0.35-0.34,0.66-0.74,0.95-1.12
							c0.09-0.11,0.17-0.22,0.26-0.33c0.22-0.28,0.51-0.48,0.78-0.66c0.07-0.04,0.13-0.09,0.19-0.13c0.08-0.06,0.17-0.12,0.25-0.18
							c0.17-0.12,0.35-0.25,0.53-0.37c-0.14,0.07-0.29,0.15-0.44,0.2c-0.41,0.13-0.79,0.33-1.21,0.56c-0.3,0.17-0.52,0.43-0.74,0.72
							c-0.11,0.14-0.23,0.29-0.36,0.42c-0.33,0.34-0.64,0.71-0.94,1.07c-0.18,0.21-0.36,0.43-0.55,0.64c-0.4,0.45-0.92,0.73-1.42,1
							c-0.2,0.11-0.41,0.22-0.61,0.34l-0.46,0.28l0.27-0.46c0.06-0.11,0.12-0.22,0.18-0.32c0.13-0.24,0.26-0.49,0.43-0.71
							c0.78-1.01,1.21-2.16,1.55-3.25c0.22-0.7,0.69-1.17,1.12-1.54c1-0.86,1.97-1.69,2.96-2.49c0.57-0.46,1.01-1.06,1.43-1.63
							c0.12-0.16,0.24-0.33,0.37-0.49c0.54-0.71,1.08-1.44,1.6-2.15c0.27-0.37,0.54-0.73,0.81-1.1c0.09-0.13,0.19-0.25,0.29-0.36
							c0.04-0.05,0.09-0.11,0.13-0.16l0.18-0.22l0.06,0.28c0.12,0.58,0.2,1.15-0.06,1.72c-0.19,0.43-0.36,0.88-0.53,1.31
							c-0.12,0.32-0.24,0.65-0.38,0.97c-0.14,0.33-0.08,0.58,0.18,0.83c0.18,0.18,0.36,0.37,0.54,0.55
							c0.31,0.32,0.63,0.66,0.98,0.95c0.17,0.14,0.24,0.26,0.24,0.39c0,0.16-0.12,0.29-0.25,0.41c-0.22,0.21-0.45,0.42-0.66,0.63
							c-0.27,0.26-0.54,0.52-0.83,0.78c-0.51,0.46-0.89,1.04-1.14,1.76c-0.15,0.42-0.42,0.71-0.86,0.9
							c-0.43,0.19-0.87,0.37-1.29,0.54c-0.54,0.22-1.1,0.45-1.64,0.7c-0.43,0.2-0.73,0.49-0.95,0.92c-0.29,0.56-0.59,1.13-0.89,1.68
							l-0.14,0.25c-0.04,0.07-0.09,0.14-0.14,0.22c-0.03,0.04-0.06,0.08-0.09,0.13L488.79,717.03z M493.3,710.04l-0.31,0.25
							c-0.39,0.32-0.81,0.61-1.21,0.9c-0.08,0.06-0.17,0.12-0.25,0.18c-0.07,0.05-0.13,0.09-0.2,0.14
							c-0.27,0.18-0.52,0.36-0.72,0.61c-0.09,0.11-0.17,0.22-0.26,0.33c-0.3,0.39-0.61,0.8-0.98,1.15
							c-0.67,0.65-0.87,1.21-0.67,1.88c0.02,0.08,0.02,0.16,0.02,0.24c0,0.05,0,0.1,0,0.14c0.02,0.09,0.03,0.19,0.04,0.28
							c0.01,0.12,0.03,0.23,0.06,0.35c0.03-0.04,0.05-0.08,0.07-0.12l0.14-0.25c0.29-0.55,0.6-1.11,0.88-1.68
							c0.25-0.48,0.59-0.82,1.08-1.04c0.54-0.25,1.11-0.48,1.65-0.7c0.42-0.17,0.86-0.35,1.28-0.54c0.37-0.16,0.59-0.39,0.71-0.74
							c0.27-0.76,0.66-1.37,1.21-1.87c0.28-0.25,0.55-0.51,0.82-0.77c0.22-0.21,0.44-0.42,0.67-0.63c0.08-0.08,0.16-0.16,0.16-0.2
							c0-0.02-0.02-0.08-0.15-0.19c-0.35-0.3-0.68-0.64-0.99-0.97c-0.17-0.18-0.35-0.36-0.53-0.54c-0.34-0.34-0.42-0.7-0.24-1.14
							c0.13-0.32,0.25-0.64,0.37-0.96c0.16-0.44,0.34-0.89,0.53-1.33c0.19-0.41,0.17-0.83,0.09-1.27c-0.08,0.09-0.16,0.19-0.23,0.29
							c-0.27,0.36-0.54,0.73-0.81,1.1c-0.52,0.71-1.06,1.45-1.6,2.16c-0.12,0.16-0.24,0.32-0.36,0.49
							c-0.43,0.59-0.88,1.19-1.48,1.68c-0.98,0.8-1.96,1.63-2.95,2.49c-0.42,0.36-0.84,0.78-1.04,1.42
							c-0.34,1.06-0.79,2.29-1.6,3.33c-0.14,0.18-0.25,0.38-0.36,0.58c0.07-0.04,0.14-0.07,0.2-0.11c0.5-0.27,0.98-0.52,1.35-0.93
							c0.18-0.21,0.36-0.43,0.54-0.64c0.3-0.36,0.62-0.74,0.96-1.09c0.12-0.12,0.23-0.26,0.34-0.4c0.23-0.29,0.47-0.59,0.83-0.78
							c0.42-0.23,0.83-0.44,1.26-0.58c0.15-0.05,0.29-0.12,0.43-0.2c0.25-0.13,0.52-0.27,0.84-0.28L493.3,710.04z"/>
					</g>
					<g>
						<path className="st73" d="M463.44,701.79c-0.55,0.54-1.41,0.67-1.79,1.36c-0.34,0.61-0.41,1.32-0.69,2.07
							c0.34-0.21,0.51-0.43,0.69-0.63c0.47-0.54,0.9-1.11,1.44-1.57c0.1-0.08,0.21-0.25,0.34-0.12c0.12,0.11-0.06,0.21-0.12,0.3
							c-0.74,1.04-1.47,2.08-2.22,3.11c-0.21,0.29-0.13,0.61-0.13,0.92c0,0.28-0.01,0.54-0.26,0.74c-0.75,0.61-0.98,1.39-0.71,2.31
							c0.1,0.36-0.01,0.59-0.31,0.81c-0.91,0.66-1.82,1.33-2.59,2.14c-1.04,1.12-1.9,2.4-2.8,3.63c-0.28,0.38-0.56,0.75-0.84,1.13
							c-0.03,0.04-0.1,0.09-0.14,0.09c-0.09-0.01-0.1-0.1-0.09-0.18c0.1-0.75,0.15-1.51,0.33-2.25c0.08-0.32,0.4-0.6,0.64-0.87
							c0.31-0.36,0.58-0.71,0.64-1.21c0.07-0.56,0.46-0.98,0.8-1.41c0.16-0.21,0.23-0.39,0.17-0.66c-0.12-0.54-0.01-1.01,0.45-1.38
							c0.49-0.39,0.94-0.81,1.41-1.21c0.17-0.14,0.29-0.31,0.28-0.54c-0.05-0.9,0.32-1.64,0.91-2.29c0.33-0.37,0.59-0.76,0.49-1.3
							c-0.08-0.42,0.27-0.66,0.49-0.93c0.74-0.9,1.51-1.78,2.27-2.66c0.08-0.09,0.15-0.24,0.28-0.2c0.15,0.05,0.06,0.21,0.05,0.32
							c0,0.06-0.01,0.12-0.01,0.18c-0.03,0.51-0.01,0.52,0.49,0.37C463.08,701.82,463.22,701.72,463.44,701.79z"/>
						<path className="st8" d="M453.33,718.22c-0.01,0-0.02,0-0.02,0c-0.07-0.01-0.12-0.04-0.16-0.09c-0.05-0.06-0.06-0.14-0.05-0.24
							c0.03-0.21,0.05-0.41,0.08-0.62c0.06-0.54,0.13-1.1,0.26-1.64c0.07-0.29,0.29-0.52,0.51-0.75c0.06-0.06,0.11-0.12,0.16-0.18
							c0.29-0.33,0.55-0.67,0.61-1.14c0.07-0.52,0.38-0.92,0.69-1.3c0.05-0.06,0.09-0.11,0.13-0.17c0.15-0.2,0.19-0.34,0.14-0.55
							c-0.14-0.63,0.03-1.14,0.5-1.52c0.32-0.26,0.64-0.54,0.95-0.81c0.15-0.13,0.31-0.27,0.46-0.4c0.17-0.15,0.24-0.27,0.23-0.43
							c-0.05-0.85,0.26-1.63,0.94-2.39c0.34-0.37,0.54-0.72,0.45-1.18c-0.08-0.41,0.17-0.67,0.38-0.89c0.05-0.05,0.1-0.11,0.14-0.16
							c0.6-0.73,1.23-1.46,1.84-2.16l0.43-0.5c0.01-0.02,0.03-0.03,0.04-0.05c0.07-0.09,0.19-0.25,0.39-0.18
							c0.23,0.08,0.18,0.3,0.16,0.39c-0.01,0.03-0.01,0.05-0.01,0.07c0,0.04,0,0.07-0.01,0.11c0,0.03,0,0.05-0.01,0.08
							c-0.01,0.1-0.01,0.25,0,0.31c0.06,0,0.21-0.05,0.32-0.08c0.03-0.01,0.07-0.02,0.1-0.04c0.13-0.05,0.29-0.11,0.5-0.04
							l0.21,0.07l-0.16,0.16c-0.24,0.23-0.53,0.39-0.81,0.55c-0.39,0.21-0.75,0.41-0.96,0.78c-0.2,0.35-0.3,0.75-0.41,1.17
							c-0.04,0.15-0.08,0.31-0.13,0.47c0.1-0.1,0.19-0.19,0.27-0.29l0.05-0.06c0.11-0.13,0.22-0.26,0.33-0.39
							c0.35-0.41,0.7-0.84,1.13-1.2c0.02-0.01,0.03-0.03,0.05-0.04c0.08-0.08,0.27-0.27,0.48-0.07c0.07,0.06,0.08,0.13,0.08,0.18
							c0,0.11-0.08,0.19-0.14,0.25c-0.02,0.02-0.04,0.04-0.05,0.05l-0.26,0.37c-0.64,0.9-1.3,1.83-1.95,2.74
							c-0.14,0.19-0.13,0.4-0.12,0.64c0,0.07,0.01,0.14,0.01,0.21c0,0.25,0,0.59-0.31,0.84c-0.7,0.57-0.92,1.28-0.66,2.17
							c0.12,0.39,0,0.7-0.36,0.96c-0.89,0.65-1.81,1.32-2.57,2.13c-0.81,0.87-1.52,1.86-2.21,2.82c-0.19,0.27-0.39,0.54-0.58,0.8
							c-0.25,0.34-0.52,0.7-0.85,1.13C453.53,718.13,453.44,718.22,453.33,718.22z M462.34,701.12c-0.02,0.01-0.05,0.06-0.07,0.09
							c-0.02,0.02-0.03,0.05-0.05,0.07l-0.43,0.5c-0.61,0.7-1.24,1.43-1.84,2.16c-0.05,0.06-0.1,0.12-0.15,0.17
							c-0.19,0.2-0.36,0.38-0.31,0.65c0.12,0.61-0.18,1.04-0.52,1.42c-0.64,0.71-0.92,1.4-0.87,2.19c0.02,0.31-0.17,0.52-0.33,0.65
							c-0.15,0.13-0.31,0.27-0.46,0.4c-0.31,0.27-0.63,0.55-0.96,0.82c-0.39,0.31-0.52,0.72-0.41,1.24
							c0.07,0.29,0.01,0.52-0.19,0.78c-0.04,0.06-0.09,0.11-0.14,0.17c-0.3,0.37-0.58,0.72-0.63,1.17
							c-0.07,0.55-0.37,0.94-0.68,1.29c-0.05,0.06-0.11,0.12-0.17,0.19c-0.19,0.2-0.39,0.41-0.44,0.63
							c-0.13,0.52-0.19,1.07-0.25,1.6c-0.02,0.2-0.05,0.4-0.07,0.6c0.32-0.42,0.58-0.77,0.83-1.11c0.19-0.27,0.39-0.53,0.58-0.8
							c0.69-0.96,1.41-1.96,2.23-2.85c0.78-0.83,1.71-1.51,2.61-2.16c0.27-0.19,0.34-0.38,0.25-0.66c-0.29-0.99-0.04-1.82,0.75-2.46
							c0.19-0.16,0.21-0.36,0.21-0.63c0-0.06,0-0.13-0.01-0.19c-0.01-0.25-0.03-0.54,0.17-0.81c0.66-0.91,1.32-1.84,1.95-2.74
							l0.26-0.37c0.01-0.02,0.02-0.03,0.04-0.04c-0.02,0.02-0.04,0.04-0.06,0.05c-0.41,0.34-0.76,0.76-1.1,1.16
							c-0.11,0.13-0.22,0.26-0.33,0.39l-0.05,0.06c-0.16,0.19-0.34,0.4-0.67,0.6l-0.33,0.2l0.13-0.36c0.11-0.29,0.18-0.58,0.26-0.86
							c0.12-0.44,0.23-0.85,0.44-1.24c0.25-0.44,0.66-0.67,1.06-0.89c0.11-0.06,0.21-0.12,0.32-0.18c-0.25,0.07-0.4,0.11-0.51,0.02
							c-0.12-0.1-0.11-0.27-0.1-0.54c0-0.03,0-0.06,0.01-0.09c0-0.03,0.01-0.06,0.01-0.09c0-0.04,0.01-0.09,0.02-0.12
							C462.33,701.17,462.34,701.14,462.34,701.12z"/>
					</g>
					<g>
						<path className="st73" d="M484.37,706.83c0.3-0.38,0.51-0.82,0.79-1.21c0.31-0.43,0.48-0.88,0.53-1.41
							c0.12-1.13,0.34-2.23,1.12-3.14c0.33-0.39,0.59-0.85,0.86-1.28c0.19-0.3,0.33-0.6,0.27-0.98c-0.19-1.15-0.35-2.3-0.55-3.45
							c-0.05-0.26-0.02-0.48,0.16-0.67c0.91-1.04,1.92-1.97,2.92-2.92c0.22-0.21,0.44-0.41,0.67-0.62c0.2-0.19,0.31-0.13,0.36,0.13
							c0.09,0.54,0.19,1.07,0.29,1.61c0.06,0.32-0.02,0.58-0.27,0.82c-0.46,0.42-0.88,0.88-1.33,1.31c-0.19,0.18-0.24,0.34-0.12,0.6
							c0.4,0.88,0.76,1.78,1.17,2.66c0.19,0.4,0.16,0.67-0.17,0.99c-1.05,1.07-2.06,2.18-3.09,3.27c-0.14,0.15-0.19,0.34-0.26,0.53
							c-0.19,0.56-0.39,1.12-0.57,1.68c-0.1,0.32-0.29,0.55-0.57,0.74c-0.71,0.47-1.4,0.97-2.1,1.46
							C484.43,706.88,484.4,706.85,484.37,706.83z"/>
						<path className="st8" d="M484.46,707.08l-0.29-0.23l0.08-0.11c0.17-0.21,0.31-0.45,0.45-0.68c0.1-0.17,0.21-0.36,0.33-0.53
							c0.3-0.42,0.46-0.85,0.51-1.35c0.11-1.11,0.33-2.26,1.15-3.22c0.25-0.29,0.46-0.63,0.66-0.95c0.06-0.1,0.13-0.21,0.19-0.31
							c0.19-0.29,0.3-0.56,0.25-0.88c-0.09-0.54-0.18-1.1-0.26-1.63c-0.09-0.6-0.19-1.21-0.29-1.82c-0.06-0.33,0-0.58,0.19-0.79
							c0.82-0.94,1.73-1.8,2.62-2.64l0.31-0.29c0.14-0.14,0.29-0.27,0.44-0.41c0.08-0.07,0.15-0.14,0.23-0.21
							c0.05-0.05,0.2-0.19,0.37-0.13c0.17,0.06,0.2,0.27,0.22,0.34c0.09,0.54,0.19,1.07,0.29,1.61c0.07,0.37-0.04,0.68-0.32,0.94
							c-0.26,0.24-0.51,0.49-0.76,0.74c-0.19,0.19-0.38,0.38-0.57,0.57c-0.16,0.15-0.18,0.25-0.09,0.44
							c0.18,0.4,0.36,0.82,0.53,1.22c0.2,0.47,0.41,0.97,0.64,1.44c0.22,0.46,0.16,0.79-0.19,1.15c-0.73,0.75-1.46,1.52-2.16,2.27
							c-0.31,0.33-0.62,0.67-0.93,1c-0.11,0.11-0.16,0.26-0.21,0.42l-0.03,0.1c-0.19,0.54-0.38,1.09-0.56,1.64
							c-0.11,0.34-0.31,0.6-0.62,0.81c-0.5,0.34-1.01,0.69-1.5,1.04c-0.2,0.14-0.4,0.28-0.6,0.42L484.46,707.08z M491.32,691.16
							c-0.02,0.01-0.05,0.02-0.1,0.07c-0.08,0.07-0.15,0.14-0.23,0.21c-0.15,0.13-0.29,0.27-0.44,0.41l-0.31,0.29
							c-0.88,0.83-1.79,1.7-2.6,2.62c-0.13,0.15-0.17,0.31-0.12,0.56c0.1,0.61,0.2,1.22,0.29,1.82c0.08,0.53,0.17,1.09,0.26,1.63
							c0.07,0.44-0.09,0.77-0.29,1.08c-0.06,0.1-0.13,0.2-0.19,0.31c-0.21,0.34-0.42,0.68-0.68,0.99c-0.77,0.9-0.98,2.01-1.09,3.07
							c-0.06,0.55-0.23,1.02-0.56,1.48c-0.11,0.16-0.22,0.34-0.32,0.51c-0.07,0.12-0.15,0.24-0.23,0.37
							c0.09-0.06,0.18-0.13,0.27-0.19c0.49-0.34,1-0.7,1.51-1.04c0.26-0.17,0.42-0.38,0.51-0.67c0.18-0.55,0.37-1.11,0.56-1.64
							l0.03-0.1c0.06-0.18,0.12-0.36,0.27-0.52c0.31-0.33,0.62-0.66,0.93-1c0.7-0.75,1.43-1.53,2.16-2.28
							c0.27-0.28,0.31-0.48,0.14-0.84c-0.22-0.48-0.43-0.97-0.64-1.45c-0.17-0.4-0.35-0.81-0.53-1.21c-0.14-0.3-0.09-0.52,0.15-0.75
							c0.19-0.18,0.38-0.38,0.57-0.56c0.25-0.25,0.5-0.5,0.76-0.74c0.21-0.2,0.28-0.41,0.23-0.69c-0.1-0.54-0.19-1.07-0.29-1.61
							C491.34,691.21,491.33,691.17,491.32,691.16z"/>
					</g>
					<g>
						<path className="st73" d="M432.91,723.14c0.2-1.15,0.38-2.29,0.99-3.32c0.19-0.31,0.36-0.63,0.51-0.96
							c0.13-0.3,0.28-0.45,0.62-0.25c0.24,0.14,0.42,0.11,0.37-0.24c-0.18-1.27,0.28-2.32,1.13-3.22c0.5-0.53,0.88-1.15,1.23-1.78
							c0.05-0.1,0.04-0.28,0.21-0.25c0.14,0.03,0.11,0.19,0.13,0.31c0.09,0.88,0.2,1.74,0.43,2.6c0.06,0.21,0.14,0.4,0.24,0.58
							c0.69,1.26,0.67,2.62,0.56,3.99c-0.02,0.29-0.14,0.4-0.43,0.39c-0.23-0.01-0.45,0.03-0.68,0.03c-0.99,0.02-1.4,0.8-1.89,1.45
							c-0.2,0.27-0.38,0.37-0.72,0.28c-0.86-0.22-1.68-0.15-2.44,0.37c-0.04,0.03-0.08,0.04-0.12,0.05
							C433.01,723.17,432.98,723.15,432.91,723.14z"/>
						<path className="st8" d="M433.02,723.3c-0.03,0-0.06-0.01-0.08-0.01c-0.02,0-0.03-0.01-0.06-0.01l-0.13-0.02l0.04-0.24
							c0.19-1.1,0.39-2.24,0.99-3.26c0.2-0.34,0.37-0.65,0.5-0.95c0.07-0.17,0.17-0.34,0.35-0.4c0.13-0.04,0.28-0.01,0.46,0.09
							c0.11,0.06,0.16,0.06,0.16,0.06c0,0,0.02-0.04,0-0.16c-0.18-1.23,0.2-2.32,1.17-3.34c0.51-0.54,0.89-1.19,1.21-1.75
							c0.01-0.01,0.02-0.04,0.02-0.07c0.02-0.08,0.09-0.3,0.33-0.25c0.22,0.05,0.23,0.26,0.23,0.37c0,0.02,0,0.04,0,0.06
							c0.08,0.77,0.19,1.68,0.43,2.58c0.05,0.18,0.12,0.35,0.23,0.55c0.72,1.32,0.68,2.73,0.57,4.07c-0.03,0.36-0.22,0.53-0.57,0.51
							c-0.12-0.01-0.25,0-0.38,0.01c-0.09,0.01-0.19,0.02-0.28,0.02c-0.78,0.01-1.17,0.55-1.58,1.11c-0.07,0.1-0.14,0.19-0.21,0.28
							c-0.21,0.28-0.44,0.44-0.86,0.33c-0.89-0.23-1.65-0.12-2.32,0.35c-0.05,0.03-0.1,0.06-0.17,0.07
							C433.05,723.3,433.04,723.3,433.02,723.3z M434.76,718.66c-0.01,0-0.03,0-0.04,0.01c-0.06,0.02-0.12,0.1-0.19,0.25
							c-0.14,0.31-0.3,0.63-0.51,0.98c-0.57,0.96-0.76,2-0.95,3.11c0.01,0,0.01-0.01,0.01-0.01c0.73-0.51,1.59-0.64,2.55-0.39
							c0.26,0.07,0.39,0.02,0.58-0.23c0.07-0.09,0.14-0.18,0.2-0.28c0.43-0.6,0.88-1.21,1.79-1.23c0.09,0,0.18-0.01,0.27-0.02
							c0.14-0.01,0.28-0.02,0.42-0.01c0.21,0.01,0.27-0.04,0.29-0.26c0.1-1.29,0.14-2.66-0.54-3.91c-0.12-0.22-0.2-0.41-0.26-0.61
							c-0.25-0.92-0.36-1.84-0.44-2.62c0-0.03,0-0.05,0-0.08c0-0.03,0-0.08-0.01-0.1c-0.01,0.02-0.01,0.04-0.02,0.06
							c-0.01,0.04-0.02,0.08-0.05,0.12c-0.32,0.58-0.72,1.24-1.25,1.81c-0.9,0.95-1.26,1.97-1.1,3.11c0.03,0.21-0.01,0.36-0.12,0.43
							c-0.11,0.08-0.26,0.06-0.45-0.05C434.87,718.68,434.81,718.66,434.76,718.66z M437.92,713.25"/>
					</g>
					<g>
						<path className="st73" d="M481.73,711.02c0.35-0.9,0.74-1.79,1.33-2.56c0.31-0.41,0.88-0.49,1.39-0.58
							c0.11-0.02,0.15,0.08,0.2,0.15c0.49,0.82,1.09,1.56,1.5,2.44c0.13,0.27,0.14,0.5-0.03,0.71c-0.62,0.78-0.93,1.69-1.12,2.65
							c-0.03,0.15-0.12,0.27-0.22,0.39c-0.83,1.12-1.86,1.97-3.19,2.43c-0.4,0.14-0.76,0.36-1.21,0.48
							c0.32-0.39,0.64-0.79,0.96-1.18c0.59-0.73,1.29-1.32,2.11-1.77c0.43-0.23,0.5-0.65,0.64-1.04c0.01-0.04-0.01-0.13-0.05-0.16
							c-0.07-0.05-0.14,0.02-0.2,0.06c-0.91,0.62-1.84,1.23-2.64,2c-0.07,0.07-0.14,0.2-0.25,0.17c-0.16-0.05-0.08-0.2-0.09-0.32
							c-0.01-0.23,0.01-0.46-0.02-0.68c-0.14-1.01,0.33-1.78,0.93-2.51c0.38-0.46,0.75-0.94,1.2-1.51
							c-0.58,0.18-0.78,0.69-1.23,0.82L481.73,711.02z"/>
						<path className="st8" d="M480.01,717.39l0.57-0.7c0.22-0.27,0.44-0.54,0.66-0.81c0.61-0.75,1.33-1.36,2.15-1.8
							c0.32-0.18,0.42-0.47,0.52-0.8c0.01-0.04,0.02-0.07,0.04-0.11c-0.01,0.01-0.01,0.01-0.02,0.02l-0.25,0.17
							c-0.81,0.55-1.65,1.13-2.37,1.82c-0.01,0.01-0.03,0.03-0.04,0.05c-0.06,0.07-0.17,0.21-0.34,0.15
							c-0.23-0.07-0.2-0.28-0.19-0.37c0-0.03,0.01-0.05,0.01-0.07c0-0.09,0-0.18,0-0.27c0-0.14,0-0.27-0.01-0.4
							c-0.16-1.1,0.37-1.91,0.96-2.62c0.22-0.26,0.44-0.54,0.66-0.82c-0.16,0.15-0.32,0.29-0.55,0.35l-0.08,0.02l-0.14-0.14
							l0.03-0.08c0.31-0.82,0.72-1.77,1.34-2.6c0.32-0.43,0.89-0.53,1.39-0.61l0.08-0.01c0.19-0.03,0.28,0.12,0.33,0.2l0.01,0.01
							c0.17,0.29,0.37,0.58,0.55,0.85c0.34,0.51,0.69,1.03,0.96,1.6c0.1,0.21,0.21,0.54-0.05,0.86c-0.54,0.67-0.88,1.5-1.09,2.59
							c-0.03,0.17-0.13,0.3-0.22,0.43l-0.02,0.02c-0.91,1.22-1.98,2.04-3.26,2.48c-0.16,0.06-0.31,0.12-0.47,0.2
							c-0.23,0.1-0.47,0.21-0.75,0.29L480.01,717.39z M484,712.85c0.04,0,0.08,0.01,0.12,0.04c0.1,0.06,0.13,0.22,0.1,0.31
							c-0.02,0.05-0.03,0.1-0.05,0.16c-0.11,0.36-0.23,0.73-0.65,0.96c-0.78,0.42-1.48,1.01-2.07,1.73
							c-0.22,0.27-0.44,0.54-0.66,0.8c0.09-0.04,0.17-0.07,0.26-0.11c0.16-0.07,0.33-0.15,0.5-0.21c1.23-0.43,2.25-1.21,3.13-2.39
							l0.02-0.02c0.08-0.1,0.15-0.2,0.17-0.32c0.22-1.14,0.58-2,1.15-2.71c0.13-0.16,0.13-0.32,0.02-0.57
							c-0.26-0.55-0.6-1.06-0.94-1.56c-0.19-0.28-0.38-0.57-0.56-0.86l-0.01-0.02c-0.02-0.03-0.04-0.07-0.05-0.08l-0.08,0.02
							c-0.47,0.08-0.96,0.17-1.22,0.51c-0.53,0.7-0.9,1.5-1.19,2.23c0.07-0.06,0.14-0.12,0.22-0.19c0.19-0.18,0.41-0.38,0.74-0.49
							l0.42-0.13l-0.27,0.34c-0.15,0.18-0.28,0.36-0.41,0.52c-0.27,0.35-0.53,0.67-0.79,0.99c-0.55,0.66-1.04,1.41-0.9,2.4
							c0.02,0.15,0.02,0.3,0.02,0.44c0,0.09,0,0.17,0,0.26c0,0.04,0,0.08-0.01,0.11c0,0.02-0.01,0.06-0.01,0.07
							c0.02-0.01,0.04-0.04,0.06-0.06c0.02-0.03,0.04-0.05,0.06-0.07c0.74-0.71,1.59-1.29,2.41-1.85l0.25-0.17
							c0.01-0.01,0.02-0.01,0.03-0.02C483.84,712.9,483.91,712.85,484,712.85z"/>
					</g>
					<g>
						<path className="st73" d="M454.98,711.55c-0.55,1.3-1.35,2.42-2.13,3.55c-0.25,0.36-0.33,0.74-0.36,1.15
							c-0.04,0.59-0.08,1.18-0.1,1.77c-0.01,0.21-0.08,0.37-0.2,0.54c-0.66,0.94-1.32,1.89-1.97,2.83c-0.13,0.18-0.2,0.37-0.19,0.59
							c0.01,0.26-0.03,0.52,0,0.77c0.09,0.67-0.08,1.27-0.49,1.79c-0.46,0.58-0.54,1.28-0.58,1.97c-0.02,0.35-0.11,0.57-0.45,0.69
							c-0.31,0.11-0.59,0.29-0.92,0.36c-0.08-0.17,0.03-0.24,0.12-0.31c0.49-0.4,0.65-0.94,0.6-1.54c-0.06-0.73,0.08-1.39,0.5-1.98
							c0.38-0.54,0.3-1.11,0.21-1.7c-0.07-0.46-0.2-0.92-0.09-1.4c0.12-0.57,0.43-1.06,0.69-1.58c0.2-0.4,0.3-0.8,0.22-1.22
							c-0.06-0.29,0.03-0.5,0.25-0.68c0.3-0.26,0.59-0.53,0.89-0.78c0.23-0.19,0.36-0.38,0.04-0.61c-0.19-0.14-0.14-0.28,0-0.45
							c1.1-1.35,2.46-2.42,3.68-3.64C454.76,711.6,454.83,711.52,454.98,711.55z"/>
						<path className="st8" d="M447.49,727.72l-0.05-0.1c-0.12-0.26,0.06-0.4,0.14-0.47l0.01-0.01c0.42-0.34,0.61-0.82,0.55-1.42
							c-0.07-0.78,0.11-1.48,0.52-2.07c0.35-0.5,0.27-1.04,0.19-1.6c-0.01-0.09-0.03-0.18-0.05-0.27c-0.07-0.38-0.13-0.77-0.05-1.17
							c0.1-0.45,0.31-0.86,0.51-1.25c0.06-0.12,0.12-0.24,0.18-0.36c0.21-0.44,0.28-0.79,0.2-1.14c-0.07-0.33,0.03-0.58,0.29-0.81
							c0.15-0.12,0.29-0.26,0.44-0.38c0.15-0.13,0.3-0.27,0.45-0.4c0.05-0.04,0.19-0.16,0.18-0.23c0-0.04-0.05-0.1-0.13-0.16
							c-0.11-0.08-0.17-0.17-0.18-0.27c-0.02-0.14,0.07-0.27,0.16-0.38c0.72-0.89,1.57-1.67,2.39-2.43c0.43-0.4,0.88-0.81,1.3-1.22
							c0.08-0.08,0.2-0.2,0.43-0.16l0.16,0.03l-0.07,0.15c-0.53,1.25-1.29,2.35-2.03,3.41l-0.12,0.17c-0.2,0.29-0.3,0.62-0.34,1.09
							c-0.05,0.64-0.08,1.28-0.1,1.77c-0.01,0.22-0.08,0.42-0.22,0.62c-0.4,0.56-0.79,1.13-1.18,1.69c-0.27,0.38-0.53,0.76-0.8,1.15
							c-0.12,0.17-0.17,0.33-0.17,0.51c0,0.11,0,0.22,0,0.32c-0.01,0.15-0.01,0.3,0.01,0.44c0.09,0.7-0.08,1.34-0.52,1.89
							c-0.44,0.55-0.51,1.21-0.55,1.89c-0.02,0.33-0.1,0.65-0.55,0.81c-0.11,0.04-0.22,0.09-0.34,0.14
							c-0.19,0.09-0.38,0.18-0.6,0.22L447.49,727.72z M454.7,711.84c-0.4,0.4-0.82,0.78-1.23,1.16c-0.81,0.75-1.66,1.52-2.37,2.4
							c-0.07,0.09-0.1,0.14-0.1,0.18c0,0.02,0.03,0.05,0.07,0.08c0.16,0.12,0.24,0.24,0.24,0.37c0.01,0.18-0.13,0.32-0.28,0.45
							c-0.15,0.13-0.3,0.26-0.45,0.39c-0.14,0.13-0.29,0.26-0.44,0.39c-0.19,0.16-0.25,0.32-0.2,0.55c0.08,0.41,0.01,0.82-0.23,1.31
							c-0.06,0.12-0.12,0.24-0.19,0.36c-0.2,0.38-0.4,0.76-0.49,1.18c-0.08,0.35-0.02,0.7,0.05,1.07c0.02,0.09,0.03,0.18,0.05,0.28
							c0.09,0.59,0.18,1.21-0.23,1.8c-0.38,0.55-0.54,1.16-0.47,1.89c0.06,0.7-0.16,1.26-0.65,1.66l-0.01,0.01c0,0,0,0,0,0
							c0.11-0.04,0.21-0.09,0.32-0.14c0.12-0.05,0.24-0.11,0.36-0.15c0.25-0.09,0.34-0.23,0.36-0.57c0.05-0.73,0.13-1.44,0.61-2.05
							c0.39-0.49,0.55-1.06,0.47-1.68c-0.02-0.16-0.02-0.32-0.01-0.48c0-0.11,0.01-0.2,0-0.3c-0.01-0.24,0.06-0.45,0.21-0.68
							c0.27-0.38,0.53-0.76,0.8-1.15c0.38-0.55,0.78-1.13,1.18-1.69c0.11-0.16,0.16-0.3,0.17-0.47c0.02-0.49,0.06-1.13,0.1-1.77
							c0.04-0.51,0.16-0.89,0.39-1.22l0.12-0.17C453.51,713.91,454.19,712.93,454.7,711.84z"/>
					</g>
					<g>
						<path className="st73" d="M467.15,718.53c0.44-0.65,0.92-1.2,1.22-1.83c0.57-1.2,1.74-1.48,2.79-1.94
							c0.87-0.39,1.8-0.67,2.63-1.13c0.6-0.33,1.09-0.86,1.63-1.3c0.84-0.69,1.59-1.5,2.57-2.01c0.26-0.14,0.52-0.35,0.96-0.35
							c-0.21,0.2-0.37,0.34-0.5,0.49c-0.09,0.09-0.25,0.2-0.12,0.34c0.1,0.11,0.25-0.01,0.32-0.08c0.5-0.55,1.18-0.57,1.84-0.66
							c0.07-0.01,0.15-0.03,0.21,0.1c-0.51,0.3-1.02,0.62-1.55,0.88c-0.77,0.38-1.41,0.94-2.04,1.51c-0.39,0.35-0.81,0.69-1.18,1.07
							c-0.46,0.47-1.02,0.69-1.63,0.85c-0.66,0.17-1.32,0.35-1.98,0.5c-0.83,0.18-1.36,0.83-2.01,1.28
							c-0.95,0.67-1.85,1.42-2.78,2.13C467.44,718.44,467.37,718.54,467.15,718.53z M477.88,711.08c0.16,0.07,0.22-0.01,0.28-0.1
							c0.01-0.02-0.03-0.09-0.05-0.09C477.99,710.88,477.93,710.96,477.88,711.08z"/>
						<path className="st8" d="M467.17,718.67c-0.01,0-0.02,0-0.02,0l-0.25-0.01l0.14-0.21c0.14-0.21,0.29-0.41,0.43-0.6
							c0.3-0.41,0.58-0.8,0.78-1.21c0.5-1.04,1.43-1.42,2.32-1.78c0.18-0.07,0.36-0.15,0.54-0.23c0.33-0.15,0.67-0.28,1-0.41
							c0.54-0.21,1.1-0.43,1.62-0.71c0.4-0.22,0.77-0.55,1.12-0.86c0.16-0.14,0.32-0.29,0.49-0.42c0.24-0.19,0.47-0.4,0.7-0.61
							c0.58-0.52,1.18-1.05,1.9-1.42c0.05-0.03,0.1-0.06,0.16-0.09c0.22-0.13,0.48-0.28,0.87-0.27l0.34,0l-0.24,0.23
							c-0.07,0.07-0.13,0.12-0.19,0.18c-0.12,0.11-0.22,0.21-0.31,0.3c-0.01,0.02-0.03,0.03-0.05,0.05
							c-0.03,0.03-0.07,0.07-0.08,0.09c0,0,0,0.01,0.01,0.01c0.01,0,0.07-0.03,0.11-0.08c0.48-0.52,1.1-0.6,1.7-0.68
							c0.08-0.01,0.15-0.02,0.23-0.03c0.08-0.01,0.25-0.04,0.36,0.18l0.06,0.11l-0.11,0.06c-0.16,0.09-0.32,0.18-0.47,0.28
							c-0.35,0.21-0.71,0.42-1.09,0.61c-0.78,0.39-1.43,0.97-2.01,1.49c-0.12,0.1-0.23,0.21-0.35,0.31
							c-0.28,0.24-0.56,0.49-0.82,0.75c-0.51,0.52-1.14,0.74-1.69,0.89l-0.21,0.05c-0.58,0.15-1.18,0.31-1.78,0.44
							c-0.56,0.12-0.98,0.47-1.42,0.83c-0.17,0.15-0.36,0.3-0.54,0.43c-0.66,0.47-1.31,0.98-1.94,1.48
							c-0.28,0.22-0.55,0.44-0.83,0.65c-0.01,0.01-0.02,0.02-0.03,0.03C467.5,718.58,467.4,718.67,467.17,718.67z M478.51,710.2
							c-0.1,0.04-0.2,0.1-0.29,0.15c-0.06,0.03-0.11,0.06-0.17,0.09c-0.69,0.35-1.27,0.87-1.84,1.38c-0.23,0.2-0.47,0.41-0.71,0.61
							c-0.16,0.13-0.32,0.28-0.48,0.42c-0.36,0.33-0.74,0.66-1.17,0.9c-0.53,0.29-1.1,0.51-1.65,0.73
							c-0.33,0.13-0.67,0.26-0.99,0.41c-0.18,0.08-0.37,0.16-0.55,0.23c-0.89,0.36-1.72,0.7-2.18,1.65
							c-0.21,0.44-0.5,0.84-0.81,1.26c-0.04,0.05-0.08,0.11-0.12,0.16c0.23-0.18,0.47-0.36,0.7-0.55c0.63-0.5,1.28-1.01,1.95-1.48
							c0.18-0.13,0.36-0.27,0.53-0.42c0.44-0.37,0.91-0.75,1.53-0.89c0.59-0.13,1.19-0.29,1.77-0.44l0.21-0.05
							c0.52-0.14,1.11-0.34,1.57-0.81c0.26-0.27,0.55-0.53,0.83-0.77c0.12-0.1,0.23-0.2,0.35-0.31c0.59-0.53,1.25-1.13,2.07-1.53
							c0.36-0.18,0.72-0.39,1.07-0.6c0.06-0.03,0.11-0.07,0.17-0.1c-0.02,0-0.03,0-0.05,0.01c-0.58,0.07-1.12,0.14-1.53,0.59
							c-0.04,0.04-0.17,0.17-0.32,0.17c0,0,0,0,0,0c-0.04,0-0.08-0.01-0.12-0.03c0,0.03,0,0.07-0.02,0.09
							c-0.11,0.2-0.26,0.25-0.45,0.16l-0.12-0.05l0.05-0.12c0.03-0.08,0.12-0.3,0.36-0.29c0.01,0,0.01,0,0.02,0
							c-0.04-0.16,0.1-0.29,0.16-0.35c0.01-0.01,0.03-0.03,0.04-0.04C478.4,710.31,478.45,710.25,478.51,710.2z"/>
					</g>
					<g>
						<path className="st73" d="M442.76,717.85c0-0.6-0.05-1.2,0.01-1.79c0.07-0.6-0.03-1.17-0.31-1.67c-0.84-1.49-0.62-3.08-0.51-4.66
							c0.03-0.47,0.43-0.79,0.74-1.12c0.22-0.25,0.48-0.46,0.73-0.69c0.07-0.06,0.12-0.16,0.22-0.13c0.13,0.04,0.11,0.17,0.1,0.27
							c-0.03,0.51,0,1.04-0.12,1.54c-0.24,1.01,0,1.96,0.28,2.9c0.16,0.53,0.21,1.03,0.06,1.59c-0.33,1.18-0.48,2.41-0.93,3.56
							c-0.03,0.07-0.06,0.14-0.09,0.2C442.87,717.85,442.82,717.85,442.76,717.85z"/>
						<path className="st8" d="M443.02,718l-0.4-0.01v-0.13c0-0.18,0-0.37-0.01-0.55c-0.01-0.41-0.02-0.84,0.02-1.26
							c0.07-0.58-0.03-1.12-0.3-1.59c-0.78-1.38-0.67-2.85-0.56-4.28c0.01-0.15,0.02-0.3,0.03-0.45c0.03-0.44,0.34-0.75,0.61-1.03
							c0.06-0.06,0.11-0.12,0.17-0.18c0.15-0.17,0.32-0.32,0.49-0.47c0.08-0.07,0.16-0.15,0.24-0.22c0.01-0.01,0.02-0.02,0.03-0.04
							c0.06-0.06,0.16-0.18,0.32-0.13c0.22,0.07,0.21,0.29,0.2,0.38l0,0.03c-0.01,0.15-0.01,0.31-0.02,0.47
							c-0.01,0.36-0.02,0.74-0.1,1.09c-0.23,0.98,0,1.9,0.27,2.83c0.19,0.63,0.2,1.13,0.06,1.67c-0.12,0.44-0.22,0.89-0.32,1.32
							c-0.16,0.74-0.33,1.51-0.61,2.25c-0.02,0.05-0.04,0.1-0.06,0.15L443.02,718z M443.6,707.93c-0.01,0.01-0.03,0.03-0.04,0.04
							c-0.02,0.02-0.03,0.04-0.05,0.05c-0.08,0.08-0.16,0.15-0.25,0.23c-0.16,0.14-0.33,0.29-0.47,0.45
							c-0.06,0.06-0.11,0.12-0.17,0.18c-0.26,0.27-0.51,0.52-0.53,0.86c-0.01,0.15-0.02,0.3-0.03,0.46
							c-0.11,1.45-0.21,2.82,0.52,4.13c0.29,0.52,0.4,1.11,0.33,1.75c-0.04,0.4-0.03,0.82-0.02,1.23c0,0.1,0.01,0.2,0.01,0.3
							c0.27-0.71,0.44-1.47,0.6-2.2c0.1-0.44,0.2-0.9,0.32-1.34c0.13-0.49,0.12-0.94-0.05-1.52c-0.29-0.97-0.53-1.92-0.28-2.97
							c0.08-0.33,0.09-0.67,0.1-1.04c0-0.16,0.01-0.32,0.02-0.48l0-0.04C443.6,707.99,443.61,707.94,443.6,707.93z"/>
					</g>
					<g>
						<path className="st73" d="M478.35,723.28c0.23-0.5,0.55-0.99,0.99-1.22c0.67-0.34,1.07-0.81,1.23-1.52
							c0.09-0.43,0.32-0.72,0.75-0.89c0.74-0.28,1.19-0.91,1.68-1.49c0.08-0.1,0.14-0.28,0.3-0.22c0.13,0.04,0.09,0.21,0.13,0.32
							c0.11,0.4,0.04,0.9,0.65,1.01c0.15,0.03,0.02,0.18-0.07,0.23c-1.18,0.67-2.14,1.65-3.26,2.39
							C479.98,722.41,479.1,722.71,478.35,723.28z"/>
						<path className="st8" d="M477.99,723.72l0.23-0.5c0.31-0.66,0.65-1.07,1.05-1.28c0.66-0.34,1.01-0.78,1.16-1.43
							c0.11-0.49,0.38-0.81,0.83-0.99c0.6-0.23,1-0.71,1.42-1.21c0.07-0.08,0.13-0.16,0.2-0.24c0.01-0.02,0.03-0.04,0.05-0.06
							c0.06-0.09,0.18-0.27,0.41-0.19c0.18,0.06,0.19,0.23,0.2,0.33c0,0.03,0.01,0.06,0.01,0.08c0.03,0.09,0.04,0.19,0.06,0.28
							c0.06,0.36,0.12,0.57,0.48,0.63c0.14,0.02,0.17,0.13,0.18,0.16c0.03,0.15-0.14,0.28-0.2,0.32c-0.67,0.38-1.28,0.87-1.87,1.34
							c-0.44,0.36-0.9,0.72-1.38,1.04c-0.35,0.23-0.73,0.43-1.09,0.61c-0.44,0.22-0.9,0.46-1.3,0.76L477.99,723.72z M483.24,718.07
							C483.24,718.07,483.24,718.07,483.24,718.07c-0.02,0.01-0.05,0.06-0.07,0.09c-0.02,0.03-0.04,0.06-0.07,0.09
							c-0.07,0.08-0.13,0.16-0.2,0.24c-0.42,0.51-0.86,1.03-1.53,1.29c-0.37,0.14-0.57,0.38-0.66,0.79
							c-0.16,0.73-0.57,1.24-1.3,1.61c-0.24,0.12-0.46,0.35-0.67,0.67c0.29-0.17,0.58-0.33,0.87-0.47c0.36-0.18,0.73-0.37,1.06-0.6
							c0.47-0.32,0.92-0.68,1.36-1.03c0.6-0.48,1.22-0.98,1.9-1.37c-0.47-0.13-0.53-0.52-0.59-0.83c-0.02-0.09-0.03-0.17-0.05-0.25
							c-0.01-0.04-0.02-0.09-0.02-0.13c0-0.03-0.01-0.09-0.02-0.1C483.25,718.07,483.24,718.07,483.24,718.07z"/>
					</g>
					<g>
						<path className="st73" d="M508.82,690.26c0.01-0.03,0.01-0.05,0.02-0.08c0.01,0.01,0.03,0.02,0.04,0.03
							C508.86,690.23,508.84,690.25,508.82,690.26C508.82,690.26,508.82,690.26,508.82,690.26z"/>
						<polygon className="st8" points="508.82,690.44 508.66,690.31 508.75,689.96 509.1,690.2 						"/>
					</g>
					<g>
						<path className="st73" d="M481.75,711.04c-0.01,0.01-0.02,0.02-0.03,0.03c-0.01-0.01-0.01-0.01-0.02-0.02
							c0.01-0.01,0.02-0.02,0.03-0.03C481.73,711.02,481.75,711.04,481.75,711.04z"/>
						<path className="st8" d="M481.73,711.27l-0.22-0.22l0.1-0.1c0.01-0.01,0.02-0.02,0.02-0.02l0.1,0.09l0.07-0.07l0,0l0.03-0.03
							l0.11,0.11L481.73,711.27z"/>
					</g>
					<g>
						<path className="st77" d="M433.63,709.94c-0.01,0-0.02-0.01-0.03-0.01C433.61,709.93,433.62,709.94,433.63,709.94
							C433.63,709.95,433.63,709.94,433.63,709.94z"/>
						<path className="st8" d="M434.34,710.71l-0.83-0.68l0.01-0.02l-0.02,0.01l-0.07-0.1l0.09-0.09l0.07,0.07l0.07-0.1l0.19,0.1
							l-0.05,0.05L434.34,710.71z M433.63,709.94L433.63,709.94L433.63,709.94z"/>
					</g>
					<g>
						<path className="st72" d="M461.52,740.01c0.01,0.03,0.02,0.07,0.04,0.1C461.47,740.1,461.46,740.07,461.52,740.01z"/>
						<path className="st8" d="M461.76,740.27l-0.22-0.02c-0.12-0.01-0.17-0.07-0.19-0.12c-0.02-0.05-0.02-0.12,0.07-0.21l0.15-0.16
							L461.76,740.27z M461.56,740.05l0.05,0.05c0.01-0.01,0.01-0.04,0-0.07c-0.01-0.03-0.03-0.05-0.04-0.05L461.56,740.05z"/>
					</g>
					<g>
						<path className="st77" d="M477.88,711.08c0.05-0.12,0.1-0.21,0.23-0.2c0.02,0,0.06,0.08,0.05,0.09
							C478.11,711.07,478.04,711.16,477.88,711.08z"/>
						<path className="st8" d="M477.99,711.25c-0.05,0-0.11-0.01-0.17-0.04l-0.12-0.05l0.05-0.12c0.03-0.08,0.12-0.3,0.36-0.29
							c0.06,0,0.11,0.04,0.15,0.11c0.04,0.07,0.04,0.14,0.01,0.19C478.21,711.18,478.11,711.25,477.99,711.25z"/>
					</g>
					<g>
						<path className="st76" d="M446.87,715.75c-0.02,0.29-0.14,0.4-0.43,0.39c-0.23-0.01-0.45,0.03-0.68,0.03
							c-0.99,0.02-1.4,0.8-1.89,1.45c-0.2,0.27-0.38,0.37-0.72,0.28c-0.86-0.22-1.68-0.15-2.44,0.37c-0.04,0.03-0.08,0.04-0.12,0.05
							c-0.03,0.01-0.06-0.01-0.13-0.02c0.2-1.15,0.38-2.29,0.99-3.32c0.19-0.31,0.36-0.63,0.51-0.96c0.13-0.3,0.28-0.45,0.62-0.25
							c0.24,0.14,0.42,0.11,0.37-0.24c-0.18-1.27,0.28-2.32,1.13-3.22c0.5-0.53,0.88-1.15,1.23-1.78c0.05-0.1,0.04-0.28,0.21-0.25
							c0.14,0.03,0.11,0.19,0.13,0.31c0.09,0.88,0.2,1.74,0.43,2.6c0.06,0.21,0.14,0.4,0.24,0.58
							C447,713.02,446.98,714.38,446.87,715.75z M451.73,708.53c-0.44,1.14-0.73,2.34-1.28,3.45c-0.03,0.07-0.07,0.13-0.11,0.19
							c-0.06-0.01-0.11-0.02-0.17-0.02c0.06-0.6,0.07-1.2,0.2-1.78c0.13-0.59,0.09-1.16-0.14-1.69c-0.68-1.57-0.3-3.13-0.03-4.69
							c0.08-0.47,0.51-0.74,0.85-1.04c0.25-0.22,0.53-0.41,0.79-0.61c0.07-0.05,0.13-0.15,0.23-0.11c0.13,0.05,0.09,0.18,0.07,0.28
							c-0.09,0.51-0.11,1.04-0.27,1.52c-0.34,0.98-0.2,1.95-0.02,2.91C451.94,707.49,451.94,707.98,451.73,708.53z M462.35,708.64
							c-0.05-0.55,0.12-1,0.63-1.31c0.53-0.32,1.04-0.68,1.56-1.02c0.18-0.12,0.33-0.27,0.35-0.5c0.07-0.9,0.53-1.58,1.19-2.15
							c0.38-0.32,0.69-0.68,0.65-1.23c-0.03-0.43,0.35-0.62,0.61-0.86c0.85-0.8,1.73-1.57,2.6-2.35c0.09-0.08,0.18-0.22,0.3-0.16
							c0.14,0.07,0.03,0.21,0.01,0.33c-0.01,0.06-0.03,0.12-0.04,0.18c-0.09,0.5-0.08,0.51,0.44,0.43c0.16-0.02,0.32-0.11,0.52-0.01
							c-0.62,0.46-1.48,0.48-1.95,1.12c-0.41,0.56-0.58,1.25-0.95,1.97c0.37-0.16,0.56-0.36,0.77-0.54
							c0.53-0.47,1.03-0.98,1.64-1.37c0.11-0.07,0.24-0.22,0.35-0.08c0.1,0.13-0.08,0.2-0.16,0.28c-0.86,0.94-1.72,1.87-2.6,2.8
							c-0.25,0.26-0.21,0.59-0.25,0.9c-0.04,0.28-0.08,0.53-0.35,0.7c-0.83,0.51-1.15,1.25-1,2.2c0.06,0.37-0.08,0.59-0.41,0.77
							c-0.99,0.54-1.98,1.08-2.84,1.79c-1.18,0.97-2.19,2.13-3.25,3.24c-0.32,0.34-0.65,0.67-0.98,1.01
							c-0.04,0.04-0.11,0.08-0.15,0.07c-0.09-0.02-0.09-0.11-0.07-0.19c0.2-0.73,0.35-1.48,0.62-2.18c0.12-0.31,0.29-0.56,0.56-0.8
							c0.35-0.31,0.85-0.6,0.98-1.1c0.14-0.55,0.58-0.91,0.97-1.29C462.29,709.09,462.38,708.92,462.35,708.64z M455.17,719.15
							c0,0.68-0.24,1.25-0.72,1.71c-0.54,0.52-0.7,1.19-0.83,1.88c-0.07,0.34-0.18,0.55-0.54,0.62c-0.32,0.07-0.62,0.21-0.96,0.24
							c-0.06-0.18,0.06-0.24,0.16-0.3c0.54-0.33,0.77-0.85,0.8-1.45c0.03-0.73,0.26-1.37,0.75-1.9c0.45-0.49,0.44-1.06,0.43-1.66
							c-0.01-0.47-0.08-0.94,0.09-1.4c0.2-0.55,0.57-1,0.88-1.48c0.25-0.37,0.4-0.75,0.37-1.19c-0.02-0.3,0.1-0.49,0.33-0.64
							c0.33-0.21,0.65-0.45,0.98-0.66c0.25-0.16,0.4-0.33,0.12-0.6c-0.17-0.17-0.1-0.29,0.06-0.45c1.26-1.2,2.75-2.09,4.12-3.14
							c0.08-0.06,0.17-0.13,0.31-0.08c-0.72,1.22-1.65,2.23-2.57,3.25c-0.29,0.32-0.42,0.69-0.51,1.1
							c-0.12,0.58-0.23,1.16-0.33,1.74c-0.04,0.2-0.13,0.36-0.27,0.51c-0.78,0.85-1.55,1.7-2.32,2.56
							c-0.15,0.16-0.24,0.34-0.26,0.56C455.24,718.64,455.16,718.9,455.17,719.15z M481.75,698.96c0.29-0.61,0.88-1,1.36-1.45
							c0.37-0.35,0.65-0.73,0.65-1.24c0.01-0.93,0.59-1.53,1.21-2.1c0.19-0.17,0.29-0.46,0.59-0.5c0.03,0.25,0.06,0.5,0.08,0.75
							c0.03,0.31-0.05,0.63,0.11,0.97c0.41-2.04,1.54-3.69,2.83-5.28c-0.14,0.68-0.28,1.36-0.13,2.15c0.59-1.08,0.96-2.11,1.02-3.25
							c0.04-0.7,0.13-1.39,0.2-2.08c0.02-0.17,0.05-0.34,0.18-0.45c0.36-0.32,0.73-0.62,1.15-0.98c-0.02,0.44-0.21,0.75-0.37,1.03
							c-0.27,0.47-0.22,0.96-0.23,1.46c0,0.1,0.02,0.18,0.12,0.23c0.2,0.09,1.03-0.34,1.07-0.57c0.13-0.68,0.26-1.37,0.35-2.06
							c0.05-0.4,0.17-0.64,0.6-0.73c0.71-0.15,1.13-0.72,1.61-1.2c0.13-0.14,0.23-0.46,0.46-0.33c0.16,0.09-0.06,0.32-0.09,0.49
							c-0.1,0.55-0.23,1.1-0.35,1.65c-0.07,0.34,0.03,0.64,0.2,0.91c0.1,0.17,0.16,0.36,0.26,0.52c0.34,0.57,0.22,1.04-0.16,1.54
							c-0.44,0.57-0.72,1.2-0.71,1.95c0,0.34-0.27,0.62-0.49,0.85c-0.68,0.72-0.66,1.49-0.29,2.33c0.21,0.47,0.34,0.97,0.53,1.45
							c0.14,0.35,0.05,0.61-0.21,0.85c-0.54,0.48-1.08,0.97-1.63,1.44c-0.29,0.24-0.67,0.11-0.96,0.07
							c-0.74-0.1-1.16,0.34-1.63,0.73c-0.29,0.24-0.57,0.49-0.85,0.74c-0.14,0.13-0.3,0.13-0.47,0.13c-0.58,0-1.15,0.01-1.73-0.01
							c-0.47-0.01-0.86,0.13-1.17,0.49c-0.08,0.09-0.19,0.16-0.25,0.26c-0.48,0.77-1.12,1.17-2.06,1.22
							c-0.54,0.03-1.02,0.44-1.51,0.72c-0.14,0.08-0.27,0.2-0.53,0.17C480.92,700.86,481.3,699.9,481.75,698.96z M483.48,708.78
							c-0.46,0.47-1.02,0.69-1.63,0.85c-0.66,0.17-1.32,0.35-1.98,0.5c-0.83,0.18-1.36,0.83-2.01,1.28
							c-0.95,0.67-1.85,1.42-2.78,2.13c-0.08,0.06-0.15,0.15-0.37,0.15c0.44-0.65,0.92-1.2,1.22-1.83c0.57-1.2,1.74-1.48,2.79-1.94
							c0.87-0.39,1.8-0.67,2.63-1.13c0.6-0.33,1.09-0.86,1.63-1.3c0.84-0.69,1.59-1.5,2.57-2.01c0.26-0.14,0.52-0.35,0.96-0.35
							c-0.21,0.2-0.37,0.34-0.5,0.49c-0.09,0.09-0.25,0.2-0.12,0.34c0.1,0.11,0.25-0.01,0.32-0.08c0.5-0.55,1.18-0.57,1.84-0.66
							c0.07-0.01,0.15-0.03,0.21,0.1c-0.51,0.3-1.02,0.62-1.55,0.88c-0.77,0.38-1.41,0.94-2.04,1.51
							C484.26,708.06,483.84,708.4,483.48,708.78z M491.56,715.03c-1.18,0.67-2.14,1.55-3.26,2.3c-0.76,0.51-1.65,0.54-2.4,1.1
							c0.23-0.5,0.55-0.99,0.99-1.22c0.67-0.34,1.07-0.81,1.23-1.52c0.09-0.43,0.32-0.72,0.75-0.89c0.74-0.28,1.19-0.91,1.68-1.49
							c0.08-0.1,0.14-0.28,0.3-0.22c0.13,0.04,0.09,0.21,0.13,0.32c0.11,0.4,0.04,0.9,0.65,1.01
							C491.78,714.46,491.65,714.98,491.56,715.03z M492.56,708.99c-0.03,0.15-0.12,0.27-0.22,0.4c-0.83,1.12-1.86,1.97-3.19,2.43
							c-0.4,0.14-0.76,0.36-1.21,0.48c0.32-0.39,0.64-0.79,0.96-1.18c0.59-0.73,1.29-1.32,2.11-1.77c0.43-0.23,0.5-0.65,0.64-1.04
							c0.01-0.04-0.01-0.13-0.05-0.16c-0.07-0.05-0.14,0.02-0.2,0.06c-0.91,0.62-1.84,1.23-2.64,2c-0.07,0.07-0.14,0.2-0.25,0.17
							c-0.16-0.05-0.08-0.2-0.09-0.32c-0.01-0.23,0.01-0.46-0.02-0.68c-0.14-1.01,0.33-1.78,0.93-2.51c0.38-0.46,0.75-0.94,1.2-1.51
							c-0.58,0.18-0.78,0.69-1.23,0.82l-0.03,0.03c-0.01-0.01-0.01-0.01-0.02-0.02c0.01-0.01,0.02-0.02,0.03-0.03
							c0.35-0.9,0.74-1.79,1.33-2.56c0.31-0.41,0.88-0.49,1.39-0.58c0.11-0.02,0.15,0.08,0.2,0.15c0.49,0.82,1.09,1.56,1.5,2.44
							c0.13,0.27,0.14,0.5-0.03,0.71C493.06,707.13,492.74,708.03,492.56,708.99z"/>
						<path className="st8" d="M452.01,723.74l-0.03-0.1c-0.09-0.27,0.11-0.39,0.2-0.45l0.01-0.01c0.46-0.28,0.71-0.73,0.73-1.34
							c0.03-0.79,0.3-1.46,0.79-1.99c0.42-0.45,0.4-0.99,0.39-1.57c0-0.09-0.01-0.18-0.01-0.27c-0.02-0.38-0.03-0.78,0.11-1.17
							c0.16-0.44,0.42-0.81,0.67-1.17c0.08-0.11,0.15-0.22,0.23-0.33c0.27-0.4,0.38-0.74,0.35-1.1c-0.02-0.33,0.1-0.58,0.4-0.77
							c0.16-0.1,0.32-0.22,0.48-0.32c0.16-0.11,0.33-0.23,0.5-0.34c0.05-0.03,0.2-0.13,0.21-0.21c0-0.04-0.03-0.1-0.11-0.18
							c-0.1-0.09-0.14-0.19-0.14-0.29c0-0.14,0.1-0.26,0.21-0.36c0.83-0.79,1.78-1.46,2.69-2.1c0.48-0.34,0.97-0.69,1.44-1.05
							c0.08-0.07,0.23-0.17,0.44-0.1l0.16,0.05l-0.08,0.14c-0.69,1.17-1.58,2.16-2.45,3.12l-0.14,0.15
							c-0.23,0.26-0.38,0.58-0.48,1.03c-0.13,0.63-0.25,1.26-0.33,1.74c-0.04,0.22-0.13,0.4-0.3,0.58c-0.46,0.5-0.93,1.02-1.38,1.52
							c-0.31,0.35-0.63,0.69-0.94,1.03c-0.14,0.16-0.21,0.31-0.23,0.48c-0.01,0.11-0.03,0.22-0.05,0.32
							c-0.03,0.15-0.05,0.29-0.05,0.43l0,0c0,0.71-0.26,1.32-0.76,1.81c-0.5,0.49-0.66,1.13-0.79,1.81
							c-0.06,0.32-0.18,0.64-0.65,0.73c-0.12,0.02-0.23,0.06-0.35,0.1c-0.2,0.06-0.4,0.12-0.63,0.14L452.01,723.74z M461.21,708.92
							c-0.45,0.34-0.91,0.67-1.37,0.99c-0.9,0.64-1.84,1.3-2.66,2.07c-0.08,0.08-0.12,0.13-0.12,0.16c0,0.02,0.02,0.05,0.06,0.09
							c0.14,0.14,0.2,0.27,0.19,0.4c-0.02,0.18-0.17,0.31-0.34,0.41c-0.17,0.11-0.33,0.22-0.5,0.33c-0.16,0.11-0.32,0.22-0.49,0.33
							c-0.21,0.14-0.29,0.28-0.27,0.52c0.03,0.42-0.09,0.81-0.39,1.27c-0.07,0.11-0.15,0.22-0.23,0.34
							c-0.24,0.35-0.49,0.71-0.64,1.11c-0.12,0.34-0.11,0.69-0.09,1.07c0,0.09,0.01,0.19,0.01,0.28c0.01,0.6,0.02,1.22-0.47,1.76
							c-0.45,0.49-0.68,1.08-0.71,1.82c-0.03,0.7-0.32,1.22-0.86,1.56l-0.02,0.01c0,0,0,0,0,0c0.11-0.02,0.22-0.06,0.34-0.09
							c0.12-0.04,0.25-0.08,0.38-0.1c0.26-0.05,0.37-0.18,0.43-0.52c0.14-0.72,0.31-1.41,0.87-1.95c0.45-0.44,0.68-0.98,0.68-1.61
							l0,0c0-0.16,0.03-0.32,0.05-0.48c0.02-0.1,0.03-0.2,0.04-0.3c0.02-0.24,0.12-0.44,0.3-0.64c0.31-0.34,0.63-0.69,0.94-1.03
							c0.45-0.5,0.92-1.02,1.39-1.52c0.13-0.14,0.2-0.28,0.23-0.44c0.09-0.48,0.2-1.11,0.33-1.75c0.1-0.5,0.27-0.86,0.54-1.16
							l0.14-0.15C459.76,710.82,460.56,709.93,461.21,708.92z M485.55,718.87l0.23-0.5c0.31-0.66,0.65-1.07,1.05-1.28
							c0.66-0.34,1.01-0.78,1.16-1.43c0.11-0.49,0.38-0.81,0.83-0.99c0.6-0.23,1-0.71,1.42-1.21c0.07-0.08,0.13-0.16,0.2-0.24
							c0.01-0.02,0.03-0.04,0.05-0.06c0.06-0.09,0.18-0.27,0.41-0.19c0.18,0.06,0.19,0.23,0.2,0.33c0,0.03,0.01,0.06,0.01,0.08
							c0.03,0.09,0.04,0.19,0.06,0.28c0.06,0.36,0.12,0.57,0.48,0.63c0.04,0.01,0.15,0.04,0.17,0.2c0.03,0.17-0.06,0.57-0.2,0.65
							c-0.69,0.4-1.33,0.88-1.94,1.34c-0.42,0.32-0.85,0.65-1.31,0.96c-0.38,0.25-0.78,0.39-1.17,0.52
							c-0.43,0.15-0.84,0.29-1.22,0.57L485.55,718.87z M490.79,713.23C490.79,713.23,490.79,713.23,490.79,713.23
							c-0.02,0.01-0.05,0.06-0.07,0.09c-0.02,0.03-0.04,0.06-0.07,0.09c-0.07,0.08-0.13,0.16-0.2,0.24
							c-0.42,0.51-0.86,1.03-1.53,1.29c-0.37,0.14-0.57,0.38-0.66,0.79c-0.16,0.72-0.57,1.24-1.3,1.61
							c-0.25,0.13-0.48,0.37-0.7,0.71c0.28-0.14,0.57-0.24,0.86-0.34c0.39-0.13,0.76-0.26,1.11-0.49c0.45-0.3,0.88-0.63,1.3-0.95
							c0.61-0.47,1.25-0.95,1.95-1.35c0.04-0.06,0.09-0.26,0.08-0.35c-0.53-0.11-0.6-0.52-0.66-0.84c-0.02-0.09-0.03-0.17-0.05-0.25
							c-0.01-0.04-0.02-0.09-0.02-0.13c0-0.03-0.01-0.09-0.02-0.1C490.8,713.23,490.79,713.23,490.79,713.23z M491.47,714.92
							L491.47,714.92L491.47,714.92z M491.49,714.91C491.49,714.91,491.49,714.91,491.49,714.91
							C491.49,714.91,491.49,714.91,491.49,714.91L491.49,714.91z M440.58,718.46c-0.03,0-0.06-0.01-0.08-0.01
							c-0.02,0-0.03-0.01-0.06-0.01l-0.13-0.02l0.04-0.24c0.19-1.1,0.39-2.24,0.99-3.26c0.2-0.34,0.37-0.65,0.5-0.95
							c0.07-0.17,0.17-0.34,0.35-0.4c0.13-0.04,0.28-0.01,0.46,0.09c0.11,0.06,0.16,0.06,0.16,0.06c0,0,0.02-0.04,0-0.16
							c-0.18-1.23,0.2-2.32,1.17-3.34c0.51-0.54,0.89-1.19,1.21-1.75c0.01-0.01,0.02-0.04,0.02-0.07c0.02-0.08,0.09-0.3,0.33-0.25
							c0.22,0.05,0.23,0.26,0.23,0.37c0,0.02,0,0.04,0,0.06c0.08,0.77,0.19,1.68,0.43,2.58c0.05,0.18,0.12,0.35,0.23,0.55
							c0.72,1.32,0.68,2.73,0.57,4.07c-0.03,0.36-0.22,0.53-0.57,0.51c-0.12-0.01-0.25,0-0.38,0.01c-0.09,0.01-0.19,0.02-0.29,0.02
							c-0.78,0.01-1.17,0.55-1.58,1.11c-0.07,0.1-0.14,0.19-0.21,0.28c-0.21,0.28-0.44,0.44-0.86,0.33
							c-0.89-0.23-1.65-0.12-2.32,0.35c-0.05,0.03-0.1,0.06-0.17,0.07C440.61,718.46,440.59,718.46,440.58,718.46z M442.31,713.81
							c-0.01,0-0.03,0-0.04,0.01c-0.06,0.02-0.12,0.1-0.19,0.25c-0.14,0.31-0.3,0.63-0.51,0.98c-0.57,0.96-0.76,2-0.95,3.11
							c0.01,0,0.01-0.01,0.01-0.01c0.73-0.51,1.59-0.64,2.55-0.39c0.26,0.07,0.39,0.02,0.58-0.23c0.07-0.09,0.14-0.18,0.2-0.28
							c0.43-0.6,0.88-1.21,1.79-1.23c0.09,0,0.18-0.01,0.27-0.02c0.14-0.01,0.28-0.02,0.42-0.01c0.21,0.01,0.27-0.04,0.29-0.26l0,0
							c0.1-1.29,0.14-2.66-0.54-3.91c-0.12-0.22-0.2-0.41-0.26-0.61c-0.25-0.92-0.36-1.84-0.44-2.62c0-0.03,0-0.05,0-0.08
							c0-0.03,0-0.08-0.01-0.1c-0.01,0.02-0.01,0.04-0.02,0.06c-0.01,0.04-0.02,0.08-0.05,0.12c-0.32,0.58-0.72,1.24-1.25,1.81
							c-0.9,0.95-1.26,1.97-1.1,3.11c0.03,0.21-0.01,0.36-0.12,0.43c-0.11,0.08-0.26,0.06-0.45-0.05
							C442.43,713.84,442.36,713.81,442.31,713.81z M445.48,708.4C445.47,708.4,445.47,708.4,445.48,708.4
							C445.47,708.4,445.47,708.4,445.48,708.4z M459.05,714.98c-0.02,0-0.03,0-0.05-0.01c-0.07-0.02-0.12-0.05-0.15-0.11
							c-0.04-0.07-0.05-0.15-0.02-0.25c0.05-0.2,0.11-0.4,0.16-0.61c0.13-0.53,0.27-1.07,0.47-1.59c0.13-0.34,0.32-0.61,0.6-0.86
							c0.08-0.07,0.16-0.14,0.25-0.2c0.29-0.24,0.6-0.48,0.69-0.82c0.13-0.51,0.5-0.86,0.85-1.2c0.05-0.05,0.1-0.1,0.15-0.15
							c0.18-0.18,0.23-0.31,0.21-0.53v0c-0.06-0.64,0.17-1.13,0.69-1.44c0.35-0.21,0.71-0.45,1.04-0.68
							c0.17-0.11,0.34-0.23,0.51-0.34c0.19-0.13,0.28-0.24,0.29-0.4c0.06-0.85,0.47-1.58,1.24-2.24c0.38-0.33,0.63-0.64,0.6-1.12
							c-0.03-0.41,0.26-0.65,0.49-0.83c0.06-0.05,0.11-0.09,0.16-0.14c0.69-0.65,1.41-1.29,2.11-1.91l0.49-0.44
							c0.01-0.01,0.03-0.03,0.05-0.05c0.08-0.08,0.22-0.23,0.41-0.13c0.21,0.11,0.14,0.32,0.11,0.4c-0.01,0.03-0.02,0.05-0.02,0.07
							c-0.01,0.04-0.01,0.07-0.02,0.11c-0.01,0.02-0.01,0.05-0.02,0.07c-0.02,0.09-0.05,0.25-0.04,0.31
							c0.06,0.01,0.22-0.02,0.33-0.04c0.03-0.01,0.07-0.01,0.1-0.02c0.13-0.03,0.3-0.07,0.5,0.02l0.2,0.1l-0.18,0.13
							c-0.27,0.2-0.58,0.32-0.88,0.44c-0.41,0.16-0.8,0.31-1.05,0.65c-0.24,0.33-0.4,0.71-0.56,1.11c-0.06,0.15-0.12,0.29-0.19,0.45
							c0.12-0.08,0.21-0.17,0.31-0.25l0.06-0.06c0.13-0.11,0.25-0.23,0.38-0.34c0.4-0.36,0.81-0.74,1.27-1.04
							c0.02-0.01,0.03-0.02,0.05-0.04c0.09-0.07,0.31-0.23,0.48-0.01c0.06,0.07,0.06,0.14,0.06,0.18c-0.01,0.11-0.1,0.18-0.17,0.23
							c-0.02,0.02-0.04,0.03-0.05,0.04l-0.32,0.34c-0.74,0.81-1.51,1.64-2.28,2.46c-0.16,0.17-0.18,0.38-0.2,0.62
							c-0.01,0.07-0.01,0.14-0.02,0.2c-0.03,0.25-0.08,0.59-0.42,0.8c-0.77,0.48-1.08,1.15-0.93,2.06c0.06,0.41-0.09,0.69-0.48,0.91
							c-0.97,0.53-1.97,1.07-2.82,1.78c-0.92,0.76-1.75,1.65-2.56,2.51c-0.23,0.24-0.45,0.48-0.68,0.72
							c-0.29,0.3-0.6,0.63-0.98,1.01C459.24,714.92,459.15,714.98,459.05,714.98z M462.49,708.63c0.03,0.3-0.06,0.51-0.29,0.75
							c-0.05,0.05-0.1,0.1-0.16,0.15c-0.34,0.33-0.66,0.64-0.78,1.08c-0.11,0.43-0.47,0.72-0.78,0.97c-0.08,0.07-0.16,0.13-0.24,0.2
							c-0.25,0.22-0.41,0.45-0.53,0.75c-0.19,0.5-0.33,1.04-0.46,1.56c-0.05,0.2-0.1,0.39-0.15,0.58c0.37-0.38,0.68-0.69,0.96-0.99
							c0.23-0.24,0.45-0.48,0.68-0.72c0.81-0.87,1.65-1.76,2.58-2.53c0.88-0.72,1.89-1.28,2.86-1.81c0.29-0.16,0.38-0.33,0.34-0.62
							c-0.16-1.02,0.2-1.81,1.06-2.34c0.21-0.13,0.25-0.33,0.29-0.6c0.01-0.06,0.01-0.13,0.02-0.19c0.02-0.25,0.04-0.54,0.27-0.78
							c0.77-0.81,1.54-1.65,2.28-2.45l0.32-0.34c0.01-0.01,0.03-0.03,0.04-0.04c-0.02,0.02-0.04,0.03-0.06,0.05
							c-0.45,0.29-0.85,0.66-1.24,1.01c-0.13,0.12-0.25,0.23-0.38,0.35l-0.06,0.05c-0.18,0.17-0.39,0.35-0.74,0.51l-0.35,0.16
							l0.18-0.34c0.14-0.28,0.26-0.55,0.36-0.82c0.17-0.42,0.33-0.81,0.59-1.17c0.3-0.41,0.74-0.58,1.17-0.75
							c0.11-0.04,0.22-0.09,0.34-0.14c-0.25,0.04-0.41,0.05-0.51-0.05c-0.11-0.11-0.07-0.29-0.02-0.55
							c0.01-0.03,0.01-0.06,0.02-0.08c0.01-0.03,0.01-0.06,0.02-0.09c0.01-0.04,0.02-0.08,0.04-0.12c0.01-0.02,0.02-0.04,0.02-0.06
							c-0.02,0.01-0.06,0.05-0.08,0.08c-0.02,0.02-0.04,0.04-0.06,0.06l-0.49,0.44c-0.69,0.62-1.41,1.26-2.1,1.91
							c-0.06,0.05-0.12,0.1-0.17,0.15c-0.22,0.18-0.4,0.33-0.39,0.6c0.04,0.62-0.31,1.01-0.7,1.34c-0.73,0.62-1.09,1.27-1.15,2.06
							c-0.02,0.31-0.23,0.49-0.41,0.61c-0.17,0.11-0.34,0.22-0.51,0.34c-0.34,0.23-0.7,0.47-1.06,0.69
							C462.63,707.71,462.44,708.09,462.49,708.63L462.49,708.63z M474.72,713.82c-0.01,0-0.02,0-0.02,0l-0.25-0.01l0.14-0.21
							c0.14-0.21,0.29-0.41,0.43-0.6c0.3-0.41,0.58-0.8,0.78-1.21c0.5-1.05,1.43-1.42,2.32-1.78c0.18-0.07,0.36-0.15,0.54-0.22
							c0.33-0.15,0.67-0.28,1-0.41c0.54-0.21,1.1-0.43,1.62-0.71c0.4-0.22,0.77-0.55,1.12-0.86c0.16-0.14,0.32-0.29,0.49-0.42
							c0.24-0.19,0.47-0.4,0.7-0.61c0.58-0.52,1.18-1.05,1.9-1.42c0.05-0.03,0.1-0.06,0.16-0.09c0.22-0.13,0.47-0.27,0.85-0.27
							c0.01,0,0.01,0,0.02,0l0.34,0l-0.24,0.23c-0.07,0.07-0.13,0.12-0.19,0.18c-0.12,0.11-0.22,0.21-0.31,0.3
							c-0.01,0.02-0.03,0.03-0.05,0.05c-0.03,0.03-0.07,0.07-0.08,0.09c0,0,0,0.01,0.01,0.01c0.01,0,0.07-0.03,0.11-0.08
							c0.48-0.52,1.1-0.6,1.7-0.68c0.08-0.01,0.15-0.02,0.22-0.03c0.08-0.01,0.25-0.04,0.35,0.18l0.06,0.11l-0.11,0.06
							c-0.16,0.09-0.32,0.18-0.47,0.28c-0.35,0.21-0.71,0.42-1.09,0.61c-0.78,0.39-1.43,0.97-2.01,1.49
							c-0.12,0.1-0.23,0.21-0.35,0.31c-0.28,0.24-0.56,0.49-0.82,0.75l0,0c-0.51,0.52-1.14,0.74-1.69,0.89l-0.2,0.05
							c-0.58,0.15-1.19,0.32-1.79,0.44c-0.56,0.12-0.98,0.47-1.42,0.83c-0.17,0.15-0.36,0.3-0.54,0.43
							c-0.66,0.47-1.31,0.98-1.94,1.47c-0.28,0.22-0.55,0.44-0.83,0.65c-0.01,0.01-0.02,0.02-0.03,0.03
							C475.06,713.73,474.95,713.82,474.72,713.82z M486.07,705.35c-0.1,0.04-0.2,0.1-0.29,0.15c-0.06,0.03-0.11,0.06-0.17,0.09
							c-0.69,0.35-1.27,0.87-1.84,1.38c-0.23,0.2-0.47,0.41-0.71,0.61c-0.16,0.13-0.32,0.28-0.48,0.42
							c-0.36,0.33-0.74,0.66-1.17,0.9c-0.53,0.29-1.1,0.51-1.65,0.73c-0.33,0.13-0.67,0.26-0.99,0.41
							c-0.18,0.08-0.37,0.16-0.55,0.23c-0.89,0.36-1.72,0.7-2.18,1.65c-0.21,0.44-0.5,0.84-0.81,1.26
							c-0.04,0.05-0.08,0.11-0.12,0.16c0.23-0.18,0.47-0.37,0.7-0.55c0.63-0.5,1.28-1.01,1.95-1.48c0.18-0.13,0.36-0.27,0.53-0.42
							c0.45-0.37,0.91-0.75,1.53-0.89c0.59-0.13,1.19-0.29,1.77-0.44l0.2-0.05c0.52-0.14,1.11-0.34,1.57-0.81v0
							c0.26-0.27,0.55-0.53,0.83-0.77c0.12-0.1,0.23-0.2,0.35-0.31c0.59-0.53,1.25-1.13,2.07-1.53c0.36-0.18,0.72-0.39,1.07-0.6
							c0.06-0.03,0.11-0.07,0.17-0.1c-0.02,0-0.03,0-0.05,0.01c-0.58,0.07-1.12,0.14-1.53,0.59c-0.04,0.04-0.17,0.17-0.32,0.17
							c0,0,0,0,0,0c-0.08,0-0.14-0.03-0.2-0.09c-0.19-0.21,0.01-0.41,0.09-0.48c0.01-0.01,0.03-0.03,0.04-0.04
							C485.95,705.47,486.01,705.41,486.07,705.35z M487.56,712.55l0.56-0.68c0.23-0.28,0.45-0.55,0.68-0.83
							c0.61-0.75,1.33-1.36,2.15-1.8c0.32-0.18,0.42-0.47,0.52-0.8c0.01-0.04,0.02-0.07,0.04-0.11c-0.01,0.01-0.01,0.01-0.02,0.02
							l-0.25,0.17c-0.81,0.55-1.65,1.13-2.37,1.82c-0.01,0.01-0.03,0.03-0.04,0.05c-0.06,0.07-0.17,0.21-0.34,0.15
							c-0.23-0.07-0.2-0.28-0.19-0.37c0-0.03,0.01-0.05,0.01-0.07c0-0.09,0-0.18,0-0.27c0-0.14,0-0.27-0.01-0.4
							c-0.16-1.1,0.37-1.91,0.96-2.62c0.22-0.26,0.44-0.54,0.66-0.82c-0.15,0.14-0.3,0.27-0.51,0.34l-0.1,0.11l-0.22-0.22l0.11-0.1
							c0.31-0.81,0.72-1.75,1.33-2.57c0.32-0.43,0.89-0.53,1.39-0.61l0.08-0.01c0.19-0.03,0.28,0.12,0.33,0.2l0.01,0.01
							c0.17,0.29,0.37,0.58,0.55,0.85c0.34,0.51,0.69,1.03,0.96,1.6c0.1,0.21,0.21,0.54-0.05,0.86c-0.54,0.67-0.88,1.5-1.09,2.59
							l0,0c-0.03,0.17-0.13,0.3-0.22,0.43l-0.02,0.02c-0.91,1.22-1.98,2.04-3.26,2.48c-0.16,0.06-0.31,0.12-0.47,0.2
							c-0.23,0.1-0.47,0.21-0.75,0.29L487.56,712.55z M491.55,708.01c0.04,0,0.08,0.01,0.12,0.04c0.1,0.06,0.13,0.22,0.1,0.31
							c-0.02,0.05-0.03,0.1-0.05,0.16c-0.11,0.36-0.23,0.73-0.65,0.96c-0.78,0.43-1.48,1.01-2.07,1.73
							c-0.22,0.27-0.44,0.54-0.66,0.8c0.09-0.04,0.17-0.07,0.26-0.11c0.16-0.07,0.33-0.15,0.5-0.21c1.23-0.43,2.25-1.21,3.13-2.39
							l0.02-0.02c0.08-0.1,0.15-0.2,0.17-0.32c0.22-1.14,0.58-2,1.15-2.71c0.13-0.16,0.13-0.32,0.02-0.57
							c-0.26-0.55-0.6-1.06-0.94-1.56c-0.19-0.28-0.38-0.57-0.56-0.86l-0.01-0.02c-0.02-0.03-0.04-0.07-0.05-0.08l-0.08,0.02
							c-0.47,0.08-0.96,0.17-1.22,0.51c-0.53,0.7-0.9,1.5-1.19,2.23c0.07-0.06,0.14-0.12,0.22-0.19c0.19-0.18,0.41-0.38,0.74-0.49
							l0.42-0.13l-0.27,0.34c-0.15,0.18-0.28,0.36-0.41,0.52c-0.27,0.35-0.53,0.67-0.79,0.99c-0.55,0.66-1.04,1.41-0.9,2.4
							c0.02,0.15,0.02,0.3,0.02,0.44c0,0.09,0,0.17,0,0.26c0,0.04,0,0.08-0.01,0.11c0,0.02-0.01,0.06-0.01,0.07
							c0.02-0.01,0.04-0.04,0.06-0.06c0.02-0.03,0.04-0.05,0.06-0.07c0.74-0.71,1.59-1.29,2.41-1.85l0.25-0.17
							c0.01-0.01,0.02-0.01,0.03-0.02C491.39,708.06,491.46,708.01,491.55,708.01z M450.42,712.32l-0.4-0.05l0.01-0.13
							c0.02-0.18,0.03-0.36,0.05-0.54c0.03-0.41,0.06-0.84,0.15-1.25c0.12-0.57,0.08-1.12-0.14-1.61c-0.63-1.45-0.37-2.91-0.12-4.31
							c0.03-0.15,0.05-0.3,0.08-0.45c0.07-0.43,0.41-0.71,0.71-0.96c0.06-0.05,0.13-0.1,0.18-0.16c0.17-0.15,0.36-0.29,0.53-0.42
							c0.09-0.07,0.18-0.13,0.26-0.2c0.01-0.01,0.02-0.02,0.04-0.03c0.06-0.06,0.18-0.16,0.33-0.09c0.22,0.09,0.18,0.31,0.16,0.4
							l-0.01,0.03c-0.03,0.15-0.05,0.31-0.07,0.46c-0.05,0.36-0.09,0.73-0.21,1.08c-0.33,0.95-0.19,1.89-0.01,2.84
							c0.12,0.65,0.09,1.14-0.11,1.66c-0.16,0.42-0.31,0.86-0.45,1.29c-0.24,0.72-0.48,1.47-0.84,2.17
							c-0.02,0.05-0.05,0.09-0.08,0.14L450.42,712.32z M452.01,702.35c-0.01,0.01-0.03,0.03-0.05,0.04
							c-0.02,0.02-0.04,0.03-0.05,0.05c-0.09,0.07-0.18,0.14-0.27,0.2c-0.17,0.13-0.35,0.26-0.51,0.4
							c-0.06,0.06-0.13,0.11-0.19,0.16c-0.29,0.24-0.55,0.46-0.61,0.8c-0.03,0.15-0.05,0.3-0.08,0.45c-0.26,1.43-0.5,2.78,0.1,4.16
							c0.24,0.55,0.29,1.14,0.15,1.77c-0.09,0.39-0.12,0.81-0.15,1.22c-0.01,0.1-0.02,0.2-0.02,0.3c0.34-0.68,0.59-1.42,0.82-2.13
							c0.14-0.43,0.29-0.87,0.45-1.3v0c0.18-0.47,0.21-0.92,0.1-1.51c-0.19-0.99-0.33-1.97,0.02-2.98c0.11-0.32,0.16-0.66,0.2-1.02
							c0.02-0.16,0.04-0.32,0.07-0.47l0.01-0.04C452.01,702.42,452.01,702.37,452.01,702.35z M480.56,701.99
							c-0.03,0-0.06,0-0.09-0.01l-0.18-0.02l0.07-0.17c0.13-0.3,0.25-0.59,0.38-0.88c0.29-0.7,0.57-1.35,0.89-2.01l0,0
							c0.23-0.48,0.63-0.83,1.02-1.16c0.13-0.11,0.26-0.22,0.38-0.33c0.42-0.4,0.6-0.74,0.61-1.14c0.01-0.96,0.6-1.59,1.25-2.19
							c0.05-0.05,0.09-0.1,0.14-0.17c0.12-0.16,0.26-0.34,0.53-0.37l0.14-0.02l0.01,0.14c0.01,0.08,0.02,0.15,0.03,0.23
							c0.02,0.17,0.04,0.34,0.05,0.52c0.01,0.09,0.01,0.19,0.01,0.28c0.53-1.83,1.6-3.32,2.69-4.66l0.35-0.43l-0.11,0.55
							c-0.11,0.56-0.22,1.09-0.18,1.68c0.5-1,0.76-1.9,0.81-2.81c0.03-0.46,0.08-0.93,0.12-1.38c0.03-0.24,0.05-0.48,0.07-0.71
							c0.02-0.19,0.06-0.4,0.23-0.54c0.25-0.23,0.52-0.45,0.79-0.68c0.12-0.1,0.23-0.2,0.36-0.3l0.24-0.21l-0.02,0.32
							c-0.03,0.47-0.22,0.79-0.38,1.08l-0.01,0.02c-0.21,0.36-0.21,0.75-0.21,1.16c0,0.08,0,0.15,0,0.23c0,0.08,0.02,0.09,0.04,0.1
							c0.15,0.04,0.84-0.32,0.88-0.47l0.05-0.27c0.11-0.58,0.22-1.19,0.29-1.78c0.04-0.37,0.16-0.73,0.7-0.85
							c0.55-0.12,0.92-0.51,1.31-0.92c0.08-0.08,0.16-0.17,0.24-0.25c0.03-0.03,0.06-0.07,0.09-0.12c0.09-0.14,0.26-0.39,0.54-0.24
							c0.22,0.12,0.1,0.36,0.04,0.49c-0.03,0.06-0.06,0.11-0.06,0.15c-0.09,0.48-0.2,0.95-0.3,1.42l-0.05,0.23
							c-0.06,0.26,0,0.52,0.18,0.81c0.06,0.1,0.1,0.2,0.15,0.29c0.04,0.08,0.07,0.16,0.11,0.23c0.34,0.57,0.29,1.09-0.17,1.69
							c-0.46,0.6-0.69,1.21-0.68,1.86c0,0.4-0.29,0.7-0.52,0.94c-0.58,0.61-0.66,1.29-0.27,2.19c0.12,0.27,0.21,0.55,0.3,0.83
							c0.07,0.21,0.14,0.43,0.22,0.63c0.15,0.39,0.07,0.71-0.25,1c-0.18,0.16-0.36,0.32-0.54,0.48c-0.36,0.32-0.72,0.65-1.09,0.96
							c-0.29,0.24-0.65,0.18-0.92,0.13c-0.05-0.01-0.1-0.02-0.15-0.03c-0.6-0.08-0.96,0.22-1.37,0.57c-0.05,0.04-0.1,0.09-0.15,0.13
							c-0.3,0.24-0.58,0.5-0.85,0.74c-0.17,0.16-0.37,0.17-0.56,0.17c-0.2,0-0.4,0-0.6,0c-0.37,0-0.75,0-1.13-0.01
							c-0.46-0.01-0.79,0.13-1.06,0.44c-0.04,0.05-0.08,0.08-0.12,0.12c-0.05,0.04-0.09,0.09-0.12,0.13
							c-0.51,0.83-1.2,1.24-2.17,1.29c-0.37,0.02-0.74,0.25-1.1,0.48c-0.11,0.07-0.23,0.15-0.35,0.22
							c-0.02,0.01-0.04,0.03-0.07,0.04C480.91,701.91,480.77,701.99,480.56,701.99z M481.87,699.02c-0.31,0.65-0.59,1.31-0.88,2
							c-0.09,0.22-0.19,0.45-0.29,0.67c0.06-0.02,0.11-0.05,0.17-0.09c0.03-0.02,0.05-0.03,0.08-0.05c0.11-0.06,0.22-0.14,0.34-0.21
							c0.38-0.25,0.78-0.5,1.23-0.53c0.89-0.05,1.49-0.4,1.96-1.16c0.05-0.08,0.11-0.13,0.17-0.19c0.03-0.03,0.07-0.06,0.09-0.09
							c0.32-0.38,0.74-0.56,1.28-0.54c0.37,0.01,0.75,0.01,1.12,0.01c0.2,0,0.4,0,0.61,0c0.16,0,0.27-0.01,0.37-0.1
							c0.27-0.24,0.56-0.5,0.86-0.75c0.05-0.04,0.1-0.08,0.15-0.12c0.43-0.36,0.87-0.73,1.59-0.63c0.05,0.01,0.11,0.02,0.16,0.03
							c0.25,0.05,0.5,0.09,0.69-0.07c0.37-0.31,0.73-0.64,1.09-0.96c0.18-0.16,0.36-0.32,0.54-0.48c0.23-0.21,0.29-0.41,0.18-0.69
							c-0.08-0.21-0.16-0.43-0.23-0.65c-0.09-0.27-0.18-0.54-0.3-0.8c-0.44-1-0.34-1.79,0.31-2.48c0.21-0.23,0.45-0.47,0.45-0.76
							c0-0.72,0.24-1.38,0.74-2.03c0.39-0.5,0.43-0.91,0.15-1.38c-0.05-0.08-0.09-0.17-0.13-0.26c-0.04-0.09-0.08-0.18-0.13-0.26
							c-0.22-0.35-0.29-0.68-0.21-1.01l0.05-0.23c0.1-0.46,0.21-0.94,0.3-1.41c0.01-0.08,0.05-0.15,0.09-0.22
							c0.02-0.04,0.05-0.1,0.06-0.13c-0.03-0.01-0.06,0.01-0.16,0.16c-0.04,0.06-0.08,0.11-0.12,0.16
							c-0.08,0.08-0.16,0.16-0.23,0.24c-0.4,0.42-0.81,0.86-1.45,1c-0.32,0.07-0.44,0.22-0.49,0.61c-0.07,0.6-0.19,1.21-0.3,1.8
							l-0.05,0.27c-0.04,0.21-0.36,0.41-0.56,0.51c-0.19,0.1-0.52,0.25-0.7,0.16c-0.09-0.04-0.2-0.14-0.2-0.35c0-0.08,0-0.15,0-0.23
							c0-0.43,0-0.87,0.25-1.3l0.01-0.02c0.11-0.19,0.22-0.37,0.29-0.6c-0.02,0.02-0.05,0.04-0.07,0.06
							c-0.28,0.23-0.54,0.45-0.79,0.68c-0.1,0.09-0.12,0.23-0.14,0.36c-0.02,0.24-0.05,0.48-0.07,0.72
							c-0.05,0.45-0.1,0.91-0.12,1.36c-0.06,1.07-0.39,2.12-1.04,3.31l-0.18,0.33l-0.07-0.37c-0.11-0.6-0.07-1.14,0.02-1.66
							c-1.08,1.37-2.07,2.9-2.45,4.79l-0.08,0.41l-0.18-0.38c-0.12-0.27-0.12-0.52-0.12-0.74c0-0.09,0-0.18-0.01-0.27
							c-0.01-0.17-0.03-0.34-0.05-0.51c0-0.02,0-0.04-0.01-0.06c-0.08,0.05-0.14,0.13-0.21,0.22c-0.05,0.07-0.11,0.14-0.17,0.2
							c-0.63,0.59-1.15,1.14-1.16,2c-0.01,0.61-0.36,1.01-0.7,1.33c-0.12,0.12-0.26,0.23-0.39,0.34
							C482.44,698.28,482.08,698.59,481.87,699.02L481.87,699.02z"/>
					</g>
				</g>
				<g>
					<path className="st76" d="M533.27,689.84c0.02-0.21,0.03-0.42,0-0.63c-0.11-0.88-0.33-1.72-0.58-2.57c-0.03-0.11-0.12-0.25,0-0.34
						c0.14-0.09,0.2,0.08,0.29,0.15c0.57,0.45,1.15,0.88,1.81,1.18c1.13,0.51,1.96,1.31,2.27,2.55c0.09,0.35,0.26,0.3,0.43,0.08
						c0.24-0.31,0.43-0.23,0.67,0c0.26,0.25,0.54,0.48,0.83,0.7c0.95,0.72,1.56,1.71,2.18,2.69c-0.06,0.04-0.09,0.06-0.11,0.07
						c-0.04,0.01-0.09,0.01-0.14,0c-0.89-0.2-1.69,0.05-2.39,0.58c-0.28,0.21-0.48,0.19-0.77,0.02c-0.7-0.41-1.38-0.99-2.3-0.63
						c-0.21,0.08-0.44,0.13-0.64,0.23c-0.26,0.12-0.42,0.06-0.54-0.19C533.65,692.51,533.11,691.26,533.27,689.84z M426.58,717.54
						c0.51-0.84,0.97-1.7,0.99-2.74c0.01-0.51,0.17-1.01,0.27-1.52c0.02-0.1,0.02-0.23,0.16-0.24c0.11-0.01,0.13,0.11,0.18,0.18
						c0.18,0.28,0.38,0.55,0.53,0.85c0.21,0.4,0.52,0.8,0.44,1.27c-0.29,1.56-0.47,3.15-1.65,4.39c-0.4,0.42-0.63,0.94-0.72,1.54
						c-0.08,0.59-0.28,1.16-0.43,1.74c-0.06-0.01-0.11-0.02-0.17-0.04c-0.01-0.07-0.03-0.15-0.03-0.22
						c-0.14-1.23,0.01-2.45-0.02-3.68C426.12,718.48,426.3,718.02,426.58,717.54z M494.84,732.9c0.22-0.04,0.39-0.15,0.54-0.31
						c0.79-0.84,1.59-1.66,2.38-2.5c0.14-0.15,0.29-0.25,0.49-0.3c0.57-0.15,1.14-0.3,1.71-0.46c0.4-0.11,0.76-0.27,1.06-0.59
						c0.95-0.99,1.89-2,3.05-2.8c0.06,0.14,0,0.23-0.06,0.32c-0.95,1.44-1.73,2.99-2.83,4.34c-0.14,0.17-0.26,0.26-0.44,0.1
						c-0.29-0.26-0.44-0.1-0.59,0.17c-0.19,0.35-0.4,0.68-0.59,1.03c-0.14,0.25-0.31,0.38-0.62,0.38c-0.44,0-0.8,0.18-1.16,0.46
						c-0.45,0.35-0.87,0.75-1.41,0.99c-0.44,0.2-0.92,0.16-1.39,0.19c-0.6,0.03-1.17,0.07-1.63,0.55c-0.49,0.53-1.12,0.8-1.84,0.89
						c-0.6,0.07-1.09,0.34-1.39,0.9c-0.05,0.1-0.1,0.22-0.28,0.18c0-0.34,0.12-0.65,0.16-0.98c0.05-0.36,0.25-0.49,0.58-0.58
						c0.67-0.18,1.33-0.4,1.81-0.97c0.42-0.51,0.98-0.79,1.65-0.84C494.34,733.03,494.59,732.94,494.84,732.9z M548.98,697.71
						c-0.63-0.57-1.26-1.13-2.04-1.51c-0.53-0.26-1.04-0.59-1.55-0.88c0.06-0.12,0.14-0.11,0.21-0.1c0.66,0.09,1.34,0.12,1.84,0.66
						c0.06,0.07,0.22,0.19,0.32,0.08c0.12-0.14-0.04-0.25-0.12-0.34c-0.14-0.15-0.29-0.28-0.5-0.49c0.44,0,0.69,0.21,0.96,0.35
						c0.98,0.5,1.73,1.32,2.57,2.01c0.54,0.44,1.03,0.97,1.63,1.3c0.83,0.45,1.76,0.74,2.63,1.13c1.05,0.47,2.22,0.74,2.79,1.94
						c0.3,0.63,0.78,1.18,1.22,1.83c-0.23,0.01-0.29-0.09-0.37-0.15c-0.92-0.71-1.83-1.46-2.78-2.13c-0.65-0.46-1.18-1.1-2.01-1.28
						c-0.67-0.14-1.32-0.32-1.98-0.5c-0.61-0.16-1.17-0.38-1.63-0.85C549.79,698.4,549.38,698.07,548.98,697.71z M566.4,706.16
						c0.61-0.11,0.54-0.61,0.65-1.01c0.03-0.11,0-0.28,0.13-0.32c0.17-0.06,0.22,0.12,0.3,0.22c0.49,0.57,0.94,1.2,1.68,1.49
						c0.43,0.16,0.65,0.46,0.75,0.89c0.16,0.71,0.56,1.17,1.23,1.52c0.45,0.23,0.76,0.72,0.99,1.22c-0.75-0.56-1.63-0.87-2.4-1.38
						c-1.12-0.75-2.08-1.72-3.26-2.39C566.38,706.34,566.25,706.19,566.4,706.16z"/>
					<path className="st8" d="M489.95,736.57c-0.03,0-0.06,0-0.1-0.01l-0.1-0.02l0-0.11c0-0.22,0.05-0.43,0.09-0.63
						c0.03-0.12,0.06-0.24,0.07-0.36c0.06-0.47,0.37-0.61,0.68-0.7c0.66-0.18,1.29-0.38,1.74-0.92c0.45-0.54,1.04-0.84,1.75-0.89
						c0.14-0.01,0.28-0.04,0.43-0.08c0.1-0.03,0.21-0.05,0.32-0.07l0.02,0.14l-0.02-0.14c0.18-0.03,0.32-0.11,0.47-0.26
						c0.32-0.34,0.64-0.68,0.97-1.02c0.46-0.49,0.94-0.99,1.41-1.49c0.17-0.18,0.35-0.29,0.56-0.34c0.47-0.12,1.09-0.28,1.71-0.46
						c0.45-0.13,0.75-0.3,1-0.55l0.14-0.15c0.89-0.93,1.82-1.9,2.93-2.67l0.14-0.09l0.07,0.15c0.09,0.21-0.01,0.36-0.07,0.45
						c-0.33,0.5-0.64,1.01-0.94,1.52c-0.58,0.96-1.17,1.95-1.9,2.83c-0.09,0.11-0.2,0.22-0.34,0.23c-0.1,0.01-0.2-0.03-0.3-0.12
						c-0.08-0.07-0.14-0.1-0.18-0.1c-0.08,0.01-0.16,0.17-0.19,0.23c-0.1,0.18-0.2,0.35-0.3,0.52c-0.1,0.16-0.19,0.33-0.29,0.5
						c-0.17,0.31-0.4,0.45-0.74,0.45c-0.36,0-0.69,0.13-1.07,0.43c-0.11,0.08-0.21,0.17-0.31,0.25c-0.34,0.28-0.7,0.57-1.12,0.75
						c-0.38,0.17-0.78,0.18-1.16,0.19c-0.09,0-0.18,0.01-0.27,0.01c-0.57,0.03-1.11,0.06-1.53,0.51c-0.5,0.53-1.14,0.84-1.93,0.93
						c-0.6,0.07-1.03,0.35-1.28,0.83l-0.01,0.02C490.23,736.4,490.15,736.57,489.95,736.57z M503.85,726.28
						c-0.96,0.72-1.78,1.58-2.58,2.41l-0.14,0.15c-0.28,0.29-0.62,0.48-1.12,0.62c-0.62,0.18-1.24,0.34-1.72,0.46
						c-0.16,0.04-0.29,0.12-0.43,0.26c-0.47,0.5-0.95,1-1.41,1.49c-0.32,0.34-0.64,0.68-0.97,1.01c-0.19,0.2-0.38,0.31-0.62,0.35v0
						c-0.1,0.02-0.19,0.04-0.3,0.06c-0.15,0.04-0.31,0.08-0.47,0.09c-0.63,0.05-1.15,0.31-1.55,0.79c-0.5,0.6-1.18,0.82-1.88,1.01
						c-0.33,0.09-0.45,0.2-0.48,0.47c-0.02,0.13-0.05,0.26-0.08,0.39c-0.03,0.12-0.05,0.23-0.07,0.34c0,0,0,0,0,0l0.01-0.02
						c0.29-0.56,0.8-0.89,1.49-0.97c0.73-0.08,1.3-0.36,1.76-0.85c0.5-0.53,1.12-0.56,1.72-0.59c0.09,0,0.19-0.01,0.28-0.01
						c0.37-0.01,0.73-0.02,1.06-0.17c0.39-0.17,0.73-0.45,1.06-0.72c0.11-0.09,0.21-0.17,0.32-0.25c0.44-0.34,0.82-0.49,1.24-0.49
						c0.24,0,0.37-0.09,0.5-0.31c0.09-0.17,0.19-0.34,0.29-0.51c0.1-0.17,0.2-0.34,0.3-0.52c0.09-0.17,0.2-0.33,0.39-0.36
						c0.13-0.02,0.27,0.03,0.41,0.16c0.04,0.03,0.07,0.05,0.09,0.05c0.03,0,0.08-0.05,0.15-0.13c0.71-0.87,1.3-1.85,1.87-2.8
						C503.25,727.24,503.54,726.75,503.85,726.28z M426.45,723.16l-0.39-0.09l-0.03-0.16c-0.01-0.05-0.02-0.1-0.02-0.16
						c-0.09-0.78-0.06-1.57-0.04-2.33c0.02-0.45,0.03-0.91,0.02-1.36c-0.01-0.56,0.13-1.04,0.47-1.6v0c0.5-0.83,0.95-1.66,0.97-2.67
						c0.01-0.37,0.09-0.73,0.17-1.09c0.03-0.15,0.07-0.31,0.1-0.46l0.01-0.03c0.02-0.09,0.05-0.31,0.29-0.32
						c0.17-0.01,0.24,0.13,0.28,0.2c0.01,0.01,0.02,0.03,0.02,0.04c0.06,0.09,0.12,0.19,0.18,0.28c0.12,0.19,0.25,0.38,0.36,0.58
						c0.04,0.07,0.08,0.14,0.12,0.21c0.19,0.33,0.41,0.71,0.33,1.15c-0.03,0.15-0.05,0.3-0.08,0.45c-0.25,1.41-0.51,2.86-1.6,4.01
						c-0.37,0.39-0.6,0.88-0.68,1.46c-0.06,0.42-0.18,0.83-0.29,1.23c-0.05,0.17-0.1,0.35-0.14,0.53L426.45,723.16z M426.7,717.61
						c-0.31,0.52-0.44,0.95-0.43,1.46c0.01,0.46-0.01,0.93-0.02,1.38c-0.03,0.75-0.05,1.53,0.04,2.29c0.03-0.1,0.05-0.2,0.08-0.29
						c0.11-0.39,0.23-0.79,0.28-1.19c0.09-0.64,0.34-1.18,0.75-1.61c1.04-1.09,1.28-2.44,1.53-3.87c0.03-0.15,0.05-0.3,0.08-0.45
						c0.06-0.33-0.11-0.64-0.3-0.96c-0.04-0.07-0.08-0.15-0.12-0.22c-0.1-0.19-0.22-0.38-0.34-0.56c-0.06-0.09-0.12-0.19-0.18-0.28
						c-0.01-0.02-0.02-0.04-0.04-0.06c-0.01-0.02-0.02-0.04-0.03-0.05c0,0,0,0,0,0c0,0.01-0.01,0.06-0.02,0.09l-0.01,0.04
						c-0.03,0.16-0.07,0.32-0.1,0.47c-0.08,0.36-0.16,0.69-0.16,1.03C427.69,715.88,427.22,716.74,426.7,717.61L426.7,717.61z
						 M572.48,710.6l-0.44-0.33c-0.4-0.3-0.86-0.54-1.3-0.76c-0.36-0.19-0.74-0.38-1.09-0.61c-0.48-0.32-0.94-0.69-1.38-1.04
						c-0.59-0.47-1.2-0.96-1.87-1.34c-0.07-0.04-0.23-0.17-0.2-0.32c0.01-0.03,0.03-0.13,0.18-0.16l0,0
						c0.37-0.06,0.42-0.27,0.48-0.63c0.02-0.09,0.03-0.19,0.06-0.28c0.01-0.02,0.01-0.05,0.01-0.08c0.01-0.1,0.03-0.27,0.2-0.33
						c0.23-0.08,0.35,0.11,0.41,0.19c0.02,0.02,0.03,0.05,0.05,0.06c0.07,0.08,0.14,0.16,0.2,0.24c0.42,0.5,0.82,0.98,1.42,1.21
						c0.45,0.17,0.72,0.5,0.83,0.99c0.14,0.65,0.5,1.09,1.16,1.43c0.4,0.21,0.75,0.63,1.05,1.28L572.48,710.6z M566.54,706.27
						c0.69,0.39,1.3,0.89,1.9,1.37c0.44,0.35,0.89,0.72,1.36,1.03c0.34,0.22,0.71,0.41,1.06,0.6c0.29,0.15,0.59,0.3,0.87,0.47
						c-0.21-0.33-0.43-0.55-0.67-0.67c-0.73-0.37-1.14-0.89-1.3-1.61c-0.09-0.4-0.29-0.65-0.66-0.79c-0.67-0.26-1.11-0.78-1.53-1.29
						c-0.07-0.08-0.13-0.16-0.2-0.24c-0.03-0.03-0.05-0.06-0.07-0.09c-0.02-0.03-0.05-0.08-0.07-0.09l0,0c0,0-0.01,0-0.02,0
						c-0.01,0.01-0.01,0.07-0.02,0.1c0,0.04-0.01,0.09-0.02,0.13c-0.02,0.08-0.04,0.16-0.05,0.25
						C567.07,705.75,567,706.14,566.54,706.27z M558.91,703.83c-0.23,0-0.33-0.09-0.41-0.15c-0.01-0.01-0.02-0.02-0.03-0.03
						c-0.28-0.22-0.56-0.43-0.83-0.65c-0.63-0.5-1.28-1.01-1.94-1.47c-0.19-0.13-0.37-0.28-0.54-0.43
						c-0.44-0.37-0.85-0.71-1.42-0.83c-0.6-0.13-1.2-0.29-1.78-0.44l-0.2-0.05c-0.55-0.15-1.19-0.37-1.69-0.89
						c-0.26-0.26-0.54-0.51-0.82-0.75c-0.12-0.1-0.24-0.21-0.35-0.31v0c-0.58-0.52-1.23-1.1-2.01-1.49
						c-0.37-0.18-0.74-0.4-1.09-0.61c-0.16-0.09-0.31-0.19-0.47-0.28l-0.11-0.06l0.06-0.11c0.1-0.21,0.28-0.19,0.35-0.18
						c0.08,0.01,0.16,0.02,0.23,0.03c0.6,0.08,1.22,0.16,1.7,0.68c0.05,0.05,0.1,0.08,0.12,0.08c0-0.01,0.01-0.01,0.01-0.01
						c-0.01-0.01-0.06-0.06-0.08-0.09c-0.02-0.02-0.03-0.03-0.05-0.05c-0.09-0.1-0.19-0.19-0.31-0.3c-0.06-0.05-0.12-0.11-0.19-0.18
						l-0.24-0.23l0.34,0c0.01,0,0.01,0,0.02,0c0.38,0,0.63,0.15,0.85,0.27c0.05,0.03,0.1,0.06,0.16,0.09
						c0.72,0.37,1.32,0.9,1.9,1.42c0.23,0.2,0.46,0.41,0.7,0.61c0.17,0.14,0.33,0.28,0.49,0.42c0.35,0.32,0.72,0.64,1.12,0.86
						c0.51,0.28,1.07,0.5,1.62,0.71c0.33,0.13,0.67,0.26,1,0.41c0.18,0.08,0.36,0.15,0.54,0.22c0.9,0.36,1.82,0.74,2.32,1.78
						c0.2,0.42,0.48,0.81,0.78,1.22c0.14,0.19,0.29,0.39,0.43,0.6l0.14,0.21l-0.25,0.01C558.93,703.83,558.92,703.83,558.91,703.83z
						 M549.07,697.61c0.12,0.1,0.23,0.21,0.35,0.31c0.28,0.24,0.57,0.5,0.83,0.77c0.46,0.47,1.05,0.68,1.57,0.81l0.2,0.05
						c0.58,0.15,1.18,0.31,1.77,0.44c0.63,0.14,1.09,0.52,1.53,0.89c0.17,0.14,0.35,0.29,0.53,0.42c0.67,0.47,1.32,0.99,1.95,1.48
						c0.23,0.18,0.47,0.37,0.7,0.55c-0.04-0.05-0.08-0.11-0.12-0.16c-0.31-0.42-0.6-0.82-0.81-1.26c-0.45-0.95-1.29-1.29-2.18-1.65
						c-0.18-0.07-0.37-0.15-0.55-0.23c-0.32-0.14-0.66-0.28-0.99-0.41c-0.55-0.22-1.12-0.44-1.65-0.73
						c-0.43-0.24-0.81-0.57-1.17-0.9c-0.16-0.14-0.32-0.28-0.48-0.42c-0.24-0.2-0.48-0.41-0.71-0.61c-0.57-0.5-1.15-1.03-1.84-1.38
						c-0.06-0.03-0.11-0.06-0.17-0.09c-0.09-0.05-0.19-0.11-0.29-0.15c0.06,0.06,0.12,0.11,0.17,0.17c0.01,0.01,0.03,0.03,0.04,0.04
						c0.08,0.07,0.28,0.27,0.09,0.48c-0.05,0.06-0.12,0.09-0.2,0.09c0,0,0,0,0,0c-0.15,0-0.28-0.13-0.32-0.17
						c-0.41-0.45-0.95-0.52-1.53-0.59c-0.02,0-0.03,0-0.05-0.01c0.06,0.03,0.11,0.07,0.17,0.1c0.35,0.21,0.7,0.42,1.07,0.6
						C547.82,696.48,548.49,697.08,549.07,697.61z M538.14,694.6c-0.16,0-0.3-0.06-0.46-0.15c-0.1-0.06-0.2-0.12-0.3-0.18
						c-0.59-0.37-1.15-0.72-1.88-0.43c-0.09,0.04-0.18,0.06-0.27,0.09c-0.13,0.04-0.25,0.08-0.36,0.13
						c-0.32,0.15-0.56,0.07-0.73-0.26c-0.6-1.2-1.18-2.49-1.01-3.98v0c0.03-0.23,0.03-0.42,0-0.6c-0.12-0.92-0.36-1.8-0.58-2.55
						c-0.01-0.02-0.01-0.04-0.02-0.06c-0.04-0.1-0.11-0.3,0.07-0.43c0.21-0.14,0.35,0.04,0.4,0.11c0.02,0.02,0.03,0.04,0.05,0.05
						c0.51,0.4,1.1,0.86,1.79,1.17c1.28,0.58,2.05,1.44,2.35,2.65c0.03,0.12,0.06,0.15,0.07,0.15c0,0,0.05-0.02,0.12-0.12
						c0.12-0.16,0.25-0.24,0.39-0.26c0.19-0.01,0.35,0.11,0.48,0.24c0.24,0.23,0.5,0.45,0.82,0.69c0.94,0.71,1.56,1.69,2.15,2.64
						l0.13,0.21l-0.12,0.07c-0.02,0.01-0.04,0.02-0.05,0.03c-0.03,0.02-0.07,0.05-0.11,0.05c-0.07,0.01-0.13,0.01-0.19,0
						c-0.8-0.18-1.55,0-2.28,0.56C538.44,694.55,538.29,694.6,538.14,694.6z M536.03,693.45c0.56,0,1.03,0.29,1.49,0.58
						c0.1,0.06,0.2,0.12,0.29,0.18c0.27,0.16,0.4,0.16,0.62-0.01c0.79-0.59,1.64-0.8,2.51-0.6c0,0,0.01,0,0.02,0
						c-0.6-0.95-1.17-1.85-2.06-2.52c-0.33-0.25-0.6-0.48-0.85-0.71c-0.12-0.12-0.21-0.17-0.27-0.16c-0.06,0-0.12,0.05-0.19,0.15
						c-0.13,0.17-0.27,0.25-0.4,0.22c-0.13-0.03-0.22-0.15-0.27-0.35c-0.28-1.12-1-1.92-2.19-2.46c-0.71-0.32-1.32-0.79-1.84-1.2
						c-0.04-0.03-0.06-0.06-0.09-0.1c-0.01-0.01-0.02-0.03-0.04-0.04c0,0.02,0.02,0.07,0.03,0.1c0.01,0.03,0.02,0.05,0.03,0.08
						c0.22,0.75,0.47,1.65,0.59,2.59c0.03,0.21,0.03,0.42,0,0.67l0,0c-0.16,1.42,0.4,2.67,0.98,3.83c0.1,0.2,0.17,0.22,0.36,0.13
						c0.13-0.06,0.26-0.1,0.4-0.15c0.09-0.03,0.17-0.05,0.25-0.09C535.63,693.49,535.84,693.45,536.03,693.45z M532.77,686.41
						C532.77,686.41,532.77,686.41,532.77,686.41L532.77,686.41z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path className="st24" d="M415.94,540.24c-8.06,1.09-10.85-10.65-10.02-16.35c0.08-0.53,0.18-1.07,0.47-1.53
							c0.42-0.66,1.15-1.03,1.78-1.5c3.62-2.73,2.97-20.95,10.67-13.8c3.36,3.12,5.3,7.41,8.35,10.62c0.19-0.98-0.31-4.65,0.28-5.37
							c1.74-2.14,12.17-11.99,10.46-2.01c-1.13,6.56,6.63-6.66,10.16-0.97c1.94,3.13-0.03,12.79,1.93,14.51
							c0.66,0.57-1.67,11.39-1.54,11.37C448.46,535.23,416.74,540.12,415.94,540.24z"/>
						<path className="st23" d="M414.9,540.51c-1.92,0-3.63-0.74-5.09-2.22c-3.33-3.36-4.71-10.1-4.08-14.42
							c0.08-0.52,0.18-1.11,0.5-1.6c0.33-0.52,0.84-0.87,1.33-1.21c0.17-0.11,0.33-0.23,0.49-0.35c1.24-0.93,1.97-3.82,2.75-6.88
							c0.98-3.86,1.99-7.86,4.24-8.52c1.13-0.33,2.41,0.19,3.93,1.61c1.85,1.72,3.29,3.8,4.67,5.82c1.06,1.53,2.14,3.11,3.39,4.49
							c0.01-0.39-0.01-0.94-0.02-1.5c-0.06-1.78-0.08-3.07,0.3-3.53c1.14-1.39,7.33-7.49,9.91-6.32c1.02,0.47,1.33,1.97,0.9,4.47
							c-0.22,1.31-0.04,1.57,0.05,1.62c0.31,0.18,1.36-0.67,2.3-1.43c1.73-1.4,3.88-3.14,5.76-2.82c0.81,0.14,1.49,0.65,2.03,1.52
							c1,1.61,0.99,4.78,0.99,7.86c-0.01,2.85-0.01,5.8,0.91,6.61c0.41,0.36,0.13,2.73-1.11,9.38c-0.19,1.02-0.37,1.99-0.37,2.12
							l-0.19,0.02l0.03,0.2c0,0,0,0,0,0l-0.1,0.02c-0.84,0.13-6.95,1.07-20.17,3.11l-12.26,1.89
							C415.61,540.49,415.25,540.51,414.9,540.51z M415.7,505.61c-0.19,0-0.38,0.03-0.56,0.08c-2.03,0.6-3.01,4.48-3.97,8.24
							c-0.79,3.13-1.54,6.08-2.89,7.1c-0.16,0.12-0.33,0.24-0.51,0.36c-0.48,0.33-0.94,0.64-1.22,1.09
							c-0.27,0.43-0.37,0.95-0.44,1.45c-0.61,4.23,0.73,10.81,3.97,14.09c1.64,1.65,3.59,2.34,5.83,2.04l12.25-1.89
							c8.58-1.32,18.75-2.89,20.12-3.1c0.03-0.27,0.14-0.83,0.36-2.04c0.47-2.54,1.59-8.5,1.23-9.02c-1.05-0.91-1.04-3.82-1.04-6.9
							c0.01-3.02,0.01-6.14-0.93-7.65c-0.48-0.78-1.06-1.22-1.76-1.34c-1.71-0.29-3.78,1.39-5.45,2.73
							c-1.28,1.03-2.2,1.78-2.74,1.46c-0.37-0.22-0.44-0.82-0.23-2.03c0.39-2.3,0.17-3.66-0.67-4.05c-2.2-1.01-8.16,4.64-9.44,6.21
							c-0.31,0.39-0.26,2.05-0.21,3.27c0.03,0.87,0.06,1.62-0.02,2.01l-0.07,0.37l-0.26-0.28c-1.4-1.47-2.58-3.19-3.73-4.86
							c-1.38-2-2.8-4.07-4.62-5.76C417.54,506.13,416.56,505.61,415.7,505.61z"/>
					</g>
					<g>
						<path className="st24" d="M488.21,505.99l5.48-4.93c2.16-1.95,6.53,1,9.15-0.28c4.87-2.39,12.24,0.17,16.41-7.78
							c0.76-1.44,13-2.9,15.44-2.76s2.44,0.14,2.44,0.14l4.59-2.85c4.29-2.89,0.63-1.85,4.29-2.89s7.31-3.11,8.88-3.12
							c1.57-0.01-1.57-1.06,1.57-0.01s6.27,0.49,6.27,0.49c5.22-1.01,1.57-0.49,5.22-1.01s7.91-1.63,7.87,1.01
							c-0.04,2.64,0,14.44,0,14.44l-15.71,23.5L488.21,505.99z"/>
						<path className="st23" d="M560.19,520.15l-0.13-0.02l-72.28-14.01l5.77-5.2c1.23-1.11,3.11-0.73,4.93-0.37
							c1.53,0.3,3.11,0.62,4.26,0.05c1.62-0.79,3.46-1.05,5.42-1.31c4-0.55,8.14-1.12,10.91-6.39c0.84-1.6,13.27-3,15.63-2.86
							l2.38,0.14l4.54-2.82c2.08-1.4,2.23-1.85,2.3-2.03c0.11-0.33,0.11-0.33,2.04-0.88c1.68-0.48,3.36-1.18,4.84-1.79
							c1.79-0.74,3.21-1.33,4.09-1.34c0.06,0,0.11,0,0.15-0.01c-0.02-0.02-0.04-0.03-0.06-0.04c-0.09-0.06-0.23-0.16-0.15-0.32
							c0.09-0.18,0.09-0.18,1.7,0.36c3.05,1.02,6.14,0.49,6.17,0.48c2.6-0.5,2.99-0.63,3.17-0.69c0.21-0.07,0.21-0.07,2.05-0.33
							c0.5-0.07,1.01-0.15,1.52-0.24c2.56-0.41,4.98-0.8,6.04,0.12c0.36,0.31,0.54,0.76,0.53,1.33c-0.04,2.61,0,14.31,0,14.43v0.06
							l-0.03,0.05L560.19,520.15z M488.64,505.87l71.38,13.84l15.6-23.34c0-0.91-0.04-11.86,0-14.38c0.01-0.46-0.12-0.79-0.4-1.03
							c-0.92-0.8-3.36-0.4-5.72-0.02c-0.51,0.08-1.03,0.17-1.53,0.24c-1.81,0.26-1.81,0.26-1.99,0.32c-0.21,0.07-0.6,0.19-3.22,0.7
							c-0.13,0.02-3.22,0.55-6.37-0.5c-0.35-0.12-0.62-0.21-0.84-0.28c0,0.02-0.01,0.03-0.01,0.05c-0.07,0.22-0.35,0.25-0.65,0.25
							c-0.8,0.01-2.26,0.61-3.94,1.31c-1.49,0.62-3.18,1.32-4.89,1.81c-1.73,0.49-1.73,0.5-1.77,0.63
							c-0.09,0.26-0.26,0.75-2.46,2.24l-4.65,2.89l-2.5-0.15c-2.79-0.16-14.6,1.4-15.25,2.65c-2.86,5.45-7.29,6.06-11.2,6.59
							c-1.92,0.26-3.74,0.51-5.3,1.28c-1.28,0.63-2.92,0.3-4.51-0.02c-1.8-0.36-3.5-0.7-4.59,0.28L488.64,505.87z"/>
					</g>
					<g>
						<path className="st27" d="M531.53,506.35c-0.17,1-0.45,1.96,0.23,2.97c0.39,0.58,0.04,1.42-0.6,1.64
							c-1.59,0.54-3.15,1.63-4.95,0.46c-1-0.65-1.95-0.17-3.05,0.07c-2.24,0.49-4.45,0.53-5.89,2.82c-0.39,0.62-1.68-0.29-2.59-0.26
							c0-4.42,0-8.85,0-13.27c2.46-0.1,2.46-0.1,2.09,2.19c-0.45,2.81-0.04,3.39,2.48,3.58c2.53-0.01,5.07-0.02,7.6-0.02
							C528.42,506.83,529.95,505.95,531.53,506.35z"/>
					</g>
					<g>
						<path className="st80" d="M513.84,514.46c-1.69,0-3.39-1.11-5.07,0.5c-0.44,0.42-1.85-0.72-2.94-0.51
							c0.15-4.73-0.37-9.44-0.54-14.16c0.88-0.41,1.65-0.53,2.47,0.28c1.61,1.6,3.6,1.86,5.74,1.32
							C513.52,506.08,513.42,510.28,513.84,514.46z"/>
						<path className="st23" d="M508.5,515.26c-0.28,0-0.62-0.13-1-0.27c-0.53-0.2-1.13-0.43-1.63-0.34l-0.24,0.05l0.01-0.24
							c0.1-3.11-0.1-6.27-0.28-9.32c-0.1-1.58-0.2-3.22-0.25-4.83l0-0.13l0.12-0.05c0.84-0.39,1.74-0.62,2.69,0.32
							c1.44,1.44,3.26,1.85,5.55,1.27l0.24-0.06l0,0.25c0,0.75,0,1.49,0,2.24c0,3.38-0.01,6.88,0.34,10.31l0.02,0.22l-0.22,0
							c-0.51,0-1.01-0.1-1.5-0.19c-1.19-0.23-2.31-0.44-3.44,0.64C508.8,515.21,508.66,515.26,508.5,515.26z M506.12,514.23
							c0.51,0,1.04,0.2,1.52,0.38c0.41,0.15,0.87,0.33,0.99,0.21c1.27-1.21,2.55-0.97,3.78-0.74c0.42,0.08,0.82,0.15,1.21,0.18
							c-0.32-3.38-0.32-6.81-0.32-10.13c0-0.66,0-1.33,0-1.99c-2.3,0.52-4.21,0.04-5.68-1.43c-0.7-0.7-1.34-0.64-2.13-0.3
							c0.06,1.57,0.16,3.15,0.25,4.69c0.18,2.99,0.37,6.07,0.29,9.13C506.06,514.23,506.09,514.23,506.12,514.23z"/>
					</g>
					<g>
						<path className="st27" d="M504.58,516.75c-0.53,0.04-1.11,0.04-1.45-0.4c-1.53-1.96-3.7-1.94-5.86-1.98
							c0.32-4.84,0.09-9.67-0.19-14.51c0.67-0.73,1.63-1,2.07-0.09c0.98,2,2.8,2.19,4.58,2.59c0.36,1.24,0.78,2.45,0.84,3.76
							C504.73,509.67,504.2,513.21,504.58,516.75z"/>
					</g>
					<g>
						<path className="st80" d="M497.08,499.87c0.29,4.83,0.51,9.67,0.19,14.51c-1.44,2-3.62,3.04-5.6,4.35
							c-0.15-0.07-0.3-0.14-0.45-0.21c-0.24-0.18-0.49-0.36-0.73-0.54c0-4.56,0-9.12-0.01-13.68c2.21,0.8,2.83,0.48,4.66-2.37
							C496.08,501.51,496.24,500.37,497.08,499.87z"/>
						<path className="st23" d="M491.69,518.94l-0.55-0.26l-0.85-0.62L490.28,504l0.26,0.09c2.11,0.76,2.65,0.49,4.43-2.29l0.03-0.05
							l0.06-0.02c0.45-0.19,0.71-0.58,0.98-1c0.25-0.39,0.51-0.78,0.94-1.04l0.28-0.17l0.02,0.32c0.28,4.66,0.52,9.62,0.19,14.53
							l0,0.06l-0.03,0.05c-1.16,1.61-2.8,2.62-4.38,3.59c-0.43,0.26-0.85,0.53-1.27,0.8L491.69,518.94z M490.68,517.87l0.65,0.48
							l0.32,0.15c0.39-0.26,0.79-0.5,1.19-0.75c1.54-0.95,3.12-1.93,4.23-3.44c0.31-4.74,0.09-9.53-0.17-14.06
							c-0.21,0.2-0.37,0.45-0.54,0.71c-0.28,0.43-0.57,0.88-1.09,1.12c-1.79,2.78-2.5,3.2-4.6,2.49L490.68,517.87z"/>
					</g>
					<g>
						<path className="st7" d="M483.19,481.54c-1.11,0.02-1.48-0.54-1.44-1.56c0.03-0.77,0.01-1.55,0.01-2.32
							c-0.98,1.22-2.52,1.96-3.08,3.53c-0.31,0.88-0.91,0.81-1.52,0.44c-0.81-0.48-1.56-1.07-2.35-1.58
							c-0.38-0.25-0.61-0.63-0.35-0.96c1.04-1.32-0.02-1.52-0.87-1.85c-0.16,0.07-0.32,0.13-0.5,0.18c-0.32,0.3-0.68,0.43-1.12,0.23
							c-0.08-0.02-0.15-0.03-0.23-0.04c-0.15,0.02-0.31,0.02-0.47,0.02c-0.18,0-0.35-0.01-0.52-0.03c-0.21-0.01-0.42-0.04-0.62-0.12
							c-0.17-0.05-0.33-0.1-0.48-0.17c-0.14-0.03-0.29-0.07-0.43-0.1l-0.19,0.03c0,0-0.19-0.03-0.19-0.03l-1.15,0.11l-0.42,0.31
							c-0.02,2.93-0.19,2.98-3.16,2.56c-2.3-0.32-4.64-0.4-6.96-0.58c-0.07,0.07-0.13,0.15-0.18,0.25c0.1,0.81,0.51,1.17,1.38,1.34
							c1.87,0.37,3.76,0.27,5.64,0.42c6.15,0.5,12.29,0.98,18.44,1.5c2.52,0.21,5.03,0.51,7.55,0.77c0.42-0.7,1.26-1.09,1.5-1.93
							C488.7,482.15,485.96,481.5,483.19,481.54z"/>
					</g>
					<g>
						<path className="st80" d="M491.51,481.96c-2.79,0.2-5.54-0.46-8.32-0.42c-1.11,0.02-1.48-0.54-1.44-1.56
							c0.03-0.77,0.01-1.55,0.01-2.32c2.67-4.05,5.21-4.48,8.62-1.53C492.33,477.8,491.48,479.95,491.51,481.96z"/>
						<path className="st23" d="M490.42,482.09c-1.18,0-2.35-0.12-3.49-0.23c-1.22-0.12-2.49-0.25-3.74-0.23c-0.02,0-0.03,0-0.05,0
							c-0.52,0-0.89-0.12-1.13-0.38c-0.26-0.27-0.38-0.69-0.35-1.29c0.02-0.53,0.02-1.08,0.02-1.61c0-0.24,0-0.48,0-0.71v-0.03
							l0.02-0.02c1.42-2.15,2.76-3.25,4.22-3.45c1.36-0.19,2.85,0.43,4.55,1.9c1.51,1.31,1.37,2.9,1.23,4.45
							c-0.04,0.48-0.09,0.98-0.08,1.46l0,0.09l-0.09,0.01C491.16,482.08,490.79,482.09,490.42,482.09z M483.46,481.44
							c1.17,0,2.35,0.12,3.49,0.23c1.46,0.14,2.97,0.29,4.46,0.2c0-0.46,0.04-0.93,0.08-1.39c0.14-1.56,0.27-3.04-1.17-4.28
							c-1.65-1.43-3.09-2.04-4.39-1.86c-1.38,0.19-2.71,1.28-4.07,3.34c0,0.23,0,0.45,0,0.68c0,0.53,0.01,1.08-0.02,1.62
							c-0.02,0.54,0.07,0.91,0.3,1.14c0.21,0.22,0.57,0.32,1.04,0.32C483.28,481.44,483.37,481.44,483.46,481.44z"/>
					</g>
					<g>
						<path className="st80" d="M546.87,504.54c-0.02,0.83-0.46,1.3-1.25,1.48c-2.52,0.55-5.01,1.22-7.64,1
							c-0.84-0.07-1.36-0.32-1.38-1.22c-0.27-1.45,1.03-2.11,1.6-3.13c1.64,0.2,3.23-0.72,4.98,0.08c0.89,0.41,0.78-1.3,1.53-1.73
							c0.37-0.21,0.33-0.96,0.93-0.84c0.53,0.11,0.55,0.71,0.65,1.11C546.53,502.36,546.68,503.45,546.87,504.54z"/>
						<path className="st23" d="M539.04,507.26c-0.36,0-0.71-0.01-1.07-0.04c-0.76-0.06-1.53-0.28-1.56-1.41
							c-0.2-1.05,0.39-1.72,0.96-2.36c0.26-0.29,0.5-0.56,0.67-0.87l0.06-0.12l0.13,0.02c0.61,0.08,1.23-0.01,1.87-0.11
							c1.01-0.15,2.05-0.31,3.16,0.21c0.15,0.07,0.21,0.04,0.24,0.03c0.15-0.06,0.28-0.37,0.39-0.67c0.16-0.41,0.34-0.87,0.72-1.08
							c0.09-0.05,0.16-0.18,0.23-0.32c0.13-0.26,0.33-0.65,0.84-0.54c0.58,0.12,0.68,0.67,0.76,1.07c0.01,0.07,0.03,0.13,0.04,0.19
							c0.18,0.77,0.31,1.57,0.44,2.34c0.05,0.31,0.1,0.62,0.16,0.93l0,0.04c-0.02,0.89-0.51,1.47-1.41,1.67l-0.85,0.19
							C542.94,506.82,541.02,507.26,539.04,507.26z M538.3,502.87c-0.18,0.3-0.42,0.57-0.65,0.83c-0.53,0.6-1.03,1.16-0.86,2.06
							c0.02,0.73,0.33,0.99,1.21,1.06c2.3,0.2,4.55-0.31,6.72-0.8l0.85-0.19c0.73-0.16,1.08-0.57,1.1-1.27
							c-0.05-0.3-0.1-0.61-0.15-0.91c-0.13-0.76-0.26-1.55-0.44-2.31c-0.02-0.07-0.03-0.14-0.04-0.21c-0.08-0.42-0.15-0.7-0.45-0.76
							c-0.2-0.04-0.26,0.05-0.41,0.33c-0.09,0.18-0.19,0.38-0.38,0.48c-0.26,0.15-0.4,0.52-0.55,0.88c-0.15,0.39-0.3,0.77-0.61,0.89
							c-0.16,0.07-0.35,0.05-0.55-0.04c-1.01-0.46-1.95-0.32-2.94-0.18C539.55,502.83,538.94,502.92,538.3,502.87z"/>
					</g>
					<g>
						<path className="st50" d="M504.58,516.75c-0.38-3.54,0.14-7.08-0.01-10.62c-0.06-1.32-0.48-2.53-0.84-3.76
							c-0.03-1.1,0.48-1.8,1.56-2.07c0.17,4.72,0.68,9.43,0.54,14.16c-0.22,0.44-0.49,0.83-0.3,1.41
							C505.8,516.7,505.27,516.84,504.58,516.75z"/>
					</g>
					<g>
						<path className="st50" d="M513.84,514.46c-0.42-4.18-0.32-8.38-0.34-12.57c0.39-0.37,0.78-0.74,1.17-1.12c0,4.42,0,8.85,0,13.27
							C514.4,514.19,514.12,514.33,513.84,514.46z"/>
					</g>
					<g>
						<path className="st81" d="M448.52,523.82c-0.05-0.26-0.09-0.52-0.14-0.78c0.02-1.02,0.03-2.05,0.05-3.07
							c-0.11-1.43-0.22-2.86-0.33-4.28c0.45-2.11,0-4.22-0.02-6.34c-0.09-8.47,0.29-16.95-0.38-25.42c0.04-0.12,0.09-0.25,0.14-0.37
							c2.46-0.62,4.93-1.2,7.37-1.88c1.47-0.41,1.93,0.03,2,1.55c0.47,9.49,0.15,18.98,0.34,28.47c0.05,2.6,0.14,5.19,0.21,7.79
							c-0.78,0.59-1.38,0.4-1.69-0.47c-1.34,1.05-1.21,3.5-3.59,3.2c0-1.04,0.02-2.08-0.01-3.12c-0.01-0.41,0.11-1.02-0.64-0.92
							c-0.54,0.07-1.01,0.25-0.99,0.95c0.02,0.84,0.07,1.7-0.02,2.53c-0.13,1.24,0.4,1.67,1.57,1.49
							C451.29,524.56,449.79,523.53,448.52,523.82z"/>
						<path className="st23" d="M450.77,524.01c-0.29,0-0.59-0.04-0.88-0.08c-0.46-0.06-0.93-0.12-1.35-0.02l-0.1,0.02l-0.16-0.88
							l0.05-3.09l-0.33-4.29l0-0.01c0.3-1.42,0.19-2.86,0.09-4.26c-0.05-0.67-0.1-1.37-0.11-2.06c-0.03-2.64-0.01-5.32,0-7.92
							c0.04-5.74,0.07-11.67-0.38-17.49l0-0.02l0.02-0.05c0.04-0.11,0.08-0.23,0.12-0.34l0.02-0.05l0.05-0.01
							c0.72-0.18,1.45-0.36,2.17-0.54c1.71-0.42,3.47-0.86,5.19-1.34c0.76-0.21,1.24-0.2,1.57,0.04c0.35,0.25,0.51,0.73,0.55,1.6
							c0.28,5.77,0.28,11.64,0.27,17.31c0,3.66-0.01,7.44,0.07,11.16c0.04,1.74,0.09,3.52,0.13,5.23c0.02,0.85,0.05,1.7,0.07,2.55
							l0,0.05l-0.04,0.03c-0.41,0.31-0.79,0.42-1.11,0.33c-0.29-0.08-0.52-0.32-0.68-0.71c-0.4,0.36-0.67,0.87-0.93,1.36
							c-0.51,0.97-1.04,1.98-2.64,1.78l-0.09-0.01v-0.09c0-0.29,0-0.58,0-0.87c0-0.74,0.01-1.5-0.02-2.24c0-0.06,0-0.12,0-0.18
							c0-0.23,0.01-0.46-0.12-0.58c-0.08-0.07-0.22-0.1-0.41-0.07c-0.55,0.07-0.92,0.24-0.9,0.85l0.01,0.42
							c0.02,0.7,0.04,1.42-0.03,2.13c-0.06,0.57,0.02,0.96,0.25,1.18c0.23,0.22,0.63,0.29,1.2,0.2l0.24-0.04l-0.15,0.19
							C451.95,523.85,451.37,524.01,450.77,524.01z M449.03,523.67c0.29,0,0.59,0.04,0.88,0.08c0.81,0.11,1.58,0.2,2.22-0.47
							c-0.51,0.04-0.87-0.05-1.12-0.28c-0.28-0.27-0.38-0.71-0.31-1.35c0.07-0.69,0.05-1.41,0.03-2.1l-0.01-0.42
							c-0.02-0.81,0.57-0.99,1.08-1.05c0.25-0.03,0.44,0.01,0.56,0.12c0.19,0.17,0.19,0.47,0.18,0.72c0,0.06,0,0.12,0,0.17
							c0.02,0.75,0.02,1.51,0.02,2.25c0,0.26,0,0.52,0,0.79c1.4,0.14,1.86-0.75,2.35-1.68c0.29-0.55,0.59-1.12,1.08-1.5l0.11-0.08
							l0.05,0.13c0.14,0.4,0.35,0.64,0.6,0.72c0.26,0.08,0.56-0.01,0.9-0.26c-0.02-0.83-0.05-1.67-0.07-2.5
							c-0.05-1.72-0.1-3.49-0.13-5.24c-0.07-3.72-0.07-7.5-0.07-11.16c0.01-5.67,0.01-11.54-0.27-17.3
							c-0.04-0.8-0.18-1.24-0.47-1.45c-0.27-0.2-0.72-0.2-1.41-0.01c-1.72,0.48-3.49,0.92-5.2,1.34c-0.71,0.17-1.41,0.35-2.12,0.53
							c-0.04,0.1-0.07,0.19-0.11,0.29l0,0.01c0.46,5.83,0.42,11.75,0.38,17.49c-0.02,2.6-0.03,5.28,0,7.92
							c0.01,0.68,0.06,1.37,0.11,2.04c0.11,1.41,0.21,2.86-0.09,4.3l0.33,4.27l-0.05,3.08l0.12,0.66
							C448.74,523.68,448.89,523.67,449.03,523.67z"/>
					</g>
					<g>
						<path className="st80" d="M466.77,473.85c-0.79-0.36-1.36-0.97-1.71-1.69c-0.48-0.04-1.08,0.02-1.14-0.44
							c-0.27-1.93-1.95-2.3-3.2-3.16c-0.15,0.28-0.4,0.54-0.45,0.83c-0.48,2.71-0.77,2.97-3.66,3.1c-0.08,0.13-0.17,0.27-0.25,0.4
							c0.93,0.18,0.72,0.93,0.74,1.53c0.04,1.73,0.05,3.46,0.08,5.19c2.32,0.19,4.66,0.26,6.96,0.58c2.97,0.42,3.13,0.36,3.16-2.56
							c-0.12-1.01-0.25-2.03-0.37-3.04C466.84,474.36,466.79,474.11,466.77,473.85z"/>
						<path className="st23" d="M465.94,480.59c-0.46,0-1.05-0.08-1.85-0.19c-1.58-0.22-3.21-0.33-4.79-0.43
							c-0.72-0.05-1.44-0.09-2.15-0.15l-0.18-0.01l-0.02-1.6c-0.01-1.26-0.03-2.51-0.06-3.77c0-0.1,0-0.21,0-0.31
							c0.02-0.58,0-0.92-0.58-1.03l-0.28-0.05l0.46-0.73l0.1,0c2.78-0.12,3.01-0.31,3.47-2.93c0.04-0.24,0.18-0.45,0.32-0.65
							c0.06-0.08,0.11-0.16,0.16-0.25l0.11-0.19l0.18,0.12c0.29,0.2,0.61,0.37,0.92,0.55c1.06,0.58,2.15,1.18,2.37,2.75
							c0.03,0.19,0.24,0.24,0.73,0.26c0.08,0,0.16,0.01,0.23,0.01l0.11,0.01l0.05,0.1c0.35,0.72,0.91,1.28,1.61,1.6l0.1,0.05
							l0.01,0.11c0.02,0.24,0.07,0.48,0.13,0.71l0.37,3.07c-0.01,1.54-0.07,2.3-0.51,2.69C466.72,480.51,466.4,480.59,465.94,480.59
							z M457.36,479.44c0.66,0.05,1.31,0.09,1.97,0.14c1.58,0.1,3.22,0.21,4.82,0.44c1.4,0.2,2.21,0.3,2.55,0
							c0.34-0.29,0.37-1.09,0.38-2.37l-0.37-3.02c-0.06-0.19-0.1-0.41-0.13-0.64c-0.71-0.35-1.28-0.91-1.65-1.63
							c-0.04,0-0.07,0-0.11-0.01c-0.43-0.02-1.02-0.05-1.1-0.59c-0.2-1.37-1.15-1.9-2.17-2.46c-0.26-0.14-0.53-0.29-0.78-0.45
							c-0.02,0.03-0.04,0.06-0.06,0.09c-0.12,0.17-0.23,0.34-0.26,0.5c-0.49,2.79-0.85,3.12-3.74,3.25l-0.06,0.09
							c0.67,0.26,0.65,0.88,0.63,1.35c0,0.1-0.01,0.2,0,0.29c0.03,1.26,0.05,2.51,0.06,3.77L457.36,479.44z"/>
					</g>
					<g>
						<path className="st50" d="M456.35,472.89c0.93,0.18,0.72,0.93,0.74,1.53c0.04,1.73,0.05,3.46,0.08,5.19
							c-0.07,0.07-0.13,0.15-0.18,0.25c-2.68,0.55-5.36,1.1-8.04,1.65c-0.06-0.25-0.12-0.5-0.18-0.75c0.29-1.56-0.89-3.22,0.36-4.71
							c1.4,0.57,3.08,0.73,4.11,1.92c1.18,1.35,1.76,0.46,2.37-0.41c0.57-0.8,0.28-1.75,0.23-2.65c0-0.14-0.01-0.28-0.01-0.42
							C456,473.96,455.89,473.33,456.35,472.89z"/>
						<path className="st23" d="M448.8,481.75l-0.23-0.98l0.01-0.04c0.1-0.55,0.01-1.12-0.09-1.72c-0.17-1.02-0.35-2.08,0.49-3.08
							l0.09-0.11l0.13,0.05c0.4,0.16,0.83,0.3,1.25,0.42c1.06,0.32,2.16,0.66,2.93,1.54c0.37,0.43,0.69,0.64,0.97,0.63
							c0.38-0.02,0.7-0.47,1.04-0.95l0.04-0.06c0.4-0.57,0.33-1.23,0.25-1.92c-0.02-0.2-0.04-0.4-0.06-0.6l-0.01-0.46l0.01-0.03
							c0.06-0.16,0.08-0.34,0.11-0.53c0.06-0.38,0.12-0.82,0.47-1.16l0.07-0.07l0.1,0.02c0.94,0.18,0.92,0.9,0.9,1.43
							c0,0.1-0.01,0.2,0,0.29c0.03,1.26,0.05,2.51,0.06,3.77l0.02,1.51l-0.06,0.06c-0.06,0.06-0.1,0.12-0.14,0.2l-0.04,0.09
							l-0.09,0.02L448.8,481.75z M448.97,480.76l0.12,0.53l7.77-1.6c0.03-0.05,0.07-0.1,0.11-0.15l-0.02-1.35
							c-0.01-1.26-0.03-2.51-0.06-3.77c0-0.1,0-0.21,0-0.31c0.02-0.55,0.01-0.87-0.48-1c-0.2,0.23-0.24,0.52-0.29,0.85
							c-0.03,0.19-0.05,0.38-0.11,0.56l0.01,0.39c0.01,0.18,0.03,0.38,0.05,0.57c0.08,0.74,0.17,1.5-0.32,2.19l-0.04,0.06
							c-0.38,0.54-0.77,1.09-1.34,1.12c-0.02,0-0.03,0-0.05,0c-0.39,0-0.8-0.25-1.24-0.76c-0.69-0.8-1.69-1.1-2.75-1.43
							c-0.38-0.12-0.77-0.24-1.15-0.38c-0.62,0.82-0.47,1.71-0.32,2.65C448.97,479.54,449.07,480.15,448.97,480.76z"/>
					</g>
					<g>
						<path className="st59" d="M455.83,474.92c0.05,0.9,0.34,1.84-0.23,2.65c-0.61,0.86-1.19,1.76-2.37,0.41
							c-1.03-1.18-2.71-1.34-4.11-1.92c0.38-1.33,1.27-0.8,2.1-0.6c1.2,0.28,2.3,1.06,3.64,0.74c0.61-0.14,0.35-0.55,0.32-0.9
							c0.02-0.09,0.02-0.17-0.03-0.25C455.38,475.01,455.61,474.96,455.83,474.92z"/>
						<path className="st23" d="M454.33,478.76c-0.36,0-0.74-0.24-1.17-0.73c-0.71-0.82-1.77-1.14-2.8-1.46
							c-0.43-0.13-0.87-0.26-1.28-0.43l-0.08-0.03l0.02-0.08c0.12-0.43,0.3-0.7,0.55-0.84c0.37-0.21,0.83-0.07,1.27,0.06
							c0.13,0.04,0.26,0.08,0.39,0.11c0.38,0.09,0.76,0.23,1.12,0.36c0.81,0.3,1.58,0.59,2.47,0.38c0.39-0.09,0.35-0.27,0.29-0.56
							c-0.02-0.08-0.03-0.16-0.04-0.24l0-0.02l0.01-0.02c0.02-0.06,0.01-0.12-0.02-0.17l-0.06-0.12l0.92-0.18l0.01,0.11
							c0.01,0.19,0.03,0.39,0.05,0.58c0.08,0.72,0.16,1.47-0.3,2.13l-0.04,0.06c-0.36,0.52-0.74,1.05-1.27,1.08
							C454.36,478.76,454.34,478.76,454.33,478.76z M449.24,476c0.38,0.15,0.79,0.27,1.18,0.39c1.05,0.32,2.14,0.65,2.89,1.51
							c0.39,0.44,0.72,0.66,1.02,0.66c0.01,0,0.02,0,0.03,0c0.43-0.02,0.76-0.49,1.12-1l0.04-0.06c0.42-0.6,0.35-1.28,0.27-1.99
							c-0.02-0.16-0.04-0.32-0.05-0.48l-0.45,0.09c0.01,0.06,0.01,0.12,0,0.18c0.01,0.06,0.02,0.13,0.04,0.19
							c0.06,0.28,0.14,0.66-0.44,0.79c-0.94,0.22-1.78-0.09-2.58-0.39c-0.36-0.13-0.73-0.27-1.1-0.36c-0.13-0.03-0.27-0.07-0.4-0.11
							c-0.42-0.12-0.82-0.24-1.12-0.08C449.49,475.46,449.35,475.67,449.24,476z"/>
					</g>
					<g>
						<path className="st82" d="M460.06,467.93c-0.54,0.53-0.02,1.63-0.96,1.94l0,0c-0.46,1.12-1.81,1.3-2.42,2.27
							c-0.05,0.11-0.07,0.23-0.08,0.35c2.89-0.12,3.18-0.38,3.66-3.1c0.05-0.29,0.3-0.56,0.45-0.83c0.06-0.18,0.12-0.35,0.18-0.53
							c-0.02-0.01-0.05-0.02-0.07-0.03C460.55,468.01,460.3,467.98,460.06,467.93z"/>
						<path className="st23" d="M456.5,472.6l0-0.1c0-0.13,0.03-0.27,0.08-0.39c0.3-0.47,0.74-0.76,1.18-1.04
							c0.52-0.33,1-0.64,1.24-1.23l0.07-0.17l0.06,0.09c0.43-0.17,0.47-0.54,0.52-0.97c0.04-0.33,0.08-0.68,0.33-0.93l0.04-0.04
							l0.05,0.01c0.22,0.05,0.47,0.07,0.74,0.08l0.07,0.02l0.13,0.05l-0.03,0.09c-0.06,0.18-0.12,0.35-0.18,0.53
							c-0.06,0.11-0.12,0.19-0.17,0.28c-0.12,0.18-0.24,0.36-0.27,0.54c-0.49,2.76-0.84,3.05-3.75,3.18L456.5,472.6z M459.17,469.96
							c-0.27,0.61-0.79,0.94-1.3,1.27c-0.43,0.28-0.84,0.54-1.11,0.97c-0.02,0.06-0.04,0.13-0.05,0.2c2.73-0.12,2.99-0.38,3.46-3.01
							c0.04-0.22,0.17-0.42,0.3-0.61c0.06-0.08,0.11-0.17,0.16-0.25c0.04-0.13,0.09-0.27,0.14-0.41c-0.24-0.01-0.47-0.03-0.67-0.07
							c-0.17,0.2-0.21,0.48-0.24,0.77C459.8,469.25,459.74,469.75,459.17,469.96z"/>
					</g>
					<g>
						<path className="st82" d="M471.21,507.67c0.05-0.79-0.02-1.68,0.96-1.68c0.99,0,0.95,0.89,0.95,1.6c0,0.71,0.04,1.59-0.95,1.6
							C471.18,509.19,471.28,508.29,471.21,507.67z"/>
					</g>
					<g>
						<path className="st82" d="M474.35,498.39c-0.09,0.6,0.13,1.48-0.85,1.42c-0.94-0.06-0.7-0.93-0.71-1.52
							c-0.01-0.55-0.11-1.31,0.72-1.33C474.49,496.94,474.26,497.79,474.35,498.39z"/>
					</g>
					<g>
						<path className="st82" d="M472.77,488.99c-0.1,0.45,0.17,1.31-0.48,1.24c-0.99-0.12-0.65-1.24-0.9-1.91
							c-0.15-0.39,0.04-0.83,0.55-0.8C472.88,487.59,472.7,488.36,472.77,488.99z"/>
					</g>
					<g>
						<path className="st24" d="M531.53,506.35c1.39,0.36,2.38-0.22,3.16-1.32c0.79-0.12,1.22,0.65,1.91,0.76
							c0.02,0.9,0.54,1.15,1.38,1.22c2.63,0.22,5.12-0.44,7.64-1c0.79-0.17,1.24-0.65,1.25-1.48c0.37,0.28,0.79,0.59,1.25,0.39
							c1.18-0.49,1.74-0.3,1.98,1.11c0.29,1.69,1.79,1.03,2.48,0.61c1.5-0.91,3.34-1.46,4.02-3.47c0.36-1.08,1.24-1.75,2.42-0.71
							c0.19,0.17,0.55,0.46,0.63,0.41c2.19-1.47,4.77,0.51,7.24-1.14c1.7-1.14,3.81-1.15,4.56-3.79c0.47-1.65,2.82-1.18,4.36-1.53
							c0,12.4,0,32.66,0,45.06c-1.43,0.36-5.21-0.38-6.05-1.79c-0.69-1.16-2.11-0.43-3.34,0c-1.05,0.36-3.38,0.1-3.2-0.88
							c0.43-2.31-4.41,1.29-5.73,1.95c-2.5,1.25,0.47,3.66-1.95,2.61c-2.23-0.97-4.01-0.66-4.71,1.66
							c-0.58,1.95,1.27-2.54-0.67-1.59c-0.69,0.34-1.43,0.46-1.69-0.23c-0.54-1.48-1.5-1.91-2.96-1.79
							c-0.62,0.05-1.05-0.48-1.43-0.93c-0.72-0.84-1.53-1.09-2.62-0.75c-0.53,0.17-1.31,0.28-1.71,0.02
							c-1.46-0.97-2.68-0.55-3.95,0.33c-0.41,0.28-0.95,0.44-1.45,0.55c-2.89,0.63-6.02-0.06-8.76,1.52
							c-0.23,0.13-0.63-0.01-0.95-0.04c-2.08-0.22-3.58-1.77-5.46-2.49c-0.91-0.34-1.6-0.75-2.65-0.63
							c-1.49,0.17-2.74,0.72-3.97,1.5c-0.86,0.55-1.88,1.4-2.87,0.73c-1.42-0.95-2.87-0.51-4.32-0.55
							c-1.47-0.03-2.91,0.74-4.44-0.48c-0.56-0.45-1.58-0.2-1.62-1.46c-0.02-0.92-0.87-0.82-1.51-0.78
							c-0.77,0.04-1.59,0.38-2.31,0.22c-2.55-0.56-4.86,0.14-7.26,0.86c-1.89,0.57-3.97,1.9-5.81,0.96c-2-1.02-3.73-0.7-5.64-0.35
							c-1.86,0.35-4.02,0.88-5.52-0.15c-1.73-1.19-3.23-1.05-4.95-0.64c-0.78,0.19-1.51,0.19-2.31,0.03
							c-2.61-0.53-4.39,0.5-5.27,2.98c-0.92-0.25-0.84-1.95-2.18-1.55c-1.99,0.59-4.13,0.71-5.92,1.91
							c-0.66,0.44-1.34,0.77-2.16,0.81c-3.05,0.14-6.02,0.51-9.02,1.3c-2.02,0.54-4,1.63-6.22,1.42c-1.09-0.11-2.01-4.52-2.87-3.77
							c-1.96,1.72-4.31,2.27-6.88,2.23c-1.42-0.02-2.91-0.37-4.25,0.31c-1.59,0.81-5.74-1.22-6.43-2.76
							c14.13-3.78,5.95-2.8,7.32-3.48c1.86-0.93,3.35-2.25,4.11-4.26c2.63,0.98,5.06-0.85,7.65-0.53c0.2,0.02,0.51-0.2,0.64-0.39
							c0.91-1.3,2.33-1.57,3.72-1.86c0.55-0.12,1-0.27,1.23-0.82c-0.01-1.62-0.24-3.35,1.52-4.31c0.72-0.39,1.55-0.62,2.36-0.82
							c2.37-0.6,4.44-2.57,7.16-1.52c1.27-0.28,2.78,0.75,3.85-0.68c0.03-0.31,0.06-0.61,0.1-0.92c2.38,0.3,2.25-2.15,3.59-3.2
							c0.31,0.86,0.91,1.06,1.69,0.47c1.95-0.32,4.31,0.99,5.75-1.42c0.05-0.08,0.24-0.07,0.37-0.11c1.98-0.68,3.97-1.19,5.72-2.59
							c1.81-1.43,0.46-8.54,1.02-6.18c0.15,0.64,3.7,1.83,4.26,2.1c-0.22-8.24,3.98-6.13,5.59-7.68c0.78-0.75,6.37,1.39,7.75,2.38
							c0.41,0.3,0.37,3.42,0.81,3.2c1.58-0.8,2.26-1.31,2.65,0.71c0.07,0.36,0.54,3.06,0.87,3.13c0.24,0.18-0.24-0.18,0,0
							c0,0,0.24-1.82,0.39-1.74c0,0,2.71-2.23,4.15-4.23c2.16,0.04,5.96,0.27,7.49,2.23c0.34,0.44-0.52,4.36,0.01,4.32
							c0.68,0.09,1.22,3.09,0.95,2.26c-0.19-0.58,0.08-0.97,0.3-1.41c1.09-0.21,1.26-3.64,1.7-4.06c1.68-1.61,4.28-2.78,5.97-2.78
							c0.28-0.14,0.9,6.57,1.18,6.43c0.91-0.03,2.2,0.88,2.59,0.26c1.44-2.29,2.12-5.83,4.36-6.32c1.1-0.24,3.58,2.79,4.58,3.43
							c1.8,1.17,3.36,0.08,4.95-0.46c0.65-0.22,1-1.06,0.6-1.64C531.08,508.32,531.37,507.36,531.53,506.35z"/>
						<path className="st23" d="M433.8,546.02c-0.2,0-0.39-0.01-0.59-0.03c-0.64-0.06-1.12-1.11-1.63-2.21c-0.31-0.67-0.73-1.58-1-1.64
							c-0.01,0-0.04-0.01-0.09,0.03c-1.81,1.58-4.11,2.33-7.01,2.28c-0.34-0.01-0.68-0.03-1.03-0.05c-1.06-0.07-2.16-0.15-3.13,0.35
							c-0.86,0.44-2.22,0.07-2.95-0.19c-1.51-0.53-3.28-1.62-3.74-2.66l-0.09-0.21l0.22-0.06c6.44-1.72,7.98-2.4,8.27-2.66
							c-0.13-0.05-0.39-0.09-0.54-0.12c-0.41-0.07-0.68-0.12-0.71-0.34c-0.03-0.2,0.19-0.31,0.27-0.35
							c2.03-1.01,3.34-2.37,4.02-4.15l0.07-0.18l0.18,0.07c1.43,0.54,2.79,0.2,4.23-0.15c1.09-0.27,2.22-0.54,3.38-0.4
							c0.1,0.01,0.34-0.14,0.46-0.31c0.93-1.33,2.35-1.63,3.72-1.92l0.12-0.03c0.55-0.12,0.88-0.26,1.07-0.67
							c0-0.15,0-0.31-0.01-0.46c-0.03-1.51-0.06-3.06,1.63-3.98c0.76-0.41,1.6-0.64,2.4-0.84c0.72-0.18,1.44-0.51,2.13-0.82
							c1.56-0.7,3.17-1.43,5.09-0.72c0.46-0.09,0.93-0.03,1.4,0.03c0.87,0.11,1.62,0.21,2.25-0.58l0.11-1.07l0.2,0.02
							c1.39,0.18,1.83-0.66,2.34-1.62c0.29-0.56,0.6-1.13,1.11-1.54l0.21-0.17l0.09,0.26c0.09,0.25,0.26,0.57,0.54,0.66
							c0.29,0.08,0.62-0.11,0.85-0.28l0.09-0.04c0.62-0.1,1.28-0.05,1.91,0.01c1.42,0.12,2.76,0.24,3.7-1.33
							c0.08-0.13,0.23-0.15,0.35-0.17c0.04-0.01,0.08-0.01,0.12-0.02c0.29-0.1,0.58-0.2,0.87-0.29c1.71-0.56,3.32-1.09,4.79-2.26
							c1.16-0.92,0.92-4.54,0.83-5.9c-0.05-0.69-0.05-0.77,0.15-0.8c0.2-0.03,0.23,0.1,0.36,0.62c0.09,0.39,2.55,1.34,3.36,1.65
							c0.2,0.08,0.38,0.14,0.51,0.2c-0.08-5.87,2.18-6.4,3.99-6.84c0.65-0.15,1.26-0.3,1.67-0.69c0.94-0.91,6.8,1.5,8,2.36
							c0.25,0.18,0.34,0.8,0.46,1.8c0.05,0.45,0.13,1.12,0.23,1.33l0.21-0.11c0.82-0.42,1.41-0.73,1.88-0.49
							c0.36,0.18,0.59,0.62,0.76,1.49l0.03,0.13c0.24,1.31,0.42,2.02,0.54,2.41c0.18-1.18,0.31-1.25,0.39-1.29l0.04-0.02
							c0.37-0.31,2.76-2.34,4.06-4.15l0.06-0.08l0.1,0c2.55,0.05,6.11,0.34,7.64,2.31c0.19,0.24,0.14,0.87,0,2.24
							c-0.07,0.72-0.19,1.79-0.09,2.02c0.38,0.11,0.66,0.68,0.85,1.25c0.03-0.06,0.06-0.11,0.09-0.17l0.12-0.24l0.1-0.02
							c0.57-0.11,0.9-1.58,1.12-2.55c0.18-0.8,0.29-1.27,0.48-1.45c1.68-1.6,4.27-2.81,6.06-2.83l0.05-0.02l0.11,0.05
							c0.19,0.1,0.27,0.42,0.67,3.36c0.16,1.14,0.36,2.67,0.49,3.04c0.37,0.01,0.76,0.14,1.15,0.26c0.62,0.2,1.04,0.31,1.18,0.09
							c0.44-0.7,0.82-1.54,1.19-2.35c0.82-1.82,1.66-3.69,3.3-4.06c0.84-0.18,2.2,1.12,3.39,2.27c0.52,0.51,1.02,0.98,1.33,1.19
							c1.37,0.89,2.58,0.39,3.86-0.13c0.31-0.13,0.61-0.25,0.92-0.36c0.27-0.09,0.49-0.33,0.59-0.61c0.09-0.26,0.06-0.52-0.08-0.73
							c-0.65-0.95-0.49-1.88-0.33-2.77c0.02-0.11,0.04-0.23,0.06-0.34l0.04-0.21l0.21,0.05c1.19,0.31,2.12-0.09,2.95-1.24l0.05-0.07
							l0.08-0.01c0.53-0.08,0.92,0.18,1.26,0.41c0.24,0.16,0.46,0.31,0.71,0.35l0.16,0.03l0,0.16c0.02,0.7,0.32,0.96,1.21,1.03
							c2.3,0.2,4.55-0.31,6.72-0.8l0.85-0.19c0.74-0.16,1.09-0.57,1.1-1.29l0.01-0.38l0.31,0.23c0.42,0.32,0.72,0.51,1.06,0.37
							c0.7-0.29,1.17-0.33,1.53-0.14c0.36,0.2,0.59,0.64,0.72,1.4c0.08,0.45,0.25,0.74,0.52,0.86c0.53,0.24,1.34-0.19,1.66-0.38
							c0.31-0.19,0.64-0.36,0.97-0.54c1.26-0.67,2.45-1.31,2.96-2.82c0.24-0.71,0.66-1.18,1.17-1.32c0.34-0.09,0.89-0.07,1.57,0.53
							c0.26,0.23,0.4,0.32,0.46,0.35c1.1-0.71,2.31-0.6,3.48-0.5c1.22,0.11,2.49,0.22,3.7-0.59c0.5-0.34,1.04-0.58,1.55-0.81
							c1.24-0.56,2.41-1.08,2.92-2.88c0.37-1.32,1.83-1.42,3.11-1.51c0.5-0.03,0.98-0.07,1.4-0.16l0.24-0.05v45.46l-0.15,0.04
							c-1.57,0.4-5.39-0.41-6.26-1.88c-0.48-0.81-1.35-0.56-2.63-0.09c-0.16,0.06-0.32,0.12-0.48,0.17c-0.88,0.3-2.52,0.2-3.17-0.36
							c-0.24-0.21-0.34-0.46-0.29-0.74c0.03-0.18,0.05-0.41-0.07-0.49c-0.51-0.35-2.82,1.09-4.05,1.86
							c-0.55,0.34-1.02,0.64-1.33,0.79c-1.19,0.59-1.01,1.44-0.88,2.05c0.07,0.32,0.12,0.59-0.07,0.75
							c-0.19,0.15-0.49,0.1-1.17-0.19c-1.27-0.55-2.31-0.65-3.08-0.29c-0.5,0.23-0.89,0.66-1.17,1.29c-0.09,0.53-0.28,1.02-0.34,1.1
							l-0.1,0.12l-0.15-0.07c-0.17-0.09-0.12-0.26,0.02-0.73c0.06-0.19,0.12-0.37,0.19-0.53c0.06-0.37,0.07-0.74-0.06-0.88
							c-0.1-0.11-0.34-0.03-0.52,0.06c-0.6,0.29-1.08,0.37-1.44,0.22c-0.24-0.1-0.41-0.28-0.52-0.56c-0.48-1.3-1.28-1.79-2.76-1.66
							c-0.7,0.06-1.18-0.5-1.56-0.95l-0.04-0.04c-0.67-0.79-1.41-1-2.41-0.69c-0.61,0.19-1.42,0.29-1.87-0.01
							c-1.38-0.91-2.49-0.53-3.73,0.33c-0.46,0.32-1.06,0.47-1.52,0.58c-1.08,0.24-2.21,0.29-3.3,0.34
							c-1.83,0.09-3.72,0.18-5.41,1.16c-0.23,0.13-0.53,0.08-0.81,0.03c-0.09-0.02-0.18-0.03-0.26-0.04
							c-1.32-0.14-2.41-0.79-3.46-1.43c-0.65-0.39-1.33-0.8-2.05-1.07c-0.17-0.06-0.34-0.13-0.49-0.2
							c-0.67-0.28-1.25-0.52-2.06-0.42c-1.32,0.15-2.52,0.61-3.88,1.48c-0.1,0.06-0.2,0.13-0.3,0.2c-0.8,0.53-1.8,1.19-2.79,0.53
							c-1.01-0.67-2.02-0.61-3.1-0.55c-0.36,0.02-0.74,0.04-1.11,0.04c-0.42-0.01-0.81,0.05-1.23,0.1c-1.07,0.15-2.17,0.3-3.32-0.63
							c-0.15-0.12-0.35-0.18-0.56-0.25c-0.49-0.16-1.1-0.36-1.13-1.35c-0.01-0.21-0.06-0.36-0.16-0.45
							c-0.22-0.2-0.65-0.17-1.04-0.15l-0.1,0.01c-0.28,0.02-0.57,0.07-0.85,0.13c-0.5,0.1-1.02,0.2-1.51,0.09
							c-2.48-0.55-4.72,0.13-7.1,0.84l-0.05,0.02c-0.45,0.13-0.9,0.31-1.37,0.49c-1.53,0.59-3.11,1.21-4.59,0.45
							c-1.95-1-3.68-0.67-5.52-0.33l-0.47,0.09c-1.76,0.34-3.75,0.72-5.2-0.27c-1.59-1.1-2.96-1.05-4.79-0.61
							c-0.79,0.19-1.56,0.2-2.39,0.03c-2.5-0.51-4.2,0.45-5.05,2.85l-0.06,0.17l-0.18-0.05c-0.45-0.12-0.7-0.53-0.91-0.88
							c-0.33-0.55-0.54-0.85-1.17-0.67c-0.61,0.18-1.23,0.32-1.84,0.45c-1.38,0.3-2.8,0.62-4.02,1.43c-0.55,0.37-1.31,0.8-2.26,0.84
							c-3.38,0.15-6.23,0.57-8.98,1.29c-0.58,0.15-1.18,0.36-1.75,0.56C436.49,545.57,435.19,546.02,433.8,546.02z M430.56,541.75
							c0.04,0,0.07,0,0.1,0.01c0.44,0.09,0.77,0.77,1.27,1.86c0.4,0.87,0.9,1.95,1.32,1.99c1.53,0.15,2.97-0.36,4.37-0.84
							c0.58-0.2,1.18-0.41,1.78-0.57c2.78-0.74,5.66-1.15,9.06-1.31c0.67-0.03,1.31-0.27,2.06-0.77c1.28-0.86,2.74-1.18,4.16-1.49
							c0.6-0.13,1.22-0.27,1.82-0.44c0.94-0.28,1.31,0.34,1.61,0.84c0.16,0.26,0.3,0.5,0.51,0.63c0.94-2.43,2.81-3.44,5.42-2.91
							c0.78,0.16,1.49,0.15,2.22-0.03c1.55-0.38,3.22-0.64,5.11,0.66c1.31,0.9,3.22,0.54,4.9,0.21l0.47-0.09
							c1.9-0.36,3.7-0.69,5.77,0.36c1.32,0.68,2.82,0.09,4.27-0.47c0.46-0.18,0.94-0.36,1.4-0.5l0.06-0.02
							c2.43-0.73,4.72-1.42,7.3-0.85c0.41,0.09,0.86,0,1.35-0.09c0.3-0.06,0.6-0.12,0.91-0.13l0.1-0.01
							c0.44-0.03,0.99-0.06,1.33,0.25c0.18,0.17,0.28,0.41,0.29,0.73c0.02,0.72,0.39,0.84,0.86,0.99c0.24,0.08,0.48,0.16,0.69,0.32
							c1.03,0.82,2,0.68,3.02,0.54c0.42-0.06,0.87-0.12,1.29-0.11c0.36,0.01,0.73-0.01,1.08-0.04c1.09-0.07,2.23-0.13,3.34,0.61
							c0.77,0.51,1.57-0.02,2.35-0.53c0.1-0.07,0.21-0.14,0.31-0.2c1.42-0.9,2.67-1.38,4.05-1.53c0.91-0.1,1.56,0.17,2.26,0.45
							c0.16,0.06,0.32,0.13,0.49,0.19c0.75,0.28,1.44,0.7,2.11,1.1c1.07,0.64,2.07,1.24,3.3,1.37c0.09,0.01,0.19,0.03,0.29,0.05
							c0.21,0.04,0.44,0.08,0.55,0.02c1.77-1.02,3.71-1.12,5.58-1.21c1.07-0.05,2.18-0.11,3.23-0.34c0.43-0.09,0.98-0.24,1.38-0.52
							c1.13-0.78,2.51-1.42,4.17-0.33c0.32,0.21,1,0.13,1.54-0.04c1.15-0.36,2.05-0.11,2.82,0.81l0.04,0.04
							c0.36,0.42,0.71,0.85,1.23,0.81c1.65-0.14,2.62,0.45,3.16,1.91c0.06,0.17,0.16,0.28,0.29,0.33c0.24,0.1,0.64,0.02,1.12-0.21
							c0.58-0.28,0.87-0.12,1.02,0.06c0.08,0.1,0.13,0.24,0.15,0.39c0.27-0.36,0.59-0.63,0.97-0.81c0.88-0.41,2.02-0.31,3.4,0.28
							c0.6,0.26,0.74,0.25,0.77,0.24c-0.01-0.02-0.05-0.22-0.08-0.36c-0.13-0.61-0.37-1.76,1.09-2.49c0.29-0.14,0.75-0.43,1.29-0.77
							c2.13-1.33,3.81-2.31,4.48-1.85c0.23,0.16,0.31,0.46,0.23,0.88c-0.02,0.09-0.02,0.22,0.16,0.37c0.52,0.45,2.04,0.55,2.79,0.29
							c0.16-0.05,0.31-0.11,0.47-0.17c1.13-0.42,2.42-0.89,3.1,0.26c0.73,1.23,4.17,2.01,5.68,1.73v-44.67
							c-0.39,0.07-0.81,0.1-1.22,0.12c-1.27,0.09-2.46,0.17-2.76,1.23c-0.56,1.97-1.87,2.56-3.14,3.13c-0.5,0.22-1.02,0.46-1.5,0.77
							c-1.32,0.88-2.66,0.77-3.95,0.66c-1.17-0.1-2.28-0.2-3.29,0.48c-0.17,0.11-0.37,0.01-0.87-0.42
							c-0.44-0.39-0.86-0.54-1.21-0.44c-0.38,0.1-0.7,0.49-0.9,1.07c-0.56,1.66-1.88,2.36-3.15,3.04c-0.32,0.17-0.64,0.34-0.95,0.53
							c-0.47,0.28-1.35,0.72-2.03,0.4c-0.39-0.18-0.64-0.57-0.74-1.15c-0.11-0.62-0.28-0.98-0.52-1.12
							c-0.24-0.13-0.62-0.08-1.19,0.16c-0.44,0.18-0.83,0.01-1.17-0.22c-0.13,0.69-0.6,1.14-1.37,1.31l-0.85,0.19
							c-2.21,0.5-4.49,1.01-6.84,0.81c-0.72-0.06-1.46-0.26-1.56-1.26c-0.27-0.08-0.49-0.23-0.71-0.38c-0.3-0.2-0.57-0.38-0.9-0.36
							c-0.86,1.17-1.88,1.62-3.11,1.37c-0.01,0.05-0.02,0.09-0.03,0.14c-0.15,0.85-0.3,1.65,0.26,2.48c0.21,0.31,0.26,0.7,0.13,1.07
							c-0.14,0.41-0.45,0.73-0.83,0.86c-0.3,0.1-0.6,0.23-0.9,0.35c-1.32,0.54-2.68,1.09-4.22,0.09c-0.34-0.22-0.83-0.69-1.39-1.23
							c-1.01-0.98-2.41-2.31-3.04-2.17c-1.45,0.32-2.21,2.03-3.03,3.83c-0.37,0.82-0.75,1.68-1.21,2.4
							c-0.32,0.5-0.98,0.28-1.63,0.08c-0.37-0.12-0.76-0.24-1.08-0.24l-0.05,0.03l-0.11-0.05c-0.19-0.1-0.27-0.42-0.67-3.36
							c-0.16-1.14-0.36-2.66-0.49-3.04c-1.67,0.04-4.17,1.22-5.74,2.72c-0.12,0.11-0.26,0.75-0.37,1.26
							c-0.27,1.21-0.58,2.58-1.33,2.83l-0.04,0.08c-0.12,0.22-0.22,0.42-0.25,0.63c0.08,0.34,0.12,0.6,0.12,0.65l0,0.17l-0.17,0.06
							c-0.19,0.03-0.24-0.15-0.28-0.28c-0.07-0.22-0.09-0.42-0.07-0.6c-0.17-0.64-0.48-1.49-0.72-1.52
							c-0.05,0.01-0.13-0.02-0.19-0.09c-0.22-0.23-0.18-0.89-0.03-2.34c0.07-0.71,0.19-1.79,0.08-1.96
							c-1.55-1.99-5.72-2.12-7.24-2.15c-1.45,1.97-4.01,4.09-4.12,4.18l-0.02,0.02c-0.08,0.19-0.2,0.88-0.28,1.5
							c0.06,0.06,0.09,0.14,0.02,0.24c-0.02,0.03-0.04,0.04-0.06,0.06l-0.02,0.13l-0.24-0.17c-0.18-0.06-0.43-0.32-0.95-3.14
							l-0.02-0.13c-0.14-0.71-0.32-1.1-0.55-1.21c-0.3-0.15-0.82,0.12-1.53,0.49l-0.29,0.15c-0.09,0.04-0.18,0.04-0.27,0
							c-0.24-0.13-0.32-0.59-0.45-1.68c-0.06-0.53-0.17-1.41-0.3-1.53c-1.46-1.05-6.86-3.01-7.49-2.4
							c-0.48,0.46-1.14,0.62-1.85,0.78c-1.8,0.43-3.84,0.91-3.69,6.75l0.01,0.32l-0.29-0.14c-0.12-0.06-0.41-0.17-0.77-0.31
							c-1.69-0.65-2.81-1.13-3.31-1.54c0.11,1.69,0.24,4.93-1,5.91c-1.53,1.21-3.25,1.78-4.91,2.33c-0.29,0.1-0.58,0.19-0.87,0.29
							c-0.05,0.02-0.12,0.03-0.18,0.04c-0.03,0-0.07,0.01-0.1,0.02c-1.07,1.75-2.59,1.62-4.05,1.49c-0.6-0.05-1.21-0.1-1.77-0.02
							c-0.42,0.31-0.82,0.42-1.16,0.31c-0.28-0.08-0.51-0.3-0.68-0.64c-0.34,0.34-0.58,0.8-0.81,1.24
							c-0.51,0.96-1.01,1.95-2.54,1.85l-0.08,0.78l-0.03,0.04c-0.77,1.02-1.76,0.89-2.65,0.78c-0.47-0.06-0.91-0.12-1.32-0.03
							l-0.06,0.01l-0.06-0.02c-1.79-0.69-3.27-0.02-4.84,0.69c-0.71,0.32-1.44,0.65-2.2,0.84c-0.78,0.2-1.59,0.42-2.31,0.81
							c-1.48,0.8-1.45,2.17-1.43,3.62c0,0.17,0.01,0.34,0.01,0.51l-0.02,0.08c-0.28,0.65-0.83,0.82-1.37,0.93l-0.12,0.03
							c-1.36,0.29-2.64,0.56-3.48,1.76c-0.15,0.22-0.52,0.51-0.83,0.47c-1.08-0.13-2.18,0.13-3.24,0.39
							c-1.38,0.34-2.8,0.68-4.28,0.2c-0.71,1.74-2.02,3.09-3.98,4.11c0.06,0.01,0.12,0.02,0.18,0.03c0.46,0.08,0.89,0.15,0.91,0.47
							c0.01,0.32,0.03,0.76-8.36,3.01c0.5,0.78,1.93,1.73,3.4,2.25c1.14,0.4,2.11,0.48,2.65,0.21c1.07-0.54,2.22-0.46,3.33-0.39
							c0.34,0.02,0.67,0.05,1,0.05c2.85,0.05,5-0.65,6.75-2.18C430.35,541.78,430.47,541.75,430.56,541.75z M504.58,513.41
							C504.57,513.41,504.57,513.41,504.58,513.41c0.01,0,0.03,0.01,0.04,0.01C504.61,513.42,504.59,513.41,504.58,513.41z
							 M559.54,502.73C559.54,502.73,559.54,502.73,559.54,502.73C559.54,502.73,559.54,502.73,559.54,502.73z"/>
					</g>
					<g>
						<path className="st83" d="M417.88,546.22c-3.02-0.01-6.03-0.21-9.04,0.12c-1.01,0.05-2.02,0.1-3.03,0.15
							c-0.03-0.11-0.08-0.21-0.13-0.31c1.72-1.04,5.2-3.9,7.13-4.37c0.44,1.3-0.14,3.22,0.7,2.98c0.99-0.29,1.88-0.81,2.93-1.01
							c3.56-0.69,7.31-0.26,10.74-1.76c0.57-0.25,1.26-0.16,1.52,0.49c0.68,1.76,1.76,1.42,2.94,0.65c0.51-0.33,1.05-0.6,1.65-0.5
							c4.01,0.69,6.7-0.12,9.55-2.33c1.33-1.03,3.25-1.62,4.69-0.71c1.9,1.2,2.79,0.75,4.07-0.61c0.63-0.67,1.67-0.17,2.52-0.65
							c1.08-0.61,1.48,0.54,1.28,1.69c-0.12,0.66-0.41-0.11,0.73-0.33c1.92-0.36,1.78-1.73,1.38-0.46
							c-0.25,0.79-0.76,1.89,0.51,2.04c1.4,0.17,3.51,0.95,4.09-0.57c0.5-1.31,1.19,0.93,0.6-0.72c-0.22-0.61-0.74-1.37,0.27-1.85
							c0.96-0.45,1.59-0.06,2.12,0.77c0.46,0.73,1.08-0.34,2.03-0.22c2.48,0.31,2.56-1.89,2.38,0.59c-0.11,1.54,0.58,1.99,2.03,1.83
							c1.66-0.18,3.15-0.94,4.73-1.18c3-0.46,5.38,2.04,7.23-0.19c0.93-1.11,1.92-1.81,3.27-1.8c1.74,0.02,3.35-0.16,4.82-1.19
							c0.47-0.33,1.1-0.62,1.63-0.15c0.52,0.46,0.27,1.05,0.1,1.63c-0.9,3,0.02,0.47,3.13,0.85c0.32,0.04,0.68,0.07,0.93,0.24
							c0.86,0.59,1.42,0.31,1.92-0.5c0.87-1.42,2.26-1.31,3.59-1.03c0.55,0.11,1.08,0.65,1.64,0.26c3.37-2.39,7.54,2.27,11.19,1.01
							c0.45-0.16,1.05-0.57,1.44-0.02c0.3,0.41-0.11-3.06-0.36-2.69c-0.74,1.07-0.41,1.54,0.82,1.56c0.45,0.01,0.89,0.06,1.52,0.11
							c-0.55,0.96,0.51,1.79,0.66,2.82c0.09,0.58-1.59,1.22-0.97,1.55c0.65,0.35,1.13-0.13,1.58-0.48c0.76-0.6,1.48-1.27,2.21-1.92
							c1.51-1.33,3.36-2.42,5.24-2.32c3.68,0.19,6.98,3.05,10.3,1.84c1.49-0.55,2.57-0.11,2.64,1.42c0.04,0.77,0.34-2.99,0.95-3.12
							c2.36-0.51,3.24,1.09,2.81,2.04c-0.65,1.42-1.58,1.52-0.45,1.61c1.32,0.1,2.54-0.48,3.88-0.35
							c5.27,0.51,10.48,0.85,15.27-2.32c2.51-1.66,5.63,0.34,8.34-0.8c0.08-0.04,0.28,0.05,0.35,0.14c1.1,1.57,2.53,0.85,3.9,0.57
							c0,2.61,0,5.22,0,7.83c-4.55-0.22-9.08,0.33-13.66,0.25c-9.9-0.19-19.81,0.41-29.72,0.5c-5.73,0.05-11.48-0.22-17.2-0.22
							c-12.71,0-25.42-0.77-38.14-0.18c-7.1,0.33-14.21-0.07-21.32,0.37c-5.79,0.36-11.55-0.86-17.33-1.04
							C431.6,546.03,424.74,546.21,417.88,546.22z"/>
						<path className="st23" d="M453.58,547.55c-2.78,0-5.57-0.29-8.29-0.57c-2.25-0.23-4.57-0.47-6.85-0.54
							c-4.77-0.15-9.64-0.11-14.34-0.06c-2.07,0.02-4.15,0.04-6.22,0.04h0c-0.81,0-1.62-0.02-2.43-0.03
							c-2.16-0.04-4.4-0.09-6.59,0.15l-3.2,0.16l-0.04-0.15c-0.03-0.1-0.07-0.19-0.11-0.28l-0.08-0.16l0.15-0.09
							c0.52-0.31,1.22-0.81,1.96-1.33c1.71-1.21,3.85-2.72,5.22-3.06l0.18-0.04l0.06,0.17c0.19,0.57,0.2,1.22,0.2,1.8
							c0,0.43,0.01,0.96,0.13,1.05c0.03,0.02,0.09,0.01,0.12,0c0.4-0.12,0.78-0.27,1.18-0.43c0.56-0.23,1.14-0.46,1.78-0.59
							c1.32-0.25,2.67-0.36,3.98-0.46c2.25-0.17,4.57-0.35,6.71-1.29c0.4-0.17,0.82-0.19,1.16-0.05c0.29,0.12,0.5,0.35,0.62,0.65
							c0.24,0.62,0.54,0.99,0.9,1.1c0.42,0.14,0.96-0.03,1.75-0.55c0.5-0.33,1.11-0.64,1.79-0.52c3.8,0.65,6.43,0.01,9.39-2.29
							c1.26-0.97,3.3-1.75,4.92-0.72c1.77,1.12,2.58,0.75,3.82-0.57c0.4-0.43,0.93-0.45,1.44-0.46c0.39-0.01,0.78-0.03,1.13-0.22
							c0.54-0.31,0.9-0.19,1.11-0.05c0.36,0.25,0.54,0.83,0.5,1.52c0.13-0.06,0.28-0.1,0.45-0.14c0.74-0.14,1.15-0.44,1.34-0.64
							c0.16-0.37,0.27-0.36,0.35-0.35l0.16,0.02l0.01,0.17c0,0.08-0.06,0.21-0.18,0.36c-0.02,0.06-0.05,0.14-0.08,0.23l-0.08,0.23
							c-0.16,0.47-0.34,0.99-0.15,1.28c0.09,0.14,0.28,0.24,0.57,0.27c0.27,0.03,0.57,0.09,0.88,0.15c1.52,0.28,2.63,0.41,3-0.59
							c0.13-0.34,0.3-0.52,0.51-0.55c0.03-0.01,0.07-0.01,0.1,0l-0.01-0.03c-0.03-0.09-0.07-0.18-0.11-0.27
							c-0.22-0.53-0.55-1.34,0.48-1.82c1.27-0.59,1.96,0.2,2.37,0.84c0.15,0.24,0.26,0.21,0.7-0.01c0.32-0.16,0.72-0.36,1.19-0.3
							c1.1,0.14,1.69-0.25,2.01-0.46c0.17-0.11,0.31-0.2,0.46-0.12c0.18,0.11,0.17,0.27,0.1,1.16l-0.02,0.21
							c-0.05,0.65,0.06,1.1,0.32,1.35c0.27,0.27,0.77,0.35,1.49,0.28c0.87-0.09,1.72-0.36,2.53-0.61c0.71-0.22,1.44-0.45,2.19-0.57
							c1.26-0.19,2.41,0.12,3.43,0.39c1.48,0.4,2.65,0.71,3.67-0.51c1.09-1.31,2.14-1.9,3.42-1.87c1.56,0.02,3.21-0.11,4.71-1.15
							c0.75-0.53,1.38-0.57,1.87-0.14c0.57,0.5,0.37,1.14,0.2,1.7l-0.04,0.14c-0.12,0.4-0.3,0.98-0.32,1.22
							c0.08-0.03,0.18-0.07,0.29-0.12c0.54-0.24,1.55-0.68,3-0.5l0.09,0.01c0.31,0.04,0.67,0.08,0.93,0.26
							c0.34,0.23,0.63,0.32,0.87,0.26c0.26-0.06,0.52-0.29,0.77-0.71c0.91-1.49,2.36-1.42,3.8-1.12c0.19,0.04,0.38,0.12,0.55,0.2
							c0.4,0.18,0.65,0.28,0.93,0.09c1.91-1.35,4.06-0.55,6.14,0.23c1.72,0.64,3.49,1.31,5.1,0.75c0.08-0.03,0.16-0.06,0.25-0.1
							c0.33-0.14,0.79-0.33,1.17-0.09c0-0.17-0.02-0.4-0.05-0.64c-0.38-0.08-0.62-0.23-0.74-0.45c-0.17-0.33-0.05-0.77,0.35-1.36
							c0.08-0.11,0.19-0.13,0.27-0.1c0.25,0.08,0.4,0.88,0.47,1.56c0.08,0.01,0.16,0.01,0.25,0.01c0.3,0.01,0.6,0.03,0.95,0.06
							c0.18,0.02,0.37,0.03,0.58,0.05l0.31,0.02l-0.15,0.27c-0.29,0.51-0.04,0.99,0.24,1.54c0.19,0.36,0.38,0.74,0.44,1.16
							c0.06,0.38-0.34,0.71-0.69,0.99c-0.14,0.12-0.37,0.31-0.41,0.4c0.01,0,0.02,0.01,0.03,0.02c0.45,0.24,0.78,0.01,1.24-0.36
							l0.12-0.1c0.55-0.44,1.09-0.91,1.61-1.38c0.2-0.18,0.4-0.36,0.6-0.53c1.89-1.66,3.71-2.46,5.37-2.37
							c1.53,0.08,2.98,0.61,4.39,1.12c2.02,0.73,3.93,1.43,5.83,0.73c0.89-0.33,1.64-0.32,2.16,0.03c0.24,0.16,0.43,0.39,0.55,0.68
							c0.26-1.5,0.49-2.33,0.9-2.42c1.21-0.26,2.22-0.01,2.77,0.69c0.39,0.48,0.49,1.12,0.26,1.62c-0.28,0.61-0.61,1-0.81,1.23
							c-0.02,0.03-0.05,0.05-0.07,0.08c0.06,0.01,0.15,0.02,0.27,0.03c0.62,0.05,1.21-0.06,1.83-0.18c0.66-0.12,1.33-0.24,2.05-0.18
							c5.58,0.54,10.57,0.74,15.14-2.29c1.41-0.93,3-0.76,4.54-0.59c1.29,0.14,2.62,0.28,3.83-0.23c0.19-0.08,0.48,0.07,0.58,0.21
							c0.85,1.21,1.86,0.95,3.04,0.65c0.22-0.06,0.44-0.11,0.66-0.16l0.24-0.05v8.28l-0.21-0.01c-2.47-0.12-4.99-0.01-7.42,0.1
							c-2.04,0.09-4.15,0.18-6.23,0.14c-5.99-0.12-12.08,0.06-17.97,0.24c-3.85,0.11-7.83,0.23-11.74,0.26
							c-3.12,0.03-6.29-0.04-9.36-0.11c-2.57-0.05-5.23-0.11-7.84-0.11c-4.58,0-9.24-0.1-13.75-0.2
							c-7.99-0.18-16.25-0.36-24.38,0.02c-3.31,0.15-6.67,0.15-9.92,0.14c-3.73-0.01-7.6-0.01-11.4,0.22
							C455.05,547.53,454.32,547.55,453.58,547.55z M430.86,545.95c2.53,0,5.07,0.03,7.59,0.11c2.3,0.07,4.63,0.31,6.88,0.54
							c3.42,0.35,6.96,0.72,10.43,0.5c3.81-0.24,7.68-0.23,11.42-0.23c3.25,0,6.61,0.01,9.9-0.14c8.14-0.38,16.41-0.2,24.41-0.02
							c4.5,0.1,9.16,0.2,13.74,0.2c2.61,0,5.28,0.06,7.85,0.11c3.07,0.06,6.23,0.13,9.35,0.11c3.91-0.03,7.89-0.15,11.73-0.26
							c5.9-0.17,12-0.35,17.99-0.24c2.07,0.04,4.18-0.05,6.21-0.14c2.37-0.11,4.82-0.22,7.25-0.11v-7.38
							c-0.14,0.03-0.27,0.07-0.41,0.1c-1.15,0.29-2.45,0.62-3.46-0.8c-0.02-0.03-0.11-0.07-0.14-0.07
							c-1.27,0.54-2.66,0.39-3.99,0.25c-1.54-0.17-3-0.32-4.29,0.53c-4.68,3.1-9.74,2.9-15.4,2.35c-0.66-0.07-1.28,0.05-1.94,0.17
							c-0.62,0.11-1.27,0.23-1.93,0.18c-0.38-0.03-0.62-0.06-0.69-0.24c-0.07-0.17,0.05-0.32,0.23-0.51c0.2-0.23,0.5-0.57,0.75-1.13
							c0.17-0.37,0.09-0.85-0.21-1.22c-0.46-0.57-1.33-0.78-2.39-0.55c-0.18,0.08-0.41,0.92-0.66,2.47
							c-0.11,0.7-0.13,0.77-0.32,0.76c-0.19-0.01-0.2-0.21-0.2-0.29c-0.03-0.6-0.22-1.02-0.57-1.25c-0.41-0.27-1.04-0.27-1.81,0.01
							c-2.03,0.74-4.01,0.03-6.1-0.73c-1.38-0.5-2.81-1.02-4.27-1.1c-1.98-0.1-3.85,1.18-5.1,2.27c-0.2,0.18-0.4,0.35-0.6,0.53
							c-0.52,0.47-1.06,0.95-1.62,1.39l-0.12,0.09c-0.43,0.35-0.96,0.78-1.68,0.4c-0.18-0.1-0.23-0.23-0.24-0.32
							c-0.03-0.26,0.24-0.49,0.56-0.75c0.23-0.19,0.57-0.47,0.55-0.63c-0.05-0.36-0.23-0.7-0.4-1.03c-0.27-0.52-0.54-1.06-0.35-1.64
							c-0.11-0.01-0.21-0.02-0.31-0.03c-0.34-0.03-0.63-0.06-0.92-0.06c-0.07,0-0.14,0-0.2-0.01c0.03,0.35,0.04,0.6,0.04,0.61
							c0,0.27-0.01,0.49-0.19,0.55c-0.04,0.01-0.17,0.04-0.27-0.11c-0.21-0.29-0.46-0.22-0.94-0.01c-0.09,0.04-0.19,0.08-0.27,0.11
							c-1.75,0.61-3.59-0.08-5.37-0.75c-2.08-0.78-4.04-1.51-5.78-0.28c-0.47,0.34-0.94,0.12-1.32-0.05
							c-0.16-0.08-0.32-0.15-0.47-0.18c-1.21-0.25-2.56-0.4-3.38,0.94c-0.32,0.52-0.64,0.8-1.02,0.89
							c-0.35,0.08-0.74-0.03-1.17-0.32c-0.19-0.13-0.48-0.16-0.76-0.19l-0.09-0.01c-1.34-0.16-2.26,0.24-2.8,0.47
							c-0.34,0.15-0.56,0.24-0.73,0.1c-0.19-0.17-0.13-0.47,0.23-1.67l0.04-0.14c0.18-0.59,0.27-0.97-0.09-1.29
							c-0.22-0.2-0.6-0.39-1.39,0.16c-1.58,1.11-3.31,1.24-4.94,1.23c-1.14-0.02-2.09,0.5-3.11,1.73c-1.18,1.42-2.59,1.04-4.08,0.64
							c-1.03-0.28-2.09-0.56-3.27-0.38c-0.71,0.11-1.4,0.32-2.13,0.55c-0.83,0.26-1.7,0.53-2.61,0.63
							c-0.87,0.09-1.44-0.03-1.81-0.38c-0.35-0.34-0.49-0.88-0.43-1.66l0.02-0.21c0.02-0.28,0.04-0.53,0.04-0.69
							c-0.33,0.22-1.02,0.67-2.27,0.52c-0.36-0.05-0.67,0.11-0.97,0.26c-0.38,0.19-0.85,0.43-1.21-0.13
							c-0.55-0.86-1.09-1.07-1.87-0.7c-0.66,0.31-0.54,0.7-0.28,1.32c0.04,0.1,0.08,0.19,0.11,0.29l0.15,0.42
							c0.09,0.25,0.13,0.37-0.02,0.45c-0.1,0.05-0.2,0.02-0.29-0.1c-0.17-0.23-0.23-0.23-0.25-0.23c0,0-0.09,0.02-0.2,0.3
							c-0.52,1.38-2.14,1.08-3.44,0.84c-0.3-0.06-0.59-0.11-0.85-0.14c-0.41-0.05-0.7-0.2-0.86-0.45c-0.26-0.42-0.1-0.98,0.07-1.5
							c-0.27,0.15-0.61,0.28-1.04,0.36c-0.32,0.06-0.5,0.16-0.59,0.24c-0.03,0.14-0.13,0.36-0.3,0.35l-0.17-0.01l-0.03-0.2
							c-0.01-0.09,0.03-0.21,0.13-0.33c0.11-0.69-0.01-1.3-0.3-1.51c-0.17-0.12-0.4-0.1-0.69,0.07c-0.42,0.24-0.89,0.26-1.3,0.27
							c-0.47,0.02-0.88,0.03-1.17,0.34c-1.35,1.43-2.34,1.89-4.32,0.64c-1.36-0.86-3.22-0.27-4.47,0.7
							c-3.02,2.34-5.84,3.03-9.7,2.36c-0.55-0.09-1.05,0.17-1.51,0.47c-0.65,0.42-1.4,0.81-2.09,0.59
							c-0.48-0.16-0.86-0.59-1.15-1.34c-0.08-0.21-0.22-0.35-0.4-0.43c-0.24-0.1-0.55-0.08-0.85,0.05
							c-2.21,0.97-4.56,1.15-6.84,1.33c-1.3,0.1-2.65,0.2-3.94,0.45c-0.6,0.12-1.16,0.34-1.71,0.56c-0.39,0.16-0.79,0.32-1.21,0.44
							c-0.18,0.05-0.34,0.03-0.46-0.06c-0.28-0.21-0.28-0.69-0.29-1.36c0-0.49-0.01-1.03-0.13-1.5c-1.31,0.4-3.3,1.81-4.91,2.95
							c-0.68,0.48-1.32,0.94-1.83,1.26c0,0.01,0.01,0.02,0.01,0.03l2.89-0.14c2.2-0.24,4.45-0.2,6.63-0.15
							c0.81,0.02,1.62,0.03,2.42,0.03c2.07,0,4.15-0.02,6.22-0.04C426.33,545.97,428.59,545.95,430.86,545.95z M455.15,540.2
							L455.15,540.2L455.15,540.2z M516.79,536.74c-0.19,0.32-0.25,0.57-0.17,0.72c0.04,0.08,0.13,0.16,0.34,0.22
							C516.91,537.31,516.84,536.96,516.79,536.74z"/>
					</g>
					<g>
						<path className="st84" d="M575.81,540.95c-1.37,0.18-2.8,0.64-3.9-0.37c-0.06-0.06-0.26-0.12-0.35-0.09
							c-2.71,0.74-5.84-0.56-8.34,0.52c-4.79,2.06-10,1.84-15.27,1.51c-1.34-0.08-2.56,0.29-3.88,0.23
							c-1.13-0.05-1.76-0.12-1.12-1.04c0.43-0.61,1.11-1.65-1.24-1.32c-0.61,0.08-0.91-0.02-0.95-0.52
							c-0.08-0.99-1.15-1.27-2.64-0.92c-3.32,0.79-6.62,1.48-10.3,1.35c-1.88-0.06-3.73,0.64-5.24,1.5
							c-0.73,0.42-1.45,0.85-2.21,1.24c-0.44,0.23-0.93,0.53-1.58,0.31c-0.62-0.21-0.52-0.63-0.6-1.01
							c-0.15-0.67,0.36-1.2,0.91-1.83c-0.63-0.03-1.07-0.07-1.52-0.07c-1.23-0.01-1.56-0.32-0.82-1.01c0.25-0.24,0.66-0.53,0.36-0.8
							c-0.39-0.35-0.99-0.09-1.44,0.02c-3.65,0.82-7.83,0.35-11.19,1.89c-0.56,0.26-1.09-0.09-1.64-0.17
							c-1.33-0.18-2.72-0.25-3.59,0.67c-0.5,0.53-1.06,0.71-1.92,0.33c-0.25-0.11-0.61-0.13-0.93-0.15
							c-3.11-0.25-4.04-1.15-3.13-3.1c0.17-0.38,0.42-0.76-0.1-1.06c-0.53-0.31-1.17-0.12-1.63,0.1c-1.47,0.67-3.08,0.79-4.82,0.77
							c-1.35-0.01-2.34,0.44-3.27,1.17c-1.85,1.44-4.22,2.37-7.23,2.67c-1.58,0.16-3.07,0.65-4.73,0.77
							c-1.44,0.1-2.14-0.19-2.03-1.19c0.18-1.61,0.09-1.71-2.38-1.91c-0.95-0.08-1.57-0.4-2.03-0.88c-0.53-0.54-1.16-0.79-2.12-0.5
							c-1.01,0.31-0.49,0.8-0.27,1.2c0.59,1.07-0.1,2.17-0.6,3.01c-0.57,0.98-2.69,0.48-4.09,0.37c-1.27-0.1-0.76-0.81-0.51-1.33
							c0.41-0.83,0.54-1.47-1.38-1.23c-1.14,0.14-0.85-0.38-0.73-0.81c0.2-0.74-0.2-0.98-1.28-0.59c-0.85,0.31-1.89,0.49-2.52,0.93
							c-1.27,0.88-2.17,1.17-4.07,0.39c-1.44-0.59-3.37,0.3-4.69,0.97c-2.85,1.43-5.54,2.97-9.55,2.53
							c-0.6-0.07-1.14,0.11-1.65,0.32c-1.18,0.49-2.27,0.72-2.94-0.42c-0.25-0.42-0.94-0.48-1.52-0.32
							c-3.43,0.98-7.18,0.7-10.74,1.15c-1.06,0.13-1.95,0.47-2.93,0.66c-0.84,0.16-1.83,0.41-2.27-0.43
							c0.42-0.38,0.84-0.76,1.26-1.13c1.06-0.56,1.98-1.27,3.45-1.39c0.69,0.99,1.7,1.29,3.29,0.77c1.34-0.44,2.83-0.22,4.25-0.2
							c2.57,0.03,4.92-0.33,6.88-1.45c0.85-0.48,1.77-0.68,2.87-0.61c2.22,0.14,4.2-0.57,6.22-0.92c3-0.52,5.97-0.75,9.02-0.84
							c0.82-0.02,1.5-0.24,2.16-0.52c1.79-0.78,3.93-0.86,5.92-1.24c1.35-0.26,1.26,0.84,2.18,1.01c0.88-1.61,2.66-2.28,5.27-1.93
							c0.79,0.1,1.53,0.1,2.31-0.02c1.71-0.27,3.22-0.36,4.95,0.41c1.51,0.67,3.66,0.33,5.52,0.1c1.92-0.23,3.65-0.44,5.64,0.23
							c1.84,0.61,3.93-0.25,5.81-0.62c2.4-0.47,4.71-0.92,7.26-0.56c0.72,0.1,1.53-0.12,2.31-0.14c0.64-0.02,1.48-0.09,1.51,0.51
							c0.03,0.81,1.06,0.65,1.62,0.94c1.53,0.79,2.97,0.29,4.44,0.31c1.45,0.02,2.9-0.26,4.32,0.35c0.99,0.43,2.01-0.12,2.87-0.47
							c1.23-0.51,2.48-0.87,3.97-0.98c1.05-0.08,1.74,0.19,2.65,0.41c1.89,0.46,3.38,1.47,5.46,1.61c0.32,0.02,0.72,0.11,0.95,0.03
							c2.74-1.03,5.86-0.58,8.76-0.99c0.5-0.07,1.04-0.17,1.45-0.35c1.27-0.57,2.49-0.84,3.95-0.21c0.4,0.17,1.18,0.1,1.71-0.01
							c1.08-0.22,1.9-0.06,2.62,0.48c0.38,0.29,0.81,0.64,1.43,0.6c1.46-0.08,2.42,0.2,2.96,1.16c0.26,0.45,0.99,0.37,1.69,0.15
							c1.94-0.62,2.77-1.69,3.35-2.96c0.69-1.51,2.81-1.94,5.05-1.32c2.42,0.68,4.97,0.88,7.47,0.07c1.32-0.43,2.43-0.72,1.99,0.78
							c-0.18,0.64,0.34,1,1.38,0.77c1.24-0.28,2.12-0.1,2.8,0.65c0.84,0.91,2.16,0.97,3.6,0.73
							C575.81,538.75,575.81,539.85,575.81,540.95z"/>
						<path className="st8" d="M412.33,545.14c-0.48,0-0.93-0.14-1.19-0.64l-0.03-0.07l1.32-1.18c0.24-0.13,0.46-0.25,0.67-0.37
							c0.81-0.46,1.65-0.94,2.84-1.04l0.06,0l0.03,0.05c0.68,0.99,1.69,1.22,3.18,0.73c1.04-0.34,2.17-0.29,3.27-0.24
							c0.33,0.01,0.68,0.03,1.01,0.03c0.09,0,0.17,0,0.26,0c2.72,0,4.88-0.47,6.58-1.43c0.87-0.5,1.8-0.69,2.92-0.62
							c1.55,0.09,3.02-0.23,4.43-0.55c0.58-0.13,1.18-0.27,1.77-0.37c2.77-0.48,5.64-0.75,9.03-0.85c0.89-0.03,1.61-0.29,2.13-0.52
							c1.26-0.55,2.71-0.75,4.12-0.95c0.6-0.09,1.23-0.17,1.82-0.29c0.84-0.16,1.18,0.2,1.48,0.52c0.19,0.2,0.37,0.4,0.67,0.47
							c0.92-1.62,2.71-2.26,5.33-1.92c0.8,0.11,1.52,0.1,2.28-0.02c1.85-0.29,3.34-0.33,5,0.42c1.35,0.61,3.29,0.36,5,0.15
							l0.47-0.06c1.88-0.23,3.66-0.44,5.69,0.23c1.37,0.46,2.9,0.07,4.37-0.3c0.46-0.12,0.94-0.24,1.39-0.32l0.05-0.01
							c2.41-0.47,4.69-0.92,7.24-0.55c0.44,0.06,0.9,0,1.4-0.06c0.29-0.04,0.6-0.08,0.89-0.09l0.1,0c0.62-0.02,1.48-0.06,1.51,0.61
							c0.02,0.45,0.36,0.55,0.91,0.67c0.23,0.05,0.46,0.1,0.65,0.19c1.07,0.55,2.06,0.46,3.12,0.37c0.41-0.04,0.84-0.08,1.27-0.07
							c0.36,0.01,0.73-0.01,1.09-0.02c1.07-0.04,2.18-0.09,3.26,0.39c0.84,0.36,1.72-0.01,2.49-0.35c0.1-0.04,0.21-0.09,0.31-0.13
							c1.4-0.58,2.64-0.88,4-0.98c0.88-0.07,1.51,0.11,2.19,0.29c0.16,0.04,0.32,0.08,0.49,0.13c0.74,0.18,1.43,0.45,2.09,0.71
							c1.03,0.4,2.09,0.82,3.35,0.9c0.09,0.01,0.18,0.02,0.28,0.03c0.24,0.03,0.49,0.06,0.63,0c1.75-0.65,3.67-0.71,5.53-0.77
							c1.08-0.03,2.19-0.07,3.25-0.22c0.44-0.06,1-0.16,1.42-0.35c1.15-0.51,2.44-0.89,4.03-0.21c0.37,0.16,1.12,0.09,1.65-0.02
							c1.1-0.22,1.95-0.06,2.69,0.5l0.04,0.03c0.36,0.27,0.77,0.59,1.33,0.55c1.61-0.08,2.52,0.27,3.05,1.21
							c0.25,0.45,1.09,0.26,1.57,0.1c2.01-0.64,2.77-1.77,3.29-2.91c0.69-1.49,2.76-2.04,5.16-1.37c2.75,0.77,5.18,0.8,7.41,0.07
							c0.94-0.31,1.7-0.51,2.04-0.18c0.2,0.2,0.23,0.55,0.08,1.08c-0.04,0.15-0.06,0.36,0.08,0.52c0.2,0.21,0.63,0.25,1.19,0.13
							c1.31-0.29,2.2-0.08,2.9,0.68c0.7,0.76,1.81,0.98,3.51,0.7l0.11-0.02v3.51l-0.09,0.01c-0.22,0.03-0.43,0.06-0.65,0.1
							c-1.16,0.19-2.37,0.39-3.33-0.5c-0.04-0.04-0.21-0.08-0.25-0.07c-1.27,0.35-2.64,0.25-3.96,0.16
							c-1.57-0.11-3.04-0.21-4.37,0.36c-4.65,2-9.68,1.87-15.31,1.51c-0.68-0.04-1.34,0.04-1.98,0.11c-0.61,0.07-1.24,0.15-1.9,0.12
							c-0.79-0.04-1.29-0.08-1.43-0.38c-0.09-0.19-0.02-0.45,0.24-0.82c0.19-0.26,0.53-0.76,0.38-1.01
							c-0.1-0.16-0.44-0.31-1.53-0.16c-0.42,0.06-0.67,0.03-0.84-0.11c-0.13-0.1-0.2-0.27-0.22-0.5c-0.02-0.3-0.15-0.54-0.37-0.7
							c-0.42-0.31-1.18-0.35-2.15-0.13c-3.32,0.79-6.61,1.48-10.32,1.36c-1.58-0.05-3.38,0.46-5.18,1.49
							c-0.2,0.11-0.4,0.23-0.59,0.34c-0.52,0.3-1.06,0.62-1.62,0.9l-0.12,0.06c-0.42,0.22-0.9,0.47-1.54,0.25
							c-0.55-0.19-0.59-0.54-0.63-0.85c-0.01-0.08-0.02-0.15-0.03-0.22c-0.15-0.66,0.3-1.2,0.8-1.76c-0.13-0.01-0.26-0.01-0.38-0.02
							c-0.34-0.02-0.64-0.04-0.93-0.04c-0.78-0.01-1.19-0.13-1.29-0.39c-0.08-0.21,0.05-0.47,0.4-0.79c0.03-0.03,0.06-0.06,0.1-0.09
							c0.15-0.14,0.34-0.31,0.34-0.44c0-0.04-0.02-0.09-0.07-0.13c-0.28-0.25-0.69-0.14-1.09-0.03c-0.09,0.03-0.18,0.05-0.26,0.07
							c-1.43,0.32-2.97,0.45-4.45,0.57c-2.3,0.19-4.68,0.38-6.72,1.32c-0.42,0.19-0.81,0.07-1.19-0.04c-0.16-0.05-0.33-0.1-0.5-0.12
							c-1.25-0.17-2.65-0.27-3.5,0.64c-0.57,0.61-1.2,0.71-2.03,0.35c-0.21-0.09-0.53-0.12-0.81-0.14l-0.09-0.01
							c-1.89-0.15-2.96-0.54-3.37-1.24c-0.29-0.5-0.24-1.15,0.15-2l0.04-0.09c0.19-0.39,0.27-0.62-0.1-0.84
							c-0.39-0.23-0.89-0.19-1.54,0.1c-1.55,0.71-3.25,0.79-4.86,0.78c-0.02,0-0.03,0-0.05,0c-1.17,0-2.14,0.35-3.16,1.15
							c-1.92,1.5-4.37,2.4-7.28,2.69c-0.72,0.07-1.42,0.21-2.15,0.36c-0.83,0.17-1.68,0.34-2.58,0.41
							c-0.98,0.07-1.58-0.05-1.89-0.37c-0.21-0.22-0.29-0.52-0.24-0.92c0.08-0.67,0.11-1.09-0.08-1.32
							c-0.24-0.29-0.86-0.37-2.21-0.48c-0.9-0.07-1.56-0.36-2.1-0.91c-0.57-0.58-1.19-0.73-2.02-0.47c-0.3,0.09-0.47,0.2-0.52,0.33
							c-0.06,0.15,0.07,0.35,0.19,0.54c0.04,0.06,0.08,0.13,0.11,0.19c0.59,1.08-0.03,2.14-0.54,3l-0.06,0.11
							c-0.5,0.85-2,0.67-3.32,0.51c-0.31-0.04-0.59-0.07-0.86-0.09c-0.46-0.04-0.74-0.16-0.85-0.36c-0.16-0.28,0.03-0.64,0.19-0.95
							c0.03-0.05,0.05-0.1,0.08-0.15c0.18-0.36,0.33-0.72,0.18-0.92c-0.15-0.21-0.64-0.27-1.46-0.17c-0.47,0.06-0.75,0.01-0.88-0.15
							c-0.14-0.17-0.07-0.41,0-0.65c0.01-0.05,0.03-0.09,0.04-0.14c0.08-0.28,0.06-0.47-0.04-0.56c-0.11-0.1-0.39-0.17-1.1,0.09
							c-0.28,0.1-0.59,0.19-0.88,0.28c-0.59,0.17-1.21,0.35-1.62,0.64c-1.33,0.92-2.24,1.19-4.16,0.4c-1.3-0.54-2.98,0.15-4.61,0.96
							l-0.57,0.29c-2.71,1.37-5.26,2.67-9.03,2.25c-0.59-0.06-1.12,0.11-1.6,0.31c-1.16,0.49-2.33,0.77-3.06-0.46
							c-0.23-0.38-0.88-0.43-1.4-0.28c-2.19,0.62-4.53,0.74-6.8,0.85c-1.31,0.06-2.65,0.13-3.96,0.29
							c-0.61,0.08-1.18,0.23-1.73,0.37c-0.39,0.1-0.79,0.21-1.2,0.29l-0.16,0.03C413.04,545.07,412.68,545.14,412.33,545.14z
							 M411.35,544.47c0.39,0.65,1.19,0.49,1.97,0.34l0.16-0.03c0.41-0.08,0.8-0.18,1.19-0.28c0.55-0.15,1.13-0.3,1.75-0.38
							c1.31-0.16,2.66-0.23,3.97-0.3c2.26-0.11,4.59-0.23,6.76-0.85c0.58-0.17,1.34-0.12,1.63,0.36c0.62,1.04,1.57,0.91,2.82,0.38
							c0.48-0.2,1.06-0.4,1.7-0.33c3.72,0.41,6.24-0.87,8.92-2.23l0.57-0.29c1.67-0.84,3.4-1.53,4.77-0.97
							c1.83,0.75,2.71,0.49,3.97-0.38c0.44-0.3,1.07-0.49,1.67-0.67c0.29-0.09,0.59-0.17,0.87-0.27c0.66-0.24,1.08-0.26,1.3-0.05
							c0.16,0.15,0.2,0.4,0.1,0.76c-0.01,0.05-0.03,0.1-0.04,0.15c-0.06,0.18-0.12,0.37-0.04,0.46c0.06,0.07,0.23,0.13,0.71,0.08
							c0.92-0.11,1.44-0.03,1.65,0.25c0.21,0.3,0.02,0.73-0.17,1.12c-0.02,0.05-0.05,0.1-0.08,0.15c-0.14,0.27-0.3,0.58-0.19,0.77
							c0.08,0.14,0.32,0.23,0.7,0.26c0.27,0.02,0.56,0.06,0.87,0.09c1.26,0.15,2.7,0.33,3.13-0.41l0.06-0.11
							c0.48-0.82,1.07-1.83,0.53-2.81c-0.03-0.06-0.07-0.11-0.11-0.17c-0.14-0.22-0.3-0.47-0.21-0.72c0.07-0.19,0.28-0.34,0.64-0.45
							c0.91-0.28,1.59-0.11,2.22,0.52c0.5,0.51,1.13,0.78,1.97,0.85c1.44,0.12,2.06,0.2,2.34,0.55c0.24,0.3,0.21,0.75,0.13,1.47
							c-0.04,0.34,0.02,0.59,0.19,0.77c0.27,0.28,0.82,0.38,1.74,0.31c0.89-0.06,1.74-0.24,2.56-0.4c0.71-0.14,1.44-0.29,2.17-0.36
							c2.87-0.28,5.29-1.18,7.18-2.65c1.05-0.82,2.06-1.19,3.28-1.19c0.11,0,0.22,0,0.33,0c1.5,0,3.07-0.11,4.5-0.76
							c0.71-0.32,1.27-0.35,1.72-0.09c0.55,0.32,0.35,0.75,0.18,1.1l-0.04,0.09c-0.36,0.79-0.42,1.38-0.16,1.82
							c0.37,0.63,1.39,1,3.21,1.14l0.09,0.01c0.3,0.02,0.63,0.05,0.87,0.16c0.76,0.34,1.29,0.25,1.81-0.3
							c0.92-0.98,2.38-0.87,3.67-0.7c0.18,0.02,0.36,0.08,0.53,0.13c0.36,0.11,0.71,0.21,1.05,0.05c2.07-0.95,4.47-1.15,6.79-1.34
							c1.48-0.12,3.01-0.25,4.43-0.57c0.08-0.02,0.17-0.04,0.25-0.07c0.41-0.11,0.91-0.25,1.27,0.07c0.09,0.08,0.14,0.17,0.14,0.28
							c0,0.22-0.21,0.41-0.4,0.59c-0.03,0.03-0.06,0.06-0.09,0.08c-0.27,0.26-0.4,0.46-0.35,0.58c0.04,0.09,0.23,0.25,1.11,0.26
							c0.3,0,0.6,0.02,0.94,0.04c0.18,0.01,0.37,0.02,0.58,0.03l0.2,0.01l-0.23,0.26c-0.5,0.57-0.93,1.05-0.8,1.64
							c0.02,0.08,0.03,0.16,0.04,0.25c0.04,0.3,0.06,0.54,0.5,0.69c0.56,0.19,0.98-0.03,1.38-0.24l0.12-0.06
							c0.55-0.28,1.09-0.6,1.61-0.9c0.2-0.12,0.4-0.23,0.6-0.34c1.83-1.05,3.66-1.57,5.29-1.52c3.7,0.13,6.97-0.57,10.27-1.35
							c1.02-0.24,1.84-0.18,2.31,0.16c0.27,0.2,0.43,0.48,0.45,0.85c0.01,0.17,0.06,0.29,0.15,0.36c0.12,0.1,0.34,0.12,0.69,0.07
							c0.97-0.14,1.53-0.05,1.72,0.25c0.22,0.36-0.16,0.9-0.38,1.22c-0.21,0.29-0.28,0.5-0.22,0.62c0.11,0.22,0.72,0.24,1.26,0.27
							c0.64,0.03,1.26-0.04,1.87-0.12c0.65-0.08,1.31-0.16,2.01-0.11c5.6,0.35,10.62,0.48,15.22-1.5c1.37-0.59,2.94-0.48,4.46-0.37
							c1.31,0.09,2.66,0.19,3.9-0.15c0.12-0.03,0.35,0.04,0.44,0.12c0.89,0.82,2,0.64,3.17,0.45c0.19-0.03,0.38-0.06,0.57-0.09v-3.1
							c-1.7,0.26-2.84,0.01-3.57-0.78c-0.64-0.7-1.48-0.89-2.71-0.62c-0.64,0.14-1.12,0.08-1.37-0.19
							c-0.16-0.18-0.21-0.42-0.13-0.71c0.13-0.45,0.12-0.75-0.02-0.89c-0.27-0.26-1.03-0.04-1.85,0.22
							c-2.27,0.73-4.73,0.71-7.53-0.07c-2.3-0.65-4.28-0.14-4.93,1.26c-0.51,1.12-1.32,2.35-3.41,3.01c-0.94,0.3-1.56,0.23-1.8-0.19
							c-0.49-0.86-1.35-1.2-2.87-1.11c-0.64,0.03-1.08-0.3-1.46-0.59l-0.04-0.03c-0.7-0.54-1.49-0.68-2.53-0.47
							c-0.58,0.12-1.35,0.18-1.77,0c-1.53-0.65-2.76-0.28-3.88,0.21c-0.45,0.2-1.02,0.3-1.48,0.36c-1.07,0.15-2.19,0.19-3.27,0.22
							c-1.84,0.06-3.75,0.12-5.46,0.76c-0.19,0.07-0.46,0.04-0.73,0.01c-0.09-0.01-0.18-0.02-0.27-0.03
							c-1.29-0.09-2.37-0.51-3.41-0.91c-0.66-0.26-1.34-0.52-2.07-0.7c-0.17-0.04-0.33-0.09-0.49-0.13
							c-0.69-0.18-1.28-0.34-2.13-0.28c-1.34,0.1-2.55,0.4-3.94,0.97c-0.1,0.04-0.2,0.08-0.3,0.13c-0.81,0.35-1.73,0.74-2.65,0.35
							c-1.04-0.45-2.08-0.41-3.18-0.37c-0.36,0.01-0.73,0.03-1.1,0.02c-0.41-0.01-0.84,0.03-1.25,0.07
							c-1.04,0.09-2.11,0.19-3.23-0.39c-0.17-0.09-0.39-0.13-0.6-0.18c-0.49-0.1-1.04-0.22-1.07-0.85
							c-0.02-0.43-0.58-0.45-1.31-0.42l-0.1,0c-0.29,0.01-0.58,0.05-0.87,0.08c-0.49,0.06-0.99,0.13-1.45,0.06
							c-2.51-0.36-4.78,0.08-7.17,0.55l-0.05,0.01c-0.45,0.09-0.9,0.2-1.38,0.32c-1.5,0.38-3.06,0.77-4.48,0.3
							c-1.98-0.66-3.74-0.45-5.6-0.22l-0.47,0.06c-1.73,0.22-3.7,0.46-5.1-0.17c-1.63-0.73-3.03-0.7-4.89-0.4
							c-0.77,0.12-1.52,0.13-2.34,0.02c-2.57-0.34-4.31,0.3-5.17,1.88l-0.03,0.06l-0.07-0.01c-0.41-0.07-0.63-0.31-0.85-0.55
							c-0.3-0.32-0.56-0.6-1.3-0.46c-0.6,0.12-1.23,0.2-1.83,0.29c-1.39,0.2-2.83,0.4-4.07,0.94c-0.54,0.23-1.27,0.5-2.2,0.53
							c-3.39,0.1-6.25,0.37-9.01,0.84c-0.59,0.1-1.18,0.24-1.76,0.37c-1.43,0.32-2.9,0.66-4.48,0.56c-1.08-0.07-1.97,0.12-2.81,0.6
							c-1.78,1.01-4.05,1.48-6.93,1.46c-0.34,0-0.68-0.02-1.02-0.03c-1.08-0.05-2.19-0.1-3.2,0.23c-1.56,0.51-2.63,0.27-3.37-0.76
							c-1.12,0.11-1.89,0.54-2.71,1.01c-0.22,0.12-0.43,0.25-0.66,0.36L411.35,544.47z"/>
					</g>
					<g>
						<path className="st59" d="M447.83,483.56c-0.45-0.92,0.36-1.36,0.75-1.95c0.12-0.02,0.24-0.04,0.36-0.09
							c2.68-0.55,5.36-1.1,8.04-1.65c0.1,0.81,0.51,1.17,1.38,1.34c1.87,0.37,3.76,0.27,5.64,0.42c6.15,0.5,12.29,0.98,18.44,1.5
							c2.52,0.21,5.03,0.51,7.55,0.77c0.43,5.99,0.34,12,0.5,18.01c0,0.8-0.01,1.59-0.01,2.39c0,4.56,0,4.56,0,4.56
							c-0.33-0.07,0.48,2.95-0.68,2.13l-1.04,0.62c-0.44,0.22-0.7-0.32-1.11-0.62c-1.37-0.99-1.46-3.92-2.54-3.92
							c-1.48,0-1.7-1.51-3.66-2.45c-8.68,0.26-5.96,6.92-6.57,6.68c-5.61-2.26-3.48,2.65-5.29,4.08c-1.76,1.39-3.74,1.91-5.72,2.59
							c-0.12,0.04-0.32,0.03-0.37,0.11c-1.44,2.41-3.8,1.09-5.75,1.42c-0.07-2.6-0.15-5.19-0.21-7.79
							c-0.19-9.49,0.13-18.98-0.34-28.47c-0.07-1.52-0.53-1.96-2-1.55C452.76,482.36,450.29,482.94,447.83,483.56z M475.39,498.64
							c-0.1-0.7,0.17-1.7-0.97-1.68c-0.96,0.02-0.85,0.91-0.84,1.56c0.01,0.7-0.26,1.72,0.83,1.79
							C475.54,500.38,475.29,499.35,475.39,498.64z M472.33,489.57c-0.08-0.88,0.14-1.96-1.05-2.04c-0.62-0.04-0.87,0.56-0.68,1.11
							c0.32,0.94-0.11,2.5,1.13,2.66C472.55,491.4,472.21,490.2,472.33,489.57z"/>
						<path className="st23" d="M460.74,519.66c-0.37,0-0.76-0.03-1.13-0.07c-0.62-0.05-1.26-0.11-1.84-0.01l-0.11,0.02l0-0.11
							c-0.02-0.85-0.05-1.7-0.07-2.55c-0.05-1.72-0.1-3.49-0.13-5.24c-0.07-3.72-0.07-7.5-0.07-11.16
							c0.01-5.67,0.01-11.54-0.27-17.3c-0.04-0.8-0.18-1.24-0.47-1.45c-0.27-0.2-0.72-0.2-1.41-0.01c-1.72,0.48-3.47,0.91-5.17,1.33
							c-0.73,0.18-1.46,0.36-2.2,0.55l-0.08,0.02l-0.04-0.07c-0.37-0.75,0.05-1.22,0.43-1.64c0.12-0.13,0.24-0.27,0.34-0.41
							l0.03-0.04l0.05-0.01c0.11-0.01,0.23-0.04,0.33-0.08l8.16-1.68l0.01,0.1c0.1,0.75,0.45,1.09,1.3,1.26
							c1.21,0.24,2.45,0.28,3.65,0.32c0.65,0.02,1.32,0.05,1.97,0.1c1.65,0.13,3.3,0.26,4.95,0.4c4.42,0.35,9,0.72,13.49,1.1
							c1.73,0.15,3.49,0.34,5.19,0.52c0.79,0.08,1.57,0.17,2.36,0.25l0.08,0.01l0.01,0.08c0.29,4.02,0.34,8.11,0.39,12.06
							c0.03,1.95,0.05,3.97,0.11,5.95l-0.01,2.39l0,4.68l-0.08-0.02c-0.02,0.11,0,0.41,0.01,0.66c0.05,0.65,0.1,1.39-0.23,1.57
							c-0.13,0.07-0.3,0.05-0.5-0.08l-0.99,0.59c-0.38,0.19-0.64-0.07-0.89-0.33c-0.1-0.1-0.21-0.21-0.33-0.3
							c-0.69-0.5-1.07-1.47-1.39-2.32c-0.33-0.85-0.61-1.58-1.09-1.58c-0.85,0-1.31-0.48-1.84-1.03c-0.45-0.47-0.97-1-1.83-1.42
							c-2.48,0.08-4.27,0.7-5.31,1.84c-1.25,1.37-1.13,3.16-1.06,4.11c0.03,0.43,0.04,0.62-0.07,0.7c-0.02,0.02-0.08,0.04-0.15,0.01
							c-1.52-0.61-2.56-0.73-3.2-0.38c-0.68,0.38-0.85,1.25-1.02,2.18c-0.16,0.87-0.33,1.77-0.97,2.28
							c-1.51,1.2-3.22,1.76-4.88,2.31c-0.29,0.1-0.58,0.19-0.87,0.29c-0.05,0.02-0.11,0.03-0.17,0.04
							c-0.05,0.01-0.13,0.02-0.15,0.04C462.82,519.4,461.8,519.66,460.74,519.66z M458.49,519.33c0.38,0,0.76,0.03,1.13,0.07
							c1.45,0.12,2.82,0.24,3.79-1.38c0.05-0.09,0.17-0.11,0.29-0.13c0.05-0.01,0.09-0.01,0.13-0.03c0.29-0.1,0.58-0.2,0.87-0.29
							c1.72-0.57,3.33-1.1,4.82-2.28c0.58-0.46,0.74-1.32,0.89-2.16c0.17-0.93,0.35-1.89,1.12-2.31c0.69-0.38,1.78-0.26,3.33,0.36
							c0.01-0.09,0-0.31-0.01-0.5c-0.07-0.99-0.19-2.82,1.11-4.26c1.08-1.19,2.92-1.83,5.47-1.91l0.02,0l0.02,0.01
							c0.91,0.44,1.45,0.98,1.91,1.47c0.53,0.54,0.94,0.97,1.7,0.97c0.62,0,0.92,0.79,1.27,1.71c0.32,0.83,0.68,1.76,1.33,2.23
							c0.13,0.09,0.24,0.21,0.35,0.32c0.26,0.27,0.42,0.41,0.66,0.29l1.09-0.65l0.05,0.04c0.11,0.08,0.25,0.16,0.34,0.11
							c0.21-0.12,0.16-0.88,0.13-1.39c-0.04-0.52-0.05-0.75,0.06-0.84c0,0,0,0,0,0l0-4.49l0.01-2.39c-0.05-1.98-0.08-4-0.11-5.95
							c-0.05-3.92-0.1-7.98-0.39-11.97c-0.76-0.08-1.52-0.16-2.28-0.24c-1.7-0.18-3.46-0.37-5.19-0.52
							c-4.5-0.38-9.07-0.75-13.49-1.1c-1.65-0.13-3.3-0.26-4.95-0.4c-0.65-0.05-1.32-0.08-1.97-0.1c-1.21-0.04-2.46-0.09-3.68-0.32
							c-0.89-0.17-1.31-0.56-1.44-1.32l-7.94,1.63c-0.1,0.04-0.21,0.07-0.32,0.08c-0.1,0.14-0.21,0.27-0.33,0.4
							c-0.36,0.41-0.68,0.76-0.43,1.35c0.7-0.18,1.41-0.35,2.12-0.53c1.7-0.42,3.46-0.85,5.17-1.33c0.76-0.21,1.24-0.2,1.57,0.04
							c0.35,0.25,0.51,0.73,0.55,1.6c0.28,5.77,0.28,11.64,0.27,17.31c0,3.66-0.01,7.44,0.07,11.16c0.04,1.74,0.09,3.52,0.13,5.23
							c0.02,0.81,0.05,1.63,0.07,2.44C458.06,519.34,458.27,519.33,458.49,519.33z M474.51,500.41c-0.04,0-0.08,0-0.11,0
							c-1-0.06-0.96-0.86-0.93-1.51c0.01-0.13,0.01-0.26,0.01-0.38l0-0.16c-0.01-0.44-0.03-0.94,0.26-1.24
							c0.15-0.16,0.38-0.25,0.68-0.26c0.01,0,0.03,0,0.04,0c0.96,0,0.99,0.73,1,1.32c0.01,0.16,0.01,0.31,0.03,0.45l0,0.01l0,0.01
							c-0.02,0.14-0.03,0.29-0.03,0.45c-0.02,0.42-0.03,0.85-0.31,1.1C474.99,500.34,474.78,500.41,474.51,500.41z M474.45,497.06
							c-0.01,0-0.02,0-0.04,0c-0.25,0-0.42,0.07-0.54,0.19c-0.23,0.24-0.22,0.7-0.2,1.1l0,0.16c0,0.12,0,0.25-0.01,0.39
							c-0.03,0.65-0.06,1.25,0.75,1.31c0.27,0.01,0.47-0.03,0.6-0.15c0.22-0.2,0.23-0.59,0.25-0.97c0.01-0.15,0.01-0.31,0.03-0.45
							c-0.02-0.15-0.02-0.3-0.03-0.46C475.24,497.58,475.22,497.06,474.45,497.06z M471.83,491.4c-0.03,0-0.07,0-0.11-0.01
							c-0.93-0.12-1-0.99-1.06-1.76c-0.03-0.34-0.05-0.69-0.15-0.97c-0.12-0.35-0.08-0.72,0.11-0.96c0.15-0.2,0.39-0.3,0.67-0.28
							c1.07,0.07,1.09,0.92,1.12,1.67c0,0.16,0.01,0.31,0.02,0.46l0,0.01l0,0.01c-0.03,0.17-0.03,0.39-0.03,0.62
							c0,0.43,0.01,0.87-0.23,1.08C472.08,491.36,471.97,491.4,471.83,491.4z M471.23,487.62c-0.19,0-0.34,0.07-0.45,0.21
							c-0.15,0.19-0.18,0.49-0.08,0.78c0.1,0.3,0.13,0.66,0.16,1.01c0.06,0.76,0.12,1.48,0.89,1.58c0.13,0.02,0.23,0,0.29-0.06
							c0.17-0.15,0.17-0.55,0.16-0.93c0-0.22,0-0.46,0.03-0.64c-0.01-0.15-0.02-0.3-0.02-0.46c-0.02-0.76-0.04-1.42-0.93-1.48
							C471.26,487.62,471.24,487.62,471.23,487.62z"/>
					</g>
					<g>
						<path className="st27" d="M453.28,523.12c-1.83,0.27-2.67-0.35-2.47-2.19c0.14-1.23,0.07-2.49,0.04-3.73
							c-0.03-1.03,0.7-1.3,1.56-1.4c1.18-0.14,0.98,0.76,1,1.36c0.05,1.53,0.02,3.06,0.02,4.6
							C453.38,522.22,453.33,522.67,453.28,523.12z"/>
					</g>
					<g>
						<path className="st85" d="M491.22,518.5c0.15,0.07,0.3,0.14,0.45,0.21C491.52,518.65,491.37,518.57,491.22,518.5z"/>
					</g>
					<g>
						<path className="st86" d="M405.8,546.49c-0.07-0.06-0.14-0.11-0.2-0.18c-0.01-0.01,0.05-0.08,0.07-0.13
							C405.73,546.28,405.77,546.38,405.8,546.49z"/>
					</g>
				</g>
				<g>
					<path className="st22" d="M540.96,516.02c-1.72,0.36-1.98,2.45-3.71,2.94c-4.72,1.34-22.57,4.95-6.49-6.07
						c2.5-1.71,0.5-6.1,2.06-8.83c0.89-2.46,1.35-5.3,5.27-4.09c0.75,0.23,1.92-0.87,2.89-1.35c0.72,0.46,1.54,1.57,2.15,1.31
						c17.51-7.47,24-1.48,23.77,1.82c-10.29,1.44-1.67,5.86-13.52,4.9C552.99,506.63,540.96,516.02,540.96,516.02z"/>
					<path className="st23" d="M527.27,520.7c-1.57,0-2.77-0.26-3.1-0.97c-0.59-1.25,1.55-3.51,6.53-6.93c1.35-0.93,1.34-2.68,1.34-4.53
						c0-1.49-0.01-3.04,0.69-4.27c0.08-0.24,0.17-0.49,0.25-0.74c0.75-2.21,1.52-4.5,5.13-3.39c0.47,0.15,1.17-0.31,1.85-0.76
						c0.32-0.21,0.66-0.43,0.97-0.59l0.05-0.02l0.05,0.03c0.24,0.15,0.48,0.37,0.72,0.59c0.48,0.43,0.97,0.87,1.33,0.72
						c9.61-4.1,17.53-4.73,21.75-1.73c1.42,1.01,2.25,2.41,2.16,3.65l-0.01,0.08l-0.08,0.01c-4.77,0.67-5.41,1.94-5.98,3.07
						c-0.66,1.31-1.19,2.35-7.56,1.83c-0.41,0.11-7.61,5.64-12.35,9.34l-0.04,0.02c-0.84,0.17-1.31,0.78-1.82,1.42
						c-0.49,0.63-1,1.27-1.89,1.52C535.57,519.54,530.6,520.7,527.27,520.7z M536.42,499.79c-2.05,0-2.64,1.73-3.25,3.55
						c-0.09,0.25-0.17,0.51-0.26,0.76c-0.68,1.2-0.68,2.72-0.67,4.18c0.01,1.91,0.01,3.71-1.42,4.69c-6,4.11-6.83,5.9-6.46,6.68
						c0.88,1.89,9.21,0.25,12.87-0.79c0.82-0.23,1.31-0.86,1.79-1.46c0.5-0.63,1.01-1.29,1.91-1.48c2.84-2.22,12.04-9.36,12.46-9.36
						c0,0,0,0,0,0c6.24,0.5,6.74-0.48,7.37-1.72c0.56-1.11,1.26-2.48,6.05-3.17c0.03-0.92-0.5-2.27-2.08-3.4
						c-1.97-1.4-8.01-4.03-21.56,1.75c-0.46,0.2-1.01-0.28-1.54-0.75c-0.22-0.19-0.44-0.39-0.65-0.54c-0.29,0.15-0.6,0.35-0.9,0.55
						c-0.75,0.49-1.46,0.96-2.02,0.78C537.42,499.88,536.88,499.79,536.42,499.79z"/>
				</g>
				<g>
					<path className="st30" d="M415.6,514.79c1.2-1.46,2.75-1.66,4.47-1.22c0.71,1.05,0.21,2.54,1.25,3.47
						c1.05,3.47,1.73,3.44-0.82,6.13c-4.55,4.8,3.74,10.89-4.66,11.28c-1.58-1.65-3.16-3.3-4.74-4.95c0.06-0.24,0.25-0.6,0.16-0.71
						c-3.35-3.89,2.7-4.36,4.69-7.68C417.55,520.42,415.63,516.98,415.6,514.79z"/>
					<path className="st23" d="M415.8,534.56l-0.03-0.03l-4.78-4.99l0.01-0.05c0.02-0.08,0.05-0.17,0.09-0.26
						c0.04-0.11,0.11-0.31,0.09-0.36c-0.77-0.89-1.09-1.66-1-2.36c0.15-1.08,1.27-1.81,2.56-2.66c1.13-0.74,2.4-1.58,3.11-2.77
						l0.02-0.03l0.03-0.01c0.95-0.42,0.54-1.95,0.11-3.56c-0.25-0.93-0.5-1.88-0.51-2.68l0-0.04l0.02-0.03
						c1.11-1.35,2.6-1.76,4.57-1.25l0.04,0.01l0.02,0.03c0.33,0.48,0.41,1.05,0.49,1.6c0.1,0.7,0.19,1.36,0.75,1.85l0.02,0.02
						l0.01,0.03c0.17,0.58,0.34,1.05,0.48,1.47c0.72,2.09,0.85,2.47-1.32,4.76c-2.01,2.12-1.48,4.46-1.01,6.52
						c0.33,1.45,0.61,2.69-0.07,3.58c-0.56,0.74-1.72,1.12-3.65,1.21L415.8,534.56z M411.21,529.49l4.67,4.88
						c1.81-0.09,2.95-0.46,3.46-1.13c0.62-0.81,0.35-2.02,0.03-3.42c-0.45-2.01-1.02-4.51,1.06-6.7c2.09-2.2,1.98-2.52,1.28-4.56
						c-0.14-0.41-0.3-0.88-0.47-1.45c-0.59-0.54-0.69-1.26-0.79-1.95c-0.08-0.54-0.15-1.05-0.43-1.49c-1.88-0.47-3.25-0.1-4.31,1.17
						c0.02,0.77,0.26,1.7,0.51,2.6c0.45,1.69,0.88,3.28-0.19,3.78c-0.74,1.22-2.02,2.06-3.16,2.8c-1.25,0.82-2.34,1.53-2.48,2.53
						c-0.09,0.64,0.22,1.36,0.95,2.21c0.1,0.12,0.03,0.32-0.06,0.55C411.25,529.36,411.22,529.42,411.21,529.49z"/>
				</g>
				<g>
					<path className="st27" d="M472.33,489.57c-0.1-0.88,0.18-1.96-1.38-2.04c-0.82-0.04-1.14,0.56-0.89,1.11
						c0.42,0.94-0.14,2.5,1.48,2.66C472.61,491.4,472.17,490.2,472.33,489.57z"/>
				</g>
				<g>
					<path className="st27" d="M475.95,499.6c-0.13-1.1,0.21-2.68-1.24-2.64c-1.23,0.03-1.09,1.43-1.07,2.45
						c0.02,1.1-0.33,2.7,1.06,2.81C476.14,502.33,475.82,500.71,475.95,499.6z"/>
				</g>
				<g>
					<path className="st27" d="M452.11,486.52c-0.06-0.46,0.11-1.11-0.62-1.1c-0.61,0.01-0.54,0.59-0.53,1.02
						c0.01,0.46-0.16,1.12,0.53,1.17C452.21,487.65,452.05,486.98,452.11,486.52z"/>
				</g>
				<g>
					<path className="st27" d="M452.69,493.57c-0.06-0.46,0.11-1.11-0.62-1.1c-0.61,0.01-0.54,0.59-0.53,1.02
						c0.01,0.46-0.16,1.12,0.53,1.17C452.79,494.7,452.63,494.03,452.69,493.57z"/>
				</g>
				<g>
					<path className="st27" d="M452.11,501.21c-0.06-0.46,0.11-1.11-0.62-1.1c-0.61,0.01-0.54,0.59-0.53,1.02
						c0.01,0.46-0.16,1.12,0.53,1.17C452.21,502.35,452.05,501.67,452.11,501.21z"/>
				</g>
				<g>
					<path className="st22" d="M453.92,520.48c1.86,0.36,9.59-2.41,15.67-5.11c-0.49,13.13-14.6,9.85-14.92,11.21
						c-2.54,2.59-2.1,1.68-5.78,2.22c-11.41,1.67-15.67,11.64-19.36,4.74c0.69-2.18,8.61-7.79,9.3-9.97c0.24-0.06,0.65-0.06,0.7-0.2
						c1.81-4.8,5.13,0.29,8.99,0.44C449.9,524.88,452,521.55,453.92,520.48z"/>
					<path className="st23" d="M432.53,536.04c-0.22,0-0.44-0.03-0.65-0.08c-0.92-0.24-1.7-0.99-2.43-2.38l-0.02-0.04l0.01-0.04
						c0.35-1.11,2.45-3.01,4.68-5.03c2.11-1.91,4.28-3.88,4.62-4.94l0.02-0.05l0.05-0.01c0.08-0.02,0.18-0.04,0.28-0.05
						c0.11-0.02,0.33-0.05,0.36-0.09c0.41-1.1,0.94-1.76,1.6-2c1.02-0.38,2.2,0.25,3.56,0.97c1.19,0.63,2.53,1.35,3.92,1.41l0.03,0
						l0.02,0.02c0.82,0.63,1.97-0.46,3.18-1.61c0.69-0.66,1.41-1.34,2.11-1.73l0.03-0.02l0.04,0.01c1.79,0.34,9.35-2.32,15.61-5.1
						l0.14-0.06l-0.01,0.16c-0.38,10.04-8.72,10.57-12.74,10.83c-1.14,0.07-2.12,0.14-2.18,0.4l-0.01,0.03l-0.02,0.02
						c-1.89,1.92-2.16,1.94-3.65,2.03c-0.54,0.03-1.21,0.07-2.18,0.21c-4.98,0.73-8.54,3.02-11.4,4.85
						C435.54,535.01,433.93,536.04,432.53,536.04z M429.64,533.53c0.7,1.29,1.45,2.02,2.29,2.24c1.45,0.38,3.23-0.76,5.47-2.19
						c2.88-1.85,6.45-4.14,11.48-4.88c0.98-0.14,1.66-0.18,2.2-0.22c1.45-0.09,1.68-0.1,3.5-1.95c0.13-0.37,0.97-0.43,2.35-0.52
						c3.93-0.25,12.09-0.78,12.55-10.49c-6.22,2.75-13.66,5.37-15.55,5.06c-0.67,0.38-1.36,1.05-2.04,1.69
						c-1.26,1.2-2.46,2.34-3.41,1.64c-1.42-0.07-2.78-0.79-3.97-1.43c-1.32-0.71-2.46-1.32-3.4-0.96c-0.6,0.23-1.09,0.85-1.48,1.89
						c-0.06,0.15-0.27,0.18-0.51,0.21c-0.07,0.01-0.14,0.02-0.2,0.03c-0.4,1.12-2.47,2.99-4.66,4.97
						C432.17,530.51,430.02,532.46,429.64,533.53z"/>
				</g>
				<g>
					<path className="st30" d="M496.41,510.2c1.87-0.29,3.15,0.6,4.14,2.07c-0.17,1.26-1.54,2.03-1.39,3.42c-1.53,3.29-1,3.72-4.7,4.02
						c-6.59,0.54-4.48,10.61-11,5.29c-0.08-2.28-0.15-4.57-0.23-6.85c0.2-0.14,0.59-0.28,0.59-0.42c0.1-5.13,4.93-1.45,8.62-2.59
						C494.1,515.69,494.96,511.85,496.41,510.2z"/>
					<path className="st23" d="M486.55,526.65c-0.84,0-1.86-0.51-3.16-1.57l-0.03-0.03l-0.23-6.95l0.04-0.03
						c0.07-0.05,0.15-0.09,0.24-0.14c0.1-0.05,0.29-0.16,0.31-0.21c0.02-1.18,0.3-1.97,0.83-2.42c0.84-0.7,2.16-0.5,3.69-0.27
						c1.33,0.2,2.84,0.43,4.17,0.02l0.03-0.01l0.03,0.01c0.98,0.32,1.7-1.09,2.46-2.58c0.43-0.85,0.88-1.74,1.41-2.34l0.02-0.03
						l0.04-0.01c1.72-0.26,3.11,0.43,4.24,2.12l0.02,0.03l0,0.04c-0.08,0.58-0.4,1.06-0.71,1.52c-0.39,0.59-0.76,1.14-0.68,1.88
						l0,0.03l-0.01,0.02c-0.25,0.54-0.45,1.01-0.62,1.41c-0.86,2.04-1.02,2.41-4.16,2.66c-2.9,0.24-4.07,2.33-5.11,4.18
						c-0.72,1.29-1.34,2.41-2.44,2.62C486.8,526.64,486.68,526.65,486.55,526.65z M483.55,524.96c1.41,1.14,2.5,1.62,3.33,1.46
						c1-0.19,1.6-1.27,2.3-2.52c1-1.8,2.25-4.04,5.26-4.28c3.03-0.25,3.16-0.55,4-2.55c0.17-0.4,0.36-0.86,0.61-1.39
						c-0.08-0.8,0.32-1.4,0.71-1.98c0.3-0.45,0.59-0.88,0.67-1.4c-1.08-1.61-2.35-2.24-3.99-2c-0.5,0.58-0.94,1.44-1.36,2.27
						c-0.79,1.56-1.55,3.03-2.66,2.69c-1.36,0.41-2.88,0.18-4.22-0.02c-1.48-0.22-2.76-0.42-3.53,0.23
						c-0.49,0.41-0.74,1.16-0.76,2.28c0,0.16-0.19,0.26-0.41,0.37c-0.06,0.03-0.12,0.07-0.18,0.1L483.55,524.96z"/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<path className="st22" d="M616.2,1055.37c-0.04,1.01,0.96,1.95,1.11,2.74c0.04,9.94-9.64,30.88-8.52,32.29
							c84.41-0.02,182.69,1.05,266.43,1.06l0,0l-0.91-0.83c0,0,0.69-0.79,0.44-2.62c-0.19-1.39-0.04-2.83-0.04-4.24
							c-2.37-0.11-4.08-1.55-5.33-3.26c-1.21-1.66-2.48-3-4.47-3.45c-1.25-0.28-2.45-0.65-3.59-1.14c0,0,0,0,0,0
							c-5.1-7.07-10.27-13.98-18.39-18.1c-2.14-1.08-4.6-2.06-6.24-4.08c-0.6-2.89-1.2-5.78-1.8-8.67
							c-2.53-1.23-2.96-3.67-3.51-6.05c-1.96,0.06-3.91,0.12-5.87,0.19c0,0-0.97-0.28-0.97-0.24c0,0,0-0.04,0-0.04l-13.3-2.51
							c-2.03-0.23-4.46-0.36-6.13,1.58c-3.48-2.03-6.25-5.32-10.56-5.86c-1.56,0.35-3.08,0.22-4.6-0.02
							c1.52,0.24,3.04,0.38,4.6,0.02c-1.63-1.19-3.25-2.37-4.88-3.56c-0.09-0.05-0.18-0.12-0.25-0.2c-0.37-0.5-0.74-1-1.11-1.51
							l-7.27-5.94l-2.89-3.53l-36.38,1.63c-0.37-0.12-0.73-0.24-1.1-0.35c-0.49-0.12-0.86,0.2-1.29,0.45l-1.71,0.08l-39.17-4.69
							c-0.59,0.31-1.18,0.57-1.87,0.39c0.01-0.09,0.05-0.19,0.03-0.28c-0.02-0.08-0.1-0.21-0.14-0.21
							c-2.83,0.08-5.66,0.17-8.48,0.25c-1.32-1.36-2.63-0.38-3.94,0.01c-0.21,0.56-0.13,1.23-0.1,1.87
							c0.04,0.97-0.04,1.89-1.43,2.34c0,0,0,0,0,0c-1.37,0.21-2.73,0.42-4.1,0.63c-0.04,0.09-0.09,0.17-0.14,0.25
							c-0.04,0.07-0.09,0.14-0.14,0.21c-0.09,0.13-0.19,0.25-0.3,0.36c-0.04,0.04-0.09,0.09-0.13,0.12
							c-0.66,0.59-1.52,0.71-2.55,0.41c0,0,0,0,0,0c-0.94-0.74-2.01-1.09-3.21-1.01c-0.07,0-0.14,0.01-0.2,0.03
							c-0.45,0.08-0.75,0.34-0.84,0.85c0,0,0,0,0,0c-0.21,0.1-0.42,0.16-0.62,0.2c-0.1,0.02-0.2,0.02-0.29,0.03
							c-1.25,0.02-2.24-1.1-3.47-1.21c-0.25-0.83-0.51-1.65-0.77-2.48c-0.26,0.26-0.73,0.51-0.75,0.78
							c-0.13,2.37-1.37,2.26-3.25,1.89c-1.14-0.23-2.72-1.64-3.77,0.34c-1.11,1.18-2.33,2.28-3.31,3.57
							c-0.93,1.22-1.84,1.07-2.93,0.42c-0.2-0.47-0.39-0.92-0.59-1.39c0.31-1.45-0.59-1.82-1.66-1.69c-0.81,0.1-1.4,0.79-1.25,1.66
							c0.26,1.52-0.42,3.07,0.21,4.57c0.49,1.18,0.26,2.8-0.52,3.22c-2.96,1.56-3.42,6.05-7.49,6.24c-0.65,0.03-0.4,0.97-0.58,1.48
							c-0.33,2.31-1.63,3.54-3.99,3.62c-1.95,0.68-3.95,1.25-5.85,2.07c-1.42,0.61-1.94-0.98-3.06-0.97
							c-0.93,0.14-1.72,0.87-2.73,0.65c-0.8,0.55-1.12,1.72-2.3,1.79c-0.02,0-0.03,0-0.04,0c-0.01,0.04-0.02,0.07-0.03,0.1
							c-0.16,0.22-0.33,0.44-0.49,0.67c-0.74,1.02-1.5,2.09-2.27,3.2C616.93,1051.39,616.27,1053.5,616.2,1055.37"/>
						<path className="st23" d="M875.78,1091.68l-0.56,0c-41-0.01-86.31-0.27-130.12-0.53c-45.83-0.27-93.22-0.55-136.31-0.53h-0.1
							l-0.06-0.08c-0.51-0.63,0.47-3.52,2.54-9.47c2.51-7.19,5.94-17.04,5.93-22.93c-0.06-0.29-0.25-0.61-0.46-0.96
							c-0.32-0.54-0.68-1.15-0.65-1.81c0.09-2.36,1.11-4.37,3.05-5.98c0.74-1.07,1.5-2.13,2.26-3.18c0.16-0.22,0.32-0.43,0.47-0.65
							c0.01-0.02,0.01-0.04,0.02-0.06l0.04-0.15l0.2-0.01c0.66-0.04,1.02-0.48,1.4-0.95c0.23-0.29,0.47-0.58,0.79-0.8l0.08-0.05
							l0.09,0.02c0.56,0.12,1.05-0.09,1.57-0.31c0.34-0.14,0.7-0.29,1.08-0.35l0.03,0c0.54-0.01,0.94,0.29,1.32,0.59
							c0.5,0.38,0.94,0.71,1.65,0.4c1.33-0.57,2.74-1.03,4.1-1.48c0.58-0.19,1.18-0.39,1.77-0.59l0.06-0.01
							c2.23-0.08,3.46-1.2,3.78-3.43l0.01-0.04c0.05-0.14,0.06-0.34,0.07-0.53c0.03-0.45,0.07-1.07,0.7-1.1
							c2.3-0.11,3.39-1.65,4.53-3.27c0.79-1.12,1.61-2.29,2.87-2.95c0.66-0.35,0.88-1.86,0.42-2.94c-0.38-0.92-0.3-1.86-0.23-2.76
							c0.06-0.64,0.11-1.25,0-1.86c-0.08-0.46,0.02-0.91,0.28-1.26c0.27-0.36,0.68-0.59,1.15-0.65c0.73-0.09,1.3,0.05,1.63,0.41
							c0.31,0.33,0.4,0.83,0.28,1.47l0.53,1.26c0.98,0.57,1.75,0.69,2.58-0.4c0.69-0.91,1.5-1.73,2.29-2.52
							c0.34-0.34,0.68-0.69,1.01-1.04c0.89-1.65,2.19-1.11,3.14-0.72c0.31,0.13,0.59,0.24,0.85,0.3c1.02,0.2,1.91,0.34,2.41-0.05
							c0.35-0.27,0.53-0.81,0.58-1.64c0.02-0.28,0.28-0.49,0.54-0.69c0.1-0.08,0.2-0.15,0.27-0.23l0.25-0.25l0.12,0.38
							c0.24,0.77,0.48,1.54,0.71,2.31c0.56,0.08,1.07,0.35,1.56,0.61c0.58,0.3,1.13,0.59,1.74,0.58c0.09,0,0.18-0.01,0.26-0.02
							c0.15-0.02,0.31-0.07,0.47-0.14c0.13-0.5,0.47-0.82,0.99-0.91c0.07-0.01,0.15-0.02,0.23-0.03c1.21-0.08,2.33,0.27,3.32,1.03
							c0.97,0.27,1.73,0.15,2.31-0.37c0.04-0.04,0.08-0.07,0.12-0.11c0.1-0.1,0.19-0.21,0.27-0.34c0.04-0.06,0.09-0.13,0.13-0.2
							c0.04-0.07,0.09-0.15,0.13-0.23l0.05-0.1l4.19-0.64c1.24-0.4,1.3-1.2,1.26-2.12c0-0.1-0.01-0.2-0.02-0.3
							c-0.03-0.56-0.07-1.14,0.13-1.66l0.04-0.1l0.1-0.03c0.25-0.08,0.52-0.18,0.77-0.28c1.03-0.4,2.19-0.85,3.32,0.25l0.64-0.02
							c2.58-0.08,5.17-0.16,7.75-0.23c0,0,0,0,0.01,0c0.23,0,0.34,0.3,0.35,0.38c0.01,0.05,0.01,0.1,0.01,0.15
							c0.54,0.07,1.03-0.16,1.52-0.41l0.06-0.03l0.07,0.01l39.15,4.69l1.63-0.07c0.05-0.03,0.1-0.06,0.15-0.09
							c0.37-0.24,0.76-0.48,1.26-0.36c0.33,0.1,0.67,0.21,1.08,0.34l36.45-1.63l2.95,3.6l7.28,5.94l1.11,1.52
							c0.06,0.06,0.12,0.11,0.19,0.15l4.85,3.53c2.98,0.39,5.26,2.09,7.47,3.73c0.95,0.71,1.94,1.44,2.97,2.06
							c1.71-1.85,4.09-1.76,6.2-1.52l13.48,2.54l0,0.03c0.16,0.03,0.4,0.1,0.79,0.21c1.95-0.06,3.89-0.12,5.84-0.18l0.18-0.01
							l0.12,0.55c0.51,2.27,1,4.41,3.3,5.53l0.1,0.05l1.81,8.72c1.35,1.65,3.27,2.58,5.12,3.49c0.34,0.17,0.68,0.33,1.01,0.5
							c7.82,3.97,12.78,10.28,18.44,18.12c1.04,0.45,2.19,0.81,3.5,1.11c2.13,0.48,3.47,1.98,4.6,3.53c1.47,2.01,3.2,3.08,5.16,3.18
							l0.21,0.01v0.21c0,0.44-0.01,0.89-0.03,1.32c-0.03,0.96-0.06,1.95,0.06,2.9c0.2,1.45-0.17,2.28-0.37,2.62L875.78,1091.68z
							 M611.21,1090.18c42.43,0,88.91,0.27,133.89,0.53c43.61,0.25,88.71,0.52,129.56,0.53l-0.66-0.6l0.14-0.16
							c0.01-0.01,0.62-0.75,0.39-2.45c-0.13-0.98-0.1-1.99-0.07-2.97c0.01-0.36,0.02-0.73,0.03-1.1c-2.01-0.16-3.79-1.29-5.28-3.34
							c-1.25-1.71-2.46-2.94-4.35-3.36c-1.36-0.31-2.55-0.68-3.63-1.15l-0.07-0.05l-0.02-0.03c-5.64-7.81-10.56-14.1-18.32-18.03
							c-0.33-0.17-0.67-0.33-1.01-0.5c-1.91-0.93-3.89-1.9-5.3-3.64l-0.03-0.04l-1.79-8.61c-2.41-1.22-2.93-3.53-3.44-5.77
							l-0.04-0.2c-1.89,0.06-3.79,0.12-5.68,0.18l-0.03,0l-0.04-0.01c-0.3-0.08-0.64-0.18-0.81-0.21l-0.3,0.43l-0.01-0.52
							l-13.13-2.48c-2.03-0.23-4.35-0.32-5.93,1.51l-0.12,0.14l-0.16-0.09c-1.1-0.64-2.15-1.42-3.16-2.17
							c-2.16-1.61-4.4-3.28-7.28-3.65c-1.63,0.36-3.2,0.2-4.64-0.03l0.07-0.43c0,0,0.01,0,0.01,0c1.27,0.2,2.63,0.35,4.02,0.12
							l-4.48-3.26c-0.09-0.05-0.19-0.13-0.28-0.22l-0.02-0.02l-1.09-1.49l-7.28-5.95l-2.83-3.45l-36.31,1.63l-0.04-0.01
							c-0.42-0.14-0.77-0.25-1.09-0.34c-0.31-0.08-0.58,0.09-0.92,0.31c-0.07,0.04-0.13,0.08-0.2,0.13l-0.05,0.03l-1.78,0.08
							l-39.12-4.69c-0.59,0.3-1.22,0.57-1.97,0.38l-0.19-0.05l0.03-0.19c0-0.03,0.01-0.07,0.02-0.1c0.01-0.04,0.02-0.09,0.02-0.11
							c0-0.01-0.01-0.02-0.01-0.03c-2.55,0.07-5.11,0.15-7.66,0.23l-0.83,0.03l-0.07-0.07c-0.95-0.98-1.86-0.63-2.92-0.22
							c-0.23,0.09-0.46,0.18-0.7,0.25c-0.13,0.41-0.1,0.88-0.07,1.38c0.01,0.1,0.01,0.21,0.02,0.31c0.04,0.98-0.04,2.06-1.58,2.55
							l-0.03,0.01l-3.99,0.61c-0.03,0.05-0.06,0.11-0.09,0.16c-0.05,0.08-0.09,0.15-0.14,0.22c-0.1,0.14-0.21,0.27-0.32,0.39
							c-0.05,0.05-0.09,0.09-0.14,0.14c-0.66,0.6-1.53,0.77-2.58,0.5l-0.04,0.08l-0.21-0.16c-0.91-0.72-1.94-1.04-3.06-0.97
							c-0.06,0-0.12,0.01-0.18,0.02c-0.38,0.07-0.6,0.29-0.67,0.68l-0.01,0.06l-0.07,0.08l-0.05,0.02
							c-0.22,0.11-0.45,0.18-0.68,0.22c-0.11,0.02-0.22,0.03-0.32,0.03c-0.72,0.01-1.34-0.32-1.94-0.63
							c-0.52-0.27-1.01-0.53-1.54-0.58l-0.15-0.01l-0.04-0.14c-0.22-0.72-0.45-1.44-0.67-2.16c-0.02,0.01-0.03,0.03-0.05,0.04
							c-0.14,0.11-0.37,0.29-0.37,0.37c-0.05,0.98-0.29,1.6-0.75,1.96c-0.65,0.5-1.59,0.37-2.76,0.13c-0.3-0.06-0.6-0.19-0.93-0.32
							c-0.98-0.4-1.9-0.78-2.6,0.55l-0.03,0.05c-0.34,0.36-0.7,0.72-1.04,1.07c-0.78,0.79-1.58,1.6-2.25,2.48
							c-1.1,1.45-2.24,1.05-3.21,0.47l-0.06-0.04l-0.64-1.51l0.01-0.07c0.11-0.53,0.06-0.92-0.16-1.15
							c-0.23-0.25-0.68-0.35-1.26-0.28c-0.35,0.04-0.66,0.22-0.86,0.48c-0.19,0.26-0.26,0.58-0.21,0.93c0.11,0.66,0.06,1.33,0,1.97
							c-0.08,0.89-0.15,1.73,0.2,2.55c0.5,1.2,0.34,2.99-0.62,3.49c-1.16,0.61-1.95,1.73-2.71,2.81c-1.16,1.64-2.35,3.33-4.87,3.46
							c-0.21,0.01-0.26,0.17-0.29,0.7c-0.01,0.21-0.03,0.43-0.09,0.62c-0.35,2.42-1.75,3.69-4.16,3.78
							c-0.58,0.2-1.17,0.39-1.74,0.58c-1.35,0.44-2.74,0.9-4.06,1.47c-0.95,0.41-1.55-0.05-2.08-0.45c-0.34-0.26-0.66-0.5-1.04-0.5
							c-0.33,0.05-0.64,0.18-0.97,0.32c-0.53,0.22-1.08,0.45-1.73,0.35c-0.23,0.18-0.42,0.41-0.62,0.66
							c-0.39,0.48-0.83,1.02-1.61,1.1l-0.02,0.03c-0.16,0.22-0.33,0.44-0.49,0.67c-0.76,1.05-1.52,2.12-2.27,3.19l-0.04,0.04
							c-1.87,1.55-2.83,3.4-2.91,5.68l-0.22-0.01l0.22,0.01c-0.02,0.53,0.29,1.06,0.59,1.57c0.23,0.39,0.45,0.76,0.51,1.12l0,0.04
							c0.02,5.96-3.43,15.87-5.95,23.1c-1.41,4.04-2.86,8.21-2.65,8.97C609.69,1090.18,610.45,1090.18,611.21,1090.18z
							 M824.54,1039.17L824.54,1039.17L824.54,1039.17z"/>
					</g>
					<g>
						<polygon className="st22" points="608.88,1090.21 608.89,1090.21 608.88,1090.21 						"/>
						<polygon className="st23" points="608.01,1090.93 608.7,1090.09 608.79,1089.92 608.94,1089.99 609.73,1089.99 						"/>
					</g>
					<g>
						<polygon className="st22" points="608.89,1090.21 608.89,1090.21 608.89,1090.21 						"/>
					</g>
				</g>
				<g>
					<path className="st24" d="M687.71,1021.61c-1.62,0.95-2.38,2.41-2.1,4.47c0.51,3.74-1.05,7.45-3.03,10.38
						c-0.32,0.48-0.67,0.96-0.77,1.55c-0.23,1.47,1.28,1.58,2.2,1.53c3-0.18,6.02-1.59,7.72,2.16c0.3,0.66,0.44,1.43,0.24,2.14
						c-0.41,1.47-2.48,1.75-3.56,1.1c-0.65-0.38-1.03-1.15-1.58-1.7c-0.8-0.8-2.55-1.33-3.51-0.5c-1.01,0.88-0.41,2.74,0.25,3.65
						c0.82,1.13,1.88,2.05,3.07,2.67c0.8,0.42,2.91,0.72,3.09,1.93c0.07,0.44-0.29,0.8-0.59,1.1c-1.62,1.68-1.52,4.17-2.79,6.06
						c-0.53,0.78-1.65,1.56-0.96,2.69c0.3,0.49,0.84,0.72,1.25,1.1c1.94,1.78-0.11,4.85-0.67,6.81c-0.84-2.45-1.89-6.11-3.82-7.77
						c-0.99-0.85-0.7-2.25-0.44-3.43c0.27-1.26,0.07-1.82-0.54-2.78c-0.61-0.96-0.76-1.8-0.7-2.99c0.09-2.03,0.18-4.06,0.27-6.09
						c-1.19,0.69-2.31,1.53-3.35,2.49c-0.56,0.52-1.13,1.17-1.12,1.98c0.01,0.99,0.88,1.67,1.64,2.17
						c-1.37,0.82-2.63,1.88-3.71,3.12c-0.11,1.98,0.51,3.95,1.43,5.65c0.77,1.43,1.78,2.61,2.73,3.88c0.93,1.25,1.55,2.8,2.12,4.31
						c1.2,3.19,1.91,11.53,2.58,14.9c1.04-0.07,2.09-0.5,3.13-0.39c-0.82-0.09,1.51-13.21,1.69-14.05c0.6,1.52,0.64,3.26,0.38,4.89
						c-0.25,1.64-2.19,9.2-2.72,10.75c0.82,0.3,4.36,0.06,5.22,0.01c0.09-0.01-1.1-5.66-1.02-5.71c0.15-0.1,0.19-0.33,0.22-0.53
						c0.32-2.05,1.74-3.56,2.52-5.37c0.51-1.18,0.72-2.65,0.09-3.76c-0.64-1.14-1.98-1.59-2.67-2.69c-0.73-1.15-0.58-2.73-0.15-4.05
						c0.57-1.78,1.89-3.57,3.59-3.45c0.62,0.05,1.27,0.44,1.43,1.1c0.08,0.35,0.02,0.72-0.02,1.08c-0.14,1.3,0.12,2.58,0.65,3.73
						c0.52,1.13,1.42,2.2,1.29,3.56c-0.05,0.53-0.26,1.02-0.4,1.52c-0.54,1.92-0.13,4.02,0.47,5.92c0.24,0.78,0.53,1.56,1.05,2.14
						c0.78,0.86,1.95,1.11,3.05,1.21c0.44,0.04,0.97,0.02,1.21-0.4c0.22-0.38,0.07-0.88-0.05-1.31c-0.67-2.27-0.4-4.59,0.24-6.82
						c0.33-1.15,0.95-2.31,1.27-3.51c0.48-1.76-0.64-3.37,0.08-4.98c0.76-1.69,1.94-3.28,3.09-4.66c2.38-2.87,5.26-5.01,8.47-6.56
						c-0.62,1.03-0.51,2.44-1.08,3.51c-0.39,0.74-1.09,1.27-1.85,1.39c-0.23,0.04-0.49,0.05-0.65,0.24
						c-0.25,0.3-0.08,0.78,0.08,1.15c0.54,1.25,0.76,2.66,0.66,4.04c-0.07,0.96-0.95,2.88-0.53,3.72c0.3,0.6,1.07,0.61,1.61,0.95
						c-0.19-1.29,0.02-2.63,0.29-3.89c0.28-1.32,0.74-2.59,1.27-3.81c1.1-2.55,2.43-4.93,3.81-7.31c1.33-2.3,3.82-4.91,4.01-7.78
						c0.14-2.22-3.46,1.76-3.96,0.34c-0.1-0.28-0.05-0.6,0.01-0.89c0.32-1.42,1.04-2.62,2.09-3.48c0.81-0.66,1.71-1.28,1.73-2.53
						c0.01-0.56-0.13-1.1-0.29-1.63c-0.35-1.16-0.68-3.04-1.56-3.85c-0.18-0.17,2.91-2.47,2.91-2.47s2.15-0.35,4.58-1.76
						 M728.31,1027.86c0.63,0.39,1.06-6.05,1.68-5.55c0.11,0.01,0.22,0.03,0.34,0.05c0.12,0.02,0.24,0.05,0.35,0.07
						c0.23,0.04,0.43,0.04,0.55-0.07c0.36-0.34-0.1-0.7-0.41-0.85c-2.51-1.25-4.63-3.2-7.81-2.94c-6.87,0.58-13.79,0.86-20.68,0.7
						c-4.05-0.09-8.05-1.45-12.18-1.18c-0.09,0.01-0.18,0-0.26-0.01c-0.61-0.07-1.29-0.42-1.64-1.09l0,0c0,0.01,0,0,0,0l0,0
						c-0.38-0.37,0.26-0.88,0.21-1.37c-0.02-0.16-0.43-0.76-0.44-0.92c-0.61,0.08-1.56,0.39-2,0.79c-0.07,0.06-0.13,0.14-0.18,0.23
						c-0.03,0.04-0.05,0.09-0.07,0.15c-0.08,0.2-0.14,0.45-0.15,0.78c-0.02,0.32-0.03,0.64-0.04,0.95c0,0.12-0.01,0.25-0.01,0.37
						c0,0.06,0,0.12,0,0.18c0,0.12,0.01,0.24,0.01,0.36c0,0.08,0.01,0.16,0.02,0.24c0.12,1.19,0.55,2.5,2.11,2.88 M716.45,1043.97
						c2.11-2.72-0.52-5.8,0.61-8.92C719.71,1039.94,719.68,1040.51,716.45,1043.97z M697.87,1029.6c0.24-0.58,0.59-0.83,1.02-0.46
						c0.64,0.54,1.51,1.39,0.83,2.22c-1.62,1.96-1.04,3.73-0.04,5.62c1.81,3.45-0.58,6.34-0.64,9.74c-1.52-0.74-1.63-1.98-1.9-2.95
						C695.78,1038.96,695.97,1034.22,697.87,1029.6z"/>
					<path className="st23" d="M687.31,1085.67c-0.81,0-1.46-0.04-1.77-0.15l-0.1-0.04l0.03-0.1c0.1-0.29,0.25-0.81,0.44-1.47
						c-0.52-0.01-1.05,0.1-1.57,0.21c-0.41,0.08-0.83,0.17-1.26,0.2l-0.1,0.01l-0.02-0.09c-0.23-1.16-0.47-2.94-0.72-4.82
						c-0.5-3.74-1.07-7.97-1.85-10.06c-0.56-1.49-1.19-3.06-2.11-4.28c-0.21-0.28-0.43-0.56-0.64-0.84
						c-0.73-0.95-1.49-1.94-2.09-3.05c-1.04-1.92-1.54-3.9-1.45-5.71l0-0.04l0.02-0.03c1.05-1.21,2.26-2.24,3.59-3.06
						c-0.78-0.53-1.54-1.2-1.55-2.17c-0.01-0.87,0.61-1.56,1.15-2.06c1.04-0.96,2.17-1.8,3.37-2.5l0.17-0.1l-0.28,6.29
						c-0.06,1.25,0.13,2.05,0.69,2.92c0.59,0.94,0.84,1.52,0.55,2.86c-0.25,1.17-0.54,2.51,0.41,3.32c1.75,1.5,2.76,4.53,3.57,6.97
						c0.06,0.18,0.12,0.36,0.18,0.53c0.13-0.37,0.29-0.78,0.46-1.2c0.74-1.81,1.58-3.87,0.14-5.18c-0.14-0.13-0.3-0.24-0.46-0.35
						c-0.3-0.21-0.61-0.43-0.81-0.77c-0.57-0.95,0.03-1.67,0.56-2.3c0.15-0.18,0.29-0.34,0.4-0.51c0.55-0.82,0.85-1.78,1.13-2.7
						c0.37-1.19,0.75-2.41,1.67-3.37l0.02-0.02c0.28-0.29,0.6-0.62,0.54-1c-0.12-0.83-1.26-1.21-2.17-1.52
						c-0.34-0.12-0.64-0.22-0.86-0.33c-1.21-0.63-2.28-1.57-3.11-2.71c-0.72-0.99-1.29-2.88-0.24-3.79c1.03-0.89,2.85-0.3,3.65,0.5
						c0.21,0.21,0.4,0.45,0.58,0.68c0.3,0.39,0.59,0.77,0.98,0.99c0.61,0.36,1.55,0.41,2.29,0.12c0.4-0.16,0.92-0.49,1.11-1.16
						c0.17-0.61,0.09-1.35-0.23-2.06c-1.33-2.94-3.41-2.64-5.83-2.3c-0.59,0.08-1.19,0.17-1.79,0.2c-0.99,0.06-1.67-0.12-2.04-0.52
						c-0.25-0.28-0.35-0.66-0.27-1.13c0.09-0.57,0.41-1.05,0.72-1.5l0.06-0.09c1.68-2.48,3.56-6.28,3.01-10.31
						c-0.27-1.95,0.38-3.45,1.94-4.44c-1.15-0.37-1.79-1.33-1.95-2.9c-0.01-0.08-0.01-0.16-0.02-0.24
						c-0.01-0.12-0.01-0.24-0.01-0.36c0-0.06,0-0.12,0-0.18c0-0.12,0-0.25,0.01-0.37c0.01-0.26,0.02-0.52,0.03-0.78l0.01-0.17
						c0.02-0.33,0.07-0.6,0.16-0.82c0.02-0.06,0.05-0.11,0.08-0.16c0.06-0.1,0.12-0.18,0.2-0.25c0.47-0.44,1.49-0.74,2.06-0.82
						l0.11-0.02l0.01,0.12c0,0.06,0.13,0.29,0.21,0.43c0.13,0.24,0.21,0.39,0.22,0.48c0.03,0.24-0.08,0.47-0.18,0.68
						c-0.09,0.19-0.15,0.33-0.14,0.44l0.12,0.23c0.35,0.66,1.02,0.96,1.54,1.02c0.08,0.01,0.16,0.01,0.24,0.01
						c2.34-0.15,4.67,0.22,6.92,0.58c1.73,0.28,3.51,0.56,5.26,0.6c6.48,0.15,13.24-0.08,20.67-0.7c2.42-0.2,4.27,0.89,6.05,1.94
						c0.59,0.35,1.19,0.7,1.81,1.01c0.13,0.06,0.54,0.29,0.59,0.62c0.02,0.1,0,0.25-0.16,0.4c-0.16,0.15-0.41,0.14-0.64,0.1
						c-0.09-0.01-0.18-0.03-0.27-0.05l-0.09-0.02c-0.11-0.02-0.23-0.04-0.33-0.05l-0.03,0l-0.03-0.02c-0.2,0.08-0.48,1.64-0.66,2.67
						c-0.33,1.87-0.52,2.77-0.81,2.9c-0.04,0.02-0.09,0.02-0.13,0.01c-2.26,1.29-4.29,1.69-4.55,1.74
						c-1.29,0.96-2.66,2.06-2.84,2.33c0.7,0.68,1.05,2.01,1.34,3.09c0.07,0.27,0.14,0.53,0.21,0.76c0.15,0.49,0.3,1.07,0.29,1.67
						c-0.02,1.2-0.82,1.84-1.59,2.46l-0.18,0.15c-1.03,0.84-1.74,2.02-2.05,3.42c-0.06,0.26-0.11,0.57-0.02,0.83
						c0.04,0.11,0.1,0.19,0.19,0.22c0.35,0.13,1.07-0.29,1.71-0.66c0.74-0.43,1.44-0.83,1.82-0.59c0.18,0.11,0.26,0.35,0.24,0.73
						c-0.14,2.11-1.52,4.1-2.74,5.86c-0.47,0.69-0.92,1.33-1.29,1.96c-1.3,2.25-2.67,4.67-3.8,7.3c-0.62,1.44-1.02,2.64-1.26,3.79
						c-0.26,1.24-0.47,2.57-0.28,3.85l0.04,0.24l-0.2-0.13c-0.18-0.12-0.4-0.2-0.61-0.27c-0.4-0.15-0.82-0.3-1.03-0.72
						c-0.29-0.58-0.01-1.61,0.24-2.51c0.13-0.48,0.26-0.94,0.28-1.27c0.1-1.4-0.12-2.78-0.65-3.99c-0.13-0.29-0.39-0.89-0.07-1.26
						c0.17-0.2,0.41-0.23,0.63-0.26l0.09-0.01c0.72-0.11,1.4-0.62,1.77-1.33c0.26-0.5,0.38-1.09,0.49-1.66
						c0.1-0.53,0.21-1.08,0.43-1.56c-3.21,1.6-5.94,3.74-8.13,6.38c-1.01,1.22-2.28,2.85-3.07,4.63c-0.37,0.82-0.24,1.65-0.1,2.53
						c0.13,0.78,0.26,1.59,0.02,2.44c-0.17,0.63-0.42,1.25-0.66,1.84c-0.23,0.57-0.45,1.12-0.61,1.67c-0.74,2.6-0.82,4.81-0.24,6.76
						c0.13,0.43,0.29,0.98,0.04,1.4c-0.28,0.48-0.88,0.49-1.31,0.45c-1.01-0.09-2.28-0.31-3.12-1.24c-0.55-0.61-0.84-1.45-1.07-2.18
						c-0.49-1.56-1.06-3.87-0.47-5.98c0.04-0.15,0.09-0.3,0.14-0.44c0.11-0.34,0.23-0.7,0.26-1.06c0.09-0.98-0.37-1.81-0.82-2.63
						c-0.16-0.29-0.32-0.58-0.46-0.88c-0.57-1.25-0.8-2.56-0.66-3.79l0.02-0.15c0.04-0.3,0.07-0.61,0.01-0.89
						c-0.15-0.64-0.79-0.98-1.33-1.02c-1.62-0.12-2.91,1.6-3.48,3.37c-0.3,0.93-0.67,2.68,0.14,3.96c0.33,0.52,0.81,0.9,1.28,1.26
						c0.52,0.4,1.05,0.82,1.39,1.43c0.56,0.99,0.53,2.44-0.08,3.86c-0.29,0.67-0.67,1.3-1.04,1.92c-0.63,1.06-1.28,2.15-1.48,3.43
						c-0.03,0.2-0.07,0.43-0.22,0.57c0.02,0.26,0.29,1.64,0.51,2.75c0.56,2.88,0.55,2.9,0.49,2.97l-0.03,0.04l-0.35,0.02
						C689.45,1085.62,688.27,1085.67,687.31,1085.67z M685.71,1085.35c0.87,0.22,3.69,0.05,4.78-0.01l0.19-0.01
						c-0.04-0.36-0.3-1.68-0.51-2.76c-0.56-2.87-0.55-2.88-0.46-2.94c0.11-0.08,0.15-0.28,0.17-0.45c0.21-1.32,0.87-2.43,1.51-3.51
						c0.36-0.61,0.74-1.24,1.02-1.89c0.59-1.36,0.62-2.73,0.09-3.67c-0.32-0.57-0.82-0.95-1.34-1.36c-0.48-0.38-0.99-0.77-1.33-1.32
						c-0.86-1.36-0.47-3.18-0.16-4.14c0.59-1.85,1.96-3.65,3.7-3.52c0.61,0.05,1.35,0.44,1.52,1.18c0.08,0.32,0.04,0.65,0,0.97
						l-0.02,0.15c-0.13,1.19,0.09,2.46,0.64,3.67c0.13,0.29,0.3,0.58,0.45,0.87c0.46,0.84,0.94,1.71,0.85,2.75
						c-0.04,0.39-0.15,0.75-0.27,1.11c-0.05,0.14-0.09,0.29-0.14,0.44c-0.58,2.06-0.01,4.33,0.46,5.86c0.22,0.71,0.5,1.52,1.03,2.1
						c0.79,0.87,2.01,1.09,2.98,1.17c0.59,0.05,0.94-0.06,1.11-0.34c0.2-0.34,0.06-0.81-0.06-1.23c-0.59-2-0.51-4.25,0.24-6.88
						c0.16-0.56,0.39-1.13,0.62-1.69c0.24-0.59,0.49-1.21,0.66-1.82c0.22-0.8,0.09-1.59-0.03-2.35c-0.14-0.87-0.28-1.77,0.11-2.65
						c0.81-1.81,2.08-3.45,3.11-4.68c2.28-2.75,5.14-4.96,8.5-6.59l0.33-0.16l-0.19,0.31c-0.32,0.52-0.44,1.17-0.56,1.79
						c-0.11,0.58-0.23,1.19-0.51,1.72c-0.4,0.77-1.14,1.32-1.93,1.44l-0.09,0.01c-0.2,0.03-0.38,0.05-0.5,0.19
						c-0.2,0.24-0.06,0.66,0.1,1.04c0.54,1.24,0.77,2.66,0.67,4.09c-0.03,0.35-0.15,0.82-0.29,1.31c-0.24,0.87-0.51,1.85-0.25,2.36
						c0.17,0.35,0.53,0.48,0.91,0.62c0.15,0.06,0.31,0.11,0.46,0.19c-0.14-1.25,0.07-2.52,0.31-3.71c0.24-1.16,0.65-2.37,1.27-3.83
						c1.14-2.64,2.51-5.06,3.82-7.32c0.37-0.64,0.82-1.29,1.3-1.98c1.2-1.74,2.56-3.7,2.7-5.75c0.02-0.29-0.03-0.47-0.14-0.54
						c-0.27-0.17-0.97,0.24-1.6,0.6c-0.75,0.43-1.45,0.84-1.9,0.67c-0.15-0.06-0.26-0.17-0.32-0.35c-0.11-0.31-0.06-0.65,0.01-0.95
						c0.32-1.44,1.06-2.67,2.13-3.54l0.18-0.15c0.77-0.62,1.49-1.2,1.51-2.3c0.01-0.57-0.14-1.12-0.28-1.6
						c-0.07-0.23-0.14-0.49-0.21-0.77c-0.28-1.07-0.64-2.41-1.32-3.03c-0.03-0.03-0.04-0.06-0.04-0.11
						c0.01-0.29,1.86-1.71,2.97-2.53l0.02-0.02l0.03,0c0.02,0,2.16-0.37,4.55-1.74l0.05,0.09l0.06-0.09c0,0,0,0,0,0
						c0.21-0.08,0.49-1.68,0.68-2.74c0.33-1.89,0.51-2.73,0.8-2.84c0.06-0.02,0.12-0.02,0.18,0.02c0.11,0.01,0.22,0.03,0.32,0.05
						l0.09,0.02c0.09,0.02,0.18,0.04,0.26,0.05c0.15,0.02,0.37,0.04,0.46-0.04c0.07-0.07,0.11-0.14,0.09-0.22
						c-0.03-0.17-0.26-0.35-0.47-0.46c-0.63-0.31-1.23-0.67-1.82-1.02c-1.84-1.08-3.57-2.11-5.92-1.91
						c-7.44,0.62-14.2,0.85-20.69,0.7c-1.77-0.04-3.56-0.33-5.29-0.61c-2.24-0.36-4.56-0.73-6.88-0.58c-0.09,0.01-0.19,0-0.28-0.01
						c-0.75-0.09-1.38-0.51-1.71-1.14c-0.26-0.26-0.11-0.58,0.03-0.86c0.09-0.19,0.18-0.39,0.16-0.56c-0.01-0.06-0.12-0.26-0.2-0.4
						c-0.1-0.18-0.17-0.31-0.21-0.41c-0.63,0.1-1.47,0.4-1.85,0.75c-0.06,0.06-0.11,0.12-0.16,0.2c-0.02,0.04-0.05,0.09-0.07,0.13
						c-0.08,0.19-0.13,0.44-0.15,0.74l-0.01,0.17c-0.01,0.26-0.03,0.52-0.03,0.77c0,0.12-0.01,0.25-0.01,0.37c0,0.06,0,0.12,0,0.18
						c0,0.12,0.01,0.24,0.01,0.35c0,0.08,0.01,0.16,0.02,0.24c0.16,1.58,0.82,2.49,2.02,2.78l-0.03,0.11l0.05,0.09
						c-1.64,0.96-2.31,2.39-2.04,4.36c0.56,4.09-1.35,7.95-3.05,10.46l-0.06,0.09c-0.3,0.44-0.6,0.89-0.69,1.41
						c-0.06,0.4,0.01,0.72,0.22,0.95c0.4,0.44,1.24,0.49,1.87,0.45c0.59-0.03,1.19-0.12,1.77-0.2c2.4-0.34,4.66-0.66,6.06,2.43
						c0.35,0.76,0.43,1.55,0.25,2.21c-0.17,0.59-0.61,1.05-1.24,1.3c-0.81,0.32-1.8,0.27-2.48-0.13c-0.42-0.25-0.74-0.66-1.04-1.05
						c-0.18-0.23-0.36-0.46-0.56-0.66c-0.73-0.73-2.44-1.29-3.36-0.49c-0.98,0.86-0.31,2.7,0.27,3.5c0.81,1.11,1.86,2.02,3.03,2.64
						c0.2,0.11,0.51,0.21,0.83,0.32c0.97,0.33,2.17,0.74,2.31,1.7c0.07,0.49-0.3,0.87-0.6,1.18l-0.02,0.02
						c-0.89,0.92-1.24,2.07-1.62,3.28c-0.29,0.94-0.59,1.91-1.16,2.76c-0.12,0.18-0.26,0.35-0.42,0.53
						c-0.51,0.6-1.04,1.23-0.54,2.05c0.18,0.3,0.46,0.5,0.75,0.71c0.16,0.11,0.33,0.23,0.48,0.37c1.55,1.42,0.64,3.64-0.08,5.42
						c-0.22,0.54-0.43,1.05-0.55,1.49l-0.09,0.33l-0.11-0.33c-0.09-0.27-0.19-0.55-0.28-0.85c-0.81-2.41-1.81-5.41-3.51-6.88
						c-1.05-0.9-0.73-2.36-0.48-3.53c0.27-1.26,0.05-1.79-0.52-2.7c-0.58-0.92-0.78-1.75-0.72-3.05l0.26-5.89
						c-1.12,0.67-2.18,1.47-3.16,2.37c-0.51,0.47-1.1,1.12-1.09,1.9c0.01,0.92,0.81,1.57,1.59,2.08l0.14,0.09l-0.15,0.09
						c-1.36,0.81-2.59,1.84-3.66,3.07c-0.09,1.76,0.4,3.68,1.42,5.56c0.6,1.1,1.35,2.08,2.08,3.02c0.21,0.28,0.43,0.56,0.64,0.84
						c0.93,1.25,1.57,2.82,2.14,4.33c0.79,2.11,1.36,6.36,1.86,10.1c0.24,1.83,0.48,3.56,0.7,4.72c0.38-0.03,0.76-0.11,1.13-0.19
						c0.55-0.11,1.11-0.23,1.67-0.21c0.01-0.02,0.01-0.04,0.02-0.06c-0.39-1.89,1.79-13.77,1.82-13.89l0.08-0.35l0.13,0.33
						c0.54,1.39,0.68,3.1,0.39,4.95c-0.21,1.32-1.42,6.17-2.2,8.98c0.01,0.02,0.02,0.04,0.04,0.05l-0.04,0.21
						c-0.02,0-0.05,0-0.07-0.01C685.96,1084.54,685.82,1085.03,685.71,1085.35z M687.94,1070.18c-0.41,2.15-1.81,10.51-1.8,12.91
						c0.77-2.84,1.86-7.2,2.05-8.44C688.45,1073,688.36,1071.46,687.94,1070.18z M699.14,1046.89l-0.15-0.07
						c-1.37-0.66-1.63-1.7-1.86-2.63c-0.03-0.14-0.07-0.27-0.1-0.4c-1.38-4.9-1.13-9.69,0.74-14.24l0,0
						c0.16-0.4,0.37-0.63,0.61-0.69c0.19-0.05,0.39,0.01,0.58,0.18c0.45,0.38,1.65,1.39,0.84,2.37c-1.65,1.99-0.92,3.81-0.03,5.51
						c1.17,2.24,0.62,4.2,0.04,6.28c-0.32,1.13-0.65,2.3-0.67,3.51L699.14,1046.89z M698.51,1029.07c-0.02,0-0.05,0-0.07,0.01
						c-0.17,0.04-0.33,0.23-0.46,0.56l0,0c-1.85,4.5-2.1,9.25-0.73,14.1c0.04,0.13,0.07,0.26,0.1,0.4c0.21,0.84,0.45,1.79,1.59,2.41
						c0.05-1.18,0.36-2.31,0.67-3.4c0.57-2.03,1.11-3.96-0.02-6.12c-0.92-1.76-1.67-3.65,0.06-5.74c0.6-0.72-0.12-1.49-0.81-2.07
						C698.71,1029.12,698.61,1029.07,698.51,1029.07z M716.53,1044.04l-0.16-0.14c1.14-1.47,0.86-3.05,0.57-4.71
						c-0.24-1.36-0.48-2.76,0.03-4.17l0.08-0.23l0.11,0.21C719.84,1039.95,719.8,1040.54,716.53,1044.04z M717.08,1035.32
						c-0.39,1.29-0.16,2.58,0.06,3.83c0.24,1.38,0.48,2.71-0.07,3.98C719.52,1040.41,719.4,1039.61,717.08,1035.32z"/>
				</g>
				<g>
					<path className="st24" d="M630.98,1089.8c2.24,1.03-10.7-26.45-8.77-26.24c-0.2-0.02,0.43-3.04,0.51-3.37
						c0.24-1.02,1.22-1.18,2.09-0.6c0.88,0.6,1.4,1.7,2.78,1.1c0.47-0.2,0.9-0.59,1.41-0.51c0.4,0.06,0.67,0.38,0.96,0.63
						c1.31,1.13,3.39,0.97,5.16,0.61c2.04-0.42,4.09-0.94,5.98,0.19c0.73,0.43,1.32,1.03,1.94,1.59c0.63,0.55,1.32,1.08,2.16,1.3
						c0.84,0.22,1.85,0.08,2.41-0.53c0.48-0.53,0.54-1.28,0.41-1.96c-0.42-2.36-3.69-4.57-1.48-6.98c0.54-0.59,1.27-1.01,2.03-1.34
						c0.22-0.1,0.45-0.19,0.7-0.18c1.09,0.05,1.55,1.32,2.93,1.28c2.09-0.07,4.38-0.97,6.4-1.42c2.38-0.53,4.76-1.03,7.15-1.49
						c4.79-0.92-1.25-1.95,3.59-2.59c1.25-0.17,11.4-6.27,11.4-6.27l0,0l0,0l0,0l0,0l0,0l0,0c0,0-0.99-2.44,0.09-4.81
						c0.86-1.91,0.56-2.77,0.72-3.92s1.83-2.44,1.83-2.44s-0.38-1.47-0.5-2.63c-0.12-1.16,0.69-2.4,1.94-2.68
						c0.4-0.68-0.53-1.52-1.37-1.41s-1.46,0.79-1.84,1.48c-0.39,0.69-0.66,1.45-1.23,2.02c-0.58,0.57-1.41,0.87-2.21,1.16
						c-1.67,0.6-3.34,1.19-5.01,1.79c-0.67,0.24-1.39,0.48-2.1,0.36c-0.71-0.12-1.36-0.77-1.15-1.4c-3.52,2.71-7.03,5.41-10.55,8.12
						c0.59-1.85,1.07-4.13-0.47-5.47c-0.82-0.71-2.2-1.14-2.28-2.17c-1.24,1.62-3.17,2.8-5.29,3.24c-0.43,0.09-0.88,0.16-1.23,0.4
						c-0.2,0.14-0.35,0.34-0.58,0.45c-0.47,0.22-1.11-0.1-1.25-0.56s0.1-0.98,0.5-1.3c0.38-0.31,1.58-0.53,1.67-0.99
						c0.1-0.51-0.76-0.95-1.32-0.86c-0.56,0.08-0.76,0.53-0.97,1.01c-0.46,1.07-0.75,2.26-0.65,3.41c0.2,2.41-0.94,4.88-2.96,6.46
						c-1.2,0.93-2.67,1.56-3.76,2.59c-1.22,1.15-1.89,2.74-3.19,3.82c-3.08,2.55-8.05,1.26-12,2.47c-3.22,0.99-5.5,3.57-7.27,6.19
						c1.17,0.19,2.44,0.55,3.06,1.46c0.89,1.3-0.57,3.11-2.15,2.12c-1.16-0.73-1.72-2.82-2.68-0.74c-0.64,1.39-0.79,2.92-1.16,4.38
						c-0.51,2.01-0.91,4.05-1.45,6.06c-1.26,4.63-2.53,9.27-4.1,13.82c-0.48,1.4-0.99,2.79-1.58,4.16"/>
					<path className="st23" d="M631.09,1089.94c-0.04,0-0.1-0.01-0.16-0.04l0.09-0.2c0.04,0.02,0.07,0.02,0.07,0.02
						c0.46-0.44-2.27-7.21-4.47-12.64c-3.22-7.98-5.15-12.87-4.65-13.53c0.02-0.03,0.05-0.05,0.08-0.07
						c-0.05-0.66,0.53-3.28,0.54-3.31c0.11-0.46,0.37-0.79,0.74-0.93c0.44-0.17,0.99-0.07,1.51,0.27c0.23,0.15,0.43,0.34,0.63,0.52
						c0.58,0.53,1.08,0.99,2.05,0.57c0.13-0.06,0.26-0.13,0.39-0.2c0.33-0.19,0.68-0.38,1.08-0.32c0.35,0.05,0.61,0.28,0.85,0.51
						c0.05,0.05,0.11,0.1,0.16,0.15c1.03,0.88,2.68,1.08,5.06,0.59l0.35-0.07c1.91-0.4,3.89-0.81,5.71,0.28
						c0.58,0.35,1.08,0.8,1.56,1.23c0.13,0.12,0.27,0.24,0.4,0.36c0.57,0.5,1.27,1.05,2.12,1.28c0.74,0.2,1.73,0.12,2.3-0.5
						c0.5-0.55,0.49-1.32,0.39-1.87c-0.14-0.79-0.62-1.59-1.09-2.36c-0.92-1.51-1.86-3.07-0.36-4.71c0.48-0.52,1.14-0.96,2.07-1.36
						c0.23-0.1,0.48-0.2,0.74-0.19c0.54,0.02,0.93,0.32,1.31,0.62c0.44,0.34,0.9,0.68,1.61,0.66c1.4-0.04,2.9-0.47,4.34-0.88
						c0.71-0.2,1.38-0.39,2.04-0.54c2.37-0.53,4.78-1.03,7.16-1.49c1.13-0.22,1.76-0.47,1.88-0.73c0.06-0.15-0.05-0.33-0.18-0.51
						c-0.13-0.2-0.28-0.42-0.18-0.65c0.1-0.22,0.43-0.48,2.08-0.7c1.16-0.15,10.34-5.64,11.28-6.2c-0.16-0.46-0.85-2.67,0.12-4.81
						c0.61-1.34,0.63-2.15,0.65-2.93c0.01-0.32,0.02-0.62,0.06-0.96c0.15-1.08,1.52-2.23,1.82-2.47c-0.08-0.3-0.38-1.56-0.48-2.58
						c-0.13-1.25,0.76-2.49,1.97-2.79c0.13-0.27,0.01-0.52-0.07-0.65c-0.25-0.38-0.77-0.62-1.22-0.56
						c-0.86,0.11-1.43,0.83-1.76,1.42c-0.11,0.19-0.21,0.39-0.3,0.58c-0.26,0.51-0.52,1.04-0.95,1.47c-0.61,0.6-1.48,0.91-2.25,1.18
						l-5.01,1.79c-0.62,0.22-1.4,0.5-2.16,0.37c-0.47-0.08-0.91-0.37-1.13-0.75c-0.1-0.17-0.15-0.34-0.15-0.51l-10.61,8.17
						l0.11-0.33c0.58-1.81,1.06-4.06-0.43-5.35c-0.25-0.21-0.56-0.41-0.85-0.6c-0.61-0.39-1.24-0.78-1.42-1.4
						c-1.26,1.55-3.14,2.67-5.21,3.09l-0.15,0.03c-0.37,0.07-0.75,0.15-1.04,0.36c-0.07,0.05-0.13,0.11-0.2,0.17
						c-0.12,0.11-0.24,0.21-0.39,0.29c-0.24,0.11-0.53,0.11-0.81-0.01c-0.29-0.13-0.51-0.36-0.59-0.62
						c-0.15-0.47,0.07-1.04,0.54-1.41c0.16-0.13,0.42-0.23,0.7-0.35c0.39-0.16,0.88-0.35,0.92-0.58c0.03-0.15-0.06-0.28-0.14-0.37
						c-0.24-0.25-0.71-0.42-1.05-0.37c-0.51,0.08-0.69,0.5-0.89,0.94c-0.51,1.17-0.73,2.33-0.64,3.36c0.2,2.45-0.95,4.96-3.01,6.55
						c-0.52,0.4-1.09,0.75-1.64,1.09c-0.74,0.45-1.5,0.91-2.11,1.49c-0.56,0.53-1.01,1.16-1.45,1.78c-0.51,0.72-1.04,1.46-1.75,2.04
						c-1.89,1.57-4.42,1.7-7.1,1.84c-1.67,0.09-3.39,0.18-4.93,0.65c-3.39,1.04-5.67,3.89-7.11,6.01c1.07,0.19,2.32,0.54,2.97,1.48
						c0.45,0.65,0.37,1.5-0.18,2.08c-0.55,0.58-1.38,0.65-2.11,0.19c-0.37-0.23-0.68-0.6-0.96-0.92c-0.33-0.39-0.62-0.73-0.89-0.69
						c-0.21,0.03-0.44,0.31-0.68,0.83c-0.44,0.95-0.65,1.99-0.85,3c-0.09,0.45-0.18,0.91-0.3,1.36c-0.21,0.85-0.41,1.71-0.6,2.55
						c-0.26,1.15-0.54,2.35-0.85,3.51c-1.34,4.95-2.58,9.41-4.1,13.83c-0.4,1.16-0.94,2.68-1.59,4.17l-0.2-0.09
						c0.64-1.48,1.18-2.99,1.58-4.15c1.52-4.41,2.75-8.87,4.09-13.81c0.32-1.16,0.59-2.35,0.85-3.51c0.19-0.84,0.39-1.7,0.6-2.55
						c0.11-0.45,0.21-0.91,0.3-1.35c0.21-1.02,0.42-2.07,0.87-3.05c0.28-0.61,0.55-0.92,0.85-0.96c0.38-0.05,0.71,0.33,1.08,0.77
						c0.26,0.31,0.56,0.66,0.91,0.88c0.77,0.49,1.46,0.24,1.84-0.16c0.39-0.41,0.61-1.15,0.16-1.81c-0.64-0.93-2.02-1.26-2.99-1.42
						l-0.17-0.03l0.09-0.14c1.45-2.16,3.79-5.15,7.32-6.23c1.57-0.48,3.31-0.57,4.99-0.66c2.64-0.14,5.14-0.27,6.98-1.79
						c0.69-0.57,1.21-1.3,1.71-2c0.44-0.62,0.9-1.27,1.48-1.81c0.64-0.6,1.41-1.07,2.15-1.52c0.55-0.33,1.12-0.68,1.62-1.07
						c2-1.55,3.12-3.99,2.92-6.37c-0.09-1.06,0.14-2.26,0.66-3.46c0.2-0.46,0.42-0.98,1.05-1.07c0.41-0.06,0.95,0.13,1.24,0.43
						c0.17,0.17,0.23,0.37,0.2,0.56c-0.07,0.34-0.57,0.54-1.06,0.74c-0.27,0.11-0.52,0.21-0.65,0.31c-0.33,0.27-0.61,0.74-0.47,1.18
						c0.07,0.2,0.24,0.39,0.47,0.49c0.22,0.09,0.45,0.1,0.63,0.01c0.13-0.06,0.23-0.15,0.34-0.25c0.07-0.06,0.14-0.13,0.22-0.19
						c0.32-0.23,0.73-0.32,1.12-0.39l0.15-0.03c2.1-0.43,4-1.6,5.23-3.2l0.17-0.22l0.02,0.28c0.05,0.66,0.69,1.06,1.36,1.48
						c0.3,0.19,0.62,0.39,0.88,0.62c1.5,1.3,1.15,3.44,0.6,5.25l10.56-8.13l-0.12,0.34c-0.09,0.25,0,0.47,0.08,0.61
						c0.19,0.32,0.57,0.57,0.98,0.64c0.71,0.12,1.45-0.14,2.05-0.36l5.01-1.79c0.75-0.27,1.59-0.57,2.17-1.14
						c0.41-0.4,0.65-0.89,0.91-1.41c0.1-0.19,0.2-0.4,0.31-0.59c0.36-0.63,0.98-1.41,1.92-1.53c0.53-0.07,1.13,0.21,1.42,0.66
						c0.2,0.3,0.22,0.63,0.05,0.91l-0.02,0.04l-0.05,0.01c-1.14,0.25-1.97,1.4-1.86,2.56c0.11,1.14,0.49,2.6,0.5,2.61l0.02,0.07
						l-0.06,0.04c-0.02,0.01-1.64,1.28-1.79,2.37c-0.04,0.32-0.05,0.62-0.06,0.93c-0.02,0.8-0.04,1.63-0.67,3.02
						c-1.04,2.3-0.1,4.7-0.09,4.73l0.03,0.09l-0.08,0.05c-0.42,0.25-10.18,6.11-11.44,6.28c-1.17,0.15-1.81,0.35-1.91,0.58
						c-0.05,0.12,0.05,0.27,0.17,0.44c0.14,0.21,0.31,0.45,0.2,0.71c-0.15,0.35-0.77,0.62-2.03,0.86c-2.38,0.46-4.79,0.96-7.15,1.49
						c-0.65,0.15-1.32,0.34-2.03,0.54c-1.46,0.41-2.97,0.84-4.4,0.89c-0.79,0.02-1.29-0.36-1.74-0.71
						c-0.37-0.28-0.72-0.55-1.19-0.57c-0.22-0.01-0.44,0.08-0.65,0.17c-0.9,0.4-1.54,0.81-2,1.31c-1.39,1.52-0.53,2.94,0.38,4.45
						c0.48,0.79,0.97,1.6,1.12,2.43c0.11,0.6,0.12,1.44-0.44,2.05c-0.63,0.68-1.71,0.77-2.51,0.56c-0.89-0.24-1.62-0.8-2.21-1.32
						c-0.14-0.12-0.27-0.24-0.41-0.37c-0.47-0.43-0.96-0.87-1.52-1.21c-1.75-1.04-3.6-0.66-5.56-0.25l-0.35,0.07
						c-2.45,0.5-4.17,0.3-5.25-0.63c-0.06-0.05-0.11-0.1-0.17-0.15c-0.23-0.21-0.45-0.41-0.74-0.45c-0.33-0.05-0.62,0.12-0.94,0.29
						c-0.13,0.07-0.27,0.15-0.41,0.21c-1.09,0.47-1.7-0.08-2.28-0.61c-0.19-0.18-0.39-0.36-0.61-0.5c-0.45-0.3-0.94-0.4-1.31-0.25
						c-0.31,0.12-0.52,0.39-0.61,0.78c-0.14,0.58-0.63,3.03-0.54,3.28c0-0.01-0.03-0.04-0.06-0.04l-0.02,0.21l0,0l0,0
						c-0.02,0-0.03,0-0.04,0.01c-0.46,0.61,2.38,7.66,4.67,13.32c3.09,7.65,4.96,12.36,4.42,12.88
						C631.21,1089.91,631.16,1089.94,631.09,1089.94z"/>
				</g>
				<g>
					<path className="st24" d="M727.56,1043.38c0.04,0.17,0.12,0.35,0.22,0.53c0.3,0.52,0.8,0.89,1.15,1.37c1.3,1.79,0.3,4.59,2.22,6.03
						c0.61,0.46,1.39,0.63,2.12,0.86c2.45,0.79,3.29,3.34,3.76,5.62c0.59,2.88,1.06,5.83,2.35,8.46c0.78,1.59,1.84,3.02,2.61,4.61
						c0.77,1.59,1.23,3.44,0.71,5.12c-0.2,0.65-0.52,1.45-0.05,1.94c0.4,0.42,1.08,0.3,1.62,0.11c1.21-0.42,2.35-1.02,3.38-1.79
						c-0.8-0.35-1.29-1.15-1.7-1.91c-1.03-1.92-1.91-3.92-2.61-5.98c-0.58-1.68-1.67-4.14-1.31-5.92c1.56-0.27,2.55,1.69,3.37,2.67
						c1.98,2.35,4.14,3.32,3.78,6.77c-0.1,1.02-0.21,2.22,0.58,2.88c0.6,0.5,1.47,0.45,2.22,0.68c1.15,0.35,2.06,1.17,3.24,0.4
						c0.52-0.34,0.94-0.82,1.49-1.12c0.63-0.35,1.39-0.4,2.02-0.74c0.74-0.39,0.98-1.03,1.58-1.56c0.51-0.45,1.22-0.67,1.82-0.96
						c-0.36,0.17-0.83-1.46-0.84-1.7c-0.05-1.47,0.23-2.9,0.16-4.38c-0.01-0.17-0.01-0.35,0.1-0.49c0.08-0.1,0.2-0.14,0.32-0.2
						c1-0.49,1.11-1.6,1.54-2.5c0.71-1.48,3.07-2.11,2.83-4.07c-0.12-0.93-0.98-0.75-1.6-1.06c-0.49-0.25-0.94-0.59-1.35-0.95
						c-3.41-2.95-5.83-6.64-8.78-9.98c-1.56-1.77-3.5-3.14-5.28-4.67c-0.91-0.78-1.77-1.66-2.5-2.61c-0.66-0.85-1.03-2.09-1.93-2.71
						c-0.32-0.22-0.7-0.35-1.08-0.47c-0.47-0.15-0.94-0.31-1.44-0.32c-1.03-0.02-1.62,0.89-2.58,1.11c-1.08,0.25-2.21,0.22-3.3,0.44
						c-1.78,0.36-5.02,0.4-6.39,1.66c-0.44,0.4-0.71,0.96-1.02,1.46C728.34,1041.05,727.24,1042.13,727.56,1043.38z M756.19,1056.71
						c-0.42,0.65-0.56,1.21-0.55,1.7c0,0.11,0.01,0.22,0.03,0.32c0.02,0.1,0.04,0.2,0.07,0.3c0.03,0.1,0.06,0.2,0.1,0.29
						c0.03,0.07,0.06,0.14,0.09,0.21c0.02,0.05,0.05,0.1,0.07,0.14c0.02,0.05,0.05,0.09,0.07,0.14c0.1,0.19,0.21,0.38,0.32,0.56
						c0.01,0.01,0.02,0.03,0.03,0.04c0.05,0.1,0.11,0.19,0.16,0.29c0.15,0.29,0.28,0.58,0.34,0.89c0.01,0,0.02,0,0.04,0.01h0
						c0,0,0,0,0,0l0,0c0.2,0.2,0.4,0.39,0.59,0.59c0,0.02,0,0.03,0,0.05c0.92,0.53,1.38,1.16,1.58,1.86
						c0.03,0.11,0.06,0.23,0.08,0.35c0.04,0.21,0.05,0.43,0.05,0.64c0,0.26-0.02,0.52-0.05,0.78c-0.02,0.2-0.05,0.4-0.08,0.6
						c-0.09,0.53-0.2,1.07-0.26,1.59c-0.71-1.88-1.47-3.75-1.32-5.82c-0.01-0.01-0.02-0.01-0.03-0.02v0c0,0,0,0,0,0h0
						c-0.2-0.2-0.4-0.39-0.59-0.59c0,0,0,0,0,0c0-0.01-0.01-0.02-0.01-0.04c-0.78-0.11-1.43-0.42-1.94-0.86
						c-0.07-0.06-0.14-0.13-0.21-0.2c-0.09-0.1-0.18-0.2-0.26-0.31c-0.08-0.1-0.15-0.2-0.21-0.31c-0.18-0.3-0.33-0.63-0.43-0.99
						C753.5,1057.76,754.63,1057.4,756.19,1056.71z M744.34,1052.45c1.6,2.08,1.85,4.42,2.21,6.7c0.44,0.18,0.87,0.42,1.33,0.54
						c3,0.78,3.88,2.5,2.3,5.48c-1.45-1.67-1.79-4.1-3.79-5.26c-1.6,0.45-2.54,0.09-2.65-1.8c-0.13-2.18-0.45-4.35-0.75-7.07
						C743.53,1051.51,743.97,1051.97,744.34,1052.45z"/>
					<path className="st23" d="M743.41,1078.31c-0.35,0-0.63-0.11-0.83-0.32c-0.47-0.5-0.24-1.23-0.04-1.87l0.06-0.18
						c0.56-1.81-0.08-3.74-0.71-5.05c-0.39-0.8-0.86-1.58-1.32-2.34c-0.44-0.73-0.9-1.49-1.29-2.27c-1.12-2.28-1.63-4.83-2.12-7.29
						c-0.08-0.4-0.16-0.8-0.24-1.19c-0.85-4.13-2.42-5.13-3.68-5.54c-0.13-0.04-0.27-0.08-0.4-0.12c-0.6-0.18-1.23-0.37-1.74-0.76
						c-1.12-0.83-1.29-2.12-1.45-3.36c-0.12-0.95-0.25-1.93-0.8-2.69c-0.15-0.2-0.32-0.39-0.49-0.57c-0.24-0.25-0.48-0.51-0.66-0.81
						c-0.1-0.18-0.18-0.37-0.23-0.55l0,0c-0.27-1.04,0.4-1.97,0.99-2.8c0.16-0.22,0.31-0.43,0.44-0.64
						c0.08-0.12,0.15-0.24,0.22-0.37c0.23-0.39,0.47-0.8,0.82-1.12c1.05-0.96,3.16-1.23,4.86-1.45c0.58-0.07,1.13-0.15,1.59-0.24
						c0.52-0.1,1.05-0.15,1.56-0.2c0.58-0.05,1.17-0.11,1.74-0.24c0.4-0.09,0.74-0.31,1.07-0.53c0.45-0.29,0.92-0.6,1.54-0.59
						c0.52,0.01,1.02,0.18,1.47,0.32c0.36,0.12,0.76,0.25,1.1,0.48c0.59,0.41,0.96,1.07,1.32,1.71c0.21,0.37,0.4,0.72,0.64,1.02
						c0.7,0.91,1.54,1.78,2.49,2.6c0.52,0.45,1.06,0.89,1.59,1.31c1.28,1.04,2.59,2.11,3.71,3.37c0.99,1.13,1.94,2.31,2.86,3.46
						c1.8,2.24,3.65,4.56,5.91,6.51c0.5,0.44,0.91,0.72,1.33,0.93c0.18,0.09,0.38,0.14,0.57,0.18c0.49,0.11,0.99,0.23,1.08,0.96
						c0.16,1.26-0.7,1.99-1.54,2.7c-0.51,0.43-1.03,0.88-1.3,1.43c-0.11,0.23-0.21,0.49-0.29,0.73c-0.26,0.71-0.53,1.44-1.3,1.82
						l-0.06,0.03c-0.09,0.04-0.17,0.08-0.22,0.14c-0.08,0.1-0.08,0.25-0.07,0.41c0.03,0.76-0.02,1.52-0.08,2.25
						c-0.05,0.7-0.11,1.42-0.08,2.13c0.01,0.2,0.36,1.43,0.65,1.6c0.02,0.01,0.03,0.01,0.04,0.01c0,0,0,0,0,0l0.09,0.2c0,0,0,0,0,0
						c-0.16,0.08-0.33,0.15-0.5,0.22c-0.46,0.2-0.94,0.4-1.3,0.72c-0.23,0.2-0.41,0.43-0.58,0.64c-0.27,0.34-0.55,0.68-1.02,0.93
						c-0.32,0.17-0.67,0.27-1.01,0.37c-0.34,0.1-0.7,0.2-1.01,0.37c-0.3,0.16-0.56,0.39-0.82,0.61c-0.21,0.18-0.43,0.36-0.66,0.51
						c-0.93,0.61-1.7,0.28-2.52-0.07c-0.26-0.11-0.53-0.23-0.81-0.32c-0.24-0.07-0.51-0.12-0.76-0.16
						c-0.53-0.09-1.07-0.19-1.49-0.54c-0.85-0.71-0.71-2.02-0.61-2.97c0.26-2.58-0.89-3.74-2.35-5.19c-0.46-0.46-0.94-0.94-1.41-1.5
						c-0.17-0.2-0.34-0.44-0.53-0.69c-0.68-0.94-1.53-2.1-2.67-1.95c-0.26,1.45,0.44,3.37,1.01,4.91c0.11,0.31,0.22,0.61,0.32,0.89
						c0.7,2.05,1.58,4.06,2.6,5.96c0.39,0.73,0.87,1.53,1.65,1.86l0.17,0.07l-0.15,0.11c-1.04,0.78-2.18,1.38-3.41,1.8
						C743.98,1078.25,743.67,1078.31,743.41,1078.31z M727.67,1043.35c0.04,0.17,0.11,0.34,0.21,0.5c0.16,0.29,0.39,0.52,0.63,0.77
						c0.18,0.19,0.36,0.38,0.51,0.59c0.59,0.81,0.72,1.81,0.84,2.79c0.16,1.25,0.31,2.44,1.36,3.21c0.49,0.36,1.09,0.55,1.68,0.72
						c0.14,0.04,0.27,0.08,0.41,0.13c2.52,0.81,3.37,3.47,3.83,5.7c0.08,0.4,0.16,0.8,0.24,1.2c0.49,2.45,0.99,4.98,2.1,7.24
						c0.38,0.77,0.84,1.53,1.28,2.25c0.46,0.76,0.94,1.54,1.33,2.36c0.65,1.34,1.3,3.33,0.72,5.2l-0.06,0.18
						c-0.19,0.59-0.4,1.25-0.01,1.66c0.29,0.3,0.79,0.33,1.51,0.08c1.15-0.4,2.22-0.96,3.21-1.67c-0.74-0.4-1.21-1.17-1.59-1.88
						c-1.03-1.91-1.91-3.93-2.62-6c-0.09-0.28-0.2-0.57-0.32-0.88c-0.58-1.59-1.31-3.58-1-5.1l0.01-0.07l0.07-0.01
						c1.32-0.23,2.22,1.02,2.96,2.03c0.18,0.25,0.35,0.48,0.52,0.68c0.47,0.55,0.94,1.03,1.4,1.48c1.44,1.44,2.69,2.68,2.41,5.37
						c-0.09,0.91-0.22,2.15,0.54,2.79c0.38,0.32,0.87,0.4,1.39,0.49c0.26,0.05,0.53,0.09,0.79,0.17c0.29,0.09,0.57,0.21,0.84,0.32
						c0.79,0.34,1.48,0.64,2.31,0.09c0.22-0.15,0.43-0.32,0.64-0.5c0.27-0.22,0.54-0.46,0.86-0.63c0.34-0.19,0.7-0.29,1.06-0.39
						c0.34-0.1,0.67-0.19,0.97-0.35c0.43-0.23,0.68-0.54,0.95-0.88c0.18-0.22,0.36-0.45,0.61-0.67c0.39-0.34,0.88-0.55,1.35-0.76
						c0.09-0.04,0.17-0.07,0.25-0.11c-0.35-0.36-0.66-1.52-0.67-1.71c-0.03-0.73,0.03-1.45,0.08-2.15c0.06-0.73,0.11-1.48,0.08-2.23
						c-0.01-0.15-0.02-0.39,0.12-0.56c0.08-0.1,0.2-0.15,0.3-0.2l0.06-0.03c0.7-0.34,0.94-1,1.19-1.7c0.09-0.25,0.19-0.51,0.3-0.75
						c0.29-0.59,0.83-1.06,1.35-1.5c0.83-0.7,1.61-1.37,1.46-2.51c-0.07-0.58-0.45-0.67-0.92-0.78c-0.21-0.05-0.42-0.1-0.62-0.2
						c-0.43-0.22-0.86-0.51-1.37-0.96c-2.27-1.96-4.13-4.29-5.93-6.54c-0.92-1.14-1.86-2.33-2.86-3.45
						c-1.1-1.25-2.41-2.31-3.68-3.34c-0.53-0.43-1.07-0.87-1.59-1.32c-0.96-0.83-1.81-1.71-2.52-2.63
						c-0.25-0.32-0.45-0.69-0.65-1.05c-0.34-0.62-0.7-1.26-1.25-1.64c-0.31-0.22-0.69-0.34-1.05-0.46c-0.45-0.15-0.92-0.3-1.41-0.31
						c-0.55-0.01-0.97,0.26-1.42,0.55c-0.35,0.23-0.7,0.46-1.14,0.56c-0.58,0.14-1.19,0.19-1.77,0.24c-0.51,0.05-1.03,0.09-1.54,0.2
						c-0.46,0.09-1.01,0.16-1.6,0.24c-1.67,0.21-3.74,0.48-4.74,1.39c-0.32,0.3-0.56,0.69-0.78,1.07c-0.07,0.13-0.15,0.25-0.23,0.37
						c-0.14,0.22-0.29,0.43-0.45,0.65C728.04,1041.56,727.43,1042.41,727.67,1043.35L727.67,1043.35z M758.91,1068.5l-0.25-0.65
						c-0.67-1.76-1.36-3.57-1.24-5.55h0l0-0.01l-0.6-0.6c-0.75-0.12-1.4-0.42-1.93-0.88c-0.08-0.07-0.15-0.13-0.22-0.21
						c-0.09-0.09-0.18-0.2-0.27-0.32c-0.09-0.12-0.16-0.22-0.22-0.32c-0.19-0.31-0.34-0.65-0.44-1.01
						c-0.34-1.16,0.65-1.58,2.02-2.18c0.13-0.06,0.26-0.11,0.39-0.17l0.33-0.14l-0.19,0.3c-0.37,0.58-0.55,1.12-0.54,1.64
						c0,0.1,0.01,0.2,0.03,0.3c0.02,0.1,0.04,0.19,0.06,0.29c0.03,0.09,0.06,0.18,0.1,0.28c0.03,0.07,0.06,0.13,0.09,0.2
						c0.02,0.05,0.04,0.09,0.07,0.14s0.05,0.09,0.07,0.14c0.08,0.15,0.17,0.3,0.25,0.45l0.09,0.16c0.05,0.1,0.11,0.19,0.16,0.29
						c0.17,0.32,0.27,0.59,0.33,0.85l0,0l0,0.01l0.02,0.01l0.63,0.63l0,0.03c0.84,0.49,1.35,1.11,1.57,1.89
						c0.03,0.11,0.06,0.23,0.08,0.36c0.04,0.21,0.05,0.43,0.06,0.66c0,0.24-0.01,0.5-0.05,0.79c-0.02,0.2-0.05,0.4-0.09,0.6
						c-0.03,0.17-0.06,0.35-0.09,0.53c-0.06,0.35-0.13,0.71-0.17,1.06L758.91,1068.5z M757.62,1062.42
						c-0.08,1.83,0.55,3.55,1.18,5.2c0.03-0.22,0.07-0.44,0.11-0.66c0.03-0.17,0.06-0.35,0.09-0.52c0.03-0.2,0.06-0.39,0.08-0.59
						c0.03-0.28,0.05-0.53,0.05-0.77c0-0.22-0.02-0.43-0.05-0.63c-0.02-0.11-0.04-0.23-0.08-0.33
						C758.81,1063.43,758.35,1062.88,757.62,1062.42z M755.93,1056.94c-0.03,0.01-0.06,0.03-0.09,0.04
						c-1.47,0.64-2.17,0.99-1.9,1.92c0.1,0.35,0.24,0.67,0.42,0.96c0.06,0.09,0.12,0.19,0.21,0.3c0.09,0.12,0.17,0.22,0.26,0.3
						c0.07,0.07,0.13,0.13,0.2,0.19c0.48,0.42,1.06,0.69,1.73,0.81c-0.06-0.21-0.15-0.44-0.29-0.71c-0.05-0.09-0.1-0.19-0.16-0.28
						l-0.09-0.16c-0.09-0.15-0.17-0.3-0.25-0.45c-0.03-0.05-0.05-0.09-0.07-0.14c-0.02-0.05-0.05-0.1-0.07-0.15
						c-0.03-0.07-0.06-0.14-0.09-0.21c-0.04-0.1-0.07-0.2-0.1-0.3c-0.03-0.11-0.05-0.21-0.07-0.32c-0.02-0.11-0.03-0.22-0.03-0.33
						C755.52,1057.93,755.65,1057.45,755.93,1056.94z M750.21,1065.37l-0.1-0.12c-0.58-0.67-0.99-1.46-1.38-2.23
						c-0.61-1.18-1.18-2.3-2.34-2.99c-0.92,0.25-1.56,0.22-2.01-0.1c-0.44-0.32-0.68-0.91-0.74-1.81c-0.09-1.49-0.26-2.94-0.46-4.62
						c-0.09-0.77-0.19-1.57-0.28-2.44l-0.03-0.27l0.21,0.17c0.51,0.43,0.97,0.91,1.35,1.41h0c1.49,1.93,1.82,4.11,2.14,6.21
						c0.02,0.16,0.05,0.32,0.08,0.49c0.12,0.05,0.24,0.11,0.37,0.16c0.29,0.13,0.59,0.27,0.9,0.35c1.57,0.41,2.53,1.05,2.94,1.98
						c0.42,0.95,0.23,2.15-0.57,3.66L750.21,1065.37z M746.42,1059.81l0.04,0.02c1.24,0.72,1.86,1.93,2.46,3.1
						c0.36,0.71,0.74,1.44,1.25,2.06c0.71-1.38,0.87-2.47,0.49-3.33c-0.38-0.86-1.29-1.47-2.8-1.86c-0.32-0.08-0.63-0.23-0.93-0.36
						c-0.14-0.06-0.28-0.13-0.41-0.18l-0.06-0.02l-0.01-0.06c-0.03-0.18-0.06-0.36-0.08-0.55c-0.32-2.07-0.65-4.22-2.1-6.11l0,0
						c-0.32-0.42-0.7-0.82-1.12-1.2c0.08,0.76,0.17,1.47,0.25,2.16c0.2,1.69,0.38,3.14,0.46,4.64c0.05,0.83,0.26,1.37,0.65,1.65
						c0.4,0.28,1.01,0.3,1.87,0.06L746.42,1059.81z"/>
				</g>
				<g>
					<path className="st24" d="M779.49,1073.47c1.95-0.06,4.46,0.9,6.03-0.7c0.97-0.99,1.43-2.47,2.8-3.04
						c0.55-0.23,1.16-0.26,1.72-0.47c0.55-0.21,1.08-0.6,1.67-0.56c1.38,0.09,1.63,2.35,2.95,2.22c0.26-0.02,0.49-0.17,0.72-0.3
						c1.77-1.07,3.76-1.68,5.64-2.49c0.67-0.29,1.97-0.53,2.23-1.34c0.43-1.33-0.96-2.32-1.36-3.45c-0.52-1.45-0.12-3.49,0.29-4.94
						c0.21-0.73,0.48-1.46,0.5-2.23s-0.31-1.59-0.99-1.92c-0.48-0.23-1.05-0.18-1.59-0.14c-3.26,0.27-6.56,0.03-9.75-0.72
						c-0.28,0.75,0.54,1.47,1.32,1.67c0.78,0.2,1.67,0.19,2.27,0.73c0.49,0.44,0.65,1.14,0.69,1.8c0.29,3.87-2.32,7.52-5.68,9.46
						c-2.09,1.21-4.45,1.88-6.83,2.25c-1.47,0.23-8.6,0.89-4.79-1.81c1.26-0.9,2.56-2.07,3.67-3.13c0.06-0.06-2.14-1.6-2.3-1.69
						c-1.86-1-4.18-1.23-6.13-0.35c-2.17,0.98-3.75,2.26-6.3,2.14c-0.47-0.02-0.93-0.08-1.4-0.04c-1.12,0.11-1.39,0.54-1.1,1.57
						c0.22,0.79,0.24,1.64,0.07,2.45c-0.19,0.88-0.45,1.9,0.14,2.7c0.81,1.11,2.8-0.27,3.81-0.42c1.37-0.21,2.83,0.06,4.1,0.59
						c2.17,0.89,3.18,2.65,5.68,2.33C778.2,1073.54,778.85,1073.49,779.49,1073.47z"/>
					<path className="st23" d="M782.9,1073.81c-0.55,0-1.13-0.06-1.69-0.12c-0.61-0.07-1.18-0.13-1.71-0.11l0,0
						c-0.64,0.02-1.29,0.07-1.92,0.15c-1.67,0.21-2.7-0.48-3.8-1.22c-0.59-0.4-1.19-0.81-1.94-1.11c-1.36-0.56-2.8-0.77-4.05-0.58
						c-0.28,0.04-0.66,0.19-1.06,0.35c-0.99,0.39-2.22,0.88-2.85,0.03c-0.59-0.81-0.37-1.8-0.18-2.68l0.02-0.11
						c0.18-0.79,0.15-1.62-0.07-2.39c-0.16-0.57-0.15-0.95,0.04-1.22c0.19-0.27,0.55-0.43,1.16-0.48c0.35-0.03,0.7-0.01,1.04,0.01
						c0.13,0.01,0.25,0.02,0.38,0.02c1.9,0.09,3.28-0.63,4.74-1.39c0.48-0.25,0.98-0.51,1.51-0.75c1.9-0.86,4.23-0.73,6.23,0.35
						c0.02,0.01,2.33,1.56,2.36,1.76l0.01,0.05l-0.04,0.04c-1.45,1.4-2.62,2.4-3.68,3.14c-1.01,0.71-1.12,1.1-1.04,1.3
						c0.33,0.82,4.2,0.56,5.75,0.32c2.7-0.42,4.92-1.15,6.79-2.23c3.02-1.75,5.92-5.32,5.62-9.36c-0.06-0.82-0.28-1.38-0.66-1.72
						c-0.41-0.37-0.96-0.47-1.55-0.57c-0.22-0.04-0.45-0.08-0.67-0.13c-0.57-0.15-1.11-0.54-1.33-0.97
						c-0.14-0.28-0.17-0.57-0.06-0.84l0.03-0.09l0.09,0.02c3.19,0.74,6.46,0.98,9.72,0.72l0.03,0c0.54-0.04,1.11-0.09,1.61,0.15
						c0.8,0.38,1.07,1.33,1.06,2.02c-0.01,0.62-0.19,1.22-0.37,1.8c-0.05,0.15-0.09,0.3-0.13,0.45c-0.58,2.08-0.68,3.77-0.29,4.88
						c0.13,0.38,0.38,0.74,0.64,1.12c0.5,0.73,1.02,1.48,0.72,2.4c-0.22,0.68-1.09,0.98-1.79,1.22c-0.19,0.06-0.37,0.13-0.51,0.19
						c-0.58,0.25-1.18,0.48-1.76,0.71c-1.3,0.51-2.65,1.03-3.87,1.77c-0.24,0.14-0.48,0.29-0.76,0.32
						c-0.74,0.07-1.16-0.53-1.58-1.11c-0.38-0.53-0.77-1.08-1.39-1.12c-0.39-0.03-0.75,0.15-1.13,0.33
						c-0.16,0.08-0.33,0.16-0.5,0.22c-0.27,0.1-0.56,0.17-0.84,0.23c-0.29,0.06-0.6,0.13-0.88,0.25c-0.85,0.35-1.33,1.06-1.84,1.82
						c-0.28,0.41-0.57,0.84-0.92,1.2C784.85,1073.61,783.9,1073.81,782.9,1073.81z M779.67,1073.36c0.5,0,1.02,0.06,1.57,0.12
						c1.53,0.17,3.12,0.34,4.22-0.79c0.34-0.35,0.62-0.76,0.89-1.17c0.5-0.75,1.03-1.52,1.94-1.9c0.3-0.12,0.61-0.19,0.91-0.26
						c0.27-0.06,0.55-0.12,0.81-0.22c0.16-0.06,0.32-0.14,0.48-0.22c0.39-0.19,0.79-0.38,1.24-0.35c0.73,0.05,1.15,0.64,1.55,1.21
						c0.39,0.55,0.77,1.07,1.38,1.02c0.23-0.02,0.46-0.16,0.67-0.29c1.23-0.75,2.59-1.28,3.91-1.79c0.58-0.23,1.18-0.46,1.75-0.71
						c0.15-0.06,0.33-0.13,0.52-0.19c0.65-0.22,1.46-0.5,1.65-1.08c0.27-0.82-0.2-1.5-0.69-2.21c-0.26-0.38-0.53-0.76-0.67-1.17
						c-0.42-1.17-0.32-2.86,0.28-5.01c0.04-0.15,0.09-0.31,0.13-0.46c0.17-0.57,0.35-1.15,0.36-1.74c0.01-0.63-0.23-1.48-0.93-1.82
						c-0.45-0.22-0.98-0.17-1.5-0.13l-0.03,0c-3.25,0.27-6.51,0.03-9.69-0.7c-0.05,0.19-0.02,0.38,0.08,0.57
						c0.2,0.38,0.68,0.73,1.19,0.86c0.21,0.05,0.44,0.09,0.65,0.13c0.59,0.1,1.2,0.21,1.66,0.62c0.43,0.38,0.67,0.99,0.73,1.87
						c0.31,4.13-2.66,7.78-5.73,9.56c-1.89,1.09-4.13,1.83-6.86,2.26c-0.05,0.01-5.49,0.77-5.99-0.45c-0.16-0.4,0.2-0.91,1.12-1.56
						c1.03-0.73,2.17-1.7,3.58-3.05c-0.34-0.3-2.04-1.49-2.21-1.59c-1.94-1.04-4.2-1.17-6.04-0.34c-0.53,0.24-1.02,0.49-1.5,0.74
						c-1.49,0.77-2.89,1.5-4.85,1.41c-0.13-0.01-0.26-0.01-0.38-0.02c-0.33-0.02-0.67-0.05-1-0.02c-0.53,0.05-0.85,0.18-1,0.39
						c-0.14,0.21-0.15,0.54-0.01,1.04c0.23,0.8,0.25,1.67,0.07,2.5l-0.02,0.11c-0.18,0.83-0.39,1.77,0.14,2.51
						c0.52,0.72,1.62,0.28,2.59-0.1c0.41-0.16,0.8-0.32,1.11-0.36c1.29-0.19,2.76,0.02,4.16,0.59c0.77,0.32,1.38,0.73,1.98,1.13
						c1.11,0.75,2.06,1.39,3.65,1.19c0.64-0.08,1.3-0.13,1.94-0.15l0,0C779.55,1073.36,779.61,1073.36,779.67,1073.36z"/>
				</g>
				<g>
					<g>
						<path className="st23" d="M741.87,1034.8c-0.65-0.32-1.1-0.86-1.38-1.67l-0.01-0.07l-0.12-14.88l0.28,0.08
							c1.85,0.55,3.75,1.25,5.93,2.2c0,0,0,0,0,0c0,0,0,0,0,0c0.25,0.11,0.5,0.22,0.75,0.33c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0
							l-0.18,0.39c0,0-0.01,0-0.01-0.01c0,0,0,0-0.01,0c0,0-0.01,0-0.01,0c0,0-0.01,0-0.01-0.01c-0.01,0-0.01-0.01-0.02-0.01
							c-0.01,0-0.01,0-0.02-0.01c-0.01,0-0.02-0.01-0.03-0.01c-0.01,0-0.01,0-0.02-0.01c0,0,0,0,0,0c-0.01,0-0.01-0.01-0.02-0.01
							l0,0c0,0-0.01,0-0.01,0c-2.32-1.03-4.31-1.79-6.22-2.37l0.11,14.26c0.23,0.68,0.61,1.13,1.14,1.39L741.87,1034.8z"/>
					</g>
					<g>
						<polygon className="st23" points="795.71,1046.25 790.92,1046.14 789.94,1046.15 789.94,1045.72 790.92,1045.71 795.72,1045.81 
													"/>
					</g>
					<g>
						<path className="st23" d="M688.6,1017.44l-0.01-0.16l-0.21,0.02l-0.01-0.05h0l-0.03-0.23l0.23-0.01c0.07,0,0.19,0.02,0.22,0.19
							l0,0l0.01,0.05c0,0,0,0,0,0l0,0l-0.22,0.02L688.6,1017.44z"/>
					</g>
					<g>
						<path className="st23" d="M742.39,1003.6l-0.05-0.43c0.02,0,0.04-0.01,0.06-0.01c0.14-0.02,0.27-0.05,0.39-0.1l0,0c0,0,0,0,0,0
							l0.15,0.41c-0.07,0.03-0.14,0.05-0.21,0.07C742.61,1003.57,742.5,1003.59,742.39,1003.6z"/>
					</g>
					<g>
						<path className="st23" d="M748.86,1010.55c-1.75,0-3.57-0.13-5.41-0.51l-0.11-0.02l-0.05-0.1c-0.11-0.25-0.21-0.51-0.28-0.78
							c-0.07-0.25-0.13-0.51-0.17-0.79c0,0,0,0,0,0l0,0l0.43-0.06c0.04,0.26,0.09,0.5,0.16,0.74c0.06,0.21,0.13,0.42,0.22,0.62
							c3.51,0.7,6.99,0.5,9.93,0.21l0.04,0.43C752.15,1010.43,750.54,1010.55,748.86,1010.55z"/>
					</g>
					<g>
						<polygon className="st23" points="751.44,1002.66 750.8,988.44 750.83,988.41 751.17,987.03 751.87,1002.64 						"/>
					</g>
					<g>
						<path className="st23" d="M724.87,1031.25c-0.11-0.02-0.22-0.03-0.33-0.03l0.01-0.43c0.13,0,0.26,0.02,0.39,0.04L724.87,1031.25z"
							/>
					</g>
					<g>
						<path className="st23" d="M796.81,1046.21l-0.27-0.33c0.18-0.15,0.37-0.29,0.55-0.42c1.56-1.08,3.18-1.36,4.55-0.77c0,0,0,0,0,0
							l0,0c0.04,0.02,0.08,0.03,0.12,0.05c0,0,0,0,0.01,0c0,0,0,0,0,0l-0.19,0.39c-0.16-0.07-0.32-0.14-0.48-0.18
							C799.79,1044.57,798.26,1045.02,796.81,1046.21z"/>
					</g>
					<g>
						<path className="st23" d="M718.5,999.07l0.44-0.59l0.07-0.02l4.32-1.34c1.92-0.6,3.83-1.19,5.75-1.79
							c1.52-0.47,3.05-0.95,4.57-1.42c0.01,0,0.03-0.01,0.04-0.01c0,0,0,0,0,0l0.13,0.41c-0.04,0.01-0.08,0.02-0.12,0.04
							c-1.49,0.46-2.98,0.92-4.47,1.39c-2.09,0.65-4.18,1.3-6.28,1.95L718.5,999.07z"/>
					</g>
					<g>
						<polygon className="st23" points="704.83,999.06 704.71,998.65 706.27,998.21 706.4,998.62 706.38,998.62 706.38,998.62 						"/>
					</g>
					<g>
						<path className="st28" d="M816.39,1049.03c4.32,1.54,8.75,1.36,13.09,2.18c2.5,0.47,4.4,2.61,7.11,2.3
							c-0.6-2.89-1.2-5.78-1.8-8.67c-2.53-1.23-2.96-3.67-3.51-6.05c-1.96,0.06-3.91,0.12-5.87,0.19l0,0c0,0-0.97-0.28-0.97-0.24
							c0,0,0-0.04,0-0.04c-1.57,1.83-3.52,1.09-5.4,0.81c-4.66-0.7-9.44-0.48-14.03-1.74c-3.48-2.03-6.25-5.32-10.56-5.86
							c-1.56,0.35-3.08,0.22-4.6-0.02c-1.64-0.26-3.27-0.64-4.93-0.65c-4.34-0.49-8.68-0.96-13.03-1.45
							c-1.63-0.18-3.26-0.37-4.89-0.56c-1.99-0.23-3.98-0.47-5.97-0.71c-2.84-0.35-4.62-2.69-6.84-4.19
							c-1.67-0.81-3.33-1.64-5.01-2.43c-0.47-0.22-0.95-0.44-1.42-0.66c-0.17-0.08-0.35-0.16-0.53-0.24
							c-2.17-0.97-4.38-1.85-6.68-2.53c0.04,4.86,0.08,9.73,0.11,14.59c0.27,0.8,0.71,1.28,1.26,1.55c0.63,0.3,1.4,0.34,2.22,0.27
							c0.82-0.03,1.65-0.46,2.47-0.49c0.49-0.02,0.98,0.11,1.47,0.54c5.91,1.48,11.57,3.91,17.62,4.83
							c5.92,0.89,11.58,3.04,17.63,3.41c1.83,0.11,4.05,0.63,5.22,2.61c0,0,0,0,0,0c0.4,0.05,0.79,0.11,1.19,0.16c0,0,0,0,0,0
							c0.05,0,0.1,0,0.15,0c0.33,0,0.65-0.01,0.98-0.01c1.6,0.04,3.19,0.07,4.79,0.11c0.32,0.01,0.64,0.01,0.96,0.02
							c1.53-1.26,3.39-1.87,5-1.11C801.9,1045.05,816.17,1048.86,816.39,1049.03"/>
						<path className="st23" d="M836.06,1053.75c-1.42,0-2.64-0.62-3.82-1.22c-0.89-0.45-1.81-0.92-2.79-1.11
							c-1.59-0.3-3.22-0.47-4.8-0.63c-2.74-0.28-5.57-0.57-8.32-1.56l-0.04-0.02c-0.41-0.17-5.67-1.6-9.52-2.64
							c-3.99-1.09-5.09-1.39-5.18-1.43c-1.4-0.66-3.13-0.27-4.77,1.08l-0.06,0.05l-1.04-0.02l-4.79-0.11l-1.14,0.02l-1.31-0.18
							l-0.05-0.09c-0.91-1.53-2.56-2.35-5.05-2.51c-3.57-0.22-7.05-1.06-10.42-1.88c-2.36-0.57-4.79-1.16-7.23-1.53
							c-3.72-0.56-7.35-1.72-10.86-2.83c-2.21-0.7-4.5-1.43-6.78-2l-0.05-0.01l-0.04-0.04c-0.39-0.35-0.81-0.51-1.32-0.49
							c-0.39,0.01-0.77,0.12-1.19,0.24c-0.42,0.12-0.85,0.24-1.29,0.25c-1,0.09-1.74,0-2.32-0.29c-0.65-0.32-1.1-0.86-1.38-1.67
							l-0.01-0.07l-0.12-14.88l0.28,0.08c2.06,0.61,4.19,1.42,6.7,2.54c0.18,0.08,0.35,0.16,0.53,0.24
							c0.48,0.22,0.95,0.44,1.42,0.66c1.16,0.55,2.33,1.12,3.47,1.68c0.52,0.25,1.03,0.5,1.55,0.76l0.03,0.02
							c0.66,0.45,1.3,0.98,1.91,1.49c1.49,1.25,2.9,2.43,4.84,2.67c1.87,0.23,3.82,0.47,5.96,0.71c1.63,0.19,3.26,0.37,4.89,0.56
							c2.08,0.23,4.17,0.47,6.25,0.7c2.25,0.25,4.51,0.5,6.76,0.75c1.26,0.01,2.52,0.23,3.73,0.45c0.4,0.07,0.81,0.14,1.21,0.21
							c1.42,0.22,2.95,0.38,4.52,0.02l0.04-0.01l0.04,0c3.01,0.38,5.3,2.09,7.52,3.74c0.99,0.74,2.02,1.5,3.1,2.13
							c2.8,0.76,5.73,0.98,8.57,1.19c1.78,0.13,3.62,0.27,5.41,0.54c0.29,0.04,0.58,0.1,0.86,0.15c1.59,0.29,3.09,0.57,4.34-0.88
							l0.36-0.41l0.02,0.4c0.16,0.03,0.4,0.1,0.79,0.21c1.95-0.06,3.89-0.12,5.84-0.18l0.18-0.01l0.12,0.55
							c0.51,2.27,1,4.41,3.3,5.53l0.1,0.05l1.87,9l-0.24,0.03C836.43,1053.74,836.25,1053.75,836.06,1053.75z M816.47,1048.82
							c2.71,0.97,5.51,1.26,8.22,1.54c1.59,0.16,3.23,0.33,4.84,0.64c1.05,0.2,1.99,0.68,2.91,1.15c1.27,0.65,2.47,1.26,3.9,1.17
							l-1.73-8.32c-2.41-1.22-2.93-3.53-3.44-5.77l-0.04-0.2c-1.89,0.06-3.79,0.12-5.68,0.18l-0.03,0l-0.04-0.01
							c-0.3-0.08-0.64-0.18-0.81-0.21l-0.3,0.43l0-0.18c-1.33,1.21-2.88,0.92-4.38,0.65c-0.28-0.05-0.57-0.1-0.84-0.15
							c-1.78-0.27-3.61-0.4-5.38-0.53c-2.86-0.21-5.82-0.43-8.68-1.21l-0.05-0.02c-1.1-0.64-2.15-1.42-3.16-2.17
							c-2.16-1.61-4.4-3.28-7.28-3.65c-1.63,0.36-3.2,0.2-4.64-0.03c-0.41-0.06-0.81-0.14-1.22-0.21c-1.2-0.21-2.45-0.43-3.67-0.44
							l-0.02,0c-2.26-0.26-4.52-0.51-6.77-0.76c-2.08-0.23-4.17-0.46-6.25-0.7c-1.63-0.18-3.26-0.37-4.89-0.56
							c-2.14-0.25-4.1-0.48-5.97-0.72c-2.06-0.26-3.59-1.53-5.06-2.76c-0.6-0.5-1.22-1.02-1.86-1.45c-0.51-0.25-1.02-0.5-1.53-0.75
							c-1.13-0.55-2.3-1.13-3.46-1.67c-0.47-0.22-0.94-0.44-1.42-0.66c-0.17-0.08-0.35-0.16-0.52-0.24
							c-2.38-1.06-4.41-1.84-6.37-2.44l0.11,14.26c0.23,0.68,0.61,1.13,1.14,1.39c0.51,0.25,1.18,0.33,2.11,0.24
							c0.4-0.02,0.79-0.12,1.2-0.24c0.42-0.12,0.85-0.24,1.29-0.25c0.6-0.02,1.12,0.16,1.58,0.56c2.28,0.57,4.55,1.29,6.75,1.99
							c3.49,1.11,7.11,2.26,10.8,2.82c2.46,0.37,4.9,0.96,7.27,1.54c3.35,0.81,6.81,1.65,10.34,1.87c2.56,0.16,4.36,1.04,5.34,2.63
							l1.07,0.15l1.12-0.02l5.68,0.12c1.75-1.42,3.63-1.81,5.17-1.08c0.15,0.06,2.56,0.71,5.11,1.4
							C814.32,1048.18,816.12,1048.68,816.47,1048.82L816.47,1048.82z"/>
					</g>
					<g>
						<path className="st87" d="M762.3,1004.96c-0.15-0.05-0.3-0.1-0.45-0.15c-0.34-0.11-0.68-0.21-1.03-0.3
							c-0.36-0.1-0.71-0.19-1.07-0.27c-0.13-0.03-0.26-0.06-0.39-0.09c-2.55-0.58-5.16-0.92-7.71-1.5
							c-1.34-0.04-2.68-0.08-4.01-0.12c-0.19-0.01-0.39-0.02-0.58-0.03c-0.3,0.1-0.63,0.12-0.96,0.12c-0.07,0-0.13,0-0.2,0
							c-0.07,0-0.13,0-0.2,0c-0.15,0-0.3,0.01-0.44,0.02c-0.43,0.05-0.84,0.2-1.18,0.63c0.21,1.76,0.41,3.51,0.62,5.27
							c3.77,0.1,22.3,1.51,25.92,3.28c-0.78,0.09-16.24-1.82-17.02-1.74c-3.37,0.33-6.74,0.45-10.11-0.24
							c-0.23-0.5-0.36-1-0.44-1.51c-0.03-0.17-0.05-0.34-0.06-0.51c-0.02-0.25-0.03-0.5-0.04-0.75c0-0.02,0-0.05,0-0.07
							c-0.01-0.69,0.03-1.38,0.02-2.07c0-0.55-0.03-1.1-0.13-1.64c0,0,0,0,0,0c-0.16,0.06-0.32,0.1-0.49,0.12
							c-0.72,0.09-1.51-0.11-2.26-0.18c-0.12-0.01-0.25-0.02-0.37-0.02c-0.18,0-0.36,0.01-0.54,0.04c-0.1,0.02-0.2,0.04-0.3,0.07
							c-0.11,0.03-0.21,0.07-0.32,0.13c-0.36,0.18-0.7,0.5-0.99,1.01c0,4.92,0,9.83,0,14.57c1.49,0.77,2-0.77,3-0.53
							c2.3,0.68,4.5,1.56,6.68,2.53c0.18,0.08,0.35,0.16,0.53,0.24c0.47,0.22,0.95,0.43,1.42,0.66c1.68,0.79,3.34,1.62,5.01,2.43
							c10.66,0.43,20.99,2.34,30.72,6.92c1.66,0.01,3.29,0.39,4.93,0.65c1.52,0.24,3.04,0.38,4.6,0.02
							c-1.63-1.19-3.25-2.37-4.88-3.56c-0.09-0.05-0.18-0.12-0.25-0.2c-0.37-0.5-0.74-1-1.11-1.51c-2.13-1.41-4.57-2.45-6.25-4.41
							c-0.29-0.34-0.56-0.71-0.8-1.11c-0.08-0.13-0.16-0.27-0.23-0.41c-0.14-0.01-0.27-0.02-0.4-0.04s-0.25-0.04-0.38-0.06
							c-0.24-0.05-0.47-0.11-0.68-0.18c-1.25-0.45-1.84-1.42-1.44-3.25c0.64-2.92-0.15-5.26-2.92-6.72
							c-0.36-0.03-0.72-0.05-1.08-0.08c0.39,3.73,0.59,7.46,0.22,11.23c-1.31-3.77-2.6-7.53-1.46-11.64
							c-0.25-0.78-0.54-2.22-1.43-1.64c-2.55,1.65-3.53-0.87-5.18-1.57c-0.84-0.52-1.7-0.95-2.58-1.31
							C763.2,1005.27,762.75,1005.11,762.3,1004.96z"/>
						<path className="st23" d="M792.43,1032.35c-0.9,0-1.77-0.11-2.61-0.24c-0.41-0.06-0.81-0.14-1.22-0.21
							c-1.2-0.21-2.45-0.43-3.68-0.44l-0.05,0l-0.04-0.02c-8.87-4.17-18.89-6.43-30.63-6.9l-0.05,0l-0.04-0.02
							c-0.52-0.25-1.03-0.5-1.55-0.76c-1.13-0.55-2.3-1.13-3.46-1.67c-0.47-0.22-0.94-0.44-1.42-0.66
							c-0.17-0.08-0.35-0.16-0.52-0.24c-2.49-1.12-4.61-1.92-6.65-2.52c-0.31-0.07-0.58,0.09-0.92,0.31
							c-0.51,0.32-1.14,0.72-2.12,0.21l-0.12-0.06v-14.76l0.03-0.05c0.29-0.52,0.65-0.87,1.08-1.09c0.11-0.06,0.23-0.1,0.35-0.14
							c0.1-0.03,0.21-0.06,0.33-0.07c0.18-0.03,0.37-0.04,0.58-0.04c0.13,0,0.26,0.01,0.39,0.02c0.24,0.02,0.49,0.06,0.73,0.09
							c0.53,0.07,1.02,0.15,1.49,0.09c0.16-0.02,0.31-0.05,0.45-0.11l0.24-0.09l0.05,0.25c0.09,0.48,0.13,1.02,0.13,1.68
							c0,0.32-0.01,0.65-0.01,0.97c-0.01,0.36-0.02,0.73-0.01,1.09l0,0.07c0,0.28,0.02,0.52,0.03,0.74c0.01,0.17,0.03,0.34,0.06,0.5
							c0.07,0.5,0.2,0.94,0.37,1.35c3.51,0.7,6.99,0.5,9.93,0.21c0.38-0.04,3.38,0.3,8.29,0.85c2.56,0.29,5.35,0.6,7.07,0.77
							c-5.62-1.46-20.5-2.62-24.25-2.72l-0.19,0l-0.65-5.54l0.05-0.07c0.42-0.56,0.96-0.67,1.32-0.72c0.13-0.01,0.28-0.02,0.46-0.02
							c0.07,0,0.13,0,0.2,0l0.2,0c0.3,0,0.61-0.01,0.89-0.11l0.04-0.01l0.63,0.04l4.05,0.12c1.26,0.28,2.56,0.52,3.82,0.74
							c1.28,0.23,2.6,0.46,3.89,0.75c0.13,0.03,0.26,0.06,0.39,0.09c0.37,0.09,0.74,0.18,1.08,0.28c0.37,0.1,0.71,0.2,1.04,0.3
							c0.15,0.05,0.3,0.1,0.45,0.15l0,0c0.46,0.16,0.92,0.33,1.35,0.5c0.94,0.39,1.79,0.82,2.59,1.32c0.52,0.23,0.97,0.62,1.41,1
							c1.02,0.89,1.9,1.65,3.55,0.59c0.26-0.17,0.51-0.21,0.74-0.13c0.51,0.19,0.73,0.93,0.93,1.59c0.03,0.1,0.06,0.2,0.09,0.29
							l0.02,0.06l-0.02,0.06c-1.04,3.72-0.03,7.19,1.12,10.55c0.22-2.97,0.12-6.16-0.31-10.18l-0.03-0.26l1.38,0.11l0.04,0.02
							c2.71,1.43,3.73,3.77,3.03,6.95c-0.35,1.6,0.07,2.56,1.31,3c0.19,0.07,0.41,0.13,0.64,0.17c0.12,0.02,0.24,0.04,0.36,0.06
							c0.13,0.02,0.26,0.03,0.39,0.04l0.12,0.01l0.06,0.11c0.07,0.14,0.15,0.27,0.22,0.4c0.22,0.38,0.48,0.74,0.78,1.08
							c1.17,1.37,2.76,2.3,4.3,3.21c0.63,0.37,1.28,0.75,1.9,1.16l0.03,0.02l1.12,1.53c0.06,0.06,0.12,0.11,0.19,0.15l5.28,3.85
							l-0.46,0.11C793.8,1032.29,793.11,1032.35,792.43,1032.35z M784.98,1031.03c1.25,0.01,2.49,0.23,3.7,0.45
							c0.4,0.07,0.81,0.14,1.21,0.21c1.27,0.2,2.64,0.35,4.04,0.12l-4.48-3.26c-0.09-0.05-0.19-0.13-0.28-0.22l-0.02-0.02
							l-1.08-1.48c-0.6-0.4-1.24-0.77-1.85-1.13c-1.57-0.92-3.19-1.87-4.41-3.3c-0.31-0.36-0.58-0.74-0.82-1.14
							c-0.06-0.1-0.12-0.21-0.18-0.32c-0.1-0.01-0.2-0.02-0.29-0.03c-0.13-0.02-0.26-0.04-0.39-0.06c-0.26-0.05-0.49-0.11-0.71-0.19
							c-1.44-0.52-1.97-1.7-1.58-3.5c0.65-3-0.25-5.11-2.77-6.46l-0.78-0.06c0.45,4.38,0.51,7.79,0.19,11.02l-0.1,0.98l-0.32-0.93
							c-1.29-3.7-2.62-7.52-1.48-11.7c-0.02-0.08-0.05-0.16-0.07-0.24c-0.14-0.47-0.36-1.19-0.66-1.3
							c-0.04-0.01-0.14-0.05-0.35,0.08c-1.92,1.24-3.06,0.25-4.07-0.62c-0.44-0.38-0.85-0.73-1.31-0.93l-0.03-0.01
							c-0.79-0.49-1.62-0.92-2.54-1.3c-0.42-0.17-0.86-0.34-1.32-0.49c-0.15-0.05-0.3-0.1-0.45-0.15c-0.32-0.1-0.65-0.2-1.02-0.3
							c-0.34-0.09-0.7-0.18-1.07-0.27c-0.13-0.03-0.26-0.06-0.38-0.09c-1.28-0.29-2.59-0.52-3.87-0.75
							c-1.26-0.22-2.56-0.45-3.82-0.74l-3.99-0.12l-0.55-0.03c-0.33,0.11-0.68,0.12-0.99,0.12l-0.2,0c-0.07,0-0.13,0-0.2,0
							c-0.17,0-0.3,0.01-0.42,0.02c-0.43,0.05-0.73,0.19-0.97,0.48l0.58,4.99c3.88,0.13,22.12,1.49,25.82,3.29l0.67,0.32l-0.74,0.09
							c-0.39,0.04-3.75-0.32-8.83-0.9c-3.68-0.41-7.84-0.88-8.2-0.85c-3.01,0.29-6.57,0.49-10.17-0.24l-0.11-0.02l-0.05-0.1
							c-0.22-0.48-0.37-0.99-0.45-1.57c-0.03-0.17-0.05-0.34-0.06-0.53c-0.02-0.23-0.03-0.48-0.04-0.77l0-0.07
							c0-0.37,0-0.74,0.01-1.11c0.01-0.32,0.01-0.64,0.01-0.96c0-0.53-0.03-0.97-0.09-1.37c-0.09,0.02-0.19,0.04-0.29,0.05
							c-0.52,0.07-1.07-0.01-1.6-0.09c-0.23-0.03-0.48-0.07-0.71-0.09c-0.12-0.01-0.24-0.02-0.36-0.02c-0.18,0-0.35,0.01-0.5,0.03
							c-0.1,0.02-0.19,0.04-0.27,0.06c-0.1,0.03-0.19,0.07-0.28,0.11c-0.34,0.17-0.62,0.46-0.87,0.87v14.38
							c0.69,0.31,1.12,0.04,1.57-0.25c0.37-0.23,0.76-0.48,1.26-0.36c2.07,0.62,4.2,1.42,6.71,2.55c0.18,0.08,0.35,0.16,0.53,0.24
							c0.48,0.22,0.95,0.44,1.42,0.66c1.16,0.55,2.33,1.12,3.47,1.68c0.5,0.25,1,0.49,1.51,0.74
							C766.03,1024.59,776.07,1026.85,784.98,1031.03z"/>
					</g>
					<g>
						<path className="st87" d="M784.93,1031.25c1.66,0.01,3.29,0.39,4.93,0.65C788.22,1031.64,786.59,1031.26,784.93,1031.25
							C784.93,1031.25,784.93,1031.25,784.93,1031.25z"/>
						<path className="st23" d="M789.82,1032.11c-0.41-0.06-0.81-0.14-1.22-0.21c-1.2-0.21-2.45-0.43-3.67-0.44v0c0,0,0,0,0,0l0-0.43
							c0.1,0,0.2,0,0.3,0.01c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c1.16,0.04,2.32,0.24,3.44,0.44c0.4,0.07,0.81,0.14,1.21,0.21
							c0,0,0,0,0,0L789.82,1032.11z"/>
					</g>
					<g>
						<path className="st27" d="M713.85,1003.11c-0.13-0.45-0.15-0.85-0.09-1.2c0.02-0.11,0.05-0.22,0.09-0.32
							c0.02-0.05,0.03-0.09,0.05-0.14c0.02-0.06,0.05-0.11,0.08-0.17c0.39-0.71,1.18-1.2,2.12-1.53c-0.54-1.5-0.57-3.06-0.63-4.62
							c-0.01-0.36-0.02-0.72-0.04-1.08c0,0,0,0,0,0c0-0.42,0-0.83,0-1.25c-0.17-0.25-0.35-0.51-0.52-0.76c0,0,0.04,0.09,0.04,0.09
							c-0.24-0.2-0.48-0.4-0.73-0.61c-0.15-0.06-0.25-0.16-0.27-0.31c-0.78-0.94-1.56-1.88-2.43-2.92
							c-1.06,3.41-4.16,5.88-4.11,9.72c0.26,1.46,1.59,3.48,0.46,4.28c-2.15,1.52-4.85,2.34-7.58,2.58
							c-0.53,0.05-0.6-0.59-0.69-1.05c0,0,0.02,1.09,0.01,1.09c0,0-0.01-1.09-0.01-1.09c-0.32,0.13-0.64,0.18-0.96,0.3
							c-0.39,0.76-0.98,1.44-0.7,2.41c0.74,2.55,0.31,5.18,0.46,7.77c5.36-1.4,10.7-2.83,15.92-4.69c-0.12-1.76,0.04-3.53-0.23-5.29
							C714.05,1003.92,713.96,1003.51,713.85,1003.11z M710.93,1005.72c-0.91-1.65-0.84-2.66-0.16-4.35
							C711.5,1003.07,711.41,1004.08,710.93,1005.72z"/>
						<path className="st23" d="M698.21,1014.58l-0.02-0.26c-0.05-0.87-0.04-1.76-0.03-2.61c0.03-1.69,0.05-3.44-0.43-5.11
							c-0.25-0.87,0.12-1.52,0.49-2.15c0.08-0.14,0.16-0.27,0.23-0.41l0.04-0.07l0.08-0.03c0.17-0.07,0.34-0.11,0.5-0.16
							c0.15-0.04,0.3-0.08,0.45-0.14l0.25-0.13l0.07,0.39c0.1,0.55,0.18,0.79,0.44,0.77c2.89-0.25,5.47-1.13,7.47-2.54
							c0.65-0.46,0.28-1.5-0.11-2.61c-0.18-0.49-0.36-1-0.44-1.46l0-0.03c-0.03-2.39,1.13-4.27,2.25-6.09
							c0.72-1.17,1.46-2.37,1.87-3.69l0.12-0.38l0.25,0.3c0.87,1.05,1.65,1.98,2.43,2.92l0.04,0.04l0.01,0.06
							c0.01,0.05,0.03,0.11,0.13,0.15l0.04,0.01l0.06,0.05c0.22,0.19,0.45,0.37,0.67,0.56l0.03-0.02l0.56,0.81l0,1.31
							c0.02,0.35,0.03,0.71,0.04,1.07l0,0.15c0.05,1.47,0.1,2.98,0.61,4.4l0.07,0.2l-0.2,0.07c-1,0.36-1.68,0.84-2,1.43
							c-0.03,0.05-0.05,0.1-0.07,0.15c-0.02,0.04-0.03,0.08-0.05,0.12c-0.04,0.1-0.06,0.2-0.08,0.29c-0.06,0.33-0.03,0.71,0.08,1.11
							l0,0c0.11,0.4,0.2,0.8,0.27,1.25c0.16,1.09,0.17,2.18,0.18,3.25c0,0.68,0.01,1.38,0.05,2.06l0.01,0.16l-0.15,0.05
							c-5.15,1.85-10.48,3.28-15.94,4.7L698.21,1014.58z M698.8,1004.3c-0.06,0.12-0.13,0.24-0.2,0.36
							c-0.33,0.58-0.65,1.12-0.44,1.82c0.5,1.73,0.47,3.51,0.45,5.24c-0.01,0.76-0.02,1.55,0.01,2.32
							c5.31-1.39,10.49-2.78,15.5-4.57c-0.04-0.64-0.04-1.29-0.04-1.93c-0.01-1.05-0.01-2.13-0.17-3.18
							c-0.06-0.42-0.15-0.81-0.25-1.19c-0.13-0.46-0.16-0.9-0.09-1.3c0.02-0.11,0.05-0.23,0.09-0.35c0.02-0.05,0.04-0.1,0.06-0.15
							c0.03-0.06,0.06-0.12,0.09-0.18c0.35-0.65,1.04-1.17,2.03-1.56c-0.47-1.42-0.52-2.9-0.56-4.33l0-0.15
							c-0.01-0.36-0.02-0.71-0.04-1.07l0-1.19l-0.37-0.55l-0.03-0.03c-0.23-0.19-0.47-0.39-0.7-0.58l0,0
							c-0.19-0.08-0.31-0.22-0.36-0.4c-0.69-0.83-1.39-1.67-2.15-2.58c-0.44,1.22-1.13,2.34-1.79,3.42
							c-1.14,1.85-2.21,3.59-2.18,5.84c0.08,0.42,0.25,0.9,0.42,1.37c0.43,1.21,0.88,2.45-0.05,3.11c-2.06,1.46-4.72,2.36-7.68,2.62
							c-0.21,0.02-0.37-0.04-0.49-0.14c-0.01,0.11-0.04,0.12-0.1,0.16l-0.21,0.11l-0.09-0.22c0,0,0-0.04-0.01-0.09l-0.01,0
							c0-0.01,0-0.03,0-0.06c-0.01-0.17-0.03-0.46-0.03-0.71c-0.06,0.02-0.13,0.04-0.19,0.05
							C699.06,1004.22,698.93,1004.26,698.8,1004.3z M714.77,992.01l0.07,0.05L714.77,992.01z M710.99,1006.28l-0.25-0.45
							c-0.98-1.77-0.84-2.87-0.17-4.53l0.19-0.48l0.21,0.48c0.76,1.75,0.67,2.8,0.17,4.49L710.99,1006.28z M710.77,1001.97
							c-0.39,1.13-0.45,1.98,0.09,3.16C711.16,1003.98,711.2,1003.14,710.77,1001.97z"/>
					</g>
					<g>
						<path className="st28" d="M753.37,984.63c-0.04,0.07-0.07,0.13-0.11,0.2c-0.08,0.13-0.17,0.26-0.26,0.39
							c-0.14,0.2-0.3,0.39-0.48,0.58c-0.5,0.91-0.99,1.81-1.49,2.72c0.21,4.71,0.42,9.42,0.63,14.13c2.55,0.58,5.16,0.92,7.71,1.5
							c0.13,0.03,0.26,0.06,0.39,0.09c0.36,0.09,0.72,0.18,1.07,0.27c0.34,0.09,0.69,0.19,1.03,0.3c0.15,0.05,0.3,0.1,0.45,0.15
							c0.45,0.15,0.89,0.32,1.33,0.5c0.88,0.36,1.74,0.79,2.58,1.31c0.06-1.44,0.12-2.88,0.2-4.32c0.16-2.87,0.46-5.79-2.77-7.39
							c-0.21,0.15-0.43,0.3-0.63,0.46c-2.46,2-2.46,2-3.35-1.51c-0.2-0.8-0.7-1.57-0.31-2.44c-0.42,0.05-0.49-0.26-0.57-0.57
							c-1.22-3.26-2.95-6.37-2.95-10c0-1.27-0.88-1.86-2.06-2.04c0,0,0,0,0,0c0,0,0,0,0,0C754.04,980.92,754.3,982.88,753.37,984.63
							z"/>
						<path className="st23" d="M766.41,1007.15l-0.31-0.19c-0.79-0.49-1.62-0.92-2.54-1.3c-0.42-0.17-0.86-0.34-1.32-0.49
							c-0.15-0.05-0.3-0.1-0.45-0.15c-0.32-0.1-0.65-0.2-1.02-0.3c-0.34-0.09-0.7-0.18-1.07-0.27c-0.13-0.03-0.26-0.06-0.38-0.09
							c-1.28-0.29-2.59-0.52-3.87-0.75c-1.26-0.22-2.57-0.46-3.84-0.74l-0.16-0.04l-0.64-14.36l1.55-2.82
							c0.17-0.18,0.32-0.36,0.46-0.55c0.09-0.12,0.17-0.25,0.25-0.38c0.04-0.06,0.07-0.13,0.11-0.19l0,0
							c0.92-1.71,0.63-3.72,0.37-5.5l-0.01-0.07v-0.25l0.25,0.04c1.47,0.22,2.25,1,2.25,2.25c0,2.77,1.05,5.29,2.06,7.73
							c0.3,0.72,0.61,1.46,0.88,2.2l0.02,0.05c0.1,0.35,0.14,0.4,0.33,0.37l0.38-0.05l-0.16,0.35c-0.26,0.58-0.07,1.12,0.13,1.69
							c0.07,0.2,0.14,0.41,0.2,0.61c0.41,1.61,0.66,2.58,0.99,2.69c0.31,0.1,0.98-0.45,2.01-1.29c0.15-0.12,0.3-0.23,0.45-0.33
							c0.07-0.05,0.13-0.09,0.2-0.14l0.11-0.08l0.12,0.06c3.23,1.6,3.07,4.48,2.91,7.26l-0.02,0.34c-0.05,0.99-0.1,2-0.14,2.98
							c-0.02,0.45-0.04,0.89-0.06,1.34L766.41,1007.15z M751.86,1002.48c1.21,0.27,2.45,0.49,3.66,0.7c1.28,0.23,2.6,0.46,3.89,0.75
							c0.13,0.03,0.26,0.06,0.39,0.09c0.37,0.09,0.74,0.18,1.08,0.28c0.37,0.1,0.71,0.2,1.04,0.3c0.15,0.05,0.3,0.1,0.45,0.15
							c0.46,0.16,0.92,0.33,1.35,0.5c0.82,0.34,1.57,0.71,2.29,1.14c0.01-0.33,0.03-0.65,0.04-0.98c0.04-0.98,0.08-1.99,0.14-2.98
							l0.02-0.34c0.15-2.73,0.3-5.3-2.55-6.79c-0.03,0.02-0.07,0.05-0.1,0.07c-0.15,0.1-0.29,0.2-0.42,0.31
							c-1.3,1.06-1.89,1.54-2.42,1.37c-0.56-0.18-0.77-1-1.27-2.98c-0.05-0.2-0.12-0.39-0.19-0.59c-0.19-0.54-0.39-1.1-0.22-1.72
							c-0.32-0.1-0.41-0.44-0.47-0.66l-0.01-0.04c-0.27-0.72-0.58-1.46-0.87-2.17c-1.03-2.48-2.09-5.04-2.09-7.89
							c0-0.94-0.53-1.53-1.59-1.77c0.26,1.78,0.49,3.75-0.45,5.5l0,0c-0.04,0.07-0.08,0.14-0.12,0.21
							c-0.08,0.14-0.17,0.28-0.27,0.41c-0.14,0.2-0.3,0.39-0.48,0.58l-1.45,2.64L751.86,1002.48z"/>
					</g>
					<g>
						<path className="st28" d="M751.66,1002.65c2.55,0.58,5.16,0.92,7.71,1.5C756.82,1003.57,754.2,1003.23,751.66,1002.65z"/>
						<path className="st23" d="M759.31,1004.36c-1.28-0.29-2.59-0.52-3.87-0.75c-1.26-0.22-2.57-0.46-3.84-0.74l0.1-0.42
							c1.26,0.28,2.56,0.52,3.82,0.74c1.28,0.23,2.6,0.46,3.89,0.75L759.31,1004.36z"/>
					</g>
					<g>
						<path className="st88" d="M717.94,995.11c0.12,0.05,0.24,0.1,0.36,0.15c2.15,0.76,1.95,1.94,0.78,3.41
							c4.89-1.52,9.78-3.05,14.68-4.56c0.46-0.14,0.92-0.27,1.39-0.4c0.46-0.13,0.93-0.25,1.39-0.38c0.4-0.15,0.8-0.3,1.21-0.45
							c4.35-1.67,8.77-3.15,13.28-4.35c0,0,0,0,0,0c0,0,0,0,0,0c0.5-0.91,0.99-1.81,1.49-2.72c-0.11-0.82-0.22-1.64-0.33-2.46
							c-6.58,2.18-13.17,4.36-19.75,6.55c-0.88,0.59-1.76,1.17-2.9,0.93c-1.46,0.43-2.91,0.87-4.37,1.31
							c-1.37,0.64-2.75,1.28-4.12,1.92c-0.79-0.81-0.16-1.89-0.57-2.73c-0.59,2.6-1.86,3.51-4.23,1.71
							c-0.21-0.16-0.54-0.15-0.81-0.22c0,0.42,0,0.83,0,1.25C716.4,994.11,717.12,994.73,717.94,995.11z"/>
						<path className="st23" d="M718.47,999.08l0.44-0.55c0.66-0.83,0.92-1.47,0.78-1.95c-0.13-0.45-0.62-0.82-1.46-1.12
							c-0.12-0.04-0.24-0.09-0.38-0.16c-0.25-0.12-0.49-0.26-0.73-0.39c-0.55-0.32-1.08-0.62-1.7-0.66l-0.2-0.01l0-1.72l0.27,0.06
							c0.08,0.02,0.17,0.03,0.25,0.05c0.22,0.03,0.45,0.07,0.64,0.21c1.01,0.77,1.84,1.05,2.45,0.82c0.64-0.23,1.13-1.04,1.44-2.41
							l0.14-0.6l0.27,0.55c0.2,0.41,0.18,0.84,0.17,1.26c-0.02,0.47-0.03,0.92,0.26,1.3l4-1.86c1.46-0.44,2.91-0.87,4.37-1.31
							l0.05-0.02l0.05,0.01c1.06,0.22,1.91-0.35,2.73-0.9l0.05-0.03l20-6.63l0.37,2.79l-1.51,2.75l0,0.03l-0.09,0.09l-0.06,0.01
							c-4.27,1.14-8.61,2.56-13.26,4.34l-1.21,0.45c-0.31,0.09-0.61,0.17-0.9,0.25l-0.51,0.14c-0.41,0.11-0.89,0.25-1.38,0.4
							c-3.45,1.07-6.91,2.15-10.36,3.22L718.47,999.08z M715.65,993.85c0.64,0.09,1.17,0.4,1.69,0.69c0.24,0.14,0.46,0.26,0.69,0.37
							h0c0.12,0.06,0.23,0.1,0.34,0.14c1,0.35,1.57,0.81,1.74,1.41c0.15,0.51,0,1.09-0.47,1.8l3.69-1.15
							c3.45-1.08,6.91-2.15,10.36-3.22c0.5-0.15,0.98-0.29,1.39-0.4l0.51-0.14c0.29-0.08,0.59-0.16,0.88-0.24l1.19-0.45
							c4.63-1.78,8.96-3.2,13.21-4.33l1.41-2.58l-0.29-2.13l-19.48,6.45c-0.83,0.56-1.78,1.19-2.98,0.96
							c-1.43,0.43-2.87,0.86-4.3,1.29L721,994.3l-0.11-0.11c-0.52-0.53-0.5-1.17-0.48-1.73c0-0.05,0-0.1,0-0.15
							c-0.34,0.96-0.82,1.53-1.44,1.76c-0.77,0.28-1.71-0.01-2.86-0.88c-0.1-0.07-0.26-0.1-0.44-0.13c-0.01,0-0.02,0-0.02,0
							L715.65,993.85z"/>
					</g>
					<g>
						<path className="st88" d="M715.44,994.05C715.44,994.05,715.44,994.05,715.44,994.05c0-0.42,0-0.83,0-1.25
							C715.43,993.22,715.43,993.63,715.44,994.05C715.43,994.05,715.43,994.05,715.44,994.05z"/>
						<polygon className="st23" points="715.22,994.07 715.22,994.05 715.22,992.8 715.65,992.8 715.65,994.05 715.43,994.05 						"/>
					</g>
					<g>
						<path className="st88" d="M751.02,988.52C751.02,988.52,751.02,988.52,751.02,988.52c0.5-0.91,0.99-1.81,1.49-2.72
							C752.02,986.71,751.52,987.61,751.02,988.52z"/>
						
							<rect x="750.22" y="986.94" transform="matrix(0.4811 -0.8767 0.8767 0.4811 -475.3081 1171.3475)" className="st23" width="3.1" height="0.43"/>
					</g>
					<g>
						<path className="st89" d="M780.96,1020.72c0.07,0.14,0.15,0.28,0.23,0.41c0.24,0.4,0.51,0.77,0.8,1.11c1.68,1.97,4.12,3,6.25,4.41
							c0.71-6.62-2.2-12.49-3.87-18.61c-0.19-0.71-0.73-0.1-1.02,0.24C781.76,1012.28,780.54,1016.35,780.96,1020.72z"/>
						<path className="st23" d="M788.41,1027.04l-0.3-0.2c-0.61-0.4-1.26-0.78-1.88-1.15c-1.57-0.92-3.19-1.87-4.41-3.3
							c-0.31-0.36-0.58-0.74-0.82-1.14c-0.08-0.14-0.16-0.28-0.24-0.43l-0.02-0.04l0-0.04c-0.44-4.59,0.89-8.74,2.39-12.54
							l0.01-0.03l0.02-0.03c0.27-0.31,0.63-0.72,0.99-0.63c0.19,0.04,0.32,0.2,0.4,0.48c0.42,1.55,0.94,3.12,1.44,4.63
							c1.47,4.46,2.98,9.08,2.44,14.06L788.41,1027.04z M781.17,1020.66c0.07,0.13,0.13,0.25,0.2,0.37
							c0.22,0.38,0.48,0.74,0.78,1.08c1.17,1.37,2.76,2.3,4.3,3.21c0.53,0.31,1.08,0.63,1.6,0.97c0.42-4.75-1.04-9.21-2.46-13.53
							c-0.5-1.52-1.01-3.09-1.44-4.66c-0.04-0.14-0.08-0.17-0.08-0.17c-0.08-0.01-0.26,0.15-0.54,0.47
							C782.05,1012.13,780.75,1016.19,781.17,1020.66z"/>
					</g>
					<g>
						<path className="st89" d="M780.96,1020.72c0.07,0.14,0.15,0.28,0.23,0.41C781.1,1021,781.03,1020.86,780.96,1020.72
							L780.96,1020.72z"/>
						<path className="st23" d="M781,1021.25C781,1021.24,781,1021.24,781,1021.25c0-0.01-0.01-0.01-0.01-0.01c0,0,0,0,0,0c0,0,0,0,0,0
							c0,0,0,0,0-0.01c0,0,0,0,0,0c-0.08-0.13-0.15-0.27-0.22-0.41h0l0,0l0.38-0.2c0.07,0.14,0.15,0.27,0.22,0.4L781,1021.25z"/>
					</g>
					<g>
						<path className="st90" d="M824.45,1038.71c-0.27-1.93-0.53-3.87-0.8-5.8c-0.93,0.04-2.05-0.22-2.32,1.1
							c-0.34,1.62-1.48,1.49-2.67,1.33c-2.5,0.19-4.85,1.17-7.51,0.87c-2.03-0.23-4.46-0.36-6.13,1.58
							c4.59,1.26,9.37,1.04,14.03,1.74C820.93,1039.8,822.88,1040.53,824.45,1038.71z"/>
						<path className="st23" d="M821.83,1040.12c-0.65,0-1.31-0.12-1.96-0.24c-0.28-0.05-0.57-0.1-0.84-0.15
							c-1.78-0.27-3.61-0.4-5.38-0.53c-2.86-0.21-5.82-0.43-8.68-1.21l-0.33-0.09l0.22-0.26c1.71-1.99,4.16-1.9,6.32-1.66
							c1.62,0.18,3.15-0.12,4.64-0.42c0.92-0.18,1.87-0.37,2.83-0.45l0.02,0l0.02,0c1.32,0.18,2.15,0.19,2.43-1.16
							c0.27-1.28,1.27-1.27,2.08-1.26c0.15,0,0.3,0,0.45,0l0.2-0.01l0.84,6.09l-0.06,0.07
							C823.76,1039.84,822.81,1040.12,821.83,1040.12z M805.42,1037.66c2.71,0.7,5.52,0.9,8.25,1.1c1.78,0.13,3.62,0.27,5.41,0.54
							c0.29,0.04,0.58,0.1,0.86,0.15c1.56,0.29,3.04,0.56,4.28-0.81l-0.76-5.51c-0.09,0-0.18,0-0.27,0
							c-0.9-0.01-1.47,0.03-1.65,0.92c-0.38,1.82-1.77,1.65-2.89,1.5c-0.93,0.07-1.86,0.26-2.76,0.44c-1.52,0.3-3.09,0.62-4.77,0.43
							C809.18,1036.19,806.98,1036.1,805.42,1037.66z"/>
					</g>
					<g>
						<path className="st27" d="M778.06,1017.19c-0.4,1.83,0.2,2.8,1.44,3.25c0.21,0.07,0.43,0.13,0.68,0.18
							c0.12,0.02,0.25,0.04,0.38,0.06s0.26,0.03,0.4,0.04c-0.42-4.38,0.8-8.44,2.38-12.44c-2.61,1.19-5.46,1.46-8.2,2.19
							C777.91,1011.93,778.7,1014.27,778.06,1017.19z"/>
						<path className="st23" d="M781.19,1020.96l-0.26-0.02c-0.14-0.01-0.28-0.03-0.41-0.04c-0.13-0.02-0.26-0.04-0.39-0.06
							c-0.26-0.05-0.49-0.11-0.71-0.19c-1.44-0.52-1.97-1.7-1.58-3.5l0,0c0.66-3.01-0.26-5.13-2.81-6.48l-0.49-0.26l0.54-0.14
							c0.91-0.24,1.84-0.44,2.75-0.62c1.81-0.38,3.69-0.77,5.41-1.55l0.49-0.22l-0.2,0.5c-1.48,3.75-2.79,7.84-2.37,12.34
							L781.19,1020.96z M775.71,1010.55c2.35,1.44,3.21,3.69,2.56,6.68l0,0c-0.35,1.6,0.07,2.56,1.31,3
							c0.19,0.07,0.41,0.13,0.64,0.17c0.12,0.02,0.24,0.04,0.36,0.06c0.04,0.01,0.09,0.01,0.13,0.02c-0.35-4.29,0.83-8.2,2.23-11.79
							c-1.63,0.67-3.35,1.03-5.02,1.37C777.19,1010.21,776.45,1010.37,775.71,1010.55z"/>
					</g>
					<g>
						<path className="st91" d="M780.56,1020.68c0.13,0.02,0.26,0.03,0.4,0.04v0C780.82,1020.71,780.69,1020.7,780.56,1020.68z"/>
						<path className="st23" d="M780.94,1020.94c-0.14-0.01-0.28-0.03-0.41-0.04l0.06-0.43c0.01,0,0.02,0,0.04,0
							c0.11,0.01,0.23,0.03,0.35,0.04v0c0,0,0,0,0,0L780.94,1020.94C780.94,1020.94,780.94,1020.94,780.94,1020.94L780.94,1020.94z"
							/>
					</g>
					<g>
						<path className="st92" d="M699.61,1003.83c0-0.02,0-0.04,0-0.06c0,0,0-0.01,0-0.01C699.61,1003.75,699.61,1003.83,699.61,1003.83z
							"/>
						<path className="st23" d="M699.39,1003.83C699.39,1003.83,699.39,1003.82,699.39,1003.83c0-0.11,0-0.17,0.06-0.23l0.29-0.29
							l0.08,0.46l0,0.04l0,0.02l-0.22,0v0v0L699.39,1003.83z"/>
					</g>
					<g>
						<path className="st27" d="M759.34,991.57c-0.39,0.87,0.11,1.64,0.31,2.44c0.89,3.51,0.89,3.51,3.35,1.51
							c0.2-0.17,0.42-0.31,0.63-0.46c-0.26-0.6-0.76-1.21-0.75-1.8c0.09-3.27-1.57-2.76-3.52-1.73c0,0.01,0,0.03,0.01,0.04
							C759.36,991.57,759.35,991.56,759.34,991.57z"/>
						<path className="st23" d="M760.93,997.09c-0.08,0-0.15-0.01-0.22-0.04c-0.56-0.18-0.77-1-1.27-2.98c-0.05-0.2-0.12-0.39-0.19-0.59
							c-0.22-0.62-0.44-1.27-0.12-2l0.01-0.01l-0.01-0.07l0.13-0.07c1.31-0.69,2.33-1.05,3.04-0.61c0.57,0.35,0.83,1.18,0.79,2.54
							c-0.01,0.35,0.2,0.72,0.42,1.12c0.11,0.19,0.22,0.39,0.31,0.59l0.07,0.16l-0.14,0.1c-0.07,0.05-0.14,0.1-0.21,0.15
							c-0.15,0.1-0.29,0.2-0.42,0.31C762.01,996.61,761.42,997.09,760.93,997.09z M759.49,991.76c-0.19,0.54-0.01,1.04,0.17,1.58
							c0.07,0.2,0.14,0.41,0.2,0.61c0.41,1.61,0.66,2.58,0.99,2.69c0.31,0.1,0.98-0.45,2.01-1.29c0.15-0.12,0.3-0.23,0.45-0.33
							c0.02-0.01,0.04-0.03,0.06-0.04c-0.06-0.13-0.14-0.26-0.21-0.39c-0.24-0.43-0.49-0.88-0.48-1.34c0.03-1.17-0.17-1.9-0.59-2.16
							c-0.55-0.34-1.51,0.05-2.49,0.56l0.01,0.09L759.49,991.76z"/>
					</g>
					<g>
						<path className="st93" d="M774.28,1021.62c0.37-3.77,0.17-7.5-0.22-11.23c-0.41-0.14-0.83-0.27-1.24-0.41
							C771.68,1014.09,772.97,1017.85,774.28,1021.62z"/>
						<path className="st23" d="M774.4,1022.62l-0.32-0.93c-1.3-3.72-2.63-7.56-1.46-11.77l0.06-0.22l1.59,0.52l0.01,0.14
							c0.48,4.51,0.54,7.98,0.22,11.27L774.4,1022.62z M772.97,1010.26c-0.94,3.64,0.05,7.04,1.18,10.33
							c0.21-2.93,0.12-6.08-0.29-10.04L772.97,1010.26z"/>
					</g>
					<g>
						<path className="st93" d="M721.05,994.04c1.37-0.64,2.75-1.28,4.12-1.92c-0.65-1.6-2.13-2.15-3.54-2.78
							c-0.56-0.25-1.1-0.27-1.53,0.27c-0.56,0.72-0.09,1.21,0.38,1.69C720.89,992.15,720.26,993.23,721.05,994.04z"/>
						<path className="st23" d="M721,994.3l-0.11-0.11c-0.52-0.53-0.5-1.17-0.48-1.73c0.01-0.37,0.02-0.72-0.11-1.03
							c-0.46-0.46-1.05-1.08-0.37-1.95c0.43-0.56,1.03-0.67,1.79-0.34l0.28,0.12c1.34,0.59,2.73,1.2,3.38,2.77l0.08,0.19L721,994.3z
							 M720.94,989.4c-0.27,0-0.49,0.12-0.67,0.36c-0.43,0.56-0.14,0.89,0.37,1.4l0.03,0.03l0.02,0.03c0.2,0.41,0.18,0.84,0.17,1.26
							c-0.02,0.47-0.03,0.92,0.26,1.3l3.78-1.76c-0.61-1.27-1.8-1.8-3.07-2.35l-0.28-0.12C721.31,989.45,721.11,989.4,720.94,989.4z
							"/>
					</g>
					<g>
						<path className="st91" d="M821.33,1034c0.28-1.32,1.39-1.06,2.32-1.1c-0.47-1.55-1.76-2.51-3.09-2.05
							c-1.92,0.67-2.53,2.44-1.9,4.47C819.85,1035.49,820.99,1035.62,821.33,1034z"/>
						<path className="st23" d="M819.64,1035.63c-0.34,0-0.68-0.05-1.01-0.09l-0.14-0.02l-0.04-0.13c-0.69-2.25,0.09-4.06,2.04-4.74
							c0.6-0.21,1.21-0.16,1.78,0.13c0.73,0.37,1.31,1.12,1.59,2.06l0.08,0.27l-0.28,0.01c-0.15,0.01-0.31,0.01-0.47,0
							c-0.9-0.01-1.47,0.03-1.65,0.92C821.26,1035.35,820.48,1035.63,819.64,1035.63z M818.83,1035.13
							c1.24,0.16,2.02,0.12,2.29-1.18l0,0c0.27-1.28,1.27-1.27,2.08-1.26c0.05,0,0.1,0,0.16,0c-0.27-0.7-0.72-1.24-1.28-1.53
							c-0.46-0.24-0.96-0.27-1.44-0.1C818.94,1031.65,818.27,1033.17,818.83,1035.13z"/>
					</g>
					<g>
						<path className="st27" d="M752.99,985.22c0.09-0.13,0.18-0.26,0.26-0.39c0.04-0.07,0.08-0.13,0.11-0.2
							c0.93-1.74,0.67-3.7,0.38-5.67c-1.35-0.71-1.74-0.1-1.67,1.2c0.06,1.06,0.07,2.12,0.11,3.18c0.11,0.82,0.22,1.64,0.33,2.46
							C752.69,985.61,752.85,985.42,752.99,985.22z"/>
						<path className="st23" d="M752.36,986.28l-0.39-2.93c-0.01-0.33-0.02-0.66-0.03-0.99c-0.02-0.72-0.04-1.46-0.08-2.18
							c-0.05-0.85,0.1-1.36,0.47-1.6c0.35-0.22,0.84-0.16,1.52,0.19l0.1,0.05l0.02,0.11c0.27,1.85,0.58,3.95-0.41,5.8
							c-0.04,0.07-0.08,0.14-0.12,0.21c-0.08,0.14-0.17,0.28-0.27,0.41h0c-0.15,0.21-0.32,0.41-0.5,0.6L752.36,986.28z
							 M752.4,983.32l0.27,1.98c0.05-0.07,0.1-0.13,0.15-0.2c0.09-0.12,0.17-0.25,0.25-0.38c0.04-0.06,0.07-0.13,0.11-0.19
							c0.9-1.69,0.64-3.67,0.38-5.43c-0.46-0.23-0.8-0.28-0.99-0.16c-0.21,0.14-0.31,0.55-0.27,1.21c0.04,0.73,0.06,1.47,0.08,2.19
							C752.38,982.67,752.39,982.99,752.4,983.32z"/>
					</g>
					<g>
						<path className="st27" d="M753.37,984.63c0.93-1.74,0.67-3.7,0.38-5.67c0,0,0,0,0,0C754.04,980.92,754.3,982.88,753.37,984.63z"/>
						<path className="st23" d="M753.56,984.73l-0.38-0.2c0,0,0,0,0-0.01c0.11-0.21,0.2-0.42,0.28-0.63c0,0,0,0,0,0c0,0,0,0,0,0
							c0.17-0.47,0.26-0.95,0.31-1.44v0c0,0,0,0,0,0c0.11-1.16-0.07-2.36-0.23-3.46l-0.05-0.33l0.46,0.13l0.02,0.14
							c0.16,1.11,0.34,2.3,0.24,3.48c0,0,0,0,0,0v0c-0.05,0.57-0.16,1.14-0.37,1.69c0,0,0,0,0,0c0,0,0,0,0,0
							c-0.08,0.2-0.16,0.4-0.27,0.59C753.57,984.71,753.56,984.72,753.56,984.73C753.56,984.73,753.56,984.73,753.56,984.73z"/>
					</g>
					<g>
						<path className="st27" d="M732.43,989.89c-0.01-1.18,0.09-2.52-1.66-2.4c-1.65,0.12-1.12,1.48-1.22,2.46
							c-0.03,0.29-0.01,0.58-0.02,0.87C730.67,991.05,731.55,990.47,732.43,989.89z"/>
						<path className="st23" d="M730.05,991.09c-0.18,0-0.37-0.02-0.56-0.06l-0.18-0.04l0-0.18c0-0.09,0-0.18,0-0.27
							c0-0.2,0-0.41,0.02-0.61c0.02-0.2,0.01-0.42,0-0.66c-0.03-0.8-0.08-1.89,1.42-2c0.61-0.04,1.06,0.08,1.38,0.37
							c0.51,0.47,0.51,1.26,0.52,2.03l0,0.33l-0.1,0.07C731.83,990.55,731.03,991.09,730.05,991.09z M729.75,990.64
							c0.94,0.12,1.72-0.37,2.46-0.87l0-0.1c0-0.67-0.01-1.37-0.38-1.72c-0.22-0.2-0.56-0.29-1.05-0.25
							c-1.02,0.07-1.05,0.61-1.01,1.55c0.01,0.24,0.02,0.49,0,0.72c-0.02,0.18-0.02,0.37-0.02,0.57
							C729.75,990.57,729.75,990.6,729.75,990.64z"/>
					</g>
					<g>
						<path className="st91" d="M702.54,999.74C702.54,999.74,702.54,999.74,702.54,999.74c0.01-0.01,0.02-0.03,0.04-0.04
							C702.58,999.7,702.54,999.74,702.54,999.74z"/>
						<path className="st23" d="M702.54,999.96c-0.01,0-0.02,0-0.03,0l-0.41-0.06l0.32-0.34l0.13,0.12l0,0l0,0l0,0l0.18,0.17l0,0
							c0,0-0.01,0.01-0.01,0.01l-0.02,0.02l0,0C702.67,999.92,702.62,999.96,702.54,999.96z"/>
					</g>
					<g>
						<path className="st94" d="M708.26,1001.76c0,0,0.06-0.44,0-1.47c-0.22-3.9-2.86-1.64-3.49-1.43c-1.52,0.5-2.63,1.17-2.63,1.17
							c-0.74,0.88-0.93,3.31-2.53,3.74c0,0.02,0.01,0.03,0.01,0.05C698.84,1007.06,708.33,1003.13,708.26,1001.76z"/>
						<path className="st23" d="M700.93,1005.28c-0.51,0-0.93-0.09-1.2-0.31c-0.23-0.18-0.47-0.52-0.33-1.16l-0.04-0.2l0.19-0.05
							c0.94-0.26,1.37-1.34,1.74-2.3c0.21-0.54,0.41-1.04,0.68-1.37l0.02-0.03l0.03-0.02c0.05-0.03,1.15-0.68,2.67-1.19
							c0.08-0.03,0.24-0.11,0.4-0.21c0.61-0.34,1.63-0.92,2.41-0.49c0.58,0.31,0.89,1.07,0.96,2.32c0.03,0.6,0.03,1.13,0,1.49h0
							c0.01,0.62-1.16,1.36-2.15,1.88C704.77,1004.45,702.46,1005.28,700.93,1005.28z M699.82,1003.93c-0.1,0.49,0.11,0.65,0.18,0.7
							c0.83,0.65,3.84-0.19,6.13-1.38c1.31-0.68,1.84-1.2,1.91-1.43l0.01-0.1c0.03-0.34,0.03-0.84,0-1.42
							c-0.06-1.07-0.31-1.73-0.74-1.97c-0.58-0.31-1.43,0.17-1.99,0.49c-0.2,0.11-0.36,0.2-0.48,0.24
							c-1.33,0.44-2.36,1.02-2.55,1.13c-0.22,0.28-0.4,0.74-0.59,1.23C701.31,1002.42,700.86,1003.56,699.82,1003.93z"/>
					</g>
					<g>
						<path className="st94" d="M699.61,1003.77c0,0.02,0,0.04,0,0.06"/>
						<path className="st23" d="M699.39,1003.83c0-0.02,0-0.03,0-0.05l0.43-0.01l0,0.04L699.39,1003.83z"/>
					</g>
					<g>
						<path className="st91" d="M702.58,999.7c-0.01,0.01-0.02,0.02-0.04,0.04c0.01,0,0.02-0.01,0.03-0.01
							C702.57,999.72,702.58,999.71,702.58,999.7z"/>
						<polygon className="st23" points="701.72,1000.29 702.8,999.15 702.79,999.89 702.62,999.94 						"/>
					</g>
					<g>
						<path className="st95" d="M714.95,992.13"/>
					</g>
					<g>
						<path className="st94" d="M759.38,991.56c0-0.01,0-0.03-0.01-0.04c-0.01,0-0.01,0.01-0.02,0.01
							C759.35,991.53,759.38,991.56,759.38,991.56z"/>
						<path className="st23" d="M759.63,992.27l-0.39-0.53h-0.77l1.06-0.56l0.05,0.31c0,0.02,0.01,0.03,0.01,0.05L759.63,992.27z"/>
					</g>
					<g>
						<polygon className="st91" points="688.58,1017.22 688.58,1017.22 688.58,1017.22 						"/>
					</g>
					<g>
						<path className="st91" d="M690.21,1018.38c0.09,0.01,0.19,0.01,0.28,0.01c4.41-0.26,8.7,1.05,13.03,1.14
							c7.37,0.15,14.77-0.12,22.12-0.68c3.4-0.26,5.66,1.62,8.35,2.82c0.33,0.15,0.82,0.49,0.43,0.82c-0.12,0.11-0.34,0.1-0.59,0.07
							c-0.12-0.02-0.25-0.04-0.38-0.06c-0.13-0.02-0.25-0.04-0.37-0.05c-0.21,0.22-0.43,0.45-0.64,0.67l0,0
							c0.09,0.57-0.05,0.92-0.3,1.18c-0.04,0.04-0.07,0.07-0.12,0.1c-0.15,0.12-0.32,0.21-0.51,0.3c-0.06,0.03-0.12,0.05-0.18,0.08
							c-0.12,0.05-0.24,0.1-0.35,0.14c-0.17,0.07-0.34,0.15-0.48,0.24c-0.81,0.52-2.05,2.52-2.05,2.52l0,0l0,0
							c0,0-6.12,3.73-5.4,3.65c0.21-0.09,0.41-0.16,0.6-0.21c0.32-0.08,0.61-0.12,0.88-0.11c0.12,0,0.24,0.02,0.36,0.04
							c0.11,0.02,0.23,0.05,0.33,0.08c0.11,0.04,0.21,0.08,0.31,0.13c0.1,0.05,0.19,0.11,0.29,0.17c0.32,0.22,0.6,0.51,0.86,0.83
							c0.07,0.09,0.14,0.19,0.21,0.29c0.03,0.05,0.07,0.1,0.1,0.15c0.07,0.1,0.13,0.21,0.19,0.31c0.16,0.26,0.3,0.53,0.45,0.8
							c0.58,1.09,1.09,2.21,1.84,3.21c3.01-0.6,6.01-1.22,9.02-1.8c1.23-0.24,2.2-0.68,2.2-2.15c-0.04-4.86-0.08-9.73-0.11-14.59
							c-0.99-0.24-1.5,1.3-3,0.53c0-4.74,0-9.65,0-14.57c-1.54-1.59-3.07-2.95-4.93-0.35c-0.26,0.36-0.77,0.62-1.22,0.74
							c-1.14,0.31-2.27,0.62-3.41,0.93c-3.41,0.92-6.82,1.84-10.23,2.75c-0.64,0.48-1.33,0.79-2.08,0.96
							c-0.3,0.07-0.6,0.11-0.91,0.13c-0.16,0.01-0.31,0.02-0.47,0.02c-5.22,1.87-10.57,3.3-15.92,4.69
							c-0.59,0.31-1.18,0.57-1.87,0.39c-2.45,1.48-5.28,1.94-8.02,2.52C688.89,1017.87,689.56,1018.31,690.21,1018.38z"/>
						<path className="st23" d="M729.39,1037.25l-0.08-0.11c-0.6-0.79-1.05-1.68-1.49-2.53c-0.12-0.24-0.24-0.47-0.37-0.71
							c-0.13-0.24-0.28-0.52-0.44-0.79c-0.06-0.1-0.12-0.2-0.19-0.3c-0.03-0.05-0.06-0.1-0.1-0.15c-0.07-0.1-0.13-0.19-0.2-0.28
							c-0.26-0.34-0.53-0.6-0.81-0.79c-0.09-0.06-0.17-0.11-0.26-0.15c-0.09-0.05-0.19-0.08-0.28-0.12c-0.1-0.03-0.2-0.06-0.3-0.08
							c-0.11-0.02-0.22-0.03-0.33-0.03c-0.26-0.01-0.53,0.03-0.82,0.1c-0.18,0.05-0.37,0.11-0.57,0.2l-0.06,0.02
							c-0.21,0.02-0.27-0.13-0.29-0.16c-0.07-0.2-0.14-0.42,5.51-3.86c0.19-0.31,1.3-2.04,2.09-2.54c0.16-0.11,0.35-0.19,0.51-0.26
							c0.06-0.03,0.12-0.05,0.18-0.07l0.17-0.07c0.06-0.02,0.11-0.05,0.17-0.07c0.2-0.09,0.34-0.17,0.46-0.27
							c0.03-0.03,0.07-0.06,0.1-0.09c0.15-0.15,0.34-0.41,0.24-0.99l-0.02-0.11l0.79-0.82l0.1,0.01c0.13,0.01,0.26,0.03,0.39,0.05
							l0.09,0.02c0.09,0.02,0.19,0.03,0.28,0.05c0.28,0.04,0.38,0.01,0.42-0.02c0.03-0.03,0.07-0.06,0.06-0.1
							c-0.01-0.08-0.18-0.24-0.45-0.36c-0.67-0.3-1.33-0.65-1.96-0.98c-1.95-1.03-3.8-2.01-6.29-1.82
							c-7.96,0.6-15.2,0.82-22.14,0.68c-1.9-0.04-3.82-0.32-5.67-0.58c-2.39-0.34-4.87-0.7-7.34-0.56c-0.11,0.01-0.21,0-0.32-0.01
							l0,0c-0.73-0.08-1.46-0.58-1.86-1.27l-0.15-0.26l0.29-0.06c0.3-0.06,0.61-0.13,0.92-0.19c2.4-0.49,4.89-1,7.04-2.3l0.08-0.05
							l0.09,0.02c0.62,0.16,1.17-0.09,1.72-0.38l0.05-0.02c5.45-1.42,10.77-2.85,15.9-4.69l0.04-0.01h0.04
							c0.16,0,0.31-0.01,0.46-0.02c0.3-0.02,0.6-0.06,0.88-0.13c0.73-0.16,1.4-0.47,1.99-0.92l0.03-0.03l3.08-0.83
							c2.4-0.64,4.79-1.29,7.19-1.94c1.14-0.31,2.27-0.62,3.41-0.93c0.47-0.13,0.91-0.39,1.1-0.66c0.7-0.98,1.41-1.49,2.16-1.54
							c1.11-0.08,2.1,0.83,3.1,1.87l0.06,0.06v14.52c0.69,0.31,1.12,0.04,1.57-0.25c0.37-0.23,0.76-0.48,1.26-0.36l0.16,0.04
							l0.12,14.76c0.01,1.68-1.19,2.14-2.37,2.36c-2.15,0.42-4.32,0.86-6.43,1.28c-0.86,0.17-1.73,0.35-2.59,0.52L729.39,1037.25z
							 M724.47,1030.78c0.03,0,0.05,0,0.08,0c0.13,0,0.26,0.02,0.39,0.04c0.12,0.02,0.25,0.05,0.36,0.09
							c0.11,0.04,0.23,0.09,0.34,0.14c0.1,0.05,0.21,0.11,0.31,0.18c0.32,0.21,0.62,0.5,0.91,0.88c0.07,0.1,0.15,0.2,0.22,0.3
							c0.03,0.05,0.07,0.1,0.1,0.16c0.07,0.1,0.13,0.21,0.2,0.32c0.17,0.28,0.32,0.57,0.45,0.81c0.13,0.24,0.25,0.47,0.37,0.71
							c0.41,0.8,0.83,1.62,1.37,2.36c0.82-0.16,1.64-0.33,2.45-0.49c2.11-0.42,4.29-0.86,6.43-1.28c1.25-0.24,2.03-0.65,2.02-1.94
							l-0.11-14.4c-0.25,0-0.48,0.15-0.76,0.32c-0.51,0.32-1.14,0.72-2.12,0.21l-0.12-0.06v-14.61c-0.9-0.93-1.79-1.74-2.7-1.67
							c-0.62,0.05-1.22,0.49-1.84,1.36c-0.38,0.53-1.12,0.76-1.34,0.82c-1.14,0.31-2.27,0.62-3.41,0.93
							c-2.4,0.65-4.79,1.29-7.19,1.94l-3,0.81c-0.64,0.47-1.35,0.8-2.13,0.97c-0.3,0.07-0.62,0.11-0.94,0.14
							c-0.15,0.01-0.3,0.02-0.45,0.02c-5.13,1.84-10.44,3.26-15.88,4.68c-0.57,0.3-1.19,0.57-1.91,0.42
							c-2.19,1.3-4.68,1.81-7.1,2.31c-0.2,0.04-0.4,0.08-0.6,0.12c0.34,0.44,0.85,0.74,1.36,0.8c0.08,0.01,0.16,0.01,0.25,0.01
							c2.51-0.15,5.01,0.21,7.42,0.56c1.84,0.27,3.75,0.54,5.62,0.58c6.93,0.14,14.16-0.08,22.1-0.68c2.62-0.2,4.6,0.86,6.52,1.87
							c0.63,0.33,1.27,0.67,1.93,0.97c0.19,0.09,0.64,0.32,0.7,0.69c0.02,0.12,0,0.31-0.21,0.49c-0.2,0.17-0.49,0.15-0.76,0.12
							c-0.1-0.01-0.2-0.03-0.3-0.05l-0.09-0.02c-0.08-0.01-0.16-0.03-0.24-0.04l-0.5,0.52c0.07,0.53-0.06,0.94-0.38,1.26
							c-0.04,0.04-0.09,0.08-0.13,0.12c-0.15,0.12-0.32,0.22-0.55,0.32c-0.06,0.03-0.12,0.05-0.18,0.08l-0.17,0.07
							c-0.06,0.02-0.12,0.05-0.18,0.07c-0.14,0.06-0.31,0.13-0.45,0.22c-0.76,0.49-1.97,2.43-1.98,2.45l-0.03,0.04l-0.04,0.03
							c-1.77,1.08-3.68,2.29-4.68,2.99C724.1,1030.8,724.29,1030.78,724.47,1030.78z"/>
					</g>
					<g>
						<path className="st91" d="M733.46,1022.49c-0.13-0.02-0.25-0.04-0.37-0.05C733.21,1022.45,733.33,1022.47,733.46,1022.49z"/>
						<path className="st23" d="M733.42,1022.7L733.42,1022.7L733.42,1022.7C733.42,1022.7,733.42,1022.7,733.42,1022.7
							c-0.12-0.02-0.24-0.04-0.34-0.05l0,0c0,0,0,0,0,0l0.03-0.43c0.01,0,0.01,0,0.02,0c0.12,0.01,0.25,0.03,0.37,0.05
							L733.42,1022.7z"/>
					</g>
					<g>
						<path className="st91" d="M690.21,1018.38c-0.65-0.07-1.26-0.51-1.63-1.16c0,0,0,0,0,0v0
							C688.95,1017.87,689.56,1018.31,690.21,1018.38z"/>
						<path className="st23" d="M690.19,1018.59c-0.72-0.08-1.39-0.55-1.79-1.26l0,0c0,0,0,0,0,0l0.38-0.21c0,0,0,0,0,0l0,0
							c0.1,0.17,0.21,0.32,0.34,0.46c0.32,0.33,0.71,0.55,1.13,0.59L690.19,1018.59z"/>
					</g>
					<g>
						<path className="st91" d="M732.15,1024.29c0.25-0.25,0.4-0.61,0.3-1.18l0,0c0,0,0,0,0,0
							C732.54,1023.68,732.4,1024.04,732.15,1024.29z"/>
						<path className="st23" d="M732.3,1024.44l-0.31-0.31c0,0,0,0,0,0c0,0,0,0,0,0c0.15-0.15,0.34-0.41,0.24-0.99l0.43-0.07v0h0
							c0.03,0.19,0.04,0.36,0.03,0.52C732.66,1023.93,732.54,1024.2,732.3,1024.44C732.31,1024.43,732.31,1024.43,732.3,1024.44
							C732.31,1024.44,732.3,1024.44,732.3,1024.44C732.3,1024.44,732.3,1024.44,732.3,1024.44z"/>
					</g>
					<g>
						<path className="st91" d="M732.15,1024.29c-0.04,0.04-0.07,0.07-0.12,0.1C732.07,1024.36,732.11,1024.33,732.15,1024.29z"/>
						<path className="st23" d="M732.17,1024.56l-0.27-0.34c0.03-0.03,0.07-0.06,0.1-0.09l0.31,0.31
							C732.26,1024.48,732.21,1024.52,732.17,1024.56z"/>
					</g>
					<g>
						<path className="st88" d="M678.11,1019.78c-0.09,0.13-0.19,0.25-0.3,0.36C677.92,1020.03,678.02,1019.91,678.11,1019.78z"/>
						<path className="st23" d="M677.97,1020.29l-0.31-0.3c0.1-0.1,0.19-0.21,0.27-0.34l0.36,0.25
							C678.19,1020.04,678.08,1020.17,677.97,1020.29z"/>
					</g>
					<g>
						<path className="st88" d="M688.59,1017.27l0,0.01c0,0,0,0,0,0C688.59,1017.29,688.59,1017.28,688.59,1017.27z"/>
						<polygon className="st23" points="688.4,1017.51 688.37,1017.3 688.59,1017.27 688.59,1017.28 688.59,1017.27 688.8,1017.25 
							688.8,1017.26 688.83,1017.49 						"/>
					</g>
					<g>
						<polygon className="st88" points="688.58,1017.22 688.58,1017.22 688.58,1017.22 						"/>
						<polygon className="st23" points="688.58,1017.22 688.58,1017 688.75,1017 688.79,1017.17 						"/>
					</g>
					<g>
						<path className="st96" d="M744.18,1034.87C744.18,1034.87,744.18,1034.87,744.18,1034.87c-0.82,0.07-1.59,0.04-2.22-0.27
							C742.59,1034.91,743.36,1034.95,744.18,1034.87z"/>
						<path className="st23" d="M743.51,1035.12c-0.67,0-1.2-0.1-1.64-0.32l0.19-0.39c0.51,0.25,1.18,0.33,2.11,0.24l0.04,0.43
							C743.95,1035.11,743.72,1035.12,743.51,1035.12z"/>
					</g>
					<g>
						<path className="st97" d="M789.79,1045.94c-0.4-0.05-0.79-0.11-1.19-0.16c0,0,0,0,0,0c0,0,0,0,0,0
							C788.99,1045.83,789.39,1045.89,789.79,1045.94z"/>
						<path className="st23" d="M789.76,1046.15l-0.28-0.04c-0.3-0.04-0.6-0.08-0.91-0.12l-0.13-0.02l-0.07-0.17l0.04-0.27l1.4,0.19
							L789.76,1046.15z"/>
					</g>
					<g>
						<path className="st98" d="M789.94,1045.94c-0.05,0-0.1,0-0.15,0c0,0,0,0,0,0C789.84,1045.94,789.89,1045.94,789.94,1045.94z"/>
						
							<rect x="789.79" y="1045.72" transform="matrix(0.9999 -0.0139 0.0139 0.9999 -14.4739 11.0892)" className="st23" width="0.15" height="0.43"/>
					</g>
					<g>
						<path className="st97" d="M767.02,1029.24c1.63,0.19,3.26,0.37,4.89,0.56c4.34,0.49,8.68,0.96,13.03,1.45c0,0,0,0,0,0
							c-9.73-4.57-20.05-6.49-30.72-6.92c2.22,1.5,4,3.84,6.84,4.19C763.04,1028.77,765.03,1029,767.02,1029.24z"/>
						<path className="st23" d="M786.21,1031.61l-1.3-0.15c-2.26-0.25-4.52-0.51-6.77-0.76c-2.08-0.23-4.17-0.46-6.25-0.7
							c-1.63-0.18-3.26-0.37-4.89-0.56l0,0c-2.14-0.25-4.1-0.48-5.97-0.72c-2.06-0.26-3.59-1.53-5.06-2.76
							c-0.6-0.5-1.22-1.03-1.87-1.46l-0.63-0.43l0.76,0.03c11.8,0.47,21.87,2.74,30.8,6.94L786.21,1031.61z M754.93,1024.58
							c0.45,0.34,0.89,0.71,1.31,1.06c1.49,1.25,2.9,2.43,4.84,2.67c1.87,0.23,3.82,0.47,5.96,0.71h0c1.63,0.19,3.26,0.37,4.89,0.56
							c2.08,0.23,4.17,0.47,6.25,0.7c1.81,0.2,3.62,0.4,5.43,0.61C775.23,1027.15,765.82,1025.08,754.93,1024.58z"/>
					</g>
					<g>
						<path className="st97" d="M771.9,1029.79c4.34,0.49,8.68,0.96,13.03,1.45c0,0,0,0,0,0c0,0,0,0,0,0
							C780.59,1030.76,776.25,1030.28,771.9,1029.79z"/>
						<path className="st23" d="M784.93,1031.46l-0.02,0c-2.26-0.26-4.52-0.51-6.77-0.76c-1.92-0.21-3.84-0.43-5.75-0.64
							c-0.17-0.02-0.33-0.04-0.5-0.06l0.05-0.43c0.34,0.04,0.68,0.08,1.03,0.12c1.74,0.2,3.48,0.39,5.22,0.58
							c2.26,0.25,4.52,0.5,6.78,0.76l0,0c0,0,0,0,0,0l-0.02,0.22l0,0L784.93,1031.46z"/>
					</g>
					<g>
						<path className="st97" d="M771.9,1029.79c-1.63-0.18-3.26-0.37-4.89-0.56C768.65,1029.43,770.28,1029.61,771.9,1029.79z"/>
						<path className="st23" d="M771.88,1030.01c-1.63-0.18-3.26-0.37-4.89-0.56l0.05-0.43c1.63,0.19,3.26,0.37,4.89,0.56
							L771.88,1030.01z"/>
					</g>
					<g>
						<path className="st94" d="M744.18,1034.87c0.82-0.03,1.65-0.46,2.47-0.49C745.83,1034.41,745,1034.84,744.18,1034.87
							C744.18,1034.87,744.18,1034.87,744.18,1034.87C744.18,1034.87,744.18,1034.87,744.18,1034.87z"/>
						<path className="st23" d="M744.19,1035.09L744.19,1035.09C744.19,1035.09,744.19,1035.09,744.19,1035.09l-0.02-0.43
							c0,0,0,0,0.01,0c0.38-0.02,0.77-0.12,1.18-0.24c0.42-0.12,0.84-0.23,1.28-0.25c0,0,0.01,0,0.01,0l0.02,0.43
							c-0.39,0.01-0.77,0.12-1.19,0.24C745.06,1034.95,744.62,1035.07,744.19,1035.09z"/>
					</g>
					<g>
						<path className="st25" d="M738.57,1003.42c0.1-0.05,0.21-0.09,0.32-0.13c0.1-0.03,0.2-0.05,0.3-0.07
							c0.18-0.03,0.36-0.04,0.54-0.04c0.12,0,0.25,0.01,0.37,0.02c0.76,0.07,1.54,0.27,2.26,0.18c0.17-0.02,0.33-0.06,0.49-0.12
							c0,0,0,0,0,0c0,0,0,0,0,0c0.41,0,0.82,0,1.23,0c0.33-0.44,0.74-0.58,1.18-0.63c0.14-0.02,0.29-0.02,0.44-0.02
							c0.07,0,0.13,0,0.2,0c0.07,0,0.13,0,0.2,0c0.33,0,0.66-0.01,0.96-0.12c-0.83-1.26-0.36-2.37,0.52-3.69
							c0.99,1.36,0.68,2.54,0.06,3.72c0,0,0,0,0,0c1.34,0.04,2.68,0.08,4.01,0.12c0,0,0,0,0,0c-0.21-4.71-0.42-9.42-0.63-14.13
							c-4.51,1.2-8.93,2.68-13.28,4.35c0.71,2.01,1.04,3.99-0.59,6.12c-1.21-1.98-0.2-3.91-0.62-5.67c0,0,0,0,0,0
							c-0.46,0.13-0.93,0.25-1.39,0.38c-0.46,0.13-0.93,0.26-1.39,0.4c-4.89,1.52-9.79,3.04-14.68,4.57
							c-0.43,0.54-0.86,1.08-1.29,1.62l0,0c0,2.74,0.01,5.48,0.01,8.22c3.41-0.92,6.82-1.83,10.23-2.75
							c1.14-0.31,2.27-0.62,3.41-0.93c0.45-0.12,0.96-0.38,1.22-0.74c1.86-2.6,3.39-1.24,4.93,0.35
							C737.87,1003.92,738.21,1003.61,738.57,1003.42z M743.01,994.07c-0.03-0.8-0.34-2.19,0.81-2.14c1.36,0.05,0.68,1.53,0.91,2.37
							c-0.17,0.81,0.3,2.21-0.86,2.16C742.51,996.4,743.04,994.94,743.01,994.07z M731.26,1000.46c-0.68-1.84-0.61-3.04-0.03-4.26
							C731.91,997.39,731.91,998.58,731.26,1000.46z"/>
						<path className="st23" d="M717.58,1008.79l-0.01-8.58l1.38-1.73l3.88-1.21c3.62-1.13,7.24-2.26,10.86-3.38
							c0.5-0.15,0.98-0.29,1.39-0.4l0.52-0.14c0.29-0.08,0.58-0.16,0.87-0.24l0.22-0.06l0.05,0.22c0.17,0.72,0.11,1.47,0.06,2.19
							c-0.08,1.02-0.16,2.07,0.37,3.13c1.28-1.86,1.08-3.61,0.36-5.65l-0.07-0.2l0.2-0.08c4.67-1.79,9.02-3.22,13.31-4.36l0.26-0.07
							l0.66,14.63l-4.59-0.14l0.06-0.11l-0.22,0.08c-0.35,0.12-0.71,0.13-1.03,0.13l-0.2,0c-0.07,0-0.13,0-0.2,0
							c-0.17,0-0.3,0.01-0.42,0.02c-0.47,0.05-0.77,0.22-1.03,0.55l-0.06,0.08l-1.3,0c-0.16,0.05-0.32,0.09-0.5,0.11
							c-0.52,0.07-1.07-0.01-1.6-0.09c-0.23-0.03-0.48-0.07-0.71-0.09c-0.12-0.01-0.24-0.02-0.36-0.02c-0.18,0-0.35,0.01-0.5,0.03
							c-0.1,0.02-0.19,0.04-0.27,0.06c-0.1,0.03-0.19,0.07-0.28,0.11l0,0c-0.35,0.18-0.65,0.48-0.9,0.92l-0.14,0.25l-0.2-0.21
							c-0.92-0.95-1.83-1.8-2.76-1.73c-0.62,0.05-1.22,0.49-1.84,1.36c-0.38,0.53-1.12,0.76-1.34,0.82
							c-1.14,0.31-2.27,0.62-3.41,0.93c-2.42,0.66-4.84,1.31-7.26,1.95L717.58,1008.79z M718,1000.36l0.01,7.86l2.7-0.72
							c2.42-0.65,4.84-1.3,7.26-1.95c1.14-0.31,2.27-0.62,3.41-0.93c0.47-0.13,0.91-0.39,1.1-0.66c0.7-0.98,1.41-1.49,2.16-1.54
							c1.04-0.08,1.97,0.72,2.91,1.67c0.26-0.39,0.57-0.67,0.93-0.85l0,0c0.11-0.06,0.23-0.1,0.35-0.14
							c0.1-0.03,0.21-0.06,0.33-0.07c0.18-0.03,0.37-0.04,0.58-0.04c0.13,0,0.26,0.01,0.39,0.02c0.24,0.02,0.49,0.06,0.73,0.09
							c0.53,0.07,1.02,0.15,1.49,0.09c0.16-0.02,0.31-0.05,0.45-0.11l0.04-0.01l1.16,0c0.41-0.48,0.91-0.59,1.25-0.63
							c0.13-0.02,0.28-0.02,0.46-0.02c0.07,0,0.13,0,0.2,0l0.2,0c0.21,0,0.42-0.01,0.63-0.04c-0.73-1.36-0.07-2.56,0.68-3.67
							l0.17-0.26l0.18,0.25c1,1.37,0.74,2.56,0.23,3.65l3.44,0.1c-0.2-4.54-0.41-9.09-0.61-13.63c-4.13,1.11-8.32,2.48-12.81,4.2
							c0.64,1.91,0.96,3.96-0.69,6.13l-0.19,0.25l-0.16-0.27c-0.77-1.27-0.68-2.49-0.59-3.67c0.05-0.64,0.09-1.25-0.01-1.84
							c-0.22,0.06-0.44,0.12-0.65,0.18l-0.52,0.14c-0.41,0.11-0.89,0.25-1.38,0.4c-3.62,1.12-7.24,2.25-10.86,3.38l-3.75,1.17
							L718,1000.36z M747.59,999.2c-0.76,1.2-1.03,2.13-0.35,3.18l0.13,0.2l0.08-0.15C748.03,1001.32,748.27,1000.33,747.59,999.2z
							 M731.26,1001.1l-0.21-0.56c-0.66-1.79-0.66-3.07-0.02-4.43l0.18-0.37l0.21,0.36c0.71,1.25,0.73,2.5,0.04,4.44L731.26,1001.1z
							 M731.24,996.71c-0.34,0.94-0.34,1.87,0.01,3.05C731.62,998.5,731.62,997.58,731.24,996.71z M743.92,996.68
							c-0.02,0-0.04,0-0.06,0c-0.38-0.02-0.67-0.13-0.86-0.35c-0.35-0.4-0.29-1.05-0.24-1.62c0.02-0.23,0.04-0.45,0.03-0.63l0,0
							c0-0.12-0.01-0.24-0.03-0.38c-0.05-0.59-0.11-1.32,0.28-1.72c0.19-0.19,0.45-0.28,0.78-0.27c0.36,0.01,0.63,0.12,0.81,0.32
							c0.32,0.35,0.29,0.87,0.26,1.38c-0.02,0.3-0.03,0.6,0.03,0.83l0.01,0.05l-0.01,0.05c-0.04,0.21-0.04,0.48-0.04,0.74
							c0.01,0.51,0.01,1.04-0.31,1.36C744.42,996.6,744.2,996.68,743.92,996.68z M743.76,992.14c-0.18,0-0.32,0.05-0.41,0.14
							c-0.25,0.26-0.2,0.88-0.16,1.38c0.01,0.14,0.02,0.28,0.03,0.4l0,0c0.01,0.21-0.01,0.44-0.04,0.68
							c-0.04,0.48-0.1,1.03,0.14,1.29c0.11,0.13,0.29,0.19,0.55,0.2c0.19,0.01,0.33-0.03,0.41-0.11c0.19-0.18,0.18-0.62,0.18-1.04
							c0-0.27-0.01-0.54,0.04-0.78c-0.06-0.28-0.05-0.6-0.03-0.91c0.02-0.42,0.04-0.86-0.15-1.07c-0.1-0.11-0.27-0.17-0.51-0.18
							C743.79,992.14,743.77,992.14,743.76,992.14z"/>
					</g>
					<g>
						<path className="st25" d="M740.1,1003.21c0.76,0.07,1.54,0.27,2.26,0.18C741.64,1003.48,740.86,1003.28,740.1,1003.21z"/>
						<path className="st23" d="M741.97,1003.63c-0.02,0-0.05,0-0.07,0c-0.37-0.01-0.75-0.06-1.11-0.11c-0.23-0.03-0.48-0.07-0.71-0.09
							l0.04-0.43c0.24,0.02,0.49,0.06,0.73,0.09c0.42,0.06,0.82,0.12,1.2,0.11c0.08,0,0.17-0.01,0.25-0.02c0.01,0,0.02,0,0.03,0
							c0,0,0,0,0,0l0.05,0.43C742.25,1003.62,742.11,1003.63,741.97,1003.63z"/>
					</g>
					<g>
						<path className="st25" d="M740.1,1003.21c-0.12-0.01-0.25-0.02-0.37-0.02C739.85,1003.19,739.98,1003.2,740.1,1003.21z"/>
						<path className="st23" d="M740.08,1003.43c-0.12-0.01-0.24-0.02-0.36-0.02l0.01-0.43c0.13,0,0.26,0.01,0.39,0.02L740.08,1003.43z"
							/>
					</g>
					<g>
						<polygon className="st25" points="742.86,1003.27 742.86,1003.27 742.86,1003.27 						"/>
					</g>
					<g>
						<path className="st25" d="M747.64,1002.53C747.64,1002.53,747.64,1002.53,747.64,1002.53
							C747.64,1002.53,747.64,1002.53,747.64,1002.53c1.34,0.04,2.68,0.08,4.01,0.12c0,0,0,0,0,0
							C750.32,1002.61,748.98,1002.57,747.64,1002.53z"/>
						<polygon className="st23" points="751.65,1002.87 751.65,1002.87 747.64,1002.75 747.65,1002.32 751.66,1002.43 						"/>
					</g>
					<g>
						<path className="st25" d="M744.08,1003.27L744.08,1003.27c0.33-0.44,0.74-0.58,1.18-0.63
							C744.82,1002.69,744.42,1002.84,744.08,1003.27z"/>
						<path className="st23" d="M744.26,1003.4L744.26,1003.4L744.26,1003.4l-0.34-0.26c0.41-0.54,0.93-0.67,1.3-0.71c0,0,0,0,0,0
							c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.01,0
							c0,0,0,0,0,0l0.05,0.43c0,0,0,0,0,0l0,0C744.81,1002.91,744.51,1003.07,744.26,1003.4z"/>
					</g>
					<g>
						<path className="st25" d="M745.7,1002.62c-0.15,0-0.3,0.01-0.44,0.02C745.4,1002.63,745.55,1002.62,745.7,1002.62z"/>
						<path className="st23" d="M745.28,1002.86l-0.05-0.43c0.13-0.02,0.28-0.02,0.46-0.02l0,0.43
							C745.53,1002.84,745.4,1002.84,745.28,1002.86z"/>
					</g>
					<g>
						<path className="st25" d="M736.53,993.32C736.53,993.32,736.53,993.32,736.53,993.32c-0.46,0.13-0.93,0.25-1.39,0.38
							C735.6,993.58,736.07,993.45,736.53,993.32C736.53,993.32,736.53,993.32,736.53,993.32z"/>
						<path className="st23" d="M735.2,993.91l-0.11-0.42h0l0.51-0.14c0.29-0.08,0.59-0.16,0.88-0.24l0.12,0.42
							c-0.29,0.08-0.58,0.16-0.88,0.24L735.2,993.91z"/>
					</g>
					<g>
						<path className="st25" d="M717.8,1008.51c3.41-0.92,6.82-1.83,10.23-2.75C724.62,1006.68,721.21,1007.59,717.8,1008.51
							C717.8,1008.51,717.8,1008.51,717.8,1008.51z"/>
						<path className="st23" d="M717.85,1008.72l-0.11-0.42l3.04-0.82c2.4-0.64,4.79-1.29,7.19-1.94l0.11,0.42
							c-0.02,0.01-0.04,0.01-0.06,0.02c0,0,0,0,0,0c-0.01,0-0.02,0-0.03,0.01c0,0,0,0-0.01,0c-0.01,0-0.01,0-0.02,0.01c0,0,0,0,0,0
							c-0.04,0.01-0.07,0.02-0.11,0.03c0,0,0,0,0,0c-0.01,0-0.02,0-0.02,0.01c0,0,0,0-0.01,0c-0.01,0-0.02,0-0.02,0.01c0,0,0,0,0,0
							c-0.01,0-0.02,0-0.02,0.01c0,0,0,0,0,0c-0.01,0-0.02,0-0.02,0.01c0,0,0,0,0,0c-0.01,0-0.02,0-0.02,0.01c0,0,0,0-0.01,0
							c-0.01,0-0.01,0-0.02,0.01c0,0,0,0-0.01,0c-0.01,0-0.01,0-0.02,0.01c0,0,0,0,0,0c-0.01,0-0.02,0-0.02,0.01c0,0,0,0,0,0
							c-0.01,0-0.02,0-0.03,0.01c0,0,0,0,0,0c-0.01,0-0.02,0-0.03,0.01c0,0,0,0,0,0c-0.01,0-0.02,0-0.03,0.01c0,0,0,0,0,0
							c-0.01,0-0.02,0-0.03,0.01c0,0,0,0,0,0c-0.01,0-0.02,0-0.03,0.01c0,0,0,0,0,0c-2.2,0.59-4.4,1.18-6.59,1.77L717.85,1008.72z"
							/>
					</g>
					<g>
						<path className="st93" d="M753.6,1011.15c0.78-0.08,16.24,0.75,17.02,0.66c-3.62-1.76-22.15-3.18-25.92-3.28
							c-0.4,0.43-0.81,0.86-1.21,1.3C746.86,1010.52,750.23,1011.48,753.6,1011.15z"/>
						<path className="st23" d="M770.38,1012.04c-0.96,0-4.13-0.14-8.66-0.35c-3.8-0.17-7.74-0.35-8.1-0.32
							c-2.81,0.27-5.65-0.34-8.39-0.94c-0.59-0.13-1.18-0.26-1.78-0.38l-0.38-0.08l1.54-1.65l0.1,0c3.44,0.09,22.25,1.47,26.01,3.3
							l0.67,0.32l-0.74,0.09C770.6,1012.03,770.51,1012.04,770.38,1012.04z M753.79,1010.93c0.82,0,3.64,0.13,7.95,0.32
							c2.82,0.13,5.9,0.27,7.6,0.33c-5.24-1.49-20.55-2.7-24.54-2.82l-0.88,0.94c0.47,0.1,0.94,0.2,1.41,0.3
							c2.71,0.59,5.52,1.2,8.26,0.93l0,0C753.62,1010.93,753.69,1010.93,753.79,1010.93z"/>
					</g>
					<g>
						<path className="st91" d="M742.86,1003.27c0.1,0.54,0.12,1.09,0.13,1.64c0,0.69-0.03,1.38-0.02,2.07c0,0.02,0,0.05,0,0.07
							c0,0.25,0.01,0.5,0.04,0.75c0.01,0.17,0.03,0.34,0.06,0.51c0.08,0.51,0.21,1.01,0.44,1.51c0.4-0.43,0.81-0.86,1.21-1.3
							c-0.21-1.76-0.41-3.51-0.62-5.27C743.67,1003.27,743.27,1003.27,742.86,1003.27z"/>
						<path className="st23" d="M743.43,1010.22l-0.13-0.29c-0.22-0.48-0.37-0.99-0.45-1.57c-0.03-0.17-0.05-0.34-0.06-0.53
							c-0.02-0.23-0.03-0.48-0.04-0.77l0-0.07c0-0.37,0-0.74,0.01-1.11c0.01-0.32,0.01-0.64,0.01-0.96c0-0.64-0.04-1.15-0.12-1.61
							l-0.05-0.25l1.68,0l0.65,5.56L743.43,1010.22z M743.11,1003.49c0.06,0.42,0.09,0.88,0.09,1.43c0,0.32-0.01,0.65-0.01,0.97
							c-0.01,0.36-0.02,0.73-0.01,1.09l0,0.07c0,0.28,0.02,0.52,0.03,0.74c0.01,0.17,0.03,0.34,0.06,0.5
							c0.06,0.42,0.16,0.79,0.29,1.15l0.91-0.98l-0.58-4.97L743.11,1003.49z"/>
					</g>
					<g>
						<path className="st91" d="M743.05,1008.33c-0.03-0.17-0.05-0.34-0.06-0.51C743.01,1007.98,743.03,1008.16,743.05,1008.33z"/>
						<path className="st23" d="M742.84,1008.36c-0.03-0.17-0.05-0.34-0.06-0.53l0.43-0.04c0.01,0.17,0.03,0.34,0.06,0.5L742.84,1008.36
							z"/>
					</g>
					<g>
						<path className="st91" d="M743,1007.81c-0.02-0.25-0.03-0.5-0.04-0.75C742.96,1007.31,742.97,1007.56,743,1007.81z"/>
						<path className="st23" d="M742.78,1007.83c-0.02-0.22-0.03-0.46-0.04-0.74c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							l0.43-0.01c0,0,0,0.01,0,0.01l0,0c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.01c0,0,0,0,0,0
							c0.01,0.26,0.02,0.48,0.03,0.69c0,0.01,0,0.01,0,0.02c0,0,0,0,0,0L742.78,1007.83z"/>
					</g>
					<g>
						<path className="st91" d="M742.96,1006.99c-0.01-0.69,0.03-1.38,0.02-2.07C742.99,1005.61,742.95,1006.3,742.96,1006.99z"/>
						<path className="st23" d="M742.74,1006.99c0-0.08,0-0.16,0-0.23c0,0,0,0,0-0.01c0-0.01,0-0.02,0-0.02c0-0.23,0-0.46,0.01-0.69
							c0-0.05,0-0.1,0-0.16c0-0.03,0-0.05,0-0.08c0.01-0.27,0.01-0.53,0.01-0.8c0-0.03,0-0.06,0-0.08l0.43,0c0,0.03,0,0.06,0,0.08
							c0,0.3-0.01,0.59-0.01,0.89c-0.01,0.29-0.01,0.59-0.01,0.88c0,0.07,0,0.14,0,0.21L742.74,1006.99z"/>
					</g>
					<g>
						<path className="st91" d="M744.7,1008.54c-0.21-1.76-0.41-3.51-0.62-5.27h0C744.29,1005.03,744.49,1006.78,744.7,1008.54z"/>
						<polygon className="st23" points="744.48,1008.56 743.87,1003.3 743.88,1003.3 744.3,1003.25 744.91,1008.51 						"/>
					</g>
					<g>
						<path className="st91" d="M742.86,1003.27C742.86,1003.27,742.86,1003.27,742.86,1003.27c0.41,0,0.82,0,1.23,0
							C743.67,1003.27,743.27,1003.27,742.86,1003.27z"/>
						<rect x="742.86" y="1003.06" className="st23" width="1.23" height="0.43"/>
					</g>
					<g>
						<path className="st27" d="M747.58,998.81c-0.89,1.33-1.35,2.43-0.52,3.69c0.19,0.01,0.39,0.02,0.58,0.03
							C748.26,1001.34,748.57,1000.17,747.58,998.81z"/>
						<path className="st23" d="M747.77,1002.76l-0.83-0.05l-0.06-0.09c-0.96-1.46-0.27-2.74,0.53-3.93l0.17-0.26l0.18,0.25
							c1.09,1.5,0.68,2.79,0.08,3.95L747.77,1002.76z M747.18,1002.29l0.33,0.02c0.53-1.07,0.73-2.02,0.07-3.11
							C746.85,1000.37,746.57,1001.28,747.18,1002.29z"/>
					</g>
					<g>
						<path className="st91" d="M747.06,1002.5c0.19,0.01,0.39,0.02,0.58,0.03c0,0,0,0,0,0C747.45,1002.52,747.25,1002.51,747.06,1002.5
							z"/>
						<polygon className="st23" points="747.63,1002.75 747.05,1002.72 747.07,1002.28 747.65,1002.32 747.65,1002.32 747.65,1002.32 
													"/>
					</g>
					<g>
						<path className="st99" d="M715.72,1009.47c0.74-0.17,1.44-0.48,2.08-0.96c0,0,0,0,0,0c0-2.74-0.01-5.48-0.01-8.22
							c-0.56-0.18-1.12-0.36-1.68-0.55c-0.94,0.33-1.73,0.82-2.12,1.53c-0.03,0.05-0.06,0.11-0.08,0.17
							c-0.02,0.05-0.04,0.09-0.05,0.14c-0.04,0.1-0.07,0.21-0.09,0.32c-0.06,0.36-0.04,0.75,0.09,1.2c0.11,0.41,0.2,0.81,0.26,1.22
							c0.27,1.76,0.11,3.53,0.23,5.29c0,0,0,0,0,0c0,0,0,0,0,0c0.16,0,0.32-0.01,0.47-0.02
							C715.12,1009.58,715.42,1009.53,715.72,1009.47z"/>
						<path className="st23" d="M714.12,1009.83v-0.22c-0.05-0.68-0.05-1.39-0.05-2.08c-0.01-1.05-0.01-2.13-0.17-3.18
							c-0.06-0.42-0.15-0.81-0.25-1.19c-0.13-0.46-0.16-0.9-0.09-1.3c0.02-0.11,0.05-0.23,0.09-0.35c0.02-0.05,0.04-0.1,0.06-0.15
							c0.03-0.06,0.06-0.12,0.09-0.18c0.38-0.69,1.13-1.24,2.24-1.63l0.07-0.02l1.9,0.62l0.01,8.48l-0.09,0.07
							c-0.64,0.49-1.37,0.82-2.16,1h0c-0.3,0.07-0.62,0.11-0.94,0.14c-0.16,0.01-0.32,0.02-0.49,0.02L714.12,1009.83z
							 M716.11,999.97c-0.97,0.35-1.62,0.83-1.93,1.41c-0.03,0.05-0.05,0.1-0.07,0.15c-0.02,0.04-0.03,0.08-0.05,0.12
							c-0.04,0.1-0.06,0.2-0.08,0.29c-0.06,0.33-0.03,0.71,0.08,1.11c0.11,0.4,0.2,0.8,0.27,1.25c0.16,1.09,0.17,2.18,0.18,3.24
							c0,0.61,0.01,1.24,0.04,1.86c0.09,0,0.17-0.01,0.25-0.01c0.3-0.02,0.6-0.06,0.88-0.13c0.69-0.16,1.33-0.44,1.91-0.86
							l-0.01-7.95L716.11,999.97z"/>
					</g>
					<g>
						<path className="st99" d="M713.91,1001.44c-0.02,0.05-0.04,0.09-0.05,0.14C713.87,1001.54,713.89,1001.49,713.91,1001.44z"/>
						<path className="st23" d="M714.05,1001.65l-0.41-0.14c0.02-0.05,0.04-0.1,0.06-0.15l0.4,0.17
							C714.09,1001.57,714.07,1001.61,714.05,1001.65z"/>
					</g>
					<g>
						<path className="st99" d="M713.99,1001.28c0.39-0.71,1.18-1.2,2.12-1.53c0,0,0,0,0,0
							C715.16,1000.08,714.37,1000.56,713.99,1001.28z"/>
						<path className="st23" d="M714.18,1001.38l-0.38-0.21c0,0,0,0,0,0c0.38-0.69,1.13-1.24,2.23-1.63l0,0c0,0,0,0,0,0l0.14,0.41
							c-0.25,0.09-0.48,0.18-0.68,0.29C714.86,1000.55,714.42,1000.93,714.18,1001.38C714.18,1001.38,714.18,1001.38,714.18,1001.38
							z"/>
					</g>
					<g>
						<path className="st99" d="M713.85,1001.58c-0.04,0.1-0.07,0.21-0.09,0.32C713.78,1001.79,713.81,1001.69,713.85,1001.58z"/>
						<path className="st23" d="M713.98,1001.94l-0.43-0.08c0.02-0.11,0.05-0.23,0.09-0.35l0.41,0.14
							C714.02,1001.76,713.99,1001.85,713.98,1001.94z"/>
					</g>
					<g>
						<path className="st99" d="M713.99,1001.28c-0.03,0.05-0.06,0.11-0.08,0.17C713.93,1001.39,713.96,1001.33,713.99,1001.28z"/>
						<path className="st23" d="M714.1,1001.53l-0.4-0.17c0.03-0.06,0.06-0.12,0.09-0.18l0.38,0.21
							C714.15,1001.43,714.13,1001.48,714.1,1001.53z"/>
					</g>
					<g>
						<path className="st99" d="M714.11,1004.32c0.27,1.76,0.11,3.53,0.23,5.29c0,0,0,0,0,0
							C714.22,1007.85,714.38,1006.09,714.11,1004.32z"/>
						<path className="st23" d="M714.12,1009.63c-0.05-0.7-0.05-1.4-0.05-2.09c-0.01-1.04-0.01-2.11-0.17-3.16c0-0.01,0-0.02,0-0.03
							c0,0,0,0,0,0l0.43-0.06c0.16,1.09,0.17,2.18,0.18,3.24c0,0.68,0.01,1.38,0.05,2.06h0c0,0,0,0,0,0L714.12,1009.63z"/>
					</g>
					<g>
						<path className="st99" d="M717.8,1008.51c-0.64,0.48-1.33,0.79-2.08,0.96C716.46,1009.3,717.16,1008.99,717.8,1008.51
							C717.8,1008.51,717.8,1008.51,717.8,1008.51C717.8,1008.51,717.8,1008.51,717.8,1008.51z"/>
						<path className="st23" d="M715.77,1009.68l-0.09-0.42l0,0c0,0,0,0,0,0c0.73-0.16,1.4-0.47,1.99-0.92l0.26,0.34
							c-0.01,0.01-0.02,0.02-0.04,0.03c0,0,0,0,0,0h0C717.25,1009.18,716.54,1009.51,715.77,1009.68z"/>
					</g>
					<g>
						<path className="st99" d="M717.78,1000.29c0,2.74,0.01,5.48,0.01,8.22C717.79,1005.77,717.79,1003.03,717.78,1000.29
							L717.78,1000.29z"/>
						
							<rect x="717.57" y="1000.29" transform="matrix(1 -1.509156e-03 1.509156e-03 1 -1.515 1.0844)" className="st23" width="0.43" height="8.22"/>
					</g>
					<g>
						<path className="st89" d="M719.08,998.67C719.08,998.67,719.08,998.67,719.08,998.67c1.17-1.46,1.38-2.65-0.78-3.41
							c-0.12-0.04-0.24-0.09-0.36-0.15c-0.82-0.38-1.55-1-2.51-1.07c0.02,0.36,0.03,0.72,0.04,1.08c0.05,1.56,0.08,3.12,0.63,4.62
							c0,0,0,0,0,0c0.56,0.18,1.12,0.36,1.68,0.55C718.21,999.75,718.64,999.21,719.08,998.67
							C719.08,998.67,719.08,998.67,719.08,998.67z"/>
						<path className="st23" d="M717.86,1000.54l-1.92-0.62l-0.04-0.1c-0.53-1.48-0.58-3.03-0.63-4.53l0-0.15
							c-0.01-0.36-0.02-0.71-0.04-1.07l-0.01-0.24l0.24,0.02c0.73,0.05,1.32,0.39,1.89,0.71c0.24,0.14,0.46,0.26,0.69,0.37
							c0.12,0.06,0.23,0.1,0.34,0.14c1,0.35,1.57,0.81,1.74,1.41c0.18,0.63-0.09,1.38-0.86,2.34l-0.17-0.13l0.17,0.13
							L717.86,1000.54z M716.27,999.57l1.43,0.47l1.2-1.5l0,0l0.01-0.01c0.66-0.82,0.92-1.46,0.78-1.94
							c-0.13-0.45-0.62-0.82-1.46-1.12c-0.12-0.04-0.24-0.09-0.38-0.16c-0.25-0.12-0.49-0.25-0.72-0.39
							c-0.48-0.27-0.94-0.54-1.46-0.63c0.01,0.27,0.02,0.55,0.03,0.82l0,0.15C715.75,996.7,715.79,998.18,716.27,999.57z"/>
					</g>
					<g>
						<path className="st89" d="M715.48,995.12c0.05,1.56,0.08,3.12,0.63,4.62c0,0,0,0,0,0c0,0,0,0,0,0
							C715.56,998.24,715.53,996.68,715.48,995.12z"/>
						<path className="st23" d="M715.9,999.82C715.9,999.82,715.9,999.82,715.9,999.82L715.9,999.82c-0.28-0.77-0.43-1.57-0.51-2.36
							c-0.08-0.73-0.1-1.46-0.12-2.17l0-0.15l0.43-0.01l0,0.15c0.05,1.47,0.1,2.98,0.61,4.4L715.9,999.82z"/>
					</g>
					<g>
						<path className="st89" d="M715.43,994.05C715.44,994.05,715.44,994.05,715.43,994.05c0.96,0.07,1.69,0.69,2.51,1.07
							C717.12,994.73,716.4,994.11,715.43,994.05z"/>
						<path className="st23" d="M717.85,995.31L717.85,995.31C717.85,995.31,717.85,995.31,717.85,995.31
							c-0.25-0.12-0.49-0.26-0.72-0.39c-0.55-0.32-1.08-0.62-1.71-0.66l-0.2-0.01v-0.19l-0.03-0.25l0.26,0.02
							c0.73,0.05,1.32,0.39,1.89,0.72c0.24,0.14,0.46,0.26,0.69,0.37L717.85,995.31z"/>
					</g>
					<g>
						<path className="st89" d="M719.08,998.67c-0.43,0.54-0.86,1.08-1.29,1.62C718.21,999.75,718.64,999.21,719.08,998.67
							C719.08,998.67,719.08,998.67,719.08,998.67z"/>
						<polygon className="st23" points="717.95,1000.42 717.61,1000.15 718.91,998.53 718.91,998.53 718.91,998.53 719.24,998.8 						
							"/>
					</g>
					<g>
						<path className="st91" d="M710.93,1005.72c0.48-1.64,0.58-2.65-0.16-4.35C710.09,1003.06,710.02,1004.08,710.93,1005.72z"/>
						<path className="st23" d="M710.99,1006.28l-0.25-0.45c-0.98-1.77-0.84-2.87-0.17-4.53l0.19-0.48l0.21,0.48
							c0.76,1.75,0.67,2.8,0.17,4.49L710.99,1006.28z M710.77,1001.97c-0.39,1.13-0.45,1.98,0.09,3.16
							C711.16,1003.98,711.2,1003.14,710.77,1001.97z"/>
					</g>
					<g>
						<path className="st27" d="M737.15,998.99c1.63-2.13,1.3-4.11,0.59-6.12c-0.4,0.15-0.8,0.3-1.21,0.45
							C736.95,995.08,735.94,997.02,737.15,998.99z"/>
						<path className="st23" d="M737.13,999.38l-0.16-0.27c-0.77-1.27-0.68-2.49-0.59-3.67c0.05-0.72,0.11-1.41-0.05-2.06l-0.04-0.19
							l1.59-0.6l0.07,0.21c0.69,1.96,1.09,4.08-0.62,6.33L737.13,999.38z M736.78,993.46c0.12,0.66,0.07,1.34,0.02,2
							c-0.08,1.02-0.16,2.07,0.37,3.13c1.24-1.8,1.09-3.49,0.43-5.44L736.78,993.46z"/>
					</g>
					<g>
						<path className="st91" d="M737.74,992.87c-0.4,0.15-0.8,0.3-1.21,0.45c0,0,0,0,0,0C736.93,993.17,737.34,993.02,737.74,992.87z"/>
						<polygon className="st23" points="736.61,993.53 736.61,993.53 736.45,993.12 736.45,993.12 736.45,993.12 737.66,992.67 
							737.81,993.07 						"/>
					</g>
					<g>
						<path className="st94" d="M723.67,1031.11c-0.19,0.05-0.39,0.12-0.6,0.21c0,0,0,0,0,0
							C723.28,1031.23,723.48,1031.16,723.67,1031.11z"/>
						<path className="st23" d="M723.15,1031.51l-0.17-0.4c0.21-0.09,0.41-0.16,0.61-0.21c0.01,0,0.01,0,0.02,0l0.11,0.42
							C723.54,1031.37,723.35,1031.43,723.15,1031.51z"/>
					</g>
					<g>
						<path className="st100" d="M687.95,1015.54"/>
					</g>
					<g>
						<path className="st100" d="M687.97,1015.54"/>
					</g>
					<g>
						<line className="st91" x1="688.02" y1="1014.67" x2="688.02" y2="1014.67"/>
						<path className="st23" d="M688.02,1014.67"/>
					</g>
					<g>
						<path className="st27" d="M743.86,996.46c1.16,0.05,0.69-1.35,0.86-2.16c-0.23-0.84,0.45-2.32-0.91-2.37
							c-1.15-0.05-0.84,1.34-0.81,2.14C743.04,994.94,742.51,996.4,743.86,996.46z"/>
						<path className="st23" d="M743.92,996.68c-0.02,0-0.04,0-0.06,0l0,0c-0.38-0.02-0.67-0.13-0.86-0.35
							c-0.35-0.4-0.29-1.05-0.24-1.62c0.02-0.23,0.04-0.45,0.03-0.63c0-0.12-0.01-0.24-0.03-0.38c-0.05-0.59-0.11-1.32,0.28-1.72
							c0.19-0.19,0.45-0.28,0.78-0.27c0.36,0.01,0.63,0.12,0.81,0.32c0.32,0.35,0.29,0.87,0.26,1.38c-0.02,0.3-0.03,0.6,0.03,0.83
							l0.01,0.05l-0.01,0.05c-0.04,0.21-0.04,0.48-0.04,0.74c0.01,0.51,0.01,1.04-0.31,1.36
							C744.42,996.6,744.2,996.68,743.92,996.68z M743.87,996.24c0.19,0.01,0.33-0.03,0.41-0.11c0.19-0.18,0.18-0.62,0.18-1.04
							c0-0.27-0.01-0.54,0.04-0.78c-0.06-0.28-0.05-0.6-0.03-0.91c0.02-0.42,0.04-0.86-0.15-1.07c-0.1-0.11-0.27-0.17-0.51-0.18
							c-0.21-0.01-0.36,0.04-0.45,0.14c-0.25,0.26-0.2,0.88-0.16,1.38c0.01,0.14,0.02,0.28,0.03,0.4c0.01,0.21-0.01,0.44-0.04,0.68
							c-0.04,0.48-0.1,1.03,0.14,1.29C743.43,996.16,743.61,996.23,743.87,996.24L743.87,996.24z"/>
					</g>
					<g>
						<path className="st27" d="M731.26,1000.46c0.66-1.87,0.66-3.07-0.03-4.26C730.65,997.41,730.58,998.62,731.26,1000.46z"/>
						<path className="st23" d="M731.26,1001.1l-0.21-0.56c-0.66-1.79-0.66-3.07-0.02-4.43l0.18-0.37l0.21,0.36
							c0.71,1.25,0.73,2.5,0.04,4.44L731.26,1001.1z M731.24,996.71c-0.34,0.94-0.34,1.87,0.01,3.05
							C731.62,998.5,731.62,997.58,731.24,996.71z"/>
					</g>
					<g>
						<path className="st91" d="M702.57,999.73c0.73-0.29,1.46-0.58,2.19-0.87c-0.17-1.02-0.33-2.05-0.5-3.07
							c-2.33,0.55-0.65,2.85-1.73,3.95c0,0,0.04-0.04,0.04-0.04C702.58,999.71,702.57,999.72,702.57,999.73z"/>
						<path className="st23" d="M702.35,1000.05l0-0.12l-0.26-0.04l0.32-0.33c0.35-0.38,0.33-0.95,0.31-1.56
							c-0.03-0.92-0.06-2.06,1.49-2.43l0.23-0.05l0.57,3.47l-2.39,0.95c-0.01,0-0.01,0-0.02,0.01L702.35,1000.05z M704.09,996.06
							c-0.98,0.32-0.96,1.04-0.94,1.93c0.01,0.45,0.03,0.91-0.11,1.32l1.48-0.59L704.09,996.06z"/>
					</g>
					<g>
						<path className="st93" d="M707.28,998.33c0,0,0.13-0.31,0.13-0.31c-0.13-1.63-1.62-2.01-3.14-2.23c0.17,1.02,0.33,2.05,0.5,3.07
							C705.29,998.71,706.77,998.47,707.28,998.33z"/>
						<path className="st23" d="M704.59,999.13l-0.59-3.6l0.29,0.04c1.11,0.16,3.17,0.46,3.33,2.42l0,0.05l-0.02,0.05
							c-0.17,0.41-0.18,0.44-0.3,0.45c-0.27,0.07-0.75,0.17-1.25,0.26c-0.49,0.09-0.99,0.19-1.24,0.26L704.59,999.13z
							 M704.53,996.05l0.41,2.54c0.28-0.06,0.65-0.14,1.04-0.21c0.43-0.08,0.87-0.17,1.14-0.23l0.07-0.16
							C707.07,996.75,706.09,996.3,704.53,996.05z"/>
					</g>
				</g>
				<g>
					<path className="st23" d="M614.95,1076c0.83,1.48,0.73,3.06,1.09,4.58c0.74,3.14,2.42,4.2,5.47,2.85c2.19-0.97,4.75-1.79,6.65-0.79
						c3.43,1.79,6.43,1.91,9.75,0.12c1.07-0.57,2.37,0.61,3.6-0.07c5.38-2.96,10.26-0.25,15.21,1.44c0.71,0.24,1.18,0.94,2.14,0.59
						c2.93-1.06,6.03-1.02,9.08-1.25c2.62-0.2,4.66,0.76,6.18,2.85c1.09,1.5,2.24,1.83,3.78,0.61c1.98-1.57,4.21-2.11,6.67-1.25
						c1.91,0.67,2.67-0.29,2.75-2.01c0.09-1.97,1.01-3.14,2.99-3.37c0.7-0.08,1.19-0.3,1.28-1.06c0.43-0.06,0.97,0.04,1.27-0.19
						c3.27-2.49,6.51-1.51,9.73,0.06c1.13,0.55,2.21,0.48,3.34,0.13c0,0,0.21-0.05,0.21-0.05l0.21,0c0.39,0.02,0.78,0.03,1.18,0.05
						c0.63,1.29,0.1,3.58,2.35,3.49c1.68-0.07,2.82,0.49,4.01,1.5c2.71,2.28,4.76,2.79,7.17,0.88c3.22-2.54,6.74-2.14,10.32-2.14
						c1.59,0,2.9,0.83,4.02,1.77c2.02,1.68,5.55,2.06,7.71,0.53c2.63-1.86,6.19-2.54,7.54-6.05c0.24-0.63,1.08-0.88,1.8-1
						c2.1-0.34,3.7-1.65,5.29-2.87c2.27-1.74,5.28-2.09,7.15,0.02c2.2,2.47,4.71,2.04,6.64,0.67c3.82-2.7,7.58-2.42,11.79-1.49
						c2.59,0.57,5.68,0.01,7.8-1.34c4.15-2.63,8.11-5.17,13.15-5.87c2.6-0.36,4.43,0.34,5.7,2.26c2.53,3.83,6.64,3.55,10.24,3.72
						c3.21,0.15,6.62-1.07,9.13,1.72c1.49,1.65,3.18,1.28,4.96,1.55c2.82,0.43,5.89-0.62,8.27,1.74c0.2,0.2,0.84,0.09,1.24-0.01
						c1.82-0.41,3.69-0.24,5.52-0.43c1.81-0.18,4.33,0,5.32,1.6c1.42,2.32,3.36,3.46,5.66,4.41c0.75,0.31,1.48,0.8,1.68,1.6
						c0.5,1.91,2.12,2.2,3.53,2.14c3.28-0.14,5.58,2.52,8.69,2.76c0.25,0.02,0.45,0.68,0.67,1.04c-91.39,0-176.72,0.01-268.11,0.01"
						/>
				</g>
				<g>
					<path className="st88" d="M688.58,1017.22c2.74-0.58,5.51-1.03,7.96-2.52c0.01-0.09,0.05-0.19,0.03-0.28
						c-0.02-0.08-0.1-0.21-0.14-0.21c-2.83,0.08-5.66,0.17-8.48,0.25c0.01,0.35,0.01,0.71,0.02,1.06c0,0-0.34,1.13-0.24,1.75
						l0.86-0.01C688.59,1017.25,688.58,1017.22,688.58,1017.22C688.58,1017.22,688.58,1017.22,688.58,1017.22"/>
					<path className="st23" d="M687.63,1017.39l-0.01-0.09c-0.09-0.61,0.2-1.63,0.24-1.78l-0.02-1.15l0.91-0.03
						c2.56-0.08,5.12-0.16,7.68-0.23c0.15,0,0.24,0.23,0.25,0.29c0.02,0.08,0,0.17-0.02,0.24c-0.01,0.03-0.01,0.05-0.01,0.08
						l-0.01,0.05l-0.04,0.03c-2.21,1.34-4.72,1.85-7.15,2.35c-0.25,0.05-0.5,0.1-0.75,0.15l0.01,0.07l-0.12,0.01L687.63,1017.39z
						 M688.05,1014.57l0.02,0.97l0,0.02c0,0.01-0.3,1-0.25,1.61l0.65-0.01l-0.01-0.05l0.11-0.01c0.28-0.06,0.56-0.12,0.84-0.17
						c2.39-0.49,4.86-1,7.04-2.3c0-0.01,0.01-0.03,0.01-0.04c0.01-0.06,0.02-0.11,0.02-0.15c-0.01-0.04-0.04-0.09-0.06-0.12
						c-2.55,0.07-5.1,0.15-7.65,0.23L688.05,1014.57z"/>
				</g>
			</g>
			<path d="M774.97,2225.78c-0.26-0.45-6.35-10.98-7.59-11.45c-1.15-0.43-4.5-2.64-7.53-5.52c-2.2-2.09-4.65-2.8-6.62-3.38
				c-0.72-0.21-1.34-0.39-1.85-0.61c-0.03-0.01-1.75-0.76-3.86-1.76c-0.27-0.45-0.55-0.72-0.88-0.8c-0.23-0.06-0.45-0.02-0.65,0.06
				c-1.19-0.59-2.4-1.22-3.44-1.83c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01-0.01-0.02-0.01-0.03-0.02c-0.47-0.28-0.91-0.55-1.29-0.81
				c-1.98-1.36-3.19-2.45-4.16-3.33c-0.32-0.29-0.63-0.57-0.93-0.83c-0.09-0.07-0.21-0.15-0.34-0.22c-0.23-0.77-0.46-1.56-0.69-2.34
				c-0.05-0.16-0.1-0.33-0.15-0.49c-0.14-0.45-0.29-0.91-0.32-1.36c-0.09-1.54-0.14-3.12-0.18-4.64c-0.02-0.6-0.04-1.21-0.06-1.81
				c-0.02-0.62-0.04-1.24-0.06-1.86c-0.03-0.91-0.06-1.82-0.09-2.73c-0.03-1.01-0.07-2.02-0.1-3.04c-0.03-0.86-0.06-1.72-0.08-2.58
				c-0.06-1.68-0.11-3.35-0.17-5.03l-0.02-0.46c-0.02-0.62-0.04-1.25-0.06-1.87c-0.04-1.23-0.08-2.46-0.12-3.69
				c-0.02-0.63-0.04-1.26-0.06-1.9c-0.02-0.75-0.04-1.53-0.07-2.3c0-0.04,0-0.09,0-0.14c0-0.14-0.02-0.28-0.05-0.42
				c-0.02-0.11-0.04-0.22-0.1-0.31c-0.27-0.41-0.64-0.84-1.26-0.92c-0.08-0.01-0.16-0.04-0.24-0.07c-0.05-0.02-0.1-0.04-0.14-0.05
				c-0.45-0.14-0.9-0.28-1.35-0.42l-0.62-0.2l-0.04,0.12c-0.05,0.16-0.11,0.33-0.16,0.49c-0.22,0.64-0.45,1.3-0.38,2.02
				c-0.15-0.03-0.26-0.06-0.3-0.09c0-0.04,0-0.08,0-0.16c0-0.06,0-0.12,0-0.2c0-0.06,0-0.11,0-0.17l0-0.08
				c0-0.22-0.03-0.39-0.07-0.54c-0.09-0.44-0.35-0.67-0.89-0.85c-0.41-0.13-0.82-0.26-1.24-0.4l-0.66-0.21l-0.57,1.62l-0.34-0.15
				c0-0.07,0-0.14,0-0.2c0-0.25,0-0.49,0-0.73c0-0.12-0.04-0.25-0.08-0.36c-0.11-0.35-0.39-0.67-0.73-0.78
				c-0.15-0.05-0.3-0.09-0.45-0.14c-0.21-0.07-0.43-0.13-0.64-0.2c-0.24-0.07-0.47-0.14-0.71-0.21c-0.11-0.03-0.21-0.06-0.32-0.09
				l-0.12-0.03l-0.56,1.77c-0.06-0.03-0.12-0.06-0.19-0.08c-0.13-0.04-0.19-0.1-0.23-0.19c0-0.04,0-0.08,0-0.13
				c0.02-0.18,0.01-0.36,0.01-0.53c0-0.04,0-0.09,0-0.13c0-0.12-0.04-0.25-0.08-0.37c-0.1-0.35-0.37-0.67-0.71-0.79
				c-0.39-0.14-0.79-0.28-1.17-0.41l-0.17-0.06c-0.21-0.07-0.41-0.14-0.63-0.21l-0.41-0.14l-0.48,1.84
				c-0.01-0.01-0.02-0.01-0.03-0.02c-0.12-0.07-0.23-0.13-0.35-0.16c-0.08-0.02-0.14-0.06-0.17-0.11c-0.01-0.04-0.01-0.1-0.01-0.16
				c0.01-0.22,0.01-0.44,0-0.66c0-0.23,0-0.47,0.01-0.7c0.01-0.27-0.02-0.5-0.07-0.72c-0.04-0.2-0.1-0.39-0.2-0.57
				c-0.04-0.07-0.14-0.24-0.3-0.3c-0.79-0.29-1.44-0.51-2.04-0.71c-0.06-0.02-0.09-0.04-0.12-0.06l0-0.23c0-1.09,0-2.21,0-3.28
				c0-1.41,0-2.87,0.01-4.3c0-0.24,0.06-0.48,0.11-0.74c0.02-0.1,0.04-0.2,0.06-0.3c0.02-0.02,0.04-0.04,0.05-0.08
				c0.01-0.03,0.02-0.05,0.04-0.08c0.04-0.09,0.09-0.18,0.09-0.3c0-0.65,0-1.31,0-1.95c0-0.59,0-1.2,0-1.8
				c0-0.28-0.13-0.42-0.28-0.53c-0.06-0.04-0.12-0.08-0.17-0.11c-0.2-0.12-0.27-0.17-0.25-0.34c0.02-0.13-0.1-0.22-0.15-0.25
				c-0.15-0.08-0.31-0.16-0.48-0.25l-0.33-0.17l-0.56,1.58l-0.11-0.04c0-0.04,0-0.08-0.01-0.12c-0.01-0.15-0.02-0.27,0.01-0.38
				c0.07-0.29-0.03-0.52-0.12-0.72c-0.06-0.13-0.11-0.25-0.12-0.37c-0.01-0.13-0.14-0.18-0.24-0.22l-0.01,0
				c-0.34-0.13-0.66-0.19-0.97-0.23c0-0.57,0-1.15,0-1.72c0-1.08,0-2.17,0-3.25s0-2.17,0-3.25c0-0.07-0.04-0.12-0.09-0.15
				c0.03,0,0.06-0.02,0.06-0.06c0-0.18,0-0.36,0-0.54c0-0.03,0-0.05,0-0.08c0-0.15,0-0.3,0-0.45l0.04-0.61
				c0-0.05-0.01-0.1-0.04-0.15c0-0.13,0-0.27,0-0.4c0-0.09-0.13-0.09-0.13,0c0,0,0,0,0,0c-0.18-0.12-0.37-0.23-0.58-0.31
				c-0.84-0.32-1.82-0.24-2.59,0.23c-0.03,0.02-0.04,0.06-0.02,0.09c-0.08,0.06-0.14,0.12-0.19,0.19c0-0.07,0-0.13,0-0.2
				c0-0.09-0.13-0.09-0.13,0c0,0.4,0,0.8,0,1.2c-0.04,0.27-0.11,0.55,0,0.67c0,0.01,0,0.03,0,0.04c0,0.07,0.07,0.07,0.11,0.02
				c0,0.02,0,0.05,0.03,0.06c0.06,0.02,0.13,0.02,0.19,0.03c0.07,0.04,0.15,0.07,0.23,0.07c0.44-0.03,0.88-0.07,1.32-0.1
				c0.02,0,0.03,0,0.05,0c0.07,0,0.14-0.01,0.21,0c0.33,0.08,0.64,0.19,0.88,0.28c0.06,0.04,0.12,0.09,0.17,0.15
				c0.05,0.06,0.12-0.01,0.1-0.07c0.01,0,0.01,0,0.02,0c-0.02,0.03-0.04,0.06-0.04,0.1c0,0.61,0,1.22,0,1.83c0,1.08,0,2.17,0,3.25
				c0,1.04,0,2.08,0,3.12c-0.02,0-0.03,0-0.05,0c-0.31,0.02-0.62,0.01-0.95,0.01c-0.15,0-0.29,0-0.44,0h-0.15v1.38h-0.62v-1.77h-1.7
				l-0.03,0.54l-0.31,0.03l-0.03,0.1c-0.06,0.22-0.15,0.43-0.23,0.64c-0.19,0.48-0.38,0.97-0.34,1.49c-0.03,0.02-0.07,0.04-0.1,0.06
				c-0.13,0.07-0.25,0.14-0.32,0.25c-0.16,0.23-0.35,0.32-0.64,0.29c-0.41-0.04-0.73,0.13-0.96,0.51c-0.11,0.18-0.27,0.34-0.43,0.44
				c-0.17,0.11-0.33,0.23-0.36,0.5c-0.01,0.02-0.04,0.06-0.06,0.08c-0.27,0.01-0.54,0.01-0.83,0.01c-0.05,0-0.11,0-0.16,0v-1.53
				h-3.04v1.56h-0.39c-0.34,0-0.52,0-0.6-0.06c-0.08-0.06-0.13-0.23-0.23-0.56c-0.05-0.18-0.16-0.39-0.5-0.37
				c-0.17,0.01-0.29-0.01-0.36-0.05c-0.35-0.21-0.74-0.29-1.22-0.26c-0.11,0.01-0.23,0.01-0.34,0c-0.01,0-0.02,0-0.03,0
				c-0.03-0.22-0.11-0.45-0.3-0.57c-0.2-0.12-0.48-0.1-0.93,0.08l-0.09,0.04l-0.02,0.42c-0.03,0.01-0.05,0.01-0.08,0.02
				c-0.13,0.04-0.25,0.07-0.37,0.09c-0.39,0.06-0.42,0.33-0.41,0.54c0.01,0.26,0.01,0.53,0.01,0.79c0,0.07,0,0.13,0,0.2l-0.38,0.06
				c0-0.07,0-0.12,0.01-0.18c0.07-0.45-0.18-0.64-0.41-0.72c-0.19-0.07-0.25-0.17-0.22-0.37l0.03-0.19l-0.19,0.01
				c-0.14,0.01-0.28,0-0.42,0c-0.46-0.01-0.94-0.03-1.39,0.31c-0.19,0.14-0.31,0.29-0.3,0.58c0.01,0.16-0.02,0.34-0.06,0.51
				c-0.02,0.09-0.04,0.17-0.07,0.27c-0.06,0.18-0.11,0.37-0.1,0.56c0.01,0.19-0.04,0.34-0.14,0.49v-0.43l-0.18,0.02
				c-0.07,0.01-0.14,0.02-0.2,0.03c-0.15,0.02-0.29,0.03-0.43,0.06c-0.43,0.08-0.87,0.61-0.87,1.05l0,0.25c0,1.51,0,3.01,0,4.52
				v0.11c0,0.6,0,1.22,0.22,1.83c0.06,0.15,0.08,0.34,0.08,0.58c0.01,2.73,0,5.22,0,7.61c0,0.11-0.05,0.24-0.11,0.32
				c-0.19,0.24-0.42,0.48-0.64,0.72c-0.31,0.28-0.68,0.67-1.04,1.21c0,0,0,0.01,0,0.01c-0.17,0.25-0.33,0.54-0.48,0.87
				c-0.08,0.16-0.14,0.33-0.21,0.5c-0.08,0.21-0.15,0.42-0.21,0.65c-0.05,0.18-0.1,0.37-0.14,0.57c-0.05,0.26-0.1,0.55-0.13,0.85
				c-0.01,0.1-0.03,0.18-0.04,0.28v0.12c-0.02,0.22-0.03,0.44-0.04,0.67h-0.55c-0.87,0-1.69,0-2.53,0c-0.05,0-0.13-0.03-0.17-0.06
				c-0.71-0.62-1.57-0.98-2.78-1.16c-0.41-0.06-0.8,0-1.26,0.19l-0.08,0.04c-0.36,0.15-0.77,0.33-0.91,0.82
				c-0.01,0.02-0.05,0.06-0.07,0.08l-0.01,0.01c-0.13,0.13-0.27,0.26-0.41,0.38c-0.29,0.27-0.59,0.55-0.86,0.84
				c-0.25,0.27-0.42,0.35-0.72,0.15c-0.17-0.12-0.44-0.11-0.63-0.07c-0.12,0.02-0.27,0.08-0.33,0.18l-0.03,0.05
				c-0.25,0.4-0.51,0.82-0.41,1.36c-0.01,0-0.01-0.01-0.02-0.01c-0.12-0.07-0.24-0.13-0.37-0.17c-0.05-0.02-0.1-0.04-0.16-0.05
				c-0.21-0.08-0.45-0.16-0.66-0.11c-0.76,0.19-1.25,0.64-1.44,1.32c-0.04,0.14-0.11,0.32-0.25,0.36c-0.14,0.05-0.31-0.06-0.43-0.15
				c-0.27-0.21-0.53-0.25-0.87-0.12c-0.4,0.16-0.83,0.28-1.27,0.37c-0.28,0.06-0.39,0.18-0.39,0.45l0,0.16
				c-0.01,0.63-0.03,1.29-0.1,1.93c-0.11,0.96-0.34,1.68-0.71,2.29c-0.06,0.1-0.1,0.22-0.1,0.35c-0.05,0.83-0.09,1.7-0.13,2.59
				c-0.03,0.59-0.05,1.19-0.08,1.78c-0.03,0.6-0.05,1.2-0.08,1.8c-0.03,0.66-0.06,1.33-0.09,1.99c-0.03,0.61-0.06,1.22-0.09,1.83
				c-0.02,0.53-0.05,1.07-0.07,1.6c-0.03,0.64-0.06,1.28-0.09,1.91c-0.04,0.86-0.08,1.73-0.13,2.59c-0.03,0.59-0.06,1.17-0.09,1.76
				l-0.01,0.22c-0.05,0.97-0.1,1.97-0.14,2.96c-0.01,0.14,0,0.28,0,0.42c-0.4,0.12-0.62,0.25-0.86,0.39
				c-0.27,0.16-0.58,0.35-1.28,0.56c-0.97,0.29-2.06,1.38-3.33,2.65c-1.02,1.02-2.18,2.18-3.38,2.98
				c-2.67,1.77-9.24,7.48-10.39,8.57c-0.27,0.25-1.22,1.18-2.53,2.45c-4.03,3.92-11.79,11.46-14.31,13.7c0,0,0,0,0,0l-0.21,0.15
				l0.04,0c-0.32,0.28-0.53,0.45-0.62,0.49l-0.36,0.18l0.4,0c0.12,0,6.19,0.03,12.62,0.09c3.04,0.18,5.59,0.31,6.07,0.28
				c0.25-0.01,0.75,0.01,1.5,0.05c1.2,0.06,3.09,0.16,5.57,0.16c3.41,0,7.96-0.19,13.44-0.92l0,0c0.53-0.05,1.06-0.09,1.57-0.14
				c2.89-0.26,5.39-0.48,6.38-0.48c0.01,0,0.01,0,0.02,0c1.16,0,5.71-0.53,10.96-1.14c0.49-0.06,0.98-0.11,1.48-0.17l-0.03,0.67
				l30.02-0.11c0.6,0.26,1.15,0.39,1.62,0.43l2.99,0.2c1.27,0.17,2.43,0.29,3.4,0.33c0.94,0.04,1.95,0.07,2.98,0.09l0.01,0l0.05,0
				l0,0c0.83,0.02,1.68,0.04,2.51,0.06c4.32,0.09,8.79,0.18,10.86,0.6c1.85,0.37,3.45,0.32,5.14,0.28c1.08-0.03,2.2-0.06,3.49,0.01
				c1.94,0.1,6.25-0.33,10.04-0.71c2.67-0.27,5.2-0.52,6.31-0.52c0.01,0,0.02,0,0.02,0c2.62,0,7.51-1.15,7.55-1.16l0.12-0.03
				L774.97,2225.78z M710.61,2128.1C710.61,2128.1,710.61,2128.1,710.61,2128.1C710.62,2128.1,710.62,2128.1,710.61,2128.1
				C710.62,2128.1,710.62,2128.1,710.61,2128.1z M710.57,2126.03c-0.04-0.05-0.09-0.1-0.15-0.14c0.05,0.03,0.1,0.05,0.15,0.08
				C710.57,2125.99,710.57,2126.01,710.57,2126.03z"/>
			<g>
				<g>
					<g>
						<path className="st53" d="M625.14,2224.02c0.88-0.42,16.43-15.65,17.66-16.82c1.23-1.16,7.74-6.81,10.38-8.56s4.92-5.09,6.68-5.62
							c1.76-0.53,1.06-0.88,3.52-1.23c2.46-0.35,9.67-1.76,11.43-2.11s10.73-0.88,10.73-0.88s20.58-0.53,21.81-1.06
							c1.23-0.53,2.46-0.35,2.99-1.06s1.06-1.23,1.41-1.76c0.35-0.53-0.35-0.35,0.35-0.53c0.7-0.18,2.11,2.11,2.64,2.64
							s0.7,0.88,1.58,0.53c0.88-0.35-0.53,0.35,0.88-0.35s0.53-1.06,1.41-0.7c0.88,0.35,1.76,0.7,2.99,1.41
							c1.23,0.7,2.29,0.88,2.99,1.23c0.7,0.35,7.03,1.41,8.27,2.46s2.46,2.37,5.1,4.17s8.27,4.36,10.2,5.19
							c1.93,0.83,5.45,1.14,8.44,3.98c2.99,2.83,6.33,5.08,7.56,5.54c1.23,0.46,7.55,11.41,7.55,11.41s-4.91,1.17-7.55,1.16
							c-2.64,0-13.01,1.4-16.36,1.23c-3.34-0.18-5.63,0.32-8.62-0.28c-2.99-0.6-10.8-0.53-16.42-0.75s-17.21-3.12-22.13-3.07
							c-4.92,0.05-25.68,2.87-28.31,2.87c-2.64,0-15.65,1.54-20.22,1.29C647.51,2224.13,625.14,2224.02,625.14,2224.02z"/>
						<path className="st23" d="M653.22,2224.49c-0.42,0-0.8-0.01-1.14-0.03c-4.52-0.24-26.71-0.35-26.93-0.35l-0.4,0l0.36-0.17
							c0.67-0.32,10.42-9.79,15.1-14.35c1.31-1.28,2.26-2.2,2.53-2.45c1.15-1.09,7.72-6.79,10.39-8.57c1.2-0.8,2.36-1.96,3.38-2.98
							c1.26-1.26,2.35-2.36,3.33-2.65c0.7-0.21,1.01-0.4,1.28-0.56c0.41-0.25,0.77-0.46,2.25-0.68c2.54-0.36,10.05-1.83,11.43-2.11
							c1.74-0.35,10.37-0.86,10.74-0.88c0.21-0.01,20.58-0.53,21.78-1.05c0.51-0.22,1.03-0.32,1.48-0.41
							c0.63-0.12,1.18-0.23,1.47-0.62c0.24-0.32,0.48-0.61,0.71-0.88c0.26-0.31,0.5-0.59,0.69-0.88c0.13-0.2,0.08-0.25,0.06-0.28
							c-0.02-0.02-0.06-0.06-0.04-0.12c0.02-0.07,0.05-0.08,0.39-0.17c0.57-0.14,1.43,0.99,2.12,1.91c0.25,0.33,0.47,0.61,0.61,0.76
							c0.06,0.06,0.12,0.12,0.17,0.18c0.41,0.42,0.59,0.62,1.31,0.33c0.22-0.09,0.33-0.13,0.38-0.1c0.1-0.05,0.26-0.13,0.49-0.24
							c0.68-0.34,0.8-0.59,0.86-0.71c0.1-0.21,0.18-0.18,0.62,0c0.87,0.35,1.76,0.71,3,1.41c0.75,0.43,1.42,0.65,2.01,0.85
							c0.37,0.13,0.7,0.24,0.97,0.37c0.22,0.11,1.12,0.31,2.15,0.55c2.23,0.51,5.3,1.21,6.14,1.92c0.3,0.26,0.61,0.54,0.93,0.83
							c0.97,0.88,2.18,1.98,4.16,3.33c3.12,2.14,10.11,5.16,10.18,5.19c0.52,0.22,1.14,0.4,1.85,0.61c1.97,0.58,4.41,1.29,6.62,3.38
							c3.03,2.88,6.38,5.09,7.53,5.52c1.25,0.47,7.34,11,7.59,11.45l0.06,0.11l-0.12,0.03c-0.05,0.01-4.93,1.16-7.55,1.16
							c-0.01,0-0.02,0-0.02,0c-1.11,0-3.64,0.25-6.31,0.52c-3.79,0.38-8.1,0.81-10.04,0.71c-1.29-0.07-2.41-0.04-3.49-0.01
							c-1.69,0.05-3.29,0.09-5.14-0.28c-2.07-0.41-6.54-0.51-10.86-0.6c-1.89-0.04-3.84-0.08-5.55-0.15
							c-2.7-0.11-6.82-0.84-10.8-1.54c-4.31-0.76-8.77-1.56-11.33-1.53c-2.77,0.03-10.52,0.93-17.35,1.73
							c-5.26,0.61-9.8,1.14-10.96,1.14c-0.01,0-0.01,0-0.02,0c-1,0-3.49,0.22-6.38,0.48
							C661.6,2224.02,656.35,2224.49,653.22,2224.49z M625.43,2223.93c2.62,0.01,22.42,0.12,26.66,0.35
							c2.84,0.15,8.92-0.39,13.81-0.82c2.9-0.26,5.41-0.48,6.41-0.48c0,0,0,0,0.01,0c1.15,0,5.69-0.53,10.94-1.14
							c6.84-0.8,14.58-1.7,17.37-1.73c2.58-0.03,7.04,0.77,11.36,1.53c3.97,0.71,8.08,1.43,10.77,1.54
							c1.71,0.07,3.66,0.11,5.55,0.15c4.33,0.09,8.8,0.18,10.89,0.6c1.83,0.37,3.42,0.32,5.1,0.27c1.09-0.03,2.21-0.06,3.5,0.01
							c1.93,0.1,6.22-0.33,10.01-0.71c2.79-0.28,5.21-0.52,6.33-0.52c0.01,0,0.02,0,0.03,0c2.37,0,6.62-0.95,7.39-1.13
							c-2.2-3.81-6.5-10.91-7.44-11.26c-1.2-0.45-4.5-2.63-7.59-5.56c-2.17-2.06-4.59-2.77-6.54-3.34c-0.72-0.21-1.35-0.4-1.87-0.62
							c-0.07-0.03-7.08-3.05-10.22-5.2c-1.99-1.36-3.2-2.47-4.18-3.35c-0.32-0.29-0.63-0.57-0.93-0.83
							c-0.8-0.69-3.96-1.41-6.06-1.88c-1.13-0.26-1.95-0.44-2.19-0.56c-0.27-0.13-0.58-0.24-0.95-0.36
							c-0.57-0.19-1.28-0.43-2.04-0.87c-1.23-0.7-2.12-1.06-2.98-1.4c-0.18-0.07-0.32-0.13-0.37-0.13
							c-0.01,0.01-0.02,0.03-0.02,0.04c-0.07,0.14-0.21,0.42-0.94,0.79c-0.27,0.13-0.43,0.22-0.53,0.27
							c-0.15,0.07-0.2,0.1-0.24,0.05c-0.03,0.01-0.07,0.03-0.11,0.04c-0.83,0.33-1.1,0.05-1.51-0.37c-0.05-0.06-0.11-0.11-0.17-0.18
							c-0.15-0.15-0.37-0.44-0.63-0.78c-0.55-0.73-1.48-1.95-1.92-1.84c-0.08,0.02-0.15,0.04-0.21,0.05
							c0.05,0.09,0.05,0.22-0.09,0.44c-0.19,0.29-0.44,0.58-0.7,0.89c-0.23,0.27-0.47,0.55-0.71,0.87
							c-0.33,0.44-0.91,0.56-1.58,0.69c-0.44,0.09-0.95,0.19-1.44,0.4c-1.23,0.53-21,1.04-21.84,1.06
							c-0.09,0.01-8.98,0.53-10.71,0.88c-1.37,0.27-8.89,1.75-11.44,2.11c-1.44,0.21-1.78,0.41-2.18,0.65
							c-0.28,0.17-0.6,0.36-1.32,0.58c-0.93,0.28-2.05,1.41-3.25,2.6c-1.03,1.03-2.19,2.2-3.41,3.01
							c-2.66,1.77-9.22,7.46-10.36,8.55c-0.27,0.25-1.22,1.18-2.53,2.45C635.62,2214.31,627.1,2222.59,625.43,2223.93z"/>
					</g>
					<g>
						<g>
							<path className="st52" d="M710.78,2147.67v-7.74c-0.02,2.53-0.01,5.06-0.02,7.58C710.77,2147.58,710.77,2147.63,710.78,2147.67z"
								/>
						</g>
						<g>
							<path className="st52" d="M710.78,2139.93c0-0.37,0.12-0.74,0.19-1.1c0-0.03,0.04-0.04,0.05-0.07c0.04-0.11,0.12-0.21,0.12-0.32
								c0.01-1.25,0-2.5,0.01-3.74c0-0.19-0.07-0.29-0.21-0.4c-0.2-0.15-0.54-0.22-0.48-0.6c0-0.03-0.04-0.07-0.07-0.09
								c-0.2-0.11-0.41-0.21-0.65-0.33c-0.19,0.54-0.37,1.04-0.56,1.55c-0.12-0.04-0.24-0.08-0.36-0.12c0-0.24-0.04-0.45,0.01-0.65
								c0.1-0.4-0.22-0.68-0.24-1.04c0-0.03-0.09-0.07-0.15-0.09c-0.43-0.17-0.88-0.27-1.35-0.24c-0.46,0.03-0.92,0.01-1.4,0.01
								c0,0.49,0,0.93,0,1.38c-0.32,0-0.61,0-0.93,0c0-0.61,0-1.19,0-1.77c-0.48,0-0.93,0-1.4,0c-0.01,0.18-0.02,0.35-0.03,0.53
								c-0.12,0.01-0.22,0.02-0.34,0.03c-0.22,0.74-0.66,1.42-0.55,2.16c-0.2,0.13-0.37,0.19-0.46,0.32
								c-0.2,0.29-0.44,0.39-0.78,0.36c-0.35-0.03-0.62,0.12-0.81,0.44c-0.12,0.19-0.29,0.37-0.48,0.49
								c-0.17,0.1-0.27,0.19-0.29,0.39c-0.01,0.08-0.11,0.21-0.18,0.22c-0.38,0.02-0.76,0.01-1.17,0.01c0-0.53,0-1.03,0-1.53
								c-0.92,0-1.81,0-2.73,0c0,0.53,0,1.03,0,1.56c-0.2,0-0.37,0-0.54,0c-0.77,0-0.77,0-0.99-0.74c-0.06-0.18-0.13-0.27-0.34-0.26
								c-0.15,0.01-0.32,0-0.45-0.07c-0.35-0.21-0.73-0.26-1.13-0.24c-0.17,0.01-0.35,0-0.52,0c-0.06-0.67-0.32-0.79-1.03-0.5
								c-0.01,0.14-0.01,0.28-0.02,0.44c-0.2,0.05-0.39,0.12-0.58,0.15c-0.23,0.03-0.29,0.15-0.28,0.38c0.02,0.38,0,0.75,0,1.12
								c-0.25,0.04-0.45,0.07-0.68,0.11c0-0.14-0.01-0.26,0-0.38c0.04-0.27-0.05-0.46-0.31-0.55c-0.24-0.09-0.37-0.24-0.32-0.54
								c-0.6,0.02-1.19-0.12-1.71,0.28c-0.17,0.13-0.25,0.24-0.24,0.45c0.01,0.18-0.02,0.36-0.06,0.54
								c-0.05,0.27-0.19,0.53-0.17,0.79c0.03,0.4-0.19,0.64-0.45,0.95c0-0.26,0-0.46,0-0.69c-0.22,0.03-0.42,0.05-0.62,0.08
								c-0.36,0.06-0.74,0.53-0.74,0.9c0,1.59,0,3.18,0,4.77c0,0.64-0.01,1.27,0.21,1.89c0.07,0.2,0.09,0.42,0.09,0.63
								c0.01,2.54,0.01,5.08,0,7.61c0,0.14-0.06,0.31-0.15,0.42c-0.29,0.35-0.58,0.7-0.9,1.01c-1.28,1.25-1.8,2.82-1.95,4.54v32.18
								c9.7,0,19.65,0,29.35,0l-0.26-45.02C710.78,2144.99,710.76,2142.46,710.78,2139.93z"/>
							<path className="st23" d="M711.18,2192.7h-29.66l0-32.35c0.18-2.01,0.81-3.49,1.99-4.64c0.33-0.32,0.62-0.67,0.89-1
								c0.07-0.08,0.11-0.21,0.11-0.32c0.01-2.39,0.01-4.88,0-7.61c0-0.24-0.03-0.43-0.08-0.58c-0.22-0.6-0.22-1.23-0.22-1.83v-0.11
								c0-1.51,0-3.01,0-4.52l0-0.25c0-0.44,0.44-0.97,0.87-1.05c0.14-0.03,0.28-0.04,0.43-0.06c0.07-0.01,0.13-0.02,0.2-0.03
								l0.18-0.02v0.43c0.1-0.15,0.15-0.3,0.14-0.49c-0.01-0.2,0.04-0.38,0.1-0.56c0.03-0.09,0.05-0.18,0.07-0.27
								c0.03-0.17,0.06-0.34,0.06-0.51c-0.01-0.28,0.11-0.44,0.3-0.58c0.45-0.33,0.92-0.32,1.39-0.31c0.14,0,0.28,0.01,0.42,0
								l0.19-0.01l-0.03,0.19c-0.04,0.2,0.02,0.29,0.22,0.37c0.22,0.08,0.47,0.27,0.41,0.72c-0.01,0.05-0.01,0.11-0.01,0.18
								l0.38-0.06c0-0.07,0-0.13,0-0.2c0-0.26,0.01-0.53-0.01-0.79c-0.01-0.2,0.02-0.48,0.41-0.54c0.12-0.02,0.24-0.05,0.37-0.09
								c0.03-0.01,0.05-0.01,0.08-0.02l0.02-0.42l0.09-0.04c0.45-0.18,0.73-0.21,0.93-0.08c0.2,0.12,0.27,0.35,0.3,0.57
								c0.01,0,0.02,0,0.03,0c0.11,0,0.23,0,0.34,0c0.48-0.03,0.86,0.05,1.22,0.26c0.07,0.04,0.19,0.06,0.36,0.05
								c0.33-0.02,0.44,0.19,0.49,0.37c0.1,0.34,0.15,0.5,0.23,0.56c0.08,0.06,0.27,0.06,0.6,0.06h0.39v-1.56h3.04v1.53
								c0.05,0,0.11,0,0.16,0c0.29,0,0.56,0,0.83-0.01c0.02-0.02,0.05-0.06,0.06-0.08c0.03-0.28,0.19-0.39,0.36-0.5
								c0.17-0.1,0.32-0.26,0.43-0.44c0.23-0.38,0.55-0.55,0.96-0.51c0.29,0.03,0.48-0.06,0.64-0.29c0.08-0.11,0.2-0.18,0.32-0.25
								c0.03-0.02,0.07-0.04,0.1-0.06c-0.04-0.52,0.15-1.01,0.34-1.49c0.08-0.21,0.17-0.43,0.23-0.64l0.03-0.1l0.31-0.03l0.03-0.54
								h1.7v1.77h0.62v-1.38h0.15c0.15,0,0.3,0,0.44,0c0.33,0,0.64,0.01,0.95-0.01c0.45-0.03,0.92,0.06,1.42,0.25l0.01,0
								c0.1,0.04,0.23,0.09,0.24,0.22c0.01,0.12,0.06,0.24,0.12,0.37c0.09,0.21,0.19,0.44,0.12,0.72c-0.03,0.11-0.02,0.23-0.01,0.38
								c0,0.04,0,0.08,0.01,0.12l0.11,0.04l0.56-1.58l0.33,0.17c0.17,0.08,0.32,0.16,0.48,0.25c0.05,0.03,0.17,0.12,0.15,0.25
								c-0.03,0.17,0.04,0.22,0.25,0.34c0.06,0.03,0.12,0.07,0.17,0.11c0.14,0.11,0.28,0.25,0.28,0.53c0,0.6,0,1.21,0,1.8
								c0,0.64,0,1.3,0,1.95c0,0.11-0.05,0.21-0.09,0.3c-0.01,0.03-0.03,0.05-0.04,0.08c-0.01,0.03-0.04,0.06-0.05,0.08
								c-0.02,0.1-0.04,0.2-0.06,0.3c-0.06,0.26-0.11,0.5-0.11,0.74l-0.15,0l0.15,0c-0.01,1.43-0.01,2.89-0.01,4.3
								c0,1.08,0,2.19,0,3.28L711.18,2192.7z M681.84,2192.39h29.04l-0.26-44.87c0-1.1,0-2.21,0-3.28c0-1.41,0-2.87,0.01-4.3
								c0-0.27,0.06-0.54,0.12-0.8c0.02-0.11,0.05-0.22,0.07-0.33c0.01-0.06,0.05-0.1,0.07-0.12c0.01-0.03,0.02-0.05,0.04-0.08
								c0.03-0.06,0.06-0.12,0.06-0.16c0-0.65,0-1.31,0-1.94c0-0.59,0-1.2,0-1.8c0-0.13-0.03-0.19-0.15-0.28
								c-0.04-0.03-0.09-0.06-0.14-0.09c-0.17-0.1-0.43-0.25-0.41-0.6c-0.14-0.08-0.29-0.15-0.45-0.23l-0.02-0.01l-0.55,1.53
								l-0.61-0.2v-0.11c0-0.07,0-0.14-0.01-0.21c-0.01-0.16-0.02-0.32,0.02-0.47c0.05-0.18-0.02-0.34-0.1-0.52
								c-0.06-0.13-0.11-0.26-0.13-0.4c-0.02-0.01-0.04-0.01-0.05-0.02l-0.01,0c-0.47-0.18-0.87-0.26-1.29-0.23
								c-0.32,0.02-0.64,0.01-0.97,0.01c-0.09,0-0.19,0-0.29,0v1.38h-1.24v-1.77h-1.09l-0.03,0.51l-0.37,0.04
								c-0.06,0.19-0.14,0.38-0.21,0.57c-0.19,0.49-0.38,0.95-0.31,1.43l0.01,0.1l-0.08,0.05c-0.07,0.05-0.14,0.08-0.2,0.12
								c-0.1,0.06-0.18,0.1-0.22,0.16c-0.23,0.33-0.52,0.46-0.92,0.42c-0.29-0.03-0.5,0.09-0.66,0.36
								c-0.13,0.22-0.33,0.42-0.53,0.54c-0.15,0.09-0.2,0.14-0.21,0.27c-0.01,0.13-0.16,0.35-0.33,0.35
								c-0.29,0.02-0.57,0.01-0.87,0.01c-0.1,0-0.21,0-0.31,0h-0.15v-1.53h-2.42v1.56h-0.7c-0.84,0-0.9-0.05-1.14-0.85
								c-0.04-0.14-0.07-0.16-0.18-0.15c-0.23,0.01-0.41-0.02-0.53-0.09c-0.3-0.18-0.63-0.24-1.04-0.22c-0.13,0.01-0.25,0.01-0.37,0
								c-0.05,0-0.11,0-0.16,0h-0.14l-0.01-0.14c-0.02-0.27-0.08-0.42-0.17-0.48c-0.1-0.06-0.28-0.04-0.56,0.07l-0.02,0.45
								l-0.11,0.03c-0.06,0.02-0.12,0.03-0.18,0.05c-0.13,0.04-0.27,0.08-0.42,0.1c-0.13,0.02-0.16,0.04-0.15,0.22
								c0.01,0.27,0.01,0.54,0.01,0.8c0,0.11,0,0.22,0,0.33v0.13l-0.99,0.16v-0.18c0-0.04,0-0.08,0-0.12c0-0.09-0.01-0.19,0.01-0.28
								c0.03-0.21-0.03-0.32-0.21-0.39c-0.32-0.12-0.42-0.33-0.43-0.53c-0.09,0-0.18,0-0.26-0.01c-0.45-0.01-0.83-0.02-1.19,0.25
								c-0.14,0.11-0.18,0.18-0.18,0.32c0.01,0.2-0.03,0.39-0.06,0.58c-0.02,0.1-0.05,0.2-0.08,0.3c-0.05,0.16-0.1,0.31-0.09,0.45
								c0.03,0.45-0.21,0.73-0.46,1.03l-0.3,0.35v-0.94c-0.01,0-0.02,0-0.03,0c-0.14,0.02-0.28,0.03-0.41,0.06
								c-0.28,0.05-0.61,0.46-0.61,0.75l0,0.25c0,1.51,0,3.01,0,4.52l0,0.11c0,0.6,0,1.17,0.2,1.72c0.07,0.18,0.1,0.41,0.1,0.69
								c0.01,2.73,0,5.22,0,7.61c0,0.18-0.07,0.38-0.18,0.51c-0.28,0.34-0.58,0.7-0.92,1.03c-1.13,1.1-1.73,2.51-1.9,4.44V2192.39z
								 M699.4,2137.09L699.4,2137.09L699.4,2137.09z"/>
						</g>
						<g>
							<path className="st101" d="M732.8,2192.54c-0.36-1.2-0.72-2.41-1.07-3.62c-0.18-0.62-0.44-1.24-0.48-1.87
								c-0.12-2.15-0.16-4.3-0.24-6.45c-0.05-1.53-0.11-3.07-0.16-4.6c-0.06-1.87-0.12-3.75-0.18-5.62
								c-0.06-1.83-0.12-3.66-0.18-5.49c-0.06-1.85-0.13-3.7-0.19-5.56c-0.04-1.4-0.08-2.8-0.13-4.2c-0.01-0.24,0.02-0.53-0.1-0.71
								c-0.28-0.42-0.62-0.8-1.17-0.87c-0.14-0.02-0.27-0.09-0.41-0.13c-0.61-0.19-1.23-0.38-1.86-0.58
								c-0.28,0.88-0.67,1.69-0.52,2.63c-0.7-0.14-0.59-0.13-0.61-0.7c0-0.08,0-0.17,0-0.25c0-0.7-0.19-0.96-0.85-1.18
								c-0.59-0.19-1.19-0.38-1.79-0.57c-0.2,0.56-0.38,1.09-0.57,1.63c-0.23-0.11-0.43-0.19-0.56-0.26c0-0.39,0-0.74,0-1.09
								c-0.01-0.41-0.32-0.81-0.7-0.93c-0.36-0.11-0.72-0.23-1.09-0.34c-0.34-0.1-0.68-0.2-1.03-0.3c-0.19,0.61-0.38,1.18-0.57,1.79
								c-0.11-0.05-0.2-0.11-0.3-0.13c-0.29-0.08-0.38-0.26-0.35-0.55c0.02-0.21,0.01-0.43,0-0.65c-0.01-0.4-0.31-0.81-0.68-0.95
								c-0.45-0.16-0.89-0.31-1.34-0.47c-0.3-0.1-0.6-0.2-0.91-0.31c-0.17,0.64-0.32,1.24-0.49,1.87c-0.18-0.09-0.33-0.2-0.5-0.25
								c-0.26-0.08-0.3-0.24-0.29-0.48c0.02-0.45-0.01-0.91,0.01-1.36c0.02-0.4-0.04-0.78-0.23-1.13c-0.05-0.1-0.14-0.21-0.23-0.24
								c-0.68-0.25-1.35-0.49-2.04-0.7c-0.17-0.05-0.25-0.12-0.28-0.25v44.87C718.08,2192.54,725.44,2192.54,732.8,2192.54z"/>
							<path className="st23" d="M732.96,2192.67H710.6v-46.04l0.24,1.02c0.02,0.07,0.05,0.12,0.2,0.16c0.61,0.19,1.26,0.42,2.04,0.71
								c0.16,0.06,0.26,0.23,0.3,0.3c0.18,0.34,0.26,0.73,0.24,1.2c-0.01,0.23-0.01,0.47-0.01,0.7c0,0.22,0,0.44,0,0.66
								c-0.01,0.23,0.04,0.31,0.21,0.36c0.12,0.04,0.24,0.1,0.35,0.16c0.01,0.01,0.02,0.01,0.03,0.02l0.48-1.84l0.41,0.14
								c0.21,0.07,0.42,0.14,0.63,0.21l0.17,0.06c0.38,0.13,0.78,0.27,1.17,0.41c0.42,0.15,0.75,0.61,0.77,1.06
								c0,0.04,0,0.09,0,0.13c0.01,0.18,0.01,0.36-0.01,0.54c-0.03,0.24,0.04,0.35,0.26,0.41c0.07,0.02,0.13,0.05,0.19,0.08
								l0.56-1.77l0.12,0.03c0.11,0.03,0.21,0.06,0.32,0.09c0.24,0.07,0.48,0.14,0.71,0.21c0.21,0.06,0.43,0.13,0.64,0.2
								c0.15,0.05,0.3,0.09,0.45,0.14c0.43,0.13,0.78,0.59,0.78,1.05c0,0.24,0,0.48,0,0.73c0,0.09,0,0.18,0,0.28l0.37,0.17
								l0.57-1.62l0.66,0.21c0.42,0.13,0.83,0.26,1.24,0.4c0.71,0.23,0.93,0.54,0.94,1.29l0,0.08c0,0.06,0,0.11,0,0.17
								c0,0.08,0,0.14,0,0.2c0,0.17,0,0.23,0.03,0.25c0.04,0.03,0.16,0.07,0.31,0.1c-0.07-0.72,0.16-1.38,0.38-2.02
								c0.06-0.16,0.11-0.32,0.16-0.49l0.04-0.12l0.62,0.2c0.45,0.14,0.9,0.28,1.35,0.42c0.05,0.02,0.1,0.03,0.14,0.05
								c0.09,0.03,0.17,0.06,0.24,0.07c0.63,0.08,0.99,0.51,1.26,0.92c0.12,0.18,0.12,0.42,0.12,0.64c0,0.05,0,0.09,0,0.14
								c0.03,0.77,0.05,1.55,0.07,2.3c0.02,0.63,0.04,1.27,0.06,1.9c0.04,1.23,0.08,2.46,0.12,3.69c0.02,0.62,0.04,1.25,0.06,1.87
								l0.02,0.46c0.06,1.68,0.11,3.35,0.17,5.03c0.03,0.86,0.06,1.71,0.08,2.57c0.03,1.02,0.07,2.03,0.1,3.05
								c0.03,0.92,0.06,1.84,0.09,2.76c0.02,0.61,0.04,1.23,0.06,1.84c0.02,0.6,0.04,1.2,0.06,1.81c0.05,1.52,0.09,3.1,0.18,4.64
								c0.03,0.45,0.17,0.91,0.32,1.35c0.05,0.16,0.11,0.33,0.15,0.49c0.25,0.86,0.51,1.72,0.76,2.56c0.11,0.35,0.21,0.71,0.31,1.06
								L732.96,2192.67z M710.84,2192.42h21.79c-0.09-0.3-0.18-0.6-0.27-0.9c-0.25-0.84-0.51-1.7-0.76-2.56
								c-0.05-0.16-0.1-0.33-0.15-0.48c-0.15-0.46-0.3-0.93-0.33-1.42c-0.09-1.55-0.14-3.12-0.18-4.65c-0.02-0.6-0.04-1.2-0.06-1.81
								c-0.02-0.61-0.04-1.23-0.06-1.84c-0.03-0.92-0.06-1.84-0.09-2.76c-0.03-1.02-0.07-2.03-0.1-3.05
								c-0.03-0.86-0.06-1.71-0.08-2.57c-0.06-1.68-0.11-3.35-0.17-5.03l-0.02-0.46c-0.02-0.62-0.04-1.25-0.06-1.87
								c-0.04-1.23-0.08-2.46-0.12-3.69c-0.02-0.63-0.04-1.27-0.06-1.9c-0.02-0.75-0.04-1.53-0.07-2.3c0-0.05,0-0.09,0-0.14
								c0-0.19,0-0.39-0.08-0.51c-0.24-0.36-0.56-0.75-1.09-0.81c-0.11-0.01-0.21-0.05-0.3-0.09c-0.05-0.02-0.09-0.03-0.13-0.05
								c-0.45-0.14-0.9-0.28-1.35-0.42l-0.39-0.12c-0.04,0.13-0.09,0.25-0.13,0.38c-0.24,0.69-0.47,1.35-0.35,2.08l0.03,0.18
								l-0.18-0.04c-0.63-0.13-0.71-0.16-0.7-0.63c0-0.06,0-0.12,0-0.2c0-0.06,0-0.12,0-0.17l0-0.08c0-0.65-0.16-0.86-0.76-1.06
								c-0.41-0.13-0.82-0.26-1.24-0.39l-0.44-0.14l-0.58,1.65l-0.76-0.35v-0.08c0-0.12,0-0.24,0-0.36c0-0.25,0-0.49,0-0.73
								c-0.01-0.35-0.28-0.71-0.61-0.81c-0.15-0.05-0.3-0.09-0.45-0.14c-0.21-0.07-0.43-0.13-0.64-0.2
								c-0.23-0.07-0.47-0.14-0.71-0.21c-0.07-0.02-0.14-0.04-0.2-0.06l-0.57,1.81l-0.13-0.06c-0.04-0.02-0.08-0.04-0.11-0.05
								c-0.06-0.03-0.12-0.06-0.17-0.07c-0.33-0.09-0.48-0.32-0.44-0.68c0.02-0.16,0.01-0.32,0.01-0.5c0-0.04,0-0.09,0-0.13
								c-0.01-0.35-0.27-0.72-0.6-0.83c-0.39-0.14-0.79-0.28-1.17-0.41l-0.17-0.06c-0.21-0.07-0.41-0.14-0.62-0.21l-0.16-0.05
								l-0.49,1.89l-0.14-0.07c-0.06-0.03-0.12-0.06-0.17-0.09c-0.11-0.06-0.2-0.11-0.3-0.14c-0.35-0.1-0.39-0.37-0.38-0.61
								c0.01-0.22,0.01-0.44,0-0.65c0-0.23,0-0.47,0.01-0.71c0.02-0.42-0.05-0.76-0.21-1.07c-0.05-0.09-0.12-0.16-0.17-0.18
								c-0.78-0.29-1.43-0.51-2.03-0.7c-0.04-0.01-0.08-0.03-0.12-0.05V2192.42z"/>
						</g>
						<g>
							<path className="st102" d="M681.61,2161.27c-1.1,0-2.15,0-3.2,0c-0.08,0-0.19-0.04-0.25-0.09c-0.78-0.68-1.71-0.98-2.72-1.13
								c-0.44-0.07-0.81,0.02-1.19,0.18c-0.38,0.16-0.8,0.3-0.93,0.78c-0.02,0.05-0.07,0.1-0.11,0.14
								c-0.42,0.41-0.86,0.79-1.27,1.22c-0.27,0.28-0.5,0.42-0.88,0.16c-0.21-0.15-0.71-0.06-0.79,0.07
								c-0.26,0.41-0.53,0.82-0.43,1.34c0.01,0.05-0.01,0.11-0.02,0.2c-0.2-0.1-0.35-0.2-0.53-0.26c-0.24-0.08-0.53-0.22-0.75-0.16
								c-0.65,0.16-1.15,0.53-1.35,1.23c-0.14,0.51-0.46,0.6-0.88,0.27c-0.24-0.19-0.45-0.22-0.74-0.1
								c-0.42,0.16-0.85,0.29-1.29,0.38c-0.22,0.05-0.29,0.12-0.3,0.33c-0.01,0.7-0.02,1.4-0.1,2.09c-0.1,0.82-0.28,1.62-0.73,2.34
								c-0.05,0.08-0.08,0.19-0.09,0.29c-0.05,0.86-0.09,1.73-0.13,2.59c-0.05,1.19-0.1,2.39-0.16,3.58
								c-0.06,1.27-0.12,2.55-0.18,3.82c-0.05,1.17-0.1,2.34-0.16,3.51c-0.07,1.45-0.14,2.9-0.21,4.35
								c-0.05,1.06-0.1,2.12-0.15,3.18c-0.01,0.3,0,0.61,0,0.96h21.44l0.99-37.65C684.5,2155.2,681.68,2156.32,681.61,2161.27z"/>
							<path className="st23" d="M683.66,2192.67h-21.69v-0.12c0-0.12,0-0.23,0-0.33c0-0.22,0-0.43,0-0.64
								c0.04-0.99,0.09-1.99,0.14-2.96l0.01-0.22c0.03-0.59,0.06-1.17,0.09-1.76c0.04-0.86,0.09-1.73,0.13-2.59
								c0.03-0.64,0.06-1.28,0.09-1.91c0.02-0.53,0.05-1.07,0.07-1.6c0.03-0.61,0.06-1.22,0.09-1.83c0.03-0.66,0.06-1.33,0.09-1.99
								c0.03-0.6,0.05-1.2,0.08-1.8c0.03-0.59,0.05-1.19,0.08-1.78c0.04-0.89,0.08-1.76,0.13-2.59c0.01-0.12,0.05-0.25,0.1-0.35
								c0.37-0.6,0.6-1.33,0.71-2.29c0.07-0.64,0.09-1.29,0.1-1.93l0-0.16c0.01-0.27,0.11-0.39,0.39-0.45
								c0.44-0.09,0.87-0.22,1.27-0.38c0.33-0.13,0.59-0.09,0.87,0.12c0.12,0.1,0.29,0.2,0.43,0.15c0.13-0.04,0.21-0.22,0.25-0.36
								c0.19-0.68,0.67-1.13,1.44-1.32c0.21-0.05,0.45,0.03,0.66,0.11c0.05,0.02,0.11,0.04,0.16,0.05c0.13,0.04,0.24,0.11,0.37,0.17
								c0.01,0,0.01,0.01,0.02,0.01c-0.1-0.54,0.16-0.96,0.41-1.36l0.03-0.05c0.06-0.1,0.22-0.15,0.33-0.18
								c0.19-0.04,0.46-0.04,0.63,0.07c0.3,0.21,0.47,0.12,0.72-0.15c0.28-0.29,0.57-0.57,0.86-0.84c0.14-0.13,0.27-0.25,0.41-0.38
								l0.01-0.01c0.02-0.02,0.07-0.06,0.07-0.08c0.14-0.5,0.55-0.67,0.91-0.82l0.08-0.04c0.46-0.2,0.84-0.26,1.26-0.19
								c1.21,0.18,2.07,0.54,2.78,1.16c0.04,0.03,0.12,0.06,0.17,0.06c0.83,0,1.66,0,2.53,0l0.55,0c0.09-3.96,1.96-5.48,2.67-6.06
								c0.11-0.09,0.23-0.19,0.25-0.22c0,0,0,0.01,0,0.02l0.25,0.01L683.66,2192.67z M662.22,2192.42h21.2l0.98-37.2
								c-0.02,0.02-0.05,0.04-0.08,0.06c-0.69,0.56-2.53,2.06-2.58,5.99l0,0.12l-0.8,0c-0.87,0-1.69,0-2.53,0
								c-0.11,0-0.25-0.05-0.33-0.12c-0.67-0.59-1.49-0.93-2.65-1.1c-0.37-0.06-0.7,0-1.12,0.18l-0.09,0.04
								c-0.34,0.14-0.66,0.28-0.77,0.66c-0.02,0.08-0.09,0.15-0.14,0.19l-0.01,0.01c-0.14,0.13-0.27,0.26-0.41,0.39
								c-0.29,0.27-0.58,0.54-0.85,0.83c-0.27,0.28-0.58,0.5-1.04,0.18c-0.16-0.11-0.56-0.03-0.62,0.03l-0.03,0.05
								c-0.24,0.38-0.47,0.75-0.38,1.2c0.01,0.06,0,0.11-0.01,0.16c0,0.02-0.01,0.05-0.01,0.08l-0.02,0.18l-0.16-0.08
								c-0.06-0.03-0.12-0.07-0.18-0.1c-0.12-0.06-0.22-0.12-0.33-0.15c-0.05-0.02-0.11-0.04-0.16-0.06
								c-0.19-0.07-0.38-0.14-0.51-0.1c-0.68,0.17-1.09,0.55-1.26,1.15c-0.08,0.29-0.22,0.47-0.41,0.53c-0.2,0.06-0.42,0-0.66-0.19
								c-0.2-0.16-0.37-0.18-0.62-0.09c-0.42,0.16-0.86,0.29-1.31,0.39c-0.18,0.04-0.19,0.07-0.2,0.21l0,0.16
								c-0.01,0.64-0.03,1.3-0.1,1.95c-0.12,1-0.35,1.75-0.75,2.39c-0.04,0.06-0.06,0.15-0.07,0.23c-0.05,0.83-0.09,1.7-0.13,2.59
								c-0.03,0.59-0.05,1.18-0.08,1.78c-0.03,0.6-0.05,1.2-0.08,1.8c-0.03,0.66-0.06,1.33-0.09,1.99
								c-0.03,0.61-0.06,1.22-0.09,1.83c-0.02,0.53-0.05,1.07-0.07,1.6c-0.03,0.64-0.06,1.28-0.09,1.92
								c-0.04,0.86-0.08,1.73-0.13,2.59c-0.03,0.59-0.06,1.17-0.09,1.76l-0.01,0.22c-0.05,0.97-0.1,1.97-0.14,2.96
								c-0.01,0.2-0.01,0.41,0,0.62C662.22,2192.27,662.22,2192.34,662.22,2192.42z"/>
						</g>
					</g>
					<g>
						<g>
							<path className="st23" d="M732.98,2192.73H662v-0.09c0-0.12,0-0.23,0-0.33c0-0.22,0-0.43,0-0.63c0.04-0.99,0.09-1.99,0.14-2.96
								l0.01-0.22c0.03-0.59,0.06-1.18,0.09-1.76c0.04-0.86,0.09-1.72,0.13-2.58c0.03-0.64,0.06-1.27,0.09-1.91
								c0.02-0.54,0.05-1.07,0.07-1.61c0.03-0.61,0.06-1.23,0.09-1.84c0.03-0.66,0.06-1.32,0.09-1.98c0.03-0.6,0.05-1.2,0.08-1.8
								c0.03-0.59,0.05-1.18,0.08-1.78c0.04-0.88,0.08-1.76,0.13-2.59c0.01-0.12,0.04-0.24,0.1-0.33c0.38-0.61,0.6-1.34,0.71-2.3
								c0.07-0.64,0.09-1.29,0.1-1.93l0-0.16c0-0.24,0.09-0.35,0.31-0.41c0.41-0.11,0.91-0.24,1.38-0.41
								c0.3-0.1,0.54-0.06,0.79,0.13c0.13,0.1,0.31,0.21,0.46,0.16c0.15-0.05,0.23-0.23,0.27-0.38c0.19-0.67,0.66-1.11,1.41-1.3
								c0.2-0.05,0.43,0.03,0.64,0.11c0.05,0.02,0.11,0.04,0.16,0.05c0.13,0.04,0.24,0.1,0.36,0.17c0.02,0.01,0.04,0.02,0.06,0.03
								c0-0.02,0-0.03,0-0.04c-0.1-0.54,0.16-0.96,0.41-1.36l0.03-0.05c0.06-0.1,0.22-0.15,0.31-0.16c0.18-0.04,0.45-0.04,0.61,0.07
								c0.32,0.22,0.5,0.12,0.76-0.15c0.28-0.29,0.57-0.57,0.86-0.84c0.14-0.13,0.27-0.26,0.41-0.38l0.01-0.01
								c0.03-0.03,0.07-0.07,0.08-0.09c0.13-0.48,0.54-0.65,0.89-0.8l0.09-0.04c0.45-0.19,0.83-0.25,1.24-0.19
								c1.21,0.18,2.06,0.54,2.76,1.16c0.04,0.04,0.12,0.07,0.19,0.07c0.83,0,1.66,0,2.53,0l0.59,0c0-0.07,0.01-0.14,0.01-0.2
								c0.01-0.2,0.03-0.41,0.05-0.62c0.17-2,0.8-3.46,1.97-4.6c0.33-0.32,0.62-0.67,0.9-1.01c0.07-0.09,0.12-0.24,0.13-0.36
								c0.01-2.39,0.01-4.88,0-7.61c0-0.25-0.03-0.45-0.09-0.6c-0.22-0.59-0.22-1.21-0.22-1.81v-0.11c0-1.52,0-3.05,0-4.57l0-0.19
								c0-0.41,0.42-0.92,0.81-0.99c0.14-0.03,0.28-0.04,0.42-0.06c0.07-0.01,0.13-0.02,0.2-0.03l0.1-0.01v0.55
								c0.17-0.21,0.28-0.4,0.26-0.68c-0.01-0.18,0.04-0.37,0.1-0.54c0.03-0.09,0.06-0.18,0.07-0.27c0.03-0.17,0.06-0.35,0.06-0.52
								c-0.01-0.26,0.1-0.39,0.28-0.53c0.43-0.32,0.89-0.31,1.35-0.3c0.14,0,0.28,0.01,0.42,0l0.12,0l-0.02,0.11
								c-0.04,0.23,0.03,0.35,0.26,0.44c0.29,0.11,0.42,0.33,0.37,0.66c-0.01,0.07-0.01,0.15-0.01,0.24c0,0.01,0,0.02,0,0.02
								l0.5-0.08c0-0.08,0-0.17,0-0.25c0-0.26,0.01-0.53-0.01-0.79c-0.01-0.19,0.02-0.42,0.36-0.47c0.13-0.02,0.25-0.05,0.38-0.09
								c0.04-0.01,0.08-0.02,0.12-0.03l0.02-0.43l0.06-0.02c0.43-0.17,0.69-0.2,0.87-0.09c0.15,0.09,0.24,0.27,0.28,0.58
								c0.03,0,0.05,0,0.08,0c0.11,0,0.23,0,0.35,0c0.46-0.03,0.84,0.05,1.18,0.25c0.08,0.05,0.21,0.07,0.39,0.06
								c0.29-0.02,0.38,0.15,0.43,0.33c0.11,0.36,0.16,0.52,0.26,0.59c0.1,0.07,0.29,0.07,0.64,0.07h0.46v-1.56h2.91v1.53
								c0.07,0,0.15,0,0.22,0c0.29,0,0.57,0,0.85-0.01c0.02-0.01,0.09-0.09,0.09-0.13c0.03-0.25,0.16-0.36,0.33-0.46
								c0.18-0.11,0.34-0.28,0.45-0.46c0.22-0.35,0.52-0.52,0.9-0.48c0.32,0.03,0.52-0.06,0.7-0.32c0.07-0.1,0.18-0.16,0.3-0.23
								c0.04-0.02,0.09-0.05,0.13-0.08c-0.05-0.52,0.14-1.02,0.33-1.5c0.08-0.21,0.17-0.43,0.23-0.65l0.02-0.06l0.32-0.03l0.03-0.54
								h1.58v1.77h0.75v-1.38h0.09c0.15,0,0.3,0,0.44,0c0.33,0,0.64,0.01,0.95-0.01c0.45-0.03,0.89,0.05,1.39,0.25l0.01,0
								c0.09,0.03,0.19,0.07,0.2,0.16c0.01,0.13,0.06,0.26,0.12,0.39c0.09,0.2,0.18,0.42,0.12,0.68c-0.03,0.12-0.02,0.25-0.01,0.4
								c0,0.05,0.01,0.11,0.01,0.16l0.21,0.07l0.56-1.57l0.27,0.14c0.16,0.08,0.32,0.16,0.47,0.24c0.04,0.02,0.13,0.09,0.12,0.19
								c-0.04,0.21,0.08,0.29,0.28,0.4c0.06,0.03,0.12,0.07,0.17,0.11c0.15,0.11,0.25,0.24,0.25,0.48c0,0.6,0,1.21,0,1.8
								c0,0.64,0,1.3,0,1.94c0,0.1-0.04,0.19-0.08,0.27c-0.01,0.03-0.03,0.06-0.04,0.09c-0.01,0.03-0.04,0.05-0.05,0.07c0,0,0,0,0,0
								c-0.02,0.11-0.04,0.21-0.07,0.32c-0.06,0.25-0.11,0.5-0.11,0.75c-0.01,1.43-0.01,2.89-0.01,4.3c0,1.08,0,2.19,0,3.28
								c0,0.2,0.04,0.26,0.23,0.32c0.61,0.19,1.26,0.42,2.04,0.71c0.15,0.06,0.26,0.23,0.28,0.28c0.18,0.34,0.26,0.72,0.24,1.18
								c-0.01,0.23-0.01,0.47-0.01,0.7c0,0.22,0,0.44,0,0.66c-0.01,0.25,0.04,0.34,0.23,0.39c0.12,0.04,0.23,0.1,0.35,0.16
								c0.02,0.01,0.04,0.02,0.06,0.03l0.48-1.85l0.38,0.13c0.21,0.07,0.42,0.14,0.63,0.21l0.18,0.06c0.38,0.13,0.78,0.27,1.16,0.4
								c0.41,0.15,0.73,0.59,0.74,1.03c0,0.04,0,0.09,0,0.13c0.01,0.17,0.01,0.35-0.01,0.53c-0.03,0.26,0.05,0.38,0.28,0.45
								c0.07,0.02,0.14,0.05,0.21,0.09c0,0,0,0,0.01,0l0.56-1.77l0.09,0.03c0.11,0.03,0.21,0.06,0.32,0.09
								c0.24,0.07,0.48,0.14,0.71,0.21c0.21,0.06,0.43,0.13,0.64,0.2c0.15,0.05,0.3,0.09,0.45,0.14c0.42,0.13,0.75,0.58,0.76,1.02
								c0,0.24,0,0.48,0,0.73c0,0.1,0,0.2,0,0.3l0.42,0.19l0.57-1.62l0.64,0.2c0.41,0.13,0.82,0.26,1.23,0.39
								c0.69,0.22,0.91,0.53,0.91,1.26l0,0.08c0,0.06,0,0.11,0,0.17c0,0.08,0,0.14,0,0.2c0,0.18,0,0.24,0.04,0.27
								c0.05,0.04,0.17,0.07,0.36,0.11c-0.08-0.73,0.14-1.37,0.38-2.05c0.06-0.16,0.11-0.32,0.17-0.49l0.03-0.09l0.57,0.18
								c0.46,0.14,0.92,0.29,1.37,0.43c0.05,0.02,0.1,0.03,0.14,0.05c0.09,0.03,0.17,0.06,0.25,0.07c0.61,0.08,0.97,0.5,1.24,0.91
								c0.11,0.17,0.11,0.41,0.12,0.62c0,0.05,0,0.09,0,0.14c0.03,0.77,0.05,1.55,0.07,2.3c0.02,0.63,0.04,1.26,0.06,1.9
								c0.04,1.23,0.08,2.46,0.12,3.69c0.02,0.62,0.04,1.25,0.06,1.87l0.02,0.46c0.06,1.68,0.11,3.35,0.17,5.03
								c0.03,0.86,0.06,1.72,0.08,2.58c0.03,1.01,0.07,2.02,0.1,3.04c0.03,0.91,0.06,1.82,0.09,2.73c0.02,0.62,0.04,1.24,0.06,1.86
								c0.02,0.6,0.04,1.21,0.06,1.81c0.05,1.52,0.09,3.1,0.18,4.64c0.03,0.45,0.18,0.92,0.32,1.36c0.05,0.16,0.11,0.33,0.15,0.49
								c0.25,0.86,0.51,1.72,0.76,2.56c0.1,0.35,0.21,0.7,0.31,1.05L732.98,2192.73z M662.19,2192.54h70.55
								c-0.09-0.31-0.18-0.62-0.28-0.93c-0.25-0.84-0.51-1.71-0.76-2.57c-0.05-0.16-0.1-0.33-0.15-0.49
								c-0.15-0.46-0.3-0.93-0.33-1.41c-0.09-1.55-0.14-3.12-0.18-4.65c-0.02-0.6-0.04-1.21-0.06-1.81
								c-0.02-0.62-0.04-1.24-0.06-1.86c-0.03-0.91-0.06-1.82-0.09-2.73c-0.03-1.01-0.07-2.02-0.1-3.04
								c-0.03-0.86-0.06-1.72-0.08-2.58c-0.06-1.68-0.11-3.35-0.17-5.03l-0.02-0.46c-0.02-0.62-0.04-1.25-0.06-1.87
								c-0.04-1.23-0.08-2.46-0.12-3.69c-0.02-0.63-0.04-1.27-0.06-1.9c-0.02-0.75-0.04-1.53-0.07-2.3c0-0.05,0-0.09,0-0.14
								c0-0.2,0-0.4-0.09-0.52c-0.25-0.37-0.57-0.76-1.11-0.83c-0.1-0.01-0.2-0.05-0.29-0.09c-0.05-0.02-0.09-0.03-0.13-0.05
								c-0.46-0.14-0.91-0.29-1.37-0.43l-0.4-0.12c-0.04,0.14-0.09,0.27-0.14,0.41c-0.24,0.7-0.47,1.36-0.35,2.09l0.02,0.13
								l-0.13-0.03c-0.62-0.12-0.68-0.15-0.68-0.6c0-0.06,0-0.12,0-0.2c0-0.06,0-0.12,0-0.17l0-0.08c0-0.66-0.17-0.89-0.78-1.09
								c-0.41-0.13-0.82-0.26-1.23-0.39l-0.47-0.15l-0.58,1.64l-0.71-0.32v-0.06c0-0.12,0-0.24,0-0.36c0-0.25,0-0.49,0-0.73
								c-0.01-0.36-0.29-0.74-0.63-0.84c-0.15-0.05-0.3-0.09-0.45-0.14c-0.21-0.07-0.42-0.13-0.64-0.2
								c-0.24-0.07-0.47-0.14-0.71-0.21c-0.08-0.02-0.15-0.04-0.23-0.07l-0.57,1.8l-0.1-0.04c-0.04-0.02-0.07-0.04-0.11-0.05
								c-0.06-0.03-0.12-0.06-0.17-0.08c-0.32-0.09-0.45-0.3-0.41-0.65c0.02-0.16,0.01-0.34,0.01-0.5c0-0.04,0-0.09,0-0.13
								c-0.01-0.36-0.28-0.74-0.62-0.86c-0.39-0.14-0.78-0.27-1.16-0.4l-0.18-0.06c-0.21-0.07-0.41-0.14-0.62-0.21l-0.19-0.06
								l-0.49,1.88l-0.1-0.05c-0.06-0.03-0.12-0.06-0.17-0.09c-0.11-0.06-0.21-0.12-0.31-0.15c-0.33-0.1-0.37-0.34-0.36-0.58
								c0.01-0.22,0.01-0.44,0-0.65c0-0.23,0-0.47,0.01-0.71c0.02-0.42-0.05-0.78-0.22-1.09c-0.05-0.1-0.13-0.18-0.18-0.2
								c-0.78-0.29-1.43-0.51-2.04-0.7c-0.26-0.08-0.36-0.22-0.36-0.5c0-1.09,0-2.2,0-3.28c0-1.41,0-2.87,0.01-4.3
								c0-0.27,0.06-0.53,0.12-0.79c0.02-0.11,0.05-0.22,0.07-0.33c0.01-0.04,0.04-0.07,0.06-0.09c0,0,0,0,0.01-0.01
								c0.01-0.03,0.03-0.06,0.04-0.09c0.03-0.07,0.06-0.13,0.07-0.19c0-0.65,0-1.31,0-1.94c0-0.59,0-1.2,0-1.8
								c0-0.14-0.04-0.22-0.18-0.33c-0.04-0.03-0.09-0.06-0.15-0.09c-0.18-0.1-0.42-0.24-0.37-0.57c-0.01-0.01-0.01-0.01-0.02-0.02
								c-0.15-0.08-0.31-0.16-0.47-0.24l-0.08-0.04l-0.55,1.54l-0.51-0.17v-0.07c0-0.07,0-0.15-0.01-0.22
								c-0.01-0.16-0.02-0.31,0.02-0.45c0.05-0.2-0.03-0.38-0.11-0.56c-0.06-0.13-0.12-0.27-0.13-0.42
								c-0.02-0.01-0.06-0.03-0.08-0.03l-0.01,0c-0.47-0.19-0.89-0.26-1.31-0.24c-0.32,0.02-0.63,0.01-0.97,0.01
								c-0.11,0-0.23,0-0.35,0v1.38h-1.12v-1.77h-1.21l-0.03,0.52l-0.36,0.04c-0.06,0.21-0.14,0.41-0.22,0.6
								c-0.2,0.5-0.38,0.97-0.31,1.46l0.01,0.06l-0.05,0.03c-0.07,0.04-0.13,0.08-0.19,0.12c-0.11,0.06-0.2,0.11-0.24,0.18
								c-0.22,0.31-0.48,0.43-0.87,0.4c-0.31-0.03-0.54,0.1-0.72,0.39c-0.13,0.21-0.31,0.4-0.51,0.52c-0.16,0.1-0.23,0.16-0.24,0.32
								c-0.01,0.12-0.15,0.29-0.27,0.3c-0.28,0.02-0.56,0.01-0.86,0.01c-0.1,0-0.21,0-0.31,0h-0.09v-1.53h-2.54v1.56h-0.64
								c-0.81,0-0.85-0.03-1.08-0.8c-0.05-0.16-0.1-0.2-0.24-0.19c-0.22,0.01-0.38-0.01-0.5-0.08c-0.31-0.18-0.65-0.25-1.07-0.22
								c-0.12,0.01-0.25,0.01-0.36,0c-0.05,0-0.11,0-0.16,0h-0.08l-0.01-0.08c-0.03-0.29-0.09-0.46-0.2-0.52
								c-0.12-0.07-0.33-0.05-0.65,0.08l-0.02,0.44l-0.07,0.02c-0.06,0.02-0.12,0.03-0.18,0.05c-0.14,0.04-0.27,0.08-0.41,0.1
								c-0.17,0.02-0.21,0.09-0.2,0.28c0.01,0.27,0.01,0.54,0.01,0.8c0,0.11,0,0.22,0,0.33v0.08l-0.87,0.14v-0.11
								c0-0.04,0-0.09,0-0.13c0-0.09-0.01-0.18,0.01-0.27c0.04-0.24-0.04-0.38-0.25-0.45c-0.27-0.1-0.4-0.28-0.39-0.53
								c-0.11,0-0.22,0-0.32,0c-0.44-0.01-0.85-0.02-1.23,0.26c-0.16,0.12-0.21,0.21-0.2,0.37c0.01,0.19-0.03,0.38-0.06,0.56
								c-0.02,0.1-0.05,0.2-0.08,0.29c-0.05,0.17-0.1,0.33-0.09,0.47c0.03,0.43-0.19,0.68-0.44,0.98l-0.19,0.23v-0.84
								c-0.03,0-0.07,0.01-0.1,0.01c-0.14,0.02-0.28,0.03-0.41,0.06c-0.31,0.06-0.66,0.49-0.66,0.81l0,0.19c0,1.52,0,3.05,0,4.57
								l0,0.11c0,0.61,0,1.18,0.21,1.74c0.07,0.18,0.1,0.39,0.1,0.67c0.01,2.73,0,5.22,0,7.61c0,0.16-0.07,0.35-0.17,0.47
								c-0.28,0.34-0.58,0.69-0.91,1.02c-1.14,1.11-1.75,2.53-1.92,4.48c-0.02,0.2-0.03,0.41-0.05,0.62
								c-0.01,0.1-0.01,0.19-0.02,0.29l-0.01,0.09l-0.76,0c-0.87,0-1.69,0-2.53,0c-0.11,0-0.23-0.05-0.31-0.11
								c-0.68-0.59-1.5-0.94-2.67-1.11c-0.37-0.06-0.72,0-1.14,0.18l-0.09,0.04c-0.35,0.15-0.68,0.29-0.79,0.68
								c-0.02,0.08-0.08,0.13-0.13,0.18l-0.01,0.01c-0.14,0.13-0.27,0.26-0.41,0.39c-0.29,0.27-0.58,0.54-0.85,0.83
								c-0.24,0.25-0.53,0.49-1,0.18c-0.09-0.06-0.28-0.08-0.47-0.04c-0.12,0.03-0.18,0.07-0.19,0.08l-0.03,0.05
								c-0.24,0.39-0.47,0.76-0.38,1.23c0.01,0.05,0,0.1-0.01,0.15c0,0.02-0.01,0.05-0.01,0.08l-0.02,0.13l-0.12-0.06
								c-0.06-0.03-0.12-0.07-0.18-0.1c-0.12-0.07-0.22-0.12-0.33-0.16c-0.05-0.02-0.11-0.04-0.16-0.06
								c-0.19-0.07-0.39-0.14-0.53-0.1c-0.69,0.18-1.11,0.56-1.28,1.17c-0.08,0.28-0.21,0.45-0.39,0.51c-0.19,0.06-0.4,0-0.64-0.19
								c-0.2-0.16-0.37-0.19-0.62-0.11c-0.48,0.17-0.99,0.3-1.4,0.41c-0.14,0.04-0.17,0.08-0.17,0.23l0,0.16
								c-0.01,0.64-0.03,1.3-0.1,1.95c-0.12,0.99-0.35,1.75-0.74,2.38c-0.04,0.07-0.07,0.16-0.07,0.25
								c-0.05,0.83-0.09,1.71-0.13,2.59c-0.03,0.59-0.05,1.18-0.08,1.78c-0.03,0.6-0.05,1.2-0.08,1.8
								c-0.03,0.66-0.06,1.32-0.09,1.99c-0.03,0.61-0.06,1.23-0.09,1.84c-0.02,0.54-0.05,1.07-0.07,1.61
								c-0.03,0.64-0.06,1.27-0.09,1.91c-0.04,0.86-0.08,1.72-0.13,2.58c-0.03,0.59-0.06,1.18-0.09,1.76l-0.01,0.22
								c-0.05,0.97-0.1,1.97-0.14,2.96c-0.01,0.2-0.01,0.41,0,0.62C662.19,2192.38,662.19,2192.46,662.19,2192.54z"/>
						</g>
					</g>
					<g>
						<polygon className="st103" points="686.61,2181.12 693.87,2181.12 693.87,2191.92 703.32,2191.92 703.32,2183.48 709.81,2183.82 
							715.47,2222.29 684.85,2222.4 						"/>
						<path className="st23" d="M684.73,2222.52l1.76-41.52h7.5v10.8h9.2v-8.44l6.73,0.35l5.69,38.71l-0.14,0L684.73,2222.52z
							 M686.73,2181.24l-1.74,41.03l30.34-0.11l-5.62-38.23l-6.26-0.33v8.43h-9.7v-10.8H686.73z"/>
					</g>
					<g>
						<path className="st53" d="M662.09,2192.82c0-0.96,4.51-1.02,6.09-1.59c1.57-0.57,2.67-0.89,4.18-0.73s3.52-0.64,3.52-0.64
							s-0.66,1.5,0.54,1.47c1.2-0.02,0.13,0.64,1.2-0.02c1.07-0.66,0.78-2.25,1.82-1.45c1.04,0.79-2.28-0.39,1.04,0.79
							c3.64,1.3,5.65,1.66,5.65,1.66l-0.11,2.6"/>
					</g>
					<g>
						<path className="st53" d="M711.03,2192.54l-0.31-3.07c0,0,1.95,1,2.44,1.58s0.66,0.08,1.32,0c0.66-0.08,0.66-0.08,0.66-0.08
							s0-0.66,0.66-0.82c0.66-0.16-0.16,0.33,0.66-0.16c0.82-0.49,0.82-0.49,0.82-0.49s-1.5-1.56-0.09-2.27s2.56-0.86,2.56-0.86
							l1.06,3.11c1.75,1.01,0.59,1.01,1.75,1.01c1.15,0,1.15,0,1.15,0l0.87-1.32c0,0,0.7,1.81,1.48,1.89s-0.21-0.24,0.78,0.09
							c0.99,0.33,2.47-0.01,2.97-0.09c0.49-0.08-0.66-0.08,0.49-0.08c1.15,0,1.41,0.34,1.98,0.49c0.57,0.15,1.6,1.07,1.6,1.07"/>
					</g>
					<g>
						<path className="st24" d="M714.32,2192.63c0.14-0.06,4.78-1.4,4.78-1.4s2.38,0.97,3.15,0.73c1.77-0.55,1.79-0.5,1.79-0.5
							s-1.24-1.97,0-1.49s1.81,2.22,2.8,2.39c0.99,0.17,2.47-0.24,3.13-0.4c0.66-0.17-0.99,0.16,0.66-0.17s1.08,0.74,2.16,0.74
							c1.08,0,2.65,1.41,3.44,2.01c0.79,0.59,1.33,1.13,3.03,2.07c1.7,0.94,1.58,2.26,2.24,2.43s1.71-1.65,2.75,0.16
							c1.04,1.81,4.34,3.13,6.97,4.12c2.64,0.99,2.8,0.99,4.45,2.47c1.65,1.48,3.79,4.45,4.45,5.44c0.66,0.99,0.16,2.8,1.32,4.28
							c1.15,1.48,1.7,2.31,1.7,2.31s3.41,1.32-1.37,0c-4.78-1.32-11.54-8.08-12.2-8.4c-0.66-0.33-0.99-0.49-2.97-0.99
							c-1.98-0.49-4.05-4.28-4.05-4.28s1.58,0.49-1.06-1.32c-2.64-1.81-1.32-1.32-5.11-3.63c-3.79-2.31-1.48-0.99-3.79-2.31
							C730.59,2195.75,710.03,2194.43,714.32,2192.63z"/>
						<path className="st23" d="M764.26,2218.51c-0.23,0-0.84-0.13-2.51-0.59c-3.71-1.02-8.59-5.29-10.93-7.34
							c-0.66-0.58-1.14-1-1.28-1.07c-0.65-0.33-0.98-0.49-2.95-0.98c-1.99-0.5-4.02-4.17-4.11-4.32l-0.11-0.2l0.22,0.07
							c0.13,0.04,0.28,0.06,0.34,0.05c-0.03-0.08-0.23-0.35-1.48-1.21c-1.08-0.74-1.5-1.1-1.87-1.42c-0.55-0.47-0.99-0.84-3.23-2.21
							c-1.9-1.15-2.27-1.41-2.45-1.53c-0.18-0.12-0.18-0.12-1.33-0.78c-0.75-0.43-4.21-0.89-7.88-1.39
							c-6.59-0.89-10.92-1.54-11.04-2.43c-0.03-0.22,0.18-0.43,0.64-0.62l0,0c0.14-0.06,4.31-1.26,4.79-1.4l0.03-0.01l0.03,0.01
							c0.02,0.01,2.36,0.95,3.09,0.73c1.01-0.31,1.46-0.43,1.67-0.48c-0.21-0.35-0.66-1.19-0.42-1.48c0.11-0.13,0.31-0.14,0.61-0.02
							c0.65,0.25,1.12,0.83,1.54,1.34c0.41,0.5,0.79,0.97,1.25,1.05c0.89,0.16,2.2-0.17,2.92-0.36c0.01-0.03,0.03-0.04,0.08-0.06
							l0,0c0,0,0,0,0.01,0c0.03-0.01,0.08-0.02,0.14-0.03c0.12-0.02,0.31-0.06,0.6-0.12c1-0.2,1.25,0.11,1.47,0.38
							c0.16,0.19,0.3,0.36,0.72,0.36c0.94,0,2.21,1.01,3.06,1.68c0.16,0.13,0.31,0.25,0.44,0.34c0.17,0.13,0.32,0.25,0.48,0.38
							c0.59,0.47,1.21,0.95,2.54,1.69c1.03,0.57,1.4,1.27,1.68,1.79c0.18,0.33,0.31,0.57,0.54,0.63c0.18,0.04,0.42-0.1,0.68-0.26
							c0.37-0.22,0.78-0.47,1.23-0.36c0.33,0.09,0.62,0.36,0.9,0.83c1,1.74,4.1,3.02,6.93,4.08c0.17,0.07,0.34,0.13,0.49,0.18
							c2.16,0.81,2.43,0.91,3.99,2.31c1.69,1.52,3.84,4.52,4.46,5.46c0.29,0.44,0.37,1.04,0.45,1.67c0.1,0.84,0.22,1.78,0.87,2.61
							c1.03,1.33,1.59,2.14,1.69,2.29c1.26,0.49,1.23,0.59,1.2,0.67C764.4,2218.47,764.39,2218.51,764.26,2218.51z M742.76,2204.3
							c0.45,0.76,2.22,3.63,3.88,4.05c1.99,0.5,2.32,0.66,2.99,1c0.16,0.08,0.6,0.46,1.32,1.09c2.33,2.04,7.19,6.29,10.86,7.3
							c1.26,0.35,1.88,0.49,2.19,0.55c-0.23-0.11-0.57-0.25-0.88-0.37l-0.03-0.01l-0.02-0.02c-0.01-0.01-0.56-0.84-1.7-2.3
							c-0.68-0.87-0.8-1.85-0.91-2.7c-0.08-0.63-0.15-1.18-0.42-1.59c-0.62-0.93-2.76-3.91-4.43-5.42
							c-1.53-1.37-1.75-1.46-3.93-2.27c-0.15-0.06-0.32-0.12-0.49-0.18c-2.86-1.07-5.99-2.36-7.02-4.16
							c-0.25-0.43-0.5-0.67-0.78-0.74c-0.37-0.1-0.75,0.13-1.09,0.34c-0.29,0.18-0.57,0.35-0.82,0.28
							c-0.31-0.08-0.46-0.36-0.65-0.72c-0.26-0.49-0.63-1.17-1.61-1.71c-1.35-0.75-1.97-1.24-2.56-1.71
							c-0.16-0.13-0.31-0.25-0.48-0.37c-0.13-0.1-0.28-0.22-0.44-0.35c-0.82-0.65-2.07-1.64-2.94-1.64c-0.51,0-0.69-0.23-0.86-0.43
							c-0.21-0.25-0.4-0.49-1.29-0.31c-0.18,0.04-0.33,0.07-0.44,0.09c-0.03,0.03-0.1,0.05-0.22,0.08l-0.13,0.03
							c-0.73,0.19-2.09,0.54-3.04,0.37c-0.53-0.09-0.93-0.59-1.36-1.11c-0.42-0.52-0.86-1.05-1.46-1.28
							c-0.2-0.08-0.35-0.09-0.4-0.03c-0.14,0.17,0.21,0.91,0.49,1.36l0.01,0.01l0.01,0.04l0.19,0.64l-0.32-0.51
							c-0.11,0.02-0.48,0.11-1.71,0.49c-0.76,0.23-2.89-0.6-3.19-0.72c-1.3,0.38-4.61,1.33-4.74,1.39l0,0
							c-0.36,0.15-0.54,0.3-0.53,0.43c0.03,0.24,0.67,0.64,3.53,1.17c2.08,0.39,4.76,0.75,7.36,1.1c3.85,0.52,7.17,0.96,7.95,1.41
							c1.16,0.66,1.16,0.66,1.34,0.79c0.18,0.12,0.55,0.37,2.45,1.52c2.26,1.37,2.7,1.75,3.26,2.22c0.37,0.31,0.79,0.67,1.86,1.4
							c1.56,1.07,1.62,1.31,1.55,1.45C743.06,2204.32,742.91,2204.33,742.76,2204.3z M723.96,2191.52
							C723.96,2191.52,723.96,2191.52,723.96,2191.52L723.96,2191.52C723.96,2191.52,723.96,2191.52,723.96,2191.52z"/>
					</g>
					<g>
						<path className="st24" d="M706.79,2189.9c0.64-1.88,6.84,0.4,7.44,2.29c0.04,0.14,0.07,0.29,0.09,0.44
							c0.16,1.96,1.64,5.43,1.15,6.08s5.04-0.33,4.34,1.98c-0.71,2.31-0.71,2.62-1.2,3.7c-0.49,1.08-1.98,2.23-1.81,2.89
							c0.16,0.66-1.74,0.99-1.28,2.47c0.46,1.48-0.04,2.8,0.79,4.12c0.82,1.32,0.66,2.47,1.81,2.8c1.15,0.33,1.78,0.49,2.7,0.82
							c0.92,0.33,0.68-0.66,2.45,0.99s2.52,3.68,2.81,3.9c0.28,0.22-0.32,0.97-0.32,0.97l-9.38-0.62
							c-2.24-0.22-7.16-2.28-8.88-16.48c-0.2-1.69-0.33-5.05-0.05-6.73L706.79,2189.9z"/>
						<path className="st23" d="M725.78,2223.46l-0.05,0l-9.38-0.62c-1.21-0.12-3-0.74-4.7-3.03c-2.08-2.79-3.51-7.34-4.26-13.53
							c-0.2-1.67-0.34-5.03-0.05-6.74l-0.64-9.65l0.01-0.02c0.17-0.5,0.71-0.76,1.55-0.76c0.02,0,0.03,0,0.05,0
							c1,0.01,2.38,0.39,3.61,1c1.31,0.64,2.19,1.39,2.4,2.06c0.05,0.15,0.08,0.31,0.09,0.46c0.07,0.84,0.38,1.94,0.68,3.01
							c0.41,1.47,0.76,2.73,0.46,3.14c-0.01,0.01-0.01,0.01-0.01,0.02c0.09,0.08,0.66,0.11,1.12,0.13c1.11,0.06,2.64,0.13,3.14,0.85
							c0.18,0.26,0.21,0.58,0.1,0.95c-0.23,0.76-0.39,1.29-0.51,1.73c-0.25,0.86-0.36,1.25-0.7,1.99c-0.22,0.47-0.61,0.95-0.98,1.4
							c-0.47,0.56-0.91,1.1-0.82,1.43c0.08,0.32-0.2,0.55-0.53,0.81c-0.47,0.38-1.01,0.82-0.75,1.65c0.19,0.63,0.22,1.23,0.24,1.81
							c0.03,0.79,0.07,1.54,0.53,2.29c0.3,0.49,0.48,0.95,0.63,1.36c0.26,0.7,0.44,1.21,1.13,1.4c0.19,0.05,0.36,0.1,0.52,0.15
							c0.85,0.24,1.41,0.4,2.19,0.68c0.29,0.1,0.44,0.07,0.61,0.03c0.36-0.08,0.66-0.15,1.87,0.98c1.25,1.16,1.98,2.49,2.42,3.28
							c0.16,0.3,0.31,0.55,0.38,0.61c0.06,0.05,0.1,0.12,0.11,0.2c0.04,0.33-0.37,0.84-0.41,0.9L725.78,2223.46z M706.89,2189.92
							l0.64,9.63l0,0.01c-0.29,1.69-0.15,5.03,0.05,6.7c0.75,6.15,2.17,10.67,4.23,13.44c1.67,2.24,3.41,2.85,4.57,2.96l9.33,0.61
							c0.15-0.2,0.36-0.55,0.34-0.72c-0.01-0.05-0.02-0.07-0.04-0.08c-0.1-0.08-0.22-0.3-0.43-0.67c-0.43-0.79-1.16-2.1-2.39-3.24
							c-1.14-1.06-1.4-1-1.71-0.93c-0.17,0.04-0.37,0.08-0.71-0.04c-0.77-0.27-1.33-0.43-2.17-0.67c-0.16-0.05-0.34-0.1-0.52-0.15
							c-0.78-0.22-0.99-0.79-1.26-1.52c-0.15-0.4-0.31-0.85-0.61-1.32c-0.49-0.79-0.53-1.56-0.56-2.38
							c-0.02-0.57-0.05-1.15-0.24-1.76c-0.29-0.96,0.34-1.47,0.81-1.85c0.32-0.26,0.52-0.43,0.47-0.62
							c-0.11-0.42,0.34-0.96,0.86-1.59c0.37-0.45,0.75-0.91,0.96-1.36c0.33-0.72,0.43-1.08,0.69-1.96c0.12-0.43,0.28-0.97,0.51-1.73
							c0.1-0.32,0.07-0.58-0.07-0.79c-0.45-0.64-1.92-0.71-3-0.77c-0.83-0.04-1.2-0.07-1.28-0.25c-0.02-0.04-0.03-0.11,0.03-0.2
							c0.25-0.34-0.12-1.68-0.49-2.98c-0.3-1.07-0.61-2.19-0.68-3.04c-0.01-0.14-0.04-0.28-0.08-0.42c-0.19-0.61-1.05-1.34-2.3-1.95
							c-1.21-0.59-2.56-0.97-3.53-0.98C707.76,2189.29,707.08,2189.39,706.89,2189.92z"/>
					</g>
					<g>
						<path className="st23" d="M687.19,2143.59l-0.22-0.21c2.18-2.27,7.33-2.57,10.4-2.75c0.18-0.01,0.35-0.02,0.52-0.03
							c3.66-0.22,7.28,0.56,11.37,2.47l-0.13,0.28c-4.05-1.88-7.61-2.66-11.22-2.44c-0.17,0.01-0.34,0.02-0.52,0.03
							C694.36,2141.11,689.29,2141.41,687.19,2143.59z"/>
					</g>
					<g>
						<path className="st23" d="M710.85,2139.1c-1.66-1.42-4.03-1.8-6.2-1.01l-0.11-0.29c2.27-0.84,4.76-0.43,6.51,1.06L710.85,2139.1z"
							/>
					</g>
					<g>
						<rect x="704.16" y="2142.38" className="st23" width="0.19" height="40.52"/>
					</g>
					<g>
						<rect x="691.86" y="2142.38" className="st23" width="0.19" height="37.22"/>
					</g>
					<g>
						<rect x="675.21" y="2160.99" className="st23" width="0.19" height="28.88"/>
					</g>
					<g>
						<polygon className="st104" points="693.83,2181.1 706.79,2193.61 703.63,2222.33 687.44,2222.33 						"/>
					</g>
					<g>
						<g>
							<rect x="693.63" y="2181.1" className="st56" width="0.2" height="41.29"/>
						</g>
					</g>
					<g>
						<polygon className="st105" points="694.7,2191.94 702.61,2191.88 702.61,2222.31 694.7,2222.33 						"/>
					</g>
					<g>
						<rect x="694.2" y="2194.16" className="st23" width="8.78" height="0.56"/>
					</g>
					<g>
						<rect x="694.2" y="2197.4" className="st23" width="8.78" height="0.56"/>
					</g>
					<g>
						<rect x="694.61" y="2205.03" className="st23" width="8.78" height="0.56"/>
					</g>
					<g>
						<rect x="694.41" y="2210.97" className="st23" width="8.78" height="0.56"/>
					</g>
					<g>
						<rect x="694.03" y="2208.28" className="st23" width="8.78" height="0.56"/>
					</g>
					<g>
						<rect x="694.03" y="2216.9" className="st23" width="8.78" height="0.56"/>
					</g>
					<g>
						<rect x="693.74" y="2201.22" className="st23" width="8.78" height="0.56"/>
					</g>
					<g>
						<rect x="693.74" y="2213.93" className="st23" width="8.78" height="0.56"/>
					</g>
					<g>
						<g>
							<path className="st27" d="M696.55,2191.97c0-0.88,0-1.75,0-2.61c0-1.11,0-2.23,0-3.34c0-1.57,0.9-2.54,2.16-3.27
								c0.05-0.03,0.16-0.02,0.21,0.01c0.99,0.56,1.79,1.3,2.05,2.46c0.09,0.4,0.06,0.84,0.07,1.26c0.01,1.77,0,3.55,0,5.32
								c0,0.05-0.01,0.1-0.01,0.18C699.54,2191.97,698.06,2191.97,696.55,2191.97z"/>
						</g>
						<g>
							<path className="st27" d="M700.08,2174.74c-0.86,0-1.69,0-2.56,0c-0.01-0.09-0.02-0.18-0.02-0.27c0-1.96,0-3.92,0-5.88
								c0-0.95,0.84-1.58,1.72-1.3c0.47,0.15,0.88,0.7,0.88,1.23c0.01,2.02,0,4.04,0,6.06
								C700.1,2174.63,700.09,2174.67,700.08,2174.74z"/>
						</g>
						<g>
							<path className="st27" d="M670.64,2187.6c0-1.07,0-2.09,0-3.12c0-0.69,0.26-1.27,0.73-1.79c0.55,0.33,0.75,0.84,0.76,1.43
								c0.01,0.93,0,1.85,0.01,2.78c0,0.16-0.03,0.27-0.21,0.32C671.51,2187.33,671.1,2187.46,670.64,2187.6z"/>
						</g>
						<g>
							<path className="st27" d="M720.81,2161.32c0.37,0.14,0.69,0.25,1.01,0.37c0,1.23,0,2.46,0,3.72c-0.31-0.08-0.61-0.15-0.89-0.25
								c-0.05-0.02-0.11-0.12-0.11-0.18C720.81,2163.77,720.81,2162.57,720.81,2161.32z"/>
						</g>
						<g>
							<path className="st27" d="M722.25,2161.74c0.03-0.01,0.04-0.01,0.05-0.01c0.96,0.29,0.96,0.29,0.96,1.29c0,0.91,0,1.83,0,2.78
								c-0.35-0.1-0.67-0.19-1.01-0.28C722.25,2164.26,722.25,2163.01,722.25,2161.74z"/>
						</g>
						<g>
							<path className="st27" d="M697.65,2144.67c0.32,0,0.62,0,0.95,0c0,1.26,0,2.51,0,3.77c-0.31,0-0.62,0-0.95,0
								C697.65,2147.21,697.65,2145.96,697.65,2144.67z"/>
						</g>
						<g>
							<path className="st27" d="M699.97,2148.45c-0.32,0-0.64,0-0.96,0c0-1.25,0-2.5,0-3.77c0.32,0,0.64,0,0.96,0
								C699.97,2145.94,699.97,2147.19,699.97,2148.45z"/>
						</g>
						<g>
							<path className="st27" d="M688.31,2151.23c0-1.14,0-2.22,0.01-3.3c0-0.07,0.06-0.18,0.12-0.21c0.21-0.11,0.44-0.2,0.71-0.33
								c0,0.15,0,0.26,0,0.37c0,0.94-0.01,1.87,0,2.81c0,0.22-0.05,0.34-0.27,0.4C688.71,2151.03,688.54,2151.12,688.31,2151.23z"/>
						</g>
						<g>
							<path className="st27" d="M699.19,2162.72c-0.25,0-0.51,0-0.77,0c0-1.04,0-2.06,0-3.1c0.26,0,0.5,0,0.77,0
								C699.19,2160.66,699.19,2161.68,699.19,2162.72z"/>
						</g>
						<g>
							<path className="st27" d="M725.44,2171.23c0,1.49,0,2.96,0,4.46c-0.11,0-0.2,0.01-0.32,0.01c0-1.5,0-2.98,0-4.48
								C725.23,2171.23,725.32,2171.23,725.44,2171.23z"/>
						</g>
						<g>
							<path className="st27" d="M688.6,2159.93c0.12,0,0.21,0,0.32,0c0,1.48,0,2.95,0,4.43c-0.11,0-0.21,0-0.32,0
								C688.6,2162.88,688.6,2161.42,688.6,2159.93z"/>
						</g>
						<g>
							<path className="st27" d="M717.67,2173.96c-0.13,0-0.23,0-0.35,0c0-1.48,0-2.95,0-4.45c0.11,0,0.22,0,0.35,0
								C717.67,2171,717.67,2172.47,717.67,2173.96z"/>
						</g>
					</g>
					<g>
						<g>
							<rect x="703.44" y="2183.52" className="st56" width="0.2" height="38.81"/>
						</g>
					</g>
					<g>
						<path className="st24" d="M625.95,2223.37c0,0,9.43-6.85,13.55-8.99c4.12-2.14,7.9-1.48,9.72-4.78c1.82-3.3,4.43-11.54,4.52-6.1
							c0.09,5.44-1.04,7.09-1.04,7.09s-2.92,4.78-2.13,4.45c0.79-0.33-1.69,1.48-1.68,2.47s-1.05,1.81,1.37,0.99s1.92-1.32,3.9-1.32
							c1.98,0,2.94-0.99,3.78,0.16c0.84,1.15-0.34,2.97,3.46,0.66s1.98-1.22,7.09-5.47c5.11-4.25,8.43-9.19,8.43-9.19
							s-3.48,6.1-4.14,7.91s-2.62,5.11-2.63,5.77c-0.01,0.66-0.58,0.33-0.79,1.15s0.04,0.99,0,1.98c-0.04,0.99-0.96,1.47,0,2.25
							h7.95l-12.99,1.29c-11.85,1.58-19.24,0.63-20.51,0.71S625.95,2223.37,625.95,2223.37z"/>
						<path className="st23" d="M650.9,2224.7c-2.48,0-4.37-0.1-5.57-0.16c-0.75-0.04-1.25-0.07-1.5-0.05
							c-1.26,0.08-17.21-0.98-17.89-1.03l-0.26-0.02l0.21-0.15c0.09-0.07,9.48-6.88,13.56-9c1.56-0.81,3.09-1.23,4.44-1.6
							c2.21-0.6,4.13-1.12,5.24-3.15c0.51-0.93,1.09-2.26,1.65-3.56c1.22-2.8,1.99-4.51,2.56-4.39c0.31,0.06,0.46,0.65,0.49,1.9
							c0.09,5.41-1.01,7.07-1.06,7.14c-1.15,1.88-2.29,3.98-2.25,4.32c0,0,0.01,0,0.01,0c0.08-0.03,0.21-0.09,0.27,0
							c0.07,0.1-0.03,0.2-0.44,0.61c-0.52,0.52-1.39,1.4-1.39,1.94c0,0.28-0.08,0.55-0.14,0.77c-0.06,0.19-0.11,0.38-0.06,0.43
							c0.15,0.14,1.13-0.19,1.45-0.3c0.99-0.34,1.47-0.61,1.86-0.83c0.56-0.32,0.87-0.49,2.07-0.49c0.71,0,1.29-0.13,1.79-0.24
							c0.87-0.2,1.5-0.34,2.07,0.44c0.24,0.33,0.32,0.69,0.39,1.01c0.08,0.34,0.13,0.6,0.34,0.69c0.35,0.15,1.15-0.18,2.61-1.07
							c2.52-1.53,2.55-1.55,3.81-2.66c0.62-0.55,1.56-1.38,3.28-2.8c5.05-4.19,8.38-9.12,8.41-9.17l0.16,0.1
							c-0.03,0.06-3.48,6.11-4.13,7.9c-0.31,0.86-0.92,2.07-1.46,3.13c-0.59,1.17-1.15,2.28-1.16,2.6
							c-0.01,0.38-0.18,0.48-0.36,0.59c-0.17,0.1-0.34,0.21-0.44,0.59c-0.12,0.46-0.08,0.7-0.04,1.02c0.03,0.24,0.07,0.51,0.05,0.94
							c-0.02,0.37-0.15,0.66-0.26,0.93c-0.2,0.47-0.34,0.78,0.2,1.23h7.92l0.01,0.19l-12.99,1.29
							C658.86,2224.51,654.31,2224.7,650.9,2224.7z M644,2224.3c0.29,0,0.73,0.02,1.34,0.06c2.85,0.15,9.54,0.51,18.98-0.76
							l11.13-1.11h-6.11l-0.03-0.02c-0.68-0.55-0.48-1-0.28-1.47c0.11-0.26,0.23-0.53,0.24-0.86c0.02-0.41-0.02-0.68-0.05-0.91
							c-0.04-0.32-0.08-0.59,0.05-1.09c0.11-0.46,0.34-0.59,0.52-0.7c0.16-0.1,0.26-0.16,0.27-0.43c0.01-0.37,0.55-1.44,1.18-2.69
							c0.54-1.06,1.15-2.26,1.46-3.11c0.46-1.26,2.29-4.61,3.36-6.53c-1.34,1.76-4.01,5.01-7.5,7.92c-1.71,1.42-2.65,2.25-3.27,2.8
							c-1.25,1.1-1.3,1.14-3.83,2.68c-1.56,0.94-2.36,1.26-2.78,1.08c-0.29-0.12-0.36-0.45-0.45-0.82c-0.07-0.3-0.15-0.65-0.36-0.94
							c-0.5-0.68-1.02-0.57-1.88-0.37c-0.49,0.11-1.09,0.25-1.83,0.25c-1.14,0-1.44,0.17-1.98,0.47c-0.38,0.21-0.89,0.5-1.9,0.84
							c-1.18,0.4-1.49,0.4-1.64,0.26c-0.14-0.13-0.07-0.35,0.01-0.62c0.06-0.21,0.14-0.46,0.14-0.71c0-0.62,0.87-1.5,1.44-2.08
							c0.1-0.1,0.2-0.2,0.28-0.29c-0.07,0-0.11-0.03-0.13-0.05c-0.08-0.09-0.22-0.25,1.01-2.45c0.59-1.06,1.22-2.09,1.23-2.1
							c0.01-0.01,0.29-0.43,0.55-1.5c0.25-0.99,0.53-2.75,0.48-5.54c-0.03-1.49-0.24-1.7-0.34-1.72c-0.1-0.02-0.37,0.11-0.95,1.23
							c-0.42,0.81-0.9,1.9-1.4,3.05c-0.56,1.3-1.15,2.64-1.66,3.57c-1.15,2.09-3.1,2.62-5.36,3.24c-1.34,0.37-2.86,0.78-4.4,1.58
							c-3.73,1.94-11.92,7.81-13.33,8.83c2.11,0.14,16.44,1.08,17.61,1.01C643.87,2224.3,643.93,2224.3,644,2224.3z"/>
					</g>
				</g>
				<g>
					<path className="st10" d="M704.02,2123.83l1.6-0.02c0.98,0.11,1.81,0.71,1.81,0.32l0.1-1.68c0-0.39-0.88-0.94-1.27-0.94h-1.06
						c-0.39,0-1.29,0.4-1.29,0.79l-0.03,0.58C703.88,2123.28,703.63,2123.83,704.02,2123.83z"/>
				</g>
				<path className="st10" d="M707.13,2132.67c0-1.08,0-2.17,0-3.25s0-2.17,0-3.25c0-0.61,0-1.22,0-1.83c0-0.1,0.09-0.2,0.2-0.2
					c0.11,0,0.2,0.09,0.2,0.2c0,1.08,0,2.17,0,3.25s0,2.17,0,3.25c0,0.61,0,1.22,0,1.83"/>
				<g>
					<g>
						<path className="st42" d="M707.05,2124.31c-0.24-0.28-0.61-0.4-0.97-0.43c-0.43-0.04-0.86,0.05-1.29,0.07
							c-0.26,0.02-0.53,0.01-0.79-0.06c-0.08-0.02-0.05-0.15,0.04-0.13c0.47,0.12,0.97,0.03,1.44-0.01
							c0.38-0.03,0.79-0.03,1.15,0.11c0.19,0.08,0.37,0.19,0.51,0.35C707.2,2124.28,707.11,2124.37,707.05,2124.31L707.05,2124.31z"
							/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M703.87,2123.81c0-0.64,0-1.27,0-1.91c0-0.08,0.13-0.09,0.13,0c0,0.64,0,1.27,0,1.91
							C704,2123.9,703.87,2123.9,703.87,2123.81L703.87,2123.81z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M707.37,2124.13c0-0.74,0-1.49,0-2.23c0-0.08,0.13-0.09,0.13,0c0,0.74,0,1.49,0,2.23
							C707.5,2124.22,707.37,2124.22,707.37,2124.13L707.37,2124.13z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M704.21,2121.83c0.76-0.47,1.75-0.55,2.59-0.23c0.24,0.09,0.47,0.21,0.67,0.36
							c0.07,0.05,0,0.16-0.07,0.11c-0.7-0.51-1.63-0.66-2.46-0.42c-0.24,0.07-0.46,0.17-0.67,0.3
							C704.2,2121.99,704.13,2121.87,704.21,2121.83L704.21,2121.83z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path className="st42" d="M704.21,2121.83c0.76-0.47,1.75-0.55,2.59-0.23c0.24,0.09,0.47,0.21,0.67,0.36
								c0.07,0.05,0,0.16-0.07,0.11c-0.7-0.51-1.63-0.66-2.46-0.42c-0.24,0.07-0.46,0.17-0.67,0.3
								C704.2,2121.99,704.13,2121.87,704.21,2121.83L704.21,2121.83z"/>
						</g>
					</g>
				</g>
				<g>
					<path className="st106" d="M704.75,2123.73c0.09-0.03,0.17-0.05,0.26-0.07c0.05-0.01,0.1-0.02,0.15-0.03
						c0.03,0,0.05-0.01,0.08-0.01c-0.02,0-0.03,0-0.05,0.01c0.08-0.02,0.18-0.02,0.27-0.02c0.1,0,0.19,0,0.29,0
						c0.04,0,0.09,0.01,0.13,0.01c0.06,0.01,0.05,0.01-0.01,0c0.02,0,0.04,0.01,0.06,0.01c0.09,0.01,0.18,0.03,0.26,0.05
						c0.09,0.02,0.17,0.05,0.26,0.08c0.04,0.01,0.08,0.03,0.13,0.05c0.05,0.02,0.05,0.02-0.01,0c0.02,0.01,0.05,0.02,0.07,0.03
						c0.23,0.11,0.53,0.07,0.66-0.17c0.11-0.21,0.07-0.55-0.17-0.66c-0.81-0.38-1.74-0.47-2.61-0.2c-0.24,0.07-0.42,0.34-0.34,0.59
						C704.23,2123.64,704.49,2123.81,704.75,2123.73L704.75,2123.73z"/>
				</g>
				<g>
					<path className="st106" d="M704.04,2123.08c-0.1,0.18-0.12,0.36-0.07,0.56c0.05,0.19,0.26,0.37,0.46,0.35
						c0.44-0.03,0.88-0.07,1.32-0.1c0.21-0.02,0.43-0.03,0.64-0.05c0.1-0.01,0.19-0.02,0.29-0.01c-0.05-0.01-0.05-0.01,0.01,0
						c-0.01-0.01-0.03-0.01-0.04-0.02c-0.07-0.1-0.11-0.11-0.08-0.27c-0.05,0.25,0.07,0.53,0.34,0.59c0.24,0.05,0.54-0.07,0.59-0.34
						c0.07-0.4-0.13-0.74-0.51-0.89c-0.27-0.1-0.6-0.04-0.88-0.02c-0.56,0.04-1.12,0.09-1.68,0.13c0.15,0.12,0.31,0.24,0.46,0.35
						c0.01,0.07,0,0.13-0.02,0.19c0.13-0.22,0.06-0.54-0.17-0.66C704.47,2122.79,704.18,2122.85,704.04,2123.08L704.04,2123.08z"/>
				</g>
				<g>
					<g>
						<path className="st42" d="M703.87,2123.81c0-0.64,0-1.27,0-1.91c0-0.08,0.13-0.09,0.13,0c0,0.64,0,1.27,0,1.91
							C704,2123.9,703.87,2123.9,703.87,2123.81L703.87,2123.81z"/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M707.05,2124.31c-0.24-0.28-0.61-0.4-0.97-0.43c-0.43-0.04-0.86,0.05-1.29,0.07
							c-0.26,0.02-0.53,0.01-0.79-0.06c-0.08-0.02-0.05-0.15,0.04-0.13c0.47,0.12,0.97,0.03,1.44-0.01
							c0.38-0.03,0.79-0.03,1.15,0.11c0.19,0.08,0.37,0.19,0.51,0.35C707.2,2124.28,707.11,2124.37,707.05,2124.31L707.05,2124.31z"
							/>
					</g>
				</g>
				<g>
					<g>
						<path className="st42" d="M707.37,2124.13c0-0.74,0-1.49,0-2.23c0-0.08,0.13-0.09,0.13,0c0,0.74,0,1.49,0,2.23
							C707.5,2124.22,707.37,2124.22,707.37,2124.13L707.37,2124.13z"/>
					</g>
				</g>
				<g>
					<path className="st49" d="M704.64,2122.64c-0.17,0.34,0.22,0.67,0.55,0.59c0.4-0.09,0.47-0.61,0.39-0.94
						c-0.08,0.02-0.15,0.04-0.23,0.06c0.05,0.16-0.04,0.33-0.19,0.4c-0.09,0.04-0.07,0.16,0,0.21c0.3,0.2,0.69-0.18,0.54-0.49
						c-0.06-0.12-0.26-0.04-0.22,0.09c0.04,0.11,0.07,0.23,0.11,0.34c0.03,0.08,0.14,0.12,0.2,0.05c0.16-0.18,0.47-0.12,0.68-0.16
						c0.23-0.05,0.45-0.22,0.39-0.48c-0.05-0.2-0.29-0.37-0.47-0.21c-0.09,0.08-0.11,0.2-0.11,0.31c0,0.05,0.01,0.11,0.01,0.17
						c0,0.02-0.01,0.04-0.02,0.06c-0.01,0.02-0.03,0.04-0.02,0.04c-0.08-0.01-0.18,0.05-0.15,0.15c0.06,0.17,0.12,0.33,0.18,0.5
						c0.07-0.04,0.13-0.08,0.2-0.12c-0.14-0.17-0.28-0.34-0.42-0.5c-0.1-0.12-0.27,0.05-0.17,0.17c0.14,0.17,0.28,0.34,0.42,0.5
						c0.08,0.09,0.24-0.01,0.2-0.12c-0.06-0.17-0.12-0.33-0.18-0.5c-0.05,0.05-0.1,0.1-0.15,0.15c0.22,0.04,0.34-0.16,0.34-0.36
						c0-0.06-0.04-0.21,0.01-0.27c0.04-0.04,0.08,0.04,0.09,0.08c0.04,0.19-0.31,0.2-0.42,0.21c-0.21,0.01-0.44,0.03-0.59,0.2
						c0.07,0.02,0.13,0.04,0.2,0.05c-0.04-0.11-0.07-0.23-0.11-0.34c-0.07,0.03-0.15,0.06-0.22,0.09c0.05,0.11-0.12,0.22-0.21,0.16
						c0,0.07,0,0.14,0,0.21c0.25-0.12,0.37-0.41,0.3-0.67c-0.04-0.15-0.27-0.09-0.23,0.06c0.05,0.2,0.04,0.51-0.17,0.63
						c-0.12,0.06-0.42-0.05-0.33-0.22C704.91,2122.62,704.71,2122.5,704.64,2122.64L704.64,2122.64z"/>
				</g>
				<g>
					<path className="st49" d="M706.55,2122.17c-0.28,0.28-0.57,0.57-0.85,0.85c-0.11,0.11,0.06,0.28,0.17,0.17
						c0.28-0.28,0.57-0.57,0.85-0.85C706.83,2122.23,706.66,2122.06,706.55,2122.17L706.55,2122.17z"/>
				</g>
			</g>
			<path d="M775.13,1832.58c-0.01-2.45,0-4.9,0-7.35c0-0.09,0-0.18,0-0.27l0.09-0.04l-0.04-0.11c0.01-0.01,0.02-0.01,0.04-0.02
				c-0.03-0.07-0.06-0.14-0.08-0.21c0-0.15,0-0.29,0-0.44c0.15-0.29,0.06-0.63,0.07-0.94c0.01-3.72,0.03-7.44-0.12-11.16
				c-0.01,0.01-0.02,0.02-0.03,0.03l-0.01-0.34l-0.24,0.25c-2.52,2.62-6.17,3.83-9.39,4.9l-11.39,3.78
				c-2.74,0.91-5.57,1.85-8.28,2.98l-0.26,0.11c-4.24,1.76-10.65,4.42-13.84,7.85c-0.05,0.05-0.09,0.14-0.14,0.25
				c-0.05,0.11-0.2,0.41-0.28,0.41c0,0,0,0,0,0c0,0-0.01,0-0.03-0.01c-0.07-0.08-0.15-0.21-0.24-0.41
				c-0.01-0.03-0.04-0.04-0.07-0.04c-0.03-0.05-0.07-0.08-0.12-0.09c-0.11-0.03-0.24,0.03-0.43,0.18c-0.1,0.08-0.2,0.15-0.29,0.23
				l-1.49,0.9c-1.17-0.08-2.19-0.1-2.81-0.11c-0.26-0.01-0.48-0.01-0.51-0.02c-2.4-0.38-4.82-1.03-7.41-1.97
				c-4.39-1.59-8.92-2.39-12.77-2.95c-0.85-0.13-1.72-0.25-2.56-0.37c-3.07-0.43-6.24-0.88-9.3-1.58c-2-0.45-4.06-0.58-6.04-0.71
				c-1.91-0.12-3.89-0.25-5.8-0.66c0,0,0,0,0,0c-0.97-0.44-1.9-0.98-2.81-1.5c-1.04-0.6-2.12-1.22-3.27-1.7
				c-0.36-0.15-0.72-0.3-1.08-0.46c-2.2-0.93-4.47-1.89-6.83-2.51c-0.38-0.1-0.77-0.18-1.14-0.26c-0.81-0.18-1.65-0.36-2.48-0.72
				l-0.16-0.07c-2.18-0.97-4.43-1.98-6.89-2.35c-1.26-0.19-2.52-0.51-3.74-0.82c-0.94-0.24-1.91-0.48-2.88-0.67
				c-2.33-0.45-4.34,0.32-6.34,2.42c-0.74,0.78-1.75,1.75-3.05,2.39c-1.18,0.57-2.42,1.01-3.63,1.44c-1.03,0.37-2.1,0.74-3.13,1.2
				c-0.55,0.25-1.09,0.55-1.61,0.85c-1.38,0.78-2.68,1.52-4.34,1.38c-0.16-0.01-0.31,0.06-0.45,0.13c-0.09,0.05-0.18,0.09-0.26,0.1
				c-0.42,0.06-0.82,0.22-1.21,0.37c-0.78,0.3-1.53,0.59-2.37,0.18c-0.34-0.16-0.63,0.1-0.81,0.27c-0.81,0.74-1.79,1.28-2.74,1.8
				c-0.23,0.13-0.47,0.26-0.7,0.39c-0.19,0.11-0.39,0.16-0.61,0.22c-0.31,0.09-0.62,0.17-0.94,0.41c-0.26,0.19-0.53,0.4-0.79,0.59
				c-1.04,0.79-2.12,1.61-3.37,2.13c-0.26,0.11-0.54,0.18-0.81,0.26c-0.5,0.14-1.02,0.28-1.43,0.61c-1.14,0.9-2.43,1.58-4.06,2.14
				c-0.15,0.05-0.32,0.09-0.49,0.12c-0.44,0.09-0.94,0.19-1.27,0.63c-0.06,0.08-0.12,0.15-0.2,0.24l-0.01,0.02
				c-3.14,4.74-6.85,10.01-11.04,15.67l-0.76,0.99l0.02,0c-0.16,0.22-0.32,0.44-0.49,0.65c-1.16,1.56-2.37,3.15-3.59,4.75l-0.6,0.78
				l-2.24,2.99l-0.06,0.02l0,0.11l-0.74,0.83l-0.08,6.9c0,0.42,0,0.84-0.01,1.26l0,1.15c0,0.15,0.11,0.27,0.25,0.3
				c3.41,0.61,6.85,1.19,10.22,1.73c0,0,0.01,0,0.01,0v0c6.08,0.97,12.24,1.85,18.29,2.62c0.03,0.01,0.05,0.02,0.09,0.04h0
				c0.03,0.01,0.07,0.02,0.1,0.03c-0.08,0.13-0.16,0.25-0.23,0.38c0.07-0.13,0.15-0.26,0.23-0.38c0.1,0.03,0.19,0.05,0.23,0.05
				c8.85,1.18,18.68,2.09,28.43,2.63c0.73,0.06,1.48,0.11,2.23,0.17c4.38,0.34,8.9,0.68,12.7,0.86c4.97,0.22,9.98,0.38,14.91,0.45
				l0.28,0c0.65,0.01,1.31-0.01,2.09-0.05c0.67-0.04,1.38-0.01,2.26,0.08c1.9,0.19,3.81,0.27,5.73,0.27c2.69,0,5.38-0.14,8.01-0.28
				c1.56-0.08,3.16-0.17,4.74-0.22c5.76-0.2,11.1-0.47,16.33-0.83c1.31-0.09,2.63-0.22,3.92-0.34c0.33-0.03,0.66-0.06,0.98-0.09
				c0.92-0.09,1.85-0.13,2.75-0.18c0.68-0.03,1.39-0.07,2.08-0.12c0.63-0.05,1.27-0.12,1.89-0.2c0.44-0.05,0.89-0.11,1.34-0.15
				c2.72-0.26,5.48-0.55,8.2-0.86c0.3-0.03,0.6-0.07,0.9-0.1c2.27-0.26,4.56-0.55,6.82-0.84c1.12-0.15,2.27-0.3,3.4-0.46
				c0.97-0.13,3.38-0.48,3.41-0.48l5.64-1.13l0.16-0.01c7.1-0.45,14.08-1.85,20.75-4.17c0.06-0.02,0.1-0.04,0.12-0.19
				c0,0,0,0,0.01,0c0.1-0.04,0.01-1.79,0.01-1.99c0-0.69,0-1.38,0.01-2.07c0-1.38,0.01-2.76,0.01-4.14
				c0.01-2.76,0.02-5.52,0.02-8.27c0.02-5.52,0.03-11.03,0.05-16.55c0-0.72-0.04-1.44-0.04-2.16
				C775.17,1833.29,775.13,1832.58,775.13,1832.58z M720.64,1876.88c-0.15-0.02-0.29-0.01-0.44,0.02c-0.04,0-0.07,0-0.11,0.01
				c0.21-0.06,0.43-0.08,0.64-0.03C720.7,1876.88,720.67,1876.88,720.64,1876.88z M717.04,1877.04c-0.85,0-1.63,0.07-2.43,0.32
				c-0.04,0-0.08,0.01-0.12,0.01c0.83-0.27,1.65-0.35,2.53-0.35c0.25,0,0.49,0.01,0.74,0.05c-0.02,0-0.05,0-0.07,0.01
				C717.48,1877.04,717.26,1877.04,717.04,1877.04z M676.62,1865.89L676.62,1865.89c0.01,0,0,0.01,0.01,0.02c-0.03,0-0.06,0-0.09,0
				c0.01,0,0.02-0.01,0.02-0.01C676.58,1865.9,676.6,1865.9,676.62,1865.89z M617.39,1871.34c0.01-0.01,0.01-0.01,0.02-0.02
				C617.4,1871.33,617.4,1871.33,617.39,1871.34z M617.24,1871.53c0,0-0.01,0.01-0.01,0.01c0,0-0.01,0.01-0.01,0.01c0,0,0,0,0,0
				C617.23,1871.55,617.24,1871.54,617.24,1871.53z M670.09,1874.96c-0.21-0.01-0.41-0.02-0.62-0.03c-0.18-0.01-0.36-0.01-0.55-0.01
				c-0.33,0-0.6-0.02-0.85-0.03c0.04,0,0.08-0.01,0.12-0.01c0.21,0.01,0.44,0.02,0.71,0.03c0.18,0,0.36,0.01,0.55,0.01
				c0.21,0.01,0.42,0.02,0.62,0.03c0.58,0.04,1.41,0.02,1.91,0.42h-0.01C671.47,1874.98,670.66,1875,670.09,1874.96z M731.5,1844.94
				c0.15-0.04,0.29-0.07,0.44-0.11c0.56-0.14,1.17-0.26,1.81-0.37C733,1844.61,732.25,1844.78,731.5,1844.94z M734.88,1837.02
				c0,0-0.01-0.01-0.01-0.01c-0.13-0.19-0.25-0.38-0.37-0.58c0,0,0,0,0,0C734.63,1836.63,734.75,1836.83,734.88,1837.02z
				 M720.15,1856.45c0.01,0.01,0.01,0.01,0.02,0.02c0,0-0.01,0-0.01,0L720.15,1856.45C720.14,1856.45,720.14,1856.45,720.15,1856.45
				z M694.9,1854.78C694.9,1854.78,694.9,1854.78,694.9,1854.78c-0.01-0.01-0.02-0.02-0.03-0.03l0,0
				C694.88,1854.75,694.89,1854.77,694.9,1854.78z M615.08,1873.26c-0.01,0.01-0.02,0.02-0.02,0.04
				C615.06,1873.28,615.07,1873.27,615.08,1873.26z M614.91,1873.57c-0.01,0.02-0.02,0.04-0.02,0.05
				C614.9,1873.6,614.9,1873.59,614.91,1873.57z M614.79,1873.92c0,0.02-0.01,0.03-0.01,0.05
				C614.79,1873.95,614.79,1873.94,614.79,1873.92z M615.28,1873c0,0.01-0.01,0.01-0.01,0.02
				C615.27,1873.01,615.27,1873,615.28,1873z M615.87,1872.51c-0.02,0.01-0.04,0.02-0.05,0.03
				C615.84,1872.53,615.85,1872.52,615.87,1872.51z M615.56,1872.74c-0.01,0.01-0.03,0.02-0.04,0.04
				C615.53,1872.76,615.54,1872.75,615.56,1872.74z M616.2,1872.31c-0.01,0.01-0.02,0.01-0.03,0.02
				C616.18,1872.32,616.19,1872.32,616.2,1872.31z M672.65,1877.64c0.01,0.01,0.01,0.02,0.02,0.02c-0.09-0.05-0.17-0.11-0.21-0.18
				C672.51,1877.55,672.57,1877.6,672.65,1877.64z M675.92,1878.25c0.01,0.01,0.03,0.02,0.04,0.04c0,0-0.01,0-0.01-0.01
				L675.92,1878.25z M696.25,1856.16c-0.12-0.13-0.28-0.25-0.47-0.36c0,0,0.01,0,0.01,0
				C695.98,1855.91,696.13,1856.03,696.25,1856.16z M674.48,1878.02c-0.31-0.03-0.59-0.08-0.89-0.14L674.48,1878.02z
				 M682.06,1825.15c-0.03-0.02-0.07-0.05-0.1-0.07l0,0C682,1825.11,682.03,1825.13,682.06,1825.15z"/>
			<g>
				<g>
					<g>
						<path className="st21" d="M662.79,1869.77c0.08,0,0.16,0,0.24,0c0,0,0,0,0,0C662.95,1869.77,662.87,1869.77,662.79,1869.77z"/>
					</g>
					<g>
						<path className="st21" d="M658.61,1869.18c0.1,0.02,0.2,0.04,0.3,0.06c0,0,0,0,0,0c0,0,0,0,0,0
							C658.81,1869.23,658.71,1869.21,658.61,1869.18z"/>
					</g>
					<g>
						<path className="st21" d="M658.55,1869.17c-1.18-0.26-2.34-0.61-3.51-0.91C656.21,1868.56,657.37,1868.91,658.55,1869.17z"/>
					</g>
					<g>
						<polygon className="st21" points="663.03,1869.77 663.02,1869.77 663.03,1869.77 						"/>
					</g>
					<g>
						<polygon className="st21" points="676.04,1860.13 676.04,1860.13 676.04,1860.13 						"/>
					</g>
					<g>
						<path className="st21" d="M679.98,1858.25c0.13,0,0.22,0,0.22,0C680.13,1858.25,680.05,1858.25,679.98,1858.25z"/>
					</g>
					<g>
						<path className="st21" d="M732.38,1829.46c0.09-0.02,0.17-0.03,0.26-0.05c0.33-0.06,0.67-0.12,1-0.18
							c0.07-0.01,0.13-0.02,0.2-0.04c0.38-0.07,0.75-0.13,1.13-0.2c0.61-0.11,1.22-0.24,1.83-0.39c0.04-0.01,0.08-0.02,0.13-0.03
							c-1.78,0.25-3.55,0.52-5.32,0.82c0.11,0.02,0.21,0.07,0.31,0.16C732.07,1829.52,732.22,1829.49,732.38,1829.46z"/>
					</g>
					<g>
						<path className="st21" d="M684.19,1851.58c-0.01,0-0.02,0-0.02,0c0,0-0.01,0.01-0.01,0.01
							C684.16,1851.58,684.18,1851.58,684.19,1851.58z"/>
					</g>
					<g>
						<path className="st21" d="M625.24,1858.8c-0.01-0.01-0.01-0.03-0.01-0.04c-0.01,0-0.01,0-0.01,0
							C625.22,1858.77,625.23,1858.78,625.24,1858.8z"/>
					</g>
					<g>
						<path className="st21" d="M667.18,1849.89c0.46-0.09,0.92-0.19,1.38-0.28C668.09,1849.7,667.64,1849.8,667.18,1849.89z"/>
					</g>
					<g>
						<path className="st21" d="M728.18,1829.98c0.07,0,0.13,0,0.2-0.01C728.32,1829.98,728.25,1829.99,728.18,1829.98z"/>
					</g>
					<g>
						<path className="st21" d="M665.38,1850.26c0.16-0.03,0.31-0.06,0.47-0.1C665.69,1850.19,665.54,1850.23,665.38,1850.26z"/>
					</g>
					<g>
						<path className="st21" d="M663.94,1850.54c0.18-0.04,0.36-0.07,0.55-0.11C664.3,1850.47,664.12,1850.51,663.94,1850.54z"/>
					</g>
					<g>
						<path className="st21" d="M682.38,1821.79c-0.13-0.09-0.26-0.17-0.38-0.26C682.13,1821.62,682.26,1821.71,682.38,1821.79z"/>
					</g>
					<g>
						<path className="st21" d="M681.02,1820.87c-0.21-0.14-0.43-0.29-0.64-0.43C680.6,1820.58,680.81,1820.72,681.02,1820.87z"/>
					</g>
					<g>
						<path className="st21" d="M660.94,1870.46c0.01,0,0.02,0,0.02,0.01c0,0,0,0,0,0c0,0,0,0,0,0
							C660.96,1870.47,660.95,1870.47,660.94,1870.46z"/>
					</g>
					<g>
						<path className="st21" d="M727.95,1829.91c-0.02-0.02-0.05-0.03-0.07-0.05C727.9,1829.88,727.93,1829.89,727.95,1829.91z"/>
					</g>
					<g>
						<path className="st21" d="M728.16,1829.98c-0.07-0.01-0.13-0.02-0.19-0.06C728.03,1829.96,728.1,1829.97,728.16,1829.98z"/>
					</g>
					<g>
						<path className="st21" d="M727.88,1829.85c-0.04-0.04-0.08-0.1-0.11-0.17C727.8,1829.75,727.84,1829.8,727.88,1829.85z"/>
					</g>
					<g>
						<path className="st21" d="M739.43,1837.83c-0.06,0.01-0.13,0.03-0.19,0.05C739.31,1837.86,739.37,1837.85,739.43,1837.83z"/>
					</g>
					<g>
						<path className="st21" d="M740.24,1837.72l-0.03,0C740.22,1837.72,740.23,1837.72,740.24,1837.72
							C740.24,1837.72,740.24,1837.72,740.24,1837.72C740.24,1837.72,740.24,1837.72,740.24,1837.72z"/>
					</g>
					<g>
						<path className="st21" d="M740.24,1837.72c0.08,0,0.16,0,0.24,0C740.4,1837.72,740.32,1837.72,740.24,1837.72
							C740.24,1837.72,740.24,1837.72,740.24,1837.72z"/>
					</g>
					<g>
						<path className="st21" d="M739.92,1837.75c0,0-0.01,0-0.01,0C739.91,1837.75,739.92,1837.75,739.92,1837.75c0.01,0,0.02,0,0.02,0
							C739.93,1837.75,739.93,1837.75,739.92,1837.75z"/>
					</g>
					<g>
						<path className="st21" d="M739.92,1837.75c0.01,0,0.01,0,0.02,0c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0c-0.01,0-0.01,0-0.02,0
							c-0.11,0.01-0.23,0.03-0.34,0.05c-0.05,0.01-0.1,0.02-0.15,0.03c0.15-0.03,0.31-0.06,0.46-0.08
							C739.91,1837.75,739.92,1837.75,739.92,1837.75z"/>
					</g>
					<g>
						<path className="st21" d="M625.12,1860.1c-0.06-0.01-0.12-0.02-0.17-0.03C625.01,1860.08,625.07,1860.09,625.12,1860.1z"/>
					</g>
					<g>
						<path className="st21" d="M635.32,1859.93c0.35-0.04,0.69-0.08,1.03-0.15C636.04,1859.82,635.69,1859.88,635.32,1859.93z"/>
					</g>
					<g>
						<path className="st21" d="M631.98,1859.4l-0.05,0c0.01,0,0.01,0,0.02,0C631.96,1859.41,631.97,1859.41,631.98,1859.4z"/>
					</g>
					<g>
						<path className="st21" d="M595.08,1866.76c-0.07,0-0.15,0-0.22,0C594.93,1866.76,595.01,1866.76,595.08,1866.76z"/>
					</g>
					<g>
						<path className="st21" d="M631.88,1859.4l-0.08,0c-0.01,0.03-0.03,0.07-0.04,0.1C631.78,1859.44,631.82,1859.4,631.88,1859.4z"/>
					</g>
					<g>
						<path className="st21" d="M595.7,1866.75c0.08,0,0.15,0,0.23,0C595.86,1866.76,595.78,1866.75,595.7,1866.75z"/>
					</g>
					<g>
						<path className="st21" d="M625.27,1858.84c0.01,0.01,0.02,0.02,0.02,0.03C625.28,1858.86,625.28,1858.85,625.27,1858.84z"/>
					</g>
					<g>
						<path className="st21" d="M625.35,1858.94c0.01,0.01,0.01,0.02,0.02,0.03C625.36,1858.96,625.35,1858.95,625.35,1858.94z"/>
					</g>
					<g>
						<path className="st21" d="M630.18,1859.33l-0.46-0.02c0,0,0,0.01,0.01,0.01C629.88,1859.32,630.03,1859.32,630.18,1859.33z"/>
					</g>
					<g>
						<path className="st21" d="M631.76,1859.49c0-0.03-0.01-0.07-0.01-0.1l-0.03,0c0.02,0.02,0.04,0.05,0.04,0.09
							C631.75,1859.48,631.75,1859.49,631.76,1859.49z"/>
					</g>
					<g>
						<path className="st21" d="M597.83,1866.83c-0.14-0.01-0.28-0.02-0.43-0.03C597.55,1866.81,597.69,1866.82,597.83,1866.83z"/>
					</g>
					<g>
						<path className="st21" d="M710.41,1847.62c-0.03,0-0.06,0-0.08,0c-0.01,0.01-0.01,0.02-0.02,0.03
							C710.34,1847.64,710.37,1847.63,710.41,1847.62z"/>
					</g>
					<g>
						<path className="st21" d="M677.34,1856.53c-0.17-0.02-0.34-0.03-0.5-0.04C677,1856.51,677.17,1856.52,677.34,1856.53z"/>
					</g>
					<g>
						<path className="st21" d="M642.21,1870.36c-0.09,0-0.17,0-0.26,0.01C642.04,1870.37,642.13,1870.36,642.21,1870.36z"/>
					</g>
					<g>
						<path className="st21" d="M758.92,1824.65c-1.95,0.46-3.9,1.13-5.81,1.85C755.07,1825.94,757,1825.32,758.92,1824.65z"/>
					</g>
					<g>
						<path className="st21" d="M625.54,1859.14c0.01,0.01,0.02,0.02,0.03,0.03C625.56,1859.15,625.55,1859.15,625.54,1859.14z"/>
					</g>
					<g>
						<path className="st21" d="M596.37,1866.76c-0.08,0-0.16,0-0.24-0.01C596.21,1866.76,596.29,1866.76,596.37,1866.76z"/>
					</g>
					<g>
						<path className="st21" d="M596.8,1866.78c-0.08,0-0.17-0.01-0.25-0.01C596.64,1866.77,596.72,1866.77,596.8,1866.78z"/>
					</g>
					<g>
						<path className="st21" d="M595.51,1866.76c-0.08,0-0.15,0-0.23,0C595.36,1866.76,595.43,1866.76,595.51,1866.76z"/>
					</g>
					<g>
						<path className="st21" d="M597.24,1866.8c-0.09,0-0.17-0.01-0.26-0.01C597.06,1866.79,597.15,1866.79,597.24,1866.8z"/>
					</g>
					<g>
						<path className="st21" d="M584.77,1854.73c-0.02,0-0.03-0.01-0.05-0.01C584.73,1854.72,584.75,1854.73,584.77,1854.73z"/>
					</g>
					<g>
						<path className="st21" d="M585.02,1854.82c-0.02-0.01-0.03-0.01-0.05-0.02C584.99,1854.81,585.01,1854.81,585.02,1854.82z"/>
					</g>
					<g>
						<path className="st21" d="M625.46,1859.07c-0.01-0.01-0.01-0.01-0.02-0.01C625.45,1859.06,625.46,1859.06,625.46,1859.07z"/>
					</g>
					<g>
						<path className="st21" d="M585.15,1854.87c-0.01-0.01-0.03-0.01-0.04-0.02C585.12,1854.86,585.13,1854.87,585.15,1854.87z"/>
					</g>
					<g>
						<path className="st21" d="M616.35,1857.72c-0.04,0.02-0.08,0.04-0.12,0.06c0.07-0.02,0.13-0.03,0.2-0.05L616.35,1857.72z"/>
					</g>
					<g>
						<path className="st21" d="M584.85,1854.76c0.02,0,0.03,0.01,0.05,0.02C584.88,1854.77,584.86,1854.76,584.85,1854.76
							C584.85,1854.76,584.85,1854.76,584.85,1854.76z"/>
					</g>
					<g>
						<path className="st21" d="M741.48,1838.1C741.48,1838.1,741.48,1838.1,741.48,1838.1c-0.01-0.01-0.02-0.01-0.03-0.02c0,0,0,0,0,0
							C741.46,1838.08,741.47,1838.09,741.48,1838.1z"/>
					</g>
					<g>
						<path className="st21" d="M625.63,1859.22c0.01,0.01,0.02,0.02,0.04,0.03C625.66,1859.23,625.65,1859.23,625.63,1859.22z"/>
					</g>
					<g>
						<polygon className="st21" points="584.85,1854.76 584.85,1854.76 584.85,1854.76 						"/>
					</g>
					<g>
						<path className="st21" d="M626.11,1860.1c-0.06,0.01-0.13,0.01-0.19,0.02C625.98,1860.12,626.05,1860.11,626.11,1860.1z"/>
					</g>
					<g>
						<path className="st21" d="M626.29,1860.08C626.29,1860.08,626.29,1860.08,626.29,1860.08c-0.05,0.01-0.1,0.02-0.16,0.02
							C626.18,1860.09,626.23,1860.09,626.29,1860.08z"/>
					</g>
					<g>
						<path className="st21" d="M626.37,1859.82c0,0-0.01-0.01-0.01-0.01C626.36,1859.81,626.37,1859.82,626.37,1859.82
							C626.37,1859.82,626.37,1859.82,626.37,1859.82z"/>
					</g>
					<g>
						<path className="st21" d="M625.74,1859.3c0.01,0.01,0.02,0.02,0.03,0.02C625.76,1859.31,625.75,1859.31,625.74,1859.3z"/>
					</g>
					<g>
						<path className="st21" d="M625.86,1860.13c-0.04,0-0.09,0-0.13,0.01C625.78,1860.13,625.82,1860.13,625.86,1860.13z"/>
					</g>
					<g>
						<path className="st21" d="M625.48,1860.13c-0.04,0-0.08,0-0.11-0.01C625.4,1860.12,625.44,1860.13,625.48,1860.13z"/>
					</g>
					<g>
						<path className="st21" d="M625.3,1860.12c-0.04,0-0.08-0.01-0.12-0.01C625.22,1860.11,625.26,1860.11,625.3,1860.12z"/>
					</g>
					<g>
						<path className="st21" d="M625.66,1860.13c-0.04,0-0.08,0-0.11,0C625.59,1860.13,625.63,1860.13,625.66,1860.13z"/>
					</g>
					<g>
						<polygon className="st21" points="741.53,1838.15 741.53,1838.15 741.53,1838.15 						"/>
					</g>
					<g>
						<path className="st21" d="M740.95,1837.82C740.95,1837.82,740.94,1837.82,740.95,1837.82
							C740.94,1837.82,740.94,1837.82,740.95,1837.82c0,0,0.01,0.01,0.02,0.01c0,0-0.01,0-0.01,0
							C740.96,1837.82,740.95,1837.82,740.95,1837.82z"/>
					</g>
					<g>
						<path className="st21" d="M740.94,1837.82C740.94,1837.82,740.94,1837.82,740.94,1837.82c-0.01,0-0.03-0.01-0.05-0.02
							c0.01,0,0.02,0.01,0.03,0.01C740.92,1837.81,740.93,1837.81,740.94,1837.82z"/>
					</g>
					<g>
						<path className="st21" d="M741.02,1837.85C741.02,1837.85,741.02,1837.85,741.02,1837.85c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0
							C741.04,1837.86,741.03,1837.85,741.02,1837.85z"/>
					</g>
					<g>
						<path className="st21" d="M741.1,1837.88C741.1,1837.88,741.1,1837.88,741.1,1837.88c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0
							C741.12,1837.89,741.11,1837.88,741.1,1837.88z"/>
					</g>
					<g>
						<path className="st21" d="M741.02,1837.85C741.02,1837.85,741.02,1837.85,741.02,1837.85c-0.01-0.01-0.03-0.01-0.04-0.02
							c0,0,0.01,0,0.01,0C740.99,1837.84,741.01,1837.84,741.02,1837.85z"/>
					</g>
					<g>
						<polygon className="st21" points="741.14,1837.9 741.13,1837.89 741.13,1837.89 						"/>
					</g>
					<g>
						<path className="st21" d="M741.53,1838.15c0,0-0.01-0.01-0.01-0.01C741.53,1838.14,741.53,1838.14,741.53,1838.15
							C741.53,1838.15,741.53,1838.15,741.53,1838.15C741.53,1838.15,741.53,1838.15,741.53,1838.15z"/>
					</g>
					<g>
						<path className="st21" d="M741.45,1838.08C741.45,1838.08,741.45,1838.08,741.45,1838.08c0.01,0.01,0.02,0.01,0.03,0.02
							c0,0,0,0,0,0C741.47,1838.09,741.46,1838.08,741.45,1838.08z"/>
					</g>
					<g>
						<path className="st21" d="M741.57,1838.18C741.57,1838.18,741.57,1838.18,741.57,1838.18
							C741.57,1838.18,741.57,1838.18,741.57,1838.18c0.01,0.01,0.01,0.01,0.02,0.02c0,0,0,0,0,0
							C741.58,1838.2,741.57,1838.19,741.57,1838.18z"/>
					</g>
					<g>
						<path className="st21" d="M741.62,1838.24C741.62,1838.24,741.62,1838.24,741.62,1838.24c0.01,0.01,0.01,0.02,0.02,0.03
							C741.63,1838.26,741.63,1838.26,741.62,1838.24C741.62,1838.25,741.62,1838.25,741.62,1838.24z"/>
					</g>
					<g>
						<path className="st21" d="M741.32,1837.99C741.32,1837.99,741.32,1837.99,741.32,1837.99c0.01,0.01,0.02,0.01,0.03,0.02
							c0,0,0,0,0,0C741.34,1838,741.33,1837.99,741.32,1837.99z"/>
					</g>
					<g>
						<path className="st21" d="M741.41,1838.05c-0.01-0.01-0.02-0.01-0.03-0.02c0,0,0,0,0,0
							C741.39,1838.04,741.4,1838.04,741.41,1838.05C741.41,1838.05,741.41,1838.05,741.41,1838.05
							C741.41,1838.05,741.41,1838.05,741.41,1838.05z"/>
					</g>
					<g>
						<polygon className="st21" points="741.68,1838.33 741.68,1838.34 741.68,1838.34 						"/>
					</g>
					<g>
						<path className="st21" d="M741.17,1837.91C741.17,1837.91,741.17,1837.91,741.17,1837.91c0.01,0,0.02,0.01,0.03,0.02c0,0,0,0,0,0
							C741.2,1837.92,741.18,1837.92,741.17,1837.91z"/>
					</g>
					<g>
						<path className="st21" d="M741.27,1837.96c-0.01,0-0.02-0.01-0.02-0.01c0,0,0,0,0,0C741.26,1837.95,741.27,1837.96,741.27,1837.96
							C741.28,1837.97,741.28,1837.97,741.27,1837.96C741.28,1837.96,741.27,1837.96,741.27,1837.96z"/>
					</g>
					<g>
						<path className="st21" d="M741.38,1838.03C741.38,1838.03,741.38,1838.03,741.38,1838.03c-0.01-0.01-0.02-0.02-0.04-0.02
							c0,0,0,0,0,0C741.36,1838.01,741.37,1838.02,741.38,1838.03z"/>
					</g>
					<g>
						<path className="st21" d="M739.97,1837.74C739.97,1837.74,739.97,1837.74,739.97,1837.74c0.08-0.01,0.16-0.02,0.23-0.02
							c0,0,0,0,0,0C740.12,1837.73,740.05,1837.73,739.97,1837.74z"/>
					</g>
					<g>
						<path className="st21" d="M740.24,1837.72c0.08,0,0.16,0,0.24,0C740.4,1837.72,740.32,1837.72,740.24,1837.72
							C740.24,1837.72,740.24,1837.72,740.24,1837.72z"/>
					</g>
					<g>
						<path className="st21" d="M741.48,1838.1C741.48,1838.1,741.48,1838.1,741.48,1838.1C741.48,1838.1,741.48,1838.1,741.48,1838.1
							c0.01,0.01,0.01,0.01,0.01,0.01C741.49,1838.1,741.48,1838.1,741.48,1838.1z"/>
					</g>
					<g>
						<path className="st21" d="M741.59,1838.21C741.59,1838.21,741.59,1838.21,741.59,1838.21c0,0.01,0.01,0.01,0.01,0.02
							s0.01,0.01,0.01,0.02C741.61,1838.23,741.6,1838.22,741.59,1838.21z"/>
					</g>
					<g>
						<path className="st21" d="M741.57,1838.18C741.57,1838.18,741.57,1838.18,741.57,1838.18c-0.01-0.01-0.02-0.02-0.03-0.03
							c0,0,0,0,0,0C741.55,1838.16,741.56,1838.17,741.57,1838.18z"/>
					</g>
					<g>
						<polygon className="st21" points="741.42,1838.05 741.41,1838.05 741.41,1838.05 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.68,1838.34 741.68,1838.34 741.68,1838.34 						"/>
					</g>
					<g>
						<path className="st21" d="M679.98,1858.25C679.98,1858.25,679.98,1858.25,679.98,1858.25c0.08,0,0.15,0,0.23,0l0,0
							C680.13,1858.25,680.05,1858.25,679.98,1858.25z"/>
					</g>
					<g>
						<polygon className="st21" points="684.16,1851.58 684.15,1851.59 684.15,1851.59 						"/>
					</g>
					<g>
						<polygon className="st21" points="684.15,1851.59 684.16,1851.58 684.15,1851.59 						"/>
					</g>
					<g>
						<path className="st21" d="M635.32,1859.93C635.32,1859.93,635.32,1859.93,635.32,1859.93c0.35-0.04,0.69-0.08,1.03-0.15
							c0,0,0,0,0,0C636.01,1859.85,635.67,1859.89,635.32,1859.93z"/>
					</g>
					<g>
						<path className="st21" d="M612.47,1870.05c0.11-0.21,0.23-0.42,0.36-0.61c0,0,0,0,0,0
							C612.7,1869.64,612.58,1869.84,612.47,1870.05z"/>
					</g>
					<g>
						<polygon className="st21" points="663.03,1869.77 663.02,1869.77 663.02,1869.77 663.03,1869.77 						"/>
					</g>
					<g>
						<path className="st21" d="M664.48,1850.44C664.48,1850.44,664.48,1850.44,664.48,1850.44c-0.18,0.04-0.37,0.07-0.55,0.11
							c-0.01,0-0.01,0-0.02,0C664.1,1850.51,664.29,1850.47,664.48,1850.44z"/>
					</g>
					<g>
						<path className="st21" d="M665.85,1850.16C665.85,1850.16,665.85,1850.16,665.85,1850.16c-0.16,0.03-0.32,0.06-0.47,0.1
							c-0.01,0-0.02,0-0.02,0C665.52,1850.23,665.69,1850.2,665.85,1850.16z"/>
					</g>
					<g>
						<path className="st21" d="M668.55,1849.61c-0.46,0.09-0.92,0.19-1.38,0.28c0,0,0,0,0,0
							C667.63,1849.8,668.09,1849.7,668.55,1849.61z"/>
					</g>
					<g>
						<path className="st21" d="M773.05,1819.82c0.03-0.01,0.06-0.03,0.08-0.04C773.11,1819.8,773.08,1819.81,773.05,1819.82
							L773.05,1819.82z"/>
					</g>
					<g>
						<path className="st21" d="M717.56,1872.15C717.56,1872.15,717.56,1872.15,717.56,1872.15c0.49-0.04,0.98-0.08,1.47-0.12
							c-0.03-0.02-0.06-0.03-0.08-0.04c-0.43,0.04-0.86,0.07-1.3,0.11C717.62,1872.11,717.59,1872.13,717.56,1872.15z"/>
					</g>
					<g>
						<path className="st21" d="M711.88,1872.58c1.5-0.1,2.98-0.21,4.44-0.33c-0.01,0-0.02-0.01-0.03-0.01
							c-0.02-0.01-0.05-0.02-0.07-0.03c-1.4,0.11-2.81,0.22-4.21,0.32C711.97,1872.55,711.92,1872.56,711.88,1872.58z"/>
					</g>
					<g>
						<path className="st21" d="M740.89,1837.8c-0.01,0-0.02-0.01-0.03-0.01c-0.02-0.01-0.04-0.02-0.06-0.02
							c-0.03-0.01-0.07-0.02-0.1-0.03c-0.07-0.01-0.14-0.01-0.21-0.01c0.07,0,0.14,0.01,0.21,0.01c0.03,0.01,0.06,0.02,0.1,0.03
							c0.02,0.01,0.04,0.02,0.07,0.02C740.87,1837.79,740.88,1837.79,740.89,1837.8c0.01,0,0.02,0.01,0.03,0.01
							C740.91,1837.8,740.9,1837.8,740.89,1837.8z"/>
					</g>
					<g>
						<polygon className="st21" points="741.57,1838.18 741.57,1838.18 741.57,1838.18 741.57,1838.18 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.39,1838.03 741.38,1838.03 741.39,1838.03 741.39,1838.03 						"/>
					</g>
					<g>
						<polygon className="st21" points="740.94,1837.82 740.94,1837.82 740.94,1837.82 740.94,1837.82 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.45,1838.08 741.45,1838.07 741.45,1838.08 741.45,1838.08 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.48,1838.1 741.48,1838.1 741.48,1838.1 741.48,1838.1 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.28,1837.97 741.28,1837.96 741.28,1837.97 741.28,1837.97 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.35,1838.01 741.35,1838.01 741.35,1838.01 741.35,1838.01 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.32,1837.99 741.32,1837.99 741.32,1837.99 741.32,1837.99 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.68,1838.34 741.68,1838.34 741.68,1838.34 741.68,1838.34 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.62,1838.24 741.62,1838.24 741.62,1838.24 741.62,1838.24 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.25,1837.95 741.25,1837.95 741.25,1837.95 741.25,1837.95 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.59,1838.21 741.59,1838.21 741.59,1838.21 741.59,1838.21 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.53,1838.15 741.53,1838.15 741.53,1838.15 741.53,1838.15 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.41,1838.05 741.41,1838.05 741.41,1838.05 741.41,1838.05 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.02,1837.85 741.02,1837.85 741.02,1837.85 741.02,1837.85 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.05,1837.86 741.05,1837.86 741.05,1837.86 741.06,1837.86 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.1,1837.88 741.1,1837.88 741.1,1837.88 741.1,1837.88 						"/>
					</g>
					<g>
						<polygon className="st21" points="740.97,1837.83 740.96,1837.83 740.97,1837.83 740.98,1837.83 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.17,1837.91 741.17,1837.91 741.17,1837.91 741.17,1837.91 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.13,1837.89 741.13,1837.89 741.13,1837.89 741.13,1837.89 						"/>
					</g>
					<g>
						<polygon className="st21" points="741.21,1837.93 741.21,1837.93 741.21,1837.93 741.21,1837.93 						"/>
					</g>
					<g>
						<path className="st107" d="M663.38,1850.82c0.3-0.06,0.4,0,0.7-0.05c0.18-0.04,0.58-0.01,0.76-0.04c0.3-0.06,0.24-0.4,0.54-0.46
							c0.16-0.03,0.31-0.06,0.47-0.1c0.44-0.09,0.88-0.18,1.33-0.27c0.46-0.09,0.92-0.19,1.38-0.28c0.47-0.09,0.95-0.19,1.42-0.28
							c0.42-0.08,0.84-0.16,1.26-0.23c0.63-0.11,1.27-0.22,1.9-0.31c0.54-0.08,1.09-0.15,1.64-0.22c0.33-0.04,0.66-0.07,0.99-0.1
							c0.19-0.02,0.38-0.03,0.57-0.05c1.04-0.08,2.09-0.12,3.15-0.11c2.58,0.03,5.27-0.22,7.81-1.02c2.77-0.88,6.01,2.22,8.84,1.9
							c4.19-2.87,10.08-9.36,14.65-11.59c0-0.04-0.01-0.08-0.01-0.12c-1.84-1.13-3.69-2.26-5.73-3c-2.43-0.89-4.69-2.13-6.99-3.31
							c-3.04-1.56-5.92-3.38-8.84-5.14c-1.95-1.17-3.97-2.25-5.83-3.55c-0.33-0.23-0.66-0.46-1-0.69c-0.13-0.09-0.26-0.17-0.38-0.26
							c-0.33-0.22-0.65-0.44-0.98-0.67c-0.21-0.14-0.43-0.29-0.64-0.43c-0.13-0.09-0.27-0.19-0.4-0.28
							c-0.2-0.14-0.39-0.27-0.59-0.41c-0.01-0.01-0.02-0.01-0.03-0.02c-2.09-0.95-3.95-2.31-6.07-3.2c-2.6-1.09-5.17-2.24-7.9-2.96
							c-1.21-0.32-2.42-0.46-3.64-1c-2.24-1-4.51-2.03-7.02-2.41c-2.23-0.34-4.41-1.06-6.63-1.49c-2.52-0.49-4.47,0.56-6.2,2.38
							c-0.89,0.93-1.87,1.82-3.1,2.42c-2.19,1.06-4.56,1.65-6.76,2.65c-1.96,0.88-3.66,2.44-6.03,2.24
							c-0.22-0.02-0.44,0.19-0.68,0.23c-1.22,0.18-2.32,1.19-3.67,0.53c-0.23-0.11-0.44,0.06-0.64,0.24
							c-1.02,0.94-2.27,1.53-3.46,2.21c-0.47,0.27-1.01,0.24-1.54,0.62c-1.34,0.98-2.61,2.08-4.19,2.73
							c-0.74,0.3-1.6,0.37-2.21,0.85c-1.25,0.99-2.61,1.65-4.11,2.17c-0.56,0.19-1.27,0.15-1.69,0.7c-0.07,0.09-0.13,0.17-0.21,0.25
							c-3.74,5.65-7.97,11.56-12.27,17.33c0.34,0.12,0.68,0.27,1.03,0.43c1.52,0.69,3.29,0.47,4.95,0.66
							c5.12,0.59,10.29,0.5,15.42,1.06c3.04,0.33,5.95-0.38,8.82-1.3c0.35-0.11,0.69-0.18,1.02-0.01c1,0.5,2.07,0.44,3.08,0.23
							c2.22-0.47,4.31,0.07,6.36,0.69c2.31,0.71,4.69,1.05,7.05,1.42c1,0.16,2.01-0.04,3.01-0.25c1.43-0.29,2.86-0.36,4.06,0.69
							c0.13,0.12,0.28,0.19,0.43,0.25c0.08,0.03,0.16,0.05,0.23,0.07c0.05,0.01,0.1,0.02,0.15,0.03c0.35,0.07,1.2,0.49,1.5,0.69
							c0,0,0,0,0,0c3.79,0.1,7.48,0.33,10.96,0.75c0,0,3.16,0.54,3.16,0.54c0.33-0.29,0.53-0.48,0.91-0.49
							c0.09,0,0.64-0.38,0.72-0.38s-0.09,0,0,0c0.04,0-0.99-0.24-0.95-0.24c0.26-0.01-0.02,0.17,0.22,0.1c0,0-0.13-0.19-0.12-0.19
							c0.04-0.01,0.36,0,0.41-0.01c0.16-0.06,1,0.09,1.15-0.01C664.92,1850.95,663.38,1850.82,663.38,1850.82"/>
					</g>
					<g>
						<path className="st108" d="M665.35,1850.26c0.01,0,0.02,0,0.02,0c-0.3,0.06-0.6,0.12-0.9,0.18c0,0,0,0,0,0
							C664.77,1850.38,665.06,1850.32,665.35,1850.26z"/>
					</g>
					<g>
						<path className="st108" d="M667.17,1849.89C667.18,1849.89,667.18,1849.89,667.17,1849.89c-0.44,0.09-0.88,0.18-1.32,0.27
							c0,0,0,0,0,0C666.29,1850.07,666.73,1849.98,667.17,1849.89z"/>
					</g>
					<g>
						<path className="st108" d="M663.94,1850.54c-0.3,0.06-0.6,0.12-0.9,0.17c0,0,0,0,0,0c0.29-0.05,0.59-0.11,0.88-0.17
							C663.92,1850.55,663.93,1850.54,663.94,1850.54z"/>
					</g>
					<g>
						<path className="st109" d="M735.95,1866.93c-2.08-0.31-4.15-0.7-6.21-1.16c-5.09-1.12-10.08-2.62-15.05-4.15
							c-2.49-0.77-4.98-1.54-7.48-2.29c-1.25-0.37-3-0.54-4.26-0.89c-0.63-0.18-0.76-0.55-1.39-0.72c-0.46-0.12-1.06-0.06-1.48-0.31
							c-0.44-0.27,0.05-0.54,0.32-0.66c0.71-0.33,1.5-0.32,2.23-0.57c5.08-1.77,10.23-3.35,15.43-4.74
							c-1.89-2.26-4.74-3.68-7.67-3.85c-0.03,0.01-0.07,0.01-0.1,0.02c0.01-0.01,0.01-0.02,0.02-0.03
							c-0.14-0.01-0.28-0.01-0.43-0.01c0.83-0.48,1-1.79,0.32-2.47c9.36-3.07,18.96-5.41,28.68-7.01c-0.1-0.09-0.19-0.18-0.29-0.26
							c-0.02-0.01-0.04-0.01-0.06-0.02c0.02,0.01,0.04,0.01,0.06,0.02c-2.3-2.08-5.23-4.36-6.56-7.1c-0.47,0.13-0.93,0.27-1.39,0.43
							c-0.14,0.05-0.28,0.1-0.42,0.15c-0.25,0.09-0.5,0.18-0.75,0.28c-0.17,0.07-0.34,0.14-0.52,0.21c-0.61,0.26-1.21,0.56-1.79,0.9
							c-0.14,0.08-0.32,0.15-0.48,0.16c-3.78,0.34-7.31,1.69-10.91,2.77c-0.04,0.12-0.09,0.22-0.16,0.31
							c-0.04,0.06-0.09,0.11-0.14,0.16c-0.08,0.07-0.16,0.13-0.25,0.18c-0.24,0.13-0.51,0.18-0.76,0.16c-1-0.05-1.76,0.44-2.55,0.86
							c0.01,0.14-0.31,0.22-0.64,0.31c0,0,0,0,0,0c-0.07,0.03-0.14,0.04-0.21,0.06c-0.26,0.07-0.48,0.15-0.47,0.27
							c-0.29,0.27-0.7,0.32-1.07,0.44c-0.03,0.01-0.06,0.02-0.09,0.03c-0.07,0.02-0.13,0.05-0.2,0.08
							c-2.75,1.39-5.39,2.98-8.01,4.61c-1.25,0.78-2.47,1.64-3.73,2.41c-1.11,0.88-3.31,3.33-4.86,3.41l-1.14,0.5c0,0,0,0,0,0
							c0.68-0.15,1.11,0.03,1.42,0.46c0.02,0.03,0.04,0.06,0.06,0.09c0.05,0.08,0.1,0.17,0.15,0.27c0.05,0.1,0.02,0.27,0.13,0.32
							c2.15,0.84,1.18,2.69,0.48,4.18c-0.07,0.15-0.14,0.3-0.21,0.45c-0.03,0.05-0.06,0.11-0.09,0.16c-0.17,0.28-0.39,0.5-0.76,0.55
							c-0.25,0.25-1.11,0.52-1.27,0.62c-0.48,0.29-1.03,0.49-1.53,0.7c-0.74,0.3-1.47,0.69-2.12,1.15
							c-0.39,0.28-0.78,0.56-1.16,0.84c-0.59,0.43-1.18,0.87-1.77,1.31c-0.48,0.35-1.05,1-1.53,1.36c-0.07,0.05-3.16,3.1-4.16,4.08
							c0.02-0.01,0.03-0.01,0.05-0.02c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.02,0.01-0.03,0.02-0.05,0.02
							c-0.18,0.18-0.3,0.29-0.31,0.3c-0.12,0-0.23-0.01-0.33-0.04c-0.18,0.05-0.35,0.09-0.52,0.11c-0.51,0.11-1.03,0.18-1.55,0.25
							c0.52-0.07,1.04-0.13,1.55-0.25c-0.77,0.13-1.51,0.1-2.25,0.5c-0.76,0.4-1.68,0.95-2.53,1.16c-0.83,0.21-1.71,0.19-2.54,0.42
							c-0.98,0.28-1.86,0.81-2.84,1.11c-0.59,0.18-1.21,0.29-1.82,0.34c0.25,0.02,0.53,0.03,0.85,0.03c0.18,0,0.36,0.01,0.55,0.01
							c0.21,0.01,0.42,0.02,0.62,0.03c0.62,0.04,1.52,0.01,2.01,0.49c0,0,0,0,0,0c0.58,0.31,1.03,0.78,0.56,1.36
							c-0.49,0.61-0.17,0.89,0.41,0.98c0.65,0.1,1.11,0.26,1.76,0.25c0.63,0,1.23,0.08,1.23,0.5c0.91,0.08,1.72,0.11,2.08,0.15
							c6.13,0.63,12.32-0.03,18.46-0.23c5.84-0.2,11.67-0.51,17.49-0.92c0.98-0.39,1.92-0.5,2.95-0.49c0.43,0,0.86,0.02,1.26,0.18
							c0.48-0.04,0.95-0.08,1.43-0.12c0.42-0.24,0.87-0.3,1.3-0.11c0.02,0,0.03,0,0.05,0c4.64-0.4,9.28-0.87,13.91-1.41
							c2.27-0.26,4.55-0.55,6.82-0.84c1.13-0.15,2.27-0.3,3.4-0.46c0.98-0.14,3.4-0.48,3.4-0.48l5.8-1.17
							C746.85,1867.95,741.36,1867.73,735.95,1866.93z M736.51,1867.41C736.51,1867.41,736.51,1867.41,736.51,1867.41
							c0.22-0.02,0.43-0.02,0.65-0.02C736.95,1867.39,736.73,1867.39,736.51,1867.41z"/>
					</g>
					<g>
						<path className="st109" d="M736.53,1867.44C736.53,1867.44,736.53,1867.44,736.53,1867.44c0.22-0.02,0.43-0.02,0.65-0.02
							C736.96,1867.41,736.75,1867.42,736.53,1867.44z"/>
					</g>
					<g>
						<path className="st109" d="M676.42,1866.6c0.52-0.07,1.04-0.13,1.55-0.25c0,0,0,0,0,0
							C677.46,1866.46,676.94,1866.53,676.42,1866.6z"/>
					</g>
					<g>
						<path className="st109" d="M679.19,1865.96C679.19,1865.96,679.19,1865.96,679.19,1865.96c-0.02,0.01-0.03,0.01-0.05,0.02
							c0,0,0,0,0,0C679.15,1865.97,679.17,1865.97,679.19,1865.96z"/>
					</g>
					<g>
						<path className="st109" d="M715.63,1835.98c-0.04,0.06-0.09,0.11-0.14,0.16C715.54,1836.1,715.59,1836.04,715.63,1835.98z"/>
					</g>
					<g>
						<path className="st109" d="M715.49,1836.14c-0.08,0.07-0.16,0.13-0.25,0.18C715.33,1836.28,715.41,1836.22,715.49,1836.14z"/>
					</g>
					<g>
						<path className="st109" d="M717.65,1872.1c0.43-0.04,0.87-0.07,1.3-0.11C718.52,1871.79,718.07,1871.85,717.65,1872.1z"/>
					</g>
					<g>
						<path className="st109" d="M712.01,1872.53c1.4-0.1,2.81-0.21,4.21-0.32c-0.41-0.15-0.83-0.18-1.26-0.18
							C713.93,1872.04,712.99,1872.14,712.01,1872.53z"/>
					</g>
					<g>
						<path className="st109" d="M711.93,1837.35c-0.21,0.11-0.42,0.22-0.64,0.31C711.62,1837.57,711.94,1837.49,711.93,1837.35z"/>
					</g>
					<g>
						<path className="st109" d="M710.78,1837.76c0-0.05-0.01-0.1-0.01-0.15c-4.57,2.23-8.94,4.97-13.13,7.84c0,0,0.01,0,0.01,0
							c-0.05,0.03-0.1,0.07-0.15,0.12c1.27-0.77,2.48-1.62,3.73-2.41c2.62-1.63,5.25-3.22,8.01-4.61c0.06-0.03,0.13-0.06,0.2-0.08
							c0.03-0.01,0.06-0.02,0.09-0.03c0.37-0.12,0.78-0.16,1.07-0.44c-0.01-0.12,0.21-0.2,0.47-0.27
							C710.97,1837.73,710.87,1837.74,710.78,1837.76L710.78,1837.76z"/>
					</g>
					<g>
						<path className="st110" d="M584.16,1855.65c-0.03,2.28-0.05,4.56-0.08,6.84c0,0.42,0,0.84-0.01,1.26c0,0.38,0,0.76,0,1.13
							c0,0.08,0.06,0.15,0.14,0.17c3.43,0.61,6.83,1.19,10.2,1.72c0.01,0,0.02,0,0.03,0c0.07,0,0.14,0,0.21-0.01
							c0.07,0,0.13,0,0.2-0.01c0.07,0,0.15,0,0.22,0c0.07,0,0.14,0,0.2,0c0.08,0,0.15,0,0.23,0c0.07,0,0.13,0,0.2,0
							c0.08,0,0.15,0,0.23,0c0.06,0,0.13,0,0.19,0c0.08,0,0.16,0,0.24,0.01c0.06,0,0.12,0,0.18,0c0.08,0,0.17,0.01,0.25,0.01
							c0.06,0,0.12,0,0.17,0.01c0.09,0,0.17,0.01,0.26,0.01c0.05,0,0.11,0.01,0.16,0.01c0.14,0.01,0.28,0.02,0.43,0.03
							c1.76,0.13,3.56-0.44,5.26,0.12c2.59,0.85,5.09-0.31,7.63-0.15c0.39,0.02,0.88-0.03,1.03-0.38c0.59-1.38,1.9-1.26,3-1.46
							c0.75-0.13,1.48-0.29,2.21-0.52c1.7-0.52,3.48-0.66,5.23-0.71c2.5-0.08,4.86-0.8,7.26-1.32c0.32-0.07,0.82-0.19,0.65-0.64
							c-0.33-0.84,0.27-0.96,0.78-1.14c0.59-0.21,1.19-0.36,1.79-0.54c0,0,0,0,0,0c0.08-0.01,0.17-0.01,0.25-0.02
							c0.17-0.01,0.34-0.02,0.5-0.03c0.16-0.01,0.33-0.02,0.49-0.02c0.47-0.02,0.93-0.05,1.4-0.09c0.37-0.05,0.72-0.11,1.03-0.15
							c0.11-0.02,0.22-0.05,0.33-0.07c0.14-0.04,0.28-0.06,0.43-0.09c-0.33-0.01-0.67-0.03-1-0.04c-0.69-0.03-1.38-0.06-2.07-0.09
							c-0.4-0.02-0.81-0.03-1.21-0.05c-0.19-0.01-0.39-0.02-0.58-0.02c-0.13,0-0.26-0.01-0.38-0.06c-0.03-0.01-0.06-0.02-0.08-0.02
							c-0.07-0.01-0.11,0.07-0.18,0.07c-0.09,0-0.17-0.02-0.26-0.03c-0.16-0.01-0.33-0.01-0.49-0.02c-0.22-0.01-0.44-0.02-0.65-0.03
							c-0.14,0.1-0.28,0.19-0.42,0.28c-0.08,0.23-0.59,0.29-2-0.04c-0.73-0.23-1.64-0.54-2.56-0.81c0,0.01,0.01,0.03,0.01,0.04
							c0.01,0.02,0.02,0.03,0.03,0.05c0.01,0.01,0.02,0.02,0.02,0.03c0.02,0.02,0.03,0.05,0.05,0.07c0.01,0.01,0.01,0.02,0.02,0.03
							c0.03,0.03,0.05,0.06,0.08,0.09c0.01,0.01,0.01,0.01,0.02,0.01c0.02,0.02,0.05,0.05,0.07,0.07c0.01,0.01,0.02,0.02,0.03,0.03
							c0.02,0.02,0.04,0.04,0.06,0.05c0.01,0.01,0.02,0.02,0.04,0.03c0.01,0,0.01,0.01,0.02,0.01c0.02,0.01,0.03,0.03,0.05,0.04
							c0.01,0.01,0.02,0.02,0.03,0.02c0.03,0.02,0.06,0.05,0.1,0.07c0.14,0.1,0.27,0.2,0.4,0.33c0.03,0.03,0.06,0.06,0.09,0.09
							c0,0,0.01,0.01,0.01,0.01c0,0,0,0,0,0c0.05,0.06,0.1,0.11,0.15,0.18c0.01,0.01,0,0.02-0.01,0.03
							c-0.07,0.02-0.15,0.03-0.22,0.05c0,0,0,0-0.01,0c-0.05,0.01-0.1,0.02-0.16,0.02c-0.01,0-0.01,0-0.02,0
							c-0.06,0.01-0.13,0.01-0.19,0.02c-0.02,0-0.04,0-0.06,0c-0.04,0-0.09,0-0.13,0.01c-0.02,0-0.05,0-0.07,0
							c-0.04,0-0.08,0-0.11,0c-0.02,0-0.05,0-0.07,0c-0.04,0-0.08,0-0.11-0.01c-0.02,0-0.04,0-0.07-0.01
							c-0.04,0-0.08-0.01-0.12-0.01c-0.02,0-0.04,0-0.06-0.01c-0.06-0.01-0.12-0.02-0.17-0.03c-4.15-0.67-8.32-0.41-12.48-0.18
							c-1.61,0.09-3.19-0.05-4.75-0.46c-1.25-0.33-2.5-0.61-3.8-0.57c-0.52,0.01-0.98-0.13-1.33-0.5c-0.73-0.78-1.68-0.97-2.67-0.95
							c-0.94,0.02-1.79-0.19-2.6-0.64c-1.7-0.94-3.47-1.18-5.43-0.91c-2.25,0.32-4.52,0.13-6.62-0.94
							c-0.04-0.02-0.08-0.04-0.12-0.06c-0.01-0.01-0.03-0.01-0.04-0.02c-0.03-0.01-0.06-0.02-0.08-0.04
							c-0.02-0.01-0.03-0.01-0.05-0.02c-0.03-0.01-0.05-0.02-0.08-0.03c-0.02-0.01,0.07-0.01,0.05-0.02"/>
						<path className="st42" d="M605.2,1867.46c-0.71,0-1.44-0.08-2.17-0.32c-1.02-0.33-2.06-0.25-3.16-0.17
							c-0.67,0.05-1.37,0.11-2.05,0.06c-0.14-0.01-0.28-0.02-0.42-0.03c-0.03,0-0.07,0-0.1-0.01l-0.12-0.01
							c-0.06,0-0.13-0.01-0.19-0.01l-0.23-0.01c-0.06,0-0.13-0.01-0.19-0.01c-0.04,0-0.07,0-0.11,0l-0.13,0c-0.06,0-0.12,0-0.18,0
							c-0.04,0-0.08,0-0.12,0l-0.14,0c-0.06,0-0.12,0-0.18,0c-0.03,0-0.07,0-0.1,0l-0.14,0c-0.05,0-0.1,0-0.15,0
							c-0.04,0-0.08,0-0.12,0l-0.14,0c-0.05,0-0.1,0-0.15,0c-0.04,0-0.08,0-0.13,0l-0.14,0c-0.05,0-0.09,0-0.14,0l-0.01,0l-0.06,0
							c-3.36-0.54-6.8-1.12-10.2-1.72c-0.18-0.03-0.31-0.18-0.31-0.36l0-1.13c0-0.42,0-0.84,0.01-1.26l0.08-6.84l0.4,0l-0.08,6.84
							c0,0.42,0,0.84-0.01,1.26l0,1.11c3.39,0.6,6.81,1.18,10.16,1.72l0.01,0c0.04,0,0.09,0,0.13,0l0.14,0c0.04,0,0.09,0,0.13,0
							c0.05,0,0.1,0,0.15,0l0.14,0c0.04,0,0.09,0,0.13,0c0.05,0,0.11,0,0.16,0l0.14,0c0.04,0,0.08,0,0.12,0c0.06,0,0.11,0,0.17,0
							l0.14,0c0.04,0,0.08,0,0.12,0c0.06,0,0.12,0,0.18,0l0.13,0c0.04,0,0.08,0,0.12,0c0.07,0,0.13,0,0.2,0.01l0.23,0.01
							c0.07,0,0.13,0.01,0.2,0.01l0.12,0.01c0.04,0,0.07,0,0.11,0.01c0.14,0.01,0.29,0.02,0.43,0.03c0.66,0.05,1.34-0.01,1.99-0.06
							c1.09-0.09,2.23-0.17,3.32,0.18c1.52,0.5,3.05,0.28,4.53,0.06c0.99-0.14,2.02-0.29,3.05-0.23c0.17,0.01,0.72,0.02,0.84-0.26
							c0.53-1.24,1.61-1.38,2.57-1.49c0.2-0.02,0.39-0.05,0.58-0.08c0.83-0.15,1.53-0.31,2.19-0.51c1.75-0.54,3.58-0.67,5.29-0.72
							c1.9-0.06,3.75-0.5,5.54-0.93c0.55-0.13,1.12-0.27,1.68-0.39c0.13-0.03,0.45-0.1,0.51-0.22c0.01-0.01,0.03-0.05-0.01-0.15
							c-0.13-0.33-0.14-0.59-0.04-0.81c0.15-0.32,0.5-0.44,0.81-0.54c0.04-0.02,0.09-0.03,0.13-0.05c0.42-0.15,0.85-0.27,1.26-0.39
							c0.18-0.05,0.36-0.1,0.54-0.15l0.04-0.01c0.09-0.01,0.17-0.01,0.25-0.02c0.17-0.01,0.34-0.02,0.51-0.03l0.49-0.02
							c0.43-0.02,0.88-0.04,1.33-0.09l-2.42-0.1c-0.07,0-0.14-0.01-0.2-0.01c-0.12-0.01-0.25-0.01-0.37-0.01
							c-0.13,0-0.29-0.01-0.45-0.07l-0.01,0c-0.04,0.02-0.1,0.06-0.19,0.06c0,0,0,0,0,0c-0.06,0-0.12-0.01-0.17-0.02
							c-0.03-0.01-0.07-0.01-0.09-0.01l-1.08-0.04l-0.32,0.22c-0.19,0.34-0.84,0.34-2.2,0.02l-0.01,0c-0.22-0.07-0.46-0.15-0.7-0.23
							c-0.35-0.11-0.73-0.24-1.13-0.36l0.08,0.06c0.13,0.1,0.28,0.21,0.42,0.34c0.03,0.03,0.07,0.06,0.1,0.1
							c0,0,0.01,0.01,0.01,0.01l0.01,0.02c0.06,0.06,0.11,0.12,0.15,0.18c0.04,0.06,0.05,0.13,0.03,0.2
							c-0.02,0.07-0.08,0.12-0.15,0.14c-0.07,0.02-0.14,0.03-0.21,0.05l-0.02,0c-0.06,0.01-0.12,0.02-0.17,0.03l-0.02,0
							c-0.07,0.01-0.13,0.02-0.2,0.02c-0.01,0-0.03,0-0.04,0l-0.02,0c-0.05,0-0.09,0-0.14,0.01c-0.02,0-0.04,0-0.06,0h-0.01
							c-0.04,0-0.08,0-0.12,0c-0.02,0-0.05,0-0.07,0c-0.04,0-0.08,0-0.12-0.01c-0.02,0-0.05,0-0.07-0.01
							c-0.04,0-0.08-0.01-0.13-0.01l-0.06-0.01c-0.06-0.01-0.12-0.02-0.18-0.03c-3.96-0.64-8-0.42-11.91-0.21l-0.52,0.03
							c-1.67,0.09-3.29-0.06-4.81-0.46c-1.12-0.29-2.42-0.6-3.74-0.56c-0.62,0.02-1.11-0.17-1.48-0.56
							c-0.59-0.63-1.39-0.91-2.52-0.89c-0.98,0.02-1.86-0.2-2.7-0.66c-1.61-0.88-3.3-1.16-5.31-0.88c-2.52,0.35-4.79,0.03-6.74-0.96
							c-0.04-0.02-0.08-0.04-0.12-0.06l-0.04-0.02c-0.03-0.01-0.05-0.02-0.08-0.03l-0.04-0.02c-0.02-0.01-0.05-0.02-0.07-0.03
							l-0.15-0.05l0.01-0.16c0-0.05,0.03-0.16,0.2-0.19l0.01,0c-0.07,0.01-0.15,0.08-0.15,0.18l0.15,0.01l0.06-0.17
							c0.01,0,0.03,0.01,0.04,0.01l0.01-0.03l0.03,0.04c0,0,0.01,0,0.01,0l0.05,0.02c0.03,0.01,0.06,0.03,0.09,0.04l0.04,0.02
							c0.04,0.02,0.09,0.04,0.13,0.06c1.88,0.95,4.06,1.26,6.5,0.92c2.1-0.29,3.86,0,5.55,0.93c0.78,0.43,1.6,0.63,2.5,0.61
							c1.25-0.02,2.15,0.3,2.82,1.02c0.29,0.31,0.67,0.45,1.18,0.44c1.37-0.04,2.71,0.28,3.85,0.57c1.5,0.39,3.04,0.54,4.69,0.45
							l0.52-0.03c3.93-0.21,8-0.44,12,0.21c0.06,0.01,0.11,0.02,0.17,0.02l0.05,0.01c0.04,0,0.08,0.01,0.11,0.01
							c0.02,0,0.04,0,0.06,0.01c0.04,0,0.07,0.01,0.11,0.01c0.02,0,0.04,0,0.07,0c0.04,0,0.07,0,0.11,0l0.07,0
							c0.04,0,0.08,0,0.12-0.01l0.06,0c0.06,0,0.12-0.01,0.18-0.02l0.02,0c0.02,0,0.04-0.01,0.06-0.01
							c-0.01-0.01-0.02-0.02-0.03-0.03c-0.13-0.12-0.26-0.22-0.39-0.31l-0.24-0.18c-0.02-0.02-0.04-0.04-0.07-0.05l-0.03-0.03
							c-0.03-0.02-0.05-0.05-0.08-0.07l-0.02-0.02c-0.03-0.03-0.06-0.06-0.09-0.1c-0.01-0.01-0.01-0.01-0.02-0.02
							c-0.03-0.03-0.05-0.06-0.07-0.08c-0.01-0.01-0.02-0.02-0.03-0.04c-0.01-0.02-0.02-0.03-0.03-0.05l-0.01-0.02
							c-0.02-0.03-0.03-0.06-0.03-0.1l-0.06-0.32l0.31,0.09c0.65,0.19,1.3,0.4,1.87,0.59c0.24,0.08,0.48,0.15,0.69,0.22
							c1.57,0.37,1.75,0.18,1.76,0.17l0.02-0.06l0.05-0.03l0.48-0.32l1.21,0.05c0.05,0,0.1,0.01,0.14,0.02
							c0.03,0,0.06,0.01,0.08,0.01c0,0,0,0,0,0c0.04-0.03,0.12-0.08,0.22-0.07c0.05,0,0.09,0.02,0.12,0.03l0.01,0
							c0.1,0.04,0.22,0.04,0.32,0.04c0.13,0,0.26,0.01,0.39,0.02c0.07,0,0.13,0.01,0.2,0.01l6.25,0.26l-1.95,0.31
							c-0.14,0.02-0.28,0.05-0.41,0.08c-0.11,0.03-0.22,0.05-0.34,0.08l-0.01,0c-0.31,0.05-0.67,0.1-1.03,0.15
							c-0.49,0.05-0.97,0.07-1.42,0.09l-0.49,0.02c-0.17,0.01-0.33,0.02-0.5,0.03c-0.08,0-0.15,0.01-0.23,0.01
							c-0.17,0.05-0.35,0.1-0.52,0.15c-0.41,0.12-0.83,0.23-1.24,0.38c-0.04,0.02-0.09,0.03-0.14,0.05
							c-0.26,0.09-0.5,0.17-0.57,0.33c-0.05,0.11-0.03,0.28,0.05,0.5c0.09,0.22,0.04,0.39-0.01,0.48c-0.15,0.28-0.54,0.37-0.78,0.42
							c-0.56,0.12-1.13,0.26-1.68,0.39c-1.81,0.43-3.69,0.88-5.62,0.94c-1.68,0.06-3.47,0.18-5.18,0.71
							c-0.67,0.21-1.38,0.37-2.23,0.52c-0.2,0.03-0.4,0.06-0.6,0.08c-0.93,0.11-1.81,0.22-2.25,1.25c-0.15,0.36-0.59,0.54-1.23,0.5
							c-0.99-0.06-1.99,0.08-2.97,0.22C606.91,1867.34,606.06,1867.46,605.2,1867.46z M626.46,1859.83
							C626.46,1859.84,626.46,1859.84,626.46,1859.83C626.46,1859.84,626.46,1859.84,626.46,1859.83z"/>
					</g>
					<g>
						<path className="st110" d="M584.16,1855.65"/>
					</g>
					<g>
						<path className="st110" d="M637.12,1859.61L637.12,1859.61c-0.14,0.02-0.28,0.05-0.43,0.09c-0.11,0.03-0.22,0.05-0.33,0.07
							c0,0,0,0,0,0c0.11-0.02,0.22-0.05,0.33-0.07C636.83,1859.67,636.97,1859.64,637.12,1859.61z"/>
					</g>
					<g>
						<path className="st110" d="M633.93,1860.02c0.47-0.02,0.93-0.05,1.4-0.09c0,0,0,0,0,0C634.86,1859.98,634.39,1860,633.93,1860.02z
							"/>
					</g>
					<g>
						<path className="st111" d="M647.64,1850.83c0.02,0.01,0.04,0.02,0.06,0.03c0.25,0.01,0.51,0.01,0.76,0.02c0,0,0,0,0,0
							c-0.3-0.2-1.15-0.62-1.5-0.69C647.29,1850.26,647.37,1850.62,647.64,1850.83z"/>
					</g>
					<g>
						<path className="st111" d="M646.57,1850.09c0.08,0.03,0.16,0.05,0.23,0.07C646.73,1850.14,646.65,1850.12,646.57,1850.09z"/>
					</g>
					<g>
						<path className="st112" d="M622.71,1858.13c-0.02,0-0.03-0.01-0.05-0.01C622.67,1858.15,622.69,1858.15,622.71,1858.13z"/>
					</g>
					<g>
						<path className="st112" d="M584.9,1854.77c0.03,0.01,0.05,0.02,0.08,0.03c0.02,0.01,0.03,0.01,0.05,0.02
							c0.03,0.01,0.06,0.02,0.08,0.04c0.01,0.01,0.03,0.01,0.04,0.02c0.04,0.02,0.08,0.04,0.12,0.06c2.1,1.07,4.38,1.26,6.62,0.94
							c1.97-0.28,3.73-0.03,5.43,0.91c0.81,0.45,1.66,0.65,2.6,0.64c0.99-0.02,1.94,0.17,2.67,0.95c0.35,0.37,0.81,0.52,1.33,0.5
							c1.3-0.04,2.55,0.24,3.8,0.57c1.56,0.41,3.14,0.54,4.75,0.46c4.16-0.23,8.33-0.49,12.48,0.18c0.06,0.01,0.12,0.02,0.17,0.03
							c0.02,0,0.04,0,0.06,0.01c0.04,0,0.08,0.01,0.12,0.01c0.02,0,0.04,0,0.07,0.01c0.04,0,0.08,0.01,0.11,0.01
							c0.02,0,0.05,0,0.07,0c0.04,0,0.08,0,0.11,0c0.02,0,0.05,0,0.07,0c0.04,0,0.08,0,0.13-0.01c0.02,0,0.04,0,0.06,0
							c0.06,0,0.13-0.01,0.19-0.02c0.01,0,0.01,0,0.02,0c0.05-0.01,0.11-0.02,0.16-0.03c0.11-0.03,0.16-0.17,0.08-0.25
							c0-0.01-0.01-0.01-0.01-0.02c-0.03-0.03-0.06-0.06-0.09-0.09c-0.13-0.12-0.27-0.23-0.4-0.33c-0.03-0.02-0.06-0.05-0.1-0.07
							c-0.01-0.01-0.02-0.02-0.03-0.02c-0.02-0.02-0.05-0.04-0.07-0.05c-0.01-0.01-0.02-0.02-0.04-0.03
							c-0.02-0.02-0.04-0.03-0.06-0.05c-0.01-0.01-0.02-0.02-0.03-0.03c-0.02-0.02-0.05-0.04-0.07-0.06
							c-0.01-0.01-0.01-0.01-0.02-0.02c-0.03-0.03-0.05-0.06-0.08-0.08c-0.01-0.01-0.02-0.02-0.02-0.03
							c-0.02-0.02-0.03-0.04-0.05-0.06c-0.01-0.01-0.02-0.02-0.03-0.04c-0.01-0.01-0.01-0.02-0.02-0.03c0,0,0-0.01-0.01-0.01
							c-0.01-0.02-0.02-0.03-0.03-0.05c-0.86-0.26-1.74-0.49-2.5-0.62c-0.01,0.02-0.03,0.02-0.05-0.01
							c-0.16-0.03-0.31-0.05-0.45-0.06l-5.78-0.33c-0.07,0.02-0.14,0.03-0.2,0.05c-2.18,0.95-4.14,0.13-6.35-0.31
							c-2.13-0.43-4.3-0.78-6.34-1.58c-1.26-0.49-2.46-1.15-3.75-1.56c-2.2-0.7-4.55-0.64-6.85-0.89c-1.84-0.2-4.14-0.65-5.77-1.7"
							/>
					</g>
					<g>
						<path className="st112" d="M625.57,1859.16c0.02,0.02,0.04,0.03,0.06,0.05C625.61,1859.2,625.59,1859.18,625.57,1859.16z"/>
					</g>
					<g>
						<path className="st112" d="M626.29,1860.08c0.08-0.01,0.16-0.03,0.25-0.05c-0.05-0.07-0.11-0.14-0.16-0.2
							C626.45,1859.91,626.41,1860.05,626.29,1860.08z"/>
					</g>
					<g>
						<path className="st112" d="M625.47,1859.07c0.02,0.02,0.05,0.04,0.07,0.06C625.51,1859.11,625.49,1859.09,625.47,1859.07z"/>
					</g>
					<g>
						<path className="st112" d="M625.27,1858.84c-0.01-0.01-0.02-0.03-0.03-0.04c0,0,0,0.01,0.01,0.01
							C625.25,1858.82,625.26,1858.83,625.27,1858.84z"/>
					</g>
					<g>
						<path className="st112" d="M625.29,1858.88c0.02,0.02,0.03,0.04,0.05,0.06C625.33,1858.92,625.31,1858.9,625.29,1858.88z"/>
					</g>
					<g>
						<path className="st112" d="M625.45,1859.05c-0.03-0.03-0.05-0.06-0.08-0.08C625.39,1859,625.42,1859.03,625.45,1859.05z"/>
					</g>
					<g>
						<path className="st112" d="M625.73,1860.13c-0.02,0-0.05,0-0.07,0C625.69,1860.13,625.71,1860.13,625.73,1860.13z"/>
					</g>
					<g>
						<path className="st112" d="M625.55,1860.13c-0.02,0-0.05,0-0.07,0C625.5,1860.13,625.53,1860.13,625.55,1860.13z"/>
					</g>
					<g>
						<path className="st112" d="M625.87,1859.39c-0.03-0.02-0.06-0.05-0.1-0.07C625.8,1859.34,625.83,1859.37,625.87,1859.39z"/>
					</g>
					<g>
						<path className="st112" d="M625.18,1860.1c-0.02,0-0.04,0-0.06-0.01C625.14,1860.1,625.16,1860.1,625.18,1860.1z"/>
					</g>
					<g>
						<path className="st112" d="M626.13,1860.1c-0.01,0-0.01,0-0.02,0C626.12,1860.1,626.12,1860.1,626.13,1860.1z"/>
					</g>
					<g>
						<path className="st112" d="M625.92,1860.12c-0.02,0-0.04,0-0.06,0C625.88,1860.13,625.9,1860.13,625.92,1860.12z"/>
					</g>
					<g>
						<path className="st112" d="M626.27,1859.72c0.03,0.03,0.06,0.06,0.09,0.09C626.33,1859.78,626.3,1859.75,626.27,1859.72z"/>
					</g>
					<g>
						<path className="st112" d="M585.27,1854.93c-0.04-0.02-0.08-0.04-0.12-0.06C585.19,1854.89,585.23,1854.91,585.27,1854.93z"/>
					</g>
					<g>
						<path className="st112" d="M625.37,1860.12c-0.02,0-0.04,0-0.07-0.01C625.32,1860.12,625.34,1860.12,625.37,1860.12z"/>
					</g>
					<g>
						<path className="st112" d="M584.98,1854.8c-0.03-0.01-0.05-0.02-0.08-0.03C584.92,1854.78,584.95,1854.79,584.98,1854.8z"/>
					</g>
					<g>
						<path className="st112" d="M625.74,1859.3c-0.02-0.01-0.03-0.03-0.05-0.04c-0.01,0-0.01-0.01-0.02-0.01
							C625.69,1859.26,625.71,1859.28,625.74,1859.3z"/>
					</g>
					<g>
						<path className="st112" d="M585.11,1854.85c-0.03-0.01-0.06-0.02-0.08-0.04C585.05,1854.83,585.08,1854.84,585.11,1854.85z"/>
					</g>
					<g>
						<path className="st112" d="M625.67,1859.24C625.67,1859.24,625.67,1859.24,625.67,1859.24c-0.01-0.01-0.02-0.02-0.04-0.03
							c0,0,0,0,0,0C625.64,1859.23,625.66,1859.23,625.67,1859.24z"/>
					</g>
					<g>
						<path className="st112" d="M625.21,1858.75c0.01,0.02,0.02,0.03,0.03,0.05c0,0,0,0,0,0
							C625.23,1858.78,625.22,1858.77,625.21,1858.75C625.21,1858.75,625.21,1858.75,625.21,1858.75z"/>
					</g>
					<g>
						<path className="st112" d="M625.29,1858.88C625.29,1858.88,625.29,1858.88,625.29,1858.88c-0.01-0.01-0.02-0.02-0.02-0.04
							c0,0,0,0,0,0C625.28,1858.85,625.28,1858.86,625.29,1858.88z"/>
					</g>
					<g>
						<path className="st112" d="M625.37,1858.97C625.37,1858.97,625.37,1858.97,625.37,1858.97c-0.01-0.01-0.01-0.02-0.02-0.03
							c0,0,0,0,0,0C625.35,1858.95,625.36,1858.96,625.37,1858.97z"/>
					</g>
					<g>
						<path className="st112" d="M625.47,1859.07C625.47,1859.07,625.47,1859.07,625.47,1859.07c-0.01-0.01-0.01-0.01-0.02-0.02
							c0,0,0,0,0,0C625.45,1859.06,625.46,1859.07,625.47,1859.07z"/>
					</g>
					<g>
						<path className="st112" d="M625.57,1859.16C625.57,1859.16,625.57,1859.16,625.57,1859.16c-0.01-0.01-0.02-0.02-0.03-0.03
							c0,0,0,0,0,0C625.55,1859.15,625.56,1859.15,625.57,1859.16z"/>
					</g>
					<g>
						<path className="st112" d="M625.63,1859.22C625.63,1859.22,625.63,1859.22,625.63,1859.22c-0.02-0.02-0.04-0.03-0.06-0.05
							c0,0,0,0,0,0C625.59,1859.18,625.61,1859.2,625.63,1859.22z"/>
					</g>
					<g>
						<path className="st112" d="M625.54,1859.14C625.54,1859.14,625.54,1859.14,625.54,1859.14c-0.02-0.02-0.05-0.04-0.07-0.07
							c0,0,0,0,0,0C625.49,1859.09,625.51,1859.11,625.54,1859.14z"/>
					</g>
					<g>
						<path className="st112" d="M625.69,1859.26c-0.01,0-0.01-0.01-0.02-0.01c0,0,0,0,0,0
							C625.67,1859.25,625.68,1859.25,625.69,1859.26z"/>
					</g>
					<g>
						<path className="st112" d="M625.34,1858.94C625.35,1858.94,625.35,1858.94,625.34,1858.94c-0.02-0.02-0.03-0.04-0.05-0.07
							c0,0,0,0,0,0C625.31,1858.9,625.33,1858.92,625.34,1858.94z"/>
					</g>
					<g>
						<path className="st112" d="M625.45,1859.05C625.45,1859.05,625.45,1859.05,625.45,1859.05c-0.03-0.03-0.05-0.06-0.08-0.09
							c0,0,0,0,0,0C625.39,1859,625.42,1859.03,625.45,1859.05z"/>
					</g>
					<g>
						<path className="st112" d="M625.27,1858.84C625.27,1858.84,625.27,1858.84,625.27,1858.84c-0.01-0.01-0.02-0.03-0.03-0.04
							c0,0,0,0,0,0C625.25,1858.81,625.26,1858.83,625.27,1858.84z"/>
					</g>
					<g>
						<path className="st112" d="M625.77,1859.32C625.77,1859.32,625.77,1859.32,625.77,1859.32c-0.01-0.01-0.02-0.02-0.03-0.02
							c0,0,0,0,0,0C625.75,1859.3,625.76,1859.31,625.77,1859.32z"/>
					</g>
					<g>
						<path className="st112" d="M625.74,1859.3c-0.02-0.01-0.03-0.03-0.05-0.04C625.7,1859.27,625.72,1859.28,625.74,1859.3
							C625.74,1859.3,625.74,1859.3,625.74,1859.3z"/>
					</g>
					<g>
						<path className="st112" d="M625.87,1859.39c-0.03-0.02-0.07-0.05-0.1-0.07c0,0,0,0,0,0
							C625.8,1859.34,625.83,1859.37,625.87,1859.39z"/>
					</g>
					<g>
						<path className="st113" d="M773.13,1819.79c-0.03-0.07-0.06-0.14-0.08-0.21c0,0.08,0,0.17,0,0.25
							C773.08,1819.81,773.11,1819.8,773.13,1819.79z"/>
					</g>
					<g>
						<path className="st42" d="M681.02,1820.87c0.33,0.22,0.65,0.44,0.98,0.67c0.13,0.09,0.26,0.17,0.38,0.26
							c0.33,0.23,0.67,0.46,1,0.69c1.85,1.3,3.88,2.38,5.83,3.55c2.93,1.76,5.81,3.58,8.84,5.14c2.29,1.18,4.55,2.42,6.99,3.31
							c1.67,0.61,3.75,2.58,5.56,3.5c1.33-0.22,3.82-0.64,5.03-1.8l1.28-0.73c0,0,1.66-0.56,2.13-0.61c1.52-0.16,2.11-0.21,3.45-1
							c0.38-0.22,3.82-0.87,4.2-0.85c0.77,0.04,5.63-1.78,6.3-2.49c0.67-2.63-9.33-2.47-9.7-2.53c-2.55-0.41-5.01-1.09-7.44-1.97
							c-4.12-1.49-8.41-2.31-12.74-2.95c-3.97-0.59-7.97-1.06-11.87-1.94c-3.93-0.89-7.96-0.52-11.86-1.38
							c0.2,0.14,0.4,0.28,0.6,0.42c0.13,0.09,0.27,0.19,0.4,0.28C680.6,1820.58,680.81,1820.72,681.02,1820.87z"/>
					</g>
					<g>
						<path className="st109" d="M682,1821.53c-0.33-0.22-0.65-0.44-0.98-0.67C681.35,1821.09,681.67,1821.31,682,1821.53z"/>
					</g>
					<g>
						<path className="st109" d="M683.38,1822.48c-0.33-0.23-0.66-0.46-1-0.69C682.72,1822.02,683.05,1822.25,683.38,1822.48z"/>
					</g>
					<g>
						<path className="st109" d="M679.98,1820.16c0.13,0.09,0.27,0.19,0.4,0.28C680.25,1820.34,680.12,1820.25,679.98,1820.16z"/>
					</g>
					<g>
						<path className="st114" d="M727.06,1828.07C727.06,1828.07,727.06,1828.07,727.06,1828.07c0.24,0.54,0.47,1.07,0.71,1.61
							C727.53,1829.14,727.3,1828.61,727.06,1828.07z"/>
					</g>
					<g>
						<path className="st114" d="M727.28,1827.88c-0.07,0.06-0.14,0.12-0.21,0.19c0.09-0.01,0.18-0.02,0.27-0.03
							C727.32,1827.99,727.3,1827.94,727.28,1827.88z"/>
					</g>
					<g>
						<path className="st109" d="M696.68,1847.65c0.5-0.35,1.62-2.65,2.12-3c-0.48,0.28-2.5,1.59-3,1.84c-0.02,0.01-0.05,0.02-0.08,0.02
							c-2.81,0.32-5.68-0.1-8.45,0.78c-2.53,0.81-5.22,1.05-7.81,1.02c-1.06-0.01-2.11,0.03-3.15,0.11
							c-0.19,0.01-0.38,0.03-0.57,0.05c-0.33,0.03-0.66,0.06-0.99,0.1c-0.55,0.06-1.09,0.14-1.64,0.22c-0.64,0.09-1.27,0.2-1.9,0.31
							c-0.42,0.07-0.84,0.15-1.26,0.23c-0.47,0.09-0.95,0.18-1.42,0.28c-0.46,0.09-0.92,0.19-1.38,0.28
							c-0.44,0.09-0.88,0.18-1.32,0.27c-0.17,0.03-0.33,0.07-0.5,0.1c-0.29,0.06-0.58,0.12-0.87,0.17
							c-0.19,0.04-0.38,0.07-0.57,0.11c-0.24,0.05-0.49,0.1-0.73,0.14c-0.08,0.01-0.1,0.1-0.17,0.15c-0.61,0.42-0.76,0.77-1.52,1.08
							c3.35,0.49,6.47,1.18,9.25,2.13c0.52,0.18,1.64,0.72,2.14,0.86c0.85,0.1,3.7,0.4,4.42,0.2c0.1-0.03,0.21-0.06,0.31-0.1
							c0.38-0.13,0.75-0.3,1.11-0.51c1.18-0.68,2.6-0.75,3.87-1.22c0.41-0.15,1.1-0.23,0.18-0.74c-0.37-0.21-0.11-0.41,0.09-0.54
							c0.04-0.03,0.08-0.05,0.12-0.08c0.1-0.05,0.19-0.09,0.3-0.12c0.03-0.01,0.07-0.02,0.1-0.03c0.02,0,0.04-0.01,0.06-0.01
							c0.03,0,0.05-0.01,0.08-0.01c0.02,0,0.04,0,0.07-0.01c0.03,0,0.05-0.01,0.08-0.01c0.18-0.01,0.37-0.05,0.48-0.15
							c0,0,0.01-0.01,0.01-0.01c0.01,0,0.02,0,0.02,0c0.67-0.2,1.81-0.05,2.23-0.15c0.22-0.06,0.27-0.5,0.49-0.55
							s0.45-0.1,0.67-0.14c0.78-0.16,2.92-0.02,3.7-0.18"/>
					</g>
					<g>
						<path className="st111" d="M664.48,1850.44c0.29-0.06,0.58-0.12,0.87-0.17C665.06,1850.32,664.77,1850.38,664.48,1850.44z"/>
					</g>
					<g>
						<path className="st111" d="M665.85,1850.16c0.44-0.09,0.88-0.18,1.32-0.27C666.73,1849.98,666.29,1850.07,665.85,1850.16z"/>
					</g>
					<g>
						<path className="st111" d="M697.64,1845.44c-0.12,0.08-0.24,0.17-0.36,0.25c0.08-0.04,0.15-0.09,0.23-0.13
							c0.05-0.04,0.1-0.08,0.15-0.12C697.65,1845.44,697.65,1845.44,697.64,1845.44z"/>
					</g>
					<g>
						<polygon className="st111" points="691.5,1849.46 691.5,1849.46 691.5,1849.46 						"/>
					</g>
					<g>
						<polygon className="st111" points="691.5,1849.46 691.5,1849.46 691.5,1849.46 						"/>
					</g>
					<g>
						<path className="st115" d="M661.55,1870.93c-0.13-0.25-0.57-0.13-0.59-0.46c-0.01,0-0.02,0-0.02-0.01c-1.3-0.3-2.85-0.13-4.22,0
							c0.14-0.07-1.24-0.32-2.22-0.41c-2.05,0.03-4.1,0.09-6.15,0.17c-1.67,0.06-3.35,0.03-5.02,0.13
							c-0.36,0.02-0.73,0.01-1.11,0.02c-0.09,0-0.17,0.01-0.26,0.01c-0.33,0.02-0.65,0.05-0.96,0.14c-0.39,0.11-0.66,0.34-0.79,0.58
							c-0.25,0.47,0.45,1.01,1.36,1.06c0,0,0,0,0,0c4.86,0.37,10.46,0.82,14.93,1.03c1.72-0.66,3.34-1.56,5.1-2.12
							C661.59,1871.01,661.57,1870.97,661.55,1870.93z"/>
					</g>
					<g>
						<path className="st116" d="M773.05,1819.57c0-0.15,0-0.29,0-0.44c-0.03,0.06-0.06,0.11-0.11,0.16
							C772.98,1819.39,773.01,1819.48,773.05,1819.57z"/>
					</g>
					<g>
						<path className="st116" d="M772.21,1820.33c-0.36,0.18-0.72,0.35-1.08,0.52c-0.36,0.17-0.73,0.34-1.09,0.5
							c-2.2,0.98-4.44,1.84-6.7,2.67c-0.05,0.01-0.11,0.03-0.16,0.05c0.13,0,0.26-0.01,0.39-0.01c-0.13,0-0.26,0-0.39,0.01
							c-0.18,0.05-0.37,0.09-0.62,0.06c-0.04-0.01-0.07-0.01-0.11-0.02c-1.17,0.08-2.35,0.28-3.53,0.55
							c-1.91,0.67-3.84,1.29-5.81,1.85c-2.06,0.78-4.08,1.61-6,2.28c-0.38,0.13-0.8,0.26-1.16,0.09c-0.36-0.18-0.53-0.6-0.84-0.85
							c-0.45-0.37-1.09-0.32-1.67-0.26c-0.34,0.04-0.68,0.08-1.03,0.12c-0.03,0.28-0.29,0.18-0.46,0.24
							c-2.84,1.13-5.86,1.62-8.81,2.35c0.01,0.03,0.02,0.05,0.03,0.08c0.05,0.01,0.1,0.02,0.14,0.02c0.83,0.08,2.48,2.85,3.02,3.57
							c0.99,1.33,1.89,2.81,3.1,3.96c-4.87,0.84-10.21,1.97-15,3.21c-2.35,0.61-4.71,1.15-7.05,1.8c-2.1,0.59-4.93,1.13-6.69,2.42
							c0.9,0.51,0.02,1.53-0.37,2.09c0.03,0,0.06,0,0.08,0c0.8-0.18,1.59-0.1,2.38,0.22c0.7,0.29,1.35,0.7,2.06,0.98
							c1.53,0.62,2.94,0.92,3.79,2.51c0.05,0.09,0.09,0.2,0.04,0.29c-0.04,0.06-0.12,0.08-0.19,0.1c-6.39,1.65-10.8,2.93-16.88,5.49
							c3.32,0.76,4.93,0.83,8.23,1.7c3.26,0.86,6.28,2.61,9.41,3.83c7.31,2.84,17.95,2.37,25.82,2.64
							c0.44,0.02,27.52-1.95,27.96-1.94c0.1-0.04,0.01-1.79,0.01-1.99c0-0.69,0-1.38,0.01-2.07c0-1.38,0.01-2.76,0.01-4.14
							c0.01-2.76,0.02-5.52,0.02-8.27c0.02-5.52,0.03-11.03,0.05-16.55c0-0.72-0.04-1.44-0.04-2.16c0,0-0.04-0.72-0.03-0.72
							c-0.01-2.45,0-4.9,0-7.35c-0.03-0.12-0.07-0.24-0.1-0.36C772.7,1820.02,772.46,1820.17,772.21,1820.33z M741.72,1838.41
							C741.72,1838.41,741.72,1838.41,741.72,1838.41c-0.01-0.03-0.02-0.05-0.04-0.08c0,0,0,0,0,0c0-0.01-0.01-0.01-0.01-0.02
							c0,0,0,0,0,0c-0.01-0.02-0.02-0.03-0.03-0.04c-0.01-0.01-0.01-0.02-0.02-0.03c0-0.01-0.01-0.01-0.02-0.02
							c0-0.01-0.01-0.01-0.01-0.02c0,0,0,0,0,0c-0.01-0.01-0.02-0.02-0.02-0.02c-0.01-0.01-0.02-0.02-0.03-0.03
							c0,0-0.01-0.01-0.01-0.01c0,0-0.01-0.01-0.01-0.01c0,0-0.01-0.01-0.01-0.01c-0.01-0.01-0.01-0.01-0.02-0.01c0,0,0,0-0.01-0.01
							c0,0,0,0,0,0c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01-0.01-0.02-0.02-0.03-0.03c-0.01-0.01-0.02-0.01-0.03-0.02
							c-0.01-0.01-0.02-0.02-0.04-0.02c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01-0.01-0.03-0.01-0.04-0.02
							c-0.01-0.01-0.02-0.01-0.03-0.02c-0.01-0.01-0.03-0.01-0.04-0.02c-0.01-0.01-0.02-0.01-0.03-0.02
							c-0.01-0.01-0.03-0.01-0.04-0.02c-0.01-0.01-0.02-0.01-0.03-0.01c-0.01-0.01-0.03-0.01-0.04-0.02c-0.01,0-0.02-0.01-0.03-0.01
							c-0.02-0.01-0.03-0.01-0.05-0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.02-0.01-0.03-0.01-0.05-0.02c-0.01,0-0.02-0.01-0.03-0.01
							c-0.02-0.01-0.04-0.02-0.07-0.02c-0.03-0.01-0.06-0.02-0.1-0.03c-0.07-0.01-0.14-0.01-0.21-0.01c-0.08,0-0.16,0-0.24,0
							c-0.01,0-0.03,0-0.04,0c-0.08,0-0.16,0.01-0.24,0.02c-0.01,0-0.02,0-0.03,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0
							c-0.15,0.02-0.31,0.05-0.46,0.08c0,0-0.01,0-0.01,0c-0.06,0.01-0.13,0.03-0.19,0.05c0.06-0.02,0.13-0.03,0.19-0.05
							c0,0,0,0,0.01,0c0,0,0.01,0,0.01,0c0.05-0.01,0.1-0.02,0.15-0.03c0.11-0.02,0.23-0.04,0.34-0.05c0.01,0,0.01,0,0.02,0
							c0,0,0.01,0,0.01,0c0,0,0,0,0,0c0,0,0,0,0,0c0.08-0.01,0.16-0.02,0.23-0.02c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0,0.03,0,0.04,0
							c0,0,0,0,0,0c0.08,0,0.16,0,0.24,0c0.07,0,0.14,0.01,0.21,0.01c0.03,0.01,0.07,0.02,0.1,0.03c0.02,0.01,0.04,0.02,0.06,0.02
							c0.01,0,0.02,0.01,0.03,0.01c0.02,0.01,0.03,0.01,0.05,0.02c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0.01c0,0,0.01,0,0.01,0
							c0.02,0.01,0.03,0.01,0.05,0.02c0,0,0,0,0,0c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0.01,0c0,0,0,0,0,0
							c0.01,0.01,0.02,0.01,0.04,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0,0,0.01,0,0.01,0
							c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0,0.02,0.01,0.03,0.01c0,0,0,0,0,0s0,0,0,0
							c0.01,0.01,0.03,0.01,0.04,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0,0.02,0.01,0.02,0.01c0,0,0.01,0,0.01,0c0,0,0,0,0,0
							c0.01,0.01,0.02,0.01,0.04,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.01,0.02,0.01,0.03,0.02c0,0,0,0,0,0
							c0.01,0.01,0.02,0.02,0.04,0.02c0.01,0.01,0.02,0.01,0.03,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
							c0.01,0.01,0.02,0.02,0.03,0.02c0,0,0,0,0,0c0.01,0.01,0.02,0.01,0.03,0.02c0,0,0.01,0.01,0.01,0.01
							c0.01,0.01,0.01,0.01,0.02,0.02c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.01,0.02,0.01,0.02,0.02c0,0,0,0,0,0
							c0.01,0.01,0.02,0.02,0.03,0.03c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0.01,0.01,0.01,0.02,0.02c0.01,0.01,0.02,0.02,0.03,0.04
							c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.01c0.01,0.01,0.01,0.01,0.01,0.02c0,0,0,0,0,0c0,0,0,0,0,0
							c0.01,0.01,0.02,0.03,0.03,0.04c0,0,0,0,0,0c0,0,0,0.01,0.01,0.01c0,0,0.01,0.01,0.01,0.01c0,0,0,0,0,0
							C741.69,1838.36,741.71,1838.39,741.72,1838.41C741.72,1838.41,741.72,1838.41,741.72,1838.41c0.01,0.03,0.02,0.06,0.03,0.09
							C741.74,1838.47,741.73,1838.44,741.72,1838.41z M772.94,1828.3C772.94,1828.3,772.94,1828.3,772.94,1828.3
							C772.94,1828.3,772.94,1828.3,772.94,1828.3l0.07-0.01C773.01,1828.29,772.94,1828.3,772.94,1828.3z"/>
					</g>
					<g>
						<polygon className="st116" points="739.45,1837.83 739.44,1837.83 739.45,1837.83 						"/>
					</g>
					<g>
						<path className="st116" d="M739.45,1837.83c0.15-0.03,0.31-0.06,0.46-0.08C739.75,1837.77,739.6,1837.8,739.45,1837.83
							C739.45,1837.83,739.45,1837.83,739.45,1837.83z"/>
					</g>
					<g>
						<polygon className="st116" points="741.51,1838.13 741.52,1838.14 741.51,1838.13 						"/>
					</g>
					<g>
						<path className="st116" d="M733.15,1830.47c-0.05,0.01-0.11,0.03-0.16,0.04c0.07,0.01,0.13,0.03,0.19,0.04
							C733.17,1830.52,733.16,1830.49,733.15,1830.47z"/>
					</g>
					<g>
						<path className="st116" d="M739.43,1837.83c0,0,0.01,0,0.01,0C739.44,1837.83,739.44,1837.83,739.43,1837.83
							C739.44,1837.83,739.43,1837.83,739.43,1837.83z"/>
					</g>
					<g>
						<path className="st116" d="M762.28,1824.08c0.03,0,0.06,0,0.09,0c0,0,0,0-0.01,0C762.33,1824.08,762.31,1824.08,762.28,1824.08z"
							/>
					</g>
					<g>
						<path className="st116" d="M773.03,1863.47l-20.7,4.16C759.37,1867.18,766.36,1865.78,773.03,1863.47z"/>
					</g>
					<g>
						<path className="st116" d="M772.95,1819.87C772.95,1819.87,772.95,1819.87,772.95,1819.87c0.03,0.12,0.06,0.24,0.1,0.36
							c0-0.14,0-0.27,0-0.41C773.02,1819.84,772.98,1819.85,772.95,1819.87z"/>
					</g>
					<g>
						<path className="st116" d="M741.51,1838.13C741.51,1838.13,741.51,1838.13,741.51,1838.13c0,0,0.01,0.01,0.01,0.01
							c0,0,0.01,0.01,0.01,0.01C741.53,1838.14,741.52,1838.13,741.51,1838.13z"/>
					</g>
					<g>
						<path className="st116" d="M741.35,1838.01C741.35,1838.01,741.35,1838.01,741.35,1838.01c-0.01-0.01-0.02-0.01-0.03-0.02
							c0,0,0,0,0,0C741.33,1837.99,741.34,1838,741.35,1838.01z"/>
					</g>
					<g>
						<polygon className="st116" points="741.67,1838.31 741.67,1838.31 741.67,1838.32 741.67,1838.33 						"/>
					</g>
					<g>
						<polygon className="st116" points="740.24,1837.72 740.24,1837.72 740.24,1837.72 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.5,1838.12 741.51,1838.13 741.51,1838.13 						"/>
					</g>
					<g>
						<path className="st116" d="M741.67,1838.31c-0.01-0.02-0.02-0.03-0.03-0.04c0,0,0,0,0,0
							C741.65,1838.28,741.66,1838.3,741.67,1838.31L741.67,1838.31z"/>
					</g>
					<g>
						<path className="st116" d="M741.64,1838.27c0.01,0.01,0.02,0.03,0.03,0.04c0,0,0,0,0,0
							C741.66,1838.3,741.65,1838.28,741.64,1838.27z"/>
					</g>
					<g>
						<path className="st116" d="M762.56,1824.12c0.25,0.04,0.44-0.01,0.62-0.06c0,0,0,0,0,0
							C762.99,1824.12,762.8,1824.16,762.56,1824.12z"/>
					</g>
					<g>
						<path className="st116" d="M763.17,1824.06C763.17,1824.06,763.17,1824.06,763.17,1824.06c0.06-0.02,0.11-0.03,0.16-0.05
							c2.26-0.83,4.5-1.69,6.7-2.67c-2.2,0.98-4.44,1.84-6.7,2.67C763.28,1824.03,763.23,1824.05,763.17,1824.06z"/>
					</g>
					<g>
						<path className="st116" d="M772.95,1819.87C772.95,1819.87,772.95,1819.87,772.95,1819.87c0.03-0.01,0.07-0.03,0.1-0.04l0,0
							C773.02,1819.84,772.98,1819.85,772.95,1819.87z"/>
					</g>
					<g>
						<polygon className="st116" points="741.45,1838.08 741.45,1838.07 741.45,1838.07 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.28,1837.97 741.28,1837.97 741.28,1837.97 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.35,1838.01 741.35,1838.01 741.35,1838.01 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.32,1837.99 741.32,1837.99 741.32,1837.99 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.32,1837.99 741.32,1837.99 741.32,1837.99 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.62,1838.24 741.62,1838.24 741.62,1838.24 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.25,1837.95 741.25,1837.95 741.25,1837.95 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.51,1838.13 741.51,1838.13 741.51,1838.13 741.51,1838.13 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.51,1838.13 741.51,1838.13 741.51,1838.13 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.06,1837.86 741.05,1837.86 741.06,1837.86 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.67,1838.31 741.67,1838.31 741.67,1838.31 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.67,1838.31 741.67,1838.31 741.67,1838.31 741.67,1838.31 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.1,1837.88 741.1,1837.88 741.1,1837.88 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.17,1837.91 741.17,1837.91 741.17,1837.91 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.21,1837.93 741.21,1837.93 741.21,1837.93 						"/>
					</g>
					<g>
						<path className="st116" d="M733.83,1831.68c-0.06-0.08-0.11-0.16-0.17-0.24c-0.19-0.28-0.37-0.57-0.48-0.89
							c-0.06-0.01-0.12-0.02-0.19-0.04c-0.31,0.08-0.63,0.17-0.94,0.25c1.33,2.74,4.26,5.02,6.56,7.1c0.05,0.02,0.11,0.03,0.17,0.04
							c-0.06-0.01-0.11-0.03-0.17-0.04c0.1,0.09,0.19,0.18,0.29,0.26c-9.72,1.6-19.32,3.94-28.68,7.01c0.67,0.68,0.5,1.99-0.32,2.47
							c0.14,0,0.28,0.01,0.43,0.01c0.39-0.56,1.27-1.58,0.37-2.09c1.76-1.3,4.6-1.84,6.69-2.42c2.34-0.65,4.7-1.2,7.05-1.8
							c4.79-1.24,10.12-2.37,15-3.21c-1.2-1.14-2.11-2.62-3.1-3.96c-0.54-0.72-2.19-3.49-3.02-3.57c-0.04,0-0.09-0.01-0.14-0.02
							c0.11,0.32,0.29,0.61,0.48,0.89C733.72,1831.52,733.78,1831.6,733.83,1831.68z"/>
					</g>
					<g>
						<path className="st116" d="M719.24,1862.76c-3.13-1.22-6.15-2.97-9.41-3.83c-3.3-0.87-4.91-0.94-8.23-1.7
							c6.08-2.56,10.49-3.84,16.88-5.49c0.07-0.02,0.15-0.04,0.19-0.1c0.06-0.09,0.01-0.2-0.04-0.29c-0.85-1.59-2.27-1.89-3.79-2.51
							c-0.7-0.29-1.36-0.69-2.06-0.98c-0.79-0.32-1.57-0.4-2.38-0.22c2.94,0.17,5.79,1.59,7.67,3.85
							c-5.2,1.39-10.35,2.97-15.43,4.74c-0.73,0.25-1.52,0.24-2.23,0.57c-0.27,0.13-0.77,0.39-0.32,0.66
							c0.42,0.26,1.02,0.19,1.48,0.31c0.63,0.17,0.76,0.54,1.39,0.71c1.26,0.35,3.01,0.52,4.26,0.89c2.5,0.74,4.99,1.52,7.48,2.29
							c4.97,1.53,9.97,3.03,15.05,4.15c2.06,0.45,4.13,0.85,6.21,1.16c5.41,0.8,10.9,1.02,16.36,0.68l20.7-4.16
							c-0.44-0.01-27.52,1.95-27.96,1.94C737.19,1865.13,726.54,1865.61,719.24,1862.76z"/>
					</g>
					<g>
						<path className="st116" d="M763.17,1824.07c-0.05,0-0.09,0-0.14,0c-0.19,0.01-0.39,0.02-0.58,0.03c0.04,0.01,0.08,0.02,0.11,0.02
							C762.8,1824.16,762.99,1824.12,763.17,1824.07z"/>
					</g>
					<g>
						<path className="st116" d="M762.36,1824.08C762.36,1824.08,762.37,1824.08,762.36,1824.08c0.23,0,0.45-0.01,0.67-0.01
							c0.05,0,0.1,0,0.14,0c0.05-0.02,0.11-0.03,0.16-0.05c2.26-0.83,4.5-1.69,6.7-2.67c0.37-0.16,0.73-0.33,1.09-0.5
							c0.36-0.17,0.72-0.34,1.08-0.52c0.25-0.15,0.49-0.31,0.74-0.46l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.03-0.01,0.07-0.03,0.1-0.04
							c0-0.08,0-0.17,0-0.25c-0.04-0.09-0.07-0.19-0.11-0.28C770.5,1820.37,765.11,1824,762.36,1824.08z"/>
					</g>
					<g>
						<path className="st116" d="M772.21,1820.33c0.25-0.15,0.49-0.31,0.74-0.46l0,0C772.7,1820.02,772.46,1820.17,772.21,1820.33z"/>
					</g>
					<g>
						<polygon className="st116" points="772.94,1828.3 772.94,1828.3 773.01,1828.29 772.94,1828.3 						"/>
					</g>
					<g>
						<rect x="772.95" y="1819.87" className="st116" width="0" height="0"/>
					</g>
					<g>
						<path className="st116" d="M741.72,1838.41c-0.01-0.02-0.02-0.05-0.03-0.07c0,0,0,0,0,0
							C741.69,1838.36,741.71,1838.39,741.72,1838.41z"/>
					</g>
					<g>
						<path className="st116" d="M739.45,1837.83L739.45,1837.83c0.15-0.03,0.31-0.06,0.46-0.08l0,0
							C739.75,1837.77,739.6,1837.8,739.45,1837.83z"/>
					</g>
					<g>
						<path className="st116" d="M739.91,1837.75c0,0,0.01,0,0.01,0C739.92,1837.75,739.91,1837.75,739.91,1837.75
							C739.91,1837.75,739.91,1837.75,739.91,1837.75z"/>
					</g>
					<g>
						<polygon className="st116" points="739.44,1837.83 739.45,1837.83 739.45,1837.83 						"/>
					</g>
					<g>
						<path className="st116" d="M741.28,1837.97C741.28,1837.97,741.28,1837.97,741.28,1837.97c0.01,0.01,0.02,0.01,0.04,0.02
							c0,0,0,0,0,0C741.3,1837.98,741.29,1837.97,741.28,1837.97z"/>
					</g>
					<g>
						<path className="st116" d="M741.32,1837.99C741.32,1837.99,741.32,1837.99,741.32,1837.99c-0.01-0.01-0.02-0.01-0.04-0.02l0,0
							C741.29,1837.97,741.3,1837.98,741.32,1837.99z"/>
					</g>
					<g>
						<path className="st116" d="M741.35,1838.01C741.35,1838.01,741.35,1838.01,741.35,1838.01c0.01,0.01,0.02,0.02,0.04,0.02
							c0,0,0,0,0,0C741.37,1838.02,741.36,1838.01,741.35,1838.01z"/>
					</g>
					<g>
						<path className="st116" d="M741.41,1838.05c-0.01-0.01-0.02-0.01-0.03-0.02c0,0,0,0,0,0
							C741.39,1838.04,741.4,1838.04,741.41,1838.05z"/>
					</g>
					<g>
						<polygon className="st116" points="741.41,1838.05 741.41,1838.05 741.41,1838.05 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.41,1838.05 741.41,1838.05 741.41,1838.05 						"/>
					</g>
					<g>
						<path className="st116" d="M741.42,1838.05C741.42,1838.05,741.42,1838.05,741.42,1838.05
							C741.41,1838.05,741.41,1838.05,741.42,1838.05c0.01,0.01,0.02,0.01,0.03,0.02c0,0,0,0,0,0
							C741.44,1838.07,741.43,1838.06,741.42,1838.05z"/>
					</g>
					<g>
						<path className="st116" d="M741.45,1838.07c-0.01-0.01-0.02-0.02-0.03-0.02C741.43,1838.06,741.44,1838.07,741.45,1838.07
							C741.45,1838.07,741.45,1838.07,741.45,1838.07z"/>
					</g>
					<g>
						<path className="st116" d="M741.48,1838.1c0.01,0,0.01,0.01,0.02,0.01C741.5,1838.11,741.49,1838.11,741.48,1838.1
							C741.49,1838.11,741.49,1838.1,741.48,1838.1z"/>
					</g>
					<g>
						<path className="st116" d="M741.51,1838.13c-0.01-0.01-0.01-0.01-0.02-0.02C741.49,1838.11,741.5,1838.11,741.51,1838.13
							C741.5,1838.12,741.51,1838.12,741.51,1838.13C741.51,1838.13,741.51,1838.13,741.51,1838.13z"/>
					</g>
					<g>
						<polygon className="st116" points="741.48,1838.1 741.48,1838.1 741.48,1838.1 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.48,1838.1 741.48,1838.1 741.48,1838.1 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.51,1838.13 741.51,1838.13 741.51,1838.13 						"/>
					</g>
					<g>
						<path className="st116" d="M741.05,1837.86C741.05,1837.86,741.05,1837.86,741.05,1837.86c-0.01,0-0.02-0.01-0.03-0.01
							c0,0,0,0,0,0C741.03,1837.85,741.04,1837.86,741.05,1837.86z"/>
					</g>
					<g>
						<polygon className="st116" points="741.02,1837.85 741.02,1837.85 741.02,1837.85 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.05,1837.86 741.05,1837.86 741.05,1837.86 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.28,1837.97 741.27,1837.96 741.28,1837.96 						"/>
					</g>
					<g>
						<path className="st116" d="M741.27,1837.96c-0.01,0-0.02-0.01-0.02-0.01c0,0,0,0,0,0
							C741.26,1837.95,741.26,1837.96,741.27,1837.96z"/>
					</g>
					<g>
						<polygon className="st116" points="741.25,1837.95 741.25,1837.95 741.25,1837.95 						"/>
					</g>
					<g>
						<path className="st116" d="M741.53,1838.15C741.53,1838.15,741.53,1838.15,741.53,1838.15c0.01,0.01,0.02,0.02,0.03,0.03
							c0,0,0,0,0,0C741.56,1838.17,741.55,1838.16,741.53,1838.15z"/>
					</g>
					<g>
						<polygon className="st116" points="740.95,1837.82 740.94,1837.82 740.94,1837.82 						"/>
					</g>
					<g>
						<path className="st116" d="M740.96,1837.83c0,0-0.01,0-0.01-0.01C740.95,1837.82,740.96,1837.82,740.96,1837.83
							C740.96,1837.83,740.96,1837.83,740.96,1837.83z"/>
					</g>
					<g>
						<path className="st116" d="M740.97,1837.83c0,0-0.01,0-0.01,0c0,0,0,0,0,0C740.97,1837.83,740.97,1837.83,740.97,1837.83z"/>
					</g>
					<g>
						<path className="st116" d="M741.13,1837.89C741.13,1837.89,741.13,1837.89,741.13,1837.89c-0.01,0-0.02-0.01-0.03-0.01
							c0,0,0,0,0,0C741.11,1837.88,741.12,1837.89,741.13,1837.89z"/>
					</g>
					<g>
						<polygon className="st116" points="741.1,1837.88 741.1,1837.88 741.1,1837.88 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.13,1837.89 741.13,1837.89 741.13,1837.89 						"/>
					</g>
					<g>
						<path className="st116" d="M741.21,1837.93C741.21,1837.93,741.21,1837.93,741.21,1837.93c-0.01-0.01-0.02-0.01-0.03-0.01
							c0,0,0,0,0,0C741.18,1837.92,741.2,1837.92,741.21,1837.93z"/>
					</g>
					<g>
						<polygon className="st116" points="741.17,1837.91 741.17,1837.91 741.17,1837.91 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.21,1837.93 741.21,1837.93 741.21,1837.93 						"/>
					</g>
					<g>
						<path className="st116" d="M741.06,1837.86C741.06,1837.86,741.05,1837.86,741.06,1837.86c0.01,0,0.03,0.01,0.04,0.02c0,0,0,0,0,0
							C741.08,1837.87,741.07,1837.87,741.06,1837.86z"/>
					</g>
					<g>
						<path className="st116" d="M741.1,1837.88C741.1,1837.88,741.1,1837.88,741.1,1837.88c-0.01-0.01-0.03-0.01-0.04-0.02c0,0,0,0,0,0
							C741.07,1837.87,741.08,1837.87,741.1,1837.88z"/>
					</g>
					<g>
						<path className="st116" d="M741.21,1837.93C741.21,1837.93,741.21,1837.93,741.21,1837.93c0.01,0.01,0.03,0.01,0.04,0.02
							c0,0,0,0,0,0C741.23,1837.94,741.22,1837.94,741.21,1837.93z"/>
					</g>
					<g>
						<path className="st116" d="M741.25,1837.95C741.25,1837.95,741.25,1837.95,741.25,1837.95c-0.01-0.01-0.03-0.01-0.04-0.02
							c0,0,0,0,0,0C741.22,1837.94,741.23,1837.94,741.25,1837.95z"/>
					</g>
					<g>
						<path className="st116" d="M741.14,1837.9C741.14,1837.9,741.14,1837.9,741.14,1837.9c-0.01,0-0.01,0-0.01,0
							c0.01,0.01,0.03,0.01,0.04,0.02c0,0,0,0,0,0C741.16,1837.91,741.15,1837.9,741.14,1837.9z"/>
					</g>
					<g>
						<path className="st116" d="M741.17,1837.91c-0.01,0-0.02-0.01-0.03-0.01C741.15,1837.9,741.16,1837.91,741.17,1837.91
							C741.17,1837.91,741.17,1837.91,741.17,1837.91z"/>
					</g>
					<g>
						<path className="st116" d="M740.91,1837.81c-0.01,0-0.02-0.01-0.03-0.01c0.02,0.01,0.03,0.01,0.05,0.02c0,0,0,0,0,0
							C740.93,1837.81,740.92,1837.81,740.91,1837.81z"/>
					</g>
					<g>
						<path className="st116" d="M740.98,1837.83c0,0-0.01,0-0.01,0c0.02,0.01,0.03,0.01,0.05,0.02c0,0,0,0,0,0
							C741.01,1837.84,740.99,1837.84,740.98,1837.83z"/>
					</g>
					<g>
						<path className="st116" d="M741.68,1838.34c0,0-0.01-0.01-0.01-0.01C741.67,1838.33,741.68,1838.33,741.68,1838.34
							C741.68,1838.34,741.68,1838.34,741.68,1838.34C741.68,1838.34,741.68,1838.34,741.68,1838.34z"/>
					</g>
					<g>
						<polygon className="st116" points="741.67,1838.31 741.67,1838.32 741.67,1838.31 						"/>
					</g>
					<g>
						<path className="st116" d="M741.67,1838.32c0,0.01,0.01,0.01,0.01,0.02C741.68,1838.33,741.67,1838.33,741.67,1838.32
							C741.67,1838.32,741.67,1838.32,741.67,1838.32z"/>
					</g>
					<g>
						<path className="st116" d="M741.59,1838.21c-0.01-0.01-0.01-0.02-0.02-0.02C741.57,1838.19,741.58,1838.2,741.59,1838.21
							C741.59,1838.21,741.59,1838.21,741.59,1838.21z"/>
					</g>
					<g>
						<polygon className="st116" points="741.57,1838.18 741.57,1838.18 741.57,1838.18 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.57,1838.18 741.57,1838.18 741.57,1838.18 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.72,1838.41 741.72,1838.41 741.72,1838.41 						"/>
					</g>
					<g>
						<path className="st116" d="M741.62,1838.24c0-0.01-0.01-0.01-0.01-0.02C741.61,1838.23,741.61,1838.24,741.62,1838.24
							C741.62,1838.24,741.62,1838.24,741.62,1838.24C741.62,1838.24,741.62,1838.24,741.62,1838.24z"/>
					</g>
					<g>
						<polygon className="st116" points="741.62,1838.24 741.62,1838.24 741.62,1838.24 						"/>
					</g>
					<g>
						<path className="st116" d="M741.59,1838.21c0,0.01,0.01,0.01,0.01,0.02C741.6,1838.22,741.59,1838.21,741.59,1838.21
							L741.59,1838.21z"/>
					</g>
					<g>
						<polygon className="st116" points="741.59,1838.21 741.59,1838.21 741.59,1838.21 						"/>
					</g>
					<g>
						<polygon className="st116" points="741.62,1838.25 741.62,1838.24 741.62,1838.24 						"/>
					</g>
					<g>
						<path className="st116" d="M741.62,1838.25c0.01,0.01,0.01,0.01,0.01,0.02c0,0,0,0,0,0
							C741.63,1838.26,741.63,1838.26,741.62,1838.25z"/>
					</g>
					<g>
						<path className="st116" d="M741.68,1838.34C741.68,1838.34,741.68,1838.34,741.68,1838.34c0.01,0.02,0.03,0.05,0.04,0.07
							c0,0,0,0,0,0C741.71,1838.39,741.69,1838.36,741.68,1838.34z"/>
					</g>
					<g>
						<path className="st116" d="M733.18,1830.55C733.18,1830.55,733.18,1830.55,733.18,1830.55c-0.01-0.03-0.02-0.05-0.03-0.08
							c0,0,0,0,0,0C733.16,1830.49,733.17,1830.52,733.18,1830.55z"/>
					</g>
					<g>
						<path className="st116" d="M733.66,1831.44c-0.19-0.28-0.37-0.57-0.48-0.89c0,0,0,0,0,0
							C733.29,1830.87,733.47,1831.16,733.66,1831.44z"/>
					</g>
					<g>
						<polygon className="st116" points="740.2,1837.72 740.2,1837.72 740.2,1837.72 740.2,1837.72 						"/>
					</g>
					<g>
						<polygon className="st116" points="740.2,1837.72 740.2,1837.72 740.2,1837.72 						"/>
					</g>
					<g>
						<polygon className="st116" points="740.24,1837.72 740.24,1837.72 740.24,1837.72 740.24,1837.72 						"/>
					</g>
					<g>
						<polygon className="st116" points="740.24,1837.72 740.24,1837.72 740.24,1837.72 						"/>
					</g>
					<g>
						<polygon className="st116" points="739.97,1837.74 739.97,1837.74 739.97,1837.74 						"/>
					</g>
					<g>
						<path className="st116" d="M740.2,1837.72L740.2,1837.72c-0.08,0-0.16,0.01-0.23,0.02c0,0,0,0,0,0
							C740.05,1837.73,740.12,1837.73,740.2,1837.72z"/>
					</g>
					<g>
						<polygon className="st116" points="740.2,1837.72 740.2,1837.72 740.2,1837.72 						"/>
					</g>
					<g>
						<polygon className="st116" points="739.97,1837.74 739.97,1837.74 739.97,1837.74 						"/>
					</g>
					<g>
						<path className="st116" d="M739.94,1837.74C739.94,1837.74,739.94,1837.74,739.94,1837.74c0.01,0,0.02,0,0.03,0c0,0,0,0,0,0
							C739.96,1837.74,739.95,1837.74,739.94,1837.74z"/>
					</g>
					<g>
						<path className="st116" d="M739.97,1837.74c0,0-0.01,0-0.01,0c-0.01,0-0.01,0-0.02,0
							C739.95,1837.74,739.96,1837.74,739.97,1837.74z"/>
					</g>
					<g>
						<polygon className="st116" points="739.97,1837.74 739.96,1837.74 739.97,1837.74 						"/>
					</g>
					<g>
						<path className="st116" d="M740.2,1837.72C740.2,1837.72,740.2,1837.72,740.2,1837.72l0.03,0
							C740.23,1837.72,740.21,1837.72,740.2,1837.72z"/>
					</g>
					<g>
						<path className="st116" d="M740.24,1837.72L740.24,1837.72c-0.01,0-0.02,0-0.03,0c0,0,0,0,0,0
							C740.21,1837.72,740.23,1837.72,740.24,1837.72z"/>
					</g>
					<g>
						<path className="st116" d="M739.91,1837.75c0.01,0,0.01,0,0.02,0C739.92,1837.75,739.91,1837.75,739.91,1837.75
							C739.91,1837.75,739.91,1837.75,739.91,1837.75z"/>
					</g>
					<g>
						<path className="st116" d="M739.91,1837.75C739.91,1837.75,739.91,1837.75,739.91,1837.75c-0.15,0.02-0.31,0.05-0.46,0.08l0,0
							C739.6,1837.8,739.75,1837.77,739.91,1837.75z"/>
					</g>
					<g>
						<polygon className="st116" points="739.45,1837.83 739.44,1837.83 739.45,1837.83 						"/>
					</g>
					<g>
						<path className="st116" d="M739.92,1837.75c0.01,0,0.01,0,0.02,0l0,0C739.93,1837.75,739.93,1837.75,739.92,1837.75z"/>
					</g>
					<g>
						<polygon className="st116" points="739.91,1837.75 739.91,1837.75 739.91,1837.75 739.91,1837.75 						"/>
					</g>
					<g>
						<polygon className="st116" points="739.94,1837.74 739.94,1837.74 739.94,1837.74 						"/>
					</g>
					<g>
						<path className="st109" d="M676.6,1865.4c-0.97,0.32-0.53-0.64-1.48-0.58c-0.34,0.02-0.86-0.19-1.01-0.6
							c-0.16-0.42-1.9,0.17-1.68-0.09c0.11-0.13-0.64-0.2-0.51-0.31c0.08-0.07-1.79-0.04-1.72-0.11c0.19-0.19-0.95,0.63-0.93,0.31
							c0,0,0.4-0.98,2.94-2.3c-2.58,0.91-5.22,1.46-8.79,1.72c-6.52,0.48-16.32,0.07-22.98,1.32c0.2,0.19,0.44,0.29,0.71,0.35
							c0.05,0.01,0.1,0.02,0.15,0.03c0.19,0.03,0.48-0.05,0.49,0.21c0,0,0,0.01,0,0.01c0.64,0.38,1.31,1.05,1.93,1.12
							c0.28,0.03,0.55,0.06,0.83,0.09c2.93,0.29,5.83,0.77,8.73,1.29c0.6,0.11,1.19,0.25,1.78,0.4c1.17,0.3,2.33,0.65,3.51,0.91
							c0.02,0,0.04,0.01,0.06,0.01c0.1,0.02,0.2,0.04,0.3,0.06c0,0,0,0,0,0c1.29,0.27,2.56,0.53,3.88,0.53c0.08,0,0.16,0,0.24-0.01
							c0.17-0.21,0.4-0.24,0.63-0.24c-0.23,0-0.46,0.03-0.63,0.24c0,0,0,0,0.01,0c0.03-0.01,0.24-0.06,0.44-0.11
							c0.23-0.06,0.48-0.07,0.71-0.03c0,0,0.01,0,0.01,0c2.57-0.76,5.21-1.27,7.79-1.97c2.01-0.54,4.1-0.86,6.02-1.67
							C677.55,1865.67,677.23,1865.2,676.6,1865.4z"/>
					</g>
					<g>
						<path className="st110" d="M663.66,1869.52c-0.23,0-0.46,0.03-0.63,0.24h0C663.2,1869.55,663.42,1869.52,663.66,1869.52z"/>
					</g>
					<g>
						<path className="st109" d="M685.08,1860.21c-1.92,0.81-11.09,6.91-13.09,7.45c-2.59,0.7-5.22,1.21-7.79,1.97
							c0.71,0.12,1.18,0.21,1.79,0.25c0.62-0.05,1.23-0.16,1.82-0.34c0.97-0.3,1.86-0.83,2.83-1.11c0.83-0.24,1.7-0.21,2.54-0.42
							c0.85-0.21,1.77-0.76,2.53-1.16c0.75-0.4,1.6-0.22,2.36-0.35c0.16-0.04,1.69-0.21,1.85-0.26
							C679.76,1866.2,685.22,1860.31,685.08,1860.21z"/>
					</g>
					<g>
						<polygon className="st110" points="679.19,1865.96 679.19,1865.96 679.19,1865.96 						"/>
					</g>
					<g>
						<path className="st117" d="M670.25,1872.41c-0.12-0.39,0.28-0.74,0.31-1.15c0.03-0.37-0.23-0.63-0.58-0.8
							c-2.92,0.18-6.1,0.12-9.01,0.01c0.03,0.33,0.46,0.21,0.59,0.46c0.02,0.04,0.03,0.08,0.03,0.13c-1.76,0.56-3.38,1.46-5.1,2.12
							c5.09,0.23,10.06,0.38,14.91,0.45c-0.01-0.08-0.03-0.17-0.07-0.28c-0.03-0.08,0.13,0.11,0.35,0.28c0.31,0,0.63,0.01,0.94,0.01
							c0.23-0.13,0.4-0.1,0.53,0.01c0.18,0,0.65-0.1,0.83-0.09c0-0.01,0-0.01,0-0.02C672.37,1873.41,670.47,1873.12,670.25,1872.41z
							"/>
					</g>
					<g>
						<path className="st117" d="M672.75,1873.04c-0.65,0-1.11-0.15-1.76-0.25c-0.58-0.09-0.91-0.38-0.41-0.98
							c0.47-0.58,0.02-1.04-0.56-1.36c-0.01,0-0.03,0-0.04,0c0.34,0.17,0.61,0.44,0.58,0.8c-0.03,0.4-0.42,0.76-0.31,1.15
							c0.22,0.71,2.12,1,3.73,1.13C673.98,1873.12,673.38,1873.04,672.75,1873.04z"/>
					</g>
					<g>
						<path className="st117" d="M671.33,1873.36c0.04,0.11,0.06,0.2,0.07,0.28c0.09,0,0.19,0,0.28,0
							C671.46,1873.47,671.3,1873.28,671.33,1873.36z"/>
					</g>
					<g>
						<path className="st117" d="M672.62,1873.65c0.18,0,0.35,0,0.53,0.01C673.01,1873.56,672.85,1873.52,672.62,1873.65z"/>
					</g>
					<g>
						<path className="st113" d="M661.55,1870.93c-0.13-0.25-0.57-0.13-0.59-0.46c0,0,0,0,0,0
							C660.99,1870.8,661.43,1870.68,661.55,1870.93z"/>
					</g>
					<g>
						<path className="st109" d="M689.94,1850.27c-0.78,0.16-1.57,0.3-2.36,0.46c-0.22,0.05-0.45,0.09-0.67,0.14s-0.45,0.1-0.67,0.16
							c-0.55,0.14-1.37-0.05-1.91,0.15l0.06,0.16c0.1,0.1,0.41,0.39,0.51,0.49c0.05,0.05,0.1,0.1,0.15,0.15s0.1,0.1,0.14,0.15
							c0.04,0.04,0.07,0.08,0.11,0.12c0.06,0.06,0.11,0.13,0.16,0.19c0.02,0.03,0.04,0.05,0.06,0.08c0.06,0.08,0.12,0.16,0.17,0.25
							c0.29,0.45,0.46,0.96,0.39,1.63c-0.09,0.81,0.49,1.46,0.81,2.16c0,0,0,0,0,0c0,0,0.01,0,0.01,0c1.53-0.33,3.06-0.86,4.59-1.18
							c0.17-0.04,0.35-0.07,0.52-0.03c0.16,0.05,0.47,0.21,0.63,0.35c0.33-0.21,0.63-0.46,0.86-0.77c1.75-2.33,0.01-4.5-2.23-5.1
							C690.95,1850.19,690.41,1850.18,689.94,1850.27z"/>
					</g>
					<g>
						<path className="st109" d="M684.03,1851.69c0.04-0.03,0.08-0.07,0.12-0.1"/>
					</g>
					<g>
						<path className="st109" d="M693.52,1854.92c-0.24,0.31-0.53,0.56-0.86,0.77c0.07,0.06,0.11,0.12,0.09,0.16
							c-0.01,0.03-0.03,0.05-0.06,0.08c0.36-0.04,0.59-0.27,0.76-0.55c0.03-0.05,0.06-0.1,0.09-0.16c0.08-0.14,0.14-0.3,0.21-0.45
							c0.7-1.49,1.67-3.35-0.48-4.18c-0.11-0.04-0.08-0.22-0.13-0.32s-0.09-0.19-0.15-0.27c-0.02-0.03-0.04-0.06-0.06-0.09
							c-0.31-0.43-0.75-0.6-1.42-0.46c-0.06,0.15-0.13,0.26-0.21,0.36C693.53,1850.42,695.27,1852.59,693.52,1854.92z"/>
					</g>
					<g>
						<path className="st110" d="M674.52,1861.02c-0.1,0.2-0.36,0.57-0.47,0.68C674.27,1861.56,674.45,1861.36,674.52,1861.02z"/>
					</g>
					<g>
						<path className="st109" d="M683.4,1861.6c0.13-0.1,0.27-0.2,0.4-0.3c0.28-0.26,1.33,0.3,1.63,0.06c2.04-1.65,4.07-2.78,6.45-3.88
							c0.35-0.16,1.52-0.73,1.86-0.92c-0.15-0.5-1.87-2.01-2.22-1.94c-0.38,0.08-1.05,0.2-1.43,0.28c-1.13,0.24-2.05,0.78-3.17,1.02
							c-0.28,0.09-0.57,0.87-0.85,0.95c-0.04,0.01-0.08,0.02-0.12,0.03c-0.11,0.02-0.21,0.03-0.31,0.02
							c-1.5-0.16-2.75,0.63-4.12,0.97c-0.44,0.11-0.87,0.23-1.31,0.35c0,0,0,0,0,0s-0.09,0-0.22,0c-0.25,0.01-0.49,0.06-0.74,0.1
							c0.05,0.09,0.02,0.22-0.09,0.27c-1.11,0.56-2.13,1.06-3.1,1.51c0,0,0,0,0,0c0,0,0,0,0,0c-0.59,0.28-1.17,0.53-1.73,0.77
							c0.08,0,0.16-0.01,0.23-0.01l0,0l0,0c0.03,0,0.02,0.05-0.02,0.13c-0.07,0.33-0.24,0.54-0.47,0.68
							c-0.01,0.01-0.02,0.02-0.03,0.03c-0.52,0.34-0.87,0.4-1.38,0.73c-0.05,0.03-0.39,0.06-0.41,0.11
							c-0.33,0.62,3.88,0.86,4.3,0.86c0.54,0,2.59-0.5,2.87,0.18c0.08,0.21-0.09,0.42-0.28,0.55c-0.18,0.12-0.61,0.23-0.68,0.44
							c-0.07,0.21,0.03,0.43,0.17,0.6c0.09,0.1,0.19,0.2,0.28,0.3c0.01,0.02,0.03,0.03,0.04,0.05c0.15-0.08,0.29-0.16,0.44-0.24
							c1.73-1.02,2.99-2.64,4.44-4.01C683.67,1861.4,683.53,1861.5,683.4,1861.6"/>
					</g>
					<g>
						<polygon className="st110" points="680.2,1858.25 680.2,1858.25 680.2,1858.25 						"/>
					</g>
					<g>
						<path className="st110" d="M679.24,1858.35C679.24,1858.35,679.24,1858.35,679.24,1858.35c0.25-0.04,0.5-0.09,0.74-0.1
							c0,0,0,0,0,0C679.73,1858.26,679.48,1858.31,679.24,1858.35z"/>
					</g>
					<g>
						<path className="st110" d="M674.05,1861.7C674.05,1861.7,674.05,1861.7,674.05,1861.7c0.22-0.14,0.39-0.35,0.47-0.68c0,0,0,0,0,0
							C674.45,1861.36,674.27,1861.56,674.05,1861.7z"/>
					</g>
					<g>
						<polygon className="st110" points="674.54,1860.9 674.54,1860.9 674.54,1860.9 						"/>
					</g>
					<g>
						<path className="st110" d="M679.01,1866.04c0.04-0.02,0.09-0.04,0.13-0.06c0,0,0,0,0,0C679.09,1866,679.05,1866.02,679.01,1866.04
							C679.01,1866.04,679.01,1866.04,679.01,1866.04z"/>
					</g>
					<g>
						<path className="st109" d="M674.05,1861.7c0.11-0.11,1.21,0.36,1.31,0.16c0.01-0.04,0.81-0.86,0.82-0.91
							c-0.08,0-1.8-0.06-1.87-0.05c-0.71,0.3-1.4,0.57-2.1,0.82c-2.54,1.32-2.94,2.3-2.94,2.3c-0.02,0.31,1.12-0.5,0.93-0.31
							c-0.07,0.08,1.8,0.04,1.72,0.11c-0.13,0.11,0.62,0.18,0.51,0.31c-0.22,0.25,1.53-0.33,1.68,0.09c0.15,0.41,0.67,0.62,1.01,0.6
							c0.95-0.05,0.51,0.9,1.48,0.58c0.63-0.21,0.8,0.38,1.25,0.71c0.31-0.13,0.77-0.39,1.08-0.55c-0.01-0.02-0.03-0.03-0.04-0.05
							c-0.09-0.1-0.19-0.2-0.28-0.3c-0.14-0.17-0.24-0.39-0.17-0.6c0.07-0.21,0.49-0.32,0.68-0.44c0.18-0.12,0.36-0.34,0.28-0.55
							c-0.28-0.69-2.33-0.19-2.87-0.18c-0.42,0-4.62-0.23-4.3-0.86c0.03-0.05,0.37-0.08,0.41-0.11c0.51-0.34,1.12,0.15,1.64-0.19
							C674.29,1862.27,674.04,1861.72,674.05,1861.7c-0.07,0.05,0.81,0.58,0.73,0.62"/>
					</g>
					<g>
						<line className="st110" x1="674.05" y1="1861.7" x2="674.05" y2="1861.7"/>
					</g>
					<g>
						<polygon className="st110" points="686.91,1856.55 686.91,1856.55 686.91,1856.55 686.92,1856.54 						"/>
					</g>
					<g>
						<path className="st113" d="M732.99,1830.51L732.99,1830.51c0.05-0.01,0.11-0.03,0.16-0.04c0,0,0,0,0,0s0,0,0,0
							C733.1,1830.48,733.04,1830.49,732.99,1830.51z"/>
					</g>
					<g>
						<polygon className="st118" points="679.19,1865.96 679.19,1865.96 679.19,1865.96 						"/>
					</g>
					<g>
						<path className="st119" d="M666.14,1869.92c-0.14-0.01-0.28-0.01-0.42-0.02c-0.25,0.01-0.5,0.02-0.76,0.01
							c-0.16,0-0.33-0.02-0.49-0.03c-0.17,0-0.35,0-0.53,0C664.68,1869.92,665.41,1869.92,666.14,1869.92z"/>
					</g>
					<g>
						<path className="st119" d="M641.26,1865.76c-0.25,0-0.49-0.04-0.71-0.12l0.17,0.13c0.14,0.1,0.09,0.32-0.07,0.36
							c-0.04,0.01-0.07,0.03-0.09,0.06c0.25,0,0.47,0,0.68,0C641.24,1866.04,641.25,1865.9,641.26,1865.76z"/>
					</g>
					<g>
						<path className="st119" d="M668.68,1870.14c-0.84-0.12-1.69-0.18-2.54-0.22c-0.73,0-1.46,0.01-2.19-0.04c-0.18,0-0.36,0-0.54,0
							c0.04-0.01,0.08-0.02,0.11-0.04c-0.17-0.02-0.33-0.04-0.5-0.07c0,0,0,0,0,0c0,0,0,0,0,0c-0.08,0-0.16,0-0.24,0
							c-0.39,0.01-0.78,0.03-1.17-0.02c-0.91-0.12-1.81-0.32-2.71-0.5c0,0,0,0,0,0c-0.1-0.02-0.2-0.04-0.3-0.06
							c-0.02,0-0.04-0.01-0.06-0.01c-1.18-0.26-2.34-0.61-3.51-0.91c-0.59-0.15-1.18-0.29-1.78-0.4c-2.9-0.53-5.8-1-8.73-1.29
							c-0.28-0.03-0.55-0.06-0.83-0.09c-0.83-0.09-1.65-0.2-2.48-0.3c-0.21,0-0.43,0-0.68,0c-0.1,0.1-0.07,0.28,0.08,0.33
							c5.07,1.6,10.89,0.76,15.59,3.13c0.19,0.09,0.12,0.38-0.09,0.38c-0.41,0-0.82,0.01-1.23,0.02c0.51,0.08,1.02,0.22,1.5,0.03
							c0.21-0.08,0.41-0.19,0.61-0.35c0,0,0,0,0,0c1.38-0.13,2.62,0.44,3.92,0.74c0.01,0,0.02,0,0.02,0.01c0,0,0,0,0,0
							C663.73,1870.57,665.97,1870.32,668.68,1870.14z"/>
					</g>
					<g>
						<path className="st119" d="M663.03,1869.77C663.03,1869.77,663.02,1869.77,663.03,1869.77c0.16,0.03,0.33,0.05,0.49,0.07
							c0.05-0.02,0.1-0.03,0.14-0.05c-0.09-0.01-0.18-0.01-0.26-0.02C663.28,1869.77,663.16,1869.77,663.03,1869.77z"/>
					</g>
					<g>
						<path className="st119" d="M657.02,1869.72c-0.2,0.16-0.4,0.27-0.61,0.35C656.62,1869.99,656.82,1869.88,657.02,1869.72
							C657.02,1869.72,657.02,1869.72,657.02,1869.72z"/>
					</g>
					<g>
						<path className="st119" d="M640.56,1866.18C640.56,1866.18,640.56,1866.18,640.56,1866.18c0.25,0,0.47,0,0.68,0
							c0.83,0.1,1.65,0.21,2.48,0.3c-0.83-0.09-1.65-0.2-2.47-0.3C641.02,1866.18,640.81,1866.18,640.56,1866.18z"/>
					</g>
					<g>
						<path className="st119" d="M666.14,1869.92c0.15,0,0.31,0,0.46,0c0.08,0,0.16,0,0.24,0c-0.33,0-0.6-0.02-0.85-0.03
							c-0.09,0.01-0.18,0.01-0.26,0.02C665.86,1869.91,666,1869.91,666.14,1869.92z"/>
					</g>
					<g>
						<path className="st119" d="M666.14,1869.92c0.85,0.04,1.69,0.1,2.54,0.22c0.16-0.01,0.32-0.02,0.48-0.03
							c0.26,0.08,0.58,0.2,0.86,0.35c0,0,0,0,0,0c-0.49-0.49-1.39-0.45-2.01-0.49c-0.21-0.01-0.42-0.02-0.62-0.03
							c-0.18-0.01-0.36-0.01-0.55-0.01c-0.08,0-0.16,0-0.24,0C666.45,1869.92,666.3,1869.92,666.14,1869.92z"/>
					</g>
					<g>
						<path className="st119" d="M663.41,1869.87c0.18,0,0.36,0,0.54,0c-0.14-0.01-0.28-0.02-0.42-0.04
							C663.49,1869.85,663.45,1869.86,663.41,1869.87z"/>
					</g>
					<g>
						<path className="st119" d="M663.95,1869.87c0.18,0,0.35,0,0.53,0c-0.27-0.03-0.54-0.06-0.81-0.09c-0.05,0.02-0.1,0.03-0.14,0.05
							C663.67,1869.85,663.81,1869.86,663.95,1869.87z"/>
					</g>
					<g>
						<path className="st119" d="M657.02,1869.72c-0.2,0.16-0.4,0.27-0.61,0.35c-0.48,0.19-0.99,0.05-1.5-0.03c-0.14,0-0.28,0-0.41,0.01
							c0.97,0.1,2.36,0.34,2.22,0.41c1.38-0.13,2.92-0.3,4.22,0C659.64,1870.16,658.4,1869.59,657.02,1869.72z"/>
					</g>
					<g>
						<path className="st119" d="M658.61,1869.18c-0.02,0-0.04-0.01-0.06-0.01C658.57,1869.18,658.59,1869.18,658.61,1869.18z"/>
					</g>
					<g>
						<path className="st119" d="M641.29,1865.14c-0.05-0.01-0.1-0.02-0.15-0.03c0.04,0.01,0.09,0.02,0.13,0.03
							C641.28,1865.14,641.28,1865.14,641.29,1865.14z"/>
					</g>
					<g>
						<path className="st119" d="M658.91,1869.25c0.9,0.19,1.8,0.38,2.71,0.5c0.39,0.05,0.78,0.03,1.17,0.02
							C661.47,1869.78,660.2,1869.51,658.91,1869.25z"/>
					</g>
					<g>
						<path className="st119" d="M663.47,1869.66c-0.2,0.05-0.41,0.1-0.44,0.11c0.12,0,0.25,0,0.37,0c0.09,0,0.18,0.01,0.26,0.02
							c0.17-0.05,0.35-0.11,0.52-0.16c0,0-0.01,0-0.01,0C663.95,1869.59,663.7,1869.6,663.47,1869.66z"/>
					</g>
					<g>
						<path className="st119" d="M641.26,1865.76c-0.01,0.14-0.02,0.28-0.02,0.43c0.83,0.1,1.65,0.21,2.47,0.3
							c-0.62-0.07-1.28-0.75-1.93-1.12C641.78,1865.63,641.47,1865.67,641.26,1865.76z"/>
					</g>
					<g>
						<path className="st119" d="M665.72,1869.9c-0.42-0.01-0.83-0.02-1.25-0.03c0.16,0.02,0.33,0.03,0.49,0.03
							C665.22,1869.92,665.47,1869.91,665.72,1869.9z"/>
					</g>
					<g>
						<path className="st119" d="M663.67,1869.79c0.27,0.02,0.54,0.06,0.81,0.09c0.42,0,0.83,0.01,1.25,0.03c0.09,0,0.18-0.01,0.26-0.02
							c-0.62-0.05-1.08-0.14-1.79-0.25C664.02,1869.68,663.84,1869.73,663.67,1869.79z"/>
					</g>
					<g>
						<path className="st119" d="M668.68,1870.14c-2.71,0.18-4.94,0.43-7.71,0.34c2.92,0.1,6.09,0.16,9.01-0.01
							c-0.24-0.12-0.51-0.2-0.76-0.24C669.04,1870.19,668.86,1870.16,668.68,1870.14z"/>
					</g>
					<g>
						<path className="st119" d="M668.68,1870.14c0.18,0.03,0.36,0.05,0.54,0.08c0.25,0.04,0.52,0.12,0.76,0.24c0.01,0,0.03,0,0.04,0
							c-0.29-0.15-0.6-0.27-0.86-0.35C669,1870.11,668.84,1870.12,668.68,1870.14z"/>
					</g>
					<g>
						<path className="st119" d="M663.02,1869.77C663.02,1869.77,663.02,1869.77,663.02,1869.77c0.17,0.03,0.33,0.05,0.5,0.07
							c0,0,0,0,0,0C663.36,1869.82,663.19,1869.79,663.02,1869.77z"/>
					</g>
					<g>
						<path className="st119" d="M663.95,1869.87C663.95,1869.87,663.95,1869.87,663.95,1869.87c0.73,0.05,1.46,0.05,2.19,0.04
							c0,0,0,0,0,0C665.41,1869.92,664.68,1869.92,663.95,1869.87z"/>
					</g>
					<g>
						<path className="st119" d="M666.6,1869.92c-0.15,0-0.31,0-0.46,0c0,0,0,0,0,0C666.3,1869.92,666.45,1869.92,666.6,1869.92z"/>
					</g>
					<g>
						<path className="st119" d="M663.52,1869.83C663.52,1869.83,663.52,1869.83,663.52,1869.83c0.14,0.02,0.28,0.03,0.42,0.04
							c0,0,0,0,0,0C663.81,1869.86,663.67,1869.85,663.52,1869.83z"/>
					</g>
					<g>
						<polygon className="st118" points="686.06,1849.85 686.06,1849.85 686.06,1849.85 						"/>
					</g>
					<g>
						<polygon className="st118" points="686.06,1849.85 686.06,1849.85 686.06,1849.85 						"/>
					</g>
					<g>
						<polygon className="st118" points="686.07,1849.85 686.06,1849.85 686.06,1849.85 686.06,1849.85 						"/>
					</g>
					<g>
						<path className="st119" d="M612.66,1869.31c0.04-1.04,0.78-1.73,1.8-2.16c0.28-0.12,0.51-0.33,0.68-0.59
							c0.41-0.66,1.2-0.75,2.1-0.91c2.46-0.42,4.96-0.48,7.4-1c0.52-0.11,1.04-0.17,1.55-0.26c1.45-0.43,3.11-1.02,4.13-1.75
							c0.41-0.3-0.41-1.52,2.19-2.02l4.64-0.61c0.21-0.02,0.23-0.29,0.08-0.38c-0.23,0.04-0.53,0.08-0.88,0.14
							c-0.34,0.07-0.69,0.12-1.03,0.15c-1.87,0.27-4.17,0.6-4.44,0.7c-0.51,0.18-1.11,0.3-0.78,1.14c0.18,0.45-0.33,0.57-0.65,0.64
							c-2.4,0.52-4.76,1.24-7.26,1.32c-1.75,0.06-3.54,0.19-5.23,0.71c-0.73,0.23-1.46,0.38-2.21,0.52c-1.1,0.19-2.41,0.08-3,1.46
							c-0.15,0.35-0.65,0.4-1.03,0.38c-2.54-0.16-5.04,1-7.63,0.15c-1.7-0.56-3.51,0.01-5.26-0.12c-0.14-0.01-0.28-0.02-0.43-0.03
							c-0.05,0-0.11-0.01-0.16-0.01c-0.09,0-0.17-0.01-0.26-0.01c-0.06,0-0.12,0-0.17-0.01c-0.08,0-0.17-0.01-0.25-0.01
							c-0.06,0-0.12,0-0.18,0c-0.08,0-0.16,0-0.24-0.01c-0.06,0-0.13,0-0.19,0c-0.08,0-0.15,0-0.23,0c-0.07,0-0.13,0-0.2,0
							c-0.08,0-0.15,0-0.23,0c-0.07,0-0.14,0-0.2,0c-0.07,0-0.15,0-0.22,0c-0.07,0-0.13,0-0.2,0.01c-0.08,0-0.15,0-0.23,0.01
							c6.22,0.99,12.33,1.87,18.31,2.62C612.69,1869.37,612.66,1869.34,612.66,1869.31z"/>
					</g>
					<g>
						<path className="st119" d="M595.51,1866.76c0.07,0,0.13,0,0.2,0C595.64,1866.75,595.57,1866.75,595.51,1866.76z"/>
					</g>
					<g>
						<path className="st119" d="M637.12,1859.61c-0.14,0.02-0.28,0.05-0.43,0.09c-0.11,0.03-0.22,0.05-0.33,0.07
							c0.34-0.05,0.64-0.1,0.88-0.14c-0.03-0.01-0.06-0.02-0.09-0.03L637.12,1859.61z"/>
					</g>
					<g>
						<path className="st119" d="M595.28,1866.76c-0.07,0-0.14,0-0.2,0C595.15,1866.76,595.21,1866.76,595.28,1866.76z"/>
					</g>
					<g>
						<path className="st119" d="M597.4,1866.81c-0.05,0-0.11-0.01-0.16-0.01C597.29,1866.8,597.35,1866.8,597.4,1866.81z"/>
					</g>
					<g>
						<path className="st119" d="M633.43,1860.05c-0.17,0.01-0.34,0.02-0.5,0.03c-0.08,0.01-0.17,0.01-0.25,0.02
							c-0.6,0.18-1.21,0.33-1.79,0.54c0.26-0.09,2.57-0.43,4.44-0.7c-0.46,0.05-0.93,0.07-1.4,0.09
							C633.76,1860.03,633.6,1860.04,633.43,1860.05z"/>
					</g>
					<g>
						<path className="st119" d="M596.55,1866.77c-0.06,0-0.12,0-0.18,0C596.43,1866.77,596.49,1866.77,596.55,1866.77z"/>
					</g>
					<g>
						<path className="st119" d="M596.98,1866.78c-0.06,0-0.12,0-0.17-0.01C596.86,1866.78,596.92,1866.78,596.98,1866.78z"/>
					</g>
					<g>
						<path className="st119" d="M596.13,1866.76c-0.06,0-0.13,0-0.19,0C596,1866.76,596.06,1866.76,596.13,1866.76z"/>
					</g>
					<g>
						<path className="st119" d="M594.66,1866.77c0.07,0,0.13,0,0.2-0.01C594.79,1866.76,594.73,1866.77,594.66,1866.77z"/>
					</g>
					<g>
						<path className="st120" d="M639.93,1871.69c-0.45-0.19-0.33-0.33-0.18-0.87c0.12-0.43,0.76-0.34,1.18-0.44
							c0.13-0.03,0.28,0.01,0.42,0.01c0.2,0,0.4-0.01,0.6-0.02c0.09,0,0.17-0.01,0.26-0.01c1.66-0.06,3.25-0.17,4.77-0.29
							c2.25-0.18,2.44-0.18,4.72,0.04c0.14,0.01,1.98-0.19,2.12-0.19c0.01,0,0.01,0,0.02,0c0.32-0.15,0.94-0.36,1.91-0.4
							c-0.66-0.28-0.32,0.02-1-0.11c-3.67-0.69-7.05-1.46-10.77-1.84c-1.34-0.14-2.75-0.5-4.23-1.39c0.03,0,0.06,0,0.1,0
							c0,0,0,0,0,0c0.15,0,0.42-0.28,0.68-0.55c-0.38-0.13-0.72-0.35-1.04-0.62c0.24-0.38,0.59-0.25,0.87-0.32
							c0.27-0.23,0.59-0.31,0.92-0.37c0.03-0.01,0.06-0.01,0.09-0.01c0.05-0.01,0.1-0.02,0.15-0.03c2.1-0.35,4.22-0.36,6.33-0.58
							c3.25-0.34,6.55-0.24,9.77-0.7c2.23-0.31,4.4-0.45,6.62-0.2c1.01,0.12,1.92-0.18,2.84-0.52c0.45-0.17,0.9-0.31,1.36-0.45
							c0.3-0.09,0.61-0.17,0.92-0.25c1.49-0.37,3.02-0.59,4.57-0.67c0.62-0.26,1.32-0.52,2.11-0.79c0,0,0,0,0,0
							c0.74-0.42,1.92-1.22,2.76-1.72c-0.14,0.01-0.27,0.02-0.41,0.01c0,0-0.01,0-0.01,0c-0.05,0-0.09-0.01-0.14-0.02
							c-0.08-0.01-0.17-0.03-0.25-0.06c-0.1-0.04-0.21-0.09-0.31-0.15c0.03-0.1,0.12-0.17,0.23-0.22c0.03-0.01,0.06-0.03,0.09-0.04
							c0.02-0.01,0.03-0.01,0.05-0.02c0.4-0.14,0.95-0.33,0.57-0.79c-0.24-0.3-0.56-0.44-0.91-0.52c0.18,0.1,0.37,0.18,0.58,0.23
							c0.09,0.07,0.16,0.14,0.23,0.2c-0.06-0.06-0.14-0.13-0.23-0.2c-0.21-0.05-0.4-0.13-0.58-0.23c-0.12-0.03-0.25-0.05-0.38-0.06
							c-0.18-0.01-0.34-0.02-0.5-0.04c-0.16-0.01-0.31-0.02-0.46-0.04c-0.94-0.11-1.78-0.52-2.61-0.93
							c-0.09-0.04-0.29-0.12-0.51-0.22c-0.36,0.22-0.72,0.45-1.03,0.21c-0.05-0.45-0.36-0.72-0.7-0.8c-0.05-0.01-0.1-0.02-0.16-0.02
							c-0.03,0-0.05,0-0.08,0c-1.15,0.02-2.16-0.5-3.24-0.7c-2.26-0.41-4.9-1.05-7.23-1.84c-0.44,0.13-0.89,0.21-1.25,0.2
							c-0.08,0.02-0.36-0.45-0.45-0.43c-0.39,0.06,0.29,0.3-0.1,0.33c-0.12,0.01-1.4-0.1-1.51-0.1c-1.55,0.06-4.52-0.9-4.52-0.9
							c-0.01-0.03-0.19,0.42-0.21,0.4c-0.05-0.05-0.11,0.92-0.17,0.89c0,0,0-0.9,0-0.9c-0.02-0.01-0.03-0.11-0.05-0.12
							c-0.14-0.04-0.29-0.04-0.45-0.01c-0.06,0.01-0.11,0.02-0.17,0.03c-0.41,0.09-0.83,0.14-1.24,0.17
							c-0.17,0.01-0.33,0.01-0.5,0.01c-0.54,0-1.24-0.41-1.86-0.67c-0.32,0.23-1.09-0.23-1.4-0.15c-0.42,0.1-0.84,0.11-1.27,0.12
							c-0.07,0-0.14,0-0.21,0c-0.11,0-0.21,0.01-0.32,0.01c-0.13,0.01-0.27,0.02-0.4,0.03c-0.4,0.04-0.79,0.14-1.16,0.35
							c0.76,0.01,1.53,0.01,2.29,0.02c0.38,0,0.76,0.01,1.15,0.02c-0.38-0.01-0.76-0.01-1.15-0.02c-0.76-0.01-1.53-0.01-2.29-0.02
							c-0.71,0.01-1.41,0.03-2.12,0.04c-0.11-0.04-0.23-0.08-0.34-0.1c-0.42-0.1-0.84-0.09-1.25,0.04
							c-0.08,0.02-0.15,0.05-0.23,0.08c0,0,0,0,0,0c-0.27,0.03-0.54,0.02-0.81-0.02c-0.16-0.03-0.32-0.07-0.47-0.13
							c-0.15-0.07-0.29-0.19-0.45-0.21c-0.41-0.06-0.81-0.12-1.22-0.16c-6.52,0.34-13.27,0.61-19.76,0.97
							c-2.88,0.16-5.77,0.32-8.58,0.91c0.45,0.53,1.18,0.7,1.86,0.84c5.51,1.14,11.01,2.28,16.52,3.41c0.51,0.11,1.7,0.27,1.9,0.75
							c-0.33,0.03,0.8,0.88,1.11,1.45l0.46,0.02c0.47,0.01,0.95,0.01,1.42,0.02c0.04,0,0.08,0.02,0.11,0.05l0.03,0
							c0-0.02,0-0.03-0.01-0.05c0,0,0,0,0,0c0,0,0.01,0,0.02,0c0.02,0,0.05,0.01,0.05,0.01c0,0,0,0,0,0
							c-0.01,0.01-0.01,0.03-0.01,0.04l0.08,0c0.02,0,0.04,0,0.06,0l0.05,0c0.08-0.03,0.16-0.23,0.24-0.2
							c-0.01,0.37,5.51-0.02,5.5,0.36l0,0c0.19-0.01,0.38-0.01,0.57-0.01c0.08,0,0.16,0,0.24,0.01c0.02,0,0.04,0,0.06,0
							c0.12,0.01,0.25,0.01,0.37,0.02c0.21,0.02,0.43,0.03,0.64,0.05c0.14,0.01,0.28,0.02,0.42,0.03c-3.15,0.86-6.57,0.25-9.57,1.95
							c0.19,0.27,0.75,0.24,0.72,0.65c-0.04,0.5-0.61,0.34-0.91,0.54c-1.04,0.68-2.27,1.06-3.42,1.45
							c-0.07,0.65-0.82,0.22-1.12,0.69c0.64,0.39,1.25,0.5,1.9,0.7c1.59,0.48,2.92,1.28,4.41,1.93c0.18,0.08,0.36,0.25,0.32,0.49
							c-0.03,0.25-0.27,0.33-0.46,0.36c-0.31,0.05-0.62,0.05-0.93,0.07c-2.22,0.09-4.46,0.1-6.67,0.08
							c-2.26-0.02-4.61,0.24-6.82-0.58c-1.04-0.39-2.06,0.16-3.1,0.07c-0.64,0.35-1.13,0.82-1.52,1.37
							c-0.01,0.01-0.01,0.02-0.02,0.03c0.11,0.04,0.24,0.07,0.3,0.08c9.29,1.24,19.07,2.11,28.42,2.63
							c-0.52-0.04-1.04-0.08-1.54-0.12C639.82,1871.85,640.18,1871.8,639.93,1871.69z"/>
					</g>
					<g>
						<path className="st120" d="M612.74,1869.4c0.03,0.02,0.06,0.03,0.09,0.04c0.01-0.01,0.01-0.02,0.02-0.03
							C612.82,1869.41,612.78,1869.41,612.74,1869.4z"/>
					</g>
					<g>
						<path className="st120" d="M678.3,1856.82c0.09,0.07,0.16,0.14,0.23,0.2C678.46,1856.96,678.39,1856.9,678.3,1856.82
							c-0.21-0.05-0.4-0.13-0.58-0.23c0,0,0,0,0,0C677.9,1856.69,678.09,1856.77,678.3,1856.82z"/>
					</g>
					<g>
						<path className="st120" d="M639.86,1851.41c0.42-0.13,0.83-0.14,1.25-0.04C640.69,1851.27,640.27,1851.28,639.86,1851.41z"/>
					</g>
					<g>
						<path className="st120" d="M678.4,1858.42c0.14,0.01,0.27,0.01,0.41-0.01c0,0,0,0,0,0
							C678.67,1858.42,678.53,1858.43,678.4,1858.42z"/>
					</g>
					<g>
						<path className="st120" d="M638.29,1859.54c-0.19,0-0.38,0-0.57,0.01c0,0,0,0,0,0C637.92,1859.54,638.11,1859.54,638.29,1859.54z"
							/>
					</g>
					<g>
						<path className="st120" d="M645.87,1851.45c-0.76-0.01-1.53-0.01-2.29-0.02c0,0,0,0,0,0
							C644.34,1851.44,645.1,1851.44,645.87,1851.45z"/>
					</g>
					<g>
						<path className="st120" d="M638.82,1851.47c0.26,0.04,0.53,0.05,0.81,0.02c0,0,0,0,0,0
							C639.35,1851.52,639.09,1851.51,638.82,1851.47z"/>
					</g>
					<g>
						<path className="st120" d="M612.85,1869.42c-0.01,0.01-0.01,0.02-0.02,0.03c0,0,0,0,0,0
							C612.84,1869.43,612.85,1869.42,612.85,1869.42L612.85,1869.42z"/>
					</g>
					<g>
						<path className="st120" d="M614.37,1868.05c-0.64,0.35-1.13,0.82-1.52,1.37h0C613.24,1868.86,613.72,1868.4,614.37,1868.05
							c0.14-0.17,0.36-0.34,0.45-0.52C614.73,1867.71,614.51,1867.87,614.37,1868.05z"/>
					</g>
					<g>
						<path className="st120" d="M641.28,1864.33c-0.33,0.06-0.65,0.13-0.92,0.37h0C640.63,1864.46,640.95,1864.38,641.28,1864.33z"/>
					</g>
					<g>
						<path className="st120" d="M648.46,1850.88c-0.02,0.08-0.07,0.14-0.12,0.18c0.62,0.26,1.32,0.67,1.86,0.67c0.17,0,0.33,0,0.5-0.01
							c0.41-0.02,0.83-0.08,1.24-0.17c0.06-0.01,0.11-0.02,0.17-0.03c0.15-0.03,0.3-0.03,0.45,0.01c0.02,0.01,0.03,0.11,0.05,0.12
							c0,0,0,0.9,0,0.9c0.06,0.02,0.12-0.94,0.17-0.89c0.02,0.02,0.2-0.42,0.21-0.4c0,0,2.97,0.96,4.52,0.9c0.12,0,1.4,0.1,1.51,0.1
							c0.39-0.02-0.29-0.27,0.1-0.33c0.08-0.02,0.37,0.45,0.45,0.43c0.36,0.01,0.8-0.07,1.25-0.2c-0.48-0.16-0.95-0.33-1.4-0.51
							C655.94,1851.22,652.25,1850.98,648.46,1850.88z"/>
					</g>
					<g>
						<path className="st120" d="M631.6,1859.34c-0.47-0.01-0.95-0.01-1.42-0.02l1.52,0.06C631.68,1859.36,631.64,1859.34,631.6,1859.34
							z"/>
					</g>
					<g>
						<path className="st120" d="M637.73,1859.55L637.73,1859.55c0.01-0.37-5.51,0.02-5.5-0.36c-0.08-0.03-0.16,0.17-0.24,0.2l5.13,0.21
							C637.32,1859.58,637.52,1859.56,637.73,1859.55z"/>
					</g>
					<g>
						<path className="st120" d="M631.88,1859.4l0.06,0C631.91,1859.39,631.9,1859.39,631.88,1859.4z"/>
					</g>
					<g>
						<path className="st120" d="M637.12,1859.61c0.2-0.03,0.41-0.05,0.61-0.06c0,0,0,0,0,0
							C637.52,1859.56,637.32,1859.58,637.12,1859.61L637.12,1859.61z"/>
					</g>
					<g>
						<path className="st120" d="M647.7,1850.86c-3.16-0.07-6.38-0.06-9.58,0.01c0.02,0.01,0.04,0.01,0.05,0.02
							c-0.5,0.03-0.99,0.05-1.49,0.08c0.41,0.05,0.81,0.1,1.22,0.16c0.16,0.02,0.3,0.14,0.45,0.21c0.16,0.06,0.31,0.1,0.47,0.13
							c0.26,0.04,0.53,0.05,0.81,0.02c0.08-0.03,0.15-0.06,0.23-0.08c0.42-0.13,0.83-0.14,1.25-0.04c0.11,0.03,0.23,0.06,0.34,0.1
							c0.71-0.01,1.41-0.03,2.12-0.04c0,0,0,0,0,0s0,0,0,0c0.37-0.21,0.76-0.31,1.16-0.35c0.13-0.01,0.27-0.02,0.4-0.03
							c0.11,0,0.21-0.01,0.32-0.01c0.07,0,0.14,0,0.21,0c0.43-0.01,0.86-0.02,1.27-0.12c0.31-0.08,1.08,0.38,1.4,0.15
							c-0.21-0.09-0.42-0.16-0.61-0.19C647.72,1850.87,647.71,1850.87,647.7,1850.86z"/>
					</g>
					<g>
						<path className="st120" d="M648.46,1850.88c-0.25-0.01-0.51-0.01-0.76-0.02c0.01,0,0.02,0.01,0.03,0.01
							c0.19,0.03,0.4,0.1,0.61,0.19C648.39,1851.02,648.44,1850.97,648.46,1850.88z"/>
					</g>
					<g>
						<path className="st120" d="M672.21,1854.63c0,0,0.01-0.11,0.01-0.12c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.01,0.01,0.01,0.01
							c0-0.01-0.03,0.12-0.02,0.04c0.01,0-0.15-0.05-0.14-0.04c-0.5-0.14-0.81-0.29-1.33-0.47c-2.78-0.95-5.9-1.64-9.25-2.13
							c-0.22,0.09-0.45,0.17-0.67,0.23c2.33,0.79,4.97,1.43,7.23,1.84c1.08,0.2,2.09,0.71,3.24,0.7c0.03,0,0.05,0,0.08,0
							c0.05,0,0.11,0.01,0.16,0.02c0.34,0.08,0.65,0.34,0.7,0.8c0.31,0.24,0.67,0.02,1.03-0.21
							C672.79,1855.1,672.42,1854.75,672.21,1854.63z"/>
					</g>
					<g>
						<path className="st120" d="M659.42,1851.64c0.45,0.18,0.92,0.35,1.4,0.51c0.23-0.07,0.45-0.15,0.67-0.23
							C660.81,1851.81,660.12,1851.72,659.42,1851.64z"/>
					</g>
					<g>
						<path className="st120" d="M643.57,1851.43c0.37-0.21,0.76-0.31,1.16-0.35C644.33,1851.12,643.94,1851.22,643.57,1851.43
							C643.57,1851.43,643.57,1851.43,643.57,1851.43z"/>
					</g>
					<g>
						<path className="st120" d="M640.2,1871.09c0.13-0.25,0.4-0.47,0.79-0.58c0.31-0.09,0.63-0.12,0.96-0.14
							c-0.2,0.01-0.4,0.01-0.6,0.02c-0.14,0-0.28-0.04-0.42-0.01c-0.42,0.1-1.07,0.01-1.18,0.44c-0.15,0.54-0.27,0.68,0.18,0.87
							c0.25,0.11-0.11,0.16,0.08,0.35c0.5,0.04,1.02,0.08,1.54,0.12c0,0,0,0,0,0C640.65,1872.1,639.95,1871.56,640.2,1871.09z"/>
					</g>
					<g>
						<path className="st120" d="M653.59,1870.05c0,0,0.08-0.06,0.25-0.14c-0.01,0-0.01,0-0.02,0c-0.14,0-1.99,0.2-2.12,0.19
							c-2.29-0.21-2.47-0.22-4.72-0.04c-1.52,0.12-3.11,0.23-4.77,0.29c0.37-0.01,0.75,0,1.11-0.02c1.67-0.09,3.35-0.06,5.02-0.13
							c2.05-0.08,4.1-0.13,6.15-0.17C654.03,1870,653.65,1869.99,653.59,1870.05z"/>
					</g>
					<g>
						<path className="st120" d="M640.43,1864.76c6.66-1.25,16.46-0.83,22.98-1.32c3.57-0.27,6.21-0.82,8.79-1.72
							c0.49-0.26,1.07-0.52,1.73-0.8c-1.55,0.08-3.07,0.3-4.57,0.67c-0.31,0.08-0.61,0.16-0.92,0.25c-0.46,0.13-0.91,0.28-1.36,0.45
							c-0.92,0.34-1.83,0.64-2.84,0.52c-2.21-0.26-4.39-0.11-6.62,0.2c-3.23,0.45-6.52,0.36-9.77,0.7
							c-2.11,0.22-4.24,0.23-6.33,0.58c-0.05,0.01-0.1,0.02-0.15,0.03c-0.03,0-0.06,0.01-0.09,0.01c-0.33,0.06-0.65,0.13-0.92,0.37
							C640.38,1864.72,640.41,1864.74,640.43,1864.76z"/>
					</g>
					<g>
						<path className="st120" d="M676.04,1860.13c-0.79,0.27-1.49,0.53-2.11,0.79c0.12-0.01,0.25-0.01,0.37-0.02
							C674.87,1860.67,675.45,1860.41,676.04,1860.13z"/>
					</g>
					<g>
						<path className="st120" d="M679.24,1858.35c-0.14,0.02-0.29,0.05-0.43,0.06c-0.84,0.5-2.02,1.31-2.76,1.72
							c0.97-0.45,2-0.95,3.1-1.51C679.26,1858.57,679.28,1858.44,679.24,1858.35z"/>
					</g>
					<g>
						<path className="st120" d="M679.24,1858.35c-0.14,0.02-0.29,0.05-0.43,0.06c0,0,0,0,0,0
							C678.95,1858.4,679.09,1858.37,679.24,1858.35C679.24,1858.35,679.24,1858.35,679.24,1858.35z"/>
					</g>
					<g>
						<path className="st120" d="M674.31,1860.91c-0.12,0-0.25,0.01-0.37,0.02c-0.67,0.28-1.24,0.55-1.73,0.8
							C672.9,1861.48,673.6,1861.21,674.31,1860.91z"/>
					</g>
					<g>
						<path className="st120" d="M654.59,1870.01c0.02,0,0.03,0,0.04,0c0.09,0.01,0.18,0.02,0.27,0.03c0.41-0.01,0.82-0.01,1.23-0.02
							c0.21,0,0.28-0.29,0.09-0.38c-4.7-2.37-10.52-1.53-15.59-3.13c-0.16-0.05-0.18-0.23-0.08-0.33c-0.04,0-0.07,0-0.11,0
							c0.04,0,0.07,0,0.11,0c0.02-0.03,0.05-0.05,0.09-0.06c0.16-0.04,0.21-0.26,0.07-0.36l-0.17-0.13c-0.01,0-0.01,0-0.02-0.01
							c-0.26,0.27-0.53,0.55-0.68,0.55c0.03,0,0.43,0,0.47,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.03,0-0.44,0-0.47,0c0,0,0,0,0,0
							c-0.03,0-0.06,0-0.1,0c1.48,0.9,2.89,1.26,4.23,1.39c3.72,0.38,7.1,1.15,10.77,1.84c0.69,0.13,0.35-0.17,1,0.11
							c-0.98,0.04-1.6,0.25-1.91,0.4c0.24,0,0.27,0.05,0.52,0.07C654.44,1869.99,654.51,1870,654.59,1870.01z"/>
					</g>
					<g>
						<path className="st120" d="M640.45,1866.18c0.04,0,0.07,0,0.11,0c0,0,0,0,0,0C640.52,1866.18,640.48,1866.18,640.45,1866.18z"/>
					</g>
					<g>
						<path className="st120" d="M654.64,1870.01c-0.01,0-0.03,0-0.04,0c-0.08-0.01-0.16-0.01-0.23-0.02c-0.25-0.02-0.28-0.07-0.52-0.07
							c-0.17,0.08-0.25,0.14-0.25,0.14c0.06-0.06,0.43-0.05,0.9,0c0.14,0,0.28,0,0.41-0.01
							C654.82,1870.03,654.73,1870.02,654.64,1870.01z"/>
					</g>
					<g>
						<path className="st120" d="M637.73,1859.55c-0.06,0.02-0.24,0.05-0.49,0.09c0.15,0.08,0.13,0.35-0.08,0.38l-4.99,0.7
							c-1.95,0.59-1.85,1.05-1.95,1.72c-0.11,0.66-2.56,1.52-4.01,1.95c0.21-0.04,0.43-0.08,0.64-0.14c1.15-0.4,2.38-0.77,3.42-1.45
							c0.3-0.2,0.86-0.03,0.91-0.54c0.04-0.41-0.53-0.38-0.72-0.65c2.99-1.7,6.42-1.09,9.57-1.95c-0.14-0.01-0.28-0.02-0.42-0.03
							c-0.21-0.02-0.43-0.04-0.64-0.05c-0.12-0.01-0.25-0.02-0.37-0.02c-0.02,0-0.04,0-0.06,0c-0.08,0-0.16-0.01-0.24-0.01
							C638.11,1859.54,637.92,1859.54,637.73,1859.55z"/>
					</g>
					<g>
						<path className="st120" d="M614.37,1868.05c0.14-0.17,0.36-0.34,0.45-0.52c0.02-0.04,0.04-0.08,0.04-0.13
							c0.02-0.16,0.05-0.3,0.09-0.43c-1.27,0.41-2.25,1.14-2.3,2.35c0,0.03,0.03,0.06,0.08,0.09c0.04,0,0.07,0.01,0.11,0.01
							C613.24,1868.86,613.72,1868.4,614.37,1868.05z"/>
					</g>
					<g>
						<path className="st120" d="M637.12,1859.61l0.02,0c0.04,0,0.07,0.01,0.09,0.03c0.25-0.04,0.43-0.07,0.49-0.09
							C637.52,1859.56,637.32,1859.58,637.12,1859.61z"/>
					</g>
					<g>
						<path className="st120" d="M645.87,1851.45c0.38,0,0.76,0.01,1.15,0.02C646.63,1851.46,646.25,1851.45,645.87,1851.45z"/>
					</g>
					<g>
						<path className="st113" d="M672.22,1854.51C672.22,1854.51,672.22,1854.51,672.22,1854.51
							C672.22,1854.51,672.22,1854.51,672.22,1854.51c0,0,0.01,0.01,0.01,0.01c0,0,0,0,0,0
							C672.23,1854.52,672.23,1854.51,672.22,1854.51z"/>
					</g>
					<g>
						<path className="st119" d="M626.73,1859.07c-0.01,0-0.03-0.01-0.04-0.01C626.7,1859.07,626.71,1859.07,626.73,1859.07z"/>
					</g>
					<g>
						<path className="st119" d="M626.87,1859.09c-0.01,0-0.03,0-0.04,0C626.84,1859.09,626.85,1859.09,626.87,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M626.91,1859.09c0.04,0,0.07,0.01,0.1,0.03c0,0,0.01,0,0.01,0.01c-0.02-0.01-0.04-0.02-0.05-0.04
							c-0.02,0-0.04,0-0.07,0C626.9,1859.09,626.91,1859.09,626.91,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M627.12,1858.96l0.31-1.39c0.02-0.11-0.05-0.22-0.16-0.24l-18.01-3.66c-0.55-0.12-1.25-0.61-1.2-1.18
							c0.03-0.38,13.73-1,15.02-1.06c4.19-0.19,8.62-0.39,13.1-0.51c-2.87-0.29-5.75-0.21-8.62-0.2c-0.03,0-0.05,0-0.08,0
							c-0.16,0.01-0.3,0.06-0.38,0.2c-0.01,0.02-0.03,0.05-0.04,0.08c0,0,0,0,0,0c-0.36,0.13-0.71,0.05-1.07,0
							c-0.05-0.01-0.1-0.02-0.15-0.02c-3.33-0.19-6.67,0.15-10-0.24c0.75,0.47,1.66,0.21,2.52,0.64c-0.48,0.24-0.86,0.15-1.23,0.15
							c-2.68,0.01-5.37-0.02-8.05,0.03c-0.99,0.02-2.06-0.02-2.93,0.54c-0.6,0.38-1.19,0.52-1.87,0.5
							c-2.33-0.06-4.62-0.46-6.94-0.63c-2.42-0.18-4.85-0.26-7.26-0.58c-0.14-0.02-0.28-0.03-0.42-0.05
							c-0.71-0.06-1.42-0.05-2.13-0.04c-0.04,0.05-0.08,0.11-0.12,0.16c3.4,0.83,6.68,2.18,10.27,2.16
							c1.61-0.01,3.31,0.28,4.53,1.34c1.43,1.24,3.08,1.52,4.78,1.8c2.78,0.45,5.56,0.85,8.35,1.26c0,0,0.42-0.11,0.9-0.23
							c0.04-0.02,0.08-0.04,0.12-0.06l0.08,0c0.54-0.14,1.1-0.27,1.21-0.27c2.01,0.04,1.76-0.01,3.78,0c0.17,0,1.11,0.6,1.23,0.55
							c0,0,0,0,0,0c0,0,0,0,0,0c0.03-0.01,0.05-0.01,0.06-0.01c0.9-0.13,1.64,0.4,2.45,0.62c0,0,0,0,0,0
							c0.02,0.04,0.04,0.08,0.06,0.12c0,0,0.01,0,0.01,0c-0.01-0.08,0.04-0.17,0.14-0.18c0.02,0,0.03,0,0.05,0c0.02,0,0.04,0,0.06,0
							s0.04,0,0.06,0c0.04,0,0.08,0.01,0.12,0.02c0.03,0.01,0.06,0.01,0.08,0.02c0.08,0.03,0.16,0.06,0.24,0.1
							c0.01,0,0.01,0.01,0.02,0.01c0.02,0.01,0.03,0.02,0.05,0.02c0.12,0.07,0.24,0.14,0.36,0.2c0.02,0.01,0.04,0.02,0.06,0.03
							c0.01,0,0.01,0.01,0.02,0.01c0.02,0.01,0.03,0.01,0.05,0.02c0.01,0,0.02,0.01,0.02,0.01c0.01,0.01,0.03,0.01,0.04,0.02
							c0.01,0,0.02,0.01,0.03,0.01c0.01,0,0.03,0.01,0.04,0.01c0.01,0,0.02,0,0.03,0.01c0.01,0,0.03,0.01,0.04,0.01
							c0.01,0,0.02,0,0.03,0c0.01,0,0.03,0,0.04,0.01c0.01,0,0.02,0,0.03,0c0.02,0,0.03,0,0.05,0c0.01,0,0.02,0,0.02,0
							c0.02,0,0.05,0,0.07,0c0,0,0,0,0,0c0,0,0,0,0,0c0.02,0.01,0.04,0.03,0.05,0.04c0.03,0.02,0.05,0.03,0.08,0.05
							c0.21,0.11,0.44,0.13,0.66,0.13c0,0,0,0,0,0c0.08,0,0.15,0,0.23,0c0.05,0,0.1,0,0.15,0c0.53,0.01,1.05,0.02,1.58,0.02
							c0,0,0-0.01-0.01-0.01l-2.41-0.1C627.19,1859.2,627.1,1859.08,627.12,1858.96z"/>
					</g>
					<g>
						<path className="st119" d="M626.66,1859.06c-0.01,0-0.03-0.01-0.04-0.01C626.63,1859.05,626.64,1859.05,626.66,1859.06z"/>
					</g>
					<g>
						<path className="st119" d="M626.8,1859.09c-0.01,0-0.03,0-0.04-0.01C626.77,1859.08,626.78,1859.08,626.8,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M626.52,1859.01c-0.01-0.01-0.03-0.01-0.04-0.02C626.5,1859,626.51,1859.01,626.52,1859.01z"/>
					</g>
					<g>
						<path className="st119" d="M626.4,1858.95c0.02,0.01,0.04,0.02,0.06,0.03C626.44,1858.97,626.42,1858.96,626.4,1858.95z"/>
					</g>
					<g>
						<path className="st119" d="M626.59,1859.04c-0.01,0-0.03-0.01-0.04-0.02C626.56,1859.03,626.58,1859.03,626.59,1859.04z"/>
					</g>
					<g>
						<path className="st119" d="M627.09,1850.92c0.09-0.14,0.23-0.19,0.38-0.2C627.32,1850.73,627.18,1850.78,627.09,1850.92z"/>
					</g>
					<g>
						<path className="st119" d="M625.98,1851c0.36,0.05,0.71,0.13,1.07,0c0,0,0,0,0,0C626.69,1851.12,626.34,1851.05,625.98,1851z"/>
					</g>
					<g>
						<path className="st119" d="M627.02,1859.12c-0.02-0.01-0.04-0.02-0.05-0.04c0,0,0,0,0,0C626.98,1859.1,627,1859.11,627.02,1859.12
							C627.02,1859.12,627.02,1859.12,627.02,1859.12z"/>
					</g>
					<g>
						<path className="st119" d="M626.04,1858.75c0.12,0.07,0.24,0.14,0.36,0.2C626.28,1858.89,626.16,1858.82,626.04,1858.75z"/>
					</g>
					<g>
						<path className="st119" d="M587.52,1851.29C587.52,1851.29,587.52,1851.29,587.52,1851.29c0.71,0,1.43-0.02,2.13,0.04
							C588.94,1851.28,588.23,1851.29,587.52,1851.29z"/>
					</g>
					<g>
						<path className="st119" d="M626.9,1859.09c-0.01,0-0.02,0-0.02,0C626.88,1859.09,626.89,1859.09,626.9,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M627.02,1859.12c0.03,0.02,0.05,0.03,0.08,0.05C627.07,1859.16,627.05,1859.14,627.02,1859.12z"/>
					</g>
					<g>
						<path className="st119" d="M626.46,1858.98c0.01,0,0.01,0.01,0.02,0.01C626.47,1858.99,626.47,1858.99,626.46,1858.98z"/>
					</g>
					<g>
						<path className="st119" d="M628,1859.3c-0.08,0-0.15,0-0.23,0c0,0,0,0,0,0c-0.23,0-0.46-0.02-0.66-0.13
							c-0.03-0.01-0.06-0.03-0.08-0.05c0,0-0.01,0-0.01-0.01c-0.03-0.02-0.06-0.03-0.1-0.03c0,0-0.01,0-0.01,0
							c-0.01,0-0.02,0-0.03,0c-0.01,0-0.03,0-0.04,0c-0.01,0-0.02,0-0.03,0c-0.01,0-0.03,0-0.04-0.01c-0.01,0-0.02,0-0.03-0.01
							c-0.01,0-0.03-0.01-0.04-0.01c-0.01,0-0.02,0-0.03-0.01c-0.01,0-0.03-0.01-0.04-0.01c-0.01,0-0.02-0.01-0.03-0.01
							c-0.01,0-0.03-0.01-0.04-0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.01-0.01-0.03-0.01-0.04-0.02c-0.01,0-0.01-0.01-0.02-0.01
							c-0.02-0.01-0.04-0.02-0.06-0.03c-0.12-0.06-0.24-0.14-0.36-0.2c-0.02-0.01-0.03-0.02-0.05-0.02c-0.01,0-0.01-0.01-0.02-0.01
							c-0.08-0.04-0.16-0.08-0.24-0.1c-0.03-0.01-0.06-0.02-0.08-0.02c-0.04-0.01-0.08-0.02-0.12-0.02c-0.02,0-0.04,0-0.06,0
							s-0.04,0-0.06,0c-0.02,0-0.03,0-0.05,0c-0.09,0.01-0.15,0.1-0.14,0.18c0.93,0.28,1.84,0.58,2.56,0.81
							c1.88,0.45,2.17,0.19,1.94-0.25c-0.53-0.01-1.05-0.01-1.58-0.02C628.1,1859.3,628.05,1859.3,628,1859.3z"/>
					</g>
					<g>
						<path className="st119" d="M626.73,1859.07c0.01,0,0.02,0,0.03,0C626.75,1859.08,626.74,1859.08,626.73,1859.07z"/>
					</g>
					<g>
						<path className="st119" d="M626.8,1859.09c0.01,0,0.02,0,0.03,0C626.82,1859.09,626.81,1859.09,626.8,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M626.66,1859.06c0.01,0,0.02,0,0.03,0.01C626.68,1859.06,626.67,1859.06,626.66,1859.06z"/>
					</g>
					<g>
						<path className="st119" d="M626.53,1859.01c0.01,0,0.02,0.01,0.02,0.01C626.54,1859.02,626.53,1859.02,626.53,1859.01z"/>
					</g>
					<g>
						<path className="st119" d="M626.59,1859.04c0.01,0,0.02,0.01,0.03,0.01C626.61,1859.04,626.6,1859.04,626.59,1859.04z"/>
					</g>
					<g>
						<path className="st119" d="M627.1,1859.17c-0.03-0.01-0.05-0.03-0.08-0.05c0,0,0,0,0,0
							C627.05,1859.14,627.07,1859.16,627.1,1859.17z"/>
					</g>
					<g>
						<path className="st119" d="M589.65,1851.34c0.14,0.01,0.28,0.03,0.42,0.05c2.41,0.32,4.84,0.4,7.26,0.58
							c2.32,0.17,4.61,0.57,6.94,0.63c0.68,0.02,1.27-0.12,1.87-0.5c0.87-0.55,1.94-0.52,2.93-0.54c2.68-0.05,5.37-0.02,8.05-0.03
							c0.37,0,0.76,0.09,1.23-0.15c-0.85-0.42-1.76-0.16-2.52-0.64c3.33,0.39,6.67,0.05,10,0.24c0.05,0.01,0.1,0.01,0.15,0.02
							c0.36,0.05,0.71,0.13,1.07,0c0,0,0,0,0,0c0.01-0.03,0.02-0.06,0.04-0.08c0.09-0.14,0.23-0.19,0.38-0.2c0.03,0,0.05,0,0.08,0
							c2.88-0.01,5.76-0.08,8.62,0.2c0.65-0.02,1.29-0.03,1.94-0.05c-1.95-0.68-4.32-0.99-6.38-1.13
							c-5.77-0.37-11.57-0.15-17.35,0.23c0.12,0.17,0.29,0.32,0.47,0.45c0.41,0.27,0.23,0.91-0.26,0.94
							c-1.75,0.11-3.47,0.15-5.3-0.01c-0.71-0.06-1.86,0.02-2.56,0.18c-0.4,0.1-0.78,0.15-1.18,0.28c-1.03,0.33-2.77,0.45-3.84,0.31
							c-4.45-0.61-9.39-0.85-13.97-1.16c-0.08,0.11-0.17,0.22-0.25,0.33C588.23,1851.29,588.94,1851.28,589.65,1851.34z"/>
					</g>
					<g>
						<path className="st119" d="M622.66,1858.12c-0.01-0.02-0.02-0.05-0.02-0.11c-0.12,0.05-1.06-0.55-1.23-0.55
							c-2.02-0.01-1.77,0.04-3.78,0c-0.11,0-0.66,0.13-1.21,0.27l5.78,0.33C622.35,1858.07,622.5,1858.1,622.66,1858.12z"/>
					</g>
					<g>
						<path className="st119" d="M622.64,1858.02c0.02,0,0.04,0,0.06-0.01C622.69,1858,622.67,1858.01,622.64,1858.02z"/>
					</g>
					<g>
						<path className="st119" d="M622.71,1858.13c0.03-0.03,0.04-0.11,0-0.12c-0.02,0-0.04,0-0.06,0.01c0,0.05,0.01,0.09,0.02,0.11
							C622.68,1858.13,622.69,1858.13,622.71,1858.13z"/>
					</g>
					<g>
						<path className="st119" d="M622.7,1858.01c0.05,0.01,0.03,0.09,0,0.12c0.76,0.13,1.64,0.36,2.5,0.62
							c-0.02-0.04-0.04-0.08-0.06-0.12C624.34,1858.41,623.6,1857.88,622.7,1858.01z"/>
					</g>
					<g>
						<path className="st119" d="M599.79,1854.33c1.29,0.41,2.49,1.06,3.75,1.56c2.04,0.8,4.21,1.15,6.34,1.58
							c2.21,0.45,4.17,1.27,6.35,0.31c-0.48,0.12-0.9,0.23-0.9,0.23c-2.78-0.42-5.57-0.81-8.35-1.26c-1.7-0.28-3.36-0.55-4.78-1.8
							c-1.22-1.06-2.92-1.35-4.53-1.34c-3.59,0.02-6.87-1.34-10.27-2.16c-0.07,0.1-0.15,0.19-0.22,0.29c1.64,1.05,3.93,1.5,5.77,1.7
							C595.24,1853.69,597.6,1853.63,599.79,1854.33z"/>
					</g>
					<g>
						<path className="st119" d="M625.21,1858.75C625.21,1858.75,625.21,1858.75,625.21,1858.75c-0.02-0.04-0.04-0.08-0.06-0.12
							c0,0,0,0,0,0C625.17,1858.67,625.19,1858.71,625.21,1858.75z"/>
					</g>
					<g>
						<path className="st119" d="M623.08,1851.43c-1.29,0.06-14.98,0.68-15.02,1.06c-0.05,0.56,0.65,1.06,1.2,1.18l18.01,3.66
							c0.11,0.02,0.18,0.13,0.16,0.24l-0.31,1.39c-0.03,0.12,0.06,0.24,0.19,0.25l2.41,0.1c-0.3-0.57-1.44-1.42-1.11-1.45
							c-0.21-0.48-1.39-0.65-1.9-0.75c-5.51-1.14-11.01-2.28-16.52-3.41c-0.68-0.14-1.41-0.31-1.86-0.84
							c2.82-0.59,5.71-0.75,8.58-0.91c6.49-0.36,13.23-0.63,19.76-0.97c-0.17-0.02-0.33-0.04-0.5-0.05
							C631.7,1851.04,627.27,1851.24,623.08,1851.43z"/>
					</g>
					<g>
						<path className="st119" d="M638.12,1850.87c-0.65,0.01-1.29,0.03-1.94,0.05c0.17,0.02,0.33,0.03,0.5,0.05
							c0.5-0.03,1-0.05,1.49-0.08C638.15,1850.88,638.14,1850.88,638.12,1850.87z"/>
					</g>
					<g>
						<path className="st119" d="M627.1,1859.17c0.21,0.11,0.44,0.13,0.66,0.13C627.54,1859.3,627.31,1859.28,627.1,1859.17z"/>
					</g>
					<g>
						<path className="st119" d="M626.73,1859.07C626.73,1859.07,626.73,1859.07,626.73,1859.07c-0.01,0-0.03-0.01-0.04-0.01
							c0,0,0,0,0,0C626.7,1859.07,626.71,1859.07,626.73,1859.07z"/>
					</g>
					<g>
						<path className="st119" d="M626.59,1859.04C626.59,1859.04,626.59,1859.04,626.59,1859.04c-0.01,0-0.03-0.01-0.04-0.02
							c0,0,0,0,0,0C626.56,1859.03,626.58,1859.03,626.59,1859.04z"/>
					</g>
					<g>
						<path className="st119" d="M626.52,1859.01C626.53,1859.01,626.53,1859.01,626.52,1859.01c-0.02-0.01-0.03-0.01-0.05-0.02
							c0,0,0,0,0,0C626.5,1859,626.51,1859.01,626.52,1859.01z"/>
					</g>
					<g>
						<path className="st119" d="M626.66,1859.06C626.66,1859.06,626.66,1859.06,626.66,1859.06c-0.01,0-0.03-0.01-0.04-0.01
							c0,0,0,0,0,0C626.63,1859.05,626.64,1859.05,626.66,1859.06z"/>
					</g>
					<g>
						<path className="st119" d="M626.46,1858.98c-0.02-0.01-0.04-0.02-0.06-0.03C626.42,1858.96,626.44,1858.97,626.46,1858.98
							C626.46,1858.98,626.46,1858.98,626.46,1858.98z"/>
					</g>
					<g>
						<path className="st119" d="M626.87,1859.09C626.87,1859.09,626.87,1859.09,626.87,1859.09c-0.01,0-0.03,0-0.04,0c0,0,0,0,0,0
							C626.84,1859.09,626.85,1859.09,626.87,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M626.8,1859.09C626.8,1859.09,626.8,1859.09,626.8,1859.09c-0.01,0-0.03,0-0.04-0.01c0,0,0,0,0,0
							C626.77,1859.08,626.78,1859.08,626.8,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M626.96,1859.09C626.96,1859.09,626.96,1859.09,626.96,1859.09c-0.02,0-0.05,0-0.07,0c0,0,0,0,0,0
							C626.92,1859.09,626.94,1859.09,626.96,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M626.69,1859.07C626.69,1859.07,626.69,1859.07,626.69,1859.07c-0.01,0-0.02,0-0.03-0.01c0,0,0,0,0,0
							C626.67,1859.06,626.68,1859.06,626.69,1859.07z"/>
					</g>
					<g>
						<path className="st119" d="M626.76,1859.08C626.76,1859.08,626.75,1859.08,626.76,1859.08c-0.01,0-0.02,0-0.03-0.01c0,0,0,0,0,0
							C626.74,1859.08,626.75,1859.08,626.76,1859.08z"/>
					</g>
					<g>
						<path className="st119" d="M626.62,1859.05C626.62,1859.05,626.62,1859.05,626.62,1859.05c-0.01,0-0.02-0.01-0.03-0.01
							c0,0,0,0,0,0C626.6,1859.04,626.61,1859.04,626.62,1859.05z"/>
					</g>
					<g>
						<path className="st119" d="M626.9,1859.09C626.9,1859.09,626.9,1859.09,626.9,1859.09c-0.01,0-0.02,0-0.03,0c0,0,0,0,0,0
							C626.88,1859.09,626.89,1859.09,626.9,1859.09z"/>
					</g>
					<g>
						<path className="st119" d="M626.48,1858.99C626.48,1858.99,626.48,1858.99,626.48,1858.99c-0.01,0-0.01-0.01-0.02-0.01
							c0,0,0,0,0,0C626.47,1858.99,626.47,1858.99,626.48,1858.99z"/>
					</g>
					<g>
						<path className="st119" d="M626.55,1859.02C626.55,1859.02,626.55,1859.02,626.55,1859.02c-0.01,0-0.02-0.01-0.03-0.01
							c0,0,0,0,0,0C626.53,1859.02,626.54,1859.02,626.55,1859.02z"/>
					</g>
					<g>
						<path className="st119" d="M626.83,1859.09C626.83,1859.09,626.82,1859.09,626.83,1859.09c-0.01,0-0.02,0-0.03,0c0,0,0,0,0,0
							C626.81,1859.09,626.82,1859.09,626.83,1859.09z"/>
					</g>
					<g>
						<polygon className="st119" points="622.64,1858.02 622.64,1858.02 622.64,1858.02 						"/>
					</g>
					<g>
						<path className="st119" d="M625.15,1858.63C625.15,1858.63,625.15,1858.63,625.15,1858.63
							C625.15,1858.63,625.15,1858.63,625.15,1858.63c-0.81-0.22-1.55-0.75-2.45-0.62c0,0,0,0,0,0
							C623.6,1857.88,624.34,1858.41,625.15,1858.63z"/>
					</g>
					<g>
						<path className="st119" d="M622.7,1858.01c-0.02,0-0.04,0-0.06,0.01c0,0,0,0,0,0C622.66,1858.01,622.68,1858.01,622.7,1858.01
							C622.7,1858.01,622.7,1858.01,622.7,1858.01z"/>
					</g>
					<g>
						<path className="st111" d="M631.79,1859.39l-0.05,0c0,0.03,0.01,0.07,0.01,0.1C631.77,1859.46,631.78,1859.43,631.79,1859.39z"/>
					</g>
					<g>
						<path className="st111" d="M631.81,1859.36c0,0-0.03,0-0.05-0.01c-0.01,0-0.02,0-0.02,0c0,0,0,0,0,0c0,0.02,0,0.03,0.01,0.05
							l0.05,0C631.8,1859.38,631.8,1859.37,631.81,1859.36z"/>
					</g>
					<g>
						<path className="st111" d="M631.76,1859.49C631.76,1859.49,631.76,1859.49,631.76,1859.49L631.76,1859.49
							c0.01-0.03,0.03-0.07,0.04-0.1h0C631.78,1859.43,631.77,1859.46,631.76,1859.49z"/>
					</g>
					<g>
						<polygon className="st111" points="631.76,1859.49 631.76,1859.49 631.76,1859.49 						"/>
					</g>
					<g>
						<path className="st111" d="M631.8,1859.39c0.01-0.01,0.01-0.03,0.01-0.04c0,0,0,0,0,0C631.8,1859.37,631.8,1859.38,631.8,1859.39
							L631.8,1859.39z"/>
					</g>
					<g>
						<path className="st119" d="M627.05,1851c0.01-0.03,0.02-0.06,0.04-0.08C627.08,1850.94,627.06,1850.97,627.05,1851
							C627.05,1851,627.05,1851,627.05,1851z"/>
					</g>
					<g>
						<path className="st109" d="M686.06,1850.73c-0.03,0-2.44,1.02-2.46,1.02c-0.02,0-0.04,0-0.07,0.01c-0.03,0-0.05,0.01-0.08,0.01
							c-0.02,0-0.04,0.01-0.06,0.01c-0.03,0.01-0.07,0.02-0.1,0.03c-0.1,0.03-0.2,0.07-0.3,0.12c-0.04,0.02-0.08,0.05-0.12,0.08
							c-0.2,0.14-0.46,0.34-0.09,0.54c0.91,0.51,0.23,0.59-0.18,0.74c-1.27,0.46-2.69,0.54-3.87,1.22
							c-0.37,0.21-0.74,0.38-1.11,0.51c-0.1,0.04-0.21,0.07-0.31,0.1c-0.73,0.21-1.48,0.26-2.27,0.18c-0.29-0.18-0.6-0.25-0.92-0.3
							c-0.11-0.02-0.22,0-0.33,0.04c0.24,0.11,0.48,0.24,0.71,0.4c0.81,0.54,1.47,0.93,2.35,1.06c0.17,0.01,0.33,0.02,0.5,0.04
							c0.05,0,0.11,0,0.16,0c0.04,0,0.08,0,0.13,0.01c-0.04-0.03-0.09-0.05-0.13-0.08c0.04,0.03,0.09,0.06,0.13,0.08
							c0.53,0.05,1.31,0.37,1.29,0.82c0,0.06-0.03,0.11-0.07,0.15l-0.1,0.25c-0.13,0.11-0.57,0.32-0.4,0.35l0.75,0.14
							c0,0,0.56,0.01,0.88,0.01c0.08,0,0.15,0,0.23,0c0,0,0.8-0.79,0.8-0.79c0.44-0.12,0.07,0.55,0.51,0.44
							c1.37-0.34,2.62-1.13,4.12-0.97c0.1,0.01,0.21,0,0.31-0.02c0.04-0.01,0.08-0.02,0.12-0.03c0.28-0.09,0.57-0.24,0.85-0.34
							c-0.32-0.7-0.9-1.35-0.81-2.16c0.07-0.66-0.1-1.18-0.39-1.63c-0.05-0.08-0.11-0.17-0.17-0.25c-0.02-0.03-0.04-0.05-0.06-0.08
							c-0.05-0.06-0.11-0.13-0.16-0.19c-0.03-0.04-0.07-0.08-0.11-0.12c-0.05-0.05-0.1-0.1-0.14-0.15"/>
					</g>
					<g>
						<path className="st109" d="M677.63,1856.54L677.63,1856.54c-0.04-0.03-0.09-0.05-0.13-0.08
							C677.54,1856.48,677.59,1856.51,677.63,1856.54z"/>
					</g>
					<g>
						<path className="st109" d="M675.03,1855.29c-0.29-0.18-0.6-0.25-0.92-0.3C674.43,1855.04,674.74,1855.11,675.03,1855.29
							c0.79,0.08,1.54,0.03,2.27-0.18C676.57,1855.32,675.82,1855.37,675.03,1855.29z"/>
					</g>
					<g>
						<path className="st109" d="M672.27,1854.5c0.44,0.13,0.87,0.26,1.29,0.44c0.18,0.02,0.37,0.03,0.55,0.06
							c0.32,0.05,0.64,0.12,0.92,0.3c0.79,0.08,1.54,0.03,2.27-0.18C676.58,1855.31,673.12,1854.59,672.27,1854.5z"/>
					</g>
					<g>
						<path className="st109" d="M673.78,1855.03c0.11-0.04,0.22-0.05,0.33-0.04c-0.18-0.03-0.37-0.04-0.55-0.06
							C673.63,1854.96,673.7,1855,673.78,1855.03z"/>
					</g>
					<g>
						<polygon className="st109" points="683.96,1851.74 683.96,1851.74 683.97,1851.74 						"/>
					</g>
					<g>
						<path className="st109" d="M681,1857.46c0,0-0.8,0.79-0.8,0.79h0c0.44-0.12,0.87-0.24,1.31-0.35
							C681.07,1858.01,681.44,1857.35,681,1857.46z"/>
					</g>
					<g>
						<path className="st109" d="M679.09,1858.24c0.07,0.01,0.12,0.05,0.14,0.11c0.25-0.04,0.49-0.09,0.74-0.1
							C679.65,1858.25,679.09,1858.24,679.09,1858.24z"/>
					</g>
					<g>
						<path className="st109" d="M678.81,1858.41c0.1-0.06,0.2-0.12,0.29-0.17l-0.75-0.14c-0.17-0.03,0.27-0.24,0.4-0.35l0.1-0.25
							c0.04-0.04,0.07-0.09,0.07-0.15c0.01-0.45-0.76-0.77-1.29-0.82c0.03,0.02,0.06,0.04,0.09,0.05c0.35,0.08,0.67,0.23,0.91,0.52
							c0.37,0.46-0.17,0.65-0.57,0.79c-0.02,0.01-0.03,0.01-0.05,0.02c-0.03,0.01-0.06,0.02-0.09,0.04
							c-0.12,0.05-0.2,0.12-0.23,0.22c0.1,0.07,0.21,0.12,0.31,0.15c0.08,0.03,0.16,0.05,0.25,0.06c0.05,0.01,0.09,0.01,0.14,0.02
							c0,0,0.01,0,0.01,0C678.53,1858.43,678.67,1858.42,678.81,1858.41z"/>
					</g>
					<g>
						<path className="st109" d="M677.5,1856.53c-0.05,0-0.11,0-0.16,0c0.13,0.01,0.26,0.03,0.38,0.06c-0.03-0.02-0.06-0.03-0.09-0.05
							C677.59,1856.53,677.54,1856.53,677.5,1856.53z"/>
					</g>
					<g>
						<path className="st109" d="M673.78,1855.03c-0.18,0.06-0.35,0.16-0.52,0.27c0.22,0.1,0.42,0.18,0.51,0.22
							c0.84,0.41,1.68,0.82,2.61,0.93c0.15,0.02,0.3,0.03,0.46,0.04c-0.88-0.13-1.54-0.52-2.35-1.06
							C674.25,1855.27,674.02,1855.14,673.78,1855.03z"/>
					</g>
					<g>
						<path className="st109" d="M677.63,1856.54c0.03,0.02,0.06,0.04,0.09,0.05c0,0,0,0,0,0
							C677.69,1856.57,677.66,1856.56,677.63,1856.54L677.63,1856.54z"/>
					</g>
					<g>
						<path className="st109" d="M672.27,1854.5c-0.01,0-0.02,0-0.03,0c0,0.01,0,0.02,0,0.03c0.4,0.3,0.86,0.37,1.32,0.41
							C673.14,1854.76,672.71,1854.62,672.27,1854.5z"/>
					</g>
					<g>
						<path className="st109" d="M673.55,1854.93c-0.47-0.05-0.92-0.12-1.32-0.41c-0.01,0.28,0.56,0.58,1.02,0.78
							c0.17-0.11,0.35-0.21,0.52-0.27C673.7,1855,673.63,1854.96,673.55,1854.93z"/>
					</g>
					<g>
						<path className="st109" d="M679.09,1858.24c-0.09,0.05-0.19,0.11-0.29,0.17c0.14-0.01,0.29-0.04,0.43-0.06
							C679.21,1858.3,679.16,1858.25,679.09,1858.24z"/>
					</g>
					<g>
						<path className="st109" d="M673.55,1854.93c0.18,0.02,0.37,0.03,0.55,0.06C673.92,1854.97,673.74,1854.95,673.55,1854.93
							C673.55,1854.93,673.55,1854.93,673.55,1854.93z"/>
					</g>
					<g>
						<path className="st109" d="M672.23,1854.52C672.23,1854.52,672.23,1854.52,672.23,1854.52c0.4,0.3,0.86,0.37,1.32,0.41
							c0,0,0,0,0,0C673.09,1854.88,672.63,1854.82,672.23,1854.52z"/>
					</g>
					<g>
						<polygon className="st118" points="683.96,1851.74 683.96,1851.74 683.96,1851.74 						"/>
					</g>
					<g>
						<polygon className="st121" points="686.07,1849.85 686.07,1849.85 686.07,1849.85 						"/>
					</g>
					<g>
						<polygon className="st121" points="686.07,1849.85 686.07,1849.85 686.07,1849.85 						"/>
					</g>
					<g>
						<polygon className="st121" points="686.06,1849.85 686.07,1849.85 686.07,1849.85 						"/>
					</g>
					<g>
						<polygon className="st121" points="686.07,1849.85 686.07,1849.85 686.06,1849.85 						"/>
					</g>
					<g>
						<path className="st119" d="M641.78,1865.35c-0.01-0.26-0.3-0.18-0.49-0.21c0.16,0.04,0.33,0.12,0.49,0.22
							C641.78,1865.35,641.78,1865.35,641.78,1865.35z"/>
					</g>
					<g>
						<path className="st119" d="M641.14,1865.11c-0.27-0.06-0.51-0.16-0.71-0.35c-0.13,0.02-0.25,0.05-0.38,0.07
							c-0.17,0.03-0.22,0.25-0.08,0.36l0.57,0.43C640.78,1865.37,641.02,1865.11,641.14,1865.11z"/>
					</g>
					<g>
						<path className="st119" d="M641.78,1865.36c-0.17-0.1-0.33-0.18-0.49-0.22c-0.01,0-0.01,0-0.02,0c-0.04-0.01-0.09-0.02-0.13-0.03
							c-0.12,0-0.36,0.25-0.6,0.51l0.01,0.01c0.22,0.07,0.45,0.12,0.71,0.12C641.47,1865.67,641.78,1865.63,641.78,1865.36z"/>
					</g>
					<g>
						<path className="st119" d="M639.97,1865.19c-0.14-0.11-0.09-0.33,0.08-0.36c0.12-0.02,0.25-0.05,0.38-0.07
							c-0.02-0.02-0.05-0.05-0.07-0.07c0,0,0,0,0,0h0c-0.28,0.07-0.63-0.06-0.87,0.32c0.33,0.27,0.67,0.49,1.04,0.62c0,0,0,0,0-0.01
							L639.97,1865.19z"/>
					</g>
					<g>
						<path className="st119" d="M640.54,1865.63C640.54,1865.63,640.53,1865.63,640.54,1865.63c0,0.01,0.01,0.01,0.01,0.01
							L640.54,1865.63z"/>
					</g>
					<g>
						<path className="st119" d="M641.14,1865.11c-0.27-0.06-0.51-0.16-0.71-0.35c0,0,0,0,0,0
							C640.63,1864.96,640.87,1865.05,641.14,1865.11z"/>
					</g>
					<g>
						<path className="st119" d="M640.36,1864.69C640.36,1864.69,640.36,1864.69,640.36,1864.69c0.02,0.03,0.04,0.05,0.07,0.07
							c0,0,0,0,0,0C640.41,1864.74,640.38,1864.72,640.36,1864.69z"/>
					</g>
					<g>
						<path className="st115" d="M619.82,1866.6c3.45,0.5,4.24,1.59,7.94,0.93c1.19-0.21,4.1,0,3.02-0.55
							c-1.35-0.68-3.64-1.58-5.21-2.02c-0.2-0.05-0.2-0.33,0-0.39c0.2-0.06,0.41-0.12,0.62-0.18c-0.51,0.09-1.03,0.15-1.55,0.26
							c-2.44,0.52-4.94,0.57-7.4,1c-1.06,0.18-1.96,0.28-2.28,1.32C616.57,1866.44,618.63,1866.43,619.82,1866.6z"/>
					</g>
					<g>
						<path className="st115" d="M625.58,1864.58c-0.2,0.06-0.2,0.34,0,0.39c1.57,0.43,3.87,1.34,5.21,2.02
							c1.08,0.54-1.84,0.34-3.02,0.55c-3.71,0.66-4.49-0.43-7.94-0.93c-1.19-0.17-3.26-0.16-4.87,0.37
							c-0.04,0.13-0.07,0.27-0.09,0.43c-0.01,0.04-0.02,0.09-0.04,0.13c-0.09,0.18-0.31,0.34-0.45,0.52
							c1.04,0.09,2.06-0.45,3.1-0.07c2.22,0.82,4.56,0.56,6.82,0.58c2.21,0.02,4.45,0.01,6.67-0.08c0.31-0.01,0.63-0.01,0.93-0.07
							c0.2-0.03,0.43-0.11,0.46-0.36c0.03-0.24-0.14-0.41-0.32-0.49c-1.49-0.65-2.69-1.83-4.29-2.31c-0.66-0.2-1.39,0.08-2.03-0.31
							c0.3-0.47,1.05-0.05,1.12-0.69c-0.21,0.06-0.43,0.11-0.64,0.14C625.98,1864.46,625.78,1864.52,625.58,1864.58z"/>
					</g>
					<g>
						<path className="st109" d="M727.07,1828.07c0,0-0.21,0-0.21,0c0.24,0.54,0.67,1.07,0.91,1.61c0.03,0.07,0.07,0.12,0.11,0.17
							c0,0,0,0.01,0.01,0.01c0.02,0.02,0.04,0.04,0.07,0.05c0.01,0.01,0.02,0.01,0.02,0.02c0.06,0.03,0.12,0.05,0.19,0.06
							c0.01,0,0.01,0,0.02,0c0.07,0,0.13,0,0.2-0.01c0.06-0.01,0.26,0.51,0.33,0.5l0,0l0.41,0.82l0,0c0.01,0-0.01,0,0,0l1.92,0.42
							c1.28-0.32,5.89-2.16,7.25-2.24c0,0,6.13-1.78,7.65-2.07l0.53,0.67c2.29-0.44,4.48-0.97,6.63-1.57
							c1.91-0.72,3.86-1.29,5.81-1.75c3.36-1.18,6.68-2.64,10.14-4.13c1.68-0.73,3.54-0.62,3.99-2.39c0,0.3,0,0.61,0,0.91
							c0.15-0.29,0.06-0.63,0.07-0.94c0.01-3.72,0.03-7.44-0.12-11.16c-2.49,2.58-6.04,3.8-9.45,4.94
							c-3.8,1.26-7.59,2.52-11.39,3.78c-2.78,0.92-5.56,1.85-8.27,2.97c-4.35,1.81-11.01,4.45-14.24,7.92
							c-0.16,0.17-0.25,1.37-0.79,0.18c-0.05-0.1-0.23,0.03-0.34,0.12c-0.4,0.32-0.82,0.62-1.24,0.93"/>
					</g>
					<g>
						<path className="st109" d="M727.88,1829.85C727.88,1829.85,727.88,1829.85,727.88,1829.85
							C727.88,1829.85,727.88,1829.85,727.88,1829.85z"/>
					</g>
					<g>
						<path className="st109" d="M728.18,1829.98c-0.01,0-0.01,0-0.02,0C728.17,1829.98,728.18,1829.98,728.18,1829.98z"/>
					</g>
					<g>
						<path className="st109" d="M727.97,1829.92c-0.01,0-0.02-0.01-0.02-0.02C727.96,1829.91,727.97,1829.92,727.97,1829.92z"/>
					</g>
					<g>
						<path className="st109" d="M731.58,1829.38c0.01,0,0.02,0,0.02,0.01l0,0C731.59,1829.38,731.59,1829.38,731.58,1829.38z"/>
					</g>
					<g>
						<path className="st109" d="M763.56,1824.06c-0.13,0-0.26,0-0.39,0.01c0,0,0,0,0,0C763.3,1824.06,763.43,1824.06,763.56,1824.06z"
							/>
					</g>
					<g>
						<path className="st109" d="M769.06,1820.61c-3.46,1.49-6.78,2.85-10.14,4.04c1.18-0.28,2.36-0.47,3.53-0.55
							c-0.01,0-0.02,0-0.03-0.01c-0.01,0-0.03-0.01-0.04-0.01c-0.03,0-0.06,0-0.09,0c0.03,0,0.06,0,0.08,0
							c-0.04-0.01-0.09-0.03-0.13-0.04c3.65-1.62,7.18-3.18,10.71-4.74c0.05-0.05,0.08-0.11,0.11-0.16c0-0.3,0-0.61,0-0.91
							C772.6,1820,770.74,1819.89,769.06,1820.61z"/>
					</g>
					<g>
						<path className="st109" d="M745.95,1827.1c-1.52,0.29-5.85,1.78-7.65,2.37c0.51-0.03,7.84-2.1,8.36-2.03
							c0,0.01-4.24,0.42-4.24,0.43c0.34-0.04,0.68-0.08,1.03-0.12c0.57-0.07,1.22-0.11,1.67,0.26c0.31,0.25,0.48,0.68,0.84,0.85
							c0.36,0.17,0.78,0.04,1.16-0.09c1.91-0.67,3.93-1.5,6-2.28c-2.14,0.6-4.34,1.13-6.63,1.57L745.95,1827.1z"/>
					</g>
					<g>
						<polygon className="st109" points="763.17,1824.07 763.17,1824.07 763.17,1824.06 763.17,1824.06 						"/>
					</g>
					<g>
						<path className="st109" d="M762.37,1824.08c0.01,0,0.03,0.01,0.04,0.01c0.01,0,0.02,0,0.03,0.01c0.2-0.01,0.39-0.02,0.58-0.03
							C762.81,1824.07,762.59,1824.08,762.37,1824.08z"/>
					</g>
					<g>
						<path className="st109" d="M763.17,1824.06c-0.05,0-0.1,0-0.14,0C763.08,1824.07,763.12,1824.07,763.17,1824.06
							C763.17,1824.07,763.17,1824.07,763.17,1824.06z"/>
					</g>
					<g>
						<path className="st109" d="M762.23,1824.03c0.05,0.02,0.09,0.03,0.13,0.04c2.75-0.07,8.14-3.7,10.58-4.78
							C769.41,1820.85,765.89,1822.42,762.23,1824.03z"/>
					</g>
					<g>
						<path className="st109" d="M740.88,1827.79c-0.42,0.14-0.85,0.28-1.28,0.43c0.93-0.12,1.87-0.23,2.81-0.34c0-0.01,0-0.02,0-0.04
							C741.9,1827.76,741.39,1827.75,740.88,1827.79z"/>
					</g>
					<g>
						<path className="st111" d="M605.58,1851.81c0.4-0.13,0.77-0.18,1.18-0.28c0.7-0.17,1.84-0.25,2.56-0.18
							c1.83,0.16,3.55,0.12,5.3,0.01c0.49-0.03,0.67-0.67,0.26-0.94c-0.19-0.12-0.35-0.27-0.47-0.45c5.77-0.37,11.57-0.6,17.35-0.23
							c2.06,0.13,4.43,0.44,6.38,1.13c3.21-0.07,6.42-0.08,9.58-0.01c-0.02-0.01,0.75-0.02,0.73-0.03
							c-0.27-0.21-1.14-0.57-1.48-0.64c-0.05-0.01-0.1-0.02-0.15-0.03c-0.08-0.02-0.16-0.04-0.23-0.07
							c-0.15-0.06-0.3-0.13-0.43-0.25c-1.2-1.05-2.64-0.99-4.06-0.69c-1,0.2-2.01,0.4-3.01,0.25c-2.37-0.37-4.74-0.71-7.05-1.42
							c-2.05-0.63-4.14-1.17-6.36-0.69c-1,0.21-2.08,0.27-3.08-0.23c-0.32-0.16-0.67-0.1-1.02,0.01c-2.87,0.92-5.78,1.63-8.82,1.3
							c-5.13-0.57-10.3-0.48-15.42-1.06c-1.66-0.19-3.42,0.03-4.95-0.66c-0.35-0.16-0.69-0.31-1.03-0.43
							c-1.19,1.6-2.39,3.19-3.59,4.75c4.58,0.3,9.52,0.55,13.97,1.16C602.81,1852.26,604.54,1852.14,605.58,1851.81z"/>
						<path className="st42" d="M602.76,1852.28c-0.38,0-0.73-0.02-1.04-0.06c-3.67-0.5-7.67-0.75-11.54-1
							c-0.81-0.05-1.62-0.1-2.42-0.16l-0.19-0.01l0.11-0.15c1.22-1.6,2.43-3.2,3.59-4.75l0.04-0.06l0.07,0.03
							c0.37,0.14,0.75,0.31,1.03,0.44c1.03,0.47,2.21,0.51,3.34,0.55c0.52,0.02,1.05,0.04,1.57,0.1c2.6,0.3,5.26,0.42,7.82,0.55
							c2.49,0.12,5.07,0.24,7.6,0.52c3.12,0.34,6.06-0.42,8.77-1.29c0.35-0.11,0.73-0.19,1.09-0.01c0.82,0.41,1.78,0.48,3.01,0.22
							c2.25-0.48,4.4,0.08,6.41,0.7c2.11,0.65,4.31,0.99,6.44,1.32l0.6,0.09c1,0.16,2.05-0.06,2.97-0.25
							c1.34-0.27,2.87-0.4,4.15,0.72c0.11,0.1,0.24,0.17,0.4,0.23c0.07,0.02,0.14,0.05,0.22,0.07c0.05,0.01,0.1,0.02,0.15,0.03
							c0.35,0.07,1.24,0.44,1.52,0.66l0.06,0.04l-0.02,0.07c-0.02,0.05-0.02,0.07-0.23,0.08l0.12,0.04l-0.72-0.02
							c-2.92-0.07-6.06-0.06-9.58,0.01l-0.02,0l-0.02-0.01c-1.65-0.58-3.79-0.96-6.35-1.12c-6.05-0.39-12.05-0.11-17.14,0.21
							c0.09,0.1,0.2,0.19,0.33,0.28c0.22,0.15,0.32,0.41,0.25,0.67c-0.07,0.26-0.29,0.44-0.56,0.46c-1.51,0.09-3.33,0.17-5.32-0.01
							c-0.71-0.06-1.84,0.02-2.53,0.18c-0.14,0.03-0.28,0.06-0.41,0.09c-0.26,0.05-0.5,0.11-0.76,0.19l0,0
							C604.87,1852.14,603.75,1852.28,602.76,1852.28z M587.96,1850.88c0.74,0.05,1.48,0.1,2.23,0.14c3.87,0.25,7.88,0.5,11.55,1
							c1.1,0.15,2.8,0.01,3.8-0.3l0.03,0.09l-0.03-0.09c0.27-0.08,0.53-0.14,0.78-0.19c0.13-0.03,0.27-0.06,0.4-0.09
							c0.7-0.17,1.86-0.25,2.59-0.19c1.98,0.18,3.78,0.11,5.29,0.01c0.22-0.01,0.34-0.17,0.38-0.31c0.04-0.14,0.01-0.33-0.17-0.45
							c-0.21-0.14-0.38-0.3-0.5-0.47l-0.1-0.15l0.18-0.01c5.14-0.33,11.22-0.62,17.36-0.23c2.57,0.17,4.72,0.54,6.39,1.13
							c3.52-0.07,6.65-0.07,9.57-0.01v0c0.05-0.01,0.13-0.01,0.26-0.01c0.04,0,0.09,0,0.14,0c-0.36-0.19-0.92-0.41-1.17-0.46
							c-0.05-0.01-0.1-0.02-0.15-0.03c-0.09-0.02-0.17-0.05-0.25-0.07c-0.18-0.07-0.34-0.15-0.47-0.27
							c-0.68-0.6-1.68-1.14-3.98-0.67c-0.94,0.19-2.01,0.41-3.04,0.25l-0.6-0.09c-2.14-0.33-4.34-0.68-6.47-1.33
							c-1.98-0.61-4.11-1.16-6.31-0.69c-1.28,0.27-2.28,0.2-3.14-0.24c-0.3-0.15-0.63-0.08-0.94,0.02c-2.74,0.88-5.7,1.65-8.86,1.3
							c-2.52-0.28-5.09-0.4-7.58-0.52c-2.57-0.12-5.23-0.25-7.84-0.55c-0.51-0.06-1.04-0.08-1.56-0.1
							c-1.16-0.04-2.35-0.09-3.42-0.57c-0.26-0.12-0.6-0.27-0.95-0.4C590.28,1847.82,589.13,1849.35,587.96,1850.88z"/>
					</g>
				</g>
				<g>
					<polygon className="st109" points="709.53,1838.42 728.7,1826.89 733.88,1834.49 					"/>
				</g>
				<g>
					<path className="st109" d="M725.03,1857.32c0.47-0.45,0.95-0.9,1.43-1.34c0.78-0.72,1.57-1.44,2.36-2.17
						c0.31-0.28,0.69-0.48,1.04-0.71c0.53-0.34,1.03-0.67,1.29-1.27c0.31-0.7,0.85-1.29,1.38-1.91c-0.4,0.12-0.8,0.25-1.21,0.35
						c-1.53,0.37-2.76,1.26-4.07,2.02c-1.17,0.68-2.31,1.42-3.52,2.05c-1.59,0.83-3.12,1.73-4.71,2.56
						c-0.88,0.46-1.58,1.1-2.36,1.67c-0.27,0.2-0.53,0.4-0.79,0.6c-0.03-0.02-0.05-0.04-0.08-0.06c0.21-0.22,0.4-0.45,0.62-0.66
						c0.3-0.3,0.62-0.58,0.91-0.88c0.47-0.48,1.06-0.8,1.64-1.15c0.68-0.41,1.38-0.79,2.04-1.22c0.85-0.55,1.78-1,2.65-1.52
						c0.73-0.44,1.48-0.86,2.15-1.38c0.79-0.61,1.59-1.2,2.39-1.8c0.06-0.05,0.15-0.08,0.16-0.19c-2.1,0.71-3.67,2.12-5.54,3.29
						c0.13-0.3,0.34-0.43,0.53-0.56c0.76-0.54,1.43-1.17,2.13-1.76c0.49-0.41,1.02-0.74,1.61-1.02c0.74-0.35,1.49-0.7,2.21-1.09
						c0.41-0.22,0.76-0.53,1.14-0.8c0.55-0.4,1.17-0.69,1.8-0.93c1.1-0.41,2-1.04,2.82-1.82c0.59-0.57,1.23-1.1,1.86-1.63
						c0.33-0.28,0.77-0.38,1.2-0.49c0.03-0.01,0.07,0,0.11-0.01c0.15-0.03,0.31-0.24,0.43-0.04c0.11,0.17,0.11,0.39-0.04,0.58
						c-0.27,0.34-0.54,0.67-0.8,1.01c-0.35,0.43-0.69,0.86-1.01,1.32c0.21-0.11,0.42-0.21,0.63-0.32c0.48-0.25,0.94-0.54,1.44-0.73
						c0.71-0.26,1.14-0.78,1.63-1.26c0.47-0.47,0.96-0.92,1.44-1.37c0.48-0.45,1.13-0.58,1.74-0.79c0.02,0.02,0.04,0.04,0.06,0.06
						c-0.62,0.66-1.24,1.31-1.86,1.97c0.02,0.02,0.05,0.04,0.07,0.06c0.35-0.29,0.71-0.56,1.05-0.86c0.35-0.31,0.74-0.59,1.06-0.93
						c0.26-0.28,4.54-1.85,4.92-1.85c0.04,0.32,5.83,1.57,5.87,1.89c-0.53,0.21-10.6,1.17-11.07,1.45
						c-0.19,0.11-0.41,0.21-0.53,0.39c-0.42,0.62-0.84,1.25-1.24,1.87c-0.14,0.22-0.34,0.39-0.58,0.53c-1.12,0.65-2.3,1.2-3.45,1.78
						c-0.14,0.07-0.25,0.16-0.34,0.28c-0.31,0.44-0.58,0.9-0.95,1.31c-0.07,0.07-0.17,0.16-0.09,0.34c0.81-0.83,1.63-1.62,2.95-1.82
						c-0.35,0.19-0.65,0.34-0.93,0.51c-0.26,0.16-0.41,0.45-0.37,0.66c0.04,0.17,0.32-0.05,0.47-0.1c0.55-0.18,1.13-0.31,1.57-0.71
						c1.2-1.1,2.4-2.21,3.61-3.31c0.47-0.43,0.88-0.92,1.45-1.27c0.04,0.34,25.04,0.42,25.09,0.76c-0.59,0.25-25.96,0.97-26.43,1.35
						c-1.02,0.83-2.03,1.67-3.02,2.54c-0.76,0.67-1.56,1.3-2.31,1.98c-0.45,0.41-0.99,0.65-1.58,0.73
						c-0.86,0.11-1.43,0.61-1.99,1.11c-0.72,0.65-1.54,1.04-2.53,1.2c-0.85,0.13-1.68,0.34-2.52,0.52
						c-0.66,0.14-1.12,0.58-1.65,0.91c-1.01,0.63-2,1.29-3,1.93C725.27,1857.23,725.17,1857.31,725.03,1857.32
						C725.03,1857.32,725.03,1857.32,725.03,1857.32z"/>
					<path className="st42" d="M715.87,1859.29l-0.22-0.18l0.07-0.08c0.06-0.07,0.12-0.14,0.19-0.2c0.14-0.15,0.28-0.31,0.43-0.46
						c0.13-0.13,0.27-0.26,0.41-0.39c0.17-0.16,0.34-0.32,0.5-0.49c0.4-0.42,0.9-0.71,1.38-1c0.09-0.05,0.18-0.11,0.27-0.16
						c0.27-0.16,0.54-0.32,0.8-0.47c0.41-0.24,0.83-0.48,1.23-0.74c0.5-0.33,1.04-0.62,1.56-0.9c0.36-0.2,0.74-0.4,1.1-0.62
						c0.1-0.06,0.2-0.12,0.3-0.18c0.62-0.37,1.26-0.75,1.83-1.19c0.51-0.4,1.04-0.79,1.55-1.17c0.21-0.16,0.43-0.32,0.64-0.48
						c-1.25,0.5-2.32,1.24-3.44,2.02c-0.52,0.36-1.06,0.74-1.62,1.09l-0.27,0.17l0.13-0.29c0.13-0.3,0.33-0.44,0.5-0.56l0.06-0.04
						c0.53-0.38,1.03-0.81,1.52-1.23c0.2-0.18,0.41-0.35,0.61-0.52c0.49-0.41,1.03-0.75,1.63-1.04l0.3-0.14
						c0.63-0.3,1.29-0.61,1.91-0.94c0.29-0.16,0.56-0.37,0.82-0.57c0.1-0.08,0.2-0.15,0.3-0.23c0.48-0.35,1.07-0.65,1.83-0.94
						c1-0.38,1.91-0.96,2.78-1.8c0.55-0.53,1.16-1.04,1.74-1.53l0.13-0.11c0.33-0.28,0.76-0.39,1.17-0.5l0.07-0.02
						c0.03-0.01,0.06-0.01,0.08-0.01c0.01,0,0.02,0,0.03,0c0.03-0.01,0.07-0.03,0.11-0.05c0.1-0.06,0.29-0.16,0.43,0.05
						c0.14,0.22,0.12,0.48-0.05,0.69c-0.27,0.34-0.54,0.67-0.8,1.01l-0.04,0.06c-0.24,0.3-0.49,0.61-0.73,0.93
						c0.09-0.05,0.18-0.09,0.27-0.14c0.15-0.08,0.3-0.16,0.44-0.24c0.32-0.18,0.66-0.36,1.02-0.49c0.59-0.22,0.97-0.61,1.38-1.03
						c0.07-0.07,0.14-0.14,0.21-0.21c0.34-0.34,0.69-0.67,1.04-0.99c0.14-0.13,0.27-0.25,0.41-0.38c0.4-0.37,0.9-0.53,1.38-0.69
						c0.13-0.04,0.26-0.08,0.39-0.13l0.06-0.02l0.17,0.18l-1.38,1.46c0.15-0.12,0.3-0.24,0.44-0.37c0.13-0.11,0.26-0.22,0.39-0.33
						c0.23-0.19,0.46-0.38,0.66-0.59c0.29-0.32,4.64-1.88,4.99-1.89l0.09,0l0.01,0.07c0.18,0.16,1.66,0.56,2.86,0.88
						c2.48,0.67,2.99,0.84,3.01,1.03l0.01,0.08l-0.07,0.03c-0.23,0.09-1.94,0.29-4.74,0.61c-2.71,0.31-6.08,0.69-6.32,0.83
						c-0.04,0.02-0.07,0.04-0.11,0.06c-0.15,0.09-0.3,0.17-0.39,0.3c-0.42,0.63-0.84,1.25-1.24,1.87c-0.15,0.23-0.34,0.4-0.61,0.56
						c-0.86,0.49-1.76,0.94-2.63,1.37c-0.28,0.14-0.55,0.27-0.83,0.41c-0.14,0.07-0.23,0.15-0.3,0.25
						c-0.08,0.11-0.16,0.23-0.23,0.34c-0.17,0.25-0.34,0.51-0.54,0.76c0.66-0.64,1.44-1.26,2.59-1.44l0.56-0.09l-0.5,0.27
						c-0.11,0.06-0.21,0.11-0.31,0.17c-0.22,0.12-0.42,0.22-0.62,0.34c-0.21,0.13-0.35,0.37-0.33,0.54
						c0.05-0.01,0.14-0.06,0.19-0.08c0.06-0.03,0.11-0.06,0.16-0.07c0.1-0.03,0.2-0.06,0.3-0.1c0.44-0.14,0.89-0.28,1.23-0.59
						c0.43-0.4,0.86-0.79,1.29-1.19c0.76-0.7,1.54-1.42,2.32-2.12c0.14-0.13,0.28-0.26,0.41-0.4c0.32-0.31,0.64-0.63,1.05-0.88
						l0.13-0.08l0.01,0.1c0.61,0.15,6.9,0.25,12.45,0.35c3.08,0.05,6.26,0.11,8.61,0.17c4,0.1,4.01,0.17,4.03,0.3l0.01,0.07
						l-0.07,0.03c-0.26,0.11-4.38,0.28-11.07,0.54c-6.66,0.26-14.94,0.59-15.34,0.79c-1.08,0.88-2.07,1.7-3.01,2.53
						c-0.33,0.29-0.68,0.58-1.01,0.86c-0.43,0.36-0.87,0.73-1.29,1.11c-0.46,0.42-1.01,0.67-1.63,0.75
						c-0.85,0.11-1.43,0.63-1.93,1.08c-0.74,0.67-1.59,1.07-2.59,1.22c-0.57,0.09-1.15,0.22-1.71,0.34
						c-0.27,0.06-0.53,0.12-0.8,0.17c-0.46,0.1-0.82,0.35-1.19,0.61c-0.14,0.1-0.28,0.2-0.43,0.29c-0.7,0.44-1.41,0.9-2.09,1.34
						c-0.3,0.2-0.61,0.4-0.91,0.59l-0.04,0.03c-0.09,0.06-0.19,0.12-0.32,0.14l-0.04,0.03l-0.31-0.22l0.26-0.01
						c0.14-0.13,0.27-0.26,0.41-0.39c0.32-0.3,0.65-0.62,0.99-0.93c0.4-0.37,0.81-0.74,1.21-1.12c0.38-0.35,0.76-0.7,1.14-1.05
						c0.23-0.22,0.51-0.38,0.77-0.54c0.09-0.06,0.19-0.11,0.28-0.17c0.58-0.37,1.01-0.68,1.25-1.23c0.27-0.61,0.72-1.16,1.17-1.68
						c-0.02,0.01-0.04,0.01-0.05,0.02c-0.27,0.08-0.55,0.17-0.83,0.24c-1.22,0.3-2.27,0.93-3.29,1.55c-0.25,0.15-0.5,0.3-0.75,0.45
						c-0.38,0.22-0.76,0.45-1.13,0.68c-0.77,0.47-1.57,0.95-2.39,1.38c-0.8,0.42-1.6,0.86-2.37,1.29c-0.76,0.42-1.55,0.86-2.33,1.27
						c-0.62,0.33-1.17,0.75-1.7,1.17c-0.21,0.16-0.43,0.33-0.64,0.49c-0.18,0.13-0.37,0.28-0.54,0.41
						c-0.08,0.06-0.16,0.13-0.25,0.19L715.87,1859.29z M728.47,1850.16l-0.02,0.16c-0.02,0.14-0.11,0.2-0.17,0.24
						c-0.01,0.01-0.02,0.01-0.03,0.02c-0.28,0.21-0.56,0.42-0.84,0.63c-0.51,0.38-1.04,0.77-1.55,1.17
						c-0.58,0.45-1.23,0.84-1.85,1.21c-0.1,0.06-0.2,0.12-0.3,0.18c-0.36,0.22-0.74,0.42-1.11,0.62c-0.51,0.28-1.05,0.57-1.54,0.9
						c-0.4,0.26-0.83,0.51-1.24,0.75c-0.26,0.15-0.54,0.31-0.8,0.47c-0.09,0.06-0.18,0.11-0.28,0.17c-0.49,0.29-0.96,0.57-1.34,0.97
						c-0.16,0.17-0.34,0.33-0.51,0.49c-0.13,0.13-0.27,0.26-0.4,0.39c-0.07,0.07-0.13,0.14-0.2,0.21c0.1-0.08,0.21-0.16,0.32-0.24
						c0.22-0.16,0.43-0.33,0.64-0.49c0.54-0.42,1.09-0.85,1.73-1.18c0.78-0.41,1.57-0.85,2.33-1.27c0.78-0.43,1.58-0.87,2.38-1.29
						c0.81-0.43,1.61-0.91,2.38-1.37c0.37-0.22,0.75-0.45,1.13-0.68c0.25-0.15,0.5-0.3,0.75-0.45c1.03-0.63,2.09-1.27,3.34-1.58
						c0.28-0.07,0.55-0.15,0.82-0.24c0.13-0.04,0.25-0.08,0.38-0.12l0.32-0.09l-0.22,0.25c-0.06,0.07-0.12,0.14-0.18,0.21
						c-0.47,0.55-0.92,1.07-1.18,1.68c-0.28,0.65-0.84,1-1.32,1.32c-0.09,0.06-0.19,0.12-0.29,0.18c-0.26,0.16-0.52,0.32-0.74,0.52
						c-0.38,0.35-0.76,0.7-1.14,1.05c-0.4,0.37-0.81,0.74-1.21,1.12c-0.29,0.27-0.58,0.54-0.87,0.81c0.19-0.13,0.39-0.25,0.58-0.38
						c0.68-0.45,1.39-0.91,2.1-1.34c0.14-0.09,0.28-0.19,0.42-0.28c0.38-0.27,0.77-0.54,1.27-0.64c0.26-0.06,0.53-0.11,0.79-0.17
						c0.56-0.13,1.15-0.26,1.73-0.35c0.96-0.15,1.77-0.53,2.48-1.17c0.53-0.48,1.13-1.02,2.04-1.13c0.58-0.08,1.1-0.31,1.53-0.7
						c0.42-0.38,0.87-0.76,1.3-1.12c0.33-0.28,0.68-0.57,1.01-0.86c0.94-0.83,1.93-1.66,3.02-2.54c0.27-0.22,6.02-0.46,15.45-0.83
						c4.12-0.16,8.35-0.33,10.14-0.45c-1.98-0.11-7.06-0.2-11.64-0.27c-3.08-0.05-6.26-0.11-8.61-0.17
						c-2.84-0.07-3.67-0.13-3.92-0.2c-0.36,0.23-0.67,0.54-0.97,0.83c-0.14,0.13-0.28,0.27-0.42,0.4c-0.78,0.7-1.56,1.42-2.31,2.12
						c-0.43,0.4-0.86,0.79-1.29,1.19c-0.37,0.34-0.85,0.49-1.31,0.64c-0.1,0.03-0.2,0.06-0.29,0.09c-0.03,0.01-0.08,0.04-0.13,0.06
						c-0.13,0.07-0.27,0.14-0.38,0.09c-0.03-0.02-0.08-0.05-0.09-0.13c-0.05-0.26,0.12-0.59,0.41-0.77c0.06-0.04,0.12-0.07,0.18-0.1
						c-0.87,0.35-1.5,0.99-2.07,1.57l-0.1,0.1l-0.06-0.13c-0.1-0.22,0.03-0.35,0.09-0.42l0.02-0.02c0.26-0.3,0.49-0.63,0.7-0.96
						c0.08-0.12,0.15-0.23,0.23-0.35c0.09-0.13,0.21-0.23,0.38-0.31c0.28-0.14,0.55-0.28,0.83-0.41c0.87-0.43,1.77-0.87,2.62-1.37
						c0.24-0.14,0.41-0.3,0.54-0.5c0.4-0.62,0.82-1.24,1.24-1.88c0.11-0.17,0.29-0.27,0.45-0.36c0.04-0.02,0.07-0.04,0.11-0.06
						c0.26-0.15,2.7-0.44,6.4-0.86c1.89-0.21,3.84-0.44,4.48-0.55c-0.44-0.19-1.69-0.52-2.72-0.8c-2.08-0.56-2.77-0.77-2.95-0.94
						c-0.61,0.09-4.59,1.59-4.8,1.82c-0.21,0.23-0.45,0.42-0.68,0.61c-0.13,0.11-0.26,0.21-0.39,0.33
						c-0.23,0.21-0.49,0.41-0.73,0.61c-0.11,0.09-0.22,0.17-0.32,0.26l-0.07,0.05l-0.21-0.19l1.77-1.88
						c-0.06,0.02-0.13,0.04-0.19,0.06c-0.49,0.15-0.95,0.3-1.31,0.64c-0.14,0.13-0.27,0.26-0.41,0.38c-0.34,0.32-0.7,0.65-1.03,0.99
						c-0.07,0.07-0.14,0.14-0.2,0.21c-0.41,0.41-0.83,0.84-1.46,1.07c-0.34,0.13-0.67,0.31-0.99,0.48
						c-0.14,0.08-0.29,0.16-0.44,0.24c-0.16,0.08-0.32,0.16-0.48,0.25l-0.5,0.25l0.22-0.32c0.31-0.45,0.65-0.87,0.97-1.27l0.04-0.06
						c0.27-0.33,0.54-0.67,0.8-1.01c0.11-0.14,0.13-0.32,0.03-0.46c-0.03-0.05-0.03-0.05-0.16,0.02c-0.05,0.03-0.11,0.06-0.17,0.07
						c-0.03,0.01-0.05,0-0.07,0c-0.01,0-0.02,0-0.03,0l-0.07,0.02c-0.39,0.1-0.79,0.2-1.09,0.45l-0.13,0.11
						c-0.58,0.49-1.18,0.99-1.73,1.52c-0.89,0.85-1.82,1.45-2.85,1.84c-0.74,0.28-1.31,0.57-1.78,0.91c-0.1,0.07-0.2,0.15-0.3,0.22
						c-0.27,0.2-0.55,0.42-0.85,0.58c-0.62,0.34-1.28,0.65-1.92,0.95l-0.3,0.14c-0.59,0.28-1.1,0.61-1.58,1.01
						c-0.21,0.17-0.41,0.35-0.61,0.52c-0.39,0.34-0.8,0.69-1.22,1.01c0.23-0.15,0.45-0.31,0.67-0.46c1.27-0.88,2.46-1.71,3.94-2.21
						L728.47,1850.16z M749.05,1840.3"/>
				</g>
				<g>
					<path className="st116" d="M695.03,1858.63c0.25-0.32,3.18-2.48,3.52-2.67c0.46-0.26,0.95-0.47,1.4-0.75
						c0.05-0.03,0.11-0.09,0.16-0.03c0.05,0.07-0.03,0.1-0.07,0.14c-0.36,0.41-0.72,0.81-1.08,1.22c-0.03,0.03-0.06,0.07-0.05,0.12
						C698.48,1856.65,695.46,1858.63,695.03,1858.63z"/>
				</g>
				<g>
					<path className="st116" d="M694.44,1849.7c0.08,0.05,0.13,0.03,0.17,0.02c1.05-0.45,2.13-0.82,3.2-1.23
						c0.41-0.16,0.83-0.06,1.24-0.08c0.03,0,0.06,0.02,0.06,0.05c0,0.05-0.04,0.08-0.08,0.1c-0.17,0.12-0.34,0.25-0.51,0.37
						c-0.03,0.02-0.08,0.04-0.07,0.14c0.2-0.11,0.39-0.21,0.58-0.31c0.46-0.25,0.91-0.52,1.46-0.52c0.05,0,0.11-0.04,0.14,0.02
						c0.03,0.06-0.04,0.09-0.08,0.11c-0.42,0.28-0.83,0.55-1.25,0.83c-0.53,0.36-1.07,0.71-1.6,1.07c-0.21,0.14-0.41,0.27-0.61,0.41
						c-0.19,0.13-0.39,0.17-0.61,0.14c-0.52-0.07-1.05-0.13-1.58-0.21c-0.21-0.03-0.4,0.03-0.59,0.08c-0.71,0.17-1.43,0.21-2.15,0.3
						c-0.27,0.03-0.46,0.2-0.68,0.32c-1.45,0.77-2.89,1.57-4.33,2.35c-0.69,0.37-1.66,2.71-2.38,3c-0.35,0.14-4.76,1.87-5.13,1.97
						c0.18-0.15,4.65-4,4.82-4.16c0.76-0.7,1.68-1.15,2.56-1.68c0.31-0.18,0.62-0.35,0.92-0.55c0.34-0.23,0.73-0.3,1.12-0.33
						c0.12-0.01,0.21-0.06,0.28-0.15c0.33-0.42,0.67-0.84,1.01-1.26c0.1-0.12,0.21-0.19,0.37-0.23c0.72-0.16,1.44-0.33,2.16-0.5
						c0.38-0.09,0.71-0.27,1.05-0.45c0.77-0.41,1.54-0.83,2.3-1.26c0.21-0.12,0.44-0.12,0.67-0.15c0.43-0.06,0.86-0.11,1.29-0.16
						c0.07-0.01,0.13-0.03,0.22,0.03C697.05,1848.38,695.75,1849.02,694.44,1849.7z"/>
				</g>
				<g>
					<path className="st116" d="M757.48,1822.13c0.28-0.25,0.45-0.55,0.67-0.82c0.58-0.73,1.38-1.2,2.06-1.8
						c0.12-0.11,0.25-0.21,0.38-0.32c0.03-0.03,0.07-0.06,0.12-0.05c0.06,0.02,0.06,0.08,0.06,0.13c0,0.14,0,0.28,0,0.43
						c0,0.05,0,0.11,0.06,0.13c0.05,0.02,0.08-0.03,0.12-0.05c0.3-0.19,0.48-0.43,0.41-0.81c-0.03-0.14,0.03-0.25,0.12-0.37
						c0.2-0.23,0.38-0.48,0.57-0.72c0.07-0.1,0.16-0.15,0.29-0.12c0.41,0.09,0.68-0.12,0.99-0.37c0.63-0.52,0.86-1.28,1.26-1.93
						c0.15-0.24,0.26-0.5,0.39-0.75c0.02-0.03,0.04-0.07,0.07-0.09c0.48-0.23,0.8-0.74,1.4-0.78c0.56-0.03,1.1-0.21,1.66-0.25
						c0.05,0,0.11-0.05,0.14,0.01c0.04,0.08-0.05,0.09-0.09,0.13c-0.24,0.22-0.48,0.44-0.73,0.66c-0.19,0.18-0.27,0.45-0.36,0.68
						c-0.12,0.34-0.35,0.53-0.64,0.66c-0.4,0.18-0.68,0.45-0.86,0.85c-0.11,0.24-0.32,0.39-0.54,0.55c-0.71,0.5-1.44,0.97-2.07,1.58
						c-0.63,0.62-1.31,1.2-1.97,1.78c-0.26,0.23-0.6,0.33-0.91,0.45c-0.81,0.31-1.56,0.74-2.31,1.14
						C757.69,1822.13,757.62,1822.18,757.48,1822.13z"/>
					<path className="st42" d="M757.59,1822.16c-0.03,0-0.07-0.01-0.12-0.02l-0.02-0.01l0.02-0.01c0.19-0.17,0.34-0.37,0.47-0.56
						c0.06-0.09,0.13-0.17,0.19-0.26c0.38-0.48,0.87-0.85,1.33-1.21c0.24-0.19,0.49-0.38,0.73-0.59c0.08-0.07,0.17-0.15,0.26-0.22
						c0.04-0.03,0.08-0.07,0.12-0.1l0,0c0.04-0.03,0.08-0.06,0.13-0.05c0.07,0.02,0.07,0.08,0.07,0.13v0.02c0,0.08,0,0.17,0,0.25
						c0,0.06,0,0.12,0,0.18v0c0,0.05,0,0.1,0.05,0.12c0.03,0.01,0.05-0.01,0.08-0.03c0.01-0.01,0.02-0.01,0.03-0.02
						c0.34-0.21,0.47-0.46,0.4-0.8c-0.03-0.13,0.01-0.25,0.12-0.38c0.15-0.18,0.3-0.37,0.44-0.55c0.04-0.06,0.08-0.11,0.13-0.17
						c0.09-0.11,0.18-0.15,0.3-0.13c0.39,0.08,0.65-0.1,0.98-0.37c0.44-0.36,0.69-0.85,0.93-1.32c0.1-0.2,0.21-0.41,0.33-0.6
						c0.1-0.16,0.18-0.33,0.27-0.5c0.04-0.08,0.08-0.17,0.13-0.25l0.01-0.01c0.02-0.03,0.04-0.07,0.07-0.08
						c0.17-0.08,0.33-0.21,0.48-0.33c0.26-0.21,0.54-0.43,0.92-0.45c0.29-0.02,0.57-0.07,0.85-0.13c0.26-0.05,0.53-0.1,0.81-0.12
						c0.01,0,0.03-0.01,0.04-0.01c0.04-0.01,0.09-0.03,0.11,0.03c0.04,0.07-0.02,0.1-0.05,0.12c-0.01,0.01-0.03,0.02-0.04,0.02
						c-0.15,0.14-0.3,0.28-0.45,0.42c-0.09,0.08-0.18,0.16-0.27,0.25c-0.17,0.16-0.26,0.4-0.33,0.62l-0.02,0.06
						c-0.11,0.31-0.31,0.52-0.64,0.67c-0.4,0.17-0.68,0.45-0.85,0.84c-0.11,0.25-0.32,0.39-0.54,0.55l0,0
						c-0.13,0.09-0.27,0.19-0.4,0.28c-0.57,0.4-1.16,0.81-1.67,1.31c-0.58,0.57-1.2,1.11-1.8,1.63l-0.17,0.15
						c-0.22,0.2-0.51,0.3-0.78,0.4c-0.04,0.02-0.09,0.03-0.13,0.05c-0.69,0.26-1.34,0.62-1.98,0.96c-0.11,0.06-0.22,0.12-0.34,0.18
						c-0.01,0-0.01,0.01-0.02,0.01C757.7,1822.14,757.66,1822.16,757.59,1822.16z M757.5,1822.12c0.11,0.04,0.17,0,0.23-0.03
						c0.01,0,0.01-0.01,0.02-0.01c0.11-0.06,0.22-0.12,0.34-0.18c0.63-0.34,1.29-0.7,1.98-0.96c0.04-0.02,0.09-0.03,0.13-0.05
						c0.27-0.1,0.55-0.21,0.78-0.4l0.17-0.15c0.6-0.52,1.22-1.06,1.8-1.63c0.51-0.5,1.1-0.91,1.67-1.31
						c0.13-0.09,0.27-0.18,0.4-0.28l0,0c0.22-0.15,0.42-0.3,0.53-0.54c0.18-0.4,0.47-0.68,0.87-0.85c0.32-0.14,0.52-0.35,0.63-0.65
						l0.02-0.06c0.07-0.22,0.16-0.46,0.34-0.63c0.09-0.08,0.18-0.16,0.27-0.25c0.15-0.14,0.3-0.27,0.45-0.42
						c0.01-0.01,0.03-0.02,0.04-0.03c0.04-0.03,0.07-0.04,0.04-0.09c-0.02-0.03-0.04-0.03-0.08-0.01c-0.02,0-0.03,0.01-0.05,0.01
						c-0.27,0.02-0.54,0.07-0.8,0.12c-0.28,0.05-0.57,0.11-0.85,0.13c-0.38,0.02-0.65,0.24-0.91,0.44
						c-0.15,0.12-0.31,0.25-0.49,0.33c-0.02,0.01-0.04,0.04-0.05,0.07l-0.01,0.01c-0.04,0.08-0.09,0.17-0.13,0.25
						c-0.08,0.17-0.17,0.34-0.27,0.5c-0.12,0.19-0.22,0.4-0.33,0.6c-0.24,0.47-0.49,0.97-0.94,1.33c-0.33,0.27-0.6,0.46-1,0.37
						c-0.11-0.02-0.2,0.01-0.28,0.12c-0.04,0.06-0.08,0.11-0.13,0.17c-0.14,0.18-0.29,0.38-0.44,0.56
						c-0.11,0.12-0.14,0.23-0.12,0.36c0.07,0.34-0.06,0.6-0.41,0.82c-0.01,0.01-0.02,0.01-0.03,0.02c-0.03,0.02-0.06,0.05-0.1,0.03
						c-0.07-0.02-0.07-0.09-0.07-0.14v0c0-0.06,0-0.12,0-0.18c0-0.08,0-0.17,0-0.25v-0.02c0-0.05,0-0.09-0.05-0.1
						c-0.04-0.01-0.07,0.02-0.1,0.04l0,0c-0.04,0.03-0.08,0.07-0.12,0.1c-0.08,0.07-0.17,0.14-0.25,0.21
						c-0.23,0.21-0.49,0.4-0.73,0.59c-0.47,0.36-0.95,0.73-1.33,1.21c-0.07,0.08-0.13,0.17-0.19,0.26
						C757.83,1821.76,757.69,1821.95,757.5,1822.12z"/>
				</g>
				<g>
					<path className="st116" d="M727.27,1832.58c-0.15,0.71-0.3,1.41-0.47,2.19c0.63-0.32,0.98-0.75,1.02-1.38
						c0.03-0.4,0.21-0.44,0.52-0.26c0.18,0.1,0.36,0.19,0.55,0.27c0.31,0.13,0.56,0.27,0.36,0.67c-0.1,0.2,0,0.24,0.19,0.23
						c0.57-0.03,1.13-0.23,1.71-0.12c0.41,0.08,0.81,0.19,1.22,0.25c0.29,0.04,0.37,0.37,0.41,0.66c-0.05,0.01-0.09,0.02-0.09,0.01
						c-0.22-0.37-0.47-0.32-0.81-0.13c-1.25,0.71-2.51,1.41-3.79,2.08c-1.1,0.59-2.2,1.12-3.49,1.23c-0.89,0.08-1.74,0.47-2.6,0.75
						c-0.76,0.24-1.51,0.5-2.29,0.71c0.21-0.25,0.41-0.5,0.73-0.6c1.46-0.5,2.92-1.01,4.38-1.49c0.26-0.09,0.43-0.28,0.62-0.44
						c0.6-0.49,1.27-0.77,2.11-0.79c-0.19-0.22-0.36-0.13-0.51-0.13c-0.6,0-1.2,0.02-1.8,0.02c-0.19,0-0.35,0.07-0.48,0.19
						c-0.3,0.28-0.61,0.54-0.88,0.85c-0.33,0.38-0.78,0.47-1.22,0.56c-0.56,0.12-1.09,0.27-1.58,0.63c-0.68,0.49-1.54,0.57-2.34,0.8
						c-0.27,0.08-0.62-0.09-0.91-0.2c-0.61-0.24-1.24-0.26-1.88-0.24c-0.09,0-0.18-0.02-0.26-0.03l0-0.01
						c0.17-0.19,0.4-0.11,0.61-0.15c0.7-0.12,1.4-0.16,2.07,0.09c0.53,0.19,0.94-0.08,1.37-0.29c0.47-0.23,0.91-0.52,1.38-0.73
						c0.27-0.12,0.48-0.31,0.61-0.6c0.17-0.38,0.4-0.73,0.61-1.1c0.15-0.26,0.42-0.29,0.67-0.31c0.48-0.04,0.8-0.27,0.91-0.72
						c0.17-0.71,0.72-0.97,1.32-1.16c0.18-0.06,0.27-0.15,0.33-0.33c0.12-0.32,0.27-0.63,0.38-0.96c0.11-0.31,0.3-0.42,0.62-0.31
						c0.18,0.06,0.37,0.11,0.55,0.16C727.16,1832.48,727.21,1832.53,727.27,1832.58z"/>
					<path className="st42" d="M719.42,1839.91l0.2-0.24c0.21-0.25,0.42-0.52,0.77-0.64c0.49-0.17,0.98-0.34,1.47-0.5
						c0.95-0.33,1.94-0.66,2.91-0.99c0.18-0.06,0.31-0.18,0.45-0.3c0.04-0.04,0.09-0.08,0.13-0.12c0.46-0.38,0.96-0.63,1.52-0.74
						c-0.26,0-0.53,0.01-0.79,0.01c-0.29,0-0.57,0.01-0.86,0.01c-0.16,0-0.29,0.05-0.41,0.17c-0.08,0.08-0.17,0.16-0.26,0.24
						c-0.21,0.19-0.43,0.39-0.62,0.61c-0.35,0.4-0.82,0.5-1.27,0.59c-0.6,0.13-1.08,0.28-1.54,0.61c-0.52,0.38-1.14,0.52-1.75,0.66
						c-0.21,0.05-0.42,0.1-0.62,0.15c-0.27,0.08-0.6-0.05-0.88-0.17l-0.09-0.04c-0.6-0.24-1.24-0.26-1.84-0.24
						c-0.07,0-0.14-0.01-0.2-0.02c-0.03,0-0.05-0.01-0.08-0.01l-0.07-0.01l-0.02-0.12l0.03-0.04c0.15-0.16,0.33-0.17,0.5-0.17
						c0.06,0,0.12,0,0.17-0.01c0.59-0.1,1.37-0.18,2.13,0.09c0.43,0.16,0.78-0.02,1.14-0.21c0.05-0.02,0.1-0.05,0.15-0.07
						c0.2-0.1,0.39-0.21,0.58-0.31c0.26-0.15,0.53-0.3,0.81-0.42c0.26-0.12,0.44-0.29,0.55-0.55c0.12-0.28,0.28-0.54,0.44-0.8
						c0.06-0.1,0.12-0.2,0.18-0.31c0.18-0.31,0.52-0.34,0.75-0.36c0.45-0.04,0.73-0.26,0.82-0.65c0.2-0.8,0.82-1.05,1.39-1.24
						c0.14-0.05,0.21-0.11,0.27-0.26c0.06-0.17,0.13-0.34,0.2-0.5c0.06-0.15,0.13-0.31,0.19-0.46c0.13-0.37,0.39-0.49,0.75-0.37
						c0.12,0.04,0.25,0.08,0.37,0.11c0.06,0.02,0.12,0.03,0.17,0.05l0.02,0.01l0.21,0.2l-0.43,2.03c0.5-0.3,0.74-0.68,0.78-1.2
						c0.02-0.22,0.08-0.36,0.19-0.42c0.15-0.08,0.35,0.01,0.48,0.08c0.2,0.11,0.37,0.2,0.54,0.26c0.19,0.08,0.4,0.17,0.47,0.36
						c0.05,0.13,0.03,0.27-0.06,0.45c-0.02,0.04-0.03,0.06-0.03,0.07c0.01,0,0.05,0.01,0.13,0.01c0.19-0.01,0.39-0.04,0.58-0.07
						c0.37-0.06,0.76-0.12,1.15-0.04c0.16,0.03,0.33,0.07,0.48,0.1c0.24,0.05,0.49,0.11,0.73,0.14c0.4,0.06,0.47,0.54,0.49,0.75
						l0.01,0.1l-0.1,0.01c-0.02,0-0.03,0-0.04,0.01c-0.04,0.01-0.11,0.02-0.15-0.04c-0.16-0.27-0.32-0.3-0.68-0.09
						c-1.25,0.71-2.52,1.41-3.79,2.09c-1.06,0.57-2.21,1.13-3.53,1.24c-0.64,0.05-1.27,0.28-1.88,0.5
						c-0.23,0.08-0.47,0.17-0.71,0.24c-0.15,0.05-0.31,0.1-0.46,0.15c-0.6,0.19-1.21,0.39-1.84,0.56L719.42,1839.91z
						 M727.24,1836.16c0.11,0,0.24,0.03,0.37,0.18l0.14,0.16l-0.21,0.01c-0.79,0.02-1.44,0.27-2.05,0.77
						c-0.04,0.04-0.09,0.07-0.13,0.11c-0.15,0.13-0.3,0.27-0.52,0.34c-0.97,0.32-1.95,0.66-2.91,0.99c-0.49,0.17-0.98,0.34-1.47,0.5
						c-0.19,0.06-0.34,0.19-0.47,0.33c0.51-0.15,1.02-0.31,1.51-0.47c0.15-0.05,0.31-0.1,0.46-0.15c0.23-0.07,0.47-0.16,0.7-0.24
						c0.62-0.22,1.26-0.45,1.93-0.51c1.28-0.11,2.37-0.64,3.45-1.22c1.27-0.67,2.54-1.37,3.78-2.08c0.28-0.16,0.56-0.26,0.81-0.02
						c-0.06-0.21-0.15-0.32-0.28-0.34c-0.25-0.04-0.51-0.09-0.75-0.15c-0.16-0.03-0.32-0.07-0.48-0.1
						c-0.36-0.07-0.71-0.01-1.08,0.04c-0.2,0.03-0.4,0.06-0.6,0.07c-0.11,0.01-0.24,0-0.31-0.1c-0.07-0.1,0-0.23,0.02-0.27
						c0.06-0.12,0.08-0.22,0.05-0.29c-0.04-0.1-0.17-0.17-0.36-0.25c-0.17-0.07-0.35-0.16-0.56-0.28c-0.18-0.1-0.25-0.09-0.28-0.08
						c-0.05,0.03-0.08,0.12-0.09,0.26c-0.05,0.65-0.39,1.11-1.08,1.46l-0.19,0.09l0.5-2.34l-0.09-0.09
						c-0.05-0.01-0.1-0.03-0.15-0.04c-0.13-0.03-0.26-0.07-0.39-0.11c-0.26-0.09-0.39-0.02-0.49,0.25
						c-0.06,0.16-0.12,0.32-0.19,0.47c-0.07,0.16-0.14,0.32-0.2,0.49c-0.07,0.21-0.19,0.32-0.39,0.39c-0.66,0.21-1.1,0.47-1.25,1.09
						c-0.12,0.47-0.46,0.75-1,0.8c-0.25,0.02-0.47,0.06-0.59,0.26c-0.06,0.1-0.12,0.21-0.18,0.31c-0.15,0.25-0.31,0.51-0.42,0.78
						c-0.13,0.3-0.35,0.52-0.66,0.65c-0.27,0.12-0.54,0.27-0.79,0.41c-0.19,0.11-0.39,0.22-0.59,0.32c-0.05,0.02-0.1,0.05-0.14,0.07
						c-0.39,0.2-0.79,0.41-1.3,0.22c-0.68-0.25-1.39-0.19-1.94-0.1c0.48,0.01,0.99,0.06,1.47,0.26l0.1,0.04
						c0.26,0.1,0.55,0.22,0.75,0.16c0.21-0.06,0.42-0.11,0.63-0.16c0.58-0.13,1.19-0.27,1.67-0.63c0.52-0.37,1.08-0.53,1.62-0.64
						c0.44-0.09,0.86-0.18,1.17-0.53c0.2-0.22,0.42-0.43,0.63-0.62c0.08-0.08,0.17-0.15,0.25-0.23c0.16-0.15,0.34-0.22,0.55-0.22
						c0.29,0,0.57-0.01,0.86-0.01c0.31-0.01,0.62-0.01,0.94-0.01c0,0,0,0,0,0c0.03,0,0.05,0,0.09-0.01
						C727.15,1836.17,727.19,1836.16,727.24,1836.16z"/>
				</g>
				<g>
					<path className="st116" d="M705.38,1850.78c0.26-0.08,0.44-0.23,0.63-0.34c0.14-0.08,0.27-0.14,0.43-0.11
						c0.06,0.01,0.12-0.01,0.18,0.01c0.45,0.15,0.69-0.1,0.9-0.44c0.09-0.15,0.18-0.31,0.31-0.44c0.18-0.19,0.45-0.19,0.68-0.24
						c0.1-0.02,0.06,0.07,0.03,0.12c-0.09,0.14-0.18,0.27-0.27,0.41c-0.03,0.04-0.07,0.08-0.03,0.15c0.2-0.17,0.36-0.38,0.61-0.49
						c-0.34,0.42-0.56,0.93-0.97,1.29c-0.23,0.2-0.55,0.26-0.83,0.37c-1.27,0.53-2.55,1.05-3.83,1.57
						c-0.12,0.05-0.23,0.11-0.34,0.16c-0.1,0.04-0.17,0.01-0.11-0.09c0.28-0.5,0.41-1.08,1-1.37c0.59-0.29,1.14-0.65,1.71-0.98
						c0.04-0.02,0.07-0.08,0.15-0.04C705.61,1850.49,705.44,1850.58,705.38,1850.78z"/>
				</g>
				<g>
					<g>
						<path className="st116" d="M664.29,1851.64C664.29,1851.64,664.29,1851.64,664.29,1851.64
							C664.25,1851.64,664.27,1851.64,664.29,1851.64z"/>
					</g>
					<g>
						<path className="st116" d="M710.32,1837.8c-11.34-3.59-28.36-13.51-38.11-20.32c-11.16,7.68-17.54,17.52-30.89,19.84
							s-27.54,1.07-39.86-4.54c-3.2,4.2-6.3,8.4-9.5,12.6c11.87,2.89,20.77-0.11,32.99,0.32c9.04,0.32,21.52,3.08,30.45,4.52
							c2.04,0.33,4.12-0.15,6.19-0.31c0.83-0.07,1.93-0.11,2.58-0.68c0.68-0.59,1.17-0.07,2.1-0.22c1.22-0.19,2.45-0.38,3.67-0.57
							c2.41-0.37,4.83-0.73,7.24-1.07c4.29-0.61,8.68-1.19,12.93-0.36c1.98,0.38,4.01,1.07,5.96,0.57c1.31-0.34,2.43-1.19,3.51-2.01
							C703.16,1842.79,706.74,1840.54,710.32,1837.8z"/>
					</g>
					<g>
						<path className="st116" d="M664.29,1851.64C664.29,1851.64,664.29,1851.64,664.29,1851.64
							C664.31,1851.64,664.34,1851.64,664.29,1851.64z"/>
					</g>
				</g>
				<g>
					<path className="st122" d="M663.23,1815.86c-0.57-0.33-0.81-0.86-0.85-1.52c-0.12-0.07-0.23-0.15-0.35-0.22
						c-0.1-0.06-0.22-0.15-0.33-0.17c-0.26-0.04-0.52-0.12-0.78-0.16c-0.14-0.02-0.27-0.04-0.41-0.06c-0.07-0.01-0.13-0.02-0.2-0.02
						c-0.03,0-0.18,0-0.19-0.02c0.03,0.05,0.08,0.09,0.12,0.14c0.18,0.23,0.18,0.23,0.35,0.01c0.09-0.12,0.14-0.03,0.19,0.04
						c0.09,0.13,0.16,0.26,0.31,0.31c0.03,0.01,0.07,0.05,0.1,0.02c0.22-0.24,0.39,0.02,0.58,0.05l0.1,0.15
						c0.04,0.13,0.21,0.21,0.17,0.35c-0.03,0.13-0.18,0.18-0.28,0.27c-0.14,0.11-0.22,0.27-0.27,0.43
						c-0.08,0.23-0.21,0.43-0.29,0.66c-0.09,0.26-0.31,0.41-0.48,0.61c-0.07,0.09-0.17,0.16-0.23,0.3c0.29-0.05,0.53-0.1,0.68-0.39
						c0.22-0.41,0.49-0.79,0.73-1.19c0.08-0.13,0.16-0.2,0.29-0.08c0.05,0.04,0.1,0.08,0.16,0.11c0.11,0.07,0.13,0.16,0.09,0.28
						c-0.08,0.24-0.01,0.48,0.06,0.71c0.03,0.09,0.03,0.19,0.07,0.27c0.11,0.21,0.02,0.35-0.13,0.45c-0.22,0.14-0.22,0.33-0.18,0.57
						c0.07,0.4,0.1,0.3-0.22,0.47c-0.15,0.08-0.31,0.16-0.46,0.25c-0.07,0.04-0.14,0.06-0.13-0.05c0.03-0.44-0.34-0.5-0.59-0.67
						c-0.03-0.02-0.08-0.02-0.08,0.03c-0.03,0.27-0.2,0.52-0.08,0.81c0.05,0.11,0.09,0.19,0.21,0.16c0.25-0.05,0.45,0.06,0.64,0.21
						c0.18,0.14,0.39,0.23,0.55,0.4c-0.35-0.05-0.72,0.09-1.06-0.13c-0.22-0.14-0.48-0.03-0.71-0.01c-0.25,0.02-0.24,0.04-0.2,0.3
						c0.05,0.32,0.1,0.63,0.12,0.97c-0.09-0.14-0.19-0.27-0.26-0.42c-0.19-0.42-0.51-0.69-0.89-0.89c-0.03-0.02-0.06-0.06-0.1-0.03
						c-0.04,0.03-0.01,0.08,0,0.12c0.04,0.14,0.08,0.28,0.09,0.43c0.01,0.14-0.05,0.18-0.15,0.2c-0.12,0.02-0.25,0.04-0.37,0.05
						c-0.03,0-0.06,0-0.08,0.03c-0.02,0.05,0.01,0.07,0.04,0.09c0.41,0.27,0.58,0.73,0.82,1.13c0.07,0.12,0.14,0.23,0.21,0.35
						c0.07,0.12,0.13,0.23,0.29,0.13c0.07-0.04,0.12,0.02,0.17,0.06c0.34,0.26,0.58,0.59,0.67,1.03c0.01,0.03,0.03,0.06,0.05,0.1
						c0.03,0.05,0.13,0.11,0.04,0.17c-0.07,0.05-0.11-0.05-0.15-0.1c-0.25-0.26-0.48-0.53-0.74-0.78c-0.15-0.15-0.36-0.22-0.54-0.31
						c-0.29-0.13-0.54-0.33-0.76-0.56c-0.11-0.11-0.23-0.22-0.34-0.32c-0.13-0.12-0.28-0.2-0.46-0.21
						c-0.19-0.01-0.37-0.07-0.56-0.09c-0.1-0.01-0.14-0.07-0.09-0.15c0.22-0.33,0.02-0.59-0.12-0.87c-0.13-0.27-0.28-0.53-0.41-0.8
						c-0.02-0.04-0.02-0.08-0.03-0.13c-0.01-0.01-0.02-0.01-0.02-0.02c0.01,0,0.01-0.01,0.02-0.01l0.01,0.03
						c0.09,0.05,0.13,0.14,0.19,0.21c0.21,0.28,0.42,0.57,0.63,0.85c0.02,0.02,0.04,0.05,0.09,0.03c-0.06-0.25-0.12-0.5-0.18-0.74
						c-0.03-0.11-0.04-0.22-0.01-0.33c0.06-0.18,0.09-0.19,0.23-0.07c0.03,0.03,0.06,0.08,0.13,0.08c0.03-0.14-0.07-0.23-0.12-0.33
						c-0.22-0.49-0.45-0.98-0.68-1.47c-0.04-0.09-0.11-0.06-0.18-0.05c-0.31,0.07-0.55,0.24-0.73,0.51
						c-0.07,0.1-0.17,0.19-0.25,0.28l-0.18,0.11c-0.14-0.01-0.28-0.01-0.42-0.02c0,0-0.08,0.01-0.11-0.01
						c-0.19-0.02-0.33,0.04-0.48,0.19c-0.5,0.53-1.03,1.04-1.62,1.48c-0.22,0.17-0.5,0.19-0.76,0.21c-0.83,0.07-1.56,0.43-2.28,0.83
						c-0.12,0.07-0.15,0.11-0.02,0.22c0.25,0.21,0.25,0.22,0.1,0.51c-0.03,0.07-0.07,0.13-0.11,0.19c-0.19,0.23-0.14,0.49-0.12,0.75
						c0.07,0.01,0.08-0.05,0.09-0.08c0.06-0.31,0.26-0.52,0.44-0.76c0.06-0.08,0.1-0.07,0.14,0.02c0.07,0.15,0.14,0.3,0.21,0.45
						c0.03,0.07,0.04,0.15,0.01,0.22c-0.13,0.41-0.26,0.82-0.39,1.23c-0.03,0.1-0.05,0.2-0.05,0.31c0.01,0.33,0.03,0.65,0.04,0.98
						c0.03,0,0.02,0,0.05,0c0.05-0.22,0.13-0.43,0.15-0.65c0.04-0.42,0.34-0.67,0.56-0.96c0.05-0.06,0.08-0.03,0.11,0.02
						c0.1,0.14,0.19,0.28,0.28,0.43c0.04,0.07-0.01,0.13-0.04,0.19c-0.12,0.24-0.23,0.47-0.35,0.71c-0.04,0.08-0.06,0.16,0.01,0.24
						c0.22,0.28,0.2,0.59,0.07,0.9c-0.04,0.09-0.05,0.18-0.02,0.27c0.1,0.34,0.2,0.69,0.31,1.03c0.01,0.04,0.01,0.09,0.08,0.11
						c0.03-0.24,0.07-0.47,0.1-0.71c0.02-0.19,0.09-0.35,0.21-0.5c0.14-0.15,0.25-0.33,0.38-0.49c0.06-0.08,0.1-0.11,0.15,0.01
						c0.19,0.43,0.4,0.85,0.58,1.29c0.14,0.33,0.02,0.63-0.07,0.94c-0.03,0.11-0.18,0.11-0.27,0.17c-0.12,0.07-0.28,0.09-0.4,0.25
						c0.15-0.02,0.27,0.02,0.39,0.05c0.21,0.05,0.37-0.06,0.52-0.18c0.14-0.11,0.26-0.12,0.43-0.02c0.33,0.19,0.57,0.45,0.68,0.84
						c0.05,0.19,0.13,0.37,0.2,0.56c0.13,0.19,0.29,0.35,0.45,0.51c0.23,0.23,0.44,0.47,0.66,0.71c0.06,0.07,0.13,0.13,0.23,0.12
						c0.04-0.06,0.02-0.12,0.01-0.18c-0.05-0.53-0.04-0.53,0.46-0.65c0.01,0,0.02-0.01,0.03-0.01c0.2-0.05,0.21-0.08,0.11-0.26
						c-0.24-0.41-0.48-0.83-0.73-1.23c-0.07-0.12-0.09-0.23,0.01-0.32c0.11-0.11,0.06-0.19-0.03-0.28
						c-0.29-0.29-0.56-0.59-0.81-0.91c-0.06-0.07-0.1-0.14-0.08-0.23c0.08-0.26,0.12-0.53,0.17-0.8c0.01-0.04,0.01-0.1,0.06-0.09
						c0.04,0,0.04,0.05,0.06,0.08c0.12,0.24,0.25,0.48,0.38,0.74c0.05-0.08,0.02-0.14,0.01-0.2c-0.13-0.41-0.19-0.85-0.33-1.26
						c-0.03-0.09-0.04-0.19-0.04-0.29c0-0.43,0-0.87,0.02-1.3c0.01-0.13,0.04-0.23,0.15-0.27c0.36-0.13,0.45-0.4,0.4-0.77
						c-0.02-0.14-0.04-0.29,0.03-0.44c0.07,0.11,0.14,0.21,0.19,0.31c0.08,0.18,0.23,0.32,0.27,0.52c0.08,0.44,0.16,0.89,0.24,1.33
						c0.01,0.05,0,0.11,0.06,0.14c0.04-0.04,0.03-0.09,0.03-0.13c0.01-0.45,0.02-0.89,0.04-1.34c0-0.08-0.05-0.19,0.04-0.24
						c0.07-0.03,0.13,0.06,0.2,0.1c0.2,0.13,0.35,0.36,0.62,0.35c0.08,0,0.15,0.02,0.23,0.02c0.09,0,0.13,0.05,0.17,0.14
						c0.09,0.23,0.19,0.46,0.31,0.68c0.15,0.28,0.22,0.56,0.11,0.88c-0.05,0.14-0.06,0.28-0.11,0.47c0.17-0.17,0.25-0.35,0.36-0.51
						c0.07-0.1,0.13-0.11,0.21-0.03c0.13,0.12,0.26,0.23,0.4,0.34c0.26,0.23,0.52,0.44,0.56,0.85c0.04,0.45,0.17,0.9-0.06,1.34
						c-0.04,0.07-0.01,0.15,0.02,0.23c0.12,0.26,0.27,0.51,0.42,0.76c0.11,0.2,0.25,0.38,0.28,0.62c0.01,0.04,0.02,0.09-0.03,0.12
						c-0.04,0.02-0.06-0.02-0.09-0.04c-0.17-0.11-0.33-0.22-0.5-0.32c-0.19-0.11-0.4-0.1-0.62-0.07c0.06,0.09,0.12,0.14,0.19,0.18
						c0.31,0.16,0.51,0.41,0.58,0.77c0.06,0.28,0.14,0.55,0.22,0.86c-0.21-0.11-0.38-0.19-0.55-0.28c-0.04-0.02-0.08-0.08-0.13-0.04
						c-0.05,0.04,0,0.09,0.02,0.13c0.24,0.52,0.36,1.06,0.3,1.63c-0.01,0.1,0.05,0.17,0.12,0.24c0.14,0.14,0.28,0.27,0.41,0.41
						c0.02,0.02,0.06,0.06,0.05,0.09c-0.01,0.06-0.07,0.04-0.1,0.04c-0.26,0-0.52,0.03-0.76-0.1c0.09,0.11,0.17,0.22,0.31,0.26
						c0.03,0.01,0.06,0.02,0.08,0.04c0.23,0.27,0.55,0.4,0.84,0.56c0.22,0.12,0.47,0.11,0.71,0.16c0.05,0.01,0.1-0.03,0.14-0.07
						c-0.01-0.02-0.02-0.05-0.04-0.06c-0.11-0.09-0.1-0.14,0.03-0.22c0.35-0.2,0.69-0.43,1.03-0.65c0.11-0.07,0.18-0.17,0.22-0.3
						c0.1-0.27,0.17-0.55,0.36-0.78c0.28-0.35,0.55-0.7,0.82-1.05c0.08-0.1,0.15-0.21,0.29-0.29c-0.17,0.68-0.34,1.33-0.51,1.98
						c0.07,0.02,0.08-0.02,0.1-0.05c0.35-0.52,0.69-1.03,1.04-1.55c0.05-0.07,0.1-0.17,0.19-0.09c0.12,0.1,0.42,0.03,0.21,0.47
						c-0.06,0.13-0.07,0.27-0.06,0.42c0,0.04-0.03,0.08,0,0.11c0.03,0.02,0.05,0,0.08-0.02c0.13-0.1,0.27-0.2,0.4-0.31
						c0.11-0.09,0.21-0.09,0.33-0.03c0.07,0.03,0.07,0.06,0.03,0.12c-0.26,0.36-0.47,0.74-0.74,1.09c-0.07,0.1-0.18,0.19-0.19,0.34
						c0.05,0.02,0.06-0.01,0.08-0.03c0.12-0.11,0.21-0.27,0.36-0.33c0.19-0.07,0.38-0.12,0.58-0.18c0.04-0.01,0.08-0.04,0.12,0
						c0.04,0.04,0,0.08-0.02,0.12c-0.12,0.25-0.23,0.5-0.35,0.75c-0.06,0.12-0.03,0.18,0.11,0.19c0.21,0.02,0.41,0.06,0.61,0.09
						c0.11,0.01,0.15,0.07,0.15,0.18c-0.01,0.15,0,0.29,0,0.49c0.16-0.14,0.28-0.24,0.4-0.35c0.07-0.06,0.11-0.09,0.19-0.01
						c0.22,0.22,0.46,0.43,0.69,0.65c0.06,0.06,0.13,0.09,0.22,0.1c0.34,0.02,0.68,0.05,1.03,0.08c0.14,0.01,0.23,0.06,0.29,0.2
						c0.04,0.1,0.08,0.21,0.17,0.28c0.15,0.06,0.28,0.14,0.44,0.16c0.14,0.01,0.27,0.02,0.36-0.11c0.03-0.04,0.07-0.07,0.12-0.08
						c0.02-0.02,0.03-0.03,0.05-0.05c0.07-0.26,0.22-0.46,0.4-0.63c0.07-0.07,0.14-0.05,0.23-0.01c0.53,0.23,0.99,0.59,1.51,0.84
						c0.09,0.04,0.17,0.08,0.27,0.08c-0.03-0.07-0.08-0.12-0.13-0.17c-0.28-0.28-0.52-0.59-0.76-0.91c-0.07-0.1-0.11-0.19-0.05-0.31
						c0.05-0.1,0.04-0.2-0.01-0.3c-0.07-0.15,0.01-0.19,0.19-0.17c0.17,0.02,0.34,0.01,0.51,0c0.15-0.02,0.24,0.05,0.31,0.17
						c0.21,0.37,0.42,0.73,0.63,1.1c0.05,0.09,0.1,0.14,0.2,0.16c0.27,0.05,0.54,0.12,0.82,0.17c0.14,0.02,0.19,0.08,0.18,0.23
						c-0.01,0.36,0,0.72,0,1.08c0,0.08,0.01,0.16-0.03,0.24c0.01,0.12,0.1,0.16,0.18,0.23c0.18,0.14,0.35,0.3,0.52,0.45
						c0.04,0.03,0.06,0.09,0.12,0.07c0.06-0.03,0.03-0.1,0.04-0.15c0.02-0.14-0.09-0.22-0.13-0.35c0.08-0.03,0.12,0.03,0.17,0.07
						c0.48,0.41,0.99,0.78,1.5,1.14c0.12,0.08,0.25,0.1,0.35,0.03c0.28-0.17,0.49-0.02,0.69,0.15c0.44,0.38,0.9,0.76,1.38,1.08
						c0.11,0.07,0.23,0.13,0.36,0.19c0.51,0.21,0.96,0.53,1.43,0.81c0.22,0.13,0.42,0.27,0.63,0.41c0.05,0.03,0.1,0.07,0.17,0.11
						c0.01-0.11-0.05-0.15-0.1-0.18c-0.2-0.13-0.31-0.37-0.53-0.46c-0.03-0.02-0.06-0.05-0.04-0.09c0.03-0.05,0.07-0.03,0.11-0.01
						c0.32,0.15,0.66,0.22,0.98,0.38c0.2,0.1,0.38,0.06,0.57,0c0.27-0.08,0.53-0.17,0.81-0.21c0.17-0.02-0.06,0.33,0.09,0.4
						c0.34,0.14,0.89,0.79,0.89,0.79c0.02,0.01,2.1,2.24,1.82,1.25c-0.01-0.05-1.23-2.07-1.25-2.09c-0.11-0.13-0.21-0.26-0.31-0.38
						c0.12,0.02,0.24,0.11,0.37,0.18c0.38,0.21,0.78,0.37,1.19,0.48c0.37,0.1,0.71,0.26,1.07,0.4c0.03,0.01,0.06,0.01,0.09,0.02
						c0-0.31,2.62,2.67,2.62,2.36c-0.04,0.02-1.67-2.95-1.71-2.98c-0.57-0.38-2.2-1.01-2.85-1.21c-0.35-0.11-0.61-0.31-0.75-0.67
						c-0.21-0.51-0.1-0.59-0.49-0.96c-0.09-0.09-0.61-0.52-0.68-0.69c0.18,0.08,0.32,0.15,0.46,0.22c0.3,0.14,0.54,0.32,0.61,0.69
						c0.05,0.27,0.23,0.47,0.43,0.64c0.03,0.03,0.06,0.09,0.12,0.06c0.04-0.03,0.01-0.08,0-0.12c-0.05-0.26-0.04-0.53-0.04-0.8
						c0-0.25,0.03-0.2,0.38-0.03c0.11,0.05,0.22,0.08,0.33,0.11c0.17,0.04,0.27,0.17,0.35,0.31c0.05,0.08,0.08,0.17,0.12,0.25
						c0.04,0.07,0.08,0.14,0.18,0.07c0.08-0.06,0.12,0.02,0.16,0.07c0.24,0.34,2.88,1.58,3.01,1.99c0.02,0.07,8.36-0.28,8.42-0.23
						c0-0.58,8.72-0.47,8.73-1.05c-0.21-0.03-19.77-1.4-19.98-1.45c-0.61-0.15-1.24-0.3-1.82-0.56c-0.44-0.19-0.87-0.4-1.35-0.48
						c-0.2-0.04-0.4-0.1-0.58-0.2c-0.55-0.29-1.06-0.65-1.59-0.99c-0.47-0.3-0.84-0.73-1.3-1.03c-0.25-0.17-0.32-0.39-0.33-0.67
						c-0.01-0.12-0.02-0.24-0.05-0.36c-0.07-0.36-0.06-0.71,0.19-1.01c0.05-0.05,0.07-0.13,0.11-0.19c0.07-0.1,0.04-0.18-0.04-0.26
						c-0.11-0.11-0.21-0.22-0.32-0.32c-0.1-0.09-0.15-0.22-0.13-0.35c0.05-0.41-0.07-0.76-0.31-1.08c-0.07-0.1-0.12-0.22-0.19-0.31
						c-0.12-0.16-0.1-0.32-0.05-0.49c0.04-0.13,0.08-0.27,0.13-0.4c0.07-0.2,0.15-0.39,0.23-0.6c0.05,0.07,0.04,0.11,0.03,0.15
						c-0.04,0.26,0.02,0.46,0.27,0.57c0.1,0.04,0.18,0.13,0.21,0.24c0.06,0.21,0.2,0.35,0.35,0.48c0.18,0.16,0.22,0.34,0.16,0.57
						c-0.03,0.1-0.02,0.21-0.07,0.31c-0.06,0.13-0.01,0.21,0.08,0.29c0.24,0.23,0.34,0.51,0.24,0.85c-0.11,0.38-0.16,0.76-0.08,1.16
						c0.01,0.04-0.01,0.1,0.03,0.12c0.07,0.03,0.08-0.06,0.12-0.09c0.08-0.07,0.16-0.14,0.21-0.24c0.09-0.2,0.23-0.35,0.42-0.34
						c0.36,0.02,0.65-0.12,0.89-0.4c0.12-0.14,0.24-0.14,0.37-0.02c0.17,0.17,0.35,0.21,0.58,0.12c0.23-0.1,0.44-0.05,0.6,0.17
						c0.04,0.05,0.09,0.08,0.12,0.13c0.2,0.31,0.44,0.41,0.82,0.31c0.3-0.08,0.59,0.07,0.55,0.49c-0.01,0.1,0.02,0.18,0.13,0.21
						c0.49,0.1,0.65,0.51,0.78,0.94c0.02,0.07,0,0.12-0.05,0.17c-0.19,0.24-0.19,0.25,0.07,0.42c0.02,0.01,0.03,0.02,0.05,0.03
						c0.31,0.19,0.32,0.19,0.18,0.54c-0.05,0.12-0.04,0.2,0.08,0.27c0.13,0.08,0.62,1.63,0.73,1.74c0.25,0.25,3.5-0.33,3.56,0
						c0.02,0.12,0.95-0.09,1.67,0c0.31,0.04,1.22-0.21,1.2-0.69c-0.02-0.37,1.97-0.66,4.21,0.74c0.14,0.09-10.22-9.74-10.18-9.03
						c-0.23,0.27-0.74,2.52-0.93,2.82c-0.09,0.13-0.17,0.27-0.18,0.44c-0.01,0.17-0.03,0.35-0.06,0.52
						c-0.04,0.26-0.07,0.53-0.11,0.79c-0.03,0-0.02,0-0.05,0c-0.09-0.28-0.17-0.57-0.28-0.85c-0.16-0.39-0.23-0.79-0.11-1.21
						c0.03-0.09,0.02-0.2,0.04-0.29c0.06-0.28,0.1-0.57,0.31-0.8c0.21-0.24,0.4-0.49,0.63-0.71c0.03-0.03,0.08-0.06,0.05-0.14
						c-0.31,0.22-0.61,0.44-0.93,0.64c-0.25,0.16-0.44,0.37-0.51,0.68c-0.03,0.13-0.07,0.25-0.1,0.38c-0.01,0.05-0.03,0.09-0.1,0.07
						c-0.14-0.06-0.34-0.34-0.32-0.51c0.02-0.23-0.04-0.43-0.17-0.61c-0.09-0.12-0.17-0.25-0.26-0.38
						c-0.03-0.05-0.07-0.09-0.06-0.15c0.04-0.35,0.07-0.7,0.26-1.01c0.19-0.32,0.37-0.65,0.55-0.97c-0.18,0.02-0.28,0.19-0.43,0.27
						c-0.25,0.15-0.43,0.35-0.5,0.64c-0.01,0.04-0.01,0.09-0.09,0.1c-0.06-0.39,0.19-0.71,0.25-1.07c-0.27,0.28-0.58,0.52-0.63,0.95
						c-0.01,0.04-0.01,0.11-0.06,0.12c-0.06,0-0.06-0.06-0.09-0.1c-0.09-0.18-0.17-0.37-0.26-0.55c-0.17-0.31-0.31-0.65-0.61-0.85
						c-0.04-0.03-0.07-0.08-0.12-0.06c-0.07,0.02-0.04,0.09-0.05,0.15c-0.04,0.37-0.17,0.71-0.33,1.04
						c-0.04-0.17-0.05-0.33-0.06-0.49c-0.03-0.3-0.1-0.57-0.32-0.79c-0.19-0.18-0.37-0.37-0.55-0.55c-0.06-0.06-0.1-0.12-0.09-0.21
						c0.04-0.42-0.14-0.78-0.27-1.16c-0.03-0.09-0.08-0.1-0.15-0.05c-0.13,0.09-0.27,0.18-0.4,0.28c-0.25,0.17-0.27,0.18-0.18,0.48
						c0.13,0.44,0.02,0.82-0.2,1.18c-0.07,0.11-0.08,0.21-0.02,0.32c0.23,0.45,0.07,0.93,0.14,1.41c-0.09-0.04-0.08-0.1-0.09-0.14
						c-0.07-0.26-0.06-0.55-0.21-0.78c-0.19-0.29-0.25-0.6-0.25-0.93c0-0.15-0.05-0.27-0.15-0.38c-0.21-0.23-0.41-0.46-0.48-0.8
						c-0.08-0.44-0.25-0.85-0.64-1.11c-0.12-0.08-0.23-0.19-0.35-0.28c-0.23-0.17-0.42-0.37-0.5-0.67
						c-0.04-0.05-0.09-0.04-0.13-0.05c-0.02,0.13-0.02,0.26-0.05,0.38c-0.04,0.12,0.01,0.19,0.1,0.24c0.13,0.07,0.26,0.15,0.4,0.21
						c0.37,0.17,0.55,0.5,0.56,0.88c0,0.47-0.09,0.95-0.14,1.42c-0.01,0.07-0.04,0.12-0.12,0.11c-0.04-0.01-0.1-0.04-0.12,0.02
						c-0.01,0.05,0.04,0.09,0.06,0.13c0.14,0.22,0.3,0.43,0.43,0.66c0.15,0.27,0.23,0.55,0.05,0.86c-0.24-0.31-0.4-0.67-0.6-1
						c-0.1-0.16-0.16-0.32-0.21-0.51c-0.05-0.23-0.1-0.45-0.25-0.65c-0.42-0.55-0.87-1.08-1.32-1.61c-0.25-0.3-0.55-0.53-0.9-0.7
						c-0.39-0.19-0.77-0.38-1.17-0.52c-0.17,0.03-0.29-0.05-0.42-0.16c-0.48-0.41-0.95-0.84-1.45-1.22
						c-0.17-0.13-0.35-0.23-0.56-0.28c0.01,0.08,0,0.16,0.02,0.24c0.04,0.21-0.02,0.45,0.14,0.64c0.1,0.11,0.19,0.24,0.28,0.36
						c0.09,0.12,0.19,0.23,0.21,0.4c0.07,0.51,0.16,1.02,0.61,1.35c0.02,0.01,0.03,0.04,0.04,0.06c0.26,0.31,0.3,0.71,0.42,1.07
						c0.08,0.24,0.09,0.51,0.3,0.71c0.11,0.1,0.19,0.23,0.24,0.37c0.05,0.15,0.2,0.24,0.23,0.46c-0.55-0.3-0.91-0.78-1.38-1.11
						c0.22,0.36,0.58,0.72,0.81,1.07c0.08,0.13,0.14,0.28,0.27,0.36c0.46,0.29,0.76,0.7,1.21,0.99c0.61,0.4,1.12,0.83,1.57,1.41
						c0.33,0.42,0.64,0.86,1.05,1.2c0.14,0.12,0.32,0.19,0.4,0.39c0.16,0.39,0.33,0.78,0.49,1.18c0.02,0.04,0.06,0.08,0.01,0.13
						c-0.06-0.02-0.08-0.07-0.11-0.12c-0.29-0.34-0.56-0.71-0.87-1.03c-0.47-0.47-0.96-0.92-1.45-1.37
						c-0.25-0.23-0.47-0.49-0.78-0.64c-0.4-0.19-0.81-0.35-1.21-0.52c-0.13-0.05-0.29-0.08-0.38-0.19
						c-0.47-0.52-0.92-1.05-1.38-1.57c-0.27-0.3-0.52-0.63-0.83-0.89c-0.37-0.3-0.68-0.65-0.72-1.17c-0.02-0.17-0.09-0.31-0.25-0.4
						c-0.19-0.12-0.37-0.26-0.55-0.4c-0.18-0.13-0.37-0.25-0.41-0.51c-0.02-0.14-0.07-0.27-0.1-0.41c-0.1-0.6-0.34-1.1-0.81-1.47
						c-0.11-0.08-0.17-0.2-0.2-0.34c-0.07-0.3-0.16-0.6-0.24-0.9c-0.02-0.09-0.06-0.16-0.13-0.22c-0.37-0.33-0.76-0.66-1.12-1.01
						c-0.17-0.17-0.39-0.3-0.53-0.51c-0.21-0.05-0.41-0.1-0.62-0.17c-0.18-0.06-0.3-0.2-0.41-0.35c-0.2-0.27-0.47-0.37-0.76-0.42
						c-0.17-0.02-0.24-0.1-0.29-0.26c-0.25-0.72-0.6-1.37-1.13-1.91c-0.15-0.15-0.32-0.27-0.51-0.37c-0.03,0.09-0.02,0.17,0.04,0.24
						c0.16,0.16,0.25,0.37,0.35,0.57c0.07,0.15,0.08,0.35,0.23,0.42c0.3,0.14,0.25,0.48,0.41,0.7c-0.12-0.04-0.31,0.02-0.26-0.22
						c0-0.01-0.01-0.02-0.02-0.03c-0.28-0.34-0.56-0.69-0.96-0.89 M683.94,1829.49c0.04-0.08,0.07-0.15,0.09-0.24
						c0.09-0.35,0.14-0.7,0.17-1.06c0-0.05-0.01-0.11,0.05-0.13c0.06-0.01,0.06,0.06,0.08,0.1c0.14,0.26,0.28,0.51,0.48,0.73
						c0.33,0.37,0.51,0.8,0.44,1.32c-0.02,0.14,0.05,0.15,0.14,0.1c0.13-0.08,0.19-0.02,0.24,0.1c0.06,0.14,0.12,0.27,0.2,0.39
						c0.24,0.42,0.31,0.89,0.39,1.36c-0.03,0.01-0.04,0.01-0.05,0.01c-0.02,0-0.03-0.01-0.05-0.02c-0.29-0.21-0.62-0.24-0.95-0.15
						c-0.13,0.04-0.2,0-0.28-0.11c-0.19-0.27-0.35-0.59-0.74-0.59c-0.09,0-0.18-0.02-0.27-0.01c-0.11,0.01-0.16-0.04-0.17-0.16
						C683.68,1830.56,683.67,1830.01,683.94,1829.49z M681.93,1827.82c0.11-0.39,0.2-0.77,0.11-1.19c-0.05-0.2-0.03-0.42-0.05-0.63
						c0-0.04-0.01-0.09,0.04-0.11c0.04-0.01,0.06,0.03,0.09,0.05c0.09,0.09,0.2,0.18,0.28,0.28c0.12,0.14,0.28,0.26,0.29,0.46
						c0.04,0.65,0.13,1.3-0.2,1.9c-0.11,0.21-0.15,0.43-0.12,0.68c0.03,0.24,0.01,0.49,0.01,0.73c-0.02,0-0.05,0-0.07,0
						c-0.01-0.03-0.02-0.06-0.03-0.08c-0.17-0.47-0.23-0.97-0.36-1.45C681.85,1828.24,681.87,1828.04,681.93,1827.82z
						 M677.34,1827.68c0.13,0.18,0.28,0.35,0.31,0.57c0.03,0.21,0.13,0.35,0.25,0.5c0.09,0.11,0.22,0.22,0.25,0.36
						c0.07,0.3,0.09,0.61,0.13,0.92c0,0.03,0,0.07-0.04,0.07c-0.02,0-0.04-0.02-0.05-0.04c-0.11-0.15-0.22-0.31-0.33-0.46
						c-0.35-0.5-0.69-1.03-1.09-1.49c-0.3-0.33-0.66-0.61-1.01-0.88c-0.12-0.09-0.16-0.19-0.12-0.33c0.11-0.38,0.02-0.7-0.28-0.95
						c-0.1-0.08-0.1-0.19-0.1-0.3c0-0.13,0.03-0.26-0.05-0.38c-0.29-0.42-0.58-0.85-0.87-1.27c-0.02-0.04-0.09-0.08-0.05-0.12
						c0.05-0.05,0.1,0.02,0.13,0.05c0.21,0.19,0.38,0.42,0.56,0.64c0.07,0.08,0.14,0.17,0.27,0.26c-0.11-0.31-0.14-0.6-0.27-0.85
						c-0.15-0.28-0.48-0.34-0.7-0.56c0.08-0.03,0.14,0.01,0.2,0.03c0.2,0.06,0.2,0.06,0.19-0.15c0-0.02,0-0.05-0.01-0.07
						c0-0.05-0.04-0.11,0.02-0.13c0.05-0.02,0.08,0.04,0.11,0.08c0.3,0.36,0.6,0.72,0.89,1.09c0.09,0.11,0.22,0.21,0.19,0.38
						c-0.04,0.22,0.06,0.38,0.19,0.53c0.23,0.27,0.37,0.57,0.35,0.93c-0.01,0.1,0.04,0.16,0.11,0.22c0.18,0.17,0.36,0.34,0.55,0.5
						c0.18,0.15,0.22,0.34,0.18,0.56C677.25,1827.52,677.28,1827.6,677.34,1827.68z M679.54,1831.7c0-0.09-0.02-0.17-0.07-0.24
						c-0.26-0.39-0.51-0.77-0.77-1.16c-0.05-0.07-0.07-0.14-0.08-0.22c-0.01-0.24-0.02-0.48-0.04-0.72
						c-0.01-0.17-0.05-0.33-0.14-0.47c-0.31-0.46-0.62-0.92-0.83-1.45c0.06-0.01,0.09,0.02,0.12,0.05c0.48,0.5,1.01,0.94,1.44,1.49
						c0.06,0.07,0.08,0.13,0,0.18c-0.12,0.1-0.07,0.17,0.02,0.25c0.12,0.1,0.23,0.21,0.35,0.32c0.08,0.07,0.12,0.16,0.1,0.28
						c-0.03,0.18,0.01,0.34,0.08,0.5c0.1,0.21,0.22,0.41,0.27,0.64c0.04,0.19-0.01,0.38-0.04,0.57c-0.03,0.24-0.07,0.47,0.08,0.7
						c0.16,0.24,0.22,0.51,0.2,0.81c-0.02,0.24,0.04,0.47,0.24,0.63c0.03,0.03,0.08,0.06,0.05,0.13c-0.13-0.03-0.24-0.1-0.36-0.15
						c-0.14-0.05-0.17-0.14-0.18-0.28c-0.02-0.28-0.05-0.57-0.23-0.81c-0.16-0.21-0.23-0.46-0.19-0.73
						C679.55,1831.91,679.53,1831.8,679.54,1831.7z M664.94,1820.8c-0.08,0.47-0.14,0.94-0.21,1.41c-0.02,0.16,0.03,0.28,0.1,0.4
						c0.11,0.18,0.21,0.36,0.34,0.52c0.31,0.37,0.39,0.82,0.44,1.28c0.02,0.22,0.09,0.4,0.21,0.58c0.11,0.16,0.19,0.34,0.31,0.5
						c-0.01,0.01-0.03,0.02-0.04,0.03c-0.27-0.2-0.54-0.4-0.82-0.6c-0.11-0.08-0.18-0.17-0.22-0.3c-0.07-0.23-0.15-0.46-0.22-0.69
						c-0.03-0.08-0.05-0.15-0.1-0.21c-0.4-0.45-0.8-0.9-1.21-1.34c-0.13-0.13-0.14-0.24-0.06-0.41c0.16-0.36,0.3-0.73,0.46-1.09
						c0.05-0.1,0.03-0.17-0.05-0.24c-0.18-0.18-0.34-0.36-0.52-0.53c-0.1-0.1-0.13-0.2-0.11-0.33c0.04-0.22,0.08-0.44,0.11-0.66
						c0.02-0.11,0.06-0.13,0.14-0.05c0.09,0.08,0.17,0.17,0.26,0.25c0.39,0.39,0.78,0.77,1.14,1.19
						C664.95,1820.61,664.96,1820.69,664.94,1820.8z M670.59,1825.44c-0.1,0.01-0.15-0.04-0.21-0.07c-0.19-0.12-0.38-0.28-0.59-0.29
						c-0.37-0.02-0.62-0.18-0.81-0.5c-0.02-0.04-0.07-0.07-0.1-0.11c-0.12-0.15-0.27-0.21-0.46-0.2c-0.23,0.01-0.45,0-0.68,0
						c-0.07,0-0.13,0-0.18-0.05c-0.11-0.1-0.22-0.21-0.33-0.3c-0.18-0.16-0.35-0.34-0.64-0.3c-0.11,0.02-0.26,0.07-0.38-0.03
						c-0.22-0.19-0.43-0.39-0.64-0.59c-0.1-0.1-0.14-0.2-0.08-0.35c0.07-0.2,0.12-0.4,0.21-0.59c0.14-0.34,0.16-0.66-0.11-0.95
						c-0.09-0.09-0.19-0.21-0.19-0.35c0-0.31-0.11-0.56-0.28-0.8c-0.15-0.2-0.2-0.4-0.06-0.63c0.04-0.07,0.06-0.14,0.1-0.21
						c0.07-0.11,0.11-0.24,0.08-0.37c-0.05-0.22-0.1-0.45-0.14-0.67c-0.01-0.07-0.1-0.17-0.04-0.2c0.09-0.05,0.13,0.08,0.18,0.14
						c0.19,0.2,0.35,0.43,0.63,0.49c0.16,0.04,0.21,0.14,0.23,0.3c0.03,0.28,0.09,0.56,0.14,0.84c0.02,0.1,0.34,0.27,0.42,0.23
						c0.04-0.02,0.05-0.05,0.05-0.09c0-0.2,0.02-0.41-0.09-0.6c-0.07-0.12-0.14-0.24-0.15-0.42c0.17,0.15,0.31,0.27,0.45,0.4
						c0.05,0.05,0.06,0.12,0.07,0.19c0.03,0.28,0.06,0.57,0.08,0.85c0.02,0.47,0.17,0.89,0.4,1.33c0.06-0.32,0-0.6-0.05-0.88
						c0.5,0.65,0.94,1.32,1.1,2.16c0.06-0.14,0.03-0.27,0.04-0.4c0.01-0.1,0.02-0.2,0.03-0.31c0.12,0.02,0.16,0.13,0.23,0.21
						c0.24,0.23,0.47,0.48,0.47,0.86c0,0.21,0.11,0.36,0.26,0.51c0.19,0.19,0.42,0.34,0.53,0.59
						C670.27,1824.64,670.42,1825.04,670.59,1825.44z M668.96,1827.84c-0.25-0.23-0.49-0.46-0.8-0.62c-0.21-0.11-0.4-0.24-0.61-0.36
						c0.02-0.05,0.06-0.04,0.08-0.04c0.26,0.04,0.52,0.05,0.72,0.27c0.02,0.03,0.08,0.08,0.12,0.03c0.05-0.06-0.01-0.1-0.05-0.14
						c-0.05-0.06-0.11-0.12-0.2-0.2c0.17,0,0.27,0.09,0.37,0.14c0.38,0.21,0.68,0.54,1,0.82c0.21,0.18,0.4,0.4,0.64,0.53
						c0.33,0.19,0.69,0.3,1.03,0.46c0.41,0.19,0.87,0.3,1.09,0.79c0.12,0.26,0.3,0.48,0.48,0.75c-0.09,0-0.11-0.04-0.15-0.06
						c-0.36-0.29-0.71-0.6-1.09-0.87c-0.25-0.19-0.46-0.45-0.79-0.52c-0.26-0.06-0.52-0.13-0.77-0.2c-0.24-0.07-0.46-0.16-0.64-0.35
						C669.28,1828.12,669.11,1827.98,668.96,1827.84z M666.23,1830.59c0.24-0.04,0.21-0.25,0.25-0.41c0.01-0.05,0-0.11,0.05-0.13
						c0.07-0.02,0.09,0.05,0.12,0.09c0.19,0.23,0.37,0.49,0.66,0.61c0.17,0.07,0.25,0.19,0.29,0.36c0.06,0.29,0.22,0.48,0.48,0.62
						c0.17,0.09,0.3,0.29,0.39,0.5c-0.29-0.23-0.64-0.35-0.94-0.56c-0.44-0.31-0.81-0.7-1.27-0.98
						C666.23,1830.66,666.18,1830.6,666.23,1830.59z M665.43,1827.28c-0.07-0.09-0.06-0.18-0.01-0.29c0.16-0.36,0.39-0.66,0.59-1
						c0.02-0.03,0.04-0.07,0.08-0.06c0.2,0.04,0.42,0.07,0.54,0.24c0.23,0.31,0.38,0.68,0.52,1.05c0,0,0.01,0.01,0.01,0.01
						c0,0-0.01,0.01-0.01,0.01l-0.01-0.01c-0.18-0.05-0.25-0.26-0.48-0.33c0.18,0.23,0.32,0.43,0.47,0.62
						c0.24,0.3,0.42,0.61,0.36,1.03c-0.01,0.09,0,0.19-0.01,0.28c0,0.05,0.03,0.11-0.03,0.13c-0.04,0.01-0.07-0.04-0.1-0.07
						c-0.31-0.32-0.67-0.56-1.03-0.82c-0.02-0.02-0.05-0.05-0.08-0.03c-0.01,0.01-0.02,0.05-0.02,0.06
						c0.05,0.16,0.08,0.33,0.25,0.43c0.32,0.18,0.59,0.43,0.82,0.72c0.13,0.16,0.25,0.32,0.38,0.48c-0.18-0.05-0.32-0.14-0.47-0.2
						c-0.52-0.19-0.92-0.54-1.25-0.99c-0.04-0.05-0.07-0.1-0.08-0.16C665.8,1827.97,665.68,1827.6,665.43,1827.28z M661.01,1825.83
						c0.21-0.19,0.4-0.41,0.59-0.61c0.12-0.12,0.14-0.24,0.08-0.4c-0.12-0.31-0.22-0.63-0.35-0.93c-0.09-0.22-0.06-0.43-0.02-0.67
						c0.06,0.07,0.11,0.14,0.17,0.21c0.31,0.44,0.62,0.89,0.94,1.33c0.22,0.3,0.42,0.63,0.71,0.88c0.39,0.33,0.77,0.68,1.15,1.02
						c0.18,0.17,0.34,0.35,0.42,0.61c0.14,0.48,0.32,0.94,0.62,1.35c0.09,0.13,0.16,0.28,0.24,0.42c-0.27-0.17-0.57-0.28-0.78-0.53
						c-0.2-0.23-0.38-0.48-0.58-0.7c-0.15-0.16-0.25-0.37-0.44-0.47c-0.16-0.09-0.32-0.18-0.48-0.23c-0.16-0.06-0.3-0.19-0.48-0.19
						c0.18,0.16,0.38,0.3,0.57,0.44c0.13,0.1,0.27,0.18,0.37,0.32c0.15,0.2,0.3,0.42,0.48,0.6c0.22,0.23,0.38,0.48,0.28,0.82
						c-0.01,0.05,0,0.1-0.01,0.15c-0.02,0.11-0.01,0.24-0.08,0.37c-0.04-0.06-0.06-0.1-0.09-0.14c-0.13-0.26-0.27-0.53-0.4-0.79
						c-0.09-0.18-0.21-0.31-0.4-0.4c-0.38-0.18-0.77-0.33-1.14-0.51c-0.15-0.07-0.25-0.17-0.31-0.34c-0.1-0.29-0.24-0.54-0.46-0.74
						c-0.2-0.19-0.39-0.39-0.58-0.57C660.93,1825.98,660.89,1825.93,661.01,1825.83z"/>
					<path className="st42" d="M690.52,1843.1c-0.12,0-0.35-0.2-1.36-1.23c-0.41-0.42-0.96-0.98-1.17-1.14l-0.09-0.02
						c-0.01,0-0.02,0-0.02,0c-0.02,0-0.05-0.01-0.08-0.02c-0.11-0.04-0.22-0.08-0.33-0.13c-0.24-0.1-0.49-0.19-0.74-0.27
						c-0.41-0.12-0.8-0.27-1.16-0.46c0,0.01,0.01,0.01,0.01,0.02c0.01,0.01,1.25,2.05,1.27,2.13c0.02,0.07,0.08,0.28-0.06,0.36
						c-0.1,0.06-0.29,0.17-1.85-1.43c-0.03-0.03-0.05-0.05-0.06-0.07l-0.01,0l-0.01-0.01c-0.15-0.18-0.59-0.66-0.85-0.77
						c-0.15-0.06-0.12-0.24-0.1-0.34c0-0.01,0-0.03,0.01-0.04c-0.21,0.04-0.41,0.1-0.62,0.16l-0.12,0.04
						c-0.18,0.06-0.4,0.11-0.64-0.01c-0.15-0.07-0.31-0.13-0.48-0.18c-0.08-0.03-0.15-0.05-0.23-0.08c0.03,0.03,0.05,0.05,0.07,0.08
						c0.07,0.08,0.13,0.15,0.21,0.2c0.05,0.03,0.16,0.1,0.15,0.28l-0.02,0.16l-0.2-0.14c-0.04-0.02-0.07-0.05-0.1-0.07
						c-0.06-0.04-0.13-0.09-0.19-0.13c-0.14-0.1-0.28-0.19-0.43-0.28c-0.13-0.08-0.27-0.16-0.4-0.24c-0.33-0.2-0.66-0.41-1.02-0.56
						c-0.14-0.06-0.26-0.12-0.37-0.19c-0.51-0.33-0.97-0.72-1.39-1.08c-0.23-0.19-0.37-0.27-0.57-0.14
						c-0.14,0.08-0.31,0.07-0.46-0.04c-0.5-0.36-0.96-0.68-1.39-1.05c0.01,0.04,0.01,0.08,0,0.12c0,0,0,0.02,0,0.03
						c0,0.04,0.01,0.15-0.1,0.2c-0.1,0.04-0.17-0.04-0.2-0.07c-0.01-0.01-0.01-0.01-0.02-0.02c-0.04-0.04-0.08-0.08-0.12-0.11
						c-0.12-0.11-0.25-0.23-0.38-0.33l-0.03-0.02c-0.08-0.06-0.18-0.13-0.2-0.28l0-0.03l0.02-0.03c0.02-0.04,0.02-0.09,0.02-0.15
						l0-0.04c0-0.12,0-0.25,0-0.37c0-0.23,0-0.47,0-0.71c0-0.1-0.01-0.11-0.1-0.13c-0.17-0.03-0.33-0.06-0.5-0.1
						c-0.11-0.02-0.22-0.05-0.33-0.07c-0.15-0.03-0.22-0.13-0.27-0.21c-0.13-0.23-0.26-0.46-0.39-0.69
						c-0.08-0.14-0.16-0.27-0.23-0.41c-0.06-0.1-0.11-0.13-0.21-0.12c-0.2,0.02-0.38,0.02-0.54,0c-0.05-0.01-0.08-0.01-0.1,0
						c0,0.01,0.01,0.02,0.01,0.03c0.06,0.13,0.07,0.27,0.01,0.39c-0.03,0.07-0.02,0.12,0.04,0.2c0.19,0.26,0.45,0.6,0.75,0.9
						l0.02,0.02c0.05,0.05,0.1,0.1,0.13,0.18l0.05,0.13l-0.14,0c-0.12,0-0.23-0.05-0.31-0.09c-0.24-0.12-0.48-0.26-0.7-0.39
						c-0.26-0.16-0.53-0.32-0.81-0.44c-0.08-0.04-0.09-0.03-0.12-0.01c-0.2,0.19-0.32,0.38-0.38,0.59l-0.01,0.03l-0.09,0.08
						l-0.03,0.01c-0.03,0.01-0.05,0.02-0.06,0.04c-0.13,0.19-0.31,0.17-0.45,0.16c-0.13-0.01-0.25-0.07-0.36-0.12
						c-0.04-0.02-0.08-0.04-0.11-0.05l-0.02-0.01c-0.1-0.07-0.14-0.18-0.18-0.27c-0.01-0.02-0.01-0.03-0.02-0.05
						c-0.04-0.09-0.1-0.13-0.21-0.14c-0.14-0.01-0.27-0.02-0.41-0.03c-0.21-0.02-0.41-0.03-0.62-0.04c-0.11-0.01-0.2-0.05-0.28-0.13
						c-0.09-0.08-0.17-0.16-0.26-0.24c-0.14-0.13-0.29-0.27-0.43-0.41c-0.01-0.01-0.02-0.01-0.02-0.02c-0.01,0-0.02,0.01-0.03,0.03
						c-0.08,0.08-0.16,0.15-0.26,0.23c-0.04,0.04-0.09,0.08-0.14,0.12l-0.17,0.14v-0.22c0-0.06,0-0.12,0-0.17c0-0.12,0-0.22,0-0.32
						c0-0.07-0.01-0.07-0.06-0.08c-0.09-0.01-0.18-0.03-0.27-0.04c-0.11-0.02-0.23-0.04-0.34-0.05c-0.04,0-0.15-0.02-0.2-0.11
						c-0.05-0.09,0-0.19,0.01-0.23l0.1-0.21c0.08-0.17,0.16-0.34,0.24-0.5c-0.04,0.01-0.09,0.03-0.13,0.04
						c-0.13,0.04-0.27,0.08-0.4,0.13c-0.09,0.03-0.15,0.11-0.22,0.19c-0.03,0.04-0.07,0.08-0.1,0.11c-0.03,0.03-0.08,0.09-0.19,0.05
						l-0.07-0.03l0.01-0.07c0.01-0.14,0.09-0.25,0.16-0.33c0.02-0.02,0.03-0.04,0.05-0.06c0.14-0.19,0.27-0.39,0.39-0.58
						c0.11-0.16,0.21-0.33,0.33-0.49c-0.08-0.03-0.14-0.02-0.2,0.03c-0.08,0.07-0.16,0.13-0.24,0.19c-0.05,0.04-0.1,0.08-0.15,0.12
						c-0.09,0.08-0.16,0.05-0.2,0.02c-0.07-0.06-0.05-0.14-0.04-0.17c0,0,0-0.01,0-0.01c-0.01-0.18,0.01-0.33,0.07-0.46
						c0.07-0.16,0.06-0.21,0.06-0.22c-0.01-0.02-0.05-0.04-0.1-0.05c-0.05-0.02-0.1-0.03-0.14-0.07c-0.01,0.01-0.03,0.04-0.04,0.05
						l-0.01,0.02c-0.29,0.43-0.57,0.85-0.86,1.28l-0.18,0.27c-0.02,0.04-0.08,0.12-0.21,0.09l-0.1-0.02l0.45-1.75c0,0,0,0,0,0
						l-0.03,0.04c-0.15,0.19-0.3,0.38-0.45,0.57c-0.12,0.16-0.25,0.32-0.37,0.47c-0.14,0.17-0.21,0.38-0.29,0.61
						c-0.02,0.05-0.03,0.1-0.05,0.15c-0.04,0.11-0.11,0.25-0.26,0.35c-0.13,0.08-0.26,0.16-0.38,0.24
						c-0.21,0.14-0.43,0.28-0.66,0.41c-0.02,0.01-0.04,0.02-0.05,0.03c0.01,0.01,0.02,0.02,0.03,0.03c0.03,0.02,0.04,0.05,0.05,0.07
						c0,0,0.01,0.01,0.01,0.01l0.04,0.06l-0.04,0.05c-0.06,0.09-0.15,0.13-0.24,0.12c-0.07-0.01-0.14-0.02-0.21-0.03
						c-0.17-0.02-0.35-0.04-0.52-0.14c-0.07-0.04-0.13-0.07-0.2-0.11c-0.24-0.12-0.48-0.25-0.67-0.48c0,0-0.01-0.01-0.03-0.01
						c-0.16-0.04-0.25-0.16-0.33-0.26l-0.33-0.41l0.43,0.23c0.18,0.1,0.39,0.1,0.6,0.09c0.02,0,0.04,0,0.06,0
						c-0.13-0.13-0.25-0.25-0.37-0.37c-0.06-0.06-0.16-0.16-0.15-0.32c0.05-0.52-0.05-1.04-0.3-1.58c0-0.01-0.01-0.01-0.01-0.02
						c-0.06-0.1-0.05-0.17,0.02-0.23c0.09-0.08,0.19-0.01,0.22,0.01c0.01,0,0.01,0.01,0.02,0.01c0.11,0.06,0.23,0.11,0.36,0.18
						c-0.01-0.02-0.01-0.05-0.02-0.07c-0.05-0.2-0.1-0.39-0.14-0.58c-0.07-0.32-0.24-0.55-0.53-0.7c-0.1-0.05-0.17-0.11-0.23-0.21
						l-0.08-0.13l0.15-0.02c0.22-0.03,0.46-0.05,0.68,0.08c0.12,0.07,0.24,0.15,0.36,0.23c0.03,0.02,0.07,0.04,0.1,0.07
						c-0.03-0.15-0.11-0.27-0.19-0.41c-0.02-0.04-0.05-0.08-0.07-0.12c-0.04-0.07-0.08-0.13-0.11-0.2
						c-0.11-0.18-0.22-0.37-0.31-0.57c-0.04-0.09-0.08-0.21-0.02-0.32c0.17-0.32,0.13-0.65,0.08-1c-0.01-0.09-0.02-0.19-0.03-0.28
						c-0.03-0.36-0.25-0.54-0.5-0.76l-0.03-0.03c-0.04-0.04-0.09-0.07-0.13-0.11c-0.09-0.08-0.18-0.15-0.27-0.24
						c-0.02-0.01-0.03-0.02-0.03-0.03c-0.01,0.01-0.02,0.02-0.03,0.04c-0.04,0.05-0.07,0.1-0.1,0.16c-0.07,0.11-0.15,0.24-0.27,0.36
						l-0.24,0.23l0.07-0.33c0.01-0.06,0.02-0.12,0.03-0.17c0.02-0.11,0.04-0.21,0.08-0.31c0.09-0.25,0.06-0.5-0.1-0.8
						c-0.11-0.21-0.22-0.44-0.31-0.69c-0.03-0.07-0.05-0.07-0.08-0.07c-0.04,0-0.07-0.01-0.11-0.01c-0.04-0.01-0.08-0.01-0.12-0.01
						c-0.24,0.01-0.39-0.12-0.52-0.24c-0.05-0.05-0.1-0.09-0.16-0.12c-0.03-0.02-0.05-0.04-0.07-0.06
						c-0.01-0.01-0.02-0.02-0.03-0.03c0,0.01,0,0.03,0.01,0.05c0,0.03,0.01,0.06,0.01,0.09c-0.01,0.3-0.02,0.6-0.03,0.9
						c0,0.14-0.01,0.29-0.01,0.43l0,0.02c0,0.04,0,0.11-0.05,0.17l-0.05,0.05l-0.07-0.02c-0.11-0.04-0.12-0.14-0.12-0.19
						c0-0.01,0-0.02,0-0.02c-0.06-0.35-0.13-0.7-0.19-1.06l-0.05-0.28c-0.02-0.11-0.08-0.2-0.14-0.3c-0.04-0.06-0.08-0.12-0.12-0.2
						c-0.01-0.03-0.03-0.06-0.05-0.09c0,0.05,0.01,0.11,0.02,0.16c0.06,0.46-0.09,0.75-0.46,0.88c-0.04,0.01-0.08,0.04-0.08,0.18
						c-0.02,0.47-0.02,0.92-0.02,1.3c0,0.09,0,0.18,0.03,0.26c0.08,0.23,0.13,0.47,0.19,0.7c0.04,0.19,0.09,0.38,0.14,0.56
						l0.01,0.02c0.02,0.06,0.05,0.15-0.02,0.26l-0.09,0.15l-0.19-0.36c-0.08-0.15-0.16-0.3-0.23-0.44c0,0.02-0.01,0.04-0.01,0.07
						c-0.04,0.21-0.08,0.42-0.14,0.63c-0.01,0.04,0,0.08,0.06,0.15c0.25,0.31,0.52,0.62,0.8,0.9c0.06,0.07,0.21,0.22,0.03,0.41
						c-0.02,0.03-0.07,0.07,0.01,0.2c0.22,0.37,0.44,0.75,0.66,1.12l0.07,0.12c0.06,0.1,0.1,0.18,0.06,0.26
						c-0.03,0.08-0.12,0.11-0.24,0.14l-0.01,0c-0.01,0-0.02,0-0.02,0.01c-0.21,0.05-0.34,0.08-0.37,0.13
						c-0.04,0.05-0.02,0.19-0.01,0.42c0,0.01,0,0.02,0,0.03c0.01,0.05,0.02,0.13-0.03,0.21l-0.03,0.04l-0.04,0.01
						c-0.16,0.02-0.26-0.09-0.31-0.15c-0.06-0.06-0.12-0.13-0.18-0.2c-0.16-0.17-0.32-0.35-0.48-0.51
						c-0.15-0.14-0.32-0.32-0.47-0.53l-0.01-0.02c-0.02-0.06-0.04-0.11-0.06-0.17c-0.05-0.13-0.1-0.26-0.14-0.39
						c-0.09-0.33-0.29-0.58-0.63-0.78c-0.13-0.08-0.21-0.07-0.32,0.02c-0.16,0.13-0.35,0.26-0.6,0.2c-0.02,0-0.04-0.01-0.07-0.02
						c-0.09-0.02-0.18-0.04-0.29-0.03l-0.23,0.02l0.15-0.19c0.1-0.12,0.21-0.17,0.31-0.22c0.04-0.02,0.08-0.03,0.11-0.05
						c0.04-0.02,0.08-0.04,0.12-0.05c0.06-0.02,0.1-0.04,0.1-0.06c0.01-0.04,0.02-0.07,0.03-0.11c0.08-0.26,0.15-0.51,0.04-0.77
						c-0.12-0.29-0.25-0.57-0.38-0.85c-0.06-0.14-0.13-0.28-0.19-0.42l-0.07,0.09c-0.1,0.13-0.19,0.27-0.3,0.39
						c-0.11,0.13-0.17,0.27-0.19,0.44c-0.02,0.17-0.04,0.34-0.07,0.5c-0.01,0.07-0.02,0.14-0.03,0.21l-0.02,0.11l-0.11-0.03
						c-0.11-0.03-0.14-0.12-0.14-0.16l0-0.01c-0.11-0.35-0.21-0.7-0.31-1.03c-0.03-0.11-0.02-0.22,0.02-0.34
						c0.14-0.33,0.12-0.58-0.06-0.8c-0.11-0.13-0.06-0.26-0.02-0.35c0.09-0.17,0.17-0.35,0.26-0.52l0.09-0.19
						c0.01-0.01,0.01-0.02,0.02-0.03c0.01-0.02,0.03-0.05,0.02-0.05c-0.09-0.14-0.18-0.27-0.26-0.4c-0.04,0.05-0.08,0.1-0.13,0.15
						c-0.18,0.22-0.36,0.42-0.39,0.72c-0.02,0.16-0.06,0.31-0.1,0.46c-0.02,0.07-0.04,0.13-0.05,0.2l-0.02,0.09l-0.22-0.02
						l-0.05-1.06c-0.01-0.11,0.01-0.22,0.05-0.34c0.08-0.25,0.16-0.5,0.24-0.75c0.05-0.16,0.1-0.32,0.15-0.48
						c0.02-0.05,0.01-0.1-0.01-0.15l-0.01-0.02c-0.06-0.14-0.13-0.27-0.19-0.41c-0.03,0.04-0.05,0.07-0.08,0.11
						c-0.14,0.18-0.28,0.35-0.32,0.58c-0.03,0.16-0.13,0.17-0.19,0.17l-0.08-0.01l-0.01-0.13c-0.03-0.25-0.06-0.53,0.14-0.78
						c0.03-0.04,0.06-0.09,0.09-0.15l0.01-0.02c0.05-0.11,0.1-0.19,0.09-0.22c-0.01-0.03-0.09-0.1-0.16-0.17
						c-0.06-0.05-0.13-0.12-0.12-0.2c0.01-0.1,0.1-0.15,0.15-0.18c0.7-0.4,1.46-0.77,2.32-0.85l0.01,0c0.25-0.02,0.5-0.04,0.7-0.19
						c0.5-0.38,1.01-0.84,1.6-1.47c0.17-0.18,0.34-0.25,0.56-0.22l0.02,0l0.02,0.01c0,0,0.03,0,0.04,0l0.01,0l0.4,0.02l0.15-0.08
						c0.02-0.03,0.05-0.05,0.07-0.08c0.06-0.06,0.12-0.12,0.16-0.19c0.21-0.3,0.47-0.48,0.79-0.55l0.02-0.01
						c0.06-0.01,0.19-0.05,0.27,0.11c0.07,0.15,0.14,0.3,0.21,0.45c0.16,0.34,0.32,0.68,0.47,1.03c0.01,0.02,0.03,0.05,0.04,0.07
						c0.05,0.08,0.11,0.18,0.08,0.32l-0.02,0.09l-0.09-0.01c-0.09-0.01-0.14-0.06-0.17-0.09c-0.01-0.01-0.01-0.01-0.02-0.02
						c-0.02-0.02-0.03-0.03-0.04-0.04c-0.01,0.01-0.01,0.04-0.02,0.07c-0.03,0.08-0.02,0.17,0.01,0.27l0.2,0.83l-0.08,0.03
						c-0.06,0.02-0.14,0.03-0.21-0.06l-0.06-0.08c-0.08-0.11-0.16-0.21-0.24-0.32c0.03,0.06,0.06,0.11,0.08,0.17
						c0.02,0.04,0.04,0.07,0.06,0.11c0.13,0.24,0.28,0.52,0.06,0.85c0,0,0.01,0,0.01,0c0.07,0.01,0.15,0.03,0.22,0.04
						c0.11,0.02,0.22,0.04,0.33,0.05c0.19,0.01,0.36,0.09,0.52,0.24l0.08,0.08c0.09,0.08,0.18,0.16,0.27,0.25
						c0.23,0.25,0.47,0.42,0.73,0.54c0.03,0.01,0.06,0.03,0.09,0.04c0.17,0.07,0.34,0.15,0.49,0.29c0.16,0.16,0.32,0.33,0.47,0.49
						c0.05,0.05,0.1,0.1,0.14,0.15c-0.08-0.36-0.29-0.66-0.62-0.92c-0.01-0.01-0.02-0.02-0.04-0.03c-0.01-0.01-0.02-0.02-0.03-0.02
						c-0.07,0.05-0.14,0.06-0.21,0.04c-0.11-0.03-0.16-0.12-0.21-0.21c-0.04-0.08-0.09-0.15-0.13-0.23
						c-0.02-0.04-0.05-0.08-0.07-0.12c-0.06-0.09-0.11-0.19-0.16-0.28c-0.17-0.32-0.33-0.62-0.63-0.81
						c-0.12-0.08-0.1-0.18-0.08-0.21c0.04-0.08,0.11-0.09,0.14-0.09l0.11-0.01c0.09-0.01,0.18-0.02,0.26-0.04
						c0.07-0.01,0.08-0.02,0.07-0.09c-0.01-0.14-0.05-0.27-0.09-0.42c0-0.01,0-0.01-0.01-0.02c-0.01-0.03-0.05-0.13,0.04-0.21
						c0.06-0.04,0.12-0.04,0.19,0.01c0,0,0.01,0.01,0.01,0.01c0.46,0.25,0.76,0.55,0.94,0.94c0.01,0.02,0.02,0.04,0.03,0.06
						c-0.02-0.18-0.05-0.37-0.08-0.55c-0.03-0.16-0.04-0.25,0.02-0.33c0.05-0.07,0.14-0.08,0.28-0.09c0.06,0,0.12-0.02,0.19-0.03
						c0.18-0.03,0.39-0.07,0.59,0.05c0.2,0.12,0.41,0.12,0.63,0.11c0.03,0,0.06,0,0.09,0c-0.04-0.02-0.07-0.05-0.11-0.07
						c-0.07-0.04-0.14-0.09-0.21-0.14c-0.16-0.12-0.34-0.23-0.56-0.19c-0.22,0.04-0.29-0.13-0.32-0.23
						c-0.09-0.23-0.03-0.43,0.02-0.61c0.02-0.09,0.05-0.17,0.05-0.25c0.01-0.05,0.04-0.1,0.08-0.12c0.05-0.02,0.11-0.02,0.16,0.02
						c0.05,0.04,0.11,0.07,0.18,0.1c0.2,0.11,0.46,0.24,0.45,0.6c0.08-0.05,0.17-0.09,0.25-0.14c0.06-0.03,0.12-0.06,0.18-0.1
						c0.05-0.03,0.09-0.05,0.13-0.07c0.03-0.02,0.07-0.03,0.08-0.04c0-0.02-0.01-0.07-0.02-0.13c-0.01-0.04-0.02-0.08-0.02-0.13
						c-0.04-0.23-0.06-0.48,0.22-0.67c0.14-0.1,0.17-0.19,0.1-0.32c-0.03-0.06-0.04-0.13-0.06-0.19c-0.01-0.03-0.01-0.06-0.02-0.09
						l-0.01-0.04c-0.07-0.23-0.13-0.47-0.05-0.73c0.03-0.1,0.01-0.13-0.04-0.16s-0.12-0.07-0.18-0.13
						c-0.04-0.03-0.05-0.04-0.06-0.04c0,0-0.02,0.01-0.08,0.09c-0.08,0.14-0.17,0.28-0.26,0.41c-0.16,0.25-0.33,0.51-0.47,0.77
						c-0.18,0.35-0.47,0.4-0.75,0.44l-0.18,0.03l0.07-0.17c0.05-0.11,0.12-0.19,0.18-0.25c0.02-0.02,0.04-0.05,0.06-0.07
						c0.05-0.06,0.11-0.12,0.17-0.18c0.12-0.12,0.24-0.24,0.3-0.4c0.04-0.11,0.09-0.22,0.14-0.32c0.06-0.11,0.11-0.22,0.15-0.33
						c0.07-0.2,0.17-0.36,0.31-0.47c0.03-0.02,0.06-0.05,0.1-0.07c0.07-0.05,0.13-0.09,0.15-0.14c0.01-0.03-0.01-0.06-0.06-0.12
						c-0.04-0.05-0.08-0.1-0.1-0.16l-0.07-0.11c-0.06-0.02-0.11-0.05-0.15-0.07c-0.13-0.08-0.19-0.1-0.29,0
						c-0.07,0.08-0.16,0.03-0.19,0.02c-0.01,0-0.01-0.01-0.01-0.01c-0.17-0.06-0.26-0.19-0.34-0.31l-0.04-0.05
						c0-0.01-0.01-0.01-0.02-0.02c0,0,0,0,0,0c-0.1,0.12-0.15,0.2-0.25,0.2c0,0,0,0,0,0c-0.09,0-0.15-0.08-0.26-0.21
						c-0.01-0.02-0.03-0.03-0.04-0.05c-0.03-0.03-0.05-0.06-0.08-0.1l0.17-0.11c-0.02-0.03-0.04-0.04-0.04-0.04
						c0.02,0,0.09,0.01,0.11,0.01c0.02,0,0.04,0,0.05,0c0.07,0.01,0.13,0.01,0.2,0.02c0.14,0.02,0.28,0.04,0.41,0.06
						c0.13,0.02,0.26,0.05,0.38,0.08c0.13,0.03,0.26,0.06,0.4,0.08c0.11,0.02,0.21,0.08,0.3,0.14c0.02,0.02,0.05,0.03,0.07,0.04
						c0.11,0.06,0.22,0.13,0.36,0.22l0.04,0.03l0,0.05c0.04,0.68,0.3,1.15,0.8,1.44l-0.1,0.17c-0.55-0.31-0.84-0.82-0.9-1.55
						c-0.11-0.07-0.21-0.13-0.31-0.19c-0.02-0.01-0.05-0.03-0.08-0.05c-0.07-0.05-0.16-0.1-0.22-0.11
						c-0.14-0.02-0.28-0.05-0.41-0.09c-0.12-0.03-0.25-0.06-0.37-0.08c0.06,0.09,0.12,0.17,0.22,0.21c0.01,0,0.02,0.01,0.03,0.01
						c0.19-0.18,0.37-0.07,0.5-0.01c0.05,0.03,0.1,0.06,0.14,0.06l0.04,0.01l0.13,0.21c0.01,0.03,0.04,0.07,0.07,0.11
						c0.06,0.07,0.14,0.17,0.11,0.3c-0.03,0.13-0.14,0.2-0.23,0.26c-0.03,0.02-0.06,0.04-0.08,0.06c-0.11,0.09-0.19,0.21-0.24,0.38
						c-0.04,0.13-0.1,0.25-0.16,0.36c-0.05,0.1-0.1,0.2-0.13,0.3c-0.07,0.2-0.21,0.34-0.34,0.47c-0.06,0.06-0.11,0.11-0.16,0.17
						c-0.02,0.03-0.05,0.06-0.08,0.08c0.16-0.05,0.28-0.12,0.36-0.29c0.14-0.27,0.31-0.53,0.47-0.79c0.09-0.14,0.17-0.27,0.26-0.41
						c0.05-0.09,0.12-0.17,0.22-0.19c0.07-0.01,0.15,0.02,0.22,0.09c0.04,0.04,0.09,0.07,0.14,0.1c0.15,0.09,0.19,0.22,0.13,0.4
						c-0.07,0.2-0.01,0.4,0.05,0.61l0.01,0.04c0.01,0.03,0.02,0.07,0.02,0.11c0.01,0.05,0.02,0.1,0.04,0.14
						c0.08,0.16,0.12,0.39-0.17,0.58c-0.17,0.11-0.18,0.24-0.14,0.47c0.01,0.05,0.02,0.09,0.02,0.13c0.04,0.23,0.04,0.28-0.17,0.39
						c-0.03,0.02-0.07,0.04-0.12,0.06c-0.06,0.03-0.12,0.07-0.19,0.1c-0.09,0.05-0.18,0.1-0.27,0.15c-0.04,0.02-0.13,0.07-0.21,0.02
						c-0.05-0.03-0.07-0.09-0.07-0.17c0.02-0.27-0.14-0.36-0.35-0.46c-0.05-0.02-0.09-0.05-0.14-0.08c-0.01,0.07-0.03,0.13-0.05,0.2
						c-0.05,0.17-0.09,0.32-0.02,0.49c0.05,0.11,0.06,0.11,0.1,0.1c0.3-0.06,0.53,0.08,0.72,0.23c0.06,0.05,0.13,0.09,0.2,0.13
						c0.13,0.08,0.26,0.16,0.37,0.28l0.19,0.21l-0.28-0.04c-0.12-0.02-0.23-0.01-0.36-0.01c-0.24,0.01-0.5,0.02-0.74-0.14
						c-0.13-0.08-0.28-0.06-0.44-0.02c-0.07,0.01-0.14,0.03-0.21,0.03c-0.03,0-0.1,0.01-0.13,0.02c0,0.03,0.01,0.1,0.02,0.17
						c0.05,0.32,0.1,0.64,0.12,0.98l0.02,0.39l-0.2-0.33c-0.03-0.04-0.05-0.08-0.08-0.13c-0.06-0.1-0.13-0.2-0.18-0.31
						c-0.15-0.33-0.4-0.59-0.78-0.81c0.04,0.13,0.06,0.25,0.08,0.39c0.02,0.23-0.13,0.28-0.24,0.3c-0.09,0.02-0.18,0.03-0.27,0.04
						l-0.02,0c0.29,0.22,0.46,0.53,0.62,0.84c0.05,0.09,0.1,0.19,0.15,0.28c0.02,0.04,0.05,0.08,0.07,0.12
						c0.05,0.08,0.09,0.15,0.13,0.23c0.03,0.05,0.06,0.11,0.09,0.12c0,0,0.02,0,0.06-0.02c0.12-0.08,0.22,0.01,0.26,0.05
						c0.01,0.01,0.02,0.01,0.03,0.02c0.39,0.3,0.62,0.66,0.71,1.09c0,0,0.01,0.02,0.02,0.03c0.01,0.02,0.02,0.03,0.03,0.05
						c0,0,0.01,0.02,0.02,0.02c0.03,0.03,0.07,0.08,0.07,0.15c0,0.03-0.02,0.07-0.07,0.11c-0.05,0.04-0.09,0.04-0.12,0.03
						c-0.07-0.01-0.11-0.07-0.14-0.11c-0.01-0.01-0.01-0.02-0.02-0.03c-0.09-0.1-0.19-0.2-0.28-0.3c-0.15-0.16-0.3-0.33-0.46-0.48
						c-0.12-0.11-0.27-0.18-0.43-0.25c-0.03-0.01-0.06-0.03-0.09-0.04c-0.28-0.13-0.54-0.32-0.79-0.59
						c-0.08-0.08-0.17-0.16-0.25-0.24l-0.08-0.08c-0.13-0.12-0.25-0.17-0.4-0.19c-0.12-0.01-0.24-0.03-0.36-0.06
						c-0.07-0.01-0.14-0.03-0.21-0.04c-0.12-0.02-0.17-0.08-0.18-0.12c-0.01-0.03-0.03-0.1,0.03-0.18c0.15-0.22,0.07-0.39-0.06-0.65
						c-0.02-0.04-0.04-0.07-0.06-0.11c-0.07-0.13-0.14-0.27-0.2-0.4c-0.07-0.13-0.14-0.27-0.21-0.4c-0.02-0.04-0.03-0.08-0.03-0.12
						c0,0,0,0,0,0l-0.09-0.08l0.24-0.17l0.03,0.14c0.07,0.05,0.11,0.11,0.15,0.17c0.01,0.02,0.02,0.03,0.03,0.05
						c0.15,0.21,0.31,0.41,0.46,0.62l-0.1-0.41c-0.03-0.12-0.05-0.25,0-0.38c0.03-0.1,0.07-0.19,0.16-0.22
						c0.04-0.01,0.07,0,0.11,0.01c-0.01-0.02-0.02-0.03-0.02-0.05c-0.15-0.34-0.31-0.69-0.47-1.02c-0.07-0.15-0.14-0.3-0.21-0.45
						c0,0,0-0.01,0-0.01c-0.01,0-0.02,0.01-0.03,0.01l-0.03,0.01c-0.27,0.06-0.49,0.21-0.67,0.47c-0.05,0.08-0.12,0.14-0.18,0.21
						c-0.03,0.03-0.05,0.06-0.08,0.08l-0.02,0.02l-0.21,0.12l-0.44-0.02c-0.03,0-0.1,0.01-0.15-0.02c-0.15-0.01-0.25,0.03-0.37,0.16
						c-0.6,0.63-1.12,1.11-1.63,1.49c-0.24,0.18-0.54,0.21-0.8,0.23l-0.01,0c-0.77,0.07-1.45,0.38-2.23,0.82
						c-0.02,0.01-0.03,0.02-0.04,0.02c0.01,0.01,0.02,0.02,0.03,0.03c0.29,0.24,0.29,0.31,0.13,0.63l-0.01,0.03
						c-0.03,0.06-0.06,0.13-0.11,0.19c-0.08,0.1-0.11,0.21-0.12,0.33c0.07-0.15,0.17-0.28,0.27-0.4c0.03-0.04,0.07-0.09,0.1-0.13
						c0.05-0.07,0.11-0.1,0.17-0.09c0.09,0.01,0.13,0.1,0.15,0.13c0.06,0.15,0.13,0.29,0.2,0.43l0.01,0.02
						c0.04,0.09,0.05,0.19,0.02,0.29c-0.05,0.16-0.1,0.32-0.15,0.48c-0.08,0.25-0.16,0.5-0.24,0.75c-0.03,0.1-0.05,0.18-0.04,0.27
						l0.02,0.46c0.01-0.05,0.02-0.09,0.02-0.14c0.04-0.36,0.24-0.6,0.43-0.83c0.05-0.06,0.1-0.12,0.15-0.19
						c0.05-0.06,0.1-0.08,0.13-0.07c0.08,0,0.12,0.07,0.14,0.09l0.02,0.02c0.09,0.13,0.18,0.27,0.27,0.41
						c0.07,0.11,0.01,0.2-0.02,0.26c0,0.01-0.01,0.02-0.01,0.03l-0.09,0.19c-0.09,0.17-0.17,0.35-0.26,0.52
						c-0.04,0.07-0.03,0.1,0,0.13c0.22,0.28,0.25,0.61,0.09,1c-0.03,0.07-0.04,0.14-0.02,0.21c0.08,0.26,0.16,0.54,0.24,0.82
						c0.02-0.12,0.03-0.25,0.05-0.37c0.02-0.21,0.1-0.39,0.24-0.55c0.1-0.12,0.2-0.25,0.29-0.38l0.08-0.11
						c0.03-0.04,0.09-0.12,0.18-0.11c0.09,0.01,0.13,0.1,0.15,0.13c0.07,0.15,0.13,0.29,0.2,0.44c0.13,0.28,0.26,0.57,0.38,0.85
						c0.14,0.33,0.05,0.62-0.03,0.9c-0.01,0.03-0.02,0.07-0.03,0.1c-0.04,0.13-0.15,0.16-0.23,0.19c-0.03,0.01-0.06,0.02-0.08,0.03
						c-0.05,0.03-0.09,0.05-0.14,0.07c-0.01,0.01-0.02,0.01-0.03,0.02c0.02,0.01,0.05,0.01,0.07,0.02c0.02,0.01,0.04,0.01,0.06,0.01
						c0.15,0.03,0.28-0.03,0.44-0.16c0.18-0.14,0.34-0.15,0.54-0.03c0.39,0.22,0.62,0.51,0.72,0.9c0.03,0.13,0.08,0.25,0.13,0.38
						c0.02,0.05,0.04,0.11,0.06,0.16c0.13,0.19,0.29,0.35,0.43,0.49c0.17,0.17,0.34,0.35,0.49,0.52c0.06,0.07,0.12,0.13,0.18,0.19
						c0.03,0.03,0.05,0.06,0.08,0.07c0,0,0,0,0-0.01c0-0.02-0.01-0.03-0.01-0.05c-0.05-0.59-0.02-0.63,0.53-0.76l0.03-0.01
						c0.05-0.01,0.07-0.02,0.09-0.03c-0.01-0.02-0.02-0.04-0.04-0.08l-0.07-0.12c-0.21-0.37-0.43-0.74-0.66-1.11
						c-0.13-0.21-0.06-0.36,0.02-0.44c0.05-0.05,0.05-0.06-0.02-0.14c-0.29-0.29-0.56-0.6-0.82-0.92c-0.05-0.06-0.14-0.17-0.09-0.32
						c0.06-0.2,0.1-0.41,0.14-0.62c0.01-0.06,0.02-0.12,0.03-0.17l0-0.01c0.02-0.14,0.1-0.16,0.16-0.16c0.07,0,0.12,0.04,0.14,0.13
						c0,0,0,0.01,0,0.01c0.03,0.06,0.06,0.11,0.09,0.17c-0.01-0.04-0.02-0.09-0.03-0.13c-0.05-0.22-0.1-0.46-0.18-0.68
						c-0.04-0.11-0.04-0.22-0.04-0.33c0-0.36,0-0.83,0.02-1.31c0-0.08,0.01-0.28,0.21-0.36c0.28-0.1,0.38-0.3,0.33-0.66
						c-0.02-0.15-0.04-0.32,0.04-0.5l0.08-0.17l0.1,0.16c0.02,0.04,0.04,0.07,0.07,0.1c0.05,0.07,0.09,0.14,0.13,0.22
						c0.03,0.06,0.06,0.11,0.1,0.17c0.07,0.11,0.15,0.22,0.17,0.37l0.05,0.28c0.03,0.18,0.06,0.36,0.1,0.54
						c0.01-0.27,0.02-0.54,0.03-0.81c0-0.02,0-0.04,0-0.05c-0.01-0.07-0.03-0.21,0.11-0.27c0.11-0.05,0.2,0.03,0.25,0.08
						c0.01,0.01,0.03,0.03,0.04,0.04c0.06,0.04,0.12,0.09,0.18,0.14c0.12,0.11,0.23,0.2,0.39,0.19c0.05,0,0.1,0,0.15,0.01
						c0.03,0,0.06,0.01,0.09,0.01c0.16,0.01,0.23,0.12,0.26,0.2c0.09,0.24,0.19,0.46,0.3,0.67c0.18,0.34,0.22,0.64,0.12,0.94
						c0.02-0.04,0.05-0.07,0.07-0.11c0.04-0.05,0.09-0.12,0.18-0.12c0.08-0.01,0.15,0.04,0.19,0.08c0.09,0.08,0.18,0.16,0.26,0.23
						c0.04,0.04,0.09,0.07,0.13,0.11l0.03,0.03c0.26,0.22,0.53,0.45,0.56,0.89c0.01,0.09,0.02,0.18,0.03,0.27
						c0.05,0.37,0.1,0.74-0.1,1.12c-0.01,0.03-0.01,0.07,0.03,0.14c0.09,0.19,0.19,0.37,0.3,0.55c0.04,0.07,0.08,0.13,0.12,0.2
						c0.02,0.04,0.04,0.07,0.07,0.11c0.1,0.16,0.2,0.33,0.23,0.54l0,0.01c0.01,0.04,0.03,0.15-0.08,0.21
						c-0.09,0.04-0.16-0.02-0.18-0.04c0,0-0.01-0.01-0.01-0.01c-0.05-0.03-0.1-0.07-0.15-0.1c-0.11-0.07-0.23-0.15-0.35-0.22
						c-0.11-0.06-0.23-0.08-0.35-0.08c0.01,0,0.01,0.01,0.02,0.01c0.35,0.18,0.56,0.45,0.64,0.83c0.04,0.19,0.09,0.38,0.14,0.58
						c0.02,0.09,0.05,0.19,0.07,0.29l0.05,0.21l-0.36-0.18c-0.12-0.06-0.22-0.11-0.33-0.16c0.24,0.55,0.34,1.08,0.29,1.62
						c0,0.05,0.02,0.09,0.09,0.16c0.13,0.13,0.27,0.27,0.41,0.41c0.06,0.06,0.09,0.12,0.08,0.18c-0.01,0.06-0.05,0.13-0.19,0.12
						l-0.02,0c-0.03,0-0.07,0-0.11,0c-0.08,0-0.16,0-0.23,0c0.02,0.01,0.03,0.02,0.04,0.04c0.17,0.2,0.38,0.31,0.61,0.43
						c0.07,0.04,0.14,0.07,0.2,0.11c0.13,0.08,0.29,0.09,0.45,0.11c0.06,0.01,0.13,0.02,0.19,0.03c-0.04-0.04-0.07-0.09-0.06-0.15
						c0.01-0.08,0.08-0.14,0.16-0.18c0.22-0.13,0.44-0.27,0.65-0.4c0.13-0.08,0.26-0.17,0.39-0.25c0.08-0.05,0.13-0.12,0.18-0.25
						c0.02-0.05,0.03-0.1,0.05-0.15c0.08-0.23,0.16-0.46,0.32-0.66c0.12-0.16,0.25-0.31,0.37-0.47c0.15-0.19,0.3-0.38,0.45-0.57
						l0.03-0.04c0.07-0.09,0.15-0.2,0.29-0.27l0.2-0.11l-0.47,1.81l0.01-0.01c0.29-0.43,0.57-0.85,0.86-1.28l0.01-0.02
						c0.04-0.06,0.09-0.13,0.17-0.15c0.05-0.01,0.1,0.01,0.15,0.05c0.02,0.01,0.05,0.03,0.08,0.04c0.07,0.03,0.17,0.06,0.22,0.16
						c0.04,0.09,0.02,0.22-0.06,0.39c-0.04,0.09-0.06,0.19-0.06,0.32c0.03-0.02,0.05-0.04,0.08-0.06c0.08-0.06,0.16-0.12,0.24-0.19
						c0.13-0.11,0.27-0.13,0.44-0.05c0.03,0.01,0.09,0.04,0.11,0.11c0.02,0.07-0.02,0.13-0.05,0.16c-0.12,0.16-0.23,0.34-0.34,0.5
						c-0.09,0.14-0.18,0.28-0.28,0.42c0,0,0.01,0,0.01,0c0.14-0.05,0.28-0.1,0.42-0.14c0.06-0.02,0.11-0.03,0.17-0.05
						c0.01,0,0.01,0,0.02-0.01c0.04-0.01,0.13-0.05,0.21,0.03c0.07,0.09,0.02,0.18,0,0.21c0,0.01-0.01,0.01-0.01,0.02
						c-0.08,0.18-0.17,0.36-0.25,0.54l-0.1,0.21c-0.01,0.02-0.01,0.03-0.02,0.04c0.01,0,0.02,0.01,0.05,0.01
						c0.12,0.01,0.24,0.03,0.35,0.05c0.09,0.01,0.18,0.03,0.26,0.04c0.11,0.01,0.24,0.08,0.23,0.29c0,0.08,0,0.17,0,0.27
						c0.08-0.07,0.16-0.14,0.23-0.2c0.05-0.04,0.17-0.16,0.33-0.01c0.14,0.14,0.28,0.27,0.42,0.4c0.09,0.08,0.17,0.16,0.26,0.24
						c0.05,0.05,0.1,0.07,0.16,0.07c0.21,0.01,0.42,0.03,0.62,0.04c0.13,0.01,0.27,0.02,0.4,0.03c0.18,0.01,0.3,0.1,0.38,0.26
						c0.01,0.02,0.01,0.03,0.02,0.05c0.03,0.07,0.06,0.14,0.11,0.18c0.04,0.02,0.07,0.03,0.11,0.05c0.1,0.05,0.19,0.09,0.29,0.1
						c0.15,0.01,0.21,0.01,0.27-0.07c0.04-0.06,0.09-0.1,0.15-0.12l0.01-0.01c0.07-0.23,0.2-0.44,0.42-0.65
						c0.13-0.12,0.27-0.06,0.34-0.03c0.29,0.13,0.56,0.29,0.83,0.45c0.19,0.12,0.39,0.24,0.6,0.34c-0.26-0.28-0.49-0.57-0.66-0.81
						c-0.07-0.1-0.15-0.23-0.06-0.41c0.03-0.07,0.03-0.14-0.01-0.22c-0.05-0.1-0.05-0.18,0-0.24c0.07-0.09,0.2-0.09,0.3-0.08
						c0.14,0.02,0.3,0.02,0.49,0c0.24-0.03,0.35,0.11,0.41,0.22c0.08,0.14,0.15,0.27,0.23,0.4c0.13,0.23,0.27,0.46,0.4,0.7
						c0.04,0.07,0.07,0.1,0.13,0.11c0.11,0.02,0.22,0.05,0.33,0.07c0.16,0.03,0.32,0.07,0.48,0.1c0.19,0.03,0.27,0.13,0.27,0.33
						c-0.01,0.23,0,0.47,0,0.7c0,0.12,0,0.25,0,0.37l0,0.04c0,0.06,0,0.14-0.03,0.21c0.01,0.04,0.05,0.07,0.11,0.11l0.03,0.02
						c0.14,0.11,0.27,0.23,0.4,0.34c0.04,0.04,0.08,0.07,0.11,0.1c0,0,0-0.01,0-0.01c0.01-0.05-0.01-0.09-0.05-0.16
						c-0.02-0.04-0.05-0.09-0.07-0.14l-0.03-0.09l0.09-0.03c0.13-0.04,0.21,0.03,0.25,0.07l0.02,0.02c0.47,0.4,0.95,0.75,1.5,1.13
						c0.09,0.06,0.18,0.07,0.24,0.03c0.36-0.22,0.64,0.02,0.8,0.16c0.42,0.36,0.87,0.74,1.37,1.07c0.1,0.07,0.21,0.12,0.34,0.18
						c0.37,0.15,0.72,0.37,1.05,0.57c0.13,0.08,0.26,0.16,0.4,0.24c0.01,0,0.01,0.01,0.02,0.01c-0.02-0.02-0.03-0.04-0.03-0.06
						c-0.01-0.04-0.01-0.09,0.01-0.13c0.03-0.05,0.09-0.12,0.24-0.05c0.16,0.08,0.32,0.13,0.48,0.19c0.17,0.06,0.34,0.11,0.5,0.19
						c0.16,0.08,0.31,0.06,0.5,0l0.12-0.04c0.23-0.07,0.46-0.14,0.7-0.18c0.07-0.01,0.11,0.02,0.13,0.04
						c0.06,0.06,0.05,0.15,0.03,0.24c-0.01,0.04-0.02,0.1-0.01,0.13c0.35,0.15,0.86,0.75,0.92,0.81c0.01,0.01,0.03,0.03,0.08,0.08
						c1.17,1.21,1.51,1.37,1.6,1.39c0-0.02,0-0.06-0.02-0.12c-0.07-0.14-1.19-1.99-1.24-2.07c-0.08-0.1-0.16-0.2-0.24-0.3
						l-0.23-0.28l0.26,0.04c0.11,0.02,0.21,0.08,0.31,0.14c0.03,0.02,0.06,0.04,0.09,0.05c0.36,0.2,0.75,0.36,1.17,0.47
						c0.26,0.07,0.51,0.17,0.76,0.27c0.11,0.04,0.21,0.09,0.32,0.13c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01-0.01
						c0.12-0.05,0.26,0.05,1.41,1.22c0.29,0.29,0.64,0.65,0.9,0.9c-0.18-0.3-0.45-0.79-0.89-1.58c-0.25-0.44-0.5-0.89-0.55-0.98
						c-0.57-0.37-2.15-0.99-2.8-1.18c-0.41-0.12-0.66-0.35-0.82-0.72c-0.07-0.16-0.1-0.29-0.13-0.38c-0.06-0.2-0.09-0.3-0.34-0.54
						c-0.01-0.01-0.04-0.04-0.07-0.07c-0.34-0.3-0.57-0.52-0.63-0.66l-0.1-0.24l0.41,0.19c0.1,0.05,0.2,0.09,0.29,0.14
						c0.28,0.13,0.58,0.32,0.67,0.76c0.04,0.2,0.15,0.37,0.35,0.54c-0.03-0.22-0.03-0.43-0.03-0.64l0-0.1c0-0.1,0-0.2,0.08-0.25
						c0.08-0.05,0.16-0.01,0.31,0.06c0.04,0.02,0.08,0.04,0.13,0.06c0.09,0.04,0.19,0.07,0.31,0.1c0.22,0.05,0.33,0.21,0.42,0.36
						c0.03,0.04,0.05,0.09,0.07,0.14c0.02,0.04,0.03,0.08,0.06,0.11l0.01,0.01c0.01,0.01,0.01,0.03,0.02,0.04c0,0,0,0,0.01,0
						c0.05-0.04,0.1-0.04,0.14-0.04c0.08,0.01,0.12,0.07,0.15,0.12l0.01,0.01c0.09,0.13,0.71,0.48,1.25,0.79
						c0.97,0.55,1.59,0.92,1.75,1.17c0.37,0.02,2.79-0.07,4.75-0.14c2.57-0.09,3.31-0.11,3.54-0.11c0.21-0.26,1.34-0.36,4.42-0.52
						c1.48-0.08,3.41-0.18,4.06-0.35c-0.94-0.07-3.99-0.29-7.2-0.52c-7.24-0.51-12.38-0.88-12.5-0.91l-0.04-0.01
						c-0.6-0.15-1.23-0.3-1.8-0.56l-0.12-0.05c-0.39-0.17-0.79-0.35-1.21-0.42c-0.22-0.04-0.43-0.11-0.61-0.21
						c-0.41-0.22-0.81-0.48-1.2-0.74c-0.13-0.09-0.27-0.18-0.4-0.26c-0.24-0.16-0.46-0.35-0.67-0.53c-0.2-0.18-0.41-0.36-0.63-0.5
						c-0.02-0.01-0.04-0.03-0.06-0.04c0,0.02-0.01,0.04-0.02,0.06l-0.03,0.07l-0.08-0.02c-0.09-0.02-0.17-0.06-0.25-0.09
						c-0.04-0.02-0.09-0.04-0.13-0.06c-0.2-0.07-0.23-0.23-0.24-0.37c-0.02-0.27-0.04-0.54-0.21-0.75c-0.18-0.23-0.25-0.5-0.21-0.8
						c0.01-0.05,0-0.11,0-0.17c0-0.05,0-0.09,0-0.14l0,0c0-0.06-0.02-0.12-0.06-0.18c-0.11-0.16-0.21-0.32-0.32-0.47
						c-0.15-0.23-0.3-0.46-0.46-0.68c-0.06-0.09-0.09-0.18-0.09-0.27c-0.01-0.2-0.02-0.46-0.04-0.72c-0.01-0.18-0.05-0.31-0.13-0.42
						l-0.05-0.07c-0.29-0.44-0.59-0.89-0.8-1.4l-0.04-0.11l0.12-0.03c0.11-0.03,0.18,0.04,0.21,0.08c0.16,0.17,0.34,0.34,0.51,0.5
						c0.32,0.31,0.66,0.63,0.94,1c0.1,0.13,0.09,0.24-0.02,0.32c-0.03,0.02-0.03,0.04-0.03,0.04c0,0,0.01,0.01,0.06,0.06
						c0.06,0.05,0.12,0.11,0.18,0.16c0.06,0.05,0.11,0.1,0.17,0.16c0.12,0.1,0.16,0.22,0.13,0.37c-0.03,0.13,0,0.27,0.08,0.44
						c0.02,0.05,0.05,0.1,0.07,0.15c0.08,0.16,0.16,0.33,0.2,0.52c0.04,0.17,0.01,0.34-0.02,0.5c-0.01,0.04-0.01,0.07-0.02,0.11
						c-0.03,0.22-0.06,0.43,0.07,0.63c0.04,0.06,0.07,0.12,0.1,0.18c0-0.24,0.06-0.49,0.25-0.72c0.02-0.02,0.03-0.05,0.05-0.09
						c0.01-0.03,0.03-0.06,0.05-0.09c0.03-0.05,0.03-0.08-0.03-0.14c-0.04-0.04-0.08-0.09-0.13-0.13c-0.06-0.07-0.13-0.13-0.19-0.19
						c-0.12-0.11-0.18-0.27-0.16-0.44c0.04-0.37-0.05-0.7-0.29-1.01c-0.04-0.05-0.07-0.11-0.1-0.17c-0.03-0.05-0.05-0.1-0.09-0.14
						c-0.16-0.2-0.12-0.42-0.07-0.58c0.01-0.04,0.03-0.09,0.04-0.13c0.03-0.09,0.06-0.19,0.09-0.28c0.05-0.14,0.1-0.27,0.16-0.41
						l0.13-0.35l0.11,0.14c0.07,0.09,0.06,0.17,0.05,0.22l0,0.01c-0.04,0.25,0.03,0.38,0.21,0.46c0.13,0.06,0.23,0.16,0.27,0.31
						c0.05,0.17,0.16,0.3,0.32,0.43c0.2,0.17,0.26,0.39,0.19,0.67c-0.01,0.04-0.01,0.07-0.02,0.11c-0.01,0.07-0.02,0.14-0.05,0.21
						c-0.03,0.07-0.02,0.1,0.05,0.18c0.28,0.28,0.37,0.59,0.27,0.95c-0.12,0.41-0.15,0.76-0.09,1.07c0.07-0.06,0.14-0.12,0.17-0.2
						c0.12-0.27,0.31-0.41,0.52-0.4c0.33,0.02,0.59-0.1,0.81-0.36c0.08-0.09,0.16-0.14,0.25-0.14c0.09-0.01,0.18,0.04,0.26,0.12
						c0.15,0.15,0.29,0.17,0.47,0.09c0.28-0.12,0.53-0.05,0.72,0.2c0.01,0.02,0.03,0.03,0.05,0.05c0.03,0.03,0.06,0.05,0.08,0.09
						c0.19,0.28,0.38,0.36,0.71,0.27c0.19-0.05,0.38-0.02,0.51,0.09c0.09,0.08,0.2,0.23,0.17,0.51c-0.01,0.09,0.02,0.09,0.05,0.1
						c0.57,0.12,0.74,0.63,0.86,1.01c0.04,0.12-0.02,0.2-0.06,0.26c-0.05,0.06-0.1,0.13-0.11,0.16c0.02,0.02,0.09,0.07,0.16,0.12
						c0.01,0.01,0.03,0.02,0.04,0.03c0.35,0.21,0.37,0.27,0.23,0.66c-0.03,0.09-0.03,0.11,0.04,0.16c0.09,0.05,0.18,0.29,0.46,1.03
						c0.11,0.3,0.25,0.68,0.3,0.73c0.11,0.08,0.99,0.01,1.64-0.05c1.28-0.11,1.83-0.14,1.93,0.05c0.07,0.01,0.26-0.01,0.44-0.02
						c0.33-0.03,0.77-0.06,1.16-0.01c0.19,0.02,0.7-0.09,0.96-0.33c0.09-0.08,0.13-0.17,0.12-0.26c0-0.07,0.02-0.16,0.16-0.24
						c0.43-0.25,1.87-0.31,3.53,0.52c-0.83-0.79-2.52-2.39-4.33-4.06c-4.02-3.69-4.96-4.35-5.17-4.44
						c-0.12,0.15-0.33,0.92-0.51,1.54c-0.17,0.59-0.31,1.11-0.41,1.25l-0.01,0.02c-0.08,0.12-0.15,0.23-0.16,0.37
						c-0.01,0.17-0.03,0.35-0.05,0.51l0,0.02c-0.02,0.18-0.05,0.35-0.07,0.53l-0.05,0.34l-0.2,0.02l-0.02-0.08
						c-0.03-0.09-0.06-0.18-0.08-0.27c-0.06-0.19-0.12-0.39-0.19-0.58c-0.19-0.48-0.23-0.88-0.12-1.27c0.01-0.04,0.02-0.1,0.02-0.15
						c0-0.04,0.01-0.09,0.02-0.14l0.01-0.03c0.05-0.28,0.11-0.57,0.32-0.81c0.07-0.07,0.13-0.15,0.2-0.23
						c0.03-0.03,0.05-0.06,0.08-0.09c-0.13,0.09-0.26,0.18-0.39,0.26c-0.26,0.16-0.41,0.36-0.47,0.62
						c-0.02,0.08-0.04,0.15-0.06,0.23l-0.04,0.15c-0.01,0.03-0.03,0.1-0.09,0.14c-0.04,0.02-0.09,0.02-0.14,0
						c-0.06-0.03-0.14-0.09-0.2-0.17c0.01,0.02,0.02,0.04,0.03,0.05c0.05,0.12,0.11,0.25,0.19,0.38c0.24,0.42,0.32,0.89,0.39,1.35
						l0.02,0.14l-0.1,0.02c-0.01,0-0.01,0-0.02,0c-0.02,0-0.04,0.01-0.07,0c-0.03-0.01-0.06-0.02-0.08-0.04
						c-0.25-0.18-0.53-0.23-0.87-0.14c-0.2,0.06-0.31-0.04-0.39-0.15c-0.03-0.04-0.05-0.08-0.08-0.12c-0.16-0.23-0.3-0.43-0.58-0.44
						c-0.04,0-0.08,0-0.11-0.01c-0.05,0-0.1-0.01-0.14-0.01c-0.12,0.01-0.26-0.03-0.27-0.25c-0.04-0.55-0.06-1.14,0.23-1.68l0,0
						c0.04-0.08,0.07-0.15,0.09-0.22c0.09-0.36,0.14-0.73,0.17-1.05c0-0.01,0-0.01,0-0.02c0-0.05,0-0.17,0.13-0.19
						c0.05-0.01,0.14-0.01,0.18,0.13c0,0.01,0,0.01,0.01,0.02c0.12,0.23,0.27,0.49,0.47,0.71c0.38,0.43,0.54,0.88,0.47,1.39
						c0.08-0.04,0.15-0.06,0.21-0.04c0.03,0.01,0.06,0.03,0.08,0.05c-0.08-0.12-0.13-0.25-0.12-0.35c0.02-0.2-0.03-0.38-0.15-0.54
						c-0.04-0.06-0.09-0.12-0.13-0.19c-0.04-0.06-0.09-0.13-0.13-0.19c-0.04-0.06-0.09-0.12-0.08-0.22l0.01-0.05
						c0.04-0.34,0.08-0.69,0.27-1.01c0.12-0.21,0.25-0.42,0.36-0.63c-0.03,0.03-0.07,0.05-0.11,0.08c-0.25,0.15-0.4,0.34-0.46,0.58
						c-0.01,0.05-0.04,0.15-0.17,0.17l-0.1,0.02l-0.01-0.1c-0.04-0.27,0.05-0.5,0.13-0.72c-0.16,0.17-0.28,0.35-0.31,0.6l0,0.01
						c0,0.05-0.02,0.18-0.15,0.19c-0.12,0.01-0.16-0.1-0.17-0.14c0-0.01-0.01-0.02-0.01-0.02c-0.03-0.06-0.06-0.12-0.09-0.19
						c-0.05-0.12-0.11-0.24-0.17-0.36c-0.03-0.05-0.05-0.1-0.08-0.15c-0.14-0.26-0.27-0.51-0.5-0.67c0,0-0.01-0.01-0.01-0.01
						c0,0.01,0,0.01,0,0.02c-0.03,0.34-0.14,0.67-0.34,1.08l-0.13,0.25l-0.06-0.28c-0.04-0.17-0.05-0.34-0.07-0.5
						c-0.03-0.34-0.11-0.56-0.29-0.72c-0.11-0.11-0.22-0.22-0.34-0.34c-0.07-0.07-0.14-0.15-0.22-0.22
						c-0.07-0.07-0.14-0.16-0.12-0.29c0.03-0.32-0.07-0.6-0.19-0.9c-0.03-0.07-0.05-0.14-0.08-0.21c-0.09,0.06-0.18,0.13-0.27,0.19
						l-0.14,0.1c-0.2,0.13-0.2,0.13-0.13,0.36c0.13,0.42,0.06,0.83-0.21,1.26c-0.05,0.09-0.06,0.14-0.02,0.23
						c0.16,0.31,0.15,0.63,0.14,0.94c-0.01,0.17-0.01,0.34,0.01,0.51l0.02,0.18l-0.16-0.07c-0.12-0.05-0.14-0.15-0.14-0.19
						c0-0.01,0-0.01,0-0.02c-0.02-0.09-0.04-0.18-0.05-0.26c-0.03-0.18-0.06-0.35-0.15-0.49c-0.18-0.27-0.27-0.59-0.27-0.99
						c0-0.12-0.04-0.21-0.13-0.31c-0.22-0.24-0.43-0.49-0.5-0.85c-0.1-0.51-0.29-0.84-0.6-1.05c-0.02-0.01-0.04-0.03-0.06-0.05
						c0.15,0.18,0.23,0.41,0.23,0.68c0,0.34-0.05,0.68-0.09,1.01c-0.02,0.14-0.04,0.28-0.05,0.42c-0.01,0.07-0.03,0.13-0.08,0.16
						c-0.02,0.02-0.06,0.04-0.11,0.04c0.04,0.06,0.08,0.12,0.12,0.17c0.11,0.16,0.22,0.32,0.31,0.49c0.13,0.23,0.27,0.58,0.05,0.96
						l-0.08,0.13l-0.09-0.12c-0.15-0.2-0.28-0.43-0.39-0.64c-0.07-0.12-0.14-0.25-0.21-0.36c-0.1-0.16-0.17-0.34-0.22-0.54
						l-0.01-0.04c-0.05-0.21-0.1-0.41-0.22-0.57c-0.31-0.41-0.65-0.81-0.98-1.2c-0.11-0.14-0.23-0.27-0.34-0.41
						c-0.24-0.29-0.53-0.51-0.87-0.68l-0.14-0.07c-0.32-0.16-0.66-0.32-1-0.44c-0.2,0.02-0.35-0.08-0.47-0.18
						c-0.12-0.1-0.23-0.2-0.35-0.3c-0.35-0.31-0.72-0.63-1.1-0.91c-0.13-0.1-0.26-0.17-0.39-0.22c0,0.03,0,0.05,0.01,0.08
						c0.01,0.07,0.02,0.15,0.02,0.22c0.01,0.14,0.01,0.27,0.1,0.37c0.08,0.09,0.15,0.19,0.22,0.28c0.02,0.03,0.04,0.06,0.06,0.09
						l0.03,0.04c0.08,0.11,0.17,0.23,0.2,0.4c0.07,0.52,0.16,0.98,0.57,1.29c0.03,0.02,0.04,0.04,0.05,0.06c0,0,0.01,0.01,0.01,0.01
						c0.2,0.24,0.28,0.53,0.36,0.81c0.03,0.1,0.05,0.2,0.08,0.29c0.02,0.07,0.04,0.14,0.06,0.2c0.04,0.17,0.08,0.34,0.22,0.46
						c0.12,0.11,0.21,0.25,0.27,0.41c0.02,0.04,0.05,0.08,0.08,0.13c0.06,0.08,0.13,0.19,0.15,0.35l0.02,0.19l-0.17-0.09
						c-0.25-0.13-0.46-0.3-0.65-0.48c0.08,0.1,0.15,0.19,0.22,0.29c0.02,0.04,0.05,0.07,0.07,0.11c0.05,0.09,0.1,0.18,0.17,0.22
						c0.24,0.15,0.44,0.34,0.64,0.52c0.19,0.17,0.36,0.34,0.58,0.48c0.68,0.44,1.17,0.88,1.6,1.43c0.05,0.06,0.09,0.12,0.14,0.18
						c0.27,0.35,0.55,0.72,0.9,1.01c0.03,0.03,0.07,0.06,0.11,0.08c0.12,0.08,0.25,0.18,0.32,0.34c0.08,0.2,0.16,0.39,0.24,0.59
						c0.08,0.2,0.16,0.39,0.24,0.59c0,0,0,0.01,0.01,0.01c0.02,0.04,0.07,0.13-0.01,0.22l-0.05,0.05l-0.06-0.02
						c-0.07-0.03-0.11-0.08-0.13-0.12c-0.01-0.01-0.01-0.02-0.02-0.03c-0.09-0.1-0.17-0.21-0.25-0.31c-0.2-0.24-0.4-0.49-0.61-0.71
						c-0.44-0.45-0.91-0.87-1.37-1.29l-0.08-0.07c-0.06-0.06-0.13-0.12-0.19-0.18c-0.18-0.18-0.35-0.34-0.56-0.45
						c-0.35-0.17-0.71-0.31-1.06-0.46l-0.15-0.06c-0.03-0.01-0.06-0.02-0.1-0.04c-0.11-0.04-0.23-0.08-0.32-0.18
						c-0.24-0.27-0.49-0.55-0.72-0.82c-0.22-0.25-0.44-0.5-0.66-0.75c-0.07-0.08-0.14-0.16-0.21-0.24
						c-0.19-0.22-0.38-0.45-0.61-0.63c-0.35-0.28-0.71-0.65-0.76-1.24c-0.01-0.14-0.08-0.25-0.2-0.33c-0.17-0.1-0.32-0.22-0.47-0.34
						l-0.15-0.11c-0.16-0.12-0.35-0.26-0.39-0.53c-0.01-0.07-0.03-0.13-0.05-0.2c-0.02-0.07-0.04-0.14-0.05-0.21
						c-0.1-0.62-0.36-1.08-0.78-1.41c-0.12-0.1-0.2-0.22-0.24-0.39c-0.04-0.18-0.09-0.36-0.14-0.53c-0.03-0.12-0.07-0.24-0.1-0.36
						c-0.02-0.07-0.05-0.12-0.1-0.17c-0.1-0.09-0.2-0.18-0.3-0.27c-0.27-0.24-0.55-0.48-0.81-0.74c-0.06-0.06-0.12-0.11-0.18-0.16
						c-0.12-0.1-0.24-0.2-0.34-0.33c-0.05-0.01-0.1-0.03-0.15-0.04c-0.14-0.04-0.29-0.07-0.43-0.12c-0.2-0.06-0.34-0.22-0.46-0.38
						c-0.15-0.21-0.37-0.33-0.7-0.38c-0.19-0.03-0.3-0.12-0.37-0.33c-0.27-0.77-0.63-1.39-1.1-1.87c-0.09-0.09-0.18-0.17-0.3-0.24
						c0.14,0.15,0.22,0.33,0.31,0.5l0.04,0.07c0.03,0.05,0.04,0.11,0.06,0.16c0.03,0.1,0.06,0.19,0.13,0.22
						c0.23,0.11,0.29,0.31,0.34,0.48c0.03,0.09,0.06,0.18,0.11,0.25l0.18,0.24l-0.29-0.09c-0.02-0.01-0.05-0.01-0.08-0.01
						c-0.07-0.01-0.17-0.02-0.22-0.1c-0.03-0.05-0.04-0.1-0.04-0.18l-0.06-0.07c-0.26-0.32-0.51-0.61-0.86-0.78l0.09-0.18
						c0.22,0.1,0.4,0.25,0.56,0.41c-0.02-0.05-0.04-0.09-0.05-0.14c-0.02-0.05-0.03-0.09-0.05-0.13l-0.04-0.08
						c-0.09-0.18-0.17-0.34-0.3-0.47c-0.06-0.06-0.12-0.17-0.07-0.34l0.04-0.11l0.1,0.05c0.21,0.11,0.39,0.24,0.53,0.39
						c0.49,0.51,0.87,1.14,1.15,1.95c0.05,0.14,0.1,0.18,0.21,0.2c0.24,0.04,0.59,0.12,0.83,0.46c0.09,0.13,0.2,0.26,0.36,0.31
						c0.14,0.04,0.28,0.08,0.42,0.12c0.06,0.02,0.13,0.03,0.19,0.05l0.04,0.01l0.02,0.03c0.09,0.13,0.2,0.22,0.32,0.32
						c0.06,0.05,0.13,0.11,0.2,0.17c0.26,0.25,0.54,0.5,0.81,0.73c0.1,0.09,0.2,0.18,0.31,0.27c0.08,0.07,0.13,0.16,0.16,0.27
						c0.03,0.12,0.06,0.24,0.1,0.36c0.05,0.18,0.1,0.36,0.14,0.54c0.03,0.12,0.08,0.21,0.17,0.28c0.46,0.36,0.74,0.86,0.85,1.54
						c0.01,0.06,0.03,0.12,0.05,0.19c0.02,0.07,0.04,0.15,0.05,0.23c0.03,0.19,0.16,0.29,0.31,0.4l0.15,0.11
						c0.15,0.11,0.3,0.23,0.46,0.33c0.17,0.11,0.28,0.27,0.29,0.48c0.05,0.51,0.36,0.83,0.69,1.11c0.24,0.2,0.44,0.43,0.63,0.66
						c0.07,0.08,0.14,0.16,0.21,0.24c0.22,0.25,0.44,0.5,0.66,0.75c0.23,0.27,0.48,0.55,0.72,0.82c0.06,0.06,0.14,0.09,0.24,0.13
						c0.04,0.01,0.07,0.03,0.11,0.04l0.15,0.06c0.35,0.14,0.72,0.29,1.07,0.46c0.24,0.12,0.43,0.3,0.61,0.48
						c0.06,0.06,0.12,0.12,0.18,0.18l0.08,0.07c0.46,0.42,0.93,0.85,1.37,1.3c0.22,0.23,0.43,0.48,0.63,0.73
						c0.01,0.02,0.03,0.04,0.04,0.06c-0.03-0.08-0.07-0.17-0.1-0.25c-0.08-0.2-0.16-0.39-0.24-0.59c-0.05-0.11-0.14-0.18-0.25-0.26
						c-0.04-0.03-0.08-0.06-0.12-0.09c-0.36-0.3-0.65-0.68-0.93-1.04c-0.04-0.06-0.09-0.12-0.13-0.17
						c-0.41-0.53-0.89-0.96-1.55-1.38c-0.23-0.15-0.42-0.33-0.61-0.5c-0.19-0.18-0.38-0.35-0.61-0.49
						c-0.12-0.07-0.18-0.19-0.24-0.29c-0.02-0.03-0.04-0.07-0.06-0.1c-0.1-0.16-0.23-0.31-0.37-0.47c-0.16-0.19-0.33-0.39-0.45-0.6
						l-0.24-0.4l0.38,0.27c0.2,0.14,0.38,0.31,0.56,0.47c0.17,0.16,0.34,0.31,0.53,0.45c-0.03-0.05-0.07-0.1-0.09-0.16
						c-0.05-0.13-0.12-0.25-0.21-0.33c-0.18-0.16-0.23-0.37-0.27-0.56c-0.02-0.07-0.03-0.13-0.05-0.19c-0.03-0.1-0.06-0.2-0.09-0.3
						c-0.07-0.27-0.14-0.53-0.32-0.74c-0.01-0.01-0.02-0.02-0.03-0.04c0,0,0-0.01-0.01-0.01c-0.46-0.34-0.56-0.86-0.64-1.41
						c-0.02-0.12-0.08-0.21-0.16-0.31l-0.03-0.04c-0.02-0.03-0.04-0.06-0.07-0.09c-0.07-0.09-0.13-0.18-0.21-0.27
						c-0.14-0.15-0.14-0.34-0.15-0.49c0-0.07-0.01-0.13-0.02-0.19c-0.01-0.06-0.01-0.12-0.02-0.18c0-0.02,0-0.05,0-0.07l-0.01-0.14
						l0.14,0.04c0.2,0.06,0.4,0.16,0.59,0.3c0.39,0.29,0.76,0.61,1.11,0.92c0.11,0.1,0.23,0.2,0.34,0.3
						c0.13,0.11,0.22,0.15,0.34,0.13l0.03,0l0.02,0.01c0.36,0.13,0.7,0.3,1.04,0.46l0.14,0.07c0.37,0.18,0.68,0.42,0.94,0.73
						c0.11,0.14,0.23,0.27,0.34,0.41c0.33,0.39,0.66,0.79,0.98,1.21c0.15,0.2,0.21,0.43,0.26,0.65l0.01,0.04
						c0.04,0.18,0.11,0.33,0.2,0.48c0.08,0.12,0.15,0.25,0.21,0.37c0.09,0.16,0.18,0.32,0.28,0.48c0.05-0.18,0.02-0.37-0.12-0.61
						c-0.09-0.16-0.2-0.32-0.3-0.47c-0.04-0.06-0.08-0.12-0.12-0.18c-0.01-0.01-0.01-0.02-0.02-0.02c-0.03-0.04-0.08-0.1-0.06-0.19
						c0.02-0.05,0.06-0.14,0.21-0.1c0.01,0,0.01,0,0.02,0c0,0,0-0.01,0-0.02c0.02-0.14,0.04-0.29,0.06-0.43
						c0.04-0.32,0.09-0.66,0.09-0.98c0-0.37-0.18-0.65-0.5-0.79c-0.1-0.05-0.2-0.1-0.3-0.15c-0.04-0.02-0.07-0.04-0.11-0.06
						c-0.15-0.08-0.2-0.19-0.15-0.35c0.02-0.08,0.03-0.16,0.04-0.25c0-0.04,0.01-0.08,0.01-0.12l0.01-0.1l0.12,0.01
						c0.05,0,0.12,0.01,0.18,0.08l0.01,0.02l0.01,0.02c0.06,0.24,0.21,0.43,0.47,0.61c0.06,0.04,0.11,0.09,0.16,0.13
						c0.06,0.05,0.12,0.1,0.19,0.15c0.47,0.31,0.62,0.82,0.69,1.18c0.06,0.32,0.25,0.54,0.45,0.75c0.12,0.13,0.18,0.27,0.18,0.45
						c0,0.36,0.08,0.64,0.23,0.88c0.05,0.08,0.09,0.17,0.12,0.25c0-0.23-0.01-0.44-0.12-0.65c-0.08-0.14-0.07-0.28,0.02-0.42
						c0.24-0.38,0.3-0.73,0.19-1.1c-0.1-0.34-0.07-0.4,0.2-0.59l0.14-0.1c0.09-0.06,0.18-0.12,0.27-0.19
						c0.02-0.02,0.1-0.07,0.18-0.04c0.08,0.03,0.11,0.11,0.12,0.14c0.02,0.07,0.05,0.14,0.08,0.21c0.12,0.31,0.24,0.63,0.2,0.99
						c0,0.04,0.01,0.07,0.07,0.13c0.07,0.07,0.14,0.15,0.22,0.22c0.11,0.11,0.22,0.22,0.33,0.33c0.26,0.26,0.32,0.59,0.35,0.85
						c0,0.05,0.01,0.1,0.01,0.15c0.1-0.25,0.16-0.48,0.19-0.7c0-0.01,0-0.02,0-0.03c0-0.04-0.01-0.16,0.11-0.2
						c0.09-0.03,0.16,0.03,0.19,0.05c0.01,0.01,0.01,0.01,0.02,0.02c0.28,0.19,0.42,0.47,0.57,0.74c0.03,0.05,0.05,0.1,0.08,0.15
						c0.07,0.12,0.12,0.25,0.18,0.37c0.02,0.04,0.04,0.08,0.06,0.13c0.07-0.36,0.31-0.6,0.54-0.83c0.04-0.04,0.07-0.07,0.11-0.11
						l0.23-0.23l-0.06,0.32c-0.03,0.14-0.08,0.28-0.13,0.41c-0.02,0.05-0.04,0.1-0.05,0.14c0.09-0.13,0.22-0.24,0.37-0.33
						c0.04-0.03,0.08-0.06,0.13-0.1c0.09-0.08,0.19-0.17,0.34-0.19l0.2-0.02l-0.1,0.17c-0.06,0.1-0.11,0.2-0.17,0.3
						c-0.12,0.22-0.25,0.45-0.39,0.67c-0.17,0.28-0.2,0.6-0.24,0.93l-0.01,0.05c0,0.02,0.01,0.03,0.04,0.07l0.01,0.01
						c0.05,0.06,0.09,0.13,0.13,0.19c0.04,0.06,0.08,0.12,0.13,0.18c0.15,0.2,0.21,0.43,0.19,0.67c-0.01,0.11,0.13,0.32,0.23,0.39
						l0.03-0.13c0.02-0.08,0.04-0.15,0.06-0.23c0.08-0.3,0.26-0.54,0.56-0.74c0.22-0.14,0.42-0.28,0.64-0.44
						c0.09-0.07,0.19-0.13,0.28-0.2l0.11-0.08l0.04,0.13c0.04,0.13-0.03,0.2-0.07,0.23l-0.01,0.01c-0.15,0.15-0.29,0.31-0.43,0.47
						c-0.07,0.08-0.13,0.16-0.2,0.23c-0.18,0.2-0.23,0.45-0.28,0.72l-0.01,0.03c-0.01,0.04-0.01,0.08-0.01,0.12
						c-0.01,0.06-0.01,0.12-0.03,0.19c-0.1,0.35-0.06,0.7,0.11,1.15c0.07,0.18,0.13,0.36,0.18,0.54c0.02-0.15,0.04-0.31,0.06-0.46
						l0-0.02c0.02-0.16,0.04-0.33,0.05-0.5c0.01-0.19,0.1-0.33,0.19-0.47l0.01-0.02c0.08-0.12,0.23-0.67,0.38-1.2
						c0.24-0.84,0.41-1.41,0.54-1.6c0.01-0.04,0.04-0.08,0.09-0.09c0.12-0.04,0.37-0.11,5.34,4.46c2.21,2.03,4.89,4.57,4.94,4.65
						l0.04,0.07l-0.11,0.11l-0.07-0.04c-1.78-1.11-3.52-1.17-3.99-0.89c-0.05,0.03-0.07,0.06-0.07,0.06
						c0.01,0.15-0.06,0.29-0.19,0.42c-0.29,0.27-0.87,0.41-1.12,0.38c-0.37-0.04-0.8-0.01-1.12,0.02c-0.41,0.03-0.59,0.05-0.63-0.08
						c-0.15-0.09-1.11-0.01-1.74,0.04c-0.34,0.03-0.63,0.05-0.87,0.07c2.27,0.17,7.09,0.51,11.17,0.8c4.6,0.33,7.42,0.53,7.5,0.54
						l0.08,0.01l0,0.09c0,0.33-1.01,0.44-4.46,0.62c-1.72,0.09-4.07,0.22-4.27,0.44l0,0.17l-0.13-0.09
						c-0.21-0.01-1.92,0.05-3.56,0.11c-1.15,0.04-2.34,0.08-3.26,0.11c-1.63,0.05-1.63,0.05-1.66-0.05
						c-0.06-0.19-1.04-0.75-1.69-1.12c-0.74-0.42-1.2-0.69-1.31-0.84l-0.01-0.01c-0.01-0.01-0.01-0.02-0.02-0.03
						c-0.05,0.04-0.1,0.05-0.15,0.04c-0.08-0.02-0.12-0.09-0.15-0.14c-0.03-0.05-0.05-0.1-0.07-0.14c-0.02-0.04-0.04-0.08-0.06-0.12
						c-0.09-0.15-0.16-0.24-0.29-0.27c-0.14-0.03-0.25-0.07-0.35-0.11c-0.05-0.02-0.09-0.04-0.13-0.06
						c-0.04-0.02-0.07-0.04-0.1-0.05c0,0.02,0,0.03,0,0.05l0,0.1c0,0.24,0,0.46,0.04,0.68c0,0.01,0,0.01,0.01,0.02
						c0.01,0.03,0.04,0.14-0.05,0.2c-0.07,0.05-0.15,0.03-0.22-0.05c0-0.01-0.01-0.01-0.01-0.01c-0.11-0.09-0.2-0.18-0.27-0.27
						c0.02,0.07,0.05,0.16,0.09,0.26c0.13,0.32,0.34,0.5,0.69,0.61c0.67,0.2,2.31,0.84,2.87,1.22c0.03,0.02,0.03,0.02,0.6,1.04
						c0.37,0.67,0.92,1.65,1.08,1.9l0.07-0.03l0,0.16c0,0.05-0.03,0.1-0.07,0.11C690.56,1843.09,690.54,1843.1,690.52,1843.1z
						 M684.47,1836.78c0.11,0.1,0.21,0.2,0.27,0.25c0.03,0.03,0.06,0.05,0.08,0.07c0.09,0.08,0.16,0.15,0.21,0.22
						c-0.07-0.23-0.23-0.38-0.53-0.52C684.48,1836.79,684.47,1836.78,684.47,1836.78z M679.88,1828.3
						c-0.02,0.06-0.05,0.13-0.07,0.19c-0.03,0.09-0.06,0.18-0.09,0.27c-0.01,0.04-0.03,0.09-0.04,0.13
						c-0.06,0.18-0.05,0.29,0.03,0.4c0.04,0.06,0.07,0.12,0.11,0.17c0.03,0.05,0.05,0.1,0.08,0.14c0.27,0.35,0.38,0.74,0.33,1.15
						c-0.01,0.1,0.03,0.2,0.1,0.27c0.07,0.06,0.14,0.13,0.2,0.2c0.04,0.04,0.08,0.08,0.12,0.12c0.15,0.15,0.12,0.29,0.05,0.39
						c-0.01,0.02-0.03,0.05-0.04,0.07c-0.02,0.04-0.04,0.09-0.08,0.13c-0.19,0.23-0.24,0.51-0.17,0.93
						c0.03,0.14,0.04,0.26,0.05,0.38c0.02,0.26,0.07,0.45,0.29,0.59c0.23,0.15,0.45,0.34,0.65,0.52c0.21,0.18,0.42,0.36,0.65,0.51
						c0.13,0.09,0.27,0.17,0.4,0.26c0.38,0.25,0.77,0.51,1.18,0.73c0.17,0.09,0.36,0.15,0.55,0.19c0.44,0.08,0.85,0.26,1.25,0.44
						l0.12,0.05c0.56,0.25,1.18,0.4,1.77,0.55l0.04,0.01c0.03,0,0.16,0.02,0.38,0.03c0,0-0.01-0.01-0.01-0.01
						c-0.06-0.06-0.14-0.28-0.34-0.8c-0.13-0.34-0.32-0.85-0.38-0.93c-0.19-0.12-0.16-0.27-0.12-0.39c0.05-0.12,0.09-0.23,0.07-0.26
						c-0.01-0.03-0.1-0.09-0.21-0.15c-0.02-0.01-0.03-0.02-0.05-0.03c-0.15-0.1-0.23-0.16-0.25-0.25c-0.02-0.1,0.05-0.17,0.16-0.31
						c0.04-0.05,0.03-0.06,0.03-0.08c-0.14-0.44-0.28-0.79-0.71-0.87c-0.15-0.03-0.23-0.15-0.21-0.31c0.02-0.15-0.02-0.27-0.1-0.34
						c-0.08-0.07-0.2-0.08-0.33-0.05c-0.41,0.11-0.69,0-0.93-0.35c-0.01-0.02-0.03-0.04-0.05-0.05c-0.02-0.02-0.05-0.04-0.07-0.07
						c-0.13-0.18-0.29-0.22-0.48-0.14c-0.26,0.11-0.48,0.07-0.69-0.14c-0.03-0.03-0.07-0.06-0.11-0.06c-0.04,0-0.08,0.04-0.11,0.07
						c-0.25,0.31-0.58,0.45-0.97,0.43c-0.16-0.01-0.27,0.15-0.33,0.28c-0.06,0.12-0.15,0.21-0.23,0.28c0,0,0,0.01-0.01,0.01
						c-0.05,0.09-0.12,0.13-0.21,0.09c-0.1-0.04-0.1-0.14-0.1-0.17c0-0.01,0-0.01,0-0.02c-0.07-0.36-0.05-0.74,0.09-1.2
						c0.08-0.29,0.01-0.53-0.21-0.76c-0.1-0.1-0.18-0.21-0.1-0.4c0.02-0.04,0.03-0.1,0.04-0.16c0.01-0.04,0.01-0.09,0.02-0.13
						c0.05-0.2,0.01-0.34-0.13-0.47c-0.16-0.14-0.31-0.3-0.38-0.53c-0.02-0.08-0.08-0.14-0.15-0.18
						C680.03,1828.6,679.92,1828.47,679.88,1828.3z M687.02,1835.38C687.02,1835.38,687.02,1835.38,687.02,1835.38L687.02,1835.38z
						 M679.63,1831.7c0,0.04,0,0.08,0,0.12c0,0.07,0,0.13,0,0.2c-0.03,0.24,0.03,0.47,0.17,0.66c0.2,0.26,0.23,0.55,0.25,0.86
						c0.01,0.13,0.04,0.17,0.11,0.19c0.03,0.01,0.05,0.02,0.07,0.03c-0.1-0.15-0.15-0.34-0.13-0.56c0.02-0.29-0.03-0.53-0.18-0.75
						c-0.17-0.26-0.13-0.53-0.1-0.77c0.01-0.04,0.01-0.08,0.02-0.11c0.02-0.15,0.05-0.28,0.02-0.42c-0.03-0.17-0.11-0.32-0.19-0.47
						c-0.02-0.05-0.05-0.1-0.07-0.15c-0.1-0.21-0.13-0.38-0.09-0.56c0.02-0.08,0-0.13-0.06-0.18c-0.06-0.05-0.12-0.11-0.17-0.16
						c-0.06-0.05-0.11-0.11-0.17-0.16c-0.06-0.06-0.13-0.13-0.12-0.22c0-0.07,0.04-0.13,0.11-0.18c0,0,0.01,0,0.01-0.01
						c0-0.01-0.01-0.02-0.03-0.04c-0.28-0.35-0.6-0.67-0.92-0.98c-0.09-0.09-0.19-0.18-0.29-0.28c0.18,0.35,0.39,0.67,0.6,0.98
						l0.05,0.07c0.09,0.14,0.14,0.3,0.16,0.52c0.02,0.26,0.03,0.53,0.04,0.73c0,0.06,0.02,0.11,0.06,0.17
						c0.15,0.23,0.31,0.46,0.46,0.69c0.1,0.16,0.21,0.32,0.31,0.47C679.61,1831.5,679.64,1831.59,679.63,1831.7L679.63,1831.7z
						 M667.03,1833.19L667.03,1833.19L667.03,1833.19z M685.51,1831.85c0.21,0,0.4,0.05,0.58,0.16c-0.07-0.41-0.15-0.8-0.35-1.16
						c-0.08-0.14-0.15-0.28-0.2-0.4c-0.03-0.07-0.04-0.08-0.05-0.08c0,0-0.02,0-0.05,0.02c-0.11,0.07-0.18,0.05-0.22,0.02
						c-0.06-0.04-0.08-0.11-0.07-0.22c0.06-0.45-0.07-0.86-0.42-1.24c-0.19-0.21-0.33-0.44-0.45-0.66c-0.03,0.3-0.08,0.64-0.16,0.98
						c-0.02,0.08-0.05,0.17-0.1,0.26c-0.26,0.5-0.25,1.05-0.21,1.58c0.01,0.07,0.01,0.07,0.06,0.07c0.06,0,0.12,0,0.17,0.01
						c0.03,0,0.07,0.01,0.1,0.01c0.38,0.01,0.57,0.28,0.74,0.52c0.03,0.04,0.05,0.08,0.08,0.11c0.07,0.09,0.1,0.1,0.17,0.08
						C685.27,1831.87,685.4,1831.85,685.51,1831.85z M652.12,1825.13L652.12,1825.13L652.12,1825.13z M678.16,1822.91
						c-0.01,0.07-0.02,0.14-0.04,0.21c-0.02,0.07-0.01,0.09,0.05,0.12c0.04,0.02,0.07,0.04,0.11,0.06c0.1,0.05,0.19,0.1,0.28,0.15
						c0.02,0.01,0.03,0.01,0.05,0.02C678.46,1823.35,678.27,1823.18,678.16,1822.91z M657.2,1820.59L657.2,1820.59L657.2,1820.59z
						 M656.75,1816.97L656.75,1816.97L656.75,1816.97z M664.08,1816.44c0.06,0.07,0.12,0.15,0.19,0.22l0,0
						C664.24,1816.57,664.19,1816.49,664.08,1816.44z M660.35,1813.81c0.03,0.03,0.05,0.06,0.07,0.08c0.02-0.02,0.04-0.04,0.06-0.07
						C660.43,1813.82,660.39,1813.82,660.35,1813.81z M668.73,1832.55l-0.32-0.25c-0.15-0.12-0.33-0.22-0.5-0.31
						c-0.14-0.08-0.29-0.16-0.43-0.25c-0.19-0.13-0.37-0.28-0.54-0.43c-0.23-0.19-0.47-0.39-0.72-0.55
						c-0.04-0.02-0.12-0.09-0.1-0.18c0.01-0.04,0.04-0.09,0.11-0.1l0,0c0.11-0.02,0.13-0.07,0.15-0.22
						c0.01-0.04,0.01-0.08,0.02-0.12c0-0.01,0-0.02,0-0.03c0-0.04,0.01-0.14,0.11-0.17c0.13-0.05,0.19,0.06,0.21,0.1
						c0,0.01,0.01,0.02,0.01,0.02c0.03,0.03,0.05,0.07,0.08,0.1c0.16,0.2,0.31,0.39,0.54,0.48c0.19,0.08,0.31,0.22,0.35,0.43
						c0.05,0.25,0.19,0.42,0.43,0.55c0.17,0.09,0.31,0.27,0.43,0.55L668.73,1832.55z M666.39,1830.64c0.24,0.15,0.46,0.34,0.68,0.52
						c0.17,0.14,0.35,0.29,0.53,0.42c0.13,0.09,0.27,0.16,0.41,0.24c0.05,0.03,0.1,0.05,0.15,0.08c-0.04-0.04-0.07-0.06-0.11-0.08
						c-0.3-0.16-0.46-0.38-0.53-0.69c-0.03-0.15-0.1-0.24-0.23-0.29c-0.27-0.11-0.45-0.33-0.62-0.54c-0.02-0.03-0.05-0.06-0.07-0.09
						c-0.01,0.03-0.01,0.06-0.02,0.1C666.55,1830.41,666.52,1830.56,666.39,1830.64z M672.84,1830.38c-0.11,0-0.16-0.05-0.19-0.07
						l-0.01-0.01c-0.11-0.09-0.22-0.18-0.33-0.27c-0.24-0.2-0.5-0.41-0.76-0.6c-0.08-0.06-0.15-0.12-0.22-0.18
						c-0.17-0.15-0.32-0.28-0.53-0.33c-0.24-0.05-0.47-0.12-0.7-0.18l-0.08-0.02c-0.22-0.06-0.48-0.15-0.68-0.38
						c-0.1-0.11-0.21-0.21-0.32-0.31c-0.05-0.04-0.09-0.08-0.14-0.13l0,0c-0.22-0.21-0.47-0.45-0.77-0.6
						c-0.15-0.08-0.29-0.16-0.43-0.25c-0.06-0.04-0.12-0.08-0.18-0.11l-0.08-0.05l0.04-0.08c0.04-0.08,0.1-0.11,0.19-0.1
						c0.03,0,0.06,0.01,0.09,0.01c0.12,0.02,0.26,0.03,0.38,0.08l-0.13-0.13l0.24,0c0.16-0.01,0.27,0.06,0.36,0.12
						c0.02,0.01,0.04,0.02,0.06,0.03c0.28,0.15,0.52,0.37,0.75,0.59c0.09,0.08,0.18,0.17,0.27,0.25c0.07,0.06,0.13,0.12,0.19,0.17
						c0.14,0.13,0.28,0.26,0.43,0.35c0.2,0.11,0.42,0.2,0.63,0.29c0.13,0.05,0.26,0.11,0.39,0.17c0.07,0.03,0.14,0.06,0.21,0.09
						c0.36,0.15,0.73,0.31,0.93,0.75c0.08,0.17,0.19,0.32,0.3,0.49c0.06,0.08,0.11,0.16,0.17,0.25l0.1,0.15l-0.18,0
						C672.85,1830.38,672.84,1830.38,672.84,1830.38z M669.03,1827.76c0.04,0.04,0.09,0.08,0.14,0.12c0.11,0.1,0.22,0.21,0.33,0.32
						c0.17,0.18,0.39,0.27,0.59,0.32l0.08,0.02c0.23,0.06,0.46,0.13,0.69,0.18c0.26,0.06,0.44,0.22,0.62,0.37
						c0.07,0.06,0.13,0.12,0.2,0.17c0.26,0.19,0.52,0.4,0.76,0.61c0.01,0.01,0.02,0.01,0.02,0.02c-0.07-0.1-0.13-0.21-0.18-0.32
						c-0.17-0.37-0.49-0.51-0.83-0.65c-0.07-0.03-0.14-0.06-0.21-0.09c-0.13-0.06-0.26-0.11-0.39-0.16
						c-0.22-0.09-0.44-0.18-0.65-0.3c-0.17-0.1-0.32-0.24-0.47-0.37c-0.06-0.06-0.12-0.11-0.19-0.17c-0.1-0.08-0.19-0.17-0.28-0.25
						c-0.22-0.21-0.43-0.4-0.68-0.54c0.02,0.06,0.01,0.11-0.03,0.16c-0.03,0.03-0.07,0.05-0.11,0.05c0,0,0,0,0,0
						c-0.08,0-0.14-0.06-0.16-0.08c-0.1-0.12-0.23-0.17-0.37-0.19c0.1,0.06,0.2,0.12,0.3,0.17
						C668.53,1827.3,668.8,1827.55,669.03,1827.76L669.03,1827.76z M678.24,1830.2c-0.07,0-0.12-0.06-0.13-0.08
						c-0.11-0.15-0.22-0.31-0.33-0.46c-0.08-0.11-0.15-0.22-0.23-0.33c-0.27-0.39-0.54-0.79-0.86-1.15c-0.29-0.33-0.65-0.6-1-0.86
						c-0.15-0.12-0.2-0.26-0.15-0.44c0.1-0.34,0.02-0.62-0.24-0.84c-0.14-0.12-0.14-0.27-0.14-0.38c0-0.03,0-0.06,0-0.09
						c0-0.09,0.01-0.17-0.04-0.23c-0.26-0.37-0.52-0.75-0.76-1.12l-0.1-0.15c0,0-0.01-0.01-0.01-0.02
						c-0.11-0.11-0.06-0.19-0.02-0.23c0.04-0.04,0.08-0.05,0.11-0.05c0,0,0,0,0,0c0.07,0,0.11,0.05,0.14,0.08
						c0.01,0.01,0.01,0.01,0.02,0.02c0.17,0.15,0.31,0.32,0.45,0.49c0.04,0.05,0.08,0.1,0.13,0.15c0,0,0,0,0,0
						c-0.04-0.17-0.08-0.33-0.16-0.47c-0.07-0.14-0.21-0.22-0.36-0.3c-0.11-0.06-0.23-0.13-0.33-0.23l-0.11-0.11l0.15-0.05
						c0.1-0.03,0.18,0,0.23,0.02c0.01,0,0.02,0.01,0.03,0.01c0.02,0.01,0.04,0.01,0.07,0.02c0-0.02,0-0.05,0-0.07l0-0.03
						c0-0.02,0-0.03,0-0.05c0-0.01,0-0.01,0-0.02c-0.02-0.11,0.01-0.18,0.09-0.21c0.11-0.04,0.18,0.06,0.21,0.09
						c0,0.01,0.01,0.01,0.01,0.02l0.17,0.21c0.24,0.29,0.48,0.58,0.71,0.88c0.02,0.02,0.03,0.04,0.05,0.06
						c0.08,0.09,0.19,0.21,0.16,0.4c-0.03,0.17,0.04,0.3,0.17,0.45c0.27,0.32,0.4,0.65,0.38,1.01c0,0.05,0.02,0.09,0.08,0.14
						c0.06,0.05,0.12,0.11,0.18,0.17c0.12,0.11,0.24,0.22,0.37,0.33c0.2,0.16,0.27,0.39,0.21,0.66c-0.02,0.07,0,0.12,0.05,0.19h0
						c0.02,0.02,0.03,0.05,0.05,0.07c0.12,0.16,0.25,0.33,0.28,0.55c0.02,0.18,0.12,0.31,0.23,0.44c0.02,0.02,0.04,0.05,0.06,0.07
						c0.08,0.09,0.18,0.2,0.21,0.33c0.05,0.22,0.08,0.45,0.1,0.67c0.01,0.08,0.02,0.17,0.03,0.25c0.01,0.05,0,0.1-0.03,0.13
						c-0.02,0.03-0.06,0.05-0.09,0.05C678.25,1830.2,678.25,1830.2,678.24,1830.2z M674.76,1824.46c0.17,0.26,0.35,0.51,0.53,0.77
						c0.08,0.12,0.08,0.25,0.07,0.36c0,0.03,0,0.05,0,0.08c0,0.12,0.01,0.18,0.07,0.23c0.32,0.27,0.43,0.63,0.31,1.05
						c-0.03,0.1-0.01,0.16,0.08,0.23c0.35,0.27,0.72,0.55,1.02,0.89c0.32,0.36,0.6,0.77,0.88,1.17c0.07,0.11,0.15,0.22,0.23,0.33
						c0.07,0.1,0.14,0.21,0.22,0.31c0-0.02-0.01-0.05-0.01-0.07c-0.02-0.22-0.05-0.44-0.1-0.65c-0.02-0.08-0.09-0.16-0.16-0.24
						c-0.02-0.03-0.05-0.05-0.07-0.08c-0.12-0.15-0.25-0.31-0.27-0.55c-0.02-0.17-0.13-0.31-0.24-0.46
						c-0.02-0.02-0.04-0.05-0.05-0.07l0,0c-0.09-0.12-0.11-0.22-0.08-0.35c0.04-0.2,0-0.35-0.14-0.47
						c-0.13-0.11-0.25-0.22-0.38-0.33c-0.06-0.05-0.12-0.11-0.18-0.16c-0.07-0.06-0.15-0.15-0.14-0.3c0.02-0.31-0.09-0.58-0.33-0.86
						c-0.13-0.15-0.26-0.35-0.21-0.62c0.02-0.08-0.03-0.14-0.11-0.23c-0.02-0.02-0.04-0.05-0.06-0.07
						c-0.23-0.29-0.48-0.59-0.71-0.87l-0.12-0.15c0.01,0.12,0.01,0.2-0.06,0.25c-0.01,0.01-0.02,0.02-0.03,0.02
						c0.15,0.09,0.29,0.18,0.39,0.36c0.09,0.16,0.13,0.33,0.17,0.51c0.03,0.11,0.06,0.23,0.1,0.35l0.09,0.27l-0.24-0.15
						c-0.13-0.08-0.21-0.17-0.27-0.25l-0.02-0.03c-0.04-0.05-0.09-0.11-0.13-0.16C674.78,1824.48,674.77,1824.47,674.76,1824.46z
						 M682.24,1830.11l-0.04-0.09c-0.01-0.02-0.02-0.04-0.02-0.06c-0.11-0.3-0.18-0.62-0.24-0.92c-0.04-0.18-0.08-0.36-0.12-0.54
						c-0.06-0.22-0.05-0.44,0.02-0.7l0,0c0.11-0.38,0.2-0.74,0.11-1.14c-0.03-0.15-0.04-0.31-0.04-0.46c0-0.06,0-0.13-0.01-0.19
						l0-0.01c0-0.04-0.01-0.16,0.11-0.2c0.09-0.03,0.15,0.04,0.18,0.07c0,0,0.01,0.01,0.01,0.01c0.03,0.03,0.06,0.06,0.09,0.09
						c0.07,0.06,0.13,0.13,0.19,0.2c0.02,0.02,0.04,0.05,0.06,0.07c0.11,0.12,0.24,0.25,0.25,0.45c0,0.06,0.01,0.12,0.01,0.19
						c0.04,0.59,0.09,1.2-0.22,1.77c-0.1,0.18-0.14,0.39-0.11,0.62c0.02,0.17,0.01,0.35,0.01,0.52c0,0.07,0,0.15,0,0.22v0.1
						L682.24,1830.11z M682.1,1826.06c0,0.04,0,0.09,0,0.13c0,0.15,0.01,0.29,0.04,0.42c0.1,0.44,0,0.85-0.11,1.23l0,0
						c-0.07,0.23-0.07,0.41-0.02,0.6c0.05,0.18,0.09,0.37,0.13,0.55c0.04,0.21,0.09,0.43,0.15,0.63c0-0.12,0-0.23-0.01-0.35
						c-0.03-0.27,0.02-0.52,0.13-0.74c0.28-0.52,0.24-1.08,0.2-1.66c0-0.06-0.01-0.12-0.01-0.19c-0.01-0.12-0.1-0.22-0.19-0.32
						c-0.02-0.03-0.05-0.05-0.07-0.08c-0.06-0.06-0.12-0.12-0.18-0.18C682.13,1826.09,682.11,1826.08,682.1,1826.06z
						 M667.94,1829.89l-0.29-0.08c-0.11-0.03-0.21-0.08-0.3-0.12c-0.06-0.03-0.12-0.06-0.18-0.08c-0.51-0.19-0.93-0.52-1.3-1.03
						l-0.01-0.01c-0.04-0.05-0.08-0.11-0.09-0.19c-0.08-0.44-0.21-0.77-0.42-1.04l0,0c-0.11-0.15-0.07-0.3-0.02-0.39
						c0.11-0.24,0.24-0.45,0.38-0.66c0.07-0.11,0.15-0.23,0.21-0.35c0.02-0.04,0.08-0.13,0.18-0.11l0.03,0.01
						c0.2,0.04,0.44,0.08,0.57,0.27c0.24,0.34,0.4,0.72,0.52,1.05l0.06,0.06l-0.15,0.15c0.02,0.02,0.04,0.05,0.05,0.07
						c0.23,0.28,0.45,0.64,0.39,1.1c-0.01,0.05-0.01,0.1-0.01,0.16c0,0.04,0,0.08,0,0.11c0,0.01,0,0.01,0,0.02
						c0.01,0.11-0.02,0.17-0.11,0.2c-0.1,0.03-0.16-0.05-0.19-0.08c0-0.01-0.01-0.01-0.01-0.02c-0.28-0.28-0.6-0.51-0.92-0.73
						c0.03,0.1,0.07,0.17,0.16,0.22c0.32,0.18,0.61,0.44,0.85,0.75c0.08,0.11,0.16,0.21,0.25,0.32L667.94,1829.89z M666.1,1826.03
						C666.1,1826.03,666.1,1826.03,666.1,1826.03c-0.08,0.13-0.15,0.25-0.22,0.36c-0.13,0.2-0.26,0.41-0.36,0.63
						c-0.04,0.1-0.03,0.14,0,0.19l0,0c0.22,0.3,0.37,0.65,0.46,1.13c0.01,0.04,0.03,0.07,0.06,0.11l0.01,0.01
						c0.34,0.47,0.73,0.79,1.2,0.96c0.04,0.02,0.09,0.03,0.13,0.05c-0.05-0.06-0.1-0.12-0.14-0.18c-0.23-0.29-0.49-0.53-0.79-0.7
						c-0.18-0.1-0.23-0.27-0.28-0.42c-0.01-0.02-0.01-0.04-0.02-0.06c-0.02-0.05,0-0.14,0.06-0.18c0.06-0.04,0.12-0.03,0.18,0.02
						l0.11,0.08c0.31,0.22,0.63,0.45,0.91,0.72c0-0.02,0-0.04,0-0.06c0-0.06,0-0.12,0.01-0.19c0.05-0.39-0.13-0.69-0.34-0.95
						c-0.1-0.13-0.2-0.26-0.31-0.4c-0.05-0.07-0.1-0.14-0.16-0.21l-0.19-0.25l0.3,0.1c0.1,0.03,0.18,0.09,0.24,0.15
						c-0.1-0.24-0.22-0.49-0.38-0.71C666.46,1826.1,666.28,1826.06,666.1,1826.03L666.1,1826.03z M666.32,1828.05
						C666.32,1828.05,666.32,1828.05,666.32,1828.05C666.32,1828.05,666.32,1828.05,666.32,1828.05z M664.4,1829.76l-0.09-0.15
						c-0.01-0.02-0.02-0.04-0.03-0.05c-0.02-0.04-0.04-0.06-0.06-0.1l-0.06-0.12c-0.11-0.22-0.23-0.45-0.34-0.67
						c-0.08-0.17-0.19-0.28-0.35-0.36c-0.21-0.1-0.43-0.19-0.64-0.28c-0.17-0.07-0.34-0.14-0.51-0.22c-0.18-0.09-0.3-0.21-0.36-0.39
						c-0.1-0.29-0.24-0.52-0.43-0.7c-0.11-0.1-0.22-0.21-0.32-0.32c-0.09-0.09-0.17-0.17-0.26-0.26c-0.04-0.04-0.11-0.11-0.11-0.2
						c0-0.08,0.06-0.15,0.11-0.19v0c0.13-0.12,0.26-0.25,0.38-0.39c0.07-0.08,0.14-0.15,0.21-0.22c0.09-0.09,0.11-0.18,0.06-0.3
						c-0.05-0.13-0.1-0.27-0.15-0.4c-0.06-0.18-0.13-0.36-0.2-0.53c-0.11-0.25-0.07-0.49-0.02-0.73l0.04-0.21l0.13,0.17
						c0.02,0.02,0.03,0.04,0.05,0.06c0.04,0.05,0.08,0.1,0.12,0.15c0.11,0.15,0.21,0.3,0.32,0.45c0.2,0.29,0.41,0.59,0.62,0.88
						c0.05,0.07,0.1,0.13,0.14,0.2c0.17,0.24,0.33,0.47,0.55,0.66c0.39,0.33,0.77,0.67,1.16,1.02c0.17,0.15,0.36,0.35,0.45,0.65
						c0.13,0.44,0.3,0.91,0.6,1.32c0.07,0.09,0.12,0.2,0.17,0.3c0.02,0.04,0.04,0.09,0.07,0.13l0.19,0.34l-0.33-0.21
						c-0.08-0.05-0.16-0.09-0.24-0.14c-0.2-0.11-0.41-0.23-0.57-0.42c-0.07-0.09-0.14-0.18-0.21-0.26c-0.12-0.15-0.24-0.3-0.36-0.43
						c-0.05-0.06-0.1-0.12-0.14-0.18c-0.09-0.11-0.17-0.22-0.28-0.28c-0.1-0.06-0.19-0.11-0.28-0.15c0.02,0.02,0.05,0.03,0.07,0.05
						c0.11,0.08,0.22,0.16,0.31,0.28c0.03,0.05,0.07,0.09,0.1,0.14c0.12,0.16,0.23,0.32,0.37,0.45c0.2,0.21,0.42,0.5,0.3,0.92
						c0,0.01,0,0.03,0,0.05c0,0.03,0,0.06,0,0.09c-0.01,0.03-0.01,0.07-0.01,0.1c-0.01,0.09-0.02,0.19-0.07,0.29L664.4,1829.76z
						 M661.04,1825.95c0,0,0.02,0.02,0.05,0.05c0.09,0.09,0.18,0.17,0.26,0.26c0.1,0.1,0.21,0.21,0.32,0.31
						c0.22,0.21,0.38,0.46,0.49,0.78c0.04,0.13,0.12,0.22,0.25,0.28c0.16,0.08,0.33,0.15,0.5,0.22c0.21,0.09,0.43,0.18,0.65,0.29
						c0.2,0.1,0.34,0.24,0.44,0.45c0.11,0.22,0.22,0.45,0.34,0.67l0.02,0.04c0,0,0,0,0,0c0-0.04,0.01-0.08,0.02-0.12
						c0-0.01,0-0.03,0-0.05c0-0.03,0-0.07,0.01-0.11c0.07-0.26,0-0.47-0.25-0.73c-0.15-0.15-0.27-0.31-0.39-0.47
						c-0.03-0.05-0.07-0.09-0.1-0.14c-0.07-0.1-0.17-0.17-0.27-0.24c-0.03-0.02-0.05-0.04-0.08-0.06l-0.1-0.07
						c-0.16-0.12-0.32-0.24-0.48-0.37l-0.21-0.18l0.28,0.01c0.14,0,0.25,0.07,0.35,0.12c0.06,0.03,0.11,0.06,0.16,0.08
						c0.17,0.06,0.34,0.15,0.5,0.24c0.15,0.08,0.24,0.21,0.34,0.33c0.04,0.06,0.09,0.11,0.13,0.16c0.13,0.14,0.26,0.3,0.37,0.45
						c0.07,0.09,0.14,0.17,0.21,0.26c0.12,0.15,0.28,0.24,0.45,0.34c-0.02-0.03-0.04-0.07-0.07-0.1c-0.32-0.43-0.5-0.94-0.63-1.38
						c-0.08-0.25-0.23-0.42-0.39-0.56c-0.39-0.35-0.77-0.69-1.15-1.02c-0.24-0.2-0.41-0.45-0.58-0.69c-0.05-0.07-0.09-0.13-0.14-0.2
						c-0.21-0.29-0.42-0.59-0.63-0.88c-0.1-0.15-0.21-0.3-0.32-0.45c-0.01-0.01-0.01-0.02-0.02-0.03c-0.01,0.14-0.01,0.26,0.05,0.39
						c0.08,0.18,0.14,0.36,0.21,0.54c0.05,0.13,0.09,0.26,0.15,0.39c0.08,0.19,0.04,0.36-0.1,0.51c-0.07,0.07-0.14,0.15-0.21,0.22
						c-0.12,0.13-0.25,0.27-0.39,0.4C661.04,1825.93,661.04,1825.95,661.04,1825.95C661.04,1825.95,661.04,1825.95,661.04,1825.95z
						 M666.09,1825.66l-0.06-0.05c-0.08-0.06-0.15-0.11-0.23-0.17c-0.19-0.14-0.39-0.29-0.59-0.42c-0.13-0.09-0.21-0.21-0.26-0.35
						c-0.07-0.22-0.14-0.45-0.22-0.67l-0.01-0.04c-0.02-0.06-0.04-0.12-0.07-0.15c-0.12-0.14-0.24-0.27-0.36-0.41
						c-0.27-0.31-0.56-0.62-0.84-0.93c-0.15-0.16-0.17-0.31-0.08-0.52c0.09-0.19,0.17-0.39,0.25-0.59c0.07-0.17,0.14-0.33,0.21-0.5
						c0.03-0.07,0.02-0.09-0.03-0.13c-0.08-0.08-0.15-0.15-0.22-0.23c-0.1-0.1-0.19-0.2-0.29-0.3c-0.12-0.12-0.16-0.25-0.14-0.42
						c0.04-0.22,0.08-0.44,0.11-0.66c0.01-0.04,0.02-0.14,0.12-0.17c0.06-0.02,0.13,0,0.19,0.07l0.09,0.09
						c0.06,0.06,0.11,0.11,0.17,0.17l0.12,0.12c0.35,0.34,0.7,0.7,1.03,1.08c0.11,0.13,0.1,0.26,0.08,0.36
						c-0.04,0.25-0.08,0.49-0.11,0.74c-0.03,0.22-0.07,0.45-0.1,0.67c-0.02,0.12,0.02,0.22,0.09,0.33c0.03,0.04,0.05,0.09,0.08,0.13
						c0.08,0.14,0.16,0.27,0.25,0.38c0.33,0.4,0.41,0.88,0.46,1.34c0.02,0.2,0.08,0.37,0.19,0.53c0.05,0.07,0.09,0.15,0.13,0.22
						c0.06,0.1,0.11,0.19,0.17,0.27l0.06,0.08L666.09,1825.66z M663.43,1819.17c-0.04,0.21-0.07,0.42-0.11,0.63
						c-0.02,0.1,0.01,0.17,0.08,0.24c0.1,0.1,0.2,0.2,0.3,0.31c0.07,0.08,0.15,0.15,0.22,0.23c0.11,0.11,0.13,0.22,0.07,0.36
						c-0.07,0.16-0.14,0.33-0.21,0.49c-0.08,0.2-0.16,0.4-0.25,0.59c-0.06,0.13-0.05,0.2,0.05,0.3c0.29,0.31,0.57,0.62,0.84,0.93
						c0.12,0.14,0.24,0.27,0.36,0.41c0.06,0.07,0.09,0.15,0.11,0.23l0.01,0.04c0.07,0.22,0.15,0.45,0.22,0.67
						c0.03,0.11,0.09,0.18,0.18,0.25c0.17,0.12,0.35,0.25,0.52,0.37c-0.03-0.06-0.07-0.12-0.1-0.17c-0.13-0.19-0.2-0.39-0.23-0.62
						c-0.04-0.43-0.12-0.87-0.42-1.23c-0.1-0.13-0.19-0.27-0.27-0.41c-0.02-0.04-0.05-0.08-0.07-0.13
						c-0.08-0.12-0.15-0.28-0.12-0.47c0.04-0.22,0.07-0.44,0.1-0.67c0.04-0.25,0.07-0.5,0.11-0.75l0,0c0.01-0.09,0-0.14-0.04-0.2
						c-0.32-0.38-0.67-0.73-1.01-1.07l-0.12-0.12c-0.06-0.06-0.11-0.11-0.17-0.16L663.43,1819.17z M670.56,1825.54
						c-0.1,0-0.16-0.04-0.2-0.07c-0.01-0.01-0.02-0.01-0.02-0.02c-0.05-0.03-0.09-0.06-0.14-0.09c-0.14-0.09-0.27-0.18-0.4-0.19
						c-0.4-0.02-0.68-0.2-0.89-0.55c-0.01-0.01-0.02-0.03-0.04-0.04c-0.02-0.02-0.03-0.03-0.05-0.05c-0.09-0.12-0.21-0.17-0.37-0.16
						c-0.15,0-0.3,0-0.44,0c-0.08,0-0.16,0-0.23,0c-0.06,0-0.16,0-0.25-0.08c-0.1-0.1-0.21-0.2-0.33-0.3
						c-0.02-0.02-0.04-0.03-0.06-0.05c-0.15-0.14-0.29-0.25-0.5-0.22c-0.02,0-0.04,0.01-0.06,0.01c-0.11,0.02-0.27,0.05-0.4-0.06
						c-0.14-0.13-0.29-0.26-0.43-0.39c-0.07-0.07-0.14-0.13-0.21-0.2c-0.14-0.13-0.17-0.28-0.11-0.45c0.03-0.08,0.06-0.17,0.09-0.26
						c0.04-0.11,0.07-0.23,0.12-0.34c0.15-0.36,0.12-0.61-0.09-0.85c-0.09-0.1-0.22-0.24-0.22-0.42c0-0.26-0.08-0.5-0.26-0.74
						c-0.12-0.16-0.25-0.41-0.07-0.74c0.01-0.03,0.03-0.06,0.04-0.09c0.02-0.04,0.03-0.08,0.06-0.13c0.06-0.11,0.09-0.2,0.07-0.3
						l-0.02-0.09c-0.04-0.19-0.08-0.39-0.12-0.58c0-0.01-0.01-0.03-0.02-0.05c-0.02-0.05-0.09-0.18,0.03-0.25
						c0.15-0.08,0.24,0.06,0.27,0.11c0.01,0.02,0.02,0.03,0.03,0.04c0.03,0.03,0.06,0.07,0.09,0.1c0.15,0.16,0.29,0.32,0.49,0.36
						c0.24,0.05,0.29,0.22,0.31,0.38c0.03,0.24,0.07,0.49,0.11,0.73l0.02,0.11c0.02,0.04,0.19,0.13,0.27,0.15c0-0.03,0-0.06,0-0.09
						c0-0.16,0-0.32-0.08-0.45c-0.07-0.13-0.15-0.26-0.16-0.47l-0.01-0.24l0.32,0.28c0.11,0.1,0.21,0.19,0.31,0.28
						c0.08,0.07,0.09,0.17,0.1,0.25c0.01,0.1,0.02,0.2,0.03,0.29c0.02,0.18,0.04,0.37,0.05,0.56c0.02,0.31,0.09,0.61,0.22,0.94
						c-0.01-0.16-0.04-0.31-0.07-0.46l-0.08-0.41l0.26,0.33c0.38,0.49,0.76,1.03,0.98,1.68c0-0.03,0.01-0.05,0.01-0.08l0.02-0.19
						l0.1,0.02c0.12,0.02,0.19,0.11,0.24,0.17c0.02,0.02,0.03,0.05,0.05,0.06c0.26,0.25,0.5,0.52,0.5,0.93
						c0,0.15,0.07,0.28,0.23,0.44c0.05,0.05,0.1,0.09,0.15,0.13c0.15,0.14,0.31,0.28,0.41,0.49c0.12,0.27,0.23,0.54,0.35,0.82
						c0.05,0.12,0.1,0.24,0.15,0.36l0.05,0.12l-0.13,0.02C670.58,1825.54,670.57,1825.54,670.56,1825.54z M668.46,1824.17
						c0.21,0,0.38,0.08,0.51,0.24c0.01,0.01,0.02,0.02,0.03,0.03c0.02,0.02,0.05,0.05,0.07,0.09c0.17,0.29,0.4,0.43,0.73,0.45
						c0.19,0.01,0.35,0.12,0.5,0.22c0.04,0.02,0.07,0.05,0.11,0.07c-0.02-0.05-0.04-0.1-0.06-0.15c-0.11-0.28-0.22-0.55-0.34-0.82
						c-0.08-0.17-0.21-0.29-0.36-0.42c-0.05-0.05-0.1-0.09-0.15-0.14c-0.13-0.13-0.28-0.31-0.28-0.58c0-0.33-0.2-0.55-0.44-0.79
						c-0.03-0.03-0.05-0.06-0.07-0.09c0,0-0.01-0.01-0.01-0.01c0,0.04-0.01,0.09-0.01,0.13c0,0.04,0,0.07,0,0.11
						c0,0.1,0,0.2-0.05,0.32l-0.13,0.29l-0.06-0.31c-0.13-0.7-0.47-1.28-0.84-1.79c0.02,0.16,0.02,0.33-0.02,0.51l-0.05,0.29
						l-0.13-0.26c-0.26-0.49-0.39-0.93-0.41-1.37c-0.01-0.18-0.03-0.37-0.05-0.55c-0.01-0.1-0.02-0.2-0.03-0.3
						c-0.01-0.07-0.02-0.1-0.04-0.12c-0.06-0.06-0.12-0.11-0.19-0.17c0.01,0.02,0.02,0.04,0.03,0.06c0.11,0.19,0.11,0.38,0.11,0.56
						c0,0.03,0,0.06,0,0.09c0,0.12-0.06,0.17-0.1,0.18c-0.06,0.03-0.17,0.01-0.3-0.06c-0.09-0.05-0.24-0.14-0.26-0.25l-0.02-0.11
						c-0.04-0.24-0.09-0.49-0.12-0.74c-0.02-0.14-0.05-0.19-0.16-0.21c-0.26-0.06-0.43-0.24-0.59-0.42
						c-0.02-0.02-0.03-0.04-0.05-0.06c0.03,0.17,0.07,0.35,0.11,0.52l0.02,0.09c0.03,0.15,0,0.29-0.09,0.44
						c-0.02,0.03-0.03,0.06-0.05,0.1c-0.02,0.04-0.03,0.07-0.05,0.11c-0.1,0.18-0.08,0.33,0.06,0.52c0.2,0.27,0.3,0.55,0.3,0.86
						c0,0.1,0.09,0.2,0.16,0.28c0.27,0.3,0.31,0.63,0.13,1.06c-0.04,0.1-0.08,0.21-0.11,0.32c-0.03,0.09-0.06,0.18-0.09,0.27
						c-0.04,0.1-0.02,0.16,0.06,0.24c0.07,0.07,0.14,0.13,0.21,0.2c0.14,0.13,0.28,0.26,0.42,0.39c0.05,0.05,0.12,0.04,0.23,0.02
						c0.02,0,0.05-0.01,0.07-0.01c0.3-0.04,0.49,0.12,0.66,0.27c0.02,0.02,0.04,0.03,0.06,0.05c0.12,0.1,0.23,0.21,0.34,0.31
						c0.02,0.02,0.06,0.03,0.11,0.03c0.08,0,0.16,0,0.24,0c0.14,0,0.29,0,0.44,0C668.44,1824.17,668.45,1824.17,668.46,1824.17z"/>
				</g>
				<g>
					<path className="st109" d="M644.24,1811.92c-0.49,0.25-0.81,0.71-1.15,1.15c-0.01,0.01-0.03,0.03-0.02,0.04
						c0.06,0.3-0.16,0.23-0.31,0.28c0.19-0.28,0.13-0.72,0.5-0.9c0.18-0.09,0.19-0.36,0.28-0.55c0.12-0.26,0.23-0.53,0.42-0.74
						c0.08-0.09,0.09-0.19,0.05-0.31c-0.22,0.12-0.43,0.28-0.61,0.47c-0.63,0.69-1.05,1.54-1.35,2.46
						c-0.07,0.21-0.15,0.31-0.35,0.34c-0.36,0.06-0.68,0.19-0.91,0.54c-0.13,0.19-0.27,0.38-0.49,0.45
						c-0.24,0.08-0.49,0.14-0.74,0.22c-0.17,0.27-0.42,0.44-0.63,0.66c-0.43,0.45-0.89,0.87-1.34,1.3
						c-0.08,0.08-0.13,0.17-0.15,0.28c-0.09,0.39-0.2,0.77-0.28,1.16c-0.04,0.18-0.12,0.33-0.25,0.43c-0.57,0.48-0.85,1.13-0.97,1.9
						c-0.03,0.18-0.09,0.35-0.12,0.54c-0.05,0.34-0.28,0.49-0.49,0.66c-0.22,0.18-0.43,0.36-0.66,0.52
						c-0.19,0.12-0.28,0.31-0.3,0.52c-0.06,0.68-0.43,1.12-0.87,1.51c-0.38,0.33-0.67,0.76-1,1.15c-0.56,0.67-1.1,1.36-1.66,2.03
						c-0.12,0.14-0.3,0.18-0.46,0.25c-0.49,0.22-0.98,0.42-1.46,0.67c-0.37,0.19-0.63,0.54-0.93,0.83
						c-0.59,0.58-1.18,1.15-1.74,1.76c-0.38,0.41-0.7,0.88-1.05,1.32c-0.04,0.05-0.07,0.12-0.14,0.15c-0.05-0.06,0-0.12,0.01-0.17
						c0.19-0.51,0.39-1.01,0.58-1.52c0.09-0.25,0.31-0.35,0.48-0.5c0.49-0.44,0.87-1,1.26-1.55c0.54-0.75,1.15-1.31,1.89-1.82
						c0.55-0.38,0.9-0.91,1.46-1.28c0.16-0.1,0.22-0.31,0.32-0.47c0.27-0.45,0.72-0.91,0.98-1.38c-0.57,0.43-1,1.05-1.66,1.43
						c0.03-0.29,0.21-0.4,0.27-0.59c0.06-0.18,0.16-0.35,0.29-0.48c0.25-0.25,0.26-0.6,0.36-0.91c0.15-0.47,0.2-0.99,0.51-1.38
						c0.02-0.02,0.03-0.06,0.05-0.08c0.53-0.43,0.64-1.08,0.73-1.74c0.03-0.21,0.15-0.36,0.25-0.52c0.11-0.16,0.21-0.32,0.33-0.47
						c0.2-0.24,0.12-0.55,0.17-0.82c0.02-0.1,0.02-0.21,0.02-0.31c-0.24,0.07-0.47,0.21-0.67,0.37c-0.61,0.49-1.17,1.04-1.74,1.57
						c-0.15,0.14-0.3,0.23-0.5,0.2c-0.48,0.19-0.94,0.44-1.41,0.68c-0.42,0.22-0.78,0.51-1.08,0.91c-0.53,0.69-1.07,1.36-1.58,2.08
						c-0.18,0.25-0.24,0.55-0.3,0.84c-0.05,0.24-0.14,0.45-0.25,0.66c-0.24,0.42-0.43,0.88-0.72,1.29c-0.22-0.4-0.12-0.77,0.06-1.11
						c0.15-0.3,0.34-0.57,0.51-0.85c0.03-0.05,0.09-0.1,0.08-0.16c-0.02-0.08-0.09-0.04-0.15-0.03c-0.09,0.01-0.13-0.05-0.14-0.14
						c-0.06-0.61-0.18-1.22-0.17-1.83c0-0.49,0.22-0.92,0.67-1.14c0.16-0.08,0.32-0.18,0.48-0.27c0.11-0.06,0.17-0.15,0.12-0.31
						c-0.04-0.16-0.05-0.33-0.07-0.5c-0.06,0.01-0.12,0-0.16,0.06c-0.09,0.38-0.32,0.64-0.6,0.86c-0.15,0.11-0.28,0.25-0.43,0.36
						c-0.47,0.33-0.67,0.86-0.77,1.43c-0.08,0.44-0.32,0.74-0.57,1.04c-0.12,0.14-0.18,0.29-0.18,0.49c0,0.43-0.07,0.83-0.3,1.2
						c-0.18,0.29-0.18,0.67-0.26,1.01c-0.01,0.06,0,0.13-0.11,0.18c0.08-0.62-0.12-1.25,0.16-1.82c0.07-0.15,0.06-0.27-0.03-0.42
						c-0.27-0.46-0.4-0.96-0.24-1.52c0.11-0.39,0.09-0.4-0.21-0.63c-0.16-0.12-0.32-0.24-0.48-0.36c-0.08-0.06-0.14-0.06-0.18,0.06
						c-0.16,0.49-0.38,0.95-0.33,1.5c0.01,0.12-0.04,0.19-0.11,0.27c-0.22,0.24-0.44,0.48-0.66,0.71c-0.26,0.28-0.35,0.63-0.38,1.01
						c-0.02,0.21-0.03,0.42-0.08,0.63c-0.2-0.43-0.36-0.86-0.4-1.34c-0.01-0.07,0.02-0.16-0.05-0.19c-0.06-0.02-0.1,0.04-0.15,0.08
						c-0.37,0.26-0.53,0.7-0.73,1.1c-0.12,0.23-0.21,0.47-0.32,0.71c-0.02,0.05-0.03,0.14-0.1,0.14c-0.07-0.01-0.07-0.1-0.08-0.15
						c-0.06-0.56-0.43-0.87-0.75-1.23c0.08,0.46,0.37,0.87,0.3,1.38c-0.09-0.02-0.1-0.08-0.11-0.13c-0.09-0.38-0.31-0.64-0.6-0.83
						c-0.17-0.11-0.3-0.32-0.52-0.35c0.22,0.42,0.43,0.84,0.66,1.25c0.22,0.4,0.26,0.86,0.31,1.31c0.01,0.08-0.03,0.13-0.07,0.19
						c-0.11,0.16-0.2,0.33-0.31,0.48c-0.16,0.23-0.23,0.49-0.21,0.78c0.02,0.21-0.22,0.58-0.39,0.66c-0.08,0.03-0.1-0.02-0.12-0.09
						c-0.04-0.16-0.08-0.32-0.12-0.49c-0.09-0.39-0.31-0.66-0.62-0.87c-0.37-0.26-0.73-0.54-1.11-0.82
						c-0.03,0.1,0.02,0.14,0.06,0.18c0.27,0.28,0.5,0.61,0.76,0.91c0.24,0.29,0.3,0.66,0.37,1.03c0.02,0.12,0.02,0.26,0.05,0.38
						c0.14,0.54,0.05,1.05-0.14,1.56c-0.13,0.36-0.23,0.73-0.34,1.1c-0.03,0-0.03,0-0.06-0.01c-0.04-0.34-0.09-0.68-0.13-1.02
						c-0.03-0.22-0.06-0.45-0.07-0.67c-0.01-0.22-0.12-0.39-0.22-0.56c-0.23-0.39-0.84-3.29-1.12-3.63
						c-0.01,0.15-12.33,13.4-12.21,13.32c2.68-1.8,5.07-3.1,5.05-2.62c-0.03,0.61,1.07,0.94,1.43,0.89c0.86-0.11,1.97,0.15,2,0
						c0.08-0.43,3.98,0.32,4.28-0.01c0.13-0.14,0.72-2.14,0.88-2.25c0.14-0.1,0.15-0.2,0.1-0.35c-0.16-0.45-0.15-0.45,0.22-0.69
						c0.02-0.01,0.04-0.02,0.05-0.04c0.32-0.22,0.32-0.23,0.08-0.54c-0.05-0.07-0.08-0.13-0.05-0.22c0.16-0.55,0.35-1.08,0.94-1.22
						c0.14-0.03,0.17-0.14,0.16-0.27c-0.05-0.55,0.3-0.74,0.66-0.64c0.45,0.13,0.74,0,0.98-0.4c0.04-0.06,0.1-0.11,0.15-0.17
						c0.19-0.28,0.44-0.35,0.72-0.22c0.27,0.13,0.49,0.07,0.69-0.15c0.15-0.16,0.3-0.16,0.44,0.02c0.28,0.36,0.63,0.54,1.06,0.51
						c0.23-0.01,0.4,0.19,0.51,0.44c0.06,0.13,0.15,0.22,0.25,0.31c0.04,0.04,0.06,0.15,0.14,0.12c0.06-0.02,0.03-0.1,0.04-0.15
						c0.1-0.51,0.03-1.01-0.1-1.49c-0.12-0.45,0-0.8,0.29-1.1c0.1-0.11,0.16-0.2,0.09-0.37c-0.05-0.12-0.05-0.27-0.08-0.4
						c-0.07-0.29-0.02-0.53,0.2-0.73c0.18-0.17,0.35-0.35,0.42-0.62c0.04-0.14,0.12-0.25,0.25-0.31c0.3-0.14,0.37-0.4,0.33-0.74
						c-0.01-0.05-0.03-0.11,0.04-0.19c0.1,0.27,0.19,0.52,0.28,0.77c0.06,0.17,0.1,0.35,0.16,0.52c0.07,0.22,0.09,0.43-0.06,0.63
						c-0.09,0.12-0.14,0.28-0.23,0.41c-0.29,0.41-0.43,0.87-0.38,1.39c0.02,0.17-0.04,0.34-0.16,0.45
						c-0.14,0.13-0.25,0.28-0.38,0.42c-0.1,0.1-0.13,0.2-0.05,0.34c0.05,0.08,0.07,0.18,0.13,0.25c0.3,0.39,0.31,0.83,0.23,1.3
						c-0.03,0.16-0.05,0.31-0.06,0.47c-0.02,0.36-0.1,0.65-0.4,0.87c-0.55,0.39-1,0.94-1.56,1.33c-0.63,0.44-1.24,0.9-1.9,1.28
						c-0.22,0.13-0.46,0.21-0.7,0.26c-0.57,0.11-1.09,0.38-1.61,0.62c-0.7,0.33-1.45,0.53-2.19,0.72
						c-0.25,0.07-21.35,4.29-21.61,4.33c0,0.75,8.43-0.11,8.43,0.64c0.08-0.06,9.76-1.36,9.78-1.44c0.16-0.53,3.32-2.13,3.61-2.56
						c0.05-0.07,0.1-0.17,0.19-0.09c0.12,0.1,0.17,0,0.21-0.09c0.06-0.1,0.09-0.22,0.15-0.32c0.1-0.19,0.22-0.35,0.42-0.4
						c0.14-0.03,0.27-0.07,0.4-0.14c0.42-0.21,0.45-0.28,0.45,0.04c0,0.34,0.01,0.69-0.05,1.03c-0.01,0.05-0.05,0.12,0,0.15
						c0.07,0.05,0.1-0.04,0.14-0.07c0.25-0.21,0.46-0.48,0.52-0.82c0.09-0.47,0.38-0.71,0.73-0.89c0.17-0.09,0.34-0.18,0.55-0.28
						c-0.08,0.21-0.7,0.78-0.81,0.89c-0.48,0.48-0.34,0.58-0.59,1.23c-0.18,0.47-0.48,0.72-0.9,0.86c-0.77,0.25-2.73,1.07-3.41,1.56
						c-0.04,0.03-1.99,2.07-2.05,2.04c0,0.4,3.14-1.65,3.14-1.25c0.03-0.01,0.07-0.01,0.1-0.02c0.43-0.17,0.84-0.38,1.28-0.51
						c0.49-0.15,0.97-0.35,1.43-0.62c0.15-0.09,0.3-0.21,0.44-0.23c-0.12,0.15-0.24,0.32-0.37,0.48c-0.03,0.03-1.49,2.64-1.5,2.7
						c-0.34,1.28,2.15-1.6,2.18-1.61c0,0,0.66-0.84,1.07-1.03c0.18-0.08-0.09-0.54,0.11-0.51c0.33,0.05,0.65,0.17,0.97,0.27
						c0.23,0.07,0.45,0.12,0.68-0.01c0.38-0.2,0.79-0.29,1.17-0.49c0.04-0.02,0.1-0.05,0.13,0.01c0.03,0.05,0,0.1-0.05,0.12
						c-0.26,0.13-0.39,0.44-0.63,0.6c-0.06,0.04-0.13,0.09-0.12,0.24c0.08-0.06,0.14-0.1,0.2-0.15c0.25-0.18,0.49-0.37,0.75-0.53
						c0.57-0.36,1.11-0.77,1.72-1.04c0.15-0.07,0.29-0.14,0.43-0.24c0.59-0.41,1.13-0.89,1.66-1.39c0.24-0.22,0.49-0.42,0.82-0.2
						c0.13,0.08,0.29,0.06,0.42-0.04c0.61-0.47,1.23-0.94,1.8-1.47c0.06-0.05,0.11-0.12,0.2-0.09c-0.05,0.16-0.18,0.27-0.15,0.45
						c0.01,0.07-0.03,0.16,0.05,0.19c0.06,0.03,0.1-0.05,0.14-0.09c0.2-0.2,0.4-0.41,0.62-0.58c0.1-0.08,0.2-0.14,0.22-0.29
						c-0.05-0.1-0.04-0.2-0.04-0.3c0-0.46,0.01-0.93,0-1.39c0-0.19,0.05-0.27,0.22-0.29c0.33-0.06,0.65-0.15,0.98-0.21
						c0.12-0.02,0.18-0.09,0.24-0.2c0.25-0.48,0.5-0.95,0.75-1.42c0.08-0.16,0.19-0.24,0.37-0.22c0.2,0.02,0.41,0.03,0.61,0.01
						c0.22-0.03,0.31,0.03,0.23,0.22c-0.06,0.13-0.07,0.26-0.01,0.39c0.07,0.15,0.02,0.27-0.07,0.39c-0.28,0.41-0.58,0.81-0.91,1.17
						c-0.06,0.07-0.13,0.13-0.16,0.22c0.12,0,0.22-0.05,0.32-0.11c0.62-0.32,1.18-0.78,1.81-1.08c0.1-0.05,0.19-0.07,0.27,0.02
						c0.22,0.23,0.4,0.49,0.48,0.82c0.02,0.02,0.04,0.04,0.06,0.06c0.06,0.01,0.11,0.05,0.14,0.11c0.11,0.18,0.26,0.17,0.43,0.15
						c0.19-0.02,0.36-0.13,0.53-0.21c0.11-0.09,0.15-0.23,0.2-0.36c0.07-0.18,0.19-0.25,0.35-0.26c0.41-0.03,0.82,0.88,1.23,0.86
						c0.11-0.01,0.19-0.05,0.27-0.13c0.27-0.28,0.55-1.51,0.82-1.79c0.09-0.1,0.15,1.84,0.23,1.92c0.14,0.14,0.29,0.27,0.48,0.45
						c0-0.25,0.01-0.44,0-0.63c-0.01-0.15,0.04-2.13,0.18-2.15c0.25-0.03,0.49-0.09,0.74-0.12c0.16-0.02,0.2-0.09,0.13-0.25
						c-0.14-0.32-0.28-0.65-0.42-0.97c-0.02-0.05-0.07-0.1-0.02-0.15c0.04-0.05,0.09-0.01,0.14,0c0.23,0.08,0.47,0.14,0.69,0.24
						c0.18,0.08,0.29,0.27,0.43,0.42c0.03,0.03,0.04,0.06,0.1,0.04c-0.01-0.19-0.14-0.3-0.23-0.43c-0.31-0.46-0.58-0.95-0.88-1.41
						c-0.05-0.08-0.06-0.11,0.03-0.15c0.14-0.07,0.27-0.07,0.4,0.04c0.15,0.14,0.32,0.27,0.47,0.4c0.03,0.03,0.06,0.06,0.09,0.03
						c0.04-0.04,0-0.09,0-0.14c0.01-0.19,0-0.37-0.08-0.54c-0.25-0.56,0.1-0.48,0.25-0.6c0.11-0.09,0.17,0.02,0.23,0.12
						c0.41,0.67,0.83,1.33,1.25,2c0.02,0.04,0.04,0.09,0.13,0.06c-0.2-0.84-0.4-1.68-0.61-2.56c0.17,0.1,0.26,0.24,0.35,0.37
						c0.33,0.45,0.66,0.9,0.99,1.35c0.22,0.3,0.31,0.66,0.43,1.01c0.06,0.16,0.13,0.29,0.27,0.39c0.41,0.28,2.78,6.31,3.2,6.57
						c0.16,0.1,0.17,0.16,0.04,0.28c-0.02,0.02-0.03,0.05-0.05,0.08c0.04,0.06,0.1,0.11,0.16,0.1c0.28-0.06,0.59-0.04,0.85-0.2
						c0.35-0.22,0.74-0.38,1.01-0.73c0.02-0.03,0.06-0.05,0.09-0.06c0.17-0.05,0.26-0.2,0.37-0.34c-0.29,0.17-0.61,0.13-0.92,0.13
						c-0.05,0-0.11,0.02-0.12-0.05c-0.01-0.03,0.03-0.09,0.06-0.12c0.16-0.18,0.33-0.36,0.49-0.53c0.08-0.08,0.16-0.17,0.14-0.31
						c-0.06-0.74-1.88-7.17-1.59-7.84c0.02-0.05,0.08-0.12,0.02-0.17c-0.05-0.05-0.1,0.02-0.15,0.05c-0.2,0.11-0.41,0.22-0.66,0.36
						c0.09-0.4,0.19-0.76,0.26-1.12c0.09-0.46,0.33-0.78,0.7-0.99c0.08-0.04,0.16-0.11,0.23-0.23c-0.27-0.04-0.51-0.06-0.74,0.09
						c-0.2,0.13-0.4,0.28-0.6,0.42c-0.03,0.02-0.06,0.08-0.11,0.05c-0.06-0.03-0.04-0.1-0.03-0.15c0.04-0.3,0.21-0.54,0.34-0.79
						c0.17-0.32,0.36-0.63,0.5-0.98c0.04-0.1,0.07-0.21,0.03-0.3c-0.28-0.57-0.12-1.14-0.07-1.73c0.04-0.53,0.36-0.81,0.67-1.1
						c0.16-0.15,0.32-0.29,0.48-0.44c0.11-0.11,0.17-0.09,0.26,0.04c0.13,0.2,0.23,0.44,0.43,0.65c-0.05-0.24-0.07-0.43-0.13-0.61
						c-0.13-0.4-0.06-0.76,0.13-1.13c0.14-0.28,0.26-0.58,0.37-0.88c0.04-0.12,0.1-0.17,0.2-0.18c0.09,0,0.18-0.03,0.28-0.02
						c0.33,0.01,0.51-0.28,0.75-0.45c0.08-0.05,0.15-0.18,0.24-0.13c0.11,0.05,0.05,0.2,0.05,0.3c0.02,0.58,0.03,1.15,0.05,1.73
						c0,0.05-0.01,0.11,0.03,0.17c0.07-0.03,0.06-0.11,0.07-0.18c0.1-0.57,0.19-1.15,0.28-1.72c0.04-0.26,0.22-0.44,0.32-0.67
						c0.06-0.13,0.14-0.25,0.23-0.4c0.08,0.2,0.06,0.4,0.04,0.57c-0.06,0.47,0.05,0.82,0.48,0.99c0.13,0.05,0.17,0.18,0.18,0.34
						c0.02,0.56,0.03,1.12,0.03,1.68c0,0.13,0,0.26-0.04,0.38c-0.17,0.53-0.24,1.09-0.39,1.62c-0.02,0.07-0.06,0.15,0.01,0.25
						c0.16-0.33,0.31-0.64,0.46-0.95c0.02-0.04,0.01-0.11,0.07-0.11c0.07,0,0.07,0.07,0.08,0.12c0.06,0.34,0.12,0.69,0.21,1.03
						c0.03,0.12-0.02,0.21-0.09,0.3c-0.31,0.41-0.63,0.8-0.97,1.18c-0.1,0.11-0.16,0.21-0.03,0.36c0.11,0.12,0.09,0.27,0.01,0.41
						c-0.3,0.53-1.76,2.97-2.05,3.5c-0.12,0.23-0.11,0.27,0.13,0.33c0.01,0,0.03,0.01,0.04,0.01c0.6,0.15,0.6,0.15,0.55,0.84
						c-0.01,0.08-0.04,0.16,0.01,0.23c0.12,0.02,1.37-1.97,1.45-2.06c0.26-0.31,0.52-0.63,0.8-0.92c0.2-0.21,0.39-0.42,0.54-0.66
						c0.08-0.24,0.17-0.47,0.24-0.72c0.13-0.5,0.41-0.83,0.81-1.08c0.2-0.13,0.34-0.12,0.52,0.03c0.18,0.15,0.38,0.29,0.63,0.23
						c0.14-0.03,0.28-0.08,0.46-0.06c-0.14-0.2-0.33-0.22-0.48-0.32c-0.11-0.07-0.28-0.07-0.32-0.22c-0.1-0.39-0.25-0.79-0.08-1.21
						c0.22-0.57,0.47-1.11,0.7-1.67c0.06-0.15,0.11-0.11,0.18-0.01c0.15,0.21,0.29,0.44,0.45,0.63c0.15,0.19,0.23,0.39,0.26,0.64
						c0.03,0.31,0.08,0.61,0.12,0.92c0.08-0.03,0.08-0.09,0.1-0.14c0.13-0.44,0.25-0.88,0.37-1.33c0.03-0.12,0.02-0.24-0.02-0.35
						c-0.15-0.41-0.18-0.8,0.09-1.17c0.08-0.1,0.06-0.2,0.01-0.31c-0.14-0.3-0.28-0.61-0.43-0.91c-0.04-0.08-0.09-0.15-0.04-0.24
						c0.11-0.19,0.22-0.37,0.34-0.55c0.04-0.06,0.08-0.1,0.13-0.02c0.27,0.38,0.63,0.7,0.68,1.24c0.03,0.28,0.12,0.56,0.18,0.84
						c0.03,0,0.02,0,0.06-0.01c0.02-0.42,0.04-0.84,0.05-1.26c0.01-0.14-0.02-0.27-0.06-0.4c-0.16-0.53-0.31-1.06-0.47-1.59
						c-0.03-0.09-0.02-0.19,0.02-0.28c0.08-0.19,0.17-0.39,0.25-0.58c0.05-0.12,0.1-0.13,0.17-0.03c0.21,0.3,0.46,0.58,0.52,0.97
						c0.01,0.05,0.02,0.12,0.1,0.11c0.04-0.34,0.09-0.68-0.14-0.97c-0.05-0.07-0.09-0.16-0.13-0.24c-0.17-0.37-0.18-0.39,0.12-0.66
						c0.16-0.14,0.11-0.2-0.02-0.28c-0.86-0.53-1.73-0.98-2.73-1.08c-0.31-0.03-0.65-0.06-0.92-0.27c-0.7-0.56-1.33-1.22-1.94-1.91
						c-0.18-0.2-0.34-0.28-0.57-0.25c-0.04,0.03-0.14,0.02-0.14,0.02c-0.17,0.01-0.34,0.02-0.5,0.03l-0.22-0.14
						c-0.1-0.12-0.22-0.23-0.3-0.37c-0.22-0.34-0.5-0.57-0.88-0.66c-0.09-0.02-0.16-0.06-0.21,0.06c-0.27,0.63-0.55,1.26-0.81,1.9
						c-0.05,0.13-0.18,0.25-0.14,0.43c0.08-0.01,0.11-0.07,0.15-0.11c0.16-0.15,0.2-0.14,0.27,0.1c0.04,0.14,0.03,0.28-0.01,0.42
						c-0.07,0.32-0.14,0.64-0.22,0.96c0.06,0.03,0.09,0,0.11-0.03c0.25-0.36,0.51-0.73,0.76-1.09c0.07-0.1,0.13-0.21,0.23-0.28
						l0.01-0.04c0.01,0,0.01,0.01,0.02,0.01c-0.01,0.01-0.02,0.02-0.03,0.03c-0.01,0.05-0.01,0.11-0.03,0.16
						c-0.16,0.35-0.33,0.69-0.49,1.04c-0.16,0.35-0.41,0.69-0.14,1.12c0.06,0.1,0.01,0.17-0.11,0.19c-0.23,0.04-0.45,0.1-0.67,0.12
						c-0.22,0.02-0.4,0.12-0.55,0.27c-0.14,0.14-0.28,0.27-0.41,0.42c-0.27,0.3-0.56,0.55-0.92,0.73c-0.23,0.11-0.47,0.21-0.65,0.4
						c-0.31,0.32-0.59,0.67-0.88,1.01c-0.05,0.06-0.09,0.19-0.18,0.12c-0.1-0.08,0.02-0.15,0.05-0.22c0.02-0.04,0.05-0.08,0.06-0.13
						c0.1-0.57,0.39-1,0.8-1.34c0.06-0.05,0.12-0.14,0.2-0.08c0.19,0.13,0.27-0.01,0.35-0.16c0.08-0.15,0.17-0.3,0.25-0.45
						c0.29-0.52,0.49-1.11,0.98-1.46c0.03-0.02,0.07-0.06,0.05-0.12c-0.02-0.04-0.06-0.04-0.09-0.04c-0.15-0.02-0.3-0.04-0.44-0.07
						c-0.12-0.02-0.2-0.07-0.18-0.25c0.02-0.2,0.06-0.38,0.11-0.56c0.01-0.05,0.06-0.11,0-0.15c-0.04-0.03-0.08,0.02-0.11,0.04
						c-0.46,0.27-0.84,0.61-1.07,1.15c-0.08,0.19-0.2,0.36-0.31,0.54c0.02-0.43,0.08-0.84,0.14-1.25c0.05-0.34,0.05-0.36-0.24-0.39
						c-0.28-0.02-0.59-0.17-0.85,0.01c-0.41,0.28-0.85,0.1-1.28,0.17c0.19-0.23,0.44-0.34,0.66-0.52c0.23-0.19,0.47-0.33,0.77-0.27
						c0.15,0.03,0.2-0.07,0.25-0.21c0.14-0.38-0.06-0.7-0.1-1.05c-0.01-0.06-0.06-0.07-0.1-0.04c-0.3,0.22-0.74,0.29-0.7,0.86
						c0.01,0.15-0.07,0.12-0.15,0.07c-0.18-0.11-0.37-0.21-0.55-0.32c-0.38-0.22-0.34-0.1-0.26-0.61c0.05-0.31,0.04-0.55-0.22-0.73
						c-0.19-0.13-0.29-0.31-0.16-0.59c0.05-0.1,0.05-0.23,0.08-0.34c0.08-0.3,0.17-0.6,0.07-0.91c-0.05-0.16-0.03-0.28,0.1-0.36
						c0.07-0.04,0.14-0.09,0.19-0.15c0.16-0.16,0.26-0.07,0.35,0.1c0.29,0.51,0.62,1,0.87,1.53c0.19,0.38,0.48,0.44,0.82,0.51
						c-0.06-0.17-0.18-0.26-0.27-0.38c-0.2-0.26-0.46-0.46-0.58-0.79c-0.1-0.29-0.26-0.55-0.35-0.85c-0.06-0.2-0.16-0.41-0.33-0.55
						c-0.12-0.11-0.3-0.18-0.34-0.34c-0.04-0.18,0.16-0.28,0.21-0.45l0.12-0.19c0.23-0.05,0.43-0.37,0.69-0.07
						c0.03,0.04,0.08-0.01,0.12-0.02c0.18-0.07,0.27-0.24,0.38-0.4c0.05-0.08,0.11-0.2,0.23-0.05c0.2,0.28,0.21,0.27,0.42-0.02
						c0.04-0.06,0.1-0.11,0.14-0.18c-0.01,0.02-0.2,0.02-0.23,0.02c-0.08,0.01-0.16,0.02-0.24,0.03c-0.16,0.02-0.33,0.05-0.49,0.08
						c-0.31,0.06-0.62,0.16-0.93,0.21c-0.14,0.02-0.28,0.14-0.4,0.22c-0.14,0.09-0.28,0.18-0.42,0.28
						c-0.05,0.84-0.33,1.53-1.02,1.96 M619.75,1831.55c-0.01,0.15-0.07,0.21-0.2,0.2c-0.11-0.01-0.21,0.01-0.32,0.02
						c-0.46,0.01-0.65,0.42-0.89,0.77c-0.1,0.14-0.18,0.19-0.34,0.15c-0.4-0.12-0.79-0.08-1.14,0.2c-0.02,0.01-0.04,0.02-0.06,0.03
						c-0.01,0-0.03,0-0.06-0.01c0.09-0.61,0.18-1.21,0.46-1.75c0.09-0.16,0.17-0.33,0.24-0.51c0.06-0.16,0.13-0.24,0.29-0.13
						c0.11,0.07,0.2,0.06,0.17-0.13c-0.09-0.67,0.13-1.22,0.53-1.7c0.24-0.28,0.41-0.61,0.58-0.94c0.03-0.05,0.02-0.15,0.1-0.13
						c0.07,0.02,0.05,0.1,0.06,0.16c0.04,0.46,0.1,0.92,0.2,1.37c0.03,0.11,0.07,0.21,0.11,0.31
						C619.81,1830.11,619.8,1830.83,619.75,1831.55z M621.91,1828.13c-0.15,0.62-0.23,1.27-0.43,1.88
						c-0.01,0.04-0.03,0.07-0.04,0.11c-0.03,0-0.05,0-0.08,0c0-0.32-0.02-0.63,0.01-0.95c0.03-0.32-0.02-0.62-0.15-0.88
						c-0.39-0.78-0.29-1.62-0.24-2.46c0.02-0.25,0.2-0.42,0.35-0.59c0.11-0.13,0.23-0.24,0.34-0.36c0.03-0.03,0.06-0.09,0.1-0.07
						c0.06,0.02,0.05,0.09,0.05,0.14c-0.02,0.27,0,0.55-0.06,0.81c-0.11,0.53,0,1.03,0.13,1.53
						C621.96,1827.57,621.99,1827.83,621.91,1828.13z M627.47,1826.76c-0.06-0.28-0.01-0.53,0.21-0.73c0.23-0.2,0.44-0.43,0.66-0.64
						c0.08-0.08,0.14-0.16,0.13-0.29c-0.02-0.48,0.15-0.86,0.43-1.21c0.16-0.19,0.28-0.4,0.23-0.69c-0.04-0.22,0.12-0.35,0.23-0.49
						c0.35-0.47,0.71-0.94,1.06-1.4c0.04-0.05,0.07-0.13,0.13-0.1c0.07,0.03,0.03,0.11,0.02,0.17c0,0.03,0,0.06-0.01,0.1
						c-0.01,0.27-0.01,0.27,0.23,0.2c0.07-0.02,0.14-0.08,0.24-0.04c-0.26,0.28-0.66,0.35-0.84,0.72c-0.16,0.32-0.19,0.69-0.32,1.09
						c0.16-0.11,0.24-0.22,0.32-0.33c0.22-0.28,0.42-0.58,0.68-0.83c0.05-0.04,0.1-0.13,0.16-0.06c0.06,0.06-0.03,0.11-0.06,0.16
						c-0.34,0.55-0.69,1.1-1.04,1.64c-0.1,0.16-0.06,0.32-0.06,0.49c0,0.15-0.01,0.28-0.12,0.39c-0.35,0.32-0.46,0.73-0.33,1.22
						c0.05,0.19,0,0.31-0.14,0.43c-0.43,0.35-0.85,0.7-1.21,1.13c-0.49,0.59-0.89,1.27-1.31,1.92c-0.13,0.2-0.26,0.4-0.4,0.59
						c-0.02,0.02-0.05,0.05-0.07,0.05c-0.04-0.01-0.05-0.05-0.04-0.09c0.05-0.4,0.07-0.8,0.16-1.18c0.04-0.17,0.19-0.32,0.3-0.46
						c0.15-0.19,0.27-0.37,0.3-0.64c0.03-0.29,0.22-0.52,0.37-0.74C627.47,1827,627.5,1826.9,627.47,1826.76z M624.76,1832.7
						c0.04,0.35-0.04,0.67-0.23,0.94c-0.22,0.31-0.25,0.67-0.27,1.04c-0.01,0.18-0.05,0.3-0.21,0.36c-0.14,0.06-0.28,0.15-0.43,0.19
						c-0.04-0.09,0.02-0.13,0.06-0.17c0.23-0.21,0.31-0.5,0.28-0.81c-0.03-0.38,0.04-0.74,0.23-1.05c0.18-0.29,0.14-0.59,0.1-0.9
						c-0.03-0.24-0.09-0.49-0.04-0.74c0.06-0.3,0.2-0.56,0.32-0.83c0.09-0.21,0.14-0.42,0.1-0.65c-0.03-0.16,0.02-0.26,0.12-0.36
						c0.14-0.13,0.28-0.28,0.42-0.41c0.11-0.1,0.17-0.2,0.02-0.32c-0.09-0.08-0.06-0.15,0-0.24c0.51-0.71,1.15-1.28,1.72-1.92
						c0.03-0.04,0.07-0.08,0.14-0.06c-0.26,0.68-0.63,1.28-1,1.87c-0.12,0.19-0.16,0.39-0.17,0.61c-0.02,0.31-0.04,0.62-0.05,0.93
						c0,0.11-0.04,0.2-0.09,0.29c-0.31,0.5-0.62,1-0.93,1.49c-0.06,0.1-0.09,0.19-0.09,0.31
						C624.77,1832.43,624.75,1832.57,624.76,1832.7z M642.34,1817.88c0.43-0.55,0.9-1.04,1.37-1.54c0.1-0.11,0.21-0.22,0.31-0.33
						c0.09-0.1,0.15-0.07,0.17,0.07c0.04,0.28,0.09,0.57,0.13,0.85c0.03,0.17-0.01,0.31-0.13,0.43c-0.21,0.22-0.41,0.46-0.62,0.69
						c-0.09,0.1-0.11,0.18-0.06,0.32c0.19,0.46,0.36,0.94,0.55,1.4c0.09,0.21,0.07,0.35-0.08,0.52c-0.49,0.56-0.97,1.15-1.45,1.73
						c-0.07,0.08-0.09,0.18-0.12,0.28c-0.09,0.29-0.18,0.59-0.27,0.89c-0.05,0.17-0.14,0.29-0.27,0.39
						c-0.33,0.25-0.66,0.51-0.98,0.77c-0.02-0.01-0.03-0.03-0.05-0.04c0.14-0.2,0.24-0.43,0.37-0.64c0.14-0.23,0.22-0.46,0.25-0.75
						c0.05-0.6,0.16-1.18,0.53-1.66c0.16-0.2,0.27-0.45,0.4-0.68c0.09-0.16,0.15-0.32,0.12-0.52c-0.09-0.61-0.17-1.22-0.26-1.82
						C642.25,1818.1,642.26,1817.98,642.34,1817.88z M636.09,1822.7c0.14-0.32,0.41-0.53,0.64-0.77c0.17-0.19,0.3-0.38,0.31-0.65
						c0.01-0.49,0.28-0.8,0.57-1.11c0.09-0.09,0.14-0.24,0.28-0.27c0.01,0.13,0.03,0.26,0.04,0.39c0.01,0.16-0.02,0.33,0.05,0.51
						c0.19-1.08,0.72-1.95,1.32-2.78c-0.07,0.36-0.13,0.72-0.06,1.13c0.28-0.57,0.45-1.11,0.48-1.71c0.02-0.37,0.06-0.73,0.09-1.1
						c0.01-0.09,0.02-0.18,0.08-0.24c0.17-0.17,0.34-0.33,0.54-0.52c-0.01,0.23-0.1,0.39-0.18,0.55c-0.13,0.25-0.1,0.51-0.11,0.77
						c0,0.05,0.01,0.1,0.06,0.12c0.09,0.05,0.48-0.18,0.5-0.3c0.06-0.36,0.12-0.72,0.16-1.09c0.02-0.21,0.08-0.34,0.28-0.38
						c0.33-0.08,0.53-0.38,0.75-0.63c0.06-0.07,0.11-0.24,0.22-0.17c0.07,0.05-0.03,0.17-0.04,0.26c-0.05,0.29-0.11,0.58-0.16,0.87
						c-0.03,0.18,0.01,0.34,0.09,0.48c0.05,0.09,0.07,0.19,0.12,0.27c0.16,0.3,0.1,0.55-0.08,0.81c-0.2,0.3-0.34,0.63-0.33,1.03
						c0,0.18-0.12,0.33-0.23,0.45c-0.32,0.38-0.31,0.78-0.13,1.23c0.1,0.25,0.16,0.51,0.25,0.77c0.06,0.18,0.02,0.32-0.1,0.45
						c-0.26,0.25-0.51,0.51-0.76,0.76c-0.13,0.13-0.32,0.06-0.45,0.04c-0.34-0.05-0.54,0.18-0.76,0.38
						c-0.14,0.13-0.27,0.26-0.4,0.39c-0.06,0.07-0.14,0.07-0.22,0.07c-0.27,0-0.54,0-0.81-0.01c-0.22-0.01-0.4,0.07-0.55,0.26
						c-0.04,0.05-0.09,0.09-0.12,0.14c-0.22,0.41-0.52,0.62-0.97,0.64c-0.25,0.01-0.48,0.23-0.71,0.38
						c-0.07,0.04-0.13,0.11-0.25,0.09C635.69,1823.7,635.88,1823.19,636.09,1822.7z M636.89,1827.88c-0.21,0.25-0.48,0.36-0.76,0.45
						c-0.31,0.09-0.62,0.19-0.93,0.26c-0.39,0.09-0.64,0.44-0.94,0.68c-0.45,0.35-0.87,0.75-1.3,1.12
						c-0.04,0.03-0.07,0.08-0.17,0.08c0.21-0.34,0.43-0.63,0.57-0.97c0.27-0.63,0.82-0.78,1.31-1.03c0.41-0.2,0.84-0.35,1.23-0.59
						c0.28-0.17,0.51-0.45,0.76-0.69c0.39-0.36,0.75-0.79,1.2-1.06c0.12-0.07,0.24-0.18,0.45-0.18c-0.1,0.11-0.17,0.18-0.24,0.26
						c-0.04,0.05-0.12,0.11-0.06,0.18c0.05,0.06,0.12-0.01,0.15-0.04c0.23-0.29,0.55-0.3,0.86-0.35c0.03-0.01,0.07-0.01,0.1,0.05
						c-0.24,0.16-0.48,0.33-0.73,0.47c-0.36,0.2-0.66,0.5-0.95,0.8C637.26,1827.5,637.07,1827.68,636.89,1827.88z M640.68,1830.98
						c-0.55,0.35-1,0.87-1.53,1.26c-0.36,0.27-0.77,0.43-1.12,0.73c0.11-0.26,0.26-0.52,0.46-0.64c0.31-0.18,0.5-0.43,0.57-0.8
						c0.04-0.23,0.15-0.38,0.35-0.47c0.35-0.15,0.56-0.48,0.79-0.78c0.04-0.05,0.06-0.15,0.14-0.12c0.06,0.02,0.04,0.11,0.06,0.17
						c0.05,0.21,0.02,0.48,0.3,0.53C640.78,1830.87,640.72,1830.95,640.68,1830.98z M641.15,1827.99c-0.01,0.08-0.06,0.14-0.1,0.21
						c-0.39,0.59-0.87,1.04-1.5,1.28c-0.19,0.07-0.36,0.19-0.57,0.25c0.15-0.21,0.3-0.41,0.45-0.62c0.28-0.38,0.6-0.7,0.99-0.93
						c0.2-0.12,0.24-0.34,0.3-0.55c0.01-0.02,0-0.07-0.02-0.08c-0.03-0.02-0.06,0.01-0.09,0.03c-0.43,0.33-0.86,0.65-1.24,1.06
						c-0.03,0.04-0.06,0.11-0.12,0.09c-0.07-0.02-0.04-0.11-0.04-0.17c0-0.12,0.01-0.24-0.01-0.36c-0.07-0.53,0.15-0.94,0.44-1.32
						c0.18-0.24,0.35-0.5,0.56-0.8c-0.27,0.09-0.36,0.36-0.58,0.43l-0.01,0.02c0,0-0.01-0.01-0.01-0.01c0,0,0.01-0.01,0.01-0.01
						c0.16-0.48,0.35-0.95,0.62-1.35c0.14-0.21,0.41-0.26,0.65-0.3c0.05-0.01,0.07,0.04,0.09,0.08c0.23,0.43,0.51,0.83,0.7,1.29
						c0.06,0.14,0.07,0.26-0.02,0.38C641.38,1827,641.24,1827.48,641.15,1827.99z M646.97,1825.03c-0.23,0.24-0.46,0.5-0.7,0.74
						c-0.26,0.26-0.44,0.59-0.55,0.96c-0.07,0.21-0.18,0.34-0.37,0.43c-0.45,0.23-0.92,0.42-1.37,0.65
						c-0.22,0.12-0.37,0.28-0.48,0.52c-0.16,0.34-0.32,0.68-0.48,1.02c-0.03,0.05-0.06,0.11-0.1,0.18
						c-0.08-0.16-0.07-0.32-0.09-0.47c-0.01-0.06,0.01-0.13-0.01-0.19c-0.12-0.45,0.06-0.77,0.33-1.06
						c0.22-0.23,0.39-0.51,0.57-0.77c0.12-0.17,0.29-0.28,0.44-0.41c0.23-0.18,0.46-0.36,0.68-0.56c-0.22,0.01-0.38,0.18-0.58,0.25
						c-0.2,0.07-0.39,0.19-0.58,0.3c-0.23,0.14-0.36,0.41-0.53,0.61c-0.25,0.29-0.46,0.61-0.7,0.91c-0.25,0.32-0.61,0.47-0.94,0.69
						c0.09-0.18,0.17-0.38,0.28-0.54c0.36-0.52,0.56-1.12,0.74-1.74c0.09-0.34,0.28-0.57,0.51-0.78c0.46-0.44,0.92-0.89,1.39-1.31
						c0.35-0.32,0.58-0.74,0.85-1.13c0.39-0.56,0.75-1.14,1.13-1.71c0.06-0.09,0.13-0.18,0.2-0.27c0.06,0.31,0.09,0.58-0.02,0.87
						c-0.16,0.39-0.28,0.81-0.42,1.2c-0.08,0.21-0.04,0.37,0.1,0.52c0.24,0.26,0.46,0.55,0.71,0.79
						C647.12,1824.85,647.08,1824.92,646.97,1825.03z"/>
					<path className="st42" d="M616.14,1846.02c-0.04,0-0.07-0.01-0.09-0.02c-0.16-0.09-0.09-0.34-0.07-0.43
						c0.02-0.08,1.51-2.72,1.52-2.74c0.03-0.04,0.07-0.09,0.1-0.13c-0.01,0.01-0.03,0.02-0.04,0.02c-0.44,0.26-0.93,0.48-1.45,0.63
						c-0.3,0.09-0.6,0.22-0.88,0.34c-0.13,0.06-0.26,0.11-0.39,0.17c-0.03,0.01-0.06,0.02-0.09,0.02c-0.01,0-0.02,0-0.03,0
						l-0.12,0.02l0-0.07c-0.21,0.02-0.9,0.39-1.42,0.67c-1.04,0.55-1.48,0.76-1.65,0.66c-0.04-0.03-0.07-0.07-0.07-0.13l0-0.17
						l0.09,0.05c0.21-0.18,1.11-1.11,1.56-1.57c0.38-0.39,0.41-0.42,0.43-0.44c0.68-0.49,2.64-1.31,3.44-1.57
						c0.42-0.14,0.69-0.39,0.84-0.8c0.08-0.2,0.11-0.35,0.15-0.47c0,0,0,0,0,0c-0.09,0.16-0.23,0.31-0.4,0.46
						c-0.01,0.01-0.01,0.01-0.02,0.02c-0.08,0.1-0.16,0.12-0.24,0.06c-0.1-0.07-0.07-0.18-0.05-0.23c0-0.01,0.01-0.02,0.01-0.03
						c0.05-0.29,0.05-0.59,0.05-0.88l0-0.13c0-0.02,0-0.07,0-0.11c-0.04,0.02-0.09,0.05-0.15,0.07c-0.05,0.02-0.1,0.05-0.16,0.08
						c-0.12,0.06-0.25,0.1-0.42,0.14c-0.16,0.04-0.25,0.16-0.36,0.35c-0.03,0.05-0.05,0.1-0.07,0.15c-0.02,0.05-0.05,0.11-0.08,0.17
						l-0.01,0.01c-0.03,0.06-0.08,0.15-0.17,0.17c-0.06,0.01-0.12-0.01-0.18-0.05c-0.01,0.01-0.03,0.04-0.03,0.05l-0.01,0.02
						c-0.13,0.2-0.68,0.54-1.56,1.08c-0.83,0.5-1.96,1.2-2.03,1.46c-0.03,0.09-0.03,0.09-2.05,0.39c-1.15,0.17-2.68,0.38-4.02,0.57
						c-1.84,0.26-3.58,0.5-3.76,0.54l-0.14,0.1l0-0.19c-0.01-0.02-0.09-0.16-1.28-0.21c-0.78-0.03-1.82-0.02-2.83-0.01
						c-2.44,0.03-3.95,0.03-4.25-0.27c-0.06-0.06-0.07-0.11-0.07-0.15l0-0.08l0.08-0.01c0.4-0.07,21.39-4.28,21.6-4.33l0.04-0.01
						c0.71-0.19,1.45-0.39,2.13-0.7l0.14-0.07c0.48-0.23,0.97-0.46,1.5-0.56c0.24-0.05,0.47-0.13,0.67-0.25
						c0.49-0.28,0.96-0.61,1.42-0.94c0.16-0.11,0.32-0.23,0.48-0.34c0.28-0.19,0.53-0.43,0.78-0.66c0.25-0.23,0.5-0.47,0.78-0.67
						c0.27-0.19,0.34-0.45,0.36-0.79c0.01-0.14,0.03-0.3,0.06-0.48c0.09-0.55,0.03-0.91-0.21-1.22c-0.04-0.05-0.06-0.11-0.09-0.16
						c-0.02-0.03-0.03-0.07-0.05-0.1c-0.1-0.16-0.07-0.31,0.06-0.46c0.05-0.05,0.1-0.11,0.15-0.16c0.08-0.09,0.16-0.18,0.24-0.26
						c0.09-0.09,0.14-0.23,0.13-0.37c-0.06-0.52,0.08-1.02,0.39-1.46c0.04-0.05,0.07-0.12,0.1-0.19c0.04-0.07,0.07-0.15,0.12-0.22
						c0.12-0.17,0.11-0.34,0.04-0.54c-0.02-0.06-0.03-0.12-0.05-0.17c-0.03-0.11-0.07-0.23-0.11-0.35c-0.04-0.1-0.07-0.21-0.11-0.31
						c-0.03,0.25-0.16,0.42-0.39,0.53c-0.1,0.04-0.17,0.13-0.2,0.25c-0.08,0.3-0.26,0.5-0.44,0.67c-0.18,0.17-0.23,0.36-0.17,0.63
						c0.01,0.05,0.02,0.11,0.03,0.16c0.01,0.08,0.02,0.15,0.05,0.22c0.1,0.23-0.01,0.37-0.11,0.48c-0.28,0.3-0.37,0.62-0.26,1.01
						c0.16,0.59,0.19,1.08,0.1,1.54c0,0.01,0,0.02,0,0.03c0,0.05,0,0.15-0.1,0.19c-0.14,0.06-0.21-0.07-0.23-0.11
						c-0.01-0.01-0.01-0.02-0.02-0.03c-0.1-0.09-0.2-0.2-0.27-0.34c-0.08-0.18-0.21-0.39-0.41-0.38c-0.46,0.03-0.85-0.16-1.15-0.55
						c-0.03-0.04-0.09-0.1-0.14-0.1c-0.05,0-0.11,0.04-0.15,0.09c-0.24,0.26-0.5,0.31-0.81,0.17c-0.24-0.11-0.43-0.05-0.6,0.19
						c-0.02,0.04-0.05,0.07-0.08,0.09c-0.03,0.03-0.05,0.05-0.06,0.08c-0.27,0.45-0.61,0.59-1.09,0.45
						c-0.15-0.04-0.29-0.02-0.39,0.06c-0.11,0.09-0.16,0.26-0.14,0.47c0.03,0.27-0.14,0.35-0.24,0.37
						c-0.52,0.12-0.69,0.57-0.86,1.15c-0.01,0.04-0.01,0.07,0.04,0.13c0.13,0.17,0.2,0.27,0.18,0.38c-0.02,0.11-0.11,0.17-0.29,0.3
						c-0.02,0.01-0.04,0.03-0.06,0.04c-0.16,0.1-0.25,0.16-0.27,0.21c-0.02,0.06,0.02,0.18,0.09,0.36c0.06,0.16,0.08,0.32-0.14,0.47
						c-0.07,0.08-0.31,0.76-0.46,1.21c-0.23,0.67-0.33,0.95-0.4,1.02c-0.16,0.17-0.78,0.14-2.15,0.01
						c-0.79-0.07-1.97-0.18-2.11-0.05c-0.03,0.15-0.24,0.14-0.74,0.09c-0.38-0.03-0.9-0.08-1.34-0.02c-0.29,0.04-0.94-0.12-1.3-0.45
						c-0.17-0.16-0.26-0.34-0.25-0.54c-0.24-0.08-1.93,0.71-4.89,2.7l-0.07,0.05l-0.06-0.07c-0.06-0.07-0.06-0.07,0.28-0.44
						c0.18-0.2,0.44-0.48,0.77-0.84c0.65-0.71,1.56-1.69,2.58-2.8c3.3-3.58,8.27-8.95,8.56-9.34l0.01-0.24l0.16,0.2
						c0.16,0.19,0.36,0.95,0.66,2.09c0.18,0.69,0.37,1.4,0.46,1.56l0.01,0.02c0.1,0.17,0.21,0.35,0.22,0.59
						c0.01,0.21,0.04,0.43,0.06,0.64l0,0.03c0.03,0.23,0.06,0.46,0.09,0.69l0,0c0-0.01,0-0.01,0-0.02c0.07-0.25,0.15-0.51,0.24-0.76
						c0.21-0.58,0.25-1.04,0.13-1.5c-0.02-0.08-0.03-0.16-0.03-0.23c0-0.05-0.01-0.11-0.02-0.15l-0.01-0.04
						c-0.06-0.35-0.12-0.68-0.34-0.94c-0.08-0.1-0.16-0.2-0.24-0.3c-0.16-0.21-0.33-0.42-0.51-0.61l-0.01-0.01
						c-0.04-0.04-0.12-0.12-0.07-0.26l0.04-0.13l0.11,0.08c0.11,0.09,0.23,0.17,0.34,0.26c0.26,0.2,0.51,0.38,0.77,0.56
						c0.35,0.24,0.57,0.55,0.66,0.93c0.02,0.1,0.05,0.19,0.07,0.29l0.05,0.19c0,0,0,0,0,0.01c0.14-0.09,0.32-0.39,0.31-0.54
						c-0.02-0.31,0.05-0.59,0.22-0.85c0.05-0.08,0.1-0.16,0.15-0.24c0.05-0.08,0.1-0.17,0.16-0.25l0.01-0.01
						c0.04-0.05,0.05-0.08,0.05-0.11l-0.01-0.06c-0.05-0.43-0.09-0.84-0.29-1.21c-0.16-0.29-0.32-0.59-0.47-0.87
						c-0.07-0.13-0.13-0.25-0.2-0.38l-0.09-0.17l0.19,0.03c0.17,0.02,0.29,0.14,0.4,0.24c0.05,0.05,0.1,0.1,0.16,0.13
						c0.24,0.15,0.41,0.34,0.52,0.56c-0.03-0.12-0.07-0.24-0.12-0.35c-0.06-0.17-0.12-0.34-0.15-0.52l-0.06-0.33l0.23,0.25
						c0.04,0.05,0.09,0.09,0.13,0.14c0.29,0.3,0.58,0.62,0.65,1.12c0.03-0.07,0.06-0.15,0.1-0.22c0.07-0.16,0.14-0.32,0.21-0.47
						c0.03-0.06,0.06-0.13,0.09-0.19c0.17-0.35,0.34-0.71,0.67-0.94c0.01-0.01,0.02-0.02,0.03-0.02c0.04-0.04,0.11-0.11,0.22-0.07
						c0.12,0.05,0.12,0.17,0.12,0.23c0,0.01,0,0.03,0,0.04c0.03,0.32,0.11,0.63,0.25,0.99c0.01-0.09,0.02-0.19,0.02-0.28
						c0.03-0.33,0.1-0.75,0.41-1.07c0.13-0.14,0.27-0.29,0.4-0.43c0.09-0.1,0.17-0.19,0.26-0.28c0.06-0.07,0.09-0.12,0.09-0.19
						c-0.04-0.46,0.1-0.87,0.24-1.26c0.03-0.09,0.06-0.18,0.09-0.27c0.01-0.03,0.04-0.13,0.13-0.16c0.08-0.02,0.15,0.02,0.2,0.05
						c0.11,0.08,0.22,0.17,0.33,0.25l0.16,0.12c0.32,0.24,0.35,0.29,0.24,0.72c-0.13,0.48-0.06,0.94,0.23,1.45
						c0.1,0.18,0.11,0.34,0.03,0.51c-0.15,0.32-0.16,0.65-0.14,1.01c0.03-0.16,0.08-0.32,0.17-0.47c0.19-0.31,0.29-0.68,0.29-1.15
						c0-0.22,0.06-0.39,0.21-0.55c0.25-0.28,0.47-0.57,0.55-0.99c0.13-0.72,0.39-1.19,0.81-1.5c0.08-0.06,0.15-0.12,0.23-0.19
						c0.06-0.06,0.12-0.11,0.19-0.17c0.32-0.25,0.49-0.5,0.57-0.81l0-0.02l0.01-0.02c0.06-0.09,0.15-0.09,0.21-0.1l0.12-0.01
						l0.01,0.1c0.01,0.05,0.01,0.1,0.02,0.16c0.01,0.12,0.02,0.22,0.05,0.33c0.05,0.19,0,0.32-0.17,0.42
						c-0.04,0.02-0.09,0.05-0.13,0.08c-0.11,0.07-0.23,0.14-0.35,0.2c-0.39,0.18-0.61,0.57-0.61,1.05c0,0.42,0.05,0.85,0.11,1.27
						c0.02,0.18,0.05,0.37,0.07,0.55c0,0.03,0.01,0.05,0.01,0.05c0,0,0.01,0,0.01,0c0.01,0,0.02,0,0.02-0.01
						c0.04-0.01,0.1-0.03,0.16,0.01c0.03,0.01,0.06,0.04,0.08,0.1c0.03,0.09-0.03,0.16-0.06,0.21c-0.01,0.01-0.02,0.02-0.02,0.03
						c-0.05,0.08-0.1,0.16-0.15,0.24c-0.12,0.2-0.25,0.4-0.36,0.61c-0.17,0.34-0.22,0.6-0.13,0.86c0.13-0.21,0.25-0.43,0.36-0.65
						c0.08-0.16,0.16-0.32,0.25-0.48c0.11-0.19,0.19-0.4,0.24-0.63l0.01-0.05c0.06-0.28,0.13-0.57,0.31-0.82
						c0.38-0.53,0.78-1.05,1.18-1.56c0.14-0.17,0.27-0.35,0.41-0.52c0.31-0.4,0.67-0.71,1.12-0.93l0.17-0.09
						c0.4-0.21,0.82-0.42,1.24-0.59l0.03-0.01l0.03,0c0.14,0.02,0.25-0.03,0.42-0.18c0.14-0.13,0.28-0.26,0.41-0.39
						c0.43-0.4,0.87-0.82,1.33-1.19c0.23-0.18,0.46-0.31,0.7-0.38l0.14-0.04l-0.01,0.14c0,0.03,0,0.06,0,0.1
						c0,0.07-0.01,0.15-0.02,0.23c-0.01,0.08-0.02,0.16-0.02,0.25c-0.01,0.2-0.02,0.43-0.17,0.62c-0.09,0.11-0.17,0.23-0.25,0.35
						c-0.03,0.04-0.05,0.08-0.08,0.12l-0.04,0.06c-0.09,0.13-0.18,0.25-0.2,0.42c-0.09,0.7-0.21,1.37-0.76,1.81
						c0,0,0,0.01-0.01,0.02c-0.01,0.02-0.02,0.03-0.03,0.04c-0.22,0.27-0.3,0.61-0.39,0.96c-0.03,0.13-0.06,0.26-0.1,0.39
						c-0.02,0.08-0.04,0.16-0.06,0.24c-0.06,0.25-0.11,0.5-0.32,0.71c-0.12,0.12-0.21,0.26-0.26,0.44
						c-0.03,0.09-0.08,0.16-0.12,0.23c-0.02,0.03-0.04,0.06-0.06,0.09c0.27-0.2,0.51-0.43,0.74-0.66c0.21-0.21,0.43-0.43,0.67-0.61
						l0.37-0.28l-0.23,0.41c-0.14,0.26-0.34,0.52-0.53,0.76c-0.16,0.21-0.32,0.41-0.44,0.61c-0.03,0.04-0.05,0.09-0.07,0.13
						c-0.07,0.14-0.14,0.28-0.28,0.37c-0.28,0.19-0.5,0.41-0.73,0.64c-0.22,0.22-0.45,0.45-0.72,0.64c-0.79,0.55-1.37,1.1-1.86,1.79
						c-0.05,0.07-0.11,0.15-0.16,0.23c-0.33,0.47-0.68,0.95-1.11,1.34c-0.05,0.04-0.1,0.08-0.15,0.12c-0.13,0.1-0.25,0.19-0.3,0.34
						c-0.1,0.25-0.19,0.51-0.29,0.76c-0.06,0.16-0.12,0.32-0.18,0.47c0.05-0.06,0.1-0.13,0.14-0.19c0.24-0.32,0.48-0.64,0.75-0.94
						c0.53-0.58,1.1-1.13,1.65-1.67l0.1-0.1c0.08-0.07,0.15-0.15,0.22-0.23c0.22-0.23,0.44-0.47,0.73-0.62
						c0.42-0.22,0.86-0.41,1.28-0.6l0.18-0.08c0.04-0.02,0.08-0.03,0.13-0.05c0.12-0.04,0.23-0.08,0.29-0.17
						c0.29-0.35,0.58-0.71,0.87-1.06c0.26-0.32,0.52-0.65,0.79-0.97c0.08-0.1,0.17-0.21,0.25-0.31c0.23-0.29,0.47-0.6,0.76-0.85
						c0.4-0.35,0.78-0.77,0.84-1.45c0.02-0.26,0.14-0.46,0.34-0.59c0.19-0.13,0.37-0.28,0.55-0.42l0.11-0.09
						c0.02-0.02,0.05-0.04,0.07-0.06c0.19-0.15,0.35-0.28,0.38-0.54c0.01-0.1,0.04-0.2,0.06-0.29c0.02-0.09,0.04-0.17,0.05-0.25
						c0.13-0.86,0.46-1.5,1.01-1.96c0.11-0.09,0.18-0.21,0.21-0.38c0.05-0.23,0.11-0.47,0.17-0.7c0.04-0.15,0.08-0.31,0.11-0.46
						c0.03-0.14,0.09-0.24,0.18-0.33c0.12-0.12,0.24-0.23,0.37-0.35c0.32-0.3,0.66-0.62,0.97-0.95c0.08-0.08,0.16-0.15,0.23-0.22
						c0.15-0.13,0.29-0.26,0.39-0.42l0.02-0.03l0.04-0.01c0.08-0.02,0.15-0.04,0.23-0.06c0.17-0.05,0.34-0.09,0.51-0.15
						c0.19-0.06,0.33-0.24,0.44-0.41c0.29-0.43,0.7-0.53,0.98-0.58c0.15-0.02,0.21-0.09,0.27-0.27c0.33-1.03,0.78-1.85,1.37-2.5
						c0.17-0.19,0.38-0.35,0.64-0.49l0.11-0.06l0.04,0.12c0.06,0.2,0,0.33-0.07,0.41c-0.16,0.17-0.26,0.39-0.36,0.62l-0.04,0.1
						c-0.02,0.05-0.04,0.11-0.06,0.17c-0.05,0.16-0.1,0.34-0.26,0.42c-0.17,0.09-0.23,0.25-0.29,0.43l0.08-0.1
						c0.31-0.41,0.64-0.83,1.11-1.07l0.09,0.18c-0.43,0.22-0.73,0.61-1.04,1.02l-0.08,0.1c0.01,0.1,0,0.17-0.04,0.23
						c-0.06,0.09-0.17,0.1-0.25,0.11c-0.03,0-0.07,0.01-0.09,0.02l-0.28,0.09l0.17-0.24c0.06-0.09,0.1-0.21,0.13-0.33
						c0.06-0.22,0.14-0.47,0.4-0.6c0.09-0.04,0.12-0.17,0.16-0.3c0.02-0.07,0.04-0.14,0.07-0.2l0.04-0.1
						c0.11-0.23,0.21-0.47,0.39-0.67c0.02-0.02,0.03-0.04,0.04-0.08c-0.18,0.11-0.33,0.23-0.45,0.37c-0.57,0.63-1.01,1.42-1.33,2.43
						c-0.07,0.21-0.17,0.37-0.43,0.41c-0.4,0.06-0.66,0.22-0.85,0.5c-0.14,0.21-0.3,0.41-0.54,0.49c-0.17,0.06-0.35,0.11-0.52,0.15
						c-0.06,0.02-0.13,0.03-0.19,0.05c-0.12,0.17-0.26,0.3-0.4,0.43c-0.08,0.07-0.15,0.14-0.22,0.21c-0.31,0.33-0.65,0.65-0.97,0.95
						c-0.12,0.12-0.24,0.23-0.37,0.35c-0.06,0.06-0.1,0.13-0.13,0.23c-0.04,0.16-0.08,0.31-0.12,0.46
						c-0.06,0.23-0.12,0.46-0.17,0.69c-0.05,0.21-0.14,0.37-0.28,0.49c-0.51,0.43-0.81,1.03-0.94,1.84
						c-0.01,0.09-0.04,0.18-0.06,0.27c-0.02,0.09-0.05,0.18-0.06,0.27c-0.05,0.34-0.27,0.51-0.46,0.67
						c-0.02,0.02-0.05,0.04-0.07,0.06l-0.11,0.09c-0.18,0.15-0.37,0.31-0.57,0.44c-0.15,0.1-0.24,0.25-0.25,0.45
						c-0.06,0.75-0.49,1.22-0.9,1.58c-0.27,0.24-0.51,0.54-0.73,0.82c-0.08,0.11-0.17,0.21-0.25,0.31
						c-0.27,0.32-0.53,0.65-0.79,0.97c-0.28,0.35-0.58,0.71-0.87,1.06c-0.1,0.12-0.25,0.18-0.38,0.23
						c-0.04,0.01-0.08,0.03-0.12,0.05l-0.18,0.08c-0.42,0.18-0.85,0.37-1.27,0.59c-0.26,0.14-0.46,0.35-0.68,0.58
						c-0.08,0.08-0.15,0.16-0.23,0.23l-0.1,0.1c-0.55,0.53-1.11,1.09-1.64,1.66c-0.26,0.29-0.5,0.61-0.74,0.92
						c-0.1,0.13-0.2,0.27-0.3,0.4c-0.01,0.01-0.02,0.02-0.03,0.04c-0.03,0.05-0.07,0.11-0.15,0.14l-0.07,0.03l-0.05-0.05
						c-0.08-0.1-0.03-0.2-0.01-0.25c0-0.01,0.01-0.01,0.01-0.02c0.1-0.25,0.19-0.51,0.29-0.76c0.1-0.25,0.2-0.51,0.29-0.76
						c0.08-0.21,0.23-0.33,0.37-0.43c0.05-0.04,0.09-0.07,0.13-0.11c0.42-0.37,0.76-0.85,1.08-1.31c0.05-0.08,0.11-0.15,0.16-0.23
						c0.51-0.71,1.1-1.27,1.91-1.84c0.26-0.18,0.48-0.4,0.7-0.62c0.23-0.23,0.47-0.47,0.76-0.66c0.09-0.06,0.15-0.18,0.21-0.29
						c0.03-0.05,0.05-0.1,0.08-0.14c0.13-0.21,0.3-0.43,0.46-0.63c0-0.01,0.01-0.01,0.01-0.02c0,0,0,0,0,0
						c-0.31,0.3-0.62,0.62-1.02,0.85l-0.17,0.1l0.02-0.2c0.02-0.2,0.11-0.32,0.18-0.43c0.04-0.06,0.08-0.12,0.1-0.18
						c0.07-0.21,0.17-0.38,0.31-0.52c0.17-0.17,0.22-0.38,0.27-0.61c0.02-0.09,0.04-0.17,0.07-0.26c0.04-0.12,0.07-0.25,0.1-0.38
						c0.09-0.36,0.18-0.73,0.42-1.04c0-0.01,0.01-0.01,0.01-0.02c0.01-0.02,0.03-0.05,0.06-0.07c0.49-0.4,0.6-0.99,0.69-1.68
						c0.03-0.21,0.13-0.36,0.23-0.51l0.04-0.06c0.03-0.04,0.05-0.08,0.08-0.12c0.08-0.12,0.17-0.25,0.26-0.36
						c0.11-0.14,0.12-0.31,0.13-0.5c0-0.09,0.01-0.18,0.02-0.28c0.01-0.05,0.01-0.1,0.01-0.15c-0.17,0.07-0.33,0.17-0.5,0.3
						c-0.46,0.37-0.9,0.78-1.32,1.18c-0.14,0.13-0.28,0.26-0.42,0.39c-0.14,0.13-0.32,0.26-0.56,0.23c-0.41,0.16-0.81,0.37-1.2,0.57
						l-0.17,0.09c-0.42,0.21-0.76,0.5-1.05,0.88c-0.13,0.17-0.27,0.35-0.41,0.52c-0.39,0.5-0.79,1.02-1.17,1.55
						c-0.16,0.22-0.21,0.48-0.27,0.75l-0.01,0.05c-0.06,0.25-0.14,0.47-0.26,0.68c-0.09,0.15-0.17,0.31-0.25,0.47
						c-0.14,0.28-0.29,0.56-0.47,0.82l-0.09,0.13l-0.08-0.14c-0.26-0.47-0.1-0.89,0.06-1.21c0.11-0.22,0.24-0.43,0.37-0.63
						c0.05-0.08,0.1-0.15,0.15-0.23c0.01-0.01,0.02-0.03,0.03-0.04c0,0,0,0,0,0c-0.08,0.01-0.13-0.01-0.16-0.04
						c-0.05-0.04-0.08-0.1-0.09-0.19c-0.02-0.18-0.04-0.37-0.07-0.55c-0.05-0.42-0.11-0.86-0.11-1.3c0-0.56,0.27-1.01,0.72-1.22
						c0.11-0.06,0.23-0.12,0.34-0.19c0.04-0.03,0.09-0.05,0.13-0.08c0.08-0.05,0.11-0.08,0.08-0.19c-0.03-0.12-0.04-0.24-0.05-0.36
						c0,0,0,0,0,0c-0.13,0.42-0.41,0.68-0.62,0.84c-0.06,0.05-0.12,0.1-0.18,0.16c-0.08,0.07-0.16,0.14-0.25,0.21
						c-0.38,0.27-0.61,0.71-0.73,1.37c-0.08,0.47-0.33,0.78-0.6,1.09c-0.11,0.13-0.16,0.25-0.16,0.42c0,0.51-0.1,0.91-0.32,1.25
						c-0.11,0.18-0.15,0.41-0.18,0.64c-0.02,0.11-0.03,0.22-0.06,0.34c0,0.01,0,0.02,0,0.03c-0.01,0.06-0.02,0.16-0.16,0.22
						l-0.16,0.08l0.02-0.18c0.03-0.22,0.02-0.45,0.01-0.66c-0.01-0.39-0.03-0.8,0.16-1.19c0.06-0.12,0.05-0.2-0.02-0.32
						c-0.32-0.55-0.4-1.06-0.25-1.6c0.09-0.32,0.09-0.32-0.16-0.51l-0.16-0.12c-0.11-0.08-0.22-0.17-0.34-0.25
						c0,0-0.01-0.01-0.01-0.01c0,0.01,0,0.01-0.01,0.02c-0.03,0.09-0.06,0.18-0.1,0.28c-0.14,0.39-0.27,0.76-0.23,1.18
						c0.02,0.16-0.06,0.26-0.14,0.35c-0.09,0.09-0.17,0.19-0.26,0.28c-0.13,0.14-0.27,0.29-0.4,0.43c-0.21,0.22-0.32,0.5-0.35,0.95
						c-0.02,0.21-0.03,0.43-0.08,0.65l-0.06,0.3l-0.13-0.27c-0.24-0.52-0.37-0.94-0.41-1.38c0-0.02,0-0.04,0-0.07c0,0,0-0.01,0-0.01
						c-0.01,0.01-0.03,0.03-0.04,0.04c-0.29,0.21-0.44,0.53-0.61,0.87c-0.03,0.06-0.06,0.13-0.1,0.19
						c-0.08,0.15-0.14,0.31-0.21,0.46c-0.03,0.08-0.07,0.16-0.1,0.24c0,0.01-0.01,0.02-0.01,0.03c-0.02,0.05-0.06,0.17-0.19,0.16
						c-0.15-0.01-0.16-0.17-0.17-0.22l0-0.02c-0.04-0.37-0.23-0.63-0.45-0.87c0.01,0.03,0.02,0.07,0.04,0.1
						c0.1,0.28,0.21,0.58,0.16,0.92l-0.01,0.1l-0.1-0.02c-0.15-0.03-0.18-0.14-0.19-0.19l0-0.01c-0.08-0.32-0.26-0.58-0.56-0.77
						c-0.07-0.04-0.13-0.1-0.19-0.15c-0.01-0.01-0.02-0.02-0.03-0.03c0.01,0.03,0.03,0.05,0.04,0.08c0.15,0.29,0.3,0.58,0.46,0.87
						c0.22,0.4,0.27,0.85,0.32,1.29l0.01,0.06c0.01,0.11-0.04,0.19-0.08,0.25l-0.01,0.01c-0.05,0.08-0.1,0.16-0.16,0.24
						c-0.05,0.08-0.1,0.16-0.16,0.24c-0.15,0.22-0.21,0.46-0.19,0.72c0.02,0.26-0.25,0.67-0.45,0.76c-0.06,0.02-0.11,0.03-0.15,0
						c-0.07-0.04-0.09-0.12-0.1-0.16l-0.05-0.19c-0.02-0.1-0.05-0.2-0.07-0.29c-0.08-0.34-0.26-0.6-0.58-0.81
						c-0.21-0.14-0.41-0.3-0.62-0.45c0.08,0.09,0.15,0.19,0.22,0.28c0.08,0.1,0.16,0.2,0.24,0.29c0.25,0.3,0.32,0.67,0.38,1.03
						l0.01,0.04c0.01,0.06,0.02,0.12,0.02,0.17c0.01,0.07,0.01,0.14,0.03,0.2c0.13,0.5,0.09,1.01-0.14,1.62
						c-0.09,0.24-0.16,0.5-0.23,0.75c-0.03,0.11-0.07,0.23-0.1,0.34l-0.02,0.08l-0.22-0.02l-0.05-0.41
						c-0.03-0.23-0.06-0.46-0.09-0.69l0-0.03c-0.03-0.21-0.05-0.43-0.06-0.65c-0.01-0.19-0.1-0.34-0.19-0.5l-0.01-0.02
						c-0.11-0.18-0.28-0.84-0.48-1.61c-0.19-0.71-0.41-1.57-0.56-1.9c-0.37,0.46-1.95,2.19-8.5,9.27c-1.29,1.39-2.45,2.65-3.1,3.36
						c1.3-0.85,3.99-2.53,4.45-2.27c0.06,0.04,0.09,0.1,0.09,0.18c-0.01,0.14,0.05,0.26,0.18,0.39c0.32,0.3,0.92,0.43,1.14,0.4
						c0.46-0.06,1-0.01,1.39,0.02c0.21,0.02,0.46,0.04,0.54,0.02c0.11-0.25,0.78-0.21,2.31-0.07c0.74,0.07,1.85,0.17,1.98,0.05
						c0.05-0.06,0.22-0.56,0.36-0.96c0.32-0.94,0.44-1.24,0.54-1.31c0.1-0.07,0.11-0.11,0.06-0.24c-0.17-0.49-0.15-0.54,0.26-0.81
						c0.02-0.01,0.04-0.02,0.05-0.03c0.1-0.07,0.2-0.14,0.21-0.17c0-0.03-0.07-0.13-0.14-0.23c-0.05-0.07-0.11-0.17-0.07-0.31
						c0.14-0.48,0.33-1.13,1.01-1.28c0.04-0.01,0.09-0.02,0.08-0.16c-0.04-0.36,0.09-0.55,0.21-0.65c0.15-0.12,0.36-0.16,0.58-0.09
						c0.39,0.11,0.64,0.01,0.87-0.36c0.03-0.04,0.06-0.08,0.09-0.11c0.02-0.02,0.04-0.04,0.06-0.07c0.22-0.31,0.52-0.4,0.85-0.25
						c0.22,0.1,0.4,0.07,0.58-0.13c0.1-0.1,0.2-0.15,0.3-0.15c0.1,0.01,0.2,0.06,0.29,0.18c0.26,0.34,0.58,0.5,0.98,0.48
						c0.17-0.01,0.42,0.07,0.6,0.5c0.05,0.11,0.14,0.2,0.22,0.28c0.01,0.01,0.01,0.01,0.02,0.02c0.08-0.42,0.05-0.88-0.1-1.44
						c-0.12-0.46-0.02-0.85,0.31-1.2c0.1-0.1,0.12-0.15,0.07-0.26c-0.04-0.09-0.05-0.18-0.06-0.27c-0.01-0.05-0.01-0.1-0.02-0.15
						c-0.08-0.34-0.01-0.61,0.23-0.83c0.19-0.18,0.33-0.34,0.39-0.58c0.05-0.17,0.15-0.31,0.3-0.38c0.23-0.11,0.32-0.3,0.27-0.63
						l0-0.02c-0.01-0.05-0.02-0.14,0.06-0.25l0.11-0.15l0.15,0.42c0.07,0.18,0.13,0.36,0.19,0.53c0.04,0.12,0.07,0.24,0.11,0.35
						c0.02,0.06,0.03,0.11,0.05,0.17c0.06,0.22,0.11,0.47-0.07,0.72c-0.04,0.06-0.07,0.12-0.11,0.19c-0.04,0.07-0.07,0.15-0.12,0.21
						c-0.29,0.41-0.41,0.84-0.36,1.33c0.02,0.2-0.05,0.4-0.19,0.54c-0.08,0.08-0.15,0.16-0.23,0.25c-0.05,0.06-0.1,0.11-0.15,0.17
						c-0.08,0.08-0.09,0.14-0.04,0.22c0.02,0.04,0.04,0.08,0.06,0.12c0.02,0.05,0.04,0.09,0.06,0.12c0.26,0.33,0.31,0.7,0.29,1.03
						c0.04-0.12,0.09-0.23,0.16-0.34c0.16-0.26,0.12-0.54,0.08-0.83c-0.01-0.05-0.01-0.09-0.02-0.14c-0.03-0.2-0.06-0.41-0.02-0.63
						c0.05-0.24,0.15-0.46,0.24-0.67c0.03-0.06,0.06-0.12,0.09-0.19c0.1-0.23,0.13-0.41,0.09-0.59c-0.03-0.18,0.01-0.32,0.15-0.45
						c0.07-0.07,0.14-0.13,0.2-0.2c0.07-0.07,0.14-0.14,0.21-0.21c0.06-0.06,0.08-0.09,0.08-0.11c0-0.01,0-0.03-0.05-0.07
						c-0.18-0.15-0.06-0.31-0.01-0.37c0.34-0.47,0.74-0.88,1.12-1.28c0.2-0.21,0.41-0.42,0.61-0.65c0.04-0.04,0.11-0.12,0.24-0.09
						l0.11,0.03l-0.04,0.11c-0.25,0.66-0.61,1.24-0.95,1.8l-0.05,0.09c-0.09,0.15-0.14,0.32-0.16,0.56
						c-0.02,0.34-0.04,0.67-0.05,0.93c-0.01,0.12-0.04,0.23-0.11,0.34c-0.19,0.3-0.37,0.59-0.55,0.89c-0.12,0.2-0.25,0.4-0.37,0.6
						c-0.05,0.09-0.08,0.17-0.07,0.26c0,0.06,0,0.11,0,0.17c0,0.08-0.01,0.16,0,0.23l0,0c0.04,0.38-0.04,0.72-0.25,1.01
						c-0.2,0.28-0.24,0.63-0.26,0.99c-0.01,0.18-0.05,0.36-0.28,0.45c-0.05,0.02-0.1,0.05-0.15,0.07c-0.09,0.05-0.18,0.1-0.29,0.12
						l-0.08,0.02l-0.03-0.08c-0.02-0.04-0.02-0.08-0.02-0.11c-0.03,0.03-0.06,0.05-0.1,0.08c-0.27,0.19-0.52,0.42-0.76,0.65
						c-0.25,0.24-0.51,0.48-0.8,0.68c-0.16,0.11-0.32,0.22-0.48,0.34c-0.46,0.33-0.94,0.66-1.43,0.95
						c-0.22,0.13-0.47,0.22-0.73,0.27c-0.51,0.1-0.99,0.33-1.45,0.55l-0.14,0.07c-0.69,0.33-1.44,0.52-2.16,0.72l-0.04,0.01
						c-0.28,0.07-19.37,3.89-21.44,4.3c0.13,0.05,0.45,0.12,1.19,0.15c0.78,0.03,1.82,0.02,2.83,0.01c2.44-0.03,3.95-0.03,4.25,0.27
						c0.01,0.01,0.02,0.03,0.03,0.04c0.25-0.05,1.04-0.16,3.71-0.54c2.35-0.33,5.54-0.78,5.93-0.87c0.16-0.31,0.94-0.81,2.1-1.52
						c0.68-0.42,1.39-0.85,1.5-1.02l0.01-0.02c0.04-0.06,0.09-0.13,0.17-0.15c0.04-0.01,0.09,0,0.15,0.05
						c0.01,0.01,0.02,0.01,0.02,0.01c0.01-0.01,0.03-0.04,0.04-0.06l0.01-0.01c0.03-0.05,0.05-0.1,0.07-0.15
						c0.02-0.06,0.05-0.11,0.08-0.17c0.1-0.19,0.24-0.39,0.49-0.45c0.15-0.04,0.27-0.08,0.38-0.13c0.06-0.03,0.11-0.06,0.15-0.08
						c0.18-0.09,0.27-0.14,0.36-0.09c0.09,0.05,0.09,0.17,0.09,0.3l0,0.13c0,0.29,0,0.59-0.04,0.88c0.26-0.23,0.41-0.47,0.46-0.74
						c0.1-0.55,0.46-0.79,0.79-0.96c0.12-0.06,0.23-0.12,0.36-0.18l0.43-0.22l-0.1,0.25c-0.07,0.18-0.4,0.51-0.75,0.84
						c-0.04,0.04-0.07,0.07-0.09,0.08c-0.31,0.31-0.34,0.44-0.41,0.71c-0.03,0.13-0.07,0.28-0.16,0.49
						c-0.18,0.47-0.49,0.76-0.97,0.92c-0.79,0.26-2.72,1.07-3.39,1.54c-0.02,0.02-0.19,0.2-0.4,0.41c-0.8,0.82-1.21,1.24-1.43,1.45
						c0.3-0.11,0.84-0.4,1.26-0.62c1.04-0.55,1.48-0.76,1.65-0.66c0.01,0.01,0.02,0.01,0.02,0.02c0,0,0.01,0,0.01,0
						c0.13-0.05,0.26-0.11,0.39-0.16c0.29-0.13,0.59-0.26,0.9-0.35c0.5-0.15,0.97-0.36,1.4-0.61c0.04-0.02,0.08-0.05,0.11-0.07
						c0.12-0.07,0.23-0.15,0.36-0.17l0.25-0.04l-0.25,0.33c-0.09,0.11-0.18,0.23-0.27,0.36c-0.01,0.01-1.43,2.54-1.49,2.68
						c-0.03,0.12-0.03,0.17-0.02,0.19c0.07,0,0.44-0.14,1.94-1.81c0.05-0.06,0.08-0.09,0.09-0.1c0.06-0.08,0.69-0.86,1.1-1.05
						c0.02-0.02,0-0.14-0.01-0.19c-0.02-0.11-0.03-0.22,0.03-0.29c0.02-0.03,0.07-0.05,0.14-0.04c0.29,0.05,0.57,0.14,0.84,0.23
						l0.15,0.05c0.23,0.08,0.41,0.1,0.6,0c0.2-0.1,0.4-0.18,0.6-0.25c0.19-0.07,0.39-0.14,0.57-0.24c0.04-0.02,0.1-0.05,0.17-0.03
						c0.03,0.01,0.07,0.03,0.1,0.09c0.02,0.04,0.03,0.09,0.01,0.14c-0.02,0.05-0.05,0.09-0.1,0.11c-0.11,0.05-0.2,0.15-0.29,0.25
						c0.13-0.09,0.26-0.19,0.4-0.27c0.16-0.1,0.32-0.21,0.48-0.31c0.4-0.27,0.81-0.54,1.26-0.74c0.16-0.07,0.29-0.14,0.41-0.23
						c0.6-0.42,1.15-0.92,1.65-1.38c0.21-0.19,0.52-0.49,0.95-0.21c0.09,0.06,0.2,0.04,0.31-0.04c0.65-0.5,1.24-0.95,1.8-1.46
						c0.01-0.01,0.01-0.01,0.02-0.02c0.05-0.05,0.14-0.14,0.29-0.09l0.09,0.03l-0.03,0.09c-0.02,0.07-0.05,0.12-0.08,0.17
						c-0.05,0.08-0.08,0.15-0.07,0.23c0,0.02,0,0.05,0,0.07c0.01-0.01,0.01-0.01,0.02-0.02c0.05-0.05,0.1-0.1,0.15-0.15
						c0.15-0.15,0.31-0.31,0.48-0.44l0.03-0.03c0.08-0.07,0.13-0.1,0.14-0.18c-0.04-0.09-0.04-0.19-0.04-0.27l0-0.05
						c0-0.16,0-0.32,0-0.49c0-0.3,0-0.6,0-0.9c0-0.24,0.09-0.36,0.3-0.4c0.19-0.03,0.39-0.08,0.58-0.12
						c0.13-0.03,0.26-0.06,0.4-0.09c0.08-0.02,0.12-0.06,0.17-0.15c0.16-0.3,0.32-0.6,0.48-0.9c0.09-0.17,0.19-0.35,0.28-0.52
						c0.07-0.13,0.19-0.3,0.47-0.27c0.22,0.03,0.42,0.03,0.59,0.01c0.11-0.01,0.26-0.02,0.33,0.08c0.08,0.1,0.02,0.24,0,0.28
						c-0.05,0.11-0.05,0.21-0.01,0.31c0.09,0.21,0.01,0.36-0.07,0.49c-0.23,0.33-0.53,0.76-0.89,1.15c0,0,0.01,0,0.01-0.01
						c0.28-0.15,0.56-0.32,0.82-0.5c0.32-0.21,0.65-0.42,0.99-0.58c0.08-0.04,0.24-0.11,0.39,0.04c0.26,0.27,0.42,0.54,0.5,0.84
						l0.02,0.02c0.07,0.02,0.13,0.07,0.18,0.14c0.08,0.12,0.17,0.12,0.33,0.1c0.12-0.01,0.24-0.07,0.36-0.13
						c0.04-0.02,0.09-0.04,0.13-0.06c0.07-0.06,0.1-0.15,0.14-0.25c0.01-0.02,0.02-0.04,0.03-0.06c0.08-0.2,0.22-0.3,0.43-0.32
						c0.25-0.02,0.48,0.22,0.7,0.46c0.19,0.2,0.38,0.4,0.53,0.4c0.08-0.01,0.14-0.03,0.2-0.1c0.12-0.13,0.27-0.52,0.4-0.87
						c0.14-0.39,0.28-0.76,0.43-0.92c0.03-0.03,0.07-0.04,0.12-0.03c0.09,0.03,0.11,0.1,0.18,1.09c0.02,0.33,0.06,0.78,0.08,0.87
						c0.09,0.09,0.19,0.18,0.29,0.28c0-0.15,0-0.27,0-0.4c0-0.08,0.01-0.61,0.04-1.13c0.06-1.1,0.13-1.11,0.23-1.12
						c0.11-0.01,0.21-0.03,0.32-0.05c0.14-0.02,0.28-0.05,0.42-0.07c0.06-0.01,0.07-0.02,0.08-0.02c0,0,0-0.02-0.03-0.09l-0.12-0.29
						c-0.1-0.23-0.2-0.46-0.3-0.68c0-0.01-0.01-0.01-0.01-0.02c-0.02-0.05-0.07-0.14,0-0.23c0.08-0.1,0.19-0.05,0.23-0.04
						c0.01,0,0.01,0.01,0.02,0.01c0.07,0.02,0.14,0.04,0.2,0.07c0.16,0.05,0.33,0.1,0.5,0.17c0.03,0.01,0.06,0.03,0.09,0.05
						c-0.14-0.21-0.27-0.42-0.39-0.63c-0.13-0.22-0.26-0.44-0.41-0.65c-0.03-0.05-0.07-0.11-0.05-0.18c0.02-0.06,0.07-0.1,0.12-0.12
						c0.19-0.1,0.36-0.08,0.51,0.06c0.09,0.08,0.19,0.17,0.28,0.24c0.04,0.03,0.08,0.07,0.12,0.1c0.01-0.2-0.01-0.35-0.07-0.48
						c-0.09-0.21-0.12-0.37-0.07-0.48c0.05-0.12,0.16-0.16,0.24-0.19c0.04-0.02,0.08-0.03,0.11-0.05c0.06-0.05,0.11-0.06,0.17-0.05
						c0.1,0.02,0.15,0.11,0.19,0.18l0.01,0.02c0.34,0.55,0.69,1.1,1.03,1.65l0.07,0.12l-0.58-2.41l0.2,0.11
						c0.16,0.09,0.26,0.22,0.34,0.34l0.04,0.05c0.18,0.25,0.37,0.5,0.55,0.74c0.15,0.2,0.29,0.41,0.44,0.61
						c0.19,0.26,0.29,0.56,0.38,0.85c0.02,0.06,0.04,0.13,0.06,0.19c0.06,0.17,0.12,0.27,0.23,0.34c0.22,0.15,0.75,1.34,1.61,3.28
						c0.62,1.41,1.4,3.17,1.59,3.29c0.09,0.05,0.17,0.12,0.18,0.21c0.01,0.09-0.05,0.16-0.12,0.22c0.01,0.01,0.02,0.01,0.02,0.01
						c0.09-0.02,0.18-0.03,0.27-0.04c0.2-0.03,0.38-0.05,0.54-0.15c0.08-0.05,0.16-0.1,0.24-0.14c0.28-0.16,0.54-0.31,0.74-0.56
						c0.04-0.06,0.11-0.08,0.14-0.09c0.02-0.01,0.04-0.01,0.06-0.02c-0.15,0.02-0.3,0.02-0.44,0.02c-0.04,0-0.09,0-0.14,0
						c-0.01,0-0.01,0-0.02,0c-0.15,0.01-0.19-0.07-0.2-0.14c-0.01-0.06,0.02-0.13,0.09-0.2c0.17-0.19,0.34-0.37,0.49-0.53
						c0.07-0.08,0.13-0.14,0.12-0.23c-0.02-0.24-0.25-1.18-0.52-2.26c-0.69-2.8-1.24-5.15-1.07-5.62c-0.14,0.08-0.28,0.15-0.44,0.24
						l-0.4,0.22l0.05-0.22c0.03-0.13,0.06-0.25,0.09-0.37c0.06-0.26,0.12-0.5,0.17-0.74c0.1-0.49,0.34-0.83,0.75-1.06
						c0.03-0.02,0.06-0.03,0.09-0.06c-0.18-0.01-0.35,0-0.5,0.1c-0.14,0.09-0.28,0.19-0.42,0.29c-0.06,0.04-0.12,0.08-0.18,0.13
						c-0.01,0-0.01,0.01-0.01,0.01c-0.02,0.02-0.1,0.1-0.2,0.05c-0.12-0.06-0.1-0.18-0.09-0.23l0-0.02
						c0.04-0.26,0.15-0.47,0.27-0.68c0.03-0.05,0.05-0.1,0.08-0.15c0.05-0.09,0.09-0.17,0.14-0.26c0.12-0.23,0.25-0.46,0.36-0.71
						c0.04-0.1,0.05-0.17,0.03-0.21c-0.23-0.48-0.17-0.96-0.12-1.43c0.01-0.12,0.03-0.24,0.04-0.35c0.04-0.55,0.36-0.85,0.67-1.13
						l0.04-0.03c0.05-0.05,0.1-0.09,0.15-0.14c0.11-0.1,0.22-0.2,0.32-0.3c0.05-0.05,0.12-0.11,0.21-0.1
						c0.09,0.01,0.16,0.09,0.2,0.15c0.05,0.07,0.09,0.15,0.13,0.22c0.01,0.02,0.02,0.04,0.03,0.06c-0.01-0.05-0.02-0.1-0.04-0.15
						c-0.13-0.39-0.09-0.77,0.13-1.21c0.13-0.27,0.25-0.55,0.36-0.87c0.04-0.1,0.1-0.24,0.29-0.25c0.04,0,0.07-0.01,0.11-0.01
						c0.06-0.01,0.12-0.02,0.18-0.01c0.19,0.01,0.32-0.11,0.47-0.26c0.07-0.06,0.14-0.13,0.21-0.18c0.02-0.01,0.03-0.03,0.05-0.05
						c0.06-0.06,0.16-0.15,0.28-0.09c0.15,0.07,0.13,0.22,0.12,0.32c0,0.03-0.01,0.06-0.01,0.08c0.01,0.39,0.02,0.78,0.03,1.17
						c0,0.03,0,0.05,0,0.08c0.05-0.31,0.1-0.61,0.15-0.92l0.06-0.35c0.03-0.19,0.12-0.33,0.21-0.47c0.05-0.08,0.09-0.15,0.13-0.22
						c0.04-0.1,0.1-0.18,0.15-0.28c0.03-0.04,0.05-0.09,0.08-0.13l0.1-0.17l0.08,0.19c0.09,0.22,0.07,0.43,0.04,0.62
						c-0.06,0.49,0.06,0.76,0.42,0.89c0.2,0.08,0.24,0.27,0.24,0.43c0.02,0.61,0.03,1.22,0.03,1.68c0,0.13,0,0.27-0.05,0.41
						c-0.09,0.28-0.15,0.58-0.22,0.87c-0.02,0.11-0.05,0.23-0.08,0.35c0.06-0.13,0.12-0.25,0.18-0.37c0-0.01,0-0.01,0.01-0.02
						c0.01-0.03,0.04-0.14,0.15-0.14c0.1,0,0.16,0.06,0.17,0.18l0,0.02c0.01,0.07,0.03,0.15,0.04,0.22c0.05,0.26,0.09,0.54,0.16,0.8
						c0.05,0.18-0.05,0.32-0.11,0.39c-0.3,0.41-0.63,0.81-0.98,1.19c-0.1,0.11-0.1,0.14-0.03,0.22c0.13,0.14,0.13,0.32,0.02,0.53
						c-0.13,0.23-0.48,0.83-0.86,1.46c-0.48,0.82-1.03,1.74-1.19,2.04c-0.05,0.08-0.06,0.12-0.06,0.14
						c0.01,0.01,0.05,0.02,0.13,0.05c0.01,0,0.02,0,0.02,0.01c0.01,0,0.01,0,0.02,0c0.65,0.16,0.68,0.21,0.62,0.94
						c0.25-0.32,0.76-1.11,1.01-1.48c0.19-0.29,0.26-0.39,0.28-0.42c0.07-0.08,0.14-0.17,0.21-0.25c0.19-0.23,0.38-0.46,0.59-0.67
						c0.17-0.18,0.36-0.39,0.53-0.64c0.02-0.07,0.05-0.14,0.07-0.21c0.06-0.16,0.11-0.32,0.16-0.49c0.13-0.49,0.4-0.85,0.86-1.14
						c0.24-0.15,0.43-0.14,0.63,0.04c0.2,0.17,0.35,0.26,0.54,0.21c0.03-0.01,0.05-0.01,0.08-0.02c0.05-0.01,0.1-0.03,0.16-0.04
						c-0.04-0.02-0.08-0.04-0.12-0.06c-0.05-0.02-0.11-0.05-0.16-0.09c-0.03-0.02-0.06-0.03-0.1-0.04
						c-0.09-0.04-0.22-0.08-0.26-0.23c-0.01-0.04-0.02-0.09-0.04-0.13c-0.1-0.36-0.2-0.73-0.04-1.14c0.14-0.37,0.3-0.74,0.46-1.1
						c0.08-0.19,0.16-0.38,0.24-0.57c0.02-0.04,0.06-0.15,0.16-0.16c0.1-0.01,0.16,0.08,0.19,0.13c0.03,0.05,0.06,0.09,0.09,0.14
						c0.11,0.17,0.23,0.34,0.35,0.49c0.17,0.2,0.25,0.42,0.28,0.69c0.02,0.19,0.04,0.39,0.07,0.58c0.11-0.39,0.22-0.77,0.32-1.14
						c0.02-0.09,0.02-0.19-0.02-0.29c-0.19-0.5-0.16-0.91,0.1-1.26c0.04-0.06,0.04-0.11,0-0.21c-0.11-0.22-0.21-0.45-0.31-0.67
						l-0.11-0.24c-0.01-0.01-0.01-0.02-0.02-0.03c-0.04-0.07-0.09-0.18-0.02-0.3c0.11-0.18,0.22-0.36,0.33-0.53l0.01-0.02
						c0.02-0.03,0.07-0.11,0.15-0.11c0.05,0,0.1,0.03,0.15,0.09c0.06,0.08,0.12,0.16,0.18,0.24c0.23,0.29,0.47,0.59,0.51,1.05
						c0.01,0.11,0.03,0.22,0.06,0.34l0.03-0.76c0-0.12-0.01-0.23-0.05-0.36c-0.1-0.32-0.2-0.65-0.29-0.97
						c-0.06-0.21-0.12-0.41-0.18-0.62c-0.04-0.12-0.03-0.24,0.02-0.35l0.01-0.03c0.08-0.18,0.16-0.37,0.23-0.55
						c0.01-0.04,0.06-0.15,0.16-0.16c0.1-0.01,0.16,0.08,0.18,0.11c0.04,0.06,0.08,0.11,0.12,0.17c0.13,0.18,0.27,0.37,0.36,0.6
						c0.01-0.2-0.02-0.38-0.15-0.55c-0.06-0.07-0.09-0.16-0.13-0.24l-0.01-0.03c-0.19-0.4-0.2-0.47,0.14-0.77
						c0.04-0.04,0.06-0.06,0.06-0.07c-0.01-0.01-0.03-0.02-0.07-0.05c-0.94-0.58-1.76-0.98-2.69-1.06l-0.01,0
						c-0.31-0.03-0.67-0.06-0.96-0.29c-0.61-0.49-1.23-1.1-1.95-1.92c-0.16-0.18-0.28-0.24-0.46-0.22
						c-0.06,0.03-0.14,0.03-0.17,0.02l-0.52,0.03l-0.03-0.02l-0.24-0.16c-0.03-0.04-0.06-0.07-0.1-0.11
						c-0.07-0.08-0.15-0.17-0.22-0.27c-0.22-0.34-0.49-0.54-0.82-0.62c-0.01,0-0.02-0.01-0.03-0.01c-0.02,0-0.04-0.01-0.05-0.01
						c0,0-0.01,0.01-0.01,0.02c-0.08,0.19-0.17,0.38-0.25,0.58c-0.19,0.43-0.38,0.88-0.56,1.32c-0.02,0.04-0.04,0.08-0.06,0.12
						c-0.01,0.01-0.01,0.02-0.02,0.03c0.06-0.05,0.12-0.07,0.19-0.06c0.1,0.03,0.14,0.13,0.18,0.26c0.05,0.17,0.03,0.33,0,0.48
						l-0.14,0.64c0.2-0.29,0.41-0.59,0.61-0.88c0.01-0.02,0.03-0.04,0.04-0.06c0.05-0.07,0.1-0.16,0.18-0.22l0,0l0.03-0.15
						l0.24,0.18l-0.09,0.08c0,0.01,0,0.01,0,0.02c-0.01,0.04-0.01,0.09-0.03,0.14c-0.08,0.17-0.16,0.34-0.25,0.52
						c-0.08,0.17-0.17,0.35-0.25,0.52c-0.02,0.05-0.05,0.1-0.07,0.14c-0.15,0.3-0.28,0.55-0.08,0.88c0.06,0.1,0.04,0.17,0.03,0.21
						c-0.03,0.07-0.1,0.11-0.2,0.13c-0.08,0.01-0.17,0.03-0.25,0.05c-0.14,0.03-0.28,0.06-0.43,0.07c-0.18,0.01-0.34,0.09-0.49,0.24
						c-0.03,0.03-0.07,0.07-0.1,0.1c-0.1,0.1-0.21,0.2-0.31,0.31c-0.3,0.34-0.61,0.59-0.95,0.75c-0.04,0.02-0.07,0.03-0.11,0.05
						c-0.19,0.09-0.38,0.18-0.52,0.33c-0.19,0.2-0.37,0.41-0.55,0.62c-0.11,0.13-0.22,0.26-0.33,0.38
						c-0.01,0.01-0.02,0.02-0.03,0.04c-0.03,0.05-0.07,0.12-0.15,0.13c-0.03,0.01-0.08,0-0.13-0.04c-0.04-0.04-0.07-0.08-0.07-0.12
						c-0.01-0.08,0.04-0.13,0.07-0.17c0.01-0.01,0.02-0.03,0.03-0.04c0.01-0.02,0.02-0.04,0.03-0.06c0.01-0.02,0.02-0.04,0.02-0.05
						c0.1-0.55,0.37-1.01,0.84-1.39c0.01-0.01,0.02-0.02,0.03-0.03c0.05-0.05,0.16-0.15,0.29-0.06c0.04,0.03,0.07,0.04,0.08,0.03
						c0.03-0.01,0.08-0.09,0.11-0.16c0.05-0.1,0.11-0.2,0.16-0.3c0.03-0.05,0.06-0.1,0.09-0.16c0.06-0.12,0.13-0.24,0.18-0.36
						c0.2-0.41,0.41-0.83,0.8-1.11l-0.09-0.01c-0.11-0.02-0.22-0.03-0.33-0.05c-0.13-0.03-0.29-0.09-0.27-0.36
						c0.02-0.19,0.06-0.37,0.11-0.56c-0.48,0.29-0.8,0.64-0.99,1.08c-0.06,0.14-0.14,0.27-0.21,0.39c-0.03,0.05-0.07,0.11-0.1,0.17
						l-0.21,0.36l0.02-0.41c0.02-0.43,0.08-0.84,0.14-1.26c0.02-0.11,0.03-0.22,0.02-0.25c-0.02-0.01-0.13-0.02-0.17-0.02
						c-0.08-0.01-0.17-0.02-0.25-0.04c-0.2-0.04-0.38-0.07-0.54,0.03c-0.29,0.2-0.59,0.19-0.88,0.17c-0.15-0.01-0.29-0.01-0.43,0.01
						l-0.26,0.04l0.17-0.21c0.13-0.16,0.28-0.26,0.43-0.36c0.08-0.06,0.16-0.11,0.24-0.17c0.22-0.18,0.5-0.36,0.85-0.29
						c0.06,0.01,0.08,0.02,0.14-0.15c0.08-0.22,0.03-0.43-0.03-0.65c-0.02-0.09-0.05-0.19-0.06-0.29c-0.06,0.04-0.12,0.08-0.19,0.11
						c-0.25,0.14-0.45,0.25-0.43,0.63c0,0.04,0.01,0.14-0.07,0.2c-0.09,0.05-0.19-0.01-0.23-0.03c-0.11-0.06-0.22-0.13-0.33-0.19
						c-0.07-0.04-0.15-0.08-0.22-0.13c-0.06-0.03-0.11-0.06-0.15-0.08c-0.24-0.13-0.24-0.18-0.19-0.47c0.01-0.05,0.02-0.1,0.03-0.17
						c0.05-0.3,0.03-0.49-0.18-0.64c-0.26-0.19-0.33-0.43-0.19-0.71c0.02-0.05,0.04-0.12,0.05-0.19c0.01-0.05,0.02-0.09,0.03-0.14
						l0.01-0.06c0.07-0.28,0.14-0.54,0.06-0.8c-0.07-0.21-0.02-0.37,0.15-0.48c0.06-0.04,0.12-0.08,0.18-0.13
						c0.09-0.09,0.17-0.12,0.25-0.11c0.12,0.02,0.19,0.12,0.25,0.23c0.1,0.18,0.21,0.36,0.31,0.53c0.19,0.33,0.4,0.66,0.57,1.01
						c0.13,0.26,0.29,0.35,0.53,0.41c-0.02-0.02-0.04-0.05-0.06-0.07c-0.03-0.03-0.06-0.07-0.09-0.1c-0.06-0.08-0.12-0.15-0.19-0.22
						c-0.16-0.17-0.32-0.35-0.4-0.6c-0.04-0.13-0.1-0.26-0.16-0.39c-0.07-0.15-0.14-0.3-0.19-0.46c-0.07-0.22-0.17-0.39-0.3-0.5
						c-0.03-0.03-0.06-0.05-0.1-0.08c-0.11-0.08-0.23-0.16-0.27-0.32c-0.03-0.15,0.05-0.26,0.12-0.35c0.04-0.05,0.08-0.1,0.09-0.15
						l0.01-0.03l0.14-0.23l0.04-0.01c0.05-0.01,0.11-0.05,0.17-0.08c0.15-0.09,0.36-0.22,0.59,0.01c0.01-0.01,0.03-0.01,0.04-0.02
						c0.13-0.05,0.21-0.17,0.29-0.3l0.02-0.03c-0.02,0-0.04,0.01-0.05,0.01c-0.15,0.03-0.3,0.06-0.44,0.1
						c-0.16,0.04-0.32,0.08-0.49,0.11c-0.08,0.01-0.18,0.08-0.27,0.14c-0.03,0.02-0.06,0.04-0.09,0.06
						c-0.12,0.07-0.24,0.15-0.38,0.25c-0.06,0.93-0.41,1.58-1.06,1.99l-0.1-0.17c0.6-0.37,0.92-0.99,0.97-1.88l0-0.05l0.04-0.03
						c0.16-0.11,0.3-0.2,0.43-0.28c0.03-0.02,0.06-0.04,0.08-0.06c0.11-0.07,0.23-0.15,0.35-0.17c0.16-0.03,0.32-0.07,0.47-0.11
						c0.15-0.04,0.3-0.08,0.46-0.1c0.16-0.03,0.33-0.06,0.49-0.08c0.08-0.01,0.16-0.02,0.24-0.03c0.01,0,0.03,0,0.06,0
						c0.03,0,0.12,0,0.14-0.01c0,0-0.02,0.01-0.04,0.04v0l0.17,0.1c0,0,0,0,0,0c-0.03,0.05-0.06,0.08-0.09,0.12
						c-0.02,0.02-0.04,0.04-0.05,0.07c-0.13,0.18-0.19,0.26-0.3,0.26c0,0,0,0,0,0c-0.1,0-0.17-0.09-0.28-0.25
						c-0.01-0.01-0.01-0.02-0.02-0.02c-0.01,0.01-0.02,0.03-0.03,0.05l-0.04,0.07c-0.09,0.15-0.2,0.32-0.39,0.39
						c-0.01,0-0.01,0.01-0.02,0.01c-0.03,0.02-0.13,0.07-0.21-0.02c-0.12-0.14-0.2-0.1-0.36,0c-0.06,0.04-0.12,0.07-0.19,0.1
						l-0.09,0.15c-0.03,0.08-0.08,0.15-0.12,0.2c-0.06,0.08-0.1,0.13-0.08,0.19c0.02,0.08,0.1,0.14,0.19,0.2
						c0.04,0.03,0.08,0.06,0.12,0.09c0.16,0.14,0.28,0.34,0.36,0.6c0.05,0.15,0.11,0.29,0.18,0.44c0.06,0.13,0.12,0.26,0.17,0.41
						c0.07,0.21,0.21,0.37,0.36,0.53c0.07,0.07,0.14,0.15,0.2,0.23c0.02,0.03,0.05,0.06,0.08,0.09c0.07,0.08,0.16,0.17,0.21,0.32
						l0.06,0.17l-0.17-0.03c-0.33-0.06-0.68-0.13-0.89-0.56c-0.17-0.34-0.37-0.67-0.56-1c-0.1-0.17-0.21-0.35-0.31-0.53
						c-0.07-0.12-0.1-0.13-0.11-0.13c-0.01,0-0.04,0.01-0.09,0.06c-0.07,0.07-0.14,0.12-0.21,0.16c-0.07,0.05-0.11,0.1-0.06,0.25
						c0.1,0.32,0.02,0.62-0.06,0.91l-0.01,0.05c-0.01,0.04-0.02,0.08-0.02,0.12c-0.01,0.08-0.03,0.17-0.07,0.24
						c-0.09,0.19-0.05,0.33,0.13,0.46c0.32,0.23,0.3,0.54,0.26,0.83c-0.01,0.07-0.02,0.12-0.03,0.17c-0.01,0.08-0.03,0.17-0.02,0.19
						c0.01,0.01,0.06,0.04,0.11,0.07c0.04,0.02,0.09,0.05,0.15,0.08c0.07,0.04,0.15,0.08,0.22,0.13c0.11,0.06,0.22,0.13,0.33,0.19
						c0,0,0,0,0,0c-0.02-0.48,0.27-0.65,0.53-0.8c0.08-0.04,0.15-0.08,0.21-0.13c0.05-0.04,0.12-0.05,0.17-0.03
						c0.05,0.02,0.08,0.07,0.09,0.13c0.01,0.11,0.04,0.21,0.07,0.32c0.06,0.24,0.13,0.49,0.02,0.77c-0.04,0.11-0.12,0.33-0.37,0.28
						c-0.27-0.06-0.49,0.09-0.68,0.25c-0.08,0.07-0.17,0.13-0.25,0.18c-0.07,0.05-0.14,0.09-0.2,0.14c0.06,0,0.13,0,0.19,0.01
						c0.27,0.01,0.52,0.02,0.76-0.14c0.23-0.16,0.47-0.11,0.69-0.07c0.08,0.02,0.15,0.03,0.23,0.04c0.15,0.01,0.25,0.02,0.31,0.1
						c0.07,0.08,0.05,0.19,0.02,0.4c-0.04,0.27-0.08,0.55-0.11,0.82c0.03-0.06,0.06-0.11,0.09-0.17c0.21-0.5,0.56-0.88,1.11-1.2
						c0.01,0,0.01-0.01,0.02-0.01c0.08-0.06,0.15-0.07,0.21-0.01c0.09,0.08,0.05,0.18,0.04,0.23c0,0.01-0.01,0.02-0.01,0.03
						c-0.05,0.19-0.09,0.36-0.1,0.54c-0.01,0.12,0.02,0.13,0.1,0.15c0.1,0.02,0.21,0.04,0.32,0.05l0.13,0.02
						c0.03,0,0.12,0.01,0.16,0.1c0.02,0.06,0.03,0.15-0.09,0.23c-0.36,0.25-0.55,0.64-0.76,1.06c-0.06,0.12-0.12,0.25-0.19,0.36
						c-0.03,0.05-0.06,0.11-0.09,0.16c-0.05,0.09-0.11,0.19-0.16,0.29c-0.06,0.12-0.12,0.23-0.25,0.26c-0.08,0.02-0.16,0-0.24-0.06
						c-0.01,0.01-0.03,0.03-0.04,0.04c-0.01,0.01-0.03,0.03-0.04,0.04c-0.43,0.35-0.68,0.77-0.77,1.28c0,0,0,0.01,0,0.01
						c0.07-0.09,0.15-0.17,0.22-0.26c0.18-0.21,0.36-0.43,0.56-0.63c0.17-0.18,0.38-0.27,0.58-0.37c0.04-0.02,0.07-0.03,0.1-0.05
						c0.31-0.15,0.6-0.38,0.89-0.7c0.1-0.11,0.21-0.22,0.32-0.32c0.03-0.03,0.07-0.06,0.1-0.1c0.19-0.19,0.39-0.28,0.62-0.3
						c0.13-0.01,0.26-0.04,0.4-0.07c0.09-0.02,0.18-0.04,0.27-0.05c0.03,0,0.05-0.01,0.05-0.02c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.02
						c-0.26-0.42-0.09-0.77,0.07-1.07c0.02-0.05,0.05-0.09,0.07-0.14c0.07-0.16,0.15-0.31,0.22-0.47c-0.14,0.21-0.29,0.42-0.43,0.62
						l-0.08,0.12c-0.07,0.11-0.17,0.1-0.23,0.07l-0.07-0.03l0.23-1.04c0.03-0.14,0.04-0.26,0.01-0.37
						c-0.02-0.06-0.03-0.09-0.04-0.11c-0.01,0.01-0.04,0.03-0.07,0.06c-0.01,0.01-0.01,0.02-0.02,0.02
						c-0.03,0.04-0.09,0.1-0.19,0.11l-0.09,0.01l-0.02-0.09c-0.03-0.17,0.04-0.29,0.1-0.39c0.02-0.03,0.04-0.07,0.05-0.1
						c0.18-0.44,0.38-0.89,0.56-1.32c0.08-0.19,0.17-0.38,0.25-0.58c0.08-0.18,0.23-0.15,0.3-0.13l0.03,0.01
						c0.38,0.09,0.69,0.32,0.94,0.7c0.06,0.09,0.13,0.16,0.2,0.24c0.03,0.03,0.06,0.07,0.09,0.1l0.18,0.11l0.48-0.02l0.01,0
						c0.02,0,0.05,0,0.06,0l0.02-0.02l0.02,0c0.26-0.04,0.46,0.05,0.66,0.28c0.71,0.81,1.32,1.41,1.93,1.9
						c0.24,0.19,0.55,0.22,0.85,0.25l0.01,0c1.03,0.1,1.94,0.58,2.77,1.09c0.08,0.05,0.16,0.11,0.17,0.2
						c0.01,0.1-0.07,0.18-0.13,0.24c-0.12,0.11-0.2,0.18-0.21,0.23c-0.01,0.05,0.04,0.15,0.11,0.3l0.01,0.03
						c0.03,0.07,0.07,0.14,0.11,0.2c0.24,0.31,0.2,0.65,0.17,0.98l-0.02,0.14l-0.08,0.01c-0.08,0.01-0.18-0.02-0.21-0.18
						c-0.05-0.31-0.21-0.53-0.39-0.77c-0.04-0.05-0.08-0.11-0.12-0.16c-0.08,0.19-0.16,0.37-0.24,0.55l-0.01,0.03
						c-0.03,0.07-0.03,0.14-0.01,0.21c0.06,0.21,0.12,0.41,0.18,0.62c0.09,0.32,0.19,0.65,0.29,0.97c0.05,0.15,0.07,0.29,0.06,0.43
						l-0.06,1.35l-0.22,0.03l-0.03-0.09c-0.02-0.09-0.04-0.17-0.06-0.26c-0.05-0.19-0.1-0.39-0.12-0.59
						c-0.04-0.4-0.25-0.66-0.47-0.94c-0.06-0.07-0.11-0.14-0.17-0.22l0,0.01c-0.11,0.17-0.22,0.35-0.32,0.53
						c-0.01,0.02-0.01,0.04,0.03,0.11c0.01,0.01,0.01,0.03,0.02,0.04l0.11,0.24c0.1,0.22,0.21,0.45,0.31,0.67
						c0.05,0.11,0.1,0.25-0.02,0.41c-0.22,0.29-0.24,0.64-0.08,1.07c0.05,0.14,0.06,0.28,0.03,0.41c-0.12,0.43-0.24,0.88-0.37,1.33
						c0,0.01,0,0.01,0,0.02c-0.01,0.05-0.03,0.15-0.16,0.19l-0.11,0.04l-0.02-0.12c-0.01-0.09-0.02-0.19-0.04-0.28
						c-0.03-0.21-0.06-0.43-0.08-0.64c-0.02-0.23-0.1-0.42-0.23-0.59c-0.13-0.16-0.25-0.34-0.36-0.5c-0.03-0.05-0.06-0.09-0.09-0.14
						c0,0,0-0.01-0.01-0.01c-0.08,0.19-0.16,0.38-0.24,0.57c-0.15,0.36-0.31,0.73-0.46,1.1c-0.13,0.35-0.05,0.67,0.05,1.02
						c0.01,0.05,0.02,0.09,0.04,0.14c0.01,0.04,0.05,0.06,0.14,0.09c0.05,0.02,0.09,0.04,0.14,0.06c0.04,0.03,0.09,0.05,0.14,0.07
						c0.12,0.05,0.25,0.12,0.37,0.27l0.13,0.18l-0.23-0.03c-0.13-0.01-0.24,0.01-0.35,0.04c-0.03,0.01-0.05,0.01-0.08,0.02
						c-0.3,0.07-0.52-0.09-0.71-0.26c-0.14-0.12-0.23-0.12-0.4-0.02c-0.41,0.26-0.66,0.58-0.77,1.02c-0.04,0.17-0.1,0.34-0.16,0.51
						c-0.03,0.07-0.05,0.15-0.08,0.22l-0.01,0.02c-0.17,0.27-0.38,0.49-0.56,0.68c-0.2,0.21-0.39,0.44-0.58,0.66
						c-0.07,0.08-0.14,0.17-0.21,0.25c-0.02,0.02-0.14,0.2-0.26,0.4c-1.12,1.72-1.18,1.71-1.27,1.7l-0.04-0.01l-0.03-0.04
						c-0.06-0.09-0.05-0.18-0.04-0.25c0-0.02,0-0.03,0.01-0.05c0.02-0.31,0.04-0.48-0.01-0.56c-0.05-0.07-0.19-0.11-0.46-0.17
						c-0.01,0-0.02-0.01-0.03-0.01l-0.01,0c-0.13-0.03-0.23-0.07-0.27-0.16c-0.04-0.09,0.01-0.19,0.07-0.31
						c0.16-0.3,0.71-1.23,1.19-2.05c0.37-0.63,0.73-1.23,0.85-1.46c0.09-0.17,0.06-0.24,0.01-0.3c-0.21-0.23-0.05-0.41,0.03-0.49
						c0.34-0.37,0.67-0.77,0.97-1.17c0.06-0.09,0.09-0.15,0.07-0.22c-0.07-0.27-0.12-0.55-0.17-0.81c-0.01-0.05-0.02-0.1-0.03-0.15
						c-0.11,0.22-0.22,0.45-0.33,0.68l-0.18,0.38l-0.09-0.16c-0.08-0.13-0.04-0.24-0.02-0.31l0.01-0.02
						c0.07-0.24,0.12-0.49,0.17-0.73c0.06-0.3,0.13-0.6,0.22-0.89c0.04-0.11,0.04-0.23,0.04-0.35c0-0.46,0-1.07-0.03-1.68
						c-0.01-0.18-0.05-0.23-0.11-0.25c-0.58-0.22-0.59-0.73-0.54-1.09c0.01-0.1,0.02-0.2,0.02-0.3c-0.04,0.06-0.07,0.12-0.09,0.18
						c-0.04,0.09-0.09,0.17-0.14,0.25c-0.08,0.13-0.15,0.25-0.18,0.4l-0.06,0.35c-0.07,0.46-0.15,0.92-0.23,1.37
						c0,0.01,0,0.02,0,0.03c-0.01,0.06-0.02,0.17-0.13,0.22l-0.07,0.03l-0.05-0.06c-0.06-0.07-0.06-0.15-0.06-0.2
						c0-0.01,0-0.02,0-0.03c-0.01-0.18-0.01-0.37-0.01-0.55c-0.01-0.39-0.02-0.78-0.03-1.17c0-0.04,0-0.07,0.01-0.11
						c0.01-0.07,0.01-0.1,0-0.11c0,0-0.04,0.04-0.06,0.06c-0.02,0.02-0.05,0.05-0.08,0.07c-0.07,0.05-0.13,0.1-0.19,0.16
						c-0.16,0.15-0.34,0.32-0.62,0.31c-0.04,0-0.09,0-0.14,0.01c-0.04,0.01-0.08,0.01-0.13,0.01c-0.04,0-0.08,0.01-0.11,0.11
						c-0.11,0.33-0.23,0.62-0.37,0.89c-0.2,0.39-0.23,0.72-0.12,1.05c0.04,0.12,0.06,0.25,0.09,0.4c0.01,0.07,0.02,0.14,0.04,0.22
						l0.07,0.34l-0.24-0.25c-0.14-0.15-0.23-0.31-0.32-0.46c-0.04-0.07-0.08-0.14-0.12-0.21c-0.03-0.05-0.05-0.06-0.05-0.06
						c0,0-0.02,0.01-0.05,0.04c-0.11,0.11-0.22,0.21-0.33,0.31c-0.05,0.05-0.1,0.09-0.15,0.14l-0.04,0.03c-0.3,0.28-0.57,0.53-0.6,1
						c-0.01,0.12-0.02,0.24-0.04,0.36c-0.05,0.46-0.11,0.89,0.1,1.32c0.06,0.13,0.02,0.27-0.03,0.38c-0.11,0.26-0.24,0.5-0.37,0.73
						c-0.05,0.08-0.09,0.17-0.14,0.26c-0.03,0.05-0.05,0.1-0.08,0.15c-0.11,0.19-0.21,0.38-0.24,0.6c0.05-0.04,0.11-0.08,0.16-0.11
						c0.14-0.1,0.28-0.2,0.43-0.29c0.26-0.17,0.54-0.14,0.81-0.1l0.15,0.02l-0.07,0.13c-0.07,0.12-0.15,0.2-0.27,0.27
						c-0.35,0.19-0.57,0.49-0.65,0.92c-0.05,0.25-0.11,0.49-0.17,0.75c-0.01,0.05-0.02,0.1-0.04,0.15l0.01,0
						c0.16-0.09,0.31-0.17,0.46-0.25c0.01,0,0.02-0.01,0.02-0.02c0.03-0.03,0.14-0.11,0.24-0.02c0.1,0.1,0.04,0.21,0.02,0.26
						c0,0.01-0.01,0.02-0.01,0.03c-0.17,0.41,0.58,3.47,1.08,5.5c0.28,1.13,0.5,2.03,0.52,2.29c0.02,0.18-0.1,0.3-0.17,0.38
						c-0.16,0.17-0.32,0.34-0.49,0.53c0,0,0,0,0,0c0.04,0,0.09,0,0.13,0c0.26,0,0.5,0.01,0.73-0.12l0.42-0.24l-0.32,0.43
						c-0.1,0.13-0.2,0.28-0.39,0.33c-0.02,0.01-0.04,0.02-0.04,0.02c-0.23,0.29-0.52,0.45-0.8,0.61c-0.08,0.05-0.16,0.09-0.24,0.14
						c-0.2,0.12-0.41,0.15-0.62,0.18c-0.09,0.01-0.17,0.02-0.26,0.04c-0.1,0.02-0.2-0.03-0.27-0.14l-0.03-0.05l0.03-0.05
						c0-0.01,0.01-0.01,0.01-0.02c0.01-0.02,0.03-0.05,0.05-0.08c0.04-0.03,0.05-0.05,0.06-0.06c-0.01-0.01-0.03-0.03-0.08-0.06
						c-0.22-0.13-0.7-1.18-1.67-3.38c-0.6-1.37-1.35-3.07-1.54-3.19c-0.18-0.12-0.26-0.3-0.31-0.44c-0.02-0.06-0.04-0.13-0.06-0.19
						c-0.09-0.29-0.18-0.56-0.35-0.79c-0.15-0.2-0.3-0.4-0.44-0.61c-0.18-0.24-0.36-0.5-0.54-0.74l-0.04-0.06
						c-0.02-0.03-0.04-0.05-0.06-0.08l0.56,2.33l-0.09,0.03c-0.15,0.04-0.21-0.06-0.23-0.1l-0.22-0.35
						c-0.34-0.55-0.69-1.1-1.03-1.65l-0.01-0.02c-0.02-0.03-0.04-0.07-0.06-0.08c0,0-0.01,0-0.01,0.01
						c-0.05,0.04-0.11,0.06-0.16,0.08c-0.07,0.03-0.12,0.04-0.13,0.08c-0.01,0.03-0.02,0.11,0.07,0.32c0.07,0.16,0.1,0.35,0.08,0.59
						c0,0.01,0,0.01,0,0.02c0.01,0.04,0.03,0.13-0.04,0.19c-0.04,0.04-0.12,0.06-0.21-0.02l-0.01-0.01
						c-0.06-0.05-0.12-0.1-0.18-0.15c-0.1-0.08-0.2-0.16-0.29-0.25c-0.09-0.08-0.17-0.09-0.28-0.03c0,0,0,0,0,0
						c0,0,0,0.01,0.01,0.01c0.14,0.22,0.28,0.44,0.41,0.66c0.15,0.25,0.31,0.51,0.47,0.75c0.02,0.03,0.04,0.05,0.06,0.08
						c0.08,0.11,0.18,0.23,0.19,0.4l0,0.07l-0.07,0.03c-0.12,0.05-0.18-0.03-0.2-0.05l-0.01-0.01c-0.04-0.05-0.08-0.1-0.12-0.14
						c-0.09-0.11-0.17-0.21-0.27-0.25c-0.15-0.07-0.32-0.12-0.48-0.17c-0.07-0.02-0.14-0.04-0.21-0.07c0,0,0,0,0,0
						c0,0.01,0.01,0.01,0.01,0.02c0.1,0.23,0.2,0.46,0.3,0.68l0.12,0.29c0.03,0.07,0.07,0.18,0.01,0.27
						c-0.06,0.1-0.18,0.11-0.23,0.12c-0.14,0.02-0.28,0.04-0.41,0.06c-0.09,0.02-0.19,0.03-0.28,0.05
						c-0.09,0.27-0.14,1.86-0.13,2.05c0.01,0.13,0,0.26,0,0.41c0,0.07,0,0.14,0,0.22v0.23l-0.17-0.16
						c-0.06-0.05-0.11-0.11-0.16-0.15c-0.12-0.11-0.22-0.2-0.31-0.3c-0.05-0.06-0.07-0.19-0.13-0.98c-0.02-0.25-0.04-0.57-0.06-0.77
						c-0.09,0.17-0.18,0.43-0.27,0.68c-0.15,0.4-0.29,0.78-0.44,0.94c-0.1,0.1-0.2,0.15-0.33,0.16c-0.25,0.02-0.48-0.23-0.69-0.46
						c-0.19-0.2-0.38-0.41-0.54-0.4c-0.13,0.01-0.21,0.07-0.27,0.2c-0.01,0.02-0.02,0.04-0.02,0.06c-0.05,0.11-0.1,0.24-0.21,0.34
						l-0.02,0.01c-0.05,0.02-0.09,0.04-0.14,0.07c-0.13,0.07-0.26,0.13-0.42,0.15c-0.17,0.02-0.38,0.04-0.52-0.19
						c-0.01-0.02-0.04-0.05-0.08-0.06l-0.03-0.01l-0.1-0.1l-0.01-0.03c-0.07-0.27-0.22-0.52-0.46-0.77c-0.03-0.03-0.05-0.05-0.16,0
						c-0.34,0.16-0.66,0.37-0.97,0.57c-0.27,0.18-0.55,0.36-0.84,0.51c-0.12,0.06-0.23,0.12-0.37,0.12l-0.14,0l0.04-0.13
						c0.03-0.1,0.1-0.17,0.15-0.23l0.03-0.03c0.36-0.39,0.67-0.83,0.9-1.16c0.09-0.12,0.1-0.2,0.06-0.3
						c-0.07-0.15-0.06-0.31,0.01-0.47c0.02-0.06,0.02-0.08,0.02-0.08c0,0-0.02-0.01-0.06-0.01c-0.02,0-0.05,0-0.09,0.01
						c-0.19,0.03-0.4,0.02-0.64-0.01c-0.13-0.02-0.2,0.03-0.27,0.17c-0.09,0.17-0.19,0.35-0.28,0.52c-0.16,0.29-0.32,0.6-0.47,0.9
						c-0.05,0.11-0.13,0.22-0.31,0.26c-0.13,0.03-0.26,0.06-0.39,0.09c-0.19,0.05-0.39,0.09-0.6,0.13
						c-0.11,0.02-0.14,0.04-0.14,0.19c0.01,0.3,0,0.61,0,0.91c0,0.16,0,0.32,0,0.48l0,0.05c0,0.08,0,0.15,0.03,0.21l0.01,0.03
						l0,0.03c-0.02,0.18-0.13,0.26-0.22,0.34l-0.03,0.02c-0.16,0.13-0.31,0.28-0.46,0.43c-0.05,0.05-0.1,0.1-0.15,0.15
						c-0.01,0.01-0.01,0.02-0.02,0.03c-0.03,0.04-0.11,0.13-0.23,0.08c-0.12-0.05-0.11-0.17-0.11-0.22c0-0.01,0-0.03,0-0.04
						c-0.01-0.09,0-0.16,0.03-0.22c-0.54,0.49-1.1,0.92-1.72,1.39c-0.18,0.14-0.38,0.15-0.54,0.05c-0.25-0.16-0.42-0.07-0.7,0.19
						c-0.51,0.47-1.06,0.97-1.67,1.4c-0.13,0.09-0.28,0.17-0.45,0.25c-0.43,0.19-0.83,0.46-1.23,0.72c-0.16,0.1-0.32,0.21-0.48,0.31
						c-0.18,0.11-0.35,0.24-0.52,0.36c-0.08,0.06-0.15,0.11-0.23,0.17c-0.04,0.03-0.08,0.06-0.13,0.09l-0.21,0.15l-0.02-0.17
						c-0.02-0.2,0.1-0.29,0.16-0.33c0.09-0.06,0.17-0.16,0.25-0.26c0.06-0.07,0.11-0.14,0.18-0.2c-0.13,0.05-0.26,0.1-0.38,0.15
						c-0.2,0.07-0.39,0.14-0.57,0.24c-0.28,0.15-0.54,0.08-0.76,0.01l-0.15-0.05c-0.26-0.09-0.51-0.17-0.77-0.21
						c0,0.03,0.01,0.07,0.01,0.09c0.02,0.14,0.05,0.33-0.11,0.4c-0.39,0.17-1.03,0.99-1.03,1l-0.02,0.02
						c-0.01,0.01-0.04,0.05-0.08,0.09C616.85,1845.69,616.36,1846.02,616.14,1846.02z M618.97,1838.76
						c-0.05,0.03-0.1,0.05-0.16,0.08c-0.3,0.15-0.59,0.35-0.68,0.82c0,0,0,0,0,0c0.06-0.11,0.16-0.22,0.31-0.37
						c0.02-0.02,0.05-0.05,0.09-0.09C618.63,1839.1,618.82,1838.92,618.97,1838.76z M626.84,1827.11c-0.14,0.16-0.29,0.31-0.44,0.46
						c-0.38,0.39-0.77,0.8-1.11,1.26c-0.05,0.07-0.05,0.08-0.02,0.1c0.08,0.07,0.12,0.14,0.12,0.21c0,0.11-0.07,0.19-0.14,0.26
						c-0.07,0.07-0.14,0.14-0.21,0.21c-0.07,0.07-0.14,0.14-0.21,0.2c-0.08,0.08-0.11,0.15-0.09,0.27c0.04,0.22,0.01,0.44-0.11,0.7
						c-0.03,0.06-0.06,0.13-0.09,0.19c-0.1,0.21-0.19,0.4-0.23,0.62c-0.04,0.18-0.01,0.37,0.02,0.56c0.01,0.05,0.02,0.1,0.02,0.15
						c0.04,0.32,0.09,0.64-0.11,0.96c-0.18,0.28-0.25,0.61-0.22,0.99c0.02,0.32-0.05,0.58-0.21,0.78c0.05-0.03,0.11-0.06,0.17-0.08
						c0.11-0.04,0.14-0.11,0.15-0.28c0.02-0.39,0.06-0.76,0.29-1.09c0.18-0.25,0.25-0.55,0.21-0.87c-0.01-0.09-0.01-0.17,0-0.26
						c0-0.05,0-0.11,0-0.16c0-0.13,0.03-0.25,0.1-0.37c0.12-0.2,0.25-0.4,0.37-0.6c0.18-0.3,0.37-0.6,0.55-0.89
						c0.05-0.08,0.07-0.15,0.08-0.24c0.01-0.26,0.03-0.6,0.05-0.94c0.02-0.27,0.08-0.48,0.18-0.65l0.05-0.09
						C626.33,1828.07,626.62,1827.61,626.84,1827.11z M623.85,1834.22c0,0.03,0,0.05-0.01,0.08c-0.01,0.17-0.03,0.35-0.1,0.51
						c0.09-0.15,0.12-0.33,0.1-0.55C623.85,1834.25,623.85,1834.24,623.85,1834.22z M652.21,1832.37L652.21,1832.37L652.21,1832.37z
						 M642.45,1830.22L642.45,1830.22L642.45,1830.22z M658.98,1821.01L658.98,1821.01L658.98,1821.01z M642.97,1813.15
						c-0.01,0.04-0.02,0.07-0.04,0.11c0.02,0,0.03-0.01,0.03-0.02C642.97,1813.24,642.98,1813.22,642.97,1813.15z M647.58,1809.21
						c0.04,0.05,0.09,0.12,0.11,0.14c0.03-0.03,0.09-0.1,0.13-0.17c-0.08,0.01-0.16,0.02-0.23,0.03
						C647.59,1809.21,647.58,1809.21,647.58,1809.21z M637.78,1833.31l0.16-0.38c0.15-0.35,0.31-0.58,0.51-0.69
						c0.3-0.17,0.46-0.4,0.53-0.73c0.05-0.27,0.19-0.44,0.41-0.54c0.27-0.12,0.46-0.36,0.65-0.63c0.03-0.04,0.06-0.08,0.1-0.13
						c0.01-0.01,0.01-0.02,0.02-0.03c0.03-0.05,0.1-0.17,0.24-0.12c0.1,0.04,0.11,0.14,0.11,0.2c0,0.01,0,0.03,0,0.04
						c0.01,0.05,0.02,0.1,0.03,0.15c0.03,0.18,0.05,0.28,0.2,0.31c0.06,0.01,0.1,0.05,0.11,0.1c0.02,0.09-0.07,0.17-0.11,0.2
						l-0.05-0.08l0.05,0.08c-0.31,0.2-0.59,0.46-0.87,0.71c-0.21,0.19-0.42,0.38-0.65,0.55c-0.17,0.12-0.34,0.23-0.52,0.33
						c-0.2,0.12-0.42,0.24-0.6,0.4L637.78,1833.31z M640.31,1830.31c-0.01,0.01-0.01,0.02-0.02,0.03c-0.03,0.04-0.06,0.08-0.09,0.12
						c-0.2,0.27-0.41,0.55-0.73,0.69c-0.16,0.07-0.25,0.19-0.29,0.4c-0.08,0.39-0.27,0.67-0.62,0.87c-0.08,0.05-0.16,0.12-0.23,0.23
						c0.1-0.06,0.19-0.12,0.29-0.17c0.17-0.1,0.34-0.19,0.49-0.31c0.22-0.17,0.43-0.36,0.64-0.54c0.27-0.24,0.55-0.49,0.85-0.7
						c-0.19-0.09-0.22-0.29-0.25-0.44c-0.01-0.05-0.01-0.09-0.02-0.13C640.31,1830.34,640.31,1830.32,640.31,1830.31z
						 M616.8,1833.01c-0.02,0-0.04,0-0.05-0.01c-0.01,0-0.01,0-0.02-0.01l-0.09-0.02l0.02-0.15c0.09-0.58,0.18-1.19,0.47-1.73
						c0.09-0.18,0.17-0.34,0.23-0.5c0.04-0.1,0.09-0.2,0.19-0.23c0.07-0.02,0.15-0.01,0.24,0.05c0.01,0.01,0.02,0.01,0.02,0.01
						c0-0.01,0-0.03,0-0.04c-0.08-0.65,0.1-1.23,0.55-1.78c0.24-0.28,0.42-0.62,0.56-0.92c0-0.01,0.01-0.02,0.01-0.03
						c0.04-0.16,0.13-0.17,0.2-0.15c0.13,0.03,0.13,0.16,0.13,0.22c0,0.01,0,0.02,0,0.03c0.04,0.41,0.09,0.88,0.2,1.36
						c0.02,0.09,0.06,0.18,0.11,0.29c0.34,0.7,0.32,1.45,0.27,2.15h0c-0.01,0.21-0.12,0.31-0.31,0.3c-0.05,0-0.11,0-0.17,0.01
						c-0.05,0-0.09,0.01-0.14,0.01c-0.34,0.01-0.52,0.28-0.71,0.57c-0.03,0.05-0.06,0.1-0.1,0.15c-0.09,0.14-0.21,0.26-0.45,0.19
						c-0.4-0.12-0.75-0.06-1.05,0.18c-0.03,0.02-0.06,0.04-0.09,0.04C616.82,1833.01,616.81,1833.01,616.8,1833.01z M617.61,1830.56
						C617.61,1830.56,617.61,1830.56,617.61,1830.56c-0.01,0-0.03,0.01-0.07,0.12c-0.06,0.16-0.14,0.33-0.24,0.52
						c-0.25,0.48-0.34,1.01-0.43,1.56c0.34-0.24,0.73-0.29,1.17-0.16c0.1,0.03,0.14,0.02,0.23-0.11c0.03-0.05,0.06-0.1,0.1-0.15
						c0.2-0.31,0.42-0.65,0.87-0.66c0.04,0,0.08,0,0.12-0.01c0.07-0.01,0.13-0.01,0.2-0.01c0.06,0,0.09,0.01,0.1-0.11h0
						c0.05-0.68,0.07-1.4-0.25-2.05c-0.06-0.12-0.1-0.22-0.12-0.33c-0.11-0.46-0.16-0.93-0.2-1.33c-0.15,0.29-0.32,0.62-0.56,0.91
						c-0.42,0.5-0.58,1.03-0.51,1.62c0.01,0.05,0.02,0.19-0.08,0.26c-0.05,0.03-0.13,0.05-0.25-0.03
						C617.64,1830.56,617.62,1830.56,617.61,1830.56z M617.81,1830.47L617.81,1830.47L617.81,1830.47z M632.79,1830.57
						c0,0-0.01,0-0.01,0l-0.17,0l0.09-0.15c0.07-0.11,0.13-0.22,0.2-0.32c0.14-0.21,0.27-0.42,0.36-0.64
						c0.24-0.56,0.68-0.76,1.1-0.96c0.08-0.04,0.17-0.08,0.25-0.12c0.16-0.08,0.32-0.15,0.47-0.22c0.25-0.11,0.51-0.23,0.75-0.37
						c0.19-0.11,0.35-0.28,0.52-0.45c0.07-0.07,0.15-0.15,0.23-0.22c0.11-0.1,0.22-0.21,0.33-0.32c0.27-0.27,0.56-0.56,0.9-0.75
						c0.02-0.01,0.05-0.03,0.07-0.04c0.1-0.07,0.23-0.15,0.42-0.15c0,0,0,0,0.01,0l0.23,0l-0.15,0.17
						c-0.02,0.02-0.03,0.04-0.05,0.05c0.18-0.1,0.39-0.13,0.57-0.16c0.03,0,0.07-0.01,0.1-0.02c0.1-0.02,0.17,0.02,0.21,0.11
						l0.03,0.08l-0.07,0.05c-0.07,0.05-0.15,0.1-0.22,0.15c-0.16,0.11-0.34,0.22-0.51,0.32c-0.36,0.2-0.66,0.51-0.93,0.78
						c-0.05,0.06-0.11,0.11-0.17,0.16c-0.13,0.13-0.26,0.26-0.38,0.4l0,0c-0.24,0.28-0.55,0.4-0.81,0.48l-0.09,0.03
						c-0.27,0.08-0.56,0.17-0.84,0.24c-0.25,0.06-0.44,0.24-0.65,0.43c-0.08,0.08-0.17,0.16-0.26,0.23
						c-0.31,0.25-0.61,0.52-0.91,0.78c-0.13,0.12-0.26,0.23-0.39,0.34l-0.01,0.01C632.98,1830.51,632.92,1830.57,632.79,1830.57z
						 M637.95,1826.2c-0.01,0.01-0.03,0.02-0.04,0.02c-0.32,0.18-0.59,0.46-0.85,0.72c-0.11,0.11-0.22,0.22-0.33,0.32
						c-0.08,0.07-0.15,0.15-0.22,0.22c-0.17,0.17-0.35,0.35-0.56,0.48c-0.25,0.15-0.52,0.27-0.78,0.39
						c-0.15,0.07-0.31,0.14-0.46,0.21c-0.08,0.04-0.17,0.08-0.26,0.12c-0.41,0.19-0.8,0.36-1,0.85c-0.08,0.2-0.19,0.38-0.31,0.56
						c0.05-0.04,0.1-0.09,0.15-0.13c0.3-0.26,0.6-0.53,0.92-0.78c0.08-0.07,0.16-0.14,0.25-0.22c0.21-0.2,0.43-0.4,0.74-0.48
						c0.28-0.07,0.56-0.15,0.83-0.23l0.09-0.03c0.24-0.07,0.51-0.18,0.72-0.42l0,0c0.12-0.14,0.26-0.28,0.39-0.41
						c0.05-0.05,0.11-0.11,0.16-0.16c0.28-0.28,0.59-0.6,0.98-0.81c0.16-0.09,0.32-0.2,0.48-0.3c-0.22,0.03-0.43,0.09-0.59,0.28
						c-0.03,0.03-0.09,0.1-0.18,0.1c0,0,0,0,0,0c-0.05,0-0.09-0.02-0.12-0.06C637.86,1826.35,637.91,1826.25,637.95,1826.2z
						 M626.31,1830.33c-0.01,0-0.01,0-0.02,0c-0.08-0.01-0.14-0.08-0.13-0.2c0.01-0.11,0.02-0.22,0.04-0.33
						c0.03-0.29,0.06-0.58,0.12-0.87c0.03-0.16,0.14-0.28,0.24-0.41c0.03-0.03,0.05-0.06,0.08-0.09c0.13-0.17,0.25-0.35,0.28-0.59
						c0.03-0.28,0.18-0.49,0.33-0.7c0.02-0.03,0.04-0.06,0.06-0.09c0.07-0.1,0.08-0.17,0.06-0.27v0c-0.07-0.34,0.01-0.62,0.24-0.82
						c0.15-0.13,0.3-0.28,0.44-0.42c0.07-0.07,0.14-0.14,0.22-0.21c0.08-0.07,0.1-0.13,0.1-0.21c-0.02-0.45,0.12-0.87,0.45-1.27
						c0.15-0.19,0.25-0.37,0.21-0.61c-0.04-0.22,0.08-0.37,0.18-0.49c0.02-0.03,0.05-0.05,0.07-0.08c0.28-0.39,0.58-0.77,0.86-1.14
						l0.2-0.26c0.01-0.01,0.01-0.02,0.02-0.03c0.03-0.04,0.1-0.16,0.23-0.11c0.13,0.05,0.1,0.18,0.09,0.23
						c0,0.01-0.01,0.02-0.01,0.03c0,0.02,0,0.04,0,0.06c0,0.01,0,0.03,0,0.04c0,0.04,0,0.09,0,0.13c0.03-0.01,0.07-0.02,0.1-0.03
						c0.01,0,0.03-0.01,0.04-0.02c0.06-0.03,0.15-0.07,0.26-0.02l0.14,0.05l-0.1,0.11c-0.12,0.13-0.25,0.21-0.39,0.29
						c-0.18,0.11-0.34,0.21-0.44,0.4c-0.09,0.18-0.14,0.39-0.19,0.61c-0.01,0.04-0.02,0.07-0.03,0.11c0.01-0.01,0.02-0.02,0.03-0.04
						l0.03-0.03c0.05-0.07,0.1-0.14,0.15-0.2c0.16-0.22,0.33-0.44,0.53-0.64c0.01-0.01,0.01-0.01,0.02-0.02
						c0.04-0.04,0.09-0.09,0.16-0.09c0.03,0,0.08,0.01,0.12,0.06c0.07,0.07,0.06,0.16-0.02,0.25c-0.01,0.01-0.01,0.02-0.02,0.02
						l-0.14,0.22c-0.29,0.47-0.59,0.95-0.9,1.42c-0.06,0.1-0.06,0.2-0.05,0.32c0,0.04,0,0.07,0,0.11c0,0.14,0,0.32-0.15,0.46
						c-0.32,0.29-0.42,0.66-0.3,1.12c0.06,0.22,0,0.39-0.18,0.53c-0.42,0.34-0.85,0.69-1.2,1.12c-0.38,0.46-0.71,0.98-1.04,1.49
						c-0.09,0.14-0.18,0.28-0.27,0.42c-0.13,0.2-0.26,0.4-0.4,0.6C626.43,1830.28,626.37,1830.33,626.31,1830.33z M627.57,1826.74
						c0.03,0.16,0,0.28-0.09,0.43c-0.02,0.03-0.04,0.06-0.06,0.09c-0.14,0.19-0.27,0.38-0.29,0.61c-0.03,0.3-0.18,0.5-0.32,0.69
						c-0.03,0.03-0.05,0.07-0.08,0.1c-0.09,0.1-0.18,0.21-0.2,0.32c-0.06,0.27-0.09,0.56-0.12,0.84c-0.01,0.06-0.01,0.12-0.02,0.18
						c0.1-0.15,0.2-0.31,0.31-0.46c0.09-0.14,0.18-0.28,0.27-0.42c0.33-0.51,0.66-1.04,1.05-1.51c0.36-0.44,0.8-0.8,1.23-1.14
						c0.12-0.1,0.15-0.18,0.11-0.33c-0.14-0.53-0.02-0.97,0.36-1.32c0.08-0.08,0.09-0.18,0.09-0.32c0-0.03,0-0.07,0-0.1
						c-0.01-0.14-0.01-0.29,0.08-0.44c0.29-0.46,0.59-0.93,0.87-1.38c-0.1,0.12-0.2,0.25-0.3,0.38c-0.05,0.07-0.1,0.14-0.16,0.21
						l-0.02,0.03c-0.08,0.1-0.16,0.22-0.32,0.32l-0.24,0.16l0.09-0.28c0.05-0.15,0.09-0.3,0.12-0.45c0.05-0.23,0.11-0.45,0.21-0.66
						c0.12-0.24,0.32-0.36,0.51-0.48c0,0,0.01,0,0.01-0.01c-0.04,0-0.07-0.01-0.1-0.03c-0.08-0.06-0.07-0.16-0.06-0.31
						c0-0.02,0-0.03,0-0.05c0-0.01,0-0.01,0-0.02l-0.18,0.23c-0.28,0.37-0.58,0.75-0.86,1.14c-0.02,0.03-0.05,0.06-0.07,0.09
						c-0.09,0.1-0.16,0.19-0.14,0.32c0.06,0.33-0.09,0.56-0.25,0.77c-0.3,0.37-0.42,0.73-0.4,1.14c0.01,0.18-0.09,0.29-0.16,0.36
						c-0.07,0.07-0.14,0.14-0.22,0.21c-0.14,0.14-0.29,0.29-0.45,0.43C627.58,1826.26,627.52,1826.47,627.57,1826.74L627.57,1826.74
						z M621.51,1830.21l-0.25-0.01v-0.1c0-0.1,0-0.19,0-0.29c-0.01-0.22-0.01-0.45,0.01-0.67c0.03-0.3-0.02-0.58-0.14-0.83
						c-0.37-0.73-0.31-1.51-0.26-2.27c0.01-0.08,0.01-0.16,0.02-0.24c0.01-0.24,0.16-0.41,0.29-0.56c0.03-0.03,0.05-0.06,0.08-0.09
						c0.07-0.09,0.15-0.17,0.23-0.25c0.04-0.04,0.08-0.08,0.11-0.12c0-0.01,0.01-0.01,0.01-0.02c0.02-0.03,0.09-0.11,0.19-0.08
						c0.13,0.04,0.12,0.17,0.11,0.23l0,0.02c0,0.08-0.01,0.16-0.01,0.25c0,0.19-0.01,0.39-0.05,0.58c-0.11,0.51,0,0.99,0.13,1.48
						c0.09,0.33,0.1,0.61,0.03,0.89h0c-0.06,0.23-0.1,0.47-0.15,0.7c-0.08,0.39-0.16,0.8-0.29,1.19c-0.01,0.03-0.02,0.05-0.03,0.08
						L621.51,1830.21z M621.71,1824.96c-0.03,0.03-0.06,0.06-0.09,0.1c-0.08,0.08-0.15,0.15-0.22,0.24
						c-0.03,0.03-0.05,0.06-0.08,0.1c-0.12,0.14-0.23,0.27-0.24,0.44c0,0.08-0.01,0.16-0.02,0.24c-0.05,0.76-0.1,1.48,0.24,2.17
						c0.14,0.28,0.19,0.59,0.16,0.93c-0.02,0.19-0.02,0.39-0.01,0.59c0.09-0.31,0.15-0.64,0.22-0.95c0.05-0.23,0.09-0.47,0.15-0.71
						l0,0c0.06-0.25,0.05-0.49-0.03-0.79c-0.14-0.51-0.25-1.02-0.13-1.58c0.04-0.17,0.04-0.35,0.05-0.54
						C621.71,1825.12,621.71,1825.04,621.71,1824.96z M638.73,1829.92l0.3-0.42c0.11-0.15,0.22-0.3,0.32-0.45
						c0.29-0.4,0.63-0.72,1.02-0.96c0.13-0.08,0.18-0.22,0.22-0.38l-0.04,0.03c-0.38,0.29-0.77,0.59-1.11,0.96
						c-0.01,0.01-0.01,0.02-0.02,0.02c-0.03,0.04-0.1,0.13-0.2,0.09c-0.13-0.04-0.12-0.17-0.11-0.22c0-0.01,0-0.02,0-0.03
						c0-0.05,0-0.1,0-0.14c0-0.07,0-0.14-0.01-0.21c-0.07-0.59,0.19-1.03,0.46-1.4c0.06-0.08,0.12-0.16,0.18-0.24
						c-0.02,0.01-0.03,0.02-0.05,0.02l-0.07,0.08l-0.15-0.16l0.06-0.07c0.15-0.43,0.34-0.92,0.63-1.36
						c0.16-0.24,0.43-0.29,0.68-0.34l0.04-0.01c0.12-0.02,0.17,0.08,0.2,0.12c0.09,0.16,0.18,0.31,0.26,0.46
						c0.16,0.27,0.32,0.54,0.45,0.85c0.05,0.12,0.1,0.29-0.03,0.47c-0.25,0.35-0.41,0.78-0.51,1.36l0,0
						c-0.02,0.1-0.07,0.17-0.11,0.23l-0.01,0.01c-0.43,0.65-0.94,1.08-1.54,1.32c-0.07,0.03-0.14,0.06-0.22,0.1
						c-0.11,0.06-0.23,0.11-0.36,0.15L638.73,1829.92z M640.68,1827.44c0.02,0,0.05,0.01,0.08,0.03c0.06,0.04,0.08,0.13,0.06,0.19
						c-0.01,0.03-0.02,0.05-0.02,0.08c-0.05,0.19-0.11,0.4-0.32,0.52c-0.36,0.22-0.69,0.53-0.96,0.91
						c-0.08,0.11-0.16,0.22-0.24,0.33c0,0,0,0,0,0c0.08-0.04,0.15-0.08,0.24-0.11c0.57-0.22,1.04-0.63,1.45-1.25l0.01-0.01
						c0.04-0.06,0.07-0.1,0.08-0.16l0,0c0.1-0.6,0.27-1.06,0.54-1.44c0.05-0.07,0.06-0.14,0-0.28c-0.12-0.29-0.28-0.56-0.44-0.82
						c-0.09-0.15-0.18-0.3-0.26-0.46c-0.01-0.01-0.01-0.02-0.01-0.03l-0.01,0c-0.21,0.04-0.44,0.09-0.55,0.25
						c-0.22,0.32-0.38,0.69-0.51,1.04c0.09-0.09,0.19-0.2,0.35-0.26l0.29-0.1l-0.18,0.25c-0.07,0.1-0.13,0.19-0.19,0.27
						c-0.13,0.18-0.25,0.36-0.37,0.52c-0.26,0.35-0.48,0.74-0.42,1.25c0.01,0.08,0.01,0.16,0.01,0.23c0,0.04,0,0.09,0,0.13
						c0.34-0.37,0.74-0.67,1.12-0.96l0.13-0.1C640.58,1827.48,640.62,1827.44,640.68,1827.44z M642.92,1829.75l-0.08-0.17
						c-0.06-0.13-0.08-0.25-0.09-0.36c0-0.05-0.01-0.09-0.02-0.13c-0.01-0.04-0.01-0.08-0.01-0.11c0-0.03,0-0.06,0-0.07
						c-0.14-0.53,0.12-0.9,0.35-1.16c0.16-0.18,0.31-0.39,0.44-0.59c0.04-0.06,0.08-0.12,0.12-0.18c0.11-0.15,0.24-0.26,0.37-0.36
						c0.03-0.02,0.06-0.05,0.09-0.07l0.12-0.09c0.02-0.01,0.03-0.02,0.05-0.04c-0.18,0.07-0.35,0.17-0.53,0.28
						c-0.14,0.08-0.24,0.22-0.34,0.37c-0.05,0.08-0.11,0.15-0.17,0.22c-0.15,0.18-0.3,0.37-0.44,0.56
						c-0.08,0.11-0.17,0.23-0.26,0.34c-0.19,0.24-0.44,0.39-0.68,0.53c-0.09,0.06-0.19,0.11-0.28,0.18l-0.33,0.22l0.18-0.35
						c0.03-0.06,0.06-0.11,0.08-0.17c0.06-0.13,0.12-0.26,0.21-0.38c0.36-0.53,0.56-1.13,0.72-1.71c0.11-0.38,0.33-0.63,0.53-0.83
						c0.47-0.45,0.93-0.89,1.39-1.32c0.26-0.24,0.47-0.55,0.66-0.85c0.06-0.09,0.11-0.17,0.17-0.26c0.25-0.37,0.5-0.76,0.75-1.13
						c0.13-0.19,0.25-0.39,0.38-0.58c0.04-0.07,0.09-0.13,0.14-0.19c0.02-0.03,0.04-0.06,0.06-0.08l0.14-0.19l0.04,0.23
						c0.06,0.31,0.1,0.61-0.03,0.92c-0.09,0.23-0.17,0.46-0.25,0.69c-0.06,0.17-0.11,0.34-0.18,0.51c-0.06,0.17-0.04,0.29,0.08,0.42
						c0.09,0.09,0.17,0.19,0.25,0.29c0.15,0.17,0.3,0.34,0.46,0.5c0.21,0.2,0.11,0.34,0,0.46v0c-0.1,0.11-0.21,0.22-0.31,0.33
						c-0.13,0.14-0.26,0.28-0.39,0.41c-0.24,0.24-0.41,0.54-0.53,0.92c-0.07,0.23-0.2,0.39-0.42,0.49c-0.2,0.1-0.41,0.19-0.61,0.29
						c-0.25,0.12-0.51,0.23-0.76,0.37c-0.2,0.1-0.33,0.25-0.43,0.47c-0.13,0.29-0.27,0.59-0.41,0.88l-0.07,0.14
						c-0.02,0.04-0.04,0.08-0.07,0.12c-0.01,0.02-0.03,0.04-0.04,0.07L642.92,1829.75z M645.11,1825.96l-0.2,0.18
						c-0.18,0.17-0.38,0.33-0.57,0.48l-0.12,0.09c-0.03,0.02-0.06,0.05-0.09,0.07c-0.12,0.1-0.24,0.19-0.33,0.31
						c-0.04,0.06-0.08,0.12-0.12,0.17c-0.14,0.21-0.29,0.42-0.46,0.61c-0.31,0.33-0.4,0.62-0.31,0.97c0.01,0.05,0.01,0.09,0.01,0.13
						c0,0.03,0,0.05,0,0.07c0.01,0.05,0.01,0.1,0.02,0.15c0,0.03,0.01,0.05,0.01,0.08l0.05-0.11c0.14-0.29,0.28-0.58,0.41-0.87
						c0.12-0.26,0.28-0.44,0.52-0.57c0.26-0.13,0.52-0.26,0.77-0.37c0.2-0.09,0.4-0.18,0.6-0.28c0.16-0.08,0.26-0.2,0.32-0.37
						c0.13-0.41,0.31-0.73,0.58-1c0.13-0.13,0.26-0.27,0.38-0.41c0.1-0.11,0.21-0.22,0.31-0.33l0,0c0.09-0.09,0.09-0.09,0.01-0.17
						c-0.17-0.16-0.32-0.34-0.47-0.51c-0.08-0.1-0.16-0.19-0.25-0.28c-0.17-0.18-0.2-0.39-0.12-0.62c0.06-0.17,0.12-0.34,0.17-0.5
						c0.08-0.23,0.16-0.47,0.25-0.7c0.08-0.19,0.08-0.38,0.05-0.58c-0.02,0.03-0.04,0.05-0.05,0.08c-0.13,0.19-0.25,0.39-0.38,0.58
						c-0.24,0.37-0.49,0.76-0.75,1.13c-0.06,0.08-0.11,0.17-0.17,0.26c-0.2,0.31-0.42,0.63-0.7,0.89c-0.46,0.42-0.92,0.86-1.38,1.31
						c-0.19,0.19-0.39,0.41-0.48,0.74c-0.16,0.56-0.37,1.21-0.75,1.77c-0.05,0.07-0.09,0.14-0.12,0.22
						c0.23-0.14,0.45-0.27,0.62-0.48c0.09-0.11,0.17-0.22,0.25-0.33c0.14-0.19,0.29-0.39,0.45-0.57c0.06-0.06,0.11-0.13,0.16-0.21
						c0.11-0.16,0.23-0.32,0.4-0.42c0.2-0.12,0.39-0.24,0.6-0.31c0.07-0.02,0.13-0.06,0.2-0.1c0.12-0.07,0.25-0.15,0.41-0.15
						L645.11,1825.96z M640.89,1824.47l-0.17-0.16l0.05-0.07c0.08-0.11,0.14-0.23,0.21-0.35c0.05-0.09,0.1-0.19,0.16-0.28
						c0.14-0.22,0.21-0.44,0.24-0.7c0.05-0.59,0.16-1.2,0.55-1.71c0.11-0.15,0.21-0.33,0.31-0.5c0.03-0.05,0.06-0.11,0.09-0.16
						c0.09-0.15,0.14-0.29,0.11-0.45c-0.04-0.29-0.08-0.57-0.12-0.86c-0.04-0.32-0.09-0.64-0.14-0.96
						c-0.02-0.12-0.02-0.28,0.09-0.43c0.38-0.49,0.81-0.95,1.23-1.39l0.15-0.16c0.07-0.07,0.13-0.14,0.2-0.21l0.11-0.12
						c0.03-0.03,0.11-0.11,0.21-0.08c0.1,0.03,0.12,0.16,0.13,0.2c0.04,0.28,0.09,0.57,0.13,0.85c0.03,0.2-0.02,0.37-0.15,0.51
						c-0.12,0.13-0.24,0.26-0.35,0.39c-0.09,0.1-0.18,0.2-0.27,0.3c-0.07,0.07-0.07,0.12-0.04,0.21c0.09,0.21,0.17,0.43,0.25,0.64
						c0.09,0.25,0.19,0.51,0.3,0.76c0.11,0.25,0.08,0.43-0.09,0.63c-0.34,0.39-0.68,0.8-1.01,1.2c-0.14,0.17-0.29,0.35-0.43,0.52
						c-0.05,0.06-0.07,0.13-0.09,0.21l-0.01,0.05c-0.09,0.28-0.18,0.58-0.26,0.87c-0.05,0.18-0.15,0.33-0.3,0.44
						c-0.24,0.18-0.47,0.37-0.7,0.55c-0.09,0.07-0.19,0.15-0.28,0.22L640.89,1824.47z M642.34,1817.88l0.08,0.06
						c-0.06,0.07-0.07,0.15-0.05,0.28c0.05,0.32,0.09,0.65,0.14,0.96c0.04,0.29,0.08,0.57,0.12,0.86c0.04,0.24-0.05,0.43-0.14,0.58
						c-0.03,0.05-0.06,0.11-0.09,0.16c-0.1,0.18-0.2,0.36-0.32,0.53c-0.36,0.47-0.45,1.05-0.51,1.61c-0.03,0.29-0.11,0.54-0.26,0.79
						c-0.05,0.08-0.1,0.17-0.15,0.27c-0.01,0.02-0.02,0.04-0.03,0.06c0.23-0.18,0.46-0.37,0.7-0.55c0.12-0.09,0.19-0.19,0.23-0.34
						c0.08-0.29,0.17-0.59,0.26-0.87l0.01-0.05c0.03-0.09,0.06-0.19,0.13-0.28c0.15-0.17,0.29-0.35,0.43-0.52
						c0.33-0.4,0.67-0.81,1.01-1.2c0.12-0.14,0.14-0.24,0.06-0.42c-0.11-0.25-0.2-0.51-0.3-0.76c-0.08-0.21-0.16-0.43-0.25-0.64
						c-0.07-0.17-0.04-0.29,0.07-0.42c0.09-0.1,0.18-0.2,0.27-0.3c0.12-0.13,0.24-0.27,0.36-0.39c0.09-0.1,0.12-0.2,0.1-0.35
						c-0.05-0.28-0.09-0.57-0.13-0.85c0,0,0-0.01,0-0.01l-0.11,0.11c-0.07,0.07-0.13,0.14-0.2,0.21l-0.15,0.16
						c-0.41,0.44-0.84,0.89-1.22,1.38L642.34,1817.88z M635.53,1824.32c-0.02,0-0.03,0-0.05,0l-0.13-0.02l0.05-0.12
						c0.06-0.16,0.12-0.31,0.18-0.46c0.14-0.37,0.27-0.72,0.42-1.06l0.09,0.04l-0.09-0.04c0.11-0.26,0.3-0.44,0.48-0.62
						c0.06-0.06,0.12-0.11,0.17-0.17c0.19-0.2,0.28-0.38,0.28-0.59c0.01-0.52,0.28-0.85,0.59-1.17c0.02-0.02,0.04-0.05,0.06-0.08
						c0.06-0.08,0.13-0.19,0.27-0.21l0.1-0.02l0.02,0.23c0.01,0.07,0.01,0.13,0.02,0.2c0.26-0.87,0.73-1.59,1.2-2.25l0.26-0.36
						l-0.08,0.43c-0.04,0.24-0.09,0.47-0.09,0.72c0.19-0.46,0.29-0.89,0.31-1.32c0.01-0.24,0.04-0.49,0.06-0.73
						c0.01-0.13,0.02-0.25,0.03-0.38c0.01-0.1,0.03-0.22,0.11-0.3c0.12-0.12,0.24-0.23,0.37-0.36l0.35-0.34l-0.01,0.25
						c-0.01,0.25-0.1,0.43-0.18,0.58c-0.1,0.2-0.1,0.4-0.1,0.61c0,0.04,0,0.08,0,0.12c0,0.01,0,0.02,0,0.02
						c0.09-0.01,0.33-0.16,0.36-0.23l0.02-0.14c0.05-0.31,0.1-0.62,0.14-0.94c0.02-0.2,0.08-0.4,0.35-0.47
						c0.25-0.06,0.41-0.26,0.59-0.47c0.04-0.04,0.07-0.09,0.11-0.13c0.01-0.01,0.03-0.04,0.04-0.06c0.04-0.06,0.08-0.14,0.17-0.16
						c0.03-0.01,0.08-0.01,0.14,0.03c0.12,0.08,0.06,0.22,0.03,0.29c-0.01,0.03-0.03,0.06-0.03,0.08c-0.04,0.25-0.09,0.5-0.14,0.75
						l-0.02,0.12c-0.03,0.13,0,0.26,0.08,0.41c0.03,0.05,0.05,0.11,0.07,0.16c0.02,0.04,0.03,0.08,0.05,0.12
						c0.16,0.31,0.14,0.6-0.08,0.91c-0.21,0.31-0.32,0.63-0.32,0.97c0,0.22-0.14,0.38-0.25,0.51c-0.27,0.32-0.3,0.66-0.12,1.13
						c0.06,0.14,0.1,0.29,0.14,0.44c0.03,0.11,0.07,0.22,0.1,0.33c0.07,0.21,0.03,0.39-0.12,0.55c-0.09,0.08-0.17,0.17-0.25,0.26
						c-0.17,0.17-0.34,0.34-0.51,0.51c-0.15,0.14-0.33,0.1-0.47,0.08c-0.02-0.01-0.05-0.01-0.07-0.01
						c-0.26-0.04-0.42,0.11-0.61,0.29c-0.02,0.02-0.05,0.05-0.07,0.07c-0.14,0.13-0.27,0.26-0.4,0.39c-0.1,0.1-0.22,0.1-0.29,0.1
						c-0.09,0-0.19,0-0.28,0c-0.17,0-0.35,0-0.53,0c-0.2-0.01-0.35,0.06-0.46,0.22c-0.02,0.02-0.04,0.04-0.06,0.07
						c-0.02,0.02-0.04,0.04-0.05,0.06c-0.24,0.45-0.58,0.67-1.05,0.7c-0.16,0.01-0.33,0.13-0.49,0.25
						c-0.06,0.04-0.11,0.08-0.17,0.12c-0.01,0.01-0.02,0.01-0.03,0.02C635.71,1824.27,635.64,1824.32,635.53,1824.32z
						 M637.79,1820.08c-0.01,0.02-0.02,0.03-0.04,0.05c-0.03,0.04-0.05,0.07-0.08,0.11c-0.29,0.3-0.53,0.59-0.54,1.04
						c0,0.33-0.18,0.56-0.33,0.72c-0.06,0.06-0.12,0.12-0.18,0.18c-0.18,0.17-0.34,0.33-0.44,0.55c-0.15,0.34-0.28,0.69-0.41,1.06
						c-0.03,0.09-0.07,0.18-0.1,0.27c0.01-0.01,0.02-0.01,0.03-0.02c0.05-0.03,0.1-0.07,0.16-0.11c0.18-0.13,0.38-0.27,0.6-0.28
						c0.4-0.02,0.67-0.21,0.89-0.59c0.02-0.04,0.05-0.08,0.08-0.1c0.01-0.02,0.03-0.03,0.04-0.05c0.16-0.21,0.36-0.31,0.63-0.3
						c0.17,0.01,0.35,0,0.52,0c0.09,0,0.19,0,0.28,0c0.07,0,0.11,0,0.15-0.04c0.13-0.13,0.26-0.27,0.4-0.4
						c0.02-0.02,0.05-0.04,0.07-0.07c0.2-0.19,0.42-0.4,0.78-0.34c0.03,0,0.05,0.01,0.08,0.01c0.13,0.03,0.22,0.04,0.29-0.03
						c0.17-0.16,0.34-0.34,0.51-0.5c0.09-0.09,0.17-0.17,0.26-0.26c0.1-0.1,0.13-0.2,0.08-0.34c-0.04-0.11-0.07-0.23-0.11-0.34
						c-0.04-0.14-0.08-0.29-0.14-0.42c-0.21-0.54-0.16-0.96,0.15-1.33c0.1-0.12,0.2-0.24,0.2-0.39c0-0.38,0.11-0.74,0.35-1.08
						c0.18-0.26,0.2-0.46,0.07-0.71c-0.02-0.05-0.04-0.09-0.06-0.14c-0.02-0.05-0.04-0.09-0.06-0.13c-0.1-0.19-0.14-0.37-0.1-0.55
						l0.02-0.12c0.05-0.24,0.1-0.49,0.14-0.74c0,0,0,0,0,0c-0.04,0.04-0.07,0.08-0.11,0.13c-0.19,0.23-0.39,0.46-0.7,0.54
						c-0.14,0.03-0.18,0.1-0.2,0.3c-0.03,0.32-0.09,0.64-0.14,0.95l-0.02,0.14c-0.03,0.16-0.29,0.29-0.34,0.32
						c-0.21,0.1-0.28,0.06-0.31,0.05c-0.05-0.03-0.11-0.08-0.11-0.21c0-0.04,0-0.08,0-0.12c0-0.23,0-0.47,0.12-0.7
						c0.03-0.07,0.07-0.13,0.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.29c-0.04,0.04-0.05,0.11-0.06,0.18c-0.01,0.13-0.02,0.25-0.03,0.38
						c-0.02,0.23-0.05,0.48-0.06,0.72c-0.03,0.57-0.18,1.12-0.49,1.75l-0.14,0.28l-0.05-0.31c-0.04-0.27-0.04-0.51-0.01-0.74
						c-0.47,0.69-0.89,1.46-1.06,2.39l-0.06,0.33l-0.13-0.31c-0.06-0.14-0.06-0.28-0.06-0.4c0-0.05,0-0.1,0-0.14
						C637.81,1820.23,637.8,1820.16,637.79,1820.08z"/>
				</g>
				<g>
					<g>
						<path className="st42" d="M584.67,1854.97c-0.01-0.01-0.03-0.01-0.04-0.01l-0.01,0l0.1-0.28l-0.05,0.14l0.04-0.14
							c0.03,0.01,0.04,0.01,0.06,0.02L584.67,1854.97z"/>
					</g>
					<g>
						<path className="st42" d="M681.57,1874.13c-1.91,0-3.83-0.07-5.73-0.27c-0.87-0.09-1.59-0.11-2.26-0.08
							c-0.78,0.04-1.44,0.06-2.09,0.05l-0.28,0c-4.93-0.08-9.95-0.23-14.91-0.45c-3.8-0.17-8.33-0.52-12.7-0.86
							c-0.76-0.06-1.5-0.12-2.23-0.17c-9.75-0.55-19.58-1.46-28.43-2.63c-0.06-0.01-0.2-0.04-0.33-0.09l0,0
							c-0.03-0.01-0.06-0.02-0.09-0.04c-6.05-0.77-12.21-1.65-18.29-2.62v0c0,0-0.01,0-0.01,0c-3.37-0.54-6.81-1.12-10.22-1.73
							c-0.15-0.03-0.25-0.15-0.25-0.3l0-1.15c0-0.42,0-0.84,0.01-1.26l0.08-6.9l0.74-0.83l0-0.11l0.06-0.02l2.24-2.99l0.6-0.78
							c1.22-1.6,2.42-3.19,3.59-4.75c0.16-0.22,0.32-0.44,0.49-0.65l-0.02,0l0.76-0.99c4.19-5.66,7.91-10.93,11.04-15.67l0.01-0.02
							c0.08-0.08,0.14-0.16,0.2-0.24c0.33-0.43,0.83-0.54,1.27-0.63c0.18-0.04,0.34-0.07,0.49-0.12c1.63-0.56,2.93-1.24,4.06-2.14
							c0.41-0.33,0.93-0.47,1.43-0.61c0.27-0.08,0.55-0.15,0.81-0.26c1.25-0.51,2.33-1.33,3.37-2.13c0.26-0.2,0.52-0.4,0.79-0.59
							c0.32-0.23,0.64-0.32,0.94-0.41c0.22-0.06,0.42-0.12,0.61-0.22c0.23-0.13,0.46-0.26,0.7-0.39c0.95-0.52,1.93-1.06,2.74-1.8
							c0.18-0.16,0.47-0.43,0.81-0.27c0.85,0.41,1.59,0.13,2.37-0.18c0.39-0.15,0.8-0.31,1.21-0.37c0.08-0.01,0.17-0.06,0.26-0.1
							c0.14-0.07,0.29-0.14,0.45-0.13c1.66,0.14,2.96-0.6,4.34-1.38c0.52-0.3,1.06-0.6,1.61-0.85c1.02-0.46,2.09-0.84,3.13-1.2
							c1.21-0.43,2.46-0.87,3.63-1.44c1.3-0.63,2.32-1.61,3.05-2.39c1.99-2.1,4.01-2.87,6.34-2.42c0.97,0.19,1.94,0.43,2.88,0.67
							c1.22,0.31,2.49,0.63,3.74,0.82c2.47,0.38,4.72,1.38,6.89,2.35l0.16,0.07c0.82,0.37,1.66,0.55,2.48,0.72
							c0.37,0.08,0.76,0.16,1.14,0.26c2.36,0.62,4.63,1.58,6.83,2.51c0.36,0.15,0.72,0.3,1.08,0.46c1.14,0.48,2.22,1.1,3.27,1.7
							c0.9,0.52,1.84,1.06,2.81,1.5c0,0,0,0,0,0c1.91,0.42,3.89,0.54,5.8,0.66c1.99,0.13,4.04,0.26,6.04,0.71
							c3.06,0.69,6.23,1.14,9.3,1.58c0.84,0.12,1.71,0.24,2.56,0.37c3.85,0.57,8.38,1.36,12.77,2.95c2.59,0.94,5.02,1.58,7.41,1.97
							c0.04,0.01,0.26,0.01,0.51,0.02c0.62,0.01,1.64,0.03,2.81,0.11l1.49-0.9c0.1-0.08,0.2-0.15,0.29-0.23
							c0.19-0.15,0.32-0.21,0.43-0.18c0.06,0.02,0.11,0.06,0.14,0.12c0.19,0.43,0.31,0.43,0.32,0.43c0,0,0,0,0,0
							c0.09,0,0.23-0.3,0.28-0.41c0.05-0.11,0.09-0.19,0.14-0.25c3.19-3.43,9.6-6.09,13.84-7.85l0.26-0.11
							c2.71-1.13,5.54-2.07,8.28-2.98l11.39-3.78c3.22-1.07,6.87-2.28,9.39-4.9l0.24-0.25l0.01,0.35c0.15,3.6,0.13,7.27,0.12,10.81
							l0,0.36c0,0.08,0.01,0.16,0.01,0.24c0.02,0.23,0.03,0.49-0.08,0.73l0,0.38l0.13,0.32l-0.13,0.06l0,1.13
							c-0.01,2.14-0.01,4.35,0,6.52c0,0.05,0.03,0.71,0.03,0.71c0,0.35,0.01,0.7,0.02,1.03c0.01,0.37,0.02,0.75,0.02,1.13
							l-0.09,31.03c0,0.04,0,0.16,0.01,0.31c0.07,1.75,0.02,1.76-0.11,1.81c-6.66,2.32-13.64,3.72-20.75,4.17l-0.16,0.01l-5.64,1.13
							c-0.03,0-2.44,0.35-3.41,0.48c-1.13,0.16-2.28,0.31-3.4,0.46c-2.26,0.3-4.55,0.58-6.82,0.84c-0.3,0.03-0.6,0.07-0.9,0.1
							c-2.72,0.31-5.48,0.6-8.2,0.86c-0.45,0.04-0.9,0.1-1.34,0.15c-0.62,0.07-1.26,0.15-1.89,0.2c-0.69,0.05-1.4,0.09-2.08,0.12
							c-0.9,0.05-1.83,0.09-2.75,0.18c-0.33,0.03-0.66,0.06-0.98,0.09c-1.28,0.12-2.61,0.25-3.92,0.34
							c-5.23,0.36-10.57,0.64-16.33,0.83c-1.58,0.05-3.19,0.14-4.74,0.22C686.95,1873.99,684.26,1874.13,681.57,1874.13z
							 M674.2,1873.48c0.51,0,1.05,0.03,1.67,0.1c4.55,0.47,9.2,0.22,13.69-0.01c1.56-0.08,3.17-0.17,4.75-0.22
							c5.75-0.2,11.09-0.47,16.32-0.83c1.3-0.09,2.63-0.22,3.91-0.34c0.33-0.03,0.66-0.06,0.99-0.09c0.92-0.09,1.86-0.13,2.76-0.18
							c0.68-0.03,1.38-0.07,2.07-0.12c0.63-0.05,1.26-0.12,1.88-0.2c0.44-0.05,0.9-0.11,1.35-0.15c2.72-0.26,5.48-0.55,8.2-0.86
							c0.3-0.03,0.6-0.07,0.9-0.1c2.27-0.26,4.56-0.55,6.81-0.84c1.12-0.15,2.27-0.3,3.4-0.46c0.97-0.13,3.37-0.48,3.4-0.48
							l5.66-1.14l0.17-0.01c7.05-0.45,13.98-1.84,20.6-4.13c0.03-0.25,0-1.18-0.02-1.54c-0.01-0.16-0.01-0.28-0.01-0.32l0.09-31.03
							c0-0.38-0.01-0.76-0.02-1.12c-0.01-0.34-0.02-0.69-0.02-1.03l-0.03-0.6c0-0.05,0-0.08,0-0.12h0c-0.01-2.17-0.01-4.39,0-6.52
							l0-1.33l0.04-0.02l-0.04-0.11l0-0.5l0.02-0.03c0.09-0.18,0.08-0.39,0.06-0.61c-0.01-0.09-0.01-0.17-0.01-0.26l0-0.36
							c0.01-3.43,0.02-6.96-0.11-10.45c-2.56,2.5-6.12,3.68-9.27,4.73l-11.39,3.78c-2.73,0.91-5.56,1.85-8.26,2.97l-0.26,0.11
							c-4.22,1.75-10.59,4.4-13.73,7.78c-0.02,0.02-0.06,0.11-0.09,0.17c-0.12,0.24-0.28,0.58-0.55,0.58c0,0,0,0,0,0
							c-0.2,0-0.37-0.17-0.55-0.54c-0.03,0.02-0.08,0.05-0.15,0.1c-0.1,0.08-0.2,0.16-0.3,0.24l-0.01,0.01l-1.58,0.95l-0.05,0
							c-1.18-0.08-2.22-0.1-2.84-0.11c-0.36-0.01-0.5-0.01-0.56-0.02c-2.41-0.39-4.86-1.04-7.47-1.98
							c-4.37-1.58-8.88-2.37-12.71-2.94c-0.85-0.13-1.72-0.25-2.56-0.37c-3.07-0.43-6.25-0.88-9.32-1.58
							c-1.98-0.45-4.02-0.58-5.99-0.7c-1.69-0.11-3.44-0.22-5.15-0.53l0.01,0.01l-0.17,0.25l-0.13-0.09
							c-0.15-0.11-0.31-0.21-0.46-0.32c0,0-0.01,0-0.01-0.01c-0.98-0.44-1.92-0.99-2.83-1.51c-1.04-0.6-2.11-1.21-3.23-1.68
							c-0.36-0.15-0.72-0.3-1.08-0.46c-2.19-0.92-4.45-1.88-6.79-2.5c-0.37-0.1-0.76-0.18-1.12-0.26c-0.83-0.18-1.68-0.36-2.54-0.74
							l-0.16-0.07c-2.16-0.96-4.39-1.96-6.82-2.33c-1.27-0.19-2.54-0.51-3.77-0.82c-0.94-0.24-1.9-0.48-2.86-0.66
							c-2.22-0.43-4.15,0.31-6.06,2.33c-0.76,0.8-1.8,1.8-3.14,2.45c-1.19,0.58-2.45,1.02-3.66,1.45c-1.03,0.36-2.09,0.74-3.1,1.19
							c-0.54,0.24-1.07,0.54-1.59,0.84c-1.36,0.77-2.76,1.56-4.51,1.42c-0.08-0.01-0.18,0.04-0.29,0.1
							c-0.11,0.05-0.22,0.11-0.35,0.13c-0.39,0.06-0.76,0.2-1.15,0.35c-0.81,0.31-1.65,0.63-2.61,0.17
							c-0.13-0.06-0.27,0.03-0.47,0.22c-0.84,0.77-1.83,1.32-2.8,1.84c-0.23,0.13-0.46,0.26-0.69,0.39
							c-0.22,0.12-0.45,0.19-0.67,0.25c-0.28,0.08-0.57,0.16-0.85,0.36c-0.26,0.19-0.53,0.39-0.78,0.59
							c-1.06,0.8-2.15,1.63-3.44,2.16c-0.27,0.11-0.56,0.19-0.84,0.27c-0.5,0.14-0.96,0.27-1.33,0.56
							c-1.16,0.92-2.48,1.62-4.15,2.19c-0.17,0.06-0.35,0.1-0.53,0.13c-0.43,0.09-0.83,0.17-1.09,0.52
							c-0.06,0.08-0.13,0.16-0.21,0.25c-3.14,4.74-6.86,10.01-11.05,15.68l-0.48,0.63l0.01,0l-0.14,0.18
							c-0.21,0.28-0.42,0.56-0.62,0.84c-1.16,1.56-2.37,3.16-3.59,4.75l-0.6,0.78l-2.15,2.87l0.05,0.02l-0.88,0.99l-0.08,6.79
							c0,0.42,0,0.84-0.01,1.26l0,1.15c3.41,0.61,6.85,1.19,10.22,1.73c0.05,0,0.1,0,0.15,0l0.07,0l0,0.04
							c6.02,0.96,12.11,1.83,18.1,2.59l0.03,0l0.02,0.01c0.02,0.01,0.04,0.02,0.07,0.03l0,0c0.1,0.04,0.22,0.06,0.27,0.07
							c8.84,1.18,18.66,2.09,28.41,2.63c0.73,0.06,1.48,0.11,2.24,0.17c4.38,0.34,8.9,0.68,12.69,0.85
							c4.96,0.22,9.98,0.38,14.9,0.45l0.28,0c0.64,0.01,1.29-0.01,2.07-0.05C673.77,1873.48,673.98,1873.48,674.2,1873.48z"/>
					</g>
				</g>
				<g>
					<path className="st42" d="M751.88,1867.79h-8.4c-5.02,0-10.22-1.02-13.7-1.78c-4.98-1.09-9.93-2.65-14.72-4.16
						c-2.57-0.81-5.24-1.65-7.86-2.4c-0.01,0-2.17-0.44-3.2-0.66l-1.07-0.23l-1.37-0.71c-0.11-0.02-0.25-0.03-0.4-0.03
						c-0.48-0.02-1.14-0.05-1.32-0.67l-0.02-0.07l0.06-0.04c0.7-0.51,1.31-0.61,1.89-0.71c0.42-0.07,0.85-0.14,1.34-0.37
						c1.22-0.57,2.78-1.01,4.16-1.4c0.4-0.11,0.78-0.22,1.14-0.33c0.56-0.17,1.11-0.33,1.67-0.5c2.42-0.72,4.93-1.46,7.71-2.35
						c-0.69-1.05-1.21-1.29-1.94-1.61c-0.41-0.18-0.88-0.39-1.47-0.79c-1.29-0.88-4.46-1.29-4.49-1.29l-0.25-0.03l0.21-0.15
						c1.23-0.89,0.61-1.84,0.35-2.25c-0.01-0.02-0.02-0.03-0.03-0.05c-0.06-0.02-0.07-0.07-0.08-0.09c-0.01-0.1-0.02-0.15,1.28-0.54
						c0.74-0.22,1.61-0.46,2.26-0.62c0.88-0.21,1.97-0.55,3.02-0.87c1.06-0.32,2.15-0.66,3.03-0.88c1.8-0.44,3.46-0.87,5.07-1.28
						c1.61-0.41,3.27-0.84,5.07-1.28c1.36-0.33,3.03-0.58,4.65-0.83c1.43-0.21,2.9-0.44,4.15-0.71c-0.41-0.28-1.35-1.09-3.68-3.44
						l-0.09-0.09c-0.74-0.75-2.74-3.04-2.76-3.06l-0.09-0.11l0.13-0.05c0.19-0.07,0.51-0.21,0.88-0.36
						c0.76-0.32,1.71-0.72,2.13-0.83c0.36-0.09,0.71-0.19,1.06-0.29c0.75-0.2,1.52-0.41,2.29-0.58c1.12-0.24,2.23-0.48,3.35-0.72
						c0.73-0.16,2.85-0.85,2.87-0.86l0.02-0.01l0.03,0.01c0.46,0.09,0.67,0.36,0.86,0.61c0.26,0.33,0.44,0.56,1.15,0.32
						c1.16-0.41,2.28-0.8,3.36-1.18c5.18-1.83,9.28-3.28,11.29-3.35c3.1-0.1,5.95-1.83,8.45-3.36c0.99-0.6,1.93-1.18,2.85-1.64
						l0.09,0.18c-0.91,0.46-1.85,1.03-2.83,1.63c-2.53,1.54-5.4,3.29-8.55,3.39c-1.99,0.06-6.07,1.51-11.23,3.34
						c-1.08,0.38-2.2,0.78-3.36,1.18c-0.84,0.29-1.11-0.05-1.37-0.38c-0.17-0.22-0.35-0.45-0.71-0.53
						c-0.26,0.08-2.16,0.71-2.87,0.86c-1.12,0.24-2.23,0.48-3.35,0.72c-0.77,0.16-1.54,0.37-2.28,0.58
						c-0.35,0.1-0.71,0.19-1.07,0.29c-0.41,0.11-1.35,0.5-2.11,0.82c-0.3,0.12-0.56,0.24-0.76,0.31c0.4,0.46,2.01,2.3,2.66,2.95
						l0.09,0.09c3.2,3.23,3.74,3.51,3.83,3.51c0,0,0,0-0.01,0l0.05,0.19c-1.31,0.31-2.9,0.55-4.45,0.78
						c-1.61,0.24-3.28,0.49-4.63,0.82c-1.8,0.44-3.46,0.87-5.07,1.28c-1.61,0.41-3.27,0.84-5.07,1.28
						c-0.88,0.21-1.97,0.55-3.02,0.87c-1.06,0.33-2.15,0.66-3.04,0.88c-1.27,0.31-2.91,0.8-3.33,0.97c0,0.01,0.01,0.01,0.01,0.02
						c0.25,0.4,0.91,1.42-0.21,2.37c0.77,0.11,3.21,0.51,4.35,1.29c0.57,0.39,1.03,0.59,1.44,0.77c0.75,0.33,1.34,0.59,2.09,1.79
						l0.07,0.11l-0.12,0.04c-2.84,0.91-5.38,1.66-7.84,2.39c-0.56,0.17-1.12,0.33-1.67,0.5c-0.36,0.11-0.74,0.22-1.14,0.33
						c-1.37,0.39-2.92,0.83-4.13,1.39c-0.51,0.24-0.96,0.31-1.39,0.39c-0.57,0.1-1.1,0.19-1.74,0.64c0.16,0.42,0.67,0.44,1.12,0.46
						c0.16,0.01,0.32,0.01,0.45,0.04l0.02,0.01l1.37,0.71l1.03,0.22c1.03,0.22,3.19,0.66,3.21,0.67c2.64,0.76,5.3,1.59,7.88,2.4
						c4.79,1.51,9.74,3.06,14.71,4.15c3.47,0.76,8.65,1.77,13.65,1.77h8.4V1867.79z"/>
				</g>
				<g>
					<path className="st42" d="M641.86,1872.31l-1.08-0.12c-0.07-0.01-0.2-0.02-0.28-0.12c-0.04-0.05-0.04-0.12-0.04-0.16
						c-0.31-0.07-0.47-0.17-0.53-0.31c-0.07-0.17,0.04-0.37,0.17-0.56c0.55-0.8,1.39-0.74,2-0.7c0.2,0.02,0.39,0.03,0.53,0.01
						c0.96-0.13,1.95-0.16,2.9-0.19c0.19-0.01,0.37-0.01,0.56-0.02c2.9-0.09,5.85-0.17,8.77-0.25c0.08,0,0.2,0,0.33,0
						c0.32,0,1.01,0,1.24-0.06c-1.95-1.28-7.14-1.83-9.09-2.05c-0.31-0.03-0.53-0.06-0.65-0.07c-0.02,0-1.65-0.24-3.25-0.51
						c-3.01-0.51-3.06-0.65-3.1-0.75c-0.02-0.04-0.01-0.09,0.02-0.12c0.14-0.17,0.2-0.29,0.19-0.35c-0.01-0.07-0.15-0.14-0.32-0.23
						c-0.25-0.13-0.59-0.31-0.96-0.67l-0.12-0.12l0.17-0.04c1.52-0.38,2.41-0.48,3.06-0.56c0.11-0.01,0.22-0.03,0.32-0.04
						c3.78-0.48,7.33-0.52,10.75-0.56c2.46-0.03,5-0.06,7.57-0.26c1.11-0.08,2.05-0.13,2.89-0.17c2.89-0.14,4.64-0.23,8.25-1.69
						c0.71-0.29,4.95-2.03,5.7-2.35c0.13-0.05,0.4-0.22,0.7-0.4c0.23-0.14,0.48-0.29,0.69-0.4c-0.09-0.01-0.19-0.02-0.29-0.03
						c-0.46-0.05-1.04-0.12-1.29-0.17c-0.03-0.01-0.14-0.03-0.14-0.12c-0.01-0.11,0.1-0.14,0.36-0.22c0.18-0.06,0.44-0.14,0.53-0.22
						c0.17-0.16,0.25-0.3,0.24-0.42c-0.01-0.09-0.07-0.17-0.18-0.24c-0.34-0.22-0.96-0.33-1.45-0.42c-0.15-0.03-0.3-0.05-0.42-0.08
						c-1.6-0.34-2.95-1.03-4.32-1.77c-1.04-0.56-2.21-0.87-3.34-1.16c-0.27-0.07-0.55-0.14-0.82-0.22c-1.89-0.52-3.58-0.91-5.16-1.2
						c-4.49-0.81-9.09-1.03-13.38-1.15c-2.33-0.06-4.49-0.1-6.59-0.1c-0.05,0-0.1,0-0.16,0c-10.28,0-21.12,0.56-32.24,1.67
						c-0.07,0.01-0.26,0.05-0.48,0.1c-0.76,0.16-1.21,0.24-1.49,0.26c0.81,0.72,1.94,0.85,3.04,0.99c0.35,0.04,0.72,0.09,1.07,0.15
						c1.49,0.27,3.1,0.59,4.92,0.98c1.94,0.41,3.9,0.86,5.8,1.29c1.23,0.28,2.49,0.56,3.74,0.84c0,0,0.01-0.01,0.03-0.02
						c0.04-0.02,0.1-0.05,0.17-0.03l0.45,0.08c0.55,0.22,1.03,0.78,1.38,1.24l0.55,0.72l7.46,0.33c0.14,0.01,0.23,0.11,0.23,0.23
						c0,0.14-0.11,0.31-0.3,0.34c-0.14,0.02-1.59,0.18-2.28,0.26l-0.44,0.05c-1.31,0.15-2.94,0.33-3.9,1.24
						c-0.19,0.18-0.21,0.38-0.22,0.59c-0.01,0.17-0.03,0.35-0.14,0.52c-0.14,0.22-0.41,0.31-0.64,0.39c-0.1,0.03-0.19,0.06-0.26,0.1
						l-1.24,0.61c-1.31,0.65-2.79,0.86-4.22,1.07c-0.21,0.03-0.43,0.06-0.64,0.09c-1.8,0.27-3.63,0.48-5.44,0.62
						c-0.46,0.04-1.19,0.16-1.69,0.35c-0.29,0.11-0.45,0.3-0.61,0.49c-0.18,0.22-0.37,0.44-0.73,0.56
						c-0.84,0.27-2.23,0.95-2.06,2.38l-0.2,0.02c-0.08-0.67,0.1-1.91,2.2-2.59c0.31-0.1,0.47-0.29,0.64-0.5
						c0.18-0.21,0.36-0.42,0.69-0.55c0.52-0.2,1.27-0.33,1.75-0.37c1.81-0.14,3.63-0.35,5.43-0.62c0.21-0.03,0.43-0.06,0.64-0.09
						c1.42-0.2,2.88-0.41,4.16-1.05l1.24-0.61c0.09-0.04,0.19-0.08,0.29-0.11c0.21-0.07,0.43-0.15,0.53-0.31
						c0.08-0.13,0.09-0.27,0.11-0.43c0.02-0.23,0.04-0.48,0.29-0.72c1-0.95,2.67-1.14,4.01-1.29l0.44-0.05
						c0.64-0.07,2.14-0.24,2.28-0.26c0.08-0.01,0.13-0.09,0.13-0.14c0-0.02,0-0.04-0.04-0.04l-7.55-0.34l-0.61-0.79
						c-0.34-0.44-0.79-0.97-1.29-1.18l-0.44-0.07c-0.01,0-0.02,0.01-0.03,0.01c-0.04,0.02-0.09,0.05-0.16,0.03
						c-1.25-0.28-2.52-0.56-3.74-0.84c-1.9-0.43-3.86-0.87-5.8-1.28c-1.82-0.39-3.43-0.71-4.92-0.97c-0.34-0.06-0.7-0.1-1.05-0.15
						c-1.23-0.15-2.5-0.3-3.37-1.26c0,0,0,0,0,0l0.15-0.13c0.14,0.08,1.2-0.15,1.66-0.25c0.25-0.05,0.43-0.09,0.5-0.1
						c11.12-1.11,21.97-1.67,32.26-1.67c0.05,0,0.1,0,0.16,0c2.1,0,4.26,0.03,6.59,0.1c4.3,0.12,8.9,0.34,13.41,1.15
						c1.58,0.29,3.27,0.68,5.18,1.2c0.27,0.07,0.54,0.15,0.81,0.22c1.14,0.3,2.33,0.61,3.39,1.18c1.35,0.73,2.69,1.41,4.26,1.75
						c0.12,0.03,0.27,0.05,0.42,0.08c0.51,0.09,1.15,0.2,1.53,0.45c0.17,0.11,0.26,0.24,0.27,0.39c0.02,0.18-0.09,0.38-0.31,0.58
						c-0.12,0.11-0.38,0.2-0.6,0.27c0,0-0.01,0-0.01,0c0.28,0.04,0.7,0.09,1.04,0.13c0.43,0.05,0.59,0.07,0.63,0.08l0.25,0.08
						l-0.24,0.11c-0.23,0.1-0.61,0.33-0.95,0.54c-0.31,0.19-0.58,0.35-0.72,0.41c-0.75,0.32-4.99,2.06-5.7,2.35
						c-3.65,1.48-5.4,1.56-8.32,1.71c-0.83,0.04-1.77,0.09-2.88,0.17c-2.58,0.2-5.12,0.23-7.58,0.26
						c-3.42,0.04-6.96,0.08-10.73,0.56c-0.1,0.01-0.21,0.03-0.32,0.04c-0.62,0.07-1.46,0.17-2.86,0.51c0.29,0.26,0.57,0.4,0.78,0.51
						c0.23,0.12,0.39,0.21,0.43,0.37c0.02,0.12-0.03,0.26-0.18,0.45c0.49,0.23,3.83,0.79,6.16,1.12c0.11,0.02,0.33,0.04,0.64,0.07
						c1.98,0.21,7.22,0.78,9.2,2.09c0.12,0.08,0.12,0.15,0.11,0.21c-0.05,0.16-0.35,0.21-1.48,0.21c-0.13,0-0.24,0-0.32,0
						c-2.91,0.07-5.86,0.15-8.77,0.25c-0.19,0.01-0.37,0.01-0.56,0.02c-0.95,0.03-1.93,0.05-2.88,0.19
						c-0.16,0.02-0.36,0.01-0.57-0.01c-0.59-0.04-1.33-0.1-1.82,0.61c-0.13,0.19-0.18,0.31-0.15,0.38c0.03,0.08,0.18,0.15,0.44,0.2
						c0.12,0.03,0.11,0.15,0.11,0.19c0,0.01,0,0.02,0,0.03c0.03,0.02,0.08,0.03,0.14,0.04l1.08,0.12L641.86,1872.31z"/>
				</g>
				<g>
					<path className="st42" d="M673.89,1873.59l-0.38-0.39l-2.79-0.43l-0.01-0.01c-0.15-0.08-0.24-0.21-0.27-0.38
						c-0.02-0.16,0.03-0.31,0.14-0.42c0.2-0.4,0.12-0.89-0.19-1.21l-0.39-0.2l-8.77,0.02c0.03,0.02,0.06,0.04,0.09,0.06
						c0.06,0.04,0.12,0.08,0.16,0.11l0.28,0.22l-3.16,1.29l-2.24,1.08l-0.09-0.18l2.24-1.08l2.85-1.16l0,0
						c-0.04-0.03-0.09-0.07-0.15-0.1c-0.23-0.15-0.34-0.23-0.31-0.34c0.01-0.03,0.04-0.09,0.14-0.09l9.01-0.02l0.47,0.24
						c0.39,0.38,0.49,0.98,0.23,1.47l-0.02,0.03c-0.07,0.07-0.1,0.17-0.09,0.26c0.01,0.09,0.07,0.17,0.15,0.22l2.82,0.44l0.43,0.44
						L673.89,1873.59z"/>
				</g>
				<g>
					<path className="st42" d="M625.14,1868.69c-0.35,0-0.69,0-1.04-0.01c-2.74-0.06-5.42-0.16-8.07-0.74c-0.39-0.08-1.27,0.06-1.64,0.2
						l-0.07,0.03l-0.05-0.06c-0.06-0.08-0.02-0.13,0.26-0.53c0.04-0.06,0.08-0.11,0.09-0.13c0.08-0.13,0.15-0.34,0.2-0.51
						c0.01-0.04,0.02-0.07,0.04-0.11l0.19,0.06c-0.01,0.03-0.02,0.07-0.04,0.11c-0.06,0.18-0.13,0.4-0.22,0.55
						c-0.01,0.02-0.04,0.06-0.1,0.14c-0.03,0.05-0.08,0.12-0.13,0.18c0.45-0.12,1.15-0.21,1.51-0.13c2.63,0.57,5.3,0.67,8.03,0.73
						c1.35,0.03,2.74,0,4.13-0.09c0.38-0.02,0.85,0,1.34,0.02c1.04,0.04,2.2,0.09,2.95-0.25c-0.29-0.23-1.43-0.85-1.88-1.1
						c-0.28-0.15-0.37-0.2-0.39-0.22c-1.47-1.07-2.36-1.59-4.14-1.66c-0.17-0.01-0.29-0.09-0.34-0.24l-0.02-0.05l0.04-0.04
						c0.2-0.23,0.47-0.38,0.73-0.53c0.09-0.05,0.17-0.1,0.26-0.15l0.1,0.17c-0.08,0.05-0.17,0.1-0.26,0.15
						c-0.23,0.13-0.46,0.26-0.63,0.44c0.02,0.03,0.06,0.05,0.13,0.05c1.75,0.07,2.68,0.56,4.24,1.69c0.02,0.02,0.19,0.11,0.37,0.21
						c1.94,1.07,2.04,1.19,2.04,1.3c0,0.04-0.02,0.08-0.06,0.1c-0.79,0.43-2.04,0.38-3.15,0.33c-0.49-0.02-0.95-0.04-1.32-0.02
						C627.21,1868.66,626.17,1868.69,625.14,1868.69z"/>
				</g>
				<g>
					<path className="st42" d="M625.58,1859.06c-0.04-0.01-4.19-1.43-5.77-0.93c-0.24,0.08-2.77-0.13-3.58-0.2l-1.27,0.33
						c-0.51,0.13-1.04,0.16-1.56,0.07c-0.35-0.06-0.78-0.15-1.19-0.25c-0.66-0.15-1.33-0.3-1.6-0.28c-0.01,0-0.03,0-0.05,0
						c-0.94,0-6.6-1.6-7.07-1.78c-0.52-0.19-3.46-1.44-3.74-1.55c-0.27-0.05-3.65-0.62-4.66-0.56c-1.11,0.07-4.76-0.76-5.14-0.85
						c-0.38-0.1-2.9-1.08-3.01-1.12l0.11-0.28c0.9,0.35,2.68,1.04,2.97,1.11c0.36,0.09,4,0.91,5.05,0.84
						c1.08-0.07,4.6,0.54,4.75,0.57l0.03,0.01c0.03,0.01,3.21,1.36,3.74,1.55c0.51,0.19,6.32,1.81,6.99,1.76
						c0.32-0.03,0.99,0.12,1.7,0.29c0.41,0.09,0.84,0.19,1.18,0.25c0.48,0.08,0.97,0.06,1.44-0.06l1.32-0.34l0.03,0
						c1.24,0.11,3.29,0.26,3.49,0.21c1.67-0.53,5.78,0.87,5.95,0.93L625.58,1859.06z"/>
				</g>
				<g>
					<path className="st42" d="M661.55,1851.99l-0.12-0.16l1.7-1.24l5.39-1.08l7.23-1.14h5.25l4.05-0.62l3.84-0.96l1.23,0.09
						c1.02,0.21,2.97,0.61,3.1,0.63c0.12,0.02,1.75-0.03,2.83-0.07c0.18-0.06,1.96-0.69,2.68-1.32c0.77-0.67,5.29-3.94,5.49-4.08
						l6.03-4.34l0.12,0.16l-6.03,4.34c-0.05,0.03-4.71,3.41-5.47,4.07c-0.78,0.68-2.68,1.33-2.76,1.36l-0.03,0.01
						c-0.28,0.01-2.72,0.09-2.88,0.07c-0.16-0.02-2.91-0.59-3.1-0.63l-1.18-0.08l-3.82,0.95l-4.09,0.63h-5.25l-7.21,1.13l-5.35,1.07
						L661.55,1851.99z"/>
				</g>
				<g>
					<path className="st42" d="M657.13,1870.59c-1.45,0-2.27-0.45-2.33-0.53l0.16-0.12l-0.08,0.06l0.07-0.07
						c0.01,0.01,1.37,0.87,4.04,0.24c0.41-0.1,1.9,0.17,2.07,0.21l-0.04,0.2c-0.45-0.08-1.68-0.28-1.99-0.21
						C658.31,1870.53,657.67,1870.59,657.13,1870.59z"/>
				</g>
			</g>
		</g>
		<g id="Hills">
			<g>
				<path className="st123" d="M658.18,1972.26c0.24,0.01,0.33,0.02,0.42,0.02c6.84,0,13.68,0,20.52,0.01c0.36,0,0.48-0.14,0.61-0.45
					c0.6-1.42,1.16-2.87,1.89-4.22c2.19-4.06,4.92-7.77,7.76-11.39c0.11-0.14,0.21-0.28,0.32-0.44c-2.03-2.5-4.08-4.94-6.42-7.11
					c-0.88-0.81-1.85-1.52-2.81-2.23c-0.75-0.55-1.58-0.57-2.34-0.02c-0.95,0.69-1.92,1.39-2.78,2.19c-3,2.8-5.59,5.97-8.09,9.22
					c-2.95,3.84-5.77,7.77-8.01,12.07C658.87,1970.65,658.56,1971.42,658.18,1972.26z M713.24,1972.25
					c-0.32-0.7-0.58-1.35-0.92-1.97c-3.01-5.54-6.62-10.65-10.9-15.28c-0.65-0.7-1.42-1.3-2.16-1.91c-0.64-0.53-1.35-0.54-1.99,0
					c-0.81,0.68-1.64,1.34-2.35,2.11c-3.85,4.17-7.12,8.78-9.95,13.69c-0.61,1.06-1.13,2.18-1.73,3.35
					C693.3,1972.25,703.22,1972.25,713.24,1972.25z"/>
				<path className="st123" d="M658.18,1972.26c0.38-0.84,0.69-1.61,1.07-2.35c2.24-4.31,5.06-8.23,8.01-12.07
					c2.5-3.25,5.09-6.42,8.09-9.22c0.86-0.8,1.83-1.49,2.78-2.19c0.76-0.55,1.59-0.53,2.34,0.02c0.96,0.71,1.94,1.42,2.81,2.23
					c2.34,2.16,4.39,4.6,6.42,7.11c-0.11,0.15-0.21,0.3-0.32,0.44c-2.84,3.62-5.57,7.33-7.76,11.39c-0.73,1.35-1.28,2.8-1.89,4.22
					c-0.13,0.31-0.26,0.45-0.61,0.45c-6.84-0.01-13.68-0.01-20.52-0.01C658.51,1972.28,658.42,1972.27,658.18,1972.26z"/>
				<path className="st123" d="M713.24,1972.25c-10.02,0-19.94,0-30.01,0c0.6-1.17,1.12-2.29,1.73-3.35c2.83-4.91,6.1-9.52,9.95-13.69
					c0.71-0.77,1.55-1.43,2.35-2.11c0.64-0.54,1.35-0.53,1.99,0c0.74,0.61,1.51,1.21,2.16,1.91c4.28,4.63,7.89,9.74,10.9,15.28
					C712.66,1970.9,712.92,1971.56,713.24,1972.25z"/>
			</g>
			<g>
				<path className="st123" d="M688.11,1985.72c0.27,0.01,0.36,0.02,0.46,0.02c7.52,0,15.04,0,22.56,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.56,1.27-3.15,2.08-4.64c2.41-4.47,5.41-8.54,8.53-12.52c0.12-0.15,0.23-0.31,0.35-0.48c-2.23-2.75-4.48-5.44-7.06-7.82
					c-0.96-0.89-2.03-1.68-3.09-2.45c-0.82-0.61-1.74-0.63-2.57-0.02c-1.05,0.76-2.11,1.52-3.05,2.41c-3.3,3.08-6.15,6.56-8.9,10.13
					c-3.24,4.22-6.34,8.54-8.81,13.27C688.87,1983.95,688.53,1984.8,688.11,1985.72z M748.66,1985.71
					c-0.35-0.77-0.64-1.49-1.01-2.17c-3.3-6.09-7.28-11.71-11.98-16.8c-0.71-0.77-1.56-1.43-2.38-2.1c-0.71-0.58-1.48-0.59-2.19,0
					c-0.89,0.74-1.8,1.47-2.59,2.32c-4.23,4.59-7.83,9.65-10.94,15.05c-0.67,1.17-1.24,2.4-1.9,3.69
					C726.73,1985.71,737.64,1985.71,748.66,1985.71z"/>
				<path className="st123" d="M688.11,1985.72c0.42-0.92,0.76-1.77,1.18-2.58c2.47-4.73,5.56-9.05,8.81-13.27
					c2.74-3.57,5.59-7.06,8.9-10.13c0.95-0.88,2.01-1.64,3.05-2.41c0.83-0.61,1.75-0.59,2.57,0.02c1.06,0.78,2.13,1.56,3.09,2.45
					c2.57,2.38,4.82,5.06,7.06,7.82c-0.12,0.17-0.23,0.33-0.35,0.48c-3.13,3.98-6.12,8.06-8.53,12.52c-0.8,1.49-1.41,3.08-2.08,4.64
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.52-0.01-15.04-0.01-22.56-0.01C688.48,1985.74,688.38,1985.73,688.11,1985.72z"/>
				<path className="st123" d="M748.66,1985.71c-11.02,0-21.93,0-32.99,0c0.66-1.29,1.23-2.52,1.9-3.69c3.12-5.4,6.71-10.46,10.94-15.05
					c0.78-0.85,1.7-1.57,2.59-2.32c0.71-0.59,1.48-0.58,2.19,0c0.82,0.67,1.66,1.33,2.38,2.1c4.71,5.09,8.68,10.71,11.98,16.8
					C748.02,1984.23,748.31,1984.95,748.66,1985.71z"/>
			</g>
			<g>
				<path className="st123" d="M1163.21,1608.53c0.27,0.01,0.36,0.02,0.46,0.02c7.52,0,15.04,0,22.56,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.56,1.27-3.15,2.08-4.64c2.41-4.47,5.41-8.54,8.53-12.52c0.12-0.15,0.23-0.31,0.35-0.48c-2.23-2.75-4.48-5.44-7.06-7.82
					c-0.96-0.89-2.03-1.68-3.09-2.45c-0.82-0.61-1.74-0.63-2.57-0.02c-1.05,0.76-2.11,1.52-3.05,2.41c-3.3,3.08-6.15,6.56-8.9,10.13
					c-3.24,4.22-6.34,8.54-8.81,13.27C1163.97,1606.76,1163.63,1607.61,1163.21,1608.53z M1223.75,1608.52
					c-0.35-0.77-0.64-1.49-1.01-2.17c-3.3-6.09-7.28-11.71-11.98-16.8c-0.71-0.77-1.56-1.43-2.38-2.1c-0.71-0.58-1.48-0.59-2.19,0
					c-0.89,0.74-1.81,1.47-2.59,2.31c-4.23,4.59-7.83,9.65-10.94,15.05c-0.67,1.17-1.24,2.4-1.9,3.69
					C1201.83,1608.52,1212.73,1608.52,1223.75,1608.52z"/>
				<path className="st123" d="M1163.21,1608.53c0.42-0.92,0.76-1.77,1.18-2.58c2.47-4.73,5.56-9.05,8.81-13.27
					c2.74-3.57,5.59-7.06,8.9-10.13c0.95-0.88,2.01-1.64,3.05-2.41c0.83-0.61,1.75-0.59,2.57,0.02c1.06,0.78,2.13,1.56,3.09,2.45
					c2.57,2.38,4.82,5.06,7.06,7.82c-0.12,0.17-0.23,0.33-0.35,0.48c-3.13,3.98-6.12,8.06-8.53,12.52c-0.8,1.49-1.41,3.08-2.08,4.64
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.52-0.01-15.04-0.01-22.56-0.01C1163.57,1608.55,1163.47,1608.54,1163.21,1608.53z"/>
				<path className="st123" d="M1223.75,1608.52c-11.02,0-21.93,0-32.99,0c0.66-1.29,1.23-2.52,1.9-3.69
					c3.12-5.4,6.71-10.46,10.94-15.05c0.78-0.85,1.7-1.57,2.59-2.31c0.71-0.59,1.48-0.58,2.19,0c0.82,0.67,1.66,1.33,2.38,2.1
					c4.71,5.09,8.68,10.71,11.98,16.8C1223.11,1607.04,1223.4,1607.76,1223.75,1608.52z"/>
			</g>
			<g>
				<path className="st123" d="M1032.76,1895.58c0.27,0.01,0.36,0.02,0.46,0.02c7.52,0,15.04,0,22.56,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.56,1.27-3.15,2.08-4.64c2.41-4.47,5.41-8.54,8.53-12.52c0.12-0.15,0.23-0.31,0.35-0.48c-2.23-2.75-4.48-5.44-7.06-7.82
					c-0.96-0.89-2.03-1.68-3.09-2.45c-0.82-0.61-1.74-0.63-2.57-0.02c-1.05,0.76-2.11,1.52-3.05,2.41c-3.3,3.08-6.15,6.56-8.9,10.13
					c-3.24,4.22-6.34,8.54-8.81,13.27C1033.52,1893.8,1033.18,1894.65,1032.76,1895.58z M1093.31,1895.57
					c-0.35-0.77-0.64-1.49-1.01-2.17c-3.3-6.09-7.28-11.71-11.98-16.8c-0.71-0.77-1.56-1.43-2.38-2.1c-0.71-0.58-1.48-0.59-2.19,0
					c-0.89,0.74-1.81,1.47-2.59,2.31c-4.23,4.59-7.83,9.65-10.94,15.05c-0.67,1.17-1.24,2.4-1.9,3.69
					C1071.38,1895.57,1082.29,1895.57,1093.31,1895.57z"/>
				<path className="st123" d="M1032.76,1895.58c0.42-0.92,0.76-1.77,1.18-2.58c2.47-4.73,5.56-9.05,8.81-13.27
					c2.74-3.57,5.59-7.06,8.9-10.13c0.95-0.88,2.01-1.64,3.05-2.41c0.83-0.61,1.75-0.59,2.57,0.02c1.06,0.78,2.13,1.56,3.09,2.45
					c2.57,2.38,4.82,5.06,7.06,7.82c-0.12,0.17-0.23,0.33-0.35,0.48c-3.13,3.98-6.12,8.06-8.53,12.52c-0.8,1.49-1.41,3.08-2.08,4.64
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.52-0.01-15.04-0.01-22.56-0.01C1033.13,1895.6,1033.03,1895.59,1032.76,1895.58z"/>
				<path className="st123" d="M1093.31,1895.57c-11.02,0-21.93,0-32.99,0c0.66-1.29,1.23-2.52,1.9-3.69
					c3.12-5.4,6.71-10.46,10.94-15.05c0.78-0.85,1.7-1.57,2.59-2.31c0.71-0.59,1.48-0.58,2.19,0c0.82,0.67,1.66,1.33,2.38,2.1
					c4.71,5.09,8.68,10.71,11.98,16.8C1092.67,1894.08,1092.95,1894.8,1093.31,1895.57z"/>
			</g>
			<g>
				<path className="st123" d="M925.99,1527.19c0.27,0.01,0.36,0.02,0.46,0.02c7.52,0,15.04,0,22.56,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.56,1.27-3.15,2.08-4.64c2.41-4.47,5.41-8.54,8.53-12.52c0.12-0.15,0.23-0.31,0.35-0.48c-2.23-2.75-4.48-5.44-7.06-7.82
					c-0.96-0.89-2.03-1.68-3.09-2.45c-0.82-0.61-1.74-0.63-2.57-0.02c-1.05,0.76-2.11,1.52-3.05,2.41c-3.3,3.08-6.15,6.56-8.9,10.13
					c-3.24,4.22-6.34,8.54-8.81,13.27C926.75,1525.42,926.41,1526.27,925.99,1527.19z M986.54,1527.18
					c-0.35-0.77-0.64-1.49-1.01-2.17c-3.3-6.09-7.28-11.71-11.98-16.8c-0.71-0.77-1.56-1.43-2.38-2.1c-0.71-0.58-1.48-0.59-2.19,0
					c-0.89,0.74-1.81,1.47-2.59,2.31c-4.23,4.59-7.83,9.65-10.94,15.05c-0.67,1.17-1.24,2.4-1.9,3.69
					C964.61,1527.18,975.52,1527.18,986.54,1527.18z"/>
				<path className="st123" d="M925.99,1527.19c0.42-0.92,0.76-1.77,1.18-2.58c2.47-4.73,5.56-9.05,8.81-13.27
					c2.74-3.57,5.59-7.06,8.9-10.13c0.95-0.88,2.01-1.64,3.05-2.41c0.83-0.61,1.75-0.59,2.57,0.02c1.06,0.78,2.13,1.56,3.09,2.45
					c2.57,2.38,4.82,5.06,7.06,7.82c-0.12,0.17-0.23,0.33-0.35,0.48c-3.13,3.98-6.12,8.06-8.53,12.52c-0.8,1.49-1.41,3.08-2.08,4.64
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.52-0.01-15.04-0.01-22.56-0.01C926.36,1527.21,926.26,1527.2,925.99,1527.19z"/>
				<path className="st123" d="M986.54,1527.18c-11.02,0-21.93,0-32.99,0c0.66-1.29,1.23-2.52,1.9-3.69c3.12-5.4,6.71-10.46,10.94-15.05
					c0.78-0.85,1.7-1.57,2.59-2.31c0.71-0.59,1.48-0.58,2.19,0c0.82,0.67,1.66,1.33,2.38,2.1c4.71,5.09,8.68,10.71,11.98,16.8
					C985.9,1525.69,986.18,1526.42,986.54,1527.18z"/>
			</g>
			<g>
				<path className="st123" d="M880.87,1857.33c0.18,0.01,0.24,0.01,0.3,0.01c4.95,0,9.9,0,14.85,0.01c0.26,0,0.35-0.1,0.44-0.32
					c0.44-1.03,0.84-2.08,1.37-3.05c1.58-2.94,3.56-5.62,5.61-8.24c0.08-0.1,0.15-0.21,0.23-0.32c-1.47-1.81-2.95-3.58-4.64-5.14
					c-0.63-0.59-1.34-1.1-2.04-1.62c-0.54-0.4-1.14-0.41-1.69-0.01c-0.69,0.5-1.39,1-2.01,1.58c-2.17,2.03-4.05,4.32-5.85,6.67
					c-2.13,2.78-4.17,5.62-5.8,8.73C881.37,1856.16,881.15,1856.72,880.87,1857.33z M920.72,1857.33c-0.23-0.5-0.42-0.98-0.66-1.42
					c-2.17-4.01-4.79-7.7-7.89-11.05c-0.47-0.51-1.03-0.94-1.56-1.38c-0.47-0.38-0.98-0.39-1.44,0c-0.58,0.49-1.19,0.97-1.7,1.52
					c-2.78,3.02-5.15,6.35-7.2,9.91c-0.44,0.77-0.82,1.58-1.25,2.43C906.28,1857.33,913.46,1857.33,920.72,1857.33z"/>
				<path className="st123" d="M880.87,1857.33c0.28-0.61,0.5-1.17,0.78-1.7c1.62-3.12,3.66-5.96,5.8-8.73
					c1.81-2.35,3.68-4.64,5.85-6.67c0.62-0.58,1.32-1.08,2.01-1.58c0.55-0.4,1.15-0.39,1.69,0.01c0.7,0.51,1.4,1.03,2.04,1.62
					c1.69,1.57,3.17,3.33,4.64,5.14c-0.08,0.11-0.15,0.22-0.23,0.32c-2.06,2.62-4.03,5.3-5.61,8.24c-0.53,0.98-0.93,2.03-1.37,3.05
					c-0.09,0.22-0.18,0.32-0.44,0.32c-4.95-0.01-9.9-0.01-14.85-0.01C881.11,1857.35,881.05,1857.34,880.87,1857.33z"/>
				<path className="st123" d="M920.72,1857.33c-7.25,0-14.43,0-21.71,0c0.43-0.85,0.81-1.66,1.25-2.43c2.05-3.56,4.42-6.89,7.2-9.91
					c0.51-0.56,1.12-1.03,1.7-1.52c0.46-0.39,0.98-0.38,1.44,0c0.54,0.44,1.09,0.87,1.56,1.38c3.1,3.35,5.71,7.04,7.89,11.05
					C920.29,1856.35,920.48,1856.82,920.72,1857.33z"/>
			</g>
			<g>
				<path className="st123" d="M1183.53,1849.39c0.18,0.01,0.24,0.01,0.3,0.01c4.95,0,9.9,0,14.85,0.01c0.26,0,0.35-0.1,0.44-0.32
					c0.44-1.03,0.84-2.08,1.37-3.06c1.58-2.94,3.56-5.62,5.61-8.24c0.08-0.1,0.15-0.21,0.23-0.32c-1.47-1.81-2.95-3.58-4.64-5.14
					c-0.63-0.59-1.34-1.1-2.04-1.62c-0.54-0.4-1.14-0.41-1.69-0.01c-0.69,0.5-1.39,1-2.01,1.58c-2.17,2.03-4.05,4.32-5.85,6.67
					c-2.13,2.78-4.17,5.62-5.8,8.73C1184.03,1848.22,1183.81,1848.78,1183.53,1849.39z M1223.38,1849.39
					c-0.23-0.5-0.42-0.98-0.66-1.42c-2.17-4.01-4.79-7.7-7.89-11.05c-0.47-0.51-1.03-0.94-1.56-1.38c-0.47-0.38-0.98-0.39-1.44,0
					c-0.58,0.49-1.19,0.97-1.7,1.52c-2.78,3.02-5.15,6.35-7.2,9.91c-0.44,0.77-0.82,1.58-1.25,2.43
					C1208.95,1849.39,1216.13,1849.39,1223.38,1849.39z"/>
				<path className="st123" d="M1183.53,1849.39c0.28-0.61,0.5-1.17,0.78-1.7c1.62-3.12,3.66-5.96,5.8-8.73
					c1.81-2.35,3.68-4.64,5.85-6.67c0.62-0.58,1.32-1.08,2.01-1.58c0.55-0.4,1.15-0.39,1.69,0.01c0.7,0.51,1.4,1.03,2.04,1.62
					c1.69,1.57,3.17,3.33,4.64,5.14c-0.08,0.11-0.15,0.22-0.23,0.32c-2.06,2.62-4.03,5.3-5.61,8.24c-0.53,0.98-0.93,2.03-1.37,3.06
					c-0.09,0.22-0.18,0.32-0.44,0.32c-4.95-0.01-9.9-0.01-14.85-0.01C1183.77,1849.4,1183.71,1849.4,1183.53,1849.39z"/>
				<path className="st123" d="M1223.38,1849.39c-7.25,0-14.43,0-21.71,0c0.43-0.85,0.81-1.66,1.25-2.43c2.05-3.56,4.42-6.89,7.2-9.91
					c0.51-0.56,1.12-1.03,1.7-1.52c0.46-0.39,0.98-0.38,1.44,0c0.54,0.44,1.09,0.87,1.56,1.38c3.1,3.35,5.71,7.04,7.89,11.05
					C1222.96,1848.41,1223.15,1848.88,1223.38,1849.39z"/>
			</g>
			<g>
				<path className="st123" d="M866.25,1736.88c0.18,0.01,0.24,0.01,0.3,0.01c4.95,0,9.9,0,14.85,0.01c0.26,0,0.35-0.1,0.44-0.32
					c0.44-1.03,0.84-2.08,1.37-3.05c1.58-2.94,3.56-5.62,5.61-8.24c0.08-0.1,0.15-0.21,0.23-0.32c-1.47-1.81-2.95-3.58-4.64-5.14
					c-0.63-0.59-1.34-1.1-2.04-1.62c-0.54-0.4-1.14-0.41-1.69-0.01c-0.69,0.5-1.39,1-2.01,1.58c-2.17,2.03-4.05,4.32-5.85,6.67
					c-2.13,2.78-4.17,5.62-5.8,8.73C866.75,1735.71,866.52,1736.27,866.25,1736.88z M906.09,1736.87c-0.23-0.5-0.42-0.98-0.66-1.42
					c-2.17-4.01-4.79-7.7-7.89-11.05c-0.47-0.51-1.03-0.94-1.56-1.38c-0.47-0.38-0.98-0.39-1.44,0c-0.58,0.49-1.19,0.97-1.7,1.52
					c-2.78,3.02-5.15,6.35-7.2,9.91c-0.44,0.77-0.82,1.58-1.25,2.43C891.66,1736.87,898.84,1736.87,906.09,1736.87z"/>
				<path className="st123" d="M866.25,1736.88c0.28-0.61,0.5-1.17,0.78-1.7c1.62-3.12,3.66-5.96,5.8-8.73
					c1.81-2.35,3.68-4.64,5.85-6.67c0.62-0.58,1.32-1.08,2.01-1.58c0.55-0.4,1.15-0.39,1.69,0.01c0.7,0.51,1.4,1.03,2.04,1.62
					c1.69,1.57,3.17,3.33,4.64,5.14c-0.08,0.11-0.15,0.22-0.23,0.32c-2.06,2.62-4.03,5.3-5.61,8.24c-0.53,0.98-0.93,2.03-1.37,3.05
					c-0.09,0.22-0.18,0.32-0.44,0.32c-4.95-0.01-9.9-0.01-14.85-0.01C866.49,1736.89,866.42,1736.89,866.25,1736.88z"/>
				<path className="st123" d="M906.09,1736.87c-7.25,0-14.43,0-21.71,0c0.43-0.85,0.81-1.66,1.25-2.43c2.05-3.56,4.42-6.89,7.2-9.91
					c0.51-0.56,1.12-1.03,1.7-1.52c0.46-0.39,0.98-0.38,1.44,0c0.54,0.44,1.09,0.87,1.56,1.38c3.1,3.35,5.71,7.04,7.89,11.05
					C905.67,1735.89,905.86,1736.37,906.09,1736.87z"/>
			</g>
			<g>
				<path className="st123" d="M589.22,2137.75c0.27,0.01,0.36,0.02,0.46,0.02c7.49,0,14.98,0,22.47,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.55,1.27-3.14,2.07-4.62c2.4-4.45,5.38-8.51,8.5-12.47c0.12-0.15,0.23-0.31,0.35-0.48c-2.22-2.74-4.46-5.41-7.03-7.78
					c-0.96-0.89-2.02-1.67-3.08-2.44c-0.82-0.6-1.73-0.62-2.56-0.02c-1.04,0.76-2.1,1.52-3.04,2.4c-3.29,3.06-6.12,6.54-8.86,10.09
					c-3.23,4.2-6.31,8.5-8.77,13.22C589.98,2135.98,589.64,2136.83,589.22,2137.75z M649.51,2137.74c-0.35-0.76-0.64-1.48-1.01-2.16
					c-3.29-6.06-7.25-11.66-11.93-16.72c-0.71-0.77-1.55-1.42-2.37-2.09c-0.71-0.58-1.48-0.59-2.18,0c-0.88,0.74-1.8,1.46-2.57,2.3
					c-4.21,4.57-7.79,9.61-10.9,14.99c-0.67,1.16-1.24,2.39-1.89,3.67C627.68,2137.74,638.54,2137.74,649.51,2137.74z"/>
				<path className="st123" d="M589.22,2137.75c0.42-0.92,0.76-1.77,1.18-2.57c2.46-4.71,5.54-9.02,8.77-13.22
					c2.73-3.56,5.57-7.03,8.86-10.09c0.94-0.88,2-1.64,3.04-2.4c0.83-0.6,1.74-0.58,2.56,0.02c1.06,0.78,2.12,1.56,3.08,2.44
					c2.56,2.37,4.8,5.04,7.03,7.78c-0.12,0.17-0.23,0.33-0.35,0.48c-3.11,3.97-6.1,8.02-8.5,12.47c-0.8,1.48-1.41,3.07-2.07,4.62
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.49-0.01-14.98-0.01-22.47-0.01C589.59,2137.77,589.49,2137.76,589.22,2137.75z"/>
				<path className="st123" d="M649.51,2137.74c-10.97,0-21.83,0-32.85,0c0.66-1.28,1.22-2.51,1.89-3.67c3.1-5.38,6.68-10.42,10.9-14.99
					c0.78-0.84,1.69-1.56,2.57-2.3c0.7-0.59,1.48-0.58,2.18,0c0.81,0.67,1.66,1.32,2.37,2.09c4.69,5.07,8.64,10.66,11.93,16.72
					C648.87,2136.26,649.16,2136.98,649.51,2137.74z"/>
			</g>
			<g>
				<path className="st123" d="M915.96,2182.52c0.27,0.01,0.36,0.02,0.46,0.02c7.49,0,14.98,0,22.47,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.55,1.27-3.14,2.07-4.62c2.4-4.45,5.38-8.51,8.5-12.47c0.12-0.15,0.23-0.31,0.35-0.48c-2.22-2.74-4.46-5.41-7.03-7.78
					c-0.96-0.89-2.02-1.67-3.08-2.44c-0.82-0.6-1.73-0.62-2.56-0.02c-1.04,0.76-2.1,1.52-3.04,2.4c-3.29,3.06-6.12,6.54-8.86,10.09
					c-3.23,4.2-6.31,8.5-8.77,13.22C916.72,2180.75,916.38,2181.6,915.96,2182.52z M976.25,2182.51c-0.35-0.76-0.64-1.48-1.01-2.16
					c-3.29-6.06-7.25-11.66-11.93-16.72c-0.71-0.77-1.55-1.42-2.37-2.09c-0.71-0.58-1.48-0.59-2.18,0c-0.88,0.74-1.8,1.46-2.57,2.3
					c-4.21,4.57-7.79,9.61-10.9,14.99c-0.67,1.16-1.24,2.39-1.89,3.67C954.42,2182.51,965.28,2182.51,976.25,2182.51z"/>
				<path className="st123" d="M915.96,2182.52c0.42-0.92,0.76-1.77,1.18-2.57c2.46-4.71,5.54-9.02,8.77-13.22
					c2.73-3.55,5.57-7.03,8.86-10.09c0.94-0.88,2-1.64,3.04-2.4c0.83-0.6,1.74-0.58,2.56,0.02c1.06,0.78,2.12,1.56,3.08,2.44
					c2.56,2.37,4.8,5.04,7.03,7.78c-0.12,0.17-0.23,0.33-0.35,0.48c-3.11,3.97-6.1,8.02-8.5,12.47c-0.8,1.48-1.41,3.07-2.07,4.62
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.49-0.01-14.98-0.01-22.47-0.01C916.33,2182.54,916.23,2182.53,915.96,2182.52z"/>
				<path className="st123" d="M976.25,2182.51c-10.97,0-21.83,0-32.85,0c0.66-1.28,1.22-2.51,1.89-3.67c3.1-5.38,6.68-10.42,10.9-14.99
					c0.78-0.84,1.69-1.56,2.57-2.3c0.7-0.59,1.48-0.58,2.18,0c0.81,0.67,1.66,1.32,2.37,2.09c4.69,5.07,8.64,10.66,11.93,16.72
					C975.61,2181.03,975.9,2181.74,976.25,2182.51z"/>
			</g>
			<g>
				<path className="st123" d="M1173.16,2010.34c0.17,0.01,0.23,0.01,0.3,0.01c4.85,0,9.69,0,14.54,0.01c0.25,0,0.34-0.1,0.43-0.32
					c0.43-1.02,0.82-2.07,1.34-3.04c1.55-2.93,3.48-5.6,5.5-8.21c0.08-0.1,0.15-0.2,0.23-0.31c-1.44-1.8-2.89-3.56-4.55-5.12
					c-0.62-0.58-1.31-1.1-1.99-1.61c-0.53-0.4-1.12-0.41-1.66-0.01c-0.67,0.5-1.36,1-1.97,1.58c-2.13,2.02-3.96,4.3-5.73,6.64
					c-2.09,2.76-4.09,5.6-5.68,8.7C1173.65,2009.18,1173.43,2009.74,1173.16,2010.34z M1212.17,2010.34
					c-0.23-0.5-0.41-0.97-0.65-1.42c-2.13-3.99-4.69-7.67-7.72-11.01c-0.46-0.51-1-0.94-1.53-1.38c-0.46-0.38-0.96-0.39-1.41,0
					c-0.57,0.49-1.16,0.96-1.67,1.52c-2.73,3.01-5.04,6.32-7.05,9.86c-0.43,0.77-0.8,1.57-1.23,2.42
					C1198.04,2010.34,1205.07,2010.34,1212.17,2010.34z"/>
				<path className="st123" d="M1173.16,2010.34c0.27-0.6,0.49-1.16,0.76-1.69c1.59-3.1,3.59-5.93,5.68-8.7
					c1.77-2.34,3.6-4.62,5.73-6.64c0.61-0.58,1.29-1.08,1.97-1.58c0.54-0.4,1.13-0.38,1.66,0.01c0.68,0.51,1.37,1.02,1.99,1.61
					c1.66,1.56,3.11,3.32,4.55,5.12c-0.08,0.11-0.15,0.22-0.23,0.31c-2.02,2.61-3.95,5.28-5.5,8.21c-0.52,0.98-0.91,2.02-1.34,3.04
					c-0.09,0.22-0.18,0.32-0.43,0.32c-4.85-0.01-9.69-0.01-14.54-0.01C1173.4,2010.36,1173.33,2010.35,1173.16,2010.34z"/>
				<path className="st123" d="M1212.17,2010.34c-7.1,0-14.13,0-21.26,0c0.43-0.84,0.79-1.65,1.23-2.42c2.01-3.54,4.32-6.86,7.05-9.86
					c0.5-0.55,1.09-1.03,1.67-1.52c0.45-0.39,0.95-0.38,1.41,0c0.53,0.44,1.07,0.87,1.53,1.38c3.03,3.33,5.59,7.01,7.72,11.01
					C1211.76,2009.36,1211.94,2009.83,1212.17,2010.34z"/>
			</g>
			<g>
				<path className="st123" d="M1345.24,2190.53c0.27,0.01,0.36,0.02,0.46,0.02c7.52,0,15.04,0,22.56,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.56,1.27-3.15,2.08-4.64c2.41-4.47,5.41-8.54,8.53-12.52c0.12-0.15,0.23-0.31,0.35-0.48c-2.23-2.75-4.48-5.44-7.06-7.82
					c-0.96-0.89-2.03-1.68-3.09-2.45c-0.82-0.61-1.74-0.63-2.57-0.02c-1.05,0.76-2.11,1.52-3.05,2.41c-3.3,3.08-6.15,6.56-8.9,10.13
					c-3.24,4.22-6.34,8.54-8.81,13.27C1346,2188.75,1345.66,2189.6,1345.24,2190.53z M1405.79,2190.52
					c-0.35-0.77-0.64-1.49-1.01-2.17c-3.3-6.09-7.28-11.71-11.98-16.8c-0.71-0.77-1.56-1.43-2.38-2.1c-0.71-0.58-1.48-0.59-2.19,0
					c-0.89,0.74-1.8,1.47-2.59,2.32c-4.23,4.59-7.83,9.65-10.94,15.05c-0.67,1.17-1.24,2.4-1.9,3.69
					C1383.86,2190.52,1394.77,2190.52,1405.79,2190.52z"/>
				<path className="st123" d="M1345.24,2190.53c0.42-0.92,0.76-1.77,1.18-2.58c2.47-4.73,5.56-9.05,8.81-13.27
					c2.74-3.57,5.59-7.06,8.9-10.13c0.95-0.88,2.01-1.64,3.05-2.41c0.83-0.61,1.75-0.59,2.57,0.02c1.06,0.78,2.13,1.56,3.09,2.45
					c2.57,2.38,4.82,5.06,7.06,7.82c-0.12,0.17-0.23,0.33-0.35,0.48c-3.13,3.98-6.12,8.06-8.53,12.52c-0.8,1.49-1.41,3.08-2.08,4.64
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.52-0.01-15.04-0.01-22.56-0.01C1345.61,2190.55,1345.51,2190.54,1345.24,2190.53z"/>
				<path className="st123" d="M1405.79,2190.52c-11.02,0-21.93,0-32.99,0c0.66-1.29,1.23-2.52,1.9-3.69
					c3.12-5.4,6.71-10.46,10.94-15.05c0.78-0.85,1.7-1.57,2.59-2.32c0.71-0.59,1.48-0.58,2.19,0c0.82,0.67,1.66,1.33,2.38,2.1
					c4.71,5.09,8.68,10.71,11.98,16.8C1405.15,2189.03,1405.44,2189.75,1405.79,2190.52z"/>
			</g>
			<g>
				<path className="st123" d="M1150.18,2322.17c0.24,0.01,0.33,0.02,0.42,0.02c6.76,0,13.53,0,20.29,0.01c0.35,0,0.48-0.14,0.61-0.44
					c0.6-1.4,1.14-2.84,1.87-4.17c2.17-4.02,4.86-7.68,7.67-11.26c0.11-0.14,0.21-0.28,0.32-0.43c-2.01-2.48-4.03-4.89-6.34-7.03
					c-0.87-0.8-1.83-1.51-2.78-2.21c-0.74-0.54-1.56-0.56-2.31-0.02c-0.94,0.69-1.9,1.37-2.75,2.16c-2.97,2.77-5.53,5.9-8,9.11
					c-2.92,3.79-5.7,7.68-7.92,11.93C1150.87,2320.58,1150.56,2321.34,1150.18,2322.17z M1204.62,2322.16
					c-0.32-0.69-0.58-1.34-0.91-1.95c-2.97-5.48-6.55-10.53-10.78-15.1c-0.64-0.69-1.4-1.29-2.14-1.89c-0.64-0.52-1.33-0.53-1.97,0
					c-0.8,0.67-1.62,1.32-2.33,2.08c-3.81,4.13-7.04,8.68-9.84,13.54c-0.61,1.05-1.12,2.16-1.71,3.32
					C1184.91,2322.16,1194.72,2322.16,1204.62,2322.16z"/>
				<path className="st123" d="M1150.18,2322.17c0.38-0.83,0.68-1.6,1.06-2.32c2.22-4.26,5-8.14,7.92-11.93c2.47-3.21,5.03-6.35,8-9.11
					c0.85-0.79,1.81-1.48,2.75-2.16c0.75-0.55,1.57-0.53,2.31,0.02c0.95,0.7,1.91,1.41,2.78,2.21c2.32,2.14,4.34,4.55,6.34,7.03
					c-0.11,0.15-0.21,0.3-0.32,0.43c-2.81,3.58-5.51,7.24-7.67,11.26c-0.72,1.34-1.27,2.77-1.87,4.17c-0.13,0.3-0.25,0.44-0.61,0.44
					c-6.76-0.01-13.53-0.01-20.29-0.01C1150.51,2322.19,1150.42,2322.18,1150.18,2322.17z"/>
				<path className="st123" d="M1204.62,2322.16c-9.91,0-19.72,0-29.67,0c0.59-1.16,1.1-2.26,1.71-3.32c2.8-4.86,6.04-9.41,9.84-13.54
					c0.7-0.76,1.53-1.41,2.33-2.08c0.63-0.53,1.33-0.53,1.97,0c0.74,0.6,1.49,1.19,2.14,1.89c4.23,4.58,7.81,9.63,10.78,15.1
					C1204.05,2320.83,1204.3,2321.47,1204.62,2322.16z"/>
			</g>
			<g>
				<path className="st123" d="M1352.68,2360.44c0.22,0.01,0.31,0.02,0.39,0.02c6.29,0,12.58,0,18.87,0.01c0.33,0,0.44-0.13,0.56-0.41
					c0.56-1.3,1.06-2.64,1.74-3.88c2.01-3.74,4.52-7.14,7.14-10.47c0.1-0.13,0.19-0.26,0.29-0.4c-1.87-2.3-3.75-4.55-5.9-6.54
					c-0.81-0.75-1.7-1.4-2.59-2.05c-0.69-0.51-1.45-0.52-2.15-0.02c-0.88,0.64-1.76,1.28-2.56,2.01c-2.76,2.57-5.14,5.49-7.44,8.48
					c-2.71,3.53-5.3,7.14-7.37,11.1C1353.32,2358.95,1353.04,2359.66,1352.68,2360.44z M1403.33,2360.43
					c-0.3-0.64-0.54-1.24-0.84-1.81c-2.76-5.09-6.09-9.79-10.02-14.05c-0.6-0.65-1.3-1.2-1.99-1.76c-0.59-0.49-1.24-0.49-1.83,0
					c-0.74,0.62-1.51,1.23-2.16,1.94c-3.54,3.84-6.55,8.07-9.15,12.59c-0.56,0.98-1.04,2.01-1.59,3.08
					C1384.99,2360.43,1394.11,2360.43,1403.33,2360.43z"/>
				<path className="st123" d="M1352.68,2360.44c0.35-0.77,0.63-1.48,0.99-2.16c2.06-3.96,4.65-7.57,7.37-11.1
					c2.3-2.99,4.68-5.9,7.44-8.48c0.79-0.74,1.68-1.37,2.56-2.01c0.7-0.51,1.46-0.49,2.15,0.02c0.89,0.65,1.78,1.31,2.59,2.05
					c2.15,1.99,4.03,4.23,5.9,6.54c-0.1,0.14-0.19,0.27-0.29,0.4c-2.62,3.33-5.12,6.74-7.14,10.47c-0.67,1.24-1.18,2.58-1.74,3.88
					c-0.12,0.28-0.23,0.41-0.56,0.41c-6.29-0.01-12.58-0.01-18.87-0.01C1352.99,2360.45,1352.91,2360.45,1352.68,2360.44z"/>
				<path className="st123" d="M1403.33,2360.43c-9.22,0-18.34,0-27.6,0c0.55-1.08,1.03-2.11,1.59-3.08c2.61-4.52,5.61-8.75,9.15-12.59
					c0.65-0.71,1.42-1.31,2.16-1.94c0.59-0.5,1.24-0.49,1.83,0c0.68,0.56,1.39,1.11,1.99,1.76c3.94,4.26,7.26,8.95,10.02,14.05
					C1402.79,2359.19,1403.03,2359.79,1403.33,2360.43z"/>
			</g>
			<g>
				<path className="st123" d="M787.62,2400.03c0.22,0.01,0.3,0.02,0.39,0.02c6.29,0,12.58,0,18.87,0.01c0.33,0,0.44-0.13,0.56-0.41
					c0.56-1.3,1.06-2.64,1.74-3.88c2.01-3.74,4.52-7.14,7.14-10.47c0.1-0.13,0.19-0.26,0.29-0.4c-1.87-2.3-3.75-4.55-5.9-6.54
					c-0.81-0.75-1.7-1.4-2.59-2.05c-0.69-0.51-1.45-0.52-2.15-0.02c-0.88,0.64-1.76,1.28-2.56,2.01c-2.76,2.57-5.14,5.49-7.44,8.48
					c-2.71,3.53-5.3,7.14-7.37,11.1C788.26,2398.55,787.97,2399.26,787.62,2400.03z M838.26,2400.02c-0.3-0.64-0.54-1.24-0.84-1.81
					c-2.76-5.09-6.09-9.79-10.02-14.05c-0.6-0.65-1.3-1.2-1.99-1.76c-0.59-0.49-1.24-0.49-1.83,0c-0.74,0.62-1.51,1.23-2.16,1.94
					c-3.54,3.84-6.55,8.07-9.15,12.59c-0.56,0.98-1.04,2.01-1.59,3.08C819.92,2400.02,829.05,2400.02,838.26,2400.02z"/>
				<path className="st123" d="M787.62,2400.03c0.35-0.77,0.63-1.48,0.99-2.16c2.06-3.96,4.65-7.57,7.37-11.1
					c2.3-2.99,4.68-5.9,7.44-8.48c0.79-0.74,1.68-1.37,2.56-2.01c0.7-0.51,1.46-0.49,2.15,0.02c0.89,0.65,1.78,1.31,2.59,2.05
					c2.15,1.99,4.03,4.23,5.9,6.54c-0.1,0.14-0.19,0.27-0.29,0.4c-2.62,3.33-5.12,6.74-7.14,10.47c-0.67,1.24-1.18,2.58-1.74,3.88
					c-0.12,0.28-0.23,0.41-0.56,0.41c-6.29-0.01-12.58-0.01-18.87-0.01C787.93,2400.05,787.84,2400.04,787.62,2400.03z"/>
				<path className="st123" d="M838.26,2400.02c-9.22,0-18.34,0-27.6,0c0.55-1.08,1.03-2.11,1.59-3.08c2.61-4.52,5.61-8.75,9.15-12.59
					c0.65-0.71,1.42-1.31,2.16-1.94c0.59-0.5,1.24-0.49,1.83,0c0.68,0.56,1.39,1.11,1.99,1.76c3.94,4.26,7.26,8.95,10.02,14.05
					C837.72,2398.78,837.96,2399.38,838.26,2400.02z"/>
			</g>
			<g>
				<path className="st123" d="M533.42,883.14c0.27,0.01,0.36,0.02,0.46,0.02c7.52,0,15.04,0,22.56,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.56,1.27-3.15,2.08-4.64c2.41-4.47,5.41-8.54,8.53-12.52c0.12-0.15,0.23-0.31,0.35-0.48c-2.23-2.75-4.48-5.44-7.06-7.82
					c-0.96-0.89-2.03-1.68-3.09-2.45c-0.82-0.61-1.74-0.63-2.57-0.02c-1.05,0.76-2.11,1.52-3.05,2.41c-3.3,3.08-6.15,6.56-8.9,10.13
					c-3.24,4.22-6.34,8.54-8.81,13.27C534.18,881.37,533.84,882.22,533.42,883.14z M593.96,883.14c-0.35-0.77-0.64-1.49-1.01-2.17
					c-3.3-6.09-7.28-11.71-11.98-16.8c-0.71-0.77-1.56-1.43-2.38-2.1c-0.71-0.58-1.48-0.59-2.19,0c-0.89,0.74-1.81,1.47-2.59,2.32
					c-4.23,4.59-7.83,9.65-10.94,15.05c-0.67,1.17-1.24,2.4-1.9,3.69C572.04,883.14,582.94,883.14,593.96,883.14z"/>
				<path className="st123" d="M533.42,883.14c0.42-0.92,0.76-1.77,1.18-2.58c2.47-4.73,5.56-9.05,8.81-13.27
					c2.74-3.57,5.59-7.06,8.9-10.13c0.95-0.88,2.01-1.64,3.05-2.41c0.83-0.61,1.75-0.59,2.57,0.02c1.06,0.78,2.13,1.56,3.09,2.45
					c2.57,2.38,4.82,5.06,7.06,7.82c-0.12,0.17-0.23,0.33-0.35,0.48c-3.13,3.98-6.12,8.06-8.53,12.52c-0.8,1.49-1.41,3.08-2.08,4.64
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.52-0.01-15.04-0.01-22.56-0.01C533.78,883.17,533.68,883.16,533.42,883.14z"/>
				<path className="st123" d="M593.96,883.14c-11.02,0-21.93,0-32.99,0c0.66-1.29,1.23-2.52,1.9-3.69c3.12-5.4,6.71-10.46,10.94-15.05
					c0.78-0.85,1.7-1.57,2.59-2.32c0.71-0.59,1.48-0.58,2.19,0c0.82,0.67,1.66,1.33,2.38,2.1c4.71,5.09,8.68,10.71,11.98,16.8
					C593.32,881.65,593.61,882.37,593.96,883.14z"/>
			</g>
			<g>
				<path className="st123" d="M659.96,925.49c0.27,0.01,0.36,0.02,0.46,0.02c7.52,0,15.04,0,22.56,0.01c0.39,0,0.53-0.15,0.67-0.49
					c0.66-1.56,1.27-3.15,2.08-4.64c2.41-4.47,5.41-8.54,8.53-12.52c0.12-0.15,0.23-0.31,0.35-0.48c-2.23-2.75-4.48-5.44-7.06-7.82
					c-0.96-0.89-2.03-1.68-3.09-2.45c-0.82-0.61-1.74-0.63-2.57-0.02c-1.05,0.76-2.11,1.52-3.05,2.41c-3.3,3.08-6.15,6.56-8.9,10.13
					c-3.24,4.22-6.34,8.54-8.81,13.27C660.72,923.72,660.38,924.57,659.96,925.49z M720.51,925.48c-0.35-0.77-0.64-1.49-1.01-2.17
					c-3.3-6.09-7.28-11.71-11.98-16.8c-0.71-0.77-1.56-1.43-2.38-2.1c-0.71-0.58-1.48-0.59-2.19,0c-0.89,0.74-1.8,1.47-2.59,2.32
					c-4.23,4.59-7.83,9.65-10.94,15.05c-0.67,1.17-1.24,2.4-1.9,3.69C698.58,925.48,709.49,925.48,720.51,925.48z"/>
				<path className="st123" d="M659.96,925.49c0.42-0.92,0.76-1.77,1.18-2.58c2.47-4.73,5.56-9.05,8.81-13.27
					c2.74-3.57,5.59-7.06,8.9-10.13c0.95-0.88,2.01-1.64,3.05-2.41c0.83-0.61,1.75-0.59,2.57,0.02c1.06,0.78,2.13,1.56,3.09,2.45
					c2.57,2.38,4.82,5.06,7.06,7.82c-0.12,0.17-0.23,0.33-0.35,0.48c-3.13,3.98-6.12,8.06-8.53,12.52c-0.8,1.49-1.41,3.08-2.08,4.64
					c-0.14,0.34-0.28,0.49-0.67,0.49c-7.52-0.01-15.04-0.01-22.56-0.01C660.33,925.51,660.23,925.5,659.96,925.49z"/>
				<path className="st123" d="M720.51,925.48c-11.02,0-21.93,0-32.99,0c0.66-1.29,1.23-2.52,1.9-3.69c3.12-5.4,6.71-10.46,10.94-15.05
					c0.78-0.85,1.7-1.57,2.59-2.32c0.71-0.59,1.48-0.58,2.19,0c0.82,0.67,1.66,1.33,2.38,2.1c4.71,5.09,8.68,10.71,11.98,16.8
					C719.87,924,720.15,924.72,720.51,925.48z"/>
			</g>
			<g>
				<path className="st123" d="M639.5,640.53c0.38,0.02,0.53,0.03,0.67,0.03c10.85,0,21.71,0,32.56,0.01c0.57,0,0.76-0.22,0.97-0.71
					c0.96-2.25,1.84-4.55,2.99-6.7c3.47-6.44,7.8-12.32,12.31-18.07c0.17-0.22,0.33-0.45,0.51-0.69
					c-3.22-3.97-6.47-7.84-10.18-11.28c-1.39-1.29-2.93-2.42-4.46-3.54c-1.19-0.87-2.51-0.9-3.71-0.03c-1.51,1.1-3.04,2.2-4.41,3.47
					c-4.77,4.44-8.88,9.47-12.84,14.62c-4.68,6.09-9.15,12.32-12.71,19.15C640.59,637.97,640.1,639.19,639.5,640.53z M726.86,640.51
					c-0.51-1.11-0.93-2.15-1.46-3.12c-4.77-8.79-10.5-16.89-17.29-24.23c-1.03-1.11-2.25-2.06-3.43-3.03
					c-1.02-0.84-2.14-0.85-3.16,0.01c-1.28,1.07-2.6,2.12-3.73,3.34c-6.11,6.62-11.29,13.93-15.79,21.72
					c-0.97,1.69-1.79,3.46-2.75,5.32C695.22,640.51,710.96,640.51,726.86,640.51z"/>
				<path className="st123" d="M639.5,640.53c0.6-1.33,1.1-2.56,1.7-3.73c3.56-6.83,8.03-13.06,12.71-19.15
					c3.96-5.15,8.07-10.18,12.84-14.62c1.36-1.27,2.9-2.37,4.41-3.47c1.2-0.88,2.52-0.85,3.71,0.03c1.53,1.12,3.07,2.26,4.46,3.54
					c3.72,3.43,6.96,7.31,10.18,11.28c-0.18,0.24-0.33,0.47-0.51,0.69c-4.51,5.75-8.84,11.63-12.31,18.07
					c-1.16,2.15-2.04,4.45-2.99,6.7c-0.21,0.49-0.4,0.71-0.97,0.71c-10.85-0.02-21.71-0.01-32.56-0.01
					C640.02,640.56,639.88,640.54,639.5,640.53z"/>
				<path className="st123" d="M726.86,640.51c-15.9,0-31.64,0-47.61,0c0.95-1.86,1.77-3.63,2.75-5.32c4.5-7.8,9.68-15.1,15.79-21.72
					c1.13-1.22,2.45-2.27,3.73-3.34c1.02-0.85,2.14-0.84,3.16-0.01c1.18,0.97,2.4,1.92,3.43,3.03c6.79,7.34,12.53,15.45,17.29,24.23
					C725.94,638.37,726.35,639.41,726.86,640.51z"/>
			</g>
			<g>
				<path className="st123" d="M711.5,625c0.29,0.01,0.39,0.02,0.5,0.02c8.1,0,16.19,0,24.29,0.01c0.42,0,0.57-0.17,0.73-0.53
					c0.71-1.68,1.37-3.39,2.23-5c2.59-4.81,5.82-9.19,9.18-13.48c0.13-0.16,0.25-0.34,0.38-0.52c-2.4-2.96-4.82-5.85-7.59-8.41
					c-1.04-0.96-2.19-1.8-3.33-2.64c-0.89-0.65-1.87-0.67-2.77-0.02c-1.13,0.82-2.27,1.64-3.29,2.59
					c-3.56,3.31-6.62,7.07-9.58,10.91c-3.49,4.54-6.82,9.19-9.48,14.29C712.31,623.09,711.95,624.01,711.5,625z M776.67,624.99
					c-0.38-0.82-0.69-1.6-1.09-2.33c-3.56-6.56-7.84-12.6-12.9-18.08c-0.77-0.83-1.68-1.54-2.56-2.26c-0.76-0.62-1.6-0.63-2.36,0
					c-0.95,0.8-1.94,1.58-2.78,2.49c-4.56,4.94-8.43,10.39-11.78,16.2c-0.73,1.26-1.34,2.58-2.05,3.97
					C753.07,624.99,764.81,624.99,776.67,624.99z"/>
				<path className="st123" d="M711.5,625c0.45-0.99,0.82-1.91,1.27-2.78c2.66-5.1,5.99-9.75,9.48-14.29c2.95-3.84,6.02-7.6,9.58-10.91
					c1.02-0.95,2.16-1.77,3.29-2.59c0.9-0.65,1.88-0.63,2.77,0.02c1.14,0.84,2.29,1.68,3.33,2.64c2.77,2.56,5.19,5.45,7.59,8.41
					c-0.13,0.18-0.25,0.35-0.38,0.52c-3.37,4.29-6.59,8.67-9.18,13.48c-0.86,1.6-1.52,3.32-2.23,5c-0.16,0.36-0.3,0.53-0.73,0.53
					c-8.1-0.01-16.19-0.01-24.29-0.01C711.89,625.02,711.78,625.01,711.5,625z"/>
				<path className="st123" d="M776.67,624.99c-11.86,0-23.6,0-35.51,0c0.71-1.39,1.32-2.71,2.05-3.97c3.35-5.82,7.22-11.26,11.78-16.2
					c0.84-0.91,1.83-1.69,2.78-2.49c0.76-0.64,1.6-0.63,2.36,0c0.88,0.72,1.79,1.43,2.56,2.26c5.06,5.48,9.34,11.52,12.9,18.08
					C775.98,623.39,776.29,624.17,776.67,624.99z"/>
			</g>
			<g>
				<path className="st123" d="M711.5,660.81c0.29,0.01,0.39,0.02,0.5,0.02c8.1,0,16.19,0,24.29,0.01c0.42,0,0.57-0.17,0.73-0.53
					c0.71-1.68,1.37-3.39,2.23-5c2.59-4.81,5.82-9.19,9.18-13.48c0.13-0.16,0.25-0.34,0.38-0.52c-2.4-2.96-4.82-5.85-7.59-8.41
					c-1.04-0.96-2.19-1.8-3.33-2.64c-0.89-0.65-1.87-0.67-2.77-0.02c-1.13,0.82-2.27,1.64-3.29,2.59
					c-3.56,3.31-6.62,7.07-9.58,10.91c-3.49,4.54-6.82,9.19-9.48,14.29C712.31,658.9,711.95,659.81,711.5,660.81z M776.67,660.8
					c-0.38-0.82-0.69-1.6-1.09-2.33c-3.56-6.56-7.84-12.6-12.9-18.08c-0.77-0.83-1.68-1.54-2.56-2.26c-0.76-0.62-1.6-0.63-2.36,0
					c-0.95,0.8-1.94,1.58-2.78,2.49c-4.56,4.94-8.43,10.39-11.78,16.2c-0.73,1.26-1.34,2.58-2.05,3.97
					C753.07,660.8,764.81,660.8,776.67,660.8z"/>
				<path className="st123" d="M711.5,660.81c0.45-0.99,0.82-1.91,1.27-2.78c2.66-5.1,5.99-9.75,9.48-14.29
					c2.95-3.84,6.02-7.6,9.58-10.91c1.02-0.95,2.16-1.77,3.29-2.59c0.9-0.65,1.88-0.63,2.77,0.02c1.14,0.84,2.29,1.68,3.33,2.64
					c2.77,2.56,5.19,5.45,7.59,8.41c-0.13,0.18-0.25,0.35-0.38,0.52c-3.37,4.29-6.59,8.67-9.18,13.48c-0.86,1.6-1.52,3.32-2.23,5
					c-0.16,0.36-0.3,0.53-0.73,0.53c-8.1-0.01-16.19-0.01-24.29-0.01C711.89,660.83,711.78,660.82,711.5,660.81z"/>
				<path className="st123" d="M776.67,660.8c-11.86,0-23.6,0-35.51,0c0.71-1.39,1.32-2.71,2.05-3.97c3.35-5.82,7.22-11.26,11.78-16.2
					c0.84-0.91,1.83-1.69,2.78-2.49c0.76-0.64,1.6-0.63,2.36,0c0.88,0.72,1.79,1.43,2.56,2.26c5.06,5.48,9.34,11.52,12.9,18.08
					C775.98,659.2,776.29,659.97,776.67,660.8z"/>
			</g>
		</g>
		<g id="Trees">
			<g>
				<path className="st124" d="M1421.23,1938.77c-0.25-0.67-0.51-1.34-0.78-2c-2.01-4.98-4.16-11.05-9.1-13.85
					c-0.92-0.52-1.82-0.55-2.78-0.07c-2.13,1.06-3.77,2.67-5.17,4.54c-2.88,3.82-4.54,8.2-5.74,12.77
					c-1.43,5.43-2.34,11.36-2.55,16.97c-0.27,7.39,1.07,15.7,8.57,19c1.11,0.49,2.32,0.78,3.5,1.14c0.23,0.07,0.38,0.28,0.38,0.52
					v5.91c0,0.3,0.24,0.54,0.54,0.54h3.54c0.3,0,0.54-0.24,0.54-0.54c0-0.23,0-0.46,0-0.68c0-1.73,0.01-3.46-0.01-5.19
					c0-0.36,0.05-0.59,0.47-0.66c6.15-0.97,9.23-5.79,10.68-11.46C1425.68,1956.6,1424.47,1947.48,1421.23,1938.77z"/>
				<path className="st124" d="M1449.88,1939.53c-1.55-7.51-4.17-14.81-7.45-21.27c-1.06-2.1-2.31-4.11-3.6-6.09
					c-1.01-1.57-2.83-1.51-3.94,0c-0.23,0.31-0.45,0.64-0.66,0.96c-3.52,5.42-5.91,11.34-7.71,17.51c-0.88,3.02-1.35,5.32-1.52,9.24
					c0-0.01-0.01-0.04-0.01-0.03c7.67,19.65-0.38,31.06-0.38,31.06c0.01-0.01,0.02-0.02,0.04-0.03c1.07,1.66,2.35,3.07,3.97,4.16
					c1.64,1.1,3.44,1.8,5.39,2.12c0.34,0.05,0.59,0.12,0.58,0.56c-0.02,2.09-0.01,4.19-0.02,6.28c0,0.07-0.04,0.13-0.07,0.24h4.74
					c0,0,0-6.96,0-6.98c0,0.09,4.58-1.64,5.17-1.95c2.15-1.16,3.73-2.95,4.71-5.18C1453.07,1961.14,1451.8,1948.83,1449.88,1939.53z
					"/>
				<path className="st124" d="M967.87,1614.74c-0.19-0.52-0.39-1.03-0.6-1.54c-1.54-3.83-3.2-8.5-7-10.65c-0.71-0.4-1.4-0.42-2.14-0.05
					c-1.64,0.81-2.9,2.06-3.98,3.49c-2.21,2.94-3.49,6.3-4.41,9.82c-1.1,4.17-1.8,8.74-1.96,13.05c-0.21,5.68,0.82,12.08,6.59,14.61
					c0.86,0.38,1.78,0.6,2.69,0.88c0.17,0.05,0.29,0.22,0.29,0.4v4.54c0,0.23,0.19,0.42,0.42,0.42h2.72c0.23,0,0.42-0.19,0.42-0.42
					c0-0.18,0-0.35,0-0.53c0-1.33,0.01-2.66,0-3.99c0-0.28,0.04-0.46,0.36-0.51c4.73-0.75,7.1-4.45,8.22-8.82
					C971.3,1628.46,970.36,1621.44,967.87,1614.74z"/>
				<path className="st124" d="M1123.56,1444.79c-0.15-0.41-0.31-0.81-0.47-1.21c-1.21-3.01-2.51-6.68-5.5-8.37
					c-0.56-0.32-1.1-0.33-1.68-0.04c-1.29,0.64-2.28,1.62-3.12,2.74c-1.74,2.31-2.74,4.95-3.47,7.72
					c-0.86,3.28-1.42,6.86-1.54,10.25c-0.16,4.46,0.65,9.49,5.18,11.48c0.67,0.3,1.4,0.47,2.11,0.69c0.14,0.04,0.23,0.17,0.23,0.31
					v3.57c0,0.18,0.15,0.33,0.33,0.33h2.14c0.18,0,0.33-0.15,0.33-0.33c0-0.14,0-0.28,0-0.41c0-1.04,0-2.09,0-3.13
					c0-0.22,0.03-0.36,0.29-0.4c3.72-0.59,5.57-3.5,6.45-6.93C1126.25,1455.56,1125.51,1450.05,1123.56,1444.79z"/>
				<path className="st124" d="M1100.72,1456.31c-0.12-0.33-0.25-0.66-0.38-0.98c-0.98-2.44-2.04-5.41-4.45-6.78
					c-0.45-0.26-0.89-0.27-1.36-0.03c-1.04,0.52-1.84,1.31-2.53,2.22c-1.41,1.87-2.22,4.01-2.81,6.25c-0.7,2.66-1.15,5.56-1.25,8.31
					c-0.13,3.62,0.53,7.69,4.2,9.3c0.55,0.24,1.13,0.38,1.71,0.56c0.11,0.03,0.19,0.14,0.19,0.25v2.89c0,0.15,0.12,0.26,0.26,0.26
					h1.73c0.15,0,0.26-0.12,0.26-0.26c0-0.11,0-0.22,0-0.33c0-0.85,0-1.69,0-2.54c0-0.18,0.02-0.29,0.23-0.32
					c3.01-0.48,4.52-2.83,5.23-5.61C1102.9,1465.04,1102.3,1460.57,1100.72,1456.31z"/>
				<path className="st124" d="M1299.02,2417.58c-0.19-0.52-0.39-1.03-0.6-1.54c-1.54-3.83-3.2-8.5-7-10.65
					c-0.71-0.4-1.4-0.42-2.14-0.05c-1.64,0.81-2.9,2.06-3.98,3.49c-2.21,2.94-3.49,6.3-4.41,9.82c-1.1,4.17-1.8,8.74-1.96,13.05
					c-0.21,5.68,0.82,12.08,6.59,14.61c0.86,0.38,1.78,0.6,2.69,0.88c0.17,0.05,0.29,0.22,0.29,0.4v4.54c0,0.23,0.19,0.42,0.42,0.42
					h2.72c0.23,0,0.42-0.19,0.42-0.42c0-0.18,0-0.35,0-0.53c0-1.33,0.01-2.66,0-3.99c0-0.28,0.04-0.46,0.36-0.51
					c4.73-0.75,7.1-4.45,8.22-8.82C1302.44,2431.29,1301.51,2424.28,1299.02,2417.58z"/>
			</g>
			<path className="st124" d="M575.96,2439.86c-0.25-0.67-0.51-1.34-0.78-2c-2.01-4.98-4.16-11.05-9.1-13.85
				c-0.92-0.52-1.82-0.55-2.78-0.07c-2.13,1.06-3.77,2.67-5.17,4.54c-2.88,3.82-4.54,8.2-5.74,12.77
				c-1.43,5.43-2.34,11.36-2.55,16.97c-0.27,7.39,1.07,15.7,8.57,19c1.11,0.49,2.32,0.78,3.5,1.14c0.23,0.07,0.38,0.28,0.38,0.52
				v5.91c0,0.3,0.24,0.54,0.54,0.54h3.54c0.3,0,0.54-0.24,0.54-0.54c0-0.23,0-0.46,0-0.68c0-1.73,0.01-3.46-0.01-5.19
				c0-0.36,0.05-0.59,0.47-0.66c6.15-0.97,9.23-5.79,10.68-11.46C580.41,2457.69,579.2,2448.57,575.96,2439.86z"/>
			<path className="st124" d="M864.27,680.53c-0.25-0.67-0.51-1.34-0.78-2c-2.01-4.98-4.16-11.05-9.1-13.85
				c-0.92-0.52-1.82-0.55-2.78-0.07c-2.13,1.06-3.77,2.67-5.17,4.54c-2.88,3.82-4.54,8.2-5.74,12.77
				c-1.43,5.43-2.34,11.36-2.55,16.97c-0.27,7.39,1.07,15.7,8.57,19c1.11,0.49,2.32,0.78,3.5,1.14c0.23,0.07,0.38,0.28,0.38,0.52
				v5.91c0,0.3,0.24,0.54,0.54,0.54h3.54c0.3,0,0.54-0.24,0.54-0.54c0-0.23,0-0.46,0-0.68c0-1.73,0.01-3.46-0.01-5.19
				c0-0.36,0.05-0.59,0.47-0.66c6.15-0.97,9.23-5.79,10.68-11.46C868.73,698.36,867.51,689.24,864.27,680.53z"/>
			<path className="st124" d="M809.05,487.8c-0.25-0.67-0.51-1.34-0.78-2c-2.01-4.98-4.16-11.05-9.1-13.85
				c-0.92-0.52-1.82-0.55-2.78-0.07c-2.13,1.06-3.77,2.67-5.17,4.54c-2.88,3.82-4.54,8.2-5.74,12.77
				c-1.43,5.43-2.34,11.36-2.55,16.97c-0.27,7.39,1.07,15.7,8.57,19c1.11,0.49,2.32,0.78,3.5,1.14c0.23,0.07,0.38,0.28,0.38,0.52
				v5.91c0,0.3,0.24,0.54,0.54,0.54h3.54c0.3,0,0.54-0.24,0.54-0.54c0-0.23,0-0.46,0-0.68c0-1.73,0.01-3.46-0.01-5.19
				c0-0.36,0.05-0.59,0.47-0.66c6.15-0.97,9.23-5.79,10.68-11.46C813.5,505.64,812.29,496.51,809.05,487.8z"/>
			<path className="st124" d="M718.93,193.21c-0.25-0.67-0.51-1.34-0.78-2c-2.01-4.98-4.16-11.05-9.1-13.85
				c-0.92-0.52-1.82-0.55-2.78-0.07c-2.13,1.06-3.77,2.67-5.17,4.54c-2.88,3.82-4.54,8.2-5.74,12.77
				c-1.43,5.43-2.34,11.36-2.55,16.97c-0.27,7.39,1.07,15.7,8.57,19c1.11,0.49,2.32,0.78,3.5,1.14c0.23,0.07,0.38,0.28,0.38,0.52
				v5.91c0,0.3,0.24,0.54,0.54,0.54h3.54c0.3,0,0.54-0.24,0.54-0.54c0-0.23,0-0.46,0-0.68c0-1.73,0.01-3.46-0.01-5.19
				c0-0.36,0.05-0.59,0.47-0.66c6.15-0.97,9.23-5.79,10.68-11.46C723.39,211.04,722.17,201.92,718.93,193.21z"/>
			<path className="st124" d="M439.03,818.27c-0.25-0.67-0.51-1.34-0.78-2c-2.01-4.98-4.16-11.05-9.1-13.85
				c-0.92-0.52-1.82-0.55-2.78-0.07c-2.13,1.06-3.77,2.67-5.17,4.54c-2.88,3.82-4.54,8.2-5.74,12.77
				c-1.43,5.43-2.34,11.36-2.55,16.97c-0.27,7.39,1.07,15.7,8.57,19c1.11,0.49,2.32,0.78,3.5,1.14c0.23,0.07,0.38,0.28,0.38,0.52
				v5.91c0,0.3,0.24,0.54,0.54,0.54h3.54c0.3,0,0.54-0.24,0.54-0.54c0-0.23,0-0.46,0-0.68c0-1.73,0.01-3.46-0.01-5.19
				c0-0.36,0.05-0.59,0.47-0.66c6.15-0.97,9.23-5.79,10.68-11.46C443.49,836.1,442.27,826.98,439.03,818.27z"/>
			<path className="st124" d="M1031.47,1468.31c-0.19-0.52-0.39-1.04-0.6-1.55c-1.55-3.85-3.21-8.54-7.03-10.7
				c-0.71-0.4-1.41-0.42-2.15-0.05c-1.65,0.82-2.91,2.07-4,3.51c-2.22,2.95-3.51,6.34-4.44,9.87c-1.1,4.19-1.81,8.78-1.97,13.12
				c-0.21,5.71,0.83,12.13,6.62,14.68c0.86,0.38,1.79,0.6,2.7,0.88c0.18,0.05,0.29,0.22,0.29,0.4v4.56c0,0.23,0.19,0.42,0.42,0.42
				h2.74c0.23,0,0.42-0.19,0.42-0.42c0-0.18,0-0.35,0-0.53c0-1.34,0.01-2.67,0-4.01c0-0.28,0.04-0.46,0.37-0.51
				c4.75-0.75,7.13-4.47,8.26-8.86C1034.91,1482.09,1033.97,1475.04,1031.47,1468.31z"/>
			<path className="st124" d="M1053.61,1468.9c-1.2-5.81-3.22-11.44-5.76-16.44c-0.82-1.62-1.79-3.18-2.78-4.71
				c-0.78-1.21-2.18-1.17-3.05,0c-0.18,0.24-0.35,0.49-0.51,0.74c-2.72,4.19-4.57,8.76-5.96,13.53c-0.68,2.34-1.04,4.11-1.17,7.14
				c0-0.01-0.01-0.03-0.01-0.02c5.92,15.18-0.29,24-0.29,24c0.01-0.01,0.02-0.01,0.03-0.02c0.83,1.28,1.82,2.38,3.07,3.22
				c1.27,0.85,2.66,1.39,4.16,1.63c0.27,0.04,0.45,0.09,0.45,0.43c-0.02,1.62-0.01,3.24-0.02,4.86c0,0.05-0.03,0.1-0.06,0.19h3.66
				c0,0,0-5.38,0-5.4c0,0.07,3.54-1.26,4-1.51c1.66-0.9,2.88-2.28,3.64-4C1056.07,1485.59,1055.09,1476.08,1053.61,1468.9z"/>
			<path className="st124" d="M911.63,1224.62c-0.19-0.52-0.39-1.04-0.6-1.55c-1.55-3.85-3.21-8.54-7.03-10.7
				c-0.71-0.4-1.41-0.42-2.15-0.05c-1.65,0.82-2.91,2.07-4,3.51c-2.22,2.95-3.51,6.34-4.44,9.87c-1.1,4.19-1.81,8.78-1.97,13.12
				c-0.21,5.71,0.83,12.13,6.62,14.68c0.86,0.38,1.79,0.6,2.7,0.88c0.18,0.05,0.29,0.22,0.29,0.4v4.56c0,0.23,0.19,0.42,0.42,0.42
				h2.74c0.23,0,0.42-0.19,0.42-0.42c0-0.18,0-0.35,0-0.53c0-1.34,0.01-2.67,0-4.01c0-0.28,0.04-0.46,0.37-0.51
				c4.75-0.75,7.13-4.47,8.26-8.86C915.07,1238.4,914.14,1231.35,911.63,1224.62z"/>
			<path className="st124" d="M933.77,1225.21c-1.2-5.81-3.22-11.44-5.76-16.44c-0.82-1.62-1.79-3.18-2.78-4.71
				c-0.78-1.21-2.18-1.17-3.05,0c-0.18,0.24-0.35,0.49-0.51,0.74c-2.72,4.19-4.57,8.76-5.96,13.53c-0.68,2.34-1.04,4.11-1.17,7.14
				c0-0.01-0.01-0.03-0.01-0.02c5.92,15.18-0.29,24-0.29,24c0.01-0.01,0.02-0.01,0.03-0.02c0.83,1.28,1.82,2.38,3.07,3.22
				c1.27,0.85,2.66,1.39,4.16,1.63c0.27,0.04,0.45,0.09,0.45,0.43c-0.02,1.62-0.01,3.24-0.02,4.86c0,0.05-0.03,0.1-0.06,0.19h3.66
				c0,0,0-5.38,0-5.4c0,0.07,3.54-1.26,4-1.51c1.66-0.9,2.88-2.28,3.64-4C936.24,1241.91,935.25,1232.4,933.77,1225.21z"/>
			<path className="st124" d="M1129.58,1608.22c-0.19-0.52-0.39-1.04-0.6-1.55c-1.55-3.85-3.21-8.54-7.03-10.7
				c-0.71-0.4-1.41-0.42-2.15-0.05c-1.65,0.82-2.91,2.07-4,3.51c-2.22,2.95-3.51,6.34-4.44,9.87c-1.1,4.19-1.81,8.78-1.97,13.12
				c-0.21,5.71,0.83,12.13,6.62,14.68c0.86,0.38,1.79,0.6,2.7,0.88c0.18,0.05,0.29,0.22,0.29,0.4v4.56c0,0.23,0.19,0.42,0.42,0.42
				h2.74c0.23,0,0.42-0.19,0.42-0.42c0-0.18,0-0.35,0-0.53c0-1.34,0.01-2.67,0-4.01c0-0.28,0.04-0.46,0.37-0.51
				c4.75-0.75,7.13-4.47,8.26-8.86C1133.02,1622,1132.08,1614.95,1129.58,1608.22z"/>
			<path className="st124" d="M1151.71,1608.81c-1.2-5.81-3.22-11.44-5.76-16.44c-0.82-1.62-1.79-3.18-2.78-4.71
				c-0.78-1.21-2.18-1.17-3.05,0c-0.18,0.24-0.35,0.49-0.51,0.74c-2.72,4.19-4.57,8.76-5.96,13.53c-0.68,2.34-1.04,4.11-1.17,7.14
				c0-0.01-0.01-0.03-0.01-0.02c5.92,15.18-0.29,24-0.29,24c0.01-0.01,0.02-0.01,0.03-0.02c0.83,1.28,1.82,2.38,3.07,3.22
				c1.27,0.85,2.66,1.39,4.16,1.63c0.27,0.04,0.45,0.09,0.45,0.43c-0.02,1.62-0.01,3.24-0.02,4.85c0,0.05-0.03,0.1-0.06,0.19h3.66
				c0,0,0-5.38,0-5.39c0,0.07,3.54-1.26,4-1.51c1.66-0.9,2.88-2.28,3.64-4C1154.18,1625.51,1153.2,1616,1151.71,1608.81z"/>
			<path className="st124" d="M831.63,1990.05c-0.19-0.52-0.39-1.04-0.6-1.55c-1.55-3.85-3.21-8.54-7.03-10.7
				c-0.71-0.4-1.41-0.42-2.15-0.05c-1.65,0.82-2.91,2.07-4,3.51c-2.22,2.95-3.51,6.34-4.44,9.87c-1.1,4.19-1.81,8.78-1.97,13.12
				c-0.21,5.71,0.83,12.13,6.62,14.68c0.86,0.38,1.79,0.6,2.7,0.88c0.18,0.05,0.29,0.22,0.29,0.4v4.56c0,0.23,0.19,0.42,0.42,0.42
				h2.74c0.23,0,0.42-0.19,0.42-0.42c0-0.18,0-0.35,0-0.53c0-1.34,0.01-2.67,0-4.01c0-0.28,0.04-0.46,0.37-0.51
				c4.75-0.75,7.13-4.47,8.26-8.86C835.07,2003.83,834.13,1996.78,831.63,1990.05z"/>
			<path className="st124" d="M853.77,1990.64c-1.2-5.81-3.22-11.44-5.76-16.44c-0.82-1.62-1.79-3.18-2.78-4.71
				c-0.78-1.21-2.18-1.17-3.05,0c-0.18,0.24-0.35,0.49-0.51,0.74c-2.72,4.19-4.57,8.76-5.96,13.53c-0.68,2.34-1.04,4.11-1.17,7.14
				c0-0.01-0.01-0.03-0.01-0.02c5.92,15.18-0.29,24-0.29,24c0.01-0.01,0.02-0.01,0.03-0.02c0.83,1.28,1.82,2.38,3.07,3.22
				c1.27,0.85,2.66,1.39,4.16,1.63c0.27,0.04,0.45,0.09,0.45,0.43c-0.02,1.62-0.01,3.24-0.02,4.85c0,0.05-0.03,0.1-0.06,0.19h3.66
				c0,0,0-5.38,0-5.39c0,0.07,3.54-1.26,4-1.51c1.66-0.9,2.88-2.28,3.64-4C856.23,2007.33,855.25,1997.82,853.77,1990.64z"/>
			<path className="st124" d="M903.47,2452.57c-0.19-0.52-0.39-1.04-0.6-1.55c-1.55-3.85-3.21-8.54-7.03-10.7
				c-0.71-0.4-1.41-0.42-2.15-0.05c-1.65,0.82-2.91,2.07-4,3.51c-2.22,2.95-3.51,6.34-4.44,9.87c-1.1,4.19-1.81,8.78-1.97,13.12
				c-0.21,5.71,0.83,12.13,6.62,14.68c0.86,0.38,1.79,0.6,2.7,0.88c0.18,0.05,0.29,0.22,0.29,0.4v4.56c0,0.23,0.19,0.42,0.42,0.42
				h2.74c0.23,0,0.42-0.19,0.42-0.42c0-0.18,0-0.35,0-0.53c0-1.34,0.01-2.67,0-4.01c0-0.28,0.04-0.46,0.37-0.51
				c4.75-0.75,7.13-4.47,8.26-8.86C906.91,2466.35,905.97,2459.3,903.47,2452.57z"/>
			<path className="st124" d="M925.6,2453.16c-1.2-5.81-3.22-11.44-5.76-16.44c-0.82-1.62-1.79-3.18-2.78-4.71
				c-0.78-1.21-2.18-1.17-3.05,0c-0.18,0.24-0.35,0.49-0.51,0.74c-2.72,4.19-4.57,8.76-5.96,13.53c-0.68,2.34-1.04,4.11-1.17,7.14
				c0-0.01-0.01-0.03-0.01-0.02c5.92,15.18-0.29,24-0.29,24c0.01-0.01,0.02-0.01,0.03-0.02c0.83,1.28,1.82,2.37,3.07,3.22
				c1.27,0.85,2.66,1.39,4.16,1.63c0.27,0.04,0.45,0.09,0.45,0.43c-0.02,1.62-0.01,3.24-0.02,4.85c0,0.05-0.03,0.1-0.06,0.19h3.66
				c0,0,0-5.38,0-5.4c0,0.07,3.54-1.26,4-1.51c1.66-0.9,2.88-2.28,3.64-4C928.07,2469.86,927.08,2460.35,925.6,2453.16z"/>
			<path className="st124" d="M1411,2045.68c-0.25-0.67-0.51-1.34-0.78-2c-2.01-4.98-4.16-11.05-9.1-13.85
				c-0.92-0.52-1.82-0.55-2.78-0.07c-2.13,1.06-3.77,2.67-5.17,4.54c-2.88,3.82-4.54,8.2-5.74,12.77
				c-1.43,5.43-2.34,11.36-2.55,16.97c-0.27,7.39,1.07,15.7,8.57,19c1.11,0.49,2.32,0.78,3.5,1.14c0.23,0.07,0.38,0.28,0.38,0.52
				v5.91c0,0.3,0.24,0.54,0.54,0.54h3.54c0.3,0,0.54-0.24,0.54-0.54c0-0.23,0-0.46,0-0.68c0-1.73,0.01-3.46-0.01-5.19
				c0-0.36,0.05-0.59,0.47-0.66c6.15-0.97,9.23-5.79,10.68-11.46C1415.45,2063.51,1414.24,2054.38,1411,2045.68z"/>
			<path className="st124" d="M1456.69,2086.12c-0.21-0.55-0.42-1.1-0.64-1.65c-1.65-4.11-3.43-9.11-7.5-11.42
				c-0.76-0.43-1.5-0.45-2.29-0.06c-1.76,0.87-3.1,2.2-4.26,3.74c-2.37,3.15-3.74,6.76-4.73,10.53c-1.18,4.47-1.93,9.37-2.1,13.99
				c-0.22,6.09,0.88,12.94,7.07,15.66c0.92,0.4,1.91,0.64,2.89,0.94c0.19,0.06,0.31,0.23,0.31,0.43v4.87c0,0.25,0.2,0.45,0.45,0.45
				h2.92c0.25,0,0.45-0.2,0.45-0.45c0-0.19,0-0.38,0-0.56c0-1.43,0.01-2.85,0-4.28c0-0.3,0.04-0.49,0.39-0.54
				c5.07-0.8,7.61-4.77,8.81-9.45C1460.36,2100.81,1459.36,2093.29,1456.69,2086.12z"/>
			<path className="st124" d="M558.6,1159.97c-0.23-0.62-0.47-1.24-0.72-1.86c-1.86-4.62-3.85-10.24-8.43-12.84
				c-0.86-0.49-1.69-0.51-2.58-0.06c-1.97,0.98-3.49,2.48-4.79,4.21c-2.67,3.54-4.2,7.6-5.32,11.84
				c-1.32,5.03-2.17,10.53-2.36,15.73c-0.25,6.85,0.99,14.55,7.95,17.61c1.03,0.45,2.15,0.72,3.24,1.06
				c0.21,0.06,0.35,0.26,0.35,0.48v5.48c0,0.28,0.22,0.5,0.5,0.5h3.28c0.28,0,0.5-0.22,0.5-0.5c0-0.21,0-0.42,0-0.63
				c0-1.6,0.01-3.21,0-4.81c0-0.33,0.05-0.55,0.44-0.61c5.7-0.9,8.55-5.36,9.9-10.63C562.73,1176.49,561.6,1168.04,558.6,1159.97z"
				/>
			<path className="st124" d="M587.65,1232.69c-0.17-0.45-0.34-0.9-0.52-1.34c-1.34-3.33-2.78-7.4-6.09-9.27
				c-0.62-0.35-1.22-0.37-1.86-0.05c-1.43,0.71-2.52,1.79-3.46,3.04c-1.93,2.56-3.04,5.49-3.84,8.55c-0.96,3.63-1.57,7.6-1.71,11.36
				c-0.18,4.95,0.72,10.51,5.74,12.72c0.75,0.33,1.55,0.52,2.34,0.76c0.15,0.05,0.26,0.19,0.26,0.35v3.95c0,0.2,0.16,0.36,0.36,0.36
				h2.37c0.2,0,0.36-0.16,0.36-0.36c0-0.15,0-0.31,0-0.46c0-1.16,0-2.31,0-3.47c0-0.24,0.03-0.4,0.32-0.44
				c4.12-0.65,6.18-3.87,7.15-7.67C590.63,1244.62,589.82,1238.51,587.65,1232.69z"/>
			<path className="st124" d="M612.35,1131.52c-0.15-0.4-0.3-0.79-0.46-1.18c-1.18-2.94-2.45-6.52-5.37-8.17
				c-0.55-0.31-1.07-0.32-1.64-0.04c-1.26,0.62-2.22,1.58-3.05,2.68c-1.7,2.25-2.68,4.84-3.39,7.54c-0.84,3.2-1.38,6.7-1.51,10.02
				c-0.16,4.36,0.63,9.27,5.06,11.21c0.66,0.29,1.37,0.46,2.07,0.67c0.13,0.04,0.23,0.17,0.23,0.31v3.49c0,0.18,0.14,0.32,0.32,0.32
				h2.09c0.18,0,0.32-0.14,0.32-0.32c0-0.14,0-0.27,0-0.4c0-1.02,0-2.04,0-3.06c0-0.21,0.03-0.35,0.28-0.39
				c3.63-0.57,5.45-3.41,6.3-6.77C614.98,1142.05,614.26,1136.66,612.35,1131.52z"/>
			<path className="st124" d="M681.74,1203.2c-0.17-0.45-0.34-0.9-0.52-1.34c-1.34-3.33-2.78-7.4-6.09-9.27
				c-0.62-0.35-1.22-0.37-1.86-0.05c-1.43,0.71-2.52,1.79-3.46,3.04c-1.93,2.56-3.04,5.49-3.84,8.55c-0.96,3.63-1.57,7.6-1.71,11.36
				c-0.18,4.95,0.72,10.51,5.74,12.72c0.75,0.33,1.55,0.52,2.34,0.76c0.15,0.05,0.26,0.19,0.26,0.35v3.95c0,0.2,0.16,0.36,0.36,0.36
				h2.37c0.2,0,0.36-0.16,0.36-0.36c0-0.15,0-0.31,0-0.46c0-1.16,0-2.31,0-3.47c0-0.24,0.03-0.4,0.32-0.44
				c4.12-0.65,6.18-3.87,7.15-7.67C684.72,1215.14,683.91,1209.03,681.74,1203.2z"/>
			<path className="st124" d="M637.59,1178.84c-0.17-0.45-0.34-0.9-0.52-1.34c-1.34-3.33-2.78-7.4-6.09-9.27
				c-0.62-0.35-1.22-0.37-1.86-0.05c-1.43,0.71-2.52,1.79-3.46,3.04c-1.93,2.56-3.04,5.49-3.84,8.55c-0.96,3.63-1.57,7.6-1.71,11.36
				c-0.18,4.95,0.72,10.51,5.74,12.72c0.75,0.33,1.55,0.52,2.34,0.76c0.15,0.05,0.26,0.19,0.26,0.35v3.95c0,0.2,0.16,0.36,0.36,0.36
				h2.37c0.2,0,0.36-0.16,0.36-0.36c0-0.15,0-0.31,0-0.46c0-1.16,0-2.31,0-3.47c0-0.24,0.03-0.4,0.32-0.44
				c4.12-0.65,6.18-3.87,7.15-7.67C640.57,1190.78,639.76,1184.67,637.59,1178.84z"/>
			<path className="st124" d="M563.8,1100.13c-0.17-0.45-0.34-0.9-0.52-1.34c-1.34-3.33-2.78-7.4-6.09-9.27
				c-0.62-0.35-1.22-0.37-1.86-0.05c-1.43,0.71-2.52,1.79-3.46,3.04c-1.93,2.56-3.04,5.49-3.84,8.55c-0.96,3.63-1.57,7.6-1.71,11.36
				c-0.18,4.95,0.72,10.51,5.74,12.72c0.75,0.33,1.55,0.52,2.34,0.76c0.15,0.05,0.26,0.19,0.26,0.35v3.95c0,0.2,0.16,0.36,0.36,0.36
				h2.37c0.2,0,0.36-0.16,0.36-0.36c0-0.15,0-0.31,0-0.46c0-1.16,0-2.31,0-3.47c0-0.24,0.03-0.4,0.32-0.44
				c4.12-0.65,6.18-3.87,7.15-7.67C566.77,1112.06,565.96,1105.96,563.8,1100.13z"/>
			<path className="st124" d="M585.15,1160.67c-1.43-6.96-3.87-13.73-6.9-19.72c-0.99-1.95-2.15-3.81-3.33-5.65
				c-0.94-1.45-2.62-1.4-3.66,0c-0.21,0.29-0.42,0.59-0.62,0.89c-3.26,5.02-5.48,10.51-7.15,16.23c-0.82,2.8-1.25,4.93-1.41,8.56
				c0-0.01-0.01-0.03-0.01-0.03c7.1,18.21-0.35,28.79-0.35,28.79c0.01-0.01,0.02-0.02,0.03-0.03c0.99,1.54,2.18,2.85,3.68,3.86
				c1.52,1.02,3.19,1.67,4.99,1.96c0.32,0.05,0.54,0.11,0.54,0.52c-0.02,1.94-0.01,3.88-0.02,5.82c0,0.06-0.04,0.12-0.07,0.22h4.39
				c0,0,0-6.45,0-6.47c0,0.08,4.25-1.52,4.79-1.81c1.99-1.08,3.45-2.73,4.37-4.8C588.11,1180.7,586.93,1169.29,585.15,1160.67z"/>
			<path className="st124" d="M428.59,598.42c-0.15-0.4-0.3-0.8-0.46-1.19c-1.2-2.97-2.48-6.59-5.42-8.26
				c-0.55-0.31-1.08-0.33-1.66-0.04c-1.27,0.63-2.24,1.59-3.08,2.71c-1.71,2.28-2.7,4.89-3.42,7.61c-0.85,3.23-1.4,6.77-1.52,10.12
				c-0.16,4.4,0.64,9.36,5.11,11.33c0.66,0.29,1.38,0.47,2.09,0.68c0.14,0.04,0.23,0.17,0.23,0.31v3.52c0,0.18,0.14,0.32,0.32,0.32
				h2.11c0.18,0,0.32-0.14,0.32-0.32c0-0.14,0-0.27,0-0.41c0-1.03,0-2.06,0-3.09c0-0.21,0.03-0.35,0.28-0.39
				c3.67-0.58,5.5-3.45,6.37-6.83C431.24,609.04,430.52,603.61,428.59,598.42z"/>
			<path className="st124" d="M460.82,649.78c-0.13-0.35-0.26-0.69-0.4-1.03c-1.03-2.56-2.14-5.69-4.68-7.13
				c-0.48-0.27-0.94-0.28-1.43-0.04c-1.1,0.54-1.94,1.38-2.66,2.34c-1.48,1.97-2.33,4.22-2.95,6.57c-0.73,2.79-1.21,5.85-1.31,8.74
				c-0.14,3.8,0.55,8.08,4.41,9.78c0.57,0.25,1.19,0.4,1.8,0.59c0.12,0.04,0.2,0.14,0.2,0.27v3.04c0,0.15,0.12,0.28,0.28,0.28h1.82
				c0.15,0,0.28-0.12,0.28-0.28c0-0.12,0-0.23,0-0.35c0-0.89,0-1.78,0-2.67c0-0.18,0.03-0.3,0.24-0.34c3.17-0.5,4.75-2.98,5.5-5.9
				C463.12,658.96,462.49,654.26,460.82,649.78z"/>
			<path className="st124" d="M554.71,616.35c-0.17-0.45-0.34-0.9-0.52-1.34c-1.34-3.33-2.78-7.4-6.09-9.27
				c-0.62-0.35-1.22-0.37-1.86-0.05c-1.43,0.71-2.52,1.79-3.46,3.04c-1.93,2.56-3.04,5.49-3.84,8.55c-0.96,3.63-1.57,7.6-1.71,11.36
				c-0.18,4.95,0.72,10.51,5.74,12.72c0.75,0.33,1.55,0.52,2.34,0.76c0.15,0.05,0.26,0.19,0.26,0.35v3.95c0,0.2,0.16,0.36,0.36,0.36
				h2.37c0.2,0,0.36-0.16,0.36-0.36c0-0.15,0-0.31,0-0.46c0-1.16,0-2.31,0-3.47c0-0.24,0.03-0.4,0.32-0.44
				c4.12-0.65,6.18-3.87,7.15-7.67C557.69,628.28,556.88,622.18,554.71,616.35z"/>
			<path className="st124" d="M506.16,599.89c-0.17-0.45-0.34-0.9-0.52-1.34c-1.34-3.33-2.78-7.4-6.09-9.27
				c-0.62-0.35-1.22-0.37-1.86-0.05c-1.43,0.71-2.52,1.79-3.46,3.04c-1.93,2.56-3.04,5.49-3.84,8.55c-0.96,3.63-1.57,7.6-1.71,11.36
				c-0.18,4.95,0.72,10.51,5.74,12.72c0.75,0.33,1.55,0.52,2.34,0.76c0.15,0.05,0.26,0.19,0.26,0.35v3.95c0,0.2,0.16,0.36,0.36,0.36
				h2.37c0.2,0,0.36-0.16,0.36-0.36c0-0.15,0-0.31,0-0.46c0-1.16,0-2.31,0-3.47c0-0.24,0.03-0.4,0.32-0.44
				c4.12-0.65,6.18-3.87,7.15-7.67C509.14,611.82,508.33,605.72,506.16,599.89z"/>
			<path className="st124" d="M503.4,400.51c-0.22-0.6-0.45-1.19-0.69-1.78c-1.78-4.43-3.7-9.82-8.08-12.31
				c-0.82-0.47-1.62-0.49-2.47-0.06c-1.89,0.94-3.35,2.38-4.59,4.03c-2.56,3.39-4.03,7.28-5.1,11.35
				c-1.27,4.82-2.08,10.1-2.27,15.08c-0.24,6.57,0.95,13.95,7.62,16.88c0.99,0.44,2.06,0.69,3.11,1.01c0.2,0.06,0.34,0.25,0.34,0.46
				v5.25c0,0.27,0.22,0.48,0.48,0.48h3.15c0.27,0,0.48-0.22,0.48-0.48c0-0.2,0-0.41,0-0.61c0-1.54,0.01-3.07,0-4.61
				c0-0.32,0.04-0.53,0.42-0.59c5.47-0.86,8.2-5.14,9.49-10.19C507.35,416.36,506.27,408.25,503.4,400.51z"/>
			<path className="st124" d="M445.66,598.87c-0.92-4.48-2.49-8.83-4.44-12.68c-0.63-1.25-1.38-2.45-2.14-3.63
				c-0.6-0.93-1.68-0.9-2.35,0c-0.14,0.19-0.27,0.38-0.4,0.57c-2.1,3.23-3.52,6.76-4.6,10.44c-0.53,1.8-0.8,3.17-0.9,5.51
				c0-0.01-0.01-0.02,0-0.02c4.57,11.71-0.23,18.51-0.23,18.51c0.01-0.01,0.01-0.01,0.02-0.02c0.64,0.99,1.4,1.83,2.37,2.48
				c0.98,0.66,2.05,1.07,3.21,1.26c0.2,0.03,0.35,0.07,0.35,0.34c-0.01,1.25-0.01,2.5-0.01,3.74c0,0.04-0.02,0.08-0.04,0.14h2.83
				c0,0,0-4.15,0-4.16c0,0.05,2.73-0.98,3.08-1.16c1.28-0.69,2.22-1.76,2.81-3.09C447.56,611.75,446.8,604.41,445.66,598.87z"/>
			<path className="st124" d="M892.41,1625.39c-0.19-0.52-0.39-1.03-0.6-1.54c-1.54-3.83-3.2-8.5-7-10.65c-0.71-0.4-1.4-0.42-2.14-0.05
				c-1.64,0.81-2.9,2.06-3.98,3.49c-2.21,2.94-3.49,6.3-4.41,9.82c-1.1,4.17-1.8,8.74-1.96,13.05c-0.21,5.68,0.82,12.08,6.59,14.61
				c0.86,0.38,1.78,0.6,2.69,0.88c0.17,0.05,0.29,0.22,0.29,0.4v4.54c0,0.23,0.19,0.42,0.42,0.42h2.72c0.23,0,0.42-0.19,0.42-0.42
				c0-0.18,0-0.35,0-0.53c0-1.33,0.01-2.66,0-3.99c0-0.28,0.04-0.46,0.36-0.51c4.73-0.75,7.1-4.45,8.22-8.82
				C895.83,1639.1,894.9,1632.09,892.41,1625.39z"/>
			<path className="st124" d="M737.42,2250.91c-0.16-0.44-0.34-0.88-0.51-1.32c-1.32-3.29-2.74-7.29-6-9.13
				c-0.61-0.35-1.2-0.36-1.83-0.05c-1.4,0.7-2.48,1.76-3.41,2.99c-1.9,2.52-2.99,5.41-3.78,8.42c-0.94,3.58-1.55,7.49-1.68,11.19
				c-0.18,4.87,0.71,10.35,5.65,12.53c0.73,0.32,1.53,0.52,2.31,0.75c0.15,0.05,0.25,0.18,0.25,0.34v3.9c0,0.2,0.16,0.36,0.36,0.36
				h2.34c0.2,0,0.36-0.16,0.36-0.36c0-0.15,0-0.3,0-0.45c0-1.14,0-2.28,0-3.42c0-0.24,0.03-0.39,0.31-0.43
				c4.06-0.64,6.08-3.82,7.05-7.56C740.35,2262.67,739.55,2256.65,737.42,2250.91z"/>
			<path className="st124" d="M702.79,2251.99c-0.14-0.37-0.28-0.73-0.42-1.09c-1.09-2.72-2.27-6.02-4.96-7.55
				c-0.5-0.29-0.99-0.3-1.51-0.04c-1.16,0.58-2.05,1.46-2.82,2.47c-1.57,2.08-2.47,4.47-3.13,6.96c-0.78,2.96-1.28,6.19-1.39,9.25
				c-0.15,4.03,0.58,8.56,4.67,10.36c0.61,0.27,1.26,0.43,1.91,0.62c0.12,0.04,0.21,0.15,0.21,0.28v3.22c0,0.16,0.13,0.29,0.29,0.29
				h1.93c0.16,0,0.29-0.13,0.29-0.3c0-0.12,0-0.25,0-0.37c0-0.94,0-1.89,0-2.83c0-0.2,0.03-0.32,0.26-0.36
				c3.35-0.53,5.03-3.15,5.82-6.25C705.22,2261.71,704.56,2256.73,702.79,2251.99z"/>
			<path className="st124" d="M791.8,2187.78c-0.15-0.42-0.31-0.83-0.48-1.24c-1.24-3.08-2.57-6.82-5.62-8.55
				c-0.57-0.32-1.12-0.34-1.72-0.04c-1.31,0.65-2.32,1.65-3.19,2.8c-1.78,2.36-2.8,5.06-3.54,7.88c-0.88,3.35-1.45,7.01-1.57,10.48
				c-0.17,4.56,0.66,9.69,5.29,11.73c0.69,0.3,1.43,0.48,2.16,0.7c0.14,0.04,0.24,0.17,0.24,0.32v3.65c0,0.18,0.15,0.33,0.33,0.33
				h2.19c0.18,0,0.33-0.15,0.33-0.33c0-0.14,0-0.28,0-0.42c0-1.07,0-2.13,0-3.2c0-0.22,0.03-0.37,0.29-0.41
				c3.8-0.6,5.7-3.57,6.59-7.08C794.55,2198.79,793.8,2193.15,791.8,2187.78z"/>
		</g>
	</g>
</switch>

</svg>

        </MapContainer>
    );
};

export default Map;